import { useMessagesContext } from '@components/messaging/MessagesContext';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';
import useThread from '@components/messaging/thread/hooks/useThread';
import AdminIcon from '@assets/admin-mention-icon.svg';
import { MessageThreadType } from '@shared/welibrary-graphql/types';

// TODO use shared repo for the ID's. Until then, MENTION_ALL and MENTION_ADMINS must be kept in sync with notifications_constants.js -> SPECIAL_MENTIONS
export const MENTION_ALL = 'MENTION_ALL';
export const MENTION_ADMINS = 'MENTION_ADMINS';

const useThreadParticipants = () => {
    const { threadId } = useMessagesContext();

    const { getThreadInfo } = useThread();

    const { data: getMessageThreadData } = getThreadInfo();

    const selectedThreadData = getMessageThreadData?.getMessageThread;

    const allIcon =
        selectedThreadData?.displayIcon ||
        getRandomDefaultCoverImage(selectedThreadData?.displayName ?? threadId);

    const ampersatAll = { display: 'all', id: MENTION_ALL, userThumb: allIcon };
    const ampersatAdmins = { display: 'admins', id: MENTION_ADMINS, userThumb: AdminIcon };

    const participants: { display: string; id: string; userThumb: string }[] = [];

    if (
        selectedThreadData?.threadType === MessageThreadType.OfficialGroupChat ||
        selectedThreadData?.threadType === MessageThreadType.OfficialGroupAdminChat ||
        (selectedThreadData?.participants?.length ?? 0) > 2
    ) {
        // @all if we're in an official group chat or if we've got more than two people in the chat
        participants.push(ampersatAll);
    }
    if (selectedThreadData?.threadType === MessageThreadType.OfficialGroupChat) {
        // @admins if we're in an official group chat
        participants.push(ampersatAdmins);
    }

    // add individual users
    participants.push(
        ...(selectedThreadData?.participants.map(participant => {
            const user = participant?.user;

            return {
                display: user?.profile?.full_name ?? '',
                id: user?._id ?? '',
                userThumb: user?.profile?.picture ?? '',
            };
        }) ?? [])
    );

    return participants;
};

export default useThreadParticipants;
