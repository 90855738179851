import React from 'react';

const EditPencil: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg
            width="28"
            height="31"
            viewBox="0 0 28 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g filter="url(#filter0_d)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5673 3.0856C18.9185 1.3641 21.102 1.45394 22.8253 2.8051L25.3736 4.80344C27.097 6.1546 27.7075 8.2501 26.3563 9.97527L11.1598 29.3628C10.652 30.0118 9.87647 30.3949 9.05147 30.4041L3.1903 30.4793L1.86297 24.7684C1.67597 23.9673 1.86297 23.1239 2.3708 22.4731L17.5673 3.0856Z"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0.791992"
                    y="0.791992"
                    width="26.3016"
                    height="29.6873"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-1" dy="-1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default EditPencil;
