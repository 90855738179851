import React from 'react';

import useModal from '@web/ui/components/modals/hooks/useModal';
import useConfirmation from '@components/generic/hooks/useConfirmation';

import useNavigation from '@web/ui/components/navigation/useNavigation';

import { ModalContainer } from '@core/types/Modals';

import StylizedX from '@web/ui/dsc/svgs/lego/StylizedX';
import X from '../../dsc/svgs/FullSizeX';

const MobileMenu: ModalContainer = ({ component, options, open }) => {
    const { isMobileNavHidden } = useNavigation();

    const { closeModal } = useModal();
    const confirm = useConfirmation({ widen: true, className: options?.confirmationClassName });

    const handleCloseModal = () => {
        if (options.disableCloseHandlers) return;

        if (options?.confirmClose) {
            confirm({ text: options.confirmClose, onConfirm: () => setTimeout(closeModal, 350) });
        } else {
            options?.onClose?.();
            closeModal();
        }
    };

    const menuClass = `mid-modal-menu-adjustment ${open ? 'open' : 'close'} ${
        isMobileNavHidden ? 'full' : ''
    } ${options?.className ?? ''} ${options?.sectionClassName ?? ''}`;

    const showNotch = options?.showNotch ?? true;
    return (
        <aside
            id="mobile-menu"
            className={`${options?.hideDimmer ? 'hide-dimmer' : ''} ${
                options?.className === 'low-z' ? 'low-z' : undefined
            }`}
        >
            {/* exclusive to lego */}
            {options?.customCloseButton && (
                <button
                    type="button"
                    className={`${
                        open
                            ? `${options.customCloseButtonClass || 'mobile-lego-btn-visible'}`
                            : 'mobile-lego-btn-hidden'
                    }`}
                    onClick={() => closeModal()}
                >
                    <StylizedX className="mobile-lego-btn-close-icon" />
                </button>
            )}
            {/* exclusive to lego */}
            <section className={menuClass}>
                {showNotch && <div role="presentation" className="mobile-quick-select-top-notch" />}
                {options?.showCloseButtonOnMobile && (
                    <button
                        type="button"
                        className={`mobile-quick-select-close-button ${options?.mobileCloseButtonClass}`}
                        onClick={() => closeModal()}
                    >
                        <X />
                    </button>
                )}
                <section>{component}</section>
            </section>
            {!options?.hideDimmer && (
                <button
                    className="mobile-quick-select-dimmer fadein"
                    type="button"
                    onClick={handleCloseModal}
                    aria-label="modal-dimmer"
                    aria-hidden
                />
            )}
        </aside>
    );
};

export default MobileMenu;
