import React from 'react';

type ChunkyArrowRightProps = {
    size?: string;
    className?: string;
    color?: string;
};

const ChunkyArrowRight: React.FC<ChunkyArrowRightProps> = ({
    size = '20',
    className = '',
    color = 'black',
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M14.6895 20C14.1175 19.0621 13.5341 18.1496 12.9392 17.2624C12.3671 16.3625 11.7608 15.4563 11.1201 14.5437C10.5252 15.4309 9.94176 16.3245 9.36973 17.2243C8.79771 18.1115 8.24857 19.0114 7.72231 19.924L0 19.6958C1.37285 18.0735 2.69995 16.4702 3.98128 14.8859C5.28549 13.3016 6.5325 11.6857 7.72231 10.038C6.48674 8.41572 5.22829 6.81876 3.94696 5.24715C2.68851 3.66286 1.41862 2.06591 0.137285 0.456274L8.16849 0C8.9922 1.73638 9.97608 3.43473 11.1201 5.09506C11.6006 4.27123 12.0926 3.44106 12.5959 2.60456C13.1222 1.76806 13.6141 0.899873 14.0718 0L22 0.342205C20.6271 1.95184 19.3229 3.54246 18.0874 5.11407C16.8518 6.68568 15.6277 8.2763 14.415 9.88593C15.5819 11.4956 16.7603 13.0672 17.9501 14.6008C19.1628 16.1343 20.3755 17.6869 21.5881 19.2586L14.6895 20Z"
                fill={color}
            />
        </svg>
    );
};

export default ChunkyArrowRight;
