import React, { useState, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

import AlertToast from '@components/toast/AlertToast';
import Modal from '@web/ui/components/generic/lightbox/Modal.js';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/square-loader.json';

import { useToastActionsContext } from '@components/toast/NewToastContext';

import {
    getInAppBrowserOptions,
    getWhitelabelPublicSettings,
    testInAppLink,
} from '@core/utilities/whitelabel_helpers';
import { isOnExternalBrowserWhitelist } from '@core/utilities/customLinkifyPlugin';

import { defaultAlertToastOptions } from '@core/types/Toast';

type ExternalLinkProps = {
    to: string;
    className?: string;
    target?: '_blank' | '_parent' | '_self' | '_top';
    nativeTarget?: string;
    whiteColor?: boolean;
    whiteColorOverride?: string;
    openInModal?: boolean;
    style?: CSSProperties;
    skipRelativeCheck?: boolean;
    onClick?: () => void;
};

const ExternalLink: React.FC<ExternalLinkProps> = ({
    to = '#',
    className = '',
    target = '_blank',
    nativeTarget,
    whiteColor,
    whiteColorOverride,
    openInModal,
    style,
    onClick,
    skipRelativeCheck,
    children,
}) => {
    const { t } = useTranslation();
    const { newToast } = useToastActionsContext();
    const { theme } = getWhitelabelPublicSettings();
    const [toggleModal, setToggleModal] = useState(false);
    const [loadingIframe, setLoadingIframe] = useState(true);
    const [isInternalLink, relativeUrl] = testInAppLink(to, skipRelativeCheck);

    const handleModal = () => {
        if (Capacitor.isNativePlatform()) {
            newToast(
                <AlertToast
                    boldText={t(
                        `common:imports.wlWeb.ui.components.externallink.not_available`,
                        'This tool is not available on mobile. You can access using a computer.'
                    )}
                    showWarningIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
            return;
        }

        if (!loadingIframe && !toggleModal) {
            setLoadingIframe(true);
        }
        setToggleModal(!toggleModal);
    };

    const handleIframeLoad = () => {
        setLoadingIframe(false);
    };

    if (!to.includes('://') && !isInternalLink) {
        to = `https://${to}`;
    }

    if (
        to === 'https://credreg.net/page/T3Mapping?mode=iframe' ||
        to === 'https://t3.editor.cassproject.org/import' ||
        openInModal
    ) {
        const renderIframeLoading = loadingIframe ? (
            <div className="iframe-loader-container">
                <LottieLoading height={50} width={200} lottieData={LottieAnimation} />
                <h4 style={{ textAlign: 'center' }}>Loading</h4>
            </div>
        ) : null;

        const iframeLoaderClass = loadingIframe ? 'iframe-loader loading' : 'iframe-loader';
        return (
            <>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={handleModal} className={className} target={target} style={style}>
                    {children}
                </a>
                <Modal delayTime={500} className="modal" isMounted={toggleModal} show={toggleModal}>
                    <div className="iframe-modal">
                        <div className="iframe-modal-container">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                                onClick={handleModal}
                                className="move-item-title-bar iframe-modal-menu "
                            >
                                <h4>Close</h4>
                                <span className="cancel-edit-content-button w-inline-block" />
                            </a>
                            <iframe
                                title="Link iframe"
                                onLoad={handleIframeLoad}
                                className="iframe-link"
                                src={to}
                                name="iframe"
                                height="100%"
                                width="100%"
                            />
                            <div className={iframeLoaderClass}>{renderIframeLoading}</div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }

    // This is to handle case if user puts in internal link
    // that gets output by this component....
    if (isInternalLink && relativeUrl) {
        return (
            <Link
                to={relativeUrl}
                className={className}
                style={style}
                target={target}
                onClick={onClick}
            >
                {children}
            </Link>
        );
    }

    if (Capacitor.isNativePlatform()) {
        // Follows Capacitor Spec: https://capacitorjs.com/docs/apis/browser/
        let _nativeTarget = nativeTarget || '_blank';
        let options: { toolbarColor: string } | undefined = getInAppBrowserOptions();
        if (!to.includes('http') || isOnExternalBrowserWhitelist(to)) {
            _nativeTarget = '_system';
            options = undefined;
        }

        const defaultStyle = {
            color: whiteColor ? whiteColorOverride ?? 'white' : theme?.primaryColor || '#0094f6',
        };

        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
                className={className}
                onClick={() => {
                    if (!to.includes('http') || isOnExternalBrowserWhitelist(to)) {
                        window.location.href = to;
                    } else {
                        Browser.open({
                            url: to,
                            windowName: _nativeTarget,
                            toolbarColor: options?.toolbarColor,
                        });
                    }
                }}
                style={style || defaultStyle}
                href="#"
                target={_nativeTarget}
                rel="noopener"
            >
                {children}
            </a>
        );
    }

    return (
        <a
            href={to}
            className={className}
            target={target}
            rel="noopener"
            style={style}
            onClick={onClick}
        >
            {children}
        </a>
    );
};

export default ExternalLink;
