import React, { useState } from 'react';
import { useImmer } from 'use-immer';

import ConnectedAccountStep0 from '@components/group/groupdashboard/stripe/ConnectedAccountStep0';
import ConnectedAccountStep1 from '@components/group/groupdashboard/stripe/ConnectedAccountStep1';
import ConnectedAccountStep2 from '@components/group/groupdashboard/stripe/ConnectedAccountStep2';
import ConnectedAccountStep3 from '@components/group/groupdashboard/stripe/ConnectedAccountStep3';
import ProgressBar from '@web/ui/components/generic/loading/ProgressBar';

import { Group } from '@shared/welibrary-graphql/types';
import { CARD_EVENT } from '@core/utilities/constants/card_types';

export type MerchantSignupState = {
    country: string;
    email: string;
};

const TOTAL_STEPS = 3;

const ConnectedAccountStripeForm: React.FC<{ group: Group }> = ({ group }) => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [state, setState] = useImmer<MerchantSignupState>({
        email: group?.paymentSettings?.stripeAccount?.email || '',
        country: 'US',
    });

    const handleNextStep = () => {
        if (currentStep < TOTAL_STEPS) {
            setCurrentStep(prevStep => prevStep + 1);
        }
    };

    const handlePrevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(prevStep => prevStep - 1);
        }
    };

    let activeStep = null;

    const isEvent = group?.subtype === CARD_EVENT;

    if (!group?.paymentSettings?.stripeAccount) {
        if (currentStep === 0) {
            activeStep = (
                <ConnectedAccountStep0
                    totalSteps={TOTAL_STEPS}
                    currentStep={currentStep}
                    handleNextStep={handleNextStep}
                    isEvent={isEvent}
                />
            );
        } else if (currentStep === 1) {
            activeStep = (
                <ConnectedAccountStep1
                    totalSteps={TOTAL_STEPS}
                    currentStep={currentStep}
                    handleNextStep={handleNextStep}
                    handlePrevStep={handlePrevStep}
                    state={state}
                    setState={setState}
                />
            );
        } else if (currentStep === 2) {
            activeStep = (
                <ConnectedAccountStep2
                    totalSteps={TOTAL_STEPS}
                    currentStep={currentStep}
                    handleNextStep={handleNextStep}
                    handlePrevStep={handlePrevStep}
                    state={state}
                    setState={setState}
                />
            );
        } else if (currentStep === 3) {
            activeStep = (
                <ConnectedAccountStep3
                    handlePrevStep={handlePrevStep}
                    totalSteps={TOTAL_STEPS}
                    currentStep={currentStep}
                    state={state}
                    groupId={group._id}
                />
            );
        }
    }

    return (
        <div className="edit-screen-content-view stripe-merchant-account">
            <div className="edit-screen-content-items">
                <div className="mc-source-post-wrapper">
                    {!group?.paymentSettings?.stripeAccount && currentStep !== 0 && (
                        <>
                            <p className="question-panel-sub">{`Step ${currentStep} of ${TOTAL_STEPS}`}</p>
                            <ProgressBar
                                bgcolor="#6a1b9a"
                                completed={(currentStep / TOTAL_STEPS) * 100}
                            />
                        </>
                    )}
                    {activeStep}
                </div>
            </div>
        </div>
    );
};

export default ConnectedAccountStripeForm;
