import path from 'path-browserify';
import { Capacitor } from '@capacitor/core';

import { config } from '@config/getConfig';
import { ConfigType } from '@config/schemas/main';

import { Logger, LoggerArgs } from './types';
import { sendLogToLambda } from './lambda';

const LAMBDA_ENVIRONMENTS: Partial<Record<ConfigType['public']['configName'], string>> = {
    ioe: 'production',
    'lego-ssl': 'production',
    'production-welibrary': 'production',
    'staging-welibrary': 'staging',
    'production-commissioned': 'production-commssioned',
    'staging-commissioned': 'staging-commissioned',
    'production-itz': 'production-itz'
};

const env = LAMBDA_ENVIRONMENTS[config.public.configName];

const parseTraceOrLog = (input: any | Error) => {
    if (!(input instanceof Error)) return { message: input };

    const { stack: stackTrace = '', message: errMessage } = input;
    const message = errMessage;

    return { stackTrace, message };
};

const log = (level: 'debug' | 'info' | 'warn' | 'error', args: LoggerArgs) => {
    const { filename, args: logArgs, jsonMessageKey } = args;

    const [traceOrLog, ...data] = logArgs;

    const { stackTrace, message } = parseTraceOrLog(traceOrLog);

    const isNativePlatform = Capacitor.isNativePlatform().toString();
    const platform = Capacitor.getPlatform();

    // don't show logs in production
    if (!env?.includes('production')) {
        // eslint-disable-next-line no-restricted-syntax
        console[level](message, { filename, data, stackTrace, isNativePlatform, platform });
    }

    const lambdaLog = {
        level,
        message,
        data,
        service: 'welibrary',
        isNativePlatform,
        stackTrace,
        platform,
        env,
        filename,
    };

    // Enables consolidation of all logs under one Elasticsearch index template
    // regardless of what type the message is: string, object, array, etc.
    if (typeof message === "object") lambdaLog["message"] = JSON.stringify(message);
    if (jsonMessageKey) lambdaLog[jsonMessageKey] = message;

    if (env) {
        sendLogToLambda(lambdaLog);
    }
};

const _logger: Logger = {
    debug: (args: LoggerArgs) => log('debug', args),
    info: (args: LoggerArgs) => log('info', args),
    warn: (args: LoggerArgs) => log('warn', args),
    error: (args: LoggerArgs) => log('error', args),
    metrics: (args: LoggerArgs) => log('info', args),
};

export const getLogger = (mod: typeof module | string) => {
    const filename = typeof mod === 'string' ? mod : path.basename(mod.id.toString(), '.js');

    return {
        debug: (...args: any[]) => _logger.debug({ args, filename }),
        info: (...args: any[]) => _logger.info({ args, filename }),
        warn: (...args: any[]) => _logger.warn({ args, filename }),
        error: (...args: any[]) => _logger.error({ args, filename }),
        metrics: (...args: any[]) => _logger.metrics({ args, filename, jsonMessageKey: 'metrics' }),
    };
};

export default getLogger;
