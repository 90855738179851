import React from 'react';

const FullScreenSelectManyOption = ({ target, value, selected, handleClick, label }) => {
    return (
        <button
            type="button"
            onClick={() => handleClick(target, value)}
            value={value}
            name={target}
            className="overlay-menu-linkblock not-primary w-inline-block button-reset"
        >
            <div className="flex-center">
                <div>{label}</div>
            </div>
            <div
                className={`menu-link-selector ${selected ? 'selected-menu-link-selector' : ''}`}
            />
        </button>
    );
};

export default FullScreenSelectManyOption;
