import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';

import TrashBin from '@dsc/svgs/Trash';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import shoppingCartStore from '@web/ui/stores/ShoppingCart';

import { useValidateCouponLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { useConvertCurrencyLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';

import { Group } from '@shared/welibrary-graphql/types';
import { DiscountCodeState } from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';
import { isEventFree } from '@web/utilities/helpers/events/event.helpers';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';

const ShoppingCartDiscountCode: React.FC<{ group: Group; productId: string }> = ({
    group,
    productId,
}) => {
    const _isEventFree = isEventFree(group);

    const shoppingCart = shoppingCartStore?.useTracked?.shoppingCarts()?.find(({ groupId }) => {
        return groupId === group?._id;
    });
    const shoppingCartProducts = shoppingCart?.products?.filter(({ product }) => {
        if (_isEventFree) return product?.type === PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP;

        return product?.type === PRODUCT_TYPES?.GROUP_MEMBERSHIP;
    });
    const currency = shoppingCartProducts?.[0]?.product?.currency;

    const [discountCodeState, setDiscountCodeState] = useImmer<Partial<DiscountCodeState | {}>>({
        code: '',
        percentage: undefined,
        minChargeAmount: '',
        isApplied: null,
        error: null,
    });

    const updateDiscountCodeSlice = curriedStateSlice(setDiscountCodeState);

    const [convertCurrency] = useConvertCurrencyLazyQuery();

    const [validateCoupon, { data }] = useValidateCouponLazyQuery({
        fetchPolicy: 'no-cache',
        onCompleted: async () => {
            if (data?.validateCoupon.valid) {
                const productsWithCoupons = data?.validateCoupon?.productsWithCoupons;

                const { data: minCurrencyCharge } = await convertCurrency({
                    variables: {
                        to: currency,
                        from: 'USD',
                        amount: '1',
                    },
                    fetchPolicy: 'network-only',
                });

                if (productsWithCoupons?.length > 0) {
                    shoppingCartProducts?.forEach(({ product }) => {
                        const coupon = productsWithCoupons?.find(
                            ({ product: p }) => product?._id === p?._id
                        )?.coupon;

                        if (!coupon) return;

                        shoppingCartStore?.set?.setProductCoupon(group?._id, product?._id, {
                            isApplied: true,
                            code: coupon?.code,
                            percentage: coupon?.percentage,
                            minChargeAmount: minCurrencyCharge?.convertCurrency?.value,
                            error: null,
                        });
                    });

                    setDiscountCodeState({
                        code: '',
                        percentage: undefined,
                        isApplied: null,
                        error: null,
                    });
                }
            } else {
                setDiscountCodeState({
                    ...discountCodeState,
                    percentage: undefined,
                    isApplied: false,
                    error: data?.validateCoupon.message,
                });
            }
        },
    });

    const handleDiscountCode = (couponCode: string) => {
        if (couponCode) {
            const shoppingCartProductIds = shoppingCartProducts?.map(({ product: p }) => p?._id);

            validateCoupon({
                variables: {
                    input: {
                        couponCode,
                        productIds: shoppingCartProductIds,
                    },
                },
            });
        }
    };

    const handleRemoveDiscountCode = () => {
        setDiscountCodeState({
            percentage: undefined,
            error: null,
            isApplied: false,
            code: '',
        });

        shoppingCartStore?.set?.setProductCoupon(group?._id, productId, {});
    };

    if (_isEventFree) return <></>;

    return (
        <div className="checkout-item-wrap !mt-[-20px] !mb-[30px]">
            <div className="customer-payments-container">
                <div className="stripe-checkout-form">
                    <div className="stripe-checkout-form-element-wrap discount-code-wrap">
                        <input
                            disabled={discountCodeState?.isApplied ?? false}
                            className={`stripe-checkout-form-element stripe-checkout-customer-form-element stripe-checkout-customer-form-element-discount ${discountCodeState?.error
                                    ? 'stripe-checkout-customer-form-element-discount-err'
                                    : ''
                                } `}
                            type="text"
                            placeholder="Discount Code"
                            onChange={e =>
                                updateDiscountCodeSlice('code', e.target.value.toUpperCase())
                            }
                            onBlur={() => updateDiscountCodeSlice('error', null)}
                            onFocus={() => updateDiscountCodeSlice('error', null)}
                            value={discountCodeState?.code?.toUpperCase()}
                            autoComplete="off"
                        />
                        {discountCodeState?.isApplied ? (
                            <button
                                onClick={() => handleRemoveDiscountCode()}
                                type="button"
                                className="stripe-checkout-discount-btn-remove"
                            >
                                <TrashBin />
                            </button>
                        ) : (
                            <button
                                onClick={() => handleDiscountCode(discountCodeState?.code)}
                                type="button"
                                className="stripe-checkout-discount-btn"
                                disabled={discountCodeState?.code?.length === 0}
                            >
                                Apply
                            </button>
                        )}
                    </div>
                    {discountCodeState?.error && (
                        <p className="stripe-checkout-customer-err-msg">
                            {discountCodeState?.error}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShoppingCartDiscountCode;
