import React from 'react';

import signUpStore from '@stores/SignUp';

import useScreenWidth from '@components/generic/hooks/useScreenWidth';

import NewSignupLanguagePicker from './NewSignupLanguagePicker';
import PostSignupGroupsJoinedAndRequested from './GroupsJoinedAndRequested';
import PostSignupGroupJoined from './PostSignupGroupJoined';

type PostSignupLayoutProps = {
    image?: string;
    children: React.ReactNode;
};

const PostSignupLayout: React.FC<PostSignupLayoutProps> = ({ children }) => {
    const groupsJoinedAndRequested = signUpStore.useTracked.groupsJoinedAndRequested();

    const width = useScreenWidth();

    const showPostSignupGroups = width > 767;

    return (
        <section className="h-full w-full flex items-center justify-center overflow-hidden relative">
            {groupsJoinedAndRequested?.length > 1 && showPostSignupGroups && (
                <PostSignupGroupsJoinedAndRequested />
            )}

            {(groupsJoinedAndRequested?.length ?? 0) === 0 && showPostSignupGroups && (
                <PostSignupGroupJoined suppressModal={false} />
            )}

            <NewSignupLanguagePicker
                className="absolute left-[3%] bottom-[3%] new-signup-language-picker w-[36%]"
                showLinks
                showSupportButton
            />

            <section className="w-full md:w-[60%] h-full bg-white">{children}</section>
        </section>
    );
};

export default PostSignupLayout;
