import { ConfigInputType } from '@config/schemas/main';

// This guy is missig some crucial stuff...
const config: ConfigInputType = {
    public: {
        root_url: 'croaktest.welibrary.io',
        apiUrl: 'croaktest.welibrary.io',

        configName: 'croaktest',
        captcha: {
            key: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        },
        copyright: 'WeLibrary, LLC',

        filestack: { api_key: 'AI3hkuq43QRiM0Z75ZCqpz' },
        unsplash: { access_key: 'NTNacJWbx9pMW5yHizHSqWr1IpQXmTJTuQ08VZ7pSEg' },
        Google: { Maps_API_Key: 'AIzaSyBDfzEYNC2sHOYi9OEwmwNVVtOMDFfUV5I' },
        sentry: { dsn: 'https://ee82c42de4bd4913abba1d776b213c8e@sentry.io/1427149' },

        defaultMembershipUrl: 'welibrary',
        restrictSignupToDefaultMembership: false,

        privacyPolicy: 'www.welibrary.io/privacy-policy',
        termsOfService: 'www.learningeconomy.io/terms-of-service',
        templatesUrl: 'templates',

        defaultLogoBackup: '/images/logo.welibrary.blue.svg',
        defaultLogoBackupWhite: '/images/logo.welibrary.blue.white.svg',
        defaultLogoBackupWhiteAlt: '',

        pushSenderID: 847997887601,
        pushLogo: 'we_notification',

        recommendationsMatchmakerWhitelist: ['library-of-matching'],

        userSnap: true,

        locize: {
            projectId: '662a9e61-2900-409c-a73e-6ba0bbfc3f38',
            apiKey: '3ff8b824-d205-42ea-be99-a366868d9f73',
            referenceLng: 'en',
        },
        appflow: {
            liveUpdate: {
                appId: 'ff5def27',
                channel: 'croaktest',
                updateMethod: 'none',
            },
        },
    },
};

export default config;
