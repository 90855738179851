import React from 'react';
//HOC that allows you to delay unmounting of a component
//takes a delaytime prop which sets timer

export default function DelayUnmounting(Component) {
    return class extends React.Component {
        state = {
            shouldRender: this.props.isMounted,
        };

        handleRender = () => {
            this.setState({ shouldRender: false });
        };

        componentDidUpdate(prevProps) {
            if (prevProps.isMounted && !this.props.isMounted) {
                setTimeout(this.handleRender, this.props.delayTime);
            } else if (!prevProps.isMounted && this.props.isMounted) {
                this.setState({ shouldRender: true });
            }
        }

        render() {
            return this.state.shouldRender ? <Component {...this.props} /> : null;
        }
    };
}
