import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { useMoveCardMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';

import { CONTENT_CARD_REFERENCE_FRAGMENT } from '@shared/welibrary-graphql/content_card/fragments';

import SearchBox from '@components/generic/search/SearchBox';

import {
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_LIBRARY,
    CARD_SHELF,
} from '@core/utilities/constants/card_types';

const MoveItem = ({
    item,
    cardTypes,
    handleClose,
    activeQuery,
    childrenTypes,
    disableRenderChildren,
}) => {
    const { t } = useTranslation();

    const [duplicate, setDuplicate] = useState(false);
    const [moveItem, setMoveItem] = useState({});
    const [moveCard, { loading }] = useMoveCardMutation();
    const [enableSubmit, setEnableSubmit] = useState(false);

    const handleCloseForm = e => handleClose(e);

    const handleMoveItems = e => {
        e.preventDefault();
        setEnableSubmit(false);
        moveCard({
            variables: {
                cardUrl: item.url,
                newParentUrl: moveItem.url,
                duplicate,
            },
            update: (cache, { data: { moveCard } }) => {
                // If user duplicated card elsewhere, keep card in cache. Only delete from cache if user moved card.
                if (duplicate) return;

                const fragment = CONTENT_CARD_REFERENCE_FRAGMENT;
                const fragmentName = 'ContentCardReferenceFragment';

                const existingFragment = cache.readFragment({
                    id: `ContentCard:${moveCard._id}`,
                    fragment,
                    fragmentName,
                });

                if (existingFragment && moveCard.reference) {
                    const newFragment = { ...existingFragment };
                    newFragment.reference = existingFragment.reference
                        ? { ...existingFragment.reference, ...moveCard.reference }
                        : { ...moveCard.reference };
                    newFragment.__typename = 'ContentCard';
                    // Write back into cache
                    cache.writeFragment({
                        id: `ContentCard:${moveCard._id}`,
                        fragment,
                        fragmentName,
                        data: newFragment,
                    });
                }

                if (activeQuery) {
                    const data = cache.readQuery({
                        query: activeQuery.query,
                        variables: activeQuery.variables,
                    });

                    if (!data || !data[activeQuery.dataTitle]) return;
                    // Remove moved card from cache.
                    const existingChildrenFromCard = data[activeQuery.dataTitle];
                    const newChildrenFromCard = existingChildrenFromCard.filter(
                        card => card._id !== moveCard._id
                    );
                    cache.writeQuery({
                        query: activeQuery.query,
                        variables: activeQuery.variables,
                        data: { [activeQuery.dataTitle]: newChildrenFromCard },
                    });
                }
            },
        }).then(() => {
            handleClose(e);
            setEnableSubmit(true);
        });
    };

    const handleDuplicate = () => {
        setDuplicate(!duplicate);
    };

    const onSelect = item => {
        if (!isEmpty(moveItem) && moveItem === item) {
            setMoveItem({});
            setEnableSubmit(false);
        } else {
            setMoveItem(item);
            setEnableSubmit(true);
        }
    };

    let searchCardType = CARD_CHAPTER;
    if (item && item.type === 'book') {
        searchCardType = CARD_SHELF;
    }
    if (item && item.type === 'shelf') {
        searchCardType = CARD_LIBRARY;
    }

    if (item && item.type === 'chapter') {
        searchCardType = CARD_BOOK;
    }

    const cardType =
        searchCardType !== CARD_CHAPTER
            ? item && item.type[0].toUpperCase() + item.type.slice(1)
            : 'Content';

    // move-form overlay header title
    let headerTitle = cardType;

    if (cardType === 'Shelf') {
        headerTitle = t('common:imports.wlWeb.ui.components.group.groupDashboard.subscriptions');
    }
    if (cardType === 'Book') {
        headerTitle = t(`common:global.constants.cards.types.collection`);
    }
    if (cardType === 'Chapter') {
        headerTitle = t(`common:global.constants.cards.types.channel`);
    }

    return (
        <div className="move-item-form-container">
            <div className="move-item-form">
                <div className="move-item-header">
                    <div className="move-item-title-bar">
                        <h4>Move {headerTitle}</h4>
                        <div>
                            <div
                                onClick={handleCloseForm}
                                className="cancel-edit-content-button w-inline-block"
                            />
                            {/* <div onClick={handleCloseForm} className="approve-edit-content-button w-inline-block"></div> */}
                        </div>
                    </div>
                    <div className="move-item-search-input-container" />
                    <div className="move-item-search-input">
                        <SearchBox
                            type="cards"
                            showEmpty
                            disableRenderChildren={disableRenderChildren}
                            moveItem={moveItem}
                            childrenTypes={childrenTypes}
                            checkbox
                            renderNoResults
                            types={cardTypes || [searchCardType]}
                            onSelect={onSelect}
                        />
                    </div>
                </div>

                <div className="move-item-footer">
                    <div className="move-item-duplicate-container">
                        <label className="move-item-label">
                            Duplicate
                            <input onClick={handleDuplicate} type="checkbox" value={duplicate} />
                            <span className="checkmark" />
                        </label>
                    </div>
                    <div className="move-item-button">
                        {enableSubmit ? (
                            <button
                                type="button"
                                onClick={handleMoveItems}
                                className="move-item-buttom"
                            >
                                Move Here
                            </button>
                        ) : (
                            <button
                                disabled
                                type="button"
                                className="move-item-buttom"
                                style={{ opacity: '0.6' }}
                            >
                                {loading ? 'Moving..' : 'Move Here'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MoveItem;
