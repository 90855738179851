import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import useModal from '@components/modals/hooks/useModal';

import TextInput from '@dsc/forms/textInputs/SimpleTextInput';
import Toggle from '@dsc/forms/customInputs/Toggle';
import Select from '@dsc/forms/customInputs/Select';
import Button from '@dsc/forms/buttons/Button';
import ImageUpload from '@dsc/forms/customInputs/ImageUpload';
import CourseFormRangeSelect from '@components/content/newpost/forms/Course/CourseFormRangeSelect';
import MultiTextInput from '@dsc/forms/customInputs/MultiTextInput';

import { curriedStateSlice } from '@web/utilities/helpers/state/state.helpers';
import {
    SurveyQuestionMeta,
    curriedShouldShowField,
    SurveyQuestionTypeDisplay,
    SurveyQuestionSubTypeDisplay,
} from '@web/utilities/helpers/surveys/survey.helpers';
import { SKILL, SKILL_SUBTYPE, MILESTONE } from '@helpers/lego/skill.helpers';
import { autoTranslatedDisplayValues } from '@web/utilities/helpers/dsc/dsc.helpers';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

import { SurveyQuestionFormState } from '@core/types/Surveys';
import { SurveyQuestionType, SurveyQuestionSubType } from '@shared/welibrary-graphql/types';

type SurveyQuestionFormProps = {
    state: SurveyQuestionFormState;
    onChange: (state: SurveyQuestionFormState) => void;
};

const SurveyQuestionForm: React.FC<SurveyQuestionFormProps> = ({ state, onChange }) => {
    const { t } = useTranslation();

    const [surveyQuestionState, setSurveyQuestionState] = useImmer(state);

    const updateSlice = curriedStateSlice(setSurveyQuestionState);

    const shouldShowField = curriedShouldShowField(surveyQuestionState.type);

    const { closeModal } = useModal();

    const submit = () => {
        onChange(surveyQuestionState);
        closeModal();
    };

    useEffect(() => {
        // Force subtype to have a valid value when type changes
        if (
            !SurveyQuestionMeta[surveyQuestionState.type].subtypes?.includes(
                surveyQuestionState.subtype as SurveyQuestionSubType
            )
        ) {
            updateSlice('subtype')(SurveyQuestionMeta[surveyQuestionState.type].subtypes?.[0]);
        }

        // Set default slider range
        if (!surveyQuestionState.range) {
            updateSlice('range')(
                surveyQuestionState.type === SurveyQuestionType.Slider ? [1, 5] : undefined
            );
        }
    }, [surveyQuestionState.type]);

    // Force skill subtype to be valid when skill changes
    useEffect(() => {
        if (
            !(SKILL_SUBTYPE[surveyQuestionState.skill] ?? []).includes(
                surveyQuestionState.skillSubtype
            )
        ) {
            updateSlice('skillSubtype')('');
        }
    }, [surveyQuestionState.skill]);

    // Force milestone to be valid when skill subtype changes
    useEffect(() => {
        if (
            !(
                MILESTONE[surveyQuestionState.skill]?.[surveyQuestionState.skillSubtype] ?? []
            ).includes(surveyQuestionState.skillMilestone)
        ) {
            updateSlice('skillMilestone')('');
        }
    }, [surveyQuestionState.skillSubtype]);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                submit();
            }}
            className="course-form-survey-question"
        >
            <header>
                <h3>{t('common:question_details', 'Question Details')}</h3>
            </header>

            <section>
                <Select
                    title="Question Type"
                    value={surveyQuestionState.type}
                    onChange={updateSlice('type')}
                    options={Object.values(SurveyQuestionType)}
                    displayValues={autoTranslatedDisplayValues(SurveyQuestionTypeDisplay, t)}
                />

                {shouldShowField('subtype') && (
                    <Select
                        title="Question SubType"
                        value={surveyQuestionState.subtype}
                        onChange={updateSlice('subtype')}
                        options={
                            SurveyQuestionMeta[surveyQuestionState.type].subtypes ??
                            Object.values(SurveyQuestionSubType)
                        }
                        displayValues={autoTranslatedDisplayValues(SurveyQuestionSubTypeDisplay, t)}
                    />
                )}

                {shouldShowField('prompt') && (
                    <>
                        <p>Prompt</p>
                        <TextInput
                            value={surveyQuestionState.prompt}
                            onChange={updateSlice('prompt')}
                            placeholder="Enter prompt here..."
                        />
                    </>
                )}

                {shouldShowField('range') && (
                    <CourseFormRangeSelect
                        title="Range"
                        prompt="Select..."
                        value={surveyQuestionState.range}
                        onChange={updateSlice('range')}
                        options={[
                            { value: [0, 3], display: '0-3' },
                            { value: [0, 5], display: '0-5' },
                            { value: [0, 10], display: '0-10' },
                            { value: [1, 3], display: '1-3' },
                            { value: [1, 5], display: '1-5' },
                            { value: [1, 10], display: '1-10' },
                        ]}
                    />
                )}

                {shouldShowField('useImage') && (
                    <label>
                        Display Prompt Image
                        <Toggle
                            value={surveyQuestionState.useImage}
                            onChange={updateSlice('useImage')}
                        />
                    </label>
                )}

                {shouldShowField('image') && surveyQuestionState.useImage && (
                    <ImageUpload
                        state={{
                            ...surveyQuestionState.image,
                            image:
                                surveyQuestionState.image.image ||
                                getRandomDefaultCoverImage(surveyQuestionState.prompt),
                        }}
                        setState={updateSlice('image')}
                    />
                )}

                {shouldShowField('answers') && (
                    <MultiTextInput
                        values={surveyQuestionState.answers ?? ['']}
                        onChange={updateSlice('answers')}
                        label="Answers"
                        inputLabels="Answer"
                        buttonText="Add Answer"
                    />
                )}

                {shouldShowField('correctAnswer') && (
                    <Select
                        title="Correct Answer"
                        value={surveyQuestionState.correctAnswer ?? ''}
                        onChange={updateSlice('correctAnswer')}
                        options={surveyQuestionState.answers ?? []}
                        clearable
                    />
                )}

                {shouldShowField('skill') && (
                    <Select
                        title="Skill addressed"
                        value={surveyQuestionState.skill ?? ''}
                        onChange={updateSlice('skill')}
                        options={Object.values(SKILL)}
                        clearable
                    />
                )}

                {shouldShowField('skillSubtype') && surveyQuestionState.skill && (
                    <Select
                        title="Skill Subtype addressed"
                        value={surveyQuestionState.skillSubtype ?? ''}
                        onChange={updateSlice('skillSubtype')}
                        options={SKILL_SUBTYPE[surveyQuestionState.skill] ?? []}
                        clearable
                    />
                )}

                {shouldShowField('skillMilestone') &&
                    surveyQuestionState.skill &&
                    surveyQuestionState.skillSubtype && (
                        <Select
                            title="Skill Milestone addressed"
                            value={surveyQuestionState.skillMilestone ?? ''}
                            onChange={updateSlice('skillMilestone')}
                            options={
                                MILESTONE[surveyQuestionState.skill]?.[
                                    surveyQuestionState.skillSubtype
                                ] ?? []
                            }
                            clearable
                        />
                    )}
            </section>

            <footer>
                <Button type="small" onClick={submit}>
                    Submit
                </Button>
                <Button type="small" onClick={closeModal} className="cancel">
                    Cancel
                </Button>
            </footer>
        </form>
    );
};

export default SurveyQuestionForm;
