import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import searchStore from '@stores/Search';

import { awsMetricPublisher } from '@welibraryos/metrics';
import { useCurrentUser } from '@stores/User';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useModal from '@components/modals/hooks/useModal';
import useNavNotifications from '@components/generic/notifications/hooks/useNavNotifications';
import useNavigation from '@components/navigation/useNavigation';

import SlideMenuNavButton from '@components/navigation/SlideMenuNavButton';
import ActionButton from '@components/navigation/ActionButton';
import NavigationIcon from '@components/navigation/NavigationIcon';
import GroupNavigation from '@components/group/GroupNavigation';
import MobileMenuList from '@components/modals/MobileMenuList';
import LegoNav from '@components/navigation/Lego/LegoNav';
import LoadingLine from '@components/generic/loading/LoadingLine';
import Discover from '@components/discover/DiscoverModal';
import NavNotifications from '@components/generic/notifications/NavNotifications';
import MessagingNavigationIcon from '@components/messaging/MessagingNavigationIcon';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import {
    getWhitelabelPublicSettings,
    getWhitelabelRedirects,
} from '@core/utilities/whitelabel_helpers';
import { unsetAuthToken } from '@helpers/auth';
import {
    navItems,
    showNavItem,
    desktopSecondaryNavIsEmpty,
} from '@helpers/navigation/secondaryNav.helpers';
import shoppingCartStore from '@stores/ShoppingCart';
import signUpStore from '@stores/SignUp';

import { ModalTypes } from '@core/types/Modals';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.navigation.secondaryNavigation';

const SecondaryNavigation = ({ client, showActionButton }) => {
    const { t } = useTranslation();

    const flags = useFlags();
    const siloedUsers = flags?.siloedUsers;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { currentUser, setCurrentUser } = useCurrentUser();
    const { newModal, closeModal } = useModal({
        desktop: ModalTypes.Center,
        mobile: ModalTypes.Center,
    });

    const {
        isProfileMenuOpen,
        setProfileMenuOpenState,
        toggleProfileMenu,
        toggleGroupSwitch,
        setGroupSwitchOpenState,
        isGroupSwitchOpen,
        toggleSlideMenu,
        closeSlideMenu,
        isSlideMenuOpen,
    } = useNavigation();

    const notifications = useNavNotifications();

    const profileImageRef = useRef<HTMLDivElement>(null);
    const profileMenuRef = useRef<HTMLElement>(null);

    const { root_redirect, root_redirect_loggedin } = getWhitelabelRedirects();
    const { logout_redirect } = getWhitelabelPublicSettings();

    const logout = () => {
        try {
            unsetAuthToken();
            setCurrentUser(null);
            client.clearStore();
            shoppingCartStore?.set?.shoppingCarts([]);
            signUpStore?.set?.resetSignupStore();
            window.location.href = logout_redirect ?? '/login';
        } catch (e) {
            logger.error(e);
            setIsLoading(false);
        }
    };

    const handleLogout = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'profile-menu', Value: 'logout' },
        ]);

        setIsLoading(true);
        // set timeOut, so loader is shown for a split second
        // otherwise logout -> redirect happens immediately since authToken doesn't exist
        setTimeout(() => {
            logout();
        }, 1500);
    };

    const openGroupSwitch = () => setGroupSwitchOpenState(true);

    const closeGroupSwitch = () => setGroupSwitchOpenState(false);

    /** toggles user profile logout menu on desktop view */
    const toggleProfile = () => toggleProfileMenu();

    const viewProfile = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'profile-menu', Value: 'view-profile' },
        ]);
        toggleProfile();
    };

    const editProfile = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'profile-menu', Value: 'edit-profile' },
        ]);
        toggleProfile();
    };

    const profileQuickMenuViewProfile = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'profile-menu', Value: 'view-profile' },
        ]);
        closeModal();
    };

    const profileQuickMenuEditProfile = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'profile-menu', Value: 'edit-profile' },
        ]);
    };

    const profileQuickMenuSwitchGroups = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'profile-menu', Value: 'groups-menu' },
        ]);
        openGroupSwitch();
        toggleProfile();
    };

    const openProfileQuickMenu = () =>
        newModal(
            <MobileMenuList
                menuList={[
                    {
                        id: 1,
                        title: t(`common:${localNamespace}.view_profile`),
                        to: '/u/',
                        iconClass: 'we-icon-member',
                        // this should be unnecessary, but for some reason, the menu wasn't closing
                        // when clicking this link, so this is a quick fix for it
                        onClick: profileQuickMenuViewProfile,
                    },
                    {
                        id: 2,
                        title: t(`common:${localNamespace}.edit_profile`),
                        to: '/u/profile/Info',
                        iconClass: 'we-icon-edit',
                        onClick: profileQuickMenuEditProfile,
                    },
                    {
                        id: 3,
                        title: t(`common:${localNamespace}.switch_groups`),
                        onClick: profileQuickMenuSwitchGroups,
                        iconClass: 'we-icon-group',
                    },
                ]}
                showIcons
            />,
            { className: 'no-min-height' },
            { desktop: ModalTypes.None, mobile: ModalTypes.Center }
        );

    const closeAllMobileMenus = () => {
        closeGroupSwitch();
        closeModal();
    };

    const rightClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.preventDefault();

    const [src, srcSet] = getBackgroundImageSetForUrl(
        currentUser?.profile?.picture || getRandomDefaultUserImage()
    );

    const showDiscover = () => {
        newModal(<Discover />, { hideButton: true });
    };

    // If discover menu option is enabled on mobile, then always show notifications on the burger nav instead
    const showBurgerNotifications = navItems.mobile?.includes('discover');

    // Return loggedout UI if there is not a loggedin User
    if (!currentUser) {
        return (
            <div className="nav-secondary fadein">
                <div className="mn-bar">
                    <Link to={root_redirect} className="mn-button mobile-hide w-inline-block">
                        <div className="mn-icon">
                            <div className="content-button-icon home" />
                        </div>
                    </Link>
                </div>
            </div>
        );
    }

    return createPortal(
        <>
            <LoadingLine isLoading={isLoading} />
            <div className="nav-secondary fadein">
                <GroupNavigation />

                {showActionButton && (
                    <ActionButton className="secondary-nav-action-button mobile-hide animate-grow" />
                )}
                <div className={`mn-bar ${desktopSecondaryNavIsEmpty ? 'empty' : ''}`}>
                    {/* HAMBURGER MENU - Mobile Only */}
                    {showNavItem('navigator', 'mobile') && (
                        <SlideMenuNavButton
                            onClick={() => {
                                if (!isSlideMenuOpen) {
                                    // only send metric when menu is opening
                                    awsMetricPublisher.publishCount('secondary-navigation', 1, [
                                        { Name: 'menu', Value: 'side-menu' },
                                    ]);
                                }
                                closeAllMobileMenus();
                                toggleSlideMenu();
                            }}
                            notifications={showBurgerNotifications ? notifications : null}
                            onContextMenu={rightClick}
                        />
                    )}

                    <LegoNav />

                    {/* DASHBOARD BUTTON - Desktop & Mobile - open group menu if no active group */}
                    {showNavItem('groups') && (
                        <NavigationIcon
                            onClick={() => {
                                if (!isGroupSwitchOpen) {
                                    awsMetricPublisher.publishCount('secondary-navigation', 1, [
                                        { Name: 'menu', Value: 'groups-menu' },
                                    ]);
                                }
                                closeSlideMenu();
                                toggleGroupSwitch();
                            }}
                            onContextMenu={rightClick}
                            icon="group"
                            showMobile={showNavItem('groups', 'mobile')}
                            showDesktop={showNavItem('groups', 'desktop')}
                            groupSwitch
                        />
                    )}
                    {/* HOME BUTTON - Desktop & Mobile */}
                    {showNavItem('home') && (
                        <NavigationIcon
                            to={root_redirect_loggedin}
                            onClick={() => {
                                awsMetricPublisher.publishCount('secondary-navigation', 1, [
                                    { Name: 'menu', Value: 'home' },
                                ]);
                                closeSlideMenu();
                                closeAllMobileMenus();
                            }}
                            onContextMenu={rightClick}
                            icon="home"
                            showMobile={showNavItem('home', 'mobile')}
                            showDesktop={showNavItem('home', 'desktop')}
                        />
                    )}
                    {/* MESSAGES BUTTON - Desktop & Mobile */}
                    {showNavItem('messages') && (
                        <MessagingNavigationIcon
                            currentUser={currentUser}
                            onClick={() => {
                                awsMetricPublisher.publishCount('secondary-navigation', 1, [
                                    { Name: 'menu', Value: 'messages' },
                                ]);
                                closeGroupSwitch();
                            }}
                            showMobile={showNavItem('messages', 'mobile')}
                            showDesktop={showNavItem('messages', 'desktop')}
                        />
                    )}
                    {/* DISCOVER BUTTON - Desktop & Mobile */}
                    {!siloedUsers && showNavItem('discover') && (
                        <NavigationIcon
                            onClick={() => {
                                awsMetricPublisher.publishCount('secondary-navigation', 1, [
                                    { Name: 'menu', Value: 'discover' },
                                ]);
                                closeSlideMenu();
                                closeAllMobileMenus();
                                showDiscover();
                            }}
                            onContextMenu={rightClick}
                            icon="search"
                            showMobile={showNavItem('discover', 'mobile')}
                            showDesktop={showNavItem('discover', 'desktop')}
                        />
                    )}

                    {/* SEARCH BUTTON - Desktop & Mobile */}
                    {!siloedUsers && showNavItem('search') && (
                        <NavigationIcon
                            onClick={() => {
                                awsMetricPublisher.publishCount('secondary-navigation', 1, [
                                    { Name: 'menu', Value: 'search' },
                                ]);
                                closeSlideMenu();
                                closeAllMobileMenus();
                                searchStore.set.showSearch();
                            }}
                            onContextMenu={rightClick}
                            icon="search"
                            showMobile={showNavItem('search', 'mobile')}
                            showDesktop={showNavItem('search', 'desktop')}
                        />
                    )}

                    {/* NOTIFICATIONS BUTTON - Desktop & Mobile */}
                    {showNavItem('notifications') && (
                        <NavNotifications
                            hideLoader
                            onClickMobile={() => {
                                closeSlideMenu();
                                closeAllMobileMenus();
                            }}
                            showMobile={showNavItem('notifications', 'mobile')}
                            showDesktop={showNavItem('notifications', 'desktop')}
                        />
                    )}
                    {/* PROFILE BUTTON - Mobile & Desktop */}
                    {showNavItem('profile', 'mobile') && (
                        <button
                            type="button"
                            className="mn-button w-inline-block mobile-only secondary-nav-user-button"
                            onClick={() => {
                                awsMetricPublisher.publishCount('secondary-navigation', 1, [
                                    { Name: 'menu', Value: 'profile-menu' },
                                ]);

                                closeSlideMenu();
                                openProfileQuickMenu();
                                closeGroupSwitch();
                            }}
                            onContextMenu={rightClick}
                        >
                            <StyledBackgroundImage
                                className="nav-user-thumb"
                                src={src as string}
                                srcSet={srcSet as string}
                            />
                        </button>
                    )}
                    {showNavItem('profile', 'desktop') && (
                        <StyledBackgroundImage
                            id="profile-image-button"
                            className="nav-user-thumb desktop-only"
                            src={src as string}
                            srcSet={srcSet as string}
                            onClick={() => {
                                awsMetricPublisher.publishCount('secondary-navigation', 1, [
                                    { Name: 'menu', Value: 'profile-menu' },
                                ]);
                                toggleProfile();
                            }}
                            ref={profileImageRef}
                        />
                    )}
                    {isProfileMenuOpen && (
                        <OutsideClickHandler onOutsideClick={() => setProfileMenuOpenState(false)}>
                            <nav
                                id="profile-menu"
                                className="dropdown-align-right w-dropdown-list"
                                ref={profileMenuRef}
                            >
                                <div className="dropdown-list notification-list">
                                    <Link
                                        to="/u/"
                                        className="dropdown-link border-bottom w-dropdown-link"
                                        onClick={viewProfile}
                                    >
                                        {t(`common:${localNamespace}.view_profile`)}
                                    </Link>
                                    <Link
                                        to="/u/profile/Info"
                                        className="dropdown-link border-bottom w-dropdown-link"
                                        onClick={editProfile}
                                    >
                                        {t(`common:${localNamespace}.edit_profile`)}
                                    </Link>
                                    <button
                                        type="button"
                                        onClick={handleLogout}
                                        className="dropdown-link w-dropdown-link button-reset"
                                    >
                                        {t(`common:${localNamespace}.logout`)}
                                    </button>
                                </div>
                            </nav>
                        </OutsideClickHandler>
                    )}
                </div>
                <div className="bottom-mobile-spacer" />
            </div>
        </>,
        document.querySelector('#modal-root') as Element
    );
};

export default SecondaryNavigation;
