var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/useApolloClientPersistedCache.ts
import { useEffect, useState } from "react";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { RetryLink } from "@apollo/client/link/retry";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";
import { getMainDefinition } from "@apollo/client/utilities";
import { CachePersistor, LocalForageWrapper } from "apollo3-cache-persist";
import localForage from "localforage";

// src/possibleTypes.ts
var possibleTypes = {
  UserCore: ["User", "Group"],
  Pagination: [
    "PaginatedCards",
    "PaginatedUsers",
    "PaginatedMessages",
    "PaginatedGroups",
    "PaginatedTaskCompletion",
    "PaginatedTags",
    "PaginatedComments",
    "PaginatedMessageThreads",
    "PaginatedEngagementData",
    "PaginatedSearchResults",
    "PaginatedRecommendations"
  ],
  EngagementData: [
    "EngagementDataUser",
    "EngagementDataGroup",
    "EngagementDataNation",
    "EngagementDataRecruit"
  ],
  Entity: ["ContentCard", "User", "Group", "Comment", "Message"],
  Domain: ["ContentCard", "Group"],
  CommentUpdateObj: ["Comment", "CommentDelete"],
  AliasHandle: ["UserAliasHandle", "GroupAliasHandle", "AliasItem"],
  ContentCardUpdateObj: ["ContentCard", "ContentCardDelete"],
  MessageUpdateObj: ["Message", "MessageDelete"],
  MessageThreadUpdateObj: ["MessageThread", "MessageThreadDelete"],
  Recommendation: [
    "UserRecommendation",
    "GroupRecommendation",
    "CardRecommendation"
  ]
};
var possibleTypes_default = possibleTypes;

// ../welibrary-constants/dist/library_settings.js
var ComparatorType = /* @__PURE__ */ ((ComparatorType2) => {
  ComparatorType2["MultiAndPos"] = "MULTI_AND_POS";
  ComparatorType2["MultiOrPos"] = "MULTI_OR_POS";
  ComparatorType2["None"] = "NONE";
  ComparatorType2["ProximityFar"] = "PROXIMITY_FAR";
  ComparatorType2["ProximityNear"] = "PROXIMITY_NEAR";
  ComparatorType2["RangePos"] = "RANGE_POS";
  return ComparatorType2;
})(ComparatorType || {});
var CustomSignupFieldTypes = /* @__PURE__ */ ((CustomSignupFieldTypes2) => {
  CustomSignupFieldTypes2["ChooseRole"] = "choose_role";
  CustomSignupFieldTypes2["ConsentBox"] = "consent_box";
  CustomSignupFieldTypes2["Description"] = "description";
  CustomSignupFieldTypes2["Dropdown"] = "dropdown";
  CustomSignupFieldTypes2["JoinGroup"] = "joinGroup";
  CustomSignupFieldTypes2["Numberslider"] = "numberslider";
  CustomSignupFieldTypes2["Paragraph"] = "paragraph";
  CustomSignupFieldTypes2["ProfileLocation"] = "profile_location";
  CustomSignupFieldTypes2["ProfileOrganization"] = "profile_organization";
  CustomSignupFieldTypes2["Shortanswer"] = "shortanswer";
  CustomSignupFieldTypes2["Signature"] = "signature";
  CustomSignupFieldTypes2["UploadFile"] = "uploadFile";
  return CustomSignupFieldTypes2;
})(CustomSignupFieldTypes || {});
var ReferenceType = /* @__PURE__ */ ((ReferenceType2) => {
  ReferenceType2["Book"] = "book";
  ReferenceType2["Chapter"] = "chapter";
  ReferenceType2["Group"] = "group";
  ReferenceType2["Library"] = "library";
  ReferenceType2["Prompt"] = "prompt";
  ReferenceType2["Shelf"] = "shelf";
  ReferenceType2["User"] = "user";
  return ReferenceType2;
})(ReferenceType || {});
var FILTER_AZ = "az";
var FILTER_LIVE = "live";
var FILTER_TOP = "top";
var FILTER_LEADERBOARD = "leaderboard";
var FILTER_ORDERED = "ordered";
var FILTER_ARCHIVE = "archived";
var FILTER_OLDEST_FIRST = "oldestFirst";
var SORT_RECENT = "recentFirst";
var DISP_REF_LIBRARY = ReferenceType.Library;
var DISP_REF_SHELF = ReferenceType.Shelf;
var DISP_REF_BOOK = ReferenceType.Book;
var DISP_REF_CHAPTER = ReferenceType.Chapter;
var DISP_REF_PROMPT = ReferenceType.Prompt;
var DISP_REF_GROUP = ReferenceType.Group;
var DISP_REF_USER = ReferenceType.User;
var SIGNUP_FIELD_TYPE_DROPDOWN = CustomSignupFieldTypes.Dropdown;
var SIGNUP_FIELD_TYPE_SHORTANSWER = CustomSignupFieldTypes.Shortanswer;
var SIGNUP_FIELD_TYPE_PARAGRAPH = CustomSignupFieldTypes.Paragraph;
var SIGNUP_FIELD_TYPE_CONSENT_BOX = CustomSignupFieldTypes.ConsentBox;
var SIGNUP_FIELD_TYPE_CHOOSE_ROLE = CustomSignupFieldTypes.ChooseRole;
var SIGNUP_FIELD_TYPE_JOIN_GROUP = CustomSignupFieldTypes.JoinGroup;
var SIGNUP_FIELD_TYPE_LOCATION = CustomSignupFieldTypes.ProfileLocation;
var SIGNUP_FIELD_TYPE_ORGANIZATION = CustomSignupFieldTypes.ProfileOrganization;
var COMPARATOR_NONE = ComparatorType.None;
var COMPARATOR_MULTI_AND_POS = ComparatorType.MultiAndPos;
var COMPARATOR_MULTI_OR_POS = ComparatorType.MultiOrPos;
var COMPARATOR_RANGE_POS = ComparatorType.RangePos;
var COMPARATOR_PROXIMITY_NEAR = ComparatorType.ProximityNear;
var COMPARATOR_PROXIMITY_FAR = ComparatorType.ProximityFar;
var SIGNUP_FIELD_COMPARATORS = {
  [SIGNUP_FIELD_TYPE_SHORTANSWER]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [SIGNUP_FIELD_TYPE_PARAGRAPH]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [SIGNUP_FIELD_TYPE_DROPDOWN]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Sum of Matches, more is better" },
      { value: COMPARATOR_MULTI_OR_POS, display: "Any Match, more is same" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_MULTI_AND_POS
  },
  [SIGNUP_FIELD_TYPE_CONSENT_BOX]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [SIGNUP_FIELD_TYPE_CHOOSE_ROLE]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Sum of Matches, more is better" },
      { value: COMPARATOR_MULTI_OR_POS, display: "Any Match, more is same" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_MULTI_AND_POS
  },
  [SIGNUP_FIELD_TYPE_JOIN_GROUP]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [SIGNUP_FIELD_TYPE_LOCATION]: {
    options: [
      { value: COMPARATOR_PROXIMITY_NEAR, display: "Match users that are closer together" },
      { value: COMPARATOR_PROXIMITY_FAR, display: "Match users that are futher apart" }
    ],
    default: COMPARATOR_PROXIMITY_NEAR
  },
  [SIGNUP_FIELD_TYPE_ORGANIZATION]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Sum of Matches, more is better" },
      { value: COMPARATOR_MULTI_OR_POS, display: "Any Match, more is same" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_MULTI_AND_POS
  },
  [CustomSignupFieldTypes.Numberslider]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [CustomSignupFieldTypes.UploadFile]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [CustomSignupFieldTypes.Description]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [CustomSignupFieldTypes.Signature]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  }
};
var PERSONAL_INFO_FIELDS = {
  [SIGNUP_FIELD_TYPE_LOCATION]: {
    type: SIGNUP_FIELD_TYPE_LOCATION,
    customText: "Proximity",
    displayName: "Request Location"
  },
  [SIGNUP_FIELD_TYPE_ORGANIZATION]: {
    type: SIGNUP_FIELD_TYPE_ORGANIZATION,
    customText: "Organization",
    displayName: "Request Organization"
  }
};

// src/helpers/sort.ts
var sortHelpers = {
  getNestedReferencedCacheValue: (obj, field, readField, isDate) => {
    const fields = field?.split(".");
    if (fields.length === 1)
      return obj[field];
    let value = obj;
    fields.forEach((field2) => {
      if (field2 && readField)
        value = readField(field2, value);
      if (field2 && !readField && value.hasOwnProperty(field2))
        value = value[field2];
    });
    return isDate ? new Date(value) : value;
  },
  sortList: (list, gqlType, sortType, readField) => {
    if (!list)
      return list;
    switch (gqlType) {
      case `PaginatedCards`:
        return sortHelpers.sortContentCards(list, sortType, readField);
      case `PaginatedGroups`:
        return sortHelpers.getGroupSortOptions(list, sortType, readField);
      case `PaginatedUsers`:
        return sortHelpers.getUserSortOptions(list, sortType, readField);
      case `PaginatedComments`:
        return sortHelpers.getCommentSortOptions(list, sortType, readField);
      case `PaginatedTags`:
        return sortHelpers.getTagSortOptions(list, sortType, readField);
      case `PaginatedTaskCompletion`:
        return sortHelpers.getTaskSortOptions(list, sortType, readField);
      case `PaginatedMessageThreads`:
        return sortHelpers.getMessageThreadSortOptions(
          list,
          FILTER_LIVE,
          readField
        );
      case `PaginatedMessages`:
      default:
        return list;
    }
  },
  sortContentCards: (list, sortType, readField) => {
    let isCursorDate = false;
    let cursorComparator = 1;
    let cursorField = "title";
    if (sortType == FILTER_TOP) {
      cursorField = "ratings_rank";
      cursorComparator = -1;
    } else if (sortType == FILTER_LIVE) {
      cursorField = "date.publish";
      cursorComparator = -1;
      isCursorDate = true;
    } else if (sortType == FILTER_OLDEST_FIRST) {
      cursorField = "date.publish";
      cursorComparator = 1;
      isCursorDate = true;
    } else if (sortType == FILTER_AZ || sortType == FILTER_ARCHIVE) {
      cursorField = "title";
      cursorComparator = 1;
    } else if (sortType == FILTER_LEADERBOARD) {
      cursorField = "ratings_rank";
      cursorComparator = -1;
    } else if (sortType == FILTER_ORDERED) {
      cursorField = "rankOrder";
      cursorComparator = -1;
    } else if (sortType == "reverseOrder") {
      cursorField = "rankOrder";
      cursorComparator = 1;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getTagSortOptions: (list, sortType, readField) => {
    let isCursorDate = true;
    let cursorComparator = 1;
    let cursorField = "updated";
    if (sortType == FILTER_LIVE) {
      cursorField = "updated";
      cursorComparator = 1;
      isCursorDate = true;
    } else if (sortType == FILTER_AZ) {
      cursorField = "label";
      cursorComparator = 1;
      isCursorDate = false;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getUserSortOptions: (list, sortType, readField) => {
    let isCursorDate = false;
    let cursorComparator = 1;
    let cursorField = "profile.full_name";
    if (sortType == FILTER_LIVE) {
      cursorField = "createdAt";
      cursorComparator = 1;
      isCursorDate = true;
    } else if (sortType == FILTER_AZ) {
      cursorField = "profile.full_name";
      cursorComparator = 1;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getGroupSortOptions: (list, sortType, readField) => {
    let isCursorDate = false;
    let cursorComparator = 1;
    let cursorField = "profile.full_name";
    if (sortType == FILTER_LIVE) {
      cursorField = "createdAt";
      cursorComparator = 1;
      isCursorDate = true;
    } else if (sortType == FILTER_AZ) {
      cursorField = "profile.full_name";
      cursorComparator = 1;
    } else if (sortType == SORT_RECENT) {
      cursorField = "currentUserLastActiveDate";
      cursorComparator = 1;
      isCursorDate = true;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getCommentSortOptions: (list, sortType, readField) => {
    let isCursorDate = true;
    let cursorComparator = 1;
    let cursorField = "publish";
    if (sortType == FILTER_LIVE) {
      cursorField = "publish";
      cursorComparator = -1;
      isCursorDate = true;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getTaskSortOptions: (list, sortType, readField) => {
    let isCursorDate = true;
    let cursorComparator = 1;
    let cursorField = "date";
    if (sortType == FILTER_LIVE) {
      cursorField = "date";
      cursorComparator = -1;
      isCursorDate = true;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getMessageThreadSortOptions: (list, sortType, readField) => {
    let isCursorDate = true;
    let cursorComparator = -1;
    let cursorField = "updated";
    if (sortType == FILTER_LIVE) {
      cursorField = "updated";
      cursorComparator = -1;
      isCursorDate = true;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  }
};

// src/helpers/pagination.ts
var mergeArray = /* @__PURE__ */ __name((existing = [], incoming, deduplicate = true) => {
  const existingIds = deduplicate ? existing.map((item) => item.__ref) : [];
  let uniqIncoming = deduplicate ? incoming.filter((item) => !existingIds.includes(item.__ref)) : incoming;
  return [...existing, ...uniqIncoming];
}, "mergeArray");
var replaceExistingArray = /* @__PURE__ */ __name((_existing = [], incoming) => {
  return incoming;
}, "replaceExistingArray");
var weaveIncomingIntoExisting = /* @__PURE__ */ __name((existing = [], incoming = [], maxDepth = 2, deduplicate = true) => {
  const existingIds = deduplicate ? existing.map((item) => item.__ref) : [];
  const uniqIncoming = deduplicate ? incoming.filter((item) => !existingIds.includes(item.__ref)) : incoming;
  maxDepth = Math.min(maxDepth, Math.min(existing.length, uniqIncoming.length));
  let weavedArray = [];
  for (let x = 0; x < maxDepth; x++) {
    weavedArray = [...weavedArray, uniqIncoming[x], existing[x]];
  }
  return [
    ...weavedArray,
    ...uniqIncoming.slice(maxDepth),
    ...existing.slice(maxDepth)
  ];
}, "weaveIncomingIntoExisting");
var mergeBlacklistPaginationObject = /* @__PURE__ */ __name((keyArgs, blacklistField = "cardBlacklist") => {
  return {
    keyArgs,
    merge: (existing = [], incoming = [], { args }, deduplicate = true) => {
      if (!args?.[blacklistField]) {
        const result = weaveIncomingIntoExisting(existing, incoming ?? [], 3);
        return result;
      }
      const existingIds = deduplicate ? existing.map((item) => item.__ref) : [];
      const uniqIncoming = deduplicate ? incoming?.filter((item) => !existingIds.includes(item.__ref)) : incoming;
      return [...existing, ...uniqIncoming];
    }
  };
}, "mergeBlacklistPaginationObject");
var mergePaginationObject = /* @__PURE__ */ __name((arrayField, keyArgs, gqlType, sortField) => {
  return {
    ...keyArgs && { keyArgs },
    merge: (existing = {}, incoming, { args, readField }) => {
      if (!args?.cursor) {
        return incoming;
      }
      const prevArray = existing[arrayField] ? [...existing[arrayField]] : [];
      const incomingArray = incoming[arrayField] ? [...incoming[arrayField]] : [];
      const newObj = { ...existing, ...incoming };
      const sortType = typeof sortField === "boolean" ? sortField : args && sortField && args[sortField];
      newObj[arrayField] = mergeArray(prevArray, incomingArray, true);
      if (gqlType && sortType) {
        newObj[arrayField] = sortHelpers.sortList(
          newObj[arrayField],
          gqlType,
          sortType,
          readField
        );
      }
      return newObj;
    }
  };
}, "mergePaginationObject");

// src/typePolicies.ts
var nullable = /* @__PURE__ */ __name(() => {
  return {
    read(existing = null) {
      return existing;
    }
  };
}, "nullable");
var typePolicies = {
  Organization: {
    fields: {
      _id: nullable(),
      name: nullable(),
      created: nullable(),
      createdBy: nullable(),
      updated: nullable()
    }
  },
  UserLocation: {
    fields: {
      formatted_address: nullable(),
      location: nullable(),
      city: nullable(),
      state: nullable(),
      country: nullable(),
      postal: nullable()
    }
  },
  Query: {
    fields: {
      myGroups: mergePaginationObject(
        "results",
        ["searchQuery", "role", "protectionLevel", "sortType"],
        "PaginatedGroups",
        "sortType"
      ),
      paginatedGroupTemplates: mergePaginationObject("results", [
        "searchQuery",
        "sortType",
        "subtypes"
      ]),
      userFeed: mergePaginationObject(
        "results",
        ["_id", "sortType"],
        "PaginatedCards",
        "sortType"
      ),
      paginatedNewsFeed: mergePaginationObject(
        "results",
        ["filterIds", "sortType", "groupId"],
        "PaginatedCards",
        "sortType"
      ),
      paginatedChildrenFromCard: mergePaginationObject(
        "results",
        ["url", "sortType", "tags"],
        "PaginatedCards",
        "sortType"
      ),
      getTemplatesAvailableToUser: mergePaginationObject("results", [
        "sortType",
        "type"
      ]),
      myBookmarks: mergePaginationObject(
        "results",
        ["searchQuery", "sortType"],
        "PaginatedCards",
        "sortType"
      ),
      paginatedListCards: mergePaginationObject("results", ["searchQuery"]),
      myPledges: mergePaginationObject(
        "results",
        ["searchQuery", "sortType"],
        "PaginatedCards",
        "sortType"
      ),
      myEvents: mergePaginationObject(
        "results",
        ["searchQuery", "sortType"],
        "PaginatedCards",
        "sortType"
      ),
      myInterestedEvents: mergePaginationObject(
        "results",
        [],
        "PaginatedEvents"
      ),
      myBookmarkedEvents: mergePaginationObject(
        "results",
        ["sortType"],
        "PaginatedEvents"
      ),
      getEventsInMyGroups: mergePaginationObject(
        "results",
        ["sortType"],
        "PaginatedEvents"
      ),
      getMyEvents: mergePaginationObject(
        "results",
        ["sortType"],
        "PaginatedEvents"
      ),
      childrenFromCard: {
        merge: mergeArray
      },
      myCommunity: mergePaginationObject(
        "results",
        ["searchQuery", "sortType", "type"],
        "PaginatedUsers",
        "sortType"
      ),
      getUserMessageThreads: mergePaginationObject(
        "threads",
        ["userId", "hasUnreadMessages"],
        "PaginatedMessageThreads",
        true
      ),
      searchUserMessageThreads: mergePaginationObject(
        "threads",
        ["userId", "hasUnreadMessages", "searchQuery"],
        "PaginatedMessageThreads",
        true
      ),
      getMessagesForThread: mergePaginationObject("messages", ["threadId"]),
      commentsForCard: mergePaginationObject(
        "results",
        ["url"],
        "PaginatedComments",
        "sortType"
      ),
      paginatedChildComments: mergePaginationObject(
        "results",
        ["_id"],
        "PaginatedComments",
        "sortType"
      ),
      getChildrenAccounts: mergePaginationObject(
        "results",
        ["_id"],
        "PaginatedUsers",
        "sortType"
      ),
      getChildrenAccountsForCode: mergePaginationObject(
        "results",
        ["code"],
        "PaginatedUsers"
      ),
      getTaskHistory: mergePaginationObject(
        "results",
        ["type", "cardId", "sortType", "pledgeKeyId"],
        "PaginatedTaskCompletion",
        "sortType"
      ),
      getStreamGroupFeed: mergePaginationObject("results", ["groupId"]),
      notificationsForLoggedInUser: {
        merge: false
      },
      listUsers: {
        merge: replaceExistingArray
      },
      listMembers: {
        merge: replaceExistingArray
      },
      listCards: {
        merge: replaceExistingArray
      },
      books: {
        merge: mergeArray
      },
      booksFromShelf: {
        merge: mergeArray
      },
      shelves: {
        merge: mergeArray
      },
      newsFeed: {
        merge: mergeArray
      },
      getPledgesForGroup: {
        merge: replaceExistingArray
      },
      getRandomEngagementCardSampling: mergeBlacklistPaginationObject([
        "groupIds",
        "userId"
      ]),
      recommendationsForUser: {
        keyArgs: ["input", ["types", "filters"]],
        merge: (existing = [], incoming, { args, readField }) => {
          return {
            ...existing,
            ...incoming,
            recommendations: mergePaginationObject(
              "recommendations",
              null,
              "PaginatedRecommendations"
            )?.merge?.(existing?.recommendations, incoming?.recommendations, {
              args: args?.input,
              readField
            })
          };
        }
      },
      getOrdersForLoggedInUser: {
        keyArgs: ["statuses"],
        merge: replaceExistingArray
      },
      getOrdersForUserForGroup: {
        keyArgs: ["groupId", "status"],
        merge: replaceExistingArray
      }
    }
  },
  Group: {
    fields: {
      profile: {
        merge: true
      },
      settings: {
        merge: true
      },
      childGroups: mergePaginationObject(
        "results",
        ["sortType"],
        "PaginatedGroups",
        "sortType"
      ),
      dashboard: mergePaginationObject(
        "results",
        ["sortType", "subtype"],
        "PaginatedCards",
        "sortType"
      ),
      tableOfContents: mergePaginationObject(
        "results",
        ["sortType"],
        "PaginatedCards",
        "sortType"
      )
    }
  },
  User: {
    fields: {
      roleForGroup: nullable(),
      profile: {
        merge: true
      },
      roles: {
        merge: true
      },
      groups: mergePaginationObject(
        "results",
        ["sortType"],
        "PaginatedGroups",
        "sortType"
      ),
      createdContent: {
        merge: mergeArray
      }
    }
  },
  TranslationKeypairs: {
    keyFields: false
  },
  CustomSignupField: {
    keyFields: false,
    fields: {
      options: {
        merge: replaceExistingArray
      }
    }
  },
  CustomField: {
    keyFields: false,
    fields: {
      values: {
        merge: replaceExistingArray
      }
    }
  },
  AliasItem: {
    keyFields: ["url", "title"],
    fields: {
      item: {
        merge: true
      }
    }
  },
  ContentCard: {
    fields: {
      media: {
        merge: true
      },
      library_settings: {
        merge: true
      },
      children: {
        keyArgs: false,
        merge: replaceExistingArray
      },
      date: {
        merge: true
      },
      links: {
        merge: true
      },
      location: {
        merge: true
      },
      promo: {
        merge: true
      },
      label: {
        merge: true
      },
      vote_settings: {
        merge: true
      },
      loggedInUserVotes: {
        merge: true
      },
      reference: {
        merge: true
      },
      currentUserViewPermissions: {
        merge: true
      },
      createFeatureConstraints: {
        merge: true
      },
      updateFeatureConstraints: {
        merge: true
      },
      electionSettings: {
        merge: true
      },
      mobilizationSettings: {
        merge: true
      },
      postingSettings: {
        merge: true
      },
      applicationItems: {
        merge: true
      },
      formSpecificationSettings: {
        merge: true
      },
      cardActionsAllowed: {
        merge: replaceExistingArray
      },
      rolesAvailable: {
        merge: replaceExistingArray
      },
      courseItems: {
        merge: replaceExistingArray
      }
    }
  },
  UserCourse: { fields: { currentState: { merge: false } } },
  CustomSignupFieldDependsOn: {
    keyFields: false
  }
};
var typePolicies_default = typePolicies;

// src/meteor-proxy.ts
var MeteorProxy = class {
  constructor() {
  }
  static absoluteUrl(path, options = {}) {
    if (!options && typeof path === "object") {
      options = path;
      path = void 0;
    }
    let url = options.rootUrl;
    let { server: isApiCall = false } = options;
    if (window.origin.includes("deploy-preview") && !isApiCall) {
      url = window.origin;
    }
    if (!url) {
      throw new Error(
        "Must pass options.rootUrl or set ROOT_URL in the server environment"
      );
    }
    if (!/^http[s]?:\/\//i.test(url)) {
      url = `http://${url}`;
    }
    if (!url.endsWith("/")) {
      url += "/";
    }
    if (path) {
      while (path.startsWith("/")) {
        path = path.slice(1);
      }
      url += path;
    }
    if (options.secure && /^http:/.test(url) && !/http:\/\/localhost[:\/]/.test(url) && !/http:\/\/127\.0\.0\.1[:\/]/.test(url)) {
      url = url.replace(/^http:/, "https:");
    }
    if (options.replaceLocalhost) {
      url = url.replace(/^http:\/\/localhost([:\/].*)/, "http://127.0.0.1$1");
    }
    return url;
  }
  static _storedLoginToken() {
    return localStorage.getItem(this.LOGIN_TOKEN_KEY);
  }
};
__name(MeteorProxy, "MeteorProxy");
MeteorProxy.LOGIN_TOKEN_KEY = "wlbLoginToken";
var meteor_proxy_default = MeteorProxy;

// src/useApolloClientPersistedCache.ts
var SCHEMA_VERSION = "3.0.3";
var SCHEMA_VERSION_KEY = "apollo-schema-version";
var useApolloClientPersistedCache = /* @__PURE__ */ __name(({
  apiUrl,
  root_url,
  version,
  disablePersistedCache = false,
  logout_redirect,
  isMobileApp,
  getAuthToken,
  unsetAuthToken,
  log,
  debugLog
}) => {
  const [client, setClient] = useState();
  useEffect(() => {
    const httpLink = new HttpLink({
      uri: meteor_proxy_default.absoluteUrl("graphql", {
        rootUrl: apiUrl,
        server: true,
        secure: true
      }),
      credentials: "same-origin"
    });
    const persistedQueryLink = createPersistedQueryLink({ sha256 });
    const websocketHost = apiUrl ? apiUrl : window.location.host;
    const baseUri = websocketHost.includes("localhost") || websocketHost.includes("amazonaws") ? `ws://${websocketHost}/graphql` : `wss://${websocketHost}/graphql`;
    const wsLink = new WebSocketLink({
      uri: baseUri,
      options: {
        reconnect: true,
        lazy: true
      }
    });
    wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () => wsLink.subscriptionClient.maxConnectTimeGenerator.max;
    const retryLink = new RetryLink();
    const splitLink = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
      },
      wsLink,
      persistedQueryLink.concat(httpLink)
    );
    const authLink = new ApolloLink((operation, forward) => {
      const token = getAuthToken();
      operation.setContext(() => ({
        headers: {
          Authorization: token ? `Bearer ${token}` : ""
        }
      }));
      return forward(operation);
    });
    const gqlClientName = `${isMobileApp ? "mobile" : "web"}: ${root_url}`;
    const gqlClientVersion = version || "3.0.0";
    const cache = new InMemoryCache({
      addTypename: true,
      possibleTypes: possibleTypes_default,
      typePolicies: typePolicies_default
    });
    const client2 = new ApolloClient({
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.map(
              ({ message, locations, path }) => log?.(
                `[GraphQL error]: Message: ${message}, Path: ${path}`,
                locations
              )
            );
          }
          if (networkError) {
            if (networkError?.response?.status === 401) {
              unsetAuthToken();
              client2.clearStore().then(() => {
                window.location.href = logout_redirect ?? "/login";
              });
            }
            log?.(`[Network error]: ${JSON.stringify(networkError, null, 4)}`);
          }
        }),
        retryLink,
        authLink,
        splitLink
      ]),
      name: gqlClientName,
      version: gqlClientVersion,
      cache,
      connectToDevTools: true
    });
    const persistor = new CachePersistor({
      cache,
      maxSize: 2e6,
      storage: new LocalForageWrapper(localForage)
    });
    const restoreOrPurgeCache = /* @__PURE__ */ __name((currentVersion) => {
      if (!disablePersistedCache && currentVersion === SCHEMA_VERSION) {
        persistor.restore().then(() => {
          debugLog?.(
            `Persisted Cache [${SCHEMA_VERSION_KEY}] v${SCHEMA_VERSION}: Loaded!`
          );
          setClient(client2);
          client2.onResetStore(async () => persistor.purge());
          client2.onClearStore(async () => persistor.purge());
        });
      } else {
        persistor.purge().then(() => {
          debugLog?.(
            `Persisted Cache [${SCHEMA_VERSION_KEY}] NEW v${SCHEMA_VERSION}. v${currentVersion} Purged!`
          );
          setClient(client2);
          localForage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
          client2.onResetStore(async () => persistor.purge());
          client2.onClearStore(async () => persistor.purge());
        });
      }
    }, "restoreOrPurgeCache");
    localForage.getItem(SCHEMA_VERSION_KEY).then((currentVersion) => {
      restoreOrPurgeCache(currentVersion ?? SCHEMA_VERSION);
    }).catch((err) => {
      log?.(`Could not read ${SCHEMA_VERSION_KEY} from localForage`, err);
      restoreOrPurgeCache("-1");
    });
    return () => {
    };
  }, []);
  return { client };
}, "useApolloClientPersistedCache");
export {
  useApolloClientPersistedCache
};
