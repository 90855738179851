import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { awsMetricPublisher } from '@welibraryos/metrics';
import { useFlags } from 'launchdarkly-react-client-sdk';
import searchStore from '@stores/Search';
import { config } from '@config/getConfig';
import ActionButton from '@components/navigation/ActionButton';
import useNavigation from '@components/navigation/useNavigation';

import { ICONS } from '@core/utilities/constants/icons';

const ROOT_HISTORY_PATHS = [
    { path: '/dashboard', exact: false },
    { path: '/login', exact: false },
    { path: '/signup', exact: false },
    { path: '/join', exact: false },
    { path: '/signup-group', exact: false },
    { path: '/add-group', exact: false },
    { path: '/bigscreen', exact: false },
    { path: '/fs', exact: false },
    { path: '/admin', exact: false },
    { path: '/u', exact: true },
    { path: '/u/', exact: true },
    { path: '/home', exact: false },
    { path: '/connections', exact: false },
    { path: '/connections', exact: false },
    { path: '/discover', exact: false },
    { path: '/newsfeed', exact: false },
    { path: '/verify-email', exact: false },
];

const PrimaryNav = ({
    currentUser,
    showSignup,
    showLogin,
    displayHeaderImage,
    displayHeaderImageWhite,
    hideOnMobile,
    enableBackButton,
    showActionButton,
    showDiscover,
}) => {
    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();
    const { toggleSlideMenu, isSlideMenuOpen } = useNavigation();

    const flags = useFlags();
    const rootGroupId = config?.public?.rootGroupId;
    const enableLogoClickToGroup = flags?.enableLogoClickToGroup;

    const handleBack = () => {
        if (history?.location?.fromSearch) {
            searchStore.set.showSearch();
        } else history.goBack();
    };

    const handleMetricAndToggleSlideMenu = () => {
        if (!isSlideMenuOpen) {
            // only send metric when menu is opening
            awsMetricPublisher.publishCount('primary-navigation-open-side-menu', 1);
        }
        toggleSlideMenu();
    };

    // Solves problem where hitting the back button can get a user into an infinite loop when navigating to profile info from slide menu
    useEffect(() => {
        if (location?.pathname?.includes('/u/profile/info') && !location?.state?.fromSlideMenu) {
            history.goBack();
        }
    }, [location]);

    const locationInRootHistoryPaths = ROOT_HISTORY_PATHS.some(({ exact, path }) => {
        return exact ? location.pathname === path : location.pathname.includes(path);
    });

    const showBack = enableBackButton && !locationInRootHistoryPaths && history.length > 0;

    const logoLink = enableLogoClickToGroup ? `/g/${rootGroupId}` : '/';

    return (
        <div
            className={`mn-bar primary loggedout primary-bar ${hideOnMobile ? 'desktop-only' : ''}`}
            style={hideOnMobile ? undefined : { display: 'flex' }}
        >
            {currentUser !== null && (
                <div className="nav-menu-trigger-container logged-out">
                    <div
                        className="open-nav-trigger"
                        onClick={
                            currentUser
                                ? handleMetricAndToggleSlideMenu
                                : () => history.push('/join')
                        }
                        style={{ display: 'flex' }}
                        data-ix="nav-open"
                    >
                        <div className="line-1" />
                        <div className="line-2 width-animate" />
                        <div className="line-3" />
                    </div>
                </div>
            )}
            <section className="primary-nav-logo-and-button">
                {enableBackButton && showBack && (
                    <div className="nav-menu-back-button">
                        <button
                            type="button"
                            onClick={handleBack}
                            className="nav-back-button w-inline-block button-reset"
                            aria-label="Back"
                        />
                    </div>
                )}
                <div
                    className={`no-background-override primary-nav-logo ${
                        showDiscover ? '' : 'center'
                    }`}
                    style={{ display: 'block' }}
                >
                    <div className="nav-primary-left loggedout" style={{ zIndex: '999' }}>
                        <Link
                            to={logoLink}
                            className="mn-brand loggedout w-inline-block"
                            style={{ backgroundImage: `url(${displayHeaderImageWhite})` }}
                        >
                            <img
                                src={displayHeaderImage}
                                alt=""
                                className="brand-spacer"
                                style={{ height: '30px', opacity: 0 }}
                            />
                            <div className="brand-3" />
                        </Link>
                    </div>
                </div>
            </section>

            <section className="primary-nav-buttons mobile-only">
                {showDiscover && (
                    <button
                        type="button"
                        onClick={searchStore.set.showSearch}
                        className="primary-nav-notifications mobile-only"
                    >
                        <img src={ICONS.searchWhite} alt="search" />
                    </button>
                )}

                {showActionButton && <ActionButton />}
            </section>

            {(showLogin || showSignup) && (
                <div className="mn-mobile-right logged-out-links">
                    {showLogin && !currentUser && (
                        <Link name="login" to="/login" className="mn-link w-inline-block">
                            <div>{t('common:global.form.login')}</div>
                        </Link>
                    )}
                    {showSignup && !currentUser && (
                        <Link name="login" to="/join" className="mn-link w-inline-block">
                            <div>{t('common:global.form.sign_up')}</div>
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
};

export default PrimaryNav;
