import { useQuery } from '@apollo/client';

import { Group } from '@shared/welibrary-graphql/types';
import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';

const useGroupPublicDashfeed: React.FC<{ group: Group }> = group => {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    if (!group) throw 'Group is required';

    // query public dashfeed
    const {
        loading,
        error,
        data: feedData,
    } = useQuery(GET_GROUP_DASHBOARD, {
        variables: {
            _id: group?._id,
            suppressMembersDashboard: true,
            suppressPublicDashboard: false,
            suppressChildGroups: true,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !group?._id,
    });

    const dashboardData = feedData;
    const dashboardGroup = dashboardData?.groupById;

    const publicDashboardData = dashboardData?.groupById?.publicDashboard;
    const publicDashboardSource = dashboardData?.groupById?.publicDashboardSource;
    const dashboardSource = publicDashboardSource;

    const dashfeedData = publicDashboardData;

    return {
        loading,
        error,
        dashboardGroup,
        dashboardSource,
        dashfeedData,
    };
};

export default useGroupPublicDashfeed;
