import {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';

import {
    MARK_CARDS_READ_FOR_LOGGED_IN_USER,
    MARK_CARDS_UNREAD_FOR_LOGGED_IN_USER,
} from '@shared/welibrary-graphql/content_card/mutations';

import useOnScreen from '@web/ui/components/generic/hooks/useOnScreen';

/**
 * React hook for handling the logic of card receipts data management
 * This hook can be used with an onscren trigger, or manually fired.
 *
 * Use like so:
 *
 * const { markAllCardsRead, markAllCardsUnread, setRef } = useCardReceipts({ cardIds: [ID] });
 *
 * const display = (<Example ref={setRef} />)
 *
 * return <Container>{display}</Container>
 *
 * Or like so:
 *
 * const ref = useRef();
 * useCardReceipts({ cardIds: [ID], onScreenRef: ref });
 *
 * const display = (<Example ref={ref} />)
 *
 * return <Container>{display}</Container>
 *
 * @param {{
 *     cardIds: [ID],
 *     onScreenRef: ref,
 *     visibleOffset: number|string,
 *     throttleTime: number
 *   }}
 */
const useCardReceipts = ({ cardIds, onScreenRef, visibleOffset = 0, throttleTime = 100 }) => {
    const [requestTriggeringElementRef, setRef] = useState(onScreenRef);
    const [cardsMarkedRead, setCardsMarkedRead] = useState([]);
    const [cardsMarkedUnread, setCardsMarkedUnread] = useState([]);

    const [markCardsReadForLoggedInUser] = useMutation(MARK_CARDS_READ_FOR_LOGGED_IN_USER);
    const [markCardsUnreadForLoggedInUser] = useMutation(MARK_CARDS_UNREAD_FOR_LOGGED_IN_USER);

    const { onScreen } = useOnScreen({
        ref: requestTriggeringElementRef,
        offset: visibleOffset,
        throttleTime,
    });

    const markAllCardsRead = async specificCardIds => {
        if (!specificCardIds && !cardIds) return;
        const cardsIdsToMark = (specificCardIds || cardIds)?.filter(
            _id => !cardsMarkedRead.includes(_id)
        );
        if (cardsIdsToMark?.length === 0) return;
        return markCardsReadForLoggedInUser({
            variables: {
                cardIds: cardsIdsToMark,
            },
        }).then(() => {
            setCardsMarkedRead([...cardsMarkedRead, ...cardsIdsToMark]);
        });
    };

    const markAllCardsUnread = async specificCardIds => {
        if (!specificCardIds && !cardIds) return;
        const cardsIdsToMark = (specificCardIds || cardIds)?.filter(
            _id => !cardsMarkedUnread.includes(_id)
        );
        if (cardsIdsToMark?.length === 0) return;
        return markCardsUnreadForLoggedInUser({
            variables: {
                cardIds: specificCardIds || cardIds,
            },
        }).then(() => {
            setCardsMarkedUnread([...cardsMarkedUnread, ...cardsIdsToMark]);
        });
    };

    useEffect(() => {
        if (requestTriggeringElementRef && onScreen) markAllCardsRead();
    }, [requestTriggeringElementRef, onScreen]);

    useEffect(() => {
        if (onScreenRef) {
            setRef(onScreenRef);
        }
    }, [onScreenRef]);

    return { markAllCardsRead, markAllCardsUnread, setRef };
};

export default useCardReceipts;
