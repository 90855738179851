import React from 'react';
import useMentionsSelect from '@components/mentions/useMentions';
import { MinimalEditor } from '@welibraryos/welibrary-editor';

import TwitterEmbed from '../embeds/TwitterEmbed';

import { client } from '@web/ui/components/generic/ReactFileStackClient';
import { config } from '@core/config/getConfig';
import { mediaRegex } from '~/wl-core/utilities/constants/regexes';

const fileStackConfig = config.public.filestack;
const filestack = client.init(fileStackConfig.api_key, { sessionCache: false });

type LightEditorProps = {
    onChange: (value: () => string) => void;
    placeholder: string;
    className?: string;
    defaultValue?: string; // used for initializing editor with initial value
    editorKey?: number; // used for clearing editor
    mentionsUsers?: any;
    maxLength?: number;
    autoFocus?: boolean;
    onFileUpload?: (file: File) => void;
    onSave?: (args: { done: boolean }) => void;
    mentionsGroupId?: string;
};

/* This is the version used in comment and DM inputs, it is lacking the fixed formatting menu */
const LightEditor: React.FC<LightEditorProps> = ({
    mentionsUsers,
    className = 'comment-field message-input-field w-input thread-textarea',
    onChange,
    placeholder,
    defaultValue,
    autoFocus = false,
    editorKey,
    maxLength = 500,
    onFileUpload,
    onSave,
    mentionsGroupId,
}) => {
    const { setSearchString, searchResults } = useMentionsSelect(mentionsUsers, mentionsGroupId);
    return (
        <MinimalEditor
            defaultValue={defaultValue ?? ''}
            participants={searchResults}
            onMentionInput={setSearchString}
            key={editorKey}
            className={className}
            maxLength={maxLength}
            onChange={onChange}
            minimal
            placeholder={placeholder}
            autoFocus={autoFocus}
            handleImageUploadOverride={onFileUpload}
            onSave={onSave}
            embeds={[
                {
                    title: 'Twitter',
                    keywords: 'twitter tweet',
                    icon: () => (
                        <img
                            alt="Twitter embed"
                            src="https://upload.wikimedia.org/wikipedia/commons/7/75/YouTube_social_white_squircle_%282017%29.svg"
                            width={24}
                            height={24}
                        />
                    ),
                    matcher: url => {
                        return url.match(mediaRegex.tweet);
                    },
                    component: TwitterEmbed,
                },
            ]}
        />
    );
};

export default LightEditor;
