import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

/**
 * React hook for handling debounced state updates that require fast UI updates
 *
 * Use like so:
 *
 * const [text, actualText, setText] = useDebouncedState('');
 *
 * return (
 *     <div>
 *         <h3>{`Real text: ${actualText}`}</h3>
 *         <input onChange={setText} value={text} />
 *     </div>
 * )
 */
const useDebouncedState = <T>(initialState: T, wait = 300) => {
    const [quickState, setQuickState] = useState(initialState);
    const [slowState, setSlowState] = useState(initialState);

    const debouncedSetSlowState = useCallback(_.debounce(setSlowState, wait), []);

    useEffect(() => {
        debouncedSetSlowState(quickState);
    }, [quickState]);

    return [quickState, slowState, setQuickState] as const;
};

export default useDebouncedState;
