import React, { useEffect } from 'react';

type TwitterEmbedProps = {
    attrs: { matches: string[] };
    isSelected: boolean;
};

const TwitterEmbed: React.FC<TwitterEmbedProps> = ({ attrs, isSelected }) => {
    const tweetUrl = attrs.matches[0].replace('x.com', 'twitter.com'); // Twitter embeds currently broken with x.com URLs 🤦

    useEffect(() => {
        (window as any).twttr?.widgets?.load?.();
    });

    return (
        <blockquote className={`twitter-tweet ${isSelected ? 'ProseMirror-selectednode' : ''}`}>
            {/* eslint-disable-next-line */}
            <a href={tweetUrl} />
        </blockquote>
    );
};

export default TwitterEmbed;
