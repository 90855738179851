import React from 'react';
import { Updater } from 'use-immer';

import useModal from '@components/modals/hooks/useModal';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@stores/User';

import ImagePicker from '@components/content/ImagePicker';
import Picture from '@components/generic/Picture';
import CameraIcon from '@dsc/svgs/CameraIcon';

const localNamespace = 'imports.wlWeb.ui.thumbAndCoverUpload';

type ThumbnailAndCoverImageUploadProps = {
    thumb: string;
    setThumb: Updater<string>;
    cover: string;
    setCover: Updater<string>;
    coverOnly?: boolean;
    thumbOnly?: boolean;
};

const ThumbnailAndCoverImageUpload: React.FC<ThumbnailAndCoverImageUploadProps> = ({
    thumb,
    setThumb,
    cover,
    coverOnly,
    thumbOnly,
    setCover,
}) => {
    const { newModal } = useModal();

    const selectThumb = () => {
        newModal(<ImagePicker onImageSelect={setThumb} />, {
            className: 'image-picker-modal no-bottom-padding full-height',
        });
    };

    const selectCover = () => {
        newModal(<ImagePicker onImageSelect={setCover} />, {
            className: 'image-picker-modal no-bottom-padding full-height',
        });
    };

    return (
        <div className="images-selector-container">
            {!thumbOnly && (
                <section className="edit-cover-container">
                    <Picture url={cover} disableLink>
                        <button
                            type="button"
                            className="add-image-button edit-image-button"
                            onClick={selectCover}
                            aria-label="Edit Cover Image"
                        />
                    </Picture>
                </section>
            )}
            {!coverOnly && (
                <section className="edit-thumbnail-container">
                    <Picture url={thumb} disableLink resolutions={[100, 200, 300]}>
                        <button
                            type="button"
                            className="add-image-button edit-image-button"
                            onClick={selectThumb}
                            aria-label="Edit Thumbnail"
                        />
                    </Picture>
                </section>
            )}
        </div>
    );
};

// new layout used in new Sign Up flow
export const NewThumbnailAndCoverImageUpload: React.FC<ThumbnailAndCoverImageUploadProps> = ({
    thumb,
    setThumb,
    cover,
    coverOnly,
    thumbOnly,
    setCover,
}) => {
    const { newModal } = useModal();
    const { t } = useTranslation();

    const selectThumb = () => {
        newModal(<ImagePicker onImageSelect={setThumb} />, {
            className: 'image-picker-modal no-bottom-padding full-height',
        });
    };

    const selectCover = () => {
        newModal(<ImagePicker onImageSelect={setCover} />, {
            className: 'image-picker-modal no-bottom-padding full-height',
        });
    };

    const noProfileFace = '/images/defaults/thumbs/user/Profile1-Black.jpg';
    const purpleFlowers = '/images/defaults/cover_images/purple-flowers.jpeg';

    thumb = thumb || noProfileFace;

    return (
        <div className="relative w-full">
            {!coverOnly && (
                <div className="absolute top-0 left-0 right-0 mx-auto z-10 w-fit flex flex-col items-center">
                    <div className="w-[130px] h-[130px] md:w-[100px] md:h-[100px] lg:w-[110px] lg:h-[110px] xl:w-[130px] xl:h-[130px] rounded-full border-solid border-[2px] border-white overflow-hidden bg-white">
                        <Picture url={thumb} disableLink resolutions={[100, 200, 300]} />
                    </div>
                    <button
                        type="button"
                        className="bg-white rounded-full relative top-[-22px] p-[10px] md:p-[6px] lg:p-[8px] xl:p-[10px]"
                        onClick={selectThumb}
                    >
                        <CameraIcon version="2" color="black" />
                    </button>
                </div>
            )}
            {!thumbOnly && (
                <div className="relative top-[32px] w-full aspect-[5/2] min-h-[190px] rounded-[30px] overflow-hidden">
                    <Picture
                        url={cover ?? purpleFlowers}
                        disableLink
                        className="!absolute top-0 left-0"
                    />
                    <button
                        type="button"
                        className="bg-white rounded-full flex items-center gap-[10px] px-[8px] py-[6px] lg:py-[8px] lg:px-[10px] xl:p-[10px] m-auto w-fit h-fit absolute top-[110px] md:top-[30px] bottom-0 left-0 right-0 text-grayscale-body font-poppins text-[14px] font-[600] leading-[16px] tracking-[0.25px]"
                        onClick={selectCover}
                        aria-label="Edit Cover Image"
                    >
                        <CameraIcon version="2" color="black" />
                        {t(`common:${localNamespace}.coverPhoto`, 'Cover Photo')}
                    </button>
                </div>
            )}
        </div>
    );
};

type InitialsProfilePicProps = {
    className?: string;
    onClick?: () => any;
};

const InitialsProfilePic: React.FC<InitialsProfilePicProps> = ({ className = '', onClick }) => {
    const { currentUser } = useCurrentUser();
    const { profile } = currentUser ?? {};

    const { full_name = '' } = profile ?? {};
    const names = full_name.trim().split(' ');
    const firstName = names[0];
    const lastName = names.length > 1 ? names[names.length - 1] : '';

    const initials = `${firstName[0] ?? ''}${lastName[0] ?? ''}`;

    return (
        <div
            className={`text-white flex items-center justify-center text-[30px] md:text-[40px] lg:text-[45px] xl:text-[50px] font-poppins tracking-[0.75px] uppercase h-full w-full ${className}`}
            style={{ backgroundColor: 'var(--color-wl-secondary)' }}
            onClick={onClick}
            role={onClick ? 'button' : 'none'}
        >
            {initials}
        </div>
    );
};

export default ThumbnailAndCoverImageUpload;
