import React from 'react';
import _ from 'lodash';

import SearchBox from '@components/generic/search/SearchBox';
import SelectedSearchItem from '@components/generic/search/SelectedSearchItem';

export default props => {
    const {
        libraryUrl,
        overrideContainerStyle,
        types,
        onSelect,
        removeItem,
        selected,
        filterUrl,
        color,
        onClickAddButton,
        onClickAddButtonLabel,
    } = props;

    const items = [];

    const _selected = _.map(selected, item => {
        return (
            <SelectedSearchItem
                key={item._id}
                handleItem={() => removeItem(item, filterUrl)}
                source={item}
                color={color}
            />
        );
    });

    items.push(
        <div
            key="search-tags-with-selected-list-item"
            className="edit-section w-clearfix"
            style={overrideContainerStyle}
        >
            {onClickAddButton ? (
                <div className="select-option-item" onClick={() => onClickAddButton()}>
                    <div>{onClickAddButtonLabel}</div>
                    <div className="select-option-line" />
                </div>
            ) : null}
            {_selected.length > 0 && <div style={{ marginBottom: '15px' }}>{_selected}</div>}
            <SearchBox
                type="tags"
                libraryUrl={libraryUrl}
                types={types}
                filterUrl={filterUrl}
                onSelect={(item, filter) => onSelect(item, filter)}
            />
        </div>
    );

    return items;
};
