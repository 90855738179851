import { useImmer } from 'use-immer';

import { DialogMessageState, DialogPersonState, DialogState } from '@core/types/Dialogs';
import { PerformantMaybify } from '@core/types/Utilities';
import { ContentCard, DialogMessage, DialogPerson } from '@shared/welibrary-graphql/types';

const dialogPersonToDialogPersonState = (
    person?: PerformantMaybify<DialogPerson> | null
): DialogPersonState => ({
    name: person?.name ?? '',
    image: person?.image ?? '',
});

const dialogMessageToDialogMessageState = (
    message?: PerformantMaybify<DialogMessage> | null
): DialogMessageState => ({
    person: message?.person ?? undefined,
    text: message?.text ?? '',
});

/**
 * Builds up all the necessary state for displaying a Dialog Form, optionally taking in an existing
 * dialog card to edit
 */
const useDialogState = (cardToUpdate?: PerformantMaybify<ContentCard>) => {
    return useImmer<DialogState>({
        main: {
            event: cardToUpdate?.dialogSettings?.event ?? 'quest_begin',
            people:
                cardToUpdate?.dialogSettings?.people?.map(dialogPersonToDialogPersonState) ?? [],
            dialogs:
                cardToUpdate?.dialogSettings?.dialogs?.map(dialogMessageToDialogMessageState) ?? [],
        },
        settings: {
            displayInNewsfeed: cardToUpdate?.library_settings?.displayInNewsfeed ?? false,
            rankOrder: cardToUpdate?.rankOrder ?? undefined,
        },
    });
};

export default useDialogState;
