import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';

import AlertCircle from '@assets/alert-circle.png';
import SuccessCircle from '@assets/check-mark-circle.svg';
import InvalidCircle from '@assets/invalid-circle.svg';
import QRCodeIcon from '~/wl-web/ui/dsc/svgs/QRCodeIcon';
import Ticket from '@dsc/svgs/Ticket';
import LottieLoading from '../../generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/circle-spinner.json';

import QRCodeScannerStore from '~/wl-web/ui/stores/QRCodeScannerStore';

import useModal from '@components/modals/hooks/useModal';
import { useSetTicketIsCheckedInViaScannerMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import { ProductInstance } from '@shared/welibrary-graphql/types';

export enum TicketCheckInStatusEnum {
    INVALID = 'invalid',
    CHECKING_IN = 'checkingIn',
    CHECKED_IN = 'checkedIn',
}

const TicketCheckInModal: React.FC<{ ticketId: string; groupId: string }> = ({
    ticketId,
    groupId,
}) => {
    const { closeAllModals } = useModal();

    const [ticketStatus, setTicketStatus] = useState<TicketCheckInStatusEnum>(
        TicketCheckInStatusEnum.INVALID
    );
    const [productInstance, setProductInstance] = useState<ProductInstance | null>(null);

    const [setTicketIsCheckedInViaScanner, { loading }] =
        useSetTicketIsCheckedInViaScannerMutation();

    const handleTicketCheckIn = () => {
        setTicketIsCheckedInViaScanner({
            variables: {
                productInstanceId: ticketId,
                groupId: groupId,
            },
            onCompleted(
                { setTicketIsCheckedInViaScanner: setTicketIsCheckedInViaScannerData },
                clientOptions
            ) {
                const ticketCheckInStatus: TicketCheckInStatusEnum =
                    setTicketIsCheckedInViaScannerData?.ticketCheckInStatus;
                const _productInstance = setTicketIsCheckedInViaScannerData?.productInstance;

                if (ticketCheckInStatus === TicketCheckInStatusEnum.CHECKING_IN) {
                    setProductInstance(_productInstance);
                    setTicketStatus(TicketCheckInStatusEnum.CHECKING_IN);
                } else if (ticketCheckInStatus === TicketCheckInStatusEnum.CHECKED_IN) {
                    setProductInstance(_productInstance);
                    setTicketStatus(TicketCheckInStatusEnum.CHECKED_IN);
                } else if (ticketCheckInStatus === TicketCheckInStatusEnum.INVALID) {
                    setProductInstance(null);
                    setTicketStatus(TicketCheckInStatusEnum.INVALID);
                }
            },
        });
    };

    useEffect(() => {
        if (ticketId) {
            handleTicketCheckIn();
        }
    }, [ticketId]);

    const showScanner = () => {
        closeAllModals();
        QRCodeScannerStore.set.showScanner(true);
    };

    const handleScan = async () => {
        const scannerPermissions = await BarcodeScanner.checkPermissions();

        if (scannerPermissions.camera === 'granted') {
            showScanner();
            return;
        } else if (scannerPermissions.camera === 'denied') {
            return;
        } else if (scannerPermissions.camera === 'prompt') {
            const reqScannerPermissions = await BarcodeScanner.requestPermissions();

            if (reqScannerPermissions.camera === 'granted') {
                showScanner();
            }
            return;
        } else {
            const reqScannerPermissions = await BarcodeScanner.requestPermissions();

            if (reqScannerPermissions.camera === 'granted') {
                showScanner();
            }
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center pt-24 text-center">
                <LottieLoading height={120} width={120} lottieData={LottieAnimation} />
            </div>
        );
    }

    let text = 'Invalid Code';
    let image = <img src={InvalidCircle} alt="ticket status" className="h-[86px] w-[86px]" />;
    let checkInDate = null;

    if (ticketStatus === TicketCheckInStatusEnum.CHECKED_IN) {
        text = 'Already Checked In';
        image = <img src={AlertCircle} alt="ticket status" className="h-[86px] w-[86px]" />;
        if (productInstance?.checkInDate) {
            const checkInDateTimestamp = Number(productInstance?.checkInDate);
            checkInDate = moment(checkInDateTimestamp).format('h:mm a M/DD/YY');
        }
    } else if (ticketStatus === TicketCheckInStatusEnum.CHECKING_IN) {
        text = 'Checked In';
        image = <img src={SuccessCircle} alt="ticket status" className="h-[86px] w-[86px]" />;
    }

    return (
        <div className="flex w-full flex-col">
            <div className="flex w-full items-center justify-center pt-4 text-center">{image}</div>
            <div className="flex w-full flex-col items-center justify-center">
                <h2 className="font-semibold text-black">{text}</h2>
                {ticketStatus === TicketCheckInStatusEnum.CHECKED_IN && checkInDate && (
                    <p>{checkInDate}</p>
                )}
            </div>

            {ticketStatus === TicketCheckInStatusEnum.INVALID && (
                <div className="mt-4 flex w-full flex-col items-center justify-center px-8">
                    <p className="m-0 p-0 text-center font-semibold">
                        This code is invalid or belongs to a different event.
                    </p>
                </div>
            )}

            {(ticketStatus === TicketCheckInStatusEnum.CHECKED_IN ||
                ticketStatus === TicketCheckInStatusEnum.CHECKING_IN) && (
                <>
                    <div className="flex w-full flex-col items-center justify-center">
                        <h4 className="p-m m-0 flex items-center justify-center">
                            <Ticket className="mr-2 h-[20px] w-[20px]" strokeColor="#000" />
                            {productInstance?.product?.name}
                        </h4>
                        <p className="m-0 p-0 text-xs">TICKET #{productInstance?._id}</p>
                    </div>
                    <div className="mt-4 flex w-full flex-col items-center justify-center">
                        <p className="m-0 p-0 font-semibold">
                            {productInstance?.owner?.profile?.full_name}
                        </p>
                        {productInstance?.owner?.profile?.phone && (
                            <p className="m-0 p-0">{productInstance?.owner?.profile?.phone}</p>
                        )}
                        {productInstance?.owner?.profile?.email && (
                            <p className="m-0 p-0">{productInstance?.owner?.profile?.email}</p>
                        )}
                    </div>
                </>
            )}

            <div className="mt-4 flex w-full items-center justify-center pb-8">
                <button
                    onClick={handleScan}
                    className="bg-primary-default flex items-center rounded-full px-[32px] py-[12px] text-white"
                >
                    <QRCodeIcon className="mr-2 text-white" /> Scan Next Ticket
                </button>
            </div>
        </div>
    );
};

export default TicketCheckInModal;
