import React from 'react';

const Notifications: React.FC<{ className?: string }> = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 500 500">
        <path
            stroke="currentcolor"
            fill="currentcolor"
            d="M415.75,255c-31.3-10.73-48.45-31.76-61-60.8-15.57-35.94-33.26-71.09-58.91-101.4-23.19-27.41-52.92-41.08-88.53-42.61-2.89-.13-5.81-.2-8.69-.29-25.73-.84-31.4-22.51-55.53-16.95-12.15,2.8-17.79,11.41-20.48,24.56-1.83,9-10,17.81-17.34,24.34-24,21.21-43,45.23-49,77.55-4.84,26.07-2.28,52.09,2.86,77.73,5.67,28.34,12.39,56.48,19.09,84.6,3.82,16,2.67,31.24-5.05,45.85-3,5.7-6.2,11.29-9,17.06C53,407.11,57.41,442,90.17,455.32c22.53,9.15,46,13,69.79,12.62,90.6-1.41,170.66-31.47,239.15-91.09,18.09-15.74,33.64-33.86,43-56.36C453,294.2,442.68,264.22,415.75,255ZM286.39,403c-111.06,43.91-184.6,15.18-184.6,15.18-4.61-12.05,4-28,22.06-44.88a77.65,77.65,0,0,0,135.71-72.72c75.38-24.59,140.13-25.26,149.82.11C409.38,300.67,397.29,359.13,286.39,403Z"
        />
    </svg>
);

export default Notifications;
