import React from 'react';
import {useTranslation} from 'react-i18next';

import {GetTaskHistoryQueryHookResult} from '@shared/welibrary-graphql/content_card/queries.hook';
import {GET_TASK_HISTORY} from '@shared/welibrary-graphql/content_card/queries';

import CenterModalHeaderFooter from '@web/ui/components/modals/CenterModalHeaderFooter';
import LoadingIndicator from '@web/ui/components/generic/loading/LoadingIndicator';
import PledgeItem from '@web/ui/components/card/pledgecard/PledgeItem';

import useInfiniteScroll from '@web/ui/components/generic/hooks/useInfiniteScroll';

import {getWhitelabeledKey} from '@core/utilities/constants/languages';

import {AliasItem, ContentCard} from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.card.pledgeCard';

type PledgeHistoryProps = {
    headerTitle?: string;
    alias: AliasItem;
    whitelabelVerbage: ContentCard['whitelabelVerbage'];
    cardRefetch: Function;
    pledgeId?: string;
};

const PledgeHistory: React.FC<PledgeHistoryProps> = ({
    headerTitle = 'pledge',
    alias,
    whitelabelVerbage,
    cardRefetch,
    pledgeId,
}) => {
    const { t } = useTranslation();

    const {
        queryResult: { data },
        setRef,
    } = useInfiniteScroll<GetTaskHistoryQueryHookResult>({
        query: {
            query: GET_TASK_HISTORY,
            options: {
                variables: {
                    type: 'PaginatedTaskCompletion',
                    cardId: alias.url,
                    pledgeKeyId: pledgeId,
                },
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
            },
        },
    });

    const title =
        headerTitle === 'pledge'
            ? t(
                  getWhitelabeledKey(
                      `common:${localNamespace}.pledge_history_title`,
                      whitelabelVerbage
                  )
              )
            : 'Your Task History';

    const hasMoreResults = data?.getTaskHistory.hasMore;
    const historyResults = data?.getTaskHistory?.results;

    const historyList = historyResults?.map((pledge, index) => (
        <PledgeItem
            cardAlias={alias}
            key={index}
            pledgeItem={pledge}
            whitelabelVerbage={whitelabelVerbage}
            cardRefetch={cardRefetch}
        />
    ));

    return (
        <CenterModalHeaderFooter title={title} className="pledge-history">
            <ul>
                {historyList}
                {hasMoreResults && (
                    <div ref={setRef}>
                        {' '}
                        <LoadingIndicator type="skeleton" size="fill" />
                    </div>
                )}
            </ul>
        </CenterModalHeaderFooter>
    );
};

export default PledgeHistory;
