import { useImmer } from 'use-immer';
import { ComposeMessageState } from '@core/types/Messages';
import { Maybe, MessageThread } from '@shared/welibrary-graphql/types';

/**
 * Builds up all the necessary state for displaying the compose message form optionally taking in an existing
 * message thread to edit
 */
const useComposeMessageState = (thread?: Maybe<MessageThread>) => {
    return useImmer<ComposeMessageState>({
        participants: thread?.participants ?? [],
        title: thread?.displayName ?? '',
        thumb: thread?.displayIcon ?? '',
    });
};

export default useComposeMessageState;
