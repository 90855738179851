import {applyMiddleware, compose, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import rootReducer from '@core/client/reducers/rootReducer';

const logger = createLogger();

const enhancers = [applyMiddleware(ReduxThunk, logger)];

const Store = createStore(rootReducer, { currentUser: null }, compose(...enhancers));
export default Store;
