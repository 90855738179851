import React from 'react';
import {useTranslation} from 'react-i18next';

import {Link} from 'react-router-dom';
import withMinimalPullDownToRefresh from '@web/ui/components/generic/withMinimalPullDownToRefresh';

const localNamespace = 'imports.wlWeb.ui.components.community';

const SectionContainer = ({
    title,
    subtitle,
    sectionCustomClass,
    sectionHeader,
    listCustomClass,
    children,
    launchExpandedView,
    onClickSubtitle,
    subtitleTo,
    onScroll,
    onTouchMove,
    onTouchStart,
    onTouchEnd,
}) => {
    let renderSubtitle;
    const { t } = useTranslation();

    if (subtitle) {
        if (subtitleTo) {
            renderSubtitle = (
                <Link className="community-section-heading-right" to={subtitleTo}>
                    <div>{subtitle}</div>
                </Link>
            );
        } else {
            renderSubtitle = (
                <div className="community-section-heading-right" onClick={onClickSubtitle}>
                    <div>{subtitle}</div>
                </div>
            );
        }
    }

    const customSectionClass = `community-section ${sectionCustomClass || ''}`;
    const listClass = listCustomClass || 'summary-list w-list-unstyled';

    return (
        <div
            className={customSectionClass}
            onScroll={onScroll}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onTouchStart={onTouchStart}
        >
            {title && (
                <div className="community-section-heading w-inline-block">
                    <strong className="community-section-title">{title}</strong>
                    {renderSubtitle}
                </div>
            )}
            {sectionHeader && <div className="section-header">{sectionHeader}</div>}
            <div className="toggle-container">
                <ul
                    className={listClass}
                    // style={overrideListContainerStyle}
                >
                    {children}
                </ul>
                {launchExpandedView && (
                    <div className="centered">
                        <button
                            onClick={launchExpandedView}
                            name="isExpandContainerOpen"
                            type="button"
                        >
                            <strong>{t(`common:${localNamespace}.show_all`)}</strong>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SectionContainer;
export const SectionContainerWithPullDownToRefresh = withMinimalPullDownToRefresh(SectionContainer);
