import React, { useState } from 'react';
import { DateRange, DateRangeSelection } from 'react-date-range';
import 'react-datepicker/dist/react-datepicker.css';
import useModal from '@web/ui/components/modals/hooks/useModal';
import { ModalTypes } from '@core/types/Modals';
import { ISOStringsToDatesSelect, getFormattedEventDates } from '@core/utilities/constants/events';
import CalendarIcon2 from '@web/ui/dsc/svgs/CalendarIcon2';
import { EventDateState } from '@core/types/EventGroup';

type CalendarOptions = {
    maxDate: string | Date | undefined;
    minDate: string | Date | undefined;
    shownDate: Date;
    editableDateInputs: boolean;
    moveRangeOnFirstSelection: boolean;
};

type DatePickerPopupProps = {
    handleSelect: (value: DateRangeSelection) => void;
    dates: EventDateState[];
    calendarOptions: CalendarOptions;
};

type CalendarDisplayContainerProps = {
    handleClose: () => void;
    handleSetDate: (value: Date) => void;
    handleSelect: (value: Date) => void;
    dates: EventDateState[];
    calendarOptions: CalendarOptions;
};

const DatePickerPopup: React.FC<DatePickerPopupProps> = ({
    handleSelect,
    dates,
    calendarOptions,
}) => {
    const { newModal, closeModal } = useModal({ mobile: ModalTypes.Center });
    const [dateSelection, setDateSelection] = useState(dates);

    // Convert ISO date string to date objects the calendar range picker can use
    const datepickerDates =
        dateSelection?.[0]?.startDate && dateSelection?.[0].endDate
            ? ISOStringsToDatesSelect(dateSelection)
            : [
                {
                    startDate: calendarOptions.shownDate,
                    endDate: calendarOptions.shownDate,
                    key: 'selection',
                },
            ];

    const handleSetDate = (value: EventDateState[]) => {
        setDateSelection(value);
    };

    const handleShowCalendar = () => {
        newModal(
            <CalendarDisplayContainer
                handleClose={closeModal}
                handleSetDate={handleSetDate}
                handleSelect={handleSelect}
                dates={datepickerDates}
                calendarOptions={calendarOptions}
            />,
            { hideButton: true, className: 'popup', sectionClassName: 'popup-picker' }
        );
    };

    const btnTxt =
        dateSelection?.[0]?.startDate && dateSelection?.[0].endDate
            ? getFormattedEventDates(dateSelection[0])
            : 'Date *';

    return (
        <section className="fake-input-w-icon">
            <button className="fake-input-button" onClick={handleShowCalendar}>
                {btnTxt}
            </button>
            <button className="icon-btn animate-grow" onClick={handleShowCalendar}>
                <CalendarIcon2 size="20px" />
            </button>
        </section>
    );
};

export default DatePickerPopup;

const CalendarDisplayContainer: React.FC<CalendarDisplayContainerProps> = ({
    handleClose,
    handleSelect,
    handleSetDate,
    dates: datesState,
    calendarOptions,
}) => {
    const { maxDate, minDate, shownDate } = calendarOptions;

    const [dateSelection, setDateSelection] = useState(datesState);

    const handleSave = () => {
        handleSelect({
            selection: {
                startDate: dateSelection?.[0]?.startDate,
                endDate: dateSelection?.[0]?.endDate,
            },
        });
        handleSetDate(dateSelection);
        handleClose();
    };

    const handleSelectRange = ranges => {
        setDateSelection([ranges.selection]);
    };

    return (
        <section className="pop-up-date-picker-container">
            <header>
                <h1>Select Date</h1>
                <nav>
                    <button
                        className="rounded-pill-btn bordered animate-grow"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                    <button className="rounded-pill-btn active animate-grow" onClick={handleSave}>
                        Save
                    </button>
                </nav>
            </header>

            <article>
                <DateRange
                    editableDateInputs
                    className="big-date-range"
                    maxDate={maxDate}
                    minDate={minDate}
                    shownDate={shownDate}
                    onChange={handleSelectRange}
                    moveRangeOnFirstSelection={false}
                    ranges={dateSelection}
                />
            </article>
        </section>
    );
};
