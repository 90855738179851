import React from 'react';

import useModal from '@components/modals/hooks/useModal';

import Picture from '@components/generic/Picture';

import { getRandomDefaultThumbImage } from '@core/utilities/constants/defaults';

import { DialogPersonState } from '@core/types/Dialogs';

type DialogPersonSelectorProps = {
    people: DialogPersonState[];
    onChange: (person: number) => void;
};

const DialogPersonSelector: React.FC<DialogPersonSelectorProps> = ({ people, onChange }) => {
    const { closeModal } = useModal();

    const buttons = people.map((person, index) => (
        <button
            type="button"
            onClick={() => {
                onChange(index);
                closeModal();
            }}
            key={index}
        >
            <Picture
                url={person.image || getRandomDefaultThumbImage(person.name)}
                disableLink
                className="dialog-person"
            >
                <figcaption>{person.name}</figcaption>
            </Picture>
        </button>
    ));

    return <section className="dialog-person-selector">{buttons}</section>;
};

export default DialogPersonSelector;
