import React from 'react';
import WindowFrame from '@dsc/svgs/lego/WindowFrame';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/legoloader.json';
import SSLogo from '@assets/lego/SSLogo.png';
import Picture from '@web/ui/components/generic/Picture';

const LegoFullScreenLoader: React.FC<{ loadingText?: string }> = ({
    loadingText = 'Building Worlds',
}) => {
    return (
        <section className="lego-loader">
            <div className="lego-frame-overlay-layer" style={{ zIndex: 1 }}>
                <WindowFrame />
            </div>
            <div className="full-screen-loader">
                <LottieLoading
                    customStyle={{ backgroundColor: '#56CCF2', height: '100%', width: '100%' }}
                    height="100%"
                    isPaused={false}
                    width="100%"
                    lottieData={LottieAnimation}
                />
            </div>
            <div className="logo-container">
                <Picture disableLink url={SSLogo}  />

                <p>{loadingText}</p>
            </div>
        </section>
    );
};

export default LegoFullScreenLoader;
