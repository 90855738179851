import { useEffect, useState } from 'react';

/**
 * React hook for keeping track of a component's width / height (NOT TO BE CONFUSED WITH WINDOW'S WIDTH)
 *
 * Use like so:
 *
 * const componentRef = ref(); // initialize the ref()
 * const { width, height } = useContainerDimensions(componentRef); // This will get the components width / height on resize
 * <div ref={componentRef} /> // set the ref on the component you want to get the width / height from on resize
 *
 * if (width < 991) { do something...  };
 */
export const useContainerDimensions = myRef => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const getDimensions = () => ({
            width: myRef.current.offsetWidth,
            height: myRef.current.offsetHeight,
        });

        const handleResize = () => {
            setDimensions(getDimensions());
        };

        if (myRef.current) {
            setDimensions(getDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [myRef]);

    return dimensions;
};

export default useContainerDimensions;
