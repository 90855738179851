import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import IntroContent from '@web/ui/components/generic/IntroContent';
import StripeCustomerOrderHistoryItem from '@components/profile/stripe/StripeCustomerOrderHistoryItem';
import SignUpPageButton from '@pages/new-signup/SignUpPageButton';

import {
    StripeSubscriptionStatus,
    useGetPreAuthPaymentOrderForGroupQuery,
} from '@shared/welibrary-graphql/user/queries.hook';
import {
    useCancelPreAuthSubscriptionOrderMutation,
    useConfirmPreAuthSubscriptionOrderMutation,
    useCreatePreAuthSubscriptionOrderMutation,
} from '@shared/welibrary-graphql/user/mutations.hook';
import { useCurrentUser } from '@stores/User';

import { UPDATE_GET_GROUP_MEMBERSHIP } from '@core/utilities/constants/update_cache';
import { OrderStatusEnum } from './stripeOrderHelpers';

enum PreAuthPaymentModes {
    confirm = 'confirm',
    deny = 'deny',
}

const StripePreAuthPaymentOrderDetails: React.FC<{
    userId: string;
    groupId: string;
    closeModal: () => void;
    mode: PreAuthPaymentModes;
}> = ({ userId, groupId, closeModal, mode }) => {
    const stripe = useStripe();
    const { currentUser } = useCurrentUser();

    const { t } = useTranslation();
    const { data: orderData, loading } = useGetPreAuthPaymentOrderForGroupQuery({
        variables: {
            userId,
            groupId,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const [
        createPreAuthPayment,
        { loading: createPreAuthPaymentLoading, error: createPreAuthPaymentError },
    ] = useCreatePreAuthSubscriptionOrderMutation();

    const [
        confirmPreAuthPayment,
        { loading: confirmPreAuthPaymentLoading, error: confirmPreAuthPaymentError },
    ] = useConfirmPreAuthSubscriptionOrderMutation();

    const [
        cancelPreAuthPayment,
        { loading: cancelPreAuthPaymentLoading, error: cancelPreAuthPaymentError },
    ] = useCancelPreAuthSubscriptionOrderMutation();

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [cardErr, setCardErr] = useState<any>(null);

    const order = orderData?.getPreAuthPaymentOrderForGroup;
    const orderId = order?._id;

    if (loading) {
        return (
            <div className="card-loading group-item">
                <strong>Loading...</strong>
            </div>
        );
    }

    const handleConfirmPayment = async (clientSecret: string) => {
        setIsProcessing(true);

        if (!stripe) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const payload = await stripe?.confirmCardPayment(clientSecret, {
            payment_method: order?.preAuthPayment,
        });

        if (payload.error) {
            setCardErr(payload.error);
            setIsProcessing(false);
        } else {
            confirmPreAuthPayment({
                variables: {
                    userId,
                    groupId,
                    orderId,
                },
                update: (cache, data) => {
                    if (
                        data?.data?.confirmPreAuthSubscriptionOrder?.paymentIntent?.status ===
                        OrderStatusEnum.Succeeded
                    ) {
                        UPDATE_GET_GROUP_MEMBERSHIP(cache, data, {
                            groupId,
                            type: 'user',
                            mutationType: 'confirmPreAuthSubscriptionOrder',
                        });
                    }
                },
            }).then(({ data: confirmPreAuthPaymentData }) => {
                if (
                    confirmPreAuthPaymentData.confirmPreAuthSubscriptionOrder.paymentIntent
                        ?.status === OrderStatusEnum.Succeeded
                ) {
                    setIsProcessing(false);
                    closeModal();
                }
            });
        }
    };

    const handleCancelPreAuthPayment = () => {
        cancelPreAuthPayment({
            variables: {
                orderId,
            },
            update: (cache, data) =>
                UPDATE_GET_GROUP_MEMBERSHIP(cache, data, {
                    groupId,
                    type: 'user',
                    mutationType: 'cancelPreAuthSubscriptionOrder',
                }),
        }).then(({ data }) => {
            if (data.cancelPreAuthSubscriptionOrder) {
                closeModal();
            }
        });
    };

    const handleClearRequestPayment = () => {
        cancelPreAuthPayment({
            variables: {
                orderId,
                groupId,
                userId,
            },
            update: (cache, data) =>
                UPDATE_GET_GROUP_MEMBERSHIP(cache, data, {
                    groupId,
                    type: 'user',
                    mutationType: 'cancelPreAuthSubscriptionOrder',
                }),
        }).then(({ data }) => {
            if (data.cancelPreAuthSubscriptionOrder) {
                closeModal();
            }
        });
    };

    const confirm = () => {
        if (mode === PreAuthPaymentModes.confirm) {
            createPreAuthPayment({
                variables: {
                    orderId,
                },
            }).then(async ({ data: createPreAuthPaymentData }) => {
                const client_secret =
                    createPreAuthPaymentData?.createPreAuthSubscriptionOrder?.paymentIntent
                        ?.client_secret;

                if (client_secret) {
                    await handleConfirmPayment(client_secret);
                }
            });
        } else if (mode === PreAuthPaymentModes.deny) {
            handleCancelPreAuthPayment();
        }
    };

    const cancel = () => {
        closeModal();
    };

    const isConfirming =
        createPreAuthPaymentLoading || confirmPreAuthPaymentLoading || isProcessing;

    let disclaimerText = `By clicking “Confirm Payment”, you'll authorize the user to join the group with their pre-authorized payment. If you wish to deny the payment, close the modal and click “X”.`;
    let actionButtonText = isConfirming ? 'Confirming Payment...' : 'Confirm Payment';
    if (mode === PreAuthPaymentModes.deny) {
        actionButtonText = cancelPreAuthPaymentLoading ? 'Loading...' : 'Deny Payment';
        disclaimerText = `By clicking “Deny Payment”, you'll decline the user's request to join the group with their pre-authorized payment. If you wish to proceed with the payment authorization, close the modal and click “+”.`;

        if (currentUser?._id === userId) {
            actionButtonText = cancelPreAuthPaymentLoading ? 'Loading...' : 'Cancel Request';
            disclaimerText = `By clicking “Cancel Request”, you'll cancel your request and your pre-authorized payment to join the group. If you wish to proceed with the payment authorization, close the modal.`;
        }
    }

    let actionButton = (
        <SignUpPageButton
            disabled={
                createPreAuthPaymentLoading ||
                confirmPreAuthPaymentLoading ||
                cancelPreAuthPaymentLoading ||
                isProcessing
            }
            onClick={confirm}
            className="!w-full mb-4"
        >
            {actionButtonText}
        </SignUpPageButton>
    );
    // detached order status
    if (
        currentUser?._id !== userId &&
        (cardErr ||
            order?.status === OrderStatusEnum.RequiresPaymentMethod ||
            order?.subscription?.status === StripeSubscriptionStatus.IncompleteExpired)
    ) {
        actionButton = (
            <SignUpPageButton
                disabled={cancelPreAuthPaymentLoading}
                onClick={handleCancelPreAuthPayment}
                className="!w-full mb-4"
            >
                Clear Request
            </SignUpPageButton>
        );
        disclaimerText = `Due to a failed pre-authorized payment and no update within the 24-hour grace period, the user's pending request for this group will be cleared. Alternatively click "Clear Request" to acknowledge & clear the user's request.`;
    }

    return (
        <div>
            {order ? (
                <div className="py-6">
                    <StripeCustomerOrderHistoryItem
                        key={order?._id}
                        order={order}
                        className="order-details-modal"
                        noShadow
                    />
                    {cardErr && (
                        <div className="customer-card-error-wrap">
                            <p className="customer-card-error-text">x {cardErr?.message}</p>
                        </div>
                    )}
                    <footer className="w-full flex flex-col items-center justify-center px-4">
                        <div className="w-full text-center mb-6 px-6 text-sm">{disclaimerText}</div>
                        <div className="w-full flex items-center justify-center flex-col !max-w-[500px] relative">
                            {actionButton}
                            <SignUpPageButton inverseColors onClick={cancel} className="!w-full">
                                Dismiss
                            </SignUpPageButton>
                        </div>
                    </footer>
                </div>
            ) : (
                <div>
                    <IntroContent declaration="No Order found!" type="nocontent" />
                    <div className="w-full flex items-center justify-center pb-4">
                        <SignUpPageButton
                            disabled={cancelPreAuthPaymentLoading}
                            onClick={handleClearRequestPayment}
                            className="!w-[80%] mb-4"
                        >
                            Clear Request
                        </SignUpPageButton>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StripePreAuthPaymentOrderDetails;
