import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { awsMetricPublisher } from '@welibraryos/metrics';

import { useCurrentUser } from '@stores/User';

import useNavNotifications from '@components/generic/notifications/hooks/useNavNotifications';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary.js';
import Notifications from '@web/ui/components/generic/notifications/Notifications';
import NotificationsWireFrame from '@web/ui/components/generic/notifications/NotificationsWireFrame';
import ClearAllButton from '@web/ui/components/generic/notifications/ClearAllButton';

import useNavHighlighting from '@web/ui/components/navigation/hooks/useNavHighlighting';

const localNamespace = 'imports.wlWeb.ui.components.generic.notifications.notifications';

type NavNotificationsProps = {
    hideLoader: boolean;
    onClickMobile: () => void;
    showMobile: boolean;
    showDesktop: boolean;
};

const NavNotifications: React.FC<NavNotificationsProps> = ({
    hideLoader,
    onClickMobile,
    showMobile,
    showDesktop,
}) => {
    const { t } = useTranslation();

    const { currentUser } = useCurrentUser();

    const {
        toggleNotifications,
        notificationRef,
        handleToggle,
        unreadNotifications,
        markNotificationsRead,
        snoozed,
        notifications,
        loading,
        error,
    } = useNavNotifications();

    const className = useNavHighlighting({ url: '/notifications' });

    if (!currentUser) return <NotificationsWireFrame />;

    const handleOnClickMobile = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'menu', Value: 'notifications' },
        ]);
        onClickMobile();
    };

    const handleOnClickDesktop = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'menu', Value: 'notifications-dropdown' },
        ]);
        handleToggle();
    };

    const handleOnClickSeeAll = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'notifications-dropdown', Value: 'see-all' },
        ]);
        handleToggle();
    };

    return (
        <>
            {showMobile && (
                <Link
                    to="/notifications"
                    className={`secondary-nav-dropdown-toggle w-dropdown-toggle w--open ${className}`}
                    onClick={handleOnClickMobile}
                    onContextMenu={e => e.preventDefault()}
                >
                    <div className="mn-icon desktop-hide">
                        <div className="content-button-icon notification" />
                        <div className="icon-alert-indicator">
                            {unreadNotifications.length > 0 && (
                                <div className="alert-indicator-dot">
                                    {unreadNotifications.length}
                                </div>
                            )}
                        </div>
                    </div>
                </Link>
            )}
            {showDesktop && (
                <div
                    ref={notificationRef}
                    data-delay="0"
                    className="sn-dropdown notification w-dropdown"
                    style={{ zIndex: 901 }}
                >
                    <div
                        className="secondary-nav-dropdown-toggle w-dropdown-toggle w--open "
                        onClick={handleOnClickDesktop}
                    >
                        <div className="mn-icon mobile-hide">
                            <div className="content-button-icon notification" />
                        </div>
                        <div className="icon-alert-indicator mobile-hide">
                            {unreadNotifications.length > 0 && (
                                <div className="alert-indicator-dot">
                                    {unreadNotifications.length}
                                </div>
                            )}
                        </div>
                    </div>
                    <nav
                        className={`dropdown-align-right w-dropdown-list ${
                            toggleNotifications ? 'w--open' : ''
                        }`}
                    >
                        <div className="dropdown-list notification-list">
                            <div className="notifications-header we-notifications-header-desktop">
                                <b>{t(`common:${localNamespace}.notifications`)}</b>
                                <div className="notifications-header-right">
                                    {unreadNotifications.length > 0 && (
                                        <>
                                            <ClearAllButton
                                                markNotificationsRead={markNotificationsRead}
                                            />
                                            <span className="dot-spacer"> • </span>
                                        </>
                                    )}
                                    <button type="button" className="button-reset gray-text">
                                        <Link onClick={handleOnClickSeeAll} to="/notifications">
                                            {t(`common:${localNamespace}.see_all`)}
                                        </Link>
                                    </button>
                                </div>
                            </div>
                            <ErrorBoundary>
                                <Notifications
                                    hideLoader={hideLoader}
                                    notifications={notifications}
                                    snoozed={snoozed}
                                    markNotificationsRead={markNotificationsRead}
                                    loading={loading}
                                    error={error}
                                    closeNotifications={() => handleToggle(false)}
                                />
                            </ErrorBoundary>
                        </div>
                    </nav>
                </div>
            )}
        </>
    );
};

export default NavNotifications;
