import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Updater } from 'use-immer';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import MomentUtils from '@date-io/moment';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { z } from 'zod';

import Select from 'react-select';
import Toggle from '@dsc/forms/customInputs/Toggle';

import {
    getClientTzSelect,
    convertTzPreserveTime,
    getClientTzRaw,
    generateSelectTimeZoneList,
    ISOStringsToDatesSelect,
} from '@core/utilities/constants/events';
import { curriedStateSlice } from '@helpers/state/state.helpers';
import { toIsoStringIgnoreTimezone } from '@helpers/date.helpers';

import { DateAndTimeState, ItineraryState } from '@core/types/EventGroup';

type EventGroupDateAndTimeFormProps = {
    state: DateAndTimeState;
    itineraryState?: ItineraryState[];
    updateItineraryState?: Updater<ItineraryState[]>;
    setState: Updater<DateAndTimeState>;
    updateValidationSlice: Updater<boolean>;
    bodyText?: string;
};

type TzSelection = {
    label: string;
    value: string;
};

type DateRangeSelection = {
    selection: {
        endDate: Date;
        startDate: Date;
    };
};

const timeZones = generateSelectTimeZoneList();

const eventlocalNamespace = 'imports.wlWeb.ui.components.content.event';

MomentUtils.prototype.isEqual = (value, comparing) => {
    if (value === null && comparing === null) return true;
    return moment(value).isSame(comparing) && value?._z?.name === comparing?._z?.name;
};

const StateValidator = z.object({
    startTimeValue: z.date(),
    endTimeValue: z.date(),
});

const EventGroupDateAndTimeForm = React.forwardRef<
    HTMLFieldSetElement,
    EventGroupDateAndTimeFormProps
>(function EventGroupDateAndTimeForm(
    { state, setState, bodyText, itineraryState, updateItineraryState, updateValidationSlice },
    ref
) {
    const { t } = useTranslation();

    const updateSlice = curriedStateSlice(setState);

    const handleDates = (ranges: DateRangeSelection) => {
        const startDate = ranges?.selection?.startDate;
        const endDate = ranges?.selection?.endDate;

        // ignore timezone because timezones with GMT+X will go back a day when converted to UTC (which is what toISOString does)
        updateSlice('dates', [
            {
                startDate: toIsoStringIgnoreTimezone(startDate),
                endDate: toIsoStringIgnoreTimezone(endDate),
            },
        ]);
    };

    const handleTime = (field: any, time: Date) => {
        updateSlice(field, time.toISOString());
    };

    const handleTimezone = (tzSelectObject: TzSelection) => {
        // When updating timezone for an event, we want to preserve the selected time
        // e.g. 8AM EST -> 8AM PST
        const updatedStartTime = convertTzPreserveTime(
            {
                time: state?.startTime,
                timeZone: state?.timeZone?.name,
            },
            tzSelectObject?.value
        );

        const updatedEndTime = convertTzPreserveTime(
            {
                time: state?.endTime,
                timeZone: state?.timeZone?.name,
            },
            tzSelectObject?.value
        );

        updateSlice('startTime', updatedStartTime);
        updateSlice('endTime', updatedEndTime);

        // If there are sessions, we need to update the times for those as well
        if (itineraryState && itineraryState?.length > 0) {
            const updatedItineraryState = itineraryState?.map(session => {
                const startTime = convertTzPreserveTime(
                    {
                        time: session?.startTime,
                        timeZone: session?.timeZone?.name,
                    },
                    tzSelectObject?.value
                );

                const endTime = convertTzPreserveTime(
                    {
                        time: session?.endTime,
                        timeZone: session?.timeZone?.name,
                    },
                    tzSelectObject?.value
                );

                return {
                    ...session,
                    startTime,
                    endTime,
                    timeZone: { name: tzSelectObject?.value },
                };
            });

            if (updateItineraryState) {
                updateItineraryState(updatedItineraryState);
            }
        }

        updateSlice('timeZone', { name: tzSelectObject?.value });
        moment.tz.setDefault(tzSelectObject?.value);
    };

    // Convert ISO date string to date objects the calendar range picker can use
    const datepickerDates = ISOStringsToDatesSelect(state?.dates);
    const startDateIndicatorTxt = moment(state?.dates?.[0]?.startDate).utc().format('LL');
    const endDateIndicatorTxt = moment(state?.dates?.[0]?.endDate).utc().format('LL');
    const startTimeValue = state?.startTime ? new Date(state?.startTime) : null;
    const endTimeValue = state?.endTime ? new Date(state?.endTime) : null;

    const validate = () => {
        const parsedData = StateValidator.safeParse({
            startTimeValue,
            endTimeValue,
        });

        if (parsedData.success) {
            updateValidationSlice(true);
            return true;
        }

        if (parsedData.error) {
            updateValidationSlice(false);
        }

        return false;
    };

    useEffect(() => {
        if (startTimeValue || endTimeValue) {
            validate();
        }
    }, [state]);

    useEffect(() => {
        moment.tz.setDefault(state?.timeZone?.name);
        return () => {
            moment.tz.setDefault(getClientTzRaw()?.name);
        };
    }, []);

    return (
        <fieldset ref={ref} className="main-form-event-group">
            <h3>{t('common:date_&_time', 'Date & Time')}</h3>

            <section className="step-desc">
                <p>
                    {bodyText ||
                        t(
                            'common:eventGroupForm.date_and_time_description',
                            'Select the start date, start time, end date and end time for your event. These represent the overall dates that your event encompasses. You will be able to create a more detailed itinerary of individual sessions that exist within this overall date range in the next step.'
                        )}
                </p>
            </section>

            <DateRange
                className="big-date-range"
                editableDateInputs
                onChange={handleDates}
                moveRangeOnFirstSelection={false}
                ranges={datepickerDates}
            />

            <section className="event-times-container">
                <div className="timepicker-container">
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <TimePicker
                            variant="inline"
                            label={
                                startTimeValue === null
                                    ? t(
                                          `common:${eventlocalNamespace}.select_start_time`,
                                          'Select Start Time'
                                      )
                                    : t(`common:${eventlocalNamespace}.start_time`)
                            }
                            value={startTimeValue}
                            onChange={value => handleTime('startTime', value)}
                            name="timeAndPlaceSettings.startTime"
                            id="timeAndPlaceSettings.startTime"
                            className="event-time-input"
                            autoOk
                        />
                    </MuiPickersUtilsProvider>
                    <p className="timepicker-date-indicator">{startDateIndicatorTxt}</p>
                </div>
                {state?.startTime === null && (
                    <p className="time-selector-error">
                        {t(
                            `common:${eventlocalNamespace}.start_time_error`,
                            'Start time is required.'
                        )}
                    </p>
                )}

                <div className="timepicker-container">
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <TimePicker
                            variant="inline"
                            label={
                                endTimeValue === null
                                    ? t(
                                          `common:${eventlocalNamespace}.select_end_time`,
                                          'Select End Time'
                                      )
                                    : t(`common:${eventlocalNamespace}.end_time`)
                            }
                            value={endTimeValue}
                            onChange={value => handleTime('endTime', value)}
                            name="timeAndPlaceSettings.endTime"
                            id="timeAndPlaceSettings.endTime"
                            className="event-time-input"
                            autoOk
                        />
                    </MuiPickersUtilsProvider>
                    <p className="timepicker-date-indicator">{endDateIndicatorTxt}</p>
                </div>
                {state?.endTime === null && (
                    <p className="time-selector-error">
                        {t(`common:${eventlocalNamespace}.end_time_error`, 'End time is required.')}
                    </p>
                )}
            </section>

            <div>
                <h4>{t('common:time_zone', 'Time Zone')}</h4>
                <Select
                    defaultValue={getClientTzSelect(state?.timeZone?.name)}
                    className="tz-select"
                    onChange={handleTimezone}
                    options={timeZones}
                    menuPlacement="auto"
                />
            </div>

            <label className="all-day-toggle" htmlFor="all-day-toggle">
                <p>{t('common:display_start_time', 'Display Start Time')}</p>
                <Toggle
                    id="disp-start-time-toggle"
                    value={state?.displayStartTime}
                    onChange={updateSlice('displayStartTime')}
                />
            </label>

            <label className="all-day-toggle" htmlFor="all-day-toggle">
                <p>{t('common:display_end_time', 'Display End Time')}</p>
                <Toggle
                    id="disp-end-time-toggle"
                    value={state?.displayEndTime}
                    onChange={updateSlice('displayEndTime')}
                />
            </label>
        </fieldset>
    );
});

export default EventGroupDateAndTimeForm;
