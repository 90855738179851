import dialogStore from '@stores/Dialog';

import {
    useQuestByIdQuery,
    QuestByIdDocument,
    QuestForLoggedInUserByRainbowRoadUrlDocument,
} from '@shared/welibrary-graphql/quest/queries.hook';
import {
    useCompleteQuestCurrentNodeMutation,
    useCompleteQuestMutation,
} from '@shared/welibrary-graphql/quest/mutations.hook';

import useCompleteQuestMapNode from '@components/quest/hooks/useCompleteQuestMapNode';

import { getNodeIndex, getFirstPostNodeIndex } from '@helpers/rainbowRoad/quest.helpers';
import useUniversalWallet from '@web/utilities/hooks/wallet/useUniversalWallet';

const useCompleteQuestNode = (questId: string, questMapId?: string) => {
    const { data } = useQuestByIdQuery({ variables: { _id: questId }, skip: !questId });

    const completeMapNode = useCompleteQuestMapNode(questMapId ?? '');

    const { issueSuperSkillsVc } = useUniversalWallet();

    const [completeQuestCurrentNode] = useCompleteQuestCurrentNodeMutation({
        refetchQueries: [QuestByIdDocument, QuestForLoggedInUserByRainbowRoadUrlDocument],
    });

    const [completeQuestMutation] = useCompleteQuestMutation({
        variables: { questId },
        refetchQueries: [QuestByIdDocument, QuestForLoggedInUserByRainbowRoadUrlDocument],
    });

    const completeQuest = async () => {
        await completeQuestMutation();
        dialogStore.set.dialogUnread();

        if (data?.getQuestById.rainbowRoad?.rainbowRoadSettings?.questSettings?.vcSettings) {
            const {
                title = 'SSL Season 1 VC',
                name = 'SuperSkills Season 1',
                description = 'This is a completion achievement for SuperSkills season 1. Congratulations!',
                image = 'https://cdn.filestackcontent.com/rotate=deg:exif/resize=width:600/auto_image/4ZTwznyGTPWuAjfQdp84',
            } = data.getQuestById.rainbowRoad.rainbowRoadSettings.questSettings.vcSettings;

            await issueSuperSkillsVc({ title, name, description, image });
        }

        if (questMapId) completeMapNode(data?.getQuestById?.rainbowRoad?.url ?? '');
    };

    const completeNode = (url?: string) => {
        if (data?.getQuestById?._id) {
            const node =
                (url
                    ? getNodeIndex(url, data.getQuestById?.rainbowRoad)
                    : data.getQuestById.currentNode ?? 0) + 1;

            if (node > (data.getQuestById.currentNode ?? 0)) {
                completeQuestCurrentNode({ variables: { questId, node } });
            }

            if (
                data.getQuestById.rainbowRoad?.nodes?.length &&
                node === data.getQuestById.rainbowRoad.nodes.length
            ) {
                completeQuest();
            }
        }
    };

    const completeAllPreNodes = () => {
        if (data?.getQuestById?._id) {
            const node = getFirstPostNodeIndex(data.getQuestById);

            if (node > (data.getQuestById.currentNode ?? 0)) {
                completeQuestCurrentNode({ variables: { questId, node } });
            }

            if (
                data.getQuestById.rainbowRoad?.nodes?.length &&
                node === data.getQuestById.rainbowRoad.nodes.length
            ) {
                completeQuest();
            }
        }
    };

    return { completeNode, completeAllPreNodes };
};

export default useCompleteQuestNode;
