import React from 'react';
import TagItem from '@web/ui/components/tags/TagItem';

const TagsBlock = ({ tags }) => {
    const tagsList = tags?.map((tag, index) => <TagItem key={`${tag._id}-${index}`} tag={tag} />);

    return <div className="tags-container">{tagsList}</div>;
};

export default TagsBlock;
