import React from 'react';
import { withTranslation } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';

import EditScreenTagSelect from '@web/ui/components/generic/editscreen/EditScreenTagSelect';
import AlertToast from '@components/toast/AlertToast';

import {
    getBackgroundImageWithGradient,
    getRandomDefaultCoverImage,
    getRandomDefaultUserImage,
} from '@core/utilities/constants/defaults';

import { Mutation } from '@apollo/client/react/components';

import {
    UPDATE_CUSTOM_USER_ROLE_FOR_URL,
    UPDATE_USER_ROLE_FOR_URL,
} from '@shared/welibrary-graphql/user/mutations';
import { UPDATE_CARD } from '@shared/welibrary-graphql/content_card/mutations';

import {
    ROLE_ADMIN,
    ROLE_MANAGER,
    ROLE_NONE,
    ROLE_SPEAKER,
    ROLE_VIEW,
} from '@core/utilities/constants/roles';

import { defaultAlertToastOptions } from '@core/types/Toast';

const localNamespace = 'imports.wlWeb.ui.components.generic.editScreen.editScreenCustomRole';

class EditScreenCustomRole extends React.Component {
    getRole(user) {
        const { url } = this.props;
        if (user) {
            if (user.roles) {
                if (user.roles.roleOf) {
                    const userRole = user.roles.roleOf.role;
                    return userRole ? { role: userRole, url } : { role: ROLE_NONE, url };
                } else if (user.roles.rolesOf) {
                    let rolesForUrl = [];
                    _.each(user.roles.rolesOf, userRole => {
                        if (userRole.url === url) {
                            rolesForUrl.push(userRole.role);
                        }
                    });
                    if (rolesForUrl.includes(ROLE_ADMIN)) return { role: ROLE_ADMIN, url: url };
                    if (rolesForUrl.includes(ROLE_MANAGER)) return { role: ROLE_MANAGER, url: url };
                    if (rolesForUrl.includes(ROLE_SPEAKER)) return { role: ROLE_SPEAKER, url: url };
                    if (rolesForUrl.includes(ROLE_VIEW)) return { role: ROLE_VIEW, url: url };
                    return { role: ROLE_NONE, url: url };
                }
            }
        }
        return { role: ROLE_NONE, url };
    }

    getCustomRoles(user) {
        const { url } = this.props;

        if (user) {
            if (user.roles) {
                let roleData = [];
                if (url) {
                    roleData = _.filter(user.roles.customRolesOf, roleData => roleData.url === url);
                } else {
                    roleData = user.roles.customRolesOf;
                }
                const mapped = _.map(roleData, role => role.role);
                return mapped;
            }
        }
    }

    handleChange = async (e, user, updateUserRoleForUrl) => {
        const target = e.target;
        const newRole = target.value === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const urlOfRole = this.getRole(user).url;

        const { t, confirm } = this.props;

        if (newRole === ROLE_NONE) {
            this.handleRemoveUser(user, updateUserRoleForUrl);
        } else if (
            await confirm(t(`common:${localNamespace}.confirmations.change_role`, { newRole }))
        ) {
            updateUserRoleForUrl({
                variables: {
                    url: urlOfRole,
                    userId: user._id,
                    role: newRole,
                },
            });
        }
    };

    handleAddUser = async (user, updateUserRoleForUrl) => {
        const urlOfRole = this.getRole(user).url;
        const { t, confirm } = this.props;
        if (
            await confirm(
                t(`common:${localNamespace}.confirmations.add_user`, {
                    userName: user.profile.full_name,
                })
            )
        ) {
            updateUserRoleForUrl({
                variables: {
                    url: urlOfRole,
                    userId: user._id,
                    role: ROLE_ADMIN,
                },
            });
        }
    };

    handleAddCustomRole = async (user, role, updateCustomUserRoleForUrl) => {
        const { t, confirm } = this.props;
        if (await confirm(t(`common:${localNamespace}.confirmations.add_role`, { role }))) {
            const urlOfRole = this.getRole(user).url;
            updateCustomUserRoleForUrl({
                variables: {
                    url: urlOfRole,
                    userId: user._id,
                    role,
                },
            });
        }
    };

    handleRemoveCustomRole = async (user, role, updateCustomUserRoleForUrl) => {
        const { t, confirm } = this.props;
        if (await confirm(t(`common:${localNamespace}.confirmations.remove_role`))) {
            const urlOfRole = this.getRole(user).url;
            updateCustomUserRoleForUrl({
                variables: {
                    url: urlOfRole,
                    userId: user._id,
                    role,
                    remove: true,
                },
            });
        }
    };

    handleRemoveUser = async (user, updateUserRoleForUrl) => {
        const { t, confirm, newToast } = this.props;
        if (user.profile.loggedInUserConnectionStatus === 'isLoggedInUser') {
            newToast(
                <AlertToast
                    boldText={`${t(`common:${localNamespace}.unable_to_remove_yourself`)}`}
                    showWarningIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
        } else {
            const urlOfRole = this.getRole(user).url;
            if (
                await confirm(
                    t(`common:${localNamespace}.confirmations.remove_user`, {
                        userName: user.profile.full_name,
                    })
                )
            ) {
                updateUserRoleForUrl({
                    variables: {
                        url: urlOfRole,
                        userId: user._id,
                        role: ROLE_NONE,
                    },
                });
            }
        }
    };

    createNewCustomRole = () => {
        const { allCustomRoles, mutate, url, t } = this.props;
        const roleName = prompt(t(`common:${localNamespace}.create_role.name`));
        if (roleName) {
            const roleUrl = prompt(t(`common:${localNamespace}.create_role.url`));
            if (roleUrl) {
                const roleColor = prompt(
                    `${t(`common:${localNamespace}.create_role.color`)} (#ffffff)`
                );

                const existingRoles = _.map(allCustomRoles, item => ({
                    id: item.id,
                    name: item.name,
                    url: item.url,
                    color: item.color,
                }));

                const newRole = {
                    name: roleName,
                    url: roleUrl,
                    color: roleColor,
                };

                mutate({
                    variables: {
                        cardUrl: url,
                        customRoles: [...existingRoles, newRole],
                    },
                });
            }
        }
    };

    render() {
        const { t, member, allCustomRoles, useColumns, showCustomRoles } = this.props;

        if (!member) {
            return null;
        }

        let selectedRoles = [];
        if (allCustomRoles) {
            selectedRoles = allCustomRoles.filter(roles => {
                return member.roles.customRolesOf.find(role => {
                    return role.role === roles.url;
                });
            });
        }

        const defaultRole = this.getRole(member).role;
        const isRemovedMember = defaultRole !== ROLE_ADMIN;
        const roleTypes = [
            { text: t(`common:${localNamespace}.role_admin`), value: ROLE_ADMIN },
            { text: t(`common:${localNamespace}.role_member`), value: ROLE_VIEW },
        ];

        const customRoleTypes = [];

        _.each(allCustomRoles, role => {
            customRoleTypes.push({
                text: role.name,
                value: role.url,
                color: role.color,
            });
        });
        customRoleTypes.push({
            text: t(`common:${localNamespace}.create_new_role`),
            value: '-create-new-',
            onClick: () => {
                this.createNewCustomRole();
            },
        });

        let defaultCustomRole = this.getCustomRoles(member);
        const _showCustomRoles = showCustomRoles && customRoleTypes.length > 0 && !isRemovedMember;

        let selectedRolesTabs = updateCustomUserRoleForUrl =>
            selectedRoles.map(role => {
                return (
                    <div
                        name={role.name}
                        onClick={(e, toggleValue) =>
                            this.handleRemoveCustomRole(
                                member,
                                role.url,
                                updateCustomUserRoleForUrl
                            )
                        }
                        className="select-option-item selected w-inline-block"
                        style={{ backgroundColor: role.color }}
                    >
                        <div>{role.name}</div>
                        <div className="select-option-line" />
                    </div>
                );
            });

        const _selectedRoles = _.map(selectedRoles, role => role.url);

        const profileImage = {
            backgroundImage: `url(${
                member.profile.picture ? member.profile.picture : getRandomDefaultUserImage()
            })`,
            margin: '10px',
        };
        const profileCoverStyle = getBackgroundImageWithGradient(
            member.profile.coverPhoto
                ? member.profile.coverPhoto
                : getRandomDefaultCoverImage(member.profile.full_name),
            0.3,
            0.6
        );
        profileCoverStyle['color'] = 'white';

        return (
            <div className={useColumns ? 'community-col w-col w-col-6 w-col-small-6' : {}}>
                <div
                    className="people-listing-small-block"
                    style={isRemovedMember ? { opacity: 0.8 } : {}}
                >
                    <Mutation mutation={UPDATE_USER_ROLE_FOR_URL}>
                        {updateUserRoleForUrl => (
                            <div className="small-listing-header cover" style={profileCoverStyle}>
                                <div className="small-listing-thumb" style={profileImage}>
                                    <img className="listing-profile-icon" alt="" />
                                </div>
                                <div className="small-listing-header-conentent">
                                    <div>
                                        <b>
                                            {member.profile.full_name
                                                ? member.profile.full_name
                                                : t(`common:${localNamespace}.anonymous`)}
                                        </b>
                                        {isRemovedMember && (
                                            <span style={{ color: 'white' }}>
                                                {' '}
                                                • {t(`common:${localNamespace}.unassigned`)}
                                            </span>
                                        )}
                                    </div>
                                    <div className="margin-clear w-form">
                                        <form
                                            id="email-form"
                                            name="email-form"
                                            data-name="Email Form"
                                        >
                                            {member.profile.short_bio}
                                            <div className="edit-item-clear-column" />
                                        </form>
                                    </div>
                                </div>
                                {isRemovedMember ? (
                                    <div
                                        onClick={() =>
                                            this.handleAddUser(member, updateUserRoleForUrl)
                                        }
                                        className="add-item-button white w-inline-block"
                                    />
                                ) : (
                                    <div
                                        onClick={() =>
                                            this.handleRemoveUser(member, updateUserRoleForUrl)
                                        }
                                        className="delete-item-button w-inline-block"
                                    />
                                )}
                            </div>
                        )}
                    </Mutation>
                    {_showCustomRoles && (
                        <div className="small-listing-roles-container">
                            <div className="listing-roles-container">
                                <Mutation mutation={UPDATE_CUSTOM_USER_ROLE_FOR_URL}>
                                    {updateCustomUserRoleForUrl => (
                                        <EditScreenTagSelect
                                            target="customUserRole"
                                            placeholder={t(
                                                `common:${localNamespace}.roles_placeholder`
                                            )}
                                            options={customRoleTypes}
                                            selected={_selectedRoles}
                                            allowMultiple
                                            handleAddToList={(name, value) => {
                                                this.handleAddCustomRole(
                                                    member,
                                                    value,
                                                    updateCustomUserRoleForUrl
                                                );
                                            }}
                                            handleRemoveFromList={(name, value) => {
                                                this.handleRemoveCustomRole(
                                                    member,
                                                    value,
                                                    updateCustomUserRoleForUrl
                                                );
                                            }}
                                        />
                                    )}
                                </Mutation>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default graphql(UPDATE_CARD, { alias: 'updateCard' })(
    withTranslation()(EditScreenCustomRole)
);
