import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import useModal from '@components/modals/hooks/useModal';

import TicketItem from '@components/card/Shopping-Cart/TicketItem';
import StripeLogo from '@dsc/svgs/StripeLogo';
import Ticket from '@dsc/svgs/Ticket';
import TicketSharing from '@components/card/Shopping-Cart/TicketSharing/TicketSharing';
import StripeCustomerOrderHistoryItemListView from '@components/profile/stripe/StripeCustomerOrderHistoryItemListView';

import {
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import {
    CustomerOrder,
    Group,
    StripeRefund,
    StripeCharge,
    StripeSubscriptionStatus,
} from '@shared/welibrary-graphql/types';
import { ModalTypes } from '@core/types/Modals';
import {
    OrderStatusEnum,
    RefundStatusEnum,
    convertCentsToDollars,
} from '@components/profile/stripe/stripeOrderHelpers';
import { getCurrencyDisplayString } from '@web/utilities/helpers/money/money.helpers';
import { getOrderTotal } from '@components/card/Shopping-Cart/CheckoutHelpers';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';
import { PAYMENT_TYPES } from '@stores/ShoppingCart';

type StripeCustomerOrderHistoryItemProps = {
    order: CustomerOrder;
    showListView?: boolean;
    className?: string;
    orderHistoryItemClassName?: string;
    activeTab?: string;
    noShadow?: boolean;
    isSignupGuestCheckout?: boolean;
};

const StripeCustomerOrderHistoryItem: React.FC<StripeCustomerOrderHistoryItemProps> = ({
    order,
    showListView = false,
    className,
    orderHistoryItemClassName = '',
    activeTab,
    noShadow = false,
    isSignupGuestCheckout = false,
}) => {
    const history = useHistory();
    const { newModal, closeAllModals } = useModal();
    const { newModal: centerModal } = useModal({
        desktop: ModalTypes.Center,
        mobile: ModalTypes.Center,
    });

    const orderId = order?.paymentIntent?.id ?? order?._id; // stripe orderID
    const purchaseRequests = order?.purchaseRequests ?? [];
    const [purchaseRequest] = order?.purchaseRequests;

    const product = purchaseRequest?.product ?? order?.products[0]; // this assumes the order has 1 product / purchaseRequest attached to it
    const event: Group = product?.reference; // group / event
    let quantity = 0;

    if (purchaseRequests?.length > 0) {
        order?.purchaseRequests?.forEach(({ quantity: qty }) => {
            quantity += qty;
        });
    } else if (order?.productInstances?.length > 0) {
        quantity = order?.productInstances.length;
    } else {
        quantity = 1;
    }

    const createdAt = new Date(Number(order?.created));
    const formattedDate = moment(createdAt).format('MMMM D, YYYY');

    const [coverSrc, coverSrcSet] = getBackgroundImageSetForUrl(
        event?.profile?.coverPhoto || getRandomDefaultCoverImage(event?.profile?.full_name)
    );

    // currency the transaction was completed in
    const currency = order?.paymentIntent?.currency ?? 'USD';

    // this assumes because the payment-intent is null,
    // the order was fully-discounted
    const orderWasFullyDiscounted = order?.paymentIntent === null;

    const refunds: StripeRefund[] = order?.paymentIntent?.charges?.data?.[0]?.refunds?.data ?? [];
    const latestCharge: StripeCharge | undefined = order?.paymentIntent?.charges?.data?.[0] ?? {};
    const latestRefund: StripeRefund | undefined =
        order?.paymentIntent?.charges?.data?.[0]?.refunds?.data?.[0] ?? {};
    const orderWasRefunded: boolean =
        // check the latest charge was refunded
        latestCharge?.amount_refunded > 0 &&
        // check the latest charge refund status was successful
        (latestRefund?.status === RefundStatusEnum.Succeeded ||
            latestRefund?.status === RefundStatusEnum?.Pending);
    const orderRefundFailed = latestRefund?.status === RefundStatusEnum?.Failed;
    const orderWasFullyRefunded: boolean =
        latestCharge?.amount_refunded === order?.paymentIntent?.amount;
    const orderWasPartiallyRefunded: boolean =
        latestCharge?.amount_refunded !== order?.paymentIntent?.amount;

    let statusText: React.ReactNode;

    // order payment status
    if (orderWasFullyDiscounted) {
        statusText = <span className="order-status-success">Payment Completed</span>;
    } else if (order?.paymentIntent?.status === OrderStatusEnum.Succeeded) {
        statusText = <span className="order-status-success">Payment Completed</span>;
    } else if (order?.paymentIntent?.status === OrderStatusEnum.Canceled) {
        statusText = <span className="order-status-cancelled">Order Canceled</span>;
    } else if (order?.paymentIntent?.status === OrderStatusEnum.Processing) {
        statusText = <span className="order-status-pending">Payment Pending</span>;
    }

    // order refund status
    if (orderWasRefunded && orderWasFullyRefunded) {
        statusText = <span className="order-status-refund">Full Refund</span>;
    } else if (orderWasRefunded && orderWasPartiallyRefunded) {
        statusText = <span className="order-status-refund">Partial Refund</span>;
    } else if (latestRefund?.status === RefundStatusEnum.Pending) {
        statusText = <span className="order-status-refund-pending">Refund Pending</span>;
    } else if (orderRefundFailed) {
        statusText = <span className="order-status-refund-failed">Refund Failed</span>;
    }

    let subscriptionStatus: React.ReactNode | null = null;

    // subscription status
    if (order?.subscription?.status === StripeSubscriptionStatus.Active) {
        subscriptionStatus = (
            <span className="order-list-item-sub-status order-status-active">
                {order?.subscription.status}
            </span>
        );
    } else if (
        order?.subscription?.status === StripeSubscriptionStatus.Canceled ||
        order?.subscription?.status === StripeSubscriptionStatus.Incomplete ||
        order?.subscription?.status === StripeSubscriptionStatus.IncompleteExpired ||
        order?.subscription?.status === StripeSubscriptionStatus.Unpaid
    ) {
        subscriptionStatus = (
            <span className="order-list-item-sub-status order-status-cancelled">
                {order?.subscription.status}
            </span>
        );
    } else if (order?.subscription?.status === StripeSubscriptionStatus.PastDue) {
        subscriptionStatus = (
            <span className="order-list-item-sub-status order-status-pastdue">
                {order?.subscription.status}
            </span>
        );
    } else if (order?.subscription?.status === StripeSubscriptionStatus.Trialing) {
        subscriptionStatus = (
            <span className="order-list-item-sub-status order-status-trial">
                {order?.subscription.status}
            </span>
        );
    }

    // detached order status
    if (order?.status === OrderStatusEnum.RequiresConfirmation) {
        statusText = <span className="order-status-pending">Requires Confirmation</span>;
    } else if (order?.status === OrderStatusEnum.RequiresPaymentMethod) {
        statusText = <span className="order-status-pending">Requires Payment</span>;
    }

    const groupName = product?.reference?.profile?.full_name;
    const purchaser = order?.user;

    // get order tickets by orderId
    const handleViewTicket = () => {
        newModal(<TicketItem orderId={order?._id} />, {
            className: 'no-top-padding no-bottom-padding no-notch ticket-modal',
            hideButton: true,
        });
    };

    const handleTicketSharing = () => {
        centerModal(<TicketSharing orderId={order?._id} />);
    };

    const currentUserIsAdmin = event?.currentUserIsAdmin || event?.currentUserIsDirectAdmin;
    const isMultipleTickets = order?.purchaseRequests?.length > 1;
    const allTicketsAreCheckedIn = !order?.productInstances.find(p => !p.isCheckedIn);
    const disableViewingAndTransferingTickets =
        order?.productInstances?.length === 0 ||
        order?.subscription ||
        currentUserIsAdmin ||
        orderWasRefunded;

    const canTransferTickets = (): boolean => {
        const transferableTickets =
            order.productInstances?.filter(t => {
                return t?.transferToken !== null && t?.isCheckedIn !== true;
            }) ?? [];
        if (transferableTickets.length > 0) return true;
        return false;
    };

    const refundList: React.ReactNode =
        refunds.length > 0 &&
        refunds.map(refund => {
            const _refund: StripeRefund = refund;
            const refundIssuedDate = moment.unix(_refund?.created).format('MMM D, YYYY');

            const refundAmount: string = convertCentsToDollars(_refund?.amount);
            const refundAmountDisplayString = getCurrencyDisplayString(
                refundAmount,
                currency,
                undefined,
                true,
                2
            );
            const refundAmountElement: React.ReactNode = currentUserIsAdmin ? (
                <p className="order-refund-amount">-{refundAmountDisplayString}</p>
            ) : (
                <p className="order-refund-amount-issued">{refundAmountDisplayString}</p>
            );

            return (
                <>
                    {!currentUserIsAdmin && (
                        <div className="order-refund-list-title">
                            <h5>{statusText}</h5>
                        </div>
                    )}
                    <div className="order-refund-list">
                        <p className="order-refund-date">
                            Refund: <span>{refundIssuedDate}</span>
                        </p>
                        {refundAmountElement}
                    </div>
                    {_refund.status === RefundStatusEnum.Failed && (
                        <div className="order-refund-error order-refund-error-failed">
                            <p>{_refund?.failure_reason}</p>
                        </div>
                    )}
                </>
            );
        });

    const allowRefund =
        currentUserIsAdmin &&
        !orderWasFullyDiscounted &&
        !orderWasRefunded &&
        !orderRefundFailed &&
        (order?.paymentIntent?.status === OrderStatusEnum.Succeeded ||
            order?.subscription?.status === StripeSubscriptionStatus.Active);

    if (showListView) {
        return (
            <StripeCustomerOrderHistoryItemListView
                order={order}
                orderStatus={statusText}
                refundList={refundList}
                quantity={quantity}
                productName={product?.name}
                purchaser={purchaser}
                event={event}
                showRefundButton={allowRefund}
                activeTab={activeTab}
            />
        );
    }

    let total = order?.paymentIntent?.amount
        ? convertCentsToDollars(order?.paymentIntent?.amount)
        : convertCentsToDollars(getOrderTotal(order));

    const isFree = order?.products?.[0]?.type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP;
    if (isFree) total = convertCentsToDollars(0);

    const totalDisplayString = getCurrencyDisplayString(total, currency, undefined, true, 2);

    return (
        <>
            <div className={`order-history-item-wrap ${className}`}>
                <div
                    className={`order-history-item ${noShadow ? '!shadow-none' : ''
                        } ${orderHistoryItemClassName}`}
                >
                    <div className="order-history-item-cover-wrap-mobile">
                        <StyledBackgroundImage
                            disableLink
                            src={coverSrc}
                            srcSet={coverSrcSet}
                            className="order-history-item-cover-mobile"
                        />
                        {event?.subtype && (
                            <div className="order-history-item-icon-wrap">
                                <Ticket className="order-history-item-icon" strokeColor="#fff" />
                            </div>
                        )}
                    </div>
                    <div className="order-history-item-ticket-wrap">
                        <div className="order-history-item-ticket">
                            <div className="order-history-item-cover-wrap">
                                <StyledBackgroundImage
                                    disableLink
                                    src={coverSrc}
                                    srcSet={coverSrcSet}
                                    className="order-history-item-cover"
                                />
                                {event?.subtype && (
                                    <div className="order-history-item-icon-wrap">
                                        <Ticket
                                            className="order-history-item-icon"
                                            strokeColor="#fff"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="order-history-item-details-wrap">
                                {event?.subtype ? (
                                    <h5 className="order-history-item-details-name">{groupName}</h5>
                                ) : (
                                    <>
                                        <h5
                                            className={`order-history-item-details-name ${product?.description &&
                                                'order-history-item-details-no-description'
                                                }`}
                                        >
                                            {product?.name}
                                        </h5>
                                        <p className="order-history-item-details-group">
                                            {groupName}
                                        </p>
                                        {product?.description && (
                                            <p className="order-history-item-details-description">
                                                {product?.description}
                                            </p>
                                        )}
                                    </>
                                )}

                                {event?.subtype && (
                                    <p className="order-history-item-inner-details-qty">
                                        {quantity} {quantity !== 1 ? 'Tickets' : 'Ticket'}
                                    </p>
                                )}
                                <p className="order-history-item-inner-details">
                                    {!disableViewingAndTransferingTickets && (
                                        <>
                                            {/* 
                                            this assumes because the payment-intent is null,
                                            the order was fully-discounted 
                                        */}
                                            {(order?.paymentIntent?.status === 'succeeded' ||
                                                order?.paymentIntent === null) && (
                                                    <span
                                                        onClick={handleViewTicket}
                                                        className="order-history-item-inner-details-ticket"
                                                    >
                                                        View
                                                    </span>
                                                )}
                                            {event?.subtype && (
                                                <>
                                                    {!allTicketsAreCheckedIn && (
                                                        <>
                                                            <span className="order-history-item-bullet">
                                                                &nbsp;&bull;&nbsp;
                                                            </span>
                                                            <span
                                                                onClick={handleTicketSharing}
                                                                className="order-history-item-inner-details-ticket"
                                                            >
                                                                Transfer
                                                            </span>
                                                        </>
                                                    )}
                                                    <span className="order-history-item-bullet">
                                                        &nbsp;&bull;&nbsp;
                                                    </span>
                                                    <span
                                                        onClick={() => {
                                                            closeAllModals();
                                                            history.push(
                                                                `/g/${event?._id}?showShoppingCart=true`
                                                            );
                                                        }}
                                                        className="order-history-item-inner-details-ticket"
                                                    >
                                                        Purchase More Tickets
                                                    </span>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {!event?.subtype && (
                                        <>
                                            <span
                                                onClick={() => history.push(`/g/${event?._id}`)}
                                                className="order-history-item-inner-details-ticket"
                                            >
                                                View Group
                                            </span>
                                        </>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="order-history-payment-info-wrap">
                        <div className="order-history-payment-info-header">
                            <h3>Order #{orderId}</h3>
                            <h3 className="order-history-payment-date">{formattedDate}</h3>
                        </div>
                        <div className="order-history-payment-info-type">
                            {event?.subtype && (
                                <div>
                                    <p className="font-bold">Ticket Quantity: {quantity}</p>
                                    {purchaseRequests?.map(({ product: p, quantity: qty }) => {
                                        return (
                                            <p key={p?._id} className="text-sm">
                                                {qty}x {p?.name}
                                            </p>
                                        );
                                    })}
                                </div>
                            )}

                            <p>{purchaser?.profile?.full_name}</p>
                            {order.paymentType === PAYMENT_TYPES.cash ? (
                                <p>Cash</p>
                            ) : (
                                <p>Card *********</p>
                            )}

                            <div className="order-history-item-inner-details-wrap">
                                <p className="order-history-item-inner-details-status">
                                    STATUS:&nbsp; {statusText}&nbsp; via &nbsp; <StripeLogo />
                                </p>
                            </div>
                        </div>
                        <div className="order-history-payment-info-total">
                            <h3>Total: {totalDisplayString}</h3>
                        </div>
                        {refundList}
                        {order?.subscription && (
                            <div className="order-details-subs-status-wrap">
                                <p>Subscription Status: {subscriptionStatus}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StripeCustomerOrderHistoryItem;
