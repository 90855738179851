import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';

import { awsMetricPublisher } from '@welibraryos/metrics';

import Postbox from '@web/ui/components/content/newpost/Postbox';
import NewPostOverlay from '@web/ui/components/content/newpost/NewPostOverlay';
import Modal from '@web/ui/components/generic/lightbox/Modal.js';
import useNewPostOverlay from '@web/ui/components/content/newpost/useNewPostOverlay';
import ReferenceCardHeader from '@web/ui/components/card/ReferenceCardHeader';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

const NewPostContainer = ({
    allowedTypes,
    group,
    prompt,
    feedUrl,
    chapter,
    chapterShowTitle,
    showReferenceForChapter,
    showConfirmation,
    visibilityRefSetting,
    useWorkflow,
    sortType,
    onPromptSubmitted,
    paddingTop,
    displaySocialHeaders,
    paddingSides,
    marginBottom,
    threeDotRightMargin,
    initOpenModal,
    className = '',
    newPostOptions,
    width = '100%',
}) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();
    const newPostProps = {
        allowedTypes,
        prompt,
        feedUrl,
        newPostOptions,
        chapter,
        chapterShowTitle,
        showReferenceForChapter,
        showConfirmation,
        threeDotRightMargin,
        displaySocialHeaders,
        visibilityRefSetting,
        useWorkflow,
        sortType,
        onPromptSubmitted,
    };

    const { openNewPost } = useNewPostOverlay(newPostProps);

    const [openModal, setOpenModal] = useState(null);

    const defaultPromptText = prompt || t(`common:${localNamespace}.share_something`);

    const newPostTopStyle = {};
    if (threeDotRightMargin) {
        newPostTopStyle.paddingRight = '30px';
    }

    const profilePhotoUrl = currentUser?.profile?.picture ?? '';

    const openNewPostModal = () => {
        if (!currentUser) {
            // TODO: REDIRECT TO SIGNUP
            window.location = `/join?returnTo=${window.location.pathname}`;
        } else {
            setOpenModal(true);
            awsMetricPublisher.publishCount('new-post-modal-open', 1);
        }
    };

    const closeNewPostModal = () => {
        setOpenModal(false);
    };

    // Add a delay to opening to prevent other modal updates closing overlay
    if (initOpenModal && openModal === null) {
        setTimeout(() => {
            setOpenModal(true);
            openNewPost();
        }, 500);
    }

    return (
        <div
            style={{
                width,
                paddingTop,
                paddingLeft: paddingSides,
                paddingRight: paddingSides,
            }}
            className={className}
        >
            {showReferenceForChapter && <ReferenceCardHeader source={showReferenceForChapter} />}

            <Postbox
                marginBottom={marginBottom}
                displaySocialHeaders={displaySocialHeaders}
                onClick={openNewPostModal}
                defaultPromptText={defaultPromptText}
                profilePhotoUrl={profilePhotoUrl}
            />

            <Modal
                className="modal"
                delayTime={500}
                isMounted={openModal}
                close={closeNewPostModal}
                contentCard
            >
                <div className="new-post-overlay-wrapper">
                    <NewPostOverlay
                        allowedTypes={allowedTypes}
                        prompt={prompt}
                        feedUrl={feedUrl}
                        chapter={chapter}
                        group={group}
                        chapterShowTitle={chapterShowTitle}
                        closeModal={closeNewPostModal}
                        showReferenceForChapter={showReferenceForChapter}
                        showConfirmation={showConfirmation}
                        threeDotRightMargin={threeDotRightMargin}
                        displaySocialHeaders={displaySocialHeaders}
                        visibilityRefSetting={visibilityRefSetting}
                        useWorkflow={useWorkflow}
                        sortType={sortType}
                        onPromptSubmitted={onPromptSubmitted}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default NewPostContainer;
