import React, {useState} from 'react';

export default ({ value, target, offText, onText }) => {
    let [isOn, setOn] = useState(false);

    //let isOn = value;
    let containerStyle = isOn
        ? { backgroundColor: 'rgb(0, 148, 246)', transition: 'background-color 200ms' }
        : { backgroundColor: 'rgb(192, 192, 192)', transition: 'background-color 200ms' };

    return (
        <div
            onClick={() => setOn(!isOn)}
            className="event-card-attendance-button w-inline-block"
            style={containerStyle}
        >
            <div className="event-attend-text" style={{ display: isOn ? 'inline-block' : 'none' }}>
                {onText}
            </div>
            <div
                className="event-attending-text"
                style={{ display: isOn ? 'none' : 'inline-block' }}
            >
                {offText}
            </div>
        </div>
    );
};
