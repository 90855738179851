import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

const executeUserSnapScript = (testerEmail?: string) => {
    const { userSnap, userSnapOverrideSrc, userSnapTesterEmails } = getWhitelabelPublicSettings();
    if (userSnap || userSnapTesterEmails?.includes(testerEmail ?? '')) {
        const existingUserSnapScript = document.getElementById('usersnap-script');
        if (!existingUserSnapScript) {
            const userSnapSource =
                '//api.usersnap.com/load/16198491-73f5-49ab-be85-f71046d333ce.js';

            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.id = 'usersnap-script';
            s.src = userSnapOverrideSrc ?? userSnapSource;

            const x = document.getElementsByTagName('script')[0];
            x.parentNode?.insertBefore(s, x);
        }
    }
};

export default executeUserSnapScript;
