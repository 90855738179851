import React from 'react';

type RadioButtonProps = {
    options: { text: string; value: string }[];
    selected: string;
    handleRadio: (selectedOption: string) => void;
    name: string;
};

const RadioButton: React.FC<RadioButtonProps> = ({ options, selected, handleRadio, name }) => {
    return (
        <div className="radio-button-container">
            {options.map(option => {
                const id = `${name}-${option.value}`;

                return (
                    <div className="radio-item" key={id}>
                        <input
                            type="radio"
                            className="radio-input"
                            id={id}
                            name={name}
                            value={option.value}
                            checked={option.value === selected}
                            onChange={() => handleRadio(option.value)}
                        />
                        <label htmlFor={id} className="radio-label">
                            {option.text}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default RadioButton;
