import { useCurrentUser } from '@stores/User';
import { config } from '@config/getConfig';

import getLogger from '@core/logger';

const logger = getLogger(module);

export const useMetric = () => {
      const { currentUser } = useCurrentUser();

    const defaults = {
        domain: config.public.root_url,
        stage: config.public.configName,
        userId: currentUser?._id ?? '',
        guardianCode: currentUser?.guardianCode,
    };

    return (data: Record<string, any>) => logger.metrics({ ...defaults, ...data });
};

export default useMetric;
