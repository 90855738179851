import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';

import WarningIcon from '@dsc/svgs/Warning';
import Button from '@dsc/forms/buttons/Button';

type SubscriptionConfirmationProps = {
    title: string;
    text: string;
    confirmationButtonText: string;
    onConfirm: () => void;
    onCancel?: () => void;
    className?: string;
};

const SubscriptionConfirmation: React.FC<SubscriptionConfirmationProps> = ({
    title,
    text,
    confirmationButtonText = 'Ok',
    onConfirm,
    onCancel,
    className = '',
}) => {
    const { t } = useTranslation();

    const { closeModal } = useModal();

    const confirm = () => {
        onConfirm();
        closeModal();
    };

    const cancel = () => {
        onCancel?.();
        closeModal();
    };

    return (
        <section role="alert" className={`subscription-confirmation ${className}`}>
            <WarningIcon />

            <h3>{title}</h3>

            <p>
                {text} <strong>This cannot be undone!</strong>
            </p>

            <footer>
                <Button
                    onClick={cancel}
                    type="small"
                    className="subscription-confirmation-btn-cancel"
                >
                    {t('common:cancel', 'Cancel')}
                </Button>
                <Button onClick={confirm} type="small" className="subscription-confirmation-btn-ok">
                    {confirmationButtonText}
                </Button>
            </footer>
        </section>
    );
};

export default SubscriptionConfirmation;
