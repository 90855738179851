import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { awsMetricPublisher } from '@welibraryos/metrics';
import ControlledToggle from '@web/ui/components/generic/buttons/ControlledToggle';
import { getOptionalPostTypes } from '@core/utilities/whitelabel_helpers';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import ReferenceCardHeader from '@web/ui/components/card/ReferenceCardHeader';
import ToastContext from '@web/ui/components/generic/context/ToastContext';
import { useCurrentUser } from '@stores/User';
import {
    ATTACH_IMAGE,
    ATTACH_IMAGE_SQUARE,
    ATTACH_LINK_TILE,
    ATTACH_POSTBOX,
    ATTACH_STATE_PREVIEW,
    ATTACH_STATE_UPLOAD,
} from '@core/utilities/constants/attachment_types';
import useLazyWorkflow from '@web/ui/components/generic/hooks/useLazyWorkflow';
import {
    ENGAGE_COMMENT,
    ENGAGE_HEART,
    ENGAGE_SHARE,
} from '@core/utilities/constants/engagement_types';
import { getFeatureConstraintsForField } from '@core/utilities/constants/feature_constraints';
import { isUserGroupAdmin } from '@web/utilities/helpers/user/user.helpers';
import PreviewContainer from './PreviewContainer';
import StagingContainer from './StagingContainer';
import NewPostIconsContainer from './NewPostIconsContainer';
import NewPostOverlayFormContainer from './NewPostOverlayFormContainer';
import NewPostMainTextInput from './NewPostMainTextInput';
import NewPostSettings from './NewPostSettings';
import NewPostTypesOptions from './NewPostTypesOptions';
import ToggleVisibility from './ToggleVisibility';
import VisibilitySetting from './VisibilitySetting';
import { getAutoOpenType } from './constants';
import useNewPost from './useNewPost';
import { focusAndOpenKeyboard } from './newposthelpers';
import { CARD_EVENT, DEFAULT_POST_TYPES } from '~/wl-core/utilities/constants/card_types';

// Hide text area input for these types
const NO_TEXT_AREA_TYPES = [ATTACH_POSTBOX, ATTACH_LINK_TILE];
const NO_TITLE_FIELD_TYPES = [ATTACH_IMAGE, ATTACH_IMAGE_SQUARE];
const CONFIRMATION_LENGTH = 19000;

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';
const updateCardNamespace = 'imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm';
const adminAnnouncementNamespace =
    'imports.wlWeb.ui.components.generic.editScreen.sendAdminNotificationContainer';

// Interfaces for state and component props
interface NewPostOverlayContainerState {
    showAllAttachOptions: boolean;
    showModal: boolean;
    formName: string;
    allowSubmit: boolean;
    showTextArea: boolean;
    showPostTypes: boolean;
    isOpen?: boolean;
}

interface NewPostOverlayContainerProps {
    allowedTypes: string[];
    prompt: string;
    formTitle?: string;
    feedUrl: string;
    group: any;
    chapter: any;
    chapterShowTitle: boolean | null;
    closeModal: () => void;
    showReferenceForChapter: any;
    showConfirmation: boolean;
    threeDotRightMargin: boolean;
    displaySocialHeaders: boolean | null;
    visibilityRefSetting: any;
    useWorkflow: any;
    sortType: string;
    onPromptSubmitted: () => void;
    newPostOptions: any;
}

const initialState: NewPostOverlayContainerState = {
    showAllAttachOptions: true,
    showModal: false,
    formName: '',
    allowSubmit: false,
    showTextArea: true,
    showPostTypes: false,
};

const NewPostOverlayContainer: React.FC<NewPostOverlayContainerProps> = ({
    allowedTypes,
    prompt,
    formTitle = '',
    feedUrl,
    group,
    chapter,
    chapterShowTitle,
    closeModal,
    showReferenceForChapter,
    showConfirmation,
    threeDotRightMargin,
    displaySocialHeaders,
    visibilityRefSetting,
    useWorkflow,
    sortType,
    onPromptSubmitted,
    newPostOptions,
}) => {
    const { t } = useTranslation();
    const { showToast } = useContext(ToastContext);
    const { currentUser } = useCurrentUser();
    const { fetchWorkflow, updateWorkflowState } = useLazyWorkflow();
    const autoOpenType = getAutoOpenType(allowedTypes);
    const submitButtonRef = React.useRef();
    const textAreaRef = React.useRef();
    const [state, setState] = useState(initialState);

    const handleSetState = newValues => setState(oldState => ({ ...oldState, ...newValues }));

    const closeNewPostModal = () => {
        if (closeModal) closeModal();
    };

    // Closes modal form
    const closeModalHandler = () => {
        handleSetState({ showModal: false });

        if (getAutoOpenType(allowedTypes)) closeNewPostModal();
    };

    // Opens form in modal
    const openModalHandler = selectName => {
        handleSetState({ showModal: true, formName: selectName });
        if (selectName === CARD_EVENT) {
            // If we're creating an event listing, close the post box modal so we don't have multiple modals up
            closeNewPostModal();
        }
    };

    useEffect(() => {
        if (autoOpenType && !state.showModal) {
            setTimeout(() => openModalHandler(autoOpenType.type), 100);
        }
    }, [autoOpenType]);

    useEffect(() => {
        if (useWorkflow) fetchWorkflow(useWorkflow, 'GROUP', 'grow-group');
    }, [useWorkflow]);

    const engagementTypesConstraint = getFeatureConstraintsForField('engagementTypes');

    const defaultEngagementTypes = [
        { text: t(`common:${updateCardNamespace}.comments`), value: ENGAGE_COMMENT },
        { text: t(`common:${updateCardNamespace}.share`), value: ENGAGE_SHARE },
        {
            text: t(`common:${'imports.wlWeb.ui.components.card.darkForms'}.heart`),
            value: ENGAGE_HEART,
        },
        // {text: "Follow",    value:ENGAGE_FOLLOW}
    ];

    const engagementTypes = engagementTypesConstraint?.options
        ? defaultEngagementTypes.filter(option =>
              engagementTypesConstraint.options.includes(option.value)
          )
        : defaultEngagementTypes;

    const defaultViewTypes = [
        {
            text: t('common:imports.wlWeb.ui.components.card.darkForms.settings.fullview'),
            value: 'fullview',
        },
        {
            text: t('common:imports.wlWeb.ui.components.card.darkForms.settings.listview'),
            value: 'listview',
        },
    ];

    const useNewPostOptions = {
        displaySocialHeaders,
        displayInNewsfeed: chapter?.library_settings?.displayInNewsfeed ?? null,
        currentUserCanSuggestEdit: chapter?.currentUserCanSuggestEdit ?? null,
        engagementTypesOptions: engagementTypes,
        engagementTypes: chapter?.library_settings?.engagementTypes ?? null,
        defaultViewTypes,
        ...newPostOptions,
    };

    // Used for tracking what the inherited value was, for removing if the end option matches inherited option
    const useNewPostInheritedOptions = {
        displaySocialHeaders,
        defaultViewType: chapter?.library_settings?.defaultViewType ?? null,
        engagementTypes: chapter?.library_settings?.engagementTypes ?? null,
        currentUserCanSuggestEdit: chapter?.currentUserCanSuggestEdit ?? null,
        inheritSurveyAnswers: chapter?.inheritSurveyAnswers ?? null,
    };

    // Initialize New Post hook which will initialize a new post state
    // and will keep track of the post state
    const {
        handleTextAreaChange: handleTextArea,
        handleTitleChange,
        handleAnonymousToggle,
        state: newPostState,
        setState: setNewPostState,
        handleSetState: handleSetNewPostState,
        submitPost: submitNewPost,
        submitAdminAnnouncement,
        handleFetchLink,
        handleSquarePhotoSelect,
        handleToggle,
        handleChange,
        handleAddToList,
        handleRemoveFromList,
        addAttachedContent,
        allAllowedTypes,
        handleFileSelect,
        handleImgToggle,
        expandAttachment,
        truncateAttachment,
        clearAttachment,
        updateCacheAfterSubmit,
        submitNewPostloading,
        handleSetCustomFields,
    } = useNewPost(
        currentUser,
        feedUrl,
        allowedTypes,
        openModalHandler,
        useNewPostOptions,
        useNewPostInheritedOptions
    );

    // On load, autofocus text area
    useEffect(() => {
        focusAndOpenKeyboard(textAreaRef.current);
    }, []);

    // On attached content, -> do something, in this case,
    // because of the way the blog form is currently
    // the submit button attaches the blog state to the post state and needs to then submit
    // This was previously handled via set state callback
    useEffect(() => {
        if (newPostState?.attachedContent?.type === 'attach_blog') handleSubmitPost();
    }, [newPostState.attachedContent]);

    // Whether to show attachment preview
    const shouldShowAttachment = type => newPostState.attachmentOpen === type;

    // Show all available post types
    const expandAttachOptions = () => {
        if (!state.showAllAttachOptions) handleSetState({ showAllAttachOptions: true });
    };

    // Display post confirmation toast
    const handleShowConfirmation = linkUrl => {
        showToast('Posted successfully.', linkUrl, CONFIRMATION_LENGTH);
        closeNewPostModal();
    };

    const expandContainer = () => {
        if (!state.isOpen) handleSetState({ isOpen: true });
    };

    const truncateContainer = input => {
        if (state.isOpen) {
            if (input) {
                input.value = '';
                input.style.height = '30px';
            }

            handleSetState({ isOpen: false, showAllAttachOptions: false });
            truncateAttachment();
        }
    };

    // Action invoked after submission
    const postResAction = async res => {
        truncateContainer();
        clearAttachment();

        if (useWorkflow) updateWorkflowState(feedUrl, 'complete', true, 'CHAPTER');

        if (onPromptSubmitted) onPromptSubmitted();

        if (showConfirmation) {
            const linkUrl = res?.data?.attachedCard?.url;
            handleShowConfirmation(linkUrl);
        } else {
            closeNewPostModal();
        }
    };

    const triggerMenu = () => {
        mobilecard.handleSubmit();
    };

    const resetForm = () => handleSetState({ formName: '' });

    // Logic for handling anonymous posting visual logic
    // displaySocialHeaders is from a chapter card via props
    // chapterSetting will override individual card setting unless chapterSetting is null
    let shouldShowAuthor;
    let lockSocialHeaderToggle = false;

    if (!displaySocialHeaders && displaySocialHeaders !== null) {
        shouldShowAuthor = displaySocialHeaders;
        lockSocialHeaderToggle = true;
    }

    if (displaySocialHeaders) {
        shouldShowAuthor = displaySocialHeaders;
        lockSocialHeaderToggle = true;
    }

    if (displaySocialHeaders === null) {
        shouldShowAuthor = newPostState.displaySocialHeaders;
        if (newPostState.displaySocialHeaders === null) shouldShowAuthor = true;
    }

    const handleAdminAnnouncement = () => {
        submitAdminAnnouncement(feedUrl)
            .then(() => {
                showToast(
                    t(`common:${adminAnnouncementNamespace}.sent`),
                    null,
                    CONFIRMATION_LENGTH
                );
                closeNewPostModal();
            })
            .catch(err => {
                showToast(err.message, null, CONFIRMATION_LENGTH);
                closeNewPostModal();
            });
    };

    const { isOpen } = state;

    let defaultPromptText = t(`common:${localNamespace}.share_something`);

    if (prompt && prompt !== '') defaultPromptText = prompt;

    const openCloseStyle = {};

    if (!isOpen) openCloseStyle.height = '0px';

    const newPostTopStyle = {};

    // if (threeDotRightMargin) newPostTopStyle.paddingRight = '30px';

    if (state.formName === 'blog') newPostTopStyle.paddingBottom = '0px';

    let profilePhotoUrl = '';
    let profileName = '';

    if (currentUser?.profile) {
        profilePhotoUrl = currentUser?.profile?.picture;
        profileName = shouldShowAuthor ? currentUser?.profile?.full_name : 'Anonymous';
    }

    const [src, srcSet] = getBackgroundImageSetForUrl(
        profilePhotoUrl || getRandomDefaultUserImage(),
        75
    );

    const formAttributes = {
        addAttachedContent: (content, type, callback) =>
            addAttachedContent(content, type, callback),
        chapter,
        sortType,
        group,
        closeModalHandler,
        closeNewPostModal,
        feedUrl,
        // the currentUser roles array check is returning always null it seems, not sure why this is the case, is it deprecated?
        hasAdminRole:
            currentUser?.roles?.adminOf?.length > 0 ||
            isUserGroupAdmin(currentUser, group, chapter),

        chapterTitle: chapter?.title,
        state: newPostState,
        handleSetState: handleSetNewPostState,
        handleSquarePhotoSelect,
        handleFileSelect,
        handleImgToggle,
        postResAction,
        submitPost: () => handleSubmitPost(),
        updateCacheAfterSubmit: (cache, data) =>
            updateCacheAfterSubmit(cache, data, feedUrl, sortType),
    };

    const allowPostSubmit =
        newPostState.attachState !== ATTACH_STATE_UPLOAD &&
        !newPostState?.isFetchingLink &&
        !submitNewPostloading &&
        newPostState?.maxLettersForPost >= newPostState?.wordCount;

    const debouncedSubmitPost = _.debounce(submitNewPost, 100);

    const handleSubmitPost = (showSocialHeaders, action = postResAction) => {
        const showAuthor = showSocialHeaders || shouldShowAuthor;
        const afterSubmit = action || postResAction;
        if (allowPostSubmit) debouncedSubmitPost(showAuthor, sortType, afterSubmit);
    };

    // Display title input field if chapter show title settings are null or true...
    // if card level show title, use that, if it's null, use chapter show title settings....
    let shouldShowTitle;

    if (newPostState.showTitle === null) {
        shouldShowTitle = chapterShowTitle === null ? true : chapterShowTitle;
    }

    if (newPostState.showTitle !== null) shouldShowTitle = newPostState.showTitle;

    const hideTextArea =
        state.formName === 'blog' ||
        NO_TEXT_AREA_TYPES.includes(newPostState?.attachedContent?.type);

    const wordCounter = newPostState?.maxLettersForPost - newPostState?.wordCount;

    let hasAdminRole = false;

    hasAdminRole = group?.currentUserIsAdmin || group?.currentUserIsDirectAdmin;

    const { showAllAttachOptions, showModal, formName } = state;

    const { attachedContent, attachState, attachmentOpen, body, title, maxLettersForPost } =
        newPostState;

    return (
        <div className="new-post-overlay-container">
            <LoadingLine
                isLoading={
                    newPostState.isFetchingLink ||
                    (!newPostState.lastAttachedCardUrl && state.showConfirmation)
                }
            />

            {showReferenceForChapter && <ReferenceCardHeader source={showReferenceForChapter} />}

            <NewPostOverlay
                allAllowedTypes={allAllowedTypes}
                allowPostSubmit={allowPostSubmit}
                attachedContent={attachedContent}
                attachState={attachState}
                attachmentOpen={attachmentOpen}
                body={body}
                clearAttachment={clearAttachment}
                closeNewPostModal={closeNewPostModal}
                chapter={chapter}
                currentUser={currentUser}
                closeModalhanler={closeModalHandler}
                defaultPromptText={defaultPromptText}
                expandContainer={expandContainer}
                expandAttachOptions={expandAttachOptions}
                expandAttachment={expandAttachment}
                formAttributes={formAttributes}
                formName={formTitle.length > 0 ? formTitle : formName}
                group={group}
                handleAdminAnnouncement={handleAdminAnnouncement}
                hasAdminRole={hasAdminRole}
                handleAnonymousToggle={handleAnonymousToggle}
                handleFileSelect={handleFileSelect}
                handleFetchLink={handleFetchLink}
                handleSetCustomFields={handleSetCustomFields}
                handleSquarePhotoSelect={handleSquarePhotoSelect}
                handleSubmitPost={handleSubmitPost}
                handleSetNewPostState={handleSetNewPostState}
                handleTextAreaChange={handleTextArea}
                handleTitleChange={handleTitleChange}
                handleToggle={handleToggle}
                hideTextArea={hideTextArea}
                handleChange={handleChange}
                handleAddToList={handleAddToList}
                handleRemoveFromList={handleRemoveFromList}
                lockSocialHeaderToggle={lockSocialHeaderToggle}
                maxLettersForPost={maxLettersForPost}
                newPostTopStyle={newPostTopStyle}
                newPostState={newPostState}
                profileName={profileName}
                openModalHandler={openModalHandler}
                resetForm={resetForm}
                setNewPostState={setNewPostState}
                shouldShowAttachment={shouldShowAttachment}
                shouldShowTitle={shouldShowTitle}
                showAllAttachOptions={showAllAttachOptions}
                shouldShowAuthor={shouldShowAuthor}
                submitButtonRef={submitButtonRef}
                showModal={showModal}
                src={src}
                state={state}
                srcSet={srcSet}
                title={title}
                triggerMenu={triggerMenu}
                truncateAttachment={truncateAttachment}
                textAreaRef={textAreaRef}
                wordCounter={wordCounter}
                submitNewPostloading={submitNewPostloading}
                visibilityRefSetting={visibilityRefSetting}
            />
        </div>
    );
};

type NewPostOverlayProps = {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTextAreaChange: (value: string) => void;
    handleTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleAnonymousToggle: () => void;
    handleFetchLink: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSquarePhotoSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSetCustomFields: (fields: any) => void;
    handleAddToList: (list: any) => void;
    handleRemoveFromList: (list: any) => void;
    handleFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleAdminAnnouncement: () => void;
    handleSetNewPostState: (state: any) => void;
    handleSubmitPost: () => void;
    handleToggle: () => void;
    clearAttachment: () => void;
    closeNewPostModal: () => void;
    closeModalHandler: () => void;
    openModalHandler: (name: string) => void;
    resetForm: () => void;
    showModal: boolean;
    formName: string;
    allowPostSubmit: boolean;
    showAllAttachOptions: boolean;
    expandAttachOptions: () => void;
    expandAttachment: () => void;
    allAllowedTypes: string[];
    attachedContent: any;
    attachState: string;
    attachmentOpen: string;
    body: string;
    chapter: any;
    currentUser: any;
    defaultPromptText: string;
    expandContainer: () => void;
    formAttributes: any;
    group: any;
    hasAdminRole: boolean;
    hideTextArea: boolean;
    lockSocialHeaderToggle: boolean;
    maxLettersForPost: number;
    newPostTopStyle: any;
    newPostState: any;
    profileName: string;
    setNewPostState: (state: any) => void;
    shouldShowAttachment: (type: string) => boolean;
    shouldShowAuthor: boolean;
    shouldShowTitle: boolean;
    submitButtonRef: any;
    src: string;
    state: any;
    srcSet: string;
    textAreaRef: any;
    title: string;
    triggerMenu: () => void;
    truncateAttachment: () => void;
    wordCounter: number;
    visibilityRefSetting: any;
};

const NewPostOverlay = React.memo(function NewPostOverlayMemo({
    allAllowedTypes,
    allowPostSubmit,
    attachedContent,
    attachState,
    attachmentOpen,
    body,
    chapter,
    clearAttachment,
    closeNewPostModal,
    closeModalHandler,
    currentUser,
    defaultPromptText,
    expandContainer,
    expandAttachOptions,
    expandAttachment,
    formAttributes,
    formName,
    group,
    hasAdminRole,
    handleAdminAnnouncement,
    handleAnonymousToggle,
    handleChange,
    handleAddToList,
    handleRemoveFromList,
    handleFileSelect,
    handleSquarePhotoSelect,
    handleFetchLink,
    handleSetNewPostState,
    handleSetCustomFields,
    handleTextAreaChange,
    handleTitleChange,
    handleSubmitPost,
    hideTextArea,
    handleToggle,
    lockSocialHeaderToggle,
    maxLettersForPost,
    newPostTopStyle,
    newPostState,
    profileName,
    openModalHandler,
    setNewPostState,
    shouldShowAttachment,
    showAllAttachOptions,
    shouldShowAuthor,
    shouldShowTitle,
    submitButtonRef,
    resetForm,
    showModal,
    src,
    state,
    srcSet,
    textAreaRef,
    title,
    triggerMenu,
    truncateAttachment,
    wordCounter,
    visibilityRefSetting,
    toggleVisibility,
}: NewPostOverlayProps) {
    const { t } = useTranslation();

    const isTextPostEnabled = chapter?.library_settings?.postTypes?.includes('text');
  
    let showSlideState = false;

    // if isTextPostEnabled then return false, else return 'postTypes'
    if (isTextPostEnabled || !chapter) {
        showSlideState = false;
    } else {
        showSlideState = 'postTypes';
    }

    // if chapter?.showPostTypes is false then return false, else return 'postTypes'
    if (chapter?.showPostTypes === true) showSlideState = 'postTypes';

    const [showSlide, setShowSlide] = useState(showSlideState); // false for generic text, 'postTypes' for all available post types
   
    const handleShowSlide = name => setShowSlide(name);

    const toggleShowSlide = name => {
        if (showSlide !== name) setShowSlide(name);
        else setShowSlide(false);
    };

    const handleReturnToPost = () => {
        resetForm();
        setShowSlide(false);
    };

    const chapterTitle = chapter?.title;

    const shortChapterTitle =
        chapterTitle?.length > 14 ? `${chapterTitle.substr(0, 14).trim()}...` : chapterTitle;

    const overlayTitle = formName === 'blog' ? 'Create Blog' : 'Create Post';

    // Display logic for showing toggle or visibility reference source, e.g. public, or 'members in group' etc
    let visibilityComponent;

    if (toggleVisibility) {
        visibilityComponent = (
            <ToggleVisibility
                handleToggle={handleToggle}
                protectionLevel={newPostState.protectionLevel}
            />
        );
    }

    if (visibilityRefSetting) {
        visibilityComponent = <VisibilitySetting visibilityRefSetting={visibilityRefSetting} />;
    }

    // Use attached content title if exists, if not use state
    const postTitle = title;
    const postingTextTranslated = t('common:global.verbs.posting_into');
    const returnText = t(`common:${localNamespace}.return_to_post`);

    return (
        <>
            <div className="white-top-filler" />
            <div className="new-post-overlay-box">
                <div className="new-post-header">
                    <div className="new-post-title-container">
                        <span className="inline-text-button">{overlayTitle}</span>
                        {chapterTitle && (
                            <p className="posting-src-text">
                                {' '}
                                {postingTextTranslated} {shortChapterTitle}{' '}
                            </p>
                        )}
                    </div>

                    <div className="posting-btns">
                        <button
                            type="button"
                            className="button-reset new-post-overlay-close"
                            onClick={() => {
                                closeNewPostModal();
                                awsMetricPublisher.publishCount('new-post-modal-close', 1);
                            }}
                        >
                            {t(`common:${localNamespace}.cancel`)}
                        </button>
                        {/* don't show when blog for now, because of the current structure */}
                        {state?.formName !== 'blog' && formName !== 'announcement' && (
                            <button
                                type="button"
                                className="new-post-overlay-button w-button post-top-button"
                                disabled={!allowPostSubmit}
                                style={allowPostSubmit ? { opacity: '1' } : { opacity: '0.5' }}
                                onClick={() => handleSubmitPost()}
                            >
                                {t(`common:${localNamespace}.post`)}
                            </button>
                        )}
                        {/* show announcement button */}
                        {formName === 'announcement' && (
                            <button
                                type="button"
                                className="new-post-overlay-button w-button post-top-button"
                                onClick={handleAdminAnnouncement}
                            >
                                {t(`common:${adminAnnouncementNamespace}.send_announcement`)}
                            </button>
                        )}
                    </div>
                </div>
                {showSlide !== 'postTypes' && (
                    <div className="new-post-overlay-box-header">
                        <div className="new-post-overlay-author-container">
                            <StyledBackgroundImage
                                className="thumbnail new-post-overlay-thumbnail thumbnail-border-highlight"
                                src={src}
                                srcSet={srcSet}
                            >
                                <div className="square-spacer" />
                                {!shouldShowAuthor && <div className="anonymous-thumb-overlay" />}
                            </StyledBackgroundImage>
                            <div className="post-overlay-author-content">
                                <div className="post-overlay-author-anonymous">{profileName}</div>
                                {formName !== 'announcement' && (
                                    <div className="new-post-visibility-container">
                                        {!lockSocialHeaderToggle && (
                                            <div className="post-anonymous-button">
                                                <ControlledToggle
                                                    reverse
                                                    hideToggle={lockSocialHeaderToggle}
                                                    name="displaySocialHeaders"
                                                    option1={t(
                                                        `common:${localNamespace}.anonymous_off`
                                                    )}
                                                    option2={t(
                                                        `common:${localNamespace}.anonymous`
                                                    )}
                                                    handleToggle={handleAnonymousToggle}
                                                    toggleValue={shouldShowAuthor}
                                                />
                                            </div>
                                        )}
                                        {visibilityComponent}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {!hideTextArea && !showSlide && (
                    <div className="new-post-top" onClick={expandContainer} style={newPostTopStyle}>
                        <div className="new-post-field-container new-post-overlay-field-container w-form">
                            <NewPostMainTextInput
                                group={group}
                                shouldShowTitle={shouldShowTitle}
                                defaultPromptText={defaultPromptText}
                                maxLettersForPost={maxLettersForPost}
                                textAreaRef={textAreaRef}
                                title={postTitle}
                                body={body}
                                handleTitleChange={handleTitleChange}
                                handleTextAreaChange={handleTextAreaChange}
                            />
                        </div>
                    </div>
                )}
                <NewPostOverlayFormContainer
                    group={group}
                    formAttributes={formAttributes}
                    showModal={showModal}
                    closeModalHandler={closeModalHandler}
                    triggerMenu={triggerMenu}
                    formName={formName}
                />

                {hasAdminRole && showSlide === 'settings' && (
                    <div className="new-post-settings-container fadein">
                        <div className="new-post-settings">
                            <button
                                onClick={handleReturnToPost}
                                type="button"
                                className="shelf-play-button ln-back-button w-inline-block"
                            >
                                {returnText}
                            </button>
                            <NewPostSettings
                                shouldShowTitle={shouldShowTitle}
                                group={chapter?.reference?.groupSource}
                                chapter={chapter}
                                handleChange={handleChange}
                                handleAddToList={handleAddToList}
                                handleSetCustomFields={handleSetCustomFields}
                                handleRemoveFromList={handleRemoveFromList}
                                handleSetNewPostState={handleSetNewPostState}
                                state={newPostState}
                            />
                        </div>
                    </div>
                )}
                {showSlide === 'postTypes' && formName !== 'blog' && (
                    <>
                        <button
                            onClick={handleReturnToPost}
                            type="button"
                            className="shelf-play-button ln-back-button w-inline-block"
                        >
                            {returnText}
                        </button>
                        <NewPostTypesOptions
                            allAllowedTypes={allAllowedTypes}
                            handleSquarePhotoSelect={handleSquarePhotoSelect}
                            handleFileSelect={handleFileSelect}
                            expandAttachment={expandAttachment}
                            openModalHandler={openModalHandler}
                        />
                    </>
                )}
                {formName !== 'blog' && (
                    <>
                        {newPostState.urlLinkError && (
                            <div className="new-post-linkError-container">
                                <p className="new-post-linkError">
                                    {t(`common:${localNamespace}.urlLinkError`)}
                                </p>
                            </div>
                        )}

                        <div className="new-post-overlay-bottom-buttons">
                            {hasAdminRole && (
                                <button
                                    type="button"
                                    onClick={() => toggleShowSlide('settings')}
                                    className="settings-button"
                                >
                                    &#x25BE;
                                </button>
                            )}

                            {showSlide !== 'postTypes' && (
                                <NewPostIconsContainer
                                    shouldShowAttachment={shouldShowAttachment}
                                    expandAttachment={expandAttachment}
                                    clearAttachment={() => clearAttachment()}
                                    showAllAttachOptions={showAllAttachOptions}
                                    expandAttachOptions={expandAttachOptions}
                                    allAllowedTypes={allAllowedTypes}
                                    handleSquarePhotoSelect={handleSquarePhotoSelect}
                                    handleFileSelect={handleFileSelect}
                                    openModalHandler={openModalHandler}
                                    handleShowAllTypes={() => handleShowSlide('postTypes')}
                                />
                            )}

                            <div className="login-text-field-subtext darker new-post-character-count">
                                {Math.sign(wordCounter) === -1 ? (
                                    <span className="red-text"> {wordCounter}</span>
                                ) : (
                                    wordCounter
                                )}
                            </div>

                            <div className="new-post-media-container">
                                {attachState === ATTACH_STATE_PREVIEW && (
                                    <PreviewContainer
                                        currentUser={currentUser}
                                        clearAttachment={() => clearAttachment()}
                                        content={attachedContent}
                                        state={newPostState}
                                        formAttributes={formAttributes}
                                        setState={setNewPostState}
                                    />
                                )}
                                {attachState === ATTACH_STATE_UPLOAD && (
                                    <StagingContainer
                                        type={attachmentOpen}
                                        handleClear={() => clearAttachment()}
                                        handleChange={e => handleFetchLink(e)}
                                        onSuccess={content => addAttachedContent(content)}
                                        onCancel={truncateAttachment}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="new-post-footer">
                            {chapterTitle && (
                                <div>
                                    {' '}
                                    {postingTextTranslated} {chapterTitle}{' '}
                                </div>
                            )}
                        </div>
                        <div className="new-post-overlay-bottom">
                            {/*
                                check if announcement,
                                else handles a post by default
                            */}
                            {formName === 'announcement' ? (
                                <button
                                    type="button"
                                    className="new-post-overlay-button w-button bottom-post-btn"
                                    onClick={handleAdminAnnouncement}
                                >
                                    {t(`common:${adminAnnouncementNamespace}.send_announcement`)}
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    ref={submitButtonRef}
                                    className={`new-post-overlay-button w-button bottom-post-btn ${
                                        allowPostSubmit ? 'allow' : 'disabled-button'
                                    }`}
                                    disabled={!allowPostSubmit}
                                    style={allowPostSubmit ? { opacity: '1' } : { opacity: '0.4' }}
                                    onClick={() => {
                                        awsMetricPublisher.publishCount(
                                            'new-post-modal-submit-post',
                                            1
                                        );
                                        handleSubmitPost();
                                    }}
                                >
                                    {t(`common:${localNamespace}.post`)}
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
});

export default NewPostOverlayContainer;
