import React from 'react';

const CloseIcon: React.FC<{
    className?: string;
    size?: string;
    strokeWidth?: string;
    color?: string;
}> = ({ className, size = '22', color = '#90949C' }) => (
    <svg
        className={className}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6 6L18.7742 18.7742"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6 18.7744L18.7742 6.00022"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CloseIcon;
