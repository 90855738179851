import { z } from 'zod';

import { LatitudeLongitudeInput, Group } from '@shared/welibrary-graphql/types';
import { ImageWithLoadingState, ImageWithLoadingStateValdator } from '@core/types/Utilities';

export enum LocationTypeEnum {
    virtual = 'virtual',
    inPerson = 'in_person',
    both = 'virtual_and_in_person',
}

export enum EventGroupFormModeEnum {
    create = 'create',
    update = 'update',
}

export type LocationState = {
    formatted_address: string;
    location: LatitudeLongitudeInput;
    city?: string;
    state?: string;
    country?: string;
    postal?: string;
};

export type VenueState = {
    name: string;
    link?: string;
    location?: LocationState;
};

export type EventLocationSettings = {
    type: LocationTypeEnum;
    link?: string;
    rsvpLink?: string;
    platformName?: string;
    venue?: VenueState;
};

export type OverviewFormState = {
    title: string;
    description: string;
    thumbnail: string;
    coverImage: string;
    eventLocationSettings: EventLocationSettings;
    rsvpEnabled?: boolean;
};

export type Fulfillment = {
    groupId: string;
    role: string;
};

export type Coupon = {
    name: string;
    code: string;
    percentage: number;
    type: string;
    expiration?: string;
};

export type ProductState = {
    _id: string;
    name: string;
    description: string;
    price: any;
    currency: string;
    type: string;
    fulfillment: Fulfillment;
    coupons?: Coupon[];
    startDate?: string;
    endDate?: string;
    reference?: ProductReference;
};

export type ProductReference = Group;

export type TicketFormState = {
    products: ProductState[];
    groupId: string;
    merchantAccountFullActivated?: boolean;
    inEditMode?: boolean;
    isGroupMembershipPaid?: boolean;
};

export type TimezoneState = {
    name?: string;
};

export type EventDateState = {
    startDate: string;
    endDate: string;
    key?: string;
};

export type DateAndTimeState = {
    dates: EventDateState[];
    startTime: string | null;
    endTime: string | null;
    timeZone: TimezoneState;
    displayStartTime: boolean;
    displayEndTime: boolean;
};

export const SpeakerState = z.object({
    _id: z.string().optional(),
    name: z.string(),
    bio: z.string(),
    email: z.optional(z.string()),
    profileThumb: ImageWithLoadingStateValdator,
    userBio: z.string().optional(),
    useUserBio: z.boolean().optional(),
});
export type SpeakerStateType = z.infer<typeof SpeakerState>;

export type ItineraryState = {
    id?: string;
    title: string;
    headline?: string;
    description: string;
    date: string | Date;
    dates: EventDateState[];
    startTime: string;
    endTime: string;
    link?: string;
    rsvpLink?: string;
    timeZone: TimezoneState;
    speakers?: SpeakerStateType[];
    displayStartTime: boolean;
    displayEndTime: boolean;
    eventLocationSettings: EventLocationSettings;
};

export type EventGroupFormSettingsState = {
    protectionLevel: string;
    recommendations?: boolean;
    shortCode?: string;
    groupChatroom?: boolean;
    adminChatroom?: boolean;
    approvalRequired?: boolean;
    registrationTitle?: string;
    registrationDescription?: string;
    registrationActionText?: string;
    canMembersSuggestEdits?: boolean;
    enableGroupNewsfeed?: boolean;
    isTemplate?: boolean;
    hideAttendeeCount?: boolean;
};

export type EventGroupFormState = {
    overviewFormState: OverviewFormState;
    dateAndTimeState: DateAndTimeState;
    itineraryState: ItineraryState[];
    ticketFormState: TicketFormState;
    sponsorsFormState: SponsorsFormState;
    previewFormState: PreviewFormState;
    groupSettingsState: EventGroupFormSettingsState;
};

export type SponsorTier = {
    name: string;
    order: number;
};

export type Sponsor = {
    id?: string;
    name: string;
    link: string;
    logo: ImageWithLoadingState;
    tier: SponsorTier | null;
};

export type SponsorsFormState = {
    description: string;
    sponsors: Sponsor[];
    tiers: SponsorTier[];
};

export type PreviewFormState = {
    privacyLevel: string;
    agreedToTerms: boolean;
};

export type SpeakerTransformedPayload = {
    userId?: string;
    name?: string;
    email?: string;
    shortBio?: string;
    profilePicture?: string;
    type: string;
};

export type EventGroupFormValidation = {
    overview: boolean;
    dateAndTime: boolean;
    itinerary: boolean;
    tickets: boolean;
    sponsors: boolean;
    preview: boolean;
    finish: boolean;
};
