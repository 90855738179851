import {AliasItemInput, QuickLinksSectionInput,} from '@shared/welibrary-graphql/user/mutations.hook';

import {QuickLinkAlias} from '@core/types/QuickLinks';
import {Entity} from '@core/types/Search';

export const getInfoFromResult = (result: Partial<Entity>): AliasItemInput | false => {
    if (!result) return false;

    if (result.__typename === 'User' && result._id)
        return { title: result.profile?.full_name, url: result._id, type: 'alias_user' };

    if (result.__typename === 'ContentCard') {
        if (!result.type || !result.url) return false;

        return { title: result.title, url: result.url, type: result.type };
    }

    if (result.__typename === 'Group' && result._id)
        return { title: result.profile?.full_name, url: result._id, type: 'alias_group' };

    if (result.__typename === 'MessageThread' && result._id)
        return { title: 'MessageThread', url: result._id, type: 'alias_message_thread' };

    return false;
};

export const getAliasInputFromAlias = (alias: QuickLinkAlias) => ({
    title: alias.title,
    url: alias.url,
    type: alias.type,
});

export const updateHomeLink = (
    quickLinks: QuickLinksSectionInput[],
    newHomeLink: QuickLinksSectionInput
) => {
    const homeLinkIndex = quickLinks.findIndex(quickLink => quickLink?.tags?.[0] === 'home');

    if (homeLinkIndex === -1) return [newHomeLink, ...quickLinks];

    const newLinks = [...quickLinks];

    newLinks[homeLinkIndex] = newHomeLink;

    return newLinks;
};

export const removeHomeLink = (quickLinks: QuickLinksSectionInput[]) => {
    const homeLinkIndex = quickLinks.findIndex(quickLink => quickLink?.tags?.[0] === 'home');

    if (homeLinkIndex === -1) return quickLinks;

    const newLinks = [...quickLinks];

    newLinks.splice(homeLinkIndex, 1);

    return newLinks;
};

export const updateLink = (
    quickLinks: QuickLinksSectionInput[],
    link: AliasItemInput,
    index: number
) => {
    const linksIndex = quickLinks.findIndex(quickLink => quickLink?.tags?.[0] === 'links');

    if (linksIndex === -1) return [...quickLinks, { tags: ['links'], aliases: [link] }];

    const newLinks = [...quickLinks];
    const newAliases = [...(newLinks[linksIndex]?.aliases ?? [])];

    newAliases.splice(index, 1, link);

    newLinks[linksIndex] = {
        tags: ['links'],
        aliases: newAliases,
    };

    return newLinks;
};

export const addToLinks = (quickLinks: QuickLinksSectionInput[], link: AliasItemInput) => {
    const linksIndex = quickLinks.findIndex(quickLink => quickLink?.tags?.[0] === 'links');

    if (linksIndex === -1) return [...quickLinks, { tags: ['links'], aliases: [link] }];

    const newLinks = [...quickLinks];

    newLinks[linksIndex] = {
        tags: ['links'],
        aliases: [...(newLinks[linksIndex]?.aliases ?? []), link],
    };

    return newLinks;
};

export const removeFromLinks = (quickLinks: QuickLinksSectionInput[], index: number) => {
    const linksIndex = quickLinks.findIndex(quickLink => quickLink?.tags?.[0] === 'links');

    if (linksIndex === -1) return quickLinks;

    const newLinks = [...quickLinks];

    const aliases = [...(newLinks[linksIndex].aliases ?? [])];

    aliases.splice(index, 1);

    newLinks[linksIndex] = {
        tags: ['links'],
        aliases,
    };

    return newLinks;
};

export const moveHomeToLinks = (quickLinks: QuickLinksSectionInput[]) => {
    const homeLinkIndex = quickLinks.findIndex(quickLink => quickLink?.tags?.[0] === 'home');

    if (homeLinkIndex === -1) return quickLinks;

    const newLinks = [...quickLinks];

    const homeLink = newLinks[homeLinkIndex].aliases ?? [];
    newLinks.splice(homeLinkIndex, 1);

    const linksIndex = newLinks.findIndex(quickLink => quickLink?.tags?.[0] === 'links');

    const newAliases =
        linksIndex === -1 ? homeLink : [...(newLinks[linksIndex]?.aliases ?? []), ...homeLink];

    newLinks[linksIndex] = {
        tags: ['links'],
        aliases: newAliases,
    };

    return newLinks;
};

export const moveToHome = (quickLinks: QuickLinksSectionInput[], index: number) => {
    const newLinks = moveHomeToLinks(quickLinks);

    const linksIndex = newLinks.findIndex(quickLink => quickLink?.tags?.[0] === 'links');

    if (linksIndex === -1) return quickLinks;

    const aliases = [...(newLinks[linksIndex]?.aliases ?? [])];

    const link = aliases.splice(index, 1);

    return [
        { tags: ['home'], aliases: link },
        { tags: ['links'], aliases },
    ];
};
