import React, { useState } from 'react';
import { GET_PAGINATED_GROUP_TEMPLATES } from '@shared/welibrary-graphql/user/queries';
import { useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

const getDefaultParent = loggedInUser => {
    const { activeGroup, groups } = loggedInUser;
    const adminOf =
        groups && groups.results && _.filter(groups.results, group => group.currentUserIsAdmin);
    let defaultParent = adminOf && adminOf.length > 0 && adminOf[0];
    if (activeGroup && activeGroup.currentUserIsAdmin) {
        defaultParent = activeGroup;
    }
    return defaultParent;
};

const GroupTemplatePicker = props => {
    const [refreshing, setRefreshing] = useState(false);
    const { defaultLabel, handlePicker } = props;
    const { networkStatus, fetchMore, refetch, data, loading, error } = useQuery(
        GET_PAGINATED_GROUP_TEMPLATES,
        {
            variables: {
                searchQuery: '',
                limit: 30,
            },
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
        }
    );
    const _results = data && data.paginatedGroupTemplates && data.paginatedGroupTemplates.results;
    const templateOptions =
        _results &&
        _results.map((group, index) => {
            const id = group && group._id;
            const name = group && group.profile && group.profile.full_name;
            return (
                <option key={id} name={name} value={id}>
                    {' '}
                    {name}
                </option>
            );
        });

    const handleSelect = e => {
        e.persist();
        handlePicker(e);
    };

    const selectStyle = { backgroundColor: 'transparent' };
    return (
        <div className="white-bg-dark-select-wrapper">
            <select
                onChange={handleSelect}
                className="signup-select-field light-background-select"
                style={selectStyle}
                name="groupTemplateOptions"
            >
                <option value="">{defaultLabel || 'Select Group Template (Optional)'}</option>
                {templateOptions}
            </select>
        </div>
    );
};

export default withTranslation()(GroupTemplatePicker);
