import React from 'react';

import ModalFriendlyChapter from '@components/chapter/ModalFriendlyChapter';
import StoryCardFull from '@components/card/storycard/StoryCardFull';
import ContentCard from '@components/card/ContentCard';
import GroupDashboard from '@components/group/GroupDashboard';
import QuickProfileView from '@components/profile/QuickProfileView/QuickProfileView';

import { CARD_CHAPTER, CARD_STORY } from '@core/utilities/constants/card_types';

import { Maybify } from '@core/types/Utilities';
import { RainbowRoadNode } from '@shared/welibrary-graphql/types';

type QuestNodePreviewProps = {
    node: Maybify<RainbowRoadNode>;
    completeNode?: () => void;
};

const QuestNodePreview: React.FC<QuestNodePreviewProps> = ({ node, completeNode }) => {
    const { alias } = node;

    if (alias?.__typename === 'AliasItem') {
        if (alias.item) {
            if (alias.item.type === CARD_CHAPTER) {
                return <ModalFriendlyChapter chapter={alias.item} />;
            }

            if (alias.item.type === CARD_STORY) {
                return <StoryCardFull card={alias.item} onAllStoriesEnd={completeNode} />;
            }

            return <ContentCard card={alias.item} className="no-border" />;
        }
    }

    if (alias?.__typename === 'GroupAliasHandle') {
        if (alias.group) return <GroupDashboard group={alias.group} inModal />;
    }

    if (alias?.__typename === 'UserAliasHandle') {
        if (alias.user) return <QuickProfileView user={alias.user} />;
    }

    return <></>;
};

export default QuestNodePreview;
