import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';

import VerifyBadge from '@dsc/svgs/VerifyBadge';
import TinyGlobe from '@dsc/svgs/TinyGlobe';
import Picture from '@components/generic/Picture';

import {
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
} from '@core/utilities/constants/defaults';
import { getTotal } from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';

import { Group } from '@shared/welibrary-graphql/types';

const ParentGroupSubscriptionActionConfirmation: React.FC<{
    subgroup: Group;
    parentSubscriptionGroup: Group;
    className?: string;
    handleRequestTojoin?: () => void;
    handleCancelRequestToJoin?: () => void;
    isRequestingToJoin?: boolean;
}> = ({
    subgroup,
    parentSubscriptionGroup,
    className,
    handleRequestTojoin = () => {},
    handleCancelRequestToJoin = () => {},
    isRequestingToJoin = true,
}) => {
    const { t } = useTranslation();
    const { closeModal } = useModal();
    const history = useHistory();

    const defaultSubscription = parentSubscriptionGroup?.products?.[0];

    const cancel = () => {
        closeModal();
    };

    const price = parseFloat(getTotal(defaultSubscription, 1)).toFixed(2);

    const [coverSrc] = getBackgroundImageSetForUrl(
        parentSubscriptionGroup?.profile?.coverPhoto ||
            getRandomDefaultCoverImage(parentSubscriptionGroup?.profile?.full_name ?? '')
    );

    const handleConfirmation = () => {
        if (isRequestingToJoin) {
            handleRequestTojoin();
            closeModal();
        } else {
            handleCancelRequestToJoin();
            closeModal();
        }
    };

    const handleParentSubscriptionGroupRedirect = () => {
        history.push(`/g/${parentSubscriptionGroup._id}?subgroupId=${subgroup?._id}`);
        closeModal();
    };

    const buttonText = isRequestingToJoin ? 'Request To Join' : 'Cancel Request to Join';
    const headerText = isRequestingToJoin ? 'Request To Join' : 'Pending Invite';

    let confirmationText;
    if (isRequestingToJoin) {
        confirmationText = (
            <p>
                This group requires approval from the{' '}
                <button type="button" onClick={handleParentSubscriptionGroupRedirect}>
                    {parentSubscriptionGroup.profile?.full_name}
                </button>{' '}
                Network and a subscription fee of <strong>${price} per month</strong> before being
                able to join.
            </p>
        );
    } else {
        confirmationText = (
            <p>
                This group requires approval from the{' '}
                <button type="button" onClick={handleParentSubscriptionGroupRedirect}>
                    {parentSubscriptionGroup.profile?.full_name}
                </button>{' '}
                Network and a subscription fee of <strong>${price}</strong> before being able to
                join. Are you sure you want to cancel your request to join?
            </p>
        );
    }

    return (
        <section role="alert" className={`confirmation ${className}`}>
            <div className="checkout-item-preview-sub">
                <div className="checkout-item-preview-sub-header">
                    <h1>{headerText}</h1>
                    <p className="checkout-item-inner-details-total checkout-item-inner-details-total-inline">
                        Membership fee:&nbsp;
                        <span className="old-total">${price}</span>
                    </p>
                </div>
                <div className="checkout-item-preview-sub-content">
                    <div
                        className="checkout-item-preview-sub-cover"
                        style={{
                            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${coverSrc})`,
                        }}
                    />

                    <div className="checkout-item-preview-sub-thumb">
                        <Picture url={coverSrc} className="checkout-item-preview-thumb-img" />
                    </div>

                    <div className="checkout-item-preview-sub-info">
                        <div className="checkout-item-preview-sub-privacy">
                            <div className="paywall-info-icon-wrap">
                                <VerifyBadge className="paywall-info-icon-badge" />
                                <TinyGlobe className="paywall-info-icon-world" strokeColor="#fff" />
                            </div>
                            &nbsp;
                            <p>{parentSubscriptionGroup?.settings?.protectionLevel} Group</p>
                        </div>
                        <div className="checkout-item-preview-sub-text">
                            <h5>{defaultSubscription?.name}</h5>
                            <p>{parentSubscriptionGroup?.profile?.full_name}</p>
                        </div>
                    </div>
                </div>
            </div>

            {confirmationText}

            <footer>
                <button
                    onClick={handleConfirmation}
                    type="button"
                    className="subs-parent-group-confirmation-btn-approve"
                >
                    {buttonText}
                </button>
                <button
                    onClick={cancel}
                    type="button"
                    className="subs-parent-group-confirmation-btn-cancel"
                >
                    Cancel
                </button>
            </footer>
        </section>
    );
};

export default ParentGroupSubscriptionActionConfirmation;
