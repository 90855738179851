import React, {useRef} from 'react';
import {useDrop} from 'react-dnd';
import {useMultiDrag} from 'react-dnd-multi-backend';

import PromptItem from '@web/ui/components/content/newpost/forms/Postbox/PromptItem';

import {DraggableItemTypes} from '@core/utilities/constants/draggable.js';

/** @type React.FC<{
 *      prompt: {
 *          _id: string,
 *          prompt: string,
 *          newPrompt: string,
 *          media: { thumb: string },
 *          reference: { coverImage: string, bookSource: { title: string } },
 *      },
 *      updatePrompt: (id: string, value: string) => void,
 *      movePrompt: (firstIndex: number, secondIndex: number) => void,
 *      deletePrompt: (id: string) => void,
 *      index: number,
 *      hidePrompt: boolean,
 *  }>
 */
const DraggablePromptItem = ({
    prompt,
    updatePrompt,
    movePrompt,
    deletePrompt,
    index,
    hidePrompt,
}) => {
    const itemRef = useRef();
    const dragRef = useRef();

    const [, drop] = useDrop({
        accept: DraggableItemTypes.CHAPTER,
        hover: item => {
            if (!itemRef.current || index === item.index) return;

            movePrompt(item.index, index);

            item.index = index;
        },
    });

    const {
        html5: [{ isDragging: html5IsDragging }, html5Drag],
        touch: [{ isDragging: touchIsDragging }, touchDrag],
    } = useMultiDrag({
        item: { type: DraggableItemTypes.CHAPTER, prompt, index },
        collect: monitor => ({ isDragging: monitor.isDragging() }),
    })[1];

    html5Drag(drop(itemRef));
    touchDrag(dragRef);

    return (
        <PromptItem
            prompt={prompt}
            updatePrompt={updatePrompt}
            deletePrompt={deletePrompt}
            hidePrompt={hidePrompt}
            draggable
            className={html5IsDragging || touchIsDragging ? 'dragging' : ''}
            ref={itemRef}
            dragRef={dragRef}
        />
    );
};

export default DraggablePromptItem;
