import React from 'react';
import {withTranslation} from 'react-i18next';

class ToggleButton extends React.Component {
    constructor(props) {
        super(props);
        const { defaultValue } = this.props;
        let _defaultValue = defaultValue || false;
        this.state = {
            toggle: _defaultValue,
        };
    }

    handleChange = e => {
        this.setState({
            toggle: !this.state.toggle,
        });

        if (this.props.handleToggle) {
            this.props.handleToggle(e, !this.state.toggle);
        }
    };

    render() {
        const { toggle } = this.state;
        const { t, name = 'default', fontSize, reverse } = this.props;

        const transitionStyle = {
            transition: 'all 0.3s ease 0s',
        };

        let buttonToggleStyle = {
            transformStyle: 'preserve-3d',
            transition: 'transform 300ms ease 0s',
            transform: 'translateX(0px) translateY(0px) translateZ(0px)',
        };

        let buttonToggleStyleActive = {
            transformStyle: 'preserve-3d',
            transition: 'transform 300ms ease 0s',
            transform: 'translateX(50px) translateY(0px) translateZ(0px)',
        };

        let buttonTextStyle = {};

        if (reverse) {
            buttonToggleStyle = {
                transformStyle: 'preserve-3d',
                transition: 'transform 300ms ease 0s',
                transform: 'translateX(50px) translateY(0px) translateZ(0px)',
            };

            let buttonTextStyleInactive = {
                transform: 'translateX(-15px)',
                transition: 'none',
            };

            buttonToggleStyleActive = {
                transformStyle: 'preserve-3d',
                transition: 'transform 300ms ease 0s',
                transform: 'translateX(0px) translateY(0px) translateZ(0px)',
            };

            let buttonTextStyleActive = {
                transform: 'translateX(11px)',
                transition: 'none',
            };

            // buttonTextStyle = this.state.toggle ? buttonTextStyleActive : buttonTextStyleInactive;
        }

        const nonActiveStyle = {
            transition: 'opacity 300ms ease 0s',
            opacity: '0',
        };

        const activeStyle = {
            transition: 'opacity 300ms ease 0s',
            opacity: '1',
            'background-color': '#dcdcdc',
        };

        if (fontSize) {
            buttonTextStyle = { fontSize: `${fontSize}px` };
        }

        return (
            <div>
                <a data-ix="toggle" className="togglebutton w-inline-block" style={transitionStyle}>
                    <div
                        className="buttontoggle"
                        style={this.state.toggle ? buttonToggleStyleActive : buttonToggleStyle}
                    ></div>
                    <div className="buttontext" style={buttonTextStyle}>
                        {this.props.option1 ? this.props.option1 : t(`common:global.no_caps`)}
                    </div>
                    <div
                        className="toggle-button-acive"
                        style={this.state.toggle ? activeStyle : nonActiveStyle}
                    >
                        <div className="buttontext" style={buttonTextStyle}>
                            {this.props.option2 ? this.props.option2 : t(`common:global.yes_caps`)}
                        </div>
                    </div>
                    <div className="toggle-checkbox-field w-checkbox">
                        <input
                            onClick={this.handleChange}
                            type="checkbox"
                            name={name}
                            className="toggle-checkbox-field w-checkbox-input"
                        />
                        <label htmlFor="toggle" className="w-form-label">
                            {t(`common:global.form.input.checkbox.title`)}
                        </label>
                    </div>
                </a>
            </div>
        );
    }
}

export default withTranslation()(ToggleButton);
