import React from 'react';
import { Link } from 'react-router-dom';

import { awsMetricPublisher } from '@welibraryos/metrics';

import useModal from '@web/ui/components/modals/hooks/useModal';

import { CONTENT_BUTTON_ICON_KEY } from '@core/utilities/constants/dropdowns';
import { getRandomDefaultUserImage } from '@core/utilities/constants/defaults';

import { MenuListItem } from '@core/types/Menus';

const MobileMenuList: React.FC<{
    menuList: MenuListItem[];
    showIcons?: boolean;
    metricName?: string;
}> = ({ menuList = [], showIcons = false, metricName = null }) => {
    const { closeModal } = useModal();

    if (!menuList || menuList.length === 0) return <></>;

    const itemClicked = (item: MenuListItem) => {
        if (metricName) {
            const metricValue = typeof item.id === 'number' ? item.id.toString() : item.id;
            awsMetricPublisher.publishCount(metricName, 1, [
                { Name: 'list-item-click', Value: metricValue },
            ]);
        }

        if (item.hasOwnProperty('onClick')) {
            if (item.onClose) item.onClose(item, closeModal);
            else closeModal();

            item.onClick?.(item.id, item.title);
        }
    };

    const linkRows = menuList.map(item => {
        const iconKey = item.iconKey || item.iconName || item.title;

        const iconClass = iconKey ? CONTENT_BUTTON_ICON_KEY[iconKey] : '';

        const icon =
            item.mediaThumb !== undefined ? (
                <div
                    key={iconKey}
                    role="img"
                    className="mobile-quick-select-icon picture follow-thumb"
                    style={{
                        backgroundImage: `url(${item.mediaThumb || getRandomDefaultUserImage()})`,
                    }}
                />
            ) : (
                <div
                    key={iconKey}
                    role="img"
                    className={`content-button-icon ${iconClass} mobile-quick-select-icon`}
                />
            );

        if (item.to) {
            return (
                <Link
                    to={item.to || '#'}
                    key={`mobile-quick-${item.id}-${item.to}`}
                    onClick={() => itemClicked(item)}
                    className={`mobile-quick-select-link w-inline-block ${
                        showIcons ? '' : 'center'
                    } ${item.selected ? 'selected' : ''}`}
                >
                    {showIcons && icon}

                    <span>{item.title}</span>
                </Link>
            );
        }

        if (item.onClick) {
            return (
                <button
                    type="button"
                    key={`mobile-quick-${item.id}-${item.title}-${item.mediaThumb}`}
                    onClick={() => itemClicked(item)}
                    className={`mobile-quick-select-link w-inline-block ${
                        showIcons ? '' : 'center'
                    } ${item.selected ? 'selected' : ''}`}
                >
                    {showIcons && icon}

                    <span>{item.title}</span>
                </button>
            );
        }

        return <></>;
    });

    return <>{linkRows}</>;
};

export default MobileMenuList;
