import React from 'react';

import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import AlternateSearchCardView from '@components/search/searchcard/AlternateSearchCardView';
import { displayNames } from '@components/search/constants';

import { renderCountryFlag } from '@core/utilities/constants/countries';

import { ContentCard, Group, User } from '@shared/welibrary-graphql/types';

type AlternateSearchCardContainerProps = {
    handleCloseSearch: () => void;
    content: ContentCard | Group | User;
};

const AlternateSearchCardContainer: React.FC<AlternateSearchCardContainerProps> = ({
    handleCloseSearch,
    content,
}) => {
    let cardUrl = content?.reference?.href;
    let title = content?.title || null;
    const type = content?.type || content.__typename;
    const itemInfo = content && content.type && displayNames[type] ? displayNames[type] : null;
    const cardDescription = content?.description;
    let thumbImage = content && content.media && content.media.thumb;
    const cardId = content && content._id;
    const isUserType = content.__typename === 'User';
    const isGroupType = content.__typename === 'Group';

    const hideInsideAction = isUserType;
    let renderItemInfo = <div className="search-result-item-info">{itemInfo} </div>;
    const renderProfileBlurb = (shortBio: string = '', country: string | undefined | null) => {
        const countryFlag = renderCountryFlag({
            countryCode: country,
            customClass: 'search-card-flag',
            width: 24,
            height: 16,
        });
        return (
            <div className="search-result-item-info">
                <div>{shortBio} </div>
                <div className="search-result-country-info">
                    {countryFlag} {country}{' '}
                </div>
            </div>
        );
    };
    if ((isUserType || isGroupType) && content?.profile) {
        const country = content?.profile?.country;
        let bio = content?.profile?.short_bio
            ? content?.profile?.short_bio
            : content?.profile?.full_bio;
        if (bio && bio.length > 120) {
            bio = `${bio.substring(0, 120).trim()}...`;
        }
        thumbImage = content?.profile?.picture;
        title = content && content?.profile && content?.profile.full_name;
        renderItemInfo = renderProfileBlurb(bio ?? '', country);
    }

    if (isUserType) {
        cardUrl = content && content._id ? `/u/${content._id}` : '#';
    }
    if (isGroupType) {
        cardUrl = content && content._id ? `/g/${content._id}` : '#';
    }

    // TODO - What to do about unresolved functions?
    const handleAction = (action: string) => {
        return getActionForCard(action);
    };

    return (
        <ErrorBoundary>
            <AlternateSearchCardView
                handleCloseSearch={handleCloseSearch}
                cardId={cardId}
                type={type}
                title={title}
                titleUrl={cardUrl}
                thumbImage={thumbImage}
                handleAction={handleAction}
                hideInsideAction={hideInsideAction}
                renderItemInfo={renderItemInfo}
                description={cardDescription}
            />
        </ErrorBoundary>
    );
};

export default AlternateSearchCardContainer;
