import moment from 'moment';
import { TFunction } from 'i18next';

import { titleCase } from '@helpers/string.helpers';

import { User, Event, LocationInput, Group } from '@shared/welibrary-graphql/types';
import { SpeakerStateType } from '@core/types/EventGroup';
import {
    ActiveFilter,
    EventsPageFilter,
    EventsPageLocationType,
    EventsPageOrder,
} from '@core/types/Events';
import { PerformantMaybify } from '@core/types/Utilities';
import { VIEW_PERMISSION_PUBLIC } from '@core/utilities/constants/roles';
import { getDateObjectFromDateAndTimeIso } from '../date.helpers';

export const userToSpeakerState = (user?: Partial<User> | null): SpeakerStateType => {
    const defaultBio = user?.profile?.full_bio || user?.profile?.short_bio || '';
    return {
        _id: user?._id ?? '',
        name: user?.profile?.full_name ?? '',
        bio: defaultBio,
        profileThumb: { image: user?.profile?.picture ?? '' },
        userBio: defaultBio,
        useUserBio: true, // default to true since this is used to add new speakers from existing users
    };
};

export const isEventInThePast = (event?: PerformantMaybify<Event> | null): boolean => {
    if (!event?.eventOverviewDetails) return false;

    const { endTime, dates, timeZone } = event.eventOverviewDetails;
    if (!dates) return false;

    const { endDate } = dates?.[0];

    // endTime is... deceptive since it's been UTC'd once or twice
    //   so we'll take the date from endDate and the time from endTime and use that
    const accurateEndTime = getDateObjectFromDateAndTimeIso(endDate, endTime, timeZone.name);
    const now = new Date();

    return accurateEndTime < now;
};

export const isEventInProgress = (event?: PerformantMaybify<Event> | null): boolean => {
    if (!event?.eventOverviewDetails) return false;
    const { startTime, endTime, dates, timeZone } = event.eventOverviewDetails;
    if (!dates) return false;

    const { startDate, endDate } = dates?.[0];

    const accurateStartTime = getDateObjectFromDateAndTimeIso(startDate, startTime, timeZone.name);
    const accurateEndTime = getDateObjectFromDateAndTimeIso(endDate, endTime, timeZone.name);

    const now = new Date();

    return now >= accurateStartTime && now < accurateEndTime;
};

export const getActiveFilters = ({
    search,
    location,
    locationType,
}: {
    search: string;
    location?: LocationInput;
    locationType: EventsPageLocationType;
}) => {
    const activeFilters = [];

    if (search) activeFilters.push(ActiveFilter.search);

    if (location?.formatted_address) activeFilters.push(ActiveFilter.location);

    if (locationType !== EventsPageLocationType.all) activeFilters.push(ActiveFilter.locationType);

    return activeFilters;
};

export const getEventsPageOrderDisplayText = (
    order: EventsPageOrder,
    filter: EventsPageFilter,
    t: TFunction
) => {
    if (order === EventsPageOrder.recently_published) {
        return t('common:recently_published', 'Recently published');
    }

    const ascendingAndDescendingFilterNames = {
        [EventsPageFilter.today]: {
            [EventsPageOrder.date_ascending]: t('common:soonest_first', 'Soonest first'),
            [EventsPageOrder.date_descending]: t('common:furthest_out', 'Furthest out'),
        },
        [EventsPageFilter.next_seven_days]: {
            [EventsPageOrder.date_ascending]: t('common:soonest_first', 'Soonest first'),
            [EventsPageOrder.date_descending]: t('common:furthest_out', 'Furthest out'),
        },
        [EventsPageFilter.upcoming_events]: {
            [EventsPageOrder.date_ascending]: t('common:soonest_first', 'Soonest first'),
            [EventsPageOrder.date_descending]: t('common:furthest_out', 'Furthest out'),
        },
        [EventsPageFilter.past_events]: {
            [EventsPageOrder.date_ascending]: t('common:oldest_first', 'Oldest first'),
            [EventsPageOrder.date_descending]: t('common:most_recent', 'Most recent'),
        },
        [EventsPageFilter.all_events]: {
            [EventsPageOrder.date_ascending]: t('common:oldest_first', 'Oldest first'),
            [EventsPageOrder.date_descending]: t('common:oldest_last', 'Oldest last'),
        },
    }[filter];

    return titleCase(ascendingAndDescendingFilterNames[order]);
};

export const isEventFree = (group: Group) => {
    const firstTicket = group?.products?.[0];
    const isFreeEvent = group?.products?.length === 1 && firstTicket.price === 0;

    return isFreeEvent;
};

export const userCanViewGroup = (group: Group) => {
    return (
        group?.settings?.protectionLevel === VIEW_PERMISSION_PUBLIC ||
        group?.currentUserIsMember ||
        group?.currentUserIsAdmin ||
        group?.currentUserIsDirectAdmin
    );
};
