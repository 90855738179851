import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { PostEditor } from '@web/ui/components/wysiwyg';
import { Group } from '@shared/welibrary-graphql/types';

type NewPostMainTextInputProps = {
    shouldShowTitle: boolean;
    defaultPromptText: string;
    maxLettersForPost?: number;
    body: string;
    title: string;
    group?: Group;
    handleTitleChange: (event: KeyboardEvent) => void;
    handleTextAreaChange: (value: () => string) => void;
};

const NewPostMainTextInput: React.FC<NewPostMainTextInputProps> = ({
    shouldShowTitle,
    defaultPromptText,
    body,
    maxLettersForPost,
    title,
    handleTitleChange,
    handleTextAreaChange,
    group,
}) => {
    const mentionsGroupId = group?.settings?.protectionLevel !== 'public' ? group?._id : undefined;
    return (
        <>
            {shouldShowTitle && (
                <div className="post-title-input-container">
                    <TextareaAutosize
                        autoComplete="off"
                        value={title}
                        onChange={handleTitleChange}
                        className="new-post-field title-post-field default w-input"
                        maxLength={200}
                        placeholder="Title..."
                    />
                </div>
            )}
            <form name="new-post-form" className="new-post-form">
                <PostEditor
                    defaultValue={body}
                    mentionsGroupId={mentionsGroupId}
                    onChange={handleTextAreaChange}
                    placeholder={defaultPromptText}
                />
            </form>
        </>
    );
};
export default NewPostMainTextInput;
