import React, {useEffect} from 'react';
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import ModalMenu from './ModalMenu';
import DelayUnmounting from '@web/ui/components/generic/animation/DelayUnmounting.js';

import withPortal from './withPortal';

const FunctionalModal = ({
    children,
    close,
    darkMode,
    isMounted,
    inverted,
    extraModalBodyClass,
}) => {
    const wrapperClass = 'edit-screen-wrapper';
    const containerClass = 'edit-screen-container';
    const backgroundStyle = darkMode ? { backgroundColor: 'white' } : {};

    useEffect(() => {
        const screenWidth = window.innerWidth > 479;
        const bodyElement = document.querySelector(`body`);
        const modalEl = document.querySelector('.modal-body');
        if (isMounted) {
            // disableBodyScroll(targetElement)
            if (screenWidth) {
                bodyElement.style.overflow = 'hidden';
            }
        } else {
            if (!modalEl) {
                if (screenWidth) {
                    bodyElement.style.overflow = 'auto';
                }
            }
            // enableBodyScroll(targetElement);
        }
        return () => {
            const modalEl = document.querySelector('.modal-body');
            const screenWidth = window.innerWidth > 479;
            if (!modalEl) {
                // enableBodyScroll(targetElement);
                if (screenWidth) {
                    bodyElement.style.overflow = 'auto';
                }
            }
        };
    }, [isMounted]);

    let fadeStyle;
    if (!isMounted) {
        fadeStyle = 'fadeout';
    } else {
        fadeStyle = 'fadein';
    }

    let darkModeClass = '';
    let darkModeDimmerClass = '';
    if (inverted === true) {
        darkModeClass = 'inverted';
    }
    darkModeDimmerClass = 'lightbox-white';

    return (
        <div style={{ zIndex: '1234516' }}>
            <div
                onClick={close}
                className={`lightbox-dimmer ${darkModeDimmerClass} ${fadeStyle}`}
            />
            <div className={`lightbox-wrapper ${fadeStyle}`}>
                <div className={`${wrapperClass}`}>
                    <div className={`${containerClass} ${darkModeClass}`} style={backgroundStyle}>
                        <div className={`modal-body ${extraModalBodyClass}`}>{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

class BaseModal extends React.Component {
    constructor(props) {
        super(props);
        this.targetElement = null;

        this.state = {
            submitFromOutside: false,
            clickedOutside: false,
        };
    }

    submitCustomForm = () => {
        this.setState({
            submitFromOutside: true,
        });
    };

    componentDidMount() {
        this.targetElement = document.querySelector('.lightbox-content-container');
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    modalRef = React.createRef();

    handleClickOutside = e => {
        if (!this.modalRef.current.contains(e.target)) {
            this.setState({ clickedOutside: true });
            this.props.close();
        }
    };

    showTargetElement = () => {
        disableBodyScroll(this.targetElement);
    };

    hideTargetElement = () => {
        enableBodyScroll(this.targetElement);
    };

    render() {
        const {
            children,
            cardEdit,
            isMounted,
            triggerMenu,
            smallModal,
            inverted,
            contentCard,
        } = this.props;
        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, {
                submitFromOutside: this.state.submitFromOutside,
            })
        );

        let fadeStyle;

        if (!isMounted) {
            fadeStyle = 'fadeout';
            this.hideTargetElement();
        } else {
            fadeStyle = 'fadein';
            this.showTargetElement();
        }
        let darkModeClass = '';
        let darkModeDimmerClass = '';
        if (inverted === true) {
            darkModeClass = 'inverted';
            darkModeDimmerClass = 'lightbox-white';
        }

        let currentModal;

        // debugger
        if (this.props.darkMode !== undefined) {
            // debugger
            currentModal = (
                <div className={`lightbox-wrapper ${fadeStyle}`}>
                    {!smallModal && (
                        <ModalMenu
                            menuText={this.props.menuText}
                            triggerMenu={triggerMenu}
                            cardEdit={cardEdit}
                            handleSubmit={this.submitCustomForm}
                            handleClose={this.props.close}
                            darkMode={this.props.darkMode}
                        />
                    )}
                    <div className="edit-screen-wrapper-modal-test">
                        <div className="edit-screen-container" style={{ backgroundColor: 'white' }}>
                            <div className="modal-body">{childrenWithProps}</div>
                        </div>
                    </div>
                </div>
            );
        } else {
            currentModal = (
                <div className={`lightbox-wrapper ${fadeStyle}`}>
                    {!smallModal && !contentCard && (
                        <ModalMenu
                            menuText={this.props.menuText}
                            triggerMenu={triggerMenu}
                            cardEdit={cardEdit}
                            handleSubmit={this.submitCustomForm}
                            handleClose={this.props.close}
                        />
                    )}
                    <div className="lightbox-content-wrapper">
                        <div
                            className={`lightbox-content-container ${darkModeClass}`}
                            style={
                                smallModal
                                    ? { maxWidth: '400px', margin: '20px' }
                                    : contentCard
                                    ? { width: 0 }
                                    : null
                            }
                        >
                            <div className="modal-body">{childrenWithProps}</div>
                        </div>
                    </div>
                </div>
            );
        }
        // debugger
        return (
            <div style={{ zIndex: '1234516' }}>
                <div
                    onClick={this.props.close}
                    className={`lightbox-dimmer ${darkModeDimmerClass} ${fadeStyle}`}
                />
                {currentModal}
            </div>
        );
    }
}

const Modal = DelayUnmounting(withPortal(FunctionalModal, 'modal-mid-root'));

export default Modal;
