import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { useCurrentUser } from '@stores/User';
import useMd from '@components/generic/hooks/useMd';

import NotificationAction from '@components/generic/notifications/NotificationAction';
import DropdownController from '@components/generic/dropdownmenu/DropdownController';
import Fade from '@components/generic/animation/Fade';

import { dotStyle, tempStyle } from '@components/generic/notifications/Notification.styles';

import {
    ctaURItoData,
    getCollectionTypeFromEventAction,
    NOTIFICATION_LEVEL,
} from '@core/utilities/constants/notifications';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
    transformExif,
} from '@core/utilities/constants/defaults';
import { getTimeSince } from '@core/utilities/utility_functions';

import {
    GET_GROUP_DASHBOARD,
    GET_GROUP_MEMBERSHIP,
    GET_CURRENT_USER_SIMPLE,
} from '@shared/welibrary-graphql/user/queries';
import { BULK_SET_NOTIFICATION_SETTINGS } from '@shared/welibrary-graphql/notifications/mutations';
import { SWITCH_ACTIVE_GROUP } from '@shared/welibrary-graphql/user/mutations';

import getLogger from '@core/logger';
import { CARD_EVENT } from '@core/utilities/constants/card_types';

import { Notification as NotificationType } from '@shared/welibrary-graphql/types';

const logger = getLogger(module);

// const localNamespace = 'imports.wlWeb.ui.components.generic.notifications.notification';

type NotificationProps = {
    block: boolean;
    notification: NotificationType;
    snoozed: (notification: NotificationType) => boolean;
    markNotificationsRead: (notificationIds: string[]) => void;
    closeNotifications?: () => void;
};

/*
 * Notification component takes in props from notifications component to render
 * a notification ...:D   <---this guy
 */
const Notification = React.forwardRef<HTMLLIElement, NotificationProps>(function Notification(
    { block, notification, snoozed, markNotificationsRead, closeNotifications },
    ref
) {
    const { t } = useTranslation();
    const {
        currentUser: { _id: currentUserId },
    } = useCurrentUser();
    const history = useHistory();
    const md = useMd();
    const [follow, setFollow] = useState(null);

    const [snoozeNotifications] = useMutation(BULK_SET_NOTIFICATION_SETTINGS);
    const [switchActiveGroup] = useMutation(SWITCH_ACTIVE_GROUP);

    // Determine if notifcation user is anonymous or not
    // There should be a better way to do this, e.g. the notifcation data shape perhaps needs to be expanded with an additional field
    // or don't return the full profile info on the backend if its anonymous at all
    // that says if its anonymous or not, this is a quick hack
    const isAnonymous = notification?.messageDetails?.body?.includes(' __Anonymous__');

    const {
        _id: notificationId,
        notificationType,
        messageDetails,
        eventDetails,
        publish,
        currentUserReadCount,
        currentUserNotificationLevel,
    } = notification;

    const linkData = ctaURItoData(messageDetails.cta.link);
    let messageThumb;
    let messageLink;
    const showMessageThumb = true;

    const snoozeTime = currentUserNotificationLevel === NOTIFICATION_LEVEL.OFF;
    const [isSnoozed, toggleIsSnoozed] = useState(snoozeTime);

    const handleSuggestion = () => {
        setFollow(true);
    };

    const handleDenySuggestion = () => {
        logger.warn('DenySuggestion handler not implemented ');
        // this.props.handleDelete(this.props.notification.id)
    };

    const snoozeStream = async days => {
        // TODO - expiry on NotificationSetting is noted to be seconds after epoch, do we want that or a datetime?
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

        const expiry = snoozed(notification) ? null : date;
        const level = snoozed(notification) ? 'ON' : 'OFF';

        const channels = ['PUBSUB', 'PUSH'];
        const { action, target } = eventDetails;
        const collection = target.collection ?? getCollectionTypeFromEventAction(action);

        await snoozeNotifications({
            variables: {
                settings: {
                    settings: channels.map(channel => ({
                        level,
                        expiry,
                        channel,
                        collection,
                        set: !isSnoozed,
                        category: action,
                        _id: target.id,
                        isTarget: true,
                    })),
                },
            },
        });

        toggleIsSnoozed(!isSnoozed);
    };

    const snoozeText = t(
        `common:imports.wlWeb.ui.components.messaging.message.quick_menu.${isSnoozed ? 'unsnooze' : 'snooze'
        }`
    );

    const quickMenuList = [
        {
            id: 1,
            title: snoozeText,
            onClick: () => snoozeStream(1),
            iconName: 'Snooze for 24 hours',
        },
    ];

    const { action, actor, target } = eventDetails;

    let _metadata = null;

    // check if the metadata field exists && if it's a string for backwards compatibility
    // otherwise the app will crash .. since previous notifications will not contain this field
    if (eventDetails?.metadata && typeof eventDetails?.metadata === 'string') {
        _metadata = JSON.parse(eventDetails?.metadata);
    }

    let messageBody = messageDetails.body;
    let extraClickLogic;

    switch (action) {
        case 'groupInviteRequest':
            messageThumb = target ? transformExif(target.picture) : '';
            if (target?.id) {
                if (_metadata?.subgroupId) {
                    messageLink = `/g/${_metadata?.subgroupId}`;
                } else if (_metadata?.cardId) {
                    messageLink = `/c/${_metadata?.cardId}`;
                } else if (!_metadata?.subgroupId && _metadata?.subtype === CARD_EVENT) {
                    messageLink = `/g/${target?.id}?showEventRegistration=true`;
                } else {
                    messageLink = `/g/${target?.id}`;
                }
            } else {
                messageLink = '#';
            }
            break;
        case 'groupSpeakerInvite':
            messageThumb = target ? transformExif(target.picture) : '';
            if (target?.id) {
                messageLink = `/g/${eventDetails.target.id}`;
            } else {
                messageLink = '#';
            }
            break;
        case 'groupPreAuthPaymentRequest':
            messageThumb = target ? transformExif(target.picture) : '';
            if (target?.id) {
                messageLink = `/g/${eventDetails.target.id}/members`;
            } else {
                messageLink = '#';
            }
            break;
        case 'groupPreAuthPaymentRequestApproved':
            messageThumb = target ? transformExif(target.picture) : '';
            messageLink = '#';
            extraClickLogic = () => {
                if (target?.id) {
                    switchActiveGroup({
                        variables: { groupId: target.id },
                        refetchQueries: [
                            GET_GROUP_DASHBOARD,
                            GET_GROUP_MEMBERSHIP,
                            GET_CURRENT_USER_SIMPLE,
                        ],
                        update: () => {
                            history.push(`/g/${target.id}`);
                        },
                    });
                }
            };
            break;
        case 'subscriptionPriceChange':
            messageThumb = target ? transformExif(target.picture) : '';
            if (target?.id) {
                if (_metadata?.orderId) {
                    messageLink = `/g/${eventDetails.target.id}?showSubscription=true&orderId=${_metadata?.orderId}`;
                } else {
                    messageLink = `/g/${eventDetails.target.id}?showSubscription=true`;
                }
            } else {
                messageLink = '#';
            }
            break;
        case 'subscriptionDeleted':
            messageThumb = target ? transformExif(target.picture) : '';
            if (target?.id) {
                if (_metadata?.orderId) {
                    messageLink = `/g/${eventDetails.target.id}?showOrder=true&orderId=${_metadata?.orderId}`;
                } else {
                    messageLink = `/g/${eventDetails.target.id}`;
                }
            } else {
                messageLink = '#';
            }
            break;
        case 'groupConnectedAccountReady':
            messageThumb = target ? transformExif(target.picture) : '';
            if (target?.id) {
                messageLink = `/g/${eventDetails.target.id}`;
            } else {
                messageLink = '#';
            }
            break;
        case 'groupAccessApproved':
        case 'groupAdminPromoted':
            messageThumb = target ? transformExif(target.picture) : '';
            messageLink = '#';
            extraClickLogic = () => {
                if (target?.id) {
                    switchActiveGroup({
                        variables: { groupId: target.id },
                        refetchQueries: [
                            GET_GROUP_DASHBOARD,
                            GET_GROUP_MEMBERSHIP,
                            GET_CURRENT_USER_SIMPLE,
                        ],
                        update: () => {
                            history.push(`/g/${target.id}`);
                        },
                    });
                }
            };
            break;
        case 'groupContentAutoModerated':
        case 'groupContentModeratedAccepted':
        case 'groupContentModeratedRejected':
            messageThumb = target ? transformExif(target.picture) : '';
            if (_metadata?.cardId) {
                messageLink = `/u?tab=moderation&cardId=${_metadata?.cardId}`;
            }
            break;
        case 'cardContentAutoModerated':
        case 'cardContentModeratedAccepted':
        case 'cardContentModeratedRejected':
            messageThumb = target ? transformExif(target.picture) : '';
            if (_metadata?.cardId) {
                messageLink = `/u?tab=moderation&cardId=${_metadata?.cardId}`;
            }
            break;
        case 'acceptConnection':
            // Accept connection goes to both users, so we need to get the right profile image for each user depending on who accepted the connection request.
            if (currentUserId !== actor?._id) {
                messageThumb = actor ? transformExif(actor.profile.picture) : '';
            } else {
                messageThumb = target ? transformExif(target.picture) : '';
            }
            messageLink = linkData.link ? linkData.link : '#';
            break;
        case 'ticketTransfer':
            if (currentUserId !== actor?._id) {
                messageThumb = actor ? transformExif(actor.profile.picture) : '';
            } else {
                messageThumb = target ? transformExif(target.picture) : '';
            }
            messageLink = linkData.link ? linkData.link : '#';
            break;
        case 'newMessage':
            if (messageDetails.subtitle) {
                messageBody = `__${messageDetails.subtitle}__: ${messageBody}`;
            }
            messageThumb = actor ? transformExif(actor.profile.picture) : '';
            messageLink = linkData.link ? linkData.link : '#';
            break;
        case 'eventCanceled':
            messageThumb = target
                ? transformExif(target.picture)
                : transformExif(actor.profile.picture);
            messageLink = '#';
            break;
        default:
            messageThumb = actor ? transformExif(actor.profile.picture) : '';
            messageLink = linkData?.link ? linkData.link : '#';
            break;
    }

    // Link component takes replace as prop to replace history or push
    let replace = false;
    // Instead of going to the link, append query parameter
    if (eventDetails?.target?.collection === 'COMMENT') {
        // This is to handle legacy or old notifications....strip link so it's just the id....
        let cardUrl = linkData.link;
        if (linkData.link?.includes('/c/')) {
            const splitLinks = linkData.link?.split(/[/?]/);
            // current format is /c/:cardUrl?=showComments=true
            // splits into ['',c, url, showComments=true]
            // eslint-disable-next-line prefer-destructuring
            cardUrl = splitLinks[2];
        }

        /**
         * This handles the new way of comment notifications
         * Data format: { params: { <key>: <value> } }
         * */
        const commentParams = linkData?.data?.params?.showComments;
        if (commentParams) {
            cardUrl = commentParams;
            replace = true;
        }

        messageLink = {
            search: `?showComments=${cardUrl}`,
        };
    }

    const getDefaultImage = () => {
        return actor?.type === 'group'
            ? getRandomDefaultCoverImage(actor?.profile?.full_name)
            : getRandomDefaultUserImage();
    };

    const [src, srcSet] = getBackgroundImageSetForUrl(
        !isAnonymous ? messageThumb : getDefaultImage()
    );

    const handleClickNotification = () => {
        markNotificationsRead?.([notificationId]);
        closeNotifications?.();
        extraClickLogic?.();
    };

    const bodyContent = md.render(messageBody);

    const justNowText = t('common:now', 'Now');

    return (
        <li ref={ref} className={`notification-container ${block ? 'block-item' : ''}`}>
            <Link
                className={`notification-item w-inline-block ${currentUserReadCount <= 0 ? 'new-notification' : 'read-notification'
                    }`}
                to={messageLink}
                replace={replace}
                onClick={handleClickNotification}
            >
                <div className="snooze-container">
                    <Fade show={isSnoozed} className="navbar">
                        <div className="snooze-overlay">
                            <div className="snooze-content" />
                        </div>
                    </Fade>

                    {showMessageThumb && (
                        <StyledBackgroundImage
                            className="notification-thumb match w-inline-block"
                            src={src}
                            srcSet={srcSet}
                        />
                    )}
                </div>
                <div className="notification-right w-inline-block">
                    <div className="we-notification-message-body">
                        <span
                            className="we-notification-message-body-span"
                            dangerouslySetInnerHTML={{ __html: bodyContent }}
                        />
                        <span className="gray-text smaller-text notification-timestamp">
                            {getTimeSince(publish, justNowText)}
                        </span>
                        <div className="notification-dropdown-positioning">
                            <DropdownController
                                style={tempStyle}
                                dotStyle={dotStyle}
                                menuList={quickMenuList}
                                inverted={false}
                            />
                        </div>
                    </div>
                </div>

                {notificationType === 'suggestion' && follow === null && (
                    <div className="notification-actions">
                        <div
                            onClick={handleSuggestion}
                            className="notification-text-button w-inline-block"
                        >
                            <div>{t('common:global.verbs.follow')}</div>
                        </div>
                        <div
                            onClick={handleDenySuggestion}
                            className="notification-action-button deny w-inline-block"
                        />
                    </div>
                )}

                <NotificationAction notification={notification} />
            </Link>
        </li>
    );
});

export default Notification;
