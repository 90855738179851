import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { useCreateOrderRefundMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import useModal from '@components/modals/hooks/useModal';
import useConfirmation from '@components/generic/hooks/useConfirmation';

import ArrowBendDownRight from '@dsc/svgs/ArrowBendDownRight';

import {
    getRandomDefaultUserImage,
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import {
    convertCentsToDollars,
    SubscriptionStatusEnum,
} from '@components/profile/stripe/stripeOrderHelpers';

import { getProductInstanceFrequencyForOwners } from '@components/group/groupdashboard/GroupMembers/groupMembersHelpers';

import {
    Group,
    User,
    CustomerOrder,
    StripeSubscriptionStatus,
} from '@shared/welibrary-graphql/types';
import { GET_ORDERS_FOR_GROUP } from '@shared/welibrary-graphql/user/queries';

import getLogger from '@core/logger';
import { getCurrencyDisplayString } from '@web/utilities/helpers/money/money.helpers';

const logger = getLogger(module);

type StripeCustomerOrderHistoryItemListViewProps = {
    order: CustomerOrder;
    orderStatus: React.ReactNode;
    refundList: React.ReactNode;
    quantity: number;
    productName: string;
    purchaser: User;
    event: Group;
    showRefundButton: boolean;
    activeTab: string;
};

const StripeCustomerOrderHistoryItemListView: React.FC<
    StripeCustomerOrderHistoryItemListViewProps
> = ({
    order,
    orderStatus,
    refundList,
    quantity,
    productName,
    purchaser,
    event,
    showRefundButton,
    activeTab,
}) => {
    const { closeModal } = useModal();
    const confirm = useConfirmation();

    const [createOrderRefund, { loading, error }] = useCreateOrderRefundMutation();
    const [showMoreDetails, setShowMoreDetails] = useState<boolean>(false);

    const createdAt = new Date(Number(order?.created));
    const formattedDate = moment(createdAt).format('MMM D, YYYY');
    const currency = order?.paymentIntent?.currency ?? 'USD';
    const orderTotal = convertCentsToDollars(order?.paymentIntent?.amount);
    const totalDisplayString = getCurrencyDisplayString(orderTotal, currency, undefined, true, 2);
    const isEvent = event?.subtype === 'event';

    const [coverSrc, coverSrcSet] = getBackgroundImageSetForUrl(
        event?.profile?.coverPhoto || getRandomDefaultCoverImage(event?.profile?.full_name)
    );

    const handleOrderRefund = () => {
        if (order?.paymentIntent?.id) {
            confirm({
                text: 'Are you sure want to issue a refund for this order?',
                onConfirm: () => {
                    createOrderRefund({
                        variables: {
                            paymentIntentId: order?.paymentIntent?.id,
                        },
                        refetchQueries: [GET_ORDERS_FOR_GROUP],
                    })
                        .then(() => {
                            // TODO: trigger success modal here
                        })
                        .catch(err => {
                            logger.error(err);
                        });
                },
            });
        }
    };

    let subscriptionStatus: React.ReactNode | null = null;

    if (order?.subscription?.status === SubscriptionStatusEnum.Active) {
        subscriptionStatus = (
            <span className="order-list-item-sub-status order-status-active">
                {order?.subscription?.status}
            </span>
        );
    } else if (
        order?.subscription?.status === StripeSubscriptionStatus.Canceled ||
        order?.subscription?.status === StripeSubscriptionStatus.Incomplete ||
        order?.subscription?.status === StripeSubscriptionStatus.IncompleteExpired ||
        order?.subscription?.status === StripeSubscriptionStatus.Unpaid
    ) {
        subscriptionStatus = (
            <span className="order-list-item-sub-status order-status-cancelled">
                {order?.subscription?.status}
            </span>
        );
    } else if (order?.subscription?.status === StripeSubscriptionStatus.PastDue) {
        subscriptionStatus = (
            <span className="order-list-item-sub-status order-status-pastdue">
                {order?.subscription?.status}
            </span>
        );
    } else if (order?.subscription?.status === StripeSubscriptionStatus.Trialing) {
        subscriptionStatus = (
            <span className="order-list-item-sub-status order-status-trial">
                {order?.subscription?.status}
            </span>
        );
    }

    let ticketsOwned =
        order?.productInstances?.filter(pr => order?.user?._id === pr?.owner?._id) ?? [];

    let ticketsTransferred = getProductInstanceFrequencyForOwners(
        order?.productInstances?.filter(pr => order?.user?._id !== pr?.owner?._id) ?? []
    );

    let _productName = productName;
    const _quantity = quantity;

    // filter data shown based on active tab
    if (activeTab) {
        const purchaseRequest = order?.purchaseRequests?.find(
            ({ product }) => product?.name === activeTab
        );

        ticketsOwned =
            order?.productInstances?.filter(
                pr => order?.user?._id === pr?.owner?._id && pr?.product?.name === activeTab
            ) ?? [];

        ticketsTransferred = getProductInstanceFrequencyForOwners(
            order?.productInstances?.filter(
                pr => order?.user?._id !== pr?.owner?._id && pr?.product?.name === activeTab
            ) ?? []
        );

        _productName = purchaseRequest?.product?.name;
        // _quantity = purchaseRequest?.quantity;
    }

    const orderDetails = (
        <div className="order-ticket-list !mb-4">
            <div className="order-ticket-list-title">
                <h4>Order Details</h4>
            </div>
            {order?.purchaseRequests?.map(pr => {
                const product = pr?.product;
                const qty = pr?.quantity;

                const activeStyles = activeTab === product?.name ? 'bg-[#e5f4fe]' : '';

                return (
                    <div
                        className={`flex items-center justify-between w-full py-[6px] pr-[6px] pl-[2px] rounded-[12px] ${activeStyles} mb-2`}
                    >
                        <p className="p-0 m-0 text-[#4e525a] font-semibold">{product?.name}</p>
                        <p className="p-0 m-0 flex items-center font-poppins font-semibold">
                            Tickets: {qty}
                        </p>
                    </div>
                );
            })}
        </div>
    );

    const ticketsOwnershipList = (
        <div className="order-ticket-list">
            {ticketsOwned.length === 0 && (
                <div className="order-ticket-list-holder-wrap order-ticket-list-owner-wrap">
                    <div className="order-ticket-list-holder-img-wrap">
                        <img
                            src={
                                order?.user?.profile?.picture?.length > 0
                                    ? order?.user?.profile?.picture
                                    : getRandomDefaultUserImage()
                            }
                            alt="purchaser"
                        />
                    </div>
                    <div className="order-ticket-list-owner-details-wrap">
                        <h4>{order?.user?.profile?.full_name}</h4>
                        <p className="order-ticket-list-owner-details-tickets">
                            Tickets: {ticketsOwned?.length ?? 0}
                        </p>
                    </div>
                </div>
            )}

            <div className="order-ticket-list-title">
                <h4>Ticket Holders</h4>
            </div>
            {ticketsOwned.length > 0 && (
                <div className="order-ticket-list-holder-wrap order-ticket-list-owner-wrap">
                    <div className="order-ticket-list-holder-img-wrap">
                        <img
                            src={
                                order?.user?.profile?.picture?.length > 0
                                    ? order?.user?.profile?.picture
                                    : getRandomDefaultUserImage()
                            }
                            alt="purchaser"
                        />
                    </div>
                    <div className="order-ticket-list-owner-details-wrap">
                        <div className="order-ticket-list-owner-details-wrap-user">
                            <h4>{order?.user?.profile?.full_name}</h4>
                            <div className="order-ticket-list-owner-details-wrap-user-sub">
                                {order?.user?.profile?.phone && (
                                    <>
                                        <h5>{order?.user?.profile?.phone}</h5>
                                        <p>•</p>
                                    </>
                                )}
                                <h5>{order?.user?.profile?.email}</h5>
                            </div>
                        </div>
                        <p className="order-ticket-list-owner-details-tickets">
                            Tickets: {ticketsOwned?.length ?? 0}
                        </p>
                    </div>
                </div>
            )}
            {ticketsTransferred?.map(ticket => (
                <div className="order-ticket-list-holder-wrap">
                    <div className="order-ticket-list-holder-img-wrap">
                        <img
                            src={
                                ticket?.owner?.profile?.picture?.length > 0
                                    ? ticket?.owner?.profile?.picture
                                    : getRandomDefaultUserImage()
                            }
                            alt="owner"
                        />
                    </div>
                    <div className="order-ticket-list-holder-details-wrap">
                        <div className="order-ticket-list-holder-details">
                            <p>{ticket?.owner?.profile?.full_name}</p>
                            <p className="order-ticket-list-owner-details-tickets">
                                Tickets: {ticket?.count ?? 0}
                            </p>
                        </div>
                        <div className="order-ticket-list-transfer-details">
                            <ArrowBendDownRight /> Transferred from&nbsp;
                            <p>{order?.user?.profile?.full_name}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className="order-list-item">
            <div className="order-list-item-top-container">
                <div className="order-list-item-img-wrap">
                    <StyledBackgroundImage
                        disableLink
                        src={coverSrc}
                        srcSet={coverSrcSet}
                        className="order-list-item-cover"
                    />
                </div>
                <div className="order-list-item-details-wrap">
                    <div className="order-list-item-date-wrap">
                        <p>{formattedDate}</p>
                        <p className="order-history-item-inner-details-status">
                            Status: &nbsp; {orderStatus}
                        </p>
                    </div>
                    <div className="order-list-item-name-wrap">
                        <h5>{_productName}</h5>
                        <h6>{event?.profile?.full_name}</h6>
                    </div>
                    <div className="order-list-item-price-wrap">
                        <p>
                            <Link
                                to={`/u/${purchaser?._id}`}
                                onClick={closeModal}
                                className="order-list-item-purchaser"
                            >
                                {purchaser?.profile?.full_name}
                            </Link>
                            &nbsp;&bull;&nbsp;Quantity: {_quantity}
                        </p>
                        <h3>{totalDisplayString}</h3>
                    </div>
                    <div className="order-list-item-btn-more-wrap">
                        <button type="button" onClick={() => setShowMoreDetails(!showMoreDetails)}>
                            {showMoreDetails ? <p>Show less details</p> : <p>Show more details</p>}
                        </button>
                    </div>
                </div>
            </div>
            {showMoreDetails && (
                <>
                    {orderDetails}
                    {isEvent && ticketsOwnershipList}
                    {refundList}
                    <div className="order-details-subs-status-wrap">
                        <h6>Order# {order?.paymentIntent?.id ?? order?._id}</h6>{' '}
                        {order?.subscription && <p>Subscription Status: {subscriptionStatus}</p>}
                    </div>
                    {error && (
                        <div className="order-refund-error">
                            <p>{error.message}</p>
                        </div>
                    )}
                </>
            )}
            <div className="order-list-item-bottom-container">
                <div className="order-list-item-bottom-wrap">
                    {showRefundButton && (
                        <button type="button" onClick={handleOrderRefund}>
                            {loading ? 'Loading...' : 'Refund'}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StripeCustomerOrderHistoryItemListView;
