import React from 'react';

const Pencil: React.FC<{ className?: string; strokeWidth?: string; version?: '1' | '2' }> = ({
    className = '',
    strokeWidth = '1',
    version = '1',
}) => {
    if (version === '1') {
        return (
            <svg
                className={className}
                viewBox="0 0 500 500"
                strokeWidth={strokeWidth}
                stroke="currentcolor"
                fill="currentcolor"
            >
                <path d="M409,52.16,379.21,30.53a45.09,45.09,0,0,0-62.85,10L88,354.92a16.29,16.29,0,0,0-3,7.53L72.6,459.6a16.35,16.35,0,0,0,23.22,16.87l88.55-41.86a16.33,16.33,0,0,0,6.24-5.18L419,115A45.05,45.05,0,0,0,409,52.16ZM166.59,406.81l-57.75,27.3L117,370.76,319.77,91.49l50,35.62Z" />
            </svg>
        );
    }

    // version === '2'
    return (
        <svg
            className={className}
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 20.7499H4.5C4.30109 20.7499 4.11032 20.6709 3.96967 20.5302C3.82902 20.3896 3.75 20.1988 3.75 19.9999V15.8105C3.75 15.7121 3.7694 15.6145 3.80709 15.5235C3.84478 15.4325 3.90003 15.3499 3.96967 15.2802L15.2197 4.03022C15.3603 3.88956 15.5511 3.81055 15.75 3.81055C15.9489 3.81055 16.1397 3.88956 16.2803 4.03022L20.4697 8.21956C20.6103 8.36021 20.6893 8.55097 20.6893 8.74989C20.6893 8.9488 20.6103 9.13956 20.4697 9.28022L9 20.7499Z"
                stroke="#6E7191"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.75 6.5L18 11.75"
                stroke="#6E7191"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Pencil;
