// src/user/mutations.ts
import { gql as gql7 } from "@apollo/client";

// src/messaging/fragments.ts
import { gql as gql4 } from "@apollo/client";

// src/content_card/fragments.ts
import { gql as gql3 } from "@apollo/client";

// src/comment/fragments.ts
import { gql } from "@apollo/client";
var COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    __typename
    _id
    publish
    stream {
      __typename
      _id
    }
    creator {
      __typename
      _id
      profile {
        picture
        full_name
      }
    }
    body
    loggedInUserCanEdit
    childCount
    parentId
  }
`;

// src/tag/fragments.ts
import { gql as gql2 } from "@apollo/client";
var TAG_FRAGMENT = gql2`
    fragment TagFragment on Tag {
        __typename
        _id
        label
        color
        created
        updated
    }
`;

// src/content_card/fragments.ts
var UPDATE_FEATURE_CONSTRAINTS_FRAGMENT = gql3`
  fragment UpdateFeatureConstraintsFragments on ContentCard {
    __typename
    updateFeatureConstraints {
      canEditAllFields
      featuresUserCanEdit {
        type
        field
        options
        canUpdate
      }
    }
  }
`;
var CONTENT_CARD_SEARCH_FRAGMENT = gql3`
  fragment ContentCardSearchFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    media {
      icon
      thumb
    }
    prompt
    isBookmarked
    isArchived
  }
`;
var CONTENT_CARD_COURSE_SEARCH_FRAGMENT = gql3`
  fragment ContentCardCourseSearchFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    media {
      icon
      thumb
    }
    prompt
    courseItems {
      levels {
        title
        media
        resource
      }
    }
    isBookmarked
    isArchived
  }
`;
var CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT = gql3`
  fragment ViewPermissions on ContentCard {
    currentViewPermissions {
      level
      canUserView
    }
  }
`;
var CONTENT_CARD_RATINGS_FRAGMENT = gql3`
  fragment CardRatings on ContentCard {
    ratings_sentiment_score
    latest_rating_sentiments {
      _id
      profile {
        full_name
      }
    }
    ratings_quality_score
    ratings_relevance_score {
      score
      hashtag
    }
    ratings_rank
    loggedInUserVotes {
      sentiment
      quality
      relevance
    }
  }
`;
var CONTENT_CARD_BOOK_SOURCE_FRAGMENT = gql3`
  fragment ContentCardBookSourceFragment on ContentCard {
    __typename
    _id
    url
    title
    library_settings {
      displayReference
      protectionLevel
      accessRoleWhitelist
    }
    reference {
      groupSource {
        profile {
          full_name
        }
        settings {
          protectionLevel
        }
      }
    }
  }
`;
var CONTENT_CARD_REFERENCE_FRAGMENT = gql3`
  fragment ContentCardReferenceFragment on ContentCard {
    reference {
      href
      isResource
      coverImage
      chapterSource {
        title
        url
        prompt
        showTitle
        inheritSurveyAnswers
        subtype
        vote_settings {
          QUALITY {
            active
          }
          RELEVANCE {
            active
          }
          SENTIMENT {
            active
          }
        }
        library_settings {
          engagementTypes
          viewTypes
          defaultViewType
          displayReference
          displaySocialHeaders
        }
        media {
          thumb
        }
      }
      bookSource {
        ...ContentCardBookSourceFragment
      }
      shelfSource {
        title
        url
        library_settings {
          displayReference
          protectionLevel
        }
      }
      librarySource {
        title
        url
        library_settings {
          displayReference
        }
      }
      groupSource {
        _id
        subtype
        profile {
          full_name
          picture
          coverPhoto
        }
        settings {
          shortCode
          shortCodeApprovalRequired
          protectionLevel
          approvalRequired
          formSpec {
            url
          }
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        groupActionsAllowed
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
      }
    }
  }
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
`;
var CONTENT_CARD_COMMENTS_FRAGMENT = gql3`
  fragment CardCommentsFragment on ContentCard {
    __typename
    _id
    comments {
      ...CommentFragment
    }
  }
  ${COMMENT_FRAGMENT}
`;
var REVEAL_CARD_FRAGMENT = gql3`
  fragment RevealCardFragment on ContentCard {
    __typename
    type
    links {
      source
      other
    }
    media {
      thumb
      icon
      logoLight
      logoDark
    }
  }
`;
var CONTENT_CARD_CREATION_FRAGMENT = gql3`
  fragment ContentCardCreationFragment on CreationItems {
    silhouetteImage
    course {
      _id
      media {
        icon
        thumb
      }
      title
      courseItems {
        materials
        ageGroup
        duration
        people
        levels {
          title
          media
          resource
          preSurvey {
            _id
            type
            subtype
            prompt
            useImage
            image
            range
            answers
            correctAnswer
            skill
            skillSubtype
            skillMilestone
          }
          postSurvey {
            _id
            type
            subtype
            prompt
            useImage
            image
            range
            answers
            correctAnswer
            skill
            skillSubtype
            skillMilestone
          }
        }
      }
      url
    }
    levels {
      title
      description
      image
    }
  }
`;
var CONTENT_CARD_COURSE_FRAGMENT = gql3`
  fragment ContentCardCourseFragment on CourseItems {
    materials
    ageGroup
    duration
    people
    levels {
      title
      media
      video
      resource
      steps {
        text
        useImage
        image
        useVideo
        video
        fullPage
        pageBreak
        skill
        skillSubtype
        skillMilestone
      }
      preSurvey {
        _id
        type
        subtype
        prompt
        useImage
        image
        range
        answers
        correctAnswer
        skill
        skillSubtype
        skillMilestone
      }
      postSurvey {
        _id
        type
        subtype
        prompt
        useImage
        image
        range
        answers
        correctAnswer
        skill
        skillSubtype
        skillMilestone
      }
    }
    publicCompletions
  }
`;
var CONTENT_CARD_PLEDGES_FRAGMENT = gql3`
  fragment ContentCardPledgesFragment on PledgeItems {
    __typename
    _id
    pledges {
      __typename
      _id
      label
      reportingType
      canPledge
      canDeletePledge
      totalPledges
      currentUsersPledges
      currentUsersPledgesMonth: currentUsersPledges(daysAgo: 30)
      currentUsersPledgesDay: currentUsersPledges(daysAgo: 1)
      successfulPledgesAll: currentUsersSuccessfulPledges
      successfulPledgesMonth: currentUsersSuccessfulPledges(daysAgo: 30)
      successfulPledgesDay: currentUsersSuccessfulPledges(daysAgo: 1)
      hidden
    }
    pledgeKeys {
      __typename
      _id
      label
      refactoryPeriod
      limitPerRefractoryPeriod
      description
      reportingType
      hidden
    }
    pledgers {
      __typename
      _id
      profile {
        full_name
        first_name
        picture
      }
    }
    latestPledgerEngagement {
      userId
      date
    }
    totalPledges
    totalPledgers
    pledgesLastDay: totalPledges(daysAgo: 1)
    pledgesLastMonth: totalPledges(daysAgo: 30)
  }
`;
var MOBILIZE_EFFORT_FRAGMENT = gql3`
  fragment MobilizeEffortFragment on ContentCard {
    __typename
    _id
    path
    url
    shortCode
    type
    title
    body
    description
    prompt
    rankOrder
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
      }
      roles {
        isSpeakerOf
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    aliases {
      ... on GroupAliasHandle {
        url
        title
        color
        type
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
      }
      ... on AliasItem {
        url
        title
        color
        type
        item {
          _id
          __typename
          title
          body
          protectionLevel
          prompt
          author {
            __typename
            _id
            type
            profile {
              full_name
            }
          }
          reference {
            href
            coverImage
            bookSource {
              ...ContentCardBookSourceFragment
            }
          }
          media {
            thumb
            icon
          }
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            displayInNewsfeed
            userCanPost
          }
          pledgeItems {
            ...ContentCardPledgesFragment
          }
          whitelabelVerbage {
            __typename
            _id
            value
          }
          isArchived
          userHasPledgedTo
          taskHistory {
            __typename
            cursor
            hasMore
            results {
              pledgeKeyId
              label
              refactoryPeriod
              date
              supplement {
                note
                image
                document
                documentTitle
              }
              verifier {
                name
                email
                phone
                country
                note
                newRecruit
              }
              claimedAccount {
                __typename
                _id
                profile {
                  picture
                  full_name
                  email
                  phone
                  country
                }
              }
            }
            type
          }
        }
      }
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
    }
    loggedInUserCanEdit
    defaultHidden
    isBookmarked
    isArchived
    applicationItems {
      applications {
        __typename
        _id
        createdAt
        status
        data {
          values {
            id
            values
          }
        }
        user {
          __typename
          _id
          profile {
            full_name
            picture
            country
            website
            email
          }
        }
      }
    }
    postingSettings {
      type
      status
      totalPositionsCount
      acceptedCount
      specification {
        url
      }
      postingData {
        parent {
          url
        }
        values {
          id
          type
          customText
          comparator
          values
          siblingId
        }
      }
      requirementsData {
        parent {
          url
        }
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    loggedInUserApplyStatus
    isTemplate
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
`;
var CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT = gql3`
  fragment ContentCardCustomFieldsFragment on ContentCard {
    _id
    customFields {
      manager
      parent {
        url
      }
      parentGroup {
        _id
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
`;
var CONTENT_CARD_FRAGMENT = gql3`
  fragment ContentCardFragment on ContentCard {
    __typename
    _id
    vs
    path
    url
    shortCode
    type
    title
    body
    shortTitle
    description
    prompt
    commentCount
    latestComments {
      cursor
      hasMore
      type
      results {
        _id
        creator {
          _id
          profile {
            full_name
            picture
          }
        }
        body
      }
    }
    inheritSurveyAnswers
    rankOrder
    subtype
    pinnedCardIds
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
        did
      }
      roles {
        isSpeakerOf
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      title
      source
      openInModal
      other
      publisher
    }
    aliases {
      ... on GroupAliasHandle {
        url
        title
        color
        thumb
        icon
        type
        group {
          __typename
          _id
          subtype
          isUnpublished
          profile {
            full_name
            picture
            coverPhoto
          }
          paymentSettings {
            stripeAccount {
              id
              email
              country
              charges_enabled
              payouts_enabled
              details_submitted
              external_accounts {
                object
                data {
                  country
                  currency
                }
                has_more
                url
              }
            }
            isGroupMembershipPaid
          }
          products {
            _id
            name
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
          }
          groupActionsAllowed
          currentUserOwnsMembership
          currentUserOwnsFreeSubscription
          doesSellMembership
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
        }
      }
      ... on MessageThreadAliasHandle {
        url
        title
        color
        thumb
        icon
        type
        thread {
          __typename
          _id
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
        }
      }
      ... on AliasItem {
        url
        title
        color
        thumb
        icon
        type
        item {
          subtype
          __typename
          _id
          title
          shortTitle
          prompt
          vote_settings {
            QUALITY {
              active
            }
            RELEVANCE {
              active
            }
            SENTIMENT {
              active
            }
          }
          type
          author {
            __typename
            _id
            type
            profile {
              full_name
            }
          }
          reference {
            href
            coverImage
            shelfSource {
              title
              url
              library_settings {
                displayReference
                protectionLevel
              }
            }
            bookSource {
              ...ContentCardBookSourceFragment
            }
            groupSource {
              _id
              subtype
              profile {
                full_name
                picture
                coverPhoto
              }
            }
          }
          media {
            thumb
            icon
          }
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            protectionLevel
            displayInNewsfeed
            userCanPost
          }
          pledgeItems {
            ...ContentCardPledgesFragment
          }
          whitelabelVerbage {
            __typename
            _id
            value
          }
          isArchived
          userHasPledgedTo
          taskHistory {
            __typename
            cursor
            hasMore
            results {
              pledgeKeyId
              label
              refactoryPeriod
              date
              supplement {
                note
                image
                document
                documentTitle
              }
              verifier {
                name
                email
                phone
                country
                note
                newRecruit
              }
              claimedAccount {
                __typename
                _id
                profile {
                  picture
                  full_name
                  email
                  phone
                  country
                }
              }
            }
            type
          }
        }
      }
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
      mediaMetaData {
        height
        width
      }
    }
    label {
      text
      type
    }
    formSpecificationSettings {
      type
      postingForm {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
          dependsOn {
            id
            values
          }
          groups {
            group {
              _id
              path
              groupActionsAllowed
              currentUserIsMember
              currentUserIsAdmin
              profile {
                picture
                full_name
                short_bio
              }
              settings {
                approvalRequired
                protectionLevel
              }
            }
            id
            requireApproval
          }
        }
      }
      applicationForm {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
        }
      }
      form {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
        }
      }
    }
    library_settings {
      postTypes
      engagementTypes
      filterTypes
      viewTypes
      defaultViewType
      defaultFilter
      allowPostbox
      postboxRoleWhiteList
      userCanPost
      displaySocialHeaders
      displayInNewsfeed
      showCreateChildren
      showResourceHeader
      allowLabels
      displayReference
      protectionLevel
      accessRoleWhitelist
      memberStyle
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      dashboardContentItems {
        name
        url
        type
        filter
      }
      customRoles {
        name
        url
        color
      }
      sidebarMenu {
        name
        url
      }
      recommendations
    }
    sendNotifications
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    meta {
      course {
        revealType
        revealContent {
          ...RevealCardFragment
        }
        compensation
        time
        sequence
      }
      research {
        category
        journalName
        organization
        publicationType
        abstract
        authors
        fileURL
        tags
        issue
      }
      mobilize {
        date
        qualifications
        skills
        summary
        city
        state
        country
        cta
        goalnumber
        hours
        matchterm
        pledge
        timeCommit
        pertime
      }
      pledge {
        date
        qualifications
        skills
        summary
        city
        state
        country
        cta
        goalnumber
        hours
        matchterm
        pledge
        timeCommit
        pertime
      }
    }
    loggedInUserCanEdit
    currentUserHasRead
    defaultHidden
    isBookmarked
    isArchived
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    isTemplate
    postingSettings {
      type
      status
      totalPositionsCount
      acceptedCount
      specification {
        url
      }
      postingData {
        parent {
          url
        }
        values {
          id
          type
          customText
          comparator
          values
          siblingId
        }
      }
      requirementsData {
        parent {
          url
        }
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    mobilizationSettings {
      approvalType
      status
      efforts {
        type
        card {
          __typename
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            displayInNewsfeed
          }
          ...MobilizeEffortFragment
        }
        status
        country
        countGoal
        count
      }
    }
    courseItems {
      ...ContentCardCourseFragment
    }
    creationItems {
      ...ContentCardCreationFragment
    }
    surveyQuestions {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
    timeAndPlaceSettings {
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        country
        postal
        state
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
    }
    eventOverviewDetails {
      title
      description
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      location {
        formatted_address
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
    }
    eventSettings {
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      location {
        formatted_address
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
    }
    eventAttendance {
      user {
        _id
        profile {
          full_name
          picture
        }
        socialActionsAllowed
      }
      status
    }
    canMembersSuggestEdits
    showTitle
    protectionLevel
    currentUserCanSuggestEdit
    verticalScrolling
    dialogSettings {
      event
      people {
        name
        image
      }
      dialogs {
        person
        text
      }
    }
    showPostTypes
    ...UpdateFeatureConstraintsFragments
    ...CardRatings
    ...ContentCardCustomFieldsFragment
  }
  ${CONTENT_CARD_RATINGS_FRAGMENT}
  ${REVEAL_CARD_FRAGMENT}
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${MOBILIZE_EFFORT_FRAGMENT}
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
  ${CONTENT_CARD_COURSE_FRAGMENT}
  ${CONTENT_CARD_CREATION_FRAGMENT}
  ${CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT}
`;
var CONTENT_CARD_PLATFORM_FRAGMENT = gql3`
  fragment ContentCardPlatformFragment on ContentCard {
    _id
    __typename
    vs
    type
    url
    platformSettings {
      platformGroupOwner
      forms {
        formCardUrl
        formSpecificationSettings {
          type
          form {
            fieldGroups {
              groupName
              index
              fieldIds
            }
            required
            fields {
              index
              id
              type
              options
              prompt
              defaultComparator
              customText
              selectMultiple
              siblingId
              allowOther
              required
              showOnSignup
              groups {
                group {
                  _id
                  path
                  groupActionsAllowed
                  currentUserIsMember
                  currentUserIsAdmin
                  profile {
                    picture
                    full_name
                    short_bio
                  }
                  settings {
                    approvalRequired
                    protectionLevel
                  }
                }
                id
                requireApproval
              }
              placeholder
              dependsOn {
                id
                values
              }
            }
          }
        }
      }
    }
  }
`;
var CONTENT_CARD_CHAPTER_FRAGMENT = gql3`
  fragment ContentCardChapterFragment on ContentCard {
    _id
    vs
    type
    url
    title
    shortTitle
    media {
      icon
      thumb
    }
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
      }
    }
    description
    isArchived
    prompt
    loggedInUserCanEdit
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    library_settings {
      postTypes
      engagementTypes
      filterTypes
      viewTypes
      defaultViewType
      defaultFilter
      allowPostbox
      postboxRoleWhiteList
      userCanPost
      displaySocialHeaders
      displayInNewsfeed
      allowLabels
      displayReference
      tagAggregation {
        ...TagFragment
      }
      cardAggregation {
        __typename
        _id
        title
        url
        body
        media {
          thumb
          icon
        }
      }
    }
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    sendNotifications
    rolesAvailable {
      url
      name
    }
    ...ContentCardReferenceFragment
    rankOrder
    canMembersSuggestEdits
    currentUserCanSuggestEdit
    showTitle
    childrenCount
    showPostTypes
    inheritSurveyAnswers
    isTemplate
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var RAINBOW_ROAD_FRAGMENT = gql3`
  fragment RainbowRoadFragment on ContentCard {
    __typename
    _id
    url
    vs
    type
    subtype
    title
    description
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
      }
      roles {
        isSpeakerOf
      }
    }
    media {
      icon
    }
    loggedInUserCanEdit
    rainbowRoadSettings {
      header {
        color
        darkMode
        image
        backgroundImage
        showTopInfo
      }
      body {
        color
        darkMode
        image
      }
      nodes {
        circleSize
        icon
        lineStyle
        lineColor
        outline
        winding
        spinnerImage
      }
      font
      questSettings {
        creationsChannel {
          url
          title
          children {
            ...ContentCardFragment
          }
        }
        creationNodeSettings {
          icon
          backgroundImage
          showMetaInformation
          spinnerImage
        }
        completionRequirement
        dialogsChannel {
          url
          title
          children {
            ...ContentCardFragment
          }
        }
        questEndImage
        vcSettings {
          title
          name
          description
          image
        }
      }
    }
    library_settings {
      engagementTypes
    }
    nodes {
      title
      icon
      backgroundImage
      spinnerImage
      showMetaInformation
      preOrPost
      alias {
        ... on UserAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          user {
            _id
            profile {
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
            }
            socialActionsAllowed
          }
        }
        ... on GroupAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          group {
            __typename
            _id
            path
            profile {
              full_name
              short_bio
              full_bio
              picture
              coverPhoto
              country
              website
              email
              customFields {
                parent {
                  url
                }
                parentGroup {
                  _id
                }
                manager
                values {
                  id
                  type
                  comparator
                  values
                  siblingId
                }
              }
            }
            socialActionsAllowed
            shelf {
              __typename
              _id
              url
              media {
                thumb
                icon
              }
            }
            settings {
              __typename
              subscriptions
              formSpec {
                url
                formSpecificationSettings {
                  type
                  form {
                    required
                    fieldGroups {
                      groupName
                      fieldIds
                      index
                    }
                    fields {
                      index
                      type
                      id
                      options
                      customText
                      prompt
                      placeholder
                      type
                      selectMultiple
                      siblingId
                      defaultComparator
                      required
                      allowOther
                      dependsOn {
                        id
                        values
                      }
                    }
                  }
                }
              }
              protectionLevel
              memberStyle
              dashboards {
                name
                url
                type
                filter
              }
              customSignupFields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                weight
                required
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
              recommendations
              canMembersSuggestEdits
              shortCode
              shortCodeApprovalRequired
              groupChatroom
              adminChatroom
              approvalRequired
              registrationTitle
              registrationDescription
              registrationActionText
              enableGroupNewsfeed
            }
            hasUnreadDashboard
            subtype
            totalMembers
            currentUserIsAdmin
            currentUserIsDirectAdmin
            currentUserIsMember
            currentUserLastActiveDate
            groupActionsAllowed
            isTemplate
            allSignupFields {
              parentGroup {
                _id
              }
              manager
              signupFields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                weight
                required
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
            }
            whitelabelVerbage {
              _id
              value
              plainText
            }
            parentGroup {
              __typename
              _id
              profile {
                full_name
              }
            }
            channelsBook {
              title
              url
              media {
                thumb
                icon
              }
              children {
                title
                url
                reference {
                  href
                }
              }
            }
          }
        }
        ... on MessageThreadAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          thread {
            __typename
            _id
            participants {
              __typename
              user {
                __typename
                _id
                profile {
                  __typename
                  full_name
                  short_bio
                  full_bio
                  picture
                  coverPhoto
                  facebookProfile
                  instagramProfile
                  twitterProfile
                  linkedInProfile
                }
              }
              hasUnreadMessages
            }
            messages {
              __typename
              hasMore
              cursor
              type
              messages {
                __typename
                _id
                author {
                  __typename
                  _id
                  profile {
                    __typename
                    first_name
                    full_name
                    picture
                  }
                }
                text
                created
                updated
              }
            }
            displayName
            displayIcon
            created
            updated
            group {
              __typename
              _id
              profile {
                full_name
                picture
                coverPhoto
              }
            }
            currentUserNotificationLevel
          }
        }
        ... on AliasItem {
          url
          type
          title
          thumb
          icon
          color
          item {
            ...ContentCardFragment
            ...ContentCardReferenceFragment
          }
        }
      }
    }
    aliases {
      ... on UserAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        user {
          _id
          profile {
            full_name
            picture
            coverPhoto
            full_bio
            short_bio
            website
            facebookProfile
            twitterProfile
            instagramProfile
            linkedInProfile
          }
          socialActionsAllowed
        }
      }
      ... on GroupAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        group {
          __typename
          _id
          path
          profile {
            full_name
            short_bio
            full_bio
            picture
            coverPhoto
            country
            website
            email
            customFields {
              parent {
                url
              }
              parentGroup {
                _id
              }
              manager
              values {
                id
                type
                comparator
                values
                siblingId
              }
            }
          }
          socialActionsAllowed
          shelf {
            __typename
            _id
            url
            media {
              thumb
              icon
            }
          }
          settings {
            __typename
            subscriptions
            formSpec {
              url
              formSpecificationSettings {
                type
                form {
                  required
                  fieldGroups {
                    groupName
                    fieldIds
                    index
                  }
                  fields {
                    index
                    type
                    id
                    options
                    customText
                    prompt
                    placeholder
                    type
                    selectMultiple
                    siblingId
                    defaultComparator
                    required
                    allowOther
                    dependsOn {
                      id
                      values
                    }
                  }
                }
              }
            }
            protectionLevel
            memberStyle
            dashboards {
              name
              url
              type
              filter
            }
            customSignupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
            recommendations
            canMembersSuggestEdits
            shortCode
            shortCodeApprovalRequired
            groupChatroom
            adminChatroom
            approvalRequired
            registrationTitle
            registrationDescription
            registrationActionText
            enableGroupNewsfeed
          }
          hasUnreadDashboard
          subtype
          totalMembers
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
          currentUserLastActiveDate
          groupActionsAllowed
          isTemplate
          allSignupFields {
            parentGroup {
              _id
            }
            manager
            signupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
          whitelabelVerbage {
            _id
            value
            plainText
          }
          parentGroup {
            __typename
            _id
            profile {
              full_name
            }
          }
          channelsBook {
            title
            url
            media {
              thumb
              icon
            }
            children {
              title
              url
              reference {
                href
              }
            }
          }
        }
      }
      ... on MessageThreadAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        thread {
          __typename
          _id
          participants {
            __typename
            user {
              __typename
              _id
              profile {
                __typename
                full_name
                short_bio
                full_bio
                picture
                coverPhoto
                facebookProfile
                instagramProfile
                twitterProfile
                linkedInProfile
              }
            }
            hasUnreadMessages
          }
          messages {
            __typename
            hasMore
            cursor
            type
            messages {
              __typename
              _id
              author {
                __typename
                _id
                profile {
                  __typename
                  first_name
                  full_name
                  picture
                }
              }
              text
              created
              updated
            }
          }
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
          currentUserNotificationLevel
        }
      }
      ... on AliasItem {
        url
        type
        title
        thumb
        icon
        color
        item {
          ...ContentCardFragment
          ...ContentCardReferenceFragment
        }
      }
    }
  }

  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var CONTENT_CARD_BOOK_FRAGMENT = gql3`
  fragment ContentCardBookFragment on ContentCard {
    _id
    vs
    type
    subtype
    url
    title
    media {
      icon
      thumb
    }
    author {
      _id
      type
      profile {
        full_name
      }
    }
    children(sort: ordered) {
      ...ContentCardChapterFragment
    }
    description
    body
    prompt
    promptEnabled
    loggedInUserFollows
    loggedInUserCanEdit
    isTemplate
    library_settings {
      displayReference
      displayInNewsfeed
      displaySocialHeaders
      protectionLevel
      accessRoleWhitelist
      defaultFilter
      filterTypes
      engagementTypes
      customRoles {
        name
        url
        color
      }
    }
    sendNotifications
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    customFields {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
    cardActionsAllowed
    rankOrder
    verticalScrolling
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_BOOK_BIG_SCREEN_FRAGMENT = gql3`
  fragment ContentCardBookBigScreenFragment on ContentCard {
    _id
    type
    url
    title
    media {
      icon
      thumb
      __typename
    }
    author {
      _id
      type
      profile {
        full_name
        __typename
      }
      __typename
    }
    library_settings {
      displaySocialHeaders
    }
    prompt
    loggedInUserFollows
    loggedInUserCanEdit
    __typename
  }
`;
var CONTENT_CARD_SHELF_FRAGMENT = gql3`
  fragment ContentCardShelfFragment on ContentCard {
    __typename
    _id
    url
    shortCode
    type
    title
    body
    description
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
      }
    }
    group {
      __typename
      _id
      subtype
      username
      profile {
        full_name
        full_bio
        picture
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    media {
      thumb
      icon
    }
    library_settings {
      displayReference
      displayInNewsfeed
      dashboardContentItems {
        name
        url
        type
        filter
      }
      protectionLevel
      accessRoleWhitelist
    }
    customFields {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
    cardActionsAllowed
    loggedInUserFollows
    loggedInUserCanEdit
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_LIBRARY_FRAGMENT = gql3`
  fragment ContentCardLibraryFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
    }
    loggedInUserFollows
    loggedInUserCanEdit
    library_settings {
      welcomeText
      autoFollowCards
      autoFollowUsers
      initialSubscriptions
      protectionLevel
      memberStyle
      sidebarMenu {
        name
        url
      }
      dashboardMenu {
        name
        url
      }
      dashboardContentItems {
        name
        url
        type
        filter
      }
      customRoles {
        name
        url
        color
      }
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      displayReference
      displayInNewsfeed
      recommendations
    }
    cardActionsAllowed
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT = gql3`
  fragment ContentCardChapterChildrenFragment on ContentCard {
    __typename
    _id
    chapterChildren: children(restrictType: chapter) {
      ...ContentCardChapterFragment
    }
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
`;

// src/messaging/fragments.ts
var MESSAGE_THREAD_FRAGMENT = gql4`
  fragment MessageThreadFragment on MessageThread {
    __typename
    _id
    participants {
      __typename
      user {
        __typename
        _id
        type
        profile {
          __typename
          full_name
          short_bio
          full_bio
          picture
          coverPhoto
          facebookProfile
          instagramProfile
          twitterProfile
          linkedInProfile
        }
      }
      hasUnreadMessages
    }
    messages {
      __typename
      hasMore
      cursor
      type
      messages {
        __typename
        _id
        author {
          __typename
          _id
          profile {
            __typename
            first_name
            full_name
            picture
          }
        }
        text
        reactions {
          emoji
          userIds
        }
        attachment {
          type
          _id
          vs
        }
        created
        updated
      }
    }
    displayName
    displayIcon
    created
    updated
    group {
      __typename
      _id
      totalMembers
      profile {
        full_name
        picture
        coverPhoto
        short_bio
      }
      settings {
        protectionLevel
        approvalRequired
        shortCodeApprovalRequired
      }
      products {
        _id
        name
      }
    }
    currentUserNotificationLevel
    threadType
  }
`;
var MESSAGE_FRAGMENT = gql4`
  fragment MessageFragment on Message {
    __typename
    _id
    author {
      __typename
      _id
      profile {
        __typename
        full_name
        picture
        short_bio
      }
    }
    thread {
      ...MessageThreadFragment
    }
    attachment {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
    }
    text
    reactions {
      emoji
      userIds
    }
    created
    updated
  }
  ${MESSAGE_THREAD_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var MESSAGE_FRAGMENT_OMIT_THREAD = gql4`
  fragment MessageFragmentOmitThread on Message {
    __typename
    _id
    author {
      __typename
      _id
      profile {
        __typename
        full_name
        picture
        short_bio
      }
    }
    reactions {
      emoji
      userIds
    }
    attachment {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
    }
    text
    created
    updated
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var MESSAGE_UPDATE_FRAGMENT = gql4`
  fragment MessageUpdateFragment on MessageUpdate {
    action
    message {
      ... on Message {
        ...MessageFragment
      }
      ... on MessageDelete {
        _id
        thread {
          ...MessageThreadFragment
        }
      }
    }
  }
  ${MESSAGE_FRAGMENT}
  ${MESSAGE_THREAD_FRAGMENT}
`;

// src/user/fragments.ts
import { gql as gql6 } from "@apollo/client";

// src/organization/fragments.ts
import { gql as gql5 } from "@apollo/client";
var ORGANIZATION_FRAGMENT = gql5`
    fragment OrganizationFragment on Organization {
        __typename
        _id
        name
        created
        createdBy {
            _id
            profile {
                full_name
            }
        }
        updated
    }
`;

// src/user/fragments.ts
var CUSTOM_FIELDS_FRAGMENT = gql6`
  fragment CustomFieldsFragment on User {
    __typename
    _id
    profile {
      customFields {
        parent {
          url
        }
        parentGroup {
          _id
        }
        manager
        lastDismissalDate
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
  }
`;
var USER_FRAGMENT = gql6`
  fragment UserFragment on User {
    __typename
    _id
    type
    profile {
      full_name
      short_bio
      full_bio
      picture
      coverPhoto
      facebookProfile
      instagramProfile
      twitterProfile
      linkedInProfile
      country
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        state
        country
        postal
      }
      language
      website
      email
      phone
      nickname
      followersCount
      connectionsCount
      loggedInUserConnectionStatus
      protectionLevel
      newsfeedFilter
      organization {
        ...OrganizationFragment
      }
      simple {
        name
        age
      }
      avatar {
        avatar
        skinTone
        cheekColor
        hairColor
        eyeColor
        clothing1
        clothing2
        clothing3
        outline
      }
      displaySurveyAnswers
      temp
    }
    guardianAccount {
      isVerified
      profile {
        email
        full_name
      }
      featureSets
    }
    guardianCode
    childVerificationCodes
    socialActionsAllowed
    isAnonymous
    isVerified
    isUserSuperAdmin
    canCreate
    featureSets
    ...CustomFieldsFragment
  }
  ${ORGANIZATION_FRAGMENT}
  ${CUSTOM_FIELDS_FRAGMENT}
`;
var UPDATE_FEATURE_CONSTRAINTS_FRAGMENT2 = gql6`
  fragment UpdateGroupFeatureConstraintsFragment on Group {
    __typename
    updateFeatureConstraints {
      canEditAllFields
      featuresUserCanEdit {
        type
        field
        options
        canUpdate
      }
    }
  }
`;
var GROUP_SHELF_FRAGMENT = gql6`
  fragment GroupShelfFragment on Group {
    __typename
    _id
    profile {
      full_name
      picture
      coverPhoto
    }
    shelf {
      __typename
      _id
      url
      media {
        thumb
        icon
      }
    }
  }
`;
var GROUP_PRODUCT_FRAGMENT = gql6`
  fragment GroupProductFragment on Group {
    products {
      _id
      name
      description
      coupons {
        name
        code
        percentage
        type
        expiration
      }
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
  }
`;
var GROUP_EVENT_FRAGMENT = gql6`
  fragment GroupEventFragment on Group {
    __typename
    _id
    path
    profile {
      full_name
      short_bio
      full_bio
      picture
      coverPhoto
      country
      website
      email
    }
    socialActionsAllowed
    shelf {
      __typename
      _id
      url
      media {
        thumb
        icon
      }
    }
    settings {
      __typename
      formSpec {
        url
      }
      protectionLevel
      memberStyle
      recommendations
      canMembersSuggestEdits
      shortCode
      shortCodeApprovalRequired
      groupChatroom
      adminChatroom
      approvalRequired
      registrationTitle
      registrationDescription
      registrationActionText
      enableGroupNewsfeed
      enableMembershipID
    }
    eventOverviewDetails {
      title
      description
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      hideAttendeeCount
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
      sponsorSettings {
        description
        tiers {
          name
          order
          sponsors {
            name
            link
            logo
          }
        }
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
          postal
          location {
            type
            coordinates
          }
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            city
            state
            country
            postal
            location {
              type
              coordinates
            }
          }
        }
      }
    }
    timeAndPlaceSettings {
      _id
      title
      description
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
          postal
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            title
            thumb
            icon
            altName
            altBio
            useUserBio
            color
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                protectionLevel
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
    }
    subtype
    totalMembers
    totalChildGroups
    totalChildEventGroups
    membershipBoostUri
    currentUserIsAdmin
    currentUserIsDirectAdmin
    currentUserIsMember
    currentUserIsInterested
    currentUserIsInvited
    currentUserIsNotGoing
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    currentUserLastActiveDate
    groupActionsAllowed
    doesSellMembership
    isUnpublished
    whitelabelVerbage {
      _id
      value
      plainText
    }
    parentGroup {
      __typename
      _id
      profile {
        full_name
        coverPhoto
        picture
        website
      }
    }
    paymentSettings {
      isGroupMembershipPaid
    }
    ...GroupProductFragment
  }

  ${GROUP_PRODUCT_FRAGMENT}
`;
var GROUP_FRAGMENT = gql6`
  fragment GroupFragment on Group {
    __typename
    _id
    path
    profile {
      full_name
      short_bio
      full_bio
      picture
      coverPhoto
      country
      website
      email
      customFields {
        parent {
          url
        }
        parentGroup {
          _id
        }
        manager
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    socialActionsAllowed
    shelf {
      __typename
      _id
      url
      loggedInUserCanEdit
      media {
        thumb
        icon
      }
    }
    settings {
      __typename
      subscriptions
      formSpec {
        url
        formSpecificationSettings {
          type
          form {
            required
            fieldGroups {
              groupName
              fieldIds
              index
            }
            fields {
              index
              type
              id
              options
              customText
              prompt
              placeholder
              type
              selectMultiple
              siblingId
              defaultComparator
              required
              showOnSignup
              groups {
                group {
                  _id
                }
                id
                requireApproval
              }
              allowOther
              dependsOn {
                id
                values
              }
            }
          }
        }
      }
      protectionLevel
      memberStyle
      dashboards {
        name
        url
        type
        filter
      }
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      recommendations
      canMembersSuggestEdits
      shortCode
      shortCodeApprovalRequired
      groupChatroom
      adminChatroom
      membersHub
      welcomeCard {
        enabled
        title
        message
      }
      chatSettings {
        showChatHistory
        showReadReceipts
        showOnlineUsers
        isEncrypted
      }
      adminChatSettings {
        showChatHistory
        showReadReceipts
        showOnlineUsers
        isEncrypted
      }
      chatPermissions {
        editGroupSettings
        addMembers
        pinMessages
        sendMessages
      }
      approvalRequired
      registrationTitle
      registrationDescription
      registrationActionText
      enableGroupNewsfeed
      enableMembershipID
      lastCustomSignupFieldsUpdate
      adminChatroom
      groupChatroom
      enableGroupNewsfeed
      welcomeCard {
        enabled
        title
        message
      }
    }
    eventOverviewDetails {
      title
      description
      hideAttendeeCount
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
      sponsorSettings {
        description
        tiers {
          name
          order
          sponsors {
            name
            link
            logo
          }
        }
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
    }
    timeAndPlaceSettings {
      title
      description
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            altName
            altBio
            useUserBio
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
    }
    hasUnreadDashboard
    subtype
    totalMembers
    totalChildGroups
    totalChildEventGroups
    membershipBoostUri
    currentUserIsAdmin
    currentUserIsDirectAdmin
    currentUserIsMember
    currentUserIsInterested
    currentUserIsInvited
    currentUserIsNotGoing
    currentUserLastActiveDate
    currentUserJoinDate
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    groupActionsAllowed
    doesSellMembership
    isTemplate
    isUnpublished
    allSignupFields {
      parentGroup {
        _id
      }
      manager
      signupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
    }
    whitelabelVerbage {
      _id
      value
      plainText
    }
    parentGroup {
      __typename
      _id
      profile {
        full_name
        coverPhoto
        picture
        website
        customFields {
          parent {
            url
          }
          parentGroup {
            _id
          }
          manager
          values {
            id
            type
            comparator
            values
            siblingId
          }
        }
      }
    }
    channelsBook {
      title
      url
      media {
        thumb
        icon
      }
      children {
        title
        url
        reference {
          href
        }
      }
    }
    eventBook {
      url
      title
      children {
        _id
        title
        url
        rankOrder
        reference {
          href
        }
      }
    }
    ...UpdateGroupFeatureConstraintsFragment
    ...GroupProductFragment
  }
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT2}
  ${GROUP_PRODUCT_FRAGMENT}
`;
var GROUP_MEMBERSHIP_FRAGMENT = gql6`
  fragment GroupMembershipFragment on Group {
    __typename
    _id
    totalMembers
    totalAdmins
    totalGuests
    owners(limit: $ownersLimit, cursor: $ownersCursor) {
      __typename
      hasMore
      cursor
      results {
        ...UserFragment
        doesUserOwnMembershipInGroup(groupId: $_id)
        doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
        productInstancesForGroup(groupId: $_id) {
          _id
          transferToken
          product {
            _id
            name
            description
            price
            currency
            type
          }
        }
      }
    }
    members(limit: $membersLimit, cursor: $membersCursor) {
      __typename
      hasMore
      cursor
      results {
        ...UserFragment
        doesUserOwnMembershipInGroup(groupId: $_id)
        doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
        productInstancesForGroup(groupId: $_id) {
          _id
          transferToken
          product {
            _id
            name
            description
            price
            currency
            type
          }
        }
      }
    }
    guests {
      __typename
      _id
      profile {
        email
        full_name
        temp
      }
    }
    giftedSubscriptions {
      __typename
      _id
      ...UserFragment
      profile {
        email
        full_name
        temp
      }
      doesUserOwnMembershipInGroup(groupId: $_id)
      doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
      productInstancesForGroup(groupId: $_id) {
        _id
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
        }
      }
    }
    memberRequests {
      user {
        __typename
        ...UserFragment
        roleForGroup(groupId: $_id)
      }
      role
      message
    }
    memberInvites {
      user {
        __typename
        ...UserFragment
        roleForGroup(groupId: $_id)
      }
      role
      message
    }
    emailInvites {
      userEmail
      role
      message
    }
  }
  ${USER_FRAGMENT}
`;
var GROUP_OPTIMIZER_DATA_FRAGMENT = gql6`
  fragment GroupOptimizerDataFragment on Group {
    _id
    profile {
      full_name
      picture
      short_bio
    }
    settings {
      recommendations
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
    }
  }
`;
var USER_PERSONAL_FEED_FRAGMENT = gql6`
  fragment UserPersonalFeedFragment on ContentCard {
    __typename
    ...ContentCardFragment
  }
  ${CONTENT_CARD_FRAGMENT}
`;
var GROUP_DASHBOARD_FRAGMENT = gql6`
  fragment GroupDashboardFragment on PaginatedCards {
    __typename
    hasMore
    cursor
    results {
      ...ContentCardReferenceFragment
      ...ContentCardFragment
      ...ContentCardChapterChildrenFragment
    }
    type
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var PRODUCT_FRAGMENT = gql6`
  fragment ProductFragment on Product {
    _id
    name
    price
    currency
    type
    fulfillment {
      groupId
      role
    }
    created
    updated
    createdBy {
      profile {
        full_name
        coverPhoto
      }
    }
    reference {
      ... on Group {
        _id
        profile {
          full_name
          picture
          coverPhoto
        }
        subtype
        doesSellMembership
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
      }
    }
  }
`;
var ORDER_FRAGMENT = gql6`
  fragment OrderFragment on CustomerOrder {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
    }
    products {
      ...ProductFragment
    }
    created
    updated
    status
  }
  ${PRODUCT_FRAGMENT}
`;
var TIME_AND_PLACE_SETTINGS_FRAGMENT = gql6`
  fragment TimeAndPlaceSettingsFragment on TimeAndPlaceSettings {
    _id
    title
    description
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    hideAttendeeCount
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
        postal
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
  }
`;

// src/user/mutations.ts
var LOG_USER_EVENT = gql7`
  mutation LogUserEvent(
    $viewed: [String]!
    $notifyOf: [String]!
    $welcomeViewed: Boolean
  ) {
    logUserEvent(
      input: {
        viewed: $viewed
        notifyOf: $notifyOf
        welcomeViewed: $welcomeViewed
      }
    ) {
      ...UserFragment
      profile {
        userEvent {
          viewed
          notifyOf
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
var FOLLOW_USER = gql7`
  mutation FollowUser($_id: ID!, $unFollow: Boolean) {
    followUser(_id: $_id, unFollow: $unFollow) {
      __typename
      _id
      socialActionsAllowed
    }
  }
`;
var FLAG_USER = gql7`
  mutation FlagUser($_id: ID!, $reason: String, $block: Boolean) {
    flagUser(_id: $_id, reason: $reason, block: $block) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
var UPDATE_USER_CONNECTION = gql7`
  mutation UpdateConnection($userId: ID!, $action: ConnectionAction!) {
    updateConnection(input: { userId: $userId, action: $action }) {
      __typename
      _id
      socialActionsAllowed
    }
  }
`;
var SEND_SMS_VERIFICATION_CODE = gql7`
  mutation SendSmsVerificationCode($input: SendSMSInput!) {
    sendSmsVerificationCode(input: $input) {
      success
      error
    }
  }
`;
var VERIFY_SMS_CODE = gql7`
  mutation VerifySmsCode($input: SmsVerificationInput!) {
    verifySmsCode(input: $input) {
      userId
      options {
        profile {
          phone
        }
      }
    }
  }
`;
var SEND_EMAIL_VERIFICATION_CODE = gql7`
  mutation SendEmailVerificationCode($input: SendEmailVerificationCodeInput!) {
    sendEmailVerificationCode(input: $input) {
      success
      error
    }
  }
`;
var VERIFY_EMAIL_VERIFICATION_CODE = gql7`
  mutation VerifyEmailVerificationCode(
    $input: VerifyEmailVerificationCodeInput!
  ) {
    verifyEmailVerificationCode(input: $input) {
      success
      error
      claimToken
    }
  }
`;
var ADD_PHONE_TO_USER = gql7`
  mutation AddPhoneToUser($input: SmsVerificationInput!) {
    addPhoneToUser(input: $input) {
      userId
      options {
        profile {
          phone
        }
      }
    }
  }
`;
var CREATE_USER = gql7`
  mutation CreateUser(
    $input: NewUserInput!
    $claimInput: ClaimUserInput
    $claimTicketInput: ClaimTicketInput
    $paymentMethodInput: PaymentMethodInput
    $skipEmail: Boolean
    $skipVerificationEmail: Boolean
    $emailIsVerified: Boolean
  ) {
    createUser(
      input: $input
      claimInput: $claimInput
      claimTicketInput: $claimTicketInput
      paymentMethodInput: $paymentMethodInput
      skipEmail: $skipEmail
      skipVerificationEmail: $skipVerificationEmail
      emailIsVerified: $emailIsVerified
    ) {
      _id
      email
      picture
      bio
      username
      password
      usedSMS
      phone
    }
  }
`;
var CREATE_GUEST_USER = gql7`
  mutation CreateGuestUser($input: NewUserInput!) {
    createGuestUser(input: $input) {
      _id
      email
      picture
      bio
      username
    }
  }
`;
var CREATE_GROUP = gql7`
  mutation CreateGroup($input: NewGroupInput!, $idempotencyKey: String) {
    createGroup(input: $input, idempotencyKey: $idempotencyKey) {
      ...GroupFragment
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
    }
  }
  ${GROUP_FRAGMENT}
`;
var DELETE_GROUP = gql7`
  mutation DeleteGroup(
    $_id: String!
    $cancelReason: String
    $messageToAttendees: String
    $contactUserId: String
  ) {
    deleteGroup(
      _id: $_id
      cancelReason: $cancelReason
      messageToAttendees: $messageToAttendees
      contactUserId: $contactUserId
    )
  }
`;
var UNPUBLISH_EVENT = gql7`
  mutation UnpublishEvent(
    $_id: String!
    $cancelReason: String!
    $messageToAttendees: String
    $contactUserId: String
  ) {
    unpublishEvent(
      _id: $_id
      cancelReason: $cancelReason
      messageToAttendees: $messageToAttendees
      contactUserId: $contactUserId
    ) {
      ...GroupFragment
    }
  }
  ${GROUP_FRAGMENT}
`;
var PUBLISH_EVENT = gql7`
  mutation PublishEvent($_id: String!) {
    publishEvent(_id: $_id) {
      ...GroupFragment
    }
  }
  ${GROUP_FRAGMENT}
`;
var UPDATE_USER = gql7`
  mutation UpdateUser(
    $_id: ID!
    $full_name: String
    $short_bio: String
    $full_bio: String
    $picture: String
    $country: String
    $location: LocationInput
    $website: String
    $email: String
    $nickname: String
    $birthday: String
    $coverPhoto: String
    $facebookProfile: String
    $instagramProfile: String
    $twitterProfile: String
    $linkedInProfile: String
    $customFields: [UpdateCustomFieldsInput]
    $protectionLevel: ViewPermission
    $language: String
    $organization: ID
    $newsfeedFilter: NewsfeedFilter
    $simple: SimpleProfileInput
    $avatar: AvatarInput
    $did: String
    $phone: String
  ) {
    updateUser(
      input: {
        _id: $_id
        full_name: $full_name
        short_bio: $short_bio
        full_bio: $full_bio
        picture: $picture
        country: $country
        location: $location
        website: $website
        email: $email
        nickname: $nickname
        birthday: $birthday
        coverPhoto: $coverPhoto
        facebookProfile: $facebookProfile
        instagramProfile: $instagramProfile
        twitterProfile: $twitterProfile
        linkedInProfile: $linkedInProfile
        customFields: $customFields
        protectionLevel: $protectionLevel
        language: $language
        organization: $organization
        newsfeedFilter: $newsfeedFilter
        simple: $simple
        avatar: $avatar
        did: $did
        phone: $phone
      }
    ) {
      ...UserFragment
      profile {
        birthday
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
        coverPhoto
        newsfeedFilter
        did
      }
      ...CustomFieldsFragment
      roles {
        roles
      }
    }
  }
  ${USER_FRAGMENT}
  ${CUSTOM_FIELDS_FRAGMENT}
`;
var UPDATE_USER_ROLE_FOR_URL = gql7`
  mutation UpdateUserRoleForUrl($url: String!, $userId: ID!, $role: Role!) {
    updateUserRoleForUrl(url: $url, userId: $userId, role: $role) {
      ...UserFragment
      roles {
        customRolesOf(url: $url) {
          role
          url
        }
        roleOf(url: $url) {
          role
          url
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
var UPDATE_CUSTOM_USER_ROLE_FOR_URL = gql7`
  mutation UpdateCustomUserRoleForUrl(
    $url: String!
    $userId: ID!
    $role: String!
    $remove: Boolean
  ) {
    updateCustomUserRoleForUrl(
      url: $url
      userId: $userId
      role: $role
      remove: $remove
    ) {
      ...UserFragment
      roles {
        customRolesOf(url: $url) {
          role
          url
        }
        roleOf(url: $url) {
          role
          url
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
var ADD_USER_TO_GROUP = gql7`
  mutation AddUserToGroup(
    $groupId: String!
    $subgroupId: String
    $cardId: String
    $userId: String!
    $remove: Boolean
    $admin: Boolean
    $setAsActiveGroup: Boolean
    $host: String
  ) {
    addUserToGroup(
      groupId: $groupId
      subgroupId: $subgroupId
      cardId: $cardId
      userId: $userId
      remove: $remove
      admin: $admin
      setAsActiveGroup: $setAsActiveGroup
      host: $host
    ) {
      user {
        __typename
        ...UserFragment
        roleForGroup(groupId: $groupId)
      }
      group {
        __typename
        ...GroupFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var RSVP_AND_REGISTER_TO_EVENT = gql7`
  mutation rsvpAndRegisterToEvent(
    $groupId: String!
    $attendeeRegistrationInput: AttendeeRegistrationInput!
  ) {
    rsvpAndRegisterToEvent(
      groupId: $groupId
      attendeeRegistrationInput: $attendeeRegistrationInput
    ) {
      user {
        __typename
        ...UserFragment
      }
      group {
        __typename
        ...GroupFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var EXPRESS_INTEREST_IN_GROUP = gql7`
  mutation ExpressInterestInGroup($groupId: String!, $userId: String!) {
    expressInterestInGroup(groupId: $groupId, userId: $userId) {
      user {
        __typename
        ...UserFragment
      }
      group {
        __typename
        ...GroupFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var RESPOND_NOT_GOING_TO_GROUP_EVENT = gql7`
  mutation RespondNotGoingToGroupEvent($groupId: String!, $userId: String!) {
    respondNotGoingToGroupEvent(groupId: $groupId, userId: $userId) {
      user {
        __typename
        ...UserFragment
      }
      group {
        __typename
        ...GroupFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var ADD_GROUP_TO_USER = gql7`
  mutation AddGroupToUser(
    $shortCode: String!
    $customFields: [UpdateCustomFieldsInput]
  ) {
    addGroupToUser(shortCode: $shortCode, customFields: $customFields) {
      ...UserFragment
      membership {
        active {
          __typename
          title
          url
        }
        activeLibrary {
          __typename
          title
          url
        }
        all {
          __typename
          title
          url
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
var UPDATE_USER_GROUP_SURVEY_DISMISSAL_TIME = gql7`
  mutation UpdateUserGroupSurveyDismissalTime($groupId: String!) {
    updateUserGroupSurveyDismissalTime(groupId: $groupId) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
var CANCEL_EMAIL_INVITE_TO_GROUP = gql7`
  mutation CancelEmailInviteToGroup($groupId: String!, $userEmail: String!) {
    cancelEmailInviteToGroup(groupId: $groupId, userEmail: $userEmail)
  }
`;
var SWITCH_ACTIVE_GROUP = gql7`
  mutation SwitchActiveGroup($groupId: String) {
    switchActiveGroup(groupId: $groupId) {
      __typename
      _id
    }
  }
`;
var PLEDGE_TO_TASK = gql7`
  mutation PledgeToTasks($taskUrls: [String]!) {
    pledgeToTasks(taskUrls: $taskUrls) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
var UPDATE_GROUP = gql7`
  mutation UpdateGroup($input: UpdateGroupInput!, $idempotencyKey: String) {
    updateGroup(input: $input, idempotencyKey: $idempotencyKey) {
      ...GroupFragment
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
    }
  }
  ${GROUP_FRAGMENT}
`;
var SET_IS_MEMBERSHIP_PAID = gql7`
  mutation SetIsMembershipPaid(
    $groupId: ID!
    $paymentSettings: PaymentSettingsInput
  ) {
    setIsMembershipPaid(groupId: $groupId, paymentSettings: $paymentSettings) {
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      doesSellMembership
    }
  }
`;
var SUBSCRIBE_GROUP_TO_CONTENT = gql7`
  mutation SubscribeGroupToContent(
    $url: String
    $urls: [String]
    $groupId: String
  ) {
    subscribeGroupToContent(url: $url, urls: $urls, groupId: $groupId) {
      __typename
      subscribedTo {
        ...ContentCardFragment
      }
      group {
        ...GroupFragment
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var SET_GROUP_DASHBOARD = gql7`
  mutation SetGroupDashboard($url: String, $role: String, $groupId: String) {
    setGroupDashboard(url: $url, role: $role, groupId: $groupId) {
      ...GroupFragment
    }
  }
  ${GROUP_FRAGMENT}
`;
var UPDATE_HAS_UNREAD_DASHBOARD = gql7`
  mutation UpdateHasUnreadDashboard(
    $groupId: String!
    $hasUnreadDashboard: Boolean!
    $userId: ID
  ) {
    updateHasUnreadDashboard(
      groupId: $groupId
      hasUnreadDashboard: $hasUnreadDashboard
      userId: $userId
    ) {
      ...GroupFragment
    }
  }
  ${GROUP_FRAGMENT}
`;
var BULK_INVITE_USERS_BY_EMAIL = gql7`
  mutation BulkInviteUsersByEmail($input: BulkUserInviteInput!) {
    bulkInviteUsersByEmail(input: $input) {
      existingInvitees {
        _id
        ...UserFragment
      }
      usersToInviteByEmail {
        email
      }
    }
  }
`;
var BULK_GENERATE_USERS_FROM_CSV = gql7`
  mutation BulkGenerateUsersFromCsv($csvFileUrl: String!) {
    bulkGenerateUsersFromCsv(csvFileUrl: $csvFileUrl)
  }
`;
var SEND_PASSWORD_RESET_FOR_USER = gql7`
  mutation SendPasswordResetForEmail($email: String!, $host: String) {
    sendPasswordResetForEmail(email: $email, host: $host)
  }
`;
var SEND_CLAIM_ACCOUNT_EMAIL_FOR_USER = gql7`
  mutation SendClaimAccountEmail($email: String!, $host: String) {
    sendClaimAccountEmail(email: $email, host: $host)
  }
`;
var REACTIVATE_ACCOUNT = gql7`
  mutation ReactivateAccount($userId: ID) {
    reactivateAccount(userId: $userId)
  }
`;
var DEACTIVATE_ACCOUNT = gql7`
  mutation DeactivateAccount($userId: ID) {
    deactivateAccount(userId: $userId)
  }
`;
var DELETE_ACCOUNT = gql7`
  mutation DeleteUserAccount($userId: ID) {
    deleteUserAccount(userId: $userId)
  }
`;
var RESET_PASSWORD_WITH_TOKEN = gql7`
  mutation ResetPasswordWithToken(
    $token: String!
    $password: String!
    $passwordConfirm: String!
  ) {
    resetPasswordWithToken(
      token: $token
      password: $password
      passwordConfirm: $passwordConfirm
    )
  }
`;
var SEND_VERIFY_EMAIL = gql7`
  mutation SendVerifyEmail($email: String!, $host: String) {
    sendVerifyEmail(email: $email, host: $host)
  }
`;
var VERIFY_EMAIL = gql7`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;
var SEND_CREATE_ACCOUNT_REMINDER_EMAILS = gql7`
  mutation SendCreateAccountReminderEmails(
    $emails: [String!]!
    $groupId: ID!
    $host: String
  ) {
    sendCreateAccountReminderEmails(
      emails: $emails
      groupId: $groupId
      host: $host
    )
  }
`;
var UPDATE_USER_SIDE_MENU = gql7`
  mutation UpdateUserSideMenu($input: SideMenuInput!) {
    updateUserSideMenu(input: $input) {
      _id
      sideMenu {
        quickLinks {
          tags
          aliases {
            ... on UserAliasHandle {
              url
              title
              type
              user {
                _id
                profile {
                  picture
                  full_name
                  short_bio
                }
              }
            }
            ... on GroupAliasHandle {
              url
              title
              type
              group {
                _id
                profile {
                  full_name
                  picture
                  short_bio
                }
              }
            }
            ... on MessageThreadAliasHandle {
              url
              title
              color
              thumb
              icon
              type
              thread {
                ...MessageThreadFragment
              }
            }
            ... on AliasItem {
              url
              title
              type
              item {
                _id
                title
                description
                reference {
                  href
                }
                media {
                  thumb
                }
              }
            }
          }
        }
      }
    }
  }

  ${MESSAGE_THREAD_FRAGMENT}
`;
var CREATE_STRIPE_CONNECTED_ACCOUNT = gql7`
  mutation CreateStripeConnectedAccount(
    $input: GroupConnectedAccountInput!
    $host: String
    $reset: Boolean
  ) {
    onboardGroupConnectedAccount(input: $input, host: $host, reset: $reset) {
      object
      created
      expires_at
      url
    }
  }
`;
var GENERATE_STRIPE_CONNECTED_ACCOUNT_LOGIN_LINK = gql7`
  mutation GenerateConnectedAccountLoginLink($groupId: String!) {
    generateConnectedAccountLoginLink(groupId: $groupId) {
      object
      created
      url
    }
  }
`;
var ADD_CARD_TO_CUSTOMER_ACCOUNT = gql7`
  mutation addCardToCustomerAccount($token: String!, $setAsDefault: Boolean) {
    createPaymentCardForLoggedInUser(
      token: $token
      setAsDefault: $setAsDefault
    ) {
      __typename
      _id
      type
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
        }
      }
    }
  }
`;
var DELETE_CUSTOMER_CARD_SOURCE = gql7`
  mutation DeleteCustomerCardSource($cardId: String!) {
    deleteCustomerCardSource(cardId: $cardId) {
      __typename
      id
      object
      deleted
    }
  }
`;
var SET_CUSTOMER_CARD_SOURCE_AS_DEFAULT = gql7`
  mutation SetCustomerCardSourceAsDefault($cardId: String!) {
    setCustomerCardSourceAsDefault(cardId: $cardId) {
      __typename
      _id
      type
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
          default_source
          invoice_settings {
            default_payment_method
          }
        }
      }
    }
  }
`;
var CREATE_SETUP_INTENT_FOR_LOGGEDIN_USER = gql7`
  mutation CreateSetupIntentForLoggedInUser {
    createSetupIntentForLoggedInUser {
      id
      object
      client_secret
      customer
      description
      next_action
      payment_method
      status
      livemode
      usage
    }
  }
`;
var CREATE_STRIPE_CUSTOMER_ACCOUNT = gql7`
  mutation CreateStripeCustomerAccount($input: CustomerAccountInput!) {
    createCustomerAccountForLoggedInUser(input: $input) {
      __typename
      _id
      type
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
        }
      }
    }
  }
`;
var UPDATE_STRIPE_CUSTOMER_ACCOUNT = gql7`
  mutation UpdateStripeCustomerAccount($input: CustomerAccountInput!) {
    updateCustomerAccountForLoggedInUser(input: $input) {
      __typename
      _id
      type
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
        }
      }
    }
  }
`;
var CREATE_PRODUCT = gql7`
  mutation CreateProduct($input: ProductInput!) {
    createProduct(input: $input) {
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
    }
  }
`;
var UPDATE_PRODUCTS = gql7`
  mutation updateProducts(
    $inputs: [UpdateProductInput]!
    $upsert: Boolean
    $groupId: ID
  ) {
    updateProducts(inputs: $inputs, upsert: $upsert, groupId: $groupId) {
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
    }
  }
`;
var UPDATE_SUBSCRIPTION_PRODUCT = gql7`
  mutation UpdateSubscriptionProduct($input: UpdateProductInput) {
    updateSubscriptionProduct(input: $input) {
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
    }
  }
`;
var DELETE_PRODUCT_BY_ID = gql7`
  mutation deleteProduct($productId: ID!, $permanent: Boolean) {
    deleteProduct(productId: $productId, permanent: $permanent)
  }
`;
var CREATE_CUSTOMER_ORDER = gql7`
  mutation CreateCustomerOrder(
    $input: CustomerOrderInput!
    $allowDuplicatePurchases: Boolean
    $subgroupId: String
    $isPurchaseForOther: Boolean
    $paymentType: String
    $orderLocation: String
    $processedByUserId: String
    $ticketsForExistingUserId: String
  ) {
    createOrder(
      input: $input
      allowDuplicatePurchases: $allowDuplicatePurchases
      subgroupId: $subgroupId
      isPurchaseForOther: $isPurchaseForOther
      paymentType: $paymentType
      orderLocation: $orderLocation
      processedByUserId: $processedByUserId
      ticketsForExistingUserId: $ticketsForExistingUserId
    ) {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
        }
        quantity
        couponCode
      }
      products {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      productInstances {
        _id
        isCheckedIn
        transferToken
        product {
          _id
          name
          description
          currency
          price
        }
        owner {
          _id
          profile {
            full_name
            picture
            email
            phone
          }
        }
        purchaser {
          _id
          profile {
            full_name
            picture
            email
            phone
          }
        }
      }
      user {
        _id
        profile {
          full_name
          picture
          email
          phone
        }
      }
      processedByUser {
        _id
        profile {
          full_name
        }
      }
      paymentType
      orderLocation
      created
      updated
      status
    }
  }
`;
var FULFILL_CASH_ORDER = gql7`
  mutation FulfillCashOrder($orderId: String!) {
    fulfillCashOrder(orderId: $orderId) {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
      }
      purchaseRequests {
        product {
          _id
          name
          description
          currency
          price
          coupons {
            name
            code
            percentage
            type
            expiration
          }
        }
        quantity
        couponCode
      }
      products {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      productInstances {
        _id
        isCheckedIn
        transferToken
        product {
          _id
          name
          description
          currency
          price
        }
        owner {
          _id
          profile {
            full_name
            picture
            email
            phone
          }
        }
        purchaser {
          _id
          profile {
            full_name
            picture
            email
            phone
          }
        }
        owner {
          _id
          profile {
            full_name
            picture
            email
            phone
          }
        }
        purchaser {
          _id
          profile {
            full_name
            picture
            email
            phone
          }
        }
      }
      user {
        _id
        profile {
          full_name
          picture
          email
          phone
        }
      }
      user {
        _id
        profile {
          full_name
          picture
          email
          phone
        }
      }
      processedByUser {
        _id
        profile {
          full_name
        }
      }
      paymentType
      orderLocation
      created
      updated
      status
    }
  }
`;
var CREATE_CUSTOMER_REFUND = gql7`
  mutation createOrderRefund($paymentIntentId: String!) {
    createOrderRefund(paymentIntentId: $paymentIntentId) {
      id
      object
      amount
      charge
      created
      currency
      payment_intent
      reason
      status
    }
  }
`;
var REFUND_AND_CANCEL_USER_GROUP_SUBSCRIPTION = gql7`
  mutation RefundAndCancelGroupSubscription(
    $userId: String!
    $groupId: String!
  ) {
    refundAndCancelGroupSubscription(userId: $userId, groupId: $groupId) {
      id
      object
      amount
      charge
      created
      currency
      payment_intent
      reason
      status
    }
  }
`;
var DELETE_SUBSCRIPTION_PRODUCT_AND_CANCEL_SUBSCRIPTIONS_IN_BULK = gql7`
  mutation DeleteSubscriptionProductAndCancelSubscriptionsInBulk(
    $groupId: String!
    $productId: String!
  ) {
    deleteSubscriptionProductAndCancelSubscriptionsInBulk(
      groupId: $groupId
      productId: $productId
    ) {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserIsDirectAdmin
            profile {
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserIsDirectAdmin
              profile {
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            created
            currency
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          created
          currency
        }
      }
      productInstances {
        _id
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              profile {
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            full_name
            picture
          }
        }
        purchaser {
          _id
          profile {
            full_name
            picture
          }
        }
      }
      user {
        _id
        profile {
          full_name
        }
      }
      created
      updated
      status
    }
  }
`;
var RETRY_INVOICE = gql7`
  mutation RetryInvoice($paymentMethodId: String!, $invoiceId: String!) {
    retryInvoice(paymentMethodId: $paymentMethodId, invoiceId: $invoiceId) {
      id
      auto_advance
      collection_method
      currency
      description
      payment_intent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
      }
      period_start
      period_end
      status
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      total
    }
  }
`;
var CANCEL_SUBSCRIPTION = gql7`
  mutation CancelSubscriptionForGroupAtPeriodEnd(
    $subscriptionId: String!
    $cancelAtPeriodEnd: Boolean!
  ) {
    cancelSubscriptionForGroupAtPeriodEnd(
      subscriptionId: $subscriptionId
      cancelAtPeriodEnd: $cancelAtPeriodEnd
    ) {
      id
      cancel_at_period_end
      current_period_end
      current_period_start
      status
      created
      days_until_due
      livemode
      next_pending_invoice_item_invoice
    }
  }
`;
var CLAIM_TICKET = gql7`
  mutation ClaimTicket($productInstanceId: ID!, $token: String!) {
    claimProductInstance(productInstanceId: $productInstanceId, token: $token) {
      _id
      transferToken
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          full_name
          picture
        }
      }
    }
  }
`;
var GENERATE_TICKET_CLAIM_LINKS = gql7`
  mutation GenerateTicketClaimLink(
    $input: [TicketClaimLinkInput!]!
    $host: String
  ) {
    generateTicketClaimLink(input: $input, host: $host) {
      status
      value
      reason
    }
  }
`;
var GENERATE_TICKET_FOR_MEMBER_BY_ADMIN = gql7`
  mutation GenerateTicketForMemberByAdmin(
    $input: GenerateTicketForMemberByAdminInput
    $host: String
  ) {
    generateTicketForMemberByAdmin(input: $input, host: $host) {
      _id
      transferToken
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          full_name
          picture
        }
      }
    }
  }
`;
var CANCEL_TICKET_TRANSFER = gql7`
  mutation CancelTicketTransfer($productInstanceId: String!) {
    cancelTicketTransfer(productInstanceId: $productInstanceId) {
      _id
      transferToken
      transferRecipients {
        user {
          _id
          profile {
            full_name
            picture
          }
        }
        email
      }
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          full_name
          picture
        }
      }
    }
  }
`;
var RETRY_CREATING_PRE_AUTH_PAYMENT_FOR_SUBSCRIPTION_ORDER = gql7`
  mutation RetryCreatingPreAuthSubscriptionOrder(
    $userID: String!
    $groupID: String!
    $paymentMethodToken: String!
  ) {
    retryCreatingPreAuthSubscriptionOrder(
      userID: $userID
      groupID: $groupID
      paymentMethodToken: $paymentMethodToken
    )
  }
`;
var CREATE_PRE_AUTH_PAYMENT_FOR_SUBSCRIPTION_ORDER = gql7`
  mutation CreatePreAuthSubscriptionOrder($orderId: String!) {
    createPreAuthSubscriptionOrder(orderId: $orderId) {
      user {
        __typename
        ...UserFragment
      }
      group {
        __typename
        ...GroupFragment
      }
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var CONFIRM_PRE_AUTH_PAYMENT_FOR_SUBSCRIPTION_ORDER = gql7`
  mutation ConfirmPreAuthSubscriptionOrder(
    $orderId: String!
    $userId: String!
    $groupId: String!
  ) {
    confirmPreAuthSubscriptionOrder(
      orderId: $orderId
      userId: $userId
      groupId: $groupId
    ) {
      user {
        __typename
        ...UserFragment
      }
      group {
        __typename
        ...GroupFragment
      }
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var CANCEL_PRE_AUTH_PAYMENT_FOR_SUBSCRIPTION_ORDER = gql7`
  mutation CancelPreAuthSubscriptionOrder(
    $orderId: String
    $userId: String
    $groupId: String
  ) {
    cancelPreAuthSubscriptionOrder(
      orderId: $orderId
      userId: $userId
      groupId: $groupId
    ) {
      user {
        __typename
        ...UserFragment
      }
      group {
        __typename
        ...GroupFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var CREATE_GUARDIAN_ACCOUNT = gql7`
  mutation CreateGuardianAccount($input: NewGuardianUserInput!) {
    createGuardianUser(input: $input) {
      token
    }
  }
`;
var CREATE_CHILD_ACCOUNT = gql7`
  mutation CreateChildUser(
    $input: NewEphemeralUserInput
    $childVerificationCode: String
  ) {
    createChildUser(
      input: $input
      childVerificationCode: $childVerificationCode
    ) {
      token
    }
  }
`;
var CREATE_EPHEMERAL_ACCOUNT = gql7`
  mutation CreateEphemeralUser($input: NewEphemeralUserInput) {
    createEphemeralUser(input: $input) {
      token
    }
  }
`;
var LOGIN_TO_CHILD_ACCOUNT = gql7`
  mutation LoginToChildAccount($input: LoginToChildAccountInput!) {
    loginToChildAccount(input: $input) {
      token
    }
  }
`;
var LOGIN_TO_CHILD_ACCOUNT_WITH_ID = gql7`
  mutation loginToChildAccountWithChildId($input: LoginToChildAccountInput!) {
    loginToChildAccountWithChildId(input: $input) {
      token
    }
  }
`;
var UPDATE_CHILD_PERMISSIONS = gql7`
  mutation UpdateChildPermissions($input: ChildPermissionsInput!) {
    updateChildPermissions(input: $input) {
      ...UserFragment
      featureSets
    }
  }
  ${USER_FRAGMENT}
`;
var UPDATE_PERMISSIONS = gql7`
  mutation UpdatePermissions($input: PermissionsInput!) {
    updatePermissions(input: $input) {
      ...UserFragment
      featureSets
    }
  }
  ${USER_FRAGMENT}
`;
var UPDATE_CHILD_VERIFICATION_CODES = gql7`
  mutation UpdateUserChildVerificationCodes(
    $childVerificationCodes: [String!]!
  ) {
    updateUserChildVerificationCodes(
      childVerificationCodes: $childVerificationCodes
    ) {
      ...UserFragment
      childVerificationCodes
    }
  }
  ${USER_FRAGMENT}
`;
var RETAIN_EPHEMERAL_USER_AS_CHILD_ACCOUNT = gql7`
  mutation RetainEphemeralUserAsChildAccount($code: String!) {
    retainEphemeralUserAsChildAccount(code: $code) {
      _id
    }
  }
`;
var UPDATE_PASSWORD = gql7`
  mutation UpdatePassword(
    $oldPassword: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    updatePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
    )
  }
  ${USER_FRAGMENT}
`;
var BOOKMARK_EVENT_GROUP = gql7`
  mutation BookmarkEventGroup($groupId: ID!, $remove: Boolean) {
    bookmarkEventGroup(groupId: $groupId, remove: $remove) {
      _id
      isBookmarked
    }
  }
`;
var CLAIM_EPHEMERAL_ACCOUNT = gql7`
  mutation ClaimEphemeralAccount($email: String!, $password: String!) {
    claimEphemeralAccount(email: $email, password: $password)
  }
`;
var UPDATE_LAUNCH_DARKLY_FLAG = gql7`
  mutation UpdateLaunchDarklyFlag($featureFlagKey: String!) {
    updateLaunchDarklyFlag(featureFlagKey: $featureFlagKey)
  }
`;
var UPDATE_DISPLAY_SURVEY_ANSWERS = gql7`
  mutation UpdateDisplaySurveyAnswers($updatedValue: Boolean!) {
    updateDisplaySurveyAnswers(updatedValue: $updatedValue) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
var JOIN_OR_REQUEST_TO_JOIN_MULTIPLE_GROUPS = gql7`
  mutation JoinOrRequestToJoinMultipleGroupsByID(
    $groupIDs: [String!]!
    $membershipGroupId: String
  ) {
    joinOrRequestToJoinMultipleGroupsByID(
      groupIDs: $groupIDs
      membershipGroupId: $membershipGroupId
    ) {
      group {
        __typename
        ...GroupFragment
      }
      status
    }
  }
  ${GROUP_FRAGMENT}
`;
var SET_TICKET_IS_CHECKED_IN = gql7`
  mutation SetTicketIsCheckedIn(
    $productInstanceId: String!
    $isCheckedIn: Boolean!
  ) {
    setTicketIsCheckedIn(
      productInstanceId: $productInstanceId
      isCheckedIn: $isCheckedIn
    ) {
      _id
      transferToken
      isCheckedIn
      transferRecipients {
        user {
          _id
          profile {
            full_name
            picture
          }
        }
        email
      }
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          full_name
          picture
        }
      }
    }
  }
`;
var SET_TICKET_IS_CHECKED_IN_VIA_SCANNER = gql7`
  mutation SetTicketIsCheckedInViaScanner(
    $productInstanceId: String!
    $groupId: String!
  ) {
    setTicketIsCheckedInViaScanner(
      productInstanceId: $productInstanceId
      groupId: $groupId
    ) {
      productInstance {
        _id
        transferToken
        isCheckedIn
        checkInDate
        transferRecipients {
          user {
            _id
            profile {
              full_name
              picture
            }
          }
          email
        }
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              profile {
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            full_name
            picture
            email
            phone
          }
        }
        purchaser {
          _id
          profile {
            full_name
            picture
          }
        }
      }
      ticketCheckInStatus
    }
  }
`;
var BACKFILL_MY_GETSTREAM_GROUPS = gql7`
  mutation BackfillMyGetStreamGroups {
    backfillMyGetStreamGroups
  }
`;
var SEND_SUBSCRIPTION_CONFIRMATION_EMAIL = gql7`
  mutation SendSubscriptionOrderConfirmationEmail($orderId: String!) {
    sendSubscriptionOrderConfirmationEmail(orderId: $orderId)
  }
`;
export {
  ADD_CARD_TO_CUSTOMER_ACCOUNT,
  ADD_GROUP_TO_USER,
  ADD_PHONE_TO_USER,
  ADD_USER_TO_GROUP,
  BACKFILL_MY_GETSTREAM_GROUPS,
  BOOKMARK_EVENT_GROUP,
  BULK_GENERATE_USERS_FROM_CSV,
  BULK_INVITE_USERS_BY_EMAIL,
  CANCEL_EMAIL_INVITE_TO_GROUP,
  CANCEL_PRE_AUTH_PAYMENT_FOR_SUBSCRIPTION_ORDER,
  CANCEL_SUBSCRIPTION,
  CANCEL_TICKET_TRANSFER,
  CLAIM_EPHEMERAL_ACCOUNT,
  CLAIM_TICKET,
  CONFIRM_PRE_AUTH_PAYMENT_FOR_SUBSCRIPTION_ORDER,
  CREATE_CHILD_ACCOUNT,
  CREATE_CUSTOMER_ORDER,
  CREATE_CUSTOMER_REFUND,
  CREATE_EPHEMERAL_ACCOUNT,
  CREATE_GROUP,
  CREATE_GUARDIAN_ACCOUNT,
  CREATE_GUEST_USER,
  CREATE_PRE_AUTH_PAYMENT_FOR_SUBSCRIPTION_ORDER,
  CREATE_PRODUCT,
  CREATE_SETUP_INTENT_FOR_LOGGEDIN_USER,
  CREATE_STRIPE_CONNECTED_ACCOUNT,
  CREATE_STRIPE_CUSTOMER_ACCOUNT,
  CREATE_USER,
  DEACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  DELETE_CUSTOMER_CARD_SOURCE,
  DELETE_GROUP,
  DELETE_PRODUCT_BY_ID,
  DELETE_SUBSCRIPTION_PRODUCT_AND_CANCEL_SUBSCRIPTIONS_IN_BULK,
  EXPRESS_INTEREST_IN_GROUP,
  FLAG_USER,
  FOLLOW_USER,
  FULFILL_CASH_ORDER,
  GENERATE_STRIPE_CONNECTED_ACCOUNT_LOGIN_LINK,
  GENERATE_TICKET_CLAIM_LINKS,
  GENERATE_TICKET_FOR_MEMBER_BY_ADMIN,
  JOIN_OR_REQUEST_TO_JOIN_MULTIPLE_GROUPS,
  LOGIN_TO_CHILD_ACCOUNT,
  LOGIN_TO_CHILD_ACCOUNT_WITH_ID,
  LOG_USER_EVENT,
  PLEDGE_TO_TASK,
  PUBLISH_EVENT,
  REACTIVATE_ACCOUNT,
  REFUND_AND_CANCEL_USER_GROUP_SUBSCRIPTION,
  RESET_PASSWORD_WITH_TOKEN,
  RESPOND_NOT_GOING_TO_GROUP_EVENT,
  RETAIN_EPHEMERAL_USER_AS_CHILD_ACCOUNT,
  RETRY_CREATING_PRE_AUTH_PAYMENT_FOR_SUBSCRIPTION_ORDER,
  RETRY_INVOICE,
  RSVP_AND_REGISTER_TO_EVENT,
  SEND_CLAIM_ACCOUNT_EMAIL_FOR_USER,
  SEND_CREATE_ACCOUNT_REMINDER_EMAILS,
  SEND_EMAIL_VERIFICATION_CODE,
  SEND_PASSWORD_RESET_FOR_USER,
  SEND_SMS_VERIFICATION_CODE,
  SEND_SUBSCRIPTION_CONFIRMATION_EMAIL,
  SEND_VERIFY_EMAIL,
  SET_CUSTOMER_CARD_SOURCE_AS_DEFAULT,
  SET_GROUP_DASHBOARD,
  SET_IS_MEMBERSHIP_PAID,
  SET_TICKET_IS_CHECKED_IN,
  SET_TICKET_IS_CHECKED_IN_VIA_SCANNER,
  SUBSCRIBE_GROUP_TO_CONTENT,
  SWITCH_ACTIVE_GROUP,
  UNPUBLISH_EVENT,
  UPDATE_CHILD_PERMISSIONS,
  UPDATE_CHILD_VERIFICATION_CODES,
  UPDATE_CUSTOM_USER_ROLE_FOR_URL,
  UPDATE_DISPLAY_SURVEY_ANSWERS,
  UPDATE_GROUP,
  UPDATE_HAS_UNREAD_DASHBOARD,
  UPDATE_LAUNCH_DARKLY_FLAG,
  UPDATE_PASSWORD,
  UPDATE_PERMISSIONS,
  UPDATE_PRODUCTS,
  UPDATE_STRIPE_CUSTOMER_ACCOUNT,
  UPDATE_SUBSCRIPTION_PRODUCT,
  UPDATE_USER,
  UPDATE_USER_CONNECTION,
  UPDATE_USER_GROUP_SURVEY_DISMISSAL_TIME,
  UPDATE_USER_ROLE_FOR_URL,
  UPDATE_USER_SIDE_MENU,
  VERIFY_EMAIL,
  VERIFY_EMAIL_VERIFICATION_CODE,
  VERIFY_SMS_CODE
};
