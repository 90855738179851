import React from 'react';
import {Link} from 'react-router-dom';

const GroupSummaryArea = ({ text, link }) => {
    
    return (
        <div className="event-card-details-date-wrapper">
            <div className="group-header-group-desc">
                <span>{text}</span>
                {link && (
                    <Link
                        to={link.to}
                        className="group-header-link white group-header-link-members uppercase"
                    >
                        {link.text}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default GroupSummaryArea;
