import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import ToastContext from '@components/generic/context/ToastContext';
import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';
import useMetric from '@web/utilities/hooks/metrics/useMetric';
import shoppingCartStore from '@web/ui/stores/ShoppingCart';
import signUpStore from '@stores/SignUp';

import AccountGate from '@pages/lego/AccountSwitcher/AccountGate';
import Avatar from '@components/profile/Lego/Avatar';
import EditSimpleProfile from '@components/profile/Lego/EditSimpleProfile';
import CustomLegoToastFooter from '@components/toast/Lego/CustomLegoToastFooter';
import LegoGuardianWall from '@components/verify/Lego/LegoGuardianWall';

import AvatarHoverShadow from '@assets/avatar-shadow.png';
import UserIcon from '@assets/lego-user-icon-black.svg';
import EditPencil from '@dsc/svgs/lego/EditPencil';
import Checkmark from '@assets/lego-checkmark-black.svg';
import SSLIcon from '@assets/lego-ssl-icon.png';
import LogoutIcon from '@assets/lego-logout-icon-white.png';

import { getUserAvatar } from '@helpers/user/avatar.helpers';
import { getAvatar } from '@components/profile/Lego/avatarHelpers';

import { AvatarType, AvatarAllowedColorsType } from '@components/profile/Lego/Avatars';
import { ModalTypes } from '@core/types/Modals';

import { GuardianAdvancedSettingsEnum } from '@pages/lego/AccountSwitcher/AccountManagementHelpers';
import { isEphemeral, isChild } from '@web/utilities/helpers/user/child.helpers';
import { unsetAuthToken } from '@web/utilities/helpers/auth';
import pushUtilities from '@core/utilities/pushUtilities';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

type AvatarContainerProps = {
    avatar: AvatarType;
    skinTone?: AvatarAllowedColorsType['skinTone'];
};

const AvatarContainer: React.FC<AvatarContainerProps> = ({ avatar, skinTone }) => {
    return (
        <div className="simple-profile-avatar-container">
            <Avatar className="simple-profile-avatar" avatar={avatar} colors={{ skinTone }} />
            <img
                src={AvatarHoverShadow}
                alt="hover shadow"
                className="simple-profile-avatar-shadow"
            />
        </div>
    );
};

const SimpleProfile: React.FC<{ openInEditMode?: boolean; randomize?: boolean }> = ({
    openInEditMode,
    randomize = false,
}) => {
    const history = useHistory();
    const client = useApolloClient();

    const log = useMetric();

    const { logout_redirect } = getWhitelabelPublicSettings();

    const { showCustomToast, hideToast } = useContext(ToastContext);
    const { currentUser, setCurrentUser } = useCurrentUser();

    const { newModal, closeModal, closeAllModals } = useModal({
        mobile: ModalTypes.FullScreen,
    });

    const [inEditMode, setInEditMode] = useState<boolean>(openInEditMode ?? false);

    const { avatar, skinTone } = getUserAvatar(currentUser?.profile?.avatar);

    const handleAccountSwitchRedirect = () => {
        if (
            !isChild(currentUser) ||
            currentUser?.guardianAccount?.featureSets?.includes(
                GuardianAdvancedSettingsEnum.AllowChildrenAccountSwitching
            )
        ) {
            history.push('/account/switch');
            closeModal();
        } else {
            newModal(
                <AccountGate
                    successCallback={() => {
                        closeAllModals();
                        history.push('/account/switch');
                    }}
                />,
                {
                    className: 'no-top-padding no-bottom-padding no-notch',
                    hideButton: true,
                    customCloseButton: false,
                    widen: true,
                    addShadow: true,
                }
            );
        }
    };

    const handleEphemeralVerificationRedirect = () => {
        hideToast();
        history.push('/ssl/signup?isEphemeral=true');
        closeModal();
    };

    const handleShowGuardianWall = (isVerifying: boolean) => {
        hideToast();
        newModal(
            <LegoGuardianWall
                isEphemeral
                isVerifying={isVerifying}
                handleRedirect={() => {
                    handleEphemeralVerificationRedirect();
                    closeAllModals();
                }}
            />,
            {
                className: 'no-top-padding no-bottom-padding no-notch full-height full-screen',
                hideButton: true,
                customCloseButton: false,
            }
        );
    };

    const logout = () => {
        try {
            unsetAuthToken();
            client.clearStore();
            pushUtilities.revokeCurrentToken();
            setCurrentUser(null);
            shoppingCartStore?.set?.shoppingCarts([]);
            signUpStore?.set?.resetSignupStore();
            closeAllModals();
            hideToast();
            log({ type: 'Logout' });
            history.push(logout_redirect ?? '/landing/ssl');
        } catch (e) {
            window.location.href = logout_redirect ?? '/landing/ssl';
        }
    };

    const handleShowToast = () => {
        showCustomToast(
            'Are you sure you want to logout? Your PROGRESS WILL BE LOST unless you create an account.',
            null,
            5000,
            'responsive',
            SSLIcon,
            <CustomLegoToastFooter showVerify showLogout handleLogout={logout} shouldCloseModal />
        );
    };

    return (
        <div className="simple-profile">
            {/* background graphics */}
            <div className="simple-profile-sky">
                <div className="simple-profile-cloud-wrap">
                    <div className="simple-profile-cloud-left" />
                    <div className="simple-profile-cloud-right" />
                </div>
            </div>
            <div className="simple-profile-grass" />
            {/* background graphics */}
            {!inEditMode ? (
                <div className="simple-profile-wrap">
                    <AvatarContainer
                        avatar={(getAvatar(avatar) || 'speedy') as AvatarType}
                        skinTone={skinTone || '#D69569'}
                    />
                    <h1 className="simple-profile-name">
                        {currentUser?.profile?.simple?.name ||
                            currentUser?.profile?.full_name ||
                            ''}
                    </h1>

                    {typeof currentUser?.profile?.simple?.age === 'number' && (
                        <h2 className="simple-profile-age">{`Age ${currentUser.profile.simple.age}`}</h2>
                    )}

                    <div className="simple-profile-btn-edit-wrap">
                        <button
                            type="button"
                            className="simple-profile-btn-edit"
                            onClick={() => setInEditMode(true)}
                        >
                            <h2 className="simple-profile-btn-edit-title">
                                Edit <EditPencil className="simple-profile-btn-edit-icon" />
                            </h2>
                        </button>
                        {isEphemeral(currentUser) && (
                            <button
                                type="button"
                                className="simple-profile-btn-verify"
                                onClick={() => handleShowGuardianWall(true)}
                            >
                                <h2 className="simple-profile-btn-verify-title">
                                    Verify{' '}
                                    <img
                                        src={Checkmark}
                                        className="hero-creator-btn-save-icon"
                                        alt="checkmark icon"
                                    />
                                </h2>
                            </button>
                        )}
                        {!isEphemeral(currentUser) && (
                            <button
                                type="button"
                                className="simple-profile-btn-players"
                                onClick={handleAccountSwitchRedirect}
                            >
                                <h2 className="simple-profile-btn-players-title">
                                    Heroes{' '}
                                    <img
                                        src={UserIcon}
                                        className="hero-creator-btn-players-icon"
                                        alt="players icon"
                                    />
                                </h2>
                            </button>
                        )}
                    </div>
                    <div className="simple-profile-begin-wrapper">
                        <button
                            type="button"
                            className="simple-profile-btn-generic glow-btn-animation"
                            onClick={() => closeAllModals()}
                        >
                            <h2 className="simple-profile-btn-title">Start Adventure!</h2>
                        </button>
                    </div>
                    <div className="simple-profile-logout-wrap">
                        <button
                            type="button"
                            className="simple-profile-btn-logout"
                            onClick={isEphemeral(currentUser) ? handleShowToast : logout}
                        >
                            Logout&nbsp;
                            <img
                                src={LogoutIcon}
                                className="simple-profile-btn-logout-icon"
                                alt="logout icon"
                            />
                        </button>
                    </div>
                </div>
            ) : (
                <EditSimpleProfile
                    onSubmit={() => setInEditMode(false)}
                    onCancel={() => setInEditMode(false)}
                    randomize={randomize}
                />
            )}
        </div>
    );
};

export default SimpleProfile;
