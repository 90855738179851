import React from 'react';

import MobileMenuList from '@components/modals/MobileMenuList';

import useModal from '@components/modals/hooks/useModal';

import { MenuListItem } from '@core/types/Menus';

type CourseFormSelectProps<T extends string = string> = {
    title?: string;
    prompt?: string;
    value?: T;
    onChange: (value: T) => void;
    options: T[];
};

const CourseFormSelect = <T extends string = string>({
    title,
    prompt = '',
    value,
    onChange,
    options,
}: CourseFormSelectProps<T>) => {
    const { newModal } = useModal();

    const optionsList: MenuListItem[] = options.map(option => ({
        id: option,
        title: option,
        onClick: () => onChange(option),
        selected: value === option,
    }));

    return (
        <>
            {title && <p>{title}</p>}
            <button
                type="button"
                onClick={() =>
                    newModal(<MobileMenuList menuList={optionsList} />, {
                        className: 'no-min-height',
                        widen: true,
                        addShadow: true,
                    })
                }
            >
                <span>{value || prompt}</span>
                <span role="presentation" className="caret">
                    &#x25BE;{/* (▾) */}
                </span>
            </button>
        </>
    );
};

export default CourseFormSelect;
