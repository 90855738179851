export const basicJsonPost = async (url, body) => {
    if (typeof body !== 'string') {
        body = JSON.stringify(body);
    }

    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body,
    });

    return [resp.status, await resp.json()];
};

export const authorizedJsonPost = async (url, body, token) => {
    if (typeof body !== 'string') {
        body = JSON.stringify(body);
    }

    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body,
    });

    return [resp.status, await resp.json()];
};
