import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { CustomSignupField } from '@shared/welibrary-graphql/types';
import useModal from '@web/ui/components/modals/hooks/useModal';
import FullScreenSelectManyOption from '@web/ui/components/generic/forms/FullScreenSelectManyOption.js';
import DropdownMultipleSelect from './DropdownMultipleSelect';

type DropdownProps = {
    name: string;
    field: CustomSignupField & { translatedOptions: CustomSignupField['options'] };
    showPrompt: boolean;
    handleCustomChange: (id: string, values: string | string[] | null, type: string) => void;
    lightBackground?: boolean;
    state: { [key: string]: { value: string[] | null } };
};

type DropdownOptionsModalProps = {
    name: string;
    state: { [key: string]: { value: string[] | null } };
    type?: string;
    lightBackground?: boolean;
    prompt?: string;
    allowOther?: boolean;
    options: any[];
    handleClick: any;
};

export const DropdownOptionsModal: React.FC<DropdownOptionsModalProps> = ({
    name,
    state: initialState,
    type,
    lightBackground,
    prompt,
    allowOther,
    options,
    handleClick,
}) => {
    const [state, setState] = useState(initialState || '');
    const { closeModal } = useModal();

    const handleClickOption = (e, option) => {
        setState(option);
        handleClick(name, option, type ?? '');
    };

    const optionsList = options?.map(option => {
        const isSelected = state?.toString() === option || option?.value;
        const handleOption = e => {
            if (option?.value) {
                handleClickOption(e, option?.value);
            } else {
                handleClickOption(e, option);
            }
        };
        const key = option?.value
            ? `option-${option?.value?.toString()}`
            : `option-${option?.toString()}`;
        return (
            <FullScreenSelectManyOption
                key={key}
                value={option?.value || option?.toString()}
                label={option?.text || option?.toString()}
                target={name}
                handleClick={handleOption}
                selected={isSelected}
            />
        );
    });

    let otherValue = '';

    const _options = options.filter(option => option === state);

    if (_options.length === 0) {
        otherValue = state;
    }

    const handleChange = e => {
        setState(e.target.value);
        handleClick(name, e.target.value, type ?? '');
    };

    return (
        <div className="select-option-container" key={`${name}`}>
            <section className="select-options-info">
                <div className="select-info-prompt">{prompt}</div>
                <button onClick={closeModal} type="button" className="standard-button callout">
                    Done
                </button>
            </section>
            <div className="options-list"> {optionsList}</div>
            {allowOther && (
                <form onSubmit={e => e.preventDefault()} className="channelTemplate-form-input">
                    <input
                        type="text"
                        name={name}
                        autoComplete="off"
                        onChange={handleChange}
                        value={otherValue}
                        id={name}
                        className={`signup-select-field ${
                            lightBackground ? 'light-background-select' : 'b-select'
                        }`}
                        placeholder="Other..."
                        list={`${name}-list`}
                    />
                </form>
            )}
        </div>
    );
};

const Dropdown: React.FC<DropdownProps> = ({
    name,
    field: { prompt, options, type, translatedOptions, selectMultiple, allowOther, required },
    showPrompt,
    handleCustomChange,
    lightBackground,
    state,
}) => {
    const { t } = useTranslation();
    const { newModal } = useModal();

    if (!selectMultiple) {
        const handleClick = (e, value) => {
            handleCustomChange(name, value, type ?? '');
        };

        // For some reason the state passed from optimzers value is in an array but not in signup?
        // workaround for now
        const initialState = Array.isArray(state[name]?.value)
            ? state[name]?.value?.[0]
            : state[name]?.value;

        return (
            <div className={`custom-signup-item ${!showPrompt && required ? 'required' : ''}`}>
                {showPrompt && (
                    <div className="custom-signup-label">
                        <span>{prompt}</span>
                        {required && <span className="required"> *</span>}
                    </div>
                )}

                <div
                    onClick={() =>
                        newModal(
                            <DropdownOptionsModal
                                name={name}
                                state={initialState}
                                allowOther={allowOther}
                                type={type}
                                prompt={prompt}
                                lightBackground
                                showPrompt={showPrompt}
                                options={options}
                                handleClick={handleClick}
                            />,
                            { hideButton: true }
                        )
                    }
                    className="signup-select-field-wrapper combo"
                    key={`${name}`}
                >
                    <input
                        type="text"
                        name={name}
                        value={state[name]?.value || ''}
                        id={name}
                        readOnly
                        style={{ caretColor: 'transparent' }}
                        autoComplete="off"
                        className={`signup-select-field ${
                            lightBackground ? 'light-background-select' : 'b-select'
                        }`}
                        placeholder={showPrompt ? 'Select or Type...' : prompt ?? undefined}
                        list={`${name}-list`}
                    />
                </div>
            </div>
        );
    }

    const handleShowMultipleModal = () => {
        newModal(
            <DropdownMultipleSelect
                target={name}
                allowMultipleSelect
                prompt={prompt || ''}
                placeholder={lightBackground ? t('common:input.question.placeholder') : prompt}
                options={
                    translatedOptions
                        ? options?.map((value, i) => ({
                              label: translatedOptions[i],
                              value,
                          }))
                        : options
                }
                state={state[name]?.value}
                type={type}
                lightBackground={lightBackground}
                handleCustomChange={handleCustomChange}
                allowOther={allowOther}
            />,
            { hideButton: true }
        );
    };

    // Display list of selected options
    const placeholder = lightBackground ? t('common:input.question.placeholder') : prompt;
    const selected = state[name]?.value?.filter(value => value !== '') || [];
    const selectedPlaceholder = _.reduce(selected, (a, b) => `${a + b}, `, '').slice(0, -2);
    const _placeholder = selected && selected?.length > 0 ? `${selectedPlaceholder}` : placeholder;

    return (
        <div
            onClick={handleShowMultipleModal}
            className={`custom-signup-item ${!showPrompt && required ? 'required' : ''}`}
        >
            {showPrompt && (
                <div className="custom-signup-label">
                    <span>{prompt}</span>
                    {required && <span className="required"> *</span>}
                </div>
            )}
            <div
                className="signup-select-field-wrapper"
                key={`${name}`}
                style={{ cursor: 'pointer', overflow: 'hidden' }}
            >
                <div
                    id={name}
                    name={name}
                    type={type}
                    className={`signup-select-field ${
                        lightBackground ? 'light-background-select' : 'b-select'
                    }`}
                    style={{ lineHeight: 2 }}
                >
                    {_placeholder}
                </div>
            </div>
        </div>
    );
};

export default Dropdown;
