import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import useDebouncedState from '@web/ui/components/generic/hooks/useDebouncedState';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import AlertToast from '@components/toast/AlertToast';

import { CARD_CHAPTER } from '@core/utilities/constants/card_types.js';
import { ATTACH_META_CHANNEL } from '@core/utilities/constants/attachment_types';
import { LIST_CARDS } from '@shared/welibrary-graphql/content_card/queries';

import { defaultAlertToastOptions } from '@core/types/Toast';

const useCreateMetaChannelCard = ({
    addAttachedContent,
    handleSubmit,
    defaultChannels = [],
    defaultChannelTitle = '',
    defaultTypes = [CARD_CHAPTER],
    groupId = null,
    prioritizeGroupId = false,
}) => {
    const { t } = useTranslation();

    const { newToast } = useToastActionsContext();
    const [title, setTitle] = useState(defaultChannelTitle);

    /** @type [Channel[], React.Dispatch<Channel[]>] */
    const [channels, setChannels] = useState(defaultChannels);
    const [query, actualQuery, setQuery] = useDebouncedState('');
    const [searching, setSearching] = useState(false);

    const { data, loading, error } = useQuery(LIST_CARDS, {
        fetchPolicy: 'no-cache',
        variables: {
            searchQuery: actualQuery,
            limit: 30,
            childrenLimit: 10,
            showEmpty: false,
            types: defaultTypes,
            prioritizeGroupId,
            groupId,
        },
        skip: !query,
    });

    /**
     * Adds a channel to the channel array
     *
     * @param {Channel} channel channel to add
     */
    const addChannel = channel => setChannels([...channels, channel]);

    /**
     * Adds a channel to the channel array and closes the search box
     *
     * @param {Channel} channel channel to add
     */
    const addChannelAndCloseSearch = channel => {
        addChannel(channel);
        setQuery('');
        setSearching(false);
    };

    /**
     * Updates a channel's channel
     *
     * @param {string} id id of channel to update
     * @param {string} value new channel
     */
    const updateChannel = (id, value) => {
        const channelIndex = channels.findIndex(channel => channel?._id === id);

        if (channelIndex < 0) return;

        setChannels(oldChannels => {
            const newChannels = [...oldChannels];

            newChannels[channelIndex] = { ...newChannels[channelIndex], newChannel: value };

            return newChannels;
        });
    };

    /**
     * Swaps two channels in the channel array
     *
     * @param {number} firstIndex index of the first channel
     * @param {number} secondIndex index of the second channel
     */
    const moveChannel = (firstIndex, secondIndex) => {
        setChannels(oldChannels => {
            const newChannels = [...oldChannels];

            newChannels.splice(secondIndex, 0, newChannels.splice(firstIndex, 1)[0]);

            return newChannels;
        });
    };

    /**
     * Deletes a channel from the channel array
     *
     * @param {number} id id of the channel to delete
     */
    const deleteChannel = id =>
        setChannels(oldChannels => oldChannels.filter(oldChannel => oldChannel?._id !== id));

    /** @type React.FormEventHandler<HTMLFormElement> */
    const submit = e => {
        e.preventDefault();

        if (channels.length <= 0) {
            newToast(
                <AlertToast
                    boldText={t(`common:imports.wlWeb.ui.components.toast.error`, 'Error!')}
                    text={t(
                        `common:imports.wlWeb.ui.components.metachannel.alias_required`,
                        'Must have at least one alias selected to create a meta channel.'
                    )}
                    showWarningIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
            return;
        }

        handleSubmit();
    };

    /** @type Channel[] */
    const results = (data?.listCards ?? []).filter(
        ({ _id }) => !channels.some(channel => channel?._id === _id)
    );

    useEffect(() => {
        setSearching(!!query);
    }, [query]);

    useEffect(() => {
        if (channels.length > 0) {
            const aliases = channels.map(({ newPrompt: title, url }) => ({
                title,
                url,
                type: CARD_CHAPTER,
            }));

            addAttachedContent(
                {
                    meta: [],
                    title: title || channels?.[0]?.newChannel || channels?.[0]?.channel,
                    aliases,
                },
                ATTACH_META_CHANNEL
            );
        }
    }, [title, channels]);

    return {
        title: { title, setTitle },
        channels,
        search: { query, actualQuery, setQuery, searching, loading, error },
        methods: { addChannelAndCloseSearch, updateChannel, moveChannel, deleteChannel, submit },
        results,
    };
};

export default useCreateMetaChannelCard;
