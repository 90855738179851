import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';
import { TemplateItems } from '@core/types/GroupTemplateItem';
import { GET_TEMPLATES_AVAILABLE_TO_USER } from '@shared/welibrary-graphql/content_card/queries';
import { useQuery } from '@apollo/client';
import { GET_BOOK } from '@shared/welibrary-graphql/content_card/queries';

import { useAttachedChapterMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';
import useInfiniteScroll from '@web/ui/components/generic/hooks/useInfiniteScroll';
import useModal from '@web/ui/components/modals/hooks/useModal';

import IntroContent from '@web/ui/components/generic/IntroContent';
import LoadingIndicator from '@web/ui/components/generic/loading/LoadingIndicator';
import ModalLoading from '@web/ui/components/modals/ModalLoading';

import getLogger from '@core/logger';
import TemplateItem from './GroupTemplateItem';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.group.groupDashboard.create_channel';

const GroupCreateChannel: React.FC<{ bookURL: string }> = ({ bookURL }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const { currentUser } = useCurrentUser();

    const { newModal, closeAllModals } = useModal();

    const [inputValue, setInput] = useState<string>('');

    const [attachChapter] = useAttachedChapterMutation();

    const {
        queryResult: { data: templatesData, loading: templatesLoading },
        setRef,
    } = useInfiniteScroll({
        query: {
            query: GET_TEMPLATES_AVAILABLE_TO_USER,
            options: {
                variables: { type: 'chapter', limit: 20 },
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
            },
        },
    });

    const { refetch: getBookRefetch } = useQuery(GET_BOOK, {
        variables: { url: bookURL },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: true, // Skip initial query, we only want to refetch
    });

    const hasMoreResults = templatesData?.getTemplatesAvailableToUser.hasMore;
    const chapterTemplates = templatesData?.getTemplatesAvailableToUser.results;

    const chapterTemplateOptions: TemplateItems = chapterTemplates?.map(item => ({
        title: item?.title ?? '',
        description: item?.description ?? '',
        thumb: item?.media?.thumb ?? '',
        url: item?.url ?? '',
    }));

    const submitChannel = async (
        e?: React.FormEvent<HTMLFormElement | HTMLButtonElement>,
        templateUrl?: string,
        titleOverride?: string
    ) => {
        e?.preventDefault();

        if (inputValue.trim() === '' && !titleOverride) return;
        if (titleOverride && titleOverride.trim() === '') return;
        newModal(<ModalLoading message={t(`common:${localNamespace}.creating_channel`)} />);

        try {
            const attachChapterResponse = await attachChapter({
                variables: {
                    parentUrl: bookURL,
                    title: inputValue,
                    authorID: currentUser?._id,
                    templateUrl,
                },
            });

            const attachedChapterId = attachChapterResponse?.data?.attachedChapter?._id;

            const waitForChapterDataToBeAvailable = async () => {
                const isChapterPresentInBook = data => {
                    return data?.cardByUrl?.children?.some(
                        chapter => chapter?._id === attachedChapterId
                    );
                };
                const startTime = Date.now();
                /* eslint-disable no-await-in-loop */
                // Wait for 10 seconds for the chapter to be available in the book
                while (Date.now() - startTime < 10000) {
                    const { data } = await getBookRefetch();
                    if (isChapterPresentInBook(data)) {
                        return;
                    }
                    await new Promise(res => setTimeout(res, 500));
                }
                /* eslint-enable no-await-in-loop */
            };

            // Wait for the chapter to be available in the book before redirecting
            await waitForChapterDataToBeAvailable();

            setInput('');
            closeAllModals();
            history.push(attachChapterResponse?.data?.attachedChapter?.reference?.href!);
        } catch (error) {
            logger.error(error);
            newModal(
                <IntroContent
                    type="error"
                    declaration={t(
                        `common:imports.wlWeb.ui.components.generic.introContent.error.general.uh_oh_something_went_wrong`
                    )}
                >
                    <button
                        type="button"
                        className="button callout-button top-margin w-button"
                        onClick={() => newModal(<GroupCreateChannel bookURL={bookURL} />)}
                    >
                        {t(
                            'common:imports.wlWeb.ui.components.generic.introContent.error.general.please_try_again'
                        )}
                    </button>
                </IntroContent>
            );
        }
    };

    const handleSelectedTemplate = (key?: string, value?: string) =>
        submitChannel(undefined, value, key);

    return (
        <section className="channelTemplate-form">
            <header>{t(`common:${localNamespace}.new_channel`)}</header>

            <form onSubmit={submitChannel} className="channelTemplate-form-input">
                <input
                    autoComplete="off"
                    type="text"
                    maxLength={256}
                    name="field"
                    data-name="Field"
                    placeholder={t(`common:${localNamespace}.title`)}
                    onChange={e => setInput(e.currentTarget.value)}
                    style={{ backgroundImage: 'none', padding: '10px 30px' }}
                    value={inputValue}
                    onClick={e => e.stopPropagation()}
                />

                <button type="button" onClick={submitChannel}>
                    <div className="content-button-icon plus" />
                </button>
            </form>

            {templatesLoading && <LoadingIndicator type="skeleton" size="fill" />}

            <ul>
                {!templatesLoading && chapterTemplateOptions.length > 0
                    ? chapterTemplateOptions.map(item => {
                          return (
                              <TemplateItem
                                  key={item.url}
                                  item={item}
                                  handleSelectedTemplate={handleSelectedTemplate}
                              />
                          );
                      })
                    : null}
                {hasMoreResults && (
                    <div ref={setRef}>
                        {' '}
                        <LoadingIndicator type="skeleton" size="fill" />
                    </div>
                )}
            </ul>
        </section>
    );
};

export default GroupCreateChannel;
