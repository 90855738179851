import React from 'react';

import Avatar from '@components/profile/Lego/Avatar';
import { useCurrentUser } from '@stores/User';

import { getUserAvatar } from '@helpers/user/avatar.helpers';

import { AvatarType } from '@components/profile/Lego/Avatars';

import { getAvatar } from '@components/profile/Lego/avatarHelpers';

const MiniAvatar: React.FC<{ circleClass?: string; avatarClass?: string }> = ({
    circleClass,
    avatarClass,
}) => {
      const { currentUser } = useCurrentUser();

    const { avatar, skinTone } = getUserAvatar(currentUser?.profile?.avatar);
    return (
        <div className={`mini-avatar ${circleClass}`}>
            <Avatar
                className={`mini-avatar-avatar ${avatarClass}`}
                avatar={(getAvatar(avatar) || 'speedy') as AvatarType}
                colors={{ skinTone }}
            />
        </div>
    );
};

export default MiniAvatar;
