import { config } from '@core/config/getConfig';

const DOCUMENT_PREVIEW = 'preview';

const isFilestackUrl = (fileUrl: string) => {
    return fileUrl.includes('cdn.filestackcontent.com');
};

export const getDocumentPreviewUrl = (fileUrl: string) => {
    if (!fileUrl) return fileUrl;

    if (config.public.filestack.filePreview) {
        if (isFilestackUrl(fileUrl) && !fileUrl.includes('preview')) {
            const splitUrl = fileUrl.split('.com/');

            if (splitUrl.length === 2) {
                return `${splitUrl[0]}.com/${DOCUMENT_PREVIEW}/${splitUrl[1]}`;
            }
        }
    } else {
        fileUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${fileUrl}`;
    }

    return fileUrl;
};

export const getDocumentDownloadUrl = (fileUrl: string) => {
    if (!fileUrl) return fileUrl;

    if (isFilestackUrl(fileUrl)) {
        return `${fileUrl}?dl=true`;
    }

    // No specific handling for non-filestack urls because we currently only use filestack
    return fileUrl;
};
