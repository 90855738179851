import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import { Capacitor } from '@capacitor/core';

import { config } from '@config/getConfig';
import {
    getWhitelabelFirebaseConfig,
    getWhitelabelSiteMeta,
} from '@core/utilities/whitelabel_helpers';
import pushUtilities from '@core/utilities/pushUtilities';

import getLogger from '@core/logger';

const logger = getLogger(module);

export const initializeFirebase = () => {
    const firebaseConfig = getWhitelabelFirebaseConfig();

    if (firebaseConfig) {
        if (firebase.apps.length) firebase.app();
        else firebase.initializeApp(firebaseConfig);

        firebase.analytics();
    }
    pushUtilities.initializeDesktopBackgroundHandlers();
};

export const registerServiceWorker = () => {
    if (!Capacitor.isNativePlatform() && config.public.pwaServiceWorker) {
        if ('serviceWorker' in navigator) {
            const { disableServiceWorker } = getWhitelabelSiteMeta();
            if (!disableServiceWorker) {
                navigator.serviceWorker
                    .register(config.public.pwaServiceWorker)
                    .then(registration => {
                        logger.info('service worker registered');
                        firebase.messaging().getToken({ serviceWorkerRegistration: registration });
                    })
                    .catch(error => {
                        logger.error('ServiceWorker registration failed: ', error);
                    });
            }
        }
    }
};
