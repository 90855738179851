import React from 'react';
import RightCaretIcon from '@dsc/svgs/RightCaretIcon';
import DownCaretIcon from '@dsc/svgs/FullDownCaretIcon';

import { SetState } from '@core/types/Utilities';

type ToggleButtonProps = {
    isCollapsed: boolean;
    setIsCollapsed: SetState<boolean>;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({ isCollapsed, setIsCollapsed }) => {
    return (
        <button
            type="button"
            className="dashboard-toggle-button"
            onClick={() => setIsCollapsed(!isCollapsed)}
        >
            {isCollapsed && <RightCaretIcon size="20" />}
            {!isCollapsed && <DownCaretIcon size="20" />}
        </button>
    );
};

export default ToggleButton;
