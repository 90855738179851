import React, { useState } from 'react';
import { useImmer } from 'use-immer';
import { v1 as uuidv1 } from 'uuid';
import SponsorTierPanel from '@components/content/newpost/forms/EventGroup/SponsorTierPanel';
import SponsorFormPanel from '@components/content/newpost/forms/EventGroup/SponsorFormPanel';

import { Sponsor, SponsorTier } from '@core/types/EventGroup';

type SponsorsFormProps = {
    handleSaveSponsor: (sponsor: Sponsor, id?: string) => void;
    handleSaveTiers: (tiers: SponsorTier[]) => void;
    handleCancel: () => void;
    initialState?: Sponsor;
    tiers: SponsorTier[];
    id?: string;
};

const generateNewSponsor = () => {
    return {
        id: uuidv1(),
        name: '',
        link: '',
        logo: {
            image: '',
        },
        tier: null,
    };
};

const SponsorsForm: React.FC<SponsorsFormProps> = ({
    handleSaveSponsor,
    handleCancel,
    initialState,
    tiers,
    handleSaveTiers,
}) => {
    const [state, setState] = useImmer(initialState || generateNewSponsor());
    const [page, setPage] = useState('main');
    const [localTiers, setLocalTiers] = useImmer(tiers);

    const handleSave = () => {
        if (!initialState) {
            handleSaveSponsor(state);
        }

        if (initialState) {
            handleSaveSponsor(state, initialState?.id);
        }
        handleCancel();
    };

    const handleSaveTiersAndUpdateSponsor = (updatedTiers: SponsorTier[]) => {
        // update top form state
        handleSaveTiers(updatedTiers);
        // update local form state
        if (localTiers?.find(tier => tier.name === state.tier?.name)) {
            setState(draft => {
                draft.tier = updatedTiers.at(-1)!;
                return draft;
            });
        }
    };

    return (
        <div className="scroll-middle-fixed-header-footer">
            {page === 'main' && (
                <SponsorFormPanel
                    tiers={localTiers}
                    handleCancel={handleCancel}
                    handleSaveSponsor={handleSave}
                    setState={setState}
                    state={state}
                    setPage={setPage}
                />
            )}
            {page === 'tier' && (
                <SponsorTierPanel
                    tiers={localTiers}
                    setLocalTiers={setLocalTiers}
                    handleSaveTiers={handleSaveTiersAndUpdateSponsor}
                    setState={setState}
                    state={state}
                    setPage={setPage}
                />
            )}
        </div>
    );
};

export default SponsorsForm;
