import React from 'react';

import Ticket from '@dsc/svgs/Ticket';
import VerifyBadge from '@dsc/svgs/VerifyBadge';
import TinyGlobe from '@dsc/svgs/TinyGlobe';
import Picture from '@components/generic/Picture';

import {
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import { Product, Group } from '@shared/welibrary-graphql/types';

import { DiscountCodeState } from '@components/card/Shopping-Cart/CheckoutHelpers';

import { CARD_EVENT } from '@core/utilities/constants/card_types';

import IncrementStepper from '@web/ui/dsc/forms/customInputs/IncrementStepper';
import { getCurrencyDisplayString } from '~/wl-web/utilities/helpers/money/money.helpers';

const OrderItemPreview: React.FC<{
    cartItem: Product;
    setCart?: React.Dispatch<
        React.SetStateAction<{
            cartItem: Product;
            quantity: number;
        }>
    >;
    originalPrice: number | string;
    discountCodeState: DiscountCodeState;
    group: Group;
    total: number | string;
    quantity: number;
    errors?: Record<string, string[]>;
    allowEdit?: boolean;
}> = ({
    cartItem,
    setCart = () => {},
    quantity,
    originalPrice,
    discountCodeState,
    group,
    total,
    errors,
    allowEdit = false,
    children,
}) => {
    const [coverSrc, coverSrcSet] = getBackgroundImageSetForUrl(
        group?.profile?.coverPhoto || getRandomDefaultCoverImage(group?.profile?.full_name ?? '')
    );

    const displayTotal = getCurrencyDisplayString(
        total,
        cartItem?.currency,
        undefined,
        true,
        2
    );

    const isEvent = group?.subtype === CARD_EVENT;
    return (
        <div className="checkout-item-wrap">
            {isEvent ? (
                // event item preview
                <div className="checkout-item">
                    <div className="checkout-item-cover-wrap">
                        <StyledBackgroundImage
                            src={coverSrc}
                            srcSet={coverSrcSet}
                            className="checkout-item-cover"
                        />
                        <div className="checkout-item-icon-wrap">
                            <Ticket className="checkout-item-icon" strokeColor="#fff" />
                        </div>
                    </div>

                    <div className="checkout-item-details-wrap">
                        <h5>{cartItem?.name}</h5>

                        <p>{group?.profile?.full_name}</p>

                        <div className="checkout-item-inner-details-wrap">
                            {allowEdit ? (
                                <IncrementStepper
                                    className={`checkout-increment-stepper
                                    ${errors?.quantity ? 'checkout-increment-stepper-error' : ''}
                                    `}
                                    placeholder="qty"
                                    value={quantity}
                                    onChange={value =>
                                        setCart(_cart => {
                                            return {
                                                ..._cart,
                                                quantity: value,
                                            };
                                        })
                                    }
                                    min={1}
                                />
                            ) : (
                                <p className="checkout-item-inner-details-qty">
                                    Quantity: {quantity}
                                </p>
                            )}

                            <div className="checkout-item-inner-details-total">
                                <span
                                    className={`old-total ${
                                        discountCodeState?.isApplied ? 'strikethrough' : ''
                                    }`}
                                >
                                    {discountCodeState?.isApplied ? originalPrice : displayTotal}
                                </span>
                                <span
                                    className={`new-total ${
                                        discountCodeState?.isApplied ? '' : 'hidden'
                                    }`}
                                >
                                    {displayTotal}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                // subcription item preview
                <div className="checkout-item-preview-sub">
                    <div className="checkout-item-preview-sub-header">
                        <h1>Subscribe</h1>
                        <p className="checkout-item-inner-details-total checkout-item-inner-details-total-inline">
                            Membership fee:&nbsp;
                            <span
                                className={`old-total ${
                                    discountCodeState?.isApplied ? 'strikethrough' : ''
                                }`}
                            >
                                {discountCodeState?.isApplied ? originalPrice : displayTotal}
                            </span>
                            &nbsp;
                            <span
                                className={`new-total ${
                                    discountCodeState?.isApplied ? '' : 'hidden'
                                }`}
                            >
                                {displayTotal}
                            </span>
                        </p>
                    </div>
                    <div className="checkout-item-preview-sub-content">
                        <div
                            className="checkout-item-preview-sub-cover"
                            style={{
                                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${coverSrc})`,
                            }}
                        />

                        <div className="checkout-item-preview-sub-thumb">
                            <Picture url={coverSrc} className="checkout-item-preview-thumb-img" />
                        </div>

                        <div className="checkout-item-preview-sub-info">
                            <div className="checkout-item-preview-sub-privacy">
                                <div className="paywall-info-icon-wrap">
                                    <VerifyBadge className="paywall-info-icon-badge" />
                                    <TinyGlobe
                                        className="paywall-info-icon-world"
                                        strokeColor="#fff"
                                    />
                                </div>
                                &nbsp;
                                <p>{group?.settings?.protectionLevel} Group</p>
                            </div>
                            <div className="checkout-item-preview-sub-text">
                                <h5>{cartItem?.name}</h5>
                                <p>{group?.profile?.full_name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {children}
        </div>
    );
};

export default OrderItemPreview;
