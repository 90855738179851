import React from 'react';
import { CARD_TYPES } from '@core/utilities/constants/card_types.js';
import moment from 'moment';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

// Icons used
import DocumentIcon from '@web/ui/dsc/svgs/DocumentIcon';
import CalendarIcon from '@web/ui/dsc/svgs/CalendarIcon';
import BriefcaseIcon from '@web/ui/dsc/svgs/BriefcaseIcon';
import RocketShip from '@web/ui/dsc/svgs/RocketShip';
import HelpingHandsIcon from '@web/ui/dsc/svgs/HelpingHandsIcon';
import ChainLink from '@web/ui/dsc/svgs/ChainLink';
import PlayCircle from '@web/ui/dsc/svgs/PlayCircle';

const TYPE_TO_ICON = {
    [CARD_TYPES.CARD_EVENT]: (
        <CalendarIcon height="100" width="100" className="rec-card-note-icon" strokeWidth="0" />
    ),
    [CARD_TYPES.CARD_DOCUMENT]: (
        <DocumentIcon height="100" width="100" className="rec-card-note-icon" strokeWidth="2" />
    ),
    [CARD_TYPES.CARD_MOBILIZATION]: (
        <BriefcaseIcon height="100" width="100" className="rec-card-note-icon" strokeWidth="3" />
    ),
    [CARD_TYPES.CARD_LINK]: (
        <ChainLink height="100" width="100" className="rec-card-note-icon" strokeWidth="3" />
    ),
    volunteer: (
        <HelpingHandsIcon height="100" width="100" className="rec-card-note-icon" strokeWidth="1" />
    ),
    actionCampaign: <RocketShip size="50" className="rec-card-campaign-icon" strokeWidth="1" />,
};

export const PostingAuthorBlock = ({ authorName, dateString, thumbSrc, thumbSrcSet }) => {
    return (
        <section className="rec-card-author-profile">
            <div className="mc-source-container">
                <StyledBackgroundImage
                    className="thumbnail w-inline-block"
                    srcSet={thumbSrcSet}
                    src={thumbSrc}
                >
                    <div className="square-spacer" />
                </StyledBackgroundImage>
                <div className="mc-source-info-container">
                    <div className="media-card-author">{authorName}</div>
                    <div className="mc-source-sub">{dateString}</div>
                </div>
            </div>
        </section>
    );
};

export const getContentCardHeader = (source, history, closeModal) => {
    let cardType = source?.type;
    const title = source?.title;

    let cardHeader = <div />;
    if (cardType) {
        const authorProfile = source?.author?.profile;
        const thumbImage = authorProfile?.picture || getRandomDefaultUserImage();
        const [thumbSrc, thumbSrcSet] = getBackgroundImageSetForUrl(
            thumbImage || getRandomDefaultUserImage()
        );

        const decipherDate = dateString => {
            const myDate = new Date(dateString);
            return new Date(myDate.getTime()).toDateString();
        };

        const dateString = decipherDate(source?.date?.last_update);

        // Doesn't this need to take into account the inherited settings from the chapter it was made?
        // chapter level from reference property
        const chapterSettings = source?.reference?.chapterSource?.library_settings;
        // If chapterSettings.displaySocialHeaders is explicitly set to false, do not check
        // in card.library_settings, but if it is set to true OR NULL, check in library_settings
        let displaySocialHeader = chapterSettings?.displaySocialHeaders;
        if (displaySocialHeader !== false) {
            displaySocialHeader =
                source.library_settings?.displaySocialHeaders ?? displaySocialHeader;
        }

        switch (cardType) {
            case CARD_TYPES.CARD_EVENT:
            case CARD_TYPES.CARD_MOBILIZATION:
            case CARD_TYPES.CARD_LINK:
            case CARD_TYPES.CARD_DOCUMENT:
                let subTitle;
                if (cardType === 'mobilization') {
                    subTitle = `By ${source?.reference?.groupSource?.profile?.full_name}`;
                }
                // volunteer vs job mobilizations are not distinguished as separate types on the backend schema
                if (
                    cardType === 'mobilization' &&
                    source?.mobilizationSettings?.efforts[0]?.card?.postingSettings?.type ===
                        'VOLUNTEER'
                ) {
                    cardType = 'volunteer';
                }

                // action campaigns - not sure the best way to distinguish this
                if (
                    cardType === 'mobilization' &&
                    source?.mobilizationSettings?.efforts[0]?.type === 'PLEDGE'
                ) {
                    cardType = 'actionCampaign';
                }

                let eventDate;

                if (cardType === 'event') {
                    eventDate = moment(source?.eventSettings?.date).format('ddd MMM Do YYYY');
                    subTitle = eventDate;
                }

                cardHeader = (
                    <section className="card-rec-top">
                        <div
                            onClick={() => {
                                history.push(`/c/${source?.url}`);
                                closeModal();
                            }}
                            className="discover-modal-list-item-cover-wrap icon-wrap"
                        >
                            {TYPE_TO_ICON[cardType]}
                        </div>
                        {subTitle && <div className="rec-card-subtitle">{subTitle}</div>}
                        <h3 className="discover-modal-list-item-cover-title black-text">{title}</h3>
                        {(cardType === CARD_TYPES.CARD_DOCUMENT ||
                            cardType === CARD_TYPES.CARD_LINK) &&
                            displaySocialHeader && (
                                <PostingAuthorBlock
                                    authorName={authorProfile.full_name}
                                    dateString={dateString}
                                    thumbSrc={thumbSrc}
                                    thumbSrcSet={thumbSrcSet}
                                />
                            )}
                    </section>
                );
                break;
            case CARD_TYPES.CARD_VIDEO:
            case CARD_TYPES.CARD_IMAGE_SQUARE:
            case CARD_TYPES.CARD_IMAGE:
                const [src, srcSet] = getBackgroundImageSetForUrl(
                    source?.media?.thumb || getRandomDefaultCoverImage()
                );

                cardHeader = (
                    <>
                        <div
                            onClick={() => {
                                history.push(`/c/${source?.url}`);
                                closeModal();
                            }}
                            className="discover-modal-list-item-cover-wrap"
                        >
                            <StyledBackgroundImage disableLink src={src} srcSet={srcSet} />
                            {cardType === CARD_TYPES.CARD_VIDEO && (
                                <PlayCircle className="video-play-btn" />
                            )}
                            <h3 className="discover-modal-list-item-cover-title">{title}</h3>
                        </div>
                    </>
                );

                break;
            default:
                cardHeader = (
                    <div
                        onClick={() => {
                            history.push(`/c/${source?.url}`);
                            closeModal();
                        }}
                        className="discover-modal-list-item-cover-wrap"
                    >
                        {displaySocialHeader && (
                            <PostingAuthorBlock
                                authorName={authorProfile.full_name}
                                dateString={dateString}
                                thumbSrc={thumbSrc}
                                thumbSrcSet={thumbSrcSet}
                            />
                        )}
                        <section className="rec-content-body">
                            <h4 className="rec-content-body-title">{source?.title}</h4>
                            <div className="rec-content-body-text">{source?.body}</div>
                        </section>
                    </div>
                );
                break;
        }
    }

    return cardHeader;
};
