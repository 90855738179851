import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import useGroupActions from '@components/group/hooks/useGroupActions';
import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';
import { useAddUserToGroupMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import VerifyBadge from '@dsc/svgs/VerifyBadge';
import SimpleLock from '@dsc/svgs/SimpleLock';
import TinyGlobe from '@dsc/svgs/TinyGlobe';
import Checkout from '@components/card/Shopping-Cart/Checkout';
import ParentGroupSubscriptionActionConfirmation from '@components/group/groupdashboard/groupSubscriptionPaywall/ParentGroupSubscriptionActionConfirmation';

import { Group } from '@shared/welibrary-graphql/types';
import { GROUP_ACTIONS } from '@core/utilities/constants/group';
import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';

import { getTotal } from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';
import {
    isGroupSubscription,
    parentGroupHasSubscription,
    shouldPurchaseParentSubscription,
    shouldRequestToPurchaseParentSubscription,
} from '@components/group/groupdashboard/groupDashboardHelpers';
import { getCurrencyDisplayString } from '~/wl-web/utilities/helpers/money/money.helpers';

const GroupSubscriptionInfoContainer: React.FC<{ group: Group; requestToJoin?: boolean }> = ({
    group,
}) => {
    const history = useHistory();
    const { newModal } = useModal();
    const { currentUser } = useCurrentUser();

    const [addUserToGroup] = useAddUserToGroupMutation();

    const { takeGroupActionForUser, primaryActionAvailable } = useGroupActions({
        group,
        refetchQueries: [GET_GROUP_DASHBOARD],
    });

    const isEvent = group.subtype === 'event';
    const _isGroupSubscription = isGroupSubscription(group);
    const _parentGroupHasSubscription = parentGroupHasSubscription(group);

    const parentSubscriptionGroup = group?.parentSubscriptions?.[0];
    const parentDefaultSubscription = parentSubscriptionGroup?.products?.[0];

    const _shouldPurchaseParentSubscription = shouldPurchaseParentSubscription(
        group,
        parentSubscriptionGroup
    );
    const _shouldRequestToPurchaseParentSubscription = shouldRequestToPurchaseParentSubscription(
        group,
        parentSubscriptionGroup
    );

    const defaultSubscription = _parentGroupHasSubscription
        ? parentDefaultSubscription
        : group?.products?.[0];

    if (!defaultSubscription || isEvent) {
        return <></>;
    }

    const modalOptions = {
        hideButton: true,
        widen: false,
        addShadow: false,
        className: 'subs-parent-group-confirmation',
    };

    const handleRequestToJoin = () => {
        if (_shouldRequestToPurchaseParentSubscription) {
            addUserToGroup({
                variables: {
                    groupId: parentSubscriptionGroup?._id,
                    userId: currentUser?._id,
                    subgroupId: group?._id,
                },
                refetchQueries: [GET_GROUP_DASHBOARD],
            });
        } else {
            addUserToGroup({
                variables: {
                    groupId: parentSubscriptionGroup?._id,
                    userId: currentUser?._id,
                },
                refetchQueries: [GET_GROUP_DASHBOARD],
            });
        }
    };

    const handleParentSubscriptionGroupRedirect = () => {
        history.push(`/g/${parentSubscriptionGroup?._id}?subgroupId=${group?._id}`);
    };

    const handleTakePrimaryAction = () => {
        if (_shouldPurchaseParentSubscription) {
            // shortcut into checkout, with first product in group.products
            // currently only supporitng a single subscription type for groups
            newModal(
                <Checkout
                    subgroup={group}
                    group={parentSubscriptionGroup}
                    product={parentDefaultSubscription}
                    quantity={1}
                />,
                {
                    className: 'no-top-padding no-bottom-padding',
                    hideButton: true,
                }
            );
        }
        if (_shouldRequestToPurchaseParentSubscription) {
            newModal(
                <ParentGroupSubscriptionActionConfirmation
                    subgroup={group}
                    parentSubscriptionGroup={parentSubscriptionGroup}
                    handleRequestTojoin={handleRequestToJoin}
                    isRequestingToJoin
                />,
                modalOptions
            );
        } else {
            takeGroupActionForUser(currentUser?._id, primaryActionAvailable?.action);
        }
    };

    const price = getTotal(defaultSubscription, 1);
    const priceDisplayString = getCurrencyDisplayString(
        price,
        defaultSubscription?.currency,
        undefined,
        true,
        2
    );

    if (
        (_isGroupSubscription &&
            primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_REQUEST_TO_PURCHASE) ||
        (!currentUser && defaultSubscription) // show for users that aren't logged in
    ) {
        return (
            <div className="paywall-info-container">
                <div className="paywall-info">
                    <div className="paywall-info-icon-wrap">
                        <VerifyBadge className="paywall-info-icon-badge" />
                        <SimpleLock className="paywall-info-icon-lock" />
                    </div>
                    <h1>Premium Membership Group</h1>
                    <p>
                        This group requires a subscription fee of{' '}
                        <strong>{priceDisplayString} per month</strong>
                        .
                        <br />
                        {currentUser && (
                            <>
                                Click <span onClick={handleTakePrimaryAction}>Request to join</span>{' '}
                                to get approval to subscribe
                            </>
                        )}
                        {!currentUser && (
                            <>
                                <Link
                                    to={`/signup?mode=email&signupLink=true&returnTo=/g/${group?._id}`}
                                >
                                    Create an account
                                </Link>{' '}
                                to join
                            </>
                        )}
                    </p>
                </div>
            </div>
        );
    }

    if (
        _isGroupSubscription &&
        (primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_PURCHASE_ACCESS ||
            primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_REACTIVATE_SUBSCRIPTION)
    ) {
        return (
            <div className="paywall-info-container">
                <div className="paywall-info">
                    <div className="paywall-info-icon-wrap">
                        <VerifyBadge className="paywall-info-icon-badge" />
                        <TinyGlobe className="paywall-info-icon-world" strokeColor="#fff" />
                    </div>
                    <h1>Premium Membership Group</h1>
                    <p>
                        This group requires a subscription fee of{' '}
                        <strong>{priceDisplayString} per month</strong>
                        .
                        <br />
                        Click <span onClick={handleTakePrimaryAction}>Subscribe</span> to join.
                    </p>
                </div>
            </div>
        );
    }

    if (_shouldPurchaseParentSubscription) {
        return (
            <div className="paywall-info-container">
                <div className="paywall-info">
                    <div className="paywall-info-icon-wrap">
                        <VerifyBadge className="paywall-info-icon-badge" />
                        <TinyGlobe className="paywall-info-icon-world" strokeColor="#fff" />
                    </div>
                    <h1>Premium Membership Group</h1>
                    <p>
                        To join, this group requires a subscription to the{' '}
                        <button type="button" onClick={handleParentSubscriptionGroupRedirect}>
                            {parentSubscriptionGroup.profile?.full_name}
                        </button>{' '}
                        Network for a fee of <strong>{priceDisplayString} per month</strong>.{' '}
                        <span onClick={handleTakePrimaryAction}>Subscribe Here</span>
                    </p>
                </div>
            </div>
        );
    }

    if (_shouldRequestToPurchaseParentSubscription) {
        return (
            <div className="paywall-info-container">
                <div className="paywall-info">
                    <div className="paywall-info-icon-wrap">
                        <VerifyBadge className="paywall-info-icon-badge" />
                        <SimpleLock className="paywall-info-icon-lock" />
                    </div>
                    <h1>Premium Membership Group</h1>
                    <p>
                        This group requires approval from the{' '}
                        <button type="button" onClick={handleParentSubscriptionGroupRedirect}>
                            {parentSubscriptionGroup.profile?.full_name}
                        </button>{' '}
                        Network and a subscription fee of{' '}
                        <strong>{priceDisplayString} per month</strong> before being able to join.{' '}
                        <span onClick={handleTakePrimaryAction}>Request to join</span>
                    </p>
                </div>
            </div>
        );
    }

    return <></>;
};

export default GroupSubscriptionInfoContainer;
