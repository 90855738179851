import React, { useState } from 'react';
import _ from 'lodash';
import { v5 as uuidv5 } from 'uuid';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
} from '@core/utilities/constants/defaults';

import {
    DndContext,
    MouseSensor,
    TouchSensor,
    KeyboardSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import LinkTile from './LinkTile';
import LinkTileListForm from './LinkTileListForm';

const EditableLinkTiles = ({
    card,
    aliases,
    group,
    chapter,
    viewType,
    disabled,
    padListItems,
    handleChange,
    deleteAliasItem,
    updateAliasItem,
    inEditMode,
}) => {
    const [draggedId, setDraggedId] = useState(null);
    const dragAndDropSensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor)
    );

    const linkTileIds = []; // need to be unique ids

    // todo: Use LinkTile for linkTilePreview too?
    const linkTiles = aliases.map((alias, index) => {
        const existingCardAlias = _.find(card?.aliases, { url: alias.url });
        const aliasItem = alias || existingCardAlias;
        const item = aliasItem.group || aliasItem.item;

        let backgroundStyle = {
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
        };
        // This is abit weird, but before the thumb field was added
        // custom imageUrls were stored in the color field...
        if (aliasItem?.color && aliasItem?.color[0] === '#') {
            backgroundStyle = {
                backgroundColor: aliasItem.color,
                backgroundImage: 'unset',
                opacity: 1,
            };
        }

        let mediaThumb;
        if (item?.__typename === 'Group') {
            mediaThumb = item?.profile?.coverPhoto || item?.profile?.picture;
        }
        if (item?.__typename === 'ContentCard') {
            if (aliasItem.type === 'chapter') {
                mediaThumb = item?.media?.icon || item?.media?.thumb || item?.reference?.coverImage;
            }
            if (aliasItem.type === 'book') {
                mediaThumb = item?.media?.thumb || item?.reference?.coverImage;
            }
        }
        if (aliasItem.thumb) mediaThumb = aliasItem.thumb;
        const [src, srcSet] = getBackgroundImageSetForUrl(
            mediaThumb || getRandomDefaultCoverImage(alias.title)
        );

        const aliasName = uuidv5('tile', '9dca4900-f786-4a2a-8217-8130a4a047f3');

        const tileId = `${aliasName}-${index}`;
        const isBeingDragged = draggedId === tileId;

        linkTileIds.push(tileId);

        if (viewType === 'listview') {
            return (
                <div className={padListItems ? 'link-tile-update-container' : 'linkTile-container'}>
                    <LinkTileListForm
                        key={tileId}
                        tileId={tileId}
                        alias={alias}
                        index={index}
                        updateAliasItem={updateAliasItem}
                        deleteAliasItem={deleteAliasItem}
                    />
                </div>
            );
        }
        return (
            <LinkTile
                key={tileId}
                tileId={tileId}
                alias={alias}
                aliasItem={aliasItem}
                item={item}
                card={card}
                group={group}
                chapter={chapter}
                src={src}
                backgroundStyle={backgroundStyle}
                srcSet={srcSet}
                updateAliasItem={updateAliasItem}
                deleteAliasItem={deleteAliasItem}
                disabled={disabled}
                currentImage={mediaThumb}
                isBeingDragged={isBeingDragged}
                inEditMode={inEditMode}
            />
        );
    });

    function handleDragEnd(event) {
        const oldIndex = event.active?.data.current.sortable.index;
        const newIndex = event.over?.data.current.sortable.index;

        if (oldIndex !== newIndex && newIndex !== undefined) {
            const updatedAliases = arrayMove(aliases, oldIndex, newIndex);

            // handleChange expects an event, so we're gonna fake one in order to update the aliases
            const fakeEvent = {};
            fakeEvent.target = { name: 'aliases', value: updatedAliases };

            handleChange(fakeEvent);
        }

        setDraggedId(null);
    }

    function handleDragStart(event) {
        setDraggedId(event.active.id);
    }

    return (
        <DndContext
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            sensors={dragAndDropSensors}
        >
            <SortableContext items={linkTileIds} strategy={rectSortingStrategy}>
                {linkTiles}
            </SortableContext>
        </DndContext>
    );
};

export default EditableLinkTiles;
