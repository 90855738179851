import jwt from 'jsonwebtoken';
import didkit from '@learncard/didkit-plugin/dist/didkit/didkit_wasm_bg.wasm';
import getTorusClient from '@startup/client/torus/init';
import { initLearnCard } from '@learncard/init';
import { config } from '@core/config/getConfig';

import { isSkill, isSubskill } from '@helpers/lego/skill.helpers';
import { legoStockBadgeImages } from '@core/utilities/constants/defaults';

import { VerifiableCredentialState } from '@core/types/Credential';

export const getWalletUsingToken = async (token: string) => {
    const torus = await getTorusClient();

    const userId = jwt.decode(token, { algorithm: 'RS256' })?.sub;

    try {
        const torusInfo = await torus.getTorusKey(
            config.public?.torus?.verifier,
            userId,
            { verifier_id: userId },
            token
        );

        const ceramicIDXconfig = {
            modelData: {
                definitions: config?.public.ceramic.definitions,
                schemas: config?.public.ceramic.schemas,
                tiles: {},
            },
            credentialAlias: 'MyVerifiableCredentials',
            ceramicEndpoint: config?.public?.ceramic?.nodeEndpointUrl,
            defaultContentFamily: config?.public.ceramic?.contentFamily,
        };

        const wallet = await initLearnCard({
            seed: torusInfo?.privateKey,
            ceramicIdx: ceramicIDXconfig,
            didkit,
        });

        return wallet;
    } catch (e) {
        console.error('Error getting Torus key:', e);
    }

    return undefined;
};

export const constructVerifiableCredential = (
    credentialInfo: VerifiableCredentialState,
    issuerDid: string,
    issueToDid: string,
    issuerName: string
) => {
    const { title, description, image, skills, subskills } = credentialInfo;
    const tag = [...skills, ...subskills];

    const credential = {
        '@context': [
            'https://www.w3.org/2018/credentials/v1',
            'https://purl.imsglobal.org/spec/ob/v3p0/context.json',
        ],
        id: 'http://example.com/credentials/3527',
        type: ['VerifiableCredential', 'OpenBadgeCredential'],
        issuer: { id: issuerDid, name: issuerName },
        issuanceDate: new Date().toISOString(),
        name: title,
        credentialSubject: {
            id: issueToDid,
            type: ['AchievementSubject'],
            achievement: {
                id: 'https://example.com/achievements/21st-century-skills/teamwork',
                type: ['Achievement'],
                description,
                name: title,
                tag,
                image,
            },
        },
    };

    return credential;
};

export const convertTagsToSkills = (tags: string[]) => {
    const skills = [];
    const subskills = [];

    tags.forEach(tag => {
        if (isSkill(tag)) skills.push(tag);
        else if (isSubskill(tag)) subskills.push(tag);
    });

    return { skills, subskills };
};

export const isLegoBadgeImage = (imageUrl: string) => {
    return legoStockBadgeImages.includes(imageUrl);
};
