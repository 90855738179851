import TorusSdk from '@toruslabs/customauth';
import { config } from '@core/config/getConfig';
import MeteorProxy from '~/meteor-proxy';

import getLogger from '@core/logger';

const logger = getLogger(module);

let torus: TorusSdk;

const init = async () => {
    try {
        logger.info(
            'Setting up Torus client',
            MeteorProxy.absoluteUrl('serviceworker/', {
                rootUrl: config.public.root_url,
                server: false,
                secure: true,
            })
        );

        torus = new TorusSdk({
            baseUrl: MeteorProxy.absoluteUrl('serviceworker/', {
                rootUrl: config.public.root_url,
                server: false,
                secure: true,
            }),
            network: config.public?.torus?.network,
            // enableLogging: false,  // TODO this should be a config value.
        });
        await torus.init();

        logger.debug('Finished initializing torus client', torus);
        return torus;
    } catch (error) {
        logger.error(error);
    }
};

init();

const getTorusClient = async () => {
    return torus || init();
};

export default getTorusClient;
