import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { initLearnCard } from '@learncard/init';

import VcCheckmark from '@assets/lego/vc-check.svg';
import VerifiableCredentialFrontDetails from '@components/lego/modals/VerifiableCredentialFrontDetails';
import VerifiableCredentialBackDetails from '@components/lego/modals/VerifiableCredentialBackDetails';
import LeftArrow from '@assets/lego-fat-arrow-left.svg';
import FitText from '@components/generic/FitText';
import RibbonEnd from '@web/ui/dsc/svgs/lego/RibbonEnd';

import type { VC } from '@learncard/types';
import { VerifiableCredentialInfo } from '@core/types/Credential';

type VerifiableCredentialModalProps = {
    credentialInfo: VerifiableCredentialInfo;
    vc?: VC;
};

const VerifiableCredentialModal: React.FC<VerifiableCredentialModalProps> = ({
    credentialInfo,
    vc,
}) => {
    const isPreview = !vc;

    const [isFront, setIsFront] = useState(true);
    const [statusText, setStatusText] = useState(isPreview ? 'Sample Preview' : '...');
    const [verificationResults, setVerificationResults] = useState(isPreview ? undefined : []);
    const [headerHeight, setHeaderHeight] = useState(44); // 44 is the height if the header is one line
    const [headerWidth, setHeaderWidth] = useState(0);

    const headerRef = useRef(null);

    const verifyCredential = async () => {
        const emptyWallet = await initLearnCard();

        const verification = await emptyWallet.invoke.verifyCredential(vc, {}, true);

        let verificationText = 'Success';
        verification.forEach(v => {
            if ((v.status === 'Error' && verificationText === 'Success') || v.status === 'Failed') {
                verificationText = v.status;
            }
        });

        setStatusText(verificationText);
        setVerificationResults(verification);
    };

    useEffect(() => {
        if (!isPreview) {
            verifyCredential();
        }
    }, []);

    useLayoutEffect(() => {
        // Needs a small setTimeout otherwise it'll be wrong sometimes with multiline header.
        //   Probably because of the interaction with FitText
        setTimeout(() => {
            setHeaderHeight(headerRef.current.clientHeight);
            setHeaderWidth(headerRef.current.clientWidth);
        }, 10);
    });

    let statusColor = '';
    switch (statusText) {
        case '...':
        case 'Sample Preview':
            statusColor = '#828282';
            break;
        case 'Success':
            statusColor = '#39B54A';
            break;
        case 'Failed':
            statusColor = '#D01012';
            break;
        case 'Error':
            statusColor = '#FFBD06';
            break;
        default:
            statusColor = '#000000';
    }

    return (
        <section className="vc-modal-content z-10 overflow-visible">
            <RibbonEnd
                side="left"
                className="absolute left-[-30px] top-[50px] z-0"
                height={(headerHeight + 10).toString()}
            />
            <RibbonEnd
                side="right"
                className="absolute right-[-30px] top-[50px] z-0"
                height={(headerHeight + 10).toString()}
            />
            <h1
                ref={headerRef}
                className="mt-[40px] overflow-visible px-[20px] py-[10px] leading-[100%]"
                style={{ wordBreak: 'break-word' }}
            >
                {!isFront && (
                    <button
                        className="absolute left-[-22px]"
                        style={{ top: `${headerHeight / 2 - 18}px` }}
                        role="button"
                        onClick={() => setIsFront(true)}
                    >
                        <img
                            src={LeftArrow}
                            alt="Back"
                            className="h-[36px] w-[36px] rounded-[40px] border-[3px] border-solid border-white bg-[#2F80ED] px-[4px]"
                        />
                    </button>
                )}
                <FitText
                    text={credentialInfo.title}
                    width={((headerWidth ?? 290) - 40).toString()}
                    options={{ maxSize: 32, minSize: 20, multiLine: true }}
                />
            </h1>
            <div className="vc-info" style={{ paddingTop: headerHeight + 70 }}>
                {isFront && <VerifiableCredentialFrontDetails credential={credentialInfo} />}
                {!isFront && (
                    <VerifiableCredentialBackDetails
                        credentialInfo={credentialInfo}
                        verificationResults={verificationResults}
                    />
                )}
            </div>
            <footer>
                <img src={VcCheckmark} alt="checkmark" />
                <div className="footer-words-wrapper">
                    <span>Verified Credential</span>
                    <span className="vc-status-text" style={{ color: statusColor }}>
                        {statusText}
                    </span>
                </div>
                <button
                    role="button"
                    className={`footer-switch-button ${isFront ? 'info' : 'award'}`}
                    onClick={() => setIsFront(!isFront)}
                />
            </footer>
        </section>
    );
};

export default VerifiableCredentialModal;
