import React from 'react';
import { useSearchNetworkQuery } from '@shared/welibrary-graphql/user/queries.hook';

/**
 * Hook for searching users within network
 * @param {string} searchQuery -  search term, e.g. Tom
 * @param {boolean} includeSelf -  whether to include current user in search results
 * @param {boolean} includeTemp -  whether to include temp accounts in search results
 */

const usePeopleSearchQuery = (searchQuery, includeSelf = false, includeTemp = false) => {
    const {
        data: searchNetworkData,
        loading: searchNetworkLoading,
        error: searchNetworkError,
    } = useSearchNetworkQuery({
        variables: { searchQuery, includeSelf, includeTemp },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !searchQuery,
    });

    const foundUsers = searchNetworkData?.listUsers;

    return {
        foundUsers,
        searchNetworkLoading,
        searchNetworkError,
    };
};

export default usePeopleSearchQuery;
