import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getWhitelabelRedirects } from '@core/utilities/whitelabel_helpers';

import Lock from '@web/ui/dsc/svgs/Lock';

const localNamespace = 'imports.wlWeb.ui.components.group.groupDashboard';

interface ILockedGroup {
    lockDescription?: React.ReactNode;
    extraButton?: React.ReactNode;
}

const LockedGroup: React.FC<ILockedGroup> = ({ lockDescription, extraButton }) => {
    const { t } = useTranslation();

    const { root_redirect_loggedin } = getWhitelabelRedirects();

    return (
        <div className="private-content-wall">
            <div className="lock-content">
                <div className="lock-image">
                    <Lock />
                </div>
                <p className="lock-desc">
                    {lockDescription ||
                        t(`common:${localNamespace}.private_group`, 'This is a private group')}
                </p>
                {extraButton}
                <Link
                    to={root_redirect_loggedin}
                    className="button callout-button top-margin w-button"
                >
                    {t(`common:global.error.take_me_home`, 'Take Me Home')}
                </Link>
            </div>
        </div>
    );
};

export default LockedGroup;
