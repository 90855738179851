// src/signature/queries.ts
import { gql } from "@apollo/client";
var GET_FONT_OPTIONS = gql`
  query GetFontOptions {
    getFontOptions
  }
`;
export {
  GET_FONT_OPTIONS
};
