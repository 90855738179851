import React from 'react';
import { useTranslation } from 'react-i18next';

import useSearchForm from '@web/ui/components/search/form/hooks/useSearchForm';

import SearchFormFilterTag from '@web/ui/components/search/form/SearchFormFilterTag';
import SearchInput from '@web/ui/components/search/SearchInput';
import SearchLoader from '@web/ui/components/search/SearchLoader';

import { FILTERS_META } from '@web/ui/components/search/constants';

import { Maybify } from '@core/types/Utilities';
import { Entity } from '@core/types/Search';
import { AliasHandle } from '@shared/welibrary-graphql/types';

type SearchFormProps = {
    title: string;
    addResult: (result: Entity) => void;
    suggestionsFilter?: Array<Maybify<AliasHandle> | null>;
    filters?: Array<keyof typeof FILTERS_META>;
    allSuggestions?: Array<keyof Omit<typeof FILTERS_META, 'ALL'>>;
};

const SearchForm: React.FC<SearchFormProps> = ({
    title,
    addResult,
    suggestionsFilter = [],
    filters = ['All', 'Channels', 'Groups', 'Profiles', 'Cards'],
    allSuggestions = ['Groups', 'Channels'],
}) => {
    const { t } = useTranslation();

    const {
        query,
        setQuery,
        filter,
        setFilter,
        emptyResults,
        emptyState,
        areResults,
        showSuggestions,
        loading,
        results,
        suggestions,
    } = useSearchForm({ addResult, suggestionsFilter, filters, allSuggestions });

    const searchLoaderStyle = { position: 'relative', marginBottom: '20px' } as const;

    const tags = filters.map(type => (
        <SearchFormFilterTag filter={filter} setFilter={setFilter} type={type} />
    ));

    return (
        <section className="search-form">
            <header>{title}</header>

            <SearchInput
                isSearchOverlayOpen
                onChange={setQuery}
                value={query}
                placeholder="Search"
            />

            <section className="tabs disable-scrollbars">{tags}</section>

            {showSuggestions ? (
                <>
                    <h2 className="quick-links-suggested">
                        {t('common:imports.wlWeb.ui.components.search.suggested')}
                    </h2>
                    <ul>{suggestions}</ul>
                </>
            ) : (
                <SearchLoader
                    customStyle={searchLoaderStyle}
                    emptyResults={emptyResults}
                    size={180}
                    emptyState={emptyState}
                    areResults={areResults}
                    loading={loading}
                />
            )}

            <ul>{results}</ul>
        </section>
    );
};

export default SearchForm;
