import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';

import { useCurrentUser } from '@stores/User';

import useEditThreadParticipants from '@components/messaging/thread/hooks/useEditThreadParticipants';
import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import SuggestedList from '@components/messaging/messageOverlay/SuggestedList';

import X from '@web/ui/dsc/svgs/X';

import MessageThreadActions from '@core/utilities/constants/thread';
import { UserParticipant, Maybe } from '@shared/welibrary-graphql/types';

const ContactsForm: React.FC<{
    state: Maybe<UserParticipant>[];
    setState: (newParticipants: Maybe<UserParticipant>[]) => void;
    onSearch?: (value: boolean) => void;
}> = ({ state, setState, onSearch }) => {
    const { currentUser } = useCurrentUser();
    const { t } = useTranslation();
    const isNativeApp = Capacitor?.isNativePlatform();

    const { searchQuery, setSearchQuery, filteredUsers, addUserToThread, removeUserFromThread } =
        useEditThreadParticipants(state, setState);

    const messageUserList = state?.reduce((acc, contact, index) => {
        if (contact?.user._id !== currentUser?._id) {
            return [
                ...acc,
                <button
                    type="button"
                    id={contact?.user._id}
                    key={index}
                    onClick={removeUserFromThread}
                    className="new-message-contact w-inline-block list-item"
                >
                    {contact?.user?.profile?.full_name}
                </button>,
            ];
        }
        return acc;
    }, []);

    const handleResetSearch = () => {
        setSearchQuery('');
    };

    const screenWidth = window?.innerWidth;

    // Hide bottom mobile nav because software keyboard comes up and it takes alot of space
    const showBottomNav = (screenWidth < 480 || isNativeApp) && searchQuery?.trim() === '';
    const hideBottomNav = (screenWidth < 480 || isNativeApp) && searchQuery?.trim() !== '';

    useEffect(() => {
        if (showBottomNav) onSearch?.(false);
        if (hideBottomNav) onSearch?.(true);
    }, [searchQuery]);

    const emptySearchQuery = searchQuery?.trim() === '';

    return (
        <ErrorBoundary>
            <section className="form-container">
                <div className="search-field-container">
                    <div className="search-inbox-container">
                        <section
                            id="search-contacts-form"
                            data-name="Search Contacts Form"
                            className="search-field-container"
                        >
                            <input
                                onChange={e => setSearchQuery(e.currentTarget.value)}
                                onKeyPress={e => {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                value={searchQuery}
                                autoComplete="off"
                                type="text"
                                className="search-field w-input inbox-search-input"
                                maxLength={256}
                                name="new-message-contact-search"
                                placeholder={`${t(
                                    'common:global.search_to_add_user',
                                    'Search to add a user'
                                )}...`}
                                id="Search-to-add-user"
                                // eslint-disable-next-line jsx-a11y/no-autofocus
                                autoFocus
                            />

                            {state?.length > 0 && emptySearchQuery && (
                                <section className="message-user-list">{messageUserList}</section>
                            )}

                            <button
                                type="button"
                                onClick={handleResetSearch}
                                className={
                                    searchQuery?.trim() !== ''
                                        ? 'clear-search-btn'
                                        : 'clear-search-btn hidden'
                                }
                            >
                                <X />
                            </button>
                        </section>
                    </div>
                </div>
                {(state?.length === 0 || !emptySearchQuery) && (
                    <SuggestedList
                        handleClick={addUserToThread}
                        suggestType={MessageThreadActions.ADD_TO_MESSAGE}
                        suggestions={filteredUsers}
                        searchQuery={searchQuery}
                    />
                )}
            </section>
        </ErrorBoundary>
    );
};

export default ContactsForm;
