import React from 'react';

import useModal from '@web/ui/components/modals/hooks/useModal';
import { useTranslation } from 'react-i18next';
import { useCreateOrderRefundMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import { getOrderTotalDisplayString } from '@components/card/Shopping-Cart/CheckoutHelpers';

import { GET_ORDERS_FOR_GROUP } from '@shared/welibrary-graphql/user/queries';
import { CustomerOrder } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

type RefundOrderModalProps = {
    order: CustomerOrder;
    onComplete: () => void;
};

const RefundOrderModal: React.FC<RefundOrderModalProps> = ({ order, onComplete }) => {
    const { t } = useTranslation();
    const { closeModal } = useModal();

    const [createOrderRefund, { loading, error }] = useCreateOrderRefundMutation();

    const issueRefund = () => {
        createOrderRefund({
            variables: {
                paymentIntentId: order?.paymentIntent?.id,
            },
            refetchQueries: [GET_ORDERS_FOR_GROUP],
        })
            .then(() => {
                closeModal();
                onComplete();
            })
            .catch(err => {
                logger.error(err);
            });
    };

    const orderTotal = getOrderTotalDisplayString(order)

    return (
        <div className="flex flex-col gap-[40px] px-[30px] py-[40px]">
            <div className="flex flex-col gap-[20px]">
                <h1 className="text-grayscale-title-active m-0 text-center text-[24px] font-[700] leading-[32px] tracking-[1px]">
                    {t('common:global.are_you_sure', 'Are you sure?')}
                </h1>
                <p className="text-grayscale-body m-0 text-center text-[17px] leading-[24px] tracking-[0.75px]">
                    {t(
                        'common:global.refund_info_paragraph',
                        'By processing this refund, the amount will be returned to the original payment method and an email will be sent to inform them of the refund. This action cannot be undone.'
                    )}
                </p>
            </div>

            <p className="text-grayscale-body m-0 text-center text-[17px] leading-[24px] tracking-[0.75px]">
                {t('common:global.refund_total', 'Refund Total')} {orderTotal}
            </p>

            {error?.message && (
                <p className="text-error-default m-0 text-center text-[16px] font-[500] leading-[24px] tracking-[0.75px]">
                    {error.message}
                </p>
            )}

            <div className="flex flex-col gap-[20px]">
                <button
                    disabled={loading}
                    onClick={issueRefund}
                    className="bg-primary-default text-grayscale-background w-full rounded-full py-[8px] text-[16px] font-[600] leading-[175%] tracking-[0.75px] disabled:opacity-70"
                >
                    {loading
                        ? t('common:global.issuing_refund', 'Issuing Refund...')
                        : t('common:global.refund', 'Refund')}
                </button>
                <button
                    onClick={closeModal}
                    className="text-grayscale-label text-[17px] font-[600] leading-[28px] tracking-[0.75px]"
                >
                    {t('common:global.cancel', 'Cancel')}
                </button>
            </div>
        </div>
    );
};

export default RefundOrderModal;
