var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/user/queries.hook.tsx
import { gql as gql7 } from "@apollo/client";

// src/user/fragments.hook.tsx
import { gql as gql5 } from "@apollo/client";

// src/organization/fragments.hook.tsx
import { gql } from "@apollo/client";
var OrganizationFragmentFragmentDoc = gql`
    fragment OrganizationFragment on Organization {
  __typename
  _id
  name
  created
  createdBy {
    _id
    profile {
      full_name
    }
  }
  updated
}
    `;

// src/content_card/fragments.hook.tsx
import { gql as gql4 } from "@apollo/client";

// src/comment/fragments.hook.tsx
import { gql as gql2 } from "@apollo/client";
var CommentFragmentFragmentDoc = gql2`
    fragment CommentFragment on Comment {
  __typename
  _id
  publish
  stream {
    __typename
    _id
  }
  creator {
    __typename
    _id
    profile {
      picture
      full_name
    }
  }
  body
  loggedInUserCanEdit
  childCount
  parentId
}
    `;

// src/tag/fragments.hook.tsx
import { gql as gql3 } from "@apollo/client";
var TagFragmentFragmentDoc = gql3`
    fragment TagFragment on Tag {
  __typename
  _id
  label
  color
  created
  updated
}
    `;

// src/content_card/fragments.hook.tsx
var ContentCardSearchFragmentFragmentDoc = gql4`
    fragment ContentCardSearchFragment on ContentCard {
  __typename
  _id
  url
  type
  title
  body
  description
  media {
    icon
    thumb
  }
  prompt
  isBookmarked
  isArchived
}
    `;
var ContentCardCourseSearchFragmentFragmentDoc = gql4`
    fragment ContentCardCourseSearchFragment on ContentCard {
  __typename
  _id
  url
  type
  title
  body
  description
  media {
    icon
    thumb
  }
  prompt
  courseItems {
    levels {
      title
      media
      resource
    }
  }
  isBookmarked
  isArchived
}
    `;
var CardCommentsFragmentFragmentDoc = gql4`
    fragment CardCommentsFragment on ContentCard {
  __typename
  _id
  comments {
    ...CommentFragment
  }
}
    ${CommentFragmentFragmentDoc}`;
var ContentCardPlatformFragmentFragmentDoc = gql4`
    fragment ContentCardPlatformFragment on ContentCard {
  _id
  __typename
  vs
  type
  url
  platformSettings {
    platformGroupOwner
    forms {
      formCardUrl
      formSpecificationSettings {
        type
        form {
          fieldGroups {
            groupName
            index
            fieldIds
          }
          required
          fields {
            index
            id
            type
            options
            prompt
            defaultComparator
            customText
            selectMultiple
            siblingId
            allowOther
            required
            showOnSignup
            groups {
              group {
                _id
                path
                groupActionsAllowed
                currentUserIsMember
                currentUserIsAdmin
                profile {
                  picture
                  full_name
                  short_bio
                }
                settings {
                  approvalRequired
                  protectionLevel
                }
              }
              id
              requireApproval
            }
            placeholder
            dependsOn {
              id
              values
            }
          }
        }
      }
    }
  }
}
    `;
var ContentCardBookSourceFragmentFragmentDoc = gql4`
    fragment ContentCardBookSourceFragment on ContentCard {
  __typename
  _id
  url
  title
  library_settings {
    displayReference
    protectionLevel
    accessRoleWhitelist
  }
  reference {
    groupSource {
      profile {
        full_name
      }
      settings {
        protectionLevel
      }
    }
  }
}
    `;
var ContentCardPledgesFragmentFragmentDoc = gql4`
    fragment ContentCardPledgesFragment on PledgeItems {
  __typename
  _id
  pledges {
    __typename
    _id
    label
    reportingType
    canPledge
    canDeletePledge
    totalPledges
    currentUsersPledges
    currentUsersPledgesMonth: currentUsersPledges(daysAgo: 30)
    currentUsersPledgesDay: currentUsersPledges(daysAgo: 1)
    successfulPledgesAll: currentUsersSuccessfulPledges
    successfulPledgesMonth: currentUsersSuccessfulPledges(daysAgo: 30)
    successfulPledgesDay: currentUsersSuccessfulPledges(daysAgo: 1)
    hidden
  }
  pledgeKeys {
    __typename
    _id
    label
    refactoryPeriod
    limitPerRefractoryPeriod
    description
    reportingType
    hidden
  }
  pledgers {
    __typename
    _id
    profile {
      full_name
      first_name
      picture
    }
  }
  latestPledgerEngagement {
    userId
    date
  }
  totalPledges
  totalPledgers
  pledgesLastDay: totalPledges(daysAgo: 1)
  pledgesLastMonth: totalPledges(daysAgo: 30)
}
    `;
var RevealCardFragmentFragmentDoc = gql4`
    fragment RevealCardFragment on ContentCard {
  __typename
  type
  links {
    source
    other
  }
  media {
    thumb
    icon
    logoLight
    logoDark
  }
}
    `;
var UpdateFeatureConstraintsFragmentsFragmentDoc = gql4`
    fragment UpdateFeatureConstraintsFragments on ContentCard {
  __typename
  updateFeatureConstraints {
    canEditAllFields
    featuresUserCanEdit {
      type
      field
      options
      canUpdate
    }
  }
}
    `;
var MobilizeEffortFragmentFragmentDoc = gql4`
    fragment MobilizeEffortFragment on ContentCard {
  __typename
  _id
  path
  url
  shortCode
  type
  title
  body
  description
  prompt
  rankOrder
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
      loggedInUserConnectionStatus
    }
    roles {
      isSpeakerOf
    }
  }
  date {
    hide_display
    last_update
    publish
  }
  links {
    source
    other
  }
  aliases {
    ... on GroupAliasHandle {
      url
      title
      color
      type
      group {
        __typename
        _id
        profile {
          full_name
          picture
          coverPhoto
        }
      }
    }
    ... on AliasItem {
      url
      title
      color
      type
      item {
        _id
        __typename
        title
        body
        protectionLevel
        prompt
        author {
          __typename
          _id
          type
          profile {
            full_name
          }
        }
        reference {
          href
          coverImage
          bookSource {
            ...ContentCardBookSourceFragment
          }
        }
        media {
          thumb
          icon
        }
        library_settings {
          postTypes
          engagementTypes
          filterTypes
          defaultFilter
          viewTypes
          defaultViewType
          allowPostbox
          displaySocialHeaders
          allowLabels
          displayReference
          displayInNewsfeed
          userCanPost
        }
        pledgeItems {
          ...ContentCardPledgesFragment
        }
        whitelabelVerbage {
          __typename
          _id
          value
        }
        isArchived
        userHasPledgedTo
        taskHistory {
          __typename
          cursor
          hasMore
          results {
            pledgeKeyId
            label
            refactoryPeriod
            date
            supplement {
              note
              image
              document
              documentTitle
            }
            verifier {
              name
              email
              phone
              country
              note
              newRecruit
            }
            claimedAccount {
              __typename
              _id
              profile {
                picture
                full_name
                email
                phone
                country
              }
            }
          }
          type
        }
      }
    }
  }
  media {
    thumb
    icon
    logoLight
    logoDark
    favicon
    webclip
  }
  loggedInUserCanEdit
  defaultHidden
  isBookmarked
  isArchived
  applicationItems {
    applications {
      __typename
      _id
      createdAt
      status
      data {
        values {
          id
          values
        }
      }
      user {
        __typename
        _id
        profile {
          full_name
          picture
          country
          website
          email
        }
      }
    }
  }
  postingSettings {
    type
    status
    totalPositionsCount
    acceptedCount
    specification {
      url
    }
    postingData {
      parent {
        url
      }
      values {
        id
        type
        customText
        comparator
        values
        siblingId
      }
    }
    requirementsData {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
  tags {
    __typename
    hasMore
    cursor
    type
    results {
      ...TagFragment
    }
  }
  loggedInUserApplyStatus
  isTemplate
  ...UpdateFeatureConstraintsFragments
}
    ${ContentCardBookSourceFragmentFragmentDoc}
${ContentCardPledgesFragmentFragmentDoc}
${TagFragmentFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardCourseFragmentFragmentDoc = gql4`
    fragment ContentCardCourseFragment on CourseItems {
  materials
  ageGroup
  duration
  people
  levels {
    title
    media
    video
    resource
    steps {
      text
      useImage
      image
      useVideo
      video
      fullPage
      pageBreak
      skill
      skillSubtype
      skillMilestone
    }
    preSurvey {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
    postSurvey {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
  }
  publicCompletions
}
    `;
var ContentCardCreationFragmentFragmentDoc = gql4`
    fragment ContentCardCreationFragment on CreationItems {
  silhouetteImage
  course {
    _id
    media {
      icon
      thumb
    }
    title
    courseItems {
      materials
      ageGroup
      duration
      people
      levels {
        title
        media
        resource
        preSurvey {
          _id
          type
          subtype
          prompt
          useImage
          image
          range
          answers
          correctAnswer
          skill
          skillSubtype
          skillMilestone
        }
        postSurvey {
          _id
          type
          subtype
          prompt
          useImage
          image
          range
          answers
          correctAnswer
          skill
          skillSubtype
          skillMilestone
        }
      }
    }
    url
  }
  levels {
    title
    description
    image
  }
}
    `;
var CardRatingsFragmentDoc = gql4`
    fragment CardRatings on ContentCard {
  ratings_sentiment_score
  latest_rating_sentiments {
    _id
    profile {
      full_name
    }
  }
  ratings_quality_score
  ratings_relevance_score {
    score
    hashtag
  }
  ratings_rank
  loggedInUserVotes {
    sentiment
    quality
    relevance
  }
}
    `;
var ContentCardCustomFieldsFragmentFragmentDoc = gql4`
    fragment ContentCardCustomFieldsFragment on ContentCard {
  _id
  customFields {
    manager
    parent {
      url
    }
    parentGroup {
      _id
    }
    values {
      id
      type
      comparator
      values
      siblingId
    }
  }
}
    `;
var ContentCardFragmentFragmentDoc = gql4`
    fragment ContentCardFragment on ContentCard {
  __typename
  _id
  vs
  path
  url
  shortCode
  type
  title
  body
  shortTitle
  description
  prompt
  commentCount
  latestComments {
    cursor
    hasMore
    type
    results {
      _id
      creator {
        _id
        profile {
          full_name
          picture
        }
      }
      body
    }
  }
  inheritSurveyAnswers
  rankOrder
  subtype
  pinnedCardIds
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
      loggedInUserConnectionStatus
      did
    }
    roles {
      isSpeakerOf
    }
  }
  date {
    hide_display
    last_update
    publish
  }
  links {
    title
    source
    openInModal
    other
    publisher
  }
  aliases {
    ... on GroupAliasHandle {
      url
      title
      color
      thumb
      icon
      type
      group {
        __typename
        _id
        subtype
        isUnpublished
        profile {
          full_name
          picture
          coverPhoto
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        groupActionsAllowed
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
      }
    }
    ... on MessageThreadAliasHandle {
      url
      title
      color
      thumb
      icon
      type
      thread {
        __typename
        _id
        displayName
        displayIcon
        created
        updated
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
      }
    }
    ... on AliasItem {
      url
      title
      color
      thumb
      icon
      type
      item {
        subtype
        __typename
        _id
        title
        shortTitle
        prompt
        vote_settings {
          QUALITY {
            active
          }
          RELEVANCE {
            active
          }
          SENTIMENT {
            active
          }
        }
        type
        author {
          __typename
          _id
          type
          profile {
            full_name
          }
        }
        reference {
          href
          coverImage
          shelfSource {
            title
            url
            library_settings {
              displayReference
              protectionLevel
            }
          }
          bookSource {
            ...ContentCardBookSourceFragment
          }
          groupSource {
            _id
            subtype
            profile {
              full_name
              picture
              coverPhoto
            }
          }
        }
        media {
          thumb
          icon
        }
        library_settings {
          postTypes
          engagementTypes
          filterTypes
          defaultFilter
          viewTypes
          defaultViewType
          allowPostbox
          displaySocialHeaders
          allowLabels
          displayReference
          protectionLevel
          displayInNewsfeed
          userCanPost
        }
        pledgeItems {
          ...ContentCardPledgesFragment
        }
        whitelabelVerbage {
          __typename
          _id
          value
        }
        isArchived
        userHasPledgedTo
        taskHistory {
          __typename
          cursor
          hasMore
          results {
            pledgeKeyId
            label
            refactoryPeriod
            date
            supplement {
              note
              image
              document
              documentTitle
            }
            verifier {
              name
              email
              phone
              country
              note
              newRecruit
            }
            claimedAccount {
              __typename
              _id
              profile {
                picture
                full_name
                email
                phone
                country
              }
            }
          }
          type
        }
      }
    }
  }
  media {
    thumb
    icon
    logoLight
    logoDark
    favicon
    webclip
    mediaMetaData {
      height
      width
    }
  }
  label {
    text
    type
  }
  formSpecificationSettings {
    type
    postingForm {
      required
      fieldGroups {
        groupName
        fieldIds
        index
      }
      fields {
        index
        type
        id
        options
        customText
        prompt
        placeholder
        type
        dependsOn {
          id
          values
        }
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
      }
    }
    applicationForm {
      required
      fieldGroups {
        groupName
        fieldIds
        index
      }
      fields {
        index
        type
        id
        options
        customText
        prompt
        placeholder
        type
      }
    }
    form {
      required
      fieldGroups {
        groupName
        fieldIds
        index
      }
      fields {
        index
        type
        id
        options
        customText
        prompt
        placeholder
        type
      }
    }
  }
  library_settings {
    postTypes
    engagementTypes
    filterTypes
    viewTypes
    defaultViewType
    defaultFilter
    allowPostbox
    postboxRoleWhiteList
    userCanPost
    displaySocialHeaders
    displayInNewsfeed
    showCreateChildren
    showResourceHeader
    allowLabels
    displayReference
    protectionLevel
    accessRoleWhitelist
    memberStyle
    customSignupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      showOnSignup
      groups {
        group {
          _id
          path
          groupActionsAllowed
          currentUserIsMember
          currentUserIsAdmin
          profile {
            picture
            full_name
            short_bio
          }
          settings {
            approvalRequired
            protectionLevel
          }
        }
        id
        requireApproval
      }
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
    dashboardContentItems {
      name
      url
      type
      filter
    }
    customRoles {
      name
      url
      color
    }
    sidebarMenu {
      name
      url
    }
    recommendations
  }
  sendNotifications
  vote_settings {
    QUALITY {
      active
    }
    RELEVANCE {
      active
    }
    SENTIMENT {
      active
    }
  }
  meta {
    course {
      revealType
      revealContent {
        ...RevealCardFragment
      }
      compensation
      time
      sequence
    }
    research {
      category
      journalName
      organization
      publicationType
      abstract
      authors
      fileURL
      tags
      issue
    }
    mobilize {
      date
      qualifications
      skills
      summary
      city
      state
      country
      cta
      goalnumber
      hours
      matchterm
      pledge
      timeCommit
      pertime
    }
    pledge {
      date
      qualifications
      skills
      summary
      city
      state
      country
      cta
      goalnumber
      hours
      matchterm
      pledge
      timeCommit
      pertime
    }
  }
  loggedInUserCanEdit
  currentUserHasRead
  defaultHidden
  isBookmarked
  isArchived
  tags {
    __typename
    hasMore
    cursor
    type
    results {
      ...TagFragment
    }
  }
  isTemplate
  postingSettings {
    type
    status
    totalPositionsCount
    acceptedCount
    specification {
      url
    }
    postingData {
      parent {
        url
      }
      values {
        id
        type
        customText
        comparator
        values
        siblingId
      }
    }
    requirementsData {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
  mobilizationSettings {
    approvalType
    status
    efforts {
      type
      card {
        __typename
        library_settings {
          postTypes
          engagementTypes
          filterTypes
          defaultFilter
          viewTypes
          defaultViewType
          allowPostbox
          displaySocialHeaders
          allowLabels
          displayReference
          displayInNewsfeed
        }
        ...MobilizeEffortFragment
      }
      status
      country
      countGoal
      count
    }
  }
  courseItems {
    ...ContentCardCourseFragment
  }
  creationItems {
    ...ContentCardCreationFragment
  }
  surveyQuestions {
    _id
    type
    subtype
    prompt
    useImage
    image
    range
    answers
    correctAnswer
    skill
    skillSubtype
    skillMilestone
  }
  timeAndPlaceSettings {
    headline
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    location {
      formatted_address
      location {
        type
        coordinates
      }
      city
      country
      postal
      state
    }
    link
    rsvpLink
    allDay
    timeZone {
      name
    }
  }
  eventOverviewDetails {
    title
    description
    headline
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    location {
      formatted_address
    }
    link
    rsvpLink
    allDay
    timeZone {
      name
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
  }
  eventSettings {
    headline
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    location {
      formatted_address
    }
    link
    rsvpLink
    allDay
    timeZone {
      name
    }
  }
  eventAttendance {
    user {
      _id
      profile {
        full_name
        picture
      }
      socialActionsAllowed
    }
    status
  }
  canMembersSuggestEdits
  showTitle
  protectionLevel
  currentUserCanSuggestEdit
  verticalScrolling
  dialogSettings {
    event
    people {
      name
      image
    }
    dialogs {
      person
      text
    }
  }
  showPostTypes
  ...UpdateFeatureConstraintsFragments
  ...CardRatings
  ...ContentCardCustomFieldsFragment
}
    ${ContentCardBookSourceFragmentFragmentDoc}
${ContentCardPledgesFragmentFragmentDoc}
${RevealCardFragmentFragmentDoc}
${TagFragmentFragmentDoc}
${MobilizeEffortFragmentFragmentDoc}
${ContentCardCourseFragmentFragmentDoc}
${ContentCardCreationFragmentFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}
${CardRatingsFragmentDoc}
${ContentCardCustomFieldsFragmentFragmentDoc}`;
var ContentCardReferenceFragmentFragmentDoc = gql4`
    fragment ContentCardReferenceFragment on ContentCard {
  reference {
    href
    isResource
    coverImage
    chapterSource {
      title
      url
      prompt
      showTitle
      inheritSurveyAnswers
      subtype
      vote_settings {
        QUALITY {
          active
        }
        RELEVANCE {
          active
        }
        SENTIMENT {
          active
        }
      }
      library_settings {
        engagementTypes
        viewTypes
        defaultViewType
        displayReference
        displaySocialHeaders
      }
      media {
        thumb
      }
    }
    bookSource {
      ...ContentCardBookSourceFragment
    }
    shelfSource {
      title
      url
      library_settings {
        displayReference
        protectionLevel
      }
    }
    librarySource {
      title
      url
      library_settings {
        displayReference
      }
    }
    groupSource {
      _id
      subtype
      profile {
        full_name
        picture
        coverPhoto
      }
      settings {
        shortCode
        shortCodeApprovalRequired
        protectionLevel
        approvalRequired
        formSpec {
          url
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      groupActionsAllowed
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
      currentUserIsAdmin
      currentUserIsDirectAdmin
      currentUserIsMember
    }
  }
}
    ${ContentCardBookSourceFragmentFragmentDoc}`;
var RainbowRoadFragmentFragmentDoc = gql4`
    fragment RainbowRoadFragment on ContentCard {
  __typename
  _id
  url
  vs
  type
  subtype
  title
  description
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
      loggedInUserConnectionStatus
    }
    roles {
      isSpeakerOf
    }
  }
  media {
    icon
  }
  loggedInUserCanEdit
  rainbowRoadSettings {
    header {
      color
      darkMode
      image
      backgroundImage
      showTopInfo
    }
    body {
      color
      darkMode
      image
    }
    nodes {
      circleSize
      icon
      lineStyle
      lineColor
      outline
      winding
      spinnerImage
    }
    font
    questSettings {
      creationsChannel {
        url
        title
        children {
          ...ContentCardFragment
        }
      }
      creationNodeSettings {
        icon
        backgroundImage
        showMetaInformation
        spinnerImage
      }
      completionRequirement
      dialogsChannel {
        url
        title
        children {
          ...ContentCardFragment
        }
      }
      questEndImage
      vcSettings {
        title
        name
        description
        image
      }
    }
  }
  library_settings {
    engagementTypes
  }
  nodes {
    title
    icon
    backgroundImage
    spinnerImage
    showMetaInformation
    preOrPost
    alias {
      ... on UserAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        user {
          _id
          profile {
            full_name
            picture
            coverPhoto
            full_bio
            short_bio
            website
            facebookProfile
            twitterProfile
            instagramProfile
            linkedInProfile
          }
          socialActionsAllowed
        }
      }
      ... on GroupAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        group {
          __typename
          _id
          path
          profile {
            full_name
            short_bio
            full_bio
            picture
            coverPhoto
            country
            website
            email
            customFields {
              parent {
                url
              }
              parentGroup {
                _id
              }
              manager
              values {
                id
                type
                comparator
                values
                siblingId
              }
            }
          }
          socialActionsAllowed
          shelf {
            __typename
            _id
            url
            media {
              thumb
              icon
            }
          }
          settings {
            __typename
            subscriptions
            formSpec {
              url
              formSpecificationSettings {
                type
                form {
                  required
                  fieldGroups {
                    groupName
                    fieldIds
                    index
                  }
                  fields {
                    index
                    type
                    id
                    options
                    customText
                    prompt
                    placeholder
                    type
                    selectMultiple
                    siblingId
                    defaultComparator
                    required
                    allowOther
                    dependsOn {
                      id
                      values
                    }
                  }
                }
              }
            }
            protectionLevel
            memberStyle
            dashboards {
              name
              url
              type
              filter
            }
            customSignupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
            recommendations
            canMembersSuggestEdits
            shortCode
            shortCodeApprovalRequired
            groupChatroom
            adminChatroom
            approvalRequired
            registrationTitle
            registrationDescription
            registrationActionText
            enableGroupNewsfeed
          }
          hasUnreadDashboard
          subtype
          totalMembers
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
          currentUserLastActiveDate
          groupActionsAllowed
          isTemplate
          allSignupFields {
            parentGroup {
              _id
            }
            manager
            signupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
          whitelabelVerbage {
            _id
            value
            plainText
          }
          parentGroup {
            __typename
            _id
            profile {
              full_name
            }
          }
          channelsBook {
            title
            url
            media {
              thumb
              icon
            }
            children {
              title
              url
              reference {
                href
              }
            }
          }
        }
      }
      ... on MessageThreadAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        thread {
          __typename
          _id
          participants {
            __typename
            user {
              __typename
              _id
              profile {
                __typename
                full_name
                short_bio
                full_bio
                picture
                coverPhoto
                facebookProfile
                instagramProfile
                twitterProfile
                linkedInProfile
              }
            }
            hasUnreadMessages
          }
          messages {
            __typename
            hasMore
            cursor
            type
            messages {
              __typename
              _id
              author {
                __typename
                _id
                profile {
                  __typename
                  first_name
                  full_name
                  picture
                }
              }
              text
              created
              updated
            }
          }
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
          currentUserNotificationLevel
        }
      }
      ... on AliasItem {
        url
        type
        title
        thumb
        icon
        color
        item {
          ...ContentCardFragment
          ...ContentCardReferenceFragment
        }
      }
    }
  }
  aliases {
    ... on UserAliasHandle {
      url
      type
      title
      thumb
      icon
      color
      user {
        _id
        profile {
          full_name
          picture
          coverPhoto
          full_bio
          short_bio
          website
          facebookProfile
          twitterProfile
          instagramProfile
          linkedInProfile
        }
        socialActionsAllowed
      }
    }
    ... on GroupAliasHandle {
      url
      type
      title
      thumb
      icon
      color
      group {
        __typename
        _id
        path
        profile {
          full_name
          short_bio
          full_bio
          picture
          coverPhoto
          country
          website
          email
          customFields {
            parent {
              url
            }
            parentGroup {
              _id
            }
            manager
            values {
              id
              type
              comparator
              values
              siblingId
            }
          }
        }
        socialActionsAllowed
        shelf {
          __typename
          _id
          url
          media {
            thumb
            icon
          }
        }
        settings {
          __typename
          subscriptions
          formSpec {
            url
            formSpecificationSettings {
              type
              form {
                required
                fieldGroups {
                  groupName
                  fieldIds
                  index
                }
                fields {
                  index
                  type
                  id
                  options
                  customText
                  prompt
                  placeholder
                  type
                  selectMultiple
                  siblingId
                  defaultComparator
                  required
                  allowOther
                  dependsOn {
                    id
                    values
                  }
                }
              }
            }
          }
          protectionLevel
          memberStyle
          dashboards {
            name
            url
            type
            filter
          }
          customSignupFields {
            id
            index
            type
            prompt
            customText
            options
            selectMultiple
            allowOther
            weight
            required
            link
            dependsOn {
              id
              values
            }
            defaultComparator
            siblingId
          }
          recommendations
          canMembersSuggestEdits
          shortCode
          shortCodeApprovalRequired
          groupChatroom
          adminChatroom
          approvalRequired
          registrationTitle
          registrationDescription
          registrationActionText
          enableGroupNewsfeed
        }
        hasUnreadDashboard
        subtype
        totalMembers
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
        currentUserLastActiveDate
        groupActionsAllowed
        isTemplate
        allSignupFields {
          parentGroup {
            _id
          }
          manager
          signupFields {
            id
            index
            type
            prompt
            customText
            options
            selectMultiple
            allowOther
            weight
            required
            link
            dependsOn {
              id
              values
            }
            defaultComparator
            siblingId
          }
        }
        whitelabelVerbage {
          _id
          value
          plainText
        }
        parentGroup {
          __typename
          _id
          profile {
            full_name
          }
        }
        channelsBook {
          title
          url
          media {
            thumb
            icon
          }
          children {
            title
            url
            reference {
              href
            }
          }
        }
      }
    }
    ... on MessageThreadAliasHandle {
      url
      type
      title
      thumb
      icon
      color
      thread {
        __typename
        _id
        participants {
          __typename
          user {
            __typename
            _id
            profile {
              __typename
              full_name
              short_bio
              full_bio
              picture
              coverPhoto
              facebookProfile
              instagramProfile
              twitterProfile
              linkedInProfile
            }
          }
          hasUnreadMessages
        }
        messages {
          __typename
          hasMore
          cursor
          type
          messages {
            __typename
            _id
            author {
              __typename
              _id
              profile {
                __typename
                first_name
                full_name
                picture
              }
            }
            text
            created
            updated
          }
        }
        displayName
        displayIcon
        created
        updated
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
        currentUserNotificationLevel
      }
    }
    ... on AliasItem {
      url
      type
      title
      thumb
      icon
      color
      item {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
      }
    }
  }
}
    ${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
var ViewPermissionsFragmentDoc = gql4`
    fragment ViewPermissions on ContentCard {
  currentViewPermissions {
    level
    canUserView
  }
}
    `;
var ContentCardChapterFragmentFragmentDoc = gql4`
    fragment ContentCardChapterFragment on ContentCard {
  _id
  vs
  type
  url
  title
  shortTitle
  media {
    icon
    thumb
  }
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
    }
  }
  description
  isArchived
  prompt
  loggedInUserCanEdit
  vote_settings {
    QUALITY {
      active
    }
    RELEVANCE {
      active
    }
    SENTIMENT {
      active
    }
  }
  library_settings {
    postTypes
    engagementTypes
    filterTypes
    viewTypes
    defaultViewType
    defaultFilter
    allowPostbox
    postboxRoleWhiteList
    userCanPost
    displaySocialHeaders
    displayInNewsfeed
    allowLabels
    displayReference
    tagAggregation {
      ...TagFragment
    }
    cardAggregation {
      __typename
      _id
      title
      url
      body
      media {
        thumb
        icon
      }
    }
  }
  tags {
    __typename
    hasMore
    cursor
    type
    results {
      ...TagFragment
    }
  }
  sendNotifications
  rolesAvailable {
    url
    name
  }
  ...ContentCardReferenceFragment
  rankOrder
  canMembersSuggestEdits
  currentUserCanSuggestEdit
  showTitle
  childrenCount
  showPostTypes
  inheritSurveyAnswers
  isTemplate
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${TagFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}
${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardBookFragmentFragmentDoc = gql4`
    fragment ContentCardBookFragment on ContentCard {
  _id
  vs
  type
  subtype
  url
  title
  media {
    icon
    thumb
  }
  author {
    _id
    type
    profile {
      full_name
    }
  }
  children(sort: ordered) {
    ...ContentCardChapterFragment
  }
  description
  body
  prompt
  promptEnabled
  loggedInUserFollows
  loggedInUserCanEdit
  isTemplate
  library_settings {
    displayReference
    displayInNewsfeed
    displaySocialHeaders
    protectionLevel
    accessRoleWhitelist
    defaultFilter
    filterTypes
    engagementTypes
    customRoles {
      name
      url
      color
    }
  }
  sendNotifications
  vote_settings {
    QUALITY {
      active
    }
    RELEVANCE {
      active
    }
    SENTIMENT {
      active
    }
  }
  customFields {
    parent {
      url
    }
    values {
      id
      type
      comparator
      values
      siblingId
    }
  }
  cardActionsAllowed
  rankOrder
  verticalScrolling
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${ContentCardChapterFragmentFragmentDoc}
${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardBookBigScreenFragmentFragmentDoc = gql4`
    fragment ContentCardBookBigScreenFragment on ContentCard {
  _id
  type
  url
  title
  media {
    icon
    thumb
    __typename
  }
  author {
    _id
    type
    profile {
      full_name
      __typename
    }
    __typename
  }
  library_settings {
    displaySocialHeaders
  }
  prompt
  loggedInUserFollows
  loggedInUserCanEdit
  __typename
}
    `;
var ContentCardShelfFragmentFragmentDoc = gql4`
    fragment ContentCardShelfFragment on ContentCard {
  __typename
  _id
  url
  shortCode
  type
  title
  body
  description
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
    }
  }
  group {
    __typename
    _id
    subtype
    username
    profile {
      full_name
      full_bio
      picture
    }
  }
  date {
    hide_display
    last_update
    publish
  }
  links {
    source
    other
  }
  media {
    thumb
    icon
  }
  library_settings {
    displayReference
    displayInNewsfeed
    dashboardContentItems {
      name
      url
      type
      filter
    }
    protectionLevel
    accessRoleWhitelist
  }
  customFields {
    parent {
      url
    }
    values {
      id
      type
      comparator
      values
      siblingId
    }
  }
  cardActionsAllowed
  loggedInUserFollows
  loggedInUserCanEdit
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardLibraryFragmentFragmentDoc = gql4`
    fragment ContentCardLibraryFragment on ContentCard {
  __typename
  _id
  url
  type
  title
  body
  description
  date {
    hide_display
    last_update
    publish
  }
  links {
    source
    other
  }
  media {
    thumb
    icon
    logoLight
    logoDark
    favicon
    webclip
  }
  loggedInUserFollows
  loggedInUserCanEdit
  library_settings {
    welcomeText
    autoFollowCards
    autoFollowUsers
    initialSubscriptions
    protectionLevel
    memberStyle
    sidebarMenu {
      name
      url
    }
    dashboardMenu {
      name
      url
    }
    dashboardContentItems {
      name
      url
      type
      filter
    }
    customRoles {
      name
      url
      color
    }
    customSignupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      showOnSignup
      groups {
        group {
          _id
          path
          groupActionsAllowed
          currentUserIsMember
          currentUserIsAdmin
          profile {
            picture
            full_name
            short_bio
          }
          settings {
            approvalRequired
            protectionLevel
          }
        }
        id
        requireApproval
      }
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
    displayReference
    displayInNewsfeed
    recommendations
  }
  cardActionsAllowed
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardChapterChildrenFragmentFragmentDoc = gql4`
    fragment ContentCardChapterChildrenFragment on ContentCard {
  __typename
  _id
  chapterChildren: children(restrictType: chapter) {
    ...ContentCardChapterFragment
  }
}
    ${ContentCardChapterFragmentFragmentDoc}`;

// src/user/fragments.hook.tsx
var GroupShelfFragmentFragmentDoc = gql5`
    fragment GroupShelfFragment on Group {
  __typename
  _id
  profile {
    full_name
    picture
    coverPhoto
  }
  shelf {
    __typename
    _id
    url
    media {
      thumb
      icon
    }
  }
}
    `;
var GroupProductFragmentFragmentDoc = gql5`
    fragment GroupProductFragment on Group {
  products {
    _id
    name
    description
    coupons {
      name
      code
      percentage
      type
      expiration
    }
    price
    currency
    type
    fulfillment {
      groupId
      role
    }
    created
    updated
  }
}
    `;
var GroupEventFragmentFragmentDoc = gql5`
    fragment GroupEventFragment on Group {
  __typename
  _id
  path
  profile {
    full_name
    short_bio
    full_bio
    picture
    coverPhoto
    country
    website
    email
  }
  socialActionsAllowed
  shelf {
    __typename
    _id
    url
    media {
      thumb
      icon
    }
  }
  settings {
    __typename
    formSpec {
      url
    }
    protectionLevel
    memberStyle
    recommendations
    canMembersSuggestEdits
    shortCode
    shortCodeApprovalRequired
    groupChatroom
    adminChatroom
    approvalRequired
    registrationTitle
    registrationDescription
    registrationActionText
    enableGroupNewsfeed
    enableMembershipID
  }
  eventOverviewDetails {
    title
    description
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    hideAttendeeCount
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
    sponsorSettings {
      description
      tiers {
        name
        order
        sponsors {
          name
          link
          logo
        }
      }
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
        postal
        location {
          type
          coordinates
        }
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          city
          state
          country
          postal
          location {
            type
            coordinates
          }
        }
      }
    }
  }
  timeAndPlaceSettings {
    _id
    title
    description
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
        postal
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          title
          thumb
          icon
          altName
          altBio
          useUserBio
          color
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              protectionLevel
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
  }
  subtype
  totalMembers
  totalChildGroups
  totalChildEventGroups
  membershipBoostUri
  currentUserIsAdmin
  currentUserIsDirectAdmin
  currentUserIsMember
  currentUserIsInterested
  currentUserIsInvited
  currentUserIsNotGoing
  currentUserOwnsMembership
  currentUserOwnsFreeSubscription
  currentUserLastActiveDate
  groupActionsAllowed
  doesSellMembership
  isUnpublished
  whitelabelVerbage {
    _id
    value
    plainText
  }
  parentGroup {
    __typename
    _id
    profile {
      full_name
      coverPhoto
      picture
      website
    }
  }
  paymentSettings {
    isGroupMembershipPaid
  }
  ...GroupProductFragment
}
    ${GroupProductFragmentFragmentDoc}`;
var UpdateGroupFeatureConstraintsFragmentFragmentDoc = gql5`
    fragment UpdateGroupFeatureConstraintsFragment on Group {
  __typename
  updateFeatureConstraints {
    canEditAllFields
    featuresUserCanEdit {
      type
      field
      options
      canUpdate
    }
  }
}
    `;
var GroupFragmentFragmentDoc = gql5`
    fragment GroupFragment on Group {
  __typename
  _id
  path
  profile {
    full_name
    short_bio
    full_bio
    picture
    coverPhoto
    country
    website
    email
    customFields {
      parent {
        url
      }
      parentGroup {
        _id
      }
      manager
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
  socialActionsAllowed
  shelf {
    __typename
    _id
    url
    loggedInUserCanEdit
    media {
      thumb
      icon
    }
  }
  settings {
    __typename
    subscriptions
    formSpec {
      url
      formSpecificationSettings {
        type
        form {
          required
          fieldGroups {
            groupName
            fieldIds
            index
          }
          fields {
            index
            type
            id
            options
            customText
            prompt
            placeholder
            type
            selectMultiple
            siblingId
            defaultComparator
            required
            showOnSignup
            groups {
              group {
                _id
              }
              id
              requireApproval
            }
            allowOther
            dependsOn {
              id
              values
            }
          }
        }
      }
    }
    protectionLevel
    memberStyle
    dashboards {
      name
      url
      type
      filter
    }
    customSignupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      showOnSignup
      groups {
        group {
          _id
        }
        id
        requireApproval
      }
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
    recommendations
    canMembersSuggestEdits
    shortCode
    shortCodeApprovalRequired
    groupChatroom
    adminChatroom
    membersHub
    welcomeCard {
      enabled
      title
      message
    }
    chatSettings {
      showChatHistory
      showReadReceipts
      showOnlineUsers
      isEncrypted
    }
    adminChatSettings {
      showChatHistory
      showReadReceipts
      showOnlineUsers
      isEncrypted
    }
    chatPermissions {
      editGroupSettings
      addMembers
      pinMessages
      sendMessages
    }
    approvalRequired
    registrationTitle
    registrationDescription
    registrationActionText
    enableGroupNewsfeed
    enableMembershipID
    lastCustomSignupFieldsUpdate
    adminChatroom
    groupChatroom
    enableGroupNewsfeed
    welcomeCard {
      enabled
      title
      message
    }
  }
  eventOverviewDetails {
    title
    description
    hideAttendeeCount
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
    sponsorSettings {
      description
      tiers {
        name
        order
        sponsors {
          name
          link
          logo
        }
      }
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
  }
  timeAndPlaceSettings {
    title
    description
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          altName
          altBio
          useUserBio
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
  }
  hasUnreadDashboard
  subtype
  totalMembers
  totalChildGroups
  totalChildEventGroups
  membershipBoostUri
  currentUserIsAdmin
  currentUserIsDirectAdmin
  currentUserIsMember
  currentUserIsInterested
  currentUserIsInvited
  currentUserIsNotGoing
  currentUserLastActiveDate
  currentUserJoinDate
  currentUserOwnsMembership
  currentUserOwnsFreeSubscription
  groupActionsAllowed
  doesSellMembership
  isTemplate
  isUnpublished
  allSignupFields {
    parentGroup {
      _id
    }
    manager
    signupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
  }
  whitelabelVerbage {
    _id
    value
    plainText
  }
  parentGroup {
    __typename
    _id
    profile {
      full_name
      coverPhoto
      picture
      website
      customFields {
        parent {
          url
        }
        parentGroup {
          _id
        }
        manager
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
  }
  channelsBook {
    title
    url
    media {
      thumb
      icon
    }
    children {
      title
      url
      reference {
        href
      }
    }
  }
  eventBook {
    url
    title
    children {
      _id
      title
      url
      rankOrder
      reference {
        href
      }
    }
  }
  ...UpdateGroupFeatureConstraintsFragment
  ...GroupProductFragment
}
    ${UpdateGroupFeatureConstraintsFragmentFragmentDoc}
${GroupProductFragmentFragmentDoc}`;
var CustomFieldsFragmentFragmentDoc = gql5`
    fragment CustomFieldsFragment on User {
  __typename
  _id
  profile {
    customFields {
      parent {
        url
      }
      parentGroup {
        _id
      }
      manager
      lastDismissalDate
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
}
    `;
var UserFragmentFragmentDoc = gql5`
    fragment UserFragment on User {
  __typename
  _id
  type
  profile {
    full_name
    short_bio
    full_bio
    picture
    coverPhoto
    facebookProfile
    instagramProfile
    twitterProfile
    linkedInProfile
    country
    location {
      formatted_address
      location {
        type
        coordinates
      }
      city
      state
      country
      postal
    }
    language
    website
    email
    phone
    nickname
    followersCount
    connectionsCount
    loggedInUserConnectionStatus
    protectionLevel
    newsfeedFilter
    organization {
      ...OrganizationFragment
    }
    simple {
      name
      age
    }
    avatar {
      avatar
      skinTone
      cheekColor
      hairColor
      eyeColor
      clothing1
      clothing2
      clothing3
      outline
    }
    displaySurveyAnswers
    temp
  }
  guardianAccount {
    isVerified
    profile {
      email
      full_name
    }
    featureSets
  }
  guardianCode
  childVerificationCodes
  socialActionsAllowed
  isAnonymous
  isVerified
  isUserSuperAdmin
  canCreate
  featureSets
  ...CustomFieldsFragment
}
    ${OrganizationFragmentFragmentDoc}
${CustomFieldsFragmentFragmentDoc}`;
var GroupMembershipFragmentFragmentDoc = gql5`
    fragment GroupMembershipFragment on Group {
  __typename
  _id
  totalMembers
  totalAdmins
  totalGuests
  owners(limit: $ownersLimit, cursor: $ownersCursor) {
    __typename
    hasMore
    cursor
    results {
      ...UserFragment
      doesUserOwnMembershipInGroup(groupId: $_id)
      doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
      productInstancesForGroup(groupId: $_id) {
        _id
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
        }
      }
    }
  }
  members(limit: $membersLimit, cursor: $membersCursor) {
    __typename
    hasMore
    cursor
    results {
      ...UserFragment
      doesUserOwnMembershipInGroup(groupId: $_id)
      doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
      productInstancesForGroup(groupId: $_id) {
        _id
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
        }
      }
    }
  }
  guests {
    __typename
    _id
    profile {
      email
      full_name
      temp
    }
  }
  giftedSubscriptions {
    __typename
    _id
    ...UserFragment
    profile {
      email
      full_name
      temp
    }
    doesUserOwnMembershipInGroup(groupId: $_id)
    doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
    productInstancesForGroup(groupId: $_id) {
      _id
      transferToken
      product {
        _id
        name
        description
        price
        currency
        type
      }
    }
  }
  memberRequests {
    user {
      __typename
      ...UserFragment
      roleForGroup(groupId: $_id)
    }
    role
    message
  }
  memberInvites {
    user {
      __typename
      ...UserFragment
      roleForGroup(groupId: $_id)
    }
    role
    message
  }
  emailInvites {
    userEmail
    role
    message
  }
}
    ${UserFragmentFragmentDoc}`;
var GroupOptimizerDataFragmentFragmentDoc = gql5`
    fragment GroupOptimizerDataFragment on Group {
  _id
  profile {
    full_name
    picture
    short_bio
  }
  settings {
    recommendations
    customSignupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      showOnSignup
      groups {
        group {
          _id
        }
        id
        requireApproval
      }
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
  }
}
    `;
var UserPersonalFeedFragmentFragmentDoc = gql5`
    fragment UserPersonalFeedFragment on ContentCard {
  __typename
  ...ContentCardFragment
}
    ${ContentCardFragmentFragmentDoc}`;
var GroupDashboardFragmentFragmentDoc = gql5`
    fragment GroupDashboardFragment on PaginatedCards {
  __typename
  hasMore
  cursor
  results {
    ...ContentCardReferenceFragment
    ...ContentCardFragment
    ...ContentCardChapterChildrenFragment
  }
  type
}
    ${ContentCardReferenceFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}
${ContentCardChapterChildrenFragmentFragmentDoc}`;
var ProductFragmentFragmentDoc = gql5`
    fragment ProductFragment on Product {
  _id
  name
  price
  currency
  type
  fulfillment {
    groupId
    role
  }
  created
  updated
  createdBy {
    profile {
      full_name
      coverPhoto
    }
  }
  reference {
    ... on Group {
      _id
      profile {
        full_name
        picture
        coverPhoto
      }
      subtype
      doesSellMembership
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
    }
  }
}
    `;
var OrderFragmentFragmentDoc = gql5`
    fragment OrderFragment on CustomerOrder {
  _id
  paymentIntent {
    id
    amount
    client_secret
    currency
    customer
    description
    next_action
    payment_method {
      id
      customer
      type
      object
      card {
        id
        brand
        last4
        exp_month
        exp_year
        funding
      }
    }
    receipt_email
    status
    livemode
  }
  products {
    ...ProductFragment
  }
  created
  updated
  status
}
    ${ProductFragmentFragmentDoc}`;
var TimeAndPlaceSettingsFragmentFragmentDoc = gql5`
    fragment TimeAndPlaceSettingsFragment on TimeAndPlaceSettings {
  _id
  title
  description
  date
  dates {
    startDate
    endDate
  }
  startTime
  endTime
  displayStartTime
  displayEndTime
  link
  timeZone {
    name
  }
  hideAttendeeCount
  eventLocationSettings {
    location {
      formatted_address
      city
      state
      country
      postal
    }
    type
    link
    venue {
      name
      link
      location {
        formatted_address
        location {
          type
          coordinates
        }
        postal
        city
        state
        country
      }
    }
  }
  speakerSettings {
    aliases {
      ... on UserAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        user {
          _id
          profile {
            email
            full_name
            picture
            coverPhoto
            full_bio
            short_bio
            website
            facebookProfile
            twitterProfile
            instagramProfile
            linkedInProfile
            city
            country
            organization {
              name
            }
            location {
              city
              state
              country
            }
          }
          socialActionsAllowed
        }
      }
    }
  }
}
    `;

// src/messaging/fragments.hook.tsx
import { gql as gql6 } from "@apollo/client";
var MessageFragmentOmitThreadFragmentDoc = gql6`
    fragment MessageFragmentOmitThread on Message {
  __typename
  _id
  author {
    __typename
    _id
    profile {
      __typename
      full_name
      picture
      short_bio
    }
  }
  reactions {
    emoji
    userIds
  }
  attachment {
    ...ContentCardFragment
    ...ContentCardReferenceFragment
  }
  text
  created
  updated
}
    ${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
var MessageThreadFragmentFragmentDoc = gql6`
    fragment MessageThreadFragment on MessageThread {
  __typename
  _id
  participants {
    __typename
    user {
      __typename
      _id
      type
      profile {
        __typename
        full_name
        short_bio
        full_bio
        picture
        coverPhoto
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
      }
    }
    hasUnreadMessages
  }
  messages {
    __typename
    hasMore
    cursor
    type
    messages {
      __typename
      _id
      author {
        __typename
        _id
        profile {
          __typename
          first_name
          full_name
          picture
        }
      }
      text
      reactions {
        emoji
        userIds
      }
      attachment {
        type
        _id
        vs
      }
      created
      updated
    }
  }
  displayName
  displayIcon
  created
  updated
  group {
    __typename
    _id
    totalMembers
    profile {
      full_name
      picture
      coverPhoto
      short_bio
    }
    settings {
      protectionLevel
      approvalRequired
      shortCodeApprovalRequired
    }
    products {
      _id
      name
    }
  }
  currentUserNotificationLevel
  threadType
}
    `;
var MessageFragmentFragmentDoc = gql6`
    fragment MessageFragment on Message {
  __typename
  _id
  author {
    __typename
    _id
    profile {
      __typename
      full_name
      picture
      short_bio
    }
  }
  thread {
    ...MessageThreadFragment
  }
  attachment {
    ...ContentCardFragment
    ...ContentCardReferenceFragment
  }
  text
  reactions {
    emoji
    userIds
  }
  created
  updated
}
    ${MessageThreadFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
var MessageUpdateFragmentFragmentDoc = gql6`
    fragment MessageUpdateFragment on MessageUpdate {
  action
  message {
    ... on Message {
      ...MessageFragment
    }
    ... on MessageDelete {
      _id
      thread {
        ...MessageThreadFragment
      }
    }
  }
}
    ${MessageFragmentFragmentDoc}
${MessageThreadFragmentFragmentDoc}`;

// src/user/queries.hook.tsx
import * as Apollo from "@apollo/client";
var defaultOptions = {};
var AccountHolderType = /* @__PURE__ */ ((AccountHolderType2) => {
  AccountHolderType2["Company"] = "company";
  AccountHolderType2["Individual"] = "individual";
  return AccountHolderType2;
})(AccountHolderType || {});
var AccountStatus = /* @__PURE__ */ ((AccountStatus2) => {
  AccountStatus2["Active"] = "active";
  AccountStatus2["Inactive"] = "inactive";
  AccountStatus2["Invited"] = "invited";
  return AccountStatus2;
})(AccountStatus || {});
var AggregationType = /* @__PURE__ */ ((AggregationType2) => {
  AggregationType2["Groups"] = "GROUPS";
  AggregationType2["Nations"] = "NATIONS";
  AggregationType2["Recruits"] = "RECRUITS";
  AggregationType2["Users"] = "USERS";
  return AggregationType2;
})(AggregationType || {});
var AnalyticScopeType = /* @__PURE__ */ ((AnalyticScopeType2) => {
  AnalyticScopeType2["Group"] = "GROUP";
  AnalyticScopeType2["PledgeKey"] = "PLEDGE_KEY";
  return AnalyticScopeType2;
})(AnalyticScopeType || {});
var ApplicationStatus = /* @__PURE__ */ ((ApplicationStatus2) => {
  ApplicationStatus2["Accepted"] = "ACCEPTED";
  ApplicationStatus2["Cancelled"] = "CANCELLED";
  ApplicationStatus2["Closed"] = "CLOSED";
  ApplicationStatus2["Open"] = "OPEN";
  ApplicationStatus2["Rejected"] = "REJECTED";
  return ApplicationStatus2;
})(ApplicationStatus || {});
var ApprovalType = /* @__PURE__ */ ((ApprovalType2) => {
  ApprovalType2["Auto"] = "AUTO";
  ApprovalType2["Manual"] = "MANUAL";
  ApprovalType2["Skip"] = "SKIP";
  return ApprovalType2;
})(ApprovalType || {});
var BulkCardAction = /* @__PURE__ */ ((BulkCardAction2) => {
  BulkCardAction2["ResetChildrenSettings"] = "RESET_CHILDREN_SETTINGS";
  return BulkCardAction2;
})(BulkCardAction || {});
var CardActions = /* @__PURE__ */ ((CardActions2) => {
  CardActions2["AdminJoin"] = "adminJoin";
  CardActions2["CancelJoinRequest"] = "cancelJoinRequest";
  CardActions2["ExitMembership"] = "exitMembership";
  CardActions2["Follow"] = "follow";
  CardActions2["MemberJoin"] = "memberJoin";
  CardActions2["OpenJoin"] = "openJoin";
  CardActions2["Subscribe"] = "subscribe";
  CardActions2["Unfollow"] = "unfollow";
  CardActions2["Unsubscribe"] = "unsubscribe";
  return CardActions2;
})(CardActions || {});
var CardEngagementType = /* @__PURE__ */ ((CardEngagementType2) => {
  CardEngagementType2["Bookmark"] = "bookmark";
  CardEngagementType2["Comment"] = "comment";
  CardEngagementType2["Follow"] = "follow";
  CardEngagementType2["Heart"] = "heart";
  CardEngagementType2["Share"] = "share";
  return CardEngagementType2;
})(CardEngagementType || {});
var CardPostType = /* @__PURE__ */ ((CardPostType2) => {
  CardPostType2["Blog"] = "blog";
  CardPostType2["Book"] = "book";
  CardPostType2["Channel"] = "channel";
  CardPostType2["Chapter"] = "chapter";
  CardPostType2["Collection"] = "collection";
  CardPostType2["Course"] = "course";
  CardPostType2["Creation"] = "creation";
  CardPostType2["CustomFieldsSummary"] = "custom_fields_summary";
  CardPostType2["Dialog"] = "dialog";
  CardPostType2["Document"] = "document";
  CardPostType2["Dynamic"] = "dynamic";
  CardPostType2["Event"] = "event";
  CardPostType2["Formspec"] = "formspec";
  CardPostType2["Group"] = "group";
  CardPostType2["Header"] = "header";
  CardPostType2["Image"] = "image";
  CardPostType2["ImageSquare"] = "image_square";
  CardPostType2["Library"] = "library";
  CardPostType2["Link"] = "link";
  CardPostType2["Linktile"] = "linktile";
  CardPostType2["Map"] = "map";
  CardPostType2["Metachannel"] = "metachannel";
  CardPostType2["Mobilization"] = "mobilization";
  CardPostType2["Mobilize"] = "mobilize";
  CardPostType2["Pledge"] = "pledge";
  CardPostType2["Position"] = "position";
  CardPostType2["Postbox"] = "postbox";
  CardPostType2["Posting"] = "posting";
  CardPostType2["Profile"] = "profile";
  CardPostType2["RainbowRoad"] = "rainbow_road";
  CardPostType2["Research"] = "research";
  CardPostType2["Shelf"] = "shelf";
  CardPostType2["Story"] = "story";
  CardPostType2["Summary"] = "summary";
  CardPostType2["Survey"] = "survey";
  CardPostType2["Text"] = "text";
  CardPostType2["Video"] = "video";
  CardPostType2["Votegallery"] = "votegallery";
  return CardPostType2;
})(CardPostType || {});
var CardVoteType = /* @__PURE__ */ ((CardVoteType2) => {
  CardVoteType2["BallotMeasure"] = "BALLOT_MEASURE";
  CardVoteType2["Quality"] = "QUALITY";
  CardVoteType2["Relevance"] = "RELEVANCE";
  CardVoteType2["Sentiment"] = "SENTIMENT";
  return CardVoteType2;
})(CardVoteType || {});
var Collection = /* @__PURE__ */ ((Collection2) => {
  Collection2["Application"] = "APPLICATION";
  Collection2["Comment"] = "COMMENT";
  Collection2["ContentCard"] = "CONTENT_CARD";
  Collection2["Group"] = "GROUP";
  Collection2["Message"] = "MESSAGE";
  Collection2["MessageThread"] = "MESSAGE_THREAD";
  Collection2["User"] = "USER";
  return Collection2;
})(Collection || {});
var CommunityTypes = /* @__PURE__ */ ((CommunityTypes2) => {
  CommunityTypes2["AllPeople"] = "all_people";
  CommunityTypes2["Connections"] = "connections";
  CommunityTypes2["Followers"] = "followers";
  CommunityTypes2["Following"] = "following";
  CommunityTypes2["SharedGroups"] = "shared_groups";
  return CommunityTypes2;
})(CommunityTypes || {});
var ComparatorType = /* @__PURE__ */ ((ComparatorType2) => {
  ComparatorType2["MultiAndPos"] = "MULTI_AND_POS";
  ComparatorType2["MultiOrPos"] = "MULTI_OR_POS";
  ComparatorType2["None"] = "NONE";
  ComparatorType2["ProximityFar"] = "PROXIMITY_FAR";
  ComparatorType2["ProximityNear"] = "PROXIMITY_NEAR";
  ComparatorType2["RangePos"] = "RANGE_POS";
  return ComparatorType2;
})(ComparatorType || {});
var ConnectionAction = /* @__PURE__ */ ((ConnectionAction2) => {
  ConnectionAction2["AcceptConnection"] = "acceptConnection";
  ConnectionAction2["CancelRequestConnection"] = "cancelRequestConnection";
  ConnectionAction2["Disconnect"] = "disconnect";
  ConnectionAction2["RejectConnection"] = "rejectConnection";
  ConnectionAction2["RequestConnection"] = "requestConnection";
  return ConnectionAction2;
})(ConnectionAction || {});
var ConnectionStatus = /* @__PURE__ */ ((ConnectionStatus2) => {
  ConnectionStatus2["Connected"] = "connected";
  ConnectionStatus2["Disconnected"] = "disconnected";
  ConnectionStatus2["IsLoggedInUser"] = "isLoggedInUser";
  ConnectionStatus2["Pending"] = "pending";
  ConnectionStatus2["Rejected"] = "rejected";
  ConnectionStatus2["Requested"] = "requested";
  return ConnectionStatus2;
})(ConnectionStatus || {});
var CouponType = /* @__PURE__ */ ((CouponType2) => {
  CouponType2["Default"] = "default";
  return CouponType2;
})(CouponType || {});
var CourseProgressType = /* @__PURE__ */ ((CourseProgressType2) => {
  CourseProgressType2["LevelComplete"] = "level_complete";
  CourseProgressType2["StepComplete"] = "step_complete";
  return CourseProgressType2;
})(CourseProgressType || {});
var CustomSignupFieldTypes = /* @__PURE__ */ ((CustomSignupFieldTypes2) => {
  CustomSignupFieldTypes2["ChooseRole"] = "choose_role";
  CustomSignupFieldTypes2["ConsentBox"] = "consent_box";
  CustomSignupFieldTypes2["Description"] = "description";
  CustomSignupFieldTypes2["Dropdown"] = "dropdown";
  CustomSignupFieldTypes2["JoinGroup"] = "joinGroup";
  CustomSignupFieldTypes2["Numberslider"] = "numberslider";
  CustomSignupFieldTypes2["Paragraph"] = "paragraph";
  CustomSignupFieldTypes2["ProfileLocation"] = "profile_location";
  CustomSignupFieldTypes2["ProfileOrganization"] = "profile_organization";
  CustomSignupFieldTypes2["Shortanswer"] = "shortanswer";
  CustomSignupFieldTypes2["Signature"] = "signature";
  CustomSignupFieldTypes2["UploadFile"] = "uploadFile";
  return CustomSignupFieldTypes2;
})(CustomSignupFieldTypes || {});
var ElectionStatus = /* @__PURE__ */ ((ElectionStatus2) => {
  ElectionStatus2["ActiveTimed"] = "ACTIVE_TIMED";
  ElectionStatus2["ActiveUntimed"] = "ACTIVE_UNTIMED";
  ElectionStatus2["Closed"] = "CLOSED";
  ElectionStatus2["Created"] = "CREATED";
  ElectionStatus2["Scheduled"] = "SCHEDULED";
  return ElectionStatus2;
})(ElectionStatus || {});
var EngagementFilterType = /* @__PURE__ */ ((EngagementFilterType2) => {
  EngagementFilterType2["CustomField"] = "CUSTOM_FIELD";
  EngagementFilterType2["Deactivated"] = "DEACTIVATED";
  EngagementFilterType2["Group"] = "GROUP";
  EngagementFilterType2["Nation"] = "NATION";
  EngagementFilterType2["NewRecruit"] = "NEW_RECRUIT";
  EngagementFilterType2["PledgeCard"] = "PLEDGE_CARD";
  EngagementFilterType2["PledgeKey"] = "PLEDGE_KEY";
  EngagementFilterType2["Recruiter"] = "RECRUITER";
  EngagementFilterType2["RecruitType"] = "RECRUIT_TYPE";
  EngagementFilterType2["TimeWindow"] = "TIME_WINDOW";
  return EngagementFilterType2;
})(EngagementFilterType || {});
var EngagementSortOrder = /* @__PURE__ */ ((EngagementSortOrder2) => {
  EngagementSortOrder2["Asc"] = "ASC";
  EngagementSortOrder2["Desc"] = "DESC";
  return EngagementSortOrder2;
})(EngagementSortOrder || {});
var EngagementSortType = /* @__PURE__ */ ((EngagementSortType2) => {
  EngagementSortType2["Comments"] = "COMMENTS";
  EngagementSortType2["CreateDate"] = "CREATE_DATE";
  EngagementSortType2["JoinDate"] = "JOIN_DATE";
  EngagementSortType2["Name"] = "NAME";
  EngagementSortType2["Pledges"] = "PLEDGES";
  EngagementSortType2["Posts"] = "POSTS";
  EngagementSortType2["RecruitDate"] = "RECRUIT_DATE";
  EngagementSortType2["Votes"] = "VOTES";
  return EngagementSortType2;
})(EngagementSortType || {});
var EngagementType = /* @__PURE__ */ ((EngagementType2) => {
  EngagementType2["Comments"] = "COMMENTS";
  EngagementType2["Pledges"] = "PLEDGES";
  EngagementType2["Posts"] = "POSTS";
  EngagementType2["Votes"] = "VOTES";
  return EngagementType2;
})(EngagementType || {});
var EventAction = /* @__PURE__ */ ((EventAction2) => {
  EventAction2["Create"] = "CREATE";
  EventAction2["Delete"] = "DELETE";
  EventAction2["Update"] = "UPDATE";
  EventAction2["AcceptConnection"] = "acceptConnection";
  EventAction2["AdminAnnouncement"] = "adminAnnouncement";
  EventAction2["ApplicationAccepted"] = "applicationAccepted";
  EventAction2["ApplicationClosed"] = "applicationClosed";
  EventAction2["ApplicationRejected"] = "applicationRejected";
  EventAction2["AttachedCardToChapter"] = "attachedCardToChapter";
  EventAction2["BroadcastSurvey"] = "broadcastSurvey";
  EventAction2["CancelRequestConnection"] = "cancelRequestConnection";
  EventAction2["CardAccessRequest"] = "cardAccessRequest";
  EventAction2["CardContentAutoModerated"] = "cardContentAutoModerated";
  EventAction2["CardContentModeratedAccepted"] = "cardContentModeratedAccepted";
  EventAction2["CardContentModeratedRejected"] = "cardContentModeratedRejected";
  EventAction2["DashfeedPushed"] = "dashfeedPushed";
  EventAction2["Disconnect"] = "disconnect";
  EventAction2["DownloadLausanneSurveyPdf"] = "downloadLausanneSurveyPdf";
  EventAction2["ElectionEnd"] = "electionEnd";
  EventAction2["ElectionReminder"] = "electionReminder";
  EventAction2["ElectionStart"] = "electionStart";
  EventAction2["EventCanceled"] = "eventCanceled";
  EventAction2["GroupAccessApproved"] = "groupAccessApproved";
  EventAction2["GroupAccessRequest"] = "groupAccessRequest";
  EventAction2["GroupAdminPromoted"] = "groupAdminPromoted";
  EventAction2["GroupConnectedAccountReady"] = "groupConnectedAccountReady";
  EventAction2["GroupContentAutoModerated"] = "groupContentAutoModerated";
  EventAction2["GroupContentModeratedAccepted"] = "groupContentModeratedAccepted";
  EventAction2["GroupContentModeratedRejected"] = "groupContentModeratedRejected";
  EventAction2["GroupInviteAccepted"] = "groupInviteAccepted";
  EventAction2["GroupInviteRequest"] = "groupInviteRequest";
  EventAction2["GroupPreAuthPaymentRequest"] = "groupPreAuthPaymentRequest";
  EventAction2["GroupPreAuthPaymentRequestApproved"] = "groupPreAuthPaymentRequestApproved";
  EventAction2["GroupSpeakerInvite"] = "groupSpeakerInvite";
  EventAction2["MissedPledgeCommitment"] = "missedPledgeCommitment";
  EventAction2["MobilizationComplete"] = "mobilizationComplete";
  EventAction2["MobilizationEffortComplete"] = "mobilizationEffortComplete";
  EventAction2["NewApplication"] = "newApplication";
  EventAction2["NewMessage"] = "newMessage";
  EventAction2["NotifyGroupsOfTarget"] = "notifyGroupsOfTarget";
  EventAction2["PostingClosed"] = "postingClosed";
  EventAction2["RejectConnection"] = "rejectConnection";
  EventAction2["RequestConnection"] = "requestConnection";
  EventAction2["RoleAdminPromotion"] = "roleAdminPromotion";
  EventAction2["SubscriptionDeleted"] = "subscriptionDeleted";
  EventAction2["SubscriptionPriceChange"] = "subscriptionPriceChange";
  EventAction2["TicketTransfer"] = "ticketTransfer";
  EventAction2["UnreadMessagesNudge"] = "unreadMessagesNudge";
  EventAction2["VoteAdded"] = "voteAdded";
  return EventAction2;
})(EventAction || {});
var EventRsvp = /* @__PURE__ */ ((EventRsvp2) => {
  EventRsvp2["Going"] = "GOING";
  EventRsvp2["Interested"] = "INTERESTED";
  EventRsvp2["MaybeAttending"] = "MAYBE_ATTENDING";
  EventRsvp2["NotAttending"] = "NOT_ATTENDING";
  EventRsvp2["NotGoing"] = "NOT_GOING";
  EventRsvp2["NoResponse"] = "NO_RESPONSE";
  EventRsvp2["YesAttending"] = "YES_ATTENDING";
  return EventRsvp2;
})(EventRsvp || {});
var FilterType = /* @__PURE__ */ ((FilterType2) => {
  FilterType2["Archived"] = "archived";
  FilterType2["Az"] = "az";
  FilterType2["Custom"] = "custom";
  FilterType2["Leaderboard"] = "leaderboard";
  FilterType2["Live"] = "live";
  FilterType2["OldestFirst"] = "oldestFirst";
  FilterType2["Ordered"] = "ordered";
  FilterType2["Past"] = "past";
  FilterType2["Top"] = "top";
  FilterType2["Upcoming"] = "upcoming";
  FilterType2["Vote"] = "vote";
  return FilterType2;
})(FilterType || {});
var FormSpecificationType = /* @__PURE__ */ ((FormSpecificationType2) => {
  FormSpecificationType2["Posting"] = "POSTING";
  FormSpecificationType2["Standard"] = "STANDARD";
  return FormSpecificationType2;
})(FormSpecificationType || {});
var GroupActions = /* @__PURE__ */ ((GroupActions2) => {
  GroupActions2["AcceptInviteGroup"] = "acceptInviteGroup";
  GroupActions2["CancelPreAuthPurchase"] = "cancelPreAuthPurchase";
  GroupActions2["CancelRequestJoinGroup"] = "cancelRequestJoinGroup";
  GroupActions2["EditProducts"] = "editProducts";
  GroupActions2["ExpressInterest"] = "expressInterest";
  GroupActions2["JoinGroup"] = "joinGroup";
  GroupActions2["LeaveGroup"] = "leaveGroup";
  GroupActions2["LoggedOutExpressInterest"] = "loggedOutExpressInterest";
  GroupActions2["LoggedOutPurchaseAccess"] = "loggedOutPurchaseAccess";
  GroupActions2["LoggedOutRsvp"] = "loggedOutRSVP";
  GroupActions2["PurchaseAccess"] = "purchaseAccess";
  GroupActions2["PurchaseParentAccess"] = "purchaseParentAccess";
  GroupActions2["ReactivateSubscription"] = "reactivateSubscription";
  GroupActions2["RequestJoinGroup"] = "requestJoinGroup";
  GroupActions2["RequestToPurchaseAccess"] = "requestToPurchaseAccess";
  GroupActions2["RespondNotGoing"] = "respondNotGoing";
  GroupActions2["ViewPurchasedProducts"] = "viewPurchasedProducts";
  return GroupActions2;
})(GroupActions || {});
var GroupRole = /* @__PURE__ */ ((GroupRole2) => {
  GroupRole2["Invited"] = "invited";
  GroupRole2["Member"] = "member";
  GroupRole2["None"] = "none";
  GroupRole2["Owner"] = "owner";
  GroupRole2["Requested"] = "requested";
  return GroupRole2;
})(GroupRole || {});
var GroupSubtype = /* @__PURE__ */ ((GroupSubtype2) => {
  GroupSubtype2["Event"] = "event";
  GroupSubtype2["General"] = "general";
  GroupSubtype2["Organization"] = "organization";
  return GroupSubtype2;
})(GroupSubtype || {});
var LabelType = /* @__PURE__ */ ((LabelType2) => {
  LabelType2["Bubble"] = "BUBBLE";
  LabelType2["Plain"] = "PLAIN";
  return LabelType2;
})(LabelType || {});
var LocationType = /* @__PURE__ */ ((LocationType2) => {
  LocationType2["InPerson"] = "in_person";
  LocationType2["Virtual"] = "virtual";
  LocationType2["VirtualAndInPerson"] = "virtual_and_in_person";
  return LocationType2;
})(LocationType || {});
var MembershipStyle = /* @__PURE__ */ ((MembershipStyle2) => {
  MembershipStyle2["Follow"] = "follow";
  MembershipStyle2["Friends"] = "friends";
  MembershipStyle2["Grouped"] = "grouped";
  return MembershipStyle2;
})(MembershipStyle || {});
var MessageThreadType = /* @__PURE__ */ ((MessageThreadType2) => {
  MessageThreadType2["DirectMessage"] = "DIRECT_MESSAGE";
  MessageThreadType2["MultiUserChat"] = "MULTI_USER_CHAT";
  MessageThreadType2["OfficialGroupAdminChat"] = "OFFICIAL_GROUP_ADMIN_CHAT";
  MessageThreadType2["OfficialGroupChat"] = "OFFICIAL_GROUP_CHAT";
  return MessageThreadType2;
})(MessageThreadType || {});
var MobilizationStatus = /* @__PURE__ */ ((MobilizationStatus2) => {
  MobilizationStatus2["Complete"] = "COMPLETE";
  MobilizationStatus2["InProgress"] = "IN_PROGRESS";
  return MobilizationStatus2;
})(MobilizationStatus || {});
var MobilizationType = /* @__PURE__ */ ((MobilizationType2) => {
  MobilizationType2["Pledge"] = "PLEDGE";
  MobilizationType2["Posting"] = "POSTING";
  MobilizationType2["Task"] = "TASK";
  return MobilizationType2;
})(MobilizationType || {});
var NewsfeedFilter = /* @__PURE__ */ ((NewsfeedFilter2) => {
  NewsfeedFilter2["ForYou"] = "FOR_YOU";
  NewsfeedFilter2["Recent"] = "RECENT";
  return NewsfeedFilter2;
})(NewsfeedFilter || {});
var NotificationChannelType = /* @__PURE__ */ ((NotificationChannelType2) => {
  NotificationChannelType2["Email"] = "EMAIL";
  NotificationChannelType2["Pubsub"] = "PUBSUB";
  NotificationChannelType2["Push"] = "PUSH";
  return NotificationChannelType2;
})(NotificationChannelType || {});
var NotificationLevel = /* @__PURE__ */ ((NotificationLevel2) => {
  NotificationLevel2["Mention"] = "MENTION";
  NotificationLevel2["Off"] = "OFF";
  NotificationLevel2["On"] = "ON";
  return NotificationLevel2;
})(NotificationLevel || {});
var NotificationType = /* @__PURE__ */ ((NotificationType2) => {
  NotificationType2["Default"] = "DEFAULT";
  NotificationType2["Mention"] = "MENTION";
  return NotificationType2;
})(NotificationType || {});
var OrderLocation = /* @__PURE__ */ ((OrderLocation2) => {
  OrderLocation2["InPerson"] = "inPerson";
  OrderLocation2["Online"] = "online";
  return OrderLocation2;
})(OrderLocation || {});
var PaymentType = /* @__PURE__ */ ((PaymentType2) => {
  PaymentType2["Cash"] = "cash";
  PaymentType2["CreditCard"] = "creditCard";
  PaymentType2["NotApplicable"] = "notApplicable";
  return PaymentType2;
})(PaymentType || {});
var PositionType = /* @__PURE__ */ ((PositionType2) => {
  PositionType2["Post"] = "post";
  PositionType2["Pre"] = "pre";
  return PositionType2;
})(PositionType || {});
var PostingStatus = /* @__PURE__ */ ((PostingStatus2) => {
  PostingStatus2["Cancelled"] = "CANCELLED";
  PostingStatus2["Closed"] = "CLOSED";
  PostingStatus2["Open"] = "OPEN";
  return PostingStatus2;
})(PostingStatus || {});
var PostingType = /* @__PURE__ */ ((PostingType2) => {
  PostingType2["Job"] = "JOB";
  PostingType2["Volunteer"] = "VOLUNTEER";
  return PostingType2;
})(PostingType || {});
var RrCircleSize = /* @__PURE__ */ ((RrCircleSize2) => {
  RrCircleSize2["Large"] = "large";
  RrCircleSize2["Medium"] = "medium";
  RrCircleSize2["Small"] = "small";
  return RrCircleSize2;
})(RrCircleSize || {});
var RrFont = /* @__PURE__ */ ((RrFont2) => {
  RrFont2["Default"] = "default";
  RrFont2["Lego"] = "lego";
  return RrFont2;
})(RrFont || {});
var RrLineStyle = /* @__PURE__ */ ((RrLineStyle2) => {
  RrLineStyle2["Dashed"] = "dashed";
  RrLineStyle2["None"] = "none";
  RrLineStyle2["Solid"] = "solid";
  return RrLineStyle2;
})(RrLineStyle || {});
var RecommendationManager = /* @__PURE__ */ ((RecommendationManager2) => {
  RecommendationManager2["Group"] = "group";
  RecommendationManager2["Library"] = "library";
  RecommendationManager2["Platform"] = "platform";
  RecommendationManager2["Posting"] = "posting";
  return RecommendationManager2;
})(RecommendationManager || {});
var RecommendationType = /* @__PURE__ */ ((RecommendationType2) => {
  RecommendationType2["ContentCard"] = "CONTENT_CARD";
  RecommendationType2["Group"] = "GROUP";
  RecommendationType2["User"] = "USER";
  return RecommendationType2;
})(RecommendationType || {});
var RecruitType = /* @__PURE__ */ ((RecruitType2) => {
  RecruitType2["Existing"] = "EXISTING";
  RecruitType2["New"] = "NEW";
  RecruitType2["None"] = "NONE";
  RecruitType2["Potential"] = "POTENTIAL";
  return RecruitType2;
})(RecruitType || {});
var ReferenceType = /* @__PURE__ */ ((ReferenceType2) => {
  ReferenceType2["Book"] = "book";
  ReferenceType2["Chapter"] = "chapter";
  ReferenceType2["Group"] = "group";
  ReferenceType2["Library"] = "library";
  ReferenceType2["Prompt"] = "prompt";
  ReferenceType2["Shelf"] = "shelf";
  ReferenceType2["User"] = "user";
  return ReferenceType2;
})(ReferenceType || {});
var ReportingType = /* @__PURE__ */ ((ReportingType2) => {
  ReportingType2["AddNote"] = "ADD_NOTE";
  ReportingType2["Done"] = "DONE";
  ReportingType2["RegisterUser"] = "REGISTER_USER";
  ReportingType2["UploadImage"] = "UPLOAD_IMAGE";
  return ReportingType2;
})(ReportingType || {});
var Resource = /* @__PURE__ */ ((Resource2) => {
  Resource2["Books"] = "Books";
  Resource2["Crayons"] = "Crayons";
  Resource2["Flowers"] = "Flowers";
  Resource2["Hearts"] = "Hearts";
  Resource2["Wood"] = "Wood";
  return Resource2;
})(Resource || {});
var Role = /* @__PURE__ */ ((Role2) => {
  Role2["Admin"] = "admin";
  Role2["Answer"] = "answer";
  Role2["Manage"] = "manage";
  Role2["None"] = "none";
  Role2["Speaker"] = "speaker";
  Role2["View"] = "view";
  Role2["Vote"] = "vote";
  return Role2;
})(Role || {});
var SearchFilterType = /* @__PURE__ */ ((SearchFilterType2) => {
  SearchFilterType2["Chapter"] = "CHAPTER";
  SearchFilterType2["Collection"] = "COLLECTION";
  SearchFilterType2["Comment"] = "COMMENT";
  SearchFilterType2["Group"] = "GROUP";
  SearchFilterType2["Message"] = "MESSAGE";
  SearchFilterType2["Post"] = "POST";
  SearchFilterType2["Prompt"] = "PROMPT";
  SearchFilterType2["User"] = "USER";
  return SearchFilterType2;
})(SearchFilterType || {});
var SearchScopeType = /* @__PURE__ */ ((SearchScopeType2) => {
  SearchScopeType2["Contentcard"] = "CONTENTCARD";
  SearchScopeType2["Group"] = "GROUP";
  return SearchScopeType2;
})(SearchScopeType || {});
var SocialActions = /* @__PURE__ */ ((SocialActions2) => {
  SocialActions2["AcceptConnection"] = "acceptConnection";
  SocialActions2["CancelRequestConnection"] = "cancelRequestConnection";
  SocialActions2["Disconnect"] = "disconnect";
  SocialActions2["Follow"] = "follow";
  SocialActions2["Message"] = "message";
  SocialActions2["RejectConnection"] = "rejectConnection";
  SocialActions2["RequestConnection"] = "requestConnection";
  SocialActions2["Unfollow"] = "unfollow";
  return SocialActions2;
})(SocialActions || {});
var StorageType = /* @__PURE__ */ ((StorageType2) => {
  StorageType2["Ceramic"] = "CERAMIC";
  StorageType2["Mongodb"] = "MONGODB";
  StorageType2["None"] = "NONE";
  return StorageType2;
})(StorageType || {});
var StripeInvoiceStatus = /* @__PURE__ */ ((StripeInvoiceStatus2) => {
  StripeInvoiceStatus2["Draft"] = "draft";
  StripeInvoiceStatus2["Open"] = "open";
  StripeInvoiceStatus2["Paid"] = "paid";
  StripeInvoiceStatus2["Uncollectible"] = "uncollectible";
  StripeInvoiceStatus2["Void"] = "void";
  return StripeInvoiceStatus2;
})(StripeInvoiceStatus || {});
var StripeSubscriptionStatus = /* @__PURE__ */ ((StripeSubscriptionStatus2) => {
  StripeSubscriptionStatus2["Active"] = "active";
  StripeSubscriptionStatus2["Canceled"] = "canceled";
  StripeSubscriptionStatus2["Incomplete"] = "incomplete";
  StripeSubscriptionStatus2["IncompleteExpired"] = "incomplete_expired";
  StripeSubscriptionStatus2["PastDue"] = "past_due";
  StripeSubscriptionStatus2["Trialing"] = "trialing";
  StripeSubscriptionStatus2["Unpaid"] = "unpaid";
  return StripeSubscriptionStatus2;
})(StripeSubscriptionStatus || {});
var SubscriptionLevel = /* @__PURE__ */ ((SubscriptionLevel2) => {
  SubscriptionLevel2["WlBasicMonthly"] = "WL_BASIC_MONTHLY";
  SubscriptionLevel2["WlBasicYearly"] = "WL_BASIC_YEARLY";
  SubscriptionLevel2["WlProMonthly"] = "WL_PRO_MONTHLY";
  SubscriptionLevel2["WlProYearly"] = "WL_PRO_YEARLY";
  SubscriptionLevel2["WlUnlimitedMonthly"] = "WL_UNLIMITED_MONTHLY";
  SubscriptionLevel2["WlUnlimitedYearly"] = "WL_UNLIMITED_YEARLY";
  return SubscriptionLevel2;
})(SubscriptionLevel || {});
var SurveyQuestionSubType = /* @__PURE__ */ ((SurveyQuestionSubType2) => {
  SurveyQuestionSubType2["Emotional"] = "Emotional";
  SurveyQuestionSubType2["Image"] = "Image";
  SurveyQuestionSubType2["LongAnswer"] = "LongAnswer";
  SurveyQuestionSubType2["Numeric"] = "Numeric";
  SurveyQuestionSubType2["ShortAnswer"] = "ShortAnswer";
  SurveyQuestionSubType2["Text"] = "Text";
  SurveyQuestionSubType2["ThreeBoxAnswer"] = "ThreeBoxAnswer";
  return SurveyQuestionSubType2;
})(SurveyQuestionSubType || {});
var SurveyQuestionType = /* @__PURE__ */ ((SurveyQuestionType2) => {
  SurveyQuestionType2["Image"] = "Image";
  SurveyQuestionType2["ImageOrText"] = "ImageOrText";
  SurveyQuestionType2["MultipleChoice"] = "MultipleChoice";
  SurveyQuestionType2["Slider"] = "Slider";
  SurveyQuestionType2["Text"] = "Text";
  return SurveyQuestionType2;
})(SurveyQuestionType || {});
var TicketCheckInStatusEnum = /* @__PURE__ */ ((TicketCheckInStatusEnum2) => {
  TicketCheckInStatusEnum2["CheckedIn"] = "checkedIn";
  TicketCheckInStatusEnum2["CheckingIn"] = "checkingIn";
  TicketCheckInStatusEnum2["Invalid"] = "invalid";
  return TicketCheckInStatusEnum2;
})(TicketCheckInStatusEnum || {});
var UpdateAction = /* @__PURE__ */ ((UpdateAction2) => {
  UpdateAction2["Add"] = "ADD";
  UpdateAction2["Delete"] = "DELETE";
  UpdateAction2["Update"] = "UPDATE";
  return UpdateAction2;
})(UpdateAction || {});
var UserType = /* @__PURE__ */ ((UserType2) => {
  UserType2["Child"] = "child";
  UserType2["Ephemeral"] = "ephemeral";
  UserType2["Group"] = "group";
  UserType2["Guardian"] = "guardian";
  UserType2["Normal"] = "normal";
  UserType2["Organization"] = "organization";
  UserType2["System"] = "system";
  return UserType2;
})(UserType || {});
var VerifierType = /* @__PURE__ */ ((VerifierType2) => {
  VerifierType2["Recruit"] = "RECRUIT";
  return VerifierType2;
})(VerifierType || {});
var ViewPermission = /* @__PURE__ */ ((ViewPermission2) => {
  ViewPermission2["Private"] = "private";
  ViewPermission2["Public"] = "public";
  ViewPermission2["Secret"] = "secret";
  ViewPermission2["Unset"] = "unset";
  return ViewPermission2;
})(ViewPermission || {});
var ViewType = /* @__PURE__ */ ((ViewType2) => {
  ViewType2["Fullview"] = "fullview";
  ViewType2["Listview"] = "listview";
  return ViewType2;
})(ViewType || {});
var Violation = /* @__PURE__ */ ((Violation2) => {
  Violation2["GroupPolicy"] = "GROUP_POLICY";
  Violation2["WelibraryPolicy"] = "WELIBRARY_POLICY";
  return Violation2;
})(Violation || {});
var UserDocument = gql7`
    query User($_id: ID!, $temp: Boolean = false) {
  user(_id: $_id, temp: $temp) {
    _id
    profile {
      lausannePdfUrl
      email
      phone
      first_name
      last_name
      full_name
      birthday
      language
      short_bio
      full_bio
      picture
      coverPhoto
      country
      website
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        state
        country
        postal
      }
      nickname
      facebookProfile
      instagramProfile
      twitterProfile
      linkedInProfile
      did
      protectionLevel
      customFields {
        parentGroup {
          _id
        }
        manager
        values {
          id
          values
          type
          comparator
        }
      }
    }
    roles {
      roles
    }
    paymentSettings {
      stripeCustomer {
        id
        name
        email
        phone
        description
        default_source
        invoice_settings {
          default_payment_method
        }
      }
      cardSourceId
    }
  }
}
    `;
function useUserQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(UserDocument, options);
}
__name(useUserQuery, "useUserQuery");
function useUserLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(UserDocument, options);
}
__name(useUserLazyQuery, "useUserLazyQuery");
var UsersDocument = gql7`
    query Users($_ids: [ID!]!, $temp: Boolean = false) {
  users(_ids: $_ids, temp: $temp) {
    _id
    profile {
      lausannePdfUrl
      email
      first_name
      last_name
      full_name
      birthday
      language
      short_bio
      full_bio
      picture
      country
      website
      nickname
      did
      protectionLevel
      customFields {
        parentGroup {
          _id
        }
        manager
        values {
          id
          values
          type
          comparator
        }
      }
    }
    roles {
      roles
    }
    paymentSettings {
      stripeCustomer {
        id
        name
        email
        phone
        description
        default_source
        invoice_settings {
          default_payment_method
        }
      }
      cardSourceId
    }
  }
}
    `;
function useUsersQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(UsersDocument, options);
}
__name(useUsersQuery, "useUsersQuery");
function useUsersLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(UsersDocument, options);
}
__name(useUsersLazyQuery, "useUsersLazyQuery");
var GetUserByPhoneDocument = gql7`
    query GetUserByPhone($phone: String!) {
  getUserByPhone(phone: $phone) {
    _id
    profile {
      lausannePdfUrl
      email
      first_name
      last_name
      full_name
      birthday
      language
      short_bio
      full_bio
      picture
      country
      website
      nickname
      protectionLevel
    }
  }
}
    `;
function useGetUserByPhoneQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetUserByPhoneDocument, options);
}
__name(useGetUserByPhoneQuery, "useGetUserByPhoneQuery");
function useGetUserByPhoneLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetUserByPhoneDocument, options);
}
__name(useGetUserByPhoneLazyQuery, "useGetUserByPhoneLazyQuery");
var GetUserByEmailDocument = gql7`
    query GetUserByEmail($email: String!) {
  getUserByEmail(email: $email) {
    _id
    profile {
      email
      first_name
      last_name
      full_name
      birthday
      language
      short_bio
      full_bio
      picture
      country
      website
      nickname
      protectionLevel
    }
  }
}
    `;
function useGetUserByEmailQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetUserByEmailDocument, options);
}
__name(useGetUserByEmailQuery, "useGetUserByEmailQuery");
function useGetUserByEmailLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetUserByEmailDocument, options);
}
__name(useGetUserByEmailLazyQuery, "useGetUserByEmailLazyQuery");
var UserStringIdDocument = gql7`
    query UserStringID($_id: ID!) {
  user(_id: $_id) {
    __typename
    _id
    ...UserFragment
    paymentSettings {
      stripeCustomer {
        id
        name
        email
        phone
        description
      }
      cardSourceId
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useUserStringIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(UserStringIdDocument, options);
}
__name(useUserStringIdQuery, "useUserStringIdQuery");
function useUserStringIdLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(UserStringIdDocument, options);
}
__name(useUserStringIdLazyQuery, "useUserStringIdLazyQuery");
var GetConnectionsForUserDocument = gql7`
    query GetConnectionsForUser($_id: ID!, $connectionsLimit: Int = 0, $connectionsOffset: Int = 0, $followersLimit: Int = 0, $followersOffset: Int = 0, $followingUsersLimit: Int = 0, $followingUsersOffset: Int = 0) {
  userPage(_id: $_id) {
    user {
      ...UserFragment
      connections(limit: $connectionsLimit, offset: $connectionsOffset) {
        ...UserFragment
      }
      followers(limit: $followersLimit, offset: $followersOffset) {
        ...UserFragment
      }
      profile {
        lausannePdfUrl
        first_name
        following(
          limitCards: 0
          offsetCards: 0
          limitUsers: $followingUsersLimit
          offsetUsers: $followingUsersOffset
        ) {
          users {
            ...UserFragment
          }
        }
      }
    }
    loggedInUser {
      ...UserFragment
      profile {
        lausannePdfUrl
        isFollowingUser(_id: $_id)
        connectionStatus(_id: $_id)
        primaryMembershipId
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useGetConnectionsForUserQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetConnectionsForUserDocument, options);
}
__name(useGetConnectionsForUserQuery, "useGetConnectionsForUserQuery");
function useGetConnectionsForUserLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetConnectionsForUserDocument, options);
}
__name(useGetConnectionsForUserLazyQuery, "useGetConnectionsForUserLazyQuery");
var UsersInSameSiloDocument = gql7`
    query UsersInSameSilo($otherUserId: String) {
  usersInSameSilo(otherUserId: $otherUserId)
}
    `;
function useUsersInSameSiloQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(UsersInSameSiloDocument, options);
}
__name(useUsersInSameSiloQuery, "useUsersInSameSiloQuery");
function useUsersInSameSiloLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(UsersInSameSiloDocument, options);
}
__name(useUsersInSameSiloLazyQuery, "useUsersInSameSiloLazyQuery");
var GetConnectionsDocument = gql7`
    query GetConnections($connectionsLimit: Int = 0, $connectionsOffset: Int = 0, $followersLimit: Int = 0, $followersOffset: Int = 0, $followingUsersLimit: Int = 0, $followingUsersOffset: Int = 0) {
  currentUser {
    ...UserFragment
    connections(limit: $connectionsLimit, offset: $connectionsOffset) {
      ...UserFragment
    }
    followers(limit: $followersLimit, offset: $followersOffset) {
      ...UserFragment
    }
    profile {
      lausannePdfUrl
      first_name
      following(
        limitCards: 0
        offsetCards: 0
        limitUsers: $followingUsersLimit
        offsetUsers: $followingUsersOffset
      ) {
        users {
          ...UserFragment
        }
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useGetConnectionsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetConnectionsDocument, options);
}
__name(useGetConnectionsQuery, "useGetConnectionsQuery");
function useGetConnectionsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetConnectionsDocument, options);
}
__name(useGetConnectionsLazyQuery, "useGetConnectionsLazyQuery");
var CurrentUserDocument = gql7`
    query CurrentUser($createdContentLimit: Int = 0, $createdContentOffset: Int = 0, $connectionsLimit: Int = 0, $connectionsOffset: Int = 0, $followersLimit: Int = 0, $followersOffset: Int = 0, $followingCardsLimit: Int = 0, $followingCardsOffset: Int = 0, $followingUsersLimit: Int = 0, $followingUsersOffset: Int = 0) {
  currentUser {
    ...UserFragment
    connections(limit: $connectionsLimit, offset: $connectionsOffset) {
      ...UserFragment
    }
    followers(limit: $followersLimit, offset: $followersOffset) {
      ...UserFragment
    }
    profile {
      lausannePdfUrl
      birthday
      coverPhoto
      facebookProfile
      instagramProfile
      twitterProfile
      linkedInProfile
      protectionLevel
      newsfeedFilter
      did
    }
    profile {
      lausannePdfUrl
      following(
        limitCards: $followingCardsLimit
        offsetCards: $followingCardsOffset
        limitUsers: $followingUsersLimit
        offsetUsers: $followingUsersOffset
      ) {
        cards {
          ...ContentCardFragment
          routeUrl
          loggedInUserFollows
        }
        users {
          ...UserFragment
        }
      }
      customFields {
        parent {
          __typename
          _id
          url
          title
        }
        values {
          id
          type
          values
        }
      }
    }
    createdContent(limit: $createdContentLimit, offset: $createdContentOffset) {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
    }
    paymentSettings {
      stripeCustomer {
        id
        name
        email
        phone
        description
        default_source
        invoice_settings {
          default_payment_method
        }
      }
      cardSourceId
    }
  }
}
    ${UserFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
function useCurrentUserQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(CurrentUserDocument, options);
}
__name(useCurrentUserQuery, "useCurrentUserQuery");
function useCurrentUserLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(CurrentUserDocument, options);
}
__name(useCurrentUserLazyQuery, "useCurrentUserLazyQuery");
var CurrentUserSimpleDocument = gql7`
    query CurrentUserSimple {
  currentUser {
    ...UserFragment
    profile {
      lausannePdfUrl
      birthday
      coverPhoto
      facebookProfile
      instagramProfile
      twitterProfile
      linkedInProfile
      protectionLevel
      newsfeedFilter
      did
    }
    paymentSettings {
      stripeCustomer {
        id
        name
        email
        phone
        description
        default_source
        invoice_settings {
          default_payment_method
        }
      }
      cardSourceId
    }
    notificationSettings {
      __typename
      type
      channels {
        type
        level
        streams {
          _id
          level
          collection
          expiry
        }
      }
    }
    roles {
      adminOf {
        __typename
        url
        title
      }
    }
    canCreate
    activeGroup {
      __typename
      ...GroupFragment
    }
    membership {
      active {
        __typename
        title
        url
        shortCode
        media {
          thumb
          icon
        }
      }
      activeLibrary {
        __typename
        title
        url
        media {
          thumb
          icon
        }
        library_settings {
          customSignupFields {
            id
            index
            type
            prompt
            options
            selectMultiple
            allowOther
            required
            showOnSignup
            link
            dependsOn {
              id
              values
            }
            groups {
              group {
                _id
                path
                groupActionsAllowed
                currentUserIsMember
                currentUserIsAdmin
                profile {
                  lausannePdfUrl
                  picture
                  full_name
                  short_bio
                }
                settings {
                  approvalRequired
                  protectionLevel
                }
              }
              id
              requireApproval
            }
          }
        }
      }
      all {
        __typename
        title
        url
        media {
          thumb
          icon
        }
      }
    }
    hasExistingPassword
    getStreamBackfilled
  }
}
    ${UserFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
function useCurrentUserSimpleQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(CurrentUserSimpleDocument, options);
}
__name(useCurrentUserSimpleQuery, "useCurrentUserSimpleQuery");
function useCurrentUserSimpleLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(CurrentUserSimpleDocument, options);
}
__name(useCurrentUserSimpleLazyQuery, "useCurrentUserSimpleLazyQuery");
var CurrentUserQuickLinksDocument = gql7`
    query CurrentUserQuickLinks {
  currentUser {
    sideMenu {
      quickLinks {
        tags
        aliases {
          ... on UserAliasHandle {
            url
            title
            type
            user {
              _id
              profile {
                lausannePdfUrl
                picture
                full_name
                short_bio
              }
            }
          }
          ... on GroupAliasHandle {
            url
            title
            type
            group {
              _id
              profile {
                lausannePdfUrl
                full_name
                picture
                short_bio
              }
              isUnpublished
              currentUserIsAdmin
              currentUserIsDirectAdmin
            }
          }
          ... on MessageThreadAliasHandle {
            url
            title
            color
            thumb
            icon
            type
            thread {
              ...MessageThreadFragment
            }
          }
          ... on AliasItem {
            url
            title
            type
            item {
              _id
              type
              title
              description
              reference {
                href
                bookSource {
                  title
                  media {
                    thumb
                  }
                }
              }
              media {
                icon
              }
            }
          }
        }
      }
    }
  }
}
    ${MessageThreadFragmentFragmentDoc}`;
function useCurrentUserQuickLinksQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(CurrentUserQuickLinksDocument, options);
}
__name(useCurrentUserQuickLinksQuery, "useCurrentUserQuickLinksQuery");
function useCurrentUserQuickLinksLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(CurrentUserQuickLinksDocument, options);
}
__name(useCurrentUserQuickLinksLazyQuery, "useCurrentUserQuickLinksLazyQuery");
var CurrentUserFeedDocument = gql7`
    query CurrentUserFeed {
  currentUser {
    personalUserfeed {
      ...UserPersonalFeedFragment
      ...ContentCardReferenceFragment
    }
  }
}
    ${UserPersonalFeedFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
function useCurrentUserFeedQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(CurrentUserFeedDocument, options);
}
__name(useCurrentUserFeedQuery, "useCurrentUserFeedQuery");
function useCurrentUserFeedLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(CurrentUserFeedDocument, options);
}
__name(useCurrentUserFeedLazyQuery, "useCurrentUserFeedLazyQuery");
var UserFeedDocument = gql7`
    query UserFeed($_id: ID!, $limit: Int = 10, $cursor: String, $sortType: String = "live") {
  userFeed(_id: $_id, limit: $limit, cursor: $cursor, sortType: $sortType) {
    __typename
    cursor
    hasMore
    results {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
      ...ContentCardChapterChildrenFragment
    }
    type
  }
}
    ${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}
${ContentCardChapterChildrenFragmentFragmentDoc}`;
function useUserFeedQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(UserFeedDocument, options);
}
__name(useUserFeedQuery, "useUserFeedQuery");
function useUserFeedLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(UserFeedDocument, options);
}
__name(useUserFeedLazyQuery, "useUserFeedLazyQuery");
var UserPageDocument = gql7`
    query UserPage($_id: ID!, $createdContentLimit: Int = 0, $createdContentOffset: Int = 0, $connectionsLimit: Int = 0, $connectionsOffset: Int = 0, $followersLimit: Int = 0, $followersOffset: Int = 0, $followingCardsLimit: Int = 0, $followingCardsOffset: Int = 0, $followingUsersLimit: Int = 0, $followingUsersOffset: Int = 0) {
  userPage(_id: $_id) {
    user {
      ...UserFragment
      createdContent(limit: $createdContentLimit, offset: $createdContentOffset) {
        ...ContentCardFragment
        reference {
          chapterSource {
            title
            url
            prompt
            vote_settings {
              QUALITY {
                active
              }
              RELEVANCE {
                active
              }
              SENTIMENT {
                active
              }
            }
            library_settings {
              engagementTypes
            }
          }
          bookSource {
            title
            url
          }
          shelfSource {
            title
            url
          }
        }
      }
      connections(limit: $connectionsLimit, offset: $connectionsOffset) {
        ...UserFragment
      }
      followers(limit: $followersLimit, offset: $followersOffset) {
        ...UserFragment
      }
      profile {
        lausannePdfUrl
        following(
          limitCards: $followingCardsLimit
          offsetCards: $followingCardsOffset
          limitUsers: $followingUsersLimit
          offsetUsers: $followingUsersOffset
        ) {
          cards {
            ...ContentCardFragment
            routeUrl
            loggedInUserFollows
          }
          users {
            ...UserFragment
          }
        }
        primaryMembershipId
      }
      profile {
        lausannePdfUrl
        coverPhoto
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
        newsfeedFilter
      }
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
          description
          default_source
          invoice_settings {
            default_payment_method
          }
        }
        cardSourceId
      }
    }
    loggedInUser {
      ...UserFragment
      profile {
        lausannePdfUrl
        isFollowingUser(_id: $_id)
        connectionStatus(_id: $_id)
        primaryMembershipId
        newsfeedFilter
      }
      roles {
        roles
      }
    }
  }
}
    ${UserFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}`;
function useUserPageQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(UserPageDocument, options);
}
__name(useUserPageQuery, "useUserPageQuery");
function useUserPageLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(UserPageDocument, options);
}
__name(useUserPageLazyQuery, "useUserPageLazyQuery");
var UserPageSimpleDocument = gql7`
    query UserPageSimple($_id: ID!) {
  userPage(_id: $_id) {
    user {
      ...UserFragment
      type
      profile {
        lausannePdfUrl
        primaryMembershipId
      }
      profile {
        lausannePdfUrl
        coverPhoto
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
        protectionLevel
        newsfeedFilter
      }
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
          description
          default_source
          invoice_settings {
            default_payment_method
          }
        }
        cardSourceId
      }
    }
    loggedInUser {
      ...UserFragment
      profile {
        lausannePdfUrl
        isFollowingUser(_id: $_id)
        connectionStatus(_id: $_id)
        primaryMembershipId
        newsfeedFilter
      }
      roles {
        roles
      }
    }
    isUserInSameSilo
  }
}
    ${UserFragmentFragmentDoc}`;
function useUserPageSimpleQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(UserPageSimpleDocument, options);
}
__name(useUserPageSimpleQuery, "useUserPageSimpleQuery");
function useUserPageSimpleLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(UserPageSimpleDocument, options);
}
__name(useUserPageSimpleLazyQuery, "useUserPageSimpleLazyQuery");
var ListUsersDocument = gql7`
    query ListUsers($searchQuery: String, $libraryUrl: String, $url: String!) {
  listUsers(searchQuery: $searchQuery, libraryUrl: $libraryUrl, url: $url) {
    ...UserFragment
    roles {
      customRolesOf(url: $url) {
        role
        url
      }
      roleOf(url: $url) {
        role
        url
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useListUsersQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ListUsersDocument, options);
}
__name(useListUsersQuery, "useListUsersQuery");
function useListUsersLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ListUsersDocument, options);
}
__name(useListUsersLazyQuery, "useListUsersLazyQuery");
var SearchNetworkGroupDocument = gql7`
    query SearchNetworkGroup($searchQuery: String, $includeSelf: Boolean, $includeTemp: Boolean, $groupId: String) {
  listUsersGroup(
    searchQuery: $searchQuery
    includeSelf: $includeSelf
    includeTemp: $includeTemp
    groupId: $groupId
  ) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
function useSearchNetworkGroupQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(SearchNetworkGroupDocument, options);
}
__name(useSearchNetworkGroupQuery, "useSearchNetworkGroupQuery");
function useSearchNetworkGroupLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(SearchNetworkGroupDocument, options);
}
__name(useSearchNetworkGroupLazyQuery, "useSearchNetworkGroupLazyQuery");
var SearchNetworkDocument = gql7`
    query SearchNetwork($searchQuery: String, $includeSelf: Boolean, $includeTemp: Boolean, $groupId: String) {
  listUsers(
    searchQuery: $searchQuery
    includeSelf: $includeSelf
    includeTemp: $includeTemp
    groupId: $groupId
  ) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
function useSearchNetworkQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(SearchNetworkDocument, options);
}
__name(useSearchNetworkQuery, "useSearchNetworkQuery");
function useSearchNetworkLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(SearchNetworkDocument, options);
}
__name(useSearchNetworkLazyQuery, "useSearchNetworkLazyQuery");
var SearchNetworkWithGroupRoleDocument = gql7`
    query SearchNetworkWithGroupRole($searchQuery: String, $groupId: String!, $includeSelf: Boolean, $includeTemp: Boolean) {
  listUsers(
    searchQuery: $searchQuery
    includeSelf: $includeSelf
    includeTemp: $includeTemp
    groupId: $groupId
  ) {
    ...UserFragment
    roleForGroup(groupId: $groupId)
  }
}
    ${UserFragmentFragmentDoc}`;
function useSearchNetworkWithGroupRoleQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(SearchNetworkWithGroupRoleDocument, options);
}
__name(useSearchNetworkWithGroupRoleQuery, "useSearchNetworkWithGroupRoleQuery");
function useSearchNetworkWithGroupRoleLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(SearchNetworkWithGroupRoleDocument, options);
}
__name(useSearchNetworkWithGroupRoleLazyQuery, "useSearchNetworkWithGroupRoleLazyQuery");
var ListMembersDocument = gql7`
    query ListMembers($searchQuery: String, $limit: Int, $offset: Int, $url: String!, $sortType: String = "az", $roles: [String], $customRoles: [String]) {
  listMembers(
    searchQuery: $searchQuery
    limit: $limit
    offset: $offset
    url: $url
    sortType: $sortType
    roles: $roles
    customRoles: $customRoles
  ) {
    ...UserFragment
    roles {
      customRolesOf(url: $url) {
        role
        url
      }
      roleOf(url: $url) {
        role
        url
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useListMembersQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ListMembersDocument, options);
}
__name(useListMembersQuery, "useListMembersQuery");
function useListMembersLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ListMembersDocument, options);
}
__name(useListMembersLazyQuery, "useListMembersLazyQuery");
var ListGroupsDocument = gql7`
    query ListGroups($searchQuery: String) {
  listGroups(searchQuery: $searchQuery) {
    ...GroupFragment
  }
}
    ${GroupFragmentFragmentDoc}`;
function useListGroupsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ListGroupsDocument, options);
}
__name(useListGroupsQuery, "useListGroupsQuery");
function useListGroupsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ListGroupsDocument, options);
}
__name(useListGroupsLazyQuery, "useListGroupsLazyQuery");
var MySidebarDocument = gql7`
    query MySidebar {
  mySidebar {
    people
    bookmarks
  }
}
    `;
function useMySidebarQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(MySidebarDocument, options);
}
__name(useMySidebarQuery, "useMySidebarQuery");
function useMySidebarLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(MySidebarDocument, options);
}
__name(useMySidebarLazyQuery, "useMySidebarLazyQuery");
var MyCommunityDocument = gql7`
    query MyCommunity($searchQuery: String, $limit: Int, $cursor: String, $sortType: String = "az", $type: CommunityTypes) {
  myCommunity(
    searchQuery: $searchQuery
    limit: $limit
    cursor: $cursor
    sortType: $sortType
    type: $type
  ) {
    __typename
    cursor
    hasMore
    results {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useMyCommunityQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(MyCommunityDocument, options);
}
__name(useMyCommunityQuery, "useMyCommunityQuery");
function useMyCommunityLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(MyCommunityDocument, options);
}
__name(useMyCommunityLazyQuery, "useMyCommunityLazyQuery");
var MyGroupsDocument = gql7`
    query MyGroups($searchQuery: String, $role: [String], $protectionLevel: [ViewPermission], $limit: Int, $cursor: String, $sortType: String = "az", $fullGroupsOnly: Boolean) {
  myGroups(
    searchQuery: $searchQuery
    role: $role
    protectionLevel: $protectionLevel
    limit: $limit
    cursor: $cursor
    sortType: $sortType
    fullGroupsOnly: $fullGroupsOnly
  ) {
    __typename
    cursor
    hasMore
    numRemainingResults
    results {
      ...GroupFragment
    }
  }
}
    ${GroupFragmentFragmentDoc}`;
function useMyGroupsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(MyGroupsDocument, options);
}
__name(useMyGroupsQuery, "useMyGroupsQuery");
function useMyGroupsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(MyGroupsDocument, options);
}
__name(useMyGroupsLazyQuery, "useMyGroupsLazyQuery");
var PopularGroupsDocument = gql7`
    query PopularGroups($searchQuery: String, $role: [String], $protectionLevel: [ViewPermission], $limit: Int = 10, $cursor: String, $sortType: String = "az", $host: String) {
  popularGroups(
    searchQuery: $searchQuery
    role: $role
    protectionLevel: $protectionLevel
    limit: $limit
    cursor: $cursor
    sortType: $sortType
    host: $host
  ) {
    __typename
    cursor
    hasMore
    results {
      ...GroupFragment
    }
  }
}
    ${GroupFragmentFragmentDoc}`;
function usePopularGroupsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(PopularGroupsDocument, options);
}
__name(usePopularGroupsQuery, "usePopularGroupsQuery");
function usePopularGroupsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(PopularGroupsDocument, options);
}
__name(usePopularGroupsLazyQuery, "usePopularGroupsLazyQuery");
var GetAdminFormDocument = gql7`
    query GetAdminForm($url: String!) {
  getAdminForm(url: $url) {
    ...ContentCardLibraryFragment
    ...ContentCardShelfFragment
  }
}
    ${ContentCardLibraryFragmentFragmentDoc}
${ContentCardShelfFragmentFragmentDoc}`;
function useGetAdminFormQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetAdminFormDocument, options);
}
__name(useGetAdminFormQuery, "useGetAdminFormQuery");
function useGetAdminFormLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetAdminFormDocument, options);
}
__name(useGetAdminFormLazyQuery, "useGetAdminFormLazyQuery");
var GetUsersGroupsDocument = gql7`
    query GetUsersGroups {
  currentUser {
    membership {
      active {
        __typename
        ...ContentCardShelfFragment
      }
      activeLibrary {
        __typename
        ...ContentCardLibraryFragment
      }
      all {
        __typename
        _id
        url
        title
        media {
          thumb
          icon
        }
      }
    }
  }
}
    ${ContentCardShelfFragmentFragmentDoc}
${ContentCardLibraryFragmentFragmentDoc}`;
function useGetUsersGroupsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetUsersGroupsDocument, options);
}
__name(useGetUsersGroupsQuery, "useGetUsersGroupsQuery");
function useGetUsersGroupsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetUsersGroupsDocument, options);
}
__name(useGetUsersGroupsLazyQuery, "useGetUsersGroupsLazyQuery");
var ListChildGroupsDocument = gql7`
    query ListChildGroups($_id: String, $cursor: String, $limit: Int = 100, $includeGrandChildren: Boolean = false) {
  groupById(_id: $_id) {
    __typename
    _id
    childGroups(
      cursor: $cursor
      limit: $limit
      includeGrandChildren: $includeGrandChildren
    ) {
      __typename
      hasMore
      cursor
      results {
        ...GroupFragment
      }
    }
  }
}
    ${GroupFragmentFragmentDoc}`;
function useListChildGroupsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ListChildGroupsDocument, options);
}
__name(useListChildGroupsQuery, "useListChildGroupsQuery");
function useListChildGroupsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ListChildGroupsDocument, options);
}
__name(useListChildGroupsLazyQuery, "useListChildGroupsLazyQuery");
var DomainRecommendationsForLoggedInUserDocument = gql7`
    query DomainRecommendationsForLoggedInUser($domain: DomainInput, $limit: Int = 6, $userQuery: String, $cardQuery: String) {
  domainRecommendationsForLoggedInUser(
    domain: $domain
    limit: $limit
    userQuery: $userQuery
    cardQuery: $cardQuery
  ) {
    user {
      ...UserFragment
    }
    userRecommendations {
      user {
        ...UserFragment
      }
      precision
      featureScores {
        field {
          id
          type
          prompt
          customText
        }
        score
      }
    }
    cardRecommendations {
      card {
        ...ContentCardFragment
        ...ContentCardShelfFragment
      }
      precision
      featureScores {
        field {
          id
          type
          prompt
          customText
        }
        score
      }
    }
  }
}
    ${UserFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}
${ContentCardShelfFragmentFragmentDoc}`;
function useDomainRecommendationsForLoggedInUserQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(DomainRecommendationsForLoggedInUserDocument, options);
}
__name(useDomainRecommendationsForLoggedInUserQuery, "useDomainRecommendationsForLoggedInUserQuery");
function useDomainRecommendationsForLoggedInUserLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(DomainRecommendationsForLoggedInUserDocument, options);
}
__name(useDomainRecommendationsForLoggedInUserLazyQuery, "useDomainRecommendationsForLoggedInUserLazyQuery");
var GetRecommendationsForUserDocument = gql7`
    query GetRecommendationsForUser($input: RecommendationsForUserInput!) {
  recommendationsForUser(input: $input) {
    user {
      __typename
      _id
    }
    domains {
      type
      id
      ids
    }
    types
    limit
    recommendations {
      type
      cursor
      hasMore
      recommendations {
        ... on UserRecommendation {
          __typename
          user {
            ...UserFragment
          }
          precision
          featureScores {
            field {
              id
              type
              prompt
              customText
            }
            score
          }
        }
        ... on GroupRecommendation {
          __typename
          group {
            ...GroupFragment
          }
          precision
          featureScores {
            field {
              id
              type
              prompt
              customText
            }
            score
          }
        }
        ... on CardRecommendation {
          __typename
          card {
            ...ContentCardFragment
            ...ContentCardReferenceFragment
          }
          precision
          featureScores {
            field {
              id
              type
              prompt
              customText
            }
            score
          }
        }
      }
    }
  }
}
    ${UserFragmentFragmentDoc}
${GroupFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
function useGetRecommendationsForUserQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetRecommendationsForUserDocument, options);
}
__name(useGetRecommendationsForUserQuery, "useGetRecommendationsForUserQuery");
function useGetRecommendationsForUserLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetRecommendationsForUserDocument, options);
}
__name(useGetRecommendationsForUserLazyQuery, "useGetRecommendationsForUserLazyQuery");
var GroupByShortCodeDocument = gql7`
    query GroupByShortCode($shortCode: String!, $translate: Boolean, $targetLanguage: String) {
  groupByShortCode(shortCode: $shortCode) {
    ...GroupFragment
    relevantSignupFields: allSignupFields(suppressCompletedSurveys: true) {
      parentGroup {
        _id
      }
      manager
      signupFields {
        id
        index
        type
        prompt(translate: $translate, targetLanguage: $targetLanguage)
        customText(translate: $translate, targetLanguage: $targetLanguage)
        options
        translatedOptions: options(
          translate: $translate
          targetLanguage: $targetLanguage
        )
        selectMultiple
        allowOther
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              lausannePdfUrl
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
    }
    paymentSettings {
      stripeAccount {
        id
        email
        country
        charges_enabled
        payouts_enabled
        details_submitted
        external_accounts {
          object
          data {
            country
            currency
          }
          has_more
          url
        }
      }
      isGroupMembershipPaid
    }
    parentSubscriptions: parentGroups(paywallExists: true) {
      ...GroupFragment
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
    }
  }
}
    ${GroupFragmentFragmentDoc}`;
function useGroupByShortCodeQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GroupByShortCodeDocument, options);
}
__name(useGroupByShortCodeQuery, "useGroupByShortCodeQuery");
function useGroupByShortCodeLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GroupByShortCodeDocument, options);
}
__name(useGroupByShortCodeLazyQuery, "useGroupByShortCodeLazyQuery");
var GetEventGroupDocument = gql7`
    query GetEventGroup($_id: String!) {
  groupById(_id: $_id) {
    ...GroupEventFragment
    parentGroup {
      _id
      profile {
        lausannePdfUrl
        full_name
        coverPhoto
        picture
        website
      }
    }
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
    paymentSettings {
      stripeAccount {
        id
        email
        country
        charges_enabled
        payouts_enabled
        details_submitted
        external_accounts {
          object
          data {
            country
            currency
          }
          has_more
          url
        }
      }
      isGroupMembershipPaid
    }
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    doesSellMembership
  }
}
    ${GroupEventFragmentFragmentDoc}`;
function useGetEventGroupQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetEventGroupDocument, options);
}
__name(useGetEventGroupQuery, "useGetEventGroupQuery");
function useGetEventGroupLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetEventGroupDocument, options);
}
__name(useGetEventGroupLazyQuery, "useGetEventGroupLazyQuery");
var GetEventSpeakersDocument = gql7`
    query GetEventSpeakers($eventId: ID!) {
  getSpeakersForEvent(eventId: $eventId) {
    userAlias {
      url
      type
      thumb
      altBio
      altName
      useUserBio
    }
    user {
      ...UserFragment
    }
    sessions {
      ...TimeAndPlaceSettingsFragment
    }
  }
}
    ${UserFragmentFragmentDoc}
${TimeAndPlaceSettingsFragmentFragmentDoc}`;
function useGetEventSpeakersQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetEventSpeakersDocument, options);
}
__name(useGetEventSpeakersQuery, "useGetEventSpeakersQuery");
function useGetEventSpeakersLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetEventSpeakersDocument, options);
}
__name(useGetEventSpeakersLazyQuery, "useGetEventSpeakersLazyQuery");
var GroupPageByIdDocument = gql7`
    query GroupPageById($_id: String!, $accessByEmail: String, $translate: Boolean, $targetLanguage: String, $suppressCompletedSurveys: Boolean = true, $limit: Int = 15, $cursor: String, $childGroupsLimit: Int) {
  groupById(_id: $_id, accessByEmail: $accessByEmail) {
    ...GroupFragment
    currentUserFreshCount
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    doesSellMembership
    relevantSignupFields: allSignupFields(
      suppressCompletedSurveys: $suppressCompletedSurveys
    ) {
      parentGroup {
        _id
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
          picture
          website
        }
      }
      manager
      signupFields {
        id
        index
        type
        prompt(translate: $translate, targetLanguage: $targetLanguage)
        customText(translate: $translate, targetLanguage: $targetLanguage)
        options
        translatedOptions: options(
          translate: $translate
          targetLanguage: $targetLanguage
        )
        selectMultiple
        allowOther
        required
        showOnSignup
        groups {
          group {
            _id
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
    }
    fromTemplate {
      __typename
      _id
      profile {
        lausannePdfUrl
        full_name
        picture
      }
    }
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
    paymentSettings {
      stripeAccount {
        id
        email
        country
        charges_enabled
        payouts_enabled
        details_submitted
        external_accounts {
          object
          data {
            country
            currency
          }
          has_more
          url
        }
      }
      isGroupMembershipPaid
    }
    childGroups(limit: $childGroupsLimit) {
      __typename
      hasMore
      cursor
      results {
        ...GroupFragment
      }
      type
    }
    parentSubscriptions: parentGroups(paywallExists: true) {
      ...GroupFragment
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
    }
    personalBook {
      url
      children {
        title
        subtype
        url
        reference {
          href
        }
      }
    }
    channelsBook {
      title
      url
      media {
        thumb
        icon
      }
      children {
        _id
        title
        url
        childrenCount
        rankOrder
        reference {
          href
        }
      }
    }
    eventBook {
      url
      title
      children {
        _id
        title
        url
        rankOrder
        reference {
          href
        }
      }
    }
    tableOfContents(limit: $limit, cursor: $cursor, sortType: "reverseOrder") {
      __typename
      hasMore
      cursor
      results {
        ...ContentCardReferenceFragment
        ...ContentCardFragment
        ...ContentCardChapterChildrenFragment
      }
      type
    }
    settings {
      tocSettings {
        rankOrder {
          key
          value
        }
      }
    }
    chatroom {
      __typename
      _id
    }
    adminChatroom {
      __typename
      _id
    }
  }
}
    ${GroupFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}
${ContentCardChapterChildrenFragmentFragmentDoc}`;
function useGroupPageByIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GroupPageByIdDocument, options);
}
__name(useGroupPageByIdQuery, "useGroupPageByIdQuery");
function useGroupPageByIdLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GroupPageByIdDocument, options);
}
__name(useGroupPageByIdLazyQuery, "useGroupPageByIdLazyQuery");
var GroupByIdDocument = gql7`
    query GroupById($_id: String!, $accessByEmail: String, $translate: Boolean, $targetLanguage: String, $suppressCompletedSurveys: Boolean = true) {
  groupById(_id: $_id, accessByEmail: $accessByEmail) {
    ...GroupFragment
    relevantSignupFields: allSignupFields(
      suppressCompletedSurveys: $suppressCompletedSurveys
    ) {
      parentGroup {
        _id
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
          picture
          website
        }
      }
      manager
      signupFields {
        id
        index
        type
        prompt(translate: $translate, targetLanguage: $targetLanguage)
        customText(translate: $translate, targetLanguage: $targetLanguage)
        options
        translatedOptions: options(
          translate: $translate
          targetLanguage: $targetLanguage
        )
        selectMultiple
        allowOther
        required
        showOnSignup
        groups {
          group {
            _id
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
    }
    fromTemplate {
      __typename
      _id
      profile {
        lausannePdfUrl
        full_name
        picture
      }
    }
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
    paymentSettings {
      stripeAccount {
        id
        email
        country
        charges_enabled
        payouts_enabled
        details_submitted
        external_accounts {
          object
          data {
            country
            currency
          }
          has_more
          url
        }
      }
      isGroupMembershipPaid
    }
    childGroups {
      __typename
      hasMore
      cursor
      results {
        ...GroupFragment
      }
      type
    }
    parentSubscriptions: parentGroups(paywallExists: true) {
      ...GroupFragment
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
      currentUserHasPreAuthorizedPayment
    }
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    doesSellMembership
    currentUserHasPreAuthorizedPayment
  }
}
    ${GroupFragmentFragmentDoc}`;
function useGroupByIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GroupByIdDocument, options);
}
__name(useGroupByIdQuery, "useGroupByIdQuery");
function useGroupByIdLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GroupByIdDocument, options);
}
__name(useGroupByIdLazyQuery, "useGroupByIdLazyQuery");
var GroupDashboardSourceDocument = gql7`
    query GroupDashboardSource($_id: String!, $suppressMembersDashboard: Boolean = false, $suppressPublicDashboard: Boolean = false) {
  groupById(_id: $_id) {
    publicDashboardSource: dashboardSource(subtype: "PUBLIC_DASHBOARD") @skip(if: $suppressPublicDashboard) {
      subtype
      ...ContentCardReferenceFragment
      ...ContentCardFragment
    }
    membersDashboardSource: dashboardSource(subtype: "DASHBOARD") @skip(if: $suppressMembersDashboard) {
      subtype
      ...ContentCardReferenceFragment
      ...ContentCardFragment
    }
  }
}
    ${ContentCardReferenceFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}`;
function useGroupDashboardSourceQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GroupDashboardSourceDocument, options);
}
__name(useGroupDashboardSourceQuery, "useGroupDashboardSourceQuery");
function useGroupDashboardSourceLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GroupDashboardSourceDocument, options);
}
__name(useGroupDashboardSourceLazyQuery, "useGroupDashboardSourceLazyQuery");
var GroupDashboardDocument = gql7`
    query GroupDashboard($_id: String!, $limit: Int = 15, $cursor: String, $suppressMembersDashboard: Boolean = false, $suppressPublicDashboard: Boolean = false, $suppressChildGroups: Boolean = false) {
  groupById(_id: $_id) {
    ...GroupFragment
    currentUserFreshCount
    publicDashboardSource: dashboardSource(subtype: "PUBLIC_DASHBOARD") @skip(if: $suppressPublicDashboard) {
      subtype
      ...ContentCardReferenceFragment
      ...ContentCardFragment
      ...ContentCardChapterChildrenFragment
    }
    membersDashboardSource: dashboardSource(subtype: "DASHBOARD") @skip(if: $suppressMembersDashboard) {
      subtype
      ...ContentCardReferenceFragment
      ...ContentCardFragment
      ...ContentCardChapterChildrenFragment
    }
    personalBook {
      url
      children {
        title
        subtype
        url
        reference {
          href
        }
      }
    }
    channelsBook {
      title
      url
      media {
        thumb
        icon
      }
      children {
        _id
        title
        url
        rankOrder
        reference {
          href
        }
      }
    }
    membersDashboard: dashboard(
      limit: $limit
      cursor: $cursor
      sortType: "ordered"
      subtype: "DASHBOARD"
    ) @skip(if: $suppressMembersDashboard) {
      ...GroupDashboardFragment
    }
    publicDashboard: dashboard(
      limit: $limit
      cursor: $cursor
      sortType: "ordered"
      subtype: "PUBLIC_DASHBOARD"
    ) @skip(if: $suppressPublicDashboard) {
      ...GroupDashboardFragment
    }
    childGroups @skip(if: $suppressChildGroups) {
      __typename
      hasMore
      cursor
      results {
        ...GroupFragment
      }
      type
    }
    parentSubscriptions: parentGroups(paywallExists: true) {
      ...GroupFragment
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
    }
    tableOfContents(limit: $limit, cursor: $cursor, sortType: "reverseOrder") {
      __typename
      hasMore
      cursor
      results {
        ...ContentCardReferenceFragment
        ...ContentCardFragment
        ...ContentCardChapterChildrenFragment
      }
      type
    }
    settings {
      tocSettings {
        rankOrder {
          key
          value
        }
      }
    }
    paymentSettings {
      stripeAccount {
        id
        email
        country
        charges_enabled
        payouts_enabled
        details_submitted
        external_accounts {
          object
          data {
            country
            currency
          }
          has_more
          url
        }
      }
      isGroupMembershipPaid
    }
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    doesSellMembership
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
    chatroom {
      __typename
      _id
    }
    adminChatroom {
      __typename
      _id
    }
  }
}
    ${GroupFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}
${ContentCardChapterChildrenFragmentFragmentDoc}
${GroupDashboardFragmentFragmentDoc}`;
function useGroupDashboardQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GroupDashboardDocument, options);
}
__name(useGroupDashboardQuery, "useGroupDashboardQuery");
function useGroupDashboardLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GroupDashboardDocument, options);
}
__name(useGroupDashboardLazyQuery, "useGroupDashboardLazyQuery");
var GroupMembershipDocument = gql7`
    query GroupMembership($_id: String!, $ownersLimit: Int = 20, $membersLimit: Int = 20, $ownersCursor: String, $membersCursor: String) {
  groupById(_id: $_id) {
    ...GroupMembershipFragment
  }
}
    ${GroupMembershipFragmentFragmentDoc}`;
function useGroupMembershipQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GroupMembershipDocument, options);
}
__name(useGroupMembershipQuery, "useGroupMembershipQuery");
function useGroupMembershipLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GroupMembershipDocument, options);
}
__name(useGroupMembershipLazyQuery, "useGroupMembershipLazyQuery");
var ValidateGroupUsernameDocument = gql7`
    query ValidateGroupUsername($username: String!) {
  validateGroupUsername(username: $username)
}
    `;
function useValidateGroupUsernameQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ValidateGroupUsernameDocument, options);
}
__name(useValidateGroupUsernameQuery, "useValidateGroupUsernameQuery");
function useValidateGroupUsernameLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ValidateGroupUsernameDocument, options);
}
__name(useValidateGroupUsernameLazyQuery, "useValidateGroupUsernameLazyQuery");
var ValidateGroupShortcodeDocument = gql7`
    query ValidateGroupShortcode($shortCode: String!) {
  validateGroupShortcode(shortCode: $shortCode)
}
    `;
function useValidateGroupShortcodeQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ValidateGroupShortcodeDocument, options);
}
__name(useValidateGroupShortcodeQuery, "useValidateGroupShortcodeQuery");
function useValidateGroupShortcodeLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ValidateGroupShortcodeDocument, options);
}
__name(useValidateGroupShortcodeLazyQuery, "useValidateGroupShortcodeLazyQuery");
var ValidateUserEmailDocument = gql7`
    query ValidateUserEmail($email: String!, $enforceTempUserEmailValidation: Boolean = false) {
  validateUserEmail(
    email: $email
    enforceTempUserEmailValidation: $enforceTempUserEmailValidation
  )
}
    `;
function useValidateUserEmailQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ValidateUserEmailDocument, options);
}
__name(useValidateUserEmailQuery, "useValidateUserEmailQuery");
function useValidateUserEmailLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ValidateUserEmailDocument, options);
}
__name(useValidateUserEmailLazyQuery, "useValidateUserEmailLazyQuery");
var ValidateUsernameDocument = gql7`
    query ValidateUsername($username: String!) {
  validateUsername(username: $username)
}
    `;
function useValidateUsernameQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ValidateUsernameDocument, options);
}
__name(useValidateUsernameQuery, "useValidateUsernameQuery");
function useValidateUsernameLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ValidateUsernameDocument, options);
}
__name(useValidateUsernameLazyQuery, "useValidateUsernameLazyQuery");
var ValidatePhoneNumberDocument = gql7`
    query ValidatePhoneNumber($phone: String!, $enforceTempUserPhoneValidation: Boolean = false, $email: String) {
  validatePhoneNumber(
    phone: $phone
    enforceTempUserPhoneValidation: $enforceTempUserPhoneValidation
    email: $email
  ) {
    userExists
    isPhoneOnlyUser
  }
}
    `;
function useValidatePhoneNumberQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ValidatePhoneNumberDocument, options);
}
__name(useValidatePhoneNumberQuery, "useValidatePhoneNumberQuery");
function useValidatePhoneNumberLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ValidatePhoneNumberDocument, options);
}
__name(useValidatePhoneNumberLazyQuery, "useValidatePhoneNumberLazyQuery");
var PaginatedGroupTemplatesDocument = gql7`
    query PaginatedGroupTemplates($searchQuery: String, $limit: Int, $cursor: String, $sortType: String = "az", $subtypes: [String]) {
  paginatedGroupTemplates(
    searchQuery: $searchQuery
    limit: $limit
    cursor: $cursor
    sortType: $sortType
    subtypes: $subtypes
  ) {
    cursor
    hasMore
    results {
      ...GroupFragment
    }
    type
  }
}
    ${GroupFragmentFragmentDoc}`;
function usePaginatedGroupTemplatesQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(PaginatedGroupTemplatesDocument, options);
}
__name(usePaginatedGroupTemplatesQuery, "usePaginatedGroupTemplatesQuery");
function usePaginatedGroupTemplatesLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(PaginatedGroupTemplatesDocument, options);
}
__name(usePaginatedGroupTemplatesLazyQuery, "usePaginatedGroupTemplatesLazyQuery");
var GetPledgesForGroupDocument = gql7`
    query GetPledgesForGroup($groupId: String!) {
  getPledgesForGroup(groupId: $groupId) {
    __typename
    _id
    url
    title
    pledgeItems {
      ...ContentCardPledgesFragment
    }
    ...ContentCardFragment
  }
}
    ${ContentCardPledgesFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}`;
function useGetPledgesForGroupQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetPledgesForGroupDocument, options);
}
__name(useGetPledgesForGroupQuery, "useGetPledgesForGroupQuery");
function useGetPledgesForGroupLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetPledgesForGroupDocument, options);
}
__name(useGetPledgesForGroupLazyQuery, "useGetPledgesForGroupLazyQuery");
var GetContentForStreamsDocument = gql7`
    query GetContentForStreams($input: [StreamContentSearchInput]!) {
  getContentForStreams(input: $input) {
    display
    stream
  }
}
    `;
function useGetContentForStreamsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetContentForStreamsDocument, options);
}
__name(useGetContentForStreamsQuery, "useGetContentForStreamsQuery");
function useGetContentForStreamsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetContentForStreamsDocument, options);
}
__name(useGetContentForStreamsLazyQuery, "useGetContentForStreamsLazyQuery");
var GetGroupCustomFieldCsvDocument = gql7`
    query GetGroupCustomFieldCsv($groupId: String!) {
  getGroupCustomFieldCsv(groupId: $groupId)
}
    `;
function useGetGroupCustomFieldCsvQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetGroupCustomFieldCsvDocument, options);
}
__name(useGetGroupCustomFieldCsvQuery, "useGetGroupCustomFieldCsvQuery");
function useGetGroupCustomFieldCsvLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetGroupCustomFieldCsvDocument, options);
}
__name(useGetGroupCustomFieldCsvLazyQuery, "useGetGroupCustomFieldCsvLazyQuery");
var GetUserCustomFieldsDocument = gql7`
    query GetUserCustomFields($_id: ID!, $limit: Int = 50, $recommendationsOn: Boolean, $hasSurveyQuestions: Boolean, $cursor: String) {
  user(_id: $_id) {
    activeGroup {
      _id
    }
    groups(
      limit: $limit
      recommendationsOn: $recommendationsOn
      hasSurveyQuestions: $hasSurveyQuestions
      cursor: $cursor
    ) {
      results {
        _id
        profile {
          lausannePdfUrl
          full_name
          short_bio
          picture
        }
        settings {
          recommendations
          customSignupFields {
            id
            index
            type
            prompt
            customText
            options
            selectMultiple
            allowOther
            weight
            required
            showOnSignup
            groups {
              group {
                _id
              }
              id
              requireApproval
            }
            link
            dependsOn {
              id
              values
            }
            defaultComparator
            siblingId
          }
        }
        currentUserIsAdmin
        currentUserHasPreAuthorizedPayment
        currentUserIsDirectAdmin
        currentUserIsMember
      }
      cursor
      hasMore
      type
    }
    profile {
      lausannePdfUrl
      customFields {
        parentGroup {
          _id
        }
        manager
        values {
          id
          values
          type
          comparator
        }
      }
    }
  }
  getPlatform {
    title
    _id
    __typename
    body
    media {
      thumb
      icon
      logoLight
      logoDark
    }
    url
    platformSettings {
      platformGroupOwner
      forms {
        respondents
        formCardUrl
        formSpecificationSettings {
          type
          form {
            fieldGroups {
              groupName
              index
              fieldIds
            }
            required
            fields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              required
              showOnSignup
              groups {
                group {
                  _id
                }
              }
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
        }
      }
    }
  }
}
    `;
function useGetUserCustomFieldsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetUserCustomFieldsDocument, options);
}
__name(useGetUserCustomFieldsQuery, "useGetUserCustomFieldsQuery");
function useGetUserCustomFieldsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetUserCustomFieldsDocument, options);
}
__name(useGetUserCustomFieldsLazyQuery, "useGetUserCustomFieldsLazyQuery");
var GetUserCustomFieldsOnlyDocument = gql7`
    query GetUserCustomFieldsOnly($_id: ID!) {
  user(_id: $_id) {
    profile {
      lausannePdfUrl
      customFields {
        parentGroup {
          _id
        }
        manager
        values {
          id
          values
          type
          comparator
        }
      }
    }
  }
  getPlatform {
    title
    _id
    __typename
    body
    media {
      thumb
      icon
      logoLight
      logoDark
    }
    url
    platformSettings {
      platformGroupOwner
      forms {
        respondents
        formCardUrl
        formSpecificationSettings {
          type
          form {
            fieldGroups {
              groupName
              index
              fieldIds
            }
            required
            fields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              required
              showOnSignup
              groups {
                group {
                  _id
                }
                id
                requireApproval
              }
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
        }
      }
    }
  }
}
    `;
function useGetUserCustomFieldsOnlyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetUserCustomFieldsOnlyDocument, options);
}
__name(useGetUserCustomFieldsOnlyQuery, "useGetUserCustomFieldsOnlyQuery");
function useGetUserCustomFieldsOnlyLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetUserCustomFieldsOnlyDocument, options);
}
__name(useGetUserCustomFieldsOnlyLazyQuery, "useGetUserCustomFieldsOnlyLazyQuery");
var GetJoinGroupInfoDocument = gql7`
    query GetJoinGroupInfo($_id: String!) {
  groupById(_id: $_id) {
    _id
    path
    groupActionsAllowed
    currentUserIsMember
    currentUserIsAdmin
    profile {
      picture
      full_name
      short_bio
    }
    settings {
      approvalRequired
      protectionLevel
    }
  }
}
    `;
function useGetJoinGroupInfoQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetJoinGroupInfoDocument, options);
}
__name(useGetJoinGroupInfoQuery, "useGetJoinGroupInfoQuery");
function useGetJoinGroupInfoLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetJoinGroupInfoDocument, options);
}
__name(useGetJoinGroupInfoLazyQuery, "useGetJoinGroupInfoLazyQuery");
var GetGroupCustomFieldsDocument = gql7`
    query GetGroupCustomFields($_id: String!) {
  groupById(_id: $_id) {
    ...GroupOptimizerDataFragment
    profile {
      lausannePdfUrl
      customFields {
        parent {
          url
          _id
        }
        manager
        parentGroup {
          _id
        }
        values {
          id
          values
          type
          comparator
        }
      }
    }
    allSignupFields {
      parentGroup {
        ...GroupOptimizerDataFragment
      }
    }
  }
}
    ${GroupOptimizerDataFragmentFragmentDoc}`;
function useGetGroupCustomFieldsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetGroupCustomFieldsDocument, options);
}
__name(useGetGroupCustomFieldsQuery, "useGetGroupCustomFieldsQuery");
function useGetGroupCustomFieldsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetGroupCustomFieldsDocument, options);
}
__name(useGetGroupCustomFieldsLazyQuery, "useGetGroupCustomFieldsLazyQuery");
var ValidateCouponDocument = gql7`
    query ValidateCoupon($input: CouponCodeForProductInput) {
  validateCoupon(input: $input) {
    valid
    message
    coupon {
      name
      code
      percentage
      type
      expiration
    }
    productsWithCoupons {
      coupon {
        name
        code
        percentage
        type
        expiration
      }
      product {
        _id
        name
        description
        price
        currency
        type
      }
    }
  }
}
    `;
function useValidateCouponQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ValidateCouponDocument, options);
}
__name(useValidateCouponQuery, "useValidateCouponQuery");
function useValidateCouponLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ValidateCouponDocument, options);
}
__name(useValidateCouponLazyQuery, "useValidateCouponLazyQuery");
var ConvertCurrencyDocument = gql7`
    query ConvertCurrency($to: String!, $from: String!, $amount: String!) {
  convertCurrency(to: $to, from: $from, amount: $amount) {
    value
  }
}
    `;
function useConvertCurrencyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ConvertCurrencyDocument, options);
}
__name(useConvertCurrencyQuery, "useConvertCurrencyQuery");
function useConvertCurrencyLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ConvertCurrencyDocument, options);
}
__name(useConvertCurrencyLazyQuery, "useConvertCurrencyLazyQuery");
var GetProductsForGroupsDocument = gql7`
    query GetProductsForGroups($groupId: ID!) {
  getProductsForGroup(groupId: $groupId) {
    _id
    name
    description
    price
    currency
    type
    fulfillment {
      groupId
      role
    }
    coupons {
      name
      code
      percentage
      type
      expiration
    }
  }
}
    `;
function useGetProductsForGroupsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetProductsForGroupsDocument, options);
}
__name(useGetProductsForGroupsQuery, "useGetProductsForGroupsQuery");
function useGetProductsForGroupsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetProductsForGroupsDocument, options);
}
__name(useGetProductsForGroupsLazyQuery, "useGetProductsForGroupsLazyQuery");
var GetProductByIdDocument = gql7`
    query GetProductById($_id: ID!) {
  getProduct(_id: $_id) {
    _id
    name
    description
    price
    currency
    type
    fulfillment {
      groupId
      role
    }
    coupons {
      name
      code
      percentage
      type
      expiration
    }
  }
}
    `;
function useGetProductByIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetProductByIdDocument, options);
}
__name(useGetProductByIdQuery, "useGetProductByIdQuery");
function useGetProductByIdLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetProductByIdDocument, options);
}
__name(useGetProductByIdLazyQuery, "useGetProductByIdLazyQuery");
var GetUserOrdersDocument = gql7`
    query GetUserOrders($statuses: [String]) {
  getOrdersForLoggedInUser(statuses: $statuses) {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
      charges {
        object
        data {
          amount
          amount_refunded
          refunded
          refunds {
            object
            data {
              created
              amount
              status
              failure_reason
              reason
            }
          }
        }
        url
        has_more
      }
    }
    products {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          currentUserIsAdmin
          currentUserHasPreAuthorizedPayment
          currentUserIsDirectAdmin
          profile {
            lausannePdfUrl
            full_name
            picture
            coverPhoto
          }
          subtype
        }
      }
    }
    purchaseRequests {
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        coupons {
          name
          code
          percentage
          type
          expiration
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      quantity
      couponCode
    }
    productInstances {
      _id
      transferToken
      isCheckedIn
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
    }
    subscription {
      id
      currency
      cancel_at_period_end
      current_period_end
      current_period_start
      status
      created
      days_until_due
      livemode
      next_pending_invoice_item_invoice
    }
    subscriptionInvoices {
      cursor
      hasMore
      results {
        id
        auto_advance
        collection_method
        currency
        created
        description
        period_start
        period_end
        total
        payment_intent {
          id
          amount
          description
          client_secret
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
        }
        status
        subscription {
          currency
          created
        }
      }
      type
    }
    subscriptionUpcomingInvoice {
      id
      description
      period_start
      period_end
      total
      subscription {
        currency
        created
      }
    }
    user {
      _id
      profile {
        lausannePdfUrl
        full_name
      }
    }
    created
    updated
    status
    paymentType
    discountedTotal
  }
}
    `;
function useGetUserOrdersQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetUserOrdersDocument, options);
}
__name(useGetUserOrdersQuery, "useGetUserOrdersQuery");
function useGetUserOrdersLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetUserOrdersDocument, options);
}
__name(useGetUserOrdersLazyQuery, "useGetUserOrdersLazyQuery");
var GetAllSubscriptionOrdersForLoggedInUserDocument = gql7`
    query GetAllSubscriptionOrdersForLoggedInUser($userId: ID!) {
  getAllSubscriptionOrdersForLoggedInUser(userId: $userId) {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
      charges {
        object
        data {
          amount
          amount_refunded
          refunded
          refunds {
            object
            data {
              created
              amount
              status
              failure_reason
              reason
            }
          }
        }
        url
        has_more
      }
    }
    products {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          subtype
        }
      }
    }
    purchaseRequests {
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        coupons {
          name
          code
          percentage
          type
          expiration
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      quantity
      couponCode
    }
    subscription {
      id
      currency
      cancel_at_period_end
      current_period_end
      current_period_start
      status
      created
      days_until_due
      livemode
      next_pending_invoice_item_invoice
    }
    subscriptionInvoices {
      cursor
      hasMore
      results {
        id
        auto_advance
        collection_method
        currency
        created
        description
        period_start
        period_end
        total
        payment_intent {
          id
          amount
          description
          client_secret
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
        }
        status
        subscription {
          currency
          created
        }
      }
      type
    }
    subscriptionUpcomingInvoice {
      id
      description
      period_start
      period_end
      total
      subscription {
        currency
        created
      }
    }
    productInstances {
      _id
      isCheckedIn
      order {
        _id
      }
      transferToken
      transferRecipients {
        user {
          _id
          profile {
            full_name
            picture
          }
        }
        email
      }
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          full_name
          picture
        }
      }
    }
    user {
      _id
      profile {
        full_name
      }
    }
    created
    updated
    status
    paymentType
  }
}
    `;
function useGetAllSubscriptionOrdersForLoggedInUserQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetAllSubscriptionOrdersForLoggedInUserDocument, options);
}
__name(useGetAllSubscriptionOrdersForLoggedInUserQuery, "useGetAllSubscriptionOrdersForLoggedInUserQuery");
function useGetAllSubscriptionOrdersForLoggedInUserLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetAllSubscriptionOrdersForLoggedInUserDocument, options);
}
__name(useGetAllSubscriptionOrdersForLoggedInUserLazyQuery, "useGetAllSubscriptionOrdersForLoggedInUserLazyQuery");
var GetOrderByIdDocument = gql7`
    query GetOrderById($_id: ID!) {
  getOrder(_id: $_id) {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
      charges {
        object
        data {
          amount
          amount_refunded
          refunded
          refunds {
            object
            data {
              created
              amount
              status
              failure_reason
              reason
            }
          }
        }
        url
        has_more
      }
    }
    products {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            coverPhoto
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          subtype
        }
      }
    }
    purchaseRequests {
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        coupons {
          name
          code
          percentage
          type
          expiration
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      quantity
      couponCode
    }
    subscription {
      id
      currency
      cancel_at_period_end
      current_period_end
      current_period_start
      status
      created
      days_until_due
      livemode
      next_pending_invoice_item_invoice
    }
    subscriptionInvoices {
      cursor
      hasMore
      results {
        id
        auto_advance
        collection_method
        currency
        created
        description
        period_start
        period_end
        total
        payment_intent {
          id
          amount
          description
          client_secret
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
        }
        status
        subscription {
          currency
          created
        }
      }
      type
    }
    subscriptionUpcomingInvoice {
      id
      description
      period_start
      period_end
      total
      subscription {
        currency
        created
      }
    }
    productInstances {
      _id
      isCheckedIn
      order {
        _id
      }
      transferToken
      transferRecipients {
        user {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
        email
      }
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
    }
    user {
      _id
      profile {
        lausannePdfUrl
        full_name
      }
    }
    created
    updated
    status
    paymentType
  }
}
    `;
function useGetOrderByIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetOrderByIdDocument, options);
}
__name(useGetOrderByIdQuery, "useGetOrderByIdQuery");
function useGetOrderByIdLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetOrderByIdDocument, options);
}
__name(useGetOrderByIdLazyQuery, "useGetOrderByIdLazyQuery");
var GetPreAuthPaymentOrderForGroupDocument = gql7`
    query GetPreAuthPaymentOrderForGroup($userId: ID!, $groupId: ID!) {
  getPreAuthPaymentOrderForGroup(userId: $userId, groupId: $groupId) {
    _id
    preAuthPayment
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
      charges {
        object
        data {
          amount
          amount_refunded
          refunded
          refunds {
            object
            data {
              created
              amount
              status
              failure_reason
              reason
            }
          }
        }
        url
        has_more
      }
    }
    products {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            coverPhoto
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          subtype
        }
      }
    }
    purchaseRequests {
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      quantity
      couponCode
    }
    subscription {
      id
      currency
      cancel_at_period_end
      current_period_end
      current_period_start
      status
      created
      days_until_due
      livemode
      next_pending_invoice_item_invoice
    }
    subscriptionInvoices {
      cursor
      hasMore
      results {
        id
        auto_advance
        collection_method
        currency
        created
        description
        period_start
        period_end
        total
        payment_intent {
          id
          amount
          description
          client_secret
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
        }
        status
        subscription {
          currency
          created
        }
      }
      type
    }
    subscriptionUpcomingInvoice {
      id
      description
      period_start
      period_end
      total
      subscription {
        currency
        created
      }
    }
    productInstances {
      _id
      transferToken
      isCheckedIn
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
    }
    user {
      _id
      profile {
        lausannePdfUrl
        full_name
      }
    }
    created
    updated
    status
  }
}
    `;
function useGetPreAuthPaymentOrderForGroupQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetPreAuthPaymentOrderForGroupDocument, options);
}
__name(useGetPreAuthPaymentOrderForGroupQuery, "useGetPreAuthPaymentOrderForGroupQuery");
function useGetPreAuthPaymentOrderForGroupLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetPreAuthPaymentOrderForGroupDocument, options);
}
__name(useGetPreAuthPaymentOrderForGroupLazyQuery, "useGetPreAuthPaymentOrderForGroupLazyQuery");
var GetUserOrdersForGroupDocument = gql7`
    query GetUserOrdersForGroup($groupId: ID!, $status: String = "succeeded") {
  getOrdersForUserForGroup(groupId: $groupId, status: $status) {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
      charges {
        object
        data {
          amount
          amount_refunded
          refunded
          refunds {
            object
            data {
              created
              amount
              status
              failure_reason
              reason
            }
          }
        }
        url
        has_more
      }
    }
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          currentUserIsAdmin
          currentUserHasPreAuthorizedPayment
          currentUserIsDirectAdmin
          profile {
            lausannePdfUrl
            full_name
            picture
            coverPhoto
          }
          timeAndPlaceSettings {
            date
            startTime
            endTime
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    purchaseRequests {
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      quantity
      couponCode
    }
    subscription {
      id
      currency
      cancel_at_period_end
      current_period_end
      current_period_start
      status
      created
      days_until_due
      livemode
      next_pending_invoice_item_invoice
    }
    subscriptionInvoices {
      cursor
      hasMore
      results {
        id
        auto_advance
        collection_method
        currency
        created
        description
        period_start
        period_end
        total
        payment_intent {
          id
          amount
          description
          client_secret
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
        }
        status
        subscription {
          currency
          created
        }
      }
      type
    }
    subscriptionUpcomingInvoice {
      id
      description
      period_start
      period_end
      total
      subscription {
        currency
        created
      }
    }
    productInstances {
      _id
      transferToken
      isCheckedIn
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
    }
    user {
      _id
      profile {
        lausannePdfUrl
        full_name
      }
    }
    created
    updated
    status
  }
}
    `;
function useGetUserOrdersForGroupQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetUserOrdersForGroupDocument, options);
}
__name(useGetUserOrdersForGroupQuery, "useGetUserOrdersForGroupQuery");
function useGetUserOrdersForGroupLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetUserOrdersForGroupDocument, options);
}
__name(useGetUserOrdersForGroupLazyQuery, "useGetUserOrdersForGroupLazyQuery");
var GetOrdersForGroupDocument = gql7`
    query GetOrdersForGroup($groupId: ID!, $statuses: [String]) {
  getOrdersForGroup(groupId: $groupId, statuses: $statuses) {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
      charges {
        object
        data {
          amount
          amount_refunded
          refunded
          refunds {
            object
            data {
              created
              amount
              status
              failure_reason
              reason
            }
          }
        }
        url
        has_more
      }
    }
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          currentUserIsAdmin
          currentUserHasPreAuthorizedPayment
          currentUserIsDirectAdmin
          profile {
            lausannePdfUrl
            full_name
            picture
            coverPhoto
          }
          timeAndPlaceSettings {
            date
            startTime
            endTime
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    purchaseRequests {
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        coupons {
          name
          code
          percentage
          type
          expiration
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      quantity
      couponCode
    }
    subscription {
      id
      currency
      cancel_at_period_end
      current_period_end
      current_period_start
      status
      created
      days_until_due
      livemode
      next_pending_invoice_item_invoice
    }
    subscriptionInvoices {
      cursor
      hasMore
      results {
        id
        auto_advance
        collection_method
        currency
        created
        description
        period_start
        period_end
        total
        payment_intent {
          id
          amount
          description
          client_secret
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
        }
        status
        subscription {
          currency
          created
        }
      }
      type
    }
    subscriptionUpcomingInvoice {
      id
      description
      period_start
      period_end
      total
      subscription {
        currency
        created
      }
    }
    productInstances {
      _id
      isCheckedIn
      transferToken
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          lausannePdfUrl
          full_name
          email
          phone
          picture
        }
      }
      purchaser {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
    }
    user {
      _id
      profile {
        lausannePdfUrl
        full_name
        email
        phone
      }
    }
    processedByUser {
      _id
      profile {
        full_name
      }
    }
    paymentType
    orderLocation
    created
    updated
    status
  }
}
    `;
function useGetOrdersForGroupQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetOrdersForGroupDocument, options);
}
__name(useGetOrdersForGroupQuery, "useGetOrdersForGroupQuery");
function useGetOrdersForGroupLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetOrdersForGroupDocument, options);
}
__name(useGetOrdersForGroupLazyQuery, "useGetOrdersForGroupLazyQuery");
var GetOrdersForGroupByProductIdDocument = gql7`
    query GetOrdersForGroupByProductId($productId: ID!, $groupId: ID!, $statuses: [String]) {
  getOrdersForGroupByProductId(
    productId: $productId
    groupId: $groupId
    statuses: $statuses
  ) {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
      charges {
        object
        data {
          amount
          amount_refunded
          refunded
          refunds {
            object
            data {
              created
              amount
              status
              failure_reason
              reason
            }
          }
        }
        url
        has_more
      }
    }
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          currentUserIsAdmin
          currentUserHasPreAuthorizedPayment
          currentUserIsDirectAdmin
          profile {
            lausannePdfUrl
            full_name
            picture
            coverPhoto
          }
          timeAndPlaceSettings {
            date
            startTime
            endTime
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    purchaseRequests {
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        coupons {
          name
          code
          percentage
          type
          expiration
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      quantity
      couponCode
    }
    subscription {
      id
      currency
      cancel_at_period_end
      current_period_end
      current_period_start
      status
      created
      days_until_due
      livemode
      next_pending_invoice_item_invoice
    }
    subscriptionInvoices {
      cursor
      hasMore
      results {
        id
        auto_advance
        collection_method
        currency
        created
        description
        period_start
        period_end
        total
        payment_intent {
          id
          amount
          description
          client_secret
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
        }
        status
        subscription {
          currency
          created
        }
      }
      type
    }
    subscriptionUpcomingInvoice {
      id
      description
      period_start
      period_end
      total
      subscription {
        currency
        created
      }
    }
    productInstances {
      _id
      transferToken
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
    }
    user {
      _id
      profile {
        lausannePdfUrl
        full_name
        picture
        email
        phone
      }
    }
    created
    updated
    status
  }
}
    `;
function useGetOrdersForGroupByProductIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetOrdersForGroupByProductIdDocument, options);
}
__name(useGetOrdersForGroupByProductIdQuery, "useGetOrdersForGroupByProductIdQuery");
function useGetOrdersForGroupByProductIdLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetOrdersForGroupByProductIdDocument, options);
}
__name(useGetOrdersForGroupByProductIdLazyQuery, "useGetOrdersForGroupByProductIdLazyQuery");
var GetOrdersAndProductsForGroupDocument = gql7`
    query GetOrdersAndProductsForGroup($groupId: ID!, $statuses: [String]) {
  getOrdersAndProductsForGroup(groupId: $groupId, statuses: $statuses) {
    orders {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          coupons {
            name
            code
            percentage
            type
            expiration
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            currency
            created
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          currency
          created
        }
      }
      productInstances {
        _id
        isCheckedIn
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
        purchaser {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
      }
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
          email
          phone
        }
      }
      created
      updated
      status
    }
    activeOrders {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          coupons {
            name
            code
            percentage
            type
            expiration
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            currency
            created
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          currency
          created
        }
      }
      productInstances {
        _id
        isCheckedIn
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
        purchaser {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
      }
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
          email
          phone
        }
      }
      created
      updated
      status
    }
    canceledOrders {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          coupons {
            name
            code
            percentage
            type
            expiration
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            currency
            created
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          currency
          created
        }
      }
      productInstances {
        _id
        isCheckedIn
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
        purchaser {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
      }
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
          email
          phone
        }
      }
      created
      updated
      status
    }
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
        }
      }
      sold
      reference {
        ... on Group {
          _id
          currentUserIsAdmin
          currentUserHasPreAuthorizedPayment
          currentUserIsDirectAdmin
          profile {
            lausannePdfUrl
            full_name
            picture
            coverPhoto
          }
          timeAndPlaceSettings {
            date
            startTime
            endTime
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    giftedTickets {
      _id
      transferToken
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
          email
          phone
        }
      }
      purchaser {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
          email
          phone
        }
      }
    }
  }
}
    `;
function useGetOrdersAndProductsForGroupQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetOrdersAndProductsForGroupDocument, options);
}
__name(useGetOrdersAndProductsForGroupQuery, "useGetOrdersAndProductsForGroupQuery");
function useGetOrdersAndProductsForGroupLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetOrdersAndProductsForGroupDocument, options);
}
__name(useGetOrdersAndProductsForGroupLazyQuery, "useGetOrdersAndProductsForGroupLazyQuery");
var GetProductInstancesForUserForGroupDocument = gql7`
    query GetProductInstancesForUserForGroup($groupId: ID!) {
  getProductInstancesForUserForGroup(groupId: $groupId) {
    _id
    transferToken
    isCheckedIn
    transferRecipients {
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      email
    }
    order {
      _id
      created
      updated
      status
    }
    product {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            coverPhoto
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    owner {
      _id
      profile {
        lausannePdfUrl
        full_name
        picture
      }
    }
    purchaser {
      _id
      profile {
        lausannePdfUrl
        full_name
        picture
      }
    }
  }
}
    `;
function useGetProductInstancesForUserForGroupQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetProductInstancesForUserForGroupDocument, options);
}
__name(useGetProductInstancesForUserForGroupQuery, "useGetProductInstancesForUserForGroupQuery");
function useGetProductInstancesForUserForGroupLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetProductInstancesForUserForGroupDocument, options);
}
__name(useGetProductInstancesForUserForGroupLazyQuery, "useGetProductInstancesForUserForGroupLazyQuery");
var GetAllGiftedProductInstancesForUserDocument = gql7`
    query GetAllGiftedProductInstancesForUser($userId: String!) {
  getAllGiftedProductInstancesForUser(userId: $userId) {
    _id
    transferToken
    isCheckedIn
    transferRecipients {
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      email
    }
    order {
      _id
      created
      updated
      status
    }
    product {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            coverPhoto
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    owner {
      _id
      profile {
        lausannePdfUrl
        full_name
        picture
      }
    }
    purchaser {
      _id
      profile {
        lausannePdfUrl
        full_name
        picture
      }
    }
  }
}
    `;
function useGetAllGiftedProductInstancesForUserQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetAllGiftedProductInstancesForUserDocument, options);
}
__name(useGetAllGiftedProductInstancesForUserQuery, "useGetAllGiftedProductInstancesForUserQuery");
function useGetAllGiftedProductInstancesForUserLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetAllGiftedProductInstancesForUserDocument, options);
}
__name(useGetAllGiftedProductInstancesForUserLazyQuery, "useGetAllGiftedProductInstancesForUserLazyQuery");
var GetProductInstanceByIdDocument = gql7`
    query GetProductInstanceById($productInstanceId: ID!) {
  getProductInstance(productInstanceId: $productInstanceId) {
    _id
    isCheckedIn
    transferToken
    product {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
          email
          phone
        }
      }
      reference {
        ... on Group {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            coverPhoto
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    owner {
      _id
      profile {
        lausannePdfUrl
        full_name
        picture
        email
        phone
      }
    }
    purchaser {
      _id
      profile {
        lausannePdfUrl
        full_name
        picture
      }
    }
  }
}
    `;
function useGetProductInstanceByIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetProductInstanceByIdDocument, options);
}
__name(useGetProductInstanceByIdQuery, "useGetProductInstanceByIdQuery");
function useGetProductInstanceByIdLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetProductInstanceByIdDocument, options);
}
__name(useGetProductInstanceByIdLazyQuery, "useGetProductInstanceByIdLazyQuery");
var ValidateProductInstanceTokenDocument = gql7`
    query ValidateProductInstanceToken($productInstanceId: ID!, $token: String!) {
  validateProductInstanceToken(
    productInstanceId: $productInstanceId
    token: $token
  )
}
    `;
function useValidateProductInstanceTokenQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ValidateProductInstanceTokenDocument, options);
}
__name(useValidateProductInstanceTokenQuery, "useValidateProductInstanceTokenQuery");
function useValidateProductInstanceTokenLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ValidateProductInstanceTokenDocument, options);
}
__name(useValidateProductInstanceTokenLazyQuery, "useValidateProductInstanceTokenLazyQuery");
var GetChildrenAccountsDocument = gql7`
    query GetChildrenAccounts($limit: Int, $cursor: String, $sortType: String) {
  getChildrenAccounts(limit: $limit, cursor: $cursor, sortType: $sortType) {
    type
    cursor
    hasMore
    results {
      _id
      profile {
        lausannePdfUrl
        full_name
        simple {
          age
          name
        }
        avatar {
          avatar
          skinTone
        }
      }
      featureSets
    }
  }
}
    `;
function useGetChildrenAccountsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetChildrenAccountsDocument, options);
}
__name(useGetChildrenAccountsQuery, "useGetChildrenAccountsQuery");
function useGetChildrenAccountsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetChildrenAccountsDocument, options);
}
__name(useGetChildrenAccountsLazyQuery, "useGetChildrenAccountsLazyQuery");
var GetChildrenAccountsForCodeDocument = gql7`
    query GetChildrenAccountsForCode($code: String, $limit: Int, $cursor: String, $sortType: String) {
  getChildrenAccountsForCode(
    code: $code
    limit: $limit
    cursor: $cursor
    sortType: $sortType
  ) {
    type
    cursor
    hasMore
    results {
      _id
      profile {
        lausannePdfUrl
        full_name
        simple {
          age
          name
        }
        avatar {
          avatar
          skinTone
        }
      }
      featureSets
    }
  }
}
    `;
function useGetChildrenAccountsForCodeQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetChildrenAccountsForCodeDocument, options);
}
__name(useGetChildrenAccountsForCodeQuery, "useGetChildrenAccountsForCodeQuery");
function useGetChildrenAccountsForCodeLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetChildrenAccountsForCodeDocument, options);
}
__name(useGetChildrenAccountsForCodeLazyQuery, "useGetChildrenAccountsForCodeLazyQuery");
var SearchChildrenAccountsByCodeDocument = gql7`
    query SearchChildrenAccountsByCode($code: String!, $searchQuery: String) {
  searchChildrenAccountsByCode(code: $code, searchQuery: $searchQuery) {
    _id
    profile {
      lausannePdfUrl
      full_name
      simple {
        age
        name
      }
      avatar {
        avatar
        skinTone
      }
    }
    featureSets
  }
}
    `;
function useSearchChildrenAccountsByCodeQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(SearchChildrenAccountsByCodeDocument, options);
}
__name(useSearchChildrenAccountsByCodeQuery, "useSearchChildrenAccountsByCodeQuery");
function useSearchChildrenAccountsByCodeLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(SearchChildrenAccountsByCodeDocument, options);
}
__name(useSearchChildrenAccountsByCodeLazyQuery, "useSearchChildrenAccountsByCodeLazyQuery");
var GetLoggedInCustomerCardByIdDocument = gql7`
    query GetLoggedInCustomerCardById($cardId: String!) {
  getLoggedInCustomerCardById(cardId: $cardId) {
    id
    brand
    exp_month
    exp_year
    last4
    customer
  }
}
    `;
function useGetLoggedInCustomerCardByIdQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetLoggedInCustomerCardByIdDocument, options);
}
__name(useGetLoggedInCustomerCardByIdQuery, "useGetLoggedInCustomerCardByIdQuery");
function useGetLoggedInCustomerCardByIdLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetLoggedInCustomerCardByIdDocument, options);
}
__name(useGetLoggedInCustomerCardByIdLazyQuery, "useGetLoggedInCustomerCardByIdLazyQuery");
var GetLoggedInCustomerCardsDocument = gql7`
    query GetLoggedInCustomerCards($limit: Int = 10, $cursor: String) {
  getLoggedInCustomerCards(limit: $limit, cursor: $cursor) {
    results {
      id
      brand
      exp_month
      exp_year
      last4
      customer
    }
    hasMore
    cursor
  }
}
    `;
function useGetLoggedInCustomerCardsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetLoggedInCustomerCardsDocument, options);
}
__name(useGetLoggedInCustomerCardsQuery, "useGetLoggedInCustomerCardsQuery");
function useGetLoggedInCustomerCardsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetLoggedInCustomerCardsDocument, options);
}
__name(useGetLoggedInCustomerCardsLazyQuery, "useGetLoggedInCustomerCardsLazyQuery");
var GetLoggedInCustomerPaymentMethodsDocument = gql7`
    query GetLoggedInCustomerPaymentMethods($paymentType: String = "card", $limit: Int = 10, $cursor: String) {
  getLoggedInCustomerPaymentMethods(
    paymentType: $paymentType
    limit: $limit
    cursor: $cursor
  ) {
    results {
      id
      customer
      type
      object
      card {
        brand
        exp_month
        exp_year
        last4
        funding
      }
    }
    hasMore
    cursor
  }
}
    `;
function useGetLoggedInCustomerPaymentMethodsQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetLoggedInCustomerPaymentMethodsDocument, options);
}
__name(useGetLoggedInCustomerPaymentMethodsQuery, "useGetLoggedInCustomerPaymentMethodsQuery");
function useGetLoggedInCustomerPaymentMethodsLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetLoggedInCustomerPaymentMethodsDocument, options);
}
__name(useGetLoggedInCustomerPaymentMethodsLazyQuery, "useGetLoggedInCustomerPaymentMethodsLazyQuery");
var ValidateChildVerificationCodeDocument = gql7`
    query ValidateChildVerificationCode($code: String!, $isNewCode: Boolean) {
  validateChildVerificationCode(code: $code, isNewCode: $isNewCode) {
    isValid
    allowAccountSwitcherAccess
  }
}
    `;
function useValidateChildVerificationCodeQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ValidateChildVerificationCodeDocument, options);
}
__name(useValidateChildVerificationCodeQuery, "useValidateChildVerificationCodeQuery");
function useValidateChildVerificationCodeLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ValidateChildVerificationCodeDocument, options);
}
__name(useValidateChildVerificationCodeLazyQuery, "useValidateChildVerificationCodeLazyQuery");
var GetClaimAccountUserInfoDocument = gql7`
    query GetClaimAccountUserInfo($claimToken: String!, $email: String!) {
  getClaimAccountUserInfo(claimToken: $claimToken, email: $email) {
    _id
    type
    profile {
      lausannePdfUrl
      email
      full_name
      first_name
      last_name
      phone
    }
  }
}
    `;
function useGetClaimAccountUserInfoQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetClaimAccountUserInfoDocument, options);
}
__name(useGetClaimAccountUserInfoQuery, "useGetClaimAccountUserInfoQuery");
function useGetClaimAccountUserInfoLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetClaimAccountUserInfoDocument, options);
}
__name(useGetClaimAccountUserInfoLazyQuery, "useGetClaimAccountUserInfoLazyQuery");
var GetNumberOfUsersForCodeDocument = gql7`
    query GetNumberOfUsersForCode($code: String!) {
  getNumberOfUsersForCode(code: $code)
}
    `;
function useGetNumberOfUsersForCodeQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetNumberOfUsersForCodeDocument, options);
}
__name(useGetNumberOfUsersForCodeQuery, "useGetNumberOfUsersForCodeQuery");
function useGetNumberOfUsersForCodeLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetNumberOfUsersForCodeDocument, options);
}
__name(useGetNumberOfUsersForCodeLazyQuery, "useGetNumberOfUsersForCodeLazyQuery");
var ListInvoicesForUserDocument = gql7`
    query ListInvoicesForUser($input: ListInvoiceInput) {
  listInvoicesForUser(input: $input) {
    type
    cursor
    hasMore
    results {
      id
      created
      billing_reason
      description
      period_start
      period_end
      total
      payment_intent {
        id
        amount
        description
        created
      }
      subscription {
        currency
        created
        description
      }
      lines {
        object
        data {
          id
          amount
          description
          plan {
            id
            amount
          }
        }
      }
    }
  }
}
    `;
function useListInvoicesForUserQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(ListInvoicesForUserDocument, options);
}
__name(useListInvoicesForUserQuery, "useListInvoicesForUserQuery");
function useListInvoicesForUserLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(ListInvoicesForUserDocument, options);
}
__name(useListInvoicesForUserLazyQuery, "useListInvoicesForUserLazyQuery");
var GetLastTimeUserDismissedGroupSurveyDocument = gql7`
    query GetLastTimeUserDismissedGroupSurvey($groupId: String!) {
  getLastTimeUserDismissedGroupSurvey(groupId: $groupId)
}
    `;
function useGetLastTimeUserDismissedGroupSurveyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetLastTimeUserDismissedGroupSurveyDocument, options);
}
__name(useGetLastTimeUserDismissedGroupSurveyQuery, "useGetLastTimeUserDismissedGroupSurveyQuery");
function useGetLastTimeUserDismissedGroupSurveyLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetLastTimeUserDismissedGroupSurveyDocument, options);
}
__name(useGetLastTimeUserDismissedGroupSurveyLazyQuery, "useGetLastTimeUserDismissedGroupSurveyLazyQuery");
var CurrentUserIsAdminOfAnyGroupDocument = gql7`
    query CurrentUserIsAdminOfAnyGroup {
  currentUserIsAdminOfAnyGroup
}
    `;
function useCurrentUserIsAdminOfAnyGroupQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(CurrentUserIsAdminOfAnyGroupDocument, options);
}
__name(useCurrentUserIsAdminOfAnyGroupQuery, "useCurrentUserIsAdminOfAnyGroupQuery");
function useCurrentUserIsAdminOfAnyGroupLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(CurrentUserIsAdminOfAnyGroupDocument, options);
}
__name(useCurrentUserIsAdminOfAnyGroupLazyQuery, "useCurrentUserIsAdminOfAnyGroupLazyQuery");
var GetUserFromDidDocument = gql7`
    query GetUserFromDid($did: String!) {
  getUserFromDid(did: $did) {
    _id
    type
    profile {
      lausannePdfUrl
      email
      short_bio
      picture
      coverPhoto
      full_name
      first_name
      last_name
      phone
      facebookProfile
      instagramProfile
      twitterProfile
      linkedInProfile
      protectionLevel
      country
      website
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        state
        country
        postal
      }
      did
    }
  }
}
    `;
function useGetUserFromDidQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetUserFromDidDocument, options);
}
__name(useGetUserFromDidQuery, "useGetUserFromDidQuery");
function useGetUserFromDidLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetUserFromDidDocument, options);
}
__name(useGetUserFromDidLazyQuery, "useGetUserFromDidLazyQuery");
var GetUsersFromDidDocument = gql7`
    query GetUsersFromDid($dids: [String!]!) {
  getUsersFromDid(dids: $dids) {
    _id
    type
    profile {
      lausannePdfUrl
      email
      short_bio
      picture
      coverPhoto
      full_name
      first_name
      last_name
      phone
      facebookProfile
      instagramProfile
      twitterProfile
      linkedInProfile
      protectionLevel
      country
      website
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        state
        country
        postal
      }
      did
    }
  }
}
    `;
function useGetUsersFromDidQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetUsersFromDidDocument, options);
}
__name(useGetUsersFromDidQuery, "useGetUsersFromDidQuery");
function useGetUsersFromDidLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetUsersFromDidDocument, options);
}
__name(useGetUsersFromDidLazyQuery, "useGetUsersFromDidLazyQuery");
var GetStreamGroupFeedDocument = gql7`
    query GetStreamGroupFeed($groupId: ID!, $limit: Int, $cursor: String) {
  getStreamGroupFeed(groupId: $groupId, limit: $limit, cursor: $cursor) {
    cursor
    hasMore
    results {
      id
      actor
      verb
      object
      postUrl
    }
  }
}
    `;
function useGetStreamGroupFeedQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetStreamGroupFeedDocument, options);
}
__name(useGetStreamGroupFeedQuery, "useGetStreamGroupFeedQuery");
function useGetStreamGroupFeedLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetStreamGroupFeedDocument, options);
}
__name(useGetStreamGroupFeedLazyQuery, "useGetStreamGroupFeedLazyQuery");
var GetStreamGroupDashFeedDocument = gql7`
    query GetStreamGroupDashFeed($groupId: ID!, $limit: Int, $cursor: String) {
  getStreamGroupDashFeed(groupId: $groupId, limit: $limit, cursor: $cursor) {
    cursor
    hasMore
    results {
      id
      actor
      verb
      object
      postUrl
    }
  }
}
    `;
function useGetStreamGroupDashFeedQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetStreamGroupDashFeedDocument, options);
}
__name(useGetStreamGroupDashFeedQuery, "useGetStreamGroupDashFeedQuery");
function useGetStreamGroupDashFeedLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetStreamGroupDashFeedDocument, options);
}
__name(useGetStreamGroupDashFeedLazyQuery, "useGetStreamGroupDashFeedLazyQuery");
var GetVcForGroupDocument = gql7`
    query GetVCForGroup($groupId: String!) {
  getVCForGroup(groupId: $groupId)
}
    `;
function useGetVcForGroupQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(GetVcForGroupDocument, options);
}
__name(useGetVcForGroupQuery, "useGetVcForGroupQuery");
function useGetVcForGroupLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(GetVcForGroupDocument, options);
}
__name(useGetVcForGroupLazyQuery, "useGetVcForGroupLazyQuery");
export {
  AccountHolderType,
  AccountStatus,
  AggregationType,
  AnalyticScopeType,
  ApplicationStatus,
  ApprovalType,
  BulkCardAction,
  CardActions,
  CardEngagementType,
  CardPostType,
  CardVoteType,
  Collection,
  CommunityTypes,
  ComparatorType,
  ConnectionAction,
  ConnectionStatus,
  ConvertCurrencyDocument,
  CouponType,
  CourseProgressType,
  CurrentUserDocument,
  CurrentUserFeedDocument,
  CurrentUserIsAdminOfAnyGroupDocument,
  CurrentUserQuickLinksDocument,
  CurrentUserSimpleDocument,
  CustomSignupFieldTypes,
  DomainRecommendationsForLoggedInUserDocument,
  ElectionStatus,
  EngagementFilterType,
  EngagementSortOrder,
  EngagementSortType,
  EngagementType,
  EventAction,
  EventRsvp,
  FilterType,
  FormSpecificationType,
  GetAdminFormDocument,
  GetAllGiftedProductInstancesForUserDocument,
  GetAllSubscriptionOrdersForLoggedInUserDocument,
  GetChildrenAccountsDocument,
  GetChildrenAccountsForCodeDocument,
  GetClaimAccountUserInfoDocument,
  GetConnectionsDocument,
  GetConnectionsForUserDocument,
  GetContentForStreamsDocument,
  GetEventGroupDocument,
  GetEventSpeakersDocument,
  GetGroupCustomFieldCsvDocument,
  GetGroupCustomFieldsDocument,
  GetJoinGroupInfoDocument,
  GetLastTimeUserDismissedGroupSurveyDocument,
  GetLoggedInCustomerCardByIdDocument,
  GetLoggedInCustomerCardsDocument,
  GetLoggedInCustomerPaymentMethodsDocument,
  GetNumberOfUsersForCodeDocument,
  GetOrderByIdDocument,
  GetOrdersAndProductsForGroupDocument,
  GetOrdersForGroupByProductIdDocument,
  GetOrdersForGroupDocument,
  GetPledgesForGroupDocument,
  GetPreAuthPaymentOrderForGroupDocument,
  GetProductByIdDocument,
  GetProductInstanceByIdDocument,
  GetProductInstancesForUserForGroupDocument,
  GetProductsForGroupsDocument,
  GetRecommendationsForUserDocument,
  GetStreamGroupDashFeedDocument,
  GetStreamGroupFeedDocument,
  GetUserByEmailDocument,
  GetUserByPhoneDocument,
  GetUserCustomFieldsDocument,
  GetUserCustomFieldsOnlyDocument,
  GetUserFromDidDocument,
  GetUserOrdersDocument,
  GetUserOrdersForGroupDocument,
  GetUsersFromDidDocument,
  GetUsersGroupsDocument,
  GetVcForGroupDocument,
  GroupActions,
  GroupByIdDocument,
  GroupByShortCodeDocument,
  GroupDashboardDocument,
  GroupDashboardSourceDocument,
  GroupMembershipDocument,
  GroupPageByIdDocument,
  GroupRole,
  GroupSubtype,
  LabelType,
  ListChildGroupsDocument,
  ListGroupsDocument,
  ListInvoicesForUserDocument,
  ListMembersDocument,
  ListUsersDocument,
  LocationType,
  MembershipStyle,
  MessageThreadType,
  MobilizationStatus,
  MobilizationType,
  MyCommunityDocument,
  MyGroupsDocument,
  MySidebarDocument,
  NewsfeedFilter,
  NotificationChannelType,
  NotificationLevel,
  NotificationType,
  OrderLocation,
  PaginatedGroupTemplatesDocument,
  PaymentType,
  PopularGroupsDocument,
  PositionType,
  PostingStatus,
  PostingType,
  RecommendationManager,
  RecommendationType,
  RecruitType,
  ReferenceType,
  ReportingType,
  Resource,
  Role,
  RrCircleSize,
  RrFont,
  RrLineStyle,
  SearchChildrenAccountsByCodeDocument,
  SearchFilterType,
  SearchNetworkDocument,
  SearchNetworkGroupDocument,
  SearchNetworkWithGroupRoleDocument,
  SearchScopeType,
  SocialActions,
  StorageType,
  StripeInvoiceStatus,
  StripeSubscriptionStatus,
  SubscriptionLevel,
  SurveyQuestionSubType,
  SurveyQuestionType,
  TicketCheckInStatusEnum,
  UpdateAction,
  UserDocument,
  UserFeedDocument,
  UserPageDocument,
  UserPageSimpleDocument,
  UserStringIdDocument,
  UserType,
  UsersDocument,
  UsersInSameSiloDocument,
  ValidateChildVerificationCodeDocument,
  ValidateCouponDocument,
  ValidateGroupShortcodeDocument,
  ValidateGroupUsernameDocument,
  ValidatePhoneNumberDocument,
  ValidateProductInstanceTokenDocument,
  ValidateUserEmailDocument,
  ValidateUsernameDocument,
  VerifierType,
  ViewPermission,
  ViewType,
  Violation,
  useConvertCurrencyLazyQuery,
  useConvertCurrencyQuery,
  useCurrentUserFeedLazyQuery,
  useCurrentUserFeedQuery,
  useCurrentUserIsAdminOfAnyGroupLazyQuery,
  useCurrentUserIsAdminOfAnyGroupQuery,
  useCurrentUserLazyQuery,
  useCurrentUserQuery,
  useCurrentUserQuickLinksLazyQuery,
  useCurrentUserQuickLinksQuery,
  useCurrentUserSimpleLazyQuery,
  useCurrentUserSimpleQuery,
  useDomainRecommendationsForLoggedInUserLazyQuery,
  useDomainRecommendationsForLoggedInUserQuery,
  useGetAdminFormLazyQuery,
  useGetAdminFormQuery,
  useGetAllGiftedProductInstancesForUserLazyQuery,
  useGetAllGiftedProductInstancesForUserQuery,
  useGetAllSubscriptionOrdersForLoggedInUserLazyQuery,
  useGetAllSubscriptionOrdersForLoggedInUserQuery,
  useGetChildrenAccountsForCodeLazyQuery,
  useGetChildrenAccountsForCodeQuery,
  useGetChildrenAccountsLazyQuery,
  useGetChildrenAccountsQuery,
  useGetClaimAccountUserInfoLazyQuery,
  useGetClaimAccountUserInfoQuery,
  useGetConnectionsForUserLazyQuery,
  useGetConnectionsForUserQuery,
  useGetConnectionsLazyQuery,
  useGetConnectionsQuery,
  useGetContentForStreamsLazyQuery,
  useGetContentForStreamsQuery,
  useGetEventGroupLazyQuery,
  useGetEventGroupQuery,
  useGetEventSpeakersLazyQuery,
  useGetEventSpeakersQuery,
  useGetGroupCustomFieldCsvLazyQuery,
  useGetGroupCustomFieldCsvQuery,
  useGetGroupCustomFieldsLazyQuery,
  useGetGroupCustomFieldsQuery,
  useGetJoinGroupInfoLazyQuery,
  useGetJoinGroupInfoQuery,
  useGetLastTimeUserDismissedGroupSurveyLazyQuery,
  useGetLastTimeUserDismissedGroupSurveyQuery,
  useGetLoggedInCustomerCardByIdLazyQuery,
  useGetLoggedInCustomerCardByIdQuery,
  useGetLoggedInCustomerCardsLazyQuery,
  useGetLoggedInCustomerCardsQuery,
  useGetLoggedInCustomerPaymentMethodsLazyQuery,
  useGetLoggedInCustomerPaymentMethodsQuery,
  useGetNumberOfUsersForCodeLazyQuery,
  useGetNumberOfUsersForCodeQuery,
  useGetOrderByIdLazyQuery,
  useGetOrderByIdQuery,
  useGetOrdersAndProductsForGroupLazyQuery,
  useGetOrdersAndProductsForGroupQuery,
  useGetOrdersForGroupByProductIdLazyQuery,
  useGetOrdersForGroupByProductIdQuery,
  useGetOrdersForGroupLazyQuery,
  useGetOrdersForGroupQuery,
  useGetPledgesForGroupLazyQuery,
  useGetPledgesForGroupQuery,
  useGetPreAuthPaymentOrderForGroupLazyQuery,
  useGetPreAuthPaymentOrderForGroupQuery,
  useGetProductByIdLazyQuery,
  useGetProductByIdQuery,
  useGetProductInstanceByIdLazyQuery,
  useGetProductInstanceByIdQuery,
  useGetProductInstancesForUserForGroupLazyQuery,
  useGetProductInstancesForUserForGroupQuery,
  useGetProductsForGroupsLazyQuery,
  useGetProductsForGroupsQuery,
  useGetRecommendationsForUserLazyQuery,
  useGetRecommendationsForUserQuery,
  useGetStreamGroupDashFeedLazyQuery,
  useGetStreamGroupDashFeedQuery,
  useGetStreamGroupFeedLazyQuery,
  useGetStreamGroupFeedQuery,
  useGetUserByEmailLazyQuery,
  useGetUserByEmailQuery,
  useGetUserByPhoneLazyQuery,
  useGetUserByPhoneQuery,
  useGetUserCustomFieldsLazyQuery,
  useGetUserCustomFieldsOnlyLazyQuery,
  useGetUserCustomFieldsOnlyQuery,
  useGetUserCustomFieldsQuery,
  useGetUserFromDidLazyQuery,
  useGetUserFromDidQuery,
  useGetUserOrdersForGroupLazyQuery,
  useGetUserOrdersForGroupQuery,
  useGetUserOrdersLazyQuery,
  useGetUserOrdersQuery,
  useGetUsersFromDidLazyQuery,
  useGetUsersFromDidQuery,
  useGetUsersGroupsLazyQuery,
  useGetUsersGroupsQuery,
  useGetVcForGroupLazyQuery,
  useGetVcForGroupQuery,
  useGroupByIdLazyQuery,
  useGroupByIdQuery,
  useGroupByShortCodeLazyQuery,
  useGroupByShortCodeQuery,
  useGroupDashboardLazyQuery,
  useGroupDashboardQuery,
  useGroupDashboardSourceLazyQuery,
  useGroupDashboardSourceQuery,
  useGroupMembershipLazyQuery,
  useGroupMembershipQuery,
  useGroupPageByIdLazyQuery,
  useGroupPageByIdQuery,
  useListChildGroupsLazyQuery,
  useListChildGroupsQuery,
  useListGroupsLazyQuery,
  useListGroupsQuery,
  useListInvoicesForUserLazyQuery,
  useListInvoicesForUserQuery,
  useListMembersLazyQuery,
  useListMembersQuery,
  useListUsersLazyQuery,
  useListUsersQuery,
  useMyCommunityLazyQuery,
  useMyCommunityQuery,
  useMyGroupsLazyQuery,
  useMyGroupsQuery,
  useMySidebarLazyQuery,
  useMySidebarQuery,
  usePaginatedGroupTemplatesLazyQuery,
  usePaginatedGroupTemplatesQuery,
  usePopularGroupsLazyQuery,
  usePopularGroupsQuery,
  useSearchChildrenAccountsByCodeLazyQuery,
  useSearchChildrenAccountsByCodeQuery,
  useSearchNetworkGroupLazyQuery,
  useSearchNetworkGroupQuery,
  useSearchNetworkLazyQuery,
  useSearchNetworkQuery,
  useSearchNetworkWithGroupRoleLazyQuery,
  useSearchNetworkWithGroupRoleQuery,
  useUserFeedLazyQuery,
  useUserFeedQuery,
  useUserLazyQuery,
  useUserPageLazyQuery,
  useUserPageQuery,
  useUserPageSimpleLazyQuery,
  useUserPageSimpleQuery,
  useUserQuery,
  useUserStringIdLazyQuery,
  useUserStringIdQuery,
  useUsersInSameSiloLazyQuery,
  useUsersInSameSiloQuery,
  useUsersLazyQuery,
  useUsersQuery,
  useValidateChildVerificationCodeLazyQuery,
  useValidateChildVerificationCodeQuery,
  useValidateCouponLazyQuery,
  useValidateCouponQuery,
  useValidateGroupShortcodeLazyQuery,
  useValidateGroupShortcodeQuery,
  useValidateGroupUsernameLazyQuery,
  useValidateGroupUsernameQuery,
  useValidatePhoneNumberLazyQuery,
  useValidatePhoneNumberQuery,
  useValidateProductInstanceTokenLazyQuery,
  useValidateProductInstanceTokenQuery,
  useValidateUserEmailLazyQuery,
  useValidateUserEmailQuery,
  useValidateUsernameLazyQuery,
  useValidateUsernameQuery
};
