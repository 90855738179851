import React from 'react';

import { useCurrentUser } from '@stores/User';
import ErrorBoundary from '../generic/errors/ErrorBoundary';
import useModal from '@web/ui/components/modals/hooks/useModal';
import useUserOptimizers from '@web/ui/components/optimizers/hooks/useUserOptimizers';
import usePlatformOptimizer from '@web/ui/components/optimizers/hooks/usePlatformOptimizer';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import Optimizers from '@web/ui/components/optimizers/Optimizers';
import { config } from '@core/config/getConfig';

export const PLATFORM_OPTIMIZER_ID = 'platform';

type UserOptimizersProps = {
    afterUpdate?: () => void;
    initialOptimizerId?: string;
    isGroupPageSurveyPrompt?: boolean;
};

const UserOptimizers: React.FC<UserOptimizersProps> = ({
    afterUpdate,
    initialOptimizerId,
    isGroupPageSurveyPrompt,
}) => {
    const { currentUser } = useCurrentUser();
    const { closeModal } = useModal();
    const { profile, groups, updateQuery, loading, error, platform, rootGroup, homeGroup, setRef } =
        useUserOptimizers(currentUser?._id, afterUpdate);

    // Assume that platform form is first form
    const platformForm = platform?.platformSettings?.forms?.[0];
    const platformFormFields = platformForm?.formSpecificationSettings?.form?.fields;
    const { percentComplete, handleChange, handleCustomFieldsChange, optimizeState, saveChanges } =
        usePlatformOptimizer({
            platformFields: platformFormFields,
            profile,
            updateQuery,
        });

    const handleSavePlatform = () => {
        saveChanges();
        closeModal();
    };

    const platformAsGroup = {
        _id: PLATFORM_OPTIMIZER_ID,
        currentUserIsAdmin: false,
        currentUserIsMember: false,
        profile: {
            full_name: platform?.title,
            short_bio: platform?.body,
            picture: platform?.media?.thumb,
        },
        settings: { recommendations: false, customSignupFields: platformFormFields },
        percentComplete,
        onSave: handleSavePlatform,
        handleCustomFieldsChange,
        handleChange,
        optimizeState,
    };

    // get rootGroup Id from config if it exists
    const rootGroupId = config?.public?.rootGroupId;
    const homeGroupIds = config?.public?.homeGroups?.groupIds ?? [];

    // If returned groups query does not include the root or home groups, fetch them instead (workaround for now)
    const rootAndHomeGroupIds = [rootGroupId, ...homeGroupIds];

    // create a filtered groups list without home and root groups
    const groupsWithoutRoot =
        groups?.filter(group => !rootAndHomeGroupIds?.includes(group?._id)) || [];
    // ordered list of root and home group (always up top)
    let rootAndHomeGroups = [];
    if (rootGroup) rootAndHomeGroups[0] = rootGroup;
    if (homeGroup) rootAndHomeGroups[1] = homeGroup;

    // get platform display setting from config
    const displayPlatformSurvey = config?.public?.displayPlatformFormspec;

    // if displayPlatformSurvey is true, display platform first
    // if rootgroup id is set, display rootgroup first
    // otherwise display groups as is
    // eslint-disable-next-line no-nested-ternary
    let displayGroups = displayPlatformSurvey
        ? [platformAsGroup, ...(groups ?? [])]
        : rootAndHomeGroups
          ? [...rootAndHomeGroups, ...groupsWithoutRoot]
          : groups;

    if (isGroupPageSurveyPrompt) {
        displayGroups = displayGroups.filter(group => group._id === initialOptimizerId);
    }
    return (
        <ErrorBoundary>
            <LoadingLine isLoading={!!loading} error={error} />
            {displayGroups?.length === 0 && !loading && <div>Join more groups!</div>}
            {displayGroups?.length > 0 && (
                <>
                    <Optimizers
                        groups={displayGroups}
                        profile={profile}
                        updateQuery={updateQuery}
                        loading={loading}
                        error={error}
                        isUserOptimizers
                        initialOptimizerId={initialOptimizerId}
                    />
                    {displayGroups?.length > 0 && !loading && <div ref={setRef} />}
                </>
            )}
        </ErrorBoundary>
    );
};

export default UserOptimizers;
