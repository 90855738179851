import React from 'react';
import {useTranslation} from 'react-i18next';

import useModal from '@web/ui/components/modals/hooks/useModal';

import CenterModalHeaderFooter from '@web/ui/components/modals/CenterModalHeaderFooter';
import CommunityListItem from '@web/ui/components/community/CommunityListItem.js';

import {getWhitelabeledKey} from '@core/utilities/constants/languages';

import {ContentCard} from '@shared/welibrary-graphql/types';
import {ContentCardPledgesFragmentFragment} from '@shared/welibrary-graphql/content_card/fragments.hook';

const localNamespace = 'imports.wlWeb.ui.components.card.pledgeCard';

type PledgersListProps = {
    pledgers: ContentCardPledgesFragmentFragment['pledgers'];
    whitelabelVerbage: ContentCard['whitelabelVerbage'];
};

const PledgersList: React.FC<PledgersListProps> = ({ pledgers, whitelabelVerbage }) => {
    const { t } = useTranslation();

    const { closeModal } = useModal();

    const title = t(getWhitelabeledKey(`common:${localNamespace}.pledgers`, whitelabelVerbage));

    const pledgersList = pledgers?.map(pledger => {
        return (
            <CommunityListItem
                onClick={closeModal}
                customButtonClass="button-reset content-button-icon dropdown-dots"
                key={pledger?._id!}
                source={pledger}
                whitelabelVerbage={whitelabelVerbage}
            />
        );
    });

    return <CenterModalHeaderFooter title={title}>{pledgersList}</CenterModalHeaderFooter>;
};

export default PledgersList;
