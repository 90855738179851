import React from 'react';
import { useTranslation } from 'react-i18next';
import ToggleButtonEditScreen from '@web/ui/components/generic/buttons/ToggleButtonEditScreen';

import Input from '@web/ui/components/generic/forms/Input';
import EditScreenItemContainer from '@web/ui/components/generic/editscreen/EditScreenItemContainer';
import EditScreenSelectOne from '@web/ui/components/generic/editscreen/EditScreenSelectOne';
import GroupTemplatePicker from '@web/ui/components/forms/custominputs/GroupTemplatePicker';
import SearchWithSelectedListTabs from '@web/ui/components/generic/editscreen/SearchWithSelectedListTabs';

import {
    ALIAS_EXTERNAL_LINK,
    ALIAS_GENERATOR,
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_SHELF,
} from '@core/utilities/constants/card_types';

import EditableLinkTiles from '../../content/newpost/forms/EditableLinkTiles';

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard.linkTileUpdateForm';

const LinkTileUpdateForm = ({
    card,
    state,
    inheritedSettings,
    handleChange,
    handleToggle,
    currentUser,
    handleSubmit,
    handleClose,
    hasAdminRole,
    deleteAliasItem,
    addSearchItemSelected,
    updateAliasItem,
    cardConstraints,
}) => {
    const { t } = useTranslation();
    const {
        rankOrderConstraint,
        aliasesConstraint,
        displayInNewsfeedConstraint,
        defaultViewTypeConstraint,
    } = cardConstraints;
    const { membership } = currentUser;
    const currentUsersMembershipTitle = membership ? membership?.activeLibrary?.title : '';

    const defaultViewTypes = [
        {
            text: t('common:imports.wlWeb.ui.components.card.darkForms.settings.fullview'),
            value: 'fullview',
        },
        {
            text: t('common:imports.wlWeb.ui.components.card.darkForms.settings.listview'),
            value: 'listview',
        },
    ];

    const handleAddCustomLink = (externalLinkLabel, externalLink) => {
        if (externalLink?.trim() !== '') {
            const actualUrl = `${externalLink.replace('http://', '').replace('https://', '')}`;
            addSearchItemSelected({
                title: externalLinkLabel || externalLink,
                url: actualUrl,
                type: ALIAS_EXTERNAL_LINK,
                // initialize link tile with the chapter || group image (instead of a random image)
                thumb:
                    card?.reference?.chapterSource?.media?.thumb ||
                    card?.reference?.groupSource?.profile?.coverPhoto ||
                    null,
            });
        }
    };

    return (
        <div className="edit-screen-content-view">
            {/* Main Edit Section */}
            <div className="edit-screen-content-items">
                <div className="mc-source-post-wrapper">
                    <div className="edit-screen-item post-edit-item edit-content-card-title-block">
                        <div>
                            {t(`common:${localNamespace}.tiled_links`)}
                            <br />
                        </div>
                    </div>
                    {/* Display Order Setting */}
                    {hasAdminRole && rankOrderConstraint && (
                        <div className="edit-screen-item columns-item content-card-item">
                            <div className="edit-display-order-container">
                                <div className="display-text-inline" style={{ marginTop: '5px' }}>
                                    {t(`common:${localNamespace}.display_order`)}
                                </div>
                                <Input
                                    id="rankOrder"
                                    name="rankOrder"
                                    placeHolder="..."
                                    maxLength="256"
                                    handleChange={handleChange}
                                    value={state.rankOrder}
                                    classNames="text-field margin-clear w-input"
                                    style={{
                                        alignSelf: 'flex-end',
                                        height: '30px',
                                        width: '95px',
                                    }}
                                    type="number"
                                />
                            </div>
                        </div>
                    )}

                    {/* Display Anonymous Toggle */}
                    {hasAdminRole && displayInNewsfeedConstraint && (
                        <div className="edit-screen-item columns-item content-card-item">
                            <div>
                                {t(
                                    'common:imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm.display_in_newsfeed'
                                )}
                                <br />
                            </div>
                            <div className="margin-clear w-form">
                                <ToggleButtonEditScreen
                                    id="displayInNewsfeed"
                                    name="displayInNewsfeed"
                                    handleToggle={handleToggle}
                                    onOption={t('common:global.yes_caps')}
                                    offOption={t('common:global.no_caps')}
                                    toggleValue={state.displayInNewsfeed}
                                />
                            </div>
                        </div>
                    )}

                    {/* Display Anonymous Toggle */}
                    {hasAdminRole && defaultViewTypeConstraint && (
                        <div className="edit-screen-item columns-item content-card-item">
                            <div>
                                Display Style
                                <br />
                            </div>
                            <div className="margin-clear w-form">
                                <EditScreenSelectOne
                                    hideTitle
                                    target="defaultViewType"
                                    options={defaultViewTypes}
                                    selected={
                                        state.defaultViewType || inheritedSettings?.defaultViewType
                                    }
                                    handleChange={handleChange}
                                />
                            </div>
                        </div>
                    )}

                    <div className="edit-screen-item post-edit-item">
                        <div
                            className={`tile-post-wrapper ${
                                state.defaultViewType === 'listview' &&
                                'link-tile-update-container-marginTop'
                            }`}
                        >
                            {/* Show "Links Description" input if we're in list view */}
                            {state.defaultViewType === 'listview' && (
                                <div className="link-tile-update-container">
                                    <div className="linkTile-list-title-input-container">
                                        <input
                                            className="linkTile-list-title-input"
                                            type="text"
                                            value={state.title}
                                            onChange={e => handleChange(e)}
                                            name="title"
                                            placeholder="Links description"
                                        />
                                    </div>
                                </div>
                            )}
                            <EditableLinkTiles
                                card={card}
                                aliases={state.aliases}
                                viewType={state.defaultViewType}
                                disabled={!aliasesConstraint}
                                padListItems
                                handleChange={handleChange}
                                deleteAliasItem={deleteAliasItem}
                                updateAliasItem={updateAliasItem}
                                inEditMode
                            />
                        </div>
                        <div className="divider" />
                        {aliasesConstraint && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.new_tile_link.title`)}
                                subtitle={t(`common:${localNamespace}.new_tile_link.subtitle`, {
                                    membershipTitle: currentUsersMembershipTitle,
                                })}
                                noBottomBorder
                                clearfix
                            >
                                <SearchWithSelectedListTabs
                                    types={[CARD_BOOK, CARD_CHAPTER]}
                                    childrenTypes={[CARD_CHAPTER]}
                                    onSelect={addSearchItemSelected}
                                    overrideContainerStyle={{ borderBottom: '0px' }}
                                    onClickAddButtonLabel={t(
                                        `common:${localNamespace}.external_link`
                                    )}
                                    handleChange={handleChange}
                                    handleAddCustomLink={handleAddCustomLink}
                                    chapter={card}
                                    aliases={state.aliases}
                                    deleteAliasItem={deleteAliasItem}
                                />

                                {hasAdminRole && (
                                    <GroupTemplatePicker
                                        defaultLabel={t('common:global.verbs.add_generator')}
                                        handlePicker={e => {
                                            const templateId = e?.target?.value;
                                            const selectedIndex = e?.target?.selectedIndex;
                                            const templateName =
                                                selectedIndex && e?.target[selectedIndex].label;

                                            if (templateId) {
                                                addSearchItemSelected({
                                                    title: `Create ${templateName}`,
                                                    url: `custom:creategroup:${JSON.stringify({
                                                        initialGeneratorAnswers: {
                                                            templateId,
                                                        },
                                                    })}`,
                                                    type: ALIAS_GENERATOR,
                                                });
                                            }
                                        }}
                                    />
                                )}
                            </EditScreenItemContainer>
                        )}
                    </div>
                </div>
                <div className="edit-footer">
                    {/* <div onClick={props.handleClose} className="button callout-button inverse fadded w-button">Delete</div> */}
                    <div
                        onClick={handleSubmit}
                        className="button callout-button w-button edit-screen-content"
                    >
                        {t('common:global.verbs.save')}
                    </div>
                </div>
                {/* Submit & Cancel Buttons */}
                <div className="edit-content-card-buttons">
                    <div
                        onClick={handleClose}
                        className="cancel-edit-content-button w-inline-block"
                    />
                    <div
                        onClick={handleSubmit}
                        className="approve-edit-content-button w-inline-block"
                    />
                </div>
            </div>
        </div>
    );
};

export default LinkTileUpdateForm;
