import React from 'react';

import useModal from '@components/modals/hooks/useModal';

import { ModalTypes } from '@core/types/Modals';

import Confirmation from '@components/modals/Confirmation';

type UseConfirmationConfirmProps = {
    title?: string;
    text: React.ReactNode;
    onConfirm?: () => void;
    onCancel?: () => void;
    confirmText?: string;
    cancelText?: string;
};

type UseConfirmationProps = {
    widen?: boolean;
    className?: string;
};

/**
 * React hook for showing a confirmation dialog for
 *
 * Use like so:
 *
 * const confirm = useConfirmation();
 *
 * const confirmSomething = () => {
 *     confirm({
 *         text: "Are you sure?",
 *         onConfirm: () => alert("You said yes!"),
 *         onCancel: () => alert("You said no!"),
 *     });
 * };
 *
 * return (
 *     <button type="button" onClick={confirmSomething}>Confirm Something for me please!</button>
 * );
 */
const useConfirmation = ({ widen = false, className = '' }: UseConfirmationProps = {}) => {
    const { newModal } = useModal({ desktop: ModalTypes.Center, mobile: ModalTypes.Center });

    const confirm = ({
        title,
        text,
        onConfirm,
        onCancel,
        confirmText,
        cancelText,
    }: UseConfirmationConfirmProps) => {
        return new Promise<boolean>(resolve => {
            const _onConfirm = () => {
                resolve(true);
                onConfirm?.();
            };

            const _onCancel = () => {
                resolve(false);
                onCancel?.();
            };

            newModal(
                <Confirmation
                    title={title}
                    text={text}
                    onConfirm={_onConfirm}
                    onCancel={_onCancel}
                    confirmText={confirmText}
                    cancelText={cancelText}
                    className={className}
                />,
                {
                    hideButton: true,
                    onClose: _onCancel,
                    className: 'confirmation-modal',
                    sectionClassName: 'confirmation-modal-section',
                    widen,
                    addShadow: widen,
                }
            );
        });
    };

    return confirm;
};

export default useConfirmation;
