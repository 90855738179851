import { useQuery } from '@apollo/client';
import { GET_PAGINATED_NEWSFEED } from '@shared/welibrary-graphql/content_card/queries';
import { useCurrentUser } from '@stores/User';

const useFetchWhatsNew = (filteredCardIds = [], skip = true) => {
    const { currentUser } = useCurrentUser();
    const skipQuery = !currentUser;

    const {
        data: whatsNewData,
        refetch: refetchNewContent,
        networkStatus: fetchNewNetworkStatus,
        loading: fetchNewLoading,
    } = useQuery(GET_PAGINATED_NEWSFEED, {
        variables: { limit: 25 },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        skip: skipQuery,
    });

    const whatsNewCards =
        whatsNewData?.paginatedNewsFeed?.results?.filter(
            card => !card.currentUserHasRead && !filteredCardIds?.includes(card?._id)
        ) || [];

    return {
        // newContentRef,
        // clearNewCards,
        whatsNewCards,
        refetchNewContent,
        fetchNewLoading,
        fetchNewNetworkStatus,
    };
};

export default useFetchWhatsNew;
