import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useApolloClient, useMutation } from '@apollo/client';

import { config } from '@core/config/getConfig';
import searchStore from '@stores/Search';
import useConfirmation from '@components/generic/hooks/useConfirmation';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import { GROUP_ACTIONS } from '@core/utilities/constants/group';
import {
    DELETE_GROUP,
    UNPUBLISH_EVENT,
    PUBLISH_EVENT,
} from '@shared/welibrary-graphql/user/mutations';
import {
    GET_GROUP_DASHBOARD,
    GET_GROUP_MEMBERSHIP,
    GET_CURRENT_USER_SIMPLE,
} from '@shared/welibrary-graphql/user/queries';

import useQuickLinks from '@components/navigation/SideMenu/Navigator/hooks/useQuickLinks';
import useGroupActions from '@components/group/hooks/useGroupActions';
import useModal from '@components/modals/hooks/useModal';
import { useShareLink } from '@components/generic/hooks/useShareLink';
import useGroupSurveyAccessControl from '@web/ui/components/group/useGroupSurveyAccessControl';

import AlertToast from '@components/toast/AlertToast';
import GroupMembers from '@components/group/groupdashboard/GroupMembers/GroupMembers';
import NotifyPanel from '@components/engagement/NotifyPanel';
import EditTickets from '@components/content/newpost/forms/ShoppingCartProducts/EditTickets';
import ManageOrders from '@components/group/groupdashboard/manageOrders/ManageOrders';
import EventGroupCustomSignup from '@components/content/newpost/forms/EventGroup/EventGroupCustomSignup';
import CancelEventModal from '@components/group/groupdashboard/CancelEventModal';
import BroadcastSurveyModal from '@components/broadcastsurvey/BroadcastSurveyModal';

import { getRootUrl } from '@core/utilities/whitelabel_helpers';
import { ModalTypes } from '@core/types/Modals';

import { VIEW_PERMISSION_SECRET, VIEW_PERMISSION_UNSET } from '@core/utilities/constants/roles';
import { defaultAlertToastOptions } from '@core/types/Toast';

import { CARD_EVENT } from '@core/utilities/constants/card_types';
import { Collection } from '@shared/welibrary-graphql/types';

import { isEventInThePast } from '@helpers/events/event.helpers';
import { titleCase } from '@helpers/string.helpers';
import { groupSellsProductsButCantAcceptPayments } from '@components/group/groupdashboard/groupDashboardHelpers';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.group.topRightMenu';

const useGroupDashboardMenu = ({
    currentUser,
    group,
    dashboardGroup,
    handleGroupAnalytics,
    handleOpenUpdateGroup,
    isEventGroup,
}) => {
    const { t } = useTranslation();
    const client = useApolloClient();
    const { addResult, getLinks, removeQuickLink } = useQuickLinks(false);
    const confirm = useConfirmation();
    const { newToast } = useToastActionsContext();
    const { shareLink } = useShareLink();
    const flags = useFlags();
    const enableJuriesRequest = flags.enableJuriesRequest;

    const history = useHistory();
    const match = useRouteMatch();

    const { newModal, closeModal } = useModal({ desktop: 'center' });
    const { newModal: centerModal, closeAllModals } = useModal({ mobile: 'center' });
    const refetchQueries = [GET_GROUP_DASHBOARD, GET_GROUP_MEMBERSHIP, GET_CURRENT_USER_SIMPLE];
    const { primaryActionAvailable, takeGroupActionForUser, handlePurchaseAccess } =
        useGroupActions({
            group,
            refetchQueries,
        });
    const { openSurveyModal, surveyQuestionsExist } = useGroupSurveyAccessControl({ group });

    const {
        _id,
        profile: { full_name },
        settings: { protectionLevel, shortCode },
        currentUserIsAdmin: isUserAdminOfGroup,
        currentUserIsMember: isUserMemberOfGroup,
        shelf,
    } = group;

    const root_url = getRootUrl();

    const isChecklistOpen =
        (match.path === `/checklist/:groupId` || match.path === '/checklist') && isUserAdminOfGroup;

    const isDraftGroup =
        protectionLevel === VIEW_PERMISSION_SECRET || protectionLevel === VIEW_PERMISSION_UNSET;

    const isEvent = group?.subtype === CARD_EVENT;
    const eventIsOver = isEvent ? isEventInThePast(dashboardGroup) : false;

    const canUserInviteOthers = isUserAdminOfGroup;
    const inviteLink = shortCode && `https://${root_url}/signup-group/${shortCode}`;

    const currentUserOwnsMembership = group?.currentUserOwnsMembership ?? false;
    const currentUserOwnsFreeSubscription = group?.currentUserOwnsFreeSubscription ?? false;

    const chatroom = dashboardGroup?.chatroom;
    const adminChatroom = dashboardGroup?.adminChatroom;

    const [deleteGroup] = useMutation(DELETE_GROUP);
    const [unpublishEvent] = useMutation(UNPUBLISH_EVENT);
    const [publishEvent] = useMutation(PUBLISH_EVENT);

    const handleTakeGroupAction = () => {
        takeGroupActionForUser(currentUser._id, primaryActionAvailable.action);
    };

    let eventPrimaryActionIcon;
    if (isEventGroup) {
        if (primaryActionAvailable.action === GROUP_ACTIONS.GROUP_JOIN) {
            eventPrimaryActionIcon = 'Calendar';
        } else if (primaryActionAvailable.action === GROUP_ACTIONS.GROUP_LEAVE) {
            eventPrimaryActionIcon = 'Checklist';
        } else if (
            primaryActionAvailable.action === GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS ||
            primaryActionAvailable.action === GROUP_ACTIONS.GROUP_PURCHASE_ACCESS
        ) {
            eventPrimaryActionIcon = 'Ticket';
        }
    } else if (!isEvent) {
        if (
            primaryActionAvailable.action === GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS ||
            primaryActionAvailable.action === GROUP_ACTIONS.GROUP_PURCHASE_ACCESS ||
            primaryActionAvailable.action === GROUP_ACTIONS.GROUP_CANCEL_PRE_AUTH_PURCHASE
        ) {
            eventPrimaryActionIcon = 'Currency';
        }
    }

    const handleOpenSearch = () => {
        const scopePayload = {
            scope: { groupId: _id, type: 'GROUP' },
            title: full_name,
            type: 'Group',
        };
        searchStore.set.showSearch();
        searchStore.set.currentScope(scopePayload);
    };

    const toggleChecklist = () => {
        closeAllModals();
        history.push({ pathname: isChecklistOpen ? `/g/${_id}` : `/checklist/${_id}` });
    };

    const handleShareGroup = () => {
        const successText =
            group.subtype === 'event'
                ? t(
                    `common:${localNamespace}.event_link_copied_to_clipboard`,
                    'Event dashboard link copied to clipboard.'
                )
                : t(
                    `common:${localNamespace}.group_link_copied_to_clipboard`,
                    'Group dashboard link copied to clipboard.'
                );

        const _shareLink = `https://${root_url}/g/${_id}`;
        shareLink(_shareLink, '', '', successText);
    };

    const handleGroupShelfLink = () => {
        history.push({ pathname: shelf ? `/s/${shelf.url}` : '#' });
    };

    const handleDeleteGroup = async () => {
        const groupName = group?.profile?.full_name;
        if (isEventGroup) {
            newModal(<CancelEventModal group={group} type="delete" />);
        } else {
            if (
                await confirm({
                    text: isEventGroup
                        ? t(
                            'common:imports.wlWeb.ui.components.group.updateGroupForm.delete_event_confirmation',
                            'Are you sure you want to delete this event? Deleting this event will delete all content owned and created by this event group. If any tickets to this event have been sold, refunds will be issued. This can not be undone.'
                        )
                        : t(
                            'common:imports.wlWeb.ui.components.group.updateGroupForm.delete_confirmation',
                            'Are you sure you want to delete this group? Deleting this group will delete all content owned and created by this group. This can not be undone.'
                        ),
                })
            ) {
                deleteGroup({
                    variables: {
                        _id: group?._id,
                    },
                })
                    .then(() => {
                        newToast(
                            <AlertToast
                                boldText={`${groupName} successfully deleted.`}
                                showSuccessIcon
                            />,
                            {
                                ...defaultAlertToastOptions,
                            }
                        );
                        history.push('/home');
                        client.resetStore().catch(err => {
                            logger.error(err);
                            history.push('/home');
                        });
                    })
                    .catch(err => {
                        newToast(<AlertToast boldText={err?.message} showWarningIcon />, {
                            ...defaultAlertToastOptions,
                        });
                    });
            }
        }
    };

    const handleUnpublishEvent = async () => {
        const groupName = group?.profile?.full_name;

        newModal(<CancelEventModal group={group} type="unpublish" />);

        /* if (
            await confirm({
                text: t(
                    'common:imports.wlWeb.ui.components.group.updateGroupForm.unpublish_confirmation',
                    'Are you sure you want to unpublish this event? This will make the event visible only to the host(s). If any tickets have been sold, refunds will be issued.'
                ),
            })
        ) {
            unpublishEvent({
                variables: {
                    _id: group?._id,
                },
            })
                .then(() => {
                    newToast(
                        <AlertToast
                            boldText={`${groupName} successfully unpublished.`}
                            showSuccessIcon
                        />,
                        {
                            ...defaultAlertToastOptions,
                        }
                    );
                })
                .catch(err => {
                    newToast(<AlertToast boldText={err?.message} showWarningIcon />, {
                        ...defaultAlertToastOptions,
                    });
                });
        } */
    };

    const handlePublishEvent = async () => {
        const groupName = group?.profile?.full_name;
        publishEvent({
            variables: {
                _id: group?._id,
            },
        })
            .then(() => {
                newToast(
                    <AlertToast
                        boldText={`${groupName} successfully published.`}
                        showSuccessIcon
                    />,
                    {
                        ...defaultAlertToastOptions,
                    }
                );
            })
            .catch(err => {
                newToast(<AlertToast boldText={err?.message} showWarningIcon />, {
                    ...defaultAlertToastOptions,
                });
            });
    };

    const topRightMenuData = [];

    if (isUserAdminOfGroup) {
        if (shelf?.url) {
            topRightMenuData.push({
                id: 9,
                title: isEventGroup
                    ? t(`common:${localNamespace}.view_event_shelf`)
                    : `${t(
                        'imports.wlWeb.ui.components.card.mediaCard.bodyItems.documentItem.view'
                    )} ${t(
                        `common:imports.wlWeb.ui.components.group.groupDashboard.subscriptions`
                    )}`,
                iconKey: 'Shelf',
                onClick: handleGroupShelfLink,
                onClose: closeAllModals,
            });
        }
        topRightMenuData.push(
            // {
            //     id: 8,
            //     title: isChecklistOpen
            //         ? t(`common:${localNamespace}.close_checklist`)
            //         : t(`common:${localNamespace}.open_checklist`),
            //     iconKey: isChecklistOpen ? 'x' : 'Checklist',
            //     onClick: toggleChecklist,
            //     onClose: closeAllModals,
            // },
            {
                id: 1,
                title: isEventGroup
                    ? t(`common:${localNamespace}.edit_event`)
                    : t(`common:${localNamespace}.edit_group`),
                iconKey: 'Edit',
                onClick: () => handleOpenUpdateGroup(true),
            }
        );

        if (isUserAdminOfGroup) {
            topRightMenuData.push(
                // always show edit product(s) option on dropdown
                // whether the event/group is paid for/not
                {
                    id: 14,
                    title: isEventGroup
                        ? `${t('imports.wlWeb.ui.components.stripe.edit_tickets', 'Edit Tickets')}`
                        : `${t(
                            'imports.wlWeb.ui.components.stripe.edit_subscription',
                            'Edit Subscriptions'
                        )}`,
                    iconKey: isEventGroup ? 'Ticket' : 'Currency',
                    onClick: () => {
                        newModal(<EditTickets group={dashboardGroup} />);
                    },
                }
            );
            topRightMenuData.push(
                // always show order history option on dropdown
                // whether the event/group is paid for/not
                {
                    id: 16,
                    title: 'Manage Orders',
                    iconKey: 'SettingsCog',
                    onClick: () => {
                        newModal(<ManageOrders group={dashboardGroup} />, {
                            mobile: ModalTypes.FullScreen,
                            desktop: isEventGroup ? ModalTypes.Center : ModalTypes.FullScreen,
                            className: 'min-h-[75%] md:h-[75%]',
                        });
                    },
                }
            );
            // Open 'Survey builder' and 'Optimize' event group settings in a new modal
            topRightMenuData.push({
                id: 15,
                title: `${t(`common:${localNamespace}.edit_signup_forms`, 'Edit Signup Forms')}`,
                iconKey: 'Edit',
                onClick: () => {
                    newModal(
                        <EventGroupCustomSignup
                            handleCancel={() => closeModal()}
                            group={dashboardGroup}
                        />,
                        {
                            className: 'center-fixed-header-footer',
                            hideButton: false,
                        },
                        { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
                    );
                },
            });
        }

        if (adminChatroom) {
            topRightMenuData.push({
                id: 7,
                title: isEventGroup
                    ? 'Admin Event Chat'
                    : t(`common:${localNamespace}.admin_group_chat`),
                iconKey: 'Email',
                to: `/messages/${adminChatroom._id}`,
                onClick: closeAllModals,
            });
        }

        if (isEvent && group?.parentGroup) {
            topRightMenuData.push({
                id: 12,
                iconKey: 'Announcement',
                title: 'Notify Members',
                isBottom: true,
                onClick: () =>
                    centerModal(
                        <NotifyPanel
                            parentGroupId={group?.parentGroup?._id}
                            groupId={group?._id}
                            collectionType={Collection.Group}
                        />,
                        { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
                    ),
                onClose: () => false,
            });
        }

        if (isEvent && enableJuriesRequest) {
            topRightMenuData.push({
                id: 18,
                iconKey: 'Announcement',
                title: 'Broadcast Surveys',

                onClick: () =>
                    centerModal(
                        <BroadcastSurveyModal group={group} closeAllModals={closeAllModals} />,
                        {
                            mobile: ModalTypes.FullScreen,
                            desktop: ModalTypes.FullScreen,
                        }
                    ),
                onClose: () => false,
            });
        }
    }

    if (isUserMemberOfGroup || isUserAdminOfGroup) {
        topRightMenuData.push({
            id: 5,
            title: isEventGroup
                ? t(`common:${localNamespace}.search_event`)
                : t(`common:${localNamespace}.search_group`),
            iconKey: 'Search',
            onClick: handleOpenSearch,
        });

        if (surveyQuestionsExist) {
            topRightMenuData.push({
                id: 13,
                title: titleCase(t('common:edit_survey', 'edit survey')),
                iconKey: 'Edit',
                onClick: openSurveyModal,
            });
        }

        if (chatroom) {
            topRightMenuData.push({
                id: 0,
                title: isEventGroup ? 'Event Chat' : t(`common:${localNamespace}.group_chat`),
                iconKey: 'Airplane',
                to: `/messages/${chatroom._id}`,
                onClick: closeAllModals,
            });
        }
    }

    if (!isDraftGroup) {
        if (inviteLink && canUserInviteOthers && currentUser) {
            // Hide members of root group for non-admins.
            if (group?._id !== config.public.rootGroupId || isUserAdminOfGroup) {
                topRightMenuData.push({
                    id: 2,
                    title: t(`common:${localNamespace}.invite_members`),
                    iconKey: 'Invite User',
                    onClick: () => centerModal(<GroupMembers group={group} />),
                });
            }
        }

        if (isUserAdminOfGroup) {
            topRightMenuData.push({
                id: 3,
                title: t(`common:${localNamespace}.view_analytics`),
                iconKey: 'Analytics',
                onClick: () => handleGroupAnalytics(true),
                onClose: closeAllModals,
            });
        }

        if (_id && !groupSellsProductsButCantAcceptPayments(group)) {
            topRightMenuData.push({
                id: 4,
                title: isEventGroup
                    ? t(`common:${localNamespace}.share_event`)
                    : t(`common:${localNamespace}.share_group`),
                iconKey: 'Share',
                onClick: handleShareGroup,
            });
        }
    }

    if (isUserAdminOfGroup && isEventGroup) {
        if (group.isUnpublished) {
            topRightMenuData.push({
                id: 41,
                title: t(`common:${localNamespace}.publish_event`, 'Publish Event'),
                iconKey: 'Publish',
                onClick: handlePublishEvent,
            });
        } else {
            topRightMenuData.push({
                id: 41,
                title: t(`common:${localNamespace}.unpublish_event`, 'Unpublish Event'),
                iconKey: 'Unpublish',
                onClick: handleUnpublishEvent,
            });
        }

        topRightMenuData.push({
            id: 42,
            title: isEventGroup
                ? t(`common:${localNamespace}.delete_event`, 'Delete Event')
                : t(`common:${localNamespace}.delete_group`, 'Delete Group'),
            iconKey: 'Delete',
            onClick: handleDeleteGroup,
        });
    }

    if (
        primaryActionAvailable?.label &&
        primaryActionAvailable?.action !== GROUP_ACTIONS?.GROUP_CANCEL_REQUEST_JOIN &&
        // ! don't rely on primary action for editing tickets/products on group-events
        primaryActionAvailable?.action !== GROUP_ACTIONS?.GROUP_EDIT_PRODUCTS &&
        currentUser
    ) {
        if (isEvent && !eventIsOver) {
            topRightMenuData.push({
                id: 6,
                title: primaryActionAvailable.label,
                iconKey: eventPrimaryActionIcon,
                onClick:
                    primaryActionAvailable?.action !== GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN
                        ? handleTakeGroupAction
                        : () => { },
            });

            if (currentUserOwnsMembership) {
                topRightMenuData.push({
                    id: 17,
                    title: 'Buy more tickets',
                    iconKey: 'TicketPlus',
                    onClick: () => handlePurchaseAccess(),
                });
            }
        }

        if (!isEvent && !currentUserOwnsFreeSubscription) {
            topRightMenuData.push({
                id: 6,
                title: primaryActionAvailable.label,
                iconKey: eventPrimaryActionIcon,
                onClick:
                    primaryActionAvailable?.action !== GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN
                        ? handleTakeGroupAction
                        : () => { },
            });
        }
    }
    const { quickLinks } = getLinks();

    const linkIndex = quickLinks?.findIndex(item => item.url === group._id);

    if (linkIndex > -1) {
        topRightMenuData.push({
            id: 10,
            iconKey: 'x',
            title: t(`common:global.menuOptions.remove_quick_link`),
            onClick: () => {
                removeQuickLink(linkIndex);
            },
            onClose: closeAllModals,
        });
    } else if (currentUser) {
        // only show Add Quick Link if the user is logged in
        topRightMenuData.push({
            id: 11,
            iconKey: 'Plus',
            title: t(`common:global.menuOptions.add_quick_link`),
            onClick: () => {
                addResult(group);
            },
            onClose: closeAllModals,
        });
    }

    return { isUserAdminOfGroup, isChecklistOpen, toggleChecklist, topRightMenuData };
};

export default useGroupDashboardMenu;
