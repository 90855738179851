import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateCardMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';

import useModal from '@components/modals/hooks/useModal';
import useEventListingFormState from '@components/content/newpost/event/useEventListingFormState';

import EventListingForm from '@components/content/newpost/event/EventListingForm';
import ModalLoading from '@components/modals/ModalLoading';

import { getOverviewTime, getEarliestDay, getLatestDay } from '@core/utilities/constants/events';

import { ContentCard } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

import getLogger from '@core/logger';

const logger = getLogger(module);

type UpdateEventListingFormProps = {
    hasAdminRole?: boolean;
    card: Maybify<ContentCard>;
};

const UpdateEventListingForm: React.FC<UpdateEventListingFormProps> = ({ hasAdminRole, card }) => {
    const { t } = useTranslation();
    const [state, setState] = useEventListingFormState(card);
    const [resData, setResData] = useState(null);

    const { newModal, closeModal } = useModal();

    const [updateCard] = useUpdateCardMutation();

    const onSubmit = async () => {
        // Construct payload

        const { overviewFormState, dateAndTimeState } = state;

        const { startTime, endTime, dates } = dateAndTimeState;
        const earliestDay = getEarliestDay(dates, dates[0].startDate);
        const latestDay = getLatestDay(dates, dates[0].startDate);

        const { type, link, platformName, venue } = overviewFormState?.eventLocationSettings;

        const eventLocationSettings = {
            type,
            link,
            platformName,
            venue,
        };

        const eventOverviewDetails = {
            title: overviewFormState.title,
            description: overviewFormState.description,
            dates: dateAndTimeState.dates,
            rsvpLink: overviewFormState?.eventLocationSettings?.rsvpLink,
            startTime: getOverviewTime(new Date(startTime), earliestDay),
            endTime: getOverviewTime(new Date(endTime), latestDay),
            timeZone: dateAndTimeState.timeZone,
            displayStartTime: dateAndTimeState.displayStartTime,
            displayEndTime: dateAndTimeState.displayEndTime,
            eventLocationSettings,
        };

        const payload9 = {
            cardID: card?._id,
            title: overviewFormState?.title,
            body: overviewFormState?.description,
            description: overviewFormState?.description,
            mediaThumb: overviewFormState?.coverImage,
            eventOverviewDetails,
            type: 'event',
        };

        newModal(
            <ModalLoading
                message={t(
                    `common:imports.wlWeb.ui.components.group.eventDashboard.updating_group`,
                    'Updating Event...'
                )}
            />,
            { widen: true, addShadow: true }
        );

        try {
            updateCard({
                variables: {
                    ...payload9,
                },
            }).then(response => {
                closeModal();
                setResData(response);
            });
        } catch (e) {
            logger.error(e);
        }
    };

    return (
        <EventListingForm
            state={state}
            singleImageUpload
            setState={setState}
            onSubmit={onSubmit}
            cardData={resData?.data?.updateCard}
            title="Update Event Listing"
            showSave={true}
        />
    );
};

export default UpdateEventListingForm;
