import { useImmer } from 'use-immer';

import { SurveyQuestionToSurveyQuestionFormState } from '@helpers/surveys/survey.helpers';

import { SurveyFormState } from '@core/types/Surveys';
import { ContentCard } from '@shared/welibrary-graphql/types';

/**
 * Builds up all the necessary state for displaying a Survey Form, optionally taking in an existing
 * survey card to edit
 */
const useSurveyFormState = (cardToUpdate?: Partial<ContentCard>) => {
    return useImmer<SurveyFormState>({
        main: {
            title: cardToUpdate?.title ?? 'New Survey',
            description: cardToUpdate?.description ?? '',
            thumbnail: cardToUpdate?.media?.icon ?? '',
            coverImage: cardToUpdate?.media?.thumb ?? '',
            questions:
                cardToUpdate?.surveyQuestions?.map(SurveyQuestionToSurveyQuestionFormState) ?? [],
        },
        settings: {
            displayInNewsfeed: cardToUpdate?.library_settings?.displayInNewsfeed ?? true,
            rankOrder: cardToUpdate?.rankOrder ?? undefined,
        },
    });
};

export default useSurveyFormState;
