import React from 'react';

import { curriedArraySlice } from '@helpers/state/state.helpers';

import { SurveyThreeAnswerState } from '@core/types/Surveys';
import { SurveyQuestion } from '@shared/welibrary-graphql/types';
import { SetState, Maybify } from '@core/types/Utilities';

type SurveyThreeBoxProps = {
    state: SurveyThreeAnswerState;
    setState: SetState<SurveyThreeAnswerState>;
    question: Maybify<SurveyQuestion>;
    className?: string;
    disabled?: boolean;
    viewOnly?: boolean;
};

const SurveyThreeBox: React.FC<SurveyThreeBoxProps> = ({
    state,
    setState,
    question,
    className = '',
    disabled = false,
    viewOnly = false,
}) => {
    const updateSlice = curriedArraySlice(setState);

    return (
        <section className={`survey-question-three-box ${className}`}>
            {!viewOnly && (
                <>
                    <p>{question.prompt}</p>

                    <input
                        value={state[0]}
                        onChange={e => updateSlice(0, e.target.value)}
                        disabled={disabled}
                    />

                    <input
                        value={state[1]}
                        onChange={e => updateSlice(1, e.target.value)}
                        disabled={disabled}
                    />

                    <input
                        value={state[2]}
                        onChange={e => updateSlice(2, e.target.value)}
                        disabled={disabled}
                    />
                </>
            )}
            {viewOnly && (
                <>
                    <p>{state[0]}</p>
                    <p>{state[1]}</p>
                    <p>{state[2]}</p>
                </>
            )}
        </section>
    );
};

export default SurveyThreeBox;
