import React from 'react';

import useModal from '@web/ui/components/modals/hooks/useModal';
import useConfirmation from '@components/generic/hooks/useConfirmation';

import X from '@web/ui/dsc/svgs/FullSizeX';

import { ModalContainer } from '@core/types/Modals';

const CenterModal: ModalContainer = ({ component, options, open }) => {
    const { closeModal } = useModal();
    const confirm = useConfirmation({ widen: true, className: options?.confirmationClassName });

    const optionalClass = options?.className || 'd-c-modal';
    const hideButton = options?.hideButton;
    const customSectionClass = options?.sectionClassName || '';

    const handleCloseModal = () => {
        if (options.disableCloseHandlers) return;

        if (options?.confirmClose) {
            confirm({ text: options.confirmClose, onConfirm: () => setTimeout(closeModal, 350) });
        } else {
            options?.onClose?.();
            closeModal();
        }
    };

    return (
        <aside
            id="center-modal"
            className={`${optionalClass} ${open ? 'open' : 'closed'} ${
                options?.hideDimmer ? 'hide-dimmer' : ''
            }`}
        >
            {!options?.hideDimmer && (
                <button
                    className="center-modal-dimmer"
                    type="button"
                    onClick={handleCloseModal}
                    aria-label="modal-dimmer"
                    aria-hidden
                />
            )}
            <section
                className={`${optionalClass} ${options?.widen ? 'widen' : ''} ${
                    options?.addShadow ? 'add-shadow' : ''
                } ${customSectionClass}`}
            >
                {!hideButton && (
                    <button type="button" className="center-modal-x" onClick={handleCloseModal}>
                        <X strokeWidth="4" />
                    </button>
                )}
                {component}
            </section>
        </aside>
    );
};

export default CenterModal;
