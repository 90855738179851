/* eslint-disable no-restricted-globals */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';

import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';
import useGroupActions from '@web/ui/components/group/hooks/useGroupActions.js';
import useConfirmation from '@components/generic/hooks/useConfirmation';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import AlertToast from '@components/toast/AlertToast';
import MobileMenuList from '@web/ui/components/modals/MobileMenuList';
import QuickProfileView from '@web/ui/components/profile/QuickProfileView/QuickProfileView';
import Picture from '@web/ui/components/generic/Picture';
import Plus from '@web/ui/dsc/svgs/FullSizePlus';
import Minus from '@web/ui/dsc/svgs/Minus';
import DropdownController from '@web/ui/components/generic/dropdownmenu/DropdownController';
import StripePreAuthPaymentOrderDetails from '@components/profile/stripe/StripePreAuthPaymentOrderDetails';

import EventInviteModal from '@components/group/groupdashboard/GroupMembers/EventInviteModal';

import { FLAG_USER, UPDATE_USER_CONNECTION } from '@shared/welibrary-graphql/user/mutations';
import { CREATE_MESSAGE_THREAD } from '@shared/welibrary-graphql/messaging/mutations';

import { getSocialActionDropdownItems } from '@core/utilities/constants/user';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
} from '@core/utilities/constants/defaults';
import { defaultAlertToastOptions } from '@core/types/Toast';
import isGroupSubscription from '@components/group/groupdashboard/groupDashboardHelpers';

import getLogger from '@core/logger';
import { CARD_EVENT } from '@core/utilities/constants/card_types';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';
import { GET_GROUP_DASHBOARD, GET_GROUP_MEMBERSHIP } from '@shared/welibrary-graphql/user/queries';

const logger = getLogger(module);

export default ({ group, member, onUpdateUserState, preAuthPayment = false }) => {
    const { currentUser } = useCurrentUser();
    const { newModal, closeModal } = useModal();
    const { newModal: centerModal } = useModal({ mobile: 'center' });
    const confirm = useConfirmation();
    const client = useApolloClient();
    const history = useHistory();
    const { newToast } = useToastActionsContext();

    const [updateConnection] = useMutation(UPDATE_USER_CONNECTION);
    const [flagUser] = useMutation(FLAG_USER);
    const [createMessageThread] = useMutation(CREATE_MESSAGE_THREAD);

    const { t } = useTranslation();

    const localNamespace =
        group?.subtype === 'event'
            ? 'imports.wlWeb.ui.components.group.eventDashboard'
            : 'imports.wlWeb.ui.components.group.groupDashboard';

    const isUserAdminOfGroup = group.currentUserIsAdmin ? group.currentUserIsAdmin : false;

    const { addUserAsAdmin, removeUser, addUserAsMember, isHomeGroup, demoteUserFromAdmin } =
        useGroupActions({ group }, [GET_GROUP_DASHBOARD, GET_GROUP_MEMBERSHIP]);

    const handleEventRegisterInviteModal = async emails => {
        let productsToDisplay;
        const _isGroupSubscription = isGroupSubscription(group);

        if (group?.paymentSettings?.isGroupMembershipPaid && group?.doesSellMembership && group?.subtype === CARD_EVENT) {
            productsToDisplay = group?.products.filter(
                product => product.type !== PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP
            );
        } else if (_isGroupSubscription) {
            productsToDisplay = group?.products.filter(
                product => product.type === PRODUCT_TYPES.GROUP_FREE_SUBSCRIPTION
            );
        } else {
            productsToDisplay = group?.products.filter(
                product => product.type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP
            );
        }
        centerModal(
            <EventInviteModal
                user={member}
                group={group}
                emails={emails}
                tickets={productsToDisplay}
                onClick={async () => {
                    const confirmed = await confirm({
                        text: t(`common:${localNamespace}.confirm_invite_people`, {
                            peopleCount: emails.length,
                        }),
                    });

                    if (confirmed) {
                        addUserAsMember(member._id).then(async () => {
                            newToast(
                                <AlertToast boldText="Invite sent successfully!" showSuccessIcon />,
                                {
                                    ...defaultAlertToastOptions,
                                }
                            );

                            setTimeout(async () => {
                                closeModal();
                            }, 500);
                        });
                    }
                }}
            />
        );
    };

    const getQuickMenuActionsForMember = source => {
        let actions = [];
        if (isUserAdminOfGroup) {
            // If group member is an admin already or not
            if (member?.roleForGroup === 'owner') {
                actions.push({
                    id: 'remove-as-admin',
                    title: t(`common:${localNamespace}.remove_as_admin`),
                    onClick: async () => {
                        if (
                            source &&
                            // eslint-disable-next-line no-restricted-globals
                            (await confirm({
                                text: t(`common:${localNamespace}.confirm_admin_removal`, {
                                    profileName: source.profile?.full_name ?? '',
                                }),
                            }))
                        ) {
                            demoteUserFromAdmin?.(source._id).then(() => {
                                onUpdateUserState();
                            });
                        }
                    },
                });
            }
            if (member?.roleForGroup === 'member') {
                actions.push({
                    id: 'make-admin',
                    title: t(`common:${localNamespace}.make_group_admin`),
                    onClick: async () => {
                        if (
                            source &&
                            // eslint-disable-next-line no-restricted-globals
                            (await confirm({
                                text: t(`common:${localNamespace}.confirm_make_admin`, {
                                    profileName: source.profile?.full_name ?? '',
                                }),
                            }))
                        ) {
                            addUserAsAdmin?.(source._id).then(() => {
                                onUpdateUserState();
                            });
                        }
                    },
                });
                actions.push({
                    id: 'remove-from-group',
                    title: t(`common:${localNamespace}.remove_from_group`, 'Remove from group'),
                    onClick: async () => {
                        if (
                            source &&
                            // eslint-disable-next-line no-restricted-globals
                            (await confirm({
                                text: t(`common:${localNamespace}.confirm_group_removal`, {
                                    profileName: source.profile?.full_name ?? '',
                                }),
                            }))
                        ) {
                            removeUser?.(source._id).then(() => {
                                onUpdateUserState();
                            });
                        }
                    },
                });
            }

            // Users are not allowed to leave the designated home group of their network.
        } else if (currentUser && source?._id === currentUser._id && !isHomeGroup) {
            actions = [
                {
                    id: 'remove-from-group',
                    title: t(`common:${localNamespace}.leave_group`),
                    onClick: async () => {
                        // eslint-disable-next-line no-restricted-globals
                        if (await confirm({ text: t(`common:${localNamespace}.confirm_leave`) })) {
                            removeUser?.(currentUser._id).then(() => {
                                onUpdateUserState();
                                client
                                    .resetStore()
                                    .then(() => history?.push('/dashboard'))
                                    .catch(err => {
                                        logger.error(err);
                                        history?.push('/dashboard');
                                    });
                            });
                        }
                    },
                },
            ];
        }
        return actions;
    };

    const openQuickMenu = items =>
        newModal(<MobileMenuList menuList={items} showIcons />, { className: 'no-min-height' });

    const additionalMenuItems = getSocialActionDropdownItems(
        member,
        {
            updateConnection,
            flagUser,
            createMessageThread,
            currentUser,
            history,
        },
        openQuickMenu,
        t
    );

    const _quickMenuList = [...getQuickMenuActionsForMember(member), ...additionalMenuItems];

    const getRole = user => {
        if (user) {
            if (member.roleForGroup) {
                return { role: member.roleForGroup, groupId: group._id };
            }
        }
        return { role: 'none', groupId: group._id };
    };

    const handleAddUser = async () => {
        const _isGroupSubscription = isGroupSubscription(group);

        if (
            group &&
            group?.subtype === CARD_EVENT &&
            group?.currentUserIsAdmin &&
            group?.currentUserIsDirectAdmin
        ) {
            handleEventRegisterInviteModal([member?.profile?.email]);
        } else if (group && _isGroupSubscription) {
            handleEventRegisterInviteModal([member?.profile?.email]);
        } else {
            const confirmed = await confirm({
                text: `Are you sure you want to add ${member.profile.full_name}?`,
            });

            if (confirmed) {
                addUserAsMember(member._id).then(() => {
                    onUpdateUserState(false);
                });
            }
        }
    };

    const handleRemoveUser = async () => {
        const _isGroupSubscription = isGroupSubscription(group);
        if (_isGroupSubscription && preAuthPayment) {
            newModal(
                <StripePreAuthPaymentOrderDetails
                    userId={member?._id}
                    groupId={group?._id}
                    closeModal={closeModal}
                    mode="deny"
                />
            );
            return;
        }

        if (member.profile.loggedInUserConnectionStatus === 'isLoggedInUser') {
            newToast(
                <AlertToast
                    boldText={t(
                        `common:${localNamespace}.cannot_remove_self`,
                        'Cannot remove yourself!'
                    )}
                    showWarningIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
        } else if (
            await confirm({ text: `Are you sure you want to remove ${member.profile.full_name}?` })
        ) {
            removeUser(member._id).then(() => {
                onUpdateUserState();
            });
        }
    };

    if (!member) {
        return null;
    }

    const defaultRole = getRole(member).role;
    let canAddMember;
    let canRemoveMember;

    let statusText = '';

    switch (defaultRole) {
        case 'owner':
            statusText = t(`common:${localNamespace}.admin`);
            canAddMember = false;
            canRemoveMember = false;
            break;
        case 'member':
            statusText = t(`common:${localNamespace}.member`);
            canAddMember = false;
            canRemoveMember = true;
            break;
        case 'invited':
            statusText = 'Invited';
            canAddMember = false;
            canRemoveMember = true;
            break;
        case 'requested':
            statusText = 'Requested';
            canAddMember = true;
            canRemoveMember = true;
            break;
        case 'none':
        default:
            statusText = t(`common:${localNamespace}.not_a_member`);
            canAddMember = true;
            canRemoveMember = false;
            break;
    }

    const [src] = getBackgroundImageSetForUrl(
        member.profile.picture ? member.profile.picture : getRandomDefaultUserImage()
    );

    const openProfileView = () => {
        newModal(<QuickProfileView user={member} />, {
            className: 'no-top-padding white-notch',
        });
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li className="group-members-form-result">
            <div className="profile-thumb-img" onClick={openProfileView}>
                <Picture url={src} disableLink resolutions={[130, 260, 390]} />
            </div>
            <section className="group-members-form-result-adjustment" onClick={openProfileView}>
                {statusText && <span>{statusText}</span>}
                <h2> {member.profile.full_name ? member.profile.full_name : 'Anonymous'}</h2>
                <h3>{member.profile.short_bio}</h3>
            </section>

            {canAddMember && (
                <button
                    className="group-members-form-result-btn-adjustment"
                    type="button"
                    onClick={e => {
                        e.stopPropagation();
                        handleAddUser();
                    }}
                >
                    <Plus />
                </button>
            )}
            {canRemoveMember && (
                <button
                    className="group-members-form-result-btn-adjustment"
                    type="button"
                    onClick={e => {
                        e.stopPropagation();
                        handleRemoveUser();
                    }}
                >
                    <Minus />
                </button>
            )}

            {(statusText === 'Admin' || statusText === 'Owner') && (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <button
                    className="group-members-form-result-btn-adjustment group-members-form-result-adjustment--hidden"
                    type="button"
                />
            )}

            {isUserAdminOfGroup && _quickMenuList && _quickMenuList?.length > 0 && (
                <div className="group-members-action-btn group-members-action-dropdown-btn">
                    <DropdownController
                        menuList={_quickMenuList}
                        buttonClass="button-reset content-button-icon dropdown-dots"
                    />
                </div>
            )}
        </li>
    );
};
