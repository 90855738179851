import React from 'react';
import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import useMd from '@components/generic/hooks/useMd';

import {
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_LIBRARY,
    CARD_SHELF,
} from '@core/utilities/constants/card_types';

import CommentSearchCard from '@components/search/searchcard/CommentSearchCard';
import MessageSearchCard from '@components/search/searchcard/MessageSearchCard';
import ContentSearchCardContainer from '@components/search/searchcard/ContentSearchCardContainer';
import AlternateSearchCardContainer from '@components/search/searchcard/AlternateSearchCardContainer';

import { FILTER_TYPES, getFilterType } from '@components/search/constants';
import { isKey } from '@helpers/types/type.helpers';

import { Maybify } from '@core/types/Utilities';
import { SearchResult } from '@shared/welibrary-graphql/types';

const collectionTypes = {
    [CARD_LIBRARY]: true,
    [CARD_SHELF]: true,
    [CARD_BOOK]: true,
    [CARD_CHAPTER]: true,
};

type SearchCardContainerProps = {
    currentTab: string[];
    handleCloseSearch: () => void;
    source?: Maybify<SearchResult> | null;
};

/* Main component for displaying search card types */
const SearchCardContainer: React.FC<SearchCardContainerProps> = ({
    currentTab,
    handleCloseSearch,
    source,
}) => {
    const md = useMd();
    const cardType = getFilterType(source);
    const content = source?.entity;
    const score = source?.score;
    const isCollectionType =
        isKey(collectionTypes, content?.type) && collectionTypes[content?.type];
    const isUserType = content?.__typename === 'User';
    const isGroupType = content?.__typename === 'Group';
    const isPromptCard = Array.isArray(currentTab) && currentTab.includes(FILTER_TYPES.PROMPT);
    const useAlternateCard = (isCollectionType || isUserType || isGroupType) && !isPromptCard;

    if (cardType === FILTER_TYPES.COMMENT) {
        return <CommentSearchCard handleCloseSearch={handleCloseSearch} source={content} />;
    }

    if (cardType === FILTER_TYPES.MESSAGE) {
        return <MessageSearchCard handleCloseSearch={handleCloseSearch} source={content} />;
    }

    // temp workaround for weird data shape from server when an event group card
    // For now we will not display it
    if (source?.type === 'CONTENTCARD' && isGroupType) {
        return <span />;
    }

    if (useAlternateCard) {
        return (
            <ErrorBoundary>
                <AlternateSearchCardContainer
                    handleCloseSearch={handleCloseSearch}
                    content={content}
                />
            </ErrorBoundary>
        );
    }

    return (
        <ErrorBoundary>
            <ContentSearchCardContainer
                handleCloseSearch={handleCloseSearch}
                isPromptCard={isPromptCard}
                score={score}
                content={content}
                md={md}
            />
        </ErrorBoundary>
    );
};

export default SearchCardContainer;
