import React from 'react';

import {getRandomDefaultCoverImage, getRandomDefaultThumbImage,} from '@core/utilities/constants/defaults';
import ScholarCardBody from '@web/ui/components/card/scholarcard/ScholarCardBody';

//speculative data object containing informatiom
const source = {
    url: '9uwoADi4BweEhMjgx',
    title: 'Article Title',
    type: 'Article',
    category: 'Education',
    tags: ['education'],
    citations: [],
    identifier: null,
    copyright: null,
    language: 'English',
    thumbURL: '../images/DC_SEP_18_Cover-610x842.jpg',
    pubInfo: {
        name: 'Publisher/Journal',
        date: '12 Apr. 1999',
        issue: 'Vol. II, No. 234',
    },
    authors: [
        {
            name: 'First A. Author',
            profileURL: '/u/234234',
            id: 0,
        },
        {
            name: 'Second N. Author',
            profileURL: '/u/234234',
            id: 1,
        },
    ],
    organization: {
        name: 'Vanderbilt University',
        link: 'https://www.vanderbilt.edu/',
    },
    fileURL: 'http://wwww.something.edu/file.pdf',
    info:
        'Article information, abstract, summary of research is probably here, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur ',
};

export default class PledgeItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { card } = this.props;

        let mediaThumb = getRandomDefaultCoverImage(card.title);
        if (card.media.thumb) mediaThumb = card.media.thumb || mediaThumb;

        const cardBgStyle = {
            background: `linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url(${mediaThumb})`,
            backgroundSize: 'cover',
        };

        let mediaIcon = getRandomDefaultThumbImage(card.title);
        if (card.media.icon) mediaIcon = card.media.icon || mediaIcon;

        const cardIconStyle = {
            background: `url(${mediaIcon})`,
            backgroundSize: 'cover',
        };

        return (
            <div className="mc-media-item">
                <ScholarCardBody source={card} />
            </div>
        );
    }
}
