import React from 'react';

type MinusCircleProps = {
    className?: string;
    size?: string;
};

const MinusCircle: React.FC<MinusCircleProps> = ({ className = '', size = '14' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.5 12.25C10.3995 12.25 12.75 9.8995 12.75 7C12.75 4.10051 10.3995 1.75 7.5 1.75C4.60051 1.75 2.25 4.10051 2.25 7C2.25 9.8995 4.60051 12.25 7.5 12.25Z"
                stroke="#4E4B66"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.3125 7H9.6875"
                stroke="#4E4B66"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default MinusCircle;
