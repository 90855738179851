import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import useCreation from '@components/lego/creation/creationpage/hooks/useCreation';
import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';
import useModal from '@components/modals/hooks/useModal';

import Picture from '@components/generic/Picture';
import ChooseCreationNav from '@components/lego/creation/creationpage/ChooseCreationNav';
import ChooseCreationHeader from '@components/lego/creation/creationpage/ChooseCreationHeader';
import CreationViewTab from '@components/lego/creation/creationpage/CreationViewTab';
import CourseViewTab from '@components/lego/creation/creationpage/CourseViewTab';
import ChunkyX from '@dsc/svgs/lego/ChunkyX';
import WindowFrame from '@dsc/svgs/lego/WindowFrame';
import Button from '@dsc/forms/buttons/Button';
import ModalLoading from '@components/modals/ModalLoading';

import { getCarouselFunctions } from '@helpers/navigation/horizontalNav.helpers';

import CreationTempBG from '@assets/lego/quest/SS.quest.creations.page.bg3.png';

type CreationContainerProps = {
    cardUrl: string;
    questId: string;
    returnUrl: string;
    goBack: () => void;
};

const CreationContainer: React.FC<CreationContainerProps> = ({
    cardUrl,
    questId,
    returnUrl,
    goBack = () => {},
}) => {
    const history = useHistory();

    const { closeModal } = useModal();

    const { card, creation, loading } = useCreation({
        url: cardUrl,
        questId,
    });

    const creationItems = card?.creationItems;

    const selectedIndex = Math.max((creation?.level ?? 0) - 1, 0);

    const refs = {
        creationSlide: useRef<HTMLDivElement>(null),
        courseSlide: useRef<HTMLDivElement>(null),
    };
    const container = useRef<HTMLDivElement>(null);
    const { on, scrollTo } = useHorizontalPages({ refs, options: { root: container.current } });
    const { current } = getCarouselFunctions({ on, scrollTo });

    const handleMainClick = () => {
        if (current === 'creationSlide') {
            scrollTo?.courseSlide();
        } else {
            const page = selectedIndex < 1 ? 0 : selectedIndex + 1;
            // go to course page
            history?.push(
                `/course/${creationItems?.course?.url}?page=${page}&returnTo=${
                    returnUrl || '/home'
                }&creation=${card?._id ?? ''}&quest=${questId ?? ''}`
            );
            closeModal();
        }
    };

    if (creation?.isComplete) {
        return (
            <div className="choose-creation-container">
                <div className="choose-creation bg-layer">
                    <Picture
                        url={card?.media?.thumb ?? CreationTempBG}
                        disableLink
                        className="creation-bg-full"
                    />
                </div>

                <div className="lego-frame-overlay-layer">
                    <WindowFrame />
                </div>

                <header>
                    <h1>{card?.title} Complete!</h1>
                    <div className="header-text">
                        <p>You built this creation and collected these</p>
                        <p>Super Skills Resources!</p>
                    </div>
                </header>

                <section className="creation-select-body" ref={container}>
                    <CreationViewTab
                        activeScreen="creationSlide"
                        state={creation}
                        card={card}
                        ref={refs.creationSlide}
                    />
                </section>

                <div className="footer">
                    <nav className="level-select">
                        <Button
                            className="go-button"
                            type="medium"
                            onClick={() => {
                                history?.push(
                                    `/course/${creationItems?.course?.url}?returnTo=${
                                        returnUrl || '/home'
                                    }&creation=${card?._id ?? ''}&quest=${questId ?? ''}`
                                );
                                closeModal();
                            }}
                        >
                            Review
                        </Button>

                        <Button type="icon" className="cancel-btn" onClick={goBack}>
                            <ChunkyX />
                        </Button>
                    </nav>
                </div>
            </div>
        );
    }

    return (
        <>
            {loading ||
                (!card && (
                    <div className="choose-creation-container">
                        <ModalLoading />
                    </div>
                ))}

            {(!loading || card) && (
                <div className="choose-creation-container">
                    <div className="choose-creation bg-layer">
                        <Picture
                            url={card?.media?.thumb ?? CreationTempBG}
                            disableLink
                            className="creation-bg-full"
                        />
                    </div>

                    <div className="lego-frame-overlay-layer">
                        <WindowFrame />
                    </div>

                    <ChooseCreationHeader
                        card={card}
                        selectedIndex={selectedIndex}
                        activeScreen={current}
                    />

                    <section className="creation-select-body" ref={container}>
                        <CreationViewTab
                            activeScreen={current}
                            state={creation}
                            card={card}
                            ref={refs.creationSlide}
                        />
                        <CourseViewTab
                            selectedIndex={selectedIndex}
                            card={card}
                            activeScreen={current}
                            ref={refs.courseSlide}
                        />
                    </section>

                    <ChooseCreationNav
                        card={card}
                        selectedIndex={selectedIndex}
                        activeScreen={current}
                        handleMainClick={handleMainClick}
                        handleGoBack={() => scrollTo?.creationSlide()}
                        handleCancel={goBack}
                    />
                </div>
            )}
        </>
    );
};

export default CreationContainer;
