import React, { useEffect, useRef, useState } from 'react';
import {
    CARD_MOBILIZE,
    CARD_EVENT,
    CARD_TEXT,
    CARD_VOLUNTEER_MOBILIZATION,
} from '@core/utilities/constants/card_types.js';
import NewPostIcons from './NewPostIcons';

const iconWidth = 40;
const containerPadding = 10;
const hiddenPostTypes = [CARD_TEXT];

const NewPostIconsContainer = ({
    allAllowedTypes,
    expandAttachment,
    clearAttachment,
    handleSquarePhotoSelect,
    handleFileSelect,
    openModalHandler,
    shouldShowAttachment,
    handleShowAllTypes,
}) => {
    // Mobilization types are shown on the front end as two differnt icons, job and volunteer icons
    if (allAllowedTypes.includes(CARD_MOBILIZE)) {
        allAllowedTypes = [...allAllowedTypes, CARD_VOLUNTEER_MOBILIZATION];
    }

    // filter out text post type & old event card type
    const postTypes = allAllowedTypes?.filter(type => {
        return !hiddenPostTypes?.includes(type);
    });

    const iconsContainerRef = useRef();
    const [shownIcons, setShownIcons] = useState();
    const [showMore, setShowMore] = useState(false);

    // Get icon container element width,
    // so we can calculate how many icons are shown and whether to show the showmore button
    useEffect(() => {
        if (!iconsContainerRef?.current) return null;
        const iconsWidth = iconsContainerRef?.current?.clientWidth - containerPadding;
        // Calculate the number of icons that can fit without wrapping
        const numIcons = Math.floor(iconsWidth / iconWidth);
        let visibleIcons = postTypes?.slice(0, numIcons);
        if (postTypes?.length > numIcons) {
            setShowMore(true);
            // We have to to account for the added show more button
            visibleIcons = postTypes?.slice(0, numIcons - 1);
        }
        setShownIcons(visibleIcons);
    }, [iconsContainerRef?.current]);

    return (
        <>
            <div className="new-post-icons" ref={iconsContainerRef}>
                {shownIcons && (
                    <NewPostIcons
                        shouldShowAttachment={shouldShowAttachment}
                        handleSquarePhotoSelect={handleSquarePhotoSelect}
                        openModalHandler={openModalHandler}
                        iconTypes={shownIcons}
                        handleExpand={expandAttachment}
                        handleClear={clearAttachment}
                        handleFileSelect={handleFileSelect}
                    />
                )}
                {showMore && (
                    <div
                        name="postTypes"
                        key="ATTACH_MORE"
                        className="new-post-media-button post-more w-inline-block"
                        onClick={handleShowAllTypes}
                    />
                )}
            </div>
        </>
    );
};

export default NewPostIconsContainer;
