import React, { useState, useEffect } from 'react';
import { TorusKey } from '@toruslabs/torus-direct-web-sdk';
import { LearnCardFromSeed } from '@learncard/init';

import { useCurrentUser } from '@stores/User';
import { createContext } from '@core/utilities/helpers/context.helpers';

import { SetState } from '@core/types/Utilities';

export type WalletContextValues = {
    subjectDid: string | null;
    setSubjectDid: SetState<string | null>;
    setTorusUser: SetState<TorusKey | null>;
    torusUser: TorusKey | null;
    walletInstance: LearnCardFromSeed['returnValue'] | null;
    clearUniversalWalletContext: () => void;
    setWalletInstance: SetState<LearnCardFromSeed['returnValue'] | null>;
};

export const [useUniversalWalletContext, UniversalWalletContextProvider] =
    createContext<WalletContextValues>();

export const UniversalWalletProvider: React.FC = ({ children }) => {
    const [subjectDid, setSubjectDid] = useState<string | null>(null);
    const [torusUser, setTorusUser] = useState<TorusKey | null>(null);
    const [walletInstance, setWalletInstance] = useState<LearnCardFromSeed['returnValue'] | null>(
        null
    );
    const { currentUser } = useCurrentUser();

    const clearUniversalWalletContext = () => {
        setSubjectDid(null);
        setTorusUser(null);
        setWalletInstance(null);
    };

    useEffect(() => {
        if (!currentUser) {
            clearUniversalWalletContext();
        }
    }, [currentUser]);

    return (
        <UniversalWalletContextProvider
            value={{
                subjectDid,
                setSubjectDid,
                setTorusUser,
                torusUser,
                walletInstance,
                setWalletInstance,
                clearUniversalWalletContext,
            }}
        >
            {children}
        </UniversalWalletContextProvider>
    );
};
