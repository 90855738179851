import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { z } from 'zod';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import LocationVenueSettings from '@components/content/newpost/forms/EventGroup/LocationVenueSettings';
import SpeakersSettings from '@components/content/newpost/forms/EventGroup/SpeakersSettings';
import AddSpeakerForm from '@components/content/newpost/forms/EventGroup/AddSpeakerForm';
import DatePickerPopup from '@components/content/newpost/forms/EventGroup/DatePickerPopup';
import Toggle from '@dsc/forms/customInputs/Toggle';
import TextArea from '@components/generic/forms/TextArea';

import { v1 as uuidv1 } from 'uuid';
import { curriedStateSlice } from '@helpers/state/state.helpers';
import { isoStringToDateNullifyTimezone, toIsoStringIgnoreTimezone } from '@helpers/date.helpers';

import {
    ItineraryState,
    DateAndTimeState,
    LocationTypeEnum,
    OverviewFormState,
    SpeakerStateType,
} from '@core/types/EventGroup';

import 'react-datepicker/dist/react-datepicker.css';

const localNamespace = 'imports.wlWeb.ui.components.group.updateEventForm.eventForm';
const eventlocalNamespace = 'imports.wlWeb.ui.components.content.event';

type DateRangeSelection = {
    selection: {
        endDate: Date;
        startDate: Date;
    };
};

const blankEventLocationSettings = {
    link: '',
    venue: {
        name: '',
        link: '',
        location: {
            formatted_address: '',
            location: { latitude: 0, longitude: 0 },
            city: '',
            state: '',
            country: '',
            postal: '',
        },
    },
    type: LocationTypeEnum.inPerson,
};

const generateNewTP = (overviewFormState?: OverviewFormState) => {
    return {
        id: uuidv1(),
        title: '',
        headline: '',
        description: '',
        date: '',
        dates: [{ startDate: '', endDate: '' }],
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString(),
        rsvpLink: '',
        timeZone: { name: '' },
        speakers: [],
        eventLocationSettings:
            overviewFormState?.eventLocationSettings || blankEventLocationSettings,
        displayStartTime: true,
        displayEndTime: true,
    };
};

type TimeAndPlaceFormProps = {
    handleSave: (session: ItineraryState, id?: number) => void;
    handleCancel: () => void;
    itemId?: string;
    initialState?: ItineraryState;
    eventState?: DateAndTimeState;
    overviewFormState?: OverviewFormState;
    locationTitle?: string;
    handleUpdateSpeakerInAllSessions?: (speaker: SpeakerStateType) => void;
    allSpeakers?: SpeakerStateType[] | undefined | null;
};

const StateValidator = z.object({
    title: z.string().nonempty('Please enter a title').max(50),
    location: z.string().nonempty('Please pick a location').max(200),
    startDate: z.string().nonempty('Please select a start date').max(50),
    endDate: z.string().nonempty('Please select an end date').max(50),
    startTime: z.string().nonempty('Please select a start time').max(50),
    endTime: z.string().nonempty('Please select an end time').max(50),
});

const TimeAndPlaceForm: React.FC<TimeAndPlaceFormProps> = ({
    handleSave,
    handleCancel,
    itemId,
    initialState,
    eventState,
    overviewFormState,
    locationTitle,
    handleUpdateSpeakerInAllSessions,
    allSpeakers,
}) => {
    const { t } = useTranslation();
    const [state, setState] = useImmer(initialState || generateNewTP(overviewFormState));
    const [formValid, setFormValid] = useImmer(false);
    const [panel, setPanel] = useImmer('panel-1');

    const updateSlice = curriedStateSlice(setState);

    const handleDates = (ranges: DateRangeSelection) => {
        const startDate = ranges?.selection?.startDate;
        const endDate = ranges?.selection?.endDate;

        updateSlice('dates', [
            {
                startDate: toIsoStringIgnoreTimezone(startDate),
                endDate: toIsoStringIgnoreTimezone(endDate),
            },
        ]);
    };

    // Generate min max date range for calendar selection
    let minDate, maxDate, shownDate;
    if (eventState) {
        maxDate = isoStringToDateNullifyTimezone(eventState?.dates?.[0].endDate);
        minDate = isoStringToDateNullifyTimezone(eventState?.dates?.[0]?.startDate);
        shownDate = minDate;
    }

    const calendarOptions = {
        editableDateInputs: true,
        maxDate,
        minDate,
        shownDate,
        moveRangeOnFirstSelection: false,
    };

    const handleShowSpeakersForm = () => setPanel('panel-2');

    const handleDeleteSpeaker = (userId: string) => {
        const _state = state?.speakers.filter(user => user._id !== userId);
        updateSlice('speakers', _state);
    };

    const validate = () => {
        const {
            title,
            eventLocationSettings: { type, venue, link },
            dates,
            startTime,
            endTime,
        } = state;

        let parsedData = StateValidator.safeParse({
            title,
            location: 'default',
            startDate: dates?.[0]?.startDate,
            endDate: dates?.[0]?.endDate,
            startTime,
            endTime,
        });

        if (type === LocationTypeEnum.inPerson) {
            parsedData = StateValidator.safeParse({
                title,
                location: venue?.location?.formatted_address,
                startDate: dates?.[0]?.startDate,
                endDate: dates?.[0]?.endDate,
                startTime,
                endTime,
            });
        }

        if (type === LocationTypeEnum.virtual) {
            parsedData = StateValidator.safeParse({
                title,
                location: link,
                startDate: dates?.[0]?.startDate,
                endDate: dates?.[0]?.endDate,
                startTime,
                endTime,
            });
        }

        if (parsedData.success) {
            setFormValid(true);
            return true;
        }

        if (parsedData.error) {
            setFormValid(false);
        }

        return false;
    };

    // Update main form state
    const handleSaveSession = () => {
        if (validate()) {
            handleSave(state, itemId);
            handleCancel();
        }
    };

    useEffect(() => {
        validate();
    }, [state]);

    const startDateIndicatorTxt = state?.dates?.[0].startDate
        ? moment.utc(state?.dates?.[0]?.startDate).format('LL')
        : '';
    const endDateIndicatorTxt = state?.dates?.[0]?.endDate
        ? moment.utc(state?.dates?.[0]?.endDate).format('LL')
        : '';

    const saveBtnClass = formValid ? 'active' : 'inactive';

    const startTimeValue = new Date(state?.startTime);
    const endTimeValue = new Date(state?.endTime);

    return (
        <div className="scroll-middle-fixed-header-footer">
            {panel === 'panel-1' && (
                <>
                    <header className="modal-form-btn-header event-form">
                        <h1>{t('common:new_session', 'New Session')}</h1>
                        <p>
                            {t(
                                'common:fields_marked_*_are_required',
                                'Fields marked * are required'
                            )}
                        </p>

                        <p className="small-600 event-form header-info">
                            {t(
                                'common:event-group-forms-skip-msg',
                                `Note: This can be skipped. After your event is published, choose the 'Edit Event' option to add or update information.`
                            )}
                        </p>

                        <div className="header-btns">
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="rounded-pill-btn bordered animate-grow"
                            >
                                {t('common:cancel', 'Cancel')}
                            </button>

                            <button
                                type="button"
                                onClick={handleSaveSession}
                                className={`rounded-pill-btn ${saveBtnClass} animate-grow`}
                            >
                                {t('common:save', 'Save')}
                            </button>
                        </div>
                    </header>
                    <article className="tp-body">
                        <TextArea
                            className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px]"
                            countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[23px]"
                            wrapperClassName="relative w-full"
                            defaultClasses=""
                            defaultAutoResizeClasses=""
                            autoResize
                            helperText=""
                            onInput={updateSlice('title')}
                            placeholder="Session Name *"
                            name="timeAndPlaceSettings.title"
                            id="timeAndPlaceSettings.title"
                            aria-labelledby="title-label"
                            required
                            value={state.title ?? ''}
                            maxCharacter={50}
                        />

                        <TextArea
                            className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px] !h-[130px] !overflow-auto"
                            countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[23px]"
                            wrapperClassName="relative w-full"
                            defaultClasses=""
                            defaultAutoResizeClasses=""
                            onInput={updateSlice('description')}
                            name="description"
                            aria-labelledby="desc-label"
                            helperText=""
                            placeholder="Description"
                            value={state?.description}
                            maxCharacter={255}
                            autoResize
                        />

                        <section className="form-group">
                            <DatePickerPopup
                                calendarOptions={calendarOptions}
                                dates={state.dates}
                                handleSelect={handleDates}
                            />

                            <section className="event-times-container">
                                <div className="timepicker-container">
                                    <MuiPickersUtilsProvider
                                        libInstance={moment}
                                        utils={MomentUtils}
                                    >
                                        <TimePicker
                                            variant="inline"
                                            label={t(`common:${eventlocalNamespace}.start_time`)}
                                            value={startTimeValue}
                                            onChange={value => {
                                                if (value) {
                                                    updateSlice('startTime', value.toISOString());
                                                }
                                            }}
                                            name="timeAndPlaceSettings.startTime"
                                            id="timeAndPlaceSettings.startTime"
                                            className="event-time-input"
                                            autoOk
                                        />
                                    </MuiPickersUtilsProvider>
                                    <p className="timepicker-date-indicator">
                                        {startDateIndicatorTxt}
                                    </p>
                                </div>
                                <div className="timepicker-container">
                                    <MuiPickersUtilsProvider
                                        libInstance={moment}
                                        utils={MomentUtils}
                                    >
                                        <TimePicker
                                            variant="inline"
                                            label={t(`common:${eventlocalNamespace}.end_time`)}
                                            value={endTimeValue}
                                            onChange={value => {
                                                if (value) {
                                                    updateSlice('endTime', value.toISOString());
                                                }
                                            }}
                                            name="timeAndPlaceSettings.endTime"
                                            id="timeAndPlaceSettings.endTime"
                                            className="event-time-input"
                                            autoOk
                                        />
                                    </MuiPickersUtilsProvider>
                                    <p className="timepicker-date-indicator">
                                        {endDateIndicatorTxt}
                                    </p>
                                </div>
                            </section>

                            <label className="all-day-toggle" htmlFor="all-day-toggle">
                                <p>{t('common:display_start_time', 'Display Start Time')}</p>
                                <Toggle
                                    id="disp-start-time-toggle"
                                    value={state?.displayStartTime}
                                    onChange={updateSlice('displayStartTime')}
                                />
                            </label>

                            <label className="all-day-toggle" htmlFor="all-day-toggle">
                                <p>{t('common:display_end_time', 'Display End Time')}</p>
                                <Toggle
                                    id="disp-end-time-toggle"
                                    value={state?.displayEndTime}
                                    onChange={updateSlice('displayEndTime')}
                                />
                            </label>
                        </section>

                        <LocationVenueSettings
                            state={state?.eventLocationSettings}
                            setState={updateSlice('eventLocationSettings')}
                            customTitle={locationTitle}
                        />

                        <SpeakersSettings
                            onClick={handleShowSpeakersForm}
                            onUserClick={handleDeleteSpeaker}
                            state={state.speakers}
                        />
                    </article>
                </>
            )}

            {panel === 'panel-2' && (
                <AddSpeakerForm
                    handleCancel={() => setPanel('panel-1')}
                    handleSaveSpeakers={updateSlice('speakers')}
                    initialState={state.speakers}
                    allSpeakers={allSpeakers}
                    handleUpdateSpeakerInAllSessions={handleUpdateSpeakerInAllSessions}
                />
            )}
        </div>
    );
};

export default TimeAndPlaceForm;
