import React, { useState } from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';

import usePeopleSearchQuery from '@components/generic/hooks/usePeopleSearchQuery';
import useDebouncedState from '@components/generic/hooks/useDebouncedState';

import Fade from '@components/generic/animation/Fade';
import Picture from '@components/generic/Picture';
import CreateUserForm from '@components/content/newpost/forms/EventGroup/CreateUserForm';
import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/circle-spinner.json';

import { userToSpeakerState } from '@helpers/events/event.helpers';
import { getRandomDefaultUserImage } from '@core/utilities/constants/defaults';

import { SpeakerState, SpeakerStateType } from '@core/types/EventGroup';

import getLogger from '@core/logger';

const logger = getLogger(module);

type AddSpeakerWidgetProps = {
    handleAddSpeaker: (user: SpeakerStateType) => void;
    handleUpdateSpeaker: (userId: string, user: SpeakerStateType) => void;
    handleDeleteSpeaker: (userId: string) => void;
    initialUserList: SpeakerStateType[];
    allSpeakers?: SpeakerStateType[] | undefined | null;
};

export const EDIT_USER_VIEW = 'edit';
export const CREATE_USER_VIEW = 'create';
const SEARCH_USER_VIEW = 'search_user_view';

const AddSpeakerWidget: React.FC<AddSpeakerWidgetProps> = ({
    handleAddSpeaker,
    handleUpdateSpeaker,
    handleDeleteSpeaker,
    initialUserList,
    allSpeakers,
}) => {
    const [searchQuery, actualSearchQuery, setSearchQuery] = useDebouncedState('');
    const { foundUsers, searchNetworkLoading, searchNetworkError } = usePeopleSearchQuery(
        actualSearchQuery,
        true,
        true
    );

    const [view, setView] = useState(SEARCH_USER_VIEW);
    const [editUser, setEditUser] = useState<SpeakerStateType | null>(null);

    const [selectedUsers, setSelectedUsers] = useImmer(initialUserList || []);

    const handleAddUserLocalState = (user: SpeakerStateType) => {
        setSelectedUsers(draft => {
            draft.push(user);
        });
    };

    const handleDeleteUserLocalState = (userId: string) => {
        const _selectedUsers = selectedUsers.filter(user => user._id !== userId);
        setSelectedUsers(_selectedUsers);
    };

    const handleUpdateUserLocalState = (user: SpeakerStateType) => {
        // update parent form state
        handleUpdateSpeaker(user?._id, user);
        const userIndex = selectedUsers.findIndex(speaker => speaker._id === user?._id);

        // update local speaker form state
        setSelectedUsers(draft => {
            draft[userIndex] = user;
        });
    };

    const handleSave = (user: SpeakerStateType, type: string) => {
        if (view === CREATE_USER_VIEW) {
            handleAddUserLocalState(user);
            handleAddSpeaker(user);
        }

        if (view === EDIT_USER_VIEW) {
            handleUpdateUserLocalState(user);
        }
    };

    const emptyResults = !searchNetworkLoading && _.isEmpty(foundUsers) && actualSearchQuery !== '';
    const emptyState = actualSearchQuery === '' && searchQuery === '';
    const areResults = foundUsers && foundUsers.length > 0;

    const renderUserList = foundUsers
        ?.filter(user => !selectedUsers?.find(selectedUser => user?._id === selectedUser._id))
        .map(user => {
            let speaker = userToSpeakerState(user);
            // Lookup to see if user exists in event group aliases and if it does and has a value use that instead
            if (allSpeakers && allSpeakers?.length > 0) {
                const existingAliasUser = allSpeakers?.find(
                    _speaker => _speaker._id === speaker?._id
                );
                if (existingAliasUser) speaker = existingAliasUser;
            }

            return (
                <SpeakerSlimInfoCard
                    className="no-border"
                    key={speaker._id}
                    user={speaker}
                    handleClick={() => {
                        handleAddUserLocalState(speaker);
                        handleAddSpeaker(speaker);
                    }}
                    iconClass="border-grey-white-fill plus2"
                />
            );
        });

    const renderSelectedUserList = selectedUsers?.map((user: SpeakerStateType) => {
        const _handleClick = () => {
            handleDeleteUserLocalState(user._id);
            handleDeleteSpeaker(user._id);
        };

        const handleEdit = () => {
            setView(EDIT_USER_VIEW);
            setEditUser(user);
        };

        return (
            <SpeakerSlimInfoCard
                key={user._id}
                user={user}
                handleClick={_handleClick}
                handleEdit={handleEdit}
                iconClass="trash2"
            />
        );
    });

    const loaderStyle = {
        position: 'absolute',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        opacity: '0.5',
        right: '0px',
        top: '0px',
    };

    let userFormTitle = 'Create Speaker';
    if (view === EDIT_USER_VIEW) userFormTitle = 'Edit Speaker';

    // user in edit user state
    const initialUserState = view === EDIT_USER_VIEW ? editUser : null;

    return (
        <>
            <section className={`search-members-widget ${view}`}>
                {view === SEARCH_USER_VIEW && (
                    <nav className="search-members-nav" style={{ position: 'relative' }}>
                        <input
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.currentTarget.value)}
                            className="text-field searchfield w-input"
                            type="text"
                            maxLength={256}
                            placeholder="Search user by name..."
                            id="search-people"
                        />
                        <Fade show={searchNetworkLoading}>
                            <LottieLoading
                                customStyle={loaderStyle}
                                height={40}
                                width={40}
                                lottieData={LottieAnimation}
                                isPaused={false}
                            />
                        </Fade>
                    </nav>
                )}

                {view === SEARCH_USER_VIEW && (
                    <>
                        <div className="new-speaker-btn-container">
                            Create New Speaker
                            <button
                                type="button"
                                aria-label="Create Speaker"
                                className="circle-icon-button light-grey border-grey-white-fill plus2"
                                onClick={() => setView(CREATE_USER_VIEW)}
                            />
                        </div>
                        <section className="user-search-list">
                            <Fade show={emptyResults}>
                                {' '}
                                <strong>No users found!</strong>{' '}
                            </Fade>

                            {renderUserList}
                        </section>
                    </>
                )}

                {(view === CREATE_USER_VIEW || view === EDIT_USER_VIEW) && (
                    <CreateUserForm
                        initialState={initialUserState}
                        handleSave={handleSave}
                        title={userFormTitle}
                        type={view}
                        handleCancel={() => setView(SEARCH_USER_VIEW)}
                    />
                )}
            </section>
            <section className="selected-users-list">
                <section className="user-selected-list">{renderSelectedUserList}</section>
            </section>
        </>
    );
};

export default AddSpeakerWidget;

type SpeakerSlimInfoCardProps = {
    user: SpeakerStateType;
    className?: String;
    iconClass?: String;
    handleClick: () => void;
    handleEdit: () => void;
};

const SpeakerSlimInfoCard: React.FC<SpeakerSlimInfoCardProps> = ({
    handleClick,
    handleEdit,
    className,
    iconClass,
    user,
}) => {
    const speakerThumbUrl = user?.profileThumb?.image || getRandomDefaultUserImage();

    const sectionClassName = className ? `slim-speaker-info ${className}` : 'slim-speaker-info';

    const baseButtonClass = 'circle-icon-button light-grey';

    return (
        <section className={sectionClassName}>
            <div className="left-side">
                <Picture className="speaker-circle-thumb" disableLink url={speakerThumbUrl} />

                <div className="speaker-info">
                    <p>{user.name}</p>
                    {user.bio && (
                        <p className="user-bio">{user.useUserBio ? user.userBio : user.bio}</p>
                    )}
                </div>
            </div>

            <div className="right-side">
                <button
                    aria-label="Edit"
                    type="button"
                    onClick={() => handleEdit?.()}
                    className={`${baseButtonClass} content-button-icon edit`}
                />
                <button
                    aria-label="Delete"
                    type="button"
                    onClick={() => handleClick?.()}
                    className={`${baseButtonClass} ${iconClass}`}
                />
            </div>
        </section>
    );
};
