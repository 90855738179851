import React from 'react';
import { useLocation } from 'react-router-dom';

import useCreation from '@components/lego/creation/creationpage/hooks/useCreation';
import useModal from '@components/modals/hooks/useModal';

import CreationContainer from '@components/lego/creation/creationpage/CreationContainer';
import CreationThumbDisplay from '@components/lego/creation/CreationThumbDisplay';

import { getCreationImage } from '@helpers/creations/creation.helpers';

import { Maybify } from '@core/types/Utilities';
import { QuestCreationType } from '@core/types/Quests';
import { ModalTypes } from '@core/types/Modals';
import { CreationState } from '@shared/welibrary-graphql/types';

type AdventureSelectorCreationProps = {
    questId: string;
    creation: QuestCreationType;
    creationsState?: Array<Maybify<CreationState> | null> | null;
};

const AdventureSelectorCreation: React.FC<AdventureSelectorCreationProps> = ({
    questId,
    creation,
    creationsState,
}) => {
    const location = useLocation();

    const { newModal, closeModal } = useModal();

    const state = creationsState?.find(
        creationState => creationState?.creation?._id === creation._id
    );

    // This query is run when the creation is loaded to prefetch data...
    useCreation({ url: creation?.url, questId });

    const foreground = state
        ? getCreationImage(creation, state)
        : creation.creationItems.silhouetteImage ?? '';

    return (
        <button
            type="button"
            className="adv-selector-item"
            onClick={() => {
                closeModal();
                setTimeout(() => {
                    newModal(
                        <CreationContainer
                            cardUrl={creation?.url ?? ''}
                            questId={questId}
                            returnUrl={location.pathname}
                            goBack={closeModal}
                        />,
                        { className: 'full-height' },
                        { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
                    );
                }, 100);
            }}
            key={creation._id}
        >
            <CreationThumbDisplay
                foreground={foreground}
                background={creation.media.thumb}
                title={creation.title}
            />
        </button>
    );
};

export default AdventureSelectorCreation;
