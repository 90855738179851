import {
    CARD_AUDIO,
    CARD_BLOG,
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_COURSE,
    CARD_DOCUMENT,
    CARD_EMBED,
    CARD_EVENT,
    CARD_FORMSPEC,
    CARD_GROUP,
    CARD_HEADER,
    CARD_IMAGE,
    CARD_LIBRARY,
    CARD_LINK,
    CARD_LINK_TILE,
    CARD_MOBILIZATION,
    CARD_MOBILIZE,
    CARD_PLEDGE,
    CARD_POSITION,
    CARD_POSTBOX,
    CARD_POSTING,
    CARD_QUOTE,
    CARD_RAINBOW_ROAD,
    CARD_RESEARCH,
    CARD_SHELF,
    CARD_TASK,
    CARD_TEXT,
    CARD_VIDEO,
    CARD_VOTE_GALLERY,
} from '@core/utilities/constants/card_types.js';

import { ENGAGE_COMMENT, ENGAGE_SHARE } from '@core/utilities/constants/engagement_types';

import _ from 'lodash';

const ALL_TYPES = '*';

const BASIC_LIBRARY_CARDS = [CARD_LIBRARY, CARD_SHELF, CARD_BOOK, CARD_CHAPTER];

const BASIC_MEDIA_CARDS = [
    CARD_BLOG,
    CARD_IMAGE,
    CARD_LINK,
    CARD_VIDEO,
    CARD_DOCUMENT,
    CARD_TEXT,
    CARD_RAINBOW_ROAD,
    CARD_GROUP,
    CARD_EVENT,
];
const ADVANCED_MEDIA_CARDS = [
    CARD_POSITION,
    CARD_COURSE,
    CARD_PLEDGE,
    CARD_MOBILIZE,
    CARD_AUDIO,
    CARD_QUOTE,
    CARD_EMBED,
    CARD_RESEARCH,
    CARD_MOBILIZATION,
    CARD_POSTING,
    CARD_FORMSPEC,
    CARD_TASK,
];
const ADVANCED_ADMIN_CARDS = [CARD_POSTBOX, CARD_VOTE_GALLERY, CARD_LINK_TILE, CARD_HEADER];

const ALL_CONTENT_CARDS = [...BASIC_MEDIA_CARDS, ...ADVANCED_MEDIA_CARDS, ...ADVANCED_ADMIN_CARDS];

const GROUP_TYPES = ['group'];

const LIBRARY_FEATURES_INFO_FRAGMENT = {
    title: true,
    body: true,
    mediaThumb: true,
    mediaIcon: true,
    description: true,
    displayReference: true,
    mediaLogoLight: true,
    mediaLogoDark: true,
    mediaFavicon: false,
    mediaWebclip: false,
};

const LIBRARY_FEATURES_SETTINGS_FRAGMENT = {
    protectionLevel: true,
    memberStyle: true,
    customRoles: true,
    sidebarMenu: true,
    initialSubscriptions: true,
    customSignupFields: true,
    dashboardMenu: true,
    dashboardContentItems: true,
    recommendations: true,
};

const SHELF_FEATURES_INFO_FRAGMENT = {
    title: true,
    body: true,
    mediaThumb: true,
    mediaIcon: true,
    description: true,
    displayReference: true,
    shortCode: true,
    dashboardContentItems: true,
    // TODO: Test shortcode feature constraints..
};

const SHELF_FEATURES_SETTINGS_FRAGMENT = {
    accessRoleWhitelist: true,
    protectionLevel: true,
};

const BOOK_FEATURES_INFO_FRAGMENT = {
    title: true,
    body: true,
    mediaThumb: true,
    mediaIcon: true,
    description: true,
    displayReference: true,
    verticalScrolling: true,
};

const BOOK_FEATURES_SETTINGS_FRAGMENT = {
    accessRoleWhitelist: true,
    protectionLevel: true,
};

const CHAPTER_FEATURES_INFO_FRAGMENT = {
    title: true,
    prompt: true,
    mediaThumb: true,
    mediaIcon: true,
    description: true,
};

const CHAPTER_FEATURES_SETTINGS_FRAGMENT = {
    sendNotifications: true,
    showPostTypes: false,
    voteTypes: true,
    engagementTypes: [ENGAGE_COMMENT, ENGAGE_SHARE],
    filterTypes: true,
    defaultFilter: true,
    viewTypes: true,
    defaultViewType: true,
    displayReference: true,
    postTypes: [...BASIC_MEDIA_CARDS, CARD_BLOG, CARD_EVENT],
    allowPostbox: true,
    postboxRoleWhiteList: true,
    displaySocialHeaders: true,
    cardAggregation: true,
    tagAggregation: true,
    isTemplate: true,
};

const CONTENT_CARD_FEATURES_FRAGMENT = {
    type: true,
    title: true,
    body: true,
    description: true,
    link: true,
    aliases: true,
    nodes: true,
    mediaThumb: true,
    mediaIcon: true,
    authorID: true,
    pinned: true,
    hideDate: true,
    deleteCard: true,
    labelText: true,
    removeLabel: true,
    allowLabels: true,
    prompt: true,
    engagementTypes: [ENGAGE_COMMENT, ENGAGE_SHARE],
    displaySocialHeaders: true,
    showResourceHeader: true,
    // showCreateChildren: true,
    // displayInNewsfeed: true,
    // protectionLevel: true,
    displayReference: true,
    meta: true,
    rankOrder: true,
    customFields: true,
    mobilizationSettings: true,
    postingSettings: true,
    formSpecificationSettings: true,
    pledgeItems: true,
    inheritSurveyAnswers: true,
    eventSettings: true,
};

const CONTENT_CARD_ACTION_FEATURES_FRAGMENT = {
    action_move: true,
    action_tag: true,
    action_archive: true,
    create_resource_card: true,
};

const GROUP_BASIC_FEATURES_FRAGMENT = {
    name: true,
    summary: true,
    picture: true,
    coverPhoto: true,
    country: true,
};

const GROUP_ADVANCED_FEATURES_FRAGMENT = {
    parentId: true,
    settings: true,
    subtype: true,
    isTemplate: false,
    whitelabelVerbage: false,
};

const GROUP_FEATURES_SETTINGS_FRAGMENT = {
    subscriptions: true,
    protectionLevel: true,
    memberStyle: true,
    sidebarMenu: true,
    dashboards: true,
    customRoles: true,
    customSignupFields: true,
    recommendations: true,
    shortCode: true,
    groupChatroom: true,
    adminChatroom: true,
};

// const ADMIN_BASIC_UPDATE_SET = [{
//  types: [CARD_SHELF, CARD_BOOK, CARD_CHAPTER],
//  features: {
//      shortCode: false,
//      title: true,
//      body: true,
//      description: true,
//      link: true,
//      aliases: true,
//      mediaThumb: true,
//      mediaFavicon: true,
//      mediaIcon: true,
//      mediaLogoLight: true,
//      mediaLogoDark: true,
//      mediaWebclip: true,
//      authorID: false,
//      pinned: true,
//      hideDate: true,
//      deleteCard: true,
//      labelText: true,
//      removeLabel: true,
//      allowLabels: true,
//      prompt: true,
//      voteTypes: [VOTING_NONE, VOTING_SENTIMENT, VOTING_RELEVANCE],
//      engagementTypes: [ENGAGE_COMMENT, ENGAGE_SHARE],
//      postTypes: BASIC_MEDIA_CARDS,
//      filterTypes: false,
//      defaultFilter: true,
//      allowPostbox: true,
//      postboxRoleWhiteList: true,
//      displaySocialHeaders: true,
//      autoFollowCards: true,
//          autoFollowUsers: true,
//          initialSubscriptions: true,
//          protectionLevel: true,
//          accessRoleWhitelist: true,
//          memberStyle: true,
//          sidebarMenu: false,
//          dashboardMenu: true,
//          customRoles: true,
//          customSignupFields: false,
//          dashboardContentItem: true,
//          displayReference: true,
//          meta: true,
//          rankOrder: true,
//          sendNotifications: true,
//          customFields: true
//  }
// }]

const ADMIN_BASIC = [
    {
        types: [CARD_LIBRARY],
        features: {
            ...LIBRARY_FEATURES_INFO_FRAGMENT,
            ...LIBRARY_FEATURES_SETTINGS_FRAGMENT,
            customSignupFields: false,
            memberStyle: false,
            recommendations: false,
        },
    },
    {
        types: [CARD_SHELF],
        features: {
            ...SHELF_FEATURES_INFO_FRAGMENT,
            displayReference: false,
        },
    },
    {
        types: [CARD_BOOK],
        features: {
            ...BOOK_FEATURES_INFO_FRAGMENT,
            displayReference: false,
            // ...BOOK_FEATURES_SETTINGS_FRAGMENT
        },
    },
    {
        types: [CARD_CHAPTER],
        features: {
            ...CHAPTER_FEATURES_INFO_FRAGMENT,
            // ...CHAPTER_FEATURES_SETTINGS_FRAGMENT
        },
    },
    {
        types: ALL_CONTENT_CARDS,
        features: {
            ...CONTENT_CARD_FEATURES_FRAGMENT,
        },
    },
];

const ADMIN_ADVANCED = [
    {
        types: [CARD_LIBRARY],
        features: {
            ...LIBRARY_FEATURES_INFO_FRAGMENT,
            ...LIBRARY_FEATURES_SETTINGS_FRAGMENT,
        },
    },
    {
        types: [CARD_SHELF],
        features: {
            ...SHELF_FEATURES_INFO_FRAGMENT,
            ...SHELF_FEATURES_SETTINGS_FRAGMENT,
        },
    },
    {
        types: [CARD_BOOK],
        features: {
            ...BOOK_FEATURES_INFO_FRAGMENT,
            ...BOOK_FEATURES_SETTINGS_FRAGMENT,
        },
    },
    {
        types: [CARD_CHAPTER],
        features: {
            ...CHAPTER_FEATURES_INFO_FRAGMENT,
            ...CHAPTER_FEATURES_SETTINGS_FRAGMENT,
        },
    },
    {
        types: ALL_CONTENT_CARDS,
        features: {
            ...CONTENT_CARD_FEATURES_FRAGMENT,
            ...CONTENT_CARD_ACTION_FEATURES_FRAGMENT,
        },
    },
    {
        types: GROUP_TYPES,
        features: {
            ...GROUP_BASIC_FEATURES_FRAGMENT,
            ...GROUP_ADVANCED_FEATURES_FRAGMENT,
            ...GROUP_FEATURES_SETTINGS_FRAGMENT,
        },
    },
];

// const ORG_GO2020_FEATURE_SET = {
//  _id: 'feature_set_go2020org',
//  CREATE: [{
//      types: [CARD_BOOK],
//      quantity: {
//          'event-template': 1,
//          'workgroup': 5
//      },
//      whitelistTemplates: ['go2020']
//  },
//  {
//      types: [CARD_CHAPTER]
//  },
//  {
//      types: BASIC_MEDIA_CARDS
//  }],
//  UPDATE: ADMIN_BASIC,
//  DELETE: [],
// }

// All Basic Users Get on Account Creation
const NORMAL_USER = {
    _id: 'feature_set_normal_user',
    CREATE: [
        {
            types: BASIC_MEDIA_CARDS,
        },
        {
            types: [CARD_CHAPTER],
        },
    ],
    UPDATE: [
        {
            types: BASIC_MEDIA_CARDS,
            features: {
                ...CONTENT_CARD_FEATURES_FRAGMENT,
            },
        },
        {
            types: [CARD_SHELF],
            features: {
                ...SHELF_FEATURES_INFO_FRAGMENT,
                displayReference: false,
                shortCode: false,
                dashboardContentItems: false,
            },
        },
        {
            types: [CARD_BOOK],
            features: {
                ...BOOK_FEATURES_INFO_FRAGMENT,
                displayReference: false,
                // ...BOOK_FEATURES_SETTINGS_FRAGMENT
            },
        },
        {
            types: [CARD_CHAPTER],
            features: {
                ...CHAPTER_FEATURES_INFO_FRAGMENT,
                // ...CHAPTER_FEATURES_SETTINGS_FRAGMENT
            },
        },
        {
            types: GROUP_TYPES,
            features: {
                ...GROUP_BASIC_FEATURES_FRAGMENT,
            },
        },
    ],
    DELETE: [
        {
            types: ALL_TYPES,
        },
    ],
};

const FULL_ADMIN_FEATURE_SET = {
    _id: 'feature_set_full_admin',
    CREATE: [
        {
            types: ALL_CONTENT_CARDS,
        },
    ],
    UPDATE: ADMIN_ADVANCED,
    DELETE: [
        {
            types: ALL_TYPES,
        },
    ],
};

// All users get when admin of a group
const GROUP_AND_PROJECT_LEADER = {
    _id: 'feature_set_org_and_project_user',
    CREATE: [
        {
            types: BASIC_MEDIA_CARDS,
        },
    ],
    UPDATE: [
        {
            types: GROUP_TYPES,
            features: {
                ...GROUP_BASIC_FEATURES_FRAGMENT,
            },
        },
    ],
    DELETE: [
        {
            types: ALL_TYPES,
        },
    ],
};

// Advanced and basic group settings
const GROUP_FEATURE_SET = {
    _id: 'feature_set_groups',
    CREATE: [
        {
            types: GROUP_TYPES,
        },
    ],
    UPDATE: [
        {
            types: ['group'],
            features: {
                ...GROUP_BASIC_FEATURES_FRAGMENT,
                ...GROUP_ADVANCED_FEATURES_FRAGMENT,
            },
        },
    ],
    DELETE: [],
};

// Group User Feature Set
const GROUP_USER = {
    _id: 'feature_set_group_user',
    CREATE: [
        {
            types: GROUP_TYPES,
        },
    ],
    UPDATE: [
        {
            types: ['group'],
            features: {
                ...GROUP_BASIC_FEATURES_FRAGMENT,
                ...GROUP_ADVANCED_FEATURES_FRAGMENT,
            },
        },
    ],
    DELETE: [],
};

// Every feature set available. No Restrictions.
const SUPER_ADMIN_FEATURE_SET = {
    _id: 'feature_set_super_admin',
    CREATE: [
        {
            types: ALL_TYPES,
        },
    ],
    UPDATE: [
        {
            types: ALL_TYPES,
        },
    ],
    DELETE: [
        {
            types: ALL_TYPES,
        },
    ],
};

// Custom T3 User Featuer Set.
// const CUSTOM_T3_FEATURE_SET = {
//  _id: 'feature_set_custom_t3',
//  CREATE: [{
//      types: [CARD_BOOK],
//      quantity: {
//          'event-starter-book': 1,
//          'workgroupsss': 5
//      },
//      whitelistTemplates: ['ed-30-dashboard', 'c2T98XzJNHZA6oYSC', 'BwXiEoza2M8GNWuGu', 'D8vkYJ6SHwLrJQNq6', 'PwjnPoxGz5jAjjbRs', 'yxxtWvAf9skaen7vP', 'Xg2GPK4ghwDuqcJjN']
//  },
//  {
//      types: [CARD_CHAPTER]
//  },
//  {
//      types: BASIC_MEDIA_CARDS,
//  }],
//  UPDATE: ADMIN_BASIC,
//  DELETE: [],
// }

const CUSTOM_T3_FEATURE_SET = {
    _id: 'feature_set_custom_t3',
    CREATE: [],
    UPDATE: [],
    DELETE: [],
};

const CUSTOM_T3_FEATURE_SET_ADMIN = {
    _id: 'feature_set_custom_t3_admin',
    CREATE: [
        {
            types: BASIC_MEDIA_CARDS,
        },
    ],
    UPDATE: [
        {
            types: [CARD_LIBRARY],
            features: {
                ...LIBRARY_FEATURES_INFO_FRAGMENT,
                ...LIBRARY_FEATURES_SETTINGS_FRAGMENT,
                customSignupFields: false,
                memberStyle: false,
                recommendations: false,
            },
        },
        {
            types: [CARD_SHELF],
            features: {
                ...SHELF_FEATURES_INFO_FRAGMENT,
                displayReference: false,
            },
        },
        {
            types: [CARD_BOOK],
            features: {
                ...BOOK_FEATURES_INFO_FRAGMENT,
                displayReference: false,
                // ...BOOK_FEATURES_SETTINGS_FRAGMENT
            },
        },
        {
            types: [CARD_CHAPTER],
            features: {
                ...CHAPTER_FEATURES_INFO_FRAGMENT,
                action_move: true,
                action_tag: true,
                action_archive: true,
                create_resource_card: false,
                // ...CHAPTER_FEATURES_SETTINGS_FRAGMENT
            },
        },
        {
            types: ALL_CONTENT_CARDS,
            features: {
                ...CONTENT_CARD_FEATURES_FRAGMENT,
                action_move: true,
                action_tag: true,
                action_archive: true,
                create_resource_card: false,
            },
        },
    ],
    DELETE: [],
};

const CUSTOM_OASIS_FEATURE_SET = {
    _id: 'feature_set_custom_oasis',
    CREATE: [],
    UPDATE: [],
    DELETE: [],
};
const CUSTOM_OASIS_ADMIN_FEATURE_SET = {
    _id: 'feature_set_custom_admin_oasis',
    CREATE: [],
    UPDATE: [],
    DELETE: [],
};

const ADMIN_SANDBOX = {
    _id: 'feature_set_admin_sandbox',
    CREATE: [
        {
            types: BASIC_MEDIA_CARDS,
        },
        {
            types: [CARD_BOOK, CARD_CHAPTER],
        },
    ],
    UPDATE: [
        {
            types: [CARD_SHELF],
            features: {
                ...SHELF_FEATURES_INFO_FRAGMENT,
            },
        },
        {
            types: [CARD_BOOK],
            features: {
                ...BOOK_FEATURES_INFO_FRAGMENT,
                ...BOOK_FEATURES_SETTINGS_FRAGMENT,
            },
        },
        {
            types: [CARD_CHAPTER],
            features: {
                ...CHAPTER_FEATURES_INFO_FRAGMENT,
                ...CHAPTER_FEATURES_SETTINGS_FRAGMENT,
                action_move: true,
                action_archive: true,
                create_resource_card: true,
            },
        },
        {
            types: BASIC_MEDIA_CARDS,
            features: {
                ...CONTENT_CARD_FEATURES_FRAGMENT,
                ...CONTENT_CARD_ACTION_FEATURES_FRAGMENT,
            },
        },
        {
            types: GROUP_TYPES,
            features: {
                ...GROUP_BASIC_FEATURES_FRAGMENT,
                ...GROUP_ADVANCED_FEATURES_FRAGMENT,
            },
        },
    ],
    DELETE: [
        {
            types: ALL_TYPES,
        },
    ],
};

const FEATURE_SETS = {
    NORMAL_USER,
    GROUP_AND_PROJECT_LEADER,
    GROUP_FEATURE_SET,
    GROUP_USER,
    FULL_ADMIN_FEATURE_SET,
    SUPER_ADMIN_FEATURE_SET,
    CUSTOM_T3_FEATURE_SET,
    CUSTOM_T3_FEATURE_SET_ADMIN,
    CUSTOM_OASIS_FEATURE_SET,
    CUSTOM_OASIS_ADMIN_FEATURE_SET,
    ADMIN_SANDBOX,
};

export { FEATURE_SETS, ALL_TYPES };

export const getFeatureConstraintsForField = (card, field) => {
    if (card) {
        const { updateFeatureConstraints } = card;

        if (!updateFeatureConstraints) {
            return true;
        }
        if (updateFeatureConstraints.canEditAllFields) {
            return true;
        }

        const featureConstraint = _.find(updateFeatureConstraints.featuresUserCanEdit, {
            field,
        });
        if (featureConstraint && featureConstraint.canUpdate) {
            return featureConstraint;
        }
        return false;
    }
    return false;
};

export const canUserEditAnyFieldOfCard = card => {
    if (card) {
        const { updateFeatureConstraints } = card;

        if (!updateFeatureConstraints) {
            return true;
        }
        if (updateFeatureConstraints.canEditAllFields) {
            return true;
        }

        let oneIsEditable = false;
        _.forEach(updateFeatureConstraints.featuresUserCanEdit, field => {
            if (field && field.canUpdate) oneIsEditable = true;
        });
        return oneIsEditable;
    }
    return false;
};
