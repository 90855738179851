import produce, { castDraft } from 'immer';
import { ApolloCache } from '@apollo/client';
import { isEqual } from 'lodash';

import dialogStore from '@stores/Dialog';

import { GET_SINGLE_CARD_BY_ID } from '@shared/welibrary-graphql/content_card/queries';
import {
    SingleCardByIdQuery,
    SingleCardByIdQueryVariables,
} from '@shared/welibrary-graphql/content_card/queries.hook';
import { GET_QUEST_BY_ID, GET_USER_COURSE } from '@shared/welibrary-graphql/quest/queries';
import {
    QuestByIdQuery,
    QuestByIdQueryVariables,
    UserCourseQuery,
    UserCourseQueryVariables,
} from '@shared/welibrary-graphql/quest/queries.hook';

import { capitalize } from '@helpers/string.helpers';
import { filterMaybes } from '@helpers/types/type.helpers';
import { sample } from '@helpers/array.helpers';

import { PerformantMaybify } from '@core/types/Utilities';
import {
    QuestCreation,
    QuestCreationType,
    QuestNode,
    QuestNodeType,
    QuestDialog,
    QuestDialogType,
} from '@core/types/Quests';
import {
    Quest,
    ResourceCount,
    Resource,
    CreationState,
    ContentCard,
    RainbowRoadNode,
    RainbowRoadSettings,
    PositionType,
} from '@shared/welibrary-graphql/types';

export const isQuestCompleteable = (quest?: PerformantMaybify<Quest> | null): boolean => {
    if (!quest || quest.isComplete) return false;

    return quest.creationsState?.every(creationState => creationState?.isComplete) ?? false;
};

export const getNodeIndex = (
    url: string,
    quest?: PerformantMaybify<ContentCard> | null,
    reverse = true
): number => {
    const index = quest?.nodes?.findIndex(node => node?.alias?.url === url) ?? -1;

    if (!reverse) return index;

    return (quest?.nodes?.length ?? -1) - index - 1;
};

export const getFirstPostNodeIndex = (quest: PerformantMaybify<Quest>): number => {
    const nodes = [...(quest.rainbowRoad?.nodes ?? [])].reverse();

    return nodes?.findIndex(node => node?.preOrPost === 'post') ?? -1;
};

export const stringIsResource = (string: string): string is Resource =>
    (Object.values(Resource) as string[]).includes(string);

export const accruedResourcesToResources = (
    accruedResources: ResourceCount
): Record<Resource, number> => {
    return Object.entries(accruedResources).reduce<Record<Resource, number>>(
        (acc, [resource, quantity]) => {
            if (resource === '__typename' || quantity === 'ResourceCount') return acc;

            const capitalizedResource = capitalize(resource);

            if (stringIsResource(capitalizedResource)) acc[capitalizedResource] += quantity ?? 0;

            return acc;
        },
        {
            [Resource.Books]: 0,
            [Resource.Crayons]: 0,
            [Resource.Flowers]: 0,
            [Resource.Hearts]: 0,
            [Resource.Wood]: 0,
        }
    );
};

export const updateCreationsStateCache = (
    creationsState?: Array<CreationState | null> | null,
    creation?: ContentCard | null,
    level?: number
): CreationState[] | false => {
    if (!creationsState || !creation || typeof level === 'undefined') return false;

    const filteredState = creationsState.filter((x): x is CreationState => !!x);

    const creationStateIndex = filteredState.findIndex(
        state => state.creation?._id === creation._id
    );

    if (creationStateIndex < 0) {
        filteredState.push({
            __typename: 'CreationState',
            creation,
            level: level + 1,
            isComplete: level + 1 > (creation.creationItems?.levels?.length ?? 0),
        });

        return filteredState;
    }

    return produce(filteredState, draft => {
        draft[creationStateIndex].level = level + 1;
        draft[creationStateIndex].isComplete =
            level + 1 > (creation.creationItems?.levels?.length ?? 0);
    });
};

export const updateQuestCreationsStateCache = (
    cache: ApolloCache<any>,
    creationId = '',
    questId = '',
    level = 0
) => {
    const creation = cache.readQuery<SingleCardByIdQuery, SingleCardByIdQueryVariables>({
        query: GET_SINGLE_CARD_BY_ID,
        variables: { _id: creationId },
    });

    const quest = cache.readQuery<QuestByIdQuery, QuestByIdQueryVariables>({
        query: GET_QUEST_BY_ID,
        variables: { _id: questId },
    });

    if (!quest?.getQuestById) return;

    const creationsState = updateCreationsStateCache(
        quest.getQuestById?.creationsState,
        creation?.card,
        level
    );

    if (!creationsState) return;

    cache.writeQuery<QuestByIdQuery, QuestByIdQueryVariables>({
        query: GET_QUEST_BY_ID,
        variables: { _id: questId },
        data: produce(quest, draft => {
            draft.getQuestById.creationsState = castDraft(creationsState);
        }),
    });
};

export const updateUserCourseStateCache = (
    cache: ApolloCache<any>,
    courseId: string,
    questId?: string,
    creationId?: string,
    level = 0
) => {
    const userCourse = cache.readQuery<UserCourseQuery, UserCourseQueryVariables>({
        query: GET_USER_COURSE,
        variables: { courseId, questId, creationId },
    });

    if (!userCourse?.getUserCourse) return;

    cache.writeQuery<UserCourseQuery, UserCourseQueryVariables>({
        query: GET_USER_COURSE,
        variables: { courseId, questId, creationId },
        data: produce(userCourse, draft => {
            if (draft.getUserCourse) {
                draft.getUserCourse.currentState = {
                    __typename: 'UserCourseState',
                    level: level + 1,
                    step: 0,
                };
            }
        }),
    });
};

export const getCreations = (
    rainbowRoadSettings?: PerformantMaybify<RainbowRoadSettings> | null
): QuestCreationType[] => {
    return (
        rainbowRoadSettings?.questSettings?.creationsChannel?.children?.reduce<QuestCreationType[]>(
            (acc, child) => {
                const parsedChild = QuestCreation.safeParse(child);

                if (parsedChild.success) acc.push(parsedChild.data);

                return acc;
            },
            []
        ) ?? []
    );
};

export const getNodesListForQuest = (
    quest: PerformantMaybify<Quest>,
    creations?: QuestCreationType[]
): PerformantMaybify<RainbowRoadNode>[] => {
    const { rainbowRoad, currentNode, creationsState } = quest;

    const { rainbowRoadSettings, nodes: rawNodes } = rainbowRoad ?? {};

    const { questSettings } = rainbowRoadSettings ?? {};

    // calculate creations if it wasn't already passed in
    const _creations = creations ?? getCreations(rainbowRoadSettings);

    const preNodes = filterMaybes(
        rawNodes?.filter(
            (node, index) =>
                (node?.preOrPost ?? PositionType.Pre) === PositionType.Pre &&
                index >= rawNodes.length - (currentNode ?? 0) - 1
        ) ?? []
    );

    const postNodes = filterMaybes(
        rawNodes?.filter(
            (node, index) =>
                (node?.preOrPost ?? PositionType.Pre) === PositionType.Post &&
                index >= rawNodes.length - (currentNode ?? 0) - 1
        ) ?? []
    );

    const showPostNodes =
        _creations.filter(
            creation =>
                creationsState?.find(creationState => creationState?.creation?._id === creation._id)
                    ?.isComplete
        ).length >
        (questSettings?.completionRequirement ?? _creations.length) - 1;

    const creationNodes =
        creationsState?.reduce<QuestNodeType[]>((acc, creationState) => {
            if (!creationState?.creation) return acc;

            const { title: creationTitle, type, url: creationUrl } = creationState.creation;

            const parsedNode = QuestNode.safeParse({
                backgroundImage:
                    rainbowRoadSettings?.questSettings?.creationNodeSettings?.backgroundImage ??
                    undefined,
                icon: rainbowRoadSettings?.questSettings?.creationNodeSettings?.icon ?? undefined,
                spinnerImage:
                    rainbowRoadSettings?.questSettings?.creationNodeSettings?.spinnerImage ??
                    undefined,
                showMetaInformation:
                    rainbowRoadSettings?.questSettings?.creationNodeSettings?.showMetaInformation ??
                    false,
                title: creationTitle,
                alias: {
                    __typename: 'AliasItem',
                    title: creationTitle,
                    type,
                    url: creationUrl,
                    item: creationState.creation,
                },
            });

            if (parsedNode.success) acc.push(parsedNode.data);

            return acc;
        }, []) ?? [];

    const nodes: PerformantMaybify<RainbowRoadNode>[] = [
        ...[...creationNodes].reverse(), // immutably reversing due to how nodes are rendered
        ...preNodes,
    ];

    if (showPostNodes) nodes.unshift(...postNodes);

    return nodes;
};

export const getNodesListForQuestMap = (
    quest: PerformantMaybify<Quest>
): PerformantMaybify<RainbowRoadNode>[] => {
    const { rainbowRoad, currentNode } = quest;

    const { nodes } = rainbowRoad ?? {};

    return filterMaybes(
        nodes?.filter((_node, index) => index >= nodes.length - (currentNode ?? 0) - 1) ?? []
    );
};

export const getActiveDialog = (quest: PerformantMaybify<Quest>): QuestDialogType | null => {
    const { rainbowRoad, currentDialogEvent } = quest;

    const { rainbowRoadSettings } = rainbowRoad ?? {};
    const dialogs = filterMaybes(
        rainbowRoadSettings?.questSettings?.dialogsChannel?.children?.map(card => {
            const validatedDialog = QuestDialog.safeParse(card?.dialogSettings);

            return validatedDialog.success ? validatedDialog.data : undefined;
        }) ?? []
    );

    const activeDialogs = dialogs.filter(dialog => dialog.event === currentDialogEvent?.event);

    if (!isEqual(dialogStore.get.dialogs(), activeDialogs)) {
        dialogStore.set.dialogs(activeDialogs);
        dialogStore.set.randomDialog();
    }

    dialogStore.set.dialogEvent(currentDialogEvent);

    return dialogStore.get.dialog();
};

export const getQuestAfterCurrent = (
    quest?: PerformantMaybify<Quest> | null,
    questMap?: PerformantMaybify<ContentCard> | null
): PerformantMaybify<RainbowRoadNode> | false => {
    if (!quest?.rainbowRoad || !questMap?.nodes) return false;

    const nodeIndex = getNodeIndex(quest.rainbowRoad.url ?? '', questMap, false);

    if (nodeIndex < 1) return false;

    const nextNode = questMap.nodes[nodeIndex - 1];

    return nextNode || false;
};
