var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/user/mutations.hook.tsx
import { gql as gql7 } from "@apollo/client";

// src/user/fragments.hook.tsx
import { gql as gql5 } from "@apollo/client";

// src/organization/fragments.hook.tsx
import { gql } from "@apollo/client";
var OrganizationFragmentFragmentDoc = gql`
    fragment OrganizationFragment on Organization {
  __typename
  _id
  name
  created
  createdBy {
    _id
    profile {
      full_name
    }
  }
  updated
}
    `;

// src/content_card/fragments.hook.tsx
import { gql as gql4 } from "@apollo/client";

// src/comment/fragments.hook.tsx
import { gql as gql2 } from "@apollo/client";
var CommentFragmentFragmentDoc = gql2`
    fragment CommentFragment on Comment {
  __typename
  _id
  publish
  stream {
    __typename
    _id
  }
  creator {
    __typename
    _id
    profile {
      picture
      full_name
    }
  }
  body
  loggedInUserCanEdit
  childCount
  parentId
}
    `;

// src/tag/fragments.hook.tsx
import { gql as gql3 } from "@apollo/client";
var TagFragmentFragmentDoc = gql3`
    fragment TagFragment on Tag {
  __typename
  _id
  label
  color
  created
  updated
}
    `;

// src/content_card/fragments.hook.tsx
var ContentCardSearchFragmentFragmentDoc = gql4`
    fragment ContentCardSearchFragment on ContentCard {
  __typename
  _id
  url
  type
  title
  body
  description
  media {
    icon
    thumb
  }
  prompt
  isBookmarked
  isArchived
}
    `;
var ContentCardCourseSearchFragmentFragmentDoc = gql4`
    fragment ContentCardCourseSearchFragment on ContentCard {
  __typename
  _id
  url
  type
  title
  body
  description
  media {
    icon
    thumb
  }
  prompt
  courseItems {
    levels {
      title
      media
      resource
    }
  }
  isBookmarked
  isArchived
}
    `;
var CardCommentsFragmentFragmentDoc = gql4`
    fragment CardCommentsFragment on ContentCard {
  __typename
  _id
  comments {
    ...CommentFragment
  }
}
    ${CommentFragmentFragmentDoc}`;
var ContentCardPlatformFragmentFragmentDoc = gql4`
    fragment ContentCardPlatformFragment on ContentCard {
  _id
  __typename
  vs
  type
  url
  platformSettings {
    platformGroupOwner
    forms {
      formCardUrl
      formSpecificationSettings {
        type
        form {
          fieldGroups {
            groupName
            index
            fieldIds
          }
          required
          fields {
            index
            id
            type
            options
            prompt
            defaultComparator
            customText
            selectMultiple
            siblingId
            allowOther
            required
            showOnSignup
            groups {
              group {
                _id
                path
                groupActionsAllowed
                currentUserIsMember
                currentUserIsAdmin
                profile {
                  picture
                  full_name
                  short_bio
                }
                settings {
                  approvalRequired
                  protectionLevel
                }
              }
              id
              requireApproval
            }
            placeholder
            dependsOn {
              id
              values
            }
          }
        }
      }
    }
  }
}
    `;
var ContentCardBookSourceFragmentFragmentDoc = gql4`
    fragment ContentCardBookSourceFragment on ContentCard {
  __typename
  _id
  url
  title
  library_settings {
    displayReference
    protectionLevel
    accessRoleWhitelist
  }
  reference {
    groupSource {
      profile {
        full_name
      }
      settings {
        protectionLevel
      }
    }
  }
}
    `;
var ContentCardPledgesFragmentFragmentDoc = gql4`
    fragment ContentCardPledgesFragment on PledgeItems {
  __typename
  _id
  pledges {
    __typename
    _id
    label
    reportingType
    canPledge
    canDeletePledge
    totalPledges
    currentUsersPledges
    currentUsersPledgesMonth: currentUsersPledges(daysAgo: 30)
    currentUsersPledgesDay: currentUsersPledges(daysAgo: 1)
    successfulPledgesAll: currentUsersSuccessfulPledges
    successfulPledgesMonth: currentUsersSuccessfulPledges(daysAgo: 30)
    successfulPledgesDay: currentUsersSuccessfulPledges(daysAgo: 1)
    hidden
  }
  pledgeKeys {
    __typename
    _id
    label
    refactoryPeriod
    limitPerRefractoryPeriod
    description
    reportingType
    hidden
  }
  pledgers {
    __typename
    _id
    profile {
      full_name
      first_name
      picture
    }
  }
  latestPledgerEngagement {
    userId
    date
  }
  totalPledges
  totalPledgers
  pledgesLastDay: totalPledges(daysAgo: 1)
  pledgesLastMonth: totalPledges(daysAgo: 30)
}
    `;
var RevealCardFragmentFragmentDoc = gql4`
    fragment RevealCardFragment on ContentCard {
  __typename
  type
  links {
    source
    other
  }
  media {
    thumb
    icon
    logoLight
    logoDark
  }
}
    `;
var UpdateFeatureConstraintsFragmentsFragmentDoc = gql4`
    fragment UpdateFeatureConstraintsFragments on ContentCard {
  __typename
  updateFeatureConstraints {
    canEditAllFields
    featuresUserCanEdit {
      type
      field
      options
      canUpdate
    }
  }
}
    `;
var MobilizeEffortFragmentFragmentDoc = gql4`
    fragment MobilizeEffortFragment on ContentCard {
  __typename
  _id
  path
  url
  shortCode
  type
  title
  body
  description
  prompt
  rankOrder
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
      loggedInUserConnectionStatus
    }
    roles {
      isSpeakerOf
    }
  }
  date {
    hide_display
    last_update
    publish
  }
  links {
    source
    other
  }
  aliases {
    ... on GroupAliasHandle {
      url
      title
      color
      type
      group {
        __typename
        _id
        profile {
          full_name
          picture
          coverPhoto
        }
      }
    }
    ... on AliasItem {
      url
      title
      color
      type
      item {
        _id
        __typename
        title
        body
        protectionLevel
        prompt
        author {
          __typename
          _id
          type
          profile {
            full_name
          }
        }
        reference {
          href
          coverImage
          bookSource {
            ...ContentCardBookSourceFragment
          }
        }
        media {
          thumb
          icon
        }
        library_settings {
          postTypes
          engagementTypes
          filterTypes
          defaultFilter
          viewTypes
          defaultViewType
          allowPostbox
          displaySocialHeaders
          allowLabels
          displayReference
          displayInNewsfeed
          userCanPost
        }
        pledgeItems {
          ...ContentCardPledgesFragment
        }
        whitelabelVerbage {
          __typename
          _id
          value
        }
        isArchived
        userHasPledgedTo
        taskHistory {
          __typename
          cursor
          hasMore
          results {
            pledgeKeyId
            label
            refactoryPeriod
            date
            supplement {
              note
              image
              document
              documentTitle
            }
            verifier {
              name
              email
              phone
              country
              note
              newRecruit
            }
            claimedAccount {
              __typename
              _id
              profile {
                picture
                full_name
                email
                phone
                country
              }
            }
          }
          type
        }
      }
    }
  }
  media {
    thumb
    icon
    logoLight
    logoDark
    favicon
    webclip
  }
  loggedInUserCanEdit
  defaultHidden
  isBookmarked
  isArchived
  applicationItems {
    applications {
      __typename
      _id
      createdAt
      status
      data {
        values {
          id
          values
        }
      }
      user {
        __typename
        _id
        profile {
          full_name
          picture
          country
          website
          email
        }
      }
    }
  }
  postingSettings {
    type
    status
    totalPositionsCount
    acceptedCount
    specification {
      url
    }
    postingData {
      parent {
        url
      }
      values {
        id
        type
        customText
        comparator
        values
        siblingId
      }
    }
    requirementsData {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
  tags {
    __typename
    hasMore
    cursor
    type
    results {
      ...TagFragment
    }
  }
  loggedInUserApplyStatus
  isTemplate
  ...UpdateFeatureConstraintsFragments
}
    ${ContentCardBookSourceFragmentFragmentDoc}
${ContentCardPledgesFragmentFragmentDoc}
${TagFragmentFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardCourseFragmentFragmentDoc = gql4`
    fragment ContentCardCourseFragment on CourseItems {
  materials
  ageGroup
  duration
  people
  levels {
    title
    media
    video
    resource
    steps {
      text
      useImage
      image
      useVideo
      video
      fullPage
      pageBreak
      skill
      skillSubtype
      skillMilestone
    }
    preSurvey {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
    postSurvey {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
  }
  publicCompletions
}
    `;
var ContentCardCreationFragmentFragmentDoc = gql4`
    fragment ContentCardCreationFragment on CreationItems {
  silhouetteImage
  course {
    _id
    media {
      icon
      thumb
    }
    title
    courseItems {
      materials
      ageGroup
      duration
      people
      levels {
        title
        media
        resource
        preSurvey {
          _id
          type
          subtype
          prompt
          useImage
          image
          range
          answers
          correctAnswer
          skill
          skillSubtype
          skillMilestone
        }
        postSurvey {
          _id
          type
          subtype
          prompt
          useImage
          image
          range
          answers
          correctAnswer
          skill
          skillSubtype
          skillMilestone
        }
      }
    }
    url
  }
  levels {
    title
    description
    image
  }
}
    `;
var CardRatingsFragmentDoc = gql4`
    fragment CardRatings on ContentCard {
  ratings_sentiment_score
  latest_rating_sentiments {
    _id
    profile {
      full_name
    }
  }
  ratings_quality_score
  ratings_relevance_score {
    score
    hashtag
  }
  ratings_rank
  loggedInUserVotes {
    sentiment
    quality
    relevance
  }
}
    `;
var ContentCardCustomFieldsFragmentFragmentDoc = gql4`
    fragment ContentCardCustomFieldsFragment on ContentCard {
  _id
  customFields {
    manager
    parent {
      url
    }
    parentGroup {
      _id
    }
    values {
      id
      type
      comparator
      values
      siblingId
    }
  }
}
    `;
var ContentCardFragmentFragmentDoc = gql4`
    fragment ContentCardFragment on ContentCard {
  __typename
  _id
  vs
  path
  url
  shortCode
  type
  title
  body
  shortTitle
  description
  prompt
  commentCount
  latestComments {
    cursor
    hasMore
    type
    results {
      _id
      creator {
        _id
        profile {
          full_name
          picture
        }
      }
      body
    }
  }
  inheritSurveyAnswers
  rankOrder
  subtype
  pinnedCardIds
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
      loggedInUserConnectionStatus
      did
    }
    roles {
      isSpeakerOf
    }
  }
  date {
    hide_display
    last_update
    publish
  }
  links {
    title
    source
    openInModal
    other
    publisher
  }
  aliases {
    ... on GroupAliasHandle {
      url
      title
      color
      thumb
      icon
      type
      group {
        __typename
        _id
        subtype
        isUnpublished
        profile {
          full_name
          picture
          coverPhoto
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        groupActionsAllowed
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
      }
    }
    ... on MessageThreadAliasHandle {
      url
      title
      color
      thumb
      icon
      type
      thread {
        __typename
        _id
        displayName
        displayIcon
        created
        updated
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
      }
    }
    ... on AliasItem {
      url
      title
      color
      thumb
      icon
      type
      item {
        subtype
        __typename
        _id
        title
        shortTitle
        prompt
        vote_settings {
          QUALITY {
            active
          }
          RELEVANCE {
            active
          }
          SENTIMENT {
            active
          }
        }
        type
        author {
          __typename
          _id
          type
          profile {
            full_name
          }
        }
        reference {
          href
          coverImage
          shelfSource {
            title
            url
            library_settings {
              displayReference
              protectionLevel
            }
          }
          bookSource {
            ...ContentCardBookSourceFragment
          }
          groupSource {
            _id
            subtype
            profile {
              full_name
              picture
              coverPhoto
            }
          }
        }
        media {
          thumb
          icon
        }
        library_settings {
          postTypes
          engagementTypes
          filterTypes
          defaultFilter
          viewTypes
          defaultViewType
          allowPostbox
          displaySocialHeaders
          allowLabels
          displayReference
          protectionLevel
          displayInNewsfeed
          userCanPost
        }
        pledgeItems {
          ...ContentCardPledgesFragment
        }
        whitelabelVerbage {
          __typename
          _id
          value
        }
        isArchived
        userHasPledgedTo
        taskHistory {
          __typename
          cursor
          hasMore
          results {
            pledgeKeyId
            label
            refactoryPeriod
            date
            supplement {
              note
              image
              document
              documentTitle
            }
            verifier {
              name
              email
              phone
              country
              note
              newRecruit
            }
            claimedAccount {
              __typename
              _id
              profile {
                picture
                full_name
                email
                phone
                country
              }
            }
          }
          type
        }
      }
    }
  }
  media {
    thumb
    icon
    logoLight
    logoDark
    favicon
    webclip
    mediaMetaData {
      height
      width
    }
  }
  label {
    text
    type
  }
  formSpecificationSettings {
    type
    postingForm {
      required
      fieldGroups {
        groupName
        fieldIds
        index
      }
      fields {
        index
        type
        id
        options
        customText
        prompt
        placeholder
        type
        dependsOn {
          id
          values
        }
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
      }
    }
    applicationForm {
      required
      fieldGroups {
        groupName
        fieldIds
        index
      }
      fields {
        index
        type
        id
        options
        customText
        prompt
        placeholder
        type
      }
    }
    form {
      required
      fieldGroups {
        groupName
        fieldIds
        index
      }
      fields {
        index
        type
        id
        options
        customText
        prompt
        placeholder
        type
      }
    }
  }
  library_settings {
    postTypes
    engagementTypes
    filterTypes
    viewTypes
    defaultViewType
    defaultFilter
    allowPostbox
    postboxRoleWhiteList
    userCanPost
    displaySocialHeaders
    displayInNewsfeed
    showCreateChildren
    showResourceHeader
    allowLabels
    displayReference
    protectionLevel
    accessRoleWhitelist
    memberStyle
    customSignupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      showOnSignup
      groups {
        group {
          _id
          path
          groupActionsAllowed
          currentUserIsMember
          currentUserIsAdmin
          profile {
            picture
            full_name
            short_bio
          }
          settings {
            approvalRequired
            protectionLevel
          }
        }
        id
        requireApproval
      }
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
    dashboardContentItems {
      name
      url
      type
      filter
    }
    customRoles {
      name
      url
      color
    }
    sidebarMenu {
      name
      url
    }
    recommendations
  }
  sendNotifications
  vote_settings {
    QUALITY {
      active
    }
    RELEVANCE {
      active
    }
    SENTIMENT {
      active
    }
  }
  meta {
    course {
      revealType
      revealContent {
        ...RevealCardFragment
      }
      compensation
      time
      sequence
    }
    research {
      category
      journalName
      organization
      publicationType
      abstract
      authors
      fileURL
      tags
      issue
    }
    mobilize {
      date
      qualifications
      skills
      summary
      city
      state
      country
      cta
      goalnumber
      hours
      matchterm
      pledge
      timeCommit
      pertime
    }
    pledge {
      date
      qualifications
      skills
      summary
      city
      state
      country
      cta
      goalnumber
      hours
      matchterm
      pledge
      timeCommit
      pertime
    }
  }
  loggedInUserCanEdit
  currentUserHasRead
  defaultHidden
  isBookmarked
  isArchived
  tags {
    __typename
    hasMore
    cursor
    type
    results {
      ...TagFragment
    }
  }
  isTemplate
  postingSettings {
    type
    status
    totalPositionsCount
    acceptedCount
    specification {
      url
    }
    postingData {
      parent {
        url
      }
      values {
        id
        type
        customText
        comparator
        values
        siblingId
      }
    }
    requirementsData {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
  mobilizationSettings {
    approvalType
    status
    efforts {
      type
      card {
        __typename
        library_settings {
          postTypes
          engagementTypes
          filterTypes
          defaultFilter
          viewTypes
          defaultViewType
          allowPostbox
          displaySocialHeaders
          allowLabels
          displayReference
          displayInNewsfeed
        }
        ...MobilizeEffortFragment
      }
      status
      country
      countGoal
      count
    }
  }
  courseItems {
    ...ContentCardCourseFragment
  }
  creationItems {
    ...ContentCardCreationFragment
  }
  surveyQuestions {
    _id
    type
    subtype
    prompt
    useImage
    image
    range
    answers
    correctAnswer
    skill
    skillSubtype
    skillMilestone
  }
  timeAndPlaceSettings {
    headline
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    location {
      formatted_address
      location {
        type
        coordinates
      }
      city
      country
      postal
      state
    }
    link
    rsvpLink
    allDay
    timeZone {
      name
    }
  }
  eventOverviewDetails {
    title
    description
    headline
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    location {
      formatted_address
    }
    link
    rsvpLink
    allDay
    timeZone {
      name
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
  }
  eventSettings {
    headline
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    location {
      formatted_address
    }
    link
    rsvpLink
    allDay
    timeZone {
      name
    }
  }
  eventAttendance {
    user {
      _id
      profile {
        full_name
        picture
      }
      socialActionsAllowed
    }
    status
  }
  canMembersSuggestEdits
  showTitle
  protectionLevel
  currentUserCanSuggestEdit
  verticalScrolling
  dialogSettings {
    event
    people {
      name
      image
    }
    dialogs {
      person
      text
    }
  }
  showPostTypes
  ...UpdateFeatureConstraintsFragments
  ...CardRatings
  ...ContentCardCustomFieldsFragment
}
    ${ContentCardBookSourceFragmentFragmentDoc}
${ContentCardPledgesFragmentFragmentDoc}
${RevealCardFragmentFragmentDoc}
${TagFragmentFragmentDoc}
${MobilizeEffortFragmentFragmentDoc}
${ContentCardCourseFragmentFragmentDoc}
${ContentCardCreationFragmentFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}
${CardRatingsFragmentDoc}
${ContentCardCustomFieldsFragmentFragmentDoc}`;
var ContentCardReferenceFragmentFragmentDoc = gql4`
    fragment ContentCardReferenceFragment on ContentCard {
  reference {
    href
    isResource
    coverImage
    chapterSource {
      title
      url
      prompt
      showTitle
      inheritSurveyAnswers
      subtype
      vote_settings {
        QUALITY {
          active
        }
        RELEVANCE {
          active
        }
        SENTIMENT {
          active
        }
      }
      library_settings {
        engagementTypes
        viewTypes
        defaultViewType
        displayReference
        displaySocialHeaders
      }
      media {
        thumb
      }
    }
    bookSource {
      ...ContentCardBookSourceFragment
    }
    shelfSource {
      title
      url
      library_settings {
        displayReference
        protectionLevel
      }
    }
    librarySource {
      title
      url
      library_settings {
        displayReference
      }
    }
    groupSource {
      _id
      subtype
      profile {
        full_name
        picture
        coverPhoto
      }
      settings {
        shortCode
        shortCodeApprovalRequired
        protectionLevel
        approvalRequired
        formSpec {
          url
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      groupActionsAllowed
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
      currentUserIsAdmin
      currentUserIsDirectAdmin
      currentUserIsMember
    }
  }
}
    ${ContentCardBookSourceFragmentFragmentDoc}`;
var RainbowRoadFragmentFragmentDoc = gql4`
    fragment RainbowRoadFragment on ContentCard {
  __typename
  _id
  url
  vs
  type
  subtype
  title
  description
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
      loggedInUserConnectionStatus
    }
    roles {
      isSpeakerOf
    }
  }
  media {
    icon
  }
  loggedInUserCanEdit
  rainbowRoadSettings {
    header {
      color
      darkMode
      image
      backgroundImage
      showTopInfo
    }
    body {
      color
      darkMode
      image
    }
    nodes {
      circleSize
      icon
      lineStyle
      lineColor
      outline
      winding
      spinnerImage
    }
    font
    questSettings {
      creationsChannel {
        url
        title
        children {
          ...ContentCardFragment
        }
      }
      creationNodeSettings {
        icon
        backgroundImage
        showMetaInformation
        spinnerImage
      }
      completionRequirement
      dialogsChannel {
        url
        title
        children {
          ...ContentCardFragment
        }
      }
      questEndImage
      vcSettings {
        title
        name
        description
        image
      }
    }
  }
  library_settings {
    engagementTypes
  }
  nodes {
    title
    icon
    backgroundImage
    spinnerImage
    showMetaInformation
    preOrPost
    alias {
      ... on UserAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        user {
          _id
          profile {
            full_name
            picture
            coverPhoto
            full_bio
            short_bio
            website
            facebookProfile
            twitterProfile
            instagramProfile
            linkedInProfile
          }
          socialActionsAllowed
        }
      }
      ... on GroupAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        group {
          __typename
          _id
          path
          profile {
            full_name
            short_bio
            full_bio
            picture
            coverPhoto
            country
            website
            email
            customFields {
              parent {
                url
              }
              parentGroup {
                _id
              }
              manager
              values {
                id
                type
                comparator
                values
                siblingId
              }
            }
          }
          socialActionsAllowed
          shelf {
            __typename
            _id
            url
            media {
              thumb
              icon
            }
          }
          settings {
            __typename
            subscriptions
            formSpec {
              url
              formSpecificationSettings {
                type
                form {
                  required
                  fieldGroups {
                    groupName
                    fieldIds
                    index
                  }
                  fields {
                    index
                    type
                    id
                    options
                    customText
                    prompt
                    placeholder
                    type
                    selectMultiple
                    siblingId
                    defaultComparator
                    required
                    allowOther
                    dependsOn {
                      id
                      values
                    }
                  }
                }
              }
            }
            protectionLevel
            memberStyle
            dashboards {
              name
              url
              type
              filter
            }
            customSignupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
            recommendations
            canMembersSuggestEdits
            shortCode
            shortCodeApprovalRequired
            groupChatroom
            adminChatroom
            approvalRequired
            registrationTitle
            registrationDescription
            registrationActionText
            enableGroupNewsfeed
          }
          hasUnreadDashboard
          subtype
          totalMembers
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
          currentUserLastActiveDate
          groupActionsAllowed
          isTemplate
          allSignupFields {
            parentGroup {
              _id
            }
            manager
            signupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
          whitelabelVerbage {
            _id
            value
            plainText
          }
          parentGroup {
            __typename
            _id
            profile {
              full_name
            }
          }
          channelsBook {
            title
            url
            media {
              thumb
              icon
            }
            children {
              title
              url
              reference {
                href
              }
            }
          }
        }
      }
      ... on MessageThreadAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        thread {
          __typename
          _id
          participants {
            __typename
            user {
              __typename
              _id
              profile {
                __typename
                full_name
                short_bio
                full_bio
                picture
                coverPhoto
                facebookProfile
                instagramProfile
                twitterProfile
                linkedInProfile
              }
            }
            hasUnreadMessages
          }
          messages {
            __typename
            hasMore
            cursor
            type
            messages {
              __typename
              _id
              author {
                __typename
                _id
                profile {
                  __typename
                  first_name
                  full_name
                  picture
                }
              }
              text
              created
              updated
            }
          }
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
          currentUserNotificationLevel
        }
      }
      ... on AliasItem {
        url
        type
        title
        thumb
        icon
        color
        item {
          ...ContentCardFragment
          ...ContentCardReferenceFragment
        }
      }
    }
  }
  aliases {
    ... on UserAliasHandle {
      url
      type
      title
      thumb
      icon
      color
      user {
        _id
        profile {
          full_name
          picture
          coverPhoto
          full_bio
          short_bio
          website
          facebookProfile
          twitterProfile
          instagramProfile
          linkedInProfile
        }
        socialActionsAllowed
      }
    }
    ... on GroupAliasHandle {
      url
      type
      title
      thumb
      icon
      color
      group {
        __typename
        _id
        path
        profile {
          full_name
          short_bio
          full_bio
          picture
          coverPhoto
          country
          website
          email
          customFields {
            parent {
              url
            }
            parentGroup {
              _id
            }
            manager
            values {
              id
              type
              comparator
              values
              siblingId
            }
          }
        }
        socialActionsAllowed
        shelf {
          __typename
          _id
          url
          media {
            thumb
            icon
          }
        }
        settings {
          __typename
          subscriptions
          formSpec {
            url
            formSpecificationSettings {
              type
              form {
                required
                fieldGroups {
                  groupName
                  fieldIds
                  index
                }
                fields {
                  index
                  type
                  id
                  options
                  customText
                  prompt
                  placeholder
                  type
                  selectMultiple
                  siblingId
                  defaultComparator
                  required
                  allowOther
                  dependsOn {
                    id
                    values
                  }
                }
              }
            }
          }
          protectionLevel
          memberStyle
          dashboards {
            name
            url
            type
            filter
          }
          customSignupFields {
            id
            index
            type
            prompt
            customText
            options
            selectMultiple
            allowOther
            weight
            required
            link
            dependsOn {
              id
              values
            }
            defaultComparator
            siblingId
          }
          recommendations
          canMembersSuggestEdits
          shortCode
          shortCodeApprovalRequired
          groupChatroom
          adminChatroom
          approvalRequired
          registrationTitle
          registrationDescription
          registrationActionText
          enableGroupNewsfeed
        }
        hasUnreadDashboard
        subtype
        totalMembers
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
        currentUserLastActiveDate
        groupActionsAllowed
        isTemplate
        allSignupFields {
          parentGroup {
            _id
          }
          manager
          signupFields {
            id
            index
            type
            prompt
            customText
            options
            selectMultiple
            allowOther
            weight
            required
            link
            dependsOn {
              id
              values
            }
            defaultComparator
            siblingId
          }
        }
        whitelabelVerbage {
          _id
          value
          plainText
        }
        parentGroup {
          __typename
          _id
          profile {
            full_name
          }
        }
        channelsBook {
          title
          url
          media {
            thumb
            icon
          }
          children {
            title
            url
            reference {
              href
            }
          }
        }
      }
    }
    ... on MessageThreadAliasHandle {
      url
      type
      title
      thumb
      icon
      color
      thread {
        __typename
        _id
        participants {
          __typename
          user {
            __typename
            _id
            profile {
              __typename
              full_name
              short_bio
              full_bio
              picture
              coverPhoto
              facebookProfile
              instagramProfile
              twitterProfile
              linkedInProfile
            }
          }
          hasUnreadMessages
        }
        messages {
          __typename
          hasMore
          cursor
          type
          messages {
            __typename
            _id
            author {
              __typename
              _id
              profile {
                __typename
                first_name
                full_name
                picture
              }
            }
            text
            created
            updated
          }
        }
        displayName
        displayIcon
        created
        updated
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
        currentUserNotificationLevel
      }
    }
    ... on AliasItem {
      url
      type
      title
      thumb
      icon
      color
      item {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
      }
    }
  }
}
    ${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
var ViewPermissionsFragmentDoc = gql4`
    fragment ViewPermissions on ContentCard {
  currentViewPermissions {
    level
    canUserView
  }
}
    `;
var ContentCardChapterFragmentFragmentDoc = gql4`
    fragment ContentCardChapterFragment on ContentCard {
  _id
  vs
  type
  url
  title
  shortTitle
  media {
    icon
    thumb
  }
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
    }
  }
  description
  isArchived
  prompt
  loggedInUserCanEdit
  vote_settings {
    QUALITY {
      active
    }
    RELEVANCE {
      active
    }
    SENTIMENT {
      active
    }
  }
  library_settings {
    postTypes
    engagementTypes
    filterTypes
    viewTypes
    defaultViewType
    defaultFilter
    allowPostbox
    postboxRoleWhiteList
    userCanPost
    displaySocialHeaders
    displayInNewsfeed
    allowLabels
    displayReference
    tagAggregation {
      ...TagFragment
    }
    cardAggregation {
      __typename
      _id
      title
      url
      body
      media {
        thumb
        icon
      }
    }
  }
  tags {
    __typename
    hasMore
    cursor
    type
    results {
      ...TagFragment
    }
  }
  sendNotifications
  rolesAvailable {
    url
    name
  }
  ...ContentCardReferenceFragment
  rankOrder
  canMembersSuggestEdits
  currentUserCanSuggestEdit
  showTitle
  childrenCount
  showPostTypes
  inheritSurveyAnswers
  isTemplate
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${TagFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}
${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardBookFragmentFragmentDoc = gql4`
    fragment ContentCardBookFragment on ContentCard {
  _id
  vs
  type
  subtype
  url
  title
  media {
    icon
    thumb
  }
  author {
    _id
    type
    profile {
      full_name
    }
  }
  children(sort: ordered) {
    ...ContentCardChapterFragment
  }
  description
  body
  prompt
  promptEnabled
  loggedInUserFollows
  loggedInUserCanEdit
  isTemplate
  library_settings {
    displayReference
    displayInNewsfeed
    displaySocialHeaders
    protectionLevel
    accessRoleWhitelist
    defaultFilter
    filterTypes
    engagementTypes
    customRoles {
      name
      url
      color
    }
  }
  sendNotifications
  vote_settings {
    QUALITY {
      active
    }
    RELEVANCE {
      active
    }
    SENTIMENT {
      active
    }
  }
  customFields {
    parent {
      url
    }
    values {
      id
      type
      comparator
      values
      siblingId
    }
  }
  cardActionsAllowed
  rankOrder
  verticalScrolling
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${ContentCardChapterFragmentFragmentDoc}
${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardBookBigScreenFragmentFragmentDoc = gql4`
    fragment ContentCardBookBigScreenFragment on ContentCard {
  _id
  type
  url
  title
  media {
    icon
    thumb
    __typename
  }
  author {
    _id
    type
    profile {
      full_name
      __typename
    }
    __typename
  }
  library_settings {
    displaySocialHeaders
  }
  prompt
  loggedInUserFollows
  loggedInUserCanEdit
  __typename
}
    `;
var ContentCardShelfFragmentFragmentDoc = gql4`
    fragment ContentCardShelfFragment on ContentCard {
  __typename
  _id
  url
  shortCode
  type
  title
  body
  description
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
    }
  }
  group {
    __typename
    _id
    subtype
    username
    profile {
      full_name
      full_bio
      picture
    }
  }
  date {
    hide_display
    last_update
    publish
  }
  links {
    source
    other
  }
  media {
    thumb
    icon
  }
  library_settings {
    displayReference
    displayInNewsfeed
    dashboardContentItems {
      name
      url
      type
      filter
    }
    protectionLevel
    accessRoleWhitelist
  }
  customFields {
    parent {
      url
    }
    values {
      id
      type
      comparator
      values
      siblingId
    }
  }
  cardActionsAllowed
  loggedInUserFollows
  loggedInUserCanEdit
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardLibraryFragmentFragmentDoc = gql4`
    fragment ContentCardLibraryFragment on ContentCard {
  __typename
  _id
  url
  type
  title
  body
  description
  date {
    hide_display
    last_update
    publish
  }
  links {
    source
    other
  }
  media {
    thumb
    icon
    logoLight
    logoDark
    favicon
    webclip
  }
  loggedInUserFollows
  loggedInUserCanEdit
  library_settings {
    welcomeText
    autoFollowCards
    autoFollowUsers
    initialSubscriptions
    protectionLevel
    memberStyle
    sidebarMenu {
      name
      url
    }
    dashboardMenu {
      name
      url
    }
    dashboardContentItems {
      name
      url
      type
      filter
    }
    customRoles {
      name
      url
      color
    }
    customSignupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      showOnSignup
      groups {
        group {
          _id
          path
          groupActionsAllowed
          currentUserIsMember
          currentUserIsAdmin
          profile {
            picture
            full_name
            short_bio
          }
          settings {
            approvalRequired
            protectionLevel
          }
        }
        id
        requireApproval
      }
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
    displayReference
    displayInNewsfeed
    recommendations
  }
  cardActionsAllowed
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardChapterChildrenFragmentFragmentDoc = gql4`
    fragment ContentCardChapterChildrenFragment on ContentCard {
  __typename
  _id
  chapterChildren: children(restrictType: chapter) {
    ...ContentCardChapterFragment
  }
}
    ${ContentCardChapterFragmentFragmentDoc}`;

// src/user/fragments.hook.tsx
var GroupShelfFragmentFragmentDoc = gql5`
    fragment GroupShelfFragment on Group {
  __typename
  _id
  profile {
    full_name
    picture
    coverPhoto
  }
  shelf {
    __typename
    _id
    url
    media {
      thumb
      icon
    }
  }
}
    `;
var GroupProductFragmentFragmentDoc = gql5`
    fragment GroupProductFragment on Group {
  products {
    _id
    name
    description
    coupons {
      name
      code
      percentage
      type
      expiration
    }
    price
    currency
    type
    fulfillment {
      groupId
      role
    }
    created
    updated
  }
}
    `;
var GroupEventFragmentFragmentDoc = gql5`
    fragment GroupEventFragment on Group {
  __typename
  _id
  path
  profile {
    full_name
    short_bio
    full_bio
    picture
    coverPhoto
    country
    website
    email
  }
  socialActionsAllowed
  shelf {
    __typename
    _id
    url
    media {
      thumb
      icon
    }
  }
  settings {
    __typename
    formSpec {
      url
    }
    protectionLevel
    memberStyle
    recommendations
    canMembersSuggestEdits
    shortCode
    shortCodeApprovalRequired
    groupChatroom
    adminChatroom
    approvalRequired
    registrationTitle
    registrationDescription
    registrationActionText
    enableGroupNewsfeed
    enableMembershipID
  }
  eventOverviewDetails {
    title
    description
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    hideAttendeeCount
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
    sponsorSettings {
      description
      tiers {
        name
        order
        sponsors {
          name
          link
          logo
        }
      }
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
        postal
        location {
          type
          coordinates
        }
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          city
          state
          country
          postal
          location {
            type
            coordinates
          }
        }
      }
    }
  }
  timeAndPlaceSettings {
    _id
    title
    description
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
        postal
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          title
          thumb
          icon
          altName
          altBio
          useUserBio
          color
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              protectionLevel
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
  }
  subtype
  totalMembers
  totalChildGroups
  totalChildEventGroups
  membershipBoostUri
  currentUserIsAdmin
  currentUserIsDirectAdmin
  currentUserIsMember
  currentUserIsInterested
  currentUserIsInvited
  currentUserIsNotGoing
  currentUserOwnsMembership
  currentUserOwnsFreeSubscription
  currentUserLastActiveDate
  groupActionsAllowed
  doesSellMembership
  isUnpublished
  whitelabelVerbage {
    _id
    value
    plainText
  }
  parentGroup {
    __typename
    _id
    profile {
      full_name
      coverPhoto
      picture
      website
    }
  }
  paymentSettings {
    isGroupMembershipPaid
  }
  ...GroupProductFragment
}
    ${GroupProductFragmentFragmentDoc}`;
var UpdateGroupFeatureConstraintsFragmentFragmentDoc = gql5`
    fragment UpdateGroupFeatureConstraintsFragment on Group {
  __typename
  updateFeatureConstraints {
    canEditAllFields
    featuresUserCanEdit {
      type
      field
      options
      canUpdate
    }
  }
}
    `;
var GroupFragmentFragmentDoc = gql5`
    fragment GroupFragment on Group {
  __typename
  _id
  path
  profile {
    full_name
    short_bio
    full_bio
    picture
    coverPhoto
    country
    website
    email
    customFields {
      parent {
        url
      }
      parentGroup {
        _id
      }
      manager
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
  socialActionsAllowed
  shelf {
    __typename
    _id
    url
    loggedInUserCanEdit
    media {
      thumb
      icon
    }
  }
  settings {
    __typename
    subscriptions
    formSpec {
      url
      formSpecificationSettings {
        type
        form {
          required
          fieldGroups {
            groupName
            fieldIds
            index
          }
          fields {
            index
            type
            id
            options
            customText
            prompt
            placeholder
            type
            selectMultiple
            siblingId
            defaultComparator
            required
            showOnSignup
            groups {
              group {
                _id
              }
              id
              requireApproval
            }
            allowOther
            dependsOn {
              id
              values
            }
          }
        }
      }
    }
    protectionLevel
    memberStyle
    dashboards {
      name
      url
      type
      filter
    }
    customSignupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      showOnSignup
      groups {
        group {
          _id
        }
        id
        requireApproval
      }
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
    recommendations
    canMembersSuggestEdits
    shortCode
    shortCodeApprovalRequired
    groupChatroom
    adminChatroom
    membersHub
    welcomeCard {
      enabled
      title
      message
    }
    chatSettings {
      showChatHistory
      showReadReceipts
      showOnlineUsers
      isEncrypted
    }
    adminChatSettings {
      showChatHistory
      showReadReceipts
      showOnlineUsers
      isEncrypted
    }
    chatPermissions {
      editGroupSettings
      addMembers
      pinMessages
      sendMessages
    }
    approvalRequired
    registrationTitle
    registrationDescription
    registrationActionText
    enableGroupNewsfeed
    enableMembershipID
    lastCustomSignupFieldsUpdate
    adminChatroom
    groupChatroom
    enableGroupNewsfeed
    welcomeCard {
      enabled
      title
      message
    }
  }
  eventOverviewDetails {
    title
    description
    hideAttendeeCount
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
    sponsorSettings {
      description
      tiers {
        name
        order
        sponsors {
          name
          link
          logo
        }
      }
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
  }
  timeAndPlaceSettings {
    title
    description
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          altName
          altBio
          useUserBio
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
  }
  hasUnreadDashboard
  subtype
  totalMembers
  totalChildGroups
  totalChildEventGroups
  membershipBoostUri
  currentUserIsAdmin
  currentUserIsDirectAdmin
  currentUserIsMember
  currentUserIsInterested
  currentUserIsInvited
  currentUserIsNotGoing
  currentUserLastActiveDate
  currentUserJoinDate
  currentUserOwnsMembership
  currentUserOwnsFreeSubscription
  groupActionsAllowed
  doesSellMembership
  isTemplate
  isUnpublished
  allSignupFields {
    parentGroup {
      _id
    }
    manager
    signupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
  }
  whitelabelVerbage {
    _id
    value
    plainText
  }
  parentGroup {
    __typename
    _id
    profile {
      full_name
      coverPhoto
      picture
      website
      customFields {
        parent {
          url
        }
        parentGroup {
          _id
        }
        manager
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
  }
  channelsBook {
    title
    url
    media {
      thumb
      icon
    }
    children {
      title
      url
      reference {
        href
      }
    }
  }
  eventBook {
    url
    title
    children {
      _id
      title
      url
      rankOrder
      reference {
        href
      }
    }
  }
  ...UpdateGroupFeatureConstraintsFragment
  ...GroupProductFragment
}
    ${UpdateGroupFeatureConstraintsFragmentFragmentDoc}
${GroupProductFragmentFragmentDoc}`;
var CustomFieldsFragmentFragmentDoc = gql5`
    fragment CustomFieldsFragment on User {
  __typename
  _id
  profile {
    customFields {
      parent {
        url
      }
      parentGroup {
        _id
      }
      manager
      lastDismissalDate
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
}
    `;
var UserFragmentFragmentDoc = gql5`
    fragment UserFragment on User {
  __typename
  _id
  type
  profile {
    full_name
    short_bio
    full_bio
    picture
    coverPhoto
    facebookProfile
    instagramProfile
    twitterProfile
    linkedInProfile
    country
    location {
      formatted_address
      location {
        type
        coordinates
      }
      city
      state
      country
      postal
    }
    language
    website
    email
    phone
    nickname
    followersCount
    connectionsCount
    loggedInUserConnectionStatus
    protectionLevel
    newsfeedFilter
    organization {
      ...OrganizationFragment
    }
    simple {
      name
      age
    }
    avatar {
      avatar
      skinTone
      cheekColor
      hairColor
      eyeColor
      clothing1
      clothing2
      clothing3
      outline
    }
    displaySurveyAnswers
    temp
  }
  guardianAccount {
    isVerified
    profile {
      email
      full_name
    }
    featureSets
  }
  guardianCode
  childVerificationCodes
  socialActionsAllowed
  isAnonymous
  isVerified
  isUserSuperAdmin
  canCreate
  featureSets
  ...CustomFieldsFragment
}
    ${OrganizationFragmentFragmentDoc}
${CustomFieldsFragmentFragmentDoc}`;
var GroupMembershipFragmentFragmentDoc = gql5`
    fragment GroupMembershipFragment on Group {
  __typename
  _id
  totalMembers
  totalAdmins
  totalGuests
  owners(limit: $ownersLimit, cursor: $ownersCursor) {
    __typename
    hasMore
    cursor
    results {
      ...UserFragment
      doesUserOwnMembershipInGroup(groupId: $_id)
      doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
      productInstancesForGroup(groupId: $_id) {
        _id
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
        }
      }
    }
  }
  members(limit: $membersLimit, cursor: $membersCursor) {
    __typename
    hasMore
    cursor
    results {
      ...UserFragment
      doesUserOwnMembershipInGroup(groupId: $_id)
      doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
      productInstancesForGroup(groupId: $_id) {
        _id
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
        }
      }
    }
  }
  guests {
    __typename
    _id
    profile {
      email
      full_name
      temp
    }
  }
  giftedSubscriptions {
    __typename
    _id
    ...UserFragment
    profile {
      email
      full_name
      temp
    }
    doesUserOwnMembershipInGroup(groupId: $_id)
    doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
    productInstancesForGroup(groupId: $_id) {
      _id
      transferToken
      product {
        _id
        name
        description
        price
        currency
        type
      }
    }
  }
  memberRequests {
    user {
      __typename
      ...UserFragment
      roleForGroup(groupId: $_id)
    }
    role
    message
  }
  memberInvites {
    user {
      __typename
      ...UserFragment
      roleForGroup(groupId: $_id)
    }
    role
    message
  }
  emailInvites {
    userEmail
    role
    message
  }
}
    ${UserFragmentFragmentDoc}`;
var GroupOptimizerDataFragmentFragmentDoc = gql5`
    fragment GroupOptimizerDataFragment on Group {
  _id
  profile {
    full_name
    picture
    short_bio
  }
  settings {
    recommendations
    customSignupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      showOnSignup
      groups {
        group {
          _id
        }
        id
        requireApproval
      }
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
  }
}
    `;
var UserPersonalFeedFragmentFragmentDoc = gql5`
    fragment UserPersonalFeedFragment on ContentCard {
  __typename
  ...ContentCardFragment
}
    ${ContentCardFragmentFragmentDoc}`;
var GroupDashboardFragmentFragmentDoc = gql5`
    fragment GroupDashboardFragment on PaginatedCards {
  __typename
  hasMore
  cursor
  results {
    ...ContentCardReferenceFragment
    ...ContentCardFragment
    ...ContentCardChapterChildrenFragment
  }
  type
}
    ${ContentCardReferenceFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}
${ContentCardChapterChildrenFragmentFragmentDoc}`;
var ProductFragmentFragmentDoc = gql5`
    fragment ProductFragment on Product {
  _id
  name
  price
  currency
  type
  fulfillment {
    groupId
    role
  }
  created
  updated
  createdBy {
    profile {
      full_name
      coverPhoto
    }
  }
  reference {
    ... on Group {
      _id
      profile {
        full_name
        picture
        coverPhoto
      }
      subtype
      doesSellMembership
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
    }
  }
}
    `;
var OrderFragmentFragmentDoc = gql5`
    fragment OrderFragment on CustomerOrder {
  _id
  paymentIntent {
    id
    amount
    client_secret
    currency
    customer
    description
    next_action
    payment_method {
      id
      customer
      type
      object
      card {
        id
        brand
        last4
        exp_month
        exp_year
        funding
      }
    }
    receipt_email
    status
    livemode
  }
  products {
    ...ProductFragment
  }
  created
  updated
  status
}
    ${ProductFragmentFragmentDoc}`;
var TimeAndPlaceSettingsFragmentFragmentDoc = gql5`
    fragment TimeAndPlaceSettingsFragment on TimeAndPlaceSettings {
  _id
  title
  description
  date
  dates {
    startDate
    endDate
  }
  startTime
  endTime
  displayStartTime
  displayEndTime
  link
  timeZone {
    name
  }
  hideAttendeeCount
  eventLocationSettings {
    location {
      formatted_address
      city
      state
      country
      postal
    }
    type
    link
    venue {
      name
      link
      location {
        formatted_address
        location {
          type
          coordinates
        }
        postal
        city
        state
        country
      }
    }
  }
  speakerSettings {
    aliases {
      ... on UserAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        user {
          _id
          profile {
            email
            full_name
            picture
            coverPhoto
            full_bio
            short_bio
            website
            facebookProfile
            twitterProfile
            instagramProfile
            linkedInProfile
            city
            country
            organization {
              name
            }
            location {
              city
              state
              country
            }
          }
          socialActionsAllowed
        }
      }
    }
  }
}
    `;

// src/messaging/fragments.hook.tsx
import { gql as gql6 } from "@apollo/client";
var MessageFragmentOmitThreadFragmentDoc = gql6`
    fragment MessageFragmentOmitThread on Message {
  __typename
  _id
  author {
    __typename
    _id
    profile {
      __typename
      full_name
      picture
      short_bio
    }
  }
  reactions {
    emoji
    userIds
  }
  attachment {
    ...ContentCardFragment
    ...ContentCardReferenceFragment
  }
  text
  created
  updated
}
    ${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
var MessageThreadFragmentFragmentDoc = gql6`
    fragment MessageThreadFragment on MessageThread {
  __typename
  _id
  participants {
    __typename
    user {
      __typename
      _id
      type
      profile {
        __typename
        full_name
        short_bio
        full_bio
        picture
        coverPhoto
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
      }
    }
    hasUnreadMessages
  }
  messages {
    __typename
    hasMore
    cursor
    type
    messages {
      __typename
      _id
      author {
        __typename
        _id
        profile {
          __typename
          first_name
          full_name
          picture
        }
      }
      text
      reactions {
        emoji
        userIds
      }
      attachment {
        type
        _id
        vs
      }
      created
      updated
    }
  }
  displayName
  displayIcon
  created
  updated
  group {
    __typename
    _id
    totalMembers
    profile {
      full_name
      picture
      coverPhoto
      short_bio
    }
    settings {
      protectionLevel
      approvalRequired
      shortCodeApprovalRequired
    }
    products {
      _id
      name
    }
  }
  currentUserNotificationLevel
  threadType
}
    `;
var MessageFragmentFragmentDoc = gql6`
    fragment MessageFragment on Message {
  __typename
  _id
  author {
    __typename
    _id
    profile {
      __typename
      full_name
      picture
      short_bio
    }
  }
  thread {
    ...MessageThreadFragment
  }
  attachment {
    ...ContentCardFragment
    ...ContentCardReferenceFragment
  }
  text
  reactions {
    emoji
    userIds
  }
  created
  updated
}
    ${MessageThreadFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
var MessageUpdateFragmentFragmentDoc = gql6`
    fragment MessageUpdateFragment on MessageUpdate {
  action
  message {
    ... on Message {
      ...MessageFragment
    }
    ... on MessageDelete {
      _id
      thread {
        ...MessageThreadFragment
      }
    }
  }
}
    ${MessageFragmentFragmentDoc}
${MessageThreadFragmentFragmentDoc}`;

// src/user/mutations.hook.tsx
import * as Apollo from "@apollo/client";
var defaultOptions = {};
var AccountHolderType = /* @__PURE__ */ ((AccountHolderType2) => {
  AccountHolderType2["Company"] = "company";
  AccountHolderType2["Individual"] = "individual";
  return AccountHolderType2;
})(AccountHolderType || {});
var AccountStatus = /* @__PURE__ */ ((AccountStatus2) => {
  AccountStatus2["Active"] = "active";
  AccountStatus2["Inactive"] = "inactive";
  AccountStatus2["Invited"] = "invited";
  return AccountStatus2;
})(AccountStatus || {});
var AggregationType = /* @__PURE__ */ ((AggregationType2) => {
  AggregationType2["Groups"] = "GROUPS";
  AggregationType2["Nations"] = "NATIONS";
  AggregationType2["Recruits"] = "RECRUITS";
  AggregationType2["Users"] = "USERS";
  return AggregationType2;
})(AggregationType || {});
var AnalyticScopeType = /* @__PURE__ */ ((AnalyticScopeType2) => {
  AnalyticScopeType2["Group"] = "GROUP";
  AnalyticScopeType2["PledgeKey"] = "PLEDGE_KEY";
  return AnalyticScopeType2;
})(AnalyticScopeType || {});
var ApplicationStatus = /* @__PURE__ */ ((ApplicationStatus2) => {
  ApplicationStatus2["Accepted"] = "ACCEPTED";
  ApplicationStatus2["Cancelled"] = "CANCELLED";
  ApplicationStatus2["Closed"] = "CLOSED";
  ApplicationStatus2["Open"] = "OPEN";
  ApplicationStatus2["Rejected"] = "REJECTED";
  return ApplicationStatus2;
})(ApplicationStatus || {});
var ApprovalType = /* @__PURE__ */ ((ApprovalType2) => {
  ApprovalType2["Auto"] = "AUTO";
  ApprovalType2["Manual"] = "MANUAL";
  ApprovalType2["Skip"] = "SKIP";
  return ApprovalType2;
})(ApprovalType || {});
var BulkCardAction = /* @__PURE__ */ ((BulkCardAction2) => {
  BulkCardAction2["ResetChildrenSettings"] = "RESET_CHILDREN_SETTINGS";
  return BulkCardAction2;
})(BulkCardAction || {});
var CardActions = /* @__PURE__ */ ((CardActions2) => {
  CardActions2["AdminJoin"] = "adminJoin";
  CardActions2["CancelJoinRequest"] = "cancelJoinRequest";
  CardActions2["ExitMembership"] = "exitMembership";
  CardActions2["Follow"] = "follow";
  CardActions2["MemberJoin"] = "memberJoin";
  CardActions2["OpenJoin"] = "openJoin";
  CardActions2["Subscribe"] = "subscribe";
  CardActions2["Unfollow"] = "unfollow";
  CardActions2["Unsubscribe"] = "unsubscribe";
  return CardActions2;
})(CardActions || {});
var CardEngagementType = /* @__PURE__ */ ((CardEngagementType2) => {
  CardEngagementType2["Bookmark"] = "bookmark";
  CardEngagementType2["Comment"] = "comment";
  CardEngagementType2["Follow"] = "follow";
  CardEngagementType2["Heart"] = "heart";
  CardEngagementType2["Share"] = "share";
  return CardEngagementType2;
})(CardEngagementType || {});
var CardPostType = /* @__PURE__ */ ((CardPostType2) => {
  CardPostType2["Blog"] = "blog";
  CardPostType2["Book"] = "book";
  CardPostType2["Channel"] = "channel";
  CardPostType2["Chapter"] = "chapter";
  CardPostType2["Collection"] = "collection";
  CardPostType2["Course"] = "course";
  CardPostType2["Creation"] = "creation";
  CardPostType2["CustomFieldsSummary"] = "custom_fields_summary";
  CardPostType2["Dialog"] = "dialog";
  CardPostType2["Document"] = "document";
  CardPostType2["Dynamic"] = "dynamic";
  CardPostType2["Event"] = "event";
  CardPostType2["Formspec"] = "formspec";
  CardPostType2["Group"] = "group";
  CardPostType2["Header"] = "header";
  CardPostType2["Image"] = "image";
  CardPostType2["ImageSquare"] = "image_square";
  CardPostType2["Library"] = "library";
  CardPostType2["Link"] = "link";
  CardPostType2["Linktile"] = "linktile";
  CardPostType2["Map"] = "map";
  CardPostType2["Metachannel"] = "metachannel";
  CardPostType2["Mobilization"] = "mobilization";
  CardPostType2["Mobilize"] = "mobilize";
  CardPostType2["Pledge"] = "pledge";
  CardPostType2["Position"] = "position";
  CardPostType2["Postbox"] = "postbox";
  CardPostType2["Posting"] = "posting";
  CardPostType2["Profile"] = "profile";
  CardPostType2["RainbowRoad"] = "rainbow_road";
  CardPostType2["Research"] = "research";
  CardPostType2["Shelf"] = "shelf";
  CardPostType2["Story"] = "story";
  CardPostType2["Summary"] = "summary";
  CardPostType2["Survey"] = "survey";
  CardPostType2["Text"] = "text";
  CardPostType2["Video"] = "video";
  CardPostType2["Votegallery"] = "votegallery";
  return CardPostType2;
})(CardPostType || {});
var CardVoteType = /* @__PURE__ */ ((CardVoteType2) => {
  CardVoteType2["BallotMeasure"] = "BALLOT_MEASURE";
  CardVoteType2["Quality"] = "QUALITY";
  CardVoteType2["Relevance"] = "RELEVANCE";
  CardVoteType2["Sentiment"] = "SENTIMENT";
  return CardVoteType2;
})(CardVoteType || {});
var Collection = /* @__PURE__ */ ((Collection2) => {
  Collection2["Application"] = "APPLICATION";
  Collection2["Comment"] = "COMMENT";
  Collection2["ContentCard"] = "CONTENT_CARD";
  Collection2["Group"] = "GROUP";
  Collection2["Message"] = "MESSAGE";
  Collection2["MessageThread"] = "MESSAGE_THREAD";
  Collection2["User"] = "USER";
  return Collection2;
})(Collection || {});
var CommunityTypes = /* @__PURE__ */ ((CommunityTypes2) => {
  CommunityTypes2["AllPeople"] = "all_people";
  CommunityTypes2["Connections"] = "connections";
  CommunityTypes2["Followers"] = "followers";
  CommunityTypes2["Following"] = "following";
  CommunityTypes2["SharedGroups"] = "shared_groups";
  return CommunityTypes2;
})(CommunityTypes || {});
var ComparatorType = /* @__PURE__ */ ((ComparatorType2) => {
  ComparatorType2["MultiAndPos"] = "MULTI_AND_POS";
  ComparatorType2["MultiOrPos"] = "MULTI_OR_POS";
  ComparatorType2["None"] = "NONE";
  ComparatorType2["ProximityFar"] = "PROXIMITY_FAR";
  ComparatorType2["ProximityNear"] = "PROXIMITY_NEAR";
  ComparatorType2["RangePos"] = "RANGE_POS";
  return ComparatorType2;
})(ComparatorType || {});
var ConnectionAction = /* @__PURE__ */ ((ConnectionAction2) => {
  ConnectionAction2["AcceptConnection"] = "acceptConnection";
  ConnectionAction2["CancelRequestConnection"] = "cancelRequestConnection";
  ConnectionAction2["Disconnect"] = "disconnect";
  ConnectionAction2["RejectConnection"] = "rejectConnection";
  ConnectionAction2["RequestConnection"] = "requestConnection";
  return ConnectionAction2;
})(ConnectionAction || {});
var ConnectionStatus = /* @__PURE__ */ ((ConnectionStatus2) => {
  ConnectionStatus2["Connected"] = "connected";
  ConnectionStatus2["Disconnected"] = "disconnected";
  ConnectionStatus2["IsLoggedInUser"] = "isLoggedInUser";
  ConnectionStatus2["Pending"] = "pending";
  ConnectionStatus2["Rejected"] = "rejected";
  ConnectionStatus2["Requested"] = "requested";
  return ConnectionStatus2;
})(ConnectionStatus || {});
var CouponType = /* @__PURE__ */ ((CouponType2) => {
  CouponType2["Default"] = "default";
  return CouponType2;
})(CouponType || {});
var CourseProgressType = /* @__PURE__ */ ((CourseProgressType2) => {
  CourseProgressType2["LevelComplete"] = "level_complete";
  CourseProgressType2["StepComplete"] = "step_complete";
  return CourseProgressType2;
})(CourseProgressType || {});
var CustomSignupFieldTypes = /* @__PURE__ */ ((CustomSignupFieldTypes2) => {
  CustomSignupFieldTypes2["ChooseRole"] = "choose_role";
  CustomSignupFieldTypes2["ConsentBox"] = "consent_box";
  CustomSignupFieldTypes2["Description"] = "description";
  CustomSignupFieldTypes2["Dropdown"] = "dropdown";
  CustomSignupFieldTypes2["JoinGroup"] = "joinGroup";
  CustomSignupFieldTypes2["Numberslider"] = "numberslider";
  CustomSignupFieldTypes2["Paragraph"] = "paragraph";
  CustomSignupFieldTypes2["ProfileLocation"] = "profile_location";
  CustomSignupFieldTypes2["ProfileOrganization"] = "profile_organization";
  CustomSignupFieldTypes2["Shortanswer"] = "shortanswer";
  CustomSignupFieldTypes2["Signature"] = "signature";
  CustomSignupFieldTypes2["UploadFile"] = "uploadFile";
  return CustomSignupFieldTypes2;
})(CustomSignupFieldTypes || {});
var ElectionStatus = /* @__PURE__ */ ((ElectionStatus2) => {
  ElectionStatus2["ActiveTimed"] = "ACTIVE_TIMED";
  ElectionStatus2["ActiveUntimed"] = "ACTIVE_UNTIMED";
  ElectionStatus2["Closed"] = "CLOSED";
  ElectionStatus2["Created"] = "CREATED";
  ElectionStatus2["Scheduled"] = "SCHEDULED";
  return ElectionStatus2;
})(ElectionStatus || {});
var EngagementFilterType = /* @__PURE__ */ ((EngagementFilterType2) => {
  EngagementFilterType2["CustomField"] = "CUSTOM_FIELD";
  EngagementFilterType2["Deactivated"] = "DEACTIVATED";
  EngagementFilterType2["Group"] = "GROUP";
  EngagementFilterType2["Nation"] = "NATION";
  EngagementFilterType2["NewRecruit"] = "NEW_RECRUIT";
  EngagementFilterType2["PledgeCard"] = "PLEDGE_CARD";
  EngagementFilterType2["PledgeKey"] = "PLEDGE_KEY";
  EngagementFilterType2["Recruiter"] = "RECRUITER";
  EngagementFilterType2["RecruitType"] = "RECRUIT_TYPE";
  EngagementFilterType2["TimeWindow"] = "TIME_WINDOW";
  return EngagementFilterType2;
})(EngagementFilterType || {});
var EngagementSortOrder = /* @__PURE__ */ ((EngagementSortOrder2) => {
  EngagementSortOrder2["Asc"] = "ASC";
  EngagementSortOrder2["Desc"] = "DESC";
  return EngagementSortOrder2;
})(EngagementSortOrder || {});
var EngagementSortType = /* @__PURE__ */ ((EngagementSortType2) => {
  EngagementSortType2["Comments"] = "COMMENTS";
  EngagementSortType2["CreateDate"] = "CREATE_DATE";
  EngagementSortType2["JoinDate"] = "JOIN_DATE";
  EngagementSortType2["Name"] = "NAME";
  EngagementSortType2["Pledges"] = "PLEDGES";
  EngagementSortType2["Posts"] = "POSTS";
  EngagementSortType2["RecruitDate"] = "RECRUIT_DATE";
  EngagementSortType2["Votes"] = "VOTES";
  return EngagementSortType2;
})(EngagementSortType || {});
var EngagementType = /* @__PURE__ */ ((EngagementType2) => {
  EngagementType2["Comments"] = "COMMENTS";
  EngagementType2["Pledges"] = "PLEDGES";
  EngagementType2["Posts"] = "POSTS";
  EngagementType2["Votes"] = "VOTES";
  return EngagementType2;
})(EngagementType || {});
var EventAction = /* @__PURE__ */ ((EventAction2) => {
  EventAction2["Create"] = "CREATE";
  EventAction2["Delete"] = "DELETE";
  EventAction2["Update"] = "UPDATE";
  EventAction2["AcceptConnection"] = "acceptConnection";
  EventAction2["AdminAnnouncement"] = "adminAnnouncement";
  EventAction2["ApplicationAccepted"] = "applicationAccepted";
  EventAction2["ApplicationClosed"] = "applicationClosed";
  EventAction2["ApplicationRejected"] = "applicationRejected";
  EventAction2["AttachedCardToChapter"] = "attachedCardToChapter";
  EventAction2["BroadcastSurvey"] = "broadcastSurvey";
  EventAction2["CancelRequestConnection"] = "cancelRequestConnection";
  EventAction2["CardAccessRequest"] = "cardAccessRequest";
  EventAction2["CardContentAutoModerated"] = "cardContentAutoModerated";
  EventAction2["CardContentModeratedAccepted"] = "cardContentModeratedAccepted";
  EventAction2["CardContentModeratedRejected"] = "cardContentModeratedRejected";
  EventAction2["DashfeedPushed"] = "dashfeedPushed";
  EventAction2["Disconnect"] = "disconnect";
  EventAction2["DownloadLausanneSurveyPdf"] = "downloadLausanneSurveyPdf";
  EventAction2["ElectionEnd"] = "electionEnd";
  EventAction2["ElectionReminder"] = "electionReminder";
  EventAction2["ElectionStart"] = "electionStart";
  EventAction2["EventCanceled"] = "eventCanceled";
  EventAction2["GroupAccessApproved"] = "groupAccessApproved";
  EventAction2["GroupAccessRequest"] = "groupAccessRequest";
  EventAction2["GroupAdminPromoted"] = "groupAdminPromoted";
  EventAction2["GroupConnectedAccountReady"] = "groupConnectedAccountReady";
  EventAction2["GroupContentAutoModerated"] = "groupContentAutoModerated";
  EventAction2["GroupContentModeratedAccepted"] = "groupContentModeratedAccepted";
  EventAction2["GroupContentModeratedRejected"] = "groupContentModeratedRejected";
  EventAction2["GroupInviteAccepted"] = "groupInviteAccepted";
  EventAction2["GroupInviteRequest"] = "groupInviteRequest";
  EventAction2["GroupPreAuthPaymentRequest"] = "groupPreAuthPaymentRequest";
  EventAction2["GroupPreAuthPaymentRequestApproved"] = "groupPreAuthPaymentRequestApproved";
  EventAction2["GroupSpeakerInvite"] = "groupSpeakerInvite";
  EventAction2["MissedPledgeCommitment"] = "missedPledgeCommitment";
  EventAction2["MobilizationComplete"] = "mobilizationComplete";
  EventAction2["MobilizationEffortComplete"] = "mobilizationEffortComplete";
  EventAction2["NewApplication"] = "newApplication";
  EventAction2["NewMessage"] = "newMessage";
  EventAction2["NotifyGroupsOfTarget"] = "notifyGroupsOfTarget";
  EventAction2["PostingClosed"] = "postingClosed";
  EventAction2["RejectConnection"] = "rejectConnection";
  EventAction2["RequestConnection"] = "requestConnection";
  EventAction2["RoleAdminPromotion"] = "roleAdminPromotion";
  EventAction2["SubscriptionDeleted"] = "subscriptionDeleted";
  EventAction2["SubscriptionPriceChange"] = "subscriptionPriceChange";
  EventAction2["TicketTransfer"] = "ticketTransfer";
  EventAction2["UnreadMessagesNudge"] = "unreadMessagesNudge";
  EventAction2["VoteAdded"] = "voteAdded";
  return EventAction2;
})(EventAction || {});
var EventRsvp = /* @__PURE__ */ ((EventRsvp2) => {
  EventRsvp2["Going"] = "GOING";
  EventRsvp2["Interested"] = "INTERESTED";
  EventRsvp2["MaybeAttending"] = "MAYBE_ATTENDING";
  EventRsvp2["NotAttending"] = "NOT_ATTENDING";
  EventRsvp2["NotGoing"] = "NOT_GOING";
  EventRsvp2["NoResponse"] = "NO_RESPONSE";
  EventRsvp2["YesAttending"] = "YES_ATTENDING";
  return EventRsvp2;
})(EventRsvp || {});
var FilterType = /* @__PURE__ */ ((FilterType2) => {
  FilterType2["Archived"] = "archived";
  FilterType2["Az"] = "az";
  FilterType2["Custom"] = "custom";
  FilterType2["Leaderboard"] = "leaderboard";
  FilterType2["Live"] = "live";
  FilterType2["OldestFirst"] = "oldestFirst";
  FilterType2["Ordered"] = "ordered";
  FilterType2["Past"] = "past";
  FilterType2["Top"] = "top";
  FilterType2["Upcoming"] = "upcoming";
  FilterType2["Vote"] = "vote";
  return FilterType2;
})(FilterType || {});
var FormSpecificationType = /* @__PURE__ */ ((FormSpecificationType2) => {
  FormSpecificationType2["Posting"] = "POSTING";
  FormSpecificationType2["Standard"] = "STANDARD";
  return FormSpecificationType2;
})(FormSpecificationType || {});
var GroupActions = /* @__PURE__ */ ((GroupActions2) => {
  GroupActions2["AcceptInviteGroup"] = "acceptInviteGroup";
  GroupActions2["CancelPreAuthPurchase"] = "cancelPreAuthPurchase";
  GroupActions2["CancelRequestJoinGroup"] = "cancelRequestJoinGroup";
  GroupActions2["EditProducts"] = "editProducts";
  GroupActions2["ExpressInterest"] = "expressInterest";
  GroupActions2["JoinGroup"] = "joinGroup";
  GroupActions2["LeaveGroup"] = "leaveGroup";
  GroupActions2["LoggedOutExpressInterest"] = "loggedOutExpressInterest";
  GroupActions2["LoggedOutPurchaseAccess"] = "loggedOutPurchaseAccess";
  GroupActions2["LoggedOutRsvp"] = "loggedOutRSVP";
  GroupActions2["PurchaseAccess"] = "purchaseAccess";
  GroupActions2["PurchaseParentAccess"] = "purchaseParentAccess";
  GroupActions2["ReactivateSubscription"] = "reactivateSubscription";
  GroupActions2["RequestJoinGroup"] = "requestJoinGroup";
  GroupActions2["RequestToPurchaseAccess"] = "requestToPurchaseAccess";
  GroupActions2["RespondNotGoing"] = "respondNotGoing";
  GroupActions2["ViewPurchasedProducts"] = "viewPurchasedProducts";
  return GroupActions2;
})(GroupActions || {});
var GroupRole = /* @__PURE__ */ ((GroupRole2) => {
  GroupRole2["Invited"] = "invited";
  GroupRole2["Member"] = "member";
  GroupRole2["None"] = "none";
  GroupRole2["Owner"] = "owner";
  GroupRole2["Requested"] = "requested";
  return GroupRole2;
})(GroupRole || {});
var GroupSubtype = /* @__PURE__ */ ((GroupSubtype2) => {
  GroupSubtype2["Event"] = "event";
  GroupSubtype2["General"] = "general";
  GroupSubtype2["Organization"] = "organization";
  return GroupSubtype2;
})(GroupSubtype || {});
var LabelType = /* @__PURE__ */ ((LabelType2) => {
  LabelType2["Bubble"] = "BUBBLE";
  LabelType2["Plain"] = "PLAIN";
  return LabelType2;
})(LabelType || {});
var LocationType = /* @__PURE__ */ ((LocationType2) => {
  LocationType2["InPerson"] = "in_person";
  LocationType2["Virtual"] = "virtual";
  LocationType2["VirtualAndInPerson"] = "virtual_and_in_person";
  return LocationType2;
})(LocationType || {});
var MembershipStyle = /* @__PURE__ */ ((MembershipStyle2) => {
  MembershipStyle2["Follow"] = "follow";
  MembershipStyle2["Friends"] = "friends";
  MembershipStyle2["Grouped"] = "grouped";
  return MembershipStyle2;
})(MembershipStyle || {});
var MessageThreadType = /* @__PURE__ */ ((MessageThreadType2) => {
  MessageThreadType2["DirectMessage"] = "DIRECT_MESSAGE";
  MessageThreadType2["MultiUserChat"] = "MULTI_USER_CHAT";
  MessageThreadType2["OfficialGroupAdminChat"] = "OFFICIAL_GROUP_ADMIN_CHAT";
  MessageThreadType2["OfficialGroupChat"] = "OFFICIAL_GROUP_CHAT";
  return MessageThreadType2;
})(MessageThreadType || {});
var MobilizationStatus = /* @__PURE__ */ ((MobilizationStatus2) => {
  MobilizationStatus2["Complete"] = "COMPLETE";
  MobilizationStatus2["InProgress"] = "IN_PROGRESS";
  return MobilizationStatus2;
})(MobilizationStatus || {});
var MobilizationType = /* @__PURE__ */ ((MobilizationType2) => {
  MobilizationType2["Pledge"] = "PLEDGE";
  MobilizationType2["Posting"] = "POSTING";
  MobilizationType2["Task"] = "TASK";
  return MobilizationType2;
})(MobilizationType || {});
var NewsfeedFilter = /* @__PURE__ */ ((NewsfeedFilter2) => {
  NewsfeedFilter2["ForYou"] = "FOR_YOU";
  NewsfeedFilter2["Recent"] = "RECENT";
  return NewsfeedFilter2;
})(NewsfeedFilter || {});
var NotificationChannelType = /* @__PURE__ */ ((NotificationChannelType2) => {
  NotificationChannelType2["Email"] = "EMAIL";
  NotificationChannelType2["Pubsub"] = "PUBSUB";
  NotificationChannelType2["Push"] = "PUSH";
  return NotificationChannelType2;
})(NotificationChannelType || {});
var NotificationLevel = /* @__PURE__ */ ((NotificationLevel2) => {
  NotificationLevel2["Mention"] = "MENTION";
  NotificationLevel2["Off"] = "OFF";
  NotificationLevel2["On"] = "ON";
  return NotificationLevel2;
})(NotificationLevel || {});
var NotificationType = /* @__PURE__ */ ((NotificationType2) => {
  NotificationType2["Default"] = "DEFAULT";
  NotificationType2["Mention"] = "MENTION";
  return NotificationType2;
})(NotificationType || {});
var OrderLocation = /* @__PURE__ */ ((OrderLocation2) => {
  OrderLocation2["InPerson"] = "inPerson";
  OrderLocation2["Online"] = "online";
  return OrderLocation2;
})(OrderLocation || {});
var PaymentType = /* @__PURE__ */ ((PaymentType2) => {
  PaymentType2["Cash"] = "cash";
  PaymentType2["CreditCard"] = "creditCard";
  PaymentType2["NotApplicable"] = "notApplicable";
  return PaymentType2;
})(PaymentType || {});
var PositionType = /* @__PURE__ */ ((PositionType2) => {
  PositionType2["Post"] = "post";
  PositionType2["Pre"] = "pre";
  return PositionType2;
})(PositionType || {});
var PostingStatus = /* @__PURE__ */ ((PostingStatus2) => {
  PostingStatus2["Cancelled"] = "CANCELLED";
  PostingStatus2["Closed"] = "CLOSED";
  PostingStatus2["Open"] = "OPEN";
  return PostingStatus2;
})(PostingStatus || {});
var PostingType = /* @__PURE__ */ ((PostingType2) => {
  PostingType2["Job"] = "JOB";
  PostingType2["Volunteer"] = "VOLUNTEER";
  return PostingType2;
})(PostingType || {});
var RrCircleSize = /* @__PURE__ */ ((RrCircleSize2) => {
  RrCircleSize2["Large"] = "large";
  RrCircleSize2["Medium"] = "medium";
  RrCircleSize2["Small"] = "small";
  return RrCircleSize2;
})(RrCircleSize || {});
var RrFont = /* @__PURE__ */ ((RrFont2) => {
  RrFont2["Default"] = "default";
  RrFont2["Lego"] = "lego";
  return RrFont2;
})(RrFont || {});
var RrLineStyle = /* @__PURE__ */ ((RrLineStyle2) => {
  RrLineStyle2["Dashed"] = "dashed";
  RrLineStyle2["None"] = "none";
  RrLineStyle2["Solid"] = "solid";
  return RrLineStyle2;
})(RrLineStyle || {});
var RecommendationManager = /* @__PURE__ */ ((RecommendationManager2) => {
  RecommendationManager2["Group"] = "group";
  RecommendationManager2["Library"] = "library";
  RecommendationManager2["Platform"] = "platform";
  RecommendationManager2["Posting"] = "posting";
  return RecommendationManager2;
})(RecommendationManager || {});
var RecommendationType = /* @__PURE__ */ ((RecommendationType2) => {
  RecommendationType2["ContentCard"] = "CONTENT_CARD";
  RecommendationType2["Group"] = "GROUP";
  RecommendationType2["User"] = "USER";
  return RecommendationType2;
})(RecommendationType || {});
var RecruitType = /* @__PURE__ */ ((RecruitType2) => {
  RecruitType2["Existing"] = "EXISTING";
  RecruitType2["New"] = "NEW";
  RecruitType2["None"] = "NONE";
  RecruitType2["Potential"] = "POTENTIAL";
  return RecruitType2;
})(RecruitType || {});
var ReferenceType = /* @__PURE__ */ ((ReferenceType2) => {
  ReferenceType2["Book"] = "book";
  ReferenceType2["Chapter"] = "chapter";
  ReferenceType2["Group"] = "group";
  ReferenceType2["Library"] = "library";
  ReferenceType2["Prompt"] = "prompt";
  ReferenceType2["Shelf"] = "shelf";
  ReferenceType2["User"] = "user";
  return ReferenceType2;
})(ReferenceType || {});
var ReportingType = /* @__PURE__ */ ((ReportingType2) => {
  ReportingType2["AddNote"] = "ADD_NOTE";
  ReportingType2["Done"] = "DONE";
  ReportingType2["RegisterUser"] = "REGISTER_USER";
  ReportingType2["UploadImage"] = "UPLOAD_IMAGE";
  return ReportingType2;
})(ReportingType || {});
var Resource = /* @__PURE__ */ ((Resource2) => {
  Resource2["Books"] = "Books";
  Resource2["Crayons"] = "Crayons";
  Resource2["Flowers"] = "Flowers";
  Resource2["Hearts"] = "Hearts";
  Resource2["Wood"] = "Wood";
  return Resource2;
})(Resource || {});
var Role = /* @__PURE__ */ ((Role2) => {
  Role2["Admin"] = "admin";
  Role2["Answer"] = "answer";
  Role2["Manage"] = "manage";
  Role2["None"] = "none";
  Role2["Speaker"] = "speaker";
  Role2["View"] = "view";
  Role2["Vote"] = "vote";
  return Role2;
})(Role || {});
var SearchFilterType = /* @__PURE__ */ ((SearchFilterType2) => {
  SearchFilterType2["Chapter"] = "CHAPTER";
  SearchFilterType2["Collection"] = "COLLECTION";
  SearchFilterType2["Comment"] = "COMMENT";
  SearchFilterType2["Group"] = "GROUP";
  SearchFilterType2["Message"] = "MESSAGE";
  SearchFilterType2["Post"] = "POST";
  SearchFilterType2["Prompt"] = "PROMPT";
  SearchFilterType2["User"] = "USER";
  return SearchFilterType2;
})(SearchFilterType || {});
var SearchScopeType = /* @__PURE__ */ ((SearchScopeType2) => {
  SearchScopeType2["Contentcard"] = "CONTENTCARD";
  SearchScopeType2["Group"] = "GROUP";
  return SearchScopeType2;
})(SearchScopeType || {});
var SocialActions = /* @__PURE__ */ ((SocialActions2) => {
  SocialActions2["AcceptConnection"] = "acceptConnection";
  SocialActions2["CancelRequestConnection"] = "cancelRequestConnection";
  SocialActions2["Disconnect"] = "disconnect";
  SocialActions2["Follow"] = "follow";
  SocialActions2["Message"] = "message";
  SocialActions2["RejectConnection"] = "rejectConnection";
  SocialActions2["RequestConnection"] = "requestConnection";
  SocialActions2["Unfollow"] = "unfollow";
  return SocialActions2;
})(SocialActions || {});
var StorageType = /* @__PURE__ */ ((StorageType2) => {
  StorageType2["Ceramic"] = "CERAMIC";
  StorageType2["Mongodb"] = "MONGODB";
  StorageType2["None"] = "NONE";
  return StorageType2;
})(StorageType || {});
var StripeInvoiceStatus = /* @__PURE__ */ ((StripeInvoiceStatus2) => {
  StripeInvoiceStatus2["Draft"] = "draft";
  StripeInvoiceStatus2["Open"] = "open";
  StripeInvoiceStatus2["Paid"] = "paid";
  StripeInvoiceStatus2["Uncollectible"] = "uncollectible";
  StripeInvoiceStatus2["Void"] = "void";
  return StripeInvoiceStatus2;
})(StripeInvoiceStatus || {});
var StripeSubscriptionStatus = /* @__PURE__ */ ((StripeSubscriptionStatus2) => {
  StripeSubscriptionStatus2["Active"] = "active";
  StripeSubscriptionStatus2["Canceled"] = "canceled";
  StripeSubscriptionStatus2["Incomplete"] = "incomplete";
  StripeSubscriptionStatus2["IncompleteExpired"] = "incomplete_expired";
  StripeSubscriptionStatus2["PastDue"] = "past_due";
  StripeSubscriptionStatus2["Trialing"] = "trialing";
  StripeSubscriptionStatus2["Unpaid"] = "unpaid";
  return StripeSubscriptionStatus2;
})(StripeSubscriptionStatus || {});
var SubscriptionLevel = /* @__PURE__ */ ((SubscriptionLevel2) => {
  SubscriptionLevel2["WlBasicMonthly"] = "WL_BASIC_MONTHLY";
  SubscriptionLevel2["WlBasicYearly"] = "WL_BASIC_YEARLY";
  SubscriptionLevel2["WlProMonthly"] = "WL_PRO_MONTHLY";
  SubscriptionLevel2["WlProYearly"] = "WL_PRO_YEARLY";
  SubscriptionLevel2["WlUnlimitedMonthly"] = "WL_UNLIMITED_MONTHLY";
  SubscriptionLevel2["WlUnlimitedYearly"] = "WL_UNLIMITED_YEARLY";
  return SubscriptionLevel2;
})(SubscriptionLevel || {});
var SurveyQuestionSubType = /* @__PURE__ */ ((SurveyQuestionSubType2) => {
  SurveyQuestionSubType2["Emotional"] = "Emotional";
  SurveyQuestionSubType2["Image"] = "Image";
  SurveyQuestionSubType2["LongAnswer"] = "LongAnswer";
  SurveyQuestionSubType2["Numeric"] = "Numeric";
  SurveyQuestionSubType2["ShortAnswer"] = "ShortAnswer";
  SurveyQuestionSubType2["Text"] = "Text";
  SurveyQuestionSubType2["ThreeBoxAnswer"] = "ThreeBoxAnswer";
  return SurveyQuestionSubType2;
})(SurveyQuestionSubType || {});
var SurveyQuestionType = /* @__PURE__ */ ((SurveyQuestionType2) => {
  SurveyQuestionType2["Image"] = "Image";
  SurveyQuestionType2["ImageOrText"] = "ImageOrText";
  SurveyQuestionType2["MultipleChoice"] = "MultipleChoice";
  SurveyQuestionType2["Slider"] = "Slider";
  SurveyQuestionType2["Text"] = "Text";
  return SurveyQuestionType2;
})(SurveyQuestionType || {});
var TicketCheckInStatusEnum = /* @__PURE__ */ ((TicketCheckInStatusEnum2) => {
  TicketCheckInStatusEnum2["CheckedIn"] = "checkedIn";
  TicketCheckInStatusEnum2["CheckingIn"] = "checkingIn";
  TicketCheckInStatusEnum2["Invalid"] = "invalid";
  return TicketCheckInStatusEnum2;
})(TicketCheckInStatusEnum || {});
var UpdateAction = /* @__PURE__ */ ((UpdateAction2) => {
  UpdateAction2["Add"] = "ADD";
  UpdateAction2["Delete"] = "DELETE";
  UpdateAction2["Update"] = "UPDATE";
  return UpdateAction2;
})(UpdateAction || {});
var UserType = /* @__PURE__ */ ((UserType2) => {
  UserType2["Child"] = "child";
  UserType2["Ephemeral"] = "ephemeral";
  UserType2["Group"] = "group";
  UserType2["Guardian"] = "guardian";
  UserType2["Normal"] = "normal";
  UserType2["Organization"] = "organization";
  UserType2["System"] = "system";
  return UserType2;
})(UserType || {});
var VerifierType = /* @__PURE__ */ ((VerifierType2) => {
  VerifierType2["Recruit"] = "RECRUIT";
  return VerifierType2;
})(VerifierType || {});
var ViewPermission = /* @__PURE__ */ ((ViewPermission2) => {
  ViewPermission2["Private"] = "private";
  ViewPermission2["Public"] = "public";
  ViewPermission2["Secret"] = "secret";
  ViewPermission2["Unset"] = "unset";
  return ViewPermission2;
})(ViewPermission || {});
var ViewType = /* @__PURE__ */ ((ViewType2) => {
  ViewType2["Fullview"] = "fullview";
  ViewType2["Listview"] = "listview";
  return ViewType2;
})(ViewType || {});
var Violation = /* @__PURE__ */ ((Violation2) => {
  Violation2["GroupPolicy"] = "GROUP_POLICY";
  Violation2["WelibraryPolicy"] = "WELIBRARY_POLICY";
  return Violation2;
})(Violation || {});
var LogUserEventDocument = gql7`
    mutation LogUserEvent($viewed: [String]!, $notifyOf: [String]!, $welcomeViewed: Boolean) {
  logUserEvent(
    input: {viewed: $viewed, notifyOf: $notifyOf, welcomeViewed: $welcomeViewed}
  ) {
    ...UserFragment
    profile {
      userEvent {
        viewed
        notifyOf
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useLogUserEventMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(LogUserEventDocument, options);
}
__name(useLogUserEventMutation, "useLogUserEventMutation");
var FollowUserDocument = gql7`
    mutation FollowUser($_id: ID!, $unFollow: Boolean) {
  followUser(_id: $_id, unFollow: $unFollow) {
    __typename
    _id
    socialActionsAllowed
  }
}
    `;
function useFollowUserMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(FollowUserDocument, options);
}
__name(useFollowUserMutation, "useFollowUserMutation");
var FlagUserDocument = gql7`
    mutation FlagUser($_id: ID!, $reason: String, $block: Boolean) {
  flagUser(_id: $_id, reason: $reason, block: $block) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
function useFlagUserMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(FlagUserDocument, options);
}
__name(useFlagUserMutation, "useFlagUserMutation");
var UpdateConnectionDocument = gql7`
    mutation UpdateConnection($userId: ID!, $action: ConnectionAction!) {
  updateConnection(input: {userId: $userId, action: $action}) {
    __typename
    _id
    socialActionsAllowed
  }
}
    `;
function useUpdateConnectionMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateConnectionDocument, options);
}
__name(useUpdateConnectionMutation, "useUpdateConnectionMutation");
var SendSmsVerificationCodeDocument = gql7`
    mutation SendSmsVerificationCode($input: SendSMSInput!) {
  sendSmsVerificationCode(input: $input) {
    success
    error
  }
}
    `;
function useSendSmsVerificationCodeMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SendSmsVerificationCodeDocument, options);
}
__name(useSendSmsVerificationCodeMutation, "useSendSmsVerificationCodeMutation");
var VerifySmsCodeDocument = gql7`
    mutation VerifySmsCode($input: SmsVerificationInput!) {
  verifySmsCode(input: $input) {
    userId
    options {
      profile {
        phone
      }
    }
  }
}
    `;
function useVerifySmsCodeMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(VerifySmsCodeDocument, options);
}
__name(useVerifySmsCodeMutation, "useVerifySmsCodeMutation");
var SendEmailVerificationCodeDocument = gql7`
    mutation SendEmailVerificationCode($input: SendEmailVerificationCodeInput!) {
  sendEmailVerificationCode(input: $input) {
    success
    error
  }
}
    `;
function useSendEmailVerificationCodeMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SendEmailVerificationCodeDocument, options);
}
__name(useSendEmailVerificationCodeMutation, "useSendEmailVerificationCodeMutation");
var VerifyEmailVerificationCodeDocument = gql7`
    mutation VerifyEmailVerificationCode($input: VerifyEmailVerificationCodeInput!) {
  verifyEmailVerificationCode(input: $input) {
    success
    error
    claimToken
  }
}
    `;
function useVerifyEmailVerificationCodeMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(VerifyEmailVerificationCodeDocument, options);
}
__name(useVerifyEmailVerificationCodeMutation, "useVerifyEmailVerificationCodeMutation");
var AddPhoneToUserDocument = gql7`
    mutation AddPhoneToUser($input: SmsVerificationInput!) {
  addPhoneToUser(input: $input) {
    userId
    options {
      profile {
        phone
      }
    }
  }
}
    `;
function useAddPhoneToUserMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AddPhoneToUserDocument, options);
}
__name(useAddPhoneToUserMutation, "useAddPhoneToUserMutation");
var CreateUserDocument = gql7`
    mutation CreateUser($input: NewUserInput!, $claimInput: ClaimUserInput, $claimTicketInput: ClaimTicketInput, $paymentMethodInput: PaymentMethodInput, $skipEmail: Boolean, $skipVerificationEmail: Boolean, $emailIsVerified: Boolean) {
  createUser(
    input: $input
    claimInput: $claimInput
    claimTicketInput: $claimTicketInput
    paymentMethodInput: $paymentMethodInput
    skipEmail: $skipEmail
    skipVerificationEmail: $skipVerificationEmail
    emailIsVerified: $emailIsVerified
  ) {
    _id
    email
    picture
    bio
    username
    password
    usedSMS
    phone
  }
}
    `;
function useCreateUserMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateUserDocument, options);
}
__name(useCreateUserMutation, "useCreateUserMutation");
var CreateGuestUserDocument = gql7`
    mutation CreateGuestUser($input: NewUserInput!) {
  createGuestUser(input: $input) {
    _id
    email
    picture
    bio
    username
  }
}
    `;
function useCreateGuestUserMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateGuestUserDocument, options);
}
__name(useCreateGuestUserMutation, "useCreateGuestUserMutation");
var CreateGroupDocument = gql7`
    mutation CreateGroup($input: NewGroupInput!, $idempotencyKey: String) {
  createGroup(input: $input, idempotencyKey: $idempotencyKey) {
    ...GroupFragment
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
    paymentSettings {
      stripeAccount {
        id
        email
        country
        charges_enabled
        payouts_enabled
        details_submitted
        external_accounts {
          object
          data {
            country
            currency
          }
          has_more
          url
        }
      }
      isGroupMembershipPaid
    }
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    doesSellMembership
  }
}
    ${GroupFragmentFragmentDoc}`;
function useCreateGroupMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateGroupDocument, options);
}
__name(useCreateGroupMutation, "useCreateGroupMutation");
var DeleteGroupDocument = gql7`
    mutation DeleteGroup($_id: String!, $cancelReason: String, $messageToAttendees: String, $contactUserId: String) {
  deleteGroup(
    _id: $_id
    cancelReason: $cancelReason
    messageToAttendees: $messageToAttendees
    contactUserId: $contactUserId
  )
}
    `;
function useDeleteGroupMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(DeleteGroupDocument, options);
}
__name(useDeleteGroupMutation, "useDeleteGroupMutation");
var UnpublishEventDocument = gql7`
    mutation UnpublishEvent($_id: String!, $cancelReason: String!, $messageToAttendees: String, $contactUserId: String) {
  unpublishEvent(
    _id: $_id
    cancelReason: $cancelReason
    messageToAttendees: $messageToAttendees
    contactUserId: $contactUserId
  ) {
    ...GroupFragment
  }
}
    ${GroupFragmentFragmentDoc}`;
function useUnpublishEventMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UnpublishEventDocument, options);
}
__name(useUnpublishEventMutation, "useUnpublishEventMutation");
var PublishEventDocument = gql7`
    mutation PublishEvent($_id: String!) {
  publishEvent(_id: $_id) {
    ...GroupFragment
  }
}
    ${GroupFragmentFragmentDoc}`;
function usePublishEventMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(PublishEventDocument, options);
}
__name(usePublishEventMutation, "usePublishEventMutation");
var UpdateUserDocument = gql7`
    mutation UpdateUser($_id: ID!, $full_name: String, $short_bio: String, $full_bio: String, $picture: String, $country: String, $location: LocationInput, $website: String, $email: String, $nickname: String, $birthday: String, $coverPhoto: String, $facebookProfile: String, $instagramProfile: String, $twitterProfile: String, $linkedInProfile: String, $customFields: [UpdateCustomFieldsInput], $protectionLevel: ViewPermission, $language: String, $organization: ID, $newsfeedFilter: NewsfeedFilter, $simple: SimpleProfileInput, $avatar: AvatarInput, $did: String, $phone: String) {
  updateUser(
    input: {_id: $_id, full_name: $full_name, short_bio: $short_bio, full_bio: $full_bio, picture: $picture, country: $country, location: $location, website: $website, email: $email, nickname: $nickname, birthday: $birthday, coverPhoto: $coverPhoto, facebookProfile: $facebookProfile, instagramProfile: $instagramProfile, twitterProfile: $twitterProfile, linkedInProfile: $linkedInProfile, customFields: $customFields, protectionLevel: $protectionLevel, language: $language, organization: $organization, newsfeedFilter: $newsfeedFilter, simple: $simple, avatar: $avatar, did: $did, phone: $phone}
  ) {
    ...UserFragment
    profile {
      birthday
      facebookProfile
      instagramProfile
      twitterProfile
      linkedInProfile
      coverPhoto
      newsfeedFilter
      did
    }
    ...CustomFieldsFragment
    roles {
      roles
    }
  }
}
    ${UserFragmentFragmentDoc}
${CustomFieldsFragmentFragmentDoc}`;
function useUpdateUserMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateUserDocument, options);
}
__name(useUpdateUserMutation, "useUpdateUserMutation");
var UpdateUserRoleForUrlDocument = gql7`
    mutation UpdateUserRoleForUrl($url: String!, $userId: ID!, $role: Role!) {
  updateUserRoleForUrl(url: $url, userId: $userId, role: $role) {
    ...UserFragment
    roles {
      customRolesOf(url: $url) {
        role
        url
      }
      roleOf(url: $url) {
        role
        url
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useUpdateUserRoleForUrlMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateUserRoleForUrlDocument, options);
}
__name(useUpdateUserRoleForUrlMutation, "useUpdateUserRoleForUrlMutation");
var UpdateCustomUserRoleForUrlDocument = gql7`
    mutation UpdateCustomUserRoleForUrl($url: String!, $userId: ID!, $role: String!, $remove: Boolean) {
  updateCustomUserRoleForUrl(
    url: $url
    userId: $userId
    role: $role
    remove: $remove
  ) {
    ...UserFragment
    roles {
      customRolesOf(url: $url) {
        role
        url
      }
      roleOf(url: $url) {
        role
        url
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useUpdateCustomUserRoleForUrlMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateCustomUserRoleForUrlDocument, options);
}
__name(useUpdateCustomUserRoleForUrlMutation, "useUpdateCustomUserRoleForUrlMutation");
var AddUserToGroupDocument = gql7`
    mutation AddUserToGroup($groupId: String!, $subgroupId: String, $cardId: String, $userId: String!, $remove: Boolean, $admin: Boolean, $setAsActiveGroup: Boolean, $host: String) {
  addUserToGroup(
    groupId: $groupId
    subgroupId: $subgroupId
    cardId: $cardId
    userId: $userId
    remove: $remove
    admin: $admin
    setAsActiveGroup: $setAsActiveGroup
    host: $host
  ) {
    user {
      __typename
      ...UserFragment
      roleForGroup(groupId: $groupId)
    }
    group {
      __typename
      ...GroupFragment
    }
  }
}
    ${UserFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
function useAddUserToGroupMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AddUserToGroupDocument, options);
}
__name(useAddUserToGroupMutation, "useAddUserToGroupMutation");
var RsvpAndRegisterToEventDocument = gql7`
    mutation rsvpAndRegisterToEvent($groupId: String!, $attendeeRegistrationInput: AttendeeRegistrationInput!) {
  rsvpAndRegisterToEvent(
    groupId: $groupId
    attendeeRegistrationInput: $attendeeRegistrationInput
  ) {
    user {
      __typename
      ...UserFragment
    }
    group {
      __typename
      ...GroupFragment
    }
  }
}
    ${UserFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
function useRsvpAndRegisterToEventMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(RsvpAndRegisterToEventDocument, options);
}
__name(useRsvpAndRegisterToEventMutation, "useRsvpAndRegisterToEventMutation");
var ExpressInterestInGroupDocument = gql7`
    mutation ExpressInterestInGroup($groupId: String!, $userId: String!) {
  expressInterestInGroup(groupId: $groupId, userId: $userId) {
    user {
      __typename
      ...UserFragment
    }
    group {
      __typename
      ...GroupFragment
    }
  }
}
    ${UserFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
function useExpressInterestInGroupMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(ExpressInterestInGroupDocument, options);
}
__name(useExpressInterestInGroupMutation, "useExpressInterestInGroupMutation");
var RespondNotGoingToGroupEventDocument = gql7`
    mutation RespondNotGoingToGroupEvent($groupId: String!, $userId: String!) {
  respondNotGoingToGroupEvent(groupId: $groupId, userId: $userId) {
    user {
      __typename
      ...UserFragment
    }
    group {
      __typename
      ...GroupFragment
    }
  }
}
    ${UserFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
function useRespondNotGoingToGroupEventMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(RespondNotGoingToGroupEventDocument, options);
}
__name(useRespondNotGoingToGroupEventMutation, "useRespondNotGoingToGroupEventMutation");
var AddGroupToUserDocument = gql7`
    mutation AddGroupToUser($shortCode: String!, $customFields: [UpdateCustomFieldsInput]) {
  addGroupToUser(shortCode: $shortCode, customFields: $customFields) {
    ...UserFragment
    membership {
      active {
        __typename
        title
        url
      }
      activeLibrary {
        __typename
        title
        url
      }
      all {
        __typename
        title
        url
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useAddGroupToUserMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AddGroupToUserDocument, options);
}
__name(useAddGroupToUserMutation, "useAddGroupToUserMutation");
var UpdateUserGroupSurveyDismissalTimeDocument = gql7`
    mutation UpdateUserGroupSurveyDismissalTime($groupId: String!) {
  updateUserGroupSurveyDismissalTime(groupId: $groupId) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
function useUpdateUserGroupSurveyDismissalTimeMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateUserGroupSurveyDismissalTimeDocument, options);
}
__name(useUpdateUserGroupSurveyDismissalTimeMutation, "useUpdateUserGroupSurveyDismissalTimeMutation");
var CancelEmailInviteToGroupDocument = gql7`
    mutation CancelEmailInviteToGroup($groupId: String!, $userEmail: String!) {
  cancelEmailInviteToGroup(groupId: $groupId, userEmail: $userEmail)
}
    `;
function useCancelEmailInviteToGroupMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CancelEmailInviteToGroupDocument, options);
}
__name(useCancelEmailInviteToGroupMutation, "useCancelEmailInviteToGroupMutation");
var SwitchActiveGroupDocument = gql7`
    mutation SwitchActiveGroup($groupId: String) {
  switchActiveGroup(groupId: $groupId) {
    __typename
    _id
  }
}
    `;
function useSwitchActiveGroupMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SwitchActiveGroupDocument, options);
}
__name(useSwitchActiveGroupMutation, "useSwitchActiveGroupMutation");
var PledgeToTasksDocument = gql7`
    mutation PledgeToTasks($taskUrls: [String]!) {
  pledgeToTasks(taskUrls: $taskUrls) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
function usePledgeToTasksMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(PledgeToTasksDocument, options);
}
__name(usePledgeToTasksMutation, "usePledgeToTasksMutation");
var UpdateGroupDocument = gql7`
    mutation UpdateGroup($input: UpdateGroupInput!, $idempotencyKey: String) {
  updateGroup(input: $input, idempotencyKey: $idempotencyKey) {
    ...GroupFragment
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
    paymentSettings {
      stripeAccount {
        id
        email
        country
        charges_enabled
        payouts_enabled
        details_submitted
        external_accounts {
          object
          data {
            country
            currency
          }
          has_more
          url
        }
      }
      isGroupMembershipPaid
    }
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    doesSellMembership
  }
}
    ${GroupFragmentFragmentDoc}`;
function useUpdateGroupMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateGroupDocument, options);
}
__name(useUpdateGroupMutation, "useUpdateGroupMutation");
var SetIsMembershipPaidDocument = gql7`
    mutation SetIsMembershipPaid($groupId: ID!, $paymentSettings: PaymentSettingsInput) {
  setIsMembershipPaid(groupId: $groupId, paymentSettings: $paymentSettings) {
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
    paymentSettings {
      stripeAccount {
        id
        email
        country
        charges_enabled
        payouts_enabled
        details_submitted
        external_accounts {
          object
          data {
            country
            currency
          }
          has_more
          url
        }
      }
      isGroupMembershipPaid
    }
    doesSellMembership
  }
}
    `;
function useSetIsMembershipPaidMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SetIsMembershipPaidDocument, options);
}
__name(useSetIsMembershipPaidMutation, "useSetIsMembershipPaidMutation");
var SubscribeGroupToContentDocument = gql7`
    mutation SubscribeGroupToContent($url: String, $urls: [String], $groupId: String) {
  subscribeGroupToContent(url: $url, urls: $urls, groupId: $groupId) {
    __typename
    subscribedTo {
      ...ContentCardFragment
    }
    group {
      ...GroupFragment
    }
  }
}
    ${ContentCardFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
function useSubscribeGroupToContentMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SubscribeGroupToContentDocument, options);
}
__name(useSubscribeGroupToContentMutation, "useSubscribeGroupToContentMutation");
var SetGroupDashboardDocument = gql7`
    mutation SetGroupDashboard($url: String, $role: String, $groupId: String) {
  setGroupDashboard(url: $url, role: $role, groupId: $groupId) {
    ...GroupFragment
  }
}
    ${GroupFragmentFragmentDoc}`;
function useSetGroupDashboardMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SetGroupDashboardDocument, options);
}
__name(useSetGroupDashboardMutation, "useSetGroupDashboardMutation");
var UpdateHasUnreadDashboardDocument = gql7`
    mutation UpdateHasUnreadDashboard($groupId: String!, $hasUnreadDashboard: Boolean!, $userId: ID) {
  updateHasUnreadDashboard(
    groupId: $groupId
    hasUnreadDashboard: $hasUnreadDashboard
    userId: $userId
  ) {
    ...GroupFragment
  }
}
    ${GroupFragmentFragmentDoc}`;
function useUpdateHasUnreadDashboardMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateHasUnreadDashboardDocument, options);
}
__name(useUpdateHasUnreadDashboardMutation, "useUpdateHasUnreadDashboardMutation");
var BulkInviteUsersByEmailDocument = gql7`
    mutation BulkInviteUsersByEmail($input: BulkUserInviteInput!) {
  bulkInviteUsersByEmail(input: $input) {
    existingInvitees {
      _id
      ...UserFragment
    }
    usersToInviteByEmail {
      email
    }
  }
}
    ${UserFragmentFragmentDoc}`;
function useBulkInviteUsersByEmailMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(BulkInviteUsersByEmailDocument, options);
}
__name(useBulkInviteUsersByEmailMutation, "useBulkInviteUsersByEmailMutation");
var BulkGenerateUsersFromCsvDocument = gql7`
    mutation BulkGenerateUsersFromCsv($csvFileUrl: String!) {
  bulkGenerateUsersFromCsv(csvFileUrl: $csvFileUrl)
}
    `;
function useBulkGenerateUsersFromCsvMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(BulkGenerateUsersFromCsvDocument, options);
}
__name(useBulkGenerateUsersFromCsvMutation, "useBulkGenerateUsersFromCsvMutation");
var SendPasswordResetForEmailDocument = gql7`
    mutation SendPasswordResetForEmail($email: String!, $host: String) {
  sendPasswordResetForEmail(email: $email, host: $host)
}
    `;
function useSendPasswordResetForEmailMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SendPasswordResetForEmailDocument, options);
}
__name(useSendPasswordResetForEmailMutation, "useSendPasswordResetForEmailMutation");
var SendClaimAccountEmailDocument = gql7`
    mutation SendClaimAccountEmail($email: String!, $host: String) {
  sendClaimAccountEmail(email: $email, host: $host)
}
    `;
function useSendClaimAccountEmailMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SendClaimAccountEmailDocument, options);
}
__name(useSendClaimAccountEmailMutation, "useSendClaimAccountEmailMutation");
var ReactivateAccountDocument = gql7`
    mutation ReactivateAccount($userId: ID) {
  reactivateAccount(userId: $userId)
}
    `;
function useReactivateAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(ReactivateAccountDocument, options);
}
__name(useReactivateAccountMutation, "useReactivateAccountMutation");
var DeactivateAccountDocument = gql7`
    mutation DeactivateAccount($userId: ID) {
  deactivateAccount(userId: $userId)
}
    `;
function useDeactivateAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(DeactivateAccountDocument, options);
}
__name(useDeactivateAccountMutation, "useDeactivateAccountMutation");
var DeleteUserAccountDocument = gql7`
    mutation DeleteUserAccount($userId: ID) {
  deleteUserAccount(userId: $userId)
}
    `;
function useDeleteUserAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(DeleteUserAccountDocument, options);
}
__name(useDeleteUserAccountMutation, "useDeleteUserAccountMutation");
var ResetPasswordWithTokenDocument = gql7`
    mutation ResetPasswordWithToken($token: String!, $password: String!, $passwordConfirm: String!) {
  resetPasswordWithToken(
    token: $token
    password: $password
    passwordConfirm: $passwordConfirm
  )
}
    `;
function useResetPasswordWithTokenMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(ResetPasswordWithTokenDocument, options);
}
__name(useResetPasswordWithTokenMutation, "useResetPasswordWithTokenMutation");
var SendVerifyEmailDocument = gql7`
    mutation SendVerifyEmail($email: String!, $host: String) {
  sendVerifyEmail(email: $email, host: $host)
}
    `;
function useSendVerifyEmailMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SendVerifyEmailDocument, options);
}
__name(useSendVerifyEmailMutation, "useSendVerifyEmailMutation");
var VerifyEmailDocument = gql7`
    mutation VerifyEmail($token: String!) {
  verifyEmail(token: $token)
}
    `;
function useVerifyEmailMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(VerifyEmailDocument, options);
}
__name(useVerifyEmailMutation, "useVerifyEmailMutation");
var SendCreateAccountReminderEmailsDocument = gql7`
    mutation SendCreateAccountReminderEmails($emails: [String!]!, $groupId: ID!, $host: String) {
  sendCreateAccountReminderEmails(emails: $emails, groupId: $groupId, host: $host)
}
    `;
function useSendCreateAccountReminderEmailsMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SendCreateAccountReminderEmailsDocument, options);
}
__name(useSendCreateAccountReminderEmailsMutation, "useSendCreateAccountReminderEmailsMutation");
var UpdateUserSideMenuDocument = gql7`
    mutation UpdateUserSideMenu($input: SideMenuInput!) {
  updateUserSideMenu(input: $input) {
    _id
    sideMenu {
      quickLinks {
        tags
        aliases {
          ... on UserAliasHandle {
            url
            title
            type
            user {
              _id
              profile {
                picture
                full_name
                short_bio
              }
            }
          }
          ... on GroupAliasHandle {
            url
            title
            type
            group {
              _id
              profile {
                full_name
                picture
                short_bio
              }
            }
          }
          ... on MessageThreadAliasHandle {
            url
            title
            color
            thumb
            icon
            type
            thread {
              ...MessageThreadFragment
            }
          }
          ... on AliasItem {
            url
            title
            type
            item {
              _id
              title
              description
              reference {
                href
              }
              media {
                thumb
              }
            }
          }
        }
      }
    }
  }
}
    ${MessageThreadFragmentFragmentDoc}`;
function useUpdateUserSideMenuMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateUserSideMenuDocument, options);
}
__name(useUpdateUserSideMenuMutation, "useUpdateUserSideMenuMutation");
var CreateStripeConnectedAccountDocument = gql7`
    mutation CreateStripeConnectedAccount($input: GroupConnectedAccountInput!, $host: String, $reset: Boolean) {
  onboardGroupConnectedAccount(input: $input, host: $host, reset: $reset) {
    object
    created
    expires_at
    url
  }
}
    `;
function useCreateStripeConnectedAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateStripeConnectedAccountDocument, options);
}
__name(useCreateStripeConnectedAccountMutation, "useCreateStripeConnectedAccountMutation");
var GenerateConnectedAccountLoginLinkDocument = gql7`
    mutation GenerateConnectedAccountLoginLink($groupId: String!) {
  generateConnectedAccountLoginLink(groupId: $groupId) {
    object
    created
    url
  }
}
    `;
function useGenerateConnectedAccountLoginLinkMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(GenerateConnectedAccountLoginLinkDocument, options);
}
__name(useGenerateConnectedAccountLoginLinkMutation, "useGenerateConnectedAccountLoginLinkMutation");
var AddCardToCustomerAccountDocument = gql7`
    mutation addCardToCustomerAccount($token: String!, $setAsDefault: Boolean) {
  createPaymentCardForLoggedInUser(token: $token, setAsDefault: $setAsDefault) {
    __typename
    _id
    type
    paymentSettings {
      stripeCustomer {
        id
        name
        email
        phone
      }
    }
  }
}
    `;
function useAddCardToCustomerAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(AddCardToCustomerAccountDocument, options);
}
__name(useAddCardToCustomerAccountMutation, "useAddCardToCustomerAccountMutation");
var DeleteCustomerCardSourceDocument = gql7`
    mutation DeleteCustomerCardSource($cardId: String!) {
  deleteCustomerCardSource(cardId: $cardId) {
    __typename
    id
    object
    deleted
  }
}
    `;
function useDeleteCustomerCardSourceMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(DeleteCustomerCardSourceDocument, options);
}
__name(useDeleteCustomerCardSourceMutation, "useDeleteCustomerCardSourceMutation");
var SetCustomerCardSourceAsDefaultDocument = gql7`
    mutation SetCustomerCardSourceAsDefault($cardId: String!) {
  setCustomerCardSourceAsDefault(cardId: $cardId) {
    __typename
    _id
    type
    paymentSettings {
      stripeCustomer {
        id
        name
        email
        phone
        default_source
        invoice_settings {
          default_payment_method
        }
      }
    }
  }
}
    `;
function useSetCustomerCardSourceAsDefaultMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SetCustomerCardSourceAsDefaultDocument, options);
}
__name(useSetCustomerCardSourceAsDefaultMutation, "useSetCustomerCardSourceAsDefaultMutation");
var CreateSetupIntentForLoggedInUserDocument = gql7`
    mutation CreateSetupIntentForLoggedInUser {
  createSetupIntentForLoggedInUser {
    id
    object
    client_secret
    customer
    description
    next_action
    payment_method
    status
    livemode
    usage
  }
}
    `;
function useCreateSetupIntentForLoggedInUserMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateSetupIntentForLoggedInUserDocument, options);
}
__name(useCreateSetupIntentForLoggedInUserMutation, "useCreateSetupIntentForLoggedInUserMutation");
var CreateStripeCustomerAccountDocument = gql7`
    mutation CreateStripeCustomerAccount($input: CustomerAccountInput!) {
  createCustomerAccountForLoggedInUser(input: $input) {
    __typename
    _id
    type
    paymentSettings {
      stripeCustomer {
        id
        name
        email
        phone
      }
    }
  }
}
    `;
function useCreateStripeCustomerAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateStripeCustomerAccountDocument, options);
}
__name(useCreateStripeCustomerAccountMutation, "useCreateStripeCustomerAccountMutation");
var UpdateStripeCustomerAccountDocument = gql7`
    mutation UpdateStripeCustomerAccount($input: CustomerAccountInput!) {
  updateCustomerAccountForLoggedInUser(input: $input) {
    __typename
    _id
    type
    paymentSettings {
      stripeCustomer {
        id
        name
        email
        phone
      }
    }
  }
}
    `;
function useUpdateStripeCustomerAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateStripeCustomerAccountDocument, options);
}
__name(useUpdateStripeCustomerAccountMutation, "useUpdateStripeCustomerAccountMutation");
var CreateProductDocument = gql7`
    mutation CreateProduct($input: ProductInput!) {
  createProduct(input: $input) {
    name
    description
    price
    currency
    type
    fulfillment {
      groupId
      role
    }
  }
}
    `;
function useCreateProductMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateProductDocument, options);
}
__name(useCreateProductMutation, "useCreateProductMutation");
var UpdateProductsDocument = gql7`
    mutation updateProducts($inputs: [UpdateProductInput]!, $upsert: Boolean, $groupId: ID) {
  updateProducts(inputs: $inputs, upsert: $upsert, groupId: $groupId) {
    name
    description
    price
    currency
    type
    fulfillment {
      groupId
      role
    }
  }
}
    `;
function useUpdateProductsMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateProductsDocument, options);
}
__name(useUpdateProductsMutation, "useUpdateProductsMutation");
var UpdateSubscriptionProductDocument = gql7`
    mutation UpdateSubscriptionProduct($input: UpdateProductInput) {
  updateSubscriptionProduct(input: $input) {
    name
    description
    price
    currency
    type
    fulfillment {
      groupId
      role
    }
  }
}
    `;
function useUpdateSubscriptionProductMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateSubscriptionProductDocument, options);
}
__name(useUpdateSubscriptionProductMutation, "useUpdateSubscriptionProductMutation");
var DeleteProductDocument = gql7`
    mutation deleteProduct($productId: ID!, $permanent: Boolean) {
  deleteProduct(productId: $productId, permanent: $permanent)
}
    `;
function useDeleteProductMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(DeleteProductDocument, options);
}
__name(useDeleteProductMutation, "useDeleteProductMutation");
var CreateCustomerOrderDocument = gql7`
    mutation CreateCustomerOrder($input: CustomerOrderInput!, $allowDuplicatePurchases: Boolean, $subgroupId: String, $isPurchaseForOther: Boolean, $paymentType: String, $orderLocation: String, $processedByUserId: String, $ticketsForExistingUserId: String) {
  createOrder(
    input: $input
    allowDuplicatePurchases: $allowDuplicatePurchases
    subgroupId: $subgroupId
    isPurchaseForOther: $isPurchaseForOther
    paymentType: $paymentType
    orderLocation: $orderLocation
    processedByUserId: $processedByUserId
    ticketsForExistingUserId: $ticketsForExistingUserId
  ) {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
    }
    purchaseRequests {
      product {
        _id
        name
        description
        price
      }
      quantity
      couponCode
    }
    products {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
    productInstances {
      _id
      isCheckedIn
      transferToken
      product {
        _id
        name
        description
        currency
        price
      }
      owner {
        _id
        profile {
          full_name
          picture
          email
          phone
        }
      }
      purchaser {
        _id
        profile {
          full_name
          picture
          email
          phone
        }
      }
    }
    user {
      _id
      profile {
        full_name
        picture
        email
        phone
      }
    }
    processedByUser {
      _id
      profile {
        full_name
      }
    }
    paymentType
    orderLocation
    created
    updated
    status
  }
}
    `;
function useCreateCustomerOrderMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateCustomerOrderDocument, options);
}
__name(useCreateCustomerOrderMutation, "useCreateCustomerOrderMutation");
var FulfillCashOrderDocument = gql7`
    mutation FulfillCashOrder($orderId: String!) {
  fulfillCashOrder(orderId: $orderId) {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
    }
    purchaseRequests {
      product {
        _id
        name
        description
        currency
        price
        coupons {
          name
          code
          percentage
          type
          expiration
        }
      }
      quantity
      couponCode
    }
    products {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
    productInstances {
      _id
      isCheckedIn
      transferToken
      product {
        _id
        name
        description
        currency
        price
      }
      owner {
        _id
        profile {
          full_name
          picture
          email
          phone
        }
      }
      purchaser {
        _id
        profile {
          full_name
          picture
          email
          phone
        }
      }
      owner {
        _id
        profile {
          full_name
          picture
          email
          phone
        }
      }
      purchaser {
        _id
        profile {
          full_name
          picture
          email
          phone
        }
      }
    }
    user {
      _id
      profile {
        full_name
        picture
        email
        phone
      }
    }
    user {
      _id
      profile {
        full_name
        picture
        email
        phone
      }
    }
    processedByUser {
      _id
      profile {
        full_name
      }
    }
    paymentType
    orderLocation
    created
    updated
    status
  }
}
    `;
function useFulfillCashOrderMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(FulfillCashOrderDocument, options);
}
__name(useFulfillCashOrderMutation, "useFulfillCashOrderMutation");
var CreateOrderRefundDocument = gql7`
    mutation createOrderRefund($paymentIntentId: String!) {
  createOrderRefund(paymentIntentId: $paymentIntentId) {
    id
    object
    amount
    charge
    created
    currency
    payment_intent
    reason
    status
  }
}
    `;
function useCreateOrderRefundMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateOrderRefundDocument, options);
}
__name(useCreateOrderRefundMutation, "useCreateOrderRefundMutation");
var RefundAndCancelGroupSubscriptionDocument = gql7`
    mutation RefundAndCancelGroupSubscription($userId: String!, $groupId: String!) {
  refundAndCancelGroupSubscription(userId: $userId, groupId: $groupId) {
    id
    object
    amount
    charge
    created
    currency
    payment_intent
    reason
    status
  }
}
    `;
function useRefundAndCancelGroupSubscriptionMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(RefundAndCancelGroupSubscriptionDocument, options);
}
__name(useRefundAndCancelGroupSubscriptionMutation, "useRefundAndCancelGroupSubscriptionMutation");
var DeleteSubscriptionProductAndCancelSubscriptionsInBulkDocument = gql7`
    mutation DeleteSubscriptionProductAndCancelSubscriptionsInBulk($groupId: String!, $productId: String!) {
  deleteSubscriptionProductAndCancelSubscriptionsInBulk(
    groupId: $groupId
    productId: $productId
  ) {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
      charges {
        object
        data {
          amount
          amount_refunded
          refunded
          refunds {
            object
            data {
              created
              amount
              status
              failure_reason
              reason
            }
          }
        }
        url
        has_more
      }
    }
    products {
      _id
      name
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          currentUserIsAdmin
          currentUserIsDirectAdmin
          profile {
            full_name
            picture
            coverPhoto
          }
          timeAndPlaceSettings {
            date
            startTime
            endTime
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    purchaseRequests {
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserIsDirectAdmin
            profile {
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      quantity
      couponCode
    }
    subscription {
      id
      currency
      cancel_at_period_end
      current_period_end
      current_period_start
      status
      created
      days_until_due
      livemode
      next_pending_invoice_item_invoice
    }
    subscriptionInvoices {
      cursor
      hasMore
      results {
        id
        auto_advance
        collection_method
        currency
        created
        description
        period_start
        period_end
        total
        payment_intent {
          id
          amount
          description
          client_secret
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
        }
        status
        subscription {
          created
          currency
        }
      }
      type
    }
    subscriptionUpcomingInvoice {
      id
      description
      period_start
      period_end
      total
      subscription {
        created
        currency
      }
    }
    productInstances {
      _id
      transferToken
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          full_name
          picture
        }
      }
    }
    user {
      _id
      profile {
        full_name
      }
    }
    created
    updated
    status
  }
}
    `;
function useDeleteSubscriptionProductAndCancelSubscriptionsInBulkMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(DeleteSubscriptionProductAndCancelSubscriptionsInBulkDocument, options);
}
__name(useDeleteSubscriptionProductAndCancelSubscriptionsInBulkMutation, "useDeleteSubscriptionProductAndCancelSubscriptionsInBulkMutation");
var RetryInvoiceDocument = gql7`
    mutation RetryInvoice($paymentMethodId: String!, $invoiceId: String!) {
  retryInvoice(paymentMethodId: $paymentMethodId, invoiceId: $invoiceId) {
    id
    auto_advance
    collection_method
    currency
    description
    payment_intent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
    }
    period_start
    period_end
    status
    subscription {
      id
      currency
      cancel_at_period_end
      current_period_end
      current_period_start
      status
      created
      days_until_due
      livemode
      next_pending_invoice_item_invoice
    }
    total
  }
}
    `;
function useRetryInvoiceMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(RetryInvoiceDocument, options);
}
__name(useRetryInvoiceMutation, "useRetryInvoiceMutation");
var CancelSubscriptionForGroupAtPeriodEndDocument = gql7`
    mutation CancelSubscriptionForGroupAtPeriodEnd($subscriptionId: String!, $cancelAtPeriodEnd: Boolean!) {
  cancelSubscriptionForGroupAtPeriodEnd(
    subscriptionId: $subscriptionId
    cancelAtPeriodEnd: $cancelAtPeriodEnd
  ) {
    id
    cancel_at_period_end
    current_period_end
    current_period_start
    status
    created
    days_until_due
    livemode
    next_pending_invoice_item_invoice
  }
}
    `;
function useCancelSubscriptionForGroupAtPeriodEndMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CancelSubscriptionForGroupAtPeriodEndDocument, options);
}
__name(useCancelSubscriptionForGroupAtPeriodEndMutation, "useCancelSubscriptionForGroupAtPeriodEndMutation");
var ClaimTicketDocument = gql7`
    mutation ClaimTicket($productInstanceId: ID!, $token: String!) {
  claimProductInstance(productInstanceId: $productInstanceId, token: $token) {
    _id
    transferToken
    product {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          subtype
        }
      }
    }
    owner {
      _id
      profile {
        full_name
        picture
      }
    }
    purchaser {
      _id
      profile {
        full_name
        picture
      }
    }
  }
}
    `;
function useClaimTicketMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(ClaimTicketDocument, options);
}
__name(useClaimTicketMutation, "useClaimTicketMutation");
var GenerateTicketClaimLinkDocument = gql7`
    mutation GenerateTicketClaimLink($input: [TicketClaimLinkInput!]!, $host: String) {
  generateTicketClaimLink(input: $input, host: $host) {
    status
    value
    reason
  }
}
    `;
function useGenerateTicketClaimLinkMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(GenerateTicketClaimLinkDocument, options);
}
__name(useGenerateTicketClaimLinkMutation, "useGenerateTicketClaimLinkMutation");
var GenerateTicketForMemberByAdminDocument = gql7`
    mutation GenerateTicketForMemberByAdmin($input: GenerateTicketForMemberByAdminInput, $host: String) {
  generateTicketForMemberByAdmin(input: $input, host: $host) {
    _id
    transferToken
    product {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    owner {
      _id
      profile {
        full_name
        picture
      }
    }
    purchaser {
      _id
      profile {
        full_name
        picture
      }
    }
  }
}
    `;
function useGenerateTicketForMemberByAdminMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(GenerateTicketForMemberByAdminDocument, options);
}
__name(useGenerateTicketForMemberByAdminMutation, "useGenerateTicketForMemberByAdminMutation");
var CancelTicketTransferDocument = gql7`
    mutation CancelTicketTransfer($productInstanceId: String!) {
  cancelTicketTransfer(productInstanceId: $productInstanceId) {
    _id
    transferToken
    transferRecipients {
      user {
        _id
        profile {
          full_name
          picture
        }
      }
      email
    }
    product {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    owner {
      _id
      profile {
        full_name
        picture
      }
    }
    purchaser {
      _id
      profile {
        full_name
        picture
      }
    }
  }
}
    `;
function useCancelTicketTransferMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CancelTicketTransferDocument, options);
}
__name(useCancelTicketTransferMutation, "useCancelTicketTransferMutation");
var RetryCreatingPreAuthSubscriptionOrderDocument = gql7`
    mutation RetryCreatingPreAuthSubscriptionOrder($userID: String!, $groupID: String!, $paymentMethodToken: String!) {
  retryCreatingPreAuthSubscriptionOrder(
    userID: $userID
    groupID: $groupID
    paymentMethodToken: $paymentMethodToken
  )
}
    `;
function useRetryCreatingPreAuthSubscriptionOrderMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(RetryCreatingPreAuthSubscriptionOrderDocument, options);
}
__name(useRetryCreatingPreAuthSubscriptionOrderMutation, "useRetryCreatingPreAuthSubscriptionOrderMutation");
var CreatePreAuthSubscriptionOrderDocument = gql7`
    mutation CreatePreAuthSubscriptionOrder($orderId: String!) {
  createPreAuthSubscriptionOrder(orderId: $orderId) {
    user {
      __typename
      ...UserFragment
    }
    group {
      __typename
      ...GroupFragment
    }
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
      charges {
        object
        data {
          amount
          amount_refunded
          refunded
          refunds {
            object
            data {
              created
              amount
              status
              failure_reason
              reason
            }
          }
        }
        url
        has_more
      }
    }
  }
}
    ${UserFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
function useCreatePreAuthSubscriptionOrderMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreatePreAuthSubscriptionOrderDocument, options);
}
__name(useCreatePreAuthSubscriptionOrderMutation, "useCreatePreAuthSubscriptionOrderMutation");
var ConfirmPreAuthSubscriptionOrderDocument = gql7`
    mutation ConfirmPreAuthSubscriptionOrder($orderId: String!, $userId: String!, $groupId: String!) {
  confirmPreAuthSubscriptionOrder(
    orderId: $orderId
    userId: $userId
    groupId: $groupId
  ) {
    user {
      __typename
      ...UserFragment
    }
    group {
      __typename
      ...GroupFragment
    }
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
      charges {
        object
        data {
          amount
          amount_refunded
          refunded
          refunds {
            object
            data {
              created
              amount
              status
              failure_reason
              reason
            }
          }
        }
        url
        has_more
      }
    }
  }
}
    ${UserFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
function useConfirmPreAuthSubscriptionOrderMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(ConfirmPreAuthSubscriptionOrderDocument, options);
}
__name(useConfirmPreAuthSubscriptionOrderMutation, "useConfirmPreAuthSubscriptionOrderMutation");
var CancelPreAuthSubscriptionOrderDocument = gql7`
    mutation CancelPreAuthSubscriptionOrder($orderId: String, $userId: String, $groupId: String) {
  cancelPreAuthSubscriptionOrder(
    orderId: $orderId
    userId: $userId
    groupId: $groupId
  ) {
    user {
      __typename
      ...UserFragment
    }
    group {
      __typename
      ...GroupFragment
    }
  }
}
    ${UserFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
function useCancelPreAuthSubscriptionOrderMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CancelPreAuthSubscriptionOrderDocument, options);
}
__name(useCancelPreAuthSubscriptionOrderMutation, "useCancelPreAuthSubscriptionOrderMutation");
var CreateGuardianAccountDocument = gql7`
    mutation CreateGuardianAccount($input: NewGuardianUserInput!) {
  createGuardianUser(input: $input) {
    token
  }
}
    `;
function useCreateGuardianAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateGuardianAccountDocument, options);
}
__name(useCreateGuardianAccountMutation, "useCreateGuardianAccountMutation");
var CreateChildUserDocument = gql7`
    mutation CreateChildUser($input: NewEphemeralUserInput, $childVerificationCode: String) {
  createChildUser(input: $input, childVerificationCode: $childVerificationCode) {
    token
  }
}
    `;
function useCreateChildUserMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateChildUserDocument, options);
}
__name(useCreateChildUserMutation, "useCreateChildUserMutation");
var CreateEphemeralUserDocument = gql7`
    mutation CreateEphemeralUser($input: NewEphemeralUserInput) {
  createEphemeralUser(input: $input) {
    token
  }
}
    `;
function useCreateEphemeralUserMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateEphemeralUserDocument, options);
}
__name(useCreateEphemeralUserMutation, "useCreateEphemeralUserMutation");
var LoginToChildAccountDocument = gql7`
    mutation LoginToChildAccount($input: LoginToChildAccountInput!) {
  loginToChildAccount(input: $input) {
    token
  }
}
    `;
function useLoginToChildAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(LoginToChildAccountDocument, options);
}
__name(useLoginToChildAccountMutation, "useLoginToChildAccountMutation");
var LoginToChildAccountWithChildIdDocument = gql7`
    mutation loginToChildAccountWithChildId($input: LoginToChildAccountInput!) {
  loginToChildAccountWithChildId(input: $input) {
    token
  }
}
    `;
function useLoginToChildAccountWithChildIdMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(LoginToChildAccountWithChildIdDocument, options);
}
__name(useLoginToChildAccountWithChildIdMutation, "useLoginToChildAccountWithChildIdMutation");
var UpdateChildPermissionsDocument = gql7`
    mutation UpdateChildPermissions($input: ChildPermissionsInput!) {
  updateChildPermissions(input: $input) {
    ...UserFragment
    featureSets
  }
}
    ${UserFragmentFragmentDoc}`;
function useUpdateChildPermissionsMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateChildPermissionsDocument, options);
}
__name(useUpdateChildPermissionsMutation, "useUpdateChildPermissionsMutation");
var UpdatePermissionsDocument = gql7`
    mutation UpdatePermissions($input: PermissionsInput!) {
  updatePermissions(input: $input) {
    ...UserFragment
    featureSets
  }
}
    ${UserFragmentFragmentDoc}`;
function useUpdatePermissionsMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdatePermissionsDocument, options);
}
__name(useUpdatePermissionsMutation, "useUpdatePermissionsMutation");
var UpdateUserChildVerificationCodesDocument = gql7`
    mutation UpdateUserChildVerificationCodes($childVerificationCodes: [String!]!) {
  updateUserChildVerificationCodes(
    childVerificationCodes: $childVerificationCodes
  ) {
    ...UserFragment
    childVerificationCodes
  }
}
    ${UserFragmentFragmentDoc}`;
function useUpdateUserChildVerificationCodesMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateUserChildVerificationCodesDocument, options);
}
__name(useUpdateUserChildVerificationCodesMutation, "useUpdateUserChildVerificationCodesMutation");
var RetainEphemeralUserAsChildAccountDocument = gql7`
    mutation RetainEphemeralUserAsChildAccount($code: String!) {
  retainEphemeralUserAsChildAccount(code: $code) {
    _id
  }
}
    `;
function useRetainEphemeralUserAsChildAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(RetainEphemeralUserAsChildAccountDocument, options);
}
__name(useRetainEphemeralUserAsChildAccountMutation, "useRetainEphemeralUserAsChildAccountMutation");
var UpdatePasswordDocument = gql7`
    mutation UpdatePassword($oldPassword: String!, $newPassword: String!, $newPasswordConfirmation: String!) {
  updatePassword(
    oldPassword: $oldPassword
    newPassword: $newPassword
    newPasswordConfirmation: $newPasswordConfirmation
  )
}
    `;
function useUpdatePasswordMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdatePasswordDocument, options);
}
__name(useUpdatePasswordMutation, "useUpdatePasswordMutation");
var BookmarkEventGroupDocument = gql7`
    mutation BookmarkEventGroup($groupId: ID!, $remove: Boolean) {
  bookmarkEventGroup(groupId: $groupId, remove: $remove) {
    _id
    isBookmarked
  }
}
    `;
function useBookmarkEventGroupMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(BookmarkEventGroupDocument, options);
}
__name(useBookmarkEventGroupMutation, "useBookmarkEventGroupMutation");
var ClaimEphemeralAccountDocument = gql7`
    mutation ClaimEphemeralAccount($email: String!, $password: String!) {
  claimEphemeralAccount(email: $email, password: $password)
}
    `;
function useClaimEphemeralAccountMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(ClaimEphemeralAccountDocument, options);
}
__name(useClaimEphemeralAccountMutation, "useClaimEphemeralAccountMutation");
var UpdateLaunchDarklyFlagDocument = gql7`
    mutation UpdateLaunchDarklyFlag($featureFlagKey: String!) {
  updateLaunchDarklyFlag(featureFlagKey: $featureFlagKey)
}
    `;
function useUpdateLaunchDarklyFlagMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateLaunchDarklyFlagDocument, options);
}
__name(useUpdateLaunchDarklyFlagMutation, "useUpdateLaunchDarklyFlagMutation");
var UpdateDisplaySurveyAnswersDocument = gql7`
    mutation UpdateDisplaySurveyAnswers($updatedValue: Boolean!) {
  updateDisplaySurveyAnswers(updatedValue: $updatedValue) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
function useUpdateDisplaySurveyAnswersMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(UpdateDisplaySurveyAnswersDocument, options);
}
__name(useUpdateDisplaySurveyAnswersMutation, "useUpdateDisplaySurveyAnswersMutation");
var JoinOrRequestToJoinMultipleGroupsByIdDocument = gql7`
    mutation JoinOrRequestToJoinMultipleGroupsByID($groupIDs: [String!]!, $membershipGroupId: String) {
  joinOrRequestToJoinMultipleGroupsByID(
    groupIDs: $groupIDs
    membershipGroupId: $membershipGroupId
  ) {
    group {
      __typename
      ...GroupFragment
    }
    status
  }
}
    ${GroupFragmentFragmentDoc}`;
function useJoinOrRequestToJoinMultipleGroupsByIdMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(JoinOrRequestToJoinMultipleGroupsByIdDocument, options);
}
__name(useJoinOrRequestToJoinMultipleGroupsByIdMutation, "useJoinOrRequestToJoinMultipleGroupsByIdMutation");
var SetTicketIsCheckedInDocument = gql7`
    mutation SetTicketIsCheckedIn($productInstanceId: String!, $isCheckedIn: Boolean!) {
  setTicketIsCheckedIn(
    productInstanceId: $productInstanceId
    isCheckedIn: $isCheckedIn
  ) {
    _id
    transferToken
    isCheckedIn
    transferRecipients {
      user {
        _id
        profile {
          full_name
          picture
        }
      }
      email
    }
    product {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
      createdBy {
        profile {
          full_name
          coverPhoto
        }
      }
      reference {
        ... on Group {
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
          eventOverviewDetails {
            title
            description
            headline
            date
            dates {
              startDate
              endDate
            }
            startTime
            endTime
            displayStartTime
            displayEndTime
            location {
              formatted_address
            }
            link
            rsvpLink
            allDay
            timeZone {
              name
            }
            eventLocationSettings {
              location {
                formatted_address
                city
                state
                country
              }
              type
              link
              venue {
                name
                link
                location {
                  formatted_address
                  location {
                    type
                    coordinates
                  }
                  postal
                  city
                  state
                  country
                }
              }
            }
          }
          settings {
            protectionLevel
          }
          subtype
        }
      }
    }
    owner {
      _id
      profile {
        full_name
        picture
      }
    }
    purchaser {
      _id
      profile {
        full_name
        picture
      }
    }
  }
}
    `;
function useSetTicketIsCheckedInMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SetTicketIsCheckedInDocument, options);
}
__name(useSetTicketIsCheckedInMutation, "useSetTicketIsCheckedInMutation");
var SetTicketIsCheckedInViaScannerDocument = gql7`
    mutation SetTicketIsCheckedInViaScanner($productInstanceId: String!, $groupId: String!) {
  setTicketIsCheckedInViaScanner(
    productInstanceId: $productInstanceId
    groupId: $groupId
  ) {
    productInstance {
      _id
      transferToken
      isCheckedIn
      checkInDate
      transferRecipients {
        user {
          _id
          profile {
            full_name
            picture
          }
        }
        email
      }
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          full_name
          picture
          email
          phone
        }
      }
      purchaser {
        _id
        profile {
          full_name
          picture
        }
      }
    }
    ticketCheckInStatus
  }
}
    `;
function useSetTicketIsCheckedInViaScannerMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SetTicketIsCheckedInViaScannerDocument, options);
}
__name(useSetTicketIsCheckedInViaScannerMutation, "useSetTicketIsCheckedInViaScannerMutation");
var BackfillMyGetStreamGroupsDocument = gql7`
    mutation BackfillMyGetStreamGroups {
  backfillMyGetStreamGroups
}
    `;
function useBackfillMyGetStreamGroupsMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(BackfillMyGetStreamGroupsDocument, options);
}
__name(useBackfillMyGetStreamGroupsMutation, "useBackfillMyGetStreamGroupsMutation");
var SendSubscriptionOrderConfirmationEmailDocument = gql7`
    mutation SendSubscriptionOrderConfirmationEmail($orderId: String!) {
  sendSubscriptionOrderConfirmationEmail(orderId: $orderId)
}
    `;
function useSendSubscriptionOrderConfirmationEmailMutation(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(SendSubscriptionOrderConfirmationEmailDocument, options);
}
__name(useSendSubscriptionOrderConfirmationEmailMutation, "useSendSubscriptionOrderConfirmationEmailMutation");
export {
  AccountHolderType,
  AccountStatus,
  AddCardToCustomerAccountDocument,
  AddGroupToUserDocument,
  AddPhoneToUserDocument,
  AddUserToGroupDocument,
  AggregationType,
  AnalyticScopeType,
  ApplicationStatus,
  ApprovalType,
  BackfillMyGetStreamGroupsDocument,
  BookmarkEventGroupDocument,
  BulkCardAction,
  BulkGenerateUsersFromCsvDocument,
  BulkInviteUsersByEmailDocument,
  CancelEmailInviteToGroupDocument,
  CancelPreAuthSubscriptionOrderDocument,
  CancelSubscriptionForGroupAtPeriodEndDocument,
  CancelTicketTransferDocument,
  CardActions,
  CardEngagementType,
  CardPostType,
  CardVoteType,
  ClaimEphemeralAccountDocument,
  ClaimTicketDocument,
  Collection,
  CommunityTypes,
  ComparatorType,
  ConfirmPreAuthSubscriptionOrderDocument,
  ConnectionAction,
  ConnectionStatus,
  CouponType,
  CourseProgressType,
  CreateChildUserDocument,
  CreateCustomerOrderDocument,
  CreateEphemeralUserDocument,
  CreateGroupDocument,
  CreateGuardianAccountDocument,
  CreateGuestUserDocument,
  CreateOrderRefundDocument,
  CreatePreAuthSubscriptionOrderDocument,
  CreateProductDocument,
  CreateSetupIntentForLoggedInUserDocument,
  CreateStripeConnectedAccountDocument,
  CreateStripeCustomerAccountDocument,
  CreateUserDocument,
  CustomSignupFieldTypes,
  DeactivateAccountDocument,
  DeleteCustomerCardSourceDocument,
  DeleteGroupDocument,
  DeleteProductDocument,
  DeleteSubscriptionProductAndCancelSubscriptionsInBulkDocument,
  DeleteUserAccountDocument,
  ElectionStatus,
  EngagementFilterType,
  EngagementSortOrder,
  EngagementSortType,
  EngagementType,
  EventAction,
  EventRsvp,
  ExpressInterestInGroupDocument,
  FilterType,
  FlagUserDocument,
  FollowUserDocument,
  FormSpecificationType,
  FulfillCashOrderDocument,
  GenerateConnectedAccountLoginLinkDocument,
  GenerateTicketClaimLinkDocument,
  GenerateTicketForMemberByAdminDocument,
  GroupActions,
  GroupRole,
  GroupSubtype,
  JoinOrRequestToJoinMultipleGroupsByIdDocument,
  LabelType,
  LocationType,
  LogUserEventDocument,
  LoginToChildAccountDocument,
  LoginToChildAccountWithChildIdDocument,
  MembershipStyle,
  MessageThreadType,
  MobilizationStatus,
  MobilizationType,
  NewsfeedFilter,
  NotificationChannelType,
  NotificationLevel,
  NotificationType,
  OrderLocation,
  PaymentType,
  PledgeToTasksDocument,
  PositionType,
  PostingStatus,
  PostingType,
  PublishEventDocument,
  ReactivateAccountDocument,
  RecommendationManager,
  RecommendationType,
  RecruitType,
  ReferenceType,
  RefundAndCancelGroupSubscriptionDocument,
  ReportingType,
  ResetPasswordWithTokenDocument,
  Resource,
  RespondNotGoingToGroupEventDocument,
  RetainEphemeralUserAsChildAccountDocument,
  RetryCreatingPreAuthSubscriptionOrderDocument,
  RetryInvoiceDocument,
  Role,
  RrCircleSize,
  RrFont,
  RrLineStyle,
  RsvpAndRegisterToEventDocument,
  SearchFilterType,
  SearchScopeType,
  SendClaimAccountEmailDocument,
  SendCreateAccountReminderEmailsDocument,
  SendEmailVerificationCodeDocument,
  SendPasswordResetForEmailDocument,
  SendSmsVerificationCodeDocument,
  SendSubscriptionOrderConfirmationEmailDocument,
  SendVerifyEmailDocument,
  SetCustomerCardSourceAsDefaultDocument,
  SetGroupDashboardDocument,
  SetIsMembershipPaidDocument,
  SetTicketIsCheckedInDocument,
  SetTicketIsCheckedInViaScannerDocument,
  SocialActions,
  StorageType,
  StripeInvoiceStatus,
  StripeSubscriptionStatus,
  SubscribeGroupToContentDocument,
  SubscriptionLevel,
  SurveyQuestionSubType,
  SurveyQuestionType,
  SwitchActiveGroupDocument,
  TicketCheckInStatusEnum,
  UnpublishEventDocument,
  UpdateAction,
  UpdateChildPermissionsDocument,
  UpdateConnectionDocument,
  UpdateCustomUserRoleForUrlDocument,
  UpdateDisplaySurveyAnswersDocument,
  UpdateGroupDocument,
  UpdateHasUnreadDashboardDocument,
  UpdateLaunchDarklyFlagDocument,
  UpdatePasswordDocument,
  UpdatePermissionsDocument,
  UpdateProductsDocument,
  UpdateStripeCustomerAccountDocument,
  UpdateSubscriptionProductDocument,
  UpdateUserChildVerificationCodesDocument,
  UpdateUserDocument,
  UpdateUserGroupSurveyDismissalTimeDocument,
  UpdateUserRoleForUrlDocument,
  UpdateUserSideMenuDocument,
  UserType,
  VerifierType,
  VerifyEmailDocument,
  VerifyEmailVerificationCodeDocument,
  VerifySmsCodeDocument,
  ViewPermission,
  ViewType,
  Violation,
  useAddCardToCustomerAccountMutation,
  useAddGroupToUserMutation,
  useAddPhoneToUserMutation,
  useAddUserToGroupMutation,
  useBackfillMyGetStreamGroupsMutation,
  useBookmarkEventGroupMutation,
  useBulkGenerateUsersFromCsvMutation,
  useBulkInviteUsersByEmailMutation,
  useCancelEmailInviteToGroupMutation,
  useCancelPreAuthSubscriptionOrderMutation,
  useCancelSubscriptionForGroupAtPeriodEndMutation,
  useCancelTicketTransferMutation,
  useClaimEphemeralAccountMutation,
  useClaimTicketMutation,
  useConfirmPreAuthSubscriptionOrderMutation,
  useCreateChildUserMutation,
  useCreateCustomerOrderMutation,
  useCreateEphemeralUserMutation,
  useCreateGroupMutation,
  useCreateGuardianAccountMutation,
  useCreateGuestUserMutation,
  useCreateOrderRefundMutation,
  useCreatePreAuthSubscriptionOrderMutation,
  useCreateProductMutation,
  useCreateSetupIntentForLoggedInUserMutation,
  useCreateStripeConnectedAccountMutation,
  useCreateStripeCustomerAccountMutation,
  useCreateUserMutation,
  useDeactivateAccountMutation,
  useDeleteCustomerCardSourceMutation,
  useDeleteGroupMutation,
  useDeleteProductMutation,
  useDeleteSubscriptionProductAndCancelSubscriptionsInBulkMutation,
  useDeleteUserAccountMutation,
  useExpressInterestInGroupMutation,
  useFlagUserMutation,
  useFollowUserMutation,
  useFulfillCashOrderMutation,
  useGenerateConnectedAccountLoginLinkMutation,
  useGenerateTicketClaimLinkMutation,
  useGenerateTicketForMemberByAdminMutation,
  useJoinOrRequestToJoinMultipleGroupsByIdMutation,
  useLogUserEventMutation,
  useLoginToChildAccountMutation,
  useLoginToChildAccountWithChildIdMutation,
  usePledgeToTasksMutation,
  usePublishEventMutation,
  useReactivateAccountMutation,
  useRefundAndCancelGroupSubscriptionMutation,
  useResetPasswordWithTokenMutation,
  useRespondNotGoingToGroupEventMutation,
  useRetainEphemeralUserAsChildAccountMutation,
  useRetryCreatingPreAuthSubscriptionOrderMutation,
  useRetryInvoiceMutation,
  useRsvpAndRegisterToEventMutation,
  useSendClaimAccountEmailMutation,
  useSendCreateAccountReminderEmailsMutation,
  useSendEmailVerificationCodeMutation,
  useSendPasswordResetForEmailMutation,
  useSendSmsVerificationCodeMutation,
  useSendSubscriptionOrderConfirmationEmailMutation,
  useSendVerifyEmailMutation,
  useSetCustomerCardSourceAsDefaultMutation,
  useSetGroupDashboardMutation,
  useSetIsMembershipPaidMutation,
  useSetTicketIsCheckedInMutation,
  useSetTicketIsCheckedInViaScannerMutation,
  useSubscribeGroupToContentMutation,
  useSwitchActiveGroupMutation,
  useUnpublishEventMutation,
  useUpdateChildPermissionsMutation,
  useUpdateConnectionMutation,
  useUpdateCustomUserRoleForUrlMutation,
  useUpdateDisplaySurveyAnswersMutation,
  useUpdateGroupMutation,
  useUpdateHasUnreadDashboardMutation,
  useUpdateLaunchDarklyFlagMutation,
  useUpdatePasswordMutation,
  useUpdatePermissionsMutation,
  useUpdateProductsMutation,
  useUpdateStripeCustomerAccountMutation,
  useUpdateSubscriptionProductMutation,
  useUpdateUserChildVerificationCodesMutation,
  useUpdateUserGroupSurveyDismissalTimeMutation,
  useUpdateUserMutation,
  useUpdateUserRoleForUrlMutation,
  useUpdateUserSideMenuMutation,
  useVerifyEmailMutation,
  useVerifyEmailVerificationCodeMutation,
  useVerifySmsCodeMutation
};
