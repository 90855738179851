import React from 'react';
import {Link} from 'react-router-dom';

import {
    getBackgroundImageSetForUrl,
    getBackgroundImageWithGradient,
    getRandomDefaultCoverImage,
    getRandomDefaultThumbImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import ConditionalWrapper from '@web/ui/components/generic/ConditionalWrapper';

const GroupSummaryCard = ({ group, link, onSelect }) => {
    const [src, srcSet] = getBackgroundImageSetForUrl(
        group?.profile?.picture || getRandomDefaultThumbImage()
    );

    const getBackgroundStyle = getBackgroundImageWithGradient(
        group?.profile?.coverPhoto || getRandomDefaultCoverImage(group.profile.full_name),
        0.3,
        0.6
    );

    const truncatedBio =
        group?.profile?.short_bio?.length > 60
            ? `${group.profile.short_bio.slice(0, 60)}...`
            : group?.profile?.short_bio;

    return (
        <ConditionalWrapper
            condition={link}
            Wrapper={Link}
            // eslint-disable-next-line react/jsx-props-no-spreading
            AlternateWrapper={({ children, ...props }) => <section {...props}>{children}</section>}
            className="summary-card-wrapper group"
            style={getBackgroundStyle}
            to={link}
        >
            <StyledBackgroundImage
                className="sc-left w-inline-block"
                src={src}
                srcSet={srcSet}
                onClick={() => onSelect?.(group)}
            />
            <div className="sc-right">
                <span className="sc-title" data-testid="group-profile-full-name">
                    {group.profile.full_name}
                </span>
                <div>{truncatedBio}</div>
            </div>
        </ConditionalWrapper>
    );
};

export default GroupSummaryCard;
