import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '@components/modals/hooks/useModal';
import useEventListingFormState from '@components/content/newpost/event/useEventListingFormState';

import EventListingForm from '@components/content/newpost/event/EventListingForm';
import ModalLoading from '@components/modals/ModalLoading';

import { getOverviewTime, getEarliestDay, getLatestDay } from '@core/utilities/constants/events';
import useSimpleNewPost from '@web/ui/components/content/newpost/useSimpleNewPost';

import { ContentCard, DistinctChannelDestinationInput } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

type CreateEventListingFormProps = {
    hasAdminRole?: boolean;
    card: ContentCard;
    closeModalhandler: () => void;
    feedUrl?: string;
    distinctChannelDestination?: DistinctChannelDestinationInput;
    chapter?: ContentCard;
    sortType?: string;
};

const CreateEventListingForm: React.FC<CreateEventListingFormProps> = ({
    feedUrl,
    chapter,
    sortType,
    distinctChannelDestination,
}) => {
    const { t } = useTranslation();

    const [state, setState] = useEventListingFormState();
    const [createRes, setCreateRes] = useState(null);

    const { newModal, closeModal } = useModal();

    const createPost = useSimpleNewPost({
        url: feedUrl,
        chapter,
        sortType,
    });

    const onSubmit = async () => {
        // Construct payload

        const { overviewFormState, dateAndTimeState } = state;

        const { startTime, endTime, dates } = dateAndTimeState;
        const earliestDay = getEarliestDay(dates, dates[0].startDate);
        const latestDay = getLatestDay(dates, dates[0].startDate);

        const { type, link, platformName, venue } = overviewFormState?.eventLocationSettings;

        const eventLocationSettings = {
            type,
            link,
            platformName,
            venue,
        };

        const eventOverviewDetails = {
            title: overviewFormState.title,
            description: overviewFormState.description,
            dates: dateAndTimeState.dates,
            startTime: getOverviewTime(new Date(startTime), earliestDay),
            endTime: getOverviewTime(new Date(endTime), latestDay),
            timeZone: dateAndTimeState.timeZone,
            displayStartTime: dateAndTimeState.displayStartTime,
            displayEndTime: dateAndTimeState.displayEndTime,
            rsvpLink: overviewFormState?.eventLocationSettings?.rsvpLink,
            eventLocationSettings,
        };

        const payload9 = {
            parentUrl: feedUrl,
            distinctChannelDestination,
            title: overviewFormState?.title,
            description: overviewFormState?.description,
            mediaThumb: overviewFormState?.coverImage,
            eventOverviewDetails,
            type: 'event',
        };

        newModal(
            <ModalLoading
                message={t(
                    `common:imports.wlWeb.ui.components.group.eventDashboard.creating_group`
                )}
            />,
            { widen: true, addShadow: true }
        );

        try {
            createPost({
                ...payload9,
            }).then(response => {
                closeModal();
                setCreateRes(response);
            });
        } catch (e) {
            logger.error(e);
        }
    };

    return (
        <EventListingForm
            state={state}
            singleImageUpload
            setState={setState}
            onSubmit={onSubmit}
            cardData={createRes?.data?.attachedCard}
            title="Create Event Listing"
        />
    );
};

export default CreateEventListingForm;
