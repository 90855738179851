import { GroupActions } from '@shared/welibrary-graphql/types';

export const GROUP_JOIN = GroupActions.JoinGroup;
export const GROUP_REQUEST_JOIN = GroupActions.RequestJoinGroup;
export const GROUP_CANCEL_REQUEST_JOIN = GroupActions.CancelRequestJoinGroup;
export const GROUP_ACCEPT_INVITE = GroupActions.AcceptInviteGroup;
export const GROUP_CANCEL_PRE_AUTH_PURCHASE = GroupActions.CancelPreAuthPurchase;
export const GROUP_LEAVE = GroupActions.LeaveGroup;
export const GROUP_PURCHASE_ACCESS = GroupActions.PurchaseAccess;
export const GROUP_EDIT_PRODUCTS = GroupActions.EditProducts;
export const GROUP_VIEW_PURCHASED_PRODUCTS = GroupActions.ViewPurchasedProducts;
export const GROUP_EXPRESS_INTEREST = GroupActions.ExpressInterest;
export const GROUP_LOGGEDOUT_EXPRESS_INTEREST = GroupActions.LoggedOutExpressInterest;
export const GROUP_LOGGEDOUT_RSVP = GroupActions.LoggedOutRsvp;
export const GROUP_LOGGEDOUT_PURCHASE_ACCESS = GroupActions.LoggedOutPurchaseAccess;
export const GROUP_RESPOND_NOT_GOING = GroupActions.RespondNotGoing;
export const GROUP_REQUEST_TO_PURCHASE = GroupActions.RequestToPurchaseAccess;
export const GROUP_REACTIVATE_SUBSCRIPTION = GroupActions.ReactivateSubscription;
export const GROUP_PURCHASE_PARENT_ACCESS = GroupActions.PurchaseParentAccess;
export const GROUP_SUBSCRIPTION_PRICE_CHANGE = 'subscriptionPriceChange';
export const GROUP_SUBSCRIPTION_DELETED = 'subscriptionDeleted';
export const GROUP_CONNECTED_ACCOUNT_READY = 'groupConnectedAccountReady';
export const GROUP_SPEAKER_INVITE = 'groupSpeakerInvite';
export const GROUP_CONTENT_AUTO_MODERATED = 'groupContentAutoModerated';
export const GROUP_CONTENT_MODERATED_ACCEPTED = 'groupContentModeratedAccepted';
export const GROUP_CONTENT_MODERATED_REJECTED = 'groupContentModeratedRejected';

export const GROUP_ACTIONS = {
    GROUP_JOIN,
    GROUP_REQUEST_JOIN,
    GROUP_CANCEL_REQUEST_JOIN,
    GROUP_ACCEPT_INVITE,
    GROUP_LEAVE,
    GROUP_REQUEST_TO_PURCHASE,
    GROUP_PURCHASE_ACCESS,
    GROUP_EDIT_PRODUCTS,
    GROUP_VIEW_PURCHASED_PRODUCTS,
    GROUP_EXPRESS_INTEREST,
    GROUP_LOGGEDOUT_EXPRESS_INTEREST,
    GROUP_LOGGEDOUT_RSVP,
    GROUP_LOGGEDOUT_PURCHASE_ACCESS,
    GROUP_RESPOND_NOT_GOING,
    GROUP_REACTIVATE_SUBSCRIPTION,
    GROUP_PURCHASE_PARENT_ACCESS,
    GROUP_SUBSCRIPTION_PRICE_CHANGE,
    GROUP_SUBSCRIPTION_DELETED,
    GROUP_CONNECTED_ACCOUNT_READY,
    GROUP_SPEAKER_INVITE,
    GROUP_CONTENT_AUTO_MODERATED,
    GROUP_CONTENT_MODERATED_ACCEPTED,
    GROUP_CONTENT_MODERATED_REJECTED,
    GROUP_CANCEL_PRE_AUTH_PURCHASE,
};

export default GROUP_ACTIONS;
