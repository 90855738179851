import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCreateRainbowRoadMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';

import useModal from '@components/modals/hooks/useModal';
import useNavigation from '@components/navigation/useNavigation';
import useQuickLinks from '@components/navigation/SideMenu/Navigator/hooks/useQuickLinks';

import RRBuilder from '@components/rainbowroad/builder/RRBuilder';
import ModalLoading from '@components/modals/ModalLoading';

import { RainbowRoadBuilderState } from '@core/types/RainbowRoad';

const localNamespace = 'imports.wlWeb.ui.pages.rainbowRoad';

const CreateRainbowRoad = () => {
    const { t } = useTranslation();

    const history = useHistory();

    const { newModal, closeAllModals } = useModal();
    const { closeSlideMenu } = useNavigation();
    const { addResult } = useQuickLinks();

    const [createRainbowRoad] = useCreateRainbowRoadMutation();

    const submit = (state: RainbowRoadBuilderState) => {
        createRainbowRoad({
            variables: {
                input: {
                    title: state.main.title,
                    description: state.main.description,
                    mediaIcon: state.main.icon.image,
                    engagementTypes: state.main.engagementTypes,
                    rainbowRoadSettings: {
                        header: {
                            darkMode: state.header.darkMode,
                            color: state.header.color,
                            image: state.header.useForegroundImage
                                ? state.header.foregroundImage.image
                                : null,
                            backgroundImage: state.header.useBackgroundImage
                                ? state.header.backgroundImage.image
                                : null,
                            showTopInfo: state.header.showTopInfo,
                        },
                        body: {
                            darkMode: state.body.darkMode,
                            color: state.body.color,
                            image: state.body.useBackgroundImage
                                ? state.body.backgroundImage.image
                                : null,
                        },
                        nodes: {
                            circleSize: state.nodes.circleSize,
                            icon: state.nodes.useIcon ? state.nodes.icon.image : null,
                            lineStyle: state.nodes.lineStyle,
                            lineColor: state.nodes.lineColor,
                            outline: state.nodes.outline,
                            winding: state.nodes.winding,
                        },
                        font: state.main.font,
                    },
                },
            },
        }).then(({ data }) => {
            if (data?.createRainbowRoad?.url) {
                addResult(data.createRainbowRoad);
                history.push(`/r/${data.createRainbowRoad.url}`);
                closeAllModals();
                closeSlideMenu();
            }
        });

        newModal(<ModalLoading message={t(`common:${localNamespace}.creating_rainbow_road`)} />, {
            widen: true,
            addShadow: true,
        });
    };

    return (
        <RRBuilder
            title={t(`common:${localNamespace}.create_rainbow_road`)}
            buttonText={t('common:global.verbs.create')}
            onSubmit={submit}
            showPreview
        />
    );
};

export default CreateRainbowRoad;
