import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import withScrolling from 'react-dnd-scrolling';
import { Flipper } from 'react-flip-toolkit';

import Toggle from '@web/ui/dsc/forms/customInputs/Toggle';
import IncrementStepper from '@web/ui/dsc/forms/customInputs/IncrementStepper';
import DragPreview from '@web/ui/components/content/newpost/forms/Postbox/DragPreview';
import PromptItem from '@web/ui/components/content/newpost/forms/Postbox/PromptItem';
import DraggablePromptItem from '@web/ui/components/content/newpost/forms/Postbox/DraggablePromptItem';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import { getFeatureConstraintsForField } from '@core/utilities/constants/feature_constraints';
import { useCurrentUser } from '@stores/User';
import useCreateMetaChannelCard from './useCreateMetaChannelCard';

const updateNamespace = 'imports.wlWeb.ui.components.card.updateCard';
const createNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

const ScrollingSection = withScrolling('section');

type CreateMetaChannelCardProps = {
    state: any;
    submitPost;
    setState: () => void;
    hasAdminRole: boolean;
    addAttachedContent?: (content: any, type: string, callback: () => void) => void;
    handleSubmit?: () => void;
    closeNewPostModal?: () => void;
    formTitle?: string;
    submitText?: string;
};

const CreateMetaChannelCard: React.FC<CreateMetaChannelCardProps> = ({
    state,
    submitPost,
    hasAdminRole = true,
    setState,
    addAttachedContent,
    handleSubmit,
    formTitle = 'Create Meta-Channel Card',
    submitText = 'Save',
    closeNewPostModal,
}) => {
    const { t } = useTranslation();

    const [showSettings, setShowSettings] = useState(false);
    const { currentUser, refetchCurrentUser } = useCurrentUser();

    useEffect(() => {
        refetchCurrentUser(); // Get up-to-date active group.
    }, []);

    const activeGroupId = currentUser?.activeGroup?._id;

    const rankOrderConstraint = getFeatureConstraintsForField('rankOrder');
    const displayInNewsfeedConstraint = getFeatureConstraintsForField('displayInNewsfeed');

    const {
        title: { title, setTitle },
        channels,
        search: { query, actualQuery, setQuery, searching, loading, error },
        methods: { addChannelAndCloseSearch, updateChannel, moveChannel, deleteChannel, submit },
        results,
    } = useCreateMetaChannelCard({
        prioritizeGroupId: !!activeGroupId,
        groupId: activeGroupId,
        addAttachedContent,
        handleSubmit,
        defaultChannels: state.aliases?.map(({ item, url, title }) => ({
            ...item,
            url,
            newChannel: title, // alias title
        })),
        defaultChannelTitle: state.title,
    });

    const hideFooter = searching;

    const selectedChannels = channels.map((channel, index) => {
        return channels.length > 1 ? (
            <DraggablePromptItem
                key={channel._id}
                hidePrompt
                prompt={channel}
                deletePrompt={deleteChannel}
                updatePrompt={updateChannel}
                index={index}
                movePrompt={moveChannel}
            />
        ) : (
            <PromptItem
                key={channel._id}
                hidePrompt
                prompt={channel}
                deletePrompt={deleteChannel}
                updatePrompt={updateChannel}
                activeGroupId={activeGroupId}
            />
        );
    });

    const searchedChannels = results.reduce((result, prompt) => {
        if (prompt.type === 'book') {
            const childRes = prompt?.children?.map(val => (
                <PromptItem
                    key={val._id}
                    prompt={val}
                    hidePrompt
                    addPrompt={addChannelAndCloseSearch}
                    activeGroupId={activeGroupId}
                />
            ));

            result.push(...childRes);
        } else {
            result.push(
                <PromptItem
                    key={prompt._id}
                    prompt={prompt}
                    hidePrompt
                    addPrompt={addChannelAndCloseSearch}
                    activeGroupId={activeGroupId}
                />
            );
        }

        return result;
    }, []);

    const handleSubmitTest = e => {
        e.preventDefault();
        submitPost();
    };

    return (
        <form onSubmit={handleSubmitTest} className="create-postbox-form meta-channel-form">
            <header>
                <section>
                    <button
                        className={showSettings ? '' : 'hidden'}
                        onClick={() => setShowSettings(false)}
                        type="button"
                        label="Back"
                    />
                    <h1>{showSettings ? 'Postbox Settings' : formTitle}</h1>
                </section>
                <button type="button" onClick={() => closeNewPostModal()}>
                    {t(`common:${createNamespace}.cancel`)}
                </button>
            </header>

            {showSettings ? (
                <section className="settings">
                    {hasAdminRole && rankOrderConstraint && (
                        // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        <label>
                            {t(`common:${updateNamespace}.postBoxUpdateForm.display_order`)}
                            <IncrementStepper
                                value={state.rankOrder}
                                onChange={value => setState({ rankOrder: value })}
                            />
                        </label>
                    )}
                    {hasAdminRole && displayInNewsfeedConstraint && (
                        // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        <label>
                            {t(`common:${updateNamespace}.mediaCardUpdateForm.display_in_newsfeed`)}
                            <Toggle
                                value={state.displayInNewsfeed}
                                onChange={value => setState({ displayInNewsfeed: value })}
                            />
                        </label>
                    )}
                </section>
            ) : (
                <>
                    <fieldset>
                        <section>
                            <img src="/images/icon.black.search.svg" alt="Search" />
                            <input
                                type="text"
                                placeholder="Search Channels"
                                value={query}
                                onChange={e => setQuery(e.target.value)}
                            />
                        </section>
                    </fieldset>

                    <ScrollingSection>
                        {loading && <LoadingLine isLoading={loading} error={error} />}
                        <DragPreview />
                        <Flipper
                            flipKey={channels.map(p => p._id).join() + actualQuery}
                            element="ol"
                        >
                            {searching ? searchedChannels : selectedChannels}
                        </Flipper>
                    </ScrollingSection>
                </>
            )}

            <footer className={hideFooter ? 'hidden' : ''}>
                <section>
                    {/* {chapterTitle && (
                        <span>{`${t('common:global.verbs.posting_into')} ${chapterTitle}`}</span>
                    )} */}
                </section>
                <section>
                    <button type="submit" disabled={channels.length < 1}>
                        {submitText}
                    </button>

                    <button
                        type="button"
                        onClick={() => setShowSettings(oldShow => !oldShow)}
                        className="toggle-settings"
                        label="Settings"
                    />
                </section>
            </footer>
        </form>
    );
};

export default CreateMetaChannelCard;
