import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';
import {
    useGetUserOrdersForGroupQuery,
    useGetEventGroupLazyQuery,
} from '@shared/welibrary-graphql/user/queries.hook';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import signUpStore from '@stores/SignUp';
import shoppingCartStore from '@web/ui/stores/ShoppingCart';

import X from '@dsc/svgs/FullSizeX';
import AlertToast from '@components/toast/AlertToast';
import OrderItemPreview from '@web/ui/components/card/Shopping-Cart/OrderItemPreview';
import StripeCheckoutForm from '@components/card/Shopping-Cart/StripeCheckoutForm';
import PendingFulfillment from '@components/card/Shopping-Cart/PendingFulfillment';
import CheckoutSuccess from '@components/card/Shopping-Cart/CheckoutSuccess';
import ShoppingCartSelectorItem from '@components/card/Shopping-Cart/ShoppingCartSelectorItem';
import StripeOrderDetails from '@components/profile/stripe/StripeOrderDetails';
import SignUpPageButton from '@pages/new-signup/SignUpPageButton';

import { Product, Group } from '@shared/welibrary-graphql/types';
import {
    UserDetailsState,
    DiscountCodeState,
    getTotal,
} from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';
import { defaultAlertToastOptions } from '@core/types/Toast';
import { CHECKOUT_STATE } from '@components/card/Shopping-Cart/CheckoutHelpers';
import { CARD_EVENT } from '@core/utilities/constants/card_types';
import { isEventFree } from '@web/utilities/helpers/events/event.helpers';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.toast';

type PaymentProps = {
    ref: React.RefObject<HTMLElement>;
    state: UserDetailsState;
    cart: { cartItem: Product; quantity: number };
    discountCodeState: DiscountCodeState;
    checkoutState: boolean | CHECKOUT_STATE;
    setCheckoutState: React.Dispatch<React.SetStateAction<boolean | CHECKOUT_STATE>>;
    group: Group;
    orderId: string;
    clientSecret: string;
    setClientSecret: React.Dispatch<React.SetStateAction<string>>;
    handlePrevStep: (smooth: boolean) => void;
    closeModal: () => void;

    // new guest/registration checkout
    hideCloseButton: boolean;
    customPaymentHeading?: string | React.ReactNode;
    onCancel?: () => void;
    onGoBack: () => void;
    useSuccessAltText?: boolean;
    handleSuccessRedirect?: () => void;
    currentPage: string;
    isSignupGuestCheckout?: boolean;

    isInline: boolean;
};

const Payment = React.forwardRef<HTMLDivElement, PaymentProps>(function Payment(
    {
        state,
        discountCodeState,
        group,
        cart,
        orderId,
        clientSecret,
        setClientSecret,
        checkoutState,
        setCheckoutState,
        handlePrevStep,
        closeModal,

        hideCloseButton = false,
        customPaymentHeading,
        onCancel,
        onGoBack,
        useSuccessAltText = false,
        handleSuccessRedirect,
        currentPage,
        isSignupGuestCheckout,

        isInline = false,
    },
    ref
) {
    const { t } = useTranslation();
    const { newToast } = useToastActionsContext();
    const { currentUser } = useCurrentUser();

    const isEvent = group?.subtype === CARD_EVENT;
    const _isEventFree = isEventFree(group);

    const shoppingCart = shoppingCartStore?.useTracked?.shoppingCarts()?.find(({ groupId }) => {
        return groupId === group?._id;
    });
    const shoppingCartProducts = shoppingCart?.products?.filter(({ product }) => {
        if (_isEventFree) return product?.type === PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP;

        return product?.type === PRODUCT_TYPES?.GROUP_MEMBERSHIP;
    });

    const handlePaymentIsComplete = () => {
        setCheckoutState(CHECKOUT_STATE.PENDING_FULFILLMENT);
        setClientSecret('');
    };

    const { data: userOrdersData, error: userOrdersError } = useGetUserOrdersForGroupQuery({
        variables: { groupId: group?._id },
        fetchPolicy: 'network-only',
        skip: !group?._id || checkoutState !== CHECKOUT_STATE.PENDING_FULFILLMENT,
        pollInterval: 1000,
    });

    // Used to refetch the event group after order has been fulfilled.
    const [getEventGroup] = useGetEventGroupLazyQuery({
        variables: {
            _id: group?._id,
        },
        fetchPolicy: 'network-only',
        onCompleted: () => {
            // TODO: could verify that the event group has verified the user has membership.
            setCheckoutState(CHECKOUT_STATE.ORDER_COMPLETE);
        },
        onError: error => {
            logger.error(error);
            // TODO: notify user there may have been an error
            setCheckoutState(CHECKOUT_STATE.ORDER_COMPLETE);
        },
    });

    useEffect(() => {
        if (currentUser && userOrdersError) {
            // TODO: Handle error state from fulfillment.
            newToast(
                <AlertToast
                    boldText={t(
                        `common:${localNamespace}.payment_error`,
                        'There was an error verifying the fulfillment of your order.'
                    )}
                    showWarningIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
            setCheckoutState(CHECKOUT_STATE.FULFILLMENT_ERROR);
        } else if (currentUser && userOrdersData?.getOrdersForUserForGroup) {
            // If the user has a successful purchase against this group, then the order succeeded.
            if (userOrdersData?.getOrdersForUserForGroup?.length > 0) {
                // TODO: To support multiple product purchases against a group, this should iterate through / filter the original query for the specific products the user tried to purchase
                // Refetching the group event ensures a cache update for group membership (doesUserHaveMembership, availableGroupActions, etc.)
                getEventGroup();
            }
            // successful ephemeral checkout
        } else if (!currentUser && checkoutState === CHECKOUT_STATE.PENDING_FULFILLMENT) {
            getEventGroup();
        }
    }, [userOrdersData, userOrdersError]);

    let showCloseButton = window.innerWidth > 991;
    if (hideCloseButton) showCloseButton = false;

    let total;
    const { quantity } = cart;
    const originalPrice = getTotal(cart.cartItem, quantity);

    if (discountCodeState.isApplied) {
        total = getTotal(cart.cartItem, quantity, discountCodeState.percentage);
    } else {
        total = getTotal(cart.cartItem, quantity);
    }

    let checkoutStateElement;

    let orderHeading = null;
    if (isEvent) {
        orderHeading = (
            <header>
                <p>Checkout</p>
            </header>
        );
    }

    if (customPaymentHeading) {
        orderHeading = customPaymentHeading;
    }

    switch (checkoutState) {
        default:
        case CHECKOUT_STATE.CHECKING_OUT:
            checkoutStateElement = (
                <>
                    {showCloseButton && (
                        <button
                            type="button"
                            onClick={() => closeModal()}
                            className="checkout-modal-btn-close"
                        >
                            <X className="checkout-modal-btn-x-dark" />
                        </button>
                    )}
                    {isEvent && (
                        <header>
                            <p>Checkout</p>
                        </header>
                    )}

                    {!isEvent && (
                        <OrderItemPreview
                            cartItem={cart.cartItem}
                            group={group}
                            total={total}
                            originalPrice={originalPrice}
                            discountCodeState={discountCodeState}
                            quantity={quantity}
                        />
                    )}

                    <div
                        className={`stripe-checkout-form-container !pb-0 ${!isEvent ? 'stripe-checkout-subs-form-container' : ''
                            }`}
                    >
                        <StripeCheckoutForm
                            clientSecret={clientSecret}
                            cartItem={cart.cartItem}
                            group={group}
                            discountCodeState={discountCodeState}
                            closeModal={closeModal}
                            handlePaymentIsComplete={handlePaymentIsComplete}
                            handlePrevStep={handlePrevStep}
                            quantity={quantity}
                            currentPage={currentPage}
                            isInline={isInline}
                        />
                    </div>

                    {isInline && isEvent && (
                        <ul className="w-full pl-0 pr-0 pb-[150px] h-[100vh] max-h-[500px] overflow-y-auto pt-6">
                            {shoppingCartProducts?.map(({ product, quantity: qty }) => {
                                return (
                                    <ShoppingCartSelectorItem
                                        key={product?._id}
                                        cartItem={product}
                                        qty={qty}
                                        group={group}
                                        allowEdit={false}
                                        showMinified
                                    />
                                );
                            })}
                        </ul>
                    )}

                    {!isInline &&
                        isEvent &&
                        shoppingCartProducts?.map(({ product, quantity: qty }) => {
                            return (
                                <ShoppingCartSelectorItem
                                    key={product?._id}
                                    cartItem={product}
                                    qty={qty}
                                    group={group}
                                    allowEdit={false}
                                    showMinified
                                />
                            );
                        })}
                </>
            );
            break;
        case CHECKOUT_STATE.PENDING_FULFILLMENT:
            checkoutStateElement = <PendingFulfillment closeModal={closeModal} />;
            break;
        case CHECKOUT_STATE.FULFILLMENT_ERROR:
            // TODO: show a retry for an unfufilled order?
            break;
        case CHECKOUT_STATE.ORDER_COMPLETE:
            if (isSignupGuestCheckout) {
                // so that this is pre-filled when the user clicks a "Create Account" button
                signUpStore.set.email(state.email);

                checkoutStateElement = (
                    <div className="flex flex-col gap-[20px]">
                        <h1 className="m-0 text-[24px] md:text-[32px] leading-[34px] tracking[1px]">
                            Thank you for registering!
                        </h1>
                        <span className="text-[17px] leading-[24px] tracking-[0.25px] text-grayscale-body">
                            A confirmation email with your tickets has been sent.
                        </span>
                        <StripeOrderDetails
                            orderId={orderId}
                            isSignupGuestCheckout={isSignupGuestCheckout}
                        />
                        <span className="text-[17px] leading-[24px] tracking-[0.25px] text-grayscale-body">
                            You are registered as a guest. Would you like to create an account?
                        </span>
                        <SignUpPageButton
                            onClick={() => {
                                signUpStore.set.isGuestCheckout(false);
                            }}
                        >
                            Sign Up
                        </SignUpPageButton>
                    </div>
                );
            } else {
                checkoutStateElement = (
                    <CheckoutSuccess
                        closeModal={closeModal}
                        orderId={orderId}
                        group={group}
                        total={total}
                        cartItem={cart?.cartItem}
                        state={state}
                        hideCloseButton={hideCloseButton}
                        useSuccessAltText={useSuccessAltText}
                        onClick={handleSuccessRedirect}
                    />
                );
            }
            break;
    }

    return (
        <section ref={ref} className="checkout-modal">
            {checkoutStateElement}
        </section>
    );
});

export default Payment;
