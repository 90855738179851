import { SearchFilterType, SearchScopeType } from '@shared/welibrary-graphql/search/queries.hook';

import { isKey } from '@helpers/types/type.helpers';

import Content from '@web/ui/dsc/svgs/Content';
import Check from '@web/ui/dsc/svgs/Check';
import Newsfeed from '@web/ui/dsc/svgs/Newsfeed';
import Group from '@web/ui/dsc/svgs/Group';
import Person from '@web/ui/dsc/svgs/Person';

import {
    CARD_BLOG,
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_COURSE,
    CARD_DOCUMENT,
    CARD_EVENT,
    CARD_IMAGE,
    CARD_LIBRARY,
    CARD_LINK,
    CARD_MOBILIZE,
    CARD_PLEDGE,
    CARD_POSTBOX,
    CARD_SHELF,
    CARD_TEXT,
    CARD_VIDEO,
} from '@core/utilities/constants/card_types.js';

import { SearchResult } from '@shared/welibrary-graphql/types';

// todo: add translations
export const displayNames = {
    [CARD_IMAGE]: 'Photo',
    [CARD_VIDEO]: 'Video',
    [CARD_TEXT]: 'Posted',
    [CARD_BLOG]: 'Blog',
    [CARD_EVENT]: 'Event',
    [CARD_COURSE]: 'Course',
    [CARD_MOBILIZE]: 'Job',
    [CARD_PLEDGE]: 'Pledge',
    [CARD_LINK]: 'Link',
    [CARD_DOCUMENT]: 'Document',
    [CARD_POSTBOX]: 'Prompt',
    [CARD_LIBRARY]: 'Library',
    [CARD_SHELF]: 'Shelf',
    [CARD_CHAPTER]: 'Channel',
    [CARD_BOOK]: 'Book',
    User: 'User',
    Group: 'Group',
};

export const FILTER_TYPES = {
    USER: SearchFilterType.User,
    GROUP: SearchFilterType.Group,
    COLLECTION: SearchFilterType.Collection,
    CHAPTER: SearchFilterType.Chapter,
    POST: SearchFilterType.Post,
    PROMPT: SearchFilterType.Prompt,
    COMMENT: SearchFilterType.Comment,
    MESSAGE: SearchFilterType.Message,
};

export const DEFAULT_FILTERS = [
    FILTER_TYPES.USER,
    FILTER_TYPES.GROUP,
    // FILTER_TYPES.MESSAGE,
    FILTER_TYPES.COLLECTION,
    FILTER_TYPES.POST,
    FILTER_TYPES.PROMPT,
];

export const FILTERS_META = {
    All: { icon: Check, text: 'common:global.nouns.all', filter: <SearchFilterType[]>[] },
    Profiles: { icon: Person, text: 'common:global.nouns.profiles', filter: [FILTER_TYPES.USER] },
    Groups: { icon: Group, text: 'common:global.nouns.groups', filter: [FILTER_TYPES.GROUP] },
    Channels: {
        icon: Newsfeed,
        text: 'common:global.nouns.channels',
        filter: [FILTER_TYPES.CHAPTER],
    },
    Cards: { icon: Content, text: 'common:global.nouns.content', filter: [FILTER_TYPES.POST] },
};

export const timer = (ms: number) => {
    return new Promise(res => setTimeout(res, ms));
};

export const slugify = (string: string) => {
    const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;';
    const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};

export const SCOPE_TYPE = {
    GROUP: SearchScopeType.Group,
    CONTENTCARD: SearchScopeType.Contentcard,
};

// TODO the typing of SearchResult doesn't seem right...
export const getFilterType = (searchResult: SearchResult) => {
    const entity = searchResult?.entity;
    const isContentCard = searchResult.type === 'CONTENTCARD';
    const isChapter = entity?.type === 'chapter';
    const isBook = entity?.type === 'book';
    const isLibrary = entity?.type === 'library';
    const isChannel = entity?.type === 'channel';
    const isComment = searchResult.type === 'COMMENT';
    const isCollectionType = isContentCard && (isChapter || isBook || isLibrary || isChannel);

    if (entity?.__typename === 'User') return FILTER_TYPES.USER;
    if (entity?.__typename === 'Group') return FILTER_TYPES.GROUP;
    if (isChapter && entity?.prompt) return FILTER_TYPES.PROMPT;
    if (isCollectionType) return FILTER_TYPES.COLLECTION;
    if (isContentCard && !isCollectionType) return FILTER_TYPES.POST;
    if (searchResult.type === 'MESSAGES') return FILTER_TYPES.MESSAGE;
    if (isComment) return FILTER_TYPES.COMMENT;
};

const typesToScope = {
    chapter: SCOPE_TYPE.CONTENTCARD,
    book: SCOPE_TYPE.CONTENTCARD,
    library: SCOPE_TYPE.CONTENTCARD,
    group: SCOPE_TYPE.GROUP,
};

export const getScopeType = (type: string) => {
    if (isKey(typesToScope, type)) {
        return typesToScope[type];
    }
    return undefined;
};
