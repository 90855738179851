export enum EventsPageTab {
    events_in_my_groups = 'events_in_my_groups',
    bookmarked = 'bookmarked',
    my_events = 'my_events',
}

export enum EventsPageFilter {
    today = 'today',
    next_seven_days = 'next_seven_days',
    upcoming_events = 'upcoming_events',
    past_events = 'past_events',
    all_events = 'all_events',
}

export enum EventsPageOrder {
    date_ascending = 'date_ascending',
    date_descending = 'date_descending',
    recently_published = 'recently_published',
}

export enum EventsPageLocationType {
    all = 'all',
    in_person = 'in_person',
    virtual = 'virtual',
}

export enum ActiveFilter {
    search = 'search',
    location = 'location',
    locationType = 'locationType',
}
