import { getNameWithLastNameFirst } from '@components/profile/helpers';
import { CustomerOrder } from '@shared/welibrary-graphql/types';

export const getSortedTicketsWithLastNameFirst = (orders: CustomerOrder[]) => {
    let tickets = orders.flatMap(o => o.productInstances);

    // Display lastName, firstName [other names]
    tickets = tickets.map(t => {
        const nameWithLastNameFirst = getNameWithLastNameFirst(t.owner.profile.full_name);

        return {
            ...t,
            owner: {
                ...t.owner,
                profile: { ...t.owner.profile, full_name: nameWithLastNameFirst },
            },
        };
    });

    // Sort alphabetically by last name
    tickets.sort((a, b) =>
        a.owner.profile.full_name
            .toLowerCase()
            .localeCompare(b.owner.profile.full_name.toLowerCase())
    );

    return tickets;
};

export const getCsvOfTickets = (orders: CustomerOrder[]) => {
    const tickets = [];

    orders?.forEach((order: CustomerOrder) => {
        const purchaseRequests = order?.purchaseRequests;
        const productInstances = order?.productInstances;
        const customer = order?.user;

        // check if an order has been refunded
        const hasBeenRefunded = order?.paymentIntent?.charges?.data?.[0]?.refunded ?? false;

        // if tickets exist map over the productIntances
        if (order?.productInstances?.length > 0) {
            productInstances?.forEach(productInstance => {
                const product = productInstance?.product;

                tickets.push({
                    owner: productInstance.owner,
                    product: {
                        ...product,
                    },
                    qty: 1,
                    isCheckedIn: productInstance?.isCheckedIn,
                    refunded: false, // default to false , since tickets DO exists
                });
            });

            // if no tickets exist map over the purchaseRequests
            // refunds will be grouped by ticket type
        } else {
            purchaseRequests?.forEach(purchaseRequest => {
                const product = purchaseRequest?.product;

                tickets.push({
                    owner: customer,
                    product: {
                        ...product,
                    },
                    qty: purchaseRequest?.quantity,
                    isCheckedIn: false, // default to false , since NO tickets exists
                    refunded: hasBeenRefunded ? true : false,
                });
            });
        }
    });

    let csv = 'Name,Email,Phone,Ticket Name,Checked In, Refunded';

    tickets.forEach(ticket => {
        const { full_name, phone, email } = ticket.owner.profile;
        csv = `${csv}\n"${full_name.trim()}","${email}","${phone ?? ''}","${ticket?.product
            ?.name}","${ticket?.isCheckedIn ? 'Y' : 'N'}","${ticket?.refunded ? 'Y' : 'N'}"`;
    });

    return csv;
};
