import React from 'react';
import { withTranslation } from 'react-i18next';

import NavigationContainer from '@components/navigation/NavigationContainer';
import LoadingIndicator from '@components/generic/loading/LoadingIndicator';
import IntroContent from '@components/generic/IntroContent';
import AppUrlListener from '../AppUrlListener';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

/**
 * The React Router client side routing definitions.
 * @namespace Client.Routes
 * @desc This is the main definition for the react router.
 */
class MainLoading extends React.Component {
    // <Router history={browserHistory}>
    //Use BrowserRouter from react router dom - new in React Router 4,
    //Don't have to explicity make history object anymore among other things
    //see this for some differences in react router types
    //https://stackoverflow.com/questions/51974369/hashrouter-vs-browserrouter

    render() {
        let { t, error, userToken } = this.props;

        return (
            <BrowserRouter>
                <AppUrlListener />
                {/* Switch Navigation Containers based on login/signup/other page */}
                <Switch>
                    <Route
                        path="/join"
                        render={props => (
                            <NavigationContainer
                                showLogin={true}
                                showSignup={false}
                                location={location}
                                loggedInUser={null}
                                hideSecondaryNavigation={!userToken}
                            />
                        )}
                    />
                    <Route
                        path="/login"
                        render={props => (
                            <NavigationContainer
                                showLogin={false}
                                showSignup={true}
                                location={location}
                                loggedInUser={null}
                                hideSecondaryNavigation={!userToken}
                            />
                        )}
                    />
                    <Route
                        path="/signup"
                        render={props => (
                            <NavigationContainer
                                showLogin={true}
                                showSignup={false}
                                location={location}
                                loggedInUser={null}
                                hideSecondaryNavigation={!userToken}
                            />
                        )}
                    />
                    <Route
                        path="/signup-group"
                        render={props => (
                            <NavigationContainer
                                showLogin={true}
                                showSignup={false}
                                location={location}
                                loggedInUser={null}
                                hideSecondaryNavigation={!userToken}
                            />
                        )}
                    />

                    <Route
                        path="/messages"
                        render={props => (
                            <NavigationContainer
                                showLogin={false}
                                showSignup={false}
                                enableBackButton={true}
                                location={location}
                                hidePrimaryNavOnMobile={true}
                                loggedInUser={null}
                            />
                        )}
                    />
                    <Route
                        path="/discover"
                        render={props => (
                            <NavigationContainer
                                showLogin={false}
                                showSignup={false}
                                enableBackButton={true}
                                location={location}
                                hidePrimaryNavOnMobile={true}
                                loggedInUser={null}
                            />
                        )}
                    />
                    <Route
                        path="/home"
                        render={props => (
                            <NavigationContainer
                                showLogin={false}
                                showSignup={false}
                                enableBackButton={true}
                                location={location}
                                hidePrimaryNavOnMobile={true}
                                loggedInUser={null}
                                hideSecondaryNavigation={!userToken}
                            />
                        )}
                    />
                    <Route
                        path="/g"
                        render={props => (
                            <NavigationContainer
                                showLogin={false}
                                showSignup={false}
                                enableBackButton={true}
                                location={location}
                                hidePrimaryNavOnMobile={true}
                                loggedInUser={null}
                            />
                        )}
                    />
                    <Route
                        path=""
                        render={props => (
                            <NavigationContainer
                                showLogin={true}
                                showSignup={false}
                                enableBackButton={false}
                                location={location}
                                loggedInUser={null}
                                hideSecondaryNavigation={!userToken}
                            />
                        )}
                    />
                </Switch>

                <Switch>
                    {/*404 Not Found Route */}
                    <Route
                        render={props =>
                            error ? (
                                <>
                                    <IntroContent
                                        declaration={t(
                                            `common:global.introContentErr.offline.declaration`
                                        )}
                                        instruction={t(
                                            `common:global.introContentErr.offline.instruction`
                                        )}
                                        type="offline"
                                        topPadding="20px"
                                    />
                                    <pre>{JSON.stringify(error, null, '    ')}</pre>
                                </>
                            ) : (
                                <LoadingIndicator type="skeleton" size="normal" repeat topPadding />
                            )
                        }
                    />
                </Switch>
            </BrowserRouter>
            // </Router>
        );
    }
}

export default withTranslation()(MainLoading);
