import React from 'react';

import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';

import useGroupActions from '@components/group/hooks/useGroupActions';

import GroupRegisterButton from '@components/group/groupdashboard/GroupRegisterButton';

import { isEventInThePast, isEventFree } from '@helpers/events/event.helpers';
import { groupSellsProductsButCantAcceptPayments } from '@components/group/groupdashboard/groupDashboardHelpers';

import { GROUP_ACTIONS } from '@core/utilities/constants/group';
import { User, Group } from '@shared/welibrary-graphql/types';

const GroupDashboardRSVPButton: React.FC<{
    group: Group;
    currentUser: User;
    customButtonClass?: string;
}> = ({ group, currentUser, customButtonClass = '' }) => {
    const { takeGroupActionForUser, primaryActionAvailable } = useGroupActions({
        group,
        refetchQueries: [GET_GROUP_DASHBOARD],
    });

    if (!takeGroupActionForUser || !primaryActionAvailable?.action) return <></>;

    const handleTakePrimaryAction = () => {
        takeGroupActionForUser(currentUser?._id, primaryActionAvailable?.action);
    };

    const eventIsOver = isEventInThePast(group);
    const eventIsFree = isEventFree(group);

    // Moved the check for eventIsOver here to ensure the button is not rendered if the event is over
    if (eventIsOver) {
        return <></>;
    }

    if (
        primaryActionAvailable.useRegisterButton &&
        eventIsFree &&
        group?.settings?.approvalRequired === false
    ) {
        return <GroupRegisterButton onClick={handleTakePrimaryAction} />;
    }

    let showIcon = true;
    let showExclamation = false;
    let rsvpIconClass = 'event-dashboard-rsvp-btn-icon-checkmark';
    const merchantAccountNotActive = groupSellsProductsButCantAcceptPayments(group);

    if (
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_PURCHASE_ACCESS ||
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS ||
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_EDIT_PRODUCTS ||
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_LOGGEDOUT_PURCHASE_ACCESS
    ) {
        rsvpIconClass = 'event-dashboard-rsvp-btn-icon-ticket';
    } else if (
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_REQUEST_JOIN ||
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN ||
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_REQUEST_TO_PURCHASE ||
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_JOIN
    ) {
        showIcon = false;
    }

    if (
        primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_LOGGEDOUT_PURCHASE_ACCESS &&
        group.settings.approvalRequired
    ) {
        return null;
    }

    if (
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_EDIT_PRODUCTS &&
        merchantAccountNotActive
    ) {
        showExclamation = true;
    }

    return (
        <button
            type="button"
            id="home-header-switcher"
            className={`event-dashboard-btn ${customButtonClass}`}
            onClick={handleTakePrimaryAction}
        >
            {showExclamation && <div className="event-exclamation">!</div>}
            {showIcon && <div className={`event-dashboard-btn-circle ${rsvpIconClass}`} />}
            <div className="event-dashboard-btn-text">{primaryActionAvailable?.label}</div>
        </button>
    );
};

export default GroupDashboardRSVPButton;