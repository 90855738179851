import React from 'react';
import { Flipped } from 'react-flip-toolkit';

type ButtonProps = {
    onClick: () => void;
    className?: string;
    disabled?: boolean;
    type?: 'icon' | 'small' | 'medium' | 'large';
    flipId?: string | number;
    children?: React.ReactNode;
};

const Button: React.FC<ButtonProps> = ({
    onClick,
    className = '',
    disabled = false,
    type = 'icon',
    flipId,
    children,
}) => {
    if (flipId === undefined) {
        return (
            <button
                type="button"
                onClick={onClick}
                className={`dsc-forms-buttons-button dsc-${type} ${className}`}
                disabled={disabled}
            >
                {children}
            </button>
        );
    }

    return (
        <Flipped flipId={flipId}>
            <button
                type="button"
                onClick={onClick}
                className={`dsc-forms-buttons-button dsc-${type} ${className}`}
                disabled={disabled}
            >
                {children}
            </button>
        </Flipped>
    );
};

export default Button;
