import React from 'react';
import MiniAvatar from '@components/profile/Lego/MiniAvatar';

const TreasureHeader: React.FC = () => {
    return (
        <section className="treasure-chest-header">
            <div className="avatar-display">
                <MiniAvatar circleClass="treasure-avatar" />
            </div>
            <h1 className="header-text">Treasure Chest</h1>
        </section>
    );
};

export default TreasureHeader;
