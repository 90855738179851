import React, { useState } from 'react';
import { Capacitor } from '@capacitor/core';

import ExternalLink from '@web/ui/components/generic/ExternalLink';
import ErrorItem from '@web/ui/components/card/mediacard/bodyitems/ErrorItem.js';
import ReactPlayer from 'react-player/lazy';
import { getEmbedUrl } from '@web/utilities/helpers/video.helpers';
import {
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

type VideoItemProps = {
    media: any;
    links: any;
    onClickCallback: () => any;
};

const VideoItem: React.FC<VideoItemProps> = ({ media, links, onClickCallback }) => {
    const [showVideo, setShowVideo] = useState(false);

    const embedUrl = links?.source;

    if (!embedUrl) return <ErrorItem />; // TODO: Update to video error item

    const mediaThumb = media?.thumb || getRandomDefaultCoverImage(embedUrl);
    const [src, srcSet] = getBackgroundImageSetForUrl(mediaThumb);

    const videoPlaceholder = (
        <>
            <div
                className="vid-lightbox-embed-link w-embed w-iframe w-script cursor-pointer"
                onClick={() => {
                    setShowVideo(true);
                    onClickCallback();
                }}
            />
            <div
                className="mc-play-button larger-button"
                onClick={() => {
                    setShowVideo(true);
                    onClickCallback();
                }}
            />
        </>
    );

    return (
        <div className="mc-media-item videoitem">
            <StyledBackgroundImage className="mc-video-thumb" src={src} srcSet={srcSet}>
                <div className="mc-video-button-container player-wrapper">
                    {showVideo ? (
                        <ReactPlayer
                            className="react-player"
                            url={embedUrl}
                            pip
                            controls
                            playing={showVideo}
                            width="100%"
                            height="100%"
                        />
                    ) : (
                        videoPlaceholder
                    )}
                </div>
            </StyledBackgroundImage>
        </div>
    );
};

export default VideoItem;
