import { MY_COMMUNITY, MY_GROUPS } from '@shared/welibrary-graphql/user/queries';
import { MY_BOOKMARKS, MY_EVENTS, MY_PLEDGES } from '@shared/welibrary-graphql/content_card/queries';

import {
    CARD_DOCUMENT,
    CARD_EVENT,
    CARD_IMAGE,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_MAP,
    CARD_PLEDGE,
    CARD_TEXT,
    CARD_TYPES,
    CARD_VIDEO,
} from '@core/utilities/constants/card_types.js';

import { FILTER_AZ, FILTER_LIVE } from '@core/utilities/constants/library_settings';

const communityNamespace = 'imports.wlWeb.ui.components.community';

const REC_CONTENT_CARD_TYPES = [
    CARD_IMAGE,
    CARD_IMAGE_SQUARE,
    CARD_DOCUMENT,
    CARD_LINK,
    CARD_TEXT,
    CARD_VIDEO,
    CARD_PLEDGE,
    CARD_EVENT,
    CARD_MAP,
];
const REC_POSTING_CARD_TYPES = [
    CARD_TYPES.CARD_MOBILIZATION,
    CARD_TYPES.CARD_MOBILIZE,
    CARD_TYPES.CARD_POSTING,
];

// Aligns with graphql schema
const COMMUNITY_TYPE_CONNECTIONS = 'connections',
    COMMUNITY_TYPE_FOLLOWERS = 'followers',
    COMMUNITY_TYPE_FOLLOWING = 'following',
    COMMUNITY_TYPE_SHAREDGROUPS = 'shared_groups',
    COMMUNITY_TYPE_ALLPEOPLE = 'all_people';

const I18N_COMMUNITY_TYPE_CONNECTIONS = t => t(`common:${communityNamespace}.connections`);
const I18N_COMMUNITY_TYPE_FOLLOWERS = t => t(`common:${communityNamespace}.followers`);
const I18N_COMMUNITY_TYPE_FOLLOWING = t => t(`common:${communityNamespace}.following`);

const COMMUNITY_VIEW_COMMUNITY = 'view_community',
    COMMUNITY_VIEW_GROUPS = 'view_groups',
    // COMMUNITY_VIEW_ORGANIZATIONS    = 'view_organizations',
    COMMUNITY_VIEW_PROJECTS = 'view_projects',
    COMMUNITY_VIEW_EVENTS = 'view_events',
    COMMUNITY_VIEW_BOOKMARKS = 'view_bookmarks',
    COMMUNITY_VIEW_PLEDGES = 'view_pledges',
    COMMUNITY_VIEW_CONTENT = 'view_content',
    COMMUNITY_VIEW_POSTINGS = 'view_postings';

const DISCOVER_URLS = {
    COMMUNITY: 'community',
    GROUPS: 'groups',
    // ORGANIZATIONS: 'orgs',
    PROJECTS: 'projects',
    EVENTS: 'events',
    ALLPEOPLE: 'people',
    SHAREDGROUPS: 'grouped',
    CONNECTIONS: 'connections',
    FOLLOWERS: 'followers',
    FOLLOWING: 'following',
    BOOKMARKS: 'bookmarks',
    PLEDGES: 'pledges',
    POSTINGS: 'postings',
    CONTENT: 'content',
};

const RECOMMENDATION_TYPE = {
    USER: 'recs_user',
    CARD: 'recs_card',
};

const COMMUNITY_PRIMARY_MENU_OPTIONS = t => {
    return [
        {
            _id: COMMUNITY_VIEW_COMMUNITY,
            url: DISCOVER_URLS.COMMUNITY,
            name: t(`common:${communityNamespace}.community`),
            icon: 'person',
            darkIcon: 'world-white',
            query: MY_COMMUNITY,
            dataTarget: 'myCommunity',
            resultsTarget: 'results',
            secondaryMenuOptions: [
                COMMUNITY_TYPE_ALLPEOPLE,
                COMMUNITY_TYPE_SHAREDGROUPS,
                COMMUNITY_TYPE_CONNECTIONS,
                COMMUNITY_TYPE_FOLLOWERS,
                COMMUNITY_TYPE_FOLLOWING,
            ],
            availableFilters: [
                { _id: FILTER_AZ, name: 'A-Z' },
                { _id: FILTER_LIVE, name: t(`common:global.most_recent`) },
                // {_id:FILTER_BEST_MATCH, name: 'Best Match'}
            ],
            recommendations: {
                stickyLabel: t(`common:${communityNamespace}.people_you_should_know`),
                type: RECOMMENDATION_TYPE.USER,
                variables: {
                    input: {
                        domains: [
                            {
                                type: 'group',
                            },
                        ],
                        types: ['USER'],
                        filters: null,
                        limit: 25,
                    },
                },
            },
        },
        {
            _id: COMMUNITY_VIEW_GROUPS,
            url: DISCOVER_URLS.GROUPS,
            name: t(`common:${communityNamespace}.groups`),
            icon: 'people',
            darkIcon: 'people-white',
            query: MY_GROUPS,
            dataTarget: 'myGroups',
            resultsTarget: 'results',
            secondaryMenuOptions: [],
            availableFilters: [
                { _id: FILTER_AZ, name: 'A-Z' },
                { _id: FILTER_LIVE, name: t(`common:global.most_recent`) },
                // {_id:FILTER_BEST_MATCH, name: 'Best Match'}
            ],
            recommendations: {
                stickyLabel: t(`common:${communityNamespace}.shared_interests_groups`),
                type: RECOMMENDATION_TYPE.CARD,
                variables: {
                    input: {
                        domains: [
                            {
                                type: 'group',
                            },
                        ],
                        types: ['GROUP'],
                        filters: null,
                        limit: 25,
                    },
                    // cardQuery: JSON.stringify({
                    //   type: CARD_SHELF,
                    //   group: { $exists: true }
                    // })
                },
            },
        },
        {
            _id: COMMUNITY_VIEW_BOOKMARKS,
            url: DISCOVER_URLS.BOOKMARKS,
            name: t(`common:${communityNamespace}.bookmarks`),
            icon: 'bookmark',
            darkIcon: 'white-bookmark-filled',
            query: MY_BOOKMARKS,
            dataTarget: 'myBookmarks',
            resultsTarget: 'results',
            secondaryMenuOptions: [],
            availableFilters: [
                { _id: FILTER_AZ, name: 'A-Z' },
                { _id: FILTER_LIVE, name: 'Most Recent' },
            ],
            // recommendations: {},
            displayFullCards: true,
            showReferenceHeader: true,
        },
        {
            _id: COMMUNITY_VIEW_EVENTS,
            url: DISCOVER_URLS.EVENTS,
            name: t(`common:${communityNamespace}.events`),
            icon: 'calendar',
            darkIcon: 'calendar-white',
            query: MY_EVENTS,
            dataTarget: 'myEvents',
            resultsTarget: 'results',
            secondaryMenuOptions: [],
            availableFilters: [
                { _id: FILTER_AZ, name: 'A-Z' },
                { _id: FILTER_LIVE, name: t(`common:global.most_recent`) },
                // {_id:FILTER_BEST_MATCH, name: 'Best Match'}
            ],
            // recommendations: {
            //   stickyLabel: t(
            //     `common:imports.wlWeb.ui.components.community.events_of_interest`
            //   ),
            //   type: RECOMMENDATION_TYPE.CARD,
            //   variables: {
            //     cardQuery: JSON.stringify({ type: CARD_EVENT })
            //     // TODO: eventDate in future...
            //   }
            // }
        },
        {
            _id: COMMUNITY_VIEW_PLEDGES,
            url: DISCOVER_URLS.PLEDGES,
            name: t(`common:${communityNamespace}.pledges`),
            icon: 'white-rocketship inverted',
            darkIcon: 'white-rocketship',
            query: MY_PLEDGES,
            dataTarget: 'myPledges',
            resultsTarget: 'results',
            secondaryMenuOptions: [],
            availableFilters: [{ _id: FILTER_LIVE, name: t(`common:global.most_recent`) }],
            displayFullCards: true,
            // recommendations: {
            //   stickyLabel: t(
            //     `common:imports.wlWeb.ui.components.community.events_of_interest`
            //   ),
            //   type: RECOMMENDATION_TYPE.CARD,
            //   variables: {
            //     cardQuery: JSON.stringify({ type: CARD_EVENT })
            //     // TODO: eventDate in future...
            //   }
            // }
        },
        {
            _id: COMMUNITY_VIEW_CONTENT,
            url: DISCOVER_URLS.CONTENT,
            name: 'Content',
            icon: 'white-rocketship inverted',
            darkIcon: 'white-rocketship',
            query: null,
            dataTarget: null,
            resultsTarget: null,
            secondaryMenuOptions: [],
            availableFilters: [{ _id: FILTER_LIVE, name: t(`common:global.most_recent`) }],
            displayFullCards: true,

            recommendations: {
                stickyLabel: 'Content',
                type: RECOMMENDATION_TYPE.CARD,
                variables: {
                    input: {
                        domains: [
                            {
                                type: 'group',
                            },
                        ],
                        types: ['CONTENT_CARD'],
                        filters: {
                            cardTypes: REC_CONTENT_CARD_TYPES,
                        },
                        limit: 10,
                    },
                },
            },
        },
        {
            _id: COMMUNITY_VIEW_POSTINGS,
            url: DISCOVER_URLS.POSTINGS,
            name: 'Mobilization',
            icon: 'white-rocketship inverted',
            darkIcon: 'white-rocketship',
            query: null,
            dataTarget: null,
            resultsTarget: 'results',
            secondaryMenuOptions: [],
            availableFilters: [{ _id: FILTER_LIVE, name: t(`common:global.most_recent`) }],
            displayFullCards: true,

            recommendations: {
                stickyLabel: 'Mobilization',
                type: RECOMMENDATION_TYPE.CARD,
                variables: {
                    input: {
                        domains: [
                            {
                                type: 'group',
                            },
                        ],
                        types: ['CONTENT_CARD'],
                        filters: {
                            cardTypes: REC_POSTING_CARD_TYPES,
                        },
                        limit: 10,
                    },
                },
            },
        },
    ];
};

const COMMUNITY_SECONDARY_MENU_OPTIONS = t => {
    return [
        {
            _id: COMMUNITY_TYPE_ALLPEOPLE,
            url: DISCOVER_URLS.ALLPEOPLE,
            name: t(`common:imports.wlWeb.ui.components.community.all_people`),
        },
        {
            _id: COMMUNITY_TYPE_SHAREDGROUPS,
            url: DISCOVER_URLS.SHAREDGROUPS,
            name: t(`common:imports.wlWeb.ui.components.community.shared_groups`),
        },
        {
            _id: COMMUNITY_TYPE_CONNECTIONS,
            url: DISCOVER_URLS.CONNECTIONS,
            name: t(`common:imports.wlWeb.ui.components.community.my_connections`),
        },
        {
            _id: COMMUNITY_TYPE_FOLLOWING,
            url: DISCOVER_URLS.FOLLOWING,
            name: t(`common:imports.wlWeb.ui.components.community.following`),
        },
        {
            _id: COMMUNITY_TYPE_FOLLOWERS,
            url: DISCOVER_URLS.FOLLOWERS,
            name: t(`common:imports.wlWeb.ui.components.community.my_followers`),
        },
    ];
};

export {
    COMMUNITY_TYPE_CONNECTIONS,
    COMMUNITY_TYPE_FOLLOWERS,
    COMMUNITY_TYPE_FOLLOWING,
    COMMUNITY_VIEW_COMMUNITY,
    COMMUNITY_VIEW_GROUPS,
    COMMUNITY_TYPE_SHAREDGROUPS,
    COMMUNITY_TYPE_ALLPEOPLE,
    COMMUNITY_VIEW_PROJECTS,
    COMMUNITY_VIEW_EVENTS,
    COMMUNITY_VIEW_PLEDGES,
    COMMUNITY_PRIMARY_MENU_OPTIONS,
    COMMUNITY_SECONDARY_MENU_OPTIONS,
    DISCOVER_URLS,
    RECOMMENDATION_TYPE,
};
