import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import useModal from '@components/modals/hooks/useModal';

import ConnectedAccountStripeForm from '@components/group/groupdashboard/stripe/ConnectedAccountStripeForm';
import UpdateConnectedAccountStripeForm from '@components/group/groupdashboard/stripe/UpdateConnectedAccountStripeForm';

import TicketsFormContainer from '@components/content/newpost/forms/ShoppingCartProducts/TicketsFormContainer';
import EditTicketsFormContainer from '@components/content/newpost/forms/ShoppingCartProducts/EditTicketsFormContainer';

import { Tabs } from '@components/content/newpost/forms/ShoppingCartProducts/ticketHelpers';
import { Group } from '@shared/welibrary-graphql/types';
import { CARD_EVENT } from '@core/utilities/constants/card_types';
import PRODUCT_TYPES from './constants';

const localNamespace = 'imports.wlWeb.ui.components.stripe';

const EditTickets: React.FC<{ group: Group }> = ({ group }) => {
    const { t } = useTranslation();
    const { closeModal } = useModal();

    const merchantAccountFullyActivated =
        group?.paymentSettings?.stripeAccount?.charges_enabled &&
        group?.paymentSettings?.stripeAccount?.details_submitted &&
        group?.paymentSettings?.stripeAccount?.payouts_enabled;

    const [activeTab, setActiveTab] = useState<string>(
        merchantAccountFullyActivated ? Tabs.Tickets : Tabs.Merchant
    );

    const isEvent = group?.subtype === CARD_EVENT;
    const productTypeText = isEvent
        ? `${t(`common:${localNamespace}.tickets`, 'Tickets')}`
        : 'Subscriptions';

    let products = [];
    if (isEvent) products = group?.products;

    // filter out freebie subscriptions from showing up
    // when editing subscriptions
    if (!isEvent) {
        products = group?.products?.filter(
            product => product?.type !== PRODUCT_TYPES.GROUP_FREE_SUBSCRIPTION
        );
    }

    return (
        <div style={{ padding: '12px 16px' }}>
            <div className="edit-tickets-tab-switch-wrap">
                <button
                    onClick={() => setActiveTab(Tabs.Tickets)}
                    type="button"
                    className={`${
                        activeTab === Tabs.Tickets
                            ? 'edit-tickets-tab-switch-btn edit-tickets-tab-switch-btn-active'
                            : 'edit-tickets-tab-switch-btn edit-tickets-tab-switch-btn-adjustment-right'
                    }`}
                >
                    {productTypeText}
                </button>
                <button
                    onClick={() => setActiveTab(Tabs.Merchant)}
                    type="button"
                    className={`${
                        activeTab === Tabs.Merchant
                            ? 'edit-tickets-tab-switch-btn edit-tickets-tab-switch-btn-active'
                            : 'edit-tickets-tab-switch-btn edit-tickets-tab-switch-btn-adjustment-left'
                    }`}
                >
                    {`${t(`common:${localNamespace}.merchant`, 'Merchant')}`}
                </button>
            </div>
            {activeTab === Tabs.Tickets && group && products?.length > 0 && (
                <EditTicketsFormContainer
                    group={group}
                    merchantAccountFullyActivated={merchantAccountFullyActivated}
                    inEditMode
                />
            )}

            {activeTab === Tabs.Tickets && (!products || isEmpty(products)) && (
                <TicketsFormContainer
                    group={group}
                    merchantAccountFullyActivated={merchantAccountFullyActivated}
                    activateMerchantAccount={() => setActiveTab(Tabs.Merchant)}
                />
            )}

            {activeTab === Tabs.Merchant && !group?.paymentSettings?.stripeAccount && (
                <ConnectedAccountStripeForm group={group} />
            )}

            {activeTab === Tabs.Merchant && group?.paymentSettings?.stripeAccount && (
                <UpdateConnectedAccountStripeForm group={group} handleClose={closeModal} />
            )}
        </div>
    );
};

export default EditTickets;
