import React from 'react';

const NoteIcon: React.FC<{ className?: string; strokeWidth?: string }> = ({
    className,
    strokeWidth = '3',
}) => (
    <svg className={className} viewBox="0 0 40 40" strokeWidth={strokeWidth} stroke="currentcolor">
        <path d="M15 16.875H25" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 23.125H18.125" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M24.4822 33.75H7.5C7.16848 33.75 6.85054 33.6183 6.61612 33.3839C6.3817 33.1495 6.25 32.8315 6.25 32.5V7.5C6.25 7.16848 6.3817 6.85054 6.61612 6.61612C6.85054 6.3817 7.16848 6.25 7.5 6.25H32.5C32.8315 6.25 33.1495 6.3817 33.3839 6.61612C33.6183 6.85054 33.75 7.16848 33.75 7.5V24.4822C33.75 24.6464 33.7177 24.8089 33.6548 24.9606C33.592 25.1122 33.5 25.25 33.3839 25.3661L25.3661 33.3839C25.25 33.5 25.1122 33.592 24.9606 33.6548C24.8089 33.7177 24.6464 33.75 24.4822 33.75V33.75Z"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M33.637 24.3737H24.375V33.6362"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default NoteIcon;
