import React from 'react';

import ExternalLink from '@web/ui/components/generic/ExternalLink';

import { useShareLink } from '@components/generic/hooks/useShareLink';

import {
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import { Capacitor } from '@capacitor/core';

const LinkItem = props => {
    const { shareLink } = useShareLink();

    const handleContextMenu = (e, mainLink, displayLink) => {
        if (Capacitor.isNativePlatform()) {
            e.preventDefault();
            shareLink(mainLink, displayLink, ``);
        }
    };

    const extractLinkHost = link => {
        if (link.indexOf('http://') > -1) link = link.replace('http://', '');
        if (link.indexOf('https://') > -1) link = link.replace('https://', '');

        // TODO: Extract only host
        // @Jacksón - did you look at url library by Node - follows WHATWG standard
        // 			- https://nodejs.org/api/url.html#url_url_strings_and_url_objects
        const domainRegex = /^([a-z0-9\-._~%!$&'()*+,;=]+@)?([a-z0-9\-._~%]+|\[[a-z0-9\-._~%!$&'()*+,;=:]+\])/gi;
        const hostOnly = domainRegex.exec(link)?.[0];

        return hostOnly;
    };

    const { media, links, onClickCallback } = props;

    const [src, srcSet] = getBackgroundImageSetForUrl(media.thumb);

    const mediaThumbStyle = {};
    if (!media.thumb) mediaThumbStyle.height = '0px';

    const mainLink = links.source;
    const displayLink = extractLinkHost(mainLink);
    let nativeTarget;
    if (displayLink && (displayLink.includes('docs.google') || displayLink.includes('zoom.us'))) {
        nativeTarget = '_system';
    }

    return (
        <div
            className="mc-media-item linkitem"
            onContextMenu={e => handleContextMenu(e, mainLink, displayLink)}
        >
            <ExternalLink to={mainLink} nativeTarget={nativeTarget} onClick={onClickCallback}>
                <StyledBackgroundImage
                    className="mc-medialink-image"
                    src={src}
                    srcSet={srcSet}
                    style={mediaThumbStyle}
                />
            </ExternalLink>
            <ExternalLink
                to={mainLink}
                nativeTarget={nativeTarget}
                className="article-source"
                onClick={onClickCallback}
            >
                {displayLink}
            </ExternalLink>
        </div>
    );
};

export default LinkItem;
