import React, { FormEventHandler } from 'react';

import { useUpdateCardMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';

import useModal from '@components/modals/hooks/useModal';
import useCourseFormState from '@components/content/newpost/forms/Course/hooks/useCourseFormState';

import ModalLoading from '@components/modals/ModalLoading';
import CourseForm from '@components/content/newpost/forms/Course/CourseForm';

import { ContentCard } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

type UpdateCourseFormProps = {
    hasAdminRole?: boolean;
    card: Partial<ContentCard>;
};

const UpdateCourseForm: React.FC<UpdateCourseFormProps> = ({ hasAdminRole, card }) => {
    const state = useCourseFormState(card);

    const { newModal, closeAllModals } = useModal();

    const [updateCard] = useUpdateCardMutation();

    const onSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault();

        const {
            card: {
                card: {
                    title,
                    headline,
                    description,
                    thumbnail,
                    coverImage,
                    link,
                    materials,
                    ageGroup,
                    duration,
                    people,
                    publicCompletions,
                },
            },
            settings: {
                cardSettings: { rankOrder, displayInNewsfeed },
            },
            levels: { levels },
        } = state;

        const updatePayload = {
            cardID: card?._id,
            title,
            body: headline,
            description,
            mediaIcon: thumbnail,
            mediaThumb: coverImage,
            link,
            // If number is removed from rankOrder, set it to null
            rankOrder: rankOrder === false ? null : rankOrder,
            displayInNewsfeed,
            courseItems: {
                materials,
                ageGroup,
                duration,
                people,
                levels: levels.map(level => ({
                    ...level,
                    media: level.media.image,
                    steps: level.steps.map(step => ({ ...step, image: step.image.image })),
                    preSurvey: level.preSurvey.map(surveyQuestion => ({
                        ...surveyQuestion,
                        image: surveyQuestion.image?.image ?? '',
                    })),
                    postSurvey: level.postSurvey.map(surveyQuestion => ({
                        ...surveyQuestion,
                        image: surveyQuestion.image?.image ?? '',
                    })),
                })),
                publicCompletions,
            },
        };

        // Get inherited setting, in this case assumes it will be the chapter for a 'normal' card
        const inheritedSettings = card?.reference?.chapterSource?.library_settings;

        if (inheritedSettings) {
            try {
                Object.entries(inheritedSettings).forEach(([key, value]) => {
                    if (key in updatePayload) {
                        // TS is having trouble inferring that key is a valid key for updatePayload,
                        // so this explicitly sets that to make the compiler happy
                        const typeSafeKey = key as keyof typeof updatePayload;

                        // This removes the property from the update payload if it matches the inherited settings
                        if (updatePayload[typeSafeKey] && value === updatePayload[typeSafeKey]) {
                            delete updatePayload[typeSafeKey];
                        }
                    }
                });
            } catch (error) {
                logger.error('error updating card', e);
            }
        }

        updateCard({ variables: updatePayload }).then(closeAllModals);

        newModal(<ModalLoading message="Updating Course" />, { widen: true, addShadow: true });
    };

    return (
        <CourseForm
            state={state}
            onSubmit={onSubmit}
            hasAdminRole={hasAdminRole}
            title="Edit Course"
            buttonText="Update"
        />
    );
};

export default UpdateCourseForm;
