import React from 'react';

import { Maybify } from '@core/types/Utilities';
import { SurveyQuestion } from '@shared/welibrary-graphql/types';

type SurveyShortAnswerProps = {
    state: string;
    setState: (value: string) => void;
    question: Maybify<SurveyQuestion>;
    className?: string;
    disabled?: boolean;
    viewOnly?: boolean;
};

const SurveyShortAnswer: React.FC<SurveyShortAnswerProps> = ({
    state,
    setState,
    question,
    className = '',
    disabled = false,
    viewOnly = false,
}) => {
    return (
        <section className={`survey-question-short-answer ${className}`}>
            {!viewOnly && (
                <>
                    <p>{question.prompt}</p>
                    <input
                        value={state}
                        onChange={e => setState(e.target.value)}
                        disabled={disabled}
                    />
                </>
            )}
            {viewOnly && <p>{state}</p>}
        </section>
    );
};

export default SurveyShortAnswer;
