import React from 'react';
import { withTranslation } from 'react-i18next';
import IntroContent from '@components/generic/IntroContent';

import getLogger from '@core/logger';

const logger = getLogger(module);

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
        logger.error(error, info);
    }

    render() {
        const { t } = this.props;

        const declaration = t(`common:global.introContentErr.declaration`);
        const instruction = t(`common:global.introContentErr.instruction`);
        const buttonTitle = t(`common:global.introContentErr.button_title`);

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <IntroContent
                    declaration={declaration}
                    instruction={instruction}
                    buttonTitle={buttonTitle}
                    type="error"
                />
            );
        }

        return this.props?.children || null;
    }
}

export default withTranslation()(ErrorBoundary);
