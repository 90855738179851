export const tempStyle = {
    position: 'absolute',
    right: '37px',
    width: '16px',
    height: '16px',
    top: '50%',
    transform: 'translate(0, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '10000000000',
};

export const dotStyle = {
    width: '12px',
    height: '12px',
};
