import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import useConfirmation from '@components/generic/hooks/useConfirmation';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import AlertToast from '@components/toast/AlertToast';
import IntroContent from '@web/ui/components/generic/IntroContent';
import X from '@web/ui/dsc/svgs/FullSizeX';

import { CANCEL_EMAIL_INVITE_TO_GROUP } from '@shared/welibrary-graphql/user/mutations';

import { defaultAlertToastOptions } from '@core/types/Toast';
import { EmailInvite, Group, Maybe } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.group.groupDashboard';

const GroupMemberEmailInvites: React.FC<{
    group: Group;
    memberInvites?: Maybe<Maybe<Partial<EmailInvite>>[]>;
    onNetworkSearchAction: () => void;
    canUserInviteOthers: Maybe<string | boolean | undefined>;
    handleLinkSharing: () => void;
}> = ({ group, memberInvites, onNetworkSearchAction, canUserInviteOthers, handleLinkSharing }) => {
    const { t } = useTranslation();
    const confirm = useConfirmation();
    const { newToast } = useToastActionsContext();

    const localNamespace =
        group?.subtype === 'event'
            ? 'imports.wlWeb.ui.components.group.eventDashboard'
            : 'imports.wlWeb.ui.components.group.groupDashboard';

    const inviteText = t(`common:${localNamespace}.invite_members`);
    const [cancelEmailInviteToGroup] = useMutation(CANCEL_EMAIL_INVITE_TO_GROUP);

    const handleCancelEmailInviteToGroup = async (userEmail?: string) => {
        if (!userEmail) {
            newToast(
                <AlertToast
                    boldText={`${t(`common:${localNamespace}.alert.no_user_selected`)}`}
                    showSuccessIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (
            await confirm({
                text: t(`common:${localNamespace}.confirm_cancel_invite`, { userEmail }),
            })
        ) {
            cancelEmailInviteToGroup({
                variables: {
                    groupId: group?._id,
                    userEmail,
                },
            }).then(() => {
                onNetworkSearchAction();
            });
        }
    };

    return (
        <ul>
            {memberInvites.length > 0 ? (
                memberInvites?.map((invite, index) => (
                    <li key={`email-invite-${index}`} className="group-members-form-result">
                        <div />
                        <section>
                            <h2>{invite?.userEmail}</h2>
                        </section>
                        <button
                            type="button"
                            onClick={() => handleCancelEmailInviteToGroup(invite?.userEmail)}
                        >
                            <X />
                        </button>
                    </li>
                ))
            ) : (
                <IntroContent
                    declaration={t('global.introContentErr.no_email_invites.declaration')}
                    instruction={t('global.introContentErr.no_members.instruction')}
                    type="nocontent"
                >
                    {canUserInviteOthers && (
                        <button
                            onClick={handleLinkSharing}
                            type="button"
                            className="group-members-invite-link-btn"
                        >
                            {inviteText}
                        </button>
                    )}
                </IntroContent>
            )}
        </ul>
    );
};

export default GroupMemberEmailInvites;
