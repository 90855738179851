import { useEffect } from 'react';
import { uniqBy } from 'lodash';

import { useCurrentUser } from '@stores/User';
import useRefetchOnResume from '@components/generic/hooks/useRefetchOnResume';

import { GET_NOTIFICATIONS_FOR_LOGGEDIN_USER } from '@shared/welibrary-graphql/notifications/queries';
import { GetNotificationsForLoggedInUserQueryResult } from '@shared/welibrary-graphql/notifications/queries.hook';
import { NOTIFICATIONS_SUBSCRIPTION } from '@shared/welibrary-graphql/notifications/subscriptions';
import { MARK_NOTIFICATIONS_READ } from '@shared/welibrary-graphql/notifications/mutations';

import pushUtilities from '@core/utilities/pushUtilities';
import {
    getUserNotificationRule,
    NOTIFICATION_LEVEL,
} from '@core/utilities/constants/notifications';

import { Notification } from '@shared/welibrary-graphql/types';
import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';

/**
 * React hook for handling the logic behind displaying notifications
 *
 * Use like so:
 * const queryResult = useQuery(NotificationQuery);
 *
 * const {
 *     snoozed,
 *     loading,
 *     error,
 *     refetch,
 *     notifications,
 *     unreadNotifications,
 *     markNotificationsRead,
 * } = useNotifications(queryResult);
 */
const useNotifications = ({
    loading,
    error,
    data,
    subscribeToMore,
    client,
    refetch,
}: GetNotificationsForLoggedInUserQueryResult) => {
    const { currentUser } = useCurrentUser();

    const notifications = data?.notificationsForLoggedInUser ?? [];

    const unreadNotifications =
        notifications?.filter(notification => (notification?.currentUserReadCount ?? 0) <= 0) ?? [];

    const subscribeToNewNotifications = () =>
        subscribeToMore?.({
            document: NOTIFICATIONS_SUBSCRIPTION,
            variables: { userId: currentUser?._id },
            updateQuery: (prev, { subscriptionData }) => {
                // TODO: This seems to be firing twice... why is that?
                if (!subscriptionData.data) return prev;

                const newNotificationItem = subscriptionData.data.notificationAdded;

                const updatedFeed = uniqBy(
                    [newNotificationItem, ...prev.notificationsForLoggedInUser],
                    item => item._id
                );

                return {
                    ...prev,
                    notificationsForLoggedInUser: updatedFeed,
                };
            },
        });

    useEffect(() => {
        if (data && currentUser) subscribeToNewNotifications();
        if (data && unreadNotifications) pushUtilities.refreshBadge(client, currentUser?._id);
    }, [data, currentUser]);

    const markNotificationsRead = async (notificationIds: string[]) => {
        if (!notificationIds) {
            notificationIds = unreadNotifications?.map(notification => notification?._id ?? '');
        }
        if (!notificationIds || notificationIds.length <= 0) return;

        await client.mutate({
            mutation: MARK_NOTIFICATIONS_READ,
            variables: { read: notificationIds },
            refetchQueries: [GET_NOTIFICATIONS_FOR_LOGGEDIN_USER, GET_GROUP_DASHBOARD],
        });

        if (refetch) refetch();
    };

    const snoozed = (notification: Notification) => {
        if (!currentUser) return;

        const {
            eventDetails: {
                action: actionType,
                target: { id: streamID },
            },
        } = notification;

        const snoozeLevel = getUserNotificationRule(
            currentUser.notificationSettings,
            actionType,
            'PUSH',
            streamID
        );

        return snoozeLevel === NOTIFICATION_LEVEL.OFF;
    };

    useRefetchOnResume(refetch);

    return {
        snoozed,
        loading,
        error,
        refetch,
        notifications,
        unreadNotifications,
        markNotificationsRead,
    };
};

export default useNotifications;
