// src/user/queries.ts
import { gql as gql7 } from "@apollo/client";

// src/user/fragments.ts
import { gql as gql5 } from "@apollo/client";

// src/content_card/fragments.ts
import { gql as gql3 } from "@apollo/client";

// src/comment/fragments.ts
import { gql } from "@apollo/client";
var COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    __typename
    _id
    publish
    stream {
      __typename
      _id
    }
    creator {
      __typename
      _id
      profile {
        picture
        full_name
      }
    }
    body
    loggedInUserCanEdit
    childCount
    parentId
  }
`;

// src/tag/fragments.ts
import { gql as gql2 } from "@apollo/client";
var TAG_FRAGMENT = gql2`
    fragment TagFragment on Tag {
        __typename
        _id
        label
        color
        created
        updated
    }
`;

// src/content_card/fragments.ts
var UPDATE_FEATURE_CONSTRAINTS_FRAGMENT = gql3`
  fragment UpdateFeatureConstraintsFragments on ContentCard {
    __typename
    updateFeatureConstraints {
      canEditAllFields
      featuresUserCanEdit {
        type
        field
        options
        canUpdate
      }
    }
  }
`;
var CONTENT_CARD_SEARCH_FRAGMENT = gql3`
  fragment ContentCardSearchFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    media {
      icon
      thumb
    }
    prompt
    isBookmarked
    isArchived
  }
`;
var CONTENT_CARD_COURSE_SEARCH_FRAGMENT = gql3`
  fragment ContentCardCourseSearchFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    media {
      icon
      thumb
    }
    prompt
    courseItems {
      levels {
        title
        media
        resource
      }
    }
    isBookmarked
    isArchived
  }
`;
var CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT = gql3`
  fragment ViewPermissions on ContentCard {
    currentViewPermissions {
      level
      canUserView
    }
  }
`;
var CONTENT_CARD_RATINGS_FRAGMENT = gql3`
  fragment CardRatings on ContentCard {
    ratings_sentiment_score
    latest_rating_sentiments {
      _id
      profile {
        full_name
      }
    }
    ratings_quality_score
    ratings_relevance_score {
      score
      hashtag
    }
    ratings_rank
    loggedInUserVotes {
      sentiment
      quality
      relevance
    }
  }
`;
var CONTENT_CARD_BOOK_SOURCE_FRAGMENT = gql3`
  fragment ContentCardBookSourceFragment on ContentCard {
    __typename
    _id
    url
    title
    library_settings {
      displayReference
      protectionLevel
      accessRoleWhitelist
    }
    reference {
      groupSource {
        profile {
          full_name
        }
        settings {
          protectionLevel
        }
      }
    }
  }
`;
var CONTENT_CARD_REFERENCE_FRAGMENT = gql3`
  fragment ContentCardReferenceFragment on ContentCard {
    reference {
      href
      isResource
      coverImage
      chapterSource {
        title
        url
        prompt
        showTitle
        inheritSurveyAnswers
        subtype
        vote_settings {
          QUALITY {
            active
          }
          RELEVANCE {
            active
          }
          SENTIMENT {
            active
          }
        }
        library_settings {
          engagementTypes
          viewTypes
          defaultViewType
          displayReference
          displaySocialHeaders
        }
        media {
          thumb
        }
      }
      bookSource {
        ...ContentCardBookSourceFragment
      }
      shelfSource {
        title
        url
        library_settings {
          displayReference
          protectionLevel
        }
      }
      librarySource {
        title
        url
        library_settings {
          displayReference
        }
      }
      groupSource {
        _id
        subtype
        profile {
          full_name
          picture
          coverPhoto
        }
        settings {
          shortCode
          shortCodeApprovalRequired
          protectionLevel
          approvalRequired
          formSpec {
            url
          }
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        groupActionsAllowed
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
      }
    }
  }
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
`;
var CONTENT_CARD_COMMENTS_FRAGMENT = gql3`
  fragment CardCommentsFragment on ContentCard {
    __typename
    _id
    comments {
      ...CommentFragment
    }
  }
  ${COMMENT_FRAGMENT}
`;
var REVEAL_CARD_FRAGMENT = gql3`
  fragment RevealCardFragment on ContentCard {
    __typename
    type
    links {
      source
      other
    }
    media {
      thumb
      icon
      logoLight
      logoDark
    }
  }
`;
var CONTENT_CARD_CREATION_FRAGMENT = gql3`
  fragment ContentCardCreationFragment on CreationItems {
    silhouetteImage
    course {
      _id
      media {
        icon
        thumb
      }
      title
      courseItems {
        materials
        ageGroup
        duration
        people
        levels {
          title
          media
          resource
          preSurvey {
            _id
            type
            subtype
            prompt
            useImage
            image
            range
            answers
            correctAnswer
            skill
            skillSubtype
            skillMilestone
          }
          postSurvey {
            _id
            type
            subtype
            prompt
            useImage
            image
            range
            answers
            correctAnswer
            skill
            skillSubtype
            skillMilestone
          }
        }
      }
      url
    }
    levels {
      title
      description
      image
    }
  }
`;
var CONTENT_CARD_COURSE_FRAGMENT = gql3`
  fragment ContentCardCourseFragment on CourseItems {
    materials
    ageGroup
    duration
    people
    levels {
      title
      media
      video
      resource
      steps {
        text
        useImage
        image
        useVideo
        video
        fullPage
        pageBreak
        skill
        skillSubtype
        skillMilestone
      }
      preSurvey {
        _id
        type
        subtype
        prompt
        useImage
        image
        range
        answers
        correctAnswer
        skill
        skillSubtype
        skillMilestone
      }
      postSurvey {
        _id
        type
        subtype
        prompt
        useImage
        image
        range
        answers
        correctAnswer
        skill
        skillSubtype
        skillMilestone
      }
    }
    publicCompletions
  }
`;
var CONTENT_CARD_PLEDGES_FRAGMENT = gql3`
  fragment ContentCardPledgesFragment on PledgeItems {
    __typename
    _id
    pledges {
      __typename
      _id
      label
      reportingType
      canPledge
      canDeletePledge
      totalPledges
      currentUsersPledges
      currentUsersPledgesMonth: currentUsersPledges(daysAgo: 30)
      currentUsersPledgesDay: currentUsersPledges(daysAgo: 1)
      successfulPledgesAll: currentUsersSuccessfulPledges
      successfulPledgesMonth: currentUsersSuccessfulPledges(daysAgo: 30)
      successfulPledgesDay: currentUsersSuccessfulPledges(daysAgo: 1)
      hidden
    }
    pledgeKeys {
      __typename
      _id
      label
      refactoryPeriod
      limitPerRefractoryPeriod
      description
      reportingType
      hidden
    }
    pledgers {
      __typename
      _id
      profile {
        full_name
        first_name
        picture
      }
    }
    latestPledgerEngagement {
      userId
      date
    }
    totalPledges
    totalPledgers
    pledgesLastDay: totalPledges(daysAgo: 1)
    pledgesLastMonth: totalPledges(daysAgo: 30)
  }
`;
var MOBILIZE_EFFORT_FRAGMENT = gql3`
  fragment MobilizeEffortFragment on ContentCard {
    __typename
    _id
    path
    url
    shortCode
    type
    title
    body
    description
    prompt
    rankOrder
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
      }
      roles {
        isSpeakerOf
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    aliases {
      ... on GroupAliasHandle {
        url
        title
        color
        type
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
      }
      ... on AliasItem {
        url
        title
        color
        type
        item {
          _id
          __typename
          title
          body
          protectionLevel
          prompt
          author {
            __typename
            _id
            type
            profile {
              full_name
            }
          }
          reference {
            href
            coverImage
            bookSource {
              ...ContentCardBookSourceFragment
            }
          }
          media {
            thumb
            icon
          }
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            displayInNewsfeed
            userCanPost
          }
          pledgeItems {
            ...ContentCardPledgesFragment
          }
          whitelabelVerbage {
            __typename
            _id
            value
          }
          isArchived
          userHasPledgedTo
          taskHistory {
            __typename
            cursor
            hasMore
            results {
              pledgeKeyId
              label
              refactoryPeriod
              date
              supplement {
                note
                image
                document
                documentTitle
              }
              verifier {
                name
                email
                phone
                country
                note
                newRecruit
              }
              claimedAccount {
                __typename
                _id
                profile {
                  picture
                  full_name
                  email
                  phone
                  country
                }
              }
            }
            type
          }
        }
      }
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
    }
    loggedInUserCanEdit
    defaultHidden
    isBookmarked
    isArchived
    applicationItems {
      applications {
        __typename
        _id
        createdAt
        status
        data {
          values {
            id
            values
          }
        }
        user {
          __typename
          _id
          profile {
            full_name
            picture
            country
            website
            email
          }
        }
      }
    }
    postingSettings {
      type
      status
      totalPositionsCount
      acceptedCount
      specification {
        url
      }
      postingData {
        parent {
          url
        }
        values {
          id
          type
          customText
          comparator
          values
          siblingId
        }
      }
      requirementsData {
        parent {
          url
        }
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    loggedInUserApplyStatus
    isTemplate
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
`;
var CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT = gql3`
  fragment ContentCardCustomFieldsFragment on ContentCard {
    _id
    customFields {
      manager
      parent {
        url
      }
      parentGroup {
        _id
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
`;
var CONTENT_CARD_FRAGMENT = gql3`
  fragment ContentCardFragment on ContentCard {
    __typename
    _id
    vs
    path
    url
    shortCode
    type
    title
    body
    shortTitle
    description
    prompt
    commentCount
    latestComments {
      cursor
      hasMore
      type
      results {
        _id
        creator {
          _id
          profile {
            full_name
            picture
          }
        }
        body
      }
    }
    inheritSurveyAnswers
    rankOrder
    subtype
    pinnedCardIds
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
        did
      }
      roles {
        isSpeakerOf
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      title
      source
      openInModal
      other
      publisher
    }
    aliases {
      ... on GroupAliasHandle {
        url
        title
        color
        thumb
        icon
        type
        group {
          __typename
          _id
          subtype
          isUnpublished
          profile {
            full_name
            picture
            coverPhoto
          }
          paymentSettings {
            stripeAccount {
              id
              email
              country
              charges_enabled
              payouts_enabled
              details_submitted
              external_accounts {
                object
                data {
                  country
                  currency
                }
                has_more
                url
              }
            }
            isGroupMembershipPaid
          }
          products {
            _id
            name
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
          }
          groupActionsAllowed
          currentUserOwnsMembership
          currentUserOwnsFreeSubscription
          doesSellMembership
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
        }
      }
      ... on MessageThreadAliasHandle {
        url
        title
        color
        thumb
        icon
        type
        thread {
          __typename
          _id
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
        }
      }
      ... on AliasItem {
        url
        title
        color
        thumb
        icon
        type
        item {
          subtype
          __typename
          _id
          title
          shortTitle
          prompt
          vote_settings {
            QUALITY {
              active
            }
            RELEVANCE {
              active
            }
            SENTIMENT {
              active
            }
          }
          type
          author {
            __typename
            _id
            type
            profile {
              full_name
            }
          }
          reference {
            href
            coverImage
            shelfSource {
              title
              url
              library_settings {
                displayReference
                protectionLevel
              }
            }
            bookSource {
              ...ContentCardBookSourceFragment
            }
            groupSource {
              _id
              subtype
              profile {
                full_name
                picture
                coverPhoto
              }
            }
          }
          media {
            thumb
            icon
          }
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            protectionLevel
            displayInNewsfeed
            userCanPost
          }
          pledgeItems {
            ...ContentCardPledgesFragment
          }
          whitelabelVerbage {
            __typename
            _id
            value
          }
          isArchived
          userHasPledgedTo
          taskHistory {
            __typename
            cursor
            hasMore
            results {
              pledgeKeyId
              label
              refactoryPeriod
              date
              supplement {
                note
                image
                document
                documentTitle
              }
              verifier {
                name
                email
                phone
                country
                note
                newRecruit
              }
              claimedAccount {
                __typename
                _id
                profile {
                  picture
                  full_name
                  email
                  phone
                  country
                }
              }
            }
            type
          }
        }
      }
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
      mediaMetaData {
        height
        width
      }
    }
    label {
      text
      type
    }
    formSpecificationSettings {
      type
      postingForm {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
          dependsOn {
            id
            values
          }
          groups {
            group {
              _id
              path
              groupActionsAllowed
              currentUserIsMember
              currentUserIsAdmin
              profile {
                picture
                full_name
                short_bio
              }
              settings {
                approvalRequired
                protectionLevel
              }
            }
            id
            requireApproval
          }
        }
      }
      applicationForm {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
        }
      }
      form {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
        }
      }
    }
    library_settings {
      postTypes
      engagementTypes
      filterTypes
      viewTypes
      defaultViewType
      defaultFilter
      allowPostbox
      postboxRoleWhiteList
      userCanPost
      displaySocialHeaders
      displayInNewsfeed
      showCreateChildren
      showResourceHeader
      allowLabels
      displayReference
      protectionLevel
      accessRoleWhitelist
      memberStyle
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      dashboardContentItems {
        name
        url
        type
        filter
      }
      customRoles {
        name
        url
        color
      }
      sidebarMenu {
        name
        url
      }
      recommendations
    }
    sendNotifications
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    meta {
      course {
        revealType
        revealContent {
          ...RevealCardFragment
        }
        compensation
        time
        sequence
      }
      research {
        category
        journalName
        organization
        publicationType
        abstract
        authors
        fileURL
        tags
        issue
      }
      mobilize {
        date
        qualifications
        skills
        summary
        city
        state
        country
        cta
        goalnumber
        hours
        matchterm
        pledge
        timeCommit
        pertime
      }
      pledge {
        date
        qualifications
        skills
        summary
        city
        state
        country
        cta
        goalnumber
        hours
        matchterm
        pledge
        timeCommit
        pertime
      }
    }
    loggedInUserCanEdit
    currentUserHasRead
    defaultHidden
    isBookmarked
    isArchived
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    isTemplate
    postingSettings {
      type
      status
      totalPositionsCount
      acceptedCount
      specification {
        url
      }
      postingData {
        parent {
          url
        }
        values {
          id
          type
          customText
          comparator
          values
          siblingId
        }
      }
      requirementsData {
        parent {
          url
        }
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    mobilizationSettings {
      approvalType
      status
      efforts {
        type
        card {
          __typename
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            displayInNewsfeed
          }
          ...MobilizeEffortFragment
        }
        status
        country
        countGoal
        count
      }
    }
    courseItems {
      ...ContentCardCourseFragment
    }
    creationItems {
      ...ContentCardCreationFragment
    }
    surveyQuestions {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
    timeAndPlaceSettings {
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        country
        postal
        state
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
    }
    eventOverviewDetails {
      title
      description
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      location {
        formatted_address
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
    }
    eventSettings {
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      location {
        formatted_address
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
    }
    eventAttendance {
      user {
        _id
        profile {
          full_name
          picture
        }
        socialActionsAllowed
      }
      status
    }
    canMembersSuggestEdits
    showTitle
    protectionLevel
    currentUserCanSuggestEdit
    verticalScrolling
    dialogSettings {
      event
      people {
        name
        image
      }
      dialogs {
        person
        text
      }
    }
    showPostTypes
    ...UpdateFeatureConstraintsFragments
    ...CardRatings
    ...ContentCardCustomFieldsFragment
  }
  ${CONTENT_CARD_RATINGS_FRAGMENT}
  ${REVEAL_CARD_FRAGMENT}
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${MOBILIZE_EFFORT_FRAGMENT}
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
  ${CONTENT_CARD_COURSE_FRAGMENT}
  ${CONTENT_CARD_CREATION_FRAGMENT}
  ${CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT}
`;
var CONTENT_CARD_PLATFORM_FRAGMENT = gql3`
  fragment ContentCardPlatformFragment on ContentCard {
    _id
    __typename
    vs
    type
    url
    platformSettings {
      platformGroupOwner
      forms {
        formCardUrl
        formSpecificationSettings {
          type
          form {
            fieldGroups {
              groupName
              index
              fieldIds
            }
            required
            fields {
              index
              id
              type
              options
              prompt
              defaultComparator
              customText
              selectMultiple
              siblingId
              allowOther
              required
              showOnSignup
              groups {
                group {
                  _id
                  path
                  groupActionsAllowed
                  currentUserIsMember
                  currentUserIsAdmin
                  profile {
                    picture
                    full_name
                    short_bio
                  }
                  settings {
                    approvalRequired
                    protectionLevel
                  }
                }
                id
                requireApproval
              }
              placeholder
              dependsOn {
                id
                values
              }
            }
          }
        }
      }
    }
  }
`;
var CONTENT_CARD_CHAPTER_FRAGMENT = gql3`
  fragment ContentCardChapterFragment on ContentCard {
    _id
    vs
    type
    url
    title
    shortTitle
    media {
      icon
      thumb
    }
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
      }
    }
    description
    isArchived
    prompt
    loggedInUserCanEdit
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    library_settings {
      postTypes
      engagementTypes
      filterTypes
      viewTypes
      defaultViewType
      defaultFilter
      allowPostbox
      postboxRoleWhiteList
      userCanPost
      displaySocialHeaders
      displayInNewsfeed
      allowLabels
      displayReference
      tagAggregation {
        ...TagFragment
      }
      cardAggregation {
        __typename
        _id
        title
        url
        body
        media {
          thumb
          icon
        }
      }
    }
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    sendNotifications
    rolesAvailable {
      url
      name
    }
    ...ContentCardReferenceFragment
    rankOrder
    canMembersSuggestEdits
    currentUserCanSuggestEdit
    showTitle
    childrenCount
    showPostTypes
    inheritSurveyAnswers
    isTemplate
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var RAINBOW_ROAD_FRAGMENT = gql3`
  fragment RainbowRoadFragment on ContentCard {
    __typename
    _id
    url
    vs
    type
    subtype
    title
    description
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
      }
      roles {
        isSpeakerOf
      }
    }
    media {
      icon
    }
    loggedInUserCanEdit
    rainbowRoadSettings {
      header {
        color
        darkMode
        image
        backgroundImage
        showTopInfo
      }
      body {
        color
        darkMode
        image
      }
      nodes {
        circleSize
        icon
        lineStyle
        lineColor
        outline
        winding
        spinnerImage
      }
      font
      questSettings {
        creationsChannel {
          url
          title
          children {
            ...ContentCardFragment
          }
        }
        creationNodeSettings {
          icon
          backgroundImage
          showMetaInformation
          spinnerImage
        }
        completionRequirement
        dialogsChannel {
          url
          title
          children {
            ...ContentCardFragment
          }
        }
        questEndImage
        vcSettings {
          title
          name
          description
          image
        }
      }
    }
    library_settings {
      engagementTypes
    }
    nodes {
      title
      icon
      backgroundImage
      spinnerImage
      showMetaInformation
      preOrPost
      alias {
        ... on UserAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          user {
            _id
            profile {
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
            }
            socialActionsAllowed
          }
        }
        ... on GroupAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          group {
            __typename
            _id
            path
            profile {
              full_name
              short_bio
              full_bio
              picture
              coverPhoto
              country
              website
              email
              customFields {
                parent {
                  url
                }
                parentGroup {
                  _id
                }
                manager
                values {
                  id
                  type
                  comparator
                  values
                  siblingId
                }
              }
            }
            socialActionsAllowed
            shelf {
              __typename
              _id
              url
              media {
                thumb
                icon
              }
            }
            settings {
              __typename
              subscriptions
              formSpec {
                url
                formSpecificationSettings {
                  type
                  form {
                    required
                    fieldGroups {
                      groupName
                      fieldIds
                      index
                    }
                    fields {
                      index
                      type
                      id
                      options
                      customText
                      prompt
                      placeholder
                      type
                      selectMultiple
                      siblingId
                      defaultComparator
                      required
                      allowOther
                      dependsOn {
                        id
                        values
                      }
                    }
                  }
                }
              }
              protectionLevel
              memberStyle
              dashboards {
                name
                url
                type
                filter
              }
              customSignupFields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                weight
                required
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
              recommendations
              canMembersSuggestEdits
              shortCode
              shortCodeApprovalRequired
              groupChatroom
              adminChatroom
              approvalRequired
              registrationTitle
              registrationDescription
              registrationActionText
              enableGroupNewsfeed
            }
            hasUnreadDashboard
            subtype
            totalMembers
            currentUserIsAdmin
            currentUserIsDirectAdmin
            currentUserIsMember
            currentUserLastActiveDate
            groupActionsAllowed
            isTemplate
            allSignupFields {
              parentGroup {
                _id
              }
              manager
              signupFields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                weight
                required
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
            }
            whitelabelVerbage {
              _id
              value
              plainText
            }
            parentGroup {
              __typename
              _id
              profile {
                full_name
              }
            }
            channelsBook {
              title
              url
              media {
                thumb
                icon
              }
              children {
                title
                url
                reference {
                  href
                }
              }
            }
          }
        }
        ... on MessageThreadAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          thread {
            __typename
            _id
            participants {
              __typename
              user {
                __typename
                _id
                profile {
                  __typename
                  full_name
                  short_bio
                  full_bio
                  picture
                  coverPhoto
                  facebookProfile
                  instagramProfile
                  twitterProfile
                  linkedInProfile
                }
              }
              hasUnreadMessages
            }
            messages {
              __typename
              hasMore
              cursor
              type
              messages {
                __typename
                _id
                author {
                  __typename
                  _id
                  profile {
                    __typename
                    first_name
                    full_name
                    picture
                  }
                }
                text
                created
                updated
              }
            }
            displayName
            displayIcon
            created
            updated
            group {
              __typename
              _id
              profile {
                full_name
                picture
                coverPhoto
              }
            }
            currentUserNotificationLevel
          }
        }
        ... on AliasItem {
          url
          type
          title
          thumb
          icon
          color
          item {
            ...ContentCardFragment
            ...ContentCardReferenceFragment
          }
        }
      }
    }
    aliases {
      ... on UserAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        user {
          _id
          profile {
            full_name
            picture
            coverPhoto
            full_bio
            short_bio
            website
            facebookProfile
            twitterProfile
            instagramProfile
            linkedInProfile
          }
          socialActionsAllowed
        }
      }
      ... on GroupAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        group {
          __typename
          _id
          path
          profile {
            full_name
            short_bio
            full_bio
            picture
            coverPhoto
            country
            website
            email
            customFields {
              parent {
                url
              }
              parentGroup {
                _id
              }
              manager
              values {
                id
                type
                comparator
                values
                siblingId
              }
            }
          }
          socialActionsAllowed
          shelf {
            __typename
            _id
            url
            media {
              thumb
              icon
            }
          }
          settings {
            __typename
            subscriptions
            formSpec {
              url
              formSpecificationSettings {
                type
                form {
                  required
                  fieldGroups {
                    groupName
                    fieldIds
                    index
                  }
                  fields {
                    index
                    type
                    id
                    options
                    customText
                    prompt
                    placeholder
                    type
                    selectMultiple
                    siblingId
                    defaultComparator
                    required
                    allowOther
                    dependsOn {
                      id
                      values
                    }
                  }
                }
              }
            }
            protectionLevel
            memberStyle
            dashboards {
              name
              url
              type
              filter
            }
            customSignupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
            recommendations
            canMembersSuggestEdits
            shortCode
            shortCodeApprovalRequired
            groupChatroom
            adminChatroom
            approvalRequired
            registrationTitle
            registrationDescription
            registrationActionText
            enableGroupNewsfeed
          }
          hasUnreadDashboard
          subtype
          totalMembers
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
          currentUserLastActiveDate
          groupActionsAllowed
          isTemplate
          allSignupFields {
            parentGroup {
              _id
            }
            manager
            signupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
          whitelabelVerbage {
            _id
            value
            plainText
          }
          parentGroup {
            __typename
            _id
            profile {
              full_name
            }
          }
          channelsBook {
            title
            url
            media {
              thumb
              icon
            }
            children {
              title
              url
              reference {
                href
              }
            }
          }
        }
      }
      ... on MessageThreadAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        thread {
          __typename
          _id
          participants {
            __typename
            user {
              __typename
              _id
              profile {
                __typename
                full_name
                short_bio
                full_bio
                picture
                coverPhoto
                facebookProfile
                instagramProfile
                twitterProfile
                linkedInProfile
              }
            }
            hasUnreadMessages
          }
          messages {
            __typename
            hasMore
            cursor
            type
            messages {
              __typename
              _id
              author {
                __typename
                _id
                profile {
                  __typename
                  first_name
                  full_name
                  picture
                }
              }
              text
              created
              updated
            }
          }
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
          currentUserNotificationLevel
        }
      }
      ... on AliasItem {
        url
        type
        title
        thumb
        icon
        color
        item {
          ...ContentCardFragment
          ...ContentCardReferenceFragment
        }
      }
    }
  }

  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var CONTENT_CARD_BOOK_FRAGMENT = gql3`
  fragment ContentCardBookFragment on ContentCard {
    _id
    vs
    type
    subtype
    url
    title
    media {
      icon
      thumb
    }
    author {
      _id
      type
      profile {
        full_name
      }
    }
    children(sort: ordered) {
      ...ContentCardChapterFragment
    }
    description
    body
    prompt
    promptEnabled
    loggedInUserFollows
    loggedInUserCanEdit
    isTemplate
    library_settings {
      displayReference
      displayInNewsfeed
      displaySocialHeaders
      protectionLevel
      accessRoleWhitelist
      defaultFilter
      filterTypes
      engagementTypes
      customRoles {
        name
        url
        color
      }
    }
    sendNotifications
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    customFields {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
    cardActionsAllowed
    rankOrder
    verticalScrolling
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_BOOK_BIG_SCREEN_FRAGMENT = gql3`
  fragment ContentCardBookBigScreenFragment on ContentCard {
    _id
    type
    url
    title
    media {
      icon
      thumb
      __typename
    }
    author {
      _id
      type
      profile {
        full_name
        __typename
      }
      __typename
    }
    library_settings {
      displaySocialHeaders
    }
    prompt
    loggedInUserFollows
    loggedInUserCanEdit
    __typename
  }
`;
var CONTENT_CARD_SHELF_FRAGMENT = gql3`
  fragment ContentCardShelfFragment on ContentCard {
    __typename
    _id
    url
    shortCode
    type
    title
    body
    description
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
      }
    }
    group {
      __typename
      _id
      subtype
      username
      profile {
        full_name
        full_bio
        picture
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    media {
      thumb
      icon
    }
    library_settings {
      displayReference
      displayInNewsfeed
      dashboardContentItems {
        name
        url
        type
        filter
      }
      protectionLevel
      accessRoleWhitelist
    }
    customFields {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
    cardActionsAllowed
    loggedInUserFollows
    loggedInUserCanEdit
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_LIBRARY_FRAGMENT = gql3`
  fragment ContentCardLibraryFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
    }
    loggedInUserFollows
    loggedInUserCanEdit
    library_settings {
      welcomeText
      autoFollowCards
      autoFollowUsers
      initialSubscriptions
      protectionLevel
      memberStyle
      sidebarMenu {
        name
        url
      }
      dashboardMenu {
        name
        url
      }
      dashboardContentItems {
        name
        url
        type
        filter
      }
      customRoles {
        name
        url
        color
      }
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      displayReference
      displayInNewsfeed
      recommendations
    }
    cardActionsAllowed
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT = gql3`
  fragment ContentCardChapterChildrenFragment on ContentCard {
    __typename
    _id
    chapterChildren: children(restrictType: chapter) {
      ...ContentCardChapterFragment
    }
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
`;

// src/organization/fragments.ts
import { gql as gql4 } from "@apollo/client";
var ORGANIZATION_FRAGMENT = gql4`
    fragment OrganizationFragment on Organization {
        __typename
        _id
        name
        created
        createdBy {
            _id
            profile {
                full_name
            }
        }
        updated
    }
`;

// src/user/fragments.ts
var CUSTOM_FIELDS_FRAGMENT = gql5`
  fragment CustomFieldsFragment on User {
    __typename
    _id
    profile {
      customFields {
        parent {
          url
        }
        parentGroup {
          _id
        }
        manager
        lastDismissalDate
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
  }
`;
var USER_FRAGMENT = gql5`
  fragment UserFragment on User {
    __typename
    _id
    type
    profile {
      full_name
      short_bio
      full_bio
      picture
      coverPhoto
      facebookProfile
      instagramProfile
      twitterProfile
      linkedInProfile
      country
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        state
        country
        postal
      }
      language
      website
      email
      phone
      nickname
      followersCount
      connectionsCount
      loggedInUserConnectionStatus
      protectionLevel
      newsfeedFilter
      organization {
        ...OrganizationFragment
      }
      simple {
        name
        age
      }
      avatar {
        avatar
        skinTone
        cheekColor
        hairColor
        eyeColor
        clothing1
        clothing2
        clothing3
        outline
      }
      displaySurveyAnswers
      temp
    }
    guardianAccount {
      isVerified
      profile {
        email
        full_name
      }
      featureSets
    }
    guardianCode
    childVerificationCodes
    socialActionsAllowed
    isAnonymous
    isVerified
    isUserSuperAdmin
    canCreate
    featureSets
    ...CustomFieldsFragment
  }
  ${ORGANIZATION_FRAGMENT}
  ${CUSTOM_FIELDS_FRAGMENT}
`;
var UPDATE_FEATURE_CONSTRAINTS_FRAGMENT2 = gql5`
  fragment UpdateGroupFeatureConstraintsFragment on Group {
    __typename
    updateFeatureConstraints {
      canEditAllFields
      featuresUserCanEdit {
        type
        field
        options
        canUpdate
      }
    }
  }
`;
var GROUP_SHELF_FRAGMENT = gql5`
  fragment GroupShelfFragment on Group {
    __typename
    _id
    profile {
      full_name
      picture
      coverPhoto
    }
    shelf {
      __typename
      _id
      url
      media {
        thumb
        icon
      }
    }
  }
`;
var GROUP_PRODUCT_FRAGMENT = gql5`
  fragment GroupProductFragment on Group {
    products {
      _id
      name
      description
      coupons {
        name
        code
        percentage
        type
        expiration
      }
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
  }
`;
var GROUP_EVENT_FRAGMENT = gql5`
  fragment GroupEventFragment on Group {
    __typename
    _id
    path
    profile {
      full_name
      short_bio
      full_bio
      picture
      coverPhoto
      country
      website
      email
    }
    socialActionsAllowed
    shelf {
      __typename
      _id
      url
      media {
        thumb
        icon
      }
    }
    settings {
      __typename
      formSpec {
        url
      }
      protectionLevel
      memberStyle
      recommendations
      canMembersSuggestEdits
      shortCode
      shortCodeApprovalRequired
      groupChatroom
      adminChatroom
      approvalRequired
      registrationTitle
      registrationDescription
      registrationActionText
      enableGroupNewsfeed
      enableMembershipID
    }
    eventOverviewDetails {
      title
      description
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      hideAttendeeCount
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
      sponsorSettings {
        description
        tiers {
          name
          order
          sponsors {
            name
            link
            logo
          }
        }
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
          postal
          location {
            type
            coordinates
          }
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            city
            state
            country
            postal
            location {
              type
              coordinates
            }
          }
        }
      }
    }
    timeAndPlaceSettings {
      _id
      title
      description
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
          postal
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            title
            thumb
            icon
            altName
            altBio
            useUserBio
            color
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                protectionLevel
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
    }
    subtype
    totalMembers
    totalChildGroups
    totalChildEventGroups
    membershipBoostUri
    currentUserIsAdmin
    currentUserIsDirectAdmin
    currentUserIsMember
    currentUserIsInterested
    currentUserIsInvited
    currentUserIsNotGoing
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    currentUserLastActiveDate
    groupActionsAllowed
    doesSellMembership
    isUnpublished
    whitelabelVerbage {
      _id
      value
      plainText
    }
    parentGroup {
      __typename
      _id
      profile {
        full_name
        coverPhoto
        picture
        website
      }
    }
    paymentSettings {
      isGroupMembershipPaid
    }
    ...GroupProductFragment
  }

  ${GROUP_PRODUCT_FRAGMENT}
`;
var GROUP_FRAGMENT = gql5`
  fragment GroupFragment on Group {
    __typename
    _id
    path
    profile {
      full_name
      short_bio
      full_bio
      picture
      coverPhoto
      country
      website
      email
      customFields {
        parent {
          url
        }
        parentGroup {
          _id
        }
        manager
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    socialActionsAllowed
    shelf {
      __typename
      _id
      url
      loggedInUserCanEdit
      media {
        thumb
        icon
      }
    }
    settings {
      __typename
      subscriptions
      formSpec {
        url
        formSpecificationSettings {
          type
          form {
            required
            fieldGroups {
              groupName
              fieldIds
              index
            }
            fields {
              index
              type
              id
              options
              customText
              prompt
              placeholder
              type
              selectMultiple
              siblingId
              defaultComparator
              required
              showOnSignup
              groups {
                group {
                  _id
                }
                id
                requireApproval
              }
              allowOther
              dependsOn {
                id
                values
              }
            }
          }
        }
      }
      protectionLevel
      memberStyle
      dashboards {
        name
        url
        type
        filter
      }
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      recommendations
      canMembersSuggestEdits
      shortCode
      shortCodeApprovalRequired
      groupChatroom
      adminChatroom
      membersHub
      welcomeCard {
        enabled
        title
        message
      }
      chatSettings {
        showChatHistory
        showReadReceipts
        showOnlineUsers
        isEncrypted
      }
      adminChatSettings {
        showChatHistory
        showReadReceipts
        showOnlineUsers
        isEncrypted
      }
      chatPermissions {
        editGroupSettings
        addMembers
        pinMessages
        sendMessages
      }
      approvalRequired
      registrationTitle
      registrationDescription
      registrationActionText
      enableGroupNewsfeed
      enableMembershipID
      lastCustomSignupFieldsUpdate
      adminChatroom
      groupChatroom
      enableGroupNewsfeed
      welcomeCard {
        enabled
        title
        message
      }
    }
    eventOverviewDetails {
      title
      description
      hideAttendeeCount
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
      sponsorSettings {
        description
        tiers {
          name
          order
          sponsors {
            name
            link
            logo
          }
        }
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
    }
    timeAndPlaceSettings {
      title
      description
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            altName
            altBio
            useUserBio
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
    }
    hasUnreadDashboard
    subtype
    totalMembers
    totalChildGroups
    totalChildEventGroups
    membershipBoostUri
    currentUserIsAdmin
    currentUserIsDirectAdmin
    currentUserIsMember
    currentUserIsInterested
    currentUserIsInvited
    currentUserIsNotGoing
    currentUserLastActiveDate
    currentUserJoinDate
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    groupActionsAllowed
    doesSellMembership
    isTemplate
    isUnpublished
    allSignupFields {
      parentGroup {
        _id
      }
      manager
      signupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
    }
    whitelabelVerbage {
      _id
      value
      plainText
    }
    parentGroup {
      __typename
      _id
      profile {
        full_name
        coverPhoto
        picture
        website
        customFields {
          parent {
            url
          }
          parentGroup {
            _id
          }
          manager
          values {
            id
            type
            comparator
            values
            siblingId
          }
        }
      }
    }
    channelsBook {
      title
      url
      media {
        thumb
        icon
      }
      children {
        title
        url
        reference {
          href
        }
      }
    }
    eventBook {
      url
      title
      children {
        _id
        title
        url
        rankOrder
        reference {
          href
        }
      }
    }
    ...UpdateGroupFeatureConstraintsFragment
    ...GroupProductFragment
  }
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT2}
  ${GROUP_PRODUCT_FRAGMENT}
`;
var GROUP_MEMBERSHIP_FRAGMENT = gql5`
  fragment GroupMembershipFragment on Group {
    __typename
    _id
    totalMembers
    totalAdmins
    totalGuests
    owners(limit: $ownersLimit, cursor: $ownersCursor) {
      __typename
      hasMore
      cursor
      results {
        ...UserFragment
        doesUserOwnMembershipInGroup(groupId: $_id)
        doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
        productInstancesForGroup(groupId: $_id) {
          _id
          transferToken
          product {
            _id
            name
            description
            price
            currency
            type
          }
        }
      }
    }
    members(limit: $membersLimit, cursor: $membersCursor) {
      __typename
      hasMore
      cursor
      results {
        ...UserFragment
        doesUserOwnMembershipInGroup(groupId: $_id)
        doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
        productInstancesForGroup(groupId: $_id) {
          _id
          transferToken
          product {
            _id
            name
            description
            price
            currency
            type
          }
        }
      }
    }
    guests {
      __typename
      _id
      profile {
        email
        full_name
        temp
      }
    }
    giftedSubscriptions {
      __typename
      _id
      ...UserFragment
      profile {
        email
        full_name
        temp
      }
      doesUserOwnMembershipInGroup(groupId: $_id)
      doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
      productInstancesForGroup(groupId: $_id) {
        _id
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
        }
      }
    }
    memberRequests {
      user {
        __typename
        ...UserFragment
        roleForGroup(groupId: $_id)
      }
      role
      message
    }
    memberInvites {
      user {
        __typename
        ...UserFragment
        roleForGroup(groupId: $_id)
      }
      role
      message
    }
    emailInvites {
      userEmail
      role
      message
    }
  }
  ${USER_FRAGMENT}
`;
var GROUP_OPTIMIZER_DATA_FRAGMENT = gql5`
  fragment GroupOptimizerDataFragment on Group {
    _id
    profile {
      full_name
      picture
      short_bio
    }
    settings {
      recommendations
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
    }
  }
`;
var USER_PERSONAL_FEED_FRAGMENT = gql5`
  fragment UserPersonalFeedFragment on ContentCard {
    __typename
    ...ContentCardFragment
  }
  ${CONTENT_CARD_FRAGMENT}
`;
var GROUP_DASHBOARD_FRAGMENT = gql5`
  fragment GroupDashboardFragment on PaginatedCards {
    __typename
    hasMore
    cursor
    results {
      ...ContentCardReferenceFragment
      ...ContentCardFragment
      ...ContentCardChapterChildrenFragment
    }
    type
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var PRODUCT_FRAGMENT = gql5`
  fragment ProductFragment on Product {
    _id
    name
    price
    currency
    type
    fulfillment {
      groupId
      role
    }
    created
    updated
    createdBy {
      profile {
        full_name
        coverPhoto
      }
    }
    reference {
      ... on Group {
        _id
        profile {
          full_name
          picture
          coverPhoto
        }
        subtype
        doesSellMembership
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
      }
    }
  }
`;
var ORDER_FRAGMENT = gql5`
  fragment OrderFragment on CustomerOrder {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
    }
    products {
      ...ProductFragment
    }
    created
    updated
    status
  }
  ${PRODUCT_FRAGMENT}
`;
var TIME_AND_PLACE_SETTINGS_FRAGMENT = gql5`
  fragment TimeAndPlaceSettingsFragment on TimeAndPlaceSettings {
    _id
    title
    description
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    hideAttendeeCount
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
        postal
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
  }
`;

// src/messaging/fragments.ts
import { gql as gql6 } from "@apollo/client";
var MESSAGE_THREAD_FRAGMENT = gql6`
  fragment MessageThreadFragment on MessageThread {
    __typename
    _id
    participants {
      __typename
      user {
        __typename
        _id
        type
        profile {
          __typename
          full_name
          short_bio
          full_bio
          picture
          coverPhoto
          facebookProfile
          instagramProfile
          twitterProfile
          linkedInProfile
        }
      }
      hasUnreadMessages
    }
    messages {
      __typename
      hasMore
      cursor
      type
      messages {
        __typename
        _id
        author {
          __typename
          _id
          profile {
            __typename
            first_name
            full_name
            picture
          }
        }
        text
        reactions {
          emoji
          userIds
        }
        attachment {
          type
          _id
          vs
        }
        created
        updated
      }
    }
    displayName
    displayIcon
    created
    updated
    group {
      __typename
      _id
      totalMembers
      profile {
        full_name
        picture
        coverPhoto
        short_bio
      }
      settings {
        protectionLevel
        approvalRequired
        shortCodeApprovalRequired
      }
      products {
        _id
        name
      }
    }
    currentUserNotificationLevel
    threadType
  }
`;
var MESSAGE_FRAGMENT = gql6`
  fragment MessageFragment on Message {
    __typename
    _id
    author {
      __typename
      _id
      profile {
        __typename
        full_name
        picture
        short_bio
      }
    }
    thread {
      ...MessageThreadFragment
    }
    attachment {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
    }
    text
    reactions {
      emoji
      userIds
    }
    created
    updated
  }
  ${MESSAGE_THREAD_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var MESSAGE_FRAGMENT_OMIT_THREAD = gql6`
  fragment MessageFragmentOmitThread on Message {
    __typename
    _id
    author {
      __typename
      _id
      profile {
        __typename
        full_name
        picture
        short_bio
      }
    }
    reactions {
      emoji
      userIds
    }
    attachment {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
    }
    text
    created
    updated
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var MESSAGE_UPDATE_FRAGMENT = gql6`
  fragment MessageUpdateFragment on MessageUpdate {
    action
    message {
      ... on Message {
        ...MessageFragment
      }
      ... on MessageDelete {
        _id
        thread {
          ...MessageThreadFragment
        }
      }
    }
  }
  ${MESSAGE_FRAGMENT}
  ${MESSAGE_THREAD_FRAGMENT}
`;

// src/user/queries.ts
var GET_USER = gql7`
  query User($_id: ID!, $temp: Boolean = false) {
    user(_id: $_id, temp: $temp) {
      _id
      profile {
        lausannePdfUrl
        email
        phone
        first_name
        last_name
        full_name
        birthday
        language
        short_bio
        full_bio
        picture
        coverPhoto
        country
        website
        location {
          formatted_address
          location {
            type
            coordinates
          }
          city
          state
          country
          postal
        }
        nickname
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
        did
        protectionLevel
        customFields {
          parentGroup {
            _id
          }
          manager
          values {
            id
            values
            type
            comparator
          }
        }
      }
      roles {
        roles
      }
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
          description
          default_source
          invoice_settings {
            default_payment_method
          }
        }
        cardSourceId
      }
    }
  }
`;
var GET_USERS = gql7`
  query Users($_ids: [ID!]!, $temp: Boolean = false) {
    users(_ids: $_ids, temp: $temp) {
      _id
      profile {
        lausannePdfUrl
        email
        first_name
        last_name
        full_name
        birthday
        language
        short_bio
        full_bio
        picture
        country
        website
        nickname
        did
        protectionLevel
        customFields {
          parentGroup {
            _id
          }
          manager
          values {
            id
            values
            type
            comparator
          }
        }
      }
      roles {
        roles
      }
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
          description
          default_source
          invoice_settings {
            default_payment_method
          }
        }
        cardSourceId
      }
    }
  }
`;
var GET_USER_BY_PHONE = gql7`
  query GetUserByPhone($phone: String!) {
    getUserByPhone(phone: $phone) {
      _id
      profile {
        lausannePdfUrl
        email
        first_name
        last_name
        full_name
        birthday
        language
        short_bio
        full_bio
        picture
        country
        website
        nickname
        protectionLevel
      }
    }
  }
`;
var GET_USER_BY_EMAIL = gql7`
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      _id
      profile {
        email
        first_name
        last_name
        full_name
        birthday
        language
        short_bio
        full_bio
        picture
        country
        website
        nickname
        protectionLevel
      }
    }
  }
`;
var GET_USER_STRINGID = gql7`
  query UserStringID($_id: ID!) {
    user(_id: $_id) {
      __typename
      _id
      ...UserFragment
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
          description
        }
        cardSourceId
      }
    }
  }
  ${USER_FRAGMENT}
`;
var GET_CONNECTIONS_FOR_USER = gql7`
  query GetConnectionsForUser(
    $_id: ID!
    $connectionsLimit: Int = 0
    $connectionsOffset: Int = 0
    $followersLimit: Int = 0
    $followersOffset: Int = 0
    $followingUsersLimit: Int = 0
    $followingUsersOffset: Int = 0
  ) {
    userPage(_id: $_id) {
      user {
        ...UserFragment
        connections(limit: $connectionsLimit, offset: $connectionsOffset) {
          ...UserFragment
        }
        followers(limit: $followersLimit, offset: $followersOffset) {
          ...UserFragment
        }
        profile {
          lausannePdfUrl
          first_name
          following(
            limitCards: 0
            offsetCards: 0
            limitUsers: $followingUsersLimit
            offsetUsers: $followingUsersOffset
          ) {
            users {
              ...UserFragment
            }
          }
        }
      }
      loggedInUser {
        ...UserFragment
        profile {
          lausannePdfUrl
          isFollowingUser(_id: $_id)
          connectionStatus(_id: $_id)
          primaryMembershipId
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
var GET_USERS_IN_SAME_SILO = gql7`
  query UsersInSameSilo(
    $otherUserId: String
  ) {
      usersInSameSilo(otherUserId:$otherUserId)
    }
`;
var GET_CONNECTIONS = gql7`
  query GetConnections(
    $connectionsLimit: Int = 0
    $connectionsOffset: Int = 0
    $followersLimit: Int = 0
    $followersOffset: Int = 0
    $followingUsersLimit: Int = 0
    $followingUsersOffset: Int = 0
  ) {
    currentUser {
      ...UserFragment
      connections(limit: $connectionsLimit, offset: $connectionsOffset) {
        ...UserFragment
      }
      followers(limit: $followersLimit, offset: $followersOffset) {
        ...UserFragment
      }
      profile {
        lausannePdfUrl
        first_name
        following(
          limitCards: 0
          offsetCards: 0
          limitUsers: $followingUsersLimit
          offsetUsers: $followingUsersOffset
        ) {
          users {
            ...UserFragment
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
var GET_CURRENT_USER = gql7`
  query CurrentUser(
    $createdContentLimit: Int = 0
    $createdContentOffset: Int = 0
    $connectionsLimit: Int = 0
    $connectionsOffset: Int = 0
    $followersLimit: Int = 0
    $followersOffset: Int = 0
    $followingCardsLimit: Int = 0
    $followingCardsOffset: Int = 0
    $followingUsersLimit: Int = 0
    $followingUsersOffset: Int = 0
  ) {
    currentUser {
      ...UserFragment
      connections(limit: $connectionsLimit, offset: $connectionsOffset) {
        ...UserFragment
      }
      followers(limit: $followersLimit, offset: $followersOffset) {
        ...UserFragment
      }
      profile {
        lausannePdfUrl
        birthday
        coverPhoto
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
        protectionLevel
        newsfeedFilter
        did
      }
      profile {
        lausannePdfUrl
        following(
          limitCards: $followingCardsLimit
          offsetCards: $followingCardsOffset
          limitUsers: $followingUsersLimit
          offsetUsers: $followingUsersOffset
        ) {
          cards {
            ...ContentCardFragment
            routeUrl
            loggedInUserFollows
          }
          users {
            ...UserFragment
          }
        }
        customFields {
          parent {
            __typename
            _id
            url
            title
          }
          values {
            id
            type
            values
          }
        }
      }
      createdContent(
        limit: $createdContentLimit
        offset: $createdContentOffset
      ) {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
      }
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
          description
          default_source
          invoice_settings {
            default_payment_method
          }
        }
        cardSourceId
      }
    }
  }
  ${USER_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var GET_CURRENT_USER_SIMPLE = gql7`
  query CurrentUserSimple {
    currentUser {
      ...UserFragment
      profile {
        lausannePdfUrl
        birthday
        coverPhoto
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
        protectionLevel
        newsfeedFilter
        did
      }
      paymentSettings {
        stripeCustomer {
          id
          name
          email
          phone
          description
          default_source
          invoice_settings {
            default_payment_method
          }
        }
        cardSourceId
      }
      notificationSettings {
        __typename
        type
        channels {
          type
          level
          streams {
            _id
            level
            collection
            expiry
          }
        }
      }
      roles {
        adminOf {
          __typename
          url
          title
        }
      }
      canCreate
      activeGroup {
        __typename
        ...GroupFragment
      }
      membership {
        active {
          __typename
          title
          url
          shortCode
          media {
            thumb
            icon
          }
        }
        activeLibrary {
          __typename
          title
          url
          media {
            thumb
            icon
          }
          library_settings {
            customSignupFields {
              id
              index
              type
              prompt
              options
              selectMultiple
              allowOther
              required
              showOnSignup
              link
              dependsOn {
                id
                values
              }
              groups {
                group {
                  _id
                  path
                  groupActionsAllowed
                  currentUserIsMember
                  currentUserIsAdmin
                  profile {
                    lausannePdfUrl
                    picture
                    full_name
                    short_bio
                  }
                  settings {
                    approvalRequired
                    protectionLevel
                  }
                }
                id
                requireApproval
              }
            }
          }
        }
        all {
          __typename
          title
          url
          media {
            thumb
            icon
          }
        }
      }
      hasExistingPassword
      getStreamBackfilled
    }
  }

  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var GET_CURRENT_USER_QUICK_LINKS = gql7`
  query CurrentUserQuickLinks {
    currentUser {
      sideMenu {
        quickLinks {
          tags
          aliases {
            ... on UserAliasHandle {
              url
              title
              type
              user {
                _id
                profile {
                  lausannePdfUrl
                  picture
                  full_name
                  short_bio
                }
              }
            }
            ... on GroupAliasHandle {
              url
              title
              type
              group {
                _id
                profile {
                  lausannePdfUrl
                  full_name
                  picture
                  short_bio
                }
                isUnpublished
                currentUserIsAdmin
                currentUserIsDirectAdmin
              }
            }
            ... on MessageThreadAliasHandle {
              url
              title
              color
              thumb
              icon
              type
              thread {
                ...MessageThreadFragment
              }
            }
            ... on AliasItem {
              url
              title
              type
              item {
                _id
                type
                title
                description
                reference {
                  href
                  bookSource {
                    title
                    media {
                      thumb
                    }
                  }
                }
                media {
                  icon
                }
              }
            }
          }
        }
      }
    }
  }

  ${MESSAGE_THREAD_FRAGMENT}
`;
var GET_CURRENT_USER_FEED = gql7`
  query CurrentUserFeed {
    currentUser {
      personalUserfeed {
        ...UserPersonalFeedFragment
        ...ContentCardReferenceFragment
      }
    }
  }
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${USER_PERSONAL_FEED_FRAGMENT}
`;
var GET_PAGINATED_USER_FEED = gql7`
  query UserFeed(
    $_id: ID!
    $limit: Int = 10
    $cursor: String
    $sortType: String = "live"
  ) {
    userFeed(_id: $_id, limit: $limit, cursor: $cursor, sortType: $sortType) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
        ...ContentCardChapterChildrenFragment
      }
      type
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var GET_USER_PAGE = gql7`
  query UserPage(
    $_id: ID!
    $createdContentLimit: Int = 0
    $createdContentOffset: Int = 0
    $connectionsLimit: Int = 0
    $connectionsOffset: Int = 0
    $followersLimit: Int = 0
    $followersOffset: Int = 0
    $followingCardsLimit: Int = 0
    $followingCardsOffset: Int = 0
    $followingUsersLimit: Int = 0
    $followingUsersOffset: Int = 0
  ) {
    userPage(_id: $_id) {
      user {
        ...UserFragment
        createdContent(
          limit: $createdContentLimit
          offset: $createdContentOffset
        ) {
          ...ContentCardFragment
          reference {
            chapterSource {
              title
              url
              prompt
              vote_settings {
                QUALITY {
                  active
                }
                RELEVANCE {
                  active
                }
                SENTIMENT {
                  active
                }
              }
              library_settings {
                engagementTypes
              }
            }
            bookSource {
              title
              url
            }
            shelfSource {
              title
              url
            }
          }
        }
        connections(limit: $connectionsLimit, offset: $connectionsOffset) {
          ...UserFragment
        }
        followers(limit: $followersLimit, offset: $followersOffset) {
          ...UserFragment
        }
        profile {
          lausannePdfUrl
          following(
            limitCards: $followingCardsLimit
            offsetCards: $followingCardsOffset
            limitUsers: $followingUsersLimit
            offsetUsers: $followingUsersOffset
          ) {
            cards {
              ...ContentCardFragment
              routeUrl
              loggedInUserFollows
            }
            users {
              ...UserFragment
            }
          }
          primaryMembershipId
        }
        profile {
          lausannePdfUrl
          coverPhoto
          facebookProfile
          instagramProfile
          twitterProfile
          linkedInProfile
          newsfeedFilter
        }
        paymentSettings {
          stripeCustomer {
            id
            name
            email
            phone
            description
            default_source
            invoice_settings {
              default_payment_method
            }
          }
          cardSourceId
        }
      }
      loggedInUser {
        ...UserFragment
        profile {
          lausannePdfUrl
          isFollowingUser(_id: $_id)
          connectionStatus(_id: $_id)
          primaryMembershipId
          newsfeedFilter
        }
        roles {
          roles
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
`;
var GET_USER_PAGE_SIMPLE = gql7`
  query UserPageSimple($_id: ID!) {
    userPage(_id: $_id) {
      user {
        ...UserFragment
        type
        profile {
          lausannePdfUrl
          primaryMembershipId
        }
        profile {
          lausannePdfUrl
          coverPhoto
          facebookProfile
          instagramProfile
          twitterProfile
          linkedInProfile
          protectionLevel
          newsfeedFilter
        }
        paymentSettings {
          stripeCustomer {
            id
            name
            email
            phone
            description
            default_source
            invoice_settings {
              default_payment_method
            }
          }
          cardSourceId
        }
      }
      loggedInUser {
        ...UserFragment
        profile {
          lausannePdfUrl
          isFollowingUser(_id: $_id)
          connectionStatus(_id: $_id)
          primaryMembershipId
          newsfeedFilter
        }
        roles {
          roles
        }
      }
      isUserInSameSilo 
    }
  }
  ${USER_FRAGMENT}
`;
var LIST_USERS = gql7`
  query ListUsers($searchQuery: String, $libraryUrl: String, $url: String!) {
    listUsers(searchQuery: $searchQuery, libraryUrl: $libraryUrl, url: $url) {
      ...UserFragment
      roles {
        customRolesOf(url: $url) {
          role
          url
        }
        roleOf(url: $url) {
          role
          url
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
var SEARCH_NETWORK_GROUP = gql7`
  query SearchNetworkGroup(
    $searchQuery: String
    $includeSelf: Boolean
    $includeTemp: Boolean
    $groupId: String
  ) {
    listUsersGroup(
      searchQuery: $searchQuery
      includeSelf: $includeSelf
      includeTemp: $includeTemp
      groupId: $groupId
    ) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
var SEARCH_NETWORK = gql7`
  query SearchNetwork(
    $searchQuery: String
    $includeSelf: Boolean
    $includeTemp: Boolean
    $groupId: String
  ) {
    listUsers(
      searchQuery: $searchQuery
      includeSelf: $includeSelf
      includeTemp: $includeTemp
      groupId: $groupId
    ) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
var SEARCH_NETWORK_WITH_GROUP_ROLE = gql7`
  query SearchNetworkWithGroupRole(
    $searchQuery: String
    $groupId: String!
    $includeSelf: Boolean
    $includeTemp: Boolean
  ) {
    listUsers(
      searchQuery: $searchQuery
      includeSelf: $includeSelf
      includeTemp: $includeTemp
      groupId: $groupId
    ) {
      ...UserFragment
      roleForGroup(groupId: $groupId)
    }
  }
  ${USER_FRAGMENT}
`;
var LIST_MEMBERS = gql7`
  query ListMembers(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $url: String!
    $sortType: String = "az"
    $roles: [String]
    $customRoles: [String]
  ) {
    listMembers(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      url: $url
      sortType: $sortType
      roles: $roles
      customRoles: $customRoles
    ) {
      ...UserFragment
      roles {
        customRolesOf(url: $url) {
          role
          url
        }
        roleOf(url: $url) {
          role
          url
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
var LIST_GROUPS = gql7`
  query ListGroups($searchQuery: String) {
    listGroups(searchQuery: $searchQuery) {
      ...GroupFragment
    }
  }
  ${GROUP_FRAGMENT}
`;
var MY_SIDEBAR = gql7`
  query MySidebar {
    mySidebar {
      people
      bookmarks
    }
  }
`;
var MY_COMMUNITY = gql7`
  query MyCommunity(
    $searchQuery: String
    $limit: Int
    $cursor: String
    $sortType: String = "az"
    $type: CommunityTypes
  ) {
    myCommunity(
      searchQuery: $searchQuery
      limit: $limit
      cursor: $cursor
      sortType: $sortType
      type: $type
    ) {
      __typename
      cursor
      hasMore
      results {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;
var MY_GROUPS = gql7`
  query MyGroups(
    $searchQuery: String
    $role: [String]
    $protectionLevel: [ViewPermission]
    $limit: Int
    $cursor: String
    $sortType: String = "az"
    $fullGroupsOnly: Boolean
  ) {
    myGroups(
      searchQuery: $searchQuery
      role: $role
      protectionLevel: $protectionLevel
      limit: $limit
      cursor: $cursor
      sortType: $sortType
      fullGroupsOnly: $fullGroupsOnly
    ) {
      __typename
      cursor
      hasMore
      numRemainingResults
      results {
        ...GroupFragment
      }
    }
  }
  ${GROUP_FRAGMENT}
`;
var POPULAR_GROUPS = gql7`
  query PopularGroups(
    $searchQuery: String
    $role: [String]
    $protectionLevel: [ViewPermission]
    $limit: Int = 10
    $cursor: String
    $sortType: String = "az"
    $host: String
  ) {
    popularGroups(
      searchQuery: $searchQuery
      role: $role
      protectionLevel: $protectionLevel
      limit: $limit
      cursor: $cursor
      sortType: $sortType
      host: $host
    ) {
      __typename
      cursor
      hasMore
      results {
        ...GroupFragment
      }
    }
  }
  ${GROUP_FRAGMENT}
`;
var GET_ADMIN_FORM = gql7`
  query GetAdminForm($url: String!) {
    getAdminForm(url: $url) {
      ...ContentCardLibraryFragment
      ...ContentCardShelfFragment
    }
  }
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
  ${CONTENT_CARD_SHELF_FRAGMENT}
`;
var GET_USERS_GROUPS = gql7`
  query GetUsersGroups {
    currentUser {
      membership {
        active {
          __typename
          ...ContentCardShelfFragment
        }
        activeLibrary {
          __typename
          ...ContentCardLibraryFragment
        }
        all {
          __typename
          _id
          url
          title
          media {
            thumb
            icon
          }
        }
      }
    }
  }

  ${CONTENT_CARD_LIBRARY_FRAGMENT}
  ${CONTENT_CARD_SHELF_FRAGMENT}
`;
var LIST_CHILD_GROUPS = gql7`
  query ListChildGroups(
    $_id: String
    $cursor: String
    $limit: Int = 100
    $includeGrandChildren: Boolean = false
  ) {
    groupById(_id: $_id) {
      __typename
      _id
      childGroups(
        cursor: $cursor
        limit: $limit
        includeGrandChildren: $includeGrandChildren
      ) {
        __typename
        hasMore
        cursor
        results {
          ...GroupFragment
        }
      }
    }
  }
  ${GROUP_FRAGMENT}
`;
var GET_DOMAIN_RECOMMENDATIONS_FOR_LOGGEDIN_USER = gql7`
  query DomainRecommendationsForLoggedInUser(
    $domain: DomainInput
    $limit: Int = 6
    $userQuery: String
    $cardQuery: String
  ) {
    domainRecommendationsForLoggedInUser(
      domain: $domain
      limit: $limit
      userQuery: $userQuery
      cardQuery: $cardQuery
    ) {
      user {
        ...UserFragment
      }
      userRecommendations {
        user {
          ...UserFragment
        }
        precision
        featureScores {
          field {
            id
            type
            prompt
            customText
          }
          score
        }
      }
      cardRecommendations {
        card {
          ...ContentCardFragment
          ...ContentCardShelfFragment
        }
        precision
        featureScores {
          field {
            id
            type
            prompt
            customText
          }
          score
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${CONTENT_CARD_SHELF_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
`;
var GET_RECOMMENDATIONS_FOR_USER = gql7`
  query GetRecommendationsForUser($input: RecommendationsForUserInput!) {
    recommendationsForUser(input: $input) {
      user {
        __typename
        _id
      }
      domains {
        type
        id
        ids
      }
      types
      limit
      recommendations {
        type
        cursor
        hasMore
        recommendations {
          ... on UserRecommendation {
            __typename
            user {
              ...UserFragment
            }
            precision
            featureScores {
              field {
                id
                type
                prompt
                customText
              }
              score
            }
          }
          ... on GroupRecommendation {
            __typename
            group {
              ...GroupFragment
            }
            precision
            featureScores {
              field {
                id
                type
                prompt
                customText
              }
              score
            }
          }
          ... on CardRecommendation {
            __typename
            card {
              ...ContentCardFragment
              ...ContentCardReferenceFragment
            }
            precision
            featureScores {
              field {
                id
                type
                prompt
                customText
              }
              score
            }
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var GET_GROUP_BY_SHORT_CODE = gql7`
  query GroupByShortCode(
    $shortCode: String!
    $translate: Boolean
    $targetLanguage: String
  ) {
    groupByShortCode(shortCode: $shortCode) {
      ...GroupFragment
      relevantSignupFields: allSignupFields(suppressCompletedSurveys: true) {
        parentGroup {
          _id
        }
        manager
        signupFields {
          id
          index
          type
          prompt(translate: $translate, targetLanguage: $targetLanguage)
          customText(translate: $translate, targetLanguage: $targetLanguage)
          options
          translatedOptions: options(
            translate: $translate
            targetLanguage: $targetLanguage
          )
          selectMultiple
          allowOther
          required
          showOnSignup
          groups {
            group {
              _id
              path
              groupActionsAllowed
              currentUserIsMember
              currentUserIsAdmin
              profile {
                lausannePdfUrl
                picture
                full_name
                short_bio
              }
              settings {
                approvalRequired
                protectionLevel
              }
            }
            id
            requireApproval
          }
          link
          dependsOn {
            id
            values
          }
          defaultComparator
          siblingId
        }
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      parentSubscriptions: parentGroups(paywallExists: true) {
        ...GroupFragment
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
      }
    }
  }
  ${GROUP_FRAGMENT}
`;
var GET_EVENT_GROUP = gql7`
  query GetEventGroup($_id: String!) {
    groupById(_id: $_id) {
      ...GroupEventFragment
      parentGroup {
        _id
        profile {
          lausannePdfUrl
          full_name
          coverPhoto
          picture
          website
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
    }
  }
  ${GROUP_EVENT_FRAGMENT}
`;
var GET_EVENT_SPEAKERS = gql7`
  query GetEventSpeakers($eventId: ID!) {
    getSpeakersForEvent(eventId: $eventId) {
      userAlias {
        url
        type
        thumb
        altBio
        altName
        useUserBio
      }
      user {
        ...UserFragment
      }
      sessions {
        ...TimeAndPlaceSettingsFragment
      }
    }
  }

  ${USER_FRAGMENT}
  ${TIME_AND_PLACE_SETTINGS_FRAGMENT}
`;
var GET_GROUP_PAGE_BY_ID = gql7`
  query GroupPageById(
    $_id: String!
    $accessByEmail: String
    $translate: Boolean
    $targetLanguage: String
    $suppressCompletedSurveys: Boolean = true
    $limit: Int = 15
    $cursor: String
    $childGroupsLimit: Int
  ) {
    groupById(_id: $_id, accessByEmail: $accessByEmail) {
      ...GroupFragment
      currentUserFreshCount
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
      relevantSignupFields: allSignupFields(
        suppressCompletedSurveys: $suppressCompletedSurveys
      ) {
        parentGroup {
          _id
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
            picture
            website
          }
        }
        manager
        signupFields {
          id
          index
          type
          prompt(translate: $translate, targetLanguage: $targetLanguage)
          customText(translate: $translate, targetLanguage: $targetLanguage)
          options
          translatedOptions: options(
            translate: $translate
            targetLanguage: $targetLanguage
          )
          selectMultiple
          allowOther
          required
          showOnSignup
          groups {
            group {
              _id
            }
            id
            requireApproval
          }
          link
          dependsOn {
            id
            values
          }
          defaultComparator
          siblingId
        }
      }
      fromTemplate {
        __typename
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      childGroups(limit: $childGroupsLimit) {
        __typename
        hasMore
        cursor
        results {
          ...GroupFragment
        }
        type
      }
      parentSubscriptions: parentGroups(paywallExists: true) {
        ...GroupFragment
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
      }
      personalBook {
        url
        children {
          title
          subtype
          url
          reference {
            href
          }
        }
      }
      channelsBook {
        title
        url
        media {
          thumb
          icon
        }
        children {
          _id
          title
          url
          childrenCount
          rankOrder
          reference {
            href
          }
        }
      }
      eventBook {
        url
        title
        children {
          _id
          title
          url
          rankOrder
          reference {
            href
          }
        }
      }
      tableOfContents(
        limit: $limit
        cursor: $cursor
        sortType: "reverseOrder"
      ) {
        __typename
        hasMore
        cursor
        results {
          ...ContentCardReferenceFragment
          ...ContentCardFragment
          ...ContentCardChapterChildrenFragment
        }
        type
      }
      settings {
        tocSettings {
          rankOrder {
            key
            value
          }
        }
      }
      chatroom {
        __typename
        _id
      }
      adminChatroom {
        __typename
        _id
      }
    }
  }
  ${GROUP_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var GET_GROUP_BY_ID = gql7`
  query GroupById(
    $_id: String!
    $accessByEmail: String
    $translate: Boolean
    $targetLanguage: String
    $suppressCompletedSurveys: Boolean = true
  ) {
    groupById(_id: $_id, accessByEmail: $accessByEmail) {
      ...GroupFragment
      relevantSignupFields: allSignupFields(
        suppressCompletedSurveys: $suppressCompletedSurveys
      ) {
        parentGroup {
          _id
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
            picture
            website
          }
        }
        manager
        signupFields {
          id
          index
          type
          prompt(translate: $translate, targetLanguage: $targetLanguage)
          customText(translate: $translate, targetLanguage: $targetLanguage)
          options
          translatedOptions: options(
            translate: $translate
            targetLanguage: $targetLanguage
          )
          selectMultiple
          allowOther
          required
          showOnSignup
          groups {
            group {
              _id
            }
            id
            requireApproval
          }
          link
          dependsOn {
            id
            values
          }
          defaultComparator
          siblingId
        }
      }
      fromTemplate {
        __typename
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      childGroups {
        __typename
        hasMore
        cursor
        results {
          ...GroupFragment
        }
        type
      }
      parentSubscriptions: parentGroups(paywallExists: true) {
        ...GroupFragment
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
        currentUserHasPreAuthorizedPayment
      }
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
      currentUserHasPreAuthorizedPayment
    }
  }
  ${GROUP_FRAGMENT}
`;
var GET_GROUP_DASHBOARD_SOURCE = gql7`
  query GroupDashboardSource(
    $_id: String!
    $suppressMembersDashboard: Boolean = false
    $suppressPublicDashboard: Boolean = false
  ) {
    groupById(_id: $_id) {
      publicDashboardSource: dashboardSource(subtype: "PUBLIC_DASHBOARD")
        @skip(if: $suppressPublicDashboard) {
        subtype
        ...ContentCardReferenceFragment
        ...ContentCardFragment
      }
      membersDashboardSource: dashboardSource(subtype: "DASHBOARD")
        @skip(if: $suppressMembersDashboard) {
        subtype
        ...ContentCardReferenceFragment
        ...ContentCardFragment
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var GET_GROUP_DASHBOARD = gql7`
  query GroupDashboard(
    $_id: String!
    $limit: Int = 15
    $cursor: String
    $suppressMembersDashboard: Boolean = false
    $suppressPublicDashboard: Boolean = false
    $suppressChildGroups: Boolean = false
  ) {
    groupById(_id: $_id) {
      ...GroupFragment
      currentUserFreshCount
      publicDashboardSource: dashboardSource(subtype: "PUBLIC_DASHBOARD")
        @skip(if: $suppressPublicDashboard) {
        subtype
        ...ContentCardReferenceFragment
        ...ContentCardFragment
        ...ContentCardChapterChildrenFragment
      }
      membersDashboardSource: dashboardSource(subtype: "DASHBOARD")
        @skip(if: $suppressMembersDashboard) {
        subtype
        ...ContentCardReferenceFragment
        ...ContentCardFragment
        ...ContentCardChapterChildrenFragment
      }
      personalBook {
        url
        children {
          title
          subtype
          url
          reference {
            href
          }
        }
      }
      channelsBook {
        title
        url
        media {
          thumb
          icon
        }
        children {
          _id
          title
          url
          rankOrder
          reference {
            href
          }
        }
      }
      membersDashboard: dashboard(
        limit: $limit
        cursor: $cursor
        sortType: "ordered"
        subtype: "DASHBOARD"
      ) @skip(if: $suppressMembersDashboard) {
        ...GroupDashboardFragment
      }
      publicDashboard: dashboard(
        limit: $limit
        cursor: $cursor
        sortType: "ordered"
        subtype: "PUBLIC_DASHBOARD"
      ) @skip(if: $suppressPublicDashboard) {
        ...GroupDashboardFragment
      }

      childGroups @skip(if: $suppressChildGroups) {
        __typename
        hasMore
        cursor
        results {
          ...GroupFragment
        }
        type
      }

      parentSubscriptions: parentGroups(paywallExists: true) {
        ...GroupFragment
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
      }

      tableOfContents(
        limit: $limit
        cursor: $cursor
        sortType: "reverseOrder"
      ) {
        __typename
        hasMore
        cursor
        results {
          ...ContentCardReferenceFragment
          ...ContentCardFragment
          ...ContentCardChapterChildrenFragment
        }
        type
      }
      settings {
        tocSettings {
          rankOrder {
            key
            value
          }
        }
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      chatroom {
        __typename
        _id
      }
      adminChatroom {
        __typename
        _id
      }
    }
  }
  ${GROUP_FRAGMENT}
  ${GROUP_DASHBOARD_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var GET_GROUP_MEMBERSHIP = gql7`
  query GroupMembership(
    $_id: String!
    $ownersLimit: Int = 20
    $membersLimit: Int = 20
    $ownersCursor: String
    $membersCursor: String
  ) {
    groupById(_id: $_id) {
      ...GroupMembershipFragment
    }
  }

  ${GROUP_MEMBERSHIP_FRAGMENT}
`;
var VALIDATE_GROUP_USERNAME = gql7`
  query ValidateGroupUsername($username: String!) {
    validateGroupUsername(username: $username)
  }
`;
var VALIDATE_GROUP_SHORTCODE = gql7`
  query ValidateGroupShortcode($shortCode: String!) {
    validateGroupShortcode(shortCode: $shortCode)
  }
`;
var VALIDATE_USER_EMAIL = gql7`
  query ValidateUserEmail(
    $email: String!
    $enforceTempUserEmailValidation: Boolean = false
  ) {
    validateUserEmail(
      email: $email
      enforceTempUserEmailValidation: $enforceTempUserEmailValidation
    )
  }
`;
var VALIDATE_USERNAME = gql7`
  query ValidateUsername($username: String!) {
    validateUsername(username: $username)
  }
`;
var VALIDATE_PHONE_NUMBER = gql7`
  query ValidatePhoneNumber(
    $phone: String!
    $enforceTempUserPhoneValidation: Boolean = false
    $email: String
  ) {
    validatePhoneNumber(
      phone: $phone
      enforceTempUserPhoneValidation: $enforceTempUserPhoneValidation
      email: $email
    ) {
      userExists
      isPhoneOnlyUser
    }
  }
`;
var GET_PAGINATED_GROUP_TEMPLATES = gql7`
  query PaginatedGroupTemplates(
    $searchQuery: String
    $limit: Int
    $cursor: String
    $sortType: String = "az"
    $subtypes: [String]
  ) {
    paginatedGroupTemplates(
      searchQuery: $searchQuery
      limit: $limit
      cursor: $cursor
      sortType: $sortType
      subtypes: $subtypes
    ) {
      cursor
      hasMore
      results {
        ...GroupFragment
      }
      type
    }
  }
  ${GROUP_FRAGMENT}
`;
var GET_PLEDGES_FOR_GROUP = gql7`
  query GetPledgesForGroup($groupId: String!) {
    getPledgesForGroup(groupId: $groupId) {
      __typename
      _id
      url
      title
      pledgeItems {
        ...ContentCardPledgesFragment
      }
      ...ContentCardFragment
    }
  }
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
`;
var GET_CONTENT_FOR_STREAMS = gql7`
  query GetContentForStreams($input: [StreamContentSearchInput]!) {
    getContentForStreams(input: $input) {
      display
      stream
    }
  }
`;
var GET_GROUP_CUSTOM_FIELD_CSV = gql7`
  query GetGroupCustomFieldCsv($groupId: String!) {
    getGroupCustomFieldCsv(groupId: $groupId)
  }
`;
var GET_USER_CUSTOM_FIELDS = gql7`
  query GetUserCustomFields(
    $_id: ID!
    $limit: Int = 50
    $recommendationsOn: Boolean
    $hasSurveyQuestions: Boolean
    $cursor: String
  ) {
    user(_id: $_id) {
      activeGroup {
        _id
      }
      groups(
        limit: $limit
        recommendationsOn: $recommendationsOn
        hasSurveyQuestions: $hasSurveyQuestions
        cursor: $cursor
      ) {
        results {
          _id
          profile {
            lausannePdfUrl
            full_name
            short_bio
            picture
          }
          settings {
            recommendations
            customSignupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              showOnSignup
              groups {
                group {
                  _id
                }
                id
                requireApproval
              }
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
          currentUserIsAdmin
          currentUserHasPreAuthorizedPayment
          currentUserIsDirectAdmin
          currentUserIsMember
        }
        cursor
        hasMore
        type
      }
      profile {
        lausannePdfUrl
        customFields {
          parentGroup {
            _id
          }
          manager
          values {
            id
            values
            type
            comparator
          }
        }
      }
    }
    getPlatform {
      title
      _id
      __typename
      body
      media {
        thumb
        icon
        logoLight
        logoDark
      }
      url
      platformSettings {
        platformGroupOwner
        forms {
          respondents
          formCardUrl
          formSpecificationSettings {
            type
            form {
              fieldGroups {
                groupName
                index
                fieldIds
              }
              required
              fields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                required
                showOnSignup
                groups {
                  group {
                    _id
                  }
                }
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
            }
          }
        }
      }
    }
  }
`;
var GET_USER_CUSTOM_FIELDS_ONLY = gql7`
  query GetUserCustomFieldsOnly($_id: ID!) {
    user(_id: $_id) {
      profile {
        lausannePdfUrl
        customFields {
          parentGroup {
            _id
          }
          manager
          values {
            id
            values
            type
            comparator
          }
        }
      }
    }
    getPlatform {
      title
      _id
      __typename
      body
      media {
        thumb
        icon
        logoLight
        logoDark
      }
      url
      platformSettings {
        platformGroupOwner
        forms {
          respondents
          formCardUrl
          formSpecificationSettings {
            type
            form {
              fieldGroups {
                groupName
                index
                fieldIds
              }
              required
              fields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                required
                showOnSignup
                groups {
                  group {
                    _id
                  }
                  id
                  requireApproval
                }
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
            }
          }
        }
      }
    }
  }
`;
var GET_JOIN_GROUP_INFO = gql7`
  query GetJoinGroupInfo($_id: String!) {
    groupById(_id: $_id) {
      _id
      path
      groupActionsAllowed
      currentUserIsMember
      currentUserIsAdmin
      profile {
        picture
        full_name
        short_bio
      }
      settings {
        approvalRequired
        protectionLevel
      }
    }
  }
`;
var GET_GROUP_CUSTOM_FIELDS = gql7`
  query GetGroupCustomFields($_id: String!) {
    groupById(_id: $_id) {
      ...GroupOptimizerDataFragment
      profile {
        lausannePdfUrl
        customFields {
          parent {
            url
            _id
          }
          manager
          parentGroup {
            _id
          }
          values {
            id
            values
            type
            comparator
          }
        }
      }
      allSignupFields {
        parentGroup {
          ...GroupOptimizerDataFragment
        }
      }
    }
  }

  ${GROUP_OPTIMIZER_DATA_FRAGMENT}
`;
var VALIDATE_COUPON = gql7`
  query ValidateCoupon($input: CouponCodeForProductInput) {
    validateCoupon(input: $input) {
      valid
      message
      coupon {
        name
        code
        percentage
        type
        expiration
      }
      productsWithCoupons {
        coupon {
          name
          code
          percentage
          type
          expiration
        }
        product {
          _id
          name
          description
          price
          currency
          type
        }
      }
    }
  }
`;
var CONVERT_CURRENCY = gql7`
  query ConvertCurrency($to: String!, $from: String!, $amount: String!) {
    convertCurrency(to: $to, from: $from, amount: $amount) {
      value
    }
  }
`;
var GET_PRODUCTS_FOR_GROUP = gql7`
  query GetProductsForGroups($groupId: ID!) {
    getProductsForGroup(groupId: $groupId) {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      coupons {
        name
        code
        percentage
        type
        expiration
      }
    }
  }
`;
var GET_PRODUCT_BY_ID = gql7`
  query GetProductById($_id: ID!) {
    getProduct(_id: $_id) {
      _id
      name
      description
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      coupons {
        name
        code
        percentage
        type
        expiration
      }
    }
  }
`;
var GET_USER_ORDERS = gql7`
  query GetUserOrders($statuses: [String]) {
    getOrdersForLoggedInUser(statuses: $statuses) {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          coupons {
            name
            code
            percentage
            type
            expiration
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      productInstances {
        _id
        transferToken
        isCheckedIn
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
        purchaser {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            currency
            created
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          currency
          created
        }
      }
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
        }
      }
      created
      updated
      status
      paymentType
      discountedTotal
    }
  }
`;
var GET_USER_SUBSCRIPTION_ORDERS = gql7`
  query GetAllSubscriptionOrdersForLoggedInUser($userId: ID!) {
    getAllSubscriptionOrdersForLoggedInUser(userId: $userId) {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              full_name
              coverPhoto
            }
          }
          coupons {
            name
            code
            percentage
            type
            expiration
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            currency
            created
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          currency
          created
        }
      }
      productInstances {
        _id
        isCheckedIn
        order {
          _id
        }
        transferToken
        transferRecipients {
          user {
            _id
            profile {
              full_name
              picture
            }
          }
          email
        }
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            full_name
            picture
          }
        }
        purchaser {
          _id
          profile {
            full_name
            picture
          }
        }
      }
      user {
        _id
        profile {
          full_name
        }
      }
      created
      updated
      status
      paymentType
    }
  }
`;
var GET_ORDER_BY_ID = gql7`
  query GetOrderById($_id: ID!) {
    getOrder(_id: $_id) {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          coupons {
            name
            code
            percentage
            type
            expiration
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            currency
            created
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          currency
          created
        }
      }
      productInstances {
        _id
        isCheckedIn
        order {
          _id
        }
        transferToken
        transferRecipients {
          user {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
            }
          }
          email
        }
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
        purchaser {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
      }
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
        }
      }
      created
      updated
      status
      paymentType
    }
  }
`;
var GET_PRE_AUTH_PAYMENT_ORDER_FOR_GROUP = gql7`
  query GetPreAuthPaymentOrderForGroup($userId: ID!, $groupId: ID!) {
    getPreAuthPaymentOrderForGroup(userId: $userId, groupId: $groupId) {
      _id
      preAuthPayment
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            currency
            created
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          currency
          created
        }
      }
      productInstances {
        _id
        transferToken
        isCheckedIn
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
        purchaser {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
      }
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
        }
      }
      created
      updated
      status
    }
  }
`;
var GET_USER_ORDERS_FOR_GROUP = gql7`
  query GetUserOrdersForGroup($groupId: ID!, $status: String = "succeeded") {
    getOrdersForUserForGroup(groupId: $groupId, status: $status) {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            currency
            created
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          currency
          created
        }
      }
      productInstances {
        _id
        transferToken
        isCheckedIn
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
        purchaser {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
      }
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
        }
      }
      created
      updated
      status
    }
  }
`;
var GET_ORDERS_FOR_GROUP = gql7`
  query GetOrdersForGroup($groupId: ID!, $statuses: [String]) {
    getOrdersForGroup(groupId: $groupId, statuses: $statuses) {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          coupons {
            name
            code
            percentage
            type
            expiration
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            currency
            created
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          currency
          created
        }
      }
      productInstances {
        _id
        isCheckedIn
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            lausannePdfUrl
            full_name
            email
            phone
            picture
          }
        }
        purchaser {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
      }
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
          email
          phone
        }
      }
      processedByUser {
        _id
        profile {
          full_name
        }
      }
      paymentType
      orderLocation
      created
      updated
      status
    }
  }
`;
var GET_ORDERS_FOR_GROUP_BY_PRODUCT_ID = gql7`
  query GetOrdersForGroupByProductId(
    $productId: ID!
    $groupId: ID!
    $statuses: [String]
  ) {
    getOrdersForGroupByProductId(
      productId: $productId
      groupId: $groupId
      statuses: $statuses
    ) {
      _id
      paymentIntent {
        id
        amount
        client_secret
        currency
        customer
        description
        next_action
        payment_method {
          id
          customer
          type
          object
          card {
            id
            brand
            last4
            exp_month
            exp_year
            funding
          }
        }
        receipt_email
        status
        livemode
        charges {
          object
          data {
            amount
            amount_refunded
            refunded
            refunds {
              object
              data {
                created
                amount
                status
                failure_reason
                reason
              }
            }
          }
          url
          has_more
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      purchaseRequests {
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          coupons {
            name
            code
            percentage
            type
            expiration
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        quantity
        couponCode
      }
      subscription {
        id
        currency
        cancel_at_period_end
        current_period_end
        current_period_start
        status
        created
        days_until_due
        livemode
        next_pending_invoice_item_invoice
      }
      subscriptionInvoices {
        cursor
        hasMore
        results {
          id
          auto_advance
          collection_method
          currency
          created
          description
          period_start
          period_end
          total
          payment_intent {
            id
            amount
            description
            client_secret
            payment_method {
              id
              customer
              type
              object
              card {
                id
                brand
                last4
                exp_month
                exp_year
                funding
              }
            }
          }
          status
          subscription {
            currency
            created
          }
        }
        type
      }
      subscriptionUpcomingInvoice {
        id
        description
        period_start
        period_end
        total
        subscription {
          currency
          created
        }
      }
      productInstances {
        _id
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
        purchaser {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
      }
      user {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
          email
          phone
        }
      }
      created
      updated
      status
    }
  }
`;
var GET_ORDERS_AND_PRODUCTS_FOR_GROUP = gql7`
  query GetOrdersAndProductsForGroup($groupId: ID!, $statuses: [String]) {
    getOrdersAndProductsForGroup(groupId: $groupId, statuses: $statuses) {
      orders {
        _id
        paymentIntent {
          id
          amount
          client_secret
          currency
          customer
          description
          next_action
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
          receipt_email
          status
          livemode
          charges {
            object
            data {
              amount
              amount_refunded
              refunded
              refunds {
                object
                data {
                  created
                  amount
                  status
                  failure_reason
                  reason
                }
              }
            }
            url
            has_more
          }
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        purchaseRequests {
          product {
            _id
            name
            description
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
            createdBy {
              profile {
                lausannePdfUrl
                full_name
                coverPhoto
              }
            }
            coupons {
              name
              code
              percentage
              type
              expiration
            }
            reference {
              ... on Group {
                _id
                currentUserIsAdmin
                currentUserHasPreAuthorizedPayment
                currentUserIsDirectAdmin
                profile {
                  lausannePdfUrl
                  full_name
                  picture
                  coverPhoto
                }
                timeAndPlaceSettings {
                  date
                  startTime
                  endTime
                }
                eventOverviewDetails {
                  title
                  description
                  headline
                  date
                  dates {
                    startDate
                    endDate
                  }
                  startTime
                  endTime
                  displayStartTime
                  displayEndTime
                  location {
                    formatted_address
                  }
                  link
                  rsvpLink
                  allDay
                  timeZone {
                    name
                  }
                  eventLocationSettings {
                    location {
                      formatted_address
                      city
                      state
                      country
                    }
                    type
                    link
                    venue {
                      name
                      link
                      location {
                        formatted_address
                        location {
                          type
                          coordinates
                        }
                        postal
                        city
                        state
                        country
                      }
                    }
                  }
                }
                settings {
                  protectionLevel
                }
                subtype
              }
            }
          }
          quantity
          couponCode
        }
        subscription {
          id
          currency
          cancel_at_period_end
          current_period_end
          current_period_start
          status
          created
          days_until_due
          livemode
          next_pending_invoice_item_invoice
        }
        subscriptionInvoices {
          cursor
          hasMore
          results {
            id
            auto_advance
            collection_method
            currency
            created
            description
            period_start
            period_end
            total
            payment_intent {
              id
              amount
              description
              client_secret
              payment_method {
                id
                customer
                type
                object
                card {
                  id
                  brand
                  last4
                  exp_month
                  exp_year
                  funding
                }
              }
            }
            status
            subscription {
              currency
              created
            }
          }
          type
        }
        subscriptionUpcomingInvoice {
          id
          description
          period_start
          period_end
          total
          subscription {
            currency
            created
          }
        }
        productInstances {
          _id
          isCheckedIn
          transferToken
          product {
            _id
            name
            description
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
            createdBy {
              profile {
                lausannePdfUrl
                full_name
                coverPhoto
              }
            }
            reference {
              ... on Group {
                _id
                profile {
                  lausannePdfUrl
                  full_name
                  picture
                  coverPhoto
                }
                eventOverviewDetails {
                  title
                  description
                  headline
                  date
                  dates {
                    startDate
                    endDate
                  }
                  startTime
                  endTime
                  displayStartTime
                  displayEndTime
                  location {
                    formatted_address
                  }
                  link
                  rsvpLink
                  allDay
                  timeZone {
                    name
                  }
                  eventLocationSettings {
                    location {
                      formatted_address
                      city
                      state
                      country
                    }
                    type
                    link
                    venue {
                      name
                      link
                      location {
                        formatted_address
                        location {
                          type
                          coordinates
                        }
                        postal
                        city
                        state
                        country
                      }
                    }
                  }
                }
                settings {
                  protectionLevel
                }
                subtype
              }
            }
          }
          owner {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              email
              phone
            }
          }
          purchaser {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              email
              phone
            }
          }
        }
        user {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
        created
        updated
        status
      }
      activeOrders {
        _id
        paymentIntent {
          id
          amount
          client_secret
          currency
          customer
          description
          next_action
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
          receipt_email
          status
          livemode
          charges {
            object
            data {
              amount
              amount_refunded
              refunded
              refunds {
                object
                data {
                  created
                  amount
                  status
                  failure_reason
                  reason
                }
              }
            }
            url
            has_more
          }
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        purchaseRequests {
          product {
            _id
            name
            description
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
            createdBy {
              profile {
                lausannePdfUrl
                full_name
                coverPhoto
              }
            }
            coupons {
              name
              code
              percentage
              type
              expiration
            }
            reference {
              ... on Group {
                _id
                currentUserIsAdmin
                currentUserHasPreAuthorizedPayment
                currentUserIsDirectAdmin
                profile {
                  lausannePdfUrl
                  full_name
                  picture
                  coverPhoto
                }
                timeAndPlaceSettings {
                  date
                  startTime
                  endTime
                }
                eventOverviewDetails {
                  title
                  description
                  headline
                  date
                  dates {
                    startDate
                    endDate
                  }
                  startTime
                  endTime
                  displayStartTime
                  displayEndTime
                  location {
                    formatted_address
                  }
                  link
                  rsvpLink
                  allDay
                  timeZone {
                    name
                  }
                  eventLocationSettings {
                    location {
                      formatted_address
                      city
                      state
                      country
                    }
                    type
                    link
                    venue {
                      name
                      link
                      location {
                        formatted_address
                        location {
                          type
                          coordinates
                        }
                        postal
                        city
                        state
                        country
                      }
                    }
                  }
                }
                settings {
                  protectionLevel
                }
                subtype
              }
            }
          }
          quantity
          couponCode
        }
        subscription {
          id
          currency
          cancel_at_period_end
          current_period_end
          current_period_start
          status
          created
          days_until_due
          livemode
          next_pending_invoice_item_invoice
        }
        subscriptionInvoices {
          cursor
          hasMore
          results {
            id
            auto_advance
            collection_method
            currency
            created
            description
            period_start
            period_end
            total
            payment_intent {
              id
              amount
              description
              client_secret
              payment_method {
                id
                customer
                type
                object
                card {
                  id
                  brand
                  last4
                  exp_month
                  exp_year
                  funding
                }
              }
            }
            status
            subscription {
              currency
              created
            }
          }
          type
        }
        subscriptionUpcomingInvoice {
          id
          description
          period_start
          period_end
          total
          subscription {
            currency
            created
          }
        }
        productInstances {
          _id
          isCheckedIn
          transferToken
          product {
            _id
            name
            description
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
            createdBy {
              profile {
                lausannePdfUrl
                full_name
                coverPhoto
              }
            }
            reference {
              ... on Group {
                _id
                profile {
                  lausannePdfUrl
                  full_name
                  picture
                  coverPhoto
                }
                eventOverviewDetails {
                  title
                  description
                  headline
                  date
                  dates {
                    startDate
                    endDate
                  }
                  startTime
                  endTime
                  displayStartTime
                  displayEndTime
                  location {
                    formatted_address
                  }
                  link
                  rsvpLink
                  allDay
                  timeZone {
                    name
                  }
                  eventLocationSettings {
                    location {
                      formatted_address
                      city
                      state
                      country
                    }
                    type
                    link
                    venue {
                      name
                      link
                      location {
                        formatted_address
                        location {
                          type
                          coordinates
                        }
                        postal
                        city
                        state
                        country
                      }
                    }
                  }
                }
                settings {
                  protectionLevel
                }
                subtype
              }
            }
          }
          owner {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              email
              phone
            }
          }
          purchaser {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              email
              phone
            }
          }
        }
        user {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
        created
        updated
        status
      }
      canceledOrders {
        _id
        paymentIntent {
          id
          amount
          client_secret
          currency
          customer
          description
          next_action
          payment_method {
            id
            customer
            type
            object
            card {
              id
              brand
              last4
              exp_month
              exp_year
              funding
            }
          }
          receipt_email
          status
          livemode
          charges {
            object
            data {
              amount
              amount_refunded
              refunded
              refunds {
                object
                data {
                  created
                  amount
                  status
                  failure_reason
                  reason
                }
              }
            }
            url
            has_more
          }
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              currentUserIsAdmin
              currentUserHasPreAuthorizedPayment
              currentUserIsDirectAdmin
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              timeAndPlaceSettings {
                date
                startTime
                endTime
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        purchaseRequests {
          product {
            _id
            name
            description
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
            createdBy {
              profile {
                lausannePdfUrl
                full_name
                coverPhoto
              }
            }
            coupons {
              name
              code
              percentage
              type
              expiration
            }
            reference {
              ... on Group {
                _id
                currentUserIsAdmin
                currentUserHasPreAuthorizedPayment
                currentUserIsDirectAdmin
                profile {
                  lausannePdfUrl
                  full_name
                  picture
                  coverPhoto
                }
                timeAndPlaceSettings {
                  date
                  startTime
                  endTime
                }
                eventOverviewDetails {
                  title
                  description
                  headline
                  date
                  dates {
                    startDate
                    endDate
                  }
                  startTime
                  endTime
                  displayStartTime
                  displayEndTime
                  location {
                    formatted_address
                  }
                  link
                  rsvpLink
                  allDay
                  timeZone {
                    name
                  }
                  eventLocationSettings {
                    location {
                      formatted_address
                      city
                      state
                      country
                    }
                    type
                    link
                    venue {
                      name
                      link
                      location {
                        formatted_address
                        location {
                          type
                          coordinates
                        }
                        postal
                        city
                        state
                        country
                      }
                    }
                  }
                }
                settings {
                  protectionLevel
                }
                subtype
              }
            }
          }
          quantity
          couponCode
        }
        subscription {
          id
          currency
          cancel_at_period_end
          current_period_end
          current_period_start
          status
          created
          days_until_due
          livemode
          next_pending_invoice_item_invoice
        }
        subscriptionInvoices {
          cursor
          hasMore
          results {
            id
            auto_advance
            collection_method
            currency
            created
            description
            period_start
            period_end
            total
            payment_intent {
              id
              amount
              description
              client_secret
              payment_method {
                id
                customer
                type
                object
                card {
                  id
                  brand
                  last4
                  exp_month
                  exp_year
                  funding
                }
              }
            }
            status
            subscription {
              currency
              created
            }
          }
          type
        }
        subscriptionUpcomingInvoice {
          id
          description
          period_start
          period_end
          total
          subscription {
            currency
            created
          }
        }
        productInstances {
          _id
          isCheckedIn
          transferToken
          product {
            _id
            name
            description
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
            createdBy {
              profile {
                lausannePdfUrl
                full_name
                coverPhoto
              }
            }
            reference {
              ... on Group {
                _id
                profile {
                  lausannePdfUrl
                  full_name
                  picture
                  coverPhoto
                }
                eventOverviewDetails {
                  title
                  description
                  headline
                  date
                  dates {
                    startDate
                    endDate
                  }
                  startTime
                  endTime
                  displayStartTime
                  displayEndTime
                  location {
                    formatted_address
                  }
                  link
                  rsvpLink
                  allDay
                  timeZone {
                    name
                  }
                  eventLocationSettings {
                    location {
                      formatted_address
                      city
                      state
                      country
                    }
                    type
                    link
                    venue {
                      name
                      link
                      location {
                        formatted_address
                        location {
                          type
                          coordinates
                        }
                        postal
                        city
                        state
                        country
                      }
                    }
                  }
                }
                settings {
                  protectionLevel
                }
                subtype
              }
            }
          }
          owner {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              email
              phone
            }
          }
          purchaser {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              email
              phone
            }
          }
        }
        user {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
        created
        updated
        status
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        sold
        reference {
          ... on Group {
            _id
            currentUserIsAdmin
            currentUserHasPreAuthorizedPayment
            currentUserIsDirectAdmin
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            timeAndPlaceSettings {
              date
              startTime
              endTime
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      giftedTickets {
        _id
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
          createdBy {
            profile {
              lausannePdfUrl
              full_name
              coverPhoto
            }
          }
          reference {
            ... on Group {
              _id
              profile {
                lausannePdfUrl
                full_name
                picture
                coverPhoto
              }
              eventOverviewDetails {
                title
                description
                headline
                date
                dates {
                  startDate
                  endDate
                }
                startTime
                endTime
                displayStartTime
                displayEndTime
                location {
                  formatted_address
                }
                link
                rsvpLink
                allDay
                timeZone {
                  name
                }
                eventLocationSettings {
                  location {
                    formatted_address
                    city
                    state
                    country
                  }
                  type
                  link
                  venue {
                    name
                    link
                    location {
                      formatted_address
                      location {
                        type
                        coordinates
                      }
                      postal
                      city
                      state
                      country
                    }
                  }
                }
              }
              settings {
                protectionLevel
              }
              subtype
            }
          }
        }
        owner {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
        purchaser {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
            email
            phone
          }
        }
      }
    }
  }
`;
var GET_PRODUCT_INSTANCE_FOR_USER_FOR_GROUP = gql7`
  query GetProductInstancesForUserForGroup($groupId: ID!) {
    getProductInstancesForUserForGroup(groupId: $groupId) {
      _id
      transferToken
      isCheckedIn
      transferRecipients {
        user {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
        email
      }
      order {
        _id
        created
        updated
        status
      }
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
    }
  }
`;
var GET_ALL_PRODUCT_INSTANCES_FOR_USER = gql7`
  query GetAllGiftedProductInstancesForUser($userId: String!) {
    getAllGiftedProductInstancesForUser(userId: $userId) {
      _id
      transferToken
      isCheckedIn
      transferRecipients {
        user {
          _id
          profile {
            lausannePdfUrl
            full_name
            picture
          }
        }
        email
      }
      order {
        _id
        created
        updated
        status
      }
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
      purchaser {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
    }
  }
`;
var GET_PRODUCT_INSTANCE = gql7`
  query GetProductInstanceById($productInstanceId: ID!) {
    getProductInstance(productInstanceId: $productInstanceId) {
      _id
      isCheckedIn
      transferToken
      product {
        _id
        name
        description
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
        createdBy {
          profile {
            lausannePdfUrl
            full_name
            coverPhoto
            email
            phone
          }
        }
        reference {
          ... on Group {
            _id
            profile {
              lausannePdfUrl
              full_name
              picture
              coverPhoto
            }
            eventOverviewDetails {
              title
              description
              headline
              date
              dates {
                startDate
                endDate
              }
              startTime
              endTime
              displayStartTime
              displayEndTime
              location {
                formatted_address
              }
              link
              rsvpLink
              allDay
              timeZone {
                name
              }
              eventLocationSettings {
                location {
                  formatted_address
                  city
                  state
                  country
                }
                type
                link
                venue {
                  name
                  link
                  location {
                    formatted_address
                    location {
                      type
                      coordinates
                    }
                    postal
                    city
                    state
                    country
                  }
                }
              }
            }
            settings {
              protectionLevel
            }
            subtype
          }
        }
      }
      owner {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
          email
          phone
        }
      }
      purchaser {
        _id
        profile {
          lausannePdfUrl
          full_name
          picture
        }
      }
    }
  }
`;
var VERIFY_PRODUCT_INSTANCE_TOKEN = gql7`
  query ValidateProductInstanceToken($productInstanceId: ID!, $token: String!) {
    validateProductInstanceToken(
      productInstanceId: $productInstanceId
      token: $token
    )
  }
`;
var GET_CHILD_ACCOUNTS = gql7`
  query GetChildrenAccounts($limit: Int, $cursor: String, $sortType: String) {
    getChildrenAccounts(limit: $limit, cursor: $cursor, sortType: $sortType) {
      type
      cursor
      hasMore
      results {
        _id
        profile {
          lausannePdfUrl
          full_name
          simple {
            age
            name
          }
          avatar {
            avatar
            skinTone
          }
        }
        featureSets
      }
    }
  }
`;
var GET_CHILD_ACCOUNTS_FOR_CLASSCODE = gql7`
  query GetChildrenAccountsForCode(
    $code: String
    $limit: Int
    $cursor: String
    $sortType: String
  ) {
    getChildrenAccountsForCode(
      code: $code
      limit: $limit
      cursor: $cursor
      sortType: $sortType
    ) {
      type
      cursor
      hasMore
      results {
        _id
        profile {
          lausannePdfUrl
          full_name
          simple {
            age
            name
          }
          avatar {
            avatar
            skinTone
          }
        }
        featureSets
      }
    }
  }
`;
var SEARCH_CHILD_ACCOUNTS_FOR_CLASSCODE = gql7`
  query SearchChildrenAccountsByCode($code: String!, $searchQuery: String) {
    searchChildrenAccountsByCode(code: $code, searchQuery: $searchQuery) {
      _id
      profile {
        lausannePdfUrl
        full_name
        simple {
          age
          name
        }
        avatar {
          avatar
          skinTone
        }
      }
      featureSets
    }
  }
`;
var GET_LOGGEDIN_CUSTOMER_CARD_BY_ID = gql7`
  query GetLoggedInCustomerCardById($cardId: String!) {
    getLoggedInCustomerCardById(cardId: $cardId) {
      id
      brand
      exp_month
      exp_year
      last4
      customer
    }
  }
`;
var GET_LOGGEDIN_CUSTOMER_CARDS = gql7`
  query GetLoggedInCustomerCards($limit: Int = 10, $cursor: String) {
    getLoggedInCustomerCards(limit: $limit, cursor: $cursor) {
      results {
        id
        brand
        exp_month
        exp_year
        last4
        customer
      }
      hasMore
      cursor
    }
  }
`;
var GET_LOGGEDIN_CUSTOMER_PAYMENT_METHODS = gql7`
  query GetLoggedInCustomerPaymentMethods(
    $paymentType: String = "card"
    $limit: Int = 10
    $cursor: String
  ) {
    getLoggedInCustomerPaymentMethods(
      paymentType: $paymentType
      limit: $limit
      cursor: $cursor
    ) {
      results {
        id
        customer
        type
        object
        card {
          brand
          exp_month
          exp_year
          last4
          funding
        }
      }
      hasMore
      cursor
    }
  }
`;
var VALIDATE_CHILD_VERIFICATION_CODE = gql7`
  query ValidateChildVerificationCode($code: String!, $isNewCode: Boolean) {
    validateChildVerificationCode(code: $code, isNewCode: $isNewCode) {
      isValid
      allowAccountSwitcherAccess
    }
  }
`;
var GET_CLAIM_ACCOUNT_USER_INFO = gql7`
  query GetClaimAccountUserInfo($claimToken: String!, $email: String!) {
    getClaimAccountUserInfo(claimToken: $claimToken, email: $email) {
      _id
      type
      profile {
        lausannePdfUrl
        email
        full_name
        first_name
        last_name
        phone
      }
    }
  }
`;
var GET_NUMBER_OF_USERS_FOR_CODE = gql7`
  query GetNumberOfUsersForCode($code: String!) {
    getNumberOfUsersForCode(code: $code)
  }
`;
var LIST_INVOICES_FOR_USER = gql7`
  query ListInvoicesForUser($input: ListInvoiceInput) {
    listInvoicesForUser(input: $input) {
      type
      cursor
      hasMore
      results {
        id
        created
        billing_reason
        description
        period_start
        period_end
        total
        payment_intent {
          id
          amount
          description
          created
        }
        subscription {
          currency
          created
          description
        }
        lines {
          object
          data {
            id
            amount
            description
            plan {
              id
              amount
            }
          }
        }
      }
    }
  }
`;
var GET_LAST_TIME_USER_DISMISSED_GROUP_SURVEY = gql7`
  query GetLastTimeUserDismissedGroupSurvey($groupId: String!) {
    getLastTimeUserDismissedGroupSurvey(groupId: $groupId)
  }
`;
var CURRENT_USER_IS_ADMIN_OF_ANY_GROUP = gql7`
  query CurrentUserIsAdminOfAnyGroup {
    currentUserIsAdminOfAnyGroup
  }
`;
var GET_USER_FROM_DID = gql7`
  query GetUserFromDid($did: String!) {
    getUserFromDid(did: $did) {
      _id
      type
      profile {
        lausannePdfUrl
        email
        short_bio
        picture
        coverPhoto
        full_name
        first_name
        last_name
        phone
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
        protectionLevel
        country
        website
        location {
          formatted_address
          location {
            type
            coordinates
          }
          city
          state
          country
          postal
        }
        did
      }
    }
  }
`;
var GET_USERS_FROM_DID = gql7`
  query GetUsersFromDid($dids: [String!]!) {
    getUsersFromDid(dids: $dids) {
      _id
      type
      profile {
        lausannePdfUrl
        email
        short_bio
        picture
        coverPhoto
        full_name
        first_name
        last_name
        phone
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
        protectionLevel
        country
        website
        location {
          formatted_address
          location {
            type
            coordinates
          }
          city
          state
          country
          postal
        }
        did
      }
    }
  }
`;
var GET_STREAM_GROUP_FEED = gql7`
  query GetStreamGroupFeed($groupId: ID!, $limit: Int, $cursor: String) {
    getStreamGroupFeed(groupId: $groupId, limit: $limit, cursor: $cursor) {
      cursor
      hasMore
      results {
        id
        actor
        verb
        object
        postUrl
      }
    }
  }
`;
var GET_STREAM_GROUP_DASH_FEED = gql7`
  query GetStreamGroupDashFeed($groupId: ID!, $limit: Int, $cursor: String) {
    getStreamGroupDashFeed(groupId: $groupId, limit: $limit, cursor: $cursor) {
      cursor
      hasMore
      results {
        id
        actor
        verb
        object
        postUrl
      }
    }
  }
`;
var GET_VC_FOR_GROUP = gql7`
  query GetVCForGroup($groupId: String!) {
    getVCForGroup(groupId: $groupId)
  }
`;
export {
  CONVERT_CURRENCY,
  CURRENT_USER_IS_ADMIN_OF_ANY_GROUP,
  GET_ADMIN_FORM,
  GET_ALL_PRODUCT_INSTANCES_FOR_USER,
  GET_CHILD_ACCOUNTS,
  GET_CHILD_ACCOUNTS_FOR_CLASSCODE,
  GET_CLAIM_ACCOUNT_USER_INFO,
  GET_CONNECTIONS,
  GET_CONNECTIONS_FOR_USER,
  GET_CONTENT_FOR_STREAMS,
  GET_CURRENT_USER,
  GET_CURRENT_USER_FEED,
  GET_CURRENT_USER_QUICK_LINKS,
  GET_CURRENT_USER_SIMPLE,
  GET_DOMAIN_RECOMMENDATIONS_FOR_LOGGEDIN_USER,
  GET_EVENT_GROUP,
  GET_EVENT_SPEAKERS,
  GET_GROUP_BY_ID,
  GET_GROUP_BY_SHORT_CODE,
  GET_GROUP_CUSTOM_FIELDS,
  GET_GROUP_CUSTOM_FIELD_CSV,
  GET_GROUP_DASHBOARD,
  GET_GROUP_DASHBOARD_SOURCE,
  GET_GROUP_MEMBERSHIP,
  GET_GROUP_PAGE_BY_ID,
  GET_JOIN_GROUP_INFO,
  GET_LAST_TIME_USER_DISMISSED_GROUP_SURVEY,
  GET_LOGGEDIN_CUSTOMER_CARDS,
  GET_LOGGEDIN_CUSTOMER_CARD_BY_ID,
  GET_LOGGEDIN_CUSTOMER_PAYMENT_METHODS,
  GET_NUMBER_OF_USERS_FOR_CODE,
  GET_ORDERS_AND_PRODUCTS_FOR_GROUP,
  GET_ORDERS_FOR_GROUP,
  GET_ORDERS_FOR_GROUP_BY_PRODUCT_ID,
  GET_ORDER_BY_ID,
  GET_PAGINATED_GROUP_TEMPLATES,
  GET_PAGINATED_USER_FEED,
  GET_PLEDGES_FOR_GROUP,
  GET_PRE_AUTH_PAYMENT_ORDER_FOR_GROUP,
  GET_PRODUCTS_FOR_GROUP,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_INSTANCE,
  GET_PRODUCT_INSTANCE_FOR_USER_FOR_GROUP,
  GET_RECOMMENDATIONS_FOR_USER,
  GET_STREAM_GROUP_DASH_FEED,
  GET_STREAM_GROUP_FEED,
  GET_USER,
  GET_USERS,
  GET_USERS_FROM_DID,
  GET_USERS_GROUPS,
  GET_USERS_IN_SAME_SILO,
  GET_USER_BY_EMAIL,
  GET_USER_BY_PHONE,
  GET_USER_CUSTOM_FIELDS,
  GET_USER_CUSTOM_FIELDS_ONLY,
  GET_USER_FROM_DID,
  GET_USER_ORDERS,
  GET_USER_ORDERS_FOR_GROUP,
  GET_USER_PAGE,
  GET_USER_PAGE_SIMPLE,
  GET_USER_STRINGID,
  GET_USER_SUBSCRIPTION_ORDERS,
  GET_VC_FOR_GROUP,
  LIST_CHILD_GROUPS,
  LIST_GROUPS,
  LIST_INVOICES_FOR_USER,
  LIST_MEMBERS,
  LIST_USERS,
  MY_COMMUNITY,
  MY_GROUPS,
  MY_SIDEBAR,
  POPULAR_GROUPS,
  SEARCH_CHILD_ACCOUNTS_FOR_CLASSCODE,
  SEARCH_NETWORK,
  SEARCH_NETWORK_GROUP,
  SEARCH_NETWORK_WITH_GROUP_ROLE,
  VALIDATE_CHILD_VERIFICATION_CODE,
  VALIDATE_COUPON,
  VALIDATE_GROUP_SHORTCODE,
  VALIDATE_GROUP_USERNAME,
  VALIDATE_PHONE_NUMBER,
  VALIDATE_USERNAME,
  VALIDATE_USER_EMAIL,
  VERIFY_PRODUCT_INSTANCE_TOKEN
};
