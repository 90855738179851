import _ from 'lodash';
import { TFunction } from 'i18next';
import { CARD_SUBTYPES } from '@core/utilities/constants/card_types';
import {
    DISP_REF_BOOK,
    DISP_REF_CHAPTER,
    DISP_REF_GROUP,
    DISP_REF_LIBRARY,
    DISP_REF_PROMPT,
    DISP_REF_SHELF,
    DISP_REF_USER,
} from '@core/utilities/constants/library_settings';
import { CARD_SUBTYPE_DISTINCT_EVENT, CARD_EVENT } from '@core/utilities/constants/card_types';

import { PerformantMaybify } from '@core/types/Utilities';
import { ContentCard, ReferenceData, ReferenceType, User } from '@shared/welibrary-graphql/types';
import { ReferenceArray } from '@core/types/Reference';

import getLogger from '@core/logger';

const logger = getLogger(module);

export const generateDisplayReferences = (
    source: PerformantMaybify<ContentCard>,
    showFullReference?: boolean
): Array<ReferenceType | null | undefined> | false => {
    if (!source.reference) return false;

    const {
        reference: { chapterSource, bookSource, shelfSource, librarySource, groupSource },
    } = source;

    if (!chapterSource && !bookSource && !shelfSource && !librarySource && !groupSource) {
        return false;
    }

    let displayReference: Array<ReferenceType | null | undefined> = [
        DISP_REF_GROUP,
        DISP_REF_CHAPTER,
    ];

    if (shelfSource?.library_settings) {
        displayReference = shelfSource.library_settings.displayReference || displayReference;
        // TODO: This is a duck tape solution to group admin announcements
        displayReference = [...displayReference, DISP_REF_GROUP];
    }

    if (bookSource?.library_settings) {
        displayReference = bookSource.library_settings.displayReference || displayReference;
    }

    if (chapterSource?.library_settings?.displayReference) {
        displayReference = chapterSource.library_settings.displayReference || displayReference;
    }

    if (source.library_settings?.displayReference) {
        displayReference = source.library_settings.displayReference || displayReference;
    }

    if (showFullReference) {
        displayReference = displayReference.includes(DISP_REF_GROUP)
            ? [DISP_REF_GROUP]
            : [DISP_REF_LIBRARY, DISP_REF_SHELF, DISP_REF_BOOK, DISP_REF_CHAPTER, DISP_REF_PROMPT];
    }

    // Events should always display as '{group} • Events'
    if (
        chapterSource?.subtype === CARD_SUBTYPE_DISTINCT_EVENT &&
        chapterSource.library_settings?.displayReference
    ) {
        displayReference = [DISP_REF_CHAPTER, DISP_REF_GROUP];
    }

    return displayReference;
};

export const shouldShowReference = _.curry(
    (
        reference: PerformantMaybify<ReferenceData>,
        displayReference: Array<ReferenceType | null | undefined>,
        refType: ReferenceType
    ) => {
        const { chapterSource, bookSource, librarySource, groupSource } = reference;

        if (refType === DISP_REF_LIBRARY) {
            return displayReference.includes(refType) && librarySource;
        }

        if (refType === DISP_REF_SHELF) return false;

        if (refType === DISP_REF_BOOK) return displayReference.includes(refType) && bookSource;

        if (refType === DISP_REF_CHAPTER) {
            return displayReference.includes(refType) && chapterSource;
        }

        if (refType === DISP_REF_GROUP) return displayReference.includes(refType) && groupSource;

        if (refType === DISP_REF_PROMPT) {
            return (
                displayReference.includes(refType) &&
                (chapterSource?.prompt || chapterSource?.title)
            );
        }

        if (refType === DISP_REF_USER) {
            return displayReference.includes(refType) || chapterSource?.title?.includes('Userfeed');
        }

        return false;
    }
);

export const constructUrl = (
    reference: ReferenceData,
    author: User | null | undefined,
    itemType: ReferenceType
) => {
    const { chapterSource, bookSource, shelfSource, groupSource, isResource, href } = reference;

    try {
        switch (itemType) {
            case DISP_REF_BOOK:
                if (!shelfSource || !bookSource) return '#';

                if (chapterSource) {
                    return `/s/${shelfSource.url}/${bookSource.url}/${chapterSource.url}`;
                }

                return `/s/${shelfSource.url}/${bookSource.url}`;
            case DISP_REF_CHAPTER:
                if (isResource) return href ?? '#';

                if (!shelfSource || !bookSource) return '#';

                return `/s/${shelfSource.url}/${bookSource.url}/${chapterSource?.url ?? ''}`;
            case DISP_REF_LIBRARY:
                // TODO: Put in library url when we have library pages...
                return '#';
            case DISP_REF_SHELF:
                return shelfSource ? `/s/${shelfSource.url}` : '#';
            case DISP_REF_GROUP:
                return groupSource ? `/g/${groupSource._id}` : '#';
            case DISP_REF_USER:
                return `/u/${author?._id}`;
            default:
                return '#';
        }
    } catch (error) {
        logger.error(error);
        return '#';
    }
};

export const generateReferenceArray = (
    t: TFunction,
    source: PerformantMaybify<ContentCard>,
    displayReference: Array<ReferenceType | null | undefined> | false
): ReferenceArray | false => {
    if (!source.reference || !displayReference) return false;

    const { reference, author } = source;

    const { chapterSource, bookSource, shelfSource, librarySource, groupSource } = reference;

    const shouldShow = shouldShowReference(reference, displayReference);

    let referenceArray: ReferenceArray = [];

    if (shouldShow(DISP_REF_CHAPTER)) {
        referenceArray = [
            {
                type: DISP_REF_CHAPTER,
                value: chapterSource!.title ?? '',
            },
        ];
    }

    if (shouldShow(DISP_REF_BOOK)) {
        referenceArray.push({
            type: DISP_REF_BOOK,
            value: bookSource!.title ?? '',
            preposition: t('common:global.preposition.in'),
        });
    }

    if (shouldShow(DISP_REF_SHELF)) {
        referenceArray.push({
            type: DISP_REF_SHELF,
            value: shelfSource!.title ?? '',
            preposition: t('common:global.preposition.on'),
        });
    }

    if (shouldShow(DISP_REF_LIBRARY)) {
        referenceArray.push({
            type: DISP_REF_LIBRARY,
            value: librarySource!.title ?? '',
            preposition: t('common:global.preposition.in'),
        });
    }

    if (shouldShow(DISP_REF_GROUP)) {
        // currently announcements created by admins always have the 'Announcement' string
        // hard coded as the contentCard.title & is of contentCard.type -> text
        // in the future when announcements are extended to include more dynamic data,
        // we should create a type || subType 'announcement' so we can distinguish announcement cards
        // from other cards .., this'll work for current announcements
        const isAnnouncement =
            source?.subtype === CARD_SUBTYPES.ADMIN_ANNOUNCEMENT ||
            (source?.title?.toLowerCase() === 'announcement' && source?.type === 'text');

        if (isAnnouncement) {
            referenceArray = [
                {
                    type: DISP_REF_GROUP,
                    value: `Announcement from ${groupSource!.profile?.full_name}`,
                },
            ];
        } else if (displayReference.length > 1 && !isAnnouncement) {
            // if the displayReference[] contains ['group'] && [].length > 1
            // combine reference(s) ...
            // otherwise show the default group reference header -> 'In from {groupName}'
            referenceArray.push({
                type: DISP_REF_GROUP,
                value: `${groupSource!.profile?.full_name}`,
                preposition: t('common:global.preposition.in'),
            });
        } else {
            referenceArray = [
                {
                    type: DISP_REF_GROUP,
                    value: `${groupSource!.profile?.full_name}`,
                },
            ];
        }
    }

    if (shouldShow(DISP_REF_USER)) {
        referenceArray = [
            {
                type: DISP_REF_USER,
                value: `${author?.profile?.full_name}'s ${t('common:global.nouns.userfeed')}`,
            },
        ];
    }

    return referenceArray;
};

export const generateReferenceTypeLabelForGroup = (groupType: string | null | undefined) => {
    if (groupType === CARD_EVENT) {
        return `common:global.constants.cards.types.group`;
    }

    return 'common:global.nouns.group';
};
