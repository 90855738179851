import React, { CSSProperties, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { ApolloError } from '@apollo/client';
import _ from 'lodash';

import searchStore from '@stores/Search';

import LoadingLine from '@components/generic/loading/LoadingLine';
import useModal from '@components/modals/hooks/useModal';
import LottieLoading from '@components/generic/loading/LottieLoading';
import PaginationAnimation from '@components/generic/loading/lotties/square-loader.json';
import { DEFAULT_FILTERS, timer, FILTER_TYPES, getFilterType } from '@components/search/constants';
import { capitalize } from '@helpers/string.helpers';
import { SearchFilterType } from '@shared/welibrary-graphql/types';
import SearchFiltersMenu from '@components/search/SearchFiltersMenu';
import SearchScope from '@components/search/SearchScope';
import SearchResults from '@components/search/SearchResults';
import SearchInput from '@components/search/SearchInput';
import { PaginatedSearchResults } from '@shared/welibrary-graphql/content_card/queries.hook';

type SearchOverlayProps = {
    error?: ApolloError;
    searchResults?: PaginatedSearchResults;
    hasMoreResults?: boolean;
    loading: boolean;
};

const searchFilterToInputPlaceholder = (
    searchFilter: SearchFilterType[],
    t: TFunction<'translation', undefined>
) => {
    const searchFilterValueToInputPlaceHolder = (searchFilterValue: SearchFilterType) => {
        switch (searchFilterValue) {
            case SearchFilterType.Group:
                return t('common:search_for_groups...', 'search for groups...');
            case SearchFilterType.User:
                return t('common:search_for_people...', 'search for people...');
            case SearchFilterType.Collection:
                return t('common:search_for_collections...', 'search for collections...');
            case SearchFilterType.Prompt:
                return t('common:search_for_prompts...', 'search for prompts...');
            case SearchFilterType.Post:
                return t('common:search_for_content...', 'search for content...');
            default:
                return null;
        }
    };
    // If search is being filtered by only 1 filter, then the input placeholder should specify what we're filtering for.
    if (searchFilter.length === 1) {
        const searchFilterValue = searchFilter[0];
        return capitalize(searchFilterValueToInputPlaceHolder(searchFilterValue));
    }
    return null;
};

const SearchOverlay: React.FC<SearchOverlayProps> = ({
    error,
    searchResults,
    hasMoreResults,
    loading,
}) => {
    const { t } = useTranslation();
    const { closeAllModals } = useModal();

    const {
        query: searchQuery,
        filter: searchFilter,
        currentScope,
        queryScopes,
        isVisible: isSearchOverlayOpen,
        refreshing,
    } = searchStore.useStore();

    const [animationStyle, setAnimationStyle] = useState<{
        slide?: CSSProperties;
        dimmer?: CSSProperties;
    }>({});

    useEffect(() => {
        let _scope = _.clone(queryScopes);

        if (currentScope) {
            _scope = [currentScope.scope ?? {}];
            searchStore.set.queryScopes(_scope);
            searchStore.set.setDefaultFilter();
        }

        if (currentScope === null && searchFilter === DEFAULT_FILTERS) {
            searchStore.set.queryScopes([]);
            searchStore.set.setDefaultFilter();
        }
    }, [currentScope]);

    const isLoading = false;

    const unloadAnimation = () => {
        timer(100).then(() =>
            setAnimationStyle({
                slide: {
                    transformStyle: 'preserve-3d',
                    transform: 'translateX(0px) translateY(100%) translateZ(0px)',
                    transition: 'transform 0.3s ease 0s',
                },
                dimmer: {
                    transition: 'opacity 300ms ease 0s',
                    opacity: 0,
                },
            })
        );
    };

    const onLoadAnimation = () => {
        timer(150).then(() =>
            setAnimationStyle({
                slide: {
                    transformStyle: 'preserve-3d',
                    transform:
                        'translateX(0px) translateY(calc(0px + env(safe-area-inset-top))) translateZ(0px)',
                    transition: 'transform 0.4s ease 0.08s',
                },
                dimmer: {
                    transition: 'opacity 400ms ease 0s',
                    opacity: 1,
                },
            })
        );
    };

    useEffect(() => {
        onLoadAnimation();
        return () => {};
    }, []);

    const handleUnmountComponent = async () => {
        unloadAnimation();
        await timer(300);
        searchStore.set.resetSearch();
        searchStore.set.hideSearch();
    };

    const handleCloseSearch = () => {
        closeAllModals();
        handleUnmountComponent();
    };

    const hideSearchOnly = async () => {
        closeAllModals();
        unloadAnimation();
        await timer(300);
        searchStore.set.hideSearch();
    };

    const handleSearch = (input: string) => {
        // Debounce the search when typing so that we're not firing off tons of requests
        searchStore.set.mergeState({
            useDebouncedSearch: true,
            query: input,
        });
    };

    const handleCloseScope = () => {
        searchStore.set.resetCurrentScope();
        searchStore.set.queryScopes([]);
    };

    let bottom;
    let scrollHeight;
    let scrollTop;
    let clientHeight;

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        e.stopPropagation();
        scrollHeight = e?.currentTarget?.scrollHeight;
        scrollTop = e?.currentTarget?.scrollTop;
        clientHeight = e?.currentTarget?.clientHeight;
        bottom = scrollHeight === scrollTop + clientHeight;

        if (!refreshing && bottom) {
            searchStore.set.refreshing(true);
        }
    };

    return (
        <div className="search-overlay-container">
            {ReactDOM.createPortal(
                <>
                    <LoadingLine isLoading={isLoading} error={error} />
                    <div className="message-dimmer initial search" style={animationStyle.dimmer} />

                    <div className="search-overlay-popup">
                        <div className="search-overlay-block initial" style={animationStyle.slide}>
                            <div className="search-header">
                                <div className="search-form-container">
                                    <SearchInput
                                        isSearchOverlayOpen={isSearchOverlayOpen}
                                        onChange={handleSearch}
                                        value={searchQuery}
                                        placeholder={searchFilterToInputPlaceholder(
                                            searchFilter,
                                            t
                                        )}
                                    />
                                    <button
                                        type="button"
                                        onClick={handleCloseSearch}
                                        className="button-reset cancel-search-overlay-button"
                                    >
                                        {' '}
                                        {t('common:global.verbs.cancel')}
                                    </button>
                                </div>
                                <SearchScope handleClose={handleCloseScope} />
                                <div>
                                    <SearchFiltersMenu />
                                </div>
                            </div>
                            <div
                                onScroll={handleScroll}
                                className="search-results-content-container"
                            >
                                {/* <div className="search-result-section-header" /> */}
                                <SearchResults
                                    handleCloseSearch={hideSearchOnly}
                                    loading={loading}
                                    searchResults={searchResults}
                                />
                                {hasMoreResults && !searchStore.get.isAllTab() && (
                                    <div className="w-full flex justify-center items-center">
                                        <LottieLoading
                                            height={50}
                                            width={200}
                                            lottieData={PaginationAnimation}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>,
                document.querySelector('#modal-mid-root')!!
            )}
        </div>
    );
};

export default SearchOverlay;
