import {combineReducers} from 'redux';
import createCard from '@core/client/reducers/ui/createCard.js';
import drawers from '@core/client/reducers/ui/drawers.js';
import search from '@core/client/reducers/ui/search.js';
import pageUI from '@core/client/reducers/ui/pageUI.js';
import voting from '@core/client/reducers/ui/voting.js';
import currentUser from '@core/client/reducers/user/currentUser.js';

const rootReducer = combineReducers({
    createCard,
    drawers,
    search,
    pageUI,
    voting,
    currentUser,
});

export default rootReducer;
