import React from 'react';

const Checkmark: React.FC<{ className?: string }> = ({ className = '' }) => {
    return (
        <svg
            width="37"
            height="28"
            viewBox="0 0 37 28"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g filter="url(#filter0_d)">
                <path
                    d="M1 11.1752L14.2836 28L37 1H25.6522L14.2836 16.7154L10.7136 10.6303L1 11.1752Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0"
                    y="0"
                    width="37"
                    height="28"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-1" dy="-1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export const RoundedCheckmark: React.FC<{ className?: string; strokeWidth?: string }> = ({
    className = '',
    strokeWidth = '3',
}) => {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M12.6562 4.21875L6.09375 10.781L2.8125 7.5"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Checkmark;
