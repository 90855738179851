import React from 'react';
import _ from 'lodash';

import X from '@dsc/svgs/FullSizeX';
import OrderTotal from '@web/ui/components/card/Shopping-Cart/OrderTotal';

import {
    DiscountCodeState,
    SHOPPING_CART,
    getDiscountedShoppingCartTotal,
    getShoppingCartTotal,
} from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';
import ShoppingCartSelectorItem from '@components/card/Shopping-Cart/ShoppingCartSelectorItem';
import ShoppingCartDiscountCode from '@components/card/Shopping-Cart/ShoppingCartDiscountCode';

import shoppingCartStore from '@web/ui/stores/ShoppingCart';

import { isEventFree } from '@web/utilities/helpers/events/event.helpers';
import { Group, Product } from '@shared/welibrary-graphql/types';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';
import { CARD_EVENT } from '@core/utilities/constants/card_types';

type ShoppingCartSelectorProps = {
    ref: React.RefObject<HTMLElement>;
    group: Group;
    cart: { cartItem: Product; quantity: number };
    setCart: React.Dispatch<
        React.SetStateAction<{
            cartItem: Product;
            quantity: number;
        }>
    >;
    discountCodeState: DiscountCodeState;
    handleNextStep: (smooth?: boolean) => void;
    closeModal: () => void;
    currentPage: string;
    onCancel: () => void;
    isInline: boolean;
    containerClassName?: string;
};

const ShoppingCartSelector = React.forwardRef<HTMLDivElement, ShoppingCartSelectorProps>(
    function ShoppingCartSelector(
        {
            group,
            discountCodeState,
            handleNextStep,
            closeModal,
            currentPage,
            isInline = false,
            onCancel = () => { },
            containerClassName,
        },
        ref
    ) {
        const products = group?.products;

        const shoppingCartProducts =
            shoppingCartStore?.useTracked?.shoppingCarts()?.find(({ groupId }) => {
                return groupId === group?._id;
            })?.products ?? [];

        const isEvent = group?.subtype === CARD_EVENT;
        const _isEventFree = isEventFree(group);

        let total: number = 0;
        let originalPrice = getShoppingCartTotal(shoppingCartProducts);

        originalPrice = getShoppingCartTotal(shoppingCartProducts);
        total = getDiscountedShoppingCartTotal(shoppingCartProducts);

        if (_isEventFree) {
            total = getShoppingCartTotal(shoppingCartProducts, _isEventFree);
        }

        const shouldDisableNextStep = () => {
            if (shoppingCartProducts?.length === 0) return true;

            const hasFreeEventTicket = shoppingCartProducts?.find(({ product }) => {
                return product?.type === PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP;
            });

            const hasPaidEventTicket = shoppingCartProducts?.find(({ product }) => {
                return product?.type === PRODUCT_TYPES?.GROUP_MEMBERSHIP;
            });

            if (_isEventFree && !hasFreeEventTicket) return true;

            if (!_isEventFree && !hasPaidEventTicket) return true;

            return false;
        };

        const handleOnClick = () => {
            handleNextStep(false);
        };

        const showCloseButton = window.innerWidth > 991;

        if (!containerClassName) {
            containerClassName = isInline ? 'inline-checkout-ticket-selector-container' : '';
        }
        const footerClassName = isInline ? 'absolute' : 'fixed';

        return (
            <section
                className="checkout-modal checkout-modal-ShoppingCartSelector modal-scrollable"
                ref={ref}
            >
                {showCloseButton && (
                    <button
                        type="button"
                        onClick={() => closeModal()}
                        className="checkout-modal-btn-close"
                    >
                        <X className="checkout-modal-btn-x-dark" />
                    </button>
                )}
                {isEvent && (
                    <header>
                        <p>Select Tickets</p>
                    </header>
                )}

                <ul
                    className={`w-full overflow-y-scroll pb-[20px] ${containerClassName}`}
                >
                    {products?.map(product => {
                        return (
                            <ShoppingCartSelectorItem
                                key={product?._id}
                                cartItem={product}
                                group={group}
                                allowEdit
                                className="!pl-0"
                            />
                        );
                    })}
                </ul>
                {isEvent && (
                    <div className="w-full pb-[250px]">
                        <ShoppingCartDiscountCode group={group} productId={''} />
                    </div>
                )}

                {currentPage === SHOPPING_CART && (
                    <div
                        className={`stripe-checkout-form-container bottom-0 left-0 z-50 bg-white ${footerClassName}`}
                    >
                        <div className="stripe-checkout-total-divider" />
                        <OrderTotal total={total} originalPrice={originalPrice} group={group} />
                        <div className="stripe-checkout-btn-wrap">
                            <button
                                type="button"
                                onClick={() => {
                                    if (isInline) {
                                        onCancel();
                                    } else {
                                        closeModal();
                                    }
                                }}
                                className="stripe-checkout-btn-cancel"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleOnClick}
                                disabled={shouldDisableNextStep()}
                                type="button"
                                className="stripe-checkout-btn-confirm"
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                )}
            </section>
        );
    }
);

export default ShoppingCartSelector;
