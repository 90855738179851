import React from 'react';

const MagnifyingGlass: React.FC<{ className?: string; strokeWidth?: string }> = ({
    className,
    strokeWidth = '3',
}) => (
    <svg
        className={className}
        viewBox="0 0 500 500"
        strokeWidth={strokeWidth}
        stroke="currentcolor"
    >
        <path d="M459.57,420.69,440.84,402l-18.72-18.72-18.73-18.73-18.72-18.72-18.73-18.73-18.72-18.72-29.34,2.48-23.71-23.7a157.38,157.38,0,1,0-29.87,24.8l26.24,26.24-2.48,29.34,18.72,18.73L325.5,405l18.73,18.73L363,442.4l18.73,18.73,18.72,18.72,18.73,18.72,7.39-7.39,7.4-7.4,1.12-1.12,27.34-27.34h0l1.12-1.12,7.4-7.39,7.39-7.4ZM59.71,179.08A119.65,119.65,0,1,1,179.36,298.73,119.65,119.65,0,0,1,59.71,179.08Z" />
    </svg>
);

export default MagnifyingGlass;
