import React from 'react';
import {withTranslation} from 'react-i18next';

/** This needs to be rewritten but is a copy of ToggleButton as a controlled and functional component */
const ControlledToggle = ({
    toggleValue,
    handleToggle,
    hideToggle,
    disableToggle,
    fontSize,
    option1,
    option2,
    t,
    name = 'default',
    reverse = false,
}) => {
    const handleChange = e => {
        if (handleToggle) {
            handleToggle(e, !toggleValue);
        }
    };

    const transitionStyle = {
        transition: 'all 0.3s ease 0s',
    };

    let buttonToggleStyle = {
        transformStyle: 'preserve-3d',
        transition: 'transform 300ms ease 0s',
        transform: 'translateX(0px) translateY(0px) translateZ(0px)',
    };

    let buttonToggleStyleActive = {
        transformStyle: 'preserve-3d',
        transition: 'transform 300ms ease 0s',
        transform: 'translateX(50px) translateY(0px) translateZ(0px)',
    };

    let buttonTextStyle = {};

    if (reverse) {
        buttonToggleStyle = {
            transformStyle: 'preserve-3d',
            transition: 'transform 300ms ease 0s',
            transform: 'translateX(50px) translateY(0px) translateZ(0px)',
        };

        buttonToggleStyleActive = {
            transformStyle: 'preserve-3d',
            transition: 'transform 300ms ease 0s',
            transform: 'translateX(0px) translateY(0px) translateZ(0px)',
        };
    }

    const nonActiveStyle = {
        transition: 'opacity 300ms ease 0s',
        opacity: '0',
    };

    const activeStyle = {
        transition: 'opacity 300ms ease 0s',
        opacity: '1',
        backgroundColor: '#dcdcdc',
    };

    if (fontSize) {
        buttonTextStyle = { fontSize: `${fontSize}px` };
    }

    if (hideToggle) {
        return null;
    }

    return (
        <div>
            <div
                type="button"
                data-ix="toggle"
                className="button-reset togglebutton w-inline-block"
                style={transitionStyle}
            >
                <div
                    className="buttontoggle"
                    style={!toggleValue ? buttonToggleStyleActive : buttonToggleStyle}
                />
                <div className="buttontext" style={buttonTextStyle}>
                    {option1 || t('common:global.no_caps')}
                </div>
                <div
                    className="toggle-button-acive"
                    style={!toggleValue ? activeStyle : nonActiveStyle}
                >
                    <div className="buttontext" style={buttonTextStyle}>
                        {option2 || t('common:global.yes_caps')}
                    </div>
                </div>
                <div className="toggle-checkbox-field w-checkbox">
                    <input
                        onClick={handleChange}
                        type="checkbox"
                        name={name}
                        className="toggle-checkbox-field w-checkbox-input"
                    />
                    <label htmlFor="toggle" className="w-form-label">
                        {t('common:global.form.input.checkbox.title')}
                    </label>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(ControlledToggle);
