import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import useHideOnScrollDown from '@web/ui/components/generic/hooks/useHideOnScrollDown';

export const ChapterContext = React.createContext();

const ChapterProvider = ({ children }) => {
    const { chapterId } = useParams();

    const [currentChapter, setCurrentChapter] = useState(chapterId);
    const [scrollRefs, setScrollRefs] = useState({});

    const isAnimating = useRef(false);

    const {
        onScroll: actionAreaScrollHandler,
        hidden: isActionAreaHidden,
        show: showActionArea,
        hide: hideActionArea,
        startChecking: startCheckingActionAreaScroll,
    } = useHideOnScrollDown({
        startHidden: true,
        throttleTime: 700,
    });

    useEffect(() => {
        setCurrentChapter(chapterId);
    }, [chapterId]);

    useEffect(() => {
        setTimeout(startCheckingActionAreaScroll, 1000);
    }, []);

    const replaceWindowHistory = useCallback(
        _.throttle(
            currentChapter =>
                window.history.replaceState(currentChapter, 'currentChapter', currentChapter),
            500
        ),
        []
    );

    useEffect(() => {
        if (currentChapter && window.history.state !== currentChapter) {
            replaceWindowHistory(currentChapter);
        }
    }, [currentChapter]);

    return (
        <ChapterContext.Provider
            value={{
                currentChapter,
                setCurrentChapter,
                scrollRefs,
                setScrollRefs,
                actionAreaScrollHandler,
                isActionAreaHidden,
                showActionArea,
                hideActionArea,
                isAnimating,
            }}
        >
            {children}
        </ChapterContext.Provider>
    );
};

export default ChapterProvider;
