import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import loadable from '@loadable/component';
import { Group, Maybe } from '@shared/welibrary-graphql/types';
import ToastContext from '@web/ui/components/generic/context/ToastContext';
import { BroadcastSurvey } from '@shared/welibrary-graphql/types';

const LoadableUpdateGroup = loadable(() => import('@components/group/UpdateGroup'), {
    resolveComponent: imported => imported.default,
});

type EventGroupCustomSignupProps = {
    group: Maybe<Group>;
    handleCancel: () => void;
    refetch: () => void;
    initialTab?: string;
    broadcastSurvey?: BroadcastSurvey;
    isBroadcastSurvey?: boolean;
    handleSave?: () => void;
};

// These correspond to the current 'tabs' used in UpdateGroup
export const EDIT_GROUP_TABS = {
    SIGNUP_FORMS: 'Signup Forms',
    GENERAL: 'General',
    OPTIMIZE: 'Optimize',
    PLATFORM_SURVEY: 'Platform Survey',
};

const hiddenTabs = [EDIT_GROUP_TABS.GENERAL, EDIT_GROUP_TABS.PLATFORM_SURVEY];

const EventGroupCustomSignup: React.FC<EventGroupCustomSignupProps> = ({
    group,
    handleCancel,
    refetch,
    initialTab,
    broadcastSurvey,
    isBroadcastSurvey,
    handleSave,
}) => {
    const { t } = useTranslation();
    const { showToast } = useContext(ToastContext);
    // code below from GroupDashboard

    const dashboardSource = group?.dashboardSource;
    const dashboard = group?.dashboard;
    const tableOfContents = group?.tableOfContents?.results;
    const tocRankOrder = group?.settings?.tocSettings?.rankOrder;

    const partitionedToc = _.partition(tableOfContents, item => item.type === 'chapter');
    let sortedToc = [];
    partitionedToc.forEach(itemSet => {
        sortedToc = [...sortedToc, ...itemSet];
    });

    if (tocRankOrder) {
        sortedToc = _.sortBy(sortedToc, item => {
            const tocRank = _.find(tocRankOrder, { key: item.url });
            return tocRank && tocRank.value;
        });
    }

    const onSave = () => {
        showToast('Signup questions updated', null, 6000);
    };

    return (
        <section className="event-group-survey-form-container">
            <LoadableUpdateGroup
                hiddenTabs={hiddenTabs}
                handleCancel={handleCancel}
                initialTab={initialTab ?? EDIT_GROUP_TABS.SIGNUP_FORMS}
                group={group}
                isOpen
                broadcastSurvey={broadcastSurvey}
                isBroadcastSurvey={isBroadcastSurvey}
                dashboardSource={dashboardSource}
                dashboard={dashboard}
                tableOfContents={sortedToc}
                handleSubmit={() => {
                    refetch?.();
                    onSave();
                }}
                handleSave={handleSave}
                handleClose={() => handleCancel?.()}
            />
        </section>
    );
};

export default EventGroupCustomSignup;
