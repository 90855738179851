import React, { ChangeEvent, FormEventHandler, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { awsMetricPublisher } from '@welibraryos/metrics';

import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';
import UpdateEventGroupFormQuery from '@components/content/newpost/forms/EventGroup/UpdateEventGroupForm';
import MediaCardUpdateForm from '@components/card/updatecard/MediaCardUpdateForm';
import PostboxForm from '@components/content/newpost/forms/Postbox/PostboxForm';
import VoteGalleryUpdateForm from '@components/card/updatecard/VoteGalleryUpdateForm';
import LinkTileUpdateForm from '@components/card/updatecard/LinkTileUpdateForm';
import TextArea from '@components/generic/forms/TextArea';
import ToggleButton from '@components/generic/buttons/ToggleButton';
import FileUploader from '@components/content/FileUploader';
import UpdateCourseForm from '@components/content/newpost/forms/Course/UpdateCourseForm';
import UpdateCreationForm from '@components/content/newpost/forms/Creation/UpdateCreationForm';
import UpdateSurveyForm from '@components/content/newpost/forms/Survey/UpdateSurveyForm';
import UpdateDialogForm from '@components/content/newpost/forms/Dialog/UpdateDialogForm';
import CreateMetaChannelCard from '@components/content/newpost/forms/CreateMetaChannelCard';
import UpdateEventListingForm from '@components/content/newpost/event/UpdateEventListingForm';

import {
    CARD_BOOK,
    CARD_DYNAMIC,
    CARD_CHAPTER,
    CARD_COURSE,
    CARD_SURVEY,
    CARD_DOCUMENT,
    CARD_IMAGE,
    CARD_LINK,
    CARD_LINK_TILE,
    CARD_META_CHANNEL,
    CARD_POSTBOX,
    CARD_VIDEO,
    CARD_VOTE_GALLERY,
    CARD_RAINBOW_ROAD,
    CARD_CREATION,
    CARD_STORY,
    CARD_GROUP,
    CARD_EVENT,
    CARD_DIALOG,
} from '@core/utilities/constants/card_types';

import { AliasItem, ContentCard } from '@shared/welibrary-graphql/types';
import { ModalTypes } from '@core/types/Modals';
import { FilestackImage } from '@core/types/Utilities';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard.updateForm';

type UpdateFormProps = {
    card: Partial<ContentCard>;
    state: { displayInNewsfeed: boolean; rankOrder: number; [key: string]: any };
    chapterSettings: Record<string, any>;
    handleUpdateState: (newState: Record<string, any>) => void;
    handleChange: (e: ChangeEvent<HTMLElement>, isInteger?: boolean) => void;
    removeLabel: boolean;
    handleToggleLabel: () => void;
    handleToggle: (e: ChangeEvent<HTMLElement>, value: boolean) => void;
    handleImgToggle: (img: AliasItem | FilestackImage) => void;
    handleAddToList: (key: string, value: any) => void;
    handleRemoveFromList: (key: string, value: any) => void;
    handleClose: () => void;
    handleSubmit: FormEventHandler<HTMLElement>;
    searchItemSelected: (item: any, filter: any) => void;
    addSearchItemSelected: (item: any, filter: any) => void;
    updateAliasItem: (item: any, target: string, value: any) => void;
    deleteAliasItem: (item: any) => void;
    getFeatureConstraintsForField: (field: string) => boolean;
    baseMetricStringName: string; // allows parent to provide name of metric for logging basic update actions
};

const UpdateForm: React.FC<UpdateFormProps> = ({
    card,
    state,
    chapterSettings,
    handleUpdateState,
    handleChange,
    removeLabel,
    handleToggleLabel,
    handleToggle,
    handleImgToggle,
    handleAddToList,
    handleRemoveFromList,
    handleClose,
    handleSubmit,
    searchItemSelected,
    addSearchItemSelected,
    updateAliasItem,
    deleteAliasItem,
    getFeatureConstraintsForField,
    baseMetricStringName,
}) => {
    const { t } = useTranslation();

    const history = useHistory();

    const { currentUser } = useCurrentUser();

    const { newModal, closeModal } = useModal({
        desktop: ModalTypes.FullScreen,
        mobile: ModalTypes.FullScreen,
    });

    const defaultLabelText = card.label?.text ?? '';
    const defaultRankOrder = card.rankOrder || 0;

    const hasAdminRole = currentUser?.isUserSuperAdmin; // Should this be changed to include admins of a group rather than just 'super admins'

    // Also what is a 'direct' admin versus an 'admin' (I assume this has to do with subgroups)
    const isUserAdminOfGroup =
        card?.reference?.groupSource?.currentUserIsAdmin ||
        card?.reference?.groupSource?.currentUserIsDirectAdmin;

    let cardConstraints = {
        titleConstraint: getFeatureConstraintsForField('title'),
        bodyConstraint: getFeatureConstraintsForField('body'),
        descriptionConstraint: getFeatureConstraintsForField('description'),
        linkConstraint: getFeatureConstraintsForField('link'),
        aliasesConstraint: getFeatureConstraintsForField('aliases'),
        mediaThumbConstraint: getFeatureConstraintsForField('mediaThumb'),
        mediaIconConstraint: getFeatureConstraintsForField('mediaIcon'),
        authorIDConstraint: getFeatureConstraintsForField('authorID'),
        pinnedConstraint: getFeatureConstraintsForField('pinned'),
        hideDateConstraint: getFeatureConstraintsForField('hideDate'),
        deleteCardConstraint: getFeatureConstraintsForField('deleteCard'),
        labelTextConstraint: getFeatureConstraintsForField('labelText'),
        removeLabelConstraint: getFeatureConstraintsForField('removeLabel'),
        allowLabelsConstraint: getFeatureConstraintsForField('allowLabels'),
        promptConstraint: getFeatureConstraintsForField('prompt'),
        engagementTypesConstraint: getFeatureConstraintsForField('engagementTypes'),
        viewTypesConstraint: getFeatureConstraintsForField('viewTypes'),
        defaultViewTypeConstraint: getFeatureConstraintsForField('defaultViewType'),
        displaySocialHeadersConstraint: getFeatureConstraintsForField('displaySocialHeaders'),
        displayInNewsfeedConstraint: getFeatureConstraintsForField('displayInNewsfeed'),
        displayReferenceConstraint: getFeatureConstraintsForField('displayReference'),
        metaConstraint: getFeatureConstraintsForField('meta'),
        rankOrderConstraint: getFeatureConstraintsForField('rankOrder'),
        customFieldsConstraint: getFeatureConstraintsForField('customFields'),
        showCreateChildrenConstraint: getFeatureConstraintsForField('showCreateChildren'),
        isTemplateConstraint: getFeatureConstraintsForField('isTemplate'),
        canMembersSuggestEditsConstraint: getFeatureConstraintsForField('canMembersSuggestEdits'),
        showTitle: getFeatureConstraintsForField('showTitle'),
        shortTitle: getFeatureConstraintsForField('shortTitle'),
        showResourceHeader: getFeatureConstraintsForField('showResourceHeader'),
    };

    // Client side display gating of certain editable fields,
    // e.g. show only these fields if this card type
    const CUSTOM_CARD_CONSTRAINT_DISPLAY: { [char: string]: any } = {
        [CARD_DYNAMIC]: {
            rankOrderConstraint: getFeatureConstraintsForField('rankOrder'),
        },
    };

    if (card?.type && CUSTOM_CARD_CONSTRAINT_DISPLAY[card?.type]) {
        cardConstraints = CUSTOM_CARD_CONSTRAINT_DISPLAY[card?.type];
    }

    const inheritedSettings = card?.reference?.chapterSource?.library_settings;
    const displaySocialHeadersSelection = card.library_settings
        ? card.library_settings.displaySocialHeaders
        : null;
    const hasCard = [0, CARD_IMAGE, CARD_LINK, CARD_VIDEO, CARD_DOCUMENT, CARD_STORY].indexOf(
        card.type ?? ''
    );

    const handleCloseFunction = () => {
        handleClose();
        if (baseMetricStringName) {
            awsMetricPublisher.publishCount(`${baseMetricStringName}-cancel`, 1);
        } else {
            // Log missing prop
            awsMetricPublisher.publishCount('warning', 1, [
                { Name: 'missing-prop', Value: 'baseMetricStringName-handleCloseFunction' },
            ]);
            logger.warn(
                'Unable to log close click metric due to missing baseMetricStringName prop'
            );
        }
    };

    const handleSubmitFunction = () => {
        handleSubmit();
        if (baseMetricStringName) {
            awsMetricPublisher.publishCount(`${baseMetricStringName}-submit`, 1);
        } else {
            // Log missing prop
            awsMetricPublisher.publishCount('warning', 1, [
                { Name: 'missing-prop', Value: 'baseMetricStringName-handleSubmitFunction' },
            ]);
            logger.warn(
                'Unable to log submit click metric due to missing baseMetricStringName prop'
            );
        }
    };

    const handleToggleSettingsCallback = (isOpen: boolean) => {
        if (baseMetricStringName) {
            const dimensionNameAndValue = isOpen ? 'open-settings' : 'close-settings';
            awsMetricPublisher.publishCount(`${baseMetricStringName}-${dimensionNameAndValue}`, 1);
        } else {
            // Log missing prop
            awsMetricPublisher.publishCount('warning', 1, [
                {
                    Name: 'missing-prop',
                    Value: 'baseMetricStringName-handleToggleSettingsCallback',
                },
            ]);
            logger.warn(
                'Unable to log settings click metric due to missing baseMetricStringName prop'
            );
        }
    };

    useEffect(() => {
        if (card.type === CARD_COURSE) {
            newModal(<UpdateCourseForm hasAdminRole={hasAdminRole ?? undefined} card={card} />, {
                hideButton: true,
            });
            handleClose();
        }

        if (card.type === CARD_GROUP) {
            newModal(
                <UpdateEventGroupFormQuery card={card} hasAdminRole={hasAdminRole ?? undefined} />,
                {
                    hideButton: true,
                    className: 'center-fixed-header-footer',
                    confirmClose: 'Are you sure you want to cancel?',
                },
                { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
            );
            handleClose();
        }

        if (card.type === CARD_RAINBOW_ROAD && card.url) {
            handleClose();
            history.push(`/r/${card.url}`);
        }

        if (card.type === CARD_CREATION) {
            newModal(<UpdateCreationForm hasAdminRole={hasAdminRole ?? undefined} card={card} />, {
                hideButton: true,
            });
            handleClose();
        }

        if (card.type === CARD_EVENT) {
            newModal(
                <UpdateEventListingForm
                    closeModalHandler={closeModal}
                    hasAdminRole={hasAdminRole ?? undefined}
                    card={card}
                />,
                {
                    hideButton: true,
                    className: 'center-fixed-header-footer',
                    confirmClose: 'Are you sure you want to cancel?',
                },
                { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
            );
            handleClose();
        }

        if (card.type === CARD_SURVEY) {
            newModal(<UpdateSurveyForm hasAdminRole={hasAdminRole ?? undefined} card={card} />, {
                hideButton: true,
            });
            handleClose();
        }

        if (card.type === CARD_DIALOG) {
            newModal(<UpdateDialogForm hasAdminRole={hasAdminRole ?? undefined} card={card} />, {
                hideButton: true,
            });
            handleClose();
        }
    }, [card.type]);

    if (card.type === CARD_LINK_TILE) {
        return (
            <LinkTileUpdateForm
                card={card}
                state={state}
                handleChange={handleChange}
                handleToggle={handleToggle}
                currentUser={currentUser}
                inheritedSettings={inheritedSettings}
                handleSubmit={handleSubmitFunction}
                handleClose={handleCloseFunction}
                hasAdminRole={hasAdminRole || isUserAdminOfGroup}
                deleteAliasItem={deleteAliasItem}
                addSearchItemSelected={addSearchItemSelected}
                updateAliasItem={updateAliasItem}
                cardConstraints={cardConstraints}
            />
        );
    }
    if (card.type === CARD_VOTE_GALLERY) {
        return (
            <VoteGalleryUpdateForm
                card={card}
                state={state}
                handleUpdateState={handleUpdateState}
                handleChange={handleChange}
                handleToggle={handleToggle}
                currentUser={currentUser}
                handleSubmit={handleSubmitFunction}
                handleClose={handleCloseFunction}
                hasAdminRole={hasAdminRole}
                searchItemSelected={searchItemSelected}
                deleteAliasItem={deleteAliasItem}
                cardConstraints={cardConstraints}
            />
        );
    }
    if (card.type === CARD_POSTBOX) {
        return (
            <PostboxForm
                state={state}
                setState={handleUpdateState}
                addAttachedContent={handleUpdateState}
                handleSubmit={handleSubmitFunction}
                handleClose={handleCloseFunction}
                hasAdminRole={hasAdminRole || isUserAdminOfGroup}
                defaultTypes={[CARD_CHAPTER, CARD_BOOK]}
                childrenTypes={[CARD_CHAPTER]}
                formTitle="Update Postbox"
                submitText="Update"
                groupId={card?.reference?.groupSource?._id}
            />
        );
    }

    if (card.type === CARD_META_CHANNEL) {
        return (
            <CreateMetaChannelCard
                state={state}
                setState={handleUpdateState}
                addAttachedContent={handleUpdateState}
                submitPost={handleSubmit}
                closeNewPostModal={handleClose}
                defaultTypes={[CARD_CHAPTER]}
                formTitle="Update Meta-Channel Card"
                submitText="Update"
            />
        );
    }

    // Course Cards are rendered inside a modal
    if (card.type === CARD_COURSE || card.type === CARD_CREATION || card?.type === CARD_EVENT) {
        return <></>;
    }

    if (hasCard) {
        return (
            <MediaCardUpdateForm
                card={card}
                state={state}
                chapterSettings={chapterSettings}
                handleImgToggle={handleImgToggle}
                handleUpdateState={handleUpdateState}
                handleChange={handleChange}
                handleSubmit={handleSubmitFunction}
                handleClose={handleCloseFunction}
                handleToggleSettingsCallback={handleToggleSettingsCallback}
                handleAddToList={handleAddToList}
                handleRemoveFromList={handleRemoveFromList}
                inheritedSettings={inheritedSettings}
                cardConstraints={cardConstraints}
            />
        );
    }

    return (
        <div className="db-section extra-padding animated animatedFadeInUp fadeInUp">
            <div className="edit-section">
                <p>{t(`common:${localNamespace}.edit_post`)}</p>
                {card.type !== 'text' &&
                    card.type !== 'link' &&
                    card.type !== 'video' &&
                    card.type !== 'image' && (
                        <span>
                            {t(`common:${localNamespace}.title`)}:
                            <input
                                onChange={handleChange}
                                defaultValue={card.title ?? ''}
                                type="text"
                                className="text-field title-field w-input"
                                maxLength={256}
                                name="title"
                                placeholder={`${t(`common:${localNamespace}.title`)}...`}
                                required
                            />
                        </span>
                    )}

                {(card.type === 'video' || card.type === 'image') && (
                    <span>
                        {card.type === 'video' ? 'Video Link:' : 'Ad Link'}
                        <input
                            onChange={handleChange}
                            defaultValue={card.links?.source ?? ''}
                            type="text"
                            className="text-field title-field w-input"
                            maxLength={256}
                            name="link"
                            placeholder={t(`common:${localNamespace}.link`)}
                            required
                        />
                    </span>
                )}

                {card.type !== 'text' && (
                    <span>
                        <FileUploader
                            currentImage={state.mediaThumb}
                            target="mediaThumb"
                            handleUpdateState={handleUpdateState}
                        />
                    </span>
                )}
            </div>
            <div className="edit-section w-clearfix">
                <TextArea
                    title={t(`common:${localNamespace}.post_text`)}
                    handleChange={handleChange}
                    defaultValue={card.body ?? ''}
                    maxCharacter={2000}
                    name="body"
                    placeholder={`${t(`common:${localNamespace}.post_text`)}...`}
                    defaultClasses="text-field w-input"
                />
            </div>

            {card.type !== 'text' &&
                card.type !== 'link' &&
                card.type !== 'video' &&
                card.type !== 'image' && (
                    <div className="edit-section w-clearfix">
                        <TextArea
                            title="Description"
                            handleChange={handleChange}
                            defaultValue={card.description ?? ''}
                            maxCharacter={140}
                            name="description"
                            placeholder={t(`common:${localNamespace}.description`)}
                            defaultClasses="text-field w-input"
                        />
                    </div>
                )}

            {hasAdminRole && (
                <div className="edit-section">
                    <div className="field-label">
                        <div className="field-title">{t(`common:${localNamespace}.label`)}?</div>
                    </div>
                    <ToggleButton defaultValue={!removeLabel} handleToggle={handleToggleLabel} />
                    <input
                        onChange={handleChange}
                        defaultValue={defaultLabelText}
                        type="text"
                        className="text-field title-field w-input"
                        maxLength={256}
                        name="labelText"
                        placeholder={`${t(`common:${localNamespace}.label`)}}...`}
                        id="Label"
                        required
                    />
                </div>
            )}

            {hasAdminRole && (
                <div className="edit-section">
                    {t(`common:${localNamespace}.display_social_headers`)}
                    <ToggleButton
                        name="displaySocialHeaders"
                        handleToggle={handleToggle}
                        defaultValue={displaySocialHeadersSelection}
                    />
                </div>
            )}
            {hasAdminRole && (
                <div className="edit-section">
                    {t(`common:${localNamespace}.ordered_rank`)}

                    <input
                        onChange={e => handleChange(e, true)}
                        defaultValue={defaultRankOrder}
                        type="number"
                        className="text-field title-field w-input"
                        maxLength={256}
                        name="rankOrder"
                        placeholder={`${t(`common:${localNamespace}.ordered_rank`)}...`}
                        id="Ordered-Rank"
                        required
                    />
                </div>
            )}
        </div>
    );
};

export default UpdateForm;
