import { MATCH_URL } from '@core/utilities/constants/regexes';

const topDomains = [
    /\.com/,
    /\.de/,
    /\.org/,
    /\.net/,
    /\.us/,
    /\.edu/,
    /\.gov/,
    /\.biz/,
    /\.za/,
    /\.info/,
    /\.cc/,
    /\.co/,
    /\.ca/,
    /\.cn/,
    /\.fr/,
    /\.ch/,
    /\.au/,
    /\.in/,
    /\.jp/,
    /\.be/,
    /\.it/,
    /\.nl/,
    /\.uk/,
    /\.mx/,
    /\.no/,
    /\.ru/,
    /\.br/,
    /\.se/,
    /\.es/,
    /\.at/,
    /\.dk/,
    /\.eu/,
    /\.il/,
    /\.io/,
    /\.me/,
    /\.tv/,
    /\.mil/,
    /\.int/,
    /\.to/,
    /\.na/,
    /\.world/,
    /\.news/,
];

/**
 * Checks a given URL for a valid top-level domain
 *
 * @param {string} url URL to check the domain of
 * @return {boolean} Whether or not the given url has a valid domain
 */
export const checkDomain = url =>
    topDomains.some(domain => {
        // handle case where url being entered is www.es or www.me www.co
        if (url === `www.${domain.source.replace('\\.', '')}`) return false;
        const domainMatch = url.match(domain);

        return !!domainMatch;
    });

/**
 * Helper function that parses a string of text for valid URLS
 *
 * @param {string} text string to parse for URLs
 * @return {string[]}
 */
export const parseTextForURLs = text => {
    const matches = [...text.matchAll(MATCH_URL)];

    return matches.reduce((acc, match) => {
        const url = match[0].replace(/^https?:\/\//, ''); // strip protocol from URL
        const { index } = match;

        if (!checkDomain(url)) return acc;

        if (index !== 0 && text[index - 1] === '@') return acc; // don't match email addresses

        return [...acc, url];
    }, []);
};

/**
 * Helper function that parses a string of text for valid URLS and separates text and links into
 * separate arrays
 *
 * @param {string} text string to parse for URLs
 * @return {{
 *     splitText: Array<{ text: string, type: string }>,
 *     splitLinks: Array<{ text: string, type: string }>,
 * }}
 */
export const splitTextByUrls = text => {
    const splitText = [];
    const splitLinks = [];
    let startIndex = 0;

    const matches = [...text.matchAll(MATCH_URL)];

    // check and push links or text into appropriate array
    matches.forEach(match => {
        const url = match[0];
        const { index } = match;

        let abort = !checkDomain(url);

        if (index !== 0 && text[index - 1] === '@') abort = true; // don't match email addresses

        // push text
        splitText.push({
            text: text.substr(startIndex, index - startIndex),
            type: 'text',
        });

        if (abort) {
            splitText.push({
                text: text.substr(index, url.length),
                type: 'text',
            });
        } else {
            // push link
            let cleanedLink = text.substr(index, url.length);
            // edge case that was causing issues...probably better way to handle this perhaps in regex
            if (cleanedLink !== 'www.es') {
                cleanedLink = cleanedLink.replace(/^https?:\/\//, '');
                splitLinks.push({ text: cleanedLink, type: 'link' });
            }
        }
        startIndex = index + url.length;
    });

    if (startIndex < text.length) splitText.push({ text: text.substr(startIndex), type: 'text' });

    return { splitText, splitLinks };
};

/* Replaces markdown links with plain text string
e.g. (check this link out!)[www.espn.com] => check this link out! */
export const stripMarkdownLink = mdString => {
    const str = String(mdString).replace(/\[([^\[\]]*)\]\((.*?)\)/gm, '$1');
    return str;
};
