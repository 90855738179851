import React from 'react';

const UserPending: React.FC<{ className?: string; strokeWidth?: string }> = ({
    className,
    strokeWidth = '1',
}) => (
    <svg className={className} strokeWidth={strokeWidth} viewBox="0 0 21 16" fill="none">
        <path
            fill="currentcolor"
            d="M16.0002 4.25C16.6906 4.25 17.2502 3.69036 17.2502 3C17.2502 2.30964 16.6906 1.75 16.0002 1.75C15.3099 1.75 14.7502 2.30964 14.7502 3C14.7502 3.69036 15.3099 4.25 16.0002 4.25Z"
        />
        <path
            fill="currentcolor"
            d="M12.8752 4.25C13.5656 4.25 14.1252 3.69036 14.1252 3C14.1252 2.30964 13.5656 1.75 12.8752 1.75C12.1849 1.75 11.6252 2.30964 11.6252 3C11.6252 3.69036 12.1849 4.25 12.8752 4.25Z"
        />
        <path
            fill="currentcolor"
            d="M19.1252 4.25C19.8156 4.25 20.3752 3.69036 20.3752 3C20.3752 2.30964 19.8156 1.75 19.1252 1.75C18.4349 1.75 17.8752 2.30964 17.8752 3C17.8752 3.69036 18.4349 4.25 19.1252 4.25Z"
        />
        <path
            d="M1.28638 14.5762C1.99567 13.3485 3.01557 12.3291 4.2436 11.6203C5.47163 10.9116 6.86456 10.5384 8.28244 10.5385C9.70033 10.5385 11.0932 10.9116 12.3213 11.6204C13.5493 12.3292 14.5692 13.3486 15.2784 14.5764"
            stroke="currentcolor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.6128 7.52284C12.3043 8.35583 11.7619 9.08203 11.0507 9.6142C10.3394 10.1464 9.48968 10.4619 8.60349 10.5228C7.7173 10.5837 6.83236 10.3875 6.05498 9.95771C5.27761 9.5279 4.64086 8.88278 4.22124 8.09986C3.80162 7.31694 3.61696 6.42952 3.68947 5.5442C3.76197 4.65888 4.08854 3.81332 4.62995 3.1091C5.17136 2.40488 5.90457 1.87196 6.74152 1.57435C7.57846 1.27674 8.48354 1.2271 9.34801 1.43141"
            stroke="currentcolor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default UserPending;
