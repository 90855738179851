// child permissions
export enum ChildPermissionsEnum {
    SharePhotos = 'feature_ssl_share_photos',
    Notifications = 'feature_ssl_receive_notifications',
    DisplayName = 'feature_ssl_edit_name',
    VerifiableCredentials = 'feature_ssl_receive_vc',
    Cookies = 'feature_ssl_cookies',
}

export const PermissionsInfo = {
    [ChildPermissionsEnum.SharePhotos]: {
        title: 'Share Photos',
        description: `Give your child permission to share photos across our SSL experiences. Photos containing identifiable people or PII is filtered out before moderation so that they cannot be uploaded.`,
    },
    [ChildPermissionsEnum.Notifications]: {
        title: 'Notifications',
        description: `Allow your child to receive notifications about new content or activities happening within a SSL experience, as well as notifications about updates or improvements to a SSL experience.`,
    },
    [ChildPermissionsEnum.DisplayName]: {
        title: 'Display Names',
        description: `Allow your child to create and edit their own custom ID (moderated by SSL), which is visible to other users in a SSL experience.`,
    },
    [ChildPermissionsEnum.VerifiableCredentials]: {
        title: 'Verifiable Credentials',
        description: `Allow your child to create a decentralized identity (DID) and receive verifiable credentials to represent their soft skills (creativity, leadership, etc.) using experimental technologies.`,
    },
    [ChildPermissionsEnum.Cookies]: {
        title: 'Cookies',
        description: `Allow / disallow any optional cookies`,
    },
};

// account managment
export enum AccountSettingsEnum {
    GuardianSettings = 'guardian_settings',
    ChildPermissions = 'child_permissions',
    Dashboard = 'dashboard',
    IssueCredential = 'issue_credential',
}

// Guardian Advanced settings
export enum GuardianAdvancedSettingsEnum {
    AllowChildrenAccountSwitching = 'feature_ssl_allow_siblings_to_switch_accounts',
}
