import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import LoadingIndicator from '@web/ui/components/generic/loading/LoadingIndicator';
import SearchItem from '@web/ui/components/generic/search/SearchItem';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/square-loader.json';
import usePaginatedSearchBox from '@web/ui/components/generic/search/hooks/usePaginatedSearchBox';
import { PAGINATED_LIST_CARDS } from '@shared/welibrary-graphql/content_card/queries';
import { useCurrentUser } from '@stores/User';

const QUERIES = { cards: PAGINATED_LIST_CARDS };

const localNamespace = 'imports.wlWeb.ui.components.generic.search.searchBoxCards';

const PaginatedSearchBox: React.FC<{
    aliases?: any[];
    deleteAliasItem: (alias: any) => void;
    type: keyof typeof QUERIES;
    getChildren: number;
    disableRenderChildren: boolean;
    prioritizeActiveGroup: boolean;
    showEmpty: boolean;
    libraryUrl: string;
    url: string;
    types: string[];
    filterUrl: string;
    onSelect: (item: { [key: string]: string }, filterUrl: string) => void;
    handleQueryInput: React.ChangeEventHandler<HTMLInputElement>;
    controlledInput: string;
    moveItem: { _id: string; title: string };
    checkbox: boolean;
    renderNoResults: boolean;
    inverted: boolean;
    fullWidth: boolean;
    placeholderText: string;
    childrenTypes: [];
    limit?: number;
}> = ({
    aliases,
    deleteAliasItem,
    type,
    getChildren,
    disableRenderChildren = false,
    prioritizeActiveGroup = false,
    showEmpty,
    libraryUrl,
    url,
    types,
    filterUrl,
    onSelect,
    handleQueryInput,
    controlledInput,
    moveItem,
    checkbox,
    renderNoResults,
    inverted,
    fullWidth,
    placeholderText,
    childrenTypes,
    limit,
}) => {
        const { currentUser, refetchCurrentUser } = useCurrentUser();

        useEffect(() => {
            if (prioritizeActiveGroup) {
                refetchCurrentUser(); // Refetch the current user to get the up-to-date active group
            }
        }, []);

        const activeGroupId = prioritizeActiveGroup ? currentUser?.activeGroup?._id : null;

        const { t } = useTranslation();
        const {
            query,
            data,
            loading,
            error,
            handleSelect,
            handleQueryInputChange,
            results,
            _displayType,
            setRef,
        } = usePaginatedSearchBox(QUERIES[type], {
            getChildren,
            showEmpty,
            libraryUrl,
            url,
            types,
            onSelect,
            handleQueryInput,
            controlledInput,
            filterUrl,
            disableRenderChildren,
            childrenTypes,
            limit: 5,
            groupId: activeGroupId,
            prioritizeGroupId: prioritizeActiveGroup,
        });

        const flattenParentAndChildrenAndFilterOutDuplicates = parents => {
            const addedIdsSet = new Set();
            const uniqueItems = [];
            parents.forEach(parent => {
                if (parent.children) {
                    parent.children.forEach(child => {
                        if (!addedIdsSet.has(child._id)) {
                            addedIdsSet.add(child._id);
                            uniqueItems.push({
                                ...child,
                                reference: {
                                    // Merge the parent's groupSource reference into the child's reference object.
                                    // This allows the child to determine the group it belongs to.
                                    ...parent.reference,
                                    ...child.reference,
                                },
                            });
                        }
                    });
                }
                if (!addedIdsSet.has(parent._id)) {
                    addedIdsSet.add(parent._id);
                    uniqueItems.push(parent);
                }
            });
            return uniqueItems;
        };

        let searchItems = results;
        if (!disableRenderChildren) {
            searchItems = flattenParentAndChildrenAndFilterOutDuplicates(searchItems);
        }

        // The backend sorts parents by active group if prioritizeActiveGroup is true.
        // But after flattening the parents and children, sometimes a child will belong
        // to the active group, but not be sorted to the top of the list.
        // So the code below moves the active group's items to the top of the list.
        if (prioritizeActiveGroup) {
            const prioritizeActiveGroupItems = () => {
                const activeGroupSearchItems = [];
                const nonActiveGroupSearchItems = [];
                for (const searchItem of searchItems) {
                    const groupSourceId = searchItem?.reference?.groupSource?._id;
                    if (groupSourceId === activeGroupId) {
                        activeGroupSearchItems.push(searchItem);
                    } else {
                        nonActiveGroupSearchItems.push(searchItem);
                    }
                }
                return [...activeGroupSearchItems, ...nonActiveGroupSearchItems];
            };

            searchItems = prioritizeActiveGroupItems();
        }

        const cards = searchItems?.map(item => {
            const relatedAlias = aliases?.find(alias => alias?.item?._id === item?._id);
            return (
                <SearchItem
                    activeGroupId={activeGroupId}
                    prioritizeActiveGroup={prioritizeActiveGroup}
                    alias={relatedAlias}
                    deleteAliasItem={deleteAliasItem}
                    moveItem={moveItem}
                    checkbox={checkbox}
                    key={item._id}
                    handleItem={handleSelect}
                    source={item}
                    title={item.title}
                    inverted={inverted}
                />
            );
        });

        const noResultsDisplay = renderNoResults && (
            <div className="fadein-delay">{t(`common:${localNamespace}.no_results`)}</div>
        );

        const containerStyle = data
            ? { transition: 'min-height 200ms', minHeight: '100px' }
            : { transition: 'min-height 200ms' };
        if (fullWidth) containerStyle.width = '100%';

        const loadingIndicator = inverted ? (
            <LoadingIndicator size="large" type="book" />
        ) : (
            <LottieLoading height={50} width={200} lottieData={LottieAnimation} />
        );

        let searchType = _displayType;

        switch (_displayType) {
            case 'library':
                searchType = _displayType;
                break;
            case 'shelf':
                searchType = `${t(`common:global.search`)} ${t(
                    'common:imports.wlWeb.ui.components.group.groupDashboard.subscriptions'
                )}...`;
                break;
            case 'chapter':
                searchType = t(`common:${localNamespace}.search_display_types`, {
                    _displayType: t(`common:global.constants.cards.types.channel`),
                });
                break;
            default:
                break;
        }
        return (
            <div style={containerStyle}>
                <span>
                    <input
                        value={query}
                        placeholder={placeholderText || searchType}
                        onChange={handleQueryInputChange}
                        className="text-field searchfield w-input"
                    />
                </span>

                {error && <div>Search Error..</div>}

                {!_.isEmpty(moveItem) && (
                    <div className="selected-results">
                        {t(`common:${localNamespace}.title_selected`, { title: moveItem.title })}
                    </div>
                )}

                {data && (
                    <div
                        className="edit-section w-clearfix fadein max-h-72 overflow-y-auto"
                        style={{ borderBottom: '0px' }}
                    >
                        {query.trim() !== '' && (
                            <h4>{t(`common:${localNamespace}.query_results`, { query })}</h4>
                        )}
                        {results.length > 0 && cards}
                        {loading && loadingIndicator}
                        <div ref={setRef} role="presentation" />
                        {query.trim() !== '' && !loading && results.length === 0 && noResultsDisplay}
                    </div>
                )}
            </div>
        );
    };

export default PaginatedSearchBox;
