import {ContentCard} from '@shared/welibrary-graphql/types';

export enum DynamicCardSubTypes {
    GroupInfoCard = 'Group Info Card',
    GroupShoppingCard = 'Group Shopping Cart',
    GroupSurveySummary = 'Group Survey Summary',
    GroupSponsorsCard = 'Group Sponsors',
    GroupItinerary = 'Group Itinerary',
    GroupSpeakers = 'Group Speakers',
}

export type DynamicCardOptions = {
    groupId: string;
};

export type DynamicCardProps = {
    card: Partial<ContentCard>;
};
