import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import SearchBox from '@web/ui/components/generic/search/SearchBox';
import SelectedSearchItem from '@web/ui/components/generic/search/SelectedSearchItem';
import { capitalize, sentenceCase } from '@helpers/string.helpers';

const SearchWithSelectedListTabs = props => {
    const { t } = useTranslation();
    const {
        aliases,
        deleteAliasItem,
        libraryUrl,
        overrideContainerStyle,
        types,
        onSelect,
        removeItem,
        selected,
        filterUrl,
        color,
        handleChange,
        externalLink,
        handleAddCustomLink,
        onClickAddButtonLabel,
        renderNoResults,
        childrenTypes,
    } = props;

    const _selected = _.map(selected, item => {
        return (
            <SelectedSearchItem
                key={item._id}
                handleItem={() => removeItem(item, filterUrl)}
                source={item}
                title={item.title ? item.title : item.name}
                color={color}
            />
        );
    });

    const [currentTab, setCurrentTab] = useState('card');
    const [currentQueryInput, setCurrentQueryInput] = useState('');

    const handleQueryInput = e => {
        const { value } = e.target;
        setCurrentQueryInput(value);
    };

    return (
        <>
            <SearchTabsMenu currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <div
                key="search-selected-list"
                className="edit-section w-clearfix"
                style={overrideContainerStyle}
            >
                {currentTab === 'card' && (
                    <div className="card-tab">
                        <SearchBox
                            aliases={aliases}
                            prioritizeActiveGroup
                            deleteAliasItem={deleteAliasItem}
                            type="cards"
                            childrenTypes={childrenTypes}
                            renderNoResults
                            controlledInput={currentQueryInput}
                            handleQueryInput={handleQueryInput}
                            libraryUrl={libraryUrl}
                            types={types}
                            filterUrl={filterUrl}
                            onSelect={(item, filter) => onSelect(item, filter)}
                            placeholderText={capitalize(
                                t('common:search_by_channel_name...', 'search by channel name...')
                            )}
                        />
                    </div>
                )}

                {currentTab === 'group' && (
                    <div className="group-tab">
                        <SearchBox
                            aliases={aliases}
                            deleteAliasItem={deleteAliasItem}
                            type="groups"
                            renderNoResults
                            controlledInput={currentQueryInput}
                            handleQueryInput={handleQueryInput}
                            libraryUrl={libraryUrl}
                            types={types}
                            filterUrl={filterUrl}
                            onSelect={(item, filter) => onSelect(item, filter)}
                            placeholderText={capitalize(
                                t('common:search_by_group_name...', 'search by group name...')
                            )}
                        />
                    </div>
                )}

                {currentTab === 'external' && (
                    <div className="external-tab">
                        <AddExternalLink handleAddLink={handleAddCustomLink} />
                    </div>
                )}
            </div>
        </>
    );
};

const AddExternalLink = ({ handleAddLink }) => {
    const [externalLink, setExternalLink] = useState('');
    const [externalLinkLabel, setExternalLinkLabel] = useState('');

    const handleChangeCustomLink = e => {
        setExternalLink(e.target.value);
    };

    const handleChangeCustomLinkLabel = e => {
        setExternalLinkLabel(e.target.value);
    };

    return (
        <>
            <input
                name="externalLinkLabel"
                onChange={handleChangeCustomLinkLabel}
                value={externalLinkLabel}
                placeholder="Enter label for external link"
                className="text-field w-input"
            />
            <input
                name="externalLink"
                onChange={handleChangeCustomLink}
                value={externalLink}
                placeholder="Enter external link URL"
                className="text-field w-input"
            />
            <div className="external-link-button-container">
                <button
                    className={`add-external-link-button ${
                        externalLink?.trim() !== '' && 'active'
                    }`}
                    onClick={() => {
                        handleAddLink(externalLinkLabel, externalLink);
                        setExternalLink('');
                        setExternalLinkLabel('');
                    }}
                    type="button"
                >
                    Add Link
                </button>
            </div>
        </>
    );
};

const SearchTabsMenu = ({ currentTab, setCurrentTab }) => {
    const { t } = useTranslation();
    const handleClick = e => {
        setCurrentTab(e.currentTarget.name);
    };

    return (
        <div className="search-tab-menu">
            <button
                className={`tab-card search-list-menu-tab ${currentTab === 'card' && 'active'}`}
                onClick={handleClick}
                type="button"
                name="card"
            >
                {sentenceCase(t('common:add_channel', 'add channel'))}
            </button>
            <button
                className={`tab-group search-list-menu-tab ${currentTab === 'group' && 'active'}`}
                onClick={handleClick}
                type="button"
                name="group"
            >
                {sentenceCase(t('common:add_group', 'add group'))}
            </button>
            <button
                className={`tab-external search-list-menu-tab ${
                    currentTab === 'external' && 'active'
                }`}
                onClick={handleClick}
                type="button"
                name="external"
            >
                {sentenceCase(t('common:add_external_link', 'add external link'))}
            </button>
        </div>
    );
};

export default SearchWithSelectedListTabs;
