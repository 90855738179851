import React from 'react';
import { useTranslation } from 'react-i18next';

import ExternalLink from '@components/generic/ExternalLink';
import DropdownController from '@components/generic/dropdownmenu/DropdownController';

import {
    getRsvpDisplayWord,
    isRsvpButtonCircleChecked,
    isRsvpButtonGrayscale,
} from '@helpers/events/rsvp.helpers';

import { MenuListItem } from '@core/types/Menus';
import { EventRsvp } from '@shared/welibrary-graphql/types';

type RSVPButtonProps = {
    rsvpStatus?: EventRsvp;

    // One of these two things must be provided for the button to appear
    externalLink?: string;
    dropdownMenuItems?: MenuListItem[];

    extraContainerClasses?: String;
    extraInnerClasses?: String;
};

const RSVPButton: React.FC<RSVPButtonProps> = ({
    rsvpStatus = EventRsvp.NoResponse,
    externalLink,
    dropdownMenuItems,
    extraContainerClasses = '',
    extraInnerClasses = '',
}) => {
    const { t } = useTranslation();

    const buttonText = getRsvpDisplayWord(rsvpStatus, t);
    const circleChecked = isRsvpButtonCircleChecked(rsvpStatus);
    const grayscale = isRsvpButtonGrayscale(rsvpStatus);

    if (externalLink) {
        return (
            <button
                type="button"
                className={`rsvp-button ${grayscale ? 'grayscale' : ''} ${extraContainerClasses}`}
            >
                <ExternalLink to={externalLink} className="rsvp-button-inner external-rsvp-link">
                    {buttonText}
                </ExternalLink>
            </button>
        );
    }
    if (dropdownMenuItems) {
        return (
            <DropdownController
                menuList={dropdownMenuItems}
                buttonClass={`rsvp-dropdown rsvp-button ${extraContainerClasses} ${
                    grayscale ? 'grayscale' : ''
                }`}
                extraMenuClasses="rsvp-menu"
                noIcons
                menuXOffsetOverride={0}
                menuYOffsetOverride={5}
                dropdownMenuSameWidthAsParent
            >
                <span className={`rsvp-button-inner ${extraInnerClasses}`}>
                    <div className={`rsvp-button-circle ${circleChecked ? 'checked' : ''}`} />
                    <div className="rsvp-button-text">{buttonText}</div>
                    <div className="rsvp-button-arrow" />
                </span>
            </DropdownController>
        );
    }

    return <></>;
};

export default RSVPButton;
