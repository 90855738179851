import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import { NewThumbnailAndCoverImageUpload } from '@dsc/forms/customInputs/ThumbnailAndCoverImageUpload';

import signUpStore from '@stores/SignUp';
import currentUserStore, { useCurrentUser } from '@stores/User';
import useScreenWidth from '@components/generic/hooks/useScreenWidth';
import { useUpdateUserMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import TextInput from '@dsc/forms/textInputs/TextInput';
import SignUpPageButton from '@pages/new-signup/SignUpPageButton';
import PostSignupHeader from '@pages/new-signup/PostSignupHeader';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';
import PostSignupGroupsJoinedAndRequested from './GroupsJoinedAndRequested';
import PostSignupGroupJoined from './PostSignupGroupJoined';

const localNamespace = 'imports.wlWeb.ui.pages.new-signup.post-signup.create-profile';

type CreateProfileProps = {
    goToNextStep: () => void;
    redirectUrl: string;
};

const CreateProfile: React.FC<CreateProfileProps> = ({ goToNextStep, redirectUrl }) => {
    const { t } = useTranslation();
    const { currentUser, refetchCurrentUser } = useCurrentUser();

    const groupsJoinedAndRequested = signUpStore.useTracked.groupsJoinedAndRequested();
    const activeGroup = signUpStore.get.activeGroup();

    const width = useScreenWidth();

    const showPostSignupGroups = width <= 767;

    const { _id, profile } = currentUser ?? {};
    const { picture, coverPhoto, short_bio } = profile ?? {};

    const [description, setDescription] = useState(short_bio ?? '');

    const [updateUser] = useUpdateUserMutation();

    const handleUpdatePicture = async (newPhotoUrl: string) => {
        const updatedUser = cloneDeep(currentUser);
        updatedUser.profile.picture = newPhotoUrl;
        currentUserStore.set.currentUser(updatedUser);

        await updateUser({
            variables: { _id, picture: newPhotoUrl },
        });
        refetchCurrentUser();
    };
    const handleUpdateCoverPhoto = async (newCoverUrl: string) => {
        const updatedUser = cloneDeep(currentUser);
        updatedUser.profile.coverPhoto = newCoverUrl;
        currentUserStore.set.currentUser(updatedUser);

        await updateUser({
            variables: { _id, coverPhoto: newCoverUrl },
        });
        refetchCurrentUser();
    };

    const handleUpdateDescription = async () => {
        await updateUser({
            variables: { _id, short_bio: description },
        });
        refetchCurrentUser();
    };

    return (
        <div className="flex flex-col pt-[40px] md:pt-[60px] px-[40px] pb-[40px] relative w-full h-full overflow-y-auto">
            <PostSignupHeader
                headerText={t(`common:${localNamespace}.welcome`, 'Welcome!')}
                subtitle={t(
                    `common:${localNamespace}.createdLetsFinish`,
                    "Your account has been successfully created. Now, let's finish your profile!"
                )}
                redirectUrl={redirectUrl}
                bottomMargin="15px"
            >
                {groupsJoinedAndRequested?.length > 1 && showPostSignupGroups && (
                    <div className="w-full flex items-center justify-center">
                        <PostSignupGroupsJoinedAndRequested
                            showWhiteLabelLogo={false}
                            containerClassName="!w-full md:mt-[10px] mb-[30px] md:mb-[10px] rounded-[20px]"
                            innerContainerClass="!p-0 !px-[40px] !py-[20px] !gap-[8px] bg-grayscale-body"
                            className="!w-full max-w-[100%]"
                            suppressModal={false}
                        />
                    </div>
                )}
                {activeGroup && groupsJoinedAndRequested?.length === 0 && showPostSignupGroups && (
                    <PostSignupGroupJoined
                        showWhiteLabelLogo={false}
                        containerClassName="!w-full md:mt-[10px] mb-[30px] md:mb-[10px] rounded-[20px]"
                        innerContainerClass="!p-0 !px-[40px] !py-[20px] !gap-[8px] bg-grayscale-body"
                        className="!w-full max-w-[100%]"
                        suppressModal={false}
                    />
                )}
            </PostSignupHeader>

            <NewThumbnailAndCoverImageUpload
                thumb={picture}
                cover={coverPhoto || getRandomDefaultCoverImage(currentUser?.profile?.full_name)}
                setThumb={handleUpdatePicture}
                setCover={handleUpdateCoverPhoto}
            />

            <TextInput
                value={description}
                onChange={setDescription}
                onBlur={handleUpdateDescription}
                type="textarea"
                className="!mt-[72px] !mb-0 h-[200px]"
                maxLength={100}
                label={t(
                    `common:${localNamespace}.briefDescription`,
                    'Brief Description of Yourself'
                )}
            />

            <SignUpPageButton className="md:mt-auto mt-[20px]" onClick={goToNextStep}>
                {t(`common:${localNamespace}.continue`, 'Continue')}
            </SignUpPageButton>
        </div>
    );
};

export default CreateProfile;
