import React from 'react';

import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';

import signUpStore from '@stores/SignUp';
import { useUpdateUserMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import X from '@dsc/svgs/FullSizeX';
import Pencil from '@dsc/svgs/Pencil';
import LocationMarker from '@dsc/svgs/LocationMarker';
import LocationPicker from '@components/location/LocationPicker';

import { INSERT_ID_INTO_CUSTOM_FIELD } from '@core/utilities/constants/user';
import { formatLocationObject } from '@helpers/user/location.helpers';
import { renderCountryFlag } from '@core/utilities/constants/countries';

import { Location } from '@core/types/User';
import { CustomSignupField } from '@shared/welibrary-graphql/types';
import { SignupState } from '@core/types/Signup';

const localNamespace = 'imports.wlWeb.ui.pages.signup.locationOptimizer';

type LocationOptimizerFieldProps = {
    state: { [key: string]: { value: string[] | null } };
    signupState?: SignupState;
    setSignupState?: (state: Partial<SignupState>) => void;
    locationField: CustomSignupField;
    onChange: (id: string, values: string | string[] | null, type: string) => void;
    signup?: boolean;
    version?: '1' | '2'; // 1 = old signup (and anywhere else that used it before versions were introduced), 2 = new signup and beyond
};

const LocationOptimizerField: React.FC<LocationOptimizerFieldProps> = ({
    state,
    signupState,
    setSignupState,
    locationField,
    onChange,
    signup = false,
    version = '1',
}) => {
    const { t } = useTranslation();
    const { currentUser, refetchCurrentUser } = useCurrentUser();
    const [updateUser] = useUpdateUserMutation();

    const { newModal } = useModal({ mobile: 'center' });

    const userHasAcceptedLocationRequest = locationField.id && !!state[locationField.id]?.value;

    const userId = currentUser?._id ?? INSERT_ID_INTO_CUSTOM_FIELD;

    const locationState = signUpStore.useTracked.location(); // New signup uses this instead of signupState?.location
    const currentLocation =
        locationState ?? currentUser?.profile?.location ?? signupState?.location;

    const updateLocation = (location: Location) => {
        if (currentUser) {
            updateUser({
                variables: { _id: currentUser._id, location, country: location.country },
            }).then(() => refetchCurrentUser());
        }
        setSignupState?.({ location, country: location.country });
    };

    const acceptLocationRequest = () => {
        if (!locationField.id || !locationField.type) return;

        onChange(locationField.id, [userId], locationField.type);
    };

    const rejectLocationRequest = () => {
        if (!locationField.id || !locationField.type) return;

        onChange(locationField.id, null, locationField.type);
    };

    const selectLocation = () => {
        newModal(
            <LocationPicker
                onLocationSelect={location => {
                    updateLocation(formatLocationObject(location));

                    acceptLocationRequest();
                }}
            />
        );
    };

    if (version === '1') {
        return (
            <section
                className={`location-optimizer-field ${signup ? 'signup' : ''} ${locationField.required ? 'required' : ''
                    }`}
            >
                {userHasAcceptedLocationRequest ? (
                    <section>
                        <button
                            type="button"
                            onClick={selectLocation}
                            className="edit-location"
                            title={t(`common:${localNamespace}.editLocation`, 'Edit Location')}
                        >
                            <span>{currentLocation?.formatted_address}</span>
                            <Pencil />
                        </button>
                        <button
                            aria-label="Stop sharing location"
                            title="Stop sharing location"
                            type="button"
                            onClick={rejectLocationRequest}
                            className="stop-sharing-location"
                        >
                            <X />
                        </button>
                    </section>
                ) : (
                    <button
                        type="button"
                        onClick={currentLocation ? acceptLocationRequest : selectLocation}
                        title="Share Location"
                    >
                        Choose your location
                    </button>
                )}
            </section>
        );
    }

    // version === '2'
    return (
        <section className="w-full">
            {userHasAcceptedLocationRequest ? (
                <section className="flex gap-[16px]">
                    <button
                        type="button"
                        onClick={selectLocation}
                        className="px-[20px] py-[10px] rounded-[16px] bg-grayscale-input-background w-full text-grayscale-title-active font-poppins text-[16px] leading-[175%] tracking-[0.75px] flex items-center gap-[10px]"
                        title={t(`common:${localNamespace}.editLocation`, 'Edit Location')}
                    >
                        {currentLocation?.country &&
                            renderCountryFlag({
                                countryCode: currentLocation.country,
                                customClass: 'rounded-full bg-center',
                                size: 30,
                            })}
                        <span className="grow text-left">{currentLocation?.formatted_address}</span>
                        <Pencil version="2" strokeWidth="2" />
                    </button>
                    <button
                        aria-label={t(
                            `common:${localNamespace}.stopSharingLocation`,
                            'Stop sharing location'
                        )}
                        title={t(
                            `common:${localNamespace}.stopSharingLocation`,
                            'Stop sharing location'
                        )}
                        type="button"
                        onClick={rejectLocationRequest}
                        className="text-grayscale-placeholder"
                    >
                        <X size="18" />
                    </button>
                </section>
            ) : (
                <button
                    type="button"
                    onClick={currentLocation ? acceptLocationRequest : selectLocation}
                    title={
                        currentLocation
                            ? t(`common:${localNamespace}.shareLocation`, 'Share Location')
                            : t(`common:${localNamespace}.chooseLocation`, 'Choose Location')
                    }
                    className="px-[20px] py-[10px] rounded-[16px] bg-grayscale-input-background w-full text-grayscale-placeholder font-poppins text-[16px] leading-[175%] tracking-[0.75px] flex gap-[10px] items-center"
                >
                    <LocationMarker version="2" />
                    <span className="grow text-left">
                        {currentLocation &&
                            t(`common:${localNamespace}.shareYourLocation`, 'Share your location')}
                        {!currentLocation &&
                            t(
                                `common:${localNamespace}.chooseYourLocation`,
                                'Choose your location'
                            )}
                    </span>
                    {!currentLocation && <Pencil version="2" strokeWidth="2" />}
                </button>
            )}
        </section>
    );
};

export default LocationOptimizerField;
