import React from 'react';
import PropTypes from 'prop-types';

const TagItem = props => {
    const propTypes = {
        color: PropTypes.string,
        activeFilter: PropTypes.boolean,
        tag: PropTypes.object,
        styles: PropTypes.object,
        classes: PropTypes.string,
        handleClick: PropTypes.func,
    };

    const { tag, handleClick, activeFilter } = props;

    let classes = props.classes ? classes : 'tag-item w-inline-block';
    if (activeFilter) {
        classes = 'tag-item w-inline-block tag-active-filter';
    }

    const handleTag = e => {
        e.preventDefault();
        if (handleClick) {
            handleClick(tag);
        }
    };

    const tagLabel = !activeFilter ? <p># {tag.label}</p> : tag.label;

    const clickStyle = !handleClick ? { pointerEvents: 'none' } : { pointerEvents: 'all' };

    return (
        <a onClick={handleTag} className={classes} style={clickStyle}>
            {tagLabel}
        </a>
    );
};

export default TagItem;
