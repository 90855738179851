import { TFunction } from 'i18next';
import { ImageWithLoadingState } from '@core/types/Utilities';

const localNamespace = 'common:global.loading';

const getLoadingText = (
    state: ImageWithLoadingState,
    t: TFunction,
    small: boolean
): React.ReactNode => {
    if (small) return state.loading?.type === 'uploading' && `${state.loading.progress || 0}%`;

    if (state.loading?.type === 'resizing') return t(`${localNamespace}.resizing`);

    if (state.loading?.type === 'uploading') {
        return state.loading?.progress
            ? `${t(`${localNamespace}.uploading`)} (${state.loading.progress}%)`
            : t(`${localNamespace}.starting_upload`);
    }
};

export default getLoadingText;
