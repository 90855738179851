import React from 'react';

import { useCurrentUser } from '@stores/User';

import RecommendationItem from '@components/discover/RecommendationItem';
import EmptyRecommendationItem from '@components/discover/EmptyRecommendationItem';
import HorizontalScrollMenu from '@components/generic/horizontalscrollmenu/HorizontalScrollMenu';

import { Maybe, Recommendation } from '@shared/welibrary-graphql/types';

const DiscoverRecommendationsList: React.FC<{
    recommendations: Maybe<Recommendation[]>;
    showOptimize: Function;
}> = ({ recommendations, showOptimize }) => {
      const { currentUser } = useCurrentUser();

    // Client side filter out loggedInUser from their own matches. Also filter out 0% recs. TODO: Move this server side.
    const filteredRecommendations = recommendations?.filter(recommendation => {
        if (!recommendation.precision) return false;
        return currentUser && 'user' in recommendation
            ? recommendation?.user?._id !== currentUser._id
            : true;
    });

    if ((filteredRecommendations?.length ?? 0) > 0) {
        return (
            <HorizontalScrollMenu>
                {filteredRecommendations?.map(recommendation => {
                    let source;
                    switch (recommendation.__typename) {
                        case 'CardRecommendation':
                            source = recommendation.card;
                            break;
                        case 'GroupRecommendation':
                            source = recommendation.group;
                            break;
                        case 'UserRecommendation':
                            source = recommendation.user;
                            break;
                        default:
                            return <></>;
                    }

                    return (
                        <RecommendationItem
                            key={source?._id}
                            source={source}
                            matchData={recommendation}
                            itemId={source?._id} // needed for horizontal scroll to work
                        />
                    );
                })}
            </HorizontalScrollMenu>
        );
    }

    return <EmptyRecommendationItem showOptimize={showOptimize} />;
};

export default DiscoverRecommendationsList;
