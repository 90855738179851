import styled from 'styled-components';
import React from 'react';

const SSkeletonPulse = styled.span`
    display: inline-block;
    height: 100%;
    width: 100%;
    background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
    background-size: 400% 400%;
    animation: wave 1.2s ease-in-out infinite;
    @keyframes wave {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: -135% 0%;
        }
    }
`;

export default SSkeletonPulse;
