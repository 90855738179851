import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { useCurrentUser } from '@stores/User';

import RSVPButton from '@components/card/mediacard/bodyitems/RSVPButton';

import { getEventListingRsvpStatus, getRsvpDropdownOptions } from '@helpers/events/rsvp.helpers';

import { RSVP_TO_EVENT } from '@shared/welibrary-graphql/content_card/mutations';
import { ContentCard, EventRsvp } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

type EventItemRSVPButtonProps = {
    card: ContentCard;
    refetch?: any;
};

const EventItemRSVPButton: React.FC<EventItemRSVPButtonProps> = ({ card, refetch }) => {
    const { t } = useTranslation();

    const customRsvpLink = card?.eventSettings?.rsvpLink ?? card?.eventOverviewDetails?.rsvpLink;

    const { currentUser } = useCurrentUser();
    const currentUserRsvpStatus = getEventListingRsvpStatus(card, currentUser);

    const [rsvpToEvent] = useMutation(RSVP_TO_EVENT);
    const handleRSVP = (status: EventRsvp) => {
        rsvpToEvent({
            variables: {
                cardUrl: card._id,
                status,
            },
        })
            .then(() => {
                if (refetch) {
                    refetch?.();
                }
            })
            .catch(err => logger.error(err));
    };

    if (customRsvpLink) {
        return <RSVPButton externalLink={customRsvpLink} />;
    }

    return (
        <RSVPButton
            rsvpStatus={currentUserRsvpStatus}
            dropdownMenuItems={getRsvpDropdownOptions(currentUserRsvpStatus, handleRSVP, t)}
        />
    );
};

export default EventItemRSVPButton;
