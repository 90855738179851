import React from 'react';
import useModal from '@web/ui/components/modals/hooks/useModal';
import NewPostOverlay from '@web/ui/components/content/newpost/NewPostOverlay';

type NewPostProps = {
    allowedTypes: any;
    prompt: string;
    feedUrl: string;
    chapter: any;
    chapterShowTitle: any;
    closeModal: any;
    showReferenceForChapter: any;
    showConfirmation: boolean;
    threeDotRightMargin: any;
    displaySocialHeaders: boolean;
    visibilityRefSetting: any;
    group?: any;
    useWorkflow?: any;
    sortType?: any;
    onPromptSubmitted?: any;
    newPostOptions?: any;
};

const useNewPostOverlay = (newPostProps: NewPostProps) => {
    const { newModal, closeModal } = useModal({ mobile: 'center' });

    const options = {
        className: 'new-post-overlay',
        hideButton: true,
    };

    // options?.classes?.section
    const openNewPost = () => {
        newModal(<NewPostOverlay {...newPostProps} closeModal={closeModal} />, options);
    };

    return {
        openNewPost,
    };
};

export default useNewPostOverlay;
