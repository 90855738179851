import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { polyfill } from 'seamless-scroll-polyfill';
import 'firebase/analytics';
import { Capacitor } from '@capacitor/core';

import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';
import {
    initializeFirebase,
    registerServiceWorker,
} from '@web/utilities/helpers/startup/firebase.helpers';
import applySettingsTheme from '@web/utilities/helpers/startup/theme.helpers';

const useStartup = () => {
    useEffect(() => {
        const publicSettings = getWhitelabelPublicSettings();

        const { sentry: { dsn } = {}, root_url, version } = publicSettings;

        applySettingsTheme(publicSettings);

        Sentry.init({
            dsn,
            release: `${root_url}@${version}`,
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications',
            ],
            // See https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        });

        polyfill();

        if (!Capacitor.isNativePlatform()) {
            registerServiceWorker();
            initializeFirebase();
        }
    }, []);
};

export default useStartup;
