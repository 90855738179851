import _ from 'lodash';
import { URI_REGEX } from './regexes';

import getLogger from '@core/logger';

const logger = getLogger(module);

const NOTIFICATION_TYPE_DEFAULT = 'DEFAULT';
const ANDROID_CHANNEL_DESCRIPTION = 'Android Channel';

const DATA_URI_PREFIX = 'custom:';

const ctaURItoData = link => {
    // link: `custom:${eventDetails.action}:${JSON.stringify({_id: requestDetails.user._id, cardUrl: data.card.url, role: requestDetails.role})}`
    if (!link) return null;
    if (!_.isString(link)) return null;

    let returnData;

    if (link.substr(0, DATA_URI_PREFIX.length) === DATA_URI_PREFIX) {
        const uriData = link.match(URI_REGEX);

        if (_.isArray(uriData)) {
            if (uriData.length > 2) {
                const linkType = uriData[0].replace(':', '');
                const linkKeyword = uriData[1].replace(':', '');
                const linkJsonData = uriData[2];
                let linkData = {};

                if (linkJsonData) {
                    try {
                        linkData = JSON.parse(linkJsonData);
                    } catch (error) {
                        logger.error(error);
                    }
                }

                returnData = {
                    type: linkType,
                    keyword: linkKeyword,
                    data: linkData,
                };
            }
        }
    }

    if (!returnData) {
        returnData = {
            link,
        };
    }

    return returnData;

    // custom:cardAccessRequest:{\"_id\":\"8tirHtEcK3D4wBFxM\",\"cardUrl\":\"friendship-shelf\",\"role\":\"admin\"}"
};

// _id is optional
const getUserNotificationRule = (settings, action, channel, _id) => {
    const setting = validNotificationPayload(settings, action, channel);
    if (!setting) return null;

    const channelSettings = setting.channels.find(c => c.type === channel);
    if (!channelSettings) {
        return null;
    }

    const channelDefault = channelSettings.level;

    const stream =
        channelSettings.streams && _id ? channelSettings.streams.find(s => s._id === _id) : null;
    const streamDefault = stream?.level;

    return streamDefault ?? channelDefault ?? null;
};

const validNotificationPayload = (settings, actionType, channelType) => {
    if (!settings || !actionType || !channelType) {
        return false;
    }

    const setting = settings.find(s => s.type === actionType);
    return setting && setting.channels ? setting : false;
};

const getCollectionTypeFromEventAction = eventAction => {
    const action = Object.keys(ACTION_TYPE).find(k => ACTION_TYPE[k] === eventAction);
    const collection = Object.keys(ACTION_TYPES_GROUPS).find(k =>
        ACTION_TYPES_GROUPS[k].includes(action)
    );
    return collection ?? '';
};

const NOTIFICATION_LEVEL = {
    OFF: 'OFF',
    ON: 'ON',
    MENTION: 'MENTION', // not currently supported
};

const CHANNEL = {
    PUBSUB: 'PUBSUB',
    PUSH: 'PUSH',
};

// Must be inline with GraphQL EventAction
const ACTION_TYPE = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    USER_REQUEST_CONNECTION: 'requestConnection',
    USER_CANCEL_REQUEST_CONNECTION: 'cancelRequestConnection',
    USER_ACCEPT_CONNECTION: 'acceptConnection',
    USER_REJECT_CONNECTION: 'rejectConnection',
    USER_DISCONNECT: 'disconnect',
    ROLE_ADMIN_PROMOTION: 'roleAdminPromotion',
    CARD_VOTE_ADDED: 'voteAdded',
    DASHFEED_PUSHED: 'dashfeedPushed',
    ATTACHED_CARD_CHAPTER: 'attachedCardToChapter',
    ADMIN_ANNOUNCEMENT: 'adminAnnouncement',
    MISSED_PLEDGE: 'missedPledgeCommitment',
    CARD_ACCESS_REQUEST: 'cardAccessRequest',
    ELECTION_START: 'electionStart',
    ELECTION_REMINDER: 'electionReminder',
    ELECTION_END: 'electionEnd',
    GROUP_ACCESS_REQUEST: 'groupAccessRequest',
    GROUP_INVITE_REQUEST: 'groupInviteRequest',
    GROUP_PRE_AUTH_PAYMENT_REQUEST: 'groupPreAuthPaymentRequest',
    GROUP_PRE_AUTH_PAYMENT_REQUEST_APPROVED: 'groupPreAuthPaymentRequestApproved',
    NEW_MESSAGE: 'newMessage',
    GROUP_INVITE_ACCEPTED: 'groupInviteAccepted',
    GROUP_ACCESS_APPROVED: 'groupAccessApproved',
    GROUP_ADMIN_PROMOTED: 'groupAdminPromoted',
    NEW_APPLICATION: 'newApplication',
    POSTING_CLOSED: 'postingClosed',
    APPLICATION_ACCEPTED: 'applicationAccepted',
    APPLICATION_REJECTED: 'applicationRejected',
    MOBILIZATION_EFFORT_COMPLETE: 'mobilizationEffortComplete',
    MOBILIZATION_COMPLETE: 'mobilizationComplete',
    UNREAD_MESSAGES_NUDGE: 'unreadMessagesNudge',
    MISSED_PLEDGE_COMMITMENT: 'missedPledgeCommitment',
    USER_TICKET_TRANSFER: 'ticketTransfer',
    GROUP_SUBSCRIPTION_PRICE_CHANGE: 'subscriptionPriceChange',
    GROUP_SUBSCRIPTION_DELETED: 'subscriptionDeleted',
    GROUP_CONNECTED_ACCOUNT_READY: 'groupConnectedAccountReady',
    GROUP_SPEAKER_INVITE: 'groupSpeakerInvite',
    GROUP_CONTENT_AUTO_MODERATED: 'groupContentAutoModerated',
    GROUP_CONTENT_MODERATED_ACCEPTED: 'groupContentModeratedAccepted',
    GROUP_CONTENT_MODERATED_REJECTED: 'groupContentModeratedRejected',
    CARD_CONTENT_AUTO_MODERATED: 'cardContentAutoModerated',
    CARD_CONTENT_MODERATED_ACCEPTED: 'cardContentModeratedAccepted',
    CARD_CONTENT_MODERATED_REJECTED: 'cardContentModeratedRejected',
};

const COLLECTION_TYPE = {
    USER: 'USER',
    GROUP: 'GROUP',
    COMMENT: 'COMMENT',
    CONTENT_CARD: 'CONTENT_CARD',
    MESSAGE_THREAD: 'MESSAGE_THREAD',
};

const ACTION_TYPES_GROUPS = {
    USER: [
        'USER_REQUEST_CONNECTION',
        'USER_CANCEL_REQUEST_CONNECTION',
        'USER_ACCEPT_CONNECTION',
        'USER_REJECT_CONNECTION',
        'USER_DISCONNECT',
        'USER_TICKET_TRANSFER',
    ],
    GROUP: [
        'GROUP_ACCESS_REQUEST',
        'GROUP_INVITE_REQUEST',
        'GROUP_INVITE_ACCEPTED',
        'GROUP_PRE_AUTH_PAYMENT_REQUEST',
        'GROUP_PRE_AUTH_PAYMENT_REQUEST_APPROVED',
        'GROUP_ACCESS_APPROVED',
        'GROUP_ADMIN_PROMOTED',
        'ROLE_ADMIN_PROMOTION',
        'ADMIN_ANNOUNCEMENT',
        'DASHFEED_PUSHED',
        'MISSED_PLEDGE',
        'GROUP_SUBSCRIPTION_PRICE_CHANGE',
        'GROUP_SUBSCRIPTION_DELETED',
        'GROUP_CONNECTED_ACCOUNT_READY',
        'GROUP_SPEAKER_INVITE',
        'GROUP_CONTENT_AUTO_MODERATED',
        'GROUP_CONTENT_MODERATED_ACCEPTED',
        'GROUP_CONTENT_MODERATED_REJECTED',
    ],
    CONTENT_CARD: [
        'CARD_VOTE_ADDED',
        'CARD_ACCESS_REQUEST',
        'ATTACHED_CARD_CHAPTER',
        'ELECTION_START',
        'ELECTION_REMINDER',
        'ELECTION_END',
        'CREATE',
        'UPDATE',
        'DELETE',
        'CARD_CONTENT_AUTO_MODERATED',
        'CARD_CONTENT_MODERATED_ACCEPTED',
        'CARD_CONTENT_MODERATED_REJECTED',
    ],
    MESSAGE_THREAD: ['NEW_MESSAGE'],
};

export {
    NOTIFICATION_TYPE_DEFAULT,
    ANDROID_CHANNEL_DESCRIPTION,
    ctaURItoData,
    NOTIFICATION_LEVEL,
    ACTION_TYPE,
    ACTION_TYPES_GROUPS,
    getUserNotificationRule,
    getCollectionTypeFromEventAction,
    CHANNEL,
    COLLECTION_TYPE,
};
