import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomStep from '@web/ui/pages/signup/CustomStep';

const Optimizer = ({
    handleChange,
    handleCustomFieldsChange,
    optimizeState,
    handleSubmit,
    groupCustomFields,
    isUserOptimizer,
    isCardOptimize,
    group,
}) => {
    const { t } = useTranslation();

    const onSubmit = e => {
        e.preventDefault();
        handleSubmit();
    };

    // Content cards cannot use the personal info component or
    // answer the location or organization fields at the moment
    const fields = !isCardOptimize
        ? groupCustomFields
        : groupCustomFields?.filter(field => field.id !== 'cf_0_2' && field.id !== 'cf_1_3');

    return (
        <form onSubmit={onSubmit}>
            <CustomStep
                state={optimizeState}
                signupChunk={fields}
                handleChange={handleChange}
                handleCustomChange={handleCustomFieldsChange}
                lightBackground
                showPrompt
                showPersonalInformation={isUserOptimizer}
                membershipGroup={group}
            />
            <div className="edit-footer">
                <button
                    type="submit"
                    className="button callout-button w-button edit-screen-content"
                >
                    {t('common:global.verbs.save')}
                </button>
            </div>
        </form>
    );
};

export default Optimizer;
