import React, { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import 'firebase/messaging';
import SiteHeadMeta from '@components/generic/SiteHeadMeta';
import '~/app.scss';

import getLogger from '@core/logger';
import { config } from '@config/getConfig';
import { awsMetricInit } from '@welibraryos/metrics';
import Store from '@core/client/store/store';
import * as lodash from 'lodash';
import useStartup from '@startup/client/hooks/useStartup';
import configureLiveUpdate from '@startup/client/appflow/liveupdate';
import MainLoading from '~/wl-startup/client/main-loading';
import Main from '~/wl-startup/client/main';
import { useApolloClientPersistedCache } from '@shared/welibrary-apollo';
import { ApolloProvider } from '@shared/welibrary-apollo/provider';
import Providers, { AllProviders } from './Providers';
import AppUrlListener from './wl-startup/AppUrlListener';
import useAuthToken from '~/wl-web/utilities/hooks/auth/useAuthToken';
import MeteorProxy from './meteor-proxy';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';
import { getAuthToken, unsetAuthToken } from './wl-web/utilities/helpers/auth';

const logger = getLogger(module);

_ = lodash;

/**
 * If the aws metrics namespace is defined, initialize the
 * metrics with this namespace and the aws keys.
 */
if (config?.public?.awsMetrics?.namespace) {
    awsMetricInit({
        namespace: config?.public?.awsMetrics.namespace,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_DEFAULT_REGION,
    });
} else {
    logger.debug('Skipped initialization of AWS metrics due to missing namespace.');
}

// const performHeapTask = () => {
//     (window.heap = window.heap || []),
//         (heap.load = function (e, t) {
//             (window.heap.appid = e), (window.heap.config = t = t || {});
//             const r = t.forceSSL || 'https:' === document.location.protocol,
//                 a = document.createElement('script');
//             (a.type = 'text/javascript'),
//                 (a.async = !0),
//                 (a.src = (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + e + '.js');
//             const n = document.getElementsByTagName('script')[0];
//             n.parentNode.insertBefore(a, n);
//             const o = function (e) {
//                     return function () {
//                         heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
//                     };
//                 },
//                 p = [
//                     'addEventProperties',
//                     'addUserProperties',
//                     'clearEventProperties',
//                     'identify',
//                     'removeEventProperty',
//                     'setEventProperties',
//                     'track',
//                     'unsetEventProperty',
//                 ];
//             let c = 0;
//             for (; c < p.length; c++) heap[p[c]] = o(p[c]);
//         });
//     heap.load('1843539735');
// }

const App = () => {
    const { root_url, version, disablePersistedCache, logout_redirect } =
        getWhitelabelPublicSettings;

    const isMobileApp = !!Capacitor.isNativePlatform();

    const { token, migratingUser, migratedUser } = useAuthToken();
    const { client } = useApolloClientPersistedCache({
        apiUrl: config.public.apiUrl,
        root_url,
        version,
        disablePersistedCache,
        logout_redirect,
        isMobileApp,
        getAuthToken,
        unsetAuthToken,
        log: logger.error,
        debugLog: logger.debug,
    });

    useStartup();

    useEffect(() => {
        document.addEventListener('deviceready', configureLiveUpdate, false);
        return () => document.removeEventListener('deviceready', configureLiveUpdate);
    }, []);

    /**
     * while migrating from Meteor sessions to JWTs, we are silently migrating users
     * to new auth mechanism to not disrupt them. we do this at root because we have
     * components relying on the auth token here and want a complete refresh on migration.
     *
     * to enforce refresh we are resetting apollo cache. this isn't done within
     * the hook itself because the client isn't yet defined in component tree (Apollo Provider below).
     * hence we're doing it here! if we've run the migration and the client exists, reset it :).
     * this should likely be removed once enough time has gone by where all active users have been able to migrate.
     */
    useEffect(() => {
        if (migratedUser && client) {
            client.resetStore();
        }
    }, [migratedUser]);

    useEffect(() => {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        const isNativeApp = Capacitor.isNativePlatform();

        /* reason for this is mobile safari needs height 100% to avoid weird scrolling issues */
        /* but native app does this weird thing where it initially loads the bottom menu wrong when that is so... */
        if (isNativeApp) {
            document.querySelector('#app').style.height = '100vh';
        }
    }, []);

    if (migratingUser || client === undefined) {
        return (
            <Providers>
                <SiteHeadMeta />
                <MainLoading userToken={token} />
                <div className="black-notch-area" />
                <div id="modal-infra-root" />
                <div id="modal-root" />
                <div id="modal-mid-root" />
                <div id="modal-super-root" />
            </Providers>
        );
    }

    return (
        <BrowserRouter>
            <ApolloProvider client={client}>
                <Provider store={Store}>
                    <AllProviders client={client}>
                        <SiteHeadMeta />
                        <Main client={client} token={token} />
                        <div className="black-notch-area" />
                        <div id="modal-infra-root" />
                        <div id="modal-root" />
                        <div id="modal-mid-root" />
                        <div id="modal-super-root" />
                    </AllProviders>
                </Provider>
            </ApolloProvider>
        </BrowserRouter>
    );
};

export default App;
