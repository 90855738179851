import React from 'react';

import ConditionalWrapper from '@web/ui/components/generic/ConditionalWrapper';
import ExternalLink from '@web/ui/components/generic/ExternalLink';
import Picture from '@web/ui/components/generic/Picture';

const DISABLE_PICTURE_LINKS = [
    'https://t3.editor.cassproject.org/import',
    'https://credreg.net/page/T3Mapping?mode=iframe',
];

const PhotoItem = ({
    disableLink = false,
    links,
    media,
    transparentBackground,
    maxWidth,
    onLoad,
    onClickCallback, // optional callback when image is clicked; pass-through from parent to "<Picture />"
}) => {
    const url = media?.thumb;
    const externalLink = links?.source;

    const hasLink =
        externalLink && externalLink.trim() !== '' && !externalLink.includes('filestackcontent');

    // LERHub specific
    if (DISABLE_PICTURE_LINKS.includes(externalLink)) {
        disableLink = true;
    }

    return (
        <section
            className="mc-media-item photoitem"
            style={transparentBackground && { backgroundColor: 'transparent' }}
        >
            <ConditionalWrapper condition={hasLink} Wrapper={ExternalLink} to={externalLink}>
                <Picture
                    onLoad={onLoad}
                    disableLink={disableLink}
                    url={url}
                    style={url && { backgroundColor: 'transparent' }}
                    maxWidth={maxWidth}
                    onClickCallback={onClickCallback}
                />
            </ConditionalWrapper>
        </section>
    );
};

export default PhotoItem;
