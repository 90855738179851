import React from 'react';

type SignUpPageButtonProps = {
    children: React.ReactNode;
    type?: JSX.IntrinsicElements['button']['type']; // 'button' | 'submit' | 'reset';
    onClick?: () => void;
    disabled?: boolean;
    inverseColors?: boolean;
    className?: string;
};

const SignUpPageButton: React.FC<SignUpPageButtonProps> = ({
    children,
    type = 'button',
    onClick,
    disabled = false,
    inverseColors = false,
    className = '',
}) => {
    const sharedClasses =
        'w-full rounded-[40px] font-poppins text-[16px] font-[600] leading-[175%] tracking-[0.75px] flex gap-[10px] items-center justify-center py-[11px] h-[50px]';
    const standardColorClasses = `bg-primary-default text-grayscale-background ${disabled ? '' : 'hover:bg-hover'
        }`;
    const inverseColorClasses = `text-primary-default border-grayscale-line border-solid border-[2px] ${disabled ? '' : 'hover:text-hover'
        }`;
    const disabledClass = 'opacity-60';

    return (
        <button
            type={type}
            className={`${sharedClasses} ${inverseColors ? inverseColorClasses : standardColorClasses
                } ${disabled ? disabledClass : ''} ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default SignUpPageButton;
