/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-comment-textnodes */

import React, {useState} from 'react';
import SearchPeopleContainer from '@web/ui/components/generic/search/SearchPeopleContainer';
import Modal from '@web/ui/components/generic/lightbox/Modal';
import CreateNewAuthorForm from '@web/ui/components/forms/form/CreateNewAuthorForm';
import {useTranslation} from 'react-i18next';
import {getRandomDefaultUserImage} from '@core/utilities/constants/defaults';

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard.changeAuthor';

const ChangeAuthorModal = ({ author, handleChangeAuthor, handleCloseModal, showModal }) => {
    const [currentTab, setCurrentTab] = useState('existing');
    const [selectedAuthor, setSelectedAuthor] = useState(author);

    const authorName = selectedAuthor?.profile?.full_name;
    const authorPicture =
        selectedAuthor?.profile?.picture ||
        selectedAuthor?.profile?.coverPhoto ||
        getRandomDefaultUserImage();
    const tabBaseClass = 'button-reset author-tab';
    const tabActiveClass = 'button-reset author-tab active-tab';
    const isSaveButtonActive = selectedAuthor?._id !== author?._id;
    const buttonBaseClass = 'author-save-button';
    const buttonDisabledClass = 'author-save-button disabled';
    const buttonClass = isSaveButtonActive ? buttonBaseClass : buttonDisabledClass;

    const handleChangeTab = e => {
        setCurrentTab(e.currentTarget.name);
    };

    /**
     * Sets currently selected author
     * @param {object} user - user object with {_id, profile{full_name, photo}} fields
     */
    const handleSelectAuthor = user => {
        setSelectedAuthor(user);
    };

    const handleSave = () => {
        handleChangeAuthor(selectedAuthor);
        handleCloseModal();
    };

    const { t } = useTranslation();

    return (
        <Modal
            extraModalBodyClass="no-overflow-modal"
            delayTime={500}
            className="modal"
            isMounted={showModal}
        >
            <div className="wrapper-positioning">
                <div className="wrapper">
                    <header>
                        <section className="change-author modal-form-title-bar">
                            <h4>{t(`common:${localNamespace}.change_author_title`)}</h4>
                            <button
                                label="Change Author"
                                type="button"
                                onClick={handleCloseModal}
                                className="cancel-edit-content-button w-inline-block"
                            />
                        </section>
                    </header>
                    <div className="author-modal items-list">
                        <section className="author-switch-content-container">
                            <div>
                                <p>{t(`common:${localNamespace}.change_author_desc`)}</p>

                                <div className="selected-author-div">
                                    <div className="blog-card-author-container">
                                        <div
                                            className="thumbnail square-thumb"
                                            style={{ backgroundImage: `url(${authorPicture})` }}
                                        >
                                            <div className="square-spacer" />
                                        </div>
                                        <div className="selected-author-text">
                                            Selected Author: {authorName}
                                        </div>
                                    </div>
                                </div>
                                <div className="author-tabs-menu">
                                    <button
                                        type="button"
                                        name="existing"
                                        onClick={handleChangeTab}
                                        className={
                                            currentTab === 'existing'
                                                ? tabActiveClass
                                                : tabBaseClass
                                        }
                                    >
                                        {t(`common:${localNamespace}.existing_users`)}
                                    </button>
                                    <button
                                        type="button"
                                        name="new"
                                        onClick={handleChangeTab}
                                        className={
                                            currentTab === 'new' ? tabActiveClass : tabBaseClass
                                        }
                                    >
                                        {t(`common:${localNamespace}.add_new_user`)}
                                    </button>
                                </div>
                            </div>
                            {currentTab === 'existing' && (
                                <div>
                                    <SearchPeopleContainer handleClick={handleSelectAuthor} />
                                </div>
                            )}
                            {currentTab === 'new' && (
                                <div>
                                    <CreateNewAuthorForm afterSubmit={handleSelectAuthor} />
                                </div>
                            )}

                            <div className="author-change-bottom">
                                <button type="button" onClick={handleSave} className={buttonClass}>
                                    {t(`common:${localNamespace}.save_author`)}
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ChangeAuthorModal;
