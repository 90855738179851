import React from 'react';

import useModal from '@components/modals/hooks/useModal';
import useConfirmation from '@components/generic/hooks/useConfirmation';

import { ModalContainer } from '@core/types/Modals';

const FullScreenModal: ModalContainer = ({ component, options, open }) => {
    const { closeModal } = useModal();
    const confirm = useConfirmation({ widen: true, className: options?.confirmationClassName });

    const optionalClass = options?.className || '';
    const customSectionClass = options?.sectionClassName || '';

    const handleCloseModal = () => {
        if (options.disableCloseHandlers) return;

        if (options?.confirmClose) {
            confirm({ text: options.confirmClose, onConfirm: () => setTimeout(closeModal, 350) });
        } else {
            options?.onClose?.();
            closeModal();
        }
    };

    return (
        <aside
            id="full-screen-modal"
            className={`${optionalClass} ${open ? 'open' : 'closed'} ${
                options?.hideDimmer ? 'hide-dimmer' : ''
            }`}
        >
            {!options?.hideDimmer && (
                <button
                    className="full-screen-modal-dimmer"
                    type="button"
                    onClick={handleCloseModal}
                    aria-label="modal-dimmer"
                    aria-hidden
                />
            )}
            <section
                className={`${optionalClass} ${options?.widen ? 'widen' : ''} ${
                    options?.addShadow ? 'add-shadow' : ''
                } ${customSectionClass}`}
            >
                {component}
            </section>
        </aside>
    );
};

export default FullScreenModal;
