import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useUpdateGroupMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { useGetEventGroupQuery } from '@shared/welibrary-graphql/user/queries.hook';

import useModal from '@components/modals/hooks/useModal';
import useEventGroupFormState from '@components/content/newpost/forms/EventGroup/hooks/useEventGroupFormState';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary';
import EventGroupForm from '@components/content/newpost/forms/EventGroup/EventGroupForm';
import ModalLoading from '@components/modals/ModalLoading';

import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';

import { getOverviewTime, getEarliestDay, getLatestDay } from '@core/utilities/constants/events';
import {
    SponsorTier,
    SpeakerTransformedPayload,
    EventGroupFormModeEnum,
} from '@core/types/EventGroup';
import { ContentCard, Group, GroupSubtype } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

import getLogger from '@core/logger';

const logger = getLogger(module);

type UpdateEventFormProps = {
    hasAdminRole?: boolean;
    group: Maybify<Group>;
    initialStep?: string;
};

export const UpdateEventGroupForm: React.FC<UpdateEventFormProps> = ({ group, initialStep }) => {
    const [state, setState] = useEventGroupFormState(group);
    const [ticketCurrency, setTicketCurrency] = useState<string>(
        state?.ticketFormState?.products?.[0]?.currency ?? 'USD'
    );

    const [groupRes, setGroupRes] = useState(null);
    const [loading, setLoading] = useState(false);

    const [updateGroup] = useUpdateGroupMutation();

    const { newModal, closeModal } = useModal();

    const idempotencyKey = useRef(uuidv4());

    useEffect(() => {
        handleUpdateTicketCurrency();
    }, [ticketCurrency]);

    // handles updating all existing tickets with the selected currency type
    const handleUpdateTicketCurrency = () => {
        setState(prevState => {
            return {
                ...prevState,
                ticketFormState: {
                    ...prevState?.ticketFormState,
                    products: [
                        ...prevState?.ticketFormState?.products?.map(product => ({
                            ...product,
                            currency: ticketCurrency,
                        })),
                    ],
                },
            };
        });
    };

    const onSubmit = async () => {
        const {
            overviewFormState,
            dateAndTimeState,
            itineraryState,
            sponsorsFormState,
            previewFormState,
            ticketFormState,
            groupSettingsState,
        } = state;

        const allSpeakers: SpeakerTransformedPayload[] = [];

        const timeAndPlaceSettings = itineraryState?.map(session => {
            const {
                title,
                headline,
                description,
                dates,
                startTime,
                endTime,
                link,
                timeZone,
                speakers,
                eventLocationSettings,
                displayStartTime,
                displayEndTime,
            } = session;

            const isNewSpeaker = (speakerId: string) => {
                return speakerId?.includes('new-speaker-');
            };

            const aliasItemSpeakersInput = speakers?.map(speaker => {
                const newSpeakerBool = isNewSpeaker(speaker?._id);

                if (allSpeakers?.length === 0) {
                    if (
                        speaker.hasOwnProperty('_id') &&
                        !newSpeakerBool &&
                        !allSpeakers.find(s => s?.userId === speaker?._id)
                    ) {
                        allSpeakers.push({
                            userId: speaker?._id,
                            name: speaker?.name,
                            email: speaker?.email,
                            shortBio: speaker?.bio,
                            profilePicture: speaker?.profileThumb?.image,
                            type: 'alias_user',
                        });
                    } else if (
                        speaker.hasOwnProperty('_id') &&
                        newSpeakerBool &&
                        !allSpeakers.find(s => s?.email === speaker?.email)
                    ) {
                        allSpeakers.push({
                            name: speaker?.name,
                            email: speaker?.email,
                            shortBio: speaker?.bio,
                            profilePicture: speaker?.profileThumb.image,
                            type: 'alias_user',
                        });
                    }
                }

                if (
                    speaker.hasOwnProperty('_id') &&
                    !newSpeakerBool &&
                    !allSpeakers.find(s => s?.userId === speaker?._id)
                ) {
                    allSpeakers.push({
                        userId: speaker?._id,
                        name: speaker?.name,
                        email: speaker?.email,
                        shortBio: speaker?.bio,
                        profilePicture: speaker?.profileThumb?.image,
                        type: 'alias_user',
                    });
                } else if (
                    speaker.hasOwnProperty('_id') &&
                    newSpeakerBool &&
                    !allSpeakers.find(s => s?.email === speaker?.email)
                ) {
                    allSpeakers.push({
                        name: speaker?.name,
                        email: speaker?.email,
                        shortBio: speaker?.bio,
                        profilePicture: speaker?.profileThumb?.image,
                        type: 'alias_user',
                    });
                }

                if (speaker.hasOwnProperty('_id') && newSpeakerBool) {
                    return {
                        name: speaker?.name,
                        email: speaker?.email,
                        shortBio: speaker?.bio,
                        profilePicture: speaker?.profileThumb?.image,
                        type: 'alias_user',
                    };
                }

                return {
                    userId: speaker?._id,
                    name: speaker?.name,
                    email: speaker?.email,
                    shortBio: speaker?.bio,
                    profilePicture: speaker?.profileThumb?.image,
                    useUserBio: speaker?.useUserBio,
                    type: 'alias_user',
                };
            });

            const earliestDay = getEarliestDay(dates, dates[0].startDate);
            const latestDay = getLatestDay(dates, dates[0].startDate);

            const timeAndPlaceSetting = {
                title,
                headline,
                description,
                dates,
                startTime: getOverviewTime(new Date(startTime), earliestDay),
                endTime: getOverviewTime(new Date(endTime), latestDay),
                link,
                timeZone: dateAndTimeState?.timeZone, // Set timezone to event overview timezone
                displayStartTime,
                displayEndTime,
                eventLocationSettings,
                speakerSettings: {
                    speakers: aliasItemSpeakersInput,
                },
            };

            return timeAndPlaceSetting;
        });

        const _sponsors = sponsorsFormState?.sponsors?.map(sponsor => {
            const {
                name,
                link,
                logo: { image },
                tier,
            } = sponsor;
            return {
                name,
                link,
                logo: image,
                tier,
            };
        });

        const sponsorTiers = sponsorsFormState?.tiers?.map((tier: SponsorTier) => {
            const tierSponsors = _sponsors
                ?.filter(sponsor => {
                    return sponsor?.tier?.name === tier?.name;
                })
                .map(sponsor => {
                    const { name, link, logo } = sponsor;
                    return {
                        name,
                        link,
                        logo,
                    };
                });

            return {
                name: tier?.name,
                order: tier?.order,
                sponsors: tierSponsors,
            };
        });

        const sponsorSettings = {
            tiers: sponsorTiers,
            description: sponsorsFormState.description,
        };

        const { startTime, endTime, dates } = dateAndTimeState;
        const earliestDay = getEarliestDay(dates, dates[0].startDate);
        const latestDay = getLatestDay(dates, dates[0].startDate);

        const eventOverviewDetails = {
            title: overviewFormState.title,
            description: overviewFormState.description,
            dates: dateAndTimeState.dates,
            startTime: getOverviewTime(new Date(startTime), earliestDay),
            endTime: getOverviewTime(new Date(endTime), latestDay),
            timeZone: dateAndTimeState.timeZone,
            displayStartTime: dateAndTimeState.displayStartTime,
            displayEndTime: dateAndTimeState.displayEndTime,
            eventLocationSettings: overviewFormState.eventLocationSettings,
            sponsorSettings,
            speakerSettings: {
                speakers: allSpeakers,
            },
            hideAttendeeCount: groupSettingsState?.hideAttendeeCount,
        };

        const settings = {
            protectionLevel: groupSettingsState?.protectionLevel,
            recommendations: groupSettingsState?.recommendations,
            shortCode: groupSettingsState.shortCode,
            groupChatroom: groupSettingsState.groupChatroom,
            adminChatroom: groupSettingsState.adminChatroom,
            approvalRequired: groupSettingsState.approvalRequired,
            registrationTitle: groupSettingsState?.registrationTitle,
            registrationDescription: groupSettingsState.registrationDescription,
            registrationActionText: groupSettingsState.registrationActionText,
            canMembersSuggestEdits: groupSettingsState.canMembersSuggestEdits,
            enableGroupNewsfeed: groupSettingsState.enableGroupNewsfeed,
        };

        const products = ticketFormState?.products?.map(product => {
            const { _id, name, description, currency, type, fulfillment, coupons } = product;

            const _coupons =
                coupons &&
                coupons?.map(coupon => {
                    const { name, code, type } = coupon;
                    return {
                        name,
                        code,
                        type,
                        percentage: coupon?.percentage,
                    };
                });

            if (_id) {
                if (product?.type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP) {
                    return {
                        productId: _id,
                        name,
                        description,
                        price: 0,
                        currency: ticketCurrency,
                        type,
                        fulfillment: {
                            groupId: ticketFormState?.groupId,
                            role: fulfillment?.role,
                        },
                        coupons: _coupons,
                    };
                }

                return {
                    productId: _id,
                    name,
                    description,
                    price: Math.round(Number(product?.price || 1) * 100),
                    currency: ticketCurrency,
                    type,
                    fulfillment: {
                        groupId: ticketFormState?.groupId,
                        role: fulfillment?.role,
                    },
                    coupons: _coupons,
                };
            }

            return {
                name,
                description,
                price: Math.round(Number(product?.price || 1) * 100),
                currency: ticketCurrency,
                type,
                fulfillment: {
                    groupId: ticketFormState?.groupId,
                    role: fulfillment?.role,
                },
                coupons: _coupons,
            };
        });

        const payload9 = {
            _id: group!._id,
            name: overviewFormState.title,
            settings,
            subtype: GroupSubtype.Event,
            picture: overviewFormState.thumbnail,
            coverPhoto: overviewFormState.coverImage,
            timeAndPlaceSettings,
            eventOverviewDetails,
            products,
            isTemplate: groupSettingsState.isTemplate,
            paymentSettings: {
                isGroupMembershipPaid: state?.ticketFormState?.isGroupMembershipPaid,
            },
        };

        try {
            newModal(<ModalLoading message="Updating Event Group..." />, {
                widen: true,
                addShadow: true,
                disableCloseHandlers: true,
            });

            setLoading(true);

            // Update Event Group
            const res = await updateGroup({
                variables: { input: payload9, idempotencyKey: idempotencyKey.current },
                update: () => {},
            });

            setGroupRes(res?.data?.updateGroup);
        } catch (e) {
            logger.error(e);
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    return (
        <ErrorBoundary>
            <EventGroupForm
                disabled={loading}
                state={state}
                setState={setState}
                initialStep={initialStep}
                onSubmit={onSubmit}
                groupData={groupRes}
                group={group}
                title="Edit Event Group"
                showSave
                mode={EventGroupFormModeEnum.update}
                ticketCurrency={ticketCurrency}
                setTicketCurrency={setTicketCurrency}
                handleUpdateTicketCurrency={handleUpdateTicketCurrency}
            />
        </ErrorBoundary>
    );
};

type UpdateEventGroupFormQueryProps = {
    card: ContentCard;
};

const UpdateEventGroupFormQuery: React.FC<UpdateEventGroupFormQueryProps> = ({ card }) => {
    const groupSource = card?.aliases?.[0]?.url ?? card?.reference?.groupSource?._id;

    const { loading: groupLoading, data: groupData } = useGetEventGroupQuery({
        variables: { _id: groupSource! },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !groupSource,
    });

    if (groupLoading && !groupData) {
        return (
            <div className="card-loading group-item">
                <strong>Loading...</strong>
            </div>
        );
    }

    const group = groupData?.groupById;

    return (
        <ErrorBoundary>
            <UpdateEventGroupForm group={group} />
        </ErrorBoundary>
    );
};

export default UpdateEventGroupFormQuery;
