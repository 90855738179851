import { createRef, useCallback, useState } from 'react';
import _ from 'lodash';

/**
 * React hook for hiding an element when scrolling down and showing that element when scrolling up
 *
 * @param {Object} options
 * @param {boolean} options.startHidden Should element default to hidden. Defaults to false.
 * @param {number} options.throttleTime
 * Amount of time scroll handler should be throttled for. Defaults to 100
 *
 * @param {boolean} options.ignoreElementsBeingAdded
 * Should appended elements be ignored when calculating scroll direction. Defaults to true
 *
 * @return {{
 *     onScroll: React.UIEventHandler<HTMLElement>,
 *     hidden: boolean,
 *     hide: () => void,
 *     show: () => void
 * }}
 */
const useHideOnScrollDown = ({
    startHidden = false,
    throttleTime = 100,
    ignoreElementsBeingAdded = true,
    alwaysShowAtTop = true,
} = {}) => {
    const [shouldCheck, setShouldCheck] = useState(false);
    const [hidden, setHidden] = useState(startHidden);
    const topRef = createRef(0);
    const heightRef = createRef(0);

    /**
     * Checks whether the element should be shown or hidden
     *
     * This function is throttled, and so must be wrapped in useCallback. Additionally, attempting
     * to use the currentScrollPosition and currentScrollHeight refs directly will for some reason
     * cause scrolling up to repeatedly toggle hiding/showing rather than just showing, so we have
     * to pass in oldTop and oldHeight to fix that.
     *
     * @param {number} oldTop old scrollTop
     * @param {number} oldHeight old scrollHeight
     * @param {number} newTop new scrollTop
     * @param {number} newHeight new scrollHeight
     */
    const checkShowOrHide = _.throttle(
        useCallback(
            (oldTop, oldHeight, newTop, newHeight) => {
                if (oldTop && oldHeight) {
                    if (ignoreElementsBeingAdded) {
                        setHidden(
                            ignoreElementsBeingAdded && oldHeight !== newHeight
                                ? newHeight - newTop > oldHeight - oldTop
                                : newTop > oldTop
                        );
                    } else setHidden(newTop > oldTop);
                }
            },
            [topRef, heightRef]
        ),
        throttleTime,
        { trailing: false }
    );

    /** @type React.UIEventHandler<HTMLElement> */
    const onScroll = e => {
        const { scrollHeight, scrollTop } = e.currentTarget;
        if (alwaysShowAtTop && scrollTop < 150) show();

        if (shouldCheck && scrollTop > 150)
            checkShowOrHide(topRef.current, heightRef.current, scrollTop, scrollHeight);

        if (topRef && heightRef) {
            topRef.current = scrollTop;
            heightRef.current = scrollHeight;
        }
    };

    const hide = () => setHidden(true);
    const show = () => setHidden(false);
    const startChecking = () => setShouldCheck(true);
    const stopChecking = () => setShouldCheck(false);

    return { onScroll, hidden, hide, show, startChecking, stopChecking };
};

export default useHideOnScrollDown;
