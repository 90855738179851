import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { CARD_CHAPTER } from '@core/utilities/constants/card_types.js';
import { ATTACH_POSTBOX } from '@core/utilities/constants/attachment_types';

import { LIST_CARDS } from '@shared/welibrary-graphql/content_card/queries';

import AlertToast from '@components/toast/AlertToast';

import { defaultAlertToastOptions } from '@core/types/Toast';

import useDebouncedState from '@web/ui/components/generic/hooks/useDebouncedState';
import { useToastActionsContext } from '@components/toast/NewToastContext';

/**
 * @typedef {{
 *     _id: string,
 *     prompt: string,
 *     newPrompt: string,
 *     media: { thumb: string },
 *     reference: { coverImage: string, bookSource: { title: string } },
 *     [key: string]: any
 * }} Prompt
 */

/**
 * React hook for handling the logic associated with creating Postbox cards
 *
 * @param {{
 *     addAttachedContent: (content: any, type: string) => void) => void,
 *     submitPost: () => void,
 *     defaultPrompts: Prompt[],
 *     defaultPromptTitle: string
 * }}
 */
const usePostboxForm = ({
    prioritizeGroupId,
    groupId,
    addAttachedContent,
    handleSubmit,
    defaultPrompts = [],
    defaultPromptTitle = '',
    defaultTypes = [CARD_CHAPTER],
    childrenTypes,
}) => {
    const { t } = useTranslation();
    const { newToast } = useToastActionsContext();

    const [title, setTitle] = useState(defaultPromptTitle);

    /** @type [Prompt[], React.Dispatch<Prompt[]>] */
    const [prompts, setPrompts] = useState(defaultPrompts);
    const [query, actualQuery, setQuery] = useDebouncedState('');
    const [searching, setSearching] = useState(false);

    const { data, loading, error } = useQuery(LIST_CARDS, {
        fetchPolicy: 'no-cache',
        variables: {
            prioritizeGroupId,
            groupId,
            searchQuery: actualQuery,
            limit: 30,
            childrenLimit: 10,
            showEmpty: false,
            types: defaultTypes,
            childrenTypes,
        },
        skip: !query,
    });

    /**
     * Adds a prompt to the prompt array
     *
     * @param {Prompt} prompt prompt to add
     */
    const addPrompt = prompt => setPrompts([...prompts, prompt]);

    /**
     * Adds a prompt to the prompt array and closes the search box
     *
     * @param {Prompt} prompt prompt to add
     */
    const addPromptAndCloseSearch = prompt => {
        addPrompt(prompt);
        setQuery('');
        setSearching(false);
    };

    /**
     * Updates a prompt's prompt
     *
     * @param {string} id id of prompt to update
     * @param {string} value new prompt
     */
    const updatePrompt = (id, value) => {
        const promptIndex = prompts.findIndex(prompt => prompt._id === id);

        if (promptIndex < 0) return;

        setPrompts(oldPrompts => {
            const newPrompts = [...oldPrompts];

            newPrompts[promptIndex] = { ...newPrompts[promptIndex], newPrompt: value };

            return newPrompts;
        });
    };

    /**
     * Swaps two prompts in the prompts array
     *
     * @param {number} firstIndex index of the first prompt
     * @param {number} secondIndex index of the second prompt
     */
    const movePrompt = (firstIndex, secondIndex) => {
        setPrompts(oldPrompts => {
            const newPrompts = [...oldPrompts];

            newPrompts.splice(secondIndex, 0, newPrompts.splice(firstIndex, 1)[0]);

            return newPrompts;
        });
    };

    /**
     * Deletes a prompt from the prompt array
     *
     * @param {number} id id of the prompt to delete
     */
    const deletePrompt = id =>
        setPrompts(oldPrompts => oldPrompts.filter(oldPrompt => oldPrompt._id !== id));

    /** @type React.FormEventHandler<HTMLFormElement> */
    const submit = e => {
        e.preventDefault();

        if (prompts.length <= 0) {
            newToast(
                <AlertToast
                    boldText={t(`common:imports.wlWeb.ui.components.toast.error`, 'Error!')}
                    text={t(
                        `common:imports.wlWeb.ui.components.postbox.alias_required`,
                        'Must have at least one alias selected to create a postbox.'
                    )}
                    showWarningIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
            return;
        }

        handleSubmit();
    };

    /** @type Prompt[] */
    const results = (data?.listCards ?? []).filter(
        ({ _id }) => !prompts.some(prompt => prompt._id === _id)
    );

    useEffect(() => {
        setSearching(!!query);
    }, [query]);

    useEffect(() => {
        if (prompts.length > 0) {
            const aliases = prompts.map(({ newPrompt: title, url }) => ({
                title,
                url,
                type: CARD_CHAPTER,
            }));

            addAttachedContent(
                { meta: [], title: title || prompts[0].newPrompt || prompts[0].prompt, aliases },
                ATTACH_POSTBOX
            );
        }
    }, [title, prompts]);

    return {
        title: { title, setTitle },
        prompts,
        search: { query, actualQuery, setQuery, searching, loading, error },
        methods: { addPromptAndCloseSearch, updatePrompt, movePrompt, deletePrompt, submit },
        results,
    };
};

export default usePostboxForm;
