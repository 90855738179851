import React from 'react';
import {useTranslation} from 'react-i18next';

import IntroContent from '@web/ui/components/generic/IntroContent';
import GroupMemberSearchResult from '@web/ui/components/group/groupdashboard/GroupMembers/GroupMemberSearchResult';

import {Group, Maybe, MemberInvite} from '@shared/welibrary-graphql/types';

const MemberInvitesList: React.FC<{
    group: Group;
    memberInvites?: Maybe<Array<Maybe<Partial<MemberInvite>>>>;
    onNetworkSearchAction: () => void;
    canUserInviteOthers: Maybe<string | boolean | undefined>;
    handleLinkSharing: () => void;
}> = ({ memberInvites, group, onNetworkSearchAction, canUserInviteOthers, handleLinkSharing }) => {
    const { t } = useTranslation();
    const localNamespace =
        group?.subtype === 'event'
            ? 'imports.wlWeb.ui.components.group.eventDashboard'
            : 'imports.wlWeb.ui.components.group.groupDashboard';

    const inviteText = t(`common:${localNamespace}.invite_members`);

    return (
        <ul>
            {memberInvites?.length > 0 ? (
                memberInvites?.map((member, index) => {
                    const { user } = member ?? {};

                    if (!user) return <></>;

                    return (
                        <GroupMemberSearchResult
                            key={`profile-cards-${index}`}
                            group={group}
                            member={user}
                            onUpdateUserState={() => onNetworkSearchAction()}
                        />
                    );
                })
            ) : (
                <IntroContent
                    declaration={t('global.introContentErr.no_invites.declaration')}
                    instruction={t('global.introContentErr.no_members.instruction')}
                    type="nocontent"
                >
                    {canUserInviteOthers && (
                        <button
                            onClick={handleLinkSharing}
                            type="button"
                            className="group-members-invite-link-btn"
                        >
                            {inviteText}
                        </button>
                    )}
                </IntroContent>
            )}
        </ul>
    );
};

export default MemberInvitesList;
