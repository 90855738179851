/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import signUpStore from '@stores/SignUp';

import useModal from '@components/modals/hooks/useModal';
import { useCurrentUser } from '@stores/User';
import { useSubscriptionConfirmation as useConfirmation } from '@components/generic/hooks/useSubscriptionConfirmation';

import TicketSharingForm from '@components/card/Shopping-Cart/TicketSharing/TicketSharingForm';
import SignUpPageButton from '@pages/new-signup/SignUpPageButton';

import { User, ProductInstance, Product, CustomerOrder } from '@shared/welibrary-graphql/types';
import { getWhitelabelPublicSettings, getRootUrl } from '@core/utilities/whitelabel_helpers';
import { useGenerateTicketClaimLinkMutation } from '@shared/welibrary-graphql/user/mutations.hook';

type TicketSharingState = {
    ticket: Product;
    ticketId: string;
    transferToken: string;
    transferRecipients: { user?: User; email?: string }[];
    email: string | null;
    user: User | null;
    purchaser: User | null;
    owner: User | null;
    order: CustomerOrder;
};

const TicketSharingContainer: React.FC<{ tickets: ProductInstance[] }> = ({ tickets }) => {
    const { currentUser } = useCurrentUser();
    const { closeAllModals } = useModal();
    const confirm = useConfirmation();
    const history = useHistory();

    const { metaInfo } = getWhitelabelPublicSettings();

    const [generateTicketClaimLink] = useGenerateTicketClaimLinkMutation();

    const [errors, setErrors] = useState<any>({});

    const [ticketSharing, setTicketSharing] = useState<TicketSharingState[]>( // mapped productInstances
        tickets?.map(ticket => {
            return {
                ticket: ticket?.product,
                ticketId: ticket?._id,
                transferToken: ticket?.transferToken,
                transferRecipients: ticket?.transferRecipients,
                purchaser: ticket?.purchaser,
                owner: ticket?.owner,
                email: '',
                user: null,
                order: ticket?.order,
            };
        }) ?? []
    );

    const handleAddAttendee = (ticketId: string, user?: User | null, email?: string) => {
        const _ticketSharing = ticketSharing.map(t => {
            if (t.ticketId === ticketId && user) {
                return {
                    ...t,
                    user,
                };
            }

            if (t.ticketId === ticketId && email) {
                return {
                    ...t,
                    email,
                };
            }
            return t;
        });
        setTicketSharing([..._ticketSharing]);
    };

    const handleRemoveAttendee = (ticketId: string) => {
        const _ticketSharing = ticketSharing.map(t => {
            if (t.ticketId === ticketId) {
                return {
                    ...t,
                    user: null,
                    email: '',
                };
            }

            return t;
        });

        setTicketSharing([..._ticketSharing]);
    };

    const handleUpdateTicketProductInstance = (
        ticketId: string,
        updatePayload?: TicketSharingState
    ) => {
        const _ticketSharing = ticketSharing.map(t => {
            if (t.ticketId === ticketId) {
                return {
                    ...t,
                    ...updatePayload,
                };
            }

            return t;
        });

        setTicketSharing([..._ticketSharing]);
    };

    const handleSendClaimLinks = async () => {
        const confirmed = await confirm({
            title: `Confirm Ticket Transfer?`,
            text: 'Please be aware that if you transfer all of your tickets for this event, you will be automatically removed as an attendee. Consider this and manage any ticket transfers carefully.',
            confirmationButtonText: 'Confirm',
        });

        if (confirmed) {
            const invitees = ticketSharing
                .map(ticket => {
                    if (ticket.user) {
                        return {
                            transferToken: ticket?.transferToken,
                            productInstanceId: ticket?.ticketId,
                            userId: ticket?.user?._id,
                        };
                    }
                    if (ticket.email) {
                        return {
                            transferToken: ticket?.transferToken,
                            productInstanceId: ticket?.ticketId,
                            email: ticket?.email,
                        };
                    }
                })
                .filter(notUndefined => notUndefined !== undefined);

            generateTicketClaimLink({
                variables: {
                    input: invitees,
                    host: getRootUrl(),
                },
            }).then(({ data }) => {
                const failedTransferLinks = data?.generateTicketClaimLink
                    ?.map(link => {
                        if (link?.status !== 'fulfilled') {
                            return {
                                [link?.value]: link?.reason,
                            };
                        }

                        return;
                    })
                    .filter(element => {
                        return element !== undefined;
                    });

                const errorMap = {};
                data?.generateTicketClaimLink?.forEach(link => {
                    if (link?.status === 'rejected') {
                        errorMap[link?.value] = link;
                    }
                });

                if (failedTransferLinks?.length === 0) {
                    closeAllModals();
                } else {
                    setErrors(errorMap);
                }
            });
        }
    };

    return (
        <section className="ticket-sharing">
            <header className="ticket-sharing-header">
                <h1>Transfer Tickets</h1>
                <p>
                    This event uses the {metaInfo?.title} app for engagement during the event. All
                    attendees need a ticket to gain access. Assign ticket holders for each ticket by
                    username or email address.
                </p>
            </header>

            {currentUser && (
                <ul className="ticket-sharing-ticket-list">
                    {ticketSharing.map((t, index) => {
                        return (
                            <TicketSharingForm
                                key={t?.ticketId}
                                index={index}
                                ticketSharingState={t}
                                handleAddAttendee={handleAddAttendee}
                                handleRemoveAttendee={handleRemoveAttendee}
                                handleUpdateTicketProductInstance={
                                    handleUpdateTicketProductInstance
                                }
                                ticketsAvailable={tickets.length}
                                transferError={errors[t?.ticketId]}
                            />
                        );
                    })}
                </ul>
            )}
            {!currentUser && (
                <div className="text-grayscale-body text-[22px] grow border-grayscale-line border-y-[1px] border-solid bg-grayscale-background w-full flex items-center justify-center px-[30px]">
                    <span className="text-center leading-[150%] tracking-[0.25px]">
                        To transfer tickets, you must first create an account and login.
                    </span>
                </div>
            )}

            <footer className="center-modal-header-footer ticket-sharing-footer">
                {currentUser && (
                    <>
                        <div>
                            <p>
                                Once claimed, your tickets cannot be
                                <br />
                                reassigned to a different account
                            </p>
                        </div>
                        <div className="ticket-sharing-footer-btn-wrap">
                            <button
                                className="ticket-sharing-footer-btn-skip"
                                type="button"
                                onClick={() => closeAllModals()}
                            >
                                Skip for Now
                            </button>
                            <button
                                className="ticket-sharing-footer-btn-share"
                                type="button"
                                onClick={() => handleSendClaimLinks()}
                            >
                                Save and Share
                            </button>
                        </div>
                    </>
                )}
                {!currentUser && (
                    <SignUpPageButton
                        type="button"
                        onClick={() => {
                            closeAllModals();
                            // signUpStore.email should already be set (in CheckoutSuccess:handleTicketSharing)
                            signUpStore.set.isGuestCheckout(false);
                            history.push('/signup');
                        }}
                    >
                        Create Account
                    </SignUpPageButton>
                )}
            </footer>
        </section>
    );
};

export default TicketSharingContainer;
