import { useTranslation } from 'react-i18next';

import { getResultTypeFromContentCard } from '@web/utilities/helpers/search/searchHelpers';
import {
    generateDisplayReferences,
    generateReferenceArray,
} from '@web/utilities/helpers/reference/reference.helpers';
import {
    getRandomDefaultCoverImage,
    getRandomDefaultUserImage,
} from '@core/utilities/constants/defaults';

import { Maybify } from '@core/types/Utilities';
import { Entity } from '@core/types/Search';

import getLogger from '@core/logger';

const logger = getLogger(module);

type UseSearchFormResultReturnValues =
    | {
          resultType: string;
          title?: string | null;
          subtitle?: string | null;
          img?: string | null;
          reference?: string;
          author?: string;
      }
    | false;

const useSearchFormResult = (entity: Maybify<Entity>): UseSearchFormResultReturnValues => {
    const { t } = useTranslation();

    if (!entity) return false;

    if (entity.__typename === 'User') {
        if (!entity.profile) {
            logger.warn('Bad User Data:', entity);
            return false;
        }

        return {
            resultType: t('common:global.nouns.profile'),
            title: entity.profile.full_name,
            subtitle: entity.profile.short_bio,
            img: entity.profile.picture || getRandomDefaultUserImage(),
        };
    }

    if (entity.__typename === 'ContentCard') {
        if (entity.type === 'chapter' || entity.type === 'channel') {
            const picture =
                entity.media?.icon ||
                entity.reference?.bookSource?.media?.thumb ||
                getRandomDefaultCoverImage(
                    entity.reference?.bookSource?.title || entity.title || ''
                );

            return {
                resultType: t('common:global.nouns.channel'),
                title: entity.title,
                subtitle: entity.description,
                img: picture,
                reference:
                    entity.reference?.groupSource?.profile?.full_name ??
                    entity.reference?.bookSource?.title ??
                    undefined,
            };
        }

        const picture =
            entity.media?.icon ||
            entity.media?.thumb ||
            entity.reference?.bookSource?.media?.thumb ||
            getRandomDefaultCoverImage(entity.reference?.bookSource?.title || entity.title || '');

        const referenceArray =
            generateReferenceArray(t, entity, generateDisplayReferences(entity, true)) || undefined;

        return {
            resultType: getResultTypeFromContentCard(entity, t),
            title: entity.title || entity.body,
            subtitle: entity.description,
            img: picture,
            reference:
                referenceArray &&
                referenceArray
                    .map(({ value, preposition }) => `${preposition ?? ''} ${value}`)
                    .join(''),
            author: entity.author?.profile?.full_name ?? '',
        };
    }

    if (entity.__typename === 'Group') {
        if (!entity.profile || !entity.profile.full_name) {
            logger.warn('Bad Group Data:', entity);
            return false;
        }

        return {
            resultType: t('common:global.nouns.group'),
            title: entity.profile.full_name,
            subtitle: entity.profile.short_bio,
            img: entity.profile.picture || getRandomDefaultCoverImage(entity.profile.full_name),
        };
    }

    return false;
};

export default useSearchFormResult;
