import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { awsMetricPublisher } from '@welibraryos/metrics';

import useSideMenuFooter from '@web/ui/components/navigation/SideMenu/Main/hooks/useSideMenuFooter';
import { useUpdateUserMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';

import MobileMenuList from '@web/ui/components/modals/MobileMenuList';
import ExternalLink from '@web/ui/components/generic/ExternalLink';

import { titleCase } from '@helpers/string.helpers';
import { allLanguages, isSupportedLanguage } from '@core/utilities/constants/languages';
import { MenuListItem } from '@core/types/Menus';
import signUpStore from '@stores/SignUp';
import NewSignupUserGuideButton from './NewSignupUserGuideButton';

const localNamespace = 'imports.wlWeb.ui.components.navigation.slideMenu';

const NewSignupLanguagePicker: React.FC<{
    className: string;
    showLanguagePicker?: boolean;
    showSupportButton?: boolean;
    showLinks?: boolean;
}> = ({ className, showSupportButton = false, showLanguagePicker = true, showLinks = false }) => {
    const { t, i18n } = useTranslation();

    const { currentUser, refetchCurrentUser } = useCurrentUser();

    const [updateUser] = useUpdateUserMutation();
    const [languageSelected, setLanguageSelected] = useState(
        currentUser?.profile?.language ?? signUpStore?.get?.language()
    );

    const { newModal, closeModal } = useModal();

    const {
        currentYear,
        copyrightName,
        footerText,
        privacyPolicyLink,
        termsOfServiceLink,
        communityGuidelinesLink,
        versionNumber,
    } = useSideMenuFooter();

    const currentLanguage = t(`common:languages.names.${languageSelected || '-none-'}`);

    const languageList: MenuListItem[] = Object.keys(allLanguages).map(key => ({
        id: key,
        title: t(`common:languages.names.${key}`),
        onClick: () => {
            if (currentUser && isSupportedLanguage(key)) {
                i18n.changeLanguage(key);
                updateUser({ variables: { _id: currentUser._id, language: key } }).then(() => {
                    refetchCurrentUser();
                    closeModal();
                });
            } else {
                i18n.changeLanguage(key);
                setLanguageSelected(key);
                signUpStore?.set?.language(key);
                closeModal();
            }
        },
        selected: languageSelected === key,
    }));

    const onClickTerms = () => {
        awsMetricPublisher.publishCount('side-menu', 1, [{ Name: 'menu', Value: 'terms' }]);
    };

    const onClickPolicy = () => {
        awsMetricPublisher.publishCount('side-menu', 1, [
            { Name: 'menu', Value: 'privacy-policy' },
        ]);
    };

    const links = (
        <span>
            {privacyPolicyLink && (
                <ExternalLink
                    to={privacyPolicyLink}
                    onClick={onClickPolicy}
                    whiteColor
                    whiteColorOverride="#ffffff"
                    className="text-white"
                    style={{ color: '#ffffff' }}
                >
                    {t(`common:${localNamespace}.privacy_policy`)}
                </ExternalLink>
            )}
            {termsOfServiceLink && (
                <>
                    {' | '}
                    <ExternalLink
                        to={termsOfServiceLink}
                        onClick={onClickTerms}
                        whiteColor
                        whiteColorOverride="#ffffff"
                        className="text-white"
                        style={{ color: '#ffffff' }}
                    >
                        {t(`common:${localNamespace}.terms`)}
                    </ExternalLink>
                </>
            )}
            {communityGuidelinesLink && (
                <>
                    {' | '}
                    <ExternalLink
                        to={communityGuidelinesLink}
                        whiteColor
                        whiteColorOverride="#ffffff"
                        className="text-white"
                        style={{ color: '#ffffff' }}
                    >
                        {titleCase(t('common:community_guidelines', 'community guidelines'))}
                    </ExternalLink>
                </>
            )}
            {versionNumber && ` | ${versionNumber}`}
        </span>
    );

    return (
        <footer className={className}>
            {showLanguagePicker && (
                <button
                    type="button"
                    className="side-menu-main-language-picker mb-2 !bg-gray-900 opacity-70"
                    onClick={() => {
                        awsMetricPublisher.publishCount('side-menu-open-select-language-modal', 1);
                        newModal(
                            <MobileMenuList
                                menuList={languageList}
                                metricName="side-menu-selected-language"
                            />,
                            { className: 'no-min-height' }
                        );
                    }}
                >
                    <span className="selected-language !text-white">{currentLanguage}</span>
                    <span role="presentation" className="caret !text-white">
                        &#x25BE;
                    </span>
                </button>
            )}

            {showSupportButton && <NewSignupUserGuideButton />}

            {showLinks && (
                <section className="side-menu-main-footer-info text-white">
                    <span>
                        ©{currentYear} {copyrightName}
                    </span>
                    {footerText && <span> {footerText}</span>}
                    <br />
                    {links}
                </section>
            )}
        </footer>
    );
};

export default NewSignupLanguagePicker;
