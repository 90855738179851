import React from 'react';
import produce from 'immer';

import TextInput from '@dsc/forms/textInputs/TextInput';
import Trash from '@dsc/svgs/Trash';
import Plus from '@dsc/svgs/FullSizePlus';

type MultiTextInputProps = {
    values: string[];
    onChange: (value: string[]) => void;
    label?: string;
    inputLabels?: string;
    buttonText?: string;
    className?: string;
};

const MultiTextInput: React.FC<MultiTextInputProps> = ({
    values,
    onChange,
    label = '',
    inputLabels = '',
    buttonText = '',
    className = '',
}) => {
    const inputs = values.map((value, index) => (
        <li key={index}>
            <TextInput
                value={value}
                label={inputLabels}
                onChange={material =>
                    onChange(
                        produce(values, draft => {
                            draft[index] = material;
                        })
                    )
                }
            />

            <button
                type="button"
                onClick={() =>
                    onChange(
                        produce(values, draft => {
                            draft.splice(index, 1);
                            if (draft.length === 0) draft.push('');
                        })
                    )
                }
            >
                <Trash />
            </button>
        </li>
    ));

    return (
        <section className={`dsc-forms-customInputs-multiTextInput ${className}`}>
            <p>{label}</p>
            <ul>{inputs}</ul>
            <button type="button" onClick={() => onChange([...values, ''])}>
                <Plus />
                {buttonText}
            </button>
        </section>
    );
};

export default MultiTextInput;
