import React from 'react';

const Newsfeed: React.FC<{ className?: string }> = ({ className = '' }) => (
    <svg stroke="currentcolor" fill="currentcolor" className={className} viewBox="0 0 500 500">
        <path d="M405,465.22H104a84,84,0,0,1-83.91-83.91V34.78H479.91V390.27A75,75,0,0,1,405,465.22ZM62.74,77.43V381.31A41.31,41.31,0,0,0,104,422.57H405a32.34,32.34,0,0,0,32.3-32.3V77.43Z" />
        <path d="M117.61,323.86v50.07A12.14,12.14,0,0,0,130,385.83H370a12.14,12.14,0,0,0,12.37-11.9V323.86A12.14,12.14,0,0,0,370,312H130A12.14,12.14,0,0,0,117.61,323.86Z" />
        <path d="M117.61,126.07v50.07A12.14,12.14,0,0,0,130,188H370a12.14,12.14,0,0,0,12.37-11.9V126.07A12.14,12.14,0,0,0,370,114.17H130A12.14,12.14,0,0,0,117.61,126.07Z" />
        <path d="M216.26,225V275a12,12,0,0,0,12.08,11.9h142A12,12,0,0,0,382.39,275V225a12,12,0,0,0-12.08-11.9h-142A12,12,0,0,0,216.26,225Z" />
        <path d="M129.69,286.93h49.06A12,12,0,0,0,190.83,275V225a12,12,0,0,0-12.08-11.9H129.69A12,12,0,0,0,117.61,225V275A12,12,0,0,0,129.69,286.93Z" />
    </svg>
);

export default Newsfeed;
