import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';
import useNavigation from '@components/navigation/useNavigation';
import useGroupActions from '@components/group/hooks/useGroupActions.js';
import useGoBack from '@components/generic/hooks/useGoBack';
import useOnScreen from '@components/generic/hooks/useOnScreen';

import ChannelsCard from '@components/group/groupdashboard/channelsCard/ChannelsCard';
import GroupActionButton from '@components/group/groupdashboard/GroupActionButton';
import GroupDashboardRSVPButton from '@components/group/groupdashboard/GroupDashboardRSVPButton';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImageLink,
} from '@core/utilities/constants/defaults';

import { Group } from '@shared/welibrary-graphql/types';

const ROOT_HISTORY_PATHS = [
    { path: '/dashboard', exact: false },
    { path: '/g', exact: false },
    { path: '/notifications', exact: false },
    { path: '/login', exact: false },
    { path: '/signup', exact: false },
    { path: '/join', exact: false },
    { path: '/signup-group', exact: false },
    { path: '/add-group', exact: false },
    { path: '/bigscreen', exact: false },
    { path: '/fs', exact: false },
    { path: '/admin', exact: false },
    { path: '/u', exact: true },
    { path: '/u/', exact: true },
    { path: '/home/', exact: false },
    { path: '/connections', exact: false },
    { path: '/connections', exact: false },
    { path: '/discover', exact: false },
];

const GroupDashboardContextHeader: React.FC<{
    group: Group;
    fullHeaderRef: any;
    newsfeedHeaderRef: any;
    isEventGroup: boolean;
    canUserViewGroup: boolean;
    scrollToTop: () => void;
}> = ({ group, fullHeaderRef, newsfeedHeaderRef, isEventGroup, canUserViewGroup, scrollToTop }) => {
    const { t } = useTranslation();

    const [groupThumbSrc, groupThumbSrcset] = getBackgroundImageSetForUrl(
        group?.profile?.picture || getRandomDefaultCoverImage(group?.profile?.full_name || '')
    );

    const { currentUser } = useCurrentUser();
    const { setChannelOpenState } = useNavigation();
    const { primaryActionAvailable, takeGroupActionForUser } = useGroupActions({ group });
    const { newModal } = useModal();

    // handle Channels button click
    const handleChannelsClick = () => {
        setChannelOpenState(true);
        newModal(
            <ChannelsCard groupId={group?._id} dashboardGroup={group} currentUser={currentUser} />,
            {
                className: 'no-top-padding white-notch',
                widen: true,
                addShadow: true,
            }
        );
    };

    // Determine whether to show the context header
    const [showContextHeader, setShowCondensedHeader] = useState(false);

    const { onScreen: fullHeaderIsOnScreen } = useOnScreen({
        ref: fullHeaderRef,
        offset: 0,
        onChange: e => {
            const fullHeaderWillBeOnScreen = e.isIntersecting; // fullHeaderIsOnScreen is always false, so we'll use this instead

            if (fullHeaderWillBeOnScreen) {
                // let the header animation play (0.2 sec) and then hide it
                setTimeout(() => {
                    setShowCondensedHeader(false);
                }, 200);
            } else if (!fullHeaderWillBeOnScreen) {
                setShowCondensedHeader(true);
            }
        },
    });

    // Handle the current section text (e.g Dashboard + Newsfeed)
    const dashboard = t('common:global.dashboard', 'Dashboard');
    const currentSectionTitleId = 'current-section-text';
    const [currentSectionTitle, setCurrentSectionTitle] = useState(dashboard);
    const [currentSectionTitleAnimation, setCurrentSectionTitleAnimation] = useState('');

    useOnScreen({
        ref: newsfeedHeaderRef,
        offset: 0,
        onChange: e => {
            const topOfHeader = e.boundingClientRect.top;
            const bottomOfHeader = topOfHeader + e.boundingClientRect.height;
            const topOfViewport = e.rootBounds?.top ?? 0;

            const currentTitle = document.getElementById(currentSectionTitleId)?.textContent;

            if (bottomOfHeader < topOfViewport) {
                // Newsfeed header is off the screen to the top -> switch the current section to newsfeed

                // Prevent animations when there's not actually a change
                const newTitle = newsfeedHeaderRef.current.innerText;
                if (newTitle === currentTitle) return;

                // scroll "DASHBOARD" off the top of the screen (100ms)
                setCurrentSectionTitleAnimation('scroll-exit-top');

                // after "DASHBOARD" scrolls off the screen
                setTimeout(() => {
                    setCurrentSectionTitle(newTitle); // change text to "NEWSFEED"
                    setCurrentSectionTitleAnimation('scroll-enter-from-bottom'); // have that text scroll in from the bottom
                }, 100);
            } else if (bottomOfHeader > topOfViewport) {
                // Newsfeed header has entered the screen from the top -> switch current section to "DASHBOARD"

                // Prevent animations when there's not actually a change
                const newTitle = dashboard;
                if (newTitle === currentTitle || currentTitle === undefined) return;

                // scroll "NEWSFEED" off the bottom of the screen (100ms)
                setCurrentSectionTitleAnimation('scroll-exit-bottom');

                setTimeout(() => {
                    setCurrentSectionTitle(newTitle); // change the title to "DASHBOARD"
                    setCurrentSectionTitleAnimation('scroll-enter-from-top'); // have that text scroll in from the top
                }, 100);
            }
        },
    });

    const { goBack, previousRouteExists } = useGoBack();

    const showBackButton =
        isEventGroup &&
        ROOT_HISTORY_PATHS.some(rootPath => {
            return rootPath.exact
                ? window.location.pathname === rootPath.path
                : window.location.pathname.includes(rootPath.path);
        }) &&
        previousRouteExists;

    let actionButton;

    const totalChannels = group?.channelsBook?.children?.length || 0;
    const totalChannelsText =
        totalChannels === 1 ? t(`common:global.nouns.channel`) : t(`common:global.nouns.channels`);
    const showChannelsBtn = group?.channelsBook || totalChannels > 0;

    if (currentUser && !canUserViewGroup) {
        actionButton = (
            <GroupActionButton
                takeGroupActionForUser={takeGroupActionForUser}
                primaryActionAvailable={primaryActionAvailable}
                customClassName=""
            />
        );
    } else if (currentUser) {
        actionButton = showChannelsBtn ? (
            <button
                onClick={handleChannelsClick}
                type="button"
                className="chapter-top-nav-channel-btn"
            >
                {totalChannelsText}
            </button>
        ) : null;
    }

    if (isEventGroup) {
        actionButton = (
            <GroupDashboardRSVPButton
                currentUser={currentUser}
                group={group}
                customButtonClass="rsvp-context-header"
            />
        );
    }

    if (!showContextHeader) return <></>;

    return (
        <section
            className={`group-dashboard-condensed-header ${fullHeaderIsOnScreen ? 'header-scroll-off-screen' : 'header-scroll-in-from-top'
                }`}
        >
            {showBackButton && (
                <div className="left-container">
                    <button
                        onClick={goBack}
                        type="button"
                        className="back-arrow-btn animate-grow"
                        aria-label="Back"
                    />
                </div>
            )}
            <div className="right-container">
                <div className="group-info-title" onClick={scrollToTop}>
                    <div className="group-info-thumb">
                        <StyledBackgroundImageLink
                            to="#"
                            className="thumbnail larger-thumbnail w-inline-block group-info-thumb-thumbnail"
                            src={groupThumbSrc}
                            srcSet={groupThumbSrcset}
                        />
                    </div>
                    <div className="group-info-text">
                        <div
                            id={currentSectionTitleId}
                            className={`strong text-grey all-caps ${currentSectionTitleAnimation}`}
                        >
                            {currentSectionTitle}
                        </div>
                        <div className="group-title">{group?.profile?.full_name}</div>
                    </div>
                </div>
                {actionButton}
            </div>
        </section>
    );
};

export default GroupDashboardContextHeader;
