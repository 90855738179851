import {
    useQuestByIdQuery,
    QuestByIdDocument,
    QuestForLoggedInUserByRainbowRoadUrlDocument,
} from '@shared/welibrary-graphql/quest/queries.hook';
import { useCompleteQuestCurrentNodeMutation } from '@shared/welibrary-graphql/quest/mutations.hook';

import { getNodeIndex } from '@helpers/rainbowRoad/quest.helpers';

const useCompleteQuestMapNode = (questMapId: string) => {
    const { data } = useQuestByIdQuery({ variables: { _id: questMapId }, skip: !questMapId });

    const [completeQuestCurrentNode] = useCompleteQuestCurrentNodeMutation({
        refetchQueries: [QuestByIdDocument, QuestForLoggedInUserByRainbowRoadUrlDocument],
    });

    const completeNode = (url?: string) => {
        if (data?.getQuestById?._id) {
            const node =
                (url
                    ? getNodeIndex(url, data.getQuestById?.rainbowRoad)
                    : data.getQuestById.currentNode ?? 0) + 1;

            if (node > (data.getQuestById.currentNode ?? 0)) {
                completeQuestCurrentNode({ variables: { questId: questMapId, node } });
            }
        }
    };

    return completeNode;
};

export default useCompleteQuestMapNode;
