import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { SEARCH_NETWORK_GROUP } from '@shared/welibrary-graphql/user/queries';

import { getRandomDefaultUserImage } from '@core/utilities/constants/defaults';

// Used to sanatize mentions input
const escapeRegExp = string => {
    return string?.replace(/[.*+?^${}()|[\]\\]/g, '');
};

export const useMentionsQuery = (
    mentionsGroupId?: string
): {
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
    searchResults: Object[];
} => {
    const [searchString, setSearchString] = useState('');

    const query = SEARCH_NETWORK_GROUP;
    // prevent flickering of displayed results during query
    const [searchResults, setSearchResults] = useState([]);
    const search = searchString && escapeRegExp(searchString);
    const {
        loading: mentionsLoading,
        error: mentionsErr,
        data: mentionsData,
        refetch: mentionsRefetch,
    } = useQuery(query, {
        variables: {
            searchQuery: search,
            groupId: mentionsGroupId,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const defaultUserImage = getRandomDefaultUserImage();

    useEffect(() => {
        if (!mentionsLoading) {
            const userList = mentionsData?.listUsersGroup?.map(user => {
                return {
                    display: user?.profile?.full_name,
                    id: user._id,
                    key: user._id,
                    userThumb: user?.profile?.picture || defaultUserImage,
                };
            });
            setSearchResults(userList);
        }
    }, [mentionsData, mentionsLoading]);

    return {
        setSearchString,
        searchResults,
    };
};

export const useMentions = (
    participants: any
): { setSearchString: React.Dispatch<React.SetStateAction<string>>; searchResults: Object[] } => {
    const [searchString, setSearchString] = useState('');
    let searchResults;
    if (participants) {
        const search = searchString && escapeRegExp(searchString);
        const word = new RegExp(search?.trim(), 'i');

        searchResults = search
            ? participants.filter(participant => participant.display?.match(word))
            : participants;
    }

    return {
        setSearchString,
        searchResults,
    };
};

const useMentionsSelect = (
    users?: any,
    mentionsGroupId?: string
): { setSearchString: React.Dispatch<React.SetStateAction<string>>; searchResults: Object[] } => {
    const preExistingUsers = useMentions(users);
    const newQueryUsers = useMentionsQuery(mentionsGroupId);
    return users ? preExistingUsers : newQueryUsers;
};

export default useMentionsSelect;
