import { createStore } from '@udecode/zustood';

export const QRCodeScannerStore = createStore('qrCodeScannerStore')<{
    showScanner: boolean;
    activeGroupId?: string; // track active group using scanner
}>({ showScanner: false, activeGroupId: '' });

export const useShowScanner = QRCodeScannerStore.useTracked.showScanner;

export default QRCodeScannerStore;
