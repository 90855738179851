// src/messaging/subscriptions.ts
import { gql as gql5 } from "@apollo/client";

// src/messaging/fragments.ts
import { gql as gql4 } from "@apollo/client";

// src/content_card/fragments.ts
import { gql as gql3 } from "@apollo/client";

// src/comment/fragments.ts
import { gql } from "@apollo/client";
var COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    __typename
    _id
    publish
    stream {
      __typename
      _id
    }
    creator {
      __typename
      _id
      profile {
        picture
        full_name
      }
    }
    body
    loggedInUserCanEdit
    childCount
    parentId
  }
`;

// src/tag/fragments.ts
import { gql as gql2 } from "@apollo/client";
var TAG_FRAGMENT = gql2`
    fragment TagFragment on Tag {
        __typename
        _id
        label
        color
        created
        updated
    }
`;

// src/content_card/fragments.ts
var UPDATE_FEATURE_CONSTRAINTS_FRAGMENT = gql3`
  fragment UpdateFeatureConstraintsFragments on ContentCard {
    __typename
    updateFeatureConstraints {
      canEditAllFields
      featuresUserCanEdit {
        type
        field
        options
        canUpdate
      }
    }
  }
`;
var CONTENT_CARD_SEARCH_FRAGMENT = gql3`
  fragment ContentCardSearchFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    media {
      icon
      thumb
    }
    prompt
    isBookmarked
    isArchived
  }
`;
var CONTENT_CARD_COURSE_SEARCH_FRAGMENT = gql3`
  fragment ContentCardCourseSearchFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    media {
      icon
      thumb
    }
    prompt
    courseItems {
      levels {
        title
        media
        resource
      }
    }
    isBookmarked
    isArchived
  }
`;
var CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT = gql3`
  fragment ViewPermissions on ContentCard {
    currentViewPermissions {
      level
      canUserView
    }
  }
`;
var CONTENT_CARD_RATINGS_FRAGMENT = gql3`
  fragment CardRatings on ContentCard {
    ratings_sentiment_score
    latest_rating_sentiments {
      _id
      profile {
        full_name
      }
    }
    ratings_quality_score
    ratings_relevance_score {
      score
      hashtag
    }
    ratings_rank
    loggedInUserVotes {
      sentiment
      quality
      relevance
    }
  }
`;
var CONTENT_CARD_BOOK_SOURCE_FRAGMENT = gql3`
  fragment ContentCardBookSourceFragment on ContentCard {
    __typename
    _id
    url
    title
    library_settings {
      displayReference
      protectionLevel
      accessRoleWhitelist
    }
    reference {
      groupSource {
        profile {
          full_name
        }
        settings {
          protectionLevel
        }
      }
    }
  }
`;
var CONTENT_CARD_REFERENCE_FRAGMENT = gql3`
  fragment ContentCardReferenceFragment on ContentCard {
    reference {
      href
      isResource
      coverImage
      chapterSource {
        title
        url
        prompt
        showTitle
        inheritSurveyAnswers
        subtype
        vote_settings {
          QUALITY {
            active
          }
          RELEVANCE {
            active
          }
          SENTIMENT {
            active
          }
        }
        library_settings {
          engagementTypes
          viewTypes
          defaultViewType
          displayReference
          displaySocialHeaders
        }
        media {
          thumb
        }
      }
      bookSource {
        ...ContentCardBookSourceFragment
      }
      shelfSource {
        title
        url
        library_settings {
          displayReference
          protectionLevel
        }
      }
      librarySource {
        title
        url
        library_settings {
          displayReference
        }
      }
      groupSource {
        _id
        subtype
        profile {
          full_name
          picture
          coverPhoto
        }
        settings {
          shortCode
          shortCodeApprovalRequired
          protectionLevel
          approvalRequired
          formSpec {
            url
          }
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        groupActionsAllowed
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
      }
    }
  }
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
`;
var CONTENT_CARD_COMMENTS_FRAGMENT = gql3`
  fragment CardCommentsFragment on ContentCard {
    __typename
    _id
    comments {
      ...CommentFragment
    }
  }
  ${COMMENT_FRAGMENT}
`;
var REVEAL_CARD_FRAGMENT = gql3`
  fragment RevealCardFragment on ContentCard {
    __typename
    type
    links {
      source
      other
    }
    media {
      thumb
      icon
      logoLight
      logoDark
    }
  }
`;
var CONTENT_CARD_CREATION_FRAGMENT = gql3`
  fragment ContentCardCreationFragment on CreationItems {
    silhouetteImage
    course {
      _id
      media {
        icon
        thumb
      }
      title
      courseItems {
        materials
        ageGroup
        duration
        people
        levels {
          title
          media
          resource
          preSurvey {
            _id
            type
            subtype
            prompt
            useImage
            image
            range
            answers
            correctAnswer
            skill
            skillSubtype
            skillMilestone
          }
          postSurvey {
            _id
            type
            subtype
            prompt
            useImage
            image
            range
            answers
            correctAnswer
            skill
            skillSubtype
            skillMilestone
          }
        }
      }
      url
    }
    levels {
      title
      description
      image
    }
  }
`;
var CONTENT_CARD_COURSE_FRAGMENT = gql3`
  fragment ContentCardCourseFragment on CourseItems {
    materials
    ageGroup
    duration
    people
    levels {
      title
      media
      video
      resource
      steps {
        text
        useImage
        image
        useVideo
        video
        fullPage
        pageBreak
        skill
        skillSubtype
        skillMilestone
      }
      preSurvey {
        _id
        type
        subtype
        prompt
        useImage
        image
        range
        answers
        correctAnswer
        skill
        skillSubtype
        skillMilestone
      }
      postSurvey {
        _id
        type
        subtype
        prompt
        useImage
        image
        range
        answers
        correctAnswer
        skill
        skillSubtype
        skillMilestone
      }
    }
    publicCompletions
  }
`;
var CONTENT_CARD_PLEDGES_FRAGMENT = gql3`
  fragment ContentCardPledgesFragment on PledgeItems {
    __typename
    _id
    pledges {
      __typename
      _id
      label
      reportingType
      canPledge
      canDeletePledge
      totalPledges
      currentUsersPledges
      currentUsersPledgesMonth: currentUsersPledges(daysAgo: 30)
      currentUsersPledgesDay: currentUsersPledges(daysAgo: 1)
      successfulPledgesAll: currentUsersSuccessfulPledges
      successfulPledgesMonth: currentUsersSuccessfulPledges(daysAgo: 30)
      successfulPledgesDay: currentUsersSuccessfulPledges(daysAgo: 1)
      hidden
    }
    pledgeKeys {
      __typename
      _id
      label
      refactoryPeriod
      limitPerRefractoryPeriod
      description
      reportingType
      hidden
    }
    pledgers {
      __typename
      _id
      profile {
        full_name
        first_name
        picture
      }
    }
    latestPledgerEngagement {
      userId
      date
    }
    totalPledges
    totalPledgers
    pledgesLastDay: totalPledges(daysAgo: 1)
    pledgesLastMonth: totalPledges(daysAgo: 30)
  }
`;
var MOBILIZE_EFFORT_FRAGMENT = gql3`
  fragment MobilizeEffortFragment on ContentCard {
    __typename
    _id
    path
    url
    shortCode
    type
    title
    body
    description
    prompt
    rankOrder
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
      }
      roles {
        isSpeakerOf
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    aliases {
      ... on GroupAliasHandle {
        url
        title
        color
        type
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
      }
      ... on AliasItem {
        url
        title
        color
        type
        item {
          _id
          __typename
          title
          body
          protectionLevel
          prompt
          author {
            __typename
            _id
            type
            profile {
              full_name
            }
          }
          reference {
            href
            coverImage
            bookSource {
              ...ContentCardBookSourceFragment
            }
          }
          media {
            thumb
            icon
          }
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            displayInNewsfeed
            userCanPost
          }
          pledgeItems {
            ...ContentCardPledgesFragment
          }
          whitelabelVerbage {
            __typename
            _id
            value
          }
          isArchived
          userHasPledgedTo
          taskHistory {
            __typename
            cursor
            hasMore
            results {
              pledgeKeyId
              label
              refactoryPeriod
              date
              supplement {
                note
                image
                document
                documentTitle
              }
              verifier {
                name
                email
                phone
                country
                note
                newRecruit
              }
              claimedAccount {
                __typename
                _id
                profile {
                  picture
                  full_name
                  email
                  phone
                  country
                }
              }
            }
            type
          }
        }
      }
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
    }
    loggedInUserCanEdit
    defaultHidden
    isBookmarked
    isArchived
    applicationItems {
      applications {
        __typename
        _id
        createdAt
        status
        data {
          values {
            id
            values
          }
        }
        user {
          __typename
          _id
          profile {
            full_name
            picture
            country
            website
            email
          }
        }
      }
    }
    postingSettings {
      type
      status
      totalPositionsCount
      acceptedCount
      specification {
        url
      }
      postingData {
        parent {
          url
        }
        values {
          id
          type
          customText
          comparator
          values
          siblingId
        }
      }
      requirementsData {
        parent {
          url
        }
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    loggedInUserApplyStatus
    isTemplate
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
`;
var CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT = gql3`
  fragment ContentCardCustomFieldsFragment on ContentCard {
    _id
    customFields {
      manager
      parent {
        url
      }
      parentGroup {
        _id
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
`;
var CONTENT_CARD_FRAGMENT = gql3`
  fragment ContentCardFragment on ContentCard {
    __typename
    _id
    vs
    path
    url
    shortCode
    type
    title
    body
    shortTitle
    description
    prompt
    commentCount
    latestComments {
      cursor
      hasMore
      type
      results {
        _id
        creator {
          _id
          profile {
            full_name
            picture
          }
        }
        body
      }
    }
    inheritSurveyAnswers
    rankOrder
    subtype
    pinnedCardIds
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
        did
      }
      roles {
        isSpeakerOf
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      title
      source
      openInModal
      other
      publisher
    }
    aliases {
      ... on GroupAliasHandle {
        url
        title
        color
        thumb
        icon
        type
        group {
          __typename
          _id
          subtype
          isUnpublished
          profile {
            full_name
            picture
            coverPhoto
          }
          paymentSettings {
            stripeAccount {
              id
              email
              country
              charges_enabled
              payouts_enabled
              details_submitted
              external_accounts {
                object
                data {
                  country
                  currency
                }
                has_more
                url
              }
            }
            isGroupMembershipPaid
          }
          products {
            _id
            name
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
          }
          groupActionsAllowed
          currentUserOwnsMembership
          currentUserOwnsFreeSubscription
          doesSellMembership
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
        }
      }
      ... on MessageThreadAliasHandle {
        url
        title
        color
        thumb
        icon
        type
        thread {
          __typename
          _id
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
        }
      }
      ... on AliasItem {
        url
        title
        color
        thumb
        icon
        type
        item {
          subtype
          __typename
          _id
          title
          shortTitle
          prompt
          vote_settings {
            QUALITY {
              active
            }
            RELEVANCE {
              active
            }
            SENTIMENT {
              active
            }
          }
          type
          author {
            __typename
            _id
            type
            profile {
              full_name
            }
          }
          reference {
            href
            coverImage
            shelfSource {
              title
              url
              library_settings {
                displayReference
                protectionLevel
              }
            }
            bookSource {
              ...ContentCardBookSourceFragment
            }
            groupSource {
              _id
              subtype
              profile {
                full_name
                picture
                coverPhoto
              }
            }
          }
          media {
            thumb
            icon
          }
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            protectionLevel
            displayInNewsfeed
            userCanPost
          }
          pledgeItems {
            ...ContentCardPledgesFragment
          }
          whitelabelVerbage {
            __typename
            _id
            value
          }
          isArchived
          userHasPledgedTo
          taskHistory {
            __typename
            cursor
            hasMore
            results {
              pledgeKeyId
              label
              refactoryPeriod
              date
              supplement {
                note
                image
                document
                documentTitle
              }
              verifier {
                name
                email
                phone
                country
                note
                newRecruit
              }
              claimedAccount {
                __typename
                _id
                profile {
                  picture
                  full_name
                  email
                  phone
                  country
                }
              }
            }
            type
          }
        }
      }
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
      mediaMetaData {
        height
        width
      }
    }
    label {
      text
      type
    }
    formSpecificationSettings {
      type
      postingForm {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
          dependsOn {
            id
            values
          }
          groups {
            group {
              _id
              path
              groupActionsAllowed
              currentUserIsMember
              currentUserIsAdmin
              profile {
                picture
                full_name
                short_bio
              }
              settings {
                approvalRequired
                protectionLevel
              }
            }
            id
            requireApproval
          }
        }
      }
      applicationForm {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
        }
      }
      form {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
        }
      }
    }
    library_settings {
      postTypes
      engagementTypes
      filterTypes
      viewTypes
      defaultViewType
      defaultFilter
      allowPostbox
      postboxRoleWhiteList
      userCanPost
      displaySocialHeaders
      displayInNewsfeed
      showCreateChildren
      showResourceHeader
      allowLabels
      displayReference
      protectionLevel
      accessRoleWhitelist
      memberStyle
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      dashboardContentItems {
        name
        url
        type
        filter
      }
      customRoles {
        name
        url
        color
      }
      sidebarMenu {
        name
        url
      }
      recommendations
    }
    sendNotifications
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    meta {
      course {
        revealType
        revealContent {
          ...RevealCardFragment
        }
        compensation
        time
        sequence
      }
      research {
        category
        journalName
        organization
        publicationType
        abstract
        authors
        fileURL
        tags
        issue
      }
      mobilize {
        date
        qualifications
        skills
        summary
        city
        state
        country
        cta
        goalnumber
        hours
        matchterm
        pledge
        timeCommit
        pertime
      }
      pledge {
        date
        qualifications
        skills
        summary
        city
        state
        country
        cta
        goalnumber
        hours
        matchterm
        pledge
        timeCommit
        pertime
      }
    }
    loggedInUserCanEdit
    currentUserHasRead
    defaultHidden
    isBookmarked
    isArchived
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    isTemplate
    postingSettings {
      type
      status
      totalPositionsCount
      acceptedCount
      specification {
        url
      }
      postingData {
        parent {
          url
        }
        values {
          id
          type
          customText
          comparator
          values
          siblingId
        }
      }
      requirementsData {
        parent {
          url
        }
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    mobilizationSettings {
      approvalType
      status
      efforts {
        type
        card {
          __typename
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            displayInNewsfeed
          }
          ...MobilizeEffortFragment
        }
        status
        country
        countGoal
        count
      }
    }
    courseItems {
      ...ContentCardCourseFragment
    }
    creationItems {
      ...ContentCardCreationFragment
    }
    surveyQuestions {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
    timeAndPlaceSettings {
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        country
        postal
        state
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
    }
    eventOverviewDetails {
      title
      description
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      location {
        formatted_address
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
    }
    eventSettings {
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      location {
        formatted_address
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
    }
    eventAttendance {
      user {
        _id
        profile {
          full_name
          picture
        }
        socialActionsAllowed
      }
      status
    }
    canMembersSuggestEdits
    showTitle
    protectionLevel
    currentUserCanSuggestEdit
    verticalScrolling
    dialogSettings {
      event
      people {
        name
        image
      }
      dialogs {
        person
        text
      }
    }
    showPostTypes
    ...UpdateFeatureConstraintsFragments
    ...CardRatings
    ...ContentCardCustomFieldsFragment
  }
  ${CONTENT_CARD_RATINGS_FRAGMENT}
  ${REVEAL_CARD_FRAGMENT}
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${MOBILIZE_EFFORT_FRAGMENT}
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
  ${CONTENT_CARD_COURSE_FRAGMENT}
  ${CONTENT_CARD_CREATION_FRAGMENT}
  ${CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT}
`;
var CONTENT_CARD_PLATFORM_FRAGMENT = gql3`
  fragment ContentCardPlatformFragment on ContentCard {
    _id
    __typename
    vs
    type
    url
    platformSettings {
      platformGroupOwner
      forms {
        formCardUrl
        formSpecificationSettings {
          type
          form {
            fieldGroups {
              groupName
              index
              fieldIds
            }
            required
            fields {
              index
              id
              type
              options
              prompt
              defaultComparator
              customText
              selectMultiple
              siblingId
              allowOther
              required
              showOnSignup
              groups {
                group {
                  _id
                  path
                  groupActionsAllowed
                  currentUserIsMember
                  currentUserIsAdmin
                  profile {
                    picture
                    full_name
                    short_bio
                  }
                  settings {
                    approvalRequired
                    protectionLevel
                  }
                }
                id
                requireApproval
              }
              placeholder
              dependsOn {
                id
                values
              }
            }
          }
        }
      }
    }
  }
`;
var CONTENT_CARD_CHAPTER_FRAGMENT = gql3`
  fragment ContentCardChapterFragment on ContentCard {
    _id
    vs
    type
    url
    title
    shortTitle
    media {
      icon
      thumb
    }
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
      }
    }
    description
    isArchived
    prompt
    loggedInUserCanEdit
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    library_settings {
      postTypes
      engagementTypes
      filterTypes
      viewTypes
      defaultViewType
      defaultFilter
      allowPostbox
      postboxRoleWhiteList
      userCanPost
      displaySocialHeaders
      displayInNewsfeed
      allowLabels
      displayReference
      tagAggregation {
        ...TagFragment
      }
      cardAggregation {
        __typename
        _id
        title
        url
        body
        media {
          thumb
          icon
        }
      }
    }
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    sendNotifications
    rolesAvailable {
      url
      name
    }
    ...ContentCardReferenceFragment
    rankOrder
    canMembersSuggestEdits
    currentUserCanSuggestEdit
    showTitle
    childrenCount
    showPostTypes
    inheritSurveyAnswers
    isTemplate
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var RAINBOW_ROAD_FRAGMENT = gql3`
  fragment RainbowRoadFragment on ContentCard {
    __typename
    _id
    url
    vs
    type
    subtype
    title
    description
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
      }
      roles {
        isSpeakerOf
      }
    }
    media {
      icon
    }
    loggedInUserCanEdit
    rainbowRoadSettings {
      header {
        color
        darkMode
        image
        backgroundImage
        showTopInfo
      }
      body {
        color
        darkMode
        image
      }
      nodes {
        circleSize
        icon
        lineStyle
        lineColor
        outline
        winding
        spinnerImage
      }
      font
      questSettings {
        creationsChannel {
          url
          title
          children {
            ...ContentCardFragment
          }
        }
        creationNodeSettings {
          icon
          backgroundImage
          showMetaInformation
          spinnerImage
        }
        completionRequirement
        dialogsChannel {
          url
          title
          children {
            ...ContentCardFragment
          }
        }
        questEndImage
        vcSettings {
          title
          name
          description
          image
        }
      }
    }
    library_settings {
      engagementTypes
    }
    nodes {
      title
      icon
      backgroundImage
      spinnerImage
      showMetaInformation
      preOrPost
      alias {
        ... on UserAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          user {
            _id
            profile {
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
            }
            socialActionsAllowed
          }
        }
        ... on GroupAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          group {
            __typename
            _id
            path
            profile {
              full_name
              short_bio
              full_bio
              picture
              coverPhoto
              country
              website
              email
              customFields {
                parent {
                  url
                }
                parentGroup {
                  _id
                }
                manager
                values {
                  id
                  type
                  comparator
                  values
                  siblingId
                }
              }
            }
            socialActionsAllowed
            shelf {
              __typename
              _id
              url
              media {
                thumb
                icon
              }
            }
            settings {
              __typename
              subscriptions
              formSpec {
                url
                formSpecificationSettings {
                  type
                  form {
                    required
                    fieldGroups {
                      groupName
                      fieldIds
                      index
                    }
                    fields {
                      index
                      type
                      id
                      options
                      customText
                      prompt
                      placeholder
                      type
                      selectMultiple
                      siblingId
                      defaultComparator
                      required
                      allowOther
                      dependsOn {
                        id
                        values
                      }
                    }
                  }
                }
              }
              protectionLevel
              memberStyle
              dashboards {
                name
                url
                type
                filter
              }
              customSignupFields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                weight
                required
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
              recommendations
              canMembersSuggestEdits
              shortCode
              shortCodeApprovalRequired
              groupChatroom
              adminChatroom
              approvalRequired
              registrationTitle
              registrationDescription
              registrationActionText
              enableGroupNewsfeed
            }
            hasUnreadDashboard
            subtype
            totalMembers
            currentUserIsAdmin
            currentUserIsDirectAdmin
            currentUserIsMember
            currentUserLastActiveDate
            groupActionsAllowed
            isTemplate
            allSignupFields {
              parentGroup {
                _id
              }
              manager
              signupFields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                weight
                required
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
            }
            whitelabelVerbage {
              _id
              value
              plainText
            }
            parentGroup {
              __typename
              _id
              profile {
                full_name
              }
            }
            channelsBook {
              title
              url
              media {
                thumb
                icon
              }
              children {
                title
                url
                reference {
                  href
                }
              }
            }
          }
        }
        ... on MessageThreadAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          thread {
            __typename
            _id
            participants {
              __typename
              user {
                __typename
                _id
                profile {
                  __typename
                  full_name
                  short_bio
                  full_bio
                  picture
                  coverPhoto
                  facebookProfile
                  instagramProfile
                  twitterProfile
                  linkedInProfile
                }
              }
              hasUnreadMessages
            }
            messages {
              __typename
              hasMore
              cursor
              type
              messages {
                __typename
                _id
                author {
                  __typename
                  _id
                  profile {
                    __typename
                    first_name
                    full_name
                    picture
                  }
                }
                text
                created
                updated
              }
            }
            displayName
            displayIcon
            created
            updated
            group {
              __typename
              _id
              profile {
                full_name
                picture
                coverPhoto
              }
            }
            currentUserNotificationLevel
          }
        }
        ... on AliasItem {
          url
          type
          title
          thumb
          icon
          color
          item {
            ...ContentCardFragment
            ...ContentCardReferenceFragment
          }
        }
      }
    }
    aliases {
      ... on UserAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        user {
          _id
          profile {
            full_name
            picture
            coverPhoto
            full_bio
            short_bio
            website
            facebookProfile
            twitterProfile
            instagramProfile
            linkedInProfile
          }
          socialActionsAllowed
        }
      }
      ... on GroupAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        group {
          __typename
          _id
          path
          profile {
            full_name
            short_bio
            full_bio
            picture
            coverPhoto
            country
            website
            email
            customFields {
              parent {
                url
              }
              parentGroup {
                _id
              }
              manager
              values {
                id
                type
                comparator
                values
                siblingId
              }
            }
          }
          socialActionsAllowed
          shelf {
            __typename
            _id
            url
            media {
              thumb
              icon
            }
          }
          settings {
            __typename
            subscriptions
            formSpec {
              url
              formSpecificationSettings {
                type
                form {
                  required
                  fieldGroups {
                    groupName
                    fieldIds
                    index
                  }
                  fields {
                    index
                    type
                    id
                    options
                    customText
                    prompt
                    placeholder
                    type
                    selectMultiple
                    siblingId
                    defaultComparator
                    required
                    allowOther
                    dependsOn {
                      id
                      values
                    }
                  }
                }
              }
            }
            protectionLevel
            memberStyle
            dashboards {
              name
              url
              type
              filter
            }
            customSignupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
            recommendations
            canMembersSuggestEdits
            shortCode
            shortCodeApprovalRequired
            groupChatroom
            adminChatroom
            approvalRequired
            registrationTitle
            registrationDescription
            registrationActionText
            enableGroupNewsfeed
          }
          hasUnreadDashboard
          subtype
          totalMembers
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
          currentUserLastActiveDate
          groupActionsAllowed
          isTemplate
          allSignupFields {
            parentGroup {
              _id
            }
            manager
            signupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
          whitelabelVerbage {
            _id
            value
            plainText
          }
          parentGroup {
            __typename
            _id
            profile {
              full_name
            }
          }
          channelsBook {
            title
            url
            media {
              thumb
              icon
            }
            children {
              title
              url
              reference {
                href
              }
            }
          }
        }
      }
      ... on MessageThreadAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        thread {
          __typename
          _id
          participants {
            __typename
            user {
              __typename
              _id
              profile {
                __typename
                full_name
                short_bio
                full_bio
                picture
                coverPhoto
                facebookProfile
                instagramProfile
                twitterProfile
                linkedInProfile
              }
            }
            hasUnreadMessages
          }
          messages {
            __typename
            hasMore
            cursor
            type
            messages {
              __typename
              _id
              author {
                __typename
                _id
                profile {
                  __typename
                  first_name
                  full_name
                  picture
                }
              }
              text
              created
              updated
            }
          }
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
          currentUserNotificationLevel
        }
      }
      ... on AliasItem {
        url
        type
        title
        thumb
        icon
        color
        item {
          ...ContentCardFragment
          ...ContentCardReferenceFragment
        }
      }
    }
  }

  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var CONTENT_CARD_BOOK_FRAGMENT = gql3`
  fragment ContentCardBookFragment on ContentCard {
    _id
    vs
    type
    subtype
    url
    title
    media {
      icon
      thumb
    }
    author {
      _id
      type
      profile {
        full_name
      }
    }
    children(sort: ordered) {
      ...ContentCardChapterFragment
    }
    description
    body
    prompt
    promptEnabled
    loggedInUserFollows
    loggedInUserCanEdit
    isTemplate
    library_settings {
      displayReference
      displayInNewsfeed
      displaySocialHeaders
      protectionLevel
      accessRoleWhitelist
      defaultFilter
      filterTypes
      engagementTypes
      customRoles {
        name
        url
        color
      }
    }
    sendNotifications
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    customFields {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
    cardActionsAllowed
    rankOrder
    verticalScrolling
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_BOOK_BIG_SCREEN_FRAGMENT = gql3`
  fragment ContentCardBookBigScreenFragment on ContentCard {
    _id
    type
    url
    title
    media {
      icon
      thumb
      __typename
    }
    author {
      _id
      type
      profile {
        full_name
        __typename
      }
      __typename
    }
    library_settings {
      displaySocialHeaders
    }
    prompt
    loggedInUserFollows
    loggedInUserCanEdit
    __typename
  }
`;
var CONTENT_CARD_SHELF_FRAGMENT = gql3`
  fragment ContentCardShelfFragment on ContentCard {
    __typename
    _id
    url
    shortCode
    type
    title
    body
    description
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
      }
    }
    group {
      __typename
      _id
      subtype
      username
      profile {
        full_name
        full_bio
        picture
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    media {
      thumb
      icon
    }
    library_settings {
      displayReference
      displayInNewsfeed
      dashboardContentItems {
        name
        url
        type
        filter
      }
      protectionLevel
      accessRoleWhitelist
    }
    customFields {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
    cardActionsAllowed
    loggedInUserFollows
    loggedInUserCanEdit
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_LIBRARY_FRAGMENT = gql3`
  fragment ContentCardLibraryFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
    }
    loggedInUserFollows
    loggedInUserCanEdit
    library_settings {
      welcomeText
      autoFollowCards
      autoFollowUsers
      initialSubscriptions
      protectionLevel
      memberStyle
      sidebarMenu {
        name
        url
      }
      dashboardMenu {
        name
        url
      }
      dashboardContentItems {
        name
        url
        type
        filter
      }
      customRoles {
        name
        url
        color
      }
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      displayReference
      displayInNewsfeed
      recommendations
    }
    cardActionsAllowed
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT = gql3`
  fragment ContentCardChapterChildrenFragment on ContentCard {
    __typename
    _id
    chapterChildren: children(restrictType: chapter) {
      ...ContentCardChapterFragment
    }
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
`;

// src/messaging/fragments.ts
var MESSAGE_THREAD_FRAGMENT = gql4`
  fragment MessageThreadFragment on MessageThread {
    __typename
    _id
    participants {
      __typename
      user {
        __typename
        _id
        type
        profile {
          __typename
          full_name
          short_bio
          full_bio
          picture
          coverPhoto
          facebookProfile
          instagramProfile
          twitterProfile
          linkedInProfile
        }
      }
      hasUnreadMessages
    }
    messages {
      __typename
      hasMore
      cursor
      type
      messages {
        __typename
        _id
        author {
          __typename
          _id
          profile {
            __typename
            first_name
            full_name
            picture
          }
        }
        text
        reactions {
          emoji
          userIds
        }
        attachment {
          type
          _id
          vs
        }
        created
        updated
      }
    }
    displayName
    displayIcon
    created
    updated
    group {
      __typename
      _id
      totalMembers
      profile {
        full_name
        picture
        coverPhoto
        short_bio
      }
      settings {
        protectionLevel
        approvalRequired
        shortCodeApprovalRequired
      }
      products {
        _id
        name
      }
    }
    currentUserNotificationLevel
    threadType
  }
`;
var MESSAGE_FRAGMENT = gql4`
  fragment MessageFragment on Message {
    __typename
    _id
    author {
      __typename
      _id
      profile {
        __typename
        full_name
        picture
        short_bio
      }
    }
    thread {
      ...MessageThreadFragment
    }
    attachment {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
    }
    text
    reactions {
      emoji
      userIds
    }
    created
    updated
  }
  ${MESSAGE_THREAD_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var MESSAGE_FRAGMENT_OMIT_THREAD = gql4`
  fragment MessageFragmentOmitThread on Message {
    __typename
    _id
    author {
      __typename
      _id
      profile {
        __typename
        full_name
        picture
        short_bio
      }
    }
    reactions {
      emoji
      userIds
    }
    attachment {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
    }
    text
    created
    updated
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var MESSAGE_UPDATE_FRAGMENT = gql4`
  fragment MessageUpdateFragment on MessageUpdate {
    action
    message {
      ... on Message {
        ...MessageFragment
      }
      ... on MessageDelete {
        _id
        thread {
          ...MessageThreadFragment
        }
      }
    }
  }
  ${MESSAGE_FRAGMENT}
  ${MESSAGE_THREAD_FRAGMENT}
`;

// src/messaging/subscriptions.ts
var NEW_MESSAGE_ON_THREAD_SUBSCRIPTION = gql5`
    subscription OnNewMessageOnThread($userId: ID!) {
        newMessageOnThread(userId: $userId) {
            ...MessageThreadFragment
        }
    }
    ${MESSAGE_THREAD_FRAGMENT}
`;
var MESSAGE_UPDATE_SUBSCRIPTION = gql5`
    subscription OnMessageUpdate($threadId: ID!) {
        messageUpdate(threadId: $threadId) {
            ...MessageUpdateFragment
        }
    }
    ${MESSAGE_UPDATE_FRAGMENT}
`;
export {
  MESSAGE_UPDATE_SUBSCRIPTION,
  NEW_MESSAGE_ON_THREAD_SUBSCRIPTION
};
