import React from 'react';

const NavigatorToggleButtonBottom: React.FC<{ className?: string }> = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 20 38">
        <path
            d="M20 8.60811C20 22.0872 0 20.5895 0 38V8.60811V0.5H20V8.60811Z"
            fill="currentcolor"
        />
    </svg>
);

export default NavigatorToggleButtonBottom;
