import React from 'react';

import ResourceIcon from '@components/card/coursecard/ResourceIcon';
import GradientFrameBg from '@assets/lego/gradientclouds.png';

import { ResourceMeta } from '@web/utilities/helpers/courses/course.helpers';

import { Resource } from '@shared/welibrary-graphql/types';

const ResourceFull: React.FC<{ resource: Resource; quantity: number }> = ({
    resource,
    quantity,
}) => {
    return (
        <div className="resource-full-container">
            <div className="treasure-gradient-bg">
                <img alt="" src={GradientFrameBg} width="100%" height="100%" />
            </div>
            <div className="resource-full-header">
                <h1>{ResourceMeta[resource].adjective}</h1>
                <div className="resource-sub-header">SKILL RESOURCE</div>
            </div>
            <div className="resource-body">
                <div className="resource-icon-large">
                    <ResourceIcon
                        className="size-large"
                        type={resource}
                        quantity={quantity}
                        color={ResourceMeta[resource].color}
                        hidePlus
                        size="200px"
                    />
                </div>
            </div>
        </div>
    );
};

export default ResourceFull;
