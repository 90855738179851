import React from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

import FileUploadHelper from '@components/content/FileUploadHelper';
import VideoItem from '@components/card/mediacard/bodyitems/VideoItem';
import ResearchItem from '@components/card/mediacard/bodyitems/ResearchItem';
import MobilizeItem from '@components/card/mediacard/bodyitems/MobilizeItem';
import HeaderItem from '@components/card/mediacard/bodyitems/HeaderItem';
import PledgeCard from '@components/card/pledgecard/PledgeCard';
import CustomFieldsSummaryItem from '@components/card/mediacard/bodyitems/CustomFieldsSummaryItem';
import LinkTilePreview from '@components/content/newpost/previews/LinkTilePreview';
import LinkTileListPreview from '@components/content/newpost/previews/LinkTileListPreview';
import PostboxPreview from '@components/content/newpost/previews/PostboxPreview';
import VoteGalleryPreview from '@components/content/newpost/previews/VoteGalleryPreview';
import EditDocumentCard from '@components/card/documentcard/EditDocumentCard';
import CreateImageCard from '@components/card/imagecard/CreateImageCard';
import CreateSquareImageCard from '@components/card/squareImagecard/CreateSquareImageCard';

import {
    ATTACH_IMAGE,
    ATTACH_IMAGE_SQUARE,
    ATTACH_VIDEO,
    ATTACH_DOCUMENT,
    ATTACH_LINK,
    ATTACH_MOBILIZE,
    ATTACH_PLEDGE,
    ATTACH_RESEARCH,
    ATTACH_POSTBOX,
    ATTACH_VOTE_GALLERY,
    ATTACH_LINK_TILE,
    ATTACH_HEADER,
    ATTACH_CUSTOM_FIELDS_SUMMARY,
} from '@core/utilities/constants/attachment_types';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.generic.editScreen.editCardMediaItem';

const HIDE_PREVIEW_META_TYPES = [ATTACH_IMAGE, ATTACH_IMAGE_SQUARE];

const PreviewContainer = ({
    content,
    clearAttachment,
    currentUser,
    state,
    setState,
    formAttributes,
}) => {
    const { t } = useTranslation();
    // TODO: Handle Errors
    if (!content || content.error) {
        logger.error('Error in PreviewContainer! ', content?.error);
    }

    let previewObject = {};

    switch (content.type) {
        case ATTACH_IMAGE:
            return <CreateImageCard {...formAttributes} />;
        case ATTACH_IMAGE_SQUARE:
            return <CreateSquareImageCard {...formAttributes} />;
        case ATTACH_LINK:
            const linkData = content.data;
            const handleSetState = updatedValues => {
                setState(prevState => {
                    return { ...prevState, ...updatedValues };
                });
            };

            const handleUpdateState = target => {
                const stateClone = cloneDeep(state);
                stateClone.attachedContent.data.image = target.mediaThumb;
                handleSetState(stateClone);
            };

            const source = `${linkData?.author || ''} | ${linkData?.publisher || ''}`;

            return (
                <div className="media-post-link w-form">
                    <div className="media-link-preview-container">
                        <div
                            className="media-link-preview-thumb"
                            style={{ backgroundImage: `url(${linkData?.image})` }}
                        >
                            <div className="square-spacer" />
                            <div className="link-thumb-edit">
                                <FileUploadHelper
                                    target="mediaThumb"
                                    handleUpdateState={handleUpdateState}
                                    render={({ handleFileSelect }) => (
                                        <div
                                            onClick={handleFileSelect}
                                            className="mc-media-button image-button w-inline-block"
                                        >
                                            <div className="display-inline-block">
                                                {t(`common:${localNamespace}.edit_thumbnail`)}
                                                <br />
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                        <div className="media-link-preview-content">
                            <div className="media-link-preview-source">{source}</div>
                            <span className="media-link-preview-title">
                                {linkData?.title || linkData?.url || ''}
                            </span>
                            <p className="media-link-preview-summary">
                                {linkData?.description || ''}
                            </p>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="media-link-preview-clear-button"
                        onClick={clearAttachment}
                    >
                        <img src="/images/icon.black.x.svg" alt="Clear link Preview" />
                    </button>
                </div>
            );
        case ATTACH_VIDEO:
            const videoData = content.data;
            previewObject = {
                media: { thumb: videoData?.image },
                links: { source: videoData?.url },
            };
            return (
                <div className="we-preview-container-content-wrapper">
                    <VideoItem media={previewObject.media} links={previewObject.links} />
                </div>
            );

        case ATTACH_DOCUMENT:
            return (
                <div className="we-preview-container-content-wrapper">
                    <EditDocumentCard state={state} setState={setState} />
                </div>
            );

        case ATTACH_RESEARCH:
            const researchData = content.data;
            previewObject = {
                media: { icon: researchData.mediaIcon, thumb: researchData.mediaThumb },
                links: { source: researchData.link },
            };

            const researchCardData = { ...researchData, ...previewObject };
            return (
                <div className="we-preview-container-content-wrapper">
                    {/* <CourseItem media={previewObject.media} title={documentData.filename} links={previewObject.links} /> */}
                    <ResearchItem card={researchCardData} />
                    {/* <CourseCardDescription source={courseData} /> */}
                </div>
            );

        case ATTACH_MOBILIZE:
            const mobilizeData = content.data;
            previewObject = {
                media: { icon: mobilizeData.mediaIcon, thumb: mobilizeData.mediaThumb },
                links: { source: mobilizeData.link },
            };
            const mobilizeCardData = { ...mobilizeData, ...previewObject };

            return (
                <div className="we-preview-container-content-wrapper">
                    {/* <CourseItem media={previewObject.media} title={documentData.filename} links={previewObject.links} /> */}
                    <MobilizeItem card={mobilizeCardData} />
                    {/* <CourseCardDescription source={courseData} /> */}
                </div>
            );
        case ATTACH_CUSTOM_FIELDS_SUMMARY:
            return (
                <div className="we-preview-container-content-wrapper">
                    {/* <CourseItem media={previewObject.media} title={documentData.filename} links={previewObject.links} /> */}
                    <CustomFieldsSummaryItem card={content.data} isPreview />
                    {/* <CourseCardDescription source={courseData} /> */}
                </div>
            );
        case ATTACH_PLEDGE:
            const pledgeData = content.data?.questionAnswers;

            return (
                <div className="we-preview-container-content-wrapper">
                    <PledgeCard
                        country={pledgeData.country}
                        title={pledgeData.title}
                        description={pledgeData.description}
                        actionItems={pledgeData.actionItems}
                    />
                </div>
            );

        case ATTACH_POSTBOX:
            const postboxData = content.data;
            previewObject = {
                title: postboxData.title,
            };

            return <PostboxPreview currentUser={currentUser} source={postboxData} />;

        case ATTACH_LINK_TILE:
            const linktileData = content.data;
            if (content.data.defaultViewType === 'listview') {
                return <LinkTileListPreview source={linktileData} />;
            }
            return <LinkTilePreview source={linktileData} />;

        case ATTACH_VOTE_GALLERY:
            const votegalleryData = content.data?.aliases?.[0];
            const displayTitle = content?.data?.title;
            return <VoteGalleryPreview displayTitle={displayTitle} source={votegalleryData} />;

        case ATTACH_HEADER:
            const headerData = content.data;

            return (
                <div className="media-post-link w-form">
                    <div className="media-link-preview-container">
                        <div className="media-link-preview-content">
                            <HeaderItem card={headerData} />
                        </div>
                    </div>
                </div>
            );

        default:
            return null;
    }

    return (
        <div className="media-post-link w-form">
            <div className="media-link-preview-container">
                <div className="media-link-preview-thumb" style={previewObject.thumbStyle}>
                    <div className="square-spacer" />
                </div>
                {!HIDE_PREVIEW_META_TYPES?.includes(content?.type) && (
                    <div className="media-link-preview-content">
                        <div className="media-link-preview-source">{previewObject.source}</div>
                        <span className="media-link-preview-title">{previewObject.title}</span>
                        <p className="media-link-preview-summary">{previewObject.summary}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PreviewContainer;
