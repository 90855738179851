import React from 'react';

import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';

import X from '@dsc/svgs/FullSizeX';
import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/success-checkmark.json';
import TicketSharing from '@components/card/Shopping-Cart/TicketSharing/TicketSharing';

import {
    UserDetailsState,
    isOrderFree,
} from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';

import { ModalTypes } from '@core/types/Modals';
import { Group, Product } from '@shared/welibrary-graphql/types';
import { CARD_EVENT } from '@core/utilities/constants/card_types';

type CheckoutSuccessProps = {
    closeModal: () => void;
    orderId: String;
    group: Group;
    total: number;
    cartItem: Product;
    hideCloseButton?: boolean;

    onClick?: () => void;
    useSuccessAltText?: boolean;
    state?: UserDetailsState;
};

const CheckoutSuccess: React.FC<CheckoutSuccessProps> = ({
    closeModal,
    orderId,
    group,
    total,
    hideCloseButton,

    onClick,
    useSuccessAltText = false,
    state,
}) => {
    const { currentUser } = useCurrentUser();
    const { newModal } = useModal({ desktop: ModalTypes.Center, mobile: ModalTypes.Center });

    let showCloseButton = window.innerWidth > 991;
    if (hideCloseButton) showCloseButton = false;

    const handleTicketSharing = () => {
        newModal(<TicketSharing orderId={orderId} />);
    };

    const isEvent = group?.subtype === CARD_EVENT;

    const orderText = isOrderFree(total) ? 'Order' : 'Payment';

    let buttonText: string = 'Done';
    if (useSuccessAltText && isEvent) buttonText = 'Got it!';

    const altText = (
        <>
            <h1>Success!</h1>
            <div>
                <p className="text-grayscale-body font-poppins text-[16px] font-[500] leading-[22px] tracking-[0.25px] text-center mt-4">
                    You have successfully placed an order for {group?.profile?.full_name}
                </p>

                <p className="text-grayscale-body font-poppins text-[16px] font-[500] leading-[22px] tracking-[0.25px] text-center mt-4">
                    An event confirmation email will be sent to: <br />{' '}
                    <strong>{state?.email}</strong>
                </p>
            </div>
        </>
    );

    return (
        <div className="checkout-complete-wrap">
            {showCloseButton && (
                <button
                    type="button"
                    onClick={() => closeModal()}
                    className="checkout-modal-btn-close"
                >
                    <X className="checkout-modal-btn-x-dark" />
                </button>
            )}
            <LottieLoading
                height={125}
                width={125}
                lottieData={LottieAnimation}
                customStyle={{ opacity: 1 }}
                isPaused={false}
                loop={false}
            />
            {useSuccessAltText && isEvent && altText}
            {!useSuccessAltText && (
                <h1>
                    {orderText}
                    <br />
                    Complete!
                </h1>
            )}
            <div className="checkout-complete-btn-wrap">
                {currentUser && isEvent && (
                    <button
                        onClick={handleTicketSharing}
                        type="button"
                        className="checkout-complete-btn checkout-complete-btn-transfer"
                    >
                        Transfer Tickets
                    </button>
                )}
                <button
                    onClick={() => {
                        closeModal();
                        onClick?.();
                    }}
                    type="button"
                    className="checkout-complete-btn checkout-complete-btn-done"
                >
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default CheckoutSuccess;
