import React from 'react';

const ChainLink: React.FC<{
    className?: string;
    size?: string;
    strokeWidth?: string;
    strokeColor?: string;
}> = ({ className, size = '50', strokeWidth = '3', strokeColor = '#000' }) => (
    <svg
        width={size}
        className={className}
        height={size}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.4872 12.3596L27.7622 10.0846C28.5601 9.28597 29.5075 8.65238 30.5503 8.22001C31.5931 7.78764 32.7108 7.56496 33.8397 7.5647C34.9686 7.56443 36.0865 7.78659 37.1295 8.21848C38.1725 8.65036 39.1202 9.28351 39.9184 10.0817C40.7167 10.88 41.3498 11.8277 41.7817 12.8707C42.2136 13.9137 42.4357 15.0316 42.4355 16.1604C42.4352 17.2893 42.2125 18.4071 41.7802 19.4499C41.3478 20.4927 40.7142 21.4401 39.9156 22.238L34.3913 27.7622C33.5933 28.5603 32.6459 29.1933 31.6033 29.6251C30.5607 30.057 29.4432 30.2793 28.3146 30.2793C27.1861 30.2793 26.0686 30.057 25.0259 29.6251C23.9833 29.1933 23.0359 28.5603 22.2379 27.7622"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24.5125 37.6408L22.2379 39.9154C21.44 40.714 20.4926 41.3476 19.4498 41.78C18.407 42.2124 17.2892 42.435 16.1603 42.4353C15.0314 42.4356 13.9136 42.2134 12.8706 41.7815C11.8276 41.3496 10.8799 40.7165 10.0816 39.9183C9.28339 39.12 8.65024 38.1723 8.21835 37.1293C7.78647 36.0863 7.56431 34.9684 7.56458 33.8396C7.56484 32.7107 7.78752 31.5929 8.21988 30.5501C8.65225 29.5073 9.28584 28.5599 10.0845 27.762L15.6087 22.2378C16.4067 21.4398 17.3541 20.8067 18.3967 20.3749C19.4394 19.943 20.5569 19.7207 21.6854 19.7207C22.814 19.7207 23.9315 19.943 24.9741 20.3749C26.0168 20.8067 26.9641 21.4398 27.7621 22.2378"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ChainLink;
