import React, { useCallback } from 'react';
import searchStore from '@stores/Search';
import { useCurrentUser } from '@stores/User';
import { RunSearchJobQuery, useRunSearchJobQuery } from '@shared/welibrary-graphql/search/queries.hook';

const QUERY_LIMIT = 20;

const useSearchJob = () => {
    const { currentUser } = useCurrentUser();

    const {
        query: searchQuery,
        filter: searchFilter,
        queryScopes,
        refreshing,
    } = searchStore.useStore();

    const allTab = searchStore.get.isAllTab();
    const userId = currentUser && currentUser._id;

    // use lazy query so that we can control (and debounce) search
    const { networkStatus, fetchMore, loading, data, error } = useRunSearchJobQuery({
        variables: {
            input: {
                text: searchQuery,
                limit: QUERY_LIMIT,
                scopes: queryScopes,
                filters: searchFilter,
                userId,
            },
        },
    });

    const updateQueryAfterFetchMore = (
        previousResult: RunSearchJobQuery,
        fetchMoreResult: RunSearchJobQuery | undefined
    ) => {
        const fetchMoreResults = fetchMoreResult?.runSearchJob?.results;
        const previousResults = previousResult?.runSearchJob?.results;

        const newEntries = fetchMoreResults?.results ?? [];
        const oldEntries = previousResults?.results ?? [];
        const newCursor = fetchMoreResults?.cursor;
        const oldCursor = previousResults?.cursor;
        const hasMore = fetchMoreResults?.hasMore;
        return {
            runSearchJob: {
                __typename: 'SearchResponse',
                limit: QUERY_LIMIT,
                results: {
                    __typename: 'PaginatedSearchResults',
                    cursor: newCursor || oldCursor,
                    hasMore,
                    type: 'PaginatedSearchResults',
                    results: [...oldEntries, ...newEntries],
                },
                text: fetchMoreResult?.runSearchJob?.text,
            },
        };
    };
    const hasMoreResults = data?.runSearchJob?.results?.hasMore;
    const fetchMoreResults = () => {
        if (fetchMore && hasMoreResults) {
            fetchMore({
                variables: {
                    input: {
                        text: searchQuery,
                        limit: QUERY_LIMIT,
                        cursor: data?.runSearchJob?.results?.cursor,
                        scopes: queryScopes,
                        filters: searchFilter,
                        userId,
                    },
                },
                updateQuery: (
                    previousResult: RunSearchJobQuery,
                    { fetchMoreResult }: { fetchMoreResult: RunSearchJobQuery | undefined }
                ) => updateQueryAfterFetchMore(previousResult, fetchMoreResult),
                onCompleted: () => searchStore.set.refreshing(false),
            });
        }
    };

    if (refreshing && networkStatus !== 3 && hasMoreResults && !allTab) {
        fetchMoreResults();
        searchStore.set.refreshing(false);
    }

    return {
        hasMoreResults,
        searchResults: data?.runSearchJob?.results,
        networkStatus,
        error,
        fetchMore: fetchMoreResults,
        loading,
    };
};

export default useSearchJob;
