import { useState, useEffect, useRef } from 'react';

import useAntiFlash from '@components/generic/hooks/useAntiFlash';

/**
 * React hook for preventing a boolean from flashing
 *
 * Takes in a boolean and optionally a minimum flash time/maximum delay time, and spits out a
 * boolean that will not flash!
 *
 * "What is this for? What problem is this solving?"
 *
 * I'm glad you asked, mysterious doc comment questioner!
 *
 * When using apollo queries/mutations, we get back a loading boolean that can be useful to tell the
 * user, "Hey! Don't worry, we're just sending/receiving data over the network right now!"
 *
 * There are a few ways to convey this to the user. One awesome way is with our LoadingLine
 * component, where we basically just have a blue line appear on the top of their screen. Another
 * way is to actually change part of or the whole screen to a large loader.
 *
 * When doing it the latter way, it can be kind of jarring for the user if the loading boolean
 * really quickly jumps from false to true and back to true again (or just starts at true and
 * immediately jumps back to false). When that happens, the loader will "flash" really quickly on
 * screen, and look kind of bad. This is what we mean by "flashing" in "antiFlashing"
 *
 * This hook solves that by allowing you to easily convert that boolean like so:
 * const antiFlashingLoading = useAntiFlashingBoolean(loading);
 *
 * This will cause the antiFlashingLoading boolean to have the following behavior:
 * - When loading becomes true, there is a set delay (maximumDelay) before antiFlashingLoading becomes true
 * - If loading becomes false before maximumDelay, antiFlashingLoading will stay false and never become true
 * - If loading stays true, antiFlashingLoading will become true
 * - If loading becomes false before a set amount of time after that (minimumFlash),
 *   antiFlashingLoading will stay true for a minimum amount of time (minimumFlash) before becoming false
 * - If loading becomes fase after ${minimumFlash}, antiFlashingLoading will immediately become false
 *
 * This way, if the user has a great internet connection, they'll never even see the loader, if they
 * have a decent internet connection, they'll see the loader for a short amount of time, but no so
 * short that it flashes, and if they have a really poor internet connection, they will see the loader
 * for only as long as they need to!
 */
const useAntiFlashingBoolean = (
    value: boolean,
    { minimumFlash, maximumDelay }: { minimumFlash?: number; maximumDelay?: number } = {}
): boolean => {
    const [newValue, setNewValue] = useState(value !== undefined ? value : true);
    const resolveFunction = useRef<() => void>();

    const antiFlash = useAntiFlash({ minimumFlash, maximumDelay });

    useEffect(() => {
        if (value) {
            antiFlash(
                resolve => {
                    resolveFunction.current = () => resolve();
                },
                () => setNewValue(true),
                () => {
                    setNewValue(false);
                }
            );
        } else {
            antiFlash(
                resolve => {
                    resolveFunction.current = () => resolve();
                },
                () => setNewValue(false),
                () => {}
            );
        }
    }, [value]);

    return newValue;
};

export default useAntiFlashingBoolean;
