import React from 'react';
import { useImmer, Updater } from 'use-immer';

import useModal from '@components/modals/hooks/useModal';

import ImageUpload from '@dsc/forms/customInputs/ImageUpload';
import TextInput from '@dsc/forms/textInputs/TextInput';
import Button from '@dsc/forms/buttons/Button';
import CreationThumbDisplay from '@components/lego/creation/CreationThumbDisplay';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { getRandomDefaultThumbImage } from '@core/utilities/constants/defaults';

import { CreationLevelState } from '@core/types/Creations';

type CreationLevelFormProps = {
    state: CreationLevelState;
    onChange: Updater<CreationLevelState>;
    background: string;
    title: string;
};

const CreationLevelForm: React.FC<CreationLevelFormProps> = ({
    state,
    onChange,
    background,
    title,
}) => {
    const [localState, setLocalState] = useImmer(state);

    const updateSlice = curriedStateSlice(setLocalState);

    const { closeModal } = useModal();

    const submit = () => {
        onChange(localState);
        closeModal();
    };

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                submit();
            }}
            className="creation-level-form"
        >
            <div className="creation-thumb-upload">
                Creation Image Select
                <ImageUpload
                    state={{
                        ...localState.image,
                        image: localState.image.image || getRandomDefaultThumbImage(title),
                    }}
                    setState={updateSlice('image')}
                    className="thumbnail-upload"
                    loaderSize={80}
                    small
                />
            </div>

            <div className="creation-icon">
                <CreationThumbDisplay
                    foreground={localState.image.image}
                    background={background}
                    title={title}
                />
            </div>

            <TextInput label="Title" value={localState.title} onChange={updateSlice('title')} />

            <TextInput
                label="Description"
                value={localState.description}
                onChange={updateSlice('description')}
                multiline
            />

            <footer>
                <Button type="small" onClick={submit}>
                    Submit
                </Button>
                <Button type="small" onClick={closeModal} className="cancel">
                    Cancel
                </Button>
            </footer>
        </form>
    );
};

export default CreationLevelForm;
