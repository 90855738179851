import {useContext} from 'react';

import {SidebarActionsContext} from '@web/ui/components/generic/context/SidebarContext';

const useSideDrawer = () => {
    const { hideSidebar, showSidebar, toggleSidebar } = useContext(SidebarActionsContext);

    const openDrawer = (type, options, size = 'shrink', id = null) =>
        showSidebar(type, null, null, size, options);

    const closeDrawer = () => hideSidebar();

    const toggleDrawer = (type, options, size = 'shrink', id = null) => {
        toggleSidebar(type, null, null, size, options, id);
    };

    return { openDrawer, closeDrawer, toggleDrawer };
};

export default useSideDrawer;
