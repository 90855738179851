import React from 'react';

const Hamburger: React.FC<{ className?: string }> = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 22 22">
        <line
            x1="2.75"
            y1="5.5"
            x2="19.25"
            y2="5.5"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <line
            x1="2.75"
            y1="11"
            x2="19.25"
            y2="11"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <line
            x1="2.75"
            y1="16.5"
            x2="19.25"
            y2="16.5"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export default Hamburger;
