import { useState } from 'react';
import _ from 'lodash';
import { areDependentQuestionsAnswered } from '~/wl-web/utilities/helpers/customFields/customFields.helpers';
import { CustomSignupFieldTypes } from '@shared/welibrary-graphql/types';

/**
 * @typedef {Object} ValuesObject
 * @property {string} _id
 * @property {string | string[]} values
 * @property {string} type
 * @property {string} comparator
 */

/**
 * @typedef {Object} CustomFieldsObject
 * @property {{ _id: string }} parentGroup
 * @property {string} manager
 * @property {ValuesObject[]} values
 */

/**
 * @typedef {Object} ProfileObject
 * @property {string} full_name
 * @property {string} picture
 * @property {string} short_bio
 */

/**
 * @typedef {Object} CustomSignupFieldsObject
 * @property {string} customText
 * @property {string} defaultComparator
 * @property {string} id
 * @property {number} index
 * @property {string[]} options
 * @property {string} prompt
 * @property {boolean} selectMultiple
 * @property {string} siblingId
 * @property {string} type
 */

/**
 * @typedef {Object} GroupObject
 * @property {boolean} currentUserIsAdmin
 * @property {boolean} currentUserIsMember
 * @property {ProfileObject} profile
 * @property {{ recommendations: boolean, customSignupFields: CustomSignupFieldsObject[] }} settings
 * @property {string} _id
 *

/**
 * React Hook for displaying optimzers
 * This will give you all the data/logic you need to display and update all optimizers from a list
 * of groups
 *
 * @param {GroupObject[]} groups List of groups to display the optimizers for
 * @param {{ customFields: CustomFieldsObject[] }} profile User profile containing their completed fields
 */
const useOptimizers = (groups, profile, isCardOptimize, initialOptimizerId) => {
    const [activeOptimizerId, setActiveOptimizerId] = useState(initialOptimizerId ?? ''); // active group

    // get currently active optimizer
    const activeOptimizerIndex = groups.findIndex(group => group._id === activeOptimizerId);

    /** @type {GroupObject | false} */
    let activeOptimizer = activeOptimizerIndex > -1 && groups[activeOptimizerIndex];

    let groupsList = [...groups];

    if (groupsList.length === 1 && !isCardOptimize) {
        [activeOptimizer] = groupsList;
        groupsList = [];
    }

    // remove active optimizer from groups list
    if (activeOptimizerIndex > -1) groupsList.splice(activeOptimizerIndex, 1);

    /**
     * Calculates the user's progress for an optimizer
     *
     * @param {string} groupId ID of optimizer's parent group
     * @return {number} percent complete (out of 100)
     */
    const calculatePercentage = groupId => {
        const group = groupsList.filter(group => group._id === groupId);

        const userCompletedFields = profile?.customFields?.filter(
            customFieldCluster =>
                customFieldCluster.parentGroup?._id === groupId ||
                groupId === customFieldCluster?.parentUrl ||
                groupId === customFieldCluster?.manager // to handle platform survey
        );

        const optimizeState = userCompletedFields?.[0]?.values?.reduce((acc, value) => {
            acc[value.id] = { value: value.values };
            return acc;
        }, {});

        // for some reason location fields are being doubled and also act strange....need to look at this separately
        // for now am filtering them out of the perecentage calculation
        const dedupedGroupSignupFields = _.uniqBy(
            group[0]?.settings?.customSignupFields
                ?.filter(
                    field =>
                        field.type !== CustomSignupFieldTypes.ProfileOrganization &&
                        field.type !== CustomSignupFieldTypes.ProfileLocation &&
                        field.type !== CustomSignupFieldTypes.ChooseRole && // choose role is deprecated
                        field.type !== CustomSignupFieldTypes.JoinGroup
                )
                ?.filter(field =>
                    areDependentQuestionsAnswered(
                        field,
                        optimizeState,
                        group[0]?.settings?.customSignupFields
                    )
                ),
            item => item.id
        );

        const completed =
            (userCompletedFields &&
                userCompletedFields[0]?.values?.filter(
                    field => field?.values?.length > 0 && field?.values?.some(value => !!value)
                )?.length) ||
            0;

        const total = (group && dedupedGroupSignupFields?.length) || completed + 1;

        return dedupedGroupSignupFields?.length === 0 ? 100 : (completed / total) * 100;
    };

    return {
        activeOptimizer,
        setActiveOptimizerId,
        groupsList,
        calculatePercentage,
    };
};

export default useOptimizers;
