import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';

import { useCopyTextToClipboard } from '@components/generic/hooks/useCopyTextToClipboard';

import { canShare } from '@core/utilities/shareHelpers';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.toast';

export const useShareLink = () => {
    const { t } = useTranslation();
    const { copyTextToClipboard } = useCopyTextToClipboard();

    const shareLink = async (
        url: string,
        title: string = '',
        text: string = '',
        successMessage: string = t(
            `common:${localNamespace}.link_copied`,
            'Link copied to clipboard!'
        )
    ) => {
        const sharePackage = { url };

        const uA = navigator?.userAgent;
        const vendor = navigator?.vendor;
        // https://stackoverflow.com/questions/52204690/how-detect-safari-browser-only-on-desktop
        const isDesktopSafari =
            /Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA);

        if (canShare()) {
            const ua = window.navigator.userAgent;
            const isiOS = Capacitor.getPlatform() === 'ios';
            const postText = title || text;
            const message = isiOS ? `${title || text} ${url || ''}` : postText;
            const shareUrl = isiOS ? null : url;

            const shareOptions = {
                url: shareUrl,
                dialogTitle: 'Pick an app',
                text: message,
                title: text,
            };

            try {
                const { activityType } = await Share.share(shareOptions);
                logger.info(`[Capacitor.Share] Shared to app: ${activityType}`);
            } catch (err) {
                logger.warn(`[Capacitor.Share] Failed with message: ${message}`);
            }
            // If sharing feature exists on platform browser + it is NOT Safari ON Desktop
        } else if (window.navigator?.share && !isDesktopSafari) {
            try {
                window.navigator
                    .share(sharePackage)
                    .then(() => logger.debug('Successfully shared. ', successMessage));
            } catch (error) {
                logger.warn('Sharing failed... Copying link to clipboard', error);
                copyTextToClipboard(url ?? title ?? text, successMessage);
            }
        } else {
            copyTextToClipboard(url ?? title ?? text, successMessage);
        }
    };

    return { shareLink };
};

export { useShareLink as default };
