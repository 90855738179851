import React from 'react';

import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-spinner.json';
import PaperPlaneAnimation from '@components/generic/loading/lotties/paperplane.json';

const ModalLoading: React.FC<{
    message?: string;
    textClassName?: string;
    height?: number;
    width?: number;
}> = ({ message, textClassName, height = 200, width = 200 }) => (
    <figure className="modal-loading">
        <LottieLoading
            customStyle={{ backgroundColor: 'transparent', borderRadius: '50%' }}
            height={height}
            width={width}
            lottieData={LottieAnimation}
        />
        <figcaption className={textClassName}>{message}</figcaption>
    </figure>
);

export default ModalLoading;

export const NewMessageLoading: React.FC<{ message?: string }> = ({ message }) => (
    <figure className="modal-loading new-message">
        <LottieLoading
            customStyle={{ backgroundColor: 'transparent', borderRadius: '50%', opacity: '1' }}
            height={160}
            width={160}
            lottieData={PaperPlaneAnimation}
        />
    </figure>
);
