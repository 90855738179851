import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

import GroupSummaryCard from '@web/ui/components/card/summarycard/GroupSummaryCard.js';
import PersonalInfoQuestions from '@web/ui/pages/signup/PersonalInfoQuestions';
import Paragraph from '@web/ui/components/group/customsignup/Paragraph';
import Dropdown from '@web/ui/components/group/customsignup/Dropdown';
import ConsentBox from '@web/ui/components/group/customsignup/ConsentBox';
import Signature from '@components/group/customsignup/signature';
import Description from '@web/ui/components/group/customsignup/Description';

import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

import {
    SIGNUP_FIELD_TYPE_CHOOSE_ROLE,
    SIGNUP_FIELD_TYPE_CONSENT_BOX,
    SIGNUP_FIELD_TYPE_DROPDOWN,
    SIGNUP_FIELD_TYPE_JOIN_GROUP,
    SIGNUP_FIELD_TYPE_PARAGRAPH,
    SIGNUP_FIELD_TYPE_SHORTANSWER,
    SIGNUP_FIELD_TYPE_SIGNATURE,
    SIGNUP_FIELD_TYPE_DESCRIPTION,
} from '@core/utilities/constants/library_settings';

import { camelize } from '@helpers/string.helpers';
import { areDependentQuestionsAnswered } from '@helpers/customFields/customFields.helpers';
import GroupSelect from '../../components/group/customsignup/GroupSelect';

const CustomStep = ({
    state,
    library,
    currentStep,
    maxSteps,
    membershipGroup,
    signupChunk,
    title,
    info,
    lightBackground,
    showPrompt,
    showPersonalInformation,
    signup,
    signupState,
    setSignupState,
    handleChange,
    handleCustomChange,
    isGroupQuestionStep,
}) => {
    const flags = useFlags();
    const enableJuriesRequest = flags.enableJuriesRequest;
    const { t } = useTranslation();
    const formsRef = useRef(null);

    const { shortCode: paramShortCode } = useParams();
    const { defaultGroupCode, disableInlinePrefilledSignupLink } = getWhitelabelPublicSettings();

    const [viewedFields, setViewedFields] = useState(
        signupChunk
            .filter(field => areDependentQuestionsAnswered(field, state, signupChunk))
            .map(field => field.id)
    );

    const resetBodyScroll = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    useEffect(() => {
        if (formsRef?.current) formsRef.current.onfocusout = () => resetBodyScroll();
    }, [formsRef?.current]);

    const hideDefaultGroupDisplayCard = !(
        (disableInlinePrefilledSignupLink && paramShortCode === defaultGroupCode) ||
        !isGroupQuestionStep
    );

    const displayCard =
        membershipGroup && hideDefaultGroupDisplayCard ? (
            <GroupSummaryCard group={membershipGroup} />
        ) : (
            <div />
        );

    const markFieldAsViewed = (name) => {
        setViewedFields(prev => [...prev, name]);
    };

    const wrapWithHighlight = (name, component) => {
        const isHighlighted = !viewedFields.includes(name);
        return (
            <div
                className={`custom-signup-item ${isHighlighted ? 'highlighted' : ''}`}
                onFocus={() => markFieldAsViewed(name)}
                onClick={() => markFieldAsViewed(name)}
            >
                {component}
            </div>
        );
    };

    const createSelectField = (name, placeholder, values, type, translatedOptions) => {
        const options = values.map((option, index) => {
            return _.isObject(option) ? (
                <option key={option.value} value={option.value}>
                    {translatedOptions ? translatedOptions[index] : option.label}
                </option>
            ) : (
                <option key={option} value={option}>
                    {translatedOptions ? translatedOptions[index] : option}
                </option>
            );
        });

        return wrapWithHighlight(
            name,
            <div>
                {showPrompt && <div className="custom-signup-label">{placeholder}</div>}
                <div className="signup-select-field-wrapper" key={`${name}`}>
                    <select
                        value={state[name]?.value || ''}
                        onChange={e => {
                            handleChange(e, type);
                            markFieldAsViewed(name);
                        }}
                        id={name}
                        name={name}
                        type={type}
                        className={`signup-select-field ${lightBackground ? 'light-background-select' : 'b-select'
                            }`}
                    >
                        {showPrompt ? (
                            <option value="">-{t('common:global.select')}-</option>
                        ) : (
                            <option value="">{placeholder}</option>
                        )}
                        {options}
                    </select>
                </div>
            </div>
        );
    };

    const signupFields =
        signupChunk?.reduce((fields, field) => {
            if (!field || !areDependentQuestionsAnswered(field, state, signupChunk)) return fields;

            const name = field.id || camelize(field.prompt + field.index);

            if (field.type === SIGNUP_FIELD_TYPE_SHORTANSWER) {
                return [
                    ...fields,
                    wrapWithHighlight(
                        name,
                        <Paragraph
                            key={name}
                            name={name}
                            field={field}
                            value={state[name]?.value}
                            onChange={e => {
                                handleCustomChange(name, e.target.value, field.type);
                                markFieldAsViewed(name);
                            }}
                            showPrompt={showPrompt}
                            lightBackground={lightBackground}
                        />
                    ),
                ];
            }

            if (field.type === SIGNUP_FIELD_TYPE_PARAGRAPH) {
                return [
                    ...fields,
                    wrapWithHighlight(
                        name,
                        <Paragraph
                            key={name}
                            name={name}
                            field={field}
                            value={state[name]?.value}
                            onChange={e => {
                                handleCustomChange(name, e.target.value, field.type);
                                markFieldAsViewed(name);
                            }}
                            maxLength={5000}
                            showPrompt={showPrompt}
                            lightBackground={lightBackground}
                        />
                    ),
                ];
            }

            if (field.type === SIGNUP_FIELD_TYPE_DROPDOWN) {
                return [
                    ...fields,
                    wrapWithHighlight(
                        name,
                        <Dropdown
                            key={name}
                            name={name}
                            field={field}
                            showPrompt={showPrompt}
                            handleCustomChange={(name, value, type) => {
                                handleCustomChange(name, value, type);
                                markFieldAsViewed(name);
                            }}
                            lightBackground={lightBackground}
                            state={state}
                        />
                    ),
                ];
            }

            if (field.type === SIGNUP_FIELD_TYPE_CHOOSE_ROLE) {
                if (library?.library_settings?.customRoles) {
                    const customRoleOptions = library.library_settings.customRoles.map(role => ({
                        value: role.url,
                        label: role.name,
                    }));

                    return [
                        ...fields,
                        createSelectField(name, field.prompt, customRoleOptions, field.type),
                    ];
                }
            }

            if (field.type === SIGNUP_FIELD_TYPE_CONSENT_BOX) {
                return [
                    ...fields,
                    wrapWithHighlight(
                        name,
                        <ConsentBox
                            key={name}
                            value={!!state[name]?.value?.[0]}
                            onClick={value => {
                                handleCustomChange(name, value ? ['accept'] : [''], field.type);
                                markFieldAsViewed(name);
                            }}
                            prompt={field.prompt}
                            link={field.link}
                            className={lightBackground ? '' : 'dark-background'}
                            required={field.required}
                        />
                    ),
                ];
            }

            if (field.type === SIGNUP_FIELD_TYPE_JOIN_GROUP) {
                return [
                    ...fields,
                    wrapWithHighlight(
                        name,
                        <GroupSelect
                            key={name}
                            field={field}
                            membershipGroupId={membershipGroup._id}
                            onInteraction={() => markFieldAsViewed(name)}
                        />
                    ),
                ];
            }

            if (field.type === SIGNUP_FIELD_TYPE_SIGNATURE && enableJuriesRequest) {
                return [
                    ...fields,
                    wrapWithHighlight(
                        name,
                        <Signature
                            value={state[name]?.value}
                            key={name}
                            name={name}
                            field={field}
                            showPrompt={showPrompt}
                            handleCustomChange={(name, value, type) => {
                                handleCustomChange(name, value, type);
                                markFieldAsViewed(name);
                            }}
                            lightBackground={lightBackground}
                            state={state}
                        />
                    ),
                ];
            }

            if (field.type === SIGNUP_FIELD_TYPE_DESCRIPTION && enableJuriesRequest) {
                return [
                    ...fields,
                    wrapWithHighlight(
                        name,
                        <Description
                            field={field}
                            key={name}
                            onInteraction={() => markFieldAsViewed(name)}
                        />
                    ),
                ];
            }

            return fields;
        }, []) ?? [];

    return (
        <div className="login-step w-slide">
            <div
                className="login-step-content"
                style={lightBackground ? { paddingTop: '20px' } : {}}
            >
                {!!currentStep && !!maxSteps && (
                    <div className="step-counter">
                        {`${currentStep + 1} ${t('common:global.prepositions.of')} ${maxSteps + 1}`}
                        <h4 className="signup-heading">
                            {t('common:imports.wlWeb.ui.pages.signup.title')}
                        </h4>
                    </div>
                )}

                {(title || info) && (
                    <div className="form-title-container">
                        <div
                            className={`form-title ${membershipGroup && hideDefaultGroupDisplayCard
                                ? 'form-title-adj'
                                : ''
                                }`}
                            style={lightBackground ? { color: 'black' } : {}}
                        >
                            {title ||
                                t('common:imports.wlWeb.ui.pages.signup.customStep.title_fallback')}
                        </div>
                        {displayCard}
                    </div>
                )}

                {signupState?.errorMessage?.customFields && (
                    <h5 className="err-msg">{signupState.errorMessage.customFields}</h5>
                )}

                {showPersonalInformation && (
                    <PersonalInfoQuestions
                        state={state}
                        signupState={signupState}
                        setSignupState={setSignupState}
                        handleCustomChange={(name, value, type) => {
                            handleCustomChange(name, value, type);
                            markFieldAsViewed(name);
                        }}
                        signupChunk={signupChunk}
                        signup={signup}
                    />
                )}

                <div data-ix="show-content-onslide" className="animated animatedFadeInUp fadeInUp">
                    <div
                        ref={formsRef}
                        className="accordian-container w-clearfix auto-height full-opacity-trans"
                    >
                        {signupFields}
                    </div>
                    <div className="columns-container">
                        <div className="half-col" />
                        <div className="half-col last-col" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomStep;