import React from 'react';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

const LinkTilePreview = ({ source }) => {
    const renderLinkTiles = source?.aliases?.map(alias => {
        return <LinkTileBlock aliasItem={alias} />;
    });
    return <div className="tile-post-wrapper">{renderLinkTiles}</div>;
};

let linkTileBgStyle = { backgroundSize: 'cover', backgroundPosition: '50% 50%' };
const LinkTileBlock = ({ aliasItem }) => {
    const { title, item } = aliasItem;

    let mediaThumb;
    if (item.__typename === 'Group') {
        mediaThumb = item?.profile?.coverPhoto || item?.profile?.picture;
    }

    if (item.__typename === 'ContentCard') {
        if (item.type === 'chapter') {
            mediaThumb = item?.media?.icon || item?.media?.thumb || item?.reference?.coverImage;
        }
        if (item.type === 'book') {
            mediaThumb = item?.media?.thumb || item?.reference?.coverImage;
        }
    }
    if (aliasItem.color && aliasItem.color[0] === '#') {
        linkTileBgStyle = {
            backgroundColor: aliasItem.color,
            backgroundImage: 'unset',
        };
    }

    if (aliasItem.thumb) mediaThumb = aliasItem.thumb;

    const [src, srcSet] = getBackgroundImageSetForUrl(
        mediaThumb || getRandomDefaultCoverImage(title)
    );

    return (
        <div className="tile-block-wrapper">
            <div className="tile-block-container">
                <div className="tile-link-block activelink w-inline-block">
                    <StyledBackgroundImage
                        className="tile-link-block-bg"
                        src={src}
                        srcSet={srcSet}
                        style={linkTileBgStyle}
                    />
                    <div className="tile-link-block-content activelinkblock">
                        <div className="tile-link-block-text">
                            <div className="tile-link-block-tile">{title}</div>
                            <div />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinkTilePreview;
