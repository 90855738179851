import React from 'react';
import { isEqual } from 'lodash';

import MobileMenuList from '@components/modals/MobileMenuList';

import useModal from '@components/modals/hooks/useModal';

import { MenuListItem } from '@core/types/Menus';
import { Scalars } from '@shared/welibrary-graphql/types';

const getDisplayValue = <T extends Scalars['Range'] = Scalars['Range']>(
    options: Array<{ value: T; display: string }>,
    value?: T,
    prompt?: string
) => {
    return options.find(option => isEqual(option.value, value))?.display ?? prompt ?? '';
};

type CourseFormRangeSelectProps<T extends Scalars['Range'] = Scalars['Range']> = {
    title?: string;
    prompt?: string;
    value?: T;
    onChange: (value: T) => void;
    options: Array<{ value: T; display: string }>;
};

const CourseFormRangeSelect = <T extends Scalars['Range'] = Scalars['Range']>({
    title,
    prompt = '',
    value,
    onChange,
    options,
}: CourseFormRangeSelectProps<T>) => {
    const { newModal } = useModal();

    const optionsList: MenuListItem[] = options.map(option => ({
        id: option.display,
        title: option.display,
        onClick: () => onChange(option.value),
        selected: isEqual(value, option.value),
    }));

    return (
        <>
            {title && <p>{title}</p>}
            <button
                type="button"
                onClick={() =>
                    newModal(<MobileMenuList menuList={optionsList} />, {
                        className: 'no-min-height',
                        widen: true,
                        addShadow: true,
                    })
                }
            >
                <span>{getDisplayValue(options, value, prompt)}</span>
                <span role="presentation" className="caret">
                    &#x25BE;{/* (▾) */}
                </span>
            </button>
        </>
    );
};

export default CourseFormRangeSelect;
