import { SOCIAL_ACTIONS } from '@core/utilities/constants/user';

const localNamespace = 'imports.wlWeb.ui.components.profile.profileHeader';

export const getConnectActionText = (user, t) => {
    const { REQUEST_CONNECTION, ACCEPT_CONNECTION, CANCEL_REQUEST_CONNECTION, DISCONNECT } =
        SOCIAL_ACTIONS;
    const { socialActionsAllowed } = user;

    if (socialActionsAllowed.includes(REQUEST_CONNECTION)) {
        return t(`common:${localNamespace}.connections.connect`);
    }
    if (socialActionsAllowed.includes(ACCEPT_CONNECTION)) {
        return t(`common:${localNamespace}.connections.accept`);
    }
    if (socialActionsAllowed.includes(CANCEL_REQUEST_CONNECTION)) {
        return t(`common:${localNamespace}.connections.cancel`);
    }
    if (socialActionsAllowed.includes(DISCONNECT)) {
        return t(`common:${localNamespace}.connections.connected`);
    }

    return null;
};

const getSocialUrl = (
    social: 'facebook' | 'twitter' | 'instagram' | 'linkedin',
    urlOrUsername: string
) => {
    const fullSocialUrl = `www.${social}.com`;
    const shortSocialUrl = `${social}.com`;

    urlOrUsername = urlOrUsername.trim();
    if (urlOrUsername.toLowerCase().includes(shortSocialUrl)) {
        return urlOrUsername;
    }

    const usernameNoAt = urlOrUsername.startsWith('@') ? urlOrUsername.substring(1) : urlOrUsername;

    if (social === 'linkedin') {
        return `//${fullSocialUrl}/in/${usernameNoAt}`;
    }
    return `//${fullSocialUrl}/${usernameNoAt}`;
};

export const getFacebookUrl = (urlOrUsername: string) => {
    return getSocialUrl('facebook', urlOrUsername);
};

export const getTwitterUrl = (urlOrUsername: string) => {
    return getSocialUrl('twitter', urlOrUsername);
};

export const getInstagramUrl = (urlOrUsername: string) => {
    return getSocialUrl('instagram', urlOrUsername);
};

export const getLinkedinUrl = (urlOrUsername: string) => {
    return getSocialUrl('linkedin', urlOrUsername);
};

export const getNameWithLastNameFirst = (name: string) => {
    if (!name) return name; // Safety

    let nameWithLastNameFirst: string;
    const splitName = name.trim().split(' ');
    const numNames = splitName.length;

    if (numNames === 1) {
        nameWithLastNameFirst = name;
    } else {
        const lastName = splitName[numNames - 1];
        const otherNames = splitName.slice(0, numNames - 1);
        nameWithLastNameFirst = `${lastName}, ${otherNames.join(' ')}`;
    }

    return nameWithLastNameFirst;
};
