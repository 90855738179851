import { useImmer } from 'use-immer';
import { getClientTzSelect } from '@core/utilities/constants/events';
import { EventListingFormState } from '@core/types/EventListing';
import { LocationTypeEnum } from '@core/types/EventGroup';
import { Maybe, ContentCard } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';
import { VIEW_PERMISSION_PUBLIC } from '@core/utilities/constants/roles';

/**
 * Builds up all the necessary state for displaying Event Listing Form, optionally taking in an existing
 * Event Listing to edit
 */
const useEventListingFormState = (card?: Maybify<ContentCard>) => {
    // Before the omnibus PR eventOverviewDetails did not have eventLocationSettings
    const isOldEventListing = card?.eventOverviewDetails?.eventLocationSettings === null;

    const getListingTitle = card => {
        if (isOldEventListing) return card?.title;
        return card?.eventOverviewDetails?.title;
    };

    const getListingDesc = card => {
        if (isOldEventListing) return card?.description;
        return card?.eventOverviewDetails?.description;
    };

    // Format old card location data into new card format data

    const getLocationType = card => {
        if (isOldEventListing) {
            const { eventOverviewDetails } = card;
            const isVirtual = eventOverviewDetails?.link && eventOverviewDetails?.link !== '';
            const inPerson = eventOverviewDetails?.location?.formatted_address !== '';
            if (isVirtual && !inPerson) {
                return LocationTypeEnum.virtual;
            }
            if (!isVirtual && inPerson) {
                return LocationTypeEnum.inPerson;
            }
            if (isVirtual && inPerson) {
                return LocationTypeEnum.both;
            }
        }
        return card?.eventOverviewDetails?.eventLocationSettings?.type;
    };

    const getLocationLink = card => {
        if (isOldEventListing) return card?.eventOverviewDetails?.link;
        return card?.eventOverviewDetails?.eventLocationSettings?.link;
    };

    const getFormattedAddy = card => {
        if (isOldEventListing) return card?.eventOverviewDetails?.location?.formatted_address;
        return card?.eventOverviewDetails?.eventLocationSettings?.venue?.location
            ?.formatted_address;
    };

    return useImmer<EventListingFormState>({
        overviewFormState: {
            title: getListingTitle(card) ?? '',
            description: getListingDesc(card) ?? '',
            thumbnail: card?.media?.thumb ?? '',
            coverImage: card?.media?.thumb ?? '',
            rsvpEnabled: true,
            eventLocationSettings: {
                type: getLocationType(card) ?? LocationTypeEnum.inPerson,
                venue: {
                    name: card?.eventOverviewDetails?.eventLocationSettings?.venue?.name ?? '',
                    link: card?.eventOverviewDetails?.eventLocationSettings?.venue?.link ?? '',
                    location: {
                        formatted_address: getFormattedAddy(card) ?? '',
                        location: {
                            latitude:
                                card?.eventOverviewDetails?.eventLocationSettings?.venue?.location
                                    ?.location?.latitude ?? 0,
                            longitude:
                                card?.eventOverviewDetails?.eventLocationSettings?.venue?.location
                                    ?.location?.longitude ?? 0,
                        },
                        city:
                            card?.eventOverviewDetails?.eventLocationSettings?.venue?.location
                                ?.city ?? '',
                        state:
                            card?.eventOverviewDetails?.eventLocationSettings?.venue?.location
                                ?.state ?? '',
                        country:
                            card?.eventOverviewDetails?.eventLocationSettings?.venue?.location
                                ?.country ?? '',
                        postal:
                            card?.eventOverviewDetails?.eventLocationSettings?.venue?.location
                                ?.postal ?? '',
                    },
                },
                link: getLocationLink(card) ?? '',
                rsvpLink: card?.eventOverviewDetails?.rsvpLink ?? '',
                platformName: card?.eventOverviewDetails?.eventLocationSettings?.platformName ?? '',
            },
        },
        dateAndTimeState: {
            dates: [
                {
                    startDate:
                        card?.eventOverviewDetails?.dates?.[0]?.startDate ??
                        new Date().toISOString(),
                    endDate:
                        card?.eventOverviewDetails?.dates?.[0]?.endDate ?? new Date().toISOString(),
                },
            ],
            startTime: card?.eventOverviewDetails?.startTime ?? null,
            endTime: card?.eventOverviewDetails?.endTime ?? null,
            displayStartTime: card?.eventOverviewDetails?.displayStartTime ?? true,
            displayEndTime: card?.eventOverviewDetails?.displayEndTime ?? true,
            timeZone: {
                name: card?.eventOverviewDetails?.timeZone?.name ?? getClientTzSelect().value,
            },
        },
        previewFormState: {
            privacyLevel: card?.library_settings?.protectionLevel ?? VIEW_PERMISSION_PUBLIC,
        },
        cardSettingsState: {
            protectionLevel: card?.library_settings?.protectionLevel ?? VIEW_PERMISSION_PUBLIC,
        },
    });
};

export default useEventListingFormState;
