import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Maybify } from '@core/types/Utilities';
import useModal from '@components/modals/hooks/useModal';

import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/success-checkmark.json';
import { EDIT_GROUP_TABS } from '@components/content/newpost/forms/EventGroup/EventGroupCustomSignup';
import EventGroupFormMerchantAccountReminder from '@components/content/newpost/forms/EventGroup/EventGroupFormMerchantAccountReminder';

import { Group } from '@shared/welibrary-graphql/types';

type EventGroupFinishStepProps = {
    title: string;
    group: Maybify<Group>;
    onClick?: (group?: Maybify<Group>, intialTab?: string) => void;
};

const localNamespace = 'imports.wlWeb.ui.components.optimize';
const eventNamespace = 'imports.wlWeb.ui.components.eventGroup';

const EventGroupFinishStep: React.FC<EventGroupFinishStepProps> = ({
    title,
    group,
    onClick = () => { },
}) => {
    const { t } = useTranslation();

    const { closeModal } = useModal();

    const history = useHistory();

    const handleShareEvent = () => {
        history.push(`/g/${group?._id}/share`); 
    }

    const optimizeTxt = t(
        `common:${localNamespace}.event_group_optimize_prompt`,
        ' If you would like for your event to be recommended to others, please click the button below to optimize your event by answering any parent group surveys*. Note: there must be at least one parent group with matchmaking enabled and surveys to fill out.'
    );

    const createSurveyTxt = t(
        `common:${localNamespace}.event_group_create_survey_prompt`,
        'If you would like to create your own survey for this event that will be shown to anyone who joins, please click the button below.*'
    );

    const tinyText = t(
        `common:${localNamespace}.event_group_survey_access_text`,
        'This also can be accessed anytime from the dashboard menu'
    );

    // Only show notify panel if the event is free
    //   Paid events shouldn't send out notifications until after the Stripe account has been activated
    const relativeGroupUrl = `/g/${group?._id}${group.paymentSettings.isGroupMembershipPaid ? '' : '?showNotifyPanel=true'
        }`;

    return (
        <section className="event-group-finish-step">
            <header>
                <section className="header-info-block">
                    <section className="header-title">
                        <h1>{title}</h1>
                    </section>
                    <button className="cancel-btn" type="button" onClick={closeModal}>
                        {t('common:cancel', 'Cancel')}
                    </button>
                </section>
            </header>

            <section className="success-message event-group">
                <LottieLoading
                    height={110}
                    width={110}
                    lottieData={LottieAnimation}
                    customStyle={{ opacity: 1 }}
                    isPaused
                    loop={false}
                />

                <h1>{t('common:success', 'Success!')}</h1>
                <p>{t('common:your_event_has_been_published', 'Your event has been published.')}</p>

                <button className="animate-grow bg-[#0094f6] h-[40px] text-white text-[14px] font-medium rounded-[25px] px-[20px]" onClick={handleShareEvent}>Share Event</button>

                {group.paymentSettings.isGroupMembershipPaid && (
                    <EventGroupFormMerchantAccountReminder group={group} />
                )}

                <article className="event-optimize-section">
                    <h4>
                        {t(`common:${eventNamespace}.optional_next_steps`, 'Optional Next Steps')}:
                    </h4>
                    <p>{optimizeTxt}</p>
                    <button
                        type="button"
                        onClick={() => onClick?.(group, EDIT_GROUP_TABS.OPTIMIZE)}
                        className="std-btn animate-grow noselect"
                    >
                        {t('common:optimize', 'Optimize')}
                    </button>

                    <p className="small-txt">
                        <small>*{tinyText}</small>
                    </p>

                    <p>{createSurveyTxt}</p>
                    <button
                        type="button"
                        onClick={() => onClick?.(group, EDIT_GROUP_TABS.SIGNUP_FORMS)}
                        className="std-btn animate-grow noselect"
                    >
                        {t(`common:${localNamespace}.create_survey`, 'Create Survey')}
                    </button>

                    <p className="small-txt">
                        <small>*{tinyText}</small>
                    </p>
                </article>
            </section>

            <footer>
                <button type="button" onClick={closeModal}>
                    {t('common:close', 'Close')}
                </button>

                <Link to={relativeGroupUrl}>
                    {t(`common:${eventNamespace}.view_event`, 'View Event')}
                </Link>
            </footer>
        </section>
    );
};

export default EventGroupFinishStep;
