import React from 'react';
import { Helmet } from 'react-helmet-async';

import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

const SiteHeadMeta: React.FC = () => {
    const { siteName, favicon, metaInfo } = getWhitelabelPublicSettings();

    return (
        <Helmet>
            <title>{siteName}</title>
            <link rel="shortcut icon" type="image/x-icon" href={favicon} />
            <meta name="description" content={metaInfo?.description} />
            <meta name="ogtitle" property="og:title" content={metaInfo?.title} />
            <meta name="ogdescription" property="og:description" content={metaInfo?.description} />
            <meta property="og:image" content={metaInfo?.image} />
            <meta property="og:url" content={metaInfo?.url} />
        </Helmet>
    );
};

export default SiteHeadMeta;
