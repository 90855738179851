import React from 'react';
import {useTranslation} from 'react-i18next';
import {getRandomDefaultCoverImage} from '@core/utilities/constants/defaults';

const localNamespace = 'imports.wlWeb.ui.components.card.mediaCard.bodyItems.quoteItem';

export default function (props) {
    let backgroundStyle;
    const { t } = useTranslation();

    if (props.card.media.thumb) {
        backgroundStyle = {
            background: `url(${props.card.media.thumb})`,
        };
    } else {
        backgroundStyle = {
            background: `url(${getRandomDefaultCoverImage(props.card.body)})`,
        };
    }

    return (
        <div className="mc-media-item galleryitem">
            <div className="mc-media-item-bg" style={backgroundStyle}></div>
            <div className="mc-media-item-content mc-gallerytext-content">
                <div className="gallery-text-content">
                    <div className="mc-post-title media-title-2">
                        {t(`common:${localNamespace}.card.body`, { cardBody: props.card.body })}
                    </div>
                </div>
                <div className="mc-source-container mc-gallery-text-container align-flex-start">
                    <div className="thumbnail smaller">
                        <div className="square-spacer" />
                    </div>
                    <div>
                        <a href="#" className="white-link display-text-inline">
                            {t(`common:${localNamespace}.card.authorName`, {
                                authorName: props.card.author.profile.full_name,
                            })}
                        </a>
                        <div className="display-text-inline">•</div>
                        <div className="display-text-inline">2m</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
