import React from 'react';

import { useGetProductByIdQuery } from '@shared/welibrary-graphql/user/queries.hook';

import Checkout from '@web/ui/components/card/Shopping-Cart/Checkout';

import { Group } from '@shared/welibrary-graphql/types';

const CheckoutWithProductQuery: React.FC<{ group: Group; productId: string; quantity: number }> = ({
    group,
    productId,
    quantity = 1,
}) => {
    const { data, loading } = useGetProductByIdQuery({
        variables: {
            _id: productId,
        },
        fetchPolicy: 'no-cache',
        skip: !group._id,
    });

    if (loading) return <div>Loading...</div>;

    return <Checkout group={group} product={data?.getProduct} quantity={quantity} />;
};

export default CheckoutWithProductQuery;
