import React from 'react';

import TreasureResources from '@components/lego/resource/TreasureResources';

import { Resource } from '@shared/welibrary-graphql/types';

const TreasureResourcesTab = React.forwardRef<HTMLElement, { resources: Record<Resource, number> }>(
    function TreasureResourcesTab({ resources }, ref) {
        return (
            <section className="treasures-tab treasure-resources-tab" ref={ref}>
                <div className="treasure-resources-body">
                    <TreasureResources resources={resources} />
                </div>
            </section>
        );
    }
);

export default TreasureResourcesTab;
