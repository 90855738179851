import React from 'react';

type YoutubeEmbedProps = {
    attrs: any;
    isSelected: boolean;
};

/* This component is passed into the Outline editor instance as an optional prop used for
displaying Youtube embeds within the editor. Note to display the youtube embed in
the markdown parser output the markdown processesor must have rules to handle rendering the embed

Usage example with editor component:
            <RichMarkdownEditor
                onChange={handleTextAreaChange}
                id="richmarkdown-editor"
                uploadImage
                participants={searchResults}
                noMobileMention
                onMentionInput={setSearchString}
                embeds={[
                    {
                        title: 'YouTube',
                        keywords: 'youtube video tube google',
                        icon: () => (
                            <img
                                alt="Youtube embed"
                                src="https://upload.wikimedia.org/wikipedia/commons/7/75/YouTube_social_white_squircle_%282017%29.svg"
                                width={24}
                                height={24}
                            />
                        ),
                        matcher: url => {
                            return url.match(
                                /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([a-zA-Z0-9_-]{11})$/i
                            );
                        },
                        component: YoutubeEmbed,
                    },
                ]}
                autoFocus
            />
*/
const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ attrs, isSelected }) => {
    const videoId = attrs.matches[1];
    return (
        <div className="video-container">
            <iframe
                title="Youtube"
                width="560"
                height="315"
                className={isSelected ? 'ProseMirror-selectednode' : ''}
                src={`https://www.youtube.com/embed/${videoId}?modestbranding=1`}
            />
        </div>
    );
};

export default YoutubeEmbed;
