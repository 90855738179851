import React from 'react';
import { useTranslation } from 'react-i18next';

import IntroContent from '@web/ui/components/generic/IntroContent';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import GroupMemberItem from '@web/ui/components/group/groupdashboard/GroupMembers/GroupMemberItem';
import MobileMenuList from '@web/ui/components/modals/MobileMenuList';

import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';

import { Group, Maybe, PaginatedUsers, User } from '@shared/welibrary-graphql/types';
import { MenuListItem } from '@core/types/Menus';
import { ModalTypes } from '@core/types/Modals';

import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/square-loader.json';

type GiftedMembersListProps = {
    group: Group;
    groupMembers?: Maybe<PaginatedUsers>;
    onNetworkSearchAction: () => void;
    canUserInviteOthers: Maybe<string | boolean | undefined>;
    handleLinkSharing: () => void;
};

const GiftedMembersList = React.forwardRef<HTMLUListElement, GiftedMembersListProps>(
    (
        { group, groupMembers, onNetworkSearchAction, canUserInviteOthers, handleLinkSharing },
        ref
    ) => {
        const { t } = useTranslation();
        const { newModal } = useModal({ desktop: ModalTypes.None });

        const localNamespace =
            group?.subtype === 'event'
                ? 'imports.wlWeb.ui.components.group.eventDashboard'
                : 'imports.wlWeb.ui.components.group.groupDashboard';

        const declaration =
            group?.subtype === 'event'
                ? t('global.introContentErr.no_attendees.declaration')
                : t('global.introContentErr.no_members.declaration');
        const instruction =
            group?.subtype === 'event'
                ? t('global.introContentErr.no_attendees.instruction')
                : t('global.introContentErr.no_members.instruction');

        const { currentUser } = useCurrentUser();

        const openQuickMenu = (items: MenuListItem[]) =>
            newModal(<MobileMenuList menuList={items} showIcons />, { className: 'no-min-height' });

        const getQuickMenuActionsForMember = (source: Maybe<User>) => {
            let actions: MenuListItem[] = [];
            return actions;
        };

        const inviteText = t(`common:${localNamespace}.invite_members`);

        return (
            <ul>
                <LoadingLine isLoading={false} />
                {(groupMembers?.results?.length ?? 0) > 0 ? (
                    groupMembers?.results.map((member, currentIndex) => {
                        return (
                            <GroupMemberItem
                                key={member?._id}
                                ref={
                                    currentIndex === groupMembers?.results?.length - 1
                                        ? ref
                                        : undefined
                                }
                                group={group}
                                customClass="group-member"
                                source={member}
                                connected
                                showEmail={false}
                                loggedInUser={currentUser}
                                getExtraQuickMenuOptions={getQuickMenuActionsForMember}
                                openQuickMenu={(items: MenuListItem[]) => openQuickMenu(items)}
                            />
                        );
                    })
                ) : (
                    <IntroContent
                        declaration={declaration}
                        instruction={instruction}
                        type="nocontent"
                    >
                        {canUserInviteOthers && (
                            <button
                                onClick={handleLinkSharing}
                                type="button"
                                className="group-members-invite-link-btn"
                            >
                                {inviteText}
                            </button>
                        )}
                    </IntroContent>
                )}
                {groupMembers?.hasMore && (
                    <div className="flex-center">
                        <LottieLoading height={50} width={300} lottieData={LottieAnimation} />
                    </div>
                )}
            </ul>
        );
    }
);

export default GiftedMembersList;
