import React from 'react';

import Slider from '@dsc/forms/customInputs/Slider';

import { Maybify } from '@core/types/Utilities';
import { SurveyQuestion } from '@shared/welibrary-graphql/types';

type SurveyEmotionalSliderProps = {
    state: number;
    setState: (value: number) => void;
    question: Maybify<SurveyQuestion>;
    className?: string;
    disabled?: boolean;
    viewOnly?: boolean;
};

const SLIDER_CLASSES = ['', 'sad', 'middle-sad', 'middle', 'middle-happy', 'happy'];

const SurveyEmotionalSlider: React.FC<SurveyEmotionalSliderProps> = ({
    state,
    setState,
    question,
    className = '',
    disabled = false,
    viewOnly = false,
}) => {
    return (
        <section className={`survey-question-emotional-slider ${className}`}>
            {!viewOnly && <p>{question.prompt}</p>}
            <Slider
                value={state}
                onChange={setState}
                min={1}
                max={5}
                className={`slider ${SLIDER_CLASSES[state] ?? ''}`}
                hideInlineDisplay
                hideTooltip
                quantize
                disabled={disabled && !viewOnly}
            />
        </section>
    );
};

export default SurveyEmotionalSlider;
