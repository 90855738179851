import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useNavigation from '@components/navigation/useNavigation';
import { useCurrentUser } from '@stores/User';

import ErrorBoundary from '@components/generic/errors/ErrorBoundary.js';
import SideMenuMain from '@components/navigation/SideMenu/Main/SideMenuMain';
import SideMenuNavigator from '@components/navigation/SideMenu/Navigator/SideMenuNavigator';
import X from '@dsc/svgs/FullSizeX';

const SideMenu = React.forwardRef<HTMLElement>(function SideMenu(_, ref) {
      const { currentUser } = useCurrentUser();

    const history = useHistory();

    const { closeSlideMenu, isSlideMenuOpen } = useNavigation();

    useEffect(() => {
        if (!currentUser && isSlideMenuOpen) {
            history.push('/join');
            closeSlideMenu();
        }
    }, [currentUser]);

    return (
        <ErrorBoundary>
            <section className="side-menu" ref={ref}>
                <SideMenuMain />
                <SideMenuNavigator />
                <button className="side-menu-x" type="button" onClick={closeSlideMenu}>
                    <X />
                </button>
                <button
                    className={`side-menu-dimmer ${isSlideMenuOpen ? 'open' : 'closed'}`}
                    type="button"
                    onClick={closeSlideMenu}
                    aria-label="Close Side Menu"
                />
            </section>
        </ErrorBoundary>
    );
});

export default SideMenu;
