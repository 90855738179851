import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Updater, useImmer } from 'use-immer';

import { useLazyQuery } from '@apollo/client';
import { useCurrentUser } from '@stores/User';

import Toggle from '@dsc/forms/customInputs/Toggle';
import TextInput from '@dsc/forms/textInputs/TextInput';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { EventGroupFormSettingsState } from '@core/types/EventGroup';

import { VALIDATE_GROUP_SHORTCODE } from '@shared/welibrary-graphql/user/queries';

const localNamespace = 'imports.wlWeb.ui.components.eventGroup.groupSettings';

type GroupSettingsProps = {
    state: EventGroupFormSettingsState;
    handleCancel: () => void;
    onSave: (state: EventGroupFormSettingsState) => void;
    setState: Updater<EventGroupFormSettingsState>;
};

const GroupSettings: React.FC<GroupSettingsProps> = ({ state, setState, handleCancel, onSave }) => {
    const { t } = useTranslation();
    const [localState, setLocalState] = useImmer(state);
    const { currentUser } = useCurrentUser();

    const isSuperAdmin = currentUser?.isUserSuperAdmin;

    const updateSlice = curriedStateSlice(setLocalState);

    const handleSave = () => {
        onSave(localState);
        handleCancel();
    };

    const [validateCode, { data: validateShortCodeData }] = useLazyQuery(VALIDATE_GROUP_SHORTCODE, {
        variables: {
            shortCode: localState.shortCode,
        },
    });

    useEffect(() => {
        validateCode();
    }, [localState.shortCode]);

    const { shortCode } = localState;
    const eventHasExistingShortCode = !!state.shortCode;
    const shortCodeLength = shortCode?.length || 0;
    let shortCodeError: string | undefined;
    if (!eventHasExistingShortCode && shortCodeLength > 0) {
        const isDuplicateShortCode =
            validateShortCodeData && !validateShortCodeData.validateGroupShortcode;
        if (isDuplicateShortCode) {
            shortCodeError = t(
                `common:global.this_code_is_already_in_use`,
                'This code is already in use'
            );
        } else if (shortCodeLength < 4) {
            shortCodeError = t(
                `common:global.short_code_must_be_at_least_4_characters`,
                'Code must be at least 4 characters'
            );
        } else if (shortCodeLength > 15) {
            shortCodeError = t(
                `common:global.short_code_must_be_15_characters_or_less`,
                'Code must be 15 characters or less'
            );
        }
    }

    return (
        <div className="scroll-middle-fixed-header-footer">
            <header className="modal-form-btn-header">
                <h1>
                    {t(`common:${localNamespace}.advanced_settings_header`, 'Advanced Settings')}
                </h1>
                <p className="header-desc">
                    {t(
                        `common:${localNamespace}.advanced_settings_description`,
                        `Set advanced settings for your event here. Note that after saving changes made to settings in this form, you will still have to publish for the changes to take effect.`
                    )}
                </p>
                <div className="header-btns">
                    <button
                        type="button"
                        onClick={handleCancel}
                        className="rounded-pill-btn bordered"
                    >
                        {t('common:cancel', 'Cancel')}
                    </button>

                    <button
                        type="button"
                        onClick={handleSave}
                        className="rounded-pill-btn active disabled:opacity-70"
                        disabled={!!shortCodeError}
                    >
                        {t('common:save', 'Save')}
                    </button>
                </div>
            </header>
            <article className="tp-body event-settings">
                <section>
                    <label
                        className="eventchat-toggle event-settings-toggle"
                        htmlFor="eventchat-toggle"
                    >
                        <p>{t(`common:${localNamespace}.enable_chat`, 'Enable Chat')}</p>
                        <Toggle
                            id="eventchat-toggle"
                            value={localState?.groupChatroom}
                            onChange={updateSlice('groupChatroom')}
                        />
                    </label>
                    <p>
                        {t(
                            `common:${localNamespace}.event_chat_description`,
                            'This creates a chat room for attendees of this event.'
                        )}
                    </p>
                </section>

                <section>
                    <label
                        className="hostchat-toggle event-settings-toggle"
                        htmlFor="hostchat-toggle"
                    >
                        <p>
                            {t(
                                `common:${localNamespace}.enable_host_chat`,
                                'Enable Host-Only Chat'
                            )}
                        </p>
                        <Toggle
                            id="hostchat-toggle"
                            value={localState?.adminChatroom}
                            onChange={updateSlice('adminChatroom')}
                        />
                    </label>
                    <p>
                        {t(
                            `common:${localNamespace}.enable_host_chat_description`,
                            'This creates a chat room only for hosts of this event.'
                        )}
                    </p>
                </section>

                {isSuperAdmin && (
                    <>
                        <section>
                            <label
                                className="newsfeed-toggle event-settings-toggle"
                                htmlFor="newsfeed-toggle"
                            >
                                <p>
                                    {t(
                                        `common:${localNamespace}.enable_event_newsfeed`,
                                        'Enable Event Newsfeed'
                                    )}
                                </p>
                                <Toggle
                                    id="newsfeed-toggle"
                                    value={localState?.enableGroupNewsfeed}
                                    onChange={updateSlice('enableGroupNewsfeed')}
                                />
                            </label>
                            <p>
                                {t(
                                    `common:${localNamespace}.enable_event_newsfeed_description`,
                                    `Show the Event Newsfeed at the bottom of the event's page.`
                                )}
                            </p>
                        </section>

                        <section>
                            <label
                                className="matchmaker-toggle event-settings-toggle"
                                htmlFor="matchmaker-toggle"
                            >
                                <p>
                                    {t(`common:${localNamespace}.enable_matchmaker`, 'Matchmaker')}
                                </p>
                                <Toggle
                                    id="mathmaker-toggle"
                                    value={localState?.recommendations}
                                    onChange={updateSlice('recommendations')}
                                />
                            </label>
                            <p>
                                {t(
                                    `common:${localNamespace}.enable_matchmaker_description`,
                                    `Turning this on will activate a matchmaker service for this event group. Must have custom signup forms activated.`
                                )}
                            </p>
                        </section>

                        {/* <section>
                            <label
                                className="template-toggle event-settings-toggle"
                                htmlFor="template-toggle"
                            >
                                <p>
                                    {t(
                                        `common:${localNamespace}.event_is_template`,
                                        'Event Is Template'
                                    )}
                                </p>
                                <Toggle
                                    id="template-toggle"
                                    value={localState?.isTemplate}
                                    onChange={updateSlice('isTemplate')}
                                />
                            </label>
                            <p>
                                {t(
                                    `common:${localNamespace}.event_is_template_description`,
                                    `Enabling allows you to select this event group as a template when creating new event groups. When used as a template, all items on this event group's shelf will be duplicated as part of the template.`
                                )}
                            </p>
                        </section> */}
                    </>
                )}

                <section>
                    <label className="member-edit event-settings-toggle" htmlFor="member-edit">
                        <p>
                            {t(
                                `common:${localNamespace}.enable_suggested_edits`,
                                'Suggested Edits'
                            )}
                        </p>
                        <Toggle
                            id="member-edit"
                            value={localState?.canMembersSuggestEdits}
                            onChange={updateSlice('canMembersSuggestEdits')}
                        />
                    </label>

                    <p>
                        {t(
                            `common:${localNamespace}.enable_suggested_edits_description`,
                            'Allow members to submit suggestions for edits to content cards.'
                        )}
                    </p>
                </section>

                <section style={{ borderTop: '1px solid grey ', padding: '20px 0px' }}>
                    <h3>{t(`common:event_code`, 'Event Code')}</h3>
                    <p style={{ marginTop: '20px' }}>
                        {t(
                            `common:${localNamespace}.helpUsersFindEventWithCode_permanent`,
                            'Help users find and join your event with an event code. This is permanent and cannot be edited.'
                        )}
                    </p>
                    <TextInput
                        value={shortCode}
                        onChange={newCode => updateSlice('shortCode', newCode.toUpperCase())}
                        className="uppercase"
                        label={t(`common:event_code`, 'Event Code')}
                        disabled={eventHasExistingShortCode}
                        maxLength={15}
                    />
                    {shortCodeError && <div className="text-error-default">{shortCodeError}</div>}
                </section>

                <section style={{ borderTop: '1px solid grey ', padding: '20px 0px' }}>
                    <h3>{t(`common:registration`, 'Registration')}</h3>
                    <p style={{ marginTop: '20px' }}>
                        {t(
                            `common:${localNamespace}.registration_instructions`,
                            'Set the text displayed to a user when they register to join an event group'
                        )}
                    </p>
                    <TextInput
                        value={localState.registrationTitle}
                        onChange={updateSlice('registrationTitle')}
                        label="Registration Title"
                    />

                    <TextInput
                        value={localState.registrationActionText}
                        onChange={updateSlice('registrationActionText')}
                        label="Registration Action Text"
                    />

                    <TextInput
                        value={localState.registrationDescription}
                        onChange={updateSlice('registrationDescription')}
                        label="Registration Description"
                        multiline
                    />
                </section>
            </article>
        </div>
    );
};

export default GroupSettings;
