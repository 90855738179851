import React from 'react';

const FullSizePlus: React.FC<{ className?: string; strokeWidth?: string }> = ({
    className,
    strokeWidth = '2',
}) => (
    <svg className={className} viewBox="0 0 32 32" strokeWidth={strokeWidth}>
        <line x1="2" y1="16" x2="30" y2="16" stroke="currentcolor" strokeLinecap="round" />
        <line x1="16" y1="2" x2="16" y2="30" stroke="currentcolor" strokeLinecap="round" />
    </svg>
);

export default FullSizePlus;
