import { KeyboardEvent } from 'react';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

export const triggerHideKeyboardOnKey = (
    e: KeyboardEvent<HTMLElement>,
    keyString: string,
    keyCode: number,
    callback: any
) => {
    if (e.key === keyString || e.keyCode === keyCode) {
        // close keyboard on native devices if enter | return key is hit
        if (Capacitor.isNativePlatform()) {
            Keyboard?.hide();
        }
        if (callback) {
            callback();
        }
    }
};

export { triggerHideKeyboardOnKey as default };
