import React from 'react';
import { Updater } from 'use-immer';

import ThumbnailAndCover from '@components/generic/editscreen/ThumbnailAndCover';
import CourseFormSelect from '@components/content/newpost/forms/Course/CourseFormSelect';
import CourseFormRangeSelect from '@components/content/newpost/forms/Course/CourseFormRangeSelect';
import TextInput from '@dsc/forms/textInputs/TextInput';
import Toggle from '@dsc/forms/customInputs/Toggle';
import MultiTextInput from '@dsc/forms/customInputs/MultiTextInput';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { getAggregateSkillsForLevels } from '@helpers/courses/course.helpers';

import { CourseFormState, LevelState } from '@core/types/Courses';
import { getSkillIcon } from '@helpers/lego/skill.helpers';

type CourseFormMainProps = {
    card: CourseFormState;
    setCard: Updater<CourseFormState>;
    levels: LevelState[];
};

const CourseFormInformation = React.forwardRef<HTMLFieldSetElement, CourseFormMainProps>(
    function CourseFormMain({ card, setCard, levels }, ref) {
        const handleUpdateState = (newState: { thumbnail: string } | { coverImage: string }) =>
            setCard(oldCard => ({ ...oldCard, ...newState }));

        const updateSlice = curriedStateSlice(setCard);

        const aggregateSkills = getAggregateSkillsForLevels(levels).skills;

        return (
            <fieldset ref={ref} className="course-form-main">
                <header>Information</header>

                {aggregateSkills.length > 0 && (
                    <section className="aggregate-skills">
                        {aggregateSkills.map((skill, index) => (
                            <img src={getSkillIcon(skill)} alt={skill} key={index} />
                        ))}
                    </section>
                )}

                <ThumbnailAndCover
                    title={card.title}
                    thumbImage={card.thumbnail}
                    coverImage={card.coverImage}
                    thumbTarget="thumbnail"
                    coverTarget="coverImage"
                    handleUpdateState={handleUpdateState}
                />

                <TextInput value={card.title} onChange={updateSlice('title')} label="Title" />

                <TextInput
                    value={card.headline}
                    onChange={updateSlice('headline')}
                    label="Headline"
                />

                <TextInput
                    value={card.description}
                    onChange={updateSlice('description')}
                    label="Description"
                    multiline
                />

                <TextInput value={card.link} onChange={updateSlice('link')} label="Video URL" />

                <MultiTextInput
                    values={card.materials}
                    onChange={updateSlice('materials')}
                    label="Materials Required"
                    inputLabels="Material"
                    buttonText="Add Material"
                />

                <CourseFormRangeSelect
                    title="Age Group"
                    prompt="Select..."
                    value={card.ageGroup}
                    onChange={updateSlice('ageGroup')}
                    options={[
                        { value: [0, 6], display: '0-6' },
                        { value: [7, 12], display: '7-12' },
                        { value: [12, null], display: '12+' },
                    ]}
                />

                <CourseFormRangeSelect
                    title="Duration"
                    prompt="Select..."
                    value={card.duration}
                    onChange={updateSlice('duration')}
                    options={[
                        { value: [0, 15], display: 'Under 15 minutes' },
                        { value: [15, 30], display: '15-30 minutes' },
                        { value: [30, 60], display: '30-60 minutes' },
                        { value: [60, null], display: '60+ minutes' },
                    ]}
                />

                <CourseFormSelect
                    title="Number of People"
                    prompt="Select..."
                    value={card.people}
                    onChange={updateSlice('people')}
                    options={['1+', '2+', '3+', '4+']}
                />

                <label htmlFor="display-gallery">
                    Allow Public Completions
                    <Toggle
                        id="display-gallery"
                        value={card.publicCompletions}
                        onChange={updateSlice('publicCompletions')}
                    />
                </label>
            </fieldset>
        );
    }
);

export default CourseFormInformation;
