import React from 'react';

import { useGroupSubscriptionAction } from '@components/group/groupdashboard/groupSubscriptionPaywall/useGroupSubscriptionAction';

import { CustomerOrder } from '@shared/welibrary-graphql/types';
import { SubscriptionStatus } from '@components/group/groupdashboard/groupSubscriptionPaywall/groupSubscriptionPaywallHelpers';

const GroupSubscriptionActionButton: React.FC<{
    order: CustomerOrder;
    customClassName: string;
}> = ({ order, customClassName }) => {
    const { subscriptionAction, label } = useGroupSubscriptionAction(order);

    if (
        order.subscription?.status === SubscriptionStatus.Canceled ||
        order.subscription?.status === SubscriptionStatus.Trialing
    )
        return <></>;

    return (
        <button type="button" onClick={subscriptionAction} className={customClassName}>
            {label}
        </button>
    );
};

export default GroupSubscriptionActionButton;
