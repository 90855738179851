import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

export const allCountries = {
    '-none-': 'Select Country...',
    US: 'United States',
    // "CS": "",
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius, and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CV: 'Cabo Verde',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos [Keeling] Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo Republic',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    CD: 'DR Congo',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    CI: 'Ivory Coast',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    XK: 'Kosovo',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MK: 'North Macedonia',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    RE: 'Réunion',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    PM: 'Saint Pierre and Miquelon',
    WS: 'Samoa',
    SM: 'San Marino',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    KN: 'St Kitts and Nevis',
    VC: 'St Vincent and Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    ST: 'São Tomé and Príncipe',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UM: 'U.S. Minor Outlying Islands',
    VI: 'U.S. Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    AX: 'Åland',
};

export const getCountryFlagUrl = countryCode => {
    if (!countryCode || countryCode === '-none-') return undefined;
    return `https://flagsapi.com/${countryCode}/flat/64.png`;
};

// Leverages https://www.countryflagsapi.com/ for flag images.
export const renderCountryFlag = ({
    countryCode,
    size = 32,
    position = 'right',
    customClass,
    width,
    height,
}) => {
    if (!countryCode) return null;
    if (countryCode === '-none-') return null;
    let positionClass;

    if (customClass) {
        positionClass = customClass;
    } else {
        positionClass =
            position === 'left' ? 'country-flag-image country-flag-left' : 'country-flag-image';
    }
    return (
        <div
            className={positionClass}
            style={{
                backgroundImage: `url(${getCountryFlagUrl(countryCode)})`,
                height: height || size,
                width: width || size,
            }}
        />
    );
};

export const getCountryPicker = ({
    value,
    handleChange,
    id,
    name,
    placeholder,
    className,
    disabled = false,
}) => {
    const { t } = useTranslation();

    const options = _.toPairs(
        _.mapValues(allCountries, (countryName, countryKey) => {
            return t(`common:countries.names.${countryKey}`);
        })
    ).map(([code, displayName]) => (
        <option key={code} value={code}>
            {displayName}
        </option>
    ));

    return (
        <select
            value={value}
            onChange={handleChange}
            id={id}
            name={name}
            className={className}
            disabled={disabled}
        >
            {options}
        </select>
    );
};

export const getCountriesWithWarning = () => [
    'AF',
    'DZ',
    'AO',
    'AZ',
    'BH',
    'BD',
    'BT',
    'BN',
    'BF',
    'BI',
    'CM',
    'CF',
    'TD',
    'CN',
    'CO',
    'KM',
    'CU',
    'DJ',
    'CD',
    'EG',
    'ER',
    'ET',
    'GM',
    'GN',
    'HN',
    'IN',
    'ID',
    'IR',
    'IQ',
    'CI',
    'JO',
    'KZ',
    'KE',
    'KW',
    'KG',
    'LA',
    'LY',
    'MY',
    'MV',
    'ML',
    'MR',
    'MX',
    'MA',
    'MZ',
    'MM',
    'NP',
    'NI',
    'NE',
    'NG',
    'KP',
    'OM',
    'PK',
    'PS',
    'QA',
    'RU',
    'RW',
    'SA',
    'SV',
    'SO',
    'SS',
    'LK',
    'SD',
    'SY',
    'TJ',
    'TZ',
    'TG',
    'TN',
    'TR',
    'TM',
    'UG',
    'AE',
    'UZ',
    'VN',
    'YE',
];
