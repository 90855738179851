import { createStore } from '@udecode/zustood';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

import { getScopeType, SCOPE_TYPE, DEFAULT_FILTERS } from '@components/search/constants';

import { Maybify } from '@core/types/Utilities';
import { PaginatedSearchResults, SearchFilterType, SearchScopeInput } from '@shared/welibrary-graphql/types';

const { defaultSearchScope } = getWhitelabelPublicSettings();

type CurrentScopeType = {
    title: string;
    type: string;
    scope?: Maybify<SearchScopeInput>;
};

const defaultSearchState: {
    query: string;
    searchJobResults?: Maybify<PaginatedSearchResults> | null;
    filter: SearchFilterType[];
    queryScopes: Maybify<SearchScopeInput[]>;
    currentScope: CurrentScopeType;
    isVisible: boolean;
    searching: boolean;
    useDebouncedSearch: boolean;
    refreshing: boolean;
} = {
    query: '',
    searchJobResults: undefined,
    filter: DEFAULT_FILTERS,
    queryScopes: [],
    currentScope: defaultSearchScope,
    isVisible: false,
    searching: false,
    useDebouncedSearch: false,
    refreshing: false,
};

const searchStore = createStore('search')(defaultSearchState)
    .extendActions(set => ({
        setDefaultFilter: () => set.filter(DEFAULT_FILTERS),
        resetCurrentScope: () => set.currentScope(defaultSearchScope),
        showSearch: () => set.isVisible(true),
        hideSearch: () => set.isVisible(false),
        resetSearch: () => set.mergeState(defaultSearchState),
        changeCurrentScope: (type: string, cardId: string, title: string) => {
            const scopeType = getScopeType(type.toLowerCase());
            let scope = {};
            if (scopeType === SCOPE_TYPE.CONTENTCARD) {
                scope = {
                    type: scopeType,
                    cardUrl: cardId,
                };
            }
            if (scopeType === SCOPE_TYPE.GROUP) {
                scope = {
                    type: scopeType,
                    groupId: cardId,
                };
            }
            const newScope = {
                title,
                type,
                scope,
            };
            set.currentScope(newScope);
        },
        changeFilter: (filterName: SearchFilterType | 'ALL') => {
            if (filterName !== 'ALL') {
                set.filter([filterName]);
            } else {
                set.filter(DEFAULT_FILTERS);
            }
        },
    }))
    .extendSelectors(store => ({
        searchResults: () => store.searchJobResults?.results,
        hasMoreResults: () => store.searchJobResults?.hasMore,
        paginationCursor: () => store.searchJobResults?.cursor,
        isAllTab: () => DEFAULT_FILTERS.every(f => store.filter.includes(f)),
    }));

export default searchStore;
