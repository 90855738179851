import React from 'react';

import {getBackgroundImageSetForUrl, getRandomDefaultCoverImage,} from '@core/utilities/constants/defaults';

import {Item} from '@core/types/GroupTemplateItem';

import Picture from '@web/ui/components/generic/Picture';
import Plus from '@web/ui/dsc/svgs/FullSizePlus';

const TemplateItem: React.FC<{
    item: Item;
    handleSelectedTemplate: (title: string, id: string) => void;
}> = ({ item, handleSelectedTemplate }) => {
    let thumbnail: string = item?.thumb;

    if (!thumbnail) {
        thumbnail = getRandomDefaultCoverImage(item?.title);
    }

    const [thumbSrc] = getBackgroundImageSetForUrl(thumbnail);

    return (
        <li className="channelTemplate-form-results">
            <Picture url={thumbSrc} disableLink resolutions={[130, 260, 390]} />

            <section>
                <h3>{item?.title}</h3>
                <span>{item?.description}</span>
            </section>

            <button type="button" onClick={() => handleSelectedTemplate(item?.title, item?.url)}>
                <Plus />
            </button>
        </li>
    );
};

export default TemplateItem;
