import React from 'react';
import { Updater } from 'use-immer';

import CourseFormSelect from '@components/content/newpost/forms/Course/CourseFormSelect';
import SkillAndTextLabel from '@components/content/newpost/forms/Course/SkillAndTextLabel';
import CourseFormStepDetails from '@components/content/newpost/forms/Course/CourseFormStepDetails';
import SurveyQuestionForm from '@components/content/newpost/forms/Survey/SurveyQuestionForm';
import TextInput from '@dsc/forms/textInputs/TextInput';
import ImageUpload from '@dsc/forms/customInputs/ImageUpload';
import MultiCustomInput from '@dsc/forms/customInputs/MultiCustomInput';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { getRandomDefaultThumbImage } from '@core/utilities/constants/defaults';

import { defaultQuestion } from '@helpers/surveys/survey.helpers';

import { LevelState } from '@core/types/Courses';
import { Resource } from '@shared/welibrary-graphql/types';

type CourseFormLevelDetailsProps = {
    level: LevelState;
    setLevel: Updater<LevelState>;
};

const CourseFormLevelDetails = React.forwardRef<HTMLFieldSetElement, CourseFormLevelDetailsProps>(
    function CourseFormLevelDetails({ level, setLevel }, ref) {
        const updateSlice = curriedStateSlice(setLevel);
        return (
            <fieldset ref={ref} className="course-form-level-details">
                <header>Level Details</header>

                <TextInput value={level.title} onChange={updateSlice('title')} label="Title" />

                <MultiCustomInput
                    labels={level.steps.map(({ text, skill }, index) => (
                        <SkillAndTextLabel key={index} skill={skill as any} label={text} />
                    ))}
                    state={level.steps}
                    onChange={steps => updateSlice('steps', steps)}
                    defaultValue={{
                        text: `Step ${level.steps.length + 1}`,
                        useImage: false,
                        image: { image: '' },
                        fullPage: false,
                        pageBreak: false,
                        useVideo: false,
                        video: '',
                    }}
                    Component={CourseFormStepDetails}
                    modalOptions={{ className: 'full-height no-bottom-padding' }}
                    label="Steps"
                    buttonText="Add Step"
                />

                <p>Thumbnail</p>
                <ImageUpload
                    state={{
                        ...level.media,
                        image: level.media.image || getRandomDefaultThumbImage(level.title),
                    }}
                    setState={updateSlice('media')}
                    className="thumbnail-upload"
                    loaderSize={80}
                    small
                />

                <TextInput value={level.video} onChange={updateSlice('video')} label="Video URL" />

                <CourseFormSelect
                    title="Resource Given Upon Completion"
                    prompt="Select..."
                    value={level.resource}
                    onChange={resource => updateSlice('resource', resource)}
                    options={Object.values(Resource)}
                />

                <MultiCustomInput
                    labels={level.preSurvey.map((question, index) => (
                        <SkillAndTextLabel
                            key={index}
                            skill={question.skill as any}
                            label={`[${question.type}] ${question.prompt}`}
                        />
                    ))}
                    state={level.preSurvey}
                    onChange={survey => updateSlice('preSurvey', survey)}
                    defaultValue={defaultQuestion}
                    Component={SurveyQuestionForm}
                    modalOptions={{ className: 'full-height no-bottom-padding' }}
                    label="Pre Survey"
                    buttonText="Add Question"
                />

                <MultiCustomInput
                    labels={level.postSurvey.map((question, index) => (
                        <SkillAndTextLabel
                            key={index}
                            skill={question.skill as any}
                            label={`[${question.type}] ${question.prompt}`}
                        />
                    ))}
                    state={level.postSurvey}
                    onChange={survey => updateSlice('postSurvey', survey)}
                    defaultValue={defaultQuestion}
                    Component={SurveyQuestionForm}
                    modalOptions={{ className: 'full-height no-bottom-padding' }}
                    label="Post Survey"
                    buttonText="Add Question"
                />
            </fieldset>
        );
    }
);

export default CourseFormLevelDetails;
