import React from 'react';
import Picture from '@web/ui/components/generic/Picture';
import { MiniVCThumbnail } from '@learncard/react';
import DisplayVC from '@components/lego/treasure/DisplayVC';
import type { VC } from '@learncard/types';
import useModal from '@components/modals/hooks/useModal';
import moment from 'moment';

const TreasureSeasonsTab = React.forwardRef<
    HTMLElement,
    { seasonImageUrls?: Array<string | null>; plugfestVc?: VC }
>(function TreasureCreationsTab({ seasonImageUrls, plugfestVc }, ref) {
    const { newModal } = useModal();

    const renderSeasonImages = seasonImageUrls?.map(url => {
        if (!url) return <></>;

        return (
            <div className="treasure season-vc-icon">
                <Picture className="seasion-vc-display-picture" disableLink url={url} />
            </div>
        );
    });

    const handleFullVcView = () => {
        newModal(<DisplayVC credential={plugfestVc} />, {
            sectionClassName: 'transparent-modal plugfest-vc',
            hideButton: true,
        });
    };

    const props = {
        achievement: plugfestVc?.credentialSubject?.achievement,
        title: plugfestVc?.credentialSubject?.achievement?.name,
        createdAt: moment(plugfestVc?.issuanceDate).format('l'),
        issuerImage: plugfestVc?.issuer?.image,
        badgeImage: plugfestVc?.credentialSubject?.achievement?.image,
        className: 'mini-vc-square',
        onClick: () => handleFullVcView(),
    };

    return (
        <section className="seasons-tab" ref={ref}>
            <div className="treasure-seasons-body season">
                {renderSeasonImages}
                {plugfestVc && <MiniVCThumbnail {...props} />}
            </div>
        </section>
    );
});

export default TreasureSeasonsTab;
