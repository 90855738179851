import React from 'react';

import TrashBin from '@dsc/svgs/Trash';

import { User } from '@shared/welibrary-graphql/types';

import { getRandomDefaultUserImage } from '@core/utilities/constants/defaults';

export const TicketSharingUserSelected: React.FC<{
    user: User;
    ticketId: string;
    showRemoveAttendeeButton?: boolean;
    handleRemoveAttendee: (id: string) => void;
    showCancelTransferButton?: boolean;
    handleCancelTransfer?: (id: string) => void;
    className?: string;
    isLoading?: boolean;
}> = ({
    user,
    ticketId,
    showRemoveAttendeeButton = true,
    handleRemoveAttendee,
    className,
    showCancelTransferButton = false,
    handleCancelTransfer = () => {},
    isLoading = false,
}) => {
    return (
        <div className={`ticket-sharing-dropdown-item ${className}`}>
            <div className="ticket-sharing-dropdown-item-details">
                <div className="ticket-sharing-dropdown-item-img-wrap">
                    <img
                        src={user?.profile?.picture ?? getRandomDefaultUserImage()}
                        alt="user profile pic"
                    />
                </div>
                <p>{user?.profile?.full_name}</p>
            </div>
            {showCancelTransferButton && (
                <button
                    type="button"
                    onClick={() => handleCancelTransfer(ticketId)}
                    className="text-sm text-white font-semibold bg-red-400 rounded-full px-[16px] py-[4px]"
                >
                    {isLoading ? 'Loading...' : 'Cancel Transfer'}
                </button>
            )}
            {showRemoveAttendeeButton && (
                <div className="ticket-sharing-dropdown-btn-wrap">
                    <button type="button" onClick={() => handleRemoveAttendee(ticketId)}>
                        <TrashBin />
                    </button>
                </div>
            )}
        </div>
    );
};

export const TicketSharingEmailSelected: React.FC<{
    email: string;
    ticketId: string;
    showRemoveAttendeeButton?: boolean;
    handleRemoveAttendee: (id: string) => void;
    showCancelTransferButton?: boolean;
    handleCancelTransfer?: (id: string) => void;
    className?: string;
    isLoading?: boolean;
}> = ({
    email,
    ticketId,
    showRemoveAttendeeButton = true,
    handleRemoveAttendee,
    className,
    showCancelTransferButton = false,
    handleCancelTransfer = () => {},
    isLoading = false,
}) => {
    return (
        <div className={`ticket-sharing-dropdown-item ${className}`}>
            <div className="ticket-sharing-dropdown-item-details">
                <p>{email}</p>
            </div>
            {showCancelTransferButton && (
                <button
                    type="button"
                    onClick={() => handleCancelTransfer(ticketId)}
                    className="text-sm text-white font-semibold bg-red-400 rounded-full px-[16px] py-[4px]"
                >
                    {isLoading ? 'Loading...' : 'Cancel Transfer'}
                </button>
            )}
            {showRemoveAttendeeButton && (
                <div className="ticket-sharing-dropdown-btn-wrap">
                    <button type="button" onClick={() => handleRemoveAttendee(ticketId)}>
                        <TrashBin />
                    </button>
                </div>
            )}
        </div>
    );
};
