import React, { useState } from 'react';
import _, { cloneDeep } from 'lodash';
import FullScreenSelectManyOption from '@web/ui/components/generic/forms/FullScreenSelectManyOption';
import useModal from '@web/ui/components/modals/hooks/useModal';

type DropdownMultipleSelectProps = {
    target?: string;
    type?: string;
    handleCustomChange: (id: string, values: string | string[] | null, type: string) => void;
    handleDoneButton?: (id: string, values: string | string[], callback: () => void) => void;
    doneButtonText?: string;
    lightBackground?: boolean;
    state: [string] | null;
    allowOther?: boolean;
    allowMultipleSelect?: boolean;
    prompt?: string;
    options: [string];
    showCancelButton?: boolean;
};

const DropdownMultipleSelect: React.FC<DropdownMultipleSelectProps> = ({
    target,
    state: initialState,
    type,
    prompt,
    handleDoneButton,
    doneButtonText,
    allowOther,
    allowMultipleSelect,
    options,
    handleCustomChange,
    showCancelButton,
}) => {
    // state here holds the selected options
    const [state, setState] = useState(initialState || []);
    const { closeModal } = useModal();
    const selected = state;

    const handleClickOption = (target, option) => {
        let updatePayload = [];
        if (allowMultipleSelect) {
            // update local state
            const _state = cloneDeep(state);

            if (state?.includes(option)) {
                updatePayload = _state?.filter(value => value !== option);
                setState(updatePayload);
            } else {
                updatePayload = [..._state, option];
                setState(updatePayload);
            }
        } else {
            updatePayload = [option];
            setState(updatePayload);
        }

        // update parent state
        handleCustomChange?.(target, updatePayload, type);
    };

    const otherValue =
        (state || []).filter(value => {
            const _options = options.filter(option => option === value);
            return !(_options?.length > 0);
        })?.[0] || '';

    const optionsList = options.map(option => {
        return _.isObject(option) ? (
            <FullScreenSelectManyOption
                key={`option-${option?.value}`}
                value={option?.value}
                label={option?.label || option?.text}
                target={target}
                handleClick={handleClickOption}
                selected={selected.includes(option?.value)}
            />
        ) : (
            <FullScreenSelectManyOption
                key={`option-${option}`}
                value={option}
                label={option}
                target={target}
                handleClick={handleClickOption}
                selected={selected.includes(option)}
            />
        );
    });

    const handleChange = e => {
        const otherIndex = (state || []).findIndex(value => {
            const _options = options.filter(option => option?.value === value);
            return !(_options?.length > 0);
        });
        const newValue = e.target.value;
        const values = [...(state || [])];

        if (otherIndex > -1) values[otherIndex] = newValue;
        else values.push(newValue);
        const uniqueValues = _.uniq(values);
        // update local state
        setState(uniqueValues);
        // update parent state
        handleCustomChange(target, uniqueValues, type);
    };

    const handleClickDoneButton = () => {
        handleDoneButton?.(target, state, () => closeModal?.());
        closeModal?.();
    };

    const buttonText = doneButtonText && doneButtonText?.trim() !== '' ? doneButtonText : 'Done';

    return (
        <div className="select-option-container" key={`${target}`}>
            <section className="select-options-info">
                <div className="select-info-prompt">{prompt}</div>
                {showCancelButton && (
                    <button
                        onClick={() => closeModal?.()}
                        type="button"
                        className="standard-button"
                    >
                        Cancel
                    </button>
                )}
                <button
                    onClick={handleClickDoneButton}
                    type="button"
                    className="standard-button callout"
                >
                    {buttonText}
                </button>
            </section>
            <div className="options-list"> {optionsList}</div>
            {allowOther && (
                <form onSubmit={e => e.preventDefault()} className="channelTemplate-form-input">
                    <input
                        type="text"
                        name={target}
                        autoComplete="off"
                        onChange={handleChange}
                        defaultValue={otherValue}
                        id={target}
                        className="signup-select-field light-background-select"
                        placeholder="Other..."
                        list={`${target}-list`}
                    />
                </form>
            )}
        </div>
    );
};

export default DropdownMultipleSelect;
