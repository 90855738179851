import React, {useState} from 'react';
import loadable from '@loadable/component';
import {useTranslation} from 'react-i18next';
import {cloneDeep} from 'lodash';
import Toggle from '@web/ui/dsc/forms/customInputs/Toggle';
import EditScreenTagSelect from '@web/ui/components/generic/editscreen/EditScreenTagSelect';
import EditScreenSelectOne from '@web/ui/components/generic/editscreen/EditScreenSelectOne.js';
import {getFeatureConstraintsForField} from '@core/utilities/constants/feature_constraints.js';
import {ATTACH_IMAGE, ATTACH_LINK, OPT_IN_TYPES,} from '@core/utilities/constants/attachment_types.js';

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm';

const LoadableNewPostOptimizer = loadable(
    () => import('@web/ui/components/optimizers/NewPostOptimizer'),
    { resolveComponent: imported => imported.default }
);

const NewPostSettings = ({
    handleSetNewPostState,
    handleAddToList,
    handleRemoveFromList,
    handleChange,
    handleSetCustomFields,
    shouldShowTitle,
    state,
    chapter,
    group,
}) => {
    const { t } = useTranslation();
    const defaultViewTypeConstraint = getFeatureConstraintsForField('defaultViewType');
    const engagementTypesConstraint = getFeatureConstraintsForField('engagementTypes');
    const showCreateChildrenConstraint = getFeatureConstraintsForField('showCreateChildren');
    const isTemplateConstraint = getFeatureConstraintsForField('isTemplate');
    const canMembersSuggestEditsConstraint =
        getFeatureConstraintsForField('canMembersSuggestEdits');
    const displayInNewsfeedConstraint = getFeatureConstraintsForField('displayInNewsfeed');
    const shouldShowTitleConstraint = getFeatureConstraintsForField('shouldShowSettings');

    const getDefaultNewsfeedValue = () => {
        if (OPT_IN_TYPES.includes(state.attachedContent?.type)) {
            return false;
        }
        return true;
    };

    const hideTitleOption = state?.attachedContent?.type === ATTACH_IMAGE;

    const enableResourceText = t(`common:${localNamespace}.show_create_children`);
    const displayNewsfeedText = t(`common:${localNamespace}.display_in_newsfeed`);
    const isTemplateText = t(
        `common:${'imports.wlWeb.ui.components.card.darkForms.settings'}.is_template.title`
    );
    const showTitleText = t(`common:${localNamespace}.show_title`);
    const suggestEditsText = t(`common:${localNamespace}.suggest_edits`);
    const openInModalText = t(`common:${localNamespace}.open_link_modal_toggle`);

    const [showOptimize, setShowOptimize] = useState(false);

    const handleShowOptimize = () => {
        setShowOptimize(true);
    };

    const handleToggleModal = value => {
        const stateClone = cloneDeep(state);
        stateClone.attachedContent.data.openInModal = value;
        handleSetNewPostState(stateClone);
    };

    if (showOptimize) {
        return (
            <LoadableNewPostOptimizer
                handleSave={handleSetCustomFields}
                chapter={chapter}
                group={group}
                state={state}
            />
        );
    }

    const showOpenModalToggle = state?.attachedContent?.type === ATTACH_LINK;

    return (
        <>
            <section className="optimize-card-toggle" style={{ marginTop: '15px' }}>
                <button
                    className="optimize-button-settings"
                    type="button"
                    onClick={handleShowOptimize}
                >
                    Optimize for matching
                </button>
            </section>
            {shouldShowTitleConstraint && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {showTitleText}
                    <Toggle
                        value={state.showTitle === null ? shouldShowTitle : state.showTitle}
                        onChange={value => handleSetNewPostState({ showTitle: value })}
                    />
                </label>
            )}

            {showOpenModalToggle && (
                <label>
                    {openInModalText}
                    <Toggle
                        value={state?.attachedContent?.data?.openInModal}
                        onChange={value => handleToggleModal(value)}
                    />
                </label>
            )}

            {displayInNewsfeedConstraint && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {displayNewsfeedText}
                    <Toggle
                        value={
                            state.displayInNewsfeed === null
                                ? getDefaultNewsfeedValue()
                                : state.displayInNewsfeed
                        }
                        onChange={value => handleSetNewPostState({ displayInNewsfeed: value })}
                    />
                </label>
            )}

            {showCreateChildrenConstraint && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {enableResourceText}
                    <Toggle
                        value={state.showCreateChildren}
                        onChange={value => handleSetNewPostState({ showCreateChildren: value })}
                    />
                </label>
            )}

            {engagementTypesConstraint && (
                <div className="card-settings tag-select">
                    <div className="edit-display-order-container">
                        <div className="display-text-inline" style={{ marginTop: '5px' }}>
                            {t(`common:${localNamespace}.engagement`)}
                            <br />
                        </div>
                        <EditScreenTagSelect
                            overrideContainerStyle="edit-screen-tag-select-container"
                            target="engagementTypes"
                            options={state.engagementTypesOptions}
                            selected={state.engagementTypes}
                            handleRemoveFromList={handleRemoveFromList}
                            handleSetList={(target, values) => {
                                handleSetNewPostState({ engagementTypes: values });
                            }}
                        />
                    </div>
                </div>
            )}

            {defaultViewTypeConstraint && (
                <div className="card-settings tag-select">
                    <div className="edit-display-order-container">
                        <div className="display-text-inline" style={{ marginTop: '5px' }}>
                            {t(
                                'common:imports.wlWeb.ui.components.card.darkForms.settings.views.title'
                            )}
                            <br />
                        </div>
                        <EditScreenSelectOne
                            hideTitle
                            target="defaultViewType"
                            options={state.defaultViewTypes}
                            selected={
                                state.defaultViewType || state?.inheritedOptions?.defaultViewType
                            }
                            handleChange={handleChange}
                        />
                    </div>
                </div>
            )}

            {isTemplateConstraint && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {isTemplateText}
                    <Toggle
                        value={state.isTemplate}
                        onChange={value => handleSetNewPostState({ isTemplate: value })}
                    />
                </label>
            )}

            {canMembersSuggestEditsConstraint && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {suggestEditsText}
                    <Toggle
                        value={
                            state.canMembersSuggestEdits
                                ? state.canMembersSuggestEdits
                                : state.currentUserCanSuggestEdit
                        }
                        onChange={value => handleSetNewPostState({ canMembersSuggestEdits: value })}
                    />
                </label>
            )}
        </>
    );
};

export default NewPostSettings;
