export const MAKE_ADMIN = 1;
export const MAKE_MANAGER = 2;
export const MAKE_CONTRIBUTOR = 3;
export const MAKE_VOTING = 4;
export const MAKE_VIEW = 5;
export const MAKE_NONE = 6;
export const MAKE_SPEAKER = 7;
export const MAKE_REJECTED = 8;

export const ROLE_ADMIN = 'admin';
export const ROLE_MANAGER = 'manage';
export const ROLE_CONTRIBUTOR = 'answer';
export const ROLE_VOTING = 'vote';
export const ROLE_VIEW = 'view';
export const ROLE_NONE = 'none';
export const ROLE_SPEAKER = 'speaker';
export const ROLE_REJECTED = 'rejected';
export const ROLE_CREATE_LIBRARIES = 'create_libraries';
export const ROLE_CREATE_REPORTS = 'create_reports';

export const ROLE_KEY = {
    1: 'admin',
    2: 'manage',
    3: 'answer',
    4: 'vote',
    5: 'view',
    6: 'none',
    7: 'speaker',
    8: 'rejected',
} as const;

export const VIEW_PERMISSION_PUBLIC = 'public';
export const VIEW_PERMISSION_PRIVATE = 'private';
export const VIEW_PERMISSION_SECRET = 'secret';
export const VIEW_PERMISSION_UNSET = 'unset';

export const PERMISSION_OPTIONS = [
    VIEW_PERMISSION_PUBLIC,
    VIEW_PERMISSION_SECRET,
    VIEW_PERMISSION_PRIVATE,
];

export const isClientUserRestrictedFromViewingCard = card => {
    /**
     * TODO: ideally, server should return content card wrapped with status (restricted content, doe, etc.)
     * This should check for fields stripped in role_helpers.js getAllowedPrivateFields & getAllowedSecretFields helpers.
     * This will return false for private Collection cards (Shelves, Libraries, Books, Chapters) because users are allowed to view partial info on private Collection cards.
     */
    return (
        card?.title === null &&
        card?.body === null &&
        card?.media === null &&
        card?.aliases === null &&
        card?.links === null
    );
};
