/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import useQuest from '@components/quest/hooks/useQuest';

import LockIcon from '@dsc/svgs/LockIcon';

import { skinTones, isSkinToneDisabled } from '@components/profile/Lego/avatarHelpers';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

import { AvatarAllowedColors } from '@components/profile/Lego/Avatars';

const SkinToneSelector: React.FC<{
    setSkinTone: Function;
    skinTone: AvatarAllowedColors['skinTone'];
    notLoggedInUser?: boolean;
}> = ({ setSkinTone, skinTone, notLoggedInUser = false }) => {
    const { main_quest_map, main_quest_url } = getWhitelabelPublicSettings();

    const quest = useQuest(main_quest_map || main_quest_url || '');

    const { isComplete, quest: { currentNode = 0 } = {} } = quest || {};

    return (
        <div className="hero-creator-skin-tone-picker-container">
            <div className="hero-creator-skin-tone-picker">
                {skinTones.map(tone => {
                    const isSelected = tone === skinTone;

                    let isDisabled = notLoggedInUser;

                    if (main_quest_map) isDisabled = isSkinToneDisabled(tone, currentNode ?? 0);
                    else isDisabled = !isComplete && isSkinToneDisabled(tone);

                    if (notLoggedInUser) isDisabled = isSkinToneDisabled(tone);

                    return (
                        <button
                            key={tone}
                            type="button"
                            onClick={() => setSkinTone(tone)}
                            className={`hero-creator-skin-tone-picker-btn ${
                                isSelected && 'hero-creator-skin-tone-picker-btn-active'
                            }`}
                            style={{ background: tone }}
                            disabled={isDisabled}
                        >
                            {isDisabled && (
                                <div className="hero-creator-skin-tone-picker-btn-overlay">
                                    <LockIcon className="hero-creator-skin-tone-picker-btn-svg" />
                                </div>
                            )}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default SkinToneSelector;
