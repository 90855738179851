import React from 'react';
import { useTranslation } from 'react-i18next';

import searchStore from '@stores/Search';

import { SearchFilterType } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.search.SearchOverlay';

type GroupedSearchCardProps = {
    type: SearchFilterType;
    renderArray: JSX.Element[];
};

const GroupedSearchCard: React.FC<GroupedSearchCardProps> = ({ type, renderArray }) => {
    const { t } = useTranslation();

    const FILTER_NAMES: { [key in SearchFilterType]: string } = {
        [SearchFilterType.User]: t(`common:${localNamespace}.people`, 'People'),
        [SearchFilterType.Group]: t(`common:${localNamespace}.groups`, 'Groups'),
        [SearchFilterType.Collection]: t(`common:${localNamespace}.collections`, 'Collections'),
        [SearchFilterType.Post]: t(`common:${localNamespace}.content`, 'Content'),
        [SearchFilterType.Prompt]: t(`common:${localNamespace}.prompts`, 'Prompts'),
        [SearchFilterType.Comment]: t(`common:${localNamespace}.comments`, 'Comments'),
        [SearchFilterType.Message]: t(`common:${localNamespace}.messages`, 'Messages'),
        [SearchFilterType.Chapter]: t(`common:${localNamespace}.channel`, 'Channel'),
    };

    return (
        <div className="search-result-section-container">
            <div className="search-result-section-header">{FILTER_NAMES[type]}</div>
            <div>{renderArray}</div>
            {renderArray?.length >= 3 && (
                <button
                    type="button"
                    name={type}
                    onClick={() => searchStore.set.changeFilter(type)}
                    className="button-reset search-result-section-button"
                >
                    {t(`common:${localNamespace}.see_all`, 'See All')}
                </button>
            )}
        </div>
    );
};

export default GroupedSearchCard;
