import React, { useState, useEffect } from 'react';

import Toggle from '@dsc/forms/customInputs/Toggle';

import { Group } from '@shared/welibrary-graphql/content_card/queries.hook';

const NotifyPanelSubGroupItem: React.FC<{
    subgroup: Group;
    selectedGroups: string[];
    handleAddSubGroup: (id: string) => void;
    handleRemoveSubGroup: (id: string) => void;
}> = ({ subgroup, selectedGroups, handleAddSubGroup, handleRemoveSubGroup }) => {
    const [isToggled, setIsToggled] = useState<boolean>(
        selectedGroups?.includes(subgroup?._id) ?? false
    );

    useEffect(() => {
        setIsToggled(selectedGroups?.includes(subgroup?._id) ?? false);
    }, [selectedGroups]);

    const handleMembersToggle = (toggled: boolean) => {
        setIsToggled(toggled);

        if (isToggled) {
            handleRemoveSubGroup(subgroup?._id);
        } else {
            handleAddSubGroup(subgroup?._id);
        }
    };

    return (
        <li className="notify-panel-subgroup-item">
            <Toggle
                className="notify-panel-toggle-btn notify-panel-subgroup-toggle-btn"
                onChange={handleMembersToggle}
                value={isToggled}
            />
            <h4>{subgroup?.profile?.full_name}</h4>
        </li>
    );
};

export default NotifyPanelSubGroupItem;
