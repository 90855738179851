import { useEffect, useState } from 'react';
import { getAuthToken, setAuthToken } from '@web/utilities/helpers/auth';
import { silentlyMigrate } from '@web/utilities/helpers/user/login';

import MeteorProxy from '~/meteor-proxy';
import getLogger from '@core/logger';

const logger = getLogger(module);

export default () => {
    const [token, setToken] = useState(getAuthToken());
    const [meteorToken, setMeteorToken] = useState(MeteorProxy._storedLoginToken());
    const [migratedUser, setMigratedUser] = useState(false);
    const [migratingUser, setMigratingUser] = useState(false);

    useEffect(() => {
        const runMigration = async () => {
            if (!token && meteorToken) {
                logger.info('Migrating user with Meteor token', meteorToken);
                setMigratingUser(true);
                try {
                    const resp = await silentlyMigrate(meteorToken);
                    const [status, obj] = resp;
                    if (status === 200 && obj?.token) {
                        setAuthToken(obj.token);
                        setToken(obj.token);
                    } else {
                        logger.error('Error migrating user');
                    }
                } catch (e) {
                    logger.error(e);
                }

                // log them out of Meteor
                // if the JWT was set, we're in business
                // if there was an error, leave it up to consumer
                Meteor.logout(() => {
                    logger.info('Logging user out of Meteor');
                    setMeteorToken(MeteorProxy._storedLoginToken());
                });

                setMigratingUser(false);
                setMigratedUser(true);
            }
        };

        runMigration();
    }, []);
    return { token, meteorToken, migratingUser, migratedUser };
};
