import React, { FormEventHandler } from 'react';
import { Updater } from 'use-immer';
import { useTranslation } from 'react-i18next';

import ImageUpload from '@dsc/forms/customInputs/ImageUpload';
import TextInput from '@dsc/forms/textInputs/TextInput';
import MultiSelectButtons from '@dsc/forms/customInputs/MultiSelectButtons';
import Select from '@dsc/forms/customInputs/Select';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { getRandomDefaultThumbImage } from '@core/utilities/constants/defaults';
import { engagementTypeValues } from '@core/utilities/constants/engagement_types';
import { capitalizedDisplayValues } from '@helpers/dsc/dsc.helpers';

import { RainbowRoadBuilderMainState } from '@core/types/RainbowRoad';
import { RrFont } from '@shared/welibrary-graphql/types';

type RRBuilderMainProps = {
    title: string;
    state: RainbowRoadBuilderMainState;
    setState: Updater<RainbowRoadBuilderMainState>;
    onSubmit: FormEventHandler<HTMLFormElement>;
};

const RRBuilderMain = React.forwardRef<HTMLFormElement, RRBuilderMainProps>(function RRBuilderMain(
    { title, state, setState, onSubmit },
    ref
) {
    const { t } = useTranslation();

    const updateSlice = curriedStateSlice(setState);

    return (
        <form
            className="rr-builder-main"
            onSubmit={e => {
                e.preventDefault();
                if (state.title) onSubmit(e);
            }}
            ref={ref}
        >
            <h1>{title}</h1>

            <ImageUpload
                state={{
                    ...state.icon,
                    image: state.icon.image || getRandomDefaultThumbImage(state.title),
                }}
                setState={updateSlice('icon')}
                className="rr-icon-upload"
                loaderSize={100}
            />

            <TextInput
                label={t('common:global.nouns.title')}
                value={state.title}
                onChange={updateSlice('title')}
            />

            <TextInput
                label={t('common:global.nouns.description')}
                value={state.description}
                onChange={updateSlice('description')}
                multiline
            />

            <MultiSelectButtons
                value={state.engagementTypes}
                onChange={engagementTypes => updateSlice('engagementTypes', engagementTypes)}
                values={engagementTypeValues}
                title={t('common:global.nouns.engagement_options')}
                className="rr-engagement-type-select"
            />

            <Select
                title="Font"
                prompt="Choose a font"
                value={state.font}
                onChange={font => updateSlice('font', font)}
                options={Object.values(RrFont)}
                displayValues={capitalizedDisplayValues(Object.values(RrFont))}
            />
        </form>
    );
});

export default RRBuilderMain;
