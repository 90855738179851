import { useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

import { useGetGroupCustomFieldCsvLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';

import { filestack } from '@components/generic/hooks/useFilestack';

import {
    getDocumentPreviewUrl,
    getDocumentDownloadUrl,
} from '@helpers/contentCard/documentItem.helpers';
import { getInAppBrowserOptions } from '@core/utilities/whitelabel_helpers';

import { Group } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

/* This generates a CSV file of answers to a group's survey */
const useGetCSV = (group?: Group) => {
    const [fileUrl, setFileUrl] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const [loading, setIsLoading] = useState(false);
    const isUserAdmin = group?.currentUserIsAdmin;

    const [
        getGroupCustomFieldCsv,
        { data: getCSVData, loading: useGetCSVLoading, error: getCSVError },
    ] = useGetGroupCustomFieldCsvLazyQuery({
        variables: { groupId: group?._id },
        fetchPolicy: 'network-only',
    });

    const downloadCSV = (url?: string) => {
        if (!url && !fileUrl) return;

        if (Capacitor.isNativePlatform()) {
            Browser.open({
                url: url || fileUrl,
                windowName: '_system',
                toolbarColor: getInAppBrowserOptions().toolBarColor,
            });
        } else {
            window.open(url || fileUrl, '_blank');
        }
    };

    // csv: a csv string to be downloaded
    //   if this isn't provided, the csv will be retrieved from the getGroupCustomFieldCsv query
    const getAndDownloadCSV = async (csv?: string, fileNameString?: string = 'customFields') => {
        if (!isUserAdmin) return;

        if (fileUrl) return downloadCSV();

        setIsLoading(true);

        if (!csv) {
            const { data } = await getGroupCustomFieldCsv();

            csv = data?.getGroupCustomFieldCsv;
        }

        if (csv) {
            const blob = new Blob([csv], { type: 'plain/csv' });
            const dateString = new Date()?.toISOString();
            const fileOfBlob = new File(
                [blob],
                `${group?.profile?.full_name}-${fileNameString}-${dateString}.csv`
            );

            try {
                const res = await filestack.upload(fileOfBlob);
                const downloadUrl = getDocumentDownloadUrl(res?.url);

                setFileUrl(downloadUrl);
                setPreviewUrl(getDocumentPreviewUrl(res?.url));

                downloadCSV(downloadUrl);
            } catch (error) {
                logger.error('Filestack Upload Error', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return {
        getCSVData,
        getCSVLoading: useGetCSVLoading || loading,
        getCSVError,
        fileUrl,
        previewUrl,
        getAndDownloadCSV,
    };
};

export default useGetCSV;
