import _ from 'lodash';
import { createStore } from '@udecode/zustood';
import { CustomerOrder, Product, User } from '@shared/welibrary-graphql/types';
import { DiscountCodeState } from '@components/card/Shopping-Cart/CheckoutHelpers';

export type ShoppingCartProduct = {
    product: Product;
    quantity?: number;
    coupon?: Partial<DiscountCodeState | {}>;
};

export const PAYMENT_TYPES = {
    creditCard: 'creditCard',
    cash: 'cash',
} as const;
type PaymentType = (typeof PAYMENT_TYPES)[keyof typeof PAYMENT_TYPES];

export const SELL_TICKETS_AT_DOOR_STEPS = {
    selectTickets: 0,
    orderDetails: 1,
    paymentDetails: 2,
    confirmOrder: 3,
    viewCompletedOrder: 4,
} as const;
type SellTicketStep = (typeof SELL_TICKETS_AT_DOOR_STEPS)[keyof typeof SELL_TICKETS_AT_DOOR_STEPS];

export type ShoppingCartStore = {
    shoppingCarts: {
        groupId: string;
        products: ShoppingCartProduct[];
    }[];

    // For sell tickets at the door flow
    currentStep: SellTicketStep;
    name?: string;
    email?: string;
    phone?: string;
    discountCode?: string;
    paymentType: PaymentType;
    isProcessingOrder: boolean;
    createOrderError?: string;
    idempotencyKey?: string;
    idempotencySalt?: string;
    order: CustomerOrder | undefined;
    cardToken?: string;
    existingAccount?: User; // if tickets are being sold for an account that already exists
};

const shoppingCartStore = createStore('shoppingCartStore')<ShoppingCartStore>(
    {
        shoppingCarts: [],
        currentStep: SELL_TICKETS_AT_DOOR_STEPS.selectTickets,
        name: '',
        email: '',
        phone: '',
        discountCode: '',
        paymentType: PAYMENT_TYPES.creditCard,
        isProcessingOrder: false,
        createOrderError: undefined,
        idempotencyKey: undefined,
        idempotencySalt: undefined,
        order: undefined,
        cardToken: undefined,
        existingAccount: undefined,
    },
    { persist: { name: 'shoppingCartStore', enabled: true } }
)
    .extendSelectors(store => ({
        shoppingCartByGroup: (groupId: string) => {
            return store.shoppingCarts?.find(cart => {
                return groupId === cart.groupId;
            });
        },
        shoppingCartProductsForGroup: (groupId: string) => {
            return (
                store.shoppingCarts?.find(cart => {
                    return groupId === cart.groupId;
                })?.products ?? []
            );
        },
    }))
    .extendActions((set, get) => ({
        setShoppingCartItem: (groupId: string, product: Product, quantity: number = 1) => {
            const shoppingCarts = get?.shoppingCarts();

            const existingShoppingCart = shoppingCarts?.find(
                shopingCart => shopingCart?.groupId === groupId
            );
            const shoppingCartIndex = shoppingCarts?.findIndex(
                shopingCart => shopingCart?.groupId === groupId
            );
            const products =
                shoppingCarts?.find(shoppingCart => shoppingCart?.groupId === groupId)?.products ??
                [];

            if (!existingShoppingCart) {
                const initShoppingCart = {
                    groupId,
                    products: [{ product, quantity, coupon: {} }],
                };
                // initialize group shopping cart
                set?.shoppingCarts([...shoppingCarts, { ...initShoppingCart }]);
            } else {
                // update existing shopping cart item
                // set?.products([
                //     ...products.map(({ product: p, quantity: qty }) => {
                //         if (p?._id === product?._id) {
                //             return {
                //                 product: p,
                //                 quantity,
                //             };
                //         }
                //         return {
                //             product: p,
                //             quantity: qty,
                //         };
                //     }),
                // ]);

                const existingProduct = _.find(
                    products,
                    ({ product: p }) => p?._id === product?._id
                );

                let updatedShoppingCartProducts = [...products];

                // update an existing shopping cart item
                if (existingProduct) {
                    updatedShoppingCartProducts = shoppingCarts?.[shoppingCartIndex]?.products?.map(
                        ({ product: p, quantity: qty, coupon: c }) => {
                            if (p?._id === product?._id) {
                                return {
                                    product: p,
                                    quantity,
                                    coupon: c,
                                };
                            }
                            return {
                                product: p,
                                quantity: qty,
                                coupon: c,
                            };
                        }
                    );
                } else {
                    // set a new shopping cart item
                    updatedShoppingCartProducts = [...products, { product, quantity, coupon: {} }];
                }

                // set the updated shopping cart
                set?.shoppingCarts([
                    ...shoppingCarts?.map(shoppingCart => {
                        if (shoppingCart?.groupId === groupId) {
                            return {
                                groupId,
                                products: updatedShoppingCartProducts,
                            };
                        }

                        return {
                            groupId: shoppingCart?.groupId,
                            products: shoppingCart?.products,
                        };
                    }),
                ]);
            }

            // clear item from shopping cart if quantity is 0
            if (quantity === 0) {
                // set?.products([...products.filter(({ product: p }) => p?._id !== product?._id)]);

                const removedShoppingCartProducts = shoppingCarts?.[
                    shoppingCartIndex
                ]?.products?.filter(({ product: p }) => p?._id !== product?._id);

                set?.shoppingCarts([
                    ...shoppingCarts?.map(shoppingCart => {
                        if (shoppingCart?.groupId === groupId) {
                            return {
                                groupId,
                                products: removedShoppingCartProducts,
                            };
                        }

                        return {
                            groupId: shoppingCart?.groupId,
                            products: shoppingCart?.products,
                        };
                    }),
                ]);
            }
        },
        clearGroupShoppingCart: (groupId: string) => {
            const shoppingCarts = get?.shoppingCarts();

            const existingShoppingCart = shoppingCarts?.find(
                shopingCart => shopingCart?.groupId === groupId
            );

            if (existingShoppingCart) {
                set?.shoppingCarts([
                    ...shoppingCarts?.map(shoppingCart => {
                        if (shoppingCart?.groupId === groupId) {
                            return {
                                groupId,
                                products: [],
                            };
                        }

                        return {
                            groupId: shoppingCart?.groupId,
                            products: shoppingCart?.products,
                        };
                    }),
                ]);
            }
        },
        setProductCoupon: (groupId: string, productId: string, coupon: DiscountCodeState) => {
            const shoppingCarts = get?.shoppingCarts();

            const existingShoppingCart = shoppingCarts?.find(
                shopingCart => shopingCart?.groupId === groupId
            );
            const shoppingCartIndex = shoppingCarts?.findIndex(
                shopingCart => shopingCart?.groupId === groupId
            );

            const products =
                shoppingCarts?.find(shoppingCart => shoppingCart?.groupId === groupId)?.products ??
                [];

            if (existingShoppingCart) {
                const existingProduct = _.find(products, ({ product: p }) => p?._id === productId);

                let updatedShoppingCartProducts = [...products];

                // update an existing shopping cart item
                if (existingProduct) {
                    updatedShoppingCartProducts = shoppingCarts?.[shoppingCartIndex]?.products?.map(
                        shoppingCartItem => {
                            if (shoppingCartItem?.product?._id === productId) {
                                return {
                                    ...shoppingCartItem,
                                    coupon,
                                };
                            }
                            return {
                                ...shoppingCartItem,
                            };
                        }
                    );
                }

                // set the updated shopping cart
                set?.shoppingCarts([
                    ...shoppingCarts?.map(shoppingCart => {
                        if (shoppingCart?.groupId === groupId) {
                            return {
                                groupId,
                                products: updatedShoppingCartProducts,
                            };
                        }

                        return {
                            groupId: shoppingCart?.groupId,
                            products: shoppingCart?.products,
                        };
                    }),
                ]);
            }
        },
        resetSellTickets: (groupId: string) => {
            set.shoppingCarts(get.shoppingCarts().filter(cart => cart.groupId !== groupId));
            set.currentStep(SELL_TICKETS_AT_DOOR_STEPS.selectTickets);
            set.name('');
            set.email('');
            set.phone('');
            set.discountCode('');
            set.paymentType(PAYMENT_TYPES.creditCard);
            set.isProcessingOrder(false);
            set.createOrderError(undefined);
            set.idempotencyKey(undefined);
            set.idempotencySalt(undefined);
            set.order(undefined);
            set.cardToken(undefined);
            set.existingAccount(undefined)
        },
    }));

export default shoppingCartStore;
