import React from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import AddSpeakerWidget from '@components/content/newpost/forms/EventGroup/AddSpeakerWidget';

import { SpeakerStateType } from '@core/types/EventGroup';

type AddSpeakerFormProps = {
    handleSaveSpeakers: (users: SpeakerStateType[]) => void;
    handleCancel: () => void;
    initialState: SpeakerStateType[];
    handleUpdateSpeakerInAllSessions: (speaker: SpeakerStateType) => void;
    allSpeakers?: SpeakerStateType[] | undefined | null;
};

const AddSpeakerForm: React.FC<AddSpeakerFormProps> = ({
    handleSaveSpeakers,
    handleCancel,
    initialState,
    handleUpdateSpeakerInAllSessions,
    allSpeakers,
}) => {
    const { t } = useTranslation();

    const [state, setState] = useImmer(initialState);

    const handleAddSpeaker = (user: SpeakerStateType) => {
        setState(draft => {
            draft.push(user);
        });
    };

    const handleUpdateSpeaker = (userId: string, user: SpeakerStateType) => {
        const index = state.findIndex(user => user._id === userId);
        setState(draft => {
            draft[index] = user;
        });
        // update the speaker in all sessions
        handleUpdateSpeakerInAllSessions(user);
    };

    const handleDeleteSpeaker = (userId: string) => {
        const _state = state.filter(user => user._id !== userId);
        setState(_state);
    };

    const handleSave = () => {
        handleSaveSpeakers(state);
        handleCancel();
    };

    const saveButtonClass = state?.length > 0 ? 'active' : 'inactive';

    return (
        <section className="add-speaker-form">
            <header>
                <h1>{t('common:add_speaker', 'Add Speaker')}</h1>
                <p>
                    {t(
                        'common:who_will_be_presenting_this_session',
                        'Who will be presenting this session?'
                    )}
                </p>

                <p className="small-600 event-form header-info">
                    {t(
                        'common:event-group-forms-skip-msg',
                        `Note: This can be skipped. After your event is published, choose the 'Edit Event' option to add or update information.`
                    )}
                </p>

                <div className="button-container">
                    <button
                        type="button"
                        className="circle-icon-button medium light-grey border-grey-white-fill arrow-back"
                        onClick={handleCancel}
                        aria-label={t('common:cancel', 'Cancel')}
                    />
                    <button
                        type="button"
                        className={`rounded-pill-btn ${saveButtonClass} animate-grow`}
                        onClick={handleSave}
                    >
                        {t('common:save', 'Save')}
                    </button>
                </div>
            </header>
            <article className="tp-body">
                <section className="speaker-search-container">
                    <AddSpeakerWidget
                        handleAddSpeaker={handleAddSpeaker}
                        handleUpdateSpeaker={handleUpdateSpeaker}
                        handleDeleteSpeaker={handleDeleteSpeaker}
                        initialUserList={state}
                        allSpeakers={allSpeakers}
                    />
                </section>
            </article>
        </section>
    );
};

export default AddSpeakerForm;
