import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@stores/User';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-spinner.json';
import Check from '@dsc/svgs/Check';
import Picture from '@components/generic/Picture';
import Plus from '@dsc/svgs/FullSizePlus';
import Clock from '@dsc/svgs/Clock2';
import AlertToast from '@components/toast/AlertToast';
import SimpleLock from '@dsc/svgs/SimpleLock';

import {
    useAddUserToGroupMutation,
    useJoinOrRequestToJoinMultipleGroupsByIdMutation,
} from '@shared/welibrary-graphql/user/mutations.hook';
import { useGetJoinGroupInfoQuery } from '@shared/welibrary-graphql/user/queries.hook';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
} from '@core/utilities/constants/defaults';

import GROUP_ACTIONS from '@core/utilities/constants/group';

type GroupSelectItemProps = {
    groupId: string;
    membershipGroupId: string;
    skipApproval?: boolean;
};

const GroupSelectItem: React.FC<GroupSelectItemProps> = ({
    groupId,
    membershipGroupId,
    skipApproval = false,
}) => {
    const { currentUser } = useCurrentUser();
    const { newToast } = useToastActionsContext();
    const { t } = useTranslation();

    const { data, loading: groupInfoLoading } = useGetJoinGroupInfoQuery({
        variables: { _id: groupId },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const group = data?.groupById;

    const [awaitingApproval, setAwaitingApproval] = useState(
        group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN)
    );
    const [requiresApproval, setRequiresApproval] = useState(
        !skipApproval && group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_REQUEST_JOIN)
    );

    const [joined, setJoined] = useState(group?.currentUserIsMember || group?.currentUserIsAdmin);

    useEffect(() => {
        if (!groupInfoLoading && group) {
            setAwaitingApproval(
                group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN)
            );
            setRequiresApproval(
                !skipApproval &&
                group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_REQUEST_JOIN)
            );
            setJoined(group?.currentUserIsMember || group?.currentUserIsAdmin);
        }
    }, [groupInfoLoading]);

    const [groupThumbSrc] = getBackgroundImageSetForUrl(
        group?.profile?.picture || getRandomDefaultCoverImage(group?.profile?.full_name)
    );

    const [joinGroups, { loading: joinGroupsLoading }] =
        useJoinOrRequestToJoinMultipleGroupsByIdMutation();
    const [addUserToGroup, { loading: addUserLoading }] = useAddUserToGroupMutation();

    const loading = groupInfoLoading || joinGroupsLoading || addUserLoading;

    const joinGroup = async () => {
        if (joined) return;

        if (awaitingApproval) {
            await addUserToGroup({
                variables: {
                    groupId,
                    userId: currentUser._id,
                    remove: true,
                },
            });

            setAwaitingApproval(false);
            setRequiresApproval(!skipApproval);

            return;
        }

        const res = await joinGroups({ variables: { groupIDs: [groupId], membershipGroupId } });

        if (res.data?.joinOrRequestToJoinMultipleGroupsByID?.[0]?.status === 'groupJoined') {
            return setJoined(true);
        }

        if (res.data?.joinOrRequestToJoinMultipleGroupsByID?.[0]?.status === 'groupRequestToJoin') {
            newToast(
                <AlertToast
                    boldText={t(
                        'common:admin_approval_is_required_in_order_to_join_this_group',
                        'Admin approval is required in order to join this group.'
                    )}
                    text={t(
                        'common:you_have_requested_approval_to_join_this_group',
                        'You have requested approval to join this group.'
                    )}
                    showSuccessIcon
                />,
                { duration: 5000 }
            );
            return setAwaitingApproval(true);
        }
    };

    const getButtonIcon = () => {
        if (joined) return <Check />;

        if (awaitingApproval) return <Clock className="h-full w-full" />;

        return <Plus strokeWidth="3" />;
    };

    const buttonIcon = getButtonIcon();

    return (
        <li className="relative pr-[10px] pt-[9px]">
            <section
                className={`flex flex-col gap-[10px] rounded-[16px] border-2 bg-white p-[10px] shadow-[0_0_4px_0_rgba(0,0,0,0.25)] transition-all ${joined ? 'border-[var(--color-wl-primary)]' : 'border-white'
                    }`}
            >
                <header className="relative flex items-center justify-center">
                    {groupInfoLoading ? (
                        <LottieLoading height={78} width={95} lottieData={LottieAnimation} />
                    ) : (
                        <Picture
                            disableLink
                            className="!m-0 max-h-[120px] flex-shrink-0 overflow-hidden rounded-[10px]"
                            url={groupThumbSrc}
                        />
                    )}
                    <section className="absolute flex max-h-full min-w-0 max-w-[calc(100%-24px)] flex-col items-center justify-center rounded-[10px] bg-[rgba(255,255,255,0.5)] p-2 backdrop-blur-sm">
                        {groupInfoLoading ? (
                            <>
                                <div
                                    role="presentation"
                                    className="animate-loading mb-2 h-4 w-full bg-gray-300"
                                />
                                <div
                                    role="presentation"
                                    className="animate-loading mb-2 h-3 w-full bg-gray-300"
                                />
                                <div
                                    role="presentation"
                                    className="animate-loading h-3 w-full bg-gray-300"
                                />
                            </>
                        ) : (
                            <>
                                <h3 className="font-poppins text-center text-base font-bold text-[var(--color-grayscale-title-active)]">
                                    {group?.profile?.full_name}
                                </h3>
                                <span className="font-poppins line-clamp-2 overflow-hidden text-ellipsis break-words text-xs font-normal text-[var(--color-grayscale-title-active)]">
                                    {group?.profile?.short_bio}
                                </span>
                            </>
                        )}
                    </section>
                </header>

                {!joined && (requiresApproval || awaitingApproval) && (
                    <section className="flex items-center gap-1">
                        <SimpleLock className="h-4 w-4" />
                        <span>
                            {awaitingApproval
                                ? t('common:awaiting_approval', 'Awaiting Approval')
                                : t('commn:requires_approval', 'Requires Approval')}
                        </span>
                    </section>
                )}
            </section>

            <button
                type="button"
                onClick={joinGroup}
                className={`absolute right-0 top-0 flex h-[34px] w-[34px] items-center justify-center rounded-full p-[7px] transition-all ${joined
                        ? 'cursor-default bg-[var(--color-wl-primary)] text-white'
                        : 'border-2 border-[var(--color-wl-primary)] bg-white text-[var(--color-wl-primary)]'
                    } ${loading ? 'rotate-180' : ''}`}
            >
                {loading ? (
                    <LottieLoading height={20} width={20} lottieData={LottieAnimation} />
                ) : (
                    buttonIcon
                )}
            </button>
        </li>
    );
};

export default GroupSelectItem;
