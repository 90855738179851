import React from 'react';

import ExternalLink from '@web/ui/components/generic/ExternalLink';
import { LocationType, EventLocationSettings } from '@shared/welibrary-graphql/types';

import { getUsefulLocData } from '@core/utilities/constants/events';

type LocationDisplayProps = {
    source: EventLocationSettings;
};

const LocationDisplay: React.FC<LocationDisplayProps> = ({ source }) => {
    if (!source) return <></>;

    const { locType } = getUsefulLocData(source);

    if (locType === LocationType.InPerson) {
        <InPersonDisplay source={source} />;
    }

    if (locType === LocationType.Virtual) {
        <VirtualDisplay source={source} />;
    }

    if (locType === LocationType.VirtualAndInPerson) {
        return (
            <div>
                <InPersonDisplay source={source} />
                <VirtualDisplay source={source} />
            </div>
        );
    }

    return <></>;
};

export default LocationDisplay;

export const InPersonDisplay: React.FC<LocationDisplayProps> = ({ source }) => {
    const { googleMapsLink, venueName, venueLink, formattedAddress } = getUsefulLocData(source);

    return (
        <>
            {venueLink && venueName && (
                <div>
                    <ExternalLink className="venue-link" to={venueLink}>
                        {venueName}
                    </ExternalLink>
                </div>
            )}

            {venueLink && !venueName && (
                <div>
                    <ExternalLink className="venue-link" to={venueLink}>
                        {venueLink}
                    </ExternalLink>
                </div>
            )}

            {!venueLink && venueName && <div>{venueName}</div>}

            <div className="formatted-address-link">
                <ExternalLink to={googleMapsLink}>{formattedAddress}</ExternalLink>
            </div>
        </>
    );
};

export const VirtualDisplay: React.FC<LocationDisplayProps> = ({ source }) => {
    const { virtualLink, virtualPlatformName } = getUsefulLocData(source);
    return (
        <div>
            {!virtualPlatformName && <ExternalLink to={virtualLink}>{virtualLink}</ExternalLink>}

            {virtualPlatformName && virtualLink && (
                <>
                    <p>{virtualPlatformName}</p>
                    <ExternalLink to={virtualLink}>{virtualLink}</ExternalLink>
                </>
            )}
        </div>
    );
};
