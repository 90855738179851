import React from 'react';
import { Link } from 'react-router-dom';

import useNavHighlighting from '@web/ui/components/navigation/hooks/useNavHighlighting';

import { ICONS } from '@core/utilities/constants/icons';

type NavigationIconProps = {
    to?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
    icon: keyof typeof ICONS;
    onContextMenu?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
    showRedDot?: boolean;
    showMobile?: boolean;
    showDesktop?: boolean;
    groupSwitch?: boolean;
    sideMenu?: boolean;
};

const NavigationIcon: React.FC<NavigationIconProps> = ({
    to,
    onClick,
    icon,
    onContextMenu,
    showRedDot = false,
    showMobile = false,
    showDesktop = false,
    groupSwitch = false,
    sideMenu = false,
}) => {
    const className = useNavHighlighting({ url: to, groupSwitch, sideMenu });

    let iconCustomClass;
    if (icon === 'group') {
        iconCustomClass = 'secondary-nav-button-icon-group';
    }

    return to ? (
        <>
            {showDesktop && (
                <Link
                    to={to}
                    className="secondary-nav-button mobile-hide w-inline-block"
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                >
                    <img src={ICONS[icon]} alt={icon} className={iconCustomClass} />
                </Link>
            )}
            {showMobile && (
                <Link
                    to={to}
                    className={`secondary-nav-button mobile-only w-inline-block ${className}`}
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                >
                    <img src={ICONS[icon]} alt={icon} className={iconCustomClass} />
                    {showRedDot && <div role="presentation" className="red-dot" />}
                </Link>
            )}
        </>
    ) : (
        <>
            {showDesktop && (
                <button
                    type="button"
                    className="secondary-nav-button mobile-hide w-inline-block"
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                >
                    <img src={ICONS[icon]} alt={icon} className={iconCustomClass} />
                </button>
            )}
            {showMobile && (
                <button
                    type="button"
                    className={`secondary-nav-button mobile-only w-inline-block ${className}`}
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                >
                    <img src={ICONS[icon]} alt={icon} className={iconCustomClass} />
                    {showRedDot && <div role="presentation" className="red-dot" />}
                </button>
            )}
        </>
    );
};

export default NavigationIcon;
