import React, { memo, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

import searchStore from '@stores/Search';

import SearchOverlay from '@components/search/SearchOverlay';
import useSearchJob from './hooks/useSearch';

const SearchOverlayQuery: React.FC = memo(function SearchOverlayQuery() {
    const { refreshing } = searchStore.useStore();

    const allTab = searchStore.useTracked.isAllTab();

    const { searchResults, loading, hasMoreResults, networkStatus, error, fetchMore } =
        useSearchJob();

    if (refreshing && networkStatus !== 3 && hasMoreResults && !allTab) {
        fetchMore();
        searchStore.set.refreshing(false);
    }

    return (
        <SearchOverlay
            error={error}
            loading={loading}
            searchResults={searchResults}
            hasMoreResults={hasMoreResults}
        />
    );
});

const SearchOverlayContainer = memo(function SearchOverlayContainerMemo() {
    const isSearchOverlayOpen = searchStore.use.isVisible();

    useEffect(() => {
        // bodylock to prevent body from scrolling
        const bodyElement = document.querySelector('body');
        const screenWidth = window.innerWidth > 479;
        if (bodyElement) {
            if (isSearchOverlayOpen && screenWidth) {
                bodyElement.style.overflow = 'hidden';
            } else {
                const modalEl = document.querySelector('.modal-body');
                if (!modalEl && screenWidth) {
                    bodyElement.style.overflow = 'auto';
                }
            }
            return () => {
                const modalEl = document.querySelector('.modal-body');
                if (!modalEl && screenWidth) {
                    bodyElement.style.overflow = 'auto';
                }
            };
        }
    }, [isSearchOverlayOpen]);

    const renderSearchOverlay = isSearchOverlayOpen ? <SearchOverlayQuery /> : null;
    return renderSearchOverlay;
});

export default SearchOverlayContainer;
