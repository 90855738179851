import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';
import { useQuery } from '@apollo/client';
import {
    GET_CHAPTER_CHILDREN,
    GET_SINGLE_CARD_BY_ID,
} from '@shared/welibrary-graphql/content_card/queries';

import useInfiniteScroll from '@web/ui/components/generic/hooks/useInfiniteScroll';

import ContentCard from '@web/ui/components/card/ContentCard.js';
import LoadingIndicator from '@web/ui/components/generic/loading/LoadingIndicator';
import BigScreenBody from '@web/ui/pages/leaderboard/BigScreenBody';
import VoteOrSkipController from '@web/ui/components/generic/voteorskip/VoteOrSkipController';

import {
    FILTER_AZ,
    FILTER_LEADERBOARD,
    FILTER_LIVE,
    FILTER_OLDEST_FIRST,
    FILTER_ORDERED,
    FILTER_TOP,
    FILTER_VOTE,
} from '@core/utilities/constants/library_settings';

import {
    CARD_HEADER,
    CARD_LINK_TILE,
    CARD_POSTBOX,
    CARD_VOTE_GALLERY,
} from '@core/utilities/constants/card_types.js';

import { sortHelpers } from '@shared/welibrary-apollo/helpers/sort';
import { sortCardsByPinned } from '@helpers/contentCard/contentCard.helpers';

import IntroContent from '@web/ui/components/generic/IntroContent';

import getLogger from '@core/logger';

const logger = getLogger(module);

const LEADERBOARD_POLL_INTERVAL = 10000;
const VOTE_OR_NEXT_POLL_INTERVAL = 10000;

const USE_CLIENT_SORT_TYPES = [FILTER_LIVE, FILTER_OLDEST_FIRST, FILTER_AZ, FILTER_ORDERED];

const ChapterContent = ({
    tagFilters = [],
    sortType,
    setSortType,
    chapterSettings,
    chapterId,
    voteSettings,
    noContentToVoteOrSkip,
    currentTerminalUrl,
    gotoDefaultChapterSort,
    backgroundImage,
    cardExtraClasses,
    manualFetchMore,
    metaChannelCard,
}) => {
    const { t } = useTranslation();
    const { data: chapterData } = useQuery(GET_SINGLE_CARD_BY_ID, {
        variables: { _id: chapterId },
        skip: !chapterId,
    });
    let chapterCard = chapterData?.card;
    let chapterPinnedCardIds = chapterCard?.pinnedCardIds;

    if (metaChannelCard) {
        // pin cards against the containerized meta channel
        chapterCard = metaChannelCard;
        chapterPinnedCardIds = metaChannelCard?.pinnedCardIds;
    }

    const { currentUser } = useCurrentUser();

    const isLeaderboard = sortType === FILTER_LEADERBOARD;
    const isVoteOrNext = sortType === FILTER_VOTE;

    const initialRetrieveCount = 10;

    const tagIds = tagFilters?.map(({ _id }) => _id);

    const {
        queryResult: { loading, error, data, fetchMore, startPolling, stopPolling },
        setRef,
    } = useInfiniteScroll({
        query: {
            query: GET_CHAPTER_CHILDREN,
            options: {
                variables: {
                    url: currentTerminalUrl,
                    limit: initialRetrieveCount,
                    sortType,
                    tags: tagIds,
                },
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                skip: !currentTerminalUrl,
            },
        },
    });

    if (error) {
        logger.error(error);
        return (
            <IntroContent
                declaration={t('common:global.introContentErr.declaration')}
                instruction={t('common:global.introContentErr.instruction')}
                buttonTitle={t('common:global.introContentErr.button_title')}
                type="error"
            />
        );
    }
    if (!data) return <LoadingIndicator loading={loading} type="skeleton" size="fill" repeat />;

    if (isLeaderboard) startPolling(LEADERBOARD_POLL_INTERVAL);
    else if (isVoteOrNext)
        if (data.paginatedChildrenFromCard?.results?.length <= 0)
            startPolling(VOTE_OR_NEXT_POLL_INTERVAL);
        else stopPolling();
    else stopPolling();

    let childrenFromCard = data.paginatedChildrenFromCard?.results?.map((card, index) => ({
        ...card,
        rank: index + 1,
    }));

    if (sortType && USE_CLIENT_SORT_TYPES.includes(sortType))
        childrenFromCard = sortHelpers.sortContentCards(childrenFromCard, sortType);

    const hasMore = data.paginatedChildrenFromCard?.hasMore;

    const handleFetchMore = () => {
        try {
            fetchMore({
                variables: {
                    cursor: data[Object.keys(data)[0]]?.cursor,
                },
            });
        } catch (e) {
            logger.error(error);
        }
    };

    if (isVoteOrNext) {
        if (childrenFromCard?.length <= 0) noContentToVoteOrSkip();

        return (
            <VoteOrSkipController
                childrenFromCard={childrenFromCard}
                fetchMore={fetchMore}
                isLeaderboard={isLeaderboard}
                initialRetrieveCount={initialRetrieveCount}
                chapterSettings={chapterSettings}
                voteSettings={voteSettings}
                customClass="we-vote-or-skip-chapterfeed"
                backgroundImage={backgroundImage}
                setSortOfChapter={type => setSortType(type)}
                gotoDefaultChapterSort={() => gotoDefaultChapterSort()}
                startPolling={startPolling}
            />
        );
    }

    const excludeTypesFromBigScreen = [
        CARD_POSTBOX,
        CARD_VOTE_GALLERY,
        CARD_LINK_TILE,
        CARD_HEADER,
    ];

    const rows = [];
    const ref = manualFetchMore ? null : setRef;

    if (isLeaderboard) {
        childrenFromCard?.forEach(card => {
            if (!excludeTypesFromBigScreen.includes(card.type)) {
                rows.push(<BigScreenBody post={card} key={card._id} rank={card.rank} smallText />);
            }
        });
    } else {
        const childrenFromCardSortedByPinned = sortCardsByPinned(
            childrenFromCard,
            chapterPinnedCardIds
        );
        childrenFromCardSortedByPinned?.forEach((card, index) => {
            if (card?._id === metaChannelCard?._id) return;

            let displaySocialHeaders = true;

            if (chapterSettings?.library_settings?.hasOwnProperty('displaySocialHeaders'))
                displaySocialHeaders = chapterSettings.library_settings.displaySocialHeaders;

            if (
                card?.library_settings?.hasOwnProperty('displaySocialHeaders') &&
                displaySocialHeaders
            ) {
                displaySocialHeaders = card.library_settings.displaySocialHeaders;
            }

            let topGreyBorder = true;
            let bottomGreyBorder = true;

            // Get the previous card. If the previous card was a postbox, disable the grey border.
            if (rows.length >= 1) {
                if (childrenFromCard?.length > rows.length - 1) {
                    const prevCard = childrenFromCard[rows.length - 1];

                    if (prevCard.type === CARD_POSTBOX && card.type === CARD_POSTBOX)
                        topGreyBorder = false;
                }
            }

            if (rows.length + 1 < childrenFromCard.length) {
                const nextCard = childrenFromCard[rows.length + 1];
                if (nextCard.type === CARD_POSTBOX) bottomGreyBorder = false;
            }
            if (card) {
                rows.push(
                    <ContentCard
                        card={card}
                        key={card._id}
                        idxTotal={childrenFromCard?.length}
                        idx={index}
                        currentTerminalURL={currentTerminalUrl}
                        currentUser={currentUser}
                        chapterSettings={chapterSettings}
                        voteSettings={voteSettings}
                        isLeaderboard={isLeaderboard}
                        rank={card.rank}
                        noDate={sortType === FILTER_TOP || sortType === FILTER_ORDERED}
                        hideTopRightDropdown={false}
                        topGreyBorder={topGreyBorder}
                        extraClasses={`${cardExtraClasses}${bottomGreyBorder ? '' : ' disable-bottom-grey'
                            }`}
                        parentCard={chapterCard}
                        pinnable
                        noRecursiveMetaCard={Boolean(metaChannelCard)}
                    />
                );
            }
        });
    }

    if (rows.length <= 0 && tagFilters?.length === 0) {
        return (
            <div>
                <IntroContent
                    declaration={t('common:global.introContentErr.nothing_posted.declaration')}
                    instruction={t('common:global.introContentErr.nothing_posted.instruction')}
                    type="nocontent"
                />
            </div>
        );
    }

    if (rows.length <= 0 && tagFilters.length > 0) {
        return (
            <div>
                <IntroContent
                    declaration={t('common:global.introContentErr.no_posts.declaration')}
                    type="nocontent"
                />
            </div>
        );
    }

    return (
        <div className="fadein" id={`${currentTerminalUrl}-fade-in`}>
            {rows}
            <div ref={ref} />
            {manualFetchMore && hasMore && (
                <div className="fetch-more-container">
                    <button type="button" onClick={handleFetchMore}>
                        {t('common:global.actions.feed.view_more')}
                    </button>
                </div>
            )}
            {hasMore && !manualFetchMore && <LoadingIndicator type="skeleton" size="fill" />}
        </div>
    );
};

export default ChapterContent;
