import {useContext} from 'react';

import {ChapterContext} from '@web/ui/components/chapter/ChapterContext';

/**
 * Provides an interface to the Chapter Context. This includes reading and setting the current
 * chapter, scrolling to a given chapter, and all the logic associated with the fixed ActionArea.
 *
 * @return {{
 *     currentChapter: string,
 *     setCurrentChapter: (url: string, animate: boolean) => void,
 *     setCurrentChapterAndScrollToIt: (url: string, animate: boolean) => void,
 *     addScrollRef: (url: string, ref: { [key: string]: any, scrollIntoView: () => void }),
 *     scrollToChapter: (url: string) => void,
 *     actionAreaScrollHandler: React.UIEventHandler<HTMLElement>,
 *     isActionAreaHidden: boolean,
 *     showActionArea: () => void,
 *     hideActionArea: () => void,
 *     isAnimating: boolean,
 * }}
 */
const useChapterContext = () => {
    /**
     * Components that use this hook may be rendered outside of a ChapterProvider. We need to allow
     * these components to still render, but simply disable the chapter switching logic that this
     * hook provides in such a situation.
     */
    const {
        currentChapter,
        setCurrentChapter = () => {},
        scrollRefs = {},
        setScrollRefs = () => {},
        actionAreaScrollHandler,
        isActionAreaHidden,
        showActionArea = () => {},
        hideActionArea = () => {},
        isAnimating = { current: false },
    } = useContext(ChapterContext) ?? {};

    const addScrollRef = (url, ref) => setScrollRefs(oldRefs => ({ ...oldRefs, [url]: ref }));

    const scrollToChapter = url => {
        scrollRefs[url]?.scrollIntoView?.();
    };

    const setCurrentChapterWithAnimation = (url, animate = true) => {
        isAnimating.current = animate;
        setCurrentChapter(url);
    };

    const setCurrentChapterAndScrollToIt = (url, animate = true) => {
        setCurrentChapterWithAnimation(url, animate);
        scrollToChapter(url);
    };

    return {
        currentChapter,
        setCurrentChapter: setCurrentChapterWithAnimation,
        setCurrentChapterAndScrollToIt,
        addScrollRef,
        scrollToChapter,
        actionAreaScrollHandler,
        isActionAreaHidden,
        showActionArea,
        hideActionArea,
        isAnimating: isAnimating.current,
    };
};

export default useChapterContext;
