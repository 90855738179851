import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Picture from '@components/generic/Picture';
import X from '@dsc/svgs/FullSizeX';

import signUpStore from '@stores/SignUp';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

import { Group, LocationType } from '@shared/welibrary-graphql/types';
import { getFormattedDateDisplayFromTimes } from '~/wl-core/utilities/constants/events';

type GroupSummaryProps = {
    group: Group;
    showX?: boolean;
    handleXClickOverride?: () => void;
    eventLinksToDashboard?: boolean;
};

const GroupSummary: React.FC<GroupSummaryProps> = ({
    group,
    showX = true,
    handleXClickOverride,
    eventLinksToDashboard = false,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    const defaultHandleXClick = () => {
        signUpStore.set._id(''); // clear group._id
        signUpStore.set.groupCode(''); // clear group.settings.ShortCode
        signUpStore.set.activeGroup(undefined); // clear group from local state
        signUpStore.set.isSignUpWithGroupCode(false);
        signUpStore.set.usedGroupCode(false);
        signUpStore.set.isSignUpWithSearch(false);
        signUpStore.set.paymentMethodToken(undefined); // clear payment method
    };

    const handleXClick = handleXClickOverride ?? defaultHandleXClick;
    const image = group?.profile?.picture ?? getRandomDefaultCoverImage(group?.profile?.full_name);

    const isSubscriptionGroup = group?.doesSellMembership && group?.subtype !== 'event';

    const isEventGroup = group?.subtype === 'event';

    const eventOverviewDetails = group?.eventOverviewDetails;
    const eventLocationSettings = eventOverviewDetails?.eventLocationSettings;

    const datesDisplay = getFormattedDateDisplayFromTimes(eventOverviewDetails);
    const locationType = eventOverviewDetails?.eventLocationSettings?.type;
    const formattedAddress = eventLocationSettings?.venue?.location?.formatted_address || '';

    const useEventLink = isEventGroup && eventLinksToDashboard;

    return (
        <div
            className={`flex gap-[10px] items-center rounded-[16px] p-[10px] pr-[20px] shadow-around bg-white w-full ${useEventLink ? 'hover:bg-grayscale-off-white' : ''
                }`}
            onClick={useEventLink ? () => history.push(`/g/${group?._id}`) : undefined}
            role={useEventLink ? 'button' : ''}
        >
            <Picture
                url={image}
                className={`${isEventGroup
                        ? '!h-[60px] !w-[60px] !min-h-[60px] !min-w-[60px]'
                        : '!h-[50px] !w-[50px] !min-h-[50px] !min-w-[50px]'
                    } rounded-[10px] overflow-hidden`}
                disableLink
            />
            <div className="flex flex-col font-poppins grow">
                {isSubscriptionGroup && (
                    <span className="text-grayscale-title-active text-[12px] font-[600] leading-[22px] tracking-[0.25px] uppercase">
                        {group?.settings?.protectionLevel}{' '}
                        {t(`common:global.subscription`, 'Subscription')}
                    </span>
                )}

                <span className="text-grayscale-title-active text-[17px] leading-[22px] tracking-[0.25px]">
                    {group?.profile?.full_name}
                </span>
                {!isEventGroup && (
                    <span className="text-grayscale-placeholder text-[14px] font-[600] leading-[22px] tracking-[0.25px]">
                        {group?.profile?.short_bio}
                    </span>
                )}
                {isEventGroup && (
                    <div className="flex flex-col">
                        <span className="text-grayscale-placeholder text-[12px] font-[600] tracking-[0.25px]">
                            {datesDisplay}
                        </span>
                        {(locationType === LocationType.InPerson ||
                            locationType === LocationType.VirtualAndInPerson) && (
                                <span className="text-grayscale-placeholder text-[12px] font-[600] tracking-[0.25px]">
                                    {formattedAddress}
                                </span>
                            )}
                        {useEventLink && (
                            <span className="text-primary-default text-[14px] font-[500]">
                                {t(`common:global.view_event`, 'View Event')}
                            </span>
                        )}
                    </div>
                )}
            </div>
            {showX && (
                <button type="button" className="text-grayscale-placeholder" onClick={handleXClick}>
                    <X size="18" />
                </button>
            )}
        </div>
    );
};

export default GroupSummary;
