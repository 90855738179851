import React from 'react';

type PreferencesIconProps = {
    className?: string;
};

const PreferencesIcon: React.FC<PreferencesIconProps> = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 2000 2000" width="400" height="400">
        <circle
            cx="500.5"
            cy="583.5"
            r="333.5"
            stroke="currentcolor"
            strokeWidth="200px"
            fill="none"
        />
        <line
            x1="917"
            y1="583.5"
            x2="1750"
            y2="583.5"
            stroke="currentcolor"
            strokeWidth="200px"
            strokeLinecap="round"
        />

        <circle
            cx="1416.5"
            cy="1416.5"
            r="333.5"
            stroke="currentcolor"
            strokeWidth="200px"
            fill="none"
        />
        <line
            x1="167"
            y1="1416.5"
            x2="1083"
            y2="1416.5"
            stroke="currentcolor"
            strokeWidth="200px"
            strokeLinecap="round"
        />
    </svg>
);

export default PreferencesIcon;
