/* eslint-disable no-nested-ternary */

import React, { ChangeEventHandler, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

type TextAreaProps = {
    autoResize?: boolean;
    autoComplete?: string;
    name?: string;
    placeholder?: string;
    value?: string;
    defaultValue?: string;
    maxCharacter: number;
    title?: string;
    handleChange?: ChangeEventHandler<HTMLTextAreaElement>;
    onInput?: (value: string) => void;
    defaultClasses?: string;
    defaultAutoResizeClasses?: string;
    className?: string;
    minHeight?: string | number;
    noResize?: boolean;
    readOnly?: boolean | string;
    showCount?: boolean;
    inverseCountDisplay?: boolean;
    countClassName?: string;
    wrapperClassName?: string;
    titleClassName?: string;
    onBlur?: () => void;
    error?: boolean;
    helperText?: string;
    required?: boolean;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
    {
        autoResize,
        autoComplete,
        name,
        placeholder,
        value,
        defaultValue,
        maxCharacter,
        title,
        handleChange,
        onInput,
        defaultClasses = 'text-field w-input',
        defaultAutoResizeClasses = 'new-post-field default w-input',
        className = '',
        minHeight,
        noResize,
        readOnly,
        showCount = true,
        inverseCountDisplay = false,
        countClassName = '',
        wrapperClassName = '',
        titleClassName = '',
        onBlur,
        helperText,
        error,
        required,
    },
    ref
) {
    const [wordCount, setWordCount] = useState(value?.length ?? 0);

    const _handleChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
        setWordCount(e.target.value.length);
        handleChange?.(e);
    };

    let displayTextArea = value ? (
        <textarea
            ref={ref}
            value={value !== null ? value : undefined}
            id={name}
            onChange={_handleChange}
            onBlur={onBlur}
            onInput={e => onInput(e.target.value)}
            name={name}
            error={error}
            required={required}
            placeholder={placeholder}
            maxLength={maxCharacter}
            className={`${defaultClasses} ${className}`}
            style={{ minHeight: minHeight || '', resize: noResize ? 'none' : undefined }}
        />
    ) : (
        <textarea
            ref={ref}
            defaultValue={defaultValue || ''}
            id={name}
            onChange={_handleChange}
            onBlur={onBlur}
            onInput={e => onInput(e.target.value)}
            name={name}
            error={error}
            required={required}
            placeholder={placeholder}
            maxLength={maxCharacter}
            className={`${defaultClasses} ${className}`}
            style={{ minHeight: minHeight || '', resize: noResize ? 'none' : undefined }}
        />
    );

    if (readOnly) {
        displayTextArea = (
            <textarea
                ref={ref}
                data-testid={`textarea-${name}`}
                className={`${defaultClasses} ${className}`}
                readOnly
                placeholder={placeholder}
            />
        );
    }

    const charCounter = wordCount ? maxCharacter - wordCount : maxCharacter;

    if (autoResize) {
        displayTextArea = (
            <TextareaAutosize
                autoComplete={autoComplete}
                id={name}
                name={name}
                onChange={_handleChange}
                onBlur={onBlur}
                onInput={e => onInput(e.target.value)}
                error={error}
                required={required}
                placeholder={placeholder}
                value={value !== null ? value : undefined}
                className={`${defaultAutoResizeClasses} ${className} ${charCounter === 0 ? 'border-red-700 border-2' : ''
                    }`}
                maxLength={maxCharacter}
                style={{
                    resize: 'none',
                    overflow: 'hidden',
                    minHeight: '30px',
                    height: '30px',
                }}
            />
        );
    }

    return (
        <div className={wrapperClassName}>
            {title && (
                <div className="field-label">
                    <div className={`field-title ${titleClassName}`}>{title}</div>
                </div>
            )}
            {displayTextArea}
            {showCount && (
                <div
                    className={`login-text-field-subtext darker ${inverseCountDisplay ? 'login-text-field-subtext-inverse-count' : ''
                        } ${countClassName}`}
                >
                    {charCounter}
                </div>
            )}
            {(charCounter === 0 || helperText) && (
                <p
                    className={
                        charCounter === 0
                            ? 'text-red-700 mx-[14px] text-xs'
                            : 'text-[#0000008a] mx-[14px] text-xs'
                    }
                >
                    {charCounter === 0 ? 'Character limit reached!' : helperText}
                </p>
            )}
        </div>
    );
});

export default TextArea;
