// Aligns with Stripe order statuses: https://stripe.com/docs/payments/intents#intent-statuses
export enum OrderStatusEnum {
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    Processing = 'processing',
    RequiresConfirmation = 'requires_confirmation', // draft orders
    RequiresPaymentMethod = 'requires_payment_method', // failed payments
}

// Aligns with Stripe refund statuses: https://stripe.com/docs/api/refunds/object#refund_object-status
export enum RefundStatusEnum {
    Succeeded = 'succeeded',
    Pending = 'pending',
    Failed = 'failed',
}

export enum SubscriptionStatusEnum {
    Active = 'active',
    Incomplete = 'incomplete',
    Incomplete_Expired = 'incomplete_expired',
    Trialing = 'trialing',
    Past_due = 'past_due',
    Canceled = 'canceled',
    Unpaid = 'unpaid',
}

export const convertCentsToDollars = (cents: number): string => {
    if (!cents) return Number(0).toFixed(2);
    return parseFloat(cents / 100).toFixed(2);
};
