import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_USERS_IN_SAME_SILO } from '@shared/welibrary-graphql/user/queries';

import Picture from '@web/ui/components/generic/Picture';
import ExternalLink from '@web/ui/components/generic/ExternalLink';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    getRandomDefaultUserImage,
} from '@core/utilities/constants/defaults';
import {
    getFacebookUrl,
    getInstagramUrl,
    getLinkedinUrl,
    getTwitterUrl,
} from '@components/profile/helpers';

import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';

import { useCreateMessageThreadMutation } from '@shared/welibrary-graphql/messaging/mutations.hook';

import { Maybify } from '@core/types/Utilities';
import { Group, User } from '@shared/welibrary-graphql/types';
import { SOCIAL_ACTIONS } from '@core/utilities/constants/user';

const localNamespace = 'imports.wlWeb.ui.components.community';

const QuickProfileView: React.FC<{
    user: Maybify<User>;
    group?: Maybify<Group>;
    containerClass?: string;
}> = ({ user, containerClass = '', group }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const {data, loading, error} = useQuery(GET_USERS_IN_SAME_SILO, {variables: { otherUserId: user._id}})
    const isUserInSameSilo = data?.usersInSameSilo;

    const { currentUser } = useCurrentUser();
    const { closeAllModals } = useModal();

    const [createMessageThreadMutation] = useCreateMessageThreadMutation();

    const {
        full_name,
        picture,
        coverPhoto,
        short_bio,
        full_bio,
        website,
        organization,
        facebookProfile,
        twitterProfile,
        instagramProfile,
        linkedInProfile,
    } = group?.profile ?? user?.profile ?? {};

    const bio = short_bio;

    const thumbnail =
        picture ??
        (group ? getRandomDefaultCoverImage(full_name ?? '') : getRandomDefaultUserImage());
    const cover = coverPhoto ?? getRandomDefaultCoverImage(full_name ?? '');

    const [src] = getBackgroundImageSetForUrl(thumbnail);
    const [coverSrc] = getBackgroundImageSetForUrl(cover);

    return (
        <section className={`quick-profile-view ${containerClass}`}>
            <div className="quick-profile-view-header">
                <Picture
                    className="quick-profile-view-header-cover"
                    url={coverSrc}
                    resolutions={[600, 1200, 1800]}
                    disableLink
                />
                <Picture
                    className="quick-profile-view-header-thumb"
                    url={src}
                    disableLink
                    resolutions={[130, 260, 390]}
                />
            </div>
            <div className="quick-profile-view-info">
                <h1>{full_name}</h1>

                {organization && (
                    <h5 className="profile-organization-badge">{organization.name}</h5>
                )}
                {bio && <p className="quick-profile-view-info-bio">{bio}</p>}
                {full_bio && (
                    <>
                        <h5 className="profile-organization-badge">
                            {t(`common:${localNamespace}.about`, 'About')}
                        </h5>
                        <p className="quick-profile-view-info-bio">{full_bio}</p>
                    </>
                )}

                {website && (
                    <ExternalLink className="quick-profile-view-info-website" to={`//${website}`}>
                        <p>{website}</p>
                    </ExternalLink>
                )}
            </div>
            <div className="quick-profile-view-socials-wrap">
                {facebookProfile && (
                    <ExternalLink
                        to={getFacebookUrl(facebookProfile)}
                        className="social-media-link w-inline-block quick-profile-view-socials-btn"
                    />
                )}
                {twitterProfile && (
                    <ExternalLink
                        to={getTwitterUrl(twitterProfile)}
                        className="social-media-link twitter w-inline-block quick-profile-view-socials-btn"
                    />
                )}
                {instagramProfile && (
                    <ExternalLink
                        to={getInstagramUrl(instagramProfile)}
                        className="social-media-link ig w-inline-block quick-profile-view-socials-btn"
                    />
                )}
                {linkedInProfile && (
                    <ExternalLink
                        to={getLinkedinUrl(linkedInProfile)}
                        className="social-media-link linkedin w-inline-block quick-profile-view-socials-btn"
                    />
                )}
            </div>
            <div className="quick-profile-view-btn-wrap">
                {isUserInSameSilo && (
                    <>
                    {currentUser?._id !== user?._id &&
                    user?.socialActionsAllowed?.includes(SOCIAL_ACTIONS.MESSAGE) && (
                        <button
                            className="quick-profile-view-btn-msg"
                            type="button"
                            onClick={() => {
                                closeAllModals();
                                createMessageThreadMutation({
                                    variables: {
                                        input: {
                                            participants: [currentUser?._id, user?._id],
                                        },
                                    },
                                    update: (cache, { data: { createMessageThread } }) => {
                                        const threadId = createMessageThread?._id;

                                        history.push({
                                            pathname: `/messages/${threadId}`,
                                            state: {
                                                user,
                                            },
                                        });
                                    },
                                });
                            }}
                        >
                            {t(`common:${localNamespace}.message`)}
                        </button>
                    )}
                <button
                    className="quick-profile-view-btn-profile"
                    type="button"
                    onClick={() => {
                        closeAllModals();
                        if (group) {
                            history.push(`/g/${group?._id}`);
                        } else {
                            history.push(`/u/${user?._id}`);
                        }
                    }}
                >
                    {group
                        ? t(`common:${localNamespace}.view_group_profile`)
                        : t(`common:${localNamespace}.view_profile`)}
                </button>
                </>
            )}
            {!isUserInSameSilo && 
            <p className='text-[18px]'>You may not view this profile.<br /> 
                This user is not a member of any of your groups.
            </p>
            }
            </div>
        </section>
    );
};

export default QuickProfileView;
