import React, { useEffect } from 'react';
import { Updater } from 'use-immer';

import useModal from '@components/modals/hooks/useModal';

import TextInput from '@dsc/forms/textInputs/TextInput';
import SearchFormListCards from '@components/search/form/SearchFormListCards';
import ImageUpload from '@dsc/forms/customInputs/ImageUpload';
import CreationCustomInputImage from '@components/content/newpost/forms/Creation/CreationCustomInputImage';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { getRandomDefaultThumbImage } from '@core/utilities/constants/defaults';

import { CARD_COURSE } from '@core/utilities/constants/card_types';

import { CreationStateMain } from '@core/types/Creations';
import { Entity } from '@shared/welibrary-graphql/types';

type CreationFormMainProps = {
    state: CreationStateMain;
    setState: Updater<CreationStateMain>;
};

const CreationFormMain = React.forwardRef<HTMLFieldSetElement, CreationFormMainProps>(
    function CourseFormMain({ state, setState }, ref) {
        const updateSlice = curriedStateSlice(setState);
        const { newModal, closeModal } = useModal();

        const handleAddCourse = (course?: Entity | null) => {
            if (course?.__typename === 'ContentCard') updateSlice('course', course);

            closeModal();
        };

        const handleCourseSearchModal = () => {
            const types = [CARD_COURSE];
            newModal(
                <SearchFormListCards
                    types={types}
                    title="Select a Course"
                    addResult={handleAddCourse}
                />,
                {
                    className: 'no-bottom-padding',
                }
            );
        };

        useEffect(() => {
            if (state.course?.courseItems?.levels) {
                const levels = state.course?.courseItems?.levels?.map(({ title }, index) => ({
                    image: { image: state.levels?.[index]?.image.image ?? '' },
                    title: state.levels?.[index]?.title ?? title,
                    description: state.levels?.[index]?.description ?? '',
                }));

                updateSlice('levels', levels);
            }
        }, [state.course?.courseItems?.levels]);

        const buttonText = state?.course?.title || 'Select Course';

        return (
            <fieldset ref={ref} className="course-form-main creation">
                <p>Upload the initial starting state image for the creation (required)</p>

                <ImageUpload
                    state={{
                        ...state.thumbnail,
                        image: state.thumbnail.image || getRandomDefaultThumbImage(state.title),
                    }}
                    setState={updateSlice('thumbnail')}
                    className="thumbnail-upload"
                    loaderSize={80}
                    small
                />

                <p>
                    Upload the background image for the creation - currently this will be used
                    across all creation states (optional)
                </p>
                <ImageUpload
                    state={{
                        ...state.coverImage,
                        image: state.coverImage.image || getRandomDefaultThumbImage(state.title),
                    }}
                    setState={updateSlice('coverImage')}
                    className="thumbnail-upload"
                    loaderSize={80}
                    small
                />

                <p>Upload a silhouetted version of the final creation (optional)</p>
                <ImageUpload
                    state={{
                        ...state.silhouetteImage,
                        image:
                            state.silhouetteImage.image || getRandomDefaultThumbImage(state.title),
                    }}
                    setState={updateSlice('silhouetteImage')}
                    className="thumbnail-upload"
                    loaderSize={80}
                    small
                />

                <TextInput value={state.title} onChange={updateSlice('title')} label="Title" />
                <TextInput
                    value={state.description}
                    onChange={updateSlice('description')}
                    label="Description"
                    multiline
                />
                <p>
                    Choose a course to associate this creation with. For each level, select an image
                    to represent the state of the creation after successful completion (required for
                    every level)
                </p>
                <button type="button" onClick={handleCourseSearchModal}>
                    {buttonText}
                </button>

                {state.levels && (
                    <CreationCustomInputImage
                        labels={state.levels?.map(({ title }) => title)}
                        state={state.levels}
                        onChange={levels => {
                            updateSlice('levels', levels);
                        }}
                        background={state.coverImage.image}
                        title={state.title}
                        modalOptions={{ className: 'full-height no-bottom-padding' }}
                        label="Levels"
                    />
                )}
            </fieldset>
        );
    }
);

export default CreationFormMain;
