import React from 'react';

const PaperPlaneIcon: React.FC<{
    className?: string;
    size?: string;
    strokeWidth?: string;
    color?: string;
}> = ({ className, size = '24', strokeWidth = '2', color = '#ffffff' }) => (
    <svg
        className={className}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.7109 3.36355L2.24269 8.29049C2.09574 8.33194 1.96493 8.41725 1.86776 8.53503C1.77059 8.65281 1.71169 8.79744 1.69892 8.9496C1.68615 9.10175 1.72012 9.25418 1.7963 9.38651C1.87248 9.51884 1.98723 9.62476 2.12523 9.69013L10.1512 13.4919C10.3078 13.5661 10.4338 13.6921 10.508 13.8487L14.3098 21.8747C14.3751 22.0127 14.4811 22.1274 14.6134 22.2036C14.7457 22.2798 14.8981 22.3138 15.0503 22.301C15.2025 22.2882 15.3471 22.2293 15.4649 22.1321C15.5827 22.035 15.668 21.9042 15.7094 21.7572L20.6364 4.28898C20.6725 4.16067 20.6739 4.02503 20.6402 3.89603C20.6066 3.76703 20.5391 3.64933 20.4448 3.55506C20.3506 3.46079 20.2329 3.39335 20.1039 3.35969C19.9749 3.32603 19.8392 3.32736 19.7109 3.36355Z"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.3933 13.6066L14.636 9.36395"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default PaperPlaneIcon;
