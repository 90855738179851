import { Deploy } from 'cordova-plugin-ionic';
import { config } from '@config/getConfig';
import liveUpdateStore from '@stores/LiveUpdate';
import getLogger from '@core/logger';
import redirectStore from '@stores/Redirect';

const logger = getLogger(module);

export const performManualUpdate = async () => {
    const update = await Deploy.checkForUpdate();

    // If a live update is available, set the status in zustood store that the
    // liveupdate modal consumes
    if (update.available) {
        try {
            // We have an update!
            liveUpdateStore.set.updateAvailable(true);

            await Deploy.downloadUpdate(progress => {
                liveUpdateStore.set.status('DOWNLOAD');
                liveUpdateStore.set.downloadProgress(progress);
            });
            await Deploy.extractUpdate(progress => {
                liveUpdateStore.set.status('EXTRACT');
                liveUpdateStore.set.extractProgress(progress);
            });

            liveUpdateStore.set.status('COMPLETE');
            setTimeout(async () => {
                redirectStore.set.url(
                    `${window.location.pathname}${window.location.search}${window.location.hash}`
                );
                await Deploy.reloadApp();
            }, 2000);
        } catch (e) {
            logger?.error?.(e);
        }
    }
};

export default async () => {
    const { appflow = {} } = config?.public;
    const { liveUpdate = {} } = appflow;

    await Deploy.configure(liveUpdate);

    // Check config to see if auto live updates are turned off
    // If auto live updates are turned off, use manual live update
    if (liveUpdate?.updateMethod !== 'auto') {
        await performManualUpdate();
    }
};

export const updateChannel = async (channel: string) => {
    await Deploy.configure({ channel });
};
