// src/tag/mutations.ts
import { gql as gql2 } from "@apollo/client";

// src/tag/fragments.ts
import { gql } from "@apollo/client";
var TAG_FRAGMENT = gql`
    fragment TagFragment on Tag {
        __typename
        _id
        label
        color
        created
        updated
    }
`;

// src/tag/mutations.ts
var CREATE_TAG = gql2`
    mutation CreateTag($input: NewTagInput) {
        createTag(input: $input) {
            ...TagFragment
        }
    }
    ${TAG_FRAGMENT}
`;
var UPDATE_TAG = gql2`
    mutation UpdateTag($input: UpdateTagInput) {
        updateTag(input: $input) {
            ...TagFragment
        }
    }
    ${TAG_FRAGMENT}
`;
var DELETE_TAG = gql2`
    mutation DeleteTag($tagId: String) {
        deleteTag(tagId: $tagId)
    }
`;
export {
  CREATE_TAG,
  DELETE_TAG,
  TAG_FRAGMENT,
  UPDATE_TAG
};
