import React, { CSSProperties, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useMd from '@web/ui/components/generic/hooks/useMd';
import MediaCardBody from '@web/ui/components/card/mediacard/MediaCardBody.js';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
    StyledBackgroundImageLink,
} from '@core/utilities/constants/defaults';
import { Maybify } from '@core/types/Utilities';

import { ContentCard } from '@shared/welibrary-graphql/types';
import { CARD_TEXT } from '@core/utilities/constants/card_types.js';

const localNamespace = 'imports.wlWeb.ui.pages.leaderboard.bigScreenBody';

type BigScreenBodyProps = {
    post: Maybify<ContentCard>;
    bigScreenView?: boolean;
    rank?: number;
};

const BigScreenBody = React.forwardRef<HTMLDivElement, BigScreenBodyProps>(function BigScreenBody(
    { post, bigScreenView = false, rank },
    ref
) {
    const { t } = useTranslation();
    const defaultHidden = post.hasOwnProperty('defaultHidden') ? post?.defaultHidden : false;
    const [hideMedia, setHideMedia] = useState(defaultHidden);

    const md = useMd();

    const bodyTextStyle: CSSProperties = {
        textTransform: 'unset',
        paddingLeft: '5px',
        paddingRight: '5px',
    };

    const displayRank = rank ?? '?';
    const authorName = post.author ? post.author.profile.full_name : '';

    const displayScore = post.ratings_sentiment_score > 0 ? `+${post.ratings_sentiment_score}` : '';

    const { roles } = post.author ? post.author : {};
    const isSpeaker = roles ? roles.isSpeakerOf : false;

    let markdownText: string | undefined;
    if (post.body) {
        markdownText = md.render(post.body);
    }

    const displaySocialHeaders =
        post?.reference?.chapterSource?.library_settings?.displaySocialHeaders;

    const [src, srcSet] = getBackgroundImageSetForUrl(
        post.author && post.author.profile.picture && displaySocialHeaders
            ? post.author.profile.picture
            : getRandomDefaultUserImage()
    );

    let postBodyContent: React.ReactNode;

    if (hideMedia) {
        postBodyContent = (
            <div className="mc-source-post-wrapper flex flex-col items-center">
                <div className="mc-source-container">
                    <div className="mc-source-info-container">
                        <div className="media-card-author">
                            <i style={{ color: 'grey' }}>
                                {t(`common:${localNamespace}.flagged_content_moderation`)}
                            </i>
                        </div>
                    </div>
                </div>
                <div className="mc-source-text-container">
                    <div
                        onClick={() => {
                            setHideMedia(false);
                        }}
                        className="content-button-icon dropdown-dots w-dropdown-toggle"
                    />
                </div>
            </div>
        );
    } else {
        // This is broken for metachannel cards
        postBodyContent = (
            <>
                <p
                    className={
                        bigScreenView
                            ? 'rank-post-text big-screen big-screen-card-body'
                            : 'wrap-text big-screen-card-body'
                    }
                    style={bodyTextStyle}
                    dangerouslySetInnerHTML={{ __html: markdownText }}
                />
                {post?.type !== CARD_TEXT && <MediaCardBody card={post} />}
            </>
        );
    }

    return (
        <div className="leaderboard-item we-mobile-topgreyborder fadein" ref={ref}>
            <div className={`ranking-card-top ${bigScreenView ? 'big-screen' : ''}`}>
                <div className="rank-label-container">
                    <div className={`rank-label ${bigScreenView ? 'big-screen' : ''}`}>
                        <div>{displayRank}</div>
                    </div>
                    {displaySocialHeaders ? (
                        <StyledBackgroundImageLink
                            to={post?.author ? `/u/${post?.author._id}` : null}
                            className={`notification-thumb rank-thumb ${bigScreenView ? 'big-screen' : ''
                                }`}
                            src={src}
                            srcSet={srcSet}
                        />
                    ) : (
                        ''
                    )}
                </div>
                {displaySocialHeaders && authorName ? (
                    <Link to={`/u/${post?.author._id}`} style={{ cursor: 'pointer' }}>
                        <span style={{ color: 'black' }}>{post?.author?.profile?.full_name}</span>
                    </Link>
                ) : (
                    <div>{t(`common:${localNamespace}.anonymous`, 'Anonymous')}</div>
                )}
                {isSpeaker && !bigScreenView && (
                    <div className="speaker-tag">{t(`common:${localNamespace}.speaker`)}</div>
                )}
                <div className={`ranking-vote-count ${bigScreenView ? 'big-screen' : ''}`}>
                    {displayScore}
                </div>
            </div>

            {postBodyContent}

            {isSpeaker && bigScreenView && (
                <div className="speaker-tag big-screen" style={{ marginBottom: '-1px' }}>
                    {t(`common:${localNamespace}.speaker`)}
                </div>
            )}
        </div>
    );
});

export default BigScreenBody;
