import React from 'react';

const X: React.FC<{ className?: string; strokeWidth?: string; size?: string }> = ({
    className,
    strokeWidth = '3',
    size = '24',
}) => (
    <svg
        width={size}
        height={size}
        className={className}
        viewBox="0 0 32 32"
        strokeWidth={strokeWidth}
        stroke="currentcolor"
    >
        <line x1="30" y1="30" x2="2" y2="2" strokeLinecap="round" />
        <line x1="2" y1="30" x2="30" y2="2" strokeLinecap="round" />
    </svg>
);

export const ThickX: React.FC<{ className?: string; strokeWidth?: string; size?: string }> = ({
    className,
    strokeWidth = '3',
    size = '15',
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M11.7188 3.28125L3.28125 11.7188"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.7188 11.7188L3.28125 3.28125"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default X;
