import React from 'react';

export default props => {
    return (
        <div>
            <iframe
                width="100%"
                height="166"
                scrolling="no"
                frameborder="no"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/34019569&amp;color=0066cc"
            />
        </div>
    );
};
