import React from 'react';
import useModal from '@web/ui/components/modals/hooks/useModal';

import ResourceIcon from '@components/card/coursecard/ResourceIcon';
import ResourceFull from '@components/lego/resource/ResourceFull';

import { stringIsResource } from '@helpers/rainbowRoad/quest.helpers';
import { ResourceMeta } from '@web/utilities/helpers/courses/course.helpers';
import { Resource } from '@shared/welibrary-graphql/types';

const TreasureResources: React.FC<{ resources: Record<Resource, number> }> = ({ resources }) => {
    const { newModal } = useModal();

    const handleClick = (resource: Resource, quantity: number) => {
        newModal(<ResourceFull resource={resource} quantity={quantity} />, {
            sectionClassName: 'no-top-padding no-bottom-padding no-notch lego-modal',
            hideButton: true,
            customCloseButton: true,
        });
    };

    const renderTreasures = Object.entries(resources).map(([resource, quantity]) => {
        if (!stringIsResource(resource)) return <></>;

        return (
            <button
                type="button"
                onClick={() => handleClick(resource, quantity)}
                className="treasure-resource-icon"
                key={resource}
            >
                <ResourceIcon
                    type={resource}
                    color={ResourceMeta[resource].color}
                    quantity={quantity}
                    size="100px"
                    hidePlus
                />
            </button>
        );
    });

    return <div className="render-icons">{renderTreasures}</div>;
};

export default TreasureResources;
