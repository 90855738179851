/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Lottie from 'react-lottie-player';

type LottieLoadingProps = {
    /** Lottie Animation Data (.json import) */
    lottieData: any;
    height?: number | string;
    width?: number | string;
    customStyle?: React.CSSProperties;
    isPaused?: boolean;
    loop?: boolean;
    aspectRatio?: string;
};

const LottieLoading: React.FC<LottieLoadingProps> = ({
    lottieData,
    height = '300',
    width = '300',
    customStyle = { opacity: '0.4' },
    loop = true,
    aspectRatio = 'xMidYMid slice',
}) => {
    const defaultOptions = {
        loop,
        play: true,
        animationData: lottieData,
        rendererSettings: { preserveAspectRatio: aspectRatio },
    };

    const lottieStyle = {
        width,
        height,
    };

    return (
        <div style={customStyle}>
            <Lottie {...defaultOptions} style={lottieStyle} />
        </div>
    );
};

export default LottieLoading;
