import React from 'react';
import { useTranslation } from 'react-i18next';

import Input from '@web/ui/components/generic/forms/Input';
import EditCardMediaItem from '@web/ui/components/generic/editscreen/EditCardMediaItem';
import EditScreenItemContainer from '@web/ui/components/generic/editscreen/EditScreenItemContainer';
import SearchWithSelectedList from '@web/ui/components/generic/editscreen/SearchWithSelectedList';
import ToggleButtonEditScreen from '@web/ui/components/generic/buttons/ToggleButtonEditScreen';
import TextareaAutosize from 'react-textarea-autosize';

import { CARD_BLOG, CARD_CHAPTER, CARD_TEXT } from '@core/utilities/constants/card_types';

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard.voteGalleryUpdateForm';
const altNamespace = 'imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm';

const VoteGalleryUpdateForm = ({
    card,
    state,
    handleUpdateState,
    handleChange,
    handleToggle,
    currentUser,
    handleSubmit,
    handleClose,
    hasAdminRole,
    searchItemSelected,
    deleteAliasItem,
    cardConstraints,
}) => {
    const { t } = useTranslation();
    const {
        titleConstraint,
        bodyConstraint,
        aliasesConstraint,
        descriptionConstraint,
        engagementTypesConstraint,
        displayInNewsfeedConstraint,
        displaySocialHeadersConstraint,
        rankOrderConstraint,
    } = cardConstraints;

    const showBodySection = card.type !== CARD_BLOG;

    return (
        <div className="edit-screen-content-view">
            {/* Main Edit Section */}
            <div className="edit-screen-content-items">
                <div className="mc-source-post-wrapper">
                    <div className="edit-screen-item post-edit-item edit-content-card-title-block">
                        <div>
                            {t(`common:${localNamespace}.vote_booth`)}
                            <br />
                        </div>
                    </div>
                    {/* Display Order Setting */}
                    {hasAdminRole && rankOrderConstraint && (
                        <div className="edit-screen-item columns-item content-card-item">
                            <div className="edit-display-order-container">
                                <div className="display-text-inline" style={{ marginTop: '5px' }}>
                                    {t(`common:${localNamespace}.display_order`)}
                                    <br />
                                </div>
                                <Input
                                    id="rankOrder"
                                    name="rankOrder"
                                    placeHolder="..."
                                    maxLength="256"
                                    handleChange={handleChange}
                                    value={state.rankOrder}
                                    classNames="text-field margin-clear w-input"
                                    style={{
                                        alignSelf: 'flex-end',
                                        height: '30px',
                                        width: '95px',
                                    }}
                                    type="number"
                                />
                            </div>
                        </div>
                    )}

                    {/* Display Anonymous Toggle */}
                    {hasAdminRole && displayInNewsfeedConstraint && (
                        <div className="edit-screen-item columns-item content-card-item">
                            <div>
                                {t(
                                    'common:imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm.display_in_newsfeed'
                                )}
                                <br />
                            </div>
                            <div className="margin-clear w-form">
                                <ToggleButtonEditScreen
                                    id="displayInNewsfeed"
                                    name="displayInNewsfeed"
                                    handleToggle={handleToggle}
                                    onOption={t('common:global.yes_caps')}
                                    offOption={t('common:global.no_caps')}
                                    toggleValue={state.displayInNewsfeed}
                                />
                            </div>
                        </div>
                    )}

                    <div className="edit-screen-item post-edit-item">
                        {aliasesConstraint && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.select_content_feed.title`)}
                                subtitle={t(
                                    `common:${localNamespace}.select_content_feed.subtitle`
                                )}
                                noBottomBorder
                                clearfix
                            >
                                <SearchWithSelectedList
                                    // customRoles={customRoles}
                                    card={card}
                                    disableRenderChildren
                                    types={[CARD_CHAPTER]}
                                    onSelect={searchItemSelected}
                                    selected={state.aliases}
                                    removeItem={deleteAliasItem}
                                />
                            </EditScreenItemContainer>
                        )}
                        <EditScreenItemContainer title={t(`common:${localNamespace}.custom_bg`)}>
                            <EditCardMediaItem
                                card={card}
                                state={state}
                                handleUpdateState={handleUpdateState}
                                handleChange={handleChange}
                                cardConstraints={cardConstraints}
                            />
                        </EditScreenItemContainer>
                    </div>

                    {/* Edit Body Input */}
                    {showBodySection && bodyConstraint && (
                        <div className="mc-source-text-container">
                            <div className="edit-post-text-container textarea-autosize w-form">
                                <TextareaAutosize
                                    autoComplete="off"
                                    id="title"
                                    name="title"
                                    onChange={e => handleChange(e)}
                                    value={state.title}
                                    className="new-post-field default w-input"
                                    maxLength={500}
                                    style={{
                                        resize: 'none',
                                        paddingTop: '7px',
                                        overflow: 'hidden',
                                    }}
                                    placeholder="Edit Title..."
                                />
                            </div>

                            <div className="edit-post-text-container textarea-autosize w-form">
                                <TextareaAutosize
                                    autoComplete="off"
                                    id="body"
                                    name="body"
                                    onChange={e => handleChange(e)}
                                    value={state.body}
                                    className={`new-post-field ${
                                        card.type === CARD_TEXT ? 'defaultexpanded' : 'default'
                                    } w-input`}
                                    maxLength={2000}
                                    style={{
                                        resize: 'none',
                                        paddingTop: '7px',
                                        overflow: 'hidden',
                                    }}
                                    placeholder={`${t(`common:${altNamespace}.say_something`)}...`}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="edit-footer">
                    {/* <div onClick={props.handleClose} className="button callout-button inverse fadded w-button">Delete</div> */}
                    <div
                        onClick={handleSubmit}
                        className="button callout-button w-button edit-screen-content"
                    >
                        {t('common:global.verbs.save')}
                    </div>
                </div>
                {/* Submit & Cancel Buttons */}
                <div className="edit-content-card-buttons">
                    <div
                        onClick={handleClose}
                        className="cancel-edit-content-button w-inline-block"
                    />
                    <div
                        onClick={handleSubmit}
                        className="approve-edit-content-button w-inline-block"
                    />
                </div>
            </div>
        </div>
    );
};

export default VoteGalleryUpdateForm;
