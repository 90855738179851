import React from 'react';

import { Mutation } from '@apollo/client/react/components';
import { PLEDGE_TO_TASK } from '@shared/welibrary-graphql/user/mutations';

import _ from 'lodash';

import getLogger from '@core/logger';

const logger = getLogger(module);

export default class PledgeDrawer extends React.Component {
    submitPledgeToTask(pledgeToTask, taskUrls) {
        pledgeToTask({
            variables: {
                taskUrls: taskUrls,
            },
        })
            .then(data => {
                window.location = '/home';
            })
            .catch(error => {
                logger.error(error);
            });
    }

    render() {
        const { activeDrawer, card, handleCloseDrawer } = this.props;
        let pledgeMeta = card.meta ? card.meta.pledge : {};

        let pledgeButtonStyle = {
            backgroundImage: `url(/images/icon.blue.yesvote.svg)`,
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '21px',
        };

        let taskUrls = _.map(card.aliases, alias => alias.url);

        return (
            <Mutation mutation={PLEDGE_TO_TASK}>
                {(pledgeToTask, { data, loading, error }) => (
                    <div className="drawer-contents">
                        <div className="drawer-section">
                            <div className="subheading">My Pledge</div>
                            <p className="jc-description">{pledgeMeta.pledge}</p>
                        </div>
                        <div className="drawer-section">
                            <div
                                className="pledge-card-button"
                                style={{ paddingRight: '5px' }}
                                onClick={() => this.submitPledgeToTask(pledgeToTask, taskUrls)}
                            >
                                <div className="pledge-button-counter-container">
                                    <div
                                        className="pledge-button-counter-text"
                                        style={pledgeButtonStyle}
                                    ></div>
                                </div>
                                Make the Pledge
                            </div>
                        </div>
                    </div>
                )}
            </Mutation>
        );
    }
}
