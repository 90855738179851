import useCancelableTimer from '@components/generic/hooks/useCancelableTimer';

/**
 * React hook for displaying loaders that don't flash
 *
 * Takes in a minimum flash time (the shortest amount of time that a loader can appear), and a
 * maximum delay time (the longest amount of time before that loader must appear). and spits out a
 * function that will run a function of indeterminate time, conditionally displaying a non-flashing
 * loader based on that function, and finally running an end function after all is said and done.
 *
 * Use like so:
 *
 * const antiFlash = useAntiFlash();
 *
 * const runMutation = () => {
 *     antiFlash(
 *         resolve => gqlMutation().then(resolve),
 *         () => newModal(<ModalLoading message="Loading..." />),
 *         () => {
 *             closeModal();
 *             history.push('newRoute');
 *         }
 *     )
 * }
 */
const useAntiFlash = ({ minimumFlash = 700, maximumDelay = 300 } = {}) => {
    const timer1 = useCancelableTimer(maximumDelay, true, false);
    const timer2 = useCancelableTimer(minimumFlash, true, false);

    /**
     * Takes in three functions: timedFunction, loaderFunction, and finishFunction
     *
     * timedFunction is given a resolve parameter that, when called will run finishFunction
     *
     * If more than a minimum amount of time passes before resolve is called, loaderFunction will be
     * ran, and if resolve is called within a minimum amount of time after that, finishFunction will wait
     * to be called by a specified amount of time
     *
     * Any data passed into the resolve function by timedFunction will be passed along to finishFunction
     *
     * E.g.
     *
     * antiFlash<string>(
     *     resolve => setTimeout(() => resolve('World'), 1000),
     *     () => newModal(<h1>Hello</h1>),
     *     data => {
     *         closeModal();
     *         newModal(<h1>{data}</h1>);
     *     }
     * )
     */
    const antiFlash = <T>(
        timedFunction: (resolve: (data?: T) => void) => void,
        loaderFunction: () => void,
        finishFunction: (data?: T) => void,
        minimumFlashOverride = minimumFlash,
        maximumDelayOverride = maximumDelay
    ) => {
        const resolve = (data: T) => {
            timer1.cancel();
            if (timer2.isRunning()) timer1.start(() => finishFunction(data), minimumFlashOverride);
            else finishFunction(data);
        };

        timer1.start(() => {
            loaderFunction();
            timer2.start(() => {}, minimumFlashOverride);
        }, maximumDelayOverride);

        timedFunction(resolve);
    };

    return antiFlash;
};

export default useAntiFlash;
