import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
    useGetOrdersAndProductsForGroupQuery,
    useGetOrdersForGroupByProductIdQuery,
} from '@shared/welibrary-graphql/user/queries.hook';

import Tag from '@dsc/display/badges/Tag';
import ModalLoading from '@components/modals/ModalLoading';
import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import ManageOrdersList from '@components/group/groupdashboard/manageOrders/ManageOrdersList';
import ProductInstanceListItem from '@components/group/groupdashboard/manageOrders/ProductInstanceListItem';
import HorizontalScrollMenu from '@components/generic/horizontalscrollmenu/HorizontalScrollMenu';

import { Group } from '@shared/welibrary-graphql/types';
import { CARD_EVENT } from '@core/utilities/constants/card_types';
import ManageEventTicketOrders from './ManageEventTicketOrders';

const OrdersListByProduct: React.FC<{ group: Group; productId: string; activeTab: string }> = ({
    group,
    productId,
    activeTab,
}) => {
    const { data, loading } = useGetOrdersForGroupByProductIdQuery({
        variables: {
            productId,
            groupId: group?._id,
            statuses: ['succeeded', 'canceled'],
        },
        skip: !group,
        fetchPolicy: 'cache-and-network',
    });

    const orders = data?.getOrdersForGroupByProductId ?? [];

    return (
        <>
            {loading && <ModalLoading message="Orders Loading..." />}
            {!loading && <ManageOrdersList group={group} orders={orders} activeTab={activeTab} />}
        </>
    );
};

const ManageOrders: React.FC<{ group: Group }> = ({ group }) => {
    const flags = useFlags();
    const { loading: ordersLoading, data } = useGetOrdersAndProductsForGroupQuery({
        variables: {
            groupId: group?._id,
            statuses: ['succeeded', 'canceled'],
        },
        skip: !group,
        fetchPolicy: 'network-only',
    });

    // TODO:
    // # add orders search query
    // # search orders by purchaser name ||
    // # search order by order #

    const [tab, setTab] = useState<string | undefined>('all');
    const [productId, setProductId] = useState<string | null | undefined>(null);

    const orders = data?.getOrdersAndProductsForGroup?.orders ?? [];
    const products = data?.getOrdersAndProductsForGroup?.products ?? [];
    const giftedTickets = data?.getOrdersAndProductsForGroup?.giftedTickets ?? [];
    const isEvent = group?.subtype === 'event';

    if (isEvent && flags?.enableNewCheckInFlow) {
        return <ManageEventTicketOrders group={group} />;
    }

    const menuComponentsList = [
        {
            id: 'all',
            value: tab === 'all',
            onChange: () => {
                setTab('all');
                setProductId(null);
            },
            text: 'All',
        },
        {
            id: 'gifted',
            value: tab === 'gifted',
            onChange: () => {
                setTab('gifted');
                setProductId(null);
            },
            text: `${giftedTickets.length} Gifted`,
        },
    ];

    if (orders.length > 0) {
        menuComponentsList.push();
    }

    products?.forEach(product => {
        const productSoldCount = product?.sold ?? 0;
        menuComponentsList.push({
            id: product?._id,
            value: tab === product?.name,
            onChange: () => {
                setTab(product?.name);
                setProductId(product?._id);
            },
            text: `${productSoldCount} ${product?.name}` ?? '',
        });
    });

    const headerTitle =
        group?.subtype === CARD_EVENT ? 'Manage Ticket Orders' : 'Manage Subscription Orders';

    let ordersList: React.ReactNode | null = null;
    if (tab === 'all' && !ordersLoading) {
        ordersList = <ManageOrdersList group={group} orders={orders} />;
    }

    if (tab === 'gifted' && !ordersLoading) {
        ordersList = (
            <ul>
                {giftedTickets?.map((ticket, index) => (
                    <ProductInstanceListItem group={group} ticket={ticket} key={index} />
                ))}
            </ul>
        );
    }

    if (tab !== 'all' && tab !== 'gifted' && !ordersLoading) {
        ordersList = <OrdersListByProduct group={group} productId={productId} activeTab={tab} />;
    }

    const showMenuTabs =
        ((orders?.length > 0 && isEvent) || (giftedTickets.length > 0 && isEvent)) ?? false;

    return (
        <ErrorBoundary>
            <section className="group-members-form manage-orders">
                <header>
                    {headerTitle}
                    {showMenuTabs && (
                        <section className="horizontal-menu" style={{ width: '100%' }}>
                            <HorizontalScrollMenu>
                                {menuComponentsList?.map(item => (
                                    <Tag
                                        value={item?.value}
                                        onChange={item?.onChange}
                                        text={item?.text}
                                        key={item?.id}
                                        id={item?.id}
                                        itemId={item?.id}
                                    />
                                ))}
                            </HorizontalScrollMenu>
                        </section>
                    )}
                </header>

                {ordersLoading && <ModalLoading message="Orders Loading..." />}

                {ordersList}
            </section>
        </ErrorBoundary>
    );
};

export default ManageOrders;
