import React from 'react';

import StylizedCheck from '@web/ui/dsc/svgs/lego/StylizedCheckNoBorder';

const COLORS = [
    'linear-gradient(180deg, #FFBD06 0%, #CA9400 100%)',
    'linear-gradient(180deg, #AFD64D 0%, #009046 100%)',
    'linear-gradient(180deg, #0094F6 0%, #0355D8 100%)',
];

type CreationProgressBarProps = {
    value: number;
    levels: number;
    numberColor: string;
    className?: string;
};

const CreationProgressBar: React.FC<CreationProgressBarProps> = ({
    value,
    levels,
    numberColor,
    className = '',
}) => {
    const isComplete = value === levels;

    return (
        <section className={`creation-progress-bar ${className}`}>
            <aside
                style={{ '--circle-color': numberColor } as React.CSSProperties}
                className={isComplete ? '' : 'grey'}
            >
                {isComplete ? (
                    <figure>
                        <StylizedCheck />
                    </figure>
                ) : (
                    <span>{value}</span>
                )}
            </aside>

            <section>
                {Array(levels)
                    .fill(0)
                    .map((_, index) => (
                        <div
                            role="presentation"
                            style={
                                {
                                    '--bar-color': COLORS[index % COLORS.length],
                                } as React.CSSProperties
                            }
                            className={value > index ? '' : 'grey'}
                            key={index}
                        />
                    ))}
            </section>
        </section>
    );
};

export default CreationProgressBar;
