import React from 'react';

const UserIcon: React.FC<{ className?: string; strokeWidth?: string }> = ({
    className,
    strokeWidth = '3',
}) => (
    <svg className={className} viewBox="0 0 40 40" strokeWidth={strokeWidth} stroke="currentColor">
        <path
            d="M20 25C25.5228 25 30 20.5228 30 15C30 9.47715 25.5228 5 20 5C14.4772 5 10 9.47715 10 15C10 20.5228 14.4772 25 20 25Z"
            strokeWidth="3"
            strokeMiterlimit="10"
        />
        <path
            d="M4.8418 33.7485C6.37861 31.0885 8.58838 28.8797 11.2491 27.344C13.9099 25.8084 16.9279 25 19.9999 25C23.072 25 26.09 25.8085 28.7507 27.3442C31.4114 28.8799 33.6212 31.0888 35.1579 33.7489"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default UserIcon;
