import React from 'react';

const Bookmark: React.FC<{ className?: string }> = ({ className = '' }) => (
    <svg
        stroke="currentcolor"
        fill="currentcolor"
        className={className}
        id="Hand-drawn_Icon_Set"
        data-name="Hand-drawn Icon Set"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
    >
        <path d="M358.54,85.21v300l-87.41-45.42a45.84,45.84,0,0,0-42.26,0l-87.41,45.42v-300H358.54m45.84-45.84H95.62V460.63L250,380.42l154.38,80.21V39.37Z" />
    </svg>
);

export default Bookmark;
