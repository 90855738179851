import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { constructUserflowUrl } from '@core/utilities/constants/userflow';

/**
 * React hook for adding css classes based on route for userflow targeting
 *
 */

const useAddUserflowClasses = () => {
    let userflowCssClassname;

    const location = useLocation();
    useEffect(() => {
        // based on location construct custom css class and attach to main app container
        userflowCssClassname = constructUserflowUrl(location?.pathname);

        // get #app element
        const el = document.getElementById('app');
        if (el && userflowCssClassname) {
            el.className = userflowCssClassname;
        }
    }, [location]);
};

export default useAddUserflowClasses;
