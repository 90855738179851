import React, { useEffect, useRef } from 'react';

import { useCurrentUser } from '@stores/User';
import useNavigation from '@web/ui/components/navigation/useNavigation';

import QRCodeScannerStore from '@stores/QRCodeScannerStore';

import Slide from '@web/ui/components/generic/animation/Slide';
import SideMenu from '@web/ui/components/navigation/SideMenu/SideMenu';
import PrimaryNav from './PrimaryNav';
import SecondaryNavigation from './SecondaryNavigation';

import { getWhitelabelImages } from '@core/utilities/whitelabel_helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

const NavigationContainer = ({
    client,
    hideSecondaryNavigation,
    hidePrimaryNavOnMobile,
    hidePrimaryNav,
    showDiscover,
    showActionButton,
    showSignup,
    showLogin,
    enableBackButton,
}) => {
    // I'm not sure how or why this doesn't crash the site, but removing this *will* crash the site
    if (!client) return <></>;

      const { currentUser } = useCurrentUser();

    const { isMobileNavHidden, isSlideMenuOpen, showMobileNav, hideMobileNav } = useNavigation();

    const sideMenuRef = useRef<HTMLElement>(null);

    const showScanner = QRCodeScannerStore.useTracked.showScanner();

    let _showDiscover = showDiscover;
    const flags = useFlags();
    const siloedUsers = flags?.siloedUsers;
    if (siloedUsers) _showDiscover = !siloedUsers;

    const [displayHeaderImage, displayHeaderImageWhite] = getWhitelabelImages();

    useEffect(() => {
        if (hideSecondaryNavigation) hideMobileNav();
        else showMobileNav();
    }, [hideSecondaryNavigation]);

    return (
        <>
            <div className="nav-primary">
                <div
                    className={`mn-bar primary loggedout notch-area ${
                        hidePrimaryNavOnMobile ? 'desktop-only' : ''
                    }`}
                />

                {!hidePrimaryNav && (
                    <PrimaryNav
                        currentUser={currentUser}
                        showSignup={showSignup}
                        showLogin={showLogin}
                        displayHeaderImage={displayHeaderImage}
                        displayHeaderImageWhite={displayHeaderImageWhite}
                        hideOnMobile={hidePrimaryNavOnMobile}
                        enableBackButton={enableBackButton}
                        showActionButton={showActionButton}
                        showDiscover={_showDiscover}
                    />
                )}

                <Slide className="side-menu-container" show={isSlideMenuOpen}>
                    <SideMenu ref={sideMenuRef} />
                </Slide>

                {!hideSecondaryNavigation && !isMobileNavHidden && !showScanner && (
                    <SecondaryNavigation client={client} showActionButton={showActionButton} />
                )}
            </div>
        </>
    );
};

export default NavigationContainer;
