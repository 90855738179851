import userflow from 'userflow.js';
import { Capacitor } from '@capacitor/core';

import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

import { CurrentUser } from '@core/types/User';

import getLogger from '@core/logger';

const logger = getLogger(module);

const initUserflow = (user?: CurrentUser) => {
    const { userflow: userflowSettings, version, siteName } = getWhitelabelPublicSettings();

    if (userflow?.init && userflowSettings?.api_key) {
        try {
            userflow.init(userflowSettings?.api_key);
            if (user) {
                userflow.identify(user._id, {
                    name: user?.profile?.full_name,
                    email: user?.profile?.email,
                    phone: user?.profile?.phone,
                    version,
                    platform: siteName,
                    isNative: Capacitor.isNativePlatform(),
                    deviceType: window.innerWidth > 991 ? 'desktop' : 'mobile',
                    isTablet: window?.innerWidth <= 991 && window?.innerWidth >= 479,
                    isDesktop: window?.innerWidth > 991,
                    isMobile: window?.innerWidth < 479,
                });
            }
        } catch (e) {
            logger?.error(e);
        }
    }
};

export default initUserflow;
