import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { validateEmailFormat } from '@helpers/email.helpers';
import { VALIDATE_USER_EMAIL, GET_USER_BY_EMAIL } from '@shared/welibrary-graphql/user/queries';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

export const useValidateEmail = (email: string, getMatchedUser: boolean = false) => {
    const { t } = useTranslation();
    const { customErrorMessages } = getWhitelabelPublicSettings();

    const [emailError, setEmailError] = useState('');
    const [matchedUser, setMatchedUser] = useState(undefined);

    const [
        validateUserEmail,
        { data: validateEmailData, loading: validateEmailLoading, error: validateUserEmailError },
    ] = useLazyQuery(VALIDATE_USER_EMAIL, {
        fetchPolicy: 'network-only',
    });

    const [getUserByEmail] = useLazyQuery(GET_USER_BY_EMAIL, {
        fetchPolicy: 'cache-and-network',
    });

    // validate email when it changes
    useEffect(() => {
        setMatchedUser(undefined);

        if (!email) {
            setEmailError(undefined);
        } else if (validateEmailFormat(email)) {
            validateUserEmail({
                variables: {
                    email,
                },
            });
        } else {
            setEmailError(t(`common:global.invalidEmailFormat`, 'Not a valid email'));
        }
    }, [email]);

    // handle data received from validateUserEmail query
    useEffect(() => {
        const handleValidateEmailData = async () => {
            if (!email) {
                setEmailError(undefined);
            } else if (!validateEmailFormat(email)) {
                setEmailError(t(`common:global.invalidEmailFormat`, 'Not a valid email'));
            } else if (validateEmailLoading) {
                setEmailError(t(`common:global.checkingEmail`, 'Checking email...'));
            } else {
                let _matchedUser;
                if (getMatchedUser) {
                    const matchedUserResponse = await getUserByEmail({ variables: { email } });
                    _matchedUser = matchedUserResponse?.data?.getUserByEmail;

                    if (_matchedUser) setMatchedUser(_matchedUser);
                }

                if (email && (!validateEmailData?.validateUserEmail || _matchedUser)) {
                    const errorText =
                        customErrorMessages?.signupEmailAlreadyExists ??
                        t(
                            `common:global.emailAlreadyExists.2`,
                            'This email is tied to an account that already exists.'
                        );

                    setEmailError(errorText);
                } else {
                    setEmailError(undefined);
                }
            }
        };

        handleValidateEmailData();
    }, [validateEmailData, validateEmailLoading]);

    return { emailError, validateEmailLoading, matchedUser };
};

export default useValidateEmail;
