import React from 'react';

import useLocationPicker from '@components/location/hooks/useLocationPicker';

import SearchInput from '@components/search/SearchInput';
import SearchLoader from '@components/search/SearchLoader';

type LocationPickerProps = {
    onLocationSelect: (location: google.maps.GeocoderResult) => void;
};

const LocationPicker: React.FC<LocationPickerProps> = ({ onLocationSelect }) => {
    const { searchInput, actualSearchInput, setSearchInput, loading, locations, selectLocation } =
        useLocationPicker(onLocationSelect);

    return (
        <section className="location-picker">
            <header>
                <SearchInput
                    isSearchOverlayOpen
                    placeholder="Enter your location"
                    onChange={setSearchInput}
                    value={searchInput}
                />
            </header>

            <ul>
                {locations.map(location => (
                    <li key={location.place_id}>
                        <button type="button" onClick={() => selectLocation(location)}>
                            {location.description}
                        </button>
                    </li>
                ))}

                <SearchLoader
                    emptyResults={!loading && locations.length === 0 && actualSearchInput !== ''}
                    emptyState={searchInput === '' && actualSearchInput === ''}
                    areResults={locations.length > 0}
                    loading={loading}
                    prompt="Enter your location"
                />
            </ul>

            {/*
                Google demands we display this =(
                see https://developers.google.com/maps/documentation/places/web-service/policies#powered
            */}
            <img src="/images/powered_by_google.png" alt="Powered by Google" />
        </section>
    );
};

export default LocationPicker;
