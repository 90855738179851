import React from 'react';

/** @type React.FC<{ className: string }> */
const DragIcon = ({ className }) => (
    <svg className={className} viewBox="0 0 931 1500">
        <circle cx="181" cy="181" r="181" fill="currentcolor" />
        <circle cx="750" cy="181" r="181" fill="currentcolor" />

        <circle cx="181" cy="750" r="181" fill="currentcolor" />
        <circle cx="750" cy="750" r="181" fill="currentcolor" />

        <circle cx="181" cy="1319" r="181" fill="currentcolor" />
        <circle cx="750" cy="1319" r="181" fill="currentcolor" />
    </svg>
);

export default DragIcon;
