import React, {useEffect, useState} from 'react';

type SlideProps = {
    show: boolean;
    callback?: () => void;
    className?: string;
};

const Slide: React.FC<SlideProps> = ({ show, callback, children, className = '' }) => {
    const [render, setRender] = useState(show);

    useEffect(() => {
        if (show) setRender(true);
    }, [show]);

    const onAnimationEnd = () => {
        if (!show) {
            callback?.();
            setRender(false);
        }
    };

    return (
        render && (
            <div
                className={`${className} ${show ? 'slideIn' : 'slideOut'}`}
                onAnimationEnd={onAnimationEnd}
            >
                {children}
            </div>
        )
    );
};

export default Slide;
