import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PostEditor } from '@web/ui/components/wysiwyg';
import _ from 'lodash';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
    StyledBackgroundImageLink,
} from '@core/utilities/constants/defaults';
import EditCardMediaItem from '@web/ui/components/generic/editscreen/EditCardMediaItem.js';
import TextareaAutosize from 'react-textarea-autosize';
import UpdateBlogForm from '@web/ui/components/card/updatecard/UpdateBlogForm.js';
import MediaCardUpdateSettings from '@web/ui/components/card/updatecard/MediaCardUpdateSettings.js';

import {
    CARD_BLOG,
    CARD_DOCUMENT,
    CARD_IMAGE,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_TEXT,
    CARD_VIDEO,
    META_MEDIA_AREA_BLACKLIST,
    CARD_DYNAMIC,
    CARD_STORY,
} from '@core/utilities/constants/card_types.js';

import {
    ENGAGE_COMMENT,
    ENGAGE_HEART,
    ENGAGE_SHARE,
} from '@core/utilities/constants/engagement_types';

const SHOW_TITLE_FIELD_TYPES = [
    CARD_TEXT,
    CARD_DOCUMENT,
    CARD_VIDEO,
    CARD_IMAGE,
    CARD_LINK,
    CARD_IMAGE_SQUARE,
    CARD_VIDEO,
];

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm';
const hideMenuOptions = ['image', 'link'];

const settingsOnlyCards = [CARD_DYNAMIC];

const MediaCardUpdateForm = ({
    card,
    state,
    chapterSettings,
    handleUpdateState,
    handleChange,
    handleSubmit,
    handleClose,
    handleToggleSettingsCallback,
    handleAddToList,
    handleRemoveFromList,
    handleImgToggle,
    inheritedSettings,
    cardConstraints,
}) => {
    const { t } = useTranslation();
    const [showSettings, setShowSettings] = useState(settingsOnlyCards?.includes(card?.type));

    const {
        titleConstraint,
        bodyConstraint,
        descriptionConstraint,
        engagementTypesConstraint,
        displayInNewsfeedConstraint,
        displaySocialHeadersConstraint,
        rankOrderConstraint,
        showCreateChildrenConstraint,
        defaultViewTypeConstraint,
        isTemplateConstraint,
    } = cardConstraints;

    const { author, date } = card;
    let sourceSection;

    const handleToggleSettings = () => {
        if (!settingsOnlyCards?.includes(card?.type)) {
            setShowSettings(!showSettings);
            if (handleToggleSettingsCallback) handleToggleSettingsCallback(!showSettings);
        }
    };

    const handleBody = value => {
        const text = value();
        // Prosemirror component returns text string as opposed to event target value
        const dummyEvent = { target: { value: text, name: 'body', id: 'body' } };
        handleChange(dummyEvent);
    };

    if (author) {
        const decipherDate = dateString => {
            const myDate = new Date(dateString);
            return new Date(myDate.getTime()).toDateString();
        };

        const [src, srcSet] = getBackgroundImageSetForUrl(
            author.profile.picture ? author.profile.picture : getRandomDefaultUserImage()
        );

        sourceSection = state.displaySocialHeaders ? (
            <div className="anonymous-off">
                <div className="mc-source-container edit-card">
                    <div className="post-header-left">
                        <StyledBackgroundImageLink
                            to={`/u/${author._id}`}
                            className="thumbnail w-inline-block"
                            src={src}
                            srcSet={srcSet}
                        >
                            <div className="square-spacer" />
                        </StyledBackgroundImageLink>
                        <div className="mc-source-info-container">
                            <Link to={`/u/${author._id}`} className="media-card-author">
                                {author.profile.full_name}
                            </Link>
                            <div className="mc-source-sub">{decipherDate(date.publish)}</div>
                        </div>
                    </div>

                    <div className="post-header-right">
                        {/* Toggle Settings Button */}
                        <button
                            type="button"
                            onClick={handleToggleSettings}
                            className={showSettings ? 'toggle-settings active' : 'toggle-settings'}
                            label="Settings"
                        />

                        {/* Submit & Cancel Buttons */}
                        <div className="edit-content-card-buttons media-card">
                            <button
                                type="button"
                                label="Cancel"
                                onClick={handleClose}
                                className="button-reset cancel-edit-content-button w-inline-block"
                            />
                            <button
                                type="button"
                                label="Save"
                                onClick={handleSubmit}
                                className="button-reset approve-edit-content-button w-inline-block"
                            />
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="anonymous-off">
                <div className="mc-source-container edit-card">
                    <div className="post-header-left">
                        <div className="thumbnail anonymous-posting w-inline-block">
                            <div className="square-spacer" />
                        </div>
                        <div className="mc-source-info-container">
                            <div className="media-card-author">
                                {t(`common:${localNamespace}.identity_hidden`)}
                            </div>
                            <div className="mc-source-sub">
                                {t(`common:${localNamespace}.post_date_hidden`)}
                            </div>
                        </div>
                    </div>

                    <div className="post-header-right">
                        {/* Toggle Settings Button */}
                        <button
                            type="button"
                            onClick={handleToggleSettings}
                            className={showSettings ? 'toggle-settings active' : 'toggle-settings'}
                            label="Settings"
                        />

                        {/* Submit & Cancel Buttons */}
                        <div className="edit-content-card-buttons media-card">
                            <button
                                type="button"
                                label="Cancel"
                                onClick={handleClose}
                                className="button-reset cancel-edit-content-button w-inline-block"
                            />
                            <button
                                type="button"
                                label="Save"
                                onClick={handleSubmit}
                                className="button-reset approve-edit-content-button w-inline-block"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const showEditCardMediaItem = card.type !== CARD_TEXT && state.type !== CARD_TEXT;
    const showBodySection = card.type !== CARD_BLOG;
    const showMetaMediaArea = !META_MEDIA_AREA_BLACKLIST.includes(card.type);

    const defaultEngagementTypes = [
        { text: t(`common:${localNamespace}.comments`), value: ENGAGE_COMMENT },
        { text: t(`common:${localNamespace}.share`), value: ENGAGE_SHARE },
        {
            text: t(`common:${'imports.wlWeb.ui.components.card.darkForms'}.heart`),
            value: ENGAGE_HEART,
        },
        // {text: "Follow",    value:ENGAGE_FOLLOW}
    ];

    const engagementTypes =
        engagementTypesConstraint && engagementTypesConstraint.options
            ? _.filter(defaultEngagementTypes, option =>
                  engagementTypesConstraint.options.includes(option.value)
              )
            : defaultEngagementTypes;

    const defaultViewTypes = [
        {
            text: t('common:imports.wlWeb.ui.components.card.darkForms.settings.fullview'),
            value: 'fullview',
        },
        {
            text: t('common:imports.wlWeb.ui.components.card.darkForms.settings.listview'),
            value: 'listview',
        },
    ];

    return (
        <div className="edit-screen-content-container card">
            <div className="edit-screen-content-view">
                {/* Main Edit Section */}
                <div className="edit-screen-content-items">
                    {/* User Source Display */}
                    {sourceSection}
                    {!showSettings && (
                        <MediaCardEditBody
                            card={card}
                            chapterSettings={chapterSettings}
                            cardConstraints={cardConstraints}
                            showBodySection={showBodySection}
                            showEditCardMediaItem={showEditCardMediaItem}
                            showMetaMediaArea={showMetaMediaArea}
                            state={state}
                            sourceSection={sourceSection}
                            handleChange={handleChange}
                            handleImgToggle={handleImgToggle}
                            handleBody={handleBody}
                            handleUpdateState={handleUpdateState}
                            hideMenuOptions={hideMenuOptions}
                        />
                    )}
                    {showSettings && (
                        <MediaCardUpdateSettings
                            card={card}
                            chapterSettings={chapterSettings}
                            engagementTypes={engagementTypes}
                            defaultViewTypes={defaultViewTypes}
                            handleAddToList={handleAddToList}
                            handleRemoveFromList={handleRemoveFromList}
                            handleChange={handleChange}
                            handleUpdateState={handleUpdateState}
                            inheritedSettings={inheritedSettings}
                            state={state}
                            cardConstraints={cardConstraints}
                        />
                    )}
                    <div className="edit-footer">
                        <div
                            onClick={handleSubmit}
                            className="button callout-button w-button edit-screen-content"
                        >
                            {t('common:global.verbs.save')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MediaCardUpdateForm;

const MediaCardEditBody = ({
    cardConstraints,
    showBodySection,
    showEditCardMediaItem,
    showMetaMediaArea,
    state,
    card,
    chapterSettings,
    handleChange,
    handleBody,
    handleSetList,
    handleUpdateState,
    hideMenuOptions,
    handleImgToggle,
}) => {
    const { titleConstraint, bodyConstraint, descriptionConstraint } = cardConstraints;
    const { t } = useTranslation();
    const mentionsGroupId =
        card?.reference?.groupSource &&
        card?.reference?.groupSource?.settings?.protectionLevel !== 'public'
            ? card?.reference?.groupSource?._id
            : undefined;
    // the initial showTitle field is null on the chapter-level settings
    // which by default (true | null) shows the title & title input field
    // if the card-level showTitle is not null, then the card-level overrides
    // the chapter settings for showing a title | title-input field
    let showTitle;

    if (
        chapterSettings?.showTitle === null ||
        chapterSettings?.showTitle === true ||
        chapterSettings?.showTitle === undefined // default to true if showTitle not populated
    ) {
        showTitle = true;
    }

    if (state?.showTitle !== null) {
        showTitle = state?.showTitle;
    }

    return (
        <>
            <div className="mc-source-post-wrapper">
                {/* Edit Body Input */}
                {showBodySection && bodyConstraint && (
                    <div className="mc-source-text-container">
                        {SHOW_TITLE_FIELD_TYPES.includes(card?.type) && showTitle && (
                            <div className="edit-post-text-container textarea-autosize w-form">
                                <TextareaAutosize
                                    autoComplete="off"
                                    id="title"
                                    name="title"
                                    onChange={e => handleChange(e)}
                                    value={state.title}
                                    className="new-post-field default w-input"
                                    maxLength={500}
                                    style={{
                                        resize: 'none',
                                        paddingTop: '7px',
                                        overflow: 'hidden',
                                    }}
                                    placeholder="Edit Title..."
                                />
                            </div>
                        )}
                        <div className="edit-post-text-container textarea-autosize w-form">
                            <PostEditor
                                onChange={handleBody}
                                mentionsGroupId={mentionsGroupId}
                                className={`new-post-field ${
                                    card.type === CARD_TEXT ? 'defaultexpanded' : 'default'
                                } w-input`}
                                defaultValue={state.body}
                                placeholder={`${t(`common:${localNamespace}.say_something`)}...`}
                            />
                        </div>
                    </div>
                )}
            </div>

            {card.type === CARD_BLOG && (
                <UpdateBlogForm
                    state={state}
                    group={card?.reference?.groupSource}
                    handleUpdateState={handleUpdateState}
                    handleChange={handleChange}
                />
            )}
            {/* <EditCardMediaItem> */}
            {showEditCardMediaItem && card.type !== CARD_BLOG && (
                <div className="mc-media-post-wrapper">
                    {/* Upload Media Area */}
                    <EditCardMediaItem
                        card={card}
                        state={state}
                        handleUpdateState={handleUpdateState}
                        handleImgToggle={handleImgToggle}
                        handleChange={handleChange}
                        cardConstraints={cardConstraints}
                    />
                    {/* Edit Meta-Media Area */}
                    {titleConstraint && showMetaMediaArea && (
                        <div className="mc-source-text-container">
                            <div className="edit-post-text-container textarea-autosize small w-form">
                                <TextareaAutosize
                                    autoComplete="off"
                                    id="linkTitle"
                                    name="linkTitle"
                                    onChange={e => handleChange(e)}
                                    value={state.linkTitle}
                                    className="new-post-field default header w-input"
                                    maxLength={100}
                                    style={{
                                        resize: 'none',
                                        paddingTop: '7px',
                                        overflow: 'hidden',
                                    }}
                                    placeholder={`${t(`common:${localNamespace}.title`)}...`}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
