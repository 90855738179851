import { cloneDeep, uniqBy } from 'lodash';
import { GET_GROUP_DASHBOARD, GET_GROUP_MEMBERSHIP } from '@shared/welibrary-graphql/user/queries';
import { GET_COMMENTS_FOR_CARD, GET_COMMENT_THREAD } from '@shared/welibrary-graphql/comment/queries';

import getLogger from '@core/logger';

const logger = getLogger(module);

const VALID_GROUP_MEMBERSHIP_CACHE_TYPES = ['user', 'email'];

/**
 * Used to update group membership query cache
 * @param {object} cache GraphQL cache
 * @param {object} res response data
 * @param {object} options - arbitrary data structure
 */
export const UPDATE_GET_GROUP_MEMBERSHIP = (cache, res, options) => {
    const { groupId, emails, type, mutationType } = options;
    if (!type || !VALID_GROUP_MEMBERSHIP_CACHE_TYPES?.includes(type)) {
        logger.warn('No type specified for cache update, will skip cache update');
        return;
    }

    try {
        const cacheData = cache.updateQuery(
            {
                query: GET_GROUP_MEMBERSHIP,
                variables: {
                    _id: groupId?.toString(), // if you do not explicity cast this to a string it for some reason does not work!!!!!
                    membersLimit: 10,
                    ownersLimit: 10,
                },
            },
            data => {
                const dataClone = cloneDeep(data);

                if (type === 'email') {
                    const _emails =
                        res?.data?.bulkInviteUsersByEmail?.usersToInviteByEmail ?? emails ?? [];

                    // convert emails to proper data structure
                    const formattedEmails = _emails?.map(invite => {
                        const _email = invite?.email ?? invite;

                        return {
                            message: null,
                            role: 'view',
                            userEmail: _email,
                            __typename: 'EmailInvite',
                        };
                    });
                    dataClone.groupById.emailInvites = [
                        ...formattedEmails,
                        ...(dataClone?.groupById?.emailInvites ?? []),
                    ];

                    // update existing members invited through emails
                    const existingInvitees =
                        res?.data?.bulkInviteUsersByEmail?.existingInvitees ?? [];

                    if (existingInvitees.length > 0) {
                        const userInvites = existingInvitees.map(user => {
                            return {
                                message: null,
                                role: 'view',
                                user,
                                __typename: 'MemberInvite',
                            };
                        });

                        dataClone.groupById.memberInvites = [
                            ...(dataClone?.groupById?.memberInvites ?? []),
                            ...userInvites,
                        ];
                    }
                }

                if (type === 'user') {
                    if (
                        mutationType === 'confirmPreAuthSubscriptionOrder' ||
                        mutationType === 'cancelPreAuthSubscriptionOrder'
                    ) {
                        const { user } = res?.data?.[mutationType];

                        // format user to member invite data shape
                        if (!user) return;
                        const preAuthPaymentMember = {
                            message: null,
                            role: 'view',
                            user,
                            __typename: 'PreAuthPaymentMember',
                        };

                        dataClone.groupById.preAuthPaymentMembers =
                            dataClone?.groupById?.preAuthPaymentMembers.filter(
                                member => member?.user?._id !== preAuthPaymentMember?.user?._id
                            );

                        return dataClone;
                    }

                    const { user } = res?.data?.addUserToGroup;
                    // format user to member invite data shape
                    if (!user) return;
                    const userInvite = {
                        message: null,
                        role: 'view',
                        user,
                        __typename: 'MemberInvite',
                    };

                    dataClone.groupById.memberInvites = [
                        ...(dataClone?.groupById?.memberInvites ?? []),
                        userInvite,
                    ];
                }

                return dataClone;
            }
        );
        return cacheData;
    } catch (error) {
        logger.error('get group membership cache not in use.', error);
    }
};

/**
 * Used to update group newsfeed after new post with a new content card
 * @param {object} cache GraphQL cache
 * @param {object} attachedCard Attached content Card
 * @param {object} options - arbitrary data structure
 */
export const UPDATE_GET_GROUP_DASHBOARD_NEWSFEED = (cache, attachedCard, options) => {
    const { groupId, subtype } = options;
    try {
        // const groupId = 'nX6CAtqkz2QiWrPSY';
        const { groupById } =
            cache.readQuery({
                query: GET_GROUP_DASHBOARD,
                variables: { _id: groupId },
            }) ?? {};

        if (!groupById) return;

        const cloneGroup = cloneDeep(groupById);
        if (subtype === 'DASHBOARD') {
            cache.writeQuery({
                query: GET_GROUP_DASHBOARD,
                variables: { _id: groupId },
                data: {
                    groupById: {
                        ...cloneGroup,
                        membersDashboard: {
                            ...cloneGroup?.membersDashboard,
                            results: [attachedCard, ...cloneGroup?.membersDashboard.results],
                        },
                    },
                },
            });
        }

        if (subtype === 'PUBLIC_DASHBOARD') {
            cache.writeQuery({
                query: GET_GROUP_DASHBOARD,
                variables: { _id: groupId },
                data: {
                    groupById: {
                        ...cloneGroup,
                        publicDashboard: {
                            ...cloneGroup?.publicDashboard,
                            results: [attachedCard, ...cloneGroup?.publicDashboard.results],
                        },
                    },
                },
            });
        }
    } catch (error) {
        logger.error('get group dashboard cache not in use.', error);
    }
};

const getUpdatedPaginatedCommentsAfterRemoval = (deleteComment, existingComments) => {
    let newComments;

    /**
     * NOTE: If we add support for n > 1 depth nesting for comment threads
     * This merge will incorrectly merge in the cache. This will only search one layer deep
     * for cache merge. Comments added at deeper levels will be ignored by cache.
     * When we go deeper, we should update typePolicies and change query/subscription structure.
     * */
    if (deleteComment.parentId) {
        // If subthreading a comment, delete from parent's children.
        newComments = {
            ...existingComments,
            results: existingComments?.results.map(c => {
                if (c._id === deleteComment.parentId) {
                    return {
                        ...c,
                        children: {
                            ...c.children,
                            results: (c.children?.results || []).filter(
                                comment => comment._id !== deleteComment._id
                            ),
                        },
                    };
                }
                return c;
            }),
        };
    } else {
        // Otherwise, add the new comment into the current commentsForCard query.
        newComments = {
            ...existingComments,
            results: existingComments.results?.filter(comment => comment._id !== deleteComment._id),
        };
    }

    if (!newComments) return;
    return newComments;
};

export const UPDATE_COMMENTS_AFTER_DELETE = (client, deleteComment, { cardUrl }) => {
    if (deleteComment?.parentId) {
        try {
            const parent = client.readQuery({
                query: GET_COMMENT_THREAD,
                variables: { _id: deleteComment?.parentId },
            })?.comment;

            const subthreadOnParent = parent?.children;

            if (!subthreadOnParent) return;

            const newSubthreadOnParent = {
                ...subthreadOnParent,
                results: subthreadOnParent.results?.filter(
                    comment => comment._id !== deleteComment._id
                ),
            };

            const newParentComment = {
                ...parent,
                children: newSubthreadOnParent,
            };

            client.writeQuery({
                query: GET_COMMENT_THREAD,
                variables: { _id: deleteComment?.parentId },
                data: { comment: newParentComment },
            });
        } catch (e) {
            logger.error(e);
        }
    }

    const existingComments = client.readQuery({
        query: GET_COMMENTS_FOR_CARD,
        variables: { url: cardUrl },
    })?.commentsForCard;

    if (!deleteComment || !existingComments) return;

    const newComments = getUpdatedPaginatedCommentsAfterRemoval(deleteComment, existingComments);

    client.writeQuery({
        query: GET_COMMENTS_FOR_CARD,
        variables: { url: cardUrl },
        data: { commentsForCard: newComments },
    });
};

export const UPDATE_COMMENTS_AFTER_ADD = (client, newComment, { cardUrl }) => {
    if (newComment?.parentId) {
        try {
            const parent = client.readQuery({
                query: GET_COMMENT_THREAD,
                variables: { _id: newComment?.parentId },
            })?.comment;

            const subthreadOnParent = parent?.children;

            if (!subthreadOnParent) return;

            const newSubthreadOnParent = {
                ...subthreadOnParent,
                results: uniqBy(
                    [newComment, ...subthreadOnParent?.results],
                    comment => comment._id
                ),
            };

            const newParentComment = {
                ...parent,
                children: newSubthreadOnParent,
            };

            client.writeQuery({
                query: GET_COMMENT_THREAD,
                variables: { _id: newComment?.parentId },
                data: { comment: newParentComment },
            });
        } catch (e) {
            logger.error(e);
        }
    }

    const existingComments = client.readQuery({
        query: GET_COMMENTS_FOR_CARD,
        variables: { url: cardUrl },
    })?.commentsForCard;

    if (!newComment || !existingComments) return;

    let newComments;

    /**
     * NOTE: If we add support for n > 1 depth nesting for comment threads
     * This merge will incorrectly merge in the cache. This will only search one layer deep
     * for cache merge. Comments added at deeper levels will be ignored by cache.
     * When we go deeper, we should update typePolicies and change query/subscription structure.
     * */
    if (newComment.parentId) {
        // If subthreading a comment, add to parent's children.
        newComments = {
            ...existingComments,
            results: existingComments?.results.map(c => {
                if (c._id === newComment.parentId) {
                    return {
                        ...c,
                        children: {
                            ...c.children,
                            results: uniqBy(
                                [newComment, ...(c.children?.results || [])],
                                comment => comment._id
                            ),
                        },
                    };
                }
                return c;
            }),
        };
    } else {
        // Otherwise, add the new comment into the current commentsForCard query.
        newComments = {
            ...existingComments,
            results: uniqBy([newComment, ...existingComments.results], item => item._id),
        };
    }

    if (!newComments) return;

    client.writeQuery({
        query: GET_COMMENTS_FOR_CARD,
        variables: { url: cardUrl },
        data: { commentsForCard: newComments },
    });
};
