import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';

import {
    GET_GROUP_DASHBOARD,
    GET_GROUP_MEMBERSHIP,
    GET_CURRENT_USER_SIMPLE,
} from '@shared/welibrary-graphql/user/queries';
import {
    FLAG_USER,
    FOLLOW_USER,
    SWITCH_ACTIVE_GROUP,
    UPDATE_USER_CONNECTION,
} from '@shared/welibrary-graphql/user/mutations';
import { FOLLOW_CARD, REQUEST_ACCESS_TO_CARD } from '@shared/welibrary-graphql/content_card/mutations';
import { CREATE_MESSAGE_THREAD } from '@shared/welibrary-graphql/messaging/mutations';
import { isGroupPrivate } from '@helpers/recommendations/recommendations.helpers';

import usePrompt from '@components/generic/hooks/usePrompt';
import useQuickLinks from '@web/ui/components/navigation/SideMenu/Navigator/hooks/useQuickLinks';
import useGroupActions from '@components/group/hooks/useGroupActions';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import AlertToast from '@components/toast/AlertToast';
import DropdownController from '@web/ui/components/generic/dropdownmenu/DropdownController';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    getRandomDefaultThumbImage,
    getRandomDefaultUserImage,
    StyledBackgroundImageLink,
} from '@core/utilities/constants/defaults';
import {
    getSocialActionDropdownItems,
    isUserSociallyEngaged,
} from '@core/utilities/constants/user';
import { renderCountryFlag } from '@core/utilities/constants/countries';
import { getWhitelabeledKey } from '@core/utilities/constants/languages';

import { defaultAlertToastOptions } from '@core/types/Toast';

import { CARD_ACTIONS } from '@core/utilities/constants/card_actions';
import { ROLE_ADMIN, ROLE_VIEW } from '@core/utilities/constants/roles';

const localNamespace = 'imports.wlWeb.ui.components.community';

const RECRUIT_TYPE = {
    EXISTING: 'EXISTING',
    NEW: 'NEW',
    POTENTIAL: 'POTENTIAL',
    NONE: 'NONE',
};

const RECRUIT_NAME = {
    [RECRUIT_TYPE.EXISTING]: `common:${localNamespace}.recruit_type.existing`,
    [RECRUIT_TYPE.NEW]: `common:${localNamespace}.recruit_type.new`,
    [RECRUIT_TYPE.POTENTIAL]: `common:${localNamespace}.recruit_type.potential`,
    [RECRUIT_TYPE.NONE]: `common:${localNamespace}.recruit_type.none`,
};

const CommunityListItem = ({
    source,
    customButtonClass,
    taskEdit,
    showEmail,
    title,
    connected,
    matchData,
    onRejectSuggestion,
    extraItemClass,
    openQuickMenu,
    getExtraQuickMenuOptions,
    loggedInUser,
    whitelabelVerbage,
    onClick,
}) => {
    const { t } = useTranslation();
    const prompt = usePrompt();
    const { newToast } = useToastActionsContext();
    const history = useHistory();
    const { addResult, getLinks, removeQuickLink } = useQuickLinks(false);

    const [followUser, { data: followUserData }] = useMutation(FOLLOW_USER);
    const [updateConnection, { data: userConnectionData }] = useMutation(UPDATE_USER_CONNECTION);
    const [flagUser, { data: flagUserData }] = useMutation(FLAG_USER);
    const [followCard, { data: followCardData }] = useMutation(FOLLOW_CARD);
    const [requestAccessToCard, { data: requestAccessToCardData }] = useMutation(
        REQUEST_ACCESS_TO_CARD
    );
    const [createMessageThread, { data: createMessageThreadData }] = useMutation(
        CREATE_MESSAGE_THREAD
    );

    const node = useRef();

    const [dropdownOpen, setOpen] = useState(false);
    const [switchActiveGroup, { client }] = useMutation(SWITCH_ACTIVE_GROUP);
    const { primaryActionAvailable, takeGroupActionForUser } = useGroupActions({
        group: source?.__typename === 'Group' ? source : null,
    });

    const handleClick = e => {
        if (node) {
            if (node.current) {
                if (node.current.contains(e.target)) {
                    // Click is inside dropdown
                    return;
                }
            }
        }
        setOpen(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, []);

    let followIcon;
    const displayStyle = {};

    let titleMarginClear;
    let titleSection;
    let headlineSection;
    let subSection;
    let matchingSection;
    let otherSection;
    let lastSection;
    let extraClass;

    if (matchData) {
        const { precision, featureScores } = matchData;

        let orderedFeatureScores = _.sortBy(featureScores, feature => feature.score);
        orderedFeatureScores = _.filter(orderedFeatureScores, feature => feature.score > 0);

        let featureLanguage;
        if (orderedFeatureScores && orderedFeatureScores.length >= 1) {
            const highMatchMessage = orderedFeatureScores[0].field.customText
                ? orderedFeatureScores[0].field.customText
                : orderedFeatureScores[0].field.prompt;
            if (orderedFeatureScores.length === 1) {
                featureLanguage = (
                    <span>
                        for
                        <strong>{` ${highMatchMessage}`}</strong>.
                    </span>
                );
            } else if (orderedFeatureScores.length > 1) {
                const lowMatchMessage = orderedFeatureScores[1].field.customText
                    ? orderedFeatureScores[1].field.customText
                    : orderedFeatureScores[1].field.prompt;
                featureLanguage = (
                    <span>
                        {/* TODO: Translation */}
                        for
                        <strong>{highMatchMessage}</strong>
                        <strong>{lowMatchMessage}</strong>.
                    </span>
                );
            }
        }

        matchingSection = (
            <div className="summary-list-matching">
                <span className="matching-link-trigger">{Math.round(precision * 100)}%</span>{' '}
                {t(`common:${localNamespace}.matched`)} {featureLanguage}
            </div>
        );

        extraClass = 'matching-card-container';
    }

    let _followIcon;
    let _title = title;
    let _description;
    let _thumbImage;
    let _linkTo = '#';
    let _onClick;
    let _connected = connected;

    let _quickMenuList = getExtraQuickMenuOptions ? getExtraQuickMenuOptions(source) : [];

    switch (source.__typename ? source.__typename : source.type) {
        case 'TaskCompletion':
            if (!source || !source.verifier) break;
            const _verifier = source && source.verifier ? source.verifier : {};
            const profile = source && source.claimedAccount && source.claimedAccount.profile;
            _title = _verifier.name;
            _description = _verifier.note;
            _thumbImage = profile ? profile.picture : getRandomDefaultUserImage();
            _linkTo =
                source && source.claimedAccount && source.claimedAccount._id
                    ? `/u/${source.claimedAccount._id}`
                    : '#';
            const { country } = _verifier;
            const { phone } = _verifier;
            const { email } = _verifier;
            extraClass = 'cl-top-align';
            const taskLabel = source.label;
            const newRecruitFlag = source.newRecruit;
            const recruitType = _verifier && _verifier.recruitType;

            // titleSection = <div className={`list-item-title display-text-inline ${titleMarginClear ? 'margin-clear' : ''}`}>{_title} {!!country && renderCountryFlag({ countryCode: country })}</div>
            headlineSection = !!country && (
                <div className="task-title-container">
                    <p
                        className={`list-item-title display-text-inline ${
                            titleMarginClear ? 'margin-clear' : ''
                        }`}
                    >
                        <b>{_title}</b>
                    </p>
                    <div>
                        <b>{t(getWhitelabeledKey(RECRUIT_NAME[recruitType], whitelabelVerbage))}</b>
                    </div>
                    <div className="task-country-container">
                        {!!country && renderCountryFlag({ countryCode: country })}

                        <span className="task-country-span">
                            {t(`common:countries.names.${country}`)}
                        </span>
                    </div>
                </div>
            );
            subSection = !!phone && <div>{phone}</div>;
            matchingSection = !!email && <div className="summary-list-matching">{email}</div>;

            break;
        case 'Group':
            const connectedShelf = _.has(source, 'shelf') && source.shelf ? source.shelf : null;

            _followIcon = _connected ? 'orgfollowbutton following-org' : 'orgfollowbutton';
            // _linkTo =  connectedShelf ? `/s/${source.shelf.url}` : `/u/${source._id}`;
            _linkTo = `/g/${source._id}`;

            _title = source.profile ? source.profile.full_name : '';
            _description = source.profile ? source.profile.short_bio : '';
            _thumbImage = source.profile.picture
                ? source.profile.picture
                : getRandomDefaultCoverImage(_title);
            if (connectedShelf) {
                _thumbImage = connectedShelf.media.icon ? connectedShelf.media.icon : _thumbImage;
                _title = connectedShelf.title ? connectedShelf.title : _title;
                _description = connectedShelf.body ? connectedShelf.body : _description;
            }

            const groupIsPrivate = isGroupPrivate(source);

            titleSection = (
                <div
                    className={`list-item-title display-text-inline ${
                        titleMarginClear ? 'margin-clear' : ''
                    }`}
                >
                    {_title}
                    {groupIsPrivate && (
                        <span
                            className="community-list-item-private-icon w-inline-block"
                            title="Private group"
                        />
                    )}
                </div>
            );
            headlineSection = <div>{_description}</div>;

            // If the user is a member or admin of the group, set active group upon select.
            if (loggedInUser && (source.currentUserIsAdmin || source.currentUserIsMember)) {
                _onClick = () => {
                    switchActiveGroup({
                        variables: {
                            groupId: source._id,
                        },
                        refetchQueries: [
                            GET_GROUP_DASHBOARD,
                            GET_GROUP_MEMBERSHIP,
                            GET_CURRENT_USER_SIMPLE,
                        ],
                        update: () => {
                            // client.resetStore()
                            //     .then(() => {
                            history.push('/dashboard');
                            // });
                        },
                    });
                };
                _linkTo = '#';
            }

            if (primaryActionAvailable?.label) {
                _quickMenuList.push({
                    id: 'take-group-action',
                    title: primaryActionAvailable.label,
                    onClick: () =>
                        takeGroupActionForUser(loggedInUser._id, primaryActionAvailable.action),
                });
            }

            _quickMenuList.push({
                id: 'goto-orf-rofile',
                iconKey: 'View Group Profile',
                title: t(`common:${localNamespace}.view_group_dashboard`),
                to: `/g/${source._id}`,
            });

            break;
        case 'User':
            _connected = isUserSociallyEngaged(source);
            _title = source.profile ? source.profile.full_name : '';
            _description = source.profile ? source.profile.short_bio : '';
            const _email = source.profile?.email;
            const emailLink = `mailto:${_email}`;

            _thumbImage = source.profile.picture
                ? source.profile.picture
                : getRandomDefaultUserImage();
            if (!_followIcon) _followIcon = _connected ? ' following-user' : '';
            _linkTo = _linkTo !== '#' ? _linkTo : `/u/${source._id}`;

            titleSection = (
                <div
                    className={`list-item-title display-text-inline ${
                        titleMarginClear ? 'margin-clear' : ''
                    }`}
                >
                    {_title}
                </div>
            );
            headlineSection = <div>{_description}</div>;
            if (showEmail) {
                otherSection = (
                    <div className="plus-info">
                        <a className="email-link" href={emailLink}>
                            {_email}
                        </a>
                    </div>
                );
            }

            // _quickMenuList = [];
            _quickMenuList.push({
                id: 'goto-profile',
                iconKey: 'member',
                title: t(`common:${localNamespace}.view_profile`),
                to: _linkTo,
            });

            // _quickMenuList.push({
            //     id:'invite-to-group',
            //     title:"Invite to Group",
            //     onClick: () => { alert("Messaged!") },
            //     onClose: () => { openQuickMenu([{ id:90, title:"<Group>", to: "/s/community"}])}
            // });

            _quickMenuList = [
                ..._quickMenuList,
                ...getSocialActionDropdownItems(
                    source,
                    {
                        updateConnection,
                        flagUser,
                        createMessageThread,
                        currentUser: loggedInUser,
                        history,
                    },
                    openQuickMenu,
                    t
                ),
            ];

            break;
        case 'ContentCard':
            const sourceRef = source.reference ? source.reference : {};
            const sourceMedia = source.media ? source.media : {};
            const { cardActionsAllowed } = source;

            _connected = cardActionsAllowed
                ? cardActionsAllowed.includes(CARD_ACTIONS.UNFOLLOW) ||
                  cardActionsAllowed.includes(CARD_ACTIONS.UNSUBSCRIBE) ||
                  cardActionsAllowed.includes(CARD_ACTIONS.EXIT_MEMBERSHIP)
                : false;
            _title = source.title;
            _description = source.body
                ? source.body
                : _.isString(source.description)
                ? source.description.substring(0, 30)
                : '';
            _thumbImage = sourceMedia.thumb
                ? sourceMedia.thumb
                : getRandomDefaultThumbImage(source.title);
            _followIcon = _connected
                ? 'projectfollowbutton following-project'
                : 'projectfollowbutton';
            _linkTo = sourceRef.href ? sourceRef.href : '#'; // `/c/${source._id}`;

            titleSection = (
                <div
                    className={`list-item-title display-text-inline ${
                        titleMarginClear ? 'margin-clear' : ''
                    }`}
                >
                    {_title}
                </div>
            );
            headlineSection = <div>{_description}</div>;
            if (source.type === 'event') {
                headlineSection = (
                    <div>
                        <strong>NOV 17 2019</strong> | 10:30am - 5:00pm
                    </div>
                );
                subSection = (
                    <div>
                        {t('common:global.nouns.locations')},{t('common:global.nouns.city')}, ST |
                        <span className="inline-event-attendance-button">
                            t(`common:global.verbs.attend`){' '}
                        </span>
                        <span className="inline-event-attendance-button attending">
                            t(`common:global.verbs.attending`)
                        </span>
                    </div>
                );
                _followIcon = _connected
                    ? 'projectfollowbutton following-project'
                    : 'projectfollowbutton';
            }

            if (_linkTo !== '#') {
                _quickMenuList.push({
                    id: 'goto-profile',
                    title: t(`common:${localNamespace}.view_page`),
                    to: _linkTo,
                });
            }

            if (_.isArray(cardActionsAllowed)) {
                if (
                    cardActionsAllowed.includes(CARD_ACTIONS.FOLLOW) ||
                    cardActionsAllowed.includes(CARD_ACTIONS.SUBSCRIBE)
                ) {
                    _quickMenuList.push({
                        id: CARD_ACTIONS.FOLLOW,
                        title: cardActionsAllowed.includes(CARD_ACTIONS.FOLLOW)
                            ? t('common:global.verbs.follow')
                            : t('common:global.verbs.subscribe'),
                        onClick: () => {
                            const _optimisticCardActions = cardActionsAllowed.filter(
                                action =>
                                    action !== CARD_ACTIONS.FOLLOW &&
                                    action !== CARD_ACTIONS.SUBSCRIBE
                            );
                            cardActionsAllowed.includes(CARD_ACTIONS.FOLLOW)
                                ? _optimisticCardActions.push(CARD_ACTIONS.UNFOLLOW)
                                : _optimisticCardActions.push(CARD_ACTIONS.UNSUBSCRIBE);

                            followCard({
                                variables: { url: source.url, unFollow: false },
                                optimisticResponse: {
                                    __typename: 'Mutation',
                                    followCard: {
                                        __typename: 'ContentCard',
                                        _id: source._id,
                                        cardActionsAllowed: _optimisticCardActions,
                                    },
                                },
                            });
                        },
                    });
                } else if (
                    cardActionsAllowed.includes(CARD_ACTIONS.UNFOLLOW) ||
                    cardActionsAllowed.includes(CARD_ACTIONS.UNSUBSCRIBE)
                ) {
                    _quickMenuList.push({
                        id: CARD_ACTIONS.UNFOLLOW,
                        iconKey: 'Unfollow',
                        title: cardActionsAllowed.includes(CARD_ACTIONS.UNFOLLOW)
                            ? t('common:global.verbs.unfollow')
                            : t('common:global.verbs.unsubscribe'),
                        onClick: () => {
                            const _optimisticCardActions = cardActionsAllowed.filter(
                                action =>
                                    action !== CARD_ACTIONS.UNFOLLOW &&
                                    action !== CARD_ACTIONS.UNSUBSCRIBE
                            );
                            cardActionsAllowed.includes(CARD_ACTIONS.UNFOLLOW)
                                ? _optimisticCardActions.push(CARD_ACTIONS.FOLLOW)
                                : cardActionsAllowed.includes(CARD_ACTIONS.SUBSCRIBE);

                            followCard({
                                variables: { url: source.url, unFollow: true },
                                optimisticResponse: {
                                    __typename: 'Mutation',
                                    followCard: {
                                        __typename: 'ContentCard',
                                        _id: source._id,
                                        cardActionsAllowed: _optimisticCardActions,
                                    },
                                },
                            });
                        },
                    });
                }

                if (cardActionsAllowed.includes(CARD_ACTIONS.OPEN_JOIN) && source.shortCode) {
                    _quickMenuList.push({
                        id: CARD_ACTIONS.OPEN_JOIN,
                        iconKey: 'Join Group',
                        title: 'Join Group',
                        to: `/add-group/${source.shortCode}`,
                    });
                }

                if (cardActionsAllowed.includes(CARD_ACTIONS.CANCEL_JOIN_REQUEST)) {
                    _quickMenuList.push({
                        id: CARD_ACTIONS.CANCEL_JOIN_REQUEST,
                        iconKey: 'x',
                        title: 'Join Request Pending',
                        to: '#',
                    });
                } else {
                    if (cardActionsAllowed.includes(CARD_ACTIONS.MEMBER_JOIN)) {
                        _quickMenuList.push({
                            id: CARD_ACTIONS.MEMBER_JOIN,
                            iconKey: 'Join Group',
                            title: 'Request Membership',
                            onClick: async () => {
                                const requestMessage = await prompt(
                                    t(`common:${localNamespace}.request_message_prompt`)
                                );
                                requestAccessToCard({
                                    variables: {
                                        cardUrl: source.url,
                                        role: ROLE_VIEW,
                                        message: requestMessage,
                                    },
                                }).then(() => {
                                    newToast(
                                        <AlertToast
                                            boldText={t(
                                                `common:${localNamespace}.member_request`,
                                                'Member request, successfully sent!'
                                            )}
                                            showSuccessIcon
                                        />,
                                        {
                                            ...defaultAlertToastOptions,
                                        }
                                    );
                                });
                            },
                        });
                    }
                    if (cardActionsAllowed.includes(CARD_ACTIONS.ADMIN_JOIN)) {
                        _quickMenuList.push({
                            id: CARD_ACTIONS.ADMIN_JOIN,
                            title: 'Request Admin Role',
                            onClick: async () => {
                                const requestMessage = await prompt(
                                    t(`common:${localNamespace}.admin_request_message_prompt`)
                                );
                                requestAccessToCard({
                                    variables: {
                                        cardUrl: source.url,
                                        role: ROLE_ADMIN,
                                        message: requestMessage,
                                    },
                                }).then(() => {
                                    newToast(
                                        <AlertToast
                                            boldText={t(
                                                `common:${localNamespace}.admin_request`,
                                                'Admin request, successfully sent!'
                                            )}
                                            showSuccessIcon
                                        />,
                                        {
                                            ...defaultAlertToastOptions,
                                        }
                                    );
                                });
                            },
                        });
                    }
                }
            }

            break;
        default:
            break;
    }

    const [src, srcSet] = getBackgroundImageSetForUrl(_thumbImage || getRandomDefaultUserImage());

    const hasQuickItems = _quickMenuList.length > 0;
    const { quickLinks } = getLinks();
    const linkIndex = quickLinks?.findIndex(item => item.url === source._id);

    if (linkIndex > -1) {
        _quickMenuList.push({
            id: 10,
            iconKey: 'x',
            title: t(`common:global.menuOptions.remove_quick_link`),
            onClick: () => {
                removeQuickLink(linkIndex);
            },
        });
    } else {
        _quickMenuList.push({
            id: 11,
            iconKey: 'Plus',
            title: t(`common:global.menuOptions.add_quick_link`),
            onClick: () => {
                addResult(source);
            },
        });
    }

    const listItemClass = extraItemClass
        ? `summary-list-item ${extraItemClass}`
        : 'summary-list-item';
    let buttonClass = connected
        ? 'button-reset content-button-icon dropdown-dots'
        : `button-reset follow-button ${_followIcon} w-inline-block`;
    if (customButtonClass) {
        buttonClass = customButtonClass;
    }
    if (taskEdit) buttonClass = 'button-reset edit-button-icon';

    return (
        <li className={listItemClass} style={displayStyle}>
            <div
                className={`summary-list-item-content cl-top-align ${extraClass}`}
                onClick={_onClick}
            >
                <StyledBackgroundImageLink
                    to={_linkTo}
                    onClick={onClick}
                    className="thumbnail larger-thumbnail w-inline-block"
                    src={src}
                    srcSet={srcSet}
                />

                {source.__typename === 'TaskCompletion' || source.type === 'TaskCompletion' ? (
                    <div className="summary-list-item-link w-inline-block">
                        {titleSection}
                        {headlineSection}
                        {subSection}
                        {matchingSection}
                        {lastSection}
                    </div>
                ) : (
                    <div className="summary-list-sections">
                        <Link
                            to={_linkTo}
                            className="summary-list-item-link w-inline-block"
                            onClick={onClick}
                        >
                            {titleSection}
                            {headlineSection}
                            {subSection}
                            {matchingSection}
                            {lastSection}
                        </Link>
                        {otherSection}
                    </div>
                )}
            </div>
            {hasQuickItems && (
                <div className="summary-list-item-right">
                    <DropdownController menuList={_quickMenuList} buttonClass={buttonClass} />
                    {onRejectSuggestion && (
                        <div
                            name={_title}
                            onClick={onRejectSuggestion}
                            className="summary-list-remove-button w-inline-block"
                        />
                    )}
                </div>
            )}
        </li>
    );
};

export default CommunityListItem;
