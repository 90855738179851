import React from 'react';
import useUniversalWallet from '@web/utilities/hooks/wallet/useUniversalWallet';

import AvatarCircle from '@components/lego/AvatarCircle';
import VerifiableCredentialModal from '@components/lego/modals/VerifiableCredentialModal';

import { convertTagsToSkills } from '@helpers/lego/credential.helpers';
import { BadgeRecord } from '@core/types/Credential';

type RealVCModalProps = {
    badge: BadgeRecord;
    user: any;
};

const RealVCModal: React.FC<RealVCModalProps> = ({ badge, user }) => {
    const { subjectDidFromWallet } = useUniversalWallet();

    const { name, description, image, tag } = badge.credential.credentialSubject.achievement;
    const { skills, subskills } = convertTagsToSkills(tag);

    const issuedToDid = badge.credential.credentialSubject.id;

    const { issuer } = badge.credential;
    const issuerName = issuer.name || issuer.id || issuer;

    const credentialInfo = {
        title: name,
        description,
        image,
        skills,
        subskills,
        issuedToUserName:
            subjectDidFromWallet === issuedToDid ? user.profile.simple.name : issuedToDid,
        issuedToUserThumb: <AvatarCircle student={user} />,
        issuedByName: issuerName,
    };

    return <VerifiableCredentialModal credentialInfo={credentialInfo} vc={badge.credential} />;
};

export default RealVCModal;
