import React, { useState } from 'react';

import { awsMetricPublisher } from '@welibraryos/metrics';

import { useCurrentUser } from '@stores/User';

import useQuickLinks from '@web/ui/components/navigation/SideMenu/Navigator/hooks/useQuickLinks';

import NavigatorToggleButton from '@web/ui/components/navigation/SideMenu/Navigator/NavigatorToggleButton';
import AliasQuickLink from '@web/ui/components/navigation/SideMenu/Navigator/AliasQuickLink';
import QuickLink from '@web/ui/components/navigation/SideMenu/Navigator/QuickLink';
import AddQuickLinkButton from '@web/ui/components/navigation/SideMenu/Navigator/AddQuickLinkButton';
import QuickLinkUserDescription from '@web/ui/components/navigation/SideMenu/Navigator/QuickLinkUserDescription';

import { getRandomDefaultUserImage } from '@core/utilities/constants/defaults';

const SideMenuNavigator: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { currentUser } = useCurrentUser();

    const { getLinks } = useQuickLinks();

    const { homeLink, quickLinks } = getLinks();
    const links = quickLinks
        ?.filter(link => {
            if (!link.group) return true;

            // filter out unpublished groups for non-admins
            const { isUnpublished, currentUserIsAdmin, currentUserIsDirectAdmin } = link.group;
            if (isUnpublished && !(currentUserIsAdmin || currentUserIsDirectAdmin)) return false;
            return true;
        })
        .map((link, index) => (
            <AliasQuickLink key={link?.url ?? index} alias={link ?? undefined} index={index} />
        ));

    return (
        <section className={`side-menu-navigator ${isOpen ? 'expanded' : ''}`}>
            <NavigatorToggleButton
                onClick={() => {
                    setIsOpen(open => {
                        const metricNameAndValue = !open ? 'expand' : 'shrink';
                        awsMetricPublisher.publishCount('side-menu-quick-links', 1, [
                            { Name: 'menu-area', Value: metricNameAndValue },
                        ]);
                        return !open;
                    });
                }}
                className="side-menu-navigator-toggle-button"
            />
            {/* disable scroll bars here is poorly  named, what it does it hide the scroll bar */}
            <nav className="disable-scrollbars">
                <header>
                    <QuickLink
                        to="/u"
                        picture={currentUser?.profile?.picture || getRandomDefaultUserImage()}
                        alt="Profile"
                        wrapChildrenInLink={false}
                    >
                        <QuickLinkUserDescription />
                    </QuickLink>

                    <AliasQuickLink alias={homeLink} home />
                </header>
                <ul className="disable-scrollbars">
                    {links}
                    <AddQuickLinkButton />
                </ul>
            </nav>
        </section>
    );
};

export default SideMenuNavigator;
