import React, { CSSProperties } from 'react';

import StylizedCheck from '@web/ui/dsc/svgs/lego/StylizedCheck';

import { ResourceMeta } from '@web/utilities/helpers/courses/course.helpers';

import { Resource } from '@shared/welibrary-graphql/types';
import ChunkyArrowBendDownRight from '@dsc/svgs/ChunkyArrowBendDownRight';

type ResourceIconProps = {
    type: Resource;
    checked?: boolean;
    quantity?: number;
    className?: string;
    color?: string;
    size?: string;
    hidePlus?: boolean;
    subskillIcon?: boolean;
};

const ResourceIcon: React.FC<ResourceIconProps> = ({
    type,
    checked = false,
    quantity,
    className = '',
    color,
    size,
    hidePlus,
    subskillIcon = false,
}) => {
    return (
        <section
            className={`resource-icon ${className}`}
            style={
                {
                    '--bg-color': checked ? ResourceMeta[type].color : color || '#BDBDBD',
                    width: size,
                    height: size,
                } as CSSProperties
            }
        >
            {checked && <StylizedCheck className="resource-check" />}
            <img src={ResourceMeta[type].icon} alt={ResourceMeta[type].adjective} />
            {typeof quantity === 'number' && <span>{`${hidePlus ? '' : '+'}${quantity}`}</span>}
            {subskillIcon && <ChunkyArrowBendDownRight className="resource-bend-arrow" />}
        </section>
    );
};

export default ResourceIcon;
