import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStripe } from '@stripe/react-stripe-js';

import {
    useGetUserOrdersForGroupLazyQuery,
    useGetLoggedInCustomerCardByIdQuery,
} from '@shared/welibrary-graphql/user/queries.hook';
import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';

import CardBranding from '@components/group/groupdashboard/groupSubscriptionPaywall/CardBranding';
import Button from '@dsc/forms/buttons/Button';

import { CustomerOrder } from '@shared/welibrary-graphql/types';

const SubscriptionRenewalConfirmation: React.FC<{
    clientSecret: string;
    order: CustomerOrder;
    className?: string;
}> = ({ className, clientSecret, order }) => {
    const stripe = useStripe();
    const { t } = useTranslation();
    const { closeModal } = useModal();
      const { currentUser } = useCurrentUser();

    const [getUserOrders] = useGetUserOrdersForGroupLazyQuery({
        variables: {
            groupId: order.products?.[0]?.reference?._id,
            status: 'succeeded',
        },
        fetchPolicy: 'network-only',
    });

    const { data } = useGetLoggedInCustomerCardByIdQuery({
        variables: {
            cardId: currentUser?.paymentSettings?.stripeCustomer?.default_source,
        },
        fetchPolicy: 'network-only',
    });

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [cardErr, setCardErr] = useState<any>(null);

    const handleConfirmPayment = async () => {
        setIsProcessing(true);

        if (!stripe) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const payload = await stripe?.confirmCardPayment(clientSecret, {
            payment_method: currentUser?.paymentSettings?.stripeCustomer?.default_source,
        });

        if (payload.error) {
            setCardErr(payload.error);
            setIsProcessing(false);
        } else {
            setIsProcessing(false);
            getUserOrders();
            closeModal();
        }
    };

    const cancel = () => {
        closeModal();
    };

    const confirmText = isProcessing ? 'Loading...' : t('common:ok', 'Ok');

    return (
        <section role="alert" className={`confirmation ${className}`}>
            <div className="subs-card-item-preview">
                <div className="subs-card-item-preview-top">
                    <CardBranding
                        branding={data?.getLoggedInCustomerCardById?.brand}
                        customClassName="card-branding-large"
                    />
                    <p>
                        Expires {data?.getLoggedInCustomerCardById?.exp_month}/
                        {data?.getLoggedInCustomerCardById?.exp_year}
                    </p>
                </div>

                <div className="subs-card-item-preview-bottom">
                    <h3>**** **** **** {data?.getLoggedInCustomerCardById?.exp_year}</h3>
                </div>
            </div>

            {cardErr && (
                <div className="customer-card-error-wrap">
                    <p className="customer-card-error-text">x {cardErr?.message}</p>
                </div>
            )}

            <p>Your default payment method will be charged.</p>

            <footer>
                <Button onClick={handleConfirmPayment} type="small">
                    {confirmText}
                </Button>
                <Button onClick={cancel} type="small">
                    {t('common:cancel', 'Cancel')}
                </Button>
            </footer>
        </section>
    );
};

export default SubscriptionRenewalConfirmation;
