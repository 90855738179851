/* eslint-disable no-nested-ternary */
import React from 'react';

import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';

import useGroupActions from '@components/group/hooks/useGroupActions';

import GroupRegisterButton from '@components/group/groupdashboard/GroupRegisterButton';

import { isEventInThePast, isEventFree } from '@helpers/events/event.helpers';
import { groupSellsProductsButCantAcceptPayments } from '@components/group/groupdashboard/groupDashboardHelpers';
import { GROUP_ACTIONS } from '@core/utilities/constants/group';

import { User, Group } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

const GroupItemButton: React.FC<{
    user?: Maybify<User> | null;
    groupDashboardData?: Maybify<Group> | null;
}> = ({ user, groupDashboardData }) => {
    const { takeGroupActionForUser, primaryActionAvailable } = useGroupActions({
        group: groupDashboardData,
        refetchQueries: [GET_GROUP_DASHBOARD],
    });

    const handleTakePrimaryAction = (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        takeGroupActionForUser?.(user?._id, primaryActionAvailable?.action);
    };

    let showExclamation = false;
    const merchantAccountNotActive = groupSellsProductsButCantAcceptPayments(groupDashboardData);
    const eventIsFree = isEventFree(groupDashboardData);

    if (primaryActionAvailable?.label) {
        if (
            primaryActionAvailable.useRegisterButton &&
            eventIsFree &&
            groupDashboardData?.settings?.approvalRequired === false
        ) {
            const eventIsOver = isEventInThePast(groupDashboardData);
            if (eventIsOver) return <></>;
            return <GroupRegisterButton onClick={handleTakePrimaryAction} />;
        }

        if (
            primaryActionAvailable.action === GROUP_ACTIONS.GROUP_EDIT_PRODUCTS &&
            merchantAccountNotActive
        ) {
            showExclamation = true;
        }

        return (
            <button
                onClick={handleTakePrimaryAction}
                type="button"
                title="RSVP"
                className="button-reset job-card-button remove-bkg w-inline-block event-card-zindex"
                style={{ position: 'relative' }}
            >
                {showExclamation && <div className="event-exclamation">!</div>}
                {primaryActionAvailable?.label}
            </button>
        );
    }
    return null;
};

export default GroupItemButton;
