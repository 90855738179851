import React from 'react';

import SideMenuLinks from '@components/navigation/SideMenu/Main/SideMenuLinks';
import SideMenuFooter from '@components/navigation/SideMenu/Main/SideMenuFooter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { config } from '@config/getConfig';
import useNavigation from '../../useNavigation';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';
import { Link } from 'react-router-dom';

const SideMenuMain: React.FC = () => {
    const { closeSlideMenu } = useNavigation();
    const { defaultLogoBlack: logo } = getWhitelabelPublicSettings();
    const poweredByConfig = config?.public?.poweredBy;
    const flags = useFlags();
    const rootGroupId = config?.public?.rootGroupId;
    const enableLogoClickToGroup = flags?.enableLogoClickToGroup;

    return (
        <section className="side-menu-main">
            {!enableLogoClickToGroup && (
                <img src={logo ?? '/images/logo.welibrary.blue.svg'} alt="" />
            )}

            {enableLogoClickToGroup && (
                <Link
                    to={`/g/${rootGroupId}`}
                    className="m-0 max-w-[200px] p-0"
                    onClick={() => closeSlideMenu()}
                >
                    <img
                        src={logo ?? '/images/logo.welibrary.blue.svg'}
                        alt=""
                        className="ml-[22px] w-[170px]"
                    />
                </Link>
            )}
            {poweredByConfig && (
                <img
                    className="!ml-[64px]"
                    src={poweredByConfig?.logo}
                    alt={'Powered by ' + poweredByConfig?.name}
                />
            )}
            <SideMenuLinks />

            <SideMenuFooter />
        </section>
    );
};

export default SideMenuMain;
