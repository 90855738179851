import { User, ContentCard, Group } from '@shared/welibrary-graphql/types';
import { VIEW_PERMISSION_PRIVATE } from '@core/utilities/constants/roles';

export const isGroupPrivate = (source: User | ContentCard | Group): boolean => {
    return 'settings' in source
        ? source.settings?.protectionLevel === VIEW_PERMISSION_PRIVATE
        : false;
};

export default isGroupPrivate;
