//CARDS & DRAWERS
export const TOGGLE_CREATE_CARD = 'TOGGLE_CREATE_CARD';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const SELECT_LAST_DRAWER_TAB = 'SELECT_LAST_DRAWER_TAB';

//SEARCH
export const SEARCH_USER_QUERY = 'SEARCH_USER_QUERY';
export const SET_GLOBAL_QUERY = 'SEARCH_GLOBAL_QUERY';
export const SET_GLOBAL_QUERY_FILTER = 'SEARCH_GLOBAL_QUERY_FILTER';
export const SET_GLOBAL_QUERY_SCOPE = 'SEARCH_GLOBAL_QUERY_SCOPE';

//UI OVERLAYS
export const TOGGLE_SHOW_LOGIN = 'TOGGLE_SHOW_LOGIN';
export const TOGGLE_SHOW_LOGIN_ID = 'showLogin';

export const TOGGLE_SHOW_PROFILE = 'TOGGLE_SHOW_PROFILE';
export const TOGGLE_SHOW_PROFILE_ID = 'showProfile';

export const TOGGLE_SHOW_EDIT_SETTINGS = 'TOGGLE_SHOW_EDIT_SETTINGS';
export const TOGGLE_SHOW_EDIT_SETTINGS_ID = 'showSettings';

//BROWSE MENUS
export const TOGGLE_SHOW_CHAPTER_BROWSE_MENU_MOBILE = 'TOGGLE_SHOW_CHAPTER_BROWSE_MENU_MOBILE';
export const TOGGLE_SHOW_CHAPTER_BROWSE_MENU_MOBILE_ID = 'showChapterBrowseMenuMobile';

//VOTING
export const SET_VOTE_DISPLAY_STATE_ON_CARD = 'SET_VOTE_DISPLAY_STATE_ON_CARD';

//CURRENT USER
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
