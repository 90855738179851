import React from 'react';

const Plus: React.FC<{ className?: string; color?: string; version?: string; size?: string }> = ({
    className,
    color = 'currentColor',
    version = '1',
    size = '20',
}) => {
    if (version === '1') {
        return (
            <svg className={className} viewBox="0 0 32 32">
                <line
                    x1="12"
                    y1="16"
                    x2="20"
                    y2="16"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <line
                    x1="16"
                    y1="12"
                    x2="16"
                    y2="20"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </svg>
        );
    }

    // version 2
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M3.125 10H16.875"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 3.125V16.875"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Plus;
