import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { createApi } from 'unsplash-js';

import { getAllDefaultCoverImages } from '@core/utilities/constants/defaults';

import useUploadPhoto from '@components/generic/hooks/useUploadPhoto';
import useModal from '@components/modals/hooks/useModal';

import ImagePickerImage from '@components/content/ImagePickerImage';
import SearchInput from '@components/search/SearchInput';
import SearchLoader from '@components/search/SearchLoader';
import ZeroState from '@assets/NoImageResultsFace.svg';

import getLoadingText from '@helpers/imageUpload/imageUpload.helpers';
import { ImageWithLoadingState } from '@core/types/Utilities';

import { resizeUrl, changeQuality } from '@web/utilities/helpers/images/unsplash.helpers';

import { config } from '@config/getConfig';

import getLogger from '@core/logger';

const logger = getLogger(module);

const unsplashApi = createApi({ accessKey: config.public.unsplash.access_key });

const defaultPhotos = getAllDefaultCoverImages();
const uploadImagePlaceholderImage = '/images/upload-image-placeholder.svg';

const localNamespace = 'imports.wlWeb.ui.components.content.imagePicker';

type UnsplashImage = {
    url: string; // url to save
    thumbUrl: string; // url to display thumb
    downloadLocation: string;
    attributionName: string;
    attributionLink: string;
};

type ImagePickerInputProps = {
    onImageSelect: (selectedImageUrl: string) => void;
    useUnsplash?: boolean;
    uploadImagePlaceholderOverride?: string;
    defaultPhotosOverride?: string[];
};

// Figma: https://www.figma.com/file/yh76Wc304FnPDJkwzorzRU/Image-Picker?node-id=0%3A1
const ImagePicker: React.FC<ImagePickerInputProps> = ({
    onImageSelect,
    useUnsplash = true,
    uploadImagePlaceholderOverride,
    defaultPhotosOverride,
}) => {
    const { t } = useTranslation();
    const { closeModal } = useModal();
    const [searchText, setSearchText] = useState('');

    const selectImageAndCloseModal = (selectedImageUrl: string) => {
        closeModal();
        onImageSelect(selectedImageUrl);
    };

    const [uploadedImage, setUploadedImage] = useState<ImageWithLoadingState>({
        image: uploadImagePlaceholderOverride || uploadImagePlaceholderImage,
    });
    const handleFileSelect = useUploadPhoto(setUploadedImage, selectImageAndCloseModal);
    const loadingText = getLoadingText(uploadedImage, t, false);

    const [unsplashResults, setUnsplashResults] = useState<UnsplashImage[] | null>(null);
    const [searchingUnsplash, setSearchingUnsplash] = useState(false);

    const runUnsplashSearch = (query: string) => {
        if (query) {
            unsplashApi.search.getPhotos({ query, perPage: 30 }).then(result => {
                if (result.type === 'error') {
                    logger.error('Error getting Unsplash images', result.errors[0]);
                    setUnsplashResults([]);
                    return;
                }

                const unsplashPhotos = result.response.results.map(photo => ({
                    url: resizeUrl(changeQuality(photo.urls.full, 75), 1000),
                    thumbUrl: resizeUrl(changeQuality(photo.urls.thumb, 60), 400),
                    downloadLocation: photo.links.download_location,
                    attributionName: photo.user.name,
                    attributionLink: `${photo.user.links.html}?utm_source=WeLibrary&utm_medium=referral`,
                }));

                setSearchingUnsplash(false);
                setUnsplashResults(unsplashPhotos);
            });
        } else {
            setSearchingUnsplash(false);
            setUnsplashResults(null);
        }
    };

    const debouncedUnsplashSearch = useCallback(debounce(runUnsplashSearch, 1000), []);

    // run debounced search every time the search text changes
    useEffect(() => {
        setSearchingUnsplash(searchText !== '');
        setUnsplashResults(null);

        if (searchText) debouncedUnsplashSearch(searchText);
    }, [searchText]);

    const handleUnsplashImageSelect = (imageUrl: string, downloadLocation: string) => {
        unsplashApi.photos.trackDownload({ downloadLocation });
        selectImageAndCloseModal(imageUrl);
    };

    const initialImages = defaultPhotosOverride || defaultPhotos;

    return (
        <section className="image-picker-wrapper">
            <header className="image-picker-header">
                <div className="image-picker-title">
                    {t(`common:${localNamespace}.select_image`, 'Select Image')}
                </div>

                <div className="image-picker-cancel mobile-only">
                    <span onClick={closeModal}>{t('common:global.verbs.cancel', 'Cancel')}</span>
                </div>

                {useUnsplash && (
                    <SearchInput
                        onChange={value => setSearchText(value)}
                        value={searchText}
                        placeholder={t(`common:${localNamespace}.search`, 'Search...')}
                    />
                )}
            </header>

            <section className="image-list-section">
                {useUnsplash && (
                    <div className="unsplash-attribution">
                        {`${t(`common:${localNamespace}.photos_by`, 'Photos by')} `}
                        <a
                            href="https://unsplash.com/?utm_source=WeLibrary&utm_medium=referral"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Unsplash
                        </a>
                    </div>
                )}

                <ul className="image-list">
                    {!searchText && (
                        <ImagePickerImage
                            imageUrl={uploadedImage.image}
                            onClick={handleFileSelect}
                            ariaLabel={t(
                                `common:${localNamespace}.upload_new_image`,
                                'Upload New Image'
                            )}
                            isUploadImage
                            imageBeingUploaded={uploadedImage.loading !== undefined}
                            loadingText={loadingText?.toString()}
                        />
                    )}

                    {!searchText &&
                        initialImages.map(photoUrl => (
                            <ImagePickerImage
                                key={photoUrl}
                                imageUrl={photoUrl}
                                onClick={() => selectImageAndCloseModal(photoUrl)}
                                ariaLabel={t(
                                    `common:${localNamespace}.upload_new_image`,
                                    'Select Image'
                                )}
                                imageBeingUploaded={uploadedImage.loading !== undefined}
                                loadingText={loadingText?.toString()}
                            />
                        ))}

                    {unsplashResults &&
                        unsplashResults.map(unsplashImage => (
                            <ImagePickerImage
                                key={unsplashImage.url}
                                imageUrl={unsplashImage.thumbUrl}
                                onClick={() =>
                                    handleUnsplashImageSelect(
                                        unsplashImage.url,
                                        unsplashImage.downloadLocation
                                    )
                                }
                                ariaLabel={t(
                                    `common:${localNamespace}.upload_new_image`,
                                    'Select Image'
                                )}
                                imageBeingUploaded={uploadedImage.loading !== undefined}
                                attributionLink={unsplashImage.attributionLink}
                                attributionName={unsplashImage.attributionName}
                            />
                        ))}

                    <SearchLoader
                        areResults={searchText === '' || (unsplashResults?.length ?? 0) > 0}
                        loading={searchingUnsplash}
                        emptyResults={unsplashResults?.length === 0 && searchText !== ''}
                        emptyState={unsplashResults?.length === 0 && searchText !== ''}
                        customEmptyState={
                            <img src={ZeroState} alt="No Results" className="zero-state-img" />
                        }
                    />
                </ul>
            </section>
        </section>
    );
};

export default ImagePicker;
