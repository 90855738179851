import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer, Updater } from 'use-immer';
import { z } from 'zod';

import TierCardForm from '@components/content/newpost/forms/EventGroup/TierCardForm';

import { immerArraySlice } from '@helpers/state/state.helpers';

import { Sponsor, SponsorTier } from '@core/types/EventGroup';

type SponsorTierPanelProps = {
    state: Sponsor;
    setState: Updater<Sponsor>;
    handleSaveTiers: (tiers: SponsorTier[]) => void;
    tiers: SponsorTier[];
    setLocalTiers: Updater<SponsorTier[]>;
    setPage: React.Dispatch<string>;
};

const emptyTier = {
    name: '',
    order: 1,
};

const StateValidator = z.array(
    z.object({
        name: z.string().nonempty('Please enter a name').max(50),
        order: z.number(),
    })
);

const SponsorTierPanel: React.FC<SponsorTierPanelProps> = ({
    state: initialState,
    handleSaveTiers,
    tiers,
    setPage,
    setLocalTiers,
}) => {
    const { t } = useTranslation();

    const [state, setState] = useImmer(tiers?.length > 0 ? tiers : [emptyTier]);
    const [errors, setErrors] = useImmer<Record<string, string[]>>({});
    const [showErrors, setShowErrors] = useImmer(false);
    const [formValid, setFormValid] = useImmer(false);

    const handleSlice = immerArraySlice(setState);

    const handleAddNewTier = () => {
        setShowErrors(false);
        setState(draft => {
            draft.push({ name: '', order: state?.length + 1 });
        });
    };

    const handleGoBack = () => {
        setPage('main');
    };

    const handleDeleteTier = (index: number) => {
        setState(draft => {
            if (index > -1) draft.splice(index, 1);

            if (draft.length === 0) draft.push({ name: '', order: 1 });
        });
    };

    const validate = () => {
        const parsedData = StateValidator.safeParse(state);

        if (parsedData.success) {
            setErrors({});
            setShowErrors(false);
            setFormValid(true);
            return true;
        }

        if (parsedData.error) {
            setErrors(parsedData.error.flatten().fieldErrors);
            setFormValid(false);
        }

        return false;
    };

    const handleSave = () => {
        if (validate()) {
            setLocalTiers(state);
            handleSaveTiers(state);
            setPage('main');
        } else {
            setShowErrors(true);
        }
    };

    useEffect(() => {
        validate();
    }, [state]);

    const renderTierCards = state?.map((tier: SponsorTier, index: number) => {
        const errorClass = errors?.[index]?.[0] && showErrors ? 'err-border' : 'normal';
        return (
            <>
                {errors?.[index]?.[0] && showErrors && (
                    <p className="err-msg small" style={{ margin: '0px' }}>
                        {t(
                            'common:please_fill_out_the_required_fields',
                            'Please fill out the required fields.'
                        )}
                    </p>
                )}
                <TierCardForm
                    className={errorClass}
                    handleClick={handleDeleteTier}
                    index={index}
                    key={index}
                    tier={tier}
                    lastTier={state.at(index === state.length - 1 ? -2 : -1)}
                    setState={handleSlice(index)}
                    showDeleteButton={state.length !== 1}
                />
            </>
        );
    });

    const saveBtnClass = formValid ? 'active' : 'inactive';

    return (
        <>
            <header className="modal-form-btn-header event-form">
                <h1>{t('common:sponsor_tiers', 'Sponsor Tiers')}</h1>
                <p>{t('common:fields_marked_*_are_required', 'Fields marked * are required')}</p>
                <p className="small-600 event-form header-info">
                    {t(
                        'common:event-group-forms-skip-msg',
                        `Note: This can be skipped. After your event is published, choose the 'Edit Event' option to add or update information.`
                    )}
                </p>

                <div className="header-btns">
                    <button
                        onClick={handleGoBack}
                        type="button"
                        className="circle-icon-button medium light-grey border-grey-white-fill arrow-back"
                        aria-label="Back"
                    />

                    <button
                        type="button"
                        onClick={handleSave}
                        className={`rounded-pill-btn ${saveBtnClass}`}
                    >
                        {t('common:save', 'Save')}
                    </button>
                </div>
            </header>
            <article className="tp-body">
                {renderTierCards}

                <div className="tp-gen-add-container">
                    <button type="button">
                        <span>New Tier</span>
                        <div
                            onClick={handleAddNewTier}
                            className="circle-plus-icon-dark animate-grow"
                        >
                            <div />
                            <div />
                        </div>
                    </button>
                </div>
            </article>
        </>
    );
};

export default SponsorTierPanel;
