import { AvatarTypes, AvatarType } from '@components/profile/Lego/Avatars';

export const skinTones: string[] = [
    '#5E2702',
    '#C36A3B',
    '#D69569',
    '#F8BC93',
    '#4C9176',
    '#9E5CA0',
    '#009CDB',
    '#ffffff',
];
export const hairColors: string[] = [
    '#000000',
    '#ffffff',
    '#ffe300',
    '#f18e38',
    '#e73c4a',
    '#aa5939',
    '#4b9176',
    '#009cdb',
    '#9e5ca0',
];

type CheekColorType = {
    [key: string]: {
        cheekColor: string;
    };
};

const cheekColors: CheekColorType = {
    // natural shades
    '#5E2702': {
        // shade 1
        cheekColor: '#441B00',
    },
    '#C36A3B': {
        // shade 2
        cheekColor: '#A85D32',
    },
    '#D69569': {
        // shade 3
        cheekColor: '#C17D5B',
    },
    '#F8BC93': {
        // shade 4
        cheekColor: '#Fd8459',
    },
    // natural shades

    // colorful shades
    '#9E5CA0': {
        // purple
        cheekColor: '#8C2F91',
    },
    '#009CDB': {
        // blue
        cheekColor: '#404B74',
    },
    '#4C9176': {
        // green
        cheekColor: '#3F7963',
    },
    '#ffffff': {
        // white
        cheekColor: '#F9D1E4',
    },
    // colorful shades
};

export const getCheekColor = (skinTone: string = '') => {
    return cheekColors[skinTone]?.cheekColor;
};

export const getRandomAvatar = () => {
    const avatars = ['speedy', 'bat', 'wonder'];
    const randomAvatar = avatars[Math.floor(Math.random() * avatars.length)];
    return randomAvatar;
};

export const getAvatar = (avatarName?: string, randomize?: boolean) => {
    const randomAvatar = getRandomAvatar();
    if (randomize) return randomAvatar;
    if (avatarName === undefined || avatarName === null || avatarName === '') return randomAvatar;

    // this compensates for users who already stored the avatar known as 'default'
    // to now use avatar -> speedy as the 'default avatar' ... otherwise an error will be thrown
    // since default no longer exists as part of our avatars list
    if (avatarName === 'default') return 'speedy';
    if (avatarName !== 'default' && avatarName?.length > 0) return avatarName;

    return randomAvatar;
};

export const isSkinToneDisabled = (skinTone: string, currentNode = 0): boolean => {
    // Hack to have better unlocks for Season 2
    // TODO: Define unlockables on the quest map
    const skinTonesToDisable: string[] = skinTones.slice(
        currentNode === 0 ? 5 : 5 + currentNode + 1
    );

    if (skinTonesToDisable.includes(skinTone)) return true;

    return false;
};

export const isAvatarDisabled = (avatar: AvatarType, currentNode = 0): boolean => {
    // Hack to have better unlocks for Season 2
    // TODO: Define unlockables on the quest map
    const avatarsToDisabled: AvatarType[] = AvatarTypes.slice(
        currentNode === 0 ? 3 : currentNode * 13 + 1
    );

    if (avatarsToDisabled.includes(avatar)) return true;

    return false;
};
