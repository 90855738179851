import React from 'react';

type EyeSlashProps = {
    className?: string;
    size?: string;
    strokeWidth?: string;
    color?: string;
    version?: '1' | '2'; // version 2 is best for strokeWidth >= 2
};

const EyeSlash: React.FC<EyeSlashProps> = ({
    className = '',
    size = '16',
    strokeWidth = '1',
    color = 'white',
    version = '1',
}) => {
    if (version === '1') {
        return (
            <svg
                className={className}
                width={size}
                height={size}
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3 3.00006L13 14.0001"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                />
                <path
                    d="M9.6817 10.3499C9.19108 10.7959 8.5434 11.0287 7.88115 10.9971C7.21889 10.9655 6.5963 10.6722 6.15031 10.1816C5.70432 9.69103 5.47147 9.04338 5.50298 8.38112C5.53449 7.71886 5.82777 7.09624 6.31831 6.65021"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                />
                <path
                    d="M4.62465 4.78717C2.07657 6.07752 1 8.50007 1 8.50007C1 8.50007 3 12.9996 8 12.9996C9.1715 13.0089 10.3284 12.7391 11.3748 12.2124"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                />
                <path
                    d="M13.0381 11.0688C14.4007 9.84833 15 8.50007 15 8.50007C15 8.50007 13 3.99958 8.00001 3.99958C7.56696 3.99887 7.1346 4.03408 6.70737 4.10484"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                />
                <path
                    d="M8.47046 6.04431C9.00182 6.14634 9.48577 6.41791 9.84972 6.81827C10.2137 7.21863 10.438 7.72621 10.4891 8.26486"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                />
            </svg>
        );
    } else {
        return (
            <svg
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M4.5 3.75012L19.5 20.2501"
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.5223 14.7749C13.7864 15.4438 12.8149 15.793 11.8215 15.7457C10.8281 15.6983 9.89422 15.2583 9.22524 14.5225C8.55626 13.7866 8.20698 12.8151 8.25424 11.8217C8.3015 10.8283 8.74142 9.89439 9.47724 9.22534"
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.93698 6.43079C3.11486 8.36631 1.5 12.0001 1.5 12.0001C1.5 12.0001 4.5 18.7494 12 18.7494C13.7572 18.7634 15.4926 18.3587 17.0623 17.5687"
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.5586 15.8532C21.6025 14.0225 22.5015 12.0001 22.5015 12.0001C22.5015 12.0001 19.5015 5.24941 12.0015 5.24941C11.3519 5.24834 10.7033 5.30115 10.0625 5.40729"
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.707 8.31647C13.5041 8.46952 14.23 8.87686 14.7759 9.47741C15.3219 10.0779 15.6584 10.8393 15.735 11.6473"
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
};

export default EyeSlash;
