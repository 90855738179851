import { ContentCard, EventRsvp, Group } from '@shared/welibrary-graphql/types';
import { CurrentUser } from '@core/types/User';
import { MenuListItem } from '@core/types/Menus';
import { GROUP_ACTIONS } from '@core/utilities/constants/group';

const localNamespace = 'common:golbal.rsvp';

export const getEventListingRsvpStatus = (
    card: ContentCard,
    user: CurrentUser | null | undefined
): EventRsvp => {
    return (
        card?.eventAttendance?.find(rsvp => rsvp?.user?._id === user?._id)?.status ??
        EventRsvp.NoResponse
    );
};

export const getEventGroupRsvpStatus = (group: Group): EventRsvp => {
    if (group.currentUserIsMember) {
        return EventRsvp.Going;
    }
    if (group.currentUserIsInterested) {
        return EventRsvp.Interested;
    }
    if (group.currentUserIsNotGoing) {
        return EventRsvp.NotGoing;
    }

    return EventRsvp.NoResponse;
};

export const getRsvpDropdownOptions = (
    currentRsvpStatus: EventRsvp,
    onSelect: (selectedRsvp: EventRsvp) => void,
    t: Function
): MenuListItem[] => [
    {
        id: 1,
        title: t(`${localNamespace}.going`, 'Going'),
        onClick: () => onSelect(EventRsvp.Going),
        selected: [EventRsvp.Going, EventRsvp.YesAttending].includes(currentRsvpStatus),
    },
    {
        id: 2,
        title: t(`${localNamespace}.interested`, 'Interested'),
        onClick: () => onSelect(EventRsvp.Interested),
        selected: [EventRsvp.Interested, EventRsvp.MaybeAttending].includes(currentRsvpStatus),
    },
    {
        id: 3,
        title: t(`${localNamespace}.not_going`, 'Not Going'),
        onClick: () => onSelect(EventRsvp.NotGoing),
        selected: [EventRsvp.NotGoing, EventRsvp.NotAttending].includes(currentRsvpStatus),
    },
];

export const getRsvpDisplayWord = (rsvpStatus: EventRsvp, t: Function): string => {
    switch (rsvpStatus) {
        case EventRsvp.Going:
        case EventRsvp.YesAttending:
            return t(`${localNamespace}.going`, 'Going');
        case EventRsvp.Interested:
        case EventRsvp.MaybeAttending:
            return t(`${localNamespace}.interested`, 'Interested');
        case EventRsvp.NotGoing:
        case EventRsvp.NotAttending:
            return t(`${localNamespace}.not_going`, 'Not Going');
        case EventRsvp.NoResponse:
            return t(`${localNamespace}.no_response`, 'No Response');
        default:
            return t(`${localNamespace}.rsvp`, 'RSVP');
    }
    // YesAttending, MaybeAttending, and NotAttending are deprecated, but are included for backwards compatibility
};

export const isRsvpButtonCircleChecked = (rsvpStatus: EventRsvp): boolean => {
    return [
        EventRsvp.Going,
        EventRsvp.Interested,
        EventRsvp.YesAttending,
        EventRsvp.MaybeAttending,
    ].includes(rsvpStatus);
};

export const isRsvpButtonGrayscale = (rsvpStatus: EventRsvp): boolean => {
    return [EventRsvp.NotGoing, EventRsvp.NotAttending].includes(rsvpStatus);
};

export const convertRsvpStatusToGroupAction = (rsvpStatus: EventRsvp): string | null => {
    switch (rsvpStatus) {
        case EventRsvp.Going:
        case EventRsvp.YesAttending:
            return GROUP_ACTIONS.GROUP_JOIN;
        case EventRsvp.Interested:
        case EventRsvp.MaybeAttending:
            return GROUP_ACTIONS.GROUP_EXPRESS_INTEREST;
        case EventRsvp.NotGoing:
        case EventRsvp.NotAttending:
            return GROUP_ACTIONS.GROUP_RESPOND_NOT_GOING;
        case EventRsvp.NoResponse:
        default:
            return null;
    }
};

export const getFilteredGuestList = (guestList, rsvpStatus: EventRsvp) => {
    if (!guestList || guestList.length === 0) return [];
    return guestList?.filter(guest => guest?.status === rsvpStatus);
};

export const getFilteredGuestListCount = (guestList, rsvpStatus: EventRsvp) => {
    if (!guestList || guestList.length === 0) return 0;
    return guestList?.filter(guest => guest?.status === rsvpStatus).length;
};
