import { ChildPermissionsEnum } from '@pages/lego/AccountSwitcher/AccountManagementHelpers';
import { USER_TYPE_EPHEMERAL, USER_TYPE_CHILD } from '@core/utilities/constants/user';

import { PerformantMaybify } from '@core/types/Utilities';
import { User } from '@shared/welibrary-graphql/types';

export const isChild = (child?: PerformantMaybify<User> | null) => {
    return child?.type === USER_TYPE_CHILD;
};

export const isEphemeral = (child?: PerformantMaybify<User> | null) => {
    return child?.type === USER_TYPE_EPHEMERAL;
};

export const childHasPermission = (
    child?: PerformantMaybify<User> | null,
    permissionType?: ChildPermissionsEnum
) => {
    if (isChild(child)) {
        return child?.featureSets?.includes(permissionType);
    }

    return true;
};

export const canEditDisplayName = (child?: PerformantMaybify<User> | null) => {
    if (isEphemeral(child)) return false;

    return childHasPermission(child, ChildPermissionsEnum.DisplayName);
};

export const canUploadPhotos = (child?: PerformantMaybify<User> | null) => {
    if (isEphemeral(child)) return false;

    return childHasPermission(child, ChildPermissionsEnum.SharePhotos);
};

export { childHasPermission as default };
