import React from 'react';
import QRCode from 'qrcode.react';
import { getRootUrl } from '@core/utilities/whitelabel_helpers';
import { Group } from '@shared/welibrary-graphql/types';
import { useShareLink } from '@components/generic/hooks/useShareLink';
import { useTranslation } from 'react-i18next';

const EventShareModal: React.FC<{
    group: Group;
 }> = ({ group }) => {

    const root_url = getRootUrl();
    const eventURL = `https://${root_url}/g/${group._id}`;
    const { shareLink } = useShareLink();
    const { t } = useTranslation();
    const localNamespace = 'imports.wlWeb.ui.components.group.groupDashboardMenu';
    const atMobileBreakpoint = window?.innerWidth < 480;
    const eventGroupName = group?.eventOverviewDetails?.title;
    
    const handleShareClick = () => {
        const successText =
            group.subtype === 'event'
                ? t(
                      `common:${localNamespace}.event_link_copied_to_clipboard`,
                      'Event dashboard link copied to clipboard.'
                  )
                : t(
                      `common:${localNamespace}.group_link_copied_to_clipboard`,
                      'Group dashboard link copied to clipboard.'
                  );

        shareLink(eventURL, '', '', successText);
    };

    const downloadQrCode = () => {
        const canvas = document.getElementById("qrCode");
        const pngUrl = canvas.toDataURL();
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `QRCode - ${eventGroupName}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <section className="text-center">
            <h1 className="font-bold">Share Event</h1>
            <p>Users will be directed to the event dashboard upon scanning the QR code or clicking the link.</p>
            <QRCode className="mt-[20px]" size={220} value={eventURL} />
            <QRCode className="hidden" id="qrCode" size={atMobileBreakpoint ? 300 : 2000} value={eventURL} />
            <div className="flex flex-col mt-[20px]">
                <button className="h-[45px] bg-[#00D1A9] rounded-[40px] text-white font-semibold leading-[28px] mb-[20px]" onClick={downloadQrCode}>Download QR Code</button>
                <button className="h-[45px] bg-[#00D1A9] rounded-[40px] text-white font-semibold leading-[28px]" onClick={handleShareClick}>Copy Link</button>
            </div>
        </section>
    );
};

export default EventShareModal;