import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';

import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary.js';
import NewPostContainer from '@web/ui/components/content/newpost/NewPostContainer.js';

import {
    getSortTypeForChapter,
    getVisibilityRefSettingForPostOverlay,
} from '@helpers/aliases/alias.helpers';

const ActionArea = React.forwardRef(function ActionArea(
    {
        shelf,
        useWorkflow,
        book,
        chapter,
        isHidden,
        initOpenPostBox,
        isResource,
        className = '',
        isPlaceholder,
    },
    ref
) {
    const { t } = useTranslation();
      const { currentUser } = useCurrentUser();

    const defaultSortType = getSortTypeForChapter(chapter);

    const newPostPrompt = chapter?.prompt ?? 'Share Something';
    const displaySocialHeaders = chapter?.library_settings?.displaySocialHeaders;
    const chapterSettings = chapter?.library_settings ?? {};

    const visibilityRefSetting = getVisibilityRefSettingForPostOverlay(book, shelf, t);

    /**
     * Logic for showing vs not showing the chapter's postbox.
     * If the user is not logged in, show the postbox if a normal user would be allowed to post (so they can click it and be rerouted to signup)
     * If the user is logged in, show the postbox if the user is allowed to post based on their permissions
     * Show the action area (grey bar) if the postbox should display or if there is a chapter description.
     */
    const showPostboxIfNotLoggedIn = !currentUser && chapterSettings?.allowPostbox;
    const showPostBox = showPostboxIfNotLoggedIn || chapterSettings.userCanPost;
    const showActionArea = showPostBox || chapter?.description;

    if (!showActionArea) return <></>;

    const group = chapter?.reference?.groupSource;

    return (
        <section
            className={`chapter-body-header ${className} ${isHidden ? 'hidden' : ''}`}
            style={{ opacity: isPlaceholder ? 0 : 1 }}
            id="chapter-body-header"
            ref={ref}
        >
            {!isResource && <div role="presentation" className="flex-spacer" />}
            <article className="chapter-header-box">
                {chapter?.description && (
                    <header className="chapter-body-header-info">
                        <h4>{chapter?.title}</h4>
                        <p>{chapter?.description}</p>
                    </header>
                )}
                {showPostBox && (
                    <ErrorBoundary>
                        <NewPostContainer
                            visibilityRefSetting={visibilityRefSetting}
                            chapter={chapter}
                            group={group}
                            useWorkflow={useWorkflow}
                            feedUrl={chapter?.url}
                            chapterShowTitle={chapter?.showTitle}
                            prompt={newPostPrompt}
                            displaySocialHeaders={displaySocialHeaders}
                            allowedTypes={chapterSettings.postTypes}
                            initOpenModal={initOpenPostBox}
                            sortType={defaultSortType}
                        />
                    </ErrorBoundary>
                )}
            </article>
            {!isResource && <div role="presentation" className="flex-spacer-right" />}
        </section>
    );
});

export default ActionArea;
