import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { useLazyQuery } from '@apollo/client';
import { z } from 'zod';
import _ from 'lodash';

import useModal from '@components/modals/hooks/useModal';
import { useConvertCurrencyLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';

import TicketFormHeader from '@components/content/newpost/forms/ShoppingCartProducts/TicketFormHeaders';
import CouponForm from '@components/content/newpost/forms/ShoppingCartProducts/CouponForm';
import CouponItem from '@components/content/newpost/forms/ShoppingCartProducts/CouponItem';
import TextArea from '@components/generic/forms/TextArea';
import CurrencySelector from './CurrencySelector';

import { curriedStateSlice } from '@helpers/state/state.helpers';

import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';
import {
    Coupon,
    ProductState,
} from '@components/content/newpost/forms/ShoppingCartProducts/ticketHelpers';
import { Group, Product } from '@shared/welibrary-graphql/types';
import { getCurrencyDisplayString } from '~/wl-web/utilities/helpers/money/money.helpers';

const StateValidator = z.object({
    name: z.string().nonempty('Name is required!').max(50),
    description: z.string(),
    // price: z
    //     .number()
    //     .nonnegative('Please enter a valid price!')
    //     .min(1, 'Price must be atleast $1.00')
    //     .max(999999, 'Price cannot exceed $999,999.00'),
});

const TicketForm: React.FC<{
    index?: number;
    group?: Group;
    groupId: string;
    groupSubtype?: string;
    ticket?: Partial<Product>;
    addTicket: (newProduct: Product) => void;
    updateTicket: (newProduct: Partial<Product>, index: number, cb: any) => void;
    deleteTicket: (index: number) => void;
    inEditMode: boolean;
    headerTitle?: string;
    productNamePlaceholder?: string;
    ticketCurrency: string;
    setTicketCurrency: React.Dispatch<React.SetStateAction<string>>;
}> = ({
    index,
    group,
    groupId,
    groupSubtype,
    ticket,
    addTicket,
    updateTicket,
    deleteTicket,
    inEditMode = false,
    headerTitle = 'New Ticket',
    productNamePlaceholder = 'Ticket Name *',
    ticketCurrency,
    setTicketCurrency,
}) => {
    const { t } = useTranslation();

    const { closeModal } = useModal();

    const [inCreateCouponMode, setInCreateCouponMode] = useState<boolean>(false);
    const [couponToEdit, setCouponToEdit] = useState<Coupon | null>(null);
    const [couponToEditIndex, setCouponToEditIndex] = useState<number | null>(null);
    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const [priceError, setPriceError] = useState<string>('');
    const [state, setState] = useImmer<ProductState>({
        _id: ticket?._id ?? '',
        name: ticket?.name ?? '',
        description: ticket?.description ?? '',
        price: ticket?.price ?? null,
        currency: ticketCurrency ?? 'USD',
        type: ticket?.type ?? PRODUCT_TYPES.GROUP_MEMBERSHIP,
        fulfillment: { groupId, role: 'member' },
        coupons: ticket?.coupons ?? [],
    });

    const [convertCurrency] = useConvertCurrencyLazyQuery();

    const externalAccounts = group?.paymentSettings?.stripeAccount?.external_accounts?.data ?? [];
    const supportedCurrencies = externalAccounts?.map(ext_acct => ext_acct?.currency.toLowerCase());
    const showCurrencyDisclaimer = !supportedCurrencies.includes(state?.currency?.toLowerCase());

    const updateSlice = curriedStateSlice(setState);

    const validate = () => {
        const parsedData = StateValidator.safeParse({
            name: state.name,
            description: state.description,
            // price: Number(state.price),
        });

        if (parsedData.success) {
            setErrors({});
            return true;
        }

        if (parsedData.error) {
            setErrors(parsedData.error.flatten().fieldErrors);
        }

        return false;
    };

    const validatePrice = async () => {
        const minUSDPrice = 1;

        // convert the selected currency type into USD
        const { data: convertedCurrency } = await convertCurrency({
            variables: {
                to: 'USD',
                from: state?.currency,
                amount: state?.price?.toString(),
            },
            fetchPolicy: 'network-only',
        });

        // check if the entered price meets the min requirement of $1 USD
        // IE: 1 EUR >= 1 USD
        const isValidPrice = Number(convertedCurrency?.convertCurrency?.value) >= minUSDPrice;

        // return true if the price meets the min charge requirement
        if (isValidPrice) return true;

        // if the price is not valid return the min charge
        // in the form of the selected currency type
        const { data: minCurrencyCharge } = await convertCurrency({
            variables: {
                to: state?.currency,
                from: 'USD',
                amount: minUSDPrice.toString(),
            },
            fetchPolicy: 'network-only',
        });

        // format price
        const formattedMinCharge = getCurrencyDisplayString(
            Number(minCurrencyCharge?.convertCurrency?.value),
            state?.currency,
            undefined,
            true,
            2
        );

        setPriceError(formattedMinCharge); // display the min charge in an error
        return false;
    };

    const handleOnClick = async () => {
        if (validate() && (await validatePrice())) {
            if (inEditMode) {
                setTicketCurrency(state?.currency);
                updateTicket(state, index, closeModal);
            } else {
                addTicket(state);
                setTicketCurrency(state?.currency);
                closeModal();
            }
        }
    };

    const clearEditState = () => {
        setCouponToEdit(null);
        setCouponToEditIndex(null);
    };

    // * discount code handlers
    const addCoupon = (coupon: Coupon) => {
        const _coupons = [...state.coupons, { ...coupon, percentage: coupon?.percentage / 100 }];
        setState({ ...state, coupons: [..._coupons] });
        setInCreateCouponMode(false);
    };

    const updateCoupon = (coupon: Coupon, couponIndex: number) => {
        const _coupons = [...state.coupons];
        _coupons[couponIndex] = { ...coupon, percentage: coupon?.percentage / 100 };
        setState({ ...state, coupons: [..._coupons] });
        clearEditState();
    };

    const deleteCoupon = (couponIndex: number) => {
        const _coupons = [...state.coupons];
        _coupons.splice(couponIndex, 1);
        setState({ ...state, coupons: [..._coupons] });
        clearEditState();
    };

    const handleNewCoupon = () => setInCreateCouponMode(true);

    const handleEditCoupon = (couponIndex: number, coupon: Coupon) => {
        setCouponToEdit(coupon);
        setCouponToEditIndex(couponIndex);
    };

    const handleSetTicketCurrency = (currency: string) => {
        setState({ ...state, currency: currency });
    };

    let couponForm: React.ReactNode;

    if (inCreateCouponMode) {
        couponForm = (
            <CouponForm
                cancel={() => setInCreateCouponMode(false)}
                addCoupon={addCoupon}
                updateCoupon={updateCoupon}
                deleteCoupon={deleteCoupon}
                inEditMode={false}
            />
        );
    }

    if (!_.isNull(couponToEdit) && !_.isNull(couponToEditIndex)) {
        couponForm = (
            <CouponForm
                index={couponToEditIndex}
                coupon={couponToEdit}
                cancel={clearEditState}
                addCoupon={() => {}}
                updateCoupon={updateCoupon}
                deleteCoupon={deleteCoupon}
                inEditMode
            />
        );
    }

    const isEvent = groupSubtype === 'event';

    return (
        <section className="ticket-full-view">
            {couponForm && isEvent ? (
                <>{couponForm}</>
            ) : (
                <>
                    <TicketFormHeader
                        index={index}
                        closeModal={closeModal}
                        handleOnClick={async () => await handleOnClick()}
                        deleteTicket={deleteTicket}
                        inEditMode={inEditMode}
                        headerTitle={headerTitle}
                    />
                    <div className="ticket-full-view-form-container">
                        <div className="ticket-full-view-input-wrap">
                            <TextArea
                                className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px]"
                                countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[23px]"
                                wrapperClassName="relative w-full"
                                defaultClasses=""
                                defaultAutoResizeClasses=""
                                autoResize
                                name="name"
                                required
                                placeholder={productNamePlaceholder}
                                value={state.name}
                                onInput={updateSlice('name')}
                                helperText=""
                                maxCharacter={50}
                                autoComplete="off"
                            />
                            {errors.name && (
                                <p className="ticket-full-view-input-error-msg">{errors.name}</p>
                            )}
                        </div>
                        <div className="ticket-full-view-input-wrap">
                            <TextArea
                                className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px] !h-[130px] !overflow-auto"
                                countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[23px]"
                                wrapperClassName="relative w-full"
                                defaultClasses=""
                                defaultAutoResizeClasses=""
                                name="description"
                                placeholder="Description"
                                value={state.description}
                                onInput={updateSlice('description')}
                                helperText=""
                                autoResize
                                maxCharacter={255}
                                autoComplete="off"
                            />
                            {errors.description && (
                                <p className="ticket-full-view-input-error-msg">
                                    {errors.description}
                                </p>
                            )}
                        </div>
                        {/* <div className="w-full">
                            {priceError && (
                                <p className="ticket-full-view-input-error-msg">{priceError}</p>
                            )}
                        </div> */}
                        <div
                            className={`ticket-full-view-input-wrap ticket-full-view-price-input-container ${
                                !isEvent ? '!pb-[170px]' : ''
                            }`}
                        >
                            <div className="w-full flex items-center justify-between">
                                <div>
                                    <p className="p-0 m-0">{`${t('common:price', 'Price')} *`}</p>
                                    {showCurrencyDisclaimer && externalAccounts?.length > 0 && (
                                        <p className="p-0 m-0 text-xs text-gray-500 font-medium">
                                            A 1% currency conversion fee will be applied.
                                        </p>
                                    )}
                                </div>
                                {/* currency selector */}
                                <div className="flex items-center justify-center">
                                    <CurrencySelector
                                        currencyISO={state?.currency}
                                        setTicketCurrency={handleSetTicketCurrency}
                                    />
                                    <div className="ticket-full-view-price-input-wrap">
                                        <input
                                            name="price"
                                            type="number"
                                            placeholder="00.00"
                                            value={
                                                state.price
                                                    ? Number(state.price.toFixed(2))
                                                    : undefined
                                            }
                                            onChange={e => {
                                                updateSlice('price', Number(e.target.value));
                                                setPriceError('');
                                            }}
                                            className={`ticket-full-view-input ${
                                                priceError
                                                    ? 'ticket-full-view-input-err text-red-400'
                                                    : ''
                                            }`}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>

                            {priceError && (
                                <div className="w-full flex items-center justify-center">
                                    <div className="w-full py-[12px]">
                                        <p
                                            className={`w-full py-[12px] px-[16px] bg-[#f6f7f9] rounded-[12px] p-0 m-0 font-bold border-red-400 border-[1px] border-solid text-red-400`}
                                        >
                                            Please enter an amount over {priceError}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>

                        {isEvent && (
                            <>
                                <div className="ticket-form-divider" />
                                <div className="ticket-full-view-sub-text-wrap">
                                    <h4>{t('common:discount_codes', 'Discount Codes')}</h4>
                                </div>

                                <button
                                    type="button"
                                    onClick={handleNewCoupon}
                                    className="ticket-new-discount-code-button"
                                >
                                    <h6>
                                        {t('common:add_new_discount_code', 'Add New Discount Code')}
                                    </h6>
                                    <span>+</span>
                                </button>

                                <ul className="discount-code-item-list">
                                    {state.coupons.length > 0
                                        ? state.coupons.map((coupon, couponIndex) => (
                                              // eslint-disable-next-line react/jsx-indent
                                              <CouponItem
                                                  key={couponIndex}
                                                  index={couponIndex}
                                                  coupon={coupon}
                                                  handleEditCoupon={handleEditCoupon}
                                                  deleteCoupon={deleteCoupon}
                                              />
                                          ))
                                        : null}
                                </ul>
                            </>
                        )}
                    </div>
                </>
            )}
        </section>
    );
};

export default TicketForm;
