import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import EditScreenItemContainer from '@web/ui/components/generic/editscreen/EditScreenItemContainer';
import EditScreenTagSelect from '@web/ui/components/generic/editscreen/EditScreenTagSelect';
import TextareaAutosize from 'react-textarea-autosize';
import ThumbnailAndCover from '@web/ui/components/generic/editscreen/ThumbnailAndCover';

import {
    DISP_REF_GROUP,
    DISP_REF_BOOK,
    DISP_REF_CHAPTER,
    DISP_REF_PROMPT,
} from '@core/utilities/constants/library_settings';

import { generateReferenceTypeLabelForGroup } from '@web/utilities/helpers/reference/reference.helpers';

const darkFormNamespace = 'imports.wlWeb.ui.components.card.darkForms';
const localNamespace = 'imports.wlWeb.ui.components.card.darkForms.shelfInfo';

const ShelfInfo = props => {
    const { t } = useTranslation();

    const { infoConstraints, state, handleUpdateState, handleSubmit, card } = props;

    const {
        bodyConstraints,
        mediaThumbConstraints,
        mediaIconConstraints,
        descriptionConstraints,
        displayReferenceTypesConstraint,
    } = infoConstraints;

    const groupDisplayReferenceLabel = generateReferenceTypeLabelForGroup(card?.group?.subtype);

    const defaultDisplayReferenceTypes = [
        {
            text: t(groupDisplayReferenceLabel),
            value: DISP_REF_GROUP,
        },
        {
            text: t(),
            value: DISP_REF_BOOK,
        },
        {
            text: t(`common:global.constants.cards.types.channel`),
            value: DISP_REF_CHAPTER,
        },
        { text: t(`common:${localNamespace}.ref_types.prompt`), value: DISP_REF_PROMPT },
    ];

    const displayReferenceTypes =
        displayReferenceTypesConstraint && displayReferenceTypesConstraint.options
            ? _.filter(defaultDisplayReferenceTypes, option =>
                  displayReferenceTypesConstraint.options.includes(option.value)
              )
            : defaultDisplayReferenceTypes;

    return (
        <div
            className="edit-screen-tab-container w-tab-pane w--tab-active"
            style={{
                opacity: 1,
                transition: 'opacity 300ms ease 0s',
                paddingBottom: '25px',
            }}
        >
            {bodyConstraints && (
                <EditScreenItemContainer
                    title={t(`common:global.nouns.headline`)}
                    extraBottomPadding
                >
                    <div className="mc-source-text-container">
                        <div className="edit-post-text-container textarea-autosize small w-form">
                            {/* <div className="edit-item-label header">Title<br/></div> */}
                            <TextareaAutosize
                                autoComplete="off"
                                id="body"
                                name="body"
                                onChange={e => props.handleChange(e)}
                                value={state.body}
                                className="new-post-field default w-input"
                                maxLength={30}
                                style={{
                                    resize: 'none',
                                    paddingTop: '7px',
                                    overflow: 'hidden',
                                }}
                                placeholder={t(`common:${localNamespace}.more_details`)}
                            />
                        </div>
                    </div>
                </EditScreenItemContainer>
            )}
            {(mediaThumbConstraints || mediaIconConstraints) && (
                <EditScreenItemContainer
                    title={t(`common:${darkFormNamespace}.thumbnail_and_cover`)}
                >
                    <ThumbnailAndCover
                        coverImage={state.mediaThumb}
                        thumbImage={state.mediaIcon}
                        coverTarget={mediaThumbConstraints ? 'mediaThumb' : ''}
                        thumbTarget={mediaIconConstraints ? 'mediaIcon' : ''}
                        title={state.title}
                        handleUpdateState={handleUpdateState}
                        coverStyle={{ height: '100%' }}
                        thumbStyle={null}
                    />
                </EditScreenItemContainer>
            )}
            {descriptionConstraints && (
                <EditScreenItemContainer
                    title={t(`common:${darkFormNamespace}.description`)}
                    extraBottomPadding
                >
                    <div className="mc-source-text-container">
                        <div className="edit-post-text-container textarea-autosize small w-form">
                            {/* <div className="edit-item-label header">Title<br/></div> */}
                            <TextareaAutosize
                                autoComplete="off"
                                id="description"
                                name="description"
                                onChange={e => props.handleChange(e)}
                                value={state.description}
                                className="new-post-field default w-input"
                                maxLength={470}
                                style={{
                                    resize: 'none',
                                    paddingTop: '7px',
                                    overflow: 'hidden',
                                }}
                                placeholder={t(`common:${localNamespace}.short_summary`)}
                            />
                        </div>
                    </div>
                </EditScreenItemContainer>
            )}
            {displayReferenceTypesConstraint && (
                <EditScreenItemContainer
                    title={t(`common:${localNamespace}.default_ref_header`)}
                    subtitle={t(`common:${localNamespace}.select_what_displays`)}
                    columnItem
                    columnsBlock
                    showToggleContainer
                    renderToggleContainer={
                        <EditScreenTagSelect
                            target="displayReference"
                            options={displayReferenceTypes}
                            selected={props.state.displayReference}
                            keepSelectedInOptions
                            handleSetList={(name, values) => {
                                handleUpdateState({ displayReference: values });
                            }}
                            handleRemoveFromList={props.handleRemoveFromList}
                        />
                    }
                />
            )}
            <div className="edit-footer">
                <div
                    onClick={handleSubmit}
                    className="button callout-button w-button edit-screen-content"
                >
                    {t(`common:global.verbs.save`)}
                </div>
            </div>
        </div>
    );
};

export default ShelfInfo;
