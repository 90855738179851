import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import OutsideClickHandler from 'react-outside-click-handler';

import AddRemoveTag, { CustomAddRemoveTag } from '@pages/new-signup/AddRemoveTag';
import CaretDownIcon from '@dsc/svgs/CaretDownIcon';

const localNamespace = 'imports.wlWeb.ui.custom-input.multi-select';

type MultiSelectInputProps = {
    selectedValues: string[];
    options: string[];
    onOptionClick: (clickedOption: string, isAdd: boolean) => void;
    isSelectOne: boolean;
    allowOther?: boolean;
};

const setSameWidthFn = ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
};
const setSameWidthEffect = ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
};

const setBorderFn = ({ state }) => {
    if (state.placement === 'top') {
        // round top, no border on bottom
        state.elements.popper.style.borderTopLeftRadius = '15px';
        state.elements.popper.style.borderTopRightRadius = '15px';
        state.elements.popper.style.borderBottom = '0px';

        // no rounded top for anchor button
        state.elements.reference.style.borderTopLeftRadius = '0px';
        state.elements.reference.style.borderTopRightRadius = '0px';
    } else {
        // round bottom, no border on top
        state.elements.popper.style.borderBottomLeftRadius = '15px';
        state.elements.popper.style.borderBottomRightRadius = '15px';
        state.elements.popper.style.borderTop = '0px';

        // no rounded bottom for anchor button
        state.elements.reference.style.borderBottomLeftRadius = '0px';
        state.elements.reference.style.borderBottomRightRadius = '0px';
    }
};

const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
    selectedValues,
    options,
    onOptionClick,
    isSelectOne = false,
    allowOther = false,
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [customOptions, setCustomOptions] = useState<string[]>([]);

    const referenceRef = useRef(null);

    // This ref needs to be stored in a useState hook to cause a rerender when the menu is
    // shown. This rerender allows popper to correctly style the position of the menu.
    // If a useRef hook is used instead, the menu will appear in the upper left corner on
    // the first click, then correctly every time after that.
    const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);

    const { styles, attributes } = usePopper(referenceRef.current, popperRef, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'eventListeners',
                options: {
                    scroll: true,
                    resize: true,
                },
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['top'],
                },
            },
            {
                name: 'sameWidth',
                enabled: true,
                phase: 'beforeWrite',
                requires: ['computeStyles'],
                fn: setSameWidthFn,
                effect: setSameWidthEffect,
            },
            {
                name: 'border',
                enabled: true,
                phase: 'beforeWrite',
                requires: ['flip'],
                fn: setBorderFn,
            },
        ],
    });

    useEffect(() => {
        // this is to reset the button to have all rounded corners after setBorderFn un-rounded the top or bottom
        if (!isOpen) {
            referenceRef.current.style = { borderRadius: '15px' };
        }
    }, [isOpen]);

    const activeClassNames = 'border-black';

    let buttonText = t(`common:${localNamespace}.select`, 'Select');
    const numSelected = selectedValues.length;
    if (numSelected === 1) {
        if (isSelectOne) {
            buttonText = selectedValues[0];
        } else {
            buttonText = `1 ${t(`common:${localNamespace}.itemSelected`, 'Item Selected')}`;
        }
    } else if (numSelected > 1) {
        buttonText = `${numSelected} ${t(
            `common:${localNamespace}.itemsSelected`,
            'Items Selected'
        )}`;
    }

    return (
        <div className="w-full">
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                <button
                    type="button"
                    className={`px-[17px] py-[20px] w-full bg-grayscale-input-background-no-transparency flex items-center rounded-[15px] border-solid border-[1px] ${isOpen && activeClassNames
                        }`}
                    ref={referenceRef}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className="grow text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] text-start">
                        {buttonText}
                    </span>
                    <CaretDownIcon />
                </button>

                {isOpen && (
                    <div
                        className="flex gap-[20px] flex-wrap z-[100000] px-[17px] py-[20px] bg-grayscale-input-background-no-transparency border-solid border-black border-[1px] max-h-[45%] overflow-scroll"
                        ref={setPopperRef}
                        style={{ ...styles.popper }}
                        {...attributes.popper} // eslint-disable-line react/jsx-props-no-spreading
                    >
                        {[...options, ...customOptions].map((option, index) => {
                            const isSelected = selectedValues.includes(option);
                            return (
                                <AddRemoveTag
                                    key={index}
                                    text={option}
                                    isSelected={isSelected}
                                    onClick={() => {
                                        onOptionClick(option, !isSelected);
                                        if (isSelectOne) {
                                            setIsOpen(false);
                                        }
                                    }}
                                    size="medium"
                                    showIcon={!isSelectOne}
                                />
                            );
                        })}
                        {allowOther && (
                            <CustomAddRemoveTag
                                isSelected={false}
                                size="medium"
                                showIcon
                                addOption={newOption => {
                                    setCustomOptions([...customOptions, newOption]);
                                    onOptionClick(newOption, true);
                                    if (isSelectOne) {
                                        setIsOpen(false);
                                    }
                                }}
                            />
                        )}
                    </div>
                )}
            </OutsideClickHandler>
        </div>
    );
};

export default MultiSelectInput;
