/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import { Link } from 'react-router-dom';

import {
    ALIAS_EXTERNAL_LINK,
    ALIAS_GENERATOR,
    ALIAS_GROUP,
} from '@core/utilities/constants/card_types.js';

import { ctaURItoData } from '@core/utilities/constants/notifications';
import { SidebarActionsContext } from '@web/ui/components/generic/context/SidebarContext';
import { TYPE_FORM } from '@web/ui/components/sidebar/sidebar_types.js';
import ExternalLink from '@web/ui/components/generic/ExternalLink';
import PRE_SELECTED_ICONS from '@core/utilities/constants/pre_selected_tile_icons';

const localNamespace = 'imports.wlWeb.ui.components.card.mediaCard.bodyItems.tileItem';

const TileItem = props => {
    const { card } = props;
    const aliases = card.aliases ? card.aliases : [];
    const isStacked = aliases.length > 1;
    const showArrow = !isStacked;
    const displayStyle = card?.library_settings?.defaultViewType || 'fullview';
    const fallbackIcon = PRE_SELECTED_ICONS.find(icon => icon.name === 'link').value;

    const { showSidebar } = useContext(SidebarActionsContext);

    const onLaunchGeneratorClick = url => {
        const options = ctaURItoData(url) || { data: { initialGeneratorAnswers: {} } };
        if (!options.data) options.data = {};
        if (!options.data.initialGeneratorAnswers) options.data.initialGeneratorAnswers = {};

        // Maybe we should have a more direct mapping here..
        options.form = options.keyword || 'creategroup';

        // Set the parentId of the generator to the group where this tile card is located
        if (!options.data.initialGeneratorAnswers.parentId)
            options.data.initialGeneratorAnswers.parentId = card?.reference?.groupSource?._id;
        showSidebar(TYPE_FORM, null, null, 'full', options);
    };

    const tiles = [];
    _.each(aliases, alias => {
        if (alias) {
            let url;
            switch (alias.type) {
                case ALIAS_GENERATOR:
                case ALIAS_EXTERNAL_LINK:
                    url = alias.url;
                    break;
                default:
                    url = alias.item ? alias.item.reference.href : '#';
                    break;
            }

            let title = alias.title ? alias.title : '';
            if (alias.item && !title) title = alias.item.title ? alias.item.title : title;

            let mediaThumb;
            if (alias.item) {
                if (alias.item.reference) {
                    mediaThumb = alias.item.reference.coverImage
                        ? alias.item.reference.coverImage
                        : mediaThumb;
                } else if (alias.item.media) {
                    mediaThumb = alias.item.media.thumb ? alias.item.media.thumb : mediaThumb;
                }

                // Hide archived items
                if (alias.item.isArchived) {
                    return;
                }
            }

            let colorBackground;
            let backgroundStyle = {
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
            };

            if (alias?.color?.includes('http')) {
                mediaThumb = alias.color;
            } else if (alias.color && alias.color[0] === '#') {
                backgroundStyle = {
                    backgroundColor: alias.color,
                    backgroundImage: 'unset',
                    opacity: 0.9,
                };
                colorBackground = <div className="tile-link-block-bg" style={backgroundStyle} />;
            }

            if (alias.thumb) mediaThumb = alias.thumb;

            if (alias.type === ALIAS_GROUP) {
                mediaThumb =
                    alias.thumb ||
                    alias?.group?.profile?.coverPhoto ||
                    alias?.group?.profile?.picture;
            }

            const [src, srcSet] = getBackgroundImageSetForUrl(
                mediaThumb || getRandomDefaultCoverImage(title)
            );

            const activeLinkBlockProps = {
                src,
                srcSet,
                backgroundStyle,
                showArrow,
                title,
                alias,
                colorBackground,
            };

            const showFullWidth = aliases.length <= 5;

            switch (alias.type) {
                case ALIAS_GROUP:
                    if (displayStyle === 'listview') {
                        tiles.push(
                            <Link
                                key={alias?.url}
                                to={`/g/${alias.url}`}
                                className={`linkTile-list-view-item ${
                                    showFullWidth
                                        ? 'linkTile-list-view-item-full'
                                        : 'linkTile-list-view-item-column'
                                }`}
                            >
                                <img
                                    className="linkTile-list-view-item-icon"
                                    src={alias.icon || fallbackIcon}
                                    alt=""
                                />
                                <p className="linkTile-list-view-item-link">{alias.title}</p>
                                <div className="linkTile-list-view-item-arrow" />
                            </Link>
                        );
                    } else {
                        tiles.push(
                            <div key={alias?.url} className="tile-block-wrapper">
                                <div className="tile-block-container">
                                    <Link
                                        to={`/g/${alias.url}`}
                                        className="tile-link-block activelink w-inline-block"
                                    >
                                        <ActiveLinkBlock {...activeLinkBlockProps} />
                                    </Link>
                                </div>
                            </div>
                        );
                    }

                    break;
                case ALIAS_EXTERNAL_LINK:
                    if (displayStyle === 'listview') {
                        tiles.push(
                            <ExternalLink
                                key={alias?.url}
                                to={alias.url}
                                className={`linkTile-list-view-item ${
                                    showFullWidth
                                        ? 'linkTile-list-view-item-full'
                                        : 'linkTile-list-view-item-column'
                                }`}
                            >
                                <img
                                    className="linkTile-list-view-item-icon"
                                    src={alias.icon || fallbackIcon}
                                    alt=""
                                />
                                <p className="linkTile-list-view-item-link">{alias.title}</p>
                                <div className="linkTile-list-view-item-arrow" />
                            </ExternalLink>
                        );
                    } else {
                        tiles.push(
                            <div key={alias?.url} className="tile-block-wrapper">
                                <div className="tile-block-container">
                                    <ExternalLink
                                        to={url}
                                        className="tile-link-block activelink w-inline-block"
                                    >
                                        <ActiveLinkBlock {...activeLinkBlockProps} />
                                    </ExternalLink>
                                </div>
                            </div>
                        );
                    }
                    break;
                case ALIAS_GENERATOR:
                    if (displayStyle === 'listview') {
                        tiles.push(
                            <Link
                                key={alias?.url}
                                to="#"
                                onClick={() => onLaunchGeneratorClick(url)}
                                className={`linkTile-list-view-item ${
                                    showFullWidth
                                        ? 'linkTile-list-view-item-full'
                                        : 'linkTile-list-view-item-column'
                                }`}
                            >
                                <img
                                    className="linkTile-list-view-item-icon"
                                    src={alias.icon || fallbackIcon}
                                    alt=""
                                />
                                <p className="linkTile-list-view-item-link">{alias.title}</p>
                                <div className="linkTile-list-view-item-arrow" />
                            </Link>
                        );
                    } else {
                        tiles.push(
                            <div key={alias?.url} className="tile-block-wrapper">
                                <div className="tile-block-container">
                                    <Link
                                        to="#"
                                        onClick={() => onLaunchGeneratorClick(url)}
                                        className="tile-link-block activelink w-inline-block"
                                    >
                                        <ActiveLinkBlock {...activeLinkBlockProps} />
                                    </Link>
                                </div>
                            </div>
                        );
                    }
                    break;
                default:
                    if (displayStyle === 'listview') {
                        tiles.push(
                            <Link
                                key={alias?.url}
                                to={url}
                                className={`linkTile-list-view-item ${
                                    showFullWidth
                                        ? 'linkTile-list-view-item-full'
                                        : 'linkTile-list-view-item-column'
                                }`}
                            >
                                <img
                                    className="linkTile-list-view-item-icon"
                                    src={alias.icon || fallbackIcon}
                                    alt=""
                                />
                                <p className="linkTile-list-view-item-link">{alias.title}</p>
                                <div className="linkTile-list-view-item-arrow" />
                            </Link>
                        );
                    } else {
                        tiles.push(
                            <div key={alias?.url} className="tile-block-wrapper">
                                <div className="tile-block-container">
                                    <Link
                                        to={url}
                                        className="tile-link-block activelink w-inline-block"
                                    >
                                        <ActiveLinkBlock {...activeLinkBlockProps} />
                                    </Link>
                                </div>
                            </div>
                        );
                    }
                    break;
            }
        }
    });

    if (displayStyle === 'listview') {
        return (
            <>
                {card?.title?.length > 0 && (
                    <div className="linkTile-list-title-wrap">
                        <h4 className="linkTile-list-title">{card.title}</h4>
                    </div>
                )}
                <ul
                    className={`linkTile-list-view ${
                        !card.title && 'linkTile-list-view-marginTop'
                    }`}
                >
                    {tiles}
                </ul>
            </>
        );
    }

    return <div className="tile-post-wrapper">{tiles}</div>;
};

export default TileItem;

const ActiveLinkBlock = ({
    src,
    srcSet,
    backgroundStyle,
    showArrow,
    title,
    alias,
    colorBackground,
}) => {
    const { t } = useTranslation();
    const aliasBodyPath = `common:${localNamespace}.alias_body`;

    return (
        <>
            {colorBackground || (
                <StyledBackgroundImage
                    className="tile-link-block-bg"
                    src={src}
                    srcSet={srcSet}
                    style={backgroundStyle}
                />
            )}
            <div className="tile-link-block-content activelinkblock">
                <div className="tile-link-block-text">
                    <div className="tile-link-block-tile">
                        {t(`common:${localNamespace}.title`, { title })}
                    </div>
                    <div>
                        {alias.item ? t(aliasBodyPath, { aliasBody: alias.item.body }) : null}
                    </div>
                </div>
                {showArrow && <div className="read-more-arrow" />}
            </div>
        </>
    );
};
