import React from 'react';
import { useTranslation } from 'react-i18next';

import useUploadPhoto from '@web/ui/components/generic/hooks/useUploadPhoto';

import Picture from '@web/ui/components/generic/Picture';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-spinner.json';

import Pencil from '@web/ui/dsc/svgs/Pencil';

import getLoadingText from '@helpers/imageUpload/imageUpload.helpers';
import { ImageWithLoadingState, SetState } from '@core/types/Utilities';

type ImageUploadProps = {
    state: ImageWithLoadingState;
    setState: SetState<ImageWithLoadingState>;
    className?: string;
    loaderSize?: number;
    lottieData?: any;
    small?: boolean;
};

const ImageUpload: React.FC<ImageUploadProps> = ({
    state,
    setState,
    className = '',
    loaderSize = 175,
    lottieData = LottieAnimation,
    small = false,
}) => {
    const { t } = useTranslation();

    const handleFileSelect = useUploadPhoto(setState);

    const loadingText = getLoadingText(state, t, small);

    return (
        <section className={`image-upload ${className}`}>
            {state.loading && (
                <section>
                    <LottieLoading
                        customStyle={{ backgroundColor: '#00000099', borderRadius: '50%' }}
                        height={loaderSize}
                        width={loaderSize}
                        lottieData={lottieData}
                        isPaused={false}
                    />
                    <span>{loadingText}</span>
                </section>
            )}
            <Picture url={state.image} disableLink>
                {!state.loading && (
                    <button type="button" onClick={handleFileSelect} aria-label="Upload New Image">
                        <Pencil />
                    </button>
                )}
            </Picture>
        </section>
    );
};

export default ImageUpload;
