import React from 'react';
import { useTranslation } from 'react-i18next';
import { Updater } from 'use-immer';

import useConfirmation from '@components/generic/hooks/useConfirmation';

import TextArea from '@components/generic/forms/TextArea';

import { curriedStateSlice } from '@helpers/state/state.helpers';

import { SponsorTier } from '@core/types/EventGroup';

type TierCardFormProps = {
    tier: SponsorTier;
    lastTier?: SponsorTier;
    setState: Updater<SponsorTier>;
    className?: string;
    index: number;
    handleClick: (index: number) => void;
    showDeleteButton: boolean;
};

const TierCardForm: React.FC<TierCardFormProps> = ({
    tier,
    lastTier,
    setState,
    className,
    index,
    handleClick,
    showDeleteButton,
}) => {
    const { t } = useTranslation();

    const confirm = useConfirmation({ widen: true });

    const updateSlice = curriedStateSlice(setState);

    const onClick = () => {
        const confirmationMessage = `Deleting this sponsor tier will move all existing sponsors within this tier to ${
            lastTier?.name || 'the lowest tier'
        }.`;

        confirm({
            text: confirmationMessage,
            onConfirm: () => handleClick(index),
        });
    };

    return (
        <div className={className ? `new-tier-card ${className}` : 'new-tier-card'}>
            <div className="tier-settings">
                <label className="order-label">
                    {`${t('common:order', 'Order')}:`}
                    <input
                        onChange={e => updateSlice('order', parseInt(e.target.value))}
                        value={tier.order}
                        type="number"
                        id="order"
                        name="order"
                        min="1"
                        max="99"
                    />
                </label>
                {showDeleteButton && (
                    <button
                        type="button"
                        onClick={onClick}
                        className="circle-icon-button light-grey trash2"
                        aria-label="Delete"
                    />
                )}
            </div>
            <TextArea
                className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px] !overflow-auto"
                countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[12px]"
                wrapperClassName="relative w-full"
                defaultClasses=""
                defaultAutoResizeClasses=""
                autoResize
                required
                value={tier.name}
                onInput={updateSlice('name')}
                placeholder="Tier Name *"
                maxCharacter={255}
                helperText=''
            />
        </div>
    );
};

export default TierCardForm;
