/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Plus from '@web/ui/dsc/svgs/FullSizePlus';

import { FilestackImage } from '@core/types/Utilities';
import { AliasItem } from '@shared/welibrary-graphql/types';
import { CARD_IMAGE } from '@core/utilities/constants/card_types.js';

type ImageGridProps = {
    aliases?: AliasItem[] | null; // stored images data
    images: FilestackImage[]; // most recent uploaded images
    imagesSelected: FilestackImage[] | AliasItem[]; // images selected by the user new/existing
    handleImgToggle: (
        img?: FilestackImage | AliasItem,
        imgUploads?: FilestackImage[] | AliasItem[]
    ) => void; // toggles images selected on/off
    handleOpeningPicker: () => void; // opens file picker
    rerender?: boolean; // should apply useEffect re-render
    mode?: string; // edit | create
    type: string; // card type
};

const ImageGrid: React.FC<ImageGridProps> = ({
    aliases,
    images,
    imagesSelected,
    handleImgToggle,
    handleOpeningPicker,
    mode = 'create',
    rerender = true,
    type,
}) => {
    const [gridImages, setGridImages] = useState<FilestackImage[] | AliasItem[]>(
        imagesSelected ?? []
    ); // set grid images (imagesSelected -> aliases) { mode: 'edit' }

    useEffect(() => {
        // initializes image grid
        if (gridImages.length === 0) {
            setGridImages(images);
            handleImgToggle(undefined, images); // pre-select all images uploaded
        }

        // updates image grid if atleast one image already exists
        if (gridImages.length >= 1) {
            const _gridImages = _.clone(gridImages);

            if (mode === 'create') {
                setGridImages([..._gridImages, ...images]);
                handleImgToggle(undefined, images); // pre-select all images uploaded
            }

            if (mode === 'edit') {
                const transformedAliases = images.map(img => {
                    return {
                        thumb: '',
                        type: type === CARD_IMAGE ? 'alias_image' : 'alias_square_image',
                        url: img.url,
                    };
                });
                setGridImages([..._gridImages, ...transformedAliases]); // update grid with incoming images
                if (transformedAliases.length > 0) handleImgToggle(undefined, transformedAliases); // pre-select all images uploaded
            }

            if (aliases?.length === 0 && mode === 'edit') {
                handleImgToggle(imagesSelected[0]); // set transformed mediaThumb as the default image when no aliases exists
            }
        }
    }, [rerender ? images : null]);

    return (
        <div className="imageGrid">
            <div className="imageGrid-wrap">
                {gridImages.map(image => {
                    const selected = imagesSelected.includes(image);
                    let placeholderIndex;

                    if (selected) {
                        placeholderIndex = imagesSelected.findIndex(i => i.url === image.url) + 1;
                    }

                    return (
                        <div key={image.url} className="imageGrid-img-wrap">
                            <img
                                className="imageGrid-img"
                                onClick={() => handleImgToggle(image)}
                                src={image.url}
                                alt=""
                            />
                            <p
                                className={
                                    selected ? 'imageGrid-img-badge-active' : 'imageGrid-img-badge'
                                }
                            >
                                {placeholderIndex}
                            </p>
                        </div>
                    );
                })}
                {gridImages.length >= 1 && (
                    <div onClick={handleOpeningPicker} className="imageGrid-upload-more">
                        <div className="imageGrid-upload-more-overlay">
                            <Plus className="imageGrid-upload-more-icon" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageGrid;
