import React from 'react';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { Updater } from 'use-immer';

import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';

import EditScreenItemContainer from '@components/generic/editscreen/EditScreenItemContainer';

import { Product, Group } from '@shared/welibrary-graphql/types';
import { UserDetailsState } from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';
import { curriedStateSlice } from '@helpers/state/state.helpers';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';
import { getAuthToken } from '@web/utilities/helpers/auth';
import { CARD_EVENT } from '~/wl-core/utilities/constants/card_types';

import Countries from '@components/generic/forms/countries.json';

enum LoginMode {
    email = 'email',
    phone = 'phone',
}

const LoginRedirect: React.FC<{ handleLoginRedirect: () => void }> = ({ handleLoginRedirect }) => {
    const { theme } = getWhitelabelPublicSettings();

    return (
        <span
            onClick={handleLoginRedirect}
            style={{
                color: theme?.primaryColor || '#0094f6',
                borderColor: theme?.primaryColor || '#0094f6',
            }}
        >
            Login to Continue
        </span>
    );
};

const CustomerDetailsForm: React.FC<{
    state: UserDetailsState;
    setState: Updater<UserDetailsState>;
    group: Group;
    cartItem: Product;
    errors: Record<string, string[]>;
    createOrderError: any | undefined;
    emailChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    quantity: number;
}> = ({
    state,
    setState,
    group,
    cartItem,
    errors,
    emailChangeHandler,
    createOrderError,
    quantity,
}) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();
    const { closeModal } = useModal();

    const isEvent = group?.subtype === CARD_EVENT;

    const history = useHistory();

    const isUserLoggedIn = getAuthToken();

    const updateSlice = curriedStateSlice(setState);

    // logic to differentiate if an error is related
    // to an email, phone or general error message

    const orderEmailError = createOrderError?.message
        .toLowerCase()
        .includes('email already exists');

    const orderPhoneError = createOrderError?.message
        .toLowerCase()
        .includes('phone already exists');

    const orderTempPhoneError = createOrderError?.message
        .toLowerCase()
        .includes('phone number is not tied to the email entered');

    const orderErrorMessage =
        orderEmailError || orderPhoneError || orderTempPhoneError ? '' : createOrderError?.message;

    const handleRedirectTo = (loginMode: LoginMode) => {
        history.push(
            `/login?mode=${loginMode}&returnTo=/g/${group?._id}/tickets/${cartItem?._id}/${quantity}`
        );
        closeModal();
    };

    const customStyles: any = { width: '100%' };
    if (!isEvent) customStyles.marginTop = '20px';

    return (
        <EditScreenItemContainer
            title={isEvent ? '' : 'Customer Details'}
            overrideContainerStyle={customStyles}
        >
            <div className="checkout-error-message-wrap">
                <p className="checkout-error-message">{orderErrorMessage}</p>
            </div>
            <div className="customer-payments-container">
                <div className="stripe-checkout-form">
                    <div className="stripe-checkout-form-element-wrap">
                        <input
                            className={`stripe-checkout-form-element stripe-checkout-customer-form-element ${
                                errors.name ? 'stripe-checkout-customer-err' : ''
                            }`}
                            type="text"
                            placeholder="Name"
                            onChange={e => updateSlice('name', e.target.value)}
                            value={state.name.replace(/ +/, ' ')}
                            autoComplete="off"
                            disabled={currentUser && currentUser?.profile?.full_name}
                            required
                        />
                        {/* validation error */}
                        {errors.name && (
                            <p className="stripe-checkout-customer-err-msg">{errors.name}</p>
                        )}
                    </div>
                    <div className="stripe-checkout-form-element-wrap">
                        <input
                            type="email"
                            placeholder="Email"
                            className={`stripe-checkout-form-element stripe-checkout-customer-form-element ${
                                errors?.email || orderEmailError
                                    ? 'stripe-checkout-customer-err'
                                    : ''
                            }`}
                            onChange={emailChangeHandler}
                            value={state.email}
                            autoComplete="off"
                            disabled={currentUser && currentUser?.profile?.email}
                            required
                        />
                        {/* validation error */}
                        {errors.email && !orderEmailError && (
                            <p className="stripe-checkout-customer-err-msg">
                                {errors?.email}{' '}
                                {errors?.email?.includes('Email already exists') &&
                                    !isUserLoggedIn && (
                                        <LoginRedirect
                                            handleLoginRedirect={() =>
                                                handleRedirectTo(LoginMode.email)
                                            }
                                        />
                                    )}
                            </p>
                        )}
                        {/* gql error */}
                        {orderEmailError && (
                            <p className="stripe-checkout-customer-err-msg">
                                {createOrderError?.message}{' '}
                                {!isUserLoggedIn && (
                                    <LoginRedirect
                                        handleLoginRedirect={() =>
                                            handleRedirectTo(LoginMode.email)
                                        }
                                    />
                                )}
                            </p>
                        )}
                    </div>
                    <div className="stripe-checkout-form-element-wrap">
                        <PhoneInput
                            placeholder={t(
                                'common:imports.wlWeb.ui.pages.signup.stepOne.enter_sms'
                            )}
                            countryOptionsOrder={Countries}
                            countries={Countries}
                            defaultCountry="US"
                            value={state.phoneNumber}
                            onChange={e => updateSlice('phoneNumber', e)}
                            className={`stripe-checkout-form-element-phone ${
                                errors.phoneNumber || orderPhoneError || orderTempPhoneError
                                    ? 'stripe-checkout-customer-phone-err'
                                    : ''
                            }`}
                        />
                        {/* validation error */}
                        {errors.phoneNumber && !orderPhoneError && (
                            <p className="stripe-checkout-customer-err-msg">{errors.phoneNumber}</p>
                        )}
                        {/* gql error */}
                        {orderPhoneError && (
                            <p className="stripe-checkout-customer-err-msg">
                                {createOrderError?.message}{' '}
                                {!isUserLoggedIn && (
                                    <LoginRedirect
                                        handleLoginRedirect={() =>
                                            handleRedirectTo(LoginMode.phone)
                                        }
                                    />
                                )}
                            </p>
                        )}
                        {/* gql error */}
                        {orderTempPhoneError && (
                            <p className="stripe-checkout-customer-err-msg">
                                {createOrderError?.message} {orderTempPhoneError}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </EditScreenItemContainer>
    );
};

export default CustomerDetailsForm;
