import React, { FormEventHandler, useEffect, useRef } from 'react';
import { Updater } from 'use-immer';

import { useTranslation } from 'react-i18next';

import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';
import useModal from '@components/modals/hooks/useModal';
import useCancelableTimer from '@components/generic/hooks/useCancelableTimer';

import SurveyFormSettings from '@components/content/newpost/forms/Survey/SurveyFormSettings';
import SurveyFormMain from '@components/content/newpost/forms/Survey/SurveyFormMain';

import { curriedStateSlice } from '@web/utilities/helpers/state/state.helpers';

import { SurveyFormState } from '@core/types/Surveys';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

type SurveyFormProps = {
    state: SurveyFormState;
    setState: Updater<SurveyFormState>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    hasAdminRole?: boolean;
    chapterTitle?: string;
    title?: string;
    buttonText?: string;
};

const SurveyForm: React.FC<SurveyFormProps> = ({
    state,
    setState,
    onSubmit,
    hasAdminRole,
    chapterTitle,
    title = 'Create Survey',
    buttonText = 'Create',
}) => {
    const { t } = useTranslation();

    const updateSlice = curriedStateSlice(setState);

    const { closeModal } = useModal();

    const container = useRef<HTMLFormElement>(null);
    const refs = {
        settings: useRef<HTMLElement>(null),
        main: useRef<HTMLFieldSetElement>(null),
    };

    const { on, scrollTo } = useHorizontalPages({
        refs,
        options: { root: container.current },
    });

    const timer = useCancelableTimer();

    // show main when first opening form
    useEffect(() => {
        timer.start(() => scrollTo.main(false), 100);
    }, []);

    const showBackButton = on.settings;

    return (
        <form onSubmit={onSubmit} className="create-course-form" ref={container}>
            <header>
                <section>
                    <button
                        className={showBackButton ? '' : 'hidden'}
                        onClick={() => scrollTo.main()}
                        type="button"
                        aria-label="Back"
                    />
                    <h1>{title}</h1>
                </section>
                <button type="button" onClick={closeModal}>
                    {t(`common:${localNamespace}.cancel`)}
                </button>
            </header>

            <article>
                <SurveyFormSettings
                    ref={refs.settings}
                    hasAdminRole={hasAdminRole}
                    state={state.settings}
                    setState={updateSlice('settings')}
                />

                <SurveyFormMain ref={refs.main} state={state.main} setState={updateSlice('main')} />
            </article>

            <footer>
                <section>
                    {chapterTitle && (
                        <span>{`${t('common:global.verbs.posting_into')} ${chapterTitle}`}</span>
                    )}
                </section>
                <section>
                    <button
                        type="button"
                        onClick={() => {
                            if (on.settings) scrollTo.main();
                            else scrollTo.settings();
                        }}
                        className="toggle-settings"
                        aria-label="Settings"
                    />

                    <button type="submit">{buttonText}</button>
                </section>
            </footer>
        </form>
    );
};

export default SurveyForm;
