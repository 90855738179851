import { basicJsonPost, authorizedJsonPost } from '/imports/wl-web/utilities/helpers/rest/post';

import MeteorProxy from '~/meteor-proxy';
import { config } from '@core/config/getConfig';

import getLogger from '@core/logger';

const logger = getLogger(module);

export const login = async (email, password) => {
    if (!email || !password) {
        logger.error('Missing email and/or password', email, !!password);
        return [undefined, { message: 'Missing email and/or password' }];
    }

    return basicJsonPost(
        MeteorProxy.absoluteUrl('api/login', {
            rootUrl: config.public.apiUrl,
            server: true,
            secure: true,
        }),
        { email, password }
    );
};

export const loginWithPhoneNumber = async (phone, password) => {
    if (!phone || !password) {
        logger.error('Missing phone and/or password', phone, !!password);
        return [undefined, { message: 'Missing phone and/or password' }];
    }

    return basicJsonPost(
        MeteorProxy.absoluteUrl('api/loginWithPhoneNumber', {
            rootUrl: config.public.apiUrl,
            server: true,
            secure: true,
        }),
        { phone, password }
    );
};

export const loginSms = {
    sendCode: async (phone, host) => {
        try {
            if (!phone) {
                logger.error('Missing phone');
                return;
            }
            return await basicJsonPost(
                MeteorProxy.absoluteUrl('api/login/sms/send', {
                    rootUrl: config.public.apiUrl,
                    server: true,
                    secure: true,
                }),
                { phone, host }
            );
        } catch (err) {
            logger.debug(err.message);
            return {
                status: 400,
                resp: null,
            };
        }
    },
    verifyCode: async ({ phone, instanceId, code, alreadyVerified = false }) => {
        if (!phone || (!code && !alreadyVerified)) {
            logger.error('Missing phone and/or code', phone, code);
            return;
        }

        return basicJsonPost(
            MeteorProxy.absoluteUrl('api/login/sms', {
                rootUrl: config.public.apiUrl,
                server: true,
                secure: true,
            }),
            {
                phone,
                instanceId,
                code,
                alreadyVerified,
            }
        );
    },
};

export const silentlyMigrate = async oldToken => {
    logger.debug('Migrating user', oldToken);

    if (!oldToken) {
        logger.error('Missing oldToken');
        return;
    }
    return basicJsonPost(
        MeteorProxy.absoluteUrl('api/login/migrate', {
            rootUrl: config.public.apiUrl,
            server: true,
            secure: true,
        }),
        { oldToken }
    );
};

export const silentlyRefreshJwt = async token => {
    logger.debug('Refreshing JWT', token);
    if (!token) {
        logger.error('Missing token');
        return;
    }
    return authorizedJsonPost(
        MeteorProxy.absoluteUrl('api/login/refresh', {
            rootUrl: config.public.apiUrl,
            server: true,
            secure: true,
        }),
        {},
        token
    );
};
