import React from 'react';

const HelpingHandsIcon: React.FC<{ className?: string; size?: string; strokeWidth?: string }> = ({
    className,
    size = '50',
    strokeWidth = '3',
}) => (
    <svg
        className={className}
        viewBox="0 0 25 30"
        height={size}
        width={size}
        strokeWidth={strokeWidth}
        stroke="currentcolor"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            stroke="black"
            strokeWidth={1}
            className="path"
            d="M25,8.08a7.23,7.23,0,0,0-2.37-5.21,7.32,7.32,0,0,0-10.12.36A7.32,7.32,0,0,0,2.38,2.86,7.3,7.3,0,0,0,2.12,13.4l.31.31-.24.24a2.16,2.16,0,0,0,0,3.06,2.13,2.13,0,0,0,1.52.63h.12a2.18,2.18,0,0,0,.64,1.66A2.13,2.13,0,0,0,6,19.92H6a2.16,2.16,0,0,0,.4,0,2.2,2.2,0,0,0,.64,1.46A2.14,2.14,0,0,0,8.57,22a2.17,2.17,0,0,0,.4,0,2.2,2.2,0,0,0,.64,1.46,2.15,2.15,0,0,0,3,0l1.81-1.81a2.12,2.12,0,0,0,.16-.2,2.22,2.22,0,0,0,.38,0,2.3,2.3,0,0,0,1.59-.65,2.27,2.27,0,0,0,.7-1.65,2.27,2.27,0,0,0,.4,0,2.31,2.31,0,0,0,1.6-.65A2.34,2.34,0,0,0,20,16.85a2.38,2.38,0,0,0,0-.26A2.2,2.2,0,0,0,21.92,16a2.27,2.27,0,0,0,.7-1.62,2.35,2.35,0,0,0-.08-.62l.34-.34A7.23,7.23,0,0,0,25,8.08ZM2.9,16.28a1.15,1.15,0,0,1,0-1.63l.24-.24h0l.71-.71h0l.86-.87a1.14,1.14,0,0,1,.81-.34,1.17,1.17,0,0,1,.83,2L4.55,16.3A1.16,1.16,0,0,1,2.9,16.28ZM6,18.92a1.15,1.15,0,0,1-.83-.36A1.14,1.14,0,0,1,5,17.23,2.16,2.16,0,0,0,5.25,17L7.06,15.2a2.13,2.13,0,0,0,.23-.29,1.16,1.16,0,0,1,1.34,1.86L6.82,18.58a1.13,1.13,0,0,1-.81.34Zm1.73,1.69a1.15,1.15,0,0,1-.21-1.32l1.81-1.81a2.14,2.14,0,0,0,.29-.38,1.14,1.14,0,0,1,1.56.1,1.16,1.16,0,0,1,0,1.63L9.38,20.63A1.15,1.15,0,0,1,7.74,20.61Zm6,.27-1.81,1.81a1.16,1.16,0,0,1-1.85-1.35l1.81-1.81a2.13,2.13,0,0,0,.29-.38,1.14,1.14,0,0,1,1.56.1A1.16,1.16,0,0,1,13.75,20.88Zm7.44-5.61a1.25,1.25,0,0,1-1.77,0l-4-4-.71.71,3.94,4h0a1.27,1.27,0,0,1,.36.92,1.36,1.36,0,0,1-.43,1,1.25,1.25,0,0,1-1.77,0l-4-4-.71.71,4,4,0,0a1.28,1.28,0,0,1-.26,1.54,1.25,1.25,0,0,1-.83.34,2.18,2.18,0,0,0-.62-1.89,2.13,2.13,0,0,0-1.92-.59,2.19,2.19,0,0,0-.64-1.46A2.13,2.13,0,0,0,10,15.87a2.19,2.19,0,0,0-.64-1.46,2.13,2.13,0,0,0-1.64-.62A2.16,2.16,0,0,0,4,12.13L3.14,13l-.31-.31A6.3,6.3,0,0,1,3.05,3.6a6.32,6.32,0,0,1,8.74.34l-5,5,1.44,1.45a2.55,2.55,0,0,0,3.54,0l2.16-2.17a1.5,1.5,0,0,1,2.11,0l5.22,5.17a1.27,1.27,0,0,1,.37.92A1.29,1.29,0,0,1,21.2,15.27Zm1-2.58-.12.13-.09-.1L16.74,7.54a2.5,2.5,0,0,0-3.53,0L11,9.72a1.53,1.53,0,0,1-2.12,0L8.18,9l5-5A6.33,6.33,0,0,1,22,3.6a6.29,6.29,0,0,1,.22,9.08Z"
        />
    </svg>
);

export default HelpingHandsIcon;
