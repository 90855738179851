import React from 'react';

import FullSizePlus from '@dsc/svgs/FullSizePlus';
import FullSizeX from '@dsc/svgs/FullSizeX';
import { RoundedCheckmark } from '@dsc/svgs/Checkmark';
import Picture from '@components/generic/Picture';

import signUpStore from '@stores/SignUp';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';
import { Group } from '@shared/welibrary-graphql/types';

const JoinMoreGroupItem: React.FC<{ className?: string; group: Group }> = ({
    className,
    group,
}) => {
    const groupID = group?._id;
    const groupsToJoin = signUpStore.useTracked.groupsToJoin();
    const groupsJoinedAndRequested = signUpStore.useTracked.groupsJoinedAndRequested();
    const groupJoinedByGroupCode = signUpStore.get.activeGroup();

    const protectionLevel = group?.settings?.protectionLevel;
    const name = group?.profile?.full_name;
    const bio = group?.profile?.short_bio;
    const image = group?.profile?.picture ?? getRandomDefaultCoverImage(group?.profile?.full_name);

    const isSelectedGroup = groupsToJoin?.find(id => id === groupID);
    const isJoinedOrRequestedGroup =
        groupJoinedByGroupCode?._id === group?._id ||
        groupsJoinedAndRequested?.find(({ group: _group }) => _group?._id === groupID);

    const handleSelectGroup = (groupId: string) => {
        signUpStore.set.setGroupToJoin(groupId);
    };
    const handleDeselectGroup = (groupId: string) => {
        if (isJoinedOrRequestedGroup) return;

        signUpStore.set.deselectGroupToJoin(groupId);
    };

    let actionButton: React.ReactNode | null = (
        <button
            type="button"
            onClick={() => handleSelectGroup(groupID)}
            className="p-0 m-0 absolute top-[-7px] right-[-5px] rounded-full flex items-center justify-center bg-white w-[34px] h-[34px] z-50 border-primary-default border-2"
        >
            <FullSizePlus className="text-primary-default w-[20px] h-[20px]" />
        </button>
    );

    if (isSelectedGroup) {
        actionButton = (
            <button
                type="button"
                onClick={() => handleDeselectGroup(groupID)}
                className="p-0 m-0 absolute top-[-7px] right-[-5px] rounded-full flex items-center justify-center bg-primary-default w-[34px] h-[34px] z-50 border-primary-default border-2 hover:bg-red-500 hover:border-red-500"
            >
                <FullSizeX className="text-white w-[16px] h-[16px]" />
            </button>
        );
    }

    if (isJoinedOrRequestedGroup) {
        actionButton = (
            <button
                type="button"
                className="p-0 m-0 absolute top-[-7px] right-[-5px] rounded-full flex items-center justify-center bg-primary-default w-[34px] h-[34px] z-50 border-primary-default border-2"
                disabled
            >
                <RoundedCheckmark className="text-white w-[20px] h-[20px]" strokeWidth="2" />
            </button>
        );
    }

    return (
        <li
            className={`w-[48%] rounded-[16px] flex items-center justify-start shadow-around p-[10px] h-[114px] min-h-[110px] relative border-2 ${isSelectedGroup || isJoinedOrRequestedGroup
                    ? 'border-primary-default'
                    : 'border-white'
                } ${className}`}
        >
            <Picture
                url={image}
                className="!w-[90px] rounded-[10px] overflow-hidden shrink-0"
                disableLink
            />
            <div className="flex flex-col font-poppins grow ml-2 justify-center items-start h-[100px] min-h-[100px] relative text-left">
                <span className="text-[12px] font-poppins font-[600] tracking-[0.25px] leading-[20px] text-grayscale-body uppercase self-start">
                    {protectionLevel}
                </span>
                <div className="flex flex-col grow">
                    <span
                        title={name}
                        className="text-grayscale-title-active text-[17px] leading-[22px] tracking-[0.25px] font-poppins font-normal text-left line-clamp-1"
                    >
                        {name}
                    </span>
                    <span className="text-grayscale-placeholder text-[14px] font-[400] leading-[22px] tracking-[0.25px] text-left line-clamp-2">
                        {bio}
                    </span>
                </div>
            </div>
            {actionButton}
        </li>
    );
};

export default JoinMoreGroupItem;
