import React, { FormEventHandler } from 'react';
import { Updater } from 'use-immer';
import { useTranslation } from 'react-i18next';

import Toggle from '@dsc/forms/customInputs/Toggle';
import RRBuilderColorPicker from '@components/rainbowroad/builder/RRBuilderColorPicker';
import ImageUpload from '@dsc/forms/customInputs/ImageUpload';

import { titleCase } from '@helpers/string.helpers';
import { curriedStateSlice } from '@helpers/state/state.helpers';
import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

import { RainbowRoadBuilderHeaderState } from '@core/types/RainbowRoad';

const localNamespace = 'imports.wlWeb.ui.components.rainbowRoad.rrBuilder';

type RRBuilderHeaderProps = {
    title: string;
    state: RainbowRoadBuilderHeaderState;
    setState: Updater<RainbowRoadBuilderHeaderState>;
    onSubmit: FormEventHandler<HTMLFormElement>;
};

const RRBuilderHeader = React.forwardRef<HTMLFormElement, RRBuilderHeaderProps>(
    function RRBuilderHeader({ title, state, setState, onSubmit }, ref) {
        const { t } = useTranslation();

        const updateSlice = curriedStateSlice(setState);

        return (
            <form
                className="rr-builder-header"
                onSubmit={e => {
                    e.preventDefault();
                    onSubmit(e);
                }}
                ref={ref}
            >
                <h1>{t(`common:${localNamespace}.header_settings`)}</h1>

                <label>
                    {t(`common:${localNamespace}.dark_mode`)}
                    <Toggle value={state.darkMode} onChange={updateSlice('darkMode')} />
                </label>

                <label>
                    Show Top Info
                    <Toggle value={state.showTopInfo} onChange={updateSlice('showTopInfo')} />
                </label>

                <RRBuilderColorPicker
                    state={state.color}
                    setState={updateSlice('color')}
                    values={[
                        'var(--color-grayscale-body)',
                        '#000',
                        'var(--color-wl-primary)',
                        'var(--color-wl-secondary',
                    ]}
                    darkMode={state.darkMode}
                    title={t(`common:${localNamespace}.color_theme`)}
                />

                <label>
                    {titleCase(t('common:foreground_image', 'Foreground image'))}
                    <Toggle
                        value={state.useForegroundImage}
                        onChange={updateSlice('useForegroundImage')}
                    />
                </label>

                {state.useForegroundImage && (
                    <ImageUpload
                        state={{
                            ...state.foregroundImage,
                            image: state.foregroundImage.image || getRandomDefaultCoverImage(title),
                        }}
                        setState={updateSlice('foregroundImage')}
                        className="rr-background-image-upload"
                        loaderSize={100}
                    />
                )}

                <label>
                    {t(`common:${localNamespace}.background_image`)}
                    <Toggle
                        value={state.useBackgroundImage}
                        onChange={updateSlice('useBackgroundImage')}
                    />
                </label>

                {state.useBackgroundImage && (
                    <ImageUpload
                        state={{
                            ...state.backgroundImage,
                            image: state.backgroundImage.image || getRandomDefaultCoverImage(title),
                        }}
                        setState={updateSlice('backgroundImage')}
                        className="rr-background-image-upload"
                        loaderSize={100}
                    />
                )}
            </form>
        );
    }
);

export default RRBuilderHeader;
