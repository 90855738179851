import React from 'react';

import DocumentItemButtons from '@components/card/mediacard/bodyitems/DocumentItemButtons';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import timer from '@core/utilities/timer';

import { Links, Media } from '@shared/welibrary-graphql/types';

type DocumentItemProps = {
    title: string;
    media: Media;
    links: Links;
    onLoad?: Function;
    baseMetricStringName?: string; // allows parent to pass the start of a string applied to multiple metric names in DocumentItemButtons
};

const DocumentItem: React.FC<DocumentItemProps> = ({
    title,
    media,
    links,
    onLoad,
    baseMetricStringName,
}) => {
    timer(1).then(() => onLoad?.());

    const randomImage = getRandomDefaultCoverImage(links.title || title || undefined);
    const bgImage =
        !media.thumb || media.thumb === '/images/document.jpeg' ? randomImage : media.thumb;

    const [src, srcSet] = getBackgroundImageSetForUrl(bgImage);

    return (
        <div className="mc-media-item documentitem">
            <StyledBackgroundImage className="mc-media-item-bg" src={src} srcSet={srcSet} />
            <div className="mc-media-item-content">
                <div className="mc-media-content" style={{ width: '100%' }}>
                    <div className="mc-post-title media-title-1">{title || ''}</div>
                    {links.source && (
                        <DocumentItemButtons
                            fileUrl={links.source}
                            baseMetricStringName={baseMetricStringName}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DocumentItem;
