import { useEffect } from 'react';
import { GET_RANDOM_ENGAGEMENT_CARD_SAMPLING } from '@shared/welibrary-graphql/content_card/queries';

import useInfiniteScrollBlacklist from '@web/ui/components/generic/hooks/useInfiniteScrollBlacklist';
import { NewsfeedEnum } from '@components/newsfeed/types';
import { useCurrentUser } from '@stores/User';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useSampledNewsfeed = (runQuery?: NewsfeedEnum) => {
    const runSampleQuery = runQuery === NewsfeedEnum.Both || runQuery === NewsfeedEnum.Sampled;
    const { currentUser } = useCurrentUser();
    const flags = useFlags();
    const disableForYouNewsfeed = flags?.disableForYouNewsfeed;

    const {
        setRef,
        queryResult: { refetch, data: sampledData, error, networkStatus, loading: sampledLoading },
        hasMoreResults,
    } = useInfiniteScrollBlacklist({
        query: {
            query: GET_RANDOM_ENGAGEMENT_CARD_SAMPLING,

            options: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                skip: !runSampleQuery,
            },
        },
        visibleOffset: 100,
        throttleTime: 100,
    });

    // sometimes this query will run before current user is set
    // and it leads to the sampled newsfeed query being empty until the user manually refetches
    useEffect(() => {
        if (currentUser) {
            refetch();
        }
    }, [currentUser?._id, disableForYouNewsfeed]);

    /** @type Array<any> | null */
    const sampledCards = sampledData?.getRandomEngagementCardSampling;
    const sampledCardIds = sampledCards?.map(card => card._id);

    return {
        setRef,
        refetch,
        error,
        networkStatus,
        hasMoreResults,
        sampledCards,
        sampledCardIds,
        sampledLoading,
    };
};

export default useSampledNewsfeed;
