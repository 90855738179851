import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

import useTimer from '@web/ui/components/generic/hooks/useTimer';

const LOADING_STATE = {
    NONE: 'loading_none',
    START: 'loading_start',
    WAITING: 'loading_waiting',
    FINISHING: 'loading_finishing',
    FINISHED: 'loading_finished',
    ERROR: 'loading_error',
    ERROR_FINISHED: 'loading_error_finished',
} as const;

const LOADING_CLASS = {
    [LOADING_STATE.START]: 'initiate',
    [LOADING_STATE.WAITING]: 'initiate interim',
    [LOADING_STATE.FINISHING]: 'initiate interim end',
    [LOADING_STATE.FINISHED]: 'initiate interim end finished',
    [LOADING_STATE.ERROR]: 'initiate interim end error',
    [LOADING_STATE.ERROR_FINISHED]: 'initiate interim end error finished',
    [LOADING_STATE.NONE]: '',
} as const;

const START_TIME = 800;
const FINISH_TIME = 1000;
const RESET_TIME = FINISH_TIME + 100;
const ERROR_FINISH_TIME = 3000;
const ERROR_RESET_TIME = ERROR_FINISH_TIME + 300;

const LoadingLine: React.FC<{
    startState?: typeof LOADING_STATE[keyof typeof LOADING_STATE];
    finishedLoading?: () => void;
    error?: any;
    isLoading: boolean;
}> = ({ startState, finishedLoading, error, isLoading }) => {
    const [loadingState, setLoadingState] = useState(startState || LOADING_STATE.NONE);

    const timer = useTimer();

    const startLoading = () => {
        if (
            loadingState === LOADING_STATE.ERROR_FINISHED ||
            loadingState === LOADING_STATE.FINISHED ||
            loadingState === LOADING_STATE.NONE
        ) {
            setLoadingState(LOADING_STATE.START);

            timer(START_TIME, () => setLoadingState(LOADING_STATE.WAITING));
        }
    };
    const finishLoading = () => {
        if (loadingState === LOADING_STATE.START || loadingState === LOADING_STATE.WAITING) {
            if (error) {
                // if there was an error, finish by displaying error line
                setLoadingState(LOADING_STATE.ERROR);

                timer(ERROR_FINISH_TIME, () => setLoadingState(LOADING_STATE.ERROR_FINISHED));

                timer(ERROR_RESET_TIME, () => setLoadingState(LOADING_STATE.NONE));
            } else {
                // if there was no error, finish out normally
                setLoadingState(LOADING_STATE.FINISHING);

                timer(FINISH_TIME, () => {
                    finishedLoading?.();
                    setLoadingState(LOADING_STATE.FINISHED);
                });

                timer(RESET_TIME, () => setLoadingState(LOADING_STATE.NONE));
            }
        }
    };

    useEffect(() => {
        if (isLoading) startLoading();
        else finishLoading();
    }, [isLoading]);

    const errorMessage = error?.message?.replace('GraphQL error:', '') ?? '';

    return createPortal(
        <div className={`loader-wrapper ${loadingState === LOADING_STATE.ERROR ? 'error' : ''}`}>
            <div className={`loader-bar ${LOADING_CLASS[loadingState]}`}>{errorMessage}</div>
        </div>,
        document.getElementById('modal-super-root')
    );
};

export default LoadingLine;
