// src/broadcastsurvey/queries.ts
import { gql } from "@apollo/client";
var GET_BROADCAST_SURVEYS_BY_GROUP_ID = gql`
  query GetBroadcastSurveysByGroupId($groupId: ID!) {
    getBroadcastSurveysByGroupId(groupId: $groupId) {
      _id
      title
      description
      status
      groupId
      questions {
        id
        type
        selectMultiple
        allowOther
        required
        link
        defaultComparator
        prompt
        index
        options
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
      }
    }
  }
`;
var GET_BROADCAST_SURVEY_BY_ID = gql`
  query GetBroadcastSurveyById($id: ID!) {
    getBroadcastSurveyById(id: $id) {
      _id
      title
      description
      status
      groupId
      questions {
        id
        type
        selectMultiple
        allowOther
        required
        link
        defaultComparator
        prompt
        index
        options
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
      }
    }
  }
`;
var BROADCAST_SURVEY_HAS_SIGNATURE_SURVEY_RESPONSE = gql`
  query BroadcastSurveyHasSignatureSurveyResponse($broadcastSurveyId: ID!) {
    broadcastSurveyHasSignatureSurveyResponse(
      broadcastSurveyId: $broadcastSurveyId
    )
  }
`;
var GET_BROADCAST_SURVEY_CSV_STRING = gql`
  query GetBroadcastSurveyCsvString($broadcastSurveyId: ID!) {
    getBroadcastSurveyCsvString(broadcastSurveyId: $broadcastSurveyId)
  }
`;
export {
  BROADCAST_SURVEY_HAS_SIGNATURE_SURVEY_RESPONSE,
  GET_BROADCAST_SURVEYS_BY_GROUP_ID,
  GET_BROADCAST_SURVEY_BY_ID,
  GET_BROADCAST_SURVEY_CSV_STRING
};
