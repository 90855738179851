import React from 'react';

type ToggleProps = {
    id?: string;
    className?: string;
    value?: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
};

/**
 * On/Off Switch
 * See https://www.figma.com/file/naUyXB9d4H9XG1tuFkgtbq/weLibrary-Design-Systems?node-id=982%3A5900
 * }>
 */
const Toggle: React.FC<ToggleProps> = ({
    id,
    className = '',
    value = false,
    onChange,
    disabled = false,
}) => (
    <button
        type="button"
        onClick={() => onChange(!value)}
        onKeyDown={e => {
            if (e.key === ' ') onChange(!value);
        }}
        id={id}
        className={`dsc-forms-customInputs-toggle ${className}`}
        role="switch"
        aria-checked={value}
        disabled={disabled}
    >
        <div role="presentation" />
    </button>
);

export default Toggle;
