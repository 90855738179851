export const ICONS = {
    member: '/images/icon.black.people3.svg',
    search: '/images/icon.black.search2.svg',
    searchWhite: '/images/icon.white.search2.svg',
    archive: '/images/icon.black.lock.svg',
    unarchive: '/images/icon.black.unlock.svg',
    message: '/images/icon.black.airplane.svg',
    switch: '/images/icon.black.switch.svg',
    switchWhite: '/images/icon.white.switch.svg',
    newsfeed: '/images/icon.black.newsfeed4.svg',
    home: '/images/icon.black.home.svg',
    homeWhite: '/images/icon.white.home.svg',
    pinWhite: '/images/icon.white.pin.svg',
    group: '/images/icon.black.groupv2.svg',
    groupHome: '/images/icon.black.group.home.svg',
    menu: '/images/icon.black.navigator3.svg',
    share: '/images/share.svg',
    notifications: '/images/icon.black.bell3.svg',
    notificationsWhite: '/images/icon.white.Bell.svg',
} as const;

export default ICONS;
