import React from 'react';

type LockIconProps = { className?: string; version: '1' | '2'; color?: string };

const LockIcon: React.FC<LockIconProps> = ({ className = '', version = '1', color = 'white' }) => {
    if (version === '1') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
                className={className}
                fill="none"
            >
                <path d="M428.38,210.8H366.89V118.21C366.89,57.58,317.28,8,256.66,8H234.61C174,8,124.38,57.58,124.38,118.21V210.8H62.89c-.14,2.42-.23,4.86-.23,7.32V365.4c0,65.32,49.6,118.77,110.22,118.77H318.39c60.62,0,110.22-53.45,110.22-118.77V218.12C428.61,215.66,428.52,213.22,428.38,210.8ZM274,407H216.45l5.25-60.42a39.9,39.9,0,1,1,47,0ZM314,210.8H168.47v-86a72.76,72.76,0,0,1,145.51,0Z" />
            </svg>
        );
    }

    // version === '2'
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 5.5H3C2.72386 5.5 2.5 5.72386 2.5 6V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V6C13.5 5.72386 13.2761 5.5 13 5.5Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.75 5.5V3.25C5.75 2.65326 5.98705 2.08097 6.40901 1.65901C6.83097 1.23705 7.40326 1 8 1C8.59674 1 9.16903 1.23705 9.59099 1.65901C10.0129 2.08097 10.25 2.65326 10.25 3.25V5.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 10.5C8.55228 10.5 9 10.0523 9 9.5C9 8.94772 8.55228 8.5 8 8.5C7.44772 8.5 7 8.94772 7 9.5C7 10.0523 7.44772 10.5 8 10.5Z"
                fill={color}
            />
        </svg>
    );
};

export default LockIcon;
