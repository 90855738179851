import React from 'react';

import {useMutation} from '@apollo/client';

import {RUN_BULK_CARD_JOB} from '@shared/welibrary-graphql/content_card/mutations';

const useBulkCardActions = () => {
    const [runBulkCardJob, { data, loading, error }] = useMutation(RUN_BULK_CARD_JOB);

    const resetChildrenSettingsOfCards = async ids => {
        return runBulkCardJob({
            variables: {
                input: {
                    action: 'RESET_CHILDREN_SETTINGS',
                    targets: ids,
                },
            },
        });
    };

    return {
        data,
        loading,
        error,
        resetChildrenSettingsOfCards,
    };
};

export default useBulkCardActions;
