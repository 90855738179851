import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    getWhitelabelPublicSettings,
    getOptionalPostTypes,
} from '@core/utilities/whitelabel_helpers';

import { awsMetricPublisher } from '@welibraryos/metrics';

import useQuickLinks from '@web/ui/components/navigation/SideMenu/Navigator/hooks/useQuickLinks';
import useModal from '@web/ui/components/modals/hooks/useModal';

import { useCurrentUser } from '@stores/User';

import DropdownController from '@web/ui/components/generic/dropdownmenu/DropdownController';
import SearchForm from '@web/ui/components/search/form/SearchForm';
import CreateRainbowRoad from '@web/ui/components/rainbowroad/CreateRainbowRoad';

import Plus from '@web/ui/dsc/svgs/FullSizePlus';

import ICONS from '@core/utilities/constants/icons';

import { MenuListItem } from '@core/types/Menus';
import { CARD_RAINBOW_ROAD } from '~/wl-core/utilities/constants/card_types';

const localNamespace = 'imports.wlWeb.ui.components.navigation.slideMenu';

const AddQuickLinkButton: React.FC<{ home?: boolean }> = ({ home }) => {
    const { t } = useTranslation();
    const { slideMenu } = getWhitelabelPublicSettings();
    const additionalPostTypes = getOptionalPostTypes();
    const displayRRCreationOption = additionalPostTypes?.includes(CARD_RAINBOW_ROAD);
      const { currentUser } = useCurrentUser();

    const { newModal } = useModal();

    const { addResult, getLinks } = useQuickLinks(home);

    const menu: MenuListItem[] = [
        {
            id: 'add-quick-link',
            title: t(`common:${localNamespace}.${home ? 'add_home_link' : 'add_quick_link'}`),
            onClick: () => {
                awsMetricPublisher.publishCount('side-menu-quick-links', 1, [
                    { Name: 'add-menu', Value: 'add-quick-link' },
                ]);
                newModal(
                    <SearchForm
                        title={t(`common:${localNamespace}.add_quick_link`)}
                        addResult={addResult}
                        suggestionsFilter={getLinks()?.quickLinks ?? []}
                        filters={['All', 'Channels', 'Groups', 'Profiles']}
                    />,
                    { className: 'no-bottom-padding' }
                );
            },
        },
    ];

    if (currentUser?.isUserSuperAdmin && slideMenu?.rainbowRoad && displayRRCreationOption) {
        menu.push({
            id: 'create-custom-rainbow-road',
            title: t(`common:${localNamespace}.create_rainbow_road`),
            onClick: () => {
                awsMetricPublisher.publishCount('side-menu-quick-links', 1, [
                    { Name: 'add-menu', Value: 'create-rainbow-road' },
                ]);
                newModal(<CreateRainbowRoad />, { className: 'full-height no-bottom-padding' });
            },
        });
    }

    return (
        <DropdownController
            buttonClass="side-menu-navigator-quick-link"
            menuList={menu}
            noIcons
            onClickCallback={() =>
                awsMetricPublisher.publishCount('side-menu-open-add-quick-link-menu', 1)
            }
        >
            <section
                className={`side-menu-navigator-quick-link-icon add-quick-link ${
                    home ? 'home' : ''
                }`}
            >
                <Plus strokeWidth="3" />
                {home && <img src={ICONS.pinWhite} alt="Pin Icon" className="corner-icon" />}
            </section>

            <section className="side-menu-navigator-quick-link-description">
                <h2>
                    {t(`common:${localNamespace}.${home ? 'add_pinned_link' : 'add_quick_link'}`)}
                </h2>
            </section>
        </DropdownController>
    );
};

export default AddQuickLinkButton;
