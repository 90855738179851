import React, { useState, useEffect } from 'react';

import {
    ATTACH_AUDIO,
    ATTACH_DOCUMENT,
    ATTACH_IMAGE,
    ATTACH_LINK,
    ATTACH_NONE,
    ATTACH_VIDEO,
} from '@core/utilities/constants/attachment_types.js';

import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import { ApolloConsumer } from '@apollo/client';

const StagingContainer = ({ type, onSuccess, handleChange, handleClear }) => {
    const [linkValue, setLinkValue] = useState('');
    const [isFetchingLink, setIsFetchingLink] = useState(false);
    const isLinkEmpty = () => linkValue.length <= 0;

    const cancelLinkFetch = () => {
        if (!isLinkEmpty()) {
            setIsFetchingLink(false);
            setLinkValue('');
        }
    };

    const handleCancel = () => {
        cancelLinkFetch?.();
        handleClear?.();
    };



    switch (type) {
        case ATTACH_NONE:
            break;
        case ATTACH_IMAGE:
        case ATTACH_DOCUMENT:
        case ATTACH_AUDIO:
            /* return(

                    <a href="#" className="media-post-upload w-inline-block">
                      <img src="/images/icon.black.download.svg" alt="" className="upload-icon" />
                      <div className="text-block-2" onClick={() => this.openFilePicker(onSuccess, onCancel)}>Drag &amp; drop or click to upload.</div>
                      {this.state.loading && <div>Opening...</div>}
                      <div className="cancel-media-upload-button" onClick={() => onCancel()}>Cancel</div>
                    </a>
            ); */
            break;
        case ATTACH_VIDEO:
            return (
                <div className="media-post-link w-form">
                    <form name="post-link-form">
                        {isFetchingLink && <LoadingLine isLoading={isFetchingLink} />}
                        <input
                            type="text"
                            className="media-post-field w-input"
                            onChange={handleChange}
                            autoFocus
                            maxLength="256"
                            name="Link"
                            data-name="Link"
                            placeholder="Paste YouTube Link"
                            id="Link"
                            disabled={isFetchingLink}
                        />
                        <button
                            type="button"
                            className="media-link-preview-clear-button post-media-button w-inline-block"
                            onClick={handleCancel}
                        >
                            <img src="/images/icon.black.x.svg" alt="Clear link Preview" />
                        </button>
                    </form>
                </div>
            );
        case ATTACH_LINK:
            return (
                <ApolloConsumer>
                    {client => (
                        <div className="media-post-link w-form">
                            <form name="post-link-form">
                                {isFetchingLink && <LoadingLine isLoading={isFetchingLink} />}
                                <input
                                    type="text"
                                    className="media-post-field w-input"
                                    onChange={handleChange}
                                    maxLength="256"
                                    autoFocus
                                    name="Link"
                                    data-name="Link"
                                    placeholder="Paste Link"
                                    id="Link"
                                    disabled={isFetchingLink}
                                />

                                <button
                                    type="button"
                                    className="media-link-preview-clear-button post-media-button w-inline-block"
                                    onClick={handleCancel}
                                >
                                    <img src="/images/icon.black.x.svg" alt="Clear link Preview" />
                                </button>
                            </form>
                        </div>
                    )}
                </ApolloConsumer>
            );
        default:
            return <div />;
    }
    return <div />;
};

export default StagingContainer;
