import React from 'react';

import { Maybe, Product } from '@shared/welibrary-graphql/types';

import { getLowestTicketPrice, getHighestTicketPrice } from '@helpers/money/money.helpers';

type TicketPricesProps = {
    products: Maybe<Product[]>;
    className?: string;
    displayRawPrices: boolean;
    currency?: string;
};

const TicketPrices: React.FC<TicketPricesProps> = ({
    products,
    className = '',
    displayRawPrices = false,
    currency,
}) => {
    if (!products || products.length === 0) {
        return <></>;
    }

    const lowestTicketPrice = getLowestTicketPrice(products, displayRawPrices, currency) ?? '';
    const highestTicketPrice = getHighestTicketPrice(products, displayRawPrices, currency) ?? '';

    const moneyString =
        lowestTicketPrice === highestTicketPrice
            ? lowestTicketPrice
            : `${lowestTicketPrice} - ${highestTicketPrice}`;

    return <span className={`ticket-prices ${className ?? ''}`}>{moneyString}</span>;
};

export default TicketPrices;
