import React from 'react';
import { useTranslation } from 'react-i18next';

import { awsMetricPublisher } from '@welibraryos/metrics';

import {
    CARD_IMAGE,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_VIDEO,
} from '@core/utilities/constants/card_types.js';

import ExternalLink from '@web/ui/components/generic/ExternalLink';

const localNamespace = 'imports.wlWeb.ui.components.card.mediaCard.mediaTextContainer';

const MediaTextContainer = ({
    title,
    description,
    source,
    type,
    card,
    baseMetricStringName, // allows parent to pass the start of a string to apply to multiple metric names
}) => {
    const { t } = useTranslation();

    const getSummaryTextData = text => {
        return (
            text && {
                text: text.length > 250 ? `${text.substr(0, 200)}...` : text,
                expanded_text: text,
                truncated: text.length > 250,
            }
        );
    };

    const summaryTextData = getSummaryTextData(description);
    const { text } = summaryTextData || {};

    if (type === CARD_IMAGE || type === CARD_IMAGE_SQUARE) return <></>;

    let nativeTarget;
    if (source && (source.includes('docs.google') || source.includes('zoom.us'))) {
        nativeTarget = '_system';
    }

    const openInModal = type === CARD_LINK && card?.links?.openInModal;

    const handleOnClick = () => {
        switch (type) {
            case CARD_LINK:
                awsMetricPublisher.publishCount(baseMetricStringName, 1, [
                    { Name: 'media-title', Value: 'link' },
                ]);
                break;
            case CARD_VIDEO:
                awsMetricPublisher.publishCount(baseMetricStringName, 1, [
                    { Name: 'media-title', Value: 'video' },
                ]);
                break;
            default:
                awsMetricPublisher.publishCount(baseMetricStringName, 0, [
                    { Name: 'media-title', Value: `invalid-type-${type}` },
                ]);
                break;
        }
    };

    return (
        <div className="mc-media-text-container">
            {title && source && (
                <div className="mc-post-title">
                    <ExternalLink
                        openInModal={openInModal}
                        to={source}
                        nativeTarget={nativeTarget}
                        style={{ color: '#1f1f1f' }}
                        alt={t(`common:${localNamespace}.alt_text`)}
                        onClick={handleOnClick}
                    >
                        <span className="we-media-link-title">{title}</span>
                    </ExternalLink>
                </div>
            )}

            {type !== CARD_LINK && type !== CARD_VIDEO && summaryTextData && (
                <div className="mc-post-summary">
                    {t(`common:${localNamespace}.summary_text_data`, {
                        textData: text,
                    })}
                </div>
            )}
        </div>
    );
};

export default MediaTextContainer;
