import React, {MouseEventHandler} from 'react';

import NavigatorToggleButtonTop from '@web/ui/components/navigation/SideMenu/Navigator/NavigatorToggleButtonTop';
import NavigatorToggleButtonIcon from '@web/ui/components/navigation/SideMenu/Navigator/NavigatorToggleButtonIcon';
import NavigatorToggleButtonBottom from '@web/ui/components/navigation/SideMenu/Navigator/NavigatorToggleButtonBottom';

type NavigatorToggleButtonProps = {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className?: string;
    style?: React.CSSProperties;
};

const NavigatorToggleButton: React.FC<NavigatorToggleButtonProps> = ({
    onClick,
    className = '',
    style,
}) => {
    return (
        <button type="button" className={className} onClick={onClick} style={style}>
            <NavigatorToggleButtonTop className="svg-curve top" />
            <NavigatorToggleButtonIcon className="toggle-button-icon" />
            <NavigatorToggleButtonBottom className="svg-curve bottom" />
        </button>
    );
};

export default NavigatorToggleButton;
