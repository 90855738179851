import React from 'react';
import Picture from '@web/ui/components/generic/Picture';
import {getRandomDefaultCoverImage} from '@core/utilities/constants/defaults';

const ChapterInfoBlock = ({ chapter, book, shelf, className, onClick, style }) => {
    const chapterTitle = chapter?.title ?? '-';
    const bookTitle = book?.title ?? '-';

    const chapterIconUrl = chapter?.media?.icon || '';
    const displayChapterIcon =
        !chapterIconUrl || chapterIconUrl.includes('www.gravatar.com')
            ? book?.media?.thumb || getRandomDefaultCoverImage(book?.title || '')
            : chapterIconUrl;

    const isChapterLive = !!shelf?.library_settings?.dashboardContentItems?.find(
        ({ url }) => url === chapter?.url
    );

    const width = 40;

    return (
        <section className={className} onClick={onClick} style={style}>
            <section className="chapter-info-block-thumb">
                <Picture
                    url={displayChapterIcon}
                    alt={chapterTitle}
                    disableLink
                    maxWidth={width}
                    resolutions={[width, width * 2]}
                />
            </section>
            <section>
                <h6 className="chapter-info-block-book-title">{bookTitle}</h6>
                <h3 className="chapter-info-block-title">
                    {chapterTitle}
                    {isChapterLive && (
                        <img
                            src="/images/icon.blue.star.svg"
                            alt=""
                            className="dropdown-link-icon chapter-live"
                        />
                    )}
                </h3>
            </section>
        </section>
    );
};

export default ChapterInfoBlock;
