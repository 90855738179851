import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/searching-telescope.json';

type SearchLoaderProps = {
    /** Loading state from query */
    loading: boolean;

    /** No results found */
    emptyResults: boolean;

    /** Default empty state */
    emptyState: boolean;

    /** Search results exist */
    areResults: boolean;

    customStyle?: CSSProperties;

    /** Size in pixels */
    size?: number;

    /** Prompt text */
    prompt?: string;

    /** Override the default empty state */
    customEmptyState?: React.ReactElement;
};

const SearchLoader: React.FC<SearchLoaderProps> = ({
    loading,
    emptyResults,
    emptyState,
    areResults,
    customStyle,
    size,
    prompt,
    customEmptyState,
}) => {
    let isPaused = true; // animation is playing or paused
    let fadeState = 'fadein search-animation';
    let statusText = <span />;
    const { t } = useTranslation();
    const noResultsTxt = t('common:imports.wlWeb.ui.components.search.SearchLoader.no_results');
    const searchingTxt = t('common:imports.wlWeb.ui.components.search.SearchLoader.searching');
    const promptTxt = prompt || t('common:imports.wlWeb.ui.components.search.SearchLoader.prompt');

    if (loading) {
        statusText = <span className="fadein-delay"> {searchingTxt} </span>;
        isPaused = false;
        fadeState = 'fadein search-animation';
    }

    if (emptyResults) {
        statusText = <span className="fadein-delay"> {noResultsTxt} </span>;
        isPaused = true;
    }

    if (emptyState) {
        statusText = <span className="fadein-delay"> {promptTxt} </span>;
        isPaused = true;
        fadeState = 'fadein search-animation';
    }

    if (areResults) {
        fadeState = 'fadeout search-animation displaynone';
    }

    // eslint-disable-next-line no-nested-ternary
    const animationSize = !size ? (window?.innerWidth > 479 ? 300 : 200) : size;
    const style = customStyle || { opacity: '1' };

    return (
        <div className={fadeState} style={style}>
            {emptyState && customEmptyState ? (
                customEmptyState
            ) : (
                <>
                    <LottieLoading
                        customStyle={style}
                        height={animationSize}
                        width={animationSize}
                        lottieData={LottieAnimation}
                        isPaused={isPaused}
                    />
                    <h4>{statusText}</h4>
                </>
            )}
        </div>
    );
};

export default SearchLoader;
