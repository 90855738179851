export const TYPE_OPTIMIZE = 'Optimize';
export const TYPE_COMPONENT = 'Component';
export const TYPE_FORM = 'Form';
export const TYPE_POSTING = 'Posting';
export const TYPE_APPLICANTS = 'Applicants';
export const TYPE_APPLICANT = 'Applicant';
export const TYPE_SUGGESTED = 'Suggested';
export const TYPE_COMMENTS = 'Comments';
export const TYPE_PROMPTS = 'Prompts';
export const TYPE_EVENT = 'Event';
export const TYPE_EVENT_OLD = 'Event-Old';
