import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import { DraggableItemTypes } from '@core/utilities/constants/draggable.js';
import { useDrag, useDrop } from 'react-dnd';

const GenericBrowseItem = ({
    item,
    thumb,
    isSelected,
    isPinned,
    url,
    useIcon,
    onClick,
    selectedBook,
    updateCardRankOrder,
    prevItem,
    dragAndDropReorder,
    extraLinkClasses,
}) => {
    let displayThumb = thumb;
    if (!thumb || thumb.includes('www.gravatar.com')) {
        displayThumb = selectedBook.media?.thumb || getRandomDefaultCoverImage(selectedBook.title);
    }

    const [src, srcSet] = getBackgroundImageSetForUrl(displayThumb);

    const itemClass = `w-inline-block ${extraLinkClasses} ${isPinned ? 'first' : ''} ${isSelected ? 'active' : ''
        }`;

    const linkImage = useIcon ? (
        <div className="content-button-icon info" />
    ) : (
        <StyledBackgroundImage className="ln-link-thumb" src={src} srcSet={srcSet} />
    );

    if (updateCardRankOrder && dragAndDropReorder) {
        const [{ isDragging }, drag, preview] = useDrag({
            item: { type: DraggableItemTypes.CHAPTER, card: item },
            collect: monitor => ({ isDragging: !!monitor.isDragging() }),
        });

        const [{ isOver }, drop] = useDrop({
            accept: DraggableItemTypes.CHAPTER,
            drop: dropped => {
                if (dropped?.card?.url && item?.url) {
                    if (item.url !== dropped.card.url) {
                        let optimisticRankOrder = dropped.card.rankOrder || null;
                        const leftSiblingRank = prevItem?.rankOrder;
                        const rightSiblingRank = item?.rankOrder;

                        if (leftSiblingRank && rightSiblingRank) {
                            optimisticRankOrder = (leftSiblingRank + rightSiblingRank) / 2;
                        } else if (leftSiblingRank) {
                            // optimisticResponse = leftSiblingRank + 0.001;
                        } else if (rightSiblingRank) {
                            // optimisticResponse = rightSiblingRank - 0.001;
                        }

                        updateCardRankOrder({
                            variables: {
                                leftSiblingUrl: prevItem?.url,
                                cardUrl: dropped.card.url,
                                rightSiblingUrl: item.url,
                            },
                            optimisticResponse: {
                                __typename: 'Mutation',
                                updateCardRankOrder: {
                                    __typename: 'ContentCardRank',
                                    card: {
                                        __typename: 'ContentCard',
                                        _id: dropped.card._id,
                                        rankOrder: optimisticRankOrder,
                                    },
                                    updatedSiblings: false,
                                    siblings: [],
                                },
                            },
                        });
                    }
                }
            },
            collect: monitor => ({ isOver: !!monitor.isOver() }),
        });

        const dragRef = useRef(null);
        const previewRef = useRef(null);
        drag(dragRef);
        drop(preview(previewRef));

        return (
            <div
                ref={previewRef}
                style={isOver ? { zIndex: 1, opacity: 0.5, borderTop: '2px #0094f6 solid' } : {}}
            >
                <Link
                    to={url}
                    id={item._id}
                    className={itemClass}
                    onClick={onClick}
                    style={{
                        color: isDragging ? 'white' : 'inherit',
                        cursor: isDragging ? 'move' : 'pointer',
                    }}
                >
                    <span className="drag-handle" ref={dragRef} />
                    <div className="ln-link-image">{linkImage}</div>
                    <div className="chapter-title">{item.title}</div>
                </Link>
            </div>
        );
    }

    return (
        <Link to={url} id={item._id} className={itemClass} onClick={onClick}>
            {/* <Link id={item._id} className={itemClass} onClick={()=>onBrowseItemClicked(item.url)}> */}
            <div className="ln-link-image">{linkImage}</div>
            <div className="chapter-title">{item.title}</div>
        </Link>
    );
};

export default GenericBrowseItem;
