// src/signature/mutations.ts
import { gql } from "@apollo/client";
var GENERATE_SIGNATURE_IMAGE_URL = gql`
  mutation GenerateSignatureImageUrl($text: String!, $font: String!) {
    generateSignatureImageUrl(text: $text, font: $font)
  }
`;
export {
  GENERATE_SIGNATURE_IMAGE_URL
};
