import React from 'react';

import VCStatusBox from '@components/lego/modals/VCStatusBox';

import { VerifiableCredentialInfo } from '@core/types/Credential';

type VerifiableCredentialBackDetailsProps = {
    credentialInfo: VerifiableCredentialInfo;
    verificationResults?: {
        status: 'Success' | 'Error' | 'Failed';
        check: string;
        details?: string;
        message?: string;
    }[];
};

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const VerifiableCredentialBackDetails: React.FC<VerifiableCredentialBackDetailsProps> = ({
    credentialInfo,
    verificationResults,
}) => {
    if (!verificationResults) {
        // Preview/sample credential
        return (
            <div className="w-full flex flex-col gap-[10px]">
                <h2 className="text-white text-[30px] self-start ml-[20px] my-0">Details</h2>
                <VCStatusBox
                    status="Success"
                    summary={`Issued to ${credentialInfo.issuedToUserName}`}
                />
                <VCStatusBox
                    status="Success"
                    summary={`Issued by ${credentialInfo.issuedByName}`}
                />
                <VCStatusBox status="Success" summary="Proof: Valid" />
                <VCStatusBox status="Success" summary="Expiration: Valid • Does Not Expire" />
            </div>
        );
    }

    if (verificationResults.length === 0) {
        return (
            <div>
                <h3>Verifying...</h3>
            </div>
        );
    }

    return (
        <div className="w-full flex flex-col gap-[10px]">
            <h2 className="text-white text-[30px] self-start ml-[20px] my-0">Details</h2>
            <VCStatusBox
                status="Success"
                summary={`Issued to ${credentialInfo.issuedToUserName}`}
            />
            <VCStatusBox status="Success" summary={`Issued by ${credentialInfo.issuedByName}`} />
            {verificationResults.map((verification, index) => {
                let summary, details;
                if (verification.status === 'Success') {
                    summary = `${capitalizeFirstLetter(verification.check)}: ${verification.message
                        }`;
                } else {
                    summary = capitalizeFirstLetter(verification.check);
                    details = verification.details;
                }
                return (
                    <VCStatusBox
                        key={index}
                        status={verification.status}
                        summary={summary}
                        details={details}
                    />
                );
            })}
        </div>
    );
};

export default VerifiableCredentialBackDetails;
