import React from 'react';

type SimpleTextInputProps = {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    id?: string;
    className?: string;
    disabled?: boolean;
};

const SimpleTextInput: React.FC<SimpleTextInputProps> = ({
    value,
    onChange,
    placeholder,
    id,
    className = '',
    disabled = false,
}) => (
    <input
        className={`dsc-forms-textInputs-simpleTextInput ${className}`}
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        id={id}
        disabled={disabled}
    />
);

export default SimpleTextInput;
