import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGroupByIdQuery } from '@shared/welibrary-graphql/user/queries.hook';

import useModal from '@components/modals/hooks/useModal';

import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/success-checkmark.json';
import NotifyPanel from '@web/ui/components/engagement/NotifyPanel';

import { Group, Collection } from '@shared/welibrary-graphql/types';
import { CARD_EVENT } from '@core/utilities/constants/card_types';

const localNamespace = 'imports.wlWeb.ui.components.stripe';

const ConnectedAccountSuccess: React.FC<{ group: Group }> = ({ group }) => {
    const { data: getGroupByIdData } = useGroupByIdQuery({
        variables: { _id: group?._id },
        fetchPolicy: 'network-only',
    });

    const _group = getGroupByIdData?.groupById;

    const { t } = useTranslation();
    const { closeModal, closeAllModals, newModal } = useModal();

    const isEvent = group?.subtype === CARD_EVENT;

    let headerMessage, successMessage: string;
    let showNotifyPanel: boolean = false;

    if (isEvent) {
        if (
            _group?.paymentSettings?.stripeAccount?.charges_enabled &&
            _group?.paymentSettings?.stripeAccount?.details_submitted &&
            _group?.paymentSettings?.stripeAccount?.payouts_enabled
        ) {
            headerMessage = t(
                `common:${localNamespace}.merchant_account_setup_success_event`,
                'Success! Your Merchant Account is setup and your event is live!'
            );
            successMessage = t(
                `common:${localNamespace}.merchant_account_event_ready`,
                'You are ready to start accepting payments for tickets!'
            );
            showNotifyPanel = true;
        } else if (
            _group?.paymentSettings?.stripeAccount !== null &&
            (!_group?.paymentSettings?.stripeAccount?.charges_enabled ||
                !_group?.paymentSettings?.stripeAccount?.details_submitted ||
                !_group?.paymentSettings?.stripeAccount?.payouts_enabled)
        ) {
            headerMessage = t(
                `common:${localNamespace}.merchant_account_setup_in_progress`,
                'Success! Your Merchant Account is currently being activated.'
            );
            successMessage = t(
                `common:${localNamespace}.merchant_account_event_in_progress_setup`,
                "The activation can take a few mins. We will notify you when it's ready!"
            );
        }
    } else {
        headerMessage = t(
            `common:${localNamespace}.merchant_account_setup_success`,
            'Great! Your Merchant Account is all setup.'
        );
        successMessage = t(
            `common:${localNamespace}.merchant_account_subscriptions_ready`,
            'You are ready to start accepting payments for subscriptions!'
        );
    }

    const showNotifyMembersModal = () => {
        closeModal();
        newModal(
            <NotifyPanel
                parentGroupId={group?.parentGroup?._id}
                groupId={group?._id}
                collectionType={Collection.Group}
            />
        );
    };

    return (
        <div className="edit-screen-content-view stripe-merchant-account">
            <div className="edit-screen-content-items">
                <div className="mc-source-post-wrapper connected-account-success">
                    <LottieLoading
                        height={125}
                        width={125}
                        lottieData={LottieAnimation}
                        customStyle={{ opacity: 1 }}
                        isPaused={false}
                        loop={false}
                    />
                    <h1>{headerMessage}</h1>
                    <p>{successMessage}</p>
                    <div>
                        <button onClick={closeAllModals} type="button">
                            {t(`common:${localNamespace}.close`, 'Close')}
                        </button>
                        {isEvent && showNotifyPanel && (
                            <button onClick={showNotifyMembersModal} type="button">
                                {t(`common:${localNamespace}.notify_members`, 'Notify Members')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectedAccountSuccess;
