import React, {
    KeyboardEventHandler,
    MouseEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { usePopper } from 'react-popper';

import { quantizeNumber } from '@helpers/numberHelpers';

type SliderProps = {
    id?: string;
    className?: string;
    value: number;
    onChange: (value: number) => void;
    min?: number;
    max?: number;
    step?: number;
    displayValue?: string;
    hideInlineDisplay?: boolean;
    hideTooltip?: boolean;
    disabled?: boolean;
    quantize?: boolean;
};

const getPercent = (value: number, min: number, max: number) => ((value - min) * 100) / (max - min);

const getValue = (percent: number, min: number, max: number) => (max - min) * percent + min;

/**
 * Slider
 * See https://www.figma.com/file/naUyXB9d4H9XG1tuFkgtbq/weLibrary-Design-Systems?node-id=982%3A5900
 */
const Slider: React.FC<SliderProps> = ({
    id,
    className = '',
    value,
    onChange,
    min = 0,
    max = 100,
    step = 1,
    displayValue,
    hideInlineDisplay = false,
    hideTooltip = false,
    disabled = false,
    quantize = false,
}) => {
    const [tooltip, setTooltip] = useState<HTMLElement | null>(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const slider = useRef<HTMLButtonElement>(null);
    const container = useRef<HTMLElement>(null);
    const thumb = useRef<HTMLDivElement>(null);
    const arrow = useRef<HTMLDivElement>(null);

    const { styles, attributes, update } = usePopper(thumb.current, tooltip, {
        placement: 'top',
        modifiers: [
            { name: 'eventListeners', options: { resize: true } },
            { name: 'preventOverflow', options: { rootBoundary: 'viewport' } },
            { name: 'arrow', options: { element: arrow.current } },
            { name: 'offset', options: { offset: [0, 18] } },
        ],
    });

    const onMouseMove = useCallback((event: MouseEvent) => {
        if (!container.current) return;

        event.preventDefault();

        const { clientX } = event;
        const { left, width } = container.current.getBoundingClientRect();

        const percent = (clientX - left) / width;

        const newValue = Math.max(Math.min(max, getValue(percent, min, max)), min);

        onChange(quantize ? quantizeNumber(newValue, step) : newValue);

        update?.();
    }, []);

    const onMouseEnd = useCallback(() => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('touchmove', onMouseMove);
        document.removeEventListener('mouseup', onMouseEnd);
        document.removeEventListener('touchend', onMouseEnd);

        setShowTooltip(false);
    }, []);

    const onMouseDown: MouseEventHandler<HTMLButtonElement> = event => {
        event.preventDefault();

        if (!disabled) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('touchmove', onMouseMove);
            document.addEventListener('mouseup', onMouseEnd);
            document.addEventListener('touchend', onMouseEnd);

            container.current?.parentElement?.focus();

            if (!hideTooltip) setShowTooltip(true);
        }
    };

    const onTouchMove = useCallback((event: TouchEvent) => {
        if (!container.current) return;

        const { clientX } = event.touches[0];
        const { left, width } = container.current.getBoundingClientRect();

        const percent = (clientX - left) / width;

        const newValue = Math.max(Math.min(max, getValue(percent, min, max)), min);

        onChange(quantize ? quantizeNumber(newValue, step) : newValue);

        update?.();
    }, []);

    const onTouchEnd = useCallback(() => {
        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', onTouchEnd);

        setShowTooltip(false);
    }, []);

    const onTouchStart = useCallback((event: TouchEvent) => {
        event.preventDefault();

        if (!disabled) {
            document.addEventListener('touchmove', onTouchMove);
            document.addEventListener('touchend', onTouchEnd);

            container.current?.parentElement?.focus();

            if (!hideTooltip) setShowTooltip(true);
        }
    }, []);

    const onKeyDown: KeyboardEventHandler<HTMLButtonElement | HTMLDivElement> = event => {
        if (event.key !== 'Tab') event.preventDefault();

        if (!disabled) {
            if (event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'PageUp')
                onChange(Math.min(value + step, max));

            if (event.key === 'ArrowLeft' || event.key === 'ArrowDown' || event.key === 'PageDown')
                onChange(Math.max(value - step, min));

            if (event.key === 'Home') onChange(min);

            if (event.key === 'End') onChange(max);

            if (event.key === ' ' && !hideTooltip) setShowTooltip(shown => !shown);

            if (event.key === 'Escape') setShowTooltip(false);

            update?.();
        }
    };

    useEffect(() => {
        // including this directly on the element via onTouchStart prop causes some odd passive
        // errors that I was only able to fix via adding the event listener manually like this
        slider.current?.addEventListener('touchstart', onTouchStart);

        return () => {
            slider.current?.removeEventListener('touchstart', onTouchStart);
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseEnd);
        };
    }, [slider.current, onTouchStart, onMouseMove, onMouseEnd]);

    return (
        <button
            ref={slider}
            onMouseDown={onMouseDown}
            onKeyDown={onKeyDown}
            onFocus={() => {
                if (!hideTooltip) setShowTooltip(true);
            }}
            onBlur={() => setShowTooltip(false)}
            disabled={disabled}
            type="button"
            aria-label="Slider"
            className={`dsc-forms-customInputs-slider ${className}`}
            id={id}
        >
            {!hideInlineDisplay && <output htmlFor={id}>{displayValue || value.toFixed(2)}</output>}

            <section ref={container}>
                <div role="presentation" className="track" />
                <div
                    role="presentation"
                    className="rail"
                    style={{ width: `${getPercent(value, min, max)}%` }}
                />
                <div
                    ref={thumb}
                    role="slider"
                    className="thumb"
                    style={{ left: `${getPercent(value, min, max)}%` }}
                    aria-valuenow={value}
                    aria-valuemin={min}
                    aria-valuemax={max}
                    aria-label="Slider Handle"
                >
                    <output
                        aria-hidden="true"
                        className={`tooltip ${showTooltip ? 'show' : ''}`}
                        ref={setTooltip}
                        role="presentation"
                        htmlFor={id}
                        style={styles.popper}
                        {...attributes.popper} // eslint-disable-line react/jsx-props-no-spreading
                    >
                        <span>{displayValue || value.toFixed(2)}</span>
                        <div
                            role="presentation"
                            ref={arrow}
                            style={styles.arrow}
                            className="arrow"
                        />
                    </output>
                </div>
            </section>
        </button>
    );
};

export default Slider;
