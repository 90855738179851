import React, { CSSProperties } from 'react';
import useMd from '@components/generic/hooks/useMd';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary';

type EditScreenItemContainerProps = {
    title: string;
    subtitle?: string;
    columnItem?: boolean;
    columnsBlock?: boolean;
    clearfix?: boolean;
    color?: string;
    renderToggleContainer?: React.ReactNode;
    showToggleContainer?: boolean;
    overrideContainerStyle?: CSSProperties | null;
    customLabelClass?: string;
    showSubtitleBelowChildren?: boolean;
    extraSubtitleClass?: string;
};

const EditScreenItemContainer: React.FC<EditScreenItemContainerProps> = ({
    title,
    subtitle,
    columnItem,
    columnsBlock,
    clearfix,
    color = '',
    renderToggleContainer,
    showToggleContainer,
    overrideContainerStyle,
    customLabelClass,
    children,
    showSubtitleBelowChildren = false,
    extraSubtitleClass = '',
}) => {
    const md = useMd();
    const subtitleMarkdown = subtitle ? md.render(subtitle) : '';

    const subtitleElement = subtitle ? (
        <div
            className={`edit-item-sublabel ${extraSubtitleClass || ''}`}
            dangerouslySetInnerHTML={{ __html: subtitleMarkdown }}
        />
    ) : undefined;

    const titleStyle = { color };
    const showHeader = title || subtitle;
    const labelClassname = customLabelClass || '';
    return (
        <ErrorBoundary>
            <div
                className={`${columnItem ? 'columns-item' : ''} edit-item-label`}
                style={overrideContainerStyle ?? undefined}
            >
                {showHeader && (
                    <div className={labelClassname} style={{ flexGrow: 1 }}>
                        <div className="edit-item-label-title" style={titleStyle}>
                            <div>
                                {title}
                                <br />
                            </div>
                        </div>
                        {!showSubtitleBelowChildren && subtitleElement}
                    </div>
                )}
                <div
                    className={`${clearfix ? 'w-clearfix' : ''} ${
                        columnsBlock ? 'columns-block' : ''
                    } edit-screen-form-block w-form`}
                >
                    {children}
                </div>
                <div className="edit-screen-toggle-container">
                    {showToggleContainer && renderToggleContainer ? renderToggleContainer : <></>}
                </div>
                {showSubtitleBelowChildren && subtitleElement}
            </div>
        </ErrorBoundary>
    );
};

export default EditScreenItemContainer;
