import React from 'react';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

const DisplayGroup = ({ title, description, thumbImage }) => {
    const [src, srcSet] = getBackgroundImageSetForUrl(
        thumbImage || getRandomDefaultCoverImage(title)
    );

    return (
        <figure className="group-summary">
            <StyledBackgroundImage
                className="thumbnail larger-thumbnail w-inline-block"
                src={src}
                srcSet={srcSet}
            />

            <figcaption className="w-inline-block">
                <h4 className="display-text-inline">{title}</h4>
                <p>{description}</p>
            </figcaption>
        </figure>
    );
};

export default DisplayGroup;
