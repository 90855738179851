import React from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
// import { LAUNCH_DARKLY_CONFIG } from './constants/launchDarkly';
// import * as ReactDOMClient from 'react-dom/client';
import { render } from 'react-dom';
import Amplify from '@aws-amplify/core';
import { SplashScreen } from '@capacitor/splash-screen';

import { config } from '@core/config/getConfig';

import App from './app';
import * as serviceWorker from './serviceWorker';

// import { defineCustomElements } from '@ionic/pwa-elements/loader';
import 'core-js/features/promise';
import 'core-js/features/array/at';
import awsconfig from '~/aws-config';
import InitializeI18N from './wl-startup/client/translations/initialize';

Amplify.configure(awsconfig?.amplify);

// const root = ReactDOMClient.createRoot(document.getElementById('app'));

InitializeI18N(async () => {
    // initialize & hide splash screen
    // root.render(<App />);

    SplashScreen.hide();

    const LDProvider = await asyncWithLDProvider(config?.public?.launchDarklyConfig);

    // We need to upgrade to react-router v6 before we can use strict mode
    render(
        <LDProvider>
            <App />
        </LDProvider>,
        document.getElementById('app')
    );
});

/* If you want your app to work offline and load faster, you can change
    unregister() to register() below. Note this comes with some pitfalls.
    Learn more about service workers: https://bit.ly/CRA-PWA
*/
serviceWorker.unregister();
