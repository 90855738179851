import React from 'react';
import { Link } from 'react-router-dom';

import CalendarDateDisplay from '@components/events/CalendarDateDisplay';
import EventPrivacyBubble from '@components/events/EventPrivacyBubble';
import EventTimeContext from '@components/events/EventTimeContext';
import Picture from '@components/generic/Picture';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

import { Group } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

type EventImageWithMetaDataProps = {
    group?: Maybify<Group> | null;
    className?: string;
};

const EventImageWithMetaData: React.FC<EventImageWithMetaDataProps> = ({
    group,
    className = '',
}) => {
    const firstEvent = group?.timeAndPlaceSettings?.[0] || {};

    const dates = group?.eventOverviewDetails?.dates;

    return (
        <section className={`event-image-with-metadata ${className}`}>
            <Link to={`/g/${group?._id}`} className="width-100">
                <Picture
                    disableLink
                    url={
                        group?.profile?.picture ||
                        getRandomDefaultCoverImage(group?.profile?.full_name ?? '')
                    }
                    className="event-image"
                />
            </Link>

            <CalendarDateDisplay date={firstEvent?.date} dates={dates} />

            <span className="event-group-info-bubble mobile">
                <EventPrivacyBubble group={group} />
                <EventTimeContext group={group} />
            </span>
        </section>
    );
};

export default EventImageWithMetaData;
