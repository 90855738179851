import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { debounce } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import TextInput from '@dsc/forms/textInputs/TextInput';
import TextArea from '@components/generic/forms/TextArea';
import ImageUpload from '@dsc/forms/customInputs/ImageUpload';
import Checkbox from '@dsc/forms/customInputs/Checkbox';

import { getRandomDefaultUserImage } from '@core/utilities/constants/defaults';
import { curriedStateSlice } from '@helpers/state/state.helpers';

import { VALIDATE_USER_EMAIL } from '@shared/welibrary-graphql/user/queries';
import { useCreateGuestUserMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import {
    EDIT_USER_VIEW,
    CREATE_USER_VIEW,
} from '@components/content/newpost/forms/EventGroup/AddSpeakerWidget';

import { SpeakerState, SpeakerStateType } from '@core/types/EventGroup';

import { UPDATED_EMAIL_REGEX } from '@core/utilities/constants/regexes.js';

import getLogger from '@core/logger';
import TextArea from '~/wl-web/ui/components/generic/forms/TextArea';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.pages.signup.stepOne';

type CreateUserFormProps = {
    handleCancel: () => void;
    handleSave?: (user: SpeakerStateType, type?: string) => void;
    title?: string;
    type?: typeof CREATE_USER_VIEW | typeof EDIT_USER_VIEW;
    initialState?: SpeakerStateType | null;
};

const CreateUserForm: React.FC<CreateUserFormProps> = ({
    handleSave,
    handleCancel,
    title = 'Create Speaker',
    type = CREATE_USER_VIEW,
    initialState,
}) => {
    const { t } = useTranslation();
    const [createNewUser] = useCreateGuestUserMutation();
    const [state, setState] = useImmer<SpeakerStateType>(
        initialState ?? {
            _id: `new-speaker-${uuidv4()}`,
            name: '',
            email: '',
            bio: '',
            profileThumb: { image: '' },
            useUserBio: false,
        }
    );

    const [errors, setErrors] = useImmer<Record<string, string[]>>({});

    const updateSlice = curriedStateSlice(setState);

    const [validateUserEmail, { data: validateEmailData, loading: validateEmailLoading }] =
        useLazyQuery(VALIDATE_USER_EMAIL, {
            fetchPolicy: 'network-only',
        });

    useEffect(() => {
        if (validateEmailLoading) {
            setErrors({
                ...errors,
                email: t(`common:${localNamespace}.checking_email`),
            });
            return;
        }
        if (validateEmailData?.validateUserEmail === false) {
            setErrors({
                ...errors,
                email: t(`common:${localNamespace}.email_already_exists`),
            });
        } else {
            setErrors({});
        }
    }, [validateEmailData, validateEmailLoading]);

    const validateEmailFormat = (email: string | undefined = state.email) =>
        UPDATED_EMAIL_REGEX.test(String(email).toLowerCase());

    const validateEmail = (email: string | undefined = state.email) => {
        if (email) {
            if (validateEmailFormat(email)) {
                setErrors({
                    ...errors,
                    email: t(`common:${localNamespace}.checking_email`),
                });
                validateUserEmail({ variables: { email } });
            } else {
                setErrors({
                    ...errors,
                    email: t(`common:${localNamespace}.invalid_email`),
                });
            }
        }
    };

    const debouncedValidateEmail = useCallback(debounce(validateEmail, 300), []);

    const emailChangeHandler = (value: string) => {
        setErrors({
            ...errors,
            email: '',
        });
        updateSlice('email', value);
        if (type === CREATE_USER_VIEW) {
            debouncedValidateEmail(value);
        }
    };

    const clearInputs = () =>
        setState({ name: '', email: '', bio: '', profileThumb: { image: '' } });

    const handleSubmit = async () => {
        const { name, email, profileThumb, bio, userBio, useUserBio } = state;
        try {
            const validatedUser = SpeakerState.safeParse({
                name: name ?? '',
                bio: (useUserBio ? userBio : bio) ?? '',
                profileThumb: { image: profileThumb?.image ?? '' },
                email,
            });

            if (validatedUser.success) {
                if (type === CREATE_USER_VIEW) {
                    const newUserPayload = {
                        username: name,
                        profile: { vs: '1.0.0', picture: profileThumb?.image, bio },
                        membership: {},
                        email: email?.toLowerCase(),
                    };
                    const { data } = await createNewUser({
                        variables: { input: newUserPayload, skipEmail: true },
                        fetchPolicy: 'no-cache',
                    });

                    if (data?.createGuestUser) {
                        const { _id, bio, email, picture, username } = data.createGuestUser;
                        const newSpeaker = {
                            _id,
                            name: username,
                            bio,
                            profileThumb: { image: picture },
                            email,
                        };
                        handleSave?.(newSpeaker as SpeakerStateType, type);
                        handleCancel?.(); // reset form
                    }
                }

                if (type === EDIT_USER_VIEW) {
                    handleSave?.(state, type);
                    handleCancel?.(); // reset form
                }
            } else logger.error('Error validating new user', validatedUser.error);
        } catch (err) {
            if (err?.message?.includes('Email already exists')) {
                setErrors({ duplicateEmail: ['Email already exists'] });
            }
        } finally {
            clearInputs();
        }
    };

    return (
        <section className="create-user-form">
            <h3>{title}</h3>
            <p>{t(`common:${localNamespace}.fields_required`, 'Fields marked * are required')}</p>
            <div className="profile-thumb-upload">
                <ImageUpload
                    state={{
                        ...state.profileThumb,
                        image: state.profileThumb.image || getRandomDefaultUserImage(),
                    }}
                    setState={updateSlice('profileThumb')}
                    className="small-circle-thumb-upload"
                    loaderSize={100}
                />
            </div>

            <TextInput
                onChange={updateSlice('name')}
                error={errors?.username?.length > 0}
                helperText={errors?.username?.[0]}
                type="text"
                label={t(`common:${localNamespace}.full_name`, 'Full Name')}
                name="name"
                id="name"
                aria-labelledby="username-label"
                required
                value={state.name ?? ''}
            />

            {type === CREATE_USER_VIEW && (
                <TextInput
                    onChange={emailChangeHandler}
                    error={errors?.email?.length > 0}
                    helperText={errors?.email}
                    type="text"
                    label={t(`common:${localNamespace}.email`, 'Email')}
                    name="email"
                    id="email"
                    aria-labelledby="email-label"
                    required
                    value={state.email ?? ''}
                />
            )}

            {type === EDIT_USER_VIEW && (
                <div className="default-user-bio-wrapper">
                    <div className="default-user-bio-header">
                        <h4>{t(`common:${localNamespace}.user_bio`, 'User Bio')}</h4>
                        <label
                            htmlFor="user-bio-checkbox"
                            className="generic-checkbox user-bio-checkbox"
                        >
                            <Checkbox
                                id="user-bio-checkbox"
                                value={state?.useUserBio}
                                onChange={updateSlice('useUserBio')}
                            />
                            <span>
                                {t(`common:${localNamespace}.use_default_bio`, 'Use Default Bio')}
                            </span>
                        </label>
                    </div>
                    {state?.useUserBio && <div className="default-user-bio">{state.userBio}</div>}
                </div>
            )}
            {!state?.useUserBio && (
                <TextArea
                    className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px] !h-[130px] !overflow-auto"
                    countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[12px]"
                    wrapperClassName="relative w-full"
                    defaultAutoResizeClasses=""
                    autoResize
                    helperText=""
                    onInput={updateSlice('bio')}
                    placeholder={
                        type === EDIT_USER_VIEW
                            ? ''
                            : t(`common:${localNamespace}.short_bio`, 'Short Bio')
                    }
                    name="bio"
                    id="bio"
                    maxCharacter={255}
                    aria-labelledby="shortBio-label"
                    multiline
                    value={state.bio ?? ''}
                />
            )}

            {errors?.duplicateEmail && (
                <p className="err-msg small" style={{ marginTop: '10px' }}>
                    {errors?.duplicateEmail}
                </p>
            )}

            <section className="bottom">
                <button
                    type="button"
                    aria-label="Cancel"
                    className="rounded-pill-btn bordered animate-grow"
                    onClick={handleCancel}
                >
                    {t(`common:${localNamespace}.cancel`, 'Cancel')}
                </button>
                <button
                    type="button"
                    aria-label="Save"
                    className="rounded-pill-btn bordered animate-grow"
                    onClick={handleSubmit}
                >
                    {t(`common:${localNamespace}.save`, 'Save')}
                </button>
            </section>
        </section>
    );
};

export default CreateUserForm;
