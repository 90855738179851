import React from 'react';

import searchStore from '@stores/Search';

import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';
import { displayNames } from '@components/search/constants';

// const localNamespace = 'imports.wlWeb.ui.components.search.SearchOverlay';

const { defaultSearchScope } = getWhitelabelPublicSettings();

type SearchScopeProps = {
    handleClose: () => void;
};

const SearchScope: React.FC<SearchScopeProps> = ({ handleClose }) => {
    const currentScope = searchStore.use.currentScope();

    const _type = currentScope?.type;
    const renderType = displayNames[_type];
    const title = currentScope?.title;
    const handleClick = () => {
        handleClose();
    };

    // If default scope is the current scope, update style to remove the 'X'
    const scopeClassModifier =
        currentScope?.scope?.groupId === defaultSearchScope?.scope?.groupId ? 'default-scope' : '';

    if (!currentScope) {
        return <></>;
    }

    return (
        <div className="pinned-search-container">
            <div className="pinned-search-content">
                <button
                    type="button"
                    onClick={handleClick}
                    className={`pinned-search-content-left ${scopeClassModifier}`}
                >
                    {renderType}
                </button>
                <span className="pinned-search-title">{title}</span>
            </div>
        </div>
    );
};

export default SearchScope;
