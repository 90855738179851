import React from 'react';

const Analytics: React.FC<{ className?: string }> = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 24 24">
        <line
            x1="3"
            y1="22"
            x2="3"
            y2="14"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <line
            x1="9"
            y1="22"
            x2="9"
            y2="12"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <line
            x1="15"
            y1="22"
            x2="15"
            y2="8"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <line
            x1="21"
            y1="22"
            x2="21"
            y2="2"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export default Analytics;
