import { ConfigType } from '@config/schemas/main';

const applySettingsTheme = (publicSettings: ConfigType['public']) => {
    if (publicSettings && publicSettings.theme) {
        const {
            primaryColor,
            primaryAccent,
            primaryHighlight,
            primaryShade,
            thumbHighlight,
            secondaryColor,
            hoverColor,
            slideBackground,
            emptyBackground,
            coffeeBackground,
            docBackground,
        } = publicSettings.theme;

        const html = document.getElementsByTagName('html')[0];

        if (primaryColor) html.style.setProperty('--color-wl-primary', primaryColor);

        if (primaryAccent) {
            html.style.setProperty('--color-wl-primary-accent', primaryAccent);
        }

        if (primaryHighlight) {
            html.style.setProperty('--color-wl-primary-highlight', primaryHighlight);
        }

        if (primaryShade) html.style.setProperty('--color-wl-primary-shade', primaryShade);

        if (thumbHighlight) {
            html.style.setProperty('--color-wl-thumb-highlight', thumbHighlight);
        }

        if (secondaryColor) html.style.setProperty('--color-wl-secondary', secondaryColor);

        if (hoverColor) html.style.setProperty('--color-wl-hover', hoverColor);

        if (slideBackground) {
            html.style.setProperty('--slidemenu-wl-background', slideBackground);
        }

        if (emptyBackground) {
            html.style.setProperty('--empty-wl-background', emptyBackground);
        }

        if (coffeeBackground) {
            html.style.setProperty('--coffee-wl-background', coffeeBackground);
        }

        if (docBackground) html.style.setProperty('--doc-wl-background', docBackground);
    }
};

export default applySettingsTheme;
