import React from 'react';

import shoppingCartStore from '@web/ui/stores/ShoppingCart';
import { isEventFree } from '@web/utilities/helpers/events/event.helpers';

import { Group } from '@shared/welibrary-graphql/types';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';
import { getCurrencyDisplayString } from '~/wl-web/utilities/helpers/money/money.helpers';

const OrderTotal: React.FC<{
    total: number | string;
    originalPrice: number | string;
    group: Group;
    className?: string;
    showLabel?: boolean;
}> = ({ total, originalPrice, group, className, showLabel = true }) => {
    const displayTotal = parseFloat(total.toString()).toFixed(2);

    const _isEventFree = isEventFree(group);

    const shoppingCart = shoppingCartStore?.useTracked?.shoppingCarts()?.find(({ groupId }) => {
        return groupId === group?._id;
    });
    const shoppingCartProducts = shoppingCart?.products?.filter(({ product }) => {
        if (_isEventFree) return product?.type === PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP;

        return product?.type === PRODUCT_TYPES?.GROUP_MEMBERSHIP;
    });

    const applyCoupon =
        shoppingCartProducts?.find(({ coupon }) => coupon?.isApplied === true)?.coupon?.isApplied ??
        false;

    const currency = group?.products?.[0]?.currency ?? 'USD';

    const originalPriceDisplayString = getCurrencyDisplayString(
        originalPrice,
        currency,
        undefined,
        true,
        2
    );
    const totalDisplayString = getCurrencyDisplayString(displayTotal, currency, undefined, true, 2);

    return (
        <div className={`stripe-checkout-total-wrap ${className}`}>
            {showLabel && <h5>Total Amount</h5>}

            <div className="discounted-total">
                <span className={`old-total ${applyCoupon ? 'strikethrough' : ''}`}>
                    {applyCoupon ? originalPriceDisplayString : totalDisplayString}
                </span>
                <span className={`new-total ${applyCoupon ? '' : 'hidden'}`}>
                    {totalDisplayString}
                </span>
            </div>
        </div>
    );
};

export default OrderTotal;
