import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import QRCode from 'qrcode.react';

import { useCurrentUser } from '@stores/User';
import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';
import {
    getNamedRefsFromArray,
    getCarouselFunctions,
    getNavFunctions,
} from '@helpers/navigation/horizontalNav.helpers';
import useModal from '@components/modals/hooks/useModal';

import Picture from '@components/generic/Picture';
import ChevronRight from '@web/ui/dsc/svgs/ChevronRight';
import ChevronLeft from '@web/ui/dsc/svgs/ChevronLeft';
import HorizontalNavHeader from '@web/ui/dsc/navigation/HorizontalNavHeader';
import TicketSharingContainer from '@web/ui/components/card/Shopping-Cart/TicketSharing/TicketSharingContainer';
import { RoundedCheckmark } from '@dsc/svgs/Checkmark';

import {
    getRandomDefaultUserImage,
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import { getRootUrl } from '@core/utilities/whitelabel_helpers';
import { getFormattedEventTimes, getFormattedEventDates } from '@core/utilities/constants/events';
// import { Product } from '@shared/welibrary-graphql/types';
import { ModalTypes } from '@core/types/Modals';
import { ProductInstance } from '@shared/welibrary-graphql/types';

type TicketSlideProps = {
    ticket: ProductInstance;
};

export enum TransferTicketStateEnum {
    AllowTransfer = 'allow_transfer',
    DisableTransfer = 'disable_transfer',
    RemoveTransfer = 'remove_transfer',
}

const TicketSlide = React.forwardRef<HTMLDivElement, TicketSlideProps>(function TicketSlide(
    { ticket },
    ref
) {
    const { currentUser } = useCurrentUser();

    const { product, isCheckedIn } = ticket;
    const event = product?.reference;

    const [coverSrc, coverSrcSet] = getBackgroundImageSetForUrl(
        event?.profile?.coverPhoto || getRandomDefaultCoverImage(event?.profile?.full_name)
    );
    const thumbnail = currentUser?.profile?.picture ?? getRandomDefaultUserImage();

    // ! pulls data from 1st event
    const { displayEndTime, displayStartTime } = event?.eventOverviewDetails || {};
    const dates = event?.eventOverviewDetails?.dates;
    const _date =
        dates && dates?.length > 0 && getFormattedEventDates(event?.eventOverviewDetails?.dates[0]);

    const ticketQRCodeValues = `https://${getRootUrl()}/g/${event?._id}?scan=true&ticketId=${ticket?._id}&groupId=${event?._id}`;

    return (
        <div className={`ticket-item ${isCheckedIn ? 'checked-in' : ''}`} ref={ref}>
            <div className="ticket-item-notch-circle" />

            <div className="ticket-item-header">
                <div className="ticket-item-user-img-wrap">
                    <Picture url={thumbnail} />
                </div>
                <div className="ticket-item-name-wrap">
                    <h4 className="ticket-item-product-name">{product?.name}</h4>
                    <h5 className="ticket-item-event-name">{event?.profile?.full_name}</h5>
                    <p className="ticket-item-ticket-id">ticket#{ticket?._id}</p>
                </div>
            </div>
            <div className="ticket-item-group-img-wrap">
                <StyledBackgroundImage
                    disableLink
                    src={coverSrc}
                    srcSet={coverSrcSet}
                    className="ticket-item-group-img"
                />
            </div>
            <div className="ticket-item-qr-code-container">
                <div className="ticket-item-qr-code-wrap">
                    <QRCode value={ticketQRCodeValues} renderAs="svg" />
                    {isCheckedIn && (
                        <div className="absolute flex flex-col gap-[20px]">
                            <div className="bg-primary-default mx-auto flex w-fit items-center justify-center rounded-full p-[20px] text-white">
                                <RoundedCheckmark className="checkmark" />
                            </div>
                            <span className="text-grayscale-body text-[24px]">Checked in</span>
                        </div>
                    )}
                </div>
            </div>
            <div className="ticket-item-date-container">
                <div className="ticket-item-date-wrap">
                    <h3 className="ticket-item-date">{_date}</h3>
                    <h4 className="ticket-item-time">
                        {(displayStartTime || displayEndTime) && (
                            <>{getFormattedEventTimes(event?.eventOverviewDetails)}</>
                        )}
                    </h4>
                </div>
            </div>
        </div>
    );
});

const MultiTicketSlider: React.FC<{
    tickets: ProductInstance[];
    handleShoppingCart?: () => void;
}> = ({ tickets, handleShoppingCart }) => {
    const history = useHistory();
    const { currentUser } = useCurrentUser();
    const {
        newModal: centerModal,
        closeModal,
        closeAllModals,
    } = useModal({
        desktop: ModalTypes.Center,
        mobile: ModalTypes.Center,
    });

    const [activeTicket, setActiveTicket] = useState<string>(tickets[0]?._id);

    const ticketRefIds: string[] = tickets.map(_t => _t._id);

    const container = useRef<HTMLDivElement>(null);
    const refs = getNamedRefsFromArray<string, HTMLDivElement>(ticketRefIds);
    const pages = Object.keys(refs).map(name => ({
        name,
        canSkip: false,
        showMainButton: false,
    }));

    const { on, scrollTo } = useHorizontalPages({ refs, options: { root: container.current } });

    const { current, isFirst, isLast, scrollToNext, scrollToPrevious } = getCarouselFunctions({
        on,
        scrollTo,
    });

    const { currentPage } = getNavFunctions({
        on,
        scrollTo,
        pages,
    });

    // Scroll to first ticket on load
    useEffect(() => {
        scrollTo[activeTicket](false);
    }, []);

    // Keep active ticket in sync with shown ticket
    useEffect(() => {
        setActiveTicket(current);
    }, [current]);

    const handleTransferTicketModal = () => {
        const transferableTickets =
            tickets.filter(t => {
                return t?.purchaser?._id === currentUser?._id && t?.isCheckedIn !== true;
            }) ?? [];

        centerModal(<TicketSharingContainer tickets={transferableTickets} />);
    };

    const isSingleTicket = tickets.length === 1;

    const getCurrentTicketIndex = (): number => {
        const currentIndex: number = tickets.findIndex(t => t._id === current);
        return currentIndex + 1 ?? 1;
    };

    const canTransferTicket = (): TransferTicketStateEnum => {
        const activeTicketSlide = tickets.find(t => t._id === current);

        if (activeTicketSlide?.isCheckedIn) {
            return TransferTicketStateEnum.RemoveTransfer;
        }

        if (
            activeTicketSlide?.transferToken &&
            activeTicketSlide.owner?._id === activeTicketSlide.purchaser?._id &&
            activeTicketSlide.purchaser?._id === currentUser?._id
        ) {
            return TransferTicketStateEnum.AllowTransfer;
        }

        if (
            !activeTicketSlide?.transferToken &&
            currentUser?._id === activeTicketSlide?.purchaser?._id
        ) {
            return TransferTicketStateEnum.DisableTransfer;
        }

        if (
            !activeTicketSlide?.transferToken &&
            currentUser?._id !== activeTicketSlide?.purchaser &&
            activeTicketSlide?.purchaser !== activeTicketSlide?.owner?._id
        ) {
            return TransferTicketStateEnum.RemoveTransfer;
        }

        return TransferTicketStateEnum.RemoveTransfer;
    };

    let transerTicketButton;

    if (canTransferTicket() === TransferTicketStateEnum.AllowTransfer) {
        transerTicketButton = (
            <button
                type="button"
                className="ticket-item-list-btn-transfer"
                onClick={handleTransferTicketModal}
            >
                Transfer
            </button>
        );
    } else if (canTransferTicket() === TransferTicketStateEnum.DisableTransfer) {
        transerTicketButton = (
            <button type="button" className="ticket-item-list-btn-transfer" disabled>
                Transferred
            </button>
        );
    } else if (canTransferTicket() === TransferTicketStateEnum.RemoveTransfer) {
        transerTicketButton = null;
    } else {
        transerTicketButton = null;
    }

    return (
        <section
            className={`ticket-item-list-container ${isSingleTicket ? 'ticket-item-list-container-single' : ''
                }`}
        >
            {!isSingleTicket && (
                <HorizontalNavHeader
                    currentPage={currentPage}
                    pages={pages}
                    skipPage={() => { }}
                    className="ticket-item-horizontal-nav-header"
                />
            )}
            {!isSingleTicket && (
                <div className="ticket-item-counter-container">
                    {getCurrentTicketIndex()} of {tickets.length}
                </div>
            )}
            {!isFirst && (
                <button
                    type="button"
                    onClick={() => scrollToPrevious()}
                    className="ticket-item-list-btn-prev"
                >
                    <ChevronLeft strokeWidth="10" />
                </button>
            )}
            <div
                className={`ticket-item-list ${isSingleTicket ? 'ticket-item-list-single' : ''}`}
                ref={container}
            >
                {tickets.map(_ticket => {
                    return (
                        <TicketSlide ref={refs[_ticket._id]} ticket={_ticket} key={_ticket._id} />
                    );
                })}
            </div>
            {!isLast && (
                <button
                    type="button"
                    onClick={() => scrollToNext()}
                    className="ticket-item-list-btn-next"
                >
                    <ChevronRight strokeWidth="10" />
                </button>
            )}
            <div className="ticket-item-list-btn-wrap">
                <button
                    type="button"
                    className="ticket-item-list-btn-view"
                    onClick={() => {
                        if (handleShoppingCart) {
                            closeAllModals();
                            setTimeout(() => {
                                handleShoppingCart?.();
                            }, 300);
                        } else {
                            // redirect to event
                            history.push(
                                `/g/${tickets[0].product?.reference?._id}?showShoppingCart=true`
                            );
                            closeModal();
                        }
                    }}
                >
                    Buy More
                </button>
                {transerTicketButton}
            </div>
        </section>
    );
};

export default MultiTicketSlider;
