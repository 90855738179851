import React from 'react';
import { useTranslation } from 'react-i18next';

import EditScreenItemContainer from '@web/ui/components/generic/editscreen/EditScreenItemContainer';
import ThumbnailAndCover from '@web/ui/components/generic/editscreen/ThumbnailAndCover';
import TextareaAutosize from 'react-textarea-autosize';

const localNamespace = 'imports.wlWeb.ui.components.card.darkForms.chapterInfo';

export default props => {
    const { t } = useTranslation();

    let { getFeatureConstraintsForField } = props;

    return (
        <div
            data-w-tab="Info"
            className="edit-screen-tab-container w-tab-pane w--tab-active"
            style={{
                opacity: 1,
                transition: 'opacity 300ms ease 0s',
                paddingBottom: '25px',
            }}
        >
            {getFeatureConstraintsForField('prompt') && (
                <EditScreenItemContainer title="Prompt" noBottomBorder>
                    <div className="mc-source-text-container">
                        <div className="edit-post-text-container textarea-autosize small w-form">
                            {/*<div className="edit-item-label header">Title<br/></div>*/}
                            <TextareaAutosize
                                autoComplete="off"
                                id={'prompt'}
                                name={'prompt'}
                                onChange={e => props.handleChange(e)}
                                value={props.state.prompt}
                                className="new-post-field default w-input"
                                maxLength={256}
                                style={{
                                    resize: 'none',
                                    paddingTop: '7px',
                                    overflow: 'hidden',
                                }}
                                placeholder={t(`common:${localNamespace}.prompt_for_action`)}
                            />
                        </div>
                    </div>
                </EditScreenItemContainer>
            )}
            {(getFeatureConstraintsForField('mediaThumb') ||
                getFeatureConstraintsForField('mediaIcon')) && (
                <EditScreenItemContainer title={t(`common:${localNamespace}.thumbnail_and_cover`)}>
                    <ThumbnailAndCover
                        coverImage={props.state.mediaThumb}
                        thumbImage={props.state.mediaIcon}
                        coverTarget={
                            getFeatureConstraintsForField('mediaThumb') ? 'mediaThumb' : null
                        }
                        thumbTarget={
                            getFeatureConstraintsForField('mediaIcon') ? 'mediaIcon' : null
                        }
                        title={props.state.title}
                        handleUpdateState={props.handleUpdateState}
                        coverStyle={{ height: '100%' }}
                        thumbStyle={null}
                    />
                </EditScreenItemContainer>
            )}
            {getFeatureConstraintsForField('description') && (
                <EditScreenItemContainer title="Description" noBottomBorder>
                    <div className="mc-source-text-container">
                        <div className="edit-post-text-container textarea-autosize w-form">
                            {/*<div className="edit-item-label header">Title<br/></div>*/}
                            <TextareaAutosize
                                autoComplete="off"
                                id={'description'}
                                name={'description'}
                                onChange={e => props.handleChange(e)}
                                value={props.state.description}
                                className="new-post-field default w-input"
                                maxLength={470}
                                style={{
                                    resize: 'none',
                                    paddingTop: '7px',
                                    overflow: 'hidden',
                                }}
                                placeholder={t(`common:${localNamespace}.short_summary`)}
                            />
                        </div>
                    </div>
                </EditScreenItemContainer>
            )}
            <div className="edit-footer">
                <div
                    onClick={props.handleSubmit}
                    className="button callout-button w-button edit-screen-content"
                >
                    {t(`common:global.verbs.save`)}
                </div>
            </div>
        </div>
    );
};
