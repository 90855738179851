/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
import React from 'react';

import _ from 'lodash';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    getRandomDefaultThumbImage,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';
import { CARD_BOOK, CARD_CHAPTER } from '@core/utilities/constants/card_types.js';

const SearchItem = React.memo(function SearchItemMemo(props) {
    const {
        activeGroupId,
        alias,
        deleteAliasItem,
        source,
        moveItem,
        title,
        subtitle,
        color,
        onSelect,
        selectOptions,
        prioritizeActiveGroup,
        inverted,
        useCancel,
        noBottomBorder,
        checkbox,
    } = props;

    const { theme } = getWhitelabelPublicSettings();

    let _title = title;
    let _description;
    let _refInfo;
    let _thumbImage;
    let _coverImage;
    let _refSource;
    let _groupSource;
    switch (source.__typename ? source.__typename : source.type) {
        case 'Group':
            _title = source.profile ? source.profile.full_name : '';
            _description = source.profile ? source.profile.short_bio : '';
            _thumbImage = source.profile.picture
                ? source.profile.picture
                : getRandomDefaultCoverImage(_title);
            break;
        case 'User':
            _title = source.profile ? source.profile.full_name : '';
            _description = source.profile ? source.profile.short_bio : '';
            _thumbImage = source.profile.picture
                ? source.profile.picture
                : getRandomDefaultUserImage();
            break;
        case 'ContentCard':
            const sourceRef = source.reference ? source.reference : {};
            const sourceMedia = source.media ? source.media : {};
            _thumbImage = sourceMedia.thumb
                ? sourceMedia.thumb
                : sourceRef.coverImage
                ? sourceRef.coverImage
                : getRandomDefaultThumbImage(source.title);
            _coverImage = sourceMedia.icon
                ? sourceMedia.icon
                : sourceRef.coverImage
                ? sourceRef.coverImage
                : getRandomDefaultCoverImage(source.title);
            _title = source.title;
            _description = source.body
                ? source.body
                : _.isString(source.description)
                ? source.description.substring(0, 30)
                : '';
            if (source.type === CARD_CHAPTER) {
                if (source.prompt) _description = source.prompt;
                if (!_.isEmpty(source.reference?.bookSource)) {
                    _refSource = {
                        title: source.reference?.bookSource?.title,
                        url: source.reference?.bookSource?.url,
                    };
                }
                if (!_.isEmpty(source.reference?.groupSource)) {
                    _groupSource = {
                        name: source.reference?.groupSource?.profile?.full_name,
                        id: source.reference?.groupSource?._id,
                    };
                }
            }

            if (source.type === CARD_BOOK) {
                if (!_.isEmpty(source.reference?.groupSource)) {
                    _groupSource = {
                        name: source.reference?.groupSource?.profile?.full_name,
                        id: source.reference?.groupSource?._id,
                    };
                }
            }

            break;
        case 'Tag':
            _title = source.label ? `#${source.label}` : '-';
            _description = '';
            break;
        default:
            break;
    }

    const getGroupSourceColor = () => {
        if (
            _groupSource &&
            activeGroupId &&
            activeGroupId === _groupSource.id &&
            prioritizeActiveGroup
        ) {
            // Return the primary color if the search item is from the active group
            // and we're sorting results to prioritize items from the active group.
            // More details: https://welibrary.atlassian.net/jira/software/projects/WE/issues/WE-2877
            return theme?.primaryColor || null;
        }
        return null;
    };

    let containerStyle = {
        width: '100%',
        borderBottom: noBottomBorder ? 'none' : '',
    };

    if (_coverImage) {
        // const bgImageStyle = getBackgroundImageWithGradient(_coverImage, 0.3, 0.5);
        containerStyle.color = 'white';
        containerStyle.fontWeight = '500';
        containerStyle.justifyContent = 'space-between';
        containerStyle.alignItems = 'center';
        containerStyle.paddingRight = '5px';
        containerStyle = { ...containerStyle };
    }

    let idMatch, disabled;

    if (checkbox) {
        idMatch = source._id === moveItem._id;
        disabled = !idMatch && !_.isEmpty(moveItem);
    }

    const handleClick = e => {
        e.preventDefault();
        props.handleItem(source);
    };

    const selectBlock = checkbox ? (
        <div className="list-item-right">
            <div className="list-item-checkbox">
                <label className="move-item-label">
                    <input type="checkbox" checked={idMatch} disabled={disabled} />
                    <span className="checkmark checkbox-right" />
                </label>
            </div>
        </div>
    ) : (
        <div
            id="home-header-switcher"
            className="content-button-icon display-text-inline whiteplus"
        />
    );

    const [src, srcSet] = getBackgroundImageSetForUrl(_thumbImage);

    return (
        <div
            onClick={alias ? () => deleteAliasItem(alias) : handleClick}
            className={`search-list-item ${inverted ? 'inverted' : ''} ${
                useCancel ? 'minus' : ''
            } w-inline-block fadein ${alias ? 'added' : ''}`}
            style={containerStyle}
        >
            <StyledBackgroundImage className="search-list-thumb" src={src} srcSet={srcSet}>
                <div className="square-spacer" />
            </StyledBackgroundImage>
            <div className="search-list-item-content">
                <div className="display-text-inline">
                    <p>
                        <strong>{_title}</strong> {_refSource ? `in ${_refSource?.title}` : null}
                    </p>
                    <p style={{ color: getGroupSourceColor() }}>
                        {_groupSource && _groupSource?.name ? `from ${_groupSource?.name}` : null}
                    </p>
                    <p>
                        {_description && '|' && (
                            <span className="smaller-text">{_description}</span>
                        )}{' '}
                    </p>
                </div>
            </div>
            {selectBlock}
        </div>
    );
});

export default SearchItem;
