import React from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import AlertToast from '@components/toast/AlertToast';
import IntroContent from '@web/ui/components/generic/IntroContent';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import GroupMemberItem from '@web/ui/components/group/groupdashboard/GroupMembers/GroupMemberItem';
import MobileMenuList from '@web/ui/components/modals/MobileMenuList';

import { useRefundAndCancelGroupSubscriptionMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import { useCurrentUser } from '@stores/User';
import { useToastActionsContext } from '@components/toast/NewToastContext';
import useGroupActions from '@web/ui/components/group/hooks/useGroupActions.js';
import useModal from '@web/ui/components/modals/hooks/useModal';
import useConfirmation from '@components/generic/hooks/useConfirmation';

import { defaultAlertToastOptions } from '@core/types/Toast';
import { isGroupSubscription } from '@components/group/groupdashboard/groupDashboardHelpers';

import { Group, Maybe, PaginatedUsers, User } from '@shared/welibrary-graphql/types';
import { MenuListItem } from '@core/types/Menus';
import { ModalTypes } from '@core/types/Modals';
import {
    GET_USER_ORDERS_FOR_GROUP,
    GET_GROUP_DASHBOARD,
} from '@shared/welibrary-graphql/user/queries';

import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/square-loader.json';

import getLogger from '@core/logger';
import PRODUCT_TYPES from '../../../content/newpost/forms/ShoppingCartProducts/constants';

const logger = getLogger(module);

type GroupMembersListProps = {
    group: Group;
    groupMembers?: Maybe<PaginatedUsers>;
    onNetworkSearchAction: () => void;
    canUserInviteOthers: Maybe<string | boolean | undefined>;
    handleLinkSharing: () => void;
};

const GroupMembersList = React.forwardRef<HTMLUListElement, GroupMembersListProps>(
    (
        { group, groupMembers, onNetworkSearchAction, canUserInviteOthers, handleLinkSharing },
        ref
    ) => {
        const { t } = useTranslation();
        const { newModal, closeAllModals } = useModal({ desktop: ModalTypes.None });
        const { newToast } = useToastActionsContext();
        const confirm = useConfirmation();

        const localNamespace =
            group?.subtype === 'event'
                ? 'imports.wlWeb.ui.components.group.eventDashboard'
                : 'imports.wlWeb.ui.components.group.groupDashboard';

        const declaration =
            group?.subtype === 'event'
                ? t('global.introContentErr.no_attendees.declaration')
                : t('global.introContentErr.no_members.declaration');
        const instruction =
            group?.subtype === 'event'
                ? t('global.introContentErr.no_attendees.instruction')
                : t('global.introContentErr.no_members.instruction');

        const history = useHistory();
        const { currentUser } = useCurrentUser();

        const client = useApolloClient();
        const { homeButton } = getWhitelabelPublicSettings();

        const [refundAndCancelSubscription, { loading }] =
            useRefundAndCancelGroupSubscriptionMutation();

        const isHomeGroup = homeButton?.groupId === group?._id;

        const { addUserAsAdmin, removeUser } = useGroupActions({ group });

        const isUserAdminOfGroup = group.currentUserIsAdmin ? group.currentUserIsAdmin : false;

        const openQuickMenu = (items: MenuListItem[]) =>
            newModal(<MobileMenuList menuList={items} showIcons />, { className: 'no-min-height' });

        const getQuickMenuActionsForMember = (source: Maybe<User>) => {
            const userOwnsFreeSubscription = source?.doesUserOwnFreeSubscriptionInGroup;

            let actions: MenuListItem[] = [];
            if (isUserAdminOfGroup) {
                actions = [
                    {
                        id: 'make-admin',
                        iconKey: 'Make group admin',
                        title: t(`common:${localNamespace}.make_group_admin`),
                        onClick: async () => {
                            if (
                                source &&
                                (await confirm({
                                    text: t(`common:${localNamespace}.confirm_make_admin`, {
                                        profileName: source.profile?.full_name ?? '',
                                    }),
                                }))
                            ) {
                                addUserAsAdmin?.(source._id).then(() => {
                                    onNetworkSearchAction();
                                });
                            }
                        },
                    },
                    {
                        id: 'remove-from-group',
                        iconKey: 'Remove from group',
                        title:
                            isGroupSubscription(group) &&
                            source?.doesUserOwnMembershipInGroup &&
                            !userOwnsFreeSubscription
                                ? 'Cancel Subscription'
                                : t(
                                      `common:${localNamespace}.remove_from_group`,
                                      'Remove from group'
                                  ),
                        onClick: async () => {
                            if (
                                source &&
                                source?.doesUserOwnMembershipInGroup &&
                                isGroupSubscription(group) &&
                                !userOwnsFreeSubscription
                            ) {
                                if (
                                    await confirm({
                                        text: `Are you sure want to cancel ${source?.profile?.full_name}'s subscription? The user will be removed from the group and a refund will be issued if applicable.`,
                                    })
                                ) {
                                    refundAndCancelSubscription({
                                        variables: {
                                            userId: source?._id,
                                            groupId: group?._id,
                                        },
                                    }).then(() => {
                                        onNetworkSearchAction();
                                        // delay showing the success toast after the confirmation modal closes
                                        setTimeout(() => {
                                            newToast(
                                                <AlertToast
                                                    boldText="The subscription has been successfully canceled."
                                                    showSuccessIcon
                                                />,
                                                {
                                                    ...defaultAlertToastOptions,
                                                }
                                            );
                                        }, 1500);
                                    });
                                }
                            } else {
                                if (
                                    source &&
                                    (await confirm({
                                        text: t(`common:${localNamespace}.confirm_group_removal`, {
                                            profileName: source.profile?.full_name ?? '',
                                        }),
                                    }))
                                ) {
                                    removeUser?.(source._id).then(() => {
                                        onNetworkSearchAction();
                                    });
                                }
                            }
                        },
                    },
                ];
                // Users are not allowed to leave the designated home group of their network.
            } else if (currentUser && source?._id === currentUser._id && !isHomeGroup) {
                if (group?.subtype === 'event') return actions;
                actions = [
                    {
                        id: 'remove-from-group',
                        iconKey: 'Remove from group',
                        title:
                            isGroupSubscription(group) &&
                            group.currentUserOwnsMembership &&
                            !group?.currentUserOwnsFreeSubscription
                                ? 'Cancel Subscription'
                                : t(`common:${localNamespace}.leave_group`),
                        onClick: async () => {
                            if (
                                currentUser &&
                                group.currentUserOwnsMembership &&
                                isGroupSubscription(group) &&
                                !group?.currentUserOwnsFreeSubscription
                            ) {
                                if (
                                    await confirm({
                                        text: 'Are you sure want to cancel your subscription? You will be removed from the group and a refund will be issued if applicable.',
                                    })
                                ) {
                                    refundAndCancelSubscription({
                                        variables: {
                                            userId: currentUser?._id,
                                            groupId: group?._id,
                                        },
                                        refetchQueries: [
                                            GET_USER_ORDERS_FOR_GROUP,
                                            GET_GROUP_DASHBOARD,
                                        ],
                                    }).then(() => {
                                        onNetworkSearchAction();
                                        closeAllModals();
                                        newToast(
                                            <AlertToast
                                                boldText="Your subscription has been successfully canceled."
                                                showSuccessIcon
                                            />,
                                            {
                                                ...defaultAlertToastOptions,
                                            }
                                        );
                                    });
                                }
                            } else {
                                if (
                                    await confirm({
                                        text: t(`common:${localNamespace}.confirm_leave`),
                                    })
                                ) {
                                    removeUser?.(currentUser._id).then(() => {
                                        onNetworkSearchAction();
                                        client
                                            .resetStore()
                                            .then(() => history?.push('/dashboard'))
                                            .catch(err => {
                                                logger.error(err);
                                                history?.push('/dashboard');
                                            });
                                    });
                                }
                            }
                        },
                    },
                ];
            }
            return actions;
        };

        const inviteText = t(`common:${localNamespace}.invite_members`);

        return (
            <ul>
                <LoadingLine isLoading={loading} />
                {(groupMembers?.results?.length ?? 0) > 0 ? (
                    groupMembers?.results.map((member, currentIndex) => {
                        return (
                            <GroupMemberItem
                                key={member?._id}
                                ref={
                                    currentIndex === groupMembers?.results?.length - 1
                                        ? ref
                                        : undefined
                                }
                                group={group}
                                customClass="group-member"
                                source={member}
                                connected
                                showEmail={false}
                                loggedInUser={currentUser}
                                getExtraQuickMenuOptions={getQuickMenuActionsForMember}
                                openQuickMenu={(items: MenuListItem[]) => openQuickMenu(items)}
                            />
                        );
                    })
                ) : (
                    <IntroContent
                        declaration={declaration}
                        instruction={instruction}
                        type="nocontent"
                    >
                        {canUserInviteOthers && (
                            <button
                                onClick={handleLinkSharing}
                                type="button"
                                className="group-members-invite-link-btn"
                            >
                                {inviteText}
                            </button>
                        )}
                    </IntroContent>
                )}
                {groupMembers?.hasMore && (
                    <div className="flex-center">
                        <LottieLoading height={50} width={300} lottieData={LottieAnimation} />
                    </div>
                )}
            </ul>
        );
    }
);

export default GroupMembersList;
