import React from 'react';
import { useTranslation } from 'react-i18next';
import { getOptionalPostTypes } from '@core/utilities/whitelabel_helpers';
import EditScreenItemContainer from '@web/ui/components/generic/editscreen/EditScreenItemContainer';
import AdvancedSettingsContainer from '@web/ui/components/generic/editscreen/AdvancedSettingsContainer.tsx';

import useConfirmation from '@components/generic/hooks/useConfirmation';

import EditScreenSelectOne from '@web/ui/components/generic/editscreen/EditScreenSelectOne.js';
import EditScreenTagSelect from '@web/ui/components/generic/editscreen/EditScreenTagSelect';
import ToggleButtonEditScreen from '@web/ui/components/generic/buttons/ToggleButtonEditScreen.js';

import SearchWithSelectedList from '@web/ui/components/generic/editscreen/SearchWithSelectedList';
import CreateTag from '@web/ui/components/generic/editscreen/CreateTag';
import SearchTagsWithSelectedList from '@web/ui/components/generic/editscreen/SearchTagsWithSelectedList';

import {
    ENGAGE_COMMENT,
    ENGAGE_FOLLOW,
    ENGAGE_HEART,
    ENGAGE_SHARE,
} from '@core/utilities/constants/engagement_types';

import {
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_LIBRARY,
    CARD_SHELF,
    DEFAULT_POST_TYPES,
} from '@core/utilities/constants/card_types.js';

import {
    VOTING_QUALITY,
    VOTING_RELEVANCE,
    VOTING_SENTIMENT,
} from '@core/utilities/constants/voting_types.js';

import { ROLE_ADMIN } from '@core/utilities/constants/roles';

import {
    DISP_REF_BOOK,
    DISP_REF_CHAPTER,
    DISP_REF_GROUP,
    DISP_REF_PROMPT,
    FILTER_ARCHIVE,
    FILTER_AZ,
    FILTER_LEADERBOARD,
    FILTER_LIVE,
    FILTER_OLDEST_FIRST,
    FILTER_ORDERED,
    FILTER_TOP,
    FILTER_VOTE,
    FILTER_UPCOMING,
    FILTER_PAST,
} from '@core/utilities/constants/library_settings';

import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import useBulkCardActions from '@web/ui/components/card/useBulkCardActions';

import { generateReferenceTypeLabelForGroup } from '@web/utilities/helpers/reference/reference.helpers';

const darkFormsNamespace = 'imports.wlWeb.ui.components.card.darkForms';
const localNamespace = 'imports.wlWeb.ui.components.card.darkForms.settings';

const Settings = ({
    isActive,
    getFeatureConstraintsForField,
    state,
    card,
    handleChange,
    handleAddToList,
    handleRemoveFromList,
    handleSetList,
    handleToggle,
    handleSubmit,
}) => {
    const { t } = useTranslation();
    const confirm = useConfirmation();

    const { loading, error, resetChildrenSettingsOfCards } = useBulkCardActions();

    const handleClickBulkResetChildrenSettings = async () => {
        if (await confirm({ text: t(`common:global.actions.bulk.reset_children.confirmation`) })) {
            resetChildrenSettingsOfCards([card._id]);
        }
    };

    const fadeStyle = isActive ? 'fadein' : 'fadeout';

    const sendNotificationsConstraints = getFeatureConstraintsForField('sendNotifications');
    const showPostTypesConstraints = getFeatureConstraintsForField('showPostTypes');

    const defaultVoteList = [
        { text: t(`common:${darkFormsNamespace}.relevance`), value: VOTING_RELEVANCE },
        { text: t(`common:${darkFormsNamespace}.quality`), value: VOTING_QUALITY },
        { text: t(`common:${darkFormsNamespace}.sentiment`), value: VOTING_SENTIMENT },
    ];

    const voteTypesFeatureConstraint = getFeatureConstraintsForField('voteTypes');
    const voteList = voteTypesFeatureConstraint?.options
        ? defaultVoteList.filter(option =>
            voteTypesFeatureConstraint.options.includes(option.value)
        )
        : defaultVoteList;

    const defaultEngagementTypes = [
        { text: t(`common:${darkFormsNamespace}.comments`), value: ENGAGE_COMMENT },
        { text: t(`common:${darkFormsNamespace}.share`), value: ENGAGE_SHARE },
        { text: t(`common:${darkFormsNamespace}.follow`), value: ENGAGE_FOLLOW },
        { text: t(`common:${darkFormsNamespace}.heart`), value: ENGAGE_HEART },
    ];

    const engagementTypesConstraint = getFeatureConstraintsForField('engagementTypes');
    const engagementTypes = engagementTypesConstraint?.options
        ? defaultEngagementTypes.filter(option =>
            engagementTypesConstraint.options.includes(option.value)
        )
        : defaultEngagementTypes;

    const defaultFilterTypes = [
        { text: t(`common:${localNamespace}.top`), value: FILTER_TOP },
        { text: t(`common:${localNamespace}.live`), value: FILTER_LIVE },
        { text: 'A-Z', value: FILTER_AZ },
        { text: t(`common:${localNamespace}.top_ten`), value: FILTER_LEADERBOARD },
        { text: t(`common:${localNamespace}.vote`), value: FILTER_VOTE },
        { text: t(`common:${localNamespace}.ordered`), value: FILTER_ORDERED },
        { text: t(`common:${localNamespace}.archived`), value: FILTER_ARCHIVE },
        { text: t(`common:${localNamespace}.oldest_first`), value: FILTER_OLDEST_FIRST },
        { text: t('common:upcoming_events', 'Upcoming Events'), value: FILTER_UPCOMING },
        { text: t('common:past_events', 'Past Events'), value: FILTER_PAST },
    ];

    const filterTypesConstraint = getFeatureConstraintsForField('filterTypes');
    const filterTypes = filterTypesConstraint?.options
        ? defaultFilterTypes.filter(option => filterTypesConstraint.options.includes(option.value))
        : defaultFilterTypes;

    const defaultFilterConstraint = getFeatureConstraintsForField('defaultFilter');

    const defaultViewTypes = [
        { text: t(`common:${localNamespace}.fullview`), value: 'fullview' },
        { text: t(`common:${localNamespace}.listview`), value: 'listview' },
    ];

    const viewTypesConstraint = getFeatureConstraintsForField('viewTypes');
    const viewTypes = viewTypesConstraint?.options
        ? defaultViewTypes.filter(option => viewTypesConstraint.options.includes(option.value))
        : defaultViewTypes;

    const defaultViewTypeConstraint = getFeatureConstraintsForField('defaultViewType');

    const groupDisplayReferenceLabel = generateReferenceTypeLabelForGroup(
        card?.reference?.groupSource?.subtype
    );

    const defaultDisplayReferenceTypes = [
        { text: t(groupDisplayReferenceLabel), value: DISP_REF_GROUP },
        { text: t(`common:global.constants.cards.types.collection`), value: DISP_REF_BOOK },
        { text: t(`common:global.constants.cards.types.channel`), value: DISP_REF_CHAPTER },
        { text: t(`common:${localNamespace}.prompt`), value: DISP_REF_PROMPT },
    ];

    const displayReferenceTypesConstraint = getFeatureConstraintsForField('displayReference');
    const displayReferenceTypes = displayReferenceTypesConstraint?.options
        ? defaultDisplayReferenceTypes.filter(option =>
            displayReferenceTypesConstraint.options.includes(option.value)
        )
        : defaultDisplayReferenceTypes;

    let roleWhitelistOptions = [{ text: t(`common:${localNamespace}.admin`), value: ROLE_ADMIN }];

    if (card?.rolesAvailable?.length > 0) {
        roleWhitelistOptions = card.rolesAvailable.map(role => ({
            text: role.name,
            value: role.url,
        }));
    }

    const postTypesConstraint = getFeatureConstraintsForField('postTypes');

    const additionalPostTypes = getOptionalPostTypes();

    const postTypesArray = postTypesConstraint?.options
        ? DEFAULT_POST_TYPES.filter(option => postTypesConstraint.options.includes(option))
        : DEFAULT_POST_TYPES;

    const allPostTypesArray = additionalPostTypes
        ? [...postTypesArray, ...additionalPostTypes]
        : postTypesArray;

    const postTypes = allPostTypesArray.map(value => ({
        text: t(`common:global.constants.cards.types.${value}`),
        value,
    }));

    let selectedPostTypes = state?.postTypes;
    if (!additionalPostTypes) {
        selectedPostTypes = state?.postTypes?.filter(postType =>
            DEFAULT_POST_TYPES?.includes(postType)
        );
    }

    const cardAggregationConstraint = getFeatureConstraintsForField('cardAggregation');
    const tagAggregationConstraint = getFeatureConstraintsForField('tagAggregation');
    const isTemplateConstraint = getFeatureConstraintsForField('isTemplate');
    const displayInNewsfeedConstraint = getFeatureConstraintsForField('displayInNewsfeed');
    const showTitleConstraint = getFeatureConstraintsForField('showTitleConstraint');

    const showAdvancedSettings =
        sendNotificationsConstraints ||
        showPostTypesConstraints ||
        filterTypesConstraint ||
        viewTypesConstraint ||
        engagementTypesConstraint ||
        postTypesConstraint ||
        displayReferenceTypesConstraint ||
        isTemplateConstraint ||
        displayInNewsfeedConstraint ||
        showTitleConstraint;

    const showTitleText = t(
        `common:${'imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm'}.show_title`
    );

    const displaySocialHeaderValues = {
        on: false,
        off: true,
        userselect: null,
    };

    const getDisplaySocialHeadersValues = value => {
        if (value === null) return 'userselect';
        return value ? 'off' : 'on';
    };

    const handleDisplaySocialHeaders = e => {
        const _value = e?.target?.value;
        const changeObject = {
            target: {
                name: 'displaySocialHeaders',
                value: displaySocialHeaderValues[_value],
            },
        };
        handleChange(changeObject);
    };

    return (
        <div className={`edit-screen-content-container ${fadeStyle}`}>
            <LoadingLine isLoading={loading} error={error} />
            <div
                data-w-tab="Settings"
                className="edit-screen-tab-container w-tab-pane w--tab-active"
                style={{
                    opacity: 1,
                    transition: 'opacity 300ms ease 0s',
                    paddingBottom: '25px',
                }}
            >
                {getFeatureConstraintsForField('allowPostbox') && (
                    <EditScreenItemContainer
                        title={t(`common:${localNamespace}.posting.title`)}
                        subtitle={t(`common:${localNamespace}.posting.subtitle`)}
                        columnItem
                        columnsBlock
                        showToggleContainer={
                            !state.allowPostbox &&
                            getFeatureConstraintsForField('postboxRoleWhiteList')
                        }
                        renderToggleContainer={
                            <EditScreenTagSelect
                                placeholder={t(`common:${localNamespace}.posting.whitelist`)}
                                target="postboxRoleWhiteList"
                                options={roleWhitelistOptions}
                                selected={state.postboxRoleWhiteList}
                                allowMultiple
                                overrideContainerStyle="whitelist-setting-toggle margin-left"
                                handleChange={handleChange}
                                handleAddToList={handleAddToList}
                                handleRemoveFromList={handleRemoveFromList}
                                handleSetList={handleSetList}
                            />
                        }
                    >
                        <ToggleButtonEditScreen
                            id="allowPostbox"
                            name="allowPostbox"
                            handleToggle={handleToggle}
                            onOption={t('common:global.on_caps')}
                            offOption={t('common:global.off_caps')}
                            toggleValue={state.allowPostbox}
                        />
                    </EditScreenItemContainer>
                )}
                <EditScreenItemContainer
                    title="Short Title"
                    subtitle="Shortened version of title"
                    columnItem
                    columnsBlock
                >
                    <input
                        placeholder="Enter short title"
                        name="shortTitle"
                        value={state?.shortTitle}
                        onChange={handleChange}
                    />
                </EditScreenItemContainer>
                {getFeatureConstraintsForField('displaySocialHeaders') && (
                    <EditScreenItemContainer
                        title={t(`common:${localNamespace}.anonymous.title`)}
                        subtitle={t(`common:${localNamespace}.anonymous.subtitle`)}
                        columnItem
                        columnsBlock
                    >
                        <select
                            value={getDisplaySocialHeadersValues(state.displaySocialHeaders)}
                            onChange={handleDisplaySocialHeaders}
                            id="displaySocialHeaders"
                            name="displaySocialHeaders"
                            aria-label="post privacy selector"
                        >
                            <option key="on" value="on">
                                On
                            </option>
                            <option key="off" value="off">
                                Off
                            </option>
                            <option key="userselect" value="userselect">
                                User Select
                            </option>
                        </select>
                    </EditScreenItemContainer>
                )}

                {voteTypesFeatureConstraint && (
                    <EditScreenItemContainer
                        title={t(`common:${localNamespace}.voting.title`)}
                        subtitle={t(`common:${localNamespace}.voting.subtitle`)}
                        columnsBlock
                        columnItem
                        showToggleContainer
                        renderToggleContainer={
                            <EditScreenTagSelect
                                target="voteTypes"
                                options={voteList}
                                selected={state.voteTypes}
                                allowMultiple={false}
                                handleChange={handleChange}
                                handleAddToList={handleAddToList}
                                handleRemoveFromList={handleRemoveFromList}
                                handleSetList={handleSetList}
                            />
                        }
                    />
                )}

                {showAdvancedSettings && (
                    <AdvancedSettingsContainer>
                        <div className="divider" />
                        {sendNotificationsConstraints && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.notify_users.title`)}
                                subtitle={t(`common:${localNamespace}.notify_users.subtitle`)}
                                columnItem
                                columnsBlock
                            >
                                <ToggleButtonEditScreen
                                    id="sendNotifications"
                                    name="sendNotifications"
                                    handleToggle={handleToggle}
                                    onOption={t('common:global.on_caps')}
                                    offOption={t('common:global.off_caps')}
                                    toggleValue={state.sendNotifications}
                                />
                            </EditScreenItemContainer>
                        )}
                        {(filterTypesConstraint || defaultFilterConstraint) && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.filters.title`)}
                                subtitle={t(`common:${localNamespace}.filters.subtitle`)}
                                columnsBlock
                                showToggleContainer
                                renderToggleContainer={
                                    <>
                                        {filterTypesConstraint && (
                                            <EditScreenTagSelect
                                                target="filterTypes"
                                                options={filterTypes}
                                                selected={state.filterTypes}
                                                handleChange={handleChange}
                                                handleSetList={handleSetList}
                                                handleRemoveFromList={handleRemoveFromList}
                                            />
                                        )}
                                        {defaultFilterConstraint && (
                                            <EditScreenSelectOne
                                                target="defaultFilter"
                                                options={filterTypes.filter(type =>
                                                    state.filterTypes.includes(type.value)
                                                )}
                                                selected={state.defaultFilter}
                                                handleChange={handleChange}
                                            />
                                        )}
                                    </>
                                }
                            />
                        )}
                        {(viewTypesConstraint || defaultViewTypeConstraint) && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.views.title`)}
                                subtitle={t(`common:${localNamespace}.views.subtitle`)}
                                columnsBlock
                                showToggleContainer
                                renderToggleContainer={
                                    <>
                                        {viewTypesConstraint && (
                                            <EditScreenTagSelect
                                                target="viewTypes"
                                                options={viewTypes}
                                                selected={state.viewTypes}
                                                handleSetList={handleSetList}
                                                handleRemoveFromList={handleRemoveFromList}
                                            />
                                        )}
                                        {defaultViewTypeConstraint && (
                                            <EditScreenSelectOne
                                                target="defaultViewType"
                                                options={viewTypes.filter(type =>
                                                    state.viewTypes.includes(type.value)
                                                )}
                                                selected={state.defaultViewType}
                                                handleChange={handleChange}
                                            />
                                        )}
                                    </>
                                }
                            />
                        )}
                        {engagementTypesConstraint && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.engagement.title`)}
                                subtitle={t(`common:${localNamespace}.engagement.subtitle`)}
                                columnsBlock
                                showToggleContainer
                                renderToggleContainer={
                                    <EditScreenTagSelect
                                        target="engagementTypes"
                                        options={engagementTypes}
                                        selected={state.engagementTypes}
                                        handleChange={handleChange}
                                        handleAddToList={handleAddToList}
                                        handleRemoveFromList={handleRemoveFromList}
                                        handleSetList={handleSetList}
                                    />
                                }
                            />
                        )}
                        {postTypesConstraint && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.show_post_types_title`, `Show Post Types`)}
                                subtitle={t(`common:${localNamespace}.show_post_types_subtitle`, `Show the post types for this card by default?`)}
                                columnItem
                                columnsBlock
                            >
                                <ToggleButtonEditScreen
                                    id="showPostTypes"
                                    name="showPostTypes"
                                    handleToggle={handleToggle}
                                    onOption={t('common:global.on_caps')}
                                    offOption={t('common:global.off_caps')}
                                    toggleValue={state.showPostTypes}
                                />
                            </EditScreenItemContainer>
                        )}
                        {postTypesConstraint && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.post_types.title`)}
                                subtitle={t(`common:${localNamespace}.post_types.subtitle`)}
                                columnsBlock
                                showToggleContainer
                                renderToggleContainer={
                                    <EditScreenTagSelect
                                        target="postTypes"
                                        options={postTypes}
                                        selected={selectedPostTypes}
                                        handleChange={handleChange}
                                        handleAddToList={handleAddToList}
                                        handleRemoveFromList={handleRemoveFromList}
                                        handleSetList={handleSetList}
                                    />
                                }
                            />
                        )}

                        {cardAggregationConstraint && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.card_aggregation.title`)}
                                subtitle={t(`common:${localNamespace}.card_aggregation.subtitle`)}
                                noBottomBorder
                                clearfix
                            >
                                <SearchWithSelectedList
                                    types={[CARD_LIBRARY, CARD_SHELF, CARD_BOOK, CARD_CHAPTER]}
                                    onSelect={item => handleAddToList('cardAggregation', item)}
                                    selected={state.cardAggregation}
                                    removeItem={item =>
                                        handleRemoveFromList('cardAggregation', item)
                                    }
                                    limit={60}
                                />
                            </EditScreenItemContainer>
                        )}
                        {tagAggregationConstraint && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.tag_aggregation.title`)}
                                subtitle={t(`common:${localNamespace}.tag_aggregation.subtitle`)}
                                noBottomBorder
                                clearfix
                            >
                                <CreateTag />

                                <SearchTagsWithSelectedList
                                    onSelect={item => handleAddToList('tagAggregation', item)}
                                    selected={state.tagAggregation}
                                    removeItem={item =>
                                        handleRemoveFromList('tagAggregation', item)
                                    }
                                />
                            </EditScreenItemContainer>
                        )}
                        {displayReferenceTypesConstraint && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.default_ref_header.title`)}
                                subtitle={t(`common:${localNamespace}.default_ref_header.subtitle`)}
                                columnItem
                                columnsBlock
                                showToggleContainer
                                renderToggleContainer={
                                    <EditScreenTagSelect
                                        target="displayReference"
                                        options={displayReferenceTypes}
                                        selected={state.displayReference}
                                        keepSelectedInOptions
                                        handleChange={handleChange}
                                        handleAddToList={handleAddToList}
                                        handleRemoveFromList={handleRemoveFromList}
                                        handleSetList={handleSetList}
                                    />
                                }
                            />
                        )}
                        {/* Display In Newsfeed Toggle */}
                        {displayInNewsfeedConstraint && (
                            <EditScreenItemContainer
                                title={t(
                                    `common:${'imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm'}.display_in_newsfeed`
                                )}
                                columnItem
                                columnsBlock
                            >
                                <ToggleButtonEditScreen
                                    id="displayInNewsfeed"
                                    name="displayInNewsfeed"
                                    handleToggle={handleToggle}
                                    onOption={t('common:global.yes_caps')}
                                    offOption={t('common:global.no_caps')}
                                    toggleValue={state?.displayInNewsfeed ?? true}
                                />
                            </EditScreenItemContainer>
                        )}
                        {isTemplateConstraint && (
                            <EditScreenItemContainer
                                title={t(`common:${localNamespace}.is_template.title`)}
                                subtitle={t(`common:${localNamespace}.is_template.subtitle`)}
                                columnItem
                                columnsBlock
                            >
                                <ToggleButtonEditScreen
                                    id="isTemplate"
                                    name="isTemplate"
                                    handleToggle={handleToggle}
                                    onOption={t('common:global.on_caps')}
                                    offOption={t('common:global.off_caps')}
                                    toggleValue={state.isTemplate}
                                />
                            </EditScreenItemContainer>
                        )}
                        <EditScreenItemContainer title={showTitleText} columnItem columnsBlock>
                            <ToggleButtonEditScreen
                                id="showTitle"
                                name="showTitle"
                                handleToggle={handleToggle}
                                onOption={t('common:global.on_caps')}
                                offOption={t('common:global.off_caps')}
                                toggleValue={state.showTitle === null ? true : state.showTitle}
                            />
                        </EditScreenItemContainer>

                        <EditScreenItemContainer
                            title="Inherit Survey Answers"
                            columnItem
                            columnsBlock
                        >
                            <ToggleButtonEditScreen
                                id="inheritSurveyAnswers"
                                name="inheritSurveyAnswers"
                                handleToggle={handleToggle}
                                onOption={t('common:global.on_caps')}
                                offOption={t('common:global.off_caps')}
                                toggleValue={state.inheritSurveyAnswers}
                            />
                        </EditScreenItemContainer>
                    </AdvancedSettingsContainer>
                )}

                {showAdvancedSettings && (
                    <AdvancedSettingsContainer title={t(`common:global.actions.bulk.title`)}>
                        <div
                            onClick={handleClickBulkResetChildrenSettings}
                            className="button callout-button w-button"
                        >
                            {t(`common:global.actions.bulk.reset_children.title`)}
                        </div>
                    </AdvancedSettingsContainer>
                )}
                <div className="edit-footer">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="button callout-button w-button edit-screen-content"
                    >
                        {t('common:global.verbs.save')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Settings;
