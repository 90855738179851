// src/tag/queries.ts
import { gql as gql2 } from "@apollo/client";

// src/tag/fragments.ts
import { gql } from "@apollo/client";
var TAG_FRAGMENT = gql`
    fragment TagFragment on Tag {
        __typename
        _id
        label
        color
        created
        updated
    }
`;

// src/tag/queries.ts
var GET_TAGS = gql2`
    query GetTags(
        $searchQuery: String
        $limit: Int = 10
        $cursor: String
        $sortType: String = "az"
        $filtered: [String]
    ) {
        getTags(
            searchQuery: $searchQuery
            limit: $limit
            cursor: $cursor
            sortType: $sortType
            filtered: $filtered
        ) {
            __typename
            cursor
            hasMore
            type
            results {
                ...TagFragment
            }
        }
    }
    ${TAG_FRAGMENT}
`;
export {
  GET_TAGS
};
