import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import useNavigation from '@web/ui/components/navigation/useNavigation';
import { useCurrentUser } from '@stores/User';
import pushUtilities from '@core/utilities/pushUtilities';
import {
    getWhitelabelPublicSettings,
    getWhitelabelSiteMeta,
} from '@core/utilities/whitelabel_helpers';
import { unsetAuthToken } from '@helpers/auth';
import { config } from '@core/config/getConfig';

import getLogger from '@core/logger';
import shoppingCartStore from '@stores/ShoppingCart';
import signUpStore from '@stores/SignUp';

const logger = getLogger(module);

const useSideMenuFooter = () => {
    const { t } = useTranslation();
    const { refetchCurrentUser } = useCurrentUser();
    const { closeSlideMenu, isSlideMenuOpen } = useNavigation();

    const history = useHistory();

    const { logout_redirect } = getWhitelabelPublicSettings();

    const client = useApolloClient();

    const closeSideMenu = () => {
        if (isSlideMenuOpen) {
            closeSlideMenu();
        }
    };

    const logout = () => {
        try {
            unsetAuthToken();
            client.clearStore().then(() => {
                refetchCurrentUser();
            });
            pushUtilities.revokeCurrentToken();
            shoppingCartStore?.set?.shoppingCarts([]);
            signUpStore?.set?.resetSignupStore();
            history.push(logout_redirect ?? '/login');
        } catch (e) {
            logger.error(e);
            history.push(logout_redirect ?? '/login');
        }
    };

    const siteMeta = getWhitelabelSiteMeta();
    const slideMenuSettings = siteMeta?.slideMenu || config.public.slideMenu;

    const publicSettings = getWhitelabelPublicSettings();

    const versionNumber: string = publicSettings?.version;

    const privacyPolicyLink = publicSettings?.privacyPolicy
        ? `//${publicSettings.privacyPolicy}`
        : '';
    const termsOfServiceLink = publicSettings?.termsOfService
        ? `//${publicSettings.termsOfService}`
        : '';

    const communityGuidelinesLink = publicSettings?.communityGuidelines
        ? `//${publicSettings.communityGuidelines}`
        : '';

    const currentYear = new Date().getFullYear();
    const copyrightName: string = config.public.copyright || 'WeLibrary, LLC';

    const footerText = slideMenuSettings?.footerText?.includes('common:')
        ? t(slideMenuSettings.footerText)
        : slideMenuSettings?.footerText;

    const showLinks = !!(privacyPolicyLink || termsOfServiceLink || versionNumber);

    return {
        t,
        logout,
        closeSideMenu,
        currentYear,
        copyrightName,
        footerText,
        showLinks,
        privacyPolicyLink,
        termsOfServiceLink,
        communityGuidelinesLink,
        versionNumber,
    };
};

export default useSideMenuFooter;
