import { ProductInstance } from '@shared/welibrary-graphql/types';

type ProductInstanceFrequencyType = {
    [key: string]: string;
};

export const getProductInstancesAndFrequency = (productInstances: ProductInstance[]) => {
    if (!productInstances) return [];

    const productInstanceFrequency: ProductInstanceFrequencyType = {};
    const dedupedProductInstances: ProductInstance = [];

    // get product instance frequency
    for (const productInstance of productInstances) {
        if (productInstanceFrequency[productInstance?.product?._id]) {
            productInstanceFrequency[productInstance?.product?._id] += 1;
        } else {
            productInstanceFrequency[productInstance?.product?._id] = 1;
        }
    }

    // dedupe productInstances
    productInstances.forEach(pi => {
        if (
            !dedupedProductInstances?.find(
                (_pi: ProductInstance) => _pi?.product?._id === pi?.product?._id
            )
        ) {
            dedupedProductInstances.push({
                ...pi,
                count: productInstanceFrequency[pi?.product?._id],
            });
        }
    });

    return dedupedProductInstances;
};

export const getProductInstanceFrequencyForOwners = (productInstances: ProductInstance[]) => {
    if (!productInstances) return [];

    const productInstanceFrequency: ProductInstanceFrequencyType = {};
    const dedupedProductInstances: ProductInstance = [];

    // get product instance frequency
    for (const productInstance of productInstances) {
        if (productInstanceFrequency[productInstance?.owner?._id]) {
            productInstanceFrequency[productInstance?.owner?._id] += 1;
        } else {
            productInstanceFrequency[productInstance?.owner?._id] = 1;
        }
    }

    // dedupe productInstances
    productInstances.forEach(pi => {
        if (!dedupedProductInstances.find(_pi => _pi?.owner?._id === pi?.owner?._id)) {
            dedupedProductInstances.push({
                ...pi,
                count: productInstanceFrequency[pi?.owner?._id],
            });
        }
    });

    return dedupedProductInstances;
};

export { getProductInstancesAndFrequency as default };
