import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Updater } from 'use-immer';
import { z } from 'zod';
import hash from 'object-hash';
import _ from 'lodash';

import { useLazyQuery } from '@apollo/client';
import { useCreateCustomerOrderMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { useGetEventGroupLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { useCurrentUser } from '@stores/User';
import { VALIDATE_USER_EMAIL } from '@shared/welibrary-graphql/user/queries';

import TrashBin from '@dsc/svgs/Trash';
import X from '@dsc/svgs/FullSizeX';
import OrderItemPreview from '@web/ui/components/card/Shopping-Cart/OrderItemPreview';
import OrderTotal from '@web/ui/components/card/Shopping-Cart/OrderTotal';
import CustomerDetailsForm from '@web/ui/components/card/Shopping-Cart/CustomerDetailsForm';
import Checkbox from '@dsc/forms/customInputs/Checkbox';
import ExternalLink from '@components/generic/ExternalLink';
import ShoppingCartSelectorItem from '@components/card/Shopping-Cart/ShoppingCartSelectorItem';
import ShoppingCartDiscountCode from '@components/card/Shopping-Cart/ShoppingCartDiscountCode';

import { Group, Product, PurchaseRequestInput } from '@shared/welibrary-graphql/types';
import { CARD_EVENT } from '@core/utilities/constants/card_types';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';

import shoppingCartStore from '@web/ui/stores/ShoppingCart';
import {
    DiscountCodeState,
    getDiscountedShoppingCartTotal,
    getShoppingCartTotal,
    getTotal,
    isOrderFree,
    ORDER,
    UserDetailsState,
} from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { UPDATED_EMAIL_REGEX } from '@core/utilities/constants/regexes.js';
import { CHECKOUT_STATE } from '@components/card/Shopping-Cart/CheckoutHelpers';

import { SetState } from '@core/types/Utilities';

import { config } from '@core/config/getConfig';
import getLogger from '@core/logger';
import { isEventFree } from '@web/utilities/helpers/events/event.helpers';

const logger = getLogger(module);

type OrderDetailProps = {
    ref: React.RefObject<HTMLElement>;
    state: UserDetailsState;
    setState: Updater<UserDetailsState>;
    group: Group;
    subgroup: Group | undefined;
    cart: { cartItem: Product; quantity: number };
    setCart: React.Dispatch<
        React.SetStateAction<{
            cartItem: Product;
            quantity: number;
        }>
    >;
    discountCodeState: DiscountCodeState;
    setDiscountCodeState: Updater<DiscountCodeState>;
    checkoutState: boolean | CHECKOUT_STATE;
    setCheckoutState: React.Dispatch<React.SetStateAction<boolean | CHECKOUT_STATE>>;
    handleDiscountCode: (discountCode: string, productId: string) => void;
    handleRemoveDiscountCode: () => void;
    handleNextStep: (smooth?: boolean) => void;
    handlePrevStep: (smooth?: boolean) => void;
    clientSecret: string;
    setClientSecret: React.Dispatch<React.SetStateAction<string>>;
    setOrderId: React.Dispatch<React.SetStateAction<string>>;
    closeModal: () => void;
    idempotencySalt: string;
    idempotencyKey: string;
    setIdempotencyKey: SetState<string>;

    // new guest/registration checkout
    hideCloseButton: boolean;
    hideCheckoutInstructions: boolean;
    customOrderHeading?: string | React.ReactNode;
    onCancel?: () => void;
    onGoBack: () => void;
    currentPage: string;

    isInline: boolean;
};

const localNamespace = 'imports.wlWeb.ui.pages.signup.stepOne';
const signupNamespace = 'imports.wlWeb.ui.pages.signup.signupContainer';

const OrderDetails = React.forwardRef<HTMLDivElement, OrderDetailProps>(function OrderDetails(
    {
        state,
        setState,
        group,
        subgroup,
        cart,
        setCart,
        discountCodeState,
        setDiscountCodeState,
        setCheckoutState,
        handleDiscountCode,
        handleRemoveDiscountCode,
        handleNextStep,
        handlePrevStep,
        clientSecret,
        setClientSecret,
        setOrderId,
        closeModal,
        idempotencySalt,
        idempotencyKey,
        setIdempotencyKey,

        hideCloseButton,
        hideCheckoutInstructions,
        customOrderHeading,
        onCancel,
        onGoBack,
        currentPage,

        isInline = false,
    },
    ref
) {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();
    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const [agreedToTerms, setAgreedToTerms] = useState(!config.public.ticketPurchaserTermsOfUseUrl);

    const isEvent = group?.subtype === CARD_EVENT;
    const _isEventFree = isEventFree(group);
    const isFreeTicketCheckout = cart?.cartItem?.type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP;

    const shoppingCart = shoppingCartStore?.useTracked?.shoppingCarts()?.find(({ groupId }) => {
        return groupId === group?._id;
    });
    const shoppingCartProducts = shoppingCart?.products?.filter(({ product }) => {
        if (_isEventFree) return product?.type === PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP;

        return product?.type === PRODUCT_TYPES?.GROUP_MEMBERSHIP;
    });

    const updateSlice = curriedStateSlice(setState);
    const updateDiscountCodeSlice = curriedStateSlice(setDiscountCodeState);

    const StateValidator = z.object({
        name: z.string().nonempty('Name is required!').max(50),
        email: z
            .string()
            .regex(UPDATED_EMAIL_REGEX, `${t(`common:${signupNamespace}.error.email`)}`),
        phoneNumber: z.string().nonempty('Phone number is required!'),
        quantity: z.number().min(1),
    });

    const [createOrder, { error: createOrderError, loading }] = useCreateCustomerOrderMutation();
    const [validateUserEmail, { data: validateEmailData, loading: validateEmailLoading }] =
        useLazyQuery(VALIDATE_USER_EMAIL, {
            fetchPolicy: 'network-only',
        });

    // Used to refetch the event group after order has been fulfilled.
    const [getEventGroup] = useGetEventGroupLazyQuery({
        variables: {
            _id: group?._id,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (validateEmailLoading) {
            setErrors({
                ...errors,
                email: t(`common:${localNamespace}.checking_email`),
            });
            return;
        }
        if (validateEmailData?.validateUserEmail === false) {
            setErrors({
                ...errors,
                email: t(`common:${localNamespace}.email_already_exists`),
            });
        } else {
            setErrors({});
        }
    }, [validateEmailData, validateEmailLoading]);

    let total: number = 0;
    const { quantity } = cart;
    let originalPrice = getTotal(cart.cartItem, quantity);

    if (discountCodeState.isApplied) {
        total = getTotal(cart.cartItem, quantity, discountCodeState.percentage);
    } else {
        total = getTotal(cart.cartItem, quantity);
    }

    if (isEvent) {
        originalPrice = getShoppingCartTotal(shoppingCartProducts);
        total = getDiscountedShoppingCartTotal(shoppingCartProducts);

        if (_isEventFree) {
            total = getShoppingCartTotal(shoppingCartProducts, _isEventFree);
        }
    }

    const validateEmailFormat = (email: string = state.email) =>
        UPDATED_EMAIL_REGEX.test(String(email).toLowerCase());

    const validateEmail = (email: string = state.email) => {
        if (email) {
            if (validateEmailFormat(email)) {
                setErrors({
                    ...errors,
                    email: t(`common:${localNamespace}.checking_email`),
                });
                validateUserEmail({ variables: { email } });
            } else {
                setErrors({
                    ...errors,
                    email: t(`common:${localNamespace}.invalid_email`),
                });
            }
        }
    };

    const debouncedValidateEmail = useCallback(_.debounce(validateEmail, 300), []);

    const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrors({
            ...errors,
            email: '',
        });
        updateSlice('email', e.target.value);
        debouncedValidateEmail(e.target.value);
    };

    const validate = () => {
        const parsedData = StateValidator.safeParse({
            name: state.name,
            email: state.email,
            phoneNumber: state.phoneNumber,
            quantity: cart.quantity,
        });

        if (parsedData.success) {
            setErrors({});
            return true;
        }

        if (parsedData?.error) {
            setErrors(parsedData?.error.flatten().fieldErrors);
        }

        return false;
    };

    const goToNextStep = () => {
        // if the order is free
        // condense flow to a single confirmation screen
        if (isOrderFree(total) || isFreeTicketCheckout) {
            setCheckoutState(CHECKOUT_STATE.ORDER_COMPLETE);

            if (currentUser) getEventGroup();

            handleNextStep(false);
        } else {
            handleNextStep(false);
        }
    };

    const handleCreateOrder = async (
        customerDetails: UserDetailsState,
        purchaseRequests: PurchaseRequestInput[]
    ) => {
        const newIdempotencyKey = `${idempotencySalt}:${hash(customerDetails)}:${hash(
            purchaseRequests
        )}`;

        if (
            idempotencyKey === newIdempotencyKey &&
            !createOrderError?.message &&
            !errors?.phoneNumber &&
            !errors?.email
        )
            return goToNextStep();

        setIdempotencyKey(newIdempotencyKey);

        createOrder({
            variables: {
                input: {
                    customerDetails: {
                        name: customerDetails.name,
                        email: customerDetails.email,
                        phone:
                            (customerDetails.phoneNumber?.length ?? 0) > 0
                                ? customerDetails.phoneNumber
                                : undefined,
                        password:
                            (customerDetails.password?.length ?? 0) > 0
                                ? customerDetails.password
                                : undefined,
                    },
                    purchaseRequests,
                    idempotencyKey: newIdempotencyKey,
                },
                // allow multiple purchases across events & subscriptions
                allowDuplicatePurchases: true,
                subgroupId: subgroup ? subgroup?._id : undefined,
            },
        })
            .then(payload => {
                setClientSecret(payload?.data?.createOrder?.paymentIntent?.client_secret ?? '');
                setOrderId(payload.data?.createOrder?._id ?? '');

                if (isOrderFree(total) || isFreeTicketCheckout) {
                    shoppingCartStore?.set?.clearGroupShoppingCart(group?._id);
                }

                goToNextStep();
            })
            .catch(err => logger.error('create order error', err));
    };

    const handleOnClick = () => {
        if (validate()) {
            const { quantity, cartItem } = cart;

            let purchaseRequest: PurchaseRequestInput[] = [
                {
                    productId: cartItem?._id,
                    quantity,
                    couponCode: discountCodeState?.code,
                },
            ];

            if (isEvent) {
                purchaseRequest = shoppingCartProducts?.map(
                    ({ product, quantity: qty, coupon }) => {
                        const _purchaseRequest = {
                            productId: product?._id,
                            quantity: qty,
                        };

                        if (coupon?.code) {
                            return {
                                ..._purchaseRequest,
                                couponCode: coupon?.code,
                            };
                        }

                        return _purchaseRequest;
                    }
                );
            }

            handleCreateOrder(state, purchaseRequest);
        }
    };

    let showCloseButton = window.innerWidth > 991;
    if (hideCloseButton) showCloseButton = false;

    const buttonText = isOrderFree(total) || isFreeTicketCheckout ? 'Place Order' : 'Continue';
    let checkoutInstructions;

    if (total > 0) {
        checkoutInstructions = (
            <p>
                *{' '}
                {t(
                    `common:${localNamespace}.continue_checkout_instructions`,
                    'Click the Continue button at the bottom of the screen to checkout and pay.'
                )}
            </p>
        );
    } else {
        checkoutInstructions = (
            <p>
                *{' '}
                {t(
                    `common:${localNamespace}.free_checkout_instructions`,
                    'Click the Place Order button at the bottom of the screen to complete your order.'
                )}
            </p>
        );
    }

    let orderHeading = null;
    if (isEvent) {
        orderHeading = (
            <header>
                <p>Order Details</p>
            </header>
        );
    }

    if (customOrderHeading) {
        orderHeading = customOrderHeading;
    }

    const containerClassName = isInline ? 'inline-checkout-order-details-container' : '';
    const footerClassName = isInline ? 'absolute' : 'fixed';

    return (
        <section className="checkout-modal checkout-modal-orderDetails modal-scrollable" ref={ref}>
            {showCloseButton && (
                <button
                    type="button"
                    onClick={() => closeModal()}
                    className="checkout-modal-btn-close"
                >
                    <X className="checkout-modal-btn-x-dark" />
                </button>
            )}

            {isEvent && (
                <header>
                    <p>Customer Details</p>
                </header>
            )}

            <ul className={`m-0 h-[100vh] w-full overflow-y-scroll p-0 ${containerClassName}`}>
                {!isEvent && (
                    <OrderItemPreview
                        cartItem={cart.cartItem}
                        setCart={setCart}
                        group={group}
                        total={total}
                        originalPrice={originalPrice}
                        discountCodeState={discountCodeState}
                        quantity={quantity}
                        errors={errors}
                        allowEdit
                    >
                        <div className="customer-payments-container">
                            <div className="stripe-checkout-form">
                                {isEvent && !isFreeTicketCheckout && (
                                    <>
                                        <div className="stripe-checkout-form-element-wrap discount-code-wrap">
                                            <input
                                                disabled={discountCodeState?.isApplied ?? false}
                                                className={`stripe-checkout-form-element stripe-checkout-customer-form-element stripe-checkout-customer-form-element-discount ${discountCodeState?.error
                                                    ? 'stripe-checkout-customer-form-element-discount-err'
                                                    : ''
                                                    } `}
                                                type="text"
                                                placeholder="Discount Code"
                                                onChange={e =>
                                                    updateDiscountCodeSlice(
                                                        'code',
                                                        e.target.value.toUpperCase()
                                                    )
                                                }
                                                onBlur={() =>
                                                    updateDiscountCodeSlice('error', null)
                                                }
                                                onFocus={() =>
                                                    updateDiscountCodeSlice('error', null)
                                                }
                                                value={discountCodeState?.code?.toUpperCase()}
                                                autoComplete="off"
                                            />
                                            {discountCodeState.isApplied ? (
                                                <button
                                                    onClick={() => handleRemoveDiscountCode()}
                                                    type="button"
                                                    className="stripe-checkout-discount-btn-remove"
                                                >
                                                    <TrashBin />
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() =>
                                                        handleDiscountCode(
                                                            discountCodeState.code,
                                                            cart.cartItem._id
                                                        )
                                                    }
                                                    type="button"
                                                    className="stripe-checkout-discount-btn"
                                                    disabled={discountCodeState?.code?.length === 0}
                                                >
                                                    Apply
                                                </button>
                                            )}
                                        </div>
                                        {discountCodeState.error && (
                                            <p className="stripe-checkout-customer-err-msg">
                                                {discountCodeState.error}
                                            </p>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </OrderItemPreview>
                )}

                <div className="stripe-checkout-form-container !mb-0 !pb-0">
                    <CustomerDetailsForm
                        state={state}
                        setState={setState}
                        errors={errors}
                        emailChangeHandler={emailChangeHandler}
                        createOrderError={createOrderError}
                        group={group}
                        cartItem={cart.cartItem}
                        quantity={quantity}
                    />
                </div>
            </ul>

            {/* <div
                className={`checkout-instructions ${!isEvent ? 'checkout-instructions-subs' : ''}`}
            >
                {checkoutInstructions}
            </div> */}

            {currentPage === ORDER && (
                <div
                    className={`stripe-checkout-form-container bottom-0 left-0 z-50 bg-white ${footerClassName}`}
                >
                    <div className="stripe-checkout-total-divider" />
                    <OrderTotal total={total} originalPrice={originalPrice} group={group} />
                    {config.public.ticketPurchaserTermsOfUseUrl && isEvent && (
                        <label
                            htmlFor="terms-of-use-checkbox"
                            className="generic-checkbox terms-of-use-checkbox"
                        >
                            <Checkbox
                                id="terms-of-use-checkbox"
                                value={agreedToTerms}
                                onChange={() => setAgreedToTerms(!agreedToTerms)}
                            />
                            <span>
                                {t(`common:${localNamespace}.i_agree`, 'I agree to the')}{' '}
                                <ExternalLink to={config.public.ticketPurchaserTermsOfUseUrl}>
                                    {t(
                                        `common:${localNamespace}.event_terms`,
                                        'Event Terms of Use'
                                    )}
                                </ExternalLink>
                            </span>
                        </label>
                    )}
                    <div className="stripe-checkout-btn-wrap">
                        <button
                            type="button"
                            onClick={() => {
                                if (isEvent) {
                                    handlePrevStep();
                                } else {
                                    closeModal();
                                }
                            }}
                            className="stripe-checkout-btn-cancel"
                        >
                            {isEvent ? 'Go Back' : 'Cancel'}
                        </button>
                        <button
                            disabled={loading || (isEvent && !agreedToTerms)}
                            onClick={handleOnClick}
                            type="button"
                            className="stripe-checkout-btn-confirm"
                        >
                            {loading ? 'Processing...' : buttonText}
                        </button>
                    </div>
                </div>
            )}
        </section>
    );
});

export default OrderDetails;
