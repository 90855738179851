import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { awsMetricPublisher } from '@welibraryos/metrics';

import useModal from '@components/modals/hooks/useModal';

import TreasureNavIcon from '@dsc/svgs/lego/TreasureNavIcon';
import MapNavIcon from '@dsc/svgs/lego/MapNavIcon';
import MiniAvatar from '@components/profile/Lego/MiniAvatar';
import SimpleProfile from '@components/profile/Lego/SimpleProfile';
import TreasureContainer from '@components/lego/treasure/TreasureContainer';
import QuestPage from '@pages/QuestPage';

import { showNavItem } from '@helpers/navigation/secondaryNav.helpers';

import { config } from '@config/getConfig';

const LegoNavMobile = () => {
    const { newModal } = useModal();
    const { pathname } = useLocation();
    const history = useHistory();

    const isActive = (url: string) => {
        if (pathname === url || (url === '/my-quests' && pathname !== '/treasures')) {
            return 'lego-nav-btn-active';
        }

        return '';
    };

    const handleTreasuresOnClick = () => {
        awsMetricPublisher.publishCount('lego-nav-menu', 1, [
            { Name: 'menu', Value: 'treasure-chest' },
        ]);
        newModal(<TreasureContainer />, {
            className: 'no-top-padding no-bottom-padding no-notch full-height',
            hideButton: true,
            customCloseButton: true,
        });
    };

    const handleMyQuestsOnClick = () => {
        awsMetricPublisher.publishCount('lego-nav-menu', 1, [{ Name: 'menu', Value: 'my-quests' }]);
        if (config.public.main_quest_map) {
            newModal(<QuestPage questUrl={config.public.main_quest_map} />, {
                className: 'no-top-padding no-bottom-padding no-notch full-height',
                hideButton: true,
                customCloseButton: true,
            });
        } else {
            history.push('/my-quests');
        }
    };

    const handleSimpleProfileModal = () => {
        awsMetricPublisher.publishCount('lego-nav-menu', 1, [
            { Name: 'menu', Value: 'simple-profile' },
        ]);
        newModal(<SimpleProfile />, {
            className: 'no-top-padding no-bottom-padding no-notch full-height',
            hideButton: true,
            customCloseButton: true,
        });
    };

    return (
        <>
            {showNavItem('treasures', 'mobile') && (
                <button
                    onClick={handleTreasuresOnClick}
                    type="button"
                    className="mn-button w-inline-block mobile-only secondary-nav-user-button lego-nav-btn-wrap"
                >
                    <div className={`lego-nav-btn ${isActive('/treasures')}`}>
                        <TreasureNavIcon className="lego-nav-icon lego-nav-icon-treasures" />
                    </div>
                </button>
            )}
            {showNavItem('myQuests', 'mobile') && (
                <button
                    onClick={handleMyQuestsOnClick}
                    type="button"
                    className="mn-button w-inline-block mobile-only secondary-nav-user-button lego-nav-btn-wrap"
                >
                    <div className={`lego-nav-btn ${isActive('/my-quests')}`}>
                        <MapNavIcon className="lego-nav-icon lego-nav-icon-quests" />
                    </div>
                </button>
            )}
            {showNavItem('simpleProfile', 'mobile') && (
                <button
                    onClick={handleSimpleProfileModal}
                    type="button"
                    className="mn-button w-inline-block mobile-only secondary-nav-user-button lego-nav-btn-wrap"
                >
                    <MiniAvatar />
                </button>
            )}
        </>
    );
};

export default LegoNavMobile;
