import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import EventGroupCustomSignup from '@components/content/newpost/forms/EventGroup/EventGroupCustomSignup';
import DropdownController from '@components/generic/dropdownmenu/DropdownController';
import useModal from '@components/modals/hooks/useModal';
import { MenuListItem } from '@core/types/Menus';
import { ModalTypes } from '@core/types/Modals';
import { titleCase } from '@helpers/string.helpers';
import { UPDATE_BROADCAST_SURVEY } from '@shared/welibrary-graphql/broadcastsurvey/mutations';
import {
    BROADCAST_SURVEY_HAS_SIGNATURE_SURVEY_RESPONSE,
    GET_BROADCAST_SURVEY_CSV_STRING,
} from '@shared/welibrary-graphql/broadcastsurvey/queries';
import { Group } from '@shared/welibrary-graphql/types';
import downloadCSV from '@web/utilities/helpers/csv/downloadCSV.helpers';

interface BroadcastSurveyEntryProps {
    handleBroadcastSurvey: (broadcastSurveyId: string) => Promise<void> | void;
    broadcastSurvey: any;
    group: Group;
    refetch: () => void;
    closeAllModals: () => void;
}

const BroadcastSurveyEntry: React.FC<BroadcastSurveyEntryProps> = ({
    handleBroadcastSurvey,
    broadcastSurvey,
    group,
    refetch,
    closeAllModals,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { newModal, closeModal } = useModal();
    const [updateBroadcastSurvey] = useMutation(UPDATE_BROADCAST_SURVEY);

    const { data: hasSignatureSurveyResponseData } = useQuery(
        BROADCAST_SURVEY_HAS_SIGNATURE_SURVEY_RESPONSE,
        {
            variables: { broadcastSurveyId: broadcastSurvey._id },
            fetchPolicy: 'network-only',
        }
    );

    const [getAndDownloadBroadcastSurveyCsv] = useLazyQuery(GET_BROADCAST_SURVEY_CSV_STRING, {
        variables: { broadcastSurveyId: broadcastSurvey._id },
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data?.getBroadcastSurveyCsvString) {
                downloadCSV(data.getBroadcastSurveyCsvString, `${broadcastSurvey.title}.csv`);
            }
        },
        onError: error => {
            console.error('Error fetching CSV data:', error);
        },
    });

    const hasSignatureSurveyResponse =
        hasSignatureSurveyResponseData?.broadcastSurveyHasSignatureSurveyResponse;
    const hasBeenBroadcasted = broadcastSurvey.status === 'broadcasted';

    const handleDownloadCSV = () => {
        getAndDownloadBroadcastSurveyCsv();
    };

    const handleDeleteSurvey = async () => {
        await updateBroadcastSurvey({
            variables: {
                broadcastSurveyId: broadcastSurvey._id,
                input: { status: 'closed' },
            },
        });
        refetch();
    };

    const handleEditSurvey = () => {
        newModal(
            <EventGroupCustomSignup
                group={group}
                broadcastSurvey={broadcastSurvey}
                isBroadcastSurvey
                handleCancel={closeModal}
                handleSave={refetch}
                refetch={refetch}
            />,
            {
                className: 'center-fixed-header-footer',
                hideButton: false,
            },
            { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
        );
    };

    const handleViewBigScreen = () => {
        closeAllModals();
        history.push(`/broadcastsurveybigscreen/${broadcastSurvey._id}`);
    };

    const menuList: MenuListItem[] = [
        {
            id: 1,
            title: `Broadcast Survey${hasBeenBroadcasted ? ' Again' : ''}`,
            onClick: () => handleBroadcastSurvey(broadcastSurvey._id),
            iconName: 'Announcement',
        },
        {
            id: 2,
            title: 'Download CSV of Responses',
            onClick: handleDownloadCSV,
            iconName: 'Document',
        },
        {
            id: 3,
            title: 'Edit Survey',
            onClick: handleEditSurvey,
            iconName: 'Edit',
        },
        {
            id: 4,
            title: 'Delete Survey',
            onClick: handleDeleteSurvey,
            iconName: 'Delete',
        },
    ];

    return (
        <div
            className="flex items-center justify-between rounded-lg px-6 py-4 shadow-md"
            style={{ background: 'white' }}
        >
            <span className="font-bold" style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                {broadcastSurvey.title}
            </span>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {hasSignatureSurveyResponse && (
                    <button
                        onClick={handleViewBigScreen}
                        className="mx-auto text-sm"
                        style={{ whiteSpace: 'nowrap', margin: '0 1rem' }}
                    >
                        {titleCase(t('common:view_big_screen', 'view big screen'))}
                    </button>
                )}
                <DropdownController menuList={menuList} />
            </div>
        </div>
    );
};

export default BroadcastSurveyEntry;
