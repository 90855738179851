import React from 'react';
import { useTranslation } from 'react-i18next';

import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/stripe-lottie.json';
import PayoutDisclaimer from '@components/content/newpost/forms/ShoppingCartProducts/PayoutDisclaimer';

const localNamespace = 'imports.wlWeb.ui.components.stripe';

const ConnectedAccountStep0: React.FC<{
    totalSteps: number;
    currentStep: number;
    handleNextStep: () => void;
    isEvent: boolean;
}> = ({ totalSteps, currentStep, handleNextStep, isEvent = false }) => {
    const { t } = useTranslation();

    const setupText = isEvent
        ? t(
              `common:${localNamespace}.merchant_account_setup_needed`,
              'Before you can accept payments for your tickets, you need a merchant account setup.'
          )
        : t(
              `common:${localNamespace}.merchant_account_subs_setup_needed`,
              'Before you can accept payments for your subscriptions, you need a merchant account setup.'
          );

    return (
        <div className="mc-source-post-wrapper connected-account-success">
            <div className="connected-account-starter-lottie-wrap">
                <LottieLoading
                    height={250}
                    width={250}
                    lottieData={LottieAnimation}
                    customStyle={{ opacity: 1 }}
                    isPaused
                    loop={false}
                />
            </div>
            <div className="connected-account-starter-text-wrap">
                <h1>
                    {t(`common:${localNamespace}.setup_merchant_account`, 'Setup Merchant Account')}{' '}
                </h1>
                <p>{setupText}</p>
                <PayoutDisclaimer />
            </div>

            {currentStep < totalSteps && (
                <button onClick={handleNextStep} type="button">
                    {t(`common:${localNamespace}.get_started`, 'Get Started')}
                </button>
            )}
        </div>
    );
};

export default ConnectedAccountStep0;
