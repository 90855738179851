import React from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '@web/ui/components/modals/hooks/useModal';
import _ from 'lodash';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary';
import DropdownMultipleSelect from '../../group/customsignup/DropdownMultipleSelect';

const localNamespace = 'imports.wlWeb.ui.components.generic.editScreen.editScreenTagSelect';

const EditScreenTagSelect = ({
    target,
    options,
    selected,
    placeholder,
    overrideContainerStyle,
    overrideSelectOptionItemClass,
    hidePlaceholder,
    handleDoneButton,
    doneButtonText,
    customSelectItemStyle,
    handleRemoveFromList,
    handleSetList,
    showCancelButton,
    allowMultiple = true,
}) => {
    const { t } = useTranslation();
    const { newModal } = useModal();

    const selectItemClass = overrideSelectOptionItemClass || 'select-option-item';
    const selectItemStyle =
        customSelectItemStyle || hidePlaceholder
            ? { padding: '5px 15px 5px 15px', margin: '5px' }
            : {};

    const _selected = selected?.map(selectedOption => {
        const matchingOption = _.find(options, { value: selectedOption });
        const displayText = matchingOption?.text ?? selectedOption;
        const displayColor = matchingOption?.color;

        return (
            <div
                key={`selected-tag-${selectedOption}`}
                onClick={() => handleRemoveFromList(target, selectedOption)}
                className={`${selectItemClass} selected w-inline-block`}
                style={displayColor ? { backgroundColor: displayColor } : {}}
            >
                <div>{displayText}</div>
                <div className="select-option-line" />
            </div>
        );
    });

    const handleCustomChange = (id: string, values: any, type: any) => {
        if (allowMultiple) {
            const _values = [...values];
            handleSetList(target, _values);
        } else {
            const _values = values[0];
            handleSetList(target, _values);
        }
    };

    const handleOpenOptions = () => {
        newModal(
            <DropdownMultipleSelect
                state={selected}
                allowMultipleSelect={allowMultiple}
                handleDoneButton={handleDoneButton}
                doneButtonText={doneButtonText}
                lightBackground
                options={options}
                showCancelButton={showCancelButton}
                handleCustomChange={handleCustomChange}
            />,
            { hideButton: true }
        );
    };

    return (
        <ErrorBoundary>
            <div className={overrideContainerStyle || 'edit-item-clear-column w-clearfix'}>
                <div
                    className={selectItemClass}
                    style={selectItemStyle}
                    onClick={handleOpenOptions}
                >
                    {!hidePlaceholder && (
                        <>
                            <div>{placeholder || `${t(`common:${localNamespace}.select`)}...`}</div>
                            <div className="select-option-line" />
                        </>
                    )}
                </div>
                {_selected}
            </div>
        </ErrorBoundary>
    );
};

export default EditScreenTagSelect;
