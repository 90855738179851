import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';

import useModal from '@components/modals/hooks/useModal';

import TextInput from '@dsc/forms/textInputs/TextInput';
import Toggle from '@dsc/forms/customInputs/Toggle';
import Button from '@dsc/forms/buttons/Button';
import ImageUpload from '@dsc/forms/customInputs/ImageUpload';
import Select from '@dsc/forms/customInputs/Select';

import { curriedStateSlice } from '@web/utilities/helpers/state/state.helpers';

import { SKILL, SKILL_SUBTYPE, MILESTONE } from '@helpers/lego/skill.helpers';
import { StepState } from '@core/types/Courses';
import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

type CourseFormStepDetailsProps = {
    state: StepState;
    onChange: (state: StepState) => void;
};

const CourseFormStepDetails: React.FC<CourseFormStepDetailsProps> = ({ state, onChange }) => {
    const [stepState, setStepState] = useImmer(state);

    const updateSlice = curriedStateSlice(setStepState);

    const { closeModal } = useModal();

    const submit = () => {
        onChange(stepState);
        closeModal();
    };

    // Force skill subtype to be valid when skill changes
    useEffect(() => {
        if (!(SKILL_SUBTYPE[stepState.skill] ?? []).includes(stepState.skillSubtype)) {
            updateSlice('skillSubtype')('');
        }
    }, [stepState.skill]);

    // Force milestone to be valid when skill subtype changes
    useEffect(() => {
        if (
            !(MILESTONE[stepState.skill]?.[stepState.skillSubtype] ?? []).includes(
                stepState.skillMilestone
            )
        ) {
            updateSlice('skillMilestone')('');
        }
    }, [stepState.skillSubtype]);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                submit();
            }}
            className="course-form-step-details"
        >
            <header>
                <h3>Step Details</h3>
            </header>

            <section>
                <TextInput
                    value={stepState.text}
                    onChange={updateSlice('text')}
                    label="Text"
                    multiline
                />

                <label>
                    Add Page Break After Step
                    <Toggle value={stepState.pageBreak} onChange={updateSlice('pageBreak')} />
                </label>

                <label>
                    Full Page Step
                    <Toggle value={stepState.fullPage} onChange={updateSlice('fullPage')} />
                </label>

                <label>
                    Image
                    <Toggle value={stepState.useImage} onChange={updateSlice('useImage')} />
                </label>

                {stepState.useImage && (
                    <ImageUpload
                        state={{
                            ...stepState.image,
                            image:
                                stepState.image.image || getRandomDefaultCoverImage(stepState.text),
                        }}
                        setState={updateSlice('image')}
                    />
                )}

                <label>
                    Video
                    <Toggle value={stepState.useVideo} onChange={updateSlice('useVideo')} />
                </label>

                {stepState.useVideo && (
                    <TextInput
                        value={stepState.video}
                        onChange={updateSlice('video')}
                        label="Video URL"
                    />
                )}

                <Select
                    title="Skill addressed"
                    value={stepState.skill ?? ''}
                    onChange={updateSlice('skill')}
                    options={Object.values(SKILL)}
                    clearable
                />

                {stepState.skill && (
                    <Select
                        title="Skill Subtype addressed"
                        value={stepState.skillSubtype ?? ''}
                        onChange={updateSlice('skillSubtype')}
                        options={SKILL_SUBTYPE[stepState.skill] ?? []}
                        clearable
                    />
                )}

                {stepState.skill && stepState.skillSubtype && (
                    <Select
                        title="Skill Milestone addressed"
                        value={stepState.skillMilestone ?? ''}
                        onChange={updateSlice('skillMilestone')}
                        options={MILESTONE[stepState.skill]?.[stepState.skillSubtype] ?? []}
                        clearable
                    />
                )}
            </section>

            <footer>
                <Button type="small" onClick={submit}>
                    Submit
                </Button>
                <Button type="small" onClick={closeModal} className="cancel">
                    Cancel
                </Button>
            </footer>
        </form>
    );
};

export default CourseFormStepDetails;
