import React, { useState } from 'react';
import ToggleButton from '@components/lego/dashboard/ToggleButton';
import VerificationStatusIcon from '@dsc/svgs/lego/VerificationStatusIcon';

type VCStatusBoxProps = {
    status: 'Success' | 'Error' | 'Failed';
    summary: string;
    details?: string;
};

const VCStatusBox: React.FC<VCStatusBoxProps> = ({ status, summary, details }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    let verificationText: string;
    let color: string;
    switch (status) {
        case 'Success':
            verificationText = 'Verification Success';
            color = '#39B54A';
            break;
        case 'Error':
            verificationText = 'Verification Error';
            color = '#FFBD06';
            break;
        case 'Failed':
            verificationText = 'Verification Failed';
            color = '#D01012';
            break;
        default:
            break;
    }

    return (
        <div className="dashboard-box relative">
            {details && <ToggleButton isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
            <div className="flex">
                <VerificationStatusIcon status={status} className="mr-[10px] shrink-0" />
                <div className="flex flex-col justify-center">
                    <span className="uppercase text-[15px] leading-[17px]" style={{ color }}>
                        {verificationText}
                    </span>
                    <span
                        className="text-[15px] leading-[17px] pr-[30px]"
                        style={{ wordBreak: 'break-word' }}
                    >
                        {summary}
                    </span>
                </div>
            </div>
            {!isCollapsed && <span className="mt-[5px]">{details}</span>}
        </div>
    );
};

export default VCStatusBox;
