import React from 'react';
import { NavLink } from 'react-router-dom';

const NotificationsWireFrame = () => (
    <>
        <NavLink
            to="/notifications"
            className="secondary-nav-dropdown-toggle w-dropdown-toggle w--open "
        >
            <div className="mn-icon desktop-hide">
                <div className="content-button-icon notification" />
                <div className="icon-alert-indicator" />
            </div>
        </NavLink>
        <div
            data-delay="0"
            className="sn-dropdown notification w-dropdown"
            style={{ zIndex: '901' }}
        >
            <div className="secondary-nav-dropdown-toggle w-dropdown-toggle w--open ">
                <div className="mn-icon mobile-hide">
                    <div className="content-button-icon notification" />
                </div>
                <div className="icon-alert-indicator mobile-hide" />
            </div>
        </div>
    </>
);

export default NotificationsWireFrame;
