import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';

import Notification from '@web/ui/components/generic/notifications/Notification';
import IntroContent from '@web/ui/components/generic/IntroContent';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/square-loader.json';
import NotificationsWireFrame from '@web/ui/components/generic/notifications/NotificationsWireFrame';

import { Notification as NotificationType } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';
import { ApolloError } from '@apollo/client';

const logger = getLogger(module);

type NotificationsProps = {
    notifications: NotificationType[];
    hideLoader?: boolean;
    block?: boolean;
    snoozed: (notification: NotificationType) => boolean;
    markNotificationsRead: (notificationIds: string[]) => void;
    loading: boolean;
    error: ApolloError;
    closeNotifications?: () => void;
};

const Notifications = React.forwardRef<HTMLLIElement, NotificationsProps>(
    (
        {
            notifications,
            hideLoader,
            block,
            snoozed,
            markNotificationsRead,
            loading,
            error,
            closeNotifications,
        },
        ref
    ) => {
        const { currentUser } = useCurrentUser();
        const { t } = useTranslation();

        if (!currentUser) return <NotificationsWireFrame />;

        if (error) {
            logger.error(error);
            if (hideLoader) return <NotificationsWireFrame />;

            return (
                <IntroContent
                    declaration={t('common:global.introContentErr.declaration')}
                    instruction={t('common:global.introContentErr.instruction')}
                    buttonTitle={t('common:global.introContentErr.button_title')}
                    type="error"
                />
            );
        }
        if (!notifications && loading) {
            if (hideLoader) return <NotificationsWireFrame />;

            return <LottieLoading height={50} width={300} lottieData={LottieAnimation} />;
        }

        const notificationsList =
            !notifications || notifications.length <= 0 ? (
                <IntroContent
                    declaration={t('common:global.introContentErr.no_notifications.declaration')}
                    instruction={t('common:global.introContentErr.no_notifications.instruction')}
                    buttonTitle={t('common:global.introContentErr.no_notifications.btn_title')}
                    type="nocontent"
                />
            ) : (
                notifications.map((notification, index) => (
                    <Notification
                        block={block}
                        notification={notification}
                        key={notification._id}
                        ref={index === notifications.length - 1 ? ref : undefined}
                        snoozed={snoozed}
                        markNotificationsRead={markNotificationsRead}
                        closeNotifications={closeNotifications}
                    />
                ))
            );

        if (notifications?.length > 0 && loading) {
            notificationsList.push(
                <li key="loading" className="notifications-loading-indicator">
                    <LottieLoading height={50} width={300} lottieData={LottieAnimation} />
                </li>
            );
        }

        return <ul className="notifications-list">{notificationsList}</ul>;
    }
);

export default Notifications;
