import React from 'react';

import Avatar from '@components/profile/Lego/Avatar';
import { getAvatar } from '@components/profile/Lego/avatarHelpers';
import { AvatarType } from '@components/profile/Lego/Avatars';

type AvatarCircleProps = {
    student: any;
};

const AvatarCircle: React.FC<AvatarCircleProps> = ({ student }) => {
    return (
        <div className="student-avatar-circle">
            <Avatar
                className="student-avatar"
                avatar={getAvatar(student?.profile?.avatar?.avatar || 'speedy') as AvatarType}
                colors={{
                    skinTone: student?.profile?.avatar?.skinTone || '#F8BC93',
                }}
            />
        </div>
    );
};

export default AvatarCircle;
