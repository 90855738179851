import React from 'react';
import { useTranslation } from 'react-i18next';

import { groupSellsProductsButCantAcceptPayments } from '@components/group/groupdashboard/groupDashboardHelpers';

import { Maybify } from '@core/types/Utilities';
import { Group } from '@shared/welibrary-graphql/types';

type EventPrivacyBubbleProps = {
    group: Maybify<Group>;
    className?: string;
};

const EventPrivacyBubble: React.FC<EventPrivacyBubbleProps> = ({ group, className = '' }) => {
    const { t } = useTranslation();

    let eventPrivacy = groupSellsProductsButCantAcceptPayments(group)
        ? 'Draft'
        : group?.settings?.protectionLevel;

    if (group?.isUnpublished) {
        eventPrivacy = 'unpublished';
    }

    return (
        <span className={`event-privacy-bubble ${eventPrivacy} ${className}`}>{`${eventPrivacy} ${t(
            `common:global.nouns.event`,
            'Event'
        )}`}</span>
    );
};

export default EventPrivacyBubble;
