import { useEffect } from 'react';

import useDebouncedState from '@web/ui/components/generic/hooks/useDebouncedState';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';

/**
 * React hook for handling the logic in the SearchBox component
 *
 * @param {DocumentNode} gqlQuery GraphQL Query to run when searching
 * @param {{
 *    getChildren: number,
 *    showEmpty: boolean,
 *    libraryUrl: string,
 *    url: string,
 *    types: string[],
 *    onSelect: (item: { [key: string]: string }, filterUrl: string) => void,
 *    handleQueryInput: React.ChangeEventHandler<HTMLInputElement>
 *    controlledInput: string,
 *    filterUrl: string,
 *    groupId: string,
 *    prioritizeGroupId: boolean,
 *    limit?: number
 * }}
 */
const usePaginatedSearchBox = (
    gqlQuery,
    {
        getChildren,
        showEmpty,
        libraryUrl,
        url,
        types,
        onSelect,
        handleQueryInput,
        controlledInput,
        filterUrl,
        disableRenderChildren = false,
        childrenTypes,
        groupId,
        prioritizeGroupId,
        limit,
    }
) => {
    const [query, actualQuery, setQuery] = useDebouncedState('');

    const {
        queryResult: { data, loading, error },
        setRef,
    } = useInfiniteScroll({
        query: {
            query: gqlQuery,
            options: {
                returnPartialData: true,
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                variables: {
                    searchQuery: actualQuery,
                    childrenLimit: getChildren || 0,
                    limit,
                    showEmpty: showEmpty || false,
                    libraryUrl,
                    url,
                    types,
                    skipChildren: disableRenderChildren,
                    childrenTypes,
                    groupId,
                    prioritizeGroupId,
                },
            },
        },
    });

    /** @param {{ [key: string]: string }} item */
    const handleSelect = item => {
        onSelect(item, filterUrl);
    };

    /** @type React.ChangeEventHandler<HTMLInputElement> */
    const handleQueryInputChange = e => {
        const { value } = e.target;
        handleQueryInput?.(e);
        setQuery(value);
    };

    useEffect(() => {
        if (controlledInput) setQuery(controlledInput);
    }, [controlledInput]);

    /** @type Array<{ [key: string]: string }> */
    const results =
        data?.getTags?.results ??
        data?.listGroups ??
        data?.listUsers ??
        data?.paginatedListCards?.results ??
        [];

    const _displayType = types?.length === 1 ? types[0] : 'card';

    return {
        query,
        data,
        loading,
        error,
        handleSelect,
        handleQueryInputChange,
        results,
        _displayType,
        setRef,
    };
};

export default usePaginatedSearchBox;
