import React from 'react';
import DropdownController from '@web/ui/components/generic/dropdownmenu/DropdownController';

import { awsMetricPublisher } from '@welibraryos/metrics';

import {
    useArchiveCardMutation,
    useBookmarkCardMutation,
    useFlagContentMutation,
    useUpdateCardMutation,
} from '@shared/welibrary-graphql/content_card/mutations.hook';

import { CARD_IMAGE, CARD_POSTBOX } from '@core/utilities/constants/card_types';
import { MenuListItem } from '@core/types/Menus';
import { ContentCard } from '@shared/welibrary-graphql/types';
import { getContentCardLabel } from '~/wl-web/utilities/helpers/contentCard/contentCard.helpers';

type ContentCardMenuProps = {
    card: ContentCard;
    positioningClass: string;
    showReferenceHeader: boolean;
    displaySocialHeader: boolean;
    getTopRightList: (
        updateCard_unused: any,
        showAuthor_unused: any,
        flagContent: (input: { variables: { url: string; reason: string } }) => void,
        bookmarkCard: (input: { variables: { url: string; remove: boolean } }) => void,
        archiveCard: (input: { variables: { url: string; unarchive: boolean } }) => void
    ) => MenuListItem[];
    areTopRightDropdownDotsInverted: boolean;
};

const ContentCardMenu: React.FC<ContentCardMenuProps> = ({
    card,
    positioningClass,
    showReferenceHeader,
    displaySocialHeader,
    getTopRightList,
    areTopRightDropdownDotsInverted,
}) => {
    const [archiveCard] = useArchiveCardMutation();
    const [bookmarkCard] = useBookmarkCardMutation();
    const [flagContent] = useFlagContentMutation();
    const [updateCard] = useUpdateCardMutation();

    const handleOpenContentCardMenuMetric = () => {
        awsMetricPublisher.publishCount('content-card-engage', 1, [
            { Name: 'menu', Value: 'more-options' },
        ]);
    };

    const menuList = getTopRightList(
        updateCard,
        displaySocialHeader,
        flagContent,
        bookmarkCard,
        archiveCard
    );

    const positionClass = positioningClass || 'dropdown-positioning';
    const isInvertedDots =
        positioningClass === 'bottom-dropdown' ? undefined : areTopRightDropdownDotsInverted;

    let adjustmentStyle;

    if (showReferenceHeader) adjustmentStyle = { display: 'block' };

    if (positioningClass !== 'bottom-dropdown') adjustmentStyle = { marginTop: '5px' };

    if (card.type === CARD_POSTBOX && !showReferenceHeader) {
        adjustmentStyle = { marginRight: '9px', marginTop: '7px' };
    }

    return (
        <div className={positionClass} style={adjustmentStyle}>
            <DropdownController
                ariaLabel={`${getContentCardLabel(card)} options`}
                menuList={menuList}
                inverted={isInvertedDots}
                onClickCallback={handleOpenContentCardMenuMetric}
            />
        </div>
    );
};

export default ContentCardMenu;
