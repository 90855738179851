import React, { useState } from 'react';

import Minus from '@web/ui/dsc/svgs/Minus';
import Plus from '@web/ui/dsc/svgs/Plus';

type IncrementStepperProps = {
    id?: string;
    className?: string;
    value?: number | false;
    onChange: (value: number | false) => void;
    disabled?: boolean;
    min?: number;
    max?: number;
    step?: number;
    placeholder?: string;

    /**
     * Setting uncontrolled to true will allow this component to be used inside of a modal.
     * If uncontrolled is true, value will only be used to set this component's internal state. All
     * interactions with this component will then update its internal state, which is what will be
     * displayed instead of value. However, onChange will still be fired, allowing the calling
     * component to track state changes that occur.
     */
    uncontrolled?: boolean;
};

/**
 * Incrementing Number Input
 * See https://www.figma.com/file/naUyXB9d4H9XG1tuFkgtbq/weLibrary-Design-Systems?node-id=982%3A5900
 *
 * Using this component in a modal? Make sure uncontrolled is set to true
 */
const IncrementStepper: React.FC<IncrementStepperProps> = ({
    id,
    className = '',
    value = false,
    onChange,
    disabled = false,
    min = -Infinity,
    max = Infinity,
    step = 1,
    placeholder = '...',
    uncontrolled = false,
}) => {
    const [uncontrolledValue, setUncontrolledValue] = useState<number | false>(value);

    const displayValue = uncontrolled ? uncontrolledValue : value;

    const handleChange = (newValue: number | false) => {
        const sanitizedValue =
            newValue === false ? newValue : Math.min(Math.max(newValue, min), max);

        if (uncontrolled) setUncontrolledValue(sanitizedValue);

        onChange(sanitizedValue);
    };

    return (
        <section className={`dsc-forms-customInputs-incrementStepper ${className}`}>
            {/*
            The input element has to appear first and then reordered via styles in order to preserve
            intended functionality when nesting this component inside of a label element.
        */}
            <input
                id={id}
                type="number"
                value={typeof displayValue === 'number' ? displayValue : ''}
                placeholder={placeholder}
                onChange={e =>
                    handleChange(e.currentTarget.value ? Number(e.currentTarget.value) : false)
                }
                min={min}
                max={max}
                step={step}
                disabled={disabled}
            />
            <button
                className="minus-button"
                type="button"
                onClick={() => handleChange((displayValue || 0) - step)}
                disabled={disabled}
            >
                <Minus />
            </button>
            <button
                className="plus-button"
                type="button"
                onClick={() => handleChange((displayValue || 0) + step)}
                disabled={disabled}
            >
                <Plus />
            </button>
        </section>
    );
};

export default IncrementStepper;
