import React from 'react';

import Picture from '@components/generic/Picture';
import DropdownController from '@components/generic/dropdownmenu/DropdownController';

import { getRandomDefaultThumbImage } from '@core/utilities/constants/defaults';

import { MenuListItem } from '@core/types/Menus';
import { Sponsor } from '@core/types/EventGroup';

type SponsorListCardProps = {
    sponsor: Sponsor;
    menuList: MenuListItem;
    index?: number;
};

const SponsorListCard: React.FC<SponsorListCardProps> = ({ sponsor, menuList = [], index }) => {
    const sponsorImageUrl = sponsor?.logo?.image || getRandomDefaultThumbImage(sponsor?.name);

    return (
        <section className="sponsor-list-card">
            <section className="left-side">
                <div className="thumb">
                    <Picture
                        url={sponsorImageUrl}
                        alt="sponsor logo"
                        resolutions={[75, 150, 300]}
                        disableLink
                    />
                </div>
                <div className="sponsor-info">
                    <p className="name">{sponsor?.name}</p>
                    <p className="tier">{`${sponsor?.tier?.name} Tier`} </p>
                </div>
            </section>

            <div className="menu-dropdown">
                <DropdownController
                    menuList={menuList}
                    inverted
                    buttonClass="content-button-icon dropdown-dots big-dots grow"
                />
            </div>
        </section>
    );
};

export default SponsorListCard;
