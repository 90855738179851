import { GET_GROUP_CUSTOM_FIELDS } from '@shared/welibrary-graphql/user/queries';
import { useGetGroupCustomFieldsQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { useUpdateUserMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import getLogger from '@core/logger';

const logger = getLogger(module);

/**
 * @typedef {Object} ValuesObject
 * @property {string} _id
 * @property {Array.<string | string[]>} values
 * @property {string} type
 * @property {string} comparator
 */

/**
 * @typedef {Object} CustomFieldsObject
 * @property {{ _id: string }} parentGroup
 * @property {string} manager
 * @property {ValuesObject[]} values
 */

/**
 * @typedef {Object} ProfileObject
 * @property {string} full_name
 * @property {string} picture
 * @property {string} short_bio
 */

/**
 * @typedef {Object} CustomSignupFieldsObject
 * @property {string} customText
 * @property {string} defaultComparator
 * @property {string} id
 * @property {number} index
 * @property {string[]} options
 * @property {string} prompt
 * @property {boolean} selectMultiple
 * @property {string} siblingId
 * @property {string} type
 */

/**
 * @typedef {Object} GroupObject
 * @property {boolean} currentUserIsAdmin
 * @property {boolean} currentUserIsMember
 * @property {ProfileObject} profile
 * @property {{ recommendations: boolean, customSignupFields: CustomSignupFieldsObject[] }} settings
 * @property {string} _id
 *

/**
 * @typedef {Object} UseGroupOptimizersOptions
 * @property {boolean} includeAllRecommendationStatuses - Indicates whether to include all groups regardless of their recommendation status.
 */

/**
 * React Hook for getting a user's optimizer data
 * This will give you all the data/logic you need to display and update all of
 * the optimizers of groups that a user has joined
 *
 * @param {string} userId ID of the user to get optimizer data for
 * @param {() => void} afterUpdate
 * @param {() => void} alternateUpdateQuery Use this instead of the default updateUser mutation
 * @param {UseGroupOptimizersOptions} options
 */
const useGroupOptimizers = (groupId, afterUpdate, alternateUpdateQuery, options) => {
    const { includeAllRecommendationStatuses } = options ?? {};

    const {
        data,
        error: queryError,
        loading: queryLoading,
    } = useGetGroupCustomFieldsQuery({
        variables: { _id: groupId },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const [updateUser, { loading: mutationLoading, error: mutationError }] =
        useUpdateUserMutation();

    /**
     * Group plus all parent groups with recommendations on
     * @type {GroupObject[]}
     */
    const groups =
        data?.groupById?.allSignupFields.reduce((acc, { parentGroup }) => {
            return (parentGroup.settings.recommendations || includeAllRecommendationStatuses) &&
                parentGroup.settings.customSignupFields.length > 0 &&
                acc.findIndex(parent => parent._id === parentGroup._id) === -1
                ? [...acc, parentGroup]
                : acc;
        }, []) ?? [];

    /**
     * Updates the group's custom fields with the given payload
     *
     * @param {CustomFieldsObject} payload
     */
    const updateQuery = payload => {
        if (!alternateUpdateQuery) {
            updateUser({
                variables: { _id: groupId, customFields: [payload] },
                refetchQueries: [GET_GROUP_CUSTOM_FIELDS],
            })
                .then(() => afterUpdate?.())
                .catch(error => logger.warn(error));
        }

        if (alternateUpdateQuery) {
            alternateUpdateQuery(payload);
        }
    };

    return {
        /** @type {{ customFields: CustomFieldsObject[] }} */
        profile: data?.groupById?.profile,
        groups,
        updateQuery,
        loading: (queryLoading && 'query') || mutationLoading,
        error: queryError ?? mutationError ?? undefined,
    };
};

export default useGroupOptimizers;
