import React, {useState} from 'react';

const NavigationContext = React.createContext([{}, () => {}]);

const NavigationProvider = props => {
    const [state, setState] = useState({
        isProfileMenuOpen: false,
        isSlideMenuOpen: false,
        isGroupSwitchOpen: false,
        isQuickMenuOpen: false,
        isMobileNavHidden: false,
        quickMenuItems: [],
        groupSwitchRoute: null,
        groupSwitchType: null,
    });

    return (
        <NavigationContext.Provider value={[state, setState]}>
            {props.children}
        </NavigationContext.Provider>
    );
};

export { NavigationContext, NavigationProvider };
