import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';

import useGroupById from '@components/group/hooks/useGroup';
import useModal from '@components/modals/hooks/useModal';
import signUpStore from '@stores/SignUp';

import GroupSummary from '@pages/new-signup/GroupSummary';
import ModalLoading from '@components/modals/ModalLoading';
import SubscriptionPaymentFailed, {
    PaymentFailedMode,
} from '@components/group/groupdashboard/groupSubscriptionPaywall/SubscriptionPaymentFailed';

import {
    StyledBackgroundImage,
    getBackgroundImageSetForUrl,
} from '@core/utilities/constants/defaults';
import { getWhitelabelImages } from '@core/utilities/whitelabel_helpers';
import { useGroupByShortCode } from '@components/group/hooks/useGroup';

import { VIEW_PERMISSION_PRIVATE } from '@core/utilities/constants/roles';

import { config } from '@core/config/getConfig';

const localNamespace = 'imports.wlWeb.ui.pages.post-signup.layout';

type PostSignupGroupsJoinedAndRequestedProps = {
    containerClassName?: string;
    innerContainerClass?: string;
    className?: string;
    showWhiteLabelLogo?: boolean;
    groupsController?: React.ReactNode | null;
    suppressModal?: boolean;
};

const PostSignupGroupJoined: React.FC<PostSignupGroupsJoinedAndRequestedProps> = ({
    containerClassName,
    innerContainerClass,
    className,
    showWhiteLabelLogo = true,
    groupsController = null,
    suppressModal = true,
}) => {
    const { t } = useTranslation();
    const { newModal, closeModal } = useModal();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [mainLogo, unused, whiteLogoAlt, defaultCoverPhoto] = getWhitelabelImages();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const _activeGroup = signUpStore.useTracked.activeGroup();
    const isSignUpWithGroupCode = signUpStore.get.isSignUpWithGroupCode();

    const { group: defaultGroup } = useGroupByShortCode(config.public.defaultGroupCode);

    const activeGroup = _activeGroup ?? defaultGroup;

    const {
        group: groupByID,
        loading: groupByIDIsLoading,
        refetch: refetchGroupByID,
    } = useGroupById(activeGroup?._id);

    const isEvent = groupByID?.subtype === 'event';
    const isSubscriptionGroup = groupByID?.doesSellMembership && !isEvent;

    let approvalRequired = groupByID?.settings?.approvalRequired ?? false;
    // A group code acts like an automatic pre-approved invitation to join.
    // Users can use the group code and enter the group without getting vetted.
    // settings?.shortCodeApprovalRequired > settings.approvalRequired
    // A group admin must approve a user to join if settings?.shortCodeApprovalRequired is enabled
    if (isSignUpWithGroupCode) {
        approvalRequired = groupByID?.settings?.shortCodeApprovalRequired;
    }

    // ! disables requiring payment for subscription groups during signup
    // const currentUserOwnsMembership = groupByID?.currentUserOwnsMembership;
    // const currentUserHasPreAuthorizedPayment = groupByID?.currentUserHasPreAuthorizedPayment;
    // const subscriptionPreAuthPaymentFailed =
    //     approvalRequired && !currentUserHasPreAuthorizedPayment;
    // const subscriptionPaymentFailed =
    // !approvalRequired && !currentUserOwnsMembership && !currentUserHasPreAuthorizedPayment;

    // ! disables requiring payment for subscription groups during signup
    // useEffect(() => {
    //     if (!groupByIDIsLoading && groupByID && !suppressModal) {
    //         if (isSubscriptionGroup && isSignUpWithGroupCode) {
    //             if (subscriptionPreAuthPaymentFailed && !isModalOpen) {
    //                 setIsModalOpen(true);
    //                 newModal(
    //                     <SubscriptionPaymentFailed
    //                         cancel={() => closeModal()}
    //                         group={groupByID}
    //                         mode={PaymentFailedMode.preAuthPayment}
    //                         groupRefetch={refetchGroupByID}
    //                     />
    //                 );
    //             } else if (subscriptionPaymentFailed && !isModalOpen) {
    //                 setIsModalOpen(true);
    //                 newModal(
    //                     <SubscriptionPaymentFailed
    //                         cancel={() => closeModal()}
    //                         group={groupByID}
    //                         mode={PaymentFailedMode.payment}
    //                         groupRefetch={refetchGroupByID}
    //                     />
    //                 );
    //             }
    //         }
    //     }
    // }, [groupByIDIsLoading, groupByID]);

    const protectionLevel = groupByID?.settings?.protectionLevel;

    let groupJoinText = t(`common:${localNamespace}.haveJoined`, 'You have joined');

    if (groupByID?.subtype === 'event') {
        groupJoinText = t(
            `common:${localNamespace}.you_will_be_redirected_to`,
            'You will be redirected to'
        );
    } else if (protectionLevel === VIEW_PERMISSION_PRIVATE && approvalRequired) {
        groupJoinText = t(`common:${localNamespace}.requestToJoin`, 'You have requested to join');
    }

    if (isSubscriptionGroup) {
        // ! disables requiring payment for subscription groups during signup
        // groupJoinText = t(
        //     `common:${localNamespace}.subscribed_success`,
        //     'You have successfully subscribed to'
        // );

        groupJoinText = t(
            `common:${localNamespace}.you_will_be_redirected_to`,
            'You will be redirected to'
        );

        // ! disables requiring payment for subscription groups during signup

        // if (isSignUpWithGroupCode && approvalRequired) {
        //     groupJoinText = t(
        //         `common:${localNamespace}.subscription_request_success`,
        //         'You have completed your subscription request to'
        //     );
        // }

        // if (subscriptionPaymentFailed || subscriptionPreAuthPaymentFailed) groupJoinText = '';
    }

    const backgroundImage = groupByID?.profile?.coverPhoto || defaultCoverPhoto;
    const [src, srcSet] = backgroundImage ? getBackgroundImageSetForUrl(backgroundImage, 800) : [];
    const isNativeApp = Capacitor.isNativePlatform();

    return (
        <section className={`w-0 md:w-[40%] flex flex-col items-center ${containerClassName}`}>
            {!isNativeApp && (
                <StyledBackgroundImage
                    // className="gs-background-blur animatedBackground bg-no-repeat bg-cover"
                    className="animatedBackground bg-no-repeat bg-cover h-full"
                    src={src}
                    srcSet={srcSet}
                />
            )}
            <div className={`flex flex-col gap-[30px] max-w-[415px] ${className}`}>
                {showWhiteLabelLogo && (
                    <div className="flex justify-center">
                        <img src={whiteLogoAlt || mainLogo} alt="" className="" />
                    </div>
                )}

                {groupByIDIsLoading && (
                    <div
                        className={`bg-[rgba(19,_21,_23,_0.40);] p-[40px] rounded-[20px] flex flex-col items-center ${innerContainerClass}`}
                    >
                        <ModalLoading
                            message={t(`common:${localNamespace}.loading_groups`, 'Loading...')}
                            height={100}
                            width={100}
                            textClassName="text-white"
                        />
                    </div>
                )}

                {groupByID && !groupByIDIsLoading && (
                    <div
                        className={`bg-[rgba(19,_21,_23,_0.40);] p-[40px] rounded-[20px] flex flex-col gap-[20px] items-center ${innerContainerClass}`}
                    >
                        <span className="text-white text-[16px] font-[500] leading-[24px] tracking-[0.25px] text-center">
                            {groupJoinText}
                        </span>
                        <GroupSummary group={groupByID} showX={false} />
                        {groupsController}
                    </div>
                )}
            </div>
        </section>
    );
};

export default PostSignupGroupJoined;
