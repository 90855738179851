import React from 'react';
import { useQuestForLoggedInUserByRainbowRoadUrlQuery } from '@shared/welibrary-graphql/quest/queries.hook';
import TreasureResourcesTab from '@components/lego/treasure/TreasureResourcesTab';
import TreasureCreationsTab from '@components/lego/treasure/TreasureCreationsTab';
import TreasureSeasonsTab from '@components/lego/treasure/TreasureSeasonsTab';
import TreasureBadgesTab from '@components/lego/treasure/TreasureBadgesTab';
import { accruedResourcesToResources } from '@helpers/rainbowRoad/quest.helpers';
import { getCredentialImageUrl } from '@web/utilities/helpers/wallet/wallet.helpers';
import { config } from '@config/getConfig';
import { BadgeRecord } from '@core/types/Credential';

const TreasureBody: React.FC<{
    refs: any;
    legoSeasonVCs?: any;
    plugfestVc?: any;
    badges: BadgeRecord[];
}> = ({ refs, legoSeasonVCs, plugfestVc, badges }) => {
    // loop through records to look for unclaimed flag
    // if you accept the VC wallet.index.IDX.add({id: record.id, uri: record.uri})
    // if reject: wallet.index.IDX.remove(record.id)

    const { data } = useQuestForLoggedInUserByRainbowRoadUrlQuery({
        variables: { url: (config.public.main_quest_map || config.public.main_quest_url) ?? '' },
        skip: !config.public.main_quest_map && !config.public.main_quest_url,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const creations = data?.getQuestForLoggedInUserByRainbowRoadUrl?.creationsState ?? [];

    const seasonImageUrls = legoSeasonVCs?.map(vc => {
        return getCredentialImageUrl(vc?.credentialSubject);
    });

    const showSeasons = legoSeasonVCs?.length > 0 || plugfestVc;

    return (
        <section className="treasure-body">
            <TreasureResourcesTab
                ref={refs.resources}
                resources={accruedResourcesToResources(
                    data?.getQuestForLoggedInUserByRainbowRoadUrl?.accruedResources ?? {}
                )}
            />
            {creations?.length > 0 && (
                <TreasureCreationsTab ref={refs.creations} creations={creations} />
            )}

            {showSeasons && (
                <TreasureSeasonsTab
                    ref={refs.seasons}
                    seasonImageUrls={seasonImageUrls}
                    plugfestVc={plugfestVc}
                />
            )}

            {badges.length > 0 && <TreasureBadgesTab ref={refs.badges} badges={badges} />}
        </section>
    );
};

export default TreasureBody;
