import _ from 'lodash';
import { createStore } from '@udecode/zustood';
import { Group, Organization, CustomSignupField } from '@shared/welibrary-graphql/types';
import { Location } from '@core/types/User';
import { filterSignupQuestions } from '@helpers/signup.helpers';
import { VIEW_PERMISSION_PRIVATE, VIEW_PERMISSION_PUBLIC } from '@core/utilities/constants/roles';
import { PostSignupStep, POST_SIGNUP_STEPS } from '@pages/new-signup/PostSignup';

export const LoginMethod = {
    email: 'email',
    phone: 'phone',
} as const;
export type LoginMethodType = (typeof LoginMethod)[keyof typeof LoginMethod];

// user's group membership status
export const GROUP_MEMBERSHIP_STATUS_JOINED = 'groupJoined'; // user a member | owner of the group
export const GROUP_MEMBERSHIP_STATUS_REQUEST_TO_JOIN = 'groupRequestToJoin'; // user has requested to join a group

type SignUpStore = {
    isEmailVerified: boolean;
    email: string;
    phone: string;
    password: string;
    location: Location | null;
    country: string | null;
    firstName: string;
    lastName: string;
    dateOfBirth: Date | undefined;
    profileVisibility: 'public' | 'private';
    isThirteen: boolean;
    acceptedConditions: boolean;
    selectedInterests: string[];
    language: string | null;
    organization?: Organization;

    loginMethod: LoginMethodType;

    _id: string | null; // group _id
    isSignUpWithGroupCode: boolean;
    isSignUpWithSearch: boolean;
    groupCode: string; // group shortCode
    activeGroup?: Group; // active group to join ^^ based on shortcode or groupID

    paymentMethodToken?: string | undefined; // token for storing a payment method for subscriptions
    isGuestCheckout?: boolean;

    groupQuestions: { [groupId: string]: CustomSignupField[] };
    groupQuestionsLoading: { [groupId: string]: boolean };
    networkQuestionsSkipped: boolean;

    groupsToJoin: string[];
    groupsJoinedAndRequested: { group: Group; status: string }[];

    postSignupStep: PostSignupStep;
    groupQuestionsIndex: number;
    groupsWithQuestions: string[];
    groupCarouselIndex: number;

    claimToken: string | undefined; // user claim token

    usedGroupCode: boolean;
};

const signUpStore = createStore('signUp', {
    persist: { name: 'signUpStore', enabled: true },
})<SignUpStore>({
    isEmailVerified: false,
    email: '',
    phone: '',
    password: '',
    location: null,
    country: null,
    firstName: '',
    lastName: '',
    dateOfBirth: undefined,
    profileVisibility: 'private',
    isThirteen: false,
    acceptedConditions: false,
    selectedInterests: [],
    language: null,
    organization: undefined,

    loginMethod: LoginMethod.email,

    _id: '',
    isSignUpWithGroupCode: false,
    isSignUpWithSearch: false,
    isGuestCheckout: false,
    groupCode: '',
    activeGroup: undefined,
    paymentMethodToken: undefined,

    groupQuestions: {},
    groupQuestionsLoading: {},
    networkQuestionsSkipped: false,

    groupsToJoin: [],
    groupsJoinedAndRequested: [],

    postSignupStep: POST_SIGNUP_STEPS.createProfile,
    groupQuestionsIndex: 0,
    groupsWithQuestions: [],

    groupCarouselIndex: 0,

    claimToken: undefined,

    usedGroupCode: false,
})
    .extendSelectors(store => ({
        hasSelectedInterests: () => store.selectedInterests.length > 0,
        groupQuestionsById: (groupId: string) => store.groupQuestions[groupId],
        groupQuestionsLoadingById: (groupId: string) => store.groupQuestionsLoading[groupId],
    }))
    .extendActions((set, get) => ({
        addGroupQuestions: (groupId: string, questions: any[], isNetworkGroup: boolean) => {
            const existingQuestions = get.groupQuestions();
            existingQuestions[groupId] = questions;
            set.groupQuestions({ ...existingQuestions });

            const filteredQuestions = filterSignupQuestions(questions);
            if (filteredQuestions?.length > 0 && !isNetworkGroup) {
                set.groupsWithQuestions([...get.groupsWithQuestions(), groupId]);
            }
        },
        groupLoading: (groupId: string, isLoading: boolean) => {
            const existingLoading = get.groupQuestionsLoading();
            existingLoading[groupId] = isLoading;
            set.groupQuestionsLoading({ ...existingLoading });
        },
        setGroupToJoin: (groupId: string) => {
            const existingGroups = get.groupsToJoin();

            set.groupsToJoin([...existingGroups, groupId]);
        },
        deselectGroupToJoin: (groupId: string) => {
            const existingGroups = get.groupsToJoin();

            set.groupsToJoin([...existingGroups.filter(id => id !== groupId)]);
        },
        setGroupsJoinedAndRequested: groupsJoinedAndRequested => {
            let existingActiveGroup = [];
            const isSignUpWithGroupCode = signUpStore.get.isSignUpWithGroupCode();
            const activeGroup = get.activeGroup(); // get the active group
            let approvalRequired = activeGroup?.settings?.approvalRequired ?? false;
            // A group code acts like an automatic pre-approved invitation to join.
            // Users can use the group code and enter the group without getting vetted.
            // settings?.shortCodeApprovalRequired > settings.approvalRequired
            // A group admin must approve a user to join if settings?.shortCodeApprovalRequired is enabled
            if (isSignUpWithGroupCode) {
                approvalRequired = activeGroup?.settings?.shortCodeApprovalRequired;
            }

            if (activeGroup) {
                let status = GROUP_MEMBERSHIP_STATUS_JOINED;
                // manually set the status of the active group joined via a groupcode
                if (activeGroup?.settings?.protectionLevel === VIEW_PERMISSION_PUBLIC) {
                    status = GROUP_MEMBERSHIP_STATUS_JOINED;
                } else if (
                    activeGroup?.settings?.protectionLevel === VIEW_PERMISSION_PRIVATE &&
                    approvalRequired
                ) {
                    status = GROUP_MEMBERSHIP_STATUS_REQUEST_TO_JOIN;
                }
                existingActiveGroup = [{ group: get.activeGroup(), status }];
            }

            // merge active group with incoming groups joined and requested
            const existingGroupsJoinedAndRequested = get.groupsJoinedAndRequested();

            // merge all existing groups with incoming groups joined and requested
            const allGroupsJoinedAndRequested = [
                ...existingActiveGroup,
                ...existingGroupsJoinedAndRequested,
                ...groupsJoinedAndRequested,
            ];

            // dedupe groups
            const dedupedGroupsJoinedAndRequested = _.uniqBy(
                allGroupsJoinedAndRequested,
                ({ group }) => {
                    return group?._id;
                }
            );

            // set groupsJoinedAndRequested
            set.groupsJoinedAndRequested(dedupedGroupsJoinedAndRequested);
        },
        completeGuestCheckout: (email: string) => {
            set.isGuestCheckout(false);
            set.email(email);
        },
        resetSignupStore: () => {
            set.isEmailVerified(false);
            set.email('');
            set.phone('');
            set.password('');
            set.location(null);
            set.country(null);
            set.firstName('');
            set.lastName('');
            set.dateOfBirth(undefined);
            set.profileVisibility('private');
            set.isThirteen(false);
            set.acceptedConditions(false);
            set.selectedInterests([]);
            set.language(null);
            set.organization(undefined);

            set.loginMethod(LoginMethod.email);

            set._id('');
            set.isSignUpWithGroupCode(false);
            set.isSignUpWithSearch(false);
            set.groupCode('');
            set.activeGroup(undefined);
            set.paymentMethodToken(undefined);

            set.groupQuestions({});
            set.groupQuestionsLoading({});
            set.networkQuestionsSkipped(false);

            set.groupsToJoin([]);
            set.groupsJoinedAndRequested([]);

            set.postSignupStep(POST_SIGNUP_STEPS.createProfile);

            set.groupQuestionsIndex(0);
            set.groupsWithQuestions([]);

            set.groupCarouselIndex(0);

            set.claimToken(undefined);

            set.usedGroupCode(false);
        },
    }));

export default signUpStore;
