import React from 'react';
import { Updater } from 'use-immer';

import SurveyQuestionForm from '@components/content/newpost/forms/Survey/SurveyQuestionForm';
import ThumbnailAndCover from '@components/generic/editscreen/ThumbnailAndCover';
import TextInput from '@dsc/forms/textInputs/TextInput';
import MultiCustomInput from '@dsc/forms/customInputs/MultiCustomInput';
import SkillAndTextLabel from '@components/content/newpost/forms/Course/SkillAndTextLabel';

import { curriedStateSlice } from '@helpers/state/state.helpers';

import { defaultQuestion } from '@helpers/surveys/survey.helpers';

import { SurveyFormMainState } from '@core/types/Surveys';

type SurveyFormMainProps = {
    state: SurveyFormMainState;
    setState: Updater<SurveyFormMainState>;
};

const SurveyFormMain = React.forwardRef<HTMLFieldSetElement, SurveyFormMainProps>(
    function SurveyFormMain({ state, setState }, ref) {
        const updateSlice = curriedStateSlice(setState);

        const handleUpdateState = (newState: Record<string, string>) => {
            if (typeof newState.thumbnail === 'string') {
                updateSlice('thumbnail', newState.thumbnail);
            }

            if (typeof newState.coverImage === 'string') {
                updateSlice('coverImage', newState.coverImage);
            }
        };

        return (
            <fieldset ref={ref} className="course-form-main">
                <header>Survey Builder</header>

                <ThumbnailAndCover
                    title={state.title}
                    thumbImage={state.thumbnail}
                    coverImage={state.coverImage}
                    thumbTarget="thumbnail"
                    coverTarget="coverImage"
                    handleUpdateState={handleUpdateState}
                />

                <TextInput value={state.title} onChange={updateSlice('title')} label="Title" />

                <TextInput
                    value={state.description}
                    onChange={updateSlice('description')}
                    label="Description"
                    multiline
                />

                <MultiCustomInput
                    labels={state.questions.map((question, index) => (
                        <SkillAndTextLabel
                            key={index}
                            skill={question.skill as any}
                            label={`[${question.type}] ${question.prompt}`}
                        />
                    ))}
                    state={state.questions}
                    onChange={survey => updateSlice('questions', survey)}
                    defaultValue={defaultQuestion}
                    Component={SurveyQuestionForm}
                    modalOptions={{ className: 'full-height no-bottom-padding' }}
                    label="Questions"
                    buttonText="Add Question"
                />
            </fieldset>
        );
    }
);

export default SurveyFormMain;
