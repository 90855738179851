import React from 'react';

type ChunkyArrowRightProps = {
    size?: string;
    className?: string;
    color?: string;
};

const ChunkyArrowRight: React.FC<ChunkyArrowRightProps> = ({size="20", className = '', color = 'black' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M9.96298 19.6929C9.82594 19.6361 9.70882 19.54 9.62641 19.4166C9.544 19.2933 9.50001 19.1483 9.5 19V15.25H2C1.60231 15.2495 1.22105 15.0913 0.939842 14.8101C0.658636 14.5289 0.500455 14.1477 0.5 13.75V6.24999C0.500455 5.8523 0.658636 5.47103 0.939842 5.18983C1.22105 4.90862 1.60231 4.75044 2 4.74998H9.5V0.999985C9.50002 0.851651 9.54401 0.706653 9.62643 0.583322C9.70884 0.459991 9.82597 0.363866 9.96301 0.307099C10.1001 0.250333 10.2509 0.235474 10.3963 0.264402C10.5418 0.29333 10.6755 0.364745 10.7804 0.469619L19.7804 9.46962C19.921 9.61031 20 9.80108 20 9.99999C20 10.1989 19.921 10.3897 19.7804 10.5304L10.7804 19.5304C10.6755 19.6352 10.5418 19.7066 10.3963 19.7355C10.2508 19.7645 10.1 19.7496 9.96298 19.6929Z"
                fill={color}
            />
        </svg>
    );
};

export default ChunkyArrowRight;
