import {useMutation} from '@apollo/client';

import {CREATE_TAG} from '@shared/welibrary-graphql/tag/mutations';
import {UPDATE_TAGS_ON_CARD} from '@shared/welibrary-graphql/content_card/mutations';
import React, {useState} from 'react';

const CreateTag = props => {
    const [showNewTagInput, setShowNewTagInput] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const [newTagInput, setNewTagInput] = useState('');
    const [createTag, { data, loading, error }] = useMutation(CREATE_TAG);
    const [
        updateTagsOnCard,
        { data: updateTagsCardData, loading: updateTagsCardLoading, error: updateTagsCardError },
    ] = useMutation(UPDATE_TAGS_ON_CARD);
    // const { error: getTagsError, loading: getTagsLoading, data: getTagsData, refetch: getTagsRefetch } = useQuery(GET_TAGS);

    const handleNewTagInput = e => {
        if (statusMsg) {
            setStatusMsg('');
        }
        setNewTagInput(e.currentTarget.value);
    };

    const handleOnBlur = e => {
        setStatusMsg('');
    };

    const handleAddNewTag = () => {
        if (newTagInput.trim() !== '') {
            createTag({
                variables: {
                    input: {
                        label: newTagInput,
                    },
                },
            })
                .then(res => setStatusMsg('Successfully created new tag!'))
                .catch(e => setStatusMsg('There was an error creating a new tag.'));
            setNewTagInput('');
        } else {
            setStatusMsg('Tag name cannot be blank');
        }
    };
    return (
        <>
            <div>
                <NewTagInputField
                    handleNewTagInput={handleNewTagInput}
                    handleOnBlur={handleOnBlur}
                    handleAddNewTag={handleAddNewTag}
                    statusMsg={statusMsg}
                    newTagInput={newTagInput}
                />
            </div>
        </>
    );
};

const NewTagInputField = props => {
    const { handleNewTagInput, newTagInput, handleAddNewTag, statusMsg, handleOnBlur } = props;
    const handleInputChange = e => handleNewTagInput(e);
    const handleAdd = e => handleAddNewTag();
    return (
        <div className="new-tag-input" style={{ marginBottom: '20px' }}>
            <div id="new-tag-form" className="search-field-container new-tag-form">
                <input
                    onChange={handleInputChange}
                    onBlur={handleOnBlur}
                    placeholder="Create New Tag..."
                    type="text"
                    className="text-field searchfield w-input hashtag-icon"
                    maxLength="256"
                    name="search-community-page"
                    id="search-community-page"
                    value={newTagInput}
                />
                <button onClick={handleAdd} className="plus-sign-tag w-inline-block"></button>
            </div>
            <div className="status-msg" style={{ marginTop: '10px' }}>
                {statusMsg}
            </div>
        </div>
    );
};

export default CreateTag;
