import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCopyTextToClipboard } from '@components/generic/hooks/useCopyTextToClipboard';

type CopyableTextProps = {
    text: string;
    className?: string;
};

const CopyableText: React.FC<CopyableTextProps> = ({ text, className = '' }) => {
    const { t } = useTranslation();

    const { copyTextToClipboard } = useCopyTextToClipboard();

    return (
        <section className={`dsc-display-copyableText ${className}`}>
            <span>{text}</span>
            <button
                type="button"
                onClick={() => {
                    copyTextToClipboard(text);
                }}
            >
                {t('common:copy', 'Copy')}
            </button>
        </section>
    );
};

export default CopyableText;
