import { ConfigInputType } from '@config/schemas/main';
import EventTypes from '@core/utilities/constants/events';
import {
    CARD_CREATION,
    CARD_STORY,
    CARD_SURVEY,
    CARD_RAINBOW_ROAD,
    CARD_DIALOG,
} from '@core/utilities/constants/card_types';

const config: ConfigInputType = {
    public: {
        root_url: 'staging.welibrary.io',
        apiUrl: 'api.staging.welibrary.io',
        deep_link_domains: ['https://staging.welibrary.io'],

        configName: 'staging-welibrary',
        captcha: {
            key: '6Lc8MlUiAAAAAC5sMITt02hJ_bKRE4OzhnhSiufh',
        },
        displayPlatformFormspec: true,
        copyright: 'WeLibrary, LLC',
        optionalPostTypes: [CARD_CREATION, CARD_STORY, CARD_SURVEY, CARD_RAINBOW_ROAD, CARD_DIALOG],
        eventTypes: [EventTypes.EVENT_GROUP, EventTypes.EVENT_LISTING],
        siteName: 'WeLibrary - Staging',
        metaInfo: {
            title: 'WeLibrary - Staging',
            description:
                'WeLibrary helps you think better - organize and prioritize content by relevance, quality & sentiment.',
            image: '/images/logo.welibrary.blue.svg',
            url: 'staging.welibrary.io',
        },

        filestack: { api_key: 'AWnnnSe6qS8aHSc5KK4Zuz', filePreview: true },
        unsplash: { access_key: 'NTNacJWbx9pMW5yHizHSqWr1IpQXmTJTuQ08VZ7pSEg' },
        Google: { Maps_API_Key: 'AIzaSyBDfzEYNC2sHOYi9OEwmwNVVtOMDFfUV5I' },
        sentry: {
            dsn: 'https://a93523418e50428c9feb61bb8de0a344@o246842.ingest.sentry.io/5199007',
        },
        userflow: { api_key: 'ct_w53eaxhevvf2vejzrecekeq3nu' },
        hidePlatformGroupHeaderDisplay: true,
        showGettingStarted: true,
        rootGroupId:'Zx2vMWkNsGtvRzwbq',
        firebaseConfig: [
            {
                hosts: ['welibrary.io', 'lerhub.org', 'localhost'],
                appId: 'com.welibrary.app',
                config: {
                    apiKey: 'AIzaSyAdqwUuzbZoS6s3H6HkR9nWyNPVEDcjmWQ',
                    authDomain: 'welibrary-native.firebaseapp.com',
                    databaseURL: 'https://welibrary-native.firebaseio.com',
                    projectId: 'welibrary-native',
                    storageBucket: 'welibrary-native.appspot.com',
                    messagingSenderId: '847997887601',
                    appId: '1:847997887601:web:90069dce228246d35127d0',
                    measurementId: 'G-DXE742J9YQ',
                },
            },
            {
                hosts: ['internetofeducation.org'],
                appId: 'com.internetofeducation.app',
                config: {
                    apiKey: 'AIzaSyAdqwUuzbZoS6s3H6HkR9nWyNPVEDcjmWQ',
                    authDomain: 'welibrary-native.firebaseapp.com',
                    databaseURL: 'https://welibrary-native.firebaseio.com',
                    projectId: 'welibrary-native',
                    storageBucket: 'welibrary-native.appspot.com',
                    messagingSenderId: '847997887601',
                    appId: '1:847997887601:web:3c5f63a1809f6ccf5127d0',
                    measurementId: 'G-XBT3YY8P0B',
                },
            },
            {
                hosts: ['legosuperskillslab.org'],
                appId: 'com.legossl.app',
                config: {
                    apiKey: 'AIzaSyAdqwUuzbZoS6s3H6HkR9nWyNPVEDcjmWQ',
                    authDomain: 'welibrary-native.firebaseapp.com',
                    databaseURL: 'https://welibrary-native.firebaseio.com',
                    projectId: 'welibrary-native',
                    storageBucket: 'welibrary-native.appspot.com',
                    messagingSenderId: '847997887601',
                    appId: '1:847997887601:web:3c5f63a1809f6ccf5127d0',
                    measurementId: 'G-XBT3YY8P0B',
                },
            },
        ],
        torus: {
            verifier: 'staging-welibrary',
            network: 'testnet',
        },
        stripe: {
            client_api_key: 'pk_test_S9NV67ltFVkEFi1X7VfMwG0N0016slSnqr',
            secret_webhook_key: 'whsec_wB0dQ9CYdM745ZgYF4HhC7MK462bc5wk',
        },

        defaultMembershipUrl: 'welibrary',
        defaultGroupCode: 'NATHAN',
        restrictSignupToDefaultMembership: false,

        privacyPolicy: 'www.welibrary.io/privacy-policy',
        termsOfService: 'www.learningeconomy.io/terms-of-service',
        templatesUrl: 'templates-64121',

        defaultGroupChannelTemplateUrl: 's9Yvh7HQoEEXdBxBA',
        defaultChannelTemplateUrl: 'r6Z3JZp4Z4xAXuGGc',

        allowBlankBookCreation: true,

        defaultLogoBackup: 'https://cdn.filestackcontent.com/4R8SRB6PRdyGXDP4ZZUn',
        defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/TmGQQbJ7TKFbJcINLNQZ',
        defaultLogoBackupWhiteAlt: '',
        defaultLogoBlack: 'https://cdn.filestackcontent.com/PX17Zaw5TpyrJAdvu8bM',

        homeButton: {
            picture: 'https://staging.welibrary.io/images/defaults/cover_images/CoverPhoto-11.jpg',
            title: 'Nathan Society',
            groupId: 'Zx2vMWkNsGtvRzwbq',
        },

        pushSenderID: 847997887601,
        pushLogo: 'https://www.welibrary.io/native/welibrary/notifications/we_notification.png',

        recommendationsMatchmakerWhitelist: [],

        userSnap: false,
        userSnapTesterEmails: ['chris@ed30.com', 'jackson@learningeconomy.io'],

        bulkUserTemplate:
            'https://docs.google.com/spreadsheets/d/1kkqURN4VHJuj6KZVMMSRRuWGdV_KvdWz5BeoYj9q1SQ/edit?usp=sharing',
        bulkGenerateUserTemplate:
            'https://docs.google.com/spreadsheets/d/1LSN6MLgMmyHToHGpA4EEHZFtGPjLWxn3GsmbgM71Rdw/edit?usp=sharing',

        brandColor1: '#0094f6',
        brandColor2: '#7a0cff',

        companyAddress: '406 Lazy Creek Lane, Nashville, TN, 37211',

        pwaServiceWorker: '/sw.js',

        pledge: {
            new_recruit_sms_override:
                "Congratulations on your decision to become a volunteer. ('New Recruit')",
            failed_recruit_sms_override:
                "Congratulations on your decision to become a volunteer. (Not a 'New Recruit')",
            already_recruit_sms_override:
                "Hello, I’m glad you're already a volunteer. ('Existing')",
        },

        domainBranding: {
            'welibrary': {
                root_url: 'staging.welibrary.io',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/GAbl7rmQQvmhUfQ1OxaH'
            },
            'lerhub.org': {
                root_url: 'lerhub.org',
                siteName: 'LER Hub',
                emailFrom: 'T3 Innovation Network <no-reply@welibrary.io>',

                defaultLogoBackup: 'https://cdn.filestackcontent.com/ixldHUo4QViKY9ldt255',
                defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/ixldHUo4QViKY9ldt255',
                defaultLogoBlack: 'https://cdn.filestackcontent.com/ZsEd7qlgS7a5ZLjHTpEV',

                privacyPolicy: 'www.uschamber.com/privacy-policy',

                brandColor1: '#103B5C',
                brandColor2: '#5acae8',

                copyright: 'T3 Innovation Network',
                companyAddress: '1133 21st St NW, Washington, D.C.',

                defaultGroupCode: 'LERHUB',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/9AiBMl6ATvKvsQTwvt6p',

                disableServiceWorker: true,

                favicon: '/images/favicon/t3/favicon.ico',

                root_redirect: '/g/muEoJqvS6bkfZr6e5',
                root_redirect_loggedin: '/dashboard',

                slideMenu: { search: false },

                secondaryNavigation: {
                    items: {
                        mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                        desktop: [
                            'groups',
                            'home',
                            'messages',
                            'discover',
                            'notifications',
                            'profile',
                        ],
                    },
                },

                disablePersistedCache: true,
            },
            internetofeducation: {
                root_url: 'app.internetofeducation.org',
                siteName: 'Internet of Education',
                emailFrom: 'Internet of Education <no-reply@welibrary.io>',

                defaultLogoBackup: 'https://cdn.filestackcontent.com/ToXAY8i3TWCkJxPgi15g',
                defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/fORSVkqKRgqAm7eQ2xoW',
                defaultLogoBlack: 'https://cdn.filestackcontent.com/rBkM4keCTAueW4d3Fpj4',

                privacyPolicy: 'www.welibrary.io/privacy-policy',
                termsOfService: 'www.learningeconomy.io/terms-of-service',

                brandColor1: '#ffeb3b',
                brandColor2: '#000000',

                copyright: 'Learning Economy',

                defaultGroupCode: 'IOEDU',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/JGFtsqlSQlaqm34J2M7M',

                disableServiceWorker: true,

                favicon: '/images/favicon/ioe/favicon.ico',

                root_redirect: '/g/u3ddFa6AgBLwmETdB',
                root_redirect_native: '/signup',
                root_redirect_loggedin: '/dashboard',

                slideMenu: { search: false },

                secondaryNavigation: {
                    items: {
                        mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                        desktop: ['home', 'messages', 'search', 'notifications', 'profile'],
                    },
                },
            },
            legossl: {
                root_url: 'app.legosuperskillslab.org',
                siteName: 'Lego Super Skills Lab',
                emailFrom: 'Lego Super Skills Lab <no-reply@welibrary.io>',

                defaultLogoBackup: '/images/IoE.logo.brandmark black.svg',
                defaultLogoBackupWhite: '/images/IoE.logo.brandmark.svg',
                defaultLogoBlack: '/images/ioe.logo.full.black.svg',

                privacyPolicy: 'www.welibrary.io/privacy-policy',
                termsOfService: 'www.learningeconomy.io/terms-of-service',

                brandColor1: '#ffeb3b',
                brandColor2: '#000000',

                copyright: 'Learning Economy',

                defaultGroupCode: 'IOEDU',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/JGFtsqlSQlaqm34J2M7M',

                disableServiceWorker: true,

                favicon: '/images/favicon/ioe/favicon.ico',

                root_redirect: '/g/u3ddFa6AgBLwmETdB',
                root_redirect_native: '/signup',
                root_redirect_loggedin: '/dashboard',

                slideMenu: { search: false },

                secondaryNavigation: {
                    items: {
                        mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                        desktop: ['home', 'messages', 'discover', 'notifications', 'profile'],
                    },
                },
            },
        },
        slideMenu: {
            search: true,
            actionItems: true,
            discover: true,
            rainbowRoad: true,
            showBookmarkIcon: true,
            showConnectionsIcon: true,
        },

        locize: {
            projectId: '662a9e61-2900-409c-a73e-6ba0bbfc3f38',
            apiKey: '3ff8b824-d205-42ea-be99-a366868d9f73',
            referenceLng: 'en',
        },

        ceramic: {
            nodeEndpointUrl: 'https://ceramic-node.staging.welibrary.io:7007',
            contentFamily: 'SuperSkills-local',
            definitions: {
                MyVerifiableCredentials:
                    'kjzl6cwe1jw1461695j0dmtvjmtcjhqdtx63calspn0r5p2ti110pzbkboabozr',
            },
            schemas: {
                AchievementVerifiableCredential:
                    'ceramic://k3y52l7qbv1frybe3lenawavapwpg38ut3enaqrw03s8nemi1ycq6lvsg1nifb37k',
                VerifiableCredentialsList:
                    'ceramic://k3y52l7qbv1fryp70yk4y1xv2aedxjnkrbdezyot13gnjkyrdn6lhs1k5irkosl4w',
            },
        },

        appflow: {
            liveUpdate: {
                appId: 'ff5def27',
                channel: 'staging-welibrary',
                updateMethod: 'none',
            },
        },

        awsMetrics: {
            namespace: 'WeLibrary/Client/Staging',
        },

        eventOrganizerTermsOfUseUrl:
            'https://www.google.com/search?q=terms+of+use&oq=terms+of+use&aqs=chrome..69i57j0i512l5j69i60l2.1230j0j1&sourceid=chrome&ie=UTF-8',
        ticketPurchaserTermsOfUseUrl:
            'https://www.google.com/search?q=terms+of+use&oq=terms+of+use&aqs=chrome..69i57j0i512l5j69i60l2.1230j0j1&sourceid=chrome&ie=UTF-8',

        launchDarklyConfig: {
            clientSideID: '64514ca1c158f01311e773ae',
            context: {
                kind: 'user',
                key: 'anonymous',
            },
        },
    },
};

export default config;
