import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useModal from '@components/modals/hooks/useModal';
import usePathQuery from '@components/generic/hooks/usePathQuery';

import SimpleProfile from '@components/profile/Lego/SimpleProfile';
import LegoNavDesktop from '@components/navigation/Lego/LegoNavDesktop';
import LegoNavMobile from '@components/navigation/Lego/LegoNavMobile';

const LegoNav = () => {
    const { newModal, closeModal } = useModal();
    const pathQuery = usePathQuery();
    const history = useHistory();

    const handleSimpleProfileModal = () => {
        newModal(<SimpleProfile openInEditMode randomize />, {
            className: 'no-top-padding no-bottom-padding no-notch full-height full-screen',
            hideButton: true,
            customCloseButton: true,
            onClose: () => history.push('/my-quests'),
        });
    };

    useEffect(() => {
        // trigger simple-profile-editor on initial signup
        // if query param exists
        if (pathQuery.get('profile-editor') === 'open') handleSimpleProfileModal();
        else closeModal();
    }, [pathQuery.get('profile-editor')]);

    return (
        <>
            <LegoNavDesktop />
            <LegoNavMobile />
        </>
    );
};

export default LegoNav;
