import { ConfigInputType } from '@config/schemas/main';
import EventTypes from '@core/utilities/constants/events';

const config: ConfigInputType = {
    public: {
        root_url: 'connectapp.live',
        apiUrl: 'wl.api.connectapp.live',
        whitelabel_url: 'connectapp.live',
        deep_link_domains: [
            'https://app.commissioned.global',
            'https://app.becommissioned.com',
            'https://app.inthezone.social',
            'https://inthezone.social',
            'https://www.inthezone.social',
            'https://connectapp.live',
            'https://www.connectapp.live'
        ],

        configName: 'production-connect',
        captcha: {
            key: '6LdHxaglAAAAABFfI7N2Ru-e_8s3XlioNvjbar_h',
        },
        siteName: 'connect.',
        metaInfo: {
            title: 'connect.',
            description: 'A place for youth serving organizations to be properly equipped to reach, engage, and guide their young people daily. ',
            image: 'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/OV8r2vFtS1W1OQGj9DDh',
            url: 'connectapp.live',
        },
        displayPlatformFormspec: false,
        copyright: 'Connect-ITZ',

        eventTypes: [EventTypes.EVENT_GROUP, EventTypes.EVENT_LISTING],

        rootGroupId: '63fe33a0c9f8e7afd736a591',
        hideRootGroupDeleteButton: true,

        filestack: { api_key: 'A7RsW3VzfSNO2TCsFJ6Eiz', filePreview: true },
        unsplash: { access_key: 'NTNacJWbx9pMW5yHizHSqWr1IpQXmTJTuQ08VZ7pSEg' },
        Google: { Maps_API_Key: 'AIzaSyBDfzEYNC2sHOYi9OEwmwNVVtOMDFfUV5I' },
        GATracking: { GA_ID: 'G-19TS27BC0B' },
        sentry: {
            dsn: 'https://bd4f25c0ac1c4278b822ee7b34fdb036@o246842.ingest.sentry.io/5199016',
        },
        stripe: {
            client_api_key:
                'pk_live_51OjRTcJoSzZ9dRq8A7qOxi8WvUMGMGvotxE7VqO9dsNC9AUeswcVhlDtPqi2ZMXbi4NyTBs19fV53Mazk5xVlALI00QPFoQSob',
            secret_webhook_key: 'whsec_4HKEI4s10JOAcr2Y5z6ge8TOfRvNziKB',
        },
        userflow: { api_key: 'ct_juxovxun2bgsnadlwu3sty73iy' },
        showGettingStarted: true,
        firebaseConfig: [
            {
                hosts: ['inthezone.social', 'localhost', 'connectapp.live'],
                appId: 'com.itz.app',
                config: {
                    apiKey: 'AIzaSyDHxIT_1fye-P4s7fmMPbwTULuSZUFYpo8',
                    authDomain: 'in-the-zone-1996f.firebaseapp.com',
                    projectId: 'in-the-zone-1996f',
                    storageBucket: 'in-the-zone-1996f.appspot.com',
                    messagingSenderId: '748105199787',
                    appId: '1:748105199787:web:5ab9dc944f7c12b9cbc7ee',
                    measurementId: 'G-19TS27BC0B',
                },
            },
        ],
        defaultMembershipUrl: 'welibrary',
        defaultJoinInstructions: 'commissioned_join_instructions',
        defaultGroupCodePlaceholder: 'commissioned_shortcode_placeholder',
        defaultGroupCode: 'INTHEZONE',
        hideGroupHeaderInfoFromLoggedOutUsers: true,
        homeButton: {
            picture: 'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/e32QmxZQhy7jFOCA96HS',
            title: 'connect.',
            groupId: '63fe33a0c9f8e7afd736a591',
        },

        homeGroups: {
            groupIds: ['63fe33a0c9f8e7afd736a591', '8y2CNH8ToRXA4svzR'],
        },
        disableInlinePrefilledSignupLink: true,
        restrictSignupToDefaultMembership: true,

        communityGuidelines: 'connect-itz.com/community-guidelines/',
        privacyPolicy: 'connect-itz.com/privacy-policy/',
        termsOfService: 'connect-itz.com/terms-of-use/',
        templatesUrl: 'templates',

        userGuide: 'connect-itz.com/contact-support',

        defaultLogoBackup:
            'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/OV8r2vFtS1W1OQGj9DDh',
        defaultLogoBackupWhite:
            'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/OV8r2vFtS1W1OQGj9DDh',
        defaultLogoBackupWhiteAlt:
            'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/OV8r2vFtS1W1OQGj9DDh',
        defaultLogoBlack:
            'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/9xVJzWWTYSS1jLQmo7Hr',

        pushSenderID: 541036629111,
        pushLogo: 'co_notification',

        recommendationsMatchmakerWhitelist: ['welibrary'],

        userSnap: false,
        userSnapOverrideSrc: '//api.usersnap.com/load/81dfe074-2710-40d9-b130-d0c9be1c2c30.js',
        userSnapTesterEmails: [
            'jkramer@pneuma33.com',
            'jkramer@commissioned.io',
            'sthomas@pneuma33.com',
            'mriddell@commissioned.io',
            'michelle43770@yahoo.com',
            'kmonaghan@commissioned.io',
        ],

        bulkUserTemplate:
            'https://docs.google.com/spreadsheets/d/1kkqURN4VHJuj6KZVMMSRRuWGdV_KvdWz5BeoYj9q1SQ/edit?usp=sharing',
        bulkGenerateUserTemplate:
            'https://docs.google.com/spreadsheets/d/1LSN6MLgMmyHToHGpA4EEHZFtGPjLWxn3GsmbgM71Rdw/edit?usp=sharing',

        brandColor1: '#000000',
        brandColor2: '#00A651',
        favicon: '/images/favicon/connect/favicon.ico',
        theme: {
            primaryColor: '#00A651',
            primaryAccent: '#BDC4C9',
            primaryHighlight: '#81d742',
            primaryShade: '#007338',
            thumbHighlight: '#00A651',
            secondaryColor: '#215D7F',
            slideBackground:
                'url(https://cdn.filestackcontent.com/auto_image/1Wjcy7OYQCqn7b6brH4Z)',
            emptyBackground:
                'url(https://cdn.filestackcontent.com/auto_image/rZZ11ay3TYCJZNyJ1dWq)',
            coffeeBackground:
                'url(https://cdn.filestackcontent.com/auto_image/inBO4fCkSxWq6ph1lbtw)',
            docBackground: 'url(https://cdn.filestackcontent.com/auto_image/Efr6ADNQQrJsAKtcUFZb)',
        },

        companyAddress: '1999 Richmond Road, Suite 300 Lexington, KY 40502',

        allowAnonymousSignups: false,
        hidePlatformGroupHeaderDisplay: true,

        pledge: {
            new_recruit_template: 'd-61eb02daf35440d59672a2acbea1a688',
            new_recruit_sms_override:
                'Congratulations on your decision to follow Jesus. To grow stronger go to https://comm.gl/frstgr. Join https://comm.gl/omwnew to grow in this faith adventure!',
            failed_recruit_template: 'd-beedc18c284c43789a2e010ceffde9e8',
            failed_recruit_sms_override:
                'Hello. Thanks for letting me share about my faith-story. I thought you might be interested in checking out my website www.frostygrapes.com. Enjoy!',
            already_recruit_template: 'd-5402723fa68b406eb9b95e9dd8504bbf',
            already_recruit_sms_override:
                'Hello, I’m glad that you’re a follower of Jesus. Learn how to tell your faith-story with pre-believers https://comm.gl/omw. You will have great joy sharing!',
            new_recruit_claim_reminder_template: 'd-4fdcddd98bc74803959ad4a51fe02b77',
            new_recruit_claim_reminder_two_template: 'd-081324f4d43048c9bfdb9e2b4196d771',
        },

        domainBranding: {
            commissioned: {
                root_url: 'app.commissioned.global',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/MVyrZRrrRSC6gXGdBHYc',
                defaultGroupCode: 'COMMISSIONED',
            },
            localhost: {
                root_url: 'connectapp.live',
                defaultCoverPhoto:
                    'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/CtFHgrddR9ODBYJIcko8',
                defaultGroupCode: 'INTHEZONE',
            },
            inthezone: {
                root_url: 'connectapp.live',
                defaultCoverPhoto:
                    'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/CtFHgrddR9ODBYJIcko8',
                defaultGroupCode: 'INTHEZONE',
            },
            connect: {
                root_url: 'connectapp.live',
                defaultCoverPhoto:
                    'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/vc0NWZY6SZr4H9tdaMUw',
                defaultGroupCode: 'INTHEZONE',
            },
        },
        slideMenu: {
            actionItems: true,
            mobilization: true,
            search: false,
            discover: false,
            footerText: 'common:global.patent_pending',
            showConnectionsIcon: true,
            showBookmarkIcon: true,
        },

        secondaryNavigation: {
            items: {
                mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                desktop: ['groups', 'home', 'messages', 'notifications', 'profile'],
            },
        },

        locize: {
            projectId: '662a9e61-2900-409c-a73e-6ba0bbfc3f38',
            apiKey: '3ff8b824-d205-42ea-be99-a366868d9f73',
            referenceLng: 'en',
        },

        ceramic: {
            nodeEndpointUrl: 'https://ceramic-node.welibrary.io:7007',
            contentFamily: 'SuperSkills',
            definitions: {
                MyVerifiableCredentials:
                    'kjzl6cwe1jw14am5tu5hh412s19o4zm8aq3g2lpd6s4paxj2nly2lj4drp3pun2',
            },
            schemas: {
                AchievementVerifiableCredential:
                    'ceramic://k3y52l7qbv1frylibw2725v8gem3hxs1onoh6pvux0szdduugczh0hddxo6qsd6o0',
                VerifiableCredentialsList:
                    'ceramic://k3y52l7qbv1frxkcwfpyauky3fyl4n44izridy3blvjjzgftis40sk9w8g3remghs',
            },
        },

        appflow: {
            liveUpdate: {
                appId: '41fef2ff',
                channel: 'production-connect',
                updateMethod: 'none',
            },
        },

        eventOrganizerTermsOfUseUrl: 'https://connect-itz.com/event-organizer-terms/',
        ticketPurchaserTermsOfUseUrl: 'https://connect-itz.com/ticket-purchaser-terms/',

        launchDarklyConfig: {
            clientSideID: '6605e519a3414f0fc3b197cc',
            context: {
                kind: 'user',
                key: 'anonymous',
            },
        },
    },
};

export default config;
