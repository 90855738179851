// src/content_card/queries.ts
import { gql as gql7 } from "@apollo/client";

// src/content_card/fragments.ts
import { gql as gql3 } from "@apollo/client";

// src/comment/fragments.ts
import { gql } from "@apollo/client";
var COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    __typename
    _id
    publish
    stream {
      __typename
      _id
    }
    creator {
      __typename
      _id
      profile {
        picture
        full_name
      }
    }
    body
    loggedInUserCanEdit
    childCount
    parentId
  }
`;

// src/tag/fragments.ts
import { gql as gql2 } from "@apollo/client";
var TAG_FRAGMENT = gql2`
    fragment TagFragment on Tag {
        __typename
        _id
        label
        color
        created
        updated
    }
`;

// src/content_card/fragments.ts
var UPDATE_FEATURE_CONSTRAINTS_FRAGMENT = gql3`
  fragment UpdateFeatureConstraintsFragments on ContentCard {
    __typename
    updateFeatureConstraints {
      canEditAllFields
      featuresUserCanEdit {
        type
        field
        options
        canUpdate
      }
    }
  }
`;
var CONTENT_CARD_SEARCH_FRAGMENT = gql3`
  fragment ContentCardSearchFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    media {
      icon
      thumb
    }
    prompt
    isBookmarked
    isArchived
  }
`;
var CONTENT_CARD_COURSE_SEARCH_FRAGMENT = gql3`
  fragment ContentCardCourseSearchFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    media {
      icon
      thumb
    }
    prompt
    courseItems {
      levels {
        title
        media
        resource
      }
    }
    isBookmarked
    isArchived
  }
`;
var CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT = gql3`
  fragment ViewPermissions on ContentCard {
    currentViewPermissions {
      level
      canUserView
    }
  }
`;
var CONTENT_CARD_RATINGS_FRAGMENT = gql3`
  fragment CardRatings on ContentCard {
    ratings_sentiment_score
    latest_rating_sentiments {
      _id
      profile {
        full_name
      }
    }
    ratings_quality_score
    ratings_relevance_score {
      score
      hashtag
    }
    ratings_rank
    loggedInUserVotes {
      sentiment
      quality
      relevance
    }
  }
`;
var CONTENT_CARD_BOOK_SOURCE_FRAGMENT = gql3`
  fragment ContentCardBookSourceFragment on ContentCard {
    __typename
    _id
    url
    title
    library_settings {
      displayReference
      protectionLevel
      accessRoleWhitelist
    }
    reference {
      groupSource {
        profile {
          full_name
        }
        settings {
          protectionLevel
        }
      }
    }
  }
`;
var CONTENT_CARD_REFERENCE_FRAGMENT = gql3`
  fragment ContentCardReferenceFragment on ContentCard {
    reference {
      href
      isResource
      coverImage
      chapterSource {
        title
        url
        prompt
        showTitle
        inheritSurveyAnswers
        subtype
        vote_settings {
          QUALITY {
            active
          }
          RELEVANCE {
            active
          }
          SENTIMENT {
            active
          }
        }
        library_settings {
          engagementTypes
          viewTypes
          defaultViewType
          displayReference
          displaySocialHeaders
        }
        media {
          thumb
        }
      }
      bookSource {
        ...ContentCardBookSourceFragment
      }
      shelfSource {
        title
        url
        library_settings {
          displayReference
          protectionLevel
        }
      }
      librarySource {
        title
        url
        library_settings {
          displayReference
        }
      }
      groupSource {
        _id
        subtype
        profile {
          full_name
          picture
          coverPhoto
        }
        settings {
          shortCode
          shortCodeApprovalRequired
          protectionLevel
          approvalRequired
          formSpec {
            url
          }
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        groupActionsAllowed
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
      }
    }
  }
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
`;
var CONTENT_CARD_COMMENTS_FRAGMENT = gql3`
  fragment CardCommentsFragment on ContentCard {
    __typename
    _id
    comments {
      ...CommentFragment
    }
  }
  ${COMMENT_FRAGMENT}
`;
var REVEAL_CARD_FRAGMENT = gql3`
  fragment RevealCardFragment on ContentCard {
    __typename
    type
    links {
      source
      other
    }
    media {
      thumb
      icon
      logoLight
      logoDark
    }
  }
`;
var CONTENT_CARD_CREATION_FRAGMENT = gql3`
  fragment ContentCardCreationFragment on CreationItems {
    silhouetteImage
    course {
      _id
      media {
        icon
        thumb
      }
      title
      courseItems {
        materials
        ageGroup
        duration
        people
        levels {
          title
          media
          resource
          preSurvey {
            _id
            type
            subtype
            prompt
            useImage
            image
            range
            answers
            correctAnswer
            skill
            skillSubtype
            skillMilestone
          }
          postSurvey {
            _id
            type
            subtype
            prompt
            useImage
            image
            range
            answers
            correctAnswer
            skill
            skillSubtype
            skillMilestone
          }
        }
      }
      url
    }
    levels {
      title
      description
      image
    }
  }
`;
var CONTENT_CARD_COURSE_FRAGMENT = gql3`
  fragment ContentCardCourseFragment on CourseItems {
    materials
    ageGroup
    duration
    people
    levels {
      title
      media
      video
      resource
      steps {
        text
        useImage
        image
        useVideo
        video
        fullPage
        pageBreak
        skill
        skillSubtype
        skillMilestone
      }
      preSurvey {
        _id
        type
        subtype
        prompt
        useImage
        image
        range
        answers
        correctAnswer
        skill
        skillSubtype
        skillMilestone
      }
      postSurvey {
        _id
        type
        subtype
        prompt
        useImage
        image
        range
        answers
        correctAnswer
        skill
        skillSubtype
        skillMilestone
      }
    }
    publicCompletions
  }
`;
var CONTENT_CARD_PLEDGES_FRAGMENT = gql3`
  fragment ContentCardPledgesFragment on PledgeItems {
    __typename
    _id
    pledges {
      __typename
      _id
      label
      reportingType
      canPledge
      canDeletePledge
      totalPledges
      currentUsersPledges
      currentUsersPledgesMonth: currentUsersPledges(daysAgo: 30)
      currentUsersPledgesDay: currentUsersPledges(daysAgo: 1)
      successfulPledgesAll: currentUsersSuccessfulPledges
      successfulPledgesMonth: currentUsersSuccessfulPledges(daysAgo: 30)
      successfulPledgesDay: currentUsersSuccessfulPledges(daysAgo: 1)
      hidden
    }
    pledgeKeys {
      __typename
      _id
      label
      refactoryPeriod
      limitPerRefractoryPeriod
      description
      reportingType
      hidden
    }
    pledgers {
      __typename
      _id
      profile {
        full_name
        first_name
        picture
      }
    }
    latestPledgerEngagement {
      userId
      date
    }
    totalPledges
    totalPledgers
    pledgesLastDay: totalPledges(daysAgo: 1)
    pledgesLastMonth: totalPledges(daysAgo: 30)
  }
`;
var MOBILIZE_EFFORT_FRAGMENT = gql3`
  fragment MobilizeEffortFragment on ContentCard {
    __typename
    _id
    path
    url
    shortCode
    type
    title
    body
    description
    prompt
    rankOrder
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
      }
      roles {
        isSpeakerOf
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    aliases {
      ... on GroupAliasHandle {
        url
        title
        color
        type
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
      }
      ... on AliasItem {
        url
        title
        color
        type
        item {
          _id
          __typename
          title
          body
          protectionLevel
          prompt
          author {
            __typename
            _id
            type
            profile {
              full_name
            }
          }
          reference {
            href
            coverImage
            bookSource {
              ...ContentCardBookSourceFragment
            }
          }
          media {
            thumb
            icon
          }
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            displayInNewsfeed
            userCanPost
          }
          pledgeItems {
            ...ContentCardPledgesFragment
          }
          whitelabelVerbage {
            __typename
            _id
            value
          }
          isArchived
          userHasPledgedTo
          taskHistory {
            __typename
            cursor
            hasMore
            results {
              pledgeKeyId
              label
              refactoryPeriod
              date
              supplement {
                note
                image
                document
                documentTitle
              }
              verifier {
                name
                email
                phone
                country
                note
                newRecruit
              }
              claimedAccount {
                __typename
                _id
                profile {
                  picture
                  full_name
                  email
                  phone
                  country
                }
              }
            }
            type
          }
        }
      }
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
    }
    loggedInUserCanEdit
    defaultHidden
    isBookmarked
    isArchived
    applicationItems {
      applications {
        __typename
        _id
        createdAt
        status
        data {
          values {
            id
            values
          }
        }
        user {
          __typename
          _id
          profile {
            full_name
            picture
            country
            website
            email
          }
        }
      }
    }
    postingSettings {
      type
      status
      totalPositionsCount
      acceptedCount
      specification {
        url
      }
      postingData {
        parent {
          url
        }
        values {
          id
          type
          customText
          comparator
          values
          siblingId
        }
      }
      requirementsData {
        parent {
          url
        }
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    loggedInUserApplyStatus
    isTemplate
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
`;
var CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT = gql3`
  fragment ContentCardCustomFieldsFragment on ContentCard {
    _id
    customFields {
      manager
      parent {
        url
      }
      parentGroup {
        _id
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
`;
var CONTENT_CARD_FRAGMENT = gql3`
  fragment ContentCardFragment on ContentCard {
    __typename
    _id
    vs
    path
    url
    shortCode
    type
    title
    body
    shortTitle
    description
    prompt
    commentCount
    latestComments {
      cursor
      hasMore
      type
      results {
        _id
        creator {
          _id
          profile {
            full_name
            picture
          }
        }
        body
      }
    }
    inheritSurveyAnswers
    rankOrder
    subtype
    pinnedCardIds
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
        did
      }
      roles {
        isSpeakerOf
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      title
      source
      openInModal
      other
      publisher
    }
    aliases {
      ... on GroupAliasHandle {
        url
        title
        color
        thumb
        icon
        type
        group {
          __typename
          _id
          subtype
          isUnpublished
          profile {
            full_name
            picture
            coverPhoto
          }
          paymentSettings {
            stripeAccount {
              id
              email
              country
              charges_enabled
              payouts_enabled
              details_submitted
              external_accounts {
                object
                data {
                  country
                  currency
                }
                has_more
                url
              }
            }
            isGroupMembershipPaid
          }
          products {
            _id
            name
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
          }
          groupActionsAllowed
          currentUserOwnsMembership
          currentUserOwnsFreeSubscription
          doesSellMembership
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
        }
      }
      ... on MessageThreadAliasHandle {
        url
        title
        color
        thumb
        icon
        type
        thread {
          __typename
          _id
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
        }
      }
      ... on AliasItem {
        url
        title
        color
        thumb
        icon
        type
        item {
          subtype
          __typename
          _id
          title
          shortTitle
          prompt
          vote_settings {
            QUALITY {
              active
            }
            RELEVANCE {
              active
            }
            SENTIMENT {
              active
            }
          }
          type
          author {
            __typename
            _id
            type
            profile {
              full_name
            }
          }
          reference {
            href
            coverImage
            shelfSource {
              title
              url
              library_settings {
                displayReference
                protectionLevel
              }
            }
            bookSource {
              ...ContentCardBookSourceFragment
            }
            groupSource {
              _id
              subtype
              profile {
                full_name
                picture
                coverPhoto
              }
            }
          }
          media {
            thumb
            icon
          }
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            protectionLevel
            displayInNewsfeed
            userCanPost
          }
          pledgeItems {
            ...ContentCardPledgesFragment
          }
          whitelabelVerbage {
            __typename
            _id
            value
          }
          isArchived
          userHasPledgedTo
          taskHistory {
            __typename
            cursor
            hasMore
            results {
              pledgeKeyId
              label
              refactoryPeriod
              date
              supplement {
                note
                image
                document
                documentTitle
              }
              verifier {
                name
                email
                phone
                country
                note
                newRecruit
              }
              claimedAccount {
                __typename
                _id
                profile {
                  picture
                  full_name
                  email
                  phone
                  country
                }
              }
            }
            type
          }
        }
      }
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
      mediaMetaData {
        height
        width
      }
    }
    label {
      text
      type
    }
    formSpecificationSettings {
      type
      postingForm {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
          dependsOn {
            id
            values
          }
          groups {
            group {
              _id
              path
              groupActionsAllowed
              currentUserIsMember
              currentUserIsAdmin
              profile {
                picture
                full_name
                short_bio
              }
              settings {
                approvalRequired
                protectionLevel
              }
            }
            id
            requireApproval
          }
        }
      }
      applicationForm {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
        }
      }
      form {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
        }
      }
    }
    library_settings {
      postTypes
      engagementTypes
      filterTypes
      viewTypes
      defaultViewType
      defaultFilter
      allowPostbox
      postboxRoleWhiteList
      userCanPost
      displaySocialHeaders
      displayInNewsfeed
      showCreateChildren
      showResourceHeader
      allowLabels
      displayReference
      protectionLevel
      accessRoleWhitelist
      memberStyle
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      dashboardContentItems {
        name
        url
        type
        filter
      }
      customRoles {
        name
        url
        color
      }
      sidebarMenu {
        name
        url
      }
      recommendations
    }
    sendNotifications
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    meta {
      course {
        revealType
        revealContent {
          ...RevealCardFragment
        }
        compensation
        time
        sequence
      }
      research {
        category
        journalName
        organization
        publicationType
        abstract
        authors
        fileURL
        tags
        issue
      }
      mobilize {
        date
        qualifications
        skills
        summary
        city
        state
        country
        cta
        goalnumber
        hours
        matchterm
        pledge
        timeCommit
        pertime
      }
      pledge {
        date
        qualifications
        skills
        summary
        city
        state
        country
        cta
        goalnumber
        hours
        matchterm
        pledge
        timeCommit
        pertime
      }
    }
    loggedInUserCanEdit
    currentUserHasRead
    defaultHidden
    isBookmarked
    isArchived
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    isTemplate
    postingSettings {
      type
      status
      totalPositionsCount
      acceptedCount
      specification {
        url
      }
      postingData {
        parent {
          url
        }
        values {
          id
          type
          customText
          comparator
          values
          siblingId
        }
      }
      requirementsData {
        parent {
          url
        }
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    mobilizationSettings {
      approvalType
      status
      efforts {
        type
        card {
          __typename
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            displayInNewsfeed
          }
          ...MobilizeEffortFragment
        }
        status
        country
        countGoal
        count
      }
    }
    courseItems {
      ...ContentCardCourseFragment
    }
    creationItems {
      ...ContentCardCreationFragment
    }
    surveyQuestions {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
    timeAndPlaceSettings {
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        country
        postal
        state
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
    }
    eventOverviewDetails {
      title
      description
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      location {
        formatted_address
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
    }
    eventSettings {
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      location {
        formatted_address
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
    }
    eventAttendance {
      user {
        _id
        profile {
          full_name
          picture
        }
        socialActionsAllowed
      }
      status
    }
    canMembersSuggestEdits
    showTitle
    protectionLevel
    currentUserCanSuggestEdit
    verticalScrolling
    dialogSettings {
      event
      people {
        name
        image
      }
      dialogs {
        person
        text
      }
    }
    showPostTypes
    ...UpdateFeatureConstraintsFragments
    ...CardRatings
    ...ContentCardCustomFieldsFragment
  }
  ${CONTENT_CARD_RATINGS_FRAGMENT}
  ${REVEAL_CARD_FRAGMENT}
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${MOBILIZE_EFFORT_FRAGMENT}
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
  ${CONTENT_CARD_COURSE_FRAGMENT}
  ${CONTENT_CARD_CREATION_FRAGMENT}
  ${CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT}
`;
var CONTENT_CARD_PLATFORM_FRAGMENT = gql3`
  fragment ContentCardPlatformFragment on ContentCard {
    _id
    __typename
    vs
    type
    url
    platformSettings {
      platformGroupOwner
      forms {
        formCardUrl
        formSpecificationSettings {
          type
          form {
            fieldGroups {
              groupName
              index
              fieldIds
            }
            required
            fields {
              index
              id
              type
              options
              prompt
              defaultComparator
              customText
              selectMultiple
              siblingId
              allowOther
              required
              showOnSignup
              groups {
                group {
                  _id
                  path
                  groupActionsAllowed
                  currentUserIsMember
                  currentUserIsAdmin
                  profile {
                    picture
                    full_name
                    short_bio
                  }
                  settings {
                    approvalRequired
                    protectionLevel
                  }
                }
                id
                requireApproval
              }
              placeholder
              dependsOn {
                id
                values
              }
            }
          }
        }
      }
    }
  }
`;
var CONTENT_CARD_CHAPTER_FRAGMENT = gql3`
  fragment ContentCardChapterFragment on ContentCard {
    _id
    vs
    type
    url
    title
    shortTitle
    media {
      icon
      thumb
    }
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
      }
    }
    description
    isArchived
    prompt
    loggedInUserCanEdit
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    library_settings {
      postTypes
      engagementTypes
      filterTypes
      viewTypes
      defaultViewType
      defaultFilter
      allowPostbox
      postboxRoleWhiteList
      userCanPost
      displaySocialHeaders
      displayInNewsfeed
      allowLabels
      displayReference
      tagAggregation {
        ...TagFragment
      }
      cardAggregation {
        __typename
        _id
        title
        url
        body
        media {
          thumb
          icon
        }
      }
    }
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    sendNotifications
    rolesAvailable {
      url
      name
    }
    ...ContentCardReferenceFragment
    rankOrder
    canMembersSuggestEdits
    currentUserCanSuggestEdit
    showTitle
    childrenCount
    showPostTypes
    inheritSurveyAnswers
    isTemplate
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var RAINBOW_ROAD_FRAGMENT = gql3`
  fragment RainbowRoadFragment on ContentCard {
    __typename
    _id
    url
    vs
    type
    subtype
    title
    description
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
      }
      roles {
        isSpeakerOf
      }
    }
    media {
      icon
    }
    loggedInUserCanEdit
    rainbowRoadSettings {
      header {
        color
        darkMode
        image
        backgroundImage
        showTopInfo
      }
      body {
        color
        darkMode
        image
      }
      nodes {
        circleSize
        icon
        lineStyle
        lineColor
        outline
        winding
        spinnerImage
      }
      font
      questSettings {
        creationsChannel {
          url
          title
          children {
            ...ContentCardFragment
          }
        }
        creationNodeSettings {
          icon
          backgroundImage
          showMetaInformation
          spinnerImage
        }
        completionRequirement
        dialogsChannel {
          url
          title
          children {
            ...ContentCardFragment
          }
        }
        questEndImage
        vcSettings {
          title
          name
          description
          image
        }
      }
    }
    library_settings {
      engagementTypes
    }
    nodes {
      title
      icon
      backgroundImage
      spinnerImage
      showMetaInformation
      preOrPost
      alias {
        ... on UserAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          user {
            _id
            profile {
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
            }
            socialActionsAllowed
          }
        }
        ... on GroupAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          group {
            __typename
            _id
            path
            profile {
              full_name
              short_bio
              full_bio
              picture
              coverPhoto
              country
              website
              email
              customFields {
                parent {
                  url
                }
                parentGroup {
                  _id
                }
                manager
                values {
                  id
                  type
                  comparator
                  values
                  siblingId
                }
              }
            }
            socialActionsAllowed
            shelf {
              __typename
              _id
              url
              media {
                thumb
                icon
              }
            }
            settings {
              __typename
              subscriptions
              formSpec {
                url
                formSpecificationSettings {
                  type
                  form {
                    required
                    fieldGroups {
                      groupName
                      fieldIds
                      index
                    }
                    fields {
                      index
                      type
                      id
                      options
                      customText
                      prompt
                      placeholder
                      type
                      selectMultiple
                      siblingId
                      defaultComparator
                      required
                      allowOther
                      dependsOn {
                        id
                        values
                      }
                    }
                  }
                }
              }
              protectionLevel
              memberStyle
              dashboards {
                name
                url
                type
                filter
              }
              customSignupFields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                weight
                required
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
              recommendations
              canMembersSuggestEdits
              shortCode
              shortCodeApprovalRequired
              groupChatroom
              adminChatroom
              approvalRequired
              registrationTitle
              registrationDescription
              registrationActionText
              enableGroupNewsfeed
            }
            hasUnreadDashboard
            subtype
            totalMembers
            currentUserIsAdmin
            currentUserIsDirectAdmin
            currentUserIsMember
            currentUserLastActiveDate
            groupActionsAllowed
            isTemplate
            allSignupFields {
              parentGroup {
                _id
              }
              manager
              signupFields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                weight
                required
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
            }
            whitelabelVerbage {
              _id
              value
              plainText
            }
            parentGroup {
              __typename
              _id
              profile {
                full_name
              }
            }
            channelsBook {
              title
              url
              media {
                thumb
                icon
              }
              children {
                title
                url
                reference {
                  href
                }
              }
            }
          }
        }
        ... on MessageThreadAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          thread {
            __typename
            _id
            participants {
              __typename
              user {
                __typename
                _id
                profile {
                  __typename
                  full_name
                  short_bio
                  full_bio
                  picture
                  coverPhoto
                  facebookProfile
                  instagramProfile
                  twitterProfile
                  linkedInProfile
                }
              }
              hasUnreadMessages
            }
            messages {
              __typename
              hasMore
              cursor
              type
              messages {
                __typename
                _id
                author {
                  __typename
                  _id
                  profile {
                    __typename
                    first_name
                    full_name
                    picture
                  }
                }
                text
                created
                updated
              }
            }
            displayName
            displayIcon
            created
            updated
            group {
              __typename
              _id
              profile {
                full_name
                picture
                coverPhoto
              }
            }
            currentUserNotificationLevel
          }
        }
        ... on AliasItem {
          url
          type
          title
          thumb
          icon
          color
          item {
            ...ContentCardFragment
            ...ContentCardReferenceFragment
          }
        }
      }
    }
    aliases {
      ... on UserAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        user {
          _id
          profile {
            full_name
            picture
            coverPhoto
            full_bio
            short_bio
            website
            facebookProfile
            twitterProfile
            instagramProfile
            linkedInProfile
          }
          socialActionsAllowed
        }
      }
      ... on GroupAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        group {
          __typename
          _id
          path
          profile {
            full_name
            short_bio
            full_bio
            picture
            coverPhoto
            country
            website
            email
            customFields {
              parent {
                url
              }
              parentGroup {
                _id
              }
              manager
              values {
                id
                type
                comparator
                values
                siblingId
              }
            }
          }
          socialActionsAllowed
          shelf {
            __typename
            _id
            url
            media {
              thumb
              icon
            }
          }
          settings {
            __typename
            subscriptions
            formSpec {
              url
              formSpecificationSettings {
                type
                form {
                  required
                  fieldGroups {
                    groupName
                    fieldIds
                    index
                  }
                  fields {
                    index
                    type
                    id
                    options
                    customText
                    prompt
                    placeholder
                    type
                    selectMultiple
                    siblingId
                    defaultComparator
                    required
                    allowOther
                    dependsOn {
                      id
                      values
                    }
                  }
                }
              }
            }
            protectionLevel
            memberStyle
            dashboards {
              name
              url
              type
              filter
            }
            customSignupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
            recommendations
            canMembersSuggestEdits
            shortCode
            shortCodeApprovalRequired
            groupChatroom
            adminChatroom
            approvalRequired
            registrationTitle
            registrationDescription
            registrationActionText
            enableGroupNewsfeed
          }
          hasUnreadDashboard
          subtype
          totalMembers
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
          currentUserLastActiveDate
          groupActionsAllowed
          isTemplate
          allSignupFields {
            parentGroup {
              _id
            }
            manager
            signupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
          whitelabelVerbage {
            _id
            value
            plainText
          }
          parentGroup {
            __typename
            _id
            profile {
              full_name
            }
          }
          channelsBook {
            title
            url
            media {
              thumb
              icon
            }
            children {
              title
              url
              reference {
                href
              }
            }
          }
        }
      }
      ... on MessageThreadAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        thread {
          __typename
          _id
          participants {
            __typename
            user {
              __typename
              _id
              profile {
                __typename
                full_name
                short_bio
                full_bio
                picture
                coverPhoto
                facebookProfile
                instagramProfile
                twitterProfile
                linkedInProfile
              }
            }
            hasUnreadMessages
          }
          messages {
            __typename
            hasMore
            cursor
            type
            messages {
              __typename
              _id
              author {
                __typename
                _id
                profile {
                  __typename
                  first_name
                  full_name
                  picture
                }
              }
              text
              created
              updated
            }
          }
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
          currentUserNotificationLevel
        }
      }
      ... on AliasItem {
        url
        type
        title
        thumb
        icon
        color
        item {
          ...ContentCardFragment
          ...ContentCardReferenceFragment
        }
      }
    }
  }

  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var CONTENT_CARD_BOOK_FRAGMENT = gql3`
  fragment ContentCardBookFragment on ContentCard {
    _id
    vs
    type
    subtype
    url
    title
    media {
      icon
      thumb
    }
    author {
      _id
      type
      profile {
        full_name
      }
    }
    children(sort: ordered) {
      ...ContentCardChapterFragment
    }
    description
    body
    prompt
    promptEnabled
    loggedInUserFollows
    loggedInUserCanEdit
    isTemplate
    library_settings {
      displayReference
      displayInNewsfeed
      displaySocialHeaders
      protectionLevel
      accessRoleWhitelist
      defaultFilter
      filterTypes
      engagementTypes
      customRoles {
        name
        url
        color
      }
    }
    sendNotifications
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    customFields {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
    cardActionsAllowed
    rankOrder
    verticalScrolling
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_BOOK_BIG_SCREEN_FRAGMENT = gql3`
  fragment ContentCardBookBigScreenFragment on ContentCard {
    _id
    type
    url
    title
    media {
      icon
      thumb
      __typename
    }
    author {
      _id
      type
      profile {
        full_name
        __typename
      }
      __typename
    }
    library_settings {
      displaySocialHeaders
    }
    prompt
    loggedInUserFollows
    loggedInUserCanEdit
    __typename
  }
`;
var CONTENT_CARD_SHELF_FRAGMENT = gql3`
  fragment ContentCardShelfFragment on ContentCard {
    __typename
    _id
    url
    shortCode
    type
    title
    body
    description
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
      }
    }
    group {
      __typename
      _id
      subtype
      username
      profile {
        full_name
        full_bio
        picture
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    media {
      thumb
      icon
    }
    library_settings {
      displayReference
      displayInNewsfeed
      dashboardContentItems {
        name
        url
        type
        filter
      }
      protectionLevel
      accessRoleWhitelist
    }
    customFields {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
    cardActionsAllowed
    loggedInUserFollows
    loggedInUserCanEdit
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_LIBRARY_FRAGMENT = gql3`
  fragment ContentCardLibraryFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
    }
    loggedInUserFollows
    loggedInUserCanEdit
    library_settings {
      welcomeText
      autoFollowCards
      autoFollowUsers
      initialSubscriptions
      protectionLevel
      memberStyle
      sidebarMenu {
        name
        url
      }
      dashboardMenu {
        name
        url
      }
      dashboardContentItems {
        name
        url
        type
        filter
      }
      customRoles {
        name
        url
        color
      }
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      displayReference
      displayInNewsfeed
      recommendations
    }
    cardActionsAllowed
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT = gql3`
  fragment ContentCardChapterChildrenFragment on ContentCard {
    __typename
    _id
    chapterChildren: children(restrictType: chapter) {
      ...ContentCardChapterFragment
    }
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
`;

// src/reports/fragments.ts
import { gql as gql4 } from "@apollo/client";
var REPORT_FRAGMENT = gql4`
    fragment ReportFragment on Report {
        __typename
        _id
        title
        meta {
            url
            includeBooks
        }
        date
        file {
            filename
            url
            size
            handle
            mimetype
            status
        }
        creator {
            profile {
                full_name
            }
        }
    }
`;

// src/user/fragments.ts
import { gql as gql6 } from "@apollo/client";

// src/organization/fragments.ts
import { gql as gql5 } from "@apollo/client";
var ORGANIZATION_FRAGMENT = gql5`
    fragment OrganizationFragment on Organization {
        __typename
        _id
        name
        created
        createdBy {
            _id
            profile {
                full_name
            }
        }
        updated
    }
`;

// src/user/fragments.ts
var CUSTOM_FIELDS_FRAGMENT = gql6`
  fragment CustomFieldsFragment on User {
    __typename
    _id
    profile {
      customFields {
        parent {
          url
        }
        parentGroup {
          _id
        }
        manager
        lastDismissalDate
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
  }
`;
var USER_FRAGMENT = gql6`
  fragment UserFragment on User {
    __typename
    _id
    type
    profile {
      full_name
      short_bio
      full_bio
      picture
      coverPhoto
      facebookProfile
      instagramProfile
      twitterProfile
      linkedInProfile
      country
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        state
        country
        postal
      }
      language
      website
      email
      phone
      nickname
      followersCount
      connectionsCount
      loggedInUserConnectionStatus
      protectionLevel
      newsfeedFilter
      organization {
        ...OrganizationFragment
      }
      simple {
        name
        age
      }
      avatar {
        avatar
        skinTone
        cheekColor
        hairColor
        eyeColor
        clothing1
        clothing2
        clothing3
        outline
      }
      displaySurveyAnswers
      temp
    }
    guardianAccount {
      isVerified
      profile {
        email
        full_name
      }
      featureSets
    }
    guardianCode
    childVerificationCodes
    socialActionsAllowed
    isAnonymous
    isVerified
    isUserSuperAdmin
    canCreate
    featureSets
    ...CustomFieldsFragment
  }
  ${ORGANIZATION_FRAGMENT}
  ${CUSTOM_FIELDS_FRAGMENT}
`;
var UPDATE_FEATURE_CONSTRAINTS_FRAGMENT2 = gql6`
  fragment UpdateGroupFeatureConstraintsFragment on Group {
    __typename
    updateFeatureConstraints {
      canEditAllFields
      featuresUserCanEdit {
        type
        field
        options
        canUpdate
      }
    }
  }
`;
var GROUP_SHELF_FRAGMENT = gql6`
  fragment GroupShelfFragment on Group {
    __typename
    _id
    profile {
      full_name
      picture
      coverPhoto
    }
    shelf {
      __typename
      _id
      url
      media {
        thumb
        icon
      }
    }
  }
`;
var GROUP_PRODUCT_FRAGMENT = gql6`
  fragment GroupProductFragment on Group {
    products {
      _id
      name
      description
      coupons {
        name
        code
        percentage
        type
        expiration
      }
      price
      currency
      type
      fulfillment {
        groupId
        role
      }
      created
      updated
    }
  }
`;
var GROUP_EVENT_FRAGMENT = gql6`
  fragment GroupEventFragment on Group {
    __typename
    _id
    path
    profile {
      full_name
      short_bio
      full_bio
      picture
      coverPhoto
      country
      website
      email
    }
    socialActionsAllowed
    shelf {
      __typename
      _id
      url
      media {
        thumb
        icon
      }
    }
    settings {
      __typename
      formSpec {
        url
      }
      protectionLevel
      memberStyle
      recommendations
      canMembersSuggestEdits
      shortCode
      shortCodeApprovalRequired
      groupChatroom
      adminChatroom
      approvalRequired
      registrationTitle
      registrationDescription
      registrationActionText
      enableGroupNewsfeed
      enableMembershipID
    }
    eventOverviewDetails {
      title
      description
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      hideAttendeeCount
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
      sponsorSettings {
        description
        tiers {
          name
          order
          sponsors {
            name
            link
            logo
          }
        }
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
          postal
          location {
            type
            coordinates
          }
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            city
            state
            country
            postal
            location {
              type
              coordinates
            }
          }
        }
      }
    }
    timeAndPlaceSettings {
      _id
      title
      description
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
          postal
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            title
            thumb
            icon
            altName
            altBio
            useUserBio
            color
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                protectionLevel
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
    }
    subtype
    totalMembers
    totalChildGroups
    totalChildEventGroups
    membershipBoostUri
    currentUserIsAdmin
    currentUserIsDirectAdmin
    currentUserIsMember
    currentUserIsInterested
    currentUserIsInvited
    currentUserIsNotGoing
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    currentUserLastActiveDate
    groupActionsAllowed
    doesSellMembership
    isUnpublished
    whitelabelVerbage {
      _id
      value
      plainText
    }
    parentGroup {
      __typename
      _id
      profile {
        full_name
        coverPhoto
        picture
        website
      }
    }
    paymentSettings {
      isGroupMembershipPaid
    }
    ...GroupProductFragment
  }

  ${GROUP_PRODUCT_FRAGMENT}
`;
var GROUP_FRAGMENT = gql6`
  fragment GroupFragment on Group {
    __typename
    _id
    path
    profile {
      full_name
      short_bio
      full_bio
      picture
      coverPhoto
      country
      website
      email
      customFields {
        parent {
          url
        }
        parentGroup {
          _id
        }
        manager
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    socialActionsAllowed
    shelf {
      __typename
      _id
      url
      loggedInUserCanEdit
      media {
        thumb
        icon
      }
    }
    settings {
      __typename
      subscriptions
      formSpec {
        url
        formSpecificationSettings {
          type
          form {
            required
            fieldGroups {
              groupName
              fieldIds
              index
            }
            fields {
              index
              type
              id
              options
              customText
              prompt
              placeholder
              type
              selectMultiple
              siblingId
              defaultComparator
              required
              showOnSignup
              groups {
                group {
                  _id
                }
                id
                requireApproval
              }
              allowOther
              dependsOn {
                id
                values
              }
            }
          }
        }
      }
      protectionLevel
      memberStyle
      dashboards {
        name
        url
        type
        filter
      }
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      recommendations
      canMembersSuggestEdits
      shortCode
      shortCodeApprovalRequired
      groupChatroom
      adminChatroom
      membersHub
      welcomeCard {
        enabled
        title
        message
      }
      chatSettings {
        showChatHistory
        showReadReceipts
        showOnlineUsers
        isEncrypted
      }
      adminChatSettings {
        showChatHistory
        showReadReceipts
        showOnlineUsers
        isEncrypted
      }
      chatPermissions {
        editGroupSettings
        addMembers
        pinMessages
        sendMessages
      }
      approvalRequired
      registrationTitle
      registrationDescription
      registrationActionText
      enableGroupNewsfeed
      enableMembershipID
      lastCustomSignupFieldsUpdate
      adminChatroom
      groupChatroom
      enableGroupNewsfeed
      welcomeCard {
        enabled
        title
        message
      }
    }
    eventOverviewDetails {
      title
      description
      hideAttendeeCount
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
      sponsorSettings {
        description
        tiers {
          name
          order
          sponsors {
            name
            link
            logo
          }
        }
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
    }
    timeAndPlaceSettings {
      title
      description
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      link
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
      speakerSettings {
        aliases {
          ... on UserAliasHandle {
            url
            type
            altName
            altBio
            useUserBio
            user {
              _id
              profile {
                email
                full_name
                picture
                coverPhoto
                full_bio
                short_bio
                website
                facebookProfile
                twitterProfile
                instagramProfile
                linkedInProfile
                city
                country
                organization {
                  name
                }
                location {
                  city
                  state
                  country
                }
              }
              socialActionsAllowed
            }
          }
        }
      }
    }
    hasUnreadDashboard
    subtype
    totalMembers
    totalChildGroups
    totalChildEventGroups
    membershipBoostUri
    currentUserIsAdmin
    currentUserIsDirectAdmin
    currentUserIsMember
    currentUserIsInterested
    currentUserIsInvited
    currentUserIsNotGoing
    currentUserLastActiveDate
    currentUserJoinDate
    currentUserOwnsMembership
    currentUserOwnsFreeSubscription
    groupActionsAllowed
    doesSellMembership
    isTemplate
    isUnpublished
    allSignupFields {
      parentGroup {
        _id
      }
      manager
      signupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
    }
    whitelabelVerbage {
      _id
      value
      plainText
    }
    parentGroup {
      __typename
      _id
      profile {
        full_name
        coverPhoto
        picture
        website
        customFields {
          parent {
            url
          }
          parentGroup {
            _id
          }
          manager
          values {
            id
            type
            comparator
            values
            siblingId
          }
        }
      }
    }
    channelsBook {
      title
      url
      media {
        thumb
        icon
      }
      children {
        title
        url
        reference {
          href
        }
      }
    }
    eventBook {
      url
      title
      children {
        _id
        title
        url
        rankOrder
        reference {
          href
        }
      }
    }
    ...UpdateGroupFeatureConstraintsFragment
    ...GroupProductFragment
  }
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT2}
  ${GROUP_PRODUCT_FRAGMENT}
`;
var GROUP_MEMBERSHIP_FRAGMENT = gql6`
  fragment GroupMembershipFragment on Group {
    __typename
    _id
    totalMembers
    totalAdmins
    totalGuests
    owners(limit: $ownersLimit, cursor: $ownersCursor) {
      __typename
      hasMore
      cursor
      results {
        ...UserFragment
        doesUserOwnMembershipInGroup(groupId: $_id)
        doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
        productInstancesForGroup(groupId: $_id) {
          _id
          transferToken
          product {
            _id
            name
            description
            price
            currency
            type
          }
        }
      }
    }
    members(limit: $membersLimit, cursor: $membersCursor) {
      __typename
      hasMore
      cursor
      results {
        ...UserFragment
        doesUserOwnMembershipInGroup(groupId: $_id)
        doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
        productInstancesForGroup(groupId: $_id) {
          _id
          transferToken
          product {
            _id
            name
            description
            price
            currency
            type
          }
        }
      }
    }
    guests {
      __typename
      _id
      profile {
        email
        full_name
        temp
      }
    }
    giftedSubscriptions {
      __typename
      _id
      ...UserFragment
      profile {
        email
        full_name
        temp
      }
      doesUserOwnMembershipInGroup(groupId: $_id)
      doesUserOwnFreeSubscriptionInGroup(groupId: $_id)
      productInstancesForGroup(groupId: $_id) {
        _id
        transferToken
        product {
          _id
          name
          description
          price
          currency
          type
        }
      }
    }
    memberRequests {
      user {
        __typename
        ...UserFragment
        roleForGroup(groupId: $_id)
      }
      role
      message
    }
    memberInvites {
      user {
        __typename
        ...UserFragment
        roleForGroup(groupId: $_id)
      }
      role
      message
    }
    emailInvites {
      userEmail
      role
      message
    }
  }
  ${USER_FRAGMENT}
`;
var GROUP_OPTIMIZER_DATA_FRAGMENT = gql6`
  fragment GroupOptimizerDataFragment on Group {
    _id
    profile {
      full_name
      picture
      short_bio
    }
    settings {
      recommendations
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
    }
  }
`;
var USER_PERSONAL_FEED_FRAGMENT = gql6`
  fragment UserPersonalFeedFragment on ContentCard {
    __typename
    ...ContentCardFragment
  }
  ${CONTENT_CARD_FRAGMENT}
`;
var GROUP_DASHBOARD_FRAGMENT = gql6`
  fragment GroupDashboardFragment on PaginatedCards {
    __typename
    hasMore
    cursor
    results {
      ...ContentCardReferenceFragment
      ...ContentCardFragment
      ...ContentCardChapterChildrenFragment
    }
    type
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var PRODUCT_FRAGMENT = gql6`
  fragment ProductFragment on Product {
    _id
    name
    price
    currency
    type
    fulfillment {
      groupId
      role
    }
    created
    updated
    createdBy {
      profile {
        full_name
        coverPhoto
      }
    }
    reference {
      ... on Group {
        _id
        profile {
          full_name
          picture
          coverPhoto
        }
        subtype
        doesSellMembership
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
      }
    }
  }
`;
var ORDER_FRAGMENT = gql6`
  fragment OrderFragment on CustomerOrder {
    _id
    paymentIntent {
      id
      amount
      client_secret
      currency
      customer
      description
      next_action
      payment_method {
        id
        customer
        type
        object
        card {
          id
          brand
          last4
          exp_month
          exp_year
          funding
        }
      }
      receipt_email
      status
      livemode
    }
    products {
      ...ProductFragment
    }
    created
    updated
    status
  }
  ${PRODUCT_FRAGMENT}
`;
var TIME_AND_PLACE_SETTINGS_FRAGMENT = gql6`
  fragment TimeAndPlaceSettingsFragment on TimeAndPlaceSettings {
    _id
    title
    description
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    link
    timeZone {
      name
    }
    hideAttendeeCount
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
        postal
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
    speakerSettings {
      aliases {
        ... on UserAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          user {
            _id
            profile {
              email
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
              city
              country
              organization {
                name
              }
              location {
                city
                state
                country
              }
            }
            socialActionsAllowed
          }
        }
      }
    }
  }
`;

// src/content_card/queries.ts
var LIST_CARDS = gql7`
  query ListCards(
    $searchQuery: String
    $libraryUrl: String
    $groupId: String
    $types: [String]
    $subTypes: [String]
    $limit: Int = 30
    $childrenLimit: Int = 0
    $showEmpty: Boolean
    $skipChildren: Boolean = false
    $prioritizeGroupId: Boolean = false
    $childrenTypes: [CardPostType]
  ) {
    listCards(
      searchQuery: $searchQuery
      libraryUrl: $libraryUrl
      groupId: $groupId
      types: $types
      limit: $limit
      showEmpty: $showEmpty
      skipChildren: $skipChildren
      prioritizeGroupId: $prioritizeGroupId
      childrenTypes: $childrenTypes
      subTypes: $subTypes
    ) {
      ...ContentCardSearchFragment
      ...ContentCardReferenceFragment
      ...ContentCardCourseSearchFragment
      children(limit: $childrenLimit, offset: 0, restrictType: $childrenTypes)
        @skip(if: $skipChildren) {
        __typename
        _id
        title
        url
        type
        reference {
          bookSource {
            title
          }
          coverImage
        }
      }
    }
  }
  ${CONTENT_CARD_COURSE_SEARCH_FRAGMENT}
  ${CONTENT_CARD_SEARCH_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var PAGINATED_LIST_CARDS = gql7`
  query PaginatedListCards(
    $searchQuery: String
    $libraryUrl: String
    $groupId: String
    $types: [String]
    $subTypes: [String]
    $limit: Int = 30
    $childrenLimit: Int = 0
    $showEmpty: Boolean
    $skipChildren: Boolean = false
    $prioritizeGroupId: Boolean = false
    $childrenTypes: [CardPostType]
    $cursor: String
  ) {
    paginatedListCards(
      searchQuery: $searchQuery
      libraryUrl: $libraryUrl
      groupId: $groupId
      types: $types
      limit: $limit
      showEmpty: $showEmpty
      skipChildren: $skipChildren
      prioritizeGroupId: $prioritizeGroupId
      childrenTypes: $childrenTypes
      subTypes: $subTypes
      cursor: $cursor
    ) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardSearchFragment
        ...ContentCardReferenceFragment
        ...ContentCardCourseSearchFragment
        children(
          limit: $childrenLimit
          offset: 0
          restrictType: $childrenTypes
        ) @skip(if: $skipChildren) {
          __typename
          _id
          title
          url
          type
          reference {
            bookSource {
              title
            }
            coverImage
          }
        }
      }
    }
  }
  ${CONTENT_CARD_COURSE_SEARCH_FRAGMENT}
  ${CONTENT_CARD_SEARCH_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var LIST_CARDS_IN_GROUP = gql7`
  query ListCardsInGroup(
    $searchQuery: String
    $types: [String]
    $groupId: String
  ) {
    listCards(
      searchQuery: $searchQuery
      types: $types
      showEmpty: true
      groupId: $groupId
    ) {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var GET_FULL_SCREEN_BOOKS_FROM_SHELF = gql7`
  query GetFullScreenBooksFromShelf(
    $url: String!
    $chapterLimit: Int = 10
    $chapterOffset: Int = 0
    $chapterSort: FilterType = oldestFirst
    $cardLimit: Int = 10
    $cardOffset: Int = 0
  ) {
    booksFromShelf(url: $url) {
      ...ContentCardBookBigScreenFragment
      children(
        limit: $chapterLimit
        offset: $chapterOffset
        sort: $chapterSort
      ) {
        ...ContentCardChapterFragment
        children(limit: $cardLimit, offset: $cardOffset, sort: leaderboard) {
          ...ContentCardFragment
        }
        __typename
      }
    }
    shelf: cardByUrl(url: $url) {
      __typename
      _id
      url
      title
      rootLibrary {
        ...ContentCardLibraryFragment
      }
    }
  }
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
  ${CONTENT_CARD_BOOK_BIG_SCREEN_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
`;
var GET_BIG_SCREEN_BOOKS_FROM_SHELF = gql7`
  query GetBigScreenBooksFromShelf(
    $url: String!
    $chapterLimit: Int = 4
    $chapterOffset: Int = 1
    $chapterSort: FilterType = oldestFirst
    $cardLimit: Int = 3
    $cardOffset: Int = 0
  ) {
    booksFromShelf(url: $url) {
      ...ContentCardBookBigScreenFragment
      children(
        limit: $chapterLimit
        offset: $chapterOffset
        sort: $chapterSort
      ) {
        ...ContentCardChapterFragment
        children(limit: $cardLimit, offset: $cardOffset, sort: leaderboard) {
          ...ContentCardFragment
        }
        __typename
      }
    }
    shelf: cardByUrl(url: $url) {
      __typename
      _id
      url
      title
      rootLibrary {
        ...ContentCardLibraryFragment
      }
    }
  }
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
  ${CONTENT_CARD_BOOK_BIG_SCREEN_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
`;
var GET_DASHBOARD_FEED_FOR_LIBRARY = gql7`
  query DashboardFeed($url: String!, $limit: Int = 15, $offset: Int = 0) {
    cardByUrl(url: $url) {
      ...ContentCardLibraryFragment
      dashfeed {
        ...ContentCardChapterFragment
        children(limit: $limit, offset: $offset, sort: ordered) {
          ...ContentCardFragment
        }
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
`;
var GET_LIBRARY_BY_URL = gql7`
  query LibraryByUrl($url: String!) {
    cardByUrl(url: $url) {
      ...ContentCardLibraryFragment
    }
  }
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
`;
var GET_SHELF_BY_SHORT_CODE = gql7`
  query ShelfByShortCode($shortCode: String!) {
    cardByShortCode(shortCode: $shortCode) {
      ...ContentCardShelfFragment
      shortCode
      rootLibrary {
        ...ContentCardLibraryFragment
      }
    }
  }
  ${CONTENT_CARD_SHELF_FRAGMENT}
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
`;
var GET_CARD_BY_URL = gql7`
  query GetCardByURL($url: String!) {
    cardByUrl(url: $url) {
      ...ContentCardBookFragment
    }
  }
  ${CONTENT_CARD_BOOK_FRAGMENT}
`;
var GET_SINGLE_CARD_BY_URL = gql7`
  query SingleCardByURL($url: String!) {
    cardByUrl(url: $url) {
      childrenCount
      ...ContentCardFragment
      ...ContentCardReferenceFragment
      ...ContentCardChapterChildrenFragment
      ...ContentCardPlatformFragment
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
  ${CONTENT_CARD_PLATFORM_FRAGMENT}
`;
var GET_SINGLE_CARD_BY_ID = gql7`
  query SingleCardById($_id: String!) {
    card(_id: $_id) {
      ...ContentCardFragment
      eventAttendance {
        user {
          _id
          profile {
            full_name
            picture
          }
          socialActionsAllowed
        }
        status
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
`;
var GET_RAINBOW_ROAD_BY_ID = gql7`
  query RainbowRoadById($_id: String!) {
    card(_id: $_id) {
      ...RainbowRoadFragment
    }
  }
  ${RAINBOW_ROAD_FRAGMENT}
`;
var GET_RAINBOW_ROAD_BY_URL = gql7`
  query RainbowRoadByUrl($url: String!) {
    cardByUrl(url: $url) {
      ...RainbowRoadFragment
    }
  }
  ${RAINBOW_ROAD_FRAGMENT}
`;
var GET_SIMPLE_BOOKS_FROM_SHELF = gql7`
  query SimpleBooksFromShelf($url: String!) {
    booksFromShelf(url: $url) {
      _id
      url
      subtype
      children(sort: ordered) {
        title
        url
        rankOrder
      }
      rankOrder
    }
    shelf: cardByUrl(url: $url) {
      __typename
      _id
      ...ContentCardShelfFragment
      ...ContentCardReferenceFragment
    }
  }
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_SHELF_FRAGMENT}
`;
var GET_BOOKS_FROM_SHELF = gql7`
  query BooksFromShelf($url: String!) {
    booksFromShelf(url: $url) {
      ...ContentCardBookFragment
      ...ContentCardReferenceFragment
      rootLibrary {
        ...ContentCardLibraryFragment
      }
    }
    shelf: cardByUrl(url: $url) {
      __typename
      _id
      ...ContentCardShelfFragment
      ...ContentCardReferenceFragment
    }
  }
  ${CONTENT_CARD_BOOK_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
  ${CONTENT_CARD_SHELF_FRAGMENT}
`;
var GET_BOOK = gql7`
  query GetBook($url: String!) {
    cardByUrl(url: $url) {
      ...ContentCardBookFragment
      ...ContentCardReferenceFragment
    }
  }
  ${CONTENT_CARD_BOOK_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var GET_CHAPTER = gql7`
  query GetChapter($url: String!) {
    cardByUrl(url: $url) {
      ...ContentCardChapterFragment
      ...ContentCardReferenceFragment
    }
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var GET_TEMPLATE_CHAPTERS = gql7`
  query GetTemplateChapters($url: String!, $includeArchived: Boolean) {
    booksFromShelf(url: $url) {
      __typename
      _id
      type
      subtype
      url
      title
      media {
        icon
        thumb
      }
      author {
        _id
        type
        profile {
          full_name
        }
      }
      children(sort: ordered, includeArchived: $includeArchived) {
        ...ContentCardChapterFragment
      }
      description
      body
      prompt
      rankOrder
    }
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
`;
var GET_TEMPLATES_AVAILABLE_TO_USER = gql7`
  query GetTemplatesAvailableToUser(
    $limit: Int
    $cursor: String
    $sortType: String
    $type: String
  ) {
    getTemplatesAvailableToUser(
      limit: $limit
      cursor: $cursor
      sortType: $sortType
      type: $type
    ) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardBookFragment
        createFeatureConstraints {
          canCreate
          canCreateBlank
          creditsAvailable
          totalCredits
        }
      }
    }
  }
  ${CONTENT_CARD_BOOK_FRAGMENT}
`;
var GET_CHILDREN_CARDS = gql7`
  query GetChildrenCards($input: GetChildrenCardsInput!) {
    getChildrenCards(input: $input) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardFragment
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
`;
var GET_CHAPTER_CHILDREN = gql7`
  query GetChapterChildren(
    $url: String!
    $limit: Int!
    $sortType: String
    $tags: [String]
    $cursor: String
  ) {
    paginatedChildrenFromCard(
      url: $url
      limit: $limit
      sortType: $sortType
      tags: $tags
      cursor: $cursor
    ) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
        ...ContentCardChapterChildrenFragment
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var GET_CHILDREN_FROM_CHAPTER = gql7`
  query ChildrenFromCard(
    $url: String!
    $limit: Int!
    $sortType: String
    $offset: Int
    $tags: [String]
  ) {
    childrenFromCard(
      url: $url
      limit: $limit
      sortType: $sortType
      offset: $offset
      tags: $tags
    ) {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
      ...ContentCardChapterChildrenFragment
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var GET_CHILDREN_FROM_CHAPTER_WITH_REFERENCE = gql7`
  query childrenFromCardWithReference(
    $url: String!
    $limit: Int!
    $sortType: String
    $offset: Int
    $tags: [String]
  ) {
    childrenFromCard(
      url: $url
      limit: $limit
      sortType: $sortType
      offset: $offset
      tags: $tags
    ) {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
      ...ContentCardChapterChildrenFragment
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var GET_LINK_METADATA = gql7`
  query LinkMetaData($url: String) {
    linkMetaData(url: $url) {
      url
      author
      date
      description
      image
      publisher
      title
    }
  }
`;
var GET_SHELF_BY_URL = gql7`
  query GetShelfByUrl($url: String!) {
    cardByUrl(url: $url) {
      ...ContentCardShelfFragment
      loggedInUserFollows
      rootLibrary {
        ...ContentCardLibraryFragment
      }
    }
  }
  ${CONTENT_CARD_SHELF_FRAGMENT}
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
`;
var GET_USERS_ROOT_LIBRARY = gql7`
  query GetUsersRootLibrary {
    getUsersRootLibrary {
      ...ContentCardLibraryFragment
    }
  }
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
`;
var GET_NEWS_FEED = gql7`
  query NewsFeed($limit: Int, $offset: Int) {
    newsFeed(limit: $limit, offset: $offset) {
      ...ContentCardFragment
      ...ContentCardReferenceFragment
      ...ContentCardChapterChildrenFragment
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var GET_TASK_HISTORY = gql7`
  query GetTaskHistory(
    $type: String = "PaginatedTaskCompletion"
    $cardId: ID!
    $pledgeKeyId: String
    $limit: Int = 10
    $cursor: String
    $sortType: String = "live"
  ) {
    getTaskHistory(
      type: $type
      cardId: $cardId
      pledgeKeyId: $pledgeKeyId
      limit: $limit
      cursor: $cursor
      sortType: $sortType
    ) {
      __typename
      type
      cursor
      results {
        __typename
        _id
        label
        date
        newRecruit
        note
        claimedAccount {
          __typename
          _id
          accountStatus
          profile {
            picture
            full_name
            email
            phone
            country
          }
        }
        supplement {
          note
          image
          document
          documentTitle
        }
        verifier {
          name
          email
          phone
          country
          note
          newRecruit
          recruitType
        }
      }
      hasMore
    }
  }
`;
var GET_PAGINATED_NEWSFEED = gql7`
  query PaginatedNewsFeed(
    $limit: Int
    $filterIds: [String]
    $cursor: String
    $sortType: String = "live"
    $groupId: ID
  ) {
    paginatedNewsFeed(
      limit: $limit
      filterIds: $filterIds
      cursor: $cursor
      sortType: $sortType
      groupId: $groupId
    ) {
      __typename
      cursor
      hasMore
      results {
        __typename
        ...ContentCardFragment
        ...ContentCardReferenceFragment
        ...ContentCardChapterChildrenFragment
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var GET_RANDOM_ENGAGEMENT_CARD_SAMPLING = gql7`
  query GetRandomEngagementCardSampling(
    $highCount: Int
    $mediumCount: Int
    $lowCount: Int
    $randomCount: Int
    $groupIds: [ID]
    $cardBlacklist: [ID]
    $userId: ID
  ) {
    getRandomEngagementCardSampling(
      highCount: $highCount
      mediumCount: $mediumCount
      lowCount: $lowCount
      randomCount: $randomCount
      groupIds: $groupIds
      cardBlacklist: $cardBlacklist
      userId: $userId
    ) {
      __typename
      ...ContentCardFragment
      ...ContentCardReferenceFragment
      ...ContentCardChapterChildrenFragment
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var GET_ADMIN_PAGE = gql7`
  query AdminPage {
    currentUser {
      ...UserFragment
      roles {
        adminOf {
          ...ContentCardFragment
          ...ContentCardLibraryFragment
          routeUrl
          children {
            __typename
            _id
            type
            title
            url
            path
            media {
              thumb
              icon
            }
          }
        }
        platformFeatures {
          createLibraries
          createReports
        }
      }
      reports {
        ...ReportFragment
      }
      pledgeReports {
        cursor
        hasMore
        results {
          ...ContentCardFragment
        }
        type
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
  ${REPORT_FRAGMENT}
  ${USER_FRAGMENT}
`;
var GET_REPORT_DATA = gql7`
  query GetReportData(
    $url: String!
    $includeBooks: [String]
    $chapterLimit: Int = 10
    $chapterOffset: Int = 0
    $chapterSort: FilterType = oldestFirst
    $cardLimit: Int = 10
    $cardOffset: Int = 0
  ) {
    booksFromShelf(url: $url, select: $includeBooks) {
      ...ContentCardBookBigScreenFragment
      children(
        limit: $chapterLimit
        offset: $chapterOffset
        sort: $chapterSort
      ) {
        ...ContentCardChapterFragment
        totalChildrenVotes(sinceDays: 100)
        topContentItem {
          ...ContentCardFragment
          ...ContentCardReferenceFragment
        }
        children(
          limit: $cardLimit
          offset: $cardOffset
          sort: leaderboard
          restrictType: [text]
        ) {
          ...ContentCardFragment
        }
        __typename
      }
    }
    shelf: cardByUrl(url: $url) {
      __typename
      _id
      ...ContentCardShelfFragment
      rootLibrary {
        ...ContentCardLibraryFragment
      }
      topContentItem {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
      }
    }
  }
  ${CONTENT_CARD_LIBRARY_FRAGMENT}
  ${CONTENT_CARD_BOOK_BIG_SCREEN_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
  ${CONTENT_CARD_SHELF_FRAGMENT}
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var MY_EVENTS = gql7`
  query MyEvents(
    $searchQuery: String
    $limit: Int
    $cursor: String
    $sortType: String = "az"
  ) {
    myEvents(
      searchQuery: $searchQuery
      limit: $limit
      cursor: $cursor
      sortType: $sortType
    ) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardFragment
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
`;
var MY_INTERESTED_EVENTS = gql7`
  query MyInterestedEvents($limit: Int, $cursor: String) {
    myInterestedEvents(limit: $limit, cursor: $cursor) {
      __typename
      cursor
      hasMore
      totalResults
      results {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
        ...ContentCardChapterChildrenFragment
        ...GroupEventFragment
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
  ${GROUP_EVENT_FRAGMENT}
`;
var MY_BOOKMARKED_EVENTS = gql7`
  query MyBookmarkedEvents(
    $searchQuery: String
    $limit: Int
    $cursor: String
    $filter: String
    $hideActive: Boolean
    $sortType: String
    $locationType: String
    $location: LocationInput
    $distance: Int
  ) {
    myBookmarkedEvents(
      searchQuery: $searchQuery
      limit: $limit
      cursor: $cursor
      filter: $filter
      hideActive: $hideActive
      sortType: $sortType
      locationType: $locationType
      location: $location
      distance: $distance
    ) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
        ...ContentCardChapterChildrenFragment
        ...GroupFragment
        ... on Group {
          isBookmarked
        }
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var GET_EVENTS_IN_MY_GROUPS = gql7`
  query GetEventsInMyGroups(
    $searchQuery: String
    $limit: Int
    $cursor: String
    $filter: String
    $hideActive: Boolean
    $sortType: String
    $locationType: String
    $location: LocationInput
    $distance: Int
  ) {
    getEventsInMyGroups(
      searchQuery: $searchQuery
      limit: $limit
      cursor: $cursor
      filter: $filter
      hideActive: $hideActive
      sortType: $sortType
      locationType: $locationType
      location: $location
      distance: $distance
    ) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
        ...ContentCardChapterChildrenFragment
        ...GroupEventFragment
        ... on Group {
          isBookmarked
        }
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
  ${GROUP_EVENT_FRAGMENT}
`;
var GET_MY_EVENTS = gql7`
  query GetMyEvents(
    $searchQuery: String
    $limit: Int
    $cursor: String
    $filter: String
    $hideActive: Boolean
    $sortType: String
    $locationType: String
    $location: LocationInput
    $distance: Int
  ) {
    getMyEvents(
      searchQuery: $searchQuery
      limit: $limit
      cursor: $cursor
      filter: $filter
      hideActive: $hideActive
      sortType: $sortType
      locationType: $locationType
      location: $location
      distance: $distance
    ) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
        ...ContentCardChapterChildrenFragment
        ...GroupFragment
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
  ${GROUP_FRAGMENT}
`;
var MY_BOOKMARKS = gql7`
  query MyBookmarks(
    $searchQuery: String
    $limit: Int
    $cursor: String
    $sortType: String = "az"
  ) {
    myBookmarks(
      searchQuery: $searchQuery
      limit: $limit
      cursor: $cursor
      sortType: $sortType
    ) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
        ...ContentCardChapterChildrenFragment
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var MY_PLEDGES = gql7`
  query MyPledges(
    $searchQuery: String
    $limit: Int
    $cursor: String
    $sortType: String = "az"
  ) {
    myPledges(
      searchQuery: $searchQuery
      limit: $limit
      cursor: $cursor
      sortType: $sortType
    ) {
      __typename
      cursor
      hasMore
      results {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
        ...ContentCardChapterChildrenFragment
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
  ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var GET_APPLICATIONS_FROM_POSTING = gql7`
  query GetApplicationsFromPosting($postingUrl: String!) {
    getApplicationsFromPosting(postingUrl: $postingUrl) {
      __typename
      _id
      createdAt
      status
      data {
        values {
          id
          values
        }
      }
      user {
        __typename
        _id
        profile {
          full_name
          picture
          country
          website
          email
        }
      }
    }
  }
`;
var GET_RATINGS_SINGLE_CARD_BY_URL = gql7`
  query getRatingsSingleCardByUrl($url: String!) {
    cardByUrl(url: $url) {
      _id
      url
      ratings_sentiment {
        userId {
          _id
        }
        extra
      }
      ratings_sentiment_score
      ratings_quality {
        starCount
        userId {
          _id
        }
      }
      ratings_rank
      ratings_quality_score
      ratings_relevance {
        userId {
          _id
        }
        isRelevant
      }
      ratings_relevance_score {
        score
      }
    }
  }
`;
export {
  GET_ADMIN_PAGE,
  GET_APPLICATIONS_FROM_POSTING,
  GET_BIG_SCREEN_BOOKS_FROM_SHELF,
  GET_BOOK,
  GET_BOOKS_FROM_SHELF,
  GET_CARD_BY_URL,
  GET_CHAPTER,
  GET_CHAPTER_CHILDREN,
  GET_CHILDREN_CARDS,
  GET_CHILDREN_FROM_CHAPTER,
  GET_CHILDREN_FROM_CHAPTER_WITH_REFERENCE,
  GET_DASHBOARD_FEED_FOR_LIBRARY,
  GET_EVENTS_IN_MY_GROUPS,
  GET_FULL_SCREEN_BOOKS_FROM_SHELF,
  GET_LIBRARY_BY_URL,
  GET_LINK_METADATA,
  GET_MY_EVENTS,
  GET_NEWS_FEED,
  GET_PAGINATED_NEWSFEED,
  GET_RAINBOW_ROAD_BY_ID,
  GET_RAINBOW_ROAD_BY_URL,
  GET_RANDOM_ENGAGEMENT_CARD_SAMPLING,
  GET_RATINGS_SINGLE_CARD_BY_URL,
  GET_REPORT_DATA,
  GET_SHELF_BY_SHORT_CODE,
  GET_SHELF_BY_URL,
  GET_SIMPLE_BOOKS_FROM_SHELF,
  GET_SINGLE_CARD_BY_ID,
  GET_SINGLE_CARD_BY_URL,
  GET_TASK_HISTORY,
  GET_TEMPLATES_AVAILABLE_TO_USER,
  GET_TEMPLATE_CHAPTERS,
  GET_USERS_ROOT_LIBRARY,
  LIST_CARDS,
  LIST_CARDS_IN_GROUP,
  MY_BOOKMARKED_EVENTS,
  MY_BOOKMARKS,
  MY_EVENTS,
  MY_INTERESTED_EVENTS,
  MY_PLEDGES,
  PAGINATED_LIST_CARDS
};
