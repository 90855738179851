import { ConfigInputType } from '@config/schemas/main';
import EventTypes from '@core/utilities/constants/events';

const config: ConfigInputType = {
    public: {
        root_url: 'app.internetofeducation.org',
        apiUrl: 'api.app.welibrary.io',
        deep_link_domains: ['https://app.internetofeducation.org'],
        captcha: {
            key: '6Lc8MlUiAAAAAC5sMITt02hJ_bKRE4OzhnhSiufh',
        },
        eventTypes: [EventTypes.EVENT_GROUP, EventTypes.EVENT_LISTING],

        configName: 'ioe',
        siteName: 'Internet of Education',
        emailFrom: 'Internet of Education <no-reply@welibrary.io>',
        metaInfo: {
            title: 'Internet of Education',
            description: 'Ed 3.0 Co-Lab in a Box. Community. Research. Productivity.',
            image: 'https://cdn.filestackcontent.com/JGFtsqlSQlaqm34J2M7M',
            url: 'app.internetofeducation.org',
        },

        copyright: 'Learning Economy',

        filestack: { api_key: 'A7RsW3VzfSNO2TCsFJ6Eiz', filePreview: true },
        unsplash: { access_key: 'NTNacJWbx9pMW5yHizHSqWr1IpQXmTJTuQ08VZ7pSEg' },
        GATracking: { GA_ID: 'G-XBT3YY8P0B' },
        Google: { Maps_API_Key: 'AIzaSyBDfzEYNC2sHOYi9OEwmwNVVtOMDFfUV5I' },
        sentry: {
            dsn: 'https://a93523418e50428c9feb61bb8de0a344@o246842.ingest.sentry.io/5199007',
        },
        firebaseConfig: {
            apiKey: 'AIzaSyAdqwUuzbZoS6s3H6HkR9nWyNPVEDcjmWQ',
            authDomain: 'welibrary-native.firebaseapp.com',
            databaseURL: 'https://welibrary-native.firebaseio.com',
            projectId: 'welibrary-native',
            storageBucket: 'welibrary-native.appspot.com',
            messagingSenderId: '847997887601',
            appId: '1:847997887601:web:3c5f63a1809f6ccf5127d0',
            measurementId: 'G-XBT3YY8P0B',
        },
        torus: {
            verifier: 'welibrary',
            network: 'testnet',
        },

        defaultGroupCode: 'IOEDU',
        defaultCoverPhoto: 'https://cdn.filestackcontent.com/JGFtsqlSQlaqm34J2M7M',

        favicon: '/images/favicon/ioe/favicon.ico',

        root_redirect: '/g/u3ddFa6AgBLwmETdB',
        root_redirect_native: '/signup',
        root_redirect_loggedin: '/g/u3ddFa6AgBLwmETdB',

        privacyPolicy: 'www.learningeconomy.io/privacy-policy',
        termsOfService: 'www.learningeconomy.io/terms-of-service',
        templatesUrl: 'templates-64121',

        defaultGroupChannelTemplateUrl: 'ZeJSZ2My9ggdJ9bym',
        defaultChannelTemplateUrl: 'dw9NQGnvfjRWcF96P',

        defaultLogoBackup: 'https://cdn.filestackcontent.com/ToXAY8i3TWCkJxPgi15g',
        defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/fORSVkqKRgqAm7eQ2xoW',
        defaultLogoBlack: 'https://cdn.filestackcontent.com/rBkM4keCTAueW4d3Fpj4',

        pushSenderID: 377262931518,
        pushLogo: 'https://www.welibrary.io/native/welibrary/notifications/we_notification.png',

        domainBranding: {
            internetofeducation: {
                root_url: 'app.internetofeducation.org',
                customErrorMessages: {
                    signupEmailAlreadyExists:
                        'Email already exists. If you already have an lerhub.org or T3networkhub.org account, you can login with that email.',
                    signupPhoneAlreadyExists:
                        'Phone already exists. If you already have an lerhub.org or T3networkhub.org account, you can login with your phone number.',
                },
            },
        },

        recommendationsMatchmakerWhitelist: [],

        userSnap: false,
        userSnapTesterEmails: [
            'chris@ed30.com',
            'jackson@learningeconomy.io',
            'johnlenac@gmail.com',
            'chris@ed40.com',
        ],

        bulkUserTemplate:
            'https://docs.google.com/spreadsheets/d/1kkqURN4VHJuj6KZVMMSRRuWGdV_KvdWz5BeoYj9q1SQ/edit?usp=sharing',
        bulkGenerateUserTemplate:
            'https://docs.google.com/spreadsheets/d/1LSN6MLgMmyHToHGpA4EEHZFtGPjLWxn3GsmbgM71Rdw/edit?usp=sharing',

        brandColor1: '#ffeb3b',
        brandColor2: '#000000',

        companyAddress: '406 Lazy Creek Lane, Nashville, TN, 37211',

        pwaServiceWorker: '/sw.js',

        slideMenu: { search: false },
        secondaryNavigation: {
            items: {
                mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                desktop: ['groups', 'home', 'messages', 'search', 'notifications', 'profile'],
            },
        },

        locize: {
            projectId: '662a9e61-2900-409c-a73e-6ba0bbfc3f38',
            apiKey: '3ff8b824-d205-42ea-be99-a366868d9f73',
            referenceLng: 'en',
        },

        ceramic: {
            nodeEndpointUrl: 'https://ceramic-node.welibrary.io:7007',
            contentFamily: 'SuperSkills',
            definitions: {
                MyVerifiableCredentials:
                    'kjzl6cwe1jw14am5tu5hh412s19o4zm8aq3g2lpd6s4paxj2nly2lj4drp3pun2',
            },
            schemas: {
                AchievementVerifiableCredential:
                    'ceramic://k3y52l7qbv1frylibw2725v8gem3hxs1onoh6pvux0szdduugczh0hddxo6qsd6o0',
                VerifiableCredentialsList:
                    'ceramic://k3y52l7qbv1frxkcwfpyauky3fyl4n44izridy3blvjjzgftis40sk9w8g3remghs',
            },
        },

        appflow: {
            liveUpdate: {
                appId: 'ff5def27',
                channel: 'production-ioe',
                updateMethod: 'auto',
            },
        },

        launchDarklyConfig: {
            clientSideID: '64514ca1c158f01311e773af',
            context: {
                kind: 'user',
                key: 'anonymous',
            },
        },
    },
};

export default config;
