import React from 'react';

import useModal from '@web/ui/components/modals/hooks/useModal';

import CreationFull from '@components/lego/creation/CreationFull';
import CreationThumbDisplay from '@components/lego/creation/CreationThumbDisplay';

import { getCreationImageSimple } from '@helpers/creations/creation.helpers';

import { CreationState } from '@shared/welibrary-graphql/types';

const TreasureCreations: React.FC<{ creations: Array<CreationState | null> }> = ({ creations }) => {
    const { newModal } = useModal();

    const handleClick = (creation: CreationState) => {
        newModal(<CreationFull creation={creation} />, {
            sectionClassName: 'no-top-padding no-bottom-padding no-notch lego-modal',
            hideButton: true,
            customCloseButton: true,
        });
    };

    const renderCreations = creations?.map((creation, index) => {
        if (!creation) return <></>;

        return (
            <div onClick={() => handleClick(creation)} className="creation-icon" key={index}>
                <CreationThumbDisplay
                    foreground={getCreationImageSimple(creation)}
                    background={creation.creation?.media?.thumb ?? ''}
                    hideTitle
                    title={creation.creation?.title ?? ''}
                />
            </div>
        );
    });

    return (
        <div className="render-icons" style={{ justifyContent: 'center' }}>
            {renderCreations}
        </div>
    );
};

export default TreasureCreations;
