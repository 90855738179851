import React from 'react';
import { VCCard } from '@learncard/react';
import type { VC } from '@learncard/types';

import { useCurrentUser } from '@stores/User';

const DisplayVC: React.FC<{ credential: VC }> = ({ credential }) => {
    const { currentUser } = useCurrentUser();

    const issuee = {
        type: 'Profile',
        id: currentUser?._id,
        name: currentUser?.profile?.full_name,
        image:
            currentUser?.profile?.picture ||
            'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/xeivQw5TqabM21de3jwv',
    };

    return <VCCard credential={credential} issueeOverride={issuee} />;
};

export default DisplayVC;
