import { createStore } from '@udecode/zustood';
import * as Sentry from '@sentry/browser';
import executeUserSnapScript from '@helpers/startup/usersnap.helpers';
import initUserflow from '@helpers/startup/userflow.helpers';
import i18n from 'i18next';
import { CurrentUser, RefetchCurrentUser } from '@core/types/User';

type CurrentUserStore = {
    currentUser?: CurrentUser | null;
    refetchCurrentUser: RefetchCurrentUser | null;
};

const currentUserStore = createStore('currentUser')<CurrentUserStore>({
    currentUser: null,
    refetchCurrentUser: () => () => {},
})
    .extendActions(set => ({
        setUser: async (user: CurrentUser) => {
            if (user?.profile?.language) await i18n.changeLanguage(user.profile.language);
            if (user?.profile?.email) executeUserSnapScript(user?.profile?.email);
            if (user?._id) Sentry.setUser({ id: user?._id });
            if (user) initUserflow(user);
            set.currentUser(user);
        },
        setRefetchUser: (refetch: RefetchCurrentUser) => set.refetchCurrentUser(refetch),
    }))
    .extendSelectors(store => ({
        currentUser: () => store.currentUser,
        refetchCurrentUser: () => store.refetchCurrentUser(),
    }));

export default currentUserStore;

export const useCurrentUser = () => {
    const currentUser = currentUserStore.use.currentUser();
    const setCurrentUser = (user: CurrentUser) => {
        currentUserStore.set.setUser(user);
    };
    const { refetchCurrentUser } = currentUserStore.get;

    return { currentUser, setCurrentUser, refetchCurrentUser };
};
