import React from 'react';

const Minus: React.FC<{ className?: string; strokeWidth?: string }> = ({
    className = '',
    strokeWidth = '2',
}) => (
    <svg className={className} viewBox="0 0 32 32">
        <line
            x1="12"
            y1="16"
            x2="20"
            y2="16"
            stroke="currentcolor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
        />
    </svg>
);

export default Minus;
