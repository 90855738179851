import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { DocumentNode, QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import useOnScreen from '@components/generic/hooks/useOnScreen';

import { SetState } from '@core/types/Utilities';

type UseInfiniteScroll = <T extends QueryResult>(args: {
    query: { query: DocumentNode; options: QueryHookOptions };
    visibleOffset?: number | string;
    throttleTime?: number;
    customNextPage?: (queryResult: T, ref: Element | null) => void;
    customHasMoreField?: string;
    customCursorField?: string;
}) => {
    getNextPage: () => Promise<void>;
    setRef: SetState<Element | null>;
    queryResult: T;
};

/**
 * React hook for handling the logic of infinite scrolling data
 * This hook assumes that pagination is done using cursors.
 *
 * Use like so:
 *
 * const { queryResult, setRef } = useInfiniteScroll({ query: { query: GQL_QUERY });
 *
 * const display = queryResult.data?.map((item, index) => (
 *     <Example ref={index === queryResult.data.length - 1 ? setRef : undefined} />
 * )) ?? [];
 *
 * return <Container>{display}</Container>
 */
const useInfiniteScroll: UseInfiniteScroll = ({
    query,
    visibleOffset = 0,
    throttleTime = 100,
    customNextPage,
    customHasMoreField = 'hasMore',
    customCursorField = 'cursor',
}) => {
    const [requestTriggeringElementRef, setRef] = useState<Element | null>(null);

    // have to cast to any for generic type parameter to work
    const queryResult: any = useQuery(query.query, {
        ...query.options,
        notifyOnNetworkStatusChange: true,
    });
    const { onScreen } = useOnScreen({
        ref: requestTriggeringElementRef,
        offset: visibleOffset,
        throttleTime,
    });

    const getNextPage = async () => {
        const resultData = queryResult?.data?.[Object.keys(queryResult?.data)?.[0]];

        if (queryResult && customNextPage) {
            customNextPage(queryResult, requestTriggeringElementRef);
        } else if (get(resultData, customHasMoreField)) {
            return queryResult.fetchMore({
                variables: { cursor: get(resultData, customCursorField) },
            });
        }
    };

    useEffect(() => {
        if (requestTriggeringElementRef && onScreen && !queryResult?.loading) getNextPage();
    }, [requestTriggeringElementRef, onScreen, queryResult?.loading]);

    return { getNextPage, setRef, queryResult };
};

export default useInfiniteScroll;
