import { useMessagesContext } from '@components/messaging/MessagesContext';

import { useGetMessageThreadQuery } from '@shared/welibrary-graphql/messaging/queries.hook';

type GetThreadInfoInput = {
    options?: any;
    threadIdInput?: string;
};

/**
 * React Hook for handling message threads
 * */
const useThread = (): {
    getThreadInfo: (queryInput?: GetThreadInfoInput) => ReturnType<typeof useGetMessageThreadQuery>;
} => {
    const { threadId } = useMessagesContext();

    /**
     * Fetches the current thread information
     * @return {QueryResult}
     */

    const getThreadInfo = (queryInput?: { options?: any; threadIdInput?: string }) => {
        const threadIdInput = queryInput?.threadIdInput || threadId;

        const defaultOptions = {
            variables: { threadId: threadIdInput },
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
            skip: !threadIdInput,
        };

        const queryOptions = queryInput?.options ?? defaultOptions;

        return useGetMessageThreadQuery({ ...queryOptions });
    };
    return {
        getThreadInfo,
    };
};

export default useThread;
