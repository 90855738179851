import React from 'react';
import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';

import DialogPersonSelector from '@components/content/newpost/forms/Dialog/DialogPersonSelector';
import Picture from '@components/generic/Picture';
import { PostEditor } from '@web/ui/components/wysiwyg';
import Button from '@dsc/forms/buttons/Button';
import Pencil from '@web/ui/dsc/svgs/Pencil';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { getRandomDefaultThumbImage } from '@core/utilities/constants/defaults';
import { titleCase } from '@helpers/string.helpers';

import { DialogMessageState, DialogPersonState } from '@core/types/Dialogs';

type DialogMessageFormProps = {
    state: DialogMessageState;
    onChange: (state: DialogMessageState) => void;
    people: DialogPersonState[];
    event: string;
};

const DialogMessageForm: React.FC<DialogMessageFormProps> = ({
    state,
    onChange,
    people,
    event,
}) => {
    const { t } = useTranslation();

    const [messageState, setMessageState] = useImmer(state);

    const updateSlice = curriedStateSlice(setMessageState);

    const { newModal, closeModal } = useModal();

    const submit = () => {
        onChange(messageState);
        closeModal();
    };

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                submit();
            }}
            className="dialog-message-form"
        >
            <section>
                <header>
                    <h3>{titleCase(t('common:dialog_message', 'Dialog message'))}</h3>
                    {event === 'level_completion' && (
                        <button
                            type="button"
                            onClick={() => {
                                newModal(
                                    <>
                                        <h5>
                                            Because this is a Level Completion event, you can use
                                            some special interpolation strings in this message!
                                        </h5>
                                        <p>$level will become the level number completed</p>
                                        <p>
                                            $levelTitle will become the title of the level completed
                                        </p>
                                        <p>
                                            $creationTitle will become the title of the creation
                                            that the level was for
                                        </p>
                                        <p>
                                            $courseTitle will become the title of the course
                                            (activity) that the level was for
                                        </p>
                                    </>
                                );
                            }}
                        >
                            What special strings can I use?
                        </button>
                    )}
                </header>
                <button
                    type="button"
                    onClick={() =>
                        newModal(
                            <DialogPersonSelector
                                people={people}
                                onChange={updateSlice('person')}
                            />
                        )
                    }
                >
                    {typeof messageState.person === 'number' ? (
                        <Picture
                            url={
                                people[messageState?.person]?.image ||
                                getRandomDefaultThumbImage(people[messageState?.person]?.name)
                            }
                            disableLink
                            className="dialog-person"
                        >
                            <figcaption>{people[messageState?.person]?.name}</figcaption>
                            <div role="presentation">
                                <Pencil />
                            </div>
                        </Picture>
                    ) : (
                        'Select Person'
                    )}
                </button>

                <PostEditor
                    defaultValue={messageState.text}
                    onChange={value => updateSlice('text', value())}
                    placeholder={t('common:text', 'Text')}
                />
            </section>

            <footer>
                <Button type="small" onClick={submit}>
                    Submit
                </Button>
                <Button type="small" onClick={closeModal} className="cancel">
                    Cancel
                </Button>
            </footer>
        </form>
    );
};

export default DialogMessageForm;
