import { TFunction } from 'i18next';

import {
    ComparatorType,
    CustomSignupFieldTypes,
    ReferenceType,
} from '@shared/welibrary-graphql/types';

export const FILTER_AZ = 'az';
export const FILTER_LIVE = 'live';
export const FILTER_TOP = 'top';
export const FILTER_LEADERBOARD = 'leaderboard';
export const FILTER_CUSTOM = 'custom';
export const FILTER_RANDOM = 'random';
export const FILTER_VOTE = 'vote';
export const FILTER_ORDERED = 'ordered';
export const FILTER_BEST_MATCH = 'best_match';
export const FILTER_ARCHIVE = 'archived';
export const FILTER_OLDEST_FIRST = 'oldestFirst';
export const SORT_RECENT = 'recentFirst';
export const FILTER_UPCOMING = 'upcoming';
export const FILTER_PAST = 'past';

export const MEMBER_STYLE_FOLLOW = 'follow';
export const MEMBER_STYLE_FRIENDS = 'friends';
export const MEMBER_STYLE_GROUPED = 'grouped';
export const DISP_REF_LIBRARY = ReferenceType.Library;
export const DISP_REF_SHELF = ReferenceType.Shelf;
export const DISP_REF_BOOK = ReferenceType.Book;
export const DISP_REF_CHAPTER = ReferenceType.Chapter;
export const DISP_REF_PROMPT = ReferenceType.Prompt;
export const DISP_REF_GROUP = ReferenceType.Group;
export const DISP_REF_USER = ReferenceType.User;

export const SIGNUP_FIELD_TYPE_DROPDOWN = CustomSignupFieldTypes.Dropdown;
export const SIGNUP_FIELD_TYPE_SHORTANSWER = CustomSignupFieldTypes.Shortanswer;
export const SIGNUP_FIELD_TYPE_PARAGRAPH = CustomSignupFieldTypes.Paragraph;
export const SIGNUP_FIELD_TYPE_CONSENT_BOX = CustomSignupFieldTypes.ConsentBox;
export const SIGNUP_FIELD_TYPE_CHOOSE_ROLE = CustomSignupFieldTypes.ChooseRole;
export const SIGNUP_FIELD_TYPE_JOIN_GROUP = CustomSignupFieldTypes.JoinGroup;
export const SIGNUP_FIELD_TYPE_LOCATION = CustomSignupFieldTypes.ProfileLocation;
export const SIGNUP_FIELD_TYPE_ORGANIZATION = CustomSignupFieldTypes.ProfileOrganization;
export const SIGNUP_FIELD_TYPE_SIGNATURE = CustomSignupFieldTypes.Signature;
export const SIGNUP_FIELD_TYPE_DESCRIPTION = CustomSignupFieldTypes.Description;

export const COMPARATOR_NONE = ComparatorType.None;
export const COMPARATOR_MULTI_AND_POS = ComparatorType.MultiAndPos;
export const COMPARATOR_MULTI_OR_POS = ComparatorType.MultiOrPos;
export const COMPARATOR_RANGE_POS = ComparatorType.RangePos;
export const COMPARATOR_PROXIMITY_NEAR = ComparatorType.ProximityNear;
export const COMPARATOR_PROXIMITY_FAR = ComparatorType.ProximityFar;

export const SIGNUP_FIELD_TYPES = {
    DROPDOWN: SIGNUP_FIELD_TYPE_DROPDOWN,
    SHORTANSWER: SIGNUP_FIELD_TYPE_SHORTANSWER,
    PARAGRAPH: SIGNUP_FIELD_TYPE_PARAGRAPH,
    CONSENT_BOX: SIGNUP_FIELD_TYPE_CONSENT_BOX,
    CHOOSE_ROLE: SIGNUP_FIELD_TYPE_CHOOSE_ROLE,
    JOIN_GROUP: SIGNUP_FIELD_TYPE_JOIN_GROUP,
    LOCATION: SIGNUP_FIELD_TYPE_LOCATION,
    ORGANIZATION: SIGNUP_FIELD_TYPE_ORGANIZATION,
};

export const COMPARATOR_TYPES = {
    NONE: COMPARATOR_NONE,
    MULTI_AND_POS: COMPARATOR_MULTI_AND_POS,
    MULTI_OR_POS: COMPARATOR_MULTI_OR_POS,
    RANGE_POS: COMPARATOR_RANGE_POS,
    PROXIMITY_NEAR: COMPARATOR_PROXIMITY_NEAR,
    PROXIMITY_FAR: COMPARATOR_PROXIMITY_FAR,
} as const;

/**
 * SIGNUP_FIELD_COMPARATORS is used to conditionally display different comparators with different
 * display names depending on the type of custom field selected. You can also use this object to
 * set a default comparator for each custom field type.
 *
 * The Record<CustomSignupFieldTypes, > part of this objet's type is simply saying that this object
 * _only_ has keys that are CustomSignupFieldTypes, and that it has all CustomSignupFieldTypes as
 * keys.
 * In other words, if you call SIGNUP_FIELD_COMPARATORS[type] and type is a member of the
 * CustomSignupFieldTypes enum, you are _guaranteed_ to get valid data. If type is not a member of
 * that enum, you are _guaranteed_ to get undefined.
 */
export const SIGNUP_FIELD_COMPARATORS: Record<
    CustomSignupFieldTypes,
    {
        options: Array<{
            value: ComparatorType;
            display: string;
        }>;
        default: ComparatorType;
    }
> = {
    [SIGNUP_FIELD_TYPE_SHORTANSWER]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Match With This Field' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_NONE,
    },
    [SIGNUP_FIELD_TYPE_PARAGRAPH]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Match With This Field' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_NONE,
    },
    [SIGNUP_FIELD_TYPE_DROPDOWN]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Sum of Matches, more is better' },
            { value: COMPARATOR_MULTI_OR_POS, display: 'Any Match, more is same' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_MULTI_AND_POS,
    },
    [SIGNUP_FIELD_TYPE_CONSENT_BOX]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Match With This Field' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_NONE,
    },
    [SIGNUP_FIELD_TYPE_CHOOSE_ROLE]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Sum of Matches, more is better' },
            { value: COMPARATOR_MULTI_OR_POS, display: 'Any Match, more is same' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_MULTI_AND_POS,
    },
    [SIGNUP_FIELD_TYPE_JOIN_GROUP]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Match With This Field' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_NONE,
    },
    [SIGNUP_FIELD_TYPE_LOCATION]: {
        options: [
            { value: COMPARATOR_PROXIMITY_NEAR, display: 'Match users that are closer together' },
            { value: COMPARATOR_PROXIMITY_FAR, display: 'Match users that are futher apart' },
        ],
        default: COMPARATOR_PROXIMITY_NEAR,
    },
    [SIGNUP_FIELD_TYPE_ORGANIZATION]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Sum of Matches, more is better' },
            { value: COMPARATOR_MULTI_OR_POS, display: 'Any Match, more is same' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_MULTI_AND_POS,
    },

    // These are unused atm
    [CustomSignupFieldTypes.Numberslider]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Match With This Field' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_NONE,
    },
    [CustomSignupFieldTypes.UploadFile]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Match With This Field' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_NONE,
    },
    [CustomSignupFieldTypes.Signature]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Match With This Field' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_NONE,
    },
    [CustomSignupFieldTypes.Description]: {
        options: [
            { value: COMPARATOR_MULTI_AND_POS, display: 'Match With This Field' },
            { value: COMPARATOR_NONE, display: 'Do Not Match With This Field' },
        ],
        default: COMPARATOR_NONE,
    },
};

export const PERSONAL_INFO_TYPES = [
    SIGNUP_FIELD_TYPE_LOCATION,
    SIGNUP_FIELD_TYPE_ORGANIZATION,
] as const;

export const PERSONAL_INFO_FIELDS: Record<
    (typeof PERSONAL_INFO_TYPES)[number],
    { type: CustomSignupFieldTypes; customText: string; displayName: string }
> = {
    [SIGNUP_FIELD_TYPE_LOCATION]: {
        type: SIGNUP_FIELD_TYPE_LOCATION,
        customText: 'Proximity',
        displayName: 'Request Location',
    },
    [SIGNUP_FIELD_TYPE_ORGANIZATION]: {
        type: SIGNUP_FIELD_TYPE_ORGANIZATION,
        customText: 'Organization',
        displayName: 'Request Organization',
    },
};

export const localNamespace = 'imports.wlWeb.ui.components.chapter.chapterBody';

export const getFilterText = (t: TFunction, filter: string) =>
    ({
        [FILTER_VOTE]: t(`common:${localNamespace}.vote`, 'Vote'),
        [FILTER_TOP]: t(`common:${localNamespace}.top`, 'Top'),
        [FILTER_AZ]: 'A-Z',
        [FILTER_LIVE]: t(`common:${localNamespace}.recent`, 'Recent'),
        [FILTER_LEADERBOARD]: t(`common:${localNamespace}.top_ten`, 'Top 10'),
        [FILTER_ORDERED]: t(`common:${localNamespace}.ordered`, 'Ordered'),
        [FILTER_ARCHIVE]: t(`common:${localNamespace}.archived`, 'Archived'),
        [FILTER_OLDEST_FIRST]: t(`common:${localNamespace}.oldest_first`, 'Oldest'),
        [FILTER_CUSTOM]: 'Custom',
        [FILTER_RANDOM]: 'Random',
        [FILTER_BEST_MATCH]: 'Best Match',
        [FILTER_UPCOMING]: t('common:upcoming_events', 'Upcoming Events'),
        [FILTER_PAST]: t('common:past_events', 'Past Events'),
    })[filter];
