import React from 'react';
import {useTranslation} from 'react-i18next';

import CustomStep from '@web/ui/pages/signup/CustomStep';

const localNamespace = 'imports.wlWeb.ui.components.card.darkForms.cardOptimize';

export default props => {
    let activeLibrary = props.activeLibrary;
    let customSignupFields = activeLibrary.library_settings.customSignupFields;
    let allFields = customSignupFields;
    const { t } = useTranslation();

    return (
        <div
            className="edit-screen-tab-container w-tab-pane w--tab-active"
            style={{
                opacity: 1,
                transition: 'opacity 300ms ease 0s',
                paddingBottom: '25px',
            }}
        >
            <CustomStep
                key={'custom'}
                state={props.state}
                title={t(`common:${localNamespace}.custom_step_title`)}
                info={t(`common:${localNamespace}.custom_step_info`)}
                signupChunk={allFields}
                handleChange={props.handleChange}
                handleCustomChange={props.handleCustomChange}
                lightBackground
            />
            <div className="edit-footer">
                <div
                    onClick={props.handleSubmit}
                    className="button callout-button w-button edit-screen-content"
                >
                    {t(`common:global.verbs.save`)}
                </div>
            </div>
        </div>
    );
};
