import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useOptimizers from '@web/ui/components/optimizers/hooks/useOptimizers';
import useOptimizer from '@web/ui/components/optimizers/hooks/useOptimizer';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import SectionContainer from '@web/ui/components/community/SectionContainer';
import SelectableListItem from '@web/ui/components/forms/SelectableListItem';
import ProgressBar from '@web/ui/components/generic/loading/ProgressBar';
import DisplayGroup from '@web/ui/components/group/DisplayGroup';
import Optimizer from '@web/ui/components/optimizers/Optimizer';
import Toggle from '@dsc/forms/customInputs/Toggle';

interface IOptimizers {
    groups: any;
    profile: any;
    updateQuery: any;
    loading: any;
    error: any;
    isUserOptimizers?: any;
    isCardOptimize?: any;
    onOptimizerChange?: any;
    onClickOptimizer?: any;
    onSave?: () => void;
    toggleUseParentAnswers?: any;
    showUseParent?: any;
    useParentValue?: any;
    customTitle?: any;
    customDescription?: any;
    initialOptimizerId?: any;
    includeLocationAndOrganizationPercentage?: boolean;
}

const Optimizers: React.FC<IOptimizers> = ({
    groups,
    profile,
    updateQuery,
    loading,
    error,
    isUserOptimizers,
    isCardOptimize,
    onOptimizerChange,
    onClickOptimizer,
    toggleUseParentAnswers,
    showUseParent,
    useParentValue,
    customTitle,
    customDescription,
    initialOptimizerId,
    includeLocationAndOrganizationPercentage,
    onSave,
}) => {
    const { t } = useTranslation();
    const { calculatePercentage, setActiveOptimizerId, activeOptimizer, groupsList } =
        useOptimizers(groups, profile, isCardOptimize, initialOptimizerId);
    const {
        percentComplete,
        handleChange,
        handleCustomFieldsChange,
        optimizeState,
        saveChanges,
        groupCustomFields,
    } = useOptimizer({
        group: activeOptimizer,
        profile,
        updateQuery,
        isCardOptimize,
        includeLocationAndOrganizationPercentage,
    });

    const save = e => {
        e?.preventDefault();
        onSave?.();
        onClickOptimizer?.();
        saveChanges();
        if (groupsList.length > 0 || (isCardOptimize && groupsList.length === 0)) {
            setActiveOptimizerId(false);
        }
    };

    const goBack = () => {
        if (groupsList.length > 0 || (isCardOptimize && groupsList.length === 0)) {
            setActiveOptimizerId(false);
        }
    };

    useEffect(() => {
        onOptimizerChange?.(activeOptimizer);
    }, [activeOptimizer]);

    return (
        <section className="user-optimizers">
            <LoadingLine isLoading={loading} error={error} />
            {activeOptimizer ? (
                <section>
                    {groupsList.length > 0 && (
                        <div className="flex-justify">
                            <button onClick={goBack} type="button" className="back-button">
                                <span aria-hidden />
                                {t('common:global.back')}
                            </button>

                            {showUseParent && (
                                <span className="parent-group-answer-toggle-span">
                                    Use Parent Group Answers
                                    <Toggle
                                        id="parent-answers-toggle"
                                        value={useParentValue}
                                        onChange={toggleUseParentAnswers}
                                    />
                                </span>
                            )}
                        </div>
                    )}
                    <DisplayGroup
                        title={activeOptimizer.profile.full_name}
                        description={activeOptimizer.profile.short_bio}
                        thumbImage={activeOptimizer.profile.picture}
                    />
                    <ProgressBar completed={activeOptimizer.percentComplete ?? percentComplete} />
                    <Optimizer
                        key={profile?.full_name}
                        isCardOptimize={isCardOptimize}
                        handleChange={activeOptimizer.handleChange ?? handleChange}
                        handleCustomFieldsChange={
                            activeOptimizer.handleCustomFieldsChange ?? handleCustomFieldsChange
                        }
                        optimizeState={activeOptimizer.optimizeState ?? optimizeState}
                        handleSubmit={activeOptimizer.onSave ?? save}
                        groupCustomFields={groupCustomFields}
                        isUserOptimizer={isUserOptimizers}
                        group={activeOptimizer}
                    />
                </section>
            ) : (
                groupsList.length > 0 && (
                    <SectionContainer title={customTitle ?? 'Group Surveys'}>
                        <p style={{ marginLeft: '10px' }}>
                            {customDescription ??
                                'Optimize recommendations by answering the following surveys:'}
                        </p>
                        {groupsList.map(
                            ({ _id: groupId, profile: { full_name, short_bio, picture } }) => (
                                <SelectableListItem
                                    selected={groupId === activeOptimizer._id}
                                    onClick={() => {
                                        setActiveOptimizerId(groupId);
                                        onClickOptimizer?.();
                                    }}
                                    key={groupId}
                                >
                                    <DisplayGroup
                                        title={full_name}
                                        description={short_bio}
                                        thumbImage={picture}
                                    />
                                    <ProgressBar completed={calculatePercentage(groupId)} />
                                </SelectableListItem>
                            )
                        )}
                    </SectionContainer>
                )
            )}
        </section>
    );
};

export default Optimizers;
