import React from 'react';

import { isEventInThePast, isEventInProgress } from '@helpers/events/event.helpers';

import { Maybify } from '@core/types/Utilities';
import { Group } from '@shared/welibrary-graphql/types';

type EventTimeContextProps = {
    /** Pass group to have eventIsOver/InProgress calculated automatically */
    group?: Maybify<Group> | null | undefined;

    /** Or pass the isOver/inProgress values directly */
    eventIsOver?: boolean;
    eventIsInProgress?: boolean;

    /** Whether to prepend with a • separator */
    includeSeparator?: boolean;

    className?: string;
};

const EventTimeContext: React.FC<EventTimeContextProps> = ({
    group,
    eventIsOver,
    eventIsInProgress,
    includeSeparator,
    className = '',
}) => {
    const _eventIsOver = eventIsOver ?? isEventInThePast(group);
    const _eventIsInProgress = eventIsInProgress ?? isEventInProgress(group);

    if (!_eventIsOver && !_eventIsInProgress) return <></>;

    return (
        <>
            {includeSeparator !== false && <span className="separator">•</span>}

            {_eventIsOver && <span className={`event-ended ${className}`}>Event Ended</span>}
            {_eventIsInProgress && <span className={`${className}`}>Event In Progress</span>}
        </>
    );
};

export default EventTimeContext;
