import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';

import { RecruitType, useAddPledgeMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';

import Modal from '@web/ui/components/generic/lightbox/Modal';
import PledgeHistory from '@web/ui/components/card/pledgecard/PledgeHistory';
import PledgeQuestions from '@web/ui/components/card/pledgecard/PledgeQuestions';
import PledgeNote from '@web/ui/components/card/pledgecard/PledgeNote';
import PledgeAttachment from '@web/ui/components/card/pledgecard/PledgeAttachment';
import PledgersList from '@web/ui/components/card/pledgecard/PledgersList';

import { getWhitelabeledKey } from '@core/utilities/constants/languages';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
    StyledBackgroundImageLink,
} from '@core/utilities/constants/defaults';

import { ModalTypes } from '@core/types/Modals';
import { AliasItem, PledgeItem } from '@shared/welibrary-graphql/types';
import { ContentCardPledgesFragmentFragment } from '@shared/welibrary-graphql/content_card/fragments.hook';

import { config } from '@core/config/getConfig';

import getLogger from '@core/logger';

const logger = getLogger(module);

enum Modals {
    Questions = 'questions',
    EditPledge = 'editPledge',
}

enum ReportingType {
    MarkDone = 'DONE',
    UploadMedia = 'UPLOAD_IMAGE',
    AddNote = 'ADD_NOTE',
    RegisterUser = 'REGISTER_USER',
}

const localNamespace = 'imports.wlWeb.ui.components.card.pledgeCard';

type PledgeCardProps = {
    alias: AliasItem;
    showReport: boolean;
    loggedInUserCanEdit: boolean;
    pledgeItems: ContentCardPledgesFragmentFragment;
    cardRefetch: Function;
};

const PledgeCard: React.FC<PledgeCardProps> = ({
    alias,
    showReport,
    loggedInUserCanEdit,
    pledgeItems: {
        pledges,
        pledgeKeys,
        pledgers,
        totalPledges,
        pledgesLastDay,
        pledgesLastMonth,
        totalPledgers,
    },
    cardRefetch,
}) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();
    const { newModal } = useModal({ mobile: ModalTypes.Center });
    const [state, setState] = useState({
        showQuestions: false,
        questionsAnswered: false,
        country: '',
        name: '',
        email: '',
        note: '',
        activityId: null,
        newRecruit: false,
        url: '',
        addPledge: null,
    });
    const [pledgeItem, setPledgeItem] = useState<PledgeItem>({});
    const [phone, setPhone] = useState<null | string>(null);
    const [recruitType, setRecruitType] = useState<null | RecruitType>(null);

    const [showModal, setShowModal] = useState<false | Modals>(false);

    const [addPledge] = useAddPledgeMutation();

    const { item } = alias;

    if (!item) return <></>;

    const { whitelabelVerbage, body } = item;

    const pledgeCardDesc = body && body?.length > 144 ? `${body?.substr(0, 144).trim()}...` : body;

    const handleUpdateState = (updateValues: Partial<typeof state>) => {
        setState(prevState => {
            return {
                ...prevState,
                ...updateValues,
            };
        });
    };

    const handleShowHistory = (e?: React.MouseEvent, id?: string) => {
        e?.stopPropagation();
        e?.preventDefault();
        newModal(
            <PledgeHistory
                alias={alias}
                whitelabelVerbage={whitelabelVerbage}
                cardRefetch={cardRefetch}
                pledgeId={id}
            />,
            { className: 'pledge-history-modal' }
        );
    };

    const resetFields = () => {
        handleUpdateState({ country: '', name: '', email: '', note: '' });
        setPhone(null);
        setRecruitType(null);
    };

    const handleModalClose = () => {
        resetFields();
        setShowModal(false);
    };

    const handleAddPledge = (pledgeData: { pledge?: PledgeItem; url?: string } = {}) => {
        const { pledge = pledgeItem, url = state.url } = pledgeData;

        const group = currentUser?.activeGroup?._id;

        const { newRecruit, country, name, email, note } = state;

        if (pledge.canPledge) {
            switch (pledge.reportingType) {
                case ReportingType.MarkDone:
                    addPledge({ variables: { input: { url, pledgeId: pledge._id!, group } } })
                        .then(() => {})
                        .catch(e => logger.error(e.message));
                    break;
                case ReportingType.RegisterUser:
                    // TODO: Remove this. This is for testing only, to add the recruit to this group so they show up in analytics.
                    const createAccountInGroup = config.public.root_url.includes('localhost')
                        ? group
                        : null;

                    let enrollEmailTemplateOverride: any;

                    if (config.public.pledge) {
                        const { new_recruit_template, failed_recruit_template } =
                            config.public.pledge;

                        enrollEmailTemplateOverride = newRecruit
                            ? new_recruit_template
                            : failed_recruit_template;
                    }

                    addPledge({
                        variables: {
                            input: {
                                url,
                                pledgeId: pledge._id!,
                                group,
                                // ignore this field for done, note & url
                                verifier: {
                                    name,
                                    email: email?.toLowerCase().trim(),
                                    phone,
                                    newRecruit,
                                    recruitType,
                                    country,
                                    note,
                                    createAccountInGroup,
                                    enrollEmailTemplateOverride,
                                },
                            },
                        },
                    })
                        .then(() => handleModalClose())
                        .catch(e => logger.error(e.message));
                    break;
                default:
                    break;
            }
        }
    };

    const submit = () => {
        handleAddPledge();
        resetFields();
    };

    const handlePledge = (url: string, pledge: PledgeItem) => {
        if (!pledge.canPledge) return;

        if (pledge.reportingType === ReportingType.MarkDone) {
            return handleAddPledge({ url, pledge });
        }

        if (pledge.reportingType === ReportingType.UploadMedia) {
            return newModal(<PledgeAttachment url={url} pledgeId={pledge._id!} />);
        }

        if (pledge.reportingType === ReportingType.AddNote) {
            return newModal(<PledgeNote url={url} pledgeId={pledge._id!} />);
        }

        setPledgeItem(pledge);
        handleUpdateState({ url });
        setShowModal(Modals.Questions);
    };

    const numberWithCommas = (number: number) =>
        number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const getDisplayableTotal = (total: number) => {
        if (total > 999999) return `${total / 1000000}m`;

        if (total > 99999) return `${total / 100000}k`;

        return numberWithCommas(total);
    };

    const handleShowPledgers: React.MouseEventHandler<HTMLElement> = e => {
        e.stopPropagation();
        newModal(<PledgersList pledgers={pledgers} whitelabelVerbage={whitelabelVerbage} />);
    };

    const handleChange = e => {
        const { target } = e;
        const { name } = target;
        const value = target.value === 'checkbox' ? target.checked : target.value;
        handleUpdateState({ [name]: value });
    };

    const handleSelectRecruit: React.ChangeEventHandler<HTMLSelectElement> = e => {
        e.persist();
        setRecruitType(e.currentTarget.value as RecruitType);
    };

    const pledgersSample = pledgers?.map((pledger, index) => {
        if (!pledger) return <></>;

        const [src, srcSet] = getBackgroundImageSetForUrl(
            pledger.profile?.picture || getRandomDefaultUserImage()
        );

        return (
            <StyledBackgroundImageLink
                to={`/u/${pledger._id}`}
                key={`pledger-${pledger._id}`}
                className={`pledge-card-participant-thumb ${
                    index === pledgers.length - 1 ? 'expanded' : ''
                } w-inline-block`}
                src={src as string}
                srcSet={srcSet as string}
            />
        );
    });

    const pledgeCards = pledges?.map((pledge, index) => {
        if (!pledge) return <></>;
        if (pledge.reportingType === ReportingType.RegisterUser) return <></>;

        const isPledgeHidden = pledgeKeys?.find(pledgeKey => pledgeKey?._id === pledge._id)?.hidden;
        const pledgeDescription = pledgeKeys?.find(
            pledgeKey => pledgeKey?._id === pledge._id
        )?.description;

        let reportingTypeIcon;
        let reportingTypeText;
        if (pledge.reportingType === ReportingType.MarkDone) {
            reportingTypeIcon = 'checkmark';
            reportingTypeText = 'Mark Done';
        } else if (pledge.reportingType === ReportingType.AddNote) {
            reportingTypeIcon = 'plus';
            reportingTypeText = 'Add Note';
        } else if (pledge.reportingType === ReportingType.RegisterUser) {
            reportingTypeIcon = 'plus';
            reportingTypeText = 'Register User';
        } else if (pledge.reportingType === ReportingType.UploadMedia) {
            reportingTypeIcon = 'plus';
            reportingTypeText = 'Add Media';
        }

        if (!loggedInUserCanEdit && isPledgeHidden) return null;

        return (
            <React.Fragment key={pledge._id ?? index}>
                <div className="pledge-card-button" key={`pledge-${pledge._id}`}>
                    {isPledgeHidden && loggedInUserCanEdit && (
                        <div className="pledge-card-button-overlay">
                            <div className="desc">This item is hidden</div>
                        </div>
                    )}
                    <div>{pledge.label}</div>
                    <div className="pledge-card-description">{pledgeDescription}</div>
                    <div className="pledge-button-counter-container">
                        <div className="pledge-button-counter-text">
                            {pledge.currentUsersPledges}
                        </div>

                        <div className="pledge-button-toggle-container">
                            {(pledge.currentUsersPledges ?? 0) > 0 && (
                                <button
                                    type="button"
                                    className="pledge-button-action-icon pledge-history-icon button-reset"
                                    onClick={e => handleShowHistory(e, pledge._id ?? '')}
                                >
                                    {t(`global.nouns.history`)}
                                </button>
                            )}
                            {pledge.canPledge && (
                                <button
                                    onClick={e => {
                                        e.stopPropagation();
                                        handlePledge(alias.url, pledge);
                                    }}
                                    type="button"
                                    className={`button-reset ${
                                        pledge.canPledge
                                            ? `pledge-button-action-icon pledge-button-toggle-button up ${reportingTypeIcon}`
                                            : 'pledge-button-inactive'
                                    } pledge-icon-hover w-inline-block content-button-icon`}
                                >
                                    {reportingTypeText}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    });

    const reportCards = [
        {
            _id: 'report_total_members',
            label: t(
                getWhitelabeledKey(
                    `common:${localNamespace}.report_total_members`,
                    whitelabelVerbage
                )
            ),
            value: totalPledgers,
        },
        {
            _id: 'report_total_reached',
            label: t(
                getWhitelabeledKey(
                    `common:${localNamespace}.report_total_reached`,
                    whitelabelVerbage
                )
            ),
            value: totalPledges,
        },
        {
            _id: 'report_total_reached_30',
            label: t(
                getWhitelabeledKey(
                    `common:${localNamespace}.report_total_reached_30`,
                    whitelabelVerbage
                )
            ),
            value: pledgesLastMonth,
        },
        {
            _id: 'report_total_reached_day',
            label: t(
                getWhitelabeledKey(
                    `common:${localNamespace}.report_total_reached_day`,
                    whitelabelVerbage
                )
            ),
            value: pledgesLastDay,
        },
    ];

    const pledgeReportCards = showReport
        ? reportCards.map(pledge => {
              return (
                  <div className="pledge-results-data-block" key={`pledge-${pledge._id}`}>
                      <div className="pledge-results-data-container">
                          <div className="pledge-results-data-container-left">
                              <div>{pledge.label}</div>
                          </div>
                          <div className="pledge-results-data-container-right">
                              <div className="pledge-results-number-container report-card">
                                  <div>{getDisplayableTotal(pledge.value ?? 0)}</div>
                              </div>
                          </div>
                      </div>
                  </div>
              );
          })
        : [];

    return (
        <>
            <div className="content-card-container pledge-card-container">
                <div className="pledge-card-header">
                    <section className="pledge-card-header-content">
                        <div className="pledge-card-heading">{item.title}</div>
                        <div className="pledge-card-desc">{pledgeCardDesc}</div>
                    </section>
                    {item.author && (
                        <div className="mc-source-sub white-text pledge-card-source">
                            {t(`common:global.prepositions.by`)}
                            <Link to={`/u/${item.author._id}`}>
                                <span className="white-link">
                                    {' '}
                                    {item.author.profile?.full_name}
                                </span>
                            </Link>
                        </div>
                    )}
                    <div className="pledge-card-participants-container">
                        <div className="pledge-card-participant-block">
                            {pledgersSample}
                            <button
                                type="button"
                                onClick={handleShowPledgers}
                                className="white-link faded-white-link button-reset"
                            >
                                {getDisplayableTotal(totalPledgers ?? 0)}{' '}
                                <span>
                                    {t(
                                        getWhitelabeledKey(
                                            `common:${localNamespace}.pledgers`,
                                            whitelabelVerbage
                                        )
                                    )}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="pledge-card-body">{pledgeCards}</div>
                <div className="engagement-footer divided open" />
                {pledgeReportCards}
            </div>

            <Modal
                delayTime={500}
                smallModal
                className="modal"
                isMounted={showModal}
                show={showModal}
                close={handleModalClose}
            >
                {showModal === Modals.Questions && (
                    <PledgeQuestions
                        state={{ ...state, phone }}
                        handleSelectRecruit={handleSelectRecruit}
                        handlePhone={setPhone}
                        handleChange={handleChange}
                        submit={submit}
                        close={handleModalClose}
                        whitelabelVerbage={whitelabelVerbage}
                    />
                )}
            </Modal>
        </>
    );
};

export default PledgeCard;
