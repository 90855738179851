import React from 'react';

import TicketSharingContainer from '@components/card/Shopping-Cart/TicketSharing/TicketSharingContainer';

import { useGetOrderByIdQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { useCurrentUser } from '~/wl-web/ui/stores/User';

const TicketSharing: React.FC<{ orderId: String }> = ({ orderId }) => {
    const { currentUser } = useCurrentUser();

    const { data, loading } = useGetOrderByIdQuery({
        variables: {
            _id: orderId,
        },
        fetchPolicy: 'network-only',
    });

    if (loading) {
        return (
            <section className="ticket-sharing">
                <p>Loading...</p>
            </section>
        );
    }

    const tickets = data?.getOrder?.productInstances ?? [];

    const transferableTickets =
        tickets.filter(t => {
            return t?.purchaser?._id === currentUser?._id && t?.isCheckedIn !== true;
        }) ?? [];

    return <TicketSharingContainer tickets={transferableTickets} />;
};

export default TicketSharing;
