import {SEARCH_USER_QUERY} from '@core/client/types.js';

export default function drawers(state = {}, action = {}) {
    switch (action.type) {
        case SEARCH_USER_QUERY:
            let searchUIItem = {};
            searchUIItem[SEARCH_USER_QUERY] = { query: action.query };
            // Return a new object for the createCard state, merging in the new property.
            return Object.assign({}, state, searchUIItem);
        default:
            return state;
    }
}
