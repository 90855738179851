import { useQuery } from '@apollo/client';

import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';

import { Group } from '@shared/welibrary-graphql/types';

const useGroupMembersDashfeed = (group: Group) => {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    if (!group) throw 'Group is required';

    // Query for members dashfeed
    const {
        loading,
        error,
        data: feedData,
    } = useQuery(GET_GROUP_DASHBOARD, {
        variables: {
            _id: group?._id,
            suppressPublicDashboard: true,
            suppressMembersDashboard: false,
            suppressChildGroups: true,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !group?._id,
    });

    const dashboardData = feedData;
    const dashboardGroup = dashboardData?.groupById;

    const membersDashboardData = dashboardData?.groupById?.membersDashboard;
    const membersDashboardSource = dashboardData?.groupById?.membersDashboardSource;
    const dashboardSource = membersDashboardSource;

    const dashfeedData = membersDashboardData;

    return {
        loading,
        error,
        dashboardGroup,
        dashboardSource,
        dashfeedData,
    };
};

export default useGroupMembersDashfeed;
