import currentUserStore from '@stores/User';

const JWT_KEY = 'jwt';

export const setAuthToken = token => {
    const storage = window?.localStorage ?? localStorage;
    return storage.setItem(JWT_KEY, token);
};

export const unsetAuthToken = () => {
    const storage = window?.localStorage ?? localStorage;
    currentUserStore.set.currentUser(null);
    return storage.removeItem(JWT_KEY);
};

export const getAuthToken = () => {
    const storage = window?.localStorage ?? localStorage;
    return storage.getItem(JWT_KEY);
};
