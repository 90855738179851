import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useCurrentUser } from '@stores/User';
import {
    useGetUserOrdersForGroupQuery,
    useGetLoggedInCustomerCardsQuery,
} from '@shared/welibrary-graphql/user/queries.hook';
import useModal from '@components/modals/hooks/useModal';

import X from '@dsc/svgs/FullSizeX';
import VerifyBadge from '@dsc/svgs/VerifyBadge';
import TinyGlobe from '@dsc/svgs/TinyGlobe';
import Picture from '@components/generic/Picture';
import AddPaymentForm from '@components/group/groupdashboard/groupSubscriptionPaywall/AddPaymentForm';
import GroupSubscriptionActionButton from '@components/group/groupdashboard/groupSubscriptionPaywall/GroupSubscriptionActionButton';
import ManageSubscriptionPayment from '@components/group/groupdashboard/groupSubscriptionPaywall/ManageSubscriptionPayment';
import SubscriptionPaymentHistory from '@components/group/groupdashboard/groupSubscriptionPaywall/SubscriptionPaymentHistory';
import LottieLoading from '../../../generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/circle-spinner.json';

import {
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
} from '@core/utilities/constants/defaults';
import { getCurrencyDisplayString } from '@web/utilities/helpers/money/money.helpers';

import { Group } from '@shared/welibrary-graphql/types';
import {
    Tabs,
    SubTabs,
    getSubscriptionStatus,
    SubscriptionStatus,
} from '@components/group/groupdashboard/groupSubscriptionPaywall/groupSubscriptionPaywallHelpers';

const ManageUserGroupSubscription: React.FC<{ group: Group }> = ({ group }) => {
    const { currentUser, refetchCurrentUser } = useCurrentUser();
    const { closeAllModals } = useModal();

    const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Subscription);
    const [activeSubTab, setActiveSubTab] = useState<SubTabs>(SubTabs.ViewCards);
    const [showFooter, setShowFooter] = useState<boolean>(true);

    const [coverSrc] = getBackgroundImageSetForUrl(
        group?.profile?.coverPhoto || getRandomDefaultCoverImage(group?.profile?.full_name ?? '')
    );

    const { data } = useGetLoggedInCustomerCardsQuery({
        fetchPolicy: 'no-cache',
    });

    const {
        data: groupOrders,
        loading,
        stopPolling,
        startPolling,
    } = useGetUserOrdersForGroupQuery({
        variables: {
            groupId: group._id,
            status: 'succeeded',
        },
        skip: !group._id,
        fetchPolicy: 'cache-and-network',
    });

    // customer cards
    const cards = data?.getLoggedInCustomerCards?.results;

    // find the first order with the subscription status of ACTIVE || trialing || past_due
    const order =
        groupOrders?.getOrdersForUserForGroup.find(
            _order =>
                _order?.subscription?.status === SubscriptionStatus.Active ||
                _order?.subscription?.status === SubscriptionStatus.Trialing ||
                _order?.subscription?.status === SubscriptionStatus.PastDue
        ) ?? {};

    const _product = order?.products?.[0] ?? {};

    const currency = order?.subscription?.currency ?? 'USD';

    const price = order?.subscriptionUpcomingInvoice?.total ?? 0;
    const priceDisplayString = getCurrencyDisplayString(price, currency, undefined, false, 2);

    const nextInvoiceDate = moment
        .unix(order?.subscriptionUpcomingInvoice?.period_end)
        .format('MMMM D, YYYY');

    const cancelPeriod = moment.unix(order?.subscription?.current_period_end).format('MMMM D');

    const isWalletTabActive = activeTab === Tabs.Wallet;
    const isAddCardTabActive = activeTab === Tabs.AddCard;
    const isSubscriptionTabActive = activeTab === Tabs.Subscription;
    const isPaymentHistorytabActive = activeTab === Tabs.PaymentHistory;

    const subscriptionStatus = getSubscriptionStatus(order?.subscription?.status);

    useEffect(() => {
        if (
            order?.subscription?.status === SubscriptionStatus.Active ||
            order?.subscription?.status === SubscriptionStatus.Canceled
        ) {
            stopPolling();
        } else {
            startPolling(1000);
        }
    }, []);

    let invoiceStatusText: string = '';
    if (
        order?.subscription?.status === SubscriptionStatus.Active &&
        !order?.subscriptionUpcomingInvoice?.period_end
    ) {
        invoiceStatusText = `Subscription cancelled, ends ${cancelPeriod}`;
    } else if (
        (order?.subscription?.status === SubscriptionStatus.Active ||
            order?.subscription?.status === SubscriptionStatus.Trialing) &&
        order?.subscriptionUpcomingInvoice?.period_end
    ) {
        invoiceStatusText = `Next invoice on ${nextInvoiceDate}`;
    }

    let defaultSource = currentUser?.paymentSettings?.stripeCustomer?.default_source;
    const defaultPaymentMethod =
        currentUser?.paymentSettings?.stripeCustomer?.invoice_settings?.default_payment_method;
    if (defaultPaymentMethod) {
        defaultSource = defaultPaymentMethod;
    }

    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center pt-24 text-center">
                <LottieLoading height={120} width={120} lottieData={LottieAnimation} />
                Loading...
            </div>
        );
    }

    return (
        <section className="manage-sub">
            <button type="button" onClick={() => closeAllModals()} className="manage-sub-btn-close">
                <X />
            </button>
            <header>
                <div className="checkout-item-preview-sub">
                    <div className="checkout-item-preview-sub-header">
                        <h1>Manage Membership</h1>
                        {isSubscriptionTabActive && (
                            <p className="checkout-item-inner-details-total checkout-item-inner-details-total-inline">
                                <span className="new-total">{priceDisplayString}</span>&nbsp;per
                                Month
                            </p>
                        )}
                    </div>
                    <div className="checkout-item-preview-sub-content">
                        <div
                            className="checkout-item-preview-sub-cover"
                            style={{
                                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${coverSrc})`,
                            }}
                        />

                        <div className="checkout-item-preview-sub-thumb">
                            <Picture url={coverSrc} className="checkout-item-preview-thumb-img" />
                        </div>

                        <div className="checkout-item-preview-sub-info">
                            <div className="checkout-item-preview-sub-privacy">
                                <div className="paywall-info-icon-wrap">
                                    <VerifyBadge className="paywall-info-icon-badge" />
                                    <TinyGlobe
                                        className="paywall-info-icon-world"
                                        strokeColor="#fff"
                                    />
                                </div>
                                &nbsp;
                                <p>{group?.settings?.protectionLevel} Group</p>
                            </div>
                            <div className="checkout-item-preview-sub-text">
                                <h5>{_product?.name}</h5>
                                <p>{group?.profile?.full_name}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="manage-sub-tabs-wrap">
                    <div className="manage-sub-tabs-wrap">
                        <button
                            type="button"
                            className={`${isSubscriptionTabActive ? 'manage-sub-tab-active' : ''}`}
                            onClick={() => {
                                setActiveTab(Tabs.Subscription);
                                setShowFooter(true);
                            }}
                        >
                            Subscription
                        </button>
                        <button
                            type="button"
                            className={`${
                                isWalletTabActive || isAddCardTabActive
                                    ? 'manage-sub-tab-active'
                                    : ''
                            }`}
                            onClick={() => {
                                setActiveTab(Tabs.Wallet);
                                setActiveSubTab(SubTabs.ViewCards);
                                setShowFooter(true);
                            }}
                        >
                            Wallet
                        </button>
                        <button
                            type="button"
                            className={`${
                                isPaymentHistorytabActive ? 'manage-sub-tab-active' : ''
                            }`}
                            onClick={() => setActiveTab(Tabs.PaymentHistory)}
                        >
                            Payment History
                        </button>
                    </div>
                </div>
            </header>
            {isSubscriptionTabActive && (
                <div className="manage-sub-tabs-wrap manage-sub-tabs-next-payment">
                    <div className="manage-subs-info-wrap">
                        <h4 style={{ color: subscriptionStatus.color }}>
                            {subscriptionStatus?.label}{' '}
                            {order?.subscription?.status === SubscriptionStatus.Active &&
                                !order?.subscriptionUpcomingInvoice?.period_end && (
                                    <span>Cancels {cancelPeriod}</span>
                                )}
                        </h4>
                        <div className="manage-subs-info-pricing">
                            <h4>Membership fee</h4>
                            <h4>{priceDisplayString}&nbsp;per Month</h4>
                        </div>
                        <p>{invoiceStatusText}</p>
                    </div>
                </div>
            )}
            {isWalletTabActive && (
                <ManageSubscriptionPayment
                    cards={cards}
                    defaultSource={defaultSource}
                    activeSubTab={activeSubTab}
                    setActiveSubTab={setActiveSubTab}
                    handleShowFooter={setShowFooter}
                    refetchCurrentUser={refetchCurrentUser}
                />
            )}
            {isPaymentHistorytabActive && <SubscriptionPaymentHistory groupId={group._id} />}
            {isAddCardTabActive && (
                <AddPaymentForm
                    cancel={() => {
                        setActiveTab(Tabs.Wallet);
                        setShowFooter(true);
                    }}
                />
            )}

            {(isWalletTabActive || isSubscriptionTabActive) && showFooter && (
                <footer className="manage-subs-payment-btns-wrap">
                    {isWalletTabActive && (
                        <button
                            type="button"
                            onClick={() => {
                                setShowFooter(false);
                                setActiveTab(Tabs.AddCard);
                            }}
                        >
                            Add Payment Method
                        </button>
                    )}

                    {isSubscriptionTabActive && (
                        <GroupSubscriptionActionButton
                            order={order}
                            customClassName="manage-subs-payment-btn-cancel"
                        />
                    )}
                </footer>
            )}
        </section>
    );
};

export default ManageUserGroupSubscription;
