import { Updater } from 'use-immer';

import { ImageWithLoadingState } from '@core/types/Utilities';
import { SurveyQuestionFormState } from '@core/types/Surveys';
import { Resource, Scalars } from '@shared/welibrary-graphql/types';

export type CourseCardSettingsState = {
    rankOrder?: number | false;
    displayInNewsfeed: boolean;
};

export type StepState = {
    text: string;
    useImage: boolean;
    image: ImageWithLoadingState;
    useVideo: boolean;
    video: string;
    fullPage: boolean;
    pageBreak: boolean;
    skill?: string;
    skillSubtype?: string;
    skillMilestone?: string;
};

export type LevelState = {
    title: string;
    steps: StepState[];
    media: ImageWithLoadingState;
    video: string;
    resource: Resource;
    preSurvey: SurveyQuestionFormState[];
    postSurvey: SurveyQuestionFormState[];
};

export type CourseFormState = {
    title: string;
    headline: string;
    description: string;
    thumbnail: string;
    coverImage: string;
    link: string;
    materials: string[];
    ageGroup: Scalars['Range'];
    duration: Scalars['Range'];
    people: string;
    publicCompletions: boolean;
};

export type CourseState = {
    settings: {
        cardSettings: CourseCardSettingsState;
        setCardSettings: Updater<CourseCardSettingsState>;
    };
    card: { card: CourseFormState; setCard: Updater<CourseFormState> };
    levels: {
        levels: LevelState[];
        addLevel: () => void;
        deleteLevel: (index: number) => void;
        updateLevel: Updater<LevelState>;
        activeLevel: number | false;
        setActiveLevel: Updater<number | false>;
    };
};

export enum CoursePageTab {
    Activity = 'activity',
    Materials = 'materials',
    Level = 'level',
    Survey = 'survey',
}

export enum CoursePageSurvey {
    Pre = 'pre',
    Post = 'post',
}
