import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonCirclePlus from '@web/ui/dsc/buttons/ButtonCirclePlus';
import ButtonCircleEdit from '@web/ui/dsc/buttons/ButtonCircleEdit';
import PreferencesIcon from '@web/ui/dsc/svgs/PreferencesIcon';

import { CARD_SUBTYPES } from '@core/utilities/constants/card_types.js';
import { Group } from '@shared/welibrary-graphql/types';

const SwitchDashFeedButton = ({ children }) => {
    return (
        <button className="button-reset group-dashfeed-switch-btn" type="button">
            <span>{children}</span>
            <PreferencesIcon className="group-feed-toggle" />
        </button>
    );
};

const EditDashboardHeader: React.FC<{
    group: Group;
    feedType: string;
    handleOpenModal: (modalType: string) => void;
    handleDashfeedSwitch: () => void;
}> = ({ group, feedType, handleDashfeedSwitch, handleOpenModal }) => {
    const { t } = useTranslation();
    const localNamespace =
        group?.subtype === 'event'
            ? 'imports.wlWeb.ui.components.group.eventDashboard'
            : 'imports.wlWeb.ui.components.group.groupDashboard';

    const handleSwitchFeed = () => {
        handleDashfeedSwitch();
    };

    const dashboardTitle =
        // eslint-disable-next-line no-nested-ternary
        feedType === CARD_SUBTYPES.DASHBOARD
            ? t(`common:${localNamespace}.members_dashboard`)
            : feedType === CARD_SUBTYPES.PUBLIC_DASHBOARD
                ? t(`common:${localNamespace}.public_dashboard`)
                : '';

    const buttonStyle = { backgroundColor: '#E3E5E8' };
    return (
        <section className="edit-dashboard-header">
            <div onClick={handleSwitchFeed} className="feed-switch">
                <SwitchDashFeedButton> </SwitchDashFeedButton>
                <h3>{dashboardTitle}</h3>
            </div>
            <div className="edit-dashboard-header-buttons">
                {/* <button
              type="button"
              onClick={() => handleOpenUpdateGroup(true)}
              label="Edit Group"
              className="button-reset content-button-icon edit animate-grow"
          /> */}
                <ButtonCircleEdit
                    onClick={() => handleOpenModal('settings')}
                    size="30px"
                    toolTipText="Edit dashfeed settings"
                    customStyle={{ backgroundColor: 'transparent' }}
                />
                <ButtonCirclePlus
                    onClick={() => handleOpenModal('postbox')}
                    customStyle={buttonStyle}
                    size="30px"
                    toolTipText="Add post to dashfeed"
                    label="Add post to dashfeed"
                />
            </div>
        </section>
    );
};

export default EditDashboardHeader;
