import {
    ATTACH_AUDIO,
    ATTACH_IMAGE,
    ATTACH_IMAGE_SQUARE,
    ATTACH_VIDEO,
    ATTACH_DOCUMENT,
    ATTACH_LINK,
    ATTACH_EVENT,
    ATTACH_BLOG,
    ATTACH_COURSE,
    ATTACH_RESEARCH,
    ATTACH_MOBILIZE,
    ATTACH_MOBILIZATION,
    ATTACH_PLEDGE,
    ATTACH_POSTBOX,
    ATTACH_VOTE_GALLERY,
    ATTACH_LINK_TILE,
    ATTACH_HEADER,
    ATTACH_MAP,
    ATTACH_CUSTOM_FIELDS_SUMMARY,
    ATTACH_META_CHANNEL,
    ATTACH_DYNAMIC_CARD,
    ATTACH_STORY_CARD,
} from '@core/utilities/constants/attachment_types.js';

import {
    CARD_IMAGE,
    CARD_IMAGE_SQUARE,
    CARD_AUDIO,
    CARD_SHELF,
    CARD_BOOK,
    CARD_LINK,
    CARD_VIDEO,
    CARD_CHANNEL,
    CARD_LIBRARY,
    CARD_DOCUMENT,
    CARD_POSITION,
    CARD_TEXT,
    CARD_COURSE,
    CARD_RESEARCH,
    CARD_PLEDGE,
    CARD_MOBILIZE,
    CARD_MOBILIZATION,
    CARD_EVENT,
    CARD_BLOG,
    CARD_POSTBOX,
    CARD_VOTE_GALLERY,
    CARD_LINK_TILE,
    CARD_HEADER,
    CARD_MAP,
    CARD_CUSTOM_FIELDS_SUMMARY,
    CARD_VOLUNTEER_MOBILIZATION,
    CARD_GROUP,
    CARD_DYNAMIC,
    CARD_META_CHANNEL,
    CARD_SHOPPING_CART,
    CARD_CHAPTER,
    CARD_SURVEY,
    CARD_STORY,
    CARD_CREATION,
    CARD_RAINBOW_ROAD,
    CARD_DIALOG,
} from '@core/utilities/constants/card_types.js';

import _ from 'lodash';

/**
 * Get Post Variable Helper Method
 * @param {object} obj post variables object
 * @param {string} obj.type Type of attachment
 * @param {string} obj.body Body of post
 * @param {string} obj.title Title of Post
 * @param {{ data: {
 *     title: string?,
 *     description: string?,
 *     link: string?,
 *     linkTitle: string?,
 *     url: string?,
 *     image: string?,
 *     filename: string?,
 *     thumb: string?,
 *     authorID: string?,
 *     content: string?,
 *     aliases: Array<{ url: string, type: string, title: string?, color: string? }>?,
 *     mediaIcon: string?,
 *     meta: Array<{ name: string, value: string, values: string[] }>?,
 * } }} obj.content attached content object
 * @param {boolean} obj.displaySocialHeaders show social headers or not
 * @param {string} obj.parentURL URL of feed to post into
 * @returns {{
 *     parentUrl: string,
 *     body: string,
 *     type: string,
 *     link: string,
 *     mediaThumb: string,
 *     displaySocialHeaders: boolean,
 *     title: string?,
 *     linkTitle: string?,
 *     description: string?,
 *     authorID: string?,
 *     aliases: Array<{ url: string, type: string, title: string?, color: string? }>?,
 *     mediaIcon: string?,
 *     meta: Array<{ name: string, value: string, values: string[] }>?,
 * }}
 */
export const getPostVariables = ({
    type,
    body,
    title,
    content,
    displaySocialHeaders,
    parentURL,
}) => {
    switch (type) {
        case ATTACH_STORY_CARD:
            const aliases = content.data.map(img => {
                return {
                    thumb: '',
                    type: 'alias_image',
                    url: img.url,
                };
            });

            return {
                parentUrl: parentURL,
                body,
                type: CARD_STORY,
                title,
                link: '',
                aliases,
                mediaThumb: content.data[0].url,
                displaySocialHeaders,
            };

        case ATTACH_IMAGE:
            if (content.data.length === 1) {
                const mediaUrl = content.data[0].url;

                return {
                    parentUrl: parentURL,
                    body,
                    type: CARD_IMAGE,
                    title,
                    link: '',
                    mediaThumb: mediaUrl,
                    displaySocialHeaders,
                };
            }

            if (content.data.length > 1) {
                const aliases = content.data.map(img => {
                    return {
                        thumb: '',
                        type: 'alias_image',
                        url: img.url,
                    };
                });

                return {
                    parentUrl: parentURL,
                    body,
                    type: CARD_IMAGE,
                    title,
                    link: '',
                    aliases,
                    mediaThumb: content.data[0].url,
                    displaySocialHeaders,
                };
            }
        case ATTACH_IMAGE_SQUARE:
            if (content.data.length === 1) {
                const mediaUrl = content.data[0].url;

                return {
                    parentUrl: parentURL,
                    body,
                    type: CARD_IMAGE_SQUARE,
                    title,
                    link: '',
                    mediaThumb: mediaUrl,
                    displaySocialHeaders,
                };
            }

            if (content.data.length > 1) {
                const aliases = content.data.map(img => {
                    return {
                        thumb: '',
                        type: 'alias_square_image',
                        url: img.url,
                    };
                });

                return {
                    parentUrl: parentURL,
                    body,
                    type: CARD_IMAGE_SQUARE,
                    title,
                    link: '',
                    aliases,
                    mediaThumb: content.data[0].url,
                    displaySocialHeaders,
                };
            }

        case ATTACH_LINK:
            const linkData = content.data;
            return {
                parentUrl: parentURL,
                body,
                type: CARD_LINK,
                link: linkData.url,
                openInModal: linkData?.openInModal,
                title,
                linkTitle: linkData.title,
                description: linkData.description,
                mediaThumb: linkData.image,
                displaySocialHeaders,

                // TODO: Capture this info too.
                /* date: content.date,
        publisher: content.publisher,
        author: content.author */
            };
        case ATTACH_VIDEO:
            const videoData = content.data;
            return {
                parentUrl: parentURL,
                body,
                type: CARD_VIDEO,
                link: videoData.url,
                title,
                linkTitle: videoData.title,
                description: videoData.description,
                mediaThumb: videoData.image,
                displaySocialHeaders,

                // TODO: Capture this info too.
                /* date: content.date,
        publisher: content.publisher,
        author: content.author */
            };

        case ATTACH_DOCUMENT:
            const documentData = content.data;
            return {
                parentUrl: parentURL,
                body,
                title,
                type: CARD_DOCUMENT,
                link: documentData.url,
                mediaThumb: documentData.mediaThumb,
                displaySocialHeaders,
                linkTitle: documentData.title,
            };

        case ATTACH_COURSE:
            const courseData = content.data;
            return {
                parentUrl: parentURL,
                body,
                type: CARD_COURSE,
                displaySocialHeaders,
                ...courseData,
            };

        case ATTACH_RESEARCH:
            const researchData = content.data;
            return {
                parentUrl: parentURL,
                body,
                type: CARD_RESEARCH,
                displaySocialHeaders,
                ...researchData,
            };

        case ATTACH_MOBILIZE:
            const mobilizeData = content.data;
            return {
                parentUrl: parentURL,
                body,
                type: CARD_MOBILIZE,
                displaySocialHeaders,
                ...mobilizeData,
            };

        case ATTACH_PLEDGE:
            const pledgeData = content.data;
            return {
                parentUrl: parentURL,
                body,
                type: CARD_PLEDGE,
                displaySocialHeaders,
                ...pledgeData,
            };

        case ATTACH_MOBILIZATION:
            return {
                parentUrl: parentURL,
                body,
                type: CARD_MOBILIZATION,
                displaySocialHeaders,
            };

        case ATTACH_POSTBOX:
            const postboxData = content.data;
            return {
                parentUrl: parentURL,
                body,
                type: CARD_POSTBOX,
                displaySocialHeaders,
                ...postboxData,
            };

        case ATTACH_META_CHANNEL:
            const metaChannelData = content.data;
            return {
                parentUrl: parentURL,
                body,
                type: CARD_META_CHANNEL,
                displaySocialHeaders,
                ...metaChannelData,
            };

        case ATTACH_VOTE_GALLERY:
            const votegalleryData = content.data;
            // Remove bgImage and cardPrompt fields from alias items
            const cleanAliases = votegalleryData.aliases.map(
                ({ bgImage, cardPrompt, ...alias }) => alias
            );
            const voteData = {
                meta: votegalleryData.meta,
                aliases: cleanAliases,
                title: votegalleryData.title,
            };
            return {
                parentUrl: parentURL,
                body,
                type: CARD_VOTE_GALLERY,
                displaySocialHeaders,
                ...voteData,
            };

        case ATTACH_LINK_TILE:
            const linktileData = content.data;
            // Remove item from payload, since this is a standin for the actual alias item
            // and is used only for previews
            const _aliases = linktileData.aliases.map(({ item, ...alias }) => alias);
            const payload = {
                title: linktileData.title,
                meta: linktileData.meta,
                aliases: _aliases,
                displayInNewsfeed: linktileData.displayInNewsfeed,
                defaultViewType: linktileData.defaultViewType,
            };
            return {
                parentUrl: parentURL,
                body,
                type: CARD_LINK_TILE,
                displaySocialHeaders,
                ...payload,
            };

        case ATTACH_HEADER:
            const headerData = content.data;
            return {
                parentUrl: parentURL,
                body,
                type: CARD_HEADER,
                displaySocialHeaders,
                ...headerData,
            };

        case ATTACH_MAP:
            const mapData = content.data;
            return {
                parentUrl: parentURL,
                body,
                type: CARD_MAP,
                displaySocialHeaders,
                ...mapData,
            };

        case ATTACH_EVENT:
            return {
                parentUrl: parentURL,
                body,
                type: CARD_EVENT,
                displaySocialHeaders,
            };

        case ATTACH_BLOG:
            return {
                parentUrl: parentURL,
                title: content?.data?.title,
                mediaThumb: content?.data?.thumb,
                authorID: content?.data?.authorID,
                body: content?.data?.content,
                type: CARD_BLOG,
                displaySocialHeaders,
            };

        case ATTACH_CUSTOM_FIELDS_SUMMARY:
            return {
                parentUrl: parentURL,
                body,
                type: CARD_CUSTOM_FIELDS_SUMMARY,
                displaySocialHeaders,
            };

        case ATTACH_DYNAMIC_CARD:
            return {
                parentUrl: parentURL,
                type: CARD_DYNAMIC,
                body: '',
                subtype: content?.data?.subtype,
            };

        default:
            return {
                parentUrl: parentURL,
                body,
                title,
                type: CARD_TEXT,
                displaySocialHeaders,
            };
    }
};

// If the allowedTypes is of size one, and includes one of the predetermined types here, the modal will automatically open.
export const AUTO_OPEN_TYPES = [
    {
        type: CARD_PLEDGE,
        translation_key: 'common:global.nouns.campaign',
    },
    {
        type: CARD_BLOG,
    },
];

export const cardToAttachType = {
    [CARD_STORY]: ATTACH_STORY_CARD,
    [CARD_IMAGE]: ATTACH_IMAGE,
    [CARD_LINK]: ATTACH_LINK,
    [CARD_DOCUMENT]: ATTACH_DOCUMENT,
    [CARD_AUDIO]: ATTACH_AUDIO,
    [CARD_VIDEO]: ATTACH_VIDEO,
};

export const ATTACH_ICON_TYPES = {
    [CARD_IMAGE]: ATTACH_IMAGE,
    [CARD_DOCUMENT]: ATTACH_DOCUMENT,
    [CARD_AUDIO]: ATTACH_AUDIO,
};

export const getAutoOpenType = allowedTypes => {
    return allowedTypes?.length === 1 && _.find(AUTO_OPEN_TYPES, { type: allowedTypes[0] });
};

// Do not display in newsfeed
export const NEWSFEED_OMIT_TYPES = [
    CARD_CHANNEL,
    CARD_DYNAMIC,
    CARD_LIBRARY,
    CARD_SHELF,
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_POSTBOX,
    CARD_LINK_TILE,
    CARD_VOTE_GALLERY,
    CARD_HEADER,
    CARD_MAP,
    CARD_PLEDGE,
    CARD_STORY,
    CARD_RAINBOW_ROAD,
    CARD_SURVEY,
    CARD_CREATION,
];

export const TYPES_TO_ICON_CLASS = {
    [CARD_DOCUMENT]: 'post-document',
    [CARD_VIDEO]: 'video',
    [CARD_LINK]: 'post-link',
    [CARD_BLOG]: 'blog',
    [CARD_EVENT]: 'event',
    [CARD_LINK_TILE]: 'link-tile',
    [CARD_VOTE_GALLERY]: 'vote-gallery',
    [CARD_POSTBOX]: 'post-box',
    [CARD_PLEDGE]: 'campaign',
    [CARD_MOBILIZE]: 'job-mobilization',
    [CARD_MOBILIZATION]: 'job-mobilization',
    [CARD_MAP]: 'map',
    [CARD_AUDIO]: 'audio',
    [CARD_POSITION]: 'position',
    [CARD_HEADER]: 'header',
    [CARD_RESEARCH]: 'research',
    [CARD_IMAGE_SQUARE]: 'square-photo',
    [CARD_VOLUNTEER_MOBILIZATION]: 'volunteer-mobilization',
    [CARD_CUSTOM_FIELDS_SUMMARY]: 'custom',
    [CARD_SHELF]: 'shelf',
    [CARD_BOOK]: 'book',
    [CARD_COURSE]: 'shelf',
    [CARD_GROUP]: 'group',
    [CARD_META_CHANNEL]: 'meta-channel-icon',
    [CARD_SHOPPING_CART]: 'shopping-cart-icon',
    [CARD_DYNAMIC]: 'dynamic-card-icon',
    [CARD_SURVEY]: 'survey-card-icon',
    [CARD_STORY]: 'story',
    [CARD_DIALOG]: 'post-box',
};
