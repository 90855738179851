import React, { FormEventHandler } from 'react';

import { useUpdateCardMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';

import useModal from '@components/modals/hooks/useModal';
import useSurveyFormState from '@components/content/newpost/forms/Survey/hooks/useSurveyFormState';

import ModalLoading from '@components/modals/ModalLoading';
import SurveyForm from '@components/content/newpost/forms/Survey/SurveyForm';

import { ContentCard } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

type UpdateSurveyFormProps = {
    hasAdminRole?: boolean;
    card: Partial<ContentCard>;
};

const UpdateSurveyForm: React.FC<UpdateSurveyFormProps> = ({ hasAdminRole, card }) => {
    const [state, setState] = useSurveyFormState(card);

    const { newModal, closeAllModals } = useModal();

    const [updateCard] = useUpdateCardMutation();

    const onSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault();

        const {
            main: { title, description, thumbnail, coverImage, questions },
            settings: { rankOrder, displayInNewsfeed },
        } = state;

        const updatePayload = {
            cardID: card?._id,
            title,
            description,
            mediaIcon: thumbnail,
            mediaThumb: coverImage,
            // If number is removed from rankOrder, set it to null
            rankOrder: rankOrder === false ? null : rankOrder,
            displayInNewsfeed,
            surveyQuestions: questions.map(question => ({
                ...question,
                image: question.image.image,
            })),
        };

        // Get inherited setting, in this case assumes it will be the chapter for a 'normal' card
        const inheritedSettings = card?.reference?.chapterSource?.library_settings;

        if (inheritedSettings) {
            try {
                Object.entries(inheritedSettings).forEach(([key, value]) => {
                    if (key in updatePayload) {
                        // TS is having trouble inferring that key is a valid key for updatePayload,
                        // so this explicitly sets that to make the compiler happy
                        const typeSafeKey = key as keyof typeof updatePayload;

                        // This removes the property from the update payload if it matches the inherited settings
                        if (updatePayload[typeSafeKey] && value === updatePayload[typeSafeKey]) {
                            delete updatePayload[typeSafeKey];
                        }
                    }
                });
            } catch (error) {
                logger.error('error updating card', e);
            }
        }

        updateCard({ variables: updatePayload }).then(closeAllModals);

        newModal(<ModalLoading message="Updating Survey" />, { widen: true, addShadow: true });
    };

    return (
        <SurveyForm
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            hasAdminRole={hasAdminRole}
            title="Edit Survey"
            buttonText="Update"
        />
    );
};

export default UpdateSurveyForm;
