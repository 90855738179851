import React from 'react';

type QuestionMarkProps = {
    className?: string;
};

const QuestionMark: React.FC<QuestionMarkProps> = ({ className = '' }) => (
    <svg
        width="20"
        height="30"
        viewBox="0 0 20 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M9.96074 0.419922C12.7341 0.419922 14.9607 1.17992 16.6407 2.69992C18.3474 4.19326 19.2007 6.25992 19.2007 8.89992C19.2007 11.5133 18.3607 13.5133 16.6807 14.8999C15.0007 16.2599 12.7741 16.9399 10.0007 16.9399L9.84075 20.0199H4.92075L4.72075 13.2199H6.52074C8.84074 13.2199 10.6274 12.9133 11.8807 12.2999C13.1341 11.6866 13.7607 10.5666 13.7607 8.93992C13.7607 7.76659 13.4141 6.83326 12.7207 6.13992C12.0541 5.44659 11.1341 5.09992 9.96074 5.09992C8.73408 5.09992 7.77408 5.43326 7.08075 6.09992C6.41408 6.76659 6.08075 7.68659 6.08075 8.85992H0.800745C0.774078 7.23326 1.12075 5.77992 1.84075 4.49992C2.56075 3.21992 3.61408 2.21992 5.00074 1.49992C6.41408 0.779922 8.06741 0.419922 9.96074 0.419922ZM7.36075 29.5799C6.34741 29.5799 5.50741 29.2733 4.84075 28.6599C4.20075 28.0199 3.88074 27.2333 3.88074 26.2999C3.88074 25.3666 4.20075 24.5933 4.84075 23.9799C5.50741 23.3399 6.34741 23.0199 7.36075 23.0199C8.34741 23.0199 9.16075 23.3399 9.80075 23.9799C10.4407 24.5933 10.7607 25.3666 10.7607 26.2999C10.7607 27.2333 10.4407 28.0199 9.80075 28.6599C9.16075 29.2733 8.34741 29.5799 7.36075 29.5799Z"
            fill="currentColor"
        />
    </svg>
);

export default QuestionMark;
