import React, { useEffect, useRef, FormEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Updater } from 'use-immer';

import useHorizontalPages from '/imports/wl-web/ui/components/generic/hooks/useHorizontalPages';
import useModal from '/imports/wl-web/ui/components/modals/hooks/useModal';
import useCancelableTimer from '/imports/wl-web/ui/components/generic/hooks/useCancelableTimer';

import CreationFormSettings from '/imports/wl-web/ui/components/content/newpost/forms/Creation/CreationFormSettings';
import CreationFormMain from '/imports/wl-web/ui/components/content/newpost/forms/Creation/CreationFormMain';

import { curriedStateSlice } from '/imports/wl-web/utilities/helpers/state/state.helpers';

import { CreationState } from '/imports/wl-core/types/Creations';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

type CreationFormProps = {
    state: CreationState;
    setState: Updater<CreationState>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    hasAdminRole?: boolean;
    chapterTitle?: string;
    title?: string;
    buttonText?: string;
};

const CreationForm: React.FC<CreationFormProps> = ({
    state,
    setState,
    onSubmit,
    hasAdminRole,
    chapterTitle,
    title = 'Create Creation',
    buttonText = 'Create',
}) => {
    const { t } = useTranslation();

    const updateSlice = curriedStateSlice(setState);

    const { closeModal } = useModal();

    const refs = {
        settings: useRef<HTMLElement>(null),
        main: useRef<HTMLFieldSetElement>(null),
    };

    const { on, scrollTo } = useHorizontalPages({ refs });

    const timer1 = useCancelableTimer();

    // show main when first opening form
    useEffect(() => {
        timer1.start(() => scrollTo.main(false), 100);
    }, []);

    const showBackButton = !on.main;

    return (
        <form onSubmit={onSubmit} className="create-course-form">
            <header>
                <section>
                    <button
                        className={showBackButton ? '' : 'hidden'}
                        onClick={() => {
                            scrollTo.main();
                        }}
                        type="button"
                        aria-label="Back"
                    />
                    <h1>{title}</h1>
                </section>
                <button type="button" onClick={closeModal}>
                    {t(`common:${localNamespace}.cancel`)}
                </button>
            </header>

            <article>
                <CreationFormSettings
                    ref={refs.settings}
                    hasAdminRole={hasAdminRole}
                    state={state.settings}
                    setState={updateSlice('settings')}
                />

                <CreationFormMain
                    ref={refs.main}
                    state={state.main}
                    setState={updateSlice('main')}
                />
            </article>

            <footer>
                <section>
                    {chapterTitle && (
                        <span>{`${t('common:global.verbs.posting_into')} ${chapterTitle}`}</span>
                    )}
                </section>
                <section>
                    <button
                        type="button"
                        onClick={() => {
                            if (on.settings) scrollTo.main();
                            else scrollTo.settings();
                        }}
                        className="toggle-settings"
                        aria-label="Settings"
                    />

                    <button type="submit">{buttonText}</button>
                </section>
            </footer>
        </form>
    );
};

export default CreationForm;
