import { init } from 'filestack-js';
import { config } from '@core/config/getConfig';

const fileStackConfig = config.public.filestack;
const filestack = init(fileStackConfig.api_key, {
    security: null,
    cname: null,
    sessionCache: false,
});

/**
 * @typedef {Object} PickerFileMetadata
 * @property {string?} container
 * @property {{
 *     cropArea: { position: [number, number], size: [number, number] },
 *     originalImageSize: [null, null],
 * } | null} cropped
 * @property {string} filename
 * @property {string} handle
 * @property {string?} key
 * @property {string} mimetype
 * @property {Object | File | null} originalFile
 * @property {string} originalPath
 * @property {{ direction: 'CCW' | 'CW', value: number } | null} rotated
 * @property {number} size
 * @property {string} source
 * @property {string?} status
 * @property {string} uploadId
 * @property {string} url
 */

/**
 * @typedef {Object} PickerOptions
 * @property {string | string[] | null} accept
 * @property {boolean?} allowManualRety
 * @property {boolean? | { keepICCandAPP: boolean?, keepOrientation: boolean? }} cleanupImageExif
 * @property {number?} concurrency
 * @property {string? | Node?} container
 * @property {{
 *     [key: string]: { bottom: string[] | null, top: string[] | null }
 * } | null} customAuthText
 * @property {string?} customSourceContainer
 * @property {string?} customSourceName
 * @property {string?} customSourcePath
 * @property {Object} customText
 * @property {boolean?} disableStorageKey
 * @property {boolean?} disableThumbnails
 * @property {boolean?} disableTransformer
 * @property {'dropPane' | 'inline' | 'overlay' | null} displayMode
 * @property {{
 *     cropFiles: boolean?,
 *     customText: string?,
 *     disableClick: boolean?,
 *     onClick: (evt: any) => void,
 *     onDragEnter: (evt: DragEvent) => void,
 *     onDragLeave: () => void,
 *     onDragOver: (evt: DragEvent) => void,
 *     onDrop: (evt: DragEvent) => void,
 *     onError: (files: PickerFileMetadata[]) => void,
 *     onProgress: (percent: number) => void,
 *     onSuccess: (files: PickerFileMetadata[]) => void,
 *     overlay: boolean?,
 *     showIcon: boolean?,
 *     showProgress: boolean?,
 * } | null} dropPane
 * @property {number?} errorsTimeout,
 * @property {boolean?} exposeOriginalFile,
 * @property {string[] | null} fromSources,
 * @property {boolean?} globalDropZone,
 * @property {boolean?} hideModalWhenUploading,
 * @property {[number, number] | null} imageDim,
 * @property {[number, number] | null} imageMax
 * @property {[number, number] | null} imageMin
 * @property {string?} lang
 * @property {number?} maxFiles
 * @property {number?} maxSize
 * @property {number?} minFiles
 * @property {[number, number] | null} modalSize
 * @property {(
 *     files: { filesUploaded: PickerFileMetadata[], filesFailed: PickerFileMetadata[] },
 * ) => void} onCancelled
 * @property {() => void} onClose
 * @property {(file: PickerFileMetadata) => void | Promise<any>} onFileCropped
 * @property {(file: PickerFileMetadata) => void | Promise<any>} onFileSelected
 * @property {(file: PickerFileMetadata, error: Error) => void | Promise<any>} onFileUploadFailed
 * @property {(file: PickerFileMetadata) => void | Promise<any>} onFileUploadFinished
 * @property {(
 *     file: PickerFileMetadata,
 *     event: { totalBytes: number, totalPercent: number },
 * ) => void | Promise<any>} onFileUploadProgress
 * @property {(file: PickerFileMetadata) => void | Promise<any>} onFileUploadStarted
 * @property {(
 *     handle: {
 *         cancel: () => Promise<void>,
 *         close: () => Promise<void>,
 *         crop: (files: any[]) => Promise<void>,
 *         open: () => Promise<void>,
 *     }
 * ) => void} onOpen
 * @property {(
 *     files: { filesUploaded: PickerFileMetadata[], filesFailed: PickerFileMetadata[] },
 * )} onUploadDone
 * @property {(
 *     files: { filesUploaded: PickerFileMetadata[], filesFailed: PickerFileMetadata[] },
 * )} onUploadStarted
 * @property {string?} rootId
 * @property {boolean?} startUploadingWhenMaxFilesReached
 * @property {{
 *     access: 'public' | 'private' | null,
 *     container: string?,
 *     location: 's3' | 'gcs' | 'azure' | 'rackspace' | 'dropbox' | null,
 *     path: string?,
 *     region: string?,
 *     workflows: string[] | Array<{ id: string }> | null,
 * }} storeTo
 * @property {string?} supportEmail
 * @property {{
 *     circle: boolean?,
 *     crop: boolean | { aspectRatio: number?, force: boolean? } | null,
 *     force: boolean?,
 *     rotate: boolean?,
 * }} transformations
 * @property {{
 *     concurrency: number?,
 *     disableIntegrityCheck: boolean?,
 *     intelligent: boolean | 'fallback' | null,
 *     intelligentChunkSize: number?,
 *     onProgress: (evt: { totalBytes: number?, totalPercent: number? }) => void,
 *     onRetry: () => void,
 *     partSize: number?,
 *     progressInterval: number?,
 *     retry: number?,
 *     retryFactor: number?,
 *     retryMaxTime: number?,
 *     tags: { [key: string]: string },
 *     timeout: number?,
 * }} uploadConfig
 * @property {boolean?} uploadInBackground
 * @property {boolean?} useSentryBreadcrumbs
 * @property {string?} videoResolution
 * @property {'grid' | 'list' | null} viewType
 */

/**
 * React hook for opening up a filestack picker
 *
 * Use like so:
 *
 * const openPicker = useFilestackPicker();
 *
 * return <button type="button" onClick={() => {
 *     openPicker({
 *         accept: 'image/*',
 *         lang: i18n.language,
 *         onUploadDone: ({ filesUploaded }) => logger.info(filesUploaded[0]),
 *     })
 * }}>Open Picker!</button>
 *
 * @returns {(pickerOptions: PickerOptions) => void}
 */
const useFilestackPicker = () => {
    const openPicker = pickerOptions => {
        if (pickerOptions?.lang) {
            delete pickerOptions.lang;
        }

        filestack.picker(pickerOptions).open();
    };

    return openPicker;
};

export default useFilestackPicker;
