import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ActionArea from '@web/ui/components/chapter/ActionArea';
import ChapterInfoBlock from '@web/ui/components/chapter/ChapterInfoBlock';
import ChapterContent from '@web/ui/components/chapter/ChapterContent.js';
import SelectTagsModal from '@web/ui/components/chapter/SelectTagsModal';
import FilterTagsList from '@web/ui/components/chapter/FilterTagsList';

import useOnScreen from '@web/ui/components/generic/hooks/useOnScreen';
import useBreadcrumbs from '@web/ui/components/generic/hooks/useBreadcrumbs';
import useChapterContext from '@web/ui/components/chapter/hooks/useChapterContext';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary.js';
import DropdownController from '@web/ui/components/generic/dropdownmenu/DropdownController';

import withMinimalPullDownToRefresh from '@web/ui/components/generic/withMinimalPullDownToRefresh';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

import IntroContent from '@web/ui/components/generic/IntroContent';

import {
    FILTER_LEADERBOARD,
    FILTER_TOP,
    FILTER_VOTE,
    getFilterText,
} from '@core/utilities/constants/library_settings';

const localNamespace = 'imports.wlWeb.ui.components.chapter.chapterBody';

const ChapterBody = React.memo(function ChapterBody({
    chapter,
    book,
    shelf,
    showTopSentinel,
    showBottomSentinel,
    addSpacers,
    hideDelineator,
    displayActionArea,
    displayActionAreaPlaceholder,
    scrollToTop,
    onActionAreaIsVisible,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    renderPullDownToRefresh,
    verticalScrolling,
}) {
    const { t } = useTranslation();

    const { setCurrentChapter, addScrollRef, showActionArea, hideActionArea } = useChapterContext();

    let defaultSortType = chapter?.library_settings?.defaultFilter ?? FILTER_TOP;

    if (chapter?.library_settings?.filterTypes)
        defaultSortType = chapter.library_settings.filterTypes.includes(defaultSortType)
            ? defaultSortType
            : chapter.library_settings.filterTypes[0];

    const [sortType, setSortType] = useState(defaultSortType);
    const [userChoseFilter, setUserChoseFilter] = useState(false);
    const [activeTagFilters, setActiveTagFilters] = useState([]);

    const chapterScrollToRef = useRef();
    const chapterHeaderDisplayRef = useRef();
    const actionAreaRef = useRef();
    const showGreyBar = chapter?.library_settings?.allowPostbox || chapter?.description;
    const { chapterId } = useParams();

    const { topSentinel, bottomSentinel } = useBreadcrumbs(() => setCurrentChapter(chapter?.url));
    useOnScreen({ ref: actionAreaRef, onChange: onActionAreaIsVisible });

    useEffect(() => {
        if (hideDelineator) addScrollRef(chapter?.url, { scrollIntoView: scrollToTop });
        else addScrollRef(chapter?.url, chapterScrollToRef.current);
    }, [chapterScrollToRef.current]);

    useEffect(() => {
        if (chapter?.url === chapterId) {
            if (hideDelineator) {
                scrollToTop?.();
                showActionArea();
            } else {
                chapterScrollToRef.current?.scrollIntoView();
            }
        }
    }, [chapter?.url, chapterId]);

    useEffect(() => {
        setUserChoseFilter(true);
    }, [sortType]);

    useEffect(() => {
        if (
            // renderPullDownToRefresh here is used
            // as a implicit way of determining if this is 'the first chapter'
            // this is only valid if we assume the first chapter is the top one
            renderPullDownToRefresh &&
            chapter &&
            chapterHeaderDisplayRef?.current?.style &&
            actionAreaRef?.current?.offsetHeight > 0
        ) {
            chapterHeaderDisplayRef.current.style.height = `${actionAreaRef?.current?.offsetHeight + 0
                }px`;
        }
    }, [chapter, chapterHeaderDisplayRef, actionAreaRef]);

    const isSortType = type => sortType === type;

    const handleFilterTag = tag => setActiveTagFilters([...activeTagFilters, tag]);

    const handleRemoveItem = tag =>
        setActiveTagFilters(activeTagFilters.filter(activeTag => activeTag._id !== tag._id));

    const gotoDefaultChapterSort = () =>
        setSortType(defaultSortType === FILTER_VOTE ? FILTER_TOP : defaultSortType);

    const noContentToVoteOrSkip = () => {
        if (defaultSortType === FILTER_VOTE && !userChoseFilter) setSortType(FILTER_LEADERBOARD);
    };

    const chapterSettings = { ...chapter?.library_settings, showTitle: chapter?.showTitle } ?? {};

    const voteOrSkipBackgroundImage =
        chapter?.media?.icon ?? getRandomDefaultCoverImage(chapter?.url ?? chapter?.prompt);

    const filterActive = type => {
        if (!chapterSettings?.filterTypes) return true;

        return chapterSettings.filterTypes.includes(type);
    };

    const tagFilters =
        chapterSettings?.tagAggregation?.length >= 2 ? chapterSettings.tagAggregation : [];

    if (!filterActive(sortType) && chapter?.library_settings.filterTypes) {
        const newDefaultSortType =
            chapter.library_settings.defaultFilter || chapter.library_settings.filterTypes[0];

        if (sortType !== newDefaultSortType) setSortType(newDefaultSortType);
    }

    const filterButtons = chapterSettings?.filterTypes?.map(filterType => (
        <button
            type="button"
            key={filterType}
            className={`we-filters-item ${isSortType(filterType) ? 'selected' : ''}`}
            onClick={() => setSortType(filterType)}
        >
            {getFilterText(t, filterType)}
        </button>
    ));

    const showTopFilterMenu = filterButtons?.length > 1 || tagFilters?.length > 0;

    return React.useMemo(
        () => (
            <article
                id={`chapter-${chapter?.url || 'default'}`}
                className="page-content-wrapper"
                dataid={chapter?.url}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                <div className="page-flex-wrapper">
                    {addSpacers && <div role="presentation" className="flex-spacer" />}
                    {showGreyBar && (
                        <div ref={chapterHeaderDisplayRef} className="grey-header-bar" />
                    )}
                    <div
                        className="flex-content chapter-flex-container shelfpage"
                        id={`chapter-flex-container-${chapter?.url}`}
                        style={{ overflowY: 'visible' }}
                    >
                        <div className="db-content-container" style={{ paddingBottom: 0 }}>
                            {!hideDelineator && (
                                <>
                                    <div role="presentation" className="chapter-line-delineator" />
                                    <ChapterInfoBlock
                                        chapter={chapter}
                                        book={book}
                                        shelf={shelf}
                                        className="chapter-scroll-delineator"
                                    />
                                    <div
                                        ref={chapterScrollToRef}
                                        role="presentation"
                                        className="chapter-scroll-ref"
                                    />
                                </>
                            )}
                            {!verticalScrolling && renderPullDownToRefresh?.()}
                            <ActionArea
                                className={renderPullDownToRefresh ? 'white-postbox no-shadow' : ''}
                                ref={actionAreaRef}
                                chapter={chapter}
                                book={book}
                                shelf={shelf}
                                isResource
                                isHidden={!displayActionArea && !displayActionAreaPlaceholder}
                                isPlaceholder={displayActionAreaPlaceholder}
                            />
                            {!!verticalScrolling && renderPullDownToRefresh?.()}
                            <section
                                className="db-section min-h-screen"
                                style={{ paddingBottom: 0 }}
                            >
                                {showTopSentinel && (
                                    <div
                                        role="presentation"
                                        ref={topSentinel}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            width: '100%',
                                            height: '100vh',
                                            pointerEvents: 'none',
                                        }}
                                    />
                                )}
                                {showTopFilterMenu && (
                                    <section className="chapter-top-menu">
                                        <div
                                            className="we-filters-container chapter-body"
                                            style={{
                                                paddingTop: '10px',
                                                marginTop: 0,
                                            }}
                                        >
                                            {filterButtons?.length > 1 && (
                                                <div className="we-filters-menu">
                                                    {filterButtons}
                                                </div>
                                            )}
                                        </div>
                                        <div className="chapter-top-filter-menu-right">
                                            {tagFilters?.length > 0 && (
                                                <DropdownController
                                                    buttonClass="filter-tag"
                                                    disableMobileMenu
                                                    menuBlockOverride={
                                                        <SelectTagsModal
                                                            activeTagFilters={activeTagFilters}
                                                            handleFilterTag={handleFilterTag}
                                                            filteredList={tagFilters}
                                                        />
                                                    }
                                                />
                                            )}
                                        </div>
                                    </section>
                                )}
                                {activeTagFilters?.length > 0 && (
                                    <section>
                                        <div className="we-tag-filter-list">
                                            <span className="we-tag-filter-label">FILTER BY </span>
                                            <FilterTagsList
                                                handleClick={handleRemoveItem}
                                                filterTagsList={activeTagFilters}
                                                activeFilter
                                            />
                                        </div>
                                    </section>
                                )}
                                <ErrorBoundary>
                                    {chapter ? (
                                        <ChapterContent
                                            tagFilters={activeTagFilters}
                                            sortType={sortType}
                                            setSortType={setSortType}
                                            chapterSettings={chapterSettings}
                                            voteSettings={chapter?.vote_settings ?? {}}
                                            noContentToVoteOrSkip={noContentToVoteOrSkip}
                                            currentTerminalUrl={chapter?.url}
                                            gotoDefaultChapterSort={gotoDefaultChapterSort}
                                            backgroundImage={voteOrSkipBackgroundImage}
                                            chapterId={chapter?._id}
                                        />
                                    ) : (
                                        <IntroContent
                                            declaration={t(
                                                `common:${localNamespace}.route_missing_declaration`
                                            )}
                                            instruction={t(
                                                `common:${localNamespace}.chapter_missing_instruction`
                                            )}
                                            type="nocontent"
                                        />
                                    )}
                                </ErrorBoundary>
                                {showBottomSentinel && (
                                    <div
                                        role="presentation"
                                        ref={bottomSentinel}
                                        style={{
                                            position: 'absolute',
                                            bottom: '-20vh',
                                            width: '100%',
                                            height: '100vh',
                                            pointerEvents: 'none',
                                        }}
                                    />
                                )}
                            </section>
                        </div>
                    </div>
                    {addSpacers && <div role="presentation" className="flex-spacer-right" />}
                </div>
            </article>
        ),
        [
            chapter,
            sortType,
            userChoseFilter,
            activeTagFilters,
            displayActionArea,
            onTouchStart,
            onTouchMove,
            onTouchEnd,
        ]
    );
});

export const ChapterBodyWithRefresh = withMinimalPullDownToRefresh(ChapterBody, undefined, true);

export default ChapterBody;
