import React from 'react';
import { Link } from 'react-router-dom';

import { awsMetricPublisher } from '@welibraryos/metrics';

import Picture from '@web/ui/components/generic/Picture';
import EyeSlash from '@dsc/svgs/EyeSlash';

import useNavigation from '@web/ui/components/navigation/useNavigation';

import ICONS from '@core/utilities/constants/icons';

type QuickLinkProps = {
    /** Link to redirect to */
    to: string;

    /** URL of the displayed image */
    picture: string;

    /** img element alt text */
    alt?: string;

    /** Display the home icon in the bottom right of the image */
    home?: boolean;

    /**
     * Setting this to false will cause only the image to be a link, rather than the entire element.
     * This is useful for including separate links in the description.
     */
    wrapChildrenInLink?: boolean;

    /** if this is a quick link for an unpublished event. An eye slash icon will be shown if true */
    isUnpublished?: boolean;
};

const QuickLink: React.FC<QuickLinkProps> = ({
    to,
    picture,
    alt = '',
    home = false,
    wrapChildrenInLink = true,
    isUnpublished,
    children,
}) => {
    const { closeSlideMenu } = useNavigation();

    const handleOnClick = () => {
        closeSlideMenu();
        awsMetricPublisher.publishCount('side-menu-select-quick-link', 1);
    };

    if (wrapChildrenInLink) {
        return (
            <Link to={to} onClick={handleOnClick} className="side-menu-navigator-quick-link">
                <section className="side-menu-navigator-quick-link-icon relative">
                    <Picture url={picture} alt={alt} resolutions={[75, 150, 300]} disableLink />
                    {home && <img src={ICONS.pinWhite} alt="Pin Icon" className="corner-icon" />}
                    {isUnpublished && (
                        <div
                            className="absolute top-0 left-0 p-[3px] bg-[#333333] bg-opacity-70 rounded-tl-[13px] rounded-br-[10px]"
                            title="Unpublished Event"
                        >
                            <EyeSlash color="white" />
                        </div>
                    )}
                </section>
                {children}
            </Link>
        );
    }

    return (
        <section className="side-menu-navigator-quick-link">
            <Link to={to} onClick={handleOnClick} className="side-menu-navigator-quick-link-icon">
                <Picture url={picture} alt={alt} resolutions={[75, 150, 300]} disableLink />
                {home && <img src={ICONS.homeWhite} alt="Home Icon" className="corner-icon" />}
            </Link>
            {children}
        </section>
    );
};

export default QuickLink;
