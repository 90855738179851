import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { config } from '@config/getConfig';

const AppUrlListener: React.FC<any> = () => {
    const history = useHistory();
    const deepLinkDomains = config?.public?.deep_link_domains ?? [
        'https://wl-react.netlify.app',
        'https://app.welibrary.io',
        'https://staging.welibrary.io',
    ];
    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            // Example url: https://app.welibrary.io/g/123ABC => should route internally to /g/123ABC
            deepLinkDomains.forEach(deepLinkDomain => {
                if (event.url.includes(deepLinkDomain)) {
                    history.push(event.url.replace(deepLinkDomain, ''));
                }
            });
            // If no match, do nothing - let regular routing
        });
    }, []);

    return null;
};

export default AppUrlListener;