import React, { useState, useContext } from 'react';

import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import NotifyPanelHeader from '@web/ui/components/engagement/NotifyPanelHeader';
import NotifyPanelSubGroupList from '@web/ui/components/engagement/NotifyPanelSubGroupList';
import NotifyPanelFooter from '@web/ui/components/engagement/NotifyPanelFooter';

import ToastContext from '@web/ui/components/generic/context/ToastContext';
import useModal from '@web/ui/components/modals/hooks/useModal';
import { useGroupByIdQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { useNotifyGroupsOfTargetMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';

import { Collection, ContentCard, Group, PaginatedGroups } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

const CONFIRMATION_LENGTH = 19000;

// TODO combine this with ConnectedAccountSuccess
const NotifyPanel: React.FC<{
    parentGroupId: string;
    groupId: string;
    card: ContentCard;
    collectionType: Collection;
}> = ({ parentGroupId, groupId, card, collectionType }) => {
    const { showToast } = useContext(ToastContext);
    const { closeAllModals } = useModal();

    const [allToggled, setAllToggled] = useState<boolean>(false);
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

    const [notifyGroups, { loading, error }] = useNotifyGroupsOfTargetMutation();

    const cardGroupSourceId = card?.reference?.groupSource?._id;

    const { data } = useGroupByIdQuery({
        variables: { _id: cardGroupSourceId ?? parentGroupId },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !parentGroupId && !cardGroupSourceId,
    });

    const group: Group = data?.groupById;

    const subgroups: PaginatedGroups = group?.childGroups;

    const handleSelectingAllAdditionalMembers = (toggled: boolean) => {
        setAllToggled(toggled);

        const _selectedGroups: string[] = subgroups?.results.map(subGroup => subGroup?._id) || [];
        const parentGroupSelected = selectedGroups.includes(parentGroupId);

        if (allToggled) {
            if (parentGroupSelected) {
                setSelectedGroups([parentGroupId]);
            } else {
                setSelectedGroups([]);
            }
        } else if (!allToggled) {
            if (parentGroupSelected) {
                setSelectedGroups([..._selectedGroups, parentGroupId]);
            } else {
                setSelectedGroups([..._selectedGroups]);
            }
        }
    };

    const handleAddSubGroup = (id: string) => {
        if (!selectedGroups.includes(id)) {
            setSelectedGroups([...selectedGroups, id]);
        }
    };

    const handleRemoveSubGroup = (id: string) => {
        if (selectedGroups.includes(id)) {
            const _selectedGroups = selectedGroups.filter(subGroupId => subGroupId !== id);
            setSelectedGroups([..._selectedGroups]);
        }
    };

    const handleSubmit = () => {
        notifyGroups({
            variables: {
                input: {
                    groupIds: selectedGroups,
                    target: {
                        // target card?._id -> for content-card (Event Listings)
                        // target group?._id -> for groups (Event Groups)
                        id: card?._id ?? groupId,
                        collection: collectionType,
                    },
                },
            },
        })
            .then(() => {
                closeAllModals();
                showToast('Notification sent!', null, CONFIRMATION_LENGTH);
            })
            .catch(err => logger.error(err));
    };

    return (
        <ErrorBoundary>
            <LoadingLine isLoading={loading} error={error} />
            <section className="notify-panel">
                <NotifyPanelHeader
                    group={group}
                    subgroups={subgroups}
                    selectedGroups={selectedGroups}
                    handleAddGroup={handleAddSubGroup}
                    handleRemoveGroup={handleRemoveSubGroup}
                    allToggled={allToggled}
                    handleSelectingAllAdditionalMembers={handleSelectingAllAdditionalMembers}
                />

                {(subgroups?.results?.length ?? 0) > 0 && (
                    <NotifyPanelSubGroupList
                        subgroups={subgroups}
                        selectedGroups={selectedGroups}
                        handleAddSubGroup={handleAddSubGroup}
                        handleRemoveSubGroup={handleRemoveSubGroup}
                    />
                )}

                <NotifyPanelFooter handleSubmit={handleSubmit} />
            </section>
        </ErrorBoundary>
    );
};

export default NotifyPanel;
