export const getCredentialName = credentialSubject => {
    if (!credentialSubject) return;

    return credentialSubject?.['schema:hasCredential']?.name;
};

export const getCredentialImageUrl = credentialSubject => {
    if (!credentialSubject) return;

    return credentialSubject?.['schema:hasCredential']?.image;
};
