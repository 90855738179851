import React from 'react';
import { withTranslation } from 'react-i18next';

const localNamespace = 'imports.wlWeb.ui.components.generic.lightBox.prompts.confirmation';

class Confirmation extends React.Component {
    handleDelete = () => {
        const { activeQuery } = this.props;

        const refetchQueries = activeQuery ? [activeQuery.query] : [];

        this.props.updateCard({
            variables: {
                cardID: this.props.card._id,
                deleteCard: true,
            },
            update: cache => {
                if (cache?.evict) {
                    cache.evict({ id: cache.identify(this.props.card) });
                    cache.gc();
                }
            },
            refetchQueries,
        });
        this.props.handleModalClose();
    };

    handleModalClose = () => {
        this.props.handleModalClose();
    };

    render() {
        let type;
        const { t, card } = this.props;
        if (card.type === 'chapter') {
            type = 'chapter';
        } else if (card.type === 'shelf') {
            type = 'shelf';
        } else if (card.type === 'book') {
            type = 'book';
        } else {
            type = 'post';
        }

        return (
            <div className="lightbox-wrapper">
                <div className="portal-content-wrapper content-fill">
                    <div className="overlay-bg fixed" />
                    <div className="page-content-container fill-page">
                        <div
                            data-duration-in="300"
                            data-duration-out="100"
                            className="w-tabs fill-page"
                        >
                            <div className="w-tab-content">
                                <div
                                    data-w-tab="Image"
                                    className="overlay-container w-tab-pane w--tab-active"
                                >
                                    <div className="content-card-container">
                                        {/* Main Edit Section*/}
                                        <div className="legacy-lightbox-container">
                                            <div
                                                className="edit-screen-item post-edit-item"
                                                style={{ fontSize: '18px', lineHeight: '24px' }}
                                            >
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    {t(`common:${localNamespace}.confirm_deletion`)}{' '}
                                                    {type}?
                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                <button
                                                    style={{ margin: '10px', width: '80px' }}
                                                    onClick={this.handleModalClose}
                                                    className="lightbox-nav-link callout-link style2 w-inline-block"
                                                >
                                                    {t(`common:global.no`)}
                                                </button>
                                                <button
                                                    style={{ margin: '10px', width: '80px' }}
                                                    onClick={this.handleDelete}
                                                    className="lightbox-nav-link callout-link style2 w-inline-block"
                                                >
                                                    {t(`common:global.yes`)}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Confirmation);
