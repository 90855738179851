import React, { useRef, useState } from 'react';
import loadable from '@loadable/component';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { awsMetricPublisher } from '@welibraryos/metrics';
import { TYPE_POSTING } from '@components/sidebar/sidebar_types';
import { useCurrentUser } from '@stores/User';

import {
    FOLLOW_CARD,
    UPDATE_CARD,
    FLAG_CONTENT_V2,
} from '@shared/welibrary-graphql/content_card/mutations';
import {
    CONTENT_CARD_BOOK_FRAGMENT,
    CONTENT_CARD_CHAPTER_FRAGMENT,
    CONTENT_CARD_FRAGMENT,
    CONTENT_CARD_SHELF_FRAGMENT,
} from '@shared/welibrary-graphql/content_card/fragments';
import { UPDATE_USER_CONNECTION } from '@shared/welibrary-graphql/user/mutations';
import { USER_FRAGMENT } from '@shared/welibrary-graphql/user/fragments';

import useConfirmation from '@components/generic/hooks/useConfirmation';
import useModal from '@components/modals/hooks/useModal';
import usePrompt from '@components/generic/hooks/usePrompt';
import useSideDrawer from '@components/generic/hooks/useSideDrawer';
import useTimer from '@components/generic/hooks/useTimer';
import useEditEventGroupForm from '@components/group/hooks/useEditEventGroupForm';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import AlertToast from '@components/toast/AlertToast';
import EngagementController from '@components/card/modules/EngagementController';
import ReferenceCardHeader from '@components/card/ReferenceCardHeader';
import Modal from '@components/generic/lightbox/Modal';
import UpdateCard from '@components/card/UpdateCard';
import MoveItem from '@components/forms/MoveItem';
import TagsForm from '@components/tags/TagsForm';
import TagsBlock from '@components/tags/TagsBlock';
import EditTickets from '@components/content/newpost/forms/ShoppingCartProducts/EditTickets';

import { compareVersions, getMajorVersion } from '@core/utilities/versionHelpers';
import { getFeatureConstraintsForField } from '@core/utilities/constants/feature_constraints';
import { groupSellsProductsButCantAcceptPayments } from '@components/group/groupdashboard/groupDashboardHelpers';
import { capitalize } from '@helpers/string.helpers';

import { Collection } from '@shared/welibrary-graphql/types';
import { defaultAlertToastOptions } from '@core/types/Toast';

import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';

import {
    CARD_BLOG,
    CARD_CREATION,
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_COURSE,
    CARD_CUSTOM_FIELDS_SUMMARY,
    CARD_DOCUMENT,
    CARD_DYNAMIC,
    CARD_EVENT,
    CARD_GROUP,
    CARD_HEADER,
    CARD_IMAGE,
    CARD_IMAGE_FULL,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_LINK_TILE,
    CARD_MAP,
    CARD_META_CHANNEL,
    CARD_MOBILIZATION,
    CARD_MOBILIZE,
    CARD_PLEDGE,
    CARD_POSTBOX,
    CARD_SHELF,
    CARD_SURVEY,
    CARD_TEXT,
    CARD_VIDEO,
    CARD_VOLUNTEER_MOBILIZATION,
    CARD_VOTE_GALLERY,
    CARD_STORY,
    CARD_RAINBOW_ROAD,
    CARD_DIALOG,
} from '@core/utilities/constants/card_types';

import {
    ENGAGE_ATTEND,
    ENGAGE_COMMENT,
    ENGAGE_FOLLOW,
    ENGAGE_HEART,
    getArrayOfRenderPropsByEngagementType,
} from '@core/utilities/constants/engagement_types';

import {
    VOTING_LEADERBOARD,
    VOTING_NO_VOTE_LABEL,
    VOTING_NO_VOTE_TEXT,
    VOTING_NONE,
    VOTING_QUALITY,
    VOTING_RELEVANCE,
    VOTING_SENTIMENT,
} from '@core/utilities/constants/voting_types';

import { GROUP_ACTIONS } from '@core/utilities/constants/group';

import { CONNECTION_STATUS_IS_LOGGEDINUSER } from '@core/utilities/constants/user';
import { getContentCardLabel } from '~/wl-web/utilities/helpers/contentCard/contentCard.helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

const LoadableCardOptimizer = loadable(
    () => import('@components/optimizers/ContentCardOptimizer'),
    { resolveComponent: imported => imported.default }
);

const LoadableNotifyPanel = loadable(() => import('@components/engagement/NotifyPanel'), {
    resolveComponent: imported => imported.default,
});

const LoadableSurveyItem = loadable(
    () => import('@components/card/mediacard/bodyitems/SurveyItem'),
    { resolveComponent: imported => imported.default }
);

const LoadableCourseItem = loadable(
    () => import('@components/card/mediacard/bodyitems/CourseItem'),
    { resolveComponent: imported => imported.default }
);

const LoadableRainbowRoadItem = loadable(
    () => import('@components/card/mediacard/bodyitems/RainbowRoadItem'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreationItem = loadable(
    () => import('@components/card/mediacard/bodyitems/CreationItem'),
    { resolveComponent: imported => imported.default }
);

const LoadableDialogItem = loadable(
    () => import('@components/card/mediacard/bodyitems/DialogItem'),
    { resolveComponent: imported => imported.default }
);

const LoadableResourcesModule = loadable(() => import('@components/card/modules/ResourcesModule'), {
    resolveComponent: imported => imported.default,
});

const LoadableMediaCardSource = loadable(
    () => import('@components/card/mediacard/MediaCardSource'),
    { resolveComponent: imported => imported.default }
);

const LoadablePledgeCardContainer = loadable(
    () => import('@components/card/pledgecard/PledgeCardContainer'),
    { resolveComponent: imported => imported.default }
);

// const LoadableCreatePledgeForm = loadable(() =>
//     import('@components/content/pledge/CreatePledgeForm'),
//     { resolveComponent: imported => imported.default }
// );

const LoadableMobilizationCardContainer = loadable(
    () => import('@components/card/mobilizationcard/MobilizationCardContainer'),
    { resolveComponent: imported => imported.default }
);

const LoadableMetaChannelCard = loadable(
    () => import('@components/card/metachannel/MetaChannelCard'),
    { resolveComponent: imported => imported.default }
);

const LoadableDynamicCard = loadable(() => import('@components/card/dynamiccard/DynamicCard'), {
    resolveComponent: imported => imported.default,
});

const LoadableCustomFieldsSummaryItem = loadable(
    () => import('@components/card/mediacard/bodyitems/CustomFieldsSummaryItem'),
    { resolveComponent: imported => imported.default }
);

const LoadableSuggestChangeOverlay = loadable(
    () => import('@components/card/suggestcard/SuggestChangeOverlay'),
    { resolveComponent: imported => imported.default }
);

const LoadableErrorBoundary = loadable(() => import('@components/generic/errors/ErrorBoundary'), {
    resolveComponent: imported => imported.default,
});

const LoadableErrorItem = loadable(() => import('@components/card/mediacard/bodyitems/ErrorItem'), {
    resolveComponent: imported => imported.default,
});

const LoadablePostboxItem = loadable(
    () => import('@components/card/mediacard/bodyitems/PostboxItem'),
    { resolveComponent: imported => imported.default }
);

const LoadableMultiPromptPostbox = loadable(
    () => import('@components/content/newpost/MultiPromptPostbox'),
    { resolveComponent: imported => imported.default }
);

const LoadableTileItem = loadable(() => import('@components/card/mediacard/bodyitems/TileItem'), {
    resolveComponent: imported => imported.default,
});

const LoadableVoteOrSkipItem = loadable(
    () => import('@components/card/mediacard/bodyitems/VoteOrSkipItem'),
    { resolveComponent: imported => imported.default }
);

const LoadableHeaderItem = loadable(
    () => import('@components/card/mediacard/bodyitems/HeaderItem'),
    { resolveComponent: imported => imported.default }
);

const LoadableMapItem = loadable(() => import('@components/card/mediacard/bodyitems/MapItem'), {
    resolveComponent: imported => imported.default,
});

const LoadableBlogItem = loadable(() => import('@components/card/mediacard/bodyitems/BlogItem'), {
    resolveComponent: imported => imported.default,
});

const LoadableMediaCardBody = loadable(() => import('@components/card/mediacard/MediaCardBody'), {
    resolveComponent: imported => imported.default,
});

const LoadableVotingContainer = loadable(() => import('@components/voting/VotingContainer'), {
    resolveComponent: imported => imported.default,
});

const LoadableBookmarkIndicator = loadable(
    () => import('@components/card/visual/BookmarkIndicator'),
    { resolveComponent: imported => imported.default }
);

const LoadableContentCardMenu = loadable(() => import('@components/card/ContentCardMenu'), {
    resolveComponent: imported => imported.default,
});

const LoadableListViewContainer = loadable(() => import('@components/card/ListViewContainer'), {
    resolveComponent: imported => imported.default,
});

const LoadableModule = loadable(() => import('@components/card/modules/Module'), {
    resolveComponent: imported => imported.default,
});

const LoadableEditPledgeForm = loadable(() => import('@components/content/pledge/EditPledgeForm'), {
    resolveComponent: imported => imported.default,
});

const LoadableEventItem = loadable(() => import('@components/card/mediacard/bodyitems/EventItem'), {
    resolveComponent: imported => imported.default,
});
const LoadableGroupItem = loadable(() => import('@components/card/mediacard/bodyitems/GroupItem'), {
    resolveComponent: imported => imported.default,
});

const LoadableStoryCard = loadable(() => import('@components/card/storycard/StoryCard'), {
    resolveComponent: imported => imported.default,
});

const localNamespace = 'imports.wlWeb.ui.components.card.contentCard';
const votingNamespace = 'imports.wlWeb.ui.components.voting';

const RESOURCE_ALLOWED_TYPES = {
    [CARD_TEXT]: CARD_TEXT,
    [CARD_DOCUMENT]: CARD_DOCUMENT,
    [CARD_IMAGE]: CARD_IMAGE,
    [CARD_IMAGE_FULL]: CARD_IMAGE_FULL,
    [CARD_LINK]: CARD_LINK,
};

const CUSTOM_DROPDOWN_TYPES = {
    [CARD_DYNAMIC]: CARD_DYNAMIC,
};

const GROUP_SHOPPING = 'Group Shopping Cart';

const ContentCard = React.memo(function ContentCard({
    card,
    chapterSettings,
    voteSettings,
    isHome,
    isLeaderboard,
    rank,
    noDate,
    voteOrNextFunction,
    defaultOpenDrawer,
    expandComments,
    connectIsLoggedInUser,
    activeQuery,
    hideTopRightDropdown,
    topGreyBorder,
    extraClasses,
    voteOrSkipStyle,
    showReferenceHeader,
    transparentBackground,
    hideTags,
    voteBooth,
    viewType: overrideViewType,
    loading,
    hideResources,
    pinnable,
    currentTerminalURL,
    idx,
    idxTotal,
    referenceHeaderExtraClasses,
    externalLinkTypes,
    onLoad,
    className = '',
    maxWidth = 600,
    showBookmark = true,
    customFooter,
    parentCard, // e.g. channel/group for updating pinned posts
    contentCardExtraClasses = '',
    noRecursiveMetaCard = false,
    setCurrentImage: _setCurrentImage = () => {},
}) {
    const { t } = useTranslation();
    const { newToast } = useToastActionsContext();
    const prompt = usePrompt();

    const { currentUser } = useCurrentUser();

    const activeGroup = currentUser?.activeGroup;
    const { openEditEventGroupFormWithQuery } = useEditEventGroupForm();

    const pinnedCardIds = parentCard?.pinnedCardIds || [];
    const isPinned = pinnedCardIds?.includes(card?._id);

    const { loggedInUserCanEdit } = card;
    const defaultHidden = card.defaultHidden || false;

    const [showModal, setShowModal] = useState(false);
    const [showEngagementModal, setShowEngagementModal] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [showTagsForm, setShowTagsForm] = useState(false);
    const [showMoveForm, setShowMoveForm] = useState(false);
    const [showSuggestionOverlay, setShowSuggestionOverlay] = useState(false);
    const [hideMedia, setHideMedia] = useState(defaultHidden);
    const confirm = useConfirmation();
    const { newModal, closeModal } = useModal();
    const { newModal: centerModal } = useModal({ mobile: 'center' });
    const { openDrawer } = useSideDrawer();
    const timer = useTimer();

    const updatecard = useRef();
    const currentImageRef = useRef(0);

    const [updateCard] = useMutation(UPDATE_CARD);
    const [flagContentV2] = useMutation(FLAG_CONTENT_V2);

    const setCurrentImage = index => {
        currentImageRef.current = index;
        _setCurrentImage(index);
    };

    const handleOpenSuggestion = () => setShowSuggestionOverlay(true);

    const handleCloseSuggestion = () => setShowSuggestionOverlay(false);

    const handleEdit = () => {
        if (card?.type === CARD_DYNAMIC) {
            openEditEventGroupFormWithQuery('overview', card);
            return;
        }

        openEditEventGroupForm('overview', activeGroup);
    };

    const handleOnClick = deletion => {
        if (deletion) {
            setShowModal(true);
            setDeleteMode(true);
        } else {
            // handle posting card case where edit form is in sidebar - todo Discuss in UI meeting
            // what to do with this case or should always be modal? UX question....
            // eslint-disable-next-line no-lonely-if
            if (card?.type === CARD_MOBILIZATION || card?.type === CARD_VOLUNTEER_MOBILIZATION) {
                // assumption for now that there are only one effort on a mobilization....
                const effortCard = card?.mobilizationSettings?.efforts[0].card;
                // Pledge cards need not apply, currently no edit for them....
                if (effortCard?.type !== 'pledge') {
                    const postingData = effortCard?.postingSettings?.postingData?.values;
                    const formSpecUrl = effortCard?.specification?.url;
                    // Show mobilization posting edit
                    const options = {
                        postingData,
                        postingCard: effortCard,
                        formSpecUrl,
                        mobilizationCard: card,
                        initialView: 'edit',
                    };
                    openDrawer(TYPE_POSTING, options);
                }

                if (effortCard?.type === 'pledge') {
                    newModal(<LoadableEditPledgeForm card={card} />);
                    setDeleteMode(false);
                }
            } else {
                setShowModal(true);
                setDeleteMode(false);
            }
        }
    };

    const handleFlagContentClick = async (flagContent, card) => {
        const reason = await prompt(t(`common:${localNamespace}.prompt`), null, null, {
            validate: true,
        });

        if (reason) {
            flagContent({
                variables: {
                    url: card.url,
                    reason,
                },
            });
            newToast(
                <AlertToast
                    boldText={t(`common:${localNamespace}.alert_flagging`)}
                    showWarningIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
        }
    };

    const flags = useFlags();
    const isContentModerationEnabled = flags?.contentModeration ?? false;

    const getCurrentVoteType = (card, voteSettings) => {
        if (voteSettings === 'none') return VOTING_NONE;
        if (!voteSettings) return VOTING_SENTIMENT;
        if (card.type === CARD_VOTE_GALLERY) return VOTING_NONE;

        let noVoting = true;
        if (voteSettings.RELEVANCE?.active) {
            noVoting = false;

            // TODO: Implement moving from relevance -> quality -> sentiment
            /* const hasPassedRelevance = (card.ratings_relevance && card.ratings_relevance_score)
                                        ? (card.ratings_relevance.length > voteSettings.RELEVANCE.to_advance
                                        && card.ratings_relevance_score > voteSettings.RELEVANCE.threshold) : false; */
            const hasPassedRelevance = false;
            if (!hasPassedRelevance) return VOTING_RELEVANCE;
        }
        if (voteSettings.QUALITY?.active) {
            noVoting = false;

            /* const hasPassedQuality = (card.ratings_quality && card.ratings_quality_score)
                                        ? (card.ratings_quality.length > voteSettings.QUALITY.to_advance
                                        && card.ratings_quality_score > voteSettings.QUALITY.threshold) : false; */
            const hasPassedQuality = false;
            if (!hasPassedQuality) return VOTING_QUALITY;
        }
        if (voteSettings.SENTIMENT?.active) {
            noVoting = false;
            return VOTING_SENTIMENT;
        }

        return noVoting ? VOTING_NONE : VOTING_SENTIMENT;
    };

    // filters out dynamic card -> shopping cart card on client side if
    // the user already purchased a ticket or doesnt have access to purchasing an item
    // and is not an admin
    if (card?.type === CARD_DYNAMIC && card?.subtype === GROUP_SHOPPING) {
        const groupSource = card?.reference?.groupSource;
        const isEventAdmin =
            groupSource?.currentUserIsAdmin || groupSource?.currentUserIsDirectAdmin;

        // GROUP_ACTIONS.GROUP_ACTIONS.GROUP_PURCHASE_ACCESS
        // GROUP_ACTIONS.GROUP_LOGGEDOUT_PURCHASE_ACCESS

        // filters out shopping cart if no items are available
        if (groupSource?.products?.length === 0) {
            return null;
        }

        // if (
        //     groupSource.products?.length > 0 &&
        //     groupSource?.products.find(
        //         product => product.type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP
        //     ) &&
        //     !isEventAdmin
        // ) {
        //     return null;
        // }

        // filters our shopping cart if group reference is not an event-subtype
        if (groupSource?.subtype !== CARD_EVENT) {
            return null;
        }

        // filters out shopping cart if user already purchased an item
        // if (
        //     groupSource?.groupActionsAllowed.includes(
        //         GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS
        //     ) &&
        //     !isEventAdmin
        // ) {
        //     return null;
        // }
    }

    let hideShareButton = false;

    // filters out the group card client side from the newsfeed if the event
    // was toggled as paid + has tickets, but the merchant account is not active
    if (card?.type === CARD_GROUP) {
        const group = card?.aliases?.[0]?.group;
        const groupIsntReadyForPayments = groupSellsProductsButCantAcceptPayments(group);

        const currentUserIsAdmin = group?.currentUserIsAdmin || group?.currentUserIsDirectAdmin;
        if (groupIsntReadyForPayments && !currentUserIsAdmin) {
            return null;
        }

        if (groupIsntReadyForPayments) {
            hideShareButton = true;
        }
    }

    // Generate menu dropdown menu options
    // TODO develop useful abstraction for this
    const getTopRightList = (updateCard, showAuthor, flagContent, bookmarkCard, archiveCard) => {
        const list = [];

        // CUSTOM_DROPDOWN_TYPES are content cards that don't use the normal content card dropdown options
        // e.g. for dynamic cards, pretty much all normal dropdown options are not applicable
        if (loggedInUserCanEdit && CUSTOM_DROPDOWN_TYPES[card?.type]) {
            const type = card?.type;
            switch (type) {
                case CARD_DYNAMIC:
                    list.push({
                        id: 1,
                        iconKey: 'Edit',
                        title: t(
                            `common:${localNamespace}.top_right_list.edit_event`,
                            'Edit Event'
                        ),
                        onClick: () => handleEdit(),
                    });
                    list.push({
                        id: 2,
                        iconKey: 'Order',
                        title: t(
                            `common:${localNamespace}.top_right_list.edit_display_order`,
                            'Edit Display Order'
                        ),
                        onClick: () => handleOnClick(false),
                        onClose:
                            card?.mobilizationSettings?.efforts[0].card?.type === 'pledge'
                                ? () => false
                                : false,
                    });
                    list.push({
                        id: 3,
                        iconKey: 'Delete',
                        title: t(`common:${localNamespace}.top_right_list.delete`),
                        isBottom: true,
                        onClick: () => handleOnClick(true),
                    });

                    break;

                default:
                    break;
            }
        }

        if (isContentModerationEnabled) {
            const handleReport = () => {
                flagContentV2({
                    variables: { contentCardId: card?._id },
                });
            };

            list.push({
                id: 102108971,
                iconKey: 'Report',
                title: capitalize(t('common:report', 'report')),
                onClick: () => {
                    confirm({
                        text: capitalize(
                            t(
                                'common:are_you_sure_you_want_to_report_this_post',
                                'are you sure you want to report this post?'
                            )
                        ),
                        onConfirm: handleReport,
                    });
                },
            });
        }

        const currentUserIsAdmin =
            activeGroup?.currentUserIsAdmin || activeGroup?.currentUserIsDirectAdmin;
        const parentCardId = parentCard?._id;
        if (currentUserIsAdmin && pinnable && parentCardId) {
            const postId = card?._id;
            const pinned = pinnedCardIds?.includes(postId);
            list.push({
                id: 112105110,
                iconKey: pinned ? 'x' : 'Pin',
                title: pinned
                    ? capitalize(t('common:unpin', 'unpin'))
                    : capitalize(t('common:pin', 'pin')),
                onClick: () => {
                    updateCard({
                        variables: {
                            cardID: parentCardId,
                            pinnedCardIds: pinned
                                ? pinnedCardIds?.filter(cardId => cardId !== postId) // unpin
                                : [...pinnedCardIds, postId], // pin
                        },
                    });
                },
            });
        }

        if (loggedInUserCanEdit && !CUSTOM_DROPDOWN_TYPES[card?.type]) {
            list.push({
                id: 22,
                iconKey: 'Edit',
                title: t(`common:${localNamespace}.top_right_list.edit`),
                onClick: () => {
                    handleOnClick(false);
                    awsMetricPublisher.publishCount('content-card-engage', 1, [
                        { Name: 'menu', Value: 'edit' },
                    ]);
                },
                onClose:
                    card?.mobilizationSettings?.efforts[0].card?.type === 'pledge'
                        ? () => false
                        : false,
            });

            if (card.type === CARD_EVENT) {
                list.push({
                    id: 323,
                    iconKey: 'Announcement',
                    title: 'Notify Members',
                    isBottom: true,
                    onClick: () => {
                        awsMetricPublisher.publishCount('content-card-engage', 1, [
                            { Name: 'menu', Value: 'notify-members' },
                        ]);
                        centerModal(
                            <LoadableNotifyPanel
                                groupId={card?.reference?.groupSource?._id}
                                card={card}
                                collectionType={Collection.ContentCard}
                            />
                        );
                    },
                    onClose: () => false,
                });
            }

            list.push({
                id: 222,
                iconKey: 'Settings',
                title: 'Optimize',
                onClick: () => {
                    awsMetricPublisher.publishCount('content-card-engage', 1, [
                        { Name: 'menu', Value: 'optimize' },
                    ]);
                    newModal(<LoadableCardOptimizer card={card} />);
                },
                onClose: () => false,
            });

            if (getFeatureConstraintsForField(card, 'action_move')) {
                list.push({
                    id: 939,
                    iconKey: 'Move',
                    title: t(`common:${localNamespace}.top_right_list.move`),
                    onClick: () => {
                        awsMetricPublisher.publishCount('content-card-engage', 1, [
                            { Name: 'menu', Value: 'move' },
                        ]);
                        setShowMoveForm(true);
                    },
                });
            }

            if (getFeatureConstraintsForField(card, 'action_tag')) {
                list.push({
                    id: 414,
                    iconKey: 'Edit Tags',
                    title: t(`common:${localNamespace}.top_right_list.edit_tags`),
                    onClick: () => {
                        awsMetricPublisher.publishCount('content-card-engage', 1, [
                            { Name: 'menu', Value: 'edit-tags' },
                        ]);
                        setShowTagsForm(true);
                    },
                });
            }

            if (getFeatureConstraintsForField(card, 'action_archive')) {
                if (card.isArchived) {
                    list.push({
                        id: 10,
                        iconKey: 'Unarchive',
                        title: t(`common:${localNamespace}.top_right_list.unarchive`),
                        onClick: () => {
                            awsMetricPublisher.publishCount('content-card-engage', 1, [
                                { Name: 'menu', Value: 'unarchive' },
                            ]);
                            archiveCard({
                                variables: {
                                    cardUrl: card.url,
                                    unarchive: true,
                                },
                            });
                        },
                    });
                } else {
                    list.push({
                        id: 10,
                        iconKey: 'Archive',
                        title: t(`common:${localNamespace}.top_right_list.archive`),
                        onClick: () => {
                            awsMetricPublisher.publishCount('content-card-engage', 1, [
                                { Name: 'menu', Value: 'archive' },
                            ]);
                            archiveCard({
                                variables: {
                                    cardUrl: card.url,
                                },
                            });
                        },
                    });
                }
            }

            list.push({
                id: 33,
                iconKey: 'Delete',
                title: t(`common:${localNamespace}.top_right_list.delete`),
                isBottom: true,
                onClick: () => {
                    awsMetricPublisher.publishCount('content-card-engage', 1, [
                        { Name: 'menu', Value: 'delete' },
                    ]);
                    handleOnClick(true);
                },
            });

            if (
                (card?.aliases && card?.aliases[0]?.group?.subtype === CARD_EVENT) ||
                (card?.type === CARD_DYNAMIC &&
                    card?.reference?.groupSource?.subtype === CARD_EVENT)
            ) {
                let groupData;

                if (card?.aliases && card?.aliases[0]?.group?.subtype === CARD_EVENT) {
                    groupData = card?.aliases[0]?.group;
                } else if (
                    card?.type === CARD_DYNAMIC &&
                    card?.reference?.groupSource?.subtype === CARD_EVENT
                ) {
                    groupData = card?.reference?.groupSource;
                }

                list.push(
                    // this option will be available for the following...
                    // a). groups with ** the correct aliasGroupHandle **
                    // ** there was an issue on the CreateEvent form where the incorrect alias type was being set
                    // ** therefor some event-groups will possibly not have this option on the GroupItemCard dropdown.
                    // b). DynamicInfoCard
                    {
                        id: 15,
                        title: 'Edit Tickets',
                        iconKey: 'Ticket',
                        onClick: () => {
                            awsMetricPublisher.publishCount('content-card-engage', 1, [
                                { Name: 'menu', Value: 'edit-tickets' },
                            ]);
                            newModal(<EditTickets group={groupData} />);
                        },
                    }
                );
            }
        }

        if (card.isBookmarked) {
            list.push({
                id: 5,
                iconKey: 'Remove Bookmark',
                title: t(`common:${localNamespace}.top_right_list.unbookmark`),
                onClick: () => {
                    awsMetricPublisher.publishCount('content-card-engage', 1, [
                        { Name: 'menu', Value: 'remove-bookmark' },
                    ]);
                    bookmarkCard({
                        variables: {
                            cardUrl: card.url,
                            remove: true,
                        },
                    });
                },
            });
        } else {
            list.push({
                id: 5,
                iconKey: 'Bookmark',
                title: t(`common:${localNamespace}.top_right_list.bookmark`),
                onClick: () => {
                    awsMetricPublisher.publishCount('content-card-engage', 1, [
                        { Name: 'menu', Value: 'add-bookmark' },
                    ]);
                    bookmarkCard({
                        variables: {
                            cardUrl: card.url,
                        },
                    });
                },
            });
        }

        if (card?.currentUserCanSuggestEdit) {
            list.push({ id: 999, title: 'Suggest Edit', onClick: handleOpenSuggestion });
        }

        list.push({
            id: 7,
            iconKey: 'Share',
            title: t(`common:${localNamespace}.top_right_list.view_post`),
            to: `/c/${card.url}`,
            onClick: () => {
                awsMetricPublisher.publishCount('content-card-engage', 1, [
                    { Name: 'menu', Value: 'view-post' },
                ]);
                closeModal();
            },
        });

        list.push({
            id: 15,
            title: 'Download',
            iconName: 'Duplicate',
            onClick: () => {
                if (card.aliases?.length > 1) {
                    window.open(`${card.aliases[currentImageRef.current]?.url}?dl=true`, '_system');
                } else {
                    window.open(`${card.media?.thumb}?dl=true`, '_system');
                }
            },
        });

        return list;
    };

    let tempViewType;

    if (overrideViewType) {
        tempViewType = overrideViewType;
    } else {
        tempViewType = chapterSettings?.defaultViewType || 'fullview';

        if (chapterSettings?.viewTypes && !_.includes(chapterSettings.viewTypes, tempViewType)) {
            tempViewType = chapterSettings.viewTypes[0];
        } // eslint-disable-line prefer-destructuring

        tempViewType = card?.library_settings?.defaultViewType || tempViewType;
    }

    const viewType = tempViewType;

    let voteType = getCurrentVoteType(card, voteSettings);

    const allowLabels = chapterSettings?.allowLabels ?? true;

    if (allowLabels && card.label?.text) {
        if (card.label.type === 'BUBBLE') voteType = VOTING_NO_VOTE_LABEL;
        else if (card.label.type === 'PLAIN') voteType = VOTING_NO_VOTE_TEXT;
    }

    if (isLeaderboard) voteType = VOTING_LEADERBOARD;

    // TODO: put this into module component like others
    if (voteOrNextFunction) voteType = VOTING_SENTIMENT;

    let engagementTypes = [ENGAGE_FOLLOW, ENGAGE_COMMENT];

    if (_.has(chapterSettings, 'engagementTypes')) {
        engagementTypes = [...chapterSettings.engagementTypes];
    }

    if (card.library_settings?.engagementTypes) {
        engagementTypes = card.library_settings.engagementTypes;
    }

    const modules = [];

    if (
        card.type === RESOURCE_ALLOWED_TYPES[card.type] &&
        !hideResources &&
        card.library_settings?.showCreateChildren
    ) {
        modules.push(
            <LoadableResourcesModule
                key="resources"
                card={card}
                activeQuery={activeQuery}
                voteSettings={voteSettings}
            />
        );
    }

    const hiddenBodyStyle = {
        // backgroundColor: 'transparent',
        // boxShadow: 'none',
        // borderLeft: 'unset',
        // borderRight: 'unset',
        // borderBottom: 'unset',
        // marginBottom: '0px',
    };

    let contentCardBody;
    let hideCard = false;
    let opaqueCard = false;
    let isEngagementJustified = true;
    let _hideEngagement = engagementTypes?.length === 0;
    let _hideTopRightDropdown = hideTopRightDropdown || false;
    let isCardContainerVisible = true;
    let leftRightMargin = false;
    let areTopRightDropdownDotsInverted = false;
    let contentCardStyle = {};
    contentCardExtraClasses += `${leftRightMargin ? 'we-mobile-lr-margin' : ''} ${
        topGreyBorder ? 'we-mobile-topgreyborder' : ''
    }`;

    let displaySocialHeader = card?.library_settings?.displaySocialHeaders;

    const _chapterSettings = chapterSettings || card?.reference?.chapterSource?.library_settings;

    if (
        _chapterSettings?.displaySocialHeaders === null &&
        card?.library_settings?.displaySocialHeaders === null
    ) {
        displaySocialHeader = true;
    }
    if (_chapterSettings?.displaySocialHeaders !== null) {
        displaySocialHeader = _chapterSettings?.displaySocialHeaders;
    }

    if (
        card?.library_settings?.displaySocialHeaders !== null &&
        _chapterSettings?.displaySocialHeaders
    ) {
        displaySocialHeader = card?.library_settings?.displaySocialHeaders;
    }

    const _noDate = !_hideEngagement;
    const cardTextContent = { title: card.title, body: card.body };

    if (!currentUser) _hideTopRightDropdown = true;

    switch (card.type) {
        case CARD_TEXT:
            contentCardBody = (
                <LoadableMediaCardSource
                    card={card}
                    source={card.author}
                    date={card.date}
                    text={cardTextContent}
                    noDate={_noDate}
                    chapterSettings={chapterSettings}
                    onLoad={onLoad}
                    displaySocialHeader={displaySocialHeader}
                    onClickCallback={() =>
                        awsMetricPublisher.publishCount('content-card-click-avatar', 1)
                    }
                />
            );
            break;
        case CARD_RAINBOW_ROAD:
            contentCardBody = <LoadableRainbowRoadItem card={card} onLoad={onLoad} />;

            areTopRightDropdownDotsInverted = true;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;

            isEngagementJustified = true;

            break;
        case CARD_SURVEY:
            contentCardBody = <LoadableSurveyItem card={card} onLoad={onLoad} />;

            areTopRightDropdownDotsInverted = true;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;

            isEngagementJustified = true;

            break;
        case CARD_COURSE:
            contentCardBody = <LoadableCourseItem card={card} onLoad={onLoad} />;

            areTopRightDropdownDotsInverted = true;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;

            isEngagementJustified = true;

            break;

        case CARD_CREATION:
            contentCardBody = <LoadableCreationItem card={card} onLoad={onLoad} />;

            areTopRightDropdownDotsInverted = true;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;

            isEngagementJustified = true;

            break;

        case CARD_DIALOG:
            contentCardBody = <LoadableDialogItem card={card} onLoad={onLoad} />;

            areTopRightDropdownDotsInverted = true;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;

            isEngagementJustified = true;

            break;

        case CARD_PLEDGE:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1, onLoad);
            contentCardBody = (
                <LoadablePledgeCardContainer
                    card={card}
                    source={card.author}
                    currentUser={currentUser}
                    date={card.date}
                    text={cardTextContent}
                    noDate={noDate}
                    chapterSettings={chapterSettings}
                    isHome={isHome ?? true}
                />
            );

            // isCardContainerVisible = false;

            areTopRightDropdownDotsInverted = true;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;

            engagementTypes = [];
            _hideEngagement = true;
            // Remove the top grey border from the pledge container.
            // The task cards within have top grey borders
            contentCardExtraClasses = contentCardExtraClasses.replace(
                'we-mobile-topgreyborder',
                ''
            );

            voteType = null;
            isEngagementJustified = true;

            break;
        case CARD_DYNAMIC:
            if (onLoad) timer(1, onLoad);
            contentCardBody = <LoadableDynamicCard card={card} />;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;
            break;
        case CARD_STORY:
            if (onLoad) timer(1, onLoad);
            contentCardBody = <LoadableStoryCard card={card} />;
            break;
        case CARD_META_CHANNEL:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1, onLoad);
            if (card.aliases?.length === 0) {
                contentCardBody = <LoadableErrorItem />;
            } else {
                isCardContainerVisible = false;
                contentCardExtraClasses = `postbox-container ${
                    showReferenceHeader ? 'ref-header' : ''
                }`;

                contentCardBody = (
                    <LoadableMetaChannelCard
                        card={card}
                        idx={idx}
                        idxTotal={idxTotal}
                        currentTerminalURL={currentTerminalURL}
                        noRecursiveMetaCard={noRecursiveMetaCard}
                    />
                );
            }

            areTopRightDropdownDotsInverted = false;
            _hideEngagement = true;
            leftRightMargin = true;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;
            break;
        // CARD_MOBILIZE is deprecated in favor of CARD_MOBILIZATION
        case CARD_MOBILIZE:
        case CARD_MOBILIZATION:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1, onLoad);
            contentCardBody = (
                <LoadableMobilizationCardContainer
                    card={card}
                    source={card.author}
                    currentUser={currentUser}
                    date={card.date}
                    text={cardTextContent}
                    noDate={noDate}
                    chapterSettings={chapterSettings}
                    isHome={isHome ?? true}
                />
            );
            // isCardContainerVisible = false;
            areTopRightDropdownDotsInverted = true;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;
            engagementTypes = [];
            _hideEngagement = true;
            contentCardExtraClasses = contentCardExtraClasses.replace(
                'we-mobile-topgreyborder',
                ''
            );
            voteType = null;
            isEngagementJustified = true;

            break;
        case CARD_CUSTOM_FIELDS_SUMMARY:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1, onLoad);
            contentCardBody = (
                <LoadableErrorBoundary>
                    <LoadableCustomFieldsSummaryItem card={card} currentUser={currentUser} />
                </LoadableErrorBoundary>
            );
            _hideEngagement = true;
            displaySocialHeader = false;
            voteType = null;

            break;
        case CARD_POSTBOX:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1, onLoad);
            if (card.aliases?.length === 0) {
                contentCardBody = <></>;
            } else {
                isCardContainerVisible = false;
                contentCardExtraClasses = `postbox-container ${
                    showReferenceHeader ? 'ref-header' : ''
                }`;

                const prompts = card.aliases?.filter(
                    alias => !alias?.item?.isArchived && alias?.item?.library_settings?.userCanPost
                );

                if (prompts?.length === 0) {
                    contentCardBody = <></>;
                } else if (prompts?.length === 1) {
                    const alias = prompts[0];
                    contentCardBody = (
                        <LoadablePostboxItem
                            alias={alias}
                            prompt={card.title || (alias?.item?.prompt ?? '')}
                            loggedInUserCanEdit={loggedInUserCanEdit}
                        />
                    );
                } else contentCardBody = <LoadableMultiPromptPostbox card={card} />;
            }

            areTopRightDropdownDotsInverted = false;
            _hideEngagement = true;
            leftRightMargin = true;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;

            break;
        case CARD_LINK_TILE:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1, onLoad);
            contentCardBody = <LoadableTileItem card={card} />;
            _hideEngagement = true;
            isCardContainerVisible = false;
            areTopRightDropdownDotsInverted = true;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;

            break;
        case CARD_VOTE_GALLERY:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1, onLoad);
            contentCardBody = <LoadableVoteOrSkipItem card={card} currentUser={currentUser} />;
            isCardContainerVisible = true;
            areTopRightDropdownDotsInverted = false;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;
            contentCardExtraClasses = '';

            break;
        case CARD_HEADER:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1, onLoad);
            contentCardBody = <LoadableHeaderItem onLoad={onLoad} card={card} />;
            isCardContainerVisible = false;
            displaySocialHeader = false;
            if (!loggedInUserCanEdit) _hideTopRightDropdown = true;

            break;
        case CARD_MAP:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1, onLoad);
            contentCardBody = <LoadableMapItem onLoad={onLoad} card={card} />;
            break;
        case CARD_BLOG:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1, onLoad);
            contentCardBody = <LoadableBlogItem card={card} getTopRightList={getTopRightList} />;
            areTopRightDropdownDotsInverted = true;

            break;
        case CARD_EVENT:
            // TODO: intelligently call onLoad....
            if (onLoad) timer(1).then(onLoad);
            contentCardBody = <LoadableEventItem card={card} getTopRightList={getTopRightList} />;
            areTopRightDropdownDotsInverted = true;
            displaySocialHeader = false;
            contentCardExtraClasses = contentCardExtraClasses.replace(
                'we-mobile-topgreyborder',
                ''
            );
            voteType = null;
            isEngagementJustified = true;

            break;
        case CARD_GROUP:
            // TODO: intelligently call onLoad....

            if (onLoad) timer(1).then(onLoad);
            contentCardBody = <LoadableGroupItem card={card} getTopRightList={getTopRightList} />;
            areTopRightDropdownDotsInverted = true;
            displaySocialHeader = false;
            voteType = null;
            isEngagementJustified = true;

            const group = card.aliases[0]?.group;
            if (group?.isUnpublished) {
                if (group.currentUserIsAdmin || group.currentUserIsDirectAdmin) {
                    opaqueCard = true;
                } else {
                    hideCard = true;
                }
            }

            break;
        case CARD_DOCUMENT:
            // Prevent the doubling up of the title, because these card types aleady display a title.
            cardTextContent.title = compareVersions(card.vs, '1.0.2') >= 0 ? card.title : null;
        // getMajorVersion(card.vs) >= 1 && getPatchVersion(card.vs) >= 2 ? card.title : null;
        case CARD_LINK:
            cardTextContent.title = getMajorVersion(card.vs) >= 1 ? cardTextContent.title : null;
        default:
            // text?.body !== '/n' is a artifact caused by the WYSIWYG editor, will look into....
            const showText =
                !!(cardTextContent.body?.trim() && cardTextContent.body !== '\n') ||
                !!cardTextContent.title?.trim();

            if (card.type === CARD_EVENT) engagementTypes.push(ENGAGE_ATTEND);

            contentCardBody =
                showText || displaySocialHeader ? (
                    <div>
                        <LoadableMediaCardSource
                            loading={loading}
                            card={card}
                            source={card.author}
                            date={card.date}
                            text={cardTextContent}
                            noDate={_noDate}
                            chapterSettings={chapterSettings}
                            displaySocialHeader={displaySocialHeader}
                            onClickCallback={() =>
                                awsMetricPublisher.publishCount('content-card-click-avatar', 1)
                            }
                        />
                        <LoadableMediaCardBody
                            loading={loading}
                            card={card}
                            getTopRightList={getTopRightList}
                            transparentBackground={transparentBackground}
                            noHeader={!showText}
                            maxWidth={maxWidth}
                            onLoad={onLoad}
                            baseMetricStringName="content-card-engage"
                            setCurrentImage={setCurrentImage}
                        />
                    </div>
                ) : (
                    <div>
                        <LoadableMediaCardBody
                            loading={loading}
                            card={card}
                            transparentBackground={transparentBackground}
                            maxWidth={maxWidth}
                            onLoad={onLoad}
                            noHeader
                            baseMetricStringName="content-card-engage"
                            setCurrentImage={setCurrentImage}
                        />
                    </div>
                );

            areTopRightDropdownDotsInverted =
                showText || displaySocialHeader
                    ? areTopRightDropdownDotsInverted
                    : !showReferenceHeader;

            if (
                card?.type === CARD_IMAGE ||
                card?.type === CARD_IMAGE_SQUARE ||
                card?.type === CARD_VIDEO
            ) {
                // This handles specifically what happens if on a card level the showTitle property is toggled....
                const cardShowTitle = !!(card?.showTitle === null || card?.showTitle);
                if (
                    showText &&
                    cardTextContent?.body !== '\n' &&
                    cardTextContent?.body?.trim() !== ''
                ) {
                    areTopRightDropdownDotsInverted = false;
                } else {
                    areTopRightDropdownDotsInverted = !showReferenceHeader;
                }
            }
    }

    if (voteOrSkipStyle) contentCardExtraClasses += ' we-review-and-vote-card';

    if (extraClasses) {
        contentCardExtraClasses += ` ${extraClasses} ${
            (card?.type === CARD_META_CHANNEL || card?.type === CARD_DYNAMIC) && card?.type
        }`;
    }

    if (!isCardContainerVisible) contentCardStyle = { ...contentCardStyle, ...hiddenBodyStyle };

    if (card.isArchived) contentCardExtraClasses += ' archived ';

    // Don't allow sentiment voting and heart engagment simeoultaneously
    if (engagementTypes.includes(ENGAGE_HEART) && voteType === VOTING_SENTIMENT) {
        voteType = VOTING_NONE;
    }

    const engagementItems = getArrayOfRenderPropsByEngagementType(engagementTypes);

    if (hideMedia) {
        // contentCardBody = <div className="mc-media-item photoitem" />;
        contentCardBody = (
            <div
                className="mc-source-post-wrapper"
                style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            >
                <div className="mc-source-container">
                    <div className="mc-source-info-container">
                        <div className="media-card-author">
                            <i style={{ color: 'grey' }}>
                                {t(`common:${localNamespace}.flagged_content_moderation`)}
                            </i>
                        </div>
                    </div>
                </div>
                <div className="mc-source-text-container">
                    <button
                        type="button"
                        onClick={() => setHideMedia(false)}
                        className="content-button-icon dropdown-dots w-dropdown-toggle"
                        aria-label="Show Media"
                    />
                </div>
            </div>
        );
        displaySocialHeader = false;
        _hideTopRightDropdown = true;
        _hideEngagement = true;
        contentCardStyle = { ...contentCardStyle, ...{ opacity: 0.7 } };
    }

    const unavailableListCardTypes = {
        [CARD_LINK_TILE]: true,
        [CARD_PLEDGE]: true,
        [CARD_POSTBOX]: true,
        [CARD_VOTE_GALLERY]: true,
        [CARD_CHAPTER]: true,
    };

    const isPossibleListCardType = cardType => !unavailableListCardTypes[cardType];

    if (viewType === 'listview') {
        _hideEngagement = true;
        areTopRightDropdownDotsInverted = false;
    }

    const renderVotingBoothButtons = voteBooth && viewType !== 'listview' && (
        <div className="voting-booth-actions">
            {voteType !== VOTING_NONE && (
                <>
                    <div className="vote-booth-heading">
                        <div>{t(`common:${votingNamespace}.vote`)}</div>
                        <button
                            type="button"
                            onClick={() => voteOrNextFunction({ data: null })}
                            className="vote-booth-skip-button w-inline-block"
                        >
                            {t(`common:${votingNamespace}.skip_vote`)}
                        </button>
                    </div>
                    <div className="voting-booth-buttons">
                        <LoadableVotingContainer
                            voteBooth
                            voteType={voteType}
                            card={card}
                            rank={rank}
                            voteOrNextFunction={voteOrNextFunction}
                        />
                    </div>
                </>
            )}
        </div>
    );

    if (hideCard) return undefined;

    return (
        <article
            className={`content-card-wrapper voting-wrapper ${opaqueCard ? 'opacity-60' : ''
                } ${className}`}
            id={`${currentTerminalURL}-${idx}`}
            aria-posinset={idx}
            aria-setsize="-1"
            aria-label={getContentCardLabel(card)}
        >
            {renderVotingBoothButtons}
            {/* add onClick function to add and remove bookmark state and add and remove .active
                class from .bookmark-indicator */}
            {showBookmark && <LoadableBookmarkIndicator isBookmarked={card.isBookmarked} />}
            <div
                className={`content-card-container ${contentCardExtraClasses}`}
                style={contentCardStyle}
            >
                <div className={`pinned-indicator ${isPinned && pinnable ? 'active' : ''}`}>
                    <div className="tack" />
                    {capitalize(t('common:pinned', 'pinned'))}
                </div>
                {showReferenceHeader && (
                    <ReferenceCardHeader source={card} extraClasses={referenceHeaderExtraClasses} />
                )}

                {_hideEngagement && !_hideTopRightDropdown && (
                    <LoadableContentCardMenu
                        card={card}
                        _showReferenceHeader={showReferenceHeader}
                        displaySocialHeader={displaySocialHeader}
                        getTopRightList={getTopRightList}
                        areTopRightDropdownDotsInverted={areTopRightDropdownDotsInverted}
                    />
                )}

                <div className="mc-post-wrapper">
                    {viewType === 'listview' && isPossibleListCardType(card.type) ? (
                        <LoadableListViewContainer
                            card={card}
                            handleOpenEngagementModal={() => setShowEngagementModal(true)}
                            displaySocialHeader={displaySocialHeader}
                            externalLinkTypes={externalLinkTypes}
                            onLoad={onLoad}
                        />
                    ) : (
                        contentCardBody
                    )}
                </div>

                {modules.length > 0 && viewType !== 'listview' && (
                    <div className="mc-module-wrapper">
                        <LoadableModule>{modules}</LoadableModule>
                    </div>
                )}

                {viewType !== 'listview' && !voteBooth && (
                    <div className="voting-footer">
                        {voteType !== VOTING_NONE && (
                            <LoadableVotingContainer
                                voteType={voteType}
                                card={card}
                                rank={rank}
                                voteOrNextFunction={voteOrNextFunction}
                            />
                        )}
                    </div>
                )}

                {customFooter || (
                    <EngagementController
                        card={card}
                        items={engagementItems}
                        voteSettings={voteSettings}
                        chapterSettings={chapterSettings}
                        voteType={voteType}
                        handleOpenEngagementModal={() => setShowEngagementModal(true)}
                        handleCloseEngagementModal={() => setShowEngagementModal(false)}
                        getTopRightList={getTopRightList}
                        defaultOpenDrawer={defaultOpenDrawer}
                        showEngagementModal={showEngagementModal}
                        showReferenceHeader={showReferenceHeader}
                        hideEngagement={_hideEngagement}
                        hideTopRightDropdown={_hideTopRightDropdown}
                        displaySocialHeader={displaySocialHeader}
                        areTopRightDropdownDotsInverted={areTopRightDropdownDotsInverted}
                        justified={isEngagementJustified}
                        expandComments={expandComments}
                        hideShareButton={hideShareButton}
                        getCurrentImageIndex={() => currentImageRef.current}
                    />
                )}
            </div>
            {/* Perhaps pass in cardid and chaptersettings to get tagdata or
                it is already passed in as part of card object? */}
            {!hideTags && <TagsBlock tags={card.tags?.results} />}

            {/* The following components should only be lkoaded if user can edit */}
            {loggedInUserCanEdit && (
                <>
                    <Modal
                        delayTime={500}
                        className="modal"
                        card={card}
                        isMounted={showTagsForm}
                        show={showTagsForm}
                    >
                        <TagsForm
                            card={card}
                            handleClose={() => setShowTagsForm(false)}
                            currentUser={currentUser}
                        />
                    </Modal>

                    <Modal
                        delayTime={500}
                        className="modal"
                        card={card}
                        isMounted={showMoveForm}
                        show={showMoveForm}
                    >
                        <MoveItem
                            item={card}
                            cardTypes={[CARD_BOOK, CARD_CHAPTER]}
                            childrenTypes={[CARD_CHAPTER]}
                            handleClose={() => setShowMoveForm(false)}
                            currentUser={currentUser}
                            activeQuery={activeQuery}
                        />
                    </Modal>

                    <Modal
                        cardEdit
                        // smallModal={deleteMode}
                        delayTime={500}
                        triggerMenu={() => updatecard.handleClick()}
                        className="modal"
                        card={card}
                        updateCard={updateCard}
                        isMounted={showModal}
                        show={showModal}
                        close={() => setShowModal(false)}
                        contentCard
                    >
                        <UpdateCard
                            deleteMode={deleteMode}
                            ref={updatecard}
                            card={card}
                            noTabs
                            handleModalClose={() => setShowModal(false)}
                            updateCard={updateCard}
                            currentUser={currentUser}
                            activeQuery={activeQuery}
                            chapterSettings={chapterSettings}
                            baseMetricStringName="content-card-update-modal"
                        />
                    </Modal>
                </>
            )}

            {card?.currentUserCanSuggestEdit && (
                <Modal
                    delayTime={500}
                    className="modal"
                    card={card}
                    isMounted={showSuggestionOverlay}
                    show={showSuggestionOverlay}
                >
                    <div className="new-post-overlay-wrapper">
                        <LoadableSuggestChangeOverlay
                            handleClose={handleCloseSuggestion}
                            card={card}
                            currentUser={currentUser}
                        />
                    </div>
                </Modal>
            )}
        </article>
    );
});

const ContentCardQuery = React.memo(function ContentCardQuery(props) {
    const { card } = props;

    const [followCard] = useMutation(FOLLOW_CARD, {
        update: (cache, { data: { followCard } }) => {
            const fragment =
                (followCard.type === CARD_SHELF && CONTENT_CARD_SHELF_FRAGMENT) ||
                (followCard.type === CARD_BOOK && CONTENT_CARD_BOOK_FRAGMENT) ||
                (followCard.type === CARD_CHAPTER && CONTENT_CARD_CHAPTER_FRAGMENT) ||
                CONTENT_CARD_FRAGMENT;

            const fragmentName =
                (followCard.type === CARD_SHELF && 'ContentCardShelfFragment') ||
                (followCard.type === CARD_BOOK && 'ContentCardBookFragment') ||
                (followCard.type === CARD_CHAPTER && 'ContentCardChapterFragment') ||
                'ContentCardFragment';

            // Get the card followed
            const followed = cache.readFragment({
                id: `ContentCard:${followCard._id}`,
                fragment,
                fragmentName,
            });

            if (!followed) return;

            // Manually update the cached card to followed = true
            followed.loggedInUserFollows = !followCard.loggedInUserFollows;
            followed.__typename = 'ContentCard';

            // Write back into cache
            cache.writeFragment({
                id: `ContentCard:${followCard._id}`,
                fragment,
                data: followed,
                fragmentName,
            });
        },
    });

    const [updateConnection] = useMutation(UPDATE_USER_CONNECTION, {
        update: (cache, { data: { updateConnection } }) => {
            const user = cache.readFragment({
                id: `User:${updateConnection._id}`,
                fragment: USER_FRAGMENT,
            });

            if (!user) return;

            user.profile.loggedInUserConnectionStatus =
                updateConnection.profile.loggedInUserConnectionStatus;
            user.__typename = 'User';

            cache.writeFragment({
                id: `User:${updateConnection._id}`,
                fragment: USER_FRAGMENT,
                data: user,
            });
        },
    });

    // If it is a shelf or a book, show the option to follow it.
    // TODO: This is currently not used
    if (card?.type === CARD_SHELF || card?.type === CARD_BOOK) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <ContentCard followCard={followCard} {...props} />;
    }

    // If the author is the loggedIn user, don't show a connect option
    // TODO: This can be calculated inside of ContentCard, eliminating a prop
    if (card?.author?.profile?.loggedInUserConnectionStatus === CONNECTION_STATUS_IS_LOGGEDINUSER) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <ContentCard connectIsLoggedInUser {...props} />;
    }

    // If there is an author to connect with, add a connection function
    // TODO: This is currently not used
    // eslint-disable-next-line react/jsx-props-no-spreading
    if (card?.author) return <ContentCard updateConnection={updateConnection} {...props} />;

    // No follow / connect button
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ContentCard {...props} />;
});

export default ContentCardQuery;
