import React, { useReducer } from 'react';

const SHOW_TOAST = 'SHOW_TOAST';
const SHOW_CUSTOM_TOAST = 'SHOW_CUSTOM_TOAST';
const HIDE_TOAST = 'HIDE_TOAST';

export const TOAST_BOTTOM = 'bottom';
export const TOAST_TOP = 'top';
export const TOAST_RESPONSIVE = 'responsive';

const ToastContext = React.createContext();
const ToastReducer = (state, action) => {
    switch (action.type) {
        case SHOW_TOAST:
            return {
                ...state,
                toastText: action?.text,
                toastLink: action?.toastLink,
                duration: action?.duration,
                position: action?.position,
                isVisible: true,
                isCustomToast: false,
            };

        case SHOW_CUSTOM_TOAST:
            return {
                ...state,
                toastText: action?.text,
                toastLink: action?.toastLink,
                toastImage: action?.toastImage,
                toastFooterBtns: action?.toastFooterBtns,
                duration: action?.duration,
                position: action?.position,
                isVisible: true,
                isCustomToast: true,
            };

        case HIDE_TOAST:
            return {
                ...state,
                isVisible: false,
            };
        default:
            return;
    }
};

export const ToastProvider = ({ children }) => {
    const [toast, dispatch] = useReducer(ToastReducer, {
        toastText: '',
        toastLink: '',
        toastImage: null,
        toastFooterBtns: null,
        duration: 150000,
        position: TOAST_BOTTOM,
        isVisible: false,
        isCustomToast: false,
    });

    const showToast = (toastText, toastLink, duration, position) => {
        dispatch({
            type: SHOW_TOAST,
            text: toastText,
            position,
            toastLink,
            duration,
            isCustomToast: false,
        });
    };

    const showCustomToast = (
        toastText,
        toastLink,
        duration,
        position,
        toastImage,
        toastFooterBtns
    ) => {
        dispatch({
            type: SHOW_CUSTOM_TOAST,
            text: toastText,
            position,
            toastLink,
            duration,
            isCustomToast: true,
            toastImage,
            toastFooterBtns,
        });
    };

    const hideToast = () => {
        dispatch({
            type: HIDE_TOAST,
            text: '',
            toastLink: '',
            isVisible: false,
        });
    };

    return (
        <ToastContext.Provider
            value={{
                toast,
                showToast,
                showCustomToast,
                hideToast,
            }}
        >
            {children}
        </ToastContext.Provider>
    );
};

export default ToastContext;
