import React from 'react';
import MessageThreadActions from '@core/utilities/constants/thread';
import { Link, useHistory } from 'react-router-dom';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import { useCurrentUser } from '@stores/User';
import PaperPlaneIcon from '@web/ui/dsc/svgs/PaperPlaneIcon';
import TrashIcon from '@web/ui/dsc/svgs/TrashIcon';
import { GET_USER_MESSAGE_THREADS } from '@shared/welibrary-graphql/messaging/queries';
import { useCreateMessageThreadMutation } from '@shared/welibrary-graphql/messaging/mutations.hook';

import getLogger from '@core/logger';

const logger = getLogger(module);

type SuggestedBlockProps = {
    suggestType: MessageThreadActions;
    showMsgUser?: boolean;
    handleClick: (e: React.MouseEvent<HTMLElement>) => void;
    wholeItemClick?: boolean;
    isLastItem?: boolean;
    data: any;
};

// This and the normal message block should be refactored to be the same display component at some point
const SuggestedBlock: React.FC<SuggestedBlockProps> = ({
    suggestType,
    showMsgUser = false,
    handleClick,
    wholeItemClick = true,
    data: { _id, profile, text, linkText, link },
    isLastItem,
}) => {
    const history = useHistory();
    const { full_name, picture } = profile || {};
    const { currentUser } = useCurrentUser();
    const isCurrentUser = _id === currentUser?._id;

    const [createMessageThread] = useCreateMessageThreadMutation();

    const thumbImage = picture || getRandomDefaultUserImage();
    const [src, srcSet] = getBackgroundImageSetForUrl(thumbImage || getRandomDefaultUserImage());
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!isCurrentUser) {
            handleClick(e);
        }
    };

    const itemClick = wholeItemClick ? onClick : undefined;

    const getIconFromSuggestType = (type: MessageThreadActions) => {
        if (isCurrentUser) return;
        switch (type) {
            case MessageThreadActions.ADD_TO_MESSAGE:
                return (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <button
                        type="button"
                        className="round-square-btn inbox-selection-indicator animate-grow"
                        onClick={onClick}
                        id={_id}
                    />
                );
            case MessageThreadActions.REMOVE_THREAD_MEMBER:
                return (
                    <button
                        type="button"
                        className="round-square-btn suggested-list animate-grow"
                        onClick={onClick}
                        id={_id}
                    >
                        <TrashIcon color="#000" />
                    </button>
                );
            default:
        }
    };

    const renderIcon = getIconFromSuggestType(suggestType);

    const handleMsgOtherUser = () => {
        try {
            createMessageThread({
                variables: {
                    input: {
                        participants: [_id, currentUser?._id],
                        displayName: '',
                    },
                },
                refetchQueries: [GET_USER_MESSAGE_THREADS],
                update: (cache, { data: { createMessageThread } }) => {
                    const threadId = createMessageThread._id;

                    history.replace({
                        pathname: `/messages/${threadId}`,
                        state: {
                            otherUser: { _id, profile },
                        },
                    });
                },
            });
        } catch (e) {
            logger?.error('error', e);
        }
    };

    const cssClassname = isLastItem
        ? 'inbox-item-block w-inline-block inbox-suggestion last'
        : 'inbox-item-block w-inline-block inbox-suggestion';

    return (
        <div className={cssClassname} onClick={itemClick} id={_id}>
            <StyledBackgroundImage
                className="thumbnail larger-thumbnail"
                style={{ backgroundSize: 'cover' }}
                src={src}
                srcSet={srcSet}
            />
            <div className="inbox-item-content">
                <div className="inbox-item-name">{full_name}</div>
                {link && linkText ? (
                    <div>
                        {`${text} `}
                        <Link to={link}>{linkText}</Link>
                    </div>
                ) : null}
            </div>
            <div className="inbox-row-right suggested">
                {showMsgUser && !isCurrentUser && (
                    <button
                        type="button"
                        onClick={handleMsgOtherUser}
                        style={{ marginRight: '10px' }}
                        className="round-square-btn suggested-list msg-user-icon animate-grow"
                    >
                        <PaperPlaneIcon color="#000" size="20px" />
                    </button>
                )}
                {renderIcon}
            </div>
        </div>
    );
};

export default SuggestedBlock;
