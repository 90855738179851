// src/types.ts
var AccountHolderType = /* @__PURE__ */ ((AccountHolderType2) => {
  AccountHolderType2["Company"] = "company";
  AccountHolderType2["Individual"] = "individual";
  return AccountHolderType2;
})(AccountHolderType || {});
var AccountStatus = /* @__PURE__ */ ((AccountStatus2) => {
  AccountStatus2["Active"] = "active";
  AccountStatus2["Inactive"] = "inactive";
  AccountStatus2["Invited"] = "invited";
  return AccountStatus2;
})(AccountStatus || {});
var AggregationType = /* @__PURE__ */ ((AggregationType2) => {
  AggregationType2["Groups"] = "GROUPS";
  AggregationType2["Nations"] = "NATIONS";
  AggregationType2["Recruits"] = "RECRUITS";
  AggregationType2["Users"] = "USERS";
  return AggregationType2;
})(AggregationType || {});
var AnalyticScopeType = /* @__PURE__ */ ((AnalyticScopeType2) => {
  AnalyticScopeType2["Group"] = "GROUP";
  AnalyticScopeType2["PledgeKey"] = "PLEDGE_KEY";
  return AnalyticScopeType2;
})(AnalyticScopeType || {});
var ApplicationStatus = /* @__PURE__ */ ((ApplicationStatus2) => {
  ApplicationStatus2["Accepted"] = "ACCEPTED";
  ApplicationStatus2["Cancelled"] = "CANCELLED";
  ApplicationStatus2["Closed"] = "CLOSED";
  ApplicationStatus2["Open"] = "OPEN";
  ApplicationStatus2["Rejected"] = "REJECTED";
  return ApplicationStatus2;
})(ApplicationStatus || {});
var ApprovalType = /* @__PURE__ */ ((ApprovalType2) => {
  ApprovalType2["Auto"] = "AUTO";
  ApprovalType2["Manual"] = "MANUAL";
  ApprovalType2["Skip"] = "SKIP";
  return ApprovalType2;
})(ApprovalType || {});
var BulkCardAction = /* @__PURE__ */ ((BulkCardAction2) => {
  BulkCardAction2["ResetChildrenSettings"] = "RESET_CHILDREN_SETTINGS";
  return BulkCardAction2;
})(BulkCardAction || {});
var CardActions = /* @__PURE__ */ ((CardActions2) => {
  CardActions2["AdminJoin"] = "adminJoin";
  CardActions2["CancelJoinRequest"] = "cancelJoinRequest";
  CardActions2["ExitMembership"] = "exitMembership";
  CardActions2["Follow"] = "follow";
  CardActions2["MemberJoin"] = "memberJoin";
  CardActions2["OpenJoin"] = "openJoin";
  CardActions2["Subscribe"] = "subscribe";
  CardActions2["Unfollow"] = "unfollow";
  CardActions2["Unsubscribe"] = "unsubscribe";
  return CardActions2;
})(CardActions || {});
var CardEngagementType = /* @__PURE__ */ ((CardEngagementType2) => {
  CardEngagementType2["Bookmark"] = "bookmark";
  CardEngagementType2["Comment"] = "comment";
  CardEngagementType2["Follow"] = "follow";
  CardEngagementType2["Heart"] = "heart";
  CardEngagementType2["Share"] = "share";
  return CardEngagementType2;
})(CardEngagementType || {});
var CardPostType = /* @__PURE__ */ ((CardPostType2) => {
  CardPostType2["Blog"] = "blog";
  CardPostType2["Book"] = "book";
  CardPostType2["Channel"] = "channel";
  CardPostType2["Chapter"] = "chapter";
  CardPostType2["Collection"] = "collection";
  CardPostType2["Course"] = "course";
  CardPostType2["Creation"] = "creation";
  CardPostType2["CustomFieldsSummary"] = "custom_fields_summary";
  CardPostType2["Dialog"] = "dialog";
  CardPostType2["Document"] = "document";
  CardPostType2["Dynamic"] = "dynamic";
  CardPostType2["Event"] = "event";
  CardPostType2["Formspec"] = "formspec";
  CardPostType2["Group"] = "group";
  CardPostType2["Header"] = "header";
  CardPostType2["Image"] = "image";
  CardPostType2["ImageSquare"] = "image_square";
  CardPostType2["Library"] = "library";
  CardPostType2["Link"] = "link";
  CardPostType2["Linktile"] = "linktile";
  CardPostType2["Map"] = "map";
  CardPostType2["Metachannel"] = "metachannel";
  CardPostType2["Mobilization"] = "mobilization";
  CardPostType2["Mobilize"] = "mobilize";
  CardPostType2["Pledge"] = "pledge";
  CardPostType2["Position"] = "position";
  CardPostType2["Postbox"] = "postbox";
  CardPostType2["Posting"] = "posting";
  CardPostType2["Profile"] = "profile";
  CardPostType2["RainbowRoad"] = "rainbow_road";
  CardPostType2["Research"] = "research";
  CardPostType2["Shelf"] = "shelf";
  CardPostType2["Story"] = "story";
  CardPostType2["Summary"] = "summary";
  CardPostType2["Survey"] = "survey";
  CardPostType2["Text"] = "text";
  CardPostType2["Video"] = "video";
  CardPostType2["Votegallery"] = "votegallery";
  return CardPostType2;
})(CardPostType || {});
var CardVoteType = /* @__PURE__ */ ((CardVoteType2) => {
  CardVoteType2["BallotMeasure"] = "BALLOT_MEASURE";
  CardVoteType2["Quality"] = "QUALITY";
  CardVoteType2["Relevance"] = "RELEVANCE";
  CardVoteType2["Sentiment"] = "SENTIMENT";
  return CardVoteType2;
})(CardVoteType || {});
var Collection = /* @__PURE__ */ ((Collection2) => {
  Collection2["Application"] = "APPLICATION";
  Collection2["Comment"] = "COMMENT";
  Collection2["ContentCard"] = "CONTENT_CARD";
  Collection2["Group"] = "GROUP";
  Collection2["Message"] = "MESSAGE";
  Collection2["MessageThread"] = "MESSAGE_THREAD";
  Collection2["User"] = "USER";
  return Collection2;
})(Collection || {});
var CommunityTypes = /* @__PURE__ */ ((CommunityTypes2) => {
  CommunityTypes2["AllPeople"] = "all_people";
  CommunityTypes2["Connections"] = "connections";
  CommunityTypes2["Followers"] = "followers";
  CommunityTypes2["Following"] = "following";
  CommunityTypes2["SharedGroups"] = "shared_groups";
  return CommunityTypes2;
})(CommunityTypes || {});
var ComparatorType = /* @__PURE__ */ ((ComparatorType2) => {
  ComparatorType2["MultiAndPos"] = "MULTI_AND_POS";
  ComparatorType2["MultiOrPos"] = "MULTI_OR_POS";
  ComparatorType2["None"] = "NONE";
  ComparatorType2["ProximityFar"] = "PROXIMITY_FAR";
  ComparatorType2["ProximityNear"] = "PROXIMITY_NEAR";
  ComparatorType2["RangePos"] = "RANGE_POS";
  return ComparatorType2;
})(ComparatorType || {});
var ConnectionAction = /* @__PURE__ */ ((ConnectionAction2) => {
  ConnectionAction2["AcceptConnection"] = "acceptConnection";
  ConnectionAction2["CancelRequestConnection"] = "cancelRequestConnection";
  ConnectionAction2["Disconnect"] = "disconnect";
  ConnectionAction2["RejectConnection"] = "rejectConnection";
  ConnectionAction2["RequestConnection"] = "requestConnection";
  return ConnectionAction2;
})(ConnectionAction || {});
var ConnectionStatus = /* @__PURE__ */ ((ConnectionStatus2) => {
  ConnectionStatus2["Connected"] = "connected";
  ConnectionStatus2["Disconnected"] = "disconnected";
  ConnectionStatus2["IsLoggedInUser"] = "isLoggedInUser";
  ConnectionStatus2["Pending"] = "pending";
  ConnectionStatus2["Rejected"] = "rejected";
  ConnectionStatus2["Requested"] = "requested";
  return ConnectionStatus2;
})(ConnectionStatus || {});
var CouponType = /* @__PURE__ */ ((CouponType2) => {
  CouponType2["Default"] = "default";
  return CouponType2;
})(CouponType || {});
var CourseProgressType = /* @__PURE__ */ ((CourseProgressType2) => {
  CourseProgressType2["LevelComplete"] = "level_complete";
  CourseProgressType2["StepComplete"] = "step_complete";
  return CourseProgressType2;
})(CourseProgressType || {});
var CustomSignupFieldTypes = /* @__PURE__ */ ((CustomSignupFieldTypes2) => {
  CustomSignupFieldTypes2["ChooseRole"] = "choose_role";
  CustomSignupFieldTypes2["ConsentBox"] = "consent_box";
  CustomSignupFieldTypes2["Description"] = "description";
  CustomSignupFieldTypes2["Dropdown"] = "dropdown";
  CustomSignupFieldTypes2["JoinGroup"] = "joinGroup";
  CustomSignupFieldTypes2["Numberslider"] = "numberslider";
  CustomSignupFieldTypes2["Paragraph"] = "paragraph";
  CustomSignupFieldTypes2["ProfileLocation"] = "profile_location";
  CustomSignupFieldTypes2["ProfileOrganization"] = "profile_organization";
  CustomSignupFieldTypes2["Shortanswer"] = "shortanswer";
  CustomSignupFieldTypes2["Signature"] = "signature";
  CustomSignupFieldTypes2["UploadFile"] = "uploadFile";
  return CustomSignupFieldTypes2;
})(CustomSignupFieldTypes || {});
var ElectionStatus = /* @__PURE__ */ ((ElectionStatus2) => {
  ElectionStatus2["ActiveTimed"] = "ACTIVE_TIMED";
  ElectionStatus2["ActiveUntimed"] = "ACTIVE_UNTIMED";
  ElectionStatus2["Closed"] = "CLOSED";
  ElectionStatus2["Created"] = "CREATED";
  ElectionStatus2["Scheduled"] = "SCHEDULED";
  return ElectionStatus2;
})(ElectionStatus || {});
var EngagementFilterType = /* @__PURE__ */ ((EngagementFilterType2) => {
  EngagementFilterType2["CustomField"] = "CUSTOM_FIELD";
  EngagementFilterType2["Deactivated"] = "DEACTIVATED";
  EngagementFilterType2["Group"] = "GROUP";
  EngagementFilterType2["Nation"] = "NATION";
  EngagementFilterType2["NewRecruit"] = "NEW_RECRUIT";
  EngagementFilterType2["PledgeCard"] = "PLEDGE_CARD";
  EngagementFilterType2["PledgeKey"] = "PLEDGE_KEY";
  EngagementFilterType2["Recruiter"] = "RECRUITER";
  EngagementFilterType2["RecruitType"] = "RECRUIT_TYPE";
  EngagementFilterType2["TimeWindow"] = "TIME_WINDOW";
  return EngagementFilterType2;
})(EngagementFilterType || {});
var EngagementSortOrder = /* @__PURE__ */ ((EngagementSortOrder2) => {
  EngagementSortOrder2["Asc"] = "ASC";
  EngagementSortOrder2["Desc"] = "DESC";
  return EngagementSortOrder2;
})(EngagementSortOrder || {});
var EngagementSortType = /* @__PURE__ */ ((EngagementSortType2) => {
  EngagementSortType2["Comments"] = "COMMENTS";
  EngagementSortType2["CreateDate"] = "CREATE_DATE";
  EngagementSortType2["JoinDate"] = "JOIN_DATE";
  EngagementSortType2["Name"] = "NAME";
  EngagementSortType2["Pledges"] = "PLEDGES";
  EngagementSortType2["Posts"] = "POSTS";
  EngagementSortType2["RecruitDate"] = "RECRUIT_DATE";
  EngagementSortType2["Votes"] = "VOTES";
  return EngagementSortType2;
})(EngagementSortType || {});
var EngagementType = /* @__PURE__ */ ((EngagementType2) => {
  EngagementType2["Comments"] = "COMMENTS";
  EngagementType2["Pledges"] = "PLEDGES";
  EngagementType2["Posts"] = "POSTS";
  EngagementType2["Votes"] = "VOTES";
  return EngagementType2;
})(EngagementType || {});
var EventAction = /* @__PURE__ */ ((EventAction2) => {
  EventAction2["Create"] = "CREATE";
  EventAction2["Delete"] = "DELETE";
  EventAction2["Update"] = "UPDATE";
  EventAction2["AcceptConnection"] = "acceptConnection";
  EventAction2["AdminAnnouncement"] = "adminAnnouncement";
  EventAction2["ApplicationAccepted"] = "applicationAccepted";
  EventAction2["ApplicationClosed"] = "applicationClosed";
  EventAction2["ApplicationRejected"] = "applicationRejected";
  EventAction2["AttachedCardToChapter"] = "attachedCardToChapter";
  EventAction2["BroadcastSurvey"] = "broadcastSurvey";
  EventAction2["CancelRequestConnection"] = "cancelRequestConnection";
  EventAction2["CardAccessRequest"] = "cardAccessRequest";
  EventAction2["CardContentAutoModerated"] = "cardContentAutoModerated";
  EventAction2["CardContentModeratedAccepted"] = "cardContentModeratedAccepted";
  EventAction2["CardContentModeratedRejected"] = "cardContentModeratedRejected";
  EventAction2["DashfeedPushed"] = "dashfeedPushed";
  EventAction2["Disconnect"] = "disconnect";
  EventAction2["DownloadLausanneSurveyPdf"] = "downloadLausanneSurveyPdf";
  EventAction2["ElectionEnd"] = "electionEnd";
  EventAction2["ElectionReminder"] = "electionReminder";
  EventAction2["ElectionStart"] = "electionStart";
  EventAction2["EventCanceled"] = "eventCanceled";
  EventAction2["GroupAccessApproved"] = "groupAccessApproved";
  EventAction2["GroupAccessRequest"] = "groupAccessRequest";
  EventAction2["GroupAdminPromoted"] = "groupAdminPromoted";
  EventAction2["GroupConnectedAccountReady"] = "groupConnectedAccountReady";
  EventAction2["GroupContentAutoModerated"] = "groupContentAutoModerated";
  EventAction2["GroupContentModeratedAccepted"] = "groupContentModeratedAccepted";
  EventAction2["GroupContentModeratedRejected"] = "groupContentModeratedRejected";
  EventAction2["GroupInviteAccepted"] = "groupInviteAccepted";
  EventAction2["GroupInviteRequest"] = "groupInviteRequest";
  EventAction2["GroupPreAuthPaymentRequest"] = "groupPreAuthPaymentRequest";
  EventAction2["GroupPreAuthPaymentRequestApproved"] = "groupPreAuthPaymentRequestApproved";
  EventAction2["GroupSpeakerInvite"] = "groupSpeakerInvite";
  EventAction2["MissedPledgeCommitment"] = "missedPledgeCommitment";
  EventAction2["MobilizationComplete"] = "mobilizationComplete";
  EventAction2["MobilizationEffortComplete"] = "mobilizationEffortComplete";
  EventAction2["NewApplication"] = "newApplication";
  EventAction2["NewMessage"] = "newMessage";
  EventAction2["NotifyGroupsOfTarget"] = "notifyGroupsOfTarget";
  EventAction2["PostingClosed"] = "postingClosed";
  EventAction2["RejectConnection"] = "rejectConnection";
  EventAction2["RequestConnection"] = "requestConnection";
  EventAction2["RoleAdminPromotion"] = "roleAdminPromotion";
  EventAction2["SubscriptionDeleted"] = "subscriptionDeleted";
  EventAction2["SubscriptionPriceChange"] = "subscriptionPriceChange";
  EventAction2["TicketTransfer"] = "ticketTransfer";
  EventAction2["UnreadMessagesNudge"] = "unreadMessagesNudge";
  EventAction2["VoteAdded"] = "voteAdded";
  return EventAction2;
})(EventAction || {});
var EventRsvp = /* @__PURE__ */ ((EventRsvp2) => {
  EventRsvp2["Going"] = "GOING";
  EventRsvp2["Interested"] = "INTERESTED";
  EventRsvp2["MaybeAttending"] = "MAYBE_ATTENDING";
  EventRsvp2["NotAttending"] = "NOT_ATTENDING";
  EventRsvp2["NotGoing"] = "NOT_GOING";
  EventRsvp2["NoResponse"] = "NO_RESPONSE";
  EventRsvp2["YesAttending"] = "YES_ATTENDING";
  return EventRsvp2;
})(EventRsvp || {});
var FilterType = /* @__PURE__ */ ((FilterType2) => {
  FilterType2["Archived"] = "archived";
  FilterType2["Az"] = "az";
  FilterType2["Custom"] = "custom";
  FilterType2["Leaderboard"] = "leaderboard";
  FilterType2["Live"] = "live";
  FilterType2["OldestFirst"] = "oldestFirst";
  FilterType2["Ordered"] = "ordered";
  FilterType2["Past"] = "past";
  FilterType2["Top"] = "top";
  FilterType2["Upcoming"] = "upcoming";
  FilterType2["Vote"] = "vote";
  return FilterType2;
})(FilterType || {});
var FormSpecificationType = /* @__PURE__ */ ((FormSpecificationType2) => {
  FormSpecificationType2["Posting"] = "POSTING";
  FormSpecificationType2["Standard"] = "STANDARD";
  return FormSpecificationType2;
})(FormSpecificationType || {});
var GroupActions = /* @__PURE__ */ ((GroupActions2) => {
  GroupActions2["AcceptInviteGroup"] = "acceptInviteGroup";
  GroupActions2["CancelPreAuthPurchase"] = "cancelPreAuthPurchase";
  GroupActions2["CancelRequestJoinGroup"] = "cancelRequestJoinGroup";
  GroupActions2["EditProducts"] = "editProducts";
  GroupActions2["ExpressInterest"] = "expressInterest";
  GroupActions2["JoinGroup"] = "joinGroup";
  GroupActions2["LeaveGroup"] = "leaveGroup";
  GroupActions2["LoggedOutExpressInterest"] = "loggedOutExpressInterest";
  GroupActions2["LoggedOutPurchaseAccess"] = "loggedOutPurchaseAccess";
  GroupActions2["LoggedOutRsvp"] = "loggedOutRSVP";
  GroupActions2["PurchaseAccess"] = "purchaseAccess";
  GroupActions2["PurchaseParentAccess"] = "purchaseParentAccess";
  GroupActions2["ReactivateSubscription"] = "reactivateSubscription";
  GroupActions2["RequestJoinGroup"] = "requestJoinGroup";
  GroupActions2["RequestToPurchaseAccess"] = "requestToPurchaseAccess";
  GroupActions2["RespondNotGoing"] = "respondNotGoing";
  GroupActions2["ViewPurchasedProducts"] = "viewPurchasedProducts";
  return GroupActions2;
})(GroupActions || {});
var GroupRole = /* @__PURE__ */ ((GroupRole2) => {
  GroupRole2["Invited"] = "invited";
  GroupRole2["Member"] = "member";
  GroupRole2["None"] = "none";
  GroupRole2["Owner"] = "owner";
  GroupRole2["Requested"] = "requested";
  return GroupRole2;
})(GroupRole || {});
var GroupSubtype = /* @__PURE__ */ ((GroupSubtype2) => {
  GroupSubtype2["Event"] = "event";
  GroupSubtype2["General"] = "general";
  GroupSubtype2["Organization"] = "organization";
  return GroupSubtype2;
})(GroupSubtype || {});
var LabelType = /* @__PURE__ */ ((LabelType2) => {
  LabelType2["Bubble"] = "BUBBLE";
  LabelType2["Plain"] = "PLAIN";
  return LabelType2;
})(LabelType || {});
var LocationType = /* @__PURE__ */ ((LocationType2) => {
  LocationType2["InPerson"] = "in_person";
  LocationType2["Virtual"] = "virtual";
  LocationType2["VirtualAndInPerson"] = "virtual_and_in_person";
  return LocationType2;
})(LocationType || {});
var MembershipStyle = /* @__PURE__ */ ((MembershipStyle2) => {
  MembershipStyle2["Follow"] = "follow";
  MembershipStyle2["Friends"] = "friends";
  MembershipStyle2["Grouped"] = "grouped";
  return MembershipStyle2;
})(MembershipStyle || {});
var MessageThreadType = /* @__PURE__ */ ((MessageThreadType2) => {
  MessageThreadType2["DirectMessage"] = "DIRECT_MESSAGE";
  MessageThreadType2["MultiUserChat"] = "MULTI_USER_CHAT";
  MessageThreadType2["OfficialGroupAdminChat"] = "OFFICIAL_GROUP_ADMIN_CHAT";
  MessageThreadType2["OfficialGroupChat"] = "OFFICIAL_GROUP_CHAT";
  return MessageThreadType2;
})(MessageThreadType || {});
var MobilizationStatus = /* @__PURE__ */ ((MobilizationStatus2) => {
  MobilizationStatus2["Complete"] = "COMPLETE";
  MobilizationStatus2["InProgress"] = "IN_PROGRESS";
  return MobilizationStatus2;
})(MobilizationStatus || {});
var MobilizationType = /* @__PURE__ */ ((MobilizationType2) => {
  MobilizationType2["Pledge"] = "PLEDGE";
  MobilizationType2["Posting"] = "POSTING";
  MobilizationType2["Task"] = "TASK";
  return MobilizationType2;
})(MobilizationType || {});
var NewsfeedFilter = /* @__PURE__ */ ((NewsfeedFilter2) => {
  NewsfeedFilter2["ForYou"] = "FOR_YOU";
  NewsfeedFilter2["Recent"] = "RECENT";
  return NewsfeedFilter2;
})(NewsfeedFilter || {});
var NotificationChannelType = /* @__PURE__ */ ((NotificationChannelType2) => {
  NotificationChannelType2["Email"] = "EMAIL";
  NotificationChannelType2["Pubsub"] = "PUBSUB";
  NotificationChannelType2["Push"] = "PUSH";
  return NotificationChannelType2;
})(NotificationChannelType || {});
var NotificationLevel = /* @__PURE__ */ ((NotificationLevel2) => {
  NotificationLevel2["Mention"] = "MENTION";
  NotificationLevel2["Off"] = "OFF";
  NotificationLevel2["On"] = "ON";
  return NotificationLevel2;
})(NotificationLevel || {});
var NotificationType = /* @__PURE__ */ ((NotificationType2) => {
  NotificationType2["Default"] = "DEFAULT";
  NotificationType2["Mention"] = "MENTION";
  return NotificationType2;
})(NotificationType || {});
var OrderLocation = /* @__PURE__ */ ((OrderLocation2) => {
  OrderLocation2["InPerson"] = "inPerson";
  OrderLocation2["Online"] = "online";
  return OrderLocation2;
})(OrderLocation || {});
var PaymentType = /* @__PURE__ */ ((PaymentType2) => {
  PaymentType2["Cash"] = "cash";
  PaymentType2["CreditCard"] = "creditCard";
  PaymentType2["NotApplicable"] = "notApplicable";
  return PaymentType2;
})(PaymentType || {});
var PositionType = /* @__PURE__ */ ((PositionType2) => {
  PositionType2["Post"] = "post";
  PositionType2["Pre"] = "pre";
  return PositionType2;
})(PositionType || {});
var PostingStatus = /* @__PURE__ */ ((PostingStatus2) => {
  PostingStatus2["Cancelled"] = "CANCELLED";
  PostingStatus2["Closed"] = "CLOSED";
  PostingStatus2["Open"] = "OPEN";
  return PostingStatus2;
})(PostingStatus || {});
var PostingType = /* @__PURE__ */ ((PostingType2) => {
  PostingType2["Job"] = "JOB";
  PostingType2["Volunteer"] = "VOLUNTEER";
  return PostingType2;
})(PostingType || {});
var RrCircleSize = /* @__PURE__ */ ((RrCircleSize2) => {
  RrCircleSize2["Large"] = "large";
  RrCircleSize2["Medium"] = "medium";
  RrCircleSize2["Small"] = "small";
  return RrCircleSize2;
})(RrCircleSize || {});
var RrFont = /* @__PURE__ */ ((RrFont2) => {
  RrFont2["Default"] = "default";
  RrFont2["Lego"] = "lego";
  return RrFont2;
})(RrFont || {});
var RrLineStyle = /* @__PURE__ */ ((RrLineStyle2) => {
  RrLineStyle2["Dashed"] = "dashed";
  RrLineStyle2["None"] = "none";
  RrLineStyle2["Solid"] = "solid";
  return RrLineStyle2;
})(RrLineStyle || {});
var RecommendationManager = /* @__PURE__ */ ((RecommendationManager2) => {
  RecommendationManager2["Group"] = "group";
  RecommendationManager2["Library"] = "library";
  RecommendationManager2["Platform"] = "platform";
  RecommendationManager2["Posting"] = "posting";
  return RecommendationManager2;
})(RecommendationManager || {});
var RecommendationType = /* @__PURE__ */ ((RecommendationType2) => {
  RecommendationType2["ContentCard"] = "CONTENT_CARD";
  RecommendationType2["Group"] = "GROUP";
  RecommendationType2["User"] = "USER";
  return RecommendationType2;
})(RecommendationType || {});
var RecruitType = /* @__PURE__ */ ((RecruitType2) => {
  RecruitType2["Existing"] = "EXISTING";
  RecruitType2["New"] = "NEW";
  RecruitType2["None"] = "NONE";
  RecruitType2["Potential"] = "POTENTIAL";
  return RecruitType2;
})(RecruitType || {});
var ReferenceType = /* @__PURE__ */ ((ReferenceType2) => {
  ReferenceType2["Book"] = "book";
  ReferenceType2["Chapter"] = "chapter";
  ReferenceType2["Group"] = "group";
  ReferenceType2["Library"] = "library";
  ReferenceType2["Prompt"] = "prompt";
  ReferenceType2["Shelf"] = "shelf";
  ReferenceType2["User"] = "user";
  return ReferenceType2;
})(ReferenceType || {});
var ReportingType = /* @__PURE__ */ ((ReportingType2) => {
  ReportingType2["AddNote"] = "ADD_NOTE";
  ReportingType2["Done"] = "DONE";
  ReportingType2["RegisterUser"] = "REGISTER_USER";
  ReportingType2["UploadImage"] = "UPLOAD_IMAGE";
  return ReportingType2;
})(ReportingType || {});
var Resource = /* @__PURE__ */ ((Resource2) => {
  Resource2["Books"] = "Books";
  Resource2["Crayons"] = "Crayons";
  Resource2["Flowers"] = "Flowers";
  Resource2["Hearts"] = "Hearts";
  Resource2["Wood"] = "Wood";
  return Resource2;
})(Resource || {});
var Role = /* @__PURE__ */ ((Role2) => {
  Role2["Admin"] = "admin";
  Role2["Answer"] = "answer";
  Role2["Manage"] = "manage";
  Role2["None"] = "none";
  Role2["Speaker"] = "speaker";
  Role2["View"] = "view";
  Role2["Vote"] = "vote";
  return Role2;
})(Role || {});
var SearchFilterType = /* @__PURE__ */ ((SearchFilterType2) => {
  SearchFilterType2["Chapter"] = "CHAPTER";
  SearchFilterType2["Collection"] = "COLLECTION";
  SearchFilterType2["Comment"] = "COMMENT";
  SearchFilterType2["Group"] = "GROUP";
  SearchFilterType2["Message"] = "MESSAGE";
  SearchFilterType2["Post"] = "POST";
  SearchFilterType2["Prompt"] = "PROMPT";
  SearchFilterType2["User"] = "USER";
  return SearchFilterType2;
})(SearchFilterType || {});
var SearchScopeType = /* @__PURE__ */ ((SearchScopeType2) => {
  SearchScopeType2["Contentcard"] = "CONTENTCARD";
  SearchScopeType2["Group"] = "GROUP";
  return SearchScopeType2;
})(SearchScopeType || {});
var SocialActions = /* @__PURE__ */ ((SocialActions2) => {
  SocialActions2["AcceptConnection"] = "acceptConnection";
  SocialActions2["CancelRequestConnection"] = "cancelRequestConnection";
  SocialActions2["Disconnect"] = "disconnect";
  SocialActions2["Follow"] = "follow";
  SocialActions2["Message"] = "message";
  SocialActions2["RejectConnection"] = "rejectConnection";
  SocialActions2["RequestConnection"] = "requestConnection";
  SocialActions2["Unfollow"] = "unfollow";
  return SocialActions2;
})(SocialActions || {});
var StorageType = /* @__PURE__ */ ((StorageType2) => {
  StorageType2["Ceramic"] = "CERAMIC";
  StorageType2["Mongodb"] = "MONGODB";
  StorageType2["None"] = "NONE";
  return StorageType2;
})(StorageType || {});
var StripeInvoiceStatus = /* @__PURE__ */ ((StripeInvoiceStatus2) => {
  StripeInvoiceStatus2["Draft"] = "draft";
  StripeInvoiceStatus2["Open"] = "open";
  StripeInvoiceStatus2["Paid"] = "paid";
  StripeInvoiceStatus2["Uncollectible"] = "uncollectible";
  StripeInvoiceStatus2["Void"] = "void";
  return StripeInvoiceStatus2;
})(StripeInvoiceStatus || {});
var StripeSubscriptionStatus = /* @__PURE__ */ ((StripeSubscriptionStatus2) => {
  StripeSubscriptionStatus2["Active"] = "active";
  StripeSubscriptionStatus2["Canceled"] = "canceled";
  StripeSubscriptionStatus2["Incomplete"] = "incomplete";
  StripeSubscriptionStatus2["IncompleteExpired"] = "incomplete_expired";
  StripeSubscriptionStatus2["PastDue"] = "past_due";
  StripeSubscriptionStatus2["Trialing"] = "trialing";
  StripeSubscriptionStatus2["Unpaid"] = "unpaid";
  return StripeSubscriptionStatus2;
})(StripeSubscriptionStatus || {});
var SubscriptionLevel = /* @__PURE__ */ ((SubscriptionLevel2) => {
  SubscriptionLevel2["WlBasicMonthly"] = "WL_BASIC_MONTHLY";
  SubscriptionLevel2["WlBasicYearly"] = "WL_BASIC_YEARLY";
  SubscriptionLevel2["WlProMonthly"] = "WL_PRO_MONTHLY";
  SubscriptionLevel2["WlProYearly"] = "WL_PRO_YEARLY";
  SubscriptionLevel2["WlUnlimitedMonthly"] = "WL_UNLIMITED_MONTHLY";
  SubscriptionLevel2["WlUnlimitedYearly"] = "WL_UNLIMITED_YEARLY";
  return SubscriptionLevel2;
})(SubscriptionLevel || {});
var SurveyQuestionSubType = /* @__PURE__ */ ((SurveyQuestionSubType2) => {
  SurveyQuestionSubType2["Emotional"] = "Emotional";
  SurveyQuestionSubType2["Image"] = "Image";
  SurveyQuestionSubType2["LongAnswer"] = "LongAnswer";
  SurveyQuestionSubType2["Numeric"] = "Numeric";
  SurveyQuestionSubType2["ShortAnswer"] = "ShortAnswer";
  SurveyQuestionSubType2["Text"] = "Text";
  SurveyQuestionSubType2["ThreeBoxAnswer"] = "ThreeBoxAnswer";
  return SurveyQuestionSubType2;
})(SurveyQuestionSubType || {});
var SurveyQuestionType = /* @__PURE__ */ ((SurveyQuestionType2) => {
  SurveyQuestionType2["Image"] = "Image";
  SurveyQuestionType2["ImageOrText"] = "ImageOrText";
  SurveyQuestionType2["MultipleChoice"] = "MultipleChoice";
  SurveyQuestionType2["Slider"] = "Slider";
  SurveyQuestionType2["Text"] = "Text";
  return SurveyQuestionType2;
})(SurveyQuestionType || {});
var TicketCheckInStatusEnum = /* @__PURE__ */ ((TicketCheckInStatusEnum2) => {
  TicketCheckInStatusEnum2["CheckedIn"] = "checkedIn";
  TicketCheckInStatusEnum2["CheckingIn"] = "checkingIn";
  TicketCheckInStatusEnum2["Invalid"] = "invalid";
  return TicketCheckInStatusEnum2;
})(TicketCheckInStatusEnum || {});
var UpdateAction = /* @__PURE__ */ ((UpdateAction2) => {
  UpdateAction2["Add"] = "ADD";
  UpdateAction2["Delete"] = "DELETE";
  UpdateAction2["Update"] = "UPDATE";
  return UpdateAction2;
})(UpdateAction || {});
var UserType = /* @__PURE__ */ ((UserType2) => {
  UserType2["Child"] = "child";
  UserType2["Ephemeral"] = "ephemeral";
  UserType2["Group"] = "group";
  UserType2["Guardian"] = "guardian";
  UserType2["Normal"] = "normal";
  UserType2["Organization"] = "organization";
  UserType2["System"] = "system";
  return UserType2;
})(UserType || {});
var VerifierType = /* @__PURE__ */ ((VerifierType2) => {
  VerifierType2["Recruit"] = "RECRUIT";
  return VerifierType2;
})(VerifierType || {});
var ViewPermission = /* @__PURE__ */ ((ViewPermission2) => {
  ViewPermission2["Private"] = "private";
  ViewPermission2["Public"] = "public";
  ViewPermission2["Secret"] = "secret";
  ViewPermission2["Unset"] = "unset";
  return ViewPermission2;
})(ViewPermission || {});
var ViewType = /* @__PURE__ */ ((ViewType2) => {
  ViewType2["Fullview"] = "fullview";
  ViewType2["Listview"] = "listview";
  return ViewType2;
})(ViewType || {});
var Violation = /* @__PURE__ */ ((Violation2) => {
  Violation2["GroupPolicy"] = "GROUP_POLICY";
  Violation2["WelibraryPolicy"] = "WELIBRARY_POLICY";
  return Violation2;
})(Violation || {});
export {
  AccountHolderType,
  AccountStatus,
  AggregationType,
  AnalyticScopeType,
  ApplicationStatus,
  ApprovalType,
  BulkCardAction,
  CardActions,
  CardEngagementType,
  CardPostType,
  CardVoteType,
  Collection,
  CommunityTypes,
  ComparatorType,
  ConnectionAction,
  ConnectionStatus,
  CouponType,
  CourseProgressType,
  CustomSignupFieldTypes,
  ElectionStatus,
  EngagementFilterType,
  EngagementSortOrder,
  EngagementSortType,
  EngagementType,
  EventAction,
  EventRsvp,
  FilterType,
  FormSpecificationType,
  GroupActions,
  GroupRole,
  GroupSubtype,
  LabelType,
  LocationType,
  MembershipStyle,
  MessageThreadType,
  MobilizationStatus,
  MobilizationType,
  NewsfeedFilter,
  NotificationChannelType,
  NotificationLevel,
  NotificationType,
  OrderLocation,
  PaymentType,
  PositionType,
  PostingStatus,
  PostingType,
  RecommendationManager,
  RecommendationType,
  RecruitType,
  ReferenceType,
  ReportingType,
  Resource,
  Role,
  RrCircleSize,
  RrFont,
  RrLineStyle,
  SearchFilterType,
  SearchScopeType,
  SocialActions,
  StorageType,
  StripeInvoiceStatus,
  StripeSubscriptionStatus,
  SubscriptionLevel,
  SurveyQuestionSubType,
  SurveyQuestionType,
  TicketCheckInStatusEnum,
  UpdateAction,
  UserType,
  VerifierType,
  ViewPermission,
  ViewType,
  Violation
};
