import React from 'react';
import { Helmet } from 'react-helmet-async';

import { getCreationImageSimple } from '@helpers/creations/creation.helpers';
import { fixUrl } from '@helpers/images/images.helpers';

import { Maybify } from '@core/types/Utilities';
import { CreationState, ContentCard } from '@shared/welibrary-graphql/types';

import SSLogo from '@assets/lego/SSLogo.png';
import WoodIcon from '@assets/resources/wood.png';
import BooksIcon from '@assets/resources/books.png';
import FlowersIcon from '@assets/resources/flowers.png';
import HeartIcon from '@assets/resources/heart.png';
import CrayonsIcon from '@assets/resources/crayons.png';
import WoodBG from '@assets/lego/wood-bg.png';
import WoodCircleButton from '@assets/wood-button-circle.svg';
import CreationTempBG from '@assets/lego/quest/SS.quest.creations.page.bg3.png';
import QuestEndImg from '@assets/lego/quest/quest-end.png';

type LegoPrefetchesProps = {
    creationsState: Array<Maybify<CreationState> | null>;
    creations: Array<Maybify<ContentCard> | null>;
};

const LegoPrefetches: React.FC<LegoPrefetchesProps> = ({ creationsState, creations }) => {
    const inProgressCreationIds = creationsState.map(
        creationState => creationState?.creation?._id ?? ''
    );

    const remainingCreations = creations.filter(
        creation => !inProgressCreationIds.includes(creation?._id ?? '')
    );

    const creationBackgrounds = creations.map(creation => {
        if (!creation?.media?.thumb) return <></>;

        return (
            <link rel="prefetch" href={fixUrl(creation.media.thumb)} key={creation.media.thumb} />
        );
    });

    const inProgressCreationImages = creationsState.map(creationState => {
        const image = getCreationImageSimple(creationState);

        if (!image) return <></>;

        return <link rel="prefetch" href={fixUrl(image)} key={image} />;
    });

    const remainingCreationImages = remainingCreations.map(creation => {
        if (!creation?.creationItems?.silhouetteImage) return <></>;

        return (
            <link
                rel="prefetch"
                href={fixUrl(creation.creationItems.silhouetteImage)}
                key={creation.creationItems.silhouetteImage}
            />
        );
    });

    return (
        <Helmet>
            <link rel="prefetch" href={WoodIcon} />
            <link rel="prefetch" href={BooksIcon} />
            <link rel="prefetch" href={FlowersIcon} />
            <link rel="prefetch" href={HeartIcon} />
            <link rel="prefetch" href={CrayonsIcon} />
            <link rel="prefetch" href={CreationTempBG} />
            <link rel="prefetch" href={SSLogo} />
            <link rel="prefetch" href={QuestEndImg} />
            <link rel="prefetch" href={WoodBG} />
            <link rel="prefetch" href={WoodCircleButton} />

            {creationBackgrounds}
            {inProgressCreationImages}
            {remainingCreationImages}
        </Helmet>
    );
};

export default LegoPrefetches;
