import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    ROLE_ADMIN,
    ROLE_CONTRIBUTOR,
    ROLE_MANAGER,
    ROLE_VIEW,
    ROLE_VOTING,
} from '@core/utilities/constants/roles';

import EditScreenItemContainer from '@web/ui/components/generic/editscreen/EditScreenItemContainer';
import MemberManagementContainer from '@web/ui/components/card/darkforms/MemberManagementContainer';
import SendAdminNotificationContainer from '@web/ui/components/generic/editscreen/SendAdminNotificationContainer';

import GroupSummaryCard from '@web/ui/components/card/summarycard/GroupSummaryCard';

const ADMIN_TAB = 'Admins';

const localNamespace = 'imports.wlWeb.ui.components.card.darkForms.community';

export default ({ card, showAdminAnnouncement, showCustomRoles, handleSubmit }) => {
    const { t } = useTranslation();

    const [currentTab, setCurrentTab] = useState(ADMIN_TAB);

    const activeSubTab = 'w--tab-active fadein';
    const withCurrent = 'w--current';

    const { library_settings } = card;
    const customRoles = library_settings.customRoles ? library_settings.customRoles : [];

    let isControlledByGroup = false;
    let groupCard;
    if (card.author) {
        if (card.author.type === 'group') {
            groupCard = <GroupSummaryCard group={card.author} />;
            isControlledByGroup = true;
        }
    }

    let _cardType = card.type;

    if (card.type === 'shelf') {
        _cardType = t('common:imports.wlWeb.ui.components.group.groupDashboard.subscriptions');
    }

    if (card.type === 'book') {
        _cardType = t(`common:global.constants.cards.types.collection`);
    }

    if (card.type === 'chapter') {
        _cardType = t(`common:global.constants.cards.types.channel`);
    }

    return (
        <div
            data-w-tab="Community"
            className="edit-screen-tab-container w-tab-pane w--tab-active"
            style={{ paddingBottom: 0 }}
        >
            {/* Only Show shortCode edit / generate if card is a shelf. TODO: Make this a feature at each level. */}
            {/* card.type === CARD_SHELF &&
                <EditScreenItemContainer
                    title={'Member Signup Code'}
                    subtitle={`Share this code with your community. Users who sign up with this code will automatically be added as a member to ${card.title}.`}
                    >
                <EditScreenSignUpCode card={card}/>
                </EditScreenItemContainer>
            */}
            {isControlledByGroup && (
                <EditScreenItemContainer
                    title={t(`common:${localNamespace}.group_managed_title`, {
                        cardType: _cardType,
                    })}
                    subtitle={t(`common:${localNamespace}.all_group_owners_admin`)}
                    extraBottomPadding
                >
                    {groupCard}
                </EditScreenItemContainer>
            )}

            {showAdminAnnouncement && (
                <EditScreenItemContainer
                    title={t(`common:${localNamespace}.send_announcement`)}
                    extraBottomPadding
                >
                    <SendAdminNotificationContainer cardUrl={card.url} />
                </EditScreenItemContainer>
            )}

            <div className="w-tabs">
                <div className="edit-community-menu w-tab-menu">
                    <div
                        onClick={() => setCurrentTab(ADMIN_TAB)}
                        className={`edit-communitytab w-inline-block w-tab-link ${
                            currentTab === ADMIN_TAB ? withCurrent : ''
                        }`}
                    >
                        <div>{ADMIN_TAB}</div>
                    </div>
                    {/* 
                        <div onClick={() => setCurrentTab(MEMBERS_TAB)} className={`edit-communitytab w-inline-block w-tab-link ${currentTab === MEMBERS_TAB ? withCurrent : ''}`}>
                            <div>{MEMBERS_TAB}</div>
                        </div> 
                    */}
                    {/*
                        showLibraryTab &&
                        <div onClick={() => setCurrentTab(UNASSIGNED_TAB)} className={`edit-communitytab w-inline-block w-tab-link ${currentTab === UNASSIGNED_TAB ? withCurrent : ''}`}>
                            <div>{UNASSIGNED_TAB}</div>
                        </div>
                    */}
                </div>
                <div className="visible-overflow w-tab-content">
                    <div
                        className={`w-tab-pane ${
                            currentTab === ADMIN_TAB ? activeSubTab : 'fadeout'
                        }`}
                    >
                        <MemberManagementContainer
                            customRoleOptions={customRoles}
                            roles={[
                                ROLE_VIEW,
                                ROLE_VOTING,
                                ROLE_CONTRIBUTOR,
                                ROLE_MANAGER,
                                ROLE_ADMIN,
                            ]}
                            url={card.url}
                            showCustomRoles={showCustomRoles}
                        />
                    </div>
                    {/* 
                        <div className={`w-tab-pane ${currentTab === MEMBERS_TAB ? activeSubTab : 'fadeout'}`}>
                            <MemberManagementContainer
                            customRoleOptions={customRoles}
                            roles={[ROLE_VIEW]}
                            url={card.url}
                            showCustomRoles={showCustomRoles}
                        />
                        </div>
                            {showLibraryTab &&
                        <div className={`w-tab-pane ${currentTab === UNASSIGNED_TAB ? activeSubTab : 'fadeout'}`}>
                            <MemberManagementContainer
                                customRoleOptions={customRoles}
                                roles={[]}
                                url={card.url}
                                showCustomRoles={showCustomRoles}
                            />
                        </div>
                    } */}
                </div>
            </div>
            <div className="edit-footer">
                {/* <div onClick={props.handleClose} className="button callout-button inverse fadded w-button">Delete</div> */}
                <div
                    onClick={handleSubmit}
                    className="button callout-button w-button edit-screen-content"
                >
                    {t(`common:global.verbs.save`)}
                </div>
            </div>
        </div>
    );
};
