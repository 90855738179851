import * as dayjs from 'dayjs';
import moment from 'moment';

import { Capacitor } from '@capacitor/core';

export const GetPropertyValue = (obj, dataToRetrieve) => {
    return dataToRetrieve.split('.').reduce(function (o, k) {
        return o && o[k]; // get inner property if `o` is defined else get `o` and return
    }, obj); // set initial value as object
};

export const getQueryVariable = (variable, search) => {
    const query = search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
};

export const getTimeSinceLong = (publish, language) => {
    const today = new Date();
    const notificationDate = new Date(publish);
    const diffMs = today - notificationDate; // milliseconds between now & Christmas
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    // Short-stop solution for showing non-english dates correctly.
    // TODO: Update for full language support of 'days/minutes/seconds ago'
    if (language && !language.includes('en')) {
        return dayjs(publish).locale(language).format('ddd MMM D YYYY');
    }

    if (diffDays > 0) {
        if (diffDays === 1) return '1 day ago';

        let decipherDate = dateString => {
            let myDate = dayjs(dateString);
            // if(language) myDate.locale(language);
            return myDate.format('ddd MMM D YYYY');
        };

        return decipherDate(publish);
    }
    if (diffHrs > 0) {
        if (diffHrs === 1) return '1 hour ago';
        return diffHrs + ' hours ago';
    }
    if (diffMins > 0) {
        if (diffMins === 1) return '1 minute ago';
        return diffMins + ' minutes ago';
    }

    return 'Just now';
};

export const getTimeSince = (publish, justNow, showFullDate = false) => {
    const today = new Date();
    const notificationDate = new Date(publish);
    const diffMs = today - notificationDate; // milliseconds between now & Christmas
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffWeeks = Math.floor(diffDays / 7); // months
    const diffMonths = Math.floor(diffDays / 30); // months
    const diffYears = Math.floor(diffDays / 365); // months
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    const fullDate = moment(notificationDate).format('h:mmA • MMM D, YYYY'); // "3:04AM • Mar 8, 2022"

    if (diffYears > 0) {
        if (showFullDate) return fullDate;
        return `${diffYears}y`;
    }
    if (diffMonths > 0) {
        if (showFullDate) return fullDate;
        return `${diffMonths}mo`;
    }
    if (diffWeeks > 0) {
        if (showFullDate) return fullDate;
        return `${diffWeeks}w`;
    }
    if (diffDays > 0) {
        if (showFullDate && diffDays === 1) return 'yesterday';
        if (showFullDate && diffDays > 1) return fullDate;
        if (diffDays === 1) return '1d';
        return `${diffDays}d`;
    }
    if (diffHrs > 0) {
        if (diffHrs === 1) return '1h';
        return `${diffHrs}h`;
    }
    if (diffMins > 0) {
        if (diffMins === 1) return '1m';
        return `${diffMins}m`;
    }

    return justNow || 'Just now';
};

export const getDisplayableTotal = total => {
    if (total > 999999) {
        return `${total / 1000000}m`;
    } else if (total > 99999) {
        return `${total / 100000}k`;
    } else if (total) {
        return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '0';
};

export const getDisplayableChange = percent => {
    if (!percent) return `No change`;
    const hundredPercent = Math.round(percent * 100);
    return `${hundredPercent > 0 ? '+' : ''}${getDisplayableTotal(hundredPercent)}%`;
};

export const getStatsClass = percent => {
    if (!percent) return 'stats-no-change';
    if (percent < 0) return 'stats-down';
    if (percent > 0) return 'stats-up';
};

export const getChangeDelta = (oldValue, newValue) => {
    if (!newValue) return null;
    if (!oldValue || oldValue === 0) return newValue - 0;
    return (newValue - oldValue) / oldValue;
};

export const isMobile = () => Capacitor.isNativePlatform() || window?.innerWidth < 991;
