import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useModal from '@components/modals/hooks/useModal';

import ToastContext from '@components/generic/context/ToastContext';

import LegoGuardianWall from '@web/ui/components/verify/Lego/LegoGuardianWall';

import StylizedX from '@dsc/svgs/lego/StylizedX';

import { ModalTypes } from '@core/types/Modals';

const CustomLegoToastFooter: React.FC<{
    showVerify?: boolean;
    showLogout?: boolean;
    handleLogout?: () => void;
    shouldCloseModal?: boolean;
}> = ({
    showVerify = false,
    showLogout = false,
    handleLogout = () => {},
    shouldCloseModal = false,
}) => {
    const history = useHistory();
    const { newModal, closeAllModals, closeModal } = useModal({
        mobile: ModalTypes.FullScreen,
    });

    const { hideToast } = useContext(ToastContext);

    const handleVerificationRedirect = () => {
        if (shouldCloseModal) {
            closeModal();
        }
        hideToast();
        history.push('/ssl/signup?isEphemeral=true');
    };

    const handleShowGuardianWall = (isVerifying: boolean) => {
        hideToast();
        newModal(
            <LegoGuardianWall
                isEphemeral
                isVerifying={isVerifying}
                handleRedirect={() => {
                    handleVerificationRedirect();
                    closeAllModals();
                }}
            />,
            {
                className: 'no-top-padding no-bottom-padding no-notch, full-height full-screen',
                hideButton: true,
                customCloseButton: false,
            }
        );
    };

    return (
        <div className="lego-toast-footer">
            <div className="lego-toast-footer-btns">
                {showLogout && (
                    <button
                        onClick={handleLogout}
                        className="lego-toast-btn lego-toast-btn-logout"
                        type="button"
                    >
                        Logout
                    </button>
                )}
                {showVerify && (
                    <button
                        onClick={() => handleShowGuardianWall(true)}
                        className="lego-toast-btn lego-toast-btn-verify"
                        type="button"
                    >
                        Create Account
                    </button>
                )}
            </div>
            <button onClick={hideToast} className="lego-toast-btn-close" type="button">
                <StylizedX className="lego-toast-btn-close-icon" />
            </button>
        </div>
    );
};

export default CustomLegoToastFooter;
