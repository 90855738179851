import React, {CSSProperties, ReactNodeArray, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SkeletonList from '@web/ui/components/skeleton/SkeletonList';

const LOADERS = {
    //  'blob': 'https://uploads-ssl.webflow.com/5bc0a4fed7e6b515ab8f687b/5bd7a79f41aa445585b99c34_animated_loading__by__amiri.gif',
    blob_small: '/images/loading_blob_small.gif',
    blob: '/images/loading_blob.gif',
    orbit: '/images/loading_orbit.gif',
    bounce: '/images/loading_bounce.gif',
    book: '/images/book_preloader.gif',
    paperplane: '/images/loading/batmanrobin.gif',
    skeleton: '/images/loading/skeleton_loading.gif',
    chat_skelly: '/images/loading/Chat-skeleton-loading.gif',
} as const;

const SIZES = {
    'x-small': '35px',
    small: '75px',
    normal: '100px',
    large: '200px',
    'x-large': '250px',
    fill: '100%',
} as const;

const MIN_HEIGHT = {
    'x-small': '26px',
    small: '56px',
    normal: '75px',
    large: '150px',
    'x-large': '187px',
    fill: '100%',
} as const;

const localNamespace = 'imports.wlWeb.ui.components.generic.loading.loadingIndicator';

type LoadingIndicatorProps = {
    delay?: number;
    loading?: boolean;
    type?: keyof typeof LOADERS;
    size?: keyof typeof SIZES;
    repeat?: boolean;
    topPadding?: boolean;
    darkBackground?: boolean;
    count?: number;
};

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
    delay = 500,
    loading = false,
    type = 'blob',
    size = 'normal',
    repeat = false,
    topPadding = false,
    darkBackground = false,
    count,
}) => {
    const loaderUrl = LOADERS[type];
    const { t } = useTranslation();
    const [showLoader, setShowLoader] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowLoader(true);
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [loading]);

    if (type === 'skeleton') {
        if (showLoader) {
            return <SkeletonList count={count} />;
        }
        return null;
    }

    const loadingStyle: CSSProperties = {
        width: SIZES[size],
        height: '100%',
        minHeight: MIN_HEIGHT[size],
    };

    if (size === 'x-large') loadingStyle.marginTop = '10%';

    const containerStyle: CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
    };

    const loadingImages: ReactNodeArray = [];

    if (!repeat) {
        loadingImages.push(
            <img
                key="loading_default"
                src={loaderUrl}
                style={loadingStyle}
                alt={t(`common:${localNamespace}.images.alt`)}
            />
        );
    } else {
        containerStyle.flexDirection = 'column';
        containerStyle.justifyContent = 'center';
        if (size === 'fill') containerStyle.alignItems = 'left';
        else containerStyle.alignItems = 'center';
        containerStyle.alignContent = 'center';

        for (let x = 0; x < 5; x += 1) {
            loadingImages.push(
                <img
                    className="we-skeleton-loading"
                    key={`loading${x}`}
                    src={loaderUrl}
                    alt={t(`common:${localNamespace}.images.alt`)}
                />
            );
        }
    }

    const background = <div className="loader-background" />;
    if (showLoader) {
        return (
            <div
                className={repeat && topPadding ? 'we-skeleton-loading-container' : ''}
                style={containerStyle}
            >
                {loadingImages}
                {darkBackground && background}
            </div>
        );
    }
    return null;
};

export default LoadingIndicator;
