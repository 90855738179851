import React, { useRef, useState } from 'react';

import _ from 'lodash';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-loader.json';

import getLogger from '@core/logger';

const logger = getLogger(module);

// Vertical y baseline when fully refreshing. Most likely 0
const DEFAULT_ACTIVE_OFFSET = 0;
// Slight delay when refreshing to give the user feedback, esp. if data loads super speedy
const DEFAULT_REFRESH_DELAY = 500; // ms
// Amount user must swipe to initiate refresh. Higher = longer, lower = shorter
const DEFAULT_PULL_DOWN_LENGTH = 75;

export default (WrappedComponent, refetchRef, useRenderProp, customLottie) => {
    const PullDownToRefresh = props => {
        const [refreshing, setRefreshing] = useState(false);
        const [fetching, setFetching] = useState(false);
        const [touchStartValue, setTouchStartValue] = useState({ x: 0, y: 0 });
        const refreshLoader = useRef();
        const lottieAnimation = customLottie || LottieAnimation;

        const endRefresh = () => {
            // Reset animation to inactive state.
            // End user-trying-to-refresh
            // Fetching is over
            if (refreshLoader && refreshLoader.current) {
                refreshLoader.current.classList.remove('pulled-down');
            }

            setRefreshing(false);
            setFetching(false);
        };

        const handleRefreshing = _.debounce(overrideRefetch => {
            // If there is a ref passed with a refetch function...
            if (overrideRefetch) {
                try {
                    overrideRefetch()
                        .then(data => {
                            endRefresh(data);
                        })
                        .catch(err => {
                            logger.error(err);
                            endRefresh(err);
                        });
                } catch (e) {
                    logger.warn(e);
                    endRefresh(e);
                }
            } else if (props && props.refetch) {
                // TODO: Why does the refetch
                try {
                    props
                        .refetch()
                        .then(data => {
                            endRefresh(data);
                        })
                        .catch(err => {
                            logger.error(err);
                            endRefresh(err);
                        });
                } catch (e) {
                    logger.warn(e);
                    endRefresh(e);
                }
            } else if (refetchRef) {
                refetchRef.current.props
                    .refetch()
                    .then(data => {
                        // Success
                        endRefresh(data);
                    })
                    .catch(err => {
                        // Error
                        // TODO: Notify the user of failure to refetch
                        logger.error(err);
                        endRefresh(err);
                    });
            } else {
                // No refetch function... finish refresh anyways to end animation.
                // TODO:
                logger.warn(
                    'No refetch function passed into pull down to refresh... no data re-loaded.'
                );
                endRefresh();
            }
        }, DEFAULT_REFRESH_DELAY);

        const handleTouchMove = e => {
            const touchValueY = e.changedTouches[0].clientY;
            if (touchValueY < touchStartValue.y || !refreshing) return;
            if (touchValueY > touchStartValue.y) {
                if (refreshLoader && refreshLoader.current) {
                    refreshLoader.current.style.transition = 'transform linear';
                    const transformPosition = Math.min(
                        Math.max(0, touchValueY - touchStartValue.y),
                        DEFAULT_PULL_DOWN_LENGTH
                    );
                    refreshLoader.current.style.height = `${transformPosition}px`;
                }
            }
        };

        const handleTouchStart = e => {
            // Check if the user is at the top of the page.
            // If so, the user might be trying to refresh. i.e. refreshing = true
            // And, track the initial touch values so we can compare them for determining if a full refresh pull occurs
            if (
                refreshLoader &&
                refreshLoader?.current &&
                refreshLoader?.current?.getBoundingClientRect().y >= DEFAULT_ACTIVE_OFFSET &&
                !refreshing
            ) {
                setRefreshing(true);
                setTouchStartValue({
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY,
                });
                props.onRefreshStart?.();
            }
        };

        // Optionally, at the touch end, you may provide a different, overriding refetch function
        const handleTouchEnd = (e, overrideRefetch) => {
            const touchEndY = e.changedTouches[0].clientY;
            if (refreshing === true) {
                if (refreshLoader) {
                    if (refreshLoader.current) {
                        if (touchEndY - touchStartValue.y > DEFAULT_PULL_DOWN_LENGTH) {
                            refreshLoader.current.style.transition = 'height 0.5s ease-out';
                            refreshLoader.current.style.height = '';
                            refreshLoader.current.classList.add('pulled-down');
                            if (!fetching) {
                                handleRefreshing(overrideRefetch);
                            }
                            setFetching(true);
                        } else {
                            refreshLoader.current.style.transition = 'height 0.5s ease-out';
                            refreshLoader.current.style.height = '';
                            refreshLoader.current.classList.remove('pulled-down');
                        }
                    }
                }
            }
            setRefreshing(false);
        };

        const customStyle = { opacity: '1' };

        const renderPullDownToRefresh = () => {
            if (useRenderProp) {
                return (
                    <div className="pull-down-to-refresh-refresher" ref={refreshLoader}>
                        <LottieLoading
                            customStyle={customStyle}
                            height={75}
                            width={150}
                            lottieData={lottieAnimation}
                            isPaused={refreshing}
                        />
                    </div>
                );
            }
        };

        return (
            <>
                {!useRenderProp && (
                    <div className="pull-down-to-refresh-refresher" ref={refreshLoader}>
                        <LottieLoading
                            customStyle={customStyle}
                            height={75}
                            width={150}
                            lottieData={lottieAnimation}
                            isPaused={refreshing}
                        />
                    </div>
                )}
                <WrappedComponent
                    onTouchMove={handleTouchMove}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    renderPullDownToRefresh={renderPullDownToRefresh}
                    {...props} // eslint-disable-line react/jsx-props-no-spreading
                />
            </>
        );
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return React.forwardRef((props, ref) => <PullDownToRefresh {...props} forwardedRef={ref} />);
};
