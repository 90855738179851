import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import useModal from '@components/modals/hooks/useModal';

import ArrowBendDownRight from '@dsc/svgs/ArrowBendDownRight';

import {
    getRandomDefaultUserImage,
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import { convertCentsToDollars } from '@components/profile/stripe/stripeOrderHelpers';

import { getProductInstanceFrequencyForOwners } from '@components/group/groupdashboard/GroupMembers/groupMembersHelpers';

import { Group, ProductInstance } from '@shared/welibrary-graphql/types';

type ProductInstanceListItemProps = {
    ticket: ProductInstance;
    group: Group;
};

const ProductInstanceListItem: React.FC<ProductInstanceListItemProps> = ({ ticket, group }) => {
    const { closeModal } = useModal();

    const [showMoreDetails, setShowMoreDetails] = useState<boolean>(false);

    const orderTotal = convertCentsToDollars(0);
    const isEvent = group?.subtype === 'event';

    const [coverSrc, coverSrcSet] = getBackgroundImageSetForUrl(
        group?.profile?.coverPhoto || getRandomDefaultCoverImage(group?.profile?.full_name)
    );

    const ticketsOwned = [ticket]?.filter(t => t?.owner?._id === t?.purchaser?._id) ?? [];

    const ticketsTransferred = getProductInstanceFrequencyForOwners(
        [ticket]?.filter(t => t?.owner?._id !== t?.purchaser?._id) ?? []
    );

    const ticketsOwnershipList = (
        <div className="order-ticket-list" style={{ marginTop: '15px' }}>
            {ticketsOwned.length === 0 && (
                <div className="order-ticket-list-holder-wrap order-ticket-list-owner-wrap">
                    <div className="order-ticket-list-holder-img-wrap">
                        <img
                            src={
                                ticket?.purchaser?.profile?.picture?.length > 0
                                    ? ticket?.purchaser?.profile?.picture
                                    : getRandomDefaultUserImage()
                            }
                            alt="purchaser"
                        />
                    </div>
                    <div className="order-ticket-list-owner-details-wrap">
                        <div className="order-ticket-list-owner-details-wrap-user">
                            <h4>{ticket?.purchaser?.profile?.full_name}</h4>
                            <div className="order-ticket-list-owner-details-wrap-user-sub">
                                {ticket?.purchaser?.profile?.phone && (
                                    <>
                                        <h5>{ticket?.purchaser?.profile?.phone}</h5>
                                        <p>•</p>
                                    </>
                                )}
                                <h5>{ticket?.purchaser?.profile?.email}</h5>
                            </div>
                        </div>
                        <p className="order-ticket-list-owner-details-tickets">
                            Tickets: {ticketsOwned?.length ?? 0}
                        </p>
                    </div>
                </div>
            )}

            <div className="order-ticket-list-title">
                <h4>Ticket Holders</h4>
            </div>
            {ticketsOwned.length > 0 && (
                <div className="order-ticket-list-holder-wrap order-ticket-list-owner-wrap">
                    <div className="order-ticket-list-holder-img-wrap">
                        <img
                            src={
                                ticket?.purchaser?.profile?.picture?.length > 0
                                    ? ticket?.purchaser?.profile?.picture
                                    : getRandomDefaultUserImage()
                            }
                            alt="purchaser"
                        />
                    </div>
                    <div className="order-ticket-list-owner-details-wrap">
                        <div className="order-ticket-list-owner-details-wrap-user">
                            <h4>{ticket?.purchaser?.profile?.full_name}</h4>
                            <div className="order-ticket-list-owner-details-wrap-user-sub">
                                {ticket?.purchaser?.profile?.phone && (
                                    <>
                                        <h5>{ticket?.purchaser?.profile?.phone}</h5>
                                        <p>•</p>
                                    </>
                                )}
                                <h5>{ticket?.purchaser?.profile?.email}</h5>
                            </div>
                        </div>
                        <p className="order-ticket-list-owner-details-tickets">
                            Tickets: {ticketsOwned?.length ?? 0}
                        </p>
                    </div>
                </div>
            )}
            {ticketsTransferred?.map(t => (
                <div className="order-ticket-list-holder-wrap">
                    <div className="order-ticket-list-holder-img-wrap">
                        <img
                            src={
                                ticket?.owner?.profile?.picture?.length > 0
                                    ? ticket?.owner?.profile?.picture
                                    : getRandomDefaultUserImage()
                            }
                            alt="owner"
                        />
                    </div>
                    <div className="order-ticket-list-owner-details-wrap">
                        <div className="order-ticket-list-owner-details-wrap-user">
                            <h4>{ticket?.owner?.profile?.full_name}</h4>
                            <div className="order-ticket-list-owner-details-wrap-user-sub order-ticket-list-owner-details-wrap-user-sub-transfer">
                                <div className="flex flex-row justify-start gap-[0.5em]">
                                    {ticket?.owner?.profile?.phone && (
                                        <>
                                            <h5>{ticket?.owner?.profile?.phone}</h5>
                                            <p>•</p>
                                        </>
                                    )}
                                    <h5>{ticket?.owner?.profile?.email}</h5>
                                </div>
                                <div className="order-ticket-list-transfer-details">
                                    <ArrowBendDownRight /> Transferred from&nbsp;
                                    <p>{ticket?.purchaser?.profile?.full_name}</p>
                                </div>
                            </div>
                        </div>
                        <p className="order-ticket-list-owner-details-tickets">
                            Tickets: {t?.count ?? 0}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className="order-list-item">
            <div className="order-list-item-top-container">
                <div className="order-list-item-img-wrap">
                    <StyledBackgroundImage
                        disableLink
                        src={coverSrc}
                        srcSet={coverSrcSet}
                        className="order-list-item-cover"
                    />
                </div>
                <div className="order-list-item-details-wrap">
                    <div className="order-list-item-name-wrap">
                        <h5>{ticket?.product?.name}</h5>
                        <h6>{group?.profile?.full_name}</h6>
                    </div>
                    <div className="order-list-item-price-wrap">
                        <p>
                            <Link
                                to={`/u/${ticket?.owner?._id}`}
                                onClick={closeModal}
                                className="order-list-item-purchaser"
                            >
                                {ticket?.owner?.profile?.full_name}
                            </Link>
                            &nbsp;&bull;&nbsp;Quantity: 1
                        </p>
                        <h3>${orderTotal}</h3>
                    </div>
                    <div className="order-list-item-btn-more-wrap">
                        <button type="button" onClick={() => setShowMoreDetails(!showMoreDetails)}>
                            {showMoreDetails ? <p>Show less details</p> : <p>Show more details</p>}
                        </button>
                    </div>
                </div>
            </div>
            {showMoreDetails && <>{isEvent && ticketsOwnershipList}</>}
            <div className="order-list-item-bottom-container">
                <div className="order-list-item-bottom-wrap" />
            </div>
        </div>
    );
};

export default ProductInstanceListItem;
