import React, { useState } from 'react';
import { CustomSignupField } from '@shared/welibrary-graphql/types';
import GroupSelectItem from './GroupSelectItem';
import CaretDownIcon from '@dsc/svgs/CaretDownIcon';

type GroupSelectProps = {
    field: CustomSignupField;
    membershipGroupId: string;
};

const GroupSelect: React.FC<GroupSelectProps> = ({ field, membershipGroupId }) => {
    const [open, setOpen] = useState(false);

    const selectedGroups = field.groups ?? [];

    const groups = [...selectedGroups].sort((groupA, groupB) => {
        if (groupA.group?.currentUserIsMember && !groupB.group?.currentUserIsMember) return -1;
        if (!groupA.group?.currentUserIsMember && groupB.group?.currentUserIsMember) return 1;

        if (groupA.group?.currentUserIsMember && groupB.group?.currentUserIsMember) {
            return groupA.group.profile?.full_name > groupB.group.profile?.full_name ? 1 : -1;
        }

        return 0;
    });

    if (groups.length === 0) return <></>;

    return (
        <section>
            {field.prompt && <p>{field.prompt}</p>}
            <section className="mb-4 w-full rounded-[15px] border border-black bg-[--color-grayscale-input-background]">
                <button
                    type="button"
                    className={`flex w-full items-center justify-between px-[17px] py-[20px] text-black ${open ? 'border-b border-b-black' : ''
                        }`}
                    onClick={() => setOpen(!open)}
                >
                    <p className="font-poppins m-0 p-0 text-base text-black">Select</p>
                    <CaretDownIcon className={`transition-all ${open ? 'rotate-180' : ''}`} />
                </button>

                <ul
                    className={`grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] gap-[10px] overflow-y-auto transition-all ${open ? 'max-h-96 px-[17px] py-[20px]' : 'max-h-0 scale-0 opacity-0'
                        }`}
                >
                    {groups.map(group => (
                        <GroupSelectItem
                            groupId={group.id}
                            membershipGroupId={membershipGroupId}
                            skipApproval={!group.requireApproval}
                            key={group.id}
                        />
                    ))}
                </ul>
            </section>
        </section>
    );
};

export default GroupSelect;
