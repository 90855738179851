import React from 'react';
import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';

import TextInput from '@dsc/forms/textInputs/TextInput';
import ImageUpload from '@dsc/forms/customInputs/ImageUploadWithPicker';
import Button from '@dsc/forms/buttons/Button';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { getRandomDefaultThumbImage } from '@core/utilities/constants/defaults';

import { DialogPersonState } from '@core/types/Dialogs';

type DialogPersonFormProps = {
    state: DialogPersonState;
    onChange: (state: DialogPersonState) => void;
};

const DialogPersonForm: React.FC<DialogPersonFormProps> = ({ state, onChange }) => {
    const { t } = useTranslation();

    const [personState, setPersonState] = useImmer(state);

    const updateSlice = curriedStateSlice(setPersonState);

    const { closeModal } = useModal();

    const submit = () => {
        onChange(personState);
        closeModal();
    };
    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                submit();
            }}
            className="dialog-person-form"
        >
            <section>
                <ImageUpload
                    state={personState.image || getRandomDefaultThumbImage(personState.name)}
                    setState={updateSlice('image')}
                />

                <TextInput
                    value={personState.name}
                    onChange={updateSlice('name')}
                    label={t('common:name', 'Name')}
                />
            </section>

            <footer>
                <Button type="small" onClick={submit}>
                    Submit
                </Button>
                <Button type="small" onClick={closeModal} className="cancel">
                    Cancel
                </Button>
            </footer>
        </form>
    );
};

export default DialogPersonForm;
