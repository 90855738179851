import React from 'react';
import { Link } from 'react-router-dom';

type IntroContentProps = {
    declaration?: string;
    instruction?: string;
    buttonTitle?: string;
    buttonLink?: string;
    customClass?: string;
    type?: 'offline' | 'nocontent' | 'error' | 'dead-page';
    children?: React.ReactNode;
};

const IntroContent: React.FC<IntroContentProps> = ({
    declaration,
    instruction,
    buttonTitle,
    buttonLink,
    customClass,
    type,
    children,
}) => {
    let emptyStateImage = '';
    switch (type) {
        case 'offline':
        case 'nocontent':
            emptyStateImage = 'empty-state-image1';
            break;
        case 'error':
            emptyStateImage = 'empty-state-image2';
            break;
        case 'dead-page':
            emptyStateImage = 'dead-page-image';
            break;
        default:
            break;
    }

    const containerClass = customClass || 'empty-state-container full-screen';

    return (
        <div className={containerClass}>
            <div className="empty-state-middle">
                <div className="empty-state-top-portion">
                    <div className={emptyStateImage} />
                    <div className="empty-state-heading">{declaration}</div>
                </div>
                <div>{instruction}</div>
                {buttonLink && (
                    <Link to={buttonLink} className="button callout-button top-margin w-button">
                        {` ${buttonTitle} `}
                    </Link>
                )}
                {children}
            </div>
        </div>
    );
};

export default IntroContent;
