import { FC, SVGProps } from 'react';
import { z } from 'zod';

import { ReactComponent as AvatarSpeedy } from '~/assets/avatars/avatar.speedy.svg';
import { ReactComponent as AvatarBat } from '~/assets/avatars/avatar.bat.svg';
import { ReactComponent as AvatarWonder } from '~/assets/avatars/avatar.wonder.svg';
import { ReactComponent as Avatar1 } from '~/assets/avatars/avatar_1.svg';
import { ReactComponent as Avatar2 } from '~/assets/avatars/avatar_2.svg';
import { ReactComponent as Avatar3 } from '~/assets/avatars/avatar_3.svg';
import { ReactComponent as Avatar4 } from '~/assets/avatars/avatar_4.svg';
import { ReactComponent as Avatar5 } from '~/assets/avatars/avatar_5.svg';
import { ReactComponent as Avatar6 } from '~/assets/avatars/avatar_6.svg';
import { ReactComponent as Avatar7 } from '~/assets/avatars/avatar_7.svg';
import { ReactComponent as Avatar8 } from '~/assets/avatars/avatar_8.svg';
import { ReactComponent as Avatar9 } from '~/assets/avatars/avatar_9.svg';
import { ReactComponent as Avatar10 } from '~/assets/avatars/avatar_10.svg';
import { ReactComponent as Avatar11 } from '~/assets/avatars/avatar_11.svg';
import { ReactComponent as Avatar12 } from '~/assets/avatars/avatar_12.svg';
import { ReactComponent as Avatar13 } from '~/assets/avatars/avatar_13.svg';
import { ReactComponent as Avatar14 } from '~/assets/avatars/avatar_14.svg';
import { ReactComponent as Avatar15 } from '~/assets/avatars/avatar_15.svg';
import { ReactComponent as Avatar16 } from '~/assets/avatars/avatar_16.svg';
import { ReactComponent as Avatar17 } from '~/assets/avatars/avatar_17.svg';
import { ReactComponent as Avatar18 } from '~/assets/avatars/avatar_18.svg';
import { ReactComponent as Avatar19 } from '~/assets/avatars/avatar_19.svg';
import { ReactComponent as Avatar20 } from '~/assets/avatars/avatar_20.svg';
import { ReactComponent as Avatar21 } from '~/assets/avatars/avatar_21.svg';
import { ReactComponent as Avatar22 } from '~/assets/avatars/avatar_22.svg';
import { ReactComponent as Avatar23 } from '~/assets/avatars/avatar_23.svg';
import { ReactComponent as Avatar24 } from '~/assets/avatars/avatar_24.svg';

export type SVGComponent = FC<SVGProps<SVGSVGElement> & { title?: string }>;

export const AvatarTypes = [
    'speedy',
    'bat',
    'wonder',
    'avatar1',
    'avatar2',
    'avatar3',
    'avatar4',
    'avatar5',
    'avatar6',
    'avatar7',
    'avatar8',
    'avatar9',
    'avatar10',
    'avatar11',
    'avatar12',
    'avatar13',
    'avatar14',
    'avatar15',
    'avatar16',
    'avatar17',
    'avatar18',
    'avatar19',
    'avatar20',
    'avatar21',
    'avatar22',
    'avatar23',
    'avatar24',
] as const;

export const AvatarTypeValidator = z.enum(AvatarTypes);
export type AvatarType = z.infer<typeof AvatarTypeValidator>;

// SVG Class Names used are
// skin-tone
// cheek-color
// hair-color
// eye-color
// clothing-1
// clothing-2
// clothing-3
// outline
export const AvatarColors = [
    'skinTone',
    'cheekColor',
    'hairColor',
    'eyeColor',
    'clothing1',
    'clothing2',
    'clothing3',
    'outline',
] as const;

export const AvatarAllowedColors = z.object({
    skinTone: z.enum([
        '#5E2702',
        '#C36A3B',
        '#D69569',
        '#F8BC93',
        '#9E5CA0',
        '#009CDB',
        '#4C9176',
        '#FFFFFF',
    ]),
    cheekColor: z.enum([
        '#441B00',
        '#A85D32',
        '#C17D5B',
        '#Fd8459',
        '#8C2F91',
        '#404B74',
        '#3F7963',
        '#F9D1E4',
    ]),
    hairColor: z.enum([
        '#000000',
        '#FFFFFF',
        '#EF293D',
        '#FFE300',
        '#F18E38',
        '#E73C4A',
        '#AA5939',
        '#4B9176',
        '#009CDB',
        '#9E5CA0',
    ]),

    eyeColor: z.string(),
    clothing1: z.string(),
    clothing2: z.string(),
    clothing3: z.string(),
    outline: z.string(),
});

export type AvatarAllowedColorsType = z.infer<typeof AvatarAllowedColors>;

export const AvatarValidator = z.object({
    avatar: z.enum(AvatarTypes).optional().default('speedy'),
    skinTone: AvatarAllowedColors.shape.skinTone.optional().default('#C36A3B'),
});

export type AvatarMetaType = Record<
    AvatarType,
    {
        Component: SVGComponent;
        defaultColors: Partial<AvatarAllowedColorsType>;
    }
>;

export const AvatarMeta: AvatarMetaType = {
    speedy: {
        Component: AvatarSpeedy,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#EF293D',
            cheekColor: '#A85D32',
            clothing1: '#000000',
            clothing2: '#EF293D',
            clothing3: '#FFE300',
        },
    },
    bat: {
        Component: AvatarBat,
        defaultColors: { skinTone: '#C36A3B', clothing1: '#404b7f', clothing2: '#9E5CA0' },
    },
    wonder: {
        Component: AvatarWonder,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#000000',
            cheekColor: '#A85D32',
            clothing1: '#EF293D',
            clothing2: '#FFE300',
            clothing3: '#009CDB',
        },
    },
    avatar1: {
        Component: Avatar1,
        defaultColors: {
            skinTone: '#C36A3B',
            clothing1: '#EF293D',
            clothing2: '#FFE300',
        },
    },
    avatar2: {
        Component: Avatar2,
        defaultColors: {
            hairColor: '#FFE300',
            skinTone: '#C36A3B',
            clothing1: '#EF293D',
            clothing2: '#9E5CA0',
        },
    },
    avatar3: {
        Component: Avatar3,
        defaultColors: {
            skinTone: '#C36A3B',
            cheekColor: '#A85D32',
            clothing1: '#9E5CA0',
            clothing2: '#EF293D',
            clothing3: '#404b7f',
        },
    },
    avatar4: {
        Component: Avatar4,
        defaultColors: {
            skinTone: '#C36A3B',
            clothing1: '#EF293D',
            clothing2: '#FFE300',
            clothing3: '#9E5CA0',
        },
    },
    avatar5: {
        Component: Avatar5,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#000000',
            clothing1: '#FFE300',
            clothing2: '#4C9176',
        },
    },
    avatar6: {
        Component: Avatar6,
        defaultColors: {
            skinTone: '#C36A3B',
            clothing1: '#009CDB',
            clothing2: '#EF293D',
        },
    },
    avatar7: {
        Component: Avatar7,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#000000',
            cheekColor: '#A85D32',
            clothing1: '#EF293D',
            clothing2: '#009CDB',
            clothing3: '#FFE300',
        },
    },
    avatar8: {
        Component: Avatar8,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#000000',
            cheekColor: '#A85D32',
            clothing1: '#404b7f',
            clothing2: '#EF293D',
        },
    },
    avatar9: {
        Component: Avatar9,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#AA5939',
            cheekColor: '#A85D32',
            clothing1: '#009CDB',
            clothing2: '#EF293D',
            clothing3: '#FFE300',
        },
    },
    avatar10: {
        Component: Avatar10,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#000000',
            cheekColor: '#A85D32',
            clothing1: '#FFFFFF',
            clothing2: '#F18E38',
        },
    },
    avatar11: {
        Component: Avatar11,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#EF293D',
            cheekColor: '#A85D32',
            clothing1: '#009CDB',
            clothing2: '#FFE300',
            clothing3: '#EF293D',
        },
    },
    avatar12: {
        Component: Avatar12,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#000000',
            cheekColor: '#A85D32',
            clothing1: '#EF293D',
            clothing3: '#009CDB',
        },
    },
    avatar13: {
        Component: Avatar13,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#FFE300',
            cheekColor: '#A85D32',
            clothing1: '#4C9176',
            clothing2: '#F18E38',
            clothing3: '#F18E38',
        },
    },
    avatar14: {
        Component: Avatar14,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#F18E38',
            cheekColor: '#A85D32',
            clothing1: '#009CDB',
            clothing2: '#404b7f',
            clothing3: '#FFFFFF',
        },
    },
    avatar15: {
        Component: Avatar15,
        defaultColors: {
            skinTone: '#C36A3B',
            hairColor: '#9E5CA0',
            cheekColor: '#A85D32',
            clothing1: '#EF293D',
            clothing2: '#FFFFFF',
            clothing3: '#FFE300',
        },
    },
    avatar16: {
        Component: Avatar16,
        defaultColors: {
            hairColor: '#009CDB',
            skinTone: '#C36A3B',
            clothing1: '#9E5CA0',
            clothing2: '#FFFFFF',
            clothing3: '#EF293D',
        },
    },
    avatar17: {
        Component: Avatar17,
        defaultColors: {
            hairColor: '#EF293D',
            skinTone: '#C36A3B',
            cheekColor: '#A85D32',
            clothing1: '#FFFFFF',
            clothing2: '#404b7f',
            clothing3: '#FFE300',
        },
    },
    avatar18: {
        Component: Avatar18,
        defaultColors: {
            hairColor: '#000000',
            skinTone: '#C36A3B',
            cheekColor: '#A85D32',
            clothing1: '#EF293D',
            clothing2: '#404b7f',
            clothing3: '#FFE300',
        },
    },
    avatar19: {
        Component: Avatar19,
        defaultColors: {
            hairColor: '#000000',
            skinTone: '#C36A3B',
            cheekColor: '#A85D32',
            clothing1: '#EF293D',
            clothing2: '#FFE300',
        },
    },
    avatar20: {
        Component: Avatar20,
        defaultColors: {
            hairColor: '#AA5939',
            skinTone: '#C36A3B',
            cheekColor: '#A85D32',
            clothing1: '#FFE300',
            clothing2: '#9E5CA0',
            clothing3: '#000000',
        },
    },
    avatar21: {
        Component: Avatar21,
        defaultColors: {
            hairColor: '#000000',
            skinTone: '#C36A3B',
            clothing1: '#EF293D',
            clothing2: '#FFFFFF',
            clothing3: '#FFE300',
        },
    },
    avatar22: {
        Component: Avatar22,
        defaultColors: {
            hairColor: '#EF293D',
            skinTone: '#C36A3B',
            clothing1: '#404b7f',
            clothing2: '#FFE300',
        },
    },
    avatar23: {
        Component: Avatar23,
        defaultColors: {
            hairColor: '#EF293D',
            skinTone: '#C36A3B',
            clothing1: '#EF293D',
            clothing2: '#FFE300',
            clothing3: '#FFFFFF',
        },
    },
    avatar24: {
        Component: Avatar24,
        defaultColors: {
            hairColor: '#FFE300',
            skinTone: '#C36A3B',
            clothing1: '#4C9176',
            clothing2: '#F18E38',
            clothing3: '#FFFFFF',
        },
    },
};

export default AvatarMeta;
