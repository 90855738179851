import React from 'react';
import moment from 'moment-timezone';

import { EventDates } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

type CalendarDateDisplayProps = {
    date?: string | null;
    dates?: Maybify<EventDates[]> | null;
};

const CalendarDateDisplay: React.FC<CalendarDateDisplayProps> = ({ date, dates }) => {
    const datesObj = dates?.[0];

    if (datesObj?.startDate === datesObj?.endDate) {
        date = datesObj?.startDate;
    }

    const multipleDatesExist = datesObj && datesObj?.startDate !== datesObj?.endDate;

    const topDate = moment(date).utc().format('MMM');
    const bottomDate = moment(date).utc().format('DD');

    if (multipleDatesExist) {
        const { startDate, endDate } = datesObj;
        const formattedDatesTxt =
            multipleDatesExist &&
            `${moment(startDate).utc().format('DD')} - ${moment(endDate).utc().format('DD')}`;

        const dateMonthStart = moment(startDate).utc().format('MMM');
        const dateMonthEnd = moment(endDate).utc().format('MMM');
        const dateMonthSame = dateMonthStart === dateMonthEnd;

        let monthDisplay = dateMonthStart;

        if (!dateMonthSame) {
            monthDisplay = `${dateMonthStart} - ${dateMonthEnd}`;
        }

        return (
            <div className="display-calendar-widget-container wide">
                <div className="mini-calendar-top wide">{monthDisplay}</div>
                <div className="mini-calendar-bottom wide">{formattedDatesTxt}</div>
            </div>
        );
    }

    return (
        <div className="display-calendar-widget-container">
            <div className="mini-calendar-top">{topDate}</div>
            <div className="mini-calendar-bottom">{bottomDate}</div>
        </div>
    );
};

export default CalendarDateDisplay;
