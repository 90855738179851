import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import withScrolling from 'react-dnd-scrolling';
import { Flipper } from 'react-flip-toolkit';

import { useCurrentUser } from '@stores/User';

import Toggle from '@web/ui/dsc/forms/customInputs/Toggle';
import IncrementStepper from '@web/ui/dsc/forms/customInputs/IncrementStepper';
import DragPreview from '@web/ui/components/content/newpost/forms/Postbox/DragPreview';
import PromptItem from '@web/ui/components/content/newpost/forms/Postbox/PromptItem';
import DraggablePromptItem from '@web/ui/components/content/newpost/forms/Postbox/DraggablePromptItem';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';

import usePostboxForm from '@web/ui/components/content/newpost/forms/Postbox/usePostboxForm';

import { getFeatureConstraintsForField } from '@core/utilities/constants/feature_constraints';

const updateNamespace = 'imports.wlWeb.ui.components.card.updateCard';
const createNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

const ScrollingSection = withScrolling('section');

/**
 * @type React.FC<{
 *     state: { displayInNewsfeed: boolean, rankOrder: number, [key: string]: any },
 *     setState: ({ [key: string]: any }) => void,
 *     addAttachedContent: (content: any, type: string, callback: () => void) => void,
 *     handleSubmit: () => void,
 *     handleClose: () => void,
 *     hasAdminRole: boolean,
 *     formTitle: string,
 *     chapterTitle: string,
 *     submitText: string,
 *     groupId: string | undefined
 * }>
 */
const PostboxForm = ({
    state,
    setState,
    addAttachedContent,
    handleSubmit,
    handleClose,
    hasAdminRole,
    formTitle,
    chapterTitle,
    submitText,
    defaultTypes,
    childrenTypes,
    groupId,
}) => {
    const { currentUser, refetchCurrentUser } = useCurrentUser();
    const { t } = useTranslation();

    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
        refetchCurrentUser(); // Get up-to-date active group
    }, []);

    const activeGroupId = currentUser?.activeGroup?._id;

    const rankOrderConstraint = getFeatureConstraintsForField('rankOrder');
    const displayInNewsfeedConstraint = getFeatureConstraintsForField('displayInNewsfeed');

    const {
        title: { title, setTitle },
        prompts,
        search: { query, actualQuery, setQuery, searching, loading, error },
        methods: { addPromptAndCloseSearch, updatePrompt, movePrompt, deletePrompt, submit },
        results,
    } = usePostboxForm({
        prioritizeGroupId: !!groupId,
        groupId,
        addAttachedContent,
        handleSubmit,
        defaultPrompts: state.aliases?.map(({ item, url, title }) => ({
            ...item,
            url,
            newPrompt: title, // alias title
        })),
        defaultPromptTitle: state.title,
        defaultTypes, // prompt title
        childrenTypes,
    });
    const hideFooter = searching;
    const selectedPrompts = prompts.map((prompt, index) => {
        return prompts.length > 1 ? (
            <DraggablePromptItem
                key={prompt._id}
                prompt={prompt}
                deletePrompt={deletePrompt}
                updatePrompt={updatePrompt}
                index={index}
                movePrompt={movePrompt}
            />
        ) : (
            <PromptItem
                key={prompt._id}
                prompt={prompt}
                deletePrompt={deletePrompt}
                updatePrompt={updatePrompt}
            />
        );
    });

    const searchedPrompts = results.reduce((result, prompt) => {
        if (prompt.type === 'book') {
            const childRes = prompt?.children?.map(val => {
                // Merge the parent's groupSource reference into the child's reference object.
                // This allows the child to determine the group it belongs to.
                val = {
                    ...val,
                    reference: {
                        ...val?.reference,
                        ...prompt?.reference,
                    },
                };
                return (
                    <PromptItem
                        key={val._id}
                        prompt={val}
                        addPrompt={addPromptAndCloseSearch}
                        activeGroupId={activeGroupId}
                    />
                );
            });

            result.push(...childRes);
        } else {
            result.push(
                <PromptItem
                    key={prompt._id}
                    prompt={prompt}
                    addPrompt={addPromptAndCloseSearch}
                    activeGroupId={activeGroupId}
                />
            );
        }

        return result;
    }, []);

    return (
        <form onSubmit={submit} className="create-postbox-form">
            <header>
                <section>
                    <button
                        className={showSettings ? '' : 'hidden'}
                        onClick={() => setShowSettings(false)}
                        type="button"
                        label="Back"
                    />
                    <h1>{showSettings ? 'Postbox Settings' : formTitle}</h1>
                </section>
                <button type="button" onClick={handleClose}>
                    {t(`common:${createNamespace}.cancel`)}
                </button>
            </header>

            {showSettings ? (
                <section className="settings">
                    {hasAdminRole && rankOrderConstraint && (
                        // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        <label>
                            {t(`common:${updateNamespace}.postBoxUpdateForm.display_order`)}
                            <IncrementStepper
                                value={state.rankOrder}
                                onChange={value => setState({ rankOrder: value })}
                            />
                        </label>
                    )}
                    {hasAdminRole && displayInNewsfeedConstraint && (
                        // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        <label>
                            {t(`common:${updateNamespace}.mediaCardUpdateForm.display_in_newsfeed`)}
                            <Toggle
                                value={state.displayInNewsfeed}
                                onChange={value => setState({ displayInNewsfeed: value })}
                            />
                        </label>
                    )}
                </section>
            ) : (
                <>
                    <fieldset>
                        <section>
                            <img src="/images/icon.black.search.svg" alt="Search" />
                            <input
                                type="text"
                                placeholder="Search Prompts"
                                value={query}
                                onChange={e => setQuery(e.target.value)}
                            />
                        </section>
                        {prompts.length > 1 && (
                            <input
                                onChange={e => setTitle(e.target.value)}
                                value={title}
                                type="text"
                                maxLength="256"
                                name="title"
                                placeholder="Prompt Title..."
                                required=""
                            />
                        )}
                    </fieldset>

                    <ScrollingSection>
                        {loading && <LoadingLine isLoading={loading} error={error} />}
                        <DragPreview />
                        <Flipper
                            flipKey={prompts.map(p => p._id).join() + actualQuery}
                            element="ol"
                        >
                            {searching ? searchedPrompts : selectedPrompts}
                        </Flipper>
                    </ScrollingSection>
                </>
            )}

            <footer className={hideFooter ? 'hidden' : ''}>
                <section>
                    {chapterTitle && (
                        <span>{`${t('common:global.verbs.posting_into')} ${chapterTitle}`}</span>
                    )}
                </section>
                <section>
                    <button type="submit" disabled={prompts.length < 1}>
                        {submitText}
                    </button>

                    <button
                        type="button"
                        onClick={() => setShowSettings(oldShow => !oldShow)}
                        className="toggle-settings"
                        label="Settings"
                    />
                </section>
            </footer>
        </form>
    );
};

export default PostboxForm;
