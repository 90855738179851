import React from 'react';
import { createPortal } from 'react-dom';

import { useModalsContext } from '@web/ui/components/modals/ModalsContext';

import { MODALS } from '@web/utilities/helpers/modals/modals.helpers';

import { ModalTypes } from '@core/types/Modals';

const Modals = () => {
    const { modals } = useModalsContext();

    return createPortal(
        modals.map(modal => {
            const type = window.innerWidth < 991 ? modal.type.mobile : modal.type.desktop;

            if (type === ModalTypes.None) return <></>;

            const Component = MODALS[type];

            return (
                <Component
                    component={modal.component}
                    options={modal.options}
                    open={modal.open}
                    key={modal.id}
                />
            );
        }),
        document.getElementById('modal-mid-root') as HTMLElement
    );
};

export default Modals;
