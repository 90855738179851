import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from '@web/ui/components/modals/hooks/useModal';

import ResourceChapterBody from '@web/ui/components/chapter/ResourceChapterBody';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImageLink,
} from '@core/utilities/constants/defaults';

import { Maybify } from '@core/types/Utilities';
import { ContentCard } from '@shared/welibrary-graphql/types';

type ModalFriendlyChapterProps = {
    chapter: Maybify<ContentCard>;
};

const ModalFriendlyChapter: React.FC<ModalFriendlyChapterProps> = ({ chapter }) => {
    const { t } = useTranslation();

    const { closeAllModals } = useModal();

    const { bookSource: book, groupSource: group, shelfSource: shelf } = chapter.reference ?? {};

    const [src, srcSet] = getBackgroundImageSetForUrl(
        chapter.media?.thumb || getRandomDefaultCoverImage(chapter.title ?? '')
    );

    return (
        <>
            <StyledBackgroundImageLink
                className="modal-friendly-chapter-header"
                src={src}
                srcSet={srcSet}
                to={chapter.reference?.href ?? ''}
                onClick={closeAllModals}
            >
                <div className="darkoverlay" role="presentation" />
                <h1>{chapter.title}</h1>
                <section>
                    <p>
                        {t(`common:global.nouns.channel`)} {t(`common:global.prepositions.by`)}{' '}
                        <span>{group?.profile?.full_name}</span>
                    </p>
                </section>
            </StyledBackgroundImageLink>
            <ResourceChapterBody shelf={shelf} book={book} chapter={chapter} />
        </>
    );
};

export default ModalFriendlyChapter;
