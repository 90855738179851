import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer, Updater } from 'use-immer';
import { z } from 'zod';

import TextArea from '@components/generic/forms/TextArea';
import ImageUpload from '@dsc/forms/customInputs/ImageUpload';
import Select from '@dsc/forms/customInputs/Select';

import { curriedStateSlice } from '@helpers/state/state.helpers';

import { Sponsor, SponsorTier } from '@core/types/EventGroup';

type SponsorFormPanelProps = {
    state: Sponsor;
    tiers: SponsorTier[];
    setState: Updater<Sponsor>;
    setPage: React.Dispatch<string>;
    handleSaveSponsor: () => void;
    handleCancel: () => void;
};

const StateValidator = z.object({
    name: z.string().nonempty('Please enter a name').max(50),
    image: z.string().nonempty('Please upload an image').max(200),
    tier: z.string().nonempty('Please pick a tier').max(50),
});

const uploadImagePlaceholderImage = '/images/upload-image-placeholder.svg';

const SponsorFormPanel: React.FC<SponsorFormPanelProps> = ({
    state,
    setState,
    setPage,
    handleSaveSponsor,
    handleCancel,
    tiers,
}) => {
    const { t } = useTranslation();

    const updateSlice = curriedStateSlice(setState);
    const [errors, setErrors] = useImmer<Record<string, string[]>>({});
    const [showErrors, setShowErrors] = useImmer(false);
    const [formValid, setFormValid] = useImmer(false);

    const tierOptions = tiers?.map(tier => {
        return tier.name;
    });

    const handleSelectTier = (selectedTierName: string) => {
        const selectedTier = tiers?.find(tier => tier.name === selectedTierName);
        updateSlice('tier', selectedTier);
    };

    const validate = () => {
        const {
            name,
            logo: { image },
            tier,
        } = state;

        const parsedData = StateValidator.safeParse({ name, tier: tier?.name, image });

        if (parsedData.success) {
            setErrors({});
            setFormValid(true);
            return true;
        }

        if (parsedData.error) {
            setErrors(parsedData.error.flatten().fieldErrors);
            setFormValid(false);
        }

        return false;
    };

    const onSave = () => {
        if (validate()) {
            handleSaveSponsor();
        } else {
            setShowErrors(true);
        }
    };

    useEffect(() => {
        validate();
    }, [state]);

    const saveBtnClass = formValid ? 'active' : 'inactive';

    return (
        <>
            <header className="modal-form-btn-header event-form">
                <h1>{t('common:sponsors', 'Sponsors')}</h1>
                <p>{t('common:fields_marked_*_are_required', 'Fields marked * are required')}</p>
                <p className="small-600 event-form header-info">
                    {t(
                        'common:event-group-forms-skip-msg',
                        `Note: This can be skipped. After your event is published, choose the 'Edit Event' option to add or update information.`
                    )}
                </p>
                <div className="header-btns">
                    <button
                        type="button"
                        onClick={handleCancel}
                        className="rounded-pill-btn bordered"
                    >
                        {t('common:cancel', 'Cancel')}
                    </button>

                    <button
                        type="button"
                        onClick={onSave}
                        className={`rounded-pill-btn ${saveBtnClass}`}
                    >
                        {t('common:save', 'Save')}
                    </button>
                </div>
            </header>
            <article className="tp-body">
                <section>
                    <TextArea
                        className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px]"
                        countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[12px]"
                        wrapperClassName="relative w-full"
                        defaultClasses=""
                        defaultAutoResizeClasses=""
                        autoResize
                        value={state.name}
                        error={!!errors?.name?.[0] && showErrors}
                        helperText={showErrors ? errors?.name?.[0] : ''}
                        onInput={updateSlice('name')}
                        placeholder="Sponsor Name *"
                        required
                        maxCharacter={50}
                    />
                    <TextArea
                        className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px] !overflow-auto"
                        countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[12px]"
                        wrapperClassName="relative w-full"
                        defaultClasses=""
                        defaultAutoResizeClasses=""
                        autoResize
                        helperText=''
                        value={state.link}
                        onInput={updateSlice('link')}
                        placeholder="Sponsor Link"
                        maxCharacter={255}
                    />
                </section>

                <section className="add-sponsor-logo">
                    <h4>{`${t('common:upload_sponsor_logo', 'Sponsor Logo')}*`}</h4>
                    {errors?.image?.[0] && showErrors && (
                        <p className="err-msg small">
                            {t('common:please_upload_a_logo', 'Please upload a logo')}
                        </p>
                    )}

                    <ImageUpload
                        state={{
                            ...state.logo,
                            image: state.logo.image || uploadImagePlaceholderImage,
                        }}
                        setState={updateSlice('logo')}
                        className={`rounded-thumbnail-edit-container small-square ${errors?.logo?.[0] ? 'err-border' : ''
                            }`}
                        loaderSize={100}
                    />
                </section>

                <section className="add-sponsor-tier-form">
                    <section className="title-desc">
                        <h4>{`${t('common:sponsor_tier', 'Sponsor Tier')}*`}</h4>
                        {errors?.tier?.[0] && showErrors && (
                            <p className="err-msg small">
                                {t(
                                    'common:please_select_or_create_a_tier',
                                    'Please select or create a tier'
                                )}
                            </p>
                        )}

                        <p>
                            {t(
                                'common:assign_a_tier_to_your_sponsor',
                                'Assign a tier to your Sponsor.'
                            )}
                        </p>
                        <p>
                            {t(
                                'common:you_must_have_a_tier_assigned_to_your_sponsor',
                                'You must have a tier assigned to your Sponsor.'
                            )}
                        </p>
                    </section>

                    <div className="select-tier-section">
                        {tierOptions?.length > 0 && (
                            <>
                                <Select
                                    prompt="Select Tier..."
                                    value={state?.tier?.name}
                                    onChange={handleSelectTier}
                                    options={tierOptions}
                                />

                                <button
                                    type="button"
                                    className="add-tier-btn"
                                    onClick={() => {
                                        setPage('tier');
                                    }}
                                >
                                    <div className="circle-plus-icon-dark animate-grow">
                                        <div />
                                        <div />
                                    </div>
                                </button>
                            </>
                        )}

                        {tierOptions?.length === 0 && (
                            <div className="tp-gen-add-container">
                                <button type="button" onClick={() => setPage('tier')}>
                                    <span>{t('common:add_tier', 'Add Tier')}</span>
                                    <div className="circle-plus-icon-dark animate-grow">
                                        <div />
                                        <div />
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>

                    <section className="speaker-list" />
                </section>
            </article>
        </>
    );
};

export default SponsorFormPanel;
