import React from 'react';
import { useTranslation } from 'react-i18next';
import { Updater } from 'use-immer';
import { sortBy, cloneDeep } from 'lodash';
import useModal from '@components/modals/hooks/useModal';

import TextArea from '@components/generic/forms/TextArea';
import SponsorsForm from '@components/content/newpost/forms/EventGroup/SponsorsForm';
import SponsorListCard from '@components/content/newpost/forms/EventGroup/SponsorListCard';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { SponsorsFormState, Sponsor, SponsorTier } from '@core/types/EventGroup';
import { ModalTypes } from '@core/types/Modals';

type EventGroupSponsorsFormProps = {
    state: SponsorsFormState;
    setState: Updater<SponsorsFormState>;
    updateValidationSlice?: Updater<boolean>;
};

const EventGroupSponsorsForm = React.forwardRef<HTMLFieldSetElement, EventGroupSponsorsFormProps>(
    function EventGroupSponsorsForm({ state, setState }, ref) {
        const { t } = useTranslation();

        const updateSlice = curriedStateSlice(setState);
        const updateSponsors = updateSlice('sponsors');

        const { newModal, closeModal } = useModal();

        const handleAddSponsor = (sponsor: Sponsor, id?: string) => {
            if (!id) {
                updateSponsors(draft => {
                    draft.push(sponsor);
                });
            } else {
                const updateIndex = state?.sponsors?.findIndex(_sponsor => _sponsor.id === id);
                updateSponsors(draft => {
                    draft[updateIndex] = sponsor;
                });
            }
        };

        const handleRemoveSponsor = (id: string) => {
            const _sponsors = state?.sponsors?.filter(sponsor => sponsor.id !== id);
            updateSponsors(_sponsors);
        };

        const handleSaveTiers = (tiers: SponsorTier[]) => {
            // Bump any sponsors in deleted tiers to the last tier

            const sponsors = cloneDeep(state?.sponsors);

            const updatedSponsors = sponsors?.map(sponsor => {
                if (!tiers.find(tier => tier.name === sponsor.tier?.name)) {
                    sponsor.tier = tiers.at(-1)!;
                }
                return sponsor;
            });

            updateSponsors(updatedSponsors);
            updateSlice('tiers', tiers);
        };

        const handleOpenSponsorsForm = (initialState?: Sponsor, id?: string) => {
            newModal(
                <SponsorsForm
                    tiers={state.tiers}
                    id={id}
                    initialState={initialState}
                    handleSaveTiers={handleSaveTiers}
                    handleSaveSponsor={handleAddSponsor}
                    handleCancel={closeModal}
                />,
                {
                    className: 'center-fixed-header-footer',
                },
                { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
            );
        };

        const sortedSponsors = sortBy(state?.sponsors, sponsor => sponsor?.tier?.order);

        const renderSponsorsList = sortedSponsors?.map((sponsor: Sponsor) => {
            const menuList = [
                {
                    id: 1,
                    title: 'Edit',
                    iconName: 'Edit',
                    onClick: () => handleOpenSponsorsForm(sponsor, sponsor.id),
                    onClose: () => false,
                },
                {
                    id: 2,
                    title: 'Delete',
                    iconName: 'Delete',
                    onClick: () => {
                        handleRemoveSponsor(sponsor.id);
                    },
                    onClose: () => false,
                },
            ];

            return <SponsorListCard key={sponsor?.id} sponsor={sponsor} menuList={menuList} />;
        });

        return (
            <fieldset ref={ref} className="main-form-event-group">
                <h3>{t('common:sponsors_(optional)', 'Sponsors (Optional)')}</h3>

                <section>
                    <p>
                        {t(
                            'common:eventGroupForm.sponsors_description',
                            'Write a message to display above your sponsors.'
                        )}
                    </p>
                    <TextArea
                        className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px] !h-[130px] !overflow-auto"
                        countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[12px]"
                        wrapperClassName="relative w-full"
                        defaultClasses=""
                        defaultAutoResizeClasses=""
                        autoResize
                        helperText=''
                        value={state.description}
                        onInput={updateSlice('description')}
                        placeholder="Description"
                        maxCharacter={255}
                    />
                </section>

                <section>
                    <section>
                        <h4>{t('common:sponsors', 'Sponsors')}</h4>
                        <p>
                            {t(
                                'common:who_will_be_sponsoring_the_event',
                                'Who will be sponsoring the event?'
                            )}
                        </p>
                    </section>

                    {renderSponsorsList}
                    <div className="tp-gen-add-container">
                        <button type="button" onClick={() => handleOpenSponsorsForm()}>
                            <span>{t('common:new_sponsor', 'New Sponsor')}</span>
                            <div className="circle-plus-icon-dark animate-grow">
                                <div />
                                <div />
                            </div>
                        </button>
                    </div>
                </section>
            </fieldset>
        );
    }
);

export default EventGroupSponsorsForm;
