import React from 'react';
import NewPostIcon from './NewPostIcon';

const NewPostIcons = ({
    iconTypes,
    handleExpand,
    handleClear,
    handleFileSelect,
    openModalHandler,
    handleSquarePhotoSelect,
    shouldShowAttachment,
}) => {
    const renderIcons = iconTypes?.map(type => {
        return (
            <NewPostIcon
                key={type}
                shouldShowAttachment={shouldShowAttachment}
                handleClear={handleClear}
                openModalHandler={openModalHandler}
                handleSquarePhotoSelect={handleSquarePhotoSelect}
                cardType={type}
                handleExpand={handleExpand}
                handleFileSelect={handleFileSelect}
            />
        );
    });
    return renderIcons;
};

export default NewPostIcons;
