import React from 'react';

type TrashProps = {
    className?: string;
};

const Trash: React.FC<TrashProps> = ({ className = '' }) => (
    <svg className={className} fill="currentcolor" viewBox="0 0 500 500">
        <path d="M373,94H332.8L328,59.68c-1.56-11.17-12.07-20.31-23.33-20.31H195.33c-11.27,0-21.77,9.14-23.33,20.31L167.2,94H127a41,41,0,0,0-41,41v13.66H414V135A41,41,0,0,0,373,94ZM198.61,50.65H301.39l6,43.13H192.59Z" />
        <path d="M379.83,160H120.17A24.58,24.58,0,0,0,95.31,187.2l22.38,246.21c1.37,15,14.78,27.22,29.82,27.22h205c15,0,28.45-12.24,29.81-27.22L404.68,187.2A24.56,24.56,0,0,0,379.83,160ZM195.33,406h-41L140.67,214.64h54.66Zm82,0H222.67V214.64h54.66Zm68.33,0h-41V214.64h54.66Z" />
    </svg>
);

export default Trash;
