/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import { AliasItem } from '@shared/welibrary-graphql/types';

import PRE_SELECTED_ICONS from '@core/utilities/constants/pre_selected_tile_icons';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const LinkTileListForm: React.FC<{
    tileId: string;
    alias: AliasItem;
    updateAliasItem: Function;
    deleteAliasItem: Function;
}> = ({ tileId, alias, updateAliasItem, deleteAliasItem }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleMenuOff = () => setIsOpen(false);

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: tileId,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <li key={tileId} className="linkTile" ref={setNodeRef} style={style}>
            <div className="linkTile-form-fields-wrap">
                <span
                    className="tile-link-drag-handle black-drag-handle"
                    {...listeners}
                    {...attributes}
                />
                <div className="linkTile-selectList-wrap">
                    <button
                        className="linkTile-selectList-btn"
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <img
                            src={alias?.icon || ''}
                            alt=""
                            className="linkTile-selectList-btn-icon"
                        />
                        <span>&nbsp; &#x025BE;</span>
                    </button>
                    {isOpen && (
                        <OutsideClickHandler onOutsideClick={handleMenuOff}>
                            <ul className="linkTile-selectList">
                                <div className="linkTile-selectList-title-wrap">
                                    <p className="linkTile-selectList-title">Select an Icon</p>
                                </div>
                                {PRE_SELECTED_ICONS.map(icon => (
                                    <div
                                        className={`linkTile-selectList-item ${
                                            icon.value === alias.icon &&
                                            'linkTile-selectList-item-active'
                                        }`}
                                        onClick={() => {
                                            updateAliasItem(alias, 'icon', icon.value);
                                            setIsOpen(false);
                                        }}
                                    >
                                        <img
                                            src={icon.value}
                                            alt={icon.name}
                                            className="linkTile-selectList-item-icon"
                                        />
                                    </div>
                                ))}
                            </ul>
                        </OutsideClickHandler>
                    )}
                </div>
                <div className="linkTile-title-input-wrap">
                    <input
                        id="title"
                        type="text"
                        value={alias.title || ''}
                        onChange={e => {
                            const { value } = e.target;
                            updateAliasItem(alias, 'title', value);
                        }}
                        className="linkTile-title-input"
                        maxLength={256}
                        name="title"
                        placeholder="Tile Title"
                    />

                    {alias.type === 'alias_external_link' && (
                        <input
                            id="url"
                            type="text"
                            value={alias?.url}
                            onChange={e => {
                                const { value } = e.target;
                                updateAliasItem(alias, 'url', value);
                            }}
                            className="linkTile-url-input"
                            maxLength={256}
                            name="url"
                            placeholder="External URL"
                        />
                    )}
                </div>
            </div>
            <button
                type="button"
                onClick={() => deleteAliasItem(alias)}
                className="button-reset linkTile-delete-button w-inline-block"
            >
                &nbsp;
            </button>
        </li>
    );
};

export default LinkTileListForm;
