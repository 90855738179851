import React from 'react';
import Toggle from '@web/ui/dsc/forms/customInputs/Toggle';
import IncrementStepper from '@web/ui/dsc/forms/customInputs/IncrementStepper';
import { useTranslation } from 'react-i18next';
import EditScreenTagSelect from '@web/ui/components/generic/editscreen/EditScreenTagSelect';
import EditScreenSelectOne from '@web/ui/components/generic/editscreen/EditScreenSelectOne.js';
import {
    CARD_DOCUMENT,
    CARD_IMAGE,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_TEXT,
    CARD_VIDEO,
} from '@core/utilities/constants/card_types.js';
import { getFeatureConstraintsForField } from '@core/utilities/constants/feature_constraints.js';

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm';

const MediaCardUpdateSettings = ({
    card,
    chapterSettings,
    cardConstraints,
    engagementTypes,
    state,
    handleUpdateState,
    inheritedSettings,
    defaultViewTypes,
    handleChange,
    handleAddToList,
    handleRemoveFromList,
}) => {
    const { t } = useTranslation();
    const {
        engagementTypesConstraint,
        displayInNewsfeedConstraint,
        displaySocialHeadersConstraint,
        rankOrderConstraint,
        showCreateChildrenConstraint,
        defaultViewTypeConstraint,
        isTemplateConstraint,
        showTitle,
        canMembersSuggestEditsConstraint,
    } = cardConstraints;

    const canToggleShowTitle = [
        CARD_TEXT,
        CARD_LINK,
        CARD_IMAGE,
        CARD_DOCUMENT,
        CARD_VIDEO,
        CARD_IMAGE_SQUARE,
    ];

    let initialToggleValue;
    if (chapterSettings?.showTitle === null || chapterSettings?.showTitle === true) {
        initialToggleValue = true;
    }

    const suggestEditsText = t(`common:${localNamespace}.suggest_edits`);

    return (
        <section className="settings">
            {displaySocialHeadersConstraint && chapterSettings?.displaySocialHeaders === null && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {t(`common:${localNamespace}.post_anonymously`)}
                    <Toggle
                        value={!state.displaySocialHeaders}
                        onChange={value => handleUpdateState({ displaySocialHeaders: !value })}
                    />
                </label>
            )}
            {displayInNewsfeedConstraint && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {t(`common:${localNamespace}.display_in_newsfeed`)}
                    <Toggle
                        value={state.displayInNewsfeed == null ? true : state.displayInNewsfeed}
                        onChange={value => handleUpdateState({ displayInNewsfeed: value })}
                    />
                </label>
            )}
            {showCreateChildrenConstraint && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className="settings-resources-toggle-wrap">
                    <div className="settings-resources-toggle settings-resources-toggle-left">
                        {t(`common:${localNamespace}.show_create_children`)}
                        <Toggle
                            value={state.showCreateChildren}
                            onChange={value => handleUpdateState({ showCreateChildren: value })}
                        />
                    </div>
                    {state.showCreateChildren && (
                        // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        <div className="settings-resources-toggle settings-resources-toggle-right">
                            {t(`common:${localNamespace}.show_resource_header`)}
                            <Toggle
                                value={
                                    state.showResourceHeader == null
                                        ? true
                                        : state.showResourceHeader
                                }
                                onChange={value => handleUpdateState({ showResourceHeader: value })}
                            />
                        </div>
                    )}
                </label>
            )}

            {rankOrderConstraint && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {t(`common:${localNamespace}.display_order`)}
                    <IncrementStepper
                        value={state.rankOrder}
                        onChange={value => handleUpdateState({ rankOrder: value })}
                    />
                </label>
            )}
            {engagementTypesConstraint && (
                <div className="card-settings tag-select">
                    <div className="edit-display-order-container">
                        <div className="display-text-inline" style={{ marginTop: '5px' }}>
                            {t(`common:${localNamespace}.engagement`)}
                            <br />
                        </div>
                        <EditScreenTagSelect
                            overrideContainerStyle="engagement-type-select"
                            target="engagementTypes"
                            options={engagementTypes}
                            selected={state.engagementTypes}
                            handleSetList={(target, values) => {
                                handleUpdateState({ engagementTypes: values });
                            }}
                            handleRemoveFromList={handleRemoveFromList}
                        />
                    </div>
                </div>
            )}
            {defaultViewTypeConstraint && (
                <div className="card-settings tag-select">
                    <div className="edit-display-order-container">
                        <div className="display-text-inline" style={{ marginTop: '5px' }}>
                            {t(
                                'common:imports.wlWeb.ui.components.card.darkForms.settings.views.title'
                            )}
                            <br />
                        </div>
                        <EditScreenSelectOne
                            hideTitle
                            target="defaultViewType"
                            options={defaultViewTypes}
                            selected={state.defaultViewType || inheritedSettings?.defaultViewType}
                            handleChange={handleChange}
                        />
                    </div>
                </div>
            )}
            {/* Card level privacy visibility setting - protectionLevel */}
            {/* partially implemented at the moment, not used in anything for now */}
            {/* <div className="card-settings tag-select">
                <div className="edit-display-order-container">
                    <div className="display-text-inline" style={{ marginTop: '5px' }}>
                        Privacy
                        <br />
                    </div>
                    <EditScreenSelectOne
                        hideTitle
                        target="cardProtectionLevel"
                        options={defaultProtectionTypes}
                        selected={state.cardProtectionLevel}
                        handleChange={handleChange}
                    />
                </div>
            </div> */}
            {/* Only show template option for admins on text cards */}
            {card.type === CARD_TEXT && isTemplateConstraint && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {t(
                        `common:${'imports.wlWeb.ui.components.card.darkForms.settings'}.is_template.title`
                    )}
                    <Toggle
                        value={state.isTemplate}
                        onChange={value => handleUpdateState({ isTemplate: value })}
                    />
                </label>
            )}
            {showTitle && canToggleShowTitle.includes(card.type) && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {t(
                        `common:${'imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm'}.show_title`
                    )}
                    <Toggle
                        // the initial showTitle field is null on the contentCard
                        // and by default uses the chapter settings to display the title & title input field
                        // initially the toggle switch is toggled to the chapterSettings.showTitle value.
                        // on the content card level, if switched to true | false
                        // then the content card's showTitle setting will override the chapter settings
                        value={state.showTitle === null ? initialToggleValue : state.showTitle}
                        onChange={value => handleUpdateState({ showTitle: value })}
                    />
                </label>
            )}
            {canMembersSuggestEditsConstraint && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>
                    {suggestEditsText}
                    <Toggle
                        value={
                            state.canMembersSuggestEdits
                                ? state.canMembersSuggestEdits
                                : state.currentUserCanSuggestEdit
                        }
                        onChange={value => handleUpdateState({ canMembersSuggestEdits: value })}
                    />
                </label>
            )}
        </section>
    );
};

export default MediaCardUpdateSettings;
