import React from 'react';
import useModal from '@components/modals/hooks/useModal';

import EditTickets from '@components/content/newpost/forms/ShoppingCartProducts/EditTickets';

import { Group } from '@shared/welibrary-graphql/types';
import { ModalTypes } from '@core/types/Modals';

const EventGroupFormMerchantAccountReminder: React.FC<{ group: Group }> = ({ group }) => {
    const { newModal: centerModal } = useModal({ desktop: ModalTypes.Center });

    const ticketsDetected = group?.products?.length > 0;
    const merchantAccountFullyActivated =
        group?.paymentSettings?.stripeAccount?.charges_enabled &&
        group?.paymentSettings?.stripeAccount?.details_submitted &&
        group?.paymentSettings?.stripeAccount?.payouts_enabled;

    if (!ticketsDetected || merchantAccountFullyActivated) {
        return <></>;
    }

    const handleEditTickets = () => {
        centerModal(<EditTickets group={group} />);
    };

    return (
        <>
            <p className="events-merchant-account-reminder-text">
                Before you can accept payments for your tickets, you need a merchant account setup.
            </p>
            <button
                type="button"
                onClick={handleEditTickets}
                className="events-merchant-account-reminder-button"
            >
                Activate Merchant Account
            </button>
        </>
    );
};

export default EventGroupFormMerchantAccountReminder;
