import { ContentCard, CreationState } from '@shared/welibrary-graphql/types';
import { QuestCreationType } from '@core/types/Quests';
import { PerformantMaybify } from '@core/types/Utilities';

export const getCreationImage = (
    creation?: PerformantMaybify<ContentCard> | QuestCreationType | null,
    state?: PerformantMaybify<CreationState> | null,
    levelOverride?: number
): string => {
    if (!creation) return '';

    const { level: stateLevel, isComplete } = state ?? {};

    const level = typeof levelOverride === 'string' ? levelOverride : stateLevel;

    if (isComplete) return creation.creationItems?.levels?.at(-1)?.image ?? '';

    // We're subtracting by 2 because of an off-by-one error with how the server is storing levels
    // and how we are using indices, as well as needing to show the _previous_ level, not the _current_
    // level's image
    if (!level || level < 2) return creation.media?.icon ?? '';

    return creation.creationItems?.levels?.[level - 2]?.image ?? '';
};

export const getCreationImageSimple = (
    state?: PerformantMaybify<CreationState> | null,
    levelOverride?: number
): string => {
    const { creation, level: stateLevel, isComplete } = state ?? {};

    if (!creation) return '';

    const level = typeof levelOverride === 'string' ? levelOverride : stateLevel;

    if (isComplete) return creation.creationItems?.levels?.at(-1)?.image ?? '';

    // We're subtracting by 2 because of an off-by-one error with how the server is storing levels
    // and how we are using indices, as well as needing to show the _previous_ level, not the _current_
    // level's image
    if (!level || level < 2) return creation.media?.icon ?? '';

    return creation.creationItems?.levels?.[level - 2]?.image ?? '';
};
