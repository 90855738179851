import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { z } from 'zod';

import useModal from '@components/modals/hooks/useModal';

import TextArea from '@web/ui/components/generic/forms/TextArea';
import Button from '@dsc/forms/buttons/Button';

type PromptProps = {
    text: string;
    onConfirm: (promptInput: string) => void;
    onCancel?: () => void;
    className?: string;
    options?: {
        validate?: boolean;
        inputPlaceholder?: string;
        defaultInputValue?: string;
    };
};

const StateValidator = z.object({
    input: z.string().nonempty().max(240),
});

const localNamespace = 'imports.wlWeb.ui.components.card.pledgeCard';

const Prompt: React.FC<PromptProps> = ({ text, onConfirm, onCancel, className = '', options }) => {
    const { t } = useTranslation();
    const [input, setInput] = useState<string>(options?.defaultInputValue ?? '');
    const [errors, setErrors] = useImmer<Record<string, string[]>>({});

    const { closeModal } = useModal();

    const onValidate = () => {
        const parsedData = StateValidator.safeParse({ input });

        if (parsedData.success) {
            setErrors({});

            return true;
        }

        setErrors(parsedData.error.flatten().fieldErrors);

        return false;
    };

    const confirm = () => {
        if (options?.validate) {
            if (onValidate()) {
                onConfirm(input);
                closeModal();
            }
        } else {
            onConfirm(input);
            closeModal();
        }
    };

    const cancel = () => {
        onCancel?.();
        closeModal();
    };

    return (
        <section role="alert" className={`prompt-modal ${className}`}>
            <strong>{text}</strong>

            <TextArea
                handleChange={e => setInput(e.target.value)}
                maxCharacter={240}
                value={input}
                placeholder={
                    options?.inputPlaceholder ?? t(`common:${localNamespace}.note_form.placeholder`)
                }
                minHeight="130px"
                defaultClasses={`${errors?.input ? 'prompt-modal-error' : ''}`}
                inverseCountDisplay
            />

            {errors?.input && <p className="prompt-modal-error-input">{errors?.input}</p>}

            <footer>
                <Button onClick={confirm} type="small">
                    {t('common:ok', 'Ok')}
                </Button>
                <Button onClick={cancel} type="small">
                    {t('common:cancel', 'Cancel')}
                </Button>
            </footer>
        </section>
    );
};

export default Prompt;
