import {useContext} from 'react';
import {NavigationContext} from './NavigationContext';

const useNavigation = () => {
    const [state, setState] = useContext(NavigationContext);
    const {
        isMobileNavHidden,
        isProfileMenuOpen,
        isSlideMenuOpen,
        isGroupSwitchOpen,
        isGroupFilterOpen,
        groupSwitchRoute,
        groupSwitchType,
        isChannelOpen
    } = state;

    const isGroupAnalyticsSelected = state && state.groupSwitchRoute;

    const setProfileMenuOpenState = newValue => {
        setState(state => ({ ...state, isProfileMenuOpen: newValue }));
    };
    const toggleProfileMenu = () => {
        setState(state => ({ ...state, isProfileMenuOpen: !state.isProfileMenuOpen }));
    };

    const setGroupSwitchOpenState = (newValue, groupSwitchRoute = null, groupSwitchType = null) => {
        setState(state => ({
            ...state,
            isGroupSwitchOpen: newValue,
            groupSwitchRoute,
            groupSwitchType,
        }));
    };

    const setChannelOpenState = (newValue) => {
        setState(state => ({
            ...state,
            isChannelOpen: newValue
        }));
    }

    const toggleGroupSwitch = () => {
        setState(state => ({
            ...state,
            isGroupSwitchOpen: !state.isGroupSwitchOpen,
            groupSwitchRoute: null,
            groupSwitchType: null,
        }));
    };

    const openQuickMenu = items => {
        setState(state => ({ ...state, quickMenuItems: items, isQuickMenuOpen: true }));
    };

    const closeQuickMenu = () => {
        setState(state => ({ ...state, quickMenuItems: [], isQuickMenuOpen: false }));
    };

    const hideMobileNav = () => {
        setState(state => ({ ...state, isMobileNavHidden: true }));
    };

    const showMobileNav = () => {
        setState(state => ({ ...state, isMobileNavHidden: false }));
    };

    const toggleSlideMenu = value => {
        setState(state => ({ ...state, isSlideMenuOpen: value ?? !state.isSlideMenuOpen }));
    };

    const closeSlideMenu = () => toggleSlideMenu(false);

    const openSlideMenu = () => toggleSlideMenu(true);

    return {
        isSlideMenuOpen,
        toggleSlideMenu,
        closeSlideMenu,
        openSlideMenu,
        isGroupAnalyticsSelected,
        groupSwitchType,
        isProfileMenuOpen,
        setProfileMenuOpenState,
        toggleProfileMenu,
        setGroupSwitchOpenState,
        toggleGroupSwitch,
        isGroupSwitchOpen,
        groupSwitchRoute,
        hideMobileNav,
        showMobileNav,
        isMobileNavHidden,
        openQuickMenu,
        closeQuickMenu,
        setChannelOpenState
    };
};

export default useNavigation;
