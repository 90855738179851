import React from 'react';
import { useCurrentUser } from '@stores/User';

import { GROUP_ACTIONS } from '@core/utilities/constants/group';
import isGroupSubscription, { parentGroupHasSubscription } from './groupDashboardHelpers';
import { Group } from '@shared/welibrary-graphql/types';

type GroupActionButtonProps = {
    takeGroupActionForUser: (userId: string, action: string) => void;
    primaryActionAvailable?: {
        label: string | null;
        action: string | null;
        useRsvpButton?: boolean;
    };
    customClassName: string;
    group: Group;
};

const GroupActionButton: React.FC<GroupActionButtonProps> = ({
    takeGroupActionForUser,
    primaryActionAvailable,
    customClassName,
    group,
}) => {
    const { currentUser } = useCurrentUser();
    const _isGroupSubscription = isGroupSubscription(group) || parentGroupHasSubscription(group);

    const handleTakePrimaryAction = () => {
        if (primaryActionAvailable?.label !== 'Request Pending') {
            takeGroupActionForUser(currentUser?._id ?? '', primaryActionAvailable?.action ?? '');
        }
    };

    const baseClassName = `standard-button callout`;
    const cssClassName =
        primaryActionAvailable?.label === GROUP_ACTIONS?.GROUP_CANCEL_REQUEST_JOIN
            ? `${baseClassName} disabled-btn`
            : baseClassName;

    // filter out subscription & event primary actions
    if (
        primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS ||
        primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_PURCHASE_ACCESS ||
        primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_REQUEST_TO_PURCHASE ||
        primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_REACTIVATE_SUBSCRIPTION ||
        primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_CANCEL_PRE_AUTH_PURCHASE
    ) {
        return <></>;
    }

    if (
        _isGroupSubscription &&
        primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN
    )
        return;

    if (primaryActionAvailable?.label) {
        return (
            <span
                className={`${cssClassName} ${customClassName}`}
                onClick={handleTakePrimaryAction}
            >
                {primaryActionAvailable.label}
            </span>
        );
    }

    return <></>;
};

export default GroupActionButton;
