import React from 'react';

type SignUpModalProps = {
    title: string;
    className?: string;
    children: React.ReactNode;
};

const SignUpModal: React.FC<SignUpModalProps> = ({ title = '', className = '', children }) => {
    return (
        <div className={className}>
            {title && (
                <h1 className="text-grayscale-title-active font-poppins text-[32px] md:text-[46px] font-[700] leading-[36px] md:leading-[50px] !m-0 text-center">
                    {title}
                </h1>
            )}

            {children}
        </div>
    );
};

export default SignUpModal;
