import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import {
    usePopularGroupsQuery,
    useGetRecommendationsForUserQuery,
} from '@shared/welibrary-graphql/user/queries.hook';
import { useJoinOrRequestToJoinMultipleGroupsByIdMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import useContainerDimensions from '@components/generic/hooks/useContainerDimensions';
import useScreenWidth from '@components/generic/hooks/useScreenWidth';

import signUpStore from '@stores/SignUp';

import CaretIcon from '@dsc/svgs/LeftCaretIcon';
import Arrow from '@dsc/svgs/Arrow';
import PostSignupHeader from '@pages/new-signup/PostSignupHeader';
import SearchInput from '@components/search/SearchInput';
import ModalLoading from '@components/modals/ModalLoading';
import SearchLoader from '@components/search/SearchLoader';
import JoinMoreGroupItem from '@pages/new-signup/JoinMoreGroupItem';
import SignUpPageButton from '@pages/new-signup/SignUpPageButton';
import PostSignupGroupsJoinedAndRequested from '@pages/new-signup/GroupsJoinedAndRequested';
import PostSignupGroupJoined from '@pages/new-signup/PostSignupGroupJoined';

import { getRootUrl } from '@core/utilities/whitelabel_helpers';
import { GroupSubtype, RecommendationType, ViewPermission } from '@shared/welibrary-graphql/types';
import { VIEW_PERMISSION_PUBLIC } from '@core/utilities/constants/roles';

const localNamespace = 'imports.wlWeb.ui.pages.post-signup.join-more-groups';

type JoinMoreGroupsProps = {
    goToNextStep: () => any;
    goToPreviousStep: () => any;
    redirectUrl: string;
};

const JoinMoreGroups: React.FC<JoinMoreGroupsProps> = ({
    goToNextStep,
    goToPreviousStep,
    redirectUrl,
}) => {
    const { t } = useTranslation();
    const [searchString, setSearchString] = useState('');
    const [showGroups, setShowGroups] = useState<boolean>(false);

    const Tabs = {
        forYou: 'For You',
        topGroups: 'Top Groups',
    } as const;
    type TabsType = (typeof Tabs)[keyof typeof Tabs];

    const [tab, setTab] = useState<TabsType>(Tabs.forYou);

    const componentRef = useRef();
    const { width } = useContainerDimensions(componentRef);
    const windowWidth = useScreenWidth();

    const groupIDs = signUpStore.useTracked.groupsToJoin();
    const groupsJoinedAndRequested = signUpStore.useTracked.groupsJoinedAndRequested();
    const activeGroup = signUpStore.get.activeGroup();

    const { data: groupsData, loading } = usePopularGroupsQuery({
        variables: {
            limit: 20,
            cursor: null,
            searchQuery: searchString,
            sortType: 'recentFirst',
            protectionLevel: [ViewPermission.Public],
            host: getRootUrl(),
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const { data: recData, loading: recsLoading } = useGetRecommendationsForUserQuery({
        variables: { input: { limit: 20, types: [RecommendationType.Group] } },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const userAnsweredQuestions = true; // TODO this should be set based on whether or not user has answered any questions
    const useRecs = userAnsweredQuestions && !searchString && tab === Tabs.forYou;

    const groups = useRecs
        ? recData?.recommendationsForUser?.recommendations?.recommendations.map(r => r.group)
        : groupsData?.popularGroups?.results;

    // Comm request: filter out events and platform groups and non-public groups
    //   the popularGroups resolver does this already, but we need it for recommended groups too.. and that's messier on the backend so we'll just do it here
    const filteredGroups = groups?.filter(
        group =>
            group && // fix some whitescreens when group is undefined
            group._id !== '8y2CNH8ToRXA4svzR' && // What's Happening Now
            group._id !== '63fe33a0c9f8e7afd736a591' && // The Home Zone
            group._id !== '654290a17625b92556ded85f' && // NickV Network
            group.subtype !== GroupSubtype.Event &&
            group.settings?.protectionLevel === VIEW_PERMISSION_PUBLIC // only show public groups
    );

    const recsExist = useRecs && filteredGroups?.length > 0;

    useEffect(() => {
        if (!recsLoading && !recsExist && tab === Tabs.forYou) {
            setTab(Tabs.topGroups);
        }
    }, [recsExist, recsLoading]);

    let header = t(`common:${localNamespace}.popularGroupsToJoin`, 'Popular Groups to Join');
    let subheader: string;

    if (useRecs) {
        header = t(`common:${localNamespace}.recommendedForYou`, 'Recommended For You');
        subheader = t(`common:${localNamespace}.basedOnYourInterests`, 'Based on your interests');
    } else if (searchString) {
        header = `${t(
            `common:${localNamespace}.topResultsFor`,
            'Top Results for'
        )} "${searchString}"`;
    }

    const [joinOrRequestToJoinMultipleGroups, { loading: mutationLoading }] =
        useJoinOrRequestToJoinMultipleGroupsByIdMutation();

    const searchLoaderStyle = { position: 'relative', marginBottom: '20px' };
    const emptyResults = !loading && _.isEmpty(filteredGroups) && searchString !== '';
    const emptyState = searchString === '';
    const areResults = filteredGroups && !_.isEmpty(filteredGroups);

    const handleJoinOrRequestToJoinMultipleGroups = () => {
        // check if there is at least one new group that's being joined
        const alreadyRequestedGroups = groupsJoinedAndRequested.map(g => g.group._id);
        const newGroup = groupIDs.find(id => !alreadyRequestedGroups.includes(id));

        if (!newGroup) {
            // don't waste time calling the mutation if there's no new group to join
            goToNextStep();
            return;
        }

        joinOrRequestToJoinMultipleGroups({
            variables: {
                groupIDs,
            },
        }).then(({ data }) => {
            if (data?.joinOrRequestToJoinMultipleGroupsByID) {
                signUpStore.set.setGroupsJoinedAndRequested(
                    data?.joinOrRequestToJoinMultipleGroupsByID
                );
                goToNextStep();
            }
        });
    };

    const showPostSignupGroups = windowWidth <= 767;

    let joinMoreGroupItemClassName: string;
    if (width <= 572) {
        joinMoreGroupItemClassName = '!w-full !mr-0';
    } else {
        joinMoreGroupItemClassName = '';
    }

    const reviewGroupsBtn = (
        <div
            className={`bg-grayscale-body flex w-full items-center justify-between rounded-tl-[20px] rounded-tr-[20px] px-4 text-white  ${showGroups ? 'pt-2' : 'mb-[30px] rounded-bl-[20px] rounded-br-[20px] py-4 md:mb-2'
                }`}
        >
            <h3>{t(`common:global.review_groups`, 'Review Groups')}</h3>{' '}
            <button type="button" onClick={() => setShowGroups(!showGroups)}>
                <CaretIcon
                    className={`w-[30px] text-white ${showGroups ? 'rotate-[270deg]' : 'rotate-[90deg]'
                        }`}
                    color="#ffffff"
                />
            </button>
        </div>
    );

    return (
        <section
            className="relative flex h-full w-full flex-col overflow-y-scroll pt-[40px] md:pt-[60px]"
            ref={componentRef}
        >
            <header className="flex flex-col gap-[20px] px-[40px]">
                <PostSignupHeader
                    headerText={t(`common:${localNamespace}.joinMoreGroups`, 'Join More Groups')}
                    subtitle={t(
                        `common:${localNamespace}.selectGroups`,
                        'Select groups to join or use the search bar to find groups that interest you.'
                    )}
                    redirectUrl={redirectUrl}
                    bottomMargin="0"
                >
                    {groupsJoinedAndRequested?.length > 1 && showPostSignupGroups && (
                        <div className="flex w-full flex-col items-center justify-center">
                            {reviewGroupsBtn}
                            {showGroups && (
                                <PostSignupGroupsJoinedAndRequested
                                    showWhiteLabelLogo={false}
                                    containerClassName="!w-full mb-[30px] md:mb-[10px]"
                                    innerContainerClass="!p-0 !px-[40px] !pb-[20px] !pt-[10px] !gap-[8px] bg-grayscale-body rounded-tl-[0px] rounded-tr-[0px]"
                                    className="!w-full max-w-[100%]"
                                />
                            )}
                        </div>
                    )}
                    {activeGroup &&
                        groupsJoinedAndRequested?.length === 0 &&
                        showPostSignupGroups && (
                            <div className="flex w-full flex-col items-center justify-center">
                                {reviewGroupsBtn}
                                {showGroups && (
                                    <PostSignupGroupJoined
                                        showWhiteLabelLogo={false}
                                        containerClassName="!w-full mb-[30px] md:mb-[10px]"
                                        innerContainerClass="!p-0 !px-[40px] !pb-[20px] !pt-[10px] !gap-[8px] bg-grayscale-body rounded-tl-[0px] rounded-tr-[0px]"
                                        className="!w-full max-w-[100%]"
                                    />
                                )}
                            </div>
                        )}
                </PostSignupHeader>

                <SearchInput value={searchString} onChange={setSearchString} />

                {searchString && (
                    <div className="mt-[20px] flex flex-col gap-[5px] pb-[10px]">
                        <h2 className="text-grayscale-title-active my-0 text-[20px] font-[500] leading-[120%] tracking-[0.25px]">
                            {header}
                        </h2>
                        {subheader && (
                            <h3 className="text-grayscale-body font-poppins text-[17px] leading-[24px] tracking-[0.25px]">
                                {subheader}
                            </h3>
                        )}
                    </div>
                )}
                {!searchString && (
                    <div className="mb-[20px] mt-[20px] flex items-start gap-[30px]">
                        {recsExist && (
                            <button
                                type="button"
                                onClick={() => setTab(Tabs.forYou)}
                                className={`font-poppins text-[20px] leading-[120%] tracking-[0.25px] ${tab === Tabs.forYou
                                        ? 'text-primary-default border-primary-default border-b-[2px] border-solid pb-[5px] font-[600]'
                                        : 'text-grayscale-title-active font-[500]'
                                    }`}
                            >
                                {t(`common:${localNamespace}.forYou`, 'For You')}
                            </button>
                        )}
                        <button
                            type="button"
                            onClick={() => setTab(Tabs.topGroups)}
                            className={`font-poppins text-[20px] leading-[120%] tracking-[0.25px] ${tab === Tabs.topGroups
                                    ? 'text-primary-default border-primary-default border-b-[2px] border-solid pb-[5px] font-[600]'
                                    : 'text-grayscale-title-active font-[500]'
                                }`}
                        >
                            {t(`common:${localNamespace}.topGroups`, 'Top Groups')}
                        </button>
                    </div>
                )}
            </header>

            <ul className="popular-group-list flex min-h-[300px] w-full flex-wrap items-center justify-start overflow-y-scroll px-[20px] py-[15px] text-center md:min-h-[initial]">
                {!emptyState && (
                    <SearchLoader
                        customStyle={searchLoaderStyle}
                        emptyResults={emptyResults}
                        size={180}
                        emptyState={emptyState}
                        areResults={areResults}
                        loading={loading}
                    />
                )}

                {loading && emptyState && (
                    <div className="flex w-full items-center justify-center">
                        <ModalLoading
                            message={t(
                                `common:${localNamespace}.loading_groups`,
                                'Loading Groups...'
                            )}
                        />
                    </div>
                )}

                {!loading && filteredGroups?.length > 0 && (
                    <div className="flex w-full flex-wrap gap-[20px]">
                        {filteredGroups?.map(group => {
                            return (
                                <JoinMoreGroupItem
                                    group={group}
                                    key={group?._id}
                                    className={joinMoreGroupItemClassName}
                                />
                            );
                        })}
                    </div>
                )}
            </ul>

            <div className="mt-auto flex gap-[10px] px-[20px] pb-[20px] pt-[20px]">
                <SignUpPageButton
                    className="!w-fit px-[11px]"
                    inverseColors
                    onClick={goToPreviousStep}
                >
                    <Arrow direction="left" />
                </SignUpPageButton>
                <SignUpPageButton
                    onClick={handleJoinOrRequestToJoinMultipleGroups}
                    disabled={mutationLoading}
                >
                    {mutationLoading
                        ? t(`common:${localNamespace}.loading`, 'Loading...')
                        : t(`common:${localNamespace}.continue`, 'Continue')}
                </SignUpPageButton>
            </div>
        </section>
    );
};

export default JoinMoreGroups;
