import React from 'react';

import { getCheekColor } from '@components/profile/Lego/avatarHelpers';
import { AvatarType, AvatarMeta, AvatarAllowedColors } from '@components/profile/Lego/Avatars';

type AvatarProps = {
    avatar: AvatarType;
    colors?: Partial<AvatarAllowedColors>;
    className?: string;
};

const Avatar: React.FC<AvatarProps> = ({ avatar, colors, className = '' }) => {
    const { Component, defaultColors } = AvatarMeta[avatar];

    return (
        <Component
            className={`avatar ${className}`}
            style={
                {
                    '--skin-tone': colors?.skinTone || defaultColors?.skinTone,
                    '--cheek-color': getCheekColor(
                        colors?.skinTone || defaultColors?.skinTone || ''
                    ),
                    '--hair-color': colors?.hairColor || defaultColors?.hairColor,
                    '--eye-color': colors?.eyeColor || defaultColors?.eyeColor,
                    '--clothing-1': colors?.clothing1 || defaultColors?.clothing1,
                    '--clothing-2': colors?.clothing2 || defaultColors?.clothing2,
                    '--clothing-3': colors?.clothing3 || defaultColors?.clothing3,
                    '--outline': colors?.outline || defaultColors?.outline,
                } as React.CSSProperties
            }
        />
    );
};

export default Avatar;
