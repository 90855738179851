import React from 'react';

const Rainbow: React.FC<{ className?: string }> = ({ className = '' }) => {
    return (
        <svg className={className} viewBox="0 0 99 98" fill="none">
            <path
                d="M67.875 70.4375V64.3125C67.875 59.4391 65.9391 54.7654 62.4931 51.3194C59.0471 47.8734 54.3734 45.9375 49.5 45.9375C44.6266 45.9375 39.9529 47.8734 36.5069 51.3194C33.0609 54.7654 31.125 59.4391 31.125 64.3125V70.4375"
                stroke="currentcolor"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M80.125 70.4375V64.3125C80.125 56.1902 76.8984 48.4007 71.1551 42.6574C65.4118 36.9141 57.6223 33.6875 49.5 33.6875C41.3777 33.6875 33.5882 36.9141 27.8449 42.6574C22.1016 48.4007 18.875 56.1902 18.875 64.3125V70.4375"
                stroke="currentcolor"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M92.375 70.4375V64.3125C92.375 52.9413 87.8578 42.0359 79.8172 33.9953C71.7766 25.9547 60.8712 21.4375 49.5 21.4375C38.1288 21.4375 27.2234 25.9547 19.1828 33.9953C11.1422 42.0359 6.625 52.9413 6.625 64.3125V70.4375"
                stroke="currentcolor"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Rainbow;
