import React, { useState } from 'react';

import useSampledNewsfeed from '@web/ui/components/newsfeed/hooks/useSampledNewsfeed';
import useFetchWhatsNew from '@web/ui/components/newsfeed/hooks/useFetchWhatsNew';
import { GET_PAGINATED_NEWSFEED } from '@shared/welibrary-graphql/content_card/queries';
import useInfiniteScroll from '@web/ui/components/generic/hooks/useInfiniteScroll';
import { NewsfeedEnum } from '@components/newsfeed/types';

export const NewsfeedContext = React.createContext(undefined);

export const NewsfeedProvider = ({ children }) => {
    // Newsfeed state: None - don't run at all
    const [runQuery, setRunQuery] = useState(NewsfeedEnum.None);
    // Get 'random' cards - "For you"
    const { setRef, refetch, error, networkStatus, hasMoreResults, sampledCards, sampledCardIds } =
        useSampledNewsfeed(runQuery);

    // Get 'New content' used in 'What's new section'
    const { whatsNewCards, refetchNewContent, fetchNewLoading, fetchNewNetworkStatus } =
        useFetchWhatsNew(sampledCardIds);

    // Get user's newsfeed

    const runNewsfeedQuery = runQuery === NewsfeedEnum.Both || runQuery === NewsfeedEnum.User;
    const {
        setRef: setNewsfeedRef,
        queryResult: {
            refetch: newsfeedRefetch,
            data: newsfeedData,
            loading: newsfeedLoading,
            error: newsfeedError,
        },
    } = useInfiniteScroll({
        query: {
            query: GET_PAGINATED_NEWSFEED,
            options: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                skip: !runNewsfeedQuery,
                variables: { limit: 25 },
            },
        },
        visibleOffset: 100,
    });

    return (
        <NewsfeedContext.Provider
            value={{
                setRef,
                setNewsfeedRef,
                newsfeedRefetch,
                newsfeedData,
                newsfeedLoading,
                newsfeedError,
                refetch,
                error,
                networkStatus,
                hasMoreResults,
                sampledCards,
                whatsNewCards,
                refetchNewContent,
                fetchNewLoading,
                fetchNewNetworkStatus,
                setRunQuery,
                runQuery,
                // newContentRef,
                // clearNewCards,
            }}
        >
            {children}
        </NewsfeedContext.Provider>
    );
};

export default NewsfeedContext;
