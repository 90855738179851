import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IntroContent from '@web/ui/components/generic/IntroContent';
import { CREATE_TAG } from '@shared/welibrary-graphql/tag/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { GET_TAGS } from '@shared/welibrary-graphql/tag/queries';
import { UPDATE_TAGS_ON_CARD } from '@shared/welibrary-graphql/content_card/mutations';
import FocusLock from 'react-focus-lock';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';

import getLogger from '@core/logger';

const logger = getLogger(module);

const timer = ms => {
    return new Promise(res => setTimeout(res, ms));
};

const TagsItem = props => {
    const { tag, handleSelectItem, selected } = props;
    const handleClickItem = e => handleSelectItem(tag);
    return (
        <div className="tags-item-container">
            <div className="list-item-left">
                <div className="list-item-title tag-item-title">#{tag.label}</div>
            </div>
            <div className="list-item-right">
                <div className="tags-item-checkbox">
                    <label className="move-item-label tags-item-label">
                        <input
                            checked={!!selected}
                            id={tag._id}
                            onChange={handleClickItem}
                            type="checkbox"
                        />
                        <span className="checkmark checkbox-right"></span>
                    </label>
                </div>
            </div>
        </div>
    );
};

const NewTagInputField = props => {
    const { handleNewTagInput, newTagInput, handleAddNewTag } = props;
    const handleInputChange = e => handleNewTagInput(e);
    const handleAdd = e => handleAddNewTag();
    return (
        <div className="new-tag-input">
            <div id="new-tag-form" className="search-field-container new-tag-form">
                <input
                    onChange={handleInputChange}
                    placeholder="Create New Tag..."
                    type="text"
                    className="search-field w-input hashtag-icon"
                    maxLength="256"
                    name="search-community-page"
                    id="search-community-page"
                    value={newTagInput}
                />
                <button onClick={handleAdd} className="plus-sign-tag w-inline-block" />
            </div>
        </div>
    );
};

const TagsForm = props => {
    const { handleClose, card } = props;
    const [thisTags, setThisTags] = useState([]);
    const [allTags, setAllTags] = useState([]);
    const [input, setInput] = useState('');
    const [newTagInput, setNewTagInput] = useState('');
    const [showNewTagInput, setShowNewTagInput] = useState(false);
    const [createTag, { data, loading, error }] = useMutation(CREATE_TAG);
    const [
        updateTagsOnCard,
        { data: updateTagsCardData, loading: updateTagsCardLoading, error: updateTagsCardError },
    ] = useMutation(UPDATE_TAGS_ON_CARD);
    const {
        error: getTagsError,
        loading: getTagsLoading,
        data: getTagsData,
        refetch: getTagsRefetch,
    } = useQuery(GET_TAGS);
    const [statusMsg, setStatusMsg] = useState('');
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleAddNewTag = () => {
        if (newTagInput.trim() !== '') {
            createTag({
                variables: {
                    input: {
                        label: newTagInput,
                    },
                },
            })
                .then(res => setStatusMsg('Successfully created new tag!'))
                .catch(e => logger.error(e));

            getTagsRefetch();
            setNewTagInput('');
        } else {
            setStatusMsg('Tag name cannot be blank');
        }
    };

    useEffect(() => {
        if (card && card.tags && card.tags.results) {
            setThisTags(card.tags.results);
        }
    }, []);

    useEffect(() => {
        setAllTags(getTagsData);
        return () => {};
    }, [getTagsData]);

    useEffect(() => {
        let variables = {
            searchQuery: input,
        };
        getTagsRefetch(variables);
    }, [input]);

    const handleNewTagInput = e => {
        if (statusMsg) {
            setStatusMsg('');
        }

        setNewTagInput(e.currentTarget.value);
    };

    const handleSelectItem = tag => {
        let foundThisTag = thisTags.find(thisTag => thisTag._id === tag._id);
        let _thisTags = foundThisTag
            ? thisTags.filter(thisTag => thisTag._id !== tag._id)
            : [...thisTags, tag];
        setThisTags(_thisTags);
    };

    const handleCloseForm = e => handleClose(e);
    const filterTags = searchString => {
        if (searchString.trim() == '') {
            return;
        }
        let _allTags = allTags.filter(tag => tag.name.length > searchString.length);
        setAllTags(_allTags);
    };

    const handleClear = e => {
        setInput('');
    };

    const handleInput = e => setInput(e.target.value);
    const handleSave = e => {
        setIsLoading(true);
        setStatusMsg('Saving...');
        e.preventDefault();
        let tagIds = thisTags.map(tag => tag._id);
        updateTagsOnCard({
            variables: {
                cardUrl: card.url,
                tags: tagIds,
            },
        })
            .then(res => {
                setIsLoading(false);
                setStatusMsg('Successfully Updated Tags!');
                timer(300).then(res => {
                    handleClose(e);
                });
            })
            .catch(e => setStatusMsg('There was an error'));
    };

    let renderList =
        allTags &&
        allTags.getTags &&
        allTags.getTags.results.map((tag, index) => {
            let selected = thisTags.find(thisTag => thisTag._id === tag._id);
            return (
                <TagsItem
                    selected={selected}
                    handleSelectItem={handleSelectItem}
                    key={index}
                    tag={tag}
                />
            );
        });

    if (allTags && allTags.getTags && allTags.getTags.results <= 0) {
        renderList = <IntroContent declaration="No Tags Found" type="nocontent" />;
    }

    return (
        <>
            <LoadingLine
                isLoading={isLoading || getTagsLoading || updateTagsCardLoading}
                error={updateTagsCardError || getTagsError}
            />
            <div className="tags-form-container">
                <div className="tags-form">
                    <FocusLock autoFocus={true}>
                        <div className="move-item-header">
                            <div className="move-item-title-bar">
                                <h4>Add/Edit Tags</h4>
                                <div>
                                    <div
                                        onClick={handleCloseForm}
                                        className="cancel-edit-content-button w-inline-block"
                                    ></div>
                                </div>
                            </div>
                            <div className="move-item-search-input-container">
                                <div className="move-item-search-input">
                                    <div
                                        id="tag-form"
                                        name="tag-form"
                                        className="search-field-container"
                                    >
                                        <input
                                            onChange={handleInput}
                                            autofocus
                                            placeholder="Search Tags..."
                                            type="text"
                                            className="search-field w-input"
                                            maxLength="256"
                                            name="search-community-page"
                                            id="search-community-page"
                                            value={input}
                                        />
                                        <div
                                            style={{ backgroundColor: '#0493f6', opacity: '1' }}
                                            onClick={handleClear}
                                            className="cancel-search-text w-inline-block"
                                        ></div>
                                    </div>
                                </div>
                                <NewTagInputField
                                    handleAddNewTag={handleAddNewTag}
                                    handleNewTagInput={handleNewTagInput}
                                    newTagInput={newTagInput}
                                />
                            </div>
                        </div>
                        <div className="tags-list">{renderList}</div>
                        <div className="tag-item-footer">
                            <div className="status-msg">{statusMsg}</div>
                            <div className="move-item-button">
                                <button onClick={handleSave} className="move-item-buttom">
                                    Save Tags
                                </button>
                            </div>
                        </div>
                    </FocusLock>
                </div>
            </div>
        </>
    );
};

TagsForm.propTypes = {
    handleClose: PropTypes.func,
    card: PropTypes.object,
};

export default TagsForm;
