import { FC, ReactNode } from 'react';

export enum ToastPosition {
    TOP = 'TOP',
    TOP_RIGHT = 'TOP_RIGHT',
    RIGHT = 'RIGHT',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
    BOTTOM = 'BOTTOM',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    LEFT = 'LEFT',
    TOP_LEFT = 'TOP_LEFT',
}

export type ToastOptions = {
    /**
     * CSS class passed to the Toast itself
     */
    className?: string;

    /**
     * Toast position, defaults to bottom right
     */
    position?: ToastPosition;

    /**
     * Toast duration in milliseconds
     */
    duration?: number;
};

export type ToastComponent = ReactNode;

export type Toast = {
    component: ToastComponent;
    options?: ToastOptions;
    open: boolean;
};

export type ToastProps = {
    component: ToastComponent;
    options?: ToastOptions;
    open: boolean;
};

export type StandardToastAction = {
    /** The click-handler fired when the user clicks on the action label */
    handler: React.MouseEventHandler<HTMLButtonElement>;
    /** The label text for the action */
    label: string;
};

export type StandardToastProps = {
    /** The title(bold text) within the toast */
    title?: string;
    /** The message for the toast */
    message: string;
    /** The icon's class name */
    iconClassName?: string;
    /** The click-handler for that is triggered whenever a user clicks on the toast  */
    handleClick?: React.MouseEventHandler<HTMLDivElement>;
    /** A callback that can be used to handle the dismissal of the toast */
    dismissedCallback?: Function;
    /** The action of the toast */
    action?: StandardToastAction;
    /** Is this toast dismissable? This should be set to false whenever there is a duration set */
    dismissable?: boolean;
};

export type ToastContainer = FC<ToastProps>;

export const defaultAlertToastOptions: ToastOptions = {
    duration: 2500,
};
