import React from 'react';
import DropdownController from '@components/generic/dropdownmenu/DropdownController';
import useModal from '@components/modals/hooks/useModal';
import {
    ItineraryState,
    DateAndTimeState,
    LocationTypeEnum,
    EventLocationSettings,
    SpeakerStateType,
} from '@core/types/EventGroup';
import { ModalTypes } from '@core/types/Modals';
import { getFormattedEventDates, getFormattedEventTimes } from '@core/utilities/constants/events';
import TimeAndPlaceForm from './TimeAndPlaceForm';

type TimeAndPlaceInfoCardProps = {
    state: ItineraryState;
    eventState: DateAndTimeState;
    itemId: string;
    handleDelete: (id: string) => void;
    handleUpdate: (id: string, newState: ItineraryState) => void;
    handleDuplicate: (newState: ItineraryState) => void;
    handleUpdateSpeakerInAllSessions: (speaker: SpeakerStateType) => void;
    allSpeakers?: SpeakerStateType[] | undefined | null;
};

const TimeAndPlaceInfoCard: React.FC<TimeAndPlaceInfoCardProps> = ({
    state,
    eventState,
    itemId,
    handleDelete,
    handleUpdate,
    handleDuplicate,
    handleUpdateSpeakerInAllSessions,
    allSpeakers,
}) => {
    const { newModal, closeModal } = useModal({ mobile: ModalTypes.Center });

    const handleUpdateSession = (newState: ItineraryState) => {
        handleUpdate(itemId, newState);
    };

    const handleOpenEdit = () => {
        newModal(
            <TimeAndPlaceForm
                handleCancel={closeModal}
                handleUpdateSpeakerInAllSessions={handleUpdateSpeakerInAllSessions}
                itemId={itemId}
                handleSave={handleUpdateSession}
                eventState={eventState}
                initialState={state}
                allSpeakers={allSpeakers}
            />,
            { className: 'center-fixed-header-footer', hideButton: true },
            { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
        );
    };

    const handleDuplicateSession = () => {
        handleDuplicate(state);
    };

    const handleDeleteSession = () => {
        handleDelete(itemId);
    };

    const menuList = [
        {
            id: 1,
            title: 'Edit',
            iconName: 'Edit',
            onClick: () => handleOpenEdit(),
            onClose: () => false,
        },
        {
            id: 2,
            title: 'Duplicate',
            iconName: 'Duplicate',
            onClick: () => handleDuplicateSession(),
            onClose: () => false,
        },
        {
            id: 3,
            title: 'Delete',
            iconName: 'Delete',
            onClick: () => handleDeleteSession(),
            onClose: () => false,
        },
    ];

    const { title, dates, startTime, endTime, speakers, eventLocationSettings } = state;

    const getLocationTxt = (eventLocationSettings: EventLocationSettings) => {
        if (eventLocationSettings?.type === LocationTypeEnum.inPerson) {
            if (
                eventLocationSettings?.venue?.name &&
                eventLocationSettings?.venue?.location?.formatted_address
            ) {
                return `${eventLocationSettings?.venue?.name}, ${eventLocationSettings?.venue?.location?.formatted_address}`;
            }
            if (eventLocationSettings?.venue?.location && !eventLocationSettings?.venue?.name) {
                return `${eventLocationSettings?.venue?.location?.formatted_address}`;
            }
        }
        if (eventLocationSettings?.type === LocationTypeEnum.virtual) {
            return `Online Event • ${eventLocationSettings?.link}`;
        }
    };

    const speakersTxt = speakers && `${speakers?.length} Speakers`;

    const eventDatesTxt = dates && getFormattedEventDates(dates?.[0]);
    const _state = { ...state, timeZone: eventState?.timeZone };
    const eventTimesTxt = (startTime || endTime) && getFormattedEventTimes(_state);
    const venueLocationTxt = getLocationTxt(eventLocationSettings);
    return (
        <section className="tnp-card">
            <div className="session-info">
                <h3>{title}</h3>
                <p className="weight-600">{eventDatesTxt}</p>
                <p className="weight-600">{eventTimesTxt}</p>
                <p className="faded-txt">{venueLocationTxt}</p>
                <p className="faded-txt weight-600">{speakersTxt}</p>
            </div>

            <div className="menu-dropdown">
                <DropdownController
                    menuList={menuList}
                    inverted
                    buttonClass="content-button-icon dropdown-dots big-dots grow"
                />
            </div>
        </section>
    );
};

export default TimeAndPlaceInfoCard;
