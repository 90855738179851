import React from 'react';

import { awsMetricPublisher } from '@welibraryos/metrics';

import ErrorItem from '@web/ui/components/card/mediacard/bodyitems/ErrorItem.js';
import PhotoItem from '@web/ui/components/card/mediacard/bodyitems/PhotoItem.js';
import LinkItem from '@web/ui/components/card/mediacard/bodyitems/LinkItem.js';
import VideoItem from '@web/ui/components/card/mediacard/bodyitems/VideoItem';
import DocumentItem from '@web/ui/components/card/mediacard/bodyitems/DocumentItem';
import QuoteItem from '@web/ui/components/card/mediacard/bodyitems/QuoteItem.js';
import MediaTextContainer from '@web/ui/components/card/mediacard/MediaTextContainer.js';
import EmbedItem from '@web/ui/components/card/mediacard/bodyitems/EmbedItem.js';
import CourseItem from '@web/ui/components/card/mediacard/bodyitems/CourseItem';
import ResearchItem from '@web/ui/components/card/mediacard/bodyitems/ResearchItem';
import MobilizeItem from '@web/ui/components/card/mediacard/bodyitems/MobilizeItem';
import PledgeItem from '@web/ui/components/card/mediacard/bodyitems/PledgeItem.js';
import EventItem from '@web/ui/components/card/mediacard/bodyitems/EventItem';
import GroupItem from '@web/ui/components/card/mediacard/bodyitems/GroupItem';
import ImageSlider from '@web/ui/components/card/imagecard/ImageSlider';

import timer from '@core/utilities/timer';
import {
    CARD_COURSE,
    CARD_DOCUMENT,
    CARD_EMBED,
    CARD_EVENT,
    CARD_GROUP,
    CARD_IMAGE,
    CARD_IMAGE_FULL,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_MOBILIZE,
    CARD_PLEDGE,
    CARD_QUOTE,
    CARD_RESEARCH,
    CARD_TEXT,
    CARD_VIDEO,
} from '@core/utilities/constants/card_types.js';
import { compareVersions, getMajorVersion } from '@core/utilities/versionHelpers';

/**
 * @type React.FC<{
 *     card: Object;
 *     course?: boolean;
 *     getTopRightList?: Function;
 *     transparentBackground?: boolean;
 *     noHeader?: boolean;
 *     maxWidth?: number;
 *     onLoad?: () => void;
 *     setCurrentPhoto?: (index: number) => void;
 * }>
 */
const MediaCardBody = ({
    card,
    course,
    getTopRightList,
    transparentBackground,
    noHeader,
    maxWidth,
    onLoad,
    baseMetricStringName, // allows parent to pass the start of a string to apply to multiple metric names
    setCurrentImage = () => { },
}) => {
    let cardBodyItem = <ErrorItem />; // TODO

    switch (card.type) {
        case CARD_IMAGE:
        case CARD_IMAGE_FULL:
        case CARD_IMAGE_SQUARE:
            cardBodyItem = (
                <PhotoItem
                    media={card.media}
                    links={card.links}
                    transparentBackground={transparentBackground}
                    maxWidth={maxWidth}
                    onLoad={onLoad}
                    onClickCallback={() =>
                        awsMetricPublisher.publishCount(baseMetricStringName, 1, [
                            { Name: 'media', Value: 'photo' },
                        ])
                    }
                />
            );
            if (card?.aliases?.length > 0) {
                if (onLoad) timer(1).then(onLoad);
                cardBodyItem = (
                    <ImageSlider
                        aliases={card?.aliases}
                        onClickCallback={() => {
                            awsMetricPublisher.publishCount(baseMetricStringName, 1, [
                                { Name: 'media', Value: 'photo' },
                            ]);
                        }}
                        setCurrentImage={setCurrentImage}
                    />
                );
            }
            break;
        case CARD_LINK:
            // TODO: intelligently call onLoad in LinkItem
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = (
                <LinkItem
                    media={card.media}
                    links={card.links}
                    transparentBackground={transparentBackground}
                    onClickCallback={() =>
                        awsMetricPublisher.publishCount(baseMetricStringName, 1, [
                            { Name: 'media', Value: 'link' },
                        ])
                    }
                />
            );
            break;
        case CARD_DOCUMENT:
            cardBodyItem = (
                <DocumentItem
                    media={card.media}
                    title={compareVersions(card.vs, '1.0.2') >= 0 ? card.links.title : ''}
                    links={card.links}
                    onLoad={onLoad}
                    baseMetricStringName={baseMetricStringName}
                />
            );
            break;
        case CARD_TEXT:
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = <div />;
            break;
        case CARD_QUOTE:
            // TODO: intelligently call onLoad in QuoteItem
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = <QuoteItem card={card} />;
            break;
        case CARD_VIDEO:
            // TODO: intelligently call onLoad in VideoItem
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = (
                <VideoItem
                    showVideo={course}
                    title={card.title}
                    media={card.media}
                    links={card.links}
                    transparentBackground={transparentBackground}
                    onClickCallback={() =>
                        awsMetricPublisher.publishCount(baseMetricStringName, 1, [
                            { Name: 'media', Value: 'play-video' },
                        ])
                    }
                />
            );
            break;
        case CARD_EMBED:
            // TODO: intelligently call onLoad in EmbedItem
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = (
                <EmbedItem source={card.source} transparentBackground={transparentBackground} />
            );
            break;
        case CARD_COURSE:
            // TODO: intelligently call onLoad in CourseItem
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = <CourseItem card={card} transparentBackground={transparentBackground} />;
            break;
        case CARD_PLEDGE:
            // TODO: intelligently call onLoad in PledgeItem
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = <PledgeItem card={card} transparentBackground={transparentBackground} />;
            break;
        case CARD_MOBILIZE:
            // TODO: intelligently call onLoad in MobilizeItem
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = (
                <MobilizeItem card={card} transparentBackground={transparentBackground} />
            );
            break;
        case CARD_RESEARCH:
            // TODO: intelligently call onLoad in ResearchItem
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = (
                <ResearchItem card={card} transparentBackground={transparentBackground} />
            );
            break;
        case CARD_EVENT:
            // TODO: intelligently call onLoad in EventItem
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = (
                <EventItem
                    card={card}
                    getTopRightList={getTopRightList}
                    transparentBackground={transparentBackground}
                />
            );
            break;
        case CARD_GROUP:
            // TODO: intelligently call onLoad in GroupItem
            if (onLoad) timer(1).then(onLoad);
            cardBodyItem = <GroupItem card={card} transparentBackground={transparentBackground} />;
            break;
        default:
            if (onLoad) timer(1).then(onLoad);
    }

    if (course) return <div style={{ height: 'auto', width: '100%' }}>{cardBodyItem}</div>;

    const title = getMajorVersion(card.vs) >= 1 ? card.links.title : card.title;

    return (
        <div className="mc-media-post-wrapper">
            <div className="mc-media-container" style={noHeader ? { paddingTop: '0px' } : {}}>
                {cardBodyItem}
            </div>
            {title &&
                card.type !== CARD_DOCUMENT &&
                card.type !== CARD_QUOTE &&
                card.type !== CARD_EMBED &&
                card.type !== CARD_EVENT &&
                card.type !== CARD_GROUP && (
                    <MediaTextContainer
                        title={title}
                        description={card.description}
                        source={card.links.source}
                        type={card.type}
                        card={card}
                        baseMetricStringName={baseMetricStringName}
                    />
                )}
        </div>
    );
};

export default MediaCardBody;
