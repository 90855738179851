import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import searchStore from '@stores/Search';

import IntroContent from '@components/generic/IntroContent';
import DiscoverRow from '@components/discover/DiscoverRow';
import ErrorBoundary from '@components/generic/errors/ErrorBoundary.js';
import UserOptimizers from '@components/optimizers/UserOptimizers';
import X from '@web/ui/dsc/svgs/FullSizeX';

import useModal from '@components/modals/hooks/useModal';

import { DISCOVER_URLS as URLS } from '@core/utilities/constants/community';

const localNamespace = 'imports.wlWeb.ui.components.community';

const DiscoverModal: React.FC = () => {
    const { t } = useTranslation();

    const [showOptimizer, setShowOptimizer] = useState<boolean>(false);
    const [showLottie, setShowLottie] = useState<boolean>(true);

    const { closeModal } = useModal();

    return (
        <ErrorBoundary>
            {showOptimizer ? (
                <section className="discover-modal-optimizer">
                    <button
                        type="button"
                        onClick={() => closeModal()}
                        className="discover-modal-btn-close"
                    >
                        <X className="discover-modal-btn-x-dark" />
                    </button>
                    <header>
                        <p>{t('common:imports.wlWeb.ui.components.community.optimize')}</p>
                        <button type="button" onClick={() => setShowOptimizer(false)}>
                            {t('imports.wlWeb.ui.components.discover.view_recommendations')}
                        </button>
                    </header>
                    <div className="discover-modal-optimizers-wrap">
                        <div>
                            <UserOptimizers afterUpdate={() => {}} />
                        </div>
                    </div>
                </section>
            ) : (
                <section className="discover-modal">
                    <button
                        type="button"
                        onClick={() => closeModal()}
                        className="discover-modal-btn-close"
                    >
                        <X className="discover-modal-btn-x-dark" />
                    </button>
                    <header>
                        <p>{t(`common:${localNamespace}.discover_tab`)}</p>
                        <button type="button" onClick={() => setShowOptimizer(true)}>
                            {t('common:imports.wlWeb.ui.components.community.optimize')}
                        </button>
                    </header>
                    <section
                        className="community-search-form w-form discover-modal-search-btn-wrap"
                        style={{ width: '100%' }}
                    >
                        <button
                            type="button"
                            className="search-field w-input discover-modal-search-btn"
                            id="search-community-page"
                            onClick={searchStore.set.showSearch}
                        >
                            {`${t('common:global.verbs.search')}...`}
                        </button>
                    </section>
                    <div className="discover-modal-list-wrap">
                        <DiscoverRow
                            discoverType={URLS.COMMUNITY}
                            setShowLottie={setShowLottie}
                            showOptimize={() => setShowOptimizer(true)}
                        />
                        <DiscoverRow
                            discoverType={URLS.GROUPS}
                            setShowLottie={setShowLottie}
                            showOptimize={() => setShowOptimizer(true)}
                        />
                        <DiscoverRow
                            discoverType={URLS.CONTENT}
                            setShowLottie={setShowLottie}
                            showOptimize={() => setShowOptimizer(true)}
                        />
                        <DiscoverRow
                            discoverType={URLS.POSTINGS}
                            setShowLottie={setShowLottie}
                            showOptimize={() => setShowOptimizer(true)}
                        />

                        {showLottie && (
                            <IntroContent type="nocontent" customClass="discover-modal-lottie">
                                <h4
                                    className="discover-modal-lottie-text"
                                    style={{ color: '#000' }}
                                >
                                    {t(
                                        'common:imports.wlWeb.ui.components.discover.no_suggestions'
                                    )}
                                </h4>
                                <button
                                    type="button"
                                    className="button callout-button top-margin w-button"
                                    onClick={() => setShowOptimizer(true)}
                                >
                                    {t('common:imports.wlWeb.ui.components.community.optimize')}
                                </button>
                            </IntroContent>
                        )}
                    </div>
                </section>
            )}
        </ErrorBoundary>
    );
};

export default DiscoverModal;
