import { useEffect, useState } from 'react';

import { useMapsContext } from '@components/generic/context/MapsContext';

import useModal from '@components/modals/hooks/useModal';
import useDebouncedState from '@components/generic/hooks/useDebouncedState';

import { ModalTypes } from '@core/types/Modals';

import getLogger from '@core/logger';

const logger = getLogger(module);

const useLocationPicker = (onLocationSelect: (location: google.maps.GeocoderResult) => void) => {
    const [sessionToken, setSessionToken] = useState<
        google.maps.places.AutocompleteSessionToken | undefined
    >();
    const [searchInput, actualSearchInput, setSearchInput] = useDebouncedState('');
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState<google.maps.places.AutocompletePrediction[]>([]);

    const { maps, autocomplete, geocoder } = useMapsContext();
    const { closeModal } = useModal({ mobile: ModalTypes.Center });

    useEffect(() => {
        if (maps) setSessionToken(new maps.places.AutocompleteSessionToken());
    }, [maps]);

    useEffect(() => {
        if (!actualSearchInput) {
            setLocations([]);
            setLoading(false);
        } else if (autocomplete) {
            const input: google.maps.places.AutocompletionRequest = {
                input: actualSearchInput,
                sessionToken,
                types: ['geocode'],
            };

            autocomplete.getPlacePredictions(input, (predictions, status) => {
                setLocations(predictions ?? []);
                setLoading(false);

                if (status !== google.maps.places.PlacesServiceStatus.OK) {
                    logger.error(`Google Places Services Error: ${status}`);
                }
            });

            setLoading(true);
        }
    }, [actualSearchInput, autocomplete]);

    useEffect(() => {
        if (searchInput) setLoading(true);
    }, [searchInput]);

    const selectLocation = (location: google.maps.places.AutocompletePrediction) => {
        geocoder?.geocode({ placeId: location.place_id }, (results, status) => {
            if (results && results.length > 0) {
                onLocationSelect(results[0]);
                closeModal();
            }

            if (status !== google.maps.GeocoderStatus.OK) {
                logger.error(`Google Geocoder Service Error: ${status}`);
            }
        });
    };

    return {
        searchInput,
        actualSearchInput,
        setSearchInput,
        loading,
        locations,
        selectLocation,
    };
};

export default useLocationPicker;
