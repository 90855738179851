import React, { useRef } from 'react';
import { useImmer } from 'use-immer';

import { useCurrentUser } from '@stores/User';
import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';

import HorizontalNavHeader from '@dsc/navigation/HorizontalNavHeader';
import RRBuilderMain from '@components/rainbowroad/builder/RRBuilderMain';
import RRBuilderHeader from '@components/rainbowroad/builder/RRBuilderHeader';
import RRBuilderBody from '@components/rainbowroad/builder/RRBuilderBody';
import RRBuilderNodes from '@components/rainbowroad/builder/RRBuilderNodes';
import RRBuilderPreview from '@components/rainbowroad/builder/RRBuilderPreview';
import HorizontalNavFooter from '@dsc/navigation/HorizontalNavFooter';

import { filterMaybes } from '@helpers/types/type.helpers';
import { curriedStateSlice } from '@helpers/state/state.helpers';
import { getNavFunctions } from '@helpers/navigation/horizontalNav.helpers';

import { Maybify } from '@core/types/Utilities';
import { RainbowRoadBuilderState } from '@core/types/RainbowRoad';
import { ContentCard, RrCircleSize, RrFont, RrLineStyle } from '@shared/welibrary-graphql/types';

type RRBuilderProps = {
    buttonText: string;
    onSubmit: (state: RainbowRoadBuilderState) => void;
    showPreview?: boolean;
    alwaysShowButton?: boolean;
    title?: string;
    rr?: Maybify<ContentCard>;
};

const RRBuilder: React.FC<RRBuilderProps> = ({
    title = '',
    buttonText,
    rr,
    onSubmit,
    alwaysShowButton = false,
    showPreview = false,
}) => {
    const [state, setState] = useImmer<RainbowRoadBuilderState>({
        main: {
            icon: { image: rr?.media?.icon ?? '' },
            title: rr?.title ?? '',
            description: rr?.description ?? '',
            engagementTypes: filterMaybes(rr?.library_settings?.engagementTypes ?? []),
            font: rr?.rainbowRoadSettings?.font ?? RrFont.Default,
        },
        header: {
            darkMode: rr?.rainbowRoadSettings?.header?.darkMode ?? false,
            color: rr?.rainbowRoadSettings?.header?.color ?? 'var(--color-grayscale-body)',
            useForegroundImage: typeof rr?.rainbowRoadSettings?.header?.image === 'string',
            foregroundImage: { image: rr?.rainbowRoadSettings?.header?.image ?? '' },
            useBackgroundImage:
                typeof rr?.rainbowRoadSettings?.header?.backgroundImage === 'string',
            backgroundImage: { image: rr?.rainbowRoadSettings?.header?.backgroundImage ?? '' },
            showTopInfo: rr?.rainbowRoadSettings?.header?.showTopInfo ?? true,
        },
        body: {
            darkMode: rr?.rainbowRoadSettings?.body?.darkMode ?? true,
            color: rr?.rainbowRoadSettings?.body?.color ?? 'var(--color-grayscale-body)',
            useBackgroundImage: typeof rr?.rainbowRoadSettings?.body?.image === 'string',
            backgroundImage: { image: rr?.rainbowRoadSettings?.body?.image ?? '' },
        },
        nodes: {
            circleSize: rr?.rainbowRoadSettings?.nodes?.circleSize ?? RrCircleSize.Medium,
            useIcon: !!rr?.rainbowRoadSettings?.nodes?.icon,
            icon: { image: rr?.rainbowRoadSettings?.nodes?.icon ?? '' },
            lineStyle: rr?.rainbowRoadSettings?.nodes?.lineStyle ?? RrLineStyle.Solid,
            lineColor: rr?.rainbowRoadSettings?.nodes?.lineColor ?? 'var(--color-grayscale-label)',
            outline: rr?.rainbowRoadSettings?.nodes?.outline ?? true,
            winding: rr?.rainbowRoadSettings?.nodes?.winding ?? false,
        },
    });

    const updateSlice = curriedStateSlice(setState);

      const { currentUser } = useCurrentUser();

    const container = useRef<HTMLFormElement>(null);
    const refs = {
        main: useRef<HTMLFormElement>(null),
        header: useRef<HTMLFormElement>(null),
        body: useRef<HTMLFormElement>(null),
        nodes: useRef<HTMLFormElement>(null),
        preview: useRef<HTMLElement>(null),
    };

    const { on, scrollTo } = useHorizontalPages({
        refs,
        options: { root: container.current },
    });

    const pages: Array<{ name: keyof typeof refs; canSkip: boolean; showMainButton: boolean }> = [
        { name: 'main', canSkip: false, showMainButton: alwaysShowButton || false },
        { name: 'header', canSkip: true, showMainButton: alwaysShowButton || false },
        { name: 'body', canSkip: true, showMainButton: alwaysShowButton || false },
        { name: 'nodes', canSkip: true, showMainButton: alwaysShowButton || !showPreview },
    ];

    if (showPreview) pages.push({ name: 'preview', canSkip: true, showMainButton: true });

    const { currentPage, goForward, goBack, headerPages, footerPages } = getNavFunctions({
        on,
        scrollTo,
        pages,
    });

    const canGoForward = !!state.main.title;

    if (!currentUser) return <h1>You must log in! How did you even get here?</h1>;

    return (
        <section className="rainbow-road-builder" ref={container}>
            <HorizontalNavHeader
                currentPage={currentPage}
                pages={headerPages}
                skipPage={goForward}
            />

            <section className={canGoForward ? '' : 'no-scroll'}>
                <RRBuilderMain
                    title={title}
                    state={state.main}
                    setState={updateSlice('main')}
                    onSubmit={() => scrollTo.header()}
                    ref={refs.main}
                />

                <RRBuilderHeader
                    title={state.main.title}
                    state={state.header}
                    setState={updateSlice('header')}
                    onSubmit={() => scrollTo.body()}
                    ref={refs.header}
                />

                <RRBuilderBody
                    title={state.main.title}
                    state={state.body}
                    setState={updateSlice('body')}
                    onSubmit={() => scrollTo.nodes()}
                    ref={refs.body}
                />

                <RRBuilderNodes
                    title={state.main.title}
                    state={state.nodes}
                    setState={updateSlice('nodes')}
                    onSubmit={() => scrollTo.preview()}
                    ref={refs.nodes}
                />

                {showPreview && <RRBuilderPreview title={title} state={state} ref={refs.preview} />}
            </section>

            <HorizontalNavFooter
                currentPage={currentPage}
                pages={footerPages}
                mainAction={() => onSubmit(state)}
                canDoMainAction={!!state.main.title}
                goBack={goBack}
                goForward={goForward}
                canGoForward={canGoForward}
                buttonText={buttonText}
            />
        </section>
    );
};

export default RRBuilder;
