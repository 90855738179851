/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

// import LottieLoading from '@components/generic/loading/LottieLoading';
// import LottieAnimation from '@components/generic/loading/lotties/circle-spinner.json';

import { User } from '@shared/welibrary-graphql/types';

import { getRandomDefaultUserImage } from '@core/utilities/constants/defaults';

export const TicketSharingFormUserInput: React.FC<{
    ticketId: string;
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    foundUsers: User[];
    emptyResults: boolean;
    handleAddAttendee: (ticketId: string, user?: User | null, email?: string) => void;
}> = ({ ticketId, searchQuery, setSearchQuery, foundUsers, emptyResults, handleAddAttendee }) => {
    return (
        <div className="ticket-sharing-input-wrap">
            <input
                value={searchQuery}
                onChange={e => setSearchQuery(e.currentTarget.value)}
                className=""
                type="text"
                maxLength={256}
                placeholder="Find people"
            />
            {foundUsers?.length > 0 && !emptyResults && (
                <ul className="ticket-sharing-dropdown">
                    {foundUsers?.map(user => {
                        return (
                            <li
                                key={user._id}
                                className="ticket-sharing-dropdown-item"
                                onClick={() => {
                                    handleAddAttendee(ticketId, user);
                                    setSearchQuery('');
                                }}
                            >
                                <div className="ticket-sharing-dropdown-item-details">
                                    <div className="ticket-sharing-dropdown-item-img-wrap">
                                        <img
                                            src={
                                                user?.profile?.picture ||
                                                getRandomDefaultUserImage()
                                            }
                                            alt="user profile pic"
                                        />
                                    </div>
                                    <p>{user?.profile?.full_name}</p>
                                </div>
                                <div className="ticket-sharing-dropdown-btn-wrap">
                                    <button type="button">+</button>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            )}
            {emptyResults && (
                <div className="ticket-sharing-dropdown ticket-sharing-dropdown-no-users">
                    No users found
                </div>
            )}
        </div>
    );
};

export const TicketSharingFormEmailInput: React.FC<{
    ticketId: string;
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    handleAddAttendee: (ticketId: string, user?: User | null, email?: string) => void;
}> = ({ ticketId, searchQuery, setSearchQuery, handleAddAttendee }) => {
    return (
        <div className="ticket-sharing-input-wrap ticket-sharing-input-email-wrap">
            <input
                value={searchQuery}
                onChange={e => setSearchQuery(e.currentTarget.value)}
                className="text-field w-input"
                type="text"
                maxLength={256}
                placeholder="Enter Email"
            />
            <div
                className="ticket-sharing-dropdown-btn-add-email"
                onClick={() => {
                    handleAddAttendee(ticketId, null, searchQuery);
                    setSearchQuery('');
                }}
            >
                <button type="button">Add Email</button>
            </div>
        </div>
    );
};
