/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
    EventHandler,
    SyntheticEvent,
    useEffect,
    useState,
    Suspense,
    ImgHTMLAttributes,
    CSSProperties,
} from 'react';

import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary';
import {
    getProvider,
    DEFAULT_RESOLUTIONS,
    ImageMetadata,
    changeQuality,
    fixUrl,
    generateSrcSet,
    getMetadata,
    resizeAndChangeQuality,
} from '@helpers/images/images.helpers';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

type ErrorState = typeof ERROR_STATES[keyof typeof ERROR_STATES];

type PictureProps = {
    url: string;
    onLoad?: EventHandler<SyntheticEvent<HTMLImageElement, Event>>;
    alt?: string;
    className?: string;
    transition?: CSSTransitionState;
    style?: CSSProperties;
    resolutions?: number[];
    maxWidth?: number;
    onClickCallback?: () => void; // optional callback when image is clicked
    imageMeta?: any;
    disableLink?: boolean;
    children?: React.ReactNode;
    disableButton?: boolean;
    innerRef?: any;
};

const ERROR_STATES = { NOERROR: false, BAD_URL: 'BAD_URL', TIMEOUT: 'TIMEOUT' } as const;

type CSSTransitionState = {
    initial: CSSProperties;
    final: CSSProperties;
};

const DEFAULT_TRANSITION = {
    initial: { opacity: 0, filter: 'blur(2px)' },
    final: { opacity: 1, filter: 'blur(0px)' },
};

// This is a transparent inline svg that can be used as a placeholder, it loads instantly as it is is basically nothing like not even 1kb, if the metadata is known
// then we also have the correct ratio dimensions before the image loads, must make sure this is positioned relatively to take up space
// and the image on top absolutely
type ImgDimension = number | string | undefined;

const placeholderSrc = (width: ImgDimension = '100%', height: ImgDimension = '100%') => {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
};

// this pattern is based from the react docs
// https://reactjs.org/docs/concurrent-mode-suspense.html

type SuspenseImageProps = {
    attributes: ImgHTMLAttributes<HTMLImageElement>;
};

export const SuspenseImage: React.FC<SuspenseImageProps> = ({ attributes }) => {
    const width = attributes?.width;
    const height = attributes?.height;
    const placeholder = placeholderSrc(width, height);
    const src = attributes?.src || placeholder;
    const srcSet = attributes?.srcSet;

    // The way this works is this function will throw a promise until it is resolved/rejected
    // when resolved it will return in this case the source used to set the img src
    // const source = imageLoader(src, originalUrl).read();  // This is disabled for now while I work out some kinks
    // We don't really need to use it now that we are using the placeholder and meta information as that mostly solves the issues
    // so a later refactor might be in order but it is okay for now

    return (
        <>
            <img src={placeholder} width={width} height={height} alt="" role="presentation" />
            <img
                src={src}
                srcSet={srcSet}
                width={width}
                height={height}
                alt={attributes?.alt}
                onLoad={attributes?.onLoad}
                onError={attributes?.onError}
            />
        </>
    );
};

type PictureLoadingProps = {
    metadata?: any;
};
const PictureLoading: React.FC<PictureLoadingProps> = ({ metadata }) => {
    const width = metadata ? metadata?.width : '100%';
    const height = metadata ? metadata?.height : '100%';
    const placeholder = placeholderSrc(width, height);
    return <img src={placeholder} width={width} height={height} alt="" role="presentation" />;
};

const Picture = React.memo<PictureProps>(function Picture({
    url,
    onLoad,
    alt = '',
    className = '',
    style = {},
    resolutions = DEFAULT_RESOLUTIONS,
    disableLink = false,
    children,
    imageMeta,
    onClickCallback = undefined,
    disableButton = false,
    innerRef,
}) {
    const [errorState, setErrorState] = useState<ErrorState>(ERROR_STATES.NOERROR);
    // const [loaded, setLoaded] = useState(false);
    const [responsiveLoaded, setResponsiveLoaded] = useState(false);
    const [metadata, setMetadata] = useState<ImageMetadata | undefined>();

    const [imageOpen, setImageOpen] = useState(false);

    const provider = getProvider(url);

    const handleResponsiveImageLoaded = () => {
        setResponsiveLoaded(true);
    };

    const handleLoaded: EventHandler<SyntheticEvent<HTMLImageElement, Event>> = e => {
        // setLoaded(true);
        onLoad?.(e);
    };

    const handleError: EventHandler<SyntheticEvent<HTMLImageElement, Event>> = e => {
        setErrorState('BAD_URL');
        handleLoaded(e);
    };

    useEffect(() => {
        setErrorState(ERROR_STATES.NOERROR);
        // setLoaded(false);
        setResponsiveLoaded(false);
        setMetadata(undefined);
    }, [url]);

    useEffect(() => {
        if (provider === 'filestack' && !imageMeta) getMetadata(url).then(setMetadata);
    }, [url, provider]);

    // The 'High res' versions of our image that load in after our tiny initial image
    const responsiveImageAttributes = {
        alt,
        onLoad: handleResponsiveImageLoaded,
        onError: () => setErrorState(ERROR_STATES.TIMEOUT),
        style: responsiveLoaded
            ? { opacity: 1, filter: 'blur(0px)' }
            : { opacity: 0, filter: 'blur(2px)' },
        height: imageMeta?.height || metadata?.height,
        width: imageMeta?.width || metadata?.width,
        src:
            errorState === ERROR_STATES.TIMEOUT || provider === 'unsplash' // don't fix the src url for unsplash. "auto=format" breaks the url if a width isn't specified
                ? url
                : fixUrl(url, metadata?.mimetype),
        srcSet: generateSrcSet(url, resolutions, { mimetype: metadata?.mimetype, fix: true }),
    };

    const hasMetaDimensions = metadata?.height && metadata?.width;

    // The initial low res image loaded in
    const imgAttributeStyle = responsiveLoaded
        ? DEFAULT_TRANSITION.initial
        : DEFAULT_TRANSITION.final;

    const imageAttributes = {
        onLoad: handleLoaded,
        onError: handleError,
        style: imgAttributeStyle,
        alt,
        src: resizeAndChangeQuality(url, 100, 1, {
            mimetype: metadata?.mimetype,
            fix: true,
        }),
        aspectRatio: hasMetaDimensions ? metadata?.width / metadata?.height : undefined,
        height: imageMeta?.height || metadata?.height,
        width: imageMeta?.width || metadata?.width,
    };

    const gifStyle = DEFAULT_TRANSITION.final;

    const gifAttributes = {
        onLoad: handleResponsiveImageLoaded,
        onError: () => setErrorState(ERROR_STATES.TIMEOUT),
        style: gifStyle,
        alt,
        src: fixUrl(changeQuality(url, 75), metadata?.mimetype),
        srcSet: generateSrcSet(
            changeQuality(url, 75),
            resolutions.map(resolution => resolution / 2),
            {
                mimetype: metadata?.mimetype,
                fix: true,
            }
        ),
        height: imageMeta?.height || metadata?.height,
        width: imageMeta?.width || metadata?.width,
        aspectRatio: hasMetaDimensions ? metadata?.width / metadata?.height : undefined,
    };

    // Fallback
    const basicAttributes = {
        onLoad: handleLoaded,
        onError: handleError,
        height: imageMeta?.height || metadata?.height,
        width: imageMeta?.width || metadata?.width,
        alt,
        src: url,
    };

    if (!provider || errorState === ERROR_STATES.BAD_URL) {
        return (
            <figure className={`picture-figure ${className}`} ref={innerRef}>
                <ErrorBoundary>
                    <Suspense fallback={<PictureLoading metadata={metadata} />}>
                        <SuspenseImage attributes={basicAttributes} />
                    </Suspense>
                </ErrorBoundary>

                {children}
            </figure>
        );
    }

    const handleLightbox = () => {
        if (!disableLink) setImageOpen(true);
    };

    const image = (
        <>
            {metadata?.mimetype === 'image/gif' && !errorState ? (
                <picture>
                    <source
                        srcSet={generateSrcSet(
                            changeQuality(url, 75),
                            resolutions.map(resolution => resolution / 2),
                            {
                                mimetype: metadata?.mimetype,
                                fix: true,
                                webp: true,
                            }
                        )}
                        type="image/webp"
                    />
                    <ErrorBoundary>
                        <Suspense fallback={<PictureLoading metadata={metadata} />}>
                            <SuspenseImage attributes={gifAttributes} />
                        </Suspense>
                    </ErrorBoundary>
                </picture>
            ) : (
                <ErrorBoundary>
                    <Suspense fallback={<PictureLoading metadata={metadata} />}>
                        <SuspenseImage attributes={responsiveImageAttributes} />
                    </Suspense>
                </ErrorBoundary>
            )}
            {imageOpen && (
                <Lightbox
                    mainSrc={url}
                    onAfterOpen={() => {
                        if (onClickCallback) onClickCallback();
                    }}
                    onCloseRequest={() => setImageOpen(false)}
                />
            )}
        </>
    );

    return (
        <figure className={`picture-figure ${className} disablelink-${disableLink}`} ref={innerRef}>
            <ErrorBoundary>
                <React.Suspense fallback={<PictureLoading metadata={metadata} />}>
                    <SuspenseImage attributes={imageAttributes} />
                </React.Suspense>
            </ErrorBoundary>
            {disableLink && (
                <div style={style} className="picture-button">
                    {image}
                </div>
            )}
            {!disableLink && (
                <button
                    style={style}
                    className="picture-button"
                    type="button"
                    onClick={handleLightbox}
                    disabled={disableButton}
                >
                    {image}
                </button>
            )}
            {children}
        </figure>
    );
});

export default Picture;
