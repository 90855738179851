import React from 'react';
import { sortBy } from 'lodash';

import TicketsFormContainer from '@components/content/newpost/forms/ShoppingCartProducts/TicketsFormContainer';
import ErrorBoundary from '@components/generic/errors/ErrorBoundary.js';
import LoadingLine from '@components/generic/loading/LoadingLine';
import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/circle-spinner.json';

import { useGetProductsForGroupsQuery } from '@shared/welibrary-graphql/user/queries.hook';

import { Group } from '@shared/welibrary-graphql/types';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';
import { CARD_EVENT } from '~/wl-core/utilities/constants/card_types';

const EditTicketsFormContainer: React.FC<{
    group: Group;
    merchantAccountFullyActivated?: boolean;
    inEditMode?: boolean;
}> = ({ group, merchantAccountFullyActivated }) => {
    const { data, loading, error } = useGetProductsForGroupsQuery({
        variables: {
            groupId: group._id,
        },
        fetchPolicy: 'no-cache',
    });

    let tickets = data?.getProductsForGroup ?? [];
    // client side sorting
    // so the free ticket always shows up as the first item in the list
    tickets = sortBy(tickets, ({ type }) =>
        type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP ? 0 : 1
    );

    const isEvent = group?.subtype === CARD_EVENT;

    // filter out freebie subscriptions from showing up
    // when editing subscriptions
    if (!isEvent) {
        tickets = tickets?.filter(
            product => product?.type !== PRODUCT_TYPES.GROUP_FREE_SUBSCRIPTION
        );
    }

    return (
        <ErrorBoundary>
            <LoadingLine isLoading={loading} error={error} />
            <>
                {loading ? (
                    <div className="shopping-cart-modal-loading">
                        <LottieLoading height={200} width={200} lottieData={LottieAnimation} />
                    </div>
                ) : (
                    <TicketsFormContainer
                        productData={tickets}
                        group={group}
                        merchantAccountFullyActivated={merchantAccountFullyActivated}
                        inEditMode
                    />
                )}
            </>
        </ErrorBoundary>
    );
};

export default EditTicketsFormContainer;
