import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    ApolloCache,
    DefaultContext,
    MutationFunctionOptions,
    OperationVariables,
    useMutation,
    useQuery,
} from '@apollo/client';
import LockedGroup from '@components/group/LockedGroup';
import useModal from '@components/modals/hooks/useModal';
import { ModalTypes } from '@core/types/Modals';
import { capitalize, titleCase } from '@helpers/string.helpers';
import { CustomSignupFieldTypes, Group, UserProfile } from '@shared/welibrary-graphql/types';
import { UPDATE_USER_GROUP_SURVEY_DISMISSAL_TIME } from '@shared/welibrary-graphql/user/mutations';
import { GET_LAST_TIME_USER_DISMISSED_GROUP_SURVEY } from '@shared/welibrary-graphql/user/queries';
import { useCurrentUser } from '@stores/User';
import useUserOptimizers from '@web/ui/components/optimizers/hooks/useUserOptimizers';

import GroupPageOptimizer from '../optimizers/GroupPageOptimizer';
import { areDependentQuestionsAnswered } from '@helpers/customFields/customFields.helpers';

type IEvaluateSurveyQuestionsStatus = (
    group: Group,
    lastTimeUserDismissedGroupSurvey: string,
    profile: UserProfile
) => {
    isRequiredSurveyLockNeeded?: boolean;
    surveyCompletionPercentage?: number;
    isSurveyIncomplete?: boolean;
    showGroupSurveyModal?: boolean;
    surveyQuestionsExist?: boolean;
};

const evaluateSurveyQuestionStatus: IEvaluateSurveyQuestionsStatus = (
    group,
    lastTimeUserDismissedGroupSurvey,
    profile
) => {
    const groupId = group?._id;
    const lastCustomSignupFieldsUpdate = group?.settings?.lastCustomSignupFieldsUpdate;

    const getUserAnswers = () => {
        // Get the user's answers to the group's custom signup questions

        const rawUserAnswers =
            profile?.customFields?.find(customField => customField?.parentGroup?._id === groupId)
                ?.values || [];
        // The ChooseRole question type is deprecated
        const userAnswersExcludingChooseRoleQuestion = rawUserAnswers.filter(
            answer => answer?.type !== CustomSignupFieldTypes.ChooseRole
        );

        const uniqueUserAnswers = _.uniqBy(userAnswersExcludingChooseRoleQuestion, 'id');
        return uniqueUserAnswers;
    };

    const answers = getUserAnswers();

    const answerState = answers.reduce(
        (acc, value) => {
            acc[value.id] = { value: value.values };
            return acc;
        },
        {} as Record<string, { value: string | string[] }>
    );

    const getQuestions = () => {
        const flags = useFlags();
        const enableJuriesRequest = flags?.enableJuriesRequest;
        const rawCustomSignupQuestions = group?.settings?.customSignupFields || [];
        const excludedQuestionTypes = [
            CustomSignupFieldTypes.ChooseRole, // The ChooseRole question type is deprecated
            CustomSignupFieldTypes.JoinGroup,
            CustomSignupFieldTypes.Description, // The Description question doesn't require an answer
            !enableJuriesRequest ? CustomSignupFieldTypes.Signature : null, // The Signature question type is part of the "Juries Request" feature
        ];
        const filteredCustomSignupQuestions = rawCustomSignupQuestions.filter(
            question => !excludedQuestionTypes.includes(question?.type)
        );
        const uniqueQuestions = _.uniqBy(filteredCustomSignupQuestions, 'id');
        return uniqueQuestions.filter(question =>
            areDependentQuestionsAnswered(question, answerState, uniqueQuestions)
        );
    };

    const isAdmin = group?.currentUserIsAdmin || group?.currentUserIsDirectAdmin;

    const questions = getQuestions();

    const isQuestionUnanswered = question =>
        !answers.find(answer => answer?.id === question?.id && answer?.values && answer?.values[0]);

    const unansweredQuestions = questions.filter(isQuestionUnanswered);

    const unansweredRequiredQuestions = unansweredQuestions.filter(q => q.required);
    const unansweredUnrequiredQuestions = unansweredQuestions.filter(q => !q.required);

    const isRequiredSurveyUnanswered = unansweredRequiredQuestions.length > 0;

    // Unsure why this logic is needed -or if it is, the behavior needs adjustment
    // for now this is causing confusion for users where they expect not to see the survey if the questions
    // are not required...see https://welibrary.atlassian.net/browse/WE-3257

    const isRequiredSurveyLockNeeded = profile && isRequiredSurveyUnanswered && !isAdmin;

    const showGroupSurveyModal = isRequiredSurveyLockNeeded;

    const totalQuestionsCount = questions.length;
    const totalAnsweredCount = totalQuestionsCount - unansweredQuestions.length;
    const surveyCompletionPercentage = Math.round((totalAnsweredCount / totalQuestionsCount) * 100);

    return {
        isRequiredSurveyLockNeeded,
        surveyCompletionPercentage,
        isSurveyIncomplete: unansweredQuestions.length > 0,
        showGroupSurveyModal,
        surveyQuestionsExist: questions.length > 0,
    };
};

type IUseSurveyModalManager = (
    group: Group,
    updateUserGroupSurveyDismissalTime: (
        options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>>
    ) => Promise<any>,
    groupTypeText: 'event' | 'group',
    isRequiredSurveyLockNeeded: Boolean
) => {
    openSurveyModal: () => void;
    isSubmitting: boolean;
};

const useSurveyModalManager: IUseSurveyModalManager = (
    group,
    updateUserGroupSurveyDismissalTime,
    groupTypeText,
    isRequiredSurveyLockNeeded
) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { t } = useTranslation();
    const { newModal, closeAllModals } = useModal();
    const { currentUser, refetchCurrentUser } = useCurrentUser();
    const { profile, updateQuery } = useUserOptimizers(currentUser?._id);
    const onModalClose = () => {
        updateUserGroupSurveyDismissalTime({
            variables: {
                groupId: group?._id,
            },
        });
    };

    const withLatestProfileData = GroupPageOptimizerComponent => {
        const GroupPageOptimizerComponentWithLatestProfileData = () => {
            const { profile, updateQuery } = useUserOptimizers(currentUser?._id, async () => {
                closeAllModals();
                setIsSubmitting(true);
                refetchCurrentUser();
                setIsSubmitting(false);
            });

            return (
                <GroupPageOptimizerComponent
                    profile={profile}
                    updateQuery={updateQuery}
                    group={group}
                />
            );
        };
        return GroupPageOptimizerComponentWithLatestProfileData;
    };

    const GroupPageOptimizerWithLatestProfileData = withLatestProfileData(GroupPageOptimizer);

    const openSurveyModal = () => {
        const hostNeedsInfoText = t(
            'common:host_needs_a_little_more_information.',
            'host needs a little more information.'
        );
        const answerRequiredQuestionsText = capitalize(
            t(`common:answer_the_questions_to_access_the`, 'Answer the questions to access the')
        );
        const answerUnrequiredQuestionsText = capitalize(
            t(`common:answer_the_questions_to_help_the`, 'Answer the questions to help the')
        );

        const title = `${capitalize(groupTypeText)} ${capitalize(t(`common:survey`, 'survey'))}`;
        const description = `${capitalize(
            t('common:the', 'the')
        )} ${groupTypeText} ${hostNeedsInfoText} ${isRequiredSurveyLockNeeded ? answerRequiredQuestionsText : answerUnrequiredQuestionsText
            } ${groupTypeText}.`;

        newModal(
            <section className="dashboard-welcome-survey flex flex-col gap-[30px] !px-[20px]">
                <div className="flex flex-col gap-[10px]">
                    <h1 className="text-grayscale-title-active font-poppins my-0 text-center text-[32px] font-[500]">
                        {title}
                    </h1>
                    <span className="text-grayscale-body text-center text-[18px]">
                        {description}
                    </span>
                </div>
                <GroupPageOptimizerWithLatestProfileData />
            </section>,
            {
                hideButton: false,
                sectionClassName: 'dashboard-survey-modal',
                onClose: () => onModalClose(),
                confirmClose: t(
                    'common:are_you_sure_you_want_to_cancel?_Any_unsaved_changes_will_be_lost',
                    'Are you sure you want to cancel? Any unsaved changes will be lost.'
                ),
            },
            { mobile: ModalTypes.Center, desktop: ModalTypes.Center }
        );
    };

    return { openSurveyModal, isSubmitting };
};

interface ISurveyLockedGroupComponent {
    isSubmitting: boolean;
    groupTypeText: 'event' | 'group';
    openSurveyModal: () => void;
}
const SurveyLockedGroupComponent: React.FC<ISurveyLockedGroupComponent> = ({
    isSubmitting,
    groupTypeText,
    openSurveyModal,
}) => {
    const { t } = useTranslation();
    const SURVEY_LOCK_DESCRIPTION = isSubmitting ? (
        <>
            {capitalize(t('common:survey_complete.', 'survey complete.'))}{' '}
            <a onClick={() => window.location.reload()}>
                {capitalize(t('common:loading', 'loading'))} {groupTypeText}…
            </a>
        </>
    ) : (
        <>
            {capitalize(
                t(
                    'common:please_answer_the_required_group_survey_questions_to_access_the_group.',
                    'please answer the required group survey questions to access the group.'
                )
            )}
        </>
    );

    return (
        <LockedGroup
            lockDescription={SURVEY_LOCK_DESCRIPTION}
            extraButton={
                !isSubmitting ? (
                    <button
                        className="button callout-button top-margin w-button"
                        onClick={openSurveyModal}
                    >
                        {titleCase(t('common:answer_questions', 'answer questions'))}
                    </button>
                ) : null
            }
        />
    );
};

type IUseGroupSurveyAccessControl = (props: { group: Group }) => {
    isRequiredSurveyLockNeeded?: boolean;
    surveyLockedGroupComponent?: JSX.Element;
    surveyCompletionPercentage?: number;
    isSurveyIncomplete?: boolean;
    showGroupSurveyModal?: boolean;
    openSurveyModal?: () => void;
    surveyQuestionsExist?: boolean;
};

const useGroupSurveyAccessControl: IUseGroupSurveyAccessControl = ({ group }) => {
    // This checks the LD flag in order to disable/enable
    // if it is disabled (false), then this hook returns an empty object
    const flags = useFlags();
    const enableNewGroupSurvey = flags?.enableNewGroupSurveyBehavior;

    const isMember =
        group?.currentUserIsMember || group?.currentUserIsAdmin || group?.currentUserIsDirectAdmin;
    const { t } = useTranslation();
    const { data: lastTimeUserDismissedGroupSurveyData, loading } = useQuery(
        GET_LAST_TIME_USER_DISMISSED_GROUP_SURVEY,
        {
            variables: { groupId: group?._id },
            fetchPolicy: 'network-only',
            skip: !isMember,
        }
    );
    const { currentUser } = useCurrentUser();
    const { profile, loading: optimizerLoading } = useUserOptimizers(currentUser?._id);

    const [updateUserGroupSurveyDismissalTime] = useMutation(
        UPDATE_USER_GROUP_SURVEY_DISMISSAL_TIME
    );
    const isEventGroup = group?.subtype === 'event';

    const groupTypeText: 'event' | 'group' = isEventGroup
        ? t(`common:event.event`, 'event')
        : t(`common:group.group`, 'group');

    const {
        isRequiredSurveyLockNeeded,
        surveyCompletionPercentage,
        isSurveyIncomplete,
        showGroupSurveyModal,
        surveyQuestionsExist,
    } = evaluateSurveyQuestionStatus(
        group,
        lastTimeUserDismissedGroupSurveyData?.getLastTimeUserDismissedGroupSurvey,
        profile
    );

    const { openSurveyModal, isSubmitting } = useSurveyModalManager(
        group,
        updateUserGroupSurveyDismissalTime,
        groupTypeText,
        isRequiredSurveyLockNeeded
    );

    if (loading) return {};

    if (!isMember) return {};

    if (!enableNewGroupSurvey) return {};

    return {
        isRequiredSurveyLockNeeded,
        surveyLockedGroupComponent: (
            <SurveyLockedGroupComponent
                openSurveyModal={openSurveyModal}
                groupTypeText={groupTypeText}
                isSubmitting={isSubmitting}
            />
        ),
        surveyCompletionPercentage,
        isSurveyIncomplete,
        showGroupSurveyModal,
        openSurveyModal,
        surveyQuestionsExist,
    };
};

export default useGroupSurveyAccessControl;
