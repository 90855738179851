import {useContext} from 'react';
import {RemarkableContext} from '@web/ui/components/generic/context/RemarkableContext';

const useMd = () => {
    /** @type Remarkable */
    const md = useContext(RemarkableContext);

    if (!md) throw new Error('useMd has been used outside of a RemarkableProvider!');

    return md;
};

export default useMd;
