import React from 'react';
import { useTranslation } from 'react-i18next';

import signUpStore from '@stores/SignUp';

import PostSignupQuestions from './PostSignupQuestions';

const localNamespace = 'imports.wlWeb.ui.pages.post-signup.network-questions';

type GroupQuestionsProps = {
    goToNextStep: () => any;
    goToPreviousStep: () => any;
    redirectUrl: string;
};

const GroupQuestions: React.FC<GroupQuestionsProps> = ({
    goToNextStep,
    goToPreviousStep,
    redirectUrl,
}) => {
    const { t } = useTranslation();

    const groupQuestionsIndex = signUpStore.useTracked.groupQuestionsIndex();
    const groupsWithQuestions = signUpStore.get.groupsWithQuestions();
    const initialGroup = signUpStore.get.activeGroup();
    const allMyGroups = [{ group: initialGroup }, ...signUpStore.get.groupsJoinedAndRequested()];

    // it's possible to get in a state where groupsWithQuestions contains groups that we didn't actually join
    //   e.g. if a group code was entered for a group with questions, it'll be added to groupsWithQuestions
    //        but then if the group code is changed, we end up with a group in groupsWithQuestions that we didn't actually join
    // so this just filters out groups that we didn't actually join
    const myGroupsWithQuestions = groupsWithQuestions.filter(id =>
        allMyGroups.find(allGroup => allGroup.group._id === id)
    );

    const groupId = myGroupsWithQuestions[groupQuestionsIndex];
    const activeGroup = allMyGroups.find(g => g.group?._id === groupId)?.group;

    if (myGroupsWithQuestions.length === 0) {
        goToNextStep();
    }

    const nextGroupOrStep = () => {
        let nextIndex = groupQuestionsIndex + 1;

        if (nextIndex < myGroupsWithQuestions.length) {
            signUpStore.set.groupQuestionsIndex(nextIndex);
        } else {
            goToNextStep();
        }
    };

    const prevGroupOrStep = () => {
        let prevIndex = groupQuestionsIndex - 1;

        if (prevIndex >= 0) {
            signUpStore.set.groupQuestionsIndex(prevIndex);
        } else {
            goToPreviousStep();
        }
    };

    return (
        <PostSignupQuestions
            groupId={groupId}
            headerText={`${activeGroup?.profile?.full_name} ${t(
                `common:${localNamespace}.questions`,
                'Questions'
            )}`}
            subtitle={activeGroup?.settings?.registrationDescription}
            goToNextStep={nextGroupOrStep}
            goToPreviousStep={prevGroupOrStep}
            redirectUrl={redirectUrl}
        />
    );
};

export default GroupQuestions;
