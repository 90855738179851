import React from 'react';

import useModal from '@components/modals/hooks/useModal';

import AdventureSelector from '@components/quest/AdventureSelectorModal';
import Picture from '@components/generic/Picture';
import Plus from '@dsc/svgs/FullSizePlus';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';
import { QuestCreationType } from '@core/types/Quests';
import { RainbowRoadNodesSettings, RrCircleSize, CreationState } from '@shared/welibrary-graphql/types';

import { Maybify } from '@core/types/Utilities';
import { ModalTypes } from '@core/types/Modals';

type QuestActionButtonProps = {
    title?: string | null;
    settings?: RainbowRoadNodesSettings | null;
    className?: string;
    questId: string;
    questMap?: string;
    creations: QuestCreationType[];
    creationsState?: Array<Maybify<CreationState> | null> | null;
    isComplete?: boolean;
};

const QuestActionButton: React.FC<QuestActionButtonProps> = ({
    title,
    settings,
    className = '',
    questId,
    questMap,
    creations,
    creationsState,
    isComplete = false,
}) => {
    const { newModal } = useModal({ mobile: ModalTypes.FullScreen, desktop: ModalTypes.Center });

    if (!isComplete && creations.length === 0) return <></>;

    const { circleSize = RrCircleSize.Medium, icon, spinnerImage } = settings || {};

    const showIcon = typeof icon === 'string';

    return (
        <section
            className={`rainbow-road-node main no-outline ${circleSize}-circle ${
                showIcon ? 'nobg' : ''
            } ${className}`}
        >
            <button
                type="button"
                className="new-node"
                onClick={() => {
                    newModal(
                        <AdventureSelector
                            questId={questId}
                            questMap={questMap}
                            creations={creations}
                            creationsState={creationsState}
                            isComplete={isComplete}
                        />,
                        {
                            hideButton: true,
                            sectionClassName: 'full-screen-modal-transparent-bg',
                        }
                    );
                }}
            >
                {showIcon ? (
                    <Picture
                        url={icon || getRandomDefaultCoverImage(title ?? '')}
                        disableLink
                        resolutions={[160, 320, 640]}
                    />
                ) : (
                    <Plus strokeWidth="2" />
                )}
                {spinnerImage && (
                    <Picture
                        url={spinnerImage}
                        disableLink
                        resolutions={[160, 320, 640]}
                        className="spinner"
                    />
                )}
            </button>
        </section>
    );
};

export default QuestActionButton;
