import React, { useContext } from 'react';

import ToastContext from '@components/generic/context/ToastContext';
import { useCurrentUser } from '@stores/User';

import SurveyLongAnswer from '@components/survey/SurveyLongAnswer';
import SurveyImage from '@components/survey/SurveyImage';
import CustomLegoToastFooter from '@components/toast/Lego/CustomLegoToastFooter';
import SSLIcon from '@assets/lego-ssl-icon.png';
import Camera from '@dsc/svgs/Camera';
import Chat from '@dsc/svgs/Chat';

import { canUploadPhotos } from '@helpers/user/child.helpers';

import { SurveyImageOrTextState } from '@core/types/Surveys';
import { Maybify } from '@core/types/Utilities';
import { SurveyQuestion } from '@shared/welibrary-graphql/types';

type SurveyImageOrTextProps = {
    state: SurveyImageOrTextState;
    setState: (value: SurveyImageOrTextState) => void;
    question: Maybify<SurveyQuestion>;
    className?: string;
    disabled?: boolean;
    viewOnly?: boolean;
};

const SurveyImageOrText: React.FC<SurveyImageOrTextProps> = ({
    state,
    setState,
    question,
    className = '',
    disabled = false,
    viewOnly = false,
}) => {
    const { currentUser } = useCurrentUser();
    const { showCustomToast } = useContext(ToastContext);

    const clickCameraButton = () => {
        if (canUploadPhotos(currentUser)) setState({ type: 'image', value: '' });
        else {
            showCustomToast(
                'You do not have permission to upload photos!',
                null,
                5000,
                'responsive',
                SSLIcon,
                <CustomLegoToastFooter />
            );
        }
    };

    if (!state.type) {
        return (
            <section className={`survey-question-image-or-text ${className}`}>
                {!viewOnly && (
                    <>
                        <section>
                            <button type="button" onClick={clickCameraButton} disabled={disabled}>
                                <Camera />
                            </button>
                            <button
                                type="button"
                                onClick={() => setState({ type: 'text', value: '' })}
                                disabled={disabled}
                            >
                                <Chat />
                            </button>
                        </section>

                        <p>{question.prompt}</p>
                    </>
                )}
                {viewOnly && <p>No Image Or Text</p>}
            </section>
        );
    }

    if (state.type === 'text') {
        return (
            <SurveyLongAnswer
                state={state.value}
                setState={value => setState({ type: 'text', value })}
                question={question}
                className={className}
                disabled={disabled}
                viewOnly={viewOnly}
            />
        );
    }

    if (state.type === 'image') {
        return (
            <SurveyImage
                state={state.value}
                setState={value => {
                    if (!value && state.value) setState({ type: '', value: '' });
                    else setState({ type: 'image', value });
                }}
                question={question}
                className={className}
                disabled={disabled}
                viewOnly={viewOnly}
            />
        );
    }

    return <></>;
};

export default SurveyImageOrText;
