import React, { CSSProperties, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMySidebarQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { awsMetricPublisher } from '@welibraryos/metrics';

import useNavNotifications from '@components/generic/notifications/hooks/useNavNotifications';
import useNavigation from '@components/navigation/useNavigation';
import useModal from '@components/modals/hooks/useModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCurrentUser } from '@stores/User';

import Discover from '@components/discover/DiscoverModal';
import ExternalLink from '@components/generic/ExternalLink';
import Notifications from '@dsc/svgs/Notifications';
import MagnifyingGlass from '@dsc/svgs/MagnifyingGlass';
import Analytics from '@dsc/svgs/Analytics';
import Gear from '@dsc/svgs/Gear';
import Check from '@dsc/svgs/Check';
import UserGuide from '@dsc/svgs/UserGuide';
import Connections from '@dsc/svgs/Group';
import Bookmark from '@dsc/svgs/Bookmark';
import Calendar from '@dsc/svgs/CalendarIcon3';

import { useCurrentUserIsAdminOfAnyGroupQuery } from '@shared/welibrary-graphql/user/queries.hook';

import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';
import { humanReadable } from '@helpers/numberHelpers';
import { showNavItem } from '@helpers/navigation/secondaryNav.helpers';

import { ModalTypes } from '@core/types/Modals';

const localNamespace = 'imports.wlWeb.ui.components.navigation.slideMenu';

const SideMenuLinks: React.FC = () => {
    const { t } = useTranslation();

    const { currentUser } = useCurrentUser();

    const { data, loading, error } = useCurrentUserIsAdminOfAnyGroupQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const { setGroupSwitchOpenState, closeSlideMenu } = useNavigation();
    const { unreadNotifications } = useNavNotifications();

    const { userGuide, slideMenu: slideMenuSettings } = getWhitelabelPublicSettings();

    const { newModal } = useModal({ mobile: ModalTypes.Center });

    const { data: { mySidebar: { people = 0, bookmarks = 0 } = {} } = {} } = useMySidebarQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const sendMetricAndCloseMenu = (dimensionValue: string) => {
        awsMetricPublisher.publishCount('side-menu', 1, [{ Name: 'menu', Value: dimensionValue }]);
        closeSlideMenu();
    };

    const handleOpenAnalytics: MouseEventHandler<HTMLButtonElement> = () => {
        setGroupSwitchOpenState(true, 'analytics', 'groupAnalytics');
        sendMetricAndCloseMenu('analytics');
    };

    const hasAdminRole = data?.currentUserIsAdminOfAnyGroup;
    const isUserSuperAdmin = currentUser?.isUserSuperAdmin;

    const flags = useFlags();
    const siloedUsers = flags?.siloedUsers;
    let slideMenuSettingsDiscover = slideMenuSettings.discover;
    if (siloedUsers) slideMenuSettingsDiscover = !siloedUsers;

    // This is operating under the assumption if discover option is enabled,
    // then override the whielabel setting for notification bg color here to red
    const notificationStyle: CSSProperties = showNavItem('discover')
        ? { backgroundColor: 'red' }
        : { position: 'absolute' };

    return (
        <nav className="side-menu-main-links">
            <Link to="/notifications" onClick={() => sendMetricAndCloseMenu('notifications')}>
                <figure className="side-menu-main-icon">
                    <Notifications />
                    {unreadNotifications?.length > 0 && (
                        <figcaption style={notificationStyle}>
                            {humanReadable(unreadNotifications?.length)}
                        </figcaption>
                    )}
                </figure>
                <span>{t(`common:${localNamespace}.notifications`, 'Notifications')}</span>
            </Link>

            <Link to="/events" onClick={() => sendMetricAndCloseMenu('events')}>
                <figure className="side-menu-main-icon">
                    <Calendar />
                </figure>
                <span>{t(`common:${localNamespace}.events`, 'Events')}</span>
            </Link>

            <Link to="/u/?tab=connections" onClick={() => sendMetricAndCloseMenu('connections')}>
                {slideMenuSettings?.showConnectionsIcon ? (
                    <figure className="side-menu-main-icon">
                        <Connections />
                    </figure>
                ) : (
                    <h3 className="side-menu-main-icon">{humanReadable(people ?? 0)}</h3>
                )}
                <span>{t(`common:${localNamespace}.connections`, 'Connections')}</span>
            </Link>

            <Link to="/u/?tab=bookmarks" onClick={() => sendMetricAndCloseMenu('bookmarks')}>
                {slideMenuSettings?.showBookmarkIcon ? (
                    <figure className="side-menu-main-icon">
                        <Bookmark />
                    </figure>
                ) : (
                    <h3 className="side-menu-main-icon">{humanReadable(bookmarks ?? 0)}</h3>
                )}
                <span>{t(`common:${localNamespace}.bookmarks`, 'Bookmarks')}</span>
            </Link>

            {!!slideMenuSettings?.actionItems && isUserSuperAdmin && (
                <Link to="/discover/pledges" onClick={() => sendMetricAndCloseMenu('pledges')}>
                    <figure className="side-menu-main-icon">
                        <Check />
                    </figure>
                    <span>{t(`common:${localNamespace}.action_items`)}</span>
                </Link>
            )}

            {!!userGuide && (
                <ExternalLink
                    to={`${userGuide}`}
                    style={{ color: '#4e525a' }}
                    skipRelativeCheck
                    onClick={() => sendMetricAndCloseMenu('user-guide')}
                >
                    <figure className="side-menu-main-icon">
                        <UserGuide />
                    </figure>
                    <span>{t(`common:${localNamespace}.support_center`, 'Support Center')}</span>
                </ExternalLink>
            )}

            {!!slideMenuSettingsDiscover && (
                <button
                    type="button"
                    onClick={() => {
                        sendMetricAndCloseMenu('discover');
                        newModal(<Discover />, { hideButton: true });
                    }}
                >
                    <figure className="side-menu-main-icon">
                        <MagnifyingGlass />
                    </figure>
                    <span>{t(`common:${localNamespace}.discover`, 'Discover')}</span>
                </button>
            )}

            {hasAdminRole && (
                <button type="button" onClick={handleOpenAnalytics}>
                    <figure className="side-menu-main-icon">
                        <Analytics />
                    </figure>
                    <span>{t(`common:${localNamespace}.analytics`, 'Analytics')}</span>
                </button>
            )}

            {currentUser?.isUserSuperAdmin && (
                <Link to="/admin" onClick={() => sendMetricAndCloseMenu('admin')}>
                    <figure className="side-menu-main-icon">
                        <Gear />
                    </figure>
                    <span>{t(`common:${localNamespace}.admin`, 'Admin')}</span>
                </Link>
            )}
        </nav>
    );
};

export default SideMenuLinks;
