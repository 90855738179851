/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import ChapterInfo from '@web/ui/components/card/darkforms/ChapterInfo.js';
import Community from '@web/ui/components/card/darkforms/Community.js';
import Settings from '@web/ui/components/card/darkforms/Settings.js';

import DarkAdminHeader from '@web/ui/components/generic/editscreen/DarkAdminHeader';
import DarkAdminTabs from '@web/ui/components/generic/editscreen/DarkAdminTabs';

const INFO_TAB = 'Info',
  SETTINGS_TAB = 'Settings',
  ADMIN_TAB = 'Admin';

const cardNamespace = 'imports.wlWeb.ui.components.card';

class DarkChapterForm extends React.Component {
  render() {
    const { t, getFeatureConstraintsForField } = this.props;

    const infoConstraints = {
      promptConstraints: getFeatureConstraintsForField('prompt'),
      mediaThumbConstraints: getFeatureConstraintsForField('mediaThumb'),
      mediaIconConstraints: getFeatureConstraintsForField('mediaIcon'),
      descriptionConstraints: getFeatureConstraintsForField('description'),
    };
    let showInfo = false;
    _.each(_.values(infoConstraints), constraint => {
      if (constraint) showInfo = true;
    });

    const settingsConstraints = {
      sendNotificationsConstraints: getFeatureConstraintsForField('sendNotifications'),
      showPostTypesConstraints: getFeatureConstraintsForField('showPostTypes'),
      voteTypesFeatureConstraint: getFeatureConstraintsForField('voteTypes'),
      engagementTypesConstraint: getFeatureConstraintsForField('engagementTypes'),
      filterTypesConstraint: getFeatureConstraintsForField('filterTypes'),
      defaultFilterConstraint: getFeatureConstraintsForField('defaultFilter'),
      viewTypesConstraint: getFeatureConstraintsForField('viewTypes'),
      defaultViewTypesConstraint: getFeatureConstraintsForField('defaultViewType'),
      displayReferenceTypesConstraint: getFeatureConstraintsForField('displayReference'),
      postTypesConstraint: getFeatureConstraintsForField('postTypes'),
      allowPostboxConstraint: getFeatureConstraintsForField('allowPostbox'),
      postboxRoleWhitelistConstraint: getFeatureConstraintsForField('postboxRoleWhiteList'),
      displaySocialHeadersConstraint: getFeatureConstraintsForField('displaySocialHeaders'),
      cardAggregationConstraint: getFeatureConstraintsForField('cardAggregation'),
      tagAggregationConstraint: getFeatureConstraintsForField('tagAggregation'),
      isTemplateConstraint: getFeatureConstraintsForField('isTemplate'),
      displayInNewsfeedConstraint: getFeatureConstraintsForField('displayInNewsfeed'),
      showTitle: getFeatureConstraintsForField('showTitle'),
      shortTitle: getFeatureConstraintsForField('shortTitle'),
    };
    let showSettings = false;
    _.each(_.values(settingsConstraints), constraint => {
      if (constraint) showSettings = true;
    });

    const tabs = [];
    if (showInfo) {
      tabs.push({
        id: INFO_TAB,
        label: t(`common:${cardNamespace}.tabs.info`),
        render: () => (
          <ChapterInfo
            state={this.props.state}
            handleSubmit={this.props.handleSubmit}
            handleClose={this.props.handleClose}
            handleChange={this.props.handleChange}
            handleToggleLabel={this.props.handleToggleLabel}
            handleUpdateState={this.props.handleUpdateState}
            handleToggle={this.props.handleToggle}
            getFeatureConstraintsForField={this.props.getFeatureConstraintsForField}
            current={this.props.card.type}
            isActive
          />
        ),
      });
    }
    if (showSettings) {
      tabs.push({
        id: SETTINGS_TAB,
        label: t(`common:${cardNamespace}.tabs.settings`),
        render: () => (
          <Settings
            card={this.props.card}
            state={this.props.state}
            handleSubmit={this.props.handleSubmit}
            handleClose={this.props.handleClose}
            handleChange={this.props.handleChange}
            handleToggle={this.props.handleToggle}
            handleAddToList={this.props.handleAddToList}
            handleSetList={this.props.handleSetList}
            handleRemoveFromList={this.props.handleRemoveFromList}
            getFeatureConstraintsForField={this.props.getFeatureConstraintsForField}
            isActive
          />
        ),
      });
    }
    tabs.push({
      id: ADMIN_TAB,
      label: t(`common:${cardNamespace}.tabs.admin`),
      render: () => (
        <Community
          state={this.state}
          card={this.props.card}
          handleSubmit={this.props.handleSubmit}
          handleClose={this.props.handleClose}
          handleChange={this.props.handleChange}
          handleToggle={this.handleToggle}
          isActive
        />
      ),
    });

    return (
      <>
        <DarkAdminHeader
          handleSubmit={this.props.handleSubmit}
          handleClose={this.props.handleClose}
          handleChange={this.props.handleChange}
          inputTarget="title"
          inputValue={this.props.state.title}
        />
        <DarkAdminTabs
          defaultTab={showInfo ? INFO_TAB : showSettings ? SETTINGS_TAB : ADMIN_TAB}
          tabs={tabs}
        />
      </>
    );
  }
}

export default withTranslation()(DarkChapterForm);
