import React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import BookInfo from '@web/ui/components/card/darkforms/BookInfo.js';
import BookSettings from '@web/ui/components/card/darkforms/DarkFormSettings.js';
import Community from '@web/ui/components/card/darkforms/Community.js';

import DarkAdminHeader from '@web/ui/components/generic/editscreen/DarkAdminHeader';
import DarkAdminTabs from '@web/ui/components/generic/editscreen/DarkAdminTabs';
import CardOptimize from '@web/ui/components/card/darkforms/CardOptimize.js';

const INFO_TAB = 'Info',
    SETTINGS_TAB = 'Settings',
    ADMIN_TAB = 'Admin',
    OPTIMIZE_TAB = 'Optimize';

class DarkBookForm extends React.Component {
    render() {
        const {
            t,
            getFeatureConstraintsForField,
            activeLibrary: _activeLibrary,
            card,
            state,
            handleUpdateState,
            handleChange,
            handleToggle,
            handleRadio,
            handleSubmit,
            handleClose,
            handleAddToList,
            handleRemoveFromList,
            handleSetList,
            handleOverrideCustomFields,
            handleCustomFieldsChange,
        } = this.props;

        const infoConstraints = {
            bodyConstraints: getFeatureConstraintsForField('body'),
            mediaThumbConstraints: getFeatureConstraintsForField('mediaThumb'),
            mediaIconConstraints: getFeatureConstraintsForField('mediaIcon'),
            descriptionConstraints: getFeatureConstraintsForField('description'),
            displayReferenceTypesConstraint: getFeatureConstraintsForField('displayReference'),
        };
        let showInfo = false;
        _.each(_.values(infoConstraints), constraint => {
            if (constraint) showInfo = true;
        });

        const settingsConstraints = {
            accessRoleWhitelistConstraints: getFeatureConstraintsForField('accessRoleWhitelist'),
            protectionLevelConstraint: getFeatureConstraintsForField('protectionLevel'),
            displayInNewsfeedConstraint: getFeatureConstraintsForField('displayInNewsfeed'),
        };
        let showSettings = false;
        _.each(_.values(settingsConstraints), constraint => {
            if (constraint) showSettings = true;
        });

        const tabs = [];
        if (showInfo) {
            tabs.push({
                id: INFO_TAB,
                label: t(`common:imports.wlWeb.ui.components.card.tabs.info`),
                render: () => (
                    <BookInfo
                        card={card}
                        isActive
                        state={state}
                        handleSubmit={handleSubmit}
                        handleClose={handleClose}
                        handleChange={handleChange}
                        handleUpdateState={handleUpdateState}
                        handleAddToList={handleAddToList}
                        handleSetList={handleSetList}
                        handleRemoveFromList={handleRemoveFromList}
                        infoConstraints={infoConstraints}
                    />
                ),
            });
        }

        // TODO: Make These Settings restricted to root admin
        if (showSettings) {
            tabs.push({
                id: SETTINGS_TAB,
                label: t(`common:imports.wlWeb.ui.components.card.tabs.settings`),
                render: () => (
                    <BookSettings
                        isActive
                        state={state}
                        handleSubmit={handleSubmit}
                        handleClose={handleClose}
                        handleChange={handleChange}
                        handleUpdateState={handleUpdateState}
                        handleAddToList={handleAddToList}
                        handleSetList={handleSetList}
                        handleRemoveFromList={handleRemoveFromList}
                        handleToggle={handleToggle}
                        handleRadio={handleRadio}
                        settingsConstraints={settingsConstraints}
                        settingsType="book_settings"
                    />
                ),
            });
        }

        const activeLibrary = _activeLibrary;
        if (activeLibrary) {
            if (activeLibrary.library_settings) {
                if (activeLibrary.library_settings.customSignupFields) {
                    if (activeLibrary.library_settings.customSignupFields.length > 0) {
                        const optimizeState = {};
                        _.each(state.customFields.values, field => {
                            optimizeState[field.id] = {
                                value: field.values,
                            };
                        });

                        tabs.push({
                            id: OPTIMIZE_TAB,
                            label: t(`common:imports.wlWeb.ui.components.card.tabs.optimize`),
                            render: () => (
                                <CardOptimize
                                    state={optimizeState}
                                    handleUpdateState={handleUpdateState}
                                    handleChange={handleOverrideCustomFields}
                                    handleCustomChange={handleCustomFieldsChange}
                                    activeLibrary={activeLibrary}
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                />
                            ),
                        });
                    }
                }
            }
        }

        tabs.push({
            id: ADMIN_TAB,
            label: t(`common:imports.wlWeb.ui.components.card.tabs.admin`),
            render: () => (
                <Community
                    isActive
                    state={this.state}
                    card={card}
                    handleSubmit={handleSubmit}
                    handleClose={handleClose}
                    handleChange={handleChange}
                    handleToggle={this.handleToggle}
                />
            ),
        });

        return (
            <>
                <DarkAdminHeader
                    handleSubmit={handleSubmit}
                    handleClose={handleClose}
                    handleChange={handleChange}
                    inputTarget="title"
                    inputValue={state.title}
                />
                <DarkAdminTabs
                    defaultTab={showInfo ? INFO_TAB : showSettings ? SETTINGS_TAB : ADMIN_TAB}
                    tabs={tabs}
                />
            </>
        );
    }
}

export default withTranslation()(DarkBookForm);
