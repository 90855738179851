import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';
import useGroupActions from '@components/group/hooks/useGroupActions';

import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';

import { Maybify } from '@core/types/Utilities';
import { Group } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.group';

type ActivateMerchantAccountWarningProps = {
    group: Maybify<Group>;
};

const ActivateMerchantAccountWarning: React.FC<ActivateMerchantAccountWarningProps> = ({
    group,
}) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();

    const { takeGroupActionForUser, primaryActionAvailable } = useGroupActions({
        group,
        refetchQueries: [GET_GROUP_DASHBOARD],
    });

    if (!group?.currentUserIsAdmin || !group?.currentUserIsDirectAdmin) {
        return null;
    }

    if (!group?.paymentSettings?.isGroupMembershipPaid) {
        return null;
    }

    if (
        group?.paymentSettings.isGroupMembershipPaid &&
        group?.paymentSettings.stripeAccount === null
    ) {
        return (
            <button
                type="button"
                className="event-activate-account-warning"
                onClick={() =>
                    takeGroupActionForUser(currentUser?._id, primaryActionAvailable?.action)
                }
            >
                {t(
                    `common:${localNamespace}.activate_merch_acct`,
                    'Activate merchant account to publish'
                )}
            </button>
        );
    }

    if (
        group?.paymentSettings.stripeAccount !== null &&
        (!group?.paymentSettings.stripeAccount.charges_enabled ||
            !group?.paymentSettings.stripeAccount.details_submitted ||
            !group?.paymentSettings.stripeAccount.payouts_enabled) &&
        group?.paymentSettings.isGroupMembershipPaid
    ) {
        return (
            <button
                type="button"
                className="event-activate-account-warning event-activate-account-inprogress"
            >
                {t(
                    `common:${localNamespace}.activate_merch_acct_inprogress`,
                    'Merchant account activation in progress...'
                )}
            </button>
        );
    }

    if (
        group?.paymentSettings.stripeAccount.charges_enabled &&
        group?.paymentSettings.stripeAccount.details_submitted &&
        group?.paymentSettings.stripeAccount.payouts_enabled &&
        group?.paymentSettings.isGroupMembershipPaid
    ) {
        return null;
    }
};

export default ActivateMerchantAccountWarning;
