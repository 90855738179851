import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useSideDrawer from '@web/ui/components/generic/hooks/useSideDrawer';

import {
    constructUrl,
    generateDisplayReferences,
    generateReferenceArray,
    shouldShowReference,
} from '@helpers/reference/reference.helpers';
import { cardIsEvent } from '@helpers/contentCard/contentCard.helpers';

import {
    DISP_REF_CHAPTER,
    DISP_REF_LIBRARY,
    DISP_REF_PROMPT,
} from '@core/utilities/constants/library_settings';

import { ContentCard } from '@shared/welibrary-graphql/types';

import { Capacitor } from '@capacitor/core';

type ReferenceCardHeaderProps = {
    source: Partial<ContentCard>;
    showFullReference?: boolean;
    extraClasses?: string;
};

const ReferenceCardHeader: React.FC<ReferenceCardHeaderProps> = ({
    source,
    showFullReference = false,
    extraClasses = '',
}) => {
    const { t } = useTranslation();

    const { closeDrawer } = useSideDrawer();

    const displayReference = generateDisplayReferences(source, showFullReference);
    const referenceArray = generateReferenceArray(t, source, displayReference);

    if (!source.reference || !displayReference || !referenceArray) return <div />;

    if (
        !shouldShowReference(source.reference, displayReference, DISP_REF_PROMPT) &&
        referenceArray.length === 0
    ) {
        return <div />;
    }

    const {
        reference,
        reference: { chapterSource },
        author,
    } = source;

    // don't display header for self posts (i.e. a user posting to their own userfeed)
    const isSelfPost = reference?.shelfSource?.url === 'system-shelf-personal-books';
    if (isSelfPost) return <div />;

    const closeSideDrawer = () => {
        if (Capacitor.isNativePlatform()) closeDrawer();
    };

    if (cardIsEvent(source)) referenceArray.reverse(); // We want event reference headers to be "{group name} • Events"

    const headerText = referenceArray.map(({ type, value, preposition }, index) => {
        if (type === DISP_REF_LIBRARY) {
            return (
                <span key={index}>
                    {index > 0 && ` ${preposition}`} <b>{value}</b>
                </span>
            );
        }

        return (
            <span key={index}>
                {index > 0 && ` ${preposition ?? '•'}`}{' '}
                <Link onClick={closeSideDrawer} to={constructUrl(reference, author, type)}>
                    {value}
                </Link>
            </span>
        );
    });

    const promptText = chapterSource?.prompt || chapterSource?.title;

    return (
        <div className={`reference-header-container ${extraClasses}`}>
            <div className="reference-header-text">{headerText}</div>
            {shouldShowReference(reference, displayReference, DISP_REF_PROMPT) && (
                <Link
                    onClick={closeSideDrawer}
                    className="prompt-text"
                    to={constructUrl(reference, author, DISP_REF_CHAPTER)}
                >
                    {promptText}
                </Link>
            )}
        </div>
    );
};

export default ReferenceCardHeader;
