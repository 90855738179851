import React, { useState } from 'react';
import _ from 'lodash';

import { useRunSearchJobQuery } from '@shared/welibrary-graphql/search/queries.hook';
import { useMyCommunityQuery, useMyGroupsQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { usePaginatedNewsFeedQuery } from '@shared/welibrary-graphql/content_card/queries.hook';

import useDebouncedState from '@components/generic/hooks/useDebouncedState';

import SearchFormResult from '@components/search/form/SearchFormResult';

import { FILTERS_META } from '@components/search/constants';

import { Maybify } from '@core/types/Utilities';
import { Entity } from '@core/types/Search';
import { AliasHandle, CommunityTypes } from '@shared/welibrary-graphql/types';

const getFiltersArray = (
    filter: keyof typeof FILTERS_META,
    filters: Array<keyof typeof FILTERS_META>
) => {
    if (filter !== 'All') return FILTERS_META[filter].filter;

    return filters.map(key => FILTERS_META[key].filter?.[0]);
};

type useSearchFormProps = {
    addResult: (result: Maybify<Entity>) => void;
    suggestionsFilter: Array<Maybify<AliasHandle> | null>;
    filters: Array<keyof typeof FILTERS_META>;
    allSuggestions: Array<keyof Omit<typeof FILTERS_META, 'ALL'>>;
};

const useSearchForm = ({
    addResult,
    suggestionsFilter,
    filters,
    allSuggestions,
}: useSearchFormProps) => {
    const [filter, setFilter] = useState<keyof typeof FILTERS_META>('All');
    const [query, actualQuery, setQuery] = useDebouncedState('');

    const { data, loading } = useRunSearchJobQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        variables: {
            input: { text: actualQuery, limit: 10, filters: getFiltersArray(filter, filters) },
        },
        skip: !query,
    });

    const { data: groupsData } = useMyGroupsQuery({
        variables: { limit: 10, cursor: null, searchQuery: '', sortType: 'recentFirst' },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip:
            !filters.includes('Groups') &&
            !filters.includes('Channels') &&
            !filters.includes('Cards'),
    });

    const { data: profilesData } = useMyCommunityQuery({
        variables: { searchQuery: '', limit: 10, sortType: 'az', type: CommunityTypes.Connections },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !filters.includes('Profiles'),
    });

    const { data: cardsData } = usePaginatedNewsFeedQuery({
        variables: { limit: 10 },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !filters.includes('Cards'),
    });

    const results =
        data?.runSearchJob?.results?.results?.map((result, index) => (
            <SearchFormResult
                addResult={addResult}
                entity={result?.entity}
                key={result?.entity?._id ?? index}
            />
        )) ?? [];

    const shouldBeFiltered = (url?: string | null) =>
        suggestionsFilter.find(item => item?.url === url);

    const profileResults =
        profilesData?.myCommunity?.results
            ?.filter(profile => !shouldBeFiltered(profile?._id))
            .map((profile, index) => (
                <SearchFormResult
                    addResult={addResult}
                    entity={profile}
                    key={profile?._id ?? index}
                />
            )) ?? [];

    // Generate group and channel suggestions in one loop, limiting each to 10 suggestions
    const groupResults: React.ReactNode[] = [];
    const channelsResults: React.ReactNode[] = [];

    groupsData?.myGroups?.results?.forEach((group, groupIndex) => {
        if (groupResults.length > 10) return;

        if (!shouldBeFiltered(group?._id)) {
            groupResults.push(
                <SearchFormResult
                    addResult={addResult}
                    entity={group}
                    key={group?._id ?? groupIndex}
                />
            );
        }

        group?.channelsBook?.children.forEach((channel, channelIndex) => {
            if (channelsResults.length > 10) return;

            if (!shouldBeFiltered(channel?.url)) {
                channelsResults.push(
                    <SearchFormResult
                        addResult={addResult}
                        entity={{ ...channel, type: 'channel' }}
                        key={channel?.url ?? channelIndex}
                    />
                );
            }
        });
    });

    const cardsResults =
        cardsData?.paginatedNewsFeed.results
            .filter(card => !shouldBeFiltered(card?._id))
            .map((card, index) => (
                <SearchFormResult addResult={addResult} entity={card} key={card?._id ?? index} />
            )) ?? [];

    const suggestions: React.ReactNode[] = [];

    // Build list of suggestions based on active filter
    if (filter === 'Groups') suggestions.push(...groupResults);
    if (filter === 'Profiles') suggestions.push(...profileResults);
    if (filter === 'Channels') suggestions.push(...channelsResults);
    if (filter === 'Cards') suggestions.push(...cardsResults);

    if (filter === 'All') {
        allSuggestions.forEach(suggestion => {
            if (suggestion === 'Groups') suggestions.push(...groupResults);
            if (suggestion === 'Profiles') suggestions.push(...profileResults);
            if (suggestion === 'Channels') suggestions.push(...channelsResults);
            if (suggestion === 'Cards') suggestions.push(...cardsResults);
        });
    }

    const emptyResults = !loading && _.isEmpty(results) && query !== '';
    const emptyState = query === '' && actualQuery === '';
    const areResults = results && results.length > 0;
    const showSuggestions = !actualQuery && suggestions.length > 0;

    return {
        query,
        setQuery,
        filter,
        setFilter,
        emptyResults,
        emptyState,
        areResults,
        showSuggestions,
        loading,
        results,
        suggestions,
    };
};

export default useSearchForm;
