var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/search/queries.hook.tsx
import { gql as gql5 } from "@apollo/client";

// src/messaging/fragments.hook.tsx
import { gql as gql4 } from "@apollo/client";

// src/content_card/fragments.hook.tsx
import { gql as gql3 } from "@apollo/client";

// src/comment/fragments.hook.tsx
import { gql } from "@apollo/client";
var CommentFragmentFragmentDoc = gql`
    fragment CommentFragment on Comment {
  __typename
  _id
  publish
  stream {
    __typename
    _id
  }
  creator {
    __typename
    _id
    profile {
      picture
      full_name
    }
  }
  body
  loggedInUserCanEdit
  childCount
  parentId
}
    `;

// src/tag/fragments.hook.tsx
import { gql as gql2 } from "@apollo/client";
var TagFragmentFragmentDoc = gql2`
    fragment TagFragment on Tag {
  __typename
  _id
  label
  color
  created
  updated
}
    `;

// src/content_card/fragments.hook.tsx
var ContentCardSearchFragmentFragmentDoc = gql3`
    fragment ContentCardSearchFragment on ContentCard {
  __typename
  _id
  url
  type
  title
  body
  description
  media {
    icon
    thumb
  }
  prompt
  isBookmarked
  isArchived
}
    `;
var ContentCardCourseSearchFragmentFragmentDoc = gql3`
    fragment ContentCardCourseSearchFragment on ContentCard {
  __typename
  _id
  url
  type
  title
  body
  description
  media {
    icon
    thumb
  }
  prompt
  courseItems {
    levels {
      title
      media
      resource
    }
  }
  isBookmarked
  isArchived
}
    `;
var CardCommentsFragmentFragmentDoc = gql3`
    fragment CardCommentsFragment on ContentCard {
  __typename
  _id
  comments {
    ...CommentFragment
  }
}
    ${CommentFragmentFragmentDoc}`;
var ContentCardPlatformFragmentFragmentDoc = gql3`
    fragment ContentCardPlatformFragment on ContentCard {
  _id
  __typename
  vs
  type
  url
  platformSettings {
    platformGroupOwner
    forms {
      formCardUrl
      formSpecificationSettings {
        type
        form {
          fieldGroups {
            groupName
            index
            fieldIds
          }
          required
          fields {
            index
            id
            type
            options
            prompt
            defaultComparator
            customText
            selectMultiple
            siblingId
            allowOther
            required
            showOnSignup
            groups {
              group {
                _id
                path
                groupActionsAllowed
                currentUserIsMember
                currentUserIsAdmin
                profile {
                  picture
                  full_name
                  short_bio
                }
                settings {
                  approvalRequired
                  protectionLevel
                }
              }
              id
              requireApproval
            }
            placeholder
            dependsOn {
              id
              values
            }
          }
        }
      }
    }
  }
}
    `;
var ContentCardBookSourceFragmentFragmentDoc = gql3`
    fragment ContentCardBookSourceFragment on ContentCard {
  __typename
  _id
  url
  title
  library_settings {
    displayReference
    protectionLevel
    accessRoleWhitelist
  }
  reference {
    groupSource {
      profile {
        full_name
      }
      settings {
        protectionLevel
      }
    }
  }
}
    `;
var ContentCardPledgesFragmentFragmentDoc = gql3`
    fragment ContentCardPledgesFragment on PledgeItems {
  __typename
  _id
  pledges {
    __typename
    _id
    label
    reportingType
    canPledge
    canDeletePledge
    totalPledges
    currentUsersPledges
    currentUsersPledgesMonth: currentUsersPledges(daysAgo: 30)
    currentUsersPledgesDay: currentUsersPledges(daysAgo: 1)
    successfulPledgesAll: currentUsersSuccessfulPledges
    successfulPledgesMonth: currentUsersSuccessfulPledges(daysAgo: 30)
    successfulPledgesDay: currentUsersSuccessfulPledges(daysAgo: 1)
    hidden
  }
  pledgeKeys {
    __typename
    _id
    label
    refactoryPeriod
    limitPerRefractoryPeriod
    description
    reportingType
    hidden
  }
  pledgers {
    __typename
    _id
    profile {
      full_name
      first_name
      picture
    }
  }
  latestPledgerEngagement {
    userId
    date
  }
  totalPledges
  totalPledgers
  pledgesLastDay: totalPledges(daysAgo: 1)
  pledgesLastMonth: totalPledges(daysAgo: 30)
}
    `;
var RevealCardFragmentFragmentDoc = gql3`
    fragment RevealCardFragment on ContentCard {
  __typename
  type
  links {
    source
    other
  }
  media {
    thumb
    icon
    logoLight
    logoDark
  }
}
    `;
var UpdateFeatureConstraintsFragmentsFragmentDoc = gql3`
    fragment UpdateFeatureConstraintsFragments on ContentCard {
  __typename
  updateFeatureConstraints {
    canEditAllFields
    featuresUserCanEdit {
      type
      field
      options
      canUpdate
    }
  }
}
    `;
var MobilizeEffortFragmentFragmentDoc = gql3`
    fragment MobilizeEffortFragment on ContentCard {
  __typename
  _id
  path
  url
  shortCode
  type
  title
  body
  description
  prompt
  rankOrder
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
      loggedInUserConnectionStatus
    }
    roles {
      isSpeakerOf
    }
  }
  date {
    hide_display
    last_update
    publish
  }
  links {
    source
    other
  }
  aliases {
    ... on GroupAliasHandle {
      url
      title
      color
      type
      group {
        __typename
        _id
        profile {
          full_name
          picture
          coverPhoto
        }
      }
    }
    ... on AliasItem {
      url
      title
      color
      type
      item {
        _id
        __typename
        title
        body
        protectionLevel
        prompt
        author {
          __typename
          _id
          type
          profile {
            full_name
          }
        }
        reference {
          href
          coverImage
          bookSource {
            ...ContentCardBookSourceFragment
          }
        }
        media {
          thumb
          icon
        }
        library_settings {
          postTypes
          engagementTypes
          filterTypes
          defaultFilter
          viewTypes
          defaultViewType
          allowPostbox
          displaySocialHeaders
          allowLabels
          displayReference
          displayInNewsfeed
          userCanPost
        }
        pledgeItems {
          ...ContentCardPledgesFragment
        }
        whitelabelVerbage {
          __typename
          _id
          value
        }
        isArchived
        userHasPledgedTo
        taskHistory {
          __typename
          cursor
          hasMore
          results {
            pledgeKeyId
            label
            refactoryPeriod
            date
            supplement {
              note
              image
              document
              documentTitle
            }
            verifier {
              name
              email
              phone
              country
              note
              newRecruit
            }
            claimedAccount {
              __typename
              _id
              profile {
                picture
                full_name
                email
                phone
                country
              }
            }
          }
          type
        }
      }
    }
  }
  media {
    thumb
    icon
    logoLight
    logoDark
    favicon
    webclip
  }
  loggedInUserCanEdit
  defaultHidden
  isBookmarked
  isArchived
  applicationItems {
    applications {
      __typename
      _id
      createdAt
      status
      data {
        values {
          id
          values
        }
      }
      user {
        __typename
        _id
        profile {
          full_name
          picture
          country
          website
          email
        }
      }
    }
  }
  postingSettings {
    type
    status
    totalPositionsCount
    acceptedCount
    specification {
      url
    }
    postingData {
      parent {
        url
      }
      values {
        id
        type
        customText
        comparator
        values
        siblingId
      }
    }
    requirementsData {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
  tags {
    __typename
    hasMore
    cursor
    type
    results {
      ...TagFragment
    }
  }
  loggedInUserApplyStatus
  isTemplate
  ...UpdateFeatureConstraintsFragments
}
    ${ContentCardBookSourceFragmentFragmentDoc}
${ContentCardPledgesFragmentFragmentDoc}
${TagFragmentFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardCourseFragmentFragmentDoc = gql3`
    fragment ContentCardCourseFragment on CourseItems {
  materials
  ageGroup
  duration
  people
  levels {
    title
    media
    video
    resource
    steps {
      text
      useImage
      image
      useVideo
      video
      fullPage
      pageBreak
      skill
      skillSubtype
      skillMilestone
    }
    preSurvey {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
    postSurvey {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
  }
  publicCompletions
}
    `;
var ContentCardCreationFragmentFragmentDoc = gql3`
    fragment ContentCardCreationFragment on CreationItems {
  silhouetteImage
  course {
    _id
    media {
      icon
      thumb
    }
    title
    courseItems {
      materials
      ageGroup
      duration
      people
      levels {
        title
        media
        resource
        preSurvey {
          _id
          type
          subtype
          prompt
          useImage
          image
          range
          answers
          correctAnswer
          skill
          skillSubtype
          skillMilestone
        }
        postSurvey {
          _id
          type
          subtype
          prompt
          useImage
          image
          range
          answers
          correctAnswer
          skill
          skillSubtype
          skillMilestone
        }
      }
    }
    url
  }
  levels {
    title
    description
    image
  }
}
    `;
var CardRatingsFragmentDoc = gql3`
    fragment CardRatings on ContentCard {
  ratings_sentiment_score
  latest_rating_sentiments {
    _id
    profile {
      full_name
    }
  }
  ratings_quality_score
  ratings_relevance_score {
    score
    hashtag
  }
  ratings_rank
  loggedInUserVotes {
    sentiment
    quality
    relevance
  }
}
    `;
var ContentCardCustomFieldsFragmentFragmentDoc = gql3`
    fragment ContentCardCustomFieldsFragment on ContentCard {
  _id
  customFields {
    manager
    parent {
      url
    }
    parentGroup {
      _id
    }
    values {
      id
      type
      comparator
      values
      siblingId
    }
  }
}
    `;
var ContentCardFragmentFragmentDoc = gql3`
    fragment ContentCardFragment on ContentCard {
  __typename
  _id
  vs
  path
  url
  shortCode
  type
  title
  body
  shortTitle
  description
  prompt
  commentCount
  latestComments {
    cursor
    hasMore
    type
    results {
      _id
      creator {
        _id
        profile {
          full_name
          picture
        }
      }
      body
    }
  }
  inheritSurveyAnswers
  rankOrder
  subtype
  pinnedCardIds
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
      loggedInUserConnectionStatus
      did
    }
    roles {
      isSpeakerOf
    }
  }
  date {
    hide_display
    last_update
    publish
  }
  links {
    title
    source
    openInModal
    other
    publisher
  }
  aliases {
    ... on GroupAliasHandle {
      url
      title
      color
      thumb
      icon
      type
      group {
        __typename
        _id
        subtype
        isUnpublished
        profile {
          full_name
          picture
          coverPhoto
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        groupActionsAllowed
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
      }
    }
    ... on MessageThreadAliasHandle {
      url
      title
      color
      thumb
      icon
      type
      thread {
        __typename
        _id
        displayName
        displayIcon
        created
        updated
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
      }
    }
    ... on AliasItem {
      url
      title
      color
      thumb
      icon
      type
      item {
        subtype
        __typename
        _id
        title
        shortTitle
        prompt
        vote_settings {
          QUALITY {
            active
          }
          RELEVANCE {
            active
          }
          SENTIMENT {
            active
          }
        }
        type
        author {
          __typename
          _id
          type
          profile {
            full_name
          }
        }
        reference {
          href
          coverImage
          shelfSource {
            title
            url
            library_settings {
              displayReference
              protectionLevel
            }
          }
          bookSource {
            ...ContentCardBookSourceFragment
          }
          groupSource {
            _id
            subtype
            profile {
              full_name
              picture
              coverPhoto
            }
          }
        }
        media {
          thumb
          icon
        }
        library_settings {
          postTypes
          engagementTypes
          filterTypes
          defaultFilter
          viewTypes
          defaultViewType
          allowPostbox
          displaySocialHeaders
          allowLabels
          displayReference
          protectionLevel
          displayInNewsfeed
          userCanPost
        }
        pledgeItems {
          ...ContentCardPledgesFragment
        }
        whitelabelVerbage {
          __typename
          _id
          value
        }
        isArchived
        userHasPledgedTo
        taskHistory {
          __typename
          cursor
          hasMore
          results {
            pledgeKeyId
            label
            refactoryPeriod
            date
            supplement {
              note
              image
              document
              documentTitle
            }
            verifier {
              name
              email
              phone
              country
              note
              newRecruit
            }
            claimedAccount {
              __typename
              _id
              profile {
                picture
                full_name
                email
                phone
                country
              }
            }
          }
          type
        }
      }
    }
  }
  media {
    thumb
    icon
    logoLight
    logoDark
    favicon
    webclip
    mediaMetaData {
      height
      width
    }
  }
  label {
    text
    type
  }
  formSpecificationSettings {
    type
    postingForm {
      required
      fieldGroups {
        groupName
        fieldIds
        index
      }
      fields {
        index
        type
        id
        options
        customText
        prompt
        placeholder
        type
        dependsOn {
          id
          values
        }
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
      }
    }
    applicationForm {
      required
      fieldGroups {
        groupName
        fieldIds
        index
      }
      fields {
        index
        type
        id
        options
        customText
        prompt
        placeholder
        type
      }
    }
    form {
      required
      fieldGroups {
        groupName
        fieldIds
        index
      }
      fields {
        index
        type
        id
        options
        customText
        prompt
        placeholder
        type
      }
    }
  }
  library_settings {
    postTypes
    engagementTypes
    filterTypes
    viewTypes
    defaultViewType
    defaultFilter
    allowPostbox
    postboxRoleWhiteList
    userCanPost
    displaySocialHeaders
    displayInNewsfeed
    showCreateChildren
    showResourceHeader
    allowLabels
    displayReference
    protectionLevel
    accessRoleWhitelist
    memberStyle
    customSignupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      showOnSignup
      groups {
        group {
          _id
          path
          groupActionsAllowed
          currentUserIsMember
          currentUserIsAdmin
          profile {
            picture
            full_name
            short_bio
          }
          settings {
            approvalRequired
            protectionLevel
          }
        }
        id
        requireApproval
      }
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
    dashboardContentItems {
      name
      url
      type
      filter
    }
    customRoles {
      name
      url
      color
    }
    sidebarMenu {
      name
      url
    }
    recommendations
  }
  sendNotifications
  vote_settings {
    QUALITY {
      active
    }
    RELEVANCE {
      active
    }
    SENTIMENT {
      active
    }
  }
  meta {
    course {
      revealType
      revealContent {
        ...RevealCardFragment
      }
      compensation
      time
      sequence
    }
    research {
      category
      journalName
      organization
      publicationType
      abstract
      authors
      fileURL
      tags
      issue
    }
    mobilize {
      date
      qualifications
      skills
      summary
      city
      state
      country
      cta
      goalnumber
      hours
      matchterm
      pledge
      timeCommit
      pertime
    }
    pledge {
      date
      qualifications
      skills
      summary
      city
      state
      country
      cta
      goalnumber
      hours
      matchterm
      pledge
      timeCommit
      pertime
    }
  }
  loggedInUserCanEdit
  currentUserHasRead
  defaultHidden
  isBookmarked
  isArchived
  tags {
    __typename
    hasMore
    cursor
    type
    results {
      ...TagFragment
    }
  }
  isTemplate
  postingSettings {
    type
    status
    totalPositionsCount
    acceptedCount
    specification {
      url
    }
    postingData {
      parent {
        url
      }
      values {
        id
        type
        customText
        comparator
        values
        siblingId
      }
    }
    requirementsData {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
  mobilizationSettings {
    approvalType
    status
    efforts {
      type
      card {
        __typename
        library_settings {
          postTypes
          engagementTypes
          filterTypes
          defaultFilter
          viewTypes
          defaultViewType
          allowPostbox
          displaySocialHeaders
          allowLabels
          displayReference
          displayInNewsfeed
        }
        ...MobilizeEffortFragment
      }
      status
      country
      countGoal
      count
    }
  }
  courseItems {
    ...ContentCardCourseFragment
  }
  creationItems {
    ...ContentCardCreationFragment
  }
  surveyQuestions {
    _id
    type
    subtype
    prompt
    useImage
    image
    range
    answers
    correctAnswer
    skill
    skillSubtype
    skillMilestone
  }
  timeAndPlaceSettings {
    headline
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    location {
      formatted_address
      location {
        type
        coordinates
      }
      city
      country
      postal
      state
    }
    link
    rsvpLink
    allDay
    timeZone {
      name
    }
  }
  eventOverviewDetails {
    title
    description
    headline
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    displayStartTime
    displayEndTime
    location {
      formatted_address
    }
    link
    rsvpLink
    allDay
    timeZone {
      name
    }
    eventLocationSettings {
      location {
        formatted_address
        city
        state
        country
      }
      type
      link
      venue {
        name
        link
        location {
          formatted_address
          location {
            type
            coordinates
          }
          postal
          city
          state
          country
        }
      }
    }
  }
  eventSettings {
    headline
    date
    dates {
      startDate
      endDate
    }
    startTime
    endTime
    location {
      formatted_address
    }
    link
    rsvpLink
    allDay
    timeZone {
      name
    }
  }
  eventAttendance {
    user {
      _id
      profile {
        full_name
        picture
      }
      socialActionsAllowed
    }
    status
  }
  canMembersSuggestEdits
  showTitle
  protectionLevel
  currentUserCanSuggestEdit
  verticalScrolling
  dialogSettings {
    event
    people {
      name
      image
    }
    dialogs {
      person
      text
    }
  }
  showPostTypes
  ...UpdateFeatureConstraintsFragments
  ...CardRatings
  ...ContentCardCustomFieldsFragment
}
    ${ContentCardBookSourceFragmentFragmentDoc}
${ContentCardPledgesFragmentFragmentDoc}
${RevealCardFragmentFragmentDoc}
${TagFragmentFragmentDoc}
${MobilizeEffortFragmentFragmentDoc}
${ContentCardCourseFragmentFragmentDoc}
${ContentCardCreationFragmentFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}
${CardRatingsFragmentDoc}
${ContentCardCustomFieldsFragmentFragmentDoc}`;
var ContentCardReferenceFragmentFragmentDoc = gql3`
    fragment ContentCardReferenceFragment on ContentCard {
  reference {
    href
    isResource
    coverImage
    chapterSource {
      title
      url
      prompt
      showTitle
      inheritSurveyAnswers
      subtype
      vote_settings {
        QUALITY {
          active
        }
        RELEVANCE {
          active
        }
        SENTIMENT {
          active
        }
      }
      library_settings {
        engagementTypes
        viewTypes
        defaultViewType
        displayReference
        displaySocialHeaders
      }
      media {
        thumb
      }
    }
    bookSource {
      ...ContentCardBookSourceFragment
    }
    shelfSource {
      title
      url
      library_settings {
        displayReference
        protectionLevel
      }
    }
    librarySource {
      title
      url
      library_settings {
        displayReference
      }
    }
    groupSource {
      _id
      subtype
      profile {
        full_name
        picture
        coverPhoto
      }
      settings {
        shortCode
        shortCodeApprovalRequired
        protectionLevel
        approvalRequired
        formSpec {
          url
        }
      }
      products {
        _id
        name
        price
        currency
        type
        fulfillment {
          groupId
          role
        }
        created
        updated
      }
      paymentSettings {
        stripeAccount {
          id
          email
          country
          charges_enabled
          payouts_enabled
          details_submitted
          external_accounts {
            object
            data {
              country
              currency
            }
            has_more
            url
          }
        }
        isGroupMembershipPaid
      }
      groupActionsAllowed
      currentUserOwnsMembership
      currentUserOwnsFreeSubscription
      doesSellMembership
      currentUserIsAdmin
      currentUserIsDirectAdmin
      currentUserIsMember
    }
  }
}
    ${ContentCardBookSourceFragmentFragmentDoc}`;
var RainbowRoadFragmentFragmentDoc = gql3`
    fragment RainbowRoadFragment on ContentCard {
  __typename
  _id
  url
  vs
  type
  subtype
  title
  description
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
      loggedInUserConnectionStatus
    }
    roles {
      isSpeakerOf
    }
  }
  media {
    icon
  }
  loggedInUserCanEdit
  rainbowRoadSettings {
    header {
      color
      darkMode
      image
      backgroundImage
      showTopInfo
    }
    body {
      color
      darkMode
      image
    }
    nodes {
      circleSize
      icon
      lineStyle
      lineColor
      outline
      winding
      spinnerImage
    }
    font
    questSettings {
      creationsChannel {
        url
        title
        children {
          ...ContentCardFragment
        }
      }
      creationNodeSettings {
        icon
        backgroundImage
        showMetaInformation
        spinnerImage
      }
      completionRequirement
      dialogsChannel {
        url
        title
        children {
          ...ContentCardFragment
        }
      }
      questEndImage
      vcSettings {
        title
        name
        description
        image
      }
    }
  }
  library_settings {
    engagementTypes
  }
  nodes {
    title
    icon
    backgroundImage
    spinnerImage
    showMetaInformation
    preOrPost
    alias {
      ... on UserAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        user {
          _id
          profile {
            full_name
            picture
            coverPhoto
            full_bio
            short_bio
            website
            facebookProfile
            twitterProfile
            instagramProfile
            linkedInProfile
          }
          socialActionsAllowed
        }
      }
      ... on GroupAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        group {
          __typename
          _id
          path
          profile {
            full_name
            short_bio
            full_bio
            picture
            coverPhoto
            country
            website
            email
            customFields {
              parent {
                url
              }
              parentGroup {
                _id
              }
              manager
              values {
                id
                type
                comparator
                values
                siblingId
              }
            }
          }
          socialActionsAllowed
          shelf {
            __typename
            _id
            url
            media {
              thumb
              icon
            }
          }
          settings {
            __typename
            subscriptions
            formSpec {
              url
              formSpecificationSettings {
                type
                form {
                  required
                  fieldGroups {
                    groupName
                    fieldIds
                    index
                  }
                  fields {
                    index
                    type
                    id
                    options
                    customText
                    prompt
                    placeholder
                    type
                    selectMultiple
                    siblingId
                    defaultComparator
                    required
                    allowOther
                    dependsOn {
                      id
                      values
                    }
                  }
                }
              }
            }
            protectionLevel
            memberStyle
            dashboards {
              name
              url
              type
              filter
            }
            customSignupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
            recommendations
            canMembersSuggestEdits
            shortCode
            shortCodeApprovalRequired
            groupChatroom
            adminChatroom
            approvalRequired
            registrationTitle
            registrationDescription
            registrationActionText
            enableGroupNewsfeed
          }
          hasUnreadDashboard
          subtype
          totalMembers
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
          currentUserLastActiveDate
          groupActionsAllowed
          isTemplate
          allSignupFields {
            parentGroup {
              _id
            }
            manager
            signupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
          whitelabelVerbage {
            _id
            value
            plainText
          }
          parentGroup {
            __typename
            _id
            profile {
              full_name
            }
          }
          channelsBook {
            title
            url
            media {
              thumb
              icon
            }
            children {
              title
              url
              reference {
                href
              }
            }
          }
        }
      }
      ... on MessageThreadAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        thread {
          __typename
          _id
          participants {
            __typename
            user {
              __typename
              _id
              profile {
                __typename
                full_name
                short_bio
                full_bio
                picture
                coverPhoto
                facebookProfile
                instagramProfile
                twitterProfile
                linkedInProfile
              }
            }
            hasUnreadMessages
          }
          messages {
            __typename
            hasMore
            cursor
            type
            messages {
              __typename
              _id
              author {
                __typename
                _id
                profile {
                  __typename
                  first_name
                  full_name
                  picture
                }
              }
              text
              created
              updated
            }
          }
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
          currentUserNotificationLevel
        }
      }
      ... on AliasItem {
        url
        type
        title
        thumb
        icon
        color
        item {
          ...ContentCardFragment
          ...ContentCardReferenceFragment
        }
      }
    }
  }
  aliases {
    ... on UserAliasHandle {
      url
      type
      title
      thumb
      icon
      color
      user {
        _id
        profile {
          full_name
          picture
          coverPhoto
          full_bio
          short_bio
          website
          facebookProfile
          twitterProfile
          instagramProfile
          linkedInProfile
        }
        socialActionsAllowed
      }
    }
    ... on GroupAliasHandle {
      url
      type
      title
      thumb
      icon
      color
      group {
        __typename
        _id
        path
        profile {
          full_name
          short_bio
          full_bio
          picture
          coverPhoto
          country
          website
          email
          customFields {
            parent {
              url
            }
            parentGroup {
              _id
            }
            manager
            values {
              id
              type
              comparator
              values
              siblingId
            }
          }
        }
        socialActionsAllowed
        shelf {
          __typename
          _id
          url
          media {
            thumb
            icon
          }
        }
        settings {
          __typename
          subscriptions
          formSpec {
            url
            formSpecificationSettings {
              type
              form {
                required
                fieldGroups {
                  groupName
                  fieldIds
                  index
                }
                fields {
                  index
                  type
                  id
                  options
                  customText
                  prompt
                  placeholder
                  type
                  selectMultiple
                  siblingId
                  defaultComparator
                  required
                  allowOther
                  dependsOn {
                    id
                    values
                  }
                }
              }
            }
          }
          protectionLevel
          memberStyle
          dashboards {
            name
            url
            type
            filter
          }
          customSignupFields {
            id
            index
            type
            prompt
            customText
            options
            selectMultiple
            allowOther
            weight
            required
            link
            dependsOn {
              id
              values
            }
            defaultComparator
            siblingId
          }
          recommendations
          canMembersSuggestEdits
          shortCode
          shortCodeApprovalRequired
          groupChatroom
          adminChatroom
          approvalRequired
          registrationTitle
          registrationDescription
          registrationActionText
          enableGroupNewsfeed
        }
        hasUnreadDashboard
        subtype
        totalMembers
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
        currentUserLastActiveDate
        groupActionsAllowed
        isTemplate
        allSignupFields {
          parentGroup {
            _id
          }
          manager
          signupFields {
            id
            index
            type
            prompt
            customText
            options
            selectMultiple
            allowOther
            weight
            required
            link
            dependsOn {
              id
              values
            }
            defaultComparator
            siblingId
          }
        }
        whitelabelVerbage {
          _id
          value
          plainText
        }
        parentGroup {
          __typename
          _id
          profile {
            full_name
          }
        }
        channelsBook {
          title
          url
          media {
            thumb
            icon
          }
          children {
            title
            url
            reference {
              href
            }
          }
        }
      }
    }
    ... on MessageThreadAliasHandle {
      url
      type
      title
      thumb
      icon
      color
      thread {
        __typename
        _id
        participants {
          __typename
          user {
            __typename
            _id
            profile {
              __typename
              full_name
              short_bio
              full_bio
              picture
              coverPhoto
              facebookProfile
              instagramProfile
              twitterProfile
              linkedInProfile
            }
          }
          hasUnreadMessages
        }
        messages {
          __typename
          hasMore
          cursor
          type
          messages {
            __typename
            _id
            author {
              __typename
              _id
              profile {
                __typename
                first_name
                full_name
                picture
              }
            }
            text
            created
            updated
          }
        }
        displayName
        displayIcon
        created
        updated
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
        currentUserNotificationLevel
      }
    }
    ... on AliasItem {
      url
      type
      title
      thumb
      icon
      color
      item {
        ...ContentCardFragment
        ...ContentCardReferenceFragment
      }
    }
  }
}
    ${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
var ViewPermissionsFragmentDoc = gql3`
    fragment ViewPermissions on ContentCard {
  currentViewPermissions {
    level
    canUserView
  }
}
    `;
var ContentCardChapterFragmentFragmentDoc = gql3`
    fragment ContentCardChapterFragment on ContentCard {
  _id
  vs
  type
  url
  title
  shortTitle
  media {
    icon
    thumb
  }
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
    }
  }
  description
  isArchived
  prompt
  loggedInUserCanEdit
  vote_settings {
    QUALITY {
      active
    }
    RELEVANCE {
      active
    }
    SENTIMENT {
      active
    }
  }
  library_settings {
    postTypes
    engagementTypes
    filterTypes
    viewTypes
    defaultViewType
    defaultFilter
    allowPostbox
    postboxRoleWhiteList
    userCanPost
    displaySocialHeaders
    displayInNewsfeed
    allowLabels
    displayReference
    tagAggregation {
      ...TagFragment
    }
    cardAggregation {
      __typename
      _id
      title
      url
      body
      media {
        thumb
        icon
      }
    }
  }
  tags {
    __typename
    hasMore
    cursor
    type
    results {
      ...TagFragment
    }
  }
  sendNotifications
  rolesAvailable {
    url
    name
  }
  ...ContentCardReferenceFragment
  rankOrder
  canMembersSuggestEdits
  currentUserCanSuggestEdit
  showTitle
  childrenCount
  showPostTypes
  inheritSurveyAnswers
  isTemplate
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${TagFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}
${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardBookFragmentFragmentDoc = gql3`
    fragment ContentCardBookFragment on ContentCard {
  _id
  vs
  type
  subtype
  url
  title
  media {
    icon
    thumb
  }
  author {
    _id
    type
    profile {
      full_name
    }
  }
  children(sort: ordered) {
    ...ContentCardChapterFragment
  }
  description
  body
  prompt
  promptEnabled
  loggedInUserFollows
  loggedInUserCanEdit
  isTemplate
  library_settings {
    displayReference
    displayInNewsfeed
    displaySocialHeaders
    protectionLevel
    accessRoleWhitelist
    defaultFilter
    filterTypes
    engagementTypes
    customRoles {
      name
      url
      color
    }
  }
  sendNotifications
  vote_settings {
    QUALITY {
      active
    }
    RELEVANCE {
      active
    }
    SENTIMENT {
      active
    }
  }
  customFields {
    parent {
      url
    }
    values {
      id
      type
      comparator
      values
      siblingId
    }
  }
  cardActionsAllowed
  rankOrder
  verticalScrolling
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${ContentCardChapterFragmentFragmentDoc}
${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardBookBigScreenFragmentFragmentDoc = gql3`
    fragment ContentCardBookBigScreenFragment on ContentCard {
  _id
  type
  url
  title
  media {
    icon
    thumb
    __typename
  }
  author {
    _id
    type
    profile {
      full_name
      __typename
    }
    __typename
  }
  library_settings {
    displaySocialHeaders
  }
  prompt
  loggedInUserFollows
  loggedInUserCanEdit
  __typename
}
    `;
var ContentCardShelfFragmentFragmentDoc = gql3`
    fragment ContentCardShelfFragment on ContentCard {
  __typename
  _id
  url
  shortCode
  type
  title
  body
  description
  author {
    _id
    type
    profile {
      full_name
      full_bio
      picture
    }
  }
  group {
    __typename
    _id
    subtype
    username
    profile {
      full_name
      full_bio
      picture
    }
  }
  date {
    hide_display
    last_update
    publish
  }
  links {
    source
    other
  }
  media {
    thumb
    icon
  }
  library_settings {
    displayReference
    displayInNewsfeed
    dashboardContentItems {
      name
      url
      type
      filter
    }
    protectionLevel
    accessRoleWhitelist
  }
  customFields {
    parent {
      url
    }
    values {
      id
      type
      comparator
      values
      siblingId
    }
  }
  cardActionsAllowed
  loggedInUserFollows
  loggedInUserCanEdit
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardLibraryFragmentFragmentDoc = gql3`
    fragment ContentCardLibraryFragment on ContentCard {
  __typename
  _id
  url
  type
  title
  body
  description
  date {
    hide_display
    last_update
    publish
  }
  links {
    source
    other
  }
  media {
    thumb
    icon
    logoLight
    logoDark
    favicon
    webclip
  }
  loggedInUserFollows
  loggedInUserCanEdit
  library_settings {
    welcomeText
    autoFollowCards
    autoFollowUsers
    initialSubscriptions
    protectionLevel
    memberStyle
    sidebarMenu {
      name
      url
    }
    dashboardMenu {
      name
      url
    }
    dashboardContentItems {
      name
      url
      type
      filter
    }
    customRoles {
      name
      url
      color
    }
    customSignupFields {
      id
      index
      type
      prompt
      customText
      options
      selectMultiple
      allowOther
      weight
      required
      showOnSignup
      groups {
        group {
          _id
          path
          groupActionsAllowed
          currentUserIsMember
          currentUserIsAdmin
          profile {
            picture
            full_name
            short_bio
          }
          settings {
            approvalRequired
            protectionLevel
          }
        }
        id
        requireApproval
      }
      link
      dependsOn {
        id
        values
      }
      defaultComparator
      siblingId
    }
    displayReference
    displayInNewsfeed
    recommendations
  }
  cardActionsAllowed
  ...ViewPermissions
  ...UpdateFeatureConstraintsFragments
}
    ${ViewPermissionsFragmentDoc}
${UpdateFeatureConstraintsFragmentsFragmentDoc}`;
var ContentCardChapterChildrenFragmentFragmentDoc = gql3`
    fragment ContentCardChapterChildrenFragment on ContentCard {
  __typename
  _id
  chapterChildren: children(restrictType: chapter) {
    ...ContentCardChapterFragment
  }
}
    ${ContentCardChapterFragmentFragmentDoc}`;

// src/messaging/fragments.hook.tsx
var MessageFragmentOmitThreadFragmentDoc = gql4`
    fragment MessageFragmentOmitThread on Message {
  __typename
  _id
  author {
    __typename
    _id
    profile {
      __typename
      full_name
      picture
      short_bio
    }
  }
  reactions {
    emoji
    userIds
  }
  attachment {
    ...ContentCardFragment
    ...ContentCardReferenceFragment
  }
  text
  created
  updated
}
    ${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
var MessageThreadFragmentFragmentDoc = gql4`
    fragment MessageThreadFragment on MessageThread {
  __typename
  _id
  participants {
    __typename
    user {
      __typename
      _id
      type
      profile {
        __typename
        full_name
        short_bio
        full_bio
        picture
        coverPhoto
        facebookProfile
        instagramProfile
        twitterProfile
        linkedInProfile
      }
    }
    hasUnreadMessages
  }
  messages {
    __typename
    hasMore
    cursor
    type
    messages {
      __typename
      _id
      author {
        __typename
        _id
        profile {
          __typename
          first_name
          full_name
          picture
        }
      }
      text
      reactions {
        emoji
        userIds
      }
      attachment {
        type
        _id
        vs
      }
      created
      updated
    }
  }
  displayName
  displayIcon
  created
  updated
  group {
    __typename
    _id
    totalMembers
    profile {
      full_name
      picture
      coverPhoto
      short_bio
    }
    settings {
      protectionLevel
      approvalRequired
      shortCodeApprovalRequired
    }
    products {
      _id
      name
    }
  }
  currentUserNotificationLevel
  threadType
}
    `;
var MessageFragmentFragmentDoc = gql4`
    fragment MessageFragment on Message {
  __typename
  _id
  author {
    __typename
    _id
    profile {
      __typename
      full_name
      picture
      short_bio
    }
  }
  thread {
    ...MessageThreadFragment
  }
  attachment {
    ...ContentCardFragment
    ...ContentCardReferenceFragment
  }
  text
  reactions {
    emoji
    userIds
  }
  created
  updated
}
    ${MessageThreadFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}
${ContentCardReferenceFragmentFragmentDoc}`;
var MessageUpdateFragmentFragmentDoc = gql4`
    fragment MessageUpdateFragment on MessageUpdate {
  action
  message {
    ... on Message {
      ...MessageFragment
    }
    ... on MessageDelete {
      _id
      thread {
        ...MessageThreadFragment
      }
    }
  }
}
    ${MessageFragmentFragmentDoc}
${MessageThreadFragmentFragmentDoc}`;

// src/search/queries.hook.tsx
import * as Apollo from "@apollo/client";
var defaultOptions = {};
var AccountHolderType = /* @__PURE__ */ ((AccountHolderType2) => {
  AccountHolderType2["Company"] = "company";
  AccountHolderType2["Individual"] = "individual";
  return AccountHolderType2;
})(AccountHolderType || {});
var AccountStatus = /* @__PURE__ */ ((AccountStatus2) => {
  AccountStatus2["Active"] = "active";
  AccountStatus2["Inactive"] = "inactive";
  AccountStatus2["Invited"] = "invited";
  return AccountStatus2;
})(AccountStatus || {});
var AggregationType = /* @__PURE__ */ ((AggregationType2) => {
  AggregationType2["Groups"] = "GROUPS";
  AggregationType2["Nations"] = "NATIONS";
  AggregationType2["Recruits"] = "RECRUITS";
  AggregationType2["Users"] = "USERS";
  return AggregationType2;
})(AggregationType || {});
var AnalyticScopeType = /* @__PURE__ */ ((AnalyticScopeType2) => {
  AnalyticScopeType2["Group"] = "GROUP";
  AnalyticScopeType2["PledgeKey"] = "PLEDGE_KEY";
  return AnalyticScopeType2;
})(AnalyticScopeType || {});
var ApplicationStatus = /* @__PURE__ */ ((ApplicationStatus2) => {
  ApplicationStatus2["Accepted"] = "ACCEPTED";
  ApplicationStatus2["Cancelled"] = "CANCELLED";
  ApplicationStatus2["Closed"] = "CLOSED";
  ApplicationStatus2["Open"] = "OPEN";
  ApplicationStatus2["Rejected"] = "REJECTED";
  return ApplicationStatus2;
})(ApplicationStatus || {});
var ApprovalType = /* @__PURE__ */ ((ApprovalType2) => {
  ApprovalType2["Auto"] = "AUTO";
  ApprovalType2["Manual"] = "MANUAL";
  ApprovalType2["Skip"] = "SKIP";
  return ApprovalType2;
})(ApprovalType || {});
var BulkCardAction = /* @__PURE__ */ ((BulkCardAction2) => {
  BulkCardAction2["ResetChildrenSettings"] = "RESET_CHILDREN_SETTINGS";
  return BulkCardAction2;
})(BulkCardAction || {});
var CardActions = /* @__PURE__ */ ((CardActions2) => {
  CardActions2["AdminJoin"] = "adminJoin";
  CardActions2["CancelJoinRequest"] = "cancelJoinRequest";
  CardActions2["ExitMembership"] = "exitMembership";
  CardActions2["Follow"] = "follow";
  CardActions2["MemberJoin"] = "memberJoin";
  CardActions2["OpenJoin"] = "openJoin";
  CardActions2["Subscribe"] = "subscribe";
  CardActions2["Unfollow"] = "unfollow";
  CardActions2["Unsubscribe"] = "unsubscribe";
  return CardActions2;
})(CardActions || {});
var CardEngagementType = /* @__PURE__ */ ((CardEngagementType2) => {
  CardEngagementType2["Bookmark"] = "bookmark";
  CardEngagementType2["Comment"] = "comment";
  CardEngagementType2["Follow"] = "follow";
  CardEngagementType2["Heart"] = "heart";
  CardEngagementType2["Share"] = "share";
  return CardEngagementType2;
})(CardEngagementType || {});
var CardPostType = /* @__PURE__ */ ((CardPostType2) => {
  CardPostType2["Blog"] = "blog";
  CardPostType2["Book"] = "book";
  CardPostType2["Channel"] = "channel";
  CardPostType2["Chapter"] = "chapter";
  CardPostType2["Collection"] = "collection";
  CardPostType2["Course"] = "course";
  CardPostType2["Creation"] = "creation";
  CardPostType2["CustomFieldsSummary"] = "custom_fields_summary";
  CardPostType2["Dialog"] = "dialog";
  CardPostType2["Document"] = "document";
  CardPostType2["Dynamic"] = "dynamic";
  CardPostType2["Event"] = "event";
  CardPostType2["Formspec"] = "formspec";
  CardPostType2["Group"] = "group";
  CardPostType2["Header"] = "header";
  CardPostType2["Image"] = "image";
  CardPostType2["ImageSquare"] = "image_square";
  CardPostType2["Library"] = "library";
  CardPostType2["Link"] = "link";
  CardPostType2["Linktile"] = "linktile";
  CardPostType2["Map"] = "map";
  CardPostType2["Metachannel"] = "metachannel";
  CardPostType2["Mobilization"] = "mobilization";
  CardPostType2["Mobilize"] = "mobilize";
  CardPostType2["Pledge"] = "pledge";
  CardPostType2["Position"] = "position";
  CardPostType2["Postbox"] = "postbox";
  CardPostType2["Posting"] = "posting";
  CardPostType2["Profile"] = "profile";
  CardPostType2["RainbowRoad"] = "rainbow_road";
  CardPostType2["Research"] = "research";
  CardPostType2["Shelf"] = "shelf";
  CardPostType2["Story"] = "story";
  CardPostType2["Summary"] = "summary";
  CardPostType2["Survey"] = "survey";
  CardPostType2["Text"] = "text";
  CardPostType2["Video"] = "video";
  CardPostType2["Votegallery"] = "votegallery";
  return CardPostType2;
})(CardPostType || {});
var CardVoteType = /* @__PURE__ */ ((CardVoteType2) => {
  CardVoteType2["BallotMeasure"] = "BALLOT_MEASURE";
  CardVoteType2["Quality"] = "QUALITY";
  CardVoteType2["Relevance"] = "RELEVANCE";
  CardVoteType2["Sentiment"] = "SENTIMENT";
  return CardVoteType2;
})(CardVoteType || {});
var Collection = /* @__PURE__ */ ((Collection2) => {
  Collection2["Application"] = "APPLICATION";
  Collection2["Comment"] = "COMMENT";
  Collection2["ContentCard"] = "CONTENT_CARD";
  Collection2["Group"] = "GROUP";
  Collection2["Message"] = "MESSAGE";
  Collection2["MessageThread"] = "MESSAGE_THREAD";
  Collection2["User"] = "USER";
  return Collection2;
})(Collection || {});
var CommunityTypes = /* @__PURE__ */ ((CommunityTypes2) => {
  CommunityTypes2["AllPeople"] = "all_people";
  CommunityTypes2["Connections"] = "connections";
  CommunityTypes2["Followers"] = "followers";
  CommunityTypes2["Following"] = "following";
  CommunityTypes2["SharedGroups"] = "shared_groups";
  return CommunityTypes2;
})(CommunityTypes || {});
var ComparatorType = /* @__PURE__ */ ((ComparatorType2) => {
  ComparatorType2["MultiAndPos"] = "MULTI_AND_POS";
  ComparatorType2["MultiOrPos"] = "MULTI_OR_POS";
  ComparatorType2["None"] = "NONE";
  ComparatorType2["ProximityFar"] = "PROXIMITY_FAR";
  ComparatorType2["ProximityNear"] = "PROXIMITY_NEAR";
  ComparatorType2["RangePos"] = "RANGE_POS";
  return ComparatorType2;
})(ComparatorType || {});
var ConnectionAction = /* @__PURE__ */ ((ConnectionAction2) => {
  ConnectionAction2["AcceptConnection"] = "acceptConnection";
  ConnectionAction2["CancelRequestConnection"] = "cancelRequestConnection";
  ConnectionAction2["Disconnect"] = "disconnect";
  ConnectionAction2["RejectConnection"] = "rejectConnection";
  ConnectionAction2["RequestConnection"] = "requestConnection";
  return ConnectionAction2;
})(ConnectionAction || {});
var ConnectionStatus = /* @__PURE__ */ ((ConnectionStatus2) => {
  ConnectionStatus2["Connected"] = "connected";
  ConnectionStatus2["Disconnected"] = "disconnected";
  ConnectionStatus2["IsLoggedInUser"] = "isLoggedInUser";
  ConnectionStatus2["Pending"] = "pending";
  ConnectionStatus2["Rejected"] = "rejected";
  ConnectionStatus2["Requested"] = "requested";
  return ConnectionStatus2;
})(ConnectionStatus || {});
var CouponType = /* @__PURE__ */ ((CouponType2) => {
  CouponType2["Default"] = "default";
  return CouponType2;
})(CouponType || {});
var CourseProgressType = /* @__PURE__ */ ((CourseProgressType2) => {
  CourseProgressType2["LevelComplete"] = "level_complete";
  CourseProgressType2["StepComplete"] = "step_complete";
  return CourseProgressType2;
})(CourseProgressType || {});
var CustomSignupFieldTypes = /* @__PURE__ */ ((CustomSignupFieldTypes2) => {
  CustomSignupFieldTypes2["ChooseRole"] = "choose_role";
  CustomSignupFieldTypes2["ConsentBox"] = "consent_box";
  CustomSignupFieldTypes2["Description"] = "description";
  CustomSignupFieldTypes2["Dropdown"] = "dropdown";
  CustomSignupFieldTypes2["JoinGroup"] = "joinGroup";
  CustomSignupFieldTypes2["Numberslider"] = "numberslider";
  CustomSignupFieldTypes2["Paragraph"] = "paragraph";
  CustomSignupFieldTypes2["ProfileLocation"] = "profile_location";
  CustomSignupFieldTypes2["ProfileOrganization"] = "profile_organization";
  CustomSignupFieldTypes2["Shortanswer"] = "shortanswer";
  CustomSignupFieldTypes2["Signature"] = "signature";
  CustomSignupFieldTypes2["UploadFile"] = "uploadFile";
  return CustomSignupFieldTypes2;
})(CustomSignupFieldTypes || {});
var ElectionStatus = /* @__PURE__ */ ((ElectionStatus2) => {
  ElectionStatus2["ActiveTimed"] = "ACTIVE_TIMED";
  ElectionStatus2["ActiveUntimed"] = "ACTIVE_UNTIMED";
  ElectionStatus2["Closed"] = "CLOSED";
  ElectionStatus2["Created"] = "CREATED";
  ElectionStatus2["Scheduled"] = "SCHEDULED";
  return ElectionStatus2;
})(ElectionStatus || {});
var EngagementFilterType = /* @__PURE__ */ ((EngagementFilterType2) => {
  EngagementFilterType2["CustomField"] = "CUSTOM_FIELD";
  EngagementFilterType2["Deactivated"] = "DEACTIVATED";
  EngagementFilterType2["Group"] = "GROUP";
  EngagementFilterType2["Nation"] = "NATION";
  EngagementFilterType2["NewRecruit"] = "NEW_RECRUIT";
  EngagementFilterType2["PledgeCard"] = "PLEDGE_CARD";
  EngagementFilterType2["PledgeKey"] = "PLEDGE_KEY";
  EngagementFilterType2["Recruiter"] = "RECRUITER";
  EngagementFilterType2["RecruitType"] = "RECRUIT_TYPE";
  EngagementFilterType2["TimeWindow"] = "TIME_WINDOW";
  return EngagementFilterType2;
})(EngagementFilterType || {});
var EngagementSortOrder = /* @__PURE__ */ ((EngagementSortOrder2) => {
  EngagementSortOrder2["Asc"] = "ASC";
  EngagementSortOrder2["Desc"] = "DESC";
  return EngagementSortOrder2;
})(EngagementSortOrder || {});
var EngagementSortType = /* @__PURE__ */ ((EngagementSortType2) => {
  EngagementSortType2["Comments"] = "COMMENTS";
  EngagementSortType2["CreateDate"] = "CREATE_DATE";
  EngagementSortType2["JoinDate"] = "JOIN_DATE";
  EngagementSortType2["Name"] = "NAME";
  EngagementSortType2["Pledges"] = "PLEDGES";
  EngagementSortType2["Posts"] = "POSTS";
  EngagementSortType2["RecruitDate"] = "RECRUIT_DATE";
  EngagementSortType2["Votes"] = "VOTES";
  return EngagementSortType2;
})(EngagementSortType || {});
var EngagementType = /* @__PURE__ */ ((EngagementType2) => {
  EngagementType2["Comments"] = "COMMENTS";
  EngagementType2["Pledges"] = "PLEDGES";
  EngagementType2["Posts"] = "POSTS";
  EngagementType2["Votes"] = "VOTES";
  return EngagementType2;
})(EngagementType || {});
var EventAction = /* @__PURE__ */ ((EventAction2) => {
  EventAction2["Create"] = "CREATE";
  EventAction2["Delete"] = "DELETE";
  EventAction2["Update"] = "UPDATE";
  EventAction2["AcceptConnection"] = "acceptConnection";
  EventAction2["AdminAnnouncement"] = "adminAnnouncement";
  EventAction2["ApplicationAccepted"] = "applicationAccepted";
  EventAction2["ApplicationClosed"] = "applicationClosed";
  EventAction2["ApplicationRejected"] = "applicationRejected";
  EventAction2["AttachedCardToChapter"] = "attachedCardToChapter";
  EventAction2["BroadcastSurvey"] = "broadcastSurvey";
  EventAction2["CancelRequestConnection"] = "cancelRequestConnection";
  EventAction2["CardAccessRequest"] = "cardAccessRequest";
  EventAction2["CardContentAutoModerated"] = "cardContentAutoModerated";
  EventAction2["CardContentModeratedAccepted"] = "cardContentModeratedAccepted";
  EventAction2["CardContentModeratedRejected"] = "cardContentModeratedRejected";
  EventAction2["DashfeedPushed"] = "dashfeedPushed";
  EventAction2["Disconnect"] = "disconnect";
  EventAction2["DownloadLausanneSurveyPdf"] = "downloadLausanneSurveyPdf";
  EventAction2["ElectionEnd"] = "electionEnd";
  EventAction2["ElectionReminder"] = "electionReminder";
  EventAction2["ElectionStart"] = "electionStart";
  EventAction2["EventCanceled"] = "eventCanceled";
  EventAction2["GroupAccessApproved"] = "groupAccessApproved";
  EventAction2["GroupAccessRequest"] = "groupAccessRequest";
  EventAction2["GroupAdminPromoted"] = "groupAdminPromoted";
  EventAction2["GroupConnectedAccountReady"] = "groupConnectedAccountReady";
  EventAction2["GroupContentAutoModerated"] = "groupContentAutoModerated";
  EventAction2["GroupContentModeratedAccepted"] = "groupContentModeratedAccepted";
  EventAction2["GroupContentModeratedRejected"] = "groupContentModeratedRejected";
  EventAction2["GroupInviteAccepted"] = "groupInviteAccepted";
  EventAction2["GroupInviteRequest"] = "groupInviteRequest";
  EventAction2["GroupPreAuthPaymentRequest"] = "groupPreAuthPaymentRequest";
  EventAction2["GroupPreAuthPaymentRequestApproved"] = "groupPreAuthPaymentRequestApproved";
  EventAction2["GroupSpeakerInvite"] = "groupSpeakerInvite";
  EventAction2["MissedPledgeCommitment"] = "missedPledgeCommitment";
  EventAction2["MobilizationComplete"] = "mobilizationComplete";
  EventAction2["MobilizationEffortComplete"] = "mobilizationEffortComplete";
  EventAction2["NewApplication"] = "newApplication";
  EventAction2["NewMessage"] = "newMessage";
  EventAction2["NotifyGroupsOfTarget"] = "notifyGroupsOfTarget";
  EventAction2["PostingClosed"] = "postingClosed";
  EventAction2["RejectConnection"] = "rejectConnection";
  EventAction2["RequestConnection"] = "requestConnection";
  EventAction2["RoleAdminPromotion"] = "roleAdminPromotion";
  EventAction2["SubscriptionDeleted"] = "subscriptionDeleted";
  EventAction2["SubscriptionPriceChange"] = "subscriptionPriceChange";
  EventAction2["TicketTransfer"] = "ticketTransfer";
  EventAction2["UnreadMessagesNudge"] = "unreadMessagesNudge";
  EventAction2["VoteAdded"] = "voteAdded";
  return EventAction2;
})(EventAction || {});
var EventRsvp = /* @__PURE__ */ ((EventRsvp2) => {
  EventRsvp2["Going"] = "GOING";
  EventRsvp2["Interested"] = "INTERESTED";
  EventRsvp2["MaybeAttending"] = "MAYBE_ATTENDING";
  EventRsvp2["NotAttending"] = "NOT_ATTENDING";
  EventRsvp2["NotGoing"] = "NOT_GOING";
  EventRsvp2["NoResponse"] = "NO_RESPONSE";
  EventRsvp2["YesAttending"] = "YES_ATTENDING";
  return EventRsvp2;
})(EventRsvp || {});
var FilterType = /* @__PURE__ */ ((FilterType2) => {
  FilterType2["Archived"] = "archived";
  FilterType2["Az"] = "az";
  FilterType2["Custom"] = "custom";
  FilterType2["Leaderboard"] = "leaderboard";
  FilterType2["Live"] = "live";
  FilterType2["OldestFirst"] = "oldestFirst";
  FilterType2["Ordered"] = "ordered";
  FilterType2["Past"] = "past";
  FilterType2["Top"] = "top";
  FilterType2["Upcoming"] = "upcoming";
  FilterType2["Vote"] = "vote";
  return FilterType2;
})(FilterType || {});
var FormSpecificationType = /* @__PURE__ */ ((FormSpecificationType2) => {
  FormSpecificationType2["Posting"] = "POSTING";
  FormSpecificationType2["Standard"] = "STANDARD";
  return FormSpecificationType2;
})(FormSpecificationType || {});
var GroupActions = /* @__PURE__ */ ((GroupActions2) => {
  GroupActions2["AcceptInviteGroup"] = "acceptInviteGroup";
  GroupActions2["CancelPreAuthPurchase"] = "cancelPreAuthPurchase";
  GroupActions2["CancelRequestJoinGroup"] = "cancelRequestJoinGroup";
  GroupActions2["EditProducts"] = "editProducts";
  GroupActions2["ExpressInterest"] = "expressInterest";
  GroupActions2["JoinGroup"] = "joinGroup";
  GroupActions2["LeaveGroup"] = "leaveGroup";
  GroupActions2["LoggedOutExpressInterest"] = "loggedOutExpressInterest";
  GroupActions2["LoggedOutPurchaseAccess"] = "loggedOutPurchaseAccess";
  GroupActions2["LoggedOutRsvp"] = "loggedOutRSVP";
  GroupActions2["PurchaseAccess"] = "purchaseAccess";
  GroupActions2["PurchaseParentAccess"] = "purchaseParentAccess";
  GroupActions2["ReactivateSubscription"] = "reactivateSubscription";
  GroupActions2["RequestJoinGroup"] = "requestJoinGroup";
  GroupActions2["RequestToPurchaseAccess"] = "requestToPurchaseAccess";
  GroupActions2["RespondNotGoing"] = "respondNotGoing";
  GroupActions2["ViewPurchasedProducts"] = "viewPurchasedProducts";
  return GroupActions2;
})(GroupActions || {});
var GroupRole = /* @__PURE__ */ ((GroupRole2) => {
  GroupRole2["Invited"] = "invited";
  GroupRole2["Member"] = "member";
  GroupRole2["None"] = "none";
  GroupRole2["Owner"] = "owner";
  GroupRole2["Requested"] = "requested";
  return GroupRole2;
})(GroupRole || {});
var GroupSubtype = /* @__PURE__ */ ((GroupSubtype2) => {
  GroupSubtype2["Event"] = "event";
  GroupSubtype2["General"] = "general";
  GroupSubtype2["Organization"] = "organization";
  return GroupSubtype2;
})(GroupSubtype || {});
var LabelType = /* @__PURE__ */ ((LabelType2) => {
  LabelType2["Bubble"] = "BUBBLE";
  LabelType2["Plain"] = "PLAIN";
  return LabelType2;
})(LabelType || {});
var LocationType = /* @__PURE__ */ ((LocationType2) => {
  LocationType2["InPerson"] = "in_person";
  LocationType2["Virtual"] = "virtual";
  LocationType2["VirtualAndInPerson"] = "virtual_and_in_person";
  return LocationType2;
})(LocationType || {});
var MembershipStyle = /* @__PURE__ */ ((MembershipStyle2) => {
  MembershipStyle2["Follow"] = "follow";
  MembershipStyle2["Friends"] = "friends";
  MembershipStyle2["Grouped"] = "grouped";
  return MembershipStyle2;
})(MembershipStyle || {});
var MessageThreadType = /* @__PURE__ */ ((MessageThreadType2) => {
  MessageThreadType2["DirectMessage"] = "DIRECT_MESSAGE";
  MessageThreadType2["MultiUserChat"] = "MULTI_USER_CHAT";
  MessageThreadType2["OfficialGroupAdminChat"] = "OFFICIAL_GROUP_ADMIN_CHAT";
  MessageThreadType2["OfficialGroupChat"] = "OFFICIAL_GROUP_CHAT";
  return MessageThreadType2;
})(MessageThreadType || {});
var MobilizationStatus = /* @__PURE__ */ ((MobilizationStatus2) => {
  MobilizationStatus2["Complete"] = "COMPLETE";
  MobilizationStatus2["InProgress"] = "IN_PROGRESS";
  return MobilizationStatus2;
})(MobilizationStatus || {});
var MobilizationType = /* @__PURE__ */ ((MobilizationType2) => {
  MobilizationType2["Pledge"] = "PLEDGE";
  MobilizationType2["Posting"] = "POSTING";
  MobilizationType2["Task"] = "TASK";
  return MobilizationType2;
})(MobilizationType || {});
var NewsfeedFilter = /* @__PURE__ */ ((NewsfeedFilter2) => {
  NewsfeedFilter2["ForYou"] = "FOR_YOU";
  NewsfeedFilter2["Recent"] = "RECENT";
  return NewsfeedFilter2;
})(NewsfeedFilter || {});
var NotificationChannelType = /* @__PURE__ */ ((NotificationChannelType2) => {
  NotificationChannelType2["Email"] = "EMAIL";
  NotificationChannelType2["Pubsub"] = "PUBSUB";
  NotificationChannelType2["Push"] = "PUSH";
  return NotificationChannelType2;
})(NotificationChannelType || {});
var NotificationLevel = /* @__PURE__ */ ((NotificationLevel2) => {
  NotificationLevel2["Mention"] = "MENTION";
  NotificationLevel2["Off"] = "OFF";
  NotificationLevel2["On"] = "ON";
  return NotificationLevel2;
})(NotificationLevel || {});
var NotificationType = /* @__PURE__ */ ((NotificationType2) => {
  NotificationType2["Default"] = "DEFAULT";
  NotificationType2["Mention"] = "MENTION";
  return NotificationType2;
})(NotificationType || {});
var OrderLocation = /* @__PURE__ */ ((OrderLocation2) => {
  OrderLocation2["InPerson"] = "inPerson";
  OrderLocation2["Online"] = "online";
  return OrderLocation2;
})(OrderLocation || {});
var PaymentType = /* @__PURE__ */ ((PaymentType2) => {
  PaymentType2["Cash"] = "cash";
  PaymentType2["CreditCard"] = "creditCard";
  PaymentType2["NotApplicable"] = "notApplicable";
  return PaymentType2;
})(PaymentType || {});
var PositionType = /* @__PURE__ */ ((PositionType2) => {
  PositionType2["Post"] = "post";
  PositionType2["Pre"] = "pre";
  return PositionType2;
})(PositionType || {});
var PostingStatus = /* @__PURE__ */ ((PostingStatus2) => {
  PostingStatus2["Cancelled"] = "CANCELLED";
  PostingStatus2["Closed"] = "CLOSED";
  PostingStatus2["Open"] = "OPEN";
  return PostingStatus2;
})(PostingStatus || {});
var PostingType = /* @__PURE__ */ ((PostingType2) => {
  PostingType2["Job"] = "JOB";
  PostingType2["Volunteer"] = "VOLUNTEER";
  return PostingType2;
})(PostingType || {});
var RrCircleSize = /* @__PURE__ */ ((RrCircleSize2) => {
  RrCircleSize2["Large"] = "large";
  RrCircleSize2["Medium"] = "medium";
  RrCircleSize2["Small"] = "small";
  return RrCircleSize2;
})(RrCircleSize || {});
var RrFont = /* @__PURE__ */ ((RrFont2) => {
  RrFont2["Default"] = "default";
  RrFont2["Lego"] = "lego";
  return RrFont2;
})(RrFont || {});
var RrLineStyle = /* @__PURE__ */ ((RrLineStyle2) => {
  RrLineStyle2["Dashed"] = "dashed";
  RrLineStyle2["None"] = "none";
  RrLineStyle2["Solid"] = "solid";
  return RrLineStyle2;
})(RrLineStyle || {});
var RecommendationManager = /* @__PURE__ */ ((RecommendationManager2) => {
  RecommendationManager2["Group"] = "group";
  RecommendationManager2["Library"] = "library";
  RecommendationManager2["Platform"] = "platform";
  RecommendationManager2["Posting"] = "posting";
  return RecommendationManager2;
})(RecommendationManager || {});
var RecommendationType = /* @__PURE__ */ ((RecommendationType2) => {
  RecommendationType2["ContentCard"] = "CONTENT_CARD";
  RecommendationType2["Group"] = "GROUP";
  RecommendationType2["User"] = "USER";
  return RecommendationType2;
})(RecommendationType || {});
var RecruitType = /* @__PURE__ */ ((RecruitType2) => {
  RecruitType2["Existing"] = "EXISTING";
  RecruitType2["New"] = "NEW";
  RecruitType2["None"] = "NONE";
  RecruitType2["Potential"] = "POTENTIAL";
  return RecruitType2;
})(RecruitType || {});
var ReferenceType = /* @__PURE__ */ ((ReferenceType2) => {
  ReferenceType2["Book"] = "book";
  ReferenceType2["Chapter"] = "chapter";
  ReferenceType2["Group"] = "group";
  ReferenceType2["Library"] = "library";
  ReferenceType2["Prompt"] = "prompt";
  ReferenceType2["Shelf"] = "shelf";
  ReferenceType2["User"] = "user";
  return ReferenceType2;
})(ReferenceType || {});
var ReportingType = /* @__PURE__ */ ((ReportingType2) => {
  ReportingType2["AddNote"] = "ADD_NOTE";
  ReportingType2["Done"] = "DONE";
  ReportingType2["RegisterUser"] = "REGISTER_USER";
  ReportingType2["UploadImage"] = "UPLOAD_IMAGE";
  return ReportingType2;
})(ReportingType || {});
var Resource = /* @__PURE__ */ ((Resource2) => {
  Resource2["Books"] = "Books";
  Resource2["Crayons"] = "Crayons";
  Resource2["Flowers"] = "Flowers";
  Resource2["Hearts"] = "Hearts";
  Resource2["Wood"] = "Wood";
  return Resource2;
})(Resource || {});
var Role = /* @__PURE__ */ ((Role2) => {
  Role2["Admin"] = "admin";
  Role2["Answer"] = "answer";
  Role2["Manage"] = "manage";
  Role2["None"] = "none";
  Role2["Speaker"] = "speaker";
  Role2["View"] = "view";
  Role2["Vote"] = "vote";
  return Role2;
})(Role || {});
var SearchFilterType = /* @__PURE__ */ ((SearchFilterType2) => {
  SearchFilterType2["Chapter"] = "CHAPTER";
  SearchFilterType2["Collection"] = "COLLECTION";
  SearchFilterType2["Comment"] = "COMMENT";
  SearchFilterType2["Group"] = "GROUP";
  SearchFilterType2["Message"] = "MESSAGE";
  SearchFilterType2["Post"] = "POST";
  SearchFilterType2["Prompt"] = "PROMPT";
  SearchFilterType2["User"] = "USER";
  return SearchFilterType2;
})(SearchFilterType || {});
var SearchScopeType = /* @__PURE__ */ ((SearchScopeType2) => {
  SearchScopeType2["Contentcard"] = "CONTENTCARD";
  SearchScopeType2["Group"] = "GROUP";
  return SearchScopeType2;
})(SearchScopeType || {});
var SocialActions = /* @__PURE__ */ ((SocialActions2) => {
  SocialActions2["AcceptConnection"] = "acceptConnection";
  SocialActions2["CancelRequestConnection"] = "cancelRequestConnection";
  SocialActions2["Disconnect"] = "disconnect";
  SocialActions2["Follow"] = "follow";
  SocialActions2["Message"] = "message";
  SocialActions2["RejectConnection"] = "rejectConnection";
  SocialActions2["RequestConnection"] = "requestConnection";
  SocialActions2["Unfollow"] = "unfollow";
  return SocialActions2;
})(SocialActions || {});
var StorageType = /* @__PURE__ */ ((StorageType2) => {
  StorageType2["Ceramic"] = "CERAMIC";
  StorageType2["Mongodb"] = "MONGODB";
  StorageType2["None"] = "NONE";
  return StorageType2;
})(StorageType || {});
var StripeInvoiceStatus = /* @__PURE__ */ ((StripeInvoiceStatus2) => {
  StripeInvoiceStatus2["Draft"] = "draft";
  StripeInvoiceStatus2["Open"] = "open";
  StripeInvoiceStatus2["Paid"] = "paid";
  StripeInvoiceStatus2["Uncollectible"] = "uncollectible";
  StripeInvoiceStatus2["Void"] = "void";
  return StripeInvoiceStatus2;
})(StripeInvoiceStatus || {});
var StripeSubscriptionStatus = /* @__PURE__ */ ((StripeSubscriptionStatus2) => {
  StripeSubscriptionStatus2["Active"] = "active";
  StripeSubscriptionStatus2["Canceled"] = "canceled";
  StripeSubscriptionStatus2["Incomplete"] = "incomplete";
  StripeSubscriptionStatus2["IncompleteExpired"] = "incomplete_expired";
  StripeSubscriptionStatus2["PastDue"] = "past_due";
  StripeSubscriptionStatus2["Trialing"] = "trialing";
  StripeSubscriptionStatus2["Unpaid"] = "unpaid";
  return StripeSubscriptionStatus2;
})(StripeSubscriptionStatus || {});
var SubscriptionLevel = /* @__PURE__ */ ((SubscriptionLevel2) => {
  SubscriptionLevel2["WlBasicMonthly"] = "WL_BASIC_MONTHLY";
  SubscriptionLevel2["WlBasicYearly"] = "WL_BASIC_YEARLY";
  SubscriptionLevel2["WlProMonthly"] = "WL_PRO_MONTHLY";
  SubscriptionLevel2["WlProYearly"] = "WL_PRO_YEARLY";
  SubscriptionLevel2["WlUnlimitedMonthly"] = "WL_UNLIMITED_MONTHLY";
  SubscriptionLevel2["WlUnlimitedYearly"] = "WL_UNLIMITED_YEARLY";
  return SubscriptionLevel2;
})(SubscriptionLevel || {});
var SurveyQuestionSubType = /* @__PURE__ */ ((SurveyQuestionSubType2) => {
  SurveyQuestionSubType2["Emotional"] = "Emotional";
  SurveyQuestionSubType2["Image"] = "Image";
  SurveyQuestionSubType2["LongAnswer"] = "LongAnswer";
  SurveyQuestionSubType2["Numeric"] = "Numeric";
  SurveyQuestionSubType2["ShortAnswer"] = "ShortAnswer";
  SurveyQuestionSubType2["Text"] = "Text";
  SurveyQuestionSubType2["ThreeBoxAnswer"] = "ThreeBoxAnswer";
  return SurveyQuestionSubType2;
})(SurveyQuestionSubType || {});
var SurveyQuestionType = /* @__PURE__ */ ((SurveyQuestionType2) => {
  SurveyQuestionType2["Image"] = "Image";
  SurveyQuestionType2["ImageOrText"] = "ImageOrText";
  SurveyQuestionType2["MultipleChoice"] = "MultipleChoice";
  SurveyQuestionType2["Slider"] = "Slider";
  SurveyQuestionType2["Text"] = "Text";
  return SurveyQuestionType2;
})(SurveyQuestionType || {});
var TicketCheckInStatusEnum = /* @__PURE__ */ ((TicketCheckInStatusEnum2) => {
  TicketCheckInStatusEnum2["CheckedIn"] = "checkedIn";
  TicketCheckInStatusEnum2["CheckingIn"] = "checkingIn";
  TicketCheckInStatusEnum2["Invalid"] = "invalid";
  return TicketCheckInStatusEnum2;
})(TicketCheckInStatusEnum || {});
var UpdateAction = /* @__PURE__ */ ((UpdateAction2) => {
  UpdateAction2["Add"] = "ADD";
  UpdateAction2["Delete"] = "DELETE";
  UpdateAction2["Update"] = "UPDATE";
  return UpdateAction2;
})(UpdateAction || {});
var UserType = /* @__PURE__ */ ((UserType2) => {
  UserType2["Child"] = "child";
  UserType2["Ephemeral"] = "ephemeral";
  UserType2["Group"] = "group";
  UserType2["Guardian"] = "guardian";
  UserType2["Normal"] = "normal";
  UserType2["Organization"] = "organization";
  UserType2["System"] = "system";
  return UserType2;
})(UserType || {});
var VerifierType = /* @__PURE__ */ ((VerifierType2) => {
  VerifierType2["Recruit"] = "RECRUIT";
  return VerifierType2;
})(VerifierType || {});
var ViewPermission = /* @__PURE__ */ ((ViewPermission2) => {
  ViewPermission2["Private"] = "private";
  ViewPermission2["Public"] = "public";
  ViewPermission2["Secret"] = "secret";
  ViewPermission2["Unset"] = "unset";
  return ViewPermission2;
})(ViewPermission || {});
var ViewType = /* @__PURE__ */ ((ViewType2) => {
  ViewType2["Fullview"] = "fullview";
  ViewType2["Listview"] = "listview";
  return ViewType2;
})(ViewType || {});
var Violation = /* @__PURE__ */ ((Violation2) => {
  Violation2["GroupPolicy"] = "GROUP_POLICY";
  Violation2["WelibraryPolicy"] = "WELIBRARY_POLICY";
  return Violation2;
})(Violation || {});
var RunSearchJobDocument = gql5`
    query RunSearchJob($input: SearchInput!) {
  runSearchJob(input: $input) {
    text
    limit
    results {
      cursor
      results {
        type
        highlights {
          path
          texts {
            value
            type
          }
          score
        }
        entity {
          ... on Message {
            __typename
            _id
            author {
              __typename
              _id
              profile {
                __typename
                full_name
                picture
                short_bio
              }
            }
            thread {
              ...MessageThreadFragment
            }
            attachment {
              ...ContentCardFragment
            }
            text
            created
            updated
          }
          ... on Group {
            __typename
            _id
            path
            profile {
              full_name
              short_bio
              full_bio
              picture
              coverPhoto
              country
              website
              email
            }
          }
          ... on User {
            __typename
            _id
            profile {
              full_name
              short_bio
              full_bio
              picture
              coverPhoto
              country
              language
              website
              email
              phone
              nickname
              followersCount
              connectionsCount
              loggedInUserConnectionStatus
            }
          }
          ... on ContentCard {
            _id
            type
            url
            title
            body
            description
            prompt
            media {
              icon
              thumb
              __typename
            }
            library_settings {
              displayReference
              displaySocialHeaders
            }
            reference {
              href
              coverImage
              bookSource {
                title
                url
                library_settings {
                  displayReference
                }
                media {
                  thumb
                }
              }
              chapterSource {
                title
                url
                media {
                  icon
                  thumb
                }
                library_settings {
                  engagementTypes
                  viewTypes
                  defaultViewType
                  displayReference
                  displaySocialHeaders
                }
              }
              groupSource {
                _id
                profile {
                  full_name
                }
              }
            }
            date {
              hide_display
              last_update
              publish
            }
            links {
              source
              other
            }
            author {
              _id
              type
              profile {
                full_name
                __typename
              }
              __typename
            }
          }
          ... on Comment {
            _id
            publish
            commentBody: body
            stream {
              __typename
              _id
              title
              body
            }
            creator {
              __typename
              _id
              profile {
                picture
                full_name
              }
            }
          }
        }
      }
      hasMore
      type
    }
  }
}
    ${MessageThreadFragmentFragmentDoc}
${ContentCardFragmentFragmentDoc}`;
function useRunSearchJobQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery(RunSearchJobDocument, options);
}
__name(useRunSearchJobQuery, "useRunSearchJobQuery");
function useRunSearchJobLazyQuery(baseOptions) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery(RunSearchJobDocument, options);
}
__name(useRunSearchJobLazyQuery, "useRunSearchJobLazyQuery");
export {
  AccountHolderType,
  AccountStatus,
  AggregationType,
  AnalyticScopeType,
  ApplicationStatus,
  ApprovalType,
  BulkCardAction,
  CardActions,
  CardEngagementType,
  CardPostType,
  CardVoteType,
  Collection,
  CommunityTypes,
  ComparatorType,
  ConnectionAction,
  ConnectionStatus,
  CouponType,
  CourseProgressType,
  CustomSignupFieldTypes,
  ElectionStatus,
  EngagementFilterType,
  EngagementSortOrder,
  EngagementSortType,
  EngagementType,
  EventAction,
  EventRsvp,
  FilterType,
  FormSpecificationType,
  GroupActions,
  GroupRole,
  GroupSubtype,
  LabelType,
  LocationType,
  MembershipStyle,
  MessageThreadType,
  MobilizationStatus,
  MobilizationType,
  NewsfeedFilter,
  NotificationChannelType,
  NotificationLevel,
  NotificationType,
  OrderLocation,
  PaymentType,
  PositionType,
  PostingStatus,
  PostingType,
  RecommendationManager,
  RecommendationType,
  RecruitType,
  ReferenceType,
  ReportingType,
  Resource,
  Role,
  RrCircleSize,
  RrFont,
  RrLineStyle,
  RunSearchJobDocument,
  SearchFilterType,
  SearchScopeType,
  SocialActions,
  StorageType,
  StripeInvoiceStatus,
  StripeSubscriptionStatus,
  SubscriptionLevel,
  SurveyQuestionSubType,
  SurveyQuestionType,
  TicketCheckInStatusEnum,
  UpdateAction,
  UserType,
  VerifierType,
  ViewPermission,
  ViewType,
  Violation,
  useRunSearchJobLazyQuery,
  useRunSearchJobQuery
};
