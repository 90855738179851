import React from 'react';

const NavigatorToggleButtonTop: React.FC<{ className?: string }> = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 20 38">
        <path
            d="M20 29.3919C20 15.9128 1.87337e-06 17.4105 3.49691e-06 0L7.56089e-07 29.3919L0 37.5L20 37.5L20 29.3919Z"
            fill="currentcolor"
        />
    </svg>
);

export default NavigatorToggleButtonTop;
