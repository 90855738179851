import React from 'react';
import { curry } from 'lodash';
import { Updater } from 'use-immer';

import SurveyShortAnswer from '@components/survey/SurveyShortAnswer';
import SurveyLongAnswer from '@components/survey/SurveyLongAnswer';
import SurveyThreeBox from '@components/survey/SurveyThreeBox';
import SurveyImage from '@components/survey/SurveyImage';
import SurveyImageOrText from '@components/survey/SurveyImageOrText';
import SurveyEmotionalSlider from '@components/survey/SurveyEmotionalSlider';
import SurveyNumericSlider from '@components/survey/SurveyNumericSlider';
import SurveyMultipleChoice from '@components/survey/SurveyMultipleChoice';

import { SurveyQuestionFormState, SurveyQuestionState } from '@core/types/Surveys';
import { SetState, Maybify } from '@core/types/Utilities';
import { SurveyQuestion, SurveyQuestionType, SurveyQuestionSubType } from '@shared/welibrary-graphql/types';
import { AggregateSkills, getAggregateSkillsFromArray } from '../lego/skill.helpers';

export const defaultQuestion = {
    type: SurveyQuestionType.Text,
    subtype: SurveyQuestionSubType.ShortAnswer,
    prompt: '',
    image: { image: '' },
};

export const SurveyQuestionMeta: Record<
    SurveyQuestionType,
    { fields: Array<keyof Omit<SurveyQuestion, '__typename'>>; subtypes?: SurveyQuestionSubType[] }
> = {
    Image: { fields: ['prompt', 'useImage', 'image', 'skill', 'skillSubtype', 'skillMilestone'] },
    Text: {
        fields: [
            'subtype',
            'prompt',
            'useImage',
            'image',
            'skill',
            'skillSubtype',
            'skillMilestone',
        ],
        subtypes: [
            SurveyQuestionSubType.ShortAnswer,
            SurveyQuestionSubType.LongAnswer,
            SurveyQuestionSubType.ThreeBoxAnswer,
        ],
    },
    ImageOrText: {
        fields: ['prompt', 'useImage', 'image', 'skill', 'skillSubtype', 'skillMilestone'],
    },
    Slider: {
        fields: [
            'subtype',
            'prompt',
            'useImage',
            'image',
            'range',
            'skill',
            'skillSubtype',
            'skillMilestone',
        ],
        subtypes: [SurveyQuestionSubType.Emotional, SurveyQuestionSubType.Numeric],
    },
    MultipleChoice: {
        fields: [
            'prompt',
            'useImage',
            'image',
            'answers',
            'correctAnswer',
            'skill',
            'skillSubtype',
            'skillMilestone',
        ],
    },
};

export const curriedShouldShowField = curry(
    (type: SurveyQuestionType, field: keyof Omit<SurveyQuestion, '__typename'>): boolean =>
        SurveyQuestionMeta[type].fields.includes(field)
);

export const SurveyQuestionTypeDisplay: Record<SurveyQuestionType, string> = {
    Image: 'Image',
    Text: 'Text',
    ImageOrText: 'Image or Text',
    Slider: 'Slider',
    MultipleChoice: 'Multiple Choice',
};

export const SurveyQuestionSubTypeDisplay: Record<SurveyQuestionSubType, string> = {
    ShortAnswer: 'Short Answer',
    LongAnswer: 'Long Answer',
    ThreeBoxAnswer: 'Three Box Answer',
    Image: 'Image',
    Text: 'Text',
    Emotional: 'Emotional',
    Numeric: 'Numeric',
};

export const SurveyQuestionToSurveyQuestionFormState = (
    question?: SurveyQuestion | null
): SurveyQuestionFormState => ({
    _id: question?._id || undefined,
    type: question?.type || SurveyQuestionType.Text,
    subtype: question?.subtype || undefined,
    prompt: question?.prompt || '',
    useImage: question?.useImage || false,
    image: { image: question?.image || '' },
    range: question?.range || undefined,
    answers: question?.answers || undefined,
    correctAnswer: question?.correctAnswer || undefined,
    skill: question?.skill ?? undefined,
    skillSubtype: question?.skillSubtype ?? undefined,
    skillMilestone: question?.skillMilestone ?? undefined,
});

export const SurveyQuestionToSurveyQuestionState = (
    question?: Maybify<SurveyQuestion> | null
): SurveyQuestionState => {
    const { type, subtype } = question ?? {};

    if (type === SurveyQuestionType.Text) {
        if (
            subtype === SurveyQuestionSubType.ShortAnswer ||
            subtype === SurveyQuestionSubType.LongAnswer
        ) {
            return '';
        }

        if (subtype === SurveyQuestionSubType.ThreeBoxAnswer) {
            return ['', '', ''];
        }
    } else if (type === SurveyQuestionType.Image || type === SurveyQuestionType.MultipleChoice) {
        return '';
    } else if (type === SurveyQuestionType.ImageOrText) {
        return { type: '', value: '' };
    } else if (type === SurveyQuestionType.Slider) {
        return 3;
    }

    return '';
};

export function getSurveyQuestionComponent(
    question: Maybify<SurveyQuestion>,
    state: SurveyQuestionState,
    setState?: Updater<SurveyQuestionState>,
    disabled = false,
    viewOnly = false
) {
    if (question.type === SurveyQuestionType.Text) {
        if (question.subtype === SurveyQuestionSubType.ShortAnswer) {
            return (
                <SurveyShortAnswer
                    state={typeof state === 'string' ? state : ''}
                    setState={setState}
                    question={question}
                    disabled={disabled}
                    viewOnly={viewOnly}
                />
            );
        }

        if (question.subtype === SurveyQuestionSubType.LongAnswer) {
            return (
                <SurveyLongAnswer
                    state={typeof state === 'string' ? state : ''}
                    setState={setState}
                    question={question}
                    disabled={disabled}
                    viewOnly={viewOnly}
                />
            );
        }

        if (question.subtype === SurveyQuestionSubType.ThreeBoxAnswer) {
            return (
                <SurveyThreeBox
                    state={Array.isArray(state) ? state : ['', '', '']}
                    setState={setState as SetState<string[]>}
                    question={question}
                    disabled={disabled}
                    viewOnly={viewOnly}
                />
            );
        }
    } else if (question.type === SurveyQuestionType.Image) {
        return (
            <SurveyImage
                state={typeof state === 'string' ? state : ''}
                setState={setState}
                question={question}
                disabled={disabled}
                viewOnly={viewOnly}
            />
        );
    } else if (question.type === SurveyQuestionType.ImageOrText) {
        return (
            <SurveyImageOrText
                state={
                    typeof state === 'object' && !Array.isArray(state)
                        ? state
                        : { type: '', value: '' }
                }
                setState={setState}
                question={question}
                disabled={disabled}
                viewOnly={viewOnly}
            />
        );
    } else if (question.type === SurveyQuestionType.Slider) {
        if (question.subtype === SurveyQuestionSubType.Emotional) {
            return (
                <SurveyEmotionalSlider
                    state={typeof state === 'number' ? state : 3}
                    setState={setState}
                    question={question}
                    disabled={disabled}
                    viewOnly={viewOnly}
                />
            );
        }

        if (question.subtype === SurveyQuestionSubType.Numeric) {
            return (
                <SurveyNumericSlider
                    state={typeof state === 'number' ? state : 3}
                    setState={setState}
                    question={question}
                    disabled={disabled}
                    viewOnly={viewOnly}
                />
            );
        }
    } else if (question.type === SurveyQuestionType.MultipleChoice) {
        return (
            <SurveyMultipleChoice
                state={typeof state === 'string' ? state : ''}
                setState={setState}
                question={question}
                disabled={disabled}
                viewOnly={viewOnly}
            />
        );
    }

    return <></>;
}

export const getAggregateSkillsForSurvey = (
    surveyQuestions: (SurveyQuestionFormState | SurveyQuestion)[]
): AggregateSkills => {
    return getAggregateSkillsFromArray(surveyQuestions);
};
