import React from 'react';

import { useToastActionsContext } from '@components/toast/NewToastContext';
import { StandardToastProps } from '@core/types/Toast';
import CloseIcon from '@web/ui/dsc/svgs/CloseIcon';

/**
 * The default toast component.
 */
const StandardToast: React.FC<StandardToastProps> = ({
    title,
    message,
    iconClassName,
    handleClick,
    dismissedCallback,
    action,
    dismissable = true,
}) => {
    const { closeToast } = useToastActionsContext();
    return (
        <div className="toast-component" onClick={handleClick}>
            {iconClassName && <i className={`toast-icon ${iconClassName}`} />}
            <div className="toast-content">
                {title && <h4 className="toast-title">{title}</h4>}
                <div className="toast-message">{message}</div>
                {!!action && (
                    <button type="button" className="toast-action" onClick={action.handler}>
                        {action.label}
                    </button>
                )}
            </div>
            {dismissable && (
                <button
                    className="toast-dismiss"
                    type="button"
                    onClick={() => {
                        closeToast();
                        if (dismissedCallback) {
                            dismissedCallback();
                        }
                    }}
                >
                    <CloseIcon />
                </button>
            )}
        </div>
    );
};

export default StandardToast;
