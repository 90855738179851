/* eslint-disable no-lonely-if */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { z } from 'zod';

import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';
import { useValidateChildVerificationCodeLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { triggerHideKeyboardOnKey } from '@core/utilities/keyboardHelpers';

import Door from '@assets/lego-door-illustration.svg';
import StylizedX from '@dsc/svgs/lego/StylizedX';

import Arrow from '@assets/lego-fat-arrow-left.svg';

import { RETAIN_EPHEMERAL_USER_AS_CHILD_ACCOUNT } from '@shared/welibrary-graphql/user/mutations';
import { ModalTypes } from '@core/types/Modals';
import { config } from '@config/getConfig';

import getLogger from '@core/logger';

const logger = getLogger(module);

const StateValidator = z.object({
    code: z.string().nonempty('Code is required! '),
});

const LegoGuardianWall: React.FC<{
    isEphemeral?: boolean;
    isVerifying?: boolean;
    handleRedirect?: () => void;
    showVerifyGuardianButton?: boolean;
    showCancelButton?: boolean;
    customTitle?: string;
}> = ({
    isVerifying,
    handleRedirect,
    isEphemeral = false,
    showVerifyGuardianButton = true,
    showCancelButton = true,
    customTitle,
}) => {
    const { refetchCurrentUser } = useCurrentUser();
    const history = useHistory();
    const { closeModal, closeAllModals } = useModal({
        mobile: ModalTypes.FullScreen,
    });

    const [code, setCode] = useState<string>('');
    const [errors, setErrors] = useState<Record<string, string[]>>({});

    const [validateSignupCode, { data }] = useValidateChildVerificationCodeLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: () => {
            if (data?.validateChildVerificationCode?.isValid) {
                closeModal();
                if (data?.validateChildVerificationCode?.allowAccountSwitcherAccess) {
                    history.push(`/ssl/switcher/class-code/${code}`);
                } else {
                    history.push(`/ssl/class-code/${code}`);
                }
            } else {
                setErrors({
                    code: 'Invalid Code',
                });
            }
        },
    });
    const [retainEphemeralAccount] = useMutation(RETAIN_EPHEMERAL_USER_AS_CHILD_ACCOUNT);

    const validate = () => {
        const parsedData = StateValidator.safeParse({
            code,
        });

        if (parsedData.success) {
            setErrors({});
            return true;
        }

        if (parsedData.error) {
            setErrors(parsedData.error.flatten().fieldErrors);
        }

        return false;
    };

    const handleOnClick = () => {
        if (validate()) {
            if (isEphemeral) {
                retainEphemeralAccount({
                    variables: {
                        code,
                    },
                })
                    .then(() => {
                        refetchCurrentUser();
                        closeAllModals();
                        history.push('/ssl/verify-child');
                    })
                    .catch(e => {
                        logger.error(e);
                        setErrors({
                            code: e.message,
                        });
                    });
            } else {
                // handles universal code
                if (code === config?.public?.universal_access_code?.toUpperCase()) {
                    closeAllModals();
                } else {
                    // handles class codes & error handling
                    validateSignupCode({
                        variables: {
                            code,
                        },
                    });
                }
            }
        }
    };

    const handleCloseKeyBoard = (e: KeyboardEvent) => {
        triggerHideKeyboardOnKey(e, 'Enter', 13, () => {
            if (code) {
                handleOnClick();
            }
        });
    };

    let title = isVerifying
        ? 'How do you want to create an account?'
        : 'Get your guardian to Login';

    if (customTitle) {
        title = customTitle;
    }

    return (
        <div className="email-verify-container">
            <div className="email-verify-wrap">
                <div className="email-verify-title-wrap guardian-verify-title-wrap">
                    <h1 className="email-verify-title">Locked Door</h1>
                    <h2 className="email-verify-subtitle">{title}</h2>
                </div>

                <div className="email-verify-img-wrap email-verify-img-door-wrap">
                    <img src={Door} alt="door" className="email-verify-img" />
                </div>

                {showVerifyGuardianButton && (
                    <div className="email-verify-btn-wrap guardian-verify-btn-container">
                        <div className="guardian-verify-btn-wrap">
                            <div className="guardian-verify-btn-inner-wrap">
                                <button
                                    onClick={handleRedirect}
                                    className="guardian-btn-verify"
                                    type="button"
                                >
                                    {isVerifying ? 'Verify Guardian' : "That's Me!"}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isVerifying && (
                    <div className="guardian-wall-code-container">
                        <div
                            className={`guardian-wall-code-wrap ${
                                errors.code ? 'guardian-wall-code-error-wrap' : ''
                            }`}
                        >
                            <input
                                className="guardian-wall-code-input"
                                type="text"
                                placeholder="Enter Code"
                                onChange={e => setCode(e.target.value.trim().toUpperCase())}
                                onKeyDown={e => handleCloseKeyBoard(e)}
                                value={code}
                                required
                            />
                            <div className="guardian-wall-code-btn-wrap">
                                <button
                                    onClick={handleOnClick}
                                    type="button"
                                    className="guardian-wall-code-btn"
                                >
                                    <img
                                        src={Arrow}
                                        alt="right-arrow"
                                        className="guardian-wall-code-btn-icon"
                                    />
                                </button>
                            </div>
                        </div>

                        {errors.code && <p className="guardian-wall-code-error">{errors.code}</p>}
                    </div>
                )}

                {showCancelButton && (
                    <div className="email-verify-btn-wrap guardian-verify-btn-container">
                        <div className="guardian-verify-btn-wrap">
                            <button
                                className="guardian-btn-cancel"
                                type="button"
                                onClick={closeModal}
                            >
                                Cancel <StylizedX className="guardian-btn-cancel-icon" />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LegoGuardianWall;
