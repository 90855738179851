import { ContentCard } from '@shared/welibrary-graphql/types';

import { CARD_EVENT, CARD_GROUP } from '@core/utilities/constants/card_types';
import { Maybify } from '@core/types/Utilities';

export const cardIsEventListing = (card: Partial<ContentCard>) => {
    return card.type === CARD_EVENT;
};

export const cardIsEventGroup = (card: Partial<ContentCard>) => {
    return (
        card.type === CARD_GROUP &&
        card.aliases &&
        card.aliases[0]?.__typename === 'GroupAliasHandle' &&
        card?.aliases[0]?.group?.subtype === CARD_EVENT
    );
};

export const cardIsEvent = (card: Partial<ContentCard>) => {
    return cardIsEventListing(card) || cardIsEventGroup(card);
};

const sortCardsByRankOrderInPlace = (cards: Partial<ContentCard>[]) => {
    // rankOrder should be sorted in ascending/increasing order
    cards.sort((a, b) => a?.rankOrder - b?.rankOrder);
};

export const sortCardsByPinned = (cards?: Partial<ContentCard>[], pinnedCardIds?: string[]) => {
    if (!cards) {
        return [];
    }
    if (!pinnedCardIds) {
        return cards;
    }

    // Make pinned cards come before unpinned cards
    const pinnedCards = [];
    const unpinnedCards = [];
    cards.forEach(card => {
        if (pinnedCardIds.includes(card?._id)) {
            pinnedCards.push(card);
        } else {
            unpinnedCards.push(card);
        }
    });
    sortCardsByRankOrderInPlace(pinnedCards);
    const cardsSortedByPinned = [...pinnedCards, ...unpinnedCards];
    return cardsSortedByPinned;
};

export const getContentCardLabel = (card?: Maybify<ContentCard>): string => {
    return card?.title || card?.body || card?.description || '';
};
