import useFilestack from '@web/ui/components/generic/hooks/useFilestack';
import { IMAGE_MIME_TYPES } from '@core/utilities/constants/filestack.js';

import { ImageWithLoadingState, SetState } from '@core/types/Utilities';

const useUploadPhoto = (
    setPhoto: SetState<ImageWithLoadingState>,
    onUploadComplete?: (imageUrl: string) => void
) => {
    const { handleFileSelect } = useFilestack({
        fileType: IMAGE_MIME_TYPES,
        onFileSelected: photo =>
            setPhoto({ image: URL.createObjectURL(photo), loading: { type: 'resizing' } }),
        onPhotoResized: photo =>
            setPhoto({ image: URL.createObjectURL(photo), loading: { type: 'uploading' } }),
        onUpload: url => {
            setPhoto({ image: url });
            onUploadComplete?.(url);
        },
        options: {
            intelligent: true,
            onProgress: event => {
                setPhoto(photo => ({
                    image: photo.image,
                    loading: { type: 'uploading', progress: event.totalPercent },
                }));
            },
        },
        resizeBeforeUploading: false,
    });

    return handleFileSelect;
};

export default useUploadPhoto;
