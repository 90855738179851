import React from 'react';

import ActionArea from '@web/ui/components/chapter/ActionArea';
import ChapterBody from '@web/ui/components/chapter/ChapterBody.js';

const ResourceChapterBody = ({ shelf, book, chapter, initOpenPostBox }) => (
    <>
        <ActionArea
            shelf={shelf}
            book={book}
            chapter={chapter}
            initOpenPostBox={initOpenPostBox}
            isResource
            className="white-postbox"
        />
        <ChapterBody chapter={chapter} hideDelineator />
    </>
);

export default ResourceChapterBody;
