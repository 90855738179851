import React, { ChangeEvent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import DocumentItemButtons from '@components/card/mediacard/bodyitems/DocumentItemButtons';
import ImagePicker from '@components/content/ImagePicker';
import useModal from '@components/modals/hooks/useModal';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import { SetState } from '@core/types/Utilities';

type AttachmentTitleState = {
    attachedContent: {
        data: {
            url: string;
            title?: string;
            mediaThumb?: string;
        };
    };
};

type EditDocumentCardProps = {
    state: AttachmentTitleState;
    setState: SetState<AttachmentTitleState>;
};

const EditDocumentCard: React.FC<EditDocumentCardProps> = ({ state, setState }) => {
    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setState(oldState => ({
            ...oldState,
            attachedContent: {
                ...oldState.attachedContent,
                data: { ...oldState.attachedContent.data, title: e.target.value },
            },
        }));
    };

    const updateImage = (url: string) => {
        setState(oldState => ({
            ...oldState,
            attachedContent: {
                ...oldState.attachedContent,
                data: { ...oldState.attachedContent.data, mediaThumb: url },
            },
        }));
    };

    const { newModal } = useModal();
    const selectCoverImage = () => {
        newModal(<ImagePicker onImageSelect={updateImage} />, {
            className: 'image-picker-modal no-bottom-padding full-height',
        });
    };

    const imgUrl =
        state?.attachedContent?.data?.mediaThumb ||
        getRandomDefaultCoverImage(state?.attachedContent?.data?.title || undefined);

    const [src, srcSet] = getBackgroundImageSetForUrl(imgUrl);

    return (
        <>
            <div className="mc-media-item documentitem">
                <StyledBackgroundImage className="mc-media-item-bg" src={src} srcSet={srcSet} />
                <div className="blog-bg-edit-button">
                    <button
                        title="Upload Cover Image"
                        onClick={selectCoverImage}
                        type="button"
                        className="button-reset content-button-icon edit we-profile-edit w-dropdown-toggle"
                        aria-label="Upload Cover Image"
                    />
                </div>
                <div className="mc-media-item-content">
                    <div className="mc-media-content" style={{ width: '100%' }}>
                        <div className="conent-card-media-title">
                            <TextareaAutosize
                                onChange={onChange}
                                name="title"
                                value={state?.attachedContent?.data?.title ?? ''}
                                placeholder="Enter title"
                                className="inline-text-input white"
                            />
                        </div>
                        {/* <div className="mc-post-title media-title-1">{title ? title : ''}</div> */}
                        <DocumentItemButtons fileUrl={state?.attachedContent?.data?.url} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditDocumentCard;
