/* eslint-disable no-nested-ternary */
import React from 'react';

const ToggleButtonEditScreen = ({
    id,
    name,
    toggleValue,
    defaultValue,
    offOption,
    onOption,
    inverted,
    handleToggle,
}) => {
    const displayToggleValue = inverted ? !toggleValue : toggleValue || defaultValue;

    const buttonToggleTextStyle = displayToggleValue
        ? { transition: 'opacity 300ms ease 0s', opacity: 1 }
        : { transition: 'opacity 300ms ease 0s', opacity: 0 };
    const buttonToggleIconStyle = displayToggleValue
        ? {
              transformStyle: 'preserve-3d',
              transition: 'transform 300ms ease 0s',
              transform: 'translateX(68px) translateY(0px) translateZ(0px)',
          }
        : {
              transformStyle: 'preserve-3d',
              transition: 'transform 300ms ease 0s',
              transform: 'translateX(0px) translateY(0px) translateZ(0px)',
          };

    return (
        <div className="togglebutton smallertoggle w-inline-block">
            <div className="buttontoggle smaller-toggle-button" style={buttonToggleIconStyle} />
            <div className="buttontext">{offOption}</div>
            <div className="toggle-button-acive" style={buttonToggleTextStyle}>
                <div className="buttontext">{onOption}</div>
            </div>
            <label className="w-checkbox toggle-checkbox-field">
                <input
                    onClick={e => handleToggle(e, !toggleValue)}
                    type="checkbox"
                    id={id}
                    name={name}
                    data-name={name}
                    className="w-checkbox-input toggle-checkbox-field"
                    value={toggleValue || undefined}
                />
            </label>
        </div>
    );
};

export default ToggleButtonEditScreen;
