import React from 'react';

import searchStore from '@stores/Search';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import { getThumbClass } from '@components/search/searchcard/components/helpers';
import { isKey } from '@helpers/types/type.helpers';

import { CARD_TEXT } from '@core/utilities/constants/card_types.js';
import { ContentCard } from '@shared/welibrary-graphql/types';

const actionButtonIconTypes = {
    User: 'message-icon',
    Group: 'message-icon',
};

const ActionButton: React.FC<{ type?: string; handleAction: (action: string) => any }> = ({
    type,
    handleAction,
}) => {
    if (isKey(actionButtonIconTypes, type) && actionButtonIconTypes[type]) {
        const handleClick = () => {
            handleAction(type);
        };
        return (
            <button
                type="button"
                aria-label="Search action"
                onClick={handleClick}
                className={`button-reset search-result-item-action-button ${actionButtonIconTypes[type]}`}
            />
        );
    }
    return null;
};

const ThumbActionButton: React.FC<{
    title: string;
    type?: string;
    cardId: string;
    hideInsideAction: boolean;
}> = ({ title, type, cardId, hideInsideAction }) => {
    const handleClick = () => {
        searchStore.set.changeCurrentScope(type ?? '', cardId, title);
    };
    const renderActionButton = !hideInsideAction ? (
        <button
            type="button"
            aria-label="Search inside..."
            onClick={handleClick}
            className="button-reset search-result-item-action-button search-inside-icon"
        />
    ) : null;
    return renderActionButton;
};

const CollectionThumb: React.FC<{ thumbImage: string; randomImageSeed: string }> = ({
    thumbImage,
    randomImageSeed,
}) => {
    const [src, srcSet] = getBackgroundImageSetForUrl(
        thumbImage || getRandomDefaultCoverImage(randomImageSeed || 'sdf')
    );
    return (
        <button type="button" className="button-reset">
            <StyledBackgroundImage className="search-result-item-thumb" src={src} srcSet={srcSet} />
        </button>
    );
};

const SearchCardHeaderThumb: React.FC<{
    content: ContentCard;
    onClick: () => void;
}> = ({ content, onClick }) => {
    if (content?.type === CARD_TEXT) {
        const chapterSource = content?.reference?.chapterSource;
        const chapterIcon = chapterSource?.media?.icon;
        const chapterCover = chapterSource?.media?.thumb;
        const thumbImage = chapterIcon || chapterCover;
        const seed = content?.title || content._id;
        const [src, srcSet] = getBackgroundImageSetForUrl(
            thumbImage || getRandomDefaultCoverImage(seed || 's')
        );
        return (
            <button onClick={onClick} type="button" className="button-reset">
                <StyledBackgroundImage
                    className="search-result-item-thumb"
                    src={src}
                    srcSet={srcSet}
                />
            </button>
        );
    }
    const thumbClass = getThumbClass(content?.type);

    return <span onClick={onClick} className={thumbClass} />;
};

const MessageAuthorThumb: React.FC<{ thumbImage?: string | null }> = ({ thumbImage }) => {
    const [src, srcSet] = getBackgroundImageSetForUrl(thumbImage || getRandomDefaultUserImage());
    return (
        <button type="button" className="button-reset">
            <StyledBackgroundImage className="search-result-item-thumb" src={src} srcSet={srcSet} />
        </button>
    );
};

export {
    MessageAuthorThumb,
    ActionButton,
    SearchCardHeaderThumb,
    CollectionThumb,
    ThumbActionButton,
};
