import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

type TextInputProps = {
    size?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    light?: boolean;
    customStyle?: object;
    showTooltip?: boolean;
    toolTipPos?: string;
    toolTipText?: string;
};
/* https://www.figma.com/file/7tzPSVPHUus4QFAsM4xbcm/Group-Page?node-id=244%3A1429 */
const ButtonCircleEdit: React.FC<TextInputProps> = ({
    size = '40px',
    onClick,
    customStyle = {},
    light = false,
    toolTipPos = 'top',
    toolTipText = '',
}) => {
    const style = { width: size, height: size, ...customStyle };
    const className = light
        ? 'edit-pencil-button animate-grow'
        : 'edit-pencil-button dark-pencil animate-grow';
    return (
        <Tooltip placement={toolTipPos} title={toolTipText}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button style={style} type="button" onClick={onClick} className={className} />
        </Tooltip>
    );
};

export default ButtonCircleEdit;
