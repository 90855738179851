import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@stores/User';

import useModal from '@components/modals/hooks/useModal';
import { useToastActionsContext } from '@components/toast/NewToastContext';
import { defaultAlertToastOptions } from '@core/types/Toast';
import { useGroupMembershipQuery } from '@shared/welibrary-graphql/user/queries.hook';

import AlertToast from '@components/toast/AlertToast';
import TextArea from '@web/ui/components/generic/forms/TextArea';
import Select from '@dsc/forms/customInputs/Select';

import { DELETE_GROUP, UNPUBLISH_EVENT } from '@shared/welibrary-graphql/user/mutations';

import getLogger from '@core/logger';
import { Group } from '@shared/welibrary-graphql/types';

const logger = getLogger(module);

const CANCEL_REASONS = {
    weather: 'Inclement Weather',
    attendance: 'Low Attendance',
    unforeseenCircumstances: 'Unforeseen Circumstance',
    venue: 'Venue Complications',
    other: 'Other (see below)',
} as const;

type CancelEventModalProps = {
    group: Group;
    type: 'unpublish' | 'delete';
};

const CancelEventModal: React.FC<CancelEventModalProps> = ({ group, type }) => {
    const history = useHistory();
    const client = useApolloClient();

    const { t } = useTranslation();
    const { closeModal } = useModal();
    const { newToast } = useToastActionsContext();
    const { currentUser } = useCurrentUser();

    const [cancelReason, setCancelReason] = useState('');
    const [messageToAttendees, setMessageToAttendees] = useState('');
    const [contactUserId, setContactUserId] = useState(currentUser?._id);
    const [loading, setLoading] = useState(false);

    const [unpublishEvent] = useMutation(UNPUBLISH_EVENT);
    const [deleteGroup] = useMutation(DELETE_GROUP);

    const groupId = group?._id;
    const groupName = group?.profile?.full_name;

    const { data: membershipData } = useGroupMembershipQuery({
        variables: { _id: groupId, ownersLimit: 50, membersLimit: 0 },
        fetchPolicy: 'cache-and-network',
    });
    const ownerNames = membershipData?.groupById.owners.results.reduce((acc, owner) => {
        acc[owner._id] = owner.profile.full_name;
        return acc;
    }, {});

    const isUnpublish = type === 'unpublish';

    const handleUnpublishEvent = () => {
        setLoading(true);
        unpublishEvent({
            variables: {
                _id: groupId,
                cancelReason,
                messageToAttendees,
                contactUserId,
            },
        })
            .then(() => {
                newToast(
                    <AlertToast
                        boldText={`${groupName} successfully unpublished.`}
                        showSuccessIcon
                    />,
                    {
                        ...defaultAlertToastOptions,
                    }
                );
            })
            .catch(err => {
                newToast(<AlertToast boldText={err?.message} showWarningIcon />, {
                    ...defaultAlertToastOptions,
                });
            })
            .finally(closeModal);
    };

    const handleDeleteEvent = () => {
        setLoading(true);
        deleteGroup({
            variables: {
                _id: groupId,
                cancelReason,
                messageToAttendees,
            },
        })
            .then(() => {
                newToast(
                    <AlertToast boldText={`${groupName} successfully deleted.`} showSuccessIcon />,
                    {
                        ...defaultAlertToastOptions,
                    }
                );
                history.push('/home');
                client.resetStore().catch(err => {
                    logger.error(err);
                    history.push('/home');
                });
            })
            .catch(err => {
                newToast(<AlertToast boldText={err?.message} showWarningIcon />, {
                    ...defaultAlertToastOptions,
                });
            })
            .finally(closeModal);
    };

    const detailsText = isUnpublish
        ? t(
            'common:imports.wlWeb.ui.components.group.unpublish_confirmation2',
            'Unpublishing this event will make it visible only to the host(s). Refunds will be issued if any tickets have been sold and a cancellation email will be sent to attendees.'
        )
        : "Deleting this event will delete also delete it's content. This cannot be undone. Refunds will be issued if any tickets have been sold and a cancellation email will be sent to attendees.";
    // t(
    //     'common:imports.wlWeb.ui.components.group.delete_confirmation',
    //     'Deleting this group will delete all content owned and created by this group. This cannot be undone. If tickets have been sold, refunds will be issued and attendees will receive any details you choose to include below.'
    // );

    const isOther = cancelReason === CANCEL_REASONS.other;
    const disableActionButton = loading || !cancelReason || (isOther && !messageToAttendees);

    return (
        <div className="pt-[20px] md:pb-[20px] px-[24px] md:px-[45px] flex flex-col gap-[25px] md:gap-[30px]">
            <div className="flex flex-col gap-[15px]">
                <h1 className="font-poppins text-[24px] md:text-[32px] font-[700] text-center my-0 text-grayscale-title-active">
                    {t(`common:global:are_you_sure`, 'Are you sure?')}
                </h1>
                <p className="my-0 text-center font-poppins text-[17px] leading-[24px] tracking-[0.75px] text-grayscale-body">
                    {detailsText}
                </p>
            </div>
            <div className="flex flex-col gap-[15px]">
                <Select
                    className="flex flex-col w-full !items-baseline !my-0"
                    titleClassName="text-grayscale-label font-poppins !text-[17px] leading-[28px] tracking-[0.75px] !mb-[7px]"
                    inputClassName="!w-full !mb-0"
                    title={`${t(`common:global.point_of_contact`, 'Point of Contact')} *`}
                    value={contactUserId}
                    options={ownerNames ? Object.keys(ownerNames) : [currentUser.profile.full_name]}
                    displayValues={
                        ownerNames || {
                            [currentUser._id]: currentUser.profile.full_name,
                            [currentUser.profile.full_name]: currentUser.profile.full_name,
                        }
                    }
                    onChange={setContactUserId}
                />

                <Select
                    className="flex flex-col w-full !items-baseline !my-0"
                    titleClassName="text-grayscale-label font-poppins !text-[17px] leading-[28px] tracking-[0.75px] !mb-[7px]"
                    inputClassName="!w-full !mb-0"
                    title={`${t(`common:global.reason_for_Cancelling`, 'Reason for Cancelling')} *`}
                    prompt={t(
                        `common:global.this_event_was_cancelled_due_to`,
                        'This event was cancelled due to...'
                    )}
                    value={cancelReason}
                    onChange={setCancelReason}
                    options={Object.values(CANCEL_REASONS)}
                />
                <TextArea
                    title={`${t(`common:global.Message_to_Attendees`, 'Message to Attendees')}${isOther ? ' *' : ''
                        }`}
                    value={messageToAttendees}
                    onInput={setMessageToAttendees}
                    maxCharacter={250}
                    defaultClasses=""
                    defaultAutoResizeClasses=""
                    className="!min-h-[128px] bg-grayscale-input-background rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px]"
                    wrapperClassName="relative w-full"
                    countClassName="absolute bottom-[10px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px]"
                    titleClassName="text-grayscale-label font-poppins !text-[17px] leading-[28px] tracking-[0.75px]"
                    autoResize
                    required
                    placeholder={`${t(
                        `common:global.message_to_attendees`,
                        'Message to attendees'
                    )}...`}
                />
            </div>
            <div className="flex flex-col gap-[15px] items-center">
                <button
                    className={`w-full bg-error-default rounded-full text-grayscale-background font-poppins text-[16px] font-[600] leading-[175%] tracking-[0.75px] py-[13px] disabled:opacity-60`}
                    disabled={disableActionButton}
                    onClick={isUnpublish ? handleUnpublishEvent : handleDeleteEvent}
                >
                    {isUnpublish
                        ? t(`common:global.unpublish`, 'Unpublish')
                        : t(`common:global.delete`, 'Delete')}
                </button>
                <button
                    onClick={closeModal}
                    className="text-grayscale-label font-poppins text-[16px] font-[600] leading-[175%] tracking-[0.75px]"
                >
                    {t(`common:global.cancel`, 'Cancel')}
                </button>
            </div>
        </div>
    );
};

export default CancelEventModal;
