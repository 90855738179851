import React from 'react';

type ChunkyArrowBendDownRightProps = {
    className?: string;
};

const ChunkyArrowBendDownRight: React.FC<ChunkyArrowBendDownRightProps> = ({ className = '' }) => {
    return (
        <svg
            width="11"
            height="10"
            className={className}
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.65433 9.98095C7.60865 9.96203 7.56961 9.92999 7.54214 9.88888C7.51467 9.84777 7.5 9.79944 7.5 9.74999V8.49999H2C1.86744 8.49984 1.74035 8.44712 1.64661 8.35338C1.55288 8.25965 1.50015 8.13256 1.5 7.99999V5.49999C1.50015 5.36743 1.55288 5.24034 1.64661 5.14661C1.74035 5.05287 1.86744 5.00015 2 4.99999H7.5V3.74999C7.50001 3.70055 7.51467 3.65222 7.54214 3.61111C7.56961 3.57 7.60866 3.53796 7.65434 3.51903C7.70002 3.50011 7.75028 3.49516 7.79878 3.5048C7.84727 3.51444 7.89182 3.53825 7.92679 3.57321L10.9268 6.57321C10.9737 6.6201 11 6.68369 11 6.74999C11 6.8163 10.9737 6.87989 10.9268 6.92678L7.92679 9.92678C7.89182 9.96174 7.84727 9.98554 7.79877 9.99518C7.75028 10.0048 7.70001 9.99987 7.65433 9.98095Z"
                fill="black"
            />
            <path
                d="M0 1C0 0.447715 0.447715 0 1 0H2.5C3.05228 0 3.5 0.447715 3.5 1V8.5H1C0.447715 8.5 0 8.05228 0 7.5V1Z"
                fill="black"
            />
        </svg>
    );
};

export default ChunkyArrowBendDownRight;
