import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider, MouseTransition, TouchTransition } from 'react-dnd-multi-backend';

const customBackend = {
    backends: [
        { id: 'html5', backend: HTML5Backend, transition: MouseTransition },
        {
            id: 'touch',
            backend: TouchBackend,
            options: { enableMouseEvents: true },
            preview: true,
            transition: TouchTransition,
        },
    ],
};

/** @type React.FC */
const CustomDndProvider = ({ children }) => (
    <DndProvider options={customBackend}>{children}</DndProvider>
);

export default CustomDndProvider;
