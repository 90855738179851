import React from 'react';

import VisaLogo from '@assets/visa-logo.svg';
import DiscoverLogo from '@assets/discover-logo.svg';
import AmexLogo from '@assets/amex-logo.svg';
import MasterCardlogo from '@assets/master-card-logo.svg';
import DinersClubLogo from '@assets/diners-club-logo.svg';
import JCBLogo from '@assets/jcb-logo.svg';
import UnionPayLogo from '@assets/union-pay-logo.svg';

import { CardBrandingTypes } from '@components/group/groupdashboard/groupSubscriptionPaywall/groupSubscriptionPaywallHelpers';

const CardBranding: React.FC<{ branding: string; customClassName?: string }> = ({
    branding,
    customClassName = '',
}) => {
    switch (branding) {
        case CardBrandingTypes.VISA:
            return (
                <img
                    src={VisaLogo}
                    className={`card-branding ${customClassName}`}
                    alt="visa-logo"
                />
            );
        case CardBrandingTypes.MASTERCARD:
            return (
                <img
                    src={MasterCardlogo}
                    className={`card-branding ${customClassName}`}
                    alt="mastercard-logo"
                />
            );
        case CardBrandingTypes.DISCOVER:
            return (
                <img
                    src={DiscoverLogo}
                    className={`card-branding ${customClassName}`}
                    alt="discover-logo"
                />
            );
        case CardBrandingTypes.AMERICAN_EXPRESS:
            return (
                <img
                    src={AmexLogo}
                    className={`card-branding ${customClassName}`}
                    alt="amex-logo"
                />
            );
        case CardBrandingTypes.DINERS_CLUB:
            return (
                <img
                    src={DinersClubLogo}
                    className={`card-branding ${customClassName}`}
                    alt="diners-club-logo"
                />
            );
        case CardBrandingTypes.JCB:
            return (
                <img src={JCBLogo} className={`card-branding ${customClassName}`} alt="jcb-logo" />
            );
        case CardBrandingTypes.UNION_PAY:
            return (
                <img
                    src={UnionPayLogo}
                    className={`card-branding ${customClassName}`}
                    alt="union-pay-logo"
                />
            );
        default:
            return null;
    }
};

export default CardBranding;
