import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCurrentUser } from '@stores/User';
import { isEventInThePast } from '@helpers/events/event.helpers';

import GroupItemButton from '@components/card/mediacard/bodyitems/GroupItemButton';

import { Group } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

type EventGroupCardButtonsProps = {
    group?: Maybify<Group> | null;
    className?: string;
};

const EventGroupCardButtons: React.FC<EventGroupCardButtonsProps> = ({ group, className = '' }) => {
    const { t } = useTranslation();
      const { currentUser } = useCurrentUser();

    const eventIsOver = isEventInThePast(group);

    return (
        <section className={`event-action-buttons ${className}`}>
            <Link to={`/g/${group?._id}`} className="event-details-button button">
                {t('common:details', 'Details')}
            </Link>
            {!eventIsOver && <GroupItemButton groupDashboardData={group} user={currentUser} />}
        </section>
    );
};

export default EventGroupCardButtons;
