import React from 'react';
import { useTranslation } from 'react-i18next';

import Arrow from '@dsc/svgs/Arrow';
import TrashBin from '@dsc/svgs/Trash';
import Checkmark from '@dsc/svgs/Checkmark';

export const EditCouponFormHeader: React.FC<{
    index: number;
    cancel: () => void;
    handleOnClick: () => void;
    deleteCoupon: (index: number) => void;
}> = ({ index, cancel, handleOnClick, deleteCoupon }) => {
    const { t } = useTranslation();

    return (
        <header className="ticket-full-view-header ticket-full-view-edit-header">
            <button onClick={cancel} type="button" className="ticket-full-view-header-cancel">
                {t('common:cancel', 'Cancel')}
            </button>
            <div className="ticket-full-view-header-btn-wrap">
                <button
                    onClick={() => deleteCoupon(index)}
                    type="button"
                    className="ticket-full-view-header-delete"
                >
                    <TrashBin />
                </button>
                <button
                    onClick={handleOnClick}
                    type="button"
                    className="ticket-full-view-header-add"
                >
                    <Checkmark />
                </button>
            </div>
        </header>
    );
};

export const NewCouponFormHeader: React.FC<{
    cancel: () => void;
    handleOnClick: () => void;
}> = ({ cancel, handleOnClick }) => {
    const { t } = useTranslation();

    return (
        <header className="ticket-full-view-header">
            <div className="ticket-full-view-text-wrap">
                <h2>{t('common:new_discount_code', 'New Discount Code')}</h2>
                <p>{`${t('common:required_fields', 'Required fields')}*`}</p>
            </div>
            <div className="ticket-full-view-header-btn-wrap">
                <button onClick={cancel} type="button" className="ticket-full-view-header-back">
                    <Arrow />
                </button>
                <button
                    onClick={handleOnClick}
                    type="button"
                    className="ticket-full-view-header-add"
                >
                    {t('common:add', 'Add')}
                </button>
            </div>
        </header>
    );
};
