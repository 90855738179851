import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';

import {useAddPledgeMutation} from '@shared/welibrary-graphql/content_card/mutations.hook';

import CenterModalHeaderFooter from '@web/ui/components/modals/CenterModalHeaderFooter';
import TextArea from '@web/ui/components/generic/forms/TextArea';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.card.pledgeCard';

type PledgeNoteProps = {
    url: string;
    pledgeId: string;
};

const PledgeNote: React.FC<PledgeNoteProps> = ({ url, pledgeId  }) => {
    const { t } = useTranslation();

      const { currentUser } = useCurrentUser();
    const { closeModal } = useModal();

    const [note, setNote] = useState('');

    const [addPledge] = useAddPledgeMutation();

    const submit = () => {
        const group = currentUser?.activeGroup?._id;

        addPledge({ variables: { input: { url, pledgeId, group, supplement: { note } } } })
            .then(() => {
                closeModal();
            })
            .catch(e => logger.error(e.message));
    };

    return (
        <CenterModalHeaderFooter
            title={t(`common:${localNamespace}.note_form.take_note`)}
            buttonText={t(`common:${localNamespace}.verification_submit`)}
            onClick={submit}
            buttonDisabled={note.length === 0}
            className="pledge-note"
        >
            <TextArea
                handleChange={e => setNote(e.target.value)}
                maxCharacter={2000}
                value={note}
                placeholder={t(`common:${localNamespace}.note_form.placeholder`)}
                defaultClasses="pledge-form-textarea"
                minHeight="130px"
            />
        </CenterModalHeaderFooter>
    );
};

export default PledgeNote;
