import React from 'react';
import { useMutation } from '@apollo/client';

import { awsMetricPublisher } from '@welibraryos/metrics';

import { ADD_CARD_VOTE } from '@shared/welibrary-graphql/content_card/vote/mutations';

import EngagementButton from '@web/ui/components/card/modules/engagement/EngagementButton';

import { ENGAGE_HEART } from '@core/utilities/constants/engagement_types';
import { VOTING_SENTIMENT } from '@core/utilities/constants/voting_types';

const LikeButton = ({ card, handleToggle, iconText }) => {
    const [addVote] = useMutation(ADD_CARD_VOTE);

    const addLike = () => {
        if (card) {
            const {
                ratings_sentiment_score,
                loggedInUserVotes: { sentiment },
            } = card;

            // Calculate the optimistic sentiment score
            const sentimentScore =
                sentiment === null ? ratings_sentiment_score + 1 : ratings_sentiment_score - 1;

            // Determine if the optimistic vote sentiment is positive (true) or negative (null)
            const voteSentiment = sentiment === null ? true : null;

            if (voteSentiment) {
                awsMetricPublisher.publishCount('content-card-engage', 1, [
                    { Name: 'menu', Value: 'like' },
                ]);
            } else {
                awsMetricPublisher.publishCount('content-card-engage', 1, [
                    { Name: 'menu', Value: 'unlike' },
                ]);
            }

            // The optimistic response matches the response, except, adds the optimistic score and sentiment
            // If the request fails, the original values are returned and the component updates
            addVote({
                variables: { cardId: card._id, type: VOTING_SENTIMENT, positive: true },
                optimisticResponse: {
                    addCardVote: {
                        _id: card._id,
                        type: card.type,
                        ratings_sentiment_score: sentimentScore,
                        ratings_quality_score: 0,
                        ratings_relevance_score: [],
                        ratings_rank: card.ratings_rank,
                        __typename: 'ContentCard',
                        loggedInUserVotes: {
                            sentiment: voteSentiment,
                            quality: null,
                            relevance: null,
                            __typename: 'UserVotes',
                        },
                    },
                },
            });
        }
    };

    return (
        <EngagementButton
            handleToggleDrawer={handleToggle}
            iconText={card?.ratings_sentiment_score || iconText}
            type={ENGAGE_HEART}
            iconClass={ENGAGE_HEART}
            addClasses={`${card?.loggedInUserVotes?.sentiment ? 'active-like' : ''}`}
            isCallout={false}
            onClick={addLike}
        />
    );
};

export default LikeButton;
