import React, { useState } from 'react';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';
import Toggle from '@dsc/forms/customInputs/Toggle';

import { useAddCardToCustomerAccountMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { GET_LOGGEDIN_CUSTOMER_CARDS } from '@shared/welibrary-graphql/user/queries';

import getLogger from '@core/logger';

const logger = getLogger(module);

const AddPaymentForm: React.FC<{ cancel: () => void }> = ({ cancel }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [addCardSource, { error, loading }] = useAddCardToCustomerAccountMutation();

    // const [isDefault, setIsDefault] = useState<boolean>(false);
    const [cardErr, setCardErr] = useState<any>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isDefault, setIsDefault] = useState<boolean>(false);

    // @ts-ignore
    const handleSaveCardPayment = async e => {
        setIsProcessing(true);
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // @ts-ignore
        const payload = await stripe.createToken(elements.getElement(CardNumberElement), {});

        if (payload.error) {
            setIsProcessing(false);
            setCardErr(payload.error);
        } else {
            // save customer payment method card
            addCardSource({
                variables: {
                    token: payload?.token?.id,
                    setAsDefault: isDefault,
                },
                refetchQueries: [GET_LOGGEDIN_CUSTOMER_CARDS],
            })
                .then(() => {
                    setIsProcessing(false);
                    cancel();
                })
                .catch(err => logger.error(err));
        }
    };

    const cardStyle = {
        style: {
            base: {
                color: '#333',
                backgroundColor: '#F8F8F8',
                fontFamily: 'Roboto, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#adadad',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    const CARD_NUMBER_OPTIONS = {
        placeholder: 'Card Number',
        showIcon: true,
        iconStyle: 'solid',
        ...cardStyle,
    };
    const CARD_EXPIRY_OPTIONS = { placeholder: 'MM/YY', ...cardStyle };

    return (
        <div className="manage-subs-payment-container">
            <div className="manage-subs-payment-wrap">
                <div className="customer-payments-container">
                    <div className="stripe-checkout-form">
                        {cardErr && (
                            <div className="customer-card-error-wrap">
                                <p className="customer-card-error-text">x {cardErr.message}</p>
                            </div>
                        )}
                        {error && (
                            <div className="customer-card-error-wrap">
                                <p className="customer-card-error-text">x {error.message}</p>
                            </div>
                        )}
                        <div className="stripe-checkout-form-element-wrap">
                            <CardNumberElement
                                className="stripe-checkout-form-element"
                                onFocus={() => setCardErr(null)}
                                onChange={event => {
                                    setCardErr(event.error);
                                }}
                                options={CARD_NUMBER_OPTIONS}
                            />
                        </div>
                        <div className="stripe-checkout-form-elements">
                            <div className="stripe-checkout-form-element-wrap">
                                <CardExpiryElement
                                    className="stripe-checkout-form-element"
                                    onFocus={() => setCardErr(null)}
                                    onChange={event => {
                                        setCardErr(event.error);
                                    }}
                                    options={CARD_EXPIRY_OPTIONS}
                                />
                            </div>
                            <div className="stripe-checkout-form-element-wrap">
                                <CardCvcElement
                                    className="stripe-checkout-form-element"
                                    options={cardStyle}
                                    onFocus={() => setCardErr(null)}
                                    onChange={event => {
                                        setCardErr(event.error);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="stripe-checkout-form-element-wrap stripe-checkout-form-set-default">
                            <p>Set as Default</p>
                            <Toggle value={isDefault} onChange={() => setIsDefault(!isDefault)} />
                        </div>
                    </div>
                    <footer className="manage-subs-payment-btns-wrap manage-subs-card-form-footer">
                        <button
                            disabled={isProcessing || loading}
                            type="button"
                            onClick={handleSaveCardPayment}
                        >
                            {isProcessing || loading ? 'Saving Card...' : 'Save Card'}
                        </button>
                        <button
                            onClick={cancel}
                            type="button"
                            className="manage-subs-payment-btn-cancel"
                        >
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default AddPaymentForm;
