import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';
import {usePreview} from 'react-dnd-multi-backend';

import PromptItem from '@web/ui/components/content/newpost/forms/Postbox/PromptItem';

/**
 * Takes a transform string and removes the X value from it.
 * E.g. translate(10px, 20px) becomes translate(0px, 20px)
 *
 * @param {string} transformString
 * @return {string}
 */
const stripXFromTransform = transformString => transformString.replace(/\d*\.?\d*px/, '0px');

const DragPreview = () => {
    const { display, style, item, ref } = usePreview();

    useEffect(() => {
        requestAnimationFrame(() => {
            if (ref?.current?.style && style) {
                ref.current.style.transform = stripXFromTransform(style.transform);
                ref.current.style.WebkitTransform = stripXFromTransform(style.WebkitTransform);
            }
        });
    }, [ref?.current, style?.transform, style?.WebkitTransform]);

    if (!display) return <></>;

    return createPortal(
        <PromptItem
            draggable
            deletePrompt
            prompt={item?.prompt}
            ref={ref}
            className="drag-preview"
        />,
        document.getElementById('modal-super-root')
    );
};

export default DragPreview;
