import React from 'react';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import { SpeakerStateType } from '@core/types/EventGroup';

type SpeakerDisplayInfoProps = {
    speaker: SpeakerStateType;
    onClick?: (user: SpeakerStateType) => void;
    handleEdit?: (user: SpeakerStateType) => void;
};

const SpeakerDisplayInfo: React.FC<SpeakerDisplayInfoProps> = ({
    speaker,
    onClick,
    handleEdit,
}) => {
    const [src, srcSet] = getBackgroundImageSetForUrl(
        speaker?.profileThumb?.image || getRandomDefaultUserImage()
    );

    return (
        <div className="speaker-display-pill">
            <div className="left-side">
                <StyledBackgroundImage
                    className="thumbnail speaker-pill-thumb"
                    style={{ backgroundSize: 'cover' }}
                    src={src}
                    srcSet={srcSet}
                />

                <p>{speaker.name} </p>
            </div>
            <div className="right-side">
                {/* 
                 These are hidden for now...
                 <button
                    aria-label="Edit"
                    type="button"
                    onClick={() => handleEdit?.(speaker)}
                    className="action-icon content-button-icon edit animate-grow"
                />
                <button
                    type="button"
                    aria-label="Edit Speaker"
                    onClick={() => onClick?.(speaker)}
                    className="action-icon circle-icon-button X2 animate-grow"
                /> */}
            </div>
        </div>
    );
};

export default SpeakerDisplayInfo;
