import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Badge } from '@capawesome/capacitor-badge';

import { useToastActionsContext } from '@components/toast/NewToastContext';

import AlertToast from '@components/toast/AlertToast';
import { defaultAlertToastOptions } from '@core/types/Toast';

import { GET_NOTIFICATIONS_FOR_LOGGEDIN_USER } from '@shared/welibrary-graphql/notifications/queries';
import { GET_USER_MESSAGE_THREADS } from '@shared/welibrary-graphql/messaging/queries';
import { SYNC_PUSH_TOKEN } from '@shared/welibrary-graphql/pushnotifications/mutations';
import { MARK_NOTIFICATIONS_READ } from '@shared/welibrary-graphql/notifications/mutations';

import { ctaURItoData } from '@core/utilities/constants/notifications';
import { getRootUrl } from '@core/utilities/whitelabel_helpers';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.toast';

const logPrefix = '[Push Utilities]';

const pushNotificationsSupported = () => {
    return Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('PushNotifications');
};

const badgeSupported = async () => {
    const result = await Badge.isSupported();
    return Capacitor.isNativePlatform() && result.isSupported;
};

const DEFAULT_PERMISSION_STATE = 'NOT_SUPPORTED';

const pushUtilities = {
    // Helper function to generically sync push tokens, both native and desktop versions.
    syncPushTokens: client => {
        return Capacitor.isNativePlatform()
            ? pushUtilities.syncPushToken(client)
            : pushUtilities.syncDesktopPushToken(client);
    },
    /**
     * Get the current state of the notification permission.
     *
     * Returns:
     *  - NOT_SUPPORTED
     *  - PENDING
     *  - DENIED
     *  - GRANTED
     */
    pushNotificationPermissionState: async () => {
        logger.debug(`${logPrefix} pushNotificationPermissionState()`);
        try {
            if (pushNotificationsSupported()) {
                /**
                 *  Check the current permission state
                 *
                 * Note: As Taylor pointed out, the PushNotifications.checkPermissions() does NOT work for
                 * Android. So we had to install the local-notifications API and use its checkPermissions()
                 * method.
                 */
                if (Capacitor.isPluginAvailable('LocalNotifications')) {
                    const result = await LocalNotifications.checkPermissions();
                    logger.debug(
                        `${logPrefix} LocalNotifications.checkPermissions() -> ${JSON.stringify(
                            result
                        )}`
                    );
                    if (result?.display) {
                        switch (result.display) {
                            case 'prompt':
                            // Intentional Fall-Through
                            case 'prompt-with-rationale':
                                // The user has not be prompted for the permission yet
                                return 'PENDING';

                            case 'denied':
                                return 'DENIED';

                            case 'granted':
                                return 'GRANTED';

                            default:
                                logger.error(
                                    `${logPrefix} Unexpected PushNotifications.checkPermissions() result: '${JSON.stringify(
                                        result
                                    )}'! Defaulting to '${DEFAULT_PERMISSION_STATE}'`
                                );
                                return DEFAULT_PERMISSION_STATE;
                        }
                    }
                } else {
                    logger.info(
                        `${logPrefix} Defaulting to ${DEFAULT_PERMISSION_STATE} due to the missing LocalNotifications plugin.`
                    );
                }
            } else {
                logger.debug(`${logPrefix} Push notifications are NOT supported`);
            }
        } catch (err) {
            logger.error(
                `${logPrefix} Issue checking notification permission state. Defaulting to ${DEFAULT_PERMISSION_STATE}.`,
                err
            );
        }

        // Return the default state
        return DEFAULT_PERMISSION_STATE;
    },
    showEnableToastNotification: async () => {
        try {
            /**
             * In an effort to make this backwards compatible, do NOT allow this to happen if any of the following plugins are NOT installed:
             * - @capacitor/local-notifications
             * - capacitor-native-settings
             */
            if (!Capacitor.isPluginAvailable('LocalNotificationss')) {
                // The LocalNotifications is not availabe, so do NOT show this...
                logger.info(
                    `${logPrefix} The EnableToastNotification is NOT enabled due to the missing LocalNotifications plugin.`
                );
                return false;
            }
            if (!Capacitor.isPluginAvailable('NativeSettings')) {
                // The LocalNotifications is not availabe, so do NOT show this...
                logger.info(
                    `${logPrefix} The EnableToastNotification is NOT enabled due to the missing NativeSettings plugin.`
                );
                return false;
            }
            // Check the state
            const state = await pushUtilities.pushNotificationPermissionState();
            logger.debug(
                `${logPrefix} pushUtilities.pushNotificationPermissionState() result: '${state}'`
            );

            // See if we want to show based on the state
            switch (state || 'UNKNOWN') {
                case 'DENIED':
                    // We want to show this!
                    return true;

                case 'PENDING':
                    // Request the permission
                    return (await PushNotifications.requestPermissions().receive) === 'granted';

                default:
                    // We do NOT want to show this!
                    return false;
            }
        } catch (err) {
            logger.error(`${logPrefix}  Issue checking push notification permission state!`, err);

            // Default to being disabled
            return false;
        }
    },
    syncPushToken: async client => {
        logger.debug(`${logPrefix} Syncing Native Push Token (client exists: ${!!client})`);

        if (pushNotificationsSupported()) {
            try {
                const result = await PushNotifications.requestPermissions();

                if (result.receive === 'granted') {
                    await PushNotifications.register();
                    logger.debug(`${logPrefix} Push messaging is allowed!`);
                } else {
                    logger.error(`${logPrefix} Permissions denied for Firebase messaging`);
                }
            } catch (err) {
                logger.error(`${logPrefix} Request Permission Failed`, err);
            }
        }
    },
    syncDesktopPushToken: client => {
        logger.debug(`${logPrefix} Syncing Desktop Push Token (client exists: ${!!client})`);

        if (!Capacitor.isNativePlatform()) {
            try {
                const messaging = firebase.messaging();
                messaging
                    .requestPermission()
                    .then(() => {
                        logger.debug(`${logPrefix} Notification permission granted.`);
                        // get the token in the form of promise
                        return messaging.getToken();
                    })
                    .then(token => {
                        logger.debug(`${logPrefix} Syncing token`, token);

                        if (client) {
                            client.mutate({
                                mutation: SYNC_PUSH_TOKEN,
                                variables: {
                                    token,
                                    host: getRootUrl(),
                                },
                            });
                        } else {
                            logger.error(`${logPrefix} - No Client Passed to Token Sync`);
                        }
                    })
                    .catch(err => {
                        if (err.message.includes('messaging/permission-blocked')) {
                            logger.debug(`${logPrefix} Unable to get permission to notify.`, err);
                        } else {
                            logger.error(`${logPrefix} Unable to get permission to notify.`, err);
                        }
                    });
            } catch (err) {
                logger.error(err);
            }
        }
    },
    // Returns listener removal function, if applicable
    addBackgroundPushNotificationListeners: async (
        history,
        client,
        syncToken,
        handleNotificationRegistrationError
    ) => {
        logger.debug(`${logPrefix} Initializing Background Handlers ${history && 'with History'}`);
        if (pushNotificationsSupported()) {
            logger.debug(`${logPrefix} Removing background listeners first...`);
            await PushNotifications.removeAllListeners();

            await PushNotifications.addListener('registration', rawToken => {
                logger.debug(`${logPrefix} Get device RAWtoken:`, rawToken.value);

                // Get FCM token instead the APN one returned by Capacitor
                FCM.getToken()
                    .then(({ token }) => {
                        logger.debug(`${logPrefix} Get device FCMtoken:`, token);

                        if (client) {
                            client.mutate({
                                mutation: SYNC_PUSH_TOKEN,
                                variables: {
                                    token:
                                        Capacitor?.getPlatform() === 'android'
                                            ? rawToken?.value // use raw token for android
                                            : token,
                                    host: getRootUrl(),
                                },
                                onCompleted: data => data,
                                onError: error => {
                                    throw new Error(error.message);
                                },
                            });

                            return token;
                        }
                        throw new Error(`${logPrefix} - No Client Passed to Token Sync`);
                    })
                    .catch(err => logger.error(`Token error: ${err}`));
            });

            PushNotifications.addListener('registrationError', error => {
                handleNotificationRegistrationError(JSON.stringify(error));
            });

            PushNotifications.addListener('pushNotificationActionPerformed', payload => {
                logger.debug(
                    `${logPrefix} New background FCM message: ${JSON.stringify(payload, null, 2)}`
                );
                const { id, link } = payload.notification.data;

                pushUtilities.incrementBadge();
                pushUtilities.markNotificationRead(id, client);

                const parsedData = ctaURItoData(link);

                if (parsedData?.keyword === 'appendQueryParam') {
                    pushUtilities.appendQueryParam(parsedData, history);
                } else {
                    const newLocation = parsedData?.link || '/notifications';

                    if (history) {
                        history.push(newLocation);
                    } else {
                        window.location = newLocation;
                    }
                }
            });

            PushNotifications.addListener('pushNotificationReceived', payload => {
                logger.debug(`${logPrefix} New foreground FCM message: ${JSON.stringify(payload)}`);
                pushUtilities.refetchUserNotifications(client);
                pushUtilities.incrementBadge();
            });

            // TODO - How to handle token refresh with @capacitor/push-notifications?
            // TODO - Does pushNotificationReceived handle foreground + background messages?

            // What is the difference between FCM messages and data messages?
            // FCM => Firebase Console Messaging. Both FCM APIs (data - client) and the FCM console (Notification - OS)
            //        are used

            // cordova.plugins.firebase.messaging.onTokenRefresh(() => {
            //     logger.debug(`${logPrefix} Device token updated`);
            //     pushUtilities.syncPushToken(client);
            // });
        }

        // Sync desktop or native push tokens
        if (syncToken) {
            logger.debug(`${logPrefix} Syncing Push Token.`);
            pushUtilities.syncPushTokens(client);
        }
    },
    initializeDesktopBackgroundHandlers: () => {
        if (!Capacitor.isNativePlatform()) {
            logger.debug(`${logPrefix} Registering desktop onMessage`);

            try {
                const messaging = firebase.messaging();
                messaging.onMessage(payload => {
                    logger.debug('Message received. ', payload);

                    const { title, ...options } = payload.notification;
                    if ('serviceWorker' in navigator) {
                        navigator.serviceWorker.ready.then(registration => {
                            registration.showNotification(title, options);
                        });
                    }
                });
            } catch (err) {
                logger.error(err);
            }
        }
    },
    revokeCurrentToken: async pluginListenerHandle => {
        logger.debug(`${logPrefix} Revoking current token`);

        if (pushNotificationsSupported()) {
            try {
                await pluginListenerHandle?.remove();
                logger.debug(`${logPrefix} Token revoked successfully`);
            } catch (error) {
                logger.error(`${logPrefix} ${error}`);
                throw new Error(error.message);
            }
        }
    },
    incrementBadge: () => {
        badgeSupported().then(result => {
            if (result) {
                Badge.increase().then(() => logger.info(`${logPrefix} Badge incremented`));
            }
        });
    },
    clearBadge: () => {
        badgeSupported().then(result => {
            if (result) {
                Badge.clear().then(() => logger.info(`${logPrefix} Badge cleared`));
            }
        });
    },
    updateBadge: count => {
        badgeSupported().then(result => {
            if (result) {
                Badge.set({ count }).then(() =>
                    logger.info(`${logPrefix} Badge count set to ${count}`)
                );
            }
        });
    },
    /**
     *    Fetch unread DMs and unread notifications from cache to syncronize iOS badge with
     *    Current client-state unread notifications and unread DMs.
     */
    refreshBadge: (client, userId) => {
        if (!client || !userId || !Capacitor.isNativePlatform()) return false;

        try {
            const unreadDMs = pushUtilities.mapReduceUserMessageThreads(client, userId);
            const unreadNotifications = pushUtilities.mapReduceUserNotifications(client);
            const totalUnread = unreadDMs + unreadNotifications;

            pushUtilities.updateBadge(totalUnread);
            return true;
        } catch (e) {
            logger.warn(e);
            return false;
        }
    },
    mapReduceUserNotifications: client => {
        return (
            client
                .readQuery({
                    query: GET_NOTIFICATIONS_FOR_LOGGEDIN_USER,
                })
                ?.notificationsForLoggedInUser.map(
                    notification => notification?.currentUserReadCount <= 0 ?? 0
                )
                .reduce((totalUnread, unread) => totalUnread + unread, 0) ?? 0
        );
    },
    mapReduceUserMessageThreads: (client, userId) => {
        return (
            client
                .readQuery({
                    query: GET_USER_MESSAGE_THREADS,
                    variables: {
                        userId,
                        messageLimit: 0,
                        hasUnreadMessages: true,
                    },
                })
                ?.getUserMessageThreads?.threads.map(notification =>
                    notification.participants.filter(
                        participantData => participantData?.user?._id === userId
                    )?.[0]?.hasUnreadMessages
                        ? 1
                        : 0
                )
                .reduce((totalUnread, unread) => totalUnread + unread, 0) ?? 0
        );
    },
    /**
     * Object containing parameters as key value pairs, e.g. {a: '1, b: '2'}
     * Convert object into string formatted as "a=1&b=2&c=3"
     */
    appendQueryParam: (parsedData, history) => {
        const params = parsedData?.data.params;

        const queryString = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');

        if (history) {
            history.push({ search: `?${queryString}` });
        } else {
            window.location = '/notifications';
        }
    },
    markNotificationRead: (id, client) => {
        if (id) {
            client.mutate({
                mutation: MARK_NOTIFICATIONS_READ,
                variables: { read: [id] },
                refetchQueries: [GET_NOTIFICATIONS_FOR_LOGGEDIN_USER],
            });
        }
    },
    refetchUserNotifications: client => {
        if (client) {
            client.query({
                query: GET_NOTIFICATIONS_FOR_LOGGEDIN_USER,
            });
        }
    },
};

export default pushUtilities;

export const PushNotificationListener = ({ client }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const { newToast } = useToastActionsContext();

    const handleNotificationRegistrationError = text => {
        newToast(
            <AlertToast
                boldText={t(
                    `common:${localNamespace}.registration_error`,
                    'Error on registration!'
                )}
                text={text}
                showWarningIcon
            />,
            {
                ...defaultAlertToastOptions,
                duration: 5000,
            }
        );
    };

    useEffect(() => {
        pushUtilities.addBackgroundPushNotificationListeners(
            history,
            client,
            true,
            handleNotificationRegistrationError
        );
    }, [history]);
    return null;
};

export { pushNotificationsSupported };
