import React from 'react';

const ChevronLeft: React.FC<{ className?: string; strokeWidth?: string }> = ({
    className,
    strokeWidth = '3',
}) => (
    <svg
        className={className}
        strokeWidth={strokeWidth}
        stroke="currentColor"
        x="0px"
        y="0px"
        viewBox="0 0 407.436 407.436"
    >
        <polygon points="315.869,21.178 294.621,0 91.566,203.718 294.621,407.436 315.869,386.258 133.924,203.718 " />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
    </svg>
);

export default ChevronLeft;
