import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import useModal from '@web/ui/components/modals/hooks/useModal';

import Checkout from '@web/ui/components/card/Shopping-Cart/Checkout';

import { Product, Group } from '@shared/welibrary-graphql/types';

const EventActionModal: React.FC<{ group: Group; product: Product }> = ({ group, product }) => {
    const { closeModal } = useModal();
    const history = useHistory();

    const [showGuestCheckout, setShowGuestCheckout] = useState<boolean>(false);

    const handleLoginRedirect = () => {
        const loginRedirect = `/login?returnTo=/g/${group?._id}/shoppingCart`;
        history.push(loginRedirect);
        closeModal();
    };

    if (showGuestCheckout) {
        return <Checkout group={group} product={product} />;
    }

    return (
        <div className="event-action-modal">
            <div className="event-action-modal-col">
                <h1>Member Checkout</h1>

                <p>Please login to your existing account to proceed.</p>

                <button type="button" onClick={handleLoginRedirect}>
                    Login
                </button>
            </div>

            <div className="event-action-modal-col-divider" />

            <div className="event-action-modal-col">
                <h1>Guest Checkout</h1>

                <p>
                    If you checkout as a guest, you can later claim your account through the email
                    associated with your purchase.
                </p>

                <button type="button" onClick={() => setShowGuestCheckout(true)}>
                    Continue as Guest
                </button>
            </div>
        </div>
    );
};

export default EventActionModal;
