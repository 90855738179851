import {useEffect, useRef} from 'react';
import useOnScreen from '@web/ui/components/generic/hooks/useOnScreen';

/**
 * React hook for firing events that update breadcrumbs when scrolling
 *
 * This hooks works by creating two sentinel elements and tracking their position with
 * IntersectionObservers.
 *
 * These two sentinels should be invisble divs, placed above the content using absolute positioning
 * with the top sentinel top-aligned and the bottom sentinel bottom-aligned. Each elements height
 * should be set to either 100vh, or to the height of your content.
 *
 * The top sentinel will fire the callback function when at least 80% of it is visible. The bottom
 * sentinel will fire the callback function when at least 30% of it is visible, and at least the
 * top 10% is invisible.
 *
 * Use like so:
 *
 * const Section = ({ name, setCurrentSection }) => {
 *     const { topSentinel, bottomSentinel } = useBreadcrumbs(() => setCurrentSection(name));
 *
 *     return (
 *         <section style={{ position: 'relative', height: '100vh' }}>
 *             <div
 *                 style={{position: 'absolute', top: 0, width: '100%', height: '100vh'}}
 *                 ref={topSentinel}
 *             />
 *             <h3>{name}</h3>
 *             <div
 *                 style={{ position: 'absolute', bottom: 0, width: '100%', height: '100vh'}}
 *                 ref={bottomSentinel}
 *             />
 *         </section>
 *     )
 * }
 *
 * const Breadcrumbs = () => {
 *     const [currentSection, setCurrentSection] = useState('Section 1');
 *
 *     const sections = ['Section 1', 'Section 2', 'Section 3'].map(name => (
 *         <Section name={name} setCurrentSection={setCurrentSection} />
 *     ));
 *
 *     return (
 *         <>
 *             <aside style={{ position: 'fixed', left: 0, top: '50%' }}>{currentSection}</aside>
 *             {sections}
 *         </>
 *     )
 * }
 *
 * The above code  will create a series of full page sections with titles and a simple display on
 * the left side that will automatically update to the correct section title as you scroll through
 * each section.
 *
 * @param {() => void} callback
 * @return {{
 *     observe: () => void,
 *     unobserve: () => void,
 *     topSentinel: React.MutableRefObject,
 *     bottomSentinel: React.MutableRefObject,
 * }}
 */
const useBreadcrumbs = callback => {
    const bottomSentinel = useRef();
    const topSentinel = useRef();

    // fires when 80% of the element is visible
    const { observe: observeTop, unobserve: unobserveTop } = useOnScreen({
        ref: topSentinel,
        options: { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] },
        onChange: entry => {
            if (entry?.isIntersecting && entry?.intersectionRatio > 0.8) callback();
        },
        observeImmediately: false,
    });

    // fires when bottom 10% of the element is visible
    const { observe: observeBottom, unobserve: unobserveBottom } = useOnScreen({
        ref: bottomSentinel,
        options: { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] },
        onChange: entry => {
            // we only want this to fire for the bottom of the element
            const isBottom =
                entry?.boundingClientRect?.y / entry?.boundingClientRect?.height < -0.1;

            if (entry?.isIntersecting && entry?.intersectionRatio > 0.3 && isBottom) callback();
        },
        observeImmediately: false,
    });

    const observe = () => {
        observeTop();
        observeBottom();
    };

    const unobserve = () => {
        unobserveTop();
        unobserveBottom();
    };

    useEffect(() => {
        setTimeout(observe, 1000);
    }, []);

    return { observe, unobserve, topSentinel, bottomSentinel };
};

export default useBreadcrumbs;
