import React, { useEffect } from 'react';

import Modal from '@web/ui/components/generic/lightbox/Modal.js';

import { useCurrentUser } from '@stores/User';

import useModal from '@web/ui/components/modals/hooks/useModal';

import {
    CARD_BLOG,
    CARD_COURSE,
    CARD_DYNAMIC,
    CARD_RAINBOW_ROAD,
    CARD_CREATION,
    CARD_EVENT,
    CARD_SURVEY,
    CARD_DIALOG,
} from '@core/utilities/constants/card_types';
import { getForm } from '@web/ui/components/content/newpost/newposthelpers';

import { ModalTypes } from '@core/types/Modals';
import { DynamicCardSubTypes } from '@web/ui/components/card/dynamiccard/constants';
import { ATTACH_DYNAMIC_CARD } from '@core/utilities/constants/attachment_types';

const FORM_DISPLAY_INLINE = [CARD_BLOG];

const NEW_MODAL_TYPES = [
    CARD_COURSE,
    CARD_RAINBOW_ROAD,
    CARD_CREATION,
    CARD_EVENT,
    CARD_SURVEY,
    CARD_DIALOG,
];

const NewPostOverlayFormContainer = ({
    formName,
    formAttributes,
    triggerMenu,
    showModal,
    closeModalHandler,
    group,
}) => {
      const { currentUser } = useCurrentUser();
    const form = getForm(formName, formAttributes, currentUser, group);

    const { newModal } = useModal({
        desktop: ModalTypes.FullScreen,
        mobile: ModalTypes.FullScreen,
    });

    const displayInline = FORM_DISPLAY_INLINE.includes(formName);

    useEffect(() => {
        if (
            [CARD_COURSE, CARD_SURVEY, CARD_CREATION, CARD_RAINBOW_ROAD, CARD_DIALOG].includes(
                formName
            )
        ) {
            newModal(form, { hideButton: true });
            formAttributes.closeNewPostModal();
        }

        if (formName === CARD_EVENT) {
            newModal(form, {
                hideButton: true,
                className: 'center-fixed-header-footer',
                confirmClose: 'Are you sure you want to cancel without saving?',
            });
        }

        // default to Group Info Card for Dynamic cards
        if (formName === CARD_DYNAMIC) {
            formAttributes.addAttachedContent(
                { subtype: DynamicCardSubTypes.GroupInfoCard },
                ATTACH_DYNAMIC_CARD,
                () => {}
            );
        }
    }, [formName]);

    if (displayInline) return <div className="inline-form-container">{form}</div>;

    if (!displayInline && form && !NEW_MODAL_TYPES.includes(formName)) {
        if (formName !== CARD_COURSE) {
            return (
                <Modal
                    className="modal"
                    delayTime={500}
                    triggerMenu={triggerMenu}
                    isMounted={showModal}
                    showModal={showModal}
                    close={closeModalHandler}
                    contentCard
                >
                    {form}
                </Modal>
            );
        }
    }

    return <></>;
};

export default NewPostOverlayFormContainer;
