import moment from 'moment';

export const nullifyTimezone = (date?: Date) => {
    if (!date) {
        return undefined;
    }

    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const toIsoStringIgnoreTimezone = (date?: Date | string) => {
    if (!date) {
        return undefined;
    }
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

export const isoStringToDateNullifyTimezone = (isoDate: string) => {
    const dateWithLocalTimezone = new Date(isoDate);

    return nullifyTimezone(dateWithLocalTimezone);
};

export const getDateObjectFromDateAndTimeIso = (
    isoDate: string,
    isoTime: string,
    timeZone: string
) => {
    const timeString = `${moment(isoDate).utc().format('MMM D, YYYY')} ${moment(isoTime)
        .tz(timeZone)
        .format('h:mm A z')}`;

    return new Date(timeString);
};

// 1671480905780 => '12:15PM • Dec 19, 2022'
export const ISOStringToLocalHumanReadableDate = (ISOString: string) => {
    const toLocalTimeStringOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    const toLocalDateStringOptions: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    };

    const time = new Date(ISOString)
        .toLocaleString('en-US', toLocalTimeStringOptions)
        .replace(' ', '');
    const date = new Date(ISOString).toLocaleString('en-US', toLocalDateStringOptions);

    return `${time} • ${date}`;
};

export const epochTimestampLocalHumanReadableDate = (epochTimestamp: number) => {
    const now = new Date(epochTimestamp).toISOString();
    return ISOStringToLocalHumanReadableDate(now);
};
