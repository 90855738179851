import React from 'react';
import {withTranslation} from 'react-i18next';

import {Link} from 'react-router-dom';

const localNamespace = 'imports.wlWeb.ui.components.generic.voteorskip.voteOrSkipButton';

const VoteOrSkipButton = props => {
    const {
        t,
        showSkip,
        noMoreToFetch,
        inverseColors,
        reviewAndVoteText,
        promptForChapter,
        finishLink,
        finishedText,
        finishedSubtext,
        setSortOfChapter,
        gotoDefaultChapterSort,
    } = props;

    if (noMoreToFetch) {
        let displayText = t(`common:${localNamespace}.seen_everything`);

        if (promptForChapter) {
            displayText = t(`common:${localNamespace}.engage_prompt`);
            displayText = promptForChapter.prompt || promptForChapter.title;
        }

        return (
            <>
                <div className={`vote-or-skip-container ${inverseColors && ' dark'}`}>
                    <div className="display-text-inline we-review-and-vote-text we-vote-finish-text">
                        {finishedText || t(`common:${localNamespace}.finished_voting_text`)}
                    </div>
                </div>
                {finishLink ? (
                    <Link
                        to={finishLink}
                        className="display-text-inline we-review-and-vote-text we-vote-finish-sub-text"
                    >
                        {finishedSubtext || t(`common:${localNamespace}.finished_voting_subtext`)}
                        &nbsp;
                        <div className="we-vote-finish-arrow w-inline-block" />
                    </Link>
                ) : (
                    ''
                )}
                {!finishLink && gotoDefaultChapterSort ? (
                    <a
                        onClick={() => gotoDefaultChapterSort()}
                        className="display-text-inline we-review-and-vote-text we-vote-finish-sub-text"
                    >
                        {finishedSubtext || t(`common:${localNamespace}.finished_voting_subtext`)}
                        &nbsp;
                        <div className="we-vote-finish-arrow w-inline-block" />
                    </a>
                ) : (
                    ''
                )}
            </>
        );
    }

    const displayText = reviewAndVoteText || t(`common:${localNamespace}.review_and_vote`);

    return (
        // <div className={`vote-or-skip-container ${inverseColors && ' dark'}`}>
        //     <div className="display-text-inline we-review-and-vote-text">
        //         {displayText}
        //     </div>
        // </div>
        <div></div>
    );
};

export default withTranslation()(VoteOrSkipButton);
