import React from 'react';

import { useTranslation } from 'react-i18next';
import { titleCase } from '@helpers/string.helpers';

interface SignatureInputProps {
    signature: string;
    setSignature: (signature: string) => void;
}

const SignatureInput: React.FC<SignatureInputProps> = ({ signature, setSignature }) => {
    const { t } = useTranslation();
    return (
        <>
            <h4>
                <label htmlFor="signature-input">
                    {titleCase(t('common:signature', 'signature')) + ':'}
                </label>
            </h4>
            <input
                value={signature}
                onChange={e => setSignature(e.target.value)}
                type="text"
                className="w-input"
                maxLength={256}
                placeholder={titleCase(t('common:enter_signature...', 'enter signature...'))}
                id="signature-input"
            />
        </>
    );
};

export default SignatureInput;
