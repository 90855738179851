import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { createContext } from '@core/utilities/helpers/context.helpers';

import { MessageAttachment, MessageDraft } from '@core/types/Messages';
import { SetState } from '@core/types/Utilities';
import { Maybe, MessageThread, UserParticipant } from '@shared/welibrary-graphql/types';

export type MessagesContextValues = {
    threadId: string;
    messageContacts: Maybe<UserParticipant>[];
    fileUrl?: string;
    attachment?: MessageAttachment;
    selectedThreadData?: MessageThread;
    messageOverlay: boolean | string;
    groupName: string;
    searchQuery: string;
    drafts: { [key: string]: MessageDraft };
    getMessage: () => string;
    focusMessageInput: () => void;
    messageInputRef?: MutableRefObject<HTMLTextAreaElement | undefined>;
    messageRef: MutableRefObject<string>;
    messageSearchQuery?: string;
    messageBeingEdited?: string;
    hasUnreadMessages?: boolean;
};

export type MessagesActionsContextValues = {
    toggleThread: SetState<string>;
    setMessageContacts: SetState<Maybe<UserParticipant>[]>;
    setFileUrl: SetState<string>;
    setAttachment: SetState<MessageAttachment | undefined>;
    setCancelAttachmentFunction: SetState<() => void | undefined>;
    setSelectedThreadData: SetState<MessageThread | undefined>;
    setMessageOverlay: (value: boolean | string) => void;
    setMessageSearchQuery: SetState<string>;
    setGroupName: SetState<string>;
    setSearchQuery: SetState<string>;
    addDraft: (thread: string) => void;
    addDraftCurrentThread: () => void;
    clearDraft: (thread: string) => void;
    setMessage: (message: string) => void;
    setDrafts: SetState<{ [key: string]: MessageDraft }>;
    setMessageBeingEdited: SetState<any>;
    setHasUnreadMessages: SetState<boolean>;
};

export const [useMessagesContext, MessagesContextProvider] = createContext<MessagesContextValues>();
export const [useMessagesActionsContext, MessagesActionsContextProvider] =
    createContext<MessagesActionsContextValues>();

// TODO: convert to using zustood!!!
const MessagesProvider: React.FC = ({ children }) => {
    const [threadId, toggleThread] = useState('');
    const [messageContacts, setMessageContacts] = useState<Maybe<UserParticipant>[]>([]);
    const [fileUrl, setFileUrl] = useState('');
    const [attachment, setAttachment] = useState<MessageAttachment>();
    const [cancelAttachmentFunction, setCancelAttachmentFunction] =
        useState<() => void | undefined>();
    const [selectedThreadData, setSelectedThreadData] = useState<MessageThread>();
    const [messageOverlay, setOverlay] = useState<boolean | string>(false);
    const [groupName, setGroupName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [messageSearchQuery, setMessageSearchQuery] = useState('');
    const [drafts, setDrafts] = useState<{ [key: string]: MessageDraft }>({});
    const [messageBeingEdited, setMessageBeingEdited] = useState('');
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

    /**
     * This is a ref to the TextArea used to input a DM
     *
     * Please note that reading the value stored by that element is NOT trustworthy. This should
     * only be used for things like forcing focus. If you need to read/set the value stored here,
     * please use the messageRef defined below. (Consumers of this context should instead be
     * using the getMessage, setMessage, and focusMessageInput functions defined below)
     */
    const messageInputRef = useRef<HTMLTextAreaElement>();

    /** This is a ref that contains the user's currently typed message */
    const messageRef = useRef('');

    const getMessage = () => messageRef?.current;

    const focusMessageInput = () => messageInputRef?.current?.focus();

    /** Updates the value stored in messageRef */
    const setMessage = (message: string) => {
        if (messageRef) messageRef.current = message;
    };

    const setMessageOverlay = (value: boolean | string) => {
        setOverlay(value);
        setMessageContacts(value === 'edit' ? selectedThreadData?.participants ?? [] : []);
        setGroupName(value === 'edit' ? selectedThreadData?.displayName ?? '' : '');
        setSearchQuery('');
    };

    const addDraft = (thread: string) => {
        setDrafts(oldDrafts => ({
            ...oldDrafts,
            [thread]: { message: getMessage(), attachment },
        }));
    };

    const addDraftCurrentThread = () => addDraft(threadId);

    const clearDraft = (thread: string) =>
        setDrafts(oldDrafts => ({ ...oldDrafts, [thread]: { message: '' } }));

    useEffect(() => {
        if (attachment === null) cancelAttachmentFunction?.();
    }, [attachment]);

    return (
        <MessagesContextProvider
            value={{
                threadId,
                messageContacts,
                fileUrl,
                attachment,
                selectedThreadData,
                messageOverlay,
                groupName,
                searchQuery,
                drafts,
                getMessage,
                focusMessageInput,
                messageInputRef,
                messageRef,
                messageSearchQuery,
                messageBeingEdited,
                hasUnreadMessages,
            }}
        >
            <MessagesActionsContextProvider
                value={{
                    toggleThread,
                    setMessageContacts,
                    setFileUrl,
                    setAttachment,
                    setCancelAttachmentFunction,
                    setSelectedThreadData,
                    setMessageOverlay,
                    setGroupName,
                    setSearchQuery,
                    addDraft,
                    addDraftCurrentThread,
                    clearDraft,
                    setMessage,
                    setDrafts,
                    setMessageSearchQuery,
                    setMessageBeingEdited,
                    setHasUnreadMessages,
                }}
            >
                {children}
            </MessagesActionsContextProvider>
        </MessagesContextProvider>
    );
};

export default MessagesProvider;
