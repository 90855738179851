import React from 'react';

import { useTranslation } from 'react-i18next';

import NewPostOverlay from '@web/ui/components/content/newpost/NewPostOverlay';
import Modal from '@web/ui/components/generic/lightbox/Modal.js';
import { CARD_IMAGE, CARD_LINK, CARD_TEXT, CARD_VIDEO } from '@core/utilities/constants/card_types';

const DEFAULT_ANNOUNCEMENT_CARD_TYPES = [CARD_IMAGE, CARD_LINK, CARD_TEXT, CARD_VIDEO];

const localNamespace =
    'imports.wlWeb.ui.components.generic.editScreen.sendAdminNotificationContainer';

const GroupAnnouncementPostBox = ({
    group,
    showConfirmation,
    showAnnouncementPostBox,
    handleAnnouncementPostBox,
}) => {
    const { t } = useTranslation();
    return (
        // TODO: replace Modal component
        // ... with new generic modal/overlay
        <Modal
            className="modal"
            delayTime={500}
            isMounted={showAnnouncementPostBox}
            close={handleAnnouncementPostBox}
            contentCard
        >
            <div className="new-post-overlay-wrapper">
                <NewPostOverlay
                    feedUrl={group?.shelf?.url}
                    allowedTypes={DEFAULT_ANNOUNCEMENT_CARD_TYPES}
                    group={group}
                    closeModal={handleAnnouncementPostBox}
                    showConfirmation={showConfirmation}
                    displaySocialHeaders
                    formTitle="announcement"
                    prompt={t(`common:${localNamespace}.text_area_placeholder`)}
                />
            </div>
        </Modal>
    );
};

export default GroupAnnouncementPostBox;
