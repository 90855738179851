import React from 'react';

import { CreationPageNavTabType } from '@components/lego/creation/creationpage/types';
import { ContentCard } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

type ChooseCreationHeaderProps = {
    card?: Maybify<ContentCard> | null;
    selectedIndex?: number;
    activeScreen: CreationPageNavTabType;
};

const ChooseCreationHeader: React.FC<ChooseCreationHeaderProps> = ({
    card,
    selectedIndex = 0,
    activeScreen,
}) => {
    const creationItems = card?.creationItems;
    const courseItems = creationItems?.course?.courseItems;
    const selectedCourseLevel = courseItems?.levels?.[selectedIndex];
    let headerText;

    if (activeScreen === 'creationSlide') {
        headerText = (
            <>
                <p>To build this creation, collect these</p>
                <p>Super Skills Resources!</p>
            </>
        );
    }

    if (activeScreen === 'courseSlide') {
        headerText = <h4 className="course-level-name">{selectedCourseLevel?.title}</h4>;
    }

    if (!activeScreen) return <></>;

    return (
        <header>
            <h1>{card?.title}</h1>
            <div className="header-text">{headerText}</div>
        </header>
    );
};

export default ChooseCreationHeader;
