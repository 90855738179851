import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/client';
import _, { clone, cloneDeep } from 'lodash';
import { getOptionalPostTypes } from '@core/utilities/whitelabel_helpers';
import {
    GET_CHAPTER_CHILDREN,
    GET_LINK_METADATA,
    GET_PAGINATED_NEWSFEED,
} from '@shared/welibrary-graphql/content_card/queries';
import { GET_CURRENT_USER, GET_PAGINATED_USER_FEED } from '@shared/welibrary-graphql/user/queries';
import { ATTACHED_CARD } from '@shared/welibrary-graphql/content_card/mutations';
import { ADD_ADMIN_ANNOUNCEMENT } from '@shared/welibrary-graphql/notifications/mutations';
import { useFilestack } from '@components/generic/hooks/useFilestack';
import useFilestackPicker from '@components/generic/hooks/useFilestackPicker';
import { parseTextForURLs } from '@core/utilities/constants/urlHelpers';
import {
    NEWSFEED_OMIT_TYPES,
    getPostVariables,
    cardToAttachType,
} from '@components/content/newpost/constants';
import { parseURL, parseYoutubeURL, typeAllowed } from '@components/content/newpost/newposthelpers';
import { IMAGE_MIME_TYPES, VIEWER_MIME_TYPES } from '@core/utilities/constants/filestack';
import { MATCH_URL, mediaRegex } from '@core/utilities/constants/regexes';
import {
    ATTACH_DOCUMENT,
    ATTACH_IMAGE,
    ATTACH_IMAGE_SQUARE,
    ATTACH_LINK,
    ATTACH_NONE,
    ATTACH_STATE_FAIL,
    ATTACH_STATE_OPEN,
    ATTACH_STATE_PREVIEW,
    ATTACH_STATE_UPLOAD,
    ATTACH_STORY_CARD,
    ATTACH_VIDEO,
    ATTACH_VOTE_GALLERY,
    OPT_IN_TYPES,
} from '@core/utilities/constants/attachment_types';
import {
    CARD_IMAGE,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_TEXT,
    CARD_TYPES,
    CARD_VIDEO,
    DEFAULT_POST_TYPES,
} from '@core/utilities/constants/card_types';
import getLogger from '@core/logger';

const FILE_PICKER_ATTACH_TYPES = [ATTACH_IMAGE, ATTACH_DOCUMENT, ATTACH_STORY_CARD];
const logger = getLogger(module);
const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

// Define types for state and options
type AttachmentType =
    | 'empty'
    | typeof ATTACH_IMAGE
    | typeof ATTACH_IMAGE_SQUARE
    | typeof ATTACH_LINK
    | typeof ATTACH_DOCUMENT
    | typeof ATTACH_VIDEO
    | typeof ATTACH_VOTE_GALLERY
    | typeof ATTACH_STORY_CARD;

interface AttachedContent {
    type: AttachmentType;
    data: any;
}

interface State {
    body: string;
    isFetchingLink: boolean;
    onLinkFetched: boolean;
    attachedContent: AttachedContent;
    attachmentOpen: typeof ATTACH_NONE | AttachmentType;
    attachState:
        | typeof ATTACH_STATE_OPEN
        | typeof ATTACH_STATE_UPLOAD
        | typeof ATTACH_STATE_PREVIEW
        | typeof ATTACH_STATE_FAIL;
    imagesSelected: any[];
    maxLettersForPost: number;
    wordCount: number;
    error: any;
    urlBlacklist: string | null;
    urlLinkError: string | null;
    displaySocialHeaders: boolean | null;
    isTemplate: boolean | null;
    showCreateChildren: boolean | null;
    statusMsg: string | null;
    lastAttachedCardUrl: string | null;
    customFields: any[];
    defaultViewType: string | null;
    showTitle: boolean | null;
    protectionLevel: string | null;
    openInModal: boolean | null;
    showPostTypes: boolean | null;
    inheritedOptions?: any;
}

interface Options {
    [key: string]: any;
}

interface InheritedOptions {
    [key: string]: any;
}

// Default state
const defaultState: State = {
    body: '',
    isFetchingLink: false,
    onLinkFetched: false,
    attachedContent: { type: 'empty' },
    attachmentOpen: ATTACH_NONE,
    attachState: ATTACH_STATE_OPEN,
    imagesSelected: [],
    maxLettersForPost: 2000,
    wordCount: 0,
    error: null,
    urlBlacklist: null,
    urlLinkError: null,
    displaySocialHeaders: null,
    isTemplate: null,
    showCreateChildren: null,
    statusMsg: null,
    lastAttachedCardUrl: null,
    customFields: [],
    defaultViewType: null,
    showTitle: null,
    protectionLevel: null,
    openInModal: null,
    showPostTypes: null,
};

// Initialize state with options
const initState = (options: Options): State => {
    return { ...defaultState, ...options };
};

/**
 * Hook to facilitate and encapsulate new posting of content
 * @param {{ canCreate: string[], [key: string]: any }} user User object
 * @param {string} feedUrl Feed URL to post content into
 * @param {string[]} allowedTypes Array of CARD_TYPE strings, e.g. ["text", "link", "video", "image"]
 * @param {(type: string) => void} openModalHandler Function that opens form in modal
 * @param {{ [key: string]: any }} options Object of optional values - used currently to set initial settings...not well documented atm
 * @param {{ [key: string]: any }} inheritedOptions Object of inherited optional values - todo condense this with options....
 */
const useNewPost = (
    user: { canCreate: string[]; [key: string]: any },
    feedUrl: string,
    allowedTypes: string[],
    openModalHandler: (type: string) => void,
    options: Options,
    inheritedOptions?: InheritedOptions
) => {
    const client = useApolloClient();
    const [state, setState] = useState(initState(options));
    const [attachedCard, { loading: submitNewPostloading, error: submitNewPostError }] =
        useMutation(ATTACHED_CARD);
    const [addAdminAnnouncement, { loading: announcementloading, error: announcementError }] =
        useMutation(ADD_ADMIN_ANNOUNCEMENT);

    const { t, i18n } = useTranslation();

    const openPicker = useFilestackPicker();
    const handleSetState = updatedValues => {
        setState(prevState => {
            return { ...prevState, ...updatedValues };
        });
    };

    useEffect(() => {
        if (inheritedOptions) {
            handleSetState({
                inheritedOptions,
            });
        }
    }, []);

    const getAllTypesAllowed = (user, allowedTypes) => {
        const cardTypesArr = Object.values(CARD_TYPES);
        let allAllowedTypes = cardTypesArr?.filter(cardType =>
            typeAllowed(cardType, user, allowedTypes)
        );

        const additionalPostTypes = getOptionalPostTypes();

        if (!additionalPostTypes) {
            allAllowedTypes = allAllowedTypes?.filter(
                postType => DEFAULT_POST_TYPES?.includes(postType)
            );
        }

        return allAllowedTypes;
    };

    const [allAllowedTypes, setAllAllowedTypes] = useState(getAllTypesAllowed(user, allowedTypes));

    const isAllowed = type => {
        return allAllowedTypes.includes(type);
    };

    const getAttachTypeFromMimeType = mimeType => {
        if (IMAGE_MIME_TYPES.includes(mimeType)) return ATTACH_IMAGE;

        // filestack returns "" for file.type for certain file types (like .pages and .numbers)
        if (VIEWER_MIME_TYPES.includes(mimeType) || mimeType === '') return ATTACH_DOCUMENT;
    };

    const handleUploadUrl = (fileUrl, file, res) => {
        let type;
        if (res.length > 1) {
            type = getAttachTypeFromMimeType(file[0].type);
        } else {
            type = getAttachTypeFromMimeType(file.type);
        }
        addAttachedContent(res, type);
    };

    // We need to use the ref because of a race condition that happens when immediately clicking the
    // file selector on first render
    const { fileSelectorRef, isLoading: fileUploadLoading } = useFilestack({
        fileType: IMAGE_MIME_TYPES,
        onUpload: handleUploadUrl,
    });

    useEffect(() => {
        if (fileUploadLoading) {
            handleSetState({
                isFetchingLink: true,
                attachState: ATTACH_STATE_UPLOAD,
            });
        } else {
            handleSetState({
                isFetchingLink: false,
            });
        }
    }, [fileUploadLoading]);

    const addAttachedContent = (content, type = state.attachmentOpen) => {
        handleSetState({
            attachState: ATTACH_STATE_PREVIEW,
            attachedContent: { type, data: content },
        });
    };

    const failAttachedContent = content => {
        handleSetState({
            statusMessage: t(`common:${localNamespace}.upload_failed_error`),
            attachState: ATTACH_STATE_FAIL,
        });
    };

    const extractMetaDataFromURL = (newInput, url, attachmentAction) => {
        const urlsToFetch = newInput.match(url);
        if (!urlsToFetch?.length) return;
        // setIsFetchingLink({ isFetchingLink: true });
        handleSetState({ isFetchingLink: true, onLinkFetched: false });
        getLinkMetadata(urlsToFetch[0], attachmentAction);
    };

    const debouncedFetchLink = _.debounce(_newInput => {
        const links = parseTextForURLs(_newInput);
        if (links.length === 0 || links[0] === state.urlBlacklist) {
            handleSetState({
                isFetchingLink: false,
                onLinkFetched: false,
            });
            return;
        }
        const urlToParse = links[0]; // use first valid URL found
        const isURL = MATCH_URL.test(urlToParse); // sanity check
        if (isURL && state.attachState !== ATTACH_STATE_PREVIEW) {
            const image = parseURL(urlToParse, mediaRegex.image);
            const vimeo = parseURL(urlToParse, mediaRegex.vimeo);
            const youtube = !vimeo
                ? parseURL(urlToParse, mediaRegex.youtube, parseYoutubeURL)
                : false;

            if ((youtube || vimeo) && isAllowed(CARD_VIDEO)) {
                extractMetaDataFromURL(urlToParse, MATCH_URL, ATTACH_VIDEO);
            } else if (image && isAllowed(CARD_IMAGE)) {
                extractMetaDataFromURL(urlToParse, MATCH_URL, ATTACH_IMAGE);
            } else if (isAllowed(CARD_LINK) && !mediaRegex.tweet.test(urlToParse)) {
                extractMetaDataFromURL(urlToParse, MATCH_URL, ATTACH_LINK);
            }
        }
    }, 1000);

    const handleFetchLink = e => {
        const { target } = e;
        const value = target.value === 'checkbox' ? target.checked : target.value;
        if (isAllowed(CARD_LINK) || isAllowed(CARD_VIDEO) || isAllowed(CARD_IMAGE)) {
            debouncedFetchLink(value);
        }
    };

    // todo extract link fetching logic to separate hook or module
    const getLinkMetadata = async (targetUrl, attachmentType) => {
        if (targetUrl !== state.urlBlacklist) {
            try {
                const { data } = await client
                    .query({
                        query: GET_LINK_METADATA,
                        variables: { url: targetUrl },
                    })
                    .catch(err =>
                        handleSetState({
                            urlLinkError: err.message,
                        })
                    );

                handleLinkFetched(data.linkMetaData, attachmentType);
            } catch (e) {
                handleSetState({
                    urlBlacklist: targetUrl,
                    isFetchingLink: false,
                    onLinkFetched: false,
                });
            }
        }
    };

    const handleLinkFetched = (data, attachmentType) => {
        handleSetState({ isFetchingLink: false, onLinkFetched: true });
        if (data?.hasOwnProperty('url')) {
            const _data = { ...data, openInModal: false };
            expandAttachment(attachmentType);
            handleSetState({
                urlBlacklist: null,
                attachedContent: { data: _data, type: attachmentType },
                attachState: ATTACH_STATE_PREVIEW,
                urlLinkError: null,
            });
        }
    };

    const expandAttachment = type => {
        if (state.attachmentOpen !== type) {
            handleSetState({
                attachmentOpen: type,
                attachState: ATTACH_STATE_UPLOAD,
            });
        }
    };

    const truncateAttachment = () => {
        if (state.attachmentOpen !== ATTACH_NONE) {
            handleSetState({
                attachmentOpen: ATTACH_NONE,
                attachState: ATTACH_STATE_OPEN,
                attachedContent: { type: 'empty' },
                showAllAttachOptions: false,
            });
        }
    };

    const clearAttachment = () => {
        handleSetState({
            attachedContent: { type: 'empty' },
            attachState: ATTACH_STATE_OPEN,
            attachmentOpen: ATTACH_NONE,
        });
    };
    const handleLinkInputChange = e => {
        const newInput = e.target.value;
        setState({ linkValue: newInput });
        debouncedFetchLink(newInput);
    };

    const handleTextAreaChange = (value, onTextAreaChange) => {
        // Value from outline prosemirror input is a function
        const text = value();

        // Added a trim to the text value.
        // If characters were entered, and then removed, the length
        // Or count, remained at 1 until entering additional characters
        // This left the count at 1999
        const length = text?.trim().length ?? 0;

        if (length <= state.maxLettersForPost) {
            handleSetState({ body: text, wordCount: length });
        } else {
            handleSetState({ body: text.substring(0, length), wordCount: length });
        }
        if (onTextAreaChange) {
            onTextAreaChange();
        }
    };

    useEffect(() => {
        // Check for inline link if allowed and there is not already an attached content item
        if (
            (state?.body &&
                state?.attachedContent?.type &&
                state?.attachedContent?.type !== 'empty' &&
                isAllowed(CARD_LINK)) ||
            isAllowed(CARD_VIDEO) ||
            isAllowed(CARD_IMAGE)
        ) {
            debouncedFetchLink(state.body);
        }
    }, [state?.body]);

    const handleTitleChange = e => {
        // first case is to handle case where title is set in attachment...but title field is shown...
        // TODO rework this logic later
        if (state?.attachedContent?.type === ATTACH_VOTE_GALLERY) {
            setState(oldState => ({
                ...oldState,
                attachedContent: {
                    ...oldState.attachedContent,
                    data: { ...oldState.attachedContent.data, title: e.target.value },
                },
            }));
        } else handleSetState({ title: e.target.value });
    };

    const handleFileSelect = type => {
        handleSetState({ attachmentOpen: type });

        const selector = fileSelectorRef.current;

        if (type === ATTACH_DOCUMENT) {
            selector?.setAttribute('accept', VIEWER_MIME_TYPES);
        }

        if (type === ATTACH_IMAGE || type === ATTACH_STORY_CARD) {
            selector?.setAttribute('accept', IMAGE_MIME_TYPES);
            selector?.setAttribute('multiple', 'multiple');
        }

        selector?.click();
    };

    // Set customFields answers on content card
    const handleSetCustomFields = survey => {
        const newFields = survey;
        if (state?.customFields && state?.customFields?.length > 0) {
            const customFieldsCloned = cloneDeep(state?.customFields);
            // Unsure if this is safe assumption to make
            const alreadyExistsIndex = state.customFields.findIndex(
                currentSurvey => currentSurvey.parentUrl === survey.parentUrl
            );
            if (alreadyExistsIndex !== -1) {
                customFieldsCloned[alreadyExistsIndex] = survey;
                handleSetState({
                    customFields: customFieldsCloned,
                });
            } else {
                handleSetState({
                    customFields: [...customFieldsCloned, survey],
                });
            }
        } else {
            handleSetState({
                customFields: [newFields],
            });
        }
    };

    const handleSquarePhotoSelect = () => {
        openPicker({
            accept: IMAGE_MIME_TYPES,
            lang: i18n.language,
            transformations: {
                crop: { aspectRatio: 1, force: true },
                rotate: true,
                circle: false,
            },
            maxFiles: 10,
            onUploadDone: ({ filesUploaded }) => {
                // addAttachedContent(filesUploaded[0], ATTACH_IMAGE_SQUARE);
                addAttachedContent(filesUploaded, ATTACH_IMAGE_SQUARE); // return entire array of images
            },
        });
    };

    // TODO: Simplify the following multiple functions:

    const handleAnonymousToggle = (e, value) => {
        const { name } = e.target;
        handleSetState({
            [name]: value,
        });
    };

    const handleToggle = (e, value) => {
        const { name } = e.target;
        handleSetState({
            [name]: value,
        });
    };

    const handleChange = (e, isInteger) => {
        const { target } = e;
        const value = target.value === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        let _value;
        if (value && isInteger) {
            _value = parseInt(value);
        } else {
            _value = value;
        }

        handleSetState({
            [name]: _value,
        });
    };

    const handleAddToList = (key, value) => {
        if (state[key]) {
            if (_.isArray(state[key])) {
                if (!state[key].includes(value)) {
                    handleSetState({
                        [key]: [...state[key], value],
                    });
                }
            }
        } else {
            handleSetState({
                [key]: [value],
            });
        }
    };

    const handleRemoveFromList = (key, value) => {
        if (state[key]) {
            if (_.isArray(state[key])) {
                if (state[key].includes(value)) {
                    handleSetState({
                        [key]: _.filter(state[key], _value => _value !== value),
                    });
                }
            }
        }
    };

    const handleImgToggle = (img, imgUploads = []) => {
        if (imgUploads.length > 0) {
            setState(prevState => {
                return {
                    ...prevState,
                    imagesSelected: [...prevState.imagesSelected, ...imgUploads],
                };
            });
        }

        if (imgUploads.length === 0) {
            const imageExists = state.imagesSelected.find(i => i.url === img.url);

            // add image to imagesSelected array if it hasn't been selected
            if (!imageExists) {
                // if (state.imagesSelected.length === 10) return; // comment out limit for now

                setState(prevState => {
                    return {
                        ...prevState,
                        imagesSelected: [...prevState.imagesSelected, img],
                    };
                });
            }

            // remove image from imagesSelected array if it's already been selected
            if (imageExists && state.imagesSelected.length !== 1) {
                const _imagesSelected = clone(state.imagesSelected);
                const updatedImagesSelected = _imagesSelected.filter(i => i.url !== img.url);
                setState(prevState => {
                    return {
                        ...prevState,
                        imagesSelected: [...updatedImagesSelected],
                    };
                });
            }
        }
    };

    const updateCacheAfterSubmit = (cache, { data: { attachedCard } }, parentURL, sortType) => {
        // In props options, you can pass in an updateCache function that will be called with passed in updateCacheOptions - it will be called here
        if (options?.updateCache && options?.updateCacheOptions) {
            options?.updateCache(cache, attachedCard, options?.updateCacheOptions);
        }

        /*
            This update function adds the newly submitted card to the top of the local cache of
            Content cards in the 'getChildrenFromChapter' feed. This is so the user immediately sees
            the card they just posted
        */
        try {
            const { paginatedChildrenFromCard } =
                cache.readQuery({
                    query: GET_CHAPTER_CHILDREN,
                    variables: { limit: 17, url: feedUrl, sortType, tags: [] },
                }) ?? {};

            if (paginatedChildrenFromCard) {
                cache.writeQuery({
                    query: GET_CHAPTER_CHILDREN,
                    variables: { limit: 17, url: feedUrl, sortType, tags: [] },
                    data: {
                        paginatedChildrenFromCard: {
                            ...paginatedChildrenFromCard,
                            results: [
                                attachedCard,
                                ...paginatedChildrenFromCard.results.slice(0, 16),
                            ],
                        },
                    },
                });
            }
        } catch (error) {
            logger.warn(error);
        }

        const doNotUpdateInNewsfeed = NEWSFEED_OMIT_TYPES.includes(attachedCard?.type);
        // updates the user's (profile) feed
        // after making a new post
        try {
            if (!doNotUpdateInNewsfeed) {
                const { userFeed } =
                    cache.readQuery({
                        query: GET_PAGINATED_USER_FEED,
                        variables: { _id: user._id },
                    }) ?? {};

                if (userFeed) {
                    const cachedUserFeed = cloneDeep(userFeed);

                    cache.writeQuery({
                        query: GET_PAGINATED_USER_FEED,
                        variables: { _id: user._id },
                        data: {
                            userFeed: {
                                ...userFeed,
                                results: [attachedCard, ...cachedUserFeed.results],
                            },
                        },
                    });
                }
            }
        } catch (error) {
            logger.error('paginatedUserFeed cache not in use.');
        }

        /* TODO: Have Cache mapped to the proper 'limit' variable.
            Right now, it will not work if the limit is not '10'... */
        /* TODO: Add Error Handling and Loading. */

        // updates the user's (home)/news feed
        // after making a new post
        try {
            if (!doNotUpdateInNewsfeed) {
                const { paginatedNewsFeed } =
                    cache.readQuery({
                        query: GET_PAGINATED_NEWSFEED,
                    }) ?? {};

                if (paginatedNewsFeed) {
                    const cachedNewsFeed = cloneDeep(paginatedNewsFeed); // clone cache

                    cache.writeQuery({
                        query: GET_PAGINATED_NEWSFEED,
                        data: {
                            paginatedNewsFeed: {
                                ...paginatedNewsFeed,
                                results: [attachedCard, ...cachedNewsFeed.results], // update paginatedNewsFeed results cache
                            },
                        },
                    });
                }
            }
        } catch (error) {
            logger.error('paginatedNewsFeed cache not in use.');
        }

        try {
            const { currentUser } = cache.readQuery({ query: GET_CURRENT_USER }) ?? {};

            if (!currentUser) return;

            currentUser.createdContent = [attachedCard, ...currentUser.createdContent];

            cache.writeQuery({ query: GET_CURRENT_USER, data: { currentUser } });
        } catch (error) {
            logger.warn(error);
        }

        try {
            handleSetState({ lastAttachedCardUrl: attachedCard.url });
        } catch (error) {
            logger.warn(error);
        }
    };

    const constructPayload = displaySocialHeaders => {
        const { body, title, attachedContent, customFields } = state;

        let attachmentData;
        if (
            (attachedContent.type === ATTACH_IMAGE_SQUARE && state.imagesSelected.length > 0) ||
            (attachedContent.type === ATTACH_IMAGE && state.imagesSelected.length > 0)
        ) {
            attachmentData = { type: attachedContent.type, data: state.imagesSelected };
        }

        if (state?.attachmentOpen === ATTACH_STORY_CARD) {
            attachmentData = { type: ATTACH_STORY_CARD, data: state.imagesSelected };
        }

        const content = attachmentData ?? attachedContent;
        const type = content?.type || null;

        if ((body === '' && content.type === 'empty') || submitNewPostloading) {
            // this.setState({ statusMessage: 'Error: No user input' });
        } else {
            const payload = getPostVariables({
                type,
                body,
                title,
                content,
                displaySocialHeaders,
                parentURL: feedUrl,
            });

            const {
                isTemplate,
                showCreateChildren,
                displayInNewsfeed,
                engagementTypes,
                defaultViewType,
                showTitle,
                canMembersSuggestEdits,
                protectionLevel,
            } = state;

            const advancedSettings = {
                isTemplate,
                showCreateChildren,
                displayInNewsfeed,
                engagementTypes,
                defaultViewType,
                showTitle,
                canMembersSuggestEdits,
                protectionLevel,
            };

            // If there are advanced options that inherit, like displayInNewsfeed - if the setting aligns with inherited setting, remove so it just uses inherited setting
            if (inheritedOptions) {
                // specific workaround for now for this property
                if (inheritedOptions?.displaySocialHeaders === displaySocialHeaders) {
                    // If this is not explicity set, the backend defaults to assigning a value to this upon creation...
                    // probably better to make this change on the backend so it doesn't assign true if this is not set
                    displaySocialHeaders = null;
                }

                try {
                    _.keys(advancedSettings).forEach(key => {
                        if ((_.has(inheritedOptions), key)) {
                            // if the settings are a match for inherited settings, don't explicity set them on card level
                            // This is an assumption....
                            if (advancedSettings[key] === inheritedOptions[key]) {
                                delete advancedSettings[key];
                            }
                            // For specific cards - postboxes, link tiles, etc - they have an inverted logic and must opt-out of the inverted inherited settings
                            if (
                                OPT_IN_TYPES.includes(payload.type) &&
                                advancedSettings[key] === false &&
                                advancedSettings[key] == !!inheritedOptions[key]
                            ) {
                                delete advancedSettings[key];
                            } else if (advancedSettings[key] == !!inheritedOptions[key]) {
                                delete advancedSettings[key];
                            }
                        }
                    });
                } catch (e) {
                    logger.error(e);
                }
            }

            // NEWSFEED_OMIT_TYPES probably should be renamed explicitly that it refers to the displayInNewsfeed property...
            // These cards never show up in the newsfeed
            if (NEWSFEED_OMIT_TYPES.includes(payload?.type)) {
                payload.displayInNewsfeed = false;
            }

            const variables = {
                ...advancedSettings,
                ...payload,
                inheritSurveyAnswers: inheritedOptions?.inheritSurveyAnswers || null,
                customFields,
            };

            return variables;
        }
    };

    const submitAdminAnnouncement = async cardUrl => {
        const { body } = state;
        const payload = constructPayload(true);
        const result = await addAdminAnnouncement({
            variables: {
                cardUrl,
                message: body,
                input: { ...payload },
            },
        });

        return result;
    };

    const submitPost = (displaySocialHeaders, sortType, postResAction) => {
        const payload = constructPayload(displaySocialHeaders);
        attachedCard({
            variables: { ...payload },
            update: (cache, data) => {
                updateCacheAfterSubmit(cache, data, feedUrl, sortType);
            },
        })
            .then(res => {
                postResAction(res);
            })
            .catch(e => logger.error('attach card error', e));
    };

    useEffect(() => {
        if (allAllowedTypes.length === 1) {
            const [cardType] = allAllowedTypes;
            const attachType = cardToAttachType[cardType];

            if (cardType === CARD_LINK || cardType === CARD_VIDEO) {
                expandAttachment(attachType);
            } else if (cardType === CARD_IMAGE_SQUARE) {
                handleSquarePhotoSelect();
            } else if (FILE_PICKER_ATTACH_TYPES.includes(attachType)) {
                handleFileSelect(attachType);
            } else {
                openModalHandler(cardType); // form modal opened here
            }
        }
    }, []);

    return {
        allAllowedTypes,
        addAttachedContent,
        clearAttachment,
        expandAttachment,
        handleFileSelect,
        handleAnonymousToggle,
        handleChange,
        handleSetCustomFields,
        handleAddToList,
        handleRemoveFromList,
        handleFetchLink,
        handleLinkInputChange,
        handleSetState,
        handleTextAreaChange,
        handleTitleChange,
        handleSquarePhotoSelect,
        handleToggle,
        handleImgToggle,
        state,
        setState,
        submitPost,
        submitAdminAnnouncement,
        submitNewPostloading,
        updateCacheAfterSubmit,
        truncateAttachment,
    };
};
export default useNewPost;
