import { z } from 'zod';
import { CARD_CREATION } from '@core/utilities/constants/card_types';

import {
    RainbowRoadBuilderMainState,
    RainbowRoadBuilderHeaderState,
    RainbowRoadBuilderBodyState,
    RainbowRoadBuilderNodesState,
    RainbowRoadNodeState,
} from '@core/types/RainbowRoad';

import { ImageWithLoadingState, Maybify } from '@core/types/Utilities';
import { ContentCard } from '@shared/welibrary-graphql/types';

export type QuestFormMainState = RainbowRoadBuilderMainState;

export type QuestFormCreationNodesState = Omit<RainbowRoadNodeState, 'title'> & {
    useSpinnerImage: boolean;
    spinnerImage: ImageWithLoadingState;
};

export type QuestFormNodesState = RainbowRoadBuilderNodesState & {
    useSpinnerImage: boolean;
    spinnerImage: ImageWithLoadingState;
};

export type QuestFormQuestSettingsState = {
    creationsChannel?: Maybify<ContentCard>;
    completionRequirement?: number;
    dialogsChannel?: Maybify<ContentCard>;
    questEndImage: string;
};

export type QuestFormVCState = {
    title: string;
    name: string;
    description: string;
    image: string;
};

export type QuestFormState = {
    main: QuestFormMainState;
    header: RainbowRoadBuilderHeaderState;
    body: RainbowRoadBuilderBodyState;
    nodes: QuestFormNodesState;
    creationNodes: QuestFormCreationNodesState;
    questSettings: QuestFormQuestSettingsState;
    vc: QuestFormVCState;
};

export type QuestMapFormMainState = RainbowRoadBuilderMainState;

export type QuestMapFormQuestSettingsState = {
    dialogsChannel?: Maybify<ContentCard>;
};

export type QuestMapFormState = {
    main: QuestMapFormMainState;
    header: RainbowRoadBuilderHeaderState;
    body: RainbowRoadBuilderBodyState;
    nodes: QuestFormNodesState;
    questSettings: QuestMapFormQuestSettingsState;
};

export type QuestNodeState = RainbowRoadNodeState & {
    preOrPost?: 'pre' | 'post';
    useSpinnerImage: boolean;
    spinnerImage: ImageWithLoadingState;
};

export type QuestMapNodeState = RainbowRoadNodeState & {
    useSpinnerImage: boolean;
    spinnerImage: ImageWithLoadingState;
};

export const QuestCreation = z.object({
    _id: z.string(),
    url: z.string(),
    type: z.literal(CARD_CREATION),
    title: z.string(),
    media: z.object({
        icon: z.string(),
        thumb: z.string(),
    }),
    creationItems: z.object({
        silhouetteImage: z.string().optional(),
        levels: z.array(z.object({ image: z.string() })),
    }),
});
export type QuestCreationType = z.infer<typeof QuestCreation>;

export const QuestNode = z.object({
    title: z.string().optional(),
    icon: z.string().optional(),
    backgroundImage: z.string().optional(),
    spinnerImage: z.string().optional(),
    showMetaInformation: z.boolean().optional(),
    preOrPost: z.string().optional(),
    alias: z.object({
        __typename: z.literal('AliasItem'),
        url: z.string(),
        type: z.string(),
        title: z.string().optional(),
        color: z.string().optional(),
        thumb: z.string().optional(),
        icon: z.string().optional(),
        item: z.object({
            _id: z.string(),
            url: z.string(),
            type: z.string(),
            title: z.string(),
            description: z.string().nullable().default(''),
            body: z.string().nullable().default(''),
            author: z.object({
                profile: z.object({
                    full_name: z.string(),
                }),
            }),
            media: z.object({
                icon: z.string(),
                thumb: z.string(),
            }),
            reference: z
                .object({
                    href: z.string().optional(),
                    bookSource: z
                        .object({
                            title: z.string().optional(),
                            media: z
                                .object({
                                    thumb: z.string().optional(),
                                })
                                .optional(),
                        })
                        .optional(),
                })
                .optional(),
            creationItems: z.object({
                silhouetteImage: z.string().optional(),
                levels: z.array(z.object({ image: z.string() })),
            }),
        }),
    }),
});
export type QuestNodeType = z.infer<typeof QuestNode>;

export const QuestDialog = z.object({
    event: z.string(),
    people: z.array(z.object({ image: z.string(), name: z.string() })),
    dialogs: z.array(z.object({ person: z.number(), text: z.string() })),
});
export type QuestDialogType = z.infer<typeof QuestDialog>;
