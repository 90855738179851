import { z } from 'zod';

import {
    GATracking,
    FilestackConfig,
    UnsplashConfig,
    FirebaseConfig,
    TorusConfig,
    StripeConfig,
    HomeButtonConfig,
    HomeGroupsConfig,
    ThemeConfig,
    SlideMenuConfig,
    SecondaryNavigationConfig,
    DomainBranding,
    PledgeConfig,
    LocizeConfig,
    CeramicConfig,
    AppFlowSettingsConfig,
    MetaInfoConfig,
    AwsMetricSettingsConfig,
    Captcha,
} from '@config/schemas/supplemental';

export const Config = z.object({
    public: z.object({
        root_url: z.string().default('localhost:3000'),
        apiUrl: z.string().default('localhost:4000'),
        whitelabel_url: z.string().optional(),
        deep_link_domains: z.array(z.string()).optional(),
        optionalPostTypes: z.array(z.string()).optional(),
        eventTypes: z.array(z.string()).optional(),
        // setting this to false will turn off display of platform formspec, even if it exists
        displayPlatformFormspec: z.boolean().optional(),
        siteName: z.string().optional(),
        emailFrom: z.string().optional(),
        favicon: z.string().optional(),
        configName: z.enum([
            'croaktest',
            'development-local',
            'development-ssl',
            'ioe',
            'lego-ssl',
            'production-welibrary',
            'staging-welibrary',
            'production-commissioned',
            'production-itz',
            'staging-commissioned',
            'production-nickv',
            'production-lausanne',
            'production-connect',
        ]),
        metaInfo: MetaInfoConfig.optional(),

        universal_access_code: z.string().optional(),

        version: z.string().default('v5.9.3'),
        copyright: z.string().default('WeLbrary, LLC'),

        filestack: FilestackConfig,
        unsplash: UnsplashConfig,
        captcha: Captcha,
        GATracking: GATracking.optional(),
        Google: z.object({ Maps_API_Key: z.string() }),
        sentry: z.object({ dsn: z.string() }).optional(),
        userflow: z.object({ api_key: z.string() }).optional(),
        showGettingStarted: z.boolean().optional(), // Show a button on the nav to trigger a 'Getting Started' userflow (WE-1641)
        firebaseConfig: FirebaseConfig.optional(),
        torus: TorusConfig.optional(),
        stripe: StripeConfig.optional(),

        // Is this even used?
        defaultMembershipUrl: z.string().optional(),
        defaultJoinInstructions: z.string().optional(),
        defaultGroupCodePlaceholder: z.string().optional(),
        defaultGroupCode: z.string().optional(),
        defaultCoverPhoto: z.string().optional(),
        restrictSignupToDefaultMembership: z.boolean().optional(),
        disableInlinePrefilledSignupLink: z.boolean().optional(),
        communityGuidelines: z.string().optional(),
        privacyPolicy: z.string(),
        termsOfService: z.string(),
        templatesUrl: z.string().default('templates'),

        userGuide: z.string().optional(),

        defaultGroupChannelTemplateUrl: z.string().optional(),
        defaultChannelTemplateUrl: z.string().optional(),

        allowBlankBookCreation: z.boolean().optional(),

        defaultLogoBackup: z.string().default('/images/logo.welibrary.full.white.svg'),
        defaultLogoBackupWhite: z.string().default('/images/logo.welibrary.blue.white.svg'),
        defaultLogoBackupWhiteAlt: z.string().default(''),
        defaultLogoBlack: z.string().default('/images/logo.welibrary.blue.svg'),
        hideGroupHeaderInfoFromLoggedOutUsers: z.boolean().optional(),
        homeButton: HomeButtonConfig.optional(), // can be removed soon
        homeGroups: HomeGroupsConfig?.optional(),
        secondaryNavigation: SecondaryNavigationConfig.optional(),

        // Native-specific
        pushSenderID: z.number(),
        pushLogo: z.string(),

        // Deprecated
        recommendationsMatchmakerWhitelist: z.array(z.string()).default([]),

        userSnap: z.boolean().optional(),
        userSnapOverrideSrc: z.string().optional(),
        userSnapTesterEmails: z.array(z.string()).optional(),

        bulkUserTemplate: z
            .string()
            .default(
                'https://docs.google.com/spreadsheets/d/1kkqURN4VHJuj6KZVMMSRRuWGdV_KvdWz5BeoYj9q1SQ/edit?usp=sharing'
            ),
        bulkGenerateUserTemplate: z
            .string()
            .default(
                'https://docs.google.com/spreadsheets/d/1LSN6MLgMmyHToHGpA4EEHZFtGPjLWxn3GsmbgM71Rdw/edit?usp=sharing'
            ),

        // Should these be deprecated in favor of theme?
        brandColor1: z.string().default('#0094f6'),
        brandColor2: z.string().default('#7a0cff'),

        theme: ThemeConfig.optional(),

        htmlHeader: z.array(z.string()).optional(),

        // Where is this used? I think just emails server-side
        companyAddress: z.string(),

        pwaServiceWorker: z.string().optional(),

        pledge: PledgeConfig.optional(),

        allowAnonymousSignups: z.boolean().optional(),
        hidePlatformGroupHeaderDisplay: z.boolean().optional(),

        main_quest_url: z.string().optional(),
        main_quest_map: z.string().optional(),

        // Domain-specific overrides
        domainBranding: z.record(DomainBranding),

        slideMenu: SlideMenuConfig.optional(),

        root_redirect: z.string().optional(),
        root_redirect_native: z.string().optional(),
        root_redirect_loggedin: z.string().optional(),
        private_route_redirect: z.string().optional(),
        logout_redirect: z.string().optional(),

        locize: LocizeConfig,

        ceramic: CeramicConfig.optional(),

        appflow: AppFlowSettingsConfig.optional(),

        awsMetrics: AwsMetricSettingsConfig.optional(),

        hideClaimEphemeralAccountToast: z.boolean().optional(),

        rootGroupId: z.string().optional(),
        hideRootGroupDeleteButton: z.boolean().optional(),

        eventOrganizerTermsOfUseUrl: z.string().optional(),
        ticketPurchaserTermsOfUseUrl: z.string().optional(),
        launchDarklyConfig: z.object({
            clientSideID: z.string().optional(),
            context: z.object({
                kind: z.string(),
                key: z.string(),
            }),
        }),
        poweredBy: z
            .object({
                name: z.string().optional(),
                logo: z.string().optional(),
            })
            .optional(),
    }),
});

export type ConfigType = z.infer<typeof Config>;
export type ConfigInputType = z.input<typeof Config>;
