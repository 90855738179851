import React from 'react';
import useModal from '@components/modals/hooks/useModal';
import useUniversalWallet from '@web/utilities/hooks/wallet/useUniversalWallet';
import { useCurrentUser } from '@stores/User';

import RealVCModal from '@components/lego/modals/RealVCModal';
import SearchIcon from '@assets/icon.white.search3.svg';
import ZeroStateImage from '@assets/LegoNoImageResultsFace.svg';

import { isLegoBadgeImage } from '@helpers/lego/credential.helpers';

import { BadgeRecord } from '@core/types/Credential';

const TreasureBadgesTab = React.forwardRef<HTMLElement, { badges: BadgeRecord[] }>(
    function TreasureBadgesTab({ badges }, ref) {
        const { newModal } = useModal();
        const { currentUser } = useCurrentUser();
        const { walletInstance } = useUniversalWallet();

        const openCredentialModal = async (badge: BadgeRecord) => {
            newModal(<RealVCModal badge={badge} user={currentUser} wallet={walletInstance} />, {
                className: 'lego-credential-modal no-notch',
                hideButton: true,
                customCloseButton: true,
                customCloseButtonClass:
                    'mobile-lego-btn-visible lego-credential-modal-close-button',
            });
        };

        const numBadges = badges.length;
        return (
            <section className="badges-tab px-[20px]" ref={ref}>
                <h2 className="text-white text-[36px] font-[400] m-0">
                    {numBadges} Badge{numBadges !== 1 ? 's' : ''}
                </h2>
                {numBadges > 0 && (
                    <div className="mt-[20px] flex gap-[20px] flex-wrap overflow-scroll">
                        {badges.map((badge, index) => {
                            const imageUrl = badge.credential.credentialSubject.achievement.image;
                            return (
                                <button
                                    role="button"
                                    key={index}
                                    className="relative h-[100px] w-[100px] border-solid border-white border-[3px] bg-[#2F80ED] rounded-[20px] flex items-center justify-center overflow-hidden"
                                    onClick={() => openCredentialModal(badge)}
                                >
                                    <img
                                        src={SearchIcon}
                                        alt="view"
                                        className="absolute top-[2px] right-[2px] h-[30px] w-[30px] bg-black bg-opacity-[0.2] rounded-[30px] p-[4px]"
                                    />
                                    {imageUrl && (
                                        <img
                                            className={`h-[100%] w-[100%] ${isLegoBadgeImage(imageUrl) ? 'object-contain' : ''
                                                }`}
                                            src={imageUrl}
                                            alt="Badge"
                                        />
                                    )}
                                    {!imageUrl && (
                                        <img
                                            className="h-[80%] w-[80%]"
                                            src={ZeroStateImage}
                                            alt="Badge"
                                        />
                                    )}
                                </button>
                            );
                        })}
                    </div>
                )}
            </section>
        );
    }
);

export default TreasureBadgesTab;
