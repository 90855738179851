import React from 'react';

import SpeakerDisplayInfo from '@components/content/newpost/forms/EventGroup/SpeakerDisplayInfo';

import { SpeakerStateType } from '@core/types/EventGroup';

type SpeakersSettingsProps = {
    state: SpeakerStateType[];
    onClick: () => void;
    onUserClick?: (userId: string) => void;
};

const SpeakersSettings: React.FC<SpeakersSettingsProps> = ({ state, onClick, onUserClick }) => {
    return (
        <div className="form-group event-location-options">
            <section className="title-desc">
                <h4>Speakers</h4>

                <p>Who will be presenting in this session?</p>
            </section>

            <div className="tp-gen-add-container">
                <button type="button" onClick={() => onClick()}>
                    <div className="edit-speakers-txt">
                        <p>Add or Edit Speakers to this session</p>
                    </div>
                    <div className="circle-plus-icon-dark animate-grow">
                        <div />
                        <div />
                    </div>
                </button>
            </div>

            <section className="speaker-list">
                {state?.map(speaker => (
                    <SpeakerDisplayInfo
                        key={speaker._id}
                        speaker={speaker}
                        onClick={speaker => onUserClick?.(speaker._id)}
                    />
                ))}
            </section>
        </div>
    );
};

export default SpeakersSettings;
