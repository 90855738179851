import React, {ChangeEventHandler} from 'react';
import {useTranslation} from 'react-i18next';

import {CustomSignupField} from '@shared/welibrary-graphql/types';

type ParagraphProps = {
    name: string;
    field: CustomSignupField & { translatedOptions: CustomSignupField['options'] };
    value?: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement>;
    maxLength?: number;
    showPrompt?: boolean;
    lightBackground?: boolean;
};

const Paragraph: React.FC<ParagraphProps> = ({
    name,
    field: { prompt, required },
    value = '',
    onChange,
    maxLength = 5000,
    showPrompt = false,
    lightBackground = false,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`custom-signup-item ${!showPrompt && required ? 'required' : ''}`}>
            {showPrompt && (
                <div className="custom-signup-label">
                    <span>{prompt}</span>
                    {required && <span className="required"> *</span>}
                </div>
            )}

            <textarea
                value={value}
                onChange={onChange}
                id={name}
                name={name}
                key={name}
                placeholder={
                    lightBackground ? t('common:input.question.placeholder') : prompt ?? ''
                }
                maxLength={maxLength}
                className={
                    lightBackground
                        ? 'optimize-text-field w-input'
                        : 'login-text-field w-input survey-longtext-height'
                }
            />
        </div>
    );
};

export default Paragraph;
