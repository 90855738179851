import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@stores/User';
import _ from 'lodash';
import { useShareLink } from '@components/generic/hooks/useShareLink';
import AnimateHeight from '@components/generic/animation/AnimateHeight';
import Modal from '@components/generic/lightbox/Modal.js';
import ContentCard from '@components/card/ContentCard';
import ContentCardMenu from '@components/card/ContentCardMenu';
import useSideDrawer from '@components/generic/hooks/useSideDrawer';

import { VOTING_LEADERBOARD } from '@core/utilities/constants/voting_types.js';
import { TYPE_COMMENTS } from '@components/sidebar/sidebar_types.js';
import useModal from '@components/modals/hooks/useModal';
import ContactList from '@components/messaging/shareContent/ContactList';
// import { useMessagesActionsContext } from '@components/messaging/MessagesContext';

import { getTimeSince } from '@core/utilities/utility_functions';
import { MenuListItem } from '@core/types/Menus';
import { ContentCard as ContentCardType, VoteSettings, LibrarySettings } from '@shared/welibrary-graphql/types';

type EngagementControllerProps = {
    items: {
        id: string;
        order: number;
        renderButton: ({
            card,
            setContactList,
            handleToggle,
            iconText,
            index,
            isActive,
        }: {
            card: ContentCardType;
            setContactList?: () => void;
            handleToggle?: (type: string) => void;
            iconText?: string;
            index?: number;
            isActive?: boolean;
        }) => JSX.Element;
        renderDrawer?: ({
            card,
            handleClose,
        }: {
            card?: ContentCardType;
            handleClose?: () => void;
        }) => JSX.Element | null;
    }[];
    card: ContentCardType;
    defaultOpenDrawer?: boolean;
    forceLoadComments?: any;
    chapterSettings?: LibrarySettings;
    hideEngagement?: boolean;
    hideTopRightDropdown?: boolean;
    handleOpenEngagementModal: () => void;
    handleCloseEngagementModal: () => void;
    showEngagementModal?: boolean;
    activeModal?: string;
    expandComments?: boolean | null;
    voteSettings: VoteSettings;
    voteType: string;
    justified: boolean;
    showReferenceHeader: boolean;
    displaySocialHeader: boolean;
    getTopRightList: (
        updateCard_unused: any,
        showAuthor_unused: any,
        flagContent: (input: { variables: { url: string; reason: string } }) => void,
        bookmarkCard: (input: { variables: { url: string; remove: boolean } }) => void,
        archiveCard: (input: { variables: { url: string; unarchive: boolean } }) => void
    ) => MenuListItem[];
    areTopRightDropdownDotsInverted: boolean;
    hideShareButton?: boolean;
};

const EngagementController: React.FC<EngagementControllerProps> = ({
    items,
    card,
    defaultOpenDrawer,
    chapterSettings,
    hideEngagement,
    hideTopRightDropdown,
    handleCloseEngagementModal,
    showEngagementModal,
    expandComments,
    voteSettings,
    voteType,
    justified,
    showReferenceHeader,
    displaySocialHeader,
    getTopRightList,
    areTopRightDropdownDotsInverted,
    hideShareButton = false,
}) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();

    const cardUrl = card.url;
    const { shareLink } = useShareLink();

    type stateType = {
        activeDrawer?: string | null;
        activeModalDrawer?: string | null;
        height?: number | 'auto';
        showModal?: boolean | null | undefined;
    };

    const initialState: stateType = {
        activeDrawer: null,
        activeModalDrawer: expandComments ? 'comment' : null,
        height: 0,
        showModal: expandComments,
    };
    // const { setAttachment } = useMessagesActionsContext();

    const [state, setState] = useState(initialState);
    const { newModal } = useModal();

    const { toggleDrawer } = useSideDrawer();

    const handleSetState = (updatedValues: stateType) => {
        setState(prevState => {
            return { ...prevState, ...updatedValues };
        });
    };

    const handleActiveDrawer = (newDrawer: string) => {
        if (state.activeDrawer !== newDrawer) {
            handleSetState({
                activeDrawer: newDrawer,
                height: 'auto',
            });
        }
    };

    useEffect(() => {
        if (defaultOpenDrawer) {
            handleActiveDrawer('comments');
        }
    }, []);

    const handleCloseDrawer = () => {
        handleSetState({
            height: 0,
        });
    };

    const handleToggleDrawer = (newDrawer: string) => {
        const { height, activeDrawer } = state;
        if (height === 0 || activeDrawer !== newDrawer) {
            handleActiveDrawer(newDrawer);
        } else {
            handleCloseDrawer();
        }
    };

    const handleCloseModal = () => {
        handleCloseEngagementModal();
        handleSetState({
            showModal: false,
        });
    };

    const setNullDrawer = (newHeight: { newHeight: string | number }) => {
        if (newHeight.newHeight === 0 || newHeight.newHeight === '0') {
            handleSetState({
                activeDrawer: null,
            });
        }
    };

    const handleToggleComments = () => {
        const options = {
            card,
            chapterSettings,
            voteSettings,
            sliderTitle: 'Comments',
        };
        toggleDrawer(TYPE_COMMENTS, options, 'shrink', card?._id);
    };

    const handleToggleSelector = (id: string) => {
        if (id === 'comment') {
            return handleToggleComments;
        }
        return handleToggleDrawer;
    };

    const { height } = state;

    const setContactList = () => {
        newModal(<ContactList card={card} />, { className: 'contact-list-set-height' });
    };

    let buttonItems = items.map((item, index) => {
        if (hideShareButton && item.id === 'share') {
            return null;
        }

        if (item) {
            return item.renderButton({
                card,
                handleToggle: handleToggleSelector(item.id),
                handleClose: () => handleCloseDrawer(),
                handleOpen: handleActiveDrawer,
                handleShare: shareLink,
                isActive: state.activeDrawer === item.id,
                index,
                currentUser,
                setContactList,
            });
        }
        return null;
    });

    const drawerItem = _.find(items, { id: state.activeDrawer ?? '' });

    const renderedDrawer = drawerItem
        ? drawerItem.renderDrawer({
              card,
              handleClose: () => handleCloseDrawer(),
              activeDrawer: state.activeDrawer,
              cardUrl,
              currentUser,
          })
        : null;

    // not great way of doing this but works for now
    const modalDrawerItem = _.find(items, {
        id: showEngagementModal ? 'comment' : state.activeModalDrawer ?? '',
    });
    const activeModalDrawer = showEngagementModal ? 'comment' : state.activeModalDrawer;
    const renderedModalDrawer = modalDrawerItem
        ? modalDrawerItem.renderDrawer({
              card,
              handleClose: () => handleCloseDrawer(),
              activeDrawer: activeModalDrawer,
              cardUrl,
              currentUser,
          })
        : null;

    if (voteType === VOTING_LEADERBOARD) {
        buttonItems = [];
    }

    // If there is nothing in the engagement container, return empty.
    // if (!items || (items.length <= 0 && voteType === VOTING_NONE)) return <div></div>;

    // not particularly ideal but probably will do until I can do a complete refactor of the way modal is handled
    if (hideEngagement || hideTopRightDropdown) {
        return (
            <div>
                <Modal
                    // smallModal={this.state.deleteMode}
                    delayTime={500}
                    className="modal"
                    isMounted={showEngagementModal}
                    show={showEngagementModal}
                    close={handleCloseModal}
                >
                    <div className="engagement-modal">
                        <div className="notch-filler-overlay" />
                        <div className="engagement-modal-flex-container">
                            <div className="overlay-module-header">
                                <div
                                    onClick={handleCloseModal}
                                    className="conent-card-detail-back-button w-inline-block"
                                />
                                <span className="modal-header-text">
                                    {' '}
                                    <a onClick={handleCloseModal}> Return to Feed</a>
                                </span>
                            </div>
                            <ContentCard
                                chapterSettings={chapterSettings}
                                currentUser={currentUser}
                                viewType="fullview"
                                voteSettings={voteSettings}
                                card={card}
                                hideTopRightDropdown
                                hideEngagement
                                transparentBackground
                                topGreyBorder={false}
                            />

                            {renderedModalDrawer}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    return (
        <div className="engagement-container">
            <div className="engagement-footer">
                <div className={`engagement-col ${justified ? 'justified' : ''}`}>
                    <div className="engagement-inner shrink">
                        <div className="mc-source-sub footer-date">
                            {getTimeSince(
                                card.date?.last_update,
                                t(
                                    'common:imports.wlWeb.ui.components.generic.notifications.notification.just_now'
                                ),
                                true
                            )}
                        </div>
                    </div>
                    <div className="engagement-inner shrink">
                        {buttonItems}
                        <ContentCardMenu
                            positioningClass="bottom-dropdown"
                            card={card}
                            showReferenceHeader={showReferenceHeader}
                            displaySocialHeader={displaySocialHeader}
                            getTopRightList={getTopRightList}
                            areTopRightDropdownDotsInverted={areTopRightDropdownDotsInverted}
                        />
                    </div>
                </div>
            </div>

            <AnimateHeight duration={500} height={height} onAnimationEnd={setNullDrawer}>
                <div className="engagement-drawer-wrapper">
                    <div className="engagement-drawer-container">
                        <div className="drawer-section">{renderedDrawer}</div>
                    </div>
                </div>
            </AnimateHeight>
        </div>
    );
};

export default EngagementController;
