import React from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import TicketPrices from '@components/events/TicketPrices';
import Picture from '@components/generic/Picture';
import CalendarDateDisplay from '@components/events/CalendarDateDisplay';
import { InPersonDisplay, VirtualDisplay } from '@components/card/dynamiccard/LocationDisplay';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';
import {
    VIEW_PERMISSION_PUBLIC,
    VIEW_PERMISSION_PRIVATE,
    VIEW_PERMISSION_SECRET,
} from '@core/utilities/constants/roles';
import {
    getFormattedDateDisplayFromTimes,
    getOverviewTime,
    getEarliestDay,
    getLatestDay,
} from '@core/utilities/constants/events';

import { LocationType } from '@shared/welibrary-graphql/types';
import { EventGroupFormState, EventGroupFormSettingsState } from '@core/types/EventGroup';

const getPrivacyString = (t: TFunction, privacyLevel: string): string => {
    if (privacyLevel === VIEW_PERMISSION_PUBLIC) return t('common:public_event', 'Public Event');

    if (privacyLevel === VIEW_PERMISSION_PRIVATE) return t('common:private_event', 'Private Event');

    if (privacyLevel === VIEW_PERMISSION_SECRET) return t('common:secret_event', 'Secret Event');

    return '';
};

type EventGroupCardPreviewProps = {
    state: EventGroupFormState;
    groupSettingsState: EventGroupFormSettingsState;
    displayRawPrices: boolean;
    ticketCurrency?: string;
};

const EventGroupCardPreview: React.FC<EventGroupCardPreviewProps> = ({
    state,
    groupSettingsState,
    displayRawPrices = false,
    ticketCurrency
}) => {
    const { t } = useTranslation();

    const dates = state?.dateAndTimeState?.dates;

    const earliestDay = getEarliestDay(dates, dates[0].startDate);
    const latestDay = getLatestDay(dates, dates[0].endDate);

    const updatedDateAndTimeState = {
        ...state.dateAndTimeState,
        startTime: getOverviewTime(new Date(state?.dateAndTimeState?.startTime), earliestDay),
        endTime: getOverviewTime(new Date(state?.dateAndTimeState?.endTime), latestDay),
    };
    const datesDisplay = getFormattedDateDisplayFromTimes(updatedDateAndTimeState);

    const { eventLocationSettings } = state.overviewFormState;
    const locationType = eventLocationSettings.type;
    const virtualLink = eventLocationSettings.link;
    const formatted_address = eventLocationSettings?.venue?.location?.formatted_address || '';

    const hideAttendeeCount = groupSettingsState?.hideAttendeeCount;

    return (
        <div className="content-card-container  we-mobile-topgreyborder home-newsfeed false">
            <div className="event-group-card-container">
                <section className="event-image-with-metadata ">
                    <a className="width-100">
                        <Picture
                            disableLink
                            url={
                                state?.overviewFormState?.thumbnail ||
                                getRandomDefaultCoverImage(state?.overviewFormState?.title ?? '')
                            }
                            className="event-image"
                        />
                    </a>
                    <CalendarDateDisplay dates={state?.dateAndTimeState?.dates} />
                </section>
                <section className="event-group-detail-section">
                    <section className="event-group-card-info ">
                        <span
                            className={`event-privacy-bubble ${state.groupSettingsState.protectionLevel} desktop`}
                        >
                            {getPrivacyString(t, state.groupSettingsState.protectionLevel)}
                        </span>
                        <span className="date">{datesDisplay}</span>
                        <a>
                            <h3 className="title">{state.overviewFormState.title}</h3>
                        </a>
                        {(locationType === LocationType.InPerson ||
                            locationType === LocationType.VirtualAndInPerson) &&
                            formatted_address && (
                                <span className="venue">
                                    <InPersonDisplay source={eventLocationSettings} />
                                </span>
                            )}
                        {(locationType === LocationType.Virtual ||
                            locationType === LocationType.VirtualAndInPerson) &&
                            virtualLink && (
                                <span className="venue">
                                    <VirtualDisplay source={eventLocationSettings} />
                                </span>
                            )}
                        <div className="tickets-and-attendees">
                            <TicketPrices
                                products={state?.ticketFormState?.products}
                                displayRawPrices={displayRawPrices}
                                currency={ticketCurrency}
                            />
                            {state?.ticketFormState?.products &&
                                !hideAttendeeCount &&
                                state?.ticketFormState?.products?.length > 0 && (
                                    <span className="dot-separator">•</span>
                                )}
                            {!hideAttendeeCount && (
                                <span className="attendee-count">1 Attendee</span>
                            )}
                        </div>
                    </section>
                    <section className="event-action-buttons ">
                        <a className="event-details-button button">Details</a>
                    </section>
                </section>
            </div>
        </div>
    );
};

export default EventGroupCardPreview;
