import React from 'react';
import {withTranslation} from 'react-i18next';
import {getRootUrl} from '@core/utilities/whitelabel_helpers';

const localNamespace = 'imports.wlWeb.ui.components.card.modules.referral';

class Referral extends React.Component {
    //basic referral module probably takes some data - links, title

    getShareUrl(cardId) {
        if (!cardId) return 'Sharing not supported for this content.';
        return `https://${getRootUrl()}/c/${cardId}`;
    }

    getFacebookShareUrl(cardId) {
        let cardShareUrl = this.getShareUrl(cardId);
        return 'https://www.facebook.com/sharer/sharer.php?href=' + cardShareUrl;
    }

    render() {
        const { t, card } = this.props;

        return (
            <div>
                <div style={{ paddingBottom: '20px' }}>
                    <div className="display-text-inline">
                        {t(`common:${localNamespace}.link_prompt`)} •{' '}
                    </div>
                    <a onClick={this.props.handleCloseDrawer} className="display-text-inline">
                        {t(`common:${localNamespace}.close`)}
                    </a>
                </div>

                <div className="drawer-contents">
                    <div className="drawer-section">
                        <div className="relative w-form">
                            <input
                                readOnly
                                value={this.getShareUrl(card._id)}
                                type="text"
                                className="media-post-field sharelink w-input"
                                name="Share-Link-3"
                                style={{ backgroundColor: '#ececec', cursor: 'text' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Referral);
