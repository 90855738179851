import React from 'react';

export default function (props) {
    const { card } = props;

    let classModifier = 'we-header-card';

    return (
        <div className={'we-header-card-container'}>
            <div className={`browse-module-title ${classModifier}`}>{card.title}</div>
        </div>
    );
}
