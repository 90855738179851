import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LIST_CHILD_GROUPS } from '@shared/welibrary-graphql/user/queries';
import useInfiniteScroll from '@components/generic/hooks/useInfiniteScroll';
import { QueryResult } from '@apollo/client';
import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import IntroContent from '@web/ui/components/generic/IntroContent';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/square-loader.json';
import ModalLoading from '@components/modals/ModalLoading';
import GroupListItem from '@components/group/groupdashboard/GroupListItem';
import Picture from '@web/ui/components/generic/Picture';
import {
    ListChildGroupsQuery,
    ListChildGroupsQueryVariables,
} from '@shared/welibrary-graphql/user/queries.hook';
import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';
import { Group } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.group.groupDashboard';

const GroupSubgroups: React.FC<{ group: Group }> = ({ group }) => {
    const { t } = useTranslation();

    const modalLoadingText = t(`common:${localNamespace}.groups_loading`, 'Loading Groups');

    // This is used instead of the default pagination logic in useInfiniteScroll because the data shape is different from the usual
    const nextPage = (
        queryResult: QueryResult<ListChildGroupsQuery, ListChildGroupsQueryVariables>
    ) => {
        if (queryResult?.data?.groupById?.childGroups?.hasMore && !queryResult.loading) {
            queryResult.fetchMore({
                variables: {
                    cursor: queryResult?.data?.groupById?.childGroups?.cursor,
                },
            });
        }
    };

    const {
        queryResult: { data, loading },
        setRef,
    } = useInfiniteScroll({
        query: {
            query: LIST_CHILD_GROUPS,
            options: {
                variables: {
                    _id: group?._id,
                },
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                skip: !group?._id,
            },
        },
        customNextPage: nextPage,
    });

    const subGroupTxt = group?.childGroups?.results?.length === 1 ? 'Subgroup' : 'Subgroups';

    const subGroupText = group?.totalChildGroups ? `${group?.totalChildGroups} ${subGroupTxt}` : '';

    return (
        <ErrorBoundary>
            <section className="group-members-form group-list">
                <header>
                    <Picture
                        url={
                            group?.profile?.picture ||
                            getRandomDefaultCoverImage(group?.profile?.full_name)
                        }
                        disableLink
                        resolutions={[130, 260, 390]}
                    />
                    <div className="group-desc">
                        <h1>{group?.profile?.full_name}</h1>
                        <p>{subGroupText}</p>
                    </div>
                </header>
                {!loading && data && (
                    <GroupSubgroupsList ref={setRef} data={data?.groupById?.childGroups?.results} />
                )}

                {loading && !data && <ModalLoading message={modalLoadingText} />}
            </section>
        </ErrorBoundary>
    );
};

export default GroupSubgroups;

type GroupSubgroupsListProps = {
    data: Group[];
};

const GroupSubgroupsList = React.forwardRef<HTMLUListElement, GroupSubgroupsListProps>(
    ({ data }, ref) => {
        const { t } = useTranslation();
        const history = useHistory();

        const handleGroupClick = (groupId: string) => {
            if (groupId) {
                history?.push(`/g/${groupId}`);
            }
        };

        return (
            <ul>
                {(data?.length ?? 0) > 0 ? (
                    data?.map((group, currentIndex) => {
                        return (
                            <GroupListItem
                                onClick={handleGroupClick}
                                group={group}
                                key={group?._id || currentIndex}
                                ref={currentIndex === data?.length - 1 ? ref : undefined}
                            />
                        );
                    })
                ) : (
                    <IntroContent declaration="No Subgroups Found" type="nocontent" />
                )}
                {data?.hasMore && (
                    <div className="flex-center">
                        <LottieLoading height={50} width={300} lottieData={LottieAnimation} />
                    </div>
                )}
            </ul>
        );
    }
);
