import React from 'react';

import IntroContent from '@web/ui/components/generic/IntroContent';
import NotifyPanelSubGroupItem from '@web/ui/components/engagement/NotifyPanelSubGroupItem';

import { PaginatedGroups } from '@shared/welibrary-graphql/content_card/queries.hook';

const NotifyPanelSubGroupList: React.FC<{
    subgroups: PaginatedGroups;
    selectedGroups: string[];
    handleAddSubGroup: (id: string) => void;
    handleRemoveSubGroup: (id: string) => void;
}> = ({ subgroups, selectedGroups, handleAddSubGroup, handleRemoveSubGroup }) => {
    return (
        <ul className="notify-panel-subgroup-list">
            {subgroups?.results?.length > 0 ? (
                subgroups?.results?.map(subgroup => (
                    <NotifyPanelSubGroupItem
                        key={subgroup._id}
                        subgroup={subgroup}
                        selectedGroups={selectedGroups}
                        handleAddSubGroup={handleAddSubGroup}
                        handleRemoveSubGroup={handleRemoveSubGroup}
                    />
                ))
            ) : (
                <IntroContent type="nocontent" />
            )}
        </ul>
    );
};

export default NotifyPanelSubGroupList;
