const CARD_ACTIONS = {
    FOLLOW: 'follow',
    UNFOLLOW: 'unfollow',
    SUBSCRIBE: 'subscribe',
    UNSUBSCRIBE: 'unsubscribe',
    OPEN_JOIN: 'openJoin',
    MEMBER_JOIN: 'memberJoin',
    ADMIN_JOIN: 'adminJoin',
    EXIT_MEMBERSHIP: 'exitMembership',
    CANCEL_JOIN_REQUEST: 'cancelJoinRequest',
};

export { CARD_ACTIONS };
