import React, { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import {
    useCreateProductMutation,
    useUpdateProductsMutation,
    useSetIsMembershipPaidMutation,
} from '@shared/welibrary-graphql/user/mutations.hook';

import useModal from '@components/modals/hooks/useModal';
import useSubscriptionConfirmation from '@components/generic/hooks/useSubscriptionConfirmation';
import { useConvertCurrencyLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';

import LoadingLine from '@components/generic/loading/LoadingLine';
import TicketQuickView from '@components/content/newpost/forms/ShoppingCartProducts/TicketQuickView';
import TicketForm from '@components/content/newpost/forms/ShoppingCartProducts/TicketForm';
import RadioButton from '@components/generic/forms/RadioButton';

import { CARD_EVENT } from '@core/utilities/constants/card_types';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';
import {
    GET_GROUP_DASHBOARD,
    GET_PRODUCTS_FOR_GROUP,
} from '@shared/welibrary-graphql/user/queries';
import {
    DELETE_SUBSCRIPTION_PRODUCT_AND_CANCEL_SUBSCRIPTIONS_IN_BULK,
    UPDATE_SUBSCRIPTION_PRODUCT,
} from '@shared/welibrary-graphql/user/mutations';
import { Product, CouponType } from '@shared/welibrary-graphql/types';
import { Group } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';
import { getCurrencyDisplayString } from '~/wl-web/utilities/helpers/money/money.helpers';

const logger = getLogger(module);

const localNamespace = 'import.wlWeb.ui.components.content.newPost.ticketsForm';

const TicketsFormContainer: React.FC<{
    group: Group;
    productData: Partial<Product>[];
    merchantAccountFullyActivated?: boolean;
    inEditMode?: boolean;
    activateMerchantAccount?: () => void;
}> = ({
    group,
    productData,
    inEditMode,
    merchantAccountFullyActivated,
    activateMerchantAccount = () => {},
}) => {
    const { t } = useTranslation();
    const { closeModal, closeAllModals, newModal } = useModal();
    const confirm = useSubscriptionConfirmation();

    const isEvent = group?.subtype === CARD_EVENT;
    const productTypeText = isEvent ? 'Tickets' : 'Subscriptions';
    const headerTitle = isEvent ? 'New Ticket' : 'New Subscription';
    const productNamePlaceholder = isEvent ? 'Ticket Name *' : 'Subscription Name *';

    const [isPaid, setIsPaid] = useState<boolean>(
        group?.paymentSettings?.isGroupMembershipPaid ?? false
    );

    const [tickets, setTickets] = useImmer<Partial<Product>[] | []>(
        productData?.map(ticket => ({
            ...ticket,
            price: Number(((ticket.price ?? 100) / 100).toFixed(2)),
        })) ?? []
    );
    const [ticketCurrency, setTicketCurrency] = useState<string>(tickets?.[0]?.currency ?? 'USD');
    const [invalidTickets, setInvalidTickets] = useState<Partial<Product>[] | []>([]);
    const [priceError, setPriceError] = useState<string>('');

    useEffect(() => {
        handleUpdateTicketCurrency();
    }, [ticketCurrency]);

    const [setIsMembershipPaid, { loading: toggleIsLoading }] = useSetIsMembershipPaidMutation();

    const [createProduct, { loading }] = useCreateProductMutation();
    const [updateProducts, { loading: updateLoading }] = useUpdateProductsMutation();
    const [updateSubscriptionProduct, { loading: loadingSubscriptionUpdate }] = useMutation(
        UPDATE_SUBSCRIPTION_PRODUCT
    );
    const [
        deleteSubscriptionProductAndCancelSubscriptionsInBulk,
        { loading: loadingSubscriptionDeletion },
    ] = useMutation(DELETE_SUBSCRIPTION_PRODUCT_AND_CANCEL_SUBSCRIPTIONS_IN_BULK);

    const [convertCurrency] = useConvertCurrencyLazyQuery();

    const handleTogglePaid = async (selectedOption: string) => {
        const isToggled = selectedOption === 'paid';
        if (isToggled === isPaid) {
            return;
        }

        setIsPaid(isToggled);

        await setIsMembershipPaid({
            variables: {
                groupId: group?._id,
                paymentSettings: {
                    isGroupMembershipPaid: isToggled,
                },
            },
            refetchQueries: [GET_GROUP_DASHBOARD, GET_PRODUCTS_FOR_GROUP],
        });
    };

    const addTicket = (newProduct: Partial<Product>) => {
        setTickets([...tickets, newProduct]);
    };

    const updateTicket = async (ticket: Partial<Product>, index: number, cb) => {
        if (!isEvent) {
            if (inEditMode) {
                const priceChangeDetected = ticket.price !== tickets[index]?.price;
                const priceUpgradeDetected =
                    priceChangeDetected && ticket.price > tickets[index]?.price;
                const priceDowngradeDetected =
                    priceChangeDetected && ticket.price < tickets[index]?.price;

                const currencyChangeDetected =
                    ticket.currency?.toLowerCase() !== tickets[index]?.currency.toLowerCase();

                let updateText = 'Updating this subscription will update all active subscriptions.';

                if (priceUpgradeDetected) {
                    updateText = `A price increase was detected. Updating this subscription will result in upgrading all active subscriptions in the next pay period. In addition all active subscribers will be notified of recent price changes.`;
                    if (currencyChangeDetected) {
                        updateText = `A currency change & price increase was detected. Existing subscriptions will remain unchanged until cancelled. New subscriptions will reflect the updated currency & price increase.`;
                    }
                } else if (priceDowngradeDetected) {
                    updateText = `A price decrease was detected. Updating this subscription will result in downgrading all active subscriptions in the next pay period. In addition all active subscribers will be notified of recent price changes.`;
                    if (currencyChangeDetected) {
                        updateText = `A currency change & price decrease was detected. Existing subscriptions will remain unchanged until cancelled. New subscriptions will reflect the updated currency & price decrease.`;
                    }
                } else if (currencyChangeDetected) {
                    updateText = `A currency change was detected. Existing subscriptions will remain unchanged until cancelled. New subscriptions will reflect the updated currency.`;
                }

                const confirmed = await confirm({
                    title: 'Are you sure you want to update this subscription?',
                    text: updateText,
                    confirmationButtonText: 'Update',
                });
                if (confirmed) {
                    updateSubscriptionProduct({
                        variables: {
                            input: {
                                productId: ticket?._id,
                                name: ticket.name ?? '',
                                price: Math.round(Number(ticket.price || 1) * 100),
                                description: ticket.description,
                                currency: ticket.currency ?? 'USD',
                                type: PRODUCT_TYPES.GROUP_SUBSCRIPTION,
                                fulfillment: { groupId: group?._id, role: 'member' },
                            },
                        },
                    }).then(() => {
                        const _tickets = [...tickets];
                        _tickets[index] = ticket;
                        setTickets([..._tickets]);
                        cb();
                    });
                }
            } else {
                const _tickets = [...tickets];
                _tickets[index] = ticket;
                setTickets([..._tickets]);
                cb();
            }
        } else {
            const _tickets = [...tickets];
            _tickets[index] = ticket;
            setTickets([..._tickets]);
            cb();
        }
    };

    const deleteTicket = async (index: number, closeCurrentModal = false) => {
        if (!isEvent) {
            if (inEditMode) {
                const confirmed = await confirm({
                    title: 'Are you sure you want to delete this subscription?',
                    text: 'Deleting this subscription will cancel all active subscriptions. In addition refunds will be issued where applicable.',
                    confirmationButtonText: 'Delete',
                });
                if (confirmed) {
                    deleteSubscriptionProductAndCancelSubscriptionsInBulk({
                        variables: {
                            groupId: group?._id,
                            productId: tickets[index]?._id,
                        },
                        refetchQueries: [GET_GROUP_DASHBOARD, GET_PRODUCTS_FOR_GROUP],
                    })
                        .then(() => {
                            setTickets(draft => {
                                draft.splice(index, 1);
                            });
                            closeAllModals();
                        })
                        .catch(err => logger.error(err));
                }
            } else {
                setTickets(draft => {
                    draft.splice(index, 1);
                });
                if (closeCurrentModal) closeModal();
            }
        } else {
            setTickets(draft => {
                draft.splice(index, 1);
            });

            const ticketToDelete = tickets.find((t, idx) => idx === index);

            setInvalidTickets([
                ...invalidTickets?.filter(t => {
                    return t?.price !== ticketToDelete?.price && t?.name !== ticketToDelete?.name;
                }),
            ]);

            if (closeCurrentModal) closeModal();
        }
    };

    // handles updating all existing tickets with the selected currency type
    const handleUpdateTicketCurrency = () => {
        const _tickets = [
            ...tickets.map(t => {
                return {
                    ...t,
                    currency: ticketCurrency,
                };
            }),
        ];
        setTickets([..._tickets]);
    };

    const handleNewTicketModal = () => {
        newModal(
            <TicketForm
                group={group}
                groupId={group?._id}
                groupSubtype={group?.subtype}
                addTicket={addTicket}
                updateTicket={updateTicket}
                deleteTicket={index => deleteTicket(index, true)}
                inEditMode={false}
                headerTitle={headerTitle}
                productNamePlaceholder={productNamePlaceholder}
                ticketCurrency={ticketCurrency}
                setTicketCurrency={setTicketCurrency}
            />,
            { className: 'no-bottom-padding', hideButton: true }
        );
    };

    const handleEditTicketModal = (ticketIndex: number) => {
        setInvalidTickets([]);
        const ticket = tickets.find((_element, index) => index === ticketIndex);

        newModal(
            <TicketForm
                group={group}
                index={ticketIndex}
                groupId={group?._id}
                groupSubtype={group?.subtype}
                ticket={ticket}
                addTicket={() => {}}
                updateTicket={updateTicket}
                deleteTicket={index => deleteTicket(index, true)}
                inEditMode
                headerTitle={headerTitle}
                productNamePlaceholder={productNamePlaceholder}
                ticketCurrency={ticketCurrency}
                setTicketCurrency={setTicketCurrency}
            />,
            { className: 'no-bottom-padding', hideButton: true }
        );
    };

    const validateTicketsBeforeSubmit = async () => {
        const { data: minCurrencyCharge } = await convertCurrency({
            variables: {
                to: ticketCurrency,
                from: 'USD',
                amount: '1',
            },
            fetchPolicy: 'network-only',
        });

        setPriceError(
            getCurrencyDisplayString(
                Number(minCurrencyCharge?.convertCurrency?.value),
                ticketCurrency,
                undefined,
                true,
                2
            )
        );

        const _tickets = tickets?.filter(
            t =>
                t?.type !== PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP &&
                t?.type !== PRODUCT_TYPES.GROUP_FREE_SUBSCRIPTION
        ); // filter out all paid tickets

        const invalidTickets = _tickets?.filter(
            t => t.price < minCurrencyCharge?.convertCurrency?.value
        );

        if (invalidTickets?.length === 0) return true;

        setInvalidTickets([...invalidTickets]);
        return false;
    };

    const onSubmit = async () => {
        const ticketsAreValid = await validateTicketsBeforeSubmit();

        if (!ticketsAreValid) return;

        // temporary default product types
        // for events -> PRODUCT_TYPES.GROUP_MEMBERSHIP -> tickets
        // for groups with paid subscriptions -> PRODUCT_TYPES.GROUP_SUBSCRIPTION -> subscriptions
        const defaultProductType = isEvent
            ? PRODUCT_TYPES.GROUP_MEMBERSHIP
            : PRODUCT_TYPES.GROUP_SUBSCRIPTION;

        const _tickets = tickets.map(ticket => {
            if (ticket._id) {
                // omit updating free ticket type
                if (ticket?.type === PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP) {
                    return {
                        productId: ticket?._id,
                        name: ticket.name ?? '',
                        price: 0,
                        description: ticket.description,
                        currency: ticketCurrency, // defaulted to USD
                        type: PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP,
                        fulfillment: { groupId: group?._id, role: 'member' },
                        coupons:
                            (ticket?.coupons?.length ?? 0) > 0
                                ? ticket?.coupons!.map(coupon => ({
                                      name: coupon?.name ?? '',
                                      code: coupon?.code ?? '',
                                      percentage: coupon?.percentage ?? 1,
                                      type: coupon?.type ?? CouponType.Default,
                                  }))
                                : [],
                    };
                }

                if (ticket?.type === PRODUCT_TYPES?.GROUP_FREE_SUBSCRIPTION) {
                    return {
                        productId: ticket?._id,
                        name: ticket.name ?? '',
                        price: 0,
                        description: ticket.description,
                        currency: ticketCurrency, // defaulted to USD
                        type: PRODUCT_TYPES?.GROUP_FREE_SUBSCRIPTION,
                        fulfillment: { groupId: group?._id, role: 'member' },
                        coupons: [],
                    };
                }

                // updates existing product / ticket
                return {
                    productId: ticket._id,
                    name: ticket.name ?? '',
                    price: Math.round(Number(ticket.price || 1) * 100),
                    description: ticket.description,
                    currency: ticketCurrency, // defaulted to USD
                    type: defaultProductType,
                    fulfillment: { groupId: group?._id, role: 'member' },
                    coupons:
                        (ticket?.coupons?.length ?? 0) > 0
                            ? ticket.coupons!.map(coupon => ({
                                  name: coupon?.name ?? '',
                                  code: coupon?.code ?? '',
                                  percentage: coupon?.percentage ?? 1,
                                  type: coupon?.type ?? CouponType.Default,
                              }))
                            : [],
                };
            }

            // add new product / ticket
            return {
                name: ticket.name ?? '',
                price: Math.round(Number(ticket.price || 1) * 100),
                description: ticket.description,
                currency: ticketCurrency, // defaulted to USD
                type: defaultProductType,
                fulfillment: { groupId: group?._id, role: 'member' },
                coupons:
                    (ticket?.coupons?.length ?? 0) > 0
                        ? ticket.coupons!.map(coupon => ({
                              name: coupon?.name ?? '',
                              code: coupon?.code ?? '',
                              percentage: coupon?.percentage ?? 1,
                              type: coupon?.type ?? CouponType.Default,
                          }))
                        : [],
            };
        });

        if (inEditMode) {
            updateProducts({
                variables: {
                    inputs: _tickets,
                    upsert: true,
                    groupId: group?._id,
                },
                refetchQueries: [GET_GROUP_DASHBOARD, GET_PRODUCTS_FOR_GROUP],
            })
                .then(() => {
                    closeModal();
                })
                .catch(err => logger.error(err));
        } else {
            _tickets.forEach(ticket => {
                createProduct({
                    variables: { input: ticket },
                    refetchQueries: [GET_GROUP_DASHBOARD, GET_PRODUCTS_FOR_GROUP],
                }).catch(err => logger.error(err));
            });
        }

        closeModal();
    };

    const submitButtonText: string = inEditMode
        ? `Update ${productTypeText}`
        : `Create ${productTypeText}`;
    const loadingText = inEditMode ? 'Updating...' : 'Creating...';

    const disableAddingSubscriptions = !isEvent && !merchantAccountFullyActivated;
    const disableAddingMoreProducts = !isEvent && tickets.length >= 1;

    let addTicketsButton: null | React.ReactNode;

    if (isEvent && !isPaid) {
        addTicketsButton = null;
    } else if ((isEvent && isPaid) || !disableAddingMoreProducts) {
        addTicketsButton = (
            <div className="ticket-new-button">
                <h3>New {isEvent ? 'Ticket' : 'Subscription'}</h3>
                <button
                    disabled={disableAddingSubscriptions}
                    onClick={handleNewTicketModal}
                    type="button"
                >
                    +
                </button>
            </div>
        );
    }

    const ticketsList = tickets?.map((ticket, index) => {
        if (isEvent) {
            if (
                (isPaid && ticket.type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP) ||
                (!isPaid && ticket.type !== PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP)
            ) {
                return null;
            }

            if (!isPaid && ticket.type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP) {
                return (
                    <TicketQuickView
                        ticket={ticket}
                        index={index}
                        key={index}
                        deleteTicket={deleteTicket}
                        handleEditTicketModal={handleEditTicketModal}
                        ticketCurrency={ticketCurrency}
                    />
                );
            }
        }

        if (ticket?.type === PRODUCT_TYPES?.GROUP_FREE_SUBSCRIPTION) return null;

        return (
            <TicketQuickView
                ticket={ticket}
                index={index}
                key={index}
                deleteTicket={deleteTicket}
                handleEditTicketModal={handleEditTicketModal}
                ticketCurrency={ticketCurrency}
                invalidTickets={invalidTickets}
            />
        );
    });

    return (
        <div>
            <LoadingLine
                isLoading={
                    toggleIsLoading || loadingSubscriptionDeletion || loadingSubscriptionUpdate
                }
            />
            <div className="ticket-form-wrapper">
                <div className="ticket-form-wrapper-header">
                    <div className="ticket-form-wrapper-header-title-wrap">
                        <h3 className="ticket-form-wrapper-header-title">
                            {productTypeText} & Pricing
                            <br />
                            {merchantAccountFullyActivated && <span>Required Fields *</span>}
                        </h3>

                        {isEvent && (
                            <RadioButton
                                name="isPaid"
                                options={[
                                    {
                                        text: t(`common:${localNamespace}.free`, 'Free'),
                                        value: 'free',
                                    },
                                    {
                                        text: t(`common:${localNamespace}.paid`, 'Paid'),
                                        value: 'paid',
                                    },
                                ]}
                                selected={isPaid ? 'paid' : 'free'}
                                handleRadio={handleTogglePaid}
                            />
                        )}
                    </div>

                    {disableAddingSubscriptions && (
                        <p>
                            <strong>
                                Note: Please activate your merchant account in order to
                                <br /> enable subscriptions.{' '}
                                <button
                                    type="button"
                                    onClick={activateMerchantAccount}
                                    className="ticket-form-activate-merchant-btn"
                                >
                                    Activate Merchant Account
                                </button>
                            </strong>
                        </p>
                    )}
                </div>

                {invalidTickets?.length > 0 && priceError && (
                    <div className="mt-2 flex w-full items-center justify-center">
                        <div className="w-full">
                            <p
                                className={`m-0 w-full rounded-[12px] border-[1px] border-solid border-red-400 bg-[#f6f7f9] p-0 px-[16px] py-[12px] font-bold text-red-400`}
                            >
                                One or more tickets, priced in {ticketCurrency}, fall below the
                                specified minimum charge amount of {priceError}
                            </p>
                        </div>
                    </div>
                )}

                {ticketsList}
            </div>
            {addTicketsButton}
            {!isEvent && inEditMode && tickets.length >= 1 ? null : (
                <div className="ticket-form-submit-btn-wrap">
                    <button
                        className="ticket-form-submit-btn"
                        onClick={onSubmit}
                        type="button"
                        disabled={loading || updateLoading || disableAddingSubscriptions}
                    >
                        {loading || updateLoading ? loadingText : submitButtonText}
                    </button>
                </div>
            )}
        </div>
    );
};

export default TicketsFormContainer;
