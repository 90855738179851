import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';

import signUpStore, {
    GROUP_MEMBERSHIP_STATUS_JOINED,
    GROUP_MEMBERSHIP_STATUS_REQUEST_TO_JOIN,
} from '@stores/SignUp';

import GroupSummary from '@pages/new-signup/GroupSummary';
import CaretIcon from '@dsc/svgs/LeftCaretIcon';

import {
    StyledBackgroundImage,
    getBackgroundImageSetForUrl,
} from '@core/utilities/constants/defaults';
import { getWhitelabelImages } from '@core/utilities/whitelabel_helpers';
import PostSignupGroupJoined from './PostSignupGroupJoined';
import { POST_SIGNUP_STEPS } from './PostSignup';
import { Group } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.pages.post-signup.layout';

type PostSignupGroupsJoinedAndRequestedProps = {
    containerClassName?: string;
    innerContainerClass?: string;
    className?: string;
    showWhiteLabelLogo?: boolean;
    suppressModal?: boolean;
};

const PostSignupGroupsJoinedAndRequested: React.FC<PostSignupGroupsJoinedAndRequestedProps> = ({
    containerClassName,
    innerContainerClass,
    className,
    showWhiteLabelLogo = true,
    suppressModal = true,
}) => {
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [mainLogo, unused, whiteLogoAlt, defaultCoverPhoto] = getWhitelabelImages();
    const isNativeApp = Capacitor.isNativePlatform();

    const groupsJoinedAndRequested = signUpStore.useTracked.groupsJoinedAndRequested();
    const mainGroupJoined = signUpStore.get.activeGroup();
    const currentStep = signUpStore.useTracked.postSignupStep();
    const groupsWithQuestions = signUpStore.useTracked.groupsWithQuestions();
    const groupQuestionsIndex = signUpStore.useTracked.groupQuestionsIndex();
    const groupCarouselIndex = signUpStore.useTracked.groupCarouselIndex();

    const isGroupQuestionsStep = currentStep === POST_SIGNUP_STEPS.groupQuestions;

    let activeGroupIndex: number;
    let activeGroupId: string;
    let activeGroup: { group: Group; status: string };
    let maxGroupsJoinedAndRequestedIndex: number;
    const minGroupJoinedAndRequestIndex = 0;

    if (isGroupQuestionsStep) {
        activeGroupIndex = groupQuestionsIndex;
        activeGroupId = groupsWithQuestions[activeGroupIndex];
        activeGroup = groupsJoinedAndRequested.find(g => g.group._id === activeGroupId);

        maxGroupsJoinedAndRequestedIndex = groupsWithQuestions.length - 1;
    } else {
        activeGroupIndex = groupCarouselIndex;
        activeGroupId = groupsJoinedAndRequested?.[activeGroupIndex].group._id;
        activeGroup = groupsJoinedAndRequested[activeGroupIndex];

        maxGroupsJoinedAndRequestedIndex = groupsJoinedAndRequested.length - 1;
    }

    const handleShowNextGroup = () => {
        if (groupCarouselIndex !== maxGroupsJoinedAndRequestedIndex) {
            signUpStore.set.groupCarouselIndex(groupCarouselIndex + 1);
        }
    };

    const handleShowPrevGroup = () => {
        if (groupCarouselIndex !== minGroupJoinedAndRequestIndex) {
            signUpStore.set.groupCarouselIndex(groupCarouselIndex - 1);
        }
    };

    const groupsJoinedAndRequestedLength =
        groupsJoinedAndRequested.length ?? minGroupJoinedAndRequestIndex;
    const activeGroupIndexDisplay = activeGroupIndex + 1;

    let groupJoinText;
    if (activeGroup?.group.subtype === 'event') {
        groupJoinText = t(
            `common:${localNamespace}.you_will_be_redirected_to`,
            'You will be redirected to'
        );
    } else if (activeGroup?.status === GROUP_MEMBERSHIP_STATUS_JOINED) {
        groupJoinText = t(`common:${localNamespace}.haveJoined`, 'You have joined');
    } else if (activeGroup?.status === GROUP_MEMBERSHIP_STATUS_REQUEST_TO_JOIN) {
        groupJoinText = t(`common:${localNamespace}.requestToJoin`, 'You have requested to join');
    }

    const backgroundImage = activeGroup?.group?.profile?.coverPhoto || defaultCoverPhoto;
    const [src, srcSet] = backgroundImage ? getBackgroundImageSetForUrl(backgroundImage, 800) : [];

    const hideLeftArrow = isGroupQuestionsStep || activeGroupIndex === 0;
    const hideRightArrow =
        isGroupQuestionsStep || activeGroupIndex === maxGroupsJoinedAndRequestedIndex;
    const groupsController = (
        <div className="w-full flex gap-[20px] items-center justify-center">
            <button
                type="button"
                onClick={handleShowPrevGroup}
                className={hideLeftArrow ? 'invisible' : ''}
                disabled={hideLeftArrow}
            >
                <CaretIcon className="text-white w-[30px]" color="#ffffff" />
            </button>

            <h4 className="text-white text-[20px] font-poppins tracking-[0.25px] font-[500]">
                {activeGroupIndexDisplay} of{' '}
                {isGroupQuestionsStep ? groupsWithQuestions.length : groupsJoinedAndRequestedLength}
            </h4>

            <button
                type="button"
                onClick={handleShowNextGroup}
                className={hideRightArrow ? 'invisible' : ''}
                disabled={hideRightArrow}
            >
                <CaretIcon className="text-white w-[30px] rotate-180" color="#ffffff" />
            </button>
        </div>
    );

    if (groupsJoinedAndRequested?.length === 0) return <></>;

    const isEvent = activeGroup?.group?.subtype === 'event';
    const isSubscriptionGroup = activeGroup?.group?.doesSellMembership && !isEvent;

    if (
        mainGroupJoined &&
        activeGroup?.group?._id === mainGroupJoined?._id &&
        isSubscriptionGroup
    ) {
        return (
            <PostSignupGroupJoined
                showWhiteLabelLogo={showWhiteLabelLogo}
                containerClassName={containerClassName}
                innerContainerClass={innerContainerClass}
                className={className}
                groupsController={groupsController}
                suppressModal={suppressModal}
            />
        );
    }

    return (
        <section className={`w-0 md:w-[40%] flex flex-col items-center ${containerClassName}`}>
            {!isNativeApp && (
                <StyledBackgroundImage
                    className="animatedBackground bg-no-repeat bg-cover h-full"
                    src={src}
                    srcSet={srcSet}
                />
            )}
            <div className={`flex flex-col gap-[30px] max-w-[415px] ${className}`}>
                {showWhiteLabelLogo && (
                    <div className="flex justify-center">
                        <img src={whiteLogoAlt || mainLogo} alt="" className="" />
                    </div>
                )}

                <div
                    className={`bg-[rgba(19,_21,_23,_0.40);] p-[40px] rounded-[20px] flex flex-col gap-[20px] items-center ${innerContainerClass}`}
                >
                    <span className="text-white text-[17px] font-[500] leading-[24px] tracking-[0.25px] text-center">
                        {groupJoinText}
                    </span>
                    <GroupSummary group={activeGroup?.group} showX={false} />
                    {groupsController}
                </div>
            </div>
        </section>
    );
};

export default PostSignupGroupsJoinedAndRequested;
