import React, { useState, useEffect } from 'react';

import X from '@dsc/svgs/FullSizeX';
import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/rocket.json';

const PENDING_TIMEOUT = 20000;

const PendingFulfillment: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
    const showCloseButton = window.innerWidth > 991;
    const [isTakingTooLong, setIsTakingTooLong] = useState(false);

    // TODO: Test average latency for webhook ingestion of stripe event / have fallback notifications for user available.
    useEffect(() => {
        const timer = setTimeout(() => setIsTakingTooLong(true), PENDING_TIMEOUT);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="checkout-complete-wrap">
            {showCloseButton && (
                <button
                    type="button"
                    onClick={() => closeModal()}
                    className="checkout-modal-btn-close"
                >
                    <X className="checkout-modal-btn-x-dark" />
                </button>
            )}
            <LottieLoading
                height={125}
                width={125}
                lottieData={LottieAnimation}
                customStyle={{ opacity: 1 }}
                isPaused={false}
            />
            <h1>
                Fulfilling
                <br />
                Order...
            </h1>
            <button
                onClick={closeModal}
                type="button"
                className="checkout-processing-btn"
                disabled={!isTakingTooLong}
            >
                {isTakingTooLong
                    ? 'Sorry, this is taking a while. Notify me when ready'
                    : 'Processing'}
            </button>
        </div>
    );
};

export default PendingFulfillment;
