import { Capacitor } from '@capacitor/core';

import getLogger from '@core/logger';

const logger = getLogger(module);

export const canShare = () => {
    let _canShare = true;

    try {
        _canShare = Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Share');
    } catch (e) {
        logger.warn(e);
    }

    return _canShare || window.navigator.canShare;
};

export default {
    canShare,
};
