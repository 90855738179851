import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { awsMetricPublisher } from '@welibraryos/metrics';

import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';
import NewPostOverlay from '@web/ui/components/content/newpost/NewPostOverlay';
import DropdownController from '@web/ui/components/generic/dropdownmenu/DropdownController';
import CreateGroup from '@web/ui/components/group/CreateGroupViaTemplate';
import Modal from '@web/ui/components/generic/lightbox/Modal.js';
import CreateComposeMessageForm from '@components/messaging/CreateComposeMessageForm';
import { ModalTypes } from '@core/types/Modals';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER_FEED } from '@shared/welibrary-graphql/user/queries';

const localNamespace = 'imports.wlWeb.ui.components.navigation.navigationContainer.action_button';

const ActionButton = ({ className = '' }) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();

    const { newModal, closeModal } = useModal();

    const [openModal, setOpenModal] = useState(false);
    const canCreateGroup = currentUser?.isUserSuperAdmin;

    const {
        data: userFeedData,
        loading: userFeedLoading,
        error: userFeedError,
    } = useQuery(GET_CURRENT_USER_FEED, {
        fetchPolicy: 'cache-and-network',
    });

    const personalUserfeed = !userFeedLoading ? userFeedData?.currentUser?.personalUserfeed : null;

    const newPostProps = {
        allowedTypes: personalUserfeed?.library_settings?.postTypes,
        feedUrl: personalUserfeed?.url,
        displaySocialHeaders: personalUserfeed?.library_settings?.displaySocialHeaders,
        showConfirmation: true,
    };

    const openNewPostModal = () => {
        setOpenModal(true);
    };

    const closeNewPostModal = () => {
        setOpenModal(false);
    };

    const openNewMessage = () => {
        awsMetricPublisher.publishCount('action-button-menu', 1, [
            { Name: 'menu', Value: 'new-message' },
        ]);
        newModal(
            <CreateComposeMessageForm onClose={closeModal} onSubmitSuccess={closeModal} />,
            { className: 'compose-msg-modal' },
            {
                mobile: ModalTypes.Right,
                desktop: ModalTypes.Right,
            }
        );
    };

    const handleNewPostOnClick = () => {
        awsMetricPublisher.publishCount('action-button-menu', 1, [
            { Name: 'menu', Value: 'new-post' },
        ]);
        openNewPostModal();
    };

    const handleJoinGroupOnClick = () => {
        awsMetricPublisher.publishCount('action-button-menu', 1, [
            { Name: 'menu', Value: 'join-group' },
        ]);
        closeModal();
    };

    const handleCreateNewGroupOnClick = () => {
        awsMetricPublisher.publishCount('action-button-menu', 1, [
            { Name: 'menu', Value: 'create-new-group' },
        ]);
        newModal(<CreateGroup createSubgroup={false} parentId={null} />);
    };

    const menuList = [
        {
            id: 'new-message',
            title: t(`common:${localNamespace}.new_direct_message`, 'New Message'),
            onClick: () => openNewMessage(),
            iconKey: 'Airplane',
        },
    ];

    if (personalUserfeed) {
        menuList.push({
            id: 'new-post',
            title: t(`common:${localNamespace}.new_post`),
            iconKey: 'Plus',
            onClick: handleNewPostOnClick,
        });
    }

    menuList.push({
        id: 'join-group',
        title: t(`imports.wlWeb.ui.components.group.groupDashboard.join_group`),
        to: '/add-group',
        iconKey: 'Groups',
        onClick: handleJoinGroupOnClick,
    });

    if (canCreateGroup) {
        menuList.push({
            id: 'new-group',
            title: t(`common:${localNamespace}.new_group`),
            iconKey: 'Groups',
            onClick: handleCreateNewGroupOnClick,
            onClose: () => false,
        });
    }

    const handleButtonPress = () => {
        awsMetricPublisher.publishCount('action-button-open-menu', 1);
    };

    return (
        <>
            <DropdownController
                menuList={menuList}
                buttonClass={`primary-nav-action-button ${className}`}
                onClickCallback={handleButtonPress}
            />

            <Modal
                className="modal"
                delayTime={500}
                isMounted={openModal}
                close={closeNewPostModal}
                contentCard
            >
                <div className="new-post-overlay-wrapper">
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <NewPostOverlay closeModal={closeNewPostModal} {...newPostProps} />
                </div>
            </Modal>
        </>
    );
};

export default ActionButton;
