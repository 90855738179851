import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import shoppingCartStore, { PAYMENT_TYPES } from '@stores/ShoppingCart';
import useSellTicketsAtDoor from '../hooks/useSellTicketsAtDoor';

import TextInput from '@dsc/forms/textInputs/TextInput';
import SellTicketsFooter from './SellTicketsFooter';

import { getCurrencyDisplayString } from '@web/utilities/helpers/money/money.helpers';

import { Group } from '@shared/welibrary-graphql/types';

type SellTicketsPaymentDetailsProps = {
    group: Group;
};

const SellTicketsPaymentDetails: React.FC<SellTicketsPaymentDetailsProps> = ({ group }) => {
    const { t } = useTranslation();

    const [cardErr, setCardErr] = useState<any>(null);

    const [cash, setCash] = useState(0);

    const { total, totalDisplayString, paymentType, createOrderError, isProcessingOrder } =
        useSellTicketsAtDoor(group);
    const isCreditCard = paymentType === PAYMENT_TYPES.creditCard;

    useEffect(() => {
        setCardErr(createOrderError);
    }, [createOrderError]);

    const changeDue = (parseFloat(cash) || 0) - parseFloat(total);

    const cardStyle = {
        style: {
            base: {
                color: '#333',
                backgroundColor: '#F8F8F8',
                fontFamily: 'Roboto, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#adadad',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    const CARD_NUMBER_OPTIONS = {
        placeholder: 'Card Number',
        showIcon: true,
        iconStyle: 'solid',
        ...cardStyle,
    };
    const CARD_EXPIRY_OPTIONS = { placeholder: 'MM/YY', ...cardStyle };

    const activeTabClassName = 'bg-primary-default text-white rounded-[40px] grow py-[10px]';
    const inactiveTabClassName = 'text-grayscale-body grow py-[10px]';

    return (
        <section className="flex h-full flex-col">
            <div className="flex grow flex-col gap-[20px]">
                <h2 className="m-0 text-[24px] text-black">
                    {t(`common:global.payment_details`, 'Payment Details')}
                </h2>

                <div className="bg-grayscale-input-background flex rounded-[40px] text-[17px] font-[600] leading-[28px] tracking-[0.75px]">
                    <button
                        className={isCreditCard ? activeTabClassName : inactiveTabClassName}
                        onClick={() => shoppingCartStore.set.paymentType(PAYMENT_TYPES.creditCard)}
                    >
                        {t(`common:global.credit_card`, 'Credit Card')}
                    </button>
                    <button
                        className={!isCreditCard ? activeTabClassName : inactiveTabClassName}
                        onClick={() => shoppingCartStore.set.paymentType(PAYMENT_TYPES.cash)}
                    >
                        {t(`common:global.cash`, 'Cash')}
                    </button>
                </div>

                {isCreditCard && (
                    <div className="flex flex-col gap-[20px]">
                        <div className="customer-payments-container">
                            <div className="stripe-checkout-form">
                                <div className="stripe-checkout-form-element-wrap">
                                    <CardNumberElement
                                        className="stripe-checkout-form-element"
                                        onFocus={() => setCardErr(null)}
                                        onChange={event => {
                                            setCardErr(event.error);
                                        }}
                                        options={CARD_NUMBER_OPTIONS}
                                    />
                                </div>
                                <div className="stripe-checkout-form-elements">
                                    <div className="stripe-checkout-form-element-wrap">
                                        <CardExpiryElement
                                            className="stripe-checkout-form-element"
                                            onFocus={() => setCardErr(null)}
                                            onChange={event => {
                                                setCardErr(event.error);
                                            }}
                                            options={CARD_EXPIRY_OPTIONS}
                                        />
                                    </div>
                                    <div className="stripe-checkout-form-element-wrap">
                                        <CardCvcElement
                                            className="stripe-checkout-form-element"
                                            options={cardStyle}
                                            onFocus={() => setCardErr(null)}
                                            onChange={event => {
                                                setCardErr(event.error);
                                            }}
                                        />
                                    </div>
                                </div>
                                {cardErr && (
                                    <div className="customer-card-error-wrap">
                                        <p className="customer-card-error-text">
                                            x {cardErr.message || cardErr}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {!isCreditCard && (
                    <div className="text-grayscale-title-active flex flex-col gap-[20px] text-[17px]">
                        <div className="flex">
                            <span className="">
                                {t(`common:global.order_total`, 'Order Total')}
                            </span>
                            <span className="ml-auto font-[700]">{totalDisplayString}</span>
                        </div>
                        <div className="flex items-center">
                            <span className="">
                                {t(`common:global.cash_tendered`, 'Cash Tendered')}
                            </span>
                            <TextInput
                                type="number"
                                value={cash}
                                onChange={_cash => setCash(_cash)}
                                className="!my-0 !ml-auto !w-[160px]"
                                classes={{
                                    actuallyInput:
                                        '!py-[8px] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
                                }}
                                required
                            />
                        </div>
                        {cash > 0 && changeDue !== 0 && (
                            <div className="flex items-center">
                                <span className="">
                                    {t(`common:global.change_due`, 'Change Due')}
                                </span>
                                <span className="ml-auto text-[24px] font-[700]">
                                    {getCurrencyDisplayString(
                                        changeDue,
                                        group.products?.[0].currency,
                                        undefined,
                                        true,
                                        2
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <SellTicketsFooter
                group={group}
                disableNext={(isCreditCard && !!cardErr) || isProcessingOrder}
                hideAmount={!isCreditCard}
                nextButtonTextOverride={
                    isProcessingOrder ? t('common:global.one_moment', 'One moment...') : undefined
                }
            />
        </section>
    );
};

export default SellTicketsPaymentDetails;
