import produce from 'immer';

import WoodIcon from '@assets/resources/wood.png';
import BooksIcon from '@assets/resources/books.png';
import FlowersIcon from '@assets/resources/flowers.png';
import HeartIcon from '@assets/resources/heart.png';
import CrayonsIcon from '@assets/resources/crayons.png';

import { Resource, Scalars, Step, Level, UserCourseState, CreationState } from '@shared/welibrary-graphql/types';
import { PerformantMaybify } from '@core/types/Utilities';
import { CoursePageTab, LevelState } from '@core/types/Courses';
import { AggregateSkills, getAggregateSkillsFromArray } from '../lego/skill.helpers';

// TODO: whitelabel colors
export const ResourceMeta: Record<Resource, { icon: string; color: string; adjective: string }> = {
    [Resource.Wood]: { icon: WoodIcon, color: '#39B54A', adjective: 'physical' },
    [Resource.Books]: { icon: BooksIcon, color: '#EB5757', adjective: 'cognitive' },
    [Resource.Flowers]: { icon: FlowersIcon, color: '#56CCF2', adjective: 'social' },
    [Resource.Hearts]: { icon: HeartIcon, color: '#FFCF00', adjective: 'emotional' },
    [Resource.Crayons]: { icon: CrayonsIcon, color: '#9B51E0', adjective: 'creativity' },
};

export const isCoursePageTab = (tab?: string | string[] | null): tab is CoursePageTab =>
    !Array.isArray(tab) && Object.values(CoursePageTab).includes((tab as CoursePageTab) ?? '');

export const getActiveLevel = (page: string | string[] | null, levels: Level[]) => {
    const activeLevelIndex = typeof page === 'string' ? Number(page) - 1 : 0;
    const activeLevel = activeLevelIndex > -1 && levels.at(activeLevelIndex);

    return { activeLevel, activeLevelIndex };
};

export const displayAgeGroup = (ageGroup: Scalars['Range']) => {
    const lowAge = ageGroup[0] ?? 0;

    if (ageGroup[1] === null) return `${lowAge}+`;

    return `${lowAge}-${ageGroup[1]}`;
};

export const displayDuration = (duration: Scalars['Range']) => {
    const lowTime = duration[0] ?? 0;

    if (duration[1] === null) return `${duration}+`;

    if (lowTime === 0) return `Under ${duration[1]}`;

    return `${lowTime}-${duration[1]}`;
};

export const createStepPages = (steps: Step[]): Step[][] => {
    return steps.reduce<Step[][]>(
        (acc, step, index) =>
            produce(acc, draft => {
                if (step.fullPage) {
                    if (draft.at(-1)?.length === 0) draft.at(-1)?.push(step);
                    else draft.push([step]);

                    if (index !== steps.length - 1) draft.push([]);
                } else {
                    draft.at(-1)?.push(step);

                    if (step.pageBreak && index !== steps.length - 1) draft.push([]);
                }
            }),
        [[]]
    );
};

export const isLevelComplete = (
    index: number,
    state?: PerformantMaybify<UserCourseState> | PerformantMaybify<CreationState> | null
): boolean => index + 1 < (state?.level ?? 0);

export const getAggregateSkillsForLevel = (level: Level | LevelState): AggregateSkills => {
    return getAggregateSkillsFromArray([
        ...(level.steps ?? []),
        ...(level.preSurvey ?? []),
        ...(level.postSurvey ?? []),
    ]);
};

export const getAggregateSkillsForLevels = (levels: (Level | LevelState)[]): AggregateSkills => {
    const skills = (levels ?? []).reduce<AggregateSkills>(
        (aggregate, level) => {
            const levelSkills = getAggregateSkillsForLevel(level);

            aggregate.skills.push(...levelSkills.skills);
            aggregate.subskills.push(...levelSkills.subskills);
            aggregate.milestones.push(...levelSkills.milestones);

            return aggregate;
        },
        { skills: [], subskills: [], milestones: [] }
    );

    // Dedupe
    skills.skills = Array.from(new Set(skills.skills));
    skills.subskills = Array.from(new Set(skills.subskills));
    skills.milestones = Array.from(new Set(skills.milestones));

    return skills;
};
