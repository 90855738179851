import React from 'react';
import { useTranslation } from 'react-i18next';

import useQuickLinks from '@web/ui/components/navigation/SideMenu/Navigator/hooks/useQuickLinks';
import useModal from '@web/ui/components/modals/hooks/useModal';

import { awsMetricPublisher } from '@welibraryos/metrics';

import SearchForm from '@web/ui/components/search/form/SearchForm';
import QuickLink from '@web/ui/components/navigation/SideMenu/Navigator/QuickLink';
import AddQuickLinkButton from '@web/ui/components/navigation/SideMenu/Navigator/AddQuickLinkButton';
import QuickLinkDescription from '@web/ui/components/navigation/SideMenu/Navigator/QuickLinkDescription';
import DropdownController from '@web/ui/components/generic/dropdownmenu/DropdownController';

import { getInfoFromAlias } from '@web/utilities/helpers/aliases/alias.helpers';

import { MenuListItem } from '@core/types/Menus';
import { QuickLinkAlias } from '@core/types/QuickLinks';
import { useCurrentUser } from '@stores/User';

const localNamespace = 'imports.wlWeb.ui.components.navigation.slideMenu';

// This is a fancy type that basically says you can provide an index or home, but never both
type AliasQuickLinkProps =
    | { alias?: QuickLinkAlias; index: number; home?: never }
    | { alias?: QuickLinkAlias; index?: never; home: true };

const AliasQuickLink: React.FC<AliasQuickLinkProps> = ({ alias, index, home = false }) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();

    const { newModal } = useModal();
    const { getLinks, replaceQuickLink, removeQuickLink, toggleHome } = useQuickLinks(home);

    if (!alias) return <AddQuickLinkButton home={home} />;

    const info = getInfoFromAlias(alias, t, currentUser);

    if (!info) return <></>;

    const { to, picture, alt, title, type } = info;

    const actions: MenuListItem[] = [
        {
            id: 'remove',
            title: t(`common:${localNamespace}.remove_quick_link`),
            onClick: () => {
                removeQuickLink(index);
                awsMetricPublisher.publishCount('side-menu-quick-links', 1, [
                    { Name: 'menu', Value: 'remove' },
                ]);
            },
            onClose: () => false,
        },
        {
            id: 'move',
            title: t(`common:${localNamespace}.${home ? 'unpin' : 'pin_to_top'}`),
            onClick: () => {
                toggleHome(index);
                awsMetricPublisher.publishCount('side-menu-quick-links', 1, [
                    { Name: 'menu', Value: home ? 'unpin' : 'pin-to-top' },
                ]);
            },
            onClose: () => false,
        },
        {
            id: 'replace',
            title: t(`common:${localNamespace}.replace_quick_link`),
            onClick: () => {
                newModal(
                    <SearchForm
                        title={t(`common:${localNamespace}.replace_quick_link`)}
                        addResult={result => replaceQuickLink(result, index)}
                        suggestionsFilter={getLinks()?.quickLinks ?? []}
                        filters={['All', 'Channels', 'Groups', 'Profiles']}
                    />,
                    { className: 'no-bottom-padding' }
                );
                awsMetricPublisher.publishCount('side-menu-quick-links', 1, [
                    { Name: 'menu', Value: 'replace' },
                ]);
            },
            onClose: () => false,
        },
    ];

    return (
        <QuickLink
            to={to}
            picture={picture}
            alt={alt}
            home={home}
            isUnpublished={alias?.group?.isUnpublished}
        >
            <QuickLinkDescription title={title} type={type} home={home} />
            <DropdownController
                buttonClass="quick-link-menu button-reset content-button-icon dropdown-dots inverse-dots"
                inverted="nobg"
                noIcons
                menuList={actions}
                onClickCallback={() =>
                    awsMetricPublisher.publishCount('side-menu-open-quick-link-menu', 1)
                }
            />
        </QuickLink>
    );
};

export default AliasQuickLink;
