import React from 'react';

import Modal from '@web/ui/components/generic/lightbox/Modal.js';

const GroupMobilizationForm: React.FC<{
    form: React.ReactNode | null;
    showMobilizationForm: boolean;
    handleMobilizationFormModal: () => void;
}> = ({ form, showMobilizationForm, handleMobilizationFormModal }) => {
    return (
        <Modal
            className="modal"
            delayTime={500}
            isMounted={showMobilizationForm}
            close={handleMobilizationFormModal}
            contentCard
        >
            {form}
        </Modal>
    );
};

export default GroupMobilizationForm;
