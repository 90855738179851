import {useCallback, useEffect, useRef} from 'react';

/**
 * React hook for creating timers
 *
 * Use like so:
 *
 * const timer = useTimer();
 *
 * console.log('One');
 *
 * // Promise
 * await timer(1000);
 * console.log('Two');
 *
 * // Callback
 * timer(1000, () => console.log('Three'));
 *
 * This hook is useful because it automatically cleans up each call to setTimeout on unmount,
 * preventing memory leaks caused by state updates on unmounted components!
 *
 * @return {(time: number, callback?: () => void) => Promise<void>}
 */
const useTimer = () => {
    const timeouts = useRef([]);

    const timer = useCallback(
        (time, callback) =>
            new Promise(resolve => {
                timeouts.current.push(
                    setTimeout(() => {
                        callback?.();
                        resolve();
                    }, time)
                );
            }),
        []
    );

    useEffect(
        () => () => {
            timeouts.current.forEach(clearTimeout);
        },
        []
    );

    return timer;
};

export default useTimer;
