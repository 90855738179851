import React, { FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import useEditThreadParticipants from '@components/messaging/thread/hooks/useEditThreadParticipants';
import SuggestedList from '@components/messaging/messageOverlay/SuggestedList';

import X from '@web/ui/dsc/svgs/X';
import EditIcon from '@web/ui/dsc/svgs/EditIcon';

import MessageThreadActions from '@core/utilities/constants/thread';
import { UserParticipant, Maybe } from '@shared/welibrary-graphql/types';

const AddMembersWidget: React.FC<{
    state: Maybe<UserParticipant>[];
    setState: (newParticipants: Maybe<UserParticipant>[]) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}> = ({ state, setState, onFocus, onBlur }) => {
    const { t } = useTranslation();

    const [showMemberSearch, setShowMemberSearch] = useState(false);

    const { searchQuery, setSearchQuery, filteredUsers, addUserToThread, removeUserFromThread } =
        useEditThreadParticipants(state, setState);
    const emptySearchQuery = !searchQuery || searchQuery?.trim() === '';

    const messageUserList = state?.reduce((acc, contact) => {
        if (contact?.user._id) {
            return [...acc, contact?.user];
        }
        return acc;
    }, []);

    // This replaces the default text in the suggestion list.
    const customTextForCurrentUserList = {
        empty: ' ',
    };

    const handleFocus = () => {
        onFocus?.();
    };

    const handleResetSearch = () => {
        setSearchQuery('');
    };

    const handleSearchSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault();
    };

    const handleEditMembers = () => {
        if (!showMemberSearch) {
            onFocus?.();
            setShowMemberSearch(true);
        }

        if (showMemberSearch) {
            onBlur?.();
            setShowMemberSearch(false);
            setSearchQuery('');
        }
    };

    const displayIcon = !showMemberSearch ? <EditIcon /> : <X />;

    return (
        <ErrorBoundary>
            <section className="form-container members-search-widget">
                <section className="members-widget-header">
                    <h4>{`${state?.length} members`}</h4>
                    <button
                        onClick={handleEditMembers}
                        type="button"
                        className="round-square-btn animate-grow noselect"
                    >
                        {displayIcon}
                    </button>
                </section>
                <div className="search-field-container">
                    {showMemberSearch && (
                        <div className="search-inbox-container">
                            <form
                                id="search-messages-form"
                                name="search-messages-form"
                                onSubmit={handleSearchSubmit}
                                data-name="Search Messages Form"
                                className="search-field-container"
                            >
                                <input
                                    onChange={e => setSearchQuery(e.currentTarget.value)}
                                    value={searchQuery}
                                    onKeyPress={e => {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                    type="text"
                                    autoComplete="off"
                                    onFocus={handleFocus}
                                    className="search-field w-input inbox-search-input"
                                    maxLength={256}
                                    name="new-message-contact-search"
                                    placeholder={`${t(
                                        'common:global.search_to_add',
                                        'Search members'
                                    )}...`}
                                    id="Search-Group-Members"
                                />

                                <button
                                    type="button"
                                    onClick={handleResetSearch}
                                    className={
                                        searchQuery?.trim() !== ''
                                            ? 'clear-search-btn'
                                            : 'clear-search-btn hidden'
                                    }
                                >
                                    <X />
                                </button>
                            </form>
                        </div>
                    )}
                </div>
                {!emptySearchQuery && (
                    <SuggestedList
                        wholeItemClick={false}
                        handleClick={addUserToThread}
                        showMsgUser
                        suggestType={MessageThreadActions.ADD_TO_MESSAGE}
                        suggestions={filteredUsers}
                        searchQuery={searchQuery}
                    />
                )}

                {emptySearchQuery && (
                    <SuggestedList
                        wholeItemClick={false}
                        showMsgUser
                        handleClick={removeUserFromThread}
                        suggestType={MessageThreadActions.REMOVE_THREAD_MEMBER}
                        suggestions={messageUserList}
                        customText={customTextForCurrentUserList}
                    />
                )}
            </section>
        </ErrorBoundary>
    );
};

export default AddMembersWidget;
