// Custom Linkify Plugin for Remarkable to handle Capacitor InAppBrowserWebLinks (and other options)...
// Adapted from: https://github.com/jonschlinkert/remarkable/blob/master/lib/linkify.js
// Autoconvert URL-like texts to links
//
// Currently restricted by `inline.validateLink()` to http/https/ftp

import Autolinker from 'autolinker';
import _ from 'lodash';
import { getInAppBrowserOptions, getRootUrl } from '@core/utilities/whitelabel_helpers';

import { Capacitor } from '@capacitor/core';

import {
    MENTION_ALL,
    MENTION_ADMINS,
} from '@components/messaging/thread/hooks/useThreadParticipants';

export const EXTERNAL_BROWSER_WHITELIST = [
    'atlassian.net',
    'welibrary.atlassian.net',
    'chat.whatsapp.com',
    'docs.google.com',
    'facebook.com',
    'github.com',
    'instagram.com',
    'twitter.com',
    'zoom.us',
];

export const isOnExternalBrowserWhitelist = to => {
    const isLinkOnWhiteList = EXTERNAL_BROWSER_WHITELIST.find(whitelistedLink => {
        if (to.includes(whitelistedLink)) return true;
        return false;
    });

    return isLinkOnWhiteList?.length > 0 ?? false;
};

const getToAndTarget = url => {
    let to = getMobileExternalLink(url);
    let target = '_blank';
    const root_url = getRootUrl();

    if (to.includes(root_url)) {
        to = to.split(root_url)[1];
        target = '_self';
    } else if (
        Capacitor.isNativePlatform() &&
        (!to.includes('http') || isOnExternalBrowserWhitelist(to))
    ) {
        target = '_system';
    }

    return [to, target];
};

const LINK_SCAN_RE = /www|@|\:\/\//;

function getMobileExternalLink(str) {
    let to = str;
    if (!to.includes('://') && !to.includes('mailto:')) {
        to = `https://${to}`;
    }
    return to;
}

function isLinkOpen(str) {
    return /^<a[>\s]/i.test(str);
}

function isLinkClose(str) {
    return /^<\/a\s*>/i.test(str);
}

// Stupid fabric to avoid singletons, for thread safety.
// Required for engines like Nashorn.
//
function createLinkifier() {
    const links = [];
    const autolinker = new Autolinker({
        stripPrefix: false,
        url: true,
        email: true,
        replaceFn(match) {
            // Only collect matched strings but don't change anything.
            switch (match.getType()) {
                /* eslint default-case:0 */
                case 'url':
                    links.push({
                        text: match.matchedText,
                        url: match.getUrl(),
                    });
                    break;
                case 'email':
                    links.push({
                        text: match.matchedText,
                        // normalize email protocol
                        url: `mailto:${match.getEmail().replace(/^mailto:/i, '')}`,
                    });
                    break;
            }
            return false;
        },
    });

    return {
        links,
        autolinker,
    };
}

function parseTokens(state) {
    let i,
        j,
        l,
        tokens,
        token,
        text,
        nodes,
        ln,
        pos,
        level,
        htmlLinkLevel,
        blockTokens = state.tokens,
        linkifier = null,
        links,
        autolinker;

    for (j = 0, l = blockTokens.length; j < l; j++) {
        if (blockTokens[j].type !== 'inline') {
            continue;
        }
        tokens = blockTokens[j].children;

        htmlLinkLevel = 0;

        // We scan from the end, to keep position when new tags added.
        // Use reversed logic in links start/end match
        for (i = tokens.length - 1; i >= 0; i--) {
            token = tokens[i];

            // Skip content of markdown links
            if (token.type === 'link_close') {
                i--;
                while (
                    tokens[i] &&
                    tokens[i].level !== token.level &&
                    tokens[i].type !== 'link_open'
                ) {
                    i--;
                }
                continue;
            }

            // Skip content of html tag links
            if (token.type === 'htmltag') {
                if (isLinkOpen(token.content) && htmlLinkLevel > 0) {
                    htmlLinkLevel--;
                }
                if (isLinkClose(token.content)) {
                    htmlLinkLevel++;
                }
            }
            if (htmlLinkLevel > 0) {
                continue;
            }

            if (token.type === 'text' && LINK_SCAN_RE.test(token.content)) {
                // Init linkifier in lazy manner, only if required.
                if (!linkifier) {
                    linkifier = createLinkifier();
                    links = linkifier.links;
                    autolinker = linkifier.autolinker;
                }

                text = token.content;
                links.length = 0;
                autolinker.link(text, {
                    replaceFn: match => {
                        const [to, target] = getToAndTarget(match.getAnchorHref());

                        const tag = match.buildTag(); // returns an Autolinker.HtmlTag instance, configured with the Match's href and anchor text

                        if (Capacitor.isNativePlatform()) {
                            tag.setAttr(
                                'onClick',
                                `() => Capacitor.Plugins.Browser.open({ url: '${to}', windowName: '${target}', toolBarColor: '${getInAppBrowserOptions().toolBarColor
                                }' })`
                            );
                        }
                        return tag;
                    },
                });

                if (!links.length) {
                    continue;
                }

                // Now split string to nodes
                nodes = [];
                level = token.level;

                for (ln = 0; ln < links.length; ln++) {
                    if (!state.inline.validateLink(links[ln].url)) {
                        continue;
                    }

                    pos = text.indexOf(links[ln].text);

                    const [to, target] = getToAndTarget(links[ln].url);

                    if (pos) {
                        nodes.push({
                            type: 'text',
                            content: text.slice(0, pos),
                            level,
                        });
                    }
                    nodes.push({
                        type: 'link_open',
                        href: links[ln].url,
                        linkTarget: target,
                        onClick: `onclick="Capacitor.Plugins.Browser.open({ url: '${to}', windowName: '${target}', toolBarColor: '${getInAppBrowserOptions().toolBarColor
                            }' })"`,
                        title: '',
                        level: level++,
                    });
                    nodes.push({
                        type: 'text',
                        content: links[ln].text,
                        level,
                    });
                    nodes.push({
                        type: 'link_close',
                        level: --level,
                    });
                    text = text.slice(pos + links[ln].text.length);
                }
                if (text.length) {
                    nodes.push({
                        type: 'text',
                        content: text,
                        level,
                    });
                }

                // replace current node
                blockTokens[j].children = tokens = [].concat(
                    tokens.slice(0, i),
                    nodes,
                    tokens.slice(i + 1)
                );
            }
        }
    }
}

// TODO: Update this to incluide all 'entities'...
// https://github.com/jonschlinkert/remarkable/tree/master/lib'
function replaceEntities(string) {
    return string;
}

const HTML_ESCAPE_TEST_RE = /[&<>"]/;
const HTML_ESCAPE_REPLACE_RE = /[&<>"]/g;
const HTML_REPLACEMENTS = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
};

function replaceUnsafeChar(ch) {
    return HTML_REPLACEMENTS[ch];
}

export function escapeHtml(str) {
    if (HTML_ESCAPE_TEST_RE.test(str)) {
        return str.replace(HTML_ESCAPE_REPLACE_RE, replaceUnsafeChar);
    }
    return str;
}

// Overriding the normal link_open tag rule of the renderer to use inAppBrowser
// EXAMPLE: if(Capacitor.isNativePlatform()) md.renderer.rules.link_open = link_open;
// https://github.com/jonschlinkert/remarkable/blob/master/lib/rules.js

export function link_open(tokens, idx, options /* env */) {
    const token = tokens[idx];
    const linkTarget = token.linkTarget || options.linkTarget;

    const title = token.title ? ` title="${escapeHtml(replaceEntities(token.title))}"` : '';
    let target = linkTarget ? ` target="${linkTarget}"` : ' target="_blank"';

    // If user mention
    if (token.href && token.href.includes('wl:userMention:')) {
        const splitString = token.href
            .split('wl:userMention:')
            .filter(string => !_.isEmpty(string));
        const userId = Array.isArray(splitString) && splitString[0];

        const disableLink = token.href.includes(MENTION_ALL) || token.href.includes(MENTION_ADMINS);
        const linkClasses = `mention-link ${disableLink ? 'no-pointer-event' : ''}`;

        target = ' target="_blank"';

        if (Capacitor.isNativePlatform()) {
            const mobileTo = `/u/${userId}`;
            return `<a class="${linkClasses}" href="${mobileTo}" ${title} ${target}>`;
        }

        return `<a class="${linkClasses}" href="/u/${userId}" ${target}>`;
    }

    let to = getMobileExternalLink(escapeHtml(token.href));
    const root_url = getRootUrl();

    if (Capacitor.isNativePlatform()) {
        let mobileTarget = linkTarget || '_blank';

        if (to.includes(root_url)) {
            to = to.split(root_url)[1];
            mobileTarget = '_self';
        } else if (isOnExternalBrowserWhitelist(to)) {
            mobileTarget = '_system';
        }

        // bypasses the in app-browser if link is on the external browsers white list
        if (isOnExternalBrowserWhitelist(to)) {
            const onClick = `onclick="window.location.href='${to}'"`;
            return `<a ${onClick} href="#" ${title} ${target} >`;
        }

        // url interceptor will handle this currently to use react router
        return `<a href="${to}" ${title}>`;
    }

    if (to.includes(root_url)) {
        to = to.split(root_url)[1];
        target = 'target="_self"';
    }

    return `<a href="${to}"${title}${target}>`;
}

export function linkify(md) {
    md.core.ruler.push('linkify', parseTokens);
}
