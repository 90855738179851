import { useImmer } from 'use-immer';
import { v1 as uuidv1 } from 'uuid';

import { locationCoreToLocationInput } from '@helpers/user/location.helpers';
import { getClientTzSelect } from '@core/utilities/constants/events';
import { VIEW_PERMISSION_PUBLIC } from '@core/utilities/constants/roles';

import { EventGroupFormState, ItineraryState, LocationTypeEnum } from '@core/types/EventGroup';
import { Group, UserAliasHandle, EventDates, Product, LocationCore } from '@shared/welibrary-graphql/types';
import { PerformantMaybify } from '@core/types/Utilities';

import { config } from '@core/config/getConfig';

const formatLocation = (location?: PerformantMaybify<LocationCore>) => {
    if (!location) return undefined;

    const newLocation = locationCoreToLocationInput(location);

    return {
        formatted_address: newLocation?.formatted_address ?? '',
        location: newLocation?.location ?? { longitude: 0, latitude: 0 },
        city: newLocation?.city ?? '',
        state: newLocation?.state ?? '',
        country: newLocation?.country ?? '',
        postal: newLocation?.postal ?? '',
    };
};

/**
 * Builds up all the necessary state for displaying Event Group Generator Form, optionally taking in an existing
 * Event Group to edit
 */
const useEventGroupFormState = (group?: PerformantMaybify<Group>) => {
    const getSponsorsFromTiers = (group: PerformantMaybify<Group>) => {
        const tiers = group?.eventOverviewDetails?.sponsorSettings?.tiers;

        const sponsors = tiers?.reduce((tiers, currentTier) => {
            if (!Array.isArray(currentTier?.sponsors)) return [];
            const tierSponsors = currentTier?.sponsors?.map(sponsor => {
                return {
                    id: uuidv1(),
                    name: sponsor?.name,
                    link: sponsor?.link,
                    logo: { image: sponsor?.logo },
                    tier: {
                        name: currentTier.name!,
                        order: currentTier.order!,
                    },
                };
            });
            return [...tiers, ...tierSponsors];
        }, []);

        return sponsors;
    };

    const timeAndPlaceToItineraryState = (timeAndPlace: any): ItineraryState => {
        return {
            id: uuidv1(),
            title: timeAndPlace?.title ?? '',
            dates: timeAndPlace?.dates?.map((datesObj: EventDates) => {
                return {
                    startDate: datesObj?.startDate,
                    endDate: datesObj?.endDate,
                };
            }) ?? [{ startDate: new Date().toISOString(), endDate: new Date().toISOString() }],
            description: timeAndPlace?.description ?? '',
            link: timeAndPlace?.link ?? '',
            displayEndTime: timeAndPlace?.displayEndTime ?? true,
            displayStartTime: timeAndPlace?.displayStartTime ?? true,
            startTime: timeAndPlace?.startTime ?? new Date()?.toISOString(),
            endTime: timeAndPlace?.endTime ?? new Date()?.toISOString(),
            eventLocationSettings: {
                type: timeAndPlace?.eventLocationSettings?.type ?? LocationTypeEnum.inPerson,
                link: timeAndPlace?.eventLocationSettings?.link ?? '',
                platformName: timeAndPlace?.eventLocationSettings?.platfornName ?? '',
                venue: {
                    name: timeAndPlace?.eventLocationSettings?.venue?.name ?? '',
                    link: timeAndPlace?.eventLocationSettings?.venue?.link ?? '',
                    location: formatLocation(timeAndPlace?.eventLocationSettings?.venue?.location),
                },
            },
            speakers:
                timeAndPlace?.speakerSettings?.aliases.map((speaker: UserAliasHandle) => {
                    const userBio =
                        speaker?.user?.profile?.full_bio || speaker?.user?.profile?.short_bio;
                    return {
                        _id: speaker?.user?._id,
                        name: speaker?.altName || speaker?.user?.profile?.full_name,
                        profileThumb: {
                            image: speaker?.thumb || speaker?.user?.profile?.picture,
                        },
                        email: speaker?.user?.profile?.email,
                        bio: speaker?.altBio || userBio,
                        useUserBio: speaker?.useUserBio,
                        userBio: userBio,
                    };
                }) ?? [],
            headline: timeAndPlace?.headline ?? '',
            rsvpLink: timeAndPlace?.rsvpLink ?? '',
            date: timeAndPlace?.date ?? new Date().toISOString(),
            timeZone: {
                name: timeAndPlace?.timeZone?.name ?? getClientTzSelect().value,
            },
        };
    };

    const getCouponsFromProduct = (ticket: PerformantMaybify<Product>) => {
        if (ticket?.coupons && ticket?.coupons?.length > 0) {
            const coupons = ticket.coupons.map(coupon => {
                return {
                    code: coupon?.code ?? '',
                    name: coupon?.name ?? '',
                    percentage: coupon?.percentage ?? '',
                    type: coupon?.type ?? '',
                };
            });
            return coupons;
        }
        return [];
    };

    const getGroupProducts = (group: PerformantMaybify<Group>) => {
        if (group?.products && group?.products?.length > 0) {
            const products = group?.products?.map(ticket => {
                return {
                    _id: ticket?._id,
                    coupons: getCouponsFromProduct(ticket),
                    currency: ticket?.currency ?? '',
                    name: ticket?.name ?? '',
                    price: Number(((ticket?.price ?? 100) / 100).toFixed(2)) ?? '',
                    type: ticket?.type ?? '',
                    description: ticket?.description ?? '',
                    fulfillment: {
                        groupId: ticket?.fulfillment?.groupId ?? '',
                        role: ticket?.fulfillment.role ?? '',
                    },
                };
            });
            return products;
        }
        return [];
    };

    return useImmer<EventGroupFormState>({
        overviewFormState: {
            title: group?.eventOverviewDetails?.title ?? '',
            description: group?.eventOverviewDetails?.description ?? '',
            thumbnail: group?.profile?.picture ?? '',
            coverImage: group?.profile?.coverPhoto ?? '',
            eventLocationSettings: {
                type:
                    group?.eventOverviewDetails?.eventLocationSettings?.type ??
                    LocationTypeEnum.inPerson,
                location: {
                    formatted_address: '',
                    location: {
                        latitude: 0,
                        longitude: 0,
                    },
                    city: '',
                    state: '',
                    country: '',
                    postal: '',
                },
                venue: {
                    name: group?.eventOverviewDetails?.eventLocationSettings?.venue?.name ?? '',
                    link: group?.eventOverviewDetails?.eventLocationSettings?.venue?.link ?? '',
                    location: formatLocation(
                        group?.eventOverviewDetails?.eventLocationSettings?.venue?.location
                    ),
                },
                link: group?.eventOverviewDetails?.eventLocationSettings?.link ?? '',
                platformName:
                    group?.eventOverviewDetails?.eventLocationSettings?.platformName ?? '',
            },
        },
        dateAndTimeState: {
            dates: [
                {
                    startDate:
                        group?.eventOverviewDetails?.dates?.[0]?.startDate ??
                        new Date().toISOString(),
                    endDate:
                        group?.eventOverviewDetails?.dates?.[0]?.endDate ??
                        new Date().toISOString(),
                },
            ],
            startTime: group?.eventOverviewDetails?.startTime ?? null,
            endTime: group?.eventOverviewDetails?.endTime ?? null,
            displayStartTime: group?.eventOverviewDetails?.displayStartTime ?? true,
            displayEndTime: group?.eventOverviewDetails?.displayEndTime ?? true,
            timeZone: {
                name: group?.eventOverviewDetails?.timeZone?.name ?? getClientTzSelect().value,
            },
        },
        itineraryState: group?.timeAndPlaceSettings?.map(timeAndPlaceToItineraryState) ?? [],
        ticketFormState: {
            products: getGroupProducts(group) || [],
            inEditMode: false,
            merchantAccountFullActivated: false,
            groupId: group?._id ?? '',
            isGroupMembershipPaid: group?.paymentSettings?.isGroupMembershipPaid ?? false,
        },
        sponsorsFormState: {
            description: group?.eventOverviewDetails?.sponsorSettings?.description ?? '',
            sponsors:
                group?.eventOverviewDetails?.sponsorSettings?.tiers?.length! > 0
                    ? getSponsorsFromTiers(group!)
                    : [],
            tiers:
                group?.eventOverviewDetails?.sponsorSettings?.tiers?.map(tier => {
                    return {
                        name: tier?.name ?? '',
                        order: tier?.order ?? 0,
                    };
                }) ?? [],
        },
        previewFormState: {
            privacyLevel: group?.settings?.protectionLevel ?? VIEW_PERMISSION_PUBLIC,
            agreedToTerms: !!group || !config.public.eventOrganizerTermsOfUseUrl, // if the group has already been created they've already accepted the terms
        },
        groupSettingsState: {
            protectionLevel: group?.settings?.protectionLevel ?? VIEW_PERMISSION_PUBLIC,
            recommendations: group?.settings?.recommendations ?? false,
            shortCode: group?.settings?.shortCode ?? '',
            groupChatroom: group?.settings?.groupChatroom ?? false,
            adminChatroom: group?.settings?.adminChatroom ?? false,
            approvalRequired: group?.settings?.approvalRequired ?? false,
            registrationTitle: group?.settings?.registrationTitle ?? '',
            registrationDescription: group?.settings?.registrationDescription ?? '',
            registrationActionText: group?.settings?.registrationActionText ?? '',
            canMembersSuggestEdits: group?.settings?.canMembersSuggestEdits ?? false,
            enableGroupNewsfeed: group?.settings?.enableGroupNewsfeed ?? false,
            isTemplate: group?.isTemplate ?? false,
            hideAttendeeCount: group?.eventOverviewDetails?.hideAttendeeCount ?? false,
        },
    });
};

export default useEventGroupFormState;
