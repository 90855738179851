import { useContext, useEffect, useState } from 'react';

import { SidebarActionsContext } from '@web/ui/components/generic/context/SidebarContext';
import { SIGNUP_FIELD_TYPE_JOIN_GROUP } from '~/wl-core/utilities/constants/library_settings';

/**
 * @typedef {Object} ValuesObject
 * @property {string} id
 * @property {Array.<string | string[]>} values
 * @property {string} type
 * @property {string} comparator
 */

/**
 * @typedef {Object} CustomFieldsObject
 * @property {{ _id: string }} parentGroup
 * @property {string} manager
 * @property {ValuesObject[]} values
 */

/**
 * @typedef {Object} ProfileObject
 * @property {string} full_name
 * @property {string} picture
 * @property {string} short_bio
 */

/**
 * @typedef {Object} CustomSignupFieldsObject
 * @property {string} customText
 * @property {string} defaultComparator
 * @property {string} id
 * @property {number} index
 * @property {string[]} options
 * @property {string} prompt
 * @property {boolean} selectMultiple
 * @property {string} siblingId
 * @property {string} type
 */

/**
 * @property {boolean} currentUserIsAdmin
 * @property {boolean} currentUserIsMember
 * @property {ProfileObject} profile
 * @property {{ recommendations: boolean, customSignupFields: CustomSignupFieldsObject[] }} settings
 * @property {string} _id
 *

/**
 * React Hook for getting a specific group's optimizer data
 * This will give you all the data/logic you need to display and update a single
 * optimizer
 *
 * @param {{
 *   platformFields: CustomSignupFieldsObject[]
 *   profile: ProfileObject
 *   updateQuery: (payload: CustomFieldsObject) => void
 * }}
 */
const usePlatformOptimizer = ({ platformFields, profile, updateQuery, initialState }) => {
    const { setUnsavedChanges } = useContext(SidebarActionsContext);

    // Custom fields the user has already filled out.
    const userPlatformCustomFields = profile?.customFields?.find(
        field => field?.manager === 'platform'
    );

    const values = userPlatformCustomFields?.values ?? [];

    /**
     * Map the starting state based on custom fields the user has already filled out
     * @type {{ parentUrl: string, manager: string, values: Array.<ValuesObject> }}
     */
    const startingPayload = {
        parentUrl: 'root',
        manager: 'platform',
        values: values.map(({ id, values, type }) => ({ id, values, type })),
    };

    // Set the update payload to the users's initial custom fields when data arrives
    const [payload, setPayload] = useState(startingPayload);
    useEffect(() => setPayload(startingPayload), [platformFields, initialState]);

    /**
     * Handles the user selecting a new response for a custom field
     * If the custom field already exists in the payload, replaces it.
     * Otherwise, it creates a new custom field entry mapped with the parentUrl
     *
     * @param {string} id custom field id
     * @param {ValuesObject} values custom field values
     * @param {string} type custom field type
     */
    const handleCustomFieldsChange = (id, values, type) => {
        setUnsavedChanges(true);
        setPayload(oldPayload => ({
            parentUrl: 'root',
            manager: 'platform',
            values: [...oldPayload.values?.filter(field => field.id !== id), { id, values, type }],
        }));
    };

    // Overrides the normal handleChange to map -> id, value, type
    const handleChange = (e, type) => handleCustomFieldsChange(e.target.name, e.target.value, type);

    /**
     * Map fields onto format for CustomStep
     * @type {{ [key: string]: string | string[] }}
     */
    const optimizeState = payload.values?.reduce((acc, value) => {
        acc[value.id] = { value: value.values };
        return acc;
    }, {});

    // Update user profile with new custom fields
    const saveChanges = e => {
        e?.preventDefault();
        if (payload.values?.length > 0 && payload.parentUrl) updateQuery(payload);
        setUnsavedChanges(false);
    };

    const questionsAnswered =
        payload.values?.filter(answer => answer.values.length > 0).length ?? 0;
    const numQuestions =
        platformFields?.filter(field => field.type !== SIGNUP_FIELD_TYPE_JOIN_GROUP)?.length ?? 1;

    return {
        percentComplete: (questionsAnswered / numQuestions) * 100,
        handleChange,
        handleCustomFieldsChange,
        optimizeState,
        saveChanges,
        platformFields,
    };
};

export default usePlatformOptimizer;
