import { v4 as uuidv4 } from 'uuid';
import { MARKDOWN_LINK_REGEX } from '~/wl-core/utilities/constants/regexes';

export const slugify = (string: string): string => {
    const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;';
    const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
        .concat(uuidv4()); // append unique id
};

export const sentenceCase = (string?: string): string => {
    if (string === null || string === '' || string === undefined) return '';

    return string
        .toString()
        .replace(/_/g, ' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const camelize = (string: string): string =>
    string
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
            index === 0 ? word.toLowerCase() : word.toUpperCase()
        )
        .replace(/\s+/g, '');

export const eliminateSpecialCharacters = (string: string): string =>
    string.replace(/[^\w\s]/gi, '');

export const capitalize = (string?: string): string => {
    return string ? string[0].toUpperCase() + string.slice(1) : '';
};

export const truncate = (string: string | undefined | null, len: number) => {
    if (string === undefined || string === null || string === '') return '';
    if (string.length > len) return `${string.substring(0, len)}...`;

    return string;
};

/** These words become -> These Words Become */
export const titleCase = (string?: string) => string?.split(' ').map(capitalize).join(' ') ?? '';

/** Removes angle brackets (<>) from a string */
export const stripAngleBrackets = (string: string) => string.replaceAll(/<|>/g, '');

/** Checks whether a string is a [Markdown](link) */
export const isMarkdownLink = (string: string) => MARKDOWN_LINK_REGEX.test(string);

/** Gets the link from a [Markdown](link) */
export const getLinkFromMarkdownLink = (string: string) => string.match(MARKDOWN_LINK_REGEX)[1];
