import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

import { awsMetricPublisher } from '@welibraryos/metrics';

import {
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_MOBILIZATION,
    CARD_MOBILIZE,
    CARD_VIDEO,
    CARD_VOLUNTEER_MOBILIZATION,
} from '@core/utilities/constants/card_types.js';

import {
    ATTACH_DOCUMENT,
    ATTACH_IMAGE,
    ATTACH_STORY_CARD,
} from '@core/utilities/constants/attachment_types.js';

import { cardToAttachType, TYPES_TO_ICON_CLASS } from '@components/content/newpost/constants';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

const FILE_PICKER_ATTACH_TYPES = [ATTACH_IMAGE, ATTACH_DOCUMENT, ATTACH_STORY_CARD];

const baseAttachIconClass = 'new-post-media-button w-inline-block';

const NewPostIcon = ({
    cardType,
    handleExpand,
    handleClear,
    handleFileSelect,
    shouldShowAttachment,
    handleSquarePhotoSelect,
    openModalHandler,
}) => {
    const iconClass = TYPES_TO_ICON_CLASS[cardType];
    const { t } = useTranslation();
    const containerClass = `${baseAttachIconClass} ${iconClass}`;
    const attachType = cardToAttachType[cardType];
    const handleClick = () => {
        if (cardType === CARD_LINK || cardType === CARD_VIDEO) {
            handleExpand(attachType);
        } else if (cardType === CARD_IMAGE_SQUARE) {
            handleClear?.();
            handleSquarePhotoSelect();
        } else if (FILE_PICKER_ATTACH_TYPES.includes(attachType)) {
            handleClear?.();
            handleFileSelect(attachType);
        } else {
            if (cardType === CARD_MOBILIZE) cardType = CARD_MOBILIZATION;
            handleClear?.();
            openModalHandler(cardType); // form modal opened here
        }

        awsMetricPublisher.publishCount('new-post-modal-select-content', 1, [
            { Name: 'type', Value: cardType },
        ]);
    };

    // Mobilization cards in this case need to be remapped to get the correct translation key
    let translationKey = cardType;
    if (cardType === CARD_MOBILIZE) translationKey = 'mobilize_job_posting';
    if (cardType === CARD_VOLUNTEER_MOBILIZATION) translationKey = 'mobilize_volunteer_posting';
    const translatedTooltip = t(`common:global.constants.cards.types.${translationKey}`);

    return (
        <Tooltip title={translatedTooltip}>
            <NewPostIconEl
                shouldShowAttachment={shouldShowAttachment}
                handleClear={handleClear}
                key={cardType}
                attachType={attachType}
                className={containerClass}
                handleClick={handleClick}
            />
        </Tooltip>
    );
};

const NewPostIconEl = React.forwardRef(function NewPostIconEl(props, ref) {
    return (
        <button
            title={props.title}
            type="button"
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onMouseOver={props.onMouseOver}
            onMouseLeave={props.onMouseLeave}
            onTouchStart={props.onTouchStart}
            onTouchEnd={props.onTouchEnd}
            ref={ref}
            className={`button-reset ${props.className}`}
            onClick={props.handleClick}
        >
            {props.shouldShowAttachment(props.attachType) && (
                <div name={props.cardType} className="post-media-icon-highlight" />
            )}
        </button>
    );
});

export default NewPostIcon;
