import React, {useState} from 'react';

export default ({
    type = '',
    name,
    style,
    value,
    autoComplete,
    showCount,
    maxLength,
    classNames,
    showErr,
    validateFunc,
    validateMsg,
    placeHolder,
    defaultValue = { length: 0 },
    handleChange: propsHandleChange,
    maxCharacter,
    onFocus,
    onBlur,
    disabled,
}) => {
    const [wordCount, setWordCount] = useState(defaultValue.length);
    const [showValidation, setShowValidation] = useState(false);
    const inputStyle = style || {};

    const handlerWordCount = e => {
        const currentText = e.target.value;
        const characterCount = currentText.length;
        setWordCount(characterCount);
    };

    const handleChange = e => {
        if (validateFunc) {
            if (!validateFunc(e.currentTarget.value)) {
                setShowValidation(true);
            }

            if (validateFunc(e.currentTarget.value)) {
                setShowValidation(false);
            }
        }
        handlerWordCount(e);
        const isInteger = type === 'number';
        if (propsHandleChange) propsHandleChange(e, isInteger);
    };

    return (
        <>
            {showValidation && validateMsg && <p className="input-validation-msg">{validateMsg}</p>}
            <input
                value={value}
                onChange={handleChange}
                autoComplete={autoComplete}
                onFocus={onFocus}
                onBlur={onBlur}
                className={classNames}
                maxLength={maxLength}
                name={name}
                placeholder={placeHolder}
                type={type}
                style={inputStyle}
                disabled={disabled}
            />
            {showCount && (
                <div className="login-text-field-subtext darker">{maxCharacter - wordCount}</div>
            )}
        </>
    );
};
