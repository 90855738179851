import i18n, { TFunction } from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Backend from 'i18next-chained-backend';
import { languageCipher } from './whitelist';

// TODO: Change back to https://translations.learnbank.io
const TRANSLATIONS_ENDPOINT = 'https://ehmuto2f30.execute-api.us-east-1.amazonaws.com';

const TRANSLATIONS_VERSION = 827;

const isProduction = process.env.NODE_ENV === 'production';

const lookupKeys = {
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
};

const languageCache = {
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'],
};

const languageCookies = {
    cookieMinutes: 10,
    cookieDomain: 'myDomain',
};

const backendOptions = {
    loadPath: (lngs?: string[]) => {
        let lng = lngs?.[0] ? lngs[0] : 'en';

        if (lng.toLowerCase() in languageCipher) {
            lng = languageCipher[lng.toLowerCase() as keyof typeof languageCipher];
        }

        return `${TRANSLATIONS_ENDPOINT}?lng=${lng}`;
    },
    addPath: (lngs?: string | string[]) => {
        let lng = lngs instanceof Array && lngs.length > 0 ? lngs[0] : 'en';

        if (lng.toLowerCase() in languageCipher) {
            lng = languageCipher[lng.toLowerCase() as keyof typeof languageCipher];
        }

        return `${TRANSLATIONS_ENDPOINT}/add/${lng}`;
    },
    // requestOptions: {
    //     cache: 'no-cache'
    // },

    queryStringParams: { v: '3.1.77' },
    crossDomain: true,
};

const DetectionOptions = {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    ...lookupKeys,
    ...languageCache,
    ...languageCookies,
    htmlTag: document.documentElement,
    checkWhitelist: false,
};

export default (callback: (error: any, t: TFunction) => void) => {
    if (!isProduction) {
        i18n.use(HttpApi)
            .use(detector)
            .use(initReactI18next)
            .init(
                {
                    ns: ['common'],
                    defaultNS: 'common',
                    lng: 'en-US',
                    lowerCaseLng: false,
                    cleanCode: false,
                    fallbackLng: 'en',
                    debug: false,
                    load: 'currentOnly',
                    interpolation: { escapeValue: false },
                    detection: DetectionOptions,
                    backend: backendOptions,
                    saveMissing: true,
                    react: {
                        wait: true,
                        useSuspense: false,
                    },
                },
                callback
            );
    } else {
        i18n.use(Backend)
            .use(detector)
            .use(initReactI18next)
            .init(
                {
                    ns: ['common'],
                    defaultNS: 'common',
                    lng: 'en-US',
                    lowerCaseLng: false,
                    cleanCode: false,
                    fallbackLng: 'en',
                    debug: false,
                    load: 'currentOnly',
                    interpolation: { escapeValue: false },
                    detection: DetectionOptions,
                    backend: {
                        backends: [LocalStorageBackend, HttpApi],
                        backendOptions: [
                            {
                                expirationTime: 7 * 24 * 60 * 60 * 1000,
                                defaultVersion: TRANSLATIONS_VERSION.toString(),
                            },
                            backendOptions,
                        ],
                    },
                    saveMissing: false,
                    react: {
                        wait: true,
                        useSuspense: false,
                    },
                },
                callback
            );
    }
};
