import React from 'react';

import useModal from '@components/modals/hooks/useModal';

import ImagePicker from '@components/content/ImagePicker';
import Picture from '@web/ui/components/generic/Picture';

import {
    getRandomDefaultCoverImage,
    getRandomDefaultThumbImage,
} from '@core/utilities/constants/defaults';

type ThumbnailAndCoverProps = {
    title: string;
    thumbImage?: string;
    coverImage?: string;
    thumbTarget?: string;
    coverTarget?: string;
    handleUpdateState: (state: { [key: string]: any }) => void;
};

const ThumbnailAndCover: React.FC<ThumbnailAndCoverProps> = ({
    title,
    thumbImage,
    coverImage,
    thumbTarget,
    coverTarget,
    handleUpdateState,
}) => {
    const { newModal } = useModal();

    const selectCover = () => {
        newModal(
            <ImagePicker
                onImageSelect={(selectedUrl: string) => {
                    if (coverTarget) handleUpdateState({ [coverTarget]: selectedUrl });
                }}
            />,
            { className: 'image-picker-modal no-bottom-padding full-height' }
        );
    };

    const selectThumbnail = () => {
        newModal(
            <ImagePicker
                onImageSelect={(selectedUrl: string) => {
                    if (thumbTarget) handleUpdateState({ [thumbTarget]: selectedUrl });
                }}
            />,
            { className: 'image-picker-modal no-bottom-padding full-height' }
        );
    };

    const coverPreview = coverImage || getRandomDefaultCoverImage(title);
    const thumbPreview = thumbImage || getRandomDefaultThumbImage(title);

    return (
        <div className="images-selector-container">
            {coverTarget && (
                <section className="edit-cover-container">
                    <Picture url={coverPreview} disableLink>
                        <button
                            type="button"
                            className="add-image-button edit-image-button"
                            onClick={selectCover}
                            aria-label="Edit Cover Image"
                        />
                    </Picture>
                </section>
            )}
            {thumbTarget && (
                <section className="edit-thumbnail-container">
                    <Picture url={thumbPreview} disableLink resolutions={[100, 200, 300]}>
                        <button
                            type="button"
                            className="add-image-button edit-image-button"
                            onClick={selectThumbnail}
                            aria-label="Edit Thumbnail"
                        />
                    </Picture>
                </section>
            )}
        </div>
    );
};

export default ThumbnailAndCover;
