import React from 'react';

import {
    useGetOrderByIdQuery,
    useGetProductInstancesForUserForGroupQuery,
} from '@shared/welibrary-graphql/user/queries.hook';

import MultiTicketSlider from '@components/card/Shopping-Cart/MultiTIcketSlider';
import IntroContent from '@web/ui/components/generic/IntroContent';

// TODO: disable the view ticket from anyplace where its accessible of productInstances are not available
// TODO: show an error if no product intances exist

const TicketItem: React.FC<{
    orderId?: string;
    groupId?: string;
    handleShoppingCart?: () => void;
}> = ({ orderId, groupId, handleShoppingCart }) => {
    // this query is for when a user clicks on an order history item
    // queries the order by ID + restricts only returning productInstances for that order
    const { data: orderByIdData, loading: orderByIdLoading } = useGetOrderByIdQuery({
        variables: {
            _id: orderId,
        },
        fetchPolicy: 'network-only',
        skip: !orderId,
    });

    // this query is for when a user clicks view ticket from anywhere on the app
    // queries all productInstances owned for that event-group
    const { data: productInstancesData, loading: productInstancesLoading } =
        useGetProductInstancesForUserForGroupQuery({
            variables: {
                groupId,
            },
            fetchPolicy: 'network-only',
            skip: !groupId,
        });

    if (orderByIdLoading || productInstancesLoading) {
        return (
            <div className="card-loading group-item">
                <strong>Loading...</strong>
            </div>
        );
    }

    const order = orderByIdData?.getOrder;

    const tickets =
        order?.productInstances ?? productInstancesData?.getProductInstancesForUserForGroup;

    if (tickets?.length > 0) {
        return <MultiTicketSlider tickets={tickets} handleShoppingCart={handleShoppingCart} />;
    }

    return (
        <div>
            <IntroContent declaration="No Ticket Found!" type="nocontent" />
        </div>
    );
};

export default TicketItem;
