// src/workflow/mutations.ts
import { gql as gql2 } from "@apollo/client";

// src/workflow/fragments.ts
import { gql } from "@apollo/client";
var WORKFLOW_STATE_FRAGMENT = gql`
    fragment WorkflowStateFragment on WorkflowState {
        __typename
        _id
        subject {
            ... on Group {
                _id
            }
        }
        workflowId
        state
        createdAt
        updatedAt
    }
`;

// src/workflow/mutations.ts
var SET_WORKFLOW_STATE = gql2`
    mutation SetWorkflowState($input: WorkflowStateInput!) {
        setWorkflowState(input: $input) {
            ...WorkflowStateFragment
        }
    }
    ${WORKFLOW_STATE_FRAGMENT}
`;
export {
  SET_WORKFLOW_STATE
};
