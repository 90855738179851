import React from 'react';
import {createPortal} from 'react-dom';

const DEFAULT_ROOT_NODE_ID = 'modal-root';

export default (WrappedComponent, rootId = DEFAULT_ROOT_NODE_ID) => {
    class Portal extends React.Component {
        constructor(props) {
            super(props);
            this.el = document.createElement('div');
        }

        componentDidMount() {
            // The portal element is inserted in the DOM tree after
            // the Modal's children are mounted, meaning that children
            // will be mounted on a detached DOM node. If a child
            // component requires to be attached to the DOM tree
            // immediately when mounted, for example to measure a
            // DOM node, or uses 'autoFocus' in a descendant, add
            // state to Modal and only render the children when Modal
            // is inserted in the DOM tree.

            const modalRoot = document.getElementById(rootId);
            modalRoot.appendChild(this.el);
        }

        componentWillUnmount() {
            const modalRoot = document.getElementById(rootId);
            modalRoot?.removeChild(this.el);
        }

        render() {
            const { forwardedRef, ...rest } = this.props;

            return createPortal(<WrappedComponent ref={forwardedRef} {...rest} />, this.el);
        }
    }

    return React.forwardRef((props, ref) => <Portal {...props} forwardedRef={ref} />);
};
