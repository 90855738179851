import React from 'react';

type MultiSelectButtonsProps<T extends string> = {
    value: T[];
    onChange: (value: T[]) => void;
    values: Array<{ display: React.ReactNode; data: T }>;
    className?: string;
    title?: string;
};

const MultiSelectButtons = <T extends string>({
    value,
    onChange,
    values,
    className = '',
    title,
}: MultiSelectButtonsProps<T>) => {
    const toggleValue = (valueToToggle: T) => {
        if (value.includes(valueToToggle)) return value.filter(val => val !== valueToToggle);

        return [...value, valueToToggle];
    };

    const buttons = values.map(({ data, display }) => (
        <button
            key={data}
            className={value.includes(data) ? 'selected' : ''}
            type="button"
            onClick={() => onChange(toggleValue(data))}
        >
            {display}
        </button>
    ));

    return (
        <section className={className}>
            {title && <header>{title}</header>}

            {buttons}
        </section>
    );
};

export default MultiSelectButtons;
