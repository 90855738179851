import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface AdvancedSettingsContainerProps extends WithTranslation {
    columnItem?: any; // Specify the correct type
    columnsBlock?: any; // Specify the correct type
    title?: string;
    subtitle?: React.ReactNode;
    clearfix?: any; // Specify the correct type
    children: React.ReactNode;
}

const localNamespace = 'imports.wlWeb.ui.components.generic.editScreen.advancedSettingsContainer';

const AdvancedSettingsContainer: React.FC<AdvancedSettingsContainerProps> = ({
    t,
    title,
    subtitle,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="edit-screen-item advanced-options-container columns-item">
            <div className="edit-item-label">
                <strong>{title ?? t(`common:${localNamespace}.advanced_settings`)}</strong>
                {subtitle}
            </div>
            <div
                className="edit-screen-form-block columns-block w-form"
                data-ix="show-hide-togglecontainer"
            >
                <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="edit-screen-form"
                >
                    <div
                        className="toggle-button-container w-inline-block"
                        onClick={() => setIsOpen(!isOpen)}
                        style={{
                            color: 'rgb(65, 65, 65)',
                            backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        }}
                    >
                        <div className="hide-text" style={{ display: 'none' }} />
                        <div className="show-text" style={{ display: 'block' }} />
                    </div>
                </form>
            </div>
            <div
                className="toggle-container"
                style={{
                    height: isOpen ? 'auto' : '0px',
                    transition: 'height 500ms ease 0s',
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default withTranslation()(AdvancedSettingsContainer);
