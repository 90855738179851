import React from 'react';

type TagProps = {
    text: string;
    id?: string;
    className?: string;
    value?: boolean;
    onChange?: (value: boolean) => void;
    disabled?: boolean;
    icon?: React.ReactNode;
};

/**
 * Toggleable Icon Tag
 * See https://www.figma.com/file/naUyXB9d4H9XG1tuFkgtbq/weLibrary-Design-Systems?node-id=982%3A3311
 */
const Tag: React.FC<TagProps> = ({
    text,
    id,
    className = '',
    value = false,
    onChange,
    disabled = false,
    icon = <></>,
}) => (
    <button
        type="button"
        className={`dsc-display-badges-tag ${className} ${value ? 'active' : ''}`}
        onClick={() => onChange?.(!value)}
        onKeyDown={e => {
            if (e.key === ' ') onChange?.(!value);
        }}
        role="switch"
        aria-checked={value}
        disabled={disabled}
        id={id}
    >
        {icon}
        <span>{text}</span>
    </button>
);
export default Tag;
