import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const localNamespace = 'imports.wlWeb.ui.pages.post-signup';

type PostSignupHeaderProps = {
    headerText: string;
    subtitle?: string;
    redirectUrl?: string;
    bottomMargin?: string;
    onSkipProfileOverride?: () => void;
};

const PostSignupHeader: React.FC<PostSignupHeaderProps> = ({
    headerText,
    subtitle,
    redirectUrl,
    bottomMargin = '60px',
    onSkipProfileOverride,
    children,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className="flex flex-col" style={{ marginBottom: bottomMargin }}>
            {redirectUrl && (
                <span className="text-grayscale-label font-poppins text-[14px] md:text-[17px] leading-[24px] tracking-[0.25px] mb-[30px] md:mb-[40px]">
                    {t(
                        `common:${localNamespace}.completeProfileLater.2`,
                        'Want to complete your profile another time?'
                    )}{' '}
                    {/* to="/dashboard" if they joined a group */}
                    <Link
                        to={redirectUrl}
                        className="font-[600] text-primary-default"
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (onSkipProfileOverride) {
                                onSkipProfileOverride();
                            } else {
                                history.push(redirectUrl);
                            }
                        }}
                    >
                        {t(`common:${localNamespace}.skipProfile`, 'Skip Profile')}
                    </Link>
                </span>
            )}

            {children}

            <h1 className="text-black font-poppins text-[24px] leading-[28px] md:text-[32px] md:leading-[34px] mb-[20px] mt-0">
                {headerText}
            </h1>
            <span className="text-grayscale-body text-[17px] leading-[24px] md:text-[20px] md:leading-[120%] tracking-[0.25px]">
                {subtitle}
            </span>
        </div>
    );
};

export default PostSignupHeader;
