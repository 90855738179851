import React from 'react';

import useGroupActions from '@components/group/hooks/useGroupActions';

import {
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import { isEventFree, isEventInThePast } from '@web/utilities/helpers/events/event.helpers';
import shoppingCartStore from '@web/ui/stores/ShoppingCart';

import { Group } from '@shared/welibrary-graphql/types';
import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';
import GROUP_ACTIONS from '@core/utilities/constants/group';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';

const ShoppingCartFixedButton: React.FC<{ group: Group }> = ({ group }) => {
    const { primaryActionAvailable, handlePurchaseAccess } = useGroupActions({
        group,
        refetchQueries: [GET_GROUP_DASHBOARD],
    });

    const _isEventFree = isEventFree(group);
    const _isEventOver = isEventInThePast(group); // Use isEventInThePast

    const shoppingCart = shoppingCartStore?.useTracked?.shoppingCarts()?.find(({ groupId }) => {
        return groupId === group?._id;
    });
    const shoppingCartProducts = shoppingCart?.products?.filter(({ product }) => {
        if (_isEventFree) return product?.type === PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP;

        return product?.type === PRODUCT_TYPES?.GROUP_MEMBERSHIP;
    });

    const showItemsInShoppingCart = shoppingCartProducts?.length > 0;

    const [coverSrc, coverSrcSet] = getBackgroundImageSetForUrl(
        group?.profile?.picture || getRandomDefaultCoverImage(group?.profile?.full_name ?? '')
    );

    const canPurchaseTickets =
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS ||
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_PURCHASE_ACCESS ||
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_LOGGEDOUT_PURCHASE_ACCESS;

    const handleShoppingCart = () => {
        if (canPurchaseTickets) {
            handlePurchaseAccess();
        }
    };

    // Hide the modal if event is over
    if (_isEventOver) return <></>;

    if (canPurchaseTickets) {
        return (
            <button
                type="button"
                className="absolute right-[20px] bottom-[80px] z-[50] rounded-full bg-gray-500 w-[110px] h-[45px] flex items-center justify-between border-white border-solid border-rounded border-2 shadow-2xl"
                onClick={handleShoppingCart}
            >
                {showItemsInShoppingCart && (
                    <div className="h-[15px] w-[15px] absolute top-[-2px] left-[-4px] bg-[#00D1A9] rounded-full border-white border-solid border-rounded border-2 overflow-visible z-10" />
                )}
                <div className="rounded-full w-[40px] h-[40px] relative overflow-hidden">
                    <StyledBackgroundImage
                        src={coverSrc}
                        srcSet={coverSrcSet}
                        className="w-full h-full bg-no-repeat bg-center bg-cover rounded-full"
                    />
                </div>
                <p className="text-white p-0 m-0 mr-2 font-semibold">Tickets</p>
            </button>
        );
    }

    return <></>;
};

export default ShoppingCartFixedButton;
