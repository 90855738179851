import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation, useQuery } from '@apollo/client';
import BroadcastSurveyEntry from '@components/broadcastsurvey/BroadcastSurveyEntry';
import EventGroupCustomSignup from '@components/content/newpost/forms/EventGroup/EventGroupCustomSignup';
import useModal from '@components/modals/hooks/useModal';
import { ModalTypes } from '@core/types/Modals';
import { capitalize, titleCase } from '@helpers/string.helpers';
import { BROADCAST_SURVEY } from '@shared/welibrary-graphql/broadcastsurvey/mutations';
import { GET_BROADCAST_SURVEYS_BY_GROUP_ID } from '@shared/welibrary-graphql/broadcastsurvey/queries';
import { Group } from '@shared/welibrary-graphql/types';
import LoadingIndicator from '@web/ui/components/generic/loading/LoadingIndicator';

interface BroadcastSurveyModalProps {
    group: Group;
    closeAllModals: () => void;
}

const BroadcastSurveyModal: React.FC<BroadcastSurveyModalProps> = ({ group, closeAllModals }) => {
    const { t } = useTranslation();
    const { newModal, closeModal } = useModal();
    const [broadcastSurvey] = useMutation(BROADCAST_SURVEY);
    const { data, refetch, loading } = useQuery(GET_BROADCAST_SURVEYS_BY_GROUP_ID, {
        variables: { groupId: group._id },
        fetchPolicy: 'network-only',
    });
    const [isLoading, setIsLoading] = useState(loading);
    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    const broadcastSurveys = data?.getBroadcastSurveysByGroupId ?? [];

    const handleBroadcastSurvey = async (broadcastSurveyId: string) => {
        await broadcastSurvey({
            variables: { input: { groupId: group._id, broadcastSurveyId } },
        });
        refetch();
    };

    const handleSave = async () => {
        setIsLoading(true);
        await refetch();
        setIsLoading(false);
        closeModal();
    };

    const handleCreateNewSurvey = () => {
        newModal(
            <EventGroupCustomSignup
                group={group}
                handleCancel={closeModal}
                isBroadcastSurvey
                handleSave={() => handleSave()}
                refetch={refetch}
            />,
            {
                className: 'center-fixed-header-footer',
                hideButton: false,
            },
            { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
        );
    };

    return (
        <section className="notify-panel px-6" style={{ paddingLeft: 0, paddingRight: 0 }}>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingIndicator />
                </div>
            ) : (
                <>
                    <header className="notify-panel-header">
                        <h1>{titleCase(t('common:broadcast_survey', 'broadcast survey'))}</h1>
                        <p>
                            {capitalize(
                                t(
                                    "common:create_and_manage_surveys_you've_sent_to_your_membership",
                                    "create and manage surveys you've sent to your membership"
                                )
                            ) + '.'}
                        </p>
                    </header>
                    <main
                        className="w-full overflow-y-scroll"
                        style={{ background: '#F8F8F8', padding: '1rem' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                            {broadcastSurveys.map(survey => (
                                <BroadcastSurveyEntry
                                    key={survey._id}
                                    handleBroadcastSurvey={() => handleBroadcastSurvey(survey._id)}
                                    broadcastSurvey={survey}
                                    group={group}
                                    refetch={refetch}
                                    closeAllModals={closeAllModals}
                                />
                            ))}
                        </div>
                    </main>
                    <footer className="center-modal-header-footer notify-panel-footer">
                        <button type="button" onClick={handleCreateNewSurvey}>
                            {'+ ' + titleCase(t('common:create_new_survey', 'create new survey'))}
                        </button>
                    </footer>
                </>
            )}
        </section>
    );
};

export default BroadcastSurveyModal;
