import React from 'react';

import { LevelState } from '@core/types/Courses';
import { getAggregateSkillsForLevel } from '~/wl-web/utilities/helpers/courses/course.helpers';
import { getSkillIcon } from '~/wl-web/utilities/helpers/lego/skill.helpers';

type CourseFormLevelsProps = {
    levels: LevelState[];
    addLevel: () => void;
    editLevel: (index: number) => void;
    deleteLevel: (index: number) => void;
};

const CourseFormLevels = React.forwardRef<HTMLElement, CourseFormLevelsProps>(
    function CourseFormLevels({ levels, addLevel, editLevel, deleteLevel }, ref) {
        const levelComponents = levels.map((level, index) => (
            <li key={`${level.title}-${index}`}>
                <span>{level.title}</span>
                {getAggregateSkillsForLevel(level).skills.map(skill => (
                    <img key={skill} src={getSkillIcon(skill)} alt={skill} />
                ))}
                <section>
                    <button type="button" onClick={() => editLevel(index)}>
                        Edit
                    </button>
                    <button type="button" onClick={() => deleteLevel(index)}>
                        Delete
                    </button>
                </section>
            </li>
        ));

        return (
            <section ref={ref}>
                <header>Levels</header>
                {levels && <ol>{levelComponents}</ol>}
                <button type="button" onClick={addLevel}>
                    Add Level
                </button>
            </section>
        );
    }
);

export default CourseFormLevels;
