import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import qs from 'query-string';

import { usePreviousRouteContext } from '@components/generic/context/PreviousRouteContext';

/**
 * React hook that allows a component to navigate back to the previous page
 *
 * This is handles navigating back a bit better than a plain history.goBack() call.
 *   e.g. If you navigate to a group via GroupSwitcher, then try to go back: history.goBack() needs to be called twice in order to work properly
 */
const useGoBack = (fallbackRoute?: string) => {
    const history = useHistory();
    const params = qs.parse(window.location.search);

    const { previous: previousRoute = fallbackRoute || '/home', previousRouteExists } =
        usePreviousRouteContext() ?? {};

    // If returnTo is specified as a URL param, go there instead
    const goBack = () => {
        if (params.returnTo === 'string') {
            history.push(params.returnTo);
        } else {
            const route = fallbackRoute ?? previousRoute;
            history.push(route);
        }
    };

    const debouncedGoBack = useCallback(debounce(goBack, 300), []);

    return { goBack, debouncedGoBack, previousRouteExists };
};

export default useGoBack;
