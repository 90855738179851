import React from 'react';
import { Updater } from 'use-immer';
import { useTranslation } from 'react-i18next';

import Toggle from '@web/ui/dsc/forms/customInputs/Toggle';
import IncrementStepper from '@web/ui/dsc/forms/customInputs/IncrementStepper';

import { curriedStateSlice } from '@web/utilities/helpers/state/state.helpers';

import { CreationStateSettings } from '@core/types/Creations';

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard';

type CreationFormSettingsProps = {
    hasAdminRole?: boolean;
    state: CreationStateSettings;
    setState: Updater<CreationStateSettings>;
};

const CreationFormSettings = React.forwardRef<HTMLElement, CreationFormSettingsProps>(
    function CreationFormSettings({ hasAdminRole, state, setState }, ref) {
        const { t } = useTranslation();

        const updateSlice = curriedStateSlice(setState);

        return (
            <section className="settings" ref={ref}>
                {hasAdminRole && (
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label>
                        {t(`common:${localNamespace}.postBoxUpdateForm.display_order`)}
                        <IncrementStepper
                            value={state.rankOrder}
                            onChange={updateSlice('rankOrder')}
                        />
                    </label>
                )}
                {hasAdminRole && (
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label>
                        {t(`common:${localNamespace}.mediaCardUpdateForm.display_in_newsfeed`)}
                        <Toggle
                            value={state.displayInNewsfeed}
                            onChange={updateSlice('displayInNewsfeed')}
                        />
                    </label>
                )}
            </section>
        );
    }
);

export default CreationFormSettings;
