/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from '@web/ui/components/generic/forms/TextArea';
import styled from 'styled-components';
import Input from '@web/ui/components/generic/forms/Input.js';
import { getCountryPicker, renderCountryFlag } from '@core/utilities/constants/countries';
import { getWhitelabeledKey } from '@core/utilities/constants/languages';
import PhoneInput from 'react-phone-number-input';
import { usePopper } from 'react-popper';

import { config } from '@core/config/getConfig';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.card.pledgeCard';

const RECRUIT_TYPE = {
    EXISTING: 'EXISTING',
    NEW: 'NEW',
    POTENTIAL: 'POTENTIAL',
    NONE: 'NONE',
};

const RECRUIT_NAME = {
    [RECRUIT_TYPE.EXISTING]: `common:${localNamespace}.recruit_question.existing`, //'Already a Recruit',
    [RECRUIT_TYPE.NEW]: `common:${localNamespace}.recruit_question.new`,
    [RECRUIT_TYPE.POTENTIAL]: `common:${localNamespace}.recruit_question.potential`,
    [RECRUIT_TYPE.NONE]: `common:${localNamespace}.recruit_question.none`,
};

const PopperContainer = styled.div`
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    text-align: center;

    #arrow {
        position: absolute;
        width: 10px;
        height: 10px;
        &:after {
            content: ' ';
            background-color: white;
            box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
            position: absolute;
            top: -25px; // padding + popper height
            left: 0;
            transform: rotate(45deg);
            width: 10px;
            height: 10px;
        }
    }

    &[data-popper-placement^='top'] > #arrow {
        bottom: -30px;
        :after {
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        }
    }
`;

const PledgeQuestions = props => {
    const { t } = useTranslation();
    const {
        handleSelectRecruit,
        handlePhone,
        close,
        updateTaskActivity,
        activityId,
        pledgeItem,
        editMode,
        submit,
        state,
        handleChange,
        whitelabelVerbage,
    } = props;
    const [formValid, setFormValid] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');

    const [showPopper, setShowPopper] = useState(false);

    const buttonRef = useRef(null);
    const popperRef = useRef(null);
    // the ref for the arrow must be a callback ref
    const [arrowRef, setArrowRef] = useState(null);

    const { styles, attributes } = usePopper(buttonRef.current, popperRef.current, {
        modifiers: [
            {
                name: 'arrow',
                options: {
                    element: arrowRef,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
        ],
    });

    const handleClose = e => {
        if (!editMode) close();
        if (editMode) close();
    };

    const handleSubmit = e => {
        if (editMode) {
            const { state } = props;
            const _name = state.name;
            const _email = state.email;
            const _phone = state.phone;
            const _country = state.country;
            const _note = state.note;
            const _newRecruit = state.newRecruit;
            const _recruitType = state.recruitType;

            let enrollEmailTemplateOverride;
            let enrollSmsBodyOverride;
            if (config.public.pledge) {
                const {
                    new_recruit_template,
                    failed_recruit_template,
                    new_recruit_sms_override,
                    failed_recruit_sms_override,
                } = config.public.pledge;
                enrollEmailTemplateOverride = _newRecruit
                    ? new_recruit_template
                    : failed_recruit_template;
                enrollSmsBodyOverride = _newRecruit
                    ? new_recruit_sms_override
                    : failed_recruit_sms_override;
            }

            updateTaskActivity({
                variables: {
                    taskId: state.activityId,
                    name: _name,
                    email: _email ? _email.trim().toLowerCase() : null,
                    phone: _phone,
                    country: _country,
                    note: _note,
                    newRecruit: _newRecruit,
                    recruitType: _recruitType,
                    enrollEmailTemplateOverride,
                    enrollSmsBodyOverride,
                },
            })
                .then(() => handleClose())
                .catch(e => logger.error(e));
        }
        if (!editMode) {
            submit();
        }
    };

    useEffect(() => {
        handleValidate();
    }, [state]);

    const handleValidate = e => {
        const nameValid = state.name && state.name.trim() != '';
        const countryValid = state.country && state.country !== '' && state.country !== '-none-';

        // Phone or Email not required currently.
        //const phoneValid = state && state.phone !== null && state.phone !== '' && state.phone !== undefined;
        //const emailValid = state && state.email !== null && state.email !== '' && state.email !== undefined;
        const contactValid = true; //phoneValid || emailValid;

        const recruiterValid = state && state.recruitType !== null && state.recruitType !== '';
        if (nameValid && countryValid && contactValid && recruiterValid) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    };

    const onChange = e => {
        handleChange(e);
        // handleValidate();
    };

    useEffect(() => {
        handleValidate();
    }, [state]);

    const title = !editMode
        ? t(getWhitelabeledKey(`common:${localNamespace}.verification_title`, whitelabelVerbage))
        : t(
              getWhitelabeledKey(
                  `common:${localNamespace}.edit_verification_title`,
                  whitelabelVerbage
              )
          );

    return (
        <div className="modal-form-container">
            <div className="modal-form-box">
                <div>
                    <div className="modal-form-title-bar">
                        <h4>{title}</h4>
                        <button
                            onClick={handleClose}
                            className="cancel-edit-content-button w-inline-block"
                        />
                    </div>

                    <div className="questions-list">
                        <ul className="w-list-unstyled pledge-list">
                            <li>
                                <p>{t(`common:${localNamespace}.verification_question_name`)}</p>
                                <Input
                                    handleChange={onChange}
                                    value={state.name}
                                    type="text"
                                    classNames="text-field title-field w-input"
                                    maxLength="256"
                                    name="name"
                                    placeHolder={t(`common:global.form.name`)}
                                    required
                                />
                            </li>

                            <li>
                                <p>{t(`common:${localNamespace}.verification_question_email`)}</p>
                                <Input
                                    handleChange={onChange}
                                    value={state.email}
                                    type="email"
                                    classNames="text-field title-field w-input"
                                    maxLength="256"
                                    name="email"
                                    placeHolder={t(`common:global.form.email.title`)}
                                />
                            </li>
                            <li>
                                <p>{t(`common:${localNamespace}.verification_question_phone`)}</p>

                                <div className="pledge-phone-input">
                                    <PhoneInput
                                        name="phone"
                                        placeholder={t(`common:global.form.phone`)}
                                        value={state.phone}
                                        onChange={handlePhone}
                                    />
                                </div>
                            </li>

                            <li>
                                <p>{t(`common:${localNamespace}.verification_question_country`)}</p>
                                <div className="signup-select-field-wrapper">
                                    {state.country &&
                                        renderCountryFlag({ countryCode: state.country })}
                                    {getCountryPicker({
                                        value: state.country,
                                        handleChange: e => handleChange(e),
                                        id: 'country',
                                        name: 'country',
                                        className: 'signup-select-field light-background-select',
                                    })}
                                </div>
                            </li>

                            <li>
                                <div className="">
                                    <div>
                                        <p>
                                            {t(
                                                getWhitelabeledKey(
                                                    `common:${localNamespace}.new_recruit`,
                                                    whitelabelVerbage
                                                )
                                            )}
                                        </p>
                                    </div>
                                    <div className="signup-select-field-wrapper">
                                        <select
                                            className="signup-select-field light-background-select"
                                            name="recruit-type-select"
                                            value={state.recruitType}
                                            onChange={handleSelectRecruit}
                                        >
                                            <option value="">
                                                {t(
                                                    getWhitelabeledKey(
                                                        `common:${localNamespace}.recruit_question.select`,
                                                        whitelabelVerbage
                                                    )
                                                )}
                                            </option>
                                            <option value={RECRUIT_TYPE.EXISTING}>
                                                {t(
                                                    getWhitelabeledKey(
                                                        RECRUIT_NAME[RECRUIT_TYPE.EXISTING],
                                                        whitelabelVerbage
                                                    )
                                                )}
                                            </option>
                                            <option value={RECRUIT_TYPE.NEW}>
                                                {t(
                                                    getWhitelabeledKey(
                                                        RECRUIT_NAME[RECRUIT_TYPE.NEW],
                                                        whitelabelVerbage
                                                    )
                                                )}
                                            </option>
                                            <option value={RECRUIT_TYPE.POTENTIAL}>
                                                {t(
                                                    getWhitelabeledKey(
                                                        RECRUIT_NAME[RECRUIT_TYPE.POTENTIAL],
                                                        whitelabelVerbage
                                                    )
                                                )}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <p>{t(`common:${localNamespace}.verification_additional_notes`)}</p>
                                <TextArea
                                    handleChange={onChange}
                                    maxCharacter={2000}
                                    value={state.note}
                                    name="note"
                                    placeHolder={t(`common:global.form.addition_notes`)}
                                    defaultClasses="text-field w-input"
                                    minHeight="130px"
                                />
                            </li>
                        </ul>
                        <p style={{ fontWeight: 'bold' }}>
                            *
                            {t(
                                getWhitelabeledKey(
                                    `common:${localNamespace}.recruit_question.required`,
                                    whitelabelVerbage
                                )
                            )}
                        </p>
                        {window.innerWidth < 479 && (
                            <p style={{ fontWeight: 'bold' }}>
                                {t(
                                    getWhitelabeledKey(
                                        `common:${localNamespace}.recruit_question.tooltip`,
                                        whitelabelVerbage
                                    )
                                )}
                            </p>
                        )}
                    </div>
                    <div className="modal-footer-box">
                        <div className="status-msg err-msg">{statusMsg}</div>
                        <button
                            type="submit"
                            className={
                                formValid ? 'modal-cta-button' : 'modal-cta-button disabled-button'
                            }
                            onClick={formValid ? handleSubmit : null}
                            onMouseLeave={() => setShowPopper(false)}
                            onMouseEnter={() => setShowPopper(true)}
                            ref={buttonRef}
                        >
                            {t(`common:${localNamespace}.verification_submit`)}
                        </button>
                        {showPopper ? (
                            <PopperContainer
                                ref={popperRef}
                                style={styles.popper}
                                {...attributes.popper}
                            >
                                <div ref={setArrowRef} style={styles.arrow} id="arrow" />
                                <p>
                                    {t(
                                        getWhitelabeledKey(
                                            `common:${localNamespace}.recruit_question.tooltip`,
                                            whitelabelVerbage
                                        )
                                    )}
                                </p>
                            </PopperContainer>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PledgeQuestions;
