import { createStore } from '@udecode/zustood';

const defaultLiveUpdateState: {
    status?: 'IDLE' | 'DOWNLOAD' | 'EXTRACT' | 'COMPLETE';
    statusMessage?: string | null;
    downloadProgress?: number;
    extractProgress?: number;
    updateAvailable?: boolean;
    errorMessage?: string;
    showModal?: boolean;
} = {
    status: 'IDLE',
    statusMessage: null,
    downloadProgress: 0,
    extractProgress: 0,
    updateAvailable: null,
    errorMessage: null,
    showModal: false,
};

const liveUpdateStore = createStore('liveUpdate')(defaultLiveUpdateState);

export default liveUpdateStore;
