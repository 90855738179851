import React from 'react';

import Picture from '@components/generic/Picture';
import ResourceIcon from '@components/card/coursecard/ResourceIcon';

import { isLevelComplete } from '@helpers/courses/course.helpers';
import { getCreationImage } from '@helpers/creations/creation.helpers';

import { ContentCard, CreationState } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

type CreationViewTabProps = {
    card?: Maybify<ContentCard> | null;
    state?: Maybify<CreationState> | null;
    activeScreen?: string;
};

const CreationViewTab = React.forwardRef<HTMLDivElement, CreationViewTabProps>(
    function CreationViewTab({ card, state, activeScreen }, ref) {
        const resources = card?.creationItems?.course?.courseItems?.levels?.map((level, index) => {
            return level?.resource ? (
                <ResourceIcon
                    key={level.title}
                    type={level.resource}
                    quantity={3}
                    checked={isLevelComplete(index, state)}
                />
            ) : (
                <></>
            );
        });

        const creationImg = getCreationImage(card, state);

        return (
            <div className="content-wrapper" ref={ref}>
                {activeScreen && (
                    <div className="centered">
                        <div className="resources-icons-list">{resources}</div>

                        <Picture
                            className="creation-large-thumb fadein ratio-wrapper"
                            disableLink
                            url={creationImg}
                        />
                    </div>
                )}
            </div>
        );
    }
);

export default CreationViewTab;
