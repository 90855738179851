import React from 'react';

import Picture from '@web/ui/components/generic/Picture';
import Rainbow from '@web/ui/dsc/svgs/Rainbow';

type RainbowWithImageProps = { url: string };

const RainbowWithImage: React.FC<RainbowWithImageProps> = ({ url }) => {
    return (
        <section className="rainbow-with-image">
            <Rainbow />
            <Picture url={url} disableLink />
        </section>
    );
};

export default RainbowWithImage;
