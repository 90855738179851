import React from 'react';

const MapNavIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg
            width="57"
            height="50"
            viewBox="0 0 57 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M56.9416 38.9589C56.7526 36.3599 56.5636 33.3047 56.3746 29.7902C56.2226 26.7626 56.0512 23.0561 55.8622 18.6738L55.4608 0L36.5777 9.21477L35.8101 9.24549L18.8987 2.64201L0 10.9889C0.188987 13.5971 0.379922 16.6677 0.568909 20.1991C0.720878 23.2266 0.89233 26.9331 1.08132 31.3155C1.11833 32.1726 1.15535 33.0558 1.19237 33.9636L1.53917 50L20.8002 40.6009L38.1013 47.358L57 39.0126L56.9435 38.9589H56.9416ZM7.45816 39.7576C7.45816 38.4965 7.45232 36.9866 7.44452 35.2278C7.43478 33.4706 7.42504 31.4092 7.4153 29.0452C7.40556 26.6812 7.40166 23.9824 7.40166 20.9441V14.104L15.5788 10.1456C15.5788 10.373 15.5827 10.6049 15.5827 10.8445C15.5827 12.1056 15.5865 13.6156 15.5963 15.3743C15.606 17.1316 15.6158 19.193 15.6255 21.5569C15.6353 23.9209 15.6392 26.6198 15.6392 29.6581V35.8361L7.46206 40.3029C7.46206 40.1247 7.46011 39.9435 7.45816 39.7576ZM22.4719 30.4031C22.3199 27.3755 22.1485 23.669 21.9595 19.2867C21.8348 16.4004 21.714 13.2254 21.5971 9.76621L33.4585 14.442C33.5364 15.7399 33.6143 17.1147 33.6923 18.5678C33.8442 21.5953 34.0157 25.3018 34.2047 29.6842C34.3372 32.7686 34.4677 36.1801 34.5904 39.9189L22.7505 35.2508C22.657 33.7455 22.5654 32.1296 22.4719 30.4015V30.4031ZM40.5815 38.1263C40.5815 36.8652 40.5757 35.3553 40.5679 33.5965C40.5581 31.8393 40.5484 29.7779 40.5387 27.4139C40.5289 25.0499 40.525 22.3511 40.525 19.3128V14.6201L49.4795 9.72935C49.4795 9.89524 49.4814 10.0627 49.4834 10.2332C49.4834 11.4943 49.4873 13.0042 49.497 14.763C49.5068 16.5202 49.5165 18.5816 49.5263 20.9456C49.536 23.3096 49.5399 26.0084 49.5399 29.0467V35.9252L40.5971 40.2538C40.5913 39.6025 40.5874 38.8943 40.5815 38.1263Z" />
        </svg>
    );
};

export default MapNavIcon;
