import React from 'react';
import { useTranslation } from 'react-i18next';

import SuggestedBlock from '@web/ui/components/messaging/messageOverlay/SuggestedBlock';
import { Virtuoso } from 'react-virtuoso';
import { User } from '@shared/welibrary-graphql/types';
import MessageThreadActions from '@core/utilities/constants/thread';
// bring in launchdarkly-react-client-sdk
import { useFlags } from 'launchdarkly-react-client-sdk';

const localNamespace = 'imports.wlWeb.ui.components.messaging.suggestedList';

type CustomSuggestionText = {
    empty?: string;
    results?: string;
};

type SuggestedListProps = {
    handleClick: (e: React.MouseEvent<HTMLElement>) => void;
    suggestType: MessageThreadActions;
    suggestions: User[];
    searchQuery?: string;
    customText?: CustomSuggestionText;
    wholeItemClick?: boolean;
    showMsgUser?: boolean;
};

const SuggestedList: React.FC<SuggestedListProps> = ({
    handleClick,
    suggestType,
    suggestions,
    searchQuery,
    customText,
    showMsgUser,
    wholeItemClick = true,
}) => {
    const { t } = useTranslation();
    // useFlags hook to get the flags
    const flags = useFlags();
    // get the siloedUsers flag
    const siloedUsers = flags?.siloedUsers;

    const suggestionsList = suggestions && (
        <Virtuoso
            style={{ height: '100%' }}
            className="virtuoso-list"
            data={suggestions}
            overscan={100}
            itemContent={(index, suggestion) => {
                return (
                    <SuggestedBlock
                        handleClick={handleClick}
                        isLastItem={index === suggestions?.length - 1}
                        wholeItemClick={wholeItemClick}
                        showMsgUser={showMsgUser}
                        suggestType={suggestType}
                        key={suggestion?._id || index}
                        data={suggestion}
                    />
                );
            }}
        />
    );

    const suggestionsCount = suggestions?.length;

    const resultsText = customText?.results || `${suggestionsCount} results for "${searchQuery}"`;
    let emptyText = customText?.empty || t(`common:${localNamespace}.connections`);
    if (suggestionsCount === 0) {
        emptyText = '';
    }

    return (
        <>
            {/* conditionally rendering the "Suggested Connections" text based on the value of the `siloedUsers` variable. */}
            {!siloedUsers && (
                <div className="inbox-title">{searchQuery ? resultsText : emptyText}</div>
            )}
            {/* conditionally renders the `suggestionsList` component based on the logical expression `(!siloedUsers || searchQuery)`. */}
            <div className="suggested-connections h-100-w-100">
                {(!siloedUsers || searchQuery) && suggestionsList}
            </div>
        </>
    );
};

export default SuggestedList;
