import { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';

import getLogger from '@core/logger';

const logger = getLogger(module);

/**
 * React Hook for handling cancelable timers
 *
 * Use like so:
 *
 * const timer = useCancelableTimer(500);
 *
 * timer.start(() => logger.info('This will be cancelled'));
 * timer.cancel();
 * timer.start(() => logger.info('This will be show in 500ms!'));
 *
 * If startResets is set to false, calling timer.start before the first time has been finished or
 * cancelled will prevent the first timer from being cancelled. This will trigger a warning which
 * can be disabled by passing false as the second argument to the hook. Otherwise, calling
 * timer.start repeatedly will reset the timer each time it is called.
 */
const useCancelableTimer = (delay = 300, startResets = true, duplicateTimerWarning = true) => {
    const timeout = useRef<NodeJS.Timeout | false>(false);

    const start = useCallback(
        (callbackFunction?: () => void, delayOverride = delay) => {
            if (startResets && timeout.current) clearTimeout(timeout.current);
            if (timeout.current && duplicateTimerWarning && !startResets)
                logger.warn(
                    `Warning a second timer was started before the first one finished! Please use a 
                    second version of this hook to keep track of both timers, or cancel the first one 
                    before starting another. The first timer will still work, but is no longer 
                    cancelable`
                );
            timeout.current = setTimeout(() => {
                callbackFunction?.();
                timeout.current = false;
            }, delayOverride);
        },
        [delay]
    );

    const cancel = useCallback(
        _.throttle(() => {
            if (timeout.current) clearTimeout(timeout.current);
            timeout.current = false;
        }, 100),
        []
    );

    useEffect(
        () => () => {
            if (timeout.current) clearTimeout(timeout.current);
        },
        []
    );

    return { start, cancel, isRunning: () => !!timeout.current };
};

export default useCancelableTimer;
