import React from 'react';

import { Maybify } from '@core/types/Utilities';
import { SurveyQuestion } from '@shared/welibrary-graphql/types';

type SurveyLongAnswerProps = {
    state: string;
    setState: (value: string) => void;
    question: Maybify<SurveyQuestion>;
    className?: string;
    disabled?: boolean;
    viewOnly?: boolean;
};

const SurveyLongAnswer: React.FC<SurveyLongAnswerProps> = ({
    state,
    setState,
    question,
    className = '',
    disabled = false,
    viewOnly = false,
}) => {
    return (
        <section className={`survey-question-long-answer ${className}`}>
            {!viewOnly && (
                <>
                    <p>{question.prompt}</p>

                    <textarea onChange={e => setState(e.target.value)} disabled={disabled}>
                        {state}
                    </textarea>
                </>
            )}
            {viewOnly && <p>{state}</p>}
        </section>
    );
};

export default SurveyLongAnswer;
