import { ConfigInputType } from '@config/schemas/main';
import EventTypes from '@core/utilities/constants/events';

const config: ConfigInputType = {
    public: {
        root_url: 'app.nickv.network',
        apiUrl: 'api.app.commissioned.global',
        whitelabel_url: 'app.nickv.network',
        deep_link_domains: [
            'https://app.commissioned.global',
            'https://app.becommissioned.com',
            'https://app.inthezone.social',
            'https://inthezone.social',
            'https://www.inthezone.social',
            'https://app.nickv.network',
        ],

        configName: 'production-nickv',
        captcha: {
            key: '6LdHxaglAAAAABFfI7N2Ru-e_8s3XlioNvjbar_h',
        },
        siteName: 'nickv.network',
        metaInfo: {
            title: 'Join The Fastest Growing Global Network | NickV Network',
            description:
                'NickV Network for growth, and scalability for churches, businesses, content creators, and individuals. A platform for team and member management.',
            image: '/images/commissioned.logoblack.full.svg',
            url: 'app.nickv.network',
        },
        displayPlatformFormspec: false,
        copyright: 'NickV Network',

        eventTypes: [EventTypes.EVENT_GROUP, EventTypes.EVENT_LISTING],

        rootGroupId: '654290a17625b92556ded85f',
        hideRootGroupDeleteButton: true,

        filestack: { api_key: 'A7RsW3VzfSNO2TCsFJ6Eiz', filePreview: true },
        unsplash: { access_key: 'NTNacJWbx9pMW5yHizHSqWr1IpQXmTJTuQ08VZ7pSEg' },
        Google: { Maps_API_Key: 'AIzaSyBDfzEYNC2sHOYi9OEwmwNVVtOMDFfUV5I' },
        GATracking: { GA_ID: 'G-TDXCZJ2DXC' },
        sentry: {
            dsn: 'https://bd4f25c0ac1c4278b822ee7b34fdb036@o246842.ingest.sentry.io/5199016',
        },
        stripe: {
            client_api_key:
                'pk_live_51JIeveETSMEXfAr3ggqmxBjwTD1P50EVbPrKNoJkZwcxQKgXsAOncGj3SGV3S8Gf0f0hTXurPhAWuOSlQx0DmL8u00Uinne8WH',
            secret_webhook_key: 'whsec_unMQEAXsMGitj3e5bdWZdk4pEwsjh76A',
        },
        userflow: { api_key: 'ct_a4o665523nbjdgnwvidhtxrdma' },
        showGettingStarted: true,
        defaultMembershipUrl: 'welibrary',
        defaultJoinInstructions: 'commissioned_join_instructions',
        defaultGroupCodePlaceholder: 'commissioned_shortcode_placeholder',
        defaultGroupCode: 'NICKVNETWORK',
        hideGroupHeaderInfoFromLoggedOutUsers: true,
        homeButton: {
            picture: 'https://cdn.filestackcontent.com/YB8QINnQHKaUu8Y6S2My',
            title: 'nickv.network',
            groupId: '654290a17625b92556ded85f',
        },

        homeGroups: {
            groupIds: ['654290a17625b92556ded85f', '63fe33a0c9f8e7afd736a591', '8y2CNH8ToRXA4svzR'],
        },
        disableInlinePrefilledSignupLink: true,
        restrictSignupToDefaultMembership: true,

        communityGuidelines: 'nickv.network/community-guidelines',
        privacyPolicy: 'nickv.network/privacy-policy',
        termsOfService: 'nickv.network/terms-of-service',
        templatesUrl: 'templates',

        userGuide: 'support.nickv.network/home/',

        defaultLogoBackup:
            'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/QtupJZKySkipzwKVNmUc',
        defaultLogoBackupWhite:
            'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/QtupJZKySkipzwKVNmUc',
        defaultLogoBackupWhiteAlt:
            'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/QtupJZKySkipzwKVNmUc',
        defaultLogoBlack:
            'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/HXkydszPQ6G3fGAjDjdW',

        pushSenderID: 541036629111,
        pushLogo: 'co_notification',

        recommendationsMatchmakerWhitelist: ['welibrary'],

        userSnap: false,
        userSnapOverrideSrc: '//api.usersnap.com/load/81dfe074-2710-40d9-b130-d0c9be1c2c30.js',
        userSnapTesterEmails: [
            'jkramer@pneuma33.com',
            'jkramer@commissioned.io',
            'sthomas@pneuma33.com',
            'mriddell@commissioned.io',
            'michelle43770@yahoo.com',
            'kmonaghan@commissioned.io',
        ],

        bulkUserTemplate:
            'https://docs.google.com/spreadsheets/d/1kkqURN4VHJuj6KZVMMSRRuWGdV_KvdWz5BeoYj9q1SQ/edit?usp=sharing',
        bulkGenerateUserTemplate:
            'https://docs.google.com/spreadsheets/d/1LSN6MLgMmyHToHGpA4EEHZFtGPjLWxn3GsmbgM71Rdw/edit?usp=sharing',

        brandColor1: '#212121',
        brandColor2: '#ffffff',
        favicon: '/images/favicon/nickv/favicon.ico',
        theme: {
            primaryColor: '#b22f2f',
            primaryAccent: '#f2cf2f',
            primaryHighlight: '#81d742',
            primaryShade: '#670f10',
            thumbHighlight: '#b22f2f',
            secondaryColor: '#b22f2f',
            slideBackground:
                'url(https://cdn.filestackcontent.com/auto_image/1Wjcy7OYQCqn7b6brH4Z)',
            emptyBackground:
                'url(https://cdn.filestackcontent.com/auto_image/rZZ11ay3TYCJZNyJ1dWq)',
            coffeeBackground:
                'url(https://cdn.filestackcontent.com/auto_image/inBO4fCkSxWq6ph1lbtw)',
            docBackground: 'url(https://cdn.filestackcontent.com/auto_image/Efr6ADNQQrJsAKtcUFZb)',
        },

        companyAddress: '600 Millennium Dr Suite 213 Allen, TX 75013',

        allowAnonymousSignups: false,
        hidePlatformGroupHeaderDisplay: true,

        pledge: {
            new_recruit_template: 'd-61eb02daf35440d59672a2acbea1a688',
            new_recruit_sms_override:
                'Congratulations on your decision to follow Jesus. To grow stronger go to https://comm.gl/frstgr. Join https://comm.gl/omwnew to grow in this faith adventure!',
            failed_recruit_template: 'd-beedc18c284c43789a2e010ceffde9e8',
            failed_recruit_sms_override:
                'Hello. Thanks for letting me share about my faith-story. I thought you might be interested in checking out my website www.frostygrapes.com. Enjoy!',
            already_recruit_template: 'd-5402723fa68b406eb9b95e9dd8504bbf',
            already_recruit_sms_override:
                'Hello, I’m glad that you’re a follower of Jesus. Learn how to tell your faith-story with pre-believers https://comm.gl/omw. You will have great joy sharing!',
            new_recruit_claim_reminder_template: 'd-4fdcddd98bc74803959ad4a51fe02b77',
            new_recruit_claim_reminder_two_template: 'd-081324f4d43048c9bfdb9e2b4196d771',
        },

        domainBranding: {
            commissioned: {
                root_url: 'app.commissioned.global',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/MVyrZRrrRSC6gXGdBHYc',
                defaultGroupCode: 'COMMISSIONED',
            },
            localhost: {
                root_url: 'app.nickv.network',
                defaultCoverPhoto:
                    'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/N1KsunCQqK49JdmJW3Vz',
                defaultGroupCode: 'NICKVNETWORK',
            },
            nickv: {
                root_url: 'app.nickv.network',
                defaultCoverPhoto:
                    'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/N1KsunCQqK49JdmJW3Vz',
                defaultGroupCode: 'NICKVNETWORK',
            },
            inthezone: {
                root_url: 'www.inthezone.social',
                defaultCoverPhoto:
                    'https://cdn.filestackcontent.com/rotate=deg:exif/auto_image/m2ZTxcCBRVqLBEgBapY9',
                defaultGroupCode: 'INTHEZONE',
            },
        },
        slideMenu: {
            actionItems: true,
            mobilization: true,
            search: true,
            discover: true,
            footerText: 'common:global.patent_pending',
            showConnectionsIcon: true,
            showBookmarkIcon: true,
        },

        secondaryNavigation: {
            items: {
                mobile: ['navigator', 'groups', 'home', 'messages', 'discover'],
                desktop: ['groups', 'home', 'messages', 'discover', 'notifications', 'profile'],
            },
        },

        locize: {
            projectId: '662a9e61-2900-409c-a73e-6ba0bbfc3f38',
            apiKey: '3ff8b824-d205-42ea-be99-a366868d9f73',
            referenceLng: 'en',
        },

        ceramic: {
            nodeEndpointUrl: 'https://ceramic-node.welibrary.io:7007',
            contentFamily: 'SuperSkills',
            definitions: {
                MyVerifiableCredentials:
                    'kjzl6cwe1jw14am5tu5hh412s19o4zm8aq3g2lpd6s4paxj2nly2lj4drp3pun2',
            },
            schemas: {
                AchievementVerifiableCredential:
                    'ceramic://k3y52l7qbv1frylibw2725v8gem3hxs1onoh6pvux0szdduugczh0hddxo6qsd6o0',
                VerifiableCredentialsList:
                    'ceramic://k3y52l7qbv1frxkcwfpyauky3fyl4n44izridy3blvjjzgftis40sk9w8g3remghs',
            },
        },

        appflow: {
            liveUpdate: {
                appId: '78ad487c',
                channel: 'production-nickv',
                updateMethod: 'none',
            },
        },

        eventOrganizerTermsOfUseUrl: 'https://nickv.network/event-organizer-terms-of-use/',
        ticketPurchaserTermsOfUseUrl: 'https://nickv.network/ticket-purchaser-terms-of-use/',

        launchDarklyConfig: {
            clientSideID: '655cf6201d32ab1223ac33bc',
            context: {
                kind: 'user',
                key: 'anonymous',
            },
        },

        poweredBy: {
            name: "Commissioned",
            logo: "https://cdn.filestackcontent.com/3crfGTRyQIDi0VcVlWBQ"
        },
    },
};

export default config;
