import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';
import useTimer from '@components/generic/hooks/useTimer';

import Picture from '@components/generic/Picture';

import {
    getRandomDefaultCoverImage,
    getRandomDefaultThumbImage,
} from '@core/utilities/constants/defaults';
import { getAggregateSkillsForLevels } from '@helpers/courses/course.helpers';
import { getSkillIcon } from '@helpers/lego/skill.helpers';

import { ContentCard } from '@shared/welibrary-graphql/types';

type CourseItemProps = {
    card: Partial<ContentCard>;
    onLoad?: () => void;
};

const CourseItem: React.FC<CourseItemProps> = ({ card, onLoad }) => {
    const { t } = useTranslation();

    const { closeModal } = useModal();

    const timer = useTimer();

    useEffect(() => {
        if (onLoad) timer(1, onLoad);
    }, []);

    const mediaThumb = card.media?.thumb || getRandomDefaultCoverImage(card.title ?? '');
    const mediaIcon = card.media?.icon || getRandomDefaultThumbImage(card.title ?? '');

    const materials = card.courseItems?.materials?.filter(Boolean) ?? [];
    const aggregateSkills = getAggregateSkillsForLevels(card.courseItems?.levels).skills;

    return (
        <article className="course-item">
            <header>
                <Picture url={mediaThumb} disableLink className="cover" />
                <Picture url={mediaIcon} disableLink className="thumb" />
            </header>

            <h3>{card.title}</h3>
            {(materials.length ?? 0) > 0 && <p>{`Materials: ${card.courseItems.materials}`}</p>}

            {aggregateSkills.length > 0 && (
                <section className="aggregate-skills">
                    {aggregateSkills.map((skill, index) => (
                        <img src={getSkillIcon(skill)} alt={skill} key={index} />
                    ))}
                </section>
            )}

            <Link to={`/course/${card.url}`} onClick={closeModal}>
                {t('common:global.verbs.begin', 'Begin')}
            </Link>
        </article>
    );
};

export default CourseItem;
