import React, { useContext } from 'react';
import { useImmer } from 'use-immer';

import { useUpdateUserMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import ToastContext from '@components/generic/context/ToastContext';
import { useCurrentUser } from '@stores/User';

import HeroCreator from '@components/profile/Lego/HeroCreator';
import CustomLegoToastFooter from '@components/toast/Lego/CustomLegoToastFooter';
import SSLIcon from '@assets/lego-ssl-icon.png';

import { getUserAvatar } from '@helpers/user/avatar.helpers';
import { GET_CHILD_ACCOUNTS } from '@shared/welibrary-graphql/user/queries';

import { UserSimpleProfileState } from '@core/types/Avatars';
import { isEphemeral } from '@web/utilities/helpers/user/child.helpers';
import { User } from '@shared/welibrary-graphql/types';

type EditSimpleProfileProps = {
    onSubmit: () => void;
    onCancel: () => void;
    childAccount?: User;
    randomize?: boolean;
    notLoggedInUser?: boolean;
};

// This EditSimpleProfile component is used in 2 places

// * 1. The EditSimpleProfile is accessed from within the account-switcher
// * when editing a child-account, hence it requires the child user obj.

// * 2. The EditSimpleProfile is accessed from outside the account-switcher,
// * IE: the profile tab on the lego-navigation ... this will edit the
// * currentUser -> current active child.

const EditSimpleProfile: React.FC<EditSimpleProfileProps> = ({
    onSubmit,
    onCancel,
    childAccount,
    randomize = false,
    notLoggedInUser = false,
}) => {
    const { currentUser, refetchCurrentUser } = useCurrentUser();
    const { showCustomToast } = useContext(ToastContext);

    const [updateUser] = useUpdateUserMutation();

    const activeChildAcct = currentUser?.profile; // current active user / child account data
    const editingChildAcct = childAccount?.profile; // child account being editied from within account-switcher

    const activeChildAcctName = activeChildAcct?.simple?.name || activeChildAcct?.full_name || '';
    const editingChildAcctName =
        editingChildAcct?.simple?.name || editingChildAcct?.full_name || '';

    const [state, setState] = useImmer<UserSimpleProfileState>({
        avatar: getUserAvatar(editingChildAcct?.avatar || activeChildAcct?.avatar, randomize),
        name: editingChildAcctName || activeChildAcctName,
        age: (editingChildAcct?.simple?.age || activeChildAcct?.simple?.age) ?? undefined,
    });

    const submit = () => {
        if (currentUser && typeof Number(state.age) === 'number') {
            updateUser({
                variables: {
                    _id: childAccount?._id || currentUser._id,
                    simple: { name: state.name, age: Number(state.age) },
                    avatar: state.avatar,
                },
                refetchQueries: [GET_CHILD_ACCOUNTS],
            }).then(() => {
                refetchCurrentUser();
                if (isEphemeral(currentUser)) {
                    showCustomToast(
                        'Your hero is Updated! To SAVE YOUR HERO, please create an account.',
                        null,
                        5000,
                        'responsive',
                        SSLIcon,
                        <CustomLegoToastFooter showVerify shouldCloseModal />
                    );
                }
            });
        }

        onSubmit();
    };

    return (
        <HeroCreator
            state={state}
            setState={setState}
            onSubmit={submit}
            onCancel={onCancel}
            notLoggedInUser={notLoggedInUser}
        />
    );
};

export default EditSimpleProfile;
