/* eslint-disable react/jsx-one-expression-per-line */
import React, { useRef, useState, useEffect } from 'react';
import { Route, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import { useCurrentUser } from '@stores/User';
import useGroupActions from './hooks/useGroupActions';
import useModal from '@components/modals/hooks/useModal';

import loadable from '@loadable/component';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary.js';
import GroupAnnouncementPostBox from '@web/ui/components/group/groupdashboard/GroupAnnouncementPostBox';
import GroupDashboardHeader from '@web/ui/components/group/groupdashboard/GroupDashboardHeader';
import MobilizationForm from '@web/ui/components/group/groupdashboard/GroupMobilizationForm';
import ConnectedAccountSuccess from '@components/group/groupdashboard/stripe/ConnectedAccountSuccess';
import ConnectedAccountRefresh from '@components/group/groupdashboard/stripe/ConnectedAccountRefresh';
import NotifyPanel from '@web/ui/components/engagement/NotifyPanel';
import ManageUserGroupSubscription from '@components/group/groupdashboard/groupSubscriptionPaywall/ManageUserGroupSubscription';
import TicketItem from '@components/card/Shopping-Cart/TicketItem';
import StripeOrderDetails from '@components/profile/stripe/StripeOrderDetails';

import Checkout from '@components/card/Shopping-Cart/Checkout';
import GroupDashboardNewsFeedTypeWrapper from '@components/group/groupdashboard/GroupDashboardNewsFeedTypeWrapper';
import ShoppingCartFixedButton from '@components/card/Shopping-Cart/ShoppingCartFixedButton';

import { UpdateEventGroupForm } from '@components/content/newpost/forms/EventGroup/UpdateEventGroupForm';
import { getForm } from '@web/ui/components/content/newpost/newposthelpers';

import { ModalTypes } from '@core/types/Modals';
import { Collection, Group } from '@shared/welibrary-graphql/types';
import useGroupSurveyAccessControl from '@web/ui/components/group/useGroupSurveyAccessControl';

import { VIEW_PERMISSION_SECRET, VIEW_PERMISSION_UNSET } from '@core/utilities/constants/roles';
import {
    CARD_MOBILIZATION,
    CARD_VOLUNTEER_MOBILIZATION,
    CARD_PLEDGE,
    CARD_SUBTYPE_DISTINCT_ACTION_CAMPAIGNS,
    CARD_SUBTYPE_DISTINCT_VOLUNTEER,
    CARD_SUBTYPE_DISTINCT_JOBS,
    CARD_SUBTYPE_DISTINCT_EVENT,
    CARD_EVENT,
} from '@core/utilities/constants/card_types';

import { UPDATE_GET_GROUP_DASHBOARD_NEWSFEED } from '@core/utilities/constants/update_cache';
import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';

import signUpStore from '../../stores/SignUp';
import isGroupSubscription from './groupdashboard/groupDashboardHelpers';

import getLogger from '@core/logger';

const logger = getLogger(module);

const LoadableGroupBanner = loadable(() => import('@web/ui/components/group/GroupBanner.js'), {
    resolveComponent: imported => imported.default,
});

const LoadableFullScreenController = loadable(
    () => import('@web/ui/components/generic/lightbox/FullScreenController'),
    { resolveComponent: imported => imported.default }
);

const LoadableUpdateGroup = loadable(() => import('@web/ui/components/group/UpdateGroup'), {
    resolveComponent: imported => imported.default,
});

const LoadableGroupAnalytics = loadable(() => import('@web/ui/components/group/GroupAnalytics'), {
    resolveComponent: imported => imported.default,
});

/**
 * Helper variables & functions for evolving subroutes under group dashboard.
 * Because we allow accessing members and analytics / group dashboards from multiple routes.
 * This adds support to add subroute checking for members & analytics.
 */
const analyticsPaths = ['/analytics', '/dashboard/analytics', '/g/:groupId/analytics'];
// const membersPaths = ['/members', '/dashboard/members', '/g/:groupId/members'];
const groupSettingsPath = ['/g/:groupId/settings'];
/**
 * This is a helper function for toggling a subroute
 * i.e. going from "/dashboard" (closed) => "/dashboard/members" (open) => "/dashboard" (closed)
 * While also supporting "/" (closed) => "/members" (open) => "/"`(closed)
 */
const pushToggledRelativeUrlToHistory = (
    history: any,
    open: boolean,
    baseUrl: string,
    openUrl: string
) => {
    // TODO: Refactor this way of handling members so it uses react-router to handle this logic.
    if (open) {
        const newUrl = baseUrl.endsWith('/') ? `${baseUrl}${openUrl}` : `${baseUrl}/${openUrl}`;
        history.push({ pathname: newUrl });
    } else {
        let newUrl = baseUrl?.replace(`/${openUrl}`, '');
        if (newUrl.slice(-1) === '/') newUrl = newUrl.slice(0, -1);
        history.push({ pathname: newUrl });
    }
};

// A custom hook that builds on useLocation to parse
// the query string for you.
const usePathQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const DialogueWrapper = styled.div`
    flex: 1;
    border-radius: 25px;
    font-size: 16px;
    line-height: 170%;
    overflow: hidden;
    padding: 50px;
`;

const GroupDashboard: React.FC<{
    group: Group;
    getGroupByIdRefetch: any;
    baseUrl: string;
    inModal: boolean;
}> = ({ group, getGroupByIdRefetch, baseUrl = '/dashboard', inModal = false }) => {
    const {
        isRequiredSurveyLockNeeded,
        surveyLockedGroupComponent,
        showGroupSurveyModal,
        openSurveyModal,
    } = useGroupSurveyAccessControl({
        group,
    });

    useEffect(() => {
        if (showGroupSurveyModal) {
            openSurveyModal();
        }
    }, [showGroupSurveyModal]);

    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();

    const { newModal: centerModal } = useModal({
        desktop: ModalTypes.Center,
        mobile: ModalTypes.Center,
    });
    const { newModal } = useModal();
    const { handlePurchaseAccess } = useGroupActions({
        group,
        refetchQueries: [GET_GROUP_DASHBOARD],
    });

    const isEventGroup = group?.subtype === 'event';
    const localNamespace = isEventGroup
        ? 'imports.wlWeb.ui.components.group.eventDashboard'
        : 'imports.wlWeb.ui.components.group.groupDashboard';

    const pathQuery = usePathQuery();
    const history = useHistory();
    const isGroupAnalyticsOpen = useRouteMatch({ path: analyticsPaths, exact: false });
    const isGroupSettingsOpen = useRouteMatch({ path: groupSettingsPath, exact: false });

    const { settings } = group;
    const { protectionLevel } = settings || {};

    const [showMobilizationForm, setShowMobilizationForm] = useState<boolean>(false);
    const [formToRender, setFormToRender] = useState<React.ReactNode | null>(null);
    const [showAnnouncementPostBox, setShowAnnouncementPostBox] = useState<boolean>(false);
    const [showNotifyPanel] = useState<boolean | string>(pathQuery.get('showNotifyPanel') ?? false);
    const [showStripeRefresh] = useState<boolean | string>(pathQuery.get('refresh') ?? false);
    const [showStripeSuccess] = useState<boolean | string>(pathQuery.get('success') ?? false);
    const [showEventRegistration] = useState<boolean | string>(
        (pathQuery.get('showEventRegistration') ||
            useRouteMatch('/g/:groupId/event/registration')) ??
        false
    );
    const [showShoppingCart] = useState<boolean | string>(
        (pathQuery.get('showShoppingCart') || useRouteMatch('/g/:groupId/shoppingCart')) ?? false
    );
    const [showTickets] = useState<boolean | string>(pathQuery.get('showTickets') ?? false);
    const [showSubscription] = useState<boolean | string>(
        pathQuery.get('showSubscription') ?? false
    );
    const [showOrder] = useState<boolean | string>(pathQuery.get('showOrder') ?? false);
    const [orderId] = useState<string>(pathQuery.get('orderId') ?? '');
    const [showNonMemberModal] = useState<boolean | string>(
        pathQuery.get('showNonMemberModal') ?? false
    );

    const isUserAdminOfGroup: boolean = group?.currentUserIsAdmin
        ? group?.currentUserIsAdmin
        : false;
    // const isUserMemberOfGroup = group.currentUserIsMember ? group.currentUserIsMember : false;

    const onModalClose = () => history?.replace(`/g/${group?._id}`);

    const usedShortCode = signUpStore.get.usedGroupCode();
    const signupCode = signUpStore.get.groupCode();
    const bypassApprovalRequired =
        usedShortCode &&
        signupCode.toUpperCase() === group?.settings?.shortCode &&
        group?.settings?.shortCodeApprovalRequired !== true &&
        isGroupSubscription(group);

    useEffect(() => {
        setTimeout(() => {
            if (group && showTickets && orderId) {
                newModal(<TicketItem orderId={orderId} />, {
                    className: 'no-top-padding no-bottom-padding no-notch ticket-modal',
                    hideButton: true,
                    onClose: onModalClose,
                });
            } else if (group && showSubscription) {
                newModal(
                    <ManageUserGroupSubscription group={group} />,
                    {
                        className: 'no-top-padding no-bottom-padding',
                        hideButton: true,
                        onClose: onModalClose,
                    },
                    { mobile: ModalTypes.FullScreen, desktop: ModalTypes.Center }
                );
            } else if (group && showOrder && orderId) {
                newModal(<StripeOrderDetails orderId={orderId} />, {
                    className: 'no-top-padding no-bottom-padding no-notch ticket-modal',
                    hideButton: true,
                    onClose: onModalClose,
                });
            } else if (group && showEventRegistration) {
                const firstTicket = group?.products?.[0];
                const isFreeEvent = group?.products?.length === 1 && firstTicket.price === 0;

                if (group?.currentUserOwnsMembership) {
                    newModal(<TicketItem groupId={group?._id} />, {
                        className: 'no-top-padding no-bottom-padding no-notch ticket-modal',
                        hideButton: true,
                        onClose: onModalClose,
                    });
                } else if (!group.currentUserOwnsMembership && isFreeEvent) {
                    newModal(<Checkout group={group} product={firstTicket} quantity={1} />, {
                        className: 'no-top-padding no-bottom-padding',
                        hideButton: true,
                        onClose: onModalClose,
                    });
                } else {
                    // newModal(<ShoppingCartModal group={group} isInline={false} />, {
                    newModal(<Checkout group={group} isInline={false} />, {
                        className: 'no-top-padding no-bottom-padding',
                        hideButton: true,
                        showCloseButtonOnMobile: true,
                        onClose: onModalClose,
                    });
                }
            } else if (group && showShoppingCart) {
                // newModal(<ShoppingCartModal group={group} isInline={false} />, {
                newModal(<Checkout group={group} isInline={false} />, {
                    className: 'no-top-padding no-bottom-padding',
                    hideButton: true,
                    showCloseButtonOnMobile: true,
                    onClose: onModalClose,
                });
            } else if (group && !isEventGroup && bypassApprovalRequired) {
                handlePurchaseAccess();
            }
        }, 300);
    }, []);

    useEffect(() => {
        if (showNotifyPanel && isUserAdminOfGroup && group?.parentGroup) {
            centerModal(
                <NotifyPanel
                    parentGroupId={group?.parentGroup?._id}
                    groupId={group?._id}
                    collectionType={Collection.Group}
                />
            );
        }

        if (showNonMemberModal) {
            newModal(
                <DialogueWrapper>
                    {t(
                        `common:${localNamespace}.no_permission_dialogue_text`,
                        "You don't have permission to view the requested content. Request to join this group to gain access."
                    )}
                </DialogueWrapper>,
                {
                    onClose: history?.replace(`/g/${group?._id}`),
                    sectionClassName: 'small-dialogue',
                }
            );
        }
    }, []);

    useEffect(() => {
        if (group && showStripeSuccess && isUserAdminOfGroup) {
            newModal(<ConnectedAccountSuccess group={group} />);
        } else if (group && showStripeRefresh && isUserAdminOfGroup) {
            centerModal(<ConnectedAccountRefresh />);
        }
    }, []);

    const whatsNewRef = useRef();
    const scrollToWhatsNew = () => whatsNewRef?.current?.scrollIntoView({ behavior: 'smooth' });

    const dashboardGroup: Group = group;
    const dashboardSource = dashboardGroup?.dashboardSource;
    const dashboard = dashboardGroup?.dashboard;
    const tableOfContents = dashboardGroup?.tableOfContents?.results;
    const tocRankOrder = dashboardGroup?.settings?.tocSettings?.rankOrder;

    const handleAnnouncementPostBox = () => setShowAnnouncementPostBox(!showAnnouncementPostBox);
    const handleMobilizationFormModal = () => setShowMobilizationForm(!showMobilizationForm);

    // handles the mobilization forms
    const handleMobilizationForm = (formType: string) => {
        let subtype;
        // check formType -> subtype
        if (formType === CARD_MOBILIZATION) {
            subtype = CARD_SUBTYPE_DISTINCT_JOBS;
        } else if (formType === CARD_VOLUNTEER_MOBILIZATION) {
            subtype = CARD_SUBTYPE_DISTINCT_VOLUNTEER;
        } else if (formType === CARD_PLEDGE) {
            subtype = CARD_SUBTYPE_DISTINCT_ACTION_CAMPAIGNS;
        } else if (formType === CARD_EVENT) {
            subtype = CARD_SUBTYPE_DISTINCT_EVENT;
        } else {
            logger.error('Invalid subtype passed to form.');
            return;
        }

        const form = getForm(
            formType,
            {
                distinctChannelDestination: {
                    groupId: group?._id,
                    subtype,
                },
                closeModalHandler: () => setShowMobilizationForm(false),
                updateCacheAfterSubmit: (cache, data) => {
                    const { attachedCard } = data.data;
                    UPDATE_GET_GROUP_DASHBOARD_NEWSFEED(cache, attachedCard, group?._id);
                },
            },
            currentUser
        );
        setFormToRender(form);
        handleMobilizationFormModal();
    };

    const [isEditGroupOpen, setIsEditGroupOpen] = useState<boolean>(false || isGroupSettingsOpen);

    const partitionedToc = _.partition(tableOfContents, item => item.type === 'chapter');
    let sortedToc = [];
    partitionedToc.forEach(itemSet => {
        sortedToc = [...sortedToc, ...itemSet];
    });

    if (tocRankOrder) {
        sortedToc = _.sortBy(sortedToc, item => {
            const tocRank = _.find(tocRankOrder, { key: item.url });
            return tocRank && tocRank.value;
        });
    }

    const handleOpenUpdateGroup = (settingsOpen: boolean) => {
        if (isUserAdminOfGroup) {
            if (!isEventGroup) {
                if (settingsOpen) {
                    setIsEditGroupOpen(true);
                } else {
                    setIsEditGroupOpen(false);
                    history?.push(`/g/${group?._id}`);
                }
            }

            if (isEventGroup) {
                centerModal(
                    <UpdateEventGroupForm
                        group={group}
                        hasAdminRole={isUserAdminOfGroup ?? undefined}
                    />,
                    {
                        hideButton: true,
                        className: 'center-fixed-header-footer',
                        confirmClose: 'Are you sure you want to cancel?',
                    },
                    { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
                );
            }
        }
    };

    const isDraftGroup: boolean =
        protectionLevel === VIEW_PERMISSION_SECRET || protectionLevel === VIEW_PERMISSION_UNSET;

    const showDraftGroupBanner: boolean = isDraftGroup && isUserAdminOfGroup;
    const showTemplateBanner: boolean =
        !showDraftGroupBanner && group.isTemplate && isUserAdminOfGroup;

    const handleGroupAnalytics = (open: boolean) => {
        pushToggledRelativeUrlToHistory(history, open, baseUrl, 'analytics');
    };

    const newsfeedHeaderRef = useRef();

    return (
        <>
            {showDraftGroupBanner && (
                <LoadableGroupBanner
                    title={`${t(`common:${localNamespace}.draft_group`)}`}
                    actionTitle={`${t(`common:${localNamespace}.edit_and_publish`)}`}
                    onClickAction={() => handleOpenUpdateGroup(true)}
                />
            )}

            {showTemplateBanner && (
                <LoadableGroupBanner
                    title={`${t(`common:${localNamespace}.template_banner`)}`}
                    actionTitle={`${t(`common:${localNamespace}.template_banner_action`)}`}
                    onClickAction={() => handleOpenUpdateGroup(true)}
                />
            )}

            <GroupDashboardHeader
                group={group}
                dashboardGroup={dashboardGroup}
                scrollToWhatsNew={scrollToWhatsNew}
                handleGroupAnalytics={handleGroupAnalytics}
                handleOpenUpdateGroup={handleOpenUpdateGroup}
                handleAnnouncementPostBox={handleAnnouncementPostBox}
                handleMobilizationForm={handleMobilizationForm}
                inModal={inModal}
                newsfeedHeaderRef={newsfeedHeaderRef}
                locked={isRequiredSurveyLockNeeded}
            />

            {isUserAdminOfGroup && (
                <GroupAnnouncementPostBox
                    group={group}
                    handleAnnouncementPostBox={handleAnnouncementPostBox}
                    showAnnouncementPostBox={showAnnouncementPostBox}
                    showConfirmation
                />
            )}

            {isUserAdminOfGroup && (
                <MobilizationForm
                    form={formToRender}
                    showMobilizationForm={showMobilizationForm}
                    handleMobilizationFormModal={handleMobilizationFormModal}
                />
            )}

            <ErrorBoundary>
                {!isRequiredSurveyLockNeeded ? (
                    <GroupDashboardNewsFeedTypeWrapper
                        group={group}
                        sortedToc={sortedToc}
                        whatsNewRef={whatsNewRef}
                        newsfeedHeaderRef={newsfeedHeaderRef}
                    />
                ) : (
                    surveyLockedGroupComponent
                )}
            </ErrorBoundary>

            {isUserAdminOfGroup && (
                <LoadableFullScreenController
                    open={isGroupAnalyticsOpen}
                    setOpen={(value: boolean) => handleGroupAnalytics(value)}
                >
                    <Route path={analyticsPaths}>
                        <LoadableGroupAnalytics group={group} currentUser={currentUser} />
                    </Route>
                </LoadableFullScreenController>
            )}

            {/* /group/groupId:/settings */}
            <LoadableFullScreenController
                title={t(`common:${localNamespace}.edit_group`)}
                open={isEditGroupOpen}
                setOpen={(value: boolean) => handleOpenUpdateGroup(value)}
            >
                {isEditGroupOpen && (
                    <LoadableUpdateGroup
                        group={dashboardGroup}
                        dashboardSource={dashboardSource}
                        dashboard={dashboard}
                        tableOfContents={sortedToc}
                        isOpen={isEditGroupOpen}
                        handleSubmit={() => {
                            handleOpenUpdateGroup(false);
                            getGroupByIdRefetch();
                        }}
                        handleClose={() => handleOpenUpdateGroup(false)}
                    />
                )}
            </LoadableFullScreenController>

            {isEventGroup && <ShoppingCartFixedButton group={dashboardGroup} />}
        </>
    );
};

export default GroupDashboard;
