import { TFunction } from 'i18next';

import { getResultTypeFromContentCard } from '@web/utilities/helpers/search/searchHelpers';
import {
    generateDisplayReferences,
    generateReferenceArray,
} from '@web/utilities/helpers/reference/reference.helpers';
import {
    getRandomDefaultCoverImage,
    getRandomDefaultThumbImage,
    getRandomDefaultUserImage,
} from '@core/utilities/constants/defaults';

import { CARD_RAINBOW_ROAD } from '@core/utilities/constants/card_types';
import { FILTER_TOP } from '@core/utilities/constants/library_settings';

import { AliasInfo } from '@core/types/Aliases';
import { CurrentUser } from '@core/types/User';
import { Maybify } from '@core/types/Utilities';
import { AliasHandle, AliasItem, AliasItemInput, ContentCard, Maybe } from '@shared/welibrary-graphql/types';
import { FilterType } from '@shared/welibrary-graphql/comment/subscriptions.hook';

export const getInfoFromAlias = (
    alias: Maybify<AliasHandle>,
    t: TFunction,
    currentUser?: CurrentUser | null
): AliasInfo | false => {
    if (alias.__typename === 'UserAliasHandle') {
        if (!alias.user || !alias.user.profile) return false;

        const {
            user: {
                _id,
                profile: { picture, full_name, short_bio },
            },
        } = alias;

        if (!_id || !full_name) return false;

        return {
            to: `/u/${_id}`,
            picture:
                picture ||
                (alias.type === 'group'
                    ? getRandomDefaultCoverImage(full_name)
                    : getRandomDefaultUserImage()),
            alt: full_name,
            title: full_name,
            description: short_bio || '',
            type: t('common:global.nouns.profile'),
        };
    }

    if (alias.__typename === 'GroupAliasHandle') {
        if (!alias.group || !alias.group.profile) return false;

        const {
            group: {
                _id,
                profile: { picture, full_name, short_bio },
            },
        } = alias;

        if (!_id || !full_name) return false;

        return {
            to: `/g/${_id}`,
            picture: picture || getRandomDefaultCoverImage(full_name),
            alt: full_name,
            title: full_name,
            description: short_bio || '',
            type: t('common:global.nouns.group'),
        };
    }

    if (alias.__typename === 'MessageThreadAliasHandle') {
        if (!alias.thread) return false;

        const {
            thread: { displayName, group, participants },
        } = alias;

        if (!alias?.url) return false;

        const otherUser = participants?.filter(item => item?.user?._id !== currentUser?._id)?.[0];
        let picture =
            alias?.thumb || group?.profile?.picture || getRandomDefaultCoverImage(alias?.url);

        if (!group && participants.length === 2) {
            picture = otherUser?.user?.profile?.picture || getRandomDefaultUserImage();
        }

        // if (!group) {
        //     displayName = otherUser?.user?.profile?.full_name;
        // }
        return {
            to: `/messages/${alias?.url}`,
            picture,
            alt: displayName || otherUser?.user?.profile?.full_name || 'Message',
            title: displayName || otherUser?.user?.profile?.full_name || 'Message',
            description: '',
            type: 'message',
        };
    }

    if (alias.__typename === 'AliasItem') {
        if (!alias.item) return false;

        if (['chapter', 'channel'].includes(alias?.type ?? '')) {
            const {
                item: { reference, media, title, description },
            } = alias;

            if (!title || !reference?.href) return false;

            const picture =
                media?.icon ||
                reference.bookSource?.media?.thumb ||
                getRandomDefaultCoverImage(reference.bookSource?.title || title);

            return {
                to: reference.href,
                picture,
                alt: title,
                title,
                description: description || '',
                type: t('common:global.nouns.channel'),
            };
        }

        const {
            item: { media, title, body, description, author, reference },
        } = alias;

        return {
            to: `/${alias.type === CARD_RAINBOW_ROAD ? 'r' : 'c'}/${alias.url}`,
            picture:
                media?.icon ||
                media?.thumb ||
                reference?.bookSource?.media?.thumb ||
                getRandomDefaultThumbImage(title || ''),
            alt: title || body || '',
            title: title || body || '',
            description: (title && body) || description || '',
            type: getResultTypeFromContentCard(alias.item, t),
            reference:
                generateReferenceArray(
                    t,
                    alias.item,
                    generateDisplayReferences(alias.item, true)
                ) || undefined,
            author: author?.profile?.full_name ?? '',
        };
    }

    return false;
};

export const getAliasInputFromAlias = (alias: Maybify<AliasHandle>): AliasItemInput => ({
    url: alias.url ?? '',
    type: alias.type ?? '',
    title: alias.title,
    thumb: alias.thumb,
    icon: alias.icon,
    color: alias.color,
});

export const getSortTypeForChapter = (chapter?: ContentCard | null): string => {
    const sortType = chapter?.library_settings?.defaultFilter ?? FILTER_TOP;

    if (chapter?.library_settings?.filterTypes) {
        return chapter.library_settings.filterTypes.includes(sortType as Maybe<FilterType>)
            ? sortType
            : chapter.library_settings.filterTypes[0] ?? FILTER_TOP;
    }

    return sortType;
};

// This determines what visibility setting to show the user in new postoverlay
// If protection level settings is public, then show 'public'
// if protection setting is private then show 'group reference'
// use book setting, if not eg. unset, use group setting
// TODO abstract logic and make simpler
export const getVisibilityRefSettingForPostOverlay = (
    book: ContentCard | null | undefined,
    shelf: ContentCard | null | undefined,
    t: TFunction
): { type: string; display: string } | undefined => {
    let visibilityRefSetting;
    const bookProtectionLevel = book?.library_settings?.protectionLevel;
    const shelfProtectionLevel = shelf?.library_settings?.protectionLevel;

    const groupSource = book?.reference?.groupSource;
    const bookGroupName = groupSource?.profile?.full_name;
    const groupProtectionLevel = groupSource?.settings?.protectionLevel;

    // Check the group's protection level first
    if (groupProtectionLevel && groupProtectionLevel !== 'unset') {
        const protectorName = bookGroupName;
        if (groupProtectionLevel === 'secret') {
            visibilityRefSetting = {
                type: 'secret',
                display: t('common:global.nouns.visibility.unpublished'),
            };
        } else if (groupProtectionLevel === 'public') {
            visibilityRefSetting = {
                type: 'public',
                display: t('common:global.nouns.visibility.public'),
            };
        } else if (groupProtectionLevel === 'private') {
            const roleText = t('common:global.nouns.visibility.members_in');
            visibilityRefSetting = {
                type: 'private',
                display: `${roleText} ${protectorName}`,
            };
        }
    }

    // Check the shelve's protection level 2nd
    if (shelfProtectionLevel && shelfProtectionLevel !== 'unset') {
        const protectorName = bookGroupName ?? shelf?.title;
        if (shelfProtectionLevel === 'secret') {
            visibilityRefSetting = {
                type: 'secret',
                display: t('common:global.nouns.visibility.secret'),
            };
        } else if (shelfProtectionLevel === 'public') {
            visibilityRefSetting = {
                type: 'public',
                display: t('common:global.nouns.visibility.public'),
            };
        } else if (shelfProtectionLevel === 'private') {
            const roleText = t('common:global.nouns.visibility.members_in');
            visibilityRefSetting = {
                type: 'private',
                display: `${roleText} ${protectorName}`,
            };
        }
    }

    // Check the book's protectionLevel last
    if (bookProtectionLevel && bookProtectionLevel !== 'unset') {
        const protectorName = bookGroupName ?? book?.title;
        // Book level settings
        if (bookProtectionLevel === 'public') {
            visibilityRefSetting = {
                type: 'public',
                display: t('common:global.nouns.visibility.public'),
            };
        } else if (bookProtectionLevel === 'private') {
            // Array of user roles allowed
            const accessRoles = book?.library_settings?.accessRoleWhitelist;
            let roleText = t('common:global.nouns.visibility.members_in');
            if (accessRoles?.includes('admin')) {
                roleText = t('common:global.nouns.visibility.admins_in');
            }
            if (accessRoles?.includes('view')) {
                roleText = t('common:global.nouns.visibility.members_in');
            }
            visibilityRefSetting = {
                type: 'private',
                display: `${roleText} ${protectorName}`,
            };
        } else if (bookProtectionLevel === 'secret') {
            visibilityRefSetting = {
                type: 'secret',
                display: t('common:global.nouns.visibility.secret'),
            };
        }
    }

    return visibilityRefSetting;
};

export const aliasHandleToAliasItem = (handle: Maybify<AliasHandle>): handle is AliasItem =>
    handle?.__typename === 'AliasItem';
