import React from 'react';

import Picture from '@web/ui/components/generic/Picture';
import Plus from '@web/ui/dsc/svgs/FullSizePlus';

import useSearchFormResult from '@web/ui/components/search/form/hooks/useSearchFormResult';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

import { Maybify } from '@core/types/Utilities';
import { Entity } from '@core/types/Search';

const SearchFormResult: React.FC<{
    entity?: Maybify<Entity> | null;
    addResult: (result: Maybify<Entity>) => void;
}> = ({ entity, addResult }) => {
    const info = useSearchFormResult(entity);

    if (!info) return <></>;

    const { title, img, resultType, reference, author } = info;

    return (
        <li className="search-form-result">
            <Picture
                url={img || getRandomDefaultCoverImage(title || resultType)}
                disableLink
                resolutions={[130, 260, 390]}
            />

            <section>
                <h2>
                    {author ? (
                        <>
                            {resultType}
                            <span>{` • ${author}`}</span>
                        </>
                    ) : (
                        resultType
                    )}
                </h2>
                {reference && <h2 className="reference">{`in ${reference}`}</h2>}
                <h3>{title}</h3>
            </section>

            <button type="button" onClick={() => addResult(entity)}>
                <Plus />
            </button>
        </li>
    );
};

export default SearchFormResult;
