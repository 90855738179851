import React, {useState} from 'react';
import {useFilestack} from '@web/ui/components/generic/hooks/useFilestack';
import {IMAGE_MIME_TYPES} from '@core/utilities/constants/filestack';
import EditBlogHeader from '@web/ui/components/card/blogcard/EditBlogHeader';
import {FullEditor} from '@web/ui/components/wysiwyg';

const UpdateBlogForm = ({ state, handleChange, group }) => {
    const [author, setAuthor] = useState(state?.author);
    const mentionsGroupId = group?.settings?.protectionLevel !== 'public' ? group?._id : undefined;
    const handleImageUrl = imageUrl => {
        const payload = {
            target: {
                name: 'mediaThumb',
                value: imageUrl,
            },
        };
        handleChange(payload);
    };

    const { fileSelector } = useFilestack({ fileType: IMAGE_MIME_TYPES, onUpload: handleImageUrl });

    const handleChangeAuthorId = authorID => {
        const payload = {
            target: {
                name: 'authorID',
                value: authorID,
            },
        };
        handleChange(payload);
    };

    const handleChangeAuthor = user => {
        setAuthor(user);
        const payload = {
            target: {
                name: 'author',
                value: user,
            },
        };
        handleChange(payload);
    };

    const handleUpdateText = value => {
        const text = value();
        const payload = {
            target: {
                name: 'body',
                value: text,
            },
        };
        handleChange(payload);
    };

    const handleUpdateTitle = e => {
        handleChange(e);
    };

    const handleFileSelect = e => {
        e.preventDefault();
        fileSelector.click();
    };

    const thumbUrl = state.mediaThumb;

    return (
        <>
            <div>
                <section>
                    <EditBlogHeader
                        imageUrl={thumbUrl}
                        author={author}
                        handleChangeAuthor={handleChangeAuthor}
                        handleFileSelect={handleFileSelect}
                        title={state.title}
                        handleSetTitle={handleUpdateTitle}
                    />
                </section>
                <section>
                    <FullEditor
                        defaultValue={state.body}
                        placeholder="Starting writing!"
                        mentionsGroupId={mentionsGroupId}
                        className="full-editor-blog"
                        onChange={handleUpdateText}
                    />
                </section>
            </div>
            <div className="new-post-overlay-bottom" />
        </>
    );
};

export default UpdateBlogForm;
