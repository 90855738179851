import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '@components/modals/hooks/useModal';
import useSellTicketsAtDoor from '../hooks/useSellTicketsAtDoor';
import useValidateEmail from '@web/utilities/hooks/signup/useValidateEmail';
import shoppingCartStore from '@stores/ShoppingCart';

import { useValidateCouponLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { useConvertCurrencyLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';

import TextInput from '@dsc/forms/textInputs/TextInput';
import PhoneInput from '@dsc/forms/customInputs/PhoneInput';
import IncrementStepper from '@dsc/forms/customInputs/IncrementStepper';
import SellTicketsFooter from './SellTicketsFooter';
import SellTicketsAccountSearch from './SellTicketsAccountSearch';
import Ticket from '@dsc/svgs/Ticket';
import TextWithLineBehind from '@web/ui/pages/new-signup/TextWithLineBehind';
import X from '@web/ui/dsc/svgs/FullSizeX';

import { getRandomDefaultUserImage } from '@core/utilities/constants/defaults';
import { Group } from '@shared/welibrary-graphql/types';

type SellTicketsOrderDetailsProps = {
    group: Group;
};

const SellTicketsOrderDetails: React.FC<SellTicketsOrderDetailsProps> = ({ group }) => {
    const { t } = useTranslation();
    const { newModal } = useModal();

    const [codeError, setCodeError] = useState(undefined);

    const {
        isEventFree,
        name,
        email,
        phone,
        discountCode,
        shoppingCartProducts,
        currency,
        isProcessingOrder,
        createOrderError,
        existingAccount,
        getCurrencyDisplayString,
    } = useSellTicketsAtDoor(group);

    const { emailError, matchedUser, validateEmailLoading } = useValidateEmail(email, true);

    const isAllGuestInfoFilledOut = name && email && phone && !emailError;
    const disableNext = (!existingAccount && !isAllGuestInfoFilledOut) || isProcessingOrder;

    const [convertCurrency] = useConvertCurrencyLazyQuery();
    const [validateCoupon, { data }] = useValidateCouponLazyQuery({
        fetchPolicy: 'no-cache',
        onCompleted: async () => {
            if (data?.validateCoupon.valid) {
                const productsWithCoupons = data?.validateCoupon?.productsWithCoupons;

                if (productsWithCoupons?.length > 0) {
                    let minCurrencyCharge = 1;

                    if (currency !== 'USD') {
                        const { data: _minCurrencyCharge } = await convertCurrency({
                            variables: {
                                to: currency,
                                from: 'USD',
                                amount: '1',
                            },
                            fetchPolicy: 'network-only',
                        });
                        minCurrencyCharge = _minCurrencyCharge?.convertCurrency?.value;
                    }

                    shoppingCartProducts?.forEach(({ product }) => {
                        const coupon = productsWithCoupons?.find(
                            ({ product: p }) => product?._id === p?._id
                        )?.coupon;

                        if (!coupon) return;

                        shoppingCartStore?.set?.setProductCoupon(group?._id, product?._id, {
                            isApplied: true,
                            code: coupon?.code,
                            percentage: coupon?.percentage,
                            minChargeAmount: minCurrencyCharge,
                            error: null,
                        });
                    });
                }

                shoppingCartStore.set.discountCode('');
            } else {
                setCodeError(data?.validateCoupon.message);
            }
        },
    });

    const handleDiscountCode = () => {
        setCodeError(undefined);

        if (discountCode) {
            const shoppingCartProductIds = shoppingCartProducts?.map(({ product: p }) => p?._id);

            validateCoupon({
                variables: {
                    input: {
                        couponCode: discountCode,
                        productIds: shoppingCartProductIds,
                    },
                },
            });
        }
    };

    const productsWithCoupons = shoppingCartProducts.filter(p => p.coupon?.isApplied);

    return (
        <section className="flex flex-col gap-[20px]">
            <h2 className="m-0 text-[24px] text-black">
                {t(`common:global.order_details`, 'Order Details')}
            </h2>

            {!isEventFree && (
                <div className="flex flex-col gap-[20px]">
                    <div className="flex flex-col gap-[5px]">
                        <div className="flex gap-[20px]">
                            <TextInput
                                value={discountCode}
                                onChange={updatedCode => {
                                    setCodeError(undefined);
                                    shoppingCartStore.set.discountCode(updatedCode.toUpperCase());
                                }}
                                label={t(`common:global.discount_code`, 'Discount Code')}
                                className="!my-0 grow"
                            />
                            <button
                                onClick={handleDiscountCode}
                                disabled={!discountCode}
                                className="bg-primary-default text-grayscale-background rounded-full px-[24px] text-[17px] font-[600] leading-[28px] tracking-[0.75px] disabled:opacity-70"
                            >
                                {t(`common:global.apply`, 'Apply')}
                            </button>
                        </div>

                        {codeError && <span className="text-error-default">{codeError}</span>}
                    </div>

                    {productsWithCoupons.length > 0 && (
                        <div className="flex flex-col gap-[10px]">
                            {productsWithCoupons.map((cartItem, index) => (
                                <div key={index} className="flex flex-col gap-[5px]">
                                    <div className="shadow-around flex flex-col gap-[10px] rounded-[10px] p-[10px]">
                                        <span className="text-[17px] font-[700] leading-[24px] tracking-[0.75px] text-black">
                                            {cartItem.product.name}
                                        </span>
                                        <div className="flex">
                                            <div className="flex flex-col gap-[5px]">
                                                <div className="flex flex-col">
                                                    <span className="text-grayscale-label text-[9px] font-[500] line-through">
                                                        {getCurrencyDisplayString(
                                                            cartItem.product.price,
                                                            true
                                                        )}{' '}
                                                        /{' '}
                                                        {t(
                                                            'common:global.ticket_lowercase',
                                                            'ticket'
                                                        )}
                                                    </span>
                                                    <span className="flex items-center gap-[5px] text-[17px] font-[700] text-black">
                                                        {getCurrencyDisplayString(
                                                            cartItem.product.price *
                                                            (1 - cartItem.coupon?.percentage),
                                                            true
                                                        )}{' '}
                                                        <span className="text-grayscale-label text-[8px] font-[600] uppercase">
                                                            /{t('common:global.ticket', 'ticket')}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <IncrementStepper
                                                className="checkout-increment-stepper ml-auto"
                                                placeholder="qty"
                                                value={cartItem.quantity ?? 0}
                                                onChange={value => {
                                                    shoppingCartStore?.set?.setShoppingCartItem(
                                                        group?._id,
                                                        cartItem.product,
                                                        value
                                                    );
                                                }}
                                                min={0}
                                            />
                                        </div>
                                    </div>

                                    <div className="bg-grayscale-input-background text-grayscale-body ml-auto flex items-center gap-[10px] rounded-full px-[10px] py-[8px]">
                                        <Ticket
                                            className="h-[20px] w-[20px]"
                                            strokeColor="currentColor"
                                        />
                                        <span className="text-[14px] font-[600] uppercase leading-[17px] tracking-[0.25px]">
                                            {cartItem.coupon.code}{' '}
                                            <span className="text-primary-default">
                                                ({cartItem.coupon.percentage * 100}%{' '}
                                                {t('common:global.OFF', 'OFF')})
                                            </span>
                                        </span>
                                        <button
                                            onClick={() =>
                                                shoppingCartStore?.set?.setProductCoupon(
                                                    group?._id,
                                                    cartItem?.product._id,
                                                    {}
                                                )
                                            }
                                        >
                                            <X className="w-[10px]" strokeWidth="5" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}

            <div className="mt-[10px] flex flex-col gap-[20px]">
                <h3 className="text-grayscale-title-active m-0 text-[17px] font-[500] leading-[24px] tracking-[1px]">
                    {t(`common:global.search_for_an_account`, 'Search for an Account')}
                </h3>

                {!existingAccount && (
                    <div
                        role="button"
                        onClick={() => {
                            newModal(<SellTicketsAccountSearch />);
                        }}
                        className="search-field-input check-in-search-field text-[16px]"
                    >
                        <input
                            tabIndex={-1}
                            type="text"
                            placeholder={t(`common:global.search`, 'Search')}
                            className="pointer-events-none"
                        />
                    </div>
                )}

                {existingAccount && (
                    <div className="flex items-center gap-[16px]">
                        <div className="shadow-around border-primary-default flex grow gap-[10px] rounded-[16px] border-[1px] border-solid p-[10px]">
                            <img
                                src={existingAccount.profile.picture || getRandomDefaultUserImage()}
                                className="h-[70px] w-[70px] rounded-full"
                            />
                            <div className="flex flex-col gap-[10px]">
                                <span className="text-grayscale-title-active text-[17px] font-[700] leading-[22px] tracking-[0.75px]">
                                    {existingAccount.profile.full_name}
                                </span>
                                <div className="text-grayscale-body flex flex-col text-[12px] font-[500]">
                                    <span>{existingAccount.profile.phone}</span>
                                    <span>{existingAccount.profile.email}</span>
                                </div>
                            </div>
                        </div>
                        <button
                            className="text-grayscale-placeholder"
                            onClick={() => shoppingCartStore.set.existingAccount(undefined)}
                        >
                            <X />
                        </button>
                    </div>
                )}

                <TextWithLineBehind text={t('common:global.or', 'Or')} />

                <h3 className="text-grayscale-title-active m-0 text-[17px] font-[500] leading-[24px] tracking-[1px]">
                    {t(
                        `common:global.continue_with_guest_checkout`,
                        'Continue with Guest Checkout'
                    )}
                </h3>

                <div
                    className={`flex flex-col gap-[20px] ${existingAccount ? 'pointer-events-none' : ''
                        }`}
                >
                    <TextInput
                        value={existingAccount ? '' : name}
                        onChange={updatedName => {
                            shoppingCartStore.set.createOrderError(undefined);
                            shoppingCartStore.set.name(updatedName);
                        }}
                        label={t(`common:global.name`, 'Name')}
                        className="!my-0"
                        required
                        disabled={!!existingAccount}
                    />

                    <div className="flex flex-col gap-[10px]">
                        <TextInput
                            value={existingAccount ? '' : email}
                            onChange={updatedEmail => {
                                shoppingCartStore.set.createOrderError(undefined);
                                shoppingCartStore.set.email(updatedEmail);
                            }}
                            label={t(`common:global.email`, 'Email')}
                            className="!my-0"
                            required
                            disabled={!!existingAccount}
                            error={!!email && !!emailError && !existingAccount}
                            loading={validateEmailLoading}
                        />

                        {emailError && !existingAccount && (
                            <span className="text-error-default font-poppins flex flex-col items-start text-[14px] leading-[24px] tracking-[0.25px]">
                                {emailError}
                                {matchedUser && (
                                    <button
                                        className="text-primary-default font-[500]"
                                        onClick={() => {
                                            shoppingCartStore.set.createOrderError(undefined);
                                            shoppingCartStore.set.existingAccount(matchedUser);
                                        }}
                                    >
                                        {t(
                                            'common:global.use_existing_user',
                                            'Use Existing Account'
                                        )}
                                    </button>
                                )}
                            </span>
                        )}
                    </div>

                    <PhoneInput
                        value={existingAccount ? '' : phone}
                        setValue={updatedPhone => {
                            shoppingCartStore.set.createOrderError(undefined);
                            shoppingCartStore.set.phone(updatedPhone);
                        }}
                        placeholder={t(`common:global.phone_number`, 'Phone Number')}
                        required
                        disabled={!!existingAccount}
                    />

                    {createOrderError && (
                        <span className="text-error-default">{createOrderError}</span>
                    )}
                </div>
            </div>

            <SellTicketsFooter
                group={group}
                disableNext={disableNext}
                nextButtonTextOverride={
                    isProcessingOrder ? t('common:global.one_moment', 'One moment...') : undefined
                }
            />
        </section>
    );
};

export default SellTicketsOrderDetails;
