import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';

import IntroContent from '@components/generic/IntroContent';

const localNamespace = 'imports.wlWeb.ui.components.stripe';

const ConnectedAccountRefresh: React.FC = () => {
    const { t } = useTranslation();
    const { closeAllModals } = useModal();

    return (
        <div className="edit-screen-content-view stripe-merchant-account">
            <div className="edit-screen-content-items">
                <div className="mc-source-post-wrapper connected-account-refresh">
                    <IntroContent
                        declaration="Eek!"
                        instruction="There must have been an error"
                        buttonTitle="Get Started"
                        type="error"
                        topPadding={0}
                    >
                        <button
                            onClick={closeAllModals}
                            type="button"
                            className="connected-account-btn-refresh"
                        >
                            {t(`common:${localNamespace}.close`, 'Close')}
                        </button>
                    </IntroContent>
                </div>
            </div>
        </div>
    );
};

export default ConnectedAccountRefresh;
