import React from 'react';

import _, { clone, cloneDeep, isEqual } from 'lodash';
import UpdateForm from '@web/ui/components/card/updatecard/UpdateForm';
import Confirmation from '@web/ui/components/generic/lightbox/prompts/Confirmation';

import DarkBookForm from '@web/ui/components/card/updatecard/DarkBookForm.js';
import DarkShelfForm from '@web/ui/components/card/updatecard/DarkShelfForm.js';
import DarkChapterForm from '@web/ui/components/card/updatecard/DarkChapterForm.js';

import { generateDefaultImageOnTileCreation } from '@web/ui/components/content/newpost/forms/linkTileHelpers';
import { getMajorVersion } from '@core/utilities/versionHelpers';
import { getVoteTypesFromVoteSettings } from '@core/utilities/constants/voting_types.js';
import { VIEW_PERMISSION_UNSET } from '@core/utilities/constants/roles';
import {
    ALIAS_GROUP,
    CARD_CHAPTER,
    CARD_IMAGE,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_SHELF,
    CARD_BOOK,
    ALIAS_EXTERNAL_LINK,
} from '@core/utilities/constants/card_types.js';
import PRE_SELECTED_ICONS from '@core/utilities/constants/pre_selected_tile_icons';

import getLogger from '@core/logger';

const logger = getLogger(module);

// TODO: Missing types for most props...
type UpdateCardProps = {
    card: any;
    deleteMode?: any;
    handleModalClose: any;
    updateCard: any;
    chapterSettings?: any;
    noTabs?: boolean;
    activeQuery?: any;
    baseMetricStringName?: string; // allows parent to provide name of metric for logging basic update actions
};

export default class UpdateCard extends React.Component<UpdateCardProps> {
    constructor(props: UpdateCardProps) {
        super(props);
        const { card } = this.props;
        let removeLabel = true;

        if (card.label) removeLabel = false;

        const cardIsAtLeastV1 = getMajorVersion(card.vs) >= 1;

        const activeLibrary = card.rootLibrary;

        const existingCustomFields = {
            parentUrl: activeLibrary ? activeLibrary.url : '',
            values:
                card.customFields && card.customFields[0]
                    ? _.map(card.customFields[0].values, item => {
                          return { id: item.id, values: item.values };
                      })
                    : [],
        };

        const existingCardAggregation = card.library_settings?.cardAggregation ?? [];
        const existingTagAggregation = card.library_settings?.tagAggregation ?? [];

        // "Normal" card being not a chapter, shelf, book content card type
        const isNormalCard = !['chapter', 'book', 'shelf'].includes(card.type);
        this.useInheritedSettings = false;
        let engagementTypes = card.library_settings ? card.library_settings.engagementTypes : null;
        if (isNormalCard && engagementTypes === null) {
            this.useInheritedSettings = true;
            const inheritedSettings = card?.reference?.chapterSource?.library_settings;
            engagementTypes = inheritedSettings?.engagementTypes;
        }

        this.state = {
            cardID: card._id,
            headerImage: '',
            prompt: card.prompt,
            author: card.author,
            removeLabel,
            title: card.type === CARD_LINK && !cardIsAtLeastV1 ? '' : card?.title,
            linkTitle: cardIsAtLeastV1 ? card?.links?.title : card?.title,
            body: card.body || '',
            description: card.description || '',
            link: card.links ? card.links.source : '',
            mediaIcon: card.media ? card.media.icon : '',
            mediaThumb: card.media ? card.media.thumb : '',
            labelText: card.label ? card.label.text : '',
            allowVoting: true,
            allowLabels: card.library_settings ? card.library_settings.allowLabels : false,
            allowPostbox: card.library_settings ? card.library_settings.allowPostbox : false,
            postboxRoleWhiteList: card.library_settings
                ? card.library_settings.postboxRoleWhiteList
                : [],
            engagementTypes: engagementTypes || null,
            voteTypes: getVoteTypesFromVoteSettings(card.vote_settings),
            postTypes: card.library_settings ? card.library_settings.postTypes : null,
            filterTypes: card.library_settings ? card.library_settings.filterTypes : null,
            defaultFilter: card.library_settings ? card.library_settings.defaultFilter : null,
            viewTypes: card.library_settings.viewTypes ? card.library_settings.viewTypes : [],
            defaultViewType: card.library_settings.defaultViewType
                ? card.library_settings.defaultViewType
                : null,
            displayReference: card.library_settings ? card.library_settings.displayReference : null,
            displaySocialHeaders: card.library_settings
                ? card.library_settings.displaySocialHeaders
                : null,
            displayInNewsfeed: card.library_settings
                ? card.library_settings.displayInNewsfeed
                : null,
            sendNotifications: card.sendNotifications ? card.sendNotifications : null,
            showPostTypes: card.showPostTypes ? card.showPostTypes : null,
            accessRoleWhitelist: card.library_settings
                ? card.library_settings.accessRoleWhitelist || []
                : [],
            // _customRoles: card.library_settings ? _.map(card.library_settings.customRoles, (item) => ({id:item.id, name: item.name, url: item.url, color: item.color})) : [],
            protectionLevel: card.library_settings
                ? card.library_settings.protectionLevel || VIEW_PERMISSION_UNSET
                : VIEW_PERMISSION_UNSET,
            // cardProtectionLevel: card?.protectionLevel,
            rankOrder: card.rankOrder ? card.rankOrder : null,
            aliases: card.aliases
                ? _.map(card.aliases, alias => ({
                      title: alias.title,
                      thumb: alias?.thumb,
                      type: alias.type,
                      color: alias.color,
                      url: alias.url,
                      item: alias.item,
                      group: alias.group,
                      icon: alias?.icon,
                  }))
                : [],
            customFields: existingCustomFields,
            cardAggregation: existingCardAggregation,
            canMembersSuggestEdits: card.canMembersSuggestEdits || card.currentUserCanSuggestEdit,
            tagAggregation: existingTagAggregation,
            showTitle: card?.showTitle,
            isTemplate:
                card.isTemplate != null && card.isTemplate !== 'undefined' ? card.isTemplate : null,
            showCreateChildren:
                card.library_settings &&
                card.library_settings.showCreateChildren != null &&
                card.library_settings.showCreateChildren !== 'undefined'
                    ? card.library_settings.showCreateChildren
                    : null,
            verticalScrolling: card?.verticalScrolling,
            showResourceHeader: card?.library_settings?.showResourceHeader,
            inheritSurveyAnswers: card?.inheritSurveyAnswers,
            shortTitle: card?.shortTitle || '',
        };
    }

    componentDidMount() {
        this.setState({
            cardId: this.props.card._id,
        });
    }

    capitalizeFirst = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    handleClick = () => {
        const { card } = this.props;
        const _state = cloneDeep(this.state);

        _state.authorID = this.state?.author?._id;
        delete _state.author;

        // remove from aliases fields used for preview data
        _state.aliases = this.state.aliases?.map(alias => {
            delete alias.item;
            delete alias.group;
            return alias;
        });

        if (card.type === CARD_IMAGE_SQUARE || card.type === CARD_IMAGE) {
            _state.aliases = this.state.aliases.length === 1 ? [] : this.state.aliases; // clear aliases if aliases.length === 1
            _state.mediaThumb = this.state.aliases[0].url; // update mediaThumb, set to first alias[0].url item
        }

        const updatePayload = { ..._state };
        if (updatePayload.cardAggregation) {
            updatePayload.cardAggregation = updatePayload.cardAggregation.map(card => card.url);
        }
        if (updatePayload.tagAggregation) {
            updatePayload.tagAggregation = updatePayload.tagAggregation.map(tag => tag._id);
        }

        // This handles not setting the card level settings if they are not different from inherited
        // Assume this is okay behavior for the time being....
        const isNormalCard = !['chapter', 'book', 'shelf'].includes(card.type);
        if (isNormalCard) {
            // Get inherited setting, in this case assumes it will be the chapter for a 'normal' card
            const inheritedSettings = card?.reference?.chapterSource?.library_settings;
            if (inheritedSettings) {
                try {
                    Object.entries(inheritedSettings).forEach(entry => {
                        const [key, value] = entry;
                        // This removes the property from the update payload if it matches the inherited settings
                        if (Array.isArray(value) && Array.isArray(updatePayload[key])) {
                            if (isEqual(value, updatePayload[key])) {
                                // specific case handling engagementtypes for now....
                                if (this.useInheritedSettings && key === 'engagementTypes') {
                                    delete updatePayload[key];
                                } else if (key !== 'engagementTypes') {
                                    delete updatePayload[key];
                                }
                            }
                        } else if (updatePayload?.key && value === updatePayload[key]) {
                            delete updatePayload[key];
                        }
                    });
                } catch {
                    e => {
                        logger.error('error updating card', e);
                    };
                }
            }
        }

        this.props
            .updateCard({
                variables: updatePayload,
            })
            .catch(err => {
                logger.error(err);
            });
        setTimeout(this.props.handleModalClose(), 0);
    };

    handleUpdateState = property => {
        this.setState(property);
    };

    handleCloseModal = () => {
        this.props.handleModalClose();
    };

    handleChange = (e, isInteger) => {
        const { target } = e;
        const value = target.value === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        let _value;
        if (value && isInteger) {
            _value = parseInt(value);
        } else {
            _value = value;
        }

        this.setState({
            [name]: _value,
        });
    };

    handleCustomFieldsChange = (id, values) => {
        const customFields = { ...this.state.customFields };
        customFields.values = _.filter({ ...customFields.values }, field => field.id !== id);
        customFields.values = [
            ...customFields.values,
            {
                id,
                values,
            },
        ];
        this.setState({ customFields });
    };

    handleOverrideCustomFields = e => {
        const { target } = e;
        const value = target.value === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.handleCustomFieldsChange(name, value);
    };

    handleSetList = (key, value) => {
        if (Array.isArray(value)) {
            this.setState({
                [key]: value,
            });
        } else {
            this.setState({
                [key]: [value],
            });
        }
    };

    handleAddToList = (key, value) => {
        if (this.state[key]) {
            if (_.isArray(this.state[key])) {
                if (!this.state[key].includes(value)) {
                    this.setState({
                        [key]: [...this.state[key], value],
                    });
                }
            }
        } else {
            this.setState({
                [key]: [value],
            });
        }
    };

    handleRemoveFromList = (key, value) => {
        if (this.state[key]) {
            if (_.isArray(this.state[key])) {
                if (this.state[key].includes(value)) {
                    this.setState({
                        [key]: _.filter(this.state[key], _value => _value !== value),
                    });
                }
            }
        }
    };

    addImageAttachment = (type, url) => {
        if (type === 'icon') {
            this.setState({
                mediaIcon: url,
            });
        }

        if (type === 'thumb') {
            this.setState({
                mediaThumb: url,
            });
        }
    };

    handleResetImage = (type = 'icon') => {
        if (type === 'icon') {
            this.setState({
                mediaIcon: null,
            });
        }
    };

    // general handler for the toggle button
    handleToggle = (e, value) => {
        const { name } = e.target;
        this.setState({
            [name]: value,
        });
    };

    handleRadio = (target, value) => {
        if (this.state[target] !== value) {
            this.setState({
                [target]: value,
            });
        }
    };

    // this function handles adding selection from the select multiple component
    // into the state
    handleTypes = (e, toggleValue) => {
        const { value } = e.target;
        const { name } = e.target;

        if (toggleValue) {
            this.setState(prevState => ({
                [name]: [value, ...(prevState[name] || [])],
            }));
        } else {
            this.setState({
                [name]: this.state[name].filter(item => {
                    return item !== value;
                }),
            });
        }
    };

    handleToggleLabel = () => {
        this.setState({
            removeLabel: !this.state.removeLabel,
        });
    };

    // Set update state
    addAliasItem = (title, url, type, color, thumb, item, icon) => {
        const newAliasItem = {
            url,
            type,
            title,
            color,
            thumb,
            item,
            icon,
        };

        this.setState(prevState => ({
            aliases: [...prevState.aliases, newAliasItem],
        }));
    };

    setAliasItem = (chapterTitle, linkUrl, linkId, type, filter) => {
        const newAliasItem = {
            title: chapterTitle,
            url: linkUrl,
            // id:linkId,
            type,
            // filter: filter
        };

        this.setState({
            aliases: [newAliasItem],
        });
    };

    deleteAliasItem = item => {
        const currentItems = this.state.aliases;
        const newItems = _.filter(currentItems, currentItem => currentItem.url !== item.url);
        this.setState({
            aliases: newItems,
        });
    };

    updateAliasItem = (item, target, value) => {
        const newAliases = [];
        _.each(this.state.aliases, alias => {
            if (alias.url === item.url) {
                const updateAlias = {
                    ...alias,
                    [target]: value,
                };
                newAliases.push(updateAlias);
            } else {
                newAliases.push(alias);
            }
        });
        this.setState({
            aliases: newAliases,
        });
    };

    searchItemSelected = (item, filter) => {
        const description = item.body
            ? item.body
            : _.isString(item.description)
              ? item.description.substring(0, 30)
              : '';
        const name = `${item.title}${description ? `: ${description}` : ''}`;

        this.setAliasItem(name, item.url, item._id, CARD_CHAPTER, filter);
    };

    // This is used to add an alias item onCLick
    addSearchItemSelected = (item, filter) => {
        const itemThumb = generateDefaultImageOnTileCreation(item);
        const itemIcon = PRE_SELECTED_ICONS.find(icon => icon.name === 'link').value;
        let itemTitle = item.title;
        let itemUrl = item.url;
        let itemType = item.type;

        if (item?.__typename === 'Group') {
            itemTitle = item?.profile?.full_name;
            itemUrl = item?._id;
            itemType = ALIAS_GROUP;
        }

        this.addAliasItem(itemTitle, itemUrl, itemType, null, itemThumb, item, itemIcon);
    };

    getFeatureConstraintsForField = field => {
        const { card } = this.props;
        if (card) {
            const { updateFeatureConstraints } = card;

            if (!updateFeatureConstraints) {
                return true;
            }
            if (updateFeatureConstraints.canEditAllFields) {
                return true;
            }

            const featureConstraint = _.find(updateFeatureConstraints.featuresUserCanEdit, {
                field,
            });
            if (featureConstraint && featureConstraint.canUpdate) {
                return featureConstraint;
            }
            return false;
        }
    };

    handleImgToggle = (img, imgUploads = []) => {
        const { state } = this;

        if (imgUploads.length > 0) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    aliases: [...prevState.aliases, ...imgUploads],
                };
            });
        }

        if (imgUploads.length === 0) {
            const imageExists = state.aliases.find(i => i.url === img.url);

            // add image to imagesSelected array if it hasn't been selected
            if (!imageExists) {
                // if (state.aliases.length === 10) return; // comment out limit for now

                this.setState(prevState => {
                    return {
                        ...prevState,
                        aliases: [...prevState.aliases, img],
                    };
                });
            }

            // remove image from imagesSelected array if it's already been selected
            if (imageExists && state.aliases.length !== 1) {
                const _aliases = clone(state.aliases);
                const updatedAliases = _aliases.filter(i => i.url !== img.url);
                this.setState(prevState => {
                    return {
                        ...prevState,
                        aliases: [...updatedAliases],
                    };
                });
            }
        }
    };

    render() {
        const { card, activeQuery, baseMetricStringName } = this.props;
        const activeLibrary = card?.rootLibrary;
        let updateCard = <div />;
        if (card) {
            updateCard = (
                <UpdateForm
                    card={card}
                    state={this.state}
                    chapterSettings={this.props.chapterSettings}
                    handleUpdateState={this.handleUpdateState}
                    handleChange={this.handleChange}
                    removeLabel={this.state.removeLabel}
                    handleToggleLabel={this.handleToggleLabel}
                    handleToggle={this.handleToggle}
                    handleImgToggle={this.handleImgToggle}
                    handleAddToList={this.handleAddToList}
                    handleRemoveFromList={this.handleRemoveFromList}
                    handleClose={this.props.handleModalClose}
                    handleSubmit={this.handleClick}
                    searchItemSelected={this.searchItemSelected}
                    addSearchItemSelected={this.addSearchItemSelected}
                    updateAliasItem={this.updateAliasItem}
                    deleteAliasItem={this.deleteAliasItem}
                    getFeatureConstraintsForField={this.getFeatureConstraintsForField}
                    baseMetricStringName={baseMetricStringName}
                />
            );

            if (card.type === 'chapter') {
                updateCard = (
                    <DarkChapterForm
                        card={card}
                        state={this.state}
                        handleUpdateState={this.handleUpdateState}
                        handleResetImage={this.handleResetImage}
                        addImageAttachment={this.addImageAttachment}
                        removeLabel={this.state.removeLabel}
                        handleChange={this.handleChange}
                        handleTypes={this.handleTypes}
                        handleToggle={this.handleToggle}
                        handleRadio={this.handleRadio}
                        handleAddToList={this.handleAddToList}
                        handleSetList={this.handleSetList}
                        handleRemoveFromList={this.handleRemoveFromList}
                        allowVoting={this.state.allowVoting}
                        allowPostbox={this.state.allowPostbox}
                        handleToggleLabel={this.handleToggleLabel}
                        capitalizeFirst={this.capitalizeFirst}
                        handleClose={this.props.handleModalClose}
                        handleSubmit={this.handleClick}
                        getFeatureConstraintsForField={this.getFeatureConstraintsForField}
                    />
                );
            }

            if (card.type === 'shelf') {
                updateCard = (
                    <DarkShelfForm
                        card={card}
                        activeLibrary={activeLibrary}
                        state={this.state}
                        handleUpdateState={this.handleUpdateState}
                        handleResetImage={this.handleResetImage}
                        addImageAttachment={this.addImageAttachment}
                        handleChange={this.handleChange}
                        handleTypes={this.handleTypes}
                        handleAddToList={this.handleAddToList}
                        handleSetList={this.handleSetList}
                        handleRemoveFromList={this.handleRemoveFromList}
                        handleCustomFieldsChange={this.handleCustomFieldsChange}
                        handleOverrideCustomFields={this.handleOverrideCustomFields}
                        removeLabel={this.state.removeLabel}
                        handleToggle={this.handleToggle}
                        handleToggleLabel={this.handleToggleLabel}
                        handleRadio={this.handleRadio}
                        capitalizeFirst={this.capitalizeFirst}
                        handleClose={this.props.handleModalClose}
                        handleSubmit={this.handleClick}
                        getFeatureConstraintsForField={this.getFeatureConstraintsForField}
                    />
                );
            }
            if (card.type === 'book') {
                updateCard = (
                    <DarkBookForm
                        card={card}
                        state={this.state}
                        activeLibrary={activeLibrary}
                        handleUpdateState={this.handleUpdateState}
                        handleResetImage={this.handleResetImage}
                        addImageAttachment={this.addImageAttachment}
                        handleChange={this.handleChange}
                        handleAddToList={this.handleAddToList}
                        handleSetList={this.handleSetList}
                        handleRemoveFromList={this.handleRemoveFromList}
                        handleCustomFieldsChange={this.handleCustomFieldsChange}
                        handleOverrideCustomFields={this.handleOverrideCustomFields}
                        removeLabel={this.state.removeLabel}
                        handleToggle={this.handleToggle}
                        handleToggleLabel={this.handleToggleLabel}
                        handleRadio={this.handleRadio}
                        capitalizeFirst={this.capitalizeFirst}
                        handleClose={this.props.handleModalClose}
                        handleSubmit={this.handleClick}
                        getFeatureConstraintsForField={this.getFeatureConstraintsForField}
                    />
                );
            }

            if (this.props.deleteMode) {
                updateCard = (
                    <Confirmation
                        updateCard={this.props.updateCard}
                        handleModalClose={this.props.handleModalClose}
                        card={card}
                        activeQuery={activeQuery}
                    />
                );
            }
        }

        return updateCard;
    }
}
