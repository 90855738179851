import React from 'react';

const NotifyPanelFooter: React.FC<{ handleSubmit: () => void }> = ({ handleSubmit }) => {
    return (
        <footer className="center-modal-header-footer notify-panel-footer">
            <button type="button" onClick={handleSubmit}>
                Send
            </button>
        </footer>
    );
};

export default NotifyPanelFooter;
