import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { z } from 'zod';

import useModal from '@components/modals/hooks/useModal';
import useAntiFlashingBoolean from '@components/generic/hooks/useAntiFlashingBoolean';
import useMetric from '@web/utilities/hooks/metrics/useMetric';

import LoadingLine from '@components/generic/loading/LoadingLine';
import LegoFullScreenLoader from '@components/lego/loading/LegoFullScreenLoader';
import Door from '@assets/lego-door-illustration.svg';

import { ModalTypes } from '@core/types/Modals';
import { TEST_PASSWORD } from '@core/utilities/constants/regexes.js';

import { login } from '@helpers/user/login';
import { setAuthToken } from '@helpers/auth';
import { useCurrentUser } from '@stores/User';
import { triggerHideKeyboardOnKey } from '@core/utilities/keyboardHelpers';
import pushUtilities from '@core/utilities/pushUtilities';

import getLogger from '@core/logger';

const logger = getLogger(module);

const StateValidator = z.object({
    password: z.string().regex(TEST_PASSWORD, `Missing or Invalid Password`),
});

/**
    successCallback
    allows us to pass in a function
    so on successful log-in we can trigger some behavior
    ie: 1. open an external modal after successful login
    ie: 2. redirect the user to a different page after successful login
*/

const AccountGate: React.FC<{ successCallback: () => void }> = ({ successCallback }) => {
    const client = useApolloClient();
    const { closeModal } = useModal({ mobile: ModalTypes.Center });
    const { currentUser, refetchCurrentUser } = useCurrentUser();

    const log = useMetric();

    const [password, setPassword] = useState<string>('');
    const [errors, setErrors] = useState<Record<string, string[]>>({});

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loginErr, setLoginErr] = useState<string | {}>('');

    const isReallyLoading = useAntiFlashingBoolean(isLoading);

    const guardianEmail: string = currentUser?.guardianAccount?.profile?.email || '';

    const validate = () => {
        const parsedData = StateValidator.safeParse({ password });

        if (parsedData.success) {
            setErrors({});
            return true;
        }

        if (parsedData.error) {
            setErrors(parsedData.error.flatten().fieldErrors);
        }

        return false;
    };

    const handleGuardianLogin = async () => {
        if (validate()) {
            setIsLoading(true);

            // @ts-ignore
            const [status, resp] = await login(guardianEmail, password);

            // success
            if (resp?.token) {
                setLoginErr('');
                setAuthToken(resp?.token);

                pushUtilities.syncPushToken(client).catch(error => logger.error(error));
                await client.resetStore();
                refetchCurrentUser();
                // ! a delay is required here
                // ! otherwise the refetch is not
                // ! given enough time to switch between users
                setTimeout(() => {
                    closeModal();
                    successCallback();
                    setIsLoading(false);

                    log({ type: 'Logout' });
                }, 2000);
            } else if (status !== 200) {
                logger.error(resp);
                setLoginErr(resp);
                setIsLoading(false);
            } else {
                logger.error('Received unexpected response while logging in', resp);
                setLoginErr({
                    message: 'There was an error logging in. Please try again soon.',
                });
                setIsLoading(false);
            }
        }
    };

    const handleCloseKeyBoard = (e: KeyboardEvent) => {
        triggerHideKeyboardOnKey(e, 'Enter', 13, () => {
            if (password) {
                handleGuardianLogin();
            }
        });
    };

    if (isReallyLoading && !loginErr) {
        return <LegoFullScreenLoader loadingText="Switching Hero..." />;
    }

    return (
        <div className="acct-gate-container">
            <LoadingLine isLoading={isLoading} error={loginErr} />
            <div className="acct-gate-wrap">
                <div className="acct-gate-title-wrap">
                    <h1 className="acct-gate-title">Locked Door</h1>
                    <h2 className="acct-gate-subtitle">Enter your password.</h2>
                </div>

                <div className="acct-gate-img-wrap">
                    <img src={Door} alt="door" className="acct-gate-img" />
                </div>

                <div className="acct-gate-input-wrap">
                    <input
                        className="acct-gate-input"
                        type="password"
                        placeholder="Password"
                        onChange={e => setPassword(e.target.value)}
                        onKeyDown={e => handleCloseKeyBoard(e)}
                        value={password}
                        required
                    />
                    {errors.password && <p className="acct-gate-input-error">{errors.password}</p>}
                    {loginErr && <p className="acct-gate-input-error">{loginErr.message}</p>}
                </div>

                <div className="acct-gate-btn-wrap">
                    <button className="acct-gate-btn-cancel" type="button" onClick={closeModal}>
                        Cancel
                    </button>
                    <button
                        className="acct-gate-btn-enter"
                        type="button"
                        onClick={handleGuardianLogin}
                    >
                        Enter
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AccountGate;
