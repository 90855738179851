import React from 'react';

type ShareProps = {
    className?: string;
};

const Share: React.FC<ShareProps> = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 500 500" fill="currentcolor">
        <path d="M236.92,101.58l169.37,37.34-98.9,142.43L295,250.26l-15.29-38.47-37.92,16.59c-34.53,15.11-60.52,27-83.35,39.23-26.61,14.2-49.77,31.09-68.81,50.21q-2.86,2.87-5.6,5.81c7.35-19.77,19.17-39.51,35.25-58.89,40.15-48.35,94.14-79.51,109.73-87.9l31.67-17.06-13.62-33.3-10.18-24.9m-47.2-51A9.48,9.48,0,0,0,181.55,55c-2.05,3.3-4.11,6.6-2.71,10.22l31.24,76.37C165.89,165.43-16,277.6,48.76,442.82a10.93,10.93,0,0,0,9.63,6.57,9,9,0,0,0,2-.23c.41,0,.72.07,1,.07.78,0,.81-.31.93-1.64,5.36-1.24,7.42-4.54,7.91-9.73.16-1.73,4.07-80.05,107.16-135C199.46,291,225,279.39,257.87,265l32.48,81.73c1.4,3.62,4.53,7.41,8,7.74.64.06,1.26.09,1.85.09,4.24,0,7.27-1.62,9.22-6L463.21,127.08a11.08,11.08,0,0,0,1-10.38c-1.41-3.63-4.71-5.69-8-7.74L192.43,50.82l-1.73-.17c-.32,0-.65,0-1,0Z" />
    </svg>
);

export default Share;
