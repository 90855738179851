import React from 'react';
import { useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';

import SignUpModal from './SignUpModal';
import SignUpPageButton from './SignUpPageButton';

// const localNamespace = 'imports.wlWeb.ui.pages.new-signup.signup';

type SkipQuestionsModalProps = {
    saveChanges: () => void;
    redirectUrl: string;
};

const SkipQuestionsModal: React.FC<SkipQuestionsModalProps> = ({ saveChanges, redirectUrl }) => {
    // const { t } = useTranslation();
    const { closeModal } = useModal();
    const history = useHistory();

    return (
        <SignUpModal
            title="Save Changes Before Skipping?"
            className="px-[80px] py-[60px] flex flex-col gap-[40px] items-center text-grayscale-body font-poppins text-[18px] leading-[28px] tracking-[0.75px]"
        >
            <span>
                You've made some changes to this screen. Would you like to save them before skipping
                your profile setup?
            </span>
            <div className="flex flex-col gap-[20px] items-center">
                <SignUpPageButton
                    className="px-[26px]"
                    onClick={() => {
                        closeModal();
                        saveChanges();
                        history.push(redirectUrl);
                    }}
                >
                    Skip and Save Changes
                </SignUpPageButton>
                <button
                    type="button"
                    className="text-grayscale-label text-[14px] font-[600] font-poppins leading-[24px] tracking-[0.75px] px-[10px] w-fit"
                    onClick={() => {
                        closeModal();
                        history.push(redirectUrl);
                    }}
                >
                    Skip Without Saving
                </button>
            </div>
        </SignUpModal>
    );
};

export default SkipQuestionsModal;
