import React, { useEffect } from 'react';

import useModal from '@components/modals/hooks/useModal';
import dialogStore from '@stores/Dialog';

import useQuestDialog from '@components/quest/hooks/useQuestDialog';

import Picture from '@components/generic/Picture';
import Button from '@dsc/forms/buttons/Button';
import X from '@dsc/svgs/lego/StylizedX';
import Arrow from '@dsc/svgs/lego/ChunkyArrowRight';

import { getRandomDefaultThumbImage } from '@core/utilities/constants/defaults';

type DialogItemProps = {
    onComplete?: () => void;
    onClose?: () => void;
};

const QuestDialog: React.FC<DialogItemProps> = ({ onComplete, onClose }) => {
    const dialog = dialogStore.use.dialog();
    const event = dialogStore.use.dialogEvent();

    const { closeModal } = useModal();

    const { person, markdownText, previousMessage, nextMessage, activeMessage, numberOfDialogs } =
        useQuestDialog({
            dialog,
            event,
            onComplete,
        });

    useEffect(dialogStore.set.dialogRead, []);

    const close = () => {
        closeModal();
        if (onClose) setTimeout(onClose, 300);
    };

    // Trick to pre-load all dialog images
    const imagePreloaders =
        dialog?.people.map(({ name, image }, index) => (
            <div
                role="presentation"
                style={{ position: 'absolute', opacity: 0, height: '120px', width: '120px' }}
                key={index}
            >
                <Picture
                    url={image || getRandomDefaultThumbImage(name ?? '')}
                    disableLink
                    resolutions={[120, 240, 360]}
                />
            </div>
        )) ?? [];

    return (
        <>
            {imagePreloaders}

            <button
                className="quest-dialog-item-dimmer"
                type="button"
                onClick={close}
                aria-label="Close dialog"
            />

            <section className="quest-dialog-item">
                <button type="button" className="x-button" onClick={close}>
                    <X />
                </button>

                <section>
                    <Picture
                        url={person?.image || getRandomDefaultThumbImage(person?.name ?? '')}
                        disableLink
                        resolutions={[120, 240, 360]}
                    >
                        <figcaption>{person?.name ?? ''}</figcaption>
                    </Picture>

                    {/* eslint-disable-next-line react/no-danger */}
                    <article dangerouslySetInnerHTML={{ __html: markdownText }} />
                </section>

                {numberOfDialogs > 1 && (
                    <footer>
                        <Button
                            type="icon"
                            className="back-button"
                            onClick={previousMessage}
                            disabled={activeMessage === 0}
                        >
                            <Arrow size="100%" color="white" />
                        </Button>
                        <Button type="medium" onClick={nextMessage} className="next-button">
                            {activeMessage === numberOfDialogs - 1 ? 'Close' : 'Next'}
                        </Button>
                    </footer>
                )}
            </section>
        </>
    );
};

export default QuestDialog;
