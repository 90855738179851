import React, { ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import {
    DeleteTaskActivityMutationOptions,
    useDeleteTaskActivityMutation,
    useUpdateTaskActivityMutation,
} from '@shared/welibrary-graphql/content_card/mutations.hook';
import { GetTaskHistoryQuery } from '@shared/welibrary-graphql/content_card/queries.hook';

import { GET_TASK_HISTORY } from '@shared/welibrary-graphql/content_card/queries';

import CommunityListItem from '@web/ui/components/community/CommunityListItem.js';
import DropdownController from '@web/ui/components/generic/dropdownmenu/DropdownController';
import PledgeQuestions from './PledgeQuestions';
import Modal from '@web/ui/components/generic/lightbox/Modal.js';

import NoteIcon from '@web/ui/dsc/svgs/NoteIcon';
import ImageIcon from '@web/ui/dsc/svgs/ImageIcon';
import DocIcon from '@web/ui/dsc/svgs/PaperClipIcon';
import UserIcon from '@web/ui/dsc/svgs/UserIcon';

import { MenuListItem } from '@core/types/Menus';
import { PledgeItem as PledgeItemType } from '@core/types/Pledges';
import { AliasItem, ContentCard } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

const cardNamespace = 'imports.wlWeb.ui.components.card.contentCard';
const pledgeNamespace = 'imports.wlWeb.ui.components.card.pledgeCard';

type PledgeItemProps = {
    cardAlias: AliasItem;
    pledgeItem: PledgeItemType;
    whitelabelVerbage: ContentCard['whitelabelVerbage'];
    cardRefetch: Function;
};

const PledgeItem = React.memo<PledgeItemProps>(function PledgeItem({
    cardAlias,
    pledgeItem,
    whitelabelVerbage,
    cardRefetch,
}) {
    const { t } = useTranslation();

    const [showEdit, setShowEdit] = useState(false);
    const [state, setState] = useState({
        name: '',
        email: '',
        activityId: null as null | string,
        phone: null as null | string,
        country: '',
        note: '',
        newRecruit: false,
        recruitType: null as null | string,
    });

    const verifier = pledgeItem?.verifier;
    const taskDate = pledgeItem?.date && parseInt(pledgeItem.date);
    const displayTime = taskDate ? moment(taskDate).format('MMM D, YYYY, h:mmA') : '';
    const title = pledgeItem?.label;
    const supplementNote = pledgeItem?.supplement?.note;
    const supplementDoc = pledgeItem?.supplement?.document;
    const supplementDocTitle = pledgeItem?.supplement?.documentTitle;
    const supplementImg = pledgeItem?.supplement?.image;

    const [deleteTask] = useDeleteTaskActivityMutation();
    const [updateTaskActivity, { loading }] = useUpdateTaskActivityMutation();

    const handleChange: MouseEventHandler<HTMLInputElement> = e => {
        const { name, value } = e.target as EventTarget & HTMLInputElement;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const handlePhone = (phoneNum: string) => {
        setState(prevState => ({ ...prevState, phone: phoneNum }));
    };

    const handleSelectRecruit: ChangeEventHandler<HTMLSelectElement> = e => {
        e.persist();
        setState(prevState => ({ ...prevState, recruitType: e.target.value }));
    };

    const handleResetForm = () => {
        setState({
            name: '',
            email: '',
            activityId: null,
            phone: null,
            country: '',
            note: '',
            newRecruit: false,
            recruitType: null,
        });
    };

    const handleToggle = () =>
        setState(prevState => ({ ...prevState, newRecruit: !state.newRecruit }));

    // Set initial state of pledge question form when opened
    const handleShowEdit = () => {
        if (verifier) {
            setState({
                name: verifier.name,
                email: verifier.email ?? '',
                activityId: pledgeItem?._id ?? null,
                phone: verifier.phone ?? null,
                country: verifier.country,
                note: verifier.note,
                newRecruit: verifier.newRecruit ?? false,
                recruitType: verifier.recruitType ?? null,
            });
        }
        setShowEdit(true);
    };

    const handleHideEdit = () => {
        handleResetForm();
        setShowEdit(false);
    };

    const updateCache: DeleteTaskActivityMutationOptions['update'] = cache => {
        // this refetch is needed here in order to update the task/pledge count
        // outside the history modal
        cardRefetch();

        const aliasUrl = cardAlias && cardAlias.url;
        try {
            // find in cache
            const result: GetTaskHistoryQuery | null = cache.readQuery({
                query: GET_TASK_HISTORY,
                variables: { type: 'PaginatedTaskCompletion', cardId: aliasUrl },
            });

            const { getTaskHistory } = result ?? {};

            // create updated payload
            const _getTaskHistory = _.clone(getTaskHistory) ?? { results: [] };
            const tasks = _getTaskHistory?.results;
            const updatedTasks = tasks?.filter(task => task?._id !== pledgeItem?._id);
            _getTaskHistory.results = updatedTasks;

            // write back to cache
            cache.writeQuery({
                query: GET_TASK_HISTORY,
                variables: { type: 'PaginatedTaskCompletion', cardId: aliasUrl },
                data: { getTaskHistory: _getTaskHistory },
            });
        } catch (e) {
            logger.error('error update cache', e);
        }
    };

    const handleDelete = () =>
        deleteTask({ variables: { taskId: pledgeItem?._id! }, update: updateCache });

    const _quickMenuList: MenuListItem[] = [];

    if (verifier) {
        _quickMenuList.push({
            id: 'edit-task-item',
            title: t(`common:${cardNamespace}.top_right_list.edit`),
            onClick: () => handleShowEdit(),
        });
    }

    _quickMenuList.push({
        id: 'delete-task-item',
        title: t(`common:${cardNamespace}.top_right_list.delete`),
        onClick: () => {
            handleDelete();
        },
    });

    return (
        <div className="pledge-history-item-container">
            {_.isNull(pledgeItem?.verifier) && _.isNull(pledgeItem?.supplement) && (
                <div className="pledge-history-item-wrap">
                    <div className="pledge-history-item-header">
                        <h6 className="pledge-history-item-title">
                            {t(`common:${pledgeNamespace}.checked`)}{' '}
                            <span className="pledge-history-item-title-span">· {title}</span>
                        </h6>
                    </div>
                    <h6 className="pledge-history-item-date">
                        <span className="pledge-history-item-date-span">{displayTime}</span>
                    </h6>
                </div>
            )}
            {supplementNote && (
                <div className="pledge-history-item-wrap">
                    <div className="pledge-history-item-header">
                        <NoteIcon className="pledge-history-item-icon" />
                        <h6 className="pledge-history-item-title">
                            {t(`common:${pledgeNamespace}.pledge_note.took_note`)}{' '}
                            <span className="pledge-history-item-title-span">· {title}</span>
                        </h6>
                    </div>
                    <h6 className="pledge-history-item-date pledge-history-item-date-content">
                        <span className="pledge-history-item-date-span">{displayTime}</span>
                    </h6>
                    <div className="pledge-history-item-content">
                        <p>{supplementNote}</p>
                    </div>
                </div>
            )}
            {supplementImg && (
                <div className="pledge-history-item-wrap">
                    <div className="pledge-history-item-header">
                        <ImageIcon className="pledge-history-item-icon-fill-blue" />
                        <h6 className="pledge-history-item-title">
                            {t(`common:${pledgeNamespace}.pledge_attachment.uploaded_img`)}{' '}
                            <span className="pledge-history-item-title-span">· {title}</span>
                        </h6>
                    </div>
                    <h6 className="pledge-history-item-date pledge-history-item-date-content">
                        <span className="pledge-history-item-date-span">{displayTime}</span>
                    </h6>
                    <div className="pledge-history-item-content">
                        <img
                            className="pledge-history-item-content-img"
                            src={supplementImg}
                            alt=""
                        />
                    </div>
                </div>
            )}
            {supplementDoc && (
                <div className="pledge-history-item-wrap">
                    <div className="pledge-history-item-header">
                        <DocIcon className="pledge-history-item-icon-fill-blue" />
                        <h6 className="pledge-history-item-title">
                            {t(`common:${pledgeNamespace}.pledge_attachment.uploaded_file`)}{' '}
                            <span className="pledge-history-item-title-span">· {title}</span>
                        </h6>
                    </div>
                    <h6 className="pledge-history-item-date pledge-history-item-date-content">
                        <span className="pledge-history-item-date-span">{displayTime}</span>
                    </h6>
                    <div className="pledge-history-item-content">
                        <p>{supplementDocTitle}</p>
                        <a
                            className="pledge-history-item-content-btn"
                            href={supplementDoc}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t(`common:${pledgeNamespace}.pledge_attachment.view_file`)}
                        </a>
                    </div>
                </div>
            )}
            {pledgeItem?.verifier && (
                <div className="pledge-history-item-wrap">
                    <div className="pledge-history-item-header">
                        <UserIcon className="pledge-history-item-icon" />
                        <h6 className="pledge-history-item-title">
                            {t(`common:${pledgeNamespace}.recruit_question.added_recruit`)}{' '}
                            <span className="pledge-history-item-title-span">· {title}</span>
                        </h6>
                    </div>
                    <h6 className="pledge-history-item-date pledge-history-item-date-content">
                        <span className="pledge-history-item-date-span">{displayTime}</span>
                    </h6>
                    <div className="pledge-history-item-content-recruit">
                        <CommunityListItem
                            taskEdit
                            key={pledgeItem._id}
                            source={pledgeItem}
                            whitelabelVerbage={whitelabelVerbage}
                        />
                        <p>{verifier?.note}</p>
                    </div>
                </div>
            )}
            <div className="pledge-dropdown-positioning">
                <DropdownController
                    menuList={_quickMenuList}
                    buttonClass="content-button-icon dropdown-dots"
                />
            </div>
            <Modal
                delayTime={500}
                smallModal
                className="modal"
                isMounted={showEdit}
                show={showEdit}
                close={handleHideEdit}
            >
                <div className="update-pledge-form">
                    <PledgeQuestions
                        loading={loading}
                        updateTaskActivity={updateTaskActivity}
                        handleSelectRecruit={handleSelectRecruit}
                        editMode
                        handlePhone={handlePhone}
                        state={state}
                        handleChange={handleChange}
                        handleToggle={handleToggle}
                        close={handleHideEdit}
                        whitelabelVerbage={whitelabelVerbage}
                    />
                </div>
            </Modal>
        </div>
    );
});

export default PledgeItem;
