import jwt from 'jsonwebtoken';
import getTorusClient from '@startup/client/torus/init';
import { getAuthToken, setAuthToken } from '@web/utilities/helpers/auth';
import { config } from '@core/config/getConfig';
import { silentlyRefreshJwt } from '@web/utilities/helpers/user/login';

import getLogger from '@core/logger';

const logger = getLogger(module);

const useTorus = () => {
    const getTorusUserInfo = async () => {
        try {
            const [status, resp] = await silentlyRefreshJwt(getAuthToken());

            if (status === 200 && resp?.token) {
                setAuthToken(resp.token);
            } else {
                logger.error('Error refreshing jwt. Exiting torus login');
                return null;
            }
        } catch (e) {
            logger?.warn('Missing token');
        }

        const torus = await getTorusClient();
        const token = getAuthToken();

        if (!token) {
            logger.warn('No user token available');
            return null;
        }

        const userId = jwt.decode(token, { algorithm: 'RS256' })?.sub;

        const userInfo = await torus.getTorusKey(
            config.public?.torus?.verifier,
            userId,
            { verifier_id: userId },
            token
        );

        logger.debug('torus user info', userInfo);

        return userInfo;
    };

    return { getTorusUserInfo };
};

export default useTorus;
