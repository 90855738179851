import { Config, ConfigType } from '@config/schemas/main';

export const CONFIG_LOOKUP = {
    'staging-commissioned': 'staging-commissioned',
    commissioned: 'production-commissioned',
    'production-commissioned': 'production-commssioned',
    croaktest: 'croaktest',
    development: 'development-local',
    'development-local': 'development-local',
    'development-js': 'development-js',
    'development-ssl': 'development-ssl',
    test: 'development-local',
    staging: 'staging-welibrary',
    'staging-welibrary': 'staging-welibrary',
    production: 'production-welibrary',
    'production-welibrary': 'production-welibrary',
    lego: 'lego-ssl',
    ioe: 'ioe',
    'lego-ssl': 'lego-ssl',
    'production-ioe': 'ioe',
    ioe: 'ioe',
    'production-itz': 'production-itz',
    itz: 'production-itz',
    nickv: 'production-nickv',
    'production-nickv': 'production-nickv',
    lausanne: 'production-lausanne',
    'production-lausanne': 'production-lausanne',
    'connect': 'production-connect',
    'production-connect': 'production-connect',
} as const;

function getConfig(): ConfigType;
function getConfig(key: string): string;
function getConfig(key?: string) {
    const nodeEnv = process.env.REACT_APP_NODE_ENV as keyof typeof CONFIG_LOOKUP;
    const configFile = CONFIG_LOOKUP[nodeEnv] ?? 'development-local';

    const config = require(`./stages/${configFile}.ts`).default;

    const parsed = Config.safeParse(config);

    if (parsed.success) {
        return key === undefined
            ? parsed.data
            : key.split('.').reduce((config, lvl) => config && config[lvl], config);
    } else {
        throw new Error(parsed.error.message);
    }
}

export const config = getConfig();

export default getConfig;
