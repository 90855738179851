// Should add a whitelist to ensure only supported languages are selected;
export default ['en'];

// Some languages we support, but browsers use multiple codes per language. This enables a translation into our file schema
export const languageCipher = {
    'en-us': 'en',
    'en-gb': 'en',
    'af-za': 'af',
    'am-et': 'am',
    'ar-bh': 'ar-bh',
    'ar-dz': 'ar-dz',
    'ar-eg': 'ar-eg',
    'ar-iq': 'ar-iq',
    'ar-jo': 'ar-jo',
    'ar-kw': 'ar-kw',
    'ar-lb': 'ar-lb',
    'ar-ly': 'ar-ly',
    'ar-ma': 'ar-ma',
    'ar-om': 'ar-om',
    'ar-qa': 'ar-qa',
    'ar-sa': 'ar-sa',
    'ar-sy': 'ar-sy',
    'ar-tn': 'ar-tn',
    'ar-ae': 'ar',
    'ar-ye': 'ar-ye',
    'bg-bg': 'bg',
    'bn-in': 'bn',
    'ca-es': 'ca',
    'cs-cz': 'cs',
    'cy-gb': 'cy',
    'da-dk': 'da',
    'de-de': 'de',
    'el-gr': 'el',
    'es-es': 'es',
    'et-ee': 'et',
    'eu-es': 'eu',
    'fa-ir': 'fa',
    'fi-fi': 'fi',
    'fr-fr': 'fr',
    'gu-in': 'gu',
    'hi-in': 'hi',
    'hr-hr': 'hr',
    'hy-am': 'hy',
    'id-id': 'id',
    'is-is': 'is',
    'it-it': 'it',
    'ja-jp': 'ja',
    'ka-ge': 'ka',
    'km-kh': 'km',
    'kn-in': 'kn',
    'ko-kr': 'ko',
    'lo-la': 'lo',
    'lt-lt': 'lt',
    'lv-lv': 'lv',
    'ml-in': 'ml',
    'mr-in': 'mr',
    'ms-my': 'ms',
    'my-mm': 'my',
    'nl-nl': 'nl',
    'nn': 'no',
    'nn-no': 'no',
    'or-in': 'or',
    'pa-in': 'pa',
    'pl-pl': 'pl',
    'pt-br': 'pt-BR',
    'pt-pt': 'pt-PT',
    'ro-ro': 'ro',
    'ru-ru': 'ru',
    'sk-sk': 'sk',
    'sl-si': 'sl',
    'sv-se': 'sv',
    'sw-tz': 'sw',
    'ta-in': 'ta',
    'te-in': 'te',
    'th-th': 'th',
    'tr-tr': 'tr',
    'uk-ua': 'uk',
    'ur-pk': 'ur',
    'vi-vn': 'vi',
    'zh-cn': 'zh',
    'sr-latn-rs': 'sr',
};
