/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import {
    constructCommentCardUrl,
    constructPostDate,
} from '@components/search/searchcard/components/helpers';
import { MessageAuthorThumb } from '@components/search/searchcard/components';

import { Comment } from '@shared/welibrary-graphql/types';

type CommentSearchCardProps = {
    source: Comment;
    handleCloseSearch: () => void;
};

const CommentSearchCard: React.FC<CommentSearchCardProps> = ({ source, handleCloseSearch }) => {
    const stream = source && source.stream;
    const parentCardId = stream && stream._id;
    const parentCardUrl = parentCardId ? constructCommentCardUrl(parentCardId) : '#';
    const user = source && source.creator;
    const userThumb = user?.profile?.picture;
    const userName = user?.profile?.full_name;

    const commentDate = source && constructPostDate(source.publish);

    let postTitle;
    if (stream?.title) {
        postTitle = `${stream.title.substring(0, 15).trim()}...`;
    } else if (stream?.body) {
        postTitle = `${stream.body.substring(0, 15).trim()}...`;
    }
    // let messageDate = source && constructPostDate(source.updated);
    const textContent = source?.body && `${source.body.substring(0, 40).trim()}...`;
    const showSpacer = postTitle && postTitle.trim() !== '';
    const renderSpacer = showSpacer ? <span className="dot-spacer comment"> • </span> : null;
    const history = useHistory();

    const handleClick = () => {
        history.push({
            pathname: parentCardUrl,
            fromSearch: true,
            comments: true,
        });
        handleCloseSearch();
    };

    const commentTitleClass = _.isEmpty(postTitle)
        ? 'search-result-comment-title inline'
        : 'search-result-comment-title';
    return (
        <button
            type="button"
            onClick={handleClick}
            className="button-reset search-result-item search-result-block-item"
        >
            <div className="search-result-thumb-containter">
                <MessageAuthorThumb thumbImage={userThumb} />
            </div>
            <div className="search-result-item-middle">
                <div className="search-result-item-info">
                    <span className={commentTitleClass}>
                        Comment on <a className="bold"> {postTitle}</a>
                    </span>
                    {renderSpacer}{' '}
                    <span className="search-result-comment-date"> {commentDate}</span>
                    <br />
                </div>
                <p className="search-result-item-snippet">
                    <span className="bold">{userName}: </span> <span>{textContent}</span>
                </p>
            </div>
        </button>
    );
};

export default CommentSearchCard;
