import React, { useState } from 'react';

import { awsMetricPublisher } from '@welibraryos/metrics';
import ExternalLink from '@web/ui/components/generic/ExternalLink';
import SideMenuLanguagePicker from '@web/ui/components/navigation/SideMenu/Main/SideMenuLanguagePicker';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import useSideMenuFooter from '@web/ui/components/navigation/SideMenu/Main/hooks/useSideMenuFooter';
import { titleCase } from '@helpers/string.helpers';

import { config } from '@core/config/getConfig';

const localNamespace = 'imports.wlWeb.ui.components.navigation.slideMenu';

const SideMenuFooter = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        t,
        logout,
        closeSideMenu,
        currentYear,
        copyrightName,
        footerText,
        showLinks,
        privacyPolicyLink,
        termsOfServiceLink,
        communityGuidelinesLink,
        versionNumber,
    } = useSideMenuFooter();

    const onClickTerms = () => {
        awsMetricPublisher.publishCount('side-menu', 1, [{ Name: 'menu', Value: 'terms' }]);
    };

    const onClickPolicy = () => {
        awsMetricPublisher.publishCount('side-menu', 1, [
            { Name: 'menu', Value: 'privacy-policy' },
        ]);
    };

    const links = showLinks && (
        <span>
            {privacyPolicyLink && (
                <ExternalLink to={privacyPolicyLink} onClick={onClickPolicy}>
                    {t(`common:${localNamespace}.privacy_policy`)}
                </ExternalLink>
            )}
            {termsOfServiceLink && (
                <>
                    {' | '}
                    <ExternalLink to={termsOfServiceLink} onClick={onClickTerms}>
                        {t(`common:${localNamespace}.terms`)}
                    </ExternalLink>
                </>
            )}
            {communityGuidelinesLink && (
                <>
                    {' | '}
                    <ExternalLink to={communityGuidelinesLink}>
                        {titleCase(t('common:community_guidelines', 'community guidelines'))}
                    </ExternalLink>
                </>
            )}
            {versionNumber && ` | ${versionNumber}`}
        </span>
    );

    const handleLogout = () => {
        awsMetricPublisher.publishCount('side-menu', 1, [{ Name: 'menu', Value: 'logout' }]);

        setIsLoading(true);
        // set timeOut, so loader is shown for a split second
        // otherwise logout -> redirect happens immediately since authToken doesn't exist
        setTimeout(() => {
            logout();
        }, 1500);
    };

    const handleGettingStarted = () => {
        awsMetricPublisher.publishCount('side-menu', 1, [
            { Name: 'menu', Value: 'get-started-tour' },
        ]);
        closeSideMenu();
    };

    return (
        <>
            <LoadingLine isLoading={isLoading} />
            <footer className="side-menu-main-footer">
                {config?.public?.showGettingStarted && (
                    <button
                        type="button"
                        onClick={handleGettingStarted}
                        className="getting-started"
                    >
                        {t(`common:${localNamespace}.getting_started_tour`, 'Getting Started Tour')}
                    </button>
                )}

                <button type="button" onClick={handleLogout} className="logout-button">
                    {t(`common:${localNamespace}.logout`)}
                </button>

                <section className="side-menu-main-footer-info">
                    <span>
                        ©{currentYear} {copyrightName}
                    </span>
                    {footerText && <span>{footerText}</span>}
                    {links}
                </section>

                <SideMenuLanguagePicker />
            </footer>
        </>
    );
};

export default SideMenuFooter;
