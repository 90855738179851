import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { generateSrcSet, resizeAndChangeQuality } from '@helpers/images/images.helpers';

const MAX_COVER_IMAGE = 41;
const MIN_COVER_IMAGE = 1;

export const getRandomDefaultCoverImage = (title?: string) => {
    let randomNumber: number;

    // For consistent randomness, use the first character of the title to pick a cover
    if (title && _.isString(title)) {
        randomNumber = (title.charCodeAt(0) % (MAX_COVER_IMAGE - 1)) + MIN_COVER_IMAGE;
    } else randomNumber = 14; // Math.floor((Math.random() * MAX_COVER_IMAGE) + MIN_COVER_IMAGE);
    return `/images/defaults/cover_images/CoverPhoto-${randomNumber}.jpg`;
};

const NON_AZ_THUMBS = {
    0: 'Default1-LINE.jpg',
    1: 'Default2-SQUARE.jpg',
    2: 'Default3-TRIANGLE.jpg',
    3: 'Default4-CIRCLE.jpg',
    4: 'Default5-RAINBOW.jpg',
    5: 'Default5-FACE.jpg',
};

export const getRandomDefaultThumbImage = (title?: string) => {
    if (title) {
        const isAZ = /(^[a-zA-Z]?$)/i;
        const firstLetter = title.charAt(0);
        if (firstLetter) {
            if (isAZ.test(firstLetter)) {
                return `/images/defaults/thumbs/Default-${firstLetter.toUpperCase()}.jpg`;
            }
        }
    }

    const randomNumber = 4; // Math.floor((Math.random() * Object.keys(NON_AZ_THUMBS).length));
    return `/images/defaults/thumbs/${NON_AZ_THUMBS[randomNumber]}`;
};

export const getRandomDefaultUserImage = () => {
    const userImage = 'Profile1-Black.jpg';
    return `/images/defaults/thumbs/user/${userImage}`;
};

export const getAllDefaultCoverImages = () => {
    const coverImageUrls = [];

    for (let i = MIN_COVER_IMAGE; i <= MAX_COVER_IMAGE; i += 1) {
        coverImageUrls.push(`/images/defaults/cover_images/CoverPhoto-${i}.jpg`);
    }

    return coverImageUrls;
};

export const legoStockBadgeImages = [
    'https://cdn.filestackcontent.com/eCXX8KZYRZqGzFZTSAgG', // Markers
    'https://cdn.filestackcontent.com/Jun8JnIiTXmkx8depWhv', // Castle
    'https://cdn.filestackcontent.com/HUlSKRzCTXqyBkYNkU7f', // Excavator
    'https://cdn.filestackcontent.com/J5OSajnWQeaoCKayBNBk', // Dog
    'https://cdn.filestackcontent.com/LxNQ72awTKO5Zm8dB4s8', // Nesting Dolls
    'https://cdn.filestackcontent.com/wH0SXZQjQgGlS9UTA66Y', // Gumball
    'https://cdn.filestackcontent.com/e3VCdZhpQCeGMw3FBS0Q', // Robot
    'https://cdn.filestackcontent.com/to2eJ2cURDq8TkrQuiIs', // Hammer Toy
    'https://cdn.filestackcontent.com/VQFz7FX1RzyCroIdqraY', // Bucket
    'https://cdn.filestackcontent.com/V4Ta6dXbSAqT3sXHlHJO', // Horse Toy
];

const EXIF_TRANSFORMATION = 'rotate=deg:exif';

export const transformExif = (imageUrl?: string) => {
    if (!imageUrl) return imageUrl;

    if (imageUrl.includes('cdn.filestackcontent.com')) {
        if (!imageUrl.includes('rotate=deg:exif')) {
            const splitUrl = imageUrl.split('.com/');
            if (splitUrl.length === 2) {
                return `${splitUrl[0]}.com/${EXIF_TRANSFORMATION}/${splitUrl[1]}`;
            }
        }
    }
    return imageUrl;
};

const PROGRESSIVE_JPEG_TRANSFORMATION = 'pjpg';

export const progressiveJpg = (imageUrl?: string) => {
    if (!imageUrl) return imageUrl;

    if (imageUrl.includes('cdn.filestackcontent.com')) {
        if (!imageUrl.includes(PROGRESSIVE_JPEG_TRANSFORMATION)) {
            const splitUrl = imageUrl.split('.com/');
            if (splitUrl.length === 2) {
                return `${splitUrl[0]}.com/${PROGRESSIVE_JPEG_TRANSFORMATION}/${splitUrl[1]}`;
            }
        }
    }
    return imageUrl;
};

export const getBackgroundImageWithGradient = (
    imageUrl: string,
    topOpacity: string | number,
    bottomOpacity: string | number,
    xOffset = '50%',
    yOffset = '50%',
    bgSize = 'cover'
) => {
    const _imageUrl = imageUrl || getRandomDefaultCoverImage('a');

    // TODO: I don't think the browser fallbacks work here...
    return {
        background: `-moz-linear-gradient(top, rgba(0, 0, 0, ${topOpacity}) 0%, rgba(0, 0, 0, ${topOpacity}) 0%, rgba(0, 0, 0, ${bottomOpacity}) 100%), url(${_imageUrl}) ${xOffset} ${yOffset}/ ${bgSize} no-repeat`,
        background: `-webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, ${topOpacity})), color-stop(0%, rgba(0, 0, 0, ${topOpacity})), color-stop(100%, rgba(0, 0, 0, ${bottomOpacity}))), url(${_imageUrl})  ${xOffset} ${yOffset}/ ${bgSize} no-repeat`,
        background: `-webkit-linear-gradient(top, rgba(0, 0, 0, ${topOpacity}) 0%, rgba(0, 0, 0, ${topOpacity}) 0%, rgba(0, 0, 0, ${bottomOpacity}) 100%), url(${_imageUrl})  ${xOffset} ${yOffset}/${bgSize} no-repeat`,
        background: `-o-linear-gradient(top, rgba(0, 0, 0, ${topOpacity}) 0%, rgba(0, 0, 0, ${topOpacity}) 0%, rgba(0, 0, 0, ${bottomOpacity}) 100%), url(${_imageUrl})  ${xOffset} ${yOffset}/${bgSize} no-repeat`,
        background: `-ms-linear-gradient(top, rgba(0, 0, 0, ${topOpacity}) 0%, rgba(0, 0, 0, ${topOpacity}) 0%, rgba(0, 0, 0, ${bottomOpacity}) 100%), url(${_imageUrl})  ${xOffset} ${yOffset}/${bgSize} no-repeat `,
        background: `linear-gradient(to bottom, rgba(0, 0, 0, ${topOpacity}) 0%, rgba(0, 0, 0, ${topOpacity}) 0%, rgba(0, 0, 0, ${bottomOpacity}) 100%), url(${_imageUrl})  ${xOffset} ${yOffset}/${bgSize} no-repeat`,
        // backgroundPosition: '50% 50%',
        // backgroundSize: 'cover'
    };
};

const getBackgroundImageSet = (url = '', width = 600, exif = true) => {
    const srcSet = generateSrcSet(url, [width, width * 2, width * 3, width * 4], { fix: exif });
    const src = resizeAndChangeQuality(url, width, 75, { fix: exif });

    const convertedSrcSet = srcSet
        .split(', ')
        .map((scaledSrc, index) => `url("${scaledSrc.split(' ')[0]}") ${index + 1}x`)
        .join(', ');

    return [src, convertedSrcSet];
};

export const getBackgroundImageSetForUrl = (url?: string, width = 300, exif = true) => {
    if (!url) return [];

    return getBackgroundImageSet(url, width, exif);
};

export const StyledBackgroundImage = styled.div<{ src: string; srcSet: string }>`
    ${props => (props.src ? `background-image: url('${props.src}') !important;` : '')}
    ${props =>
        props.srcSet
            ? `
                background-image: -webkit-image-set(${props.srcSet}) !important;
                background-image: -moz-image-set(${props.srcSet}) !important;
                background-image: -o-image-set(${props.srcSet}) !important;
                background-image: -ms-image-set(${props.srcSet}) !important;
                background-image: image-set(${props.srcSet}) !important;`
            : ''}
`;

export const StyledBackgroundImageLink = styled(Link) <{ src: string; srcSet: string }>`
    ${props => (props.src ? `background-image: url('${props.src}') !important;` : '')}
    ${props =>
        props.srcSet
            ? `
                background-image: -webkit-image-set(${props.srcSet}) !important;
                background-image: -moz-image-set(${props.srcSet}) !important;
                background-image: -o-image-set(${props.srcSet}) !important;
                background-image: -ms-image-set(${props.srcSet}) !important;
                background-image: image-set(${props.srcSet}) !important;`
            : ''}
`;
