import React from 'react';

import CreationProgressBar from '@components/lego/creation/CreationProgressBar';
import Picture from '@components/generic/Picture';

import { getCreationImageSimple } from '@helpers/creations/creation.helpers';

import { CreationState } from '@shared/welibrary-graphql/types';

const CreationFull: React.FC<{ creation: CreationState }> = ({ creation }) => {
    return (
        <div className="resource-full-container">
            <div className="treasure-gradient-bg">
                <Picture disableLink url={creation.creation?.media?.thumb ?? ''} />
            </div>
            <div className="resource-full-header creation">
                <h1>{creation.creation?.title ?? ''}</h1>
            </div>
            <div className="resource-body creation-content">
                <div className="creation-icon-large">
                    <Picture
                        className="creation-large-thumb"
                        disableLink
                        url={getCreationImageSimple(creation)}
                    />
                </div>
                <CreationProgressBar
                    value={(creation.level ?? 1) - 1}
                    levels={creation.creation?.creationItems?.levels?.length ?? 3}
                    numberColor="#219653"
                />
            </div>
        </div>
    );
};

export default CreationFull;
