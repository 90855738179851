import React from 'react';
import moment from 'moment';

import { useListInvoicesForUserQuery } from '@shared/welibrary-graphql/user/queries.hook';

import { getCurrencyDisplayString } from '@web/utilities/helpers/money/money.helpers';

const SubscriptionPaymentHistory: React.FC<{ groupId: string }> = ({ groupId }) => {
    const { data } = useListInvoicesForUserQuery({
        variables: {
            input: {
                groupId,
            },
        },
        fetchPolicy: 'no-cache',
    });

    const invoices = data?.listInvoicesForUser?.results ?? [];

    return (
        <ul className="subs-payment-history-list-wrap">
            {invoices.map(invoice => {
                const currency = invoice?.subscription?.currency;
                const priceDisplayString = getCurrencyDisplayString(invoice?.total, currency, undefined, false, 2);

                const formattedDate = moment
                    .unix(invoice?.payment_intent?.created ?? invoice?.created)
                    .format('MM/DD/YY');

                const invoiceItemDescription = invoice?.lines?.data?.[0]?.description;
                return (
                    <li key={invoice?.id} className="subs-payment-history-item">
                        <div className="subs-payment-history-item-top-row">
                            <p>{formattedDate}</p>
                            <h3>{priceDisplayString}</h3>
                        </div>
                        <div className="subs-payment-history-item-bottom-row">
                            <p>{invoiceItemDescription}</p>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default SubscriptionPaymentHistory;
