import React from 'react';
import { useTranslation } from 'react-i18next';

import Picture from '@web/ui/components/generic/Picture';

import { getRandomDefaultUserImage } from '@core/utilities/constants/defaults';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

const Postbox = ({
    marginBottom,
    displaySocialHeaders,
    onClick,
    defaultPromptText,
    profilePhotoUrl,
    numberOfPrompts,
}) => {
    const { t } = useTranslation();
    const profileThumb = profilePhotoUrl || getRandomDefaultUserImage();

    return (
        <button type="button" onClick={onClick} className="postbox" style={{ marginBottom }}>
            <Picture url={profileThumb} disableLink resolutions={[19, 38, 76]}>
                {!displaySocialHeaders && <div className="anonymous-thumb-overlay small" />}
            </Picture>

            {numberOfPrompts && <span className="multi-postbox-number">{numberOfPrompts}</span>}

            <span className="prompt">
                {defaultPromptText || t(`common:${localNamespace}.share_something`)}
            </span>
        </button>
    );
};

export default Postbox;
