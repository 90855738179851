import React from 'react';

const PlayCircle: React.FC<{ className?: string; size?: string; strokeWidth?: string }> = ({
    className,
    size = '50',
}) => (
    <svg className={className} width={size} height={size} viewBox="0 0 40 40" fill="none">
        <circle opacity="0.6" cx="20" cy="20" r="20" fill="black" />
        <path
            d="M28.9925 18.8227L16.5814 11.2379C16.3723 11.11 16.133 11.0402 15.888 11.0356C15.643 11.0309 15.4012 11.0917 15.1875 11.2115C14.9737 11.3313 14.7958 11.5059 14.6719 11.7174C14.5481 11.9288 14.4828 12.1694 14.4828 12.4145V27.5855C14.483 27.8305 14.5484 28.071 14.6723 28.2823C14.7962 28.4936 14.9741 28.6681 15.1878 28.7879C15.4015 28.9077 15.6432 28.9685 15.8881 28.9639C16.133 28.9594 16.3723 28.8897 16.5814 28.7621L28.9925 21.1773C29.1941 21.054 29.3607 20.881 29.4762 20.6748C29.5918 20.4687 29.6525 20.2363 29.6525 20C29.6525 19.7637 29.5918 19.5313 29.4762 19.3252C29.3607 19.119 29.1941 18.946 28.9925 18.8227Z"
            fill="white"
        />
    </svg>
);

export default PlayCircle;
