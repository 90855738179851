/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef } from 'react';
import { useImmer, Updater } from 'use-immer';
import { z } from 'zod';

import Avatar from '@components/profile/Lego/Avatar';
import SkinToneSelector from '@components/profile/Lego/SkinToneSelector';

import AvatarHoverShadow from '@assets/avatar-shadow.png';

import WoodButton from '@dsc/buttons/Lego/WoodButton';
import StylizedX from '@dsc/svgs/lego/StylizedX';
import Checkmark from '@assets/lego-checkmark-black.svg';
import LockIcon from '@dsc/svgs/LockIcon';

import useQuest from '@components/quest/hooks/useQuest';
import { useCurrentUser } from '@stores/User';
import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';
import {
    getNamedRefsFromArray,
    getCarouselFunctions,
} from '@helpers/navigation/horizontalNav.helpers';

import { curriedStateSlice } from '@helpers/state/state.helpers';

import {
    AvatarType,
    AvatarTypes,
    AvatarAllowedColorsType,
    AvatarValidator,
} from '@components/profile/Lego/Avatars';

import { UserSimpleProfileState } from '@core/types/Avatars';
import { isEphemeral, childHasPermission } from '@web/utilities/helpers/user/child.helpers';
import { ChildPermissionsEnum } from '@pages/lego/AccountSwitcher/AccountManagementHelpers';
import { isAvatarDisabled } from '@components/profile/Lego/avatarHelpers';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

type AvatarContainerProps = {
    avatar: AvatarType;
    skinTone?: AvatarAllowedColorsType['skinTone'];
    notLoggedInUser?: boolean;
};

const AvatarEditContainer = React.forwardRef<HTMLDivElement, AvatarContainerProps>(
    function AvatarEditContainer({ avatar, skinTone, notLoggedInUser = false }, ref) {
        const { main_quest_url, main_quest_map } = getWhitelabelPublicSettings();

        const quest = useQuest(main_quest_map || main_quest_url || '');

        const { isComplete, quest: { currentNode = 0 } = {} } = quest || {};

        let isDisabled = notLoggedInUser;

        if (main_quest_map) isDisabled = isAvatarDisabled(avatar, currentNode ?? 0);
        else isDisabled = !isComplete && isAvatarDisabled(avatar);

        if (notLoggedInUser) isDisabled = isAvatarDisabled(avatar);

        return (
            <div className="hero-creator-avatar-wrap" ref={ref}>
                <Avatar
                    className={`hero-creator-avatar ${
                        isDisabled ? 'hero-creator-avatar-disabled' : ''
                    }`}
                    avatar={avatar}
                    colors={{ skinTone }}
                />
                <img
                    src={AvatarHoverShadow}
                    alt="hover shadow"
                    className="hero-creator-avatar-shadow"
                />
                {isDisabled && (
                    <div className="hero-creator-avatar-locked">
                        {' '}
                        <LockIcon className="hero-creator-skin-tone-picker-btn-svg" />
                    </div>
                )}
            </div>
        );
    }
);

// TODO: This should probably live somewhere else, but where?
const StateValidator = z.object({
    avatar: AvatarValidator,
    name: z.string().nonempty('Please enter a name').max(50),
    age: z
        .number()
        .nonnegative('Please enter a valid age!')
        .min(1, 'Please enter a valid age!')
        .max(122, 'You are too old to use this app!')
        .int('Please enter a valid age!'),
});

type HeroCreatorProps = {
    state: UserSimpleProfileState;
    setState: Updater<UserSimpleProfileState>;
    onSubmit: () => void;
    onCancel: () => void;
    notLoggedInUser?: boolean;
};

const HeroCreator: React.FC<HeroCreatorProps> = ({
    state,
    setState,
    onSubmit,
    onCancel,
    notLoggedInUser = false,
}) => {
      const { currentUser } = useCurrentUser();
    const { main_quest_url, main_quest_map } = getWhitelabelPublicSettings();
    const quest = useQuest(main_quest_map || main_quest_url || '');

    const updateSlice = curriedStateSlice(setState);
    const updateAvatar = curriedStateSlice(updateSlice('avatar'));

    const [errors, setErrors] = useImmer<Record<string, string[]>>({});

    const container = useRef<HTMLDivElement>(null);

    const refs = getNamedRefsFromArray<AvatarType, HTMLDivElement>(AvatarTypes);

    const { on, scrollTo } = useHorizontalPages({ refs, options: { root: container.current } });

    const { current, isFirst, isLast, scrollToNext, scrollToPrevious } = getCarouselFunctions({
        on,
        scrollTo,
    });

    const validate = () => {
        const parsedData = StateValidator.safeParse({ ...state, age: Number(state.age) });

        if (parsedData.success) {
            setErrors({});

            return true;
        }

        setErrors(parsedData.error.flatten().fieldErrors);

        return false;
    };

    const updateState: typeof updateSlice = (field, value) => {
        return updateSlice(field, value);
    };

    const increaseAge = () => {
        if (state.age === 0 || state.age === undefined) {
            updateState('age', 1);
            return;
        }

        // age cannot be more then 122 (oldest person to ever live)
        if (state.age >= 1 && state.age <= 122) updateState('age', state.age + 1);
    };

    const decreaseAge = () => {
        // age cannot be less then 1
        if (state.age === undefined || state.age <= 1) return;

        if (state.age > 1) updateState('age', state.age - 1);
    };

    // Scroll to user's avatar on load
    useEffect(() => {
        scrollTo[state.avatar.avatar](false);
    }, []);

    // Keep avatar state in sync with shown avatar
    useEffect(() => {
        updateAvatar('avatar', current);
    }, [current]);

    // disable avatar saving
    const { isComplete, quest: { currentNode = 0 } = {} } = quest || {};

    let isDisabled = notLoggedInUser;

    if (main_quest_map) isDisabled = isAvatarDisabled(current, currentNode ?? 0);
    else isDisabled = !isComplete && isAvatarDisabled(current);

    if (notLoggedInUser) isDisabled = isAvatarDisabled(current);

    return (
        <div className="hero-creator">
            {/* if an avatar exists on the user object render that one first */}
            <div className="hero-creator-avatar-container">
                {!isFirst && (
                    <WoodButton
                        className="hero-creator-avatar-btn-prev"
                        onClick={() => scrollToPrevious()}
                    />
                )}
                <div className="hero-creator-avatar-slider" ref={container}>
                    {AvatarTypes.map(avatar => {
                        return (
                            <AvatarEditContainer
                                ref={refs[avatar]}
                                avatar={avatar}
                                skinTone={state.avatar.skinTone}
                                key={avatar}
                                notLoggedInUser={notLoggedInUser}
                            />
                        );
                    })}
                </div>
                {!isLast && (
                    <WoodButton
                        className="hero-creator-avatar-btn-next"
                        onClick={() => scrollToNext()}
                    />
                )}
            </div>
            <SkinToneSelector
                setSkinTone={updateAvatar('skinTone')}
                skinTone={state.avatar.skinTone}
                notLoggedInUser={notLoggedInUser}
            />
            {errors.name && <p className="error">{errors.name.join()}</p>}
            {isEphemeral(currentUser) ||
            !childHasPermission(currentUser, ChildPermissionsEnum.DisplayName) ? (
                <div className="hero-creator-input-wrap hero-creator-input-wrap-disabled">
                    <div className="hero-creator-input-name-disabled">
                        <p className="hero-creator-input-name">{state.name}</p>
                    </div>
                </div>
            ) : (
                <div className="hero-creator-input-wrap">
                    <input
                        value={state.name}
                        onChange={e => updateState('name', e.target.value)}
                        type="text"
                        placeholder="name"
                        className="hero-creator-input-name"
                    />
                </div>
            )}

            {errors.age && <p className="error">{errors.age.join()}</p>}
            <div className="hero-creator-input-wrap">
                <button type="button" className="hero-creator-input-decrease" onClick={decreaseAge}>
                    -
                </button>
                <input
                    value={state.age}
                    onChange={e => updateState('age', e.target.value)}
                    type="number"
                    placeholder="age"
                    className="hero-creator-input-age"
                    min={1}
                />
                <button type="button" className="hero-creator-input-increase" onClick={increaseAge}>
                    +
                </button>
            </div>
            <div className="hero-creator-btn-edit-wrap">
                <button type="button" className="hero-creator-btn-cancel" onClick={onCancel}>
                    <h2 className="hero-creator-btn-cancel-title">
                        Cancel <StylizedX className="hero-creator-btn-x-icon" />
                    </h2>
                </button>
                <button
                    type="button"
                    className={`hero-creator-btn-save ${
                        isDisabled ? 'hero-creator-btn-disabled' : ''
                    }`}
                    disabled={isDisabled}
                    onClick={() => {
                        if (validate()) onSubmit();
                    }}
                >
                    <h2 className="hero-creator-btn-save-title">
                        Save{' '}
                        {isDisabled ? (
                            <LockIcon className="hero-creator-btn-disabled-icon" />
                        ) : (
                            <img
                                src={Checkmark}
                                className="hero-creator-btn-save-icon"
                                alt="checkmark icon"
                            />
                        )}
                    </h2>
                </button>
            </div>
        </div>
    );
};

export default HeroCreator;
