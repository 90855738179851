import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Updater } from 'use-immer';
import { z } from 'zod';

import TextArea from '@components/generic/forms/TextArea';
import ThumbnailAndCoverImageUpload from '@dsc/forms/customInputs/ThumbnailAndCoverImageUpload';
import LocationVenueSettings from '@components/content/newpost/forms/EventGroup/LocationVenueSettings';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import {
    getRandomDefaultThumbImage,
    getRandomDefaultCoverImage,
} from '@core/utilities/constants/defaults';

import { OverviewFormState, LocationTypeEnum } from '@core/types/EventGroup';

type EventGroupOverviewFormProps = {
    state: OverviewFormState;
    setState: Updater<OverviewFormState>;
    updateValidationSlice: Updater<boolean>;
    singleImageUpload?: boolean;
};

const StateValidator = z.object({
    title: z.string().nonempty('Please enter a title').max(50),
    description: z.string().max(255),
    virtualLink: z.string().nonempty('Please enter a link').max(255),
    location: z.string().nonempty('Please pick a location').max(255),
});

const EventGroupOverviewForm = React.forwardRef<HTMLFieldSetElement, EventGroupOverviewFormProps>(
    function EventGroupOverviewForm(
        { state, setState, updateValidationSlice, singleImageUpload },
        ref
    ) {
        const { t } = useTranslation();

        const updateSlice = curriedStateSlice(setState);
        const [errors, setErrors] = useState<Record<string, string[]>>({});

        const validate = () => {
            const {
                title,
                eventLocationSettings: { type, venue, link },
                description,
            } = state;

            let parsedData = StateValidator.safeParse({
                title,
                description,
                location: 'default',
                virtualLink: 'default',
            });

            if (type === LocationTypeEnum.inPerson) {
                parsedData = StateValidator.safeParse({
                    title,
                    description,
                    location: venue?.location?.formatted_address,
                    virtualLink: 'default',
                });
            }

            if (type === LocationTypeEnum.virtual) {
                parsedData = StateValidator.safeParse({
                    title,
                    description,
                    virtualLink: link,
                    location: 'default',
                });
            }

            if (type === LocationTypeEnum.both) {
                parsedData = StateValidator.safeParse({
                    title,
                    description,
                    location: venue?.location?.formatted_address,
                    virtualLink: link,
                });
            }

            if (parsedData.success) {
                updateValidationSlice(true);
                setErrors({});
                return true;
            }

            if (parsedData.error) {
                updateValidationSlice(false);
                setErrors(parsedData?.error?.flatten()?.fieldErrors);
            }

            return false;
        };

        useEffect(() => {
            validate();
        }, [state]);

        return (
            <fieldset ref={ref} className="main-form-event-group">
                <h3>{t('common:overview', 'Overview')}</h3>

                <h4>{t('common:main_image', 'Main Image')}</h4>

                <ThumbnailAndCoverImageUpload
                    coverOnly={singleImageUpload}
                    thumb={state?.thumbnail || getRandomDefaultThumbImage(state?.title ?? '')}
                    setThumb={updateSlice('thumbnail')}
                    cover={state?.coverImage || getRandomDefaultCoverImage(state?.title || 'l')}
                    setCover={updateSlice('coverImage')}
                />

                <TextArea
                    className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px]"
                    countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[12px]"
                    wrapperClassName="relative w-full"
                    defaultClasses=""
                    defaultAutoResizeClasses=""
                    minHeight="128px"
                    autoResize
                    value={state.title}
                    onInput={updateSlice('title')}
                    placeholder="Title *"
                    required
                    error={state?.title?.length > 0 && errors?.title}
                    helperText={`${state?.title.length > 0 && errors?.title ? errors?.title : ''}`}
                    maxCharacter={50}
                />

                <TextArea
                    className="bg-[#f6f7f9] rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px] mt-[5px] !h-[130px] !overflow-auto"
                    countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px] !mb-[12px]"
                    wrapperClassName="relative w-full"
                    defaultClasses=""
                    defaultAutoResizeClasses=""
                    value={state.description}
                    onInput={updateSlice('description')}
                    placeholder="Description"
                    error={state?.description?.length > 0 && errors?.description}
                    helperText={`${
                        state?.description?.length > 0 && errors?.description
                            ? errors?.description
                            : ''
                    }`}
                    multiline
                    autoResize
                    maxCharacter={255}
                />

                <LocationVenueSettings
                    errors={errors}
                    rsvpEnabled={state?.rsvpEnabled}
                    state={state.eventLocationSettings}
                    setState={updateSlice('eventLocationSettings')}
                />
            </fieldset>
        );
    }
);

export default EventGroupOverviewForm;
