import React from 'react';
import { Link } from 'react-router-dom';

import { MessageAuthorThumb } from '@components/search/searchcard/components/index';
import { constructPostDate } from '@components/search/searchcard/components/helpers';

import { Message } from '@shared/welibrary-graphql/types';

const MESSAGE_BASE_URL = '/messages/';

type MessageSearchCardProps = {
    source: Message;
    handleCloseSearch: () => void;
};

const MessageSearchCard: React.FC<MessageSearchCardProps> = ({ source, handleCloseSearch }) => {
    const handleClick = () => handleCloseSearch();

    const messageText = source && `${source.text.substring(0, 50).trim()}...`;
    const user = source?.author;
    const userName = user?.profile?.full_name;
    const userThumb = user?.profile?.picture;
    const messageDate = source?.updated && constructPostDate(source.updated);
    const messageThreadUrl = source?.thread?._id;
    const messageThreadLink = `${MESSAGE_BASE_URL}${messageThreadUrl}`;
    const title = source?.thread.displayName;
    const showSpacer = title?.trim() !== '';
    const renderSpacer = showSpacer ? <span className="dot-spacer"> • </span> : null;
    return (
        <Link
            to={{ pathname: messageThreadLink }}
            onClick={handleClick}
            className="search-result-item search-result-block-item"
        >
            <div className="search-result-thumb-containter">
                <MessageAuthorThumb thumbImage={userThumb} />
            </div>
            <div className="search-result-item-middle">
                <div className="search-result-item-info">
                    <Link to={{ pathname: messageThreadLink }} className="bold">
                        {title}
                    </Link>{' '}
                    {renderSpacer} {messageDate}
                    <br />
                </div>
                <p className="search-result-item-snippet">
                    <span className="bold">{userName}: </span> <span>{messageText}</span>
                </p>
            </div>
        </Link>
    );
};

export default MessageSearchCard;
