import React from 'react';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import {useTranslation} from 'react-i18next';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';
const VoteGalleryPreview = ({ displayTitle, source }) => {
    const { t } = useTranslation();
    const [src, srcSet] = getBackgroundImageSetForUrl(getRandomDefaultUserImage(), 25);

    const promptText = source?.cardPrompt || t(`common:${localNamespace}.share_something`);

    const bgImage = source?.bgImage || getRandomDefaultCoverImage('T');
    const backgroundStyle = {
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url(${bgImage}) 50% 50% / cover`,
    };
    return (
        <div className="we-vote-or-skip-item">
            <div className="vote-booth-container">
                <div className="vote-booth-top">
                    <h4>{displayTitle}</h4>
                </div>
                <div className="vote-booth-prompt">
                    <div style={{ width: '100%' }}>
                        <div className="new-post-container">
                            <div
                                className="new-post-top preview"
                                style={{ paddingBottom: '0px', minHeight: '25px' }}
                            >
                                <StyledBackgroundImage
                                    className="thumbnail new-post"
                                    src={src}
                                    srcSet={srcSet}
                                >
                                    <div className="square-spacer" />
                                </StyledBackgroundImage>
                                <div
                                    className="new-post-field-container w-form"
                                    style={{ display: 'flex' }}
                                >
                                    <span style={{ paddingTop: '5px', paddingLeft: '10px' }}>
                                        {promptText}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="we-vote-or-skip-bg we-mobile-voteitem-topgreyborder"
                    style={backgroundStyle}
                >
                    <div />
                    <div className="content-card-wrapper voting-wrapper">
                        <div className="voting-booth-actions">
                            <div className="vote-booth-heading">
                                <div>Vote</div>
                                <a className="vote-booth-skip-button w-inline-block">
                                    <div>Skip</div>
                                </a>
                            </div>
                            <div className="voting-booth-buttons">
                                <div>
                                    <div className="voting-buttons">
                                        <div className="vote-buttons-container">
                                            <div className="vote-button-container">
                                                <button
                                                    type="button"
                                                    className="vote-button null w-inline-block"
                                                    aria-label="Vote Up"
                                                />
                                            </div>
                                            <div className="vote-button-container">
                                                <button
                                                    type="button"
                                                    className="vote-button downvote false w-inline-block"
                                                    aria-label="Vote Down"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-card-container we-review-and-vote-card">
                            <div className="mc-post-wrapper">
                                <div className="mc-source-post-wrapper">
                                    <div className="mc-source-text-container">
                                        <div className="mc-source-title">
                                            <h4>Card Title</h4>
                                        </div>
                                        <div className="offset-text-bottom">
                                            <span className="short-post-message">
                                                <p>Content will appear here.</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default VoteGalleryPreview;
