import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

import { createContext } from '@core/utilities/helpers/context.helpers';

export const [usePreviousRouteContext, PreviousRouteContextProvider] = createContext<{
    previous: Location | string;
    current: Location | string;
    previousRouteExists: boolean;
}>();

export const PreviousRouteProvider: React.FC = ({ children }) => {
    const location = useLocation();

    // Default to /home if there is no previous route
    // TODO: Is this the correct behavior? Should we let this be null and allow consumers to decide
    // what to do if there is no previous route?
    const [previous, setPrevious] = useState<Location | string>('/home');
    const [current, setCurrent] = useState(location);
    const [previousRouteExists, setPreviousRouteExists] = useState(false);

    const isFirstRender = useRef(true);

    useEffect(() => {
        setPrevious(current);
        setCurrent(location);

        if (!isFirstRender.current) {
            setPreviousRouteExists(true);
        } else {
            isFirstRender.current = false;
        }
    }, [location]);

    return (
        <PreviousRouteContextProvider value={{ previous, current, previousRouteExists }}>
            {children}
        </PreviousRouteContextProvider>
    );
};
