import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useModal from '@components/modals/hooks/useModal';
import { useCreateMessageThreadMutation } from '@shared/welibrary-graphql/messaging/mutations.hook';
import { GET_USER_MESSAGE_THREADS } from '@shared/welibrary-graphql/messaging/queries';
import { NewMessageLoading } from '@components/modals/ModalLoading';
import { useCurrentUser } from '@stores/User';
import { useMessagesActionsContext } from '@components/messaging/MessagesContext';
import { ModalTypes } from '@core/types/Modals';
import ComposeMessageForm from '@components/messaging/ComposeMessageForm';
import useComposeMessageState from '@components/messaging/thread/hooks/useComposeMessageState';
import useAntiFlashingBoolean from '@components/generic/hooks/useAntiFlashingBoolean';
import { uniq } from 'lodash';

const localNamespace = 'imports.wlWeb.ui.components.messaging.messageOverlay';

type CreateComposeMessageFormProps = {
    onClose?: () => void;
    onSubmitSuccess?: () => void;
    className?: string;
};

const CreateComposeMessageForm: React.FC<CreateComposeMessageFormProps> = ({
    onClose,
    onSubmitSuccess,
    className,
}) => {
    const { t } = useTranslation();
    const { newModal, closeAllModals } = useModal({
        desktop: ModalTypes.Center,
        mobile: ModalTypes.Center,
    });
    const [state, setState] = useComposeMessageState();
    const [submitLoading, setSubmitLoading] = useState(false);
      const { currentUser } = useCurrentUser();

    const history = useHistory();

    const { toggleThread } = useMessagesActionsContext();

    const [createMessageThread, { loading }] = useCreateMessageThreadMutation();
    const { participants, title, thumb } = state;
    const enabled =
        participants?.length === 1 || (participants?.length > 1 && title?.trim() !== '');

    const onSubmit = async (e: any) => {
        if (!enabled) return;
        e?.preventDefault();

        if (!participants || participants?.length < 1) return;

        const participantsInThread = participants?.map(contact => contact?.user._id);
        const allParticipants = [...participantsInThread, currentUser?._id];

        if (submitLoading) return;

        setSubmitLoading(true);

        createMessageThread({
            variables: {
                input: {
                    participants: allParticipants,
                    displayName: title,
                    displayIcon: thumb,
                },
            },
        })
            .then(({ data: { createMessageThread } }) => {
                // setMessageContacts([]);
                onSubmitSuccess?.();
                const threadId = createMessageThread._id;
                toggleThread(threadId);
                closeAllModals();
                history?.replace(`/messages/${threadId}`);
                setSubmitLoading(false);
            })
            .finally(() => {
                closeAllModals();
                setSubmitLoading(false);
            });
    };

    const antiFlashingLoading = useAntiFlashingBoolean(submitLoading, {
        minimumFlash: 2000,
        maximumDelay: 700,
    });

    useEffect(() => {
        if (antiFlashingLoading) {
            newModal(
                <NewMessageLoading
                    message={t(`common:${localNamespace}.creating_thread`, 'Creating...')}
                />,
                {
                    widen: false,
                    addShadow: false,
                    hideButton: true,
                    hideDimmer: true,
                    sectionClassName: 'new-message-creation-modal',
                }
            );
        }
    }, [antiFlashingLoading]);

    return (
        <ComposeMessageForm
            className={className}
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            submitLoading={submitLoading || loading}
            onClose={onClose}
            bottomNav
            title={t(`common:${localNamespace}.new_conversation`, 'New Conversation')}
        />
    );
};

export default CreateComposeMessageForm;
