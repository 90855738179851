/* eslint-disable no-nested-ternary */
import React from 'react';

// NOTE: prevent scrolling on main page
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import useHideBodyScroll from '@web/ui/components/generic/hooks//useHideBodyScroll';

// NOTE drag with mouse
import useDrag from '@web/ui/components/generic/hooks/useDrag';
import LeftCaretIcon from '~/wl-web/ui/dsc/svgs/LeftCaretIcon';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

// const onWheel = (apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void => {
//     // NOTE: no good standard way to distinguish touchpad scrolling gestures
//     // but can assume that gesture will affect X axis, mouse scroll only Y axis
//     // of if deltaY too small probably is it touchpad
//     const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

//     if (isTouchpad) {
//         ev.stopPropagation();
//         return;
//     }

//     if (ev.deltaY > 0) {
//         apiObj.scrollNext();
//     } else if (ev.deltaY < 0) {
//         apiObj.scrollPrev();
//     }
// };

const HorizontalScroll: React.FC<{ invert?: boolean }> = ({ children, invert }) => {
    // const [items, setItems] = React.useState(m);
    // const [selected, setSelected] = React.useState<string[]>([]);
    const [position, setPosition] = React.useState(0);

    const { dragStart, dragStop, dragMove } = useDrag();

    const handleDrag =
        ({ scrollContainer }: scrollVisibilityApiType) =>
        (ev: React.MouseEvent) =>
            dragMove(ev, newPos => {
                if (scrollContainer.current) {
                    const currentScroll = scrollContainer.current.scrollLeft;
                    scrollContainer.current.scrollLeft = currentScroll + newPos;
                }
            });

    const onInit = React.useCallback(
        ({ scrollContainer }: scrollVisibilityApiType) => {
            if (scrollContainer.current) {
                scrollContainer.current.scrollLeft = position;
            }
        },
        [position]
    );

    const savePosition = ({ scrollContainer }: scrollVisibilityApiType) =>
        !!scrollContainer.current && setPosition(scrollContainer.current.scrollLeft);

    const { hideScroll, showScroll } = useHideBodyScroll();

    return (
        <div className="horizontal-scroll-menu-container">
            <div onMouseEnter={hideScroll} onMouseLeave={showScroll}>
                <div onMouseLeave={dragStop}>
                    <ScrollMenu
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        LeftArrow={<LeftItem invert={invert} />}
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        RightArrow={<RightItem invert={invert} />}
                        onInit={onInit}
                        onScroll={savePosition}
                        // onWheel={onWheel}
                        onMouseDown={() => ev => dragStart(ev)}
                        onMouseUp={() => dragStop}
                        onMouseMove={handleDrag}
                    >
                        {children}
                    </ScrollMenu>
                </div>
            </div>
        </div>
    );
};

type SideItemProps = {
    invert?: boolean;
};
const LeftItem: React.FC<SideItemProps> = ({ invert }) => {
    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            {invert ? <WhiteLeftArrow /> : <LeftArrow />} <LeftVerticalGrayLine />
        </>
    );
};
const RightItem: React.FC<SideItemProps> = ({ invert }) => {
    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            {invert ? <WhiteRightArrow /> : <RightArrow />} <RightVerticalGrayLine />
        </>
    );
};

const WhiteLeftArrow = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <LeftArrow color="white" className="light" />;
};

const WhiteRightArrow = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <RightArrow color="white" className="light" />;
};

type ArrowProps = {
    color?: string;
    className?: string;
};

export const LeftArrow: React.FC<ArrowProps> = ({ color = '#666666', className = 'dark' }) => {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);

    const _className = ` horizontal-left-arrow ${className}`;
    return (
        <div className={`arrow-wrapper left-arrow-wrapper ${isFirstItemVisible ? 'hidden' : ''}`}>
            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            <Arrow
                className={_className}
                disabled={isFirstItemVisible}
                onClick={() => scrollPrev('smooth')}
            >
                <LeftCaretIcon color={color} />
            </Arrow>
        </div>
    );
};

/* NOTE: I tried condensing this into one component but for some reason this 
broke the scrolling logic used in ScrollMenu, so will leave as is for now */

export const RightArrow: React.FC<ArrowProps> = ({ color = '#666666', className = 'dark' }) => {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    const _className = ` horizontal-right-arrow ${className}`;
    return (
        <div className={`arrow-wrapper right-arrow-wrapper ${isLastItemVisible ? 'hidden' : ''}`}>
            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            <Arrow
                className={_className}
                disabled={isLastItemVisible}
                onClick={() => scrollNext('smooth')}
            >
                {/* transform: scaleX(-1) will flip this to a right caret */}
                <LeftCaretIcon color={color} />
            </Arrow>
        </div>
    );
};

const Arrow = ({
    children,
    disabled,
    onClick,
    className,
}: {
    children: React.ReactNode;
    disabled: boolean;
    onClick: VoidFunction;
    className: string;
}) => {
    return (
        <button
            disabled={disabled}
            className={`horizontal-scroll-menu-arrow ${className}`}
            onClick={onClick}
            type="button"
        >
            {children}
        </button>
    );
};

export const LeftVerticalGrayLine: React.FC = () => {
    const { isFirstItemVisible } = React.useContext(VisibilityContext);

    return <div className={`vertical-gray-line ${isFirstItemVisible ? 'hidden' : ''}`} />;
};

export const RightVerticalGrayLine: React.FC = () => {
    const { isLastItemVisible } = React.useContext(VisibilityContext);

    return <div className={`vertical-gray-line ${isLastItemVisible ? 'hidden' : ''}`} />;
};

export default HorizontalScroll;
