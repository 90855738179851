import React from 'react';

const ConditionalWrapper = ({
    condition,
    Wrapper,
    AlternateWrapper = false,
    children,
    ...props
}) => {
    if (AlternateWrapper)
        return condition ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Wrapper {...props}>{children}</Wrapper>
        ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <AlternateWrapper {...props}>{children}</AlternateWrapper>
        );

    // eslint-disable-next-line react/jsx-props-no-spreading
    return condition ? <Wrapper {...props}>{children}</Wrapper> : children;
};

export default ConditionalWrapper;
