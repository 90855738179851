import React from 'react';

import MobileMenuList from '@components/modals/MobileMenuList';
import X from '@dsc/svgs/FullSizeX';

import useModal from '@components/modals/hooks/useModal';

import { MenuListItem } from '@core/types/Menus';

const getDisplayValue = <T extends string = string>(
    value: T,
    displayValues?: Record<T, string>
) => {
    if (displayValues) return displayValues[value];
    return value;
};

type SelectProps<T extends string = string> = {
    title?: string;
    prompt?: string;
    className?: string;
    titleClassName?: string;
    inputClassName?: string;
    value?: T;
    onChange: (value: T) => void;
    options: T[];
    displayValues?: Record<T, string>;
    clearable?: boolean;
};

const Select = <T extends string = string>({
    title,
    prompt = '',
    className = '',
    titleClassName = '',
    inputClassName = '',
    value,
    onChange,
    options,
    displayValues,
    clearable = false,
}: SelectProps<T>) => {
    const { newModal } = useModal();

    const optionsList: MenuListItem[] = options.map(option => ({
        id: option,
        title: getDisplayValue(option, displayValues),
        onClick: () => onChange(option),
        selected: value === option,
    }));

    return (
        <section className={`dsc-forms-customInputs-select ${className}`}>
            {title && <p className={titleClassName}>{title}</p>}
            <button
                type="button"
                onClick={() =>
                    newModal(<MobileMenuList menuList={optionsList} />, {
                        className: 'no-min-height',
                        widen: true,
                        addShadow: true,
                    })
                }
                className={`main-button ${inputClassName}`}
            >
                <span>{value ? getDisplayValue(value, displayValues) : prompt}</span>
                <span role="presentation" className="caret">
                    ▾
                </span>
            </button>

            {clearable && value && (
                <button className="x-button" type="button" onClick={() => onChange('' as T)}>
                    <X />
                </button>
            )}
        </section>
    );
};

export default Select;
