import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGetVerifiableCredentialsForSubjectDidQuery } from '@shared/welibrary-graphql/quest/queries.hook';
import { useQuestByIdQuery } from '@shared/welibrary-graphql/quest/queries.hook';

import useModal from '@components/modals/hooks/useModal';
import useUniversalWallet from '@web/utilities/hooks/wallet/useUniversalWallet';
import useQuestMapInfo from '@components/quest/hooks/useQuestMapInfo';

import AdventureSelectorCreation from '@components/quest/AdventureSelectorCreation';
import StylizedX from '@dsc/svgs/lego/StylizedX';
import WoodButton from '@dsc/buttons/Lego/WoodButton';
import Button from '@dsc/forms/buttons/Button';
import ResourceIcon from '@components/card/coursecard/ResourceIcon';
import ModalLoading from '@components/modals/ModalLoading';
import Picture from '@components/generic/Picture';

import {
    accruedResourcesToResources,
    getQuestAfterCurrent,
    stringIsResource,
} from '@helpers/rainbowRoad/quest.helpers';
import { getCredentialImageUrl, getCredentialName } from '@helpers/wallet/wallet.helpers';

import { Maybify } from '@core/types/Utilities';
import { QuestCreationType } from '@core/types/Quests';
import { CreationState, StorageType } from '@shared/welibrary-graphql/types';
import { getNodeUrl } from '~/wl-web/utilities/helpers/rainbowRoad/rainbowRoadHelpers';

type AdventureSelectorProps = {
    questId: string;
    questMap?: string;
    creations: QuestCreationType[];
    creationsState?: Array<Maybify<CreationState> | null> | null;
    isComplete?: boolean;
};

const AdventureSelector: React.FC<AdventureSelectorProps> = ({
    questId,
    questMap,
    creations,
    creationsState,
    isComplete = false,
}) => {
    const { closeModal } = useModal();
    const { subjectDidFromWallet } = useUniversalWallet();

    const location = useLocation();

    const [showCreations, setShowCreations] = useState(false);

    const defaultStorageType = StorageType.Ceramic;

    const queryVariables = {
        subjectDid: subjectDidFromWallet!,
        storageTypes: [defaultStorageType, StorageType.Mongodb],
    };

    const { data: credentialData, loading: credentialLoading } =
        useGetVerifiableCredentialsForSubjectDidQuery({
            variables: queryVariables,
            skip: !subjectDidFromWallet,
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
        });

    const { data } = useQuestByIdQuery({
        variables: { _id: questId },
        skip: !isComplete || !questId,
    });
    const questMapInfo = useQuestMapInfo(questMap);

    if (!showCreations && isComplete) {
        const nextQuest = getQuestAfterCurrent(data?.getQuestById, questMapInfo);
        const resources = accruedResourcesToResources(data?.getQuestById?.accruedResources ?? {});

        const questVcName =
            data?.getQuestById?.rainbowRoad?.rainbowRoadSettings?.questSettings?.vcSettings?.name;

        // for now there is only one SSL VC, so assume it is first item in array...
        const vcImageUrl = getCredentialImageUrl(
            credentialData?.getVerifiableCredentialsForSubjectDid?.find(
                credential => getCredentialName(credential?.credentialSubject) === questVcName
            )?.credentialSubject
        );

        const displayResources = Object.entries(resources).map(([resource, quantity]) => {
            if (quantity === 0) return;
            if (!stringIsResource(resource)) return <></>;

            return (
                <ResourceIcon
                    key={resource}
                    type={resource}
                    quantity={quantity}
                    checked={quantity > 0}
                    size="80px"
                    hidePlus
                />
            );
        });

        return (
            <div className="completion-modal-wrapper is-complete">
                <div className="adv-selector-container">
                    <h1 className="adv-selector-title">Congratulations!</h1>
                </div>

                <section className="adv-selector-advs-container">
                    <div className="completion-content">
                        <div className="vc-display">
                            {credentialLoading && !vcImageUrl && <ModalLoading />}
                            {vcImageUrl && (
                                <Picture
                                    disableLink
                                    className="vc-thumb"
                                    url={vcImageUrl}
                                    resolutions={[130, 260, 390]}
                                />
                            )}
                        </div>
                        <h1>{`You completed the quest${
                            nextQuest ? ` and unlocked ${nextQuest.title ?? ''}` : ''
                        }!`}</h1>

                        <div className="resources-display">{displayResources}</div>

                        {nextQuest && (
                            <Link
                                to={getNodeUrl(nextQuest, location.pathname, questMap)}
                                onClick={closeModal}
                                className="next-quest-link"
                            >{`Start ${nextQuest.title ?? ''}`}</Link>
                        )}
                    </div>
                </section>

                <div className="adv-selector-btn-wrap">
                    <WoodButton className="adv-selector-btn" onClick={() => closeModal()}>
                        <StylizedX className="adv-selector-btn-icon" />
                    </WoodButton>

                    <Button
                        type="medium"
                        className="completion bottom-right-btn"
                        onClick={() => setShowCreations(true)}
                    >
                        View Creations
                    </Button>
                </div>
            </div>
        );
    }

    const adventures = creations.map(creation => (
        <AdventureSelectorCreation
            key={creation._id}
            questId={questId}
            creation={creation}
            creationsState={creationsState}
        />
    ));

    return (
        <div>
            <div className="adv-selector-container">
                <h1 className="adv-selector-title">
                    What do you
                    <br /> want to build?
                </h1>
            </div>

            <section className="adv-selector-advs-container">
                <div className="adv-selector-advs-wrap">{adventures}</div>
            </section>

            <div className="adv-selector-btn-wrap">
                <WoodButton className="adv-selector-btn" onClick={() => closeModal()}>
                    <StylizedX className="adv-selector-btn-icon" />
                </WoodButton>
            </div>
        </div>
    );
};

export default AdventureSelector;
