import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import liveUpdateStore from '@stores/LiveUpdate';

import ProgressBar from '@web/ui/components/generic/loading/ProgressBar';
import LottieAnimation from '@components/generic/loading/lotties/circle-spinner.json';
import { useTranslation } from 'react-i18next';
import RocketAnimation from '@web/ui/components/generic/loading/lotties/rocket.json';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useConfirmation from '@components/generic/hooks/useConfirmation';
import X from '@web/ui/dsc/svgs/FullSizeX';
import ErrorBoundary from '../generic/errors/ErrorBoundary';
import LottieLoading from '../generic/loading/LottieLoading';

const localNamespace = 'imports.wlWeb.ui.components.manualLiveUpdate';

type ManualLiveUpdateProps = {};

export const ManualLiveUpdate: React.FC<ManualLiveUpdateProps> = () => {
    const { t } = useTranslation();
    const downloadProgress = liveUpdateStore.useTracked.downloadProgress();
    const extractProgress = liveUpdateStore.useTracked.extractProgress();
    const liveUpdateStatus = liveUpdateStore.useTracked.status();
    const liveUpdateError = liveUpdateStore.useTracked.errorMessage();

    const updateMessage = t(
        `common:${localNamespace}.update_available_message_default`,
        'An update is available! Please wait while updates are downloaded and installed'
    );

    const downloadMessage = t(`common:${localNamespace}.downloading_update`, 'Downloading updates');

    const extractMessage = t(
        `common:${localNamespace}.extract_update`,
        'Installing and extracting updates'
    );

    const completedMessage = t(
        `common:${localNamespace}.completed_update`,
        'Update Complete. Restarting Application...'
    );

    let liveUpdateStatusText = '';
    if (liveUpdateStatus === 'DOWNLOAD') {
        liveUpdateStatusText = `${downloadMessage}...${downloadProgress}%`;
    }

    if (liveUpdateStatus === 'EXTRACT') {
        liveUpdateStatusText = `${extractMessage}...${extractProgress}%`;
    }

    if (liveUpdateStatus === 'COMPLETE') {
        liveUpdateStatusText = completedMessage;
    }

    const loaderStyle = {
        opacity: '0.5',
    };

    return (
        <ErrorBoundary>
            <section className="manual-live-update p-[20px] min-h-[200px]">
                <strong>{updateMessage}.</strong>
                <div className="my-[20px]">{liveUpdateStatus && <p>{liveUpdateStatusText}</p>}</div>
                {liveUpdateError && (
                    <strong className="text-red-400 my-[20px]">{liveUpdateError}</strong>
                )}
                {typeof downloadProgress === 'number' &&
                    liveUpdateStatus === 'DOWNLOAD' &&
                    downloadProgress > 2 && (
                        <div className="update-progress-bar my-[20px]">
                            <ProgressBar completed={downloadProgress} />
                        </div>
                    )}
                {typeof extractProgress === 'number' &&
                    liveUpdateStatus === 'EXTRACT' &&
                    extractProgress > 2 && (
                        <div className="update-progress-bar my-[20px]">
                            <ProgressBar completed={extractProgress} />
                        </div>
                    )}
                {liveUpdateStatus === 'IDLE' && (
                    <div className="flex w-full items-center justify-center min-h-[80px]">
                        <LottieLoading
                            customStyle={loaderStyle}
                            height={80}
                            width={80}
                            lottieData={LottieAnimation}
                            isPaused={false}
                        />
                    </div>
                )}

                {liveUpdateStatus === 'COMPLETE' && (
                    <div className="flex w-full items-center justify-center min-h-[80px]">
                        <LottieLoading
                            customStyle={loaderStyle}
                            height={80}
                            width={80}
                            lottieData={RocketAnimation}
                            isPaused={false}
                        />
                    </div>
                )}
            </section>
        </ErrorBoundary>
    );
};

export const ManualLiveUpdateModal: React.FC<ManualLiveUpdateProps> = () => {
    const confirm = useConfirmation({ widen: true });
    const showModal = liveUpdateStore.useTracked.showModal();

    const handleCloseModal = () => {
        confirm({
            text: 'Updates will be downloaded and installed in the background.',
            onConfirm: () => setTimeout(() => liveUpdateStore.set.showModal(false), 350),
        });
    };

    if (showModal) {
        return createPortal(
            <aside
                id="center-modal"
                className="flex items-center justify-center h-full w-full no-top-padding white-notch open relative"
            >
                <button
                    className="center-modal-dimmer z-0"
                    type="button"
                    onClick={handleCloseModal}
                    aria-label="modal-dimmer"
                    aria-hidden
                />
                <div className=" bg-white rounded-[20px] m-[20px] max-w-[300px] relative z-10 pt-[20px]">
                    <ManualLiveUpdate />
                    <button
                        type="button"
                        className="center-modal-x absolute top-[13px] right-[13px]"
                        onClick={handleCloseModal}
                    >
                        <X strokeWidth="4" size={12} />
                    </button>
                </div>
            </aside>,

            document.getElementById('modal-mid-root') as HTMLElement
        );
    }
    return <></>;
};

// Hook used for triggering live update modal

export const useManualLiveUpdate = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const flags = useFlags();

    const showLiveUpdateModal = () => {
        liveUpdateStore.set.showModal(true);
    };

    const updateAvailable = liveUpdateStore.useTracked.updateAvailable();

    if (updateAvailable && !modalOpen && flags?.manualLiveUpdateModal) {
        setModalOpen(true);
        showLiveUpdateModal();
    }
};
