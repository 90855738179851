import { Product } from '@shared/welibrary-graphql/types';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';

export enum Tabs {
    Merchant = 'merchant',
    Tickets = 'tickets',
}

export type ProductState = {
    name: string;
    description: string;
    price: number | null;
    currency: string;
    type: string;
    fulfillment: { groupId: string; role: string };
    coupons: Coupon[];
};

export type Coupon = {
    name: string;
    code: string;
    percentage: number | null;
    type: string;
};

export const getFreeProducts = (products: Product[] = []): Product[] => {
    return products?.filter(product => product?.type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP);
};

export const getPaidProducts = (products: Product[] = []): Product[] => {
    return products?.filter(product => product?.type !== PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP);
};
