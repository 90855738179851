import React from 'react';

import { useCurrentUser } from '@stores/User';

import { useQuery } from '@apollo/client';
import { GET_SINGLE_CARD_BY_ID } from '@shared/welibrary-graphql/content_card/queries';

import ContentCard from '@web/ui/components/card/ContentCard';

import { ENGAGE_COMMENT, ENGAGE_FOLLOW } from '@core/utilities/constants/engagement_types';

import { CARD_POSTBOX } from '@core/utilities/constants/card_types.js';
import { sortCardsByPinned } from '@helpers/contentCard/contentCard.helpers';

import getLogger from '@core/logger';

const logger = getLogger(module);

const Newsfeed = React.memo(function HomeNewsFeedMemo({
    list,
    activeQuery,
    showReferenceHeader,
    superLayer,
    renderPaginationDiv, // This div needs to have a key since it's displayed in a list
    groupShelfId,
    postsArePinnable,
}) {
    const { data: parentData } = useQuery(GET_SINGLE_CARD_BY_ID, {
        variables: {
            _id: groupShelfId,
        },
        skip: !groupShelfId,
    });

    const { currentUser } = useCurrentUser();

    if (!list) return <></>;

    const parentCard = parentData?.card;
    const pinnedCardIds = parentCard?.pinnedCardIds;
    const listSortedByPinnedPostsFirst = sortCardsByPinned(list, pinnedCardIds);

    let cardNumber = 0;
    let newsFeedList = listSortedByPinnedPostsFirst.map((item, index) => {
        // TODO: Generalize this.
        // This sets the default 'chapter settings' and 'vote settings' for content cards, like ones posted by a user from the newsfeed
        // But, if this card has a reference header, it should use the vote and library settings from where it came from.
        let chapterSettings = { engagementTypes: [ENGAGE_COMMENT, ENGAGE_FOLLOW] };
        let voteSettings = {
            RELEVANCE: { active: false },
            QUALITY: { active: false },
            SENTIMENT: { active: false },
        };
        if (item.reference) {
            const { chapterSource } = item.reference;
            if (chapterSource) {
                const { library_settings, vote_settings } = chapterSource;
                if (library_settings) chapterSettings = library_settings;
                if (vote_settings) voteSettings = vote_settings;
            }
        }

        let topGreyBorder = true;

        // Get the previous card. If the previous card was a postbox, disable the grey border.
        if (cardNumber >= 1) {
            if (list.length > cardNumber - 1) {
                const prevCard = list[cardNumber - 1];
                if (prevCard.type === CARD_POSTBOX) topGreyBorder = false;
            }
        }

        cardNumber += 1;
        return (
            <ContentCard
                parentCard={parentCard}
                pinnable={postsArePinnable}
                currentUser={currentUser}
                showReferenceHeader={showReferenceHeader}
                key={item._id}
                idx={index}
                idxTotal={list?.length}
                card={item}
                chapterSettings={chapterSettings}
                voteSettings={voteSettings}
                activeQuery={activeQuery}
                noDate
                topGreyBorder={topGreyBorder}
                superLayer={superLayer}
                extraClasses="home-newsfeed"
            />
        );
    });

    if (renderPaginationDiv) {
        // Place the pagination div 3/4 of the way into the content cards
        const paginationDiv = renderPaginationDiv();
        try {
            const PAGINATION_DEPTH = 1;
            newsFeedList = [
                ...newsFeedList.slice(0, newsFeedList.length - PAGINATION_DEPTH),
                paginationDiv,
                ...newsFeedList.slice(newsFeedList.length - PAGINATION_DEPTH),
            ];
        } catch (e) {
            logger.error(e);
        }
    }

    return <>{newsFeedList}</>;
});

export default Newsfeed;
