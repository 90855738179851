import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import produce from 'immer';

import { useCurrentUser } from '@stores/User';
import { useMessagesActionsContext } from '@web/ui/components/messaging/MessagesContext';
import useModal from '@web/ui/components/modals/hooks/useModal';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.messaging.message';

// This and the normal message block should be refactored to be the same display component at some point
const ContactBlock = React.forwardRef(function ContactBlock({ card, data }, ref) {
    const { t } = useTranslation();

    const { currentUser } = useCurrentUser();

    const {
        _id,
        profile: { picture } = {},
        text,
        linkText,
        link,
        participants,
        displayName,
        __typename,
        displayIcon,
    } = data || {};

    const { setAttachment, setDrafts } = useMessagesActionsContext();
    const { closeAllModals } = useModal();

    const handleClick = () => {
        closeAllModals();

        const draftsObj = { [_id]: { attachment: { type: 'content-card', card }, message: '' } };
        setDrafts(draftsObj);

        setAttachment({
            type: 'content-card',
            card: produce(card, draft => {
                draft.viewType = 'listview';
            }),
        });
    };

    let displayMostRecentMessage = t(`common:${localNamespace}.new_conversation`);

    const otherParticipants = participants.filter(
        participant => participant.user._id !== currentUser._id
    );

    const _displayname =
        otherParticipants.length === 1 && !displayName
            ? otherParticipants[0].user.profile.full_name
            : displayName;

    let _displayPicture =
        otherParticipants.length === 1 && !displayName
            ? otherParticipants[0].user.profile.picture
            : displayIcon || getRandomDefaultCoverImage(_id);

    if (__typename === 'MessageThread' && data?.group?.profile?.picture) {
        _displayPicture = data.group.profile.picture;
    }

    if (displayMostRecentMessage) {
        try {
            // Strip html tags
            displayMostRecentMessage = displayMostRecentMessage?.replace(/<[^>]*>/g, '')?.trim();
        } catch (e) {
            logger.error(e);
        }
    }
    if (displayMostRecentMessage.length > 50) {
        displayMostRecentMessage = `${displayMostRecentMessage.substr(0, 50).trim()}...`;
    }

    const thumbImage = _displayPicture || picture || getRandomDefaultUserImage();

    const [src, srcSet] = getBackgroundImageSetForUrl(thumbImage || getRandomDefaultUserImage());
    return (
        <div ref={ref} className="contact-block-item ">
            <Link
                to={`/messages/${_id}`}
                className="inbox-item-block w-inline-block"
                id={_id}
                onClick={handleClick}
            >
                <StyledBackgroundImage
                    className="thumbnail larger-thumbnail"
                    style={{ backgroundSize: 'cover' }}
                    src={src}
                    srcSet={srcSet}
                />
                <div className="inbox-item-content">
                    <div className="inbox-item-name">{_displayname}</div>
                    {link && linkText ? (
                        <div>
                            {`${text} `}
                            <Link to={link}>{linkText}</Link>
                        </div>
                    ) : null}
                </div>
            </Link>
        </div>
    );
});

export default ContactBlock;
