import { throttle } from 'lodash';

import { Log } from './types';

const ENDPOINT = 'https://logs.learnbank.io';

let logQ: Log[] = [];

const sendLogs = () => {
    try {
        fetch(ENDPOINT, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({ logs: logQ }),
        })
            .then(response => {
                if (response.status !== 200) {
                    // eslint-disable-next-line no-restricted-syntax
                    console.error('Error sending log to AWS!', response.statusText);
                }
            })
            .catch(error => {
                // eslint-disable-next-line no-restricted-syntax
                console.error('Error sending log to AWS!', error);
            });

        logQ = [];
    } catch (error) {
        console.warn('Error sending logs!', error, logQ);
        logQ = [];
    }
};

const throttledSendLogs = throttle(sendLogs, 2000);

export const sendLogToLambda = (log: Log) => {
    logQ.push(log);
    throttledSendLogs();
};

export default sendLogToLambda;
