import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SMALL_HEADER_HEIGHT = '80px';
const LARGE_HEADER_HEIGHT = '100px';

const localNamespace = 'imports.wlWeb.ui.components.generic.editScreen.darkAdminHeader';

export default ({
    handleSubmit,
    handleClose,
    handleChange,
    inputTarget,
    inputValue,
    disabled,
    disableSubmit,
}) => {
    const { t } = useTranslation();

    const [smallHeader, setSmallHeader] = useState(true);
    const smallHeaderStyle = {
        transform:
            'translate3d(0px, 0px, 0px) scale3d(0.75, 0.75, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
        transformStyle: 'preserve-3d',
    };
    const largeHeaderStyle = {
        transform:
            'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
        transformStyle: 'preserve-3d',
    };

    return (
        <div
            className="edit-screen-header-container"
            style={{ height: smallHeader ? SMALL_HEADER_HEIGHT : LARGE_HEADER_HEIGHT }}
        >
            <div onClick={handleClose} className="close-edit-screen-button w-inline-block" />
            <div className="edit-sreen-title-container">
                <div className="edit-screen-form-block w-form">
                    <form id="email-form" name="email-form">
                        <input
                            onChange={handleChange}
                            type="text"
                            className="edit-screen-title-field w-input"
                            value={inputValue}
                            maxLength="256"
                            name={inputTarget}
                            placeholder={t(`common:${localNamespace}.input_placeholder`)}
                            id={inputTarget}
                            style={smallHeader ? smallHeaderStyle : largeHeaderStyle}
                            disabled={disabled}
                        />
                    </form>
                </div>
            </div>
            <button
                type="button"
                aria-label="Save"
                onClick={handleSubmit}
                className="approve-edit-screen-button w-inline-block"
                disabled={disableSubmit}
            />
        </div>
    );
};
