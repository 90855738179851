import { AvatarValidator } from '@components/profile/Lego/Avatars';

import { AvatarCreatorState } from '@core/types/Avatars';
import { Avatar } from '@shared/welibrary-graphql/types';
import { getAvatar } from '@components/profile/Lego/avatarHelpers';

export const getUserAvatar = (
    avatar?: Avatar | null,
    randomize: boolean = false
): AvatarCreatorState => {
    const parsedAvatar = AvatarValidator.safeParse(avatar);

    if (parsedAvatar.success) {
        return parsedAvatar.data;
    }

    // on initial ephemeral account load
    // randomize the avatar
    // anytime after use the default avatar -> speedy
    const initialAvatar = randomize
        ? getAvatar(undefined, randomize)
        : getAvatar(AvatarValidator.shape.avatar._def.defaultValue(), false);

    return {
        avatar: initialAvatar,
        skinTone: AvatarValidator.shape.skinTone._def.defaultValue(),
    };
};

export default getUserAvatar;
