import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { awsMetricPublisher } from '@welibraryos/metrics';

import ExternalLink from '@components/generic/ExternalLink';

import {
    getDocumentPreviewUrl,
    getDocumentDownloadUrl,
} from '@helpers/contentCard/documentItem.helpers';
import { getFileType, fileTypeSupportsPreview } from '@helpers/images/filestack.helpers';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.card.mediaCard.bodyItems.documentItem';

type DocumentItemButtonsProps = {
    fileUrl: string;
    baseMetricStringName?: string; // allows parent to pass the start of a string to apply to multiple metric names
};

const DocumentItemButtons: React.FC<DocumentItemButtonsProps> = ({
    fileUrl,
    baseMetricStringName,
}) => {
    const { t } = useTranslation();

    const [fileType, setFileType] = useState('');
    getFileType(fileUrl).then(fileTypeMetadata => setFileType(fileTypeMetadata));
    const previewSupported = fileTypeSupportsPreview(fileType);

    const previewUrl = getDocumentPreviewUrl(fileUrl);
    const downloadUrl = getDocumentDownloadUrl(fileUrl);

    const handleViewMetricOnClick = () => {
        // log metric with unique dimension if baseMetricStringName is provided, otherwise do nothing
        if (baseMetricStringName) {
            awsMetricPublisher.publishCount(baseMetricStringName, 1, [
                { Name: 'media', Value: 'view-document' },
            ]);
        } else {
            // Log missing prop
            awsMetricPublisher.publishCount('warning', 1, [
                { Name: 'missing-prop', Value: 'baseMetricStringName-handleViewMetricOnClick' },
            ]);
            logger.warn(
                'Unable to log view-document click metric due to missing baseMetricStringName prop'
            );
        }
    };

    const handleDownloadMetricOnClick = () => {
        // log metric with unique dimension if baseMetricStringName is provided, otherwise do nothing
        if (baseMetricStringName) {
            awsMetricPublisher.publishCount(baseMetricStringName, 1, [
                { Name: 'media', Value: 'download-document' },
            ]);
        } else {
            // Log missing prop
            awsMetricPublisher.publishCount('warning', 1, [
                { Name: 'missing-prop', Value: 'baseMetricStringName-handleDownloadMetricOnClick' },
            ]);
            logger.warn(
                'Unable to log download-document click metric due to missing baseMetricStringName prop'
            );
        }
    };

    return (
        <>
            {previewSupported && (
                <div className="w-inline-block">
                    <ExternalLink
                        to={previewUrl}
                        nativeTarget="_system"
                        className="document-icon-button"
                        whiteColor
                        onClick={handleViewMetricOnClick}
                    >
                        <div className="mc-view-icon" />
                        {t(`common:${localNamespace}.view`)}
                    </ExternalLink>
                </div>
            )}
            <div className="w-inline-block">
                <ExternalLink
                    to={downloadUrl}
                    nativeTarget="_system"
                    className="document-icon-button"
                    whiteColor
                    onClick={handleDownloadMetricOnClick}
                >
                    <div
                        className={`mc-download-icon ${previewSupported ? '' : 'no-left-margin'}`}
                    />
                    {t(`common:${localNamespace}.download`, 'Download')}
                </ExternalLink>
            </div>
        </>
    );
};

export default DocumentItemButtons;
