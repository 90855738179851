import React from 'react';

import StylizedCheck from '@dsc/svgs/lego/StylizedCheck';

import { filterMaybes } from '@helpers/types/type.helpers';

import { Maybify } from '@core/types/Utilities';
import { SurveyQuestion } from '@shared/welibrary-graphql/types';

type SurveyMultipleChoiceProps = {
    state: string;
    setState: (value: string) => void;
    question: Maybify<SurveyQuestion>;
    className?: string;
    disabled?: boolean;
    viewOnly?: boolean;
};

const SurveyMultipleChoice: React.FC<SurveyMultipleChoiceProps> = ({
    state,
    setState,
    question,
    className = '',
    disabled = false,
    viewOnly = false,
}) => {
    if (!question.answers || question.answers.length === 0) return <></>;

    return (
        <section className={`survey-question-multiple-choice ${className}`}>
            {!viewOnly && (
                <>
                    <p>{question.prompt}</p>

                    <ol>
                        {filterMaybes(question.answers).map(answer => (
                            <li key={answer}>
                                <button
                                    type="button"
                                    onClick={() => setState(state === answer ? '' : answer)}
                                    disabled={disabled}
                                >
                                    <section>
                                        <figure
                                            className={`check ${state === answer ? 'checked' : 'unchecked'
                                                }`}
                                        >
                                            <StylizedCheck strokeWidth={3} />
                                        </figure>
                                        <span>{answer}</span>
                                    </section>
                                </button>
                            </li>
                        ))}
                    </ol>
                </>
            )}
            {viewOnly && <p>{state}</p>}
        </section>
    );
};

export default SurveyMultipleChoice;
