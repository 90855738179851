import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';

import dialogStore from '@stores/Dialog';

import useModal from '@components/modals/hooks/useModal';
import useQuest from '@components/quest/hooks/useQuest';
import useQuestMapInfo from '@components/quest/hooks/useQuestMapInfo';
import useCancelableTimer from '@components/generic/hooks/useCancelableTimer';
import useScreenWidth from '@components/generic/hooks/useScreenWidth';

import RainbowWithImage from '@components/rainbowroad/RainbowWithImage';
import RRHeaderBottomMask from '@components/rainbowroad/RRHeaderBottomMask';
import QuestActionButton from '@components/quest/QuestActionButton';
import QuestNode from '@components/quest/QuestNode';
import QuestDialog from '@components/quest/QuestDialog';
import Picture from '@components/generic/Picture';
import LegoFullScreenLoader from '@components/lego/loading/LegoFullScreenLoader';
import LegoPrefetches from '@components/lego/LegoPrefetches';
import LottieLoading from '@components/generic/loading/LottieLoading';
import FireworksAnimation from '@components/generic/loading/lotties/fireworks.json';
import LottieAnimation from '@components/generic/loading/lotties/particles.json';
import CreationContainer from '@components/lego/creation/creationpage/CreationContainer';
import MapNavIcon from '@dsc/svgs/lego/MapNavIcon';

import {
    getRandomDefaultThumbImage,
    getRandomDefaultCoverImage,
} from '@core/utilities/constants/defaults';

import { config } from '@config/getConfig';

import { ModalTypes } from '@core/types/Modals';

const fireworksBgStyle = {
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: 'auto',
    transform: 'translateY(-160px)',
    width: '100%',
    opacity: '1',
};

const localNamespace = 'imports.wlWeb.ui.pages.rainbowRoad';

const particleBgStyle = {
    opacity: '1',
    transform: 'scale(0.55)',
};

type QuestPageProps = {
    questUrl?: string;
};

const QuestPage: React.FC<QuestPageProps> = ({ questUrl }) => {
    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();
    const { questMap, url: paramUrl } = useParams<{ questMap?: string; url: string }>();
    const params = qs.parse(window.location.search);

    const url = questUrl || paramUrl;

    const isDialogRead = dialogStore.use.isDialogRead();

    const [showDialog, setShowDialog] = useState(true);
    const [showCreation, setShowCreation] = useState(true);

    const { newModal, closeModal } = useModal();
    const timer = useCancelableTimer();

    const quest = useQuest(url);
    const questMapInfo = useQuestMapInfo(questMap);

    const showNextCreation = () => {
        if (quest && quest.questId && typeof params.creation === 'string') {
            history.push({ search: qs.stringify({ ...params, creation: undefined }) });
            newModal(
                <CreationContainer
                    cardUrl={typeof params.creation === 'string' ? params.creation : ''}
                    questId={quest.questId}
                    returnUrl={location.pathname}
                    goBack={closeModal}
                />,
                { className: 'full-height' },
                { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
            );
        }
    };

    const showQuestDialog = () => {
        if (quest && quest.activeDialog) {
            newModal(
                <QuestDialog onComplete={showNextCreation} onClose={showNextCreation} />,
                { className: 'quest-dialog' },
                { mobile: ModalTypes.Freeform, desktop: ModalTypes.Freeform }
            );
        }
    };

    useEffect(() => {
        if (showCreation && quest && quest.questId && !quest.activeDialog) {
            timer.start(showNextCreation, 300);
            setShowCreation(false);
        }
    }, [params.creation, quest && quest.questId, quest && quest.activeDialog]);

    useEffect(() => {
        if (!isDialogRead && showDialog && quest && quest.activeDialog) {
            timer.start(showQuestDialog, 300);
            setShowDialog(false);
        }
    }, [quest && quest.activeDialog, isDialogRead]);

    // This is used to prevent layout thrashing going from course -> quest nodes view

    const innerWidth = window?.innerWidth > 600 ? 600 : window?.innerWidth;
    let minHeight = innerWidth * 0.6;

    const windowWidth = useScreenWidth();
    const width = Math.min(windowWidth, 600);
    minHeight = width * 0.6;

    const headerStyle = {
        minHeight: `${minHeight}px`,
    };

    if (!quest) {
        return (
            <main className="rainbow-road-loading">
                <LegoFullScreenLoader />
            </main>
        );
    }

    const {
        rr: { author, media, title, rainbowRoadSettings },
        questId,
        activeDialog,
        creations,
        creationsState,
        cssVars,
        isComplete,
        nodes,
    } = quest;

    const picture = media?.icon || getRandomDefaultThumbImage(title ?? '');

    const headerForegroundImage = typeof rainbowRoadSettings?.header.image === 'string';
    const headerBackgroundImage = typeof rainbowRoadSettings?.header.backgroundImage === 'string';
    const questEndImage = rainbowRoadSettings?.questSettings?.questEndImage;

    const nodeComponents =
        nodes?.map((node, index) => (
            <QuestNode
                node={node}
                key={index}
                settings={rainbowRoadSettings?.nodes}
                creationsState={creationsState}
                questId={questId}
                questMapId={questMap}
            />
        )) ?? [];

    return (
        <main
            className={`rainbow-road ${rainbowRoadSettings?.font ?? 'default'}-font ${headerForegroundImage ? 'header-img-set' : ''
                } ssl-questpage`}
            style={cssVars}
        >
            <LegoPrefetches creationsState={creationsState ?? []} creations={creations ?? []} />
            <header style={headerStyle}>
                <section>
                    {rainbowRoadSettings?.header.showTopInfo !== false && (
                        <>
                            <RainbowWithImage url={picture} />
                            <h3>{t(`common:${localNamespace}.rainbow_road`)}</h3>
                            {author?.profile?.full_name && (
                                <h4>{`by ${author.profile.full_name}`}</h4>
                            )}
                        </>
                    )}
                </section>

                {headerForegroundImage ? (
                    <>
                        {headerBackgroundImage && (
                            <Picture
                                url={rainbowRoadSettings?.header.backgroundImage!}
                                className="header-background-image sky"
                                disableLink
                            />
                        )}
                        <div role="presentation" className="header-background-image">
                            {isComplete && (
                                <LottieLoading
                                    lottieData={FireworksAnimation}
                                    width="100%"
                                    height="400px"
                                    isPaused={false}
                                    aspectRatio="xMidYMid meet"
                                    customStyle={fireworksBgStyle}
                                />
                            )}
                        </div>
                        <Picture
                            url={
                                rainbowRoadSettings?.header.image ||
                                getRandomDefaultCoverImage(title ?? '')
                            }
                            className="header-bg"
                            disableLink
                        />

                        {questEndImage && (
                            <Picture
                                url={questEndImage}
                                className={`header-bg quest-end ${isComplete}`}
                                disableLink
                            />
                        )}
                        <div className="particle-container-bg">
                            {!isComplete && (
                                <LottieLoading
                                    lottieData={LottieAnimation}
                                    width="100%"
                                    height="100%"
                                    customStyle={particleBgStyle}
                                    isPaused={false}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <div role="presentation" className="mask">
                        <RRHeaderBottomMask className="rr-header-bottom-mask" />
                    </div>
                )}

                <QuestActionButton
                    title={title}
                    settings={rainbowRoadSettings?.nodes}
                    className={headerForegroundImage ? 'move-up header-node' : ''}
                    questId={questId}
                    questMap={questMap}
                    creations={creations}
                    creationsState={creationsState}
                    isComplete={isComplete ?? false}
                />
            </header>

            {(questMapInfo?.url || activeDialog) && (
                <aside className="quest-buttons">
                    {questMapInfo?.url && (
                        <button
                            type="button"
                            onClick={() => {
                                newModal(<QuestPage questUrl={questMapInfo.url ?? ''} />, {
                                    className:
                                        'no-top-padding no-bottom-padding no-notch full-height',
                                    hideButton: true,
                                    customCloseButton: true,
                                });
                            }}
                            className={`quest-map-button ${config.public.secondaryNavigation?.items?.mobile?.includes(
                                'myQuests'
                            ) && questMapInfo.url === config.public.main_quest_map
                                    ? 'desktop-only'
                                    : ''
                                }`}
                        >
                            <MapNavIcon />
                        </button>
                    )}

                    {activeDialog && (
                        <button
                            type="button"
                            onClick={showQuestDialog}
                            className="quest-dialog-button"
                        >
                            <Picture
                                url={
                                    activeDialog.people[activeDialog.dialogs[0].person]?.image ?? ''
                                }
                                disableLink
                                resolutions={[38, 76, 114, 64, 128, 192]}
                            />
                        </button>
                    )}
                </aside>
            )}

            <section>{nodeComponents}</section>
        </main>
    );
};

export default QuestPage;
