import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import useSellTicketsAtDoor from '../hooks/useSellTicketsAtDoor';

import SelectTicketsTicketItem from './SelectTicketsTicketItem';
import SellTicketsFooter from './SellTicketsFooter';

import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';
import { Group } from '@shared/welibrary-graphql/types';

type SelectTicketsProps = {
    group: Group;
};

const SelectTickets: React.FC<SelectTicketsProps> = ({ group }) => {
    const { t } = useTranslation();

    const { shoppingCartProducts, isEventFree } = useSellTicketsAtDoor(group);

    const availableProducts = group?.products.filter(p => {
        return isEventFree ? p.price === 0 : p.price !== 0;
    });

    const shouldDisableNextStep = () => {
        if (shoppingCartProducts?.length === 0) return true;

        const hasFreeEventTicket = shoppingCartProducts?.find(({ product }) => {
            return product?.type === PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP;
        });

        const hasPaidEventTicket = shoppingCartProducts?.find(({ product }) => {
            return product?.type === PRODUCT_TYPES?.GROUP_MEMBERSHIP;
        });

        if (isEventFree && !hasFreeEventTicket) return true;
        if (!isEventFree && !hasPaidEventTicket) return true;

        return false;
    };

    return (
        <section className="flex h-full flex-col">
            <h2 className="mb-[20px] mt-0 text-[24px] text-black">
                {t(`common:global.select_tickets`, 'Select Tickets')}
            </h2>

            <ul className="my-[3px] flex w-full grow flex-col gap-[10px] pb-[20px] pl-0">
                {availableProducts?.map(product => {
                    return (
                        <SelectTicketsTicketItem
                            key={product?._id}
                            product={product}
                            group={group}
                        />
                    );
                })}
            </ul>

            <SellTicketsFooter group={group} disableNext={shouldDisableNextStep()} />
        </section>
    );
};

export default SelectTickets;
