import React, { useEffect, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_USERS_IN_SAME_SILO } from '@shared/welibrary-graphql/user/queries';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
} from '@core/utilities/constants/defaults';
import {
    getSocialActionDropdownItems,
    isUserSociallyEngaged,
    SOCIAL_ACTIONS,
} from '@core/utilities/constants/user';
import { getProductInstancesAndFrequency } from '@components/group/groupdashboard/GroupMembers/groupMembersHelpers';

import { FLAG_USER, UPDATE_USER_CONNECTION } from '@shared/welibrary-graphql/user/mutations';
import { CREATE_MESSAGE_THREAD } from '@shared/welibrary-graphql/messaging/mutations';
import { useMutation } from '@apollo/client';

import QuickProfileView from '@web/ui/components/profile/QuickProfileView/QuickProfileView';
import DropdownController from '@web/ui/components/generic/dropdownmenu/DropdownController';
import Picture from '@web/ui/components/generic/Picture';
import UserConnected from '@web/ui/dsc/svgs/UserConnected';
import UserPending from '@web/ui/dsc/svgs/UserPending';
import UserAdd from '@web/ui/dsc/svgs/UserAdd';

import useUserActions from '@web/ui/components/profile/useUserActions';
import useModal from '@web/ui/components/modals/hooks/useModal';
import { SidebarActionsContext } from '@components/generic/context/SidebarContext';

import { SIGNUP_FIELD_TYPE_ORGANIZATION } from '@core/utilities/constants/library_settings';

const localNamespace = 'imports.wlWeb.ui.components.community';

const GroupMemberItem = React.forwardRef(function GroupMemberItem(
    {
        group,
        source,
        showEmail = false,
        connected,
        openQuickMenu,
        getExtraQuickMenuOptions,
        loggedInUser,
        customClass,
    },
    ref
) {
    const { t } = useTranslation();
    const { hideSidebar } = useContext(SidebarActionsContext);

    const [updateConnection] = useMutation(UPDATE_USER_CONNECTION);
    const [flagUser] = useMutation(FLAG_USER);

    const [createMessageThread] = useMutation(CREATE_MESSAGE_THREAD);
    const node = useRef();
    const { newModal, closeAllModals } = useModal();
    const { handleConnectClick } = useUserActions(source?.__typename === 'User' ? source : null);

    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dropdownOpen, setOpen] = useState(false);

    const handleClick = e => {
        if (node) {
            if (node.current) {
                if (node.current.contains(e.target)) {
                    // Click is inside dropdown
                    return;
                }
            }
        }
        setOpen(false);
    };
    const {data, loading, error} = useQuery(GET_USERS_IN_SAME_SILO, {variables: { otherUserId: loggedInUser._id}})
    const isUserInSameSilo = data?.usersInSameSilo;

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, []);

    let titleSection;
    let headlineSection;
    let otherSection;

    let _followIcon;
    let _title;
    let _description;
    let _thumbImage;
    let _linkTo = '#';
    let _connected = connected;
    let _organization;

    let _quickMenuList = getExtraQuickMenuOptions ? getExtraQuickMenuOptions(source) : [];
    
    let filteredMenuList = _quickMenuList.filter(item => item.id === 'remove-from-group');

    let _productInstances = [];

    switch (source.__typename ? source.__typename : source.type) {
        case 'User':
            const customFields = source.profile?.customFields?.find(
                ({ parentGroup }) => parentGroup?._id === group?._id
            );
            const isSharingOrganization = !!customFields?.values.find(
                ({ type }) => type === SIGNUP_FIELD_TYPE_ORGANIZATION
            )?.values?.[0];

            _organization = isSharingOrganization && source.profile?.organization;
            _connected = isUserSociallyEngaged(source);
            _title = source.profile ? source.profile.full_name?.replace(/\s+/, ' ') : '';
            _description = source.profile ? source.profile.short_bio : '';
            const _email = source.profile?.email;
            const emailLink = `mailto:${_email}`;

            _thumbImage = source.profile.picture
                ? source.profile.picture
                : getRandomDefaultUserImage();
            if (!_followIcon) _followIcon = _connected ? ' following-user' : '';
            _linkTo = _linkTo !== '#' ? _linkTo : `/u/${source._id}`;

            titleSection = _title;
            headlineSection = _description;
            if (showEmail) {
                otherSection = (
                    <div className="plus-info">
                        <a className="email-link" href={emailLink}>
                            {_email}
                        </a>
                    </div>
                );
            }

            _quickMenuList.push({
                id: 'goto-profile',
                iconKey: 'member',
                title: t(`common:${localNamespace}.view_profile`),
                to: _linkTo,
                onClick: () => {
                    closeAllModals();
                    hideSidebar();
                },
            });

            _quickMenuList = [
                ..._quickMenuList,
                ...getSocialActionDropdownItems(
                    source,
                    {
                        updateConnection,
                        flagUser,
                        createMessageThread,
                        currentUser: loggedInUser,
                        history,
                    },
                    openQuickMenu,
                    t
                ),
            ];

            if (!isUserInSameSilo) {
                _quickMenuList = _quickMenuList.filter(menuItem => menuItem.id === 'report')
            }

            if (source?.productInstancesForGroup?.length > 0) {
                _productInstances = getProductInstancesAndFrequency(
                    source?.productInstancesForGroup
                );
            }

            break;
        default:
            break;
    }

    const [src] = getBackgroundImageSetForUrl(_thumbImage || getRandomDefaultUserImage());

    const hasQuickItems = _quickMenuList.length > 0;

    const buttonClass = connected
        ? 'button-reset content-button-icon dropdown-dots'
        : `button-reset follow-button ${_followIcon} w-inline-block`;

    let connectBtn;

    const { REQUEST_CONNECTION, ACCEPT_CONNECTION, CANCEL_REQUEST_CONNECTION, DISCONNECT } =
        SOCIAL_ACTIONS;
    const { socialActionsAllowed } = source;

    if (socialActionsAllowed.includes(REQUEST_CONNECTION) && isUserInSameSilo) {
        connectBtn = (
            <button
                type="button"
                aria-label="Connect"
                className="group-members-action-btn group-members-action-btn-gray"
                onClick={e => {
                    e.stopPropagation();
                    handleConnectClick(source);
                }}
            >
                <UserAdd className="group-members-action-icon-blk" />
            </button>
        );
    }
    if (
        socialActionsAllowed.includes(ACCEPT_CONNECTION) ||
        socialActionsAllowed.includes(CANCEL_REQUEST_CONNECTION)
    ) {
        connectBtn = (
            <button
                className="group-members-action-btn group-members-action-btn-light-blue"
                onClick={e => {
                    e.stopPropagation();
                    handleConnectClick(source);
                }}
                type="button"
            >
                <UserPending className="group-members-action-icon-white" />
            </button>
        );
    }
    if (socialActionsAllowed.includes(DISCONNECT)) {
        connectBtn = (
            <button
                className="group-members-action-btn group-members-action-btn-blue"
                onClick={e => {
                    e.stopPropagation();
                    handleConnectClick(source);
                }}
                type="button"
            >
                <UserConnected className="group-members-action-icon-white" />
            </button>
        );
    }

    const className = `group-members-form-result ${customClass}`;

    const openProfileView = () => {
        source?.profile?.protectionLevel != null &&
        newModal(<QuickProfileView user={source} />, {
            className: 'no-top-padding white-notch',
        });
    };

    const productInstancesList =
        _productInstances?.length > 0 ? (
            <div className="group-members-product-instances-wrap">
                {_productInstances.map(pi => {
                    const productInstanceCount =
                        pi?.product?.type === 'GROUP_SUBSCRIPTION' ? 1 : pi?.count;
                    return (
                        <p
                            className={`${productInstanceCount === 1
                                    ? 'group-members-product-instances-badge'
                                    : ''
                                }`}
                        >
                            {pi?.product?.name}
                            {productInstanceCount > 1 && <span>{productInstanceCount}</span>}
                        </p>
                    );
                })}
            </div>
        ) : null;

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li ref={ref} className={className}>
            <div className="profile-thumb-img" onClick={openProfileView}>
                <Picture url={src} disableLink resolutions={[130, 260, 390]} />
            </div>
            <section onClick={openProfileView}>
                <h3 title={titleSection}>{titleSection}</h3>
                {_organization && <h5 title={_organization.name}>{_organization.name}</h5>}
                <span title={headlineSection}>{headlineSection}</span>
                {showEmail && otherSection}
                {productInstancesList}
            </section>
            {(hasQuickItems || _organization) && (
                <div className="group-members-action-btn-wrap">
                    {source?.profile?.protectionLevel != null ? connectBtn : ''}
                    <div className="group-members-action-btn group-members-action-dropdown-btn">
                        {hasQuickItems  && (
                            <DropdownController
                                menuList={source?.profile?.protectionLevel != null ? _quickMenuList : filteredMenuList}
                                buttonClass={buttonClass}
                            />
                        )}
                    </div>
                </div>
            )}
        </li>
    );
});

export default GroupMemberItem;
