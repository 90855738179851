import React from 'react';

import MiniCourseLevelView from '@components/lego/creation/creationpage/MiniCourseLevelView';

import Fade from '@components/generic/animation/Fade';

import { ContentCard } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

type CreationViewTabProps = {
    card?: Maybify<ContentCard> | null;
    selectedIndex: number;
    activeScreen: string;
};

const CourseViewTab = React.forwardRef<HTMLDivElement, CreationViewTabProps>(function CourseViewTab(
    { card, selectedIndex, activeScreen },
    ref
) {
    const course = card?.creationItems?.course;

    return (
        <div className="content-wrapper" ref={ref}>
            <div className="centered">
                <Fade show={activeScreen === 'courseSlide'}>
                    <MiniCourseLevelView card={course} selectedIndex={selectedIndex} />
                </Fade>
            </div>
        </div>
    );
});

export default CourseViewTab;
