import { Maybe } from '@shared/welibrary-graphql/types';

/**
 * Narrows test to be a key of object, note that this is _technically_ unsafe, but tbh it's safer
 * than using as to shut TS up
 */
export const isKey = <Obj extends Record<string, any>>(
    object: Obj,
    test?: any
): test is keyof Obj => (test && test in object) || false;

/** Type guard for removing undefined */
export const isNotUndefined = <T>(item: T | undefined): item is T => !!item;

/** Type guard for removing Maybe */
export const isNotMaybe = <T>(item?: Maybe<T>): item is T => !!item;

/** Removes Maybe from items in an array */
export const filterMaybes = <T>(array: Maybe<T | undefined>[]) => array.filter(isNotMaybe);

/** Narrows a string down to a union of literal string types */
export const narrowString = <T extends string>(str: string, values: T[]): str is T =>
    values.includes(str as T);
