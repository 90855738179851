import React, {useReducer} from 'react';

const SET_GROUPS_FILTER = 'SET_GROUPS_FILTER';
const ADD_GROUPS_FILTER = 'ADD_GROUPS_FILTER';
const DELETE_GROUPS_FILTER = 'DELETE_GROUPS_FILTER';
const SET_GROUP_SELECT = 'SET_GROUP_SELECT';
const GroupContext = React.createContext();
export const GroupActionsContext = React.createContext();

const GroupReducer = (state, action) => {
    switch (action.type) {
        case ADD_GROUPS_FILTER:
            return {
                ...state,
                groupsFilter: [...state.groupsFilter, action.group],
            };
        case DELETE_GROUPS_FILTER:
            const filteredGroup = state.groupsFilter.filter(group => group !== action.group);
            return {
                ...state,
                groupsFilter: filteredGroup,
            };
        case SET_GROUPS_FILTER:
            return {
                ...state,
                groupsFilter: action.groups,
            };
        case SET_GROUP_SELECT:
            return {
                ...state,
                groupSelectMode: action.mode,
            };
        default:
    }
};

export const GroupProvider = ({ children }) => {
    const [groupState, dispatch] = useReducer(GroupReducer, {
        groupSelectMode: 'single',
        groupsFilter: [],
    });

    const addGroupsFilter = group => {
        dispatch({
            type: ADD_GROUPS_FILTER,
            group,
        });
    };

    const deleteGroupsFilter = group => {
        dispatch({
            type: DELETE_GROUPS_FILTER,
            group,
        });
    };

    const setGroupsFilter = groups => {
        dispatch({
            type: SET_GROUPS_FILTER,
            groups,
        });
    };

    const setGroupSelect = mode => {
        dispatch({
            type: SET_GROUP_SELECT,
            mode,
        });
    };

    return (
        <GroupContext.Provider value={{ groupState }}>
            <GroupActionsContext.Provider
                value={{
                    setGroupSelect,
                    addGroupsFilter,
                    deleteGroupsFilter,
                    setGroupsFilter,
                }}
            >
                {children}
            </GroupActionsContext.Provider>
        </GroupContext.Provider>
    );
};

export default GroupContext;
