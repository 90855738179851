/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSortable } from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';

import { StyledBackgroundImage } from '@core/utilities/constants/defaults';
import {
    generateDefaultImageOnTileCreation,
    revertToDefaultImageInEditMode,
    disableDefaultingImageOnCreation,
    disableDefaultingImageInEditMode,
} from '@web/ui/components/content/newpost/forms/linkTileHelpers';

import useModal from '@components/modals/hooks/useModal';
import ImagePicker from '@components/content/ImagePicker';

import { AliasHandle, ContentCard, Group } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.card.linkTile';

type LinkTileProps = {
    tileId: string;
    alias: AliasHandle;
    aliasItem: AliasHandle;
    item: ContentCard | Group;
    card: ContentCard;
    chapter?: ContentCard;
    group?: Group;
    updateAliasItem: (item: any, target: string, value: any) => void;
    deleteAliasItem: (item: any) => void;
    backgroundStyle: React.CSSProperties;
    src: string;
    srcSet?: string;
    disabled: boolean;
    currentImage: string | undefined;
    isBeingDragged: boolean;
    inEditMode: boolean;
};

const LinkTile: React.FC<LinkTileProps> = ({
    tileId,
    alias,
    aliasItem,
    item,
    card,
    chapter,
    group,
    updateAliasItem,
    deleteAliasItem,
    backgroundStyle,
    src,
    srcSet,
    disabled,
    currentImage,
    isBeingDragged,
    inEditMode,
}) => {
    const { t } = useTranslation();
    const { newModal } = useModal();
    const blockRef = useRef<HTMLDivElement>(null);

    const selectImage = () => {
        newModal(<ImagePicker onImageSelect={url => updateAliasItem(alias, 'thumb', url)} />, {
            className: 'image-picker-modal no-bottom-padding full-height',
        });
    };

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: tileId,
    });

    if (transform) {
        // Don't scale the one that's being dragged
        if (isBeingDragged) {
            transform.scaleX = 1;
        }

        // If the component is scaled, we need to adjust the X so that the element appears in the right place
        const elementStyle = blockRef?.current
            ? getComputedStyle(blockRef.current)
            : { width: '0' };
        const elementWidth = parseInt(elementStyle.width) || 0;

        const scaleXRounded = Math.round(transform.scaleX * 10) / 10;
        if (scaleXRounded === 2) {
            // going from small to big
            transform.x += elementWidth / 2;
        } else if (scaleXRounded === 0.5) {
            // going from big to small
            transform.x -= elementWidth / 4;
        }
    }

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div key={tileId} className="tile-block-wrapper" ref={setNodeRef} style={style}>
            <div className="tile-block-container">
                <div className="tile-link-block" ref={blockRef}>
                    <span
                        className="tile-link-drag-handle white-drag-handle"
                        {...listeners}
                        {...attributes}
                    />
                    <StyledBackgroundImage
                        className="tile-link-block-bg"
                        src={src}
                        srcSet={srcSet}
                        style={backgroundStyle}
                    />
                    {backgroundStyle && (
                        <div
                            className="tile-link-block-bg color-bg-preview"
                            style={backgroundStyle}
                        />
                    )}
                    <div className="tile-link-block-content tile-link-form-block">
                        <div className="margin-clear w-form">
                            <input
                                id="title"
                                type="text"
                                value={alias.title}
                                onChange={e => {
                                    const { value } = e.target;
                                    updateAliasItem(alias, 'title', value);
                                }}
                                className="tile-link-field title-field w-input"
                                maxLength={256}
                                name="title"
                                placeholder="Tile Title"
                                disabled={disabled}
                                style={{ backgroundColor: 'transparent' }}
                            />

                            {aliasItem?.type === 'alias_external_link' && (
                                <input
                                    id="url"
                                    type="text"
                                    value={alias.url}
                                    onChange={e => {
                                        const { value } = e.target;
                                        updateAliasItem(alias, 'url', value);
                                    }}
                                    className="tile-link-field w-input"
                                    maxLength={256}
                                    name="url"
                                    placeholder="External URL"
                                    disabled={disabled}
                                    style={{ backgroundColor: 'transparent' }}
                                />
                            )}
                            <input
                                id="color"
                                type="text"
                                value={alias.color}
                                onChange={e => {
                                    const { value } = e.target;
                                    updateAliasItem(alias, 'color', value);
                                }}
                                className="tile-link-field w-input"
                                maxLength={256}
                                name="color"
                                placeholder={`${t(
                                    `common:${localNamespace}.background_color`,
                                    `Background Color`
                                )} (e.g. #000000)`}
                                disabled={disabled}
                                style={{ backgroundColor: 'transparent' }}
                            />

                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <button
                                type="button"
                                className="add-image-button edit-image-button tile-link-edit-image-btn"
                                onClick={selectImage}
                            />
                        </div>
                    </div>
                    {/* <div class="move-button"></div> */}
                    <div className="tile-link-block-buttons-container tile-link-block-buttons-row">
                        {inEditMode &&
                            !disableDefaultingImageInEditMode(
                                alias,
                                aliasItem,
                                card,
                                currentImage
                            ) && (
                                <button
                                    type="button"
                                    onClick={() =>
                                        updateAliasItem(
                                            alias,
                                            'thumb',
                                            revertToDefaultImageInEditMode(alias, aliasItem, item)
                                        )
                                    }
                                    className="tile-link-delete-image-btn"
                                >
                                    Use Default Image
                                </button>
                            )}
                        {!inEditMode && !disableDefaultingImageOnCreation(alias, chapter, group) && (
                            <button
                                type="button"
                                onClick={() =>
                                    updateAliasItem(
                                        alias,
                                        'thumb',
                                        generateDefaultImageOnTileCreation(alias.item)
                                    )
                                }
                                className="tile-link-delete-image-btn"
                            >
                                Use Default Image
                            </button>
                        )}
                        {!disabled && (
                            <button
                                type="button"
                                title={t(`common:${localNamespace}.delete_tile`, `Delete Tile`)}
                                aria-label={t(
                                    `common:${localNamespace}.delete_tile`,
                                    `Delete Tile`
                                )}
                                onClick={() => deleteAliasItem(alias)}
                                className="button-reset delete-button-white delete-circle w-inline-block"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinkTile;
