import { ConfigInputType } from '@config/schemas/main';
import EventTypes from '@core/utilities/constants/events';

import {
    CARD_CREATION,
    CARD_STORY,
    CARD_SURVEY,
    CARD_RAINBOW_ROAD,
    CARD_DIALOG,
} from '@core/utilities/constants/card_types';

const config: ConfigInputType = {
    public: {
        root_url: 'localhost:3000',
        apiUrl: 'localhost:4000',
        siteName: 'WeLibrary',
        captcha: {
            key: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        },
        displayPlatformFormspec: true,
        configName: 'development-local',
        copyright: 'WeLibrary, LLC',
        optionalPostTypes: [CARD_CREATION, CARD_STORY, CARD_SURVEY, CARD_RAINBOW_ROAD, CARD_DIALOG],
        eventTypes: [EventTypes.EVENT_GROUP, EventTypes.EVENT_LISTING],
        metaInfo: {
            title: 'WeLibrary',
            description:
                'WeLibrary helps you think better - organize and prioritize content by relevance, quality & sentiment.',
            image: '/images/logo.welibrary.blue.svg',
            url: 'localhost:3000',
        },
        filestack: { api_key: 'AWnnnSe6qS8aHSc5KK4Zuz' },
        unsplash: { access_key: 'NTNacJWbx9pMW5yHizHSqWr1IpQXmTJTuQ08VZ7pSEg' },
        GATracking: { GA_ID: 'G-FT25RRYQT3' },
        Google: { Maps_API_Key: 'AIzaSyBDfzEYNC2sHOYi9OEwmwNVVtOMDFfUV5I' },
        sentry: { dsn: 'https://2e9ae6c6eba5493fac642cb40304989e@sentry.io/1427122' },
        userflow: { api_key: 'ct_w53eaxhevvf2vejzrecekeq3nu' },
        showGettingStarted: true,
        firebaseConfig: {
            apiKey: 'AIzaSyAdqwUuzbZoS6s3H6HkR9nWyNPVEDcjmWQ',
            authDomain: 'welibrary-native.firebaseapp.com',
            databaseURL: 'https://welibrary-native.firebaseio.com',
            projectId: 'welibrary-native',
            storageBucket: 'welibrary-native.appspot.com',
            messagingSenderId: '847997887601',
            appId: '1:847997887601:web:90069dce228246d35127d0',
            measurementId: 'G-DXE742J9YQ',
        },
        torus: {
            verifier: 'staging-welibrary',
            network: 'testnet',
        },
        stripe: {
            client_api_key: 'pk_test_S9NV67ltFVkEFi1X7VfMwG0N0016slSnqr',
            secret_webhook_key: 'whsec_TbG0nmOcyl0McacfGhdld6IDnynHN52K',
        },

        defaultGroupCode: 'WELIBRARY',
        defaultMembershipUrl: 'welibrary',
        restrictSignupToDefaultMembership: true,

        communityGuidelines: 'commissioned.global/community-guidelines',
        privacyPolicy: 'www.learningeconomy.io/privacy-policy',
        termsOfService: 'www.learningeconomy.io/terms-of-service',
        templatesUrl: 'templates',

        defaultLogoBackup: 'https://cdn.filestackcontent.com/4R8SRB6PRdyGXDP4ZZUn',
        defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/TmGQQbJ7TKFbJcINLNQZ',
        defaultLogoBackupWhiteAlt: '',
        defaultLogoBlack: 'https://cdn.filestackcontent.com/PX17Zaw5TpyrJAdvu8bM',

        homeButton: {
            picture: '/images/defaults/cover_images/CoverPhoto-16.jpg',
            title: 'Sample Group',
            groupId: 'RqJFHstXEK46RJrtQ',
        },

        pushSenderID: 847997887601,
        pushLogo: 'we_notification',

        recommendationsMatchmakerWhitelist: ['friendship-library', 'library-of-matching'],

        userSnap: true,
        userSnapTesterEmails: [],

        bulkUserTemplate:
            'https://docs.google.com/spreadsheets/d/1kkqURN4VHJuj6KZVMMSRRuWGdV_KvdWz5BeoYj9q1SQ/edit?usp=sharing',
        bulkGenerateUserTemplate:
            'https://docs.google.com/spreadsheets/d/1LSN6MLgMmyHToHGpA4EEHZFtGPjLWxn3GsmbgM71Rdw/edit?usp=sharing',

        brandColor1: '#0094f6',
        brandColor2: '#7a0cff',

        theme: {
            // primaryColor: '#0094f6', // we/ blue
            // hoverColor: '#000', // we/ black hover
            primaryColor: '#00AF90', // commisioned green
            hoverColor: '#008E76', // comm hover
            primaryAccent: '#0094f61a',
            primaryShade: '#0076c5',
            thumbHighlight: '#ffffff',
            primaryHighlight: '#38b0ff',
            secondaryColor: '#7a0cff',
            slideBackground: 'url(images/menu-background-weLibrary.jpg)',
        },

        companyAddress: '406 Lazy Creek Lane, Nashville, TN, 37211',

        allowAnonymousSignups: true,

        domainBranding: {
            'localhost:3000': {
                root_url: 'localhost:3000',
                // defaultCoverPhoto: 'https://cdn.filestackcontent.com/IvNFPjSQmeAYgdhOTMnN', // We/ background (I think), but it's white instead of blue
                // siteName: 'WeLibrary',

                // comm logos
                siteName: 'Commisioned',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/l2kemkFTqqx9LIxCElgQ', // commisioned background
                defaultLogoBackup: 'https://cdn.filestackcontent.com/0bohNBYrSBeJyDQN3f2J',
                defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/0bohNBYrSBeJyDQN3f2J',
                defaultLogoBackupWhiteAlt: 'https://cdn.filestackcontent.com/Xfr6Jiz2Q36YMuFJddBh',
                defaultLogoBlack: 'https://cdn.filestackcontent.com/iXspwqTOBrG1xaB6kgYE',
            },
            'lerhub.org': {
                root_url: 'lerhub.org',
                siteName: 'LER Hub',
                emailFrom: 'T3 Innovation Network <no-reply@welibrary.io>',

                defaultLogoBackup: 'https://cdn.filestackcontent.com/ixldHUo4QViKY9ldt255',
                defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/ixldHUo4QViKY9ldt255',
                defaultLogoBlack: 'https://cdn.filestackcontent.com/ZsEd7qlgS7a5ZLjHTpEV',

                privacyPolicy: 'www.uschamber.com/privacy-policy',

                brandColor1: '#103B5C',
                brandColor2: '#5acae8',

                copyright: 'T3 Innovation Network',
                companyAddress: '1133 21st St NW, Washington, D.C.',

                defaultGroupCode: 'LERHUB',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/9AiBMl6ATvKvsQTwvt6p',

                disableServiceWorker: true,

                favicon: '/images/favicon/t3/favicon.ico',

                root_redirect: '/g/bqCgme2fQxDbgJ6D7',
                root_redirect_loggedin: '/dashboard',

                slideMenu: { search: false },

                secondaryNavigation: {
                    items: {
                        mobile: ['navigator', 'home', 'messages', 'notifications', 'profile'],
                        desktop: ['home', 'messages', 'notifications', 'profile'],
                    },
                },
            },
            internetofeducation: {
                root_url: 'app.internetofeducation.org',
                siteName: 'Internet of Education',
                emailFrom: 'Internet of Education <no-reply@welibrary.io>',

                defaultLogoBackup: 'https://cdn.filestackcontent.com/ToXAY8i3TWCkJxPgi15g',
                defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/fORSVkqKRgqAm7eQ2xoW',
                defaultLogoBlack: 'https://cdn.filestackcontent.com/rBkM4keCTAueW4d3Fpj4',

                privacyPolicy: 'www.welibrary.io/privacy-policy',
                termsOfService: 'www.learningeconomy.io/terms-of-service',

                brandColor1: '#ffeb3b',
                brandColor2: '#000000',

                copyright: 'Learning Economy',

                defaultGroupCode: 'IOEDU',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/JGFtsqlSQlaqm34J2M7M',

                disableServiceWorker: true,

                favicon: '/images/favicon/ioe/favicon.ico',

                root_redirect: '/g/u3ddFa6AgBLwmETdB',
                root_redirect_native: '/signup',
                root_redirect_loggedin: '/dashboard',

                slideMenu: { search: false },

                secondaryNavigation: {
                    items: {
                        mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                        desktop: ['home', 'messages', 'search', 'notifications', 'profile'],
                    },
                },
            },
            legossl: {
                root_url: 'app.legosuperskillslab.org',
                siteName: 'Lego Super Skills Lab',
                emailFrom: 'Lego Super Skills Lab <no-reply@welibrary.io>',

                defaultLogoBackup: '/images/IoE.logo.brandmark black.svg',
                defaultLogoBackupWhite: '/images/IoE.logo.brandmark.svg',
                defaultLogoBlack: '/images/ioe.logo.full.black.svg',

                privacyPolicy: 'www.welibrary.io/privacy-policy',
                termsOfService: 'www.learningeconomy.io/terms-of-service',

                brandColor1: '#ffeb3b',
                brandColor2: '#000000',

                copyright: 'Learning Economy',

                defaultGroupCode: 'IOEDU',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/JGFtsqlSQlaqm34J2M7M',

                disableServiceWorker: true,

                favicon: '/images/favicon/ioe/favicon.ico',

                root_redirect: '/g/u3ddFa6AgBLwmETdB',
                root_redirect_native: '/signup',
                root_redirect_loggedin: '/dashboard',

                slideMenu: { search: false },

                secondaryNavigation: {
                    items: {
                        mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                        desktop: ['home', 'messages', 'discover', 'notifications', 'profile'],
                    },
                },
            },
        },
        slideMenu: {
            search: true,
            filterNewsfeed: true,
            discover: true,
            rainbowRoad: true,
            footerText: 'common:global.patent_pending',
        },

        locize: {
            projectId: '662a9e61-2900-409c-a73e-6ba0bbfc3f38',
            apiKey: '3ff8b824-d205-42ea-be99-a366868d9f73',
            referenceLng: 'en',
        },
        ceramic: {
            nodeEndpointUrl: 'https://ceramic-node.staging.welibrary.io:7007',
            contentFamily: 'SuperSkills-local',
            definitions: {
                MyVerifiableCredentials:
                    'kjzl6cwe1jw1461695j0dmtvjmtcjhqdtx63calspn0r5p2ti110pzbkboabozr',
            },
            schemas: {
                AchievementVerifiableCredential:
                    'ceramic://k3y52l7qbv1frybe3lenawavapwpg38ut3enaqrw03s8nemi1ycq6lvsg1nifb37k',
                VerifiableCredentialsList:
                    'ceramic://k3y52l7qbv1fryp70yk4y1xv2aedxjnkrbdezyot13gnjkyrdn6lhs1k5irkosl4w',
            },
        },

        appflow: {
            liveUpdate: {
                appId: 'ff5def27',
                channel: 'development-local',
                updateMethod: 'none',
            },
        },

        eventOrganizerTermsOfUseUrl:
            'https://www.commissioned.global/event-organizer-terms-of-use/',
        ticketPurchaserTermsOfUseUrl:
            'https://www.commissioned.global/ticket-purchaser-terms-of-use/',

        launchDarklyConfig: {
            clientSideID: '64514ca1c158f01311e773ae',
            context: {
                kind: 'user',
                key: 'anonymous',
            },
        },
    },
};

export default config;
