import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';
import useOptimizer from '@components/optimizers/hooks/useOptimizer';
import useUserOptimizers from '@web/ui/components/optimizers/hooks/useUserOptimizers';

import signUpStore from '@stores/SignUp';

import Arrow from '@dsc/svgs/Arrow';
import TextArea from '@components/generic/forms/TextArea';
import ConsentBox from '@components/group/customsignup/ConsentBox';
import PostSignupHeader from '@pages/new-signup/PostSignupHeader';
import SignUpPageButton from '@pages/new-signup/SignUpPageButton';
import MultiSelectInput from '@dsc/forms/customInputs/MultiSelectInput';
import SkipQuestionsModal from '@pages/new-signup/SkipQuestionsModal';
import LocationOptimizerField from '@components/optimizers/LocationOptimizerField';
import OrganizationOptimizerField from '@components/optimizers/OrganizationOptimizerField';

import { filterSignupQuestions } from '@helpers/signup.helpers';
import {
    CustomSignupFieldTypes,
    Organization,
} from '@shared/welibrary-graphql/user/mutations.hook';
import { areDependentQuestionsAnswered } from '@helpers/customFields/customFields.helpers';
import GroupSelect from '../../components/group/customsignup/GroupSelect';

const localNamespace = 'imports.wlWeb.ui.pages.post-signup.questions';

type PostSignupQuestionsProps = {
    groupId: string;
    goToNextStep: () => any;
    goToPreviousStep: () => any;
    redirectUrl: string;
    headerText: string;
    subtitle: string;
    onSkip?: () => void;
};

const PostSignupQuestions: React.FC<PostSignupQuestionsProps> = ({
    groupId,
    goToNextStep,
    goToPreviousStep,
    redirectUrl,
    headerText,
    subtitle,
    onSkip,
}) => {
    const { t } = useTranslation();
    const { newModal } = useModal();
    const { currentUser } = useCurrentUser();

    const [saving, setSaving] = useState(false);

    const questions = signUpStore.useTracked.groupQuestionsById(groupId);
    const questionsLoading = signUpStore.useTracked.groupQuestionsLoadingById(groupId);

    const { profile, updateQuery } = useUserOptimizers(currentUser?._id, undefined, false);
    const { handleCustomFieldsChange, optimizeState, saveChanges, unsavedChanges } = useOptimizer({
        group: { _id: groupId }, // the only other thing this uses besides _id is customSignupFields, but we're getting those from useSignupQuestions
        profile,
        updateQuery,
    });

    const showUnsavedChangesModal = () => {
        newModal(<SkipQuestionsModal redirectUrl={redirectUrl} saveChanges={saveChanges} />);
    };

    const signupQuestions = filterSignupQuestions(questions)?.filter(question =>
        areDependentQuestionsAnswered(question, optimizeState, questions)
    );
    const requiredSignupQuestions = signupQuestions?.filter(question => question.required);

    const allRequredQuestionsAreAnswerd = () => {
        if (!requiredSignupQuestions || requiredSignupQuestions.length === 0) {
            return true;
        }
        const allAnswered = requiredSignupQuestions.reduce((allAnsweredSoFar, question) => {
            const answer = optimizeState[question.id]?.value;

            let isAnswered: boolean;
            switch (question.type) {
                case CustomSignupFieldTypes.Dropdown:
                    isAnswered = answer?.length > 0;
                    break;
                case CustomSignupFieldTypes.ConsentBox:
                    isAnswered = answer && answer[0] === 'accept';
                    break;
                case CustomSignupFieldTypes.JoinGroup:
                    isAnswered = true;
                    break;
                case CustomSignupFieldTypes.Shortanswer:
                case CustomSignupFieldTypes.Paragraph:
                case CustomSignupFieldTypes.ProfileLocation:
                case CustomSignupFieldTypes.ProfileOrganization:
                case CustomSignupFieldTypes.ChooseRole: // this is obsolete and can be ignored
                default:
                    isAnswered = !!answer;
            }

            return allAnsweredSoFar && isAnswered;
        }, true);

        return allAnswered;
    };

    if (groupId && !questionsLoading && (signupQuestions?.length ?? 0) === 0) {
        onSkip?.();
        goToNextStep();
    }

    return (
        <div className="flex flex-col pt-[40px] md:pt-[60px] px-[40px] pb-[40px] relative w-full h-full overflow-y-auto">
            <PostSignupHeader
                headerText={headerText}
                subtitle={subtitle}
                redirectUrl={redirectUrl}
                onSkipProfileOverride={unsavedChanges ? showUnsavedChangesModal : undefined}
            />
            <div className="flex flex-col gap-[40px] grow">
                {signupQuestions?.map((question, index) => {
                    const {
                        id,
                        type,
                        prompt,
                        options,
                        selectMultiple,
                        required,
                        link,
                        allowOther,
                    } = question;
                    const answers = optimizeState[id]?.value ?? [];

                    let input: React.ReactNode | undefined;
                    let showLabel = true;
                    let customLabel: string;

                    switch (type) {
                        case CustomSignupFieldTypes.Dropdown:
                            input = (
                                <MultiSelectInput
                                    selectedValues={answers}
                                    options={options}
                                    isSelectOne={!selectMultiple}
                                    allowOther={allowOther}
                                    onOptionClick={(option, isAdd) => {
                                        if (selectMultiple) {
                                            const updatedAnswers = isAdd
                                                ? [...answers, option]
                                                : answers.filter(a => a !== option);
                                            handleCustomFieldsChange(id, updatedAnswers, type);
                                        } else {
                                            handleCustomFieldsChange(id, [option], type);
                                        }
                                    }}
                                />
                            );
                            break;
                        case CustomSignupFieldTypes.Shortanswer:
                        case CustomSignupFieldTypes.Paragraph:
                            input = (
                                <TextArea
                                    maxCharacter={
                                        type === CustomSignupFieldTypes.Shortanswer ? 250 : 1000
                                    }
                                    defaultClasses=""
                                    defaultAutoResizeClasses=""
                                    className="bg-grayscale-input-background rounded-[16px] w-full text-grayscale-title-active text-[16px] leading-[175%] tracking-[0.75px] py-[20px] px-[24px]"
                                    wrapperClassName="relative w-full"
                                    countClassName="absolute bottom-[5px] right-[10px] text-grayscale-placeholder text-[14px] leading-[200%] tracking-[0.75px]"
                                    minHeight="128px"
                                    autoResize
                                    value={answers}
                                    handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        handleCustomFieldsChange(id, e.target.value, type);
                                    }}
                                />
                            );
                            break;
                        case CustomSignupFieldTypes.ConsentBox:
                            showLabel = false;
                            input = (
                                <ConsentBox
                                    key={index}
                                    value={answers[0] === 'accept'}
                                    onClick={value =>
                                        handleCustomFieldsChange(
                                            id,
                                            value ? ['accept'] : [''],
                                            type
                                        )
                                    }
                                    prompt={prompt}
                                    link={link}
                                    required={required}
                                    className="!p-0 !mb-0"
                                />
                            );
                            break;
                        case CustomSignupFieldTypes.ProfileLocation:
                            customLabel = t(
                                `common:${localNamespace}.shareEditLocation`,
                                'Select or edit your location.'
                            );
                            input = (
                                <LocationOptimizerField
                                    state={optimizeState}
                                    locationField={question}
                                    onChange={handleCustomFieldsChange}
                                    setSignupState={({
                                        location,
                                        country,
                                    }: {
                                        location: Location;
                                        country?: string;
                                    }) => {
                                        signUpStore.set.location(location);
                                        signUpStore.set.country(country);
                                    }}
                                    signup
                                    version="2"
                                />
                            );
                            break;
                        case CustomSignupFieldTypes.ProfileOrganization:
                            customLabel = t(
                                `common:${localNamespace}.selectEditOrg`,
                                'Select or edit your organization.'
                            );
                            input = (
                                <OrganizationOptimizerField
                                    state={optimizeState}
                                    organizationField={question}
                                    onChange={handleCustomFieldsChange}
                                    setSignupState={({
                                        organization,
                                    }: {
                                        organization: Organization;
                                    }) => {
                                        signUpStore.set.organization(organization);
                                    }}
                                    signup
                                    version="2"
                                />
                            );
                            break;
                        case CustomSignupFieldTypes.JoinGroup:
                            input = <GroupSelect field={question} membershipGroupId={groupId} />;
                            break;
                        case CustomSignupFieldTypes.ChooseRole: // this is obsolete and can be ignored
                        default:
                            break;
                    }

                    if (!input) {
                        return undefined;
                    }

                    return (
                        <div className="flex flex-col gap-[10px] w-full items-start" key={index}>
                            {showLabel && (
                                <label
                                    className="text-grayscale-label text-[17px] leading-[28px] tracking-[0.75px] font-[400] !mb-0"
                                    htmlFor={id}
                                >
                                    {customLabel ?? prompt}
                                    {required && ' *'}
                                </label>
                            )}
                            {input}
                        </div>
                    );
                })}

                <div className="flex gap-[10px] mt-auto">
                    <SignUpPageButton
                        className="!w-fit px-[11px]"
                        inverseColors
                        onClick={async () => {
                            if (unsavedChanges) {
                                setSaving(true);
                                await saveChanges();
                                setSaving(false);
                            }
                            goToPreviousStep();
                        }}
                        disabled={saving}
                    >
                        <Arrow direction="left" />
                    </SignUpPageButton>
                    <SignUpPageButton
                        onClick={async () => {
                            if (unsavedChanges) {
                                setSaving(true);
                                await saveChanges();
                                setSaving(false);
                            }
                            goToNextStep();
                        }}
                        disabled={!allRequredQuestionsAreAnswerd() || saving}
                    >
                        {saving
                            ? t(`common:${localNamespace}.saving`, 'Saving...')
                            : t(`common:${localNamespace}.continue`, 'Continue')}
                    </SignUpPageButton>
                </div>
            </div>
        </div>
    );
};

export default PostSignupQuestions;
