import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Updater, DraftFunction } from 'use-immer';
import { Maybify } from '@core/types/Utilities';
import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';
import useModal from '@components/modals/hooks/useModal';
import useCancelableTimer from '@components/generic/hooks/useCancelableTimer';

import EventGroupTicketsForm from '@components/content/newpost/forms/EventGroup/EventGroupTicketsForm';
import EventGroupOverviewForm from '@components/content/newpost/forms/EventGroup/EventGroupOverviewForm';
import EventGroupDateAndTimeForm from '@components/content/newpost/forms/EventGroup/EventGroupDateAndTimeForm';
import EventGroupItineraryForm from '@components/content/newpost/forms/EventGroup/EventGroupItineraryForm';
import EventGroupSponsorsForm from '@components/content/newpost/forms/EventGroup/EventGroupSponsorsForm';
import EventGroupPreviewStep from '@components/content/newpost/forms/EventGroup/EventGroupPreviewStep';
import EventGroupFinishStep from '@components/content/newpost/forms/EventGroup/EventGroupFinishStep';
import HorizontalNavHeader from '@dsc/navigation/HorizontalNavHeader';
import HorizontalNavFooter from '@dsc/navigation/HorizontalNavFooter';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary';
import GroupSettings from '@components/content/newpost/forms/EventGroup/GroupSettings';
import EventGroupCustomSignup from '@components/content/newpost/forms/EventGroup/EventGroupCustomSignup';
import { ModalTypes } from '@core/types/Modals';
import { getNavFunctions } from '@helpers/navigation/horizontalNav.helpers';
import { curriedStateSlice } from '@helpers/state/state.helpers';
import { isKey } from '@helpers/types/type.helpers';

import {
    EventGroupFormState,
    EventGroupFormSettingsState,
    EventGroupFormValidation,
    EventGroupFormModeEnum,
} from '@core/types/EventGroup';
import { Group, Maybe } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

const getButtonText = (currentPageName: string): string => {
    if (currentPageName === 'sponsors') return 'Preview';
    if (currentPageName === 'preview') return 'Publish';
    if (currentPageName === 'finish') return 'View Event';

    return 'Next';
};

type EventGroupFormProps = {
    disabled?: boolean;
    state: EventGroupFormState;
    setState: Updater<EventGroupFormState>;
    onSubmit: () => void;
    title?: string;
    groupData?: Maybe<Group>;
    group?: Group;
    initialStep?: string;
    showSave?: boolean;
    mode: EventGroupFormModeEnum;
    ticketCurrency: string;
    setTicketCurrency: React.Dispatch<React.SetStateAction<string>>;
    handleUpdateTicketCurrency: () => void;
};

const EventGroupForm: React.FC<EventGroupFormProps> = ({
    disabled = false,
    state,
    setState,
    onSubmit,
    title = 'Create Event Group',
    groupData,
    group,
    initialStep,
    showSave,
    mode = EventGroupFormModeEnum.create,
    ticketCurrency,
    setTicketCurrency,
    handleUpdateTicketCurrency = () => {},
}) => {
    const { t } = useTranslation();

    const [validation, setValidation] = useState<EventGroupFormValidation>({
        overview: true,
        dateAndTime: false,
        itinerary: true,
        tickets: false, // I don't know why, but setting this to false fixes a bug where the next button would dissapear on the iternary page during create
        sponsors: true,
        preview: state.previewFormState.agreedToTerms,
        finish: true,
    });

    const timer = useCancelableTimer();

    const updateSlice = curriedStateSlice(setState);

    const updateValidationSlice = (name: string, value: boolean | DraftFunction<boolean>) => {
        const validationClone = { ...validation };
        validationClone[name] = value;
        setValidation(validationClone);
    };

    const { closeModal, replaceModal, newModal, closeAllModals } = useModal({
        mobile: ModalTypes.FullScreen,
        desktop: ModalTypes.FullScreen,
    });

    const refs = {
        overview: useRef<HTMLFieldSetElement>(null),
        dateAndTime: useRef<HTMLFieldSetElement>(null),
        itinerary: useRef<HTMLFieldSetElement>(null),
        tickets: useRef<HTMLFieldSetElement>(null),
        sponsors: useRef<HTMLFieldSetElement>(null),
        preview: useRef<HTMLFieldSetElement>(null),
    };

    const { on, scrollTo } = useHorizontalPages({ refs });

    const { currentPage, currentPageName, goForward, goBack, headerPages, footerPages } =
        getNavFunctions({
            on,
            scrollTo,
            pages: Object.keys(refs).map(page => ({
                name: page as keyof typeof refs,
                canSkip: false,
                showMainButton: true,
            })),
        });

    const handleOptimizeForm = (group: Maybify<Group>, initialTab?: string) => {
        newModal(
            <EventGroupCustomSignup
                group={group}
                initialTab={initialTab}
                handleCancel={() => closeAllModals()}
            />,
            {
                className: 'center-fixed-header-footer',
                hideButton: false,
            }
        );
    };

    useEffect(() => {
        if (groupData?._id) {
            // Timeout is necessary to wait for loading modal to close before replacing
            timer.start(
                () =>
                    replaceModal(
                        <EventGroupFinishStep
                            title={title}
                            group={groupData}
                            onClick={handleOptimizeForm}
                        />,
                        {
                            className: 'center-fixed-header-footer',
                        }
                    ),
                500
            );
        }
    }, [groupData]);

    useEffect(() => {
        if (isKey(refs, initialStep) && refs[initialStep]?.current) {
            timer.start(() => scrollTo[initialStep](true), 10);
        }
    }, [initialStep, refs[initialStep as keyof typeof refs]?.current]);

    const allValid = Object.values(validation).every(v => v);

    const handleSaveSettings = (settingsState: EventGroupFormSettingsState) => {
        updateSlice('groupSettingsState', settingsState);
    };

    const handleSettings = () => {
        newModal(
            <GroupSettings
                handleCancel={closeModal}
                onSave={handleSaveSettings}
                state={state?.groupSettingsState}
                setState={updateSlice('groupSettingsState')}
            />,
            {
                className: 'center-fixed-header-footer',
            },
            { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
        );
    };

    return (
        <form
            onSubmit={disabled ? undefined : onSubmit}
            className="scroll-middle-fixed-header-footer"
        >
            <ErrorBoundary>
                <header>
                    <section className="header-info-block">
                        <section className="header-title">
                            <h1>{title}</h1>
                        </section>

                        <button className="cancel-btn" type="button" onClick={closeModal}>
                            {t(`common:${localNamespace}.cancel`)}
                        </button>
                    </section>

                    <HorizontalNavHeader
                        currentPage={currentPage}
                        pages={headerPages}
                        tag="section"
                        showCurrentPage
                    />
                </header>

                <article>
                    <EventGroupOverviewForm
                        state={state?.overviewFormState}
                        ref={refs?.overview}
                        setState={updateSlice('overviewFormState')}
                        updateValidationSlice={value => updateValidationSlice('overview', value)}
                    />
                    {validation.overview && (
                        <EventGroupDateAndTimeForm
                            state={state?.dateAndTimeState}
                            itineraryState={state?.itineraryState}
                            updateItineraryState={updateSlice('itineraryState')}
                            ref={refs?.dateAndTime}
                            setState={updateSlice('dateAndTimeState')}
                            updateValidationSlice={value =>
                                updateValidationSlice('dateAndTime', value)
                            }
                        />
                    )}

                    {validation.overview && validation.dateAndTime && (
                        <EventGroupItineraryForm
                            state={state?.itineraryState}
                            eventState={state?.dateAndTimeState}
                            overviewFormState={state?.overviewFormState}
                            ref={refs?.itinerary}
                            setState={updateSlice('itineraryState')}
                            updateValidationSlice={value =>
                                updateValidationSlice('itinerary', value)
                            }
                        />
                    )}

                    {validation.overview && validation.dateAndTime && validation.itinerary && (
                        <EventGroupTicketsForm
                            state={state?.ticketFormState}
                            ref={refs?.tickets}
                            setState={updateSlice('ticketFormState')}
                            updateValidationSlice={value => updateValidationSlice('tickets', value)}
                            mode={mode}
                            ticketCurrency={ticketCurrency}
                            setTicketCurrency={setTicketCurrency}
                            group={group}
                        />
                    )}
                    {validation.overview &&
                        validation.dateAndTime &&
                        validation.itinerary &&
                        validation.tickets && (
                            <EventGroupSponsorsForm
                                state={state?.sponsorsFormState}
                                ref={refs?.sponsors}
                                setState={updateSlice('sponsorsFormState')}
                                updateValidationSlice={value =>
                                    updateValidationSlice('sponsors', value)
                                }
                            />
                        )}

                    {validation.overview &&
                        validation.dateAndTime &&
                        validation.itinerary &&
                        validation?.sponsors && (
                            <EventGroupPreviewStep
                                state={state}
                                previewState={state?.previewFormState}
                                groupSettingsState={state?.groupSettingsState}
                                ref={refs?.preview}
                                setSettingsState={updateSlice('groupSettingsState')}
                                setState={updateSlice('previewFormState')}
                                updateValidationSlice={value =>
                                    updateValidationSlice('preview', value)
                                }
                                ticketCurrency={ticketCurrency}
                            />
                        )}
                </article>

                <HorizontalNavFooter
                    currentPage={currentPage}
                    pages={footerPages}
                    goBack={goBack}
                    showSettingsButton
                    settingsAction={handleSettings}
                    canDoMainAction={
                        currentPageName === 'preview'
                            ? !disabled && allValid
                            : validation[currentPageName]
                    }
                    mainAction={
                        currentPageName === 'preview'
                            ? onSubmit
                            : () => {
                                  goForward(false);
                              }
                    }
                    buttonText={getButtonText(currentPageName)}
                    showForwardButton={false}
                    onSave={onSubmit}
                    showSave={showSave}
                    saveText={t(`common:${localNamespace}.Save`, 'Save')}
                />
            </ErrorBoundary>
        </form>
    );
};

export default EventGroupForm;
