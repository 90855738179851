import React, {FormEventHandler} from 'react';
import {Updater} from 'use-immer';
import {useTranslation} from 'react-i18next';

import Toggle from '@web/ui/dsc/forms/customInputs/Toggle';
import RRBuilderColorPicker from '@web/ui/components/rainbowroad/builder/RRBuilderColorPicker';
import ImageUpload from '@web/ui/dsc/forms/customInputs/ImageUpload';

import {curriedStateSlice} from '@web/utilities/helpers/state/state.helpers';
import {getRandomDefaultCoverImage} from '@core/utilities/constants/defaults';

import {RainbowRoadBuilderBodyState} from '@core/types/RainbowRoad';

const localNamespace = 'imports.wlWeb.ui.components.rainbowRoad.rrBuilder';

type RRBuilderBodyProps = {
    title: string;
    state: RainbowRoadBuilderBodyState;
    setState: Updater<RainbowRoadBuilderBodyState>;
    onSubmit: FormEventHandler<HTMLFormElement>;
};

const RRBuilderBody = React.forwardRef<HTMLFormElement, RRBuilderBodyProps>(function RRBuilderBody(
    { title, state, setState, onSubmit },
    ref
) {
    const { t } = useTranslation();

    const updateSlice = curriedStateSlice(setState);

    return (
        <form
            className="rr-builder-header"
            onSubmit={e => {
                e.preventDefault();
                onSubmit(e);
            }}
            ref={ref}
        >
            <h1>{t(`common:${localNamespace}.body_settings`)}</h1>

            <label>
                {t(`common:${localNamespace}.dark_mode`)}
                <Toggle value={state.darkMode} onChange={updateSlice('darkMode')} />
            </label>

            <RRBuilderColorPicker
                state={state.color}
                setState={updateSlice('color')}
                values={[
                    'var(--color-grayscale-body)',
                    '#000',
                    'var(--color-wl-primary)',
                    'var(--color-wl-secondary',
                ]}
                darkMode={state.darkMode}
                title={t(`common:${localNamespace}.color_theme`)}
            />

            <label>
                {t(`common:${localNamespace}.background_image`)}
                <Toggle
                    value={state.useBackgroundImage}
                    onChange={updateSlice('useBackgroundImage')}
                />
            </label>

            {state.useBackgroundImage && (
                <ImageUpload
                    state={{
                        ...state.backgroundImage,
                        image: state.backgroundImage.image || getRandomDefaultCoverImage(title),
                    }}
                    setState={updateSlice('backgroundImage')}
                    className="rr-background-image-upload"
                    loaderSize={100}
                />
            )}
        </form>
    );
});

export default RRBuilderBody;
