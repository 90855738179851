import React from 'react';
import { useTranslation } from 'react-i18next';
import useSignupQuestions from '@web/utilities/hooks/signup/useSignupQuestions';

import signUpStore from '@stores/SignUp';

import PostSignupQuestions from '@pages/new-signup/PostSignupQuestions';

const localNamespace = 'imports.wlWeb.ui.pages.post-signup.network-questions';

type NetworkQuestionsProps = {
    goToNextStep: () => any;
    goToPreviousStep: () => any;
    redirectUrl: string;
};

const NetworkQuestions: React.FC<NetworkQuestionsProps> = ({
    goToNextStep,
    goToPreviousStep,
    redirectUrl,
}) => {
    const { t } = useTranslation();
    const { networkGroupId } = useSignupQuestions();

    const onSkip = () => {
        signUpStore.set.networkQuestionsSkipped(true);
    };

    return (
        <PostSignupQuestions
            groupId={networkGroupId}
            headerText={t(`common:${localNamespace}.aBitMore`, 'A Bit More')}
            subtitle={t(
                `common:${localNamespace}.optimizeExperience`,
                'Answering these questions will optimize your experience so you can connect with the people & content you love.'
            )}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            redirectUrl={redirectUrl}
            onSkip={onSkip}
        />
    );
};

export default NetworkQuestions;
