import React from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentUser } from '@core/types/User';
import { Group } from '@shared/welibrary-graphql/messaging/queries.hook';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import Optimizers from '@web/ui/components/optimizers/Optimizers';
import useGroupOptimizers from '@web/ui/components/optimizers/hooks/useGroupOptimizers';
import LoadingIndicator from '@components/generic/loading/LoadingIndicator';
import useModal from '../modals/hooks/useModal';
import ModalLoading from '../modals/ModalLoading';

interface IGroupPageOptimizer {
    group: Group;
    profile: CurrentUser['profile'];
    updateQuery: (payload: any) => void;
}

const GroupPageOptimizer: React.FC<IGroupPageOptimizer> = ({ group, profile, updateQuery }) => {
    const { t } = useTranslation();
    const {
        updateQuery: groupOptimizerUpdateQuery,
        loading,
        error,
    } = useGroupOptimizers(group._id, null, null, {
        includeAllRecommendationStatuses: true,
    });

    const { newModal } = useModal();

    console.log({ loading, group, profile });

    const isLoading = (typeof loading === 'string' ? true : loading) || !group || !profile;

    return (
        <>
            <LoadingLine isLoading={isLoading} error={error} />
            {!isLoading ? (
                <Optimizers
                    isUserOptimizers={true}
                    groups={[group]}
                    profile={profile}
                    updateQuery={updateQuery || groupOptimizerUpdateQuery}
                    onSave={() => {
                        newModal(<ModalLoading message={t('common:global.saving', 'Saving')} />, {
                            widen: true,
                            addShadow: true,
                        });
                    }}
                    loading={loading}
                    error={error}
                    includeLocationAndOrganizationPercentage
                />
            ) : (
                <LoadingIndicator />
            )}
        </>
    );
};

export default GroupPageOptimizer;
