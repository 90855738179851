import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

type EditScreenTagRadioProps = {
    target: string;
    options: { text: string; value: string; iconClass: string; selectedClass: string, highlightedClass: string }[];
    selected: string;
    handleRadio: (name, protectionLevel) => void;
    optionNameToHighlight: string; // name of option to apply highlight styles to
};

const EditScreenTagRadio: React.FC<EditScreenTagRadioProps> = ({
    target,
    options,
    selected,
    handleRadio,
    optionNameToHighlight,
}) => {
    const flags = useFlags();
    const siloedUsers = flags?.siloedUsers;
    const filteredOptions = siloedUsers ? options.filter(option => {
        return option.text !== 'Public'
    }) : options;
    const _options = _.map(filteredOptions, availableOption => {
        const iconClass = availableOption.iconClass ? availableOption.iconClass : '';
        
        let selectedClass = '';
        let highlightedClass = '';
        const isHighlighted = availableOption.value === optionNameToHighlight;
        const isSelected = availableOption.value === selected;
        
        if (isSelected) {
            selectedClass = availableOption.selectedClass
                ? availableOption.selectedClass
                : 'selected';
        }
        if (isHighlighted) {
            highlightedClass = availableOption.highlightedClass
                ? availableOption.highlightedClass
                : '';
        }
        return (
            <div key={`${availableOption.value}-radio-tag`} className="flex-item">
                <div
                    onClick={() => handleRadio(target, availableOption.value)}
                    type="radio"
                    checked={availableOption.value}
                    className={`select-option-item ${iconClass} ${selectedClass} ${highlightedClass} w-inline-block`}
                >
                    <div className="select-option-text" name={target} id={target} value="checkbox">
                        {availableOption.text}
                    </div>
                    <div className="select-option-line" />
                </div>
            </div>
        );
    });

    return _options;
};

export default EditScreenTagRadio;
