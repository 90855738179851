import { useState } from 'react';

import useMd from '@components/generic/hooks/useMd';
import useModal from '@components/modals/hooks/useModal';

import { QuestDialogType } from '@core/types/Quests';
import { PerformantMaybify } from '@core/types/Utilities';
import { DialogEvent } from '@shared/welibrary-graphql/types';

const interpolateText = (text: string, event?: PerformantMaybify<DialogEvent> | null): string => {
    if (!event || event.event !== 'level_completion') return text;

    let newText = text;

    if (
        typeof event.level === 'number' &&
        event.level > 0 &&
        event.creation?.creationItems?.course?.courseItems?.levels?.[event.level - 1]?.title
    ) {
        newText = newText.replaceAll(
            '$levelTitle',
            event.creation?.creationItems?.course?.courseItems?.levels?.[event.level - 1]?.title ??
                ''
        );
    }

    if (typeof event.level === 'number') {
        newText = newText.replaceAll('$level', event.level.toString());
    }

    if (event.creation?.title) newText = newText.replaceAll('$creationTitle', event.creation.title);

    if (event.creation?.creationItems?.course?.title) {
        newText = newText.replaceAll('$courseTitle', event.creation.creationItems.course.title);
    }

    return newText;
};

const useQuestDialog = ({
    dialog,
    event,
    onComplete,
}: {
    dialog?: QuestDialogType | null;
    event?: PerformantMaybify<DialogEvent> | null;
    onComplete?: () => void;
}) => {
    const [activeMessage, setActiveMessage] = useState(0);

    const md = useMd();
    const { closeModal } = useModal();

    const numberOfDialogs = dialog?.dialogs.length ?? 0;
    const activeDialog = dialog?.dialogs[activeMessage];
    const person = dialog?.people[activeDialog?.person ?? 0];

    const previousMessage = () => setActiveMessage(current => Math.max(0, current - 1));
    const nextMessage = () => {
        setActiveMessage(current => {
            if (current === numberOfDialogs - 1) {
                closeModal();
                if (onComplete) setTimeout(onComplete, 300);
            }

            return Math.min(current + 1, numberOfDialogs - 1);
        });
    };

    const text = interpolateText(activeDialog?.text ?? '', event);
    const markdownText = md.render(text) ?? text;

    return { person, markdownText, previousMessage, nextMessage, activeMessage, numberOfDialogs };
};

export default useQuestDialog;
