import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { z } from 'zod';

import {
    NewCouponFormHeader,
    EditCouponFormHeader,
} from '@components/content/newpost/forms/ShoppingCartProducts/CouponFormHeaders';

import { curriedStateSlice } from '@helpers/state/state.helpers';

import { Coupon } from '@components/content/newpost/forms/ShoppingCartProducts/ticketHelpers';

const StateValidator = z.object({
    name: z.string().nonempty('Name is required!').max(50),
    code: z
        .string()
        .nonempty('Code is required! ')
        .min(3, 'Code must be atleast 3 characters long.')
        .max(15, 'Code cannot exceed 15 characters.'),
    percentage: z
        .number()
        .nonnegative('Please enter a valid percentage!')
        .min(1, 'Percentage must be atleast 1')
        .max(100, 'Percentage cannot exceed 100'),
});

const CouponForm: React.FC<{
    index: number;
    coupon?: Coupon;
    cancel: () => void;
    addCoupon: (coupon: Coupon) => void;
    updateCoupon: (coupon: Coupon, index: number) => void;
    deleteCoupon: (index: number) => void;
    inEditMode: boolean;
}> = ({ index, coupon, cancel, addCoupon, updateCoupon, deleteCoupon, inEditMode = false }) => {
    const { t } = useTranslation();

    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const [state, setState] = useImmer<Coupon>({
        name: coupon?.name ?? '',
        code: coupon?.code ?? '',
        percentage: coupon?.percentage * 100 ?? null,
        type: coupon?.type ?? 'default',
    });

    const updateSlice = curriedStateSlice(setState);

    const validate = () => {
        const parsedData = StateValidator.safeParse({
            name: state.name,
            code: state.code,
            percentage: Number(state.percentage),
        });

        if (parsedData.success) {
            setErrors({});
            return true;
        }

        if (parsedData.error) {
            setErrors(parsedData.error.flatten().fieldErrors);
        }

        return false;
    };

    const handleOnClick = () => {
        if (validate()) {
            if (inEditMode) {
                updateCoupon(state, index);
            } else {
                addCoupon(state);
            }
        }
    };

    return (
        <>
            {inEditMode ? (
                <EditCouponFormHeader
                    index={index}
                    cancel={cancel}
                    deleteCoupon={deleteCoupon}
                    handleOnClick={handleOnClick}
                />
            ) : (
                <NewCouponFormHeader handleOnClick={handleOnClick} cancel={cancel} />
            )}

            <div className="ticket-full-view-form-container">
                <div className="ticket-full-view-input-wrap">
                    <input
                        name="name"
                        type="text"
                        placeholder="Name *"
                        value={state.name}
                        onChange={e => updateSlice('name', e.target.value)}
                        className={`ticket-full-view-input ${
                            errors.name ? 'ticket-full-view-input-err' : ''
                        }`}
                        autoComplete="off"
                    />
                    {errors.name && (
                        <p className="ticket-full-view-input-error-msg">{errors.name}</p>
                    )}
                </div>
                <div className="ticket-full-view-input-wrap">
                    <input
                        name="code"
                        placeholder="CODE *"
                        value={state.code.toUpperCase()}
                        onChange={e => updateSlice('code', e.target.value.trim().toUpperCase())}
                        className={`ticket-full-view-input ${
                            errors.code ? 'ticket-full-view-input-err' : ''
                        }`}
                        autoComplete="off"
                    />
                    {errors.code && (
                        <p className="ticket-full-view-input-error-msg">{errors.code}</p>
                    )}
                </div>
                <div className="ticket-full-view-input-wrap ticket-full-view-price-input-container">
                    <p>{`${t('common:discount', 'Discount')} *`}</p>
                    <div className="ticket-full-view-price-input-wrap">
                        <input
                            name="percentage"
                            type="number"
                            placeholder="%"
                            value={state.percentage}
                            onChange={e => updateSlice('percentage', e.target.value)}
                            className={`ticket-full-view-input ${
                                errors.percentage ? 'ticket-full-view-input-err' : ''
                            }`}
                            autoComplete="off"
                        />
                        {errors.percentage && (
                            <p className="ticket-full-view-input-error-msg">{errors.percentage}</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CouponForm;
