import React from 'react';

import ExternalLink from '@web/ui/components/generic/ExternalLink';
import QuestionMark from '@dsc/svgs/QuestionMark';

import { config } from '@core/config/getConfig';

const NewSignupUserGuideButton: React.FC<{ className?: string }> = ({ className }) => {
    const userGuide = config?.public?.userGuide;

    if (!userGuide) return <></>;

    return (
        <button
            type="button"
            className={`bg-gray-900 opacity-70 p-4 flex items-center justify-center rounded-full absolute right-0 top-0 ${className}`}
        >
            <ExternalLink to={userGuide}>
                <QuestionMark className="text-white w-[25px] h-[25px]" />
            </ExternalLink>
        </button>
    );
};

export default NewSignupUserGuideButton;
