import React from 'react';
import { Updater } from 'use-immer';
import { useTranslation } from 'react-i18next';

import Toggle from '@dsc/forms/customInputs/Toggle';
import IncrementStepper from '@dsc/forms/customInputs/IncrementStepper';

import { curriedStateSlice } from '@web/utilities/helpers/state/state.helpers';

import { SurveyFormSettingsState } from '@core/types/Surveys';

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard';

type SurveyFormSettingsProps = {
    hasAdminRole?: boolean;
    state: SurveyFormSettingsState;
    setState: Updater<SurveyFormSettingsState>;
};

const SurveyFormSettings = React.forwardRef<HTMLElement, SurveyFormSettingsProps>(
    function SurveyFormSettings({ hasAdminRole, state, setState }, ref) {
        const { t } = useTranslation();

        const updateSlice = curriedStateSlice(setState);

        return (
            <section className="settings" ref={ref}>
                {hasAdminRole && (
                    <label>
                        {t(`common:${localNamespace}.postBoxUpdateForm.display_order`)}
                        <IncrementStepper
                            value={state.rankOrder}
                            onChange={updateSlice('rankOrder')}
                        />
                    </label>
                )}
                {hasAdminRole && (
                    <label>
                        {t(`common:${localNamespace}.mediaCardUpdateForm.display_in_newsfeed`)}
                        <Toggle
                            value={state.displayInNewsfeed}
                            onChange={updateSlice('displayInNewsfeed')}
                        />
                    </label>
                )}
            </section>
        );
    }
);

export default SurveyFormSettings;
