import React from 'react';

import { useGetOrderByIdQuery } from '@shared/welibrary-graphql/user/queries.hook';

import IntroContent from '@web/ui/components/generic/IntroContent';
import StripeCustomerOrderHistoryItem from '@components/profile/stripe/StripeCustomerOrderHistoryItem';

const StripeOrderDetails: React.FC<{ orderId: string; isSignupGuestCheckout?: boolean }> = ({
    orderId,
    isSignupGuestCheckout = false,
}) => {
    const { data, loading } = useGetOrderByIdQuery({
        variables: {
            _id: orderId,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const order = data?.getOrder;

    if (loading) {
        return (
            <div className="card-loading group-item">
                <strong>Loading...</strong>
            </div>
        );
    }

    return (
        <div>
            {order ? (
                <StripeCustomerOrderHistoryItem
                    key={order?._id}
                    order={order}
                    className="order-details-modal"
                    orderHistoryItemClassName={isSignupGuestCheckout ? '!mb-0' : ''}
                    isSignupGuestCheckout={isSignupGuestCheckout}
                    noShadow={isSignupGuestCheckout}
                />
            ) : (
                <div>
                    <IntroContent declaration="No Order found!" type="nocontent" />
                </div>
            )}
        </div>
    );
};

export default StripeOrderDetails;
