import React from 'react';
import GenericCardSkelly from '@web/ui/components/skeleton/GenericCardSkelly';

const SkeletonList = ({ count = 6 }) => {
    const skellies = Array(count)
        .fill()
        .map((item, index) => <GenericCardSkelly key={index} />);

    return (
        <div className="skeleton-list-container">
            <div className="skeleton-list">{skellies}</div>
        </div>
    );
};

export default SkeletonList;
