import React from 'react';
import { Updater } from 'use-immer';
import { useTranslation } from 'react-i18next';

import Toggle from '@dsc/forms/customInputs/Toggle';
import IncrementStepper from '@dsc/forms/customInputs/IncrementStepper';

import { curriedStateSlice } from '@web/utilities/helpers/state/state.helpers';

import { CourseCardSettingsState } from '@core/types/Courses';

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard';

type CourseFormSettingsProps = {
    hasAdminRole?: boolean;
    cardSettings: CourseCardSettingsState;
    setCardSettings: Updater<CourseCardSettingsState>;
};

const CourseFormSettings = React.forwardRef<HTMLElement, CourseFormSettingsProps>(
    function CourseFormSettings({ hasAdminRole, cardSettings, setCardSettings }, ref) {
        const { t } = useTranslation();

        const updateSlice = curriedStateSlice(setCardSettings);

        return (
            <section className="settings" ref={ref}>
                {hasAdminRole && (
                    <label>
                        {t(`common:${localNamespace}.postBoxUpdateForm.display_order`)}
                        <IncrementStepper
                            value={cardSettings.rankOrder}
                            onChange={updateSlice('rankOrder')}
                        />
                    </label>
                )}
                {hasAdminRole && (
                    <label>
                        {t(`common:${localNamespace}.mediaCardUpdateForm.display_in_newsfeed`)}
                        <Toggle
                            value={cardSettings.displayInNewsfeed}
                            onChange={updateSlice('displayInNewsfeed')}
                        />
                    </label>
                )}
            </section>
        );
    }
);

export default CourseFormSettings;
