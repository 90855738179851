import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuestForLoggedInUserByRainbowRoadUrlQuery } from '@shared/welibrary-graphql/quest/queries.hook';
import { useStartOrResumeQuestMutation } from '@shared/welibrary-graphql/quest/mutations.hook';

import {
    getNodesListForQuest,
    getNodesListForQuestMap,
    getActiveDialog,
    getCreations,
} from '@helpers/rainbowRoad/quest.helpers';
import { getCssVars } from '@helpers/rainbowRoad/rainbowRoadHelpers';

import {
    CARD_SUBTYPE_QUESTABLE_RAINBOW_ROAD,
    CARD_SUBTYPE_QUESTABLE_RAINBOW_ROAD_MAP,
} from '@core/utilities/constants/card_types';

const useQuest = (url: string) => {
    const history = useHistory();

    const { data, loading, refetch } = useQuestForLoggedInUserByRainbowRoadUrlQuery({
        variables: { url },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const [startOrResumeQuest] = useStartOrResumeQuestMutation({ variables: { url } });

    useEffect(() => {
        if (!data?.getQuestForLoggedInUserByRainbowRoadUrl && !loading) {
            startOrResumeQuest().then(() => refetch());
        }
    }, [data, loading]);

    if (
        !data ||
        !data.getQuestForLoggedInUserByRainbowRoadUrl ||
        !data.getQuestForLoggedInUserByRainbowRoadUrl.rainbowRoad
    ) {
        return false;
    }

    const quest = data.getQuestForLoggedInUserByRainbowRoadUrl;
    const { rainbowRoad, currentDialogEvent, creationsState, accruedResources } = quest;
    const { rainbowRoadSettings, subtype } = rainbowRoad ?? {};

    if (
        ![CARD_SUBTYPE_QUESTABLE_RAINBOW_ROAD, CARD_SUBTYPE_QUESTABLE_RAINBOW_ROAD_MAP].includes(
            subtype ?? ''
        )
    ) {
        history.push(`/r/${url}`);
    }

    const cssVars = getCssVars(rainbowRoad);
    const activeDialog = getActiveDialog(quest);
    const creations = getCreations(rainbowRoadSettings);
    const nodes =
        subtype === CARD_SUBTYPE_QUESTABLE_RAINBOW_ROAD_MAP
            ? getNodesListForQuestMap(quest)
            : getNodesListForQuest(quest, creations);

    return {
        accruedResources,
        activeDialog,
        creations,
        creationsState,
        currentDialogEvent,
        cssVars,
        isComplete: data.getQuestForLoggedInUserByRainbowRoadUrl.isComplete,
        nodes,
        quest,
        questId: data.getQuestForLoggedInUserByRainbowRoadUrl._id,
        rr: data.getQuestForLoggedInUserByRainbowRoadUrl.rainbowRoad,
    };
};

export default useQuest;
