import { ConfigInputType } from '@config/schemas/main';
import EventTypes from '@core/utilities/constants/events';

import {
    CARD_CREATION,
    CARD_STORY,
    CARD_SURVEY,
    CARD_RAINBOW_ROAD,
    CARD_DIALOG,
} from '@core/utilities/constants/card_types';

const config: ConfigInputType = {
    public: {
        root_url: 'app.welibrary.io',
        apiUrl: 'api.app.welibrary.io',
        deep_link_domains: ['https://app.welibrary.io'],

        optionalPostTypes: [CARD_CREATION, CARD_STORY, CARD_SURVEY, CARD_RAINBOW_ROAD, CARD_DIALOG],
        eventTypes: [EventTypes.EVENT_GROUP, EventTypes.EVENT_LISTING],

        configName: 'production-welibrary',
        siteName: 'WeLibrary',
        metaInfo: {
            title: 'WeLibrary',
            description:
                'WeLibrary helps you think better - organize and prioritize content by relevance, quality & sentiment.',
            image: '/images/logo.welibrary.blue.svg',
            url: 'localhost:3000',
        },
        captcha: {
            key: '6Lc8MlUiAAAAAC5sMITt02hJ_bKRE4OzhnhSiufh',
        },
        displayPlatformFormspec: true,
        copyright: 'WeLibrary, LLC',

        filestack: { api_key: 'A7RsW3VzfSNO2TCsFJ6Eiz', filePreview: true },
        unsplash: { access_key: 'NTNacJWbx9pMW5yHizHSqWr1IpQXmTJTuQ08VZ7pSEg' },
        GATracking: { GA_ID: 'G-DXE742J9YQ' },
        Google: { Maps_API_Key: 'AIzaSyBDfzEYNC2sHOYi9OEwmwNVVtOMDFfUV5I' },
        sentry: {
            dsn: 'https://a93523418e50428c9feb61bb8de0a344@o246842.ingest.sentry.io/5199007',
        },
        firebaseConfig: [
            {
                hosts: ['welibrary.io', 'lerhub.org', 'localhost', 't3networkhub'],
                appId: 'com.welibrary.app',
                config: {
                    apiKey: 'AIzaSyAdqwUuzbZoS6s3H6HkR9nWyNPVEDcjmWQ',
                    authDomain: 'welibrary-native.firebaseapp.com',
                    databaseURL: 'https://welibrary-native.firebaseio.com',
                    projectId: 'welibrary-native',
                    storageBucket: 'welibrary-native.appspot.com',
                    messagingSenderId: '847997887601',
                    appId: '1:847997887601:web:90069dce228246d35127d0',
                    measurementId: 'G-DXE742J9YQ',
                },
            },
            {
                hosts: ['internetofeducation.org'],
                appId: 'com.internetofeducation.app',
                config: {
                    apiKey: 'AIzaSyAdqwUuzbZoS6s3H6HkR9nWyNPVEDcjmWQ',
                    authDomain: 'welibrary-native.firebaseapp.com',
                    databaseURL: 'https://welibrary-native.firebaseio.com',
                    projectId: 'welibrary-native',
                    storageBucket: 'welibrary-native.appspot.com',
                    messagingSenderId: '847997887601',
                    appId: '1:847997887601:web:3c5f63a1809f6ccf5127d0',
                    measurementId: 'G-XBT3YY8P0B',
                },
            },
            {
                hosts: ['legosuperskillslab.org'],
                appId: 'com.legossl.app',
                config: {
                    apiKey: 'AIzaSyAdqwUuzbZoS6s3H6HkR9nWyNPVEDcjmWQ',
                    authDomain: 'welibrary-native.firebaseapp.com',
                    databaseURL: 'https://welibrary-native.firebaseio.com',
                    projectId: 'welibrary-native',
                    storageBucket: 'welibrary-native.appspot.com',
                    messagingSenderId: '847997887601',
                    appId: '1:847997887601:web:3c5f63a1809f6ccf5127d0',
                    measurementId: 'G-XBT3YY8P0B',
                },
            },
        ],
        torus: {
            verifier: 'welibrary',
            network: 'testnet',
        },
        stripe: {
            client_api_key: 'pk_live_MOOuOWcMT4L5vXQ6JBTyBOiD00wuzw4AGE',
            secret_webhook_key: 'whsec_ZnfVOlbs4vfd0FzGYFcvxbAW6xrRJDdO',
        },
        defaultMembershipUrl: 'welibrary',
        restrictSignupToDefaultMembership: false,

        privacyPolicy: 'www.welibrary.io/privacy-policy',
        termsOfService: 'www.learningeconomy.io/terms-of-service',
        templatesUrl: 'templates-64121',

        defaultGroupChannelTemplateUrl: 'ZeJSZ2My9ggdJ9bym',
        defaultChannelTemplateUrl: 'dw9NQGnvfjRWcF96P',

        defaultLogoBackup: 'https://cdn.filestackcontent.com/4R8SRB6PRdyGXDP4ZZUn',
        defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/TmGQQbJ7TKFbJcINLNQZ',
        defaultLogoBackupWhiteAlt: '',
        defaultLogoBlack: 'https://cdn.filestackcontent.com/PX17Zaw5TpyrJAdvu8bM',

        pushSenderID: 847997887601,
        pushLogo: 'https://www.welibrary.io/native/welibrary/notifications/we_notification.png',

        recommendationsMatchmakerWhitelist: [],

        userSnap: false,
        userSnapTesterEmails: [
            'chris@ed30.com',
            'jackson@learningeconomy.io',
            'johnlenac@gmail.com',
            'chris@ed40.com',
        ],

        bulkUserTemplate:
            'https://docs.google.com/spreadsheets/d/1kkqURN4VHJuj6KZVMMSRRuWGdV_KvdWz5BeoYj9q1SQ/edit?usp=sharing',
        bulkGenerateUserTemplate:
            'https://docs.google.com/spreadsheets/d/1LSN6MLgMmyHToHGpA4EEHZFtGPjLWxn3GsmbgM71Rdw/edit?usp=sharing',

        brandColor1: '#0094f6',
        brandColor2: '#7a0cff',

        companyAddress: '406 Lazy Creek Lane, Nashville, TN, 37211',

        pwaServiceWorker: '/sw.js',

        domainBranding: {
            welibrary: {
                root_url: 'app.welibrary.io',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/GAbl7rmQQvmhUfQ1OxaH',
            },
            'lerhub.org': {
                root_url: 'lerhub.org',
                siteName: 'LER Hub',
                emailFrom: 'T3 Innovation Network <no-reply@welibrary.io>',

                defaultLogoBackup: 'https://cdn.filestackcontent.com/ixldHUo4QViKY9ldt255',
                defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/ixldHUo4QViKY9ldt255',
                defaultLogoBlack: 'https://cdn.filestackcontent.com/ZsEd7qlgS7a5ZLjHTpEV',

                metaInfo: {
                    title: 'LER Hub',
                    description:
                        'The Learning and Employment Records Resource Hub by the T3 Innovation Network provides a variety of resources to support organizations implementing LERs as well as a community for learning and sharing best practices.',
                    image: 'https://cdn.filestackcontent.com/ZsEd7qlgS7a5ZLjHTpEV',
                    url: 'lerhub.org',
                },

                privacyPolicy: 'www.uschamber.com/privacy-policy',

                brandColor1: '#103B5C',
                brandColor2: '#5acae8',

                copyright: 'T3 Innovation Network',
                companyAddress: '1133 21st St NW, Washington, D.C.',

                defaultGroupCode: 'LERHUB',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/9AiBMl6ATvKvsQTwvt6p',

                disableServiceWorker: true,

                favicon: '/images/favicon/t3/favicon.ico',

                root_redirect: '/g/bqCgme2fQxDbgJ6D7',
                root_redirect_loggedin: '/dashboard',

                slideMenu: { search: false },

                secondaryNavigation: {
                    items: {
                        mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                        desktop: ['groups', 'home', 'messages', 'notifications', 'profile'],
                    },
                },

                customErrorMessages: {
                    signupEmailAlreadyExists:
                        'Email already exists. If you already have an T3networkhub.org or IoE account, you can login with that email.',
                    signupPhoneAlreadyExists:
                        'Phone already exists. If you already have an T3networkhub.org or IoE account, you can login with your phone number.',
                },

                GATracking: { GA_ID: 'G-KK900SXC63' },

                disablePersistedCache: true,
            },
            t3networkhub: {
                root_url: 'app.t3networkhub.org',
                siteName: 'T3 Network Hub',
                emailFrom: 'T3 Innovation Network <no-reply@welibrary.io>',

                defaultLogoBackup: 'https://cdn.filestackcontent.com/ixldHUo4QViKY9ldt255',
                defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/ixldHUo4QViKY9ldt255',
                defaultLogoBlack: 'https://cdn.filestackcontent.com/ZsEd7qlgS7a5ZLjHTpEV',

                metaInfo: {
                    title: 'T3 Network Hub',
                    description:
                        'The T3 Innovation Network Hub provides a variety of resources to support organizations implementing LERs as well as a community for learning and sharing best practices.',
                    image: 'https://cdn.filestackcontent.com/ZsEd7qlgS7a5ZLjHTpEV',
                    url: 'app.t3networkhub.org',
                },

                privacyPolicy: 'www.uschamber.com/privacy-policy',
                termsOfService: 'www.t3networkhub.org/terms-of-service',

                signupToggles: { hidePrivacyStep: true, hideAgeConsent: true },

                brandColor1: '#103B5C',
                brandColor2: '#5acae8',

                copyright: 'T3 Innovation Network',
                companyAddress: '1133 21st St NW, Washington, D.C.',

                defaultGroupCode: 'T3NETWORK',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/9AiBMl6ATvKvsQTwvt6p',

                disableServiceWorker: true,

                favicon: '/images/favicon/t3/favicon.ico',

                root_redirect: '/g/iXficBToArKnvd8dv',
                root_redirect_loggedin: '/dashboard',

                slideMenu: { search: false },

                secondaryNavigation: {
                    items: {
                        mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                        desktop: [
                            'groups',
                            'home',
                            'messages',
                            'discover',
                            'notifications',
                            'profile',
                        ],
                    },
                },

                customErrorMessages: {
                    signupEmailAlreadyExists:
                        'Email already exists. If you already have an lerhub.org or IoE account, you can login with that email.',
                    signupPhoneAlreadyExists:
                        'Phone already exists. If you already have an lerhub.org or IoE account, you can login with your phone number.',
                },

                GATracking: { GA_ID: 'UA-44515710-28' },

                disablePersistedCache: true,
            },
            internetofeducation: {
                root_url: 'app.internetofeducation.org',
                siteName: 'Internet of Education',
                emailFrom: 'Internet of Education <no-reply@welibrary.io>',

                defaultLogoBackup: 'https://cdn.filestackcontent.com/ToXAY8i3TWCkJxPgi15g',
                defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/fORSVkqKRgqAm7eQ2xoW',
                defaultLogoBlack: 'https://cdn.filestackcontent.com/rBkM4keCTAueW4d3Fpj4',

                metaInfo: {
                    title: 'Internet of Education',
                    description: 'Ed 3.0 Co-Lab in a Box. Community. Research. Productivity.',
                    image: 'https://cdn.filestackcontent.com/JGFtsqlSQlaqm34J2M7M',
                    url: 'app.internetofeducation.org',
                },

                privacyPolicy: 'www.welibrary.io/privacy-policy',
                termsOfService: 'www.learningeconomy.io/terms-of-service',

                brandColor1: '#ffeb3b',
                brandColor2: '#000000',

                copyright: 'Learning Economy',

                defaultGroupCode: 'IOEDU',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/JGFtsqlSQlaqm34J2M7M',

                disableServiceWorker: true,

                favicon: '/images/favicon/ioe/favicon.ico',

                root_redirect: '/g/u3ddFa6AgBLwmETdB',
                root_redirect_native: '/signup',
                root_redirect_loggedin: '/dashboard',

                slideMenu: { search: false },

                secondaryNavigation: {
                    items: {
                        mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                        desktop: ['home', 'messages', 'search', 'notifications', 'profile'],
                    },
                },

                customErrorMessages: {
                    signupEmailAlreadyExists:
                        'Email already exists. If you already have an lerhub.org or T3networkhub.org account, you can login with that email.',
                    signupPhoneAlreadyExists:
                        'Phone already exists. If you already have an lerhub.org or T3networkhub.org account, you can login with your phone number.',
                },

                GATracking: { GA_ID: 'G-XBT3YY8P0B' },
            },
            legossl: {
                root_url: 'app.legosuperskillslab.org',
                siteName: 'Lego Super Skills Lab',
                emailFrom: 'Lego Super Skills Lab <no-reply@welibrary.io>',

                defaultLogoBackup: '/images/IoE.logo.brandmark black.svg',
                defaultLogoBackupWhite: '/images/IoE.logo.brandmark.svg',
                defaultLogoBlack: '/images/ioe.logo.full.black.svg',

                privacyPolicy: 'www.welibrary.io/privacy-policy',
                termsOfService: 'www.learningeconomy.io/terms-of-service',

                brandColor1: '#ffeb3b',
                brandColor2: '#000000',

                copyright: 'Learning Economy',

                defaultGroupCode: 'IOEDU',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/JGFtsqlSQlaqm34J2M7M',

                disableServiceWorker: true,

                favicon: '/images/favicon/ioe/favicon.ico',

                root_redirect: '/g/u3ddFa6AgBLwmETdB',
                root_redirect_native: '/signup',
                root_redirect_loggedin: '/dashboard',

                slideMenu: { search: false },

                secondaryNavigation: {
                    items: {
                        mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'],
                        desktop: ['home', 'messages', 'discover', 'notifications', 'profile'],
                    },
                },

                customErrorMessages: {
                    signupEmailAlreadyExists:
                        'Email already exists. If you already have an lerhub.org or T3networkhub.org account, you can login with that email.',
                    signupPhoneAlreadyExists:
                        'Phone already exists. If you already have an lerhub.org or T3networkhub.org account, you can login with your phone number.',
                },

                GATracking: { GA_ID: 'G-XBT3YY8P0B' },
            },
        },

        slideMenu: { search: true, discover: true, rainbowRoad: true },

        locize: {
            projectId: '662a9e61-2900-409c-a73e-6ba0bbfc3f38',
            apiKey: '3ff8b824-d205-42ea-be99-a366868d9f73',
            referenceLng: 'en',
        },

        ceramic: {
            nodeEndpointUrl: 'https://ceramic-node.welibrary.io:7007',
            contentFamily: 'SuperSkills',
            definitions: {
                MyVerifiableCredentials:
                    'kjzl6cwe1jw14am5tu5hh412s19o4zm8aq3g2lpd6s4paxj2nly2lj4drp3pun2',
            },
            schemas: {
                AchievementVerifiableCredential:
                    'ceramic://k3y52l7qbv1frylibw2725v8gem3hxs1onoh6pvux0szdduugczh0hddxo6qsd6o0',
                VerifiableCredentialsList:
                    'ceramic://k3y52l7qbv1frxkcwfpyauky3fyl4n44izridy3blvjjzgftis40sk9w8g3remghs',
            },
        },

        appflow: {
            liveUpdate: {
                appId: 'ff5def27',
                channel: 'production-welibrary',
                updateMethod: 'none',
            },
        },

        awsMetrics: {
            namespace: 'WeLibrary/Client',
        },

        launchDarklyConfig: {
            clientSideID: '64514ca1c158f01311e773af',
            context: {
                kind: 'user',
                key: 'anonymous',
            },
        },
    },
};

export default config;
