import React from 'react';
import { Link } from 'react-router-dom';

import useModal from '@components/modals/hooks/useModal';

import {
    ActionButton,
    CollectionThumb,
    ThumbActionButton,
} from '@components/search/searchcard/components';

type AlternateSearchCardViewProps = {
    handleCloseSearch: () => void;
    title: string;
    titleUrl: string;
    cardId: string;
    type?: string;
    thumbImage: string;
    handleAction: (action: string) => any;
    hideInsideAction: boolean;
    renderItemInfo: JSX.Element;
    description?: string;
};

const AlternateSearchCardView: React.FC<AlternateSearchCardViewProps> = ({
    handleCloseSearch,
    title,
    titleUrl,
    cardId,
    type,
    thumbImage,
    handleAction,
    hideInsideAction,
    renderItemInfo,
    description,
}) => {
    const { closeAllModals } = useModal();

    const handleClick = () => {
        closeAllModals();
        handleCloseSearch();
    };

    return (
        <div className="search-result-item search-result-block-item">
            <div className="search-result-thumb-containter">
                <ThumbActionButton
                    title={title}
                    cardId={cardId}
                    type={type}
                    hideInsideAction={hideInsideAction}
                />
                <Link to={titleUrl} onClick={handleClick}>
                    <CollectionThumb thumbImage={thumbImage} randomImageSeed={cardId} />
                </Link>
            </div>
            <div className="search-result-item-middle">
                <Link to={titleUrl} onClick={handleClick} className="search-result-item-title">
                    {' '}
                    {title}
                </Link>
                {renderItemInfo}
                <p className="search-result-item-snippet">{description}</p>
            </div>
            <div className="search-result-item-right">
                <ActionButton type={type} handleAction={handleAction} />
            </div>
        </div>
    );
};

export default AlternateSearchCardView;
