import React from 'react';

import Check from '@web/ui/dsc/svgs/Check';

type CheckboxProps = {
    id?: string;
    className?: string;
    value: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
};

/**
 * Checkbox
 * See https://www.figma.com/file/naUyXB9d4H9XG1tuFkgtbq/weLibrary-Design-Systems?node-id=982%3A5900
 */
const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(function Checkbox(
    { id, className = '', value = false, onChange, disabled = false },
    ref
) {
    return (
        <button
            type="button"
            role="checkbox"
            aria-checked={value}
            onClick={() => onChange(!value)}
            onKeyDown={e => {
                if (e.key === ' ') onChange(!value);
            }}
            disabled={disabled}
            id={id}
            className={`dsc-forms-customInputs-checkbox ${className}`}
            ref={ref}
        >
            <Check />
        </button>
    );
});

export default Checkbox;
