import React from 'react';

const Check: React.FC<{ className?: string }> = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 500 500">
        <path
            stroke="currentcolor"
            fill="currentcolor"
            d="M85.23,217.2a21.76,21.76,0,0,1,17.54,7.11c27.88,28.44,56,56.22,84.11,84.29,5.59,5.5,5.59,5.5,10.05-1,20.67-30.63,41.24-61.35,61.91-91.88q37.26-55,74.72-109.8C352,78.76,370.25,51.65,388.74,24.72a24.46,24.46,0,0,1,34-6.49l.06,0c12.8,8.53,25.41,17.07,37.93,25.79a24.37,24.37,0,0,1,9.75,33.05,24,24,0,0,1-2.36,3.55q-36.41,54-72.72,108.09C378.69,213.4,361.72,238,345,262.61c-26.17,38.59-52,77.28-78.32,115.68q-18.48,27.12-37.92,53.57a33.57,33.57,0,0,1-43.9,8.16A102.89,102.89,0,0,1,168,426.93L35.26,294.19a25,25,0,0,1-2.62-35.31,27,27,0,0,1,2.62-2.62c10.62-10.62,21.24-21.33,32-31.95A22.24,22.24,0,0,1,85.23,217.2Z"
        />
    </svg>
);

export default Check;
