import React, { useState } from 'react';
import Stories from 'react-insta-stories';

import X from '@dsc/svgs/FullSizeX';

import { Maybify } from '@core/types/Utilities';
import { ContentCard } from '@shared/welibrary-graphql/types';

const storyStyles = {
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
};

type StoryCardFullProps = {
    card: Maybify<ContentCard>;
    onAllStoriesEnd?: () => void;
};

const StoryCardFull: React.FC<StoryCardFullProps> = ({ card, onAllStoriesEnd }) => {
    const simpleImageStories =
        card?.aliases?.map(alias => {
            return alias?.url;
        }) || [];

    const totalItemsCount = simpleImageStories?.length || 0;

    const [currentIndex, setCurrentIndex] = useState(0);

    const onStoryStart = (index: number) => {
        setCurrentIndex(index);
    };

    const handleNext = (index: number) => {
        if (index === totalItemsCount - 1) {
            onAllStoriesEnd?.();
        } else {
            const newIndex = index < totalItemsCount ? index + 1 : totalItemsCount;
            setCurrentIndex(newIndex);
        }
    };

    const handlePrevious = (index: number) => {
        const newIndex = index > 0 ? index - 1 : 0;
        setCurrentIndex(newIndex);
    };

    return (
        <div className="quest-story-card-container">
            <button type="button" onClick={onAllStoriesEnd} className="x-button">
                <X />
            </button>

            <Stories
                onStoryStart={onStoryStart}
                currentIndex={currentIndex}
                onAllStoriesEnd={onAllStoriesEnd}
                storyStyles={storyStyles}
                stories={simpleImageStories}
                defaultInterval={15000}
                width="100%"
                height="100vh"
            />

            <div className="story-buttons">
                <div onClick={() => handlePrevious(currentIndex)} className="story-back" />
                <div onClick={() => handleNext(currentIndex)} className="story-next" />
            </div>
        </div>
    );
};

export default StoryCardFull;
