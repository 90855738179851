import React from 'react';

import { awsMetricPublisher } from '@welibraryos/metrics';

import useModal from '@components/modals/hooks/useModal';

import TreasureNavIcon from '@dsc/svgs/lego/TreasureNavIcon';
import MiniAvatar from '@components/profile/Lego/MiniAvatar';
import SimpleProfile from '@components/profile/Lego/SimpleProfile';
import TreasureContainer from '@components/lego/treasure/TreasureContainer';

import { showNavItem } from '@helpers/navigation/secondaryNav.helpers';

const LegoNavDesktop = () => {
    const { newModal } = useModal();

    const handleSimpleProfileModal = () => {
        awsMetricPublisher.publishCount('lego-nav-menu', 1, [
            { Name: 'menu', Value: 'simple-profile' },
        ]);
        newModal(<SimpleProfile />, {
            className: 'no-top-padding no-bottom-padding no-notch full-height full-screen',
            hideButton: true,
            customCloseButton: true,
        });
    };

    const handleTreasuresModal = () => {
        awsMetricPublisher.publishCount('lego-nav-menu', 1, [
            { Name: 'menu', Value: 'treasure-chest' },
        ]);
        newModal(<TreasureContainer />, {
            className: 'no-top-padding no-bottom-padding no-notch full-height',
            sectionClassName: 'treasure-modal',
            hideButton: true,
            customCloseButton: true,
        });
    };

    return (
        <nav className="lego-desktop-nav">
            {showNavItem('simpleProfile', 'desktop') && (
                <button
                    onClick={handleSimpleProfileModal}
                    type="button"
                    className="mn-button w-inline-block mobile-hide lego-nav-desktop-icon lego-nav-btn-wrap"
                >
                    <MiniAvatar />
                </button>
            )}
            {showNavItem('treasures', 'desktop') && (
                <button
                    onClick={handleTreasuresModal}
                    type="button"
                    className="mn-button w-inline-block mobile-hide lego-nav-desktop-icon lego-nav-btn-wrap"
                >
                    <div className="lego-nav-btn">
                        <TreasureNavIcon className="lego-nav-icon lego-nav-icon-treasures" />
                    </div>
                </button>
            )}
        </nav>
    );
};

export default LegoNavDesktop;
