import React from 'react';

type ArrowProps = {
    size?: string;
    className?: string;
    direction?: 'right' | 'left';
};

const Arrow: React.FC<ArrowProps> = ({ className = '', size = '24', direction = 'right' }) => {
    return (
        <svg
            height={size}
            width={size}
            viewBox="0 0 24 24"
            className={`${className} ${direction === 'left' ? '-scale-x-100' : ''}`}
            stroke="currentcolor"
            fill="none"
        >
            <path
                d="M14.2827 5L20.9998 12L14.2827 19"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="19.7329"
                y1="12.0317"
                x2="3.99985"
                y2="12.0317"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default Arrow;
