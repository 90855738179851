import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGenerateTicketForMemberByAdminMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { useToastActionsContext } from '@components/toast/NewToastContext';
import useModal from '@components/modals/hooks/useModal';
import useConfirmation from '@components/generic/hooks/useConfirmation';
import { useCurrentUser } from '@stores/User';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
} from '@core/utilities/constants/defaults';

import Ticket from '@dsc/svgs/Ticket';
import Picture from '@web/ui/components/generic/Picture';

import { Group, Product, User } from '@shared/welibrary-graphql/types';
import AlertToast from '@components/toast/AlertToast';

import { defaultAlertToastOptions } from '@core/types/Toast';
import { getRootUrl } from '@core/utilities/whitelabel_helpers';

const localNamespace = 'imports.wlWeb.ui.components.group.groupDashboard';

const EventInviteModal: React.FC<{
    group: Group;
    tickets?: Product[];
    emails: string[];
    onClick: () => void;
    user?: User;
    showEmails?: boolean;
}> = ({ group, tickets, emails, onClick, user, showEmails = false }) => {
    const { t } = useTranslation();
    const { closeModal } = useModal();
    const confirm = useConfirmation();
    const { newToast } = useToastActionsContext();
    const { currentUser } = useCurrentUser();

    const [generateTicket] = useGenerateTicketForMemberByAdminMutation();

    const isEvent = group?.subtype === 'event';

    const host = getRootUrl();

    const handleGenerateTicket = async (productId: string) => {
        let confirmationText = t(
            `common:${localNamespace}.confirm_gifting_subscription`,
            `Are you sure you want to gift a subscription?`
        );

        if (isEvent) {
            confirmationText = t(
                `common:${localNamespace}.confirm_ticket_invite`,
                `Are you sure you want send a Ticket claim link?`
            );
        }

        const confirmed = await confirm({
            text: confirmationText,
        });

        const payload = {
            ownerId: currentUser?._id,
            groupId: group?._id,
            productId,
        };

        if (confirmed) {
            generateTicket({
                variables: {
                    input: user
                        ? {
                              ...payload,
                              userId: user?._id,
                          }
                        : {
                              ...payload,
                              email: emails?.[0],
                          },
                    host,
                },
            }).then(() => {
                setTimeout(() => {
                    closeModal();
                }, 500);

                if (isEvent) {
                    newToast(
                        <AlertToast
                            boldText={t(
                                `common:${localNamespace}.ticket_sent_success`,
                                'Ticket claim link sent successfully!'
                            )}
                            showSuccessIcon
                        />,
                        {
                            ...defaultAlertToastOptions,
                        }
                    );
                } else {
                    newToast(
                        <AlertToast
                            boldText={t(
                                `common:${localNamespace}.subscription_gifted_success`,
                                'Subscription gifted successfully!'
                            )}
                            showSuccessIcon
                        />,
                        {
                            ...defaultAlertToastOptions,
                        }
                    );
                }
            });
        }
    };

    const [src] = getBackgroundImageSetForUrl(
        user?.profile?.picture || getRandomDefaultUserImage()
    );

    let emailsToDisplay = null;

    if (emails.length > 1) {
        const emailInvitationCount = emails.length - 1;
        emailsToDisplay = (
            <div className="event-register-invite-wrap">
                <h3>{emails?.[0]}</h3>
                <span>(+{emailInvitationCount})</span>
            </div>
        );
    }

    const getRole = user => {
        if (user) {
            if (user.roleForGroup) {
                return { role: user.roleForGroup, groupId: group._id };
            }
        }
        return { role: 'none', groupId: group._id };
    };

    const defaultRole = getRole(user).role;
    let statusText = '';
    switch (defaultRole) {
        case 'owner':
            statusText = t(`common:${localNamespace}.admin`);
            break;
        case 'member':
            statusText = t(`common:${localNamespace}.member`);
            break;
        case 'invited':
            statusText = 'Invited';
            break;
        case 'requested':
            statusText = 'Requested';
            break;
        case 'none':
        default:
            statusText = t(`common:${localNamespace}.not_a_member`);
            break;
    }

    if (!group?.paymentSettings?.isGroupMembershipPaid && !group?.doesSellMembership) {
        return (
            <article className="center-modal-header-footer event-register-modal">
                <header>
                    <h3>Invite to Register!</h3>
                    <p>Share an invitation to register for this event</p>
                </header>
                <div className="event-register-invite-container">
                    {showEmails ? (
                        <ul className="event-register-email-list">
                            {emails.map((email, index) => {
                                return (
                                    <li className="event-register-email-list-item" key={index}>
                                        <p>{email}</p>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <div className="group-members-form-result">
                            <div className="profile-thumb-img">
                                <Picture url={src} disableLink resolutions={[130, 260, 390]} />
                            </div>
                            <section className="group-members-form-result-adjustment">
                                {statusText && <span>{statusText}</span>}
                                <h2>
                                    {' '}
                                    {user?.profile.full_name
                                        ? user?.profile.full_name
                                        : 'Anonymous'}
                                </h2>
                                <h3>{user?.profile.short_bio}</h3>
                            </section>
                        </div>
                    )}
                    <button type="button" onClick={onClick}>
                        Invite to Register
                    </button>
                </div>
            </article>
        );
    }

    const productTypeTitle = isEvent ? 'Ticket' : 'Subscription';
    const groupTypeText = isEvent ? 'event' : 'subscription';
    const groupFreebieDisclaimer = isEvent
        ? `This will get them into the ${groupTypeText} free! 
        They will receive a ticket to this event which they can claim.`
        : `This will grant them access to the subscription group,
        free of charge.`;

    return (
        <article className="center-modal-header-footer event-register-ticket-modal">
            <header>
                <h3>Invite to Register or Give {productTypeTitle}!</h3>
            </header>
            {emails.length > 1 && !user && (
                <div className="event-register-user-container">{emailsToDisplay}</div>
            )}
            {emails.length === 1 && !user && (
                <div className="event-register-user-container">
                    <h3>{emails?.[0]}</h3>
                </div>
            )}
            {user && (
                <div className="event-register-user-container">
                    <div className="event-register-user-wrap">
                        <div className="event-register-user-img">
                            <Picture
                                className="profile-thumb-img"
                                url={src}
                                disableLink
                                resolutions={[130, 260, 390]}
                            />
                        </div>
                        <section>
                            <h3>{user?.profile?.full_name}</h3>
                            <p>{user?.profile?.short_bio ?? user?.profile?.full_bio}</p>
                        </section>
                    </div>
                </div>
            )}
            <div className="event-register-invite-container">
                <h4>Invite to Register</h4>
                <p>Share an invitation to register for this {groupTypeText}</p>
                <button type="button" onClick={onClick}>
                    Invite to Register
                </button>
            </div>
            <div className="event-register-ticket-container">
                <h4>Give {productTypeTitle}</h4>

                {emails.length === 1 && (
                    <>
                        <p className="event-register-ticket-container-subtitle">
                            {groupFreebieDisclaimer}
                        </p>
                        <div className="event-register-ticket-container-title-wrap">
                            <p className="event-register-ticket-container-title">
                                Choose {isEvent ? 'Ticket Type' : 'Subscription'}
                            </p>
                        </div>
                        <ul className="event-register-ticket-list">
                            {tickets?.map(product => {
                                const price = Number((product.price ?? 100) / 100).toFixed(2);
                                return (
                                    <li
                                        className="event-register-ticket-list-item"
                                        key={product?._id}
                                    >
                                        <div className="ticket-quick-view">
                                            <div className="ticket-quick-view-details-wrap">
                                                <div className="ticket-quick-view-img-wrap">
                                                    <Ticket strokeColor="#fff" />
                                                </div>
                                                <div className="ticket-quick-view-info-wrap">
                                                    <h3 className="ticket-quick-view-info-name">
                                                        {product?.name}
                                                    </h3>
                                                    <p className="ticket-quick-view-info-price">
                                                        ${price}
                                                    </p>
                                                </div>
                                            </div>

                                            <button
                                                type="button"
                                                onClick={() => handleGenerateTicket(product?._id)}
                                            >
                                                Give {productTypeTitle}
                                            </button>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}

                {emails.length > 1 && (
                    <p className="event-register-warning-text">
                        You can only gift a {isEvent ? 'ticket' : 'subscription'} to one person at a
                        time.
                        <br />
                        Go back and enter one email address if you want
                        <br /> to gift a {isEvent ? 'ticket' : 'subscription'}.{' '}
                    </p>
                )}
            </div>
        </article>
    );
};

export default EventInviteModal;
