/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState} from 'react';

import ImageGrid from './ImageGrid';
import ImageSlider from './ImageSlider';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';

import useFilestack from '@web/ui/components/generic/hooks/useFilestack';

import {FilestackImage} from '@core/types/Utilities';
import {CARD_IMAGE} from '@core/utilities/constants/card_types.js';
import {AliasItem} from '@shared/welibrary-graphql/types';
import {ATTACH_IMAGE} from '@core/utilities/constants/attachment_types.js';
import {IMAGE_MIME_TYPES} from '@core/utilities/constants/filestack';

const UploadTypes = { Image: ATTACH_IMAGE } as const;
type UploadType = typeof UploadTypes[keyof typeof UploadTypes];

const CreateImageCard: React.FC<{
    state: any;
    addAttachedContent: any;
    handleImgToggle: (img?: FilestackImage | AliasItem, imgUploads?: FilestackImage[]) => void; // toggles images selected on/off
}> = ({ state, handleImgToggle, addAttachedContent }) => {
    const [rerender, setRerender] = useState<boolean>(false);

    const { data: uploadData } = state.attachedContent;

    let images: FilestackImage[] = [];

    if (uploadData?.length > 0) images = uploadData;
    else images = [uploadData];

    const onUpload = (_type: UploadType, data: any) => {
        if (data.length > 1) {
            addAttachedContent(data, ATTACH_IMAGE);
        } else {
            addAttachedContent([data], ATTACH_IMAGE);
        }

        setRerender(true);
    };

    const {
        fileSelector,
        handleFileSelect: handleImageSelect,
        isLoading: imageUploadLoading,
    } = useFilestack({
        fileType: IMAGE_MIME_TYPES,
        onUpload: (_url, _file, data) => onUpload(UploadTypes.Image, data),
    });

    const isUploading = imageUploadLoading;
    fileSelector?.setAttribute('multiple', 'multiple');

    return (
        <div className="content-card-container">
            <LoadingLine isLoading={isUploading} />
            <div className="legacy-lightbox-container">
                {/* image slider */}
                {state.imagesSelected.length > 0 && (
                    <ImageSlider images={state.imagesSelected} disableExternalLink />
                )}
                {/* image grid */}
                {images.length >= 1 && (
                    <ImageGrid
                        rerender={rerender}
                        images={images}
                        imagesSelected={state.imagesSelected}
                        handleImgToggle={handleImgToggle}
                        handleOpeningPicker={handleImageSelect}
                        mode="create"
                        type={CARD_IMAGE}
                    />
                )}
            </div>
        </div>
    );
};

export default CreateImageCard;
