import React, { useEffect, useState } from 'react';

import GroupDashboardNewsFeedContainer from '@components/group/groupdashboard/GroupDashboardNewsFeedContainer';

import { CARD_SUBTYPES } from '@core/utilities/constants/card_types';

const GroupDashboardNewsFeedTypeWrapper = ({
    group,
    sortedToc,
    whatsNewRef,
    newsfeedHeaderRef,
}) => {
    const {
        currentUserIsMember: isUserMemberOfGroup,
        currentUserIsAdmin: isUserAdminOfGroup,
        currentUserIsDirectAdmin: isUserDirectAdminOfGroup,
    } = group;

    const isMember = isUserMemberOfGroup || isUserAdminOfGroup || isUserDirectAdminOfGroup;

    const [dashfeedType, setDashfeedType] = useState(
        isMember ? CARD_SUBTYPES.DASHBOARD : CARD_SUBTYPES.PUBLIC_DASHBOARD
    );

    useEffect(() => {
        if (group) {
            if (isMember) {
                setDashfeedType(CARD_SUBTYPES.DASHBOARD);
            } else {
                setDashfeedType(CARD_SUBTYPES.PUBLIC_DASHBOARD);
            }
        }
    }, [group]);

    const handleSetDashFeed = () => {
        if (dashfeedType === CARD_SUBTYPES.DASHBOARD) {
            setDashfeedType(CARD_SUBTYPES.PUBLIC_DASHBOARD);
        } else if (dashfeedType === CARD_SUBTYPES.PUBLIC_DASHBOARD) {
            setDashfeedType(CARD_SUBTYPES.DASHBOARD);
        }
    };

    return (
        <GroupDashboardNewsFeedContainer
            group={group}
            sortedToc={sortedToc}
            whatsNewRef={whatsNewRef}
            newsfeedHeaderRef={newsfeedHeaderRef}
            dashfeedType={dashfeedType}
            setDashfeedType={handleSetDashFeed}
        />
    );
};

export default GroupDashboardNewsFeedTypeWrapper;
