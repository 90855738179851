import _ from 'lodash';

import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

/**
 * Custom Remarkable Plugin that matches [wl:GROUPCODE:Group Title:Group Thumbnail]
 *
 * To make parsing easier, we are just the handle from a filestack URL for the Group Thumbnail
 * parameter. This means that this will break in fantastic ways if something other than filestack is
 * used for that group thumbnail parameter. It may be worth looking at a better way to do this, but
 * for right now, group thumbnails are only ever going to be null or a filestack URL. Doing this
 * also means that we can easily ask filestack for a smaller image, making these group invites much
 * smaller to load than asking for, e.g., the same size image that appears at the Group Dashboard.
 */
const parseWL: Remarkable.InlineParsingRule = state => {
    const currentSpot = state.src.slice(state.pos);

    const result = currentSpot.match(/([wl:[A-Z]*:?.*])/);
    if (
        !result ||
        !result[0] ||
        result[0].indexOf('[wl:') !== 0 ||
        _.values(state.tokens).includes('wlGroupCode')
    )
        return false;

    const [groupCode, groupName, groupThumbHandle] = result[0]
        .replace('[wl:', '')
        .replace(']', '')
        .split(':');

    const groupThumb = groupThumbHandle
        ? `https://cdn.filestackcontent.com/rotate=deg:exif/resize=width:100/${groupThumbHandle}`
        : getRandomDefaultCoverImage(groupName ?? groupCode);

    // add a token to the state list
    const token: Remarkable.ContentToken = {
        type: 'wlGroupCode',
        level: state.level,
        content: {
            groupCode,
            groupName,
            groupThumb,
        },
    };

    state.pos = Math.min(state.pos + result[0].length, state.posMax);
    state.push(token);

    return true;
};

// renders a nice link to signup to a group
const renderWL: Remarkable.Rule<Remarkable.ContentToken> = (tokens, index) => {
    const {
        content: { groupCode, groupName, groupThumb },
    } = tokens[index];
    return `<a class="wl-markdown-join-group" href="/signup-group/${groupCode}">
                <span class="join-group-thumb" style="background-image: url('${groupThumb}');"></span>
                <span class="join-group-text">
                    <span class="join-group-title">Join Group</span>
                    ${groupName ?? groupCode}
                </span>
            </a>`;
};

// create a custom plugin matching :!:
const parse: Remarkable.InlineParsingRule = state => {
    // I get my character hex codes from the console using
    // "0x"+"[".charCodeAt(0).toString(16).toUpperCase()

    let { pos } = state;
    let marker = state.src.charCodeAt(state.pos);

    // Our pos starts at 0, so we are looking for :!:
    // marker starts with the character at 0
    // Given state.src :!:
    // We are here:    ^

    if (marker !== 0x3a /* : */) return false;

    pos += 1;
    marker = state.src.charCodeAt(pos);

    // Given state.src :!:
    // We are here:     ^

    if (marker !== 0x21 /* ! */) return false;

    pos += 1;
    marker = state.src.charCodeAt(pos);

    // Given state.src :!:
    // We are here:      ^

    if (marker !== 0x3a /* : */) {
        return false;
    }

    state.pos = pos + 1;

    if (state.pos > state.posMax) state.pos = state.posMax;

    // Having matched all three characters we add a token to the state list
    const token: Remarkable.ContentToken = {
        type: 'bangSmiley',
        level: state.level,
        content: marker,
    };

    state.push(token);

    return true;
};

const render: Remarkable.Rule<Remarkable.ContentToken> = (_tokens, _idx, options) => {
    let smileyString =
        '<img src="http://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Binette-typo.png/200px-Binette-typo.png" alt="" width="20" height="20"';
    smileyString += options?.xhtmlOut ? '/>' : '>';

    return smileyString;
};

export const wlParser = (md: Remarkable) => {
    md.inline.ruler.push('bangSmiley', parse, {});
    md.renderer.rules.bangSmiley = render;

    md.inline.ruler.push('wlGroupCode', parseWL, {});
    md.renderer.rules.wlGroupCode = renderWL;
};
