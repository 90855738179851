import React from 'react';
import { Flipper } from 'react-flip-toolkit';

import Button from '@web/ui/dsc/forms/buttons/Button';
import Arrow from '@web/ui/dsc/svgs/Arrow';

type HorizontalNavHeaderProps = {
    currentPage: number;
    pages: boolean[];
    mainAction?: () => void;
    canDoMainAction?: boolean;
    goBack?: () => void;
    canGoBack?: boolean;
    goForward?: () => void;
    canGoForward?: boolean;
    buttonText?: string;
    showArrows?: boolean;
    showForwardButton?: boolean;
    showBackButton?: boolean;
    showSettingsButton?: boolean;
    settingsAction?: () => void;
    className?: string;
    tag?: keyof JSX.IntrinsicElements;
    showSave?: boolean;
    onSave?: () => void;
    saveText?: string;
};

const HorizontalNavFooter: React.FC<HorizontalNavHeaderProps> = ({
    currentPage,
    pages,
    mainAction = () => {},
    canDoMainAction = true,
    goBack = () => {},
    canGoBack = true,
    goForward = () => {},
    canGoForward = true,
    buttonText,
    showArrows = true,
    showForwardButton = true,
    showBackButton = true,
    showSettingsButton = false,
    settingsAction = () => {},
    className = '',
    tag = 'footer',
    showSave,
    onSave,
    saveText = 'Save',
}) => {
    const isLastPage = currentPage === pages.length - 1;

    return (
        <Flipper
            element={tag}
            flipKey={`${currentPage}-${pages[currentPage]}-${canDoMainAction}-${canGoBack}-${canGoForward}`}
            className={`dsc-navigation-horizontal-nav-footer ${className}`}
        >
            <div className="left-side">
                {showArrows && showBackButton && currentPage > 0 && (
                    <Button
                        type="icon"
                        className="back-button"
                        onClick={goBack}
                        disabled={!canGoBack}
                        flipId="back-button"
                    >
                        <Arrow />
                    </Button>
                )}
                {showSettingsButton && settingsAction && (
                    <Button
                        type="icon"
                        className="toggle-settings"
                        onClick={settingsAction}
                        flipId="toggle-settings-button"
                    ></Button>
                )}

                {showSave && onSave && !isLastPage && (
                    <Button
                        type="medium"
                        className="save-button"
                        onClick={onSave}
                        flipId="save-button"
                    >
                        {saveText}
                    </Button>
                )}
            </div>

            {pages[currentPage] && (
                <Button
                    type="medium"
                    onClick={mainAction}
                    disabled={!canDoMainAction}
                    flipId="main-action-button"
                >
                    {buttonText}
                </Button>
            )}

            {showArrows && showForwardButton && !isLastPage && (
                <Button
                    type="icon"
                    className="forward-button"
                    onClick={goForward}
                    disabled={!canGoForward}
                    flipId="forward-button"
                >
                    <Arrow />
                </Button>
            )}
        </Flipper>
    );
};

export default HorizontalNavFooter;
