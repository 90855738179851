import React from 'react';
import { SubscriptionStatus } from '@components/group/groupdashboard/groupSubscriptionPaywall/groupSubscriptionPaywallHelpers';
import { useStripe } from '@stripe/react-stripe-js';

import {
    useRetryInvoiceMutation,
    useCancelSubscriptionForGroupAtPeriodEndMutation,
    useRefundAndCancelGroupSubscriptionMutation,
} from '@shared/welibrary-graphql/user/mutations.hook';

import useConfirmation from '@components/generic/hooks/useConfirmation';
import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import SubscriptionRenewalConfirmation from '@components/group/groupdashboard/groupSubscriptionPaywall/SubscriptionRenewalConfirmation';
import AlertToast from '@components/toast/AlertToast';

import { CustomerOrder } from '@shared/welibrary-graphql/types';
import { GET_USER_ORDERS_FOR_GROUP, GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';

import { ModalTypes } from '@core/types/Modals';

import { defaultAlertToastOptions } from '@core/types/Toast';

import getLogger from '@core/logger';

const logger = getLogger(module);

export const useGroupSubscriptionAction = (
    order: CustomerOrder
): { label: string; subscriptionAction: () => void } => {
    const { currentUser } = useCurrentUser();
    const { newModal, closeAllModals } = useModal({
        desktop: ModalTypes.Center,
        mobile: ModalTypes.Center,
    });
    const { newToast } = useToastActionsContext();
    const confirm = useConfirmation();
    const stripe = useStripe();

    const subscription = order?.subscription;

    const [retryInvoice, { loading, error }] = useRetryInvoiceMutation();
    const [cancelSubscription, { loading: cancelLoading, error: cancelError }] =
        useCancelSubscriptionForGroupAtPeriodEndMutation();

    const [cancelAndRefundSubscription, { loading: cancelAndRefundLoading }] =
        useRefundAndCancelGroupSubscriptionMutation();

    const handleCancelMemberShip = () => {
        // cancels a subscription prior to
        // reaching the end period
        confirm({
            text: 'Are you sure want to cancel your subscription?',
            onConfirm: () => {
                cancelSubscription({
                    variables: {
                        subscriptionId: subscription?.id,
                        cancelAtPeriodEnd: true,
                    },
                    refetchQueries: [GET_USER_ORDERS_FOR_GROUP, GET_GROUP_DASHBOARD],
                })
                    .then(({ data }) => {
                        if (data?.cancelSubscriptionForGroupAtPeriodEnd) {
                            // delay showing the success toast after the confirmation modal closes
                            setTimeout(() => {
                                newToast(
                                    <AlertToast
                                        boldText="Subscription successfully canceled!"
                                        showSuccessIcon
                                    />,
                                    {
                                        ...defaultAlertToastOptions,
                                    }
                                );
                            }, 1500);
                        }
                    })
                    .catch(err => {
                        logger.error(err);
                    });
            },
        });
    };

    const handleRefundAndCancelMembership = async () => {
        // cancels a subscription and issues a refund immediately
        if (
            await confirm({
                text: 'Are you sure want to cancel your subscription? You will be removed from the group and a refund will be issued if applicable.',
            })
        ) {
            cancelAndRefundSubscription({
                variables: {
                    userId: currentUser?._id,
                    groupId: order?.purchaseRequests?.[0]?.product?.fulfillment?.groupId,
                },
                refetchQueries: [GET_USER_ORDERS_FOR_GROUP, GET_GROUP_DASHBOARD],
            })
                .then(({ data }) => {
                    if (data?.refundAndCancelGroupSubscription) {
                        closeAllModals();
                        // delay showing the success toast after the confirmation modal closes
                        setTimeout(() => {
                            newToast(
                                <AlertToast
                                    boldText="Subscription successfully canceled!"
                                    showSuccessIcon
                                />,
                                {
                                    ...defaultAlertToastOptions,
                                }
                            );
                        }, 1500);
                    }
                })
                .catch(err => {
                    logger.error(err);
                });
        }
    };

    const handleReactivateCancelledSubscription = () => {
        // Re-activates a subscription that was cancelled
        // prior to reaching the end period
        confirm({
            text: 'Are you sure want reactivate your subscription?',
            onConfirm: () => {
                cancelSubscription({
                    variables: {
                        subscriptionId: subscription?.id,
                        cancelAtPeriodEnd: false,
                    },
                    refetchQueries: [GET_USER_ORDERS_FOR_GROUP, GET_GROUP_DASHBOARD],
                })
                    .then(({ data }) => {
                        if (data?.cancelSubscriptionForGroupAtPeriodEnd) {
                            // delay showing the success toast after the confirmation modal closes
                            setTimeout(() => {
                                newToast(
                                    <AlertToast
                                        boldText="Subscription successfully reactivated!"
                                        showSuccessIcon
                                    />,
                                    {
                                        ...defaultAlertToastOptions,
                                    }
                                );
                            }, 1500);
                        }
                    })
                    .catch(err => {
                        logger.error(err);
                    });
            },
        });
    };

    const handleRenewMembership = async () => {
        if (!stripe) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        retryInvoice({
            variables: {
                invoiceId: order?.subscriptionInvoices?.results?.[0]?.id,
                paymentMethodId: currentUser?.paymentSettings?.stripeCustomer?.default_source,
            },
            refetchQueries: [GET_USER_ORDERS_FOR_GROUP, GET_GROUP_DASHBOARD],
        })
            .then(({ data }) => {
                newModal(
                    <SubscriptionRenewalConfirmation
                        clientSecret={data?.retryInvoice?.payment_intent?.client_secret}
                        order={order}
                    />,
                    {
                        hideButton: true,
                        className: 'confirmation-modal subs-renewal-confirmation',
                        sectionClassName: 'confirmation-modal-section',
                        widen: false,
                        addShadow: false,
                    }
                );
            })
            .catch(err => {
                logger.error(err);
            });
    };

    if (
        subscription?.status === SubscriptionStatus.Active &&
        order?.subscriptionUpcomingInvoice?.period_end
    ) {
        return {
            label: cancelAndRefundLoading ? `Loading...` : 'Cancel Subscription',
            subscriptionAction: handleRefundAndCancelMembership,
        };
    }

    if (
        subscription?.status === SubscriptionStatus.Active &&
        !order?.subscriptionUpcomingInvoice?.period_end
    ) {
        return {
            label: cancelLoading ? `Loading...` : 'Reactivate Subscription',
            subscriptionAction: handleReactivateCancelledSubscription,
        };
    }

    if (
        subscription?.status === SubscriptionStatus.PastDue ||
        subscription?.status === SubscriptionStatus.Unpaid
    ) {
        return {
            label: loading ? 'Loading...' : 'Renew membership',
            subscriptionAction: handleRenewMembership,
        };
    }

    return {
        label: '',
        subscriptionAction: () => {},
    };
};

export default useGroupSubscriptionAction;
