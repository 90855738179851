import React from 'react';

type CalendarProps = {
    className?: string;
};

const Calendar: React.FC<CalendarProps> = ({ className = '' }) => {
    return (
        <svg fill="currentcolor" viewBox="0 0 500 500" className={className}>
            <rect x="126.78" y="43" width="32.01" height="50.07" />
            <path d="M397.23,64.81v47.36H320.41V64.81H181.19v47.36H104.37V64.81H46.76V460.23H341.22l112-106.41v-289Zm17.6,274.32H333.22L338,413.43H98V171H414.83Z" />
            <rect x="344.42" y="39.77" width="32.01" height="50.07" />
        </svg>
    );
};

export default Calendar;
