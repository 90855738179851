import React from 'react';
import { useTranslation } from 'react-i18next';

import IntroContent from '@web/ui/components/generic/IntroContent';
import GroupMemberSearchResult from '@web/ui/components/group/groupdashboard/GroupMembers/GroupMemberSearchResult';

import { Group, Maybe, PreAuthPaymentMember } from '@shared/welibrary-graphql/types';

const GroupPreAuthPaymentMembers: React.FC<{
    group: Group;
    memberPreAuthPayments?: Maybe<Array<Maybe<Partial<PreAuthPaymentMember>>>>;
    onNetworkSearchAction: () => void;
    canUserInviteOthers: Maybe<string | boolean | undefined>;
    handleLinkSharing: () => void;
}> = ({
    memberPreAuthPayments,
    group,
    onNetworkSearchAction,
    canUserInviteOthers,
    handleLinkSharing,
}) => {
    const { t } = useTranslation();
    const localNamespace =
        group?.subtype === 'event'
            ? 'imports.wlWeb.ui.components.group.eventDashboard'
            : 'imports.wlWeb.ui.components.group.groupDashboard';

    const inviteText = t(`common:${localNamespace}.invite_members`);

    return (
        <ul>
            {memberPreAuthPayments?.length > 0 ? (
                memberPreAuthPayments?.map((member, index) => {
                    const { user } = member ?? {};

                    if (!user) return <></>;

                    return (
                        <GroupMemberSearchResult
                            key={`profile-cards-${index}`}
                            group={group}
                            member={user}
                            onUpdateUserState={() => onNetworkSearchAction()}
                            preAuthPayment
                        />
                    );
                })
            ) : (
                <IntroContent
                    declaration={t('global.introContentErr.no_requests_to_join.declaration')}
                    type="nocontent"
                >
                    {canUserInviteOthers && (
                        <button
                            onClick={handleLinkSharing}
                            type="button"
                            className="group-members-invite-link-btn"
                        >
                            {inviteText}
                        </button>
                    )}
                </IntroContent>
            )}
        </ul>
    );
};

export default GroupPreAuthPaymentMembers;
