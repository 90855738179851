/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState} from 'react';

import ImageGrid from './ImageGrid';
import ImageSlider from './ImageSlider';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';

import useFilestack from '@web/ui/components/generic/hooks/useFilestack';

import {FilestackImage} from '@core/types/Utilities';
import {AliasItem} from '@shared/welibrary-graphql/types';
import {CARD_IMAGE} from '@core/utilities/constants/card_types.js';
import {ATTACH_IMAGE} from '@core/utilities/constants/attachment_types.js';
import {IMAGE_MIME_TYPES} from '@core/utilities/constants/filestack';

const UploadTypes = { Image: ATTACH_IMAGE } as const;
type UploadType = typeof UploadTypes[keyof typeof UploadTypes];

const EditImageCard: React.FC<{
    state: any; // card state,
    aliases: AliasItem[]; // images stored/selected
    handleImgToggle: (img?: FilestackImage | AliasItem, imgUploads?: FilestackImage[]) => void; // toggles images selected on/off
}> = ({ state, aliases, handleImgToggle }) => {
    const [images, setImages] = useState<FilestackImage[]>([]);

    const onUpload = (_type: UploadType, data: any) => {
        if (data.length > 1) setImages(data);
        else setImages([data]);
    };

    const {
        fileSelector,
        handleFileSelect: handleImageSelect,
        isLoading: imageUploadLoading,
    } = useFilestack({
        fileType: IMAGE_MIME_TYPES,
        onUpload: (_url, _file, data) => onUpload(UploadTypes.Image, data),
    });

    let imagesSelected: AliasItem[] = [];

    if (aliases.length === 0) {
        // if there is no aliases, create an aliasItem from the stored mediaThumb
        imagesSelected = [
            {
                thumb: '',
                title: null,
                type: 'alias_image',
                url: state.mediaThumb,
            },
        ];
    } else if (aliases.length >= 1) {
        imagesSelected = aliases;
    }

    const isUploading = imageUploadLoading;
    fileSelector?.setAttribute('multiple', 'multiple');

    return (
        <div className="content-card-container">
            <LoadingLine isLoading={isUploading} />
            <div className="legacy-lightbox-container">
                {/* image slider */}
                {imagesSelected.length > 0 && (
                    <ImageSlider aliases={imagesSelected} disableExternalLink />
                )}
                {/* image grid */}
                {imagesSelected.length >= 1 && (
                    <ImageGrid
                        rerender
                        aliases={aliases}
                        images={images}
                        imagesSelected={imagesSelected}
                        handleImgToggle={handleImgToggle}
                        handleOpeningPicker={handleImageSelect}
                        type={CARD_IMAGE}
                        mode="edit"
                    />
                )}
            </div>
        </div>
    );
};

export default EditImageCard;
