import { ConfigInputType } from '@config/schemas/main';
import EventTypes from '@core/utilities/constants/events';

import {
    CARD_CREATION,
    CARD_STORY,
    CARD_SURVEY,
    CARD_RAINBOW_ROAD,
    CARD_DIALOG,
} from '@core/utilities/constants/card_types';

// Eventually we'll seed the main quest, but until then...
// For this to work, you need a rainbow road card in your local db titled "Rebuild a Forgotten City"
//   In any channel -> enable Rainbow Road post type -> Create Rainbow Road post (it's the camera icon) ->
//   title it "Rebuild a Forgotten City" -> Don't need to enter anything else (unless you want some content), just post it.
// Now you should be able to run "npm run start-ssl"!

const config: ConfigInputType = {
    public: {
        root_url: 'localhost:3000',
        apiUrl: 'localhost:4000',
        siteName: 'WeLibrary',
        captcha: {
            key: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        },
        configName: 'development-ssl',
        copyright: 'WeLibrary, LLC',

        root_redirect: '/landing/ssl',
        root_redirect_loggedin: '/my-quests',
        private_route_redirect: '/landing/ssl',
        logout_redirect: '/landing/ssl',
        main_quest_url: 'rebuild-a-forgotten-city',
        main_quest_map: 'super-skills-map',

        universal_access_code: 'PLAY2LEARN',

        hideClaimEphemeralAccountToast: true,

        optionalPostTypes: [CARD_CREATION, CARD_STORY, CARD_SURVEY, CARD_RAINBOW_ROAD, CARD_DIALOG],
        eventTypes: [EventTypes.EVENT_GROUP, EventTypes.EVENT_LISTING],
        metaInfo: {
            title: 'WeLibrary',
            description:
                'WeLibrary helps you think better - organize and prioritize content by relevance, quality & sentiment.',
            image: '/images/logo.welibrary.blue.svg',
            url: 'localhost:3000',
        },
        filestack: { api_key: 'AWnnnSe6qS8aHSc5KK4Zuz' },
        unsplash: { access_key: 'NTNacJWbx9pMW5yHizHSqWr1IpQXmTJTuQ08VZ7pSEg' },
        GATracking: { GA_ID: 'G-FT25RRYQT3' },
        Google: { Maps_API_Key: 'AIzaSyBDfzEYNC2sHOYi9OEwmwNVVtOMDFfUV5I' },
        sentry: { dsn: 'https://2e9ae6c6eba5493fac642cb40304989e@sentry.io/1427122' },
        userflow: { api_key: 'ct_w53eaxhevvf2vejzrecekeq3nu' },
        showGettingStarted: true,
        firebaseConfig: {
            apiKey: 'AIzaSyAdqwUuzbZoS6s3H6HkR9nWyNPVEDcjmWQ',
            authDomain: 'welibrary-native.firebaseapp.com',
            databaseURL: 'https://welibrary-native.firebaseio.com',
            projectId: 'welibrary-native',
            storageBucket: 'welibrary-native.appspot.com',
            messagingSenderId: '847997887601',
            appId: '1:847997887601:web:90069dce228246d35127d0',
            measurementId: 'G-DXE742J9YQ',
        },
        torus: {
            verifier: 'staging-welibrary',
            network: 'testnet',
        },
        stripe: {
            client_api_key: 'pk_test_S9NV67ltFVkEFi1X7VfMwG0N0016slSnqr',
            secret_webhook_key: 'whsec_TbG0nmOcyl0McacfGhdld6IDnynHN52K',
        },

        defaultMembershipUrl: 'welibrary',
        restrictSignupToDefaultMembership: false,

        privacyPolicy: 'www.learningeconomy.io/privacy-policy',
        termsOfService: 'www.learningeconomy.io/terms-of-service',
        templatesUrl: 'templates',

        defaultLogoBackup: '/images/logo.welibrary.full.white.svg',
        defaultLogoBackupWhite: '/images/logo.welibrary.blue.white.svg',
        defaultLogoBackupWhiteAlt: '',
        defaultLogoBlack: '/images/logo.welibrary.blue.svg',

        homeButton: {
            picture: '/images/defaults/cover_images/CoverPhoto-16.jpg',
            title: 'Sample Group',
            groupId: 'RqJFHstXEK46RJrtQ',
        },
        domainBranding: {},

        pushSenderID: 847997887601,
        pushLogo: 'we_notification',

        recommendationsMatchmakerWhitelist: ['friendship-library', 'library-of-matching'],

        userSnap: true,
        userSnapTesterEmails: [],

        bulkUserTemplate:
            'https://docs.google.com/spreadsheets/d/1kkqURN4VHJuj6KZVMMSRRuWGdV_KvdWz5BeoYj9q1SQ/edit?usp=sharing',
        bulkGenerateUserTemplate:
            'https://docs.google.com/spreadsheets/d/1LSN6MLgMmyHToHGpA4EEHZFtGPjLWxn3GsmbgM71Rdw/edit?usp=sharing',

        brandColor1: '#0094f6',
        brandColor2: '#7a0cff',

        theme: {
            primaryColor: '#0094f6',
            primaryAccent: '#0094f61a',
            primaryShade: '#0076c5',
            thumbHighlight: '#ffffff',
            primaryHighlight: '#38b0ff',
            secondaryColor: '#7a0cff',
            slideBackground: 'url(images/menu-background-weLibrary.jpg)',
        },

        companyAddress: '406 Lazy Creek Lane, Nashville, TN, 37211',

        allowAnonymousSignups: true,

        secondaryNavigation: {
            items: {
                mobile: ['treasures', 'myQuests', 'simpleProfile'],
                desktop: ['treasures', 'simpleProfile'],
            },
        },

        slideMenu: {
            search: true,
            filterNewsfeed: true,
            discover: true,
            rainbowRoad: true,
            footerText: 'common:global.patent_pending',
        },

        locize: {
            projectId: '662a9e61-2900-409c-a73e-6ba0bbfc3f38',
            apiKey: '3ff8b824-d205-42ea-be99-a366868d9f73',
            referenceLng: 'en',
        },
        ceramic: {
            nodeEndpointUrl: 'https://ceramic-node.staging.welibrary.io:7007',
            contentFamily: 'SuperSkills-local',
            definitions: {
                MyVerifiableCredentials:
                    'kjzl6cwe1jw1461695j0dmtvjmtcjhqdtx63calspn0r5p2ti110pzbkboabozr',
            },
            schemas: {
                AchievementVerifiableCredential:
                    'ceramic://k3y52l7qbv1frybe3lenawavapwpg38ut3enaqrw03s8nemi1ycq6lvsg1nifb37k',
                VerifiableCredentialsList:
                    'ceramic://k3y52l7qbv1fryp70yk4y1xv2aedxjnkrbdezyot13gnjkyrdn6lhs1k5irkosl4w',
            },
        },

        appflow: {
            liveUpdate: {
                appId: 'ff5def27',
                channel: 'development-local',
                updateMethod: 'none',
            },
        },

        launchDarklyConfig: {
            clientSideID: '64514ca1c158f01311e773ae',
            context: {
                kind: 'user',
                key: 'anonymous',
            },
        },
    },
};

export default config;
