import { mediaRegex, mustBeAtStart } from './constants/regexes';
import {
    getLinkFromMarkdownLink,
    isMarkdownLink,
    stripAngleBrackets,
} from '@helpers/string.helpers';

// NOTE: Sometimes our editor will send links as <link.com> rather than [link.com](link.com),
// so we need to account for that! If <link.com> is sent, it will be tokenized as a text token,
// if [link.com](link.com) is sent, it will be tokenized as a link_open, text, and link_close series
// of tokens.
//
// The twitter plugin replaces the above tokens with a custom tweet token, that is then rendered as
// the expected Twitter Widget markup (see https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-parameter-reference#html-example)

/** Matches a Twitter URL in either of the formats described at the top of this file */
const twitterMarkdownLink = new RegExp(
    `(${mediaRegex.tweet.source})|(\\[${mediaRegex.tweet.source}\\]\\(${mediaRegex.tweet.source}\\))`
);

/**
 * Custom Remarkable Plugin that matches Twitter URLs and embeds the tweet
 */
const parseTwitter: Remarkable.InlineParsingRule = state => {
    const currentSpot = state.src.slice(state.pos);

    const result = currentSpot.match(mustBeAtStart(mediaRegex.tweet));

    if (!result || !result[0]) return false;

    const tweetUrl = stripAngleBrackets(result[0]).replace('x.com', 'twitter.com'); // Twitter embeds currently broken with x.com URLs 🤦

    // Create custom tweet token
    const token: Remarkable.ContentToken = {
        type: 'tweet',
        level: state.level,
        content: { tweetUrl },
    };

    // Update pos to prevent other plugins from using this URL and add token list
    state.pos = Math.min(state.pos + tweetUrl.length, state.posMax);
    state.push(token);

    return true;
};

/**
 * Custom Remarkable Plugin that replaces links to Twitter URLs and embeds the tweet
 */
const parseTwitterLinks: Remarkable.CoreParsingRule = state => {
    state.tokens.forEach((token, index) => {
        if (token.type === 'inline' && twitterMarkdownLink.test(token.content)) {
            // If the link was stored as <link.com>, we can use the tweet regex to extract it, otherwise
            // it should be a [link.com](link.com) style link, and we can extract it like any other markdown
            // link
            const tweetUrl = (
                isMarkdownLink(token.content)
                    ? getLinkFromMarkdownLink(token.content)
                    : stripAngleBrackets(token.content.match(mediaRegex.tweet)?.[0] ?? '')
            ).replace('x.com', 'twitter.com'); // Twitter embeds currently broken with x.com URLs 🤦

            // Create custom tweet token
            const newToken: Remarkable.ContentToken = {
                type: 'tweet',
                level: token.level,
                content: { tweetUrl },
            };

            // If text was solely <link.com> style URL, there would be no children and we can simply
            // replace this token with the tweet token.
            //
            // Otherwise, we need to carefully remove _only_ the link pointing to this tweet from
            // this token's children
            if (!(token as any).children) {
                state.tokens[index] = newToken;

                return;
            }

            // Remove links related to this tweet
            const filteredChildren = (token as any).children.filter(child => {
                // Twitter embeds currently broken with x.com URLs 🤦
                return child.href?.replace('x.com', 'twitter.com') !== tweetUrl;
            });

            // Replace all text tokens pointing to this tweet with a tweet token
            filteredChildren.forEach((child, childIndex) => {
                // Twitter embeds currently broken with x.com URLs 🤦
                if (child.content?.replace('x.com', 'twitter.com') === tweetUrl) {
                    filteredChildren[childIndex] = { ...newToken, level: child.level };
                }
            });

            (token as any).children = filteredChildren;
        }
    });

    return false;
};

// renders a nice embedded tweet
const renderTweet: Remarkable.Rule<Remarkable.ContentToken> = (tokens, index) => {
    const {
        content: { tweetUrl },
    } = tokens[index];

    // This function will scan the DOM for the correct markup and embed the tweet there. We must
    // wait until this gets rendered, then call to ensure that it actually works!
    setTimeout(() => {
        (window as any).twttr?.widgets?.load?.();
    }, 300);

    return `<blockquote class="twitter-tweet"><a href=${tweetUrl}></a></blockquote>`;
};

export const twitterParser = (md: Remarkable) => {
    md.inline.ruler.push('tweet', parseTwitter, {});
    md.core.ruler.push('tweet', parseTwitterLinks, {});
    md.renderer.rules.tweet = renderTweet;
};

export default twitterParser;
