import React, { useContext, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { createPortal } from 'react-dom';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import {
    SidebarActionsContext,
    SidebarContext,
} from '@web/ui/components/generic/context/SidebarContext';
import ErrorBoundary from '../generic/errors/ErrorBoundary';
import {
    TYPE_OPTIMIZE,
    TYPE_COMPONENT,
    TYPE_POSTING,
    TYPE_FORM,
    TYPE_APPLICANTS,
    TYPE_APPLICANT,
    TYPE_SUGGESTED,
    TYPE_COMMENTS,
    TYPE_PROMPTS,
    TYPE_EVENT,
    TYPE_EVENT_OLD,
} from '@web/ui/components/sidebar/sidebar_types.js';

const LoadableApplicantsView = loadable(
    () => import('@web/ui/components/card/postingcard/ApplicantsView'),
    { resolveComponent: imported => imported.default }
);

const LoadableApplicantView = loadable(
    () => import('@web/ui/components/card/postingcard/ApplicantView'),
    { resolveComponent: imported => imported.default }
);

const LoadableUserOptimizers = loadable(
    () => import('@web/ui/components/optimizers/UserOptimizers'),
    { resolveComponent: imported => imported.default }
);

const LoadablePostingFullDetails = loadable(
    () => import('@web/ui/components/card/postingcard/PostingFullDetails'),
    { resolveComponent: imported => imported.default }
);

const LoadableEventFullDetails = loadable(
    () => import('@web/ui/components/card/postingcard/EventFullDetails'),
    { resolveComponent: imported => imported.default }
);

const LoadableEventFullDetailsOld = loadable(
    () => import('@web/ui/components/card/postingcard/EventFullDetailsOld'),
    { resolveComponent: imported => imported.default }
);

const LoadablePostingSuggested = loadable(
    () => import('@web/ui/components/card/postingcard/PostingSuggested'),
    { resolveComponent: imported => imported.default }
);

const LoadableFormController = loadable(() => import('@web/ui/components/forms/FormController'), {
    resolveComponent: imported => imported.default,
});

const LoadableCommentsContainer = loadable(
    () => import('@web/ui/components/card/modules/engagement/drawer/comments/CommentsContainer'),
    { resolveComponent: imported => imported.default }
);

const LoadableMultiPromptSelector = loadable(
    () => import('@web/ui/components/content/newpost/MultiPromptSelector'),
    { resolveComponent: imported => imported.default }
);

// This is deprecated, logic was added so you can pass in
// the title in options as 'sliderTitle'
const SIDEBAR_TITLE = {
    [TYPE_OPTIMIZE]: 'Optimize Profile',
    [TYPE_POSTING]: 'Posting Details',
    [TYPE_APPLICANTS]: 'Job Applicants',
    [TYPE_EVENT]: 'Event Details',
    [TYPE_APPLICANT]: 'Job Applicant',
};

const getSideBarTitle = type => {
    return SIDEBAR_TITLE[type];
};

const RightSidebar = React.memo(
    ({ title, children, handleClose, handleExpandToggle, sideBarWidth }) => {
        const toggleButtonClass =
            sideBarWidth === 'full' ? 'toggle-expand-button shrink' : 'toggle-expand-button';
        return (
            <>
                <div className="sidebar-header-container">
                    <h4>{title}</h4>
                    <div className="sidebar-header-right">
                        <button
                            onClick={handleClose}
                            label="Close side drawer"
                            type="button"
                            className="button-reset cancel-edit-content-button w-inline-block cancel-sidebar"
                        />
                        <div className="expand-button">
                            <button
                                type="button"
                                label="Expand"
                                onClick={handleExpandToggle}
                                className={toggleButtonClass}
                            />
                        </div>
                    </div>
                </div>
                <div
                    id="right-sidebar-content-container"
                    className="right-sidebar-content-container"
                >
                    <div id="right-sidebar-content" className="right-sidebar-content">
                        {children}
                    </div>
                </div>
            </>
        );
    }
);

// use this to define and load in additional components in sidebar
const getSideBarComponent = (type, props, passThruFunc, options) => {
    switch (type) {
        case TYPE_APPLICANTS:
            return <LoadableApplicantsView options={options} />;
        case TYPE_APPLICANT:
            return <LoadableApplicantView options={options} />;
        case TYPE_OPTIMIZE:
            return <LoadableUserOptimizers afterUpdate={passThruFunc} />;
        case TYPE_POSTING:
            return <LoadablePostingFullDetails options={options} />;
        case TYPE_EVENT_OLD:
            return <LoadableEventFullDetailsOld options={options} />;
        case TYPE_EVENT:
            return <LoadableEventFullDetails options={options} />;
        case TYPE_SUGGESTED:
            return <LoadablePostingSuggested option={options} />;
        case TYPE_FORM:
            return <LoadableFormController {...props} options={options} />;
        case TYPE_COMMENTS:
            return <LoadableCommentsContainer options={options} />;
        case TYPE_PROMPTS:
            return <LoadableMultiPromptSelector options={options} />;
        default:
            return null;
    }
};

const RightSidebarController = props => {
    const location = useLocation();
    const history = useHistory();
    const { sidebarState } = useContext(SidebarContext);
    const { hideSidebar, setComponent, showSidebar, clearSidebar, setWidth } =
        useContext(SidebarActionsContext);
    const componentType = sidebarState && sidebarState.componentType;
    const sideBarWidth = sidebarState && sidebarState.width;
    const options = sidebarState && sidebarState.options;

    let child, title, passThruFunc;

    // This allows one to store a component in state ala the modal but this was implemented before that and is not
    // full fleshed out as that and was more of an experimental thing
    if (componentType === TYPE_COMPONENT) {
        child = sidebarState && sidebarState.component;
        title = '';
    }

    if (componentType !== TYPE_COMPONENT) {
        passThruFunc = sidebarState && sidebarState.passThruFunc;
        child = getSideBarComponent(componentType, props, passThruFunc, options);
        title = options?.sliderTitle || sidebarState?.headerTitle || getSideBarTitle(componentType);
    }

    const visible = sidebarState && sidebarState.isVisible;
    let containerClass = visible ? 'right-sidebar-container show' : 'right-sidebar-container';
    if (sideBarWidth === 'full') {
        containerClass = `${containerClass} expanded`;
    }
    const [show, setShow] = useState(false);

    const handleExpandToggle = () => {
        if (sideBarWidth === 'full') {
            setWidth('shrink');
        }
        if (sideBarWidth === 'shrink') {
            setWidth('full');
        }
    };

    useEffect(() => {
        if (visible && !show) {
            setShow(visible);
        }

        if (!visible && show) {
            // will trigger hide menu animation
            setShow(false);
            // unmount child component displayed in slider after slide out animation is complete
            setTimeout(() => {
                clearSidebar();
                const params = queryString.parse(location.search);
                if (params?.showComments && location?.pathname) {
                    history.replace(location.pathname);
                }
            }, 400);
        }
    }, [visible]);

    useEffect(() => {
        const params = queryString.parse(location.search);
        if (params?.showComments) {
            const options = {
                cardUrl: params.showComments,
                sliderTitle: 'Comments',
            };
            showSidebar(TYPE_COMMENTS, null, null, 'shrink', options);
        }
    }, [location]);

    const handleClose = e => {
        hideSidebar();
    };

    // To output to a portal
    // pass in the options object properties usePortal: true and an optoinal property called portalEl which is a string
    // that corresponds to the html element, e.g. #modal-mid-root

    if (options?.usePortal) {
        const portalEl = options?.portalEl || 'modal-mid-root';
        return createPortal(
            <div className={containerClass}>
                <RightSidebar
                    sideBarWidth={sideBarWidth}
                    handleExpandToggle={handleExpandToggle}
                    handleClose={handleClose}
                    title={title}
                    visible={show}
                >
                    {child}
                </RightSidebar>
            </div>,
            document.getElementById(portalEl)
        );
    }

    return (
        // <OutsideClickHandler onOutsideClick={handleClose}>

        <div className={containerClass}>
            <ErrorBoundary>
                <RightSidebar
                    sideBarWidth={sideBarWidth}
                    handleExpandToggle={handleExpandToggle}
                    handleClose={handleClose}
                    title={title}
                    visible={show}
                >
                    {child}
                </RightSidebar>
            </ErrorBoundary>
        </div>
        // </OutsideClickHandler>
    );
};

export default RightSidebarController;
