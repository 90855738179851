import React from 'react';

const RRHeaderBottomMask = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 414 71">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M414 0H0V71H107.917C124.485 71 137.332 56.9532 144.318 41.9291C155.281 18.348 179.17 2 206.875 2C234.58 2 258.469 18.348 269.433 41.9291C276.418 56.9532 289.265 71 305.833 71H414V0Z"
            fill="currentcolor"
        />
    </svg>
);

export default RRHeaderBottomMask;
