export const VOTING_NONE = 'NO_VOTING';
export const VOTING_QUALITY = 'QUALITY';
export const VOTING_RELEVANCE = 'RELEVANCE';
export const VOTING_SENTIMENT = 'SENTIMENT';
export const VOTING_BALLOT_MEASURE = 'BALLOT_MEASURE';
export const VOTING_CHECKMARK = 'CHECKMARK';
export const VOTING_RADIO = 'RADIO';
export const VOTING_NO_VOTE_LABEL = 'LABEL';
export const VOTING_NO_VOTE_TEXT = 'TEXT';
export const VOTING_LEADERBOARD = 'LEADERBOARD';
export const VOTING_HEART = 'HEART';

export const getVoteTypesFromVoteSettings = vote_settings => {
    if (!vote_settings) return [];
    const voteTypes = [];
    if (vote_settings[VOTING_SENTIMENT]) {
        if (vote_settings[VOTING_SENTIMENT].active) voteTypes.push(VOTING_SENTIMENT);
    }
    if (vote_settings[VOTING_QUALITY]) {
        if (vote_settings[VOTING_QUALITY].active) voteTypes.push(VOTING_QUALITY);
    }
    if (vote_settings[VOTING_RELEVANCE]) {
        if (vote_settings[VOTING_RELEVANCE].active) voteTypes.push(VOTING_RELEVANCE);
    }
    if (vote_settings[VOTING_BALLOT_MEASURE]) {
        if (vote_settings[VOTING_BALLOT_MEASURE].active) voteTypes.push(VOTING_BALLOT_MEASURE);
    }

    return voteTypes;
};
