import { ApolloCache, FetchResult } from '@apollo/client';
import { cloneDeep } from 'lodash';

import { useCurrentUser } from '@stores/User';

import { CreateCardMutation, useCreateCardMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';
import {
    CurrentUserDocument,
    CurrentUserQuery,
    UserFeedDocument,
    UserFeedQuery,
} from '@shared/welibrary-graphql/user/queries.hook';
import {
    GetChapterChildrenDocument,
    GetChapterChildrenQuery,
    PaginatedNewsFeedDocument,
    PaginatedNewsFeedQuery,
} from '@shared/welibrary-graphql/content_card/queries.hook';

import { OPT_IN_TYPES } from '@core/utilities/constants/attachment_types';

import { ContentCard, ContentCardInput } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

type UseSimpleNewPost = (args: {
    url: string;
    updateCache?: (
        cache: ApolloCache<CreateCardMutation>,
        attachedCard?: CreateCardMutation['attachedCard'] | null
    ) => void;
    chapter?: ContentCard;
    sortType?: string;
}) => (variables: ContentCardInput) => Promise<FetchResult<CreateCardMutation>>;

const useSimpleNewPost: UseSimpleNewPost = ({ url, updateCache, chapter, sortType }) => {
      const { currentUser } = useCurrentUser();

    const [createCard] = useCreateCardMutation();

    const updateCacheAfterSubmit = (
        cache: ApolloCache<CreateCardMutation>,
        { data }: FetchResult<CreateCardMutation>
    ) => {
        const { attachedCard } = data ?? {};

        updateCache?.(cache, attachedCard);

        if (!attachedCard) return;

        // updates the user's (profile) feed
        // after making a new post
        try {
            const { userFeed } =
                cache.readQuery<UserFeedQuery>({
                    query: UserFeedDocument,
                    variables: { _id: currentUser?._id },
                }) ?? {};

            if (userFeed) {
                const cachedUserFeed = cloneDeep(userFeed);

                cache.writeQuery<UserFeedQuery>({
                    query: UserFeedDocument,
                    variables: { _id: currentUser?._id },
                    data: {
                        userFeed: {
                            ...userFeed,
                            results: [attachedCard, ...cachedUserFeed.results],
                        },
                    },
                });
            }
        } catch (error) {
            logger.error('paginatedUserFeed cache not in use.');
        }

        // TODO: Have Cache mapped to the proper 'limit' variable.
        // Right now, it will not work if the limit is not '10'...

        // TODO: Add Error Handling and Loading.

        // updates the user's (home)/news feed
        // after making a new post
        try {
            const { paginatedNewsFeed } =
                cache.readQuery<PaginatedNewsFeedQuery>({
                    // read paginatedNewsFeed cache
                    query: PaginatedNewsFeedDocument,
                }) ?? {};

            if (paginatedNewsFeed) {
                const cachedNewsFeed = cloneDeep(paginatedNewsFeed); // clone cache

                cache.writeQuery<PaginatedNewsFeedQuery>({
                    query: PaginatedNewsFeedDocument,
                    data: {
                        paginatedNewsFeed: {
                            ...paginatedNewsFeed,
                            results: [attachedCard, ...cachedNewsFeed.results], // update paginatedNewsFeed results cache
                        },
                    },
                });
            }
        } catch (error) {
            logger.error('paginatedNewsFeed cache not in use.');
        }

        // This update function adds the newly submitted card to the top of the local cache of
        // Content cards in the 'getChildrenFromChapter' feed. This is so the user immediately sees
        // the card they just posted
        try {
            const { paginatedChildrenFromCard } =
                cache.readQuery<GetChapterChildrenQuery>({
                    query: GetChapterChildrenDocument,
                    variables: { limit: 17, url, sortType, tags: [] },
                }) ?? {};

            if (paginatedChildrenFromCard) {
                cache.writeQuery<GetChapterChildrenQuery>({
                    query: GetChapterChildrenDocument,
                    variables: { limit: 17, url, sortType, tags: [] },
                    data: {
                        paginatedChildrenFromCard: {
                            ...paginatedChildrenFromCard,
                            results: [
                                attachedCard,
                                ...paginatedChildrenFromCard.results.slice(0, 16),
                            ],
                        },
                    },
                });
            }
        } catch (error) {
            logger.warn(error);
        }

        try {
            const { currentUser: cachedCurrentUser } =
                cache.readQuery<CurrentUserQuery>({
                    query: CurrentUserDocument,
                }) ?? {};

            if (cachedCurrentUser) {
                cachedCurrentUser.createdContent = [
                    attachedCard,
                    ...(cachedCurrentUser.createdContent ?? []),
                ];
                cache.writeQuery<CurrentUserQuery>({
                    query: CurrentUserDocument,
                    data: { currentUser: cachedCurrentUser },
                });
            }
        } catch (error) {
            logger.warn(error);
        }
    };

    const createPost = (variables: ContentCardInput) => {
        const input = { parentUrl: url, ...variables };

        const chapterOptions = chapter
            ? {
                  displayInNewsfeed: chapter.library_settings?.displayInNewsfeed ?? null,
                  displaySocialHeaders: chapter.library_settings?.displaySocialHeaders ?? null,
                  defaultViewType: chapter.library_settings?.defaultViewType ?? null,
                  engagementTypes: chapter.library_settings?.engagementTypes ?? null,
                  currentUserCanSuggestEdit: chapter.currentUserCanSuggestEdit ?? null,
              }
            : false;

        // If there are advanced options that inherit, like displayInNewsfeed - if the setting aligns with inherited setting, remove so it just uses inherited setting
        if (chapterOptions) {
            try {
                Object.keys(chapterOptions).forEach(key => {
                    // if the settings are a match for inherited settings, don't explicity set them on card level
                    // This is an assumption....
                    if (input[key] === chapterOptions[key]) delete input[key];

                    // For specific cards - postboxes, link tiles, etc - they have an inverted logic and must opt-out of the inverted inherited settings
                    if (
                        input.type &&
                        OPT_IN_TYPES.includes(input.type) &&
                        input[key] === false &&
                        !chapterOptions[key]
                    ) {
                        delete input[key];
                    } else if (input[key] === !!chapterOptions[key]) {
                        delete input[key];
                    }
                });
            } catch (e) {
                logger.error(e);
            }
        }

        return createCard({
            variables: { input },
            update: (cache, data) => updateCacheAfterSubmit(cache, data),
        });
    };

    return createPost;
};

export default useSimpleNewPost;
