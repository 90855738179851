import { useQuestByIdQuery } from '@shared/welibrary-graphql/quest/queries.hook';

const useQuestMapInfo = (questMapId?: string) => {
    const { data } = useQuestByIdQuery({
        variables: { _id: questMapId ?? '' },
        skip: !questMapId,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    return data?.getQuestById.rainbowRoad;
};

export default useQuestMapInfo;
