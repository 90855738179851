import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    AliasItemInput,
    QuickLinksSectionInput,
    useUpdateUserSideMenuMutation,
} from '@shared/welibrary-graphql/user/mutations.hook';

import useModal from '@web/ui/components/modals/hooks/useModal';

import ModalLoading from '@web/ui/components/modals/ModalLoading';

import {
    addToLinks,
    getAliasInputFromAlias,
    getInfoFromResult,
    moveHomeToLinks,
    moveToHome,
    removeFromLinks,
    removeHomeLink,
    updateHomeLink,
    updateLink,
} from '@web/utilities/helpers/quickLinks/quickLinkHelpers';

import { Entity } from '@core/types/Search';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER_QUICK_LINKS } from '@shared/welibrary-graphql/user/queries';

const localNamespace = 'imports.wlWeb.ui.components.navigation.slideMenu';

const useQuickLinks = (home?: boolean) => {
    const { t } = useTranslation();

    const { data, refetch: refetchQuickLinks } = useQuery(GET_CURRENT_USER_QUICK_LINKS);
    const allQuickLinks = data?.currentUser?.sideMenu?.quickLinks;
    const { newModal, closeAllModals } = useModal();

    const [updateUserSideMenu] = useUpdateUserSideMenuMutation();

    const updateQuickLinks = (quickLinks: QuickLinksSectionInput[], loadingMessage?: string) => {
        updateUserSideMenu({ variables: { input: { quickLinks } } }).then(() => {
            refetchQuickLinks();
            closeAllModals();
        });

        newModal(<ModalLoading message={loadingMessage} />);
    };

    const getLinks = () => {
        const homeLink =
            allQuickLinks?.find(quickLink => quickLink?.tags?.[0] === 'home')?.aliases?.[0] ??
            undefined;

        const quickLinks = allQuickLinks?.find(
            quickLink => quickLink?.tags?.[0] === 'links'
        )?.aliases;

        return { homeLink, quickLinks };
    };

    const generateQuickLinksSectionInput = () => {
        const { homeLink, quickLinks } = getLinks();

        const newQuickLinks: QuickLinksSectionInput[] = [];

        // if there is nothing in the home slot, don't add anything to the main array
        if (homeLink) {
            newQuickLinks.push({ tags: ['home'], aliases: [getAliasInputFromAlias(homeLink)] });
        }

        const newLinks: AliasItemInput[] =
            quickLinks?.reduce((linksAccumulator, alias) => {
                return alias
                    ? [...linksAccumulator, getAliasInputFromAlias(alias)]
                    : linksAccumulator;
            }, []) ?? [];

        // if there is nothing in the links array, don't add anything to the main array
        if (newLinks.length > 0) newQuickLinks.push({ tags: ['links'], aliases: newLinks });

        return newQuickLinks;
    };

    const addResult = (result: Partial<Entity>) => {
        const info = getInfoFromResult(result);
        if (!info) return;

        const quickLinks = generateQuickLinksSectionInput();
        const newQuickLinks = home
            ? updateHomeLink(quickLinks, { tags: ['home'], aliases: [info] })
            : addToLinks(quickLinks, info);
        updateQuickLinks(newQuickLinks, t(`common:${localNamespace}.adding_quick_link`));
    };

    const replaceQuickLink = (result: Entity, index?: number) => {
        if (!home && index === undefined) return;

        const info = getInfoFromResult(result);

        if (!info) return;

        const quickLinks = generateQuickLinksSectionInput();

        const newQuickLinks = home
            ? updateHomeLink(quickLinks, { tags: ['home'], aliases: [info] })
            : updateLink(quickLinks, info, index!);

        updateQuickLinks(newQuickLinks, t(`common:${localNamespace}.replacing_quick_link`));
    };

    const removeQuickLink = (index?: number) => {
        if (!home && index === undefined) return;

        const quickLinks = generateQuickLinksSectionInput();

        const newQuickLinks = home
            ? removeHomeLink(quickLinks)
            : removeFromLinks(quickLinks, index!);

        updateQuickLinks(newQuickLinks, t(`common:${localNamespace}.removing_quick_link`));
    };

    const toggleHome = (index?: number) => {
        if (!home && index === undefined) return;

        const quickLinks = generateQuickLinksSectionInput();

        const newQuickLinks = home ? moveHomeToLinks(quickLinks) : moveToHome(quickLinks, index!);

        updateQuickLinks(newQuickLinks, t(`common:${localNamespace}.moving_quick_link`));
    };

    return { getLinks, addResult, replaceQuickLink, removeQuickLink, toggleHome };
};

export default useQuickLinks;
