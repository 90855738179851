import React, { useState } from 'react';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';

import shoppingCartStore from '@web/ui/stores/ShoppingCart';
import { useAddCardToCustomerAccountMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { useCurrentUser } from '@stores/User';

import OrderTotal from '@web/ui/components/card/Shopping-Cart/OrderTotal';
import EditScreenItemContainer from '@components/generic/editscreen/EditScreenItemContainer';
import Toggle from '@dsc/forms/customInputs/Toggle';

import { Product, Group } from '@shared/welibrary-graphql/types';
import { CARD_EVENT } from '@core/utilities/constants/card_types';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';
import { isEventFree } from '@web/utilities/helpers/events/event.helpers';
import {
    DiscountCodeState,
    PAYMENT,
    getDiscountedShoppingCartTotal,
    getShoppingCartTotal,
    getTotal,
} from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';

import { GET_LOGGEDIN_CUSTOMER_CARDS } from '@shared/welibrary-graphql/user/queries';

import getLogger from '@core/logger';

const logger = getLogger(module);

type StripeCheckoutFormProps = {
    clientSecret: string;
    cartItem: Product;
    group: Group;
    discountCodeState: DiscountCodeState;
    closeModal: () => void;
    handlePaymentIsComplete?: () => void;
    handlePrevStep: (smooth: boolean) => void;
    quantity: number;
    currentPage: string;

    isInline: boolean;
};

const StripeCheckoutForm: React.FC<StripeCheckoutFormProps> = ({
    clientSecret,
    cartItem,
    group,
    discountCodeState,
    handlePaymentIsComplete = () => { },
    handlePrevStep,
    quantity,
    currentPage,

    isInline,
}) => {
    const isEvent = group?.subtype === CARD_EVENT;
    const _isEventFree = isEventFree(group);

    const shoppingCart = shoppingCartStore?.useTracked?.shoppingCarts()?.find(({ groupId }) => {
        return groupId === group?._id;
    });
    const shoppingCartProducts = shoppingCart?.products?.filter(({ product }) => {
        if (_isEventFree) return product?.type === PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP;

        return product?.type === PRODUCT_TYPES?.GROUP_MEMBERSHIP;
    });

    const { currentUser } = useCurrentUser();
    const stripe = useStripe();
    const elements = useElements();

    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [cardErr, setCardErr] = useState<any>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const [addCardSource] = useAddCardToCustomerAccountMutation();

    const checkoutButtonText = isEvent ? 'Place Order' : 'Subscribe';

    // during checkout if a purchase is not an event
    // do not show the "set payment as default" option,
    // otherwise a payment method should be set as the default silently (ie: for subscriptions)
    const setPaymentAsDefault = !isEvent || isDefault;

    // @ts-ignore
    const handleCardCheckout = async e => {
        e.preventDefault();
        setIsProcessing(true);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        let cardPayload;

        if (setPaymentAsDefault) {
            // @ts-ignore
            cardPayload = await stripe.createToken(elements.getElement(CardNumberElement), {});
        }

        // @ts-ignore
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                // @ts-ignore
                card: elements.getElement(CardNumberElement),
            },
        });

        if (payload.error) {
            setCardErr(payload.error);
            setIsProcessing(false);
        } else {
            if (setPaymentAsDefault && cardPayload) {
                addCardSource({
                    variables: {
                        token: cardPayload?.token?.id,
                        setAsDefault: isDefault,
                    },
                    refetchQueries: [GET_LOGGEDIN_CUSTOMER_CARDS],
                })
                    .then(() => {
                        setIsProcessing(false);
                    })
                    .catch(err => logger.error(err));
            }
            // show user their payment was successful
            shoppingCartStore?.set?.clearGroupShoppingCart(group?._id);
            setIsProcessing(false);
            handlePaymentIsComplete();
        }
    };

    const cardStyle = {
        style: {
            base: {
                color: '#333',
                backgroundColor: '#F8F8F8',
                fontFamily: 'Roboto, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#adadad',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    const CARD_NUMBER_OPTIONS = {
        placeholder: 'Card Number',
        showIcon: true,
        iconStyle: 'solid',
        ...cardStyle,
    };
    const CARD_EXPIRY_OPTIONS = { placeholder: 'MM/YY', ...cardStyle };

    let total;
    let originalPrice = getTotal(cartItem, quantity);

    if (discountCodeState.isApplied) {
        total = getTotal(cartItem, quantity, discountCodeState.percentage);
    } else {
        total = getTotal(cartItem, quantity);
    }

    if (isEvent) {
        originalPrice = getShoppingCartTotal(shoppingCartProducts);
        total = getDiscountedShoppingCartTotal(shoppingCartProducts);

        if (_isEventFree) {
            total = getShoppingCartTotal(shoppingCartProducts, _isEventFree);
        }
    }

    const customStyles: any = { width: '100%' };
    if (!isEvent) customStyles.marginTop = '20px';

    const footerClassName = isInline ? 'absolute' : 'fixed';

    return (
        <>
            <EditScreenItemContainer title="Payment method" overrideContainerStyle={customStyles}>
                <div className="customer-payments-container">
                    <div className="stripe-checkout-form">
                        <div className="stripe-checkout-form-element-wrap">
                            <CardNumberElement
                                className="stripe-checkout-form-element"
                                onFocus={() => setCardErr(null)}
                                onChange={event => {
                                    setCardErr(event.error);
                                }}
                                options={CARD_NUMBER_OPTIONS}
                            />
                        </div>
                        <div className="stripe-checkout-form-elements">
                            <div className="stripe-checkout-form-element-wrap">
                                <CardExpiryElement
                                    className="stripe-checkout-form-element"
                                    onFocus={() => setCardErr(null)}
                                    onChange={event => {
                                        setCardErr(event.error);
                                    }}
                                    options={CARD_EXPIRY_OPTIONS}
                                />
                            </div>
                            <div className="stripe-checkout-form-element-wrap">
                                <CardCvcElement
                                    className="stripe-checkout-form-element"
                                    options={cardStyle}
                                    onFocus={() => setCardErr(null)}
                                    onChange={event => {
                                        setCardErr(event.error);
                                    }}
                                />
                            </div>
                        </div>
                        {isEvent && currentUser && (
                            <div className="customer-payment-default-wrap">
                                <p className="customer-payment-default-text">Set as default</p>
                                <Toggle
                                    onChange={() => setIsDefault(!isDefault)}
                                    value={isDefault}
                                />
                            </div>
                        )}
                        {cardErr && (
                            <div className="customer-card-error-wrap">
                                <p className="customer-card-error-text">x {cardErr.message}</p>
                            </div>
                        )}
                    </div>
                </div>
            </EditScreenItemContainer>
            {currentPage === PAYMENT && (
                <div
                    className={`stripe-checkout-form-container bottom-0 left-0 z-[99999] bg-white ${footerClassName}`}
                >
                    <div className="stripe-checkout-total-divider" />
                    <OrderTotal total={total} originalPrice={originalPrice} group={group} />
                    {!isEvent && (
                        <div className="stripe-checkout-subs-message-wrap">
                            <p className="stripe-checkout-subs-message">
                                You will be charged an ongoing monthly <br />
                                subscription fee. You can cancel anytime.
                            </p>
                        </div>
                    )}
                    <div className="stripe-checkout-btn-wrap">
                        <button
                            type="button"
                            onClick={() => handlePrevStep(false)}
                            className="stripe-checkout-btn-cancel"
                        >
                            Go Back
                        </button>

                        <button
                            type="button"
                            onClick={handleCardCheckout}
                            className="stripe-checkout-btn-confirm"
                            disabled={!stripe || !elements || isProcessing}
                        >
                            {isProcessing ? 'Processing...' : checkoutButtonText}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default StripeCheckoutForm;
