import React from 'react';

const LocationMarker: React.FC<{
    className?: string;
    strokeColor?: string;
    version: '1' | '2';
}> = ({ className, strokeColor = 'currentColor', version = '1' }) => {
    if (version === '1') {
        return (
            <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M22 11C22 15.346 15.8077 20.9022 13.1687 23.0727C12.4838 23.636 11.5162 23.636 10.8313 23.0727C8.19234 20.9022 2 15.346 2 11C2 5.47715 6.47715 1 12 1C17.5228 1 22 5.47715 22 11Z"
                    stroke={strokeColor}
                    strokeWidth="2"
                />
                <circle cx="12" cy="11" r="3" stroke={strokeColor} strokeWidth="2" />
            </svg>
        );
    }

    // version === '2'
    return (
        <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.23926 9.39148C1.25367 5.15089 4.70302 1.7249 8.94361 1.7393C13.1842 1.75371 16.6102 5.20306 16.5958 9.44365V9.53061C16.5436 12.2871 15.0045 14.835 13.1175 16.8263C12.0384 17.9469 10.8333 18.939 9.52621 19.7828C9.17672 20.0851 8.65832 20.0851 8.30882 19.7828C6.36033 18.5145 4.65019 16.9133 3.25665 15.0523C2.01461 13.4296 1.30942 11.4599 1.23926 9.41756L1.23926 9.39148Z"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="8.91741"
                cy="9.53899"
                r="2.46087"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LocationMarker;
