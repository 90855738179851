import React from 'react';

/** @type React.FC<{ className: string }> */
const RocketShip = ({ className, size }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentcolor"
        className={className}
        viewBox="0 0 500 500"
        height={size}
        width={size}
    >
        <path d="M235.45,467.35c-2.9-37.4-5.75-74.3-8.62-111.34-23.1-1.28-43.19-8.41-59.48-24.64S144,295,142.57,272.26l-108-6.92c.84-1.63,1.34-2.78,2-3.82q30.56-48.33,61.16-96.63c12-19,28.8-30.95,51.29-33.32,9.15-1,18.57.28,27.85.89,8.13.55,16.21,1.83,24.35,2.32a9,9,0,0,0,6.4-2.45C253.31,82.27,311,53.92,376.21,39.42c22.48-5,45.28-7.73,68.39-6.46,4,.22,7.92,1,11.9,1.33,5.19.38,7.77,3.1,7.88,8.2.3,13.95,1.85,28,.59,41.81a336.62,336.62,0,0,1-46.73,143.45,264.15,264.15,0,0,1-50.81,62c-2.72,2.44-3.76,4.74-3.29,8.57,1.59,13,3.14,26.13,3.81,39.25,1.3,25.51-8.22,46.58-29.27,61.1-33.29,23-67.21,45-100.87,67.48C237.28,466.47,236.69,466.71,235.45,467.35ZM385.78,161.2c0-27-21.24-48.41-47.89-48.42a48.35,48.35,0,0,0-48.41,48.13c-.05,26.89,21.81,48.18,49.36,48.08C364.52,208.91,385.82,187.22,385.78,161.2Z" />
        <path d="M43.28,365.6l68.35-68.33C121.67,347,151.77,377,201.24,387l-67.65,67.68V410.84L65.07,433.57l22.58-68Z" />
    </svg>
);

export default RocketShip;
