import React from 'react';

import Picture from '@components/generic/Picture';
import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/circle-spinner.json';
import ExternalLink from '@components/generic/ExternalLink';

type ImagePickerImageProps = {
    imageUrl: string;
    ariaLabel: string;

    /** ignored while an image is being uploaded */
    onClick: () => void;

    /**  if this particular image is the Upload placeholder / image being uploaded */
    isUploadImage?: boolean;

    /** if an image is currently being uploaded by the ImagePicker component */
    imageBeingUploaded: boolean;
    loadingText?: string | null;
    attributionLink?: string;
    attributionName?: string;
};

const ImagePickerImage: React.FC<ImagePickerImageProps> = ({
    imageUrl,
    ariaLabel,
    onClick,
    isUploadImage,
    imageBeingUploaded,
    loadingText,
    attributionLink,
    attributionName,
}) => {
    return (
        <section className="image-wrapper">
            <button
                type="button"
                onClick={imageBeingUploaded ? undefined : onClick}
                className={`${imageBeingUploaded ? 'disabled' : ''}`}
                aria-label={ariaLabel}
            >
                {isUploadImage && imageBeingUploaded && (
                    <section className="image-loading">
                        <LottieLoading lottieData={LottieAnimation} isPaused={false} />
                        <span className="loading-text">{loadingText}</span>
                    </section>
                )}
                <Picture
                    url={imageUrl}
                    disableLink
                    className={isUploadImage ? 'image-upload-figure' : ''}
                />
            </button>

            {attributionLink && attributionName && (
                <ExternalLink to={attributionLink} className="photo-attribution">
                    {attributionName}
                </ExternalLink>
            )}
        </section>
    );
};

export default ImagePickerImage;
