import React from 'react';

const Ticket: React.FC<{ className?: string; strokeWidth?: string; strokeColor?: string }> = ({
    className = '',
    strokeWidth = '2',
    strokeColor = '#fff',
}) => (
    <svg viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path
            d="M16.3121 1.31287V4.33787"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.3121 18.2001V20.7301"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.3121 13.9057V7.87949"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.3777 21C24.6552 21 26.5 19.1782 26.5 16.9289V13.6883C24.9929 13.6883 23.7792 12.4896 23.7792 11.0013C23.7792 9.51294 24.9929 8.31299 26.5 8.31299L26.4987 5.07107C26.4987 2.82181 24.6526 1 22.3764 1H5.62364C3.34736 1 1.5013 2.82181 1.5013 5.07107L1.5 8.41857C3.00709 8.41857 4.22085 9.51294 4.22085 11.0013C4.22085 12.4896 3.00709 13.6883 1.5 13.6883V16.9289C1.5 19.1782 3.34475 21 5.62234 21H22.3777Z"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ThickTicket: React.FC<{
    className?: string;
    strokeWidth?: string;
    strokeColor?: string;
}> = ({ className = '', strokeWidth = '2', strokeColor = '#fff' }) => (
    <svg
        className={className}
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.3873 3.68774V5.50274"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.3873 13.8201V15.3381"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.3873 11.2434V7.62769"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0266 15.5C15.3931 15.5 16.5 14.4069 16.5 13.0574V11.113C15.5957 11.113 14.8675 10.3938 14.8675 9.50077C14.8675 8.60776 15.5957 7.88779 16.5 7.88779L16.4992 5.94264C16.4992 4.59309 15.3916 3.5 14.0258 3.5H3.97419C2.60842 3.5 1.50078 4.59309 1.50078 5.94264L1.5 7.95114C2.40426 7.95114 3.13251 8.60776 3.13251 9.50077C3.13251 10.3938 2.40426 11.113 1.5 11.113V13.0574C1.5 14.4069 2.60685 15.5 3.9734 15.5H14.0266Z"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Ticket;
