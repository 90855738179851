/* eslint-disable import/prefer-default-export */
import LeftModal from '@web/ui/components/modals/LeftModal';
import RightModal from '@web/ui/components/modals/RightModal';
import CenterModal from '@web/ui/components/modals/CenterModal';
import MobileMenu from '@web/ui/components/modals/MobileMenu';
import FreeformModal from '@web/ui/components/modals/FreeformModal';
import FullScreenModal from '@web/ui/components/modals/FullScreenModal';

import { ModalContainer, ModalTypes } from '@core/types/Modals';

export const MODALS: Record<Exclude<ModalTypes, ModalTypes.None>, ModalContainer> = {
    [ModalTypes.Left]: LeftModal,
    [ModalTypes.Right]: RightModal,
    [ModalTypes.Center]: CenterModal,
    [ModalTypes.Mobile]: MobileMenu,
    [ModalTypes.Freeform]: FreeformModal,
    [ModalTypes.FullScreen]: FullScreenModal,
} as const;
