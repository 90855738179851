import React, { useContext } from 'react';
import _ from 'lodash';

import { awsMetricPublisher } from '@welibraryos/metrics';
import { useShareLink } from '@components/generic/hooks/useShareLink';

import EngagementButton from '@web/ui/components/card/modules/engagement/EngagementButton';
import EngagementToggleButton from '@web/ui/components/card/modules/engagement/EngagementToggleButton';
import ShareDrawer from '@web/ui/components/card/modules/engagement/drawer/share/ShareDrawer';
import PledgeDrawer from '@web/ui/components/card/modules/engagement/drawer/pledge/PledgeDrawer';
import LikeButton from '@web/ui/components/engagement/LikeButton';

import Heart from '@web/ui/dsc/svgs/Heart';
import Comment from '@web/ui/dsc/svgs/Comment';
import Share from '@web/ui/dsc/svgs/Share';

import { getRootUrl } from '@core/utilities/whitelabel_helpers';
import { SidebarContext } from '@components/generic/context/SidebarContext';
import DropdownController from '@components/generic/dropdownmenu/DropdownController';

import { MenuListItem } from '@core/types/Menus';
import { CardEngagementType, ContentCard } from '@shared/welibrary-graphql/types';

// Must Align with gql type: CardEngagementType
export const ENGAGE_COMMENT = 'comment';
export const ENGAGE_SHARE = 'share';
export const ENGAGE_FOLLOW = 'follow';
export const ENGAGE_HEART = 'heart';
export const ENGAGE_BOOKMARK = 'bookmark';
export const ENGAGE_PLEDGE = 'pledge';
export const ENGAGE_ATTEND = 'attend';

type CommentButtonContainerProps = {
    handleToggleDrawer: (type: string) => void;
    iconText: string | number;
    leftText?: string;
    cardId: string;
    type: string;
    iconClass: string;
    isCallout: boolean;
};

const CommentButtonContainer: React.FC<CommentButtonContainerProps> = ({
    handleToggleDrawer,
    iconText,
    leftText,
    cardId,
    type,
    iconClass,
    isCallout,
}) => {
    const { sidebarState } = useContext(SidebarContext);

    const handleOnClick = () => {
        // if visible, we close comments onClick; if hidden, we open comments onClick
        if (sidebarState.isVisible) {
            awsMetricPublisher.publishCount('content-card-engage', 1, [
                { Name: 'menu', Value: 'close-comments' },
            ]);
        } else {
            awsMetricPublisher.publishCount('content-card-engage', 1, [
                { Name: 'menu', Value: 'open-comments' },
            ]);
        }

        // because the onClick prop overrides handleToggleDrawer in EngagementButton, handling both here within onClick
        handleToggleDrawer();
    };

    return (
        <EngagementButton
            iconText={iconText}
            leftText={leftText}
            isActive={cardId === sidebarState.id}
            type={type}
            iconClass={iconClass}
            isCallout={isCallout}
            onClick={handleOnClick}
        />
    );
};

const ENGAGEMENT_COMMENTS = {
    id: ENGAGE_COMMENT,
    order: 4,
    renderButton: ({
        card,
        handleToggle,
        iconText,
        index,
    }: {
        card: ContentCard;
        handleToggle: (type: string) => void;
        iconText: string;
        index: number;
    }) => (
        <CommentButtonContainer
            handleToggleDrawer={handleToggle}
            iconText={card?.commentCount || iconText}
            cardId={card?._id}
            key={`button${index}`}
            type={ENGAGE_COMMENT}
            iconClass={ENGAGE_COMMENT}
            isCallout={false}
        />
    ),
    renderDrawer: () => null,
};

const ENGAGEMENT_HEART = {
    id: ENGAGE_HEART,
    order: 3,
    renderButton: ({
        card,
        handleToggle,
        iconText,
        index,
    }: {
        card: ContentCard;
        handleToggle: (type: string) => void;
        iconText: string;
        index: number;
    }) => (
        <LikeButton
            card={card}
            handleToggle={handleToggle}
            iconText={iconText}
            key={`button${index}`}
        />
    ),
};

const ENGAGEMENT_SHARE = {
    id: ENGAGE_SHARE,
    order: 2,
    renderButton: ({
        card,
        setContactList,
        handleShare,
    }: {
        card: ContentCard;
        setContactList: () => void;
    }) => {
        const getShareUrl = (cardId: string) => {
            if (cardId) {
                awsMetricPublisher.publishCount('content-card-engage', 1, [
                    { Name: 'menu', Value: 'direct-link' },
                ]);
                return `https://${getRootUrl()}/c/${cardId}`;
            }
            return 'Sharing not supported for this content.';
        };

        const shareDecider = () => {
            // Event group cards link directly to the group dashboard
            if (card.aliases?.[0].__typename === 'GroupAliasHandle') {
                const group = card.aliases[0].group;
                if (group.subtype === 'event') {
                    handleShare(`https://${getRootUrl()}/g/${group._id}`);
                    return;
                }
            }

            handleShare(getShareUrl(card._id), card.title, card.body);
        };

        const shareInMessage = () => {
            awsMetricPublisher.publishCount('content-card-engage', 1, [
                { Name: 'menu', Value: 'share-in-messages' },
            ]);
            setContactList();
        };

        const onClickCallback = () => {
            awsMetricPublisher.publishCount('content-card-engage', 1, [
                { Name: 'menu', Value: 'share-options' },
            ]);
        };

        const menuList: MenuListItem[] = [
            {
                id: 1,
                title: 'Share in messages',
                iconName: 'Share',
                onClick: () => shareInMessage(),
                onClose: () => false,
            },
            {
                id: 2,
                title: 'Copy Direct Link',
                iconName: 'Duplicate',
                onClick: () => shareDecider(),
            },
        ];

        return (
            <div className="engagement-button shrink w-inline-block" key={card._id}>
                <DropdownController
                    menuList={menuList}
                    inverted={false}
                    buttonClass="content-button-icon share share-button-transparent"
                    // superLayer={superLayer}
                    onClickCallback={onClickCallback}
                />
            </div>
        );
    },
    renderDrawer: ({ card, handleClose }: { card: ContentCard; handleClose: () => void }) => {
        return <ShareDrawer handleCloseDrawer={handleClose} card={card} />;
    },
};

const ENGAGEMENT_PLEDGE = {
    id: ENGAGE_PLEDGE,
    order: 1,
    renderButton: ({
        handleToggle,
        isActive,
        iconText,
        index,
    }: {
        handleToggle: (type: string) => void;
        isActive: boolean;
        iconText: string;
        index: number;
    }) => (
        <EngagementButton
            handleToggleDrawer={handleToggle}
            iconText={iconText || 'Make the Pledge'}
            isActive={isActive}
            key={`button${index}`}
            type={ENGAGE_PLEDGE}
            iconClass="pledge-button-counter-text upthumb"
            isCallout
        />
    ),
    renderDrawer: ({ card, handleClose }: { card: ContentCard; handleClose: () => void }) => {
        return <PledgeDrawer handleCloseDrawer={handleClose} card={card} />;
    },
};

const ENGAGEMENT_ATTEND = {
    id: ENGAGE_ATTEND,
    order: 0,
    renderButton: ({
        card,
        handleToggle,
        index,
    }: {
        card: ContentCard;
        handleToggle: (type: string) => void;
        index: number;
    }) => {
        return (
            <EngagementToggleButton
                value={card.userIsAttending}
                target="isUserAttending"
                onText="Attending"
                offText="Attend"
                key={`button${index}`}
                handleToggle={handleToggle}
            />
        );
    },
};

export const getRenderPropsByEngagementType = (type: string) => {
    switch (type) {
        case ENGAGE_SHARE:
            return ENGAGEMENT_SHARE;
        case ENGAGE_COMMENT:
            return ENGAGEMENT_COMMENTS;
        case ENGAGE_PLEDGE:
            return ENGAGEMENT_PLEDGE;
        case ENGAGE_ATTEND:
            return ENGAGEMENT_ATTEND;
        case ENGAGE_HEART:
            return ENGAGEMENT_HEART;
        case ENGAGE_FOLLOW:
        // return ENGAGEMENT_FOLLOW
        case ENGAGE_BOOKMARK:
        // return ENGAGEMENT_BOOKMARK
        default:
            return null;
    }
};

export const getArrayOfRenderPropsByEngagementType = (types: string[]) => {
    return _.sortBy(
        types.map(type => getRenderPropsByEngagementType(type)),
        'order'
    );
};

export const engagementTypeValues: Array<{ display: React.ReactNode; data: CardEngagementType }> = [
    { data: CardEngagementType.Heart, display: <Heart /> },
    { data: CardEngagementType.Comment, display: <Comment /> },
    { data: CardEngagementType.Share, display: <Share /> },
];
