import React from 'react';

const Clock2: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            stroke="currentcolor"
        >
            <circle cx="50" cy="50" r="45" stroke-width="10" fill="none" />

            <line x1="50" y1="50" x2="67.32" y2="60" stroke-width="14" />

            <line x1="50" y1="50" x2="50" y2="20" stroke-width="10" />
        </svg>
    );
};

export default Clock2;
