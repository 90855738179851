import React from 'react';

import useModal from '@web/ui/components/modals/hooks/useModal';
import useConfirmation from '@components/generic/hooks/useConfirmation';

import NavigatorToggleButton from '@web/ui/components/navigation/SideMenu/Navigator/NavigatorToggleButton';

import { ModalContainer } from '@core/types/Modals';

const LeftModal: ModalContainer = ({ component, options, open }) => {
    const { closeModal } = useModal();
    const confirm = useConfirmation({ widen: true, className: options?.confirmationClassName });

    const optionalClass = options?.className || '';

    const handleCloseModal = () => {
        if (options.disableCloseHandlers) return;

        if (options?.confirmClose) {
            confirm({ text: options.confirmClose, onConfirm: () => setTimeout(closeModal, 350) });
        } else {
            options?.onClose?.();
            closeModal();
        }
    };

    const buttonColors = {
        '--foreground': options?.buttonColors?.foreground ?? 'var(--color-grayscale-body)',
        '--background': options?.buttonColors?.background ?? 'white',
    } as React.CSSProperties;

    return (
        <aside
            id="left-modal"
            className={`${optionalClass} ${open ? 'open' : 'closed'} ${
                options?.hideDimmer ? 'hide-dimmer' : ''
            }`}
        >
            {!options?.hideDimmer && (
                <button
                    className="left-modal-dimmer"
                    type="button"
                    onClick={handleCloseModal}
                    aria-label="modal-dimmer"
                    aria-hidden
                />
            )}
            <section>
                <NavigatorToggleButton
                    onClick={closeModal}
                    className="left-modal-close-button"
                    style={buttonColors}
                />
                <section
                    className={`${optionalClass} ${options?.widen ? 'widen' : ''} ${
                        options?.addShadow ? 'add-shadow' : ''
                    }`}
                >
                    {component}
                </section>
            </section>
        </aside>
    );
};

export default LeftModal;
