import React from 'react';

const VerifyBadge: React.FC<{ className?: string; strokeWidth?: string; strokeColor?: string }> = ({
    className,
    strokeWidth = '3',
    strokeColor,
}) => {
    return (
        <svg
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            strokeWidth={strokeWidth}
            stroke="currentColor"
        >
            <path
                d="M14.0973 4.52137C14.569 3.19827 15.4385 2.05349 16.5865 1.24401C17.7344 0.434534 19.1047 0 20.5094 0C21.9141 0 23.2844 0.434534 24.4323 1.24401C25.5803 2.05349 26.4497 3.19827 26.9214 4.52137C28.1897 3.92113 29.6125 3.72787 30.9949 3.96808C32.3773 4.20829 33.6516 4.87023 34.643 5.86312C35.6345 6.856 36.2945 8.13125 36.5327 9.51401C36.7709 10.8968 36.5755 12.3193 35.9733 13.5867C37.2972 14.0567 38.443 14.9252 39.2533 16.0727C40.0636 17.2203 40.4986 18.5906 40.4986 19.9954C40.4986 21.4002 40.0636 22.7705 39.2533 23.9181C38.443 25.0656 37.2972 25.9341 35.9733 26.4041C36.5768 27.6722 36.7729 29.0959 36.5347 30.4799C36.2966 31.8639 35.6359 33.1402 34.6432 34.1336C33.6506 35.127 32.3747 35.7887 30.9909 36.0279C29.6071 36.2671 28.1832 36.072 26.9147 35.4694C26.443 36.7925 25.5736 37.9373 24.4256 38.7468C23.2776 39.5563 21.9074 39.9908 20.5027 39.9908C19.098 39.9908 17.7277 39.5563 16.5797 38.7468C15.4318 37.9373 14.5623 36.7925 14.0906 35.4694C12.8218 36.0743 11.3969 36.2712 10.0117 36.033C8.62646 35.7949 7.34907 35.1334 6.3552 34.1395C5.36134 33.1456 4.69986 31.8682 4.4617 30.483C4.22354 29.0978 4.42046 27.6729 5.02531 26.4041C3.70148 25.9341 2.55562 25.0656 1.74532 23.9181C0.935026 22.7705 0.5 21.4002 0.5 19.9954C0.5 18.5906 0.935026 17.2203 1.74532 16.0727C2.55562 14.9252 3.70148 14.0567 5.02531 13.5867C4.42046 12.318 4.22354 10.893 4.4617 9.50779C4.69986 8.12258 5.36134 6.84519 6.3552 5.85133C7.34907 4.85747 8.62646 4.19596 10.0117 3.9578C11.3969 3.71964 12.8218 3.91652 14.0906 4.52137H14.0973Z"
                fill={strokeColor}
            />
        </svg>
    );
};

export default VerifyBadge;
