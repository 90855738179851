import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateStripeConnectedAccountMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { getRootUrl } from '@core/utilities/whitelabel_helpers';

import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/stripe-lottie.json';
import PayoutDisclaimer from '@components/content/newpost/forms/ShoppingCartProducts/PayoutDisclaimer';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.stripe';

export type MerchantSignupState = {
    country: string;
    email: string;
};

const ConnectedAccountStep3: React.FC<{
    totalSteps: number;
    currentStep: number;
    handlePrevStep: () => void;
    state: MerchantSignupState;
    groupId: string;
}> = ({ totalSteps, currentStep, handlePrevStep, state, groupId }) => {
    const { t } = useTranslation();
    const [createAccount, { loading }] = useCreateStripeConnectedAccountMutation();

    let stripeAccountBtnText;
    if (loading) {
        stripeAccountBtnText = t(`common:${localNamespace}.redirecting`, 'Redirecting...');
    } else {
        stripeAccountBtnText = t(`common:${localNamespace}.continue`, 'Continue');
    }

    const handleCreateStripeAccount = () => {
        const isValid = state.country && state.email;

        if (isValid) {
            createAccount({
                variables: {
                    input: {
                        groupId,
                        email: state.email,
                        country: state.country,
                    },
                    host: getRootUrl(),
                },
            })
                .then(({ data }) => {
                    // @ts-ignore
                    const { url } = data?.onboardGroupConnectedAccount;
                    window.location.href = url;
                })
                .catch(e => {
                    logger.error(e);
                });
        }
    };

    return (
        <div className="mc-source-post-wrapper connected-account-success">
            <div className="connected-account-starter-lottie-wrap">
                <LottieLoading
                    height={250}
                    width={250}
                    lottieData={LottieAnimation}
                    customStyle={{ opacity: 1 }}
                    isPaused
                    loop={false}
                />
            </div>
            <div className="connected-account-starter-text-wrap">
                <h1>
                    {t(
                        `common:${localNamespace}.complete_merchant_account`,
                        'Complete Merchant Account'
                    )}
                </h1>
                <p>
                    {t(
                        `common:${localNamespace}.stripe_as_payment_processor`,
                        'We use Stripe as a payment processor to ensure seamless, secure payments around the world. Click continue to finish setting up your Merchant account with stripe.'
                    )}
                </p>
                <PayoutDisclaimer />
            </div>
            <div className="connected-account-btns-wrap connected-account-btns-wrap-margin-top">
                {currentStep > 1 && (
                    <button onClick={handlePrevStep} type="button">
                        {t(`common:${localNamespace}.back`, 'Back')}
                    </button>
                )}

                {currentStep === totalSteps && (
                    // redirect to stripe setup
                    <button onClick={handleCreateStripeAccount} type="button">
                        {stripeAccountBtnText}
                    </button>
                )}
            </div>
        </div>
    );
};

export default ConnectedAccountStep3;
