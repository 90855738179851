import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAddUserToGroupMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import useGroupActions from '@components/group/hooks/useGroupActions';
import useModal from '@components/modals/hooks/useModal';

import SimpleCurrency from '@dsc/svgs/SimpleCurrency';
import Checkout from '@components/card/Shopping-Cart/Checkout';
import ParentGroupSubscriptionActionConfirmation from '@components/group/groupdashboard/groupSubscriptionPaywall/ParentGroupSubscriptionActionConfirmation';

// import { GROUP_ACTIONS } from '@core/utilities/constants/group';
import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';
import { User, Group } from '@shared/welibrary-graphql/types';
import { GROUP_ACTIONS } from '@core/utilities/constants/group';
import {
    shouldPurchaseParentSubscription,
    shouldRequestToPurchaseParentSubscription,
    isPendingRequestToPurchaseParentSubscription,
} from '@components/group/groupdashboard/groupDashboardHelpers';

const GroupDashboardSubscriptionButton: React.FC<{
    group: Group;
    currentUser: User;
}> = ({ group, currentUser }) => {
    const history = useHistory();
    const { newModal } = useModal();

    const [addUserToGroup] = useAddUserToGroupMutation();

    const { takeGroupActionForUser, primaryActionAvailable, removeUser } = useGroupActions({
        group,
        refetchQueries: [GET_GROUP_DASHBOARD],
    });

    const parentSubscriptionGroup: Group = group?.parentSubscriptions?.[0];
    const defaultSubscription = parentSubscriptionGroup?.products?.[0];

    const _shouldPurchaseParentSubscription = shouldPurchaseParentSubscription(
        group,
        parentSubscriptionGroup
    );
    const _shouldRequestToPurchaseParentSubscription = shouldRequestToPurchaseParentSubscription(
        group,
        parentSubscriptionGroup
    );
    const _isPendingRequestToPurchaseParentSubscription =
        isPendingRequestToPurchaseParentSubscription(group, parentSubscriptionGroup);

    // ! dont think we need this here since were already checking on the groupDashboardMenu
    // ! if the group is a subscription or a parent has a subscription
    // ! redundant check
    // if (!takeGroupActionForUser || !primaryActionAvailable?.action) return <></>;

    const modalOptions = {
        hideButton: true,
        widen: false,
        addShadow: false,
        className: 'subs-parent-group-confirmation',
    };

    const handleRequestToJoin = () => {
        addUserToGroup({
            variables: {
                groupId: parentSubscriptionGroup._id,
                userId: currentUser._id,
                subgroupId: group?._id,
            },
            refetchQueries: [GET_GROUP_DASHBOARD],
        });
    };

    const handleCancelRequestToJoin = () => {
        addUserToGroup({
            variables: {
                groupId: parentSubscriptionGroup._id,
                userId: currentUser._id,
                remove: true,
            },
            refetchQueries: [GET_GROUP_DASHBOARD],
        });
    };

    const handleTakePrimaryAction = () => {
        if (!currentUser) {
            history.push(`/signup?mode=email&signupLink=true&returnTo=/g/${group?._id}`);
            return;
        }

        if (group?.currentUserOwnsFreeSubscription) {
            removeUser(currentUser?._id);
            return;
        }

        if (_shouldPurchaseParentSubscription) {
            // shortcut into checkout, with first product in group.products
            // currently only supporitng a single subscription type for groups
            newModal(
                <Checkout
                    subgroup={group}
                    group={parentSubscriptionGroup}
                    product={defaultSubscription}
                    quantity={1}
                />,
                {
                    className: 'no-top-padding no-bottom-padding',
                    hideButton: true,
                }
            );
        } else if (_shouldRequestToPurchaseParentSubscription) {
            newModal(
                <ParentGroupSubscriptionActionConfirmation
                    subgroup={group}
                    parentSubscriptionGroup={parentSubscriptionGroup}
                    handleRequestTojoin={handleRequestToJoin}
                    isRequestingToJoin
                />,
                modalOptions
            );
        } else if (_isPendingRequestToPurchaseParentSubscription) {
            newModal(
                <ParentGroupSubscriptionActionConfirmation
                    subgroup={group}
                    parentSubscriptionGroup={parentSubscriptionGroup}
                    handleCancelRequestToJoin={handleCancelRequestToJoin}
                    isRequestingToJoin={false}
                />,
                modalOptions
            );
        } else {
            takeGroupActionForUser(currentUser?._id, primaryActionAvailable?.action);
        }
    };

    let label: string = '';

    if (primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_EDIT_PRODUCTS) {
        label = 'Subscriptions';
    } else if (primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS) {
        label = 'Member';
    } else if (
        primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_PURCHASE_ACCESS ||
        _shouldPurchaseParentSubscription
    ) {
        label = 'Subscribe';
    } else if (
        primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_REQUEST_TO_PURCHASE ||
        _shouldRequestToPurchaseParentSubscription
    ) {
        label = 'Request To Join';
    } else if (
        primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_CANCEL_PRE_AUTH_PURCHASE ||
        primaryActionAvailable.action === GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN
    ) {
        label = 'Cancel Request';
    } else if (primaryActionAvailable?.action === GROUP_ACTIONS.GROUP_REACTIVATE_SUBSCRIPTION) {
        label = 'Member';
    } else if (_isPendingRequestToPurchaseParentSubscription) {
        label = 'Invite Pending';
    }

    if (group?.currentUserOwnsFreeSubscription) {
        label = 'Leave Group';
    }

    if (!currentUser) label = 'Create Account';

    return (
        <button onClick={handleTakePrimaryAction} type="button" className="paywall-action-btn">
            <div className="paywall-action-btn-text-wrap">{label}</div>
            <div className="paywall-action-btn-icon-wrap">
                <SimpleCurrency />
            </div>
        </button>
    );
};

export default GroupDashboardSubscriptionButton;
