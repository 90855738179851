// ../welibrary-constants/dist/library_settings.js
var ComparatorType = /* @__PURE__ */ ((ComparatorType2) => {
  ComparatorType2["MultiAndPos"] = "MULTI_AND_POS";
  ComparatorType2["MultiOrPos"] = "MULTI_OR_POS";
  ComparatorType2["None"] = "NONE";
  ComparatorType2["ProximityFar"] = "PROXIMITY_FAR";
  ComparatorType2["ProximityNear"] = "PROXIMITY_NEAR";
  ComparatorType2["RangePos"] = "RANGE_POS";
  return ComparatorType2;
})(ComparatorType || {});
var CustomSignupFieldTypes = /* @__PURE__ */ ((CustomSignupFieldTypes2) => {
  CustomSignupFieldTypes2["ChooseRole"] = "choose_role";
  CustomSignupFieldTypes2["ConsentBox"] = "consent_box";
  CustomSignupFieldTypes2["Description"] = "description";
  CustomSignupFieldTypes2["Dropdown"] = "dropdown";
  CustomSignupFieldTypes2["JoinGroup"] = "joinGroup";
  CustomSignupFieldTypes2["Numberslider"] = "numberslider";
  CustomSignupFieldTypes2["Paragraph"] = "paragraph";
  CustomSignupFieldTypes2["ProfileLocation"] = "profile_location";
  CustomSignupFieldTypes2["ProfileOrganization"] = "profile_organization";
  CustomSignupFieldTypes2["Shortanswer"] = "shortanswer";
  CustomSignupFieldTypes2["Signature"] = "signature";
  CustomSignupFieldTypes2["UploadFile"] = "uploadFile";
  return CustomSignupFieldTypes2;
})(CustomSignupFieldTypes || {});
var ReferenceType = /* @__PURE__ */ ((ReferenceType2) => {
  ReferenceType2["Book"] = "book";
  ReferenceType2["Chapter"] = "chapter";
  ReferenceType2["Group"] = "group";
  ReferenceType2["Library"] = "library";
  ReferenceType2["Prompt"] = "prompt";
  ReferenceType2["Shelf"] = "shelf";
  ReferenceType2["User"] = "user";
  return ReferenceType2;
})(ReferenceType || {});
var FILTER_AZ = "az";
var FILTER_LIVE = "live";
var FILTER_TOP = "top";
var FILTER_LEADERBOARD = "leaderboard";
var FILTER_ORDERED = "ordered";
var FILTER_ARCHIVE = "archived";
var FILTER_OLDEST_FIRST = "oldestFirst";
var SORT_RECENT = "recentFirst";
var DISP_REF_LIBRARY = ReferenceType.Library;
var DISP_REF_SHELF = ReferenceType.Shelf;
var DISP_REF_BOOK = ReferenceType.Book;
var DISP_REF_CHAPTER = ReferenceType.Chapter;
var DISP_REF_PROMPT = ReferenceType.Prompt;
var DISP_REF_GROUP = ReferenceType.Group;
var DISP_REF_USER = ReferenceType.User;
var SIGNUP_FIELD_TYPE_DROPDOWN = CustomSignupFieldTypes.Dropdown;
var SIGNUP_FIELD_TYPE_SHORTANSWER = CustomSignupFieldTypes.Shortanswer;
var SIGNUP_FIELD_TYPE_PARAGRAPH = CustomSignupFieldTypes.Paragraph;
var SIGNUP_FIELD_TYPE_CONSENT_BOX = CustomSignupFieldTypes.ConsentBox;
var SIGNUP_FIELD_TYPE_CHOOSE_ROLE = CustomSignupFieldTypes.ChooseRole;
var SIGNUP_FIELD_TYPE_JOIN_GROUP = CustomSignupFieldTypes.JoinGroup;
var SIGNUP_FIELD_TYPE_LOCATION = CustomSignupFieldTypes.ProfileLocation;
var SIGNUP_FIELD_TYPE_ORGANIZATION = CustomSignupFieldTypes.ProfileOrganization;
var COMPARATOR_NONE = ComparatorType.None;
var COMPARATOR_MULTI_AND_POS = ComparatorType.MultiAndPos;
var COMPARATOR_MULTI_OR_POS = ComparatorType.MultiOrPos;
var COMPARATOR_RANGE_POS = ComparatorType.RangePos;
var COMPARATOR_PROXIMITY_NEAR = ComparatorType.ProximityNear;
var COMPARATOR_PROXIMITY_FAR = ComparatorType.ProximityFar;
var SIGNUP_FIELD_COMPARATORS = {
  [SIGNUP_FIELD_TYPE_SHORTANSWER]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [SIGNUP_FIELD_TYPE_PARAGRAPH]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [SIGNUP_FIELD_TYPE_DROPDOWN]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Sum of Matches, more is better" },
      { value: COMPARATOR_MULTI_OR_POS, display: "Any Match, more is same" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_MULTI_AND_POS
  },
  [SIGNUP_FIELD_TYPE_CONSENT_BOX]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [SIGNUP_FIELD_TYPE_CHOOSE_ROLE]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Sum of Matches, more is better" },
      { value: COMPARATOR_MULTI_OR_POS, display: "Any Match, more is same" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_MULTI_AND_POS
  },
  [SIGNUP_FIELD_TYPE_JOIN_GROUP]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [SIGNUP_FIELD_TYPE_LOCATION]: {
    options: [
      { value: COMPARATOR_PROXIMITY_NEAR, display: "Match users that are closer together" },
      { value: COMPARATOR_PROXIMITY_FAR, display: "Match users that are futher apart" }
    ],
    default: COMPARATOR_PROXIMITY_NEAR
  },
  [SIGNUP_FIELD_TYPE_ORGANIZATION]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Sum of Matches, more is better" },
      { value: COMPARATOR_MULTI_OR_POS, display: "Any Match, more is same" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_MULTI_AND_POS
  },
  [CustomSignupFieldTypes.Numberslider]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [CustomSignupFieldTypes.UploadFile]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [CustomSignupFieldTypes.Description]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  },
  [CustomSignupFieldTypes.Signature]: {
    options: [
      { value: COMPARATOR_MULTI_AND_POS, display: "Match With This Field" },
      { value: COMPARATOR_NONE, display: "Do Not Match With This Field" }
    ],
    default: COMPARATOR_NONE
  }
};
var PERSONAL_INFO_FIELDS = {
  [SIGNUP_FIELD_TYPE_LOCATION]: {
    type: SIGNUP_FIELD_TYPE_LOCATION,
    customText: "Proximity",
    displayName: "Request Location"
  },
  [SIGNUP_FIELD_TYPE_ORGANIZATION]: {
    type: SIGNUP_FIELD_TYPE_ORGANIZATION,
    customText: "Organization",
    displayName: "Request Organization"
  }
};

// src/helpers/sort.ts
var sortHelpers = {
  getNestedReferencedCacheValue: (obj, field, readField, isDate) => {
    const fields = field?.split(".");
    if (fields.length === 1)
      return obj[field];
    let value = obj;
    fields.forEach((field2) => {
      if (field2 && readField)
        value = readField(field2, value);
      if (field2 && !readField && value.hasOwnProperty(field2))
        value = value[field2];
    });
    return isDate ? new Date(value) : value;
  },
  sortList: (list, gqlType, sortType, readField) => {
    if (!list)
      return list;
    switch (gqlType) {
      case `PaginatedCards`:
        return sortHelpers.sortContentCards(list, sortType, readField);
      case `PaginatedGroups`:
        return sortHelpers.getGroupSortOptions(list, sortType, readField);
      case `PaginatedUsers`:
        return sortHelpers.getUserSortOptions(list, sortType, readField);
      case `PaginatedComments`:
        return sortHelpers.getCommentSortOptions(list, sortType, readField);
      case `PaginatedTags`:
        return sortHelpers.getTagSortOptions(list, sortType, readField);
      case `PaginatedTaskCompletion`:
        return sortHelpers.getTaskSortOptions(list, sortType, readField);
      case `PaginatedMessageThreads`:
        return sortHelpers.getMessageThreadSortOptions(
          list,
          FILTER_LIVE,
          readField
        );
      case `PaginatedMessages`:
      default:
        return list;
    }
  },
  sortContentCards: (list, sortType, readField) => {
    let isCursorDate = false;
    let cursorComparator = 1;
    let cursorField = "title";
    if (sortType == FILTER_TOP) {
      cursorField = "ratings_rank";
      cursorComparator = -1;
    } else if (sortType == FILTER_LIVE) {
      cursorField = "date.publish";
      cursorComparator = -1;
      isCursorDate = true;
    } else if (sortType == FILTER_OLDEST_FIRST) {
      cursorField = "date.publish";
      cursorComparator = 1;
      isCursorDate = true;
    } else if (sortType == FILTER_AZ || sortType == FILTER_ARCHIVE) {
      cursorField = "title";
      cursorComparator = 1;
    } else if (sortType == FILTER_LEADERBOARD) {
      cursorField = "ratings_rank";
      cursorComparator = -1;
    } else if (sortType == FILTER_ORDERED) {
      cursorField = "rankOrder";
      cursorComparator = -1;
    } else if (sortType == "reverseOrder") {
      cursorField = "rankOrder";
      cursorComparator = 1;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getTagSortOptions: (list, sortType, readField) => {
    let isCursorDate = true;
    let cursorComparator = 1;
    let cursorField = "updated";
    if (sortType == FILTER_LIVE) {
      cursorField = "updated";
      cursorComparator = 1;
      isCursorDate = true;
    } else if (sortType == FILTER_AZ) {
      cursorField = "label";
      cursorComparator = 1;
      isCursorDate = false;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getUserSortOptions: (list, sortType, readField) => {
    let isCursorDate = false;
    let cursorComparator = 1;
    let cursorField = "profile.full_name";
    if (sortType == FILTER_LIVE) {
      cursorField = "createdAt";
      cursorComparator = 1;
      isCursorDate = true;
    } else if (sortType == FILTER_AZ) {
      cursorField = "profile.full_name";
      cursorComparator = 1;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getGroupSortOptions: (list, sortType, readField) => {
    let isCursorDate = false;
    let cursorComparator = 1;
    let cursorField = "profile.full_name";
    if (sortType == FILTER_LIVE) {
      cursorField = "createdAt";
      cursorComparator = 1;
      isCursorDate = true;
    } else if (sortType == FILTER_AZ) {
      cursorField = "profile.full_name";
      cursorComparator = 1;
    } else if (sortType == SORT_RECENT) {
      cursorField = "currentUserLastActiveDate";
      cursorComparator = 1;
      isCursorDate = true;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getCommentSortOptions: (list, sortType, readField) => {
    let isCursorDate = true;
    let cursorComparator = 1;
    let cursorField = "publish";
    if (sortType == FILTER_LIVE) {
      cursorField = "publish";
      cursorComparator = -1;
      isCursorDate = true;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getTaskSortOptions: (list, sortType, readField) => {
    let isCursorDate = true;
    let cursorComparator = 1;
    let cursorField = "date";
    if (sortType == FILTER_LIVE) {
      cursorField = "date";
      cursorComparator = -1;
      isCursorDate = true;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  },
  getMessageThreadSortOptions: (list, sortType, readField) => {
    let isCursorDate = true;
    let cursorComparator = -1;
    let cursorField = "updated";
    if (sortType == FILTER_LIVE) {
      cursorField = "updated";
      cursorComparator = -1;
      isCursorDate = true;
    }
    return list.sort(
      (a, b) => (Number(
        sortHelpers.getNestedReferencedCacheValue(
          a,
          cursorField,
          readField,
          isCursorDate
        )
      ) - Number(
        sortHelpers.getNestedReferencedCacheValue(
          b,
          cursorField,
          readField,
          isCursorDate
        )
      )) * cursorComparator
    );
  }
};
export {
  sortHelpers
};
