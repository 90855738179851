/**
 * Gets the Major Version from a semver string, returning -1 for invalid strings
 */
export const getMajorVersion = (version: string) => {
    if (!version?.includes('.')) return -1;

    return Number(version.split('.')[0]);
};

/**
 * Gets the Minor Version from a semver string, returning -1 for invalid strings
 */
export const getMinorVersion = (version: string) => {
    if (!version?.includes('.')) return -1;

    return Number(version.split('.')[1]);
};

/**
 * Gets the Patch Version from a semver string, returning -1 for invalid strings
 */
export const getPatchVersion = (version: string) => {
    if (!version?.includes('.')) return -1;

    return Number(version.split('.')[2]);
};

/**
 * Gets the Build Version from a semver string, returning -1 for invalid strings
 */
export const getBuildVersion = (version: string) => {
    if (!version?.includes('+')) return -1;

    return Number(version.split('+')[1]);
};

/**
 * Compares two semver strings, returning 1 if the first is higher, -1 if its lower, and 0 if they are equal
 */
export const compareVersions = (version1: string, version2: string) => {
    if (!version1?.includes('.') || !version2?.includes('.'))
        throw new Error('Invalid version strings');

    const [majorVersion1, minorVersion1, patchVersion1] = version1.split('.');
    const [majorVersion2, minorVersion2, patchVersion2] = version2.split('.');

    if (majorVersion1 !== majorVersion2) return majorVersion1 > majorVersion2 ? 1 : -1;

    if (minorVersion1 !== minorVersion2) return minorVersion1 > minorVersion2 ? 1 : -1;

    if (patchVersion1 !== patchVersion2) return patchVersion1 > patchVersion2 ? 1 : -1;

    return 0;
};
