import React from 'react';
import { Flipped } from 'react-flip-toolkit';

import DragIcon from '@web/ui/dsc/svgs/DragIcon';
import Picture from '@web/ui/components/generic/Picture';

import { getRandomDefaultThumbImage } from '@core/utilities/constants/defaults';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

/** @type React.FC<{
 *      prompt: {
 *          _id: string,
 *          prompt: string,
 *          newPrompt: string,
 *          media: { thumb: string },
 *          reference: { coverImage: string, bookSource: { title: string } },
 *      },
 *      addPrompt: boolean | (id: string) => void,
 *      hidePrompt: boolean,
 *      updatePrompt: (id: string, value: string) => void,
 *      deletePrompt: boolean | (id: string) => void,
 *      className: string,
 *      draggable: boolean,
 *      dragRef: React.MutableRefObject<HTMLDivElement>,
 *      activeGroupId: string,
 *  }>
 */
const PromptItem = React.memo(
    React.forwardRef(function PromptItem(
        {
            prompt,
            addPrompt,
            updatePrompt,
            deletePrompt,
            className,
            draggable,
            dragRef,
            hidePrompt,
            activeGroupId,
        },
        ref
    ) {
        const groupSource = prompt?.reference?.groupSource;
        const groupSourceName = groupSource?.profile?.full_name;
        const isFromActiveGroup = activeGroupId && groupSource?._id === activeGroupId;
        const { theme } = getWhitelabelPublicSettings();

        return (
            <Flipped flipId={prompt._id}>
                <li
                    ref={ref}
                    className={`prompt-list-item ${draggable ? 'draggable' : ''} ${
                        className || ''
                    }`}
                >
                    {draggable && <div role="presentation" className="drag-box" ref={dragRef} />}

                    <section className="prompt-list-item-info-section">
                        {draggable && <DragIcon className="drag-icon" />}

                        <div role="img" aria-label={`${prompt?.title} thumbnail`}>
                            <Picture
                                url={
                                    prompt?.media?.thumb ||
                                    prompt?.reference?.coverImage ||
                                    getRandomDefaultThumbImage(prompt?.title)
                                }
                                disableLink
                            />
                        </div>
                        <article>
                            <h3>{prompt?.title}</h3>
                            <span>{prompt?.reference?.bookSource?.title}</span>
                            {groupSourceName && (
                                <p
                                    style={
                                        isFromActiveGroup
                                            ? { color: theme?.primaryColor || '#0094f6' }
                                            : null
                                    }
                                >
                                    from {groupSourceName}
                                </p>
                            )}
                        </article>
                    </section>

                    <section className="prompt-list-item-actions-section">
                        {!hidePrompt && (
                            <input
                                type="text"
                                value={prompt?.newPrompt ?? ''}
                                placeholder={prompt?.prompt ?? 'Share Something'}
                                onChange={e => updatePrompt(prompt._id, e.target.value)}
                                disabled={!updatePrompt}
                            />
                        )}
                        {addPrompt && (
                            <button
                                type="button"
                                onClick={() => addPrompt?.(prompt)}
                                className="add-button"
                            >
                                <img src="/images/icon.white.plus.svg" alt="Select Prompt" />
                            </button>
                        )}
                        {deletePrompt && (
                            <button
                                type="button"
                                onClick={() => deletePrompt?.(prompt._id)}
                                className="delete-button"
                            >
                                <img src="/images/icon.white.trash.svg" alt="Deselect Prompt" />
                            </button>
                        )}
                    </section>
                </li>
            </Flipped>
        );
    })
);

export default PromptItem;
