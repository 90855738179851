import React from 'react';

import LocationOptimizerField from '@web/ui/components/optimizers/LocationOptimizerField';
import OrganizationOptimizerField from '@web/ui/components/optimizers/OrganizationOptimizerField';

import {SIGNUP_FIELD_TYPE_LOCATION, SIGNUP_FIELD_TYPE_ORGANIZATION,} from '@core/utilities/constants/library_settings';

import {CustomSignupField} from '@shared/welibrary-graphql/types';
import {SignupState} from '@core/types/Signup';

type PersonalInfoQuestionsProps = {
    state: { [key: string]: { value: string[] | null } };
    signupState: SignupState;
    setSignupState: (state: Partial<SignupState>) => void;
    handleCustomChange: (id: string, values: string | string[] | null, type: string) => void;
    signupChunk: CustomSignupField[];
    signup?: boolean;
};

const PersonalInfoQuestions: React.FC<PersonalInfoQuestionsProps> = ({
    state,
    signupState,
    setSignupState,
    handleCustomChange,
    signupChunk,
    signup,
}) => {
    const locationField = signupChunk.find(field => field.type === SIGNUP_FIELD_TYPE_LOCATION);
    const organizationField = signupChunk.find(
        field => field.type === SIGNUP_FIELD_TYPE_ORGANIZATION
    );

    return (
        <>
            {locationField && (
                <LocationOptimizerField
                    state={state}
                    signupState={signupState}
                    setSignupState={setSignupState}
                    locationField={locationField}
                    onChange={handleCustomChange}
                    signup={signup}
                />
            )}
            {organizationField && (
                <OrganizationOptimizerField
                    state={state}
                    signupState={signupState}
                    setSignupState={setSignupState}
                    organizationField={organizationField}
                    onChange={handleCustomChange}
                    signup={signup}
                />
            )}
        </>
    );
};

export default PersonalInfoQuestions;
