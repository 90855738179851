import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';
import useRefetchOnResume from '@web/ui/components/generic/hooks/useRefetchOnResume';
import useInfiniteScroll from '@web/ui/components/generic/hooks/useInfiniteScroll';

import ContactBlock from '@web/ui/components/messaging/shareContent/ContactBlock';
import LoadingIndicator from '@web/ui/components/generic/loading/LoadingIndicator';
import SearchInput from '@components/search/SearchInput';
import { GET_USER_MESSAGE_THREADS } from '@shared/welibrary-graphql/messaging/queries';

const localNamespace = 'imports.wlWeb.ui.components.messaging';
const ContactList = ({ card }) => {
    const { t } = useTranslation();

    const { currentUser } = useCurrentUser();

    const [searchText, setSearchText] = useState('');

    const {
        setRef,
        queryResult: { refetch, data: userMessageThreadsData, loading: userMessageThreadsLoading },
    } = useInfiniteScroll({
        query: {
            query: GET_USER_MESSAGE_THREADS,
            options: {
                variables: { userId: currentUser?._id, searchQuery: searchText },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                skip: !currentUser?._id,
            },
        },
        visibleOffset: 100,
    });

    useRefetchOnResume(refetch);

    const hasMore = userMessageThreadsData?.getUserMessageThreads?.hasMore;
    const threadList = userMessageThreadsData?.getUserMessageThreads?.threads;

    const suggestionsList = threadList?.map((item, index) => {
        return (
            <ContactBlock
                ref={index === threadList.length - 1 ? setRef : undefined}
                key={index}
                data={item}
                card={card}
            />
        );
    });

    return (
        <div className="scrollable suggested-connections">
            <h2 className="messages-share-title">{t(`common:${localNamespace}.messages`)}</h2>

            <div className="messages-share-search-input-container">
                <SearchInput
                    onChange={value => setSearchText(value)}
                    value={searchText}
                    placeholder={t(`common:${localNamespace}.search`, 'Search...')}
                />
            </div>

            {suggestionsList && !userMessageThreadsLoading && suggestionsList}

            {userMessageThreadsLoading && !suggestionsList && (
                <LoadingIndicator type="skeleton" size="fill" />
            )}

            {hasMore && <LoadingIndicator type="skeleton" size="fill" />}
        </div>
    );
};

export default ContactList;
