import React from 'react';

import { useGetEventGroupQuery } from '@shared/welibrary-graphql/user/queries.hook';

import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import EventImageWithMetaData from '@components/events/EventImageWithMetaData';
import EventGroupCardInfo from '@components/events/EventGroupCardInfo';
import EventGroupCardButtons from '@components/events/EventGroupCardButtons';

import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/circle-spinner.json';

import { ContentCard } from '@shared/welibrary-graphql/types';

const loaderStyle = {
    opacity: '0.5',
};

const GroupItem: React.FC<{ card: ContentCard }> = ({ card }) => {
    const { loading: groupLoading, data: groupData } = useGetEventGroupQuery({
        variables: { _id: card?.aliases?.[0]?.url! },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !card?.aliases?.[0]?.url,
    });

    if (groupLoading && !groupData) {
        return (
            <div className="card-loading group-item">
                <LottieLoading
                    customStyle={loaderStyle}
                    height={80}
                    width={80}
                    lottieData={LottieAnimation}
                    isPaused={false}
                />
            </div>
        );
    }
    const group = groupData?.groupById;

    if (!group?.profile && !groupLoading) {
        const iconStyle = {
            width: '50px',
            height: '50px',
            backgroundSize: '40px',
            marginBottom: '10px',
        };
        return (
            <div className="card-loading group-item">
                <div style={iconStyle} className="event-icon date" />
                <strong>No Event info found. This event may have been deleted.</strong>
            </div>
        );
    }

    return (
        <ErrorBoundary>
            <div className="event-group-card-container">
                <EventImageWithMetaData group={group} />

                <section className="event-group-detail-section">
                    <EventGroupCardInfo group={group} />

                    <EventGroupCardButtons group={group} />
                </section>
            </div>
        </ErrorBoundary>
    );
};

export default GroupItem;
