import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import useConfirmation from '@components/generic/hooks/useConfirmation';

import _ from 'lodash';

// Google Doc Tracking Supported Languages
// https://docs.google.com/spreadsheets/d/1-DbtAfihCoGcfSovixcJe8YmzHN3Nwh4lc6WtlNW3T0/edit#gid=1876883063
export const allLanguages = {
    '-none-': 'Select language...',
    'en-US': 'English (US)',
    af: 'Afrikaans',
    'am-et': 'Amharic',
    'ar-dz': 'Arabic (Algeria)',
    'ar-bh': 'Arabic (Bahrain)',
    'ar-eg': 'Arabic (Egypt)',
    'ar-iq': 'Arabic (Iraq)',
    'ar-jo': 'Arabic (Jordan)',
    'ar-kw': 'Arabic (Kuwait)',
    'ar-lb': 'Arabic (Lebanon)',
    'ar-ly': 'Arabic (Libya)',
    'ar-ma': 'Arabic (Morocco)',
    'ar-om': 'Arabic (Oman)',
    'ar-qa': 'Arabic (Qatar)',
    'ar-sa': 'Arabic (Saudi Arabia)',
    'ar-sy': 'Arabic (Syria)',
    'ar-tn': 'Arabic (Tunisia)',
    'ar-ae': 'Arabic (U.A.E.)',
    'ar-ye': 'Arabic (Yemen)',
    ar: 'Arabic',
    hy: 'Armenian',
    'eu-ES': 'Basque',
    bn: 'Bengali',
    bg: 'Bulgarian',
    ca: 'Catalan',
    'zh': 'Chinese (PRC)',
    'zh-TW': 'Chinese (Taiwan)',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    nl: 'Dutch',
    'nl-BE': 'Dutch (Belgium)',
    // "nl-NL"  : "Dutch (Netherlands)",
    // "en-GB"  : "English (UK)",
    et: 'Estonian',
    fa: 'Farsi (Persian)',
    fi: 'Finnish',
    fr: 'French',
    ka: 'Georgian',
    de: 'German',
    el: 'Greek',
    gu: 'Gujarati',
    he: 'Hebrew',
    hi: 'Hindi',
    hu: 'Hungarian',
    is: 'Icelandic',
    id: 'Indonesian',
    it: 'Italian',
    ja: 'Japanese',
    // "jv"  : "Javanese",
    kn: 'Kannada',
    km: 'Khmer',
    ko: 'Korean',
    lo: 'Lao',
    lv: 'Latvian',
    lt: 'Lithuanian',
    ms: 'Malay',
    mr: 'Marathi',
    nn: 'Norwegian',
    pl: 'Polish',
    'pt-BR': 'Portuguese (Brazil)',
    'pt-PT': 'Portuguese (Portugal)',
    pa: 'Punjabi',
    ro: 'Romanian',
    ru: 'Russian',
    sr: 'Serbian',
    sk: 'Slovak',
    sl: 'Slovenian',
    es: 'Spanish',
    sw: 'Swahili',
    sv: 'Swedish',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thai',
    tr: 'Turkish',
    uk: 'Ukrainian',
    ur: 'Urdu',
    vi: 'Vietnamese',
    'cy-GB': 'Welsh',
    // "to" : "Tonga",
    // "sn" : "Shona",
    // "ach"    : "Acholi",
    // "as" : "Assamese",
    // "bem"    : "Bemba",
    // "brx"  : "Bodo",
    my: 'Burmese',
    // "chr"    : "Cherokee",
    // "bnt"    : "Chewa (Bantu)",
    // "cnb"  : "Chinbon Chin",
    // "fil"    : "Filipino",
    // "ht"  : "Haitian-Creole",
    // "zkd"  : "Kado",
    // "kqn"  : "Kaonde (Bantu)",
    // "bcp"  : "Kibali or Bali (Bantu)",
    // "rw" : "Kinyarwandan",
    // "rn"  : "Kirundi",
    // "loz"  : "Lozi (Bantu)",
    // "lun"  : "Lunda (DRC)",
    // "lue"  : "Luvale (Bantu)",
    // "mg" : "Malagasy",
    ml: 'Malayalam',
    // "nag"  : "Nagamese",
    // "nd" : "Ndebele",
    ne: 'Nepali',
    or: 'Odia',
    // "om" : "Oromo",
    // "st"  : "Sesotho (Bantu)",
    // "si"  : "Sinhalese",
    // "ss" : "Siswati (Bantu)"
} as const;

export const isSupportedLanguage = (languageCode: string) => {
    if (!languageCode) return false;
    if (languageCode === '-none-') return false;
    return Object.keys(allLanguages).includes(languageCode);
};

type GetLanguagePickerProps = {
    value?: string;
    handleChange: ChangeEventHandler<HTMLSelectElement>;
    id?: string;
    name?: string;
    className?: string;
    enableSwitchLanguage?: boolean;
    globeIcon?: boolean;
};

export const getLanguagePicker: React.FC<GetLanguagePickerProps> = ({
    value = '-none-',
    handleChange,
    id,
    name,
    className,
    enableSwitchLanguage,
    globeIcon,
}) => {
    const { t, i18n } = useTranslation();
    const confirm = useConfirmation();

    const _handleChange: ChangeEventHandler<HTMLSelectElement> = async e => {
        const languageCode = e.target.value;

        await i18n.loadLanguages([languageCode]);

        if (enableSwitchLanguage && isSupportedLanguage(languageCode)) {
            const languageName = t(`common:languages.names.${languageCode}`, { lng: languageCode });
            const confirmation = t(`common:you_selected`, 'You selected', { lng: languageCode });

            await confirm({
                text: `${confirmation}: ${languageName}`,
                onConfirm: () => {
                    i18n.changeLanguage(languageCode);

                    handleChange(languageCode);
                },
            });
        }
    };

    const options = Object.keys(allLanguages).map(key => (
        <option key={key} value={key}>
            {t(`common:languages.names.${key}`)}
        </option>
    ));

    return (
        <>
            {globeIcon && <div className="language-globe-image" />}
            <select
                value={value}
                onChange={_handleChange}
                id={id}
                name={name}
                className={className}
                aria-label="language selector"
            >
                {options}
            </select>
        </>
    );
};

export const getWhitelabeledKey = (key, whitelabelVerbage) => {
    if (whitelabelVerbage) {
        const altVerbage = _.find(whitelabelVerbage, { _id: key });
        return altVerbage ? altVerbage.value : key;
    }
    return key;
};
