import React, { useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { LIST_CARDS } from '@shared/welibrary-graphql/content_card/queries';
import useDebouncedState from '@web/ui/components/generic/hooks/useDebouncedState';
import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';
import { CARD_TYPES } from '@core/utilities/constants/card_types.js';
import { FILTERS_META } from '@web/ui/components/search/constants';
import Picture from '@components/generic/Picture';
import { Entity } from '@core/types/Search';
import { AliasHandle, ContentCard } from '@shared/welibrary-graphql/types';
import Plus from '@web/ui/dsc/svgs/FullSizePlus';

type useSearchFormProps = {
    addResult: (result: Entity) => void;
    suggestionsFilter: Array<Partial<AliasHandle> | null>;
    types?: Array<keyof typeof CARD_TYPES>;
    allSuggestions: Array<keyof Omit<typeof FILTERS_META, 'ALL'>>;
};

const useSearchFormListCards = ({
    addResult,
    suggestionsFilter,
    types,
    allSuggestions,
}: useSearchFormProps) => {
    const [filter, setFilter] = useState<keyof typeof FILTERS_META>('All');
    const [query, actualQuery, setQuery] = useDebouncedState('');

    const { data, loading, error } = useQuery(LIST_CARDS, {
        fetchPolicy: 'no-cache',
        variables: {
            searchQuery: actualQuery,
            limit: 30,
            showEmpty: false,
            types,
        },
        skip: !query,
    });

    const results =
        data?.listCards?.map((card, index) => (
            <SearchFormListCardResult addResult={addResult} card={card} key={card?._id} />
        )) ?? [];

    const emptyResults = !loading && _.isEmpty(results) && query !== '';
    const emptyState = query === '' && actualQuery === '';
    const areResults = results && results.length > 0;

    return {
        query,
        setQuery,
        filter,
        setFilter,
        emptyResults,
        emptyState,
        areResults,
        loading,
        results,
    };
};

export default useSearchFormListCards;

const SearchFormListCardResult: React.FC<{
    card: Partial<ContentCard>;
    addResult: (result: Partial<ContentCard>) => void;
}> = ({ card, addResult }) => {
    if (!card) return <></>;

    const { title, mediaThumb, reference } = card;

    return (
        <li className="search-form-result">
            <Picture
                url={mediaThumb || getRandomDefaultCoverImage(title)}
                disableLink
                resolutions={[130, 260, 390]}
            />

            <section>
                {reference && (
                    <h2 className="reference">{`in ${
                        reference?.chapterSource?.title ??
                        reference?.groupSource?.profile?.full_name
                    }`}</h2>
                )}
                <h3>{title}</h3>
            </section>

            <button type="button" onClick={() => addResult(card)}>
                <Plus />
            </button>
        </li>
    );
};
