import React from 'react';

import Pencil from '@dsc/svgs/Pencil';

import { Coupon } from '@components/content/newpost/forms/ShoppingCartProducts/ticketHelpers';

const CouponItem: React.FC<{
    index: number;
    coupon: Coupon;
    handleEditCoupon: (couponToEditIndex: number, coupon: Coupon) => void;
    deleteCoupon: (index: number) => void;
}> = ({ index, coupon, handleEditCoupon, deleteCoupon }) => {
    // convert float ie: 0.75 -> whole number 75 to display as percentage off
    const couponPercentage = coupon?.percentage * 100;

    return (
        <li className="discount-code-item">
            <div className="discount-code-item-info-wrap">
                <p className="discount-code-item-name">{coupon.name}</p>
                <p className="discount-code-item-percent">
                    {couponPercentage}% off &#x000B7; {coupon.code.toUpperCase()}
                </p>
            </div>
            <div className="discount-code-button-wrap">
                <button
                    className="discount-code-btn-edit"
                    onClick={() => handleEditCoupon(index, coupon)}
                    type="button"
                >
                    <Pencil />
                </button>
                <button
                    className="discount-code-btn-delete"
                    onClick={() => deleteCoupon(index)}
                    type="button"
                >
                    X
                </button>
            </div>
        </li>
    );
};

export default CouponItem;
