import React from 'react';

import {AliasItem} from '@shared/welibrary-graphql/types';

type LinkTileListPreviewProps = {
    aliases: AliasItem[];
    defaultViewType: string;
    displayInNewsfeed: boolean;
    meta: [];
    title: string;
};

const LinkTileListPreview: React.FC<{ source: LinkTileListPreviewProps }> = ({ source }) => {
    const showFullWidth = source.aliases.length <= 5;

    // alias item list
    const aliasesList = source.aliases.map((alias, index) => (
        <li
            className={`linkTile-list-preview-item ${
                showFullWidth ? 'linkTile-list-view-item-full' : 'linkTile-list-view-item-column'
            }`}
            key={`${alias.url}-${index}`}
        >
            <img className="linkTile-list-preview-item-icon" src={alias.icon} alt="" />
            <p className="linkTile-list-preview-item-link">{alias.title}</p>
            <div className="linkTile-list-view-item-arrow" />
        </li>
    ));

    return (
        <>
            {source?.title?.length > 0 && (
                <div className="linkTile-list-title-preview-wrap">
                    <h4 className="linkTile-list-title-preview">{source.title}</h4>
                </div>
            )}
            <ul
                className={`linkTile-list-preview ${
                    !source.title && 'linkTile-list-view-marginTop'
                }`}
            >
                {aliasesList}
            </ul>
        </>
    );
};

export default LinkTileListPreview;
