/** @prettier */

import React from 'react';

import FilterTagsList from '@web/ui/components/chapter/FilterTagsList';

const SelectTagsModal = ({ handleFilterTag, activeTagFilters, filteredList }) => (
    <div className="select-menu-modal">
        <div className="select-menu-header">
            <span className="select-tag-menu-title">Filter by tags</span>
        </div>
        <ul className="filter-tags-list" style={{ padding: '5px' }}>
            <FilterTagsList
                activeTagFilters={activeTagFilters}
                handleClick={handleFilterTag}
                filterTagsList={filteredList}
            />
        </ul>
    </div>
);

export default SelectTagsModal;
