import React, { useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';

import SearchInput from '@components/search/SearchInput';
import UserTicketListItem from './UserTicketListItem';
import QRCodeIcon from '@dsc/svgs/QRCodeIcon';
import Select from '@dsc/forms/customInputs/Select';
import SearchLoader from '@components/search/SearchLoader';

import QRCodeScannerStore from '@web/ui/stores/QRCodeScannerStore';

import useModal from '@components/modals/hooks/useModal';

import ModalLoading from '@components/modals/ModalLoading';

import { getSortedTicketsWithLastNameFirst } from '@web/utilities/helpers/checkIn.helpers';
import { CustomerOrder, Group } from '@shared/welibrary-graphql/types';
import { SetState } from '@core/types/Utilities';

const TABS_ENUM = {
    all: 'All',
    checkedIn: 'Checked In',
    notCheckedIn: 'Not Checked In',
} as const;
type TabType = (typeof TABS_ENUM)[keyof typeof TABS_ENUM];

type CheckInProps = {
    group: Group,
    orders: CustomerOrder[];
    ordersLoading: boolean;
    searchString: string;
    setSearchString: SetState<string>;
};

const CheckIn: React.FC<CheckInProps> = ({
    group,
    orders,
    ordersLoading,
    searchString,
    setSearchString,
}) => {
    const { t } = useTranslation();
    const { closeAllModals } = useModal();
    const isMobileApp = !!Capacitor.isNativePlatform();

    const [currentTab, setCurrentTab] = useState<TabType>(TABS_ENUM.all);

    let tickets = getSortedTicketsWithLastNameFirst(orders);

    if (searchString) {
        // Filter to only tickets that match the search
        searchString = searchString.toLowerCase();
        tickets = tickets.filter(t => {
            const { full_name: name, phone, email } = t.owner.profile;
            if (
                name.toLowerCase().includes(searchString) ||
                email?.toLowerCase().includes(searchString) ||
                phone?.includes(searchString)
            ) {
                // simple match 👍
                return true;
            }

            // Let's handle spaces in the search string so that a search of "first last"
            // will match a ticket with the name "last, first"
            const splitSearch = searchString.split(' ');
            if (splitSearch.length > 1) {
                const match = splitSearch.reduce((allMatch, subSearchString) => {
                    return allMatch && name.toLowerCase().includes(subSearchString);
                }, true);

                return match;
            }

            return false;
        });
    }

    const checkedInTickets = tickets.filter(t => t.isCheckedIn);
    const notCheckedInTickets = tickets.filter(t => !t.isCheckedIn);

    const tabDisplayText: Record<TabType, string> = {
        [TABS_ENUM.all]: `${t(`common:global.all`, 'All')} (${tickets.length})`,
        [TABS_ENUM.checkedIn]: `${t(`common:global.checked_in`, 'Checked In')} (${checkedInTickets.length
            })`,
        [TABS_ENUM.notCheckedIn]: `${t(`common:global.not_checked_in`, 'Not Checked In')} (${notCheckedInTickets.length
            })`,
    };

    if (currentTab === TABS_ENUM.checkedIn) tickets = checkedInTickets;
    else if (currentTab === TABS_ENUM.notCheckedIn) tickets = notCheckedInTickets;

    const showScanner = () => {
        closeAllModals();
        QRCodeScannerStore.set.activeGroupId(group?._id);
        QRCodeScannerStore.set.showScanner(true);
    };

    const handleScan = async () => {
        const scannerPermissions = await BarcodeScanner.checkPermissions();

        if (scannerPermissions.camera === 'granted') {
            showScanner();
            return;
        } else if (scannerPermissions.camera === 'denied') {
            return;
        } else if (scannerPermissions.camera === 'prompt') {
            const reqScannerPermissions = await BarcodeScanner.requestPermissions();

            if (reqScannerPermissions.camera === 'granted') {
                showScanner();
            }
            return;
        } else {
            const reqScannerPermissions = await BarcodeScanner.requestPermissions();

            if (reqScannerPermissions.camera === 'granted') {
                showScanner();
            }
        }
    };

    return (
        <>
            <SearchInput
                value={searchString}
                onChange={setSearchString}
                className="check-in-search-field text-[16px]"
                placeholder={t(
                    `common:global.search_name_email_or_phone`,
                    'Search name, email, or phone'
                )}
            />

            <div className="flex gap-[20px]">
                <Select
                    value={currentTab}
                    displayValues={tabDisplayText}
                    options={Object.values(TABS_ENUM)}
                    onChange={newTab => setCurrentTab(newTab)}
                    className="!my-0"
                    inputClassName="!w-full flex items-center !mb-0 !py-[10px] !px-[20px] !text-[14px] !font-[500] !leading-normal !rounded-[20px]"
                />
                {isMobileApp && (
                    <button
                        role="button"
                        className="bg-primary-default ml-auto flex items-center gap-[10px] rounded-full px-[15px] py-[8px]"
                        onClick={handleScan}
                    >
                        <QRCodeIcon />
                        <span className="text-[14px] font-[600] text-white">
                            {t(`common:global.scan`, 'Scan')}
                        </span>
                    </button>
                )}
            </div>

            {ordersLoading && (
                <ModalLoading message={t('common:tickets_loading', 'Tickets Loading...')} />
            )}

            {!ordersLoading && (
                <SearchLoader
                    customStyle={{ position: 'relative', marginTop: '20px' }}
                    emptyResults={tickets?.length === 0}
                    size={180}
                    areResults={tickets?.length > 0}
                    emptyState={false}
                    loading={false}
                />
            )}

            {!ordersLoading && tickets?.length > 0 && (
                <ul className="pl-0">
                    {tickets.map((ticket, index) => (
                        <UserTicketListItem
                            key={index}
                            ticket={ticket}
                            onCheckInToggle={() => setSearchString('')}
                        />
                    ))}
                </ul>
            )}
        </>
    );
};

export default CheckIn;
