import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';

import { useAddPledgeMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';

import useFilestack, { UploadRes } from '@web/ui/components/generic/hooks/useFilestack';

import CenterModalHeaderFooter from '@web/ui/components/modals/CenterModalHeaderFooter';
import PreviewContainer from '@web/ui/components/content/newpost/PreviewContainer';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-spinner.json';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';

import { IMAGE_MIME_TYPES, VIEWER_MIME_TYPES } from '@core/utilities/constants/filestack';

import { ATTACH_DOCUMENT, ATTACH_IMAGE } from '@core/utilities/constants/attachment_types.js';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.components.card.pledgeCard.attachment_form';

const UploadTypes = { Image: ATTACH_IMAGE, Document: ATTACH_DOCUMENT } as const;
type UploadType = typeof UploadTypes[keyof typeof UploadTypes];

type PledgeAttachmentProps = {
    url: string;
    pledgeId: string;
};

type AttachmentTitleState = {
    attachedContent: {
        data: {
            title: string;
        };
    };
};

const PledgeAttachment: React.FC<PledgeAttachmentProps> = ({ url, pledgeId }) => {
    const { t } = useTranslation();

      const { currentUser } = useCurrentUser();
    const { closeModal } = useModal();

    const [attachment, setAttachment] = useState<false | { type: UploadType; data: UploadRes }>(
        false
    );
    const [state, setState] = useState<AttachmentTitleState>({
        attachedContent: {
            data: {
                title: '',
            },
        },
    });
    const [uploadProgress, setUploadProgress] = useState<number | false>(false);

    const [addPledge] = useAddPledgeMutation();

    const onUpload = (type: UploadType, data: UploadRes) => {
        setUploadProgress(false);
        setAttachment({ type, data });
    };

    const { handleFileSelect: handleImageSelect, isLoading: imageUploadLoading } = useFilestack({
        fileType: IMAGE_MIME_TYPES,
        onUpload: (_url, _file, data) => onUpload(UploadTypes.Image, data),
        options: { onProgress: event => setUploadProgress(event.totalPercent) },
    });

    const { handleFileSelect, isLoading: fileUploadLoading } = useFilestack({
        fileType: VIEWER_MIME_TYPES,
        onUpload: (_url, _file, data) => onUpload(UploadTypes.Document, data),
        options: { onProgress: event => setUploadProgress(event.totalPercent) },
    });

    const submit = () => {
        if (!attachment) return;

        const group = currentUser?.activeGroup?._id;

        const supplement =
            attachment.type === UploadTypes.Image
                ? { image: attachment.data.url }
                : {
                      document: attachment.data.url,
                      documentTitle:
                          state?.attachedContent?.data?.title || attachment.data.filename,
                  };

        addPledge({ variables: { input: { url, pledgeId, group, supplement } } })
            .then(() => {
                closeModal();
            })
            .catch(e => logger.error(e.message));
    };

    const isUploading = fileUploadLoading || imageUploadLoading;

    return (
        <CenterModalHeaderFooter
            title={t(`common:${localNamespace}.add_media`)}
            subtitle={t(`common:${localNamespace}.select_attachment`)}
            buttonText={t(`common:${localNamespace}.upload`)}
            onClick={submit}
            buttonDisabled={!attachment}
            className="pledge-attachment"
        >
            <LoadingLine isLoading={isUploading} />

            {isUploading && (
                <div className="pledge-attachment-loader">
                    <LottieLoading
                        customStyle={{ backgroundColor: '#fff', borderRadius: '50%' }}
                        height={175}
                        width={175}
                        lottieData={LottieAnimation}
                    />
                    <span>
                        {uploadProgress ? `Uploading (${uploadProgress}%)` : 'Starting Upload...'}
                    </span>
                </div>
            )}

            {!isUploading && attachment.type === ATTACH_DOCUMENT && (
                <PreviewContainer
                    state={state}
                    setState={setState}
                    content={attachment}
                    currentUser={currentUser}
                    clearAttachment={() => {}}
                />
            )}

            {!isUploading && attachment.type === ATTACH_IMAGE && (
                <div className="pledge-attachment-img-preview-wrap">
                    <img
                        className="pledge-attachment-img-preview"
                        src={attachment?.data?.url}
                        alt="pledge-img"
                    />
                </div>
            )}

            {!attachment && !isUploading && (
                <section>
                    <button type="button" onClick={handleImageSelect}>
                        {t(`common:${localNamespace}.choose_img`)}
                    </button>
                    <button type="button" onClick={handleFileSelect}>
                        {t(`common:${localNamespace}.choose_file`)}
                    </button>
                </section>
            )}
        </CenterModalHeaderFooter>
    );
};

export default PledgeAttachment;
