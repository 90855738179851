import React from 'react';
import { useTranslation } from 'react-i18next';

const localNamespace = 'imports.wlWeb.ui.components.group.eventDashboard';

const GroupRegisterButton: React.FC<{
    onClick: () => void;
    customButtonClass?: String;
}> = ({ onClick, customButtonClass }) => {
    const { t } = useTranslation();

    return (
        <button
            type="button"
            id="home-header-switcher"
            onClick={onClick}
            className={`event-dashboard-btn ${customButtonClass}`}
        >
            <div className="event-dashboard-btn-text">
                {t(`common:${localNamespace}.register_to_event`, 'Register')}
            </div>
        </button>
    );
};

export default GroupRegisterButton;
