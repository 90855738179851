import React from 'react';
import { useTranslation } from 'react-i18next';

import { awsMetricPublisher } from '@welibraryos/metrics';

import {useUpdateUserMutation} from '@shared/welibrary-graphql/user/mutations.hook';

import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';

import MobileMenuList from '@web/ui/components/modals/MobileMenuList';

import { allLanguages, isSupportedLanguage } from '@core/utilities/constants/languages';

import { MenuListItem } from '@core/types/Menus';

const SideMenuLanguagePicker = () => {
    const { t, i18n } = useTranslation();

    const { currentUser, refetchCurrentUser } = useCurrentUser();

    const [updateUser] = useUpdateUserMutation();

    const { newModal, closeModal } = useModal();

    const currentLanguage = t(
        `common:languages.names.${currentUser?.profile?.language || '-none-'}`
    );

    const languageList: MenuListItem[] = Object.keys(allLanguages).map(key => ({
        id: key,
        title: t(`common:languages.names.${key}`),
        onClick: () => {
            if (currentUser && isSupportedLanguage(key)) {
                i18n.changeLanguage(key);
                updateUser({ variables: { _id: currentUser._id, language: key } }).then(() => {
                    refetchCurrentUser();
                    closeModal();
                });
            }
        },
        selected: currentUser?.profile?.language === key,
    }));

    return (
        <button
            type="button"
            className="side-menu-main-language-picker"
            onClick={() => {
                awsMetricPublisher.publishCount('side-menu-open-select-language-modal', 1);
                newModal(
                    <MobileMenuList
                        menuList={languageList}
                        metricName="side-menu-selected-language"
                    />,
                    { className: 'no-min-height' }
                );
            }}
        >
            <span className="selected-language">{currentLanguage}</span>
            <span role="presentation" className="caret">
                &#x25BE;
            </span>
        </button>
    );
};

export default SideMenuLanguagePicker;
