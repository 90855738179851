import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group } from '@shared/welibrary-graphql/types';
import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import Picture from '@web/ui/components/generic/Picture';
import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

type GroupListItemProps = {
    group: Group;
    onClick?: (groupId: string) => void;
};

const GroupListItem = React.forwardRef<HTMLUListElement, GroupListItemProps>(
    ({ group, onClick }, ref) => {
        const { t } = useTranslation();

        const handleClick = () => {
            onClick?.(group?._id);
        };

        return (
            <ErrorBoundary>
                <li className="search-form-result group-result-display" ref={ref}>
                    <button
                        type="button"
                        className="button-reset overlay-btn"
                        onClick={handleClick}
                    />
                    <Picture
                        url={
                            group?.profile?.picture ||
                            getRandomDefaultCoverImage(group?.profile?.full_name || 'group')
                        }
                        disableLink
                        resolutions={[130, 260, 390]}
                    />

                    <div className="right">
                        <div className="desc">
                            <h3>{group?.profile?.full_name}</h3>
                            <p>{group?.profile?.short_bio}</p>
                        </div>
                        <div className="action-container">
                            <button
                                onClick={handleClick}
                                type="button"
                                className="read-more-arrow dark animate-grow"
                            />
                        </div>
                    </div>
                </li>
            </ErrorBoundary>
        );
    }
);

export default GroupListItem;
