import React from 'react';

type ChatProps = {
    className?: string;
};

const Chat: React.FC<ChatProps> = ({ className = '' }) => {
    return (
        <svg viewBox="0 0 50 51" fill="none" className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0317 28.3973C14.6884 28.3973 13.5972 27.306 13.5972 25.9628C13.5972 24.6235 14.6884 23.5322 16.0317 23.5322C17.3749 23.5322 18.4662 24.6235 18.4662 25.9628C18.4662 27.306 17.3749 28.3973 16.0317 28.3973ZM25.496 28.3973C24.1527 28.3973 23.0615 27.306 23.0615 25.9628C23.0615 24.6235 24.1527 23.5322 25.496 23.5322C26.8392 23.5322 27.9305 24.6235 27.9305 25.9628C27.9305 27.306 26.8392 28.3973 25.496 28.3973ZM32.5259 25.9628C32.5259 27.306 33.6172 28.3973 34.9605 28.3973C36.3037 28.3973 37.395 27.306 37.395 25.9628C37.395 24.6235 36.3037 23.5322 34.9605 23.5322C33.6172 23.5322 32.5259 24.6235 32.5259 25.9628Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.0415 4.6665C12.9373 4.6665 4.1665 14.5543 4.1665 25.5311C4.1665 29.0364 5.18734 32.6439 6.979 35.9405C7.31234 36.4851 7.354 37.1715 7.12484 37.8204L5.729 42.4941C5.4165 43.6208 6.37484 44.4533 7.43734 44.1194L11.6457 42.8696C12.7915 42.4941 13.6873 42.9719 14.7498 43.6208C17.7915 45.413 21.5832 46.3332 24.9998 46.3332C35.3332 46.3332 45.8332 38.342 45.8332 25.4685C45.8332 14.3665 36.8748 4.6665 25.0415 4.6665Z"
                stroke="currentcolor"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Chat;
