import React from 'react';

import StandardToast from '@components/toast/StandardToast';

const AlertToast: React.FC<{
    showWarningIcon?: boolean;
    showSuccessIcon?: boolean;
    boldText?: string;
    text?: string;
    extraIconClassNames?: string;
}> = ({ showWarningIcon, showSuccessIcon, boldText = '', text = '', extraIconClassNames = '' }) => {
    let iconClassName;
    if (showSuccessIcon) {
        iconClassName = 'toast-success-icon';
    } else if (showWarningIcon) {
        iconClassName = 'toast-warning-icon';
    }
    iconClassName = `${iconClassName} ${extraIconClassNames}`;

    return (
        <StandardToast
            title={boldText}
            message={text}
            iconClassName={iconClassName}
            dismissable={false}
        />
    );
};

export default AlertToast;
