import React from 'react';

import { CurrencyType } from '@core/utilities/constants/currencies';

export const CurrencyList: React.FC<{
    currencies: CurrencyType[];
    handleOnClick: (currency: CurrencyType) => void;
}> = ({ currencies, handleOnClick }) => {
    return (
        <ul className="ticket-sharing-dropdown">
            {currencies?.map(currency => {
                return (
                    <li
                        key={currency?.code}
                        className="ticket-sharing-dropdown-item mb-2 cursor-pointer"
                        onClick={() => handleOnClick(currency)}
                    >
                        <div className="ticket-sharing-dropdown-item-details">
                            <p className="p-0 m-0 text-black px-[16px] py-[12px] font-semibold">
                                {currency?.code}
                                <span className="ml-2">{currency?.symbol}</span>
                            </p>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default CurrencyList;
