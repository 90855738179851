export const CONTENT_BUTTON_ICON_KEY = {
    Edit: 'edit',
    Order: 'edit-order',
    Announcement: 'megaphone',
    Channels: 'channels',
    Job: 'job',
    Volunteer: 'volunteer',
    Subgroup: 'subgroup',
    Checklist: 'checklist',
    'Edit Profile': 'edit',
    'Edit Group Profile': 'edit',
    Shelf: 'shelf',
    Delete: 'delete',
    Report: 'flag',
    Clone: 'duplicate',
    Analytics: 'analytics',
    'Invite User': 'invite-user',
    Follow: 'plus',
    Airplane: 'airplane',
    Unfollow: 'x',
    Disconnect: 'x',
    'Cancel Connection Request': 'x',
    'Accept Connection Request': 'plus',
    'Reject Connection Request': 'x',
    'Request Connection': 'plus',
    'Invite to Group': 'group',
    'See Profile': 'member',
    Subscribe: 'plus',
    Create: 'plus',
    Unsubscribe: 'x',
    Email: 'email',
    Share: 'share',
    Plus: 'plus',
    Logout: 'x',
    Duplicate: 'duplicate',
    'View Group Profile': 'business',
    'My Profile': 'member',
    'View Profile': 'member',
    Groups: 'group',
    'View Projects': 'white-rocketship inverted',
    'View Page': 'group',
    'View Group Dashboard': 'white-rocketship inverted',
    'My Community': 'white-rocketship inverted',
    'My Groups': 'group',
    'Go Live!': 'white-rocketship inverted',
    'Stop Live': 'x',
    'Join Group': 'plus',
    'Request Membership': 'plus',
    'Request Admin Role': 'edit',
    'Join Request Pending': 'x',
    'Make group admin': 'group',
    'Remove from group': 'x',
    'Remove as admin': 'x',
    'Leave conversation': 'x',
    Unsnooze: 'notification',
    'Snooze for 24 hours': 'snooze',
    'Leave group': 'x',
    Bookmark: 'bookmark',
    'Remove Bookmark': 'x',
    Unarchive: 'unarchive',
    Archive: 'archive',
    Move: 'move',
    'Edit Tags': 'tags',
    'Request to Join': 'plus',
    'Request Pending': 'x',
    'Accept Invite': 'plus',
    Search: 'search',
    SettingsCog: 'settings-cog',
    Deactivate: 'delete',
    Rocket: 'white-rocketship inverted',
    x: 'x',
    plus: 'plus',
    member: 'member',
    Calendar: 'blk-calendar',
    Settings: 'settings',
    Ticket: 'ticket',
    TicketPlus: 'ticket-plus',
    Currency: 'currency',
    Pin: 'tack',
    Moderation: 'moderation',
    Unpublish: 'eye-slash',
    Publish: 'circle-check',
    Document: 'document-icon',
} as const;

export { CONTENT_BUTTON_ICON_KEY as default };
