import React from 'react';

import CreationProgressBar from '@components/lego/creation/CreationProgressBar';
import Picture from '@components/generic/Picture';

import { getCreationImage } from '@helpers/creations/creation.helpers';

import { CARD_CREATION } from '@core/utilities/constants/card_types';

import { Maybify } from '@core/types/Utilities';
import { RainbowRoadNode, AliasItem, CreationState } from '@shared/welibrary-graphql/types';

type QuestCreationDisplayProps = {
    node: Maybify<RainbowRoadNode>;
    creationsState?: Array<Maybify<CreationState> | null> | null;
};

const getCreationThumb = (
    alias: Maybify<AliasItem>,
    creation?: Maybify<CreationState> | null
): string => {
    return creation ? getCreationImage(alias.item, creation) : alias.item?.media?.icon ?? '';
};

const QuestCreationDisplay: React.FC<QuestCreationDisplayProps> = ({ node, creationsState }) => {
    if (node.alias?.__typename !== 'AliasItem') return <></>;

    const { alias } = node;

    const creation = creationsState?.find(
        creationState => creationState?.creation?._id === alias.item?._id
    );

    const isCreation = alias.type === CARD_CREATION;
    const currentLevel = creation?.level ?? 1;
    const creationTitle = node?.title;
    const creationThumb = getCreationThumb(alias, creation);

    return (
        <div className="quest-node-info">
            <div className="main">
                <div className="left-side fadein-delay">
                    {isCreation && <Picture url={creationThumb} disableLink className="bg" />}
                </div>

                <div className="center-spacer" />

                <div className="right-side">
                    <div className="info-container">
                        <h3>{creationTitle}</h3>
                        {isCreation &&
                            (currentLevel > 1 ? (
                                <CreationProgressBar
                                    value={currentLevel - 1}
                                    levels={alias.item?.creationItems?.levels?.length ?? 3}
                                    numberColor="#219653"
                                />
                            ) : (
                                <p>Get Started!</p>
                            ))}
                    </div>
                </div>
            </div>
            <div className="bottom">
                <h3>{creationTitle}</h3>
            </div>
        </div>
    );
};

export default QuestCreationDisplay;
