import React from 'react';

type HeartProps = {
    className?: string;
};

const Heart: React.FC<HeartProps> = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 500 500" fill="currentcolor">
        <path d="M356.14,99.4A93.74,93.74,0,0,1,429.48,135c18.39,22.9,25.11,53.25,18.44,83.25-4.58,20.64-15.5,41.64-32.44,62.4-18.46,22.63-44.19,45.17-76.47,67a608.12,608.12,0,0,1-89,49.55,609.88,609.88,0,0,1-89-49.55c-32.28-21.83-58-44.37-76.46-67-17-20.76-27.87-41.76-32.46-62.4-6.66-30,.06-60.35,18.45-83.27A93.77,93.77,0,0,1,143.86,99.4c27.7,0,54.63,12.27,77.9,35.47L250,163.06l28.25-28.2c23.24-23.2,50.17-35.46,77.87-35.46m0-40c-38.7,0-75.42,16.5-106.13,47.15C219.28,75.9,182.57,59.4,143.86,59.4A133.56,133.56,0,0,0,39.32,109.92c-26,32.44-35.6,75.07-26.3,117,12,53.84,54.21,105.6,125.56,153.86a635.37,635.37,0,0,0,99.73,54.95l11.7,4.9,11.68-4.9a634.39,634.39,0,0,0,99.73-54.95C432.77,332.49,475,280.73,487,226.89c9.31-41.9-.26-84.53-26.3-117A133.54,133.54,0,0,0,356.14,59.4Z" />
    </svg>
);

export default Heart;
