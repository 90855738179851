// src/notifications/subscriptions.ts
import { gql as gql2 } from "@apollo/client";

// src/notifications/fragments.ts
import { gql } from "@apollo/client";
var FULL_NOTIFICATION_FRAGMENT = gql`
    fragment FullNotificationFragment on Notification {
        __typename
        _id
        publish
        messageDetails {
            title
            subtitle
            body
            cta {
                label
                link
            }
        }
        eventDetails {
            actor {
                _id
                type
                profile {
                    full_name
                    first_name
                    picture
                    loggedInUserConnectionStatus
                }
                socialActionsAllowed
            }
            action
            target {
                collection
                id
                picture
            }
            metadata
        }
        notificationType
        currentUserNotificationLevel
    }
`;
var NOTIFICATION_SETTINGS_FRAGMENT = gql`
    fragment NotificationSettingsFragment on Notification {
        __typename
        _id
        publish
        notificationType
    }
`;

// src/notifications/subscriptions.ts
var NOTIFICATIONS_SUBSCRIPTION = gql2`
    subscription onNotificationAdded($userId: ID!) {
        notificationAdded(userId: $userId) {
            ...FullNotificationFragment
            currentUserReadCount
        }
    }
    ${FULL_NOTIFICATION_FRAGMENT}
`;
export {
  NOTIFICATIONS_SUBSCRIPTION
};
