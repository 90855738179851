import React from 'react';
import { Updater } from 'use-immer';
import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';

import LocationPicker from '@components/location/LocationPicker';
import TextInput from '@dsc/forms/textInputs/TextInput';
import Select from '@dsc/forms/customInputs/Select';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { formatLocationObject } from '@helpers/user/location.helpers';

import { EventLocationSettings, LocationTypeEnum, VenueState } from '@core/types/EventGroup';

type LocationVenueFormProps = {
    errors?: Record<string, string[]>;
    state: EventLocationSettings;
    setState: Updater<EventLocationSettings>;
    rsvpEnabled?: boolean;
    customTitle?: string;
};

const localNamespace = 'imports.wlWeb.ui.components.group.updateEventForm.eventForm';
const eventlocalNamespace = 'imports.wlWeb.ui.components.content.event';

const locationTypeOptions = [
    LocationTypeEnum.inPerson,
    LocationTypeEnum.virtual,
    LocationTypeEnum.both,
];
const locationTypeDisplayValues = {
    [LocationTypeEnum.both]: 'In Person & Virtual',
    [LocationTypeEnum.inPerson]: 'In Person',
    [LocationTypeEnum.virtual]: 'Virtual',
};

const LocationVenueSettings: React.FC<LocationVenueFormProps> = ({
    errors,
    state,
    setState,
    rsvpEnabled,
    customTitle,
}) => {
    const { t } = useTranslation();

    const updateSlice = curriedStateSlice(setState);

    const promptTitle =
        customTitle ||
        t(
            `common:${eventlocalNamespace}.event_location_prompt`,
            'Where will this event take place?*'
        );

    return (
        <div className="form-group event-location-options">
            <section className="title-desc">
                <h4>{t('common:location', 'Location')}</h4>

                <Select
                    title={promptTitle}
                    value={state.type}
                    onChange={updateSlice('type')}
                    options={locationTypeOptions}
                    displayValues={locationTypeDisplayValues}
                />
            </section>

            {(state?.type === LocationTypeEnum.inPerson ||
                state?.type === LocationTypeEnum.both) && (
                <VenueSettings state={state.venue!} setState={updateSlice('venue')} />
            )}

            {(state?.type === LocationTypeEnum.virtual ||
                state?.type === LocationTypeEnum.both) && (
                <>
                    <TextInput
                        onChange={updateSlice('link')}
                        type="text"
                        label={t(`common:${eventlocalNamespace}.event_link`, 'Event Link')}
                        name="Event Link"
                        id="link"
                        required
                        aria-labelledby="event-link"
                        placeholder={t(`common:${eventlocalNamespace}.event_link`, 'Event Link')}
                        value={state?.link ?? ''}
                        error={state?.link?.length > 0 && errors?.virtualLink}
                        helperText={`${
                            state?.link.length > 0 && errors?.virtualLink ? errors?.virtualLink : ''
                        }`}
                        maxLength={255}
                    />

                    <TextInput
                        onChange={updateSlice('platformName')}
                        type="text"
                        label="Platform (e.g. Zoom, Minecraft, etc)"
                        name="Platform Name"
                        id="platform"
                        aria-labelledby="platform-label"
                        placeholder="Platform (e.g. Zoom, Minecraft, etc)"
                        value={state?.platformName ?? ''}
                    />
                </>
            )}

            {rsvpEnabled && (
                <TextInput
                    onChange={updateSlice('rsvpLink')}
                    type="text"
                    label={t(`common:${eventlocalNamespace}.event_rsvp_link`, 'RSVP Link')}
                    name="RSVP Link"
                    id="rsvplink"
                    aria-labelledby="event-link"
                    placeholder={t(`common:${eventlocalNamespace}.event_rsvp_link`, 'RSVP Link')}
                    value={state?.rsvpLink ?? ''}
                />
            )}
        </div>
    );
};

export default LocationVenueSettings;

type VenueSettingsProps = {
    state: VenueState;
    setState: Updater<VenueState>;
};

export const VenueSettings: React.FC<VenueSettingsProps> = ({ state, setState }) => {
    const { t } = useTranslation();
    const updateSlice = curriedStateSlice(setState);

    const { newModal } = useModal();

    const selectLocation = () => {
        newModal(
            <LocationPicker
                onLocationSelect={loc => updateSlice('location', formatLocationObject(loc))}
            />
        );
    };

    const handleVenueName = (value: string) => updateSlice('name', value);

    return (
        <>
            <button
                type="button"
                onClick={selectLocation}
                className={`event-location-button event-group ${
                    state?.location?.formatted_address ? 'filled' : 'empty'
                }`}
                title={t(`common:${localNamespace}.edit_location`)}
            >
                {state?.location?.formatted_address && (
                    <span className="tiny-input-label">{`${t(
                        'common:location',
                        'Location'
                    )}*`}</span>
                )}
                <span>
                    {state?.location?.formatted_address || `${t('common:location', 'Location')}*`}
                </span>
            </button>
            <TextInput
                onChange={handleVenueName}
                type="text"
                label="Venue Name"
                name="Venue Name"
                id="venue-name"
                aria-labelledby="event-link"
                placeholder="Venue Name"
                value={state?.name}
            />

            <TextInput
                onChange={updateSlice('link')}
                type="text"
                label="Venue Link"
                name="Venue Link"
                id="venue-link"
                aria-labelledby="event-link"
                placeholder="Venue Link"
                value={state?.link}
            />
        </>
    );
};
