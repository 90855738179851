import React, { useState } from 'react';

import {
    useSetCustomerCardSourceAsDefaultMutation,
    useDeleteCustomerCardSourceMutation,
} from '@shared/welibrary-graphql/user/mutations.hook';
import useConfirmation from '@components/generic/hooks/useConfirmation';

import IntroContent from '@web/ui/components/generic/IntroContent';
import CardBranding from '@components/group/groupdashboard/groupSubscriptionPaywall/CardBranding';
import ChevronLeft from '@web/ui/dsc/svgs/ChevronLeft';
import ChevronRight from '@web/ui/dsc/svgs/ChevronRight';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';

import { StripeCustomerCard } from '@shared/welibrary-graphql/types';
import { GET_LOGGEDIN_CUSTOMER_CARDS } from '@shared/welibrary-graphql/user/queries';

import { SubTabs } from '@components/group/groupdashboard/groupSubscriptionPaywall/groupSubscriptionPaywallHelpers';

import getLogger from '@core/logger';

const logger = getLogger(module);

const ManageSubscriptionPayment: React.FC<{
    cards: [StripeCustomerCard];
    defaultSource: string;
    activeSubTab: SubTabs;
    setActiveSubTab: React.Dispatch<React.SetStateAction<SubTabs>>;
    handleShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
    refetchCurrentUser: any;
}> = ({
    cards,
    defaultSource,
    handleShowFooter,
    activeSubTab,
    setActiveSubTab,
    refetchCurrentUser,
}) => {
    const confirm = useConfirmation();

    const [cardToEdit, setCardToEdit] = useState<StripeCustomerCard | null>(null);

    const [setCardAsDefault, { loading }] = useSetCustomerCardSourceAsDefaultMutation();
    const [isDefaultCard, setIsDefaultCard] = useState<string>(defaultSource);
    const [deleteCard, { loading: deleteCardLoading, error: deleteCardError }] =
        useDeleteCustomerCardSourceMutation();

    const handleEditCard = (card: StripeCustomerCard) => {
        setCardToEdit(card);
        handleShowFooter(false);
        setActiveSubTab(SubTabs.ViewCard);
    };

    const handleDeleteCard = (cardId: string) => {
        confirm({
            text: 'Are you sure want to delete this card?',
            onConfirm: () => {
                deleteCard({
                    variables: {
                        cardId,
                    },
                    refetchQueries: [GET_LOGGEDIN_CUSTOMER_CARDS],
                })
                    .then(({ data }) => {
                        if (data?.deleteCustomerCardSource?.deleted === true) {
                            handleShowFooter(true);
                            setActiveSubTab(SubTabs.ViewCards);
                        }
                    })
                    .catch(err => logger?.error(err));
            },
        });
    };

    const handleSetCardAsDefault = (cardId: string) => {
        confirm({
            text: 'Are you sure want to set this card as your default?',
            onConfirm: () => {
                setCardAsDefault({
                    variables: {
                        cardId,
                    },
                    refetchQueries: [GET_LOGGEDIN_CUSTOMER_CARDS],
                })
                    .then(() => {
                        handleShowFooter(true);
                        setActiveSubTab(SubTabs.ViewCards);
                        setIsDefaultCard(cardId);
                        refetchCurrentUser();
                    })
                    .catch(err => logger?.error(err));
            },
        });
    };

    if (cardToEdit && activeSubTab === SubTabs.ViewCard) {
        const _isDefaultCard = isDefaultCard === cardToEdit?.id;

        return (
            <div className="manage-subs-payment-container manage-subs-card-wrap">
                <LoadingLine isLoading={loading || deleteCardLoading} />

                <div className="manage-subs-card-back-btn-wrap">
                    <button
                        onClick={() => {
                            handleShowFooter(true);
                            setActiveSubTab(SubTabs.ViewCards);
                        }}
                        type="button"
                        className="manage-subs-card-back-btn"
                    >
                        <ChevronLeft />
                        Go Back
                    </button>
                </div>

                <div className="manage-subs-payment-wrap subs-card-list">
                    <div className="subs-card-item-preview">
                        <div className="subs-card-item-preview-top">
                            <CardBranding
                                branding={cardToEdit?.brand}
                                customClassName="card-branding-large"
                            />
                            <p>
                                Expires {cardToEdit?.exp_month}/{cardToEdit?.exp_year}
                            </p>
                        </div>

                        <div className="subs-card-item-preview-bottom">
                            <h3>**** **** **** {cardToEdit?.last4}</h3>
                        </div>
                    </div>
                    {deleteCardError && (
                        <div className="customer-card-error-wrap">
                            <p className="customer-card-error-text">x {deleteCardError?.message}</p>
                        </div>
                    )}
                    <footer className="manage-subs-payment-btns-wrap">
                        <button
                            type="button"
                            onClick={() => handleSetCardAsDefault(cardToEdit?.id)}
                        >
                            {_isDefaultCard ? 'Default' : 'Use as Default'}
                        </button>
                        <button
                            onClick={() => handleDeleteCard(cardToEdit?.id)}
                            type="button"
                            className="manage-subs-payment-btn-cancel"
                        >
                            Delete Card
                        </button>
                    </footer>
                </div>
            </div>
        );
    }

    return (
        <ul className="manage-subs-payment-wrap subs-card-list">
            {cards?.length > 0 ? (
                cards?.map(card => {
                    const _isDefaultCard = isDefaultCard === card?.id;

                    return (
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                        <li className="subs-card-item" onClick={() => handleEditCard(card)}>
                            <div className="subs-card-item-left-col">
                                <CardBranding branding={card?.brand} />
                                <p>{card?.last4}</p>
                            </div>
                            <div className="subs-card-item-right-col">
                                <div className="subs-card-item-info">
                                    <p>
                                        Expires {card?.exp_month}/{card?.exp_year}
                                    </p>
                                    {_isDefaultCard && (
                                        <p className="subs-card-item-default">Used as default</p>
                                    )}
                                </div>
                                <button className="subs-card-item-button" type="button">
                                    <ChevronRight strokeWidth="10" />
                                </button>
                            </div>
                        </li>
                    );
                })
            ) : (
                <div>
                    <IntroContent
                        declaration="No saved payment methods Found."
                        type="nocontent"
                        customClass="subs-no-cards"
                    />
                </div>
            )}
        </ul>
    );
};

export default ManageSubscriptionPayment;
