import React from 'react';

type ArrowBendDownRightProps = {
    className?: string;
};

const ArrowBendDownRight: React.FC<ArrowBendDownRightProps> = ({ className = '' }) => {
    return (
        <svg viewBox="0 0 32 32" fill="none" className={className}>
            <path
                d="M22 13L28 19L22 25"
                stroke="currentcolor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 7C4 10.1826 5.26428 13.2348 7.51472 15.4853C9.76516 17.7357 12.8174 19 16 19H28"
                stroke="currentcolor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowBendDownRight;
