import React from 'react';

type WindowFrameProps = {
    size?: string;
    className?: string;
    color?: string;
};

const WindowFrame: React.FC<WindowFrameProps> = ({
    size = '100%',
    className = '',
    color = 'white',
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 374 812"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            preserveAspectRatio="none"
        >
            <g opacity="0.2" clipPath="url(#clip0_3270:14000)">
                <path
                    d="M0 812H373.6V0H0V812ZM10.46 598.2L22.39 220.36C25.63 117.36 95.12 35.61 177.6 37.8L220.27 38.92C302.74 41.13 366.96 126.42 363.71 229.42L351.79 607.2C348.6 710.2 279.05 791.89 196.6 789.72L153.95 788.61C71.44 786.42 7.21996 701.2 10.46 598.2Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_3270:14000">
                    <rect width="373.56" height="812" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default WindowFrame;
