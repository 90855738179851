import React from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

import useConfirmation from '@components/generic/hooks/useConfirmation';
import { useFollowUserMutation, useUpdateConnectionMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import { GET_USER_PAGE } from '@shared/welibrary-graphql/user/queries';
import { SOCIAL_ACTIONS } from '@core/utilities/constants/user';

const localNamespace = 'imports.wlWeb.ui.components.profile.profileHeader';

const useUserActions = user => {
    const { t } = useTranslation();
    const confirm = useConfirmation();

    const [followUser, { loading: userFollowLoading }] = useFollowUserMutation();

    const [connectUser, { loading: userConnectLoading }] = useUpdateConnectionMutation();

    const followOtherUser = ({ variables }) => {
        followUser({
            variables,
            update: (cache, { data: { followUser } }) => {
                const { userPage } =
                    cache.readQuery({
                        query: GET_USER_PAGE,
                        variables: { _id: user._id },
                    }) ?? {};

                if (!userPage) return;

                const userPageClone = cloneDeep(userPage);
                userPageClone.user = followUser;
                userPageClone.loggedInUser.profile.isFollowingUser =
                    !userPageClone.loggedInUser.profile.isFollowingUser;

                cache.writeQuery({
                    query: GET_USER_PAGE,
                    variables: { _id: user._id },
                    data: { userPage: userPageClone },
                });
            },
        });
    };

    const handleConnectClick = async user => {
        const { socialActionsAllowed } = user;

        let confirmed = true;

        let action = SOCIAL_ACTIONS.REQUEST_CONNECTION;
        if (socialActionsAllowed.includes(SOCIAL_ACTIONS.REQUEST_CONNECTION)) {
            action = SOCIAL_ACTIONS.REQUEST_CONNECTION;
        } else if (socialActionsAllowed.includes(SOCIAL_ACTIONS.ACCEPT_CONNECTION)) {
            action = SOCIAL_ACTIONS.ACCEPT_CONNECTION;
        } else if (socialActionsAllowed.includes(SOCIAL_ACTIONS.CANCEL_REQUEST_CONNECTION)) {
            action = SOCIAL_ACTIONS.CANCEL_REQUEST_CONNECTION;
            confirmed = await confirm({
                text: t(
                    `common:${localNamespace}.connections.confirm_cancel_request`,
                    'Are you sure you want to cancel your request?'
                ),
            });
        } else if (socialActionsAllowed.includes(SOCIAL_ACTIONS.DISCONNECT)) {
            action = SOCIAL_ACTIONS.DISCONNECT;
            confirmed = await confirm({
                text: t(
                    `common:${localNamespace}.connections.prompt.disconnect`,
                    'Are you sure you want to disconnect?'
                ),
            });
        }

        if (confirmed) {
            connectUser({ variables: { userId: user._id, action } });
        }
    };

    /**
     * @param { array } participants Array of user Ids
     * @param {function} postCreate Function to run after creation of new message thread
     */
    const handleFollowClick = (user, unFollow) => {
        followOtherUser({
            variables: {
                _id: user._id,
                unFollow,
            },
        });
    };

    const getConnectActionText = user => {
        const { REQUEST_CONNECTION, ACCEPT_CONNECTION, CANCEL_REQUEST_CONNECTION, DISCONNECT } =
            SOCIAL_ACTIONS;
        const { socialActionsAllowed } = user;

        if (socialActionsAllowed.includes(REQUEST_CONNECTION)) {
            return t(`common:${localNamespace}.connections.connect`);
        }
        if (socialActionsAllowed.includes(ACCEPT_CONNECTION)) {
            return t(`common:${localNamespace}.connections.accept`);
        }
        if (socialActionsAllowed.includes(CANCEL_REQUEST_CONNECTION)) {
            return t(`common:${localNamespace}.connections.cancel`);
        }
        if (socialActionsAllowed.includes(DISCONNECT)) {
            return t(`common:${localNamespace}.connections.connected`);
        }

        return null;
    };

    return {
        userFollowLoading,
        userConnectLoading,
        getConnectActionText,
        handleConnectClick,
        handleFollowClick,
    };
};

export default useUserActions;
