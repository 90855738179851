import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { useCreateCardMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';
import { useCreateGroupMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import useModal from '@components/modals/hooks/useModal';
import useEventGroupFormState from '@components/content/newpost/forms/EventGroup/hooks/useEventGroupFormState';

import EventGroupForm from '@components/content/newpost/forms/EventGroup/EventGroupForm';
import ModalLoading from '@components/modals/ModalLoading';

import { getOverviewTime, getEarliestDay, getLatestDay } from '@core/utilities/constants/events';
import { slugify } from '@helpers/string.helpers';

import { CARD_SUBTYPES, CARD_GROUP, ALIAS_GROUP } from '@core/utilities/constants/card_types';
import {
    SponsorTier,
    SpeakerTransformedPayload,
    EventGroupFormModeEnum,
} from '@core/types/EventGroup';
import { GroupSubtype } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

type CreateCreationFormProps = {
    hasAdminRole?: boolean;
    chapterTitle?: string;
    feedUrl?: string;
    sortType?: string;
    parentId: string;
};

const CreateEventGroupForm: React.FC<CreateCreationFormProps> = ({ parentId }) => {
    const { t } = useTranslation();

    const [state, setState] = useEventGroupFormState();
    const [ticketCurrency, setTicketCurrency] = useState<string>('USD');

    const [groupRes, setGroupRes] = useState(null);
    const [loading, setLoading] = useState(false);

    const { newModal, closeModal } = useModal();

    const idempotencyKey = useRef(uuidv4());

    const [createCard] = useCreateCardMutation();
    const [createGroup] = useCreateGroupMutation();

    useEffect(() => {
        handleUpdateTicketCurrency();
    }, [ticketCurrency]);

    // handles updating all existing tickets with the selected currency type
    const handleUpdateTicketCurrency = () => {
        setState(prevState => {
            return {
                ...prevState,
                ticketFormState: {
                    ...prevState?.ticketFormState,
                    products: [
                        ...prevState?.ticketFormState?.products?.map(product => ({
                            ...product,
                            currency: ticketCurrency,
                        })),
                    ],
                },
            };
        });
    };

    const onSubmit = async () => {
        if (!parentId) return;

        try {
            setLoading(true);

            // Construct payload

            const {
                overviewFormState,
                dateAndTimeState,
                itineraryState,
                sponsorsFormState,
                ticketFormState,
                groupSettingsState,
            } = state;

            const allSpeakers: SpeakerTransformedPayload[] = []; // this is an array that stores all speakers for an event and is stored on EventOverviewDetails

            const timeAndPlaceSettings = itineraryState?.map(session => {
                const {
                    title,
                    headline,
                    description,
                    dates,
                    startTime,
                    endTime,
                    link,
                    speakers,
                    eventLocationSettings,
                    displayStartTime,
                    displayEndTime,
                } = session;

                const aliasItemSpeakersInput = speakers?.map(speaker => {
                    if (
                        speaker.hasOwnProperty('_id') &&
                        !allSpeakers.find(s => s.userId === speaker._id)
                    ) {
                        allSpeakers.push({
                            userId: speaker._id,
                            name: speaker?.name,
                            email: speaker?.email,
                            shortBio: speaker?.bio,
                            profilePicture: speaker?.profileThumb?.image,
                            type: 'alias_user',
                        });
                    } else if (
                        !speaker.hasOwnProperty('_id') &&
                        !allSpeakers.find(s => s.email === speaker.email)
                    ) {
                        allSpeakers.push({
                            name: speaker.name,
                            email: speaker.email,
                            shortBio: speaker.bio,
                            profilePicture: speaker.profileThumb.image,
                            type: 'alias_user',
                        });
                    } else if (
                        !speaker.hasOwnProperty('_id') &&
                        !allSpeakers.find(s => s.email === speaker.email)
                    ) {
                        allSpeakers.push({
                            name: speaker.name,
                            email: speaker.email,
                            shortBio: speaker.bio,
                            profilePicture: speaker.profileThumb.image,
                            type: 'alias_user',
                        });
                    }

                    if (speaker?._id) {
                        return { userId: speaker?._id, type: 'alias_user' };
                    }
                    return {
                        userId: speaker?._id,
                        name: speaker?.name,
                        email: speaker?.email,
                        shortBio: speaker?.bio,
                        profilePicture: speaker?.profileThumb?.image,
                        useUserBio: speaker?.useUserBio,
                        type: 'alias_user',
                    };
                });

                const earliestDay = getEarliestDay(dates, dates[0].startDate);
                const latestDay = getLatestDay(dates, dates[0].startDate);

                const timeAndPlaceSetting = {
                    title,
                    headline,
                    description,
                    dates,
                    startTime: getOverviewTime(new Date(startTime), earliestDay),
                    endTime: getOverviewTime(new Date(endTime), latestDay),
                    link,
                    timeZone: dateAndTimeState?.timeZone, // Set timezone to event overview timezone
                    displayStartTime,
                    displayEndTime,
                    eventLocationSettings,
                    speakerSettings: {
                        speakers: aliasItemSpeakersInput,
                    },
                };

                return timeAndPlaceSetting;
            });

            const _sponsors = sponsorsFormState?.sponsors?.map(sponsor => {
                const {
                    name,
                    link,
                    logo: { image },
                    tier,
                } = sponsor;
                return {
                    name,
                    link,
                    logo: image,
                    tier,
                };
            });

            const sponsorTiers = sponsorsFormState?.tiers?.map((tier: SponsorTier) => {
                const tierSponsors = _sponsors
                    ?.filter(sponsor => {
                        return sponsor?.tier?.name === tier?.name;
                    })
                    .map(sponsor => {
                        const { name, link, logo } = sponsor;
                        return {
                            name,
                            link,
                            logo,
                        };
                    });

                return {
                    name: tier?.name,
                    order: tier?.order,
                    sponsors: tierSponsors,
                };
            });

            const sponsorSettings = {
                tiers: sponsorTiers,
                description: sponsorsFormState.description,
            };

            const { startTime, endTime, dates } = dateAndTimeState;
            const earliestDay = getEarliestDay(dates, dates[0].startDate);
            const latestDay = getLatestDay(dates, dates[0].startDate);

            const eventOverviewDetails = {
                title: overviewFormState.title,
                description: overviewFormState.description,
                dates: dateAndTimeState.dates,
                startTime: getOverviewTime(new Date(startTime), earliestDay),
                endTime: getOverviewTime(new Date(endTime), latestDay),
                timeZone: dateAndTimeState.timeZone,
                displayStartTime: dateAndTimeState.displayStartTime,
                displayEndTime: dateAndTimeState.displayEndTime,
                eventLocationSettings: overviewFormState.eventLocationSettings,
                sponsorSettings,
                speakerSettings: {
                    speakers: allSpeakers,
                },
                hideAttendeeCount: groupSettingsState?.hideAttendeeCount,
            };

            const settings = {
                protectionLevel: groupSettingsState?.protectionLevel,
                recommendations: groupSettingsState?.recommendations,
                shortCode: groupSettingsState.shortCode,
                groupChatroom: groupSettingsState.groupChatroom,
                adminChatroom: groupSettingsState.adminChatroom,
                approvalRequired: groupSettingsState.approvalRequired,
                registrationTitle: groupSettingsState?.registrationTitle,
                registrationDescription: groupSettingsState.registrationDescription,
                registrationActionText: groupSettingsState.registrationActionText,
                canMembersSuggestEdits: groupSettingsState.canMembersSuggestEdits,
                enableGroupNewsfeed: groupSettingsState.enableGroupNewsfeed,
            };

            const products = ticketFormState?.products?.map(product => {
                const { name, description, currency, type, coupons } = product;
                const _coupons =
                    coupons &&
                    coupons?.map(coupon => {
                        const { name, code, type } = coupon;
                        return {
                            name,
                            code,
                            type,
                            percentage: coupon?.percentage,
                        };
                    });

                return {
                    name,
                    description,
                    price: Math.round(Number(product?.price || 1) * 100),
                    currency: ticketCurrency,
                    type,
                    fulfillment: {
                        groupId: null,
                        role: 'member',
                    },
                    coupons: _coupons,
                };
            });

            // THE FINAL CREATE EVENT GROUP PAYLOAD OBJECT

            const payload9 = {
                username: slugify(overviewFormState.title),
                parentId,
                name: overviewFormState.title,
                settings,
                subtype: GroupSubtype.Event,
                picture: overviewFormState.thumbnail,
                coverPhoto: overviewFormState.coverImage,
                timeAndPlaceSettings,
                eventOverviewDetails,
                products,
                currencyISO: ticketCurrency,
                isTemplate: groupSettingsState?.isTemplate,
                paymentSettings: {
                    isGroupMembershipPaid: state?.ticketFormState?.isGroupMembershipPaid,
                },
            };

            newModal(
                <ModalLoading
                    message={t(
                        `common:imports.wlWeb.ui.components.group.eventDashboard.creating_group`
                    )}
                />,
                { widen: true, addShadow: true, disableCloseHandlers: true }
            );

            // Create Event Group
            const res = await createGroup({
                variables: { input: payload9, idempotencyKey: idempotencyKey.current },
                update: () => {},
            });

            const groupData = res?.data?.createGroup;

            const title = groupData?.profile?.full_name;
            const url = groupData?._id ?? '';
            const type = CARD_GROUP;

            const distinctChannelDestination = {
                groupId: parentId,
                subtype: CARD_SUBTYPES.DISTINCT_EVENT,
            };

            // Create Event Group Card in Events Channel of parent group

            await createCard({
                variables: {
                    input: {
                        distinctChannelDestination,
                        title,
                        aliases: [{ url, type: ALIAS_GROUP }],
                        type,
                    },
                },
            });

            setGroupRes(groupData);
            // handleUnmountForm(groupData?._id!);
        } catch (e) {
            logger.error(e);
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    return (
        <EventGroupForm
            disabled={loading}
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            groupData={groupRes}
            title="Create Event Group"
            mode={EventGroupFormModeEnum.create}
            ticketCurrency={ticketCurrency}
            setTicketCurrency={setTicketCurrency}
            handleUpdateTicketCurrency={handleUpdateTicketCurrency}
        />
    );
};

export default CreateEventGroupForm;
