import React from 'react';
import { withTranslation } from 'react-i18next';

import _ from 'lodash';

const localNamespace = 'imports.wlWeb.ui.components.generic.editScreen.editScreenSelectOne';

class EditScreenSelectOne extends React.Component {
    constructor(props) {
        super(props);
    }

    handleChange = e => {
        this.props.handleChange(e);
    };

    render() {
        let { t, target, options, selected, hideTitle } = this.props;

        let _options = _.map(options, availableOption => {
            return (
                <option key={`tag-select-${availableOption.value}`} value={availableOption.value}>
                    {availableOption.text}
                </option>
            );
        });

        let matchingOption = _.find(options, { value: selected });
        let displayText = matchingOption
            ? matchingOption.text
            : `${t(`common:${localNamespace}.select`)}...`;

        return (
            <div className="edit-screen-form-block columns-block v2 w-form">
                <form
                    id="edit-screen-select-one"
                    name="edit-screen-select-one"
                    className="edit-screen-form"
                >
                    {!hideTitle && (
                        <div className="edit-item-label">
                            {t(`common:${localNamespace}.default_filter`)}
                            <span className="gray-text" />
                            <br />
                        </div>
                    )}
                    <div className="select-add-new">
                        <select
                            onChange={this.handleChange}
                            id={target}
                            name={target}
                            className="hidden-select-field dropdown-option w-select"
                        >
                            <option key={`tag-select-none`} value={''}>
                                -{t(`common:${localNamespace}.select`)}-
                            </option>
                            {_options}
                        </select>
                        <div>{displayText}</div>
                        <a
                            href="#"
                            className="add-option-button square-button select-button w-inline-block"
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(EditScreenSelectOne);
