import React from 'react';

import { ComposeMessageState } from '@core/types/Messages';

type ComposeMessageFormActionButtonProps = {
    state: ComposeMessageState;
    page: number;
    loading?: boolean;
    handleNextStep: () => void;
    handleSubmit: (e: any) => void;
};

const ComposeMessageFormActionButton: React.FC<ComposeMessageFormActionButtonProps> = ({
    state,
    page,
    loading,
    handleNextStep,
    handleSubmit,
}) => {
    if (!state) return <></>;
    const button: { text?: string; action?: (e?: any) => void } = {
        text: undefined,
        action: undefined,
    };

    const baseBtnClass = 'primary-action-btn grow disabled-btn';

    if (state?.participants?.length <= 1 && page === 0) {
        button.text = 'Create';
        button.action = handleSubmit;
    }

    if (state?.participants?.length > 1 && page === 0) {
        button.text = 'Next';
        button.action = handleNextStep;
    }

    if (state?.participants?.length > 1 && page === 1) {
        button.text = 'Create';
        button.action = handleSubmit;
    }

    const handleClick = () => {
        button?.action?.();
    };

    const isActiveCondition1 =
        page === 1 &&
        state?.participants?.length > 1 &&
        state?.title?.trim() !== '' &&
        state?.title?.length > 0;
    const isActiveCondition2 = page === 0 && state?.participants?.length >= 1;

    let actionBtnClass = baseBtnClass;
    if (isActiveCondition1 || isActiveCondition2) {
        actionBtnClass = 'primary-action-btn grow';
    }

    if (loading) actionBtnClass = `${baseBtnClass} disabled-btn`;

    return (
        <button className={actionBtnClass} type="button" onClick={handleClick}>
            {loading ? 'Loading...' : button?.text}
        </button>
    );
};

export default ComposeMessageFormActionButton;
