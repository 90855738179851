import React from 'react';

import Checkbox from '@web/ui/dsc/forms/customInputs/Checkbox';
import ExternalLink from '@web/ui/components/generic/ExternalLink';

type ConsentBoxProps = {
    value: boolean;
    onClick: (value: boolean) => void;
    prompt?: string;
    link?: string;
    className?: string;
    required?: boolean;
};

const ConsentBox: React.FC<ConsentBoxProps> = ({
    value,
    onClick,
    prompt = '',
    link = '',
    className = '',
    required = false,
}) => (
    <section className={`consent-box ${className} ${required ? 'required' : ''}`}>
        <Checkbox value={value} onChange={onClick} />
        {prompt &&
            (link ? (
                <ExternalLink to={link} className="consent-box-text">
                    {prompt}
                </ExternalLink>
            ) : (
                <span className="consent-box-text">{prompt}</span>
            ))}
    </section>
);

export default ConsentBox;
