import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Updater, DraftFunction } from 'use-immer';

import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';
import useModal from '@components/modals/hooks/useModal';

import EventGroupOverviewForm from '@components/content/newpost/forms/EventGroup/EventGroupOverviewForm';
import EventGroupDateAndTimeForm from '@components/content/newpost/forms/EventGroup/EventGroupDateAndTimeForm';

import EventListingFinishStep from '@components/content/newpost/event/EventListingFinishStep';
import HorizontalNavHeader from '@dsc/navigation/HorizontalNavHeader';
import HorizontalNavFooter from '@dsc/navigation/HorizontalNavFooter';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary';

import { ModalTypes } from '@core/types/Modals';
import { getNavFunctions } from '@helpers/navigation/horizontalNav.helpers';
import { curriedStateSlice } from '@helpers/state/state.helpers';

import { EventListingFormState } from '@core/types/EventListing';
import { Maybe, ContentCard } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

const getButtonText = (currentPageName: string): string => {
    if (currentPageName === 'dateAndTime') return 'Publish';
    return 'Next';
};

type EventGroupFormProps = {
    state: EventListingFormState;
    setState: Updater<EventListingFormState>;
    onSubmit: () => void;
    title?: string;
    singleImageUpload?: boolean;
    cardData?: Maybe<ContentCard>;
    showSave?: boolean;
};

const EventListingForm: React.FC<EventGroupFormProps> = ({
    state,
    setState,
    onSubmit,
    title = 'Create Event Listing',
    cardData,
    singleImageUpload,
    showSave,
}) => {
    const { t } = useTranslation();

    const [validation, setValidation] = useState<{ overview: boolean; dateAndTime: boolean }>({
        overview: true,
        dateAndTime: false,
    });

    const updateSlice = curriedStateSlice(setState);

    const updateValidationSlice = (name: string, value: boolean | DraftFunction<boolean>) => {
        const validationClone = { ...validation };
        validationClone[name] = value;
        setValidation(validationClone);
    };

    const { closeModal, replaceModal } = useModal({
        mobile: ModalTypes.FullScreen,
        desktop: ModalTypes.FullScreen,
    });

    const refs = {
        overview: useRef<HTMLFieldSetElement>(null),
        dateAndTime: useRef<HTMLFieldSetElement>(null),
    };

    const { on, scrollTo } = useHorizontalPages({ refs });

    const { currentPage, currentPageName, goForward, goBack, headerPages, footerPages } =
        getNavFunctions({
            on,
            scrollTo,
            pages: Object.keys(refs).map(page => ({
                name: page as keyof typeof refs,
                canSkip: false,
                showMainButton: true,
            })),
        });

    useEffect(() => {
        if (cardData) {
            // Timeout is necessary to wait for loading modal to close before replacing
            setTimeout(
                () =>
                    replaceModal(<EventListingFinishStep title={title} card={cardData} />, {
                        className: 'center-fixed-header-footer',
                    }),
                500
            );
        }
    }, [cardData]);

    const allValid = Object.values(validation).every(v => v);

    return (
        <form onSubmit={onSubmit} className="scroll-middle-fixed-header-footer">
            <ErrorBoundary>
                <header>
                    <section className="header-info-block">
                        <section className="header-title">
                            <h1>{title}</h1>
                        </section>

                        <button className="cancel-btn" type="button" onClick={closeModal}>
                            {t(`common:${localNamespace}.cancel`)}
                        </button>
                    </section>

                    <HorizontalNavHeader
                        currentPage={currentPage}
                        pages={headerPages}
                        tag="section"
                        showCurrentPage
                    />
                </header>

                <article>
                    <EventGroupOverviewForm
                        state={state?.overviewFormState}
                        singleImageUpload={singleImageUpload}
                        ref={refs?.overview}
                        setState={updateSlice('overviewFormState')}
                        updateValidationSlice={value => updateValidationSlice('overview', value)}
                    />
                    {validation.overview && (
                        <EventGroupDateAndTimeForm
                            state={state?.dateAndTimeState}
                            bodyText="Select a date or date range and start and end times for your event."
                            ref={refs?.dateAndTime}
                            setState={updateSlice('dateAndTimeState')}
                            updateValidationSlice={value =>
                                updateValidationSlice('dateAndTime', value)
                            }
                        />
                    )}
                </article>

                <HorizontalNavFooter
                    currentPage={currentPage}
                    pages={footerPages}
                    goBack={goBack}
                    showSettingsButton={false}
                    canDoMainAction={
                        currentPageName === 'dateAndTime' ? allValid : validation[currentPageName]
                    }
                    mainAction={
                        currentPageName === 'dateAndTime'
                            ? onSubmit
                            : () => {
                                  goForward(false);
                              }
                    }
                    buttonText={getButtonText(currentPageName)}
                    showForwardButton={false}
                    showSave={showSave}
                    onSave={onSubmit}
                    saveText={t(`common:${localNamespace}.Save`, 'Save')}
                />
            </ErrorBoundary>
        </form>
    );
};

export default EventListingForm;
