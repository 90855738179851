import React from 'react';

const RightCaretIcon: React.FC<{
    className?: string;
    size?: string;
}> = ({ className, size = '27' }) => (
    <svg
        className={className}
        width={size}
        height={size}
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.1591 12.9034L10.7216 4.46585C10.6036 4.34786 10.4533 4.26751 10.2896 4.23496C10.1259 4.20241 9.95627 4.21912 9.8021 4.28299C9.64792 4.34685 9.51615 4.455 9.42344 4.59375C9.33073 4.73251 9.28125 4.89564 9.28125 5.06251V21.9375C9.28125 22.1044 9.33074 22.2675 9.42345 22.4063C9.51616 22.545 9.64793 22.6532 9.8021 22.717C9.95627 22.7809 10.1259 22.7976 10.2896 22.7651C10.4533 22.7325 10.6036 22.6522 10.7216 22.5342L19.1591 14.0967C19.2375 14.0183 19.2996 13.9253 19.342 13.8229C19.3844 13.7206 19.4063 13.6108 19.4063 13.5C19.4063 13.3892 19.3844 13.2795 19.342 13.1771C19.2996 13.0747 19.2375 12.9817 19.1591 12.9034Z"
            fill="black"
        />
    </svg>
);

export default RightCaretIcon;
