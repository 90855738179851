import React, {useState} from 'react';
import usePeopleSearchQuery from '@web/ui/components/generic/hooks/usePeopleSearchQuery';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

const SearchPeopleContainer = ({ handleClick }) => {
    const [searchQuery, setSearchQuery] = useState(' ');
    const { foundUsers, searchNetworkLoading, searchNetworkError } = usePeopleSearchQuery(
        searchQuery
    );

    return (
        <SearchPeopleListView
            handleClick={handleClick}
            foundUsers={foundUsers}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
        />
    );
};

const SearchPeopleListView = ({ setSearchQuery, searchQuery, foundUsers, handleClick }) => {
    const renderPeopleList = foundUsers?.map(person => {
        const _handleClick = () => handleClick(person);
        return <UserListBlockView key={person._id} userData={person} handleClick={_handleClick} />;
    });

    return (
        <div className="search-people-container">
            <input
                onChange={e => setSearchQuery(e.currentTarget.value)}
                className="text-field searchfield w-input"
                type="text"
                maxLength="256"
                placeholder="Search user by name..."
                id="search-people"
            />
            <div>{renderPeopleList}</div>
        </div>
    );
};

export default SearchPeopleContainer;

const UserListBlockView = ({
    handleClick,
    iconClass = 'inbox-selection-indicator',
    userData: { _id, profile },
}) => {
    const { full_name, coverPhoto, picture } = profile || {};
    const thumbImage = picture || coverPhoto || getRandomDefaultUserImage();
    const [src, srcSet] = getBackgroundImageSetForUrl(thumbImage || getRandomDefaultUserImage());

    return (
        <div className="inbox-item-block w-inline-block" id={_id} onClick={handleClick}>
            <StyledBackgroundImage
                className="thumbnail larger-thumbnail"
                style={{ backgroundSize: 'cover' }}
                src={src}
                srcSet={srcSet}
            />
            <div className="inbox-item-content">
                <div className="inbox-item-name">{full_name}</div>
            </div>
            <div className="inbox-row-right suggested">
                <div className={iconClass} />
            </div>
        </div>
    );
};
