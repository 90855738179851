import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type CustomerOrderDetailsProps = {
    name: string;
    phone: string;
    email: string;
    orderTotal: string;
    paymentType: string;
    orderLocation: string;
    processedBy?: string;
    orderInstant?: string;
    orderWasRefunded?: boolean;
    orderRefundTotal?: string;
    refundDate?: string;
};

const CustomerOrderDetails: React.FC<CustomerOrderDetailsProps> = ({
    name,
    phone,
    email,
    orderTotal,
    paymentType,
    orderLocation,
    processedBy,
    orderInstant,

    orderWasRefunded,
    orderRefundTotal,
    refundDate,
}) => {
    const { t } = useTranslation();

    const createdAt = new Date(Number(orderInstant));
    const formattedDate = moment(createdAt).format('M/D/YYYY h:mm a');

    let paymentTypeDisplayString = t('common:global.credit_card', 'Credit Card'); // TODO N/A if free
    if (paymentType === 'cash') paymentTypeDisplayString = t('common:global.cash', 'Cash');

    let orderLocationDisplayString = t('common:global.online', 'Online');
    if (orderLocation === 'inPerson')
        orderLocationDisplayString = t('common:global.in_person', 'In Person');

    const refundIssuedDate = moment.unix(refundDate).format('M/D/YYYY h:mm a');

    return (
        <>
            <div className="border-grayscale-line flex flex-col border-b-[1px] border-solid pb-[20px]">
                <span className="text-grayscale-title-active mb-[10px] text-[17px] font-[600] tracking-[0.75px]">
                    {name}
                </span>
                {phone && (
                    <span className="text-grayscale-title-active mb-[5px] text-[14px]">
                        {/* TODO format */}
                        {phone}
                    </span>
                )}
                {email && <span className="text-grayscale-title-active text-[14px]">{email}</span>}
            </div>

            <div className="border-grayscale-line text-grayscale-title-active flex flex-col gap-[10px] border-b-[1px] border-solid pb-[20px] text-[14px]">
                <div className="flex">
                    <span className="">{t(`common:global.order_total`, 'Order Total')}</span>
                    <span className="ml-auto font-[600]">{orderTotal}</span>
                </div>
                <div className="flex">
                    <span className="">{t(`common:global.payment_type`, 'Payment Type')}</span>
                    <span className="ml-auto font-[600]">{paymentTypeDisplayString}</span>
                </div>
                <div className="flex">
                    <span className="">{t(`common:global.order_location`, 'Order Location')}</span>
                    <span className="ml-auto font-[600]">{orderLocationDisplayString}</span>
                </div>
                {processedBy && (
                    <div className="flex">
                        <span className="">{t(`common:global.processed_by`, 'Processed By')}</span>
                        <span className="ml-auto font-[600]">{processedBy}</span>
                    </div>
                )}
                {orderInstant && (
                    <div className="flex">
                        <span className="">{t(`common:global.order_date`, 'Order Date')}</span>
                        <span className="ml-auto font-[600]">{formattedDate}</span>
                    </div>
                )}
            </div>

            {orderWasRefunded && (
                <div className="border-grayscale-line text-grayscale-title-active flex flex-col gap-[10px] border-b-[1px] border-solid pb-[20px] text-[14px]">
                    {orderRefundTotal && (
                        <div className="flex">
                            <span className="">
                                {t(`common:global.order_date_refund_total`, 'Refund Total')}
                            </span>
                            <span className="ml-auto font-[600] text-red-500">
                                -{orderRefundTotal}
                            </span>
                        </div>
                    )}

                    {refundDate && (
                        <div className="flex">
                            <span className="">
                                {t(`common:global.order_date_refund_date`, 'Order Refund Date')}
                            </span>
                            <span className="ml-auto font-[600]">{refundIssuedDate}</span>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default CustomerOrderDetails;
