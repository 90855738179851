import React from 'react';
import { useTranslation } from 'react-i18next';

import TrashBin from '@dsc/svgs/Trash';
import Checkmark from '@dsc/svgs/Checkmark';

export const NewTicketHeader: React.FC<{
    closeModal: () => void;
    handleOnClick: () => void;
    headerTitle: string;
}> = ({ closeModal, handleOnClick, headerTitle }) => {
    const { t } = useTranslation();

    return (
        <header className="ticket-full-view-header">
            <div className="ticket-full-view-text-wrap">
                <h2>{headerTitle}</h2>
                <p>{`${t('common:required_fields', 'Required fields')}*`}</p>
            </div>
            <div className="ticket-full-view-header-btn-wrap">
                <button
                    onClick={closeModal}
                    type="button"
                    className="ticket-full-view-header-cancel"
                >
                    {t('common:cancel', 'Cancel')}
                </button>
                <button
                    onClick={handleOnClick}
                    type="button"
                    className="ticket-full-view-header-add"
                >
                    {t('common:add', 'Add')}
                </button>
            </div>
        </header>
    );
};

export const EditTicketHeader: React.FC<{
    index: number;
    closeModal: () => void;
    handleOnClick: () => void;
    deleteTicket: (index: number) => void;
}> = ({ index, deleteTicket, closeModal, handleOnClick }) => {
    const { t } = useTranslation();

    return (
        <header className="ticket-full-view-header ticket-full-view-edit-header">
            <button onClick={closeModal} type="button" className="ticket-full-view-header-cancel">
                {t('common:cancel', 'Cancel')}
            </button>
            <div className="ticket-full-view-header-btn-wrap">
                <button
                    onClick={() => deleteTicket(index)}
                    type="button"
                    className="ticket-full-view-header-delete"
                >
                    <TrashBin />
                </button>
                <button
                    onClick={handleOnClick}
                    type="button"
                    className="ticket-full-view-header-add"
                >
                    <Checkmark />
                </button>
            </div>
        </header>
    );
};

const TicketFormHeader: React.FC<{
    index: number;
    inEditMode: boolean;
    closeModal: () => void;
    handleOnClick: () => void;
    deleteTicket: (index: number) => void;
    headerTitle: string;
}> = ({ index, inEditMode, closeModal, handleOnClick, deleteTicket, headerTitle }) => {
    return (
        <>
            {inEditMode ? (
                <EditTicketHeader
                    index={index}
                    closeModal={closeModal}
                    handleOnClick={handleOnClick}
                    deleteTicket={deleteTicket}
                />
            ) : (
                <NewTicketHeader
                    closeModal={closeModal}
                    handleOnClick={handleOnClick}
                    headerTitle={headerTitle}
                />
            )}
        </>
    );
};

export default TicketFormHeader;
