import React from 'react';

import LoadingIndicator from '@components/generic/loading/LoadingIndicator';
import { capitalize, titleCase } from '@helpers/string.helpers';
import { useTranslation } from 'react-i18next';

import FontSelector from './FontSelector';
import SignatureInput from './SignatureInput';

interface SignatureModalContentProps {
    font: string;
    setFont: (font: string) => void;
    signature: string;
    setSignature: (signature: string) => void;
    options: JSX.Element[];
    onSave: () => void;
    isLoading: boolean;
    error: any;
    warning: string;
}

const SignatureModalContent: React.FC<SignatureModalContentProps> = ({
    font,
    setFont,
    signature,
    setSignature,
    options,
    onSave,
    isLoading,
    error,
    warning,
}) => {
    const { t } = useTranslation();
    return (
        <section className="notify-panel px-6">
            <header className="notify-panel-header">
                <h1>{titleCase(t('common:adopt_your_signature', 'adopt your signature'))}</h1>
                <p>
                    {capitalize(
                        t(
                            'common:select_a_font_style,_then_type_your_signature.',
                            'select a font style, then type your signature.'
                        )
                    )}
                </p>
            </header>
            <main className="w-full">
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <FontSelector font={font} setFont={setFont} options={options} />
                        <br />
                        <SignatureInput signature={signature} setSignature={setSignature} />
                    </>
                )}
            </main>
            <footer className="center-modal-header-footer notify-panel-footer">
                {!isLoading && (
                    <>
                        {error && <div className="text-center text-red-600">{error.message}</div>}
                        {warning && <div className="text-center text-red-600">{warning}</div>}
                        <button onClick={onSave}>{isLoading ? 'Saving...' : 'Save'}</button>
                    </>
                )}
            </footer>
        </section>
    );
};

export default SignatureModalContent;
