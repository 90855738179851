import React from 'react';
import { useTranslation } from 'react-i18next';

import useSellTicketsAtDoor from '../hooks/useSellTicketsAtDoor';
import shoppingCartStore, { SELL_TICKETS_AT_DOOR_STEPS } from '@stores/ShoppingCart';

import HorizontalNavHeader from '@dsc/navigation/HorizontalNavHeader';
import SelectTickets from './SelectTickets';
import SellTicketsOrderDetails from './SellTicketsOrderDetails';
import SellTicketsPaymentDetails from './SellTicketsPaymentDetails';
import SellTicketsConfirmOrder from './SellTicketsConfirmOrder';
import ViewCustomerOrder from './ViewCustomerOrder';

import { Group } from '@shared/welibrary-graphql/types';

type SellTicketsProps = {
    group: Group;
};

const SellTickets: React.FC<SellTicketsProps> = ({ group }) => {
    const { t } = useTranslation();
    const { currentStep, isEventFree, order } = useSellTicketsAtDoor(group);

    if (currentStep === SELL_TICKETS_AT_DOOR_STEPS.viewCompletedOrder && order) {
        return (
            <ViewCustomerOrder
                order={order}
                goBack={() => shoppingCartStore.set.resetSellTickets(group._id)}
                showRefundOrder={false}
                backTextOverride={t('common:global.done', 'Done')}
            />
        );
    }

    return (
        <div className="h-full">
            <HorizontalNavHeader
                currentPage={
                    // shenanigans to handle one less step for free events
                    isEventFree && currentStep > SELL_TICKETS_AT_DOOR_STEPS.paymentDetails
                        ? currentStep - 1
                        : currentStep
                }
                pages={isEventFree ? [false, false, false] : [false, false, false, false]}
            />

            {currentStep === SELL_TICKETS_AT_DOOR_STEPS.selectTickets && (
                <SelectTickets group={group} />
            )}
            {currentStep === SELL_TICKETS_AT_DOOR_STEPS.orderDetails && (
                <SellTicketsOrderDetails group={group} />
            )}
            {currentStep === SELL_TICKETS_AT_DOOR_STEPS.paymentDetails && (
                <SellTicketsPaymentDetails group={group} />
            )}
            {currentStep === SELL_TICKETS_AT_DOOR_STEPS.confirmOrder && (
                <SellTicketsConfirmOrder group={group} />
            )}
        </div>
    );
};

export default SellTickets;
