import styled from 'styled-components';
import React from 'react';
import SSkeletonPulse from './SkeletonPulse';

const SSkeletonCircle = styled(SSkeletonPulse)`
    height: ${props => (props.height ? `${props.width}` : '30px')};
    width: ${props => (props.width ? `${props.width}` : '30px')};
    border-radius: 50%;

    &::before {
        content: '\\00a0';
    }
`;

export default ({ width, height }) => <SSkeletonCircle width={width} height={height} />;
