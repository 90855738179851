import React from 'react';

type StylizedCheckProps = {
    className?: string;
    strokeWidth?: string | number;
};

const StylizedCheck: React.FC<StylizedCheckProps> = ({ className = '', strokeWidth = '3' }) => {
    return (
        <svg viewBox="0 0 37 27" fill="none" className={className}>
            <path
                d="M6.24762 9.21196L3.40734 9.37484L5.14636 11.6264L13.8096 22.8429L14.9594 24.3317L16.1549 22.8793L30.9699 4.87926L32.9891 2.42603H29.8118H22.411H21.6349L21.1866 3.05953L15.1288 11.6196L13.9694 9.59956L13.508 8.7956L12.5826 8.84867L6.24762 9.21196Z"
                fill="currentcolor"
                stroke="white"
                strokeWidth={strokeWidth}
            />
        </svg>
    );
};

export default StylizedCheck;
