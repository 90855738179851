import React from 'react';
import {cardToAttachType, TYPES_TO_ICON_CLASS} from './constants.js';
import {ATTACH_DOCUMENT, ATTACH_IMAGE} from '@core/utilities/constants/attachment_types.js';
import {useTranslation} from 'react-i18next';
import {
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_MOBILIZATION,
    CARD_MOBILIZE,
    CARD_TEXT,
    CARD_VIDEO,
    CARD_VOLUNTEER_MOBILIZATION,
} from '@core/utilities/constants/card_types.js';

const FILE_PICKER_ATTACH_TYPES = [ATTACH_IMAGE, ATTACH_DOCUMENT];

const NewPostTypesOptions = ({
    allAllowedTypes,
    openModalHandler,
    handleSquarePhotoSelect,
    handleFileSelect,
    expandAttachment,
}) => {
    // Mobilization types are shown on the front end as two differnt icons, job and volunteer icons
    if (allAllowedTypes.includes(CARD_MOBILIZE)) {
        allAllowedTypes = [...allAllowedTypes, CARD_VOLUNTEER_MOBILIZATION];
    }
    const renderIcons = allAllowedTypes?.map(type => {
        return (
            <NewPostTypeButton
                key={type}
                type={type}
                handleFileSelect={handleFileSelect}
                expandAttachment={expandAttachment}
                handleClick={
                    type === CARD_IMAGE_SQUARE ? handleSquarePhotoSelect : openModalHandler
                }
            />
        );
    });
    return <div className="new-post-content-types fadein">{renderIcons}</div>;
};

const NewPostTypeButton = ({ type, handleClick, handleFileSelect, expandAttachment }) => {
    const { t } = useTranslation();
    if (type === CARD_MOBILIZE) type = CARD_MOBILIZATION;
    if (type === CARD_TEXT) return null;

    const baseClass = 'new-post-media-button';
    const iconClass = TYPES_TO_ICON_CLASS[type];

    const divClass = `${baseClass} ${iconClass}`;
    let nameKey = type;
    if (type === CARD_MOBILIZATION) nameKey = 'mobilize_job_posting';
    if (type === CARD_VOLUNTEER_MOBILIZATION) nameKey = 'mobilize_volunteer_posting';
    const displayName = t(`common:global.constants.cards.types.${nameKey}`);

    const _handleClick = () => {
        const attachType = cardToAttachType[type];
        if (type === CARD_LINK || type === CARD_VIDEO) {
            expandAttachment(attachType);
        } else if (FILE_PICKER_ATTACH_TYPES.includes(attachType)) {
            handleFileSelect(attachType);
        } else {
            handleClick(type);
        }
    };
    return (
        <div className="new-post-content-button-container">
            <button onClick={_handleClick} type="button">
                <div className={divClass} />
                {displayName}
            </button>
        </div>
    );
};

export default NewPostTypesOptions;
