import React, { FormEventHandler, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';
import useModal from '@components/modals/hooks/useModal';
import useCancelableTimer from '@components/generic/hooks/useCancelableTimer';

import CourseFormSettings from '@components/content/newpost/forms/Course/CourseFormSettings';
import CourseFormInformation from '@components/content/newpost/forms/Course/CourseFormInformation';
import CourseFormLevels from '@components/content/newpost/forms/Course/CourseFormLevels';
import CourseFormLevelDetails from '@components/content/newpost/forms/Course/CourseFormLevelDetails';

import { CourseState } from '@core/types/Courses';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

type CourseFormProps = {
    state: CourseState;
    onSubmit: FormEventHandler<HTMLFormElement>;
    hasAdminRole?: boolean;
    chapterTitle?: string;
    title?: string;
    buttonText?: string;
};

const CourseForm: React.FC<CourseFormProps> = ({
    state: {
        settings: { cardSettings, setCardSettings },
        card: { card, setCard },
        levels: { levels, addLevel, deleteLevel, updateLevel, activeLevel, setActiveLevel },
    },
    onSubmit,
    hasAdminRole,
    chapterTitle,
    title = 'Create Course',
    buttonText = 'Create',
}) => {
    const { t } = useTranslation();

    const { closeModal } = useModal();

    const container = useRef<HTMLFormElement>(null);
    const refs = {
        settings: useRef<HTMLElement>(null),
        information: useRef<HTMLFieldSetElement>(null),
        levelsPage: useRef<HTMLElement>(null),
        levelDetails: useRef<HTMLFieldSetElement>(null),
    };

    const { on, scrollTo, observe } = useHorizontalPages({
        refs,
        options: { root: container.current },
    });

    const timer1 = useCancelableTimer();
    const timer2 = useCancelableTimer();

    // show main when first opening form
    useEffect(() => {
        timer1.start(() => scrollTo.information(false), 100);
    }, []);

    // scroll to level details when user clicks edit level
    useEffect(() => {
        if (activeLevel !== false && refs.levelDetails.current) {
            // wait to observe until we've finished scrolling to levelDetails
            timer1.start(observe, 500);

            timer2.start(scrollTo.levelDetails, 50);
        }
    }, [activeLevel, refs.levelDetails.current]);

    // remove level details page when user scrolls away from it
    useEffect(() => {
        if (activeLevel !== false && !on.levelDetails) {
            timer1.start(() => setActiveLevel(false), 150);
        }
    }, [on.levelDetails]);

    const showBackButton = !on.information;

    return (
        <form onSubmit={onSubmit} className="create-course-form" ref={container}>
            <header>
                <section>
                    <button
                        className={showBackButton ? '' : 'hidden'}
                        onClick={() => {
                            if (on.levelDetails) scrollTo.levelsPage();
                            else scrollTo.information();
                        }}
                        type="button"
                        aria-label="Back"
                    />
                    <h1>{title}</h1>
                </section>
                <button type="button" onClick={closeModal}>
                    {t(`common:${localNamespace}.cancel`)}
                </button>
            </header>

            <article>
                <CourseFormSettings
                    ref={refs.settings}
                    hasAdminRole={hasAdminRole}
                    cardSettings={cardSettings}
                    setCardSettings={setCardSettings}
                />

                <CourseFormInformation
                    ref={refs.information}
                    card={card}
                    setCard={setCard}
                    levels={levels}
                />

                <CourseFormLevels
                    ref={refs.levelsPage}
                    levels={levels}
                    addLevel={addLevel}
                    editLevel={setActiveLevel}
                    deleteLevel={deleteLevel}
                />

                {activeLevel !== false && (
                    <CourseFormLevelDetails
                        ref={refs.levelDetails}
                        level={levels[activeLevel]}
                        setLevel={updateLevel}
                    />
                )}
            </article>

            <footer>
                <section>
                    {chapterTitle && (
                        <span>{`${t('common:global.verbs.posting_into')} ${chapterTitle}`}</span>
                    )}
                </section>
                <section>
                    <button
                        type="button"
                        onClick={() => {
                            if (on.settings) scrollTo.information();
                            else scrollTo.settings();
                        }}
                        className="toggle-settings"
                        aria-label="Settings"
                    />

                    <button type="submit">{buttonText}</button>

                    {!on.levelsPage && (
                        <button
                            type="button"
                            onClick={() => scrollTo.levelsPage()}
                            className="levels-button"
                        >
                            Levels
                        </button>
                    )}

                    {on.levelsPage && (
                        <button
                            type="button"
                            onClick={() => scrollTo.information()}
                            className="levels-button"
                        >
                            Information
                        </button>
                    )}
                </section>
            </footer>
        </form>
    );
};

export default CourseForm;
