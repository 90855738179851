import React from 'react';
import { useTranslation } from 'react-i18next';

import searchStore from '@stores/Search';

import { isKey } from '@helpers/types/type.helpers';

import { DEFAULT_FILTERS, FILTER_TYPES } from '@components/search/constants';
import { SearchFilterType } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.search.SearchOverlay';

const HIDDEN_FILTERS = {
    chapter: [FILTER_TYPES.USER, FILTER_TYPES.GROUP],
    book: [FILTER_TYPES.USER, FILTER_TYPES.GROUP],
};

const FILTER_NAMES = {
    [FILTER_TYPES.USER]: 'People',
    [FILTER_TYPES.GROUP]: 'Groups',
    [FILTER_TYPES.COLLECTION]: 'Collections',
    [FILTER_TYPES.POST]: 'Content',
    [FILTER_TYPES.PROMPT]: 'Prompts',
    [FILTER_TYPES.MESSAGE]: 'Messages',
    [FILTER_TYPES.COMMENT]: 'Comments',
};

const SearchFiltersMenu: React.FC = () => {
    const { t } = useTranslation();
    const { filter: currentFilter, currentScope } = searchStore.useStore();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const filterName = e.currentTarget.name as SearchFilterType | 'ALL';

        searchStore.set.changeFilter(filterName);
    };

    const isAllTab = searchStore.get.isAllTab();

    const renderFilterList = Object.values(FILTER_TYPES)
        .filter(type => DEFAULT_FILTERS.includes(type))
        .map(filter => {
            if (
                currentScope &&
                isKey(HIDDEN_FILTERS, currentScope.type) &&
                HIDDEN_FILTERS[currentScope.type].includes(filter)
            ) {
                return null;
            }
            return (
                <button
                    type="button"
                    key={FILTER_NAMES[filter]}
                    name={filter}
                    className={`button-reset search-results-tab ${
                        currentFilter[0] === filter && !isAllTab ? 'selected' : ''
                    }`}
                    onClick={handleClick}
                >
                    {FILTER_NAMES[filter]}
                </button>
            );
        });

    return (
        <div className="search-results-tab-container">
            <div className="search-results-tabs-menu disable-scrollbars">
                <button
                    type="button"
                    name="ALL"
                    className={`button-reset search-results-tab ${isAllTab ? 'selected' : ''}`}
                    onClick={handleClick}
                >
                    {t(`common:${localNamespace}.top`, 'Top')}
                </button>
                {renderFilterList}
            </div>
        </div>
    );
};

export default SearchFiltersMenu;
