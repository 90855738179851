import React, { useState, useEffect } from 'react';

import Toggle from '@dsc/forms/customInputs/Toggle';

import { Group, PaginatedGroups } from '@shared/welibrary-graphql/content_card/queries.hook';

const NotifyPanelHeader: React.FC<{
    group: Group;
    subgroups: PaginatedGroups;
    selectedGroups: string[];
    handleAddGroup: (id: string) => void;
    handleRemoveGroup: (id: string) => void;
    allToggled: boolean;
    handleSelectingAllAdditionalMembers: (toggled: boolean) => void;
}> = ({
    group,
    subgroups,
    selectedGroups,
    handleAddGroup,
    handleRemoveGroup,
    allToggled,
    handleSelectingAllAdditionalMembers,
}) => {
    const [isToggled, setIsToggled] = useState<boolean>(
        selectedGroups?.includes(group?._id) ?? false
    );

    useEffect(() => {
        setIsToggled(selectedGroups?.includes(group?._id) ?? false);
    }, [selectedGroups]);

    //The function `handleMembersToggle` toggles a boolean value and calls different functions
    const handleMembersToggle = (toggled: boolean) => {
        setIsToggled(toggled);

        if (isToggled) {
            handleRemoveGroup(group?._id);
        } else {
            handleAddGroup(group?._id);
        }
    };

    return (
        <header className="notify-panel-header">
            <h4>{group?.profile?.full_name}</h4>
            <h1>Notify Membership</h1>
            <p>This will send a system notification and email to all selected users</p>

            <div className="notify-panel-toggle-wrap">
                <div>
                    <h4 className="notify-panel-toggle-label">
                        {group?.profile?.full_name} Members
                    </h4>
                    <Toggle
                        className="notify-panel-toggle-btn"
                        onChange={handleMembersToggle}
                        value={isToggled}
                    />
                </div>
                {subgroups?.results?.length > 0 && (
                    <div>
                        <h4 className="notify-panel-toggle-label">Additional Members</h4>
                        <button
                            onClick={() => {
                                handleSelectingAllAdditionalMembers(!allToggled);
                            }}
                            type="button"
                            className="notify-panel-toggle-btn-all"
                        >
                            {allToggled ? 'Deselect All' : 'Select All'}
                        </button>
                    </div>
                )}
            </div>
        </header>
    );
};

export default NotifyPanelHeader;
