/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import ImageGrid from '../imagecard/ImageGrid';
import ImageSlider from '../imagecard/ImageSlider';

import useFilestackPicker from '@web/ui/components/generic/hooks/useFilestackPicker';

import {FilestackImage} from '@core/types/Utilities';
import {AliasItem} from '@shared/welibrary-graphql/types';

import {IMAGE_MIME_TYPES} from '@core/utilities/constants/filestack';
import {CARD_IMAGE_SQUARE} from '@core/utilities/constants/card_types.js';

const EditSquareImageCard: React.FC<{
    state: any; // card state,
    aliases: AliasItem[]; // images stored/selected
    handleImgToggle: (img?: FilestackImage | AliasItem, imgUploads?: FilestackImage[]) => void; // toggles images selected on/off
}> = ({ state, aliases, handleImgToggle }) => {
    const { i18n } = useTranslation();
    const openPicker = useFilestackPicker();
    const [uploadedImages, setUploadedImages] = useState<FilestackImage[]>([]);

    let imagesSelected: AliasItem[] = [];

    if (aliases.length === 0) {
        // if there is no aliases, create an aliasItem from the stored mediaThumb
        imagesSelected = [
            {
                thumb: '',
                title: null,
                type: 'alias_square_image',
                url: state.mediaThumb,
            },
        ];
    } else if (aliases.length >= 1) {
        imagesSelected = aliases;
    }

    const handleSquarePhotoSelect = () => {
        openPicker({
            accept: IMAGE_MIME_TYPES,
            lang: i18n.language,
            transformations: {
                crop: { aspectRatio: 1, force: true },
                rotate: true,
                circle: false,
            },
            maxFiles: 10,
            onUploadDone: ({ filesUploaded }) => {
                setUploadedImages(filesUploaded);
            },
        });
    };

    return (
        <div className="content-card-container">
            <div className="legacy-lightbox-container">
                {/* image slider */}
                {imagesSelected.length > 0 && (
                    <ImageSlider aliases={imagesSelected} disableExternalLink />
                )}
                {/* image grid */}
                {imagesSelected.length >= 1 && (
                    <ImageGrid
                        rerender
                        aliases={aliases}
                        images={uploadedImages}
                        imagesSelected={imagesSelected}
                        handleImgToggle={handleImgToggle}
                        handleOpeningPicker={handleSquarePhotoSelect}
                        mode="edit"
                        type={CARD_IMAGE_SQUARE}
                    />
                )}
            </div>
        </div>
    );
};

export default EditSquareImageCard;
