import {CLOSE_DRAWER, OPEN_DRAWER} from '@core/client/types.js';

export default function drawers(state = {}, action = {}) {
    switch (action.type) {
        case OPEN_DRAWER:
            //Default state is not open.
            let isOpen = false;
            //If the state has already been set to open or closed at the id, get that
            if (state.hasOwnProperty(action.id)) {
                if (state[action.id].url == action.drawerURL) isOpen = state[action.id].open;
            }
            // Toggle the open state at the id
            let drawerUIItem = {};
            drawerUIItem[action.id] = { open: !isOpen, url: action.drawerURL };
            // Return a new object for the createCard state, merging in the new property.
            return Object.assign({}, state, drawerUIItem);
        case CLOSE_DRAWER:
            // Toggle the open state at the id
            let closedDrawerUIItem = {};
            closedDrawerUIItem[action.id] = { open: false, url: action.drawerURL };
            // Return a new object for the createCard state, merging in the new property.
            return Object.assign({}, state, closedDrawerUIItem);

        default:
            return state;
    }
}
