export type Point = { lat: number; lng: number };

// According to Google...
export const EARTH_RADIUS_IN_MILES = 3963.19;

export const toRadians = (number: number): number => (number * Math.PI) / 180;

export const convertPointToRadians = (point: Point): Point => ({
    lat: toRadians(point.lat),
    lng: toRadians(point.lng),
});

export const getHaversineDistance = (point1: Point, point2: Point): number => {
    const { lat: lat1, lng: lng1 } = convertPointToRadians(point1);
    const { lat: lat2, lng: lng2 } = convertPointToRadians(point2);

    // Haversine formula
    const longitudeDelta = lng2 - lng1;
    const latitudeDelta = lat2 - lat1;
    const a =
        Math.sin(latitudeDelta / 2) ** 2 +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(longitudeDelta / 2) ** 2;

    return 2 * Math.asin(Math.sqrt(a)) * EARTH_RADIUS_IN_MILES;
};
