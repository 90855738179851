import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Updater } from 'use-immer';
import { z } from 'zod';

import { getCountryPicker, renderCountryFlag } from '@core/utilities/constants/countries';
import { curriedStateSlice } from '@helpers/state/state.helpers';

export type MerchantSignupState = {
    country: string;
    email: string;
};

const localNamespace = 'imports.wlWeb.ui.components.stripe';

const ConnectedAccountStep1: React.FC<{
    totalSteps: number;
    currentStep: number;
    handleNextStep: () => void;
    handlePrevStep: () => void;
    state: MerchantSignupState;
    setState: Updater<MerchantSignupState>;
}> = ({ totalSteps, currentStep, handleNextStep, handlePrevStep, state, setState }) => {
    const { t } = useTranslation();

    const updateSlice = curriedStateSlice(setState);
    const [errors, setErrors] = useState<Record<string, string[]>>({});

    const StateValidator = z.object({
        country: z.string().nonempty('Country is required!'),
    });

    // @ts-ignore
    const countryPicker = getCountryPicker({
        value: state.country,
        handleChange: (e: any) => updateSlice('country', e.target.value),
        id: 'country',
        name: 'country',
        className: 'connected-account-form-flags',
    });

    const validate = () => {
        const parsedData = StateValidator.safeParse({ country: state.country });

        if (parsedData.success) {
            setErrors({});
            return true;
        }

        if (parsedData.error) {
            setErrors(parsedData.error.flatten().fieldErrors);
        }

        return false;
    };

    const handleOnClick = () => {
        if (validate()) {
            handleNextStep();
        }
    };

    return (
        <div className="edit-screen-content-view stripe-merchant-account-flow-step">
            <div className="edit-screen-content-items">
                <div className="mc-source-post-wrapper">
                    <p>
                        {t(
                            `common:${localNamespace}.what_country_is_org_in`,
                            'What country is your organization in?'
                        )}
                    </p>
                    <div className="connected-account-form-input-wrap">
                        {state.country &&
                            renderCountryFlag({
                                countryCode: state.country,
                                customClass: '',
                            })}
                        {countryPicker}
                    </div>
                    {errors.country && <p className="stripeAccount-error">{errors.country}</p>}

                    {currentStep > 1 && (
                        <button onClick={handlePrevStep} type="button">
                            {t(`common:${localNamespace}.back`, 'Back')}
                        </button>
                    )}
                    <div className="connected-account-btns-wrap" style={{ marginTop: '50px' }}>
                        {currentStep < totalSteps && (
                            <button onClick={handleOnClick} type="button">
                                {t(`common:${localNamespace}.next`, 'Next')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectedAccountStep1;
