import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Updater } from 'use-immer';
import { sortBy } from 'lodash';

import useModal from '@components/modals/hooks/useModal';
import { useConvertCurrencyLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';

import TicketQuickView from '@components/content/newpost/forms/ShoppingCartProducts/TicketQuickView';
import TicketForm from '@components/content/newpost/forms/ShoppingCartProducts/TicketForm';
import PayoutDisclaimer from '@components/content/newpost/forms/ShoppingCartProducts/PayoutDisclaimer';
import RadioButton from '@components/generic/forms/RadioButton';

import { curriedStateSlice } from '@helpers/state/state.helpers';

import { TicketFormState, ProductState, EventGroupFormModeEnum } from '@core/types/EventGroup';
import { ModalTypes } from '@core/types/Modals';
import { Group, Product } from '@shared/welibrary-graphql/types';
import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';
import { getCurrencyDisplayString } from '~/wl-web/utilities/helpers/money/money.helpers';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

type EventGroupTicketsFormProps = {
    state: TicketFormState;
    setState: Updater<TicketFormState>;
    updateValidationSlice: Updater<boolean>;
    mode: EventGroupFormModeEnum;
    ticketCurrency: string;
    setTicketCurrency: React.Dispatch<React.SetStateAction<string>>;
    group: Group;
};

const EventGroupTicketsForm = React.forwardRef<HTMLFieldSetElement, EventGroupTicketsFormProps>(
    function EventGroupTicketsForm(
        { state, setState, updateValidationSlice, mode, ticketCurrency, setTicketCurrency, group },
        ref
    ) {
        const { t } = useTranslation();

        const updateSlice = curriedStateSlice(setState);
        const updateTickets = updateSlice('products');
        const updateIsMembershipPaid = updateSlice('isGroupMembershipPaid');

        const { newModal, closeModal } = useModal();

        const nonFreeTickets = state.products.filter(product => product.price !== 0);
        const needsToAddTicket = state.isGroupMembershipPaid && nonFreeTickets.length === 0;

        const [invalidTickets, setInvalidTickets] = useState<Partial<Product>[] | []>([]);
        const [priceError, setPriceError] = useState<string>('');

        const [convertCurrency] = useConvertCurrencyLazyQuery();

        const validateTicketsBeforeSubmit = async () => {
            // if the price is not valid return the min charge
            // in the form of the selected currency type
            const { data: minCurrencyCharge } = await convertCurrency({
                variables: {
                    to: ticketCurrency,
                    from: 'USD',
                    amount: '1',
                },
                fetchPolicy: 'network-only',
            });

            setPriceError(
                getCurrencyDisplayString(
                    Number(minCurrencyCharge?.convertCurrency?.value),
                    ticketCurrency,
                    undefined,
                    true,
                    2
                )
            );

            const _tickets = state?.products?.filter(
                t => t?.type !== PRODUCT_TYPES?.GROUP_FREE_MEMBERSHIP
            );

            const invalidTickets = _tickets?.filter(
                t => t.price < minCurrencyCharge?.convertCurrency?.value
            );

            if (invalidTickets?.length === 0) return true;

            setInvalidTickets([...invalidTickets]);
            return false;
        };

        const validate = async () => {
            if (needsToAddTicket) {
                updateValidationSlice(false);
            } else {
                const ticketsAreValid = await validateTicketsBeforeSubmit();

                if (!ticketsAreValid) updateValidationSlice(false);
                else {
                    updateValidationSlice(true);
                    setInvalidTickets([]);
                }
            }
        };

        useEffect(() => {
            validate();
        }, [state, ticketCurrency]);

        const handleDeleteTicket = (index: number) => {
            updateTickets(draft => {
                if (index > -1) draft.splice(index, 1);
            });

            const ticketToDelete = state?.products.find((t, idx) => idx === index);

            setInvalidTickets([
                ...invalidTickets?.filter(t => {
                    return t?.price !== ticketToDelete?.price && t?.name !== ticketToDelete?.name;
                }),
            ]);
        };

        const handleAddTicket = (ticket: Product) => {
            updateTickets(draft => {
                draft.push(ticket as ProductState);
            });
        };

        const handleUpdateTicket = (ticket: Partial<Product>, index: number) => {
            updateTickets(draft => {
                draft[index] = ticket as ProductState;
            });
            closeModal();
        };

        const handleTicketForm = () => {
            newModal(
                <TicketForm
                    groupId="groupId_placeholder"
                    inEditMode={false}
                    addTicket={handleAddTicket}
                    updateTicket={handleUpdateTicket}
                    deleteTicket={handleDeleteTicket}
                    ticketCurrency={ticketCurrency}
                    setTicketCurrency={setTicketCurrency}
                    groupSubtype="event"
                    group={group}
                />,
                { hideButton: true, widen: true, addShadow: true },
                { mobile: ModalTypes.FullScreen, desktop: ModalTypes.Center }
            );
        };

        const handleEditTicketModal = (ticketIndex: number) => {
            const ticket = state.products[ticketIndex];
            newModal(
                <TicketForm
                    index={ticketIndex}
                    groupId="groupId_placeholder"
                    ticket={ticket as Product}
                    addTicket={() => {}}
                    updateTicket={handleUpdateTicket}
                    deleteTicket={index => {
                        handleDeleteTicket(index);
                        closeModal();
                    }}
                    inEditMode
                    ticketCurrency={ticketCurrency}
                    setTicketCurrency={setTicketCurrency}
                    groupSubtype="event"
                    group={group}
                />,
                { hideButton: true, widen: true, addShadow: true },
                { mobile: ModalTypes.FullScreen, desktop: ModalTypes.Center }
            );
        };

        // client side sorting
        // so the free ticket always shows up as the first item in the list
        const sortedTickets = sortBy(state?.products ?? [], ({ type }) =>
            type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP ? 0 : 1
        );

        const renderTicketsList = sortedTickets?.map((ticket, index) => {
            // only show paid tickets for paid events and Free Event Ticket for free events
            if (
                (state.isGroupMembershipPaid && ticket.price !== 0) ||
                (!state.isGroupMembershipPaid && ticket.price === 0)
            ) {
                return (
                    <TicketQuickView
                        ticket={ticket as Product}
                        index={index}
                        key={index}
                        deleteTicket={handleDeleteTicket}
                        handleEditTicketModal={handleEditTicketModal}
                        ticketCurrency={ticketCurrency}
                        invalidTickets={invalidTickets}
                    />
                );
            }
            return undefined;
        });

        // renders a default free ticket on event creation
        const defaultFreeTicket = {
            coupons: [],
            currency: ticketCurrency ?? 'USD',
            description: '',
            fulfillment: { groupId: '', role: 'member' },
            name: 'Free Event Ticket',
            price: 0,
            type: 'GROUP_FREE_MEMBERSHIP',
            _id: '',
        };

        return (
            <fieldset ref={ref} className="main-form-event-group">
                <h3>{t('common:tickets_&_pricing_(optional)', 'Tickets & Pricing (Optional)')}</h3>
                <div className="ticket-form-toggle-wrap main-form-ticket-toggle">
                    <RadioButton
                        name="isPaid"
                        options={[
                            { text: t(`common:${localNamespace}.free`, 'Free'), value: 'free' },
                            { text: t(`common:${localNamespace}.paid`, 'Paid'), value: 'paid' },
                        ]}
                        selected={state.isGroupMembershipPaid ? 'paid' : 'free'}
                        handleRadio={() => updateIsMembershipPaid(!state.isGroupMembershipPaid)}
                    />
                </div>
                {state.isGroupMembershipPaid && (
                    <section className="step-desc">
                        <p>
                            {t(
                                'common:eventGroupForm.tickets_description',
                                "Create Tickets for your event. Before you can accept payments for your tickets, you need to setup a merchant account. Create the tickets now and later after the event is created, you will be able to setup a merchant account with Stripe by clicking on the 'Edit Tickets' button."
                            )}
                        </p>
                        <PayoutDisclaimer />
                    </section>
                )}

                {invalidTickets?.length > 0 && priceError && (
                    <div className="w-full flex items-center justify-center mt-2">
                        <div className="w-full">
                            <p
                                className={`w-full py-[12px] px-[16px] bg-[#f6f7f9] rounded-[12px] p-0 m-0 font-bold border-red-400 border-[1px] border-solid text-red-400`}
                            >
                                One or more tickets, priced in {ticketCurrency}, fall below the
                                specified minimum charge amount of {priceError}
                            </p>
                        </div>
                    </div>
                )}

                <section>{renderTicketsList}</section>

                {mode === EventGroupFormModeEnum.create && !state?.isGroupMembershipPaid && (
                    <TicketQuickView
                        ticket={defaultFreeTicket as Product}
                        deleteTicket={() => {}}
                        handleEditTicketModal={() => {}}
                        ticketCurrency={ticketCurrency}
                    />
                )}

                {state.isGroupMembershipPaid && (
                    <div className="tp-gen-add-container">
                        <button type="button" onClick={handleTicketForm}>
                            <span>{t('common:new_ticket', 'New Ticket')}</span>
                            <div className="circle-plus-icon-dark animate-grow">
                                <div />
                                <div />
                            </div>
                        </button>
                    </div>
                )}

                {needsToAddTicket && (
                    <p className="needs-ticket-warning">
                        You must add at least one ticket. You can change or delete this ticket after
                        creating your event by opening Edit Tickets
                    </p>
                )}
            </fieldset>
        );
    }
);

export default EventGroupTicketsForm;
