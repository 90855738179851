import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';

import Picture from '@components/generic/Picture';
import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import GroupDashboardMenu from '@components/group/groupdashboard/GroupDashboardMenu';
import GroupDashboardContextHeader from '@components/group/groupdashboard/GroupDashboardContextHeader';
import GroupSummaryArea from '@components/group/groupdashboard/GroupSummaryArea';
import GroupSubgroups from '@components/group/groupdashboard/GroupSubgroups';
import ActivateMerchantAccountWarning from '@components/group/ActivateMerchantAccountWarning';
import useGroupSurveyAccessControl from '@web/ui/components/group/useGroupSurveyAccessControl';
import useUserOptimizers from '@web/ui/components/optimizers/hooks/useUserOptimizers';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import {
    getTinyCalendarFormattedDates,
    getFormattedDateDisplayFromTimes,
} from '@core/utilities/constants/events';
import { config } from '@core/config/getConfig';
import { groupSellsProductsButCantAcceptPayments } from '@components/group/groupdashboard/groupDashboardHelpers';
import { userCanViewGroup } from '@helpers/events/event.helpers';

import { Group, Maybe, TimeAndPlaceSettings } from '@shared/welibrary-graphql/types';
import { ModalTypes } from '@core/types/Modals';

const localNamespace = 'imports.wlWeb.ui.components.group.groupDashboard';

const SUBGROUP_DISPLAY_LIMIT = 5;

type GroupDashboardHeaderProps = {
    group: Group;
    dashboardGroup: Group;
    scrollToWhatsNew: () => void;
    handleGroupAnalytics: (open: boolean) => void;
    handleOpenUpdateGroup: (settingsOpen: boolean) => void;
    handleAnnouncementPostBox: () => void;
    handleMobilizationForm: (formType: string) => void;
    inModal: boolean;
    newsfeedHeaderRef: any;
    locked?: boolean; // Prevent user from interacting with the header
};

const GroupDashboardHeader: React.FC<GroupDashboardHeaderProps> = ({
    group,
    dashboardGroup,
    scrollToWhatsNew,
    handleGroupAnalytics,
    handleOpenUpdateGroup,
    handleAnnouncementPostBox,
    handleMobilizationForm,
    inModal,
    newsfeedHeaderRef,
    locked,
}) => {
    const { surveyCompletionPercentage, isSurveyIncomplete, openSurveyModal } =
        useGroupSurveyAccessControl({
            group,
        });
    const { t } = useTranslation();
    const { newModal: centerModal } = useModal({ mobile: ModalTypes.Center });
    const { currentUser } = useCurrentUser();
    const history = useHistory();
    const { profile, loading: optimizerLoading } = useUserOptimizers(currentUser?._id);
    const hideGroupInfo = config?.public?.hideGroupHeaderInfoFromLoggedOutUsers && !currentUser;

    const {
        profile: { full_name, picture, coverPhoto, short_bio },
        settings: { enableGroupNewsfeed },
        currentUserIsAdmin: isUserAdminOfGroup,
    } = group;
    const isEventGroup: boolean = group?.subtype === 'event';
    const currentUserFreshCount: Maybe<number> | undefined = group?.currentUserFreshCount;
    const canUserViewGroup = userCanViewGroup(group);

    let groupDescription;
    if (short_bio) {
        groupDescription = <GroupSummaryArea text={short_bio} link="" />;
    }

    if (isEventGroup && group?.eventOverviewDetails?.description) {
        groupDescription = (
            <GroupSummaryArea text={group?.eventOverviewDetails?.description} link="" />
        );
    }

    const [groupThumbSrc, groupThumbSrcset] = getBackgroundImageSetForUrl(
        picture || getRandomDefaultCoverImage(full_name)
    );

    let eventGroupTimeDisplayTxt: string | null = '';

    const eventOverviewDetails: Maybe<TimeAndPlaceSettings> | undefined =
        group?.eventOverviewDetails;

    if (eventOverviewDetails) {
        eventGroupTimeDisplayTxt = getFormattedDateDisplayFromTimes(eventOverviewDetails);
    }

    const { topDateDisplay, bottomDateDisplay, isMultipleDates } =
        getTinyCalendarFormattedDates(eventOverviewDetails);

    const calendarClass = isMultipleDates
        ? 'event-dashboard-image-calendar wide'
        : 'event-dashboard-image-calendar';

    const headerRef = useRef();
    const scrollToTop = () => headerRef?.current?.scrollIntoView({ behavior: 'smooth' });

    const handleShowSubgroups = () => {
        if (!hideGroupInfo) centerModal(<GroupSubgroups group={group} />);
    };

    const areChildGroups =
        (group?.childGroups?.results?.length ?? 0) > 0 &&
        ((group?._id !== config.public.rootGroupId &&
            !config?.public?.homeGroups?.groupIds?.includes(group?._id)) ||
            isUserAdminOfGroup);
    // don't show child groups for the home groups/root group (for non-admins)

    const childGroupSample =
        areChildGroups &&
        group?.childGroups?.results?.slice(0, SUBGROUP_DISPLAY_LIMIT)?.map((childGroup, index) => {
            if (!childGroup) return <></>;

            const [src, srcSet] = getBackgroundImageSetForUrl(
                childGroup?.profile?.picture || getRandomDefaultCoverImage(full_name)
            );

            return (
                <StyledBackgroundImage
                    onClick={() => history?.push(childGroup?._id)}
                    key={`pledger-${childGroup?._id}`}
                    className={`pledge-card-participant-thumb ${index === dashboardGroup?.childGroups?.length - 1 ? 'expanded' : ''
                        } w-inline-block`}
                    src={src}
                    srcSet={srcSet}
                />
            );
        });

    if (childGroupSample && group?.childGroups?.results?.length <= SUBGROUP_DISPLAY_LIMIT) {
        childGroupSample.push(
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
                type="button"
                onClick={handleShowSubgroups}
                className="button-reset pledge-card-participant-thumb w-inline-block view-all-subgroup-btn dropdown-white-dots"
                key="view-all-subgroups-dots"
            />
        );
    }

    if (childGroupSample && group?.childGroups?.results?.length > SUBGROUP_DISPLAY_LIMIT) {
        const additionalGroupCount = group?.totalChildGroups - SUBGROUP_DISPLAY_LIMIT;
        childGroupSample.push(
            <button
                type="button"
                onClick={handleShowSubgroups}
                className="button-reset pledge-card-participant-thumb w-inline-block view-all-subgroup-btn subgroup-count "
                key="view-all-subgroups-count"
            >
                <span>+{additionalGroupCount}</span>
            </button>
        );
    }

    const subGroupTxt = group?.childGroups?.results?.length === 1 ? 'Subgroup' : 'Subgroups';

    const subGroupText = group?.totalChildGroups ? `${group?.totalChildGroups} ${subGroupTxt}` : '';

    const hideParentGroupDisplay =
        (config.public?.hidePlatformGroupHeaderDisplay &&
            group?.parentGroup?._id === config?.public?.homeButton?.groupId) ||
        config?.public?.homeGroups?.groupIds?.includes(group?._id);

    const parentGroupDisplay =
        group?.parentGroup && !hideParentGroupDisplay && !hideGroupInfo ? (
            <span className="parent-group-link">
                • In
                <Link to={`/g/${group?.parentGroup?._id}`}>
                    {' '}
                    {group?.parentGroup?.profile?.full_name}{' '}
                </Link>
            </span>
        ) : (
            ''
        );

    const groupTxt =
        group?.subtype === 'event'
            ? t(`common:global.nouns.event`, 'Event')
            : t(`common:global.nouns.group`, 'Group');

    let groupVisibilityClass = group?.settings?.protectionLevel
        ? `${group?.settings?.protectionLevel}-visibility-white`
        : '';
    let groupContextTxt = group?.settings?.protectionLevel
        ? `${group?.settings?.protectionLevel} ${groupTxt}`
        : '';

    const groupIsntReadyForPayments = groupSellsProductsButCantAcceptPayments(group);

    if (groupIsntReadyForPayments) {
        groupVisibilityClass = 'hidden-visibility-white';
        groupContextTxt = `${t(`common:${localNamespace}.draft`, 'Draft')} ${groupTxt}`;
    } else if (group?.isUnpublished) {
        groupVisibilityClass = 'hidden-visibility-white';
        groupContextTxt = `${t(`common:${localNamespace}.unpublished`, 'Unpublished')} ${groupTxt}`;
    }

    const surveyProgressIndicator = (
        <>
            <span>•</span>
            <span onClick={openSurveyModal} className="underline cursor-pointer">
                Survey {surveyCompletionPercentage}% Complete
            </span>
        </>
    );

    return (
        <ErrorBoundary>
            <div className="group-dashboard-header-wrapper" ref={headerRef}>
                <div className="group-dashboard-header-bg">
                    <Picture
                        url={coverPhoto || getRandomDefaultCoverImage(full_name)}
                        resolutions={[400, 600, 1200, 1800]}
                        disableLink
                    />
                    <div className="group-dashboard-header-overlay" />
                </div>
                <div className="group-dashboard-header-row">
                    <div className="group-dashboard-header-content">
                        <div className="group-dashboard-image-container">
                            {isEventGroup && (
                                <div className={`${calendarClass}`}>
                                    <div className="event-dashboard-image-calendar-top">
                                        {topDateDisplay}
                                    </div>
                                    <div className="event-dashboard-image-calendar-bottom">
                                        {bottomDateDisplay}
                                    </div>
                                </div>
                            )}
                            <Picture
                                className="group-dashboard-thumb"
                                url={groupThumbSrc}
                                resolutions={[400, 600, 1000, 1600]}
                                disableLink
                            />
                        </div>

                        <div className="group-header-right">
                            <ActivateMerchantAccountWarning group={group} />
                            <div className="header-title-desc pb-[10px]">
                                {enableGroupNewsfeed !== false && currentUserFreshCount > 0 && (
                                    <span
                                        className="group-select-link-new-content"
                                        onClick={scrollToWhatsNew}
                                    >
                                        {currentUserFreshCount} {t('common:global.new_items')}
                                    </span>
                                )}
                                {isEventGroup && (
                                    <div className="group-header-event-time">
                                        {eventGroupTimeDisplayTxt}
                                    </div>
                                )}
                                <div className="conent-card-media-title">{full_name}</div>
                                {!isEventGroup && groupDescription}
                                <div className="flex gap-2.5 items-center">
                                    {(!isEventGroup || group?.isUnpublished) && (
                                        <div
                                            className={`group-dashboard-visibility-level visibility-button  ${groupVisibilityClass}`}
                                        >
                                            {groupContextTxt}{' '}
                                            {!group?.isUnpublished && parentGroupDisplay}
                                        </div>
                                    )}
                                    {isSurveyIncomplete && !optimizerLoading && profile && surveyProgressIndicator}
                                </div>
                            </div>
                            {!locked && (
                                <GroupDashboardMenu
                                    group={group}
                                    dashboardGroup={dashboardGroup}
                                    handleGroupAnalytics={handleGroupAnalytics}
                                    handleOpenUpdateGroup={handleOpenUpdateGroup}
                                    handleAnnouncementPostBox={handleAnnouncementPostBox}
                                    handleMobilizationForm={handleMobilizationForm}
                                    canUserViewGroup={canUserViewGroup}
                                    inModal={inModal}
                                    isEventGroup={isEventGroup}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="group-dashboard-header-row subgroup-display">
                    {!isEventGroup && areChildGroups && !hideGroupInfo && (
                        <div className="group-dashboard-header-content subgroup-row">
                            <button
                                type="button"
                                className="button-reset show-subgroups"
                                onClick={handleShowSubgroups}
                            >
                                {subGroupText}
                            </button>
                            <div className="child-groups">{childGroupSample}</div>
                        </div>
                    )}
                </div>
            </div>

            <GroupDashboardContextHeader
                group={group}
                fullHeaderRef={headerRef}
                newsfeedHeaderRef={newsfeedHeaderRef}
                isEventGroup={isEventGroup}
                canUserViewGroup={canUserViewGroup}
                scrollToTop={scrollToTop}
            />
        </ErrorBoundary>
    );
};

export default GroupDashboardHeader;