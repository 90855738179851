import { TFunction } from 'i18next';
import { BASIC_CARDS, CARD_TEXT } from '@core/utilities/constants/card_types.js';

import { Maybify } from '@core/types/Utilities';
import { ContentCard } from '@shared/welibrary-graphql/types';

export const getResultTypeFromContentCard = (card: Maybify<ContentCard>, t: TFunction) => {
    if (!card.type || card.type === CARD_TEXT) return t('common:global.nouns.post');

    if (BASIC_CARDS.includes(card.type)) {
        return `${t(`common:global.constants.cards.types.${card.type}`)} ${t(
            'common:global.nouns.post'
        )}`;
    }

    return t(`common:global.constants.cards.types.${card.type}`);
};

export default getResultTypeFromContentCard;
