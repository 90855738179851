import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';

import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/success-checkmark.json';
import CopyableText from '@dsc/display/CopyableText';

import { getRootUrl } from '@core/utilities/whitelabel_helpers';

import { ContentCard, Maybe } from '@shared/welibrary-graphql/types';

type EventListingFinishStepProps = {
    title: string;
    card: Maybe<ContentCard>;
};

const EventListingFinishStep: React.FC<EventListingFinishStepProps> = ({ title, card }) => {
    const { t } = useTranslation();

    const { closeModal } = useModal();

    return (
        <section className="event-group-finish-step">
            <header>
                <section className="header-info-block">
                    <section className="header-title">
                        <h1>{title}</h1>
                    </section>
                    <button className="cancel-btn" type="button" onClick={closeModal}>
                        {t('common:cancel', 'Cancel')}
                    </button>
                </section>
            </header>

            <section className="success-message event-group">
                <LottieLoading
                    height={110}
                    width={110}
                    lottieData={LottieAnimation}
                    customStyle={{ opacity: 1 }}
                    isPaused
                    loop={false}
                />

                <h1>{t('common:success', 'Success!')}</h1>
                <p>{t('common:your_event_has_been_published', 'Your event has been published.')}</p>

                <CopyableText text={`https://${getRootUrl()}/c/${card?.url}`} />
            </section>

            <footer>
                <button type="button" onClick={closeModal}>
                    {t('common:close', 'Close')}
                </button>
                <Link onClick={closeModal} to={`/c/${card?.url}`}>
                    {t('common:view_event', 'View Event')}
                </Link>
            </footer>
        </section>
    );
};

export default EventListingFinishStep;
