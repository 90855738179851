import React from 'react';

type CameraProps = {
    className?: string;
};

const Camera: React.FC<CameraProps> = ({ className = '' }) => {
    return (
        <svg viewBox="0 0 42 39" fill="none" className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.3332 7.49251C32.4165 7.63818 32.5623 7.74223 32.7498 7.74223C37.7498 7.74223 41.8332 11.821 41.8332 16.8155V29.1767C41.8332 34.1712 37.7498 38.25 32.7498 38.25H9.24984C4.229 38.25 0.166504 34.1712 0.166504 29.1767V16.8155C0.166504 11.821 4.229 7.74223 9.24984 7.74223C9.4165 7.74223 9.58317 7.65899 9.64567 7.49251L9.77067 7.24279C9.84248 7.09164 9.91619 6.93639 9.99101 6.77879C10.524 5.65617 11.1136 4.41418 11.479 3.68424C12.4373 1.81132 14.0623 0.77081 16.0832 0.75H25.8957C27.9165 0.77081 29.5623 1.81132 30.5207 3.68424C30.8488 4.33976 31.3487 5.39569 31.8305 6.41341C31.93 6.62341 32.0286 6.83179 32.1248 7.03468L32.3332 7.49251ZM30.8123 15.4836C30.8123 16.5241 31.6457 17.3566 32.6873 17.3566C33.729 17.3566 34.5832 16.5241 34.5832 15.4836C34.5832 14.4431 33.729 13.5899 32.6873 13.5899C31.6457 13.5899 30.8123 14.4431 30.8123 15.4836ZM17.3957 18.7092C18.3748 17.7311 19.6457 17.2109 20.9998 17.2109C22.354 17.2109 23.6248 17.7311 24.5832 18.6884C25.5415 19.6457 26.0623 20.9151 26.0623 22.2678C26.0415 25.0563 23.7915 27.3246 20.9998 27.3246C19.6457 27.3246 18.3748 26.8044 17.4165 25.8471C16.4582 24.8898 15.9373 23.6204 15.9373 22.2678V22.2469C15.9165 20.9359 16.4373 19.6665 17.3957 18.7092ZM26.7707 28.053C25.2915 29.5305 23.2498 30.4462 20.9998 30.4462C18.8123 30.4462 16.7707 29.593 15.2082 28.053C13.6665 26.4922 12.8123 24.4528 12.8123 22.2678C12.7915 20.1035 13.6457 18.0641 15.1873 16.5033C16.7498 14.9426 18.8123 14.0893 20.9998 14.0893C23.1873 14.0893 25.2498 14.9426 26.7915 16.4825C28.3332 18.0433 29.1873 20.1035 29.1873 22.2678C29.1665 24.5361 28.2498 26.5755 26.7707 28.053Z"
                fill="currentcolor"
            />
        </svg>
    );
};

export default Camera;
