import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import _ from 'lodash';

import GroupDashboardNewsfeed from '@web/ui/components/group/groupdashboard/GroupDashboardNewsfeed';
import Newsfeed from '@web/ui/components/newsfeed/Newsfeed';
import TileItem from '@web/ui/components/card/mediacard/bodyitems/TileItem';
import LoadingIndicator from '@web/ui/components/generic/loading/LoadingIndicator';
import EditDashboardHeader from '@web/ui/components/group/groupdashboard/EditDashboardHeader';
import NewPostOverlay from '@web/ui/components/content/newpost/NewPostOverlay';
import Modal from '@web/ui/components/generic/lightbox/Modal.js';
import LockedGroup from '@web/ui/components/group/LockedGroup';
import GroupSubscriptionInfoContainer from '@components/group/groupdashboard/groupSubscriptionPaywall/GroupSubscriptionInfoContainer';
import UpdateCard from '@components/card/UpdateCard';

import useGroupPublicDashfeed from '@components/group/groupdashboard/hooks//useGroupPublicDashfeed';
import useGroupMembersDashfeed from '@components/group/groupdashboard/hooks/useGroupMembersDashfeed';

import { UPDATE_CARD } from '@shared/welibrary-graphql/content_card/mutations';
import { UPDATE_GET_GROUP_DASHBOARD_NEWSFEED } from '@core/utilities/constants/update_cache';
import { CARD_SUBTYPES } from '@core/utilities/constants/card_types';
import { VIEW_PERMISSION_PRIVATE, VIEW_PERMISSION_SECRET } from '@core/utilities/constants/roles';

const GroupDashboardNewsFeedContainer = ({
    group,
    sortedToc,
    whatsNewRef,
    newsfeedHeaderRef,
    dashfeedType,
    setDashfeedType,
}) => {
    const { t } = useTranslation();
    const updatecard = useRef();

    const localNamespace =
        group?.subtype === 'event'
            ? 'imports.wlWeb.ui.components.group.eventDashboard'
            : 'imports.wlWeb.ui.components.group.groupDashboard';

    const {
        shelf,
        currentUserIsMember: isUserMemberOfGroup,
        currentUserIsAdmin: isUserAdminOfGroup,
        currentUserIsDirectAdmin: isUserDirectAdminOfGroup,
        settings: { protectionLevel, enableGroupNewsfeed },
    } = group;

    let _enableGroupNewsfeed = enableGroupNewsfeed;

    /* The create group mutation resolver if the initial value is false will set
    this field to null, for normal groups, null means newsfeed is on by default,
    for event groups this should be false */
    if (group?.subtype === 'event' && enableGroupNewsfeed === null) {
        _enableGroupNewsfeed = false;
    }

    const {
        dashboardSource,
        dashfeedData,
        loading: groupDashboardLoading,
    } = dashfeedType === CARD_SUBTYPES.DASHBOARD
            ? useGroupMembersDashfeed(group)
            : useGroupPublicDashfeed(group);

    const [newsfeedLoaded, setNewsfeedLoaded] = useState([]);
    const [showPlaceholder, setShowPlaceholder] = useState(false);
    const [deleteMode] = useState(false);

    const [updateCard] = useMutation(UPDATE_CARD);

    const postAllowedTypes = dashboardSource?.library_settings?.postTypes;
    const postFeedUrl = dashboardSource?.url;
    const postDisplaySocialHeaders = dashboardSource?.library_settings?.displaySocialHeaders;

    const groupShelfId = group?.shelf?._id;

    const subscriptionsWhitelabelVerbage =
        group.whitelabelVerbage &&
        _.find(group.whitelabelVerbage, { _id: `common:${localNamespace}.subscriptions` });

    const userIsInGroup = isUserMemberOfGroup || isUserAdminOfGroup || isUserDirectAdminOfGroup;
    const showLockedScreen =
        (protectionLevel === VIEW_PERMISSION_SECRET ||
            protectionLevel === VIEW_PERMISSION_PRIVATE) &&
        !dashfeedData?.results &&
        !userIsInGroup;

    const tocWrapper = {
        aliases: sortedToc
            ? sortedToc.map(item => {
                return { item };
            })
            : [],
    };

    const groupSubsExist = sortedToc?.length > 0;

    const [openModal, setOpenModal] = useState(false);
    const [openSettingsModal, setOpenSettingsModal] = useState(false);

    const handleOpenModal = modalType => {
        if (isUserAdminOfGroup && isUserDirectAdminOfGroup) {
            if (modalType === 'postbox') {
                setOpenModal(true);
            } else if (modalType === 'settings') {
                setOpenSettingsModal(true);
            }
        }
    };

    const handleCloseModal = modalType => {
        if (isUserAdminOfGroup && isUserDirectAdminOfGroup) {
            if (modalType === 'postbox') {
                setOpenModal(false);
            } else if (modalType === 'settings') {
                setOpenSettingsModal(false);
            }
        }
    };

    const newPostOptions = {
        updateCacheOptions: {
            groupId: group?._id,
            subtype: dashfeedType,
        },
        updateCache: UPDATE_GET_GROUP_DASHBOARD_NEWSFEED,
    };
    const newPostProps = {
        allowedTypes: postAllowedTypes,
        feedUrl: postFeedUrl,
        group,
        chapter: dashboardSource,
        displaySocialHeaders: postDisplaySocialHeaders,
        showConfirmation: true,
        chapterShowTitle: dashboardSource?.showTitle,
        closeModal: () => handleCloseModal('postbox'),
        newPostOptions,
        prompt: dashboardSource?.prompt,
    };

    // This function is passed to another component as a callback
    const handleNewsfeedLoaded = data => {
        if (data?.length > 0) {
            setNewsfeedLoaded(data);
        }
    };

    const placeHolderMsg =
        isUserAdminOfGroup && isUserDirectAdminOfGroup
            ? t(`common:${localNamespace}.welcome_add_content`)
            : t(`common:${localNamespace}.no_content`);

    const placeholderClass =
        isUserAdminOfGroup && isUserDirectAdminOfGroup
            ? 'empty-state-message-container user-clickable'
            : 'empty-state-message-container';

    /* Inefficient way of determining whether to show placeholder or not
        Was trying without restructuring too much to account for child component query loading
        when displaying placeholder ....
    */
    useEffect(() => {
        if (dashfeedData?.results?.length > 0) {
            setShowPlaceholder(false);
        } else {
            setShowPlaceholder(true);
        }

        return () => setShowPlaceholder(false);
    }, [dashfeedData, newsfeedLoaded]);

    const displayDashboardHeader =
        shelf && isUserAdminOfGroup && isUserDirectAdminOfGroup && dashboardSource;

    if (showLockedScreen) {
        return (
            <section className="empty-group-dashboard-container fadein">
                <GroupSubscriptionInfoContainer group={group} />
                <LockedGroup />
            </section>
        );
    }

    return (
        <div className="overlay-module-container group-dashboard">
            <div className="left-col">
                {!userIsInGroup && group && <GroupSubscriptionInfoContainer group={group} />}
                {shelf && isUserAdminOfGroup && isUserDirectAdminOfGroup && dashboardSource && (
                    <EditDashboardHeader
                        group={group}
                        feedType={dashfeedType}
                        handleDashfeedSwitch={setDashfeedType}
                        handleOpenModal={handleOpenModal}
                    />
                )}
                {groupDashboardLoading && !dashfeedData ? (
                    <LoadingIndicator type="skeleton" size="normal" />
                ) : (
                    <section
                        className={`group-dashfeed ${dashfeedData?.results && !displayDashboardHeader ? 'content' : 'empty'
                            }`}
                    >
                        {' '}
                        <Newsfeed
                            list={dashfeedData ? dashfeedData.results : []}
                            superLayer
                            groupShelfId={groupShelfId}
                            postsArePinnable
                        />{' '}
                    </section>
                )}
                {showPlaceholder && (
                    <section className="empty-group-dashboard-container fadein">
                        <div className="add-content-placeholder" />
                        <div
                            onClick={() => handleOpenModal('postbox')}
                            style={{ marginTop: '50px', textAlign: 'center' }}
                            className={placeholderClass}
                        >
                            {placeHolderMsg}
                        </div>
                    </section>
                )}
                {groupSubsExist && (
                    <div className="dashboard-section-title-container">
                        <Link to={shelf && isUserAdminOfGroup ? `/s/${shelf.url}` : '#'}>
                            <div>
                                {subscriptionsWhitelabelVerbage?.plainText ||
                                    t(`common:${localNamespace}.subscriptions`)}
                            </div>
                        </Link>
                    </div>
                )}
                <TileItem card={tocWrapper} />
                {_enableGroupNewsfeed !== false && (
                    <GroupDashboardNewsfeed
                        onLoad={handleNewsfeedLoaded}
                        groupId={group._id}
                        whatsNewRef={whatsNewRef}
                        newsfeedHeaderRef={newsfeedHeaderRef}
                    />
                )}

                <Modal
                    className="modal"
                    delayTime={500}
                    isMounted={openModal}
                    close={() => handleCloseModal('postbox')}
                    contentCard
                >
                    <div className="new-post-overlay-wrapper">
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <NewPostOverlay {...newPostProps} />
                    </div>
                </Modal>

                <Modal
                    smallModal={deleteMode}
                    delayTime={500}
                    className="modal"
                    cardEdit
                    isMounted={openSettingsModal}
                    close={() => handleCloseModal('settings')}
                    darkMode
                    title={dashboardSource ? dashboardSource.title : ''}
                >
                    <UpdateCard
                        deleteMode={deleteMode}
                        ref={updatecard}
                        card={dashboardSource}
                        handleModalClose={() => handleCloseModal('settings')}
                        updateCard={updateCard}
                        noTabs
                    />
                </Modal>
            </div>
        </div>
    );
};

export default GroupDashboardNewsFeedContainer;
