import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '@components/modals/hooks/useModal';
import useSearchJob from '../../../search/hooks/useSearch';
import searchStore from '@stores/Search';
import shoppingCartStore from '@stores/ShoppingCart';

import SearchLoader from '@components/search/SearchLoader';
import SearchInput from '@components/search/SearchInput';

import { getRandomDefaultUserImage } from '@core/utilities/constants/defaults';
import { FILTER_TYPES } from '../../../search/constants';

const SellTicketsAccountSearch: React.FC = () => {
    const { t } = useTranslation();

    const { closeModal } = useModal();

    const searchString = searchStore.useTracked.query();
    const { searchResults, loading, hasMoreResults, fetchMore } = useSearchJob();

    useEffect(() => {
        searchStore.set.filter([FILTER_TYPES.USER]);
    }, []);

    const areResults = searchResults?.results?.length > 0;

    return (
        <div className="flex flex-col gap-[20px] px-[30px] py-[40px]">
            <h1 className="m-0">
                {t('common:global.search_for_an_account', 'Search for an Account')}
            </h1>

            <SearchInput
                value={searchString}
                onChange={value => searchStore.set.query(value)}
                className="check-in-search-field mb-[20px] text-[16px]"
                placeholder={t(`common:global.search`, 'Search')}
            />

            <SearchLoader
                customStyle={{ position: 'relative', marginBottom: '20px' }}
                emptyResults={searchResults?.results?.length === 0}
                size={180}
                emptyState={!searchString}
                areResults={areResults}
                loading={loading}
            />

            {areResults && (
                <div className="flex flex-col gap-[20px]">
                    {searchResults?.results?.map((searchResult, index) => {
                        const { full_name, phone, email, picture } = searchResult.entity.profile;
                        return (
                            <div
                                key={index}
                                role="button"
                                onClick={() => {
                                    shoppingCartStore.set.existingAccount(searchResult.entity);
                                    closeModal();
                                    searchStore.set.query('');
                                }}
                                className="shadow-around flex gap-[10px] rounded-[16px] p-[10px]"
                            >
                                <img
                                    src={picture || getRandomDefaultUserImage()}
                                    className="h-[70px] w-[70px] rounded-full"
                                />
                                <div className="flex flex-col gap-[10px]">
                                    <span className="text-grayscale-title-active text-[17px] font-[700] leading-[22px] tracking-[0.75px]">
                                        {full_name}
                                    </span>
                                    <div className="text-grayscale-body flex flex-col text-[12px] font-[500]">
                                        <span>{phone}</span>
                                        <span>{email}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default SellTicketsAccountSearch;
