import React from 'react';
import _ from 'lodash';

import searchStore from '@stores/Search';

import { DEFAULT_FILTERS, FILTER_TYPES, getFilterType } from '@components/search/constants';
import SearchCardContainer from '@components/search/searchcard/SearchCardContainer';
import GroupedCardContainer from '@components/search/GroupedCardContainer';
import SearchLoader from '@components/search/SearchLoader';
import { PaginatedSearchResults } from '@shared/welibrary-graphql/content_card/queries.hook';
// const localNamespace = 'imports.wlWeb.ui.components.search.SearchOverlay';

type SearchResultsProps = {
    /** Closes search overlay */
    handleCloseSearch: () => void;
    searchResults?: PaginatedSearchResults;
    loading: boolean;
};

/** Component displaying multiple types of search filter types in one view */
const SearchResults: React.FC<SearchResultsProps> = ({
    handleCloseSearch,
    searchResults,
    loading,
}) => {
    const { query: searchQuery, filter: searchFilter } = searchStore.useStore();

    const results = searchResults?.results;

    const renderSearchCards = results?.map((searchItem, index) => {
        return (
            <SearchCardContainer
                handleCloseSearch={handleCloseSearch}
                currentTab={searchFilter}
                source={searchItem}
                key={index}
            />
        );
    });

    const emptyResults = !loading && results && _.isEmpty(results);
    const emptyState = searchQuery === undefined || searchQuery === '';
    const areResults = results && results?.length > 0;
    const isAllTab = DEFAULT_FILTERS.every(filter => searchFilter.includes(filter));
    // If there is are results and  no current tab is set,
    // set to default grouped card view
    const renderView =
        areResults && isAllTab ? (
            <GroupedCardContainer handleCloseSearch={handleCloseSearch} searchResults={results} />
        ) : (
            renderSearchCards
        );

    return (
        <div>
            <SearchLoader
                emptyResults={emptyResults}
                emptyState={emptyState}
                areResults={areResults as boolean}
                loading={loading}
            />
            <div className="search-results">{renderView}</div>
        </div>
    );
};

export default SearchResults;
