import React, { CSSProperties, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useScreenWidth from '@components/generic/hooks/useScreenWidth';
import { useCurrentUser } from '@stores/User';
import useModal from '@components/modals/hooks/useModal';
import useCompleteQuestNode from '@components/quest/hooks/useCompleteQuestNode';

import CreationContainer from '@components/lego/creation/creationpage/CreationContainer';
import Picture from '@components/generic/Picture';
import QuestNodePreview from '@components/quest/QuestNodePreview';
import QuestCreationDisplay from '@components/quest/QuestCreationDisplay';

import { CARD_CREATION, CARD_STORY } from '@core/utilities/constants/card_types';
import { getInfoFromNode, nodeIsLink, getNodeUrl } from '@helpers/rainbowRoad/rainbowRoadHelpers';

import { Maybify } from '@core/types/Utilities';
import { ModalTypes } from '@core/types/Modals';
import {
    RainbowRoadNode as RainbowRoadNodeType,
    RainbowRoadNodesSettings,
    RrCircleSize,
    RrLineStyle,
    CreationState,
} from '@shared/welibrary-graphql/types';

type QuestNodeProps = {
    node?: Maybify<RainbowRoadNodeType> | null;
    settings?: RainbowRoadNodesSettings | null;
    creationsState?: Array<Maybify<CreationState> | null> | null;
    questId: string;
    questMapId?: string;
};

const QuestNode: React.FC<QuestNodeProps> = ({
    node,
    settings,
    creationsState,
    questId = '',
    questMapId = '',
}) => {
    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();

      const { currentUser } = useCurrentUser();
    const { newModal, closeModal } = useModal();

    const { completeNode } = useCompleteQuestNode(questId, questMapId);

    const info = getInfoFromNode(node ?? {}, t, currentUser);

    if (!node || !info) return <></>;

    const { customPicture, spinnerImage } = info;

    const {
        circleSize = RrCircleSize.Medium,
        lineStyle = RrLineStyle.Solid,
        lineColor = 'var(--color-grayscale-body)',
        outline,
        winding,
    } = settings || {};

    // This is used to prevent layout thrashing going from course -> quest nodes view

    const innerWidth = window?.innerWidth > 600 ? 600 : window?.innerWidth;
    let minHeight = innerWidth * 0.6;

    const windowWidth = useScreenWidth();
    const width = Math.min(windowWidth, 600);
    minHeight = width * 0.6;

    const cssVars = {
        '--rr-node-line-color': lineColor,
        minHeight: `${minHeight}px`,
    } as CSSProperties;
    const buttonClass = `button-reset node-button ${
        node.alias && 'item' in node.alias ? node.alias.item?.type : ''
    }`;

    return (
        <section
            className={`rainbow-road-node ${circleSize}-circle ${lineStyle}-line ${
                outline ? '' : 'no-'
            }outline ${winding ? 'winding' : ''}`}
            style={cssVars}
        >
            <button
                className={buttonClass}
                type="button"
                onClick={() => {
                    if (nodeIsLink(node)) {
                        if (
                            node.alias?.__typename === 'AliasItem' &&
                            node.alias?.item?.type === CARD_CREATION &&
                            node.alias?.item.url
                        ) {
                            newModal(
                                <CreationContainer
                                    cardUrl={node.alias.item.url}
                                    questId={questId}
                                    returnUrl={location.pathname}
                                    goBack={closeModal}
                                />,
                                { className: 'full-height' },
                                {
                                    mobile: ModalTypes.FullScreen,
                                    desktop: ModalTypes.FullScreen,
                                }
                            );
                        } else {
                            history.push(getNodeUrl(node, location.pathname, questId, questMapId));
                            closeModal();
                        }
                    } else {
                        newModal(
                            <QuestNodePreview
                                node={node}
                                completeNode={() => {
                                    completeNode(node.alias?.url ?? '');
                                    closeModal();
                                }}
                            />,
                            {
                                className:
                                    'no-top-padding no-bottom-padding white-notch low-z full-height',
                                onClose:
                                    node.alias?.__typename === 'AliasItem' &&
                                    node.alias?.item?.type === CARD_STORY
                                        ? () => completeNode(node.alias?.url ?? '')
                                        : undefined,
                            }
                        );
                    }
                }}
            >
                <Picture url={customPicture} disableLink resolutions={[160, 320, 640]} />
                {spinnerImage && (
                    <Picture
                        url={spinnerImage}
                        disableLink
                        resolutions={[160, 320, 640]}
                        className="spinner"
                    />
                )}
            </button>

            {node.showMetaInformation && (
                <QuestCreationDisplay node={node} creationsState={creationsState} />
            )}

            {node.backgroundImage ? (
                <Picture url={node.backgroundImage} disableLink className="bg" />
            ) : (
                <div role="presentation" className="bg-spacer" />
            )}
        </section>
    );
};

export default QuestNode;
