import { TFunction } from 'react-i18next';
import { capitalize } from '@helpers/string.helpers';

/**
 * Generates a displayValues object where each option has a display value with its first letter
 * capitalized
 */
export const capitalizedDisplayValues = <T extends string>(options: T[]): Record<T, string> => {
    return options.reduce<Record<T, string>>((acc, option) => {
        acc[option] = capitalize(option);
        return acc;
    }, {} as Record<T, string>);
};

/**
 * Generates a displayValues object where each option has a display value of
 * t(`common:${localNamespace}.${option}`)
 */
export const translatedDisplayValues = <T extends string>(
    options: T[],
    t: TFunction,
    localNamespace: string
): Record<T, string> => {
    return options.reduce<Record<T, string>>((acc, option) => {
        acc[option] = t(`common:${localNamespace}.${option}`);
        return acc;
    }, {} as Record<T, string>);
};

/**
 * Given an object of the form { [value]: fallback } (where value and fallback are strings),
 * generates a displayValues object where each option has a display value of
 * t(`common:${value}`, fallback)
 *
 * In tandem with locize, this will effectively generate all necessary translations
 */
export const autoTranslatedDisplayValues = <T extends string>(
    values: Record<T, string>,
    t: TFunction
): Record<T, string> => {
    return Object.keys(values).reduce<Record<T, string>>((acc, value) => {
        acc[value as T] = t(`common:${value}`, values[value as T]);
        return acc;
    }, {} as Record<T, string>);
};
