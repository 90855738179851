/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import ImageGrid from '../imagecard/ImageGrid';
import ImageSlider from '../imagecard/ImageSlider';

import {FilestackImage} from '@core/types/Utilities';
import {CARD_IMAGE_SQUARE} from '@core/utilities/constants/card_types.js';
import {AliasItem} from '@shared/welibrary-graphql/types';

const CreateSquareImageCard: React.FC<{
    state: any;
    handleSquarePhotoSelect: () => void;
    handleImgToggle: (img?: FilestackImage | AliasItem, imgUploads?: FilestackImage[]) => void; // toggles images selected on/off
}> = ({ state, handleSquarePhotoSelect, handleImgToggle }) => {
    const { data } = state.attachedContent;

    let images: FilestackImage[] = [];

    if (data?.length > 0) images = data;

    return (
        <div className="content-card-container">
            <div className="legacy-lightbox-container">
                {/* image slider */}
                {state.imagesSelected.length > 0 && (
                    <ImageSlider images={state.imagesSelected} disableExternalLink />
                )}
                {/* image grid */}
                {images.length >= 1 && (
                    <ImageGrid
                        rerender
                        images={images}
                        imagesSelected={state.imagesSelected}
                        handleImgToggle={handleImgToggle}
                        handleOpeningPicker={handleSquarePhotoSelect}
                        mode="create"
                        type={CARD_IMAGE_SQUARE}
                    />
                )}
            </div>
        </div>
    );
};

export default CreateSquareImageCard;
