import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { capitalize } from '@helpers/string.helpers';
import { GENERATE_SIGNATURE_IMAGE_URL } from '@shared/welibrary-graphql/signature/mutations';
import { GET_FONT_OPTIONS } from '@shared/welibrary-graphql/signature/queries';
import { CustomSignupFieldTypes } from '@shared/welibrary-graphql/types';
import { useTranslation } from 'react-i18next';

import SignatureModalContent from './SignatureModalContent';

interface SignatureModalProps {
    closeModal: () => void;
    handleCustomChange: (name: string, value: string, type: CustomSignupFieldTypes) => void;
    name: string;
}

const SignatureModal: React.FC<SignatureModalProps> = ({
    closeModal,
    handleCustomChange,
    name,
}) => {
    const { t } = useTranslation();
    const [generateSignatureImageUrl, { loading: mutationLoading, error: mutationError }] =
        useMutation(GENERATE_SIGNATURE_IMAGE_URL);
    const { data: queryData, loading: queryLoading } = useQuery(GET_FONT_OPTIONS);
    const isLoading = queryLoading || mutationLoading;
    const [font, setFont] = useState('');
    const [signature, setSignature] = useState('');
    const [warning, setWarning] = useState('');

    const onSave = async () => {
        if (!signature) {
            setWarning(capitalize(t('common:signature_is_required', 'signature is required')));
            return;
        }
        setWarning('');
        try {
            const response = await generateSignatureImageUrl({
                variables: { text: signature, font },
            });
            const imageUrl = response?.data?.generateSignatureImageUrl;
            handleCustomChange(name, imageUrl, CustomSignupFieldTypes.Signature);
            closeModal();
        } catch (error) {
            console.error({ error });
        }
    };

    const options = queryData?.getFontOptions?.map((fontOption: string) => (
        <option key={fontOption} value={fontOption}>
            {fontOption}
        </option>
    ));

    return (
        <SignatureModalContent
            font={font}
            setFont={setFont}
            signature={signature}
            setSignature={setSignature}
            options={options}
            onSave={onSave}
            isLoading={isLoading}
            error={mutationError}
            warning={warning}
        />
    );
};

export default SignatureModal;
