import React, { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';

import useConfirmation from '@components/generic/hooks/useConfirmation';

import TextareaAutosize from 'react-textarea-autosize';

import { ADD_ADMIN_ANNOUNCEMENT } from '@shared/welibrary-graphql/notifications/mutations';

const localNamespace =
    'imports.wlWeb.ui.components.generic.editScreen.sendAdminNotificationContainer';

const SendAdminNotificationContainer = ({ cardTitle, cardUrl }) => {
    const confirm = useConfirmation();

    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    const handleChange = e => {
        const name = e.currentTarget.value;
        setMessage(name);
    };

    return (
        <Mutation mutation={ADD_ADMIN_ANNOUNCEMENT}>
            {(addAdminAnnouncement, { data, loading, error }) => (
                <div>
                    <div className="mc-source-text-container">
                        <div className="textarea-autosize small w-form group-announcement-container">
                            <TextareaAutosize
                                autoComplete="off"
                                onChange={handleChange}
                                value={message}
                                className="group-announcement input"
                                maxLength={470}
                                style={{ resize: 'none', paddingTop: '7px', overflow: 'hidden' }}
                                placeholder={t(`common:${localNamespace}.text_area_placeholder`)}
                            />

                            {message.length > 2 && (
                                <button
                                    onClick={async () => {
                                        if (
                                            await confirm({
                                                text: t(
                                                    `common:${localNamespace}.send_message_prompt`,
                                                    {
                                                        title: cardTitle ? `of ${cardTitle}` : '',
                                                    }
                                                ),
                                            })
                                        ) {
                                            addAdminAnnouncement({
                                                variables: { cardUrl, message },
                                            });
                                            setMessage('');
                                        }
                                    }}
                                    label="Send Announcement"
                                    type="button"
                                    className="announcement-button"
                                />
                            )}
                        </div>
                    </div>

                    <section className="mc-source-text-container status-msgs">
                        {error && <div style={{ color: 'red' }}>{error.message}</div>}
                        {loading && <div>{t(`common:${localNamespace}.sending`)}</div>}
                        {data && (
                            <div style={{ color: '#3898EC' }}>
                                {t(`common:${localNamespace}.sent`)}
                            </div>
                        )}
                    </section>
                </div>
            )}
        </Mutation>
    );
};

export default SendAdminNotificationContainer;
