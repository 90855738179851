import React, { useEffect, useState } from 'react';

export type FadeProps = {
    show: boolean;
    callback?: () => void;
    children: React.ReactNode;
    className?: string;
    childrenProps?: any;
    alwaysRerender?: boolean;
};

/**
 * React.memo comparison function. This prevents unnecessary re-renders
 */
const FadeMemoComparator = (
    { show: prevShow, childrenProps: prevProps, alwaysRerender: prevAlwaysRerender }: FadeProps,
    { show: nextShow, childrenProps: nextProps, alwaysRerender: nextAlwaysRerender }: FadeProps
) => {
    if (prevAlwaysRerender || nextAlwaysRerender) return false;
    if (prevProps && nextProps) {
        return prevShow === nextShow && prevProps === nextProps;
    }
    return prevShow === nextShow;
};

const Fade = React.memo<FadeProps>(function Fade({
    show,
    callback,
    children,
    className,
    childrenProps,
}) {
    const [shouldRender, setRender] = useState(show);

    useEffect(() => {
        if (show) setRender(true);
    }, [show]);

    const onAnimationEnd = () => {
        if (!show) {
            callback?.();
            setRender(false);
        }
    };

    if (!shouldRender) return <></>;

    if (childrenProps) {
        return (
            shouldRender && (
                <div
                    style={{ animation: `${show ? 'fadeIn' : 'fadeOut'} 0.5s` }}
                    onAnimationEnd={onAnimationEnd}
                    className={className}
                >
                    {React.cloneElement(children, { ...childrenProps })}
                </div>
            )
        );
    }

    return (
        shouldRender && (
            <div
                style={{ animation: `${show ? 'fadeIn' : 'fadeOut'} 0.5s` }}
                onAnimationEnd={onAnimationEnd}
                className={className}
            >
                {children}
            </div>
        )
    );
},
FadeMemoComparator);

export default Fade;
