import {
    useSingleCardByUrlQuery,
    useSingleCardByIdQuery,
} from '@shared/welibrary-graphql/content_card/queries.hook';
import { useQuestByIdQuery } from '@shared/welibrary-graphql/quest/queries.hook';

const useCreation = (
    args: { url: string; questId?: string | null } | { id: string; questId?: string | null }
) => {
    const { questId } = args;
    // Thank you TypeScript.. *facepalm*
    const url = 'url' in args ? args.url : undefined;
    const id = 'id' in args ? args.id : undefined;

    const { data: urlCard } = useSingleCardByUrlQuery({
        variables: { url: url ?? '' },
        skip: !url,
    });

    const { data: idCard, loading: singleCardLoading } = useSingleCardByIdQuery({
        variables: { _id: id ?? '' },
        skip: !id,
    });

    const { data: quest, loading: questIdLoading } = useQuestByIdQuery({
        variables: { _id: questId ?? '' },
        skip: !questId,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const card = urlCard?.cardByUrl || idCard?.card;

    const creation = quest?.getQuestById?.creationsState?.find(
        creationState => creationState?.creation?._id === card?._id
    );

    return {
        card,
        quest: quest?.getQuestById,
        creation,
        loading: singleCardLoading || questIdLoading,
    };
};

export default useCreation;
