import React from 'react';

import TextField from '@material-ui/core/TextField';
import { IconButton, InputAdornment } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import EyeSlash from '@dsc/svgs/EyeSlash';
import Eye from '@dsc/svgs/Eye';

import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-spinner.json';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';

type TextInputProps = {
    autoComplete?: string;
    autoFocus?: boolean;
    className?: string;
    defaultValue?: string;
    error?: boolean;
    classes?: any;
    disabled?: boolean;
    id?: string;
    label: string;
    type?: string;
    name?: string;
    helperText?: string;
    multiline?: boolean;
    required?: boolean;
    rows?: string | number;
    rowsMax?: string | number;
    value: string;
    onChange: (
        value: string,
        e?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    maxLength?: string | number;
    showPassword?: boolean;
    setShowPassword?: (show: boolean) => void;
    inputRef?: React.Ref<any>;
    rightIcon?: React.ReactNode;
    onClick?: () => any;
    loading?: boolean;
};

const styles = () => ({
    root: {
        '& .MuiInputBase-root': {
            width: '100%',
            margin: '10px 0px',
        },
    },
    label: {
        transform: 'translate(12px, 17px) scale(1)',
        color: 'rgb(179, 179, 179)',

        '&.shrink': {
            transform: 'translate(12px, 6px) scale(0.75)',
            color: 'rgb(179, 179, 179);',
            fontWeight: '500',
            letterSpacing: '0.25px',
        },
    },
    input: {
        border: '1px solid transparent',
        backgroundColor: '#f6f7f9',
        color: '#333',
        borderRadius: '15px',
        outline: '1px solid transparent', // we use a transparent outline here so the component doesn't move when focused
        '& .MuiFilledInput-input': {
            padding: '21px 12px 8px',
        },
        '& .MuiFilledInput-inputMultiline': {
            padding: '0px',
        },
        '&:hover': {
            background: '#f6f7f9',
        },
        '&$focused': {
            border: `1px solid #E3E5E8`,
            backgroundColor: '#f6f7f9',
        },
    },
    focused: {}, // we have to pass in this focused class to make the focused state rule above work
});

/* https://www.figma.com/file/naUyXB9d4H9XG1tuFkgtbq/weLibrary-Design-Systems?node-id=0%3A1 */
const TextInput: React.FC<TextInputProps> = ({
    id,
    autoComplete = 'off',
    autoFocus,
    classes,
    multiline,
    type,
    required = false,
    label,
    defaultValue,
    error,
    className,
    value,
    onChange,
    onBlur,
    onFocus,
    helperText,
    disabled,
    rows,
    rowsMax,
    maxLength = '255',
    name,
    showPassword,
    setShowPassword,
    inputRef,
    rightIcon,
    onClick,
    loading = false,
}) => {
    if (!multiline && rows && rowsMax) rows = undefined;
    rowsMax = undefined;
    if (multiline && !rows && !rowsMax) rows = 4;
    rowsMax = 'Infinity';

    const isPassword = type === 'password';

    const passwordEyeSlash = isPassword && setShowPassword && (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
            >
                {showPassword ? (
                    <Eye />
                ) : (
                    <EyeSlash size="24" color="currentColor" strokeWidth="2" version="2" />
                )}
            </IconButton>
        </InputAdornment>
    );

    const endAdornment = (loading || passwordEyeSlash || rightIcon) && (
        <div className="flex items-center gap-[10px] bg-transparent">
            {!loading && passwordEyeSlash}
            {!loading && rightIcon}
            {loading && (
                <LottieLoading
                    lottieData={LottieAnimation}
                    customStyle={{ opacity: 1, height: '30px', width: '30px' }}
                />
            )}
        </div>
    );

    return (
        <TextField
            inputRef={inputRef}
            autoFocus={autoFocus}
            disabled={disabled}
            required={required}
            autoComplete={autoComplete}
            error={error}
            className={className}
            id={id}
            onChange={e => onChange(e.target.value, e)}
            name={name}
            helperText={helperText}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            label={label}
            multiline={multiline}
            minRows={rows}
            maxRows={rowsMax}
            type={isPassword && showPassword ? 'text' : type}
            defaultValue={defaultValue}
            variant="filled"
            onClick={onClick}
            inputProps={{
                maxLength,
                style: { border: error ? '1px solid #CC0000' : '', borderRadius: '15px' },
            }}
            InputLabelProps={{
                classes: {
                    root: classes.label,
                    shrink: 'shrink',
                },
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
                classes: {
                    root: classes.input,
                    focused: classes.focused, // we can't forget to pass this in or &$focused in our input class won't work
                    input: classes.actuallyInput,
                },
                disableUnderline: true, // remove the underline
                endAdornment,
            }}
        />
    );
};

export default withStyles(styles)(TextInput);
