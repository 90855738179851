import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

import { createContext } from '@core/utilities/helpers/context.helpers';

import { getWhitelabelGoogleKeys } from '@core/utilities/whitelabel_helpers';

export type useMapsContextValues = {
    maps: typeof google.maps | null;
    autocomplete: google.maps.places.AutocompleteService | undefined;
    geocoder: google.maps.Geocoder | undefined;
};

export const [useMapsContext, MapsContextProvider] = createContext<useMapsContextValues>();

export const MapsProvider = ({ children }) => {
    const [maps, setMaps] = useState<typeof google.maps | null>(null);

    const autocomplete = useRef<google.maps.places.AutocompleteService>();
    const geocoder = useRef<google.maps.Geocoder>();

    useEffect(() => {
        const loader = new Loader({
            apiKey: getWhitelabelGoogleKeys().Maps_API_Key,
            libraries: ['places'],
        });

        loader.load().then(() => {
            autocomplete.current = new google.maps.places.AutocompleteService();
            geocoder.current = new google.maps.Geocoder();
            setMaps(google.maps);
        });
    }, []);

    return (
        <MapsContextProvider
            value={{ maps, autocomplete: autocomplete.current, geocoder: geocoder.current }}
        >
            {children}
        </MapsContextProvider>
    );
};
