import React from 'react';
import { Updater } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { curriedStateSlice } from '@helpers/state/state.helpers';
import ThumbnailAndCoverImageUpload from '@dsc/forms/customInputs/ThumbnailAndCoverImageUpload';
import TextInput from '@dsc/forms/textInputs/TextInput';
import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

import { ComposeMessageState } from '@core/types/Messages';

const localNamespace = 'imports.wlWeb.ui.components.messaging.messageOverlay';

type GroupMessageTitleInputProps = {
    state: ComposeMessageState;
    setState: Updater<ComposeMessageState>;
    onInput?: (value: boolean) => void;
};

const GroupMessageTitleInput: React.FC<GroupMessageTitleInputProps> = ({
    state,
    setState,
    onInput,
}) => {
    const { t } = useTranslation();

    const updateSlice = curriedStateSlice(setState);
    const imgUrl = state?.thumb || getRandomDefaultCoverImage(state?.title || 'V');

    const handleOnBlur = () => {
        onInput?.(false);
    };

    const handleOnFocus = () => {
        onInput?.(true);
    };

    return (
        <div className="new-message-contacts-container group-name aligncenter fadein group-msg-input">
            <ThumbnailAndCoverImageUpload
                thumbOnly
                setThumb={updateSlice('thumb')}
                thumb={imgUrl}
                cover={imgUrl}
                setCover={updateSlice('thumb')}
            />
            <div className="groupSearchInput fadein">
                <TextInput
                    disabled={state?.participants?.length < 2}
                    value={state.title}
                    onFocus={handleOnFocus}
                    autoComplete="off"
                    onBlur={handleOnBlur}
                    maxLength={50}
                    onChange={updateSlice('title')}
                    label={t(`common:${localNamespace}.message_group_name`, 'Message group name')}
                    required
                />
            </div>
        </div>
    );
};

export default GroupMessageTitleInput;
