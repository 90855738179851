import _ from 'lodash';

const ruleName = `translateMarkdown`;

// create a custom plugin matching :!:
const parse = function (state) {
    // I get my character hex codes from the console using
    // "0x"+"[".charCodeAt(0).toString(16).toUpperCase()

    const key = 'tr';
    let pos = state.pos;
    const marker = state.src.charCodeAt(pos);

    let reg = new RegExp(`([${key}:([a-zA-Z._-]*])`);
    let currentSpot = state.src.slice(pos);
    let result = reg.exec(currentSpot);
    if (!result) return false;
    if (!result[0]) return false;
    if (result[0].indexOf(`[${key}:`) !== 0) return false;
    if (_.values(state.tokens).includes(ruleName)) return false;

    let sequence = result[0];
    let code = result[0].replace(`[${key}:`, '').replace(']', '');

    pos += sequence.length;
    state.pos = pos;
    if (state.pos > state.posMax) {
        state.pos = state.posMax;
    }

    // Having matched all three characters we add a token to the state list
    const token = {
        type: ruleName,
        level: state.level,
        content: code,
    };

    state.push(token);
    return true;
};

const render = function (tokens, idx, options) {
    const tokenToTranslate = tokens[idx];
    if (options.t) return `${options.t(`common:${tokenToTranslate.content}`)}`;
    return `<i>${tokenToTranslate.content}</i>`;
};

export const translate_plugin = md => {
    md.inline.ruler.push(ruleName, parse);
    md.renderer.rules[ruleName] = render;
};
