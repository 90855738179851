import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ThickX } from '@dsc/svgs/FullSizeX';
import Plus from '@dsc/svgs/Plus';

type AddRemoveTagProps = {
    text: string;
    isSelected: boolean;
    onClick: () => any;
    size?: 'large' | 'medium';
    showIcon?: boolean;
};

const AddRemoveTag: React.FC<AddRemoveTagProps> = ({
    text,
    isSelected,
    onClick,
    size = 'large',
    showIcon = true,
}) => {
    const selectedClassNames = 'bg-primary-default text-white';
    const notSelectedClassNames = 'bg-white text-grayscale-title-active';

    const bigClasses =
        'gap-[10px] rounded-[40px] px-[20px] py-[15px] border-[2px] text-[17px] leading-[24px]';
    const medClasses =
        'gap-[8px] rounded-[20px] px-[12px] py-[8px] border-[1.5px] text-[14px] leading-[26px]';

    let sizeClassNames = '';
    let iconSize = '';
    if (size === 'large') {
        sizeClassNames = bigClasses;
        iconSize = '20';
    } else if (size === 'medium') {
        sizeClassNames = medClasses;
        iconSize = '15';
    }

    return (
        <button
            type="button"
            className={`flex items-center border-solid border-primary-default font-[600] ${sizeClassNames} ${isSelected ? selectedClassNames : notSelectedClassNames
                }`}
            onClick={onClick}
        >
            {showIcon && !isSelected && (
                <span className="text-primary-default">
                    <Plus version="2" size={iconSize} />
                </span>
            )}
            {text}
            {showIcon && isSelected && <ThickX size={iconSize} strokeWidth="2" />}
        </button>
    );
};

type CustomAddRemoveTagProps = {
    isSelected: boolean;
    size?: 'large' | 'medium';
    showIcon?: boolean;
    addOption: (value: string) => void;
};

export const CustomAddRemoveTag: React.FC<CustomAddRemoveTagProps> = ({
    isSelected,
    size = 'large',
    showIcon = true,
    addOption,
}) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>();
    const sizeReferenceDivRef = useRef<HTMLDivElement>();
    const [value, setValue] = useState('');

    const selectedClassNames = 'bg-primary-default text-white';
    const notSelectedClassNames = 'bg-transparent text-grayscale-title-active';

    const bigClasses =
        'gap-[10px] rounded-[40px] px-[20px] py-[15px] border-[2px] text-[17px] leading-[24px]';
    const medClasses =
        'gap-[8px] rounded-[20px] px-[12px] py-[8px] border-[1.5px] text-[14px] leading-[26px]';

    let sizeClassNames = '';
    let iconSize = '';
    if (size === 'large') {
        sizeClassNames = bigClasses;
        iconSize = '20';
    } else if (size === 'medium') {
        sizeClassNames = medClasses;
        iconSize = '15';
    }

    let colorClassNames = '';
    if (!value || !isSelected) {
        colorClassNames = 'border-grayscale-label';
    } else {
        colorClassNames = 'border-primary-default';
    }

    const placeholderText = t('common:type-your-own', 'Type your own...');
    useEffect(() => {
        inputRef.current.style.width = `${sizeReferenceDivRef.current.clientWidth}px`;
    }, [value]);

    const _addOption = () => {
        if (value) {
            addOption(value);
            setValue('');
        }
    };

    return (
        <div
            className={`flex items-center border-solid font-[600] ${colorClassNames} ${sizeClassNames} ${isSelected ? selectedClassNames : notSelectedClassNames
                }`}
        >
            {showIcon && !isSelected && (
                <button
                    type="button"
                    className="text-grayscale-label"
                    onClick={_addOption}
                    disabled={!value}
                >
                    <Plus version="2" size={iconSize} />
                </button>
            )}

            <div ref={sizeReferenceDivRef} className="absolute opacity-0 z-[-1] whitespace-pre">
                {value || placeholderText}
            </div>
            <input
                ref={inputRef}
                type="text"
                value={value}
                onClick={e => e.stopPropagation()}
                onChange={e => {
                    setValue(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        _addOption();
                    }
                }}
                placeholder={placeholderText}
                className="bg-transparent w-fit placeholder-grayscale-label"
            />

            {/* {text || t('common:type-your-own', 'Type your own...')} */}
            {showIcon && isSelected && <ThickX size={iconSize} strokeWidth="2" />}
        </div>
    );
};

export default AddRemoveTag;
