import React from 'react';
import {Updater} from 'use-immer';

type RRBuilderColorPickerProps = {
    state: string;
    setState: Updater<string>;
    values: string[];
    darkMode?: boolean;
    className?: string;
    title?: string;
    showCircles?: boolean;
};

const RRBuilderColorPicker: React.FC<RRBuilderColorPickerProps> = ({
    state,
    setState,
    values,
    darkMode,
    className,
    title,
    showCircles = true,
}) => {
    const buttons = values.map(value => (
        <button
            key={value}
            type="button"
            className={value === state ? 'selected' : ''}
            onClick={() => setState(value)}
        >
            {showCircles ? (
                <>
                    <div
                        className="background"
                        role="presentation"
                        style={darkMode ? { backgroundColor: value } : undefined}
                    />
                    <div
                        className="foreground"
                        role="presentation"
                        style={darkMode ? undefined : { backgroundColor: value }}
                    />
                </>
            ) : (
                <div
                    className="background"
                    role="presentation"
                    style={{ backgroundColor: value }}
                />
            )}
        </button>
    ));

    return (
        <section className={`rr-builder-color-picker ${className}`}>
            {title && <header>{title}</header>}

            {buttons}
        </section>
    );
};

export default RRBuilderColorPicker;
