import React, { useState, useEffect, useContext, MouseEventHandler } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import useUploadPhoto from '@web/ui/components/generic/hooks/useUploadPhoto';
import ToastContext from '@components/generic/context/ToastContext';
import { useCurrentUser } from '@stores/User';

import Picture from '@components/generic/Picture';
import LottieLoading from '@components/generic/loading/LottieLoading';
import LottieAnimation from '@components/generic/loading/lotties/circle-spinner.json';
import CustomLegoToastFooter from '@components/toast/Lego/CustomLegoToastFooter';
import SSLIcon from '@assets/lego-ssl-icon.png';
import Plus from '@dsc/svgs/FullSizePlus';
import Trash from '@dsc/svgs/Trash';

import { canUploadPhotos } from '@helpers/user/child.helpers';

import { ImageWithLoadingState, Maybify } from '@core/types/Utilities';
import { SurveyQuestion } from '@shared/welibrary-graphql/types';

const localNamespace = 'common:global.loading';

const getLoadingText = (state: ImageWithLoadingState, t: TFunction): React.ReactNode => {
    if (state.loading?.type === 'resizing') return t(`${localNamespace}.resizing`);

    if (state.loading?.type === 'uploading') {
        return state.loading?.progress
            ? `${t(`${localNamespace}.uploading`)} (${state.loading.progress})`
            : t(`${localNamespace}.starting_upload`);
    }
};

type SurveyImageProps = {
    state: string;
    setState: (value: string) => void;
    question: Maybify<SurveyQuestion>;
    className?: string;
    disabled?: boolean;
    viewOnly?: boolean;
};

const SurveyImage: React.FC<SurveyImageProps> = ({
    state,
    setState,
    question,
    className = '',
    disabled = false,
    viewOnly = false,
}) => {
    const { t } = useTranslation();

    const { currentUser } = useCurrentUser();
    const { showCustomToast } = useContext(ToastContext);

    const [image, setImage] = useState<ImageWithLoadingState>({ image: state });

    const handleFileSelect = useUploadPhoto(setImage);

    const canUpload = canUploadPhotos(currentUser);

    const loadingText = getLoadingText(image, t);

    const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
        if (canUpload) return handleFileSelect(e);
        showCustomToast(
            'You do not have permission to upload photos!',
            null,
            5000,
            'responsive',
            SSLIcon,
            <CustomLegoToastFooter />
        );
    };

    useEffect(() => {
        if (!image.loading) setState(image.image);
    }, [image.image, image.loading]);

    useEffect(() => {
        if (state !== image.image) setImage({ image: state });
    }, [state]);

    if (!image.image) {
        return (
            <section className={`survey-question-image ${className}`}>
                {!viewOnly && (
                    <>
                        <button
                            type="button"
                            onClick={handleClick}
                            className="upload-photo"
                            disabled={disabled}
                        >
                            <Plus strokeWidth="8" />
                        </button>

                        <p>{question.prompt}</p>
                    </>
                )}
                {viewOnly && <p>No Image Uploaded</p>}
            </section>
        );
    }

    return (
        <section className={`survey-question-image ${className}`}>
            <Picture url={image.image} disableLink>
                {image.loading && (
                    <section>
                        <LottieLoading
                            customStyle={{ backgroundColor: '#00000099', borderRadius: '50%' }}
                            height={175}
                            width={175}
                            lottieData={LottieAnimation}
                            isPaused={false}
                        />
                        <span>{loadingText}</span>
                    </section>
                )}
            </Picture>

            {!viewOnly && (
                <>
                    <button
                        type="button"
                        onClick={() => setImage({ image: '' })}
                        className="remove-photo"
                    >
                        <Trash />
                    </button>

                    {!image.loading && <p>Looks Great!</p>}
                </>
            )}
        </section>
    );
};

export default SurveyImage;
