import React from 'react';

const CameraIcon: React.FC<{
    className?: string;
    size?: string;
    strokeWidth?: string;
    color?: string;
    version?: '1' | '2';
}> = ({ className, size = '24', strokeWidth = '2', color = '#14142B', version = '1' }) => {
    if (version === '1') {
        return (
            <svg
                className={className}
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2 9.25694C2 7.45818 3.45818 6 5.25694 6V6C6.34591 6 7.36283 5.45576 7.96688 4.54969L8 4.5C8.62474 3.56288 9.6765 3 10.8028 3H13.1972C14.3235 3 15.3753 3.56288 16 4.5L16.0331 4.54969C16.6372 5.45576 17.6541 6 18.7431 6V6C20.5418 6 22 7.45818 22 9.25694V17C22 19.2091 20.2091 21 18 21H6C3.79086 21 2 19.2091 2 17V9.25694Z"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <circle
                    cx="12"
                    cy="13"
                    r="4"
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }

    // version === '2'
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.8488 5.28467L16.8674 5.3125H16.9008H19.4999C20.0799 5.31316 20.6359 5.54385 21.046 5.95396C21.4561 6.36406 21.6868 6.9201 21.6875 7.50007V17.9999C21.6868 18.5799 21.4561 19.1359 21.046 19.546C20.6359 19.9561 20.0799 20.1868 19.4999 20.1875H4.50007C3.9201 20.1868 3.36406 19.9561 2.95396 19.546C2.54385 19.1359 2.31316 18.5799 2.3125 17.9999L2.3125 7.50004C2.31317 6.92007 2.54386 6.36405 2.95396 5.95396C3.36406 5.54385 3.9201 5.31316 4.50007 5.3125H7.09808H7.13153L7.15009 5.28467L8.42743 3.36865L8.37543 3.33398L8.42743 3.36865C8.49021 3.27448 8.57527 3.19726 8.67505 3.14385C8.77484 3.09044 8.88627 3.0625 8.99945 3.0625H14.9995C15.1126 3.0625 15.2241 3.09044 15.3238 3.14385C15.4236 3.19726 15.5087 3.27448 15.5715 3.36865L15.6235 3.33398L15.5715 3.36865L16.8488 5.28467ZM15.4375 12.375V12.3749C15.4365 11.4636 15.074 10.5899 14.4296 9.94543C13.7852 9.30101 12.9114 8.93852 12.0001 8.9375H12C11.3201 8.9375 10.6555 9.13911 10.0902 9.51682C9.52493 9.89454 9.08434 10.4314 8.82416 11.0595C8.56399 11.6876 8.49591 12.3788 8.62855 13.0456C8.76119 13.7124 9.08858 14.3249 9.56932 14.8057C10.0501 15.2864 10.6626 15.6138 11.3294 15.7464C11.9962 15.8791 12.6874 15.811 13.3155 15.5508C13.9436 15.2907 14.4805 14.8501 14.8582 14.2848C15.2359 13.7195 15.4375 13.0549 15.4375 12.375Z"
                fill={color}
                stroke={color}
                strokeWidth="0.125"
            />
        </svg>
    );
};

export default CameraIcon;
