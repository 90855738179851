import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import useScreenWidth from '@components/generic/hooks/useScreenWidth';
import useGetCSV from '@components/group/groupdashboard/GroupMembers/hooks/useGetCSV';

import CaretIcon from '@dsc/svgs/LeftCaretIcon';
import ViewCustomerOrder from './ViewCustomerOrder';
import SearchInput from '@components/search/SearchInput';
import ExportIcon from '@web/ui/dsc/svgs/ExportIcon';
import { ThickTicket } from '@dsc/svgs/Ticket';

import { CustomerOrder, Group, User } from '@shared/welibrary-graphql/types';

import {
    convertCentsToDollars,
    getOrderTotalDisplayString,
} from '@components/card/Shopping-Cart/CheckoutHelpers';
import { getNameWithLastNameFirst } from '@components/profile/helpers';
import { getCsvOfTickets } from '@web/utilities/helpers/checkIn.helpers';
import { StripeCharge, StripeRefund } from '~/wl-core/graphql/types';
import { RefundStatusEnum } from '../../../profile/stripe/stripeOrderHelpers';
import { getCurrencyDisplayString } from '~/wl-web/utilities/helpers/money/money.helpers';

type ManageEventOrdersTabProps = {
    group: Group;
    orders: CustomerOrder[];
};

const ManageEventOrdersTab: React.FC<ManageEventOrdersTabProps> = ({ group, orders }) => {
    const { t } = useTranslation();
    const [searchString, setSearchString] = useState('');
    const [orderBeingViewed, setOrderBeingViewed] = useState<CustomerOrder>(undefined);

    const windowWidth = useScreenWidth();
    const isMobileSize = windowWidth <= 767;

    const { getAndDownloadCSV } = useGetCSV(group);

    useEffect(() => {
        // so that orderBeingViewed gets updated in state after checking in a ticket
        if (orderBeingViewed) {
            setOrderBeingViewed(orders.find(o => o._id === orderBeingViewed._id));
        }
    }, [orders]);


    const ordersUnmodified = cloneDeep(orders); // for the csv export

    // Display orders with last name first
    orders = orders.map(o => {
        const nameWithLastNameFirst = getNameWithLastNameFirst(o.user.profile.full_name);

        return {
            ...o,
            user: {
                ...o.user,
                profile: { ...o.user.profile, full_name: nameWithLastNameFirst },
            },
        };
    });

    // Sort alphabetically by last name
    orders.sort((a, b) =>
        a.user.profile.full_name.toLowerCase().localeCompare(b.user.profile.full_name.toLowerCase())
    );

    const searchStringLower = searchString?.toLowerCase();

    const userIsSearchMatch = (user: User, includePhoneEmailMatch: boolean = true) => {
        const { full_name: name, phone, email } = user.profile;

        if (name.toLowerCase().includes(searchStringLower)) {
            // simple match 👍
            return true;
        }

        if (
            includePhoneEmailMatch &&
            (email?.toLowerCase().includes(searchStringLower) || phone?.includes(searchStringLower))
        ) {
            return true;
        }

        // Let's handle spaces in the search string so that a search of "first last"
        // will match a ticket with the name "last, first"
        const splitSearch = searchStringLower.split(' ');
        if (splitSearch.length > 1) {
            const match = splitSearch.reduce((allMatch, subSearchString) => {
                return allMatch && name.toLowerCase().includes(subSearchString);
            }, true);

            return match;
        }

        return false;
    };

    if (searchString) {
        // Filter to only orders that match the search
        orders = orders.filter(o => {
            if (
                o._id.includes(searchString) || // id search is case sensitive
                userIsSearchMatch(o.user)
            ) {
                return true;
            }

            // check if there are any transfer ticket recipients who are a match
            const transferMatch = o.productInstances.some(ticket =>
                userIsSearchMatch(ticket.owner, false)
            );

            return transferMatch;
        });
    }

    const exportOrdersToCsv = () => {
        const csv = getCsvOfTickets(ordersUnmodified);
        getAndDownloadCSV(csv, 'TicketCheckIn');
    };

    const selectOrder = (order: CustomerOrder) => {
        setOrderBeingViewed(order);
        setSearchString('');
    };

    return (
        <>
            {orderBeingViewed && (
                <ViewCustomerOrder
                    group={group}
                    order={orderBeingViewed}
                    goBack={() => setOrderBeingViewed(undefined)}
                />
            )}

            {!orderBeingViewed && (
                <>
                    <div className="flex gap-[10px]">
                        <SearchInput
                            value={searchString}
                            onChange={setSearchString}
                            className="check-in-search-field text-[16px]"
                            placeholder={t(`common:global.search`, 'Search')}
                        />

                        <button
                            onClick={exportOrdersToCsv}
                            className="bg-primary-default flex flex-col items-center rounded-[15px] px-[15px] py-[5px] text-[14px] font-[600] text-white"
                        >
                            <ExportIcon />
                            {t(`common:global.export`, 'Export')}
                        </button>
                    </div>

                    {orders && orders.length > 0 && (
                        <ul className="pl-0">
                            {orders.map((order, index) => {
                                const orderTotal = getOrderTotalDisplayString(order);
                                const { full_name: name, phone, email } = order.user.profile;
                                const emailIsSearchMatch =
                                    searchString &&
                                    email?.toLowerCase().includes(searchStringLower);
                                const phoneIsSearchMatch =
                                    searchString &&
                                    phone?.toLowerCase().includes(searchStringLower);
                                const idIsSearchMatch =
                                    searchString && order._id.includes(searchString);
                                const transferMatch =
                                    searchString &&
                                    !emailIsSearchMatch &&
                                    !phoneIsSearchMatch &&
                                    !idIsSearchMatch &&
                                    order.productInstances.find(ticket =>
                                        userIsSearchMatch(ticket.owner, false)
                                    );

                                const refunds: StripeRefund[] =
                                    order?.paymentIntent?.charges?.data?.[0]?.refunds?.data ?? [];
                                const latestCharge: StripeCharge | undefined =
                                    order?.paymentIntent?.charges?.data?.[0] ?? {};
                                const latestRefund: StripeRefund | undefined =
                                    order?.paymentIntent?.charges?.data?.[0]?.refunds?.data?.[0] ??
                                    {};
                                const orderWasRefunded: boolean =
                                    // check the latest charge was refunded
                                    latestCharge?.amount_refunded > 0 &&
                                    // check the latest charge refund status was successful
                                    (latestRefund?.status === RefundStatusEnum.Succeeded ||
                                        latestRefund?.status === RefundStatusEnum?.Pending);
                                const orderRefundFailed =
                                    latestRefund?.status === RefundStatusEnum?.Failed;
                                const orderWasFullyRefunded: boolean =
                                    latestCharge?.amount_refunded === order?.paymentIntent?.amount;
                                const orderWasPartiallyRefunded: boolean =
                                    latestCharge?.amount_refunded !== order?.paymentIntent?.amount;

                                const currency = order?.paymentIntent?.currency ?? 'USD';

                                const refundAmount: string = convertCentsToDollars(
                                    latestRefund?.amount
                                );
                                const refundAmountDisplayString = getCurrencyDisplayString(
                                    refundAmount,
                                    currency,
                                    undefined,
                                    true,
                                    2
                                );

                                return (
                                    <li
                                        key={index}
                                        role={isMobileSize ? 'button' : undefined}
                                        onClick={
                                            isMobileSize ? () => selectOrder(order) : undefined
                                        }
                                        className="border-grayscale-line flex items-baseline gap-[20px] border-b-[1px] border-solid py-[20px] last:border-b-0"
                                    >
                                        <div className="flex grow flex-col">
                                            <div className="mb-[10px] flex flex-col gap-[5px]">
                                                <span className="text-grayscale-title-active text-[17px] font-[700] leading-[22px] tracking-[0.75px]">
                                                    {name}
                                                </span>

                                                {idIsSearchMatch && (
                                                    <span className="text-grayscale-body mb-[10px] text-[12px] font-[500]">
                                                        {order._id}
                                                    </span>
                                                )}
                                                {emailIsSearchMatch && (
                                                    <span className="text-grayscale-body mb-[10px] text-[12px] font-[500]">
                                                        {email}
                                                    </span>
                                                )}
                                                {phoneIsSearchMatch && (
                                                    <span className="text-grayscale-body mb-[10px] text-[12px] font-[500]">
                                                        {phone}
                                                    </span>
                                                )}
                                                {transferMatch && (
                                                    <span className="text-grayscale-body mb-[10px] text-[12px] font-[500]">
                                                        {t(
                                                            'common:global.received_a_transferred_ticket',
                                                            'Received a transferred ticket'
                                                        )}
                                                        : {transferMatch.owner.profile.full_name}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="grow">
                                                {order.purchaseRequests.map(
                                                    (purchaseRequest, index) => {
                                                        const {
                                                            quantity,
                                                            product: { name },
                                                        } = purchaseRequest;
                                                        return (
                                                            <div
                                                                className="flex gap-[10px]"
                                                                key={index}
                                                            >
                                                                <span className="text-grayscale-body text-[12px] font-[600] leading-[20px]">
                                                                    {quantity}x
                                                                </span>
                                                                <div className="text-grayscale-body line-clamp-1 flex grow gap-[5px] text-[12px] font-[600] uppercase leading-[20px]">
                                                                    <ThickTicket strokeColor="currentColor" />{' '}
                                                                    {name}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>

                                            <span className="text-grayscale-title-active mt-[5px] text-[14px] font-[700]">
                                                {orderTotal}
                                            </span>
                                        </div>

                                        <div className="flex h-full flex-col items-center justify-between">
                                            <button
                                                type="button"
                                                className="text-grayscale-background bg-primary-default my-auto ml-auto h-fit w-fit rounded-[40px] p-[10px] text-[14px] font-[600] leading-[24px] tracking-[0.75px] md:my-0 md:px-[34px] md:py-[8px]"
                                                onClick={
                                                    isMobileSize
                                                        ? undefined
                                                        : () => selectOrder(order)
                                                }
                                            >
                                                {isMobileSize && (
                                                    <CaretIcon
                                                        className="rotate-[180deg]"
                                                        color="#ffffff"
                                                    />
                                                )}
                                                {!isMobileSize &&
                                                    t(`common:global.view_order`, 'View Order')}
                                            </button>

                                            {orderWasRefunded && (
                                                <div className="mt-2 flex w-full flex-col items-end justify-center text-right">
                                                    <p className="text-grayscale-body m-0 p-0 font-semibold">
                                                        Refunded
                                                    </p>
                                                    <p className="m-0 p-0 font-semibold text-red-500">
                                                        -{refundAmountDisplayString}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </>
            )}
        </>
    );
};

export default ManageEventOrdersTab;
