import React, { useRef, useEffect, useState } from 'react';

import useUniversalWallet from '@web/utilities/hooks/wallet/useUniversalWallet';
import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';

import TreasureHeader from '@components/lego/treasure/TreasureHeader';
import TreasureBody from '@components/lego/treasure/TreasureBody';
import TreasureNav from '@components/lego/treasure/TreasureNav';

import { getCarouselFunctions } from '@helpers/navigation/horizontalNav.helpers';
import { BadgeRecord } from '@core/types/Credential';

const TreasureContainer: React.FC = () => {
    const { walletInstance } = useUniversalWallet();
    const [badges, setBadges] = useState<BadgeRecord[]>([]);
    const [legoSeasonVCs, setLegoSeasonVC] = useState();
    const [plugfestVc, setPlugfestVc] = useState<any | null>(null);

    const refs = {
        resources: useRef<HTMLElement>(null),
        creations: useRef<HTMLElement>(null),
        seasons: useRef<HTMLElement>(null),
        badges: useRef<HTMLElement>(null),
    };

    const { on, scrollTo } = useHorizontalPages({ refs });

    const { current } = getCarouselFunctions({ on, scrollTo });

    const getBadges = async () => {
        if (!walletInstance) return;

        const records = await walletInstance.index.all.get();

        const tmpBadges = [];
        await Promise.all(
            records.map(async record => {
                const credential = await walletInstance.read.get(record.uri);
                tmpBadges.push({
                    id: record.id,
                    unclaimed: record.unclaimed,
                    uri: record.uri,
                    credential,
                });
            })
        );

        setBadges(tmpBadges);
    };

    useEffect(() => {
        getBadges();
    }, [walletInstance]);

    return (
        <div className="treasures-page-container">
            <div className="center-container">
                <TreasureHeader />

                <TreasureNav
                    scrollTo={scrollTo}
                    active={current}
                    legoSeasonVCs={legoSeasonVCs}
                    showBadges={badges.length > 0}
                />

                <TreasureBody
                    refs={refs}
                    legoSeasonVCs={legoSeasonVCs}
                    plugfestVc={plugfestVc}
                    badges={badges}
                />
            </div>
        </div>
    );
};

export default TreasureContainer;
