import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';
import useConfirmation from '@components/generic/hooks/useConfirmation';
import { useBulkInviteUsersByEmailMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { init } from 'filestack-js';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import EventInviteModal from '@web/ui/components/group/groupdashboard/GroupMembers/EventInviteModal';

import AlertToast from '@components/toast/AlertToast';

import { defaultAlertToastOptions } from '@core/types/Toast';

import { UPDATE_GET_GROUP_MEMBERSHIP } from '@core/utilities/constants/update_cache';

import { GET_GROUP_DASHBOARD, GET_GROUP_MEMBERSHIP } from '@shared/welibrary-graphql/user/queries';
import { config } from '@core/config/getConfig';
import { CARD_EVENT } from '~/wl-core/utilities/constants/card_types';
import PRODUCT_TYPES from '~/wl-web/ui/components/content/newpost/forms/ShoppingCartProducts/constants';

const localNamespace = 'imports.wlWeb.ui.components.group.groupDashboard';

const useBulkCSVUploader = (groupId, onNetworkSearchAction, group) => {
    const { t } = useTranslation();
    const { newModal, closeModal } = useModal({ mobile: 'center' });
    const confirm = useConfirmation();
    const { newToast } = useToastActionsContext();

    const [bulkInviteUsersByEmail] = useBulkInviteUsersByEmailMutation();
    const [fsClient] = useState(init(config.public.filestack.api_key));
    const host = typeof window !== 'undefined' && window?.location?.host;

    const handleInvites = async (emails, shouldCloseModal = false) => {
        bulkInviteUsersByEmail({
            variables: {
                input: {
                    groupId,
                    emailAndNames: emails.map(email => {
                        return {
                            email,
                        };
                    }),
                    host,
                },
            },
            update: (cache, data) =>
                UPDATE_GET_GROUP_MEMBERSHIP(cache, data, { groupId, emails, type: 'email' }),
        }).then(async () => {
            if (shouldCloseModal) {
                setTimeout(async () => {
                    await onNetworkSearchAction();
                    closeModal();
                }, 500);
            } else {
                await onNetworkSearchAction();
            }

            newToast(
                <AlertToast
                    boldText={t(
                        `common:${localNamespace}.emails_invites_success`,
                        'Email invites sent successfully!'
                    )}
                    showSuccessIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
        });
    };

    const handleFreeEventRegisterInviteModal = emails => {
        newModal(
            <EventInviteModal
                group={group}
                emails={emails}
                onClick={async () => {
                    const confirmed = await confirm({
                        text: t(`common:${localNamespace}.confirm_invite_people`, {
                            peopleCount: emails.length,
                        }),
                    });

                    if (confirmed) {
                        await handleInvites(emails, true);
                    }
                }}
                showEmails
            />
        );
    };

    const handlePaidEventRegisterInviteModal = emails => {
        let productsToDisplay;
        if (group?.paymentSettings?.isGroupMembershipPaid && group?.doesSellMembership) {
            productsToDisplay = group?.products.filter(
                product => product.type !== PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP
            );
        } else {
            productsToDisplay = group?.products.filter(
                product => product.type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP
            );
        }
        newModal(
            <EventInviteModal
                group={group}
                emails={emails}
                tickets={productsToDisplay}
                onClick={async () => {
                    const confirmed = await confirm({
                        text: t(`common:${localNamespace}.confirm_invite_people`, {
                            peopleCount: emails.length,
                        }),
                    });

                    if (confirmed) {
                        await handleInvites(emails, true);
                    }
                }}
                showEmails
            />
        );
    };

    const handleBulkInviteUsersByEmail = async emails => {
        if (!emails) {
            newToast(
                <AlertToast
                    boldText={`${t(`common:${localNamespace}.error.no_emails`)}`}
                    showWarningIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
            return;
        }

        if (group && group?.subtype === CARD_EVENT) {
            if (group?.paymentSettings?.isGroupMembershipPaid && group?.doesSellMembership) {
                handlePaidEventRegisterInviteModal(emails);
            } else {
                handleFreeEventRegisterInviteModal(emails);
            }
        } else {
            // eslint-disable-next-line no-restricted-globals
            const confirmed = await confirm({
                text: t(`common:${localNamespace}.confirm_invite_people`, {
                    peopleCount: emails.length,
                }),
            });

            if (confirmed) {
                await handleInvites(emails, false);
            }
        }
    };

    const openBulkCSVUploadHandler = () => {
        const options = {
            fromSources: [
                'local_file_system',
                'url',
                'googledrive',
                'dropbox',
                'box',
                'gmail',
                'onedrive',
            ],
            accept: ['text/csv'],
            startUploadingWhenMaxFilesReached: true,
            onFileUploadFinished: data => {
                const { url } = data;
                bulkInviteUsersByEmail({
                    variables: {
                        input: {
                            groupId,
                            csvFileUrl: url,
                            host,
                        },
                    },
                    refetchQueries: [GET_GROUP_DASHBOARD, GET_GROUP_MEMBERSHIP],
                }).then(() => {
                    newToast(
                        <AlertToast
                            boldText={`${t(
                                `common:${localNamespace}.alert.invite_by_csv_success`
                            )}`}
                            showSuccessIcon
                        />,
                        {
                            ...defaultAlertToastOptions,
                        }
                    );
                    onNetworkSearchAction();
                });
            },
        };
        fsClient.picker(options).open();
    };

    return {
        openBulkCSVUploadHandler,
        handleBulkInviteUsersByEmail,
    };
};

export default useBulkCSVUploader;
