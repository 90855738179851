import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '@config/getConfig';

const PayoutDisclaimer: React.FC<{ className?: string }> = ({ className }) => {
    const { t } = useTranslation();

    let platformName = '';
    if (
        config.public.configName === 'production-commissioned' ||
        config.public.configName === 'staging-commissioned' ||
        config.public.configName === 'production-itz'
    ) {
        platformName = 'Commissioned LLC';
    } else if (config.public.configName === 'production-connect') {
        platformName = 'Connect-ITZ, LLC';
    } else {
        platformName = 'WeLibrary, LLC';
    }

    return (
        <p className={className}>
            <em>
                {t(
                    'common:eventGroupForm.payout_disclaimer',
                    'Deposits to your bank will come from'
                )}{' '}
                <b>{platformName}</b>
            </em>
        </p>
    );
};

export default PayoutDisclaimer;
