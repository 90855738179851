import { useImmer } from 'use-immer';

import { CreationState, CreationLevelState } from '@core/types/Creations';
import { ContentCard, CreationLevel } from '@shared/welibrary-graphql/types';

/** Converts a Creationlevel, as returned by GraphQL, and converts it into a valid CreationLevelState object */
const creationLevelToCreationLevelState = (level: CreationLevel): CreationLevelState => ({
    title: level.title,
    description: level.description,
    image: { image: level.image ?? '' },
});

/**
 * Builds up all the necessary state for displaying a Creation Form, optionally taking in an existing
 * creation card to edit
 */
const useCreationFormState = (cardToUpdate?: Partial<ContentCard>) => {
    return useImmer<CreationState>({
        settings: {
            displayInNewsfeed: cardToUpdate?.library_settings?.displayInNewsfeed ?? true,
            rankOrder: cardToUpdate?.rankOrder ?? undefined,
        },
        main: {
            title: cardToUpdate?.title ?? 'New Creation',
            description: cardToUpdate?.description ?? '',
            thumbnail: { image: cardToUpdate?.media?.icon ?? '' },
            coverImage: { image: cardToUpdate?.media?.thumb ?? '' },
            silhouetteImage: { image: cardToUpdate?.creationItems?.silhouetteImage ?? '' },
            course: cardToUpdate?.creationItems?.course ?? undefined,
            levels:
                cardToUpdate?.creationItems?.levels.map(creationLevelToCreationLevelState) ??
                undefined,
        },
    });
};

export default useCreationFormState;
