import React, {MouseEventHandler, useContext} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import { useCurrentUser } from '@stores/User';
import {SidebarActionsContext} from '@web/ui/components/generic/context/SidebarContext';

import useNavigation from '@web/ui/components/navigation/useNavigation';

import {TYPE_OPTIMIZE} from '@web/ui/components/sidebar/sidebar_types';

const QuickLinkUserDescription: React.FC = () => {
    const { t } = useTranslation();

    const { closeSlideMenu } = useNavigation();
    const { showSidebar } = useContext(SidebarActionsContext);
      const { currentUser } = useCurrentUser();

    const handleOptimize: MouseEventHandler<HTMLButtonElement> = e => {
        e.preventDefault();
        showSidebar(TYPE_OPTIMIZE);
        closeSlideMenu();
    };

    return (
        <section className="side-menu-navigator-quick-link-description user-description">
            <h3>{currentUser?.profile?.full_name}</h3>
            <section>
                <Link
                    to={{ pathname: '/u/profile/info', state: { fromSlideMenu: true } }}
                    onClick={closeSlideMenu}
                >
                    {t('common:global.verbs.edit')}
                </Link>
                <span className="separator">•</span>
                <button type="button" onClick={handleOptimize}>
                    {t('common:imports.wlWeb.ui.components.card.tabs.optimize')}
                </button>
            </section>
        </section>
    );
};

export default QuickLinkUserDescription;
