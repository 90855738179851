import React from 'react';
import produce, { Draft } from 'immer';

import useModal from '@components/modals/hooks/useModal';

import Plus from '@dsc/svgs/FullSizePlus';
import CreationThumbDisplay from '@web/ui/components/lego/creation/CreationThumbDisplay';
import CreationLevelForm from '@components/content/newpost/forms/Creation/CreationLevelForm';

import { ModalOptions } from '@core/types/Modals';
import { CreationLevelState } from '@core/types/Creations';

type CreationCustomInputImageProps = {
    state: CreationLevelState[];
    onChange: (value: CreationLevelState[]) => void;
    background: string;
    title: string;

    /**
     * The labels displayed for each state value
     */
    labels: string[];

    /**
     * Options passed to `newModal` when opening the modal
     */
    modalOptions?: ModalOptions;

    label?: string;
    className?: string;
};

/**
 * This is a ✨ _magical_ ✨ component that allows you to take any form component that updates an
 * object and can redner in a modal, and make it work with arrays!
 *
 * The TS compiler is not _particularly_ good at specifying components with specific props as a
 * render prop, so you might find that errors get missed, but the basic usage is to pass a component
 * constructor that has at least the props state and onChange as the Component prop, and pass in any
 * extra props to that component through the componentProps prop.
 *
 * Here's an example:
 *
 * const TestForm: React.FC<{
 *     state: { value: string };
 *     onChange: (value: { value: string }) => void;
 *     disabled: boolean;
 * }> = ({ state, onChange, disabled }) => {
 *     const [text, setText] = useState(state.value);
 *
 *     const { closeModal } = useModal();
 *
 *     return (
 *         <form
 *             onSubmit={e => {
 *                 e.preventDefault();
 *                 onChange({ value: text });
 *                 closeModal();
 *             }}
 *         >
 *             <input disabled={disabled} value={text} onChange={e => setText(e.target.value)} />
 *             <button type="submit">Submit</button>
 *         </form>
 *     );
 * };
 *
 * const TestArrayForm = () => {
 *     const [state, setState] = useState([{ value: 'cool!' }]);
 *
 *     return (
 *         <MultiCustomInput
 *             labels={state.map(({ value }) => value)}
 *             state={state}
 *             onChange={values => setState(values)}
 *             defaultValue={{ value: 'default' }}
 *             Component={TestForm}
 *             componentProps={{ disabled: false }}
 *             label="Tests"
 *             buttonText="Add Test"
 *         />
 *     );
 * };
 */
const CreationCustomInputImage = ({
    labels,
    state,
    onChange,
    background,
    title,
    modalOptions,
    label = '',
    className = '',
}: CreationCustomInputImageProps) => {
    const { newModal } = useModal();

    const inputs = labels.map((inputLabel, index) => (
        <li key={index}>
            <p>{inputLabel}</p>

            <button
                type="button"
                onClick={() =>
                    newModal(
                        <CreationLevelForm
                            state={state[index]}
                            onChange={value => {
                                onChange(
                                    produce(state, draft => {
                                        draft[index] = value as Draft<CreationLevelState>;
                                    })
                                );
                            }}
                            background={background}
                            title={title}
                        />,
                        modalOptions
                    )
                }
            >
                <Plus />
            </button>

            {state[index].image?.image && (
                <div className="image-preview-upload">
                    <div className="creation-icon">
                        <CreationThumbDisplay
                            foreground={state[index].image.image}
                            background={background}
                            title={title}
                        />
                    </div>
                </div>
            )}
        </li>
    ));

    return (
        <section className={`dsc-forms-customInputs-multiCustomInput ${className}`}>
            <p>{label}</p>
            <ul>{inputs}</ul>
        </section>
    );
};

export default CreationCustomInputImage;
