/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEventHandler, useState } from 'react';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { QueryResult, useSubscription } from '@apollo/client';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import {
    GroupMembershipQuery,
    GroupMembershipQueryVariables,
    useSearchNetworkWithGroupRoleQuery,
} from '@shared/welibrary-graphql/user/queries.hook';
import { UPDATED_GROUP_MEMBERSHIP_SUBSCRIPTION } from '@shared/welibrary-graphql/user/subscriptions';
import { GET_GROUP_MEMBERSHIP } from '@shared/welibrary-graphql/user/queries';

import useBulkCSVUploader from '@components/group/groupdashboard/GroupMembers/hooks/useBulkCSVuploader';
import useGetCSV from '@components/group/groupdashboard/GroupMembers/hooks/useGetCSV';
import useInfiniteScroll from '@components/generic/hooks/useInfiniteScroll';
import { useShareLink } from '@components/generic/hooks/useShareLink';

import Tag from '@dsc/display/badges/Tag';
import ExternalLink from '@components/generic/ExternalLink';
import GroupMembersList from '@components/group/groupdashboard/GroupMembers/GroupMembersList';
import GroupAdminsList from '@components/group/groupdashboard/GroupMembers/GroupAdminsList';
import GroupMemberInvites from '@components/group/groupdashboard/GroupMembers/GroupMemberInvites';
import GroupMemberEmailInvites from '@components/group/groupdashboard/GroupMembers/GroupMemberEmailInvites';
import GroupMemberSearchResult from '@components/group/groupdashboard/GroupMembers/GroupMemberSearchResult';
import GroupMemberJoinRequests from '@components/group/groupdashboard/GroupMembers/GroupMemberJoinRequests';
import SearchLoader from '@components/search/SearchLoader';
import ModalLoading from '@components/modals/ModalLoading';
import HorizontalScrollMenu from '@components/generic/horizontalscrollmenu/HorizontalScrollMenu';
import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import GroupPreAuthPaymentMembers from '@components/group/groupdashboard/GroupMembers/GroupPreAuthPaymentMembers';

import isGroupSubscription from '@components/group/groupdashboard/groupDashboardHelpers';
import { getRootUrl } from '@core/utilities/whitelabel_helpers';
import { EMAIL_REGEX, getAllMatches } from '@core/utilities/constants/regexes';

import { config } from '@core/config/getConfig';

import { Group } from '@shared/welibrary-graphql/types';
import EventGroupGuestsList from '@components/group/groupdashboard/GroupMembers/EventGroupGuestsList';
import GiftedMembersList from './GiftedMembersList';

const GroupMembers: React.FC<{ group: Group }> = ({ group }) => {
    const { t } = useTranslation();
    const { shareLink } = useShareLink();

    const { settings, subtype } = group;
    const { shortCode } = settings || {};

    const isEvent = subtype === 'event';

    const localNamespace = isEvent
        ? 'imports.wlWeb.ui.components.group.eventDashboard'
        : 'imports.wlWeb.ui.components.group.groupDashboard';

    const [inputValue, setInput] = useState<string>('');
    const [tab, setTab] = useState<string>('members');
    const [hasMembershipUpdates, setHasMembershipUpdates] = useState<boolean>(false);
    const [networkSearchQuery, setNetworkSearchQuery] = useState<string>('');

    const handleSearchQueryDebounced = debounce(searchQuery => {
        // Build query based on passed fields
        setNetworkSearchQuery(searchQuery);
    }, 250);

    const root_url = getRootUrl();
    // {root_url}/signup-group/{shortCode} if the group has a shortCode
    // {root_url}/g/{group._id} if the group doesn't have a shortCode (e.g. event groups)
    //   http instead of https for localhost because https gives a security error when you try to go to the link
    const inviteLink = `${root_url?.startsWith('localhost') ? 'http' : 'https'}://${root_url}/${shortCode ? `signup-group/${shortCode}` : `g/${group._id}`
        }`;

    const handleLinkSharing = () => {
        shareLink(inviteLink, '', '', t(`common:${localNamespace}.link_copied_to_clipboard`));
    };

    const { loading: searchMembersLoading, data: searchMembersData } =
        useSearchNetworkWithGroupRoleQuery({
            variables: {
                searchQuery: networkSearchQuery,
                groupId: group?._id,
            },
            skip: !networkSearchQuery,
            fetchPolicy: 'network-only',
        });

    const nextPage = (
        queryResult: QueryResult<GroupMembershipQuery, GroupMembershipQueryVariables>,
        ref: Element | null
    ) => {
        if (queryResult?.fetchMore && ref) {
            // This is a workaround to selectively change the cursor being updated
            const isAdminQuery = ref.classList.contains('group-admin');
            const isMemberQuery = ref.classList.contains('group-member');
            const membersHasMore = queryResult?.data?.groupById?.members?.hasMore;
            const adminHasMore = queryResult?.data?.groupById?.owners?.hasMore;
            if (membersHasMore || adminHasMore) {
                let variables = {};

                if (isMemberQuery) {
                    variables = { membersCursor: queryResult?.data?.groupById?.members?.cursor };
                }
                if (isAdminQuery) {
                    variables = { ownersCursor: queryResult?.data?.groupById?.owners?.cursor };
                }

                queryResult?.fetchMore({
                    variables,
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return previousResult;

                        const newMembersResults = [
                            ...(previousResult.groupById?.members?.results ?? []),
                            ...(fetchMoreResult.groupById?.members?.results ?? []),
                        ];

                        const dedupedNewMembers = _.uniqBy(newMembersResults, item => item._id);

                        const newOwnersResults = [
                            ...(previousResult.groupById?.owners?.results ?? []),
                            ...(fetchMoreResult.groupById?.owners?.results ?? []),
                        ];

                        const dedupedNewOwners = _.uniqBy(newOwnersResults, item => item._id);

                        return produce(fetchMoreResult, result => {
                            const { members, owners } = result.groupById ?? {};

                            if (members) members.results = dedupedNewMembers;
                            if (owners) owners.results = dedupedNewOwners;
                        });
                    },
                });
            }
        }
    };

    const {
        queryResult: {
            data,
            refetch: _groupMembershipRefetch,
            loading: groupMembersLoading,
            networkStatus,
        },
        setRef,
    } = useInfiniteScroll<QueryResult<GroupMembershipQuery, GroupMembershipQueryVariables>>({
        query: {
            query: GET_GROUP_MEMBERSHIP,
            options: {
                variables: {
                    _id: group._id,
                    membersLimit: 10,
                    ownersLimit: 10,
                },
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                skip: !group?._id,
            },
        },
        customNextPage: nextPage,
    });

    const isUserAdminOfGroup = group.currentUserIsAdmin ? group.currentUserIsAdmin : false;
    const canUserInviteOthers = isUserAdminOfGroup;

    const groupOwners = data?.groupById?.owners;
    const groupMembers = data?.groupById?.members;
    const eventGuests = data?.groupById?.guests || [];
    const groupMemberRequests = data?.groupById?.memberRequests || [];
    const memberInvites = data?.groupById?.memberInvites || [];
    const emailInvites = data?.groupById?.emailInvites || [];
    const memberPreAuthPayments = data?.groupById?.preAuthPaymentMembers || [];
    const giftedSubscriptions = data?.groupById?.giftedSubscriptions || [];

    const membersCount = data?.groupById?.totalMembers || 0;
    const adminsCount = data?.groupById?.totalAdmins || 0;
    const guestsCount = data?.groupById?.totalGuests || 0;
    const giftedSubscriptionsCount = data?.groupById?.giftedSubscriptions?.length || 0;

    const memberPreAuthPaymentsCount = data?.groupById?.preAuthPaymentMembers?.length || 0;
    const memberRequestsCount = data?.groupById?.memberRequests?.length || 0;
    const memberInvitesCount = data?.groupById?.memberInvites?.length || 0;
    const emailInvitesCount = data?.groupById?.emailInvites?.length || 0;

    const modalLoadingText = t(`common:${localNamespace}.members_loading`);

    const groupMembershipRefetch = () => {
        _groupMembershipRefetch();
        setHasMembershipUpdates(false);
    };

    useSubscription(UPDATED_GROUP_MEMBERSHIP_SUBSCRIPTION, {
        variables: { groupId: group._id },
        onSubscriptionData: () => {
            setHasMembershipUpdates(true);
        },
    });

    const onNetworkSearchAction = (refetch = true) => {
        if (refetch) {
            groupMembershipRefetch();
        }

        if (networkSearchQuery) {
            setNetworkSearchQuery('');
        }

        if (inputValue) {
            setInput('');
        }
    };

    const { openBulkCSVUploadHandler, handleBulkInviteUsersByEmail } = useBulkCSVUploader(
        group?._id,
        () => onNetworkSearchAction(false),
        group
    );
    const { getCSVLoading, getCSVError, getCSVData, getAndDownloadCSV, fileUrl } = useGetCSV(group);

    const searchResults = searchMembersData?.listUsers || [];
    let searchProfileCards = searchResults
        .filter(user => canUserInviteOthers || user?.roleForGroup !== 'none') // Don't show non-members to invite if the current user isn't allowed to invite others
        .map((user, index) => {
            return (
                <GroupMemberSearchResult
                    key={`profile-cards-${index}`}
                    group={group}
                    member={user}
                    onUpdateUserState={(doRefetch = true) => onNetworkSearchAction(doRefetch)}
                />
            );
        });

    const emptyResults =
        !searchMembersLoading && _.isEmpty(searchProfileCards) && inputValue !== '';
    const emptyState = inputValue === '' && networkSearchQuery === '';
    const areResults = searchProfileCards && searchProfileCards.length > 0;
    const searchLoaderStyle = { position: 'relative', marginBottom: '20px' };

    const menuComponentsList = [
        {
            id: 'members',
            value: tab === 'members',
            onChange: () => setTab('members'),
            text: `${membersCount} ${membersCount === 1
                    ? t(`common:${localNamespace}.member`)
                    : t(`common:${localNamespace}.members`)
                }`,
        },
        {
            id: 'admin',
            value: tab === 'admins',
            onChange: () => setTab('admins'),
            text: `${adminsCount} ${adminsCount === 1
                    ? t(`common:${localNamespace}.admin`)
                    : t(`common:${localNamespace}.admins`)
                }`,
        },
    ];

    if (canUserInviteOthers) {
        let memberRequestText;
        if (isEvent) {
            menuComponentsList.push({
                id: 'guests',
                value: tab === 'guests',
                onChange: () => setTab('guests'),
                text: `${guestsCount} ${guestsCount === 1
                        ? t(`common:${localNamespace}.guest`, 'Guest')
                        : t(`common:${localNamespace}.guests`, 'Guests')
                    }`,
            });

            memberRequestText =
                memberRequestsCount === 1
                    ? t(`common:${localNamespace}.request_to_attend`, 'Request to Attend')
                    : t(`common:${localNamespace}.requests_to_attend`, 'Requests to Attend');
        } else {
            memberRequestText =
                memberRequestsCount === 1
                    ? t(`common:${localNamespace}.request_to_join`, 'Request to Join')
                    : t(`common:${localNamespace}.join_requests`, 'Join Requests');
        }

        if (!isEvent && giftedSubscriptionsCount > 0) {
            menuComponentsList.push({
                id: 'subscription-freebies',
                value: tab === 'subscription-freebies',
                onChange: () => setTab('subscription-freebies'),
                text: `${giftedSubscriptionsCount} ${
                    giftedSubscriptionsCount === 1
                        ? t(`common:${localNamespace}.gifted_subscription`, 'Gifted Subscription')
                        : t(`common:${localNamespace}.gifted_subscriptions`, 'Gifted Subscriptions')
                }`,
            });
        }

        menuComponentsList.push({
            id: 'member-requests',
            value: tab === 'member-requests',
            onChange: () => setTab('member-requests'),
            text: `${memberRequestsCount} ${memberRequestText}`,
        });

        // if (isGroupSubscription(group) && isUserAdminOfGroup) {
        //     menuComponentsList.push({
        //         id: 'member-preAuthPayments',
        //         value: tab === 'member-preAuthPayments',
        //         onChange: () => setTab('member-preAuthPayments'),
        //         text: `${memberPreAuthPaymentsCount} Pre Authorized Payments`,
        //     });
        // }

        menuComponentsList.push(
            {
                id: 'member-invites',
                value: tab === 'member-invites',
                onChange: () => setTab('member-invites'),
                text: `${memberInvitesCount} ${memberInvitesCount === 1
                        ? t(`common:${localNamespace}.invite_sent`)
                        : t(`common:${localNamespace}.invites_sent`)
                    }`,
            },
            {
                id: 'email-invites',
                value: tab === 'email-invites',
                onChange: () => setTab('email-invites'),
                text: `${emailInvitesCount} ${emailInvitesCount === 1
                        ? t(`common:${localNamespace}.email_invite_sent`)
                        : t(`common:${localNamespace}.email_invites_sent`)
                    }`,
            }
        );
    }

    if (networkSearchQuery) {
        const emailMatches = getAllMatches(networkSearchQuery, EMAIL_REGEX, 0);
        let emailCount = 0;
        const emailLinks = emailMatches.map((email, index) => {
            emailCount += 1;
            return (
                <span key={index}>
                    <span className="highlighted">{email}</span>
                    {emailCount < emailMatches.length ? ', ' : ''}
                </span>
            );
        });
        if (emailLinks.length > 0 && canUserInviteOthers) {
            searchProfileCards = [
                <>
                    <div
                        className="group-membership-noresults invite"
                        onClick={() => handleBulkInviteUsersByEmail(emailMatches)}
                    >
                        {t(`common:${localNamespace}.invite`, 'Invite')} {emailLinks}
                    </div>
                    {config.public.bulkUserTemplate ? (
                        <div className="bulk-upload" onClick={openBulkCSVUploadHandler}>
                            {t(`common:${localNamespace}.bulk_upload`, 'Bulk Upload?')}
                            <ExternalLink to={config.public.bulkUserTemplate}>
                                {t(
                                    `common:${localNamespace}.download_template`,
                                    'Download Template.'
                                )}
                            </ExternalLink>
                        </div>
                    ) : null}
                </>,
                ...searchProfileCards,
            ];
        } else if (!areResults) {
            searchProfileCards.push(
                <SearchLoader
                    customStyle={searchLoaderStyle}
                    emptyResults={emptyResults}
                    size={180}
                    emptyState={emptyState}
                    areResults={areResults}
                    loading={searchMembersLoading}
                />
            );
        }
    }

    let membersList: React.ReactNode;
    if (tab === 'members') {
        membersList = (
            <GroupMembersList
                ref={setRef}
                group={group}
                groupMembers={groupMembers}
                onNetworkSearchAction={onNetworkSearchAction}
                canUserInviteOthers={canUserInviteOthers}
                handleLinkSharing={handleLinkSharing}
            />
        );
    } else if (tab === 'admins') {
        membersList = (
            <GroupAdminsList
                ref={setRef}
                group={group}
                groupOwners={groupOwners}
                onNetworkSearchAction={onNetworkSearchAction}
                canUserInviteOthers={canUserInviteOthers}
                handleLinkSharing={handleLinkSharing}
            />
        );
    } else if (tab === 'guests') {
        membersList = <EventGroupGuestsList group={group} eventGuests={eventGuests} />;
    }
    if (tab === 'subscription-freebies') {
        membersList = (
            <GiftedMembersList
                ref={setRef}
                group={group}
                groupMembers={{
                    results: giftedSubscriptions,
                }}
                onNetworkSearchAction={onNetworkSearchAction}
                canUserInviteOthers={canUserInviteOthers}
                handleLinkSharing={handleLinkSharing}
            />
        );
    } else if (tab === 'member-requests') {
        membersList = (
            <GroupMemberJoinRequests
                group={group}
                memberInvites={groupMemberRequests}
                onNetworkSearchAction={onNetworkSearchAction}
                canUserInviteOthers={canUserInviteOthers}
                handleLinkSharing={handleLinkSharing}
            />
        );
    } else if (tab === 'member-preAuthPayments') {
        membersList = (
            <GroupPreAuthPaymentMembers
                group={group}
                memberPreAuthPayments={memberPreAuthPayments}
                onNetworkSearchAction={onNetworkSearchAction}
                canUserInviteOthers={canUserInviteOthers}
                handleLinkSharing={handleLinkSharing}
            />
        );
    } else if (tab === 'member-invites') {
        membersList = (
            <GroupMemberInvites
                group={group}
                memberInvites={memberInvites}
                onNetworkSearchAction={onNetworkSearchAction}
                canUserInviteOthers={canUserInviteOthers}
                handleLinkSharing={handleLinkSharing}
            />
        );
    } else if (tab === 'email-invites') {
        if (group?.currentUserIsAdmin || group?.currentUserIsDirectAdmin) {
            membersList = (
                <GroupMemberEmailInvites
                    group={group}
                    memberInvites={emailInvites}
                    onNetworkSearchAction={onNetworkSearchAction}
                    canUserInviteOthers={canUserInviteOthers}
                    handleLinkSharing={handleLinkSharing}
                />
            );
        } else {
            membersList = (
                <div>
                    {t(
                        `common:${localNamespace}.admin_only_email`,
                        'Only Admins can view email invites.'
                    )}
                </div>
            );
        }
    }

    const handleSearchUsersQueryChange: ChangeEventHandler<HTMLInputElement> = e => {
        const { value } = e.target;
        setInput(value);
        handleSearchQueryDebounced(value);
    };

    return (
        <ErrorBoundary>
            <section className="group-members-form">
                <header>
                    {t(`common:${localNamespace}.group_members`)}
                    <div className="group-members-header-links">
                        {isUserAdminOfGroup && (
                            <div className="group-members-csv">
                                {getCSVError && (
                                    <button
                                        type="button"
                                        className="signup-form-export-button"
                                        onClick={() => getAndDownloadCSV()}
                                    >
                                        {t(`common:there_was_an_error`, 'There was an error')}
                                    </button>
                                )}
                                {!getCSVError && getCSVLoading && (
                                    <button type="button" className="signup-form-export-button">
                                        {t(
                                            `common:${localNamespace}.generating_csv`,
                                            'Generating CSV...'
                                        )}
                                    </button>
                                )}
                                {!getCSVData && !getCSVLoading && !getCSVError && (
                                    <button
                                        type="button"
                                        className="signup-form-export-button"
                                        onClick={() => getAndDownloadCSV()}
                                    >
                                        {t(
                                            `common:${localNamespace}.generate_csv`,
                                            'Generate CSV of Members'
                                        )}
                                    </button>
                                )}
                                {getCSVData &&
                                    !getCSVLoading &&
                                    !getCSVError &&
                                    fileUrl &&
                                    !getCSVError && (
                                        <button
                                            type="button"
                                            className="signup-form-export-button"
                                            onClick={() => getAndDownloadCSV()}
                                        >
                                            {t(
                                                `common:${localNamespace}.download_csv`,
                                                'Download CSV of Members'
                                            )}
                                        </button>
                                    )}
                            </div>
                        )}

                        {canUserInviteOthers && (
                            <div className="group-members-invite-link-wrap">
                                <a onClick={handleLinkSharing}>
                                    <strong>{t(`common:${localNamespace}.get_invite_link`)}</strong>
                                </a>
                            </div>
                        )}
                    </div>
                </header>

                {canUserInviteOthers && (
                    <form className="group-members-form-input" onSubmit={e => e?.preventDefault()}>
                        <input
                            autoComplete="off"
                            type="text"
                            name="field"
                            data-name="Field"
                            placeholder={t(
                                `common:${localNamespace}.search_and_add_members`,
                                'Search by name or invite by email...'
                            )}
                            onChange={e => handleSearchUsersQueryChange(e)}
                            value={inputValue}
                            onClick={e => e.stopPropagation()}
                        />
                    </form>
                )}

                {!canUserInviteOthers && (
                    <form className="group-members-form-input" onSubmit={e => e?.preventDefault()}>
                        <input
                            autoComplete="off"
                            type="text"
                            maxLength={256}
                            name="field"
                            data-name="Field"
                            placeholder={t(
                                `common:${localNamespace}.search_members`,
                                'Find a member...'
                            )}
                            onChange={e => handleSearchUsersQueryChange(e)}
                            value={inputValue}
                            onClick={e => e.stopPropagation()}
                        />
                    </form>
                )}

                <section className="horizontal-menu" style={{ width: '100%' }}>
                    <HorizontalScrollMenu>
                        {menuComponentsList?.map(item => (
                            <Tag
                                value={item?.value}
                                onChange={item?.onChange}
                                text={item?.text}
                                key={item?.id}
                                id={item?.id}
                                itemId={item?.id}
                            />
                        ))}
                    </HorizontalScrollMenu>
                </section>

                {/* route back to members tab || admins tab */}
                {/* {hasMembershipUpdates && searchProfileCards.length === 0 && (
                <span
                    className="group-dashboard-alert-text"
                    onClick={() => groupMembershipRefetch()}
                >
                    {' '}
                    • Click to See New Members •
                </span>
            )} */}

                {searchMembersLoading && (
                    // in a <ul> so that it's positioned the same as the "No Results" Lottie
                    <ul>
                        <SearchLoader
                            customStyle={searchLoaderStyle}
                            emptyResults={emptyResults}
                            size={180}
                            emptyState={emptyState}
                            areResults={areResults}
                            loading
                        />
                    </ul>
                )}

                {groupMembersLoading && !searchMembersLoading && networkStatus !== 3 && (
                    <ModalLoading message={modalLoadingText} />
                )}

                {!searchMembersLoading && searchProfileCards.length > 0 && (
                    <ul>{searchProfileCards}</ul>
                )}

                {networkStatus !== 1 &&
                    membersList &&
                    searchProfileCards.length === 0 &&
                    membersList}
            </section>
        </ErrorBoundary>
    );
};

export default GroupMembers;
