import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import useScreenWidth from '@components/generic/hooks/useScreenWidth';

import shoppingCartStore from '@web/ui/stores/ShoppingCart';

import Ticket from '@dsc/svgs/Ticket';
import IncrementStepper from '@dsc/forms/customInputs/IncrementStepper';

import {
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import { getCurrencyDisplayString } from '@web/utilities/helpers/money/money.helpers';

import { Group, Product } from '@shared/welibrary-graphql/types';

type SelectTicketsTicketItemProps = {
    group: Group;
    product: Product;
};

const SelectTicketsTicketItem: React.FC<SelectTicketsTicketItemProps> = ({ group, product }) => {
    const { t } = useTranslation();

    const shoppingCartProducts =
        shoppingCartStore?.useTracked?.shoppingCarts()?.find(({ groupId }) => {
            return groupId === group?._id;
        })?.products ?? [];

    const shoppingCartProduct = _.find(
        shoppingCartProducts,
        ({ product: p }) => p?._id === product?._id
    );

    const [coverSrc, coverSrcSet] = getBackgroundImageSetForUrl(
        group?.profile?.coverPhoto || getRandomDefaultCoverImage(group?.profile?.full_name ?? '')
    );

    const priceDisplay = getCurrencyDisplayString(
        product.price,
        product.currency,
        undefined,
        false,
        2
    );

    const windowWidth = useScreenWidth();
    const isMobileSize = windowWidth <= 767;

    return (
        <div className="shadow-around relative mx-[3px] flex min-h-[111px] rounded-[10px] p-[3px]">
            <div className="relative flex w-[100px] shrink-0 items-center justify-center md:w-[140px]">
                <StyledBackgroundImage
                    src={coverSrc}
                    srcSet={coverSrcSet}
                    className="absolute h-full w-full rounded-[10px] bg-cover bg-center bg-no-repeat"
                />
                <div className="bg-primary-default z-20 rounded-full p-[11px]">
                    <Ticket className="h-[25px] w-[25px]" strokeColor="#fff" />
                </div>
            </div>

            <div className="flex w-full flex-col gap-[10px] px-[10px] py-[7px]">
                <div className="text-[17px] font-[700] leading-[24px] tracking-[0.75px] text-black">
                    {product.name}
                </div>

                {product.description && (
                    <div className="text-grayscale-body text-[12px] leading-[17px] tracking-[0.25px]">
                        {product.description}
                    </div>
                )}

                <div className="mt-auto flex items-center gap-[5px]">
                    <span className="text-[17px] font-[700] text-black">{priceDisplay}</span>
                    <span className="text-grayscale-label text-[8px] font-[600] uppercase leading-[250%]">
                        /{t(`common:global.ticket`, 'Ticket')}
                    </span>

                    {!isMobileSize && (
                        <IncrementStepper
                            className="checkout-increment-stepper ml-auto"
                            placeholder="qty"
                            value={shoppingCartProduct?.quantity ?? 0}
                            onChange={value => {
                                shoppingCartStore?.set?.setShoppingCartItem(
                                    group?._id,
                                    product,
                                    value
                                );
                            }}
                            min={0}
                        />
                    )}
                </div>

                {isMobileSize && (
                    <IncrementStepper
                        className="checkout-increment-stepper"
                        placeholder="qty"
                        value={shoppingCartProduct?.quantity ?? 0}
                        onChange={value => {
                            shoppingCartStore?.set?.setShoppingCartItem(group?._id, product, value);
                        }}
                        min={0}
                    />
                )}
            </div>
        </div>
    );
};

export default SelectTicketsTicketItem;
