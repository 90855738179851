import React from 'react';

const UserConnected: React.FC<{ className?: string; strokeWidth?: string }> = ({
    className,
    strokeWidth = '1',
}) => (
    <svg fill="none" className={className} strokeWidth={strokeWidth} viewBox="0 0 21 21">
        <path
            d="M14.3335 4.10461L16.2191 5.99023L19.9904 2.219"
            stroke="currentcolor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
        />
        <path
            d="M2.28638 17.5762C2.99567 16.3485 4.01557 15.3291 5.2436 14.6203C6.47163 13.9116 7.86456 13.5384 9.28244 13.5385C10.7003 13.5385 12.0932 13.9116 13.3213 14.6204C14.5493 15.3292 15.5692 16.3486 16.2784 17.5764"
            stroke="currentcolor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.6128 10.5228C13.3043 11.3558 12.7619 12.082 12.0507 12.6142C11.3394 13.1464 10.4897 13.4619 9.60349 13.5228C8.7173 13.5837 7.83236 13.3875 7.05498 12.9577C6.27761 12.5279 5.64086 11.8828 5.22124 11.0999C4.80162 10.3169 4.61696 9.42952 4.68947 8.5442C4.76197 7.65888 5.08854 6.81332 5.62995 6.1091C6.17136 5.40488 6.90457 4.87196 7.74152 4.57435C8.57846 4.27674 9.48354 4.2271 10.348 4.43141"
            stroke="currentcolor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default UserConnected;
