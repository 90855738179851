import {useLocation} from 'react-router-dom';

import useNavigation from '@web/ui/components/navigation/useNavigation';

const useNavHighlighting = ({
    url = '',
    groupSwitch = false,
    sideMenu = false,
    activeClassName = 'active',
} = {}) => {
    const { pathname } = useLocation();
    const { isSlideMenuOpen, isGroupSwitchOpen } = useNavigation();

    // highlight group switch button
    if (groupSwitch) return isGroupSwitchOpen ? activeClassName : '';

    // highlight side menu button
    if (sideMenu) return isSlideMenuOpen ? activeClassName : '';

    // don't use route-based highlights when group switch or side menu are open
    if (isGroupSwitchOpen || isSlideMenuOpen) return '';

    // route-based highlighting
    return pathname === url ? activeClassName : '';
};

export default useNavHighlighting;
