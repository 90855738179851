import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary';
import { SidebarActionsContext } from '@components/generic/context/SidebarContext';

import EventItemRSVPButton from '@components/card/mediacard/bodyitems/EventItemRSVPButton';
import CalendarDateDisplay from '@components/events/CalendarDateDisplay';
import EventTimeContext from '@components/events/EventTimeContext';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import { getFormattedDateDisplayFromTimes } from '@core/utilities/constants/events';

import { isEventInThePast, isEventInProgress } from '@helpers/events/event.helpers';

import { TYPE_EVENT_OLD } from '@components/sidebar/sidebar_types';
import { ContentCard, EventRsvp } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.card.mediaCard.bodyItems.eventItem';

type EventItemProps = {
    card: ContentCard;
};

// Figma: https://www.figma.com/file/FCupshqkHUmzpemYOhtcs4/Events?node-id=2114%3A9175
const EventItem: React.FC<EventItemProps> = ({ card }) => {
    const { t } = useTranslation();
    const { showSidebar } = useContext(SidebarActionsContext);

    // Eventsettings are for backwards compatibility - new event list cards will use timeAndPlaceSettings
    let { eventSettings } = card;
    const { eventOverviewDetails } = card;
    eventSettings = eventOverviewDetails;

    const eventIsOver = isEventInThePast(card);
    const eventIsInProgress = isEventInProgress(card);

    const datesDisplay = getFormattedDateDisplayFromTimes(eventOverviewDetails);

    const dates = eventOverviewDetails?.dates;
    const firstEvent = dates ? dates[0] : eventOverviewDetails?.date || {};

    const [src, srcSet] = getBackgroundImageSetForUrl(
        card?.media?.thumb ? card?.media?.thumb : getRandomDefaultCoverImage(card?.title ?? '')
    );

    const handleShowFull = () => {
        const options = {
            eventCard: card,
            eventData: eventSettings,
            eventImage: src,

            initialView: 'details',
        };
        showSidebar(TYPE_EVENT_OLD, null, null, 'shrink', options);
    };

    const numAttendees = card.eventAttendance?.filter(
        attendee => attendee?.status === EventRsvp.Going
    ).length;

    const attendeesText =
        numAttendees === 1
            ? `${numAttendees} ${t(`common:${localNamespace}.attendee`, 'Attendee')}`
            : `${numAttendees} ${t(`common:${localNamespace}.attendees`, 'Attendees')}`;

    return (
        <ErrorBoundary>
            <div className="mc-media-item galleryitem event-listing">
                <div className="mc-media-item-bg blog-bg">
                    <StyledBackgroundImage
                        className="blog-card-header-image"
                        src={src}
                        srcSet={srcSet}
                    />
                </div>
                <div className="mc-media-item-content content-card-detail-header">
                    <div className="blog-card-summary-container">
                        <div className="conent-card-detail-date">{eventSettings?.headline}</div>
                        <div className="conent-card-media-title">{card.title}</div>
                        <div className="blog-card-left-container">
                            <div className="w-inline-block">
                                <div className="blog-card-author-container">
                                    <div className="event-card-icon-container">
                                        <CalendarDateDisplay
                                            date={firstEvent?.date}
                                            dates={dates}
                                        />

                                        <div className="conent-card-detail-date event-card-date">
                                            <EventTimeContext
                                                eventIsOver={eventIsOver}
                                                eventIsInProgress={eventIsInProgress}
                                                includeSeparator={false}
                                                className="event-listing-time-context"
                                            />
                                            <span>
                                                <strong>{datesDisplay}</strong>
                                            </span>
                                            {numAttendees > 0 && (
                                                <span className="attendee-count">
                                                    {attendeesText}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="blog-card-read-more-arrow" />
                            </div>
                        </div>
                    </div>
                    <div className="event-card-footer event-action-buttons">
                        <button
                            type="button"
                            title={t(
                                `common:${localNamespace}.view_full_posting`,
                                'View full posting'
                            )}
                            onClick={handleShowFull}
                            className="event-details-button button event-card-zindex"
                        >
                            {t(`common:${localNamespace}.details`, 'Details')}
                        </button>
                        {!eventIsOver && !eventIsInProgress && <EventItemRSVPButton card={card} />}
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default EventItem;
