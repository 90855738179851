import React, { useEffect, useState } from 'react';
import { BarcodeScanner, BarcodeFormat, LensFacing } from '@capacitor-mlkit/barcode-scanning';
import { Capacitor } from '@capacitor/core';

import QRCodeScannerStore from '@stores/QRCodeScannerStore';
import TicketCheckInModal from './TicketCheckInModal/TicketCheckInModal';

import useModal from '@components/modals/hooks/useModal';

export const QRCodeScannerListener: React.FC = () => {
    const { newModal } = useModal();

    const showScanner = QRCodeScannerStore.useTracked.showScanner();
    const activeGroupId = QRCodeScannerStore.useTracked.activeGroupId();

    const handleStartScanning = async () => {
        return new Promise(async resolve => {
            const listener = await BarcodeScanner?.addListener('barcodeScanned', async result => {
                await listener.remove();
                await BarcodeScanner.stopScan();
                resolve(result.barcode);
            });

            await BarcodeScanner.startScan({
                formats: [BarcodeFormat.QrCode],
                lensFacing: LensFacing.Back,
            });
        });
    };

    const handleScan = async (qrCodeValue: string) => {
        await handleCancelScanning();

        try {
            if (qrCodeValue) {
                await handleCancelScanning();

                const query = new URLSearchParams(qrCodeValue);

                const ticketID = query.get('ticketId');

                newModal(<TicketCheckInModal ticketId={ticketID} groupId={activeGroupId} />);
            }
        } catch (error) {
            console.log('❌❌ scanner::error ❌❌', error);
            await handleCancelScanning();
        }
    };

    const handleCancelScanning = async () => {
        document?.querySelector('#app-router')?.classList?.remove('scanner-active');
        QRCodeScannerStore.set.showScanner(false);

        // Remove all listeners
        await BarcodeScanner?.removeAllListeners();

        // Stop the barcode scanner
        await BarcodeScanner?.stopScan();
    };

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            if (showScanner) {
                handleStartScanning()
                    .then(async res => {
                        console.log('scan::success', res);
                        await handleScan(res?.rawValue);
                    })
                    .catch(async error => {
                        console.log('scan::error', error);
                        await handleCancelScanning();
                    });
            } else if (!showScanner) {
                handleCancelScanning();
            }
        }
    }, [showScanner]);

    return <></>;
};

export default QRCodeScannerListener;
