import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from '@web/ui/components/modals/hooks/useModal';
import useNavigation from '@web/ui/components/navigation/useNavigation';

import { isGroupSubscription } from '@components/group/groupdashboard/groupDashboardHelpers';

import ChannelsCard from '@web/ui/components/group/groupdashboard/channelsCard/ChannelsCard';

// todo extract group book logic out of this so it more of a dumb display component
const ChannelsButton = ({ group, currentUser, customClassName }) => {
    const { t } = useTranslation();

    const isEventGroup = group?.subtype === 'event';
    const isSubscriptionGroup = isGroupSubscription(group);
    const isAdmin = group?.currentUserIsAdmin || group?.currentUserIsDirectAdmin;
    const isMember = group?.currentUserIsMember || group?.currentUserOwnsMembership;

    const totalChannels =
        (isEventGroup
            ? group?.eventBook?.children?.length
            : group?.channelsBook?.children?.length) || 0;
    const channelBook = isEventGroup ? group?.eventBook : group?.channelsBook;

    const { setChannelOpenState } = useNavigation();
    const { newModal } = useModal();

    const handleClick = e => {
        e.stopPropagation();
        setChannelOpenState(true);
        newModal(
            <ChannelsCard dashboardGroup={group} currentUser={currentUser} book={channelBook} />,
            {
                className: 'no-top-padding white-notch',
                widen: true,
                addShadow: true,
            }
        );
    };

    const totalChannelsText =
        totalChannels === 1 ? t(`common:global.nouns.channel`) : t(`common:global.nouns.channels`);

    if (!channelBook || totalChannels === 0) return <></>;
    if ((isEventGroup || isSubscriptionGroup) && !isAdmin && !isMember) return <></>;

    return (
        <button
            type="button"
            id="home-header-switcher"
            className={`channel-switch ${customClassName}`}
            onClick={e => {
                handleClick(e);
            }}
        >
            {totalChannels} {totalChannelsText}
        </button>
    );
};

export default ChannelsButton;
