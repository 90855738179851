import { ConfigInputType } from '@config/schemas/main';
import EventTypes from '@core/utilities/constants/events';

const config: ConfigInputType = {
    public: {
        root_url: 'staging.commissioned.global',
        apiUrl: 'staging.becommissioned.com',
        deep_link_domains: ['https://staging.commissioned.global'],

        configName: 'staging-commissioned',
        captcha: {
            key: '6Lc8MlUiAAAAAC5sMITt02hJ_bKRE4OzhnhSiufh',
        },
        displayPlatformFormspec: false,
        copyright: 'Commissioned, LLC',
        eventTypes: [EventTypes.EVENT_GROUP, EventTypes.EVENT_LISTING],
        siteName: 'Commissioned - Staging',
        metaInfo: {
            title: 'Commissioned - Staging',
            description: 'Communication, Intelligence and Mobilization for the Great Commission.',
            image: '/images/commissioned.logoblack.full.svg',
            url: 'staging.commissioned.global',
        },

        rootGroupId: 'HWRY9fxMab6EiPxuz',

        filestack: { api_key: 'A7RsW3VzfSNO2TCsFJ6Eiz' },
        unsplash: { access_key: 'NTNacJWbx9pMW5yHizHSqWr1IpQXmTJTuQ08VZ7pSEg' },
        Google: { Maps_API_Key: 'AIzaSyBDfzEYNC2sHOYi9OEwmwNVVtOMDFfUV5I' },
        sentry: {
            dsn: 'https://bd4f25c0ac1c4278b822ee7b34fdb036@o246842.ingest.sentry.io/5199016',
        },
        userflow: { api_key: 'ct_a4o665523nbjdgnwvidhtxrdma' },
        showGettingStarted: true,
        stripe: {
            client_api_key:
                'pk_test_51JIeveETSMEXfAr36sfHnWZ67ojsuzxi4m2emc9sg56SkIc5B7g0Mglu3lbJTAIOpHFMMweXej8MkZhKwLYYYpCR00b5CDuTat',
        },

        defaultMembershipUrl: 'welibrary',
        defaultJoinInstructions: 'commissioned_join_instructions',
        defaultGroupCodePlaceholder: 'commissioned_shortcode_placeholder',
        defaultGroupCode: 'COMMISSIONED',
        hideGroupHeaderInfoFromLoggedOutUsers: true,
        homeButton: {
            picture: 'https://cdn.filestackcontent.com/resize=width:600/DinxN842TZy1UPoowYYe',
            title: 'Commissioned',
            groupId: 'HWRY9fxMab6EiPxuz',
        },

        disableInlinePrefilledSignupLink: true,
        restrictSignupToDefaultMembership: true,

        userGuide: 'support.commissioned.global/home/',

        communityGuidelines: 'commissioned.global/community-guidelines',
        privacyPolicy: 'commissioned.global/privacy-policy',
        termsOfService: 'commissioned.global/terms-of-service',
        templatesUrl: 'templates',

        defaultLogoBackup: 'https://cdn.filestackcontent.com/0bohNBYrSBeJyDQN3f2J',
        defaultLogoBackupWhite: 'https://cdn.filestackcontent.com/0bohNBYrSBeJyDQN3f2J',
        defaultLogoBackupWhiteAlt: 'https://cdn.filestackcontent.com/Xfr6Jiz2Q36YMuFJddBh',
        defaultLogoBlack: 'https://cdn.filestackcontent.com/iXspwqTOBrG1xaB6kgYE',

        pushSenderID: 541036629111,
        pushLogo: 'co_notification',

        recommendationsMatchmakerWhitelist: ['welibrary'],

        userSnap: false,
        userSnapOverrideSrc: '//api.usersnap.com/load/81dfe074-2710-40d9-b130-d0c9be1c2c30.js',
        userSnapTesterEmails: [
            'jkramer@pneuma33.com',
            'jkramer@commissioned.io',
            'sthomas@pneuma33.com',
            'mriddell@commissioned.io',
            'michelle43770@yahoo.com',
            'kmonaghan@commissioned.io',
        ],

        bulkUserTemplate:
            'https://docs.google.com/spreadsheets/d/1kkqURN4VHJuj6KZVMMSRRuWGdV_KvdWz5BeoYj9q1SQ/edit?usp=sharing',
        bulkGenerateUserTemplate:
            'https://docs.google.com/spreadsheets/d/1LSN6MLgMmyHToHGpA4EEHZFtGPjLWxn3GsmbgM71Rdw/edit?usp=sharing',

        brandColor1: '#000000',
        brandColor2: '#d29e0e',

        theme: {
            primaryColor: '#00AF90',
            primaryAccent: '#cff2ee',
            primaryHighlight: '#00AF90',
            primaryShade: '#007a65',
            thumbHighlight: '#00AF90',
            secondaryColor: '#0070BF',
            hoverColor: '#008E76',
            slideBackground:
                'url(https://cdn.filestackcontent.com/auto_image/1Wjcy7OYQCqn7b6brH4Z)',
            emptyBackground:
                'url(https://cdn.filestackcontent.com/auto_image/rZZ11ay3TYCJZNyJ1dWq)',
            coffeeBackground:
                'url(https://cdn.filestackcontent.com/auto_image/inBO4fCkSxWq6ph1lbtw)',
            docBackground: 'url(https://cdn.filestackcontent.com/auto_image/Efr6ADNQQrJsAKtcUFZb)',
        },

        companyAddress: '14606 Floyd Street, Overland Park, KS 66223',

        pledge: {
            new_recruit_template: 'd-61eb02daf35440d59672a2acbea1a688',
            new_recruit_sms_override:
                'Congratulations on your decision to follow Jesus. To grow stronger go to https://comm.gl/frstgr. Join https://comm.gl/omwnew to grow in this faith adventure!',
            failed_recruit_template: 'd-beedc18c284c43789a2e010ceffde9e8',
            failed_recruit_sms_override:
                'Hello. Thanks for letting me share about my faith-story. I thought you might be interested in checking out my website www.frostygrapes.com. Enjoy!',
            already_recruit_template: 'd-5402723fa68b406eb9b95e9dd8504bbf',
            already_recruit_sms_override:
                'Hello, I’m glad that you’re a follower of Jesus. Learn how to tell your faith-story with pre-believers https://comm.gl/omw. You will have great joy sharing!',
            new_recruit_claim_reminder_template: 'd-4fdcddd98bc74803959ad4a51fe02b77',
            new_recruit_claim_reminder_two_template: 'd-081324f4d43048c9bfdb9e2b4196d771',
        },

        allowAnonymousSignups: false,
        hidePlatformGroupHeaderDisplay: true,

        domainBranding: {
            localhost: {
                root_url: 'staging.commissioned.global',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/l2kemkFTqqx9LIxCElgQ',
                defaultGroupCode: 'COMMISSIONED',
                siteName: 'Commissioned',
            },
            commissioned: {
                root_url: 'staging.commissioned.global',
                defaultCoverPhoto: 'https://cdn.filestackcontent.com/l2kemkFTqqx9LIxCElgQ',
                defaultGroupCode: 'COMMISSIONED',
                siteName: 'Commissioned',
            },
        },

        slideMenu: {
            actionItems: true,
            mobilization: true,
            search: true,
            discover: true,
            footerText: 'common:global.patent_pending',
            showConnectionsIcon: true,
            showBookmarkIcon: true,
        },

        secondaryNavigation: {
            items: {
                mobile: ['navigator', 'groups', 'home', 'messages', 'discover'],
                desktop: ['groups', 'home', 'messages', 'discover', 'notifications', 'profile'],
            },
        },

        locize: {
            projectId: '662a9e61-2900-409c-a73e-6ba0bbfc3f38',
            apiKey: '3ff8b824-d205-42ea-be99-a366868d9f73',
            referenceLng: 'en',
        },

        ceramic: {
            nodeEndpointUrl: 'https://ceramic-node.staging.welibrary.io:7007',
            contentFamily: 'SuperSkills-local',
            definitions: {
                MyVerifiableCredentials:
                    'kjzl6cwe1jw1461695j0dmtvjmtcjhqdtx63calspn0r5p2ti110pzbkboabozr',
            },
            schemas: {
                AchievementVerifiableCredential:
                    'ceramic://k3y52l7qbv1frybe3lenawavapwpg38ut3enaqrw03s8nemi1ycq6lvsg1nifb37k',
                VerifiableCredentialsList:
                    'ceramic://k3y52l7qbv1fryp70yk4y1xv2aedxjnkrbdezyot13gnjkyrdn6lhs1k5irkosl4w',
            },
        },

        appflow: {
            liveUpdate: {
                appId: '78ad487c',
                channel: 'staging-commissioned',
                updateMethod: 'auto',
            },
        },

        eventOrganizerTermsOfUseUrl:
            'https://www.commissioned.global/event-organizer-terms-of-use/',
        ticketPurchaserTermsOfUseUrl:
            'https://www.commissioned.global/ticket-purchaser-terms-of-use/',

        launchDarklyConfig: {
            clientSideID: '6453e216ed06ad12d0c44dd0',
            context: {
                kind: 'user',
                key: 'anonymous',
            },
        },
    },
};

export default config;
