import React from 'react';
import {useTranslation} from 'react-i18next';
import useModal from '@web/ui/components/modals/hooks/useModal';
import Plus from '@web/ui/dsc/svgs/FullSizePlus';
import GroupCreateChannel from '@web/ui/components/group/groupdashboard/GroupCreateChannel';

const localNamespace = 'imports.wlWeb.ui.components.chapter.createChapter';

const CreateChannel = ({ bookURL, placeholder }) => {
    const { t } = useTranslation();
    const { newModal } = useModal();

    return (
        <button
            type="button"
            className="new-channel"
            onClick={() => newModal(<GroupCreateChannel bookURL={bookURL} />)}
        >
            <Plus strokeWidth="3" />
            <span>{placeholder ?? `${t(`common:${localNamespace}.new_chapter`)}`}</span>
        </button>
    );
};

export default CreateChannel;
