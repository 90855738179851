import { Resource, Step, SurveyQuestion } from '@shared/welibrary-graphql/types';
import { DeepValues } from '@core/types/Utilities';
import { StepState } from '@core/types/Courses';
import { SurveyQuestionFormState } from '@core/types/Surveys';
import { ResourceMeta } from '@web/utilities/helpers/courses/course.helpers';

export const SKILL = {
    CREATIVE: 'Creative',
    EMOTIONAL: 'Emotional',
    SOCIAL: 'Social',
    COGNITIVE: 'Cognitive',
    PHYSICAL: 'Physical',
} as const;
export type SkillType = typeof SKILL[keyof typeof SKILL];

export type StudentSkillType = {
    name: SkillType;
    resource: Resource;
};

export const SKILL_TO_RESOURCE = {
    [SKILL.CREATIVE]: Resource.Crayons,
    [SKILL.EMOTIONAL]: Resource.Hearts,
    [SKILL.SOCIAL]: Resource.Flowers,
    [SKILL.COGNITIVE]: Resource.Books,
    [SKILL.PHYSICAL]: Resource.Wood,
} as const;

export const getSkillIcon = (skill: SkillType) => ResourceMeta[SKILL_TO_RESOURCE[skill]].icon;

export const SKILL_SUBTYPE = {
    Creative: [
        'Creative Process',
        'Generate Diverse Ideas',
        'Evaluate and Improve',
        'Generate Original Ideas',
        'Recognize and Transfer',
    ],
    Emotional: ['Emotional Subskill 1', 'Emotional Subskill 2', 'Emotional Subskill 3'],
    Social: ['Social Subskill 1', 'Social Subskill 2', 'Social Subskill 3'],
    Cognitive: ['Cognitive Subskill 1', 'Cognitive Subskill 2', 'Cognitive Subskill 3'],
    Physical: ['Physical Subskill 1', 'Physical Subskill 2', 'Physical Subskill 3'],
} as const; /* satisfies Record<SkillType, readonly string[]> */ // Future TS Syntax! Uncomment for Type Safety
export type SubskillType = { [Skill in SkillType]: typeof SKILL_SUBTYPE[Skill][number] };
const ALL_SUBSKILLS = Object.values(SKILL).reduce((allSubskills, skill) => {
    return [...allSubskills, ...SKILL_SUBTYPE[skill]];
}, []);

export const MILESTONE = {
    Creative: {
        'Creative Process': ['Milestone 1', 'Milestone 2', 'Milestone 3'],
        'Generate Diverse Ideas': ['Milestone 4', 'Milestone 5', 'Milestone 6'],
        'Evaluate and Improve': ['Milestone 7', 'Milestone 8', 'Milestone 9'],
        'Generate Original Ideas': ['Milestone 10', 'Milestone 11', 'Milestone 12'],
        'Recognize and Transfer': ['Milestone 13', 'Milestone 14', 'Milestone 15'],
    },
    Emotional: {
        'Emotional Subskill 1': ['Milestone 16', 'Milestone 17', 'Milestone 18'],
        'Emotional Subskill 2': ['Milestone 19', 'Milestone 20', 'Milestone 21'],
        'Emotional Subskill 3': ['Milestone 22', 'Milestone 23', 'Milestone 24'],
    },
    Social: {
        'Social Subskill 1': ['Milestone 25', 'Milestone 26', 'Milestone 27'],
        'Social Subskill 2': ['Milestone 28', 'Milestone 29', 'Milestone 30'],
        'Social Subskill 3': ['Milestone 31', 'Milestone 32', 'Milestone 33'],
    },
    Cognitive: {
        'Cognitive Subskill 1': ['Milestone 34', 'Milestone 35', 'Milestone 36'],
        'Cognitive Subskill 2': ['Milestone 37', 'Milestone 38', 'Milestone 39'],
        'Cognitive Subskill 3': ['Milestone 40', 'Milestone 41', 'Milestone 42'],
    },
    Physical: {
        'Physical Subskill 1': ['Milestone 43', 'Milestone 44', 'Milestone 45'],
        'Physical Subskill 2': ['Milestone 46', 'Milestone 47', 'Milestone 48'],
        'Physical Subskill 3': ['Milestone 49', 'Milestone 50', 'Milestone 51'],
    },
} as const; /* satisfies { [Skill in SkillType]: { [Subskill in SubskillType[Skill]]: readonly string[] } } */ // Future TS Syntax! Uncomment for Type Safety
export type MilestoneType = {
    [Skill in SkillType]: {
        [Subskill in SubskillType[Skill]]: typeof MILESTONE[Skill][Subskill][number];
    };
};

export type AggregateSkills = {
    skills: SkillType[];
    subskills: SubskillType[keyof SubskillType][];
    milestones: DeepValues<MilestoneType>[];
};

export const getProficiencyScoreForSkill = (skill: SkillType) => {
    switch (skill) {
        case SKILL.CREATIVE:
            return 95;
        case SKILL.EMOTIONAL:
            return 81;
        case SKILL.SOCIAL:
            return 80;
        case SKILL.COGNITIVE:
            return 65;
        case SKILL.PHYSICAL:
            return 49;
        default:
            return 0;
    }
};

export const PROFICIENCY_STATUS = {
    EXCEEDING: 'Exceeding',
    PROFICIENT: 'Proficient',
    APPROACHING: 'Approaching',
    BELOW: 'Below',
    FAR_BELOW: 'Far below',
} as const;
export type ProficiencyStatusType = typeof PROFICIENCY_STATUS[keyof typeof PROFICIENCY_STATUS];

const MAX_SCORE = 100;
const EXCEEDING_THRESHOLD = 90;
const PROFICIENT_THRESHOLD = 75;
const APPROACHING_THRESHOLD = 50;
const BELOW_THRESHOLD = 25;
const MIN_SCORE = 0;

export const getProficiencyStatusForScore = (score: number) => {
    if (score <= MAX_SCORE && score > EXCEEDING_THRESHOLD) {
        return PROFICIENCY_STATUS.EXCEEDING;
    }
    if (score <= EXCEEDING_THRESHOLD && score > PROFICIENT_THRESHOLD) {
        return PROFICIENCY_STATUS.PROFICIENT;
    }
    if (score <= PROFICIENT_THRESHOLD && score > APPROACHING_THRESHOLD) {
        return PROFICIENCY_STATUS.APPROACHING;
    }
    if (score <= APPROACHING_THRESHOLD && score > BELOW_THRESHOLD) {
        return PROFICIENCY_STATUS.BELOW;
    }
    if (score <= BELOW_THRESHOLD && score >= MIN_SCORE) {
        return PROFICIENCY_STATUS.FAR_BELOW;
    }

    throw new Error(`Invalid score: ${score}`);
};

export const getProficiencyWordForStatus = (status: ProficiencyStatusType) => {
    switch (status) {
        case PROFICIENCY_STATUS.EXCEEDING:
            return 'Exceeding Expectations';
        case PROFICIENCY_STATUS.PROFICIENT:
            return 'Proficient';
        case PROFICIENCY_STATUS.APPROACHING:
            return 'Approaching Proficiency';
        case PROFICIENCY_STATUS.BELOW:
            return 'Below Proficiency';
        case PROFICIENCY_STATUS.FAR_BELOW:
            return 'Far Below Proficiency';
        default:
            throw new Error(`Invalid proficiency status: ${status}`);
    }
};

export const getDotColorForStatus = (status: ProficiencyStatusType) => {
    switch (status) {
        case PROFICIENCY_STATUS.EXCEEDING:
            return '#27AE60';
        case PROFICIENCY_STATUS.PROFICIENT:
            return '#2F80ED';
        case PROFICIENCY_STATUS.APPROACHING:
            return '#FFCF00';
        case PROFICIENCY_STATUS.BELOW:
            return '#F2994A';
        case PROFICIENCY_STATUS.FAR_BELOW:
            return '#EF293D';
        default:
            throw new Error(`Invalid proficiency status: ${status}`);
    }
};

export const getDotColorForScore = (score: number) => {
    return getDotColorForStatus(getProficiencyStatusForScore(score));
};

const MIN_OPACITY = 0.3;
const MAX_OPACITY = 1;
const MAX_DOT_SCORE = 10;

export const getDotOpacity = (dotScore: number) => {
    const opacityRange = MAX_OPACITY - MIN_OPACITY;
    const opacityPercent = dotScore / MAX_DOT_SCORE;
    const opacityModifier = opacityRange * opacityPercent; // 0 for MIN_SCORE, opacityRange for MAX_SCORE
    return MIN_OPACITY + opacityModifier;
};

export const getSkillForSubskill = (subskill: SubskillType) => {
    let skill: any; // really SkillType, but ts is annoying about it
    Object.keys(SKILL_SUBTYPE).forEach(key => {
        if (SKILL_SUBTYPE[key].includes(subskill)) skill = key;
    });
    return skill;
};

export const getResourceForSkill = (skill: SkillType) => {
    return SKILL_TO_RESOURCE[skill];
};

export const getResourceForSubskill = (subskill: SubskillType) => {
    const skill = getSkillForSubskill(subskill);
    if (!skill) return;
    return getResourceForSkill(skill);
};

export const getResourceForSkillOrSubskill = (
    skill: SkillType | SubskillType,
    isSubskill: boolean
) => {
    return isSubskill ? getResourceForSubskill(skill) : getResourceForSkill(skill);
};

export const getColorForSkillOrSubskill = (
    skill: SkillType | SubskillType,
    isSubskill: boolean
) => {
    const resource = getResourceForSkillOrSubskill(skill, isSubskill);
    return ResourceMeta[resource].color;
};

export const isSkill = (maybeSkill: string) => {
    return Object.values(SKILL).includes(maybeSkill);
};

export const isSubskill = (maybeSubskill: string) => {
    return ALL_SUBSKILLS.includes(maybeSubskill);
};

export const getAggregateSkillsFromArray = (
    array: (Step | StepState | SurveyQuestion | SurveyQuestionFormState)[]
): AggregateSkills => {
    const skills = array.reduce<AggregateSkills>(
        (aggregate, item) => {
            if (item.skill) aggregate.skills.push(item.skill as any);
            if (item.skillSubtype) aggregate.subskills.push(item.skillSubtype as any);
            if (item.skillMilestone) aggregate.milestones.push(item.skillMilestone as any);

            return aggregate;
        },
        { skills: [], subskills: [], milestones: [] }
    );

    // Dedupe
    skills.skills = Array.from(new Set(skills.skills));
    skills.subskills = Array.from(new Set(skills.subskills));
    skills.milestones = Array.from(new Set(skills.milestones));

    return skills;
};
