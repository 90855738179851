import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import CaretDownIcon from '@web/ui/dsc/svgs/CaretDownIcon';
import CurrencyList from './CurrencySelectorList';

import currencies, { CurrencyType } from '@core/utilities/constants/currencies';

export const CurrencySelector: React.FC<{
    currencyISO: string;
    setTicketCurrency: React.Dispatch<React.SetStateAction<string>>;
}> = ({ currencyISO, setTicketCurrency }) => {
    const [search, setSearch] = useState<string>('');
    const [showSelector, setShowSelector] = useState<boolean>(false);
    const [activeCurrency, setActiveCurrency] = useState<CurrencyType | null>(null);

    useEffect(() => {
        const _activeCurrency = currencies?.find(c => c.code === currencyISO);
        setActiveCurrency(_activeCurrency);
    }, [currencyISO]);

    const searchResults = currencies?.filter(c => {
        if (
            c.countryName?.toLowerCase()?.includes(search?.toLowerCase()) ||
            c.currency?.toLowerCase()?.includes(search?.toLowerCase()) ||
            c.code?.toLowerCase()?.includes(search?.toLowerCase())
        )
            return c;
    });

    const handleSelectCurrency = (currency: CurrencyType) => {
        setActiveCurrency({
            flag: currency?.flag,
            countryName: currency?.countryName,
            currency: currency?.currency,
            code: currency?.code,
            symbol: currency?.symbol,
        });
        setTicketCurrency(currency?.code);
        setShowSelector(false);
        setSearch('');
    };

    return (
        <div className="relative inline-block min-w-[150px] max-w-[150px] mr-2">
            {!showSelector && (
                <button
                    type="button"
                    className="flex items-center justify-between bg-[#f4f5f5] w-full rounded-[10px]"
                    onClick={() => setShowSelector(!showSelector)}
                >
                    <p className="p-0 m-0 text-black px-[16px] py-[12px] font-semibold">
                        {activeCurrency?.code}
                        <span className="ml-2">{activeCurrency?.symbol}</span>
                    </p>

                    <span className="pr-4">
                        <CaretDownIcon />
                    </span>
                </button>
            )}

            <OutsideClickHandler onOutsideClick={() => setShowSelector(false)}>
                {showSelector && (
                    <div className="bg-[#f4f5f5] rounded-[10px] min-w-[150px] max-w-[150px] relative flex items-center justify-between pr-4">
                        <input
                            value={search}
                            onChange={e => setSearch(e.currentTarget.value)}
                            className="px-[16px] py-[12px] w-full h-full bg-[#f4f5f5] rounded-[10px]"
                            type="text"
                            maxLength={256}
                            placeholder="Search..."
                            autoFocus
                        />

                        <button type="button" onClick={() => setShowSelector(!showSelector)}>
                            <CaretDownIcon className="rotate-180" />
                        </button>
                    </div>
                )}

                {search?.length === 0 && showSelector && (
                    <CurrencyList currencies={currencies} handleOnClick={handleSelectCurrency} />
                )}

                {search?.length > 0 && showSelector && searchResults.length > 0 && (
                    <CurrencyList currencies={searchResults} handleOnClick={handleSelectCurrency} />
                )}

                {search?.length > 0 && showSelector && searchResults.length === 0 && (
                    <div className="ticket-sharing-dropdown min-h-[65px] max-h-[65px]">
                        <p className="p-0 m-0 text-black font-semibold">No currency found</p>
                    </div>
                )}
            </OutsideClickHandler>
        </div>
    );
};

export default CurrencySelector;
