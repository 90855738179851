import React from 'react';
import { createPortal } from 'react-dom';

import { useToastContext } from '@components/toast/NewToastContext';

const Toast = () => {
    const { toast } = useToastContext();

    if (!toast.component) {
        // Return null, since there is nothing to render
        return null;
    }

    return createPortal(
        <aside
            className={`toast-center ${toast?.options?.className ?? ''} ${
                toast.open ? 'open' : 'closed'
            }`}
        >
            {toast.component}
        </aside>,
        document.getElementById('modal-super-root') as HTMLElement
    );
};

export default Toast;
