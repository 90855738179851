import React, { MouseEventHandler } from 'react';

type CenterModalHeaderFooterProps = {
    title: string;
    subtitle?: string;
    buttonText?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    buttonDisabled?: boolean;
    className?: string;
    buttonClassName?: string;
    children?: React.ReactNode;
};

const CenterModalHeaderFooter: React.FC<CenterModalHeaderFooterProps> = ({
    title,
    subtitle,
    buttonText,
    onClick,
    buttonDisabled = false,
    className = '',
    buttonClassName = '',
    children,
}) => (
    <article className={`center-modal-header-footer ${className}`}>
        <header>
            <h3> {title} </h3>
            {subtitle && <span>{subtitle}</span>}
        </header>
        <section>{children}</section>
        {onClick && (
            <footer>
                <button
                    type="button"
                    onClick={onClick}
                    disabled={buttonDisabled}
                    className={buttonClassName}
                >
                    {buttonText}
                </button>
            </footer>
        )}
    </article>
);

export default CenterModalHeaderFooter;
