import {TOGGLE_CREATE_CARD} from '@core/client/types.js';

export default function createCard(state = {}, action = {}) {
    switch (action.type) {
        case TOGGLE_CREATE_CARD:
            //Default state is not open.
            let isOpen = false;
            //If the state has already been set to open or closed at the index, get that
            if (state.hasOwnProperty(action.index)) {
                isOpen = state[action.index].open;
            }
            // Toggle the open state at the index
            let createCardUIItem = {};
            createCardUIItem[action.index] = { open: !isOpen };
            // Return a new object for the createCard state, merging in the new property.
            return Object.assign({}, state, createCardUIItem);
        default:
            return state;
    }
}
