import React from 'react';
import useMd from '@web/ui/components/generic/hooks/useMd';

interface DescriptionProps {
    field: {
        prompt: string;
        required: boolean;
    };
}

const Description: React.FC<DescriptionProps> = ({ field: { prompt, required } }) => {
    const md = useMd();
    return (
        <div className="custom-signup-item">
            <div className="custom-signup-label">
                <div
                    className="custom-signup-description"
                    dangerouslySetInnerHTML={{ __html: md.render(prompt) }}
                />
            </div>
        </div>
    );
};

export default Description;
