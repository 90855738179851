import React, {useState} from 'react';

import {useQuery} from '@apollo/client';

import {GET_GROUP_BY_ID} from '@shared/welibrary-graphql/user/queries';

import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-spinner.json';

const ShortAnswerSection = ({ prompt, values }) => {
    const value = values[0];
    return (
        <div className="survey-card-section">
            <div className="survey-card-question">{prompt}</div>
            <p className="survey-card-answer short-survey-answer">{value}</p>
        </div>
    );
};

const LongAnswerSection = ({ prompt, values }) => {
    const value = values[0];
    return (
        <div className="survey-card-section">
            <div className="survey-card-question">{prompt}</div>
            <p className="survey-card-answer">{value}</p>
        </div>
    );
};

const MultipleChoiceSection = ({ prompt, values }) => {
    const options = values.map(value => <MultipleChoiceItem value={value} />);
    return (
        <div className="survey-card-section">
            <div className="survey-card-question">{prompt}</div>
            <div className="survey-section-answer-container select-answer">{options}</div>
        </div>
    );
};

const MultipleChoiceItem = ({ value }) => {
    return (
        <div className="survey-card-selected-answer">
            <div>{value}</div>
        </div>
    );
};

const CustomFieldsSummaryItem = ({ data }) => {
    const showExpansion = data?.length > 3;

    const [isExpanded, setExpanded] = useState(!showExpansion);

    const sections = data.map(item => {
        switch (item.type) {
            case 'shortanswer':
                return <ShortAnswerSection {...item} />;
            case 'paragraph':
                return <LongAnswerSection {...item} />;
            case 'dropdown':
                return <MultipleChoiceSection {...item} />;
        }
    });

    return (
        <div className="survey-results-card">
            <div
                className={`survey-card-sections-container ${isExpanded && 'show-survey-sections'}`}
            >
                {sections}
            </div>
            {showExpansion && (
                <div
                    onClick={() => setExpanded(!isExpanded)}
                    className={`fading-more-button ${
                        isExpanded && 'fading-show-less-button'
                    } w-inline-block`}
                >
                    <div>Show {isExpanded ? 'less' : 'more'}</div>
                </div>
            )}
        </div>
    );
};

const CustomFieldsSummaryQuery = ({ card, currentUser, isPreview }) => {
    const previewData = [
        {
            type: 'shortanswer',
            prompt: 'Question lorem ipsum sit dalor?',
            values: ['Lorem ipsum dolor sit amet.'],
        },
        {
            type: 'paragraph',
            prompt: 'Question lorem ipsum sit dalor?',
            values: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.',
            ],
        },
        {
            type: 'dropdown',
            prompt: 'Question lorem ipsum sit dalor?',
            values: ['Hamsters', 'Cats', 'Bunnies'],
        },
    ];

    const emptyData = [
        {
            type: 'paragraph',
            prompt: 'Optimize Your Group',
            values: [
                'There is no survey data available for this group. Optimize your group and it will show up here!',
            ],
        },
    ];

    const _id = card?.reference?.groupSource?._id;
    const { data, loading, error } = useQuery(GET_GROUP_BY_ID, {
        variables: {
            suppressCompletedSurveys: false,
            translate: true,
            targetLanguage: currentUser?.profile?.language,
            _id,
        },
        skip: !_id,
    });

    if (loading || error) {
        return (
            <div className="survey-results-card">
                <LottieLoading height={150} width={150} lottieData={LottieAnimation} />
            </div>
        );
    }

    const groupCustomSurveys = data?.groupById?.relevantSignupFields;
    const getPromptForQuestionId = questionId => {
        if (!groupCustomSurveys) return null;
        for (let x = 0; x < groupCustomSurveys.length; x++) {
            const surveyCollection = groupCustomSurveys[x];
            if (surveyCollection) {
                for (let y = 0; y < surveyCollection.signupFields.length; y++) {
                    const signupField = surveyCollection.signupFields[y];
                    if (signupField?.id === questionId) return signupField.prompt;
                }
            }
        }
    };

    let groupCustomFields = data?.groupById?.profile?.customFields?.reduce(
        (accumulator, currentValue) => [...accumulator, ...currentValue.values],
        []
    );

    if (groupCustomFields) {
        groupCustomFields = groupCustomFields
            .map(field => {
                return field ? { ...field, prompt: getPromptForQuestionId(field.id) } : null;
            })
            .filter(item => item !== null);
    } else {
        groupCustomFields = emptyData;
    }

    return <CustomFieldsSummaryItem data={isPreview ? previewData : groupCustomFields} />;
};

export default CustomFieldsSummaryQuery;
