import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from '@components/modals/hooks/useModal';

import Button from '@dsc/forms/buttons/Button';

type ConfirmationProps = {
    title?: string;
    text: React.ReactNode;
    onConfirm: () => void;
    onCancel?: () => void;
    confirmText?: string;
    cancelText?: string;
    className?: string;
};

const Confirmation: React.FC<ConfirmationProps> = ({
    text,
    onConfirm,
    onCancel,
    confirmText,
    cancelText,
    title,
    className = '',
}) => {
    const { t } = useTranslation();

    const { closeModal } = useModal();

    const confirm = () => {
        onConfirm();
        closeModal();
    };

    const cancel = () => {
        onCancel?.();
        closeModal();
    };

    return (
        <section role="alert" className={`confirmation ${className}`}>
            {title && <h2>{title}</h2>}

            {typeof text === 'string' ? <strong>{text}</strong> : text}

            <footer>
                <Button onClick={confirm} type="small">
                    {confirmText ?? t('common:ok', 'Ok')}
                </Button>
                <Button onClick={cancel} type="small">
                    {cancelText ?? t('common:cancel', 'Cancel')}
                </Button>
            </footer>
        </section>
    );
};

export default Confirmation;
