import React from 'react';

type CaretDownIconProps = {
    className?: string;
    type?: 'v' | 'triangle';
};

const CaretDownIcon: React.FC<CaretDownIconProps> = ({ className = '', type = 'v' }) => {
    if (type === 'v') {
        return (
            <svg width="9" height="4" viewBox="0 0 9 4" fill="none" className={className}>
                <path
                    d="M7.96317 1.12939L4.48158 2.87019L0.999994 1.12939"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }

    // type === 'triangle'
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M12.6206 5.44561C12.5851 5.35996 12.525 5.28675 12.4479 5.23525C12.3708 5.18374 12.2802 5.15625 12.1875 5.15625H2.8125C2.71979 5.15625 2.62916 5.18374 2.55208 5.23525C2.47499 5.28676 2.41491 5.35997 2.37943 5.44562C2.34395 5.53128 2.33467 5.62553 2.35276 5.71646C2.37085 5.80739 2.41549 5.89091 2.48105 5.95646L7.16855 10.644C7.21208 10.6875 7.26375 10.722 7.32062 10.7456C7.37749 10.7691 7.43845 10.7813 7.5 10.7813C7.56156 10.7813 7.62251 10.7691 7.67938 10.7456C7.73625 10.722 7.78793 10.6875 7.83145 10.644L12.519 5.95646C12.5845 5.89091 12.6292 5.80738 12.6472 5.71645C12.6653 5.62552 12.6561 5.53127 12.6206 5.44561Z"
                fill="#6E7191"
            />
        </svg>
    );
};

export default CaretDownIcon;
