import React from 'react';

import Newsfeed from '@web/ui/components/newsfeed/Newsfeed';
import LoadingIndicator from '@web/ui/components/generic/loading/LoadingIndicator';

const GroupNewsfeedSection = React.forwardRef(function GroupNewsfeedSection(
    { sectionTitle, cards, setRef, hasMoreResults },
    ref
) {
    const renderPaginationDiv = () => (
        <div className="group-newsfeed-pagination-threshold" ref={setRef} key="pagination-div" />
    );
    return (
        <>
            <h3 className="dashboard-section-title-container" ref={ref}>
                {sectionTitle}
            </h3>
            <Newsfeed
                list={cards}
                renderPaginationDiv={setRef && renderPaginationDiv}
                showReferenceHeader
            />
            {hasMoreResults && (
                <section style={{ marginBottom: '10px' }}>
                    <LoadingIndicator type="skeleton" className="loader" size="fill" />
                </section>
            )}
        </>
    );
});

export default GroupNewsfeedSection;
