export const VIEWER_MIME_TYPES = [
    'application/pdf',
    'application/mspowerpoint',
    'application/powerpoint',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/excel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/x-excel',
    'application/x-msexcel',
    'application/msword',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/epub+zip',
    'text/html',
    'text/plain',
    'text/csv',
    'text/calendar',
    'application/postscript',
    'application/octet-stream',
    '.pdf',
    '.ppt',
    '.pptx',
    '.xls',
    '.xlsx',
    '.doc',
    '.docx',
    '.odt',
    '.odp',
    '.ods',
    '.html',
    '.txt',
    '.ai',
    '.psd',
    '.numbers',
    'application/vnd.apple.numbers',
    '.pages',
    'application/vnd.apple.pages',
    '.key',
    'application/x-iwork-keynote-sffkey',
    'application/rtf',
];

export const IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/webp', 'image/gif', 'image/bmp'];
