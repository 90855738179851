import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';

import useModal from '@components/modals/hooks/useModal';
import { SearchCardHeaderThumb } from '@components/search/searchcard/components';

import { ContentCard } from '@shared/welibrary-graphql/types';

type ContentSearchCardViewProps = {
    handleCloseSearch: () => void;
    cardUrl: string;
    hideBody: boolean;
    hasThumb: boolean;
    content: ContentCard;
    cardTitle: JSX.Element | null;
    subHeaderInfo: JSX.Element;
    authorInfo: JSX.Element | null;
    cardBody: JSX.Element | null;
    mediaThumbStyle: CSSProperties;
};

const ContentSearchCardView: React.FC<ContentSearchCardViewProps> = ({
    handleCloseSearch,
    cardUrl,
    hideBody,
    hasThumb,
    content,
    cardTitle,
    subHeaderInfo,
    authorInfo,
    cardBody,
    mediaThumbStyle,
}) => {
    const containerClass = hideBody
        ? 'search-result-item search-result-block-item'
        : 'search-result-item search-result-block-item search-result-content-item';
    const headerClass = hideBody ? 'search-post-header header-only' : 'search-post-header';

    const { closeAllModals } = useModal();
    const history = useHistory();

    const handleCardLink = () => {
        history.push({ pathname: cardUrl, fromSearch: true });
        if (handleCloseSearch) {
            closeAllModals();
            handleCloseSearch();
        }
    };

    return (
        <div className={containerClass}>
            <div className={headerClass}>
                <SearchCardHeaderThumb onClick={handleCardLink} content={content} />

                <div className="search-result-item-middle">
                    {cardTitle}
                    {subHeaderInfo}
                </div>
                <a className="search-result-item-action-button menu-trigger" />
            </div>
            {!hideBody && (
                <div className="search-post-content-container">
                    <div>
                        {authorInfo}
                        <span className="search-card-content-body" onClick={handleCardLink}>
                            {cardBody}
                        </span>
                    </div>
                    {hasThumb && (
                        <button
                            type="button"
                            aria-label="View card"
                            onClick={handleCardLink}
                            className="button-reset search-post-media"
                            style={mediaThumbStyle}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default ContentSearchCardView;
