import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@stores/User';
import useModal from '@web/ui/components/modals/hooks/useModal';

import signUpStore from '@stores/SignUp';
import { useUpdateUserMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import X from '@web/ui/dsc/svgs/FullSizeX';
import Pencil from '@web/ui/dsc/svgs/Pencil';
import OrganizationPicker from '@web/ui/components/organization/OrganizationPicker';

import { INSERT_ID_INTO_CUSTOM_FIELD } from '@core/utilities/constants/user';

import { ModalTypes } from '@core/types/Modals';
import { CustomSignupField, Organization } from '@shared/welibrary-graphql/types';
import { SignupState } from '@core/types/Signup';

const localNamespace = 'imports.wlWeb.ui.pages.signup.organizationOptimizer';

type OrganizationOptimizerFieldProps = {
    state: { [key: string]: { value: string[] | null } };
    signupState?: SignupState;
    setSignupState?: (state: Partial<SignupState>) => void;
    organizationField: CustomSignupField;
    onChange: (id: string, values: string | string[] | null, type: string) => void;
    signup?: boolean;
    version?: '1' | '2'; // 1 = old signup (and anywhere else that used it before versions were introduced), 2 = new signup and beyond
};

const OrganizationOptimizerField: React.FC<OrganizationOptimizerFieldProps> = ({
    state,
    signupState,
    setSignupState,
    organizationField,
    onChange,
    signup = false,
    version = '1',
}) => {
    const { t } = useTranslation();
    const { currentUser, refetchCurrentUser } = useCurrentUser();
    const [updateUser] = useUpdateUserMutation();

    const { newModal } = useModal({ mobile: ModalTypes.Center, desktop: ModalTypes.Center });

    const userHasAcceptedOrganizationRequest =
        organizationField.id && !!state[organizationField.id]?.value;

    const userId = currentUser?._id ?? INSERT_ID_INTO_CUSTOM_FIELD;

    const organizationState = signUpStore.useTracked.organization(); // new signup uses this instead of signupState.organization
    const currentOrganization =
        organizationState ?? currentUser?.profile?.organization ?? signupState?.organization;

    const updateOrganization = (organization: Organization) => {
        if (currentUser) {
            updateUser({
                variables: { _id: currentUser._id, organization: organization._id },
            }).then(() => refetchCurrentUser());
        }
        setSignupState?.({ organization });
    };

    const acceptOrganizationRequest = () => {
        if (!organizationField.id || !organizationField.type) return;

        onChange(organizationField.id, [userId], organizationField.type);
    };

    const rejectOrganizationRequest = () => {
        if (!organizationField.id || !organizationField.type) return;

        onChange(organizationField.id, null, organizationField.type);
    };

    const selectOrganization = () => {
        newModal(
            <OrganizationPicker
                onOrganizationSelect={organization => {
                    updateOrganization(organization);

                    acceptOrganizationRequest();
                }}
            />,
            { className: 'full-height' }
        );
    };

    if (version === '1') {
        return (
            <section
                className={`organization-optimizer-field ${signup ? 'signup' : ''} ${
                    organizationField.required ? 'required' : ''
                }`}
            >
                {userHasAcceptedOrganizationRequest ? (
                    <section>
                        <button
                            type="button"
                            onClick={selectOrganization}
                            className="edit-organization"
                            title={t(`common:${localNamespace}.editOrg`, 'Edit Organization')}
                        >
                            <span>{currentOrganization?.name}</span>
                            <Pencil />
                        </button>
                        <button
                            aria-label={t(
                                `common:${localNamespace}.stopSharingOrganization`,
                                'Stop sharing organization'
                            )}
                            title={t(
                                `common:${localNamespace}.stopSharingOrganization`,
                                'Stop sharing organization'
                            )}
                            type="button"
                            onClick={rejectOrganizationRequest}
                            className="stop-sharing-organization"
                        >
                            <X />
                        </button>
                    </section>
                ) : (
                    <button
                        type="button"
                        onClick={
                            currentOrganization ? acceptOrganizationRequest : selectOrganization
                        }
                        title={t(
                            `common:${localNamespace}.selectOrganization`,
                            'Share Organization'
                        )}
                    >
                        {t(
                            `common:${localNamespace}.chooseYourOrganization`,
                            'Choose your organization'
                        )}
                    </button>
                )}
            </section>
        );
    }

    return (
        <section className="w-full">
            {userHasAcceptedOrganizationRequest ? (
                <section className="flex gap-[16px]">
                    <button
                        type="button"
                        onClick={selectOrganization}
                        title={t(`common:${localNamespace}.editOrg`, 'Edit Organization')}
                        className="bg-grayscale-input-background text-grayscale-title-active font-poppins flex w-full items-center rounded-[16px] px-[20px] py-[10px] text-[16px] leading-[175%] tracking-[0.75px]"
                    >
                        <span className="grow text-left">{currentOrganization?.name}</span>
                        <Pencil version="2" strokeWidth="2" />
                    </button>
                    <button
                        aria-label={t(
                            `common:${localNamespace}.stopSharingOrganization`,
                            'Stop sharing organization'
                        )}
                        title={t(
                            `common:${localNamespace}.stopSharingOrganization`,
                            'Stop sharing organization'
                        )}
                        type="button"
                        onClick={rejectOrganizationRequest}
                        className="text-grayscale-placeholder"
                    >
                        <X size="18" />
                    </button>
                </section>
            ) : (
                <button
                    type="button"
                    onClick={currentOrganization ? acceptOrganizationRequest : selectOrganization}
                    title={t(`common:${localNamespace}.selectOrganization`, 'Select Organization')}
                    className="bg-grayscale-input-background text-grayscale-placeholder font-poppins flex w-full items-center rounded-[16px] px-[20px] py-[10px] text-[16px] leading-[175%] tracking-[0.75px]"
                >
                    <span className="grow text-left">
                        {currentOrganization &&
                            t(`common:${localNamespace}.shareYourOrg`, 'Share your organization')}
                        {!currentOrganization &&
                            t(`common:${localNamespace}.organization`, 'Organization')}
                    </span>
                    {!currentOrganization && <Pencil version="2" strokeWidth="2" />}
                </button>
            )}
        </section>
    );
};

export default OrganizationOptimizerField;
