/* eslint-disable react/jsx-one-expression-per-line */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';

import { useCurrentUser } from '@stores/User';
import useGroupDashboardMenu from '@components/group/groupdashboard/hooks/useGroupDashboardMenu';
import useGroupActions from '@components/group/hooks/useGroupActions.js';
import useModal from '@components/modals/hooks/useModal';

import TwoPeopleIcon from '@dsc/svgs/TwoPeopleIcon';
import GroupPlusButton from '@components/group/groupdashboard/GroupPlusButton';
import DropdownController from '@components/generic/dropdownmenu/DropdownController';
import GroupMembers from '@components/group/groupdashboard/GroupMembers/GroupMembers';
import ChannelsButton from '@components/group/groupdashboard/ChannelsButton';
import GroupActionButton from '@components/group/groupdashboard/GroupActionButton';
import GroupDashboardRSVPButton from '@components/group/groupdashboard/GroupDashboardRSVPButton';
import GroupDashboardSubscriptionButton from '@components/group/groupdashboard/groupSubscriptionPaywall/GroupSubscriptionButton';
import CheckoutWithProductQuery from '@components/card/Shopping-Cart/CheckoutWithProductQuery';
import EventShareModal from './GroupMembers/EventShareModal';
// import GroupChecklistHeader from '../checklist/GroupChecklistHeader';

import {
    isGroupSubscription,
    parentGroupHasSubscription,
    groupSellsProductsButCantAcceptPayments,
} from '@components/group/groupdashboard/groupDashboardHelpers';

import { getRootUrl } from '@core/utilities/whitelabel_helpers';
import { config } from '@core/config/getConfig';

import { Group } from '@shared/welibrary-graphql/types';
import { ModalTypes } from '@core/types/Modals';

const LoginSignupButtons: React.FC<{ t: any; group: Group }> = ({ t, group }) => {
    return (
        <div className="login-signup-btns">
            <Link
                to={`/login?mode=email&returnTo=/g/${group?._id}`}
                className="group-header-link white group-header-link-login"
            >
                {' '}
                {t('common:global.form.login')}
            </Link>
            <Link
                to={`/signup?mode=email&signupLink=true&returnTo=/g/${group?._id}`}
                className="group-header-link white group-header-link-signup"
            >
                • {t('common:global.form.signup')}
            </Link>
        </div>
    );
};

type GroupDashboardMenuProps = {
    group: Group;
    dashboardGroup: Group;
    handleGroupAnalytics: (open: boolean) => void;
    handleOpenUpdateGroup: (settingsOpen: boolean) => void;
    handleAnnouncementPostBox: () => void;
    handleMobilizationForm: (formType: string) => void;
    inModal: boolean;
    canUserViewGroup: boolean;
    isEventGroup: boolean;
};

const GroupDashboardMenu: React.FC<GroupDashboardMenuProps> = ({
    group,
    dashboardGroup,
    handleGroupAnalytics,
    handleOpenUpdateGroup,
    handleAnnouncementPostBox,
    handleMobilizationForm,
    inModal,
    canUserViewGroup,
    isEventGroup,
}) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();
    const { newModal: centerModal, closeModal } = useModal({ mobile: ModalTypes.Center });
    const { newModal } = useModal();
    const location = useLocation();
    const history = useHistory();
    const { productId, quantity } = useParams<{ productId: string; quantity: string }>();

    const { primaryActionAvailable, takeGroupActionForUser } = useGroupActions({ group });
    const hideGroupInfo = config?.public?.hideGroupHeaderInfoFromLoggedOutUsers && !currentUser;
    const root_url = getRootUrl();

    const isMembersOpen = useRouteMatch('/g/:groupId/members') && canUserViewGroup;
    const isTicketModalOpen = useRouteMatch('/g/:groupId/tickets/:productId/:quantity');
    const isShareModalOpen = useRouteMatch('/g/:groupId/share');

    const atMobileBreakpoint = window?.innerWidth < 480;

    const options = {
        onClose: () => history?.push(`/g/${group?._id}`),
    };

    useEffect(() => {
        if (isMembersOpen) {
            centerModal(<GroupMembers group={group} />, options);
        } else if (isTicketModalOpen) {
            newModal(
                <CheckoutWithProductQuery
                    group={group}
                    productId={productId}
                    quantity={Number(quantity)}
                />,
                {
                    onClose: () => history?.push(`/g/${group?._id}`),
                    className: 'no-top-padding no-bottom-padding',
                    hideButton: true,
                }
            );
        } else if (isShareModalOpen) {
            newModal(<EventShareModal group={group} />, {
                onClose: () => history?.push(`/g/${group?._id}`),
                className: 'w-[700px] h-[600px] py-[20px] px-[40px]',
                // hideButton: true,
            });
        } else if (!inModal) {
            closeModal();
        }
    }, [location]);

    const showMembers = () => {
        if (!hideGroupInfo && canUserViewGroup && currentUser) {
            if (inModal) {
                closeModal();
                // wait for existing modal to close before navigating
                setTimeout(() => history.push(`/g/${group?._id}/members`), 300);
            } else {
                history.push(`/g/${group?._id}/members`);
            }
        }
    };

    const shareEvent = () => {
        if (inModal) {
            closeModal();
            setTimeout(() => history.push(`/g/${group?._id}/share`), 300);
        } else {
            history.push(`/g/${group?._id}/share`);
        }
    };

    const {
        isUserAdminOfGroup,
        // isChecklistOpen,
        // toggleChecklist,
        topRightMenuData,
    } = useGroupDashboardMenu({
        currentUser,
        group,
        dashboardGroup,
        handleGroupAnalytics,
        handleOpenUpdateGroup,
        isEventGroup,
    });

    const _isGroupSubscription = isGroupSubscription(group) || parentGroupHasSubscription(group);

    const subscriptionHeaderStyles = {
        headerLinkStyles: 'subs-group-header-links-container',
        membersButtonStyles: `subs-group-header-link ${atMobileBreakpoint ? '!mb-4' : ''}`,
        channelsButtonStyles: _isGroupSubscription ? 'subs-group-channels-btn' : '',
    };

    let groupActionButton;

    if (currentUser && !canUserViewGroup) {
        groupActionButton = (
            <GroupActionButton
                user={currentUser}
                takeGroupActionForUser={takeGroupActionForUser}
                primaryActionAvailable={primaryActionAvailable}
                customClassName=""
                group={group}
            />
        );
    } else if (currentUser) {
        groupActionButton = (
            <ChannelsButton
                currentUser={currentUser}
                group={group}
                customClassName={subscriptionHeaderStyles.channelsButtonStyles}
            />
        );
    }

    if (isEventGroup) {
        groupActionButton = (
            <GroupDashboardRSVPButton
                currentUser={currentUser}
                group={group}
                customButtonClass=""
            />
        );
    }
    const hideAttendeeCount =
        isEventGroup && group?.eventOverviewDetails?.hideAttendeeCount && !isUserAdminOfGroup;

    const channelsButton = (
        <ChannelsButton currentUser={currentUser} group={group} customClassName="channel-button" />
    );

    // Hide members of root group for non-admins.
    const showMembersButton =
        (group?._id !== config.public.rootGroupId &&
            !config?.public?.homeGroups?.groupIds?.includes(group?._id)) ||
        isUserAdminOfGroup;

    const subscriptionButton = (
        <GroupDashboardSubscriptionButton currentUser={currentUser} group={group} />
    );

    return (
        <>
            <div
                className={`group-header-links-container ${
                    _isGroupSubscription ? subscriptionHeaderStyles.headerLinkStyles : ''
                }`}
            >
                <div className="group-header-links-left mobile-group-header-links-left">
                    {showMembersButton && !hideAttendeeCount && (
                        <button
                            type="button"
                            onClick={showMembers}
                            className={`group-header-link white group-header-link-members group-dashboard-header-menu blue-btn ${
                                _isGroupSubscription
                                    ? subscriptionHeaderStyles.membersButtonStyles
                                    : ''
                            }`}
                            disabled={!(currentUser && canUserViewGroup)}
                        >
                            {atMobileBreakpoint && (
                                <TwoPeopleIcon
                                    size="15px"
                                    color="#ffffff"
                                    className="group-members-icon"
                                />
                            )}
                            <strong>{group?.totalMembers || 0}</strong>{' '}
                            {!isEventGroup &&
                                !atMobileBreakpoint &&
                                (group?.totalMembers === 1
                                    ? t('common:global.nouns.member', 'Member')
                                    : t('common:global.nouns.members', 'Members'))}
                            {isEventGroup &&
                                !atMobileBreakpoint &&
                                (group?.totalMembers === 1
                                    ? t('common:attendee', 'Attendee')
                                    : t('common:attendees', 'Attendees'))}
                        </button>
                    )}
                    {_isGroupSubscription && groupActionButton}
                </div>

                {isEventGroup && currentUser && (
                    <>
                        <div className="group-header-links-right">{channelsButton}</div>
                        <div className="flex-item-break mt-[10px]" />
                    </>
                )}
                <div className="channel-btn-container flex">
                    {!_isGroupSubscription && groupActionButton}
                    {_isGroupSubscription && subscriptionButton}
                    {isUserAdminOfGroup && !isEventGroup && (
                        <GroupPlusButton
                            className="groupdashboard-plus-adjustment"
                            group={group?.channelsBook ? group : dashboardGroup || group}
                            handleAnnouncementPostBox={handleAnnouncementPostBox}
                            handleMobilizationForm={handleMobilizationForm}
                        />
                    )}

                    {isEventGroup && !groupSellsProductsButCantAcceptPayments(group) && (
                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                        <button
                            type="button"
                            onClick={shareEvent}
                            className="primary-nav-action-share-button group-dashboard-menu"
                        />
                    )}
                    {topRightMenuData?.length > 0 && (
                        <div className="groupdashboard-dot-adjustment">
                            <DropdownController
                                menuList={topRightMenuData}
                                inverted="nobg"
                                superLayer
                            />
                        </div>
                    )}
                </div>
            </div>

            {!currentUser && <LoginSignupButtons t={t} group={group} />}
        </>
    );
};

export default GroupDashboardMenu;
