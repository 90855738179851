import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

import { SecondaryNavigationConfigType } from '@config/schemas/supplemental';

export type Platform = keyof SecondaryNavigationConfigType['items'];
export type NavItem = SecondaryNavigationConfigType['items'][Platform][number];
export type DesktopNavItem = SecondaryNavigationConfigType['items']['desktop'][number];

const { secondaryNavigation } = getWhitelabelPublicSettings();

/**
 * Active Navigation Items, can be overriden by public whitelabel setting.
 * Currently Supported Items:
 *  mobile: 'navigator', 'groups', 'home', 'messages', 'discover',  'notifications', 'profile'
 *  desktop: 'groups', 'home', 'messages', 'discover', 'notifications', 'profile'
 */
export const navItems = secondaryNavigation?.items ?? {
    mobile: ['navigator', 'groups', 'home', 'messages', 'notifications'], // welibrary default
    desktop: ['groups', 'home', 'messages', 'search', 'notifications', 'profile'],
};

/** Determines whether or not a given nav item should be shown based on whitelabel settings */
export const showNavItem = (type: NavItem, platform?: Platform): boolean => {
    if (platform) return navItems[platform]?.includes(type as DesktopNavItem);

    return navItems.mobile?.includes(type) || navItems.desktop?.includes(type as DesktopNavItem);
};

/** Desktop Nav Items that are not contained within the white desktop menu */
export const NON_MENU_DESKTOP_NAV_ITEMS: DesktopNavItem[] = ['treasures', 'simpleProfile'];

/** Whether or not the white desktop menu is empty */
export const desktopSecondaryNavIsEmpty =
    navItems.desktop.filter(item => !NON_MENU_DESKTOP_NAV_ITEMS.includes(item)).length === 0;
