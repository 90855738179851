import React from 'react';

import { useTranslation } from 'react-i18next';
import { capitalize } from '@helpers/string.helpers';

interface FontSelectorProps {
    font: string;
    setFont: (font: string) => void;
    options: JSX.Element[];
}

const FontSelector: React.FC<FontSelectorProps> = ({ font, setFont, options }) => {
    const { t } = useTranslation();

    return (
        <>
            <h4>
                <label htmlFor="font-selector">{capitalize(t('common:font', 'font')) + ':'}</label>
            </h4>
            <select id="font-selector" value={font} onChange={e => setFont(e.target.value)}>
                {options}
            </select>
        </>
    );
};

export default FontSelector;
