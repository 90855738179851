import _ from 'lodash';
import { Capacitor } from '@capacitor/core';

import { config } from '@config/getConfig';
import { RawFirebaseConfigType, DomainBrandingType } from '@config/schemas/supplemental';

import getLogger from '@core/logger';

const logger = getLogger(module);

export const getWhitelabelGoogleKeys = _.memoize(() => config.public.Google);

export const getWhitelabelFirebaseConfig = _.memoize(() => {
    const { firebaseConfig } = config.public;
    if (!_.isArray(firebaseConfig)) return firebaseConfig;
    let _config: RawFirebaseConfigType | null = null;

    let appId = '';
    try {
        appId =
            // TODO: Where is BuildInfo coming from?
            Capacitor.isNativePlatform() &&
            typeof BuildInfo !== 'undefined' &&
            BuildInfo.packageName;
    } catch (e) {
        logger.warn(e);
    }

    if (_.isArray(firebaseConfig)) {
        try {
            _.forEach(firebaseConfig, firebaseSettings => {
                if (Capacitor.isNativePlatform() && appId) {
                    if (firebaseSettings.appId === appId) {
                        _config = firebaseSettings.config;
                    }
                }

                if (_.isArray(firebaseSettings.hosts)) {
                    if (typeof window !== undefined && window.location.host) {
                        _.forEach(firebaseSettings.hosts, host => {
                            if (window.location.host.includes(host)) {
                                _config = firebaseSettings.config;
                            }
                        });
                    }
                }
            });
        } catch (e) {
            logger.warn(e);
        }
    }

    return _config;
});

export const getWhitelabelRedirects = _.memoize(() => {
    const { domainBranding } = config.public;

    let { root_redirect = '/join', root_redirect_loggedin = '/home' } = config.public;

    let appId = '';

    try {
        appId =
            Capacitor.isNativePlatform() &&
            typeof BuildInfo !== 'undefined' &&
            BuildInfo.packageName;
    } catch (e) {
        logger.warn(e);
    }

    const hostLocation = typeof window !== 'undefined' && window.location && window.location.host;

    const hostKey = appId || hostLocation;

    try {
        if (domainBranding) {
            _.forEach(_.keys(domainBranding), key => {
                if (hostKey && hostKey.includes(key) && domainBranding[key]) {
                    const {
                        root_redirect: _root_redirect,
                        root_redirect_loggedin: _root_redirect_loggedin,
                        root_redirect_native: _root_redirect_native,
                    } = domainBranding[key];

                    if (_root_redirect) root_redirect = _root_redirect;

                    if (_root_redirect_loggedin) {
                        root_redirect_loggedin = _root_redirect_loggedin;
                    }

                    if (Capacitor.isNativePlatform() && _root_redirect_native) {
                        root_redirect = _root_redirect_native;
                    }
                }
            });
        }
    } catch (e) {
        logger.warn(e);
    }

    return {
        root_redirect,
        root_redirect_loggedin,
    };
});

export const getWhitelabelShortCode = () => {
    const { domainBranding, defaultGroupCode } = config.public;

    let appId = '';
    try {
        appId =
            // TODO: Where is BuildInfo coming from?
            Capacitor.isNativePlatform() &&
            typeof BuildInfo !== 'undefined' &&
            BuildInfo.packageName;
    } catch (e) {
        logger.warn(e);
    }
    const hostLocation = typeof window !== 'undefined' && window.location && window.location.host;

    const hostKey = appId || hostLocation;

    try {
        let groupCode = defaultGroupCode;
        if (domainBranding) {
            _.forEach(_.keys(domainBranding), key => {
                if (hostKey && hostKey.includes(key) && domainBranding[key]) {
                    groupCode = domainBranding[key].defaultGroupCode;
                }
            });
        }

        return groupCode;
    } catch (e) {
        logger.warn(e);
        return null;
    }
};

// TODO: Is this even used?
export const getWhitelabelEmailMeta = _.memoize((host: string | string[]) => {
    const { domainBranding } = config.public;

    let {
        siteName,
        disableServiceWorker,
        defaultLogoBackup,
        defaultLogoBackupWhite,
        brandColor1,
        brandColor2,
        copyright,
        companyAddress,
        emailFrom,
    } = config.public;

    let root_url = getRootUrl();

    if (config.email) {
        siteName = config.email.siteName;
        emailFrom = config.email.from;
    }

    try {
        if (domainBranding && host) {
            _.forEach(_.keys(domainBranding), key => {
                if (host.includes(key)) {
                    if (domainBranding[key]) {
                        const {
                            root_url: _root_url,
                            siteName: _siteName,
                            defaultLogoBackup: _defaultLogoBackup,
                            defaultLogoBackupWhite: _defaultLogoBackupWhite,
                            brandColor1: _brandColor1,
                            brandColor2: _brandColor2,
                            copyright: _copyright,
                            companyAddress: _companyAddress,
                            emailFrom: _emailFrom,
                        } = domainBranding[key];
                        if (_siteName) siteName = _siteName;
                        if (_defaultLogoBackup) defaultLogoBackup = _defaultLogoBackup;
                        if (_defaultLogoBackupWhite) {
                            defaultLogoBackupWhite = _defaultLogoBackupWhite;
                        }
                        if (_brandColor1) brandColor1 = _brandColor1;
                        if (_brandColor2) brandColor2 = _brandColor2;
                        if (_copyright) copyright = _copyright;
                        if (_companyAddress) companyAddress = _companyAddress;
                        if (_root_url) root_url = _root_url;
                        if (_emailFrom) emailFrom = _emailFrom;
                    }
                }
            });
        }
    } catch (e) {
        logger.warn(e);
    }

    return [
        root_url,
        siteName,
        defaultLogoBackup,
        defaultLogoBackupWhite,
        brandColor1,
        brandColor2,
        copyright,
        companyAddress,
        emailFrom,
    ];
});

export const getWhitelabelSiteMeta = _.memoize((): Partial<DomainBrandingType> => {
    const { domainBranding } = config.public;

    let appId = '';
    try {
        appId =
            // TODO: Where is BuildInfo coming from?
            Capacitor.isNativePlatform() &&
            typeof BuildInfo !== 'undefined' &&
            BuildInfo.packageName;
    } catch (e) {
        logger.warn(e);
    }

    const hostLocation = typeof window !== 'undefined' && window.location && window.location.host;

    const hostKey = appId || hostLocation;

    let siteMeta: Partial<DomainBrandingType> = {};

    try {
        if (domainBranding) {
            _.forEach(_.keys(domainBranding), key => {
                if (hostKey && hostKey.includes(key) && domainBranding[key]) {
                    siteMeta = domainBranding[key];
                }
            });
        }
    } catch (e) {
        logger.warn(e);
    }
    return siteMeta;
});

export const getRootUrl = () => {
    const siteMeta = getWhitelabelSiteMeta();
    const rootUrl = config.public.whitelabel_url || config.public.root_url;
    return siteMeta && 'root_url' in siteMeta ? siteMeta.root_url : rootUrl;
};

export const isInAppLink = (url: string) => {
    const siteUrl = getRootUrl();
    return url?.includes(siteUrl);
};

export const getRelativeUrl = (url: string, domain: string) => {
    /* todo replace with regex? not really ideal currently */
    return url
        ?.replace('http://', '')
        .replace('https://', '')
        .replace('www.', '')
        .replace(`//${domain}`, '')
        .replace(domain, '');
};

export const getPath = (url: string) => {
    return getRelativeUrl(url, getRootUrl());
};

export const getRootUrlsAcrossAllBrandedDomains = _.memoize(() => {
    const { root_url: mainRootUrl, domainBranding } = config.public;
    const root_urls = [mainRootUrl];

    try {
        if (domainBranding) {
            _.forEach(_.keys(domainBranding), key => {
                if (domainBranding[key]?.root_url) root_urls.push(domainBranding[key]?.root_url);
            });
        }
    } catch (e) {
        logger.warn(e);
    }

    return root_urls;
});

// Tests if is in app link, first returning truthiness, then returning relative url.
export const testInAppLink = (url?: string, skip?: boolean) => {
    if (skip) {
        return [false, undefined] as const;
    }

    const rootUrls = getRootUrlsAcrossAllBrandedDomains();

    for (const rootUrl of rootUrls) {
        if (url?.includes(rootUrl)) return [true, getRelativeUrl(url, rootUrl)] as const;
    }

    return [false, undefined] as const;
};

export const getWhitelabelPublicSettings = _.memoize(() => {
    const { domainBranding } = config.public;

    let publicSettings = { ...config.public };

    let appId = '';
    try {
        appId =
            // TODO: Where is BuildInfo coming from?
            Capacitor.isNativePlatform() &&
            typeof BuildInfo !== 'undefined' &&
            BuildInfo.packageName;
    } catch (e) {
        logger.warn(e);
    }
    const hostLocation = typeof window !== 'undefined' && window.location && window.location.host;

    const hostKey = appId || hostLocation;

    try {
        if (domainBranding) {
            _.forEach(_.keys(domainBranding), key => {
                if (hostKey && hostKey.includes(key) && domainBranding[key]) {
                    publicSettings = { ...publicSettings, ...domainBranding[key] };
                }
            });
        }
    } catch (e) {
        logger.warn(e);
    }
    return publicSettings;
});

export const getOptionalPostTypes = _.memoize(() => {
    const whiteLabelPublicSettings = getWhitelabelPublicSettings();

    return whiteLabelPublicSettings?.optionalPostTypes;
});

export const getWhitelabelImages = () => {
    const { domainBranding, defaultLogoBackup, defaultLogoBackupWhite, defaultLogoBackupWhiteAlt } =
        config.public;

    let appId = '';
    try {
        appId =
            // TODO: Where is BuildInfo coming from?
            Capacitor.isNativePlatform() &&
            typeof BuildInfo !== 'undefined' &&
            BuildInfo.packageName;
    } catch (e) {
        logger.warn(e);
    }

    const hostLocation = typeof window !== 'undefined' && window.location && window.location.host;

    const hostKey = appId || hostLocation;

    try {
        let mainLogo = '';
        let whiteLogo = '';
        let whiteLogoAlt = '';
        let defaultCoverPhoto = '';

        if (domainBranding) {
            _.forEach(_.keys(domainBranding), key => {
                if (hostKey && hostKey.includes(key) && domainBranding[key]) {
                    const {
                        defaultLogoBackup: main,
                        defaultLogoBackupWhite: backup,
                        defaultLogoBackupWhiteAlt: backupAlt,
                        defaultCoverPhoto: coverPhoto,
                    } = domainBranding[key];

                    if (main) mainLogo = main;
                    if (backup) whiteLogo = backup;
                    if (backupAlt) whiteLogoAlt = backupAlt;
                    if (coverPhoto) defaultCoverPhoto = coverPhoto;
                }
            });
        }

        return [
            mainLogo || defaultLogoBackup,
            whiteLogo || defaultLogoBackupWhite,
            whiteLogoAlt || defaultLogoBackupWhiteAlt,
            defaultCoverPhoto,
        ] as const;
    } catch (e) {
        logger.warn(e);

        return [
            defaultLogoBackup,
            defaultLogoBackupWhite,
            defaultLogoBackupWhiteAlt,
            null,
        ] as const;
    }
};

export const getInAppBrowserOptions = () => {
    const { brandColor1 } = getWhitelabelPublicSettings();
    const primaryColor = brandColor1 || '#0094f6';
    let turnOnLocation = 'yes';

    // iOS Location bar is ugly and unneeded
    if (Capacitor.isNativePlatform() && typeof window !== undefined) {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (iOSSafari) {
            turnOnLocation = 'no';
        }
    }

    return { toolbarColor: '#FFFFFF' };

    // OLD - https://cordova.apache.org/docs/en/10.x/reference/cordova-plugin-inappbrowser/
    // |
    // v
    // NEW - https://capacitorjs.com/docs/apis/browser
    // TODO - Capacitor InAppBrowser plugin does not have options for location
    //        navigationbuttoncolor, toolbarposition, usewkwebview,
    //        closebuttoncolor, hideurlbar, or zoom. How should we handle these?
    // return `location=${turnOnLocation},navigationbuttoncolor=${primaryColor},usewkwebview=yes,toolbarposition=top,footer=no,closebuttoncolor=${primaryColor},hideurlbar=true,zoom=false`;
};
