export enum Tabs {
    Wallet = 'wallet',
    PaymentHistory = 'payment_history',
    Subscription = 'subscription',
    AddCard = 'add-card',
}

export enum SubTabs {
    ViewCards = 'view-cards',
    ViewCard = 'view-card',
}

// https://stripe.com/docs/api/cards/object#card_object-brand
export enum CardBrandingTypes {
    AMERICAN_EXPRESS = 'American Express',
    DISCOVER = 'Discover',
    MASTERCARD = 'MasterCard',
    VISA = 'Visa',
    DINERS_CLUB = 'Diners Club',
    JCB = 'JCB',
    UNION_PAY = 'UnionPay',
}

// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
export enum SubscriptionStatus {
    Incomplete = 'incomplete',
    IncompleteExpired = 'incomplete_expired',
    Trialing = 'trialing',
    Active = 'active',
    PastDue = 'past_due',
    Canceled = 'canceled',
    Unpaid = 'unpaid',
}

export const getSubscriptionStatus = (
    status: SubscriptionStatus
): {
    label: string;
    color: string;
} => {
    if (status === SubscriptionStatus.Active) {
        return {
            label: 'Active Subscription',
            color: '#00ba88',
        };
    }
    if (status === SubscriptionStatus.Canceled) {
        return {
            label: 'Subscription Canceled',
            color: 'red',
        };
    }
    if (status === SubscriptionStatus.PastDue) {
        return {
            label: 'Subscription payment is past due',
            color: 'red',
        };
    }
    if (status === SubscriptionStatus.Unpaid) {
        return {
            label: 'Subscription payment is due',
            color: 'red',
        };
    }
    if (status === SubscriptionStatus.Trialing) {
        return {
            label: 'Trial Subscription',
            color: '#00ba88',
        };
    }

    return {
        label: '',
        color: '',
    };
};
