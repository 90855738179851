/**
 * @file
 * Creates new user from new createauthor form schema
 */
import React, {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {CREATE_USER} from '@shared/welibrary-graphql/user/mutations';
import Input from '@web/ui/components/generic/forms/Input.js';
import {useTranslation} from 'react-i18next';
import {useFilestack} from '@web/ui/components/generic/hooks/useFilestack';
import {IMAGE_MIME_TYPES} from '@core/utilities/constants/filestack';
import {getBackgroundImageSetForUrl, StyledBackgroundImageLink,} from '@core/utilities/constants/defaults';

const localNamespace = 'imports.wlWeb.ui.components.card.updateCard.changeAuthor';

const CreateNewAuthorForm = ({ afterSubmit }) => {
    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [valid, setValid] = useState(false);
    const [picture, setPicture] = useState(null);
    const [statusMsg, setStatusMsg] = useState({ text: null, type: null });
    const { t } = useTranslation();
    const [createNewUser, { loading, error }] = useMutation(CREATE_USER);

    const clearInputs = () => {
        setEmail(null);
        setUsername(null);
        setPicture(null);
    };

    const onSubmit = async () => {
        if (valid) {
            const newUserPayload = {
                username,
                profile: {
                    vs: '1.0.0',
                    picture,
                },
                membership: {},
                email: email?.toLowerCase(),
            };
            try {
                const { data } = await createNewUser({
                    variables: {
                        input: newUserPayload,
                    },
                    fetchPolicy: 'no-cache',
                });
                const { picture, username, _id } = data?.createUser;
                afterSubmit({ _id, profile: { picture, full_name: username } });
            } catch (err) {
                setStatusMsg({
                    type: 'error',
                    text: err?.toString().replace('GraphQL error:', ''),
                });
            } finally {
                clearInputs();
            }
        }
    };

    useEffect(() => {
        const usernameValid = username?.length > 6;
        const emailValid = validateEmail(email);
        if (usernameValid && emailValid) setValid(true);
        if (!usernameValid || !emailValid) setValid(false);
    }, [username, email]);

    const handleUsername = e => {
        clearStatusMsg();
        setUsername(e.currentTarget.value);
    };

    const clearStatusMsg = () => {
        if (statusMsg) {
            setStatusMsg({
                type: null,
                text: null,
            });
        }
    };

    const handleEmail = e => {
        clearStatusMsg();
        setEmail(e.currentTarget.value);
    };

    const handleImageUrl = imageUrl => {
        setPicture(imageUrl);
    };

    const handleFileSelect = e => {
        e.preventDefault();
        fileSelector.click();
    };

    const { fileSelector } = useFilestack({ fileType: IMAGE_MIME_TYPES, onUpload: handleImageUrl });

    const validateEmail = email => {
        const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return re.test(email);
    };

    const validateUsername = username => {
        return username?.length > 6;
    };

    const baseButtonClass = 'button callout-button w-button edit-screen-content';
    const buttonClass = valid ? baseButtonClass : `${baseButtonClass} disabled-button`;
    const [fallbackSrc, srcSet] = getBackgroundImageSetForUrl(picture || '', 60);

    const emptyBgClass = 'thumbnail w-inline-block create-author-thumb photo-empty-icon no-margin';
    const bgImageClass = 'thumbnail w-inline-block create-author-thumb photo-exist-icon no-margin';
    const bgClass = picture ? bgImageClass : emptyBgClass;

    return (
        <div className="form-container-ex">
            <p className="new-author err-msg text-center">{statusMsg.text}</p>
            <div className="flex-container">
                <div className="left-container">
                    <StyledBackgroundImageLink
                        onClick={handleFileSelect}
                        className={bgClass}
                        srcSet={srcSet}
                        src={fallbackSrc}
                    />
                </div>
                <div className="right-container">
                    <Input
                        id="username"
                        validateFunc={validateUsername}
                        validateMsg="Name must be greater than 6 characters"
                        name="username"
                        placeHolder="Enter name"
                        value={username || ''}
                        maxLength={100}
                        maxCharacter={100}
                        handleChange={handleUsername}
                        classNames="form-control text-field-normal"
                        required
                    />

                    <Input
                        id="email"
                        name="email"
                        validateFunc={validateEmail}
                        validateMsg="Invalid Email"
                        placeHolder="Enter email"
                        value={email || ''}
                        maxLength={100}
                        maxCharacter={100}
                        handleChange={handleEmail}
                        classNames="form-control text-field-normal"
                        required
                    />
                </div>
            </div>

            <button onClick={onSubmit} disabled={!valid} className={buttonClass} type="button">
                {t(`common:${localNamespace}.create_author`)}
            </button>
        </div>
    );
};

export default CreateNewAuthorForm;
