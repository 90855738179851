import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { z } from 'zod';

import {
    useCreateStripeConnectedAccountMutation,
    useGenerateConnectedAccountLoginLinkMutation,
} from '@shared/welibrary-graphql/user/mutations.hook';

import EditScreenItemContainer from '@components/generic/editscreen/EditScreenItemContainer';
import StripeBranding from '@assets/stripe-branding.png';
import PayoutDisclaimer from '@components/content/newpost/forms/ShoppingCartProducts/PayoutDisclaimer';
import Toggle from '@dsc/forms/customInputs/Toggle';

import { getCountryPicker, renderCountryFlag } from '@core/utilities/constants/countries';
import { curriedStateSlice } from '@helpers/state/state.helpers';
import { UPDATED_EMAIL_REGEX } from '@core/utilities/constants/regexes.js';
import { getRootUrl } from '@core/utilities/whitelabel_helpers';

import { Group } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

const localNamespace = 'imports.wlWeb.ui.pages.signup.signupContainer';

export type MerchantSignupState = {
    country: string;
    email: string;
};

const UpdateConnectedAccountStripeForm: React.FC<{ group: Group; handleClose: () => void }> = ({
    group,
    handleClose,
}) => {
    const { t } = useTranslation();

    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const [state, setState] = useImmer<MerchantSignupState>({
        email: group?.paymentSettings?.stripeAccount?.email || '',
        country: group?.paymentSettings?.stripeAccount?.country ?? 'US',
    });
    const [toggle, setToggle] = useState<boolean>(false);

    const updateSlice = curriedStateSlice(setState);
    const [createAccount, { loading }] = useCreateStripeConnectedAccountMutation();
    const [generateLoginLink, { loading: loginLinkLoading }] =
        useGenerateConnectedAccountLoginLinkMutation();

    const StateValidator = z.object({
        country: z.string().nonempty('Country is required!'),
        email: z
            .string()
            .regex(UPDATED_EMAIL_REGEX, `${t(`common:${localNamespace}.error.email`)}`),
    });

    const validate = () => {
        const parsedData = StateValidator.safeParse({ country: state.country, email: state.email });

        if (parsedData.success) {
            setErrors({});
            return true;
        }

        if (parsedData.error) {
            setErrors(parsedData.error.flatten().fieldErrors);
        }

        return false;
    };

    const handleCreateStripeAccount = () => {
        const currentHost = window.location.host;

        if (validate()) {
            createAccount({
                variables: {
                    input: {
                        groupId: group._id,
                        email: state.email,
                        country: state.country,
                    },
                    host: getRootUrl(),
                    reset: toggle,
                },
            })
                .then(({ data }) => {
                    handleClose();
                    // @ts-ignore
                    const { url } = data?.onboardGroupConnectedAccount;
                    window.location.href = url;
                })
                .catch(e => {
                    logger.error(e);
                });
        }
    };

    const handleGenerateConnectedAccountLoginLink = () => {
        if (validate()) {
            generateLoginLink({
                variables: {
                    groupId: group?._id,
                },
            })
                .then(({ data }) => {
                    handleClose();
                    // @ts-ignore
                    const { url } = data?.generateConnectedAccountLoginLink;
                    window.location.href = url;
                })
                .catch(e => {
                    logger.error(e);
                });
        }
    };

    let stripeAccountBtnText;

    const merchantAccountFullyActivated =
        group?.paymentSettings?.stripeAccount?.charges_enabled &&
        group?.paymentSettings?.stripeAccount?.details_submitted &&
        group?.paymentSettings?.stripeAccount?.payouts_enabled;

    // @ts-ignore
    const countryPicker = getCountryPicker({
        value: state.country,
        handleChange: (e: any) => updateSlice('country', e.target.value),
        id: 'country',
        name: 'country',
        className: 'connected-account-form-flags',
        disabled: merchantAccountFullyActivated,
    });

    if (loading) {
        stripeAccountBtnText = 'Redirecting...';
    } else if (merchantAccountFullyActivated) {
        stripeAccountBtnText = 'Update Stripe Account';
    } else {
        stripeAccountBtnText = 'Continue';
    }

    return (
        <div className="edit-screen-content-view stripe-merchant-account-flow-step">
            <div className="edit-screen-content-items">
                <div className="mc-source-post-wrapper">
                    <div className="connected-account-update-wrap">
                        {group?.paymentSettings?.stripeAccount && (
                            <div className="connected-account-links-wrap">
                                <div className="connected-account-logo-wrap">
                                    <img src={StripeBranding} alt="stripe branding" />
                                </div>
                                <div className="connected-account-btns-wrap">
                                    {merchantAccountFullyActivated && (
                                        <button
                                            onClick={handleGenerateConnectedAccountLoginLink}
                                            type="button"
                                            className="connected-account-login-link-btn"
                                        >
                                            {loginLinkLoading
                                                ? 'Redirecting...'
                                                : 'View Stripe Dashboard'}
                                        </button>
                                    )}
                                    {!merchantAccountFullyActivated && (
                                        <>
                                            <p className="connected-account-update-note">
                                                <strong>
                                                    Note: Your merchant account is incomplete. Click
                                                    continue to finish setting up your account with
                                                    stripe.
                                                </strong>
                                            </p>
                                            <div className="connected-account-reset-btn-wrap">
                                                Reset Onboarding
                                                <Toggle
                                                    onChange={() => setToggle(!toggle)}
                                                    value={toggle}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <button
                                        className="connected-account-btn"
                                        onClick={handleCreateStripeAccount}
                                        type="button"
                                    >
                                        {stripeAccountBtnText}
                                    </button>
                                </div>
                            </div>
                        )}

                        {merchantAccountFullyActivated && <PayoutDisclaimer className="mt-4" />}

                        <EditScreenItemContainer title="Email">
                            <input
                                name="email"
                                placeholder="Stripe Account Email..."
                                value={state.email}
                                maxLength={100}
                                onChange={e => updateSlice('email', e.target.value)}
                                className="connected-account-form-input"
                                required
                                type="text"
                                autoComplete="off"
                            />
                            {errors.email && <p className="stripeAccount-error">{errors.email}</p>}
                            <div className="connected-account-form-input-wrap">
                                {state.country &&
                                    renderCountryFlag({
                                        countryCode: state.country,
                                        customClass: '',
                                    })}
                                {countryPicker}
                            </div>
                        </EditScreenItemContainer>
                    </div>
                    {/* 
                        The component below is an additional form that allows
                        the admin to add additional payoutMethods (bank acct, card, etc).
                        Currently we are letting stripe's express form handle this for us
                        so this not being used at the moment 
                    */}
                    {/* merchantAccountFullyActivated && (
                        <ConnectedAccountStripePayoutMethods group={group} />
                    ) */}
                </div>
            </div>
        </div>
    );
};

export default UpdateConnectedAccountStripeForm;
