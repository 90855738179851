import React from 'react';

const SimpleLock: React.FC<{ className?: string; strokeWidth?: string; strokeColor?: string }> = ({
    className,
    strokeWidth = '3',
    strokeColor,
}) => {
    return (
        <svg
            viewBox="0 0 27 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            strokeWidth={strokeWidth}
            stroke="currentColor"
        >
            <path
                d="M21.8328 9H18.0828V6.08333C18.0828 4.86776 17.6 3.70197 16.7404 2.84243C15.8809 1.98289 14.7151 1.5 13.4995 1.5C12.2839 1.5 11.1181 1.98289 10.2586 2.84243C9.39906 3.70197 8.91618 4.86776 8.91618 6.08333V9H5.16618C4.72431 9.0005 4.30068 9.17626 3.98823 9.48871C3.67577 9.80116 3.50002 10.2248 3.49951 10.6667V22.3333C3.50002 22.7752 3.67577 23.1988 3.98823 23.5113C4.30068 23.8237 4.72431 23.9995 5.16618 24H21.8328C22.2747 23.9995 22.6983 23.8237 23.0108 23.5113C23.3232 23.1988 23.499 22.7752 23.4995 22.3333V10.6667C23.499 10.2248 23.3232 9.80116 23.0108 9.48871C22.6983 9.17626 22.2747 9.0005 21.8328 9V9ZM10.5828 6.08333C10.5828 5.30978 10.8901 4.56792 11.4371 4.02094C11.9841 3.47396 12.726 3.16667 13.4995 3.16667C14.2731 3.16667 15.0149 3.47396 15.5619 4.02094C16.1089 4.56792 16.4162 5.30978 16.4162 6.08333V9H10.5828V6.08333Z"
                fill={strokeColor}
            />
        </svg>
    );
};

export default SimpleLock;
