import { ShoppingCartProduct } from '@web/ui/stores/ShoppingCart';
import shoppingCartStore from '@web/ui/stores/ShoppingCart';
import { getCurrencyDisplayString } from '@web/utilities/helpers/money/money.helpers';
import { Product, CustomerOrder } from '@shared/welibrary-graphql/types';

export type UserDetailsState = {
    name: string;
    email: string;
    phoneNumber?: string | undefined;
    password?: string | undefined;
};

export type DiscountCodeState = {
    code: string;
    isApplied: boolean | null;
    percentage: number | undefined;
    minChageAmount: number;
    error: any;
};

export type CouponPayload = {
    productId: string;
    couponCode: string;
};

export const SHOPPING_CART = 'shoppingCart';
export const ORDER = 'order';
export const PAYMENT = 'payment';
export const SHARING = 'sharing';

export enum CHECKOUT_STATE {
    CHECKING_OUT = 'CHECKING_OUT',
    PENDING_FULFILLMENT = 'PENDING_FULFILLMENT',
    FULFILLMENT_ERROR = 'FULFILLMENT_ERROR',
    ORDER_COMPLETE = 'ORDER_COMPLETE',
}

export const convertCentsToDollars = (cents: number) => {
    if (!cents) return 0;
    return cents / 100;
};

export const getDiscountedTotal = (
    total: number,
    percentage: number,
    currency: string = 'usd',
    minChargeAmount: string
) => {
    let adjustedPrice: number = total - total * percentage;

    // check if the currency the ticket being solid as, has a min charge
    // if a coupon is applied and the price of a ticket goes below the min charge price
    // floor the price to its allowed min price, this is to offset stripe fees
    const hasMinCharge = minChargeAmount;

    if (hasMinCharge) {
        const _minChargeAmount = Number(minChargeAmount) * 100;

        if (adjustedPrice < _minChargeAmount && percentage) {
            adjustedPrice = _minChargeAmount;
        }
        if (percentage === 1) return 0;

        return convertCentsToDollars(adjustedPrice).toFixed(2);
    }

    // if the price of a ticket goes below .99 after a discount is applied
    // floor the price to $1.00, this is to offset stripe fees

    // if a discount percentage is <= 0.99 dont allow the adjustedPrice to go below $1.00
    if (adjustedPrice < 100 && percentage < 1) {
        adjustedPrice = 100;
    }

    return convertCentsToDollars(adjustedPrice).toFixed(2);
};

export const getRawDiscountedTotal = (
    total: number,
    percentage: number,
    currency: string = 'usd',
    minChargeAmount: string
) => {
    let adjustedPrice: number = total - total * percentage;

    // check if the currency the ticket being solid as, has a min charge
    // if a coupon is applied and price of a ticket goes below the min charge price
    // floor the price to its min price, this is to offset stripe fees
    const hasMinCharge = minChargeAmount;

    if (hasMinCharge) {
        const _minChargeAmount = Number(minChargeAmount) * 100;

        if (adjustedPrice < _minChargeAmount && percentage) {
            adjustedPrice = _minChargeAmount;
        }
        if (percentage === 1) return 0;

        return adjustedPrice;
    }

    // if the price of a ticket goes below .99 after a discount is applied
    // floor the price to $1.00, this is to offset stripe fees

    // if a discount percentage is <= 0.99 dont allow the adjustedPrice to go below $1.00
    if (adjustedPrice < 100 && percentage < 1) {
        adjustedPrice = 100;
    }

    return adjustedPrice;
};

export const getTotal = (
    cartItem: Product,
    quantity: number,
    percentage?: number,
    currency: string = 'usd'
) => {
    if (!cartItem || !quantity) return '0.00';
    const total: number = cartItem.price * quantity;

    const groupID = cartItem?.fulfillment?.groupId;
    const shoppingCartProducts =
        shoppingCartStore?.get?.shoppingCarts()?.find(({ groupId }) => {
            return groupId === groupID;
        })?.products ?? [];

    const minCharge = shoppingCartProducts.find(p => !!p.coupon?.minChargeAmount)?.coupon
        ?.minChargeAmount;

    if (percentage && minCharge) {
        return getDiscountedTotal(total, percentage, currency, minCharge);
    }

    return convertCentsToDollars(total).toFixed(2);
};

export const getRawTotal = (
    cartItem: Product,
    quantity: number,
    percentage?: number,
    currency: string = 'usd'
) => {
    if (!cartItem || !quantity) return 0;
    const total: number = cartItem.price * quantity;

    const groupID = cartItem?.fulfillment?.groupId;
    const shoppingCartProducts =
        shoppingCartStore?.get?.shoppingCarts()?.find(({ groupId }) => {
            return groupId === groupID;
        })?.products ?? [];

    const minCharge = shoppingCartProducts.find(p => !!p.coupon?.minChargeAmount)?.coupon
        ?.minChargeAmount;

    if (percentage && minCharge) {
        return getRawDiscountedTotal(total, percentage, currency, minCharge);
    }

    return total;
};

export const getShoppingCartTotal = (
    cartItems: ShoppingCartProduct[] = [],
    isEventFree = false
) => {
    if (cartItems?.length === 0) return '0.00';
    if (isEventFree) return '0.00';

    let total = 0;
    cartItems.forEach(({ product, quantity }) => {
        total += getRawTotal(product, quantity);
    });

    return convertCentsToDollars(total).toFixed(2);
};

export const getDiscountedShoppingCartTotal = (
    cartItems: ShoppingCartProduct[] = [],
    isEventFree = false
) => {
    if (cartItems?.length === 0) return '0.00';
    if (isEventFree) return '0.00';

    let total = 0;
    cartItems.forEach(({ product, quantity, coupon }) => {
        total += getRawTotal(product, quantity, coupon?.percentage, product?.currency);
    });

    return convertCentsToDollars(total).toFixed(2);
};

export const isOrderFree = (total: number): boolean => {
    if (!total) return false;
    return Number(total) === 0;
};

export const getOrderTotal = (order: CustomerOrder) => {
    // moved this logic to the backend and put it in discountedTotal because non-admins don't
    // have access to the coupons on the frontend
    if (order.discountedTotal) return order.discountedTotal;

    const total = order.purchaseRequests.reduce((total, purchaseRequest) => {
        const {
            quantity,
            couponCode,
            product: { price, coupons },
        } = purchaseRequest;

        let discountPercentage = 0;

        if (couponCode && coupons?.length > 0) {
            const coupon = coupons.find(c => c.code === couponCode);
            discountPercentage = coupon?.percentage;
        }

        const productPricePercentage = 1 - discountPercentage;
        const ticketPrice = price * productPricePercentage;

        return (total += quantity * ticketPrice);
    }, 0);
    return total;
};

export const getOrderTotalDisplayString = (order: CustomerOrder) => {
    const total = getOrderTotal(order);

    const displayString = getCurrencyDisplayString(
        total,
        order.paymentIntent?.currency || order.products[0].currency,
        undefined,
        false,
        2
    );

    return displayString;
};
