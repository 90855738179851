import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useCurrentUser } from '@stores/User';
import useGroupActions from '@web/ui/components/group/hooks/useGroupActions.js';
import useUserActions from '@web/ui/components/profile/useUserActions';
import useModal from '@web/ui/components/modals/hooks/useModal';

import Picture from '@web/ui/components/generic/Picture';
import QuickProfileView from '@web/ui/components/profile/QuickProfileView/QuickProfileView';

import { isGroupPrivate } from '@helpers/recommendations/recommendations.helpers';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    getRandomDefaultUserImage,
} from '@core/utilities/constants/defaults';

import { truncate } from '@helpers/string.helpers';
import { getContentCardHeader } from '@components/discover/constants';

import { User, ContentCard, Group, Recommendation } from '@shared/welibrary-graphql/types';

const handlePercentColor = (percent: number) => {
    if (percent >= 0.75) {
        return '#00BA88'; // green
    }
    if (percent <= 0.74 && percent >= 0.5) {
        return '#0094F6'; // blue
    }
    if (percent <= 0.49) {
        return '#F4B740'; // yellow
    }

    return '';
};

type RecommendationItemProps = {
    key: string;
    source: User | ContentCard | Group;
    matchData: Recommendation;
    itemId?: string;
};

const RecommendationItem: React.FC<RecommendationItemProps> = ({ source, matchData }) => {
    const history = useHistory();
    const { t } = useTranslation();
      const { currentUser } = useCurrentUser();
    const { newModal, closeModal } = useModal();

    const { primaryActionAvailable, takeGroupActionForUser } = useGroupActions({
        group: source?.__typename === 'Group' ? source : null,
    });

    const { handleConnectClick, getConnectActionText } = useUserActions(
        source?.__typename === 'User' ? source : null
    );

    let cardHeader;
    let matchingSection;
    let actionButton;

    if (matchData) {
        const { precision, featureScores } = matchData;

        let matchMessage;

        let orderedFeatureScores = _.sortBy(featureScores, feature => feature.score); // sort matches by score
        orderedFeatureScores = _.filter(orderedFeatureScores, feature => feature.score > 0); // filter out scores greater then 0

        if (orderedFeatureScores && orderedFeatureScores.length >= 1) {
            const highMatchMessage = orderedFeatureScores[0].field?.customText
                ? orderedFeatureScores[0].field.customText
                : orderedFeatureScores[0].field.prompt;
            matchMessage = highMatchMessage;

            if (orderedFeatureScores.length === 1) {
                matchMessage = highMatchMessage;
            } else if (orderedFeatureScores.length > 1) {
                const lowMatchMessage = orderedFeatureScores[1].field?.customText
                    ? orderedFeatureScores[1].field.customText
                    : orderedFeatureScores[1].field.prompt;

                matchMessage = (
                    <>
                        <strong>{highMatchMessage}</strong>, <strong>{lowMatchMessage}</strong>.
                    </>
                );
            }
        }

        const _matchMessage = truncate(matchMessage, 30);

        matchingSection = (
            <div className="discover-modal-list-item-percent-wrap">
                <h1 style={{ color: handlePercentColor(precision) }}>
                    {Math.round(precision * 100)}%
                </h1>{' '}
                <p>
                    {t('common:imports.wlWeb.ui.components.discover.matched_for')}{' '}
                    <span>{_matchMessage}</span>
                </p>
            </div>
        );
    }

    switch (source.__typename ? source.__typename : source.type) {
        case 'ContentCard':
            cardHeader = getContentCardHeader(source, history, closeModal);

            actionButton = (
                <div className="discover-modal-list-item-btn-wrap">
                    <button
                        onClick={() => {
                            history.push(`/c/${source?.url}`);
                            closeModal();
                        }}
                        type="button"
                    >
                        View Card
                    </button>
                </div>
            );
            break;
        case 'Group':
            const connectedShelf = _.has(source, 'shelf') && source.shelf ? source.shelf : null;

            let groupName = source.profile ? source.profile.full_name : '';
            let coverImg = source.profile.picture
                ? source.profile.picture
                : getRandomDefaultCoverImage(groupName);
            if (connectedShelf) {
                coverImg = connectedShelf.media.icon ? connectedShelf.media.icon : coverImg;
                groupName = connectedShelf.title ? connectedShelf.title : groupName;
            }

            const [coverSrc] = getBackgroundImageSetForUrl(
                coverImg || getRandomDefaultCoverImage()
            );

            const groupIsPrivate = isGroupPrivate(source);

            cardHeader = (
                <div
                    style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${coverSrc})`,
                    }}
                    onClick={() => {
                        history.push(`/g/${source._id}`);
                        closeModal();
                    }}
                    className="discover-modal-list-item-group-cover-wrap"
                >
                    <h3 className="discover-modal-list-item-cover-title">
                        {groupName}
                        {groupIsPrivate && (
                            <span
                                className="community-recommendation-private-icon w-inline-block x"
                                title="Private group"
                            />
                        )}
                    </h3>
                </div>
            );

            if (primaryActionAvailable?.label) {
                // group primary action button
                actionButton = (
                    <div className="discover-modal-list-item-btn-wrap">
                        <button
                            onClick={() =>
                                takeGroupActionForUser(
                                    currentUser._id,
                                    primaryActionAvailable.action
                                )
                            }
                            type="button"
                        >
                            {primaryActionAvailable.label}
                        </button>
                    </div>
                );
            }

            break;
        case 'User':
            const user = source?.__typename === 'User' ? source : null;

            const name = source.profile ? source.profile.full_name : '';
            const thumbImage = source.profile.picture
                ? source.profile.picture
                : getRandomDefaultUserImage();

            const [thumbSrc] = getBackgroundImageSetForUrl(
                thumbImage || getRandomDefaultUserImage()
            );

            cardHeader = (
                <div
                    onClick={() =>
                        newModal(<QuickProfileView user={source} />, {
                            className: 'no-top-padding white-notch',
                        })
                    }
                    className="discover-modal-list-item-thumbnail-wrap"
                >
                    <Picture
                        disableLink
                        className={`${
                            !source.profile.picture
                                ? 'discover-modal-list-item-thumbnail-alt'
                                : 'discover-modal-list-item-thumbnail'
                        }`}
                        url={thumbSrc}
                        resolutions={[130, 260, 390]}
                    />
                    <div className="discover-modal-list-item-name-wrap">
                        <h3>{name}</h3>
                    </div>
                </div>
            );

            actionButton = (
                <div className="discover-modal-list-item-btn-wrap">
                    <button onClick={() => handleConnectClick(user)} type="button">
                        {getConnectActionText(user)}
                    </button>
                </div>
            );

            break;
        default:
            break;
    }

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li className="discover-modal-list-item">
            {/* hide X btn for now */}
            {/* <button
                className="discover-modal-list-item-btn-reject"
                type="button"
                style={{ color: source.__typename === 'Group' ? '#fff' : '#000' }}
            >
                X
            </button> */}
            {cardHeader}
            {matchingSection}
            {actionButton}
        </li>
    );
};

export default RecommendationItem;
