import { FsRequestError } from 'filestack-js';
import React, { MouseEvent, useEffect, useState, useRef } from 'react';

import { client, UploadOptions } from '@components/generic/ReactFileStackClient';

import useResizePhoto from '@components/generic/hooks/useResizePhoto';

import { config } from '@config/getConfig';

import getLogger from '@core/logger';

const logger = getLogger(module);

export const fileStackConfig = config.public.filestack;
export const filestack = client.init(fileStackConfig.api_key, { sessionCache: false });

export type UploadRes = {
    filename: string;
    handle: string;
    mimetype: string;
    size: number;
    status: string;
    url: string;
};

export type Token = {
    cancel: () => void;
    pause: () => void;
    resume: () => void;
};

type UseFileStack = (args: {
    onToken?: (token: Token) => void;
    onUpload: (url: string, file: File, res: UploadRes) => void;
    onFileSelected?: (file: File) => void;
    onPhotoResized?: (file: File) => void;
    fileType?: string | string[];
    options?: UploadOptions;
    resizeBeforeUploading?: boolean;
}) => {
    fileSelector: HTMLInputElement | null;
    handleFileSelect: (e?: MouseEvent) => void;
    isLoading: boolean;
    error: any;
    directUploadFile: (file: File) => void;
};

export const useFilestack: UseFileStack = ({
    onToken,
    onUpload,
    onFileSelected,
    onPhotoResized,
    fileType = 'image/*',
    options = { intelligent: true },
    resizeBeforeUploading = false,
}) => {
    const resizePhoto = useResizePhoto();
    const [fileSelector, setFileSelector] = useState<HTMLInputElement | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const token = useRef<Token>({ cancel: () => {}, pause: () => {}, resume: () => {} });

    const fileSelectorRef = useRef<HTMLInputElement>();

    const getFileToUpload = async (file: File) => {
        if (!resizeBeforeUploading) return file;

        try {
            if (file.length > 1) {
                return file;
            }

            let isCancelled = false;

            token.current.cancel = () => {
                isCancelled = true;
            };

            onToken?.(token.current);

            const newFile = await resizePhoto(file);

            return !isCancelled && newFile;
        } catch (e) {
            logger.error(e);
            setError(e);
            return false;
        }
    };

    const singleImageUpload = (file: File) => {
        filestack // single image upload
            .upload(file, { ...options, intelligent: true }, {}, token.current)
            .then((res: UploadRes) => onUpload(res.url, file, res))
            .catch(e => {
                logger.error('Filestack Upload Error', e);
                setError(e);
            })
            .finally(() => setIsLoading(false));

        onToken?.(token.current);
    };

    const directUploadFile = async (file: File) => {
        setIsLoading(true);

        const fileToUpload = await getFileToUpload(file);

        if (!fileToUpload) {
            setIsLoading(false);
            return;
        }

        if (resizeBeforeUploading) onPhotoResized?.(fileToUpload);

        singleImageUpload(fileToUpload);
    };

    const uploadFile: GlobalEventHandlers['onchange'] = async event => {
        let file;

        if ((<HTMLInputElement>event.target).files?.length > 1) {
            file = (<HTMLInputElement>event.target).files;
        } else {
            file = (<HTMLInputElement>event.target).files?.[0];
        }

        if (!file) return;

        onFileSelected?.(file);

        setIsLoading(true);

        const fileToUpload = await getFileToUpload(file);

        if (!fileToUpload) {
            setIsLoading(false);
            return;
        }

        if (resizeBeforeUploading) onPhotoResized?.(fileToUpload);

        if (file.length > 1) {
            filestack // multi image upload
                .multiupload(fileToUpload, { ...options, intelligent: true }, {}, token.current)
                .then((res: UploadRes) => onUpload(res.url, fileToUpload, res))
                .catch(e => {
                    logger.error('Filestack Upload Error', e);
                    setError(e);
                })
                .finally(() => setIsLoading(false));

            onToken(token.current);
        } else {
            singleImageUpload(fileToUpload);
        }
    };

    const handleFileSelect = (e?: MouseEvent) => {
        e?.preventDefault();
        fileSelector?.click();
    };

    const handleEvent = (e: globalThis.MouseEvent) => {
        if (e) {
            (e.target as HTMLInputElement).value = '';
        }
    };

    useEffect(() => {
        const selectorElement = document.createElement('input');
        selectorElement.id = 'file-selector';
        selectorElement.setAttribute('type', 'file');
        selectorElement.setAttribute('accept', fileType.toString());
        // fileSelector.setAttribute('multiple', 'multiple');
        // todo not sure how to fix this type error - MouseEvent not assignable to MouseEvent<Element>
        selectorElement.onclick = handleEvent;
        selectorElement.onchange = uploadFile;
        setFileSelector(selectorElement);
        fileSelectorRef.current = selectorElement;
    }, []);

    return {
        fileSelector,
        fileSelectorRef,
        handleFileSelect,
        isLoading,
        error,
        directUploadFile,
    };
};

export default useFilestack;
