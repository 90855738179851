import React from 'react';
import { Updater } from 'use-immer';
import { useTranslation } from 'react-i18next';

import Select from '@dsc/forms/customInputs/Select';
import Toggle from '@dsc/forms/customInputs/Toggle';
import Checkbox from '@dsc/forms/customInputs/Checkbox';
import ExternalLink from '@components/generic/ExternalLink';
import EventGroupCardPreview from '@components/content/newpost/forms/EventGroup/EventGroupCardPreview';

import { VIEW_PERMISSION_PUBLIC, VIEW_PERMISSION_PRIVATE } from '@core/utilities/constants/roles';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { capitalizedDisplayValues } from '@helpers/dsc/dsc.helpers';

import {
    EventGroupFormState,
    PreviewFormState,
    EventGroupFormSettingsState,
} from '@core/types/EventGroup';

import { config } from '@core/config/getConfig';

// state here is the entire main state
type EventDatePreviewStepProps = {
    state: EventGroupFormState;
    previewState: PreviewFormState;
    groupSettingsState: EventGroupFormSettingsState;
    setState: Updater<PreviewFormState>;
    setSettingsState: Updater<EventGroupFormSettingsState>;
    updateValidationSlice: Updater<boolean>;
    ticketCurrency?: string;
};

const AVAILABLE_PERMISSION_OPTIONS = [VIEW_PERMISSION_PUBLIC, VIEW_PERMISSION_PRIVATE];

const localNamespace = 'imports.wlWeb.ui.components.eventGroup.groupSettings';

const EventDatePreviewStep = React.forwardRef<HTMLFieldSetElement, EventDatePreviewStepProps>(
    function EventGroupDateAndTimeForm(
        {
            state,
            previewState,
            groupSettingsState,
            setSettingsState,
            setState,
            updateValidationSlice,
            ticketCurrency
        },
        ref
    ) {
        const { t } = useTranslation();

        const updateSettingsSlice = curriedStateSlice(setSettingsState);
        const updateSlice = curriedStateSlice(setState);

        const handleSelectPrivacyLevel = (newProtectionLevel: string) => {
            const { protectionLevel: oldProtectionLevel, approvalRequired } = groupSettingsState;

            if (
                oldProtectionLevel === VIEW_PERMISSION_PUBLIC &&
                newProtectionLevel === VIEW_PERMISSION_PRIVATE &&
                !approvalRequired
            ) {
                updateSettingsSlice('approvalRequired', true);
            }
            if (
                oldProtectionLevel === VIEW_PERMISSION_PRIVATE &&
                newProtectionLevel === VIEW_PERMISSION_PUBLIC &&
                approvalRequired
            ) {
                updateSettingsSlice('approvalRequired', false);
            }

            updateSettingsSlice('protectionLevel', newProtectionLevel);
        };

        return (
            <fieldset ref={ref} className="event-group-card-preview">
                <section>
                    <EventGroupCardPreview
                        state={state}
                        groupSettingsState={groupSettingsState}
                        previewState={previewState}
                        displayRawPrices
                        ticketCurrency={ticketCurrency}
                    />

                    <div className="event-protection-level-description">
                        {groupSettingsState?.protectionLevel === VIEW_PERMISSION_PUBLIC && (
                            <>
                                {t(
                                    `common:${localNamespace}.public_description`,
                                    'This event is public and anyone can view or join.'
                                )}
                            </>
                        )}

                        {groupSettingsState?.protectionLevel === VIEW_PERMISSION_PRIVATE && (
                            <>
                                {t(
                                    `common:${localNamespace}.private_description`,
                                    'This event is private and only attendees can view its content.'
                                )}
                            </>
                        )}
                    </div>
                    <section>
                        <Select
                            title="Event Privacy"
                            prompt="Privacy"
                            className="event-privacy-setting"
                            value={groupSettingsState?.protectionLevel}
                            onChange={handleSelectPrivacyLevel}
                            displayValues={capitalizedDisplayValues(AVAILABLE_PERMISSION_OPTIONS)}
                            options={AVAILABLE_PERMISSION_OPTIONS}
                        />
                    </section>

                    {groupSettingsState?.protectionLevel === VIEW_PERMISSION_PRIVATE && (
                        <section>
                            <label
                                className="approval-toggle event-settings-toggle"
                                htmlFor="approval-toggle"
                            >
                                <p>
                                    {t(
                                        `common:${localNamespace}.approval_required`,
                                        'Approval Required'
                                    )}
                                </p>
                                <Toggle
                                    id="approval-toggle"
                                    value={groupSettingsState?.approvalRequired}
                                    onChange={option =>
                                        updateSettingsSlice('approvalRequired', option)
                                    }
                                />
                            </label>
                            <p>
                                {t(
                                    `common:${localNamespace}.approval_required_description_3`,
                                    `If enabled, users must request to join this event. If disabled, users can join this event as if it were public.`
                                )}
                            </p>
                        </section>
                    )}
                    <section className="attendee-visibility-toggle">
                        <div>
                            <label
                                className="approval-toggle event-settings-toggle"
                                htmlFor="attendee-count-toggle"
                            >
                                <p>
                                    {t(
                                        `common:${localNamespace}.hide_attendee_count`,
                                        'Hide Attendee Count'
                                    )}
                                </p>
                                <Toggle
                                    id="attendee-count-toggle"
                                    value={groupSettingsState?.hideAttendeeCount}
                                    onChange={option =>
                                        updateSettingsSlice('hideAttendeeCount', option)
                                    }
                                />
                            </label>
                            <p>
                                {t(
                                    `common:${localNamespace}.attendee_count_toggle_description`,
                                    `If enabled, the attendee count for your event will be hidden. If disabled (default), the attendee count for your event will be visible`
                                )}
                            </p>
                        </div>
                    </section>
                    {config.public.eventOrganizerTermsOfUseUrl && (
                        <div className="terms-of-use-wrapper">
                            <label
                                htmlFor="terms-of-use-checkbox"
                                className="terms-of-use-checkbox generic-checkbox"
                            >
                                <Checkbox
                                    id="terms-of-use-checkbox"
                                    value={previewState.agreedToTerms}
                                    onChange={() => {
                                        const newVal = !previewState.agreedToTerms;
                                        updateValidationSlice(newVal);
                                        updateSlice('agreedToTerms', newVal);
                                    }}
                                />
                                <span>
                                    {t(`common:${localNamespace}.i_agree`, 'I agree to the')}{' '}
                                    <ExternalLink to={config.public.eventOrganizerTermsOfUseUrl}>
                                        {t(
                                            `common:${localNamespace}.event_terms`,
                                            'Event Terms of Use'
                                        )}
                                    </ExternalLink>
                                </span>
                            </label>
                        </div>
                    )}
                </section>
            </fieldset>
        );
    }
);

export default EventDatePreviewStep;
