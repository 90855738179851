import { useState } from 'react';
import { useImmer, Updater } from 'use-immer';

import { curriedStateSlice } from '@web/utilities/helpers/state/state.helpers';

import {
    CourseCardSettingsState,
    LevelState,
    StepState,
    CourseFormState,
    CourseState,
} from '@core/types/Courses';
import { SurveyQuestionFormState } from '@core/types/Surveys';
import { ContentCard, Level, Resource, Step, SurveyQuestion } from '@shared/welibrary-graphql/types';

/** Generates a default LevelState object */
const defaultLevel: LevelState = {
    title: 'New Level',
    media: { image: '' },
    video: '',
    resource: Resource.Wood,
    steps: [
        {
            text: 'Step 1',
            useImage: false,
            image: { image: '' },
            useVideo: false,
            video: '',
            fullPage: false,
            pageBreak: false,
            skill: '',
        },
    ],
    preSurvey: [],
    postSurvey: [],
};

const stepToStepState = (step: Step): StepState => ({
    text: step.text ?? 'Step 1',
    useImage: step.useImage ?? false,
    image: { image: step.image ?? '' },
    useVideo: step.useVideo ?? false,
    video: step.video ?? '',
    fullPage: step.fullPage ?? false,
    pageBreak: step.pageBreak ?? false,
    skill: step.skill ?? undefined,
    skillSubtype: step.skillSubtype ?? undefined,
    skillMilestone: step.skillMilestone ?? undefined,
});

const surveyQuestionToSurveyQuestionState = (
    surveyQuestion: SurveyQuestion
): SurveyQuestionFormState => ({
    _id: surveyQuestion._id ?? undefined,
    type: surveyQuestion.type,
    subtype: surveyQuestion.subtype ?? undefined,
    prompt: surveyQuestion.prompt,
    useImage: surveyQuestion.useImage ?? false,
    image: { image: surveyQuestion.image ?? '' },
    range: surveyQuestion.range ?? undefined,
    answers: surveyQuestion.answers ?? undefined,
    correctAnswer: surveyQuestion.correctAnswer ?? undefined,
    skill: surveyQuestion.skill ?? undefined,
    skillSubtype: surveyQuestion.skillSubtype ?? undefined,
    skillMilestone: surveyQuestion.skillMilestone ?? undefined,
});

/** Converts a level, as returned by GraphQL, and converts it into a valid LevelState object */
const levelToLevelState = (level: Level): LevelState => ({
    title: level.title,
    media: { image: level.media ?? '' },
    video: level.video ?? '',
    resource: level.resource,
    steps: level.steps.map(stepToStepState) ?? defaultLevel.steps,
    preSurvey: level.preSurvey?.map(surveyQuestionToSurveyQuestionState) ?? [],
    postSurvey: level.postSurvey?.map(surveyQuestionToSurveyQuestionState) ?? [],
});

/**
 * Builds up all the necessary state for displaying a Course Form, optionally taking in an existing
 * course card to edit
 */
const useCourseFormState = (cardToUpdate?: Partial<ContentCard>): CourseState => {
    const [cardSettings, setCardSettings] = useImmer<CourseCardSettingsState>({
        displayInNewsfeed: cardToUpdate?.library_settings?.displayInNewsfeed ?? true,
        rankOrder: cardToUpdate?.rankOrder ?? undefined,
    });

    const [card, setCard] = useImmer<CourseFormState>({
        title: cardToUpdate?.title ?? 'New Course',
        headline: cardToUpdate?.body ?? '',
        description: cardToUpdate?.description ?? '',
        thumbnail: cardToUpdate?.media?.icon ?? '',
        coverImage: cardToUpdate?.media?.thumb ?? '',
        link: cardToUpdate?.links?.source ?? '',
        materials: cardToUpdate?.courseItems?.materials ?? [''],
        ageGroup: cardToUpdate?.courseItems?.ageGroup ?? [0, 6],
        duration: cardToUpdate?.courseItems?.duration ?? [0, 15],
        people: cardToUpdate?.courseItems?.people ?? '',
        publicCompletions: cardToUpdate?.courseItems?.publicCompletions ?? false,
    });

    const [levels, setLevels] = useImmer<LevelState[]>(
        cardToUpdate?.courseItems?.levels.map(levelToLevelState) ?? [defaultLevel]
    );

    const [activeLevel, setActiveLevel] = useState<false | number>(false);

    const addLevel = () => {
        setLevels(oldLevels => {
            // scroll to new level's details screen
            setActiveLevel(oldLevels.length);

            oldLevels.push(defaultLevel);
        });
    };

    const deleteLevel = (index: number) => {
        setLevels(oldLevels => {
            oldLevels.splice(index, 1);

            // don't allow courses with 0 levels
            if (oldLevels.length === 0) oldLevels.push(defaultLevel);
        });
    };

    const updateLevel: Updater<LevelState> = stateAction => {
        if (activeLevel === false) return;

        curriedStateSlice(setLevels)(activeLevel, stateAction);
    };

    return {
        settings: { cardSettings, setCardSettings },
        card: { card, setCard },
        levels: { levels, addLevel, deleteLevel, updateLevel, activeLevel, setActiveLevel },
    };
};

export default useCourseFormState;
