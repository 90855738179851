import React, { FormEventHandler } from 'react';
import { Updater } from 'use-immer';

import Toggle from '@web/ui/dsc/forms/customInputs/Toggle';
import ImageUpload from '@web/ui/dsc/forms/customInputs/ImageUpload';
import Select from '@web/ui/dsc/forms/customInputs/Select';
import RRBuilderColorPicker from '@web/ui/components/rainbowroad/builder/RRBuilderColorPicker';

import { curriedStateSlice } from '@web/utilities/helpers/state/state.helpers';
import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';
import { capitalizedDisplayValues } from '@web/utilities/helpers/dsc/dsc.helpers';

import { RainbowRoadBuilderNodesState } from '@core/types/RainbowRoad';
import { RrCircleSize, RrLineStyle } from '@shared/welibrary-graphql/types';

type RRBuilderNodesProps = {
    title: string;
    state: RainbowRoadBuilderNodesState;
    setState: Updater<RainbowRoadBuilderNodesState>;
    onSubmit: FormEventHandler<HTMLFormElement>;
};

const RRBuilderNodes = React.forwardRef<HTMLFormElement, RRBuilderNodesProps>(
    function RRBuilderNodes({ title, state, setState, onSubmit }, ref) {
        const updateSlice = curriedStateSlice(setState);

        return (
            <form
                className="rr-builder-nodes"
                onSubmit={e => {
                    e.preventDefault();
                    onSubmit(e);
                }}
                ref={ref}
            >
                <h1>Node Settings</h1>

                <label>
                    Override Main Icon
                    <Toggle value={state.useIcon} onChange={updateSlice('useIcon')} />
                </label>

                {state.useIcon && (
                    <ImageUpload
                        state={{
                            ...state.icon,
                            image: state.icon.image || getRandomDefaultCoverImage(title),
                        }}
                        setState={updateSlice('icon')}
                        className="rr-main-node-icon"
                        loaderSize={100}
                    />
                )}

                <label>
                    Show Node Outline
                    <Toggle value={state.outline} onChange={updateSlice('outline')} />
                </label>

                <label>
                    Wind Road
                    <Toggle value={state.winding} onChange={updateSlice('winding')} />
                </label>

                <Select
                    title="Circle Size"
                    prompt="Choose a circle size"
                    value={state.circleSize}
                    onChange={circleSize => updateSlice('circleSize', circleSize)}
                    options={Object.values(RrCircleSize)}
                    displayValues={capitalizedDisplayValues(Object.values(RrCircleSize))}
                />

                <Select
                    title="Line Style"
                    prompt="Choose a line style"
                    value={state.lineStyle}
                    onChange={lineStyle => updateSlice('lineStyle', lineStyle)}
                    options={Object.values(RrLineStyle)}
                    displayValues={capitalizedDisplayValues(Object.values(RrLineStyle))}
                />

                <RRBuilderColorPicker
                    state={state.lineColor}
                    setState={updateSlice('lineColor')}
                    values={[
                        '#fff',
                        'var(--color-grayscale-label)',
                        'var(--color-grayscale-body)',
                        '#000',
                        'var(--color-wl-primary)',
                        'var(--color-wl-secondary',
                    ]}
                    title="Line Color"
                    showCircles={false}
                />
            </form>
        );
    }
);

export default RRBuilderNodes;
