import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import _, { flowRight as compose, debounce } from 'lodash';

import useConfirmation from '@components/generic/hooks/useConfirmation';
import { useToastActionsContext } from '@components/toast/NewToastContext';

import EditScreenCustomRole from '@web/ui/components/generic/editscreen/EditScreenCustomRole';

import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary';

import { LIST_MEMBERS } from '@shared/welibrary-graphql/user/queries';
import { FILTER_AZ } from '@core/utilities/constants/library_settings';

const DEFAULT_LIMIT = 10;
const localNamespace = 'imports.wlWeb.ui.components.card.darkForms.memberManagementContainer';

const MemberManagementContainer = ({
    onSearch,
    onMoreSearch,
    url,
    roles,
    data,
    loading,
    error,
    customRoleOptions,
    showCustomRoles,
}) => {
    const { t } = useTranslation();
    const confirm = useConfirmation();
    const { newToast } = useToastActionsContext();

    const { listMembers, noMore } = data;
    const _listMembers = listMembers ?? [];

    const [query, setQuery] = useState('');
    const [limit] = useState(DEFAULT_LIMIT);
    const [sortType] = useState(FILTER_AZ);
    const [customRoleFilter] = useState([]);
    const [showFilters] = useState(false);

    const runSearchQuery = (searchQuery, more) => {
        let fullQuery;

        // If the user has filters activated
        if (showFilters) {
            fullQuery = {
                url,
                searchQuery,
                limit,
                roles,
                sortType,
                customRoles: customRoleFilter,
            };
        } else {
            fullQuery = {
                url,
                searchQuery,
                limit,
                roles,
            };
        }
        if (more) {
            fullQuery.offset = _listMembers.length;
            onMoreSearch(fullQuery);
        } else {
            onSearch(fullQuery);
        }
    };

    // Debounced for
    const handleSearchQueryDebounced = debounce(searchQuery => {
        // Build query based on passed fields
        runSearchQuery(searchQuery);
    }, 250);

    const handleQueryInputChange = e => {
        const { value } = e.target;
        handleSearchQueryDebounced(value);
        setQuery(value);
    };

    // If the user changes a filter, re-run the search query
    useEffect(() => {
        runSearchQuery(query);
    }, [sortType, customRoleFilter, showFilters]);

    const searchProfileCards = _.map(_listMembers, user => (
        <EditScreenCustomRole
            name="Role"
            showCustomRoles={showCustomRoles}
            allCustomRoles={customRoleOptions}
            member={user}
            url={url}
            confirm={confirm}
            newToast={newToast}
        />
    ));

    const searchPlaceholder = t(`common:${localNamespace}.add_new_admins`);
    // let sortTypeOptions = [
    //   { text: 'A-Z', value: FILTER_AZ },
    //   { text: 'Account Created', value: FILTER_LIVE }
    // ]
    // let _customRoleOptions = _.map(customRoleOptions, role => ({
    //   text: role.name,
    //   value: role.url,
    //   color: role.color
    // }))

    const hasMore = _listMembers.length % limit === 0 && !noMore;

    return (
        <ErrorBoundary>
            {/* Filter Header - Sorting and Filtering By Custom Roles */}
            {/* <ErrorBoundary>
	            <EditScreenItemContainer
					showToggleContainer={showFilters}
					renderToggleContainer={() => (
						<React.Fragment>
							<FilterDropdownEditScreen
								options={sortTypeOptions}
								selected={sortType}
								keepSelectedInOptions
								handleChange={(name, value) => setSortType(value)}
							/>
							{_customRoleOptions.length > 0 &&
								<EditScreenTagSelect
									target={'customRoleFilter'}
									placeholder={'Roles'}
									options={_customRoleOptions}
									selected={customRoleFilter}
									keepSelectedInOptions
									allowMultiple={true}
									handleAddToList={(name, value) => setCustomRoleFilter([...customRoleFilter, value ])}
									handleRemoveFromList={(name, value) => setCustomRoleFilter(_.filter(customRoleFilter, (existingItem) => existingItem != value))}
									handleSetList={(name, value) => setCustomRoleFilter([value])}
								/>
							}
						</React.Fragment>
					)}>
					<div name={'filterToggle'} className="filters-button">
						<ToggleButtonEditScreen
							id={'showFilters'}
							name={'showFilters'}
							handleToggle={() => {
								setShowFilters(!showFilters);
							}}
							onOption={"Filters"}
							offOption={"Filters"}
							toggleValue={showFilters}
						/>
					</div>
	            </EditScreenItemContainer>
            </ErrorBoundary> */}

            {/* Main Body - Searching and Displaying Memebrs */}

            <form className="edit-screen-form">
                <input
                    placeholder={searchPlaceholder}
                    onChange={e => handleQueryInputChange(e)}
                    className="text-field searchfield w-input"
                />
            </form>

            <div>
                <LoadingLine isLoading={loading} />
                {error && <div>{t(`common:global.search_error`)}..</div>}
                <h4
                    style={
                        query
                            ? { height: '24px', transition: 'height, opacity 200ms' }
                            : {
                                  height: '0px',
                                  opacity: 0,
                                  transition: 'height, opacity 200ms',
                              }
                    }
                >
                    Results for "{query}"
                </h4>
                {searchProfileCards || <div>{t(`common:global.no_results`)}..</div>}
                {hasMore && (
                    <div className="float-clear-width" onClick={() => runSearchQuery(query, true)}>
                        <div className="view-more-button w-inline-block" />
                    </div>
                )}
            </div>
        </ErrorBoundary>
    );
};

export default compose(
    graphql(LIST_MEMBERS, {
        options: () => ({
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
        }),
        props: props => {
            return {
                onSearch: searchQuery => {
                    return props.data.fetchMore({
                        query: LIST_MEMBERS,
                        variables: searchQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { listMembers: [...fetchMoreResult.listMembers] };
                        },
                    });
                },
                onMoreSearch: searchQuery => {
                    return props.data.fetchMore({
                        query: LIST_MEMBERS,
                        variables: searchQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) {
                                return {
                                    listMembers: [...previousResult.listMembers],
                                    noMore: true,
                                };
                            }
                            return {
                                listMembers: [
                                    ...previousResult.listMembers,
                                    ...fetchMoreResult.listMembers,
                                ],
                            };
                        },
                    });
                },
                data: props.data,
                loading: props.data.loading,
                error: props.data.error,
            };
        },
    })
)(MemberManagementContainer);
