import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import getLogger from '@core/logger';

const logger = getLogger(module);

/**
 * Hook to enable google analytics tracking on route change
 * @param {string} trackingId -  Google Analytics global site tag Id
 */
export const useGATracking = trackingId => {
    const { listen } = useHistory();

    useEffect(() => {
        const unlisten = listen(location => {
            if (!window.gtag) return;
            if (!trackingId) {
                logger.warn(
                    'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
                );
                return;
            }

            window.gtag('config', trackingId, { page_path: location.pathname });
        });

        return unlisten;
    }, [trackingId, listen]);
};

export default useGATracking;
