/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation, useQuery } from '@apollo/client';

import { ALL_TYPES, CARD_CHAPTER } from '@core/utilities/constants/card_types.js';
import { GET_GROUP_DASHBOARD } from '@shared/welibrary-graphql/user/queries';
// import { GET_BOOKS_FROM_SHELF } from '@shared/welibrary-graphql/content_card/queries';
import { UPDATE_CARD_RANK_ORDER } from '@shared/welibrary-graphql/content_card/mutations';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary.js';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-spinner.json';
import CreateChannel from '@web/ui/components/chapter/CreateChannel';
import GenericBrowseItem from '@components/generic/GenericBrowseItem';
import useModal from '@web/ui/components/modals/hooks/useModal';

// todo make this dumb display component that takes in a channel type and renders it out instead of handling logic how and hwere to find channels
// this will make it more reusable since this card doesn't work well with event groups atm
const ChannelsCard = ({ groupId, dashboardGroup, currentUser, book }) => {
    const { t } = useTranslation();
    const { closeAllModals } = useModal();

    const [updateCardRankOrder] = useMutation(UPDATE_CARD_RANK_ORDER);

    const { loading: groupDashboardLoading, data: groupDashboardData } = useQuery(
        GET_GROUP_DASHBOARD,
        {
            variables: {
                _id: groupId || dashboardGroup?._id,
            },
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
            skip: !(dashboardGroup || groupId),
        }
    );

    const group = groupDashboardData?.groupById;

    const [src, srcSet] = getBackgroundImageSetForUrl(
        group?.channelsBook?.media?.thumb || getRandomDefaultCoverImage(group?.profile?.full_name)
    );

    const canCreateAllTypes = currentUser?.canCreate?.includes(ALL_TYPES) ?? false;
    const canCreateChapter =
        (currentUser?.canCreate?.includes(CARD_CHAPTER) ?? false) || canCreateAllTypes;

    const createChapterPlaceholder = `${t('common:global.verbs.new')} ${t(
        'common:global.nouns.channel'
    )}...`;

    const isEventGroup = group?.subtype === 'event';
    const channels = !isEventGroup ? group?.channelsBook?.children : book?.children;
    const channelsBookUrl = isEventGroup && book ? book?.url : group?.channelsBook?.url;

    const sortedChannels = channels
        ? [...channels].sort((a, b) => a.rankOrder - b.rankOrder)
        : undefined;

    return (
        <ErrorBoundary>
            <div className="channels-card-container">
                <StyledBackgroundImage className="channels-card-header" src={src} srcSet={srcSet}>
                    <div className="channels-card-darkoverlay" />
                    <div className="channels-card-header-title">
                        {group?.channelsBook?.title || t(`common:global.nouns.channels`)}
                    </div>
                    <div className="channels-card-header-subcontainer">
                        <div className="channels-card-header-subtitle">
                            {t(`common:global.prepositions.by`)}{' '}
                            <span>{group?.profile?.full_name}</span>
                        </div>
                    </div>
                </StyledBackgroundImage>

                {sortedChannels && groupDashboardData && (
                    <div className="channel-card-results fadein">
                        {sortedChannels.map((channel, index) => {
                            const prevChannel = index > 0 ? sortedChannels[index - 1] : null;
                            const nextChannel =
                                index < sortedChannels.length - 1
                                    ? sortedChannels[index + 1]
                                    : null;

                            const itemUrl = `/s/${group.shelf.url}/${channelsBookUrl}/${channel.url}`;

                            return (
                                <GenericBrowseItem
                                    key={channel?._id}
                                    item={channel}
                                    selectedBook={group?.channelsBook}
                                    url={itemUrl}
                                    updateCardRankOrder={updateCardRankOrder}
                                    nextItem={nextChannel}
                                    prevItem={prevChannel}
                                    dragAndDropReorder={group?.shelf?.loggedInUserCanEdit}
                                    onClick={closeAllModals}
                                    extraLinkClasses="channels-item-container"
                                />
                            );
                        })}
                    </div>
                )}

                {groupDashboardLoading && !group && !groupDashboardData && (
                    <div className="flex-center">
                        <LottieLoading
                            customStyle={{
                                backgroundColor: 'transparent',
                                borderRadius: '50%',
                                opacity: 0.3,
                            }}
                            height={100}
                            width={100}
                            lottieData={LottieAnimation}
                        />
                    </div>
                )}

                <div className="ln-links-field">
                    {canCreateChapter && group?.currentUserIsAdmin && channelsBookUrl && (
                        <CreateChannel
                            bookURL={channelsBookUrl}
                            placeholder={createChapterPlaceholder}
                        />
                    )}
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default ChannelsCard;
