import { useEffect, useState } from 'react';
import _ from 'lodash';
import { DocumentNode, QueryHookOptions, useQuery } from '@apollo/client';

import useOnScreen from '@web/ui/components/generic/hooks/useOnScreen';

/**
 * React hook for handling the logic of infinite scrolling data
 * This hook assumes that pagination is done using blacklists.
 *
 * Use like so:
 *
 * const { queryResult, setRef } = useInfiniteScrollBlacklist({ query: { query: GQL_QUERY });
 *
 * const display = queryResult.data?.map((item, index) => (
 *     <Example ref={index === queryResult.data.length - 1 ? setRef : undefined} />
 * )) ?? [];
 *
 * return <Container>{display}</Container>
 *
 * @param {{
 *     query: { query: DocumentNode, options: QueryHookOptions },
 *     visibleOffset: number|string,
 *     throttleTime: number
 *   }}
 */
const useInfiniteScrollBlacklist = ({ query, visibleOffset = 0, throttleTime = 100 }) => {
    const [requestTriggeringElementRef, setRef] = useState(null);
    const [cardBlacklist, setCardBlacklist] = useState([]);
    const [gettingNextPage, setGettingNextPage] = useState(false);

    // TODO: Currently there isn't a good way to know if has more results... need to figure out.
    const [hasMoreResults, setHasMore] = useState(true);

    const queryResult = useQuery(query.query, {
        ...query.options,
        notifyOnNetworkStatusChange: true,
    });
    const { onScreen } = useOnScreen({
        ref: requestTriggeringElementRef,
        offset: visibleOffset,
        throttleTime,
    });

    useEffect(() => {
        if (gettingNextPage) {
            queryResult
                .fetchMore({
                    variables: { cardBlacklist, iterator: cardBlacklist.length },
                })
                .then(() => {
                    setGettingNextPage(false);
                })
                .catch(() => {
                    setGettingNextPage(false);
                });
        }
    }, [gettingNextPage]);

    const getNextPage = () => {
        if (hasMoreResults && !queryResult.loading && !gettingNextPage) {
            setGettingNextPage(true);
        } else if (gettingNextPage) {
            // Safety mechanism to prevent gettingNextPage from remaining true if getNextPage called quickly back to back.
            setGettingNextPage(false);
        }
    };

    const addToCardBlacklist = cards => {
        const newCards = _.filter(cards, card => !cardBlacklist.includes(card._id));

        if (newCards.length > 0) {
            setCardBlacklist(_.uniq([...cardBlacklist, ..._.map(newCards, card => card._id)]));
        }
    };

    const resetCardBlacklist = () => {
        setCardBlacklist([]);
    };

    useEffect(() => {
        const newCards = _.filter(
            queryResult?.data?.getRandomEngagementCardSampling,
            card => !cardBlacklist.includes(card._id)
        );

        const hasMore = !(cardBlacklist?.length > 0 && newCards.length === 0);

        if (queryResult?.loading === false && hasMoreResults !== hasMore) {
            setHasMore(hasMore);
        }
    }, [queryResult?.loading]);

    useEffect(() => {
        addToCardBlacklist(queryResult?.data?.getRandomEngagementCardSampling);
    }, [queryResult?.data]);

    useEffect(() => {
        if (requestTriggeringElementRef && onScreen) getNextPage();
    }, [requestTriggeringElementRef, onScreen]);

    return { getNextPage, setRef, queryResult, hasMoreResults };
};

export default useInfiniteScrollBlacklist;
