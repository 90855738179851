export const CARD_IMAGE = 'image';
export const CARD_IMAGE_FULL = 'image_full';
export const CARD_IMAGE_SQUARE = 'image_square';
export const CARD_LINK = 'link';
export const CARD_VIDEO = 'video';
export const CARD_DOCUMENT = 'document';
export const CARD_TEXT = 'text';
export const CARD_PROFILE = 'profile';
export const CARD_POSITION = 'position';
export const CARD_COLLECTION = 'collection';
export const CARD_COURSE = 'course';
export const CARD_SUMMARY = 'summary';
export const CARD_PLATFORM = 'platform';
export const CARD_CHANNEL = 'channel';
export const CARD_LIBRARY = 'library';
export const CARD_SHELF = 'shelf';
export const CARD_BOOK = 'book';
export const CARD_CHAPTER = 'chapter';
export const CARD_MOBILIZE = 'mobilize';
export const CARD_EVENT = 'event';
export const CARD_BLOG = 'blog';
export const CARD_AUDIO = 'audio';
export const CARD_QUOTE = 'quote';
export const CARD_EMBED = 'embed';
export const CARD_RESEARCH = 'research';
export const CARD_PLEDGE = 'pledge';
export const CARD_POSTBOX = 'postbox';
export const CARD_VOTE_GALLERY = 'votegallery';
export const CARD_LINK_TILE = 'linktile';
export const CARD_HEADER = 'header';
export const CARD_MAP = 'map';
export const CARD_MOBILIZATION = 'mobilization';
export const CARD_VOLUNTEER_MOBILIZATION = 'volunteer';
export const CARD_POSTING = 'posting';
export const CARD_FORMSPEC = 'formspec';
export const CARD_TASK = 'task';
export const CARD_CUSTOM_FIELDS_SUMMARY = 'custom_fields_summary';
export const CARD_RAINBOW_ROAD = 'rainbow_road';
export const CARD_GROUP = 'group';
export const CARD_META_CHANNEL = 'metachannel';
export const CARD_SHOPPING_CART = 'shopping_cart';
export const CARD_DYNAMIC = 'dynamic';
export const CARD_CREATION = 'creation';
export const CARD_SURVEY = 'survey';
export const CARD_STORY = 'story';
export const CARD_DIALOG = 'dialog';

export const ALIAS_EXTERNAL_LINK = 'alias_external_link';
export const ALIAS_GENERATOR = 'alias_generator';
export const ALIAS_GROUP = 'alias_group';
export const ALIAS_USER = 'alias_user';
export const ALIAS_MESSAGE_THREAD = 'alias_message_thread';

export const ALL_TYPES = '*';

export const CARD_TYPES = {
    CARD_IMAGE,
    CARD_IMAGE_FULL,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_VIDEO,
    CARD_DOCUMENT,
    CARD_TEXT,
    CARD_PROFILE,
    CARD_POSITION,
    CARD_COLLECTION,
    CARD_COURSE,
    CARD_SUMMARY,
    CARD_CHANNEL,
    CARD_LIBRARY,
    CARD_SHELF,
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_PLEDGE,
    CARD_MOBILIZE,
    CARD_EVENT,
    CARD_AUDIO,
    CARD_PLATFORM,
    CARD_QUOTE,
    CARD_EMBED,
    CARD_RESEARCH,
    CARD_POSTBOX,
    CARD_VOTE_GALLERY,
    CARD_LINK_TILE,
    CARD_HEADER,
    CARD_MAP,
    CARD_BLOG,
    CARD_MOBILIZATION,
    CARD_POSTING,
    CARD_FORMSPEC,
    CARD_TASK,
    CARD_CUSTOM_FIELDS_SUMMARY,
    CARD_RAINBOW_ROAD,
    CARD_GROUP,
    CARD_META_CHANNEL,
    CARD_DYNAMIC,
    CARD_CREATION,
    CARD_STORY,
    CARD_SURVEY,
    CARD_DIALOG,
};

export const DEFAULT_POST_TYPES = [
    CARD_IMAGE,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_VIDEO,
    CARD_DOCUMENT,
    CARD_TEXT,
    CARD_POSITION,
    CARD_COURSE,
    CARD_SHELF,
    CARD_BOOK,
    CARD_MOBILIZE,
    CARD_PLEDGE,
    CARD_RESEARCH,
    CARD_POSTBOX,
    CARD_VOTE_GALLERY,
    CARD_LINK_TILE,
    CARD_MAP,
    CARD_BLOG,
    CARD_CUSTOM_FIELDS_SUMMARY,
    CARD_GROUP,
    CARD_META_CHANNEL,
    CARD_DYNAMIC,
    CARD_EVENT,
];

export const META_MEDIA_AREA_BLACKLIST = [CARD_IMAGE, CARD_IMAGE_SQUARE];

export const CARD_SUBTYPE_STANDARD = 'STANDARD';
export const CARD_SUBTYPE_USERFEED = 'USERFEED';
export const CARD_SUBTYPE_SYSTEM = 'SYSTEM';
export const CARD_SUBTYPE_PERSONAL = 'PERSONAL';
export const CARD_SUBTYPE_DASHBOARD = 'DASHBOARD';
export const CARD_SUBTYPE_SECONDARY_DASHBOARD = 'SECONDARY_DASHBOARD';
export const CARD_SUBTYPE_PUBLIC_DASHBOARD = 'PUBLIC_DASHBOARD';
export const CARD_SUBTYPE_DIRECTORY = 'DIRECTORY';
export const CARD_SUBTYPE_CHANNEL = 'CHANNEL';
export const CARD_SUBTYPE_DISTINCT_JOBS = 'DISTINCT_JOBS';
export const CARD_SUBTYPE_DISTINCT_VOLUNTEER = 'DISTINCT_VOLUNTEER';
export const CARD_SUBTYPE_DISTINCT_ACTION_CAMPAIGNS = 'DISTINCT_ACTION_CAMPAIGNS';
export const CARD_SUBTYPE_DISTINCT_EVENT = 'DISTINCT_EVENTS';
export const CARD_SUBTYPE_CUSTOM_RAINBOW_ROAD = 'CUSTOM_RAINBOW_ROAD';
export const CARD_SUBTYPE_QUESTABLE_RAINBOW_ROAD = 'QUESTABLE_RAINBOW_ROAD';
export const CARD_SUBTYPE_QUESTABLE_RAINBOW_ROAD_MAP = 'QUESTABLE_RAINBOW_ROAD_MAP';
export const CARD_SUBTYPE_DISTINCT_ITINERARY = 'Itinerary';
export const CARD_SUBTYPE_DISTINCT_SPONSORS = 'Sponsors';
export const CARD_SUBTYPE_DISTINCT_SPEAKERS = 'Speakers';
export const CARD_SUBTYPE_DISTINCT_ABOUT = 'About';
export const CARD_SUBTYPE_DISTINCT_UPDATES = 'Updates';
export const CARD_SUBTYPE_DISTINCT_COMMUNITY = 'Community';
export const CARD_SUBTYPE_ADMIN_ANNOUNCEMENT = 'Admin Announcement';

export const CARD_SUBTYPES = {
    STANDARD: CARD_SUBTYPE_STANDARD,
    USERFEED: CARD_SUBTYPE_USERFEED,
    SYSTEM: CARD_SUBTYPE_SYSTEM,
    PERSONAL: CARD_SUBTYPE_PERSONAL,
    DASHBOARD: CARD_SUBTYPE_DASHBOARD,
    SECONDARY_DASHBOARD: CARD_SUBTYPE_SECONDARY_DASHBOARD,
    PUBLIC_DASHBOARD: CARD_SUBTYPE_PUBLIC_DASHBOARD,
    DIRECTORY: CARD_SUBTYPE_DIRECTORY,
    CHANNEL: CARD_SUBTYPE_CHANNEL,
    DISTINCT_JOBS: CARD_SUBTYPE_DISTINCT_JOBS,
    DISTINCT_VOLUNTEER: CARD_SUBTYPE_DISTINCT_VOLUNTEER,
    DISTINCT_ACTION_CAMPAIGNS: CARD_SUBTYPE_DISTINCT_ACTION_CAMPAIGNS,
    DISTINCT_EVENT: CARD_SUBTYPE_DISTINCT_EVENT,
    CUSTOM_RAINBOW_ROAD: CARD_SUBTYPE_CUSTOM_RAINBOW_ROAD,
    QUESTABLE_RAINBOW_ROAD: CARD_SUBTYPE_QUESTABLE_RAINBOW_ROAD,
    DISTINCT_ITINERARY_CHANNEL: CARD_SUBTYPE_DISTINCT_ITINERARY,
    DISTINCT_SPONSORS_CHANNEL: CARD_SUBTYPE_DISTINCT_SPONSORS,
    DISTINCT_SPEAKERS_CHANNEL: CARD_SUBTYPE_DISTINCT_SPEAKERS,
    DISTINCT_ABOUT_CHANNEL: CARD_SUBTYPE_DISTINCT_ABOUT,
    DISTINCT_UPDATES_CHANNEL: CARD_SUBTYPE_DISTINCT_UPDATES,
    DISTINCT_COMMUNITY_CHANNEL: CARD_SUBTYPE_DISTINCT_COMMUNITY,
    ADMIN_ANNOUNCEMENT: CARD_SUBTYPE_ADMIN_ANNOUNCEMENT,
};

export const COLLECTION_CARD_TYPES = [
    CARD_PLATFORM,
    CARD_LIBRARY,
    CARD_SHELF,
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_RAINBOW_ROAD,
    CARD_META_CHANNEL,
];

export const BASIC_CARDS = [CARD_BLOG, CARD_IMAGE, CARD_LINK, CARD_VIDEO, CARD_DOCUMENT, CARD_TEXT];
