import React from 'react';

import PaginatedSearchBox from '@web/ui/components/generic/search/PaginatedSearchBox';
import SelectedSearchItem from '@web/ui/components/generic/search/SelectedSearchItem';
import _ from 'lodash';

const SearchWithSelectedList = props => {
    const {
        disableRenderChildren = false,
        childrenTypes,
        libraryUrl,
        overrideContainerStyle,
        types,
        onSelect,
        removeItem,
        selected,
        filterUrl,
        color,
        onClickAddButton,
        onClickAddButtonLabel,
        renderNoResults,
        limit,
        prioritizeActiveGroup = true,
    } = props;

    const items = [];

    const _selected = _.map(selected, item => {
        return (
            <SelectedSearchItem
                key={item._id}
                handleItem={() => removeItem(item, filterUrl)}
                source={item}
                title={item.title ? item.title : item.name}
                color={color}
            />
        );
    });

    /// DASHBOARD SECTIONS FOR DIFFERENT ROLES TO SHOW UP ON DASHBOARD
    items.push(
        <div
            key="search-selected-list"
            className="edit-section w-clearfix"
            style={overrideContainerStyle}
        >
            {onClickAddButton ? (
                <div className="select-option-item" onClick={() => onClickAddButton()}>
                    <div>{onClickAddButtonLabel}</div>
                    <div className="select-option-line" />
                </div>
            ) : null}
            {_selected.length > 0 && <div style={{ marginBottom: '15px' }}>{_selected}</div>}
            <PaginatedSearchBox
                prioritizeActiveGroup={prioritizeActiveGroup}
                type="cards"
                disableRenderChildren={disableRenderChildren}
                childrenTypes={childrenTypes}
                renderNoResults={renderNoResults}
                libraryUrl={libraryUrl}
                types={types}
                filterUrl={filterUrl}
                onSelect={(item, filter) => onSelect(item, filter)}
                limit={limit}
            />
        </div>
    );

    return <div>{items}</div>;
};

export default SearchWithSelectedList;
