import React from 'react';

type LockProps = {
    className?: string;
    color?: string;
};

const Lock: React.FC<LockProps> = ({ className, color = '#000000' }) => (
    <svg
        version="1.1"
        id="Hand-drawn_Icon_Set"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        className={className}
        stroke={color}
        viewBox="0 0 500 500"
    >
        <circle fill="none" stroke="#1A1A1A" strokeWidth="4" cx="201" cy="329.1" r="19.7" />
        <line
            fill="none"
            stroke="#1A1A1A"
            strokeWidth="4"
            x1="201"
            y1="388.6"
            x2="201"
            y2="348.8"
        />
        <g>
            <path
                fill="none"
                stroke="#1A1A1A"
                strokeWidth="4"
                d="M349.8,220.1c0,11.1-8.7,19.7-19.7,19.7c-11.1,0-19.7-8.7-19.7-19.7v-69.5
		c-0.4-49.3-40.3-89.2-89.6-89.2c-21.7,0-39.5,17.8-39.5,39.5v59.6c0,11.1-8.7,19.7-19.7,19.7c-11.1,0-19.7-8.7-19.7-19.7v-59.6
		C141.4,57.5,177.3,22,220.8,22c71,0,128.7,57.6,128.7,128.7v69.5H349.8z"
            />
        </g>
        <path
            fill="none"
            stroke="#1A1A1A"
            strokeWidth="4"
            d="M72.4,175.5v188.3c0,6.3,3.9,11.8,9.9,13.8L319.8,466h0.4
	c3.2,1.2,6.7,2,10.3,2c5.1,0,9.9-1.6,14.2-3.9h0.4l67.1-37.9c4.3-2.4,7.5-7.5,7.5-13V224.9"
        />
        <line
            fill="none"
            stroke="#1A1A1A"
            strokeWidth="4"
            x1="330.1"
            y1="358.7"
            x2="330.1"
            y2="438"
        />
        <line
            fill="none"
            stroke="#1A1A1A"
            strokeWidth="4"
            x1="330.1"
            y1="309.3"
            x2="330.1"
            y2="338.9"
        />
        <path
            fill="none"
            stroke="#1A1A1A"
            strokeWidth="4"
            d="M141.4,127.8l-62,34.7c-4.3,2.4-7.5,7.5-7.5,13c0,6.3,3.9,11.8,9.9,13.8
	l237.6,88.4h0.4c3.2,1.2,6.7,2,10.3,2c5.1,0,9.9-1.6,14.2-3.9h0.4l67.1-37.9c4.3-2.4,7.5-7.5,7.5-13c0-6.3-3.9-11.8-9.9-13.8
	l-59.6-22.1"
        />
        <line
            fill="none"
            stroke="#1A1A1A"
            strokeWidth="4"
            x1="310"
            y1="174.3"
            x2="181.3"
            y2="126.2"
        />
    </svg>
);

export default Lock;
