type PreSelectedIcons = {
    name: string;
    value: string;
};

const PRE_SELECTED_ICONS: PreSelectedIcons[] = [
    {
        name: 'archive',
        value: '/images/icon.black.lock.svg',
    },
    {
        name: 'unarchive',
        value: '/images/icon.black.unlock.svg',
    },
    {
        name: 'dash',
        value: '/images/icon.black.minus.svg',
    },
    {
        name: 'document',
        value: '/images/icon.black.document.svg',
    },
    {
        name: 'event',
        value: '/images/icon.black.calendar.outline.svg',
    },
    {
        name: 'home',
        value: '/images/icon.black.home.svg',
    },
    {
        name: 'group',
        value: '/images/icon.black.groupv2.svg',
    },
    {
        name: 'link',
        value: '/images/icon.black.link.svg',
    },
    {
        name: 'member',
        value: '/images/icon.black.people3.svg',
    },
    {
        name: 'message',
        value: '/images/icon.black.airplane.svg',
    },
    {
        name: 'newsfeed',
        value: '/images/icon.black.newsfeed4.svg',
    },
    {
        name: 'notification',
        value: '/images/icon.black.bell3.svg',
    },
    {
        name: 'search',
        value: '/images/icon.black.search2.svg',
    },
    {
        name: 'share',
        value: '/images/icon.black.share.2.svg',
    },
    {
        name: 'github',
        value: '/images/icon.black.github.svg',
    },
    {
        name: 'zoom',
        value: '/images/icon.black.zoom.svg',
    },
];

export { PRE_SELECTED_ICONS as default };
