import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Picture from '@components/generic/Picture';
import Plus from '@dsc/svgs/Plus';
import MinusCircle from '@dsc/svgs/MinusCircle';
import ArrowsLeftRight from '@dsc/svgs/ArrowsLeftRight';
import { ThickTicket } from '@dsc/svgs/Ticket';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-spinner.json';

import { ProductInstance } from '@shared/welibrary-graphql/types';

type ViewCustomerOrderProductInstanceProps = {
    ticket: ProductInstance;
    checkingInAllTickets: boolean;
    toggleTicketCheckIn: () => Promise<void>;
};

const ViewCustomerOrderProductInstance: React.FC<ViewCustomerOrderProductInstanceProps> = ({
    ticket,
    checkingInAllTickets,
    toggleTicketCheckIn,
}) => {
    const { t } = useTranslation();
    const [toggleLoading, setToggleLoading] = useState(false);

    // TODO some duplicated code with UserTicketListItem
    const { owner, purchaser, isCheckedIn } = ticket;

    const names = owner.profile.full_name.split(' ');
    const initials = `${names[0][0]}${names?.[1]?.[0] ?? ''}`;

    const isTransfer = owner._id !== purchaser._id;

    const loading = toggleLoading || (checkingInAllTickets && !isCheckedIn && !isTransfer);

    return (
        <div className="shadow-around flex gap-[20px] rounded-[10px] p-[20px]">
            <div
                role="button"
                onClick={async () => {
                    setToggleLoading(true);
                    await toggleTicketCheckIn();
                    setToggleLoading(false);
                }}
                className={`flex w-[91px] flex-col items-center gap-[5px] rounded-[10px] pb-[10px] pt-[15px] shrink-0 ${isCheckedIn ? 'bg-grayscale-input-background' : 'bg-primary-default'
                    }`}
            >
                <div className="relative h-[60px] w-[60px] rounded-full border-[2px] border-solid border-white">
                    {owner.profile.picture && (
                        <Picture
                            url={owner.profile.picture}
                            className="!h-full !w-full overflow-hidden rounded-full"
                            disableLink
                        />
                    )}
                    {!owner.profile.picture && (
                        <div className="bg-grayscale-body flex h-full w-full items-center justify-center overflow-hidden rounded-full text-[24px] uppercase leading-[34px] tracking-[1px] text-white">
                            {initials}
                        </div>
                    )}

                    {isTransfer && (
                        <div className="bg-grayscale-body absolute right-[-7px] top-[-7px] flex h-[28px] w-[28px] items-center justify-center rounded-full border-[2px] border-solid border-white">
                            <ArrowsLeftRight color="white" />
                        </div>
                    )}
                </div>

                <div className="font-poppins flex items-center gap-[3px] text-[12px] font-[500]">
                    {!isCheckedIn && (
                        <>
                            {!loading && <Plus version="2" size="15" color="white" />}
                            {loading && (
                                <LottieLoading
                                    height={15}
                                    width={15}
                                    lottieData={LottieAnimation}
                                />
                            )}{' '}
                            <span className="text-white">
                                {t(`common:global.check_in`, 'Check In')}
                            </span>
                        </>
                    )}
                    {isCheckedIn && (
                        <>
                            {!loading && (
                                <>
                                    <MinusCircle />{' '}
                                    <span className="text-grayscale-body">Remove</span>
                                </>
                            )}
                            {loading && <span className="text-grayscale-body">Removing...</span>}
                        </>
                    )}
                </div>
            </div>

            <div className="flex flex-col gap-[10px]">
                <div className="flex flex-col">
                    <div className="text-grayscale-title-active flex items-center gap-[5px] text-[14px] font-[700] leading-[24px] tracking-[0.75px]">
                        <ThickTicket strokeColor="currentColor" />
                        <span className="line-clamp-1">{ticket.product.name}</span>
                    </div>
                    <span className="text-grayscale-label text-[12px] leading-[17px] tracking-[0.25px]">
                        <span className="uppercase">{t(`common:global.ticket`, 'Ticket')}</span> #
                        {ticket._id}
                    </span>
                </div>

                {isTransfer && (
                    <div className="flex gap-[5px] text-[10px] tracking-[0.25px]">
                        <span className="text-grayscale-label">
                            {t(`common:global.recipient`, 'Recipient')}:
                        </span>
                        <span className="text-grayscale-title-active font-[500] underline">
                            {owner.profile.full_name}
                        </span>
                    </div>
                )}

                <span
                    className={`text-[12px] font-[500] ${isCheckedIn ? 'text-success-default' : 'text-error-default'
                        }`}
                >
                    {isCheckedIn && t(`common:global.checked_in`, 'Checked In')}
                    {!isCheckedIn && t(`common:global.not_checked_in`, 'Not Checked In')}
                </span>
            </div>
        </div>
    );
};

export default ViewCustomerOrderProductInstance;
