/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import catchLinks from 'catch-links';
import { useTranslation } from 'react-i18next';

// import '@web/startup/serviceWorker.js';

import currentUserStore from '@stores/User';
import { useCurrentUserSimpleQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { useGATracking } from '@components/generic/hooks/useGATracking';
import useAddUserflowClasses from '@components/generic/hooks/useAddUserflowClasses';
import useUniversalWallet from '@web/utilities/hooks/wallet/useUniversalWallet';

import { useToastContext, useToastActionsContext } from '@components/toast/NewToastContext';
import ToastContext from '@components/generic/context/ToastContext';
import Toast from '@components/generic/toast/Toast';
import NewToast from '@components/toast/NewToast';
import RightSidebarController from '@components/sidebar/RightSidebar';
import Modals from '@components/modals/Modals';
import SearchOverlayQuery from '@components/search/SearchOverlayQuery';
import AppUrlListener from '@startup/AppUrlListener';
import QRCodeScannerListener from '@web/ui/components/qrcode-scanner-listener/QRCodeScannerListener';
import QRCodeScannerOverlay from '@web/ui/components/QRCodeScannerOverlay/QRCodeScannerOverlay';

import QRCodeScannerStore from '@web/ui/stores/QRCodeScannerStore';
import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';
import { getAuthToken } from '@helpers/auth';
import StandardToast from '@components/toast/StandardToast';
import { isEphemeralUser } from '@web/utilities/helpers/accounts/accounts.helpers';
import { config } from '@config/getConfig';
import {
    useManualLiveUpdate,
    ManualLiveUpdateModal,
} from '@components/liveupdate/ManualLiveUpdate';

import getLogger from '@core/logger';
import redirectStore from '@stores/Redirect';

const logger = getLogger(module);

const Routes = loadable(() => import('@startup/client/routes'), {
    resolveComponent: imported => imported.default,
});

const LegoRoutes = loadable(() => import('@startup/client/LegoRoutes'), {
    resolveComponent: imported => imported.default,
});

const GATrackingId = getWhitelabelPublicSettings()?.GATracking?.GA_ID;

const Main = props => {
    const {
        client,
    }: {
        client?: ApolloClient<NormalizedCacheObject>;
    } = props;
    useGATracking(GATrackingId);
    const { t } = useTranslation();
    useAddUserflowClasses();

    const history = useHistory();
    const location = useLocation();

    const { showToast } = useContext(ToastContext);
    const { newToast, closeToast } = useToastActionsContext();
    const { toast } = useToastContext();
    const { initWallet } = useUniversalWallet();
    useManualLiveUpdate();
    // This is causing some weird issue where all the modals on the messaging routes are broken
    //   Jan 31 2024 - this is still a problem (on mobile message routes)
    // const { newModal } = useModal();

    const setStatusBarStyleDark = async () => {
        await StatusBar.setStyle({ style: Style.Dark });
    };

    const showStatusBar = async () => {
        await StatusBar.show();
    };

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            setStatusBarStyleDark();
            showStatusBar();
            Keyboard?.setScroll({ isDisabled: true });
        }
    }, []);

    useEffect(() => {
        const redirectUrl = redirectStore.get.url();

        if (redirectUrl) {
            redirectStore.set.url(null);
            history.push(redirectUrl);
        }
    }, []);

    const { loading, error, data, refetch } = useCurrentUserSimpleQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const loggedInUser = data ? data.currentUser : null;

    const refetchUserData = () =>
        refetch().then(res => currentUserStore.set.setUser(res.data.currentUser));

    const currentUser = currentUserStore.get.currentUser();

    useEffect(() => {
        if (loggedInUser && currentUser?._id !== loggedInUser?._id) {
            currentUserStore.set.setUser(loggedInUser);
            currentUserStore.set.setRefetchUser(refetchUserData);
            if (config?.public?.torus) {
                initWallet();
            }
        }
    }, [loggedInUser?._id]);

    useEffect(() => {
        if (
            isEphemeralUser(loggedInUser) &&
            // for V1, we're showing the header on /messages pages
            !location.pathname.startsWith('/messages') &&
            !config.public.hideClaimEphemeralAccountToast
        ) {
            newToast(
                <StandardToast
                    iconClassName="toast-warning-icon"
                    title={t(
                        'common:imports.wlWeb.ui.components.account.claimAccount.this_is_unclaimed_account',
                        'This is an unclaimed account.'
                    )}
                    message={t(
                        'common:imports.wlWeb.ui.components.account.claimAccount.claim_it',
                        "Claim it before it's lost."
                    )}
                    action={{
                        label: 'Claim',
                        handler: () => {
                            // newModal(<ClaimAccountForm />);
                        },
                    }}
                />
            );
        } else if (toast.open) {
            closeToast();
        }
    }, [location, loggedInUser]);

    useEffect(() => {
        if (error) {
            showToast('Sorry, unable to load user.', null, 6000);
        }
    }, [error]);

    // This intercepts all relative href onclicks and if relative links will use react router
    // This is a workaround to solve links within markdown that don't use the link component
    if (typeof window !== 'undefined') {
        catchLinks(window, href => {
            if (href?.toString()?.includes('//')) {
                window.open(href, '_blank');
            } else {
                try {
                    history.push(href);
                } catch (e) {
                    logger?.error(e);
                }
            }
        });
    }

    const showScannerOverlay = QRCodeScannerStore?.use?.showScanner();

    return (
        <>
            <AppUrlListener />
            <QRCodeScannerListener />
            {showScannerOverlay && <QRCodeScannerOverlay />}
            {config.public.configName === 'lego-ssl' ? (
                <LegoRoutes
                    {...props}
                    client={client}
                    refetch={refetchUserData}
                    loggedInUser={loggedInUser}
                    userDataState={{ loading, error }}
                    token={getAuthToken()}
                />
            ) : (
                <Routes
                    {...props}
                    client={client}
                    refetch={refetchUserData}
                    loggedInUser={loggedInUser}
                    userDataState={{ loading, error }}
                    token={getAuthToken()}
                />
            )}
            <SearchOverlayQuery handleNewMessageToggle={() => {}} newMessageUserId="" {...props} />
            <RightSidebarController {...props} />
            <Modals />
            <ManualLiveUpdateModal />
            <Toast {...props} />
            <NewToast />
        </>
    );
};

export default Main;
