import React from 'react';

type CommentProps = {
    className?: string;
};

const Comment: React.FC<CommentProps> = ({ className = '' }) => (
    <svg className={className} viewBox="0 0 500 500" fill="currentcolor">
        <path d="M250,79.37a230.07,230.07,0,0,1,78.13,13.38,200.74,200.74,0,0,1,62.48,35.78c35.4,30.12,54.9,69.62,54.9,111.23,0,35.35-13.87,68.93-40.11,97.1a40,40,0,0,0-10.67,29.51l1.72,30.52-28.17-12.78a40,40,0,0,0-31.65-.61A228,228,0,0,1,250,400.15a229.84,229.84,0,0,1-78.13-13.39A200.72,200.72,0,0,1,109.39,351c-35.4-30.12-54.9-69.62-54.9-111.23s19.5-81.11,54.9-111.23a200.74,200.74,0,0,1,62.48-35.78A230.07,230.07,0,0,1,250,79.37m0-40c-130.07,0-235.51,89.72-235.51,200.39S119.93,440.15,250,440.15a269.28,269.28,0,0,0,101.75-19.62l88.34,40.1-5.42-96.5c31.83-34.18,50.84-77.38,50.84-124.37C485.51,129.09,380.07,39.37,250,39.37Z" />
    </svg>
);

export default Comment;
