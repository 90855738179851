import {
    COMPARATOR_MULTI_AND_POS,
    SIGNUP_FIELD_COMPARATORS,
} from '@core/utilities/constants/library_settings';

import { CustomSignupField, CustomSignupFieldTypes } from '@shared/welibrary-graphql/types';
import { CustomSignupFieldBeingEdited } from '@core/types/CustomFields';

export const createBlankObject = ({
    type,
    customText,
}: {
    type: CustomSignupFieldTypes;
    customText: string;
}): CustomSignupFieldBeingEdited => ({
    type,
    prompt: '',
    customText,
    index: 0,
    options: [],
    selectMultiple: false,
    allowOther: false,
    defaultComparator: type ? SIGNUP_FIELD_COMPARATORS[type].default : COMPARATOR_MULTI_AND_POS,
});

export const incrementFields = (fields: CustomSignupFieldBeingEdited[]) =>
    fields.map((field, index) => ({
        ...field,
        index: (field.index ?? index) + 1,
    }));

export const resetFieldsIndex = (fields: CustomSignupFieldBeingEdited[]) =>
    fields.map((field, index) => ({ ...field, index }));

export const areDependentQuestionsAnswered = (
    field: CustomSignupField,
    state: Record<string, { value: string | string[] }>,
    fields?: CustomSignupField[]
): boolean => {
    const dependsOns = field.dependsOn;

    if (!dependsOns || dependsOns.length === 0) return true;

    return dependsOns.every(dependsOn => {
        const dependentQuestion = fields?.find(question => question.id === dependsOn.id);
        const dependentQuestionValue = state?.[dependsOn.id]?.value;

        // Wonkily check for unchecked state
        if (dependentQuestion?.type === CustomSignupFieldTypes.ConsentBox && !dependsOn.values[0]) {
            return !dependentQuestionValue?.[0];
        }

        return dependsOn?.values?.some(value => {
            return Array.isArray(dependentQuestionValue)
                ? dependentQuestionValue.includes(value)
                : value === dependentQuestionValue;
        });
    });
};
