import { GROUP_ACTIONS } from '@core/utilities/constants/group';
import { CARD_EVENT } from '@core/utilities/constants/card_types';

import { Maybify } from '@core/types/Utilities';
import { Group } from '@shared/welibrary-graphql/types';

export const isGroupSubscription = (group: Group): boolean => {
    const isEvent = group?.subtype === CARD_EVENT;
    if (isEvent) return false;

    // return (
    //     group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_PURCHASE_ACCESS) ||
    //     group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_EDIT_PRODUCTS) ||
    //     group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS) ||
    //     group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_REQUEST_TO_PURCHASE) ||
    //     group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_REACTIVATE_SUBSCRIPTION) ||
    //     group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_CANCEL_PRE_AUTH_PURCHASE)
    // );

    return group?.doesSellMembership && !isEvent;
};

export const parentGroupHasSubscription = (group: Group): boolean => {
    if (group?.parentGroups?.length === 0) return false;

    if (group?.groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_PURCHASE_PARENT_ACCESS)) {
        return true;
    }

    return false;
};

export const shouldPurchaseParentSubscription = (subgroup: Group, parentGroup: Group): boolean => {
    if (!subgroup || !parentGroup) return false;
    if (
        parentGroupHasSubscription(subgroup) &&
        parentGroup?.groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_PURCHASE_ACCESS)
    ) {
        return true;
    }

    return false;
};

export const shouldRequestToPurchaseParentSubscription = (
    subgroup: Group,
    parentGroup: Group
): boolean => {
    if (!subgroup || !parentGroup) return false;
    if (
        parentGroupHasSubscription(subgroup) &&
        parentGroup?.groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_REQUEST_TO_PURCHASE)
    ) {
        return true;
    }

    return false;
};

export const isPendingRequestToPurchaseParentSubscription = (
    subgroup: Group,
    parentGroup: Group
): boolean => {
    if (!subgroup || !parentGroup) return false;
    if (
        parentGroupHasSubscription(subgroup) &&
        parentGroup?.groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN)
    ) {
        return true;
    }

    return false;
};

export const groupSellsProductsButCantAcceptPayments = (group: Maybify<Group>) => {
    // doesSellMembership is false if Stripe isn't set up yet
    return group?.paymentSettings?.isGroupMembershipPaid && !group?.doesSellMembership;
};

export { isGroupSubscription as default };
