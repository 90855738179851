/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable keyword-spacing */

import React from 'react';

const VISIBILITY_TEXT_TO_CLASSNAME = {
    public: 'public-visibility',
    private: 'group-visibility',
    secret: 'secret-visibility',
};

const VisibilitySetting = ({ visibilityRefSetting }) => {
    const buttonText = visibilityRefSetting?.display;
    const baseClass = 'disable-pointer visibility-button';
    const buttonClass = `${baseClass} ${VISIBILITY_TEXT_TO_CLASSNAME[visibilityRefSetting?.type]}`;

    return (
        <button type="button" className={buttonClass}>
            {buttonText}
        </button>
    );
};

export default VisibilitySetting;
