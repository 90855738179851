import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Remarkable from 'remarkable';

import {
    CARD_BLOG,
    CARD_CHAPTER,
    CARD_COURSE,
    CARD_DOCUMENT,
    CARD_EVENT,
    CARD_IMAGE,
    CARD_LINK,
    CARD_MOBILIZE,
    CARD_PLEDGE,
    CARD_POSTBOX,
    CARD_VIDEO,
} from '@core/utilities/constants/card_types';

import { ContentCard } from '@shared/welibrary-graphql/types';
import { isKey } from '@helpers/types/type.helpers';

const iconClasses = {
    [CARD_IMAGE]: 'photo-item',
    [CARD_VIDEO]: 'video-item',
    [CARD_BLOG]: 'blog-item',
    [CARD_EVENT]: 'event-item',
    [CARD_COURSE]: 'course-item',
    [CARD_MOBILIZE]: 'job-item',
    [CARD_PLEDGE]: 'pledge-item',
    [CARD_LINK]: 'link-item',
    [CARD_DOCUMENT]: 'document-item',
    [CARD_POSTBOX]: 'prompt-item',
    [CARD_CHAPTER]: 'prompt-item',
};

// Returns whether card should display author info or anonymous
const showSocialHeader = (card: ContentCard) => {
    const chapterSettings = card?.reference?.chapterSource?.library_settings;

    return (
        (chapterSettings?.displaySocialHeaders ?? true) &&
        card?.library_settings?.displaySocialHeaders
    );
};

const constructAuthorUrl = (userId: string) => {
    return `/u/${userId}`;
};

const constructPostDate = (date: string) => {
    // 2 JAN 2020, 6:22AM
    return moment(date).format('lll');
};

const constructReferenceLink = (
    href: string | undefined | null,
    url: string | undefined | null
) => {
    const newUrl = href?.substring(0, href?.indexOf(`/${url}`));

    // takes this /s/gH6jYat4T2LuEdimX/mariobook/Go6vNPHHcTdad3Mfm/PTchrJZLjK6j5zvig
    // and returns PTchrJZLjK6j5zvig
    return newUrl;
};

const constructSingleCardLink = (url: string) => {
    return `/c/${url}`;
};

const constructCommentCardUrl = (parentCardId: string) => {
    return `/c/${parentCardId}`;
};

const getThumbClass = (type?: string | null) => {
    if (isKey(iconClasses, type) && iconClasses[type]) {
        return `search-result-item-thumb ${iconClasses[type]}`;
    }
    return 'search-result-item-thumb';
};

const renderSubHeaderInfo = (type: string, title: string, url: string, handleFunc: () => void) => {
    const handleClick = () => {
        if (handleFunc) {
            handleFunc();
        }
    };
    return (
        <div className="search-result-item-info">
            {type} in
            <Link
                onClick={handleClick}
                to={{ pathname: `${url}`, fromSearch: true }}
                className="bold"
            >
                &nbsp;
                {title}
            </Link>
        </div>
    );
};

const renderAuthorInfo = (content: ContentCard, handleFunc: () => void) => {
    const showAuthor = showSocialHeader(content);
    const handleClick = () => {
        if (handleFunc) {
            handleFunc();
        }
    };
    let authorName, profileLink, authorId, postDate, displayDate;
    if (!showAuthor) {
        return null;
    }
    if (showAuthor) {
        authorName =
            content && content.author && content.author.profile && content.author.profile.full_name;
        authorId = content && content.author && content.author._id;
        postDate = content && content.date && content.date.last_update;
        profileLink = constructAuthorUrl(authorId ?? '');
        displayDate = constructPostDate(postDate ?? '');
    }

    const renderAuthorName = (
        <Link
            onClick={handleClick}
            to={{ pathname: profileLink, fromSearch: true }}
            className="bold"
        >
            {authorName}
        </Link>
    );
    return (
        <div className="search-result-item-info">
            {renderAuthorName} <span className="dot-spacer"> • </span>
            <span className="search-postdate">{displayDate}</span>
        </div>
    );
};

const renderTitle = (title: string, cardUrl: string, handleFunc: () => void) => {
    const handleClick = () => {
        if (handleFunc) {
            handleFunc();
        }
    };
    return (
        <Link
            onClick={handleClick}
            to={{ pathname: cardUrl, fromSearch: true }}
            className="search-result-item-title"
        >
            {title}
        </Link>
    );
};

const renderBody = (cardBody: string, md: Remarkable) => {
    if (cardBody.length > 120) {
        cardBody = `${cardBody.substring(0, 120).trim()}...`;
    }
    const markdownText = md?.render(cardBody);
    const renderText: JSX.Element | undefined =
        cardBody !== '' && cardBody !== null ? (
            // eslint-disable-next-line react/no-danger
            <span
                className="list-view-body-md"
                dangerouslySetInnerHTML={{ __html: markdownText }}
            />
        ) : undefined;
    return <p className="search-result-item-snippet">{renderText}</p>;
};

export {
    getThumbClass,
    constructAuthorUrl,
    constructCommentCardUrl,
    constructSingleCardLink,
    constructReferenceLink,
    constructPostDate,
    showSocialHeader,
    renderBody,
    renderTitle,
    renderAuthorInfo,
    renderSubHeaderInfo,
};
