import React from 'react';
import { Updater } from 'use-immer';
import { useTranslation } from 'react-i18next';

import DialogPersonForm from '@components/content/newpost/forms/Dialog/DialogPersonForm';
import DialogMessageForm from '@components/content/newpost/forms/Dialog/DialogMessageForm';
import Select from '@dsc/forms/customInputs/Select';
import MultiCustomInput from '@dsc/forms/customInputs/MultiCustomInput';

import { curriedStateSlice } from '@helpers/state/state.helpers';

import { DialogMainState } from '@core/types/Dialogs';

type DialogFormMainProps = {
    state: DialogMainState;
    setState: Updater<DialogMainState>;
};

const DialogFormMain = React.forwardRef<HTMLFieldSetElement, DialogFormMainProps>(
    function DialogFormMain({ state, setState }, ref) {
        const { t } = useTranslation();

        const updateSlice = curriedStateSlice(setState);

        return (
            <fieldset ref={ref} className="course-form-main">
                <header>Dialog Builder</header>

                <Select
                    title="Event"
                    value={state.event}
                    onChange={updateSlice('event')}
                    options={[
                        'quest_begin',
                        'level_completion',
                        'pre_boss_battle',
                        'quest_completion',
                    ]}
                    displayValues={{
                        quest_begin: 'Quest Begin',
                        level_completion: 'Level Completion',
                        pre_boss_battle: 'Pre-Boss Battle',
                        quest_completion: 'Quest Completion',
                    }}
                />

                <MultiCustomInput
                    labels={state.people.map(person => person.name)}
                    state={state.people}
                    onChange={people => updateSlice('people', people)}
                    defaultValue={{ name: '', image: '' }}
                    Component={DialogPersonForm}
                    modalOptions={{ className: 'full-height no-bottom-padding' }}
                    label="People"
                    buttonText="Add Person"
                />

                <MultiCustomInput
                    labels={state.dialogs.map(dialog => (
                        <>
                            <b>
                                {typeof dialog.person === 'number'
                                    ? state.people[dialog.person]?.name
                                    : 'ERROR'}
                                :
                            </b>{' '}
                            <span>{dialog.text.slice(0, 20)}</span>
                        </>
                    ))}
                    state={state.dialogs}
                    onChange={dialog => updateSlice('dialogs', dialog)}
                    defaultValue={{ text: '' }}
                    Component={DialogMessageForm}
                    componentProps={{ people: state.people, event: state.event }}
                    modalOptions={{ className: 'full-height no-bottom-padding' }}
                    label="Messages"
                    buttonText="Add Message"
                />
            </fieldset>
        );
    }
);

export default DialogFormMain;
