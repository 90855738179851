import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';

import ToastContext, {
    TOAST_RESPONSIVE,
    TOAST_TOP,
} from '@components/generic/context/ToastContext';

const Toast = () => {
    const { toast, hideToast } = useContext(ToastContext);

    useEffect(() => {
        let isCancelled = false;
        let timer;
        if (!isCancelled) {
            timer = setTimeout(() => {
                hideToast();
            }, toast.duration);
        }

        return () => {
            clearTimeout(timer);
            isCancelled = true;
        };
    }, [toast.isVisible]);

    const handleCloseToast = e => hideToast();

    let openTransitionClass = 'new-post-submission-confirmation toast-open-transition';
    let closeTransitionClass = 'new-post-submission-confirmation toast-close-transition';
    let toastPositionClass = 'new-post-submission-confirmation-container';

    if (toast?.position === TOAST_TOP) {
        openTransitionClass = 'new-post-submission-confirmation toast-open-transition-top';
        closeTransitionClass = 'new-post-submission-confirmation toast-close-transition-top';
        toastPositionClass = 'new-post-submission-confirmation-container-top';
    } else if (toast?.position === TOAST_RESPONSIVE) {
        openTransitionClass = 'new-post-submission-confirmation toast-open-transition-responsive';
        closeTransitionClass = 'new-post-submission-confirmation toast-close-transition-responsive';
        toastPositionClass = 'toast-responsive';
    }

    const transitionClass = toast.isVisible ? openTransitionClass : closeTransitionClass;

    let toastEl = null;

    if (toast?.isCustomToast === true) {
        toastEl = (
            <div className="toast-container-top">
                <div className={`${toastPositionClass}`}>
                    <div className={`${transitionClass} custom-toast-container`}>
                        <div className="custom-toast-header-wrap">
                            <div className="custom-toast-img-wrap">
                                <img
                                    className="custom-toast-img"
                                    src={toast?.toastImage}
                                    alt="app-icon"
                                />
                            </div>
                            <div className="custom-toast-text-wrap">
                                <p className="custom-toast-text">{toast.toastText}</p>
                            </div>
                        </div>
                        <div className="custom-toast-footer-container">
                            {toast?.toastFooterBtns && toast?.toastFooterBtns}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        toastEl = (
            <div className="toast-container-top">
                <div className={toastPositionClass}>
                    <div className={transitionClass}>
                        <div>
                            {toast.toastText}
                            {toast?.toastLink !== '' && toast?.toastLink !== null && (
                                <Link
                                    onClick={handleCloseToast}
                                    className="notification-link"
                                    to={`/c/${toast?.toastLink}`} // todo make this not specific to card
                                >
                                    View
                                </Link>
                            )}
                        </div>
                        <a className="close-notification" onClick={handleCloseToast} />
                    </div>
                </div>
            </div>
        );
    }

    return createPortal(toastEl, document.getElementById('modal-super-root'));
};

export default Toast;
