import React from 'react';

const FullDownCaretIcon: React.FC<{
    className?: string;
    size?: string;
    strokeWidth?: string;
    color?: string;
}> = ({ className, size = '20' }) => (
    <svg
        className={className}
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M16.8274 7.26082C16.7801 7.14662 16.7 7.04901 16.5972 6.98033C16.4945 6.91166 16.3736 6.875 16.25 6.875L3.75 6.875C3.62639 6.875 3.50555 6.91166 3.40277 6.98033C3.29999 7.04901 3.21988 7.14662 3.17257 7.26083C3.12527 7.37503 3.11289 7.5007 3.13701 7.62194C3.16113 7.74318 3.22066 7.85455 3.30807 7.94195L9.55807 14.1919C9.6161 14.25 9.685 14.296 9.76083 14.3274C9.83666 14.3588 9.91793 14.375 10 14.375C10.0821 14.375 10.1634 14.3588 10.2392 14.3274C10.315 14.296 10.3839 14.25 10.4419 14.1919L16.6919 7.94195C16.7793 7.85454 16.8389 7.74318 16.863 7.62194C16.8871 7.5007 16.8747 7.37503 16.8274 7.26082Z" fill="black" />
    </svg>

);

export default FullDownCaretIcon;
