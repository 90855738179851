// src/content_card/mutations.ts
import { gql as gql4 } from "@apollo/client";

// src/content_card/fragments.ts
import { gql as gql3 } from "@apollo/client";

// src/comment/fragments.ts
import { gql } from "@apollo/client";
var COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    __typename
    _id
    publish
    stream {
      __typename
      _id
    }
    creator {
      __typename
      _id
      profile {
        picture
        full_name
      }
    }
    body
    loggedInUserCanEdit
    childCount
    parentId
  }
`;

// src/tag/fragments.ts
import { gql as gql2 } from "@apollo/client";
var TAG_FRAGMENT = gql2`
    fragment TagFragment on Tag {
        __typename
        _id
        label
        color
        created
        updated
    }
`;

// src/content_card/fragments.ts
var UPDATE_FEATURE_CONSTRAINTS_FRAGMENT = gql3`
  fragment UpdateFeatureConstraintsFragments on ContentCard {
    __typename
    updateFeatureConstraints {
      canEditAllFields
      featuresUserCanEdit {
        type
        field
        options
        canUpdate
      }
    }
  }
`;
var CONTENT_CARD_SEARCH_FRAGMENT = gql3`
  fragment ContentCardSearchFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    media {
      icon
      thumb
    }
    prompt
    isBookmarked
    isArchived
  }
`;
var CONTENT_CARD_COURSE_SEARCH_FRAGMENT = gql3`
  fragment ContentCardCourseSearchFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    media {
      icon
      thumb
    }
    prompt
    courseItems {
      levels {
        title
        media
        resource
      }
    }
    isBookmarked
    isArchived
  }
`;
var CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT = gql3`
  fragment ViewPermissions on ContentCard {
    currentViewPermissions {
      level
      canUserView
    }
  }
`;
var CONTENT_CARD_RATINGS_FRAGMENT = gql3`
  fragment CardRatings on ContentCard {
    ratings_sentiment_score
    latest_rating_sentiments {
      _id
      profile {
        full_name
      }
    }
    ratings_quality_score
    ratings_relevance_score {
      score
      hashtag
    }
    ratings_rank
    loggedInUserVotes {
      sentiment
      quality
      relevance
    }
  }
`;
var CONTENT_CARD_BOOK_SOURCE_FRAGMENT = gql3`
  fragment ContentCardBookSourceFragment on ContentCard {
    __typename
    _id
    url
    title
    library_settings {
      displayReference
      protectionLevel
      accessRoleWhitelist
    }
    reference {
      groupSource {
        profile {
          full_name
        }
        settings {
          protectionLevel
        }
      }
    }
  }
`;
var CONTENT_CARD_REFERENCE_FRAGMENT = gql3`
  fragment ContentCardReferenceFragment on ContentCard {
    reference {
      href
      isResource
      coverImage
      chapterSource {
        title
        url
        prompt
        showTitle
        inheritSurveyAnswers
        subtype
        vote_settings {
          QUALITY {
            active
          }
          RELEVANCE {
            active
          }
          SENTIMENT {
            active
          }
        }
        library_settings {
          engagementTypes
          viewTypes
          defaultViewType
          displayReference
          displaySocialHeaders
        }
        media {
          thumb
        }
      }
      bookSource {
        ...ContentCardBookSourceFragment
      }
      shelfSource {
        title
        url
        library_settings {
          displayReference
          protectionLevel
        }
      }
      librarySource {
        title
        url
        library_settings {
          displayReference
        }
      }
      groupSource {
        _id
        subtype
        profile {
          full_name
          picture
          coverPhoto
        }
        settings {
          shortCode
          shortCodeApprovalRequired
          protectionLevel
          approvalRequired
          formSpec {
            url
          }
        }
        products {
          _id
          name
          price
          currency
          type
          fulfillment {
            groupId
            role
          }
          created
          updated
        }
        paymentSettings {
          stripeAccount {
            id
            email
            country
            charges_enabled
            payouts_enabled
            details_submitted
            external_accounts {
              object
              data {
                country
                currency
              }
              has_more
              url
            }
          }
          isGroupMembershipPaid
        }
        groupActionsAllowed
        currentUserOwnsMembership
        currentUserOwnsFreeSubscription
        doesSellMembership
        currentUserIsAdmin
        currentUserIsDirectAdmin
        currentUserIsMember
      }
    }
  }
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
`;
var CONTENT_CARD_COMMENTS_FRAGMENT = gql3`
  fragment CardCommentsFragment on ContentCard {
    __typename
    _id
    comments {
      ...CommentFragment
    }
  }
  ${COMMENT_FRAGMENT}
`;
var REVEAL_CARD_FRAGMENT = gql3`
  fragment RevealCardFragment on ContentCard {
    __typename
    type
    links {
      source
      other
    }
    media {
      thumb
      icon
      logoLight
      logoDark
    }
  }
`;
var CONTENT_CARD_CREATION_FRAGMENT = gql3`
  fragment ContentCardCreationFragment on CreationItems {
    silhouetteImage
    course {
      _id
      media {
        icon
        thumb
      }
      title
      courseItems {
        materials
        ageGroup
        duration
        people
        levels {
          title
          media
          resource
          preSurvey {
            _id
            type
            subtype
            prompt
            useImage
            image
            range
            answers
            correctAnswer
            skill
            skillSubtype
            skillMilestone
          }
          postSurvey {
            _id
            type
            subtype
            prompt
            useImage
            image
            range
            answers
            correctAnswer
            skill
            skillSubtype
            skillMilestone
          }
        }
      }
      url
    }
    levels {
      title
      description
      image
    }
  }
`;
var CONTENT_CARD_COURSE_FRAGMENT = gql3`
  fragment ContentCardCourseFragment on CourseItems {
    materials
    ageGroup
    duration
    people
    levels {
      title
      media
      video
      resource
      steps {
        text
        useImage
        image
        useVideo
        video
        fullPage
        pageBreak
        skill
        skillSubtype
        skillMilestone
      }
      preSurvey {
        _id
        type
        subtype
        prompt
        useImage
        image
        range
        answers
        correctAnswer
        skill
        skillSubtype
        skillMilestone
      }
      postSurvey {
        _id
        type
        subtype
        prompt
        useImage
        image
        range
        answers
        correctAnswer
        skill
        skillSubtype
        skillMilestone
      }
    }
    publicCompletions
  }
`;
var CONTENT_CARD_PLEDGES_FRAGMENT = gql3`
  fragment ContentCardPledgesFragment on PledgeItems {
    __typename
    _id
    pledges {
      __typename
      _id
      label
      reportingType
      canPledge
      canDeletePledge
      totalPledges
      currentUsersPledges
      currentUsersPledgesMonth: currentUsersPledges(daysAgo: 30)
      currentUsersPledgesDay: currentUsersPledges(daysAgo: 1)
      successfulPledgesAll: currentUsersSuccessfulPledges
      successfulPledgesMonth: currentUsersSuccessfulPledges(daysAgo: 30)
      successfulPledgesDay: currentUsersSuccessfulPledges(daysAgo: 1)
      hidden
    }
    pledgeKeys {
      __typename
      _id
      label
      refactoryPeriod
      limitPerRefractoryPeriod
      description
      reportingType
      hidden
    }
    pledgers {
      __typename
      _id
      profile {
        full_name
        first_name
        picture
      }
    }
    latestPledgerEngagement {
      userId
      date
    }
    totalPledges
    totalPledgers
    pledgesLastDay: totalPledges(daysAgo: 1)
    pledgesLastMonth: totalPledges(daysAgo: 30)
  }
`;
var MOBILIZE_EFFORT_FRAGMENT = gql3`
  fragment MobilizeEffortFragment on ContentCard {
    __typename
    _id
    path
    url
    shortCode
    type
    title
    body
    description
    prompt
    rankOrder
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
      }
      roles {
        isSpeakerOf
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    aliases {
      ... on GroupAliasHandle {
        url
        title
        color
        type
        group {
          __typename
          _id
          profile {
            full_name
            picture
            coverPhoto
          }
        }
      }
      ... on AliasItem {
        url
        title
        color
        type
        item {
          _id
          __typename
          title
          body
          protectionLevel
          prompt
          author {
            __typename
            _id
            type
            profile {
              full_name
            }
          }
          reference {
            href
            coverImage
            bookSource {
              ...ContentCardBookSourceFragment
            }
          }
          media {
            thumb
            icon
          }
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            displayInNewsfeed
            userCanPost
          }
          pledgeItems {
            ...ContentCardPledgesFragment
          }
          whitelabelVerbage {
            __typename
            _id
            value
          }
          isArchived
          userHasPledgedTo
          taskHistory {
            __typename
            cursor
            hasMore
            results {
              pledgeKeyId
              label
              refactoryPeriod
              date
              supplement {
                note
                image
                document
                documentTitle
              }
              verifier {
                name
                email
                phone
                country
                note
                newRecruit
              }
              claimedAccount {
                __typename
                _id
                profile {
                  picture
                  full_name
                  email
                  phone
                  country
                }
              }
            }
            type
          }
        }
      }
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
    }
    loggedInUserCanEdit
    defaultHidden
    isBookmarked
    isArchived
    applicationItems {
      applications {
        __typename
        _id
        createdAt
        status
        data {
          values {
            id
            values
          }
        }
        user {
          __typename
          _id
          profile {
            full_name
            picture
            country
            website
            email
          }
        }
      }
    }
    postingSettings {
      type
      status
      totalPositionsCount
      acceptedCount
      specification {
        url
      }
      postingData {
        parent {
          url
        }
        values {
          id
          type
          customText
          comparator
          values
          siblingId
        }
      }
      requirementsData {
        parent {
          url
        }
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    loggedInUserApplyStatus
    isTemplate
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
`;
var CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT = gql3`
  fragment ContentCardCustomFieldsFragment on ContentCard {
    _id
    customFields {
      manager
      parent {
        url
      }
      parentGroup {
        _id
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
  }
`;
var CONTENT_CARD_FRAGMENT = gql3`
  fragment ContentCardFragment on ContentCard {
    __typename
    _id
    vs
    path
    url
    shortCode
    type
    title
    body
    shortTitle
    description
    prompt
    commentCount
    latestComments {
      cursor
      hasMore
      type
      results {
        _id
        creator {
          _id
          profile {
            full_name
            picture
          }
        }
        body
      }
    }
    inheritSurveyAnswers
    rankOrder
    subtype
    pinnedCardIds
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
        did
      }
      roles {
        isSpeakerOf
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      title
      source
      openInModal
      other
      publisher
    }
    aliases {
      ... on GroupAliasHandle {
        url
        title
        color
        thumb
        icon
        type
        group {
          __typename
          _id
          subtype
          isUnpublished
          profile {
            full_name
            picture
            coverPhoto
          }
          paymentSettings {
            stripeAccount {
              id
              email
              country
              charges_enabled
              payouts_enabled
              details_submitted
              external_accounts {
                object
                data {
                  country
                  currency
                }
                has_more
                url
              }
            }
            isGroupMembershipPaid
          }
          products {
            _id
            name
            price
            currency
            type
            fulfillment {
              groupId
              role
            }
            created
            updated
          }
          groupActionsAllowed
          currentUserOwnsMembership
          currentUserOwnsFreeSubscription
          doesSellMembership
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
        }
      }
      ... on MessageThreadAliasHandle {
        url
        title
        color
        thumb
        icon
        type
        thread {
          __typename
          _id
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
        }
      }
      ... on AliasItem {
        url
        title
        color
        thumb
        icon
        type
        item {
          subtype
          __typename
          _id
          title
          shortTitle
          prompt
          vote_settings {
            QUALITY {
              active
            }
            RELEVANCE {
              active
            }
            SENTIMENT {
              active
            }
          }
          type
          author {
            __typename
            _id
            type
            profile {
              full_name
            }
          }
          reference {
            href
            coverImage
            shelfSource {
              title
              url
              library_settings {
                displayReference
                protectionLevel
              }
            }
            bookSource {
              ...ContentCardBookSourceFragment
            }
            groupSource {
              _id
              subtype
              profile {
                full_name
                picture
                coverPhoto
              }
            }
          }
          media {
            thumb
            icon
          }
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            protectionLevel
            displayInNewsfeed
            userCanPost
          }
          pledgeItems {
            ...ContentCardPledgesFragment
          }
          whitelabelVerbage {
            __typename
            _id
            value
          }
          isArchived
          userHasPledgedTo
          taskHistory {
            __typename
            cursor
            hasMore
            results {
              pledgeKeyId
              label
              refactoryPeriod
              date
              supplement {
                note
                image
                document
                documentTitle
              }
              verifier {
                name
                email
                phone
                country
                note
                newRecruit
              }
              claimedAccount {
                __typename
                _id
                profile {
                  picture
                  full_name
                  email
                  phone
                  country
                }
              }
            }
            type
          }
        }
      }
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
      mediaMetaData {
        height
        width
      }
    }
    label {
      text
      type
    }
    formSpecificationSettings {
      type
      postingForm {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
          dependsOn {
            id
            values
          }
          groups {
            group {
              _id
              path
              groupActionsAllowed
              currentUserIsMember
              currentUserIsAdmin
              profile {
                picture
                full_name
                short_bio
              }
              settings {
                approvalRequired
                protectionLevel
              }
            }
            id
            requireApproval
          }
        }
      }
      applicationForm {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
        }
      }
      form {
        required
        fieldGroups {
          groupName
          fieldIds
          index
        }
        fields {
          index
          type
          id
          options
          customText
          prompt
          placeholder
          type
        }
      }
    }
    library_settings {
      postTypes
      engagementTypes
      filterTypes
      viewTypes
      defaultViewType
      defaultFilter
      allowPostbox
      postboxRoleWhiteList
      userCanPost
      displaySocialHeaders
      displayInNewsfeed
      showCreateChildren
      showResourceHeader
      allowLabels
      displayReference
      protectionLevel
      accessRoleWhitelist
      memberStyle
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      dashboardContentItems {
        name
        url
        type
        filter
      }
      customRoles {
        name
        url
        color
      }
      sidebarMenu {
        name
        url
      }
      recommendations
    }
    sendNotifications
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    meta {
      course {
        revealType
        revealContent {
          ...RevealCardFragment
        }
        compensation
        time
        sequence
      }
      research {
        category
        journalName
        organization
        publicationType
        abstract
        authors
        fileURL
        tags
        issue
      }
      mobilize {
        date
        qualifications
        skills
        summary
        city
        state
        country
        cta
        goalnumber
        hours
        matchterm
        pledge
        timeCommit
        pertime
      }
      pledge {
        date
        qualifications
        skills
        summary
        city
        state
        country
        cta
        goalnumber
        hours
        matchterm
        pledge
        timeCommit
        pertime
      }
    }
    loggedInUserCanEdit
    currentUserHasRead
    defaultHidden
    isBookmarked
    isArchived
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    isTemplate
    postingSettings {
      type
      status
      totalPositionsCount
      acceptedCount
      specification {
        url
      }
      postingData {
        parent {
          url
        }
        values {
          id
          type
          customText
          comparator
          values
          siblingId
        }
      }
      requirementsData {
        parent {
          url
        }
        values {
          id
          type
          comparator
          values
          siblingId
        }
      }
    }
    mobilizationSettings {
      approvalType
      status
      efforts {
        type
        card {
          __typename
          library_settings {
            postTypes
            engagementTypes
            filterTypes
            defaultFilter
            viewTypes
            defaultViewType
            allowPostbox
            displaySocialHeaders
            allowLabels
            displayReference
            displayInNewsfeed
          }
          ...MobilizeEffortFragment
        }
        status
        country
        countGoal
        count
      }
    }
    courseItems {
      ...ContentCardCourseFragment
    }
    creationItems {
      ...ContentCardCreationFragment
    }
    surveyQuestions {
      _id
      type
      subtype
      prompt
      useImage
      image
      range
      answers
      correctAnswer
      skill
      skillSubtype
      skillMilestone
    }
    timeAndPlaceSettings {
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      location {
        formatted_address
        location {
          type
          coordinates
        }
        city
        country
        postal
        state
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
    }
    eventOverviewDetails {
      title
      description
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      displayStartTime
      displayEndTime
      location {
        formatted_address
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
      eventLocationSettings {
        location {
          formatted_address
          city
          state
          country
        }
        type
        link
        venue {
          name
          link
          location {
            formatted_address
            location {
              type
              coordinates
            }
            postal
            city
            state
            country
          }
        }
      }
    }
    eventSettings {
      headline
      date
      dates {
        startDate
        endDate
      }
      startTime
      endTime
      location {
        formatted_address
      }
      link
      rsvpLink
      allDay
      timeZone {
        name
      }
    }
    eventAttendance {
      user {
        _id
        profile {
          full_name
          picture
        }
        socialActionsAllowed
      }
      status
    }
    canMembersSuggestEdits
    showTitle
    protectionLevel
    currentUserCanSuggestEdit
    verticalScrolling
    dialogSettings {
      event
      people {
        name
        image
      }
      dialogs {
        person
        text
      }
    }
    showPostTypes
    ...UpdateFeatureConstraintsFragments
    ...CardRatings
    ...ContentCardCustomFieldsFragment
  }
  ${CONTENT_CARD_RATINGS_FRAGMENT}
  ${REVEAL_CARD_FRAGMENT}
  ${CONTENT_CARD_PLEDGES_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${MOBILIZE_EFFORT_FRAGMENT}
  ${CONTENT_CARD_BOOK_SOURCE_FRAGMENT}
  ${CONTENT_CARD_COURSE_FRAGMENT}
  ${CONTENT_CARD_CREATION_FRAGMENT}
  ${CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT}
`;
var CONTENT_CARD_PLATFORM_FRAGMENT = gql3`
  fragment ContentCardPlatformFragment on ContentCard {
    _id
    __typename
    vs
    type
    url
    platformSettings {
      platformGroupOwner
      forms {
        formCardUrl
        formSpecificationSettings {
          type
          form {
            fieldGroups {
              groupName
              index
              fieldIds
            }
            required
            fields {
              index
              id
              type
              options
              prompt
              defaultComparator
              customText
              selectMultiple
              siblingId
              allowOther
              required
              showOnSignup
              groups {
                group {
                  _id
                  path
                  groupActionsAllowed
                  currentUserIsMember
                  currentUserIsAdmin
                  profile {
                    picture
                    full_name
                    short_bio
                  }
                  settings {
                    approvalRequired
                    protectionLevel
                  }
                }
                id
                requireApproval
              }
              placeholder
              dependsOn {
                id
                values
              }
            }
          }
        }
      }
    }
  }
`;
var CONTENT_CARD_CHAPTER_FRAGMENT = gql3`
  fragment ContentCardChapterFragment on ContentCard {
    _id
    vs
    type
    url
    title
    shortTitle
    media {
      icon
      thumb
    }
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
      }
    }
    description
    isArchived
    prompt
    loggedInUserCanEdit
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    library_settings {
      postTypes
      engagementTypes
      filterTypes
      viewTypes
      defaultViewType
      defaultFilter
      allowPostbox
      postboxRoleWhiteList
      userCanPost
      displaySocialHeaders
      displayInNewsfeed
      allowLabels
      displayReference
      tagAggregation {
        ...TagFragment
      }
      cardAggregation {
        __typename
        _id
        title
        url
        body
        media {
          thumb
          icon
        }
      }
    }
    tags {
      __typename
      hasMore
      cursor
      type
      results {
        ...TagFragment
      }
    }
    sendNotifications
    rolesAvailable {
      url
      name
    }
    ...ContentCardReferenceFragment
    rankOrder
    canMembersSuggestEdits
    currentUserCanSuggestEdit
    showTitle
    childrenCount
    showPostTypes
    inheritSurveyAnswers
    isTemplate
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var RAINBOW_ROAD_FRAGMENT = gql3`
  fragment RainbowRoadFragment on ContentCard {
    __typename
    _id
    url
    vs
    type
    subtype
    title
    description
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
        loggedInUserConnectionStatus
      }
      roles {
        isSpeakerOf
      }
    }
    media {
      icon
    }
    loggedInUserCanEdit
    rainbowRoadSettings {
      header {
        color
        darkMode
        image
        backgroundImage
        showTopInfo
      }
      body {
        color
        darkMode
        image
      }
      nodes {
        circleSize
        icon
        lineStyle
        lineColor
        outline
        winding
        spinnerImage
      }
      font
      questSettings {
        creationsChannel {
          url
          title
          children {
            ...ContentCardFragment
          }
        }
        creationNodeSettings {
          icon
          backgroundImage
          showMetaInformation
          spinnerImage
        }
        completionRequirement
        dialogsChannel {
          url
          title
          children {
            ...ContentCardFragment
          }
        }
        questEndImage
        vcSettings {
          title
          name
          description
          image
        }
      }
    }
    library_settings {
      engagementTypes
    }
    nodes {
      title
      icon
      backgroundImage
      spinnerImage
      showMetaInformation
      preOrPost
      alias {
        ... on UserAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          user {
            _id
            profile {
              full_name
              picture
              coverPhoto
              full_bio
              short_bio
              website
              facebookProfile
              twitterProfile
              instagramProfile
              linkedInProfile
            }
            socialActionsAllowed
          }
        }
        ... on GroupAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          group {
            __typename
            _id
            path
            profile {
              full_name
              short_bio
              full_bio
              picture
              coverPhoto
              country
              website
              email
              customFields {
                parent {
                  url
                }
                parentGroup {
                  _id
                }
                manager
                values {
                  id
                  type
                  comparator
                  values
                  siblingId
                }
              }
            }
            socialActionsAllowed
            shelf {
              __typename
              _id
              url
              media {
                thumb
                icon
              }
            }
            settings {
              __typename
              subscriptions
              formSpec {
                url
                formSpecificationSettings {
                  type
                  form {
                    required
                    fieldGroups {
                      groupName
                      fieldIds
                      index
                    }
                    fields {
                      index
                      type
                      id
                      options
                      customText
                      prompt
                      placeholder
                      type
                      selectMultiple
                      siblingId
                      defaultComparator
                      required
                      allowOther
                      dependsOn {
                        id
                        values
                      }
                    }
                  }
                }
              }
              protectionLevel
              memberStyle
              dashboards {
                name
                url
                type
                filter
              }
              customSignupFields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                weight
                required
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
              recommendations
              canMembersSuggestEdits
              shortCode
              shortCodeApprovalRequired
              groupChatroom
              adminChatroom
              approvalRequired
              registrationTitle
              registrationDescription
              registrationActionText
              enableGroupNewsfeed
            }
            hasUnreadDashboard
            subtype
            totalMembers
            currentUserIsAdmin
            currentUserIsDirectAdmin
            currentUserIsMember
            currentUserLastActiveDate
            groupActionsAllowed
            isTemplate
            allSignupFields {
              parentGroup {
                _id
              }
              manager
              signupFields {
                id
                index
                type
                prompt
                customText
                options
                selectMultiple
                allowOther
                weight
                required
                link
                dependsOn {
                  id
                  values
                }
                defaultComparator
                siblingId
              }
            }
            whitelabelVerbage {
              _id
              value
              plainText
            }
            parentGroup {
              __typename
              _id
              profile {
                full_name
              }
            }
            channelsBook {
              title
              url
              media {
                thumb
                icon
              }
              children {
                title
                url
                reference {
                  href
                }
              }
            }
          }
        }
        ... on MessageThreadAliasHandle {
          url
          type
          title
          thumb
          icon
          color
          thread {
            __typename
            _id
            participants {
              __typename
              user {
                __typename
                _id
                profile {
                  __typename
                  full_name
                  short_bio
                  full_bio
                  picture
                  coverPhoto
                  facebookProfile
                  instagramProfile
                  twitterProfile
                  linkedInProfile
                }
              }
              hasUnreadMessages
            }
            messages {
              __typename
              hasMore
              cursor
              type
              messages {
                __typename
                _id
                author {
                  __typename
                  _id
                  profile {
                    __typename
                    first_name
                    full_name
                    picture
                  }
                }
                text
                created
                updated
              }
            }
            displayName
            displayIcon
            created
            updated
            group {
              __typename
              _id
              profile {
                full_name
                picture
                coverPhoto
              }
            }
            currentUserNotificationLevel
          }
        }
        ... on AliasItem {
          url
          type
          title
          thumb
          icon
          color
          item {
            ...ContentCardFragment
            ...ContentCardReferenceFragment
          }
        }
      }
    }
    aliases {
      ... on UserAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        user {
          _id
          profile {
            full_name
            picture
            coverPhoto
            full_bio
            short_bio
            website
            facebookProfile
            twitterProfile
            instagramProfile
            linkedInProfile
          }
          socialActionsAllowed
        }
      }
      ... on GroupAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        group {
          __typename
          _id
          path
          profile {
            full_name
            short_bio
            full_bio
            picture
            coverPhoto
            country
            website
            email
            customFields {
              parent {
                url
              }
              parentGroup {
                _id
              }
              manager
              values {
                id
                type
                comparator
                values
                siblingId
              }
            }
          }
          socialActionsAllowed
          shelf {
            __typename
            _id
            url
            media {
              thumb
              icon
            }
          }
          settings {
            __typename
            subscriptions
            formSpec {
              url
              formSpecificationSettings {
                type
                form {
                  required
                  fieldGroups {
                    groupName
                    fieldIds
                    index
                  }
                  fields {
                    index
                    type
                    id
                    options
                    customText
                    prompt
                    placeholder
                    type
                    selectMultiple
                    siblingId
                    defaultComparator
                    required
                    allowOther
                    dependsOn {
                      id
                      values
                    }
                  }
                }
              }
            }
            protectionLevel
            memberStyle
            dashboards {
              name
              url
              type
              filter
            }
            customSignupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
            recommendations
            canMembersSuggestEdits
            shortCode
            shortCodeApprovalRequired
            groupChatroom
            adminChatroom
            approvalRequired
            registrationTitle
            registrationDescription
            registrationActionText
            enableGroupNewsfeed
          }
          hasUnreadDashboard
          subtype
          totalMembers
          currentUserIsAdmin
          currentUserIsDirectAdmin
          currentUserIsMember
          currentUserLastActiveDate
          groupActionsAllowed
          isTemplate
          allSignupFields {
            parentGroup {
              _id
            }
            manager
            signupFields {
              id
              index
              type
              prompt
              customText
              options
              selectMultiple
              allowOther
              weight
              required
              link
              dependsOn {
                id
                values
              }
              defaultComparator
              siblingId
            }
          }
          whitelabelVerbage {
            _id
            value
            plainText
          }
          parentGroup {
            __typename
            _id
            profile {
              full_name
            }
          }
          channelsBook {
            title
            url
            media {
              thumb
              icon
            }
            children {
              title
              url
              reference {
                href
              }
            }
          }
        }
      }
      ... on MessageThreadAliasHandle {
        url
        type
        title
        thumb
        icon
        color
        thread {
          __typename
          _id
          participants {
            __typename
            user {
              __typename
              _id
              profile {
                __typename
                full_name
                short_bio
                full_bio
                picture
                coverPhoto
                facebookProfile
                instagramProfile
                twitterProfile
                linkedInProfile
              }
            }
            hasUnreadMessages
          }
          messages {
            __typename
            hasMore
            cursor
            type
            messages {
              __typename
              _id
              author {
                __typename
                _id
                profile {
                  __typename
                  first_name
                  full_name
                  picture
                }
              }
              text
              created
              updated
            }
          }
          displayName
          displayIcon
          created
          updated
          group {
            __typename
            _id
            profile {
              full_name
              picture
              coverPhoto
            }
          }
          currentUserNotificationLevel
        }
      }
      ... on AliasItem {
        url
        type
        title
        thumb
        icon
        color
        item {
          ...ContentCardFragment
          ...ContentCardReferenceFragment
        }
      }
    }
  }

  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var CONTENT_CARD_BOOK_FRAGMENT = gql3`
  fragment ContentCardBookFragment on ContentCard {
    _id
    vs
    type
    subtype
    url
    title
    media {
      icon
      thumb
    }
    author {
      _id
      type
      profile {
        full_name
      }
    }
    children(sort: ordered) {
      ...ContentCardChapterFragment
    }
    description
    body
    prompt
    promptEnabled
    loggedInUserFollows
    loggedInUserCanEdit
    isTemplate
    library_settings {
      displayReference
      displayInNewsfeed
      displaySocialHeaders
      protectionLevel
      accessRoleWhitelist
      defaultFilter
      filterTypes
      engagementTypes
      customRoles {
        name
        url
        color
      }
    }
    sendNotifications
    vote_settings {
      QUALITY {
        active
      }
      RELEVANCE {
        active
      }
      SENTIMENT {
        active
      }
    }
    customFields {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
    cardActionsAllowed
    rankOrder
    verticalScrolling
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_BOOK_BIG_SCREEN_FRAGMENT = gql3`
  fragment ContentCardBookBigScreenFragment on ContentCard {
    _id
    type
    url
    title
    media {
      icon
      thumb
      __typename
    }
    author {
      _id
      type
      profile {
        full_name
        __typename
      }
      __typename
    }
    library_settings {
      displaySocialHeaders
    }
    prompt
    loggedInUserFollows
    loggedInUserCanEdit
    __typename
  }
`;
var CONTENT_CARD_SHELF_FRAGMENT = gql3`
  fragment ContentCardShelfFragment on ContentCard {
    __typename
    _id
    url
    shortCode
    type
    title
    body
    description
    author {
      _id
      type
      profile {
        full_name
        full_bio
        picture
      }
    }
    group {
      __typename
      _id
      subtype
      username
      profile {
        full_name
        full_bio
        picture
      }
    }
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    media {
      thumb
      icon
    }
    library_settings {
      displayReference
      displayInNewsfeed
      dashboardContentItems {
        name
        url
        type
        filter
      }
      protectionLevel
      accessRoleWhitelist
    }
    customFields {
      parent {
        url
      }
      values {
        id
        type
        comparator
        values
        siblingId
      }
    }
    cardActionsAllowed
    loggedInUserFollows
    loggedInUserCanEdit
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_LIBRARY_FRAGMENT = gql3`
  fragment ContentCardLibraryFragment on ContentCard {
    __typename
    _id
    url
    type
    title
    body
    description
    date {
      hide_display
      last_update
      publish
    }
    links {
      source
      other
    }
    media {
      thumb
      icon
      logoLight
      logoDark
      favicon
      webclip
    }
    loggedInUserFollows
    loggedInUserCanEdit
    library_settings {
      welcomeText
      autoFollowCards
      autoFollowUsers
      initialSubscriptions
      protectionLevel
      memberStyle
      sidebarMenu {
        name
        url
      }
      dashboardMenu {
        name
        url
      }
      dashboardContentItems {
        name
        url
        type
        filter
      }
      customRoles {
        name
        url
        color
      }
      customSignupFields {
        id
        index
        type
        prompt
        customText
        options
        selectMultiple
        allowOther
        weight
        required
        showOnSignup
        groups {
          group {
            _id
            path
            groupActionsAllowed
            currentUserIsMember
            currentUserIsAdmin
            profile {
              picture
              full_name
              short_bio
            }
            settings {
              approvalRequired
              protectionLevel
            }
          }
          id
          requireApproval
        }
        link
        dependsOn {
          id
          values
        }
        defaultComparator
        siblingId
      }
      displayReference
      displayInNewsfeed
      recommendations
    }
    cardActionsAllowed
    ...ViewPermissions
    ...UpdateFeatureConstraintsFragments
  }
  ${CONTENT_CARD_VIEW_PERMISSIONS_FRAGMENT}
  ${UPDATE_FEATURE_CONSTRAINTS_FRAGMENT}
`;
var CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT = gql3`
  fragment ContentCardChapterChildrenFragment on ContentCard {
    __typename
    _id
    chapterChildren: children(restrictType: chapter) {
      ...ContentCardChapterFragment
    }
  }
  ${CONTENT_CARD_CHAPTER_FRAGMENT}
`;

// src/content_card/mutations.ts
var UPDATE_CARD = gql4`
    mutation UpdateCard(
        $cardID: ID
        $cardUrl: String
        $shortCode: String
        $type: String
        $title: String
        $body: String
        $prompt: String
        $description: String
        $link: String
        $linkTitle: String
        $linkPublisher: String
        $aliases: [AliasItemInput]
        $mediaThumb: String
        $mediaFavicon: String
        $mediaWebclip: String
        $mediaIcon: String
        $mediaLogoLight: String
        $mediaLogoDark: String
        $authorID: String
        $pinned: Boolean
        $pinnedCardIds: [String]
        $hideDate: Boolean
        $deleteCard: Boolean
        $labelText: String
        $removeLabel: Boolean
        $allowLabels: Boolean
        $voteTypes: [CardVoteType]
        $engagementTypes: [CardEngagementType]
        $postTypes: [CardPostType]
        $filterTypes: [FilterType]
        $viewTypes: [ViewType]
        $defaultViewType: ViewType
        $defaultFilter: FilterType
        $allowPostbox: Boolean
        $pledgeItems: PledgeItemsInput
        $postboxRoleWhiteList: [String]
        $displaySocialHeaders: Boolean
        $displayInNewsfeed: Boolean
        $showCreateChildren: Boolean
        $showResourceHeader: Boolean
        $autoFollowCards: [String]
        $autoFollowUsers: [String]
        $initialSubscriptions: [String]
        $protectionLevel: ViewPermission
        $accessRoleWhitelist: [String]
        $memberStyle: MembershipStyle
        $sidebarMenu: [MenuItemInput]
        $dashboardMenu: [MenuItemInput]
        $dashboardContentItems: [DashboardContentItemInput]
        $customRoles: [CustomRoleInput]
        $customSignupFields: [CustomSignupFieldInput]
        $displayReference: [ReferenceType]
        $meta: [MetaItemInput]
        $rankOrder: Float
        $sendNotifications: Boolean
        $customFields: [UpdateCustomFieldsInput]
        $recommendations: Boolean
        $cardAggregation: [String]
        $tagAggregation: [String]
        $isTemplate: Boolean
        $canMembersSuggestEdits: Boolean
        $showTitle: Boolean
        $postingSettings: PostingSettingsInput
        $verticalScrolling: Boolean
        $mobilizationSettings: MobilizationSettingsInput
        $formSpecificationSettings: FormSpecificationSettingsInput
        $courseItems: CourseItemsInput
        $creationItems: CreationItemsInput
        $surveyQuestions: [SurveyQuestionInput]
        $inheritSurveyAnswers: Boolean
        $shortTitle: String
        $openInModal: Boolean
        $eventSettings: EventSettingsInput
        $eventOverviewDetails: TimeAndPlaceSettingsInput
        $timeAndPlaceSettings: [TimeAndPlaceSettingsInput]
        $dialogSettings: DialogSettingsInput
        $includeUnpublishedCards: Boolean
        $showPostTypes: Boolean
    ) {
        updateCard(
            input: {
                cardID: $cardID
                cardUrl: $cardUrl
                shortCode: $shortCode
                type: $type
                title: $title
                body: $body
                prompt: $prompt
                description: $description
                link: $link
                linkPublisher: $linkPublisher
                linkTitle: $linkTitle
                aliases: $aliases
                mediaThumb: $mediaThumb
                mediaFavicon: $mediaFavicon
                mediaWebclip: $mediaWebclip
                mediaIcon: $mediaIcon
                mediaLogoLight: $mediaLogoLight
                mediaLogoDark: $mediaLogoDark
                authorID: $authorID
                pinned: $pinned
                pinnedCardIds: $pinnedCardIds
                hideDate: $hideDate
                deleteCard: $deleteCard
                labelText: $labelText
                removeLabel: $removeLabel
                allowLabels: $allowLabels
                voteTypes: $voteTypes
                engagementTypes: $engagementTypes
                postTypes: $postTypes
                filterTypes: $filterTypes
                defaultFilter: $defaultFilter
                viewTypes: $viewTypes
                defaultViewType: $defaultViewType
                allowPostbox: $allowPostbox
                postboxRoleWhiteList: $postboxRoleWhiteList
                displaySocialHeaders: $displaySocialHeaders
                displayInNewsfeed: $displayInNewsfeed
                showCreateChildren: $showCreateChildren
                showResourceHeader: $showResourceHeader
                autoFollowCards: $autoFollowCards
                autoFollowUsers: $autoFollowUsers
                initialSubscriptions: $initialSubscriptions
                protectionLevel: $protectionLevel
                accessRoleWhitelist: $accessRoleWhitelist
                memberStyle: $memberStyle
                sidebarMenu: $sidebarMenu
                dashboardMenu: $dashboardMenu
                customRoles: $customRoles
                customSignupFields: $customSignupFields
                dashboardContentItems: $dashboardContentItems
                displayReference: $displayReference
                meta: $meta
                rankOrder: $rankOrder
                sendNotifications: $sendNotifications
                customFields: $customFields
                recommendations: $recommendations
                canMembersSuggestEdits: $canMembersSuggestEdits
                cardAggregation: $cardAggregation
                tagAggregation: $tagAggregation
                isTemplate: $isTemplate
                showTitle: $showTitle
                postingSettings: $postingSettings
                mobilizationSettings: $mobilizationSettings
                verticalScrolling: $verticalScrolling
                formSpecificationSettings: $formSpecificationSettings
                pledgeItems: $pledgeItems
                courseItems: $courseItems
                creationItems: $creationItems
                surveyQuestions: $surveyQuestions
                inheritSurveyAnswers: $inheritSurveyAnswers
                shortTitle: $shortTitle
                openInModal: $openInModal
                eventSettings: $eventSettings
                timeAndPlaceSettings: $timeAndPlaceSettings
                eventOverviewDetails: $eventOverviewDetails
                dialogSettings: $dialogSettings
                includeUnpublishedCards: $includeUnpublishedCards
                showPostTypes: $showPostTypes
            }
        ) {
            ...ContentCardFragment
            ...ContentCardCustomFieldsFragment
            pledgeItems {
                ...ContentCardPledgesFragment
            }
            ...ContentCardChapterChildrenFragment
        }
    }
    ${CONTENT_CARD_FRAGMENT}
    ${CONTENT_CARD_CUSTOM_FIELDS_FRAGMENT}
    ${CONTENT_CARD_PLEDGES_FRAGMENT}
    ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
`;
var UPDATE_RAINBOW_ROAD = gql4`
    mutation UpdateRainbowRoad($input: ContentCardUpdateInput!) {
        updateRainbowRoad(input: $input) {
            ...RainbowRoadFragment
        }
    }
    ${RAINBOW_ROAD_FRAGMENT}
`;
var FOLLOW_CARD = gql4`
    mutation FollowCard($url: String!, $unFollow: Boolean) {
        followCard(url: $url, unFollow: $unFollow) {
            __typename
            _id
            cardActionsAllowed
        }
    }
`;
var FLAG_CONTENT = gql4`
    mutation FlagContent($url: String!, $reason: String) {
        flagContent(url: $url, reason: $reason) {
            ...ContentCardFragment
        }
    }
    ${CONTENT_CARD_FRAGMENT}
`;
var FLAG_CONTENT_V2 = gql4`
    mutation FlagContentV2($contentCardId: String!, $userId: String) {
        flagContentV2(contentCardId: $contentCardId, userId: $userId)
    }
`;
var UPDATE_PLATFORM = gql4`
    mutation UpdatePlatform($input: PlatformCardUpdateInput!) {
        updatePlatform(input: $input) {
            ...ContentCardPlatformFragment
        }
    }
    ${CONTENT_CARD_PLATFORM_FRAGMENT}
`;
var DUPLICATE_CARD = gql4`
    mutation DuplicateCardWithChildren($url: String!) {
        duplicateCardWithChildren(url: $url) {
            ...ContentCardShelfFragment
        }
    }
    ${CONTENT_CARD_SHELF_FRAGMENT}
`;
var CREATE_ATTACHED_CHAPTER = gql4`
    mutation AttachedChapter(
        $parentUrl: String!
        $title: String!
        $authorID: String
        $templateUrl: String
        $subtype: String
        $voteTypes: [CardVoteType]
        $isTemplate: Boolean
        $prompt: String
        $promptEnabled: Boolean
        $sendNotifications: Boolean
        $displayInNewsfeed: Boolean
        $defaultFilter: String
        $filterTypes: [FilterType]
        $engagementTypes: [CardEngagementType]
        $displaySocialHeaders: Boolean
        $adminIds: [String]
    ) {
        attachedChapter(
            input: {
                parentUrl: $parentUrl
                title: $title
                authorID: $authorID
                templateUrl: $templateUrl
                subtype: $subtype
                voteTypes: $voteTypes
                isTemplate: $isTemplate
                prompt: $prompt
                promptEnabled: $promptEnabled
                sendNotifications: $sendNotifications
                displayInNewsfeed: $displayInNewsfeed
                defaultFilter: $defaultFilter
                filterTypes: $filterTypes
                engagementTypes: $engagementTypes
                displaySocialHeaders: $displaySocialHeaders
                adminIds: $adminIds
            }
        ) {
            ...ContentCardChapterFragment
        }
    }
    ${CONTENT_CARD_CHAPTER_FRAGMENT}
`;
var CREATE_ATTACHED_BOOK = gql4`
    mutation AttachedBook(
        $parentUrl: String!
        $title: String!
        $firstChapter: String
        $authorID: String
        $templateUrl: String
        $isTemplate: Boolean
        $mediaThumb: String
    ) {
        attachedBook(
            input: {
                parentUrl: $parentUrl
                title: $title
                firstChapter: $firstChapter
                authorID: $authorID
                templateUrl: $templateUrl
                isTemplate: $isTemplate
                mediaThumb: $mediaThumb
            }
        ) {
            ...ContentCardBookFragment
        }
    }
    ${CONTENT_CARD_BOOK_FRAGMENT}
`;
var CREATE_ATTACHED_SHELF = gql4`
    mutation AttachedShelf(
        $parentUrl: String!
        $title: String!
        $url: String
        $authorID: String
        $ownerID: String
    ) {
        attachedShelf(
            input: {
                url: $url
                parentUrl: $parentUrl
                title: $title
                authorID: $authorID
                ownerID: $ownerID
            }
        ) {
            ...ContentCardBookFragment
        }
    }
    ${CONTENT_CARD_BOOK_FRAGMENT}
`;
var CREATE_ATTACHED_LIBRARY = gql4`
    mutation AttachedLibrary(
        $title: String!
        $url: String
        $body: String
        $mediaLogoLight: String
        $mediaLogoDark: String
        $autoFollowCards: [String]
        $autoFollowUsers: [String]
        $initialSubscriptions: [String]
        $protectionLevel: ViewPermission
        $memberStyle: MembershipStyle
        $sidebarMenu: [MenuItemInput]
        $dashboardMenu: [MenuItemInput]
        $dashboardContentItems: [DashboardContentItemInput]
        $customRoles: [CustomRoleInput]
        $customSignupFields: [CustomSignupFieldInput]
    ) {
        attachedLibrary(
            input: {
                url: $url
                title: $title
                body: $body
                mediaLogoDark: $mediaLogoDark
                mediaLogoLight: $mediaLogoLight
                autoFollowCards: $autoFollowCards
                autoFollowUsers: $autoFollowUsers
                initialSubscriptions: $initialSubscriptions
                protectionLevel: $protectionLevel
                memberStyle: $memberStyle
                sidebarMenu: $sidebarMenu
                dashboardMenu: $dashboardMenu
                dashboardContentItems: $dashboardContentItems
                customRoles: $customRoles
                customSignupFields: $customSignupFields
            }
        ) {
            ...ContentCardBookFragment
        }
    }
    ${CONTENT_CARD_BOOK_FRAGMENT}
`;
var ATTACHED_CARD = gql4`
    mutation AttachedCard(
        $parentUrl: String
        $distinctChannelDestination: DistinctChannelDestinationInput
        $title: String
        $linkTitle: String
        $linkPublisher: String
        $body: String!
        $type: String!
        $authorID: String
        $link: String
        $openInModal: Boolean
        $aliases: [AliasItemInput]
        $description: String
        $mediaThumb: String
        $mediaIcon: String
        $meta: [MetaItemInput]
        $displaySocialHeaders: Boolean
        $displayInNewsfeed: Boolean
        $showCreateChildren: Boolean
        $showResourceHeader: Boolean
        $isTemplate: Boolean
        $engagementTypes: [CardEngagementType]
        $formSpecificationSettings: FormSpecificationSettingsInput
        $defaultViewType: ViewType
        $canMembersSuggestEdits: Boolean
        $showTitle: Boolean
        $protectionLevel: ViewPermission
        $url: String
        $inheritSurveyAnswers: Boolean
        $subtype: String
        $customFields: [UpdateCustomFieldsInput]
    ) {
        attachedCard(
            input: {
                parentUrl: $parentUrl
                distinctChannelDestination: $distinctChannelDestination
                title: $title
                linkTitle: $linkTitle
                linkPublisher: $linkPublisher
                body: $body
                type: $type
                authorID: $authorID
                link: $link
                aliases: $aliases
                description: $description
                mediaThumb: $mediaThumb
                mediaIcon: $mediaIcon
                meta: $meta
                displaySocialHeaders: $displaySocialHeaders
                displayInNewsfeed: $displayInNewsfeed
                showCreateChildren: $showCreateChildren
                showResourceHeader: $showResourceHeader
                isTemplate: $isTemplate
                engagementTypes: $engagementTypes
                formSpecificationSettings: $formSpecificationSettings
                defaultViewType: $defaultViewType
                canMembersSuggestEdits: $canMembersSuggestEdits
                showTitle: $showTitle
                protectionLevel: $protectionLevel
                url: $url
                inheritSurveyAnswers: $inheritSurveyAnswers
                customFields: $customFields
                openInModal: $openInModal
                subtype: $subtype
            }
        ) {
            ...ContentCardFragment
            ...ContentCardReferenceFragment
            ...ContentCardChapterChildrenFragment
        }
    }
    ${CONTENT_CARD_FRAGMENT}
    ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
    ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var CREATE_CARD = gql4`
    mutation CreateCard($input: ContentCardInput!) {
        attachedCard(input: $input) {
            ...ContentCardFragment
            ...ContentCardReferenceFragment
            ...ContentCardChapterChildrenFragment
        }
    }
    ${CONTENT_CARD_FRAGMENT}
    ${CONTENT_CARD_CHAPTER_CHILDREN_FRAGMENT}
    ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var CREATE_RAINBOW_ROAD = gql4`
    mutation CreateRainbowRoad($input: ContentCardInput!) {
        createRainbowRoad(input: $input) {
            ...RainbowRoadFragment
        }
    }
    ${RAINBOW_ROAD_FRAGMENT}
`;
var ADD_PLEDGE = gql4`
    mutation AddPledge($input: AddPledgeInput!) {
        addPledge(input: $input) {
            ...ContentCardPledgesFragment
        }
    }

    ${CONTENT_CARD_PLEDGES_FRAGMENT}
`;
var DELETE_PLEDGE = gql4`
    mutation DeletePledge($url: String!, $pledgeId: String!) {
        deletePledge(url: $url, pledgeId: $pledgeId) {
            ...ContentCardPledgesFragment
        }
    }
    ${CONTENT_CARD_PLEDGES_FRAGMENT}
`;
var DELETE_TASK_ACTIVITY = gql4`
    mutation DeleteTaskActivity($taskId: String!) {
        deleteTaskActivity(taskId: $taskId) {
            __typename
            _id
        }
    }
`;
var ATTACHED_ADMIN_CARD = gql4`
    mutation AttachedAdminCard(
        $parentUrl: String!
        $title: String!
        $body: String!
        $ownerID: String!
        $engagementTypes: [CardEngagementType]
    ) {
        attachedTextCard(
            input: {
                parentUrl: $parentUrl
                title: $title
                body: $body
                ownerID: $ownerID
                engagementTypes: $engagementTypes
            }
        ) {
            _id
            bodyPreview
            url
        }
    }
`;
var REQUEST_ACCESS_TO_CARD = gql4`
    mutation RequestAccessToCard($cardUrl: String!, $role: Role!, $message: String) {
        requestAccessToCard(cardUrl: $cardUrl, role: $role, message: $message)
    }
`;
var UPDATE_CARD_RANK_ORDER = gql4`
    mutation UpdateCardRankOrder(
        $cardUrl: String!
        $leftSiblingUrl: String
        $rightSiblingUrl: String
        $index: Int
    ) {
        updateCardRankOrder(
            cardUrl: $cardUrl
            leftSiblingUrl: $leftSiblingUrl
            rightSiblingUrl: $rightSiblingUrl
            index: $index
        ) {
            updatedSiblings
            card {
                __typename
                _id
                rankOrder
            }
            siblings {
                __typename
                _id
                rankOrder
            }
        }
    }
`;
var MOVE_CARD = gql4`
    mutation MoveCard($cardUrl: String!, $newParentUrl: String!, $duplicate: Boolean) {
        moveCard(cardUrl: $cardUrl, newParentUrl: $newParentUrl, duplicate: $duplicate) {
            ...ContentCardFragment
            ...ContentCardReferenceFragment
        }
    }
    ${CONTENT_CARD_FRAGMENT}
    ${CONTENT_CARD_REFERENCE_FRAGMENT}
`;
var ARCHIVE_CARD = gql4`
    mutation ArchiveCard($cardUrl: String!, $unarchive: Boolean) {
        archiveCard(cardUrl: $cardUrl, unarchive: $unarchive) {
            ...ContentCardFragment
        }
    }
    ${CONTENT_CARD_FRAGMENT}
`;
var BOOKMARK_CARD = gql4`
    mutation BookmarkCard($cardUrl: String!, $remove: Boolean) {
        bookmarkCard(cardUrl: $cardUrl, remove: $remove) {
            ...ContentCardFragment
        }
    }
    ${CONTENT_CARD_FRAGMENT}
`;
var UPDATE_TAGS_ON_CARD = gql4`
    mutation UpdateTagsOnCard($cardUrl: String!, $tags: [String]!) {
        updateTagsOnCard(cardUrl: $cardUrl, tags: $tags) {
            ...ContentCardFragment
        }
    }
    ${CONTENT_CARD_FRAGMENT}
`;
var UPDATE_TASK_ACTIVITY = gql4`
    mutation UpdateTaskActivity(
        $taskId: String!
        $name: String!
        $email: String
        $phone: String
        $country: String!
        $note: String!
        $newRecruit: Boolean
        $recruitType: RecruitType
        $enrollEmailTemplateOverride: String
        $enrollSmsBodyOverride: String
    ) {
        updateTaskActivity(
            taskId: $taskId
            name: $name
            email: $email
            phone: $phone
            country: $country
            note: $note
            newRecruit: $newRecruit
            recruitType: $recruitType
            enrollEmailTemplateOverride: $enrollEmailTemplateOverride
            enrollSmsBodyOverride: $enrollSmsBodyOverride
        ) {
            __typename
            _id
            activityId
            pledgeKeyId
            label
            refactoryPeriod
            date
            note
            newRecruit
            verifier {
                name
                email
                phone
                country
                note
                newRecruit
                recruitType
            }
            claimedAccount {
                __typename
                _id
                accountStatus
                profile {
                    picture
                    full_name
                    email
                    phone
                    country
                }
            }
        }
    }
`;
var MARK_CARDS_READ_FOR_LOGGED_IN_USER = gql4`
    mutation MarkCardsReadForLoggedInUser($cardIds: [ID]!) {
        markCardsReadForLoggedInUser(cardIds: $cardIds)
    }
`;
var MARK_CARDS_UNREAD_FOR_LOGGED_IN_USER = gql4`
    mutation MarkCardsUnreadForLoggedInUser($cardIds: [ID]!) {
        markCardsUnreadForLoggedInUser(cardIds: $cardIds)
    }
`;
var SUBMIT_CONTENT_EDIT_SUGGESTION = gql4`
    mutation SubmitContentEditSuggestion($cardId: ID!, $text: String, $userId: String) {
        submitContentEditSuggestion(cardId: $cardId, text: $text, userId: $userId)
    }
`;
var RUN_BULK_CARD_JOB = gql4`
    mutation RunBulkCardJob($input: BulkCardJobInput!) {
        runBulkCardJob(input: $input) {
            updated {
                __typename
                ...ContentCardFragment
            }
            failedTargets
        }
    }
    ${CONTENT_CARD_FRAGMENT}
`;
var UPDATE_APPLICATION_STATUS = gql4`
    mutation UpdateApplicationStatus($appId: String!, $status: ApplicationStatus) {
        updateApplicationStatus(appId: $appId, status: $status) {
            __typename
            _id
            posting {
                __typename
                _id
                title
                postingSettings {
                    type
                    status
                    totalPositionsCount
                    acceptedCount
                }
            }
            status
        }
    }
`;
var APPLY_TO_POSTING = gql4`
    mutation ApplyToPosting($input: ApplicationInput!) {
        applyToPosting(input: $input) {
            __typename
            _id
            posting {
                __typename
                _id
                title
                description
            }
            user {
                __typename
                _id
                profile {
                    __typename
                    picture
                    full_name
                    email
                    country
                    website
                }
            }
            status
            data {
                values {
                    id
                    type
                    comparator
                    values
                    siblingId
                }
            }
            createdAt
        }
    }
`;
var RSVP_TO_EVENT = gql4`
    mutation RSVPToEvent($cardUrl: String!, $status: EventRSVP!) {
        rsvpToEvent(cardUrl: $cardUrl, status: $status) {
            ...ContentCardFragment
        }
    }
    ${CONTENT_CARD_FRAGMENT}
`;
var NOTIFY_GROUPS_OF_TARGET = gql4`
    mutation NotifyGroupsOfTarget($input: NotifyGroupsOfTargetInput!) {
        notifyGroupsOfTarget(input: $input)
    }
`;
export {
  ADD_PLEDGE,
  APPLY_TO_POSTING,
  ARCHIVE_CARD,
  ATTACHED_ADMIN_CARD,
  ATTACHED_CARD,
  BOOKMARK_CARD,
  CREATE_ATTACHED_BOOK,
  CREATE_ATTACHED_CHAPTER,
  CREATE_ATTACHED_LIBRARY,
  CREATE_ATTACHED_SHELF,
  CREATE_CARD,
  CREATE_RAINBOW_ROAD,
  DELETE_PLEDGE,
  DELETE_TASK_ACTIVITY,
  DUPLICATE_CARD,
  FLAG_CONTENT,
  FLAG_CONTENT_V2,
  FOLLOW_CARD,
  MARK_CARDS_READ_FOR_LOGGED_IN_USER,
  MARK_CARDS_UNREAD_FOR_LOGGED_IN_USER,
  MOVE_CARD,
  NOTIFY_GROUPS_OF_TARGET,
  REQUEST_ACCESS_TO_CARD,
  RSVP_TO_EVENT,
  RUN_BULK_CARD_JOB,
  SUBMIT_CONTENT_EDIT_SUGGESTION,
  UPDATE_APPLICATION_STATUS,
  UPDATE_CARD,
  UPDATE_CARD_RANK_ORDER,
  UPDATE_PLATFORM,
  UPDATE_RAINBOW_ROAD,
  UPDATE_TAGS_ON_CARD,
  UPDATE_TASK_ACTIVITY
};
