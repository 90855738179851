import React from 'react';

export default class ErrorItem extends React.Component {
    render() {
        const errorImage = '/images/olive-404-animation.gif';

        return (
            <div className="mc-media-item photoitem">
                <img src={errorImage} alt="Content Card Error" className="mc-photo" />
            </div>
        );
    }
}
