import React from 'react';
import {withTranslation} from 'react-i18next';

const localNamespace = 'imports.wlWeb.ui.components.generic.lightbox.modalMenu';

class ModalMenu extends React.Component {
    render() {
        const { t, cardEdit } = this.props;
        let currentModalMenu;

        if (this.props.darkMode) {
            currentModalMenu = null;
        } else {
            currentModalMenu = (
                <div>
                    <div className="lightbox-nav">
                        <div className="lightbox-nav-container style2">
                            <div className="lightbox-nav-col-container">
                                <a
                                    href="#"
                                    className="lightbox-pagination-button margin-clear hide w-inline-block"
                                >
                                    <div className="content-button-icon x" />
                                </a>
                                <a
                                    onClick={this.props.handleClose}
                                    className="lightbox-nav-link style2 w-inline-block"
                                >
                                    <div>{t(`common:${localNamespace}.cancel`)}</div>
                                </a>
                            </div>
                            <div className="lightbox-nav-col-container">
                                <div className="lightbox-nav-pagination-container hide">
                                    <a
                                        href="#"
                                        className="lightbox-pagination-button inactive w-inline-block"
                                    >
                                        <div className="content-button-icon back-arrow dimmer" />
                                    </a>
                                    <a className="lightbox-pagination-button inactive w-inline-block">
                                        <div className="dimmer">
                                            {t(`common:${localNamespace}.skip`)}
                                        </div>
                                    </a>
                                    <a
                                        href="#"
                                        className="lightbox-pagination-button w-inline-block"
                                    >
                                        <div className="content-button-icon forward-arrow" />
                                    </a>
                                </div>
                                <div className="relative">
                                    <a
                                        onClick={this.props.triggerMenu}
                                        className="lightbox-nav-link callout-link style2 w-inline-block"
                                    >
                                        {cardEdit ? (
                                            <div>
                                                {t(`common:${localNamespace}.save_and_update`)}
                                            </div>
                                        ) : (
                                            <div>
                                                {' '}
                                                {this.props.menuText
                                                    ? this.props.menuText
                                                    : t(
                                                          `common:${localNamespace}.menu_text_default`
                                                      )}
                                            </div>
                                        )}
                                    </a>
                                </div>
                            </div>
                            <a className="lightbox-button hide w-inline-block" />
                        </div>
                    </div>
                </div>
            );
        }

        return currentModalMenu;
    }
}

export default withTranslation()(ModalMenu);
