import React from 'react';

const NavigatorToggleButtonIcon: React.FC<{ className?: string }> = ({ className = '' }) => (
    <svg
        className={className}
        viewBox="0 0 20 24"
        strokeWidth="2"
        stroke="currentcolor"
        fill="none"
    >
        <path
            d="M9.69739 5.07367L15.5 12.0368L9.69739 19"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default NavigatorToggleButtonIcon;
