import React from 'react';
import SkeletonLine from '@web/ui/components/generic/loading/skeleton/SkeletonLine';
import SkeletonCircle from '@web/ui/components/generic/loading/skeleton/SkeletonCircle';

const GenericCardSkelly = props => {
    return (
        <div className="content-card-wrapper voting-wrapper">
            <div className="content-card-container  we-mobile-topgreyborder">
                <div className="mc-source-post-wrapper">
                    <div className="mc-source-container">
                        <SkeletonCircle />
                        <div className="mc-source-info-container" style={{ marginLeft: '6px' }}>
                            <span>
                                <SkeletonLine width="9em" />
                            </span>
                        </div>
                    </div>

                    <div className="mc-source-text-container">
                        <div className="mc-source-title">
                            <p>
                                <SkeletonLine width="100%" />
                            </p>
                        </div>

                        <div className="offset-text-bottom">
                            <p>
                                <SkeletonLine width="100%" />
                            </p>
                            <p>
                                <SkeletonLine width="80%" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenericCardSkelly;
