import useModal from '@web/ui/components/modals/hooks/useModal';

export const INSERT_ID_INTO_CUSTOM_FIELD = '>>->INSERT ID HERE<-<<';

const communityNamespace = 'imports.wlWeb.ui.components.community';

const USER_REQUEST_CONNECTION = 'requestConnection',
    USER_CANCEL_REQUEST_CONNECTION = 'cancelRequestConnection',
    USER_ACCEPT_CONNECTION = 'acceptConnection',
    USER_REJECT_CONNECTION = 'rejectConnection',
    USER_DISCONNECT = 'disconnect',
    USER_FOLLOW = 'follow',
    USER_UNFOLLOW = 'unfollow',
    USER_MESSAGE = 'message',
    USER_TICKET_TRANSFER = 'ticketTransfer';

const CONNECTION_STATUS_REQUESTED = 'requested',
    CONNECTION_STATUS_PENDING = 'pending',
    CONNECTION_STATUS_CONNECTED = 'connected',
    CONNECTION_STATUS_REJECTED = 'rejected',
    CONNECTION_STATUS_DISCONNECTED = 'disconnected',
    CONNECTION_STATUS_IS_LOGGEDINUSER = 'isLoggedInUser';

const USER_TYPE_NORMAL = 'normal',
    USER_TYPE_GROUP = 'group',
    USER_TYPE_SYSTEM = 'system',
    USER_TYPE_EPHEMERAL = 'ephemeral',
    USER_TYPE_GUARDIAN = 'guardian',
    USER_TYPE_CHILD = 'child';

const SOCIAL_ACTIONS = {
    REQUEST_CONNECTION: USER_REQUEST_CONNECTION,
    CANCEL_REQUEST_CONNECTION: USER_CANCEL_REQUEST_CONNECTION,
    ACCEPT_CONNECTION: USER_ACCEPT_CONNECTION,
    REJECT_CONNECTION: USER_REJECT_CONNECTION,
    DISCONNECT: USER_DISCONNECT,
    FOLLOW: USER_FOLLOW,
    UNFOLLOW: USER_UNFOLLOW,
    MESSAGE: USER_MESSAGE,
} as const;

const isUserSociallyEngaged = otherUser => {
    const { socialActionsAllowed } = otherUser;
    if (!socialActionsAllowed) return false;
    return (
        socialActionsAllowed.includes(SOCIAL_ACTIONS.DISCONNECT) ||
        socialActionsAllowed.includes(SOCIAL_ACTIONS.UNFOLLOW)
    );
};

const getSocialActionDropdownItems = (
    otherUser,
    { updateConnection, flagUser, createMessageThread, currentUser, history },
    openQuickMenu,
    t
) => {
    const { closeAllModals } = useModal();
    const { socialActionsAllowed } = otherUser;

    const items = [];
    if (Array.isArray(socialActionsAllowed)) {
        if (socialActionsAllowed.includes(SOCIAL_ACTIONS.MESSAGE)) {
            items.push({
                id: SOCIAL_ACTIONS.MESSAGE,
                title: t(`common:${communityNamespace}.message`),
                onClick: () => {
                    closeAllModals();
                    createMessageThread({
                        variables: {
                            input: {
                                participants: [currentUser._id, otherUser._id],
                            },
                        },
                        update: (cache, { data: { createMessageThread } }) => {
                            const threadId = createMessageThread._id;

                            history.push({
                                pathname: `/messages/${threadId}`,
                                state: {
                                    otherUser,
                                },
                            });
                        },
                    });
                },
            });
        }

        if (socialActionsAllowed.includes(SOCIAL_ACTIONS.DISCONNECT)) {
            items.push({
                id: SOCIAL_ACTIONS.DISCONNECT,
                iconKey: 'x',
                title: t(`common:${communityNamespace}.disconnect`),
                onClick: () => {
                    const _optimisticSocialActions = socialActionsAllowed.filter(
                        action => action !== SOCIAL_ACTIONS.DISCONNECT
                    );
                    _optimisticSocialActions.push(SOCIAL_ACTIONS.REQUEST_CONNECTION);

                    updateConnection({
                        variables: {
                            userId: otherUser._id,
                            action: SOCIAL_ACTIONS.DISCONNECT,
                        },
                        optimisticResponse: {
                            __typename: 'Mutation',
                            updateConnection: {
                                __typename: 'User',
                                _id: otherUser._id,
                                socialActionsAllowed: _optimisticSocialActions,
                            },
                        },
                    });
                },
            });
        } else if (socialActionsAllowed.includes(SOCIAL_ACTIONS.CANCEL_REQUEST_CONNECTION)) {
            items.push({
                id: SOCIAL_ACTIONS.CANCEL_REQUEST_CONNECTION,
                iconKey: 'x',
                title: t(`common:${communityNamespace}.cancel_connection_request`),
                onClick: () => {
                    const _optimisticSocialActions = socialActionsAllowed.filter(
                        action => action !== SOCIAL_ACTIONS.CANCEL_REQUEST_CONNECTION
                    );
                    _optimisticSocialActions.push(SOCIAL_ACTIONS.REQUEST_CONNECTION);

                    updateConnection({
                        variables: {
                            userId: otherUser._id,
                            action: SOCIAL_ACTIONS.CANCEL_REQUEST_CONNECTION,
                        },
                        optimisticResponse: {
                            __typename: 'Mutation',
                            updateConnection: {
                                __typename: 'User',
                                _id: otherUser._id,
                                socialActionsAllowed: _optimisticSocialActions,
                            },
                        },
                    });
                },
            });
        } else if (socialActionsAllowed.includes(SOCIAL_ACTIONS.ACCEPT_CONNECTION)) {
            items.push({
                id: SOCIAL_ACTIONS.ACCEPT_CONNECTION,
                iconKey: 'plus',
                title: t(`common:${communityNamespace}.accept_connection_request`),
                onClick: () => {
                    const _optimisticSocialActions = socialActionsAllowed.filter(
                        action => action !== SOCIAL_ACTIONS.ACCEPT_CONNECTION
                    );
                    _optimisticSocialActions.push(SOCIAL_ACTIONS.DISCONNECT);

                    updateConnection({
                        variables: {
                            userId: otherUser._id,
                            action: SOCIAL_ACTIONS.ACCEPT_CONNECTION,
                        },
                        optimisticResponse: {
                            __typename: 'Mutation',
                            updateConnection: {
                                __typename: 'User',
                                _id: otherUser._id,
                                socialActionsAllowed: _optimisticSocialActions,
                            },
                        },
                    });
                },
            });
        } else if (socialActionsAllowed.includes(SOCIAL_ACTIONS.REJECT_CONNECTION)) {
            items.push({
                id: SOCIAL_ACTIONS.REJECT_CONNECTION,
                iconKey: 'x',
                title: t(`common:${communityNamespace}.reject_connection_request`),
                onClick: () => {
                    const _optimisticSocialActions = socialActionsAllowed.filter(
                        action => action !== SOCIAL_ACTIONS.REJECT_CONNECTION
                    );
                    _optimisticSocialActions.push(SOCIAL_ACTIONS.REQUEST_CONNECTION);

                    updateConnection({
                        variables: {
                            userId: otherUser._id,
                            action: SOCIAL_ACTIONS.REJECT_CONNECTION,
                        },
                        optimisticResponse: {
                            __typename: 'Mutation',
                            updateConnection: {
                                __typename: 'User',
                                _id: otherUser._id,
                                socialActionsAllowed: _optimisticSocialActions,
                            },
                        },
                    });
                },
            });
        } else if (socialActionsAllowed.includes(SOCIAL_ACTIONS.REQUEST_CONNECTION)) {
            items.push({
                id: SOCIAL_ACTIONS.REQUEST_CONNECTION,
                iconKey: 'Request Connection',
                title: t(`common:${communityNamespace}.connect`),
                onClick: () => {
                    const _optimisticSocialActions = socialActionsAllowed.filter(
                        action => action !== SOCIAL_ACTIONS.REQUEST_CONNECTION
                    );
                    _optimisticSocialActions.push(SOCIAL_ACTIONS.CANCEL_REQUEST_CONNECTION);

                    updateConnection({
                        variables: {
                            userId: otherUser._id,
                            action: SOCIAL_ACTIONS.REQUEST_CONNECTION,
                        },
                        optimisticResponse: {
                            __typename: 'Mutation',
                            updateConnection: {
                                __typename: 'User',
                                _id: otherUser._id,
                                socialActionsAllowed: _optimisticSocialActions,
                            },
                        },
                    });
                },
            });
        }
    }

    const getBlockUserItems = reason => {
        return [
            {
                id: 'block-user',
                title: t(`common:${communityNamespace}.block`),
                onClick: () => {
                    flagUser({
                        variables: {
                            _id: otherUser._id,
                            reason,
                            block: true,
                        },
                    });
                },
            },
            {
                id: 'no-block-user',
                title: t(`common:${communityNamespace}.dont_block`),
                onClick: () => {
                    flagUser({
                        variables: {
                            _id: otherUser._id,
                            reason,
                            block: false,
                        },
                    });
                },
            },
        ];
    };

    const reasonsWhyOptions = [
        t(`common:${communityNamespace}.block_reason.not_interested`),
        t(`common:${communityNamespace}.block_reason.suspicious_or_spam`),
        t(`common:${communityNamespace}.block_reason.abusive_or_harmful`),
        t(`common:${communityNamespace}.block_reason.harrasment`),
    ];

    const reasonsItems = reasonsWhyOptions.map(reason => {
        return {
            id: reason,
            title: reason,
            onClick: () => {},
            onClose: () => openQuickMenu(getBlockUserItems(reason)),
        };
    });

    items.push({
        id: 'report',
        iconKey: 'Report',
        title: t(`common:${communityNamespace}.report`),
        onClick: () => {},
        onClose: () => {
            openQuickMenu(reasonsItems);
        },
    });

    return items;
};

export {
    USER_REQUEST_CONNECTION,
    USER_CANCEL_REQUEST_CONNECTION,
    USER_ACCEPT_CONNECTION,
    USER_REJECT_CONNECTION,
    USER_DISCONNECT,
    CONNECTION_STATUS_REQUESTED,
    CONNECTION_STATUS_PENDING,
    CONNECTION_STATUS_CONNECTED,
    CONNECTION_STATUS_REJECTED,
    CONNECTION_STATUS_DISCONNECTED,
    CONNECTION_STATUS_IS_LOGGEDINUSER,
    USER_TYPE_NORMAL,
    USER_TYPE_GROUP,
    USER_TYPE_SYSTEM,
    USER_TYPE_EPHEMERAL,
    USER_TYPE_GUARDIAN,
    USER_TYPE_CHILD,
    SOCIAL_ACTIONS,
    USER_TICKET_TRANSFER,
    getSocialActionDropdownItems,
    isUserSociallyEngaged,
};
