import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import TextInput from '@dsc/forms/textInputs/TextInput';
import ReactPhoneInput, { CountrySelectComponentProps } from 'react-phone-number-input';
import Countries from '@components/generic/forms/countries.json';
import CaretDownIcon from '@web/ui/dsc/svgs/CaretDownIcon';

import TinyGlobe from '@assets/tinyglobe.svg';

import { getCountryFlagUrl } from '@core/utilities/constants/countries';

const localNamespace = 'imports.wlWeb.ui.pages.new-signup.login';

type PhoneInputProps = {
    value: string;
    setValue: (newValue: string) => any;
    error?: React.ReactNode;
    showError?: boolean;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
};

const PhoneInput: React.FC<PhoneInputProps> = ({
    value,
    setValue,
    error,
    showError = true,
    placeholder,
    required = false,
    disabled = false,
}) => {
    const { t } = useTranslation();

    placeholder = placeholder || t(`common:${localNamespace}.phoneNumber`, 'Phone Number');

    return (
        <div className="flex flex-col gap-[10px]">
            <ReactPhoneInput
                placeholder={`${placeholder}${required ? ' *' : ''}`}
                countryOptionsOrder={Countries}
                countries={Countries}
                defaultCountry="US"
                value={value}
                onChange={setValue}
                className={`new-signup-phone-input ${error ? 'has-error' : ''}`}
                inputComponent={TextInputWithPhoneLabel}
                countrySelectComponent={PhoneInputCountrySelect}
                disabled={disabled}
            />
            {error && showError && (
                <span className="text-error-default font-poppins text-[14px] leading-[24px] tracking-[0.25px]">
                    {error}
                </span>
            )}
        </div>
    );
};

const PhoneInputCountrySelect: React.FC<CountrySelectComponentProps> = ({
    name,
    value,
    onChange,
    onFocus,
    onBlur,
    options,
    disabled,
    tabIndex,
    className = '',
}) => {
    const valueAsObject = typeof value === 'string' ? options.find(o => o.value === value) : value;

    return (
        <Select
            name={name}
            value={valueAsObject}
            onChange={x => onChange(x.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            tabIndex={tabIndex}
            isDisabled={disabled}
            className={`${className} bg-grayscale-input-background h-[50px] rounded-[16px]`}
            options={options}
            components={{
                DropdownIndicator: () => <CaretDownIcon type="triangle" />,
                SingleValue: props => {
                    return <div className="absolute left-[38px]" />;
                },
            }}
            // List of all components that can be styled - https://react-select.com/styles#inner-components
            styles={{
                container: styles => ({
                    ...styles,
                    backgroundColor: '#f6f7f9',
                    zIndex: 2, // so that the dropdown menu is above the Password label
                }),
                control: (styles, { isFocused, isDisabled }) => {
                    return {
                        ...styles,
                        height: '100%',
                        border: 'none',
                        backgroundColor: isDisabled ? 'rgba(0, 0, 0, 0.12)' : '#f6f7f9',
                        borderRadius: '16px',
                        paddingRight: '15px',
                        paddingLeft: '15px',
                        cursor: 'pointer',

                        boxShadow: isFocused ? '0 0 0 1px #E3E5E8' : 'none',

                        ':hover': {
                            borderColor: '#E3E5E8',
                        },
                    };
                },
                valueContainer: styles => ({
                    ...styles,
                    width: '55px',
                    padding: '8px 0',
                    margin: '0',
                    fontSize: '16px',
                    fontFamily: 'Poppins',
                    lineHeight: '175%',
                    letterSpacing: '0.75px',
                    display: 'flex',

                    ':before': {
                        backgroundImage: `url(${valueAsObject ? getCountryFlagUrl(valueAsObject?.value) : TinyGlobe
                            })`, // TODO handle international
                        backgroundPosition: 'center',
                        borderRadius: 30,
                        content: '""',
                        display: 'block',
                        height: 30,
                        width: 30,
                        flexShrink: 0,
                        marginRight: '8px',
                        visibility: 'visible',
                    },
                }),
                indicatorSeparator: styles => ({ ...styles, display: 'none' }),
                placeholder: styles => ({ ...styles, marginLeft: '38px', marginRight: '0' }),
                singleValue: styles => ({ ...styles, marginLeft: '38px', marginRight: '0' }),
                input: styles => ({
                    ...styles,
                    alignItems: 'center',
                    display: 'flex',
                    margin: '0',
                    opacity: '0',
                    position: 'absolute',

                    ':after': {
                        display: 'none',
                    },
                }),
                menu: styles => ({
                    ...styles,
                    width: '175px',
                    backgroundColor: '#f6f7f9',
                    borderRadius: '16px',
                    overflow: 'hidden',
                }),
                menuList: styles => ({
                    ...styles,
                    paddingTop: '0',
                    paddingBottom: '0',
                }),
            }}
        />
    );
};

const TextInputWithPhoneLabel = React.forwardRef(
    ({ value, onChange, placeholder, disabled }, ref) => {
        const { t } = useTranslation();
        return (
            <TextInput
                value={value}
                label={placeholder}
                onChange={(updatedValue, event) => {
                    onChange(event);
                }}
                inputRef={ref}
                type="phone"
                className="!m-0"
                disabled={disabled}
            />
        );
    }
);

export default PhoneInput;
