import {
    TOGGLE_SHOW_CHAPTER_BROWSE_MENU_MOBILE,
    TOGGLE_SHOW_EDIT_SETTINGS,
    TOGGLE_SHOW_LOGIN,
    TOGGLE_SHOW_PROFILE,
} from '@core/client/types.js';

import {TOGGLE_POPUP} from '../../actions/ui/timelinePopup';
import * as types from '../../actions/ui/profileTabs';

export default function pageUI(state = { timelinePopup: false }, action = {}) {
    switch (action.type) {
        case types.UPDATE_PROFILE_TAB:
            return Object.assign({}, state, {
                tab: action.tab,
            });
        case TOGGLE_POPUP:
            return Object.assign({}, state, {
                timelinePopup: action.value,
            });
        case TOGGLE_SHOW_LOGIN:
        case TOGGLE_SHOW_PROFILE:
        case TOGGLE_SHOW_EDIT_SETTINGS:
        case TOGGLE_SHOW_CHAPTER_BROWSE_MENU_MOBILE:
            //Default state is not open.
            let isOpen = false;
            //If the state has already been set to open or closed at the id, get that
            if (state.hasOwnProperty(action.id)) {
                isOpen = state[action.id].open;
            }
            // Toggle the open state at the id
            let pageUIItem = {};
            pageUIItem[action.id] = { open: !isOpen };
            // Return a new object for the createCard state, merging in the new property.
            return Object.assign({}, state, pageUIItem);

        default:
            return state;
    }
}
