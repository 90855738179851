import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { SidebarActionsContext } from '@web/ui/components/generic/context/SidebarContext';
import { areDependentQuestionsAnswered } from '@helpers/customFields/customFields.helpers';
import { CustomSignupFieldTypes } from '@shared/welibrary-graphql/types';

/**
 * @typedef {Object} ValuesObject
 * @property {string} id
 * @property {string | string[]} values
 * @property {string} type
 * @property {string} comparator
 */

/**
 * @typedef {Object} CustomFieldsObject
 * @property {{ _id: string }} parentGroup
 * @property {string} manager
 * @property {ValuesObject[]} values
 */

/**
 * @typedef {Object} ProfileObject
 * @property {string} full_name
 * @property {string} picture
 * @property {string} short_bio
 */

/**
 * @typedef {Object} CustomSignupFieldsObject
 * @property {string} customText
 * @property {string} defaultComparator
 * @property {string} id
 * @property {number} index
 * @property {string[]} options
 * @property {string} prompt
 * @property {boolean} selectMultiple
 * @property {string} siblingId
 * @property {string} type
 */

/**
 * @typedef {Object} GroupObject
 * @property {boolean} currentUserIsAdmin
 * @property {boolean} currentUserIsMember
 * @property {ProfileObject} profile
 * @property {{ recommendations: boolean, customSignupFields: CustomSignupFieldsObject[] }} settings
 * @property {string} _id
 *

/**
 * React Hook for getting a specific group's optimizer data
 * This will give you all the data/logic you need to display and update a single
 * optimizer
 *
 * @param {{
 *   group: GroupObject
 *   profile: { customFields: CustomFieldsObject[] }
 *   updateQuery: (payload: CustomFieldsObject) => Promise<void>
 *   isCardOptimize: Boolean
 *   includeLocationAndOrganizationPercentage?: Boolean
 * }}
 */
const useOptimizer = ({
    group,
    profile,
    updateQuery,
    isCardOptimize,
    includeLocationAndOrganizationPercentage = false,
}) => {
    const flags = useFlags();
    const enableJuriesRequest = flags.enableJuriesRequest;
    const { setUnsavedChanges: setUnsavedChangesContext } = useContext(SidebarActionsContext);
    const [unsavedChanges, setUnsavedChangesState] = useState(false);
    const setUnsavedChanges = value => {
        setUnsavedChangesState(value);
        setUnsavedChangesContext(value);
    };

    const groupCustomFields = _.flatten(group?.settings?.customSignupFields);

    // Custom fields the user has already filled out.
    const [userCustomFields] =
        profile?.customFields?.filter(
            customFieldCluster =>
                group._id === customFieldCluster.parentGroup?._id ||
                group._id === customFieldCluster.parentUrl
        ) ?? [];

    const values = userCustomFields?.values ?? [];

    /**
     * Map the starting state based on custom fields the user has already filled out
     * @type {{ parentUrl: string, manager: string, values: Array.<ValuesObject> }}
     */
    const startingPayload = {
        parentUrl: group._id,
        manager: 'group',
        values: values.map(({ id, values, type }) => ({ id, values, type })),
    };

    // Set the update payload to the users's initial custom fields when data arrives
    const [payload, setPayload] = useState(startingPayload);
    useEffect(() => setPayload(startingPayload), [group?._id]);

    // Update user profile with new custom fields
    const saveChanges = async (newPayload = payload) => {
        if (newPayload.values?.length > 0 && newPayload.parentUrl) {
            await updateQuery(newPayload);
        }
        setUnsavedChanges(false);
    };

    // const debouncedSave = useCallback(_.debounce(saveChanges, 2000), []);

    /**
     * Handles the user selecting a new response for a custom field
     * If the custom field already exists in the payload, replaces it.
     * Otherwise, it creates a new custom field entry mapped with the parentUrl
     *
     * @param {string} id custom field id
     * @param {ValuesObject} values custom field values
     * @param {string} type custom field type
     */
    const handleCustomFieldsChange = (id, values, type) => {
        setUnsavedChanges(true);
        setPayload(oldPayload => {
            const newPayload = {
                parentUrl: group._id,
                manager: 'group',
                values: [
                    ...oldPayload.values?.filter(field => field.id !== id),
                    { id, values, type },
                ],
            };
            /* this is disabled for now as it is causing issues with the added platform survey... */
            /* Plus I don't think it is really necessary or desirable, if the purpose is to
            save a users progress in some fashion i think simply saving in localstorage or even context somewhere
            for now is a better idea */
            // debouncedSave(newPayload);

            return newPayload;
        });
    };

    // Overrides the normal handleChange to map -> id, value, type
    const handleChange = (e, type) => {
        handleCustomFieldsChange(e.target.name, e.target.value, type);
    };

    /**
     * Map fields onto format for CustomStep
     * @type {{ [key: string]: { value: string | string[] } }}
     */
    const optimizeState = payload.values?.reduce((acc, value) => {
        acc[value.id] = { value: value.values };
        return acc;
    }, {});

    const filterFieldTypes = field => {
        if (includeLocationAndOrganizationPercentage) {
            return (
                field.type !== CustomSignupFieldTypes.JoinGroup &&
                field.type !== CustomSignupFieldTypes.ChooseRole && // choose role is deprecated
                field.type !== CustomSignupFieldTypes.Description &&
                (!enableJuriesRequest ? field.type !== CustomSignupFieldTypes.Signature : true)
            );
        }
        // Location field are being duplicated somehow and also not acting properly
        // so am filtering them for now out of calculation ~ Timothy Baney
        return (
            field.type !== CustomSignupFieldTypes.ProfileOrganization &&
            field.type !== CustomSignupFieldTypes.ProfileLocation &&
            field.type !== CustomSignupFieldTypes.ChooseRole && // choose role is deprecated
            field.type !== CustomSignupFieldTypes.JoinGroup &&
            field.type !== CustomSignupFieldTypes.Description &&
            (!enableJuriesRequest ? field.type !== CustomSignupFieldTypes.Signature : true)
        );
    };

    const dedupedGroupSignupFields = _.uniqBy(
        groupCustomFields
            ?.filter(filterFieldTypes)
            ?.filter(field =>
                areDependentQuestionsAnswered(field, optimizeState, groupCustomFields)
            ),
        field => field.id
    );

    const numQuestionsAnswered =
        payload.values?.filter(answer =>
            _.isArray(answer.values)
                ? answer.values.length > 0 && answer.values.some(value => !!value)
                : !!answer.values
        ).length ?? 0;

    return {
        percentComplete:
            dedupedGroupSignupFields?.length === 0
                ? 100
                : (numQuestionsAnswered / (dedupedGroupSignupFields?.length || 1)) * 100,
        handleChange,
        handleCustomFieldsChange,
        optimizeState,
        saveChanges,
        groupCustomFields,
        unsavedChanges,
    };
};

export default useOptimizer;
