import React from 'react';

const TrashIcon: React.FC<{
    className?: string;
    size?: string;
    strokeWidth?: string;
    color?: string;
}> = ({ className, size = '22', color = '#131517' }) => (
    <svg
        width={size}
        height={size}
        className={className}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.5 6V6.5H16H20C20.1326 6.5 20.2598 6.55268 20.3536 6.64645C20.4473 6.74021 20.5 6.86739 20.5 7C20.5 7.13261 20.4473 7.25979 20.3536 7.35355C20.2598 7.44732 20.1326 7.5 20 7.5H19H18.5V8V19C18.5 19.663 18.2366 20.2989 17.7678 20.7678C17.2989 21.2366 16.663 21.5 16 21.5H8C7.33696 21.5 6.70107 21.2366 6.23223 20.7678C5.76339 20.2989 5.5 19.663 5.5 19V8V7.5H5H4C3.86739 7.5 3.74021 7.44732 3.64645 7.35355C3.55268 7.25979 3.5 7.13261 3.5 7C3.5 6.86739 3.55268 6.74021 3.64645 6.64645C3.74021 6.55268 3.86739 6.5 4 6.5H8H8.5V6V5C8.5 4.33696 8.76339 3.70107 9.23223 3.23223C9.70107 2.76339 10.337 2.5 11 2.5H13C13.663 2.5 14.2989 2.76339 14.7678 3.23223C15.2366 3.70107 15.5 4.33696 15.5 5V6ZM14 6.5H14.5V6V5C14.5 4.60217 14.342 4.22064 14.0607 3.93934C13.7794 3.65804 13.3978 3.5 13 3.5H11C10.6022 3.5 10.2206 3.65804 9.93934 3.93934C9.65804 4.22064 9.5 4.60217 9.5 5V6V6.5H10H14ZM7 7.5H6.5V8V19C6.5 19.3978 6.65804 19.7794 6.93934 20.0607C7.22064 20.342 7.60218 20.5 8 20.5H16C16.3978 20.5 16.7794 20.342 17.0607 20.0607C17.342 19.7794 17.5 19.3978 17.5 19V8V7.5H17H7ZM10.3536 17.3536C10.2598 17.4473 10.1326 17.5 10 17.5C9.86739 17.5 9.74021 17.4473 9.64645 17.3536C9.55268 17.2598 9.5 17.1326 9.5 17V11C9.5 10.8674 9.55268 10.7402 9.64645 10.6464C9.74022 10.5527 9.86739 10.5 10 10.5C10.1326 10.5 10.2598 10.5527 10.3536 10.6464C10.4473 10.7402 10.5 10.8674 10.5 11V17C10.5 17.1326 10.4473 17.2598 10.3536 17.3536ZM14.3536 17.3536C14.2598 17.4473 14.1326 17.5 14 17.5C13.8674 17.5 13.7402 17.4473 13.6464 17.3536C13.5527 17.2598 13.5 17.1326 13.5 17V11C13.5 10.8674 13.5527 10.7402 13.6464 10.6464C13.7402 10.5527 13.8674 10.5 14 10.5C14.1326 10.5 14.2598 10.5527 14.3536 10.6464C14.4473 10.7402 14.5 10.8674 14.5 11V17C14.5 17.1326 14.4473 17.2598 14.3536 17.3536Z"
            fill={color}
            stroke={color}
        />
    </svg>
);

export default TrashIcon;
