import { useEffect } from 'react';
import _ from 'lodash';

import signUpStore from '@stores/SignUp';

import { useCurrentUser } from '@stores/User';
import { useGroupByIdLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';
import useUserOptimizers from '@web/ui/components/optimizers/hooks/useUserOptimizers';

import { config } from '@core/config/getConfig';

const useSignupQuestions = () => {
    const { currentUser } = useCurrentUser();

    const groupsToJoin = signUpStore.useTracked.groupsToJoin();
    const [getGroupById] = useGroupByIdLazyQuery();
    const { platform, profile, updateQuery } = useUserOptimizers(
        currentUser?._id,
        undefined,
        false
    );

    const activeGroup = signUpStore.useTracked.activeGroup();
    const groupQuestions = signUpStore.useTracked.groupQuestions();
    const groupQuestionsLoading = signUpStore.useTracked.groupQuestionsLoading();

    const rootGroupId = config?.public?.rootGroupId;
    const platformGroupOwnerId = platform?.platformSettings?.platformGroupOwner;
    const networkGroupId = rootGroupId ?? platformGroupOwnerId;

    const platformForm = platform?.platformSettings?.forms?.[0];

    const getGroupQuestions = (groupId: string) => {
        return groupQuestions[groupId];
    };

    const getIsGroupLoading = (groupId: string) => {
        return groupQuestionsLoading[groupId];
    };

    const prefetchQuestionsForGroup = async (groupId: string) => {
        if (getGroupQuestions(groupId)) return;
        if (getIsGroupLoading(groupId)) return;
        signUpStore.set.groupLoading(groupId, true);

        const { data } = await getGroupById({ variables: { _id: groupId } });
        const group = data.groupById;

        if (group.subtype === 'event') {
            // Group questions should not show up on signup for events
            // The group questions should only show after the user registers for the event
            // (As requested by Commissioned)
            signUpStore.set.groupLoading(groupId, false);
            return;
        }

        const groupCustomFields = _.flatten(group?.settings?.customSignupFields);

        let questions = groupCustomFields;
        if (questions.length === 0 && groupId === networkGroupId) {
            questions = platformForm?.formSpecificationSettings?.form?.fields;
        }

        signUpStore.set.addGroupQuestions(groupId, questions, groupId === networkGroupId);
        signUpStore.set.groupLoading(groupId, false);
    };

    useEffect(() => {
        if (networkGroupId) {
            prefetchQuestionsForGroup(networkGroupId);
        }
    }, [networkGroupId]);

    useEffect(() => {
        if (activeGroup) {
            prefetchQuestionsForGroup(activeGroup._id);
        }
    }, [activeGroup?._id]);

    useEffect(() => {
        if (groupsToJoin.length > 0) {
            const newGroupId = groupsToJoin[groupsToJoin.length - 1];

            prefetchQuestionsForGroup(newGroupId);
        }
    }, [groupsToJoin]);

    return { getIsGroupLoading, getGroupQuestions, networkGroupId, profile, updateQuery };
};

export default useSignupQuestions;
