import React, { useState } from 'react';

import { Group, Maybe, User } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';
import { useTranslation } from 'react-i18next';
import useConfirmation from '@components/generic/hooks/useConfirmation';
import { useToastActionsContext } from '@components/toast/NewToastContext';
import Checkbox from '@dsc/forms/customInputs/Checkbox';
import Button from '@dsc/forms/buttons/Button';
import { useSendCreateAccountReminderEmailsMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { getRootUrl } from '@core/utilities/whitelabel_helpers';
import EventGroupGuest from './EventGroupGuest';

const logger = getLogger(module);

type EventGroupGuestsListProps = {
    group: Group;
    eventGuests?: Maybe<Array<User>>;
};

const EventGroupGuestsList: React.FC<EventGroupGuestsListProps> = ({ group, eventGuests }) => {
    const { t } = useTranslation();
    const confirm = useConfirmation();
    const { newToast } = useToastActionsContext();
    const [sendCreateAccountReminderEmailsMutation] = useSendCreateAccountReminderEmailsMutation({
        onCompleted: () => {
            newToast(<p>Email sent!</p>);
        },
        onError: error => {
            newToast(<p>{error.message}</p>);
        },
    });

    const showInvites = true;

    const localNamespace =
        group?.subtype === 'event'
            ? 'imports.wlWeb.ui.components.group.eventDashboard'
            : 'imports.wlWeb.ui.components.group.groupDashboard';

    const [selected, setSelected] = useState<string[]>([]);
    const toggleSelected = (id: string) => {
        if (selected.includes(id)) {
            setSelected(prevState => prevState.filter(s => s !== id));
        } else {
            setSelected(prevState => [...prevState, id]);
        }
    };
    const isSelected = (id: string) => selected.includes(id);
    const allSelected = eventGuests?.length === selected.length && eventGuests?.length !== 0;
    const toggleAllSelected = () => {
        if (allSelected) {
            setSelected([]);
        } else {
            setSelected(eventGuests?.map(g => g._id) || []);
        }
    };

    return (
        <>
            <p className="border-top-[1px] mx-4 mb-[20px] border-solid border-[var(--color-grayscale-line)] text-[var(--color-grayscale-label)]">
                {showInvites
                    ? `These attendees used guest checkout during registration and have not yet created accounts on Commissioned. Send these guests an email reminder to create their accounts.`
                    : `Guests have registered for your event but they do not have an account on WeLibrary.`}
            </p>
            {showInvites && eventGuests?.length !== 0 && eventGuests && (
                <div className="w-full px-4">
                    <div className="mb-2 flex w-full flex-row items-center justify-start gap-[20px]">
                        <Checkbox
                            className="!h-[28px] !w-[28px] !rounded-full"
                            value={allSelected}
                            onChange={() => toggleAllSelected()}
                        />
                        <p className="mb-0 text-[1.125rem]">Select All</p>
                    </div>
                </div>
            )}
            <ul className="w-full !border-none [&>*:nth-child(even)]:bg-gray-100">
                {eventGuests?.map(guest => (
                    <EventGroupGuest
                        key={guest._id}
                        group={group}
                        guest={guest}
                        isSelected={isSelected(guest._id)}
                        toggleSelected={() => toggleSelected(guest._id)}
                        showInvites={showInvites}
                    />
                ))}
            </ul>
            {showInvites && (
                <div className="flex w-full items-center justify-center p-[20px]">
                    <Button
                        disabled={selected.length === 0}
                        type="small"
                        className="h-full w-full rounded-[39px] bg-[var(--color-wl-primary)] !py-1 text-white"
                        onClick={() => {
                            confirm({
                                title: 'Send Create Account Reminder Emails?',
                                text: (
                                    <ul className="w-full px-[20px] py-[10px]">
                                        {selected.map(id => {
                                            const email = eventGuests?.find(g => g._id === id)
                                                ?.profile?.email;

                                            if (!email) {
                                                return null;
                                            }

                                            return (
                                                <li className="mt-[5px] w-full" key={id}>
                                                    {email}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ),
                                onConfirm: () => {
                                    sendCreateAccountReminderEmailsMutation({
                                        variables: {
                                            groupId: group._id,
                                            emails: selected
                                                .map(id => {
                                                    return eventGuests?.find(g => g._id === id)
                                                        ?.profile.email;
                                                })
                                                .filter(Boolean),
                                            host: getRootUrl(),
                                        },
                                    });
                                },
                            });
                        }}
                    >
                        Send Reminders
                    </Button>
                </div>
            )}
        </>
    );
};

export default EventGroupGuestsList;
