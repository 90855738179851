class MeteorProxy {
    static LOGIN_TOKEN_KEY = 'wlbLoginToken';

    constructor() {}

    static absoluteUrl(path, options = {}) {
        if (!options && typeof path === 'object') {
            options = path;
            path = undefined;
        }

        let url = options.rootUrl;
        let { server: isApiCall = false } = options;

        /**
         * Checks if build is from Netlify PR deployment preview. Deploy previews will always have
         * a different URL based on PR ID, so we can't store it in a configuration file.
         */
        if (window.origin.includes('deploy-preview') && !isApiCall) {
            url = window.origin;
        }
        // if (process.env.PULL_REQUEST) {
        //     const reviewID = process.env.REVIEW_ID;
        //     url = `https://deploy-preview-${reviewID}--wl-react.netlify.app/`;
        // }

        if (!url) {
            throw new Error('Must pass options.rootUrl or set ROOT_URL in the server environment');
        }

        if (!/^http[s]?:\/\//i.test(url)) {
            url = `http://${url}`;
        }

        if (!url.endsWith('/')) {
            url += '/';
        }

        if (path) {
            while (path.startsWith('/')) {
                path = path.slice(1);
            }
            url += path;
        }

        if (
            options.secure &&
            /^http:/.test(url) &&
            !/http:\/\/localhost[:\/]/.test(url) &&
            !/http:\/\/127\.0\.0\.1[:\/]/.test(url)
        ) {
            url = url.replace(/^http:/, 'https:');
        }

        if (options.replaceLocalhost) {
            url = url.replace(/^http:\/\/localhost([:\/].*)/, 'http://127.0.0.1$1');
        }

        return url;
    }

    static _storedLoginToken() {
        return localStorage.getItem(this.LOGIN_TOKEN_KEY);
    }
}

export default MeteorProxy;
