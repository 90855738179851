import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Updater } from 'use-immer';
import { z } from 'zod';

import EditScreenItemContainer from '@components/generic/editscreen/EditScreenItemContainer';

import { curriedStateSlice } from '@helpers/state/state.helpers';
import { UPDATED_EMAIL_REGEX } from '@core/utilities/constants/regexes.js';

const errNamespace = 'imports.wlWeb.ui.pages.signup.signupContainer';
const localNamespace = 'imports.wlWeb.ui.components.stripe';

export type MerchantSignupState = {
    country: string;
    email: string;
};

const ConnectedAccountStep2: React.FC<{
    totalSteps: number;
    currentStep: number;
    handleNextStep: () => void;
    handlePrevStep: () => void;
    state: MerchantSignupState;
    setState: Updater<MerchantSignupState>;
}> = ({ totalSteps, currentStep, handleNextStep, handlePrevStep, state, setState }) => {
    const { t } = useTranslation();

    const updateSlice = curriedStateSlice(setState);
    const [errors, setErrors] = useState<Record<string, string[]>>({});

    const StateValidator = z.object({
        email: z.string().regex(UPDATED_EMAIL_REGEX, `${t(`common:${errNamespace}.error.email`)}`),
    });

    const validate = () => {
        const parsedData = StateValidator.safeParse({ email: state.email });

        if (parsedData.success) {
            setErrors({});
            return true;
        }

        if (parsedData.error) {
            setErrors(parsedData.error.flatten().fieldErrors);
        }

        return false;
    };

    const handleOnClick = () => {
        if (validate()) {
            handleNextStep();
        }
    };

    return (
        <div className="edit-screen-content-view stripe-merchant-account-flow-step">
            <div className="edit-screen-content-items">
                <div className="mc-source-post-wrapper">
                    <p>
                        {t(
                            `common:${localNamespace}.fiduciary_email`,
                            'Enter the email address you want connected to your merchant account. Please note it should be connected to a fiduciary of your organization.'
                        )}
                    </p>
                    <EditScreenItemContainer title="Email">
                        <input
                            name="email"
                            placeholder={t(
                                `common:${localNamespace}.stripe_account_email`,
                                'Stripe Account Email'
                            )}
                            value={state.email}
                            maxLength={100}
                            onChange={e => updateSlice('email', e.target.value)}
                            className="connected-account-form-input"
                            required
                            type="text"
                            autoComplete="off"
                        />
                        {errors.email && <p className="stripeAccount-error">{errors.email}</p>}
                    </EditScreenItemContainer>

                    <div className="connected-account-btns-wrap">
                        {currentStep > 1 && (
                            <button onClick={handlePrevStep} type="button">
                                {t(`common:${localNamespace}.back`, 'Back')}
                            </button>
                        )}
                        {currentStep < totalSteps && (
                            <button onClick={handleOnClick} type="button">
                                {t(`common:${localNamespace}.next`, 'Next')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectedAccountStep2;
