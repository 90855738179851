import { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

import getLogger from '@core/logger';

const logger = getLogger(module);
// TODO - Make sure these events are valid for Capacitor

/**
 * Adds an event listener for app backgrounding/foregrounding change
 * See documentation https://capacitorjs.com/docs/apis/app#addlistenerappstatechange
 *
 * @param {function} refetch Refetch function call when Foregrounding the app
 * @param {function} onFinished Callback executed on resolution of Refetch
 */

const useRefetchOnResume = (refetch: Function, onFinished?: Function) => {
    const onAppStateChange = (appState: any) => {
        if (appState?.isActive) {
            setTimeout(() => refetch?.().then(onFinished && onFinished()), 0);
        }
    };

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            const appStateListener = App.addListener('appStateChange', onAppStateChange);

            return () => {
                try {
                    appStateListener?.remove();
                } catch (e) {
                    logger?.error(e);
                }
            };
        }
    }, []);
};

export default useRefetchOnResume;
