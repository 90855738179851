export const ATTACH_NONE = 'attach_none';
export const ATTACH_IMAGE = 'attach_image';
export const ATTACH_IMAGE_SQUARE = 'attach_image_square';
export const ATTACH_IMAGE_ICON = 'attach_image_icon';
export const ATTACH_IMAGE_THUMB = 'attach_image_thumb';
export const ATTACH_VIDEO = 'attach_video';
export const ATTACH_DOCUMENT = 'attach_document';
export const ATTACH_LINK = 'attach_text';
export const ATTACH_AUDIO = 'attach_audio';
export const ATTACH_EVENT = 'attach_event';
export const ATTACH_BLOG = 'attach_blog';
export const ATTACH_SHELF = 'attach_shelf';
export const ATTACH_BOOK = 'attach_book';
export const ATTACH_COURSE = 'attach_course';
export const ATTACH_RESEARCH = 'attach_research';
export const ATTACH_PLEDGE = 'attach_pledge';
export const ATTACH_MOBILIZE = 'attach_mobilize';
export const ATTACH_MOBILIZATION = 'attach_mobilization';
export const ATTACH_POSTBOX = 'attach_postbox';
export const ATTACH_VOTE_GALLERY = 'attach_votegallery';
export const ATTACH_LINK_TILE = 'attach_linktile';
export const ATTACH_HEADER = 'attach_header';
export const ATTACH_MAP = 'attach_map';
export const ATTACH_CUSTOM_FIELDS_SUMMARY = 'attach_custom_fields_summary';
export const ATTACH_STATE_OPEN = 'ATTACH_STATE_OPEN';
export const ATTACH_STATE_UPLOAD = 'ATTACH_STATE_UPLOAD';
export const ATTACH_STATE_PREVIEW = 'ATTACH_STATE_PREVIEW';
export const ATTACH_STATE_FAIL = 'ATTACH_STATE_FAIL';
export const ATTACH_META_CHANNEL = 'ATTACH_META_CHANNEL';
export const ATTACH_DYNAMIC_CARD = 'ATTACH_DYNAMIC_CARD';
export const ATTACH_STORY_CARD = 'ATTACH_STORY_CARD';

export const OPT_IN_TYPES = [
    ATTACH_LINK_TILE,
    ATTACH_STORY_CARD,
    ATTACH_DYNAMIC_CARD,
    ATTACH_VOTE_GALLERY,
    ATTACH_HEADER,
    ATTACH_MAP,
    ATTACH_PLEDGE,
];
