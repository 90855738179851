import React from 'react';

type TextWithLineBehindProps = {
    text?: string;
    className?: string;
};

const TextWithLineBehind: React.FC<TextWithLineBehindProps> = ({ text, className = '' }) => {
    return (
        <span
            className={`relative before:border-t-solid before:border-t-[1px] before:border-t-grayscale-line before:content-[""] before:my-auto before:absolute before:top-[50%] before:left-0 before:right-0 before:bottom-0 before:z-0 ${className}`}
        >
            {text && (
                <span className="mx-auto bg-white px-[10px] block text-center z-10 text-grayscale-label font-poppins text-[17px] leading-[28px] tracking-[0.75px] relative w-fit">
                    {text}
                </span>
            )}
        </span>
    );
};

export default TextWithLineBehind;
