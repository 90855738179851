import React from 'react';
import { useTranslation } from 'react-i18next';
import useSellTicketsAtDoor from '../hooks/useSellTicketsAtDoor';

import CustomerOrderDetails from './CustomerOrderDetails';
import SellTicketsFooter from './SellTicketsFooter';
import { ThickTicket } from '@dsc/svgs/Ticket';

import { Group } from '@shared/welibrary-graphql/types';

type SellTicketsConfirmOrderProps = {
    group: Group;
};

const SellTicketsConfirmOrder: React.FC<SellTicketsConfirmOrderProps> = ({ group }) => {
    const { t } = useTranslation();
    const {
        name,
        email,
        phone,
        paymentType,
        totalDisplayString,
        shoppingCartProducts,
        isProcessingOrder,
        existingAccount,
    } = useSellTicketsAtDoor(group);

    return (
        <section className="flex flex-col gap-[20px]">
            <h2 className="m-0 text-[24px] text-black">
                {t(`common:global.confirm_order`, 'Confirm Order')}
            </h2>

            <CustomerOrderDetails
                name={existingAccount?.profile.full_name || name}
                phone={existingAccount?.profile.phone || phone}
                email={existingAccount?.profile.email || email}
                orderTotal={totalDisplayString}
                paymentType={paymentType}
                orderLocation="inPerson"
            />

            <div className="flex flex-col gap-[10px]">
                {shoppingCartProducts.map((item, index) => (
                    <div
                        key={index}
                        className="shadow-around flex flex-col rounded-[10px] p-[20px]"
                    >
                        <span className="text-grayscale-title-active flex items-center gap-[5px] text-[17px] font-[700] leading-[24px] tracking-[0.75px]">
                            <ThickTicket strokeColor="currentColor" />
                            <span className="line-clamp-1">{item.product.name}</span>
                        </span>
                        <span className="text-grayscale-label text-[12px] uppercase leading-[17px] tracking-[0.25px]">
                            Quantity: {item.quantity}
                        </span>
                    </div>
                ))}
            </div>

            <SellTicketsFooter
                group={group}
                disableNext={isProcessingOrder}
                hideAmount
                nextButtonTextOverride={
                    isProcessingOrder
                        ? t('common:global.processing_dots', 'Processing...')
                        : t(`common:global.process_order`, 'Process Order')
                }
            />
        </section>
    );
};

export default SellTicketsConfirmOrder;
