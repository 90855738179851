import React, {useState} from 'react';
import ChangeAuthorModal from '@web/ui/components/generic/editscreen/ChangeAuthorModal';
import TextareaAutosize from 'react-textarea-autosize';
import { useCurrentUser } from '@stores/User';
import {getRandomDefaultUserImage} from '@core/utilities/constants/defaults';

const EditBlogHeader = ({
    title,
    author,
    handleSetTitle,
    handleChangeAuthor,
    handleFileSelect,
    imageUrl,
}) => {
    const authorName = author?.profile?.full_name;
    const authorThumb = author?.profile?.picture || getRandomDefaultUserImage();
    const [showModal, setShowModal] = useState(false);
      const { currentUser } = useCurrentUser();

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className="mc-post-wrapper">
                <div className="mc-media-item galleryitem">
                    <div className="mc-media-item-bg blog-bg">
                        <div
                            className="blog-card-header-image"
                            style={{ backgroundImage: `url(${imageUrl})` }}
                        />

                        <div className="blog-bg-edit-button">
                            <button
                                label="Upload Blog Cover Image"
                                onClick={handleFileSelect}
                                type="button"
                                className="button-reset content-button-icon edit we-profile-edit w-dropdown-toggle"
                            />
                        </div>
                    </div>
                    <div className="mc-media-item-content content-card-detail-header">
                        <div className="blog-card-summary-container">
                            {/* <div className="conent-card-detail-date">Tue Sep 15 2020</div> */}
                            <div className="conent-card-media-title">
                                <TextareaAutosize
                                    onChange={handleSetTitle}
                                    name="title"
                                    value={title}
                                    placeholder="Enter title"
                                    className="inline-text-input white"
                                />
                                {/* <InlineEditTextInput
                              text={title}
                              onSetText={text => handleSetTitle(text)}
                          /> */}
                            </div>
                        </div>

                        <div className="blog-card-author-edit">
                            <div className="blog-card-read-more-container w-inline-block pointer">
                                <div className="blog-card-author-container">
                                    <div
                                        className="thumbnail square-thumb"
                                        style={{ backgroundImage: `url(${authorThumb})` }}
                                    >
                                        <div className="square-spacer" />
                                    </div>
                                    <div>{authorName}</div>
                                </div>
                                {currentUser?.isUserSuperAdmin && (
                                    <div className="author-edit-button-container">
                                        <button
                                            label="Change Author"
                                            onClick={handleOpenModal}
                                            type="button"
                                            className="button-reset content-button-icon edit we-profile-edit author-edit-button"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChangeAuthorModal
                author={author}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                handleChangeAuthor={handleChangeAuthor}
            />
        </>
    );
};

export default EditBlogHeader;
