// src/broadcastsurvey/mutations.ts
import { gql } from "@apollo/client";
var CREATE_BROADCAST_SURVEY = gql`
  mutation CreateBroadcastSurvey($input: CreateBroadcastSurveyInput!) {
    createBroadcastSurvey(input: $input)
  }
`;
var UPDATE_BROADCAST_SURVEY = gql`
  mutation UpdateBroadcastSurvey(
    $broadcastSurveyId: ID!
    $input: UpdateBroadcastSurveyInput!
  ) {
    updateBroadcastSurvey(broadcastSurveyId: $broadcastSurveyId, input: $input)
  }
`;
var BROADCAST_SURVEY = gql`
  mutation BroadcastSurvey($input: BroadcastSurveyInput!) {
    broadcastSurvey(input: $input)
  }
`;
export {
  BROADCAST_SURVEY,
  CREATE_BROADCAST_SURVEY,
  UPDATE_BROADCAST_SURVEY
};
