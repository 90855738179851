import React, { useState } from 'react';
import _ from 'lodash';

import { useCurrentUser } from '@stores/User';

import VoteOrSkipButton from './VoteOrSkipButton';
import ContentCard from '@web/ui/components/card/ContentCard.js';
import { CARD_VOTE_GALLERY } from '@core/utilities/constants/card_types.js';
import { getRandomDefaultCoverImage } from '@core/utilities/constants/defaults';

const POLL_INTERVAL = 10000;

const VoteOrSkipController = ({
    childrenFromCard,
    fetchMore,
    isLeaderboard,
    initialRetrieveCount,
    chapterSettings,
    voteSettings,
    activeQuery,
    showReferenceHeader,
    inverseColors,
    style,
    customClass,
    promptForChapter,
    reviewAndVoteText,
    hideTopBar,
    hideTopRightDropdowns,
    forceLoadComments,
    backgroundImage,
    finishLink,
    finishedText,
    finishedSubtext,
    setSortOfChapter,
    gotoDefaultChapterSort,
    startPolling,
}) => {
    const { currentUser } = useCurrentUser();

    const [currentVote, setCurrentVote] = useState(0);
    const [noMoreToFetch, setNoMoreToFetch] = useState(false);
    const [needToAdvance, setNeedToAdvance] = useState(false);
    const [iterations, setIterations] = useState(0);

    const backPressed = () => {
        if (currentVote >= 1) setCurrentVote(oldVote => oldVote - 1);
    };

    const pollForNewItems = () => {
        startPolling?.(POLL_INTERVAL);
    };

    const fetchMoreRandomCards = () => {
        if (fetchMore && activeQuery) {
            fetchMore({
                variables: activeQuery.variables,
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (fetchMoreResult[activeQuery.dataTitle].length <= 0) {
                        setNoMoreToFetch(true);
                        pollForNewItems();
                        return prev;
                    }

                    const currentCardIds = prev[activeQuery.dataTitle].map(card => card._id);
                    const newFetchLength = fetchMoreResult[activeQuery.dataTitle].filter(
                        ({ _id }) => !currentCardIds.includes(_id)
                    ).length;

                    const updatedFeed = _.uniqBy(
                        [...prev[activeQuery.dataTitle], ...fetchMoreResult[activeQuery.dataTitle]],
                        item => item._id
                    );

                    if (newFetchLength <= 0) {
                        setNoMoreToFetch(true);
                        pollForNewItems();
                    } else {
                        setNeedToAdvance(true);
                    }

                    return { ...prev, [activeQuery.dataTitle]: updatedFeed };
                },
            });
        } else {
            setNoMoreToFetch(true);
            pollForNewItems();
        }
    };

    const skipPressed = () => {
        setNeedToAdvance(false);

        if (iterations === 2 && promptForChapter) return;

        if (currentVote < childrenFromCard?.length - 1) {
            setCurrentVote(oldVote => oldVote + 1);
            setIterations(oldIterations => oldIterations + 1);
        } else if (noMoreToFetch) {
            setCurrentVote(oldVote => oldVote + 1);
            setIterations(oldIterations => oldIterations + 1);
            pollForNewItems();
        } else {
            fetchMoreRandomCards();
        }
    };

    if (needToAdvance) skipPressed();

    const showSkip = currentVote < childrenFromCard?.length - 1;

    // !!OVERRIDE ALL SOCIAL HEADERS - DISABLE FOR NOW!!
    const _chapterSettings = { ...chapterSettings };
    _chapterSettings.displaySocialHeaders = false;

    const rows = [];

    if (childrenFromCard?.length > currentVote) {
        const card = childrenFromCard[currentVote];
        if (card.type !== CARD_VOTE_GALLERY) {
            rows.push(
                <ContentCard
                    card={card}
                    viewType="fullview"
                    voteBooth
                    hideTags
                    key={card._id}
                    currentUser={currentUser}
                    chapterSettings={_chapterSettings}
                    voteSettings={voteSettings}
                    isLeaderboard={isLeaderboard}
                    rank={card.rank}
                    activeQuery={activeQuery}
                    voteOrNextFunction={skipPressed}
                    showReferenceHeader={showReferenceHeader}
                    hideTopRightDropdown={hideTopRightDropdowns}
                    forceLoadComments={forceLoadComments}
                    voteOrSkipStyle
                />
            );
        }
    }

    const _style = { ...style };

    const bgImage = backgroundImage || getRandomDefaultCoverImage('T');
    _style.background = `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url(${bgImage}) 50% 50% / cover`;

    // If there is nothing available to vote on, show this message:
    if (childrenFromCard?.length <= 0) {
        return (
            <div className={`we-vote-or-skip-bg finished ${customClass || ''}`} style={_style}>
                <VoteOrSkipButton
                    showBack={false}
                    showSkip={false}
                    noMoreToFetch
                    skipPressed={skipPressed}
                    backPressed={backPressed}
                    fetchMore={fetchMore}
                    feedName="childrenFromCard"
                    initialRetrieveCount={initialRetrieveCount}
                    inverseColors={inverseColors}
                    reviewAndVoteText={reviewAndVoteText}
                    finishedText={finishedText}
                    finishedSubtext={finishedSubtext}
                    finishLink={finishLink}
                    setSortOfChapter={t => setSortOfChapter(t)}
                    gotoDefaultChapterSort={() => gotoDefaultChapterSort()}
                />
            </div>
        );
    }

    const voteOrSkipButton = (
        <VoteOrSkipButton
            showBack
            showSkip
            noMoreToFetch={noMoreToFetch}
            skipPressed={skipPressed}
            backPressed={backPressed}
            fetchMore={fetchMore}
            feedName="childrenFromCard"
            initialRetrieveCount={initialRetrieveCount}
            inverseColors={inverseColors}
            reviewAndVoteText={reviewAndVoteText}
            finishedText={finishedText}
            finishedSubtext={finishedSubtext}
            finishLink={finishLink}
            setSortOfChapter={t => setSortOfChapter(t)}
            gotoDefaultChapterSort={() => gotoDefaultChapterSort()}
        />
    );

    const renderRows = noMoreToFetch ? null : rows;
    return (
        <div
            className={`we-vote-or-skip-bg ${customClass || ''} ${
                noMoreToFetch && !showSkip ? 'finished' : ''
            }`}
            style={_style}
        >
            {!hideTopBar && voteOrSkipButton}
            {renderRows}
        </div>
    );
};

export default VoteOrSkipController;
