import React from 'react';
import { v4 as uuid } from 'uuid';
import hash from 'object-hash';

import { useCurrentUser } from '@stores/User';
import {
    useCreateCustomerOrderMutation,
    useFulfillCashOrderMutation,
} from '@shared/welibrary-graphql/user/mutations.hook';

import shoppingCartStore, {
    PAYMENT_TYPES,
    SELL_TICKETS_AT_DOOR_STEPS,
} from '@web/ui/stores/ShoppingCart';

import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';

import { isEventFree } from '@web/utilities/helpers/events/event.helpers';
import {
    getShoppingCartTotal,
    getDiscountedShoppingCartTotal,
} from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';
import { getCurrencyDisplayString } from '@web/utilities/helpers/money/money.helpers';

import { Group } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

export const useSellTicketsAtDoor = (group: Group) => {
    const { currentUser } = useCurrentUser();
    const stripe = useStripe();
    const stripeElements = useElements();

    const shoppingCartProducts =
        shoppingCartStore.useTracked.shoppingCartByGroup(group?._id)?.products ?? [];

    const {
        currentStep,
        name,
        email,
        phone,
        discountCode,
        paymentType,
        order,
        isProcessingOrder,
        idempotencySalt,
        idempotencyKey,
        createOrderError,
        cardToken,
        existingAccount,
    } = shoppingCartStore.useTrackedStore();

    const clientSecret = order?.paymentIntent?.client_secret ?? '';

    const isFree = isEventFree(group);
    const totalWithoutDiscount = getShoppingCartTotal(shoppingCartProducts);
    const total = getDiscountedShoppingCartTotal(shoppingCartProducts, isFree);
    const isFullyDiscounted = total === '0.00';

    const isCash = paymentType === PAYMENT_TYPES.cash;
    const isCreditCard = paymentType === PAYMENT_TYPES.creditCard;

    const currency = group.products?.[0].currency;

    const _getCurrencyDisplayString = (amount: string, convertToDollars?: boolean = false) => {
        return getCurrencyDisplayString(amount, currency, undefined, !convertToDollars, 2);
    };

    const totalDisplayString = _getCurrencyDisplayString(total);

    const [fulfillCashOrder] = useFulfillCashOrderMutation();
    const [createOrder] = useCreateCustomerOrderMutation();
    const processOrder = async () => {
        shoppingCartStore.set.isProcessingOrder(true);
        shoppingCartStore.set.createOrderError(undefined);

        const purchaseRequests = shoppingCartProducts?.map(({ product, quantity: qty, coupon }) => {
            const _purchaseRequest = {
                productId: product?._id,
                quantity: qty,
            };

            if (coupon?.code) {
                return {
                    ..._purchaseRequest,
                    couponCode: coupon?.code,
                };
            }

            return _purchaseRequest;
        });

        const customerDetails = existingAccount
            ? {
                name: existingAccount.profile.full_name,
                email: existingAccount.profile.email,
                phone: existingAccount.profile.phone,
            }
            : {
                name,
                email,
                phone: phone || undefined,
            };

        let _idempotencySalt = idempotencySalt;
        if (!_idempotencySalt) {
            _idempotencySalt = uuid();
            shoppingCartStore.set.idempotencySalt(_idempotencySalt);
        }

        const newIdempotencyKey = `${_idempotencySalt}:${hash(customerDetails)}:${hash(
            purchaseRequests
        )}`;

        if (idempotencyKey === newIdempotencyKey && !createOrderError) {
            // we've already created the order and set clientSecret, just move on
            return;
        }

        shoppingCartStore.set.idempotencyKey(newIdempotencyKey);

        const orderResponse = await createOrder({
            variables: {
                input: {
                    customerDetails,
                    purchaseRequests,
                    idempotencyKey: newIdempotencyKey,
                    paymentType,
                    orderLocation: 'inPerson',
                    processedByUserId: currentUser._id,
                    ticketsForExistingUserId: existingAccount?._id,
                },
                // allow multiple purchases across events & subscriptions
                allowDuplicatePurchases: true,
                isPurchaseForOther: true,
            },
        });

        const order = orderResponse?.data?.createOrder;
        shoppingCartStore.set.order(order);

        return order;
    };

    const handleCardCheckout = async () => {
        shoppingCartStore.set.isProcessingOrder(true);

        if (!stripe || !stripeElements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return false;
        }

        // @ts-ignore
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: {
                    token: cardToken,
                },
            },
        });

        if (payload.error) {
            return false;
        } else {
            return true;
        }
        shoppingCartStore.set.isProcessingOrder(false);
    };

    const goToNextStep = async () => {
        switch (currentStep) {
            case SELL_TICKETS_AT_DOOR_STEPS.selectTickets:
                shoppingCartStore.set.currentStep(SELL_TICKETS_AT_DOOR_STEPS.orderDetails);
                break;
            case SELL_TICKETS_AT_DOOR_STEPS.orderDetails:
                if (isFree || isFullyDiscounted) {
                    // don't call processOrder here bc it will fulfill the order if it's free
                    shoppingCartStore.set.currentStep(SELL_TICKETS_AT_DOOR_STEPS.confirmOrder);
                } else {
                    try {
                        await processOrder(); // Create the order so that we can fulfill it later (if need be)
                        shoppingCartStore.set.currentStep(
                            SELL_TICKETS_AT_DOOR_STEPS.paymentDetails
                        );
                    } catch (e) {
                        shoppingCartStore.set.createOrderError(e.message);
                    } finally {
                        shoppingCartStore.set.isProcessingOrder(false);
                    }
                }
                break;
            case SELL_TICKETS_AT_DOOR_STEPS.paymentDetails:
                if (isCreditCard) {
                    shoppingCartStore.set.isProcessingOrder(true);

                    // turn CC info into a token so we can use it when we process the payment during the next step
                    const createToken = await stripe.createToken(
                        stripeElements.getElement(CardNumberElement)
                    );

                    if (createToken.error?.message) {
                        // Problem with credit card info (e.g. incomplete)
                        shoppingCartStore.set.createOrderError(createToken.error.message);
                        shoppingCartStore.set.isProcessingOrder(false);
                        return;
                    }

                    shoppingCartStore.set.isProcessingOrder(false);

                    const token = createToken.token;
                    shoppingCartStore.set.cardToken(token.id);
                }

                shoppingCartStore.set.currentStep(SELL_TICKETS_AT_DOOR_STEPS.confirmOrder);
                break;
            case SELL_TICKETS_AT_DOOR_STEPS.confirmOrder:
                if (isFree || isFullyDiscounted) {
                    await processOrder();
                    shoppingCartStore.set.currentStep(
                        SELL_TICKETS_AT_DOOR_STEPS.viewCompletedOrder
                    );
                } else {
                    if (isCash) {
                        shoppingCartStore.set.isProcessingOrder(true);
                        try {
                            const resp = await fulfillCashOrder({
                                variables: {
                                    orderId: order._id,
                                },
                            });

                            shoppingCartStore.set.order(resp?.data?.fulfillCashOrder);
                            shoppingCartStore.set.currentStep(
                                SELL_TICKETS_AT_DOOR_STEPS.viewCompletedOrder
                            );
                        } finally {
                            shoppingCartStore.set.isProcessingOrder(false);
                        }
                    } else if (isCreditCard) {
                        try {
                            const cardCheckoutSuccess = await handleCardCheckout();

                            if (cardCheckoutSuccess) {
                                shoppingCartStore.set.currentStep(
                                    SELL_TICKETS_AT_DOOR_STEPS.viewCompletedOrder
                                );
                            }

                            shoppingCartStore.set.isProcessingOrder(false);
                        } catch (e) {
                            console.log('🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 card failed');
                            console.log('e.message:', e.message);
                            shoppingCartStore.set.isProcessingOrder(false);
                        }
                    }
                }
                break;
            default:
                console.log('🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥');
                console.log('UNHANDLED NEXT STEP', currentStep);
        }
    };
    const goBack = () => {
        switch (currentStep) {
            case SELL_TICKETS_AT_DOOR_STEPS.selectTickets:
                // First step, shouldn't be possible to goBack
                break;
            case SELL_TICKETS_AT_DOOR_STEPS.orderDetails:
                shoppingCartStore.set.currentStep(SELL_TICKETS_AT_DOOR_STEPS.selectTickets);
                break;
            case SELL_TICKETS_AT_DOOR_STEPS.paymentDetails:
                shoppingCartStore.set.createOrderError(undefined);
                shoppingCartStore.set.currentStep(SELL_TICKETS_AT_DOOR_STEPS.orderDetails);
                break;
            case SELL_TICKETS_AT_DOOR_STEPS.confirmOrder:
                if (isFree) {
                    shoppingCartStore.set.currentStep(SELL_TICKETS_AT_DOOR_STEPS.orderDetails);
                } else {
                    shoppingCartStore.set.currentStep(SELL_TICKETS_AT_DOOR_STEPS.paymentDetails);
                }
                break;
        }
    };

    return {
        shoppingCartProducts,
        isEventFree: isFree,
        total,
        totalDisplayString,
        totalWithoutDiscount,
        currency,
        name,
        email,
        phone,
        discountCode,
        paymentType,
        isProcessingOrder,
        currentStep,
        goToNextStep,
        goBack,
        getCurrencyDisplayString: _getCurrencyDisplayString,
        order,
        createOrderError,
        existingAccount,
    };
};

export default useSellTicketsAtDoor;
