/* eslint-disable import/prefer-default-export */
import { Group, User, ContentCard } from '@shared/welibrary-graphql/types';

export const isUserGroupAdmin = (
    user: Partial<User>,
    group?: Partial<Group>,
    card?: Partial<ContentCard>
) => {
    if (!user) {
        return;
    }

    if (user?.isUserSuperAdmin) return true;

    if (group) {
        return group?.currentUserIsAdmin || group?.currentUserIsDirectAdmin;
    }

    if (card) {
        const groupSource = card?.reference?.groupSource;
        return groupSource?.currentUserIsAdmin || groupSource?.currentUserIsDirectAdmin;
    }
};
