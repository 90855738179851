import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getQueryVariable } from '@core/utilities/utility_functions';
import { useFlags } from 'launchdarkly-react-client-sdk';

import signUpStore from '@stores/SignUp';
import useSignupQuestions from '@web/utilities/hooks/signup/useSignupQuestions';

import CreateProfile from './CreateProfile';
import PostSignupLayout from './PostSignupLayout';
import NetworkQuestions from './NetworkQuestions';
import GroupQuestions from './GroupQuestions';
// import SelectInterests from './SelectInterests';
import JoinMoreGroups from './JoinMoreGroups';

export const POST_SIGNUP_STEPS = {
    createProfile: 'create profile',
    joinMoreGroups: 'join more groups',
    networkQuestions: 'network questions',
    groupQuestions: 'group questions',
} as const;
export type PostSignupStep = (typeof POST_SIGNUP_STEPS)[keyof typeof POST_SIGNUP_STEPS];

const PostSignup: React.FC = () => {
    const history = useHistory();
    const currentStep = signUpStore.useTracked.postSignupStep();
    const flags = useFlags();
    const connectAppSignupChanges = flags?.connectAppSignupChanges;

    useSignupQuestions(); // to prefetch network/group questions

    const activeGroup = signUpStore.useTracked.activeGroup();
    const location = useLocation();
    const returnUrl = getQueryVariable('returnTo', location.search);
    const isSubscriptionGroup = activeGroup?.subtype !== 'event' && activeGroup?.doesSellMembership;
    const isApprovalRequired = activeGroup?.settings?.shortCodeApprovalRequired ?? false;

    let redirectUrl = '/home';
    if (returnUrl) {
        redirectUrl = returnUrl;
    } else if (activeGroup) {
        redirectUrl = `/g/${activeGroup._id}`;
    }

    useEffect(() => {
        if (connectAppSignupChanges && currentStep === POST_SIGNUP_STEPS.joinMoreGroups) {
            signUpStore.set.postSignupStep(POST_SIGNUP_STEPS.groupQuestions);
        }
    }, [currentStep, connectAppSignupChanges]);

    const goToNextStep = () => {
        switch (currentStep) {
            case POST_SIGNUP_STEPS.createProfile:
                signUpStore.set.postSignupStep(POST_SIGNUP_STEPS.networkQuestions);
                break;
            case POST_SIGNUP_STEPS.networkQuestions:
                signUpStore.set.postSignupStep(POST_SIGNUP_STEPS.joinMoreGroups);
                break;
            case POST_SIGNUP_STEPS.joinMoreGroups:
                signUpStore.set.postSignupStep(POST_SIGNUP_STEPS.groupQuestions);
                break;
            case POST_SIGNUP_STEPS.groupQuestions:
                history.push(redirectUrl);
                break;
            default:
                break;
        }
    };
    
    const goToPreviousStep = () => {
        switch (currentStep) {
            case POST_SIGNUP_STEPS.createProfile:
                break;
            case POST_SIGNUP_STEPS.networkQuestions:
                signUpStore.set.postSignupStep(POST_SIGNUP_STEPS.createProfile);
                break;
            case POST_SIGNUP_STEPS.joinMoreGroups:
                if (signUpStore.get.networkQuestionsSkipped()) {
                    // if we already skipped network questions, don't try to go back there. Go back twice
                    signUpStore.set.postSignupStep(POST_SIGNUP_STEPS.createProfile);
                } else {
                    signUpStore.set.postSignupStep(POST_SIGNUP_STEPS.networkQuestions);
                }
                break;
            case POST_SIGNUP_STEPS.groupQuestions:
                signUpStore.set.postSignupStep(POST_SIGNUP_STEPS.joinMoreGroups);
                break;
            default:
                break;
        }
    };

    const steps: Record<PostSignupStep, React.ReactNode> = {
        [POST_SIGNUP_STEPS.createProfile]: (
            <CreateProfile goToNextStep={goToNextStep} redirectUrl={redirectUrl} />
        ),
        [POST_SIGNUP_STEPS.networkQuestions]: (
            <NetworkQuestions
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                redirectUrl={redirectUrl}
            />
        ),
        [POST_SIGNUP_STEPS.groupQuestions]: (
            <GroupQuestions
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                redirectUrl={redirectUrl}
            />
        ),
        // [POST_SIGNUP_STEPS.selectInterests]: (
        //     <SelectInterests
        //         goToNextStep={goToNextStep}
        //         goToPreviousStep={goToPreviousStep}
        //         redirectUrl={redirectUrl}
        //     />
        // ),
    };

    if (!connectAppSignupChanges) {
        steps[POST_SIGNUP_STEPS.joinMoreGroups] = (
            <JoinMoreGroups
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                redirectUrl={redirectUrl}
            />
        );
    };

    return <PostSignupLayout>{steps[currentStep]}</PostSignupLayout>;
};

export default PostSignup;
