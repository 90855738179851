import React from 'react';

import ResourceIcon from '@components/card/coursecard/ResourceIcon';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import { ContentCard } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

const MiniCourseLevelView: React.FC<{ card?: Maybify<ContentCard> | null; selectedIndex: number }> =
    ({ card, selectedIndex }) => {
        const courseTitle = card?.title;
        const ageRange = `AGE ${card?.courseItems?.ageGroup?.join('-')}`;
        const duration = `${card?.courseItems?.duration?.join('-')} MIN`;
        const playersCount = `${card?.courseItems?.people} PLAYERS`;
        const selectedCourseLevel = card?.courseItems?.levels?.[selectedIndex];
        const levelThumbUrl = selectedCourseLevel?.media;
        const fallbackImg = card?.media?.thumb || card?.media?.icon;

        const [src, srcSet] = getBackgroundImageSetForUrl(
            levelThumbUrl || fallbackImg || getRandomDefaultCoverImage()
        );

        if (!selectedCourseLevel) return <></>;

        return (
            <div className="mini-course-container">
                <div className="thumb-display">
                    <StyledBackgroundImage
                        className="course-mini-bg"
                        src={src as string}
                        srcSet={srcSet as string}
                    />
                </div>

                <div className="course-info">
                    <div className="resource-display">
                        {selectedCourseLevel?.resource && (
                            <ResourceIcon
                                key={selectedCourseLevel?.title}
                                type={selectedCourseLevel.resource}
                                quantity={3}
                                checked={false}
                            />
                        )}
                    </div>
                    <h3>{courseTitle}</h3>
                    <p>{duration}</p>
                    <p>{playersCount}</p>
                </div>
            </div>
        );
    };

export default MiniCourseLevelView;
