import React from 'react';
import _ from 'lodash';

import Ticket from '@dsc/svgs/Ticket';
import IncrementStepper from '@web/ui/dsc/forms/customInputs/IncrementStepper';
import shoppingCartStore from '@web/ui/stores/ShoppingCart';
import X from '@web/ui/dsc/svgs/FullSizeX';

import {
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import { DiscountCodeState, getTotal } from '@components/card/Shopping-Cart/CheckoutHelpers';
import { getCurrencyDisplayString } from '@web/utilities/helpers/money/money.helpers';

import { Product, Group } from '@shared/welibrary-graphql/types';

const ShoppingCartSelectorItem: React.FC<{
    cartItem: Product;
    qty?: undefined | number;
    group: Group;
    allowEdit?: boolean;
    className?: string;
    showMinified?: boolean;
    showPriceQuantityTotal?: boolean;
}> = ({ cartItem, qty, group, allowEdit = false, className = '', showMinified = false }) => {
    const [coverSrc, coverSrcSet] = getBackgroundImageSetForUrl(
        group?.profile?.coverPhoto || getRandomDefaultCoverImage(group?.profile?.full_name ?? '')
    );

    const shoppingCartProducts =
        shoppingCartStore?.useTracked?.shoppingCarts()?.find(({ groupId }) => {
            return groupId === group?._id;
        })?.products ?? [];

    const shoppingCartProduct = _.find(
        shoppingCartProducts,
        ({ product: p }) => p?._id === cartItem?._id
    );

    const quantity = qty ?? shoppingCartProduct?.quantity ?? 0;
    const coupon: DiscountCodeState | {} = shoppingCartProduct?.coupon ?? {};
    const couponPercentage = coupon?.percentage * 100 ?? 0;
    const currency = cartItem?.currency ?? 'USD';

    // original price
    const price = getTotal(cartItem, 1);
    const priceDisplayString = getCurrencyDisplayString(price, currency, undefined, true, 2);

    // discounted price
    const discountedPrice = getTotal(shoppingCartProduct?.product, 1, coupon?.percentage);
    const discountPriceDisplayString = getCurrencyDisplayString(
        discountedPrice,
        currency,
        undefined,
        true,
        2
    );

    // product total
    let total = getTotal(cartItem, quantity);
    if (coupon?.isApplied) {
        total = getTotal(
            shoppingCartProduct?.product,
            shoppingCartProduct?.quantity,
            coupon?.percentage,
            shoppingCartProduct?.product?.currency
        );
    }
    const totalDisplayString = getCurrencyDisplayString(total, currency, undefined, true, 2);

    // if (showPriceQuantityTotal) price = getTotal(cartItem, quantity);

    const itemContainerStyles = showMinified ? '!h-auto' : '!h-[120px]';
    const itemNameStyles = showMinified ? '!mb-2' : '';
    const thumbContainerStyles = showMinified ? '!flex-none !h-[60px] !w-[60px] ml-[5px]' : '';
    const iconContainerStyles = showMinified ? '!h-[30px] !w-[30px]' : '';
    const iconStyles = showMinified ? '!h-[25px] !w-[25px]' : '';

    return (
        <>
            <div className={`checkout-item-wrap ${className}`}>
                <div className={`checkout-item mb-4 ${itemContainerStyles}`}>
                    <div className={`checkout-item-cover-wrap ${thumbContainerStyles}`}>
                        <StyledBackgroundImage
                            src={coverSrc}
                            srcSet={coverSrcSet}
                            className="checkout-item-cover"
                        />
                        <div className={`checkout-item-icon-wrap ${iconContainerStyles}`}>
                            <Ticket
                                className={`checkout-item-icon ${iconStyles}`}
                                strokeColor="#fff"
                            />
                        </div>
                    </div>

                    <div className="checkout-item-details-wrap">
                        <div className="checkout-item-inner-details-wrap !h-full">
                            <div className="flex flex-col justify-between items-start h-full pb-[4px]">
                                <div>
                                    <h5 className={`m-0 p-0 pt-4 line-clamp-1 ${itemNameStyles}`}>
                                        {cartItem?.name}
                                    </h5>

                                    {!showMinified && (
                                        <p className="line-clamp-1">{group?.profile?.full_name}</p>
                                    )}
                                </div>
                                {allowEdit ? (
                                    <IncrementStepper
                                        className="checkout-increment-stepper"
                                        placeholder="qty"
                                        value={quantity}
                                        onChange={value => {
                                            shoppingCartStore?.set?.setShoppingCartItem(
                                                group?._id,
                                                cartItem,
                                                value
                                            );
                                        }}
                                        min={0}
                                    />
                                ) : (
                                    <p className="checkout-item-inner-details-qty">
                                        Quantity: {quantity}
                                    </p>
                                )}
                            </div>

                            <div className="checkout-item-inner-details-total flex flex-col items-end justify-end h-full">
                                <p
                                    className={`!text-[9px] !text-right text-gray-500 p-0 m-0 ${
                                        coupon?.isApplied ? 'line-through' : ''
                                    }`}
                                >
                                    {priceDisplayString}/ticket
                                </p>
                                {coupon?.isApplied && (
                                    <p
                                        className={`!text-[9px] !text-right p-0 m-0 ${
                                            coupon?.isApplied ? 'text-red-500' : ''
                                        }`}
                                    >
                                        {discountPriceDisplayString}/ticket
                                    </p>
                                )}
                                <span className="new-total !text-lg !text-right">
                                    {totalDisplayString}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {coupon?.isApplied && (
                <div className="checkout-item-wrap flex items-center justify-end w-full mt-[-10px] mb-[10px] !pl-[0] text-right">
                    <div className="rounded-full flex items-center justify-center bg-[#EFF0F6] px-[16px] py-[2px]">
                        <Ticket className="w-[15px] mr-1" strokeColor="#6b7280" />

                        <p className="p-0 m-0 text-gray-500 text-sm font-semibold mr-2">
                            {coupon?.code}{' '}
                            <span className="text-red-500 text-[11px] font-normal">
                                ({couponPercentage}% OFF)
                            </span>
                        </p>

                        {allowEdit && (
                            <button
                                onClick={() =>
                                    shoppingCartStore?.set?.setProductCoupon(
                                        group?._id,
                                        cartItem?._id,
                                        {}
                                    )
                                }
                            >
                                <X className="w-[10px]" strokeWidth="5" />
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ShoppingCartSelectorItem;
