import { createStore } from '@udecode/zustood';

import { sample } from '@helpers/array.helpers';

import { QuestDialogType } from '@core/types/Quests';
import { PerformantMaybify } from '@core/types/Utilities';
import { DialogEvent } from '@shared/welibrary-graphql/types';

type DialogStore = {
    isDialogRead: boolean;
    dialogs: QuestDialogType[];
    dialog: QuestDialogType | null;
    dialogEvent: PerformantMaybify<DialogEvent> | null | undefined;
};

const dialogStore = createStore('dialog')<DialogStore>({
    isDialogRead: false,
    dialogs: [],
    dialog: null,
    dialogEvent: undefined,
})
    .extendActions((set, get) => ({ randomDialog: () => set.dialog(sample(get.dialogs())) }))
    .extendActions(set => ({
        dialogRead: () => set.isDialogRead(true),
        dialogUnread: () => {
            set.isDialogRead(false);
            set.randomDialog();
        },
    }));

export default dialogStore;
