import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import _ from 'lodash';

import AlertToast from '@components/toast/AlertToast';
import Toggle from '@dsc/forms/customInputs/Toggle';
import Ticket from '@dsc/svgs/Ticket';
import {
    TicketSharingUserSelected,
    TicketSharingEmailSelected,
} from '@components/card/Shopping-Cart/TicketSharing/TicketSharingUserSelected';
import {
    TicketSharingFormUserInput,
    TicketSharingFormEmailInput,
} from '@components/card/Shopping-Cart/TicketSharing/TicketSharingFormInputs';
import ChainLink from '@dsc/svgs/ChainLink';

import usePeopleSearchQuery from '@components/generic/hooks/usePeopleSearchQuery';
import useDebouncedState from '@components/generic/hooks/useDebouncedState';
import { useToastActionsContext } from '@components/toast/NewToastContext';
import { useShareLink } from '@components/generic/hooks/useShareLink';

import {
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';
import { getRootUrl } from '@core/utilities/whitelabel_helpers';

import { Product, User, CustomerOrder } from '@shared/welibrary-graphql/types';
import { useCancelTicketTransferMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { defaultAlertToastOptions } from '@core/types/Toast';

type TicketSharingState = {
    ticket: Product;
    ticketId: string;
    transferToken: string;
    transferRecipients: { user?: User; email?: string }[];
    email: string | null;
    user: User | null;
    purchaser: User | null;
    owner: User | null;
    order: CustomerOrder;
};

const ADD_USER = 'add_user';
const ADD_EMAIL = 'add_email';

type TicketSharingFormProps = {
    index: number;
    ticketSharingState: TicketSharingState;
    handleAddAttendee: (ticketId: string, user?: User | null, email?: string) => void;
    handleRemoveAttendee: (ticketId: string) => void;
    handleUpdateTicketProductInstance: (
        ticketId: string,
        updatePayload?: TicketSharingState
    ) => void;
    ticketsAvailable: number;
    transferError: any;
}

const TicketSharingForm: React.FC<TicketSharingFormProps> = ({
    index,
    ticketSharingState,
    handleAddAttendee,
    handleRemoveAttendee,
    handleUpdateTicketProductInstance,
    ticketsAvailable,
    transferError,
}) => {
    const { newToast } = useToastActionsContext();
    const { shareLink } = useShareLink();

    const [activeTab, setActiveTab] = useState<string>(ADD_USER);

    const [searchQuery, actualSearchQuery, setSearchQuery] = useDebouncedState('');
    const { foundUsers, searchNetworkLoading } = usePeopleSearchQuery(actualSearchQuery);

    const [cancelTicketTransfer, { loading: cancelTransferLoading }] =
        useCancelTicketTransferMutation();

    const {
        ticket,
        ticketId,
        user,
        email,
        transferRecipients,
        transferToken,
        owner,
        purchaser,
        order,
    } = ticketSharingState;

    const [coverSrc, coverSrcSet] = getBackgroundImageSetForUrl(
        ticket?.reference?.profile?.coverPhoto ||
        getRandomDefaultCoverImage(ticket?.reference?.profile?.full_name ?? '')
    );

    const emptyResults = !searchNetworkLoading && _.isEmpty(foundUsers) && actualSearchQuery !== '';

    const handleCloseDropdown = () => setSearchQuery('');

    const claimLink = `https://${getRootUrl()}/claim-ticket/${ticketSharingState?.ticketId}/${ticketSharingState?.transferToken}`;

    const isError = transferError?.status === 'rejected';
    const hasTransferRecipients = transferRecipients?.length > 0;
    const ticketHasBeenClaimed =
        transferRecipients?.length === 0 && !transferToken && owner?._id !== purchaser?._id;
    const ticketTransferPending =
        transferRecipients?.length > 0 && transferToken && owner?._id === purchaser?._id;

    const handleCancelTransfer = (_ticketId: string, showToast: boolean = false) => {
        if (!ticketHasBeenClaimed) {
            cancelTicketTransfer({
                variables: {
                    productInstanceId: _ticketId,
                },
            }).then(({ data }) => {
                handleUpdateTicketProductInstance(_ticketId, {
                    transferToken: data?.cancelTicketTransfer?.transferToken,
                    transferRecipients: [],
                });

                if (showToast) {
                    newToast(<AlertToast boldText="Claim Link Generated!" showSuccessIcon />, {
                        ...defaultAlertToastOptions,
                    });
                }
            });
        }
    };

    let claimStatus = null;
    if (ticketTransferPending) {
        claimStatus = (
            <p className="!max-w-[100px] rounded-full bg-[#F4B740] px-[10px] py-[4px] text-center font-semibold text-white">
                Pending
            </p>
        );
    } else if (ticketHasBeenClaimed) {
        claimStatus = (
            <p className="!max-w-[100px] rounded-full bg-[#00D1A9] px-[10px] py-[4px] text-center font-semibold text-white">
                Claimed
            </p>
        );
    }

    return (
        <OutsideClickHandler onOutsideClick={handleCloseDropdown}>
            <li
                key={ticketId}
                className={`ticket-sharing-ticket-item ${isError ? 'ticket-sharing-ticket-item-error' : ''
                    }`}
            >
                {isError && (
                    <p className="ticket-sharing-ticket-error-msg">{transferError.reason}</p>
                )}

                <div className="ticket-sharing-ticket-item-header">
                    {ticketsAvailable > 0 && (
                        <div className="ticket-sharing-item-counter">
                            {index + 1} of {ticketsAvailable}
                        </div>
                    )}
                    <div className="ticket-sharing-ticket-img-wrap">
                        <div className="ticket-sharing-ticket-notch" />
                        <StyledBackgroundImage src={coverSrc} srcSet={coverSrcSet} />
                    </div>
                    <div className="ticket-sharing-ticket-details">
                        {claimStatus}
                        <div className="ticket-sharing-ticket-details-title-wrap">
                            <Ticket strokeColor="#90949C" />
                            <h3>{ticket?.name}</h3>
                        </div>
                        <div className="ticket-sharing-ticket-name-wrap">
                            <p>{ticket?.reference?.profile?.full_name}</p>
                        </div>
                        <div className="ticket-sharing-ticket-id-wrap">
                            <p>ticket#{ticketId}</p>
                        </div>
                    </div>
                </div>
                <div className="ticket-sharing-divider" />

                {!hasTransferRecipients && !ticketHasBeenClaimed && (
                    <>
                        {!user && !email && (
                            <div className="ticket-sharing-tabs-wrap">
                                <div className="ticket-sharing-tabs">
                                    Send To:{' '}
                                    <button
                                        type="button"
                                        className={`${activeTab === ADD_USER
                                            ? 'ticket-sharing-tabs-btn-active'
                                            : ''
                                            }`}
                                        onClick={() => setActiveTab(ADD_USER)}
                                    >
                                        User
                                    </button>{' '}
                                    <button
                                        type="button"
                                        className={`${activeTab === ADD_EMAIL
                                            ? 'ticket-sharing-tabs-btn-active'
                                            : ''
                                            }`}
                                        onClick={() => setActiveTab(ADD_EMAIL)}
                                    >
                                        Email
                                    </button>
                                </div>
                                <button
                                    onClick={() => {
                                        shareLink(claimLink);
                                    }}
                                    type="button"
                                    className="ticket-sharing-tabs-btn-copy"
                                >
                                    Copy Claim Link
                                    <ChainLink
                                        strokeColor="#ffff"
                                        className="ticket-sharing-tabs-btn-copy-icon"
                                    />
                                </button>{' '}
                            </div>
                        )}

                        {activeTab === ADD_USER && !user && (
                            <TicketSharingFormUserInput
                                ticketId={ticketId}
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                                foundUsers={foundUsers}
                                emptyResults={emptyResults}
                                handleAddAttendee={handleAddAttendee}
                            />
                        )}
                        {user && (
                            <TicketSharingUserSelected
                                user={user}
                                ticketId={ticketId}
                                handleRemoveAttendee={handleRemoveAttendee}
                            />
                        )}

                        {activeTab === ADD_EMAIL && !email && (
                            <TicketSharingFormEmailInput
                                ticketId={ticketId}
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                                handleAddAttendee={handleAddAttendee}
                            />
                        )}
                        {email && (
                            <TicketSharingEmailSelected
                                email={email}
                                ticketId={ticketId}
                                handleRemoveAttendee={handleRemoveAttendee}
                            />
                        )}

                        {!user && !email && (
                            <div className="mt-4 flex w-full flex-col items-start justify-center">
                                <p className="m-0 p-0 text-left">
                                    Need to create a new link? By regenerating the claim link, the
                                    previous link will be invalid.
                                </p>
                                <button
                                    type="button"
                                    className="mt-4 rounded-full bg-gray-300 px-[16px] py-[4px] text-sm font-semibold text-black"
                                    onClick={() => handleCancelTransfer(ticketId, true)}
                                >
                                    {cancelTransferLoading ? 'Loading...' : 'Regenerate Claim Link'}
                                </button>
                            </div>
                        )}
                    </>
                )}

                {hasTransferRecipients &&
                    transferRecipients?.map(({ email: _email, user: _user }) => {
                        if (_email) {
                            return (
                                <TicketSharingEmailSelected
                                    email={_email}
                                    ticketId={ticketId}
                                    handleRemoveAttendee={handleRemoveAttendee}
                                    showCancelTransferButton
                                    showRemoveAttendeeButton={false}
                                    handleCancelTransfer={handleCancelTransfer}
                                    className="!mb-2"
                                    isLoading={cancelTransferLoading}
                                    key={_email}
                                />
                            );
                        }

                        return (
                            <TicketSharingUserSelected
                                user={_user}
                                ticketId={ticketId}
                                handleRemoveAttendee={handleRemoveAttendee}
                                showCancelTransferButton
                                showRemoveAttendeeButton={false}
                                handleCancelTransfer={handleCancelTransfer}
                                className="!mb-2"
                                isLoading={cancelTransferLoading}
                                key={user?._id}
                            />
                        );
                    })}

                {ticketHasBeenClaimed && (
                    <TicketSharingUserSelected
                        user={owner}
                        ticketId={ticketId}
                        handleRemoveAttendee={handleRemoveAttendee}
                        showCancelTransferButton={false}
                        showRemoveAttendeeButton={false}
                        handleCancelTransfer={handleCancelTransfer}
                        className="!mb-2"
                        key={user?._id}
                    />
                )}
            </li>
        </OutsideClickHandler>
    );
};

export default TicketSharingForm;
