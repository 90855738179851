import React from 'react';
import RichMarkdownEditor from '@welibraryos/welibrary-editor';
import YoutubeEmbed from '../embeds/YoutubeEmbed';
import TwitterEmbed from '../embeds/TwitterEmbed';
import useMentionsSelect from '@web/ui/components/mentions/useMentions';
import { client } from '@web/ui/components/generic/ReactFileStackClient';
import { config } from '@core/config/getConfig';

import getLogger from '@core/logger';
import { mediaRegex } from '~/wl-core/utilities/constants/regexes';

const logger = getLogger(module);

const fileStackConfig = config.public.filestack;
const filestack = client.init(fileStackConfig.api_key, { sessionCache: false });

type FullEditorProps = {
    onChange: (value: () => string) => void;
    placeholder: string;
    className?: string;
    defaultValue?: string; // used for initializing editor with initial value
    editorKey?: number; // used for clearing editor
    readOnly?: boolean;
    mentionsUsers?: any;
    mentionsGroupId?: string; //used for filtering out mentions to a specific group
};

/* This is a version of the editor that includes the full menu, mentions, image uploading, and soon to be added embeds and slash commands */
const FullEditor: React.FC<FullEditorProps> = ({
    className,
    onChange,
    placeholder,
    defaultValue,
    editorKey,
    readOnly,
    mentionsUsers,
    mentionsGroupId,
}) => {
    const { setSearchString, searchResults } = useMentionsSelect(mentionsUsers, mentionsGroupId);
    return (
        <RichMarkdownEditor
            readOnly={readOnly}
            className={className}
            onChange={onChange}
            defaultValue={defaultValue}
            key={editorKey}
            id="richmarkdown-editor"
            placeholder={placeholder}
            participants={searchResults}
            noMobileMention
            onMentionInput={setSearchString}
            autoFocus
            menuType="full"
            uploadImage={file => {
                return filestack
                    .upload(file)
                    .then(res => {
                        return res.url;
                    })
                    .catch(e => {
                        logger.error(e);
                    });
            }}
            embeds={[
                {
                    title: 'YouTube',
                    keywords: 'youtube video tube google',
                    icon: () => (
                        <img
                            alt="Youtube embed"
                            src="https://upload.wikimedia.org/wikipedia/commons/7/75/YouTube_social_white_squircle_%282017%29.svg"
                            width={24}
                            height={24}
                        />
                    ),
                    matcher: url => {
                        return url.match(
                            /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([a-zA-Z0-9_-]{11})$/i
                        );
                    },
                    component: YoutubeEmbed,
                },
                {
                    title: 'Twitter',
                    keywords: 'twitter tweet',
                    icon: () => (
                        <img
                            alt="Twitter embed"
                            src="https://upload.wikimedia.org/wikipedia/commons/7/75/YouTube_social_white_squircle_%282017%29.svg"
                            width={24}
                            height={24}
                        />
                    ),
                    matcher: url => {
                        return url.match(mediaRegex.tweet);
                    },
                    component: TwitterEmbed,
                },
            ]}
        />
    );
};

export default FullEditor;
