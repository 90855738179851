import React from 'react';
import DragAndDrop from '@web/ui/components/generic/DragAndDrop.js';
import { client } from '@web/ui/components/generic/ReactFileStackClient';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';

import { config } from '@core/config/getConfig';

let fileStackConfig = config.public.filestack;

/** FileUploadHelper
*   This component uses the render prop pattern to wrap any component with file uploading functionality.
*   Key props: {
        handleUpdateState - function that passes { [target]: <fileUrl> } on file upload
        target - target for updateState
        render - render prop, forwards { uploadProgress, isUploading, handleFileSelect }
          handleFileSelect - function that opens file select menu
      }

    TODO: Add type prop - restrict types of files that can be uploaded. See Filestack API.
    TODO: Add multiple prop - allow uploading multiple files.
**/

export default class FileUploadHelper extends React.Component {
    constructor(props) {
        super(props);
        this.filestack = client.init(fileStackConfig.api_key, {
            security: null,
            cname: null,
            sessionCache: false,
        });
        this.files = [];

        this.state = {
            uploadProgress: 0,
            isUploading: false,
        };
    }

    componentDidMount() {
        //create an input to use for filedialogue
        this.fileSelector = this.buildFileSelector();
    }

    buildFileSelector = () => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        //uncomment this or add some logic if you want to select multiple files
        // fileSelector.setAttribute('multiple', 'multiple');

        //what to do after you upload the file
        fileSelector.onchange = this.handleFileUpload;

        let mimeType = this.props.mimeType ? this.props.mimeType : 'image/*';

        //define accepted file types
        fileSelector.setAttribute('accept', mimeType);
        return fileSelector;
    };

    // This function triggers the open file dialogue box by programatically clicking the input element
    handleFileSelect = e => {
        e.preventDefault();
        this.fileSelector.click();
    };

    /** Function called after uploading file through file dialogue
     *   this probably can be combined with the handledrop but
     *   would have to modify the Drag and Drop component a bit
     *   this works fine for now
     **/
    handleFileUpload = event => {
        let file = event.target.files[0];
        this.handleUploadPromise(file)
            .then(res => this.handleUpdateState({ [this.props.target]: res.url }))
            .catch(err => this.setState({ error: err, isUploading: false, uploadProgress: 0 }));
    };

    // This handles the Drag and Drop functionality currently setup to handle multiple files, although input is restricted to one
    handleDrop = files => {
        let fileList = this.files;

        for (let i = 0; i < files.length; i++) {
            if (!files[i].name) return;
            fileList.push(files[i].name);
        }
        this.setState({ files: fileList });
        this.handleUploadPromise(files[0])
            .then(res => this.handleUpdateState({ [this.props.target]: res.url }))
            .catch(err => this.setState({ error: err, isUploading: false, uploadProgress: 0 }));
    };

    handleProgress = e => {
        let progress = e.totalPercent;
        if (progress < 100) {
            this.setState({ uploadProgress: e.totalPercent, isUploading: true });
        } else {
            this.setState({ uploadProgress: e.totalPercent, isUploading: false });
        }
    };

    // This uses the clients upload method to upload the file, returns a promise
    handleUploadPromise = file => {
        this.setState({ isUploading: true, error: null });
        const options = { onProgress: this.handleProgress };
        return this.filestack.upload(file, options);
    };

    handleUpdateState = property => {
        this.props.handleUpdateState(property);
    };

    render() {
        let { render } = this.props;

        return (
            <React.Fragment>
                <LoadingLine isLoading={this.state.isUploading} error={this.state.error} />
                <DragAndDrop handleDrop={this.handleDrop}>
                    {render({
                        uploadProgress: this.state.uploadProgress,
                        isUploading: this.state.isUploading,
                        handleFileSelect: this.handleFileSelect,
                    })}
                </DragAndDrop>
            </React.Fragment>
        );
    }
}
