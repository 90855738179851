import React, { useState, useRef } from 'react';
import { useImmer } from 'use-immer';
import { v4 as uuid } from 'uuid';

import { useCurrentUser } from '@stores/User';
import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';
import { getNavFunctions } from '@helpers/navigation/horizontalNav.helpers';

import useModal from '@components/modals/hooks/useModal';

import HorizontalNavHeader from '@dsc/navigation/HorizontalNavHeader';
import OrderDetails from '@web/ui/components/card/Shopping-Cart/OrderDetails';
import Payment from '@web/ui/components/card/Shopping-Cart/Payment';

import { useValidateCouponLazyQuery } from '@shared/welibrary-graphql/user/queries.hook';

import { Product, Group } from '@shared/welibrary-graphql/types';
import {
    UserDetailsState,
    DiscountCodeState,
    ORDER,
    PAYMENT,
    SHOPPING_CART,
} from '@web/ui/components/card/Shopping-Cart/CheckoutHelpers';
import { CHECKOUT_STATE } from '@components/card/Shopping-Cart/CheckoutHelpers';
import ShoppingCartSelector from './ShoppingCartSelector';

type PageRefs = {
    shoppingCart?: React.RefObject<HTMLElement>;
    order: React.RefObject<HTMLElement>;
    payment: React.RefObject<HTMLElement>;
};

type CheckoutProps = {
    group: Group;
    subgroup?: Group;
    product?: Product;
    quantity?: number;

    // new guest/registration checkout
    hideCloseButton?: boolean;
    hideCheckoutInstructions?: boolean;
    customOrderHeading?: string | React.ReactNode;
    customPaymentHeading?: string | React.ReactNode;
    onCancel?: () => void;
    onGoBack?: () => void;
    useSuccessAltText?: boolean;
    handleSuccessRedirect?: () => void;
    isSignupGuestCheckout?: boolean;

    isInline?: boolean;
};

const Checkout: React.FC<CheckoutProps> = ({
    group,
    subgroup,
    product,
    quantity = 1,

    hideCloseButton = false,
    hideCheckoutInstructions = false,
    customOrderHeading,
    customPaymentHeading,
    onCancel,
    onGoBack,
    useSuccessAltText = false,
    handleSuccessRedirect = () => { },
    isSignupGuestCheckout,

    isInline = false,
}) => {
    const idempotencySalt = useRef(uuid());

    const isEvent = group?.subtype === 'event';

    const { closeAllModals } = useModal();
    const { currentUser } = useCurrentUser();

    const [cart, setCart] = useState<{
        cartItem: Product;
        quantity: number;
    }>({
        cartItem: product,
        quantity: Number(quantity) ?? 1,
    });
    const [discountCodeState, setDiscountCodeState] = useImmer<DiscountCodeState>({
        code: '',
        percentage: undefined,
        isApplied: null,
        error: null,
    });
    const [state, setState] = useImmer<UserDetailsState>({
        name: currentUser?.profile?.full_name ?? '',
        email: currentUser?.profile?.email ?? '',
        phoneNumber: currentUser?.profile?.phone ?? '',
        password: '',
    });

    const [idempotencyKey, setIdempotencyKey] = useState('');

    const [clientSecret, setClientSecret] = useState<string>('');
    const [orderId, setOrderId] = useState<string>('');
    const [checkoutState, setCheckoutState] = useState<CHECKOUT_STATE | boolean>(false);

    const refs: PageRefs = isEvent
        ? {
            shoppingCart: useRef<HTMLElement>(null),
            order: useRef<HTMLElement>(null),
            payment: useRef<HTMLElement>(null),
        }
        : {
            order: useRef<HTMLElement>(null),
            payment: useRef<HTMLElement>(null),
        };

    const pages = Object.keys(refs).map(name => ({
        name,
        canSkip: false,
        showMainButton: false,
    }));

    const { on, scrollTo } = useHorizontalPages({ refs });
    const { currentPage, currentPageName } = getNavFunctions({
        on,
        scrollTo,
        pages,
    });

    const [validateCoupon, { data }] = useValidateCouponLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: () => {
            if (data?.validateCoupon.valid) {
                setDiscountCodeState({
                    ...discountCodeState,
                    percentage: data?.validateCoupon?.coupon?.percentage,
                    isApplied: true,
                    error: null,
                });
            } else {
                setDiscountCodeState({
                    ...discountCodeState,
                    percentage: undefined,
                    isApplied: false,
                    error: data?.validateCoupon.message,
                });
            }
        },
    });

    const maxPages = pages.length - 1;

    const handleNextStep = (smooth = false) => {
        if (isEvent) {
            if (currentPage === 0 && currentPage !== maxPages) {
                scrollTo?.[ORDER](smooth);
            } else if (currentPage === 1 && currentPage !== maxPages) {
                scrollTo?.[PAYMENT](smooth);
            }
        } else {
            scrollTo?.[PAYMENT](smooth);
        }
    };

    const handlePrevStep = (smooth = false) => {
        if (isEvent) {
            if (currentPage === 1) {
                scrollTo?.[SHOPPING_CART](smooth);
            } else if (currentPage === 2) {
                scrollTo?.[ORDER](smooth);
            }
        } else {
            scrollTo?.[ORDER](smooth);
        }
    };

    const handleDiscountCode = (couponCode: string, productId: string) => {
        if (couponCode) {
            validateCoupon({
                variables: {
                    input: {
                        couponCode,
                        productId,
                    },
                },
            });
        }
    };

    const handleRemoveDiscountCode = () => {
        setDiscountCodeState({
            percentage: undefined,
            error: null,
            isApplied: false,
            code: '',
        });
    };

    return (
        <div>
            <div className="stripe-horizontal-nav-header-wrap">
                <HorizontalNavHeader
                    currentPage={currentPage}
                    pages={pages}
                    skipPage={() => { }}
                    className="stripe-horizontal-nav-header"
                />
            </div>
            <div className="stripe-checkout-wrapper">
                {isEvent && (
                    <ShoppingCartSelector
                        ref={refs.shoppingCart}
                        cart={cart}
                        setCart={setCart}
                        discountCodeState={discountCodeState}
                        group={group}
                        handleNextStep={handleNextStep}
                        closeModal={closeAllModals}
                        currentPage={currentPageName}
                        onCancel={onCancel}
                        isInline={isInline}
                    />
                )}
                <OrderDetails
                    ref={refs.order}
                    state={state}
                    setState={setState}
                    cart={cart}
                    setCart={setCart}
                    checkoutState={checkoutState}
                    setCheckoutState={setCheckoutState}
                    discountCodeState={discountCodeState}
                    setDiscountCodeState={setDiscountCodeState}
                    group={group}
                    subgroup={subgroup}
                    clientSecret={clientSecret}
                    setClientSecret={setClientSecret}
                    setOrderId={setOrderId}
                    handleNextStep={handleNextStep}
                    handlePrevStep={handlePrevStep}
                    handleDiscountCode={handleDiscountCode}
                    handleRemoveDiscountCode={handleRemoveDiscountCode}
                    closeModal={closeAllModals}
                    idempotencySalt={idempotencySalt.current}
                    idempotencyKey={idempotencyKey}
                    setIdempotencyKey={setIdempotencyKey}
                    hideCloseButton={hideCloseButton}
                    hideCheckoutInstructions={hideCheckoutInstructions}
                    customOrderHeading={customOrderHeading}
                    onCancel={onCancel}
                    onGoBack={onGoBack}
                    currentPage={currentPageName}
                    isInline={isInline}
                />
                <Payment
                    ref={refs.payment}
                    state={state}
                    discountCodeState={discountCodeState}
                    group={group}
                    cart={cart}
                    checkoutState={checkoutState}
                    setCheckoutState={setCheckoutState}
                    orderId={orderId}
                    closeModal={closeAllModals}
                    handlePrevStep={handlePrevStep}
                    clientSecret={clientSecret}
                    setClientSecret={setClientSecret}
                    hideCloseButton={hideCloseButton}
                    customPaymentHeading={customPaymentHeading}
                    onCancel={onCancel}
                    onGoBack={onGoBack}
                    useSuccessAltText={useSuccessAltText}
                    handleSuccessRedirect={handleSuccessRedirect}
                    currentPage={currentPageName}
                    isSignupGuestCheckout={isSignupGuestCheckout}
                    isInline={isInline}
                />
            </div>
        </div>
    );
};

export default Checkout;
