import React from 'react';
import RichMarkdownEditor from '@welibraryos/welibrary-editor';
import useMentionsSelect from '@web/ui/components/mentions/useMentions';

import TwitterEmbed from '../embeds/TwitterEmbed';
import { mediaRegex } from '~/wl-core/utilities/constants/regexes';

type PostEditorProps = {
    onChange: (value: () => string) => void;
    placeholder: string;
    mentionsUsers?: any;
    className?: string;
    defaultValue?: string; // used for initializing editor with initial value
    editorKey?: number; // used for clearing editor
    mentionsGroupId?: string; //used for filtering out mentions to a specific group
};

/* This is the version used for new posts, it is missing the image upload options and embed options
since that is handled separately outside the editor in this case */
const PostEditor: React.FC<PostEditorProps> = ({
    className,
    onChange,
    placeholder,
    defaultValue,
    editorKey,
    mentionsUsers,
    mentionsGroupId,
}) => {
    const { setSearchString, searchResults } = useMentionsSelect(mentionsUsers, mentionsGroupId);

    return (
        <RichMarkdownEditor
            defaultValue={defaultValue}
            className={className}
            key={editorKey}
            onChange={onChange}
            id="richmarkdown-editor"
            placeholder={placeholder}
            participants={searchResults}
            noMobileMention
            onMentionInput={setSearchString}
            autoFocus
            embeds={[
                {
                    title: 'Twitter',
                    keywords: 'twitter tweet',
                    icon: () => (
                        <img
                            alt="Twitter embed"
                            src="https://upload.wikimedia.org/wikipedia/commons/7/75/YouTube_social_white_squircle_%282017%29.svg"
                            width={24}
                            height={24}
                        />
                    ),
                    matcher: url => {
                        return url.match(mediaRegex.tweet);
                    },
                    component: TwitterEmbed,
                },
            ]}
        />
    );
};

export default PostEditor;
