import React from 'react';
import useModal from '@components/modals/hooks/useModal';
import { ModalTypes } from '@core/types/Modals';
import UpdateEventGroupFormQuery, {
    UpdateEventGroupForm,
} from '@components/content/newpost/forms/EventGroup/UpdateEventGroupForm';
import { Maybify } from '@core/types/Utilities';
import { Group } from '@shared/welibrary-graphql/types';

const useEditEventGroupForm = () => {
    const { newModal } = useModal();

    const openEditEventGroupForm = (initialStep: string, group: Maybify<Group>) => {
        if (!initialStep || !group) return;
        newModal(
            <UpdateEventGroupForm initialStep={initialStep} group={group} />,
            {
                hideButton: true,
                className: 'center-fixed-header-footer',
                confirmClose: 'Are you sure you want to cancel?',
            },
            { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
        );
    };

    const openEditEventGroupFormWithQuery = (initialStep: string, card: Maybify<Group>) => {
        if (!initialStep || !card) return;
        newModal(
            <UpdateEventGroupFormQuery initialStep={initialStep} card={card} />,
            {
                hideButton: true,
                className: 'center-fixed-header-footer',
                confirmClose: 'Are you sure you want to cancel?',
            },
            { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
        );
    };

    return { openEditEventGroupForm, openEditEventGroupFormWithQuery };
};

export default useEditEventGroupForm;
