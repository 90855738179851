import React from 'react';

import { SkillType, getSkillIcon } from '~/wl-web/utilities/helpers/lego/skill.helpers';

type SkillAndTextLabelProps = {
    skill?: SkillType;
    label: string;
};

export const SkillAndTextLabel: React.FC<SkillAndTextLabelProps> = ({ skill, label }) => {
    return (
        <section className="skill-and-text-label">
            <figure>{skill ? <img src={getSkillIcon(skill)} alt={skill} /> : <></>}</figure>
            <span>{label}</span>
        </section>
    );
};

export default SkillAndTextLabel;
