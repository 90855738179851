import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Updater, useImmer } from 'use-immer';
import { sortBy, cloneDeep, uniqBy } from 'lodash';
import useModal from '@components/modals/hooks/useModal';
import { v1 as uuidv1 } from 'uuid';
import TimeAndPlaceForm from '@components/content/newpost/forms/EventGroup/TimeAndPlaceForm';
import TimeAndPlaceInfoCard from '@components/content/newpost/forms/EventGroup/TimeAndPlaceInfoCard';
import { getFormattedEventDates, getFormattedEventTimes } from '@core/utilities/constants/events';

import {
    ItineraryState,
    DateAndTimeState,
    OverviewFormState,
    SpeakerStateType,
} from '@core/types/EventGroup';
import { ModalTypes } from '@core/types/Modals';

type EventGroupItineraryFormProps = {
    state: ItineraryState[];
    eventState: DateAndTimeState;
    overviewFormState?: OverviewFormState;
    setState: Updater<ItineraryState[]>;
    updateValidationSlice: Updater<boolean>;
};

const EventGroupItineraryForm = React.forwardRef<HTMLFieldSetElement, EventGroupItineraryFormProps>(
    function EventGroupItineraryForm({ state, setState, eventState, overviewFormState }, ref) {
        const { t } = useTranslation();

        const { newModal, closeModal } = useModal();

        // keeps track of all speakers for a given event group
        const [allSpeakers, setAllSpeakers] = useImmer<SpeakerStateType[] | null>(null);

        const locationTitle = t(
            `common:eventGroupForm.itinerary_location_prompt`,
            'Where will this session take place?*'
        );

        const handleDeleteSession = (id: string) => {
            const _state = state.filter(sesh => sesh.id !== id);
            setState(_state);
        };

        const handleAddSession = (session: ItineraryState) => {
            setState(draft => {
                draft.push(session);
            });
        };

        const handleUpdateSession = (id: string, newState: ItineraryState) => {
            const updateIndex = state?.findIndex(sesh => sesh.id === id);
            setState(draft => {
                draft[updateIndex] = newState;
            });
        };

        const handleDuplicateSession = (session: ItineraryState) => {
            const seshClone = cloneDeep(session);
            seshClone.id = uuidv1();
            setState(draft => {
                draft.push(seshClone);
            });
        };

        const updateAllSpeakers = (stateCopy: ItineraryState[]) => {
            // all speakers local state keeps track of all speakers for an event
            // used to compare and display an event's aliased user's info instead of users info
            const _speakers = stateCopy?.reduce((acc, sesh) => {
                if (sesh?.speakers) {
                    return [...acc, ...sesh.speakers];
                }
                return acc;
            }, [] as Array<SpeakerStateType>);

            const dedupedSpeakers = uniqBy(_speakers, '_id');

            setAllSpeakers(dedupedSpeakers);
        };

        useEffect(() => {
            if (!allSpeakers && state) {
                updateAllSpeakers(state);
            }
        }, [state]);

        const handleUpdateSpeakerInAllSessions = (speaker: SpeakerStateType) => {
            // If a speaker's information is updated in one session, update it in all other sessions
            const updatedSeshes = state?.map(sesh => {
                const existingSpeakerIndex =
                    sesh.speakers?.findIndex(seshSpeaker => seshSpeaker._id === speaker._id) ?? -1;

                if (existingSpeakerIndex < 0) return sesh;

                const seshClone = cloneDeep(sesh);
                if (seshClone?.speakers) seshClone.speakers[existingSpeakerIndex] = speaker;

                return seshClone;
            });
            updateAllSpeakers(updatedSeshes);
            setState(updatedSeshes);
        };

        const handleTPForm = () => {
            newModal(
                <TimeAndPlaceForm
                    eventState={eventState}
                    overviewFormState={overviewFormState}
                    handleSave={handleAddSession}
                    handleUpdateSpeakerInAllSessions={handleUpdateSpeakerInAllSessions}
                    handleCancel={closeModal}
                    locationTitle={locationTitle}
                    allSpeakers={allSpeakers}
                />,
                { className: 'center-fixed-header-footer', hideButton: true },
                { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
            );
        };

        const eventDatesExist = eventState?.dates?.length > 0;
        const eventTimes =
            eventState?.startTime && eventState?.endTime && getFormattedEventTimes(eventState);
        const eventDates = eventDatesExist && getFormattedEventDates(eventState?.dates?.[0]);

        const sortedSessions = sortBy(state, session => session?.startTime);

        return (
            <fieldset ref={ref} className="main-form-event-group">
                <h3>{t('common:itinerary_(optional)', 'Itinerary (Optional)')}</h3>
                <section className="step-desc">
                    <p>
                        {t(
                            'common:eventGroupForm.itinerary_description',
                            'Add sessions to your event. Sessions represent distinct sub-events that occur during the frame of the overall timespan you selected in the previous step. You can also add speakers to your sessions here.'
                        )}
                    </p>
                </section>

                <h4>{eventDates}</h4>
                <p className="grey-600">{eventTimes}</p>

                <section>
                    {sortedSessions?.map(item => {
                        return (
                            <TimeAndPlaceInfoCard
                                key={item?.id}
                                itemId={item?.id}
                                handleUpdate={handleUpdateSession}
                                handleDelete={handleDeleteSession}
                                handleDuplicate={handleDuplicateSession}
                                handleUpdateSpeakerInAllSessions={handleUpdateSpeakerInAllSessions}
                                allSpeakers={allSpeakers}
                                state={item}
                                eventState={eventState}
                            />
                        );
                    })}
                </section>

                <div className="tp-gen-add-container">
                    <button type="button" onClick={handleTPForm}>
                        <span>{t('common:new_session', 'New Session')}</span>
                        <div className="circle-plus-icon-dark animate-grow">
                            <div />
                            <div />
                        </div>
                    </button>
                </div>
            </fieldset>
        );
    }
);

export default EventGroupItineraryForm;
