import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TYPE_FORM } from '@web/ui/components/sidebar/sidebar_types.js';
import { GET_PAGINATED_GROUP_TEMPLATES } from '@shared/welibrary-graphql/user/queries';
import { TemplateItems } from '@core/types/GroupTemplateItem';

import useModal from '@web/ui/components/modals/hooks/useModal';
import useInfiniteScroll from '@web/ui/components/generic/hooks/useInfiniteScroll';

import { SidebarActionsContext } from '@web/ui/components/generic/context/SidebarContext';

import TemplateItem from '@web/ui/components/group/groupdashboard/GroupTemplateItem';
import LoadingIndicator from '@web/ui/components/generic/loading/LoadingIndicator';

const localNamespace = 'imports.wlWeb.ui.components.group.groupDashboard.plus_button';

const CreateGroupViaTemplate: React.FC<{
    parentId: string | null;
    title?: string | null;
    createSubgroup: boolean;
    formTemplate?: string | null;
    subtypes?: [string] | null;
}> = ({ parentId = null, createSubgroup = true, title, formTemplate, subtypes }) => {
    const { t } = useTranslation();

    const { showSidebar } = useContext(SidebarActionsContext);

    const { closeModal } = useModal();

    const [groupName, setGroupName] = useState<string>('');

    const {
        queryResult: { data, loading },
        setRef,
    } = useInfiniteScroll({
        query: {
            query: GET_PAGINATED_GROUP_TEMPLATES,
            options: {
                variables: { type: 'PaginatedGroups', subtypes, limit: 20 },
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
            },
        },
    });

    const hasMoreResults = data?.paginatedGroupTemplates?.hasMore;
    const groupTemplates = data?.paginatedGroupTemplates?.results;

    const groupTemplateOptions: TemplateItems = groupTemplates?.map(item => ({
        title: item?.profile?.full_name ?? '',
        description: item?.profile?.short_bio ?? '',
        thumb: item?.profile?.picture ?? '',
        url: item?._id ?? '',
    }));

    const handleNewGroup = (
        e?: React.FormEvent<HTMLFormElement | HTMLButtonElement>,
        id?: string,
        override: boolean = false
    ) => {
        e?.preventDefault();

        if (groupName.trim() === '' && !override) return;

        closeModal();
        showSidebar(TYPE_FORM, null, null, 'full', {
            form: formTemplate || 'creategroup',
            data: {
                initialGeneratorAnswers: {
                    parentId,
                    templateId: id,
                    groupName,
                    recommendations: true,
                },
            },
        });
    };

    const handleSelectedTemplate = (_title: string, id?: string) =>
        handleNewGroup(undefined, id, true);

    let _title = title;
    // conditionally render title when creating
    // sub-groups or new-groups
    if (createSubgroup && !title) {
        _title = t(`common:${localNamespace}.new_subgroup`);
    } else if (!title) {
        _title = t(`common:${localNamespace}.new_group`);
    }

    return (
        <section className="channelTemplate-form">
            <header>{_title}</header>

            <form onSubmit={handleNewGroup} className="channelTemplate-form-input">
                <input
                    autoComplete="off"
                    type="text"
                    maxLength={256}
                    name="field"
                    data-name="Field"
                    placeholder={t(`common:${localNamespace}.title`)}
                    onChange={e => setGroupName(e.currentTarget.value)}
                    style={{ backgroundImage: 'none', padding: '10px 30px' }}
                    value={groupName}
                    onClick={e => e.stopPropagation()}
                />

                <button type="button" onClick={handleNewGroup}>
                    <div className="content-button-icon plus" />
                </button>
            </form>

            {loading && <LoadingIndicator type="skeleton" size="fill" />}

            <ul>
                {!loading && groupTemplateOptions?.length > 0
                    ? groupTemplateOptions?.map(item => {
                          return (
                              <TemplateItem
                                  key={item.url}
                                  item={item}
                                  handleSelectedTemplate={handleSelectedTemplate}
                              />
                          );
                      })
                    : null}
                {hasMoreResults && (
                    <div ref={setRef}>
                        {' '}
                        <LoadingIndicator type="skeleton" size="fill" />
                    </div>
                )}
            </ul>
        </section>
    );
};

export default CreateGroupViaTemplate;
