/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import _ from 'lodash';
import moment from 'moment';

import useModal from '@components/modals/hooks/useModal';
import withPortal from '@components/generic/lightbox/withPortal';
import useNavigation from '@components/navigation/useNavigation';
import useDebouncedState from '@components/generic/hooks/useDebouncedState';
import { useCurrentUser } from '@stores/User';

import Picture from '@components/generic/Picture';
import EyeSlash from '@dsc/svgs/EyeSlash';
import LoadingLine from '@components/generic/loading/LoadingLine';
import SearchInput from '@components/search/SearchInput';
import CreateGroup from '@components/group/CreateGroupViaTemplate';
import GroupNavigationSortSelect from '@components/group/GroupNavigationSortSelect';
import GroupContext, { GroupActionsContext } from '@components/group/context/GroupContext';

import { Group } from '@shared/welibrary-graphql/types';
import { MY_GROUPS } from '@shared/welibrary-graphql/user/queries';
import { SWITCH_ACTIVE_GROUP } from '@shared/welibrary-graphql/user/mutations';

import { truncate } from '@helpers/string.helpers';
import {
    getRandomDefaultCoverImage,
    getBackgroundImageSetForUrl,
    StyledBackgroundImageLink,
} from '@core/utilities/constants/defaults';
import {
    getFormattedDateDisplayFromTimes,
    getTinyCalendarFormattedDates,
} from '@core/utilities/constants/events';
import { SetState } from '@core/types/Utilities';

import { awsMetricPublisher } from '@welibraryos/metrics';
import { config } from '@config/getConfig';

const localNamespace = 'imports.wlWeb.ui.components.group.groupNavigation';

// Constants for determining open animation translation offset

// .group-select-link height (9 -> 9vh) TODO: Have better way for determining top translation
const GROUP_SELECT_LINK_HEIGHT = 9;

// On very short screens, the final link may be slightly squished. Adjust this to give more room.
const BOTTOM_PADDING = 10;

// When there are few items (~ n < 10), this is the total range of motion for the y-translation
// (0 < y-translation < SCREEN_SPACE)
const SCREEN_SPACE = 100 - BOTTOM_PADDING;

// Extra, manually added links (i.e. join-button)
const EXTRA_LINKS = 1;

const GROUP_NAV_TYPES = { FILTER: 'groupFilter', ANALYTICS: 'groupAnalytics' };

const INITIAL_GROUP_LIMIT = 30;

// Increase this value to optimistically load new content as users scroll down
const INFINITE_LOADER_BOTTOM_PADDING = 600;

const DEFAULT_SORT = 'recentFirst';

const GroupNavigationQuery = () => {
    const { isGroupAnalyticsSelected, isGroupSwitchOpen } = useNavigation();

    const [refreshing, setRefreshing] = useState(false);
    const [searchQuery, actualSearchQuery, setSearchQuery] = useDebouncedState('');

    const [selectedSort, setSelectedSort] = useState(DEFAULT_SORT);

    const handleChangeSort = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSort(e?.target?.value);
    };

    const filters: {
        searchQuery: string;
        sortType: string;
        role?: string[];
        protectionLevel?: string[];
    } = { searchQuery: actualSearchQuery, sortType: selectedSort };
    if (isGroupAnalyticsSelected) {
        filters.role = ['owners'];
    }

    const { networkStatus, fetchMore, data, loading, refetch } = useQuery(MY_GROUPS, {
        variables: { limit: INITIAL_GROUP_LIMIT, cursor: null, ...filters },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    useEffect(() => {
        // Ensure fresh group list on open
        if (isGroupSwitchOpen) {
            if (refetch) refetch();
        }
    }, [isGroupSwitchOpen, selectedSort]);

    const lastPaginationCursor = data?.myGroups?.cursor;
    const hasMoreResults = data?.myGroups?.hasMore;

    if (refreshing && networkStatus !== 3 && hasMoreResults) {
        fetchMore({
            variables: { cursor: lastPaginationCursor },
            onCompleted: () => setRefreshing(false),
        }).then(() => setRefreshing(false));
        setRefreshing(false);
    }

    const handleDocumentScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        e.persist();
        const scrollHeight = e?.currentTarget?.scrollHeight;
        const scrollTop = e?.currentTarget?.scrollTop;
        const clientHeight = e?.currentTarget?.clientHeight;

        // If scroll is beyond threshold, including padding, then set refreshing to true
        const bottom = scrollHeight - INFINITE_LOADER_BOTTOM_PADDING <= scrollTop + clientHeight;

        if (!refreshing && bottom) {
            setRefreshing(true);
        }
    };

    const showGroups = data?.myGroups?.results;

    return (
        <>
            <LoadingLine isLoading={loading && !isGroupSwitchOpen && !data} />
            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            <GroupNavigation
                handleScroll={handleDocumentScroll}
                showGroups={showGroups}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleChangeSort={handleChangeSort}
                selectedSort={selectedSort}
                refetch={refetch}
            />
        </>
    );
};

type GroupNavigationProps = {
    showGroups?: Group[];
    handleScroll: React.UIEventHandler<HTMLDivElement>;
    searchQuery: string;
    setSearchQuery: SetState<string>;
    selectedSort: string;
    handleChangeSort: React.ChangeEventHandler<HTMLSelectElement>;
    refetch: () => void;
};

const GroupNavigation: React.FC<GroupNavigationProps> = ({
    showGroups,
    handleScroll,
    searchQuery,
    setSearchQuery,
    selectedSort,
    handleChangeSort,
    refetch,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const { newModal } = useModal();

    const {
        isGroupAnalyticsSelected,
        isGroupSwitchOpen,
        setGroupSwitchOpenState,
        groupSwitchRoute,
        groupSwitchType,
    } = useNavigation();

    const { currentUser } = useCurrentUser();
    const { addGroupsFilter, deleteGroupsFilter } = useContext(GroupActionsContext);
    const { groupState } = useContext(GroupContext);
    const [quickJoinGroupOpen, setQuickJoinGroupOpen] = useState(false);
    const [switchActiveGroup] = useMutation(SWITCH_ACTIVE_GROUP);
    const groupFilterIds = groupState?.groupsFilter;
    const multiSelectTypes = ['groupFilter'];
    const isMultiSelect = multiSelectTypes.includes(groupSwitchType);
    const isNativeApp = Capacitor.isNativePlatform();
    // reset search input when opening/closing GroupNav
    useEffect(() => {
        setSearchQuery('');
    }, [isGroupSwitchOpen]);

    // Close the Group Navigation Menu
    const close = () => {
        setGroupSwitchOpenState(false);
    };

    // When a membership link is clicked
    const onGroupClick = (groupId: string) => {
        /* Fire mutation for switching active membership */

        if (!isMultiSelect) {
            awsMetricPublisher.publishCount('secondary-navigation', 1, [
                { Name: 'groups-menu', Value: 'switch-active-group' },
            ]);

            switchActiveGroup({
                variables: { groupId },
            }).then(() => {
                if (refetch) refetch();
            });

            if (groupSwitchRoute) {
                history.push(`/g/${groupId}/${groupSwitchRoute}`);
            } else {
                history.push(`/g/${groupId}`);
            }
        }
    };

    const onCreateGroupClick = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'groups-menu', Value: 'create-new-group' },
        ]);
        close();
        newModal(<CreateGroup createSubgroup={false} parentId={null} />);
    };

    const onJoinGroupClick = () => {
        awsMetricPublisher.publishCount('secondary-navigation', 1, [
            { Name: 'groups-menu', Value: 'join-new-group' },
        ]);
        close();
    };

    // Only SuperAdmins can create groups...
    const canCreateGroup = currentUser?.isUserSuperAdmin;

    // Total number of memberships the user is a part of
    const membershipCount = currentUser?.groups?.results?.length ?? 0;

    // Get active membership to highlight which one the user is currently oriented in.
    const activeMembership = currentUser?.activeGroup;

    // Map memberships onto selectable links
    const memberRows = () => {
        //  if (membershipCount <= 0) return [];
        // Show the groups on the user's profile if no groups have loaded so it's not blank to begin with.

        const _showGroups = showGroups;

        // todo exract this into separate components....
        const rows =
            _showGroups?.map(membershipCard => {
                const isEvent = membershipCard?.subtype === 'event';

                if (membershipCard.profile === null) {
                    // I think this can happen sometimes if a group has been deleted?
                    //   Prevents a crash
                    return;
                }

                const {
                    profile: { full_name, picture },
                    timeAndPlaceSettings,
                    isUnpublished,
                } = membershipCard;

                const eventData = isEvent ? timeAndPlaceSettings[0] : null;
                const eventDate = eventData?.date;

                // todo find a better way to do this, maybe variables? or create helper function, or keep track in a context
                let memberCountText = isEvent
                    ? 'common:global.nouns.attendees'
                    : 'common:global.nouns.members';
                if (membershipCard?.totalMembers === 1) {
                    memberCountText = isEvent
                        ? 'common:global.nouns.attendee'
                        : 'common:global.nouns.member';
                }

                const totalMembersText = `${membershipCard?.totalMembers} ${t(memberCountText)}`;
                let eventDateText = `${moment(eventDate).format('ddd, MMM Do')} AT ${moment(
                    eventData?.startTime
                ).format('h:mm A z')}`;

                if (membershipCard?.eventOverviewDetails) {
                    eventDateText = getFormattedDateDisplayFromTimes(
                        membershipCard?.eventOverviewDetails
                    );
                }

                const { topDateDisplay, bottomDateDisplay, isMultipleDates } =
                    getTinyCalendarFormattedDates(membershipCard?.eventOverviewDetails);

                const calendarClass = isMultipleDates
                    ? 'event-dashboard-image-calendar wide'
                    : 'event-dashboard-image-calendar';

                const cardContainerClassName = isEvent
                    ? 'group-select-link-content event'
                    : 'group-select-link-content';

                const title = membershipCard?.profile?.full_name ?? membershipCard?.username;
                const shelfPhoto = membershipCard?.shelf?.media?.thumb;
                const coverImage =
                    membershipCard?.profile?.coverPhoto ||
                    shelfPhoto ||
                    getRandomDefaultCoverImage(title);
                const [src, srcSet] = getBackgroundImageSetForUrl(coverImage);

                const [groupThumbSrc, groupThumbSrcset] = getBackgroundImageSetForUrl(
                    picture || getRandomDefaultCoverImage(full_name)
                );

                // Check active membership. If selected, add checkmark icon
                const selectedIcon = <div className="group-select-checkmark" />;

                /**
                 *    One of 3 Scenarios:
                 *    1: this is a multi-select menu, which has it's own selection logic
                 *    2: we are on a group page /g/, which should highlight the group page we are on
                 *    3: we are on the dashboard, so we should highlight the user's 'active group'
                 */
                const isSelected = isMultiSelect
                    ? groupFilterIds?.find((groupId: string) => groupId === membershipCard?._id)
                    : location.pathname.includes(membershipCard?._id ?? '') ||
                    (location.pathname.includes('dashboard') &&
                        activeMembership?._id === membershipCard?._id);

                const handleClick = () => {
                    if (!isMultiSelect) onGroupClick(membershipCard?._id ?? '');

                    if (isMultiSelect && groupSwitchType === GROUP_NAV_TYPES.FILTER) {
                        if (isSelected) deleteGroupsFilter(membershipCard?._id);
                        else addGroupsFilter(membershipCard?._id);
                    }
                };

                const hasUnreadDashboardIcon = (membershipCard?.currentUserFreshCount ?? 0) > 0 && (
                    <span className="group-select-link-new-content">
                        {`${membershipCard?.currentUserFreshCount} ${t('common:global.new_items')}`}
                    </span>
                );

                const groupDescription =
                    truncate(membershipCard?.profile?.short_bio, 35) ||
                    membershipCard?.parentGroup?.profile?.full_name;
                const parentGroupName = membershipCard?.parentGroup?.profile?.full_name;
                const parentIsRootGroup =
                    (config.public.rootGroupId !== undefined &&
                        membershipCard?.parentGroup?._id === config.public.rootGroupId) ||
                    config?.public?.homeGroups?.groupIds?.includes(
                        membershipCard?.parentGroup?._id
                    );
                const displayParentGroup = parentGroupName && !parentIsRootGroup;

                const handleHasParentGroup = () => {
                    if (isEvent && displayParentGroup) {
                        return 'group-event-select-link-content-has-parent';
                    }

                    if (displayParentGroup) return 'group-select-link-content-has-parent';

                    return '';
                };

                // Hide members of root group
                const showMembersButton =
                    membershipCard?._id !== config.public.rootGroupId &&
                    !config.public?.homeGroups?.groupIds?.includes(membershipCard?._id);

                return (
                    <StyledBackgroundImageLink
                        to="#"
                        onClick={e => {
                            e.stopPropagation();
                            handleClick();
                            close();
                        }}
                        key={`group-${membershipCard?._id}`}
                        className="group-select-link w-inline-block"
                        src={src}
                        srcSet={srcSet}
                    >
                        <div className="group-select-link-dimmer" />
                        <div className="group-select-item-container">
                            {displayParentGroup && (
                                <div className="group-select-parent-badge-container">
                                    <div className="group-select-parent-badge-wrap">
                                        <p className="group-select-parent-badge">
                                            {t('common:global.preposition.in')}{' '}
                                            {t(`common:global.nouns.group`)}{' '}
                                            <span>{parentGroupName}</span>
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className={`${cardContainerClassName} ${handleHasParentGroup()}`}>
                                {isEvent && (
                                    <div className="event-dashboard-image-container">
                                        <div className={calendarClass}>
                                            <div className="event-dashboard-image-calendar-top">
                                                {topDateDisplay}
                                            </div>
                                            <div className="event-dashboard-image-calendar-bottom">
                                                {bottomDateDisplay}
                                            </div>
                                        </div>

                                        <Picture
                                            className="group-dashboard-thumb thumbnail-border-highlight"
                                            url={groupThumbSrc}
                                            resolutions={[200, 400, 600]}
                                            disableLink
                                        />
                                    </div>
                                )}
                                <div
                                    className={`group-select-menu-text ${isSelected && 'current'}`}
                                >
                                    {isEvent && (
                                        <div className="group-select-link-info date">
                                            {eventDateText}
                                        </div>
                                    )}
                                    {title}
                                    {isUnpublished && (
                                        <span className="flex gap-[5px] items-center text-[13px] font-[600] pl-[2px]">
                                            <EyeSlash size="16" strokeWidth="1.2" />
                                            {t(`common:global.unpublished`, 'Unpublished')}
                                        </span>
                                    )}
                                    {showMembersButton && !isUnpublished && (
                                        <div className="group-select-link-info">
                                            <div>{totalMembersText}</div>
                                            {hasUnreadDashboardIcon}
                                        </div>
                                    )}
                                    {groupDescription && !isEvent && (
                                        <div className="group-select-link-summary">
                                            {groupDescription}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {isSelected && selectedIcon}
                        </div>
                    </StyledBackgroundImageLink>
                );
            }) ?? [];
        return rows;
    };

    // Calculate how far up the screen the group navigation menu should open.
    let openTranslation = SCREEN_SPACE - (membershipCount + EXTRA_LINKS) * GROUP_SELECT_LINK_HEIGHT;
    // If there are many elements, make full screen (the container is scrollable)
    if (openTranslation <= 0) openTranslation = 0;

    const modalRoot = document.querySelector('#modal-mid-root');
    const getHeaderText = (type: string) => {
        if (type === GROUP_NAV_TYPES.ANALYTICS) return t(`common:${localNamespace}.nav_analytics`);
        if (type === GROUP_NAV_TYPES.FILTER) return t(`common:${localNamespace}.nav_filter`);
    };

    const headerComponent = (
        <div className="group-analytics-header">
            <div onClick={close} className="cancel-edit-content-button w-inline-block" />
            <div className="group-header-title">{getHeaderText(groupSwitchType)}</div>
        </div>
    );

    const isHeaderType = (type: string) => type === 'groupAnalytics' || type === 'groupFilter';

    const hideCreateJoinButtons =
        groupSwitchType === GROUP_NAV_TYPES.ANALYTICS || groupSwitchType === GROUP_NAV_TYPES.FILTER;

    // various display/ui adjustment logic
    const headerOutput =
        window.innerWidth < 991 ? createPortal(headerComponent, modalRoot) : headerComponent;
    const renderGroupHeader =
        isGroupAnalyticsSelected || isHeaderType(groupSwitchType) ? headerOutput : null;
    const scrollGroupsClass = isGroupAnalyticsSelected
        ? `${isGroupAnalyticsSelected} overflow-y-scroll`
        : `scroll-groups ${isHeaderType(groupSwitchType) ? 'header' : ''}`;

    const handleClose = isMultiSelect ? () => { } : close;

    return (
        <>
            {isGroupSwitchOpen && <div onClick={close} className="group-select-dimmer fadein" />}

            <div id="group-navigation" className={isGroupSwitchOpen ? 'open' : ''}>
                <div className={scrollGroupsClass} onScroll={handleScroll} onClick={handleClose}>
                    {renderGroupHeader}
                    <div className="scroll-groups-header-container">
                        <div className="scroll-groups-header-top">
                            <div className="scroll-groups-search-container">
                                <div
                                    className="width-100"
                                    onClick={e => {
                                        awsMetricPublisher.publishCount('secondary-navigation', 1, [
                                            { Name: 'groups-menu', Value: 'search-groups' },
                                        ]);
                                        e.stopPropagation();
                                    }}
                                >
                                    <SearchInput
                                        autoFocusInput={!isNativeApp}
                                        isSearchOverlayOpen={isGroupSwitchOpen} // to allow for autofocusing
                                        onChange={value => setSearchQuery(value)}
                                        value={searchQuery}
                                        placeholder={t(
                                            'common:imports.wlWeb.ui.components.search.SearchOverlay.search_my_groups',
                                            'Search my groups...'
                                        )}
                                    />
                                </div>
                            </div>
                            <GroupNavigationSortSelect
                                onChange={handleChangeSort}
                                selected={selectedSort}
                            />
                        </div>
                        <div className="scroll-groups-header-buttons">
                            {!hideCreateJoinButtons && canCreateGroup && (
                                <button
                                    type="button"
                                    onClick={onCreateGroupClick}
                                    key="create-group"
                                    className="group-select-link join-group"
                                >
                                    <div>{t('common:global.verbs.create')}</div>
                                    <div className="group-select-icon plus" />
                                </button>
                            )}
                            {!hideCreateJoinButtons && (
                                <Link
                                    to="/add-group"
                                    key="new-group"
                                    onClick={onJoinGroupClick}
                                    className="group-select-link join-group"
                                >
                                    <div>{t('common:global.join')}</div>
                                    <div className="group-select-icon plus" />
                                </Link>
                            )}
                        </div>
                        <div className={`drawer-container ${!!quickJoinGroupOpen && 'open'}`}>
                            <input
                                autoComplete="off"
                                name="shortCode"
                                type="text"
                                className="login-text-field w-input"
                                maxLength={15}
                                placeholder={t(
                                    `common:${localNamespace}.enter_group_code`,
                                    'Enter Group Code'
                                )}
                                id="shortCode"
                                style={{ textTransform: 'uppercase' }}
                            />
                            <span className="">
                                {t(`common:${localNamespace}.no_group_membership`)}
                            </span>
                            <div className="flex-center">
                                <button type="submit" className="login-button w-inline-block">
                                    {t('common:global.next')}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setQuickJoinGroupOpen(false)}
                                    className="login-button w-inline-block"
                                >
                                    {t(`common:global.cancel`, 'Cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                    {memberRows()}
                </div>
            </div>
        </>
    );
};

// Use a portal so the menu overlays everything else.
export default withPortal(GroupNavigationQuery);
