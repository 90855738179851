import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGetOrderByIdQuery } from '@shared/welibrary-graphql/user/queries.hook';
import { useSetTicketIsCheckedInMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import useModal from '@web/ui/components/modals/hooks/useModal';
import shoppingCartStore, { SELL_TICKETS_AT_DOOR_STEPS } from '@stores/ShoppingCart';

import ViewCustomerOrderProductInstance from './ViewCustomerOrderProductInstance';
import CustomerOrderDetails from './CustomerOrderDetails';
import RefundOrderModal from './RefundOrderModal';
import ModalLoading from '@components/modals/ModalLoading';

import {
    convertCentsToDollars,
    getOrderTotalDisplayString,
} from '@components/card/Shopping-Cart/CheckoutHelpers';

import { CustomerOrder, StripeSubscriptionStatus } from '@shared/welibrary-graphql/types';
import { Group, StripeCharge, StripeRefund } from '~/wl-core/graphql/types';
import { OrderStatusEnum, RefundStatusEnum } from '../../../profile/stripe/stripeOrderHelpers';
import { getCurrencyDisplayString } from '~/wl-web/utilities/helpers/money/money.helpers';

type ViewCustomerOrderProps = {
    group: Group;
    order: CustomerOrder;
    goBack: () => void;
    showRefundOrder?: boolean;
    backTextOverride?: string;
};

const ViewCustomerOrder: React.FC<ViewCustomerOrderProps> = ({
    group,
    order,
    goBack,
    showRefundOrder = true,
    backTextOverride,
}) => {
    const { t } = useTranslation();
    const { newModal } = useModal();
    const [checkingInTickets, setCheckingInTickets] = useState(false);
    const currentUserIsAdmin =
        group?.currentUserIsAdmin || group?.currentUserIsDirectAdmin || false;

    const { paymentType, orderLocation, user, processedByUser } = order;
    const { full_name, phone, email } = user.profile;
    const processedByUserName = processedByUser?.profile.full_name;

    const numberOfTickets = order.productInstances.length;

    // sometimes when we get here the tickets haven't been attached to the order yet (e.g. credit card checkout)
    //   so we'll poll until we have tickets
    const { data: orderByIdData } = useGetOrderByIdQuery({
        variables: {
            _id: order._id,
        },
        fetchPolicy: 'network-only',
        skip: numberOfTickets > 0,
        pollInterval: 1000,
    });

    useEffect(() => {
        const order = orderByIdData?.getOrder;
        if (order) {
            const newNumberOfTickets = order.productInstances.length;
            if (newNumberOfTickets > 0) {
                shoppingCartStore.set.order(order);
            }
        }
    }, [orderByIdData]);

    const orderTotal = getOrderTotalDisplayString(order);
    const [setTicketIsCheckedIn] = useSetTicketIsCheckedInMutation();
    const checkInAllTickets = async () => {
        setCheckingInTickets(true);
        const _order = cloneDeep(order);
        const ticketsToCheckIn = _order.productInstances.filter(
            ticket => !ticket.isCheckedIn && ticket.owner._id === ticket.purchaser._id
        );

        await Promise.all(
            ticketsToCheckIn.map(async ticket => {
                await setTicketIsCheckedIn({
                    variables: {
                        productInstanceId: ticket._id,
                        isCheckedIn: true,
                    },
                });
            })
        );

        ticketsToCheckIn.forEach(ticket => (ticket.isCheckedIn = true));
        shoppingCartStore.set.order(_order);

        setCheckingInTickets(false);
    };

    const checkedInTickets = order.productInstances.filter(p => p.isCheckedIn);
    const numCheckedInTickets = checkedInTickets.length;
    const numUncheckedInTickets = numberOfTickets - numCheckedInTickets;

    const uncheckedInTransferredTickets = order.productInstances.filter(
        ticket => !ticket.isCheckedIn && ticket.owner._id !== ticket.purchaser._id
    );
    const numUncheckedInTransferredTickets = uncheckedInTransferredTickets.length;
    const areUncheckedInTransferredTickets = numUncheckedInTransferredTickets > 0;

    const numUncheckedInNonTransferTickets =
        numUncheckedInTickets - numUncheckedInTransferredTickets;
    const areTicketsToCheckIn = numUncheckedInNonTransferTickets > 0;

    // currency the transaction was completed in
    const currency = order?.paymentIntent?.currency ?? 'USD';

    // this assumes because the payment-intent is null,
    // the order was fully-discounted
    const orderWasFullyDiscounted = order?.paymentIntent === null;

    const refunds: StripeRefund[] = order?.paymentIntent?.charges?.data?.[0]?.refunds?.data ?? [];
    const latestCharge: StripeCharge | undefined = order?.paymentIntent?.charges?.data?.[0] ?? {};
    const latestRefund: StripeRefund | undefined =
        order?.paymentIntent?.charges?.data?.[0]?.refunds?.data?.[0] ?? {};
    const orderWasRefunded: boolean =
        // check the latest charge was refunded
        latestCharge?.amount_refunded > 0 &&
        // check the latest charge refund status was successful
        (latestRefund?.status === RefundStatusEnum.Succeeded ||
            latestRefund?.status === RefundStatusEnum?.Pending);
    const orderRefundFailed = latestRefund?.status === RefundStatusEnum?.Failed;
    const orderWasFullyRefunded: boolean =
        latestCharge?.amount_refunded === order?.paymentIntent?.amount;
    const orderWasPartiallyRefunded: boolean =
        latestCharge?.amount_refunded !== order?.paymentIntent?.amount;

    const refundAmount: string = convertCentsToDollars(latestRefund?.amount);
    const refundAmountDisplayString = getCurrencyDisplayString(
        refundAmount,
        currency,
        undefined,
        true,
        2
    );

    const allowRefund =
        currentUserIsAdmin &&
        !orderWasFullyDiscounted &&
        !orderWasRefunded &&
        !orderRefundFailed &&
        (order?.paymentIntent?.status === OrderStatusEnum.Succeeded ||
            order?.subscription?.status === StripeSubscriptionStatus.Active);

    return (
        <div className="flex flex-col gap-[20px] pt-[20px]">
            <h2 className="m-0 text-[18px] font-[700] text-black">
                {t('common:global.order', 'Order')} #{order._id}
            </h2>

            <CustomerOrderDetails
                name={full_name}
                phone={phone}
                email={email}
                orderTotal={orderTotal}
                paymentType={paymentType}
                orderLocation={orderLocation}
                processedBy={processedByUserName}
                orderInstant={order?.created}
                orderWasRefunded={orderWasRefunded}
                orderRefundTotal={refundAmountDisplayString}
                refundDate={latestRefund?.created}
            />

            <div className="flex flex-col gap-[10px]">
                {order.productInstances.map((instance, index) => (
                    <ViewCustomerOrderProductInstance
                        key={index}
                        ticket={instance}
                        checkingInAllTickets={checkingInTickets}
                        toggleTicketCheckIn={async () => {
                            const { _id, isCheckedIn } = instance;
                            await setTicketIsCheckedIn({
                                variables: {
                                    productInstanceId: _id,
                                    isCheckedIn: !isCheckedIn,
                                },
                            });

                            // To optimistially update in Sell Tickets
                            // we need to update the ticket in the store
                            //   (Orders -> View Order updates on refetch)
                            if (
                                shoppingCartStore.get.currentStep() ===
                                SELL_TICKETS_AT_DOOR_STEPS.viewCompletedOrder
                            ) {
                                const _order = cloneDeep(order);
                                _order.productInstances.find(
                                    p => p._id === instance._id
                                ).isCheckedIn = !isCheckedIn;
                                shoppingCartStore.set.order(_order);
                            }
                        }}
                    />
                ))}
                {allowRefund && (numberOfTickets ?? 0) === 0 && (
                    <ModalLoading
                        message={t('common:global.loading_tickets', 'Loading Tickets...')}
                    />
                )}
            </div>

            <div
                className={`border-grayscale-line flex flex-col items-center gap-[20px] border-solid pt-[20px] md:pb-[60px] ${
                    allowRefund ? 'border-t-[1px]' : 'border-t-[0px]'
                }`}
            >
                {areUncheckedInTransferredTickets && (
                    <span className="text-grayscale-label text-[12px] tracking-[0.75px]">
                        {t(
                            `common:global.transferred_tickets_will_not_be_checked_in`,
                            'Transferred tickets will not be checked in'
                        )}
                    </span>
                )}

                {!orderWasRefunded && (
                    <button
                        onClick={checkInAllTickets}
                        disabled={!areTicketsToCheckIn || checkingInTickets}
                        className="bg-primary-default text-grayscale-background w-full rounded-full py-[8px] text-[16px] font-[600] leading-[175%] tracking-[0.75px] disabled:opacity-70"
                    >
                        {(!areTicketsToCheckIn || numCheckedInTickets === 0) &&
                            t(`common:global.check_in_all_tickets`, 'Check In All Tickets')}
                        {areTicketsToCheckIn &&
                            numCheckedInTickets > 0 &&
                            t(
                                `common:global.check_in_remaining_tickets`,
                                'Check In Remaining Tickets'
                            )}
                    </button>
                )}

                {allowRefund && (
                    <button
                        onClick={() =>
                            newModal(<RefundOrderModal order={order} onComplete={goBack} />)
                        }
                        className="bg-grayscale-body text-grayscale-background w-full rounded-full py-[8px] text-[16px] font-[600] leading-[175%] tracking-[0.75px] disabled:opacity-70"
                    >
                        {`${t(`common:global.refund_order`, 'Refund Order')} - ${
                            order.productInstances.length
                        } ${t(`common:global.tickets`, 'Tickets')}`}
                    </button>
                )}
                <button
                    onClick={goBack}
                    className="text-grayscale-label text-[17px] font-[600] leading-[28px] tracking-[0.75px]"
                >
                    {backTextOverride || t(`common:global.back_to_orders`, 'Back to Orders')}
                </button>
            </div>
        </div>
    );
};

export default ViewCustomerOrder;
