import React from 'react';
import { useTranslation } from 'react-i18next';

import useSellTicketsAtDoor from '../hooks/useSellTicketsAtDoor';

import OrderTotal from '@web/ui/components/card/Shopping-Cart/OrderTotal';
import Arrow from '@dsc/svgs/Arrow';

import { Group } from '@shared/welibrary-graphql/types';
import { SELL_TICKETS_AT_DOOR_STEPS } from '@stores/ShoppingCart';

type SellTicketsFooterProps = {
    group: Group;
    disableNext: boolean;
    hideAmount?: boolean;
    nextButtonTextOverride?: string;
};

const SellTicketsFooter: React.FC<SellTicketsFooterProps> = ({
    group,
    disableNext,
    hideAmount = false,
    nextButtonTextOverride,
}) => {
    const { t } = useTranslation();

    const { total, totalWithoutDiscount, goToNextStep, goBack, currentStep } =
        useSellTicketsAtDoor(group);

    return (
        <footer className="border-grayscale-line md:pb-[60px] flex flex-col gap-[30px] border-t-[1px] border-solid pt-[20px] mt-auto">
            {!hideAmount && (
                <div className="flex items-center">
                    <span className="text-grayscale-title-active text-[17px]">
                        {t(`common:global.total_amount`, 'Total Amount')}
                    </span>

                    <OrderTotal
                        group={group}
                        total={total}
                        originalPrice={totalWithoutDiscount}
                        className="discounted-total-wrap"
                        showLabel={false}
                    />
                </div>
            )}

            <div className="flex w-full gap-[20px]">
                {currentStep !== SELL_TICKETS_AT_DOOR_STEPS.selectTickets && (
                    <button
                        onClick={goBack}
                        className="text-primary-default border-grayscale-line rounded-full border-[2px] border-solid px-[11px]"
                    >
                        <Arrow direction="left" />
                    </button>
                )}

                <button
                    onClick={goToNextStep}
                    disabled={disableNext}
                    type="button"
                    className="bg-primary-default text-grayscale-background font-[600 tracking-[0.75px]] grow rounded-full py-[11px] text-[17px] font-[600] leading-[28px] disabled:opacity-70"
                >
                    {nextButtonTextOverride ?? t(`common:global.continue`, 'Continue')}
                </button>
            </div>
        </footer>
    );
};

export default SellTicketsFooter;
