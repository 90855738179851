import React from 'react';

const DocumentIcon: React.FC<{ className?: string; size?: string; strokeWidth?: string }> = ({
    className,
    size = '50',
    strokeWidth = '3',
}) => (
    <svg
        className={className}
        viewBox="0 0 30 30"
        height={size}
        width={size}
        strokeWidth={strokeWidth}
        stroke="currentcolor"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25 28H6.99902C6.73381 28 6.47945 27.8946 6.29192 27.7071C6.10438 27.5196 5.99902 27.2652 5.99902 27V5C5.99902 4.73478 6.10438 4.48043 6.29192 4.29289C6.47945 4.10536 6.73381 4 6.99902 4H19L26 11V27C26 27.1313 25.9741 27.2614 25.9239 27.3827C25.8736 27.504 25.8 27.6143 25.7071 27.7071C25.6143 27.8 25.504 27.8736 25.3827 27.9239C25.2614 27.9741 25.1313 28 25 28Z"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.5 4V11.5H26.001"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default DocumentIcon;
