import {SET_CURRENT_USER} from '@core/client/types.js';

export default function currentUser(state = {}, action = {}) {
    switch (action.type) {
        case SET_CURRENT_USER:
            // Return a new object for the createCard state, merging in the new property.
            return Object.assign({}, state, action.currentUser);
        default:
            return state;
    }
}
