// src/workflow/queries.ts
import { gql as gql2 } from "@apollo/client";

// src/workflow/fragments.ts
import { gql } from "@apollo/client";
var WORKFLOW_STATE_FRAGMENT = gql`
    fragment WorkflowStateFragment on WorkflowState {
        __typename
        _id
        subject {
            ... on Group {
                _id
            }
        }
        workflowId
        state
        createdAt
        updatedAt
    }
`;

// src/workflow/queries.ts
var GET_WORKFLOW_STATE = gql2`
    query GetWorkflowState($subjectId: String!, $subjectType: Collection!, $workflowId: String!) {
        getWorkflowState(
            subjectId: $subjectId
            subjectType: $subjectType
            workflowId: $workflowId
        ) {
            ...WorkflowStateFragment
        }
    }
    ${WORKFLOW_STATE_FRAGMENT}
`;
export {
  GET_WORKFLOW_STATE
};
