import React, { useEffect, useState, useRef } from 'react';
import { Browser } from '@capacitor/browser';

import Lightbox from 'react-image-lightbox';

import ChevronRight from '@web/ui/dsc/svgs/ChevronRight';
import ChevronLeft from '@web/ui/dsc/svgs/ChevronLeft';

import useHorizontalPages from '@components/generic/hooks/useHorizontalPages';
import {
    getNamedRefsFromArray,
    getCarouselFunctions,
} from '@helpers/navigation/horizontalNav.helpers';

import { FilestackImage } from '@core/types/Utilities';
import { AliasItem } from '@shared/welibrary-graphql/types';

type ImageSliderSlideProps = {
    disableExternalLink: boolean;
    slide: any;
    openLightbox: () => void;
};

const ImageSliderSlide = React.forwardRef<HTMLDivElement, ImageSliderSlideProps>(
    function ImageSliderSlide({ disableExternalLink, slide, openLightbox }, ref) {
        return (
            <div
                ref={ref}
                className="imageSlider--slide"
                onClick={disableExternalLink ? () => { } : openLightbox}
            >
                <img src={slide.url} alt="" />
            </div>
        );
    }
);

const ImageSlider: React.FC<{
    images?: FilestackImage[];
    aliases?: AliasItem[];
    containerClass?: string;
    disableExternalLink?: boolean;
    onClickCallback?: () => void;
    setCurrentImage?: (index: number) => void;
}> = ({
    images,
    aliases,
    containerClass = '',
    disableExternalLink = false,
    onClickCallback = undefined,
    setCurrentImage = () => { },
}) => {
        const [slideIndex, setSlideIndex] = useState<string>('0');
        const [slideOpen, setSlideOpen] = useState(false);
        const slides = images ?? aliases;

        const slideRefIds = slides?.map((_, index) => index.toString()) ?? [];

        const container = useRef<HTMLDivElement>(null);
        const refs = getNamedRefsFromArray<string, HTMLDivElement>(slideRefIds);

        const { on, scrollTo } = useHorizontalPages({ refs, options: { root: container.current } });

        const { current, isFirst, isLast, scrollToNext, scrollToPrevious } = getCarouselFunctions({
            on,
            scrollTo,
        });

        // Keep slideIndex in sync with shown slide
        useEffect(() => {
            if (current) setSlideIndex(current);
        }, [current]);

        useEffect(() => {
            const slideNumber = Number(slideIndex);

            if (!Number.isNaN(slideNumber)) setCurrentImage(Number(slideIndex));
        }, [slideIndex]);

        const nextSlide = () => scrollToNext(true, { block: 'nearest' });
        const prevSlide = () => scrollToPrevious(true, { block: 'nearest' });

        return (
            <>
                <div className="imageSlider--container">
                    {!isFirst && slideIndex !== '0' && (
                        <button
                            type="button"
                            onClick={prevSlide}
                            className="imageSlider--btn imageSlider--btn--prev"
                        >
                            <ChevronLeft className="imageSlider--btn--icon" />
                        </button>
                    )}
                    <div className={`imageSlider ${containerClass}`} ref={container}>
                        {slides?.map((slide, index) => {
                            return (
                                <ImageSliderSlide
                                    disableExternalLink={disableExternalLink}
                                    key={index}
                                    ref={refs[index.toString()]}
                                    slide={slide}
                                    openLightbox={() => {
                                        setSlideOpen(true);
                                        if (onClickCallback) onClickCallback();
                                    }}
                                />
                            );
                        })}
                    </div>

                    {!isLast && slideIndex !== ((slides?.length ?? 0) - 1).toString() && (
                        <button
                            type="button"
                            onClick={nextSlide}
                            className="imageSlider--btn imageSlider--btn--next"
                        >
                            <ChevronRight className="imageSlider--btn--icon" />
                        </button>
                    )}
                </div>

                <div className="imageSlider--dots">
                    {Array(Math.min(slides?.length ?? 1, 10))
                        .fill(0)
                        .map((_item, index) => (
                            <div
                                key={index}
                                onClick={() => scrollTo[index.toString()](true, { block: 'nearest' })}
                                className={
                                    slideIndex === index.toString()
                                        ? 'imageSlider--dot imageSlider--dot--active'
                                        : 'imageSlider--dot'
                                }
                            />
                        ))}
                </div>

                {/*
             * slideIndex is a string, but need to parseInt() for controlling slides in Lightbox with number
             */}
                {slideOpen && (
                    <Lightbox
                        mainSrc={slides[Number.parseInt(slideIndex)]?.url}
                        nextSrc={slides[Number.parseInt(slideIndex) + 1]?.url}
                        prevSrc={slides[Number.parseInt(slideIndex) - 1]?.url}
                        onCloseRequest={() => setSlideOpen(false)}
                        onMovePrevRequest={() => prevSlide()}
                        onMoveNextRequest={() => nextSlide()}
                    />
                )}
            </>
        );
    };

export default ImageSlider;
