import React from 'react';
import { useTranslation } from 'react-i18next';

import useSearchFormListCards from '@web/ui/components/search/form/hooks/useSearchFormListCards';

import SearchFormFilterTag from '@web/ui/components/search/form/SearchFormFilterTag';
import SearchInput from '@web/ui/components/search/SearchInput';
import SearchLoader from '@web/ui/components/search/SearchLoader';

import { FILTERS_META } from '@web/ui/components/search/constants';
import { CARD_TYPES } from '@core/utilities/constants/card_types.js';

import { Entity } from '@core/types/Search';
import { AliasHandle } from '@shared/welibrary-graphql/types';

type SearchFormListCardsProps = {
    title: string;
    addResult: (result: Entity) => void;
    suggestionsFilter?: Array<Partial<AliasHandle> | null>;
    types?: string[];
    allSuggestions?: Array<keyof Omit<typeof FILTERS_META, 'ALL'>>;
};

const SearchFormListCards: React.FC<SearchFormListCardsProps> = ({
    title,
    addResult,
    suggestionsFilter = [],
    types = [],
    allSuggestions = ['Groups', 'Channels'],
}) => {
    const { t } = useTranslation();

    const {
        query,
        setQuery,
        filter,
        setFilter,
        emptyResults,
        emptyState,
        areResults,
        loading,
        results,
    } = useSearchFormListCards({ addResult, suggestionsFilter, types, allSuggestions });

    const searchLoaderStyle = { position: 'relative', marginBottom: '20px' } as const;

    return (
        <section className="search-form">
            <header>{title}</header>

            <SearchInput
                isSearchOverlayOpen
                onChange={setQuery}
                value={query}
                placeholder="Search"
            />

            <ul>{results}</ul>
        </section>
    );
};

export default SearchFormListCards;
