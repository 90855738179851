import styled from 'styled-components';
import React from 'react';
import SSkeletonPulse from './SkeletonPulse';

const SSkeletonLine = styled(SSkeletonPulse)`
    width: ${props => (props.width ? props.width : '5.5em')};
    height: ${props => (props.height ? props.height : '1.2em')};
    border-radius: 5px;

    &::before {
        content: '\\00a0';
    }
`;

export default ({ width, height }) => (
    <SSkeletonLine height={height} width={width} />
);
