import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Updater } from 'use-immer';
import { z } from 'zod';

import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import GroupMessageTitleInput from '@components/messaging/messageOverlay/GroupMessageTitleInput';
import ContactsForm from '@components/messaging/messageOverlay/ContactsForm';
import AddMembersWidget from '@components/messaging/messageOverlay/AddMembersWidget';
import ComposeMessageFormActionButton from '@components/messaging/ComposeMessageFormActionButton';

import X from '@web/ui/dsc/svgs/FullSizeX';
import Arrow from '@web/ui/dsc/svgs/Arrow';
import Button from '@web/ui/dsc/forms/buttons/Button';

import timer from '@core/utilities/timer';
import { curriedStateSlice } from '@helpers/state/state.helpers';

import { ComposeMessageState } from '@core/types/Messages';
import { Maybe, MessageThread } from '@shared/welibrary-graphql/types';

const localNamespace = 'imports.wlWeb.ui.components.messaging.messageOverlay';

type ComposeMessageFormProps = {
    state: ComposeMessageState;
    setState: Updater<ComposeMessageState>;
    onSubmit: (e: any) => void;
    className?: string;
    title?: string;
    onClose?: () => void;
    thread?: Maybe<MessageThread>;
    bottomNav?: boolean;
    submitLoading?: boolean;
};

const ComposeMessageForm: React.FC<ComposeMessageFormProps> = ({
    state,
    setState,
    onSubmit,
    onClose,
    className,
    title,
    bottomNav = false,
    submitLoading,
}) => {
    const { t } = useTranslation();
    title = title || t(`common:${localNamespace}.new_conversation`, 'New Conversation');
    const isMobileWidth = window?.innerWidth < 480;
    const inlineForm = true;
    const { participants, title: displayName, thumb } = state;

    const [searchFocus, setSearchFocus] = useState(false);
    const [shrinkTop, setShrinkTop] = useState(false);

    const updateSlice = curriedStateSlice(setState);

    const [page, setPage] = useState(0);

    const handleUnmountComponent = async () => {
        await timer(300);
        onClose?.();
    };

    const formClassname = className
        ? `scroll-middle-fixed-header-footer ${className}`
        : 'scroll-middle-fixed-header-footer';

    const handleNextStep = () => {
        setPage(1);
    };

    const handlePrevStep = () => {
        setPage(0);
    };

    const handleFocus = () => {
        setSearchFocus(true);
    };

    const handleBlur = () => {
        setSearchFocus(false);
    };

    // All this display logic revolves around making these forms usable on mobile (!!)
    const backBtnClass = page === 0 ? 'back-button disabled-btn' : 'back-button';

    const enableEditSave = participants?.length > 1 && displayName?.trim() !== '';

    const actionBtnClass = enableEditSave
        ? 'primary-action-btn grow'
        : 'primary-action-btn grow disabled-btn';

    let groupMessageInputClass =
        searchFocus && isMobileWidth
            ? 'dm-group-name-input shrink-height fadeout'
            : 'dm-group-name-input fadein-delay';

    if (page === 1) {
        groupMessageInputClass = shrinkTop
            ? 'dm-group-name-input scale-down'
            : 'dm-group-name-input';
    }

    const [hideBottomNav, setHideBottomNav] = useState(false);

    const inboxHeaderClass = hideBottomNav
        ? 'inbox-msgs-header shrink-height fadeout'
        : 'inbox-msgs-header fadein';

    const handleOnSearch = (value: boolean) => {
        setHideBottomNav(value);
    };

    // This refers to the input of the GroupMessageTitleInput component
    const handleOnInput = (value: boolean) => {
        if (isMobileWidth) {
            setShrinkTop(value);
        }
    };

    const handleSubmit = e => {
        if (!submitLoading) {
            onSubmit?.(e);
        }
    };

    return (
        <ErrorBoundary>
            <form onSubmit={handleSubmit} className={formClassname}>
                <article className="message-overlay">
                    <div className="inbox-overlay-container">
                        <div className="message-overlay-block initial shown">
                            <div className={inboxHeaderClass}>
                                <div className="left-side">
                                    {!hideBottomNav && (
                                        <>
                                            <button
                                                type="button"
                                                onClick={handleUnmountComponent}
                                                className="display-text-inline round-square-btn animate-grow"
                                            >
                                                <X size={15} strokeWidth="4" />
                                            </button>
                                            <h3> {title}</h3>
                                        </>
                                    )}
                                </div>
                                <div className="right-side">
                                    {!bottomNav && (
                                        <button
                                            className={actionBtnClass}
                                            type="button"
                                            onClick={onSubmit}
                                        >
                                            {t(`common:global.verbs.save`)}
                                        </button>
                                    )}
                                </div>
                            </div>

                            {!bottomNav && (
                                <div className="edit-group-message-inline">
                                    <section className={groupMessageInputClass}>
                                        <GroupMessageTitleInput setState={setState} state={state} />
                                    </section>

                                    <AddMembersWidget
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        setState={updateSlice('participants')}
                                        state={participants}
                                    />
                                </div>
                            )}

                            {page === 0 && bottomNav && (
                                <ContactsForm
                                    onSearch={handleOnSearch}
                                    setState={updateSlice('participants')}
                                    state={participants}
                                />
                            )}

                            {page === 1 &&
                                bottomNav &&
                                state?.participants?.length > 1 &&
                                inlineForm && (
                                    <section className={groupMessageInputClass}>
                                        <GroupMessageTitleInput
                                            setState={setState}
                                            onInput={handleOnInput}
                                            state={state}
                                        />
                                    </section>
                                )}
                        </div>
                    </div>
                </article>
                {bottomNav && !hideBottomNav && (
                    <footer className="msg-form-footer noselect">
                        <Button
                            type="icon"
                            className={backBtnClass}
                            onClick={handlePrevStep}
                            disabled={false}
                        >
                            <Arrow />
                        </Button>

                        <ComposeMessageFormActionButton
                            state={state}
                            loading={submitLoading}
                            page={page}
                            handleNextStep={handleNextStep}
                            handleSubmit={onSubmit}
                        />
                    </footer>
                )}
            </form>
        </ErrorBoundary>
    );
};

export default ComposeMessageForm;
