import React from 'react';
import DragAndDrop from '@web/ui/components/generic/DragAndDrop.js';
import { client } from '@web/ui/components/generic/ReactFileStackClient';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import {
    getBackgroundImageSetForUrl,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import { config } from '@core/config/getConfig';

let fileStackConfig = config.public.filestack;
//This component replaces the default Filestack client dropper
//but still uses the clients methods which are somewhat mysterious
//does this by creating a client instance and accessing the methods from it
//perhaps maybe use the Filestack API instead?

export default class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.filestack = client.init(fileStackConfig.api_key, {
            security: null,
            cname: null,
            sessionCache: false,
        });
        this.files = [];

        //sets type of files that will be uploaded
        this.type = this.props.type;

        this.state = {
            uploadProgress: 0,
            isUploading: false,
        };
    }

    componentDidMount() {
        //create an input to use for filedialogue
        this.fileSelector = this.buildFileSelector();
    }

    buildFileSelector = () => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        //uncomment this or add some logic if you want to select multiple files
        // fileSelector.setAttribute('multiple', 'multiple');
        //what to do after you upload the file
        fileSelector.onchange = this.handleFileUpload;
        //define accepted file types

        fileSelector.setAttribute('accept', 'image/*');
        return fileSelector;
    };

    //This function triggers the open file dialogue box by programatically clicking the input element
    handleFileSelect = e => {
        e.preventDefault();
        this.fileSelector.click();
    };

    //function called after uploading file through file dialogue
    //this probably can be combined with the handledrop but
    // would have to modify the Drag and Drop component a bit
    // this works fine for now
    handleFileUpload = event => {
        let file = event.target.files[0];
        this.handleUploadPromise(file).then(res =>
            this.handleUpdateState({ [this.props.target]: res.url })
        );
    };

    //This handles the Drag and Drop functionality
    //currently setup to handle multiple files,
    //although input is restricted to one
    handleDrop = files => {
        let fileList = this.files;

        for (let i = 0; i < files.length; i++) {
            if (!files[i].name) return;
            fileList.push(files[i].name);
        }
        this.setState({ files: fileList });
        this.handleUploadPromise(files[0]).then(res =>
            this.handleUpdateState({ [this.props.target]: res.url })
        );
    };

    //update upload progress

    handleProgress = e => {
        let progress = e.totalPercent;
        if (progress < 100) {
            this.setState({
                uploadProgress: e.totalPercent,
                isUploading: true,
            });
        } else {
            this.setState({
                uploadProgress: e.totalPercent,
                isUploading: false,
            });
        }
    };

    //this uses the clients upload method to upload the file, returns a promise
    handleUploadPromise = file => {
        this.setState({ isUploading: true });
        const options = {
            onProgress: this.handleProgress,
        };

        return this.filestack.upload(file, options);
    };

    handleUpdateState = property => {
        this.props.handleUpdateState(property);
    };

    render() {
        let uploadBackgroundClass = 'current-image-container';

        if (this.props.containImage) {
            uploadBackgroundClass = 'current-image-container contain-image';
        }

        if (this.props.darkBackground && this.props.containImage) {
            uploadBackgroundClass = 'current-image-container contain-image dark-background';
        }

        let widthStyle;
        let hiddenStyle;
        if (this.props.editScreen) {
            widthStyle = { width: '100%', height: 'inherit', opacity: 0 };
            hiddenStyle = { visibility: 'hidden' };
        }

        const [src, srcSet] = getBackgroundImageSetForUrl(this.props.currentImage);

        return (
            <div className="columns-container" style={widthStyle}>
                <LoadingLine isLoading={this.state.isUploading} />
                <div className="half-col" style={widthStyle}>
                    <DragAndDrop handleDrop={this.handleDrop}>
                        <div
                            onClick={this.handleFileSelect}
                            style={widthStyle}
                            className="media-post-upload w-inline-block"
                        >
                            <img
                                style={hiddenStyle}
                                src="https://uploads-ssl.webflow.com/5bc0a4fed7e6b515ab8f687b/5c193e6ba396749ef74b50c7_icon.black.photo.svg"
                                alt=""
                                className="image-icon"
                            />
                            <div style={hiddenStyle}>Drag and drop</div>
                            <div style={hiddenStyle} className="sub-text">
                                or click to browse for a file
                            </div>
                            <div style={hiddenStyle} className="sub-text">
                                {this.state.uploadProgress}%
                            </div>
                        </div>
                    </DragAndDrop>
                </div>
                {this.props.currentImage && (
                    <div className="half-col">
                        <StyledBackgroundImage
                            className={uploadBackgroundClass}
                            src={src}
                            srcSet={srcSet}
                            style={{ backgroundSize: 'cover' }}
                        >
                            <div className="chosen-image"></div>
                            {/*
                  <div className="edit-image-buttons">
                      <a href="#" className="circle-button w-inline-block">
                          <div className="content-button-icon edit"></div>
                      </a>
                      <a href="#" className="circle-button w-inline-block">
                          <div className="content-button-icon delete"></div>
                      </a>
                  </div> */}
                        </StyledBackgroundImage>
                    </div>
                )}
            </div>
        );
    }
}
