import React, { CSSProperties, MouseEventHandler } from 'react';
import { v4 as uuidv4 } from 'uuid';

type DropdownDotsProps = {
    styleOverride?: CSSProperties;
    dotStyle?: CSSProperties;
    onClick: MouseEventHandler<HTMLButtonElement>;
    inverted?: boolean | 'nobg';
    buttonClass?: string;
    handleButton?: MouseEventHandler<HTMLButtonElement>;
    ariaLabel?: string;
    children?: React.ReactNode;
};

const DropdownDots = React.forwardRef<HTMLButtonElement, DropdownDotsProps>(function DropdownDots(
    {
        styleOverride = {},
        dotStyle,
        onClick,
        inverted,
        buttonClass,
        handleButton,
        ariaLabel,
        children,
    },
    ref
) {
    const uniqueId = uuidv4();
    const dotClass =
        buttonClass ||
        `button-reset content-button-icon dropdown-dots ${
            inverted && 'button-reset inverse-dots w-dropdown-toggle'
        }`;

    const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        e.preventDefault();

        handleButton?.(e);
        onClick?.(e);
    };

    if (!inverted) {
        return (
            <button
                type="button"
                id={uniqueId}
                ref={ref}
                onClick={handleClick}
                className={dotClass}
                aria-label={ariaLabel}
            >
                {children}
            </button>
        );
    }

    if (inverted === 'nobg') {
        return (
            <button
                type="button"
                id={uniqueId}
                ref={ref}
                onClick={handleClick}
                className={dotClass}
                aria-label={ariaLabel}
            >
                {children}
            </button>
        );
    }

    return (
        <button
            type="button"
            className="dropdown-bg-circle"
            style={styleOverride}
            ref={ref}
            id={uniqueId}
            onClick={handleClick}
        >
            <div style={dotStyle} className={dotClass} />
            {children}
        </button>
    );
});

export default DropdownDots;
