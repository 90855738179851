import React from 'react';

import _ from 'lodash';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultThumbImage,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

import { CARD_CHAPTER } from '@core/utilities/constants/card_types.js';

export default function SelectedSearchItem(props) {
    const { source, title, color, onSelect } = props;

    let _title = title;
    let _description;
    let _thumbImage;
    let _color = color;
    switch (source.__typename ? source.__typename : source.type) {
        case 'Group':
        case 'User':
            _title = source.profile ? source.profile.full_name : '';
            _description = source.profile ? source.profile.short_bio : '';
            _thumbImage = source.profile.picture
                ? source.profile.picture
                : getRandomDefaultUserImage();
            break;
        case 'ContentCard':
            let sourceRef = source.reference ? source.reference : {};
            let sourceMedia = source.media ? source.media : {};
            _title = source.title;
            _description = source.body
                ? source.body
                : _.isString(source.description)
                ? source.description.substring(0, 30)
                : '';
            if (source.type === CARD_CHAPTER) if (source.prompt) _description = source.prompt;
            _thumbImage = sourceMedia.thumb
                ? sourceMedia.thumb
                : sourceRef.coverImage
                ? sourceRef.coverImage
                : getRandomDefaultThumbImage(source.title);
            break;
        case 'Tag':
            _title = source.label ? `#${source.label}` : '';
            if (source.color) _color = source.color;
            break;
        default:
            break;
    }

    let containerStyle = {
        backgroundColor: _color ? _color : '',
    };

    const [src, srcSet] = getBackgroundImageSetForUrl(_thumbImage);

    return (
        <div
            onClick={props.handleItem}
            className="select-option-item selected w-inline-block"
            style={containerStyle}
        >
            {_thumbImage && (
                <StyledBackgroundImage
                    className="option-image"
                    src={src}
                    srcSet={srcSet}
                ></StyledBackgroundImage>
            )}
            <div>
                {_title}{' '}
                {_description && '|' && <span className="smaller-text">{_description}</span>}
            </div>
            <div className="select-option-line"></div>
        </div>
    );
}
