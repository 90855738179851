import { rawTimeZones } from '@vvo/tzdb';
import moment from 'moment-timezone';
import { uniqBy } from 'lodash';

import { Maybify } from '@core/types/Utilities';
import { EventDateState, DateAndTimeState } from '@core/types/EventGroup';
import {
    EventDates,
    EventLocationSettings,
    Group,
    UserProfile,
    User,
    TimeAndPlaceSettings,
    EventSettings,
} from '@shared/welibrary-graphql/types';

import { geoJSONPointToLatLngObject } from '@web/utilities/helpers/user/location.helpers';

import { toIsoStringIgnoreTimezone } from '@helpers/date.helpers';

export const EVENT_TYPES = {
    EVENT_GROUP: 'event-group',
    EVENT_LISTING: 'event-listing',
} as const;

export default EVENT_TYPES;

/* Timezone helpers */

type TimeAndTz = {
    time: string;
    timeZone: string;
};

export const findTzByNameRaw = (tzName: string) => {
    return (
        rawTimeZones?.find(item => item.name === tzName) ||
        rawTimeZones?.find(item => item.group.includes(tzName)) ||
        rawTimeZones?.find(item => item.name === 'America/New_York')
    );
};

export const findTzByNameSelect = (tzName: string) => {
    const tzObj = findTzByNameRaw(tzName);
    return {
        value: tzObj?.name,
        label: `(${tzObj?.abbreviation}) ${tzObj?.rawFormat}`,
    };
};

export const convertTzPreserveTime = (timeTz: TimeAndTz, newTz: string) => {
    // construct new Date object from time
    const timeCopy = new Date(timeTz?.time);

    // Get offset in hours for time input's timezone
    const currentTzOffsetHours = findTzByNameRaw(timeTz?.timeZone)?.rawOffsetInMinutes / 60;

    // Get offset in hours for new timezone
    const selectedTzOffsetHours = findTzByNameRaw(newTz)?.rawOffsetInMinutes / 60;

    // Calculate new timezone offset
    const updatedOffsetHours = currentTzOffsetHours - selectedTzOffsetHours;

    // Update time
    timeCopy.setHours(timeCopy.getHours() + updatedOffsetHours);

    // Return UTC string
    return timeCopy.toISOString();
};

export const generateSelectTimeZoneList = () => {
    const timezones = rawTimeZones;
    const formattedTz = timezones?.map(timezoneObj => {
        return {
            value: timezoneObj?.name,
            label: `(${timezoneObj?.abbreviation}) ${timezoneObj?.rawFormat}`,
        };
    });

    return formattedTz;
};

export const getClientTzSelect = (timezoneName?: string) => {
    const clientTz = timezoneName || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const clientTzObj = findTzByNameRaw(clientTz);
    return {
        value: clientTzObj?.name,
        label: `(${clientTzObj?.abbreviation}) ${clientTzObj?.rawFormat}`,
    };
};

export const getClientTzRaw = () => {
    const clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const clientTzObj = findTzByNameRaw(clientTz);
    return clientTzObj;
};

/* Date display formatting helpers */

export const getFormattedEventDates = (dates?: EventDates | null) => {
    // If these are Date objects then we need to convert them to ISO string (ignoring local timezones that were added)
    if (typeof dates?.startDate === 'object') {
        dates.startDate = toIsoStringIgnoreTimezone(dates.startDate);
    }
    if (typeof dates?.endDate === 'object') {
        dates.endDate = toIsoStringIgnoreTimezone(dates.endDate);
    }

    if (dates?.startDate !== dates?.endDate) {
        const startDate = moment.utc(dates?.startDate).format('MMM Do');
        const endDate = moment.utc(dates?.endDate).format('MMM Do YYYY');
        return `${startDate} - ${endDate}`;
    }

    return moment.utc(dates?.startDate).format('ddd MMM Do YYYY');
};

/* Used by old event listings */
export const getFormattedEventTimesEventListings = (eventSettings?: EventSettings | null) => {
    let eventStartTime = moment(eventSettings?.startTime).format('h:mm A z');
    let eventEndTime = moment(eventSettings?.endTime).format('h:mm A z');

    const eventTimezone = eventSettings?.timeZone?.name
        ? findTzByNameRaw(eventSettings?.timeZone?.name)?.abbreviation
        : '';

    if (eventSettings?.timeZone?.name) {
        eventStartTime = moment
            .tz(eventSettings?.startTime, eventSettings?.timeZone?.name)
            .format('h:mm A');

        eventEndTime = moment
            .tz(eventSettings?.endTime, eventSettings?.timeZone?.name)
            .format('h:mm A');
        if (eventStartTime !== eventEndTime) {
            return `${eventStartTime} - ${eventEndTime} ${eventTimezone}`;
        }
        return `${eventStartTime} ${eventTimezone}`;
    }

    if (eventStartTime !== eventEndTime) {
        return `${eventStartTime} - ${eventEndTime}`;
    }
    return `${eventStartTime}`;
};

export const getFormattedEventTimes = (eventSettings?: Maybify<DateAndTimeState> | null) => {
    let eventStartTime, eventEndTime, eventTimezone;

    if (eventSettings?.timeZone?.name) {
        eventTimezone = eventSettings?.timeZone?.name
            ? findTzByNameRaw(eventSettings?.timeZone?.name)?.abbreviation
            : '';

        eventStartTime = moment(eventSettings?.startTime)
            .tz(eventSettings?.timeZone?.name ?? '')
            .format('h:mm A');
        eventEndTime = moment(eventSettings?.endTime)
            .tz(eventSettings?.timeZone?.name ?? '')
            .format('h:mm A');
    } else {
        eventStartTime = moment(eventSettings?.startTime).format('h:mm A');
        eventEndTime = moment(eventSettings?.endTime).format('h:mm A');
    }

    const displayStartTime = eventSettings?.displayStartTime ?? true;
    const displayEndTime = eventSettings?.displayEndTime ?? true;

    if (!displayEndTime && !displayStartTime) {
        return 'All Day';
    }

    if (!displayStartTime && eventEndTime) {
        return `Until ${eventEndTime} ${eventTimezone}`;
    }

    if (!displayEndTime && eventStartTime) {
        return `Starting ${eventStartTime} ${eventTimezone}`;
    }

    if (eventStartTime !== eventEndTime && eventSettings?.timeZone?.name) {
        return `${eventStartTime} - ${eventEndTime} ${eventTimezone}`;
    }

    if (eventStartTime !== eventEndTime && !eventSettings?.timeZone?.name) {
        return `${eventStartTime} - ${eventEndTime}`;
    }

    return eventTimezone ? `${eventStartTime} ${eventTimezone}` : `${eventStartTime}`;
};

/* string helpers */

/* Replaces the date portion of ISO-8601 date string with date portion from another ISO-8601 date string */
export const replaceDateInISOString = (isoStringOne: string, isoStringTwo: string) => {
    const splitIsoStringOne = isoStringOne?.split('T');
    const splitIsoStringTwo = isoStringTwo?.split('T');
    return `${splitIsoStringTwo[0]}T${splitIsoStringOne[1]}`;
};

// Check if is a valid date object
export const isValidDate = (date: any) => {
    return date && Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date);
};

export const getEarliestDay = (
    dates: Array<{ startDate: string; endDate: string }>,
    overviewDate: string
) =>
    dates.reduce((earliest, date) => {
        const dateDay = new Date(date.startDate!);

        return dateDay < earliest ? dateDay : earliest;
    }, new Date(overviewDate));

export const getLatestDay = (
    dates: Array<{ startDate: string; endDate: string }>,
    overviewDate: string
) =>
    dates.reduce((latest, date) => {
        const dateDay = new Date(date.endDate!);

        return dateDay > latest ? dateDay : latest;
    }, new Date(overviewDate));

export const getOverviewTime = (startTime: any, startDate = new Date()): string => {
    if (!isValidDate(startTime)) return startDate.toISOString();

    startTime.setFullYear(startDate.getFullYear());
    startTime.setMonth(startDate.getMonth());
    startTime.setDate(startDate.getDate());

    return startTime.toISOString();
};

export const getUTCDate = date => {
    const d = new Date(date);
    const utcDate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
    return utcDate;
};

// Convert ISO-8601 date string to date format so the Date-Range picker can use it

export const ISOStringsToDatesSelect = (dates: EventDateState[]) => {
    return dates?.map(date => {
        return {
            startDate: getUTCDate(date?.startDate),
            endDate: getUTCDate(date?.endDate),
            key: 'selection',
        };
    });
};

type EventDate = {
    startDate: Date;
    endDate: Date;
};

export const DatesToISOStrings = (dates: EventDate[]) => {
    return dates?.map(date => {
        return {
            startDate: date?.startDate?.toISOString(),
            endDate: date?.endDate?.toISOString(),
        };
    });
};

/* get formatted dates for the group info card */

type DatesObj = {
    startDate: string;
    endDate: string;
};

type DateDisplayInput = {
    dates: DatesObj[];
    startTime: string;
    endTime: string;
    displayStartTime?: boolean;
    displayEndTime?: boolean;
    timeZone?: {
        name?: string;
    };
};

export const getFormattedDateDisplayFromTimes = (dateDisplayInput?: DateDisplayInput) => {
    if (!dateDisplayInput) return null;

    const { startTime } = dateDisplayInput;
    const startDate = dateDisplayInput?.dates?.[0]?.startDate;
    const endDate = dateDisplayInput?.dates?.[0]?.endDate;

    const timeZone = findTzByNameRaw(dateDisplayInput?.timeZone?.name || '');

    if (startDate !== endDate) {
        return `${moment(startDate).utc().format('ddd MMM D')} - ${moment(endDate)
            .utc()
            .format('MMM D, YYYY')}`;
    }

    let formattedDateTime = `${moment(startDate).utc().format('ddd MMMM Do, YYYY')}`;

    if (dateDisplayInput?.displayStartTime) {
        formattedDateTime = `${formattedDateTime} at ${moment
            .tz(startTime, dateDisplayInput?.timeZone?.name || '')
            .format('h:mm A')} ${timeZone?.abbreviation}`;
    }

    return `${formattedDateTime}`;
};

/* Get formatted top and bottom date display for the mini calendars */

type datesObj = {
    startDate: string;
    endDate: string;
};

type TinyCalendarDateInput = {
    dates: datesObj[];
    startTime: string;
    endTime: string;
};
export const getTinyCalendarFormattedDates = (eventOverviewDetails: TinyCalendarDateInput) => {
    if (!eventOverviewDetails) {
        return {
            topDateDisplay: null,
            bottomDateDisplay: null,
            isMultipleDates: false,
        };
    }

    let topDateDisplay;
    let bottomDateDisplay;
    let date;
    let isMultipleDates;

    const datesObj = eventOverviewDetails?.dates?.[0];
    const { startTime, endTime } = eventOverviewDetails;

    if (datesObj?.startDate === datesObj?.endDate) {
        date = datesObj?.startDate;
    }

    topDateDisplay = moment(date).utc().format('MMM');
    bottomDateDisplay = moment(date).utc().format('DD');

    const multipleDatesExist = datesObj && datesObj?.startDate !== datesObj?.endDate;

    if (multipleDatesExist) {
        const { startDate, endDate } = datesObj;
        bottomDateDisplay =
            multipleDatesExist &&
            `${moment(startDate).utc().format('DD')} - ${moment(endDate).utc().format('DD')}`;

        const dateMonthStart = moment(startDate).utc().format('MMM');
        const dateMonthEnd = moment(endDate).utc().format('MMM');
        const dateMonthSame = dateMonthStart === dateMonthEnd;

        topDateDisplay = dateMonthStart;

        if (!dateMonthSame) {
            topDateDisplay = `${dateMonthStart} - ${dateMonthEnd}`;
        }
    }

    return {
        topDateDisplay,
        bottomDateDisplay,
        isMultipleDates: multipleDatesExist,
    };
};

/* location and venue formatting helpers */

export const getUsefulLocData = (eventLocationSettings: EventLocationSettings) => {
    const { latitude: locLatitude, longitude: locLongitude } =
        geoJSONPointToLatLngObject(eventLocationSettings?.venue?.location?.location) ?? {};
    const locType = eventLocationSettings?.type;
    const googleMapsLink = `http://maps.google.com/maps?z=12&t=m&q=loc:${locLatitude}+${locLongitude}`;
    const venueName = eventLocationSettings?.venue?.name;
    const virtualLink = eventLocationSettings?.link;
    const formattedAddress = eventLocationSettings?.venue?.location?.formatted_address;
    const venueLink = eventLocationSettings?.venue?.link;
    const virtualPlatformName = eventLocationSettings?.platformName;

    return {
        locType,
        locLongitude,
        locLatitude,
        googleMapsLink,
        venueName,
        venueLink,
        virtualLink,
        formattedAddress,
        virtualPlatformName,
    };
};

/* Parses through all sessions and matches them with associated speaker, 
returns an array of objects that contain the user/speaker and their associated sessions */

type SpeakerWithSessions = {
    user: Maybify<User>;
    sessions: TimeAndPlaceSettings[];
};

export const constructSpeakersWithSessions: (group: Maybify<Group>) => SpeakerWithSessions[] = (
    group: Maybify<Group>
) => {
    const allSessions = group?.timeAndPlaceSettings;
    const allSpeakers = group?.eventOverviewDetails?.speakerSettings?.aliases;

    const allSpeakersWithSessions = allSpeakers?.map(speaker => {
        const allSessionsForSpeaker = allSessions?.filter(session => {
            const sessionSpeakers = session?.speakerSettings?.aliases;
            return sessionSpeakers?.find(sessionSpeaker => sessionSpeaker?.url === speaker?.url);
        });

        return {
            user: speaker?.user,
            sessions: allSessionsForSpeaker,
        };
    });

    const deDupedAllSpeakersSessions = uniqBy(allSpeakersWithSessions, speaker => speaker.user._id);

    return deDupedAllSpeakersSessions;
};

export const getFormattedUserDisplayInfo = (profile: Maybify<UserProfile>) => {
    const { website, organization, linkedInProfile, city, country, location } = profile;

    const hasLocationInfo =
        city || country || location?.city || location?.country || location?.state;

    let userLocationText;

    if (hasLocationInfo) {
        const userLocationArr = [
            city || location?.city,
            location?.state,
            location?.country || 'USA',
        ];

        // this will return location text formatted as Charleston, South Carolina, USA
        // if one of the values is null it will be filtered out, so if missing city
        // and has state and country it will return South Carolina, USA
        userLocationText = userLocationArr?.filter(Boolean).join(', ');
    }

    if (!hasLocationInfo && (website || linkedInProfile)) {
        if (website && !linkedInProfile) userLocationText = website;
        if (linkedInProfile && !website) userLocationText = linkedInProfile;
    }

    if (organization?.name && userLocationText) {
        return `${organization?.name} • ${userLocationText}`;
    }

    if (organization?.name && !userLocationText) {
        return `${organization?.name}`;
    }

    if (!organization?.name && userLocationText) {
        return `${userLocationText}`;
    }

    return null;
};
