import React, { FormEventHandler } from 'react';

import { useUpdateCardMutation } from '@shared/welibrary-graphql/content_card/mutations.hook';

import useModal from '@web/ui/components/modals/hooks/useModal';
import useCreationFormState from '@web/ui/components/content/newpost/forms/Creation/hooks/useCreationFormState';

import ModalLoading from '@web/ui/components/modals/ModalLoading';
import CreationForm from '@web/ui/components/content/newpost/forms/Creation/CreationForm';

import { ContentCard } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

type UpdateCreationFormProps = {
    hasAdminRole?: boolean;
    card: Partial<ContentCard>;
};

const UpdateCreationForm: React.FC<UpdateCreationFormProps> = ({ hasAdminRole, card }) => {
    const [state, setState] = useCreationFormState(card);

    const { newModal, closeAllModals } = useModal();

    const [updateCard] = useUpdateCardMutation();

    const onSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault();
        const {
            main: { title, description, thumbnail, coverImage, course, levels, silhouetteImage },
            settings: { rankOrder, displayInNewsfeed },
        } = state;

        if (!course || !course.url || !levels) return;

        const updatePayload = {
            cardID: card?._id,
            title,
            description,
            mediaIcon: thumbnail.image,
            mediaThumb: coverImage.image,
            // If number is removed from rankOrder, set it to null
            rankOrder: rankOrder === false ? null : rankOrder,
            displayInNewsfeed,
            creationItems: {
                courseUrl: course.url,
                silhouetteImage: silhouetteImage.image,
                levels: levels?.map(level => ({ ...level, image: level.image.image })),
            },
        };

        // Get inherited setting, in this case assumes it will be the chapter for a 'normal' card
        const inheritedSettings = card?.reference?.chapterSource?.library_settings;

        if (inheritedSettings) {
            try {
                Object.entries(inheritedSettings).forEach(([key, value]) => {
                    // This removes the property from the update payload if it matches the inherited settings
                    if (updatePayload[key] && value === updatePayload[key])
                        delete updatePayload[key];
                });
            } catch (error) {
                logger.error('error updating card', e);
            }
        }

        updateCard({ variables: updatePayload })
            .then(closeAllModals)
            .catch(error => logger?.error('update creation error', error));

        newModal(<ModalLoading message="Updating Creation" />, { widen: true, addShadow: true });
    };

    return (
        <CreationForm
            state={state}
            setState={setState}
            onSubmit={onSubmit}
            hasAdminRole={hasAdminRole}
            title="Edit Creation"
            buttonText="Update"
        />
    );
};

export default UpdateCreationForm;
