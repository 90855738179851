import React from 'react';

import DropdownController from '@components/generic/dropdownmenu/DropdownController';
import Ticket from '@dsc/svgs/Ticket';

import PRODUCT_TYPES from '@components/content/newpost/forms/ShoppingCartProducts/constants';

import { MenuListItem } from '@core/types/Menus';
import { Product } from '@shared/welibrary-graphql/types';
import { getCurrencyDisplayString } from '~/wl-web/utilities/helpers/money/money.helpers';

const TicketQuickView: React.FC<{
    ticket: Partial<Product>;
    index?: number;
    deleteTicket: (index: number) => void;
    handleEditTicketModal: (index: number) => void;
    disableEditMode?: boolean;
    ticketCurrency?: string;
    isInvalidTicket?: boolean;
    invalidTickets?: Partial<Product>[] | [];
}> = ({
    ticket,
    index,
    deleteTicket,
    handleEditTicketModal,
    disableEditMode = false,
    ticketCurrency,
    invalidTickets = [],
}) => {
    const menuList: MenuListItem[] = [
        {
            id: 1,
            title: 'Edit',
            iconName: 'Edit',
            onClick: () => handleEditTicketModal(index),
        },
        {
            id: 2,
            title: 'Delete',
            iconName: 'Delete',
            onClick: () => deleteTicket(index),
        },
    ];

    const ticketPrice = Number(ticket.price).toFixed(2);
    const ticketDisplayString = getCurrencyDisplayString(
        ticketPrice,
        ticketCurrency ?? ticket?.currency,
        undefined,
        true,
        2
    );

    const isFreeTicketType = ticket.type === PRODUCT_TYPES.GROUP_FREE_MEMBERSHIP;

    const invalidTicket = invalidTickets.find(t => {
        return t?.id === ticket?.id || (t?.name === ticket?.name && t?.price === ticket?.price);
    });

    const isInvalidTicket =
        invalidTicket?.name === ticket?.name && invalidTicket?.price === ticket?.price;

    return (
        <div
            className={`ticket-item-container ${
                isFreeTicketType ? 'ticket-item-container-free-type' : ''
            } ${isInvalidTicket ? 'border-red-300 border-[1px] border-solid' : ''}`}
        >
            <div className="ticket-quick-view">
                <div className="ticket-quick-view-details-wrap">
                    <div className="ticket-quick-view-img-wrap">
                        <Ticket strokeColor="#fff" />
                    </div>
                    <div className="ticket-quick-view-info-wrap">
                        <h3 className="ticket-quick-view-info-name">{ticket.name}</h3>
                        <p className="ticket-quick-view-info-price">{ticketDisplayString}</p>
                    </div>
                </div>
                {/* ! temporary fix until subscriptions are updating correctly */}
                {!disableEditMode && !isFreeTicketType && (
                    <div className="ticket-quick-view-edit-btn">
                        <DropdownController menuList={menuList} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TicketQuickView;
