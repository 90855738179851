import React from 'react';

import GroupedSearchCard from '@components/search/searchcard/GroupedSearchCard';
import SearchCardContainer from '@components/search/searchcard/SearchCardContainer';
import { FILTER_TYPES, getFilterType } from '@components/search/constants';

import { Maybify } from '@core/types/Utilities';
import { SearchResult } from '@shared/welibrary-graphql/types';

type GroupedCardContainerProps = {
    searchResults: Maybify<SearchResult[]>;
    handleCloseSearch: () => void;
};

/** Component displaying multiple types of search filter types in one view */
const GroupedCardContainer: React.FC<GroupedCardContainerProps> = ({
    searchResults,
    handleCloseSearch,
}) => {
    const userArray: Maybify<SearchResult[]> = [],
        groupArray: Maybify<SearchResult[]> = [],
        collectionArray: Maybify<SearchResult[]> = [],
        postArray: Maybify<SearchResult[]> = [],
        promptArray: Maybify<SearchResult[]> = [],
        commentArray: Maybify<SearchResult[]> = [],
        messageArray: Maybify<SearchResult[]> = [];

    const MAX_NUMBER_TO_SHOW = 3; // show a max of X per type
    const sortSearchResults = () => {
        searchResults.forEach(searchResult => {
            const filterType = getFilterType(searchResult);
            if (filterType === FILTER_TYPES.USER && userArray.length < MAX_NUMBER_TO_SHOW) {
                userArray.push(searchResult);
            }
            if (filterType === FILTER_TYPES.GROUP && groupArray.length < MAX_NUMBER_TO_SHOW) {
                groupArray.push(searchResult);
            }
            if (
                filterType === FILTER_TYPES.COLLECTION &&
                collectionArray.length < MAX_NUMBER_TO_SHOW
            ) {
                collectionArray.push(searchResult);
            }
            if (filterType === FILTER_TYPES.POST && postArray.length < MAX_NUMBER_TO_SHOW) {
                postArray.push(searchResult);
            }
            if (filterType === FILTER_TYPES.PROMPT && promptArray.length < MAX_NUMBER_TO_SHOW) {
                promptArray.push(searchResult);
            }
            if (filterType === FILTER_TYPES.MESSAGE && messageArray.length < MAX_NUMBER_TO_SHOW) {
                messageArray.push(searchResult);
            }
            if (filterType === FILTER_TYPES.COMMENT && commentArray.length < MAX_NUMBER_TO_SHOW) {
                commentArray.push(searchResult);
            }
        });
    };

    sortSearchResults();

    const renderUserArray = userArray?.map((searchItem, index) => {
        return (
            <SearchCardContainer
                handleCloseSearch={handleCloseSearch}
                currentTab={[FILTER_TYPES.USER]}
                source={searchItem}
                key={index}
            />
        );
    });

    const renderGroupArray = groupArray
        ?.filter(item => item?.type !== 'CONTENTCARD') // filter out group event cards
        .map((searchItem, index) => {
            return (
                <SearchCardContainer
                    handleCloseSearch={handleCloseSearch}
                    currentTab={[FILTER_TYPES.GROUP]}
                    source={searchItem}
                    key={index}
                />
            );
        });

    const renderCollectionArray = collectionArray?.map((searchItem, index) => {
        return (
            <SearchCardContainer
                handleCloseSearch={handleCloseSearch}
                currentTab={[FILTER_TYPES.COLLECTION]}
                source={searchItem}
                key={index}
            />
        );
    });

    const renderPostArray = postArray?.map((searchItem, index) => {
        return (
            <SearchCardContainer
                handleCloseSearch={handleCloseSearch}
                currentTab={[FILTER_TYPES.POST]}
                source={searchItem}
                key={index}
            />
        );
    });

    const renderPromptArray = promptArray?.map((searchItem, index) => {
        return (
            <SearchCardContainer
                handleCloseSearch={handleCloseSearch}
                currentTab={[FILTER_TYPES.PROMPT]}
                source={searchItem}
                key={index}
            />
        );
    });

    const renderMessageArray = messageArray?.map((searchItem, index) => {
        return (
            <SearchCardContainer
                handleCloseSearch={handleCloseSearch}
                currentTab={[FILTER_TYPES.MESSAGE]}
                source={searchItem}
                key={index}
            />
        );
    });

    const renderCommentArray = commentArray?.map((searchItem, index) => {
        return (
            <SearchCardContainer
                handleCloseSearch={handleCloseSearch}
                currentTab={[FILTER_TYPES.COMMENT]}
                source={searchItem}
                key={index}
            />
        );
    });

    const userData = {
        type: FILTER_TYPES.USER,
        renderData: renderUserArray,
    };
    const groupData = {
        type: FILTER_TYPES.GROUP,
        renderData: renderGroupArray,
    };

    const collectionData = {
        type: FILTER_TYPES.COLLECTION,
        renderData: renderCollectionArray,
    };

    const postData = {
        type: FILTER_TYPES.POST,
        renderData: renderPostArray,
    };

    const promptData = {
        type: FILTER_TYPES.PROMPT,
        renderData: renderPromptArray,
    };

    const messageData = {
        type: FILTER_TYPES.MESSAGE,
        renderData: renderMessageArray,
    };

    const commentData = {
        type: FILTER_TYPES.COMMENT,
        renderData: renderCommentArray,
    };

    const groupedArray = [
        userData,
        groupData,
        collectionData,
        postData,
        promptData,
        messageData,
        commentData,
    ];

    const renderGroupedArray = groupedArray?.map((data, index) => {
        if (data.renderData.length > 0) {
            return <GroupedSearchCard type={data.type} renderArray={data.renderData} key={index} />;
        }
        return null;
    });

    return <div>{renderGroupedArray}</div>;
};

export default GroupedCardContainer;
