import React from 'react';
import {useTranslation} from 'react-i18next';
import { awsMetricPublisher } from '@welibraryos/metrics';

/** Map of sort values to their translation keys. To add a sort option, add it to this object */
const sortLabels = {
    recentFirst: 'common:global.sort.recent',
    az: 'common:global.sort.az',
};

/** @type React.FC<{ onChange: React.ChangeEventHandler<HTMLSelectElement>, selected: keyof sortLabels }> */
const GroupNavigationSortSelect = ({ onChange, selected }) => {
    const { t } = useTranslation();

    const sortOptions = Object.keys(sortLabels).map(key => (
        <option key={key} value={key}>
            {t(sortLabels[key])}
        </option>
    ));

    return (
        <section className="scroll-groups-filter-picker" onClick={e => e.stopPropagation()}>
            <span role="presentation">{t(sortLabels[selected])}</span>
            <span role="presentation" className="caret">
                &#x25BE;
            </span>
            <select
                aria-label="Group Sort Select"
                onChange={onChange}
                onClick={e => {
                    awsMetricPublisher.publishCount('secondary-navigation', 1, [
                        { Name: 'groups-menu', Value: 'sort-groups' },
                    ]);
                    e.stopPropagation();
                }}
                value={selected}
            >
                {sortOptions}
            </select>
        </section>
    );
};

export default GroupNavigationSortSelect;
