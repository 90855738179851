import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import shoppingCartStore from '@stores/ShoppingCart';
import { useGetOrdersForGroupQuery } from '@shared/welibrary-graphql/user/queries.hook';

import ErrorBoundary from '@components/generic/errors/ErrorBoundary';
import TextWithLineBehind from '@pages/new-signup/TextWithLineBehind';
import CheckIn from './CheckIn';

import { Group } from '@shared/welibrary-graphql/types';
import ManageEventOrdersTab from './ManageEventOrdersTab';
import SellTickets from './SellTickets';

const TABS_ENUM = {
    checkIn: 'Check In',
    sellTickets: 'Sell Tickets',
    orders: 'Orders',
} as const;
type TabType = (typeof TABS_ENUM)[keyof typeof TABS_ENUM];

type ManageEventTicketOrdersProps = {
    group: Group;
};

const ManageEventTicketOrders: React.FC<ManageEventTicketOrdersProps> = ({ group }) => {
    const { t } = useTranslation();
    const [searchString, setSearchString] = useState('');

    const { loading: ordersLoading, data } = useGetOrdersForGroupQuery({
        variables: {
            groupId: group?._id,
            statuses: ['succeeded', 'canceled'],
        },
        skip: !group,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        // reset sell tickets state when opening this modal for the first time
        shoppingCartStore.set.resetSellTickets(group?._id);
    }, []);

    const orders = data?.getOrdersForGroup ?? [];

    const tabs: Record<TabType, { buttonText: string; component: React.ReactNode }> = {
        [TABS_ENUM.checkIn]: {
            buttonText: t(`common:global.check-in`, 'Check-In'),
            component: (
                <CheckIn
                    group={group}
                    orders={orders}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    ordersLoading={ordersLoading}
                />
            ),
        },
        [TABS_ENUM.sellTickets]: {
            buttonText: t(`common:global.sell_tickets`, 'Sell Tickets'),
            component: <SellTickets group={group} />,
        },
        [TABS_ENUM.orders]: {
            buttonText: t(`common:global.orders`, 'Orders'),
            component: <ManageEventOrdersTab orders={orders} group={group} />,
        },
    };

    const [currentTab, setCurrentTab] = useState<TabType>(TABS_ENUM.checkIn);

    return (
        <ErrorBoundary>
            <section className="flex h-full flex-col gap-[20px] px-[20px] py-[10px] md:px-[40px] md:py-[60px]">
                <h1 className="text-grayscale-title-active mb-[10px] mt-0 text-center text-[32px] font-[500]">
                    {t(`common:global.manage_ticket_orders`, 'Manage Ticket Orders')}
                </h1>

                <div className="bg-grayscale-input-background flex rounded-[10px]">
                    {Object.keys(tabs).map((tabKey, index) => {
                        const isActive = currentTab === tabKey;
                        return (
                            <button
                                key={index}
                                role="button"
                                className={`grow rounded-[10px] py-[10px] font-[600] ${isActive
                                        ? 'bg-grayscale-body text-white'
                                        : 'text-grayscale-body'
                                    }`}
                                onClick={() => {
                                    if (currentTab === TABS_ENUM.sellTickets) {
                                        // reset Sell Tickets when switching tabs
                                        shoppingCartStore.set.resetSellTickets(group._id);
                                    }
                                    setCurrentTab(tabKey);
                                }}
                            >
                                {tabs[tabKey].buttonText}
                            </button>
                        );
                    })}
                </div>

                <TextWithLineBehind />

                {tabs[currentTab].component}
            </section>
        </ErrorBoundary>
    );
};

export default ManageEventTicketOrders;
