import React from 'react';

const BriefCaseIcon: React.FC<{ className?: string; size?: string; strokeWidth?: string }> = ({
    className,
    size = '50',
    strokeWidth = '3',
}) => (
    <svg
        className={className}
        viewBox="0 0 50 50"
        height={size}
        width={size}
        strokeWidth={strokeWidth}
        stroke="currentcolor"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M42.189 13.2812H7.81403C6.95108 13.2812 6.25153 13.9808 6.25153 14.8438V39.8438C6.25153 40.7067 6.95108 41.4062 7.81403 41.4062H42.189C43.052 41.4062 43.7515 40.7067 43.7515 39.8438V14.8438C43.7515 13.9808 43.052 13.2812 42.189 13.2812Z"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M32.8125 13.2812V9.375C32.8125 8.5462 32.4833 7.75134 31.8972 7.16529C31.3112 6.57924 30.5163 6.25 29.6875 6.25H20.3125C19.4837 6.25 18.6888 6.57924 18.1028 7.16529C17.5167 7.75134 17.1875 8.5462 17.1875 9.375V13.2812"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M43.7516 23.8885C38.0529 27.1855 31.5837 28.9166 25 28.9063C18.4175 28.9166 11.9493 27.1861 6.25128 23.8902"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.6562 21.875H27.3438"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default BriefCaseIcon;
