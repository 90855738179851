import React from 'react';

const Group: React.FC<{ className?: string }> = ({ className = '' }) => (
    <svg stroke="currentcolor" fill="currentcolor" className={className} viewBox="0 0 500 500">
        <path d="M474.77,459.87a20,20,0,0,0,19.8-22.41,179.68,179.68,0,0,0-23.4-68.78c-.78-1.33-1.56-2.66-2.38-4a181.61,181.61,0,0,0-45.06-49.19,161.21,161.21,0,0,0,30.14-38.12,158.8,158.8,0,0,0,20.64-78.29c0-3.21-.1-6.41-.28-9.59A157.67,157.67,0,0,0,427.92,86.73,160.63,160.63,0,0,0,396.06,62,159.07,159.07,0,0,0,156.79,189.56c-.18,3.16-.29,6.33-.29,9.54a159.49,159.49,0,0,0,50.74,116.38,181.5,181.5,0,0,0-45.08,49.22c-.82,1.31-1.6,2.64-2.38,4a179.65,179.65,0,0,0-23.39,68.79,20,20,0,0,0,19.8,22.41h0A19.9,19.9,0,0,0,176,442.5,140.33,140.33,0,0,1,250.87,335a20.36,20.36,0,0,0,10.51-13.09,20,20,0,0,0-8.79-21.71A119.08,119.08,0,0,1,315.5,80a121.46,121.46,0,0,1,16,1.07A119.07,119.07,0,0,1,378.8,300a20.55,20.55,0,0,0-9.54,14,20,20,0,0,0,10.53,20.93A140.4,140.4,0,0,1,455,442.5a19.9,19.9,0,0,0,19.78,17.37Z" />
        <path d="M156.35,322a29.15,29.15,0,0,0,2.23-41.1,117.62,117.62,0,0,1-27.5-100.71,88.81,88.81,0,0,0-70.2,161.1A102.64,102.64,0,0,0,5.27,432.5h0a27.37,27.37,0,0,0,27.37,27.37H83.31a27.37,27.37,0,0,0,27.37-27.37v-8.9A135.6,135.6,0,0,1,156.35,322Z" />
    </svg>
);

export default Group;
