import { useSearchOrganizationsQuery } from '@shared/welibrary-graphql/organization/queries.hook';
import { useAddOrganizationMutation } from '@shared/welibrary-graphql/organization/mutations.hook';

import useModal from '@web/ui/components/modals/hooks/useModal';
import useDebouncedState from '@web/ui/components/generic/hooks/useDebouncedState';

import { Organization } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

const useOrganizationPicker = (onOrganizationSelect: (organization: Organization) => void) => {
    const [searchInput, actualSearchInput, setSearchInput] = useDebouncedState('');
    const { closeModal } = useModal();

    const { data, loading } = useSearchOrganizationsQuery({
        variables: { searchQuery: actualSearchInput },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const [addOrganizationMutation] = useAddOrganizationMutation();

    const organizations = data?.searchOrganizations;

    const selectOrganization = (organization: Organization) => {
        onOrganizationSelect(organization);
        closeModal();
    };

    const addOrganization = () => {
        if (!searchInput) return;

        addOrganizationMutation({ variables: { input: { name: searchInput } } })
            .then(({ data: newOrgData }) => {
                if (newOrgData?.addOrganization) selectOrganization(newOrgData.addOrganization);
                else logger.error('No new organization data', newOrgData);
            })
            .catch(error => logger.error(error));
    };

    return {
        searchInput,
        actualSearchInput,
        setSearchInput,
        loading,
        organizations,
        selectOrganization,
        addOrganization,
    };
};

export default useOrganizationPicker;
