import React from 'react';
import {useTranslation} from 'react-i18next';

import Tag from '@web/ui/dsc/display/badges/Tag';

import {FILTERS_META} from '@web/ui/components/search/constants';

import {SetState} from '@core/types/Utilities';

type SearchFormFilterTagProps = {
    filter: keyof typeof FILTERS_META;
    setFilter: SetState<keyof typeof FILTERS_META>;
    type: keyof typeof FILTERS_META;
};

const SearchFormFilterTag: React.FC<SearchFormFilterTagProps> = ({ filter, setFilter, type }) => {
    const { t } = useTranslation();

    const Icon = FILTERS_META[type].icon;

    return (
        <Tag
            value={filter === type}
            onChange={() => setFilter(type)}
            icon={<Icon />}
            text={t(FILTERS_META[type].text)}
        />
    );
};

export default SearchFormFilterTag;
