import React from 'react';
import {useTranslation} from 'react-i18next';

import Postbox from '@web/ui/components/content/newpost/Postbox';
import {
    getBackgroundImageSetForUrl,
    getRandomDefaultUserImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

const localNamespace = 'imports.wlWeb.ui.components.content.newPost.newPostContainer';

const PostboxPreview = ({ source, currentUser }) => {
    const { t } = useTranslation();

    let profilePhotoUrl;
    if (currentUser?.profile) {
        profilePhotoUrl = currentUser?.profile?.picture;
    }
    const [src, srcSet] = getBackgroundImageSetForUrl(
        profilePhotoUrl || getRandomDefaultUserImage(),
        75
    );

    const defaultPromptText = t(`common:${localNamespace}.share_something`);
    // Set prompt title, if there isn't a user input, use the one from chapter, if that is null
    // then use the default
    if (source.prompt === null) source.prompt = defaultPromptText;
    const promptTitle = source?.title !== '' ? source.title : source?.prompt;

    return (
        <div className="mc-post-wrapper" style={{ marginTop: '20px' }}>
            <div style={{ width: '100%' }}>
                <div />
                <Postbox
                    displaySocialHeaders
                    defaultPromptText={promptTitle}
                    profilePhotoUrl={profilePhotoUrl}
                />
                <div className="new-post-container">
                    <div
                        className="new-post-top preview"
                        style={{ paddingBottom: '0px', minHeight: '20px' }}
                    >
                        <StyledBackgroundImage
                            className="thumbnail new-post"
                            src={src}
                            srcSet={srcSet}
                        >
                            <div className="square-spacer" />
                        </StyledBackgroundImage>
                        <div
                            className="new-post-field-container w-form"
                            style={{ display: 'flex' }}
                        >
                            <span style={{ paddingTop: '5px', paddingLeft: '10px' }}>
                                {promptTitle}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PostboxPreview;
