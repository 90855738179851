import {useState} from 'react';
import {GET_WORKFLOW_STATE} from '@shared/welibrary-graphql/workflow/queries';
import {SET_WORKFLOW_STATE} from '@shared/welibrary-graphql/workflow/mutations';
import {useLazyQuery, useMutation} from '@apollo/client';
import _ from 'lodash';

/**
 * Hook to encapsulate various checklist related functionality
 * @param {string} subjectId -  unique subjectId, e.g. groupId UWU777237
 * @param {string} subjectType - Workflow subject type, e.g. GROUP, USER, CONTENTCARD etc
 * @param {string} workflowId - Unique id to identify workflow e.g. 'grow-group'
 */
const useLazyWorkflow = () => {
    const [
        setWorkflowState,
        { data, loading: setWorkflowLoading, error: setWorkflowError },
    ] = useMutation(SET_WORKFLOW_STATE);

    const emptyQuery = {
        subjectId: null,
        subjectType: null,
        workflowId: null,
    };

    const [workflowQuery, setWorkflowQuery] = useState(emptyQuery);

    const [
        fetchWorkflowData,
        {
            networkStatus: workflowNetworkStatus,
            fetchMore,
            refetch: refetchWorkflow,
            data: workflowData,
            loading: getWorkflowLoading,
            error: getWorkflowErr,
        },
    ] = useLazyQuery(GET_WORKFLOW_STATE, {
        variables: {
            subjectId: workflowQuery.subjectId,
            subjectType: workflowQuery.subjectType,
            workflowId: workflowQuery.workflowId,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    /**
     * Fetch a workflow
     * @param {string} subjectId - e.g. groupId
     * @param {string} subjectType - e.g. GROUP
     * @param {string} workflowId - e.g. 'grow-group'
     * @return null
     */
    const fetchWorkflow = async (subjectId, subjectType, workflowId) => {
        setWorkflowQuery({
            subjectId,
            subjectType,
            workflowId,
        });

        const _variables = {
            subjectId,
            subjectType,
            workflowId,
        };
        fetchWorkflowData({ variables: _variables });
        // seems like uselazyQuery doesn't return anything....apollo docs seem to indicate it returns void
    };

    // helper function
    const isEmpty = object => {
        return _.isEmpty(object) || !Object.values(object).some(x => !_.isEmpty(x));
    };

    /**
     * Use to set individual fields on the workflow state.
     * This assumes the state is being stored as an array of objects.
     * Does not work if state is different data structure...
     * @param {string} id - unique id of particular object
     * @param {string} key - key on object to update
     * @param {value} any - value to assign
     * @param {addFallback} string - if not found, add to state instead, string indicates type
     * @return null
     */
    const updateWorkflowState = (id, key, value, addFallback = false) => {
        if (isEmpty(workflowQuery) && isEmpty(emptyQuery)) return null;
        const workflowClone = _.cloneDeep(workflowData?.getWorkflowState);
        const workflowState = workflowClone?.state;
        const foundItem = workflowState.find(obj => {
            return obj.id === id;
        });

        if (!foundItem && !addFallback) {
            return null;
        }
        if (!foundItem && addFallback) {
            let itemPayload;
            switch (addFallback) {
                case 'CHAPTER':
                    itemPayload = {
                        type: 'CHAPTER',
                        complete: true,
                        id,
                    };
                    addWorkflowStateItems(itemPayload);
                    break;
                default:
                    return;
            }
            return;
        }

        foundItem[key] = value;

        // construct payload object
        const payload = {
            subjectId: workflowQuery.subjectId,
            subjectType: workflowQuery.subjectType,
            workflowId: workflowQuery.workflowId,
            state: workflowState,
        };

        setWorkflow(payload);
    };

    /**
     * Use to remove an item to the workflow state
     * @param {string} type = item id
     */
    const deleteWorkflowStateItem = id => {
        if (!workflowData) return;
        const workflowStateClone = _.cloneDeep(workflowData?.getWorkflowState?.state);
        const updatedState = workflowStateClone?.filter(item => {
            return item?.id !== id;
        });

        // construct payload object
        const payload = {
            subjectId: workflowQuery.subjectId,
            subjectType: workflowQuery.subjectType,
            workflowId: workflowQuery.workflowId,
            state: updatedState,
        };
        setWorkflow(payload);
    };

    /**
     * Use to add an item to the workflow state
     * @param {any} stateItems - array or object
     */
    const addWorkflowStateItems = stateItems => {
        if (isEmpty(workflowQuery) && isEmpty(emptyQuery)) return null;
        const workflowStateClone = _.cloneDeep(workflowData?.getWorkflowState?.state);
        let updatedState;
        if (Array.isArray(stateItems)) {
            updatedState = [...workflowStateClone, ...stateItems];
        } else {
            updatedState = [...workflowStateClone, stateItems];
        }

        // construct payload object
        const payload = {
            subjectId: workflowQuery.subjectId,
            subjectType: workflowQuery.subjectType,
            workflowId: workflowQuery.workflowId,
            state: updatedState,
        };

        setWorkflow(payload);
    };

    /**
     * Use to replace entire workflow state
     * @param {object} payload - Payload object corresponding to WorkflowStateInput
     */
    const setWorkflow = async payload => {
        setWorkflowState({
            variables: {
                input: { ...payload },
            },
        })
            .then(res => res)
            .catch(e => {
                return `Could not set workflow: ${e}`;
            });
    };

    return {
        fetchWorkflow,
        addWorkflowStateItems,
        getWorkflowLoading,
        workflowData,
        setWorkflow,
        updateWorkflowState,
        deleteWorkflowStateItem,
    };
};

export default useLazyWorkflow;
