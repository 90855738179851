/** @prettier */

import React from 'react';

import TagItem from '@web/ui/components/tags/TagItem';

const FilterTagsList = ({ filterTagsList, handleClick, activeFilter, activeTagFilters }) => {
    const filters = activeTagFilters
        ? filterTagsList.filter(tag => !activeTagFilters.includes(tag))
        : filterTagsList;

    return (
        <div className="filter-tag-list">
            {filters?.map(tag => (
                <TagItem
                    activeFilter={activeFilter}
                    handleClick={handleClick}
                    key={tag._id}
                    tag={tag}
                />
            ))}
        </div>
    );
};

export default FilterTagsList;
