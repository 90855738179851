import {
    GET_USER_CUSTOM_FIELDS,
    GET_USER_CUSTOM_FIELDS_ONLY,
    GET_RECOMMENDATIONS_FOR_USER,
    GET_GROUP_BY_ID,
} from '@shared/welibrary-graphql/user/queries';
import { useUpdateUserMutation } from '@shared/welibrary-graphql/user/mutations.hook';
import { config } from '@core/config/getConfig';
import getLogger from '@core/logger';
import useInfiniteScroll from '@components/generic/hooks/useInfiniteScroll';
import { useQuery } from '@apollo/client';
const logger = getLogger(module);

/**
 * @typedef {Object} ValuesObject
 * @property {string} _id
 * @property {Array.<string | string[]>} values
 * @property {string} type
 * @property {string} comparator
 */

/**
 * @typedef {Object} CustomFieldsObject
 * @property {{ _id: string }} parentGroup
 * @property {string} manager
 * @property {ValuesObject[]} values
 */

/**
 * @typedef {Object} ProfileObject
 * @property {string} full_name
 * @property {string} picture
 * @property {string} short_bio
 */

/**
 * @typedef {Object} CustomSignupFieldsObject
 * @property {string} customText
 * @property {string} defaultComparator
 * @property {string} id
 * @property {number} index
 * @property {string[]} options
 * @property {string} prompt
 * @property {boolean} selectMultiple
 * @property {string} siblingId
 * @property {string} type
 */

/**
 * @typedef {Object} GroupObject
 * @property {boolean} currentUserIsAdmin
 * @property {boolean} currentUserIsMember
 * @property {ProfileObject} profile
 * @property {{ recommendations: boolean, customSignupFields: CustomSignupFieldsObject[] }} settings
 * @property {string} _id
 *

/**
 * React Hook for getting a user's optimizer data
 * This will give you all the data/logic you need to display and update all of
 * the optimizers of groups that a user has joined
 *
 * @param {string} userId ID of the user to get optimizer data for
 * @param {() => void} afterUpdate
 * @param {boolean} whether to retrieve groups. Necessary if you're retrieving the survey questions/answers for another user.
 *                    (because you'll hit a permissions error if you try to retrieve another user's groups)
 */
const useUserOptimizers = (userId: string, afterUpdate?: () => void, getGroups: boolean = true) => {
    //const getQuery = getGroups ? useGetUserCustomFieldsQuery : useGetUserCustomFieldsOnlyQuery;
    const getQuery = getGroups ? GET_USER_CUSTOM_FIELDS : GET_USER_CUSTOM_FIELDS_ONLY;

    //custom pagination function since data shape is not as expected
    const nextPage = () => {
        if (data?.user?.groups?.hasMore && !queryLoading) {
            return queryFetchMore({
                variables: {
                    cursor: data?.user?.groups?.cursor,
                },
            });
        }
    };

    const {
        queryResult: { data, error: queryError, loading: queryLoading, fetchMore: queryFetchMore },
        setRef,
    } = useInfiniteScroll({
        query: {
            query: getQuery,
            options: {
                variables: {
                    _id: userId,
                    limit: 30,
                    recommendationsOn: true,
                    hasSurveyQuestions: true,
                },
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                skip: !userId,
            },
        },
        customNextPage: nextPage,
    });

    const [updateUser, { loading: mutationLoading, error: mutationError }] =
        useUpdateUserMutation();

    /**
     * All groups the user has joined that have recommendations on
     * @type {GroupObject[]}
     */
    const groups = getGroups ? data?.user?.groups.results : [];

    // Get root group from groups array from root group id defined in config

    const rootGroupId = config?.public?.rootGroupId;

    const {
        loading,
        error,
        data: rootGroupQueryData,
    } = useQuery(GET_GROUP_BY_ID, {
        variables: {
            _id: rootGroupId,
        },
        skip: !rootGroupId,
    });

    // band aid for now, proper solution later - basically this solves situation where home group is not returned in initial results
    // homegroupsids in the config can be an array but in actual use it's only ever 2 groups
    const homeGroupIds = config?.public?.homeGroups?.groupIds?.filter(
        groupId => groupId !== rootGroupId
    );
    const homeGroupId = homeGroupIds?.[0];
    const { data: homeGroupQueryData } = useQuery(GET_GROUP_BY_ID, {
        variables: {
            _id: homeGroupId,
        },
        skip: !homeGroupId,
    });

    const rootGroup =
        groups?.find(group => group._id === rootGroupId) || rootGroupQueryData?.groupById;
    const homeGroup =
        groups?.find(group => group._id === homeGroupId) || homeGroupQueryData?.groupById;

    /**
     * Platform-level card data, including form spec
     * TODO: Need to incorporate into Optimizer work! Various group assumptions are made in optimizer
     * that need to be stripped out in support of general customfields.
     */
    const platform = data?.getPlatform;

    /**
     * Updates the user's custom fields with the given payload
     *
     * @param {CustomFieldsObject} payload
     */
    const updateQuery = async payload => {
        await updateUser({
            variables: { _id: userId, customFields: [payload] },
            refetchQueries: [
                GET_USER_CUSTOM_FIELDS,
                GET_USER_CUSTOM_FIELDS_ONLY,
                GET_RECOMMENDATIONS_FOR_USER,
            ],
        })
            .then(() => afterUpdate?.())
            .catch(error => logger.warn(error));
    };

    return {
        /** @type {{ customFields: CustomFieldsObject[] }} */
        profile: data?.user?.profile,
        groups,
        rootGroup,
        homeGroup,
        platform,
        nextPage,
        setRef,
        updateQuery,
        loading: (queryLoading && 'query') || mutationLoading,
        error: queryError ?? mutationError ?? undefined,
    };
};

export default useUserOptimizers;
