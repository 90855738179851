import { useGroupByIdQuery, useGroupByShortCodeQuery } from '@shared/welibrary-graphql/user/queries.hook';

export const useGroupById = (id: string) => {
    const { data, loading, error, refetch } = useGroupByIdQuery({
        variables: { _id: id },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !id,
    });

    return { group: data?.groupById, loading, error, refetch };
};

export const useGroupByShortCode = (shortCode: string) => {
    const { data, loading, error, refetch } = useGroupByShortCodeQuery({
        variables: { shortCode },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !shortCode,
    });

    return { group: data?.groupByShortCode, loading, error, refetch };
};

export default useGroupById;
