import React from 'react';

import useModal from '@components/modals/hooks/useModal';

import { ModalTypes } from '@core/types/Modals';

import Prompt from '@components/modals/Prompt';

/**
 * React hook for showing a prompt dialog for
 *
 * Use like so:
 *
 * const prompt = usePrompt();
 *
 * ** example 1 (pass in a callback function)
 * const handlePrompt(promptInput: string) => {...do something with the promptInput...}
 *
 * const promptSomething = () => {
 *     prompt("Prompt something?", handlePrompt, () => {});
 * };
 *
 * --------------------------------------------------------------
 *
 * ** example 2 (get the prompt value)
 * const promptSomething = () => {
 *      const prompt = await prompt("Prompt something?");
 *      if(prompt) {...do something}
 * }
 *
 * return (
 *     <button type="button" onClick={promptSomething}>Prompt User</button>
 * );
 */

export type PromptOptions = {
    /**
     * Whether or not validation should be enforced
     * on the prompts input field (**is an answer required before submitting**)
     */
    validate?: boolean;

    /**
     * default placeholder for the input field
     */
    inputPlaceholder: string;

    /**
     * default value for the input field
     */
    defaultInputValue: string;
};

const usePrompt = ({ widen = false, className = '' } = {}) => {
    const { newModal } = useModal({ desktop: ModalTypes.Center, mobile: ModalTypes.Center });

    const prompt = (
        text: string,
        onConfirm?: (promptInput: string) => void,
        onCancel?: () => void,
        options: PromptOptions = {
            validate: false,
            inputPlaceholder: '',
            defaultInputValue: '',
        }
    ) => {
        return new Promise<string>(resolve => {
            const _onConfirm = (promptInput: string) => {
                resolve(promptInput);
                onConfirm?.(promptInput);
            };

            const _onCancel = () => {
                resolve('');
                onCancel?.();
            };

            newModal(
                <Prompt
                    text={text}
                    onConfirm={_onConfirm}
                    onCancel={_onCancel}
                    options={options}
                    className={className}
                />,
                {
                    hideButton: true,
                    onClose: _onCancel,
                    className: 'confirmation-modal',
                    sectionClassName: 'prompt-modal-section',
                    widen,
                    addShadow: widen,
                }
            );
        });
    };

    return prompt;
};

export default usePrompt;
