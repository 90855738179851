import React from 'react';

import useModal from '@components/modals/hooks/useModal';

import ImagePicker from '@components/content/ImagePicker';
import Picture from '@web/ui/components/generic/Picture';

import Pencil from '@web/ui/dsc/svgs/Pencil';

import { SetState } from '@core/types/Utilities';

type ImageUploadWithPickerProps = {
    state: string;
    setState: SetState<string>;
    className?: string;
    loaderSize?: number;
    lottieData?: any;
    small?: boolean;
};

const ImageUploadWithPicker: React.FC<ImageUploadWithPickerProps> = ({
    state,
    setState,
    className = '',
}) => {
    const { newModal } = useModal();

    const handleFileSelect = () =>
        newModal(<ImagePicker onImageSelect={setState} />, {
            className: 'image-picker-modal no-bottom-padding full-height',
        });

    return (
        <section className={`image-upload ${className}`}>
            <Picture url={state} disableLink>
                <button type="button" onClick={handleFileSelect} aria-label="Upload New Image">
                    <Pencil />
                </button>
            </Picture>
        </section>
    );
};

export default ImageUploadWithPicker;
