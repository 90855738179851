import { Group, User } from '@shared/welibrary-graphql/types';
import React from 'react';
import Checkbox from '@dsc/forms/customInputs/Checkbox';

type EventGroupGuestProps = {
    group: Group;
    guest: User;
    isSelected: boolean;
    toggleSelected: () => void;
    showInvites: boolean;
};

const EventGroupGuest: React.FC<EventGroupGuestProps> = ({
    group,
    guest,
    isSelected,
    toggleSelected,
    showInvites,
}) => {
    return (
        <li className="flex flex-row p-4 gap-[20px] items-center">
            {showInvites && (
                <div>
                    <Checkbox
                        className="!rounded-full !w-[28px] !h-[28px]"
                        value={isSelected}
                        onChange={() => toggleSelected()}
                    />
                </div>
            )}
            <div className="flex flex-col">
                <div className="text-[1.125rem]">{guest.profile.full_name}</div>
                <div className="text-[var(--color-grayscale-label)]">{guest.profile.email}</div>
            </div>
        </li>
    );
};

export default EventGroupGuest;
