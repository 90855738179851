import React, { useState } from 'react';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary.js';
import _ from 'lodash';

const DarkAdminTabs = ({ defaultTab, tabs }) => {
    const { id: defaultTabID = '' } = _.find(tabs, { id: defaultTab });
    const [currentTab, setCurrentTab] = useState(defaultTabID);

    let activeTabStyle = 'w--current';
    let renderedTab = currentTab ? _.find(tabs, { id: currentTab }).render() : null;
    let tabButtons = _.map(tabs, ({ id, label }) => (
        <div
            key={id}
            onClick={() => setCurrentTab(id)}
            className={`edit-screen-tab w-inline-block w-tab-link ${
                currentTab === id ? activeTabStyle : ''
            }`}
        >
            <div>{label}</div>
        </div>
    ));

    return (
        <div className="edit-screen-content-container">
            <div data-duration-in="300" data-duration-out="100" className="w-tabs">
                <div className="edit-screen-tabs-menu w-tab-menu">
                    <ErrorBoundary>{tabButtons}</ErrorBoundary>
                </div>

                <div className="w-tab-content dark-admin-tabs">
                    <ErrorBoundary>{renderedTab}</ErrorBoundary>
                </div>
            </div>
        </div>
    );
};

export default DarkAdminTabs;
