import React from 'react';
import { useTranslation } from 'react-i18next';
import EventTypes from '@core/utilities/constants/events';
import { MenuListItem } from '@core/types/Menus';
import { useCurrentUser } from '@stores/User';

import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';
import useModal from '@components/modals/hooks/useModal';
import DropdownController from '@components/generic/dropdownmenu/DropdownController';
import GroupCreateChannel from '@components/group/groupdashboard/GroupCreateChannel';
import GroupCreateSubgroup from '@components/group/CreateGroupViaTemplate';
import CreateEventGroupForm from '@components/content/newpost/forms/EventGroup/CreateEventGroupForm';
import CreateEventListingForm from '@components/content/newpost/event/CreateEventListingForm';

import {
    ALL_TYPES,
    CARD_CHAPTER,
    CARD_MOBILIZATION,
    CARD_PLEDGE,
    CARD_VOLUNTEER_MOBILIZATION,
    CARD_SUBTYPE_DISTINCT_EVENT,
} from '@core/utilities/constants/card_types.js';

import { Group } from '@shared/welibrary-graphql/types';
import { ModalTypes } from '@core/types/Modals';

const localNamespace = 'imports.wlWeb.ui.components.group.groupDashboard.plus_button';

const GroupPlusButton: React.FC<{
    group: Group;
    handleAnnouncementPostBox: () => void;
    handleMobilizationForm: (formType: string) => void;
    className: string;
}> = ({ group, handleAnnouncementPostBox, handleMobilizationForm, className }) => {
    const { t } = useTranslation();

    const { newModal } = useModal();

    const { currentUser } = useCurrentUser();

    const { channelsBook, shelf, currentUserIsAdmin } = group;

    const canCreateAllTypes = currentUser?.canCreate?.includes(ALL_TYPES) ?? false;
    const canCreateChapter =
        (currentUser?.canCreate?.includes(CARD_CHAPTER) ?? false) || canCreateAllTypes;

    const menuList: MenuListItem[] = [];

    if (shelf?.url) {
        menuList.push({
            id: 1,
            title: t(`common:${localNamespace}.send_announcement`),
            iconKey: 'Announcement',
            onClick: handleAnnouncementPostBox,
        });
    }

    if (currentUserIsAdmin) {
        menuList.push({
            id: 3,
            title: t(`common:${localNamespace}.create_subgroup`),
            iconKey: 'Plus',
            onClick: () => newModal(<GroupCreateSubgroup createSubgroup parentId={group?._id} />),
            onClose: () => false,
        });

        if (channelsBook && canCreateChapter) {
            menuList.push(
                {
                    id: 2,
                    title: t(`common:${localNamespace}.create_channel`),
                    iconKey: 'Channels',
                    onClick: () =>
                        newModal(<GroupCreateChannel bookURL={group?.channelsBook?.url ?? ''} />),
                    onClose: () => false,
                },
                {
                    id: 4,
                    title: t(`common:${localNamespace}.create_job_post`),
                    iconKey: 'Job',
                    onClick: () => handleMobilizationForm(CARD_MOBILIZATION),
                },
                {
                    id: 5,
                    title: t(`common:${localNamespace}.create_volunteer_post`),
                    iconKey: 'Volunteer',
                    onClick: () => handleMobilizationForm(CARD_VOLUNTEER_MOBILIZATION),
                },
                {
                    id: 6,
                    title: t(`common:${localNamespace}.create_action_campaign`),
                    iconKey: 'Rocket',
                    onClick: () => handleMobilizationForm(CARD_PLEDGE),
                },
                {
                    id: 7,
                    title: t(
                        `common:${localNamespace}.create_event_listing`,
                        'Create Event Listing'
                    ),
                    iconKey: 'Calendar',
                    onClick: () => {
                        const distinctChannelDestination = {
                            groupId: group?._id,
                            subtype: CARD_SUBTYPE_DISTINCT_EVENT,
                        };
                        // TODO why is this not working - the UPDATE_GET_GROUP_NEWSFEED
                        // cache update function upon reading the cache is always empty for some reason
                        // const updateCacheAfterSubmit = (cache, data) =>
                        //     UPDATE_GET_GROUP_DASHBOARD_NEWSFEED(cache, data?.data?.attachedCard, {
                        //         groupId: group?._id,
                        //         subtype: dashfeedType,
                        //     });
                        newModal(
                            <CreateEventListingForm
                                group={group}
                                distinctChannelDestination={distinctChannelDestination}
                            />,
                            {
                                hideButton: true,
                                className: 'center-fixed-header-footer',
                                confirmClose: 'Are you sure you want to cancel without saving?',
                            },
                            { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
                        );
                    },
                }
            );
        }
    }

    const { eventTypes } = getWhitelabelPublicSettings();

    if (eventTypes?.includes(EventTypes.EVENT_GROUP)) {
        menuList.push({
            id: 8,
            title: t(`common:${localNamespace}.create_event_group`, 'Create Event Group'),
            iconKey: 'Calendar',
            onClick: () => {
                newModal(
                    <CreateEventGroupForm parentId={group?._id} />,
                    {
                        hideButton: true,
                        className: 'center-fixed-header-footer',
                        confirmClose: 'Are you sure you want to cancel without saving?',
                    },
                    { mobile: ModalTypes.FullScreen, desktop: ModalTypes.FullScreen }
                );
            },
        });
    }
    if (menuList?.length === 0) {
        return <></>;
    }
    return (
        <DropdownController
            menuList={menuList}
            buttonClass={`primary-nav-action-button group-dashboard-menu ${className}`}
        />
    );
};
export default GroupPlusButton;