import React from 'react';
import { useTranslation } from 'react-i18next';

import Picture from '@components/generic/Picture';

import Plus from '@dsc/svgs/Plus';
import MinusCircle from '@dsc/svgs/MinusCircle';
import ArrowsLeftRight from '@dsc/svgs/ArrowsLeftRight';
import { ThickTicket } from '@dsc/svgs/Ticket';
import { RoundedCheckmark } from '@dsc/svgs/Checkmark';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-spinner.json';

import { useSetTicketIsCheckedInMutation } from '@shared/welibrary-graphql/user/mutations.hook';

import { ProductInstance } from '@shared/welibrary-graphql/types';

type UserTicketListItemProps = {
    ticket: ProductInstance;
    onCheckInToggle: () => void;
};

const UserTicketListItem: React.FC<UserTicketListItemProps> = ({ ticket, onCheckInToggle }) => {
    const { t } = useTranslation();
    const { owner, purchaser, isCheckedIn } = ticket;

    const [setTicketIsCheckedIn, { loading }] = useSetTicketIsCheckedInMutation();
    const toggleIsCheckedIn = async () => {
        await setTicketIsCheckedIn({
            variables: { productInstanceId: ticket._id, isCheckedIn: !isCheckedIn },
        });
        onCheckInToggle();
    };

    const names = owner.profile.full_name.split(' ');
    const initials = `${names[0][0]}${names?.[1]?.[0] ?? ''}`;

    const isTransfer = owner._id !== purchaser._id;

    return (
        <li className="border-grayscale-line flex gap-[15px] border-b-[1px] border-solid p-[20px] last:border-b-0">
            <div
                role="button"
                onClick={toggleIsCheckedIn}
                className={`flex w-[91px] shrink-0 flex-col items-center justify-center gap-[5px] rounded-[10px] pb-[10px] pt-[15px] ${isCheckedIn ? 'bg-grayscale-input-background' : 'bg-primary-default'
                    }`}
            >
                <div className="relative h-[60px] w-[60px] rounded-full border-[2px] border-solid border-white">
                    {owner.profile.picture && (
                        <Picture
                            url={owner.profile.picture}
                            className="!h-full !w-full overflow-hidden rounded-full"
                            disableLink
                        />
                    )}
                    {!owner.profile.picture && (
                        <div className="bg-grayscale-body flex h-full w-full items-center justify-center overflow-hidden rounded-full text-[24px] uppercase leading-[34px] tracking-[1px] text-white">
                            {initials}
                        </div>
                    )}

                    {isCheckedIn && (
                        <div className="bg-primary-default absolute right-[-7px] top-[-7px] flex h-[24px] w-[24px] items-center justify-center rounded-full border-[2px] border-solid border-white text-white">
                            <RoundedCheckmark className="mt-[2px]" />
                        </div>
                    )}
                </div>

                <div className="font-poppins flex items-center gap-[3px] text-[12px] font-[500]">
                    {loading && (
                        <LottieLoading height={15} width={15} lottieData={LottieAnimation} />
                    )}
                    {!isCheckedIn && (
                        <>
                            {!loading && (
                                <>
                                    <Plus version="2" size="15" color="white" />{' '}
                                    <span className="text-white">Check In</span>
                                </>
                            )}

                            {loading && <span className="text-white">Almost...</span>}
                        </>
                    )}
                    {isCheckedIn && (
                        <>
                            {!loading && (
                                <>
                                    <MinusCircle />{' '}
                                    <span className="text-grayscale-body">Remove</span>
                                </>
                            )}
                            {loading && <span className="text-grayscale-body">Removing...</span>}
                        </>
                    )}
                </div>
            </div>

            <div className="flex grow flex-col gap-[5px] pt-[10px]">
                <div className="flex flex-col">
                    <span className="text-grayscale-title-active text-[17px] font-[700] leading-[22px] tracking-[0.75px]">
                        {ticket.owner.profile.full_name}
                    </span>
                    <span
                        className={`text-[12px] font-[500] ${isCheckedIn ? 'text-primary-default' : 'text-error-default'
                            }`}
                    >
                        {isCheckedIn && t('common:global.checked_in', 'Checked In')}
                        {!isCheckedIn && t('common:global.not_checked_in', 'Not Checked In')}
                    </span>
                </div>
                {isTransfer && (
                    <span className="text-grayscale-body font-poppins text-[12px] font-[500]">
                        {t('common:global.transferred_by', 'Transferred by')}:{' '}
                        {purchaser.profile.full_name}
                    </span>
                )}
                {!isTransfer && owner.profile.phone && (
                    <span className="text-grayscale-body text-[12px] font-[500]">
                        {owner.profile.phone}
                    </span>
                )}
                <div className="text-grayscale-body flex items-center gap-[5px]">
                    <ThickTicket strokeColor="currentColor" />
                    {isTransfer && <ArrowsLeftRight />}
                    <span className="text-grayscale-body line-clamp-1 text-[12px] font-[600] uppercase leading-[20px]">
                        {ticket.product.name}
                    </span>
                </div>
            </div>
        </li>
    );
};

export default UserTicketListItem;
