import React from 'react';

type QuickLinkDescriptionProps = {
    title: string;
    description?: string;
    type?: string;
};

const QuickLinkDescription: React.FC<QuickLinkDescriptionProps> = ({
    title,
    description,
    type,
}) => (
    <section className="side-menu-navigator-quick-link-description">
        <h5>{type}</h5>
        <h3>{title}</h3>
        <p>{description}</p>
    </section>
);

export default QuickLinkDescription;
