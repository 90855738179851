import React, { useState } from 'react';
import moment from 'moment';

import ZeroStateImage from '@assets/LegoNoImageResultsFace.svg';
import ResourceIcon from '@components/card/coursecard/ResourceIcon';
import ToggleButton from '@components/lego/dashboard/ToggleButton';

import { isLegoBadgeImage } from '@helpers/lego/credential.helpers';
import {
    getColorForSkillOrSubskill,
    getResourceForSkill,
    SKILL_SUBTYPE,
} from '@helpers/lego/skill.helpers';

import { VerifiableCredentialInfo } from '@core/types/Credential';

type VerifiableCredentialFrontDetailsProps = {
    credential: VerifiableCredentialInfo;
};

const VerifiableCredentialFrontDetails: React.FC<VerifiableCredentialFrontDetailsProps> = ({
    credential,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [descriptionTruncated, setDescriptionTruncated] = useState(true);

    const selectedSkillsToSubskills: { [skill: string]: string[] } = {};

    const earnedResources: { skill: string; resource: any; quantity: number; color: string }[] = [];
    credential.skills.forEach(skill => {
        const subskillsForSkill = SKILL_SUBTYPE[skill];

        credential.subskills.forEach(subskill => {
            if (subskillsForSkill.includes(subskill)) {
                if (!selectedSkillsToSubskills[skill]) {
                    selectedSkillsToSubskills[skill] = [];
                }
                selectedSkillsToSubskills[skill].push(subskill);
            }
        });

        earnedResources.push({
            skill,
            resource: getResourceForSkill(skill),
            color: getColorForSkillOrSubskill(skill, false),
            quantity: 1 + (selectedSkillsToSubskills[skill]?.length ?? 0),
        });
    });

    const defaultDescriptionLength = 130;
    const truncatedDescription =
        credential.description.length > defaultDescriptionLength
            ? credential.description.substring(0, defaultDescriptionLength)
            : credential.description;

    return (
        <>
            <img
                className={`vc-image ${isLegoBadgeImage(credential.image) ? 'lego-badge' : ''}`}
                src={credential.image ?? ZeroStateImage}
                alt="badge"
            />
            {credential.description && (
                <div className="dashboard-box text-[15px] leading-[17px] tracking-[0.5px]">
                    <h2 className="text-black mt-0 mb-[10px] leading-[100%] text-[20px]">
                        Description
                    </h2>
                    {descriptionTruncated &&
                        credential.description.length > defaultDescriptionLength && (
                            <span>
                                {truncatedDescription}...{' '}
                                <span
                                    onClick={() => setDescriptionTruncated(false)}
                                    className="text-[#2F80ED]"
                                >
                                    More
                                </span>
                            </span>
                        )}
                    {(!descriptionTruncated ||
                        credential.description.length <= defaultDescriptionLength) &&
                        credential.description}
                </div>
            )}
            <div
                className="awarded-to-box bg-white mx-[20px] px-[10px] pt-[17px] shadow-lego rounded-[20px] text-black flex flex-col items-center gap-[5px] relative"
                style={{ paddingBottom: isCollapsed ? '17px' : '5px' }}
            >
                {earnedResources.length > 0 && (
                    <ToggleButton isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                )}

                {credential.issuedToUserThumb}
                <div className="flex flex-col items-center text-center">
                    <span className="text-[22px] leading[29px] tracking-[1px]">
                        Awarded to {credential.issuedToUserName}
                    </span>
                    <span className="text-[15px] leading[20px] tracking-[1px] text-[#4F4F4F]">
                        {moment().format('MMM D, YYYY')}
                    </span>
                </div>
                {earnedResources.length > 0 && (
                    <>
                        <div className={`flex ${isCollapsed ? 'mb-[10px]' : 'mb-[30px]'} `}>
                            {earnedResources.map((earnedResource, index) => (
                                <ResourceIcon
                                    key={index}
                                    type={earnedResource.resource}
                                    color={earnedResource.color}
                                    quantity={earnedResource.quantity}
                                    size="40px"
                                    className=""
                                />
                            ))}
                        </div>
                        {!isCollapsed && (
                            <div className="flex flex-col gap-[5px] w-full">
                                {earnedResources.map((earnedResource, index) => {
                                    const subskills =
                                        selectedSkillsToSubskills[earnedResource.skill] ?? [];
                                    const rows = [earnedResource.skill, ...subskills].map(
                                        (skill, rowIndex) => {
                                            return (
                                                <div
                                                    key={`row-${rowIndex}`}
                                                    className="flex items-center"
                                                >
                                                    <ResourceIcon
                                                        type={earnedResource.resource}
                                                        color={earnedResource.color}
                                                        size="40px"
                                                        subskillIcon={rowIndex > 0}
                                                    />
                                                    <span className="text-[16px] leading-[18px] ml-[10px]">
                                                        {skill}
                                                    </span>
                                                    <div className="ml-auto bg-white rounded-full text-[20px] h-[24px] w-[24px] pt-[3px] pl-[5px]">
                                                        +1
                                                    </div>
                                                </div>
                                            );
                                        }
                                    );
                                    return (
                                        <div
                                            key={index}
                                            className="flex flex-col pt-[10px] pr-[10px] pb-[10px] pl-[15px] rounded-[15px] gap-[10px]"
                                            style={{
                                                backgroundColor: `${earnedResource.color}26`,
                                            }}
                                        >
                                            {rows}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default VerifiableCredentialFrontDetails;
