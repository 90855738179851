interface DownloadCSVOptions {
    mimeType?: string; // Allows specifying a different MIME type.
    charset?: string; // Allows specifying a different character set.
}

interface DownloadCSV {
    (
        csvString: string, // The CSV data as a string.
        filename: string, // The name of the file to be downloaded.
        options?: DownloadCSVOptions // Optional configurations.
    ): void;
}

export const downloadCSV: DownloadCSV = (csvString, filename, options = {}) => {
    const { mimeType = 'text/csv', charset = 'utf-8' } = options;

    try {
        // Create a Blob from the CSV string with the provided MIME type and character set
        const blob = new Blob([csvString], { type: `${mimeType};charset=${charset};` });

        // Use URL.createObjectURL to create a link to the blob
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', filename);

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up by removing the link
        document.body.removeChild(link);
    } catch (error) {
        console.error('Failed to download CSV:', error);

        throw error;
    }
};

export default downloadCSV;
