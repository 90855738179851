import React, {
    CSSProperties,
    MouseEventHandler,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';

import useModal from '@web/ui/components/modals/hooks/useModal';

import MobileMenuList from '@web/ui/components/modals/MobileMenuList';
import DropdownDots from '@web/ui/components/generic/dropdownmenu/DropdownDots';
import OutsideClickHandler from 'react-outside-click-handler';

import { CONTENT_BUTTON_ICON_KEY } from '@core/utilities/constants/dropdowns';

import { ModalTypes } from '@core/types/Modals';
import { MenuListItem } from '@core/types/Menus';

// These need to be defined outside of the component otherwise Popper will FREAK OUT (read: infinite loop)
const setSameWidthFn = ({ state }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`;
};
const setSameWidthEffect = ({ state }) => {
    state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
};

type DropdownControllerProps = {
    dotStyle?: CSSProperties;
    menuList: MenuListItem[];
    rootElement?: string;
    buttonClass?: string;
    extraMenuClasses?: string;
    inverted?: boolean | 'nobg';
    disableMobileMenu?: boolean;
    handleButton?: MouseEventHandler<HTMLButtonElement>;
    onClickCallback?: () => void;
    ariaLabel?: string;
    noIcons?: boolean;
    menuBlockOverride?: React.ReactNode;
    menuXOffsetOverride?: number;
    menuYOffsetOverride?: number;
    dropdownMenuSameWidthAsParent?: boolean;
    children?: React.ReactNode;
};

/** Toggles a menu of items to show when clicked. Menu is a configurable list of objects.  */
const DropdownController = React.memo<DropdownControllerProps>(
    React.forwardRef<{ toggleMenu: () => void }, DropdownControllerProps>(
        function DropdownController(
            {
                dotStyle,
                menuList,
                rootElement,
                buttonClass,
                extraMenuClasses = '',
                inverted,
                disableMobileMenu,
                handleButton,
                onClickCallback = null,
                ariaLabel,
                noIcons = false,
                menuBlockOverride,
                menuXOffsetOverride,
                menuYOffsetOverride,
                dropdownMenuSameWidthAsParent = false,
                children,
            },
            ref
        ) {
            const { newModal, closeModal } = useModal({ desktop: ModalTypes.None });

            const [menu, toggleMenu] = useState(false);

            const referenceRef = useRef(null);

            // This ref needs to be stored in a useState hook to cause a rerender when the menu is
            // shown. This rerender allows popper to correctly style the position of the menu.
            // If a useRef hook is used instead, the menu will appear in the upper left corner on
            // the first click, then correctly every time after that.
            const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);

            const { styles, attributes, update } = usePopper(referenceRef.current, popperRef, {
                placement: 'bottom',
                modifiers: [
                    {
                        name: 'eventListeners',
                        options: {
                            scroll: true, // true
                            resize: true,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            rootBoundary: 'viewport',
                        },
                    },
                    {
                        name: 'offset',
                        enabled: true,
                        options: {
                            offset: [menuXOffsetOverride ?? 49, menuYOffsetOverride ?? -18],
                        },
                    },
                    {
                        name: 'sameWidth',
                        enabled: dropdownMenuSameWidthAsParent,
                        phase: 'beforeWrite',
                        requires: ['computeStyles'],
                        fn: setSameWidthFn,
                        effect: setSameWidthEffect,
                    },
                ],
            });

            const itemClicked = (item: MenuListItem) => {
                if (item.hasOwnProperty('onClick')) {
                    item.onClick?.(item.id, item.title);
                    toggleMenu(false);

                    if (window.innerWidth < 991) closeModal();
                }
            };

            const handleToggle = () => {
                // check for optional callback method from props; example useage to log metric from parent
                onClickCallback?.();

                if (window.innerWidth < 991 && !disableMobileMenu) {
                    newModal(<MobileMenuList menuList={menuList} showIcons={!noIcons} />, {
                        className: `no-min-height ${extraMenuClasses}`,
                    });
                } else {
                    toggleMenu(!menu);
                    update?.();
                }
            };

            const handleMenuOff = () => toggleMenu(false);

            // allow parents to toggle the menu by passing a ref and calling toggleMenu on that ref
            useImperativeHandle(ref, () => ({ toggleMenu: handleToggle }));

            // temporary way of handling the way some current data is structured and comes in so that it works
            const dropdownList = menuList?.map(item => {
                const iconKey = item.iconKey || item.iconName || item.title;
                const iconClass = iconKey ? CONTENT_BUTTON_ICON_KEY[iconKey] : '';

                const icon = (
                    <div
                        className={`content-button-icon item-dropdown dropdown-nav ${iconClass}`}
                    />
                );

                if (item.to) {
                    return (
                        <li key={item.id} className={item.selected ? 'selected' : ''}>
                            <Link
                                to={item.to || '#'}
                                className={`dropdown-link border-bottom w-dropdown-link item-dropdown ${
                                    noIcons ? 'center' : ''
                                }`}
                                key={item.id}
                                onClick={e => {
                                    e.stopPropagation();
                                    itemClicked(item);
                                }}
                            >
                                {!noIcons && icon}
                                <div
                                    className="display-text-inline item-dropdown"
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    {item.title}
                                </div>
                            </Link>
                        </li>
                    );
                }

                return (
                    <li key={item.id} className={item.selected ? 'selected' : ''}>
                        <button
                            type="button"
                            className={`button-reset dropdown-link border-bottom w-dropdown-link item-dropdown ${
                                noIcons ? 'center' : ''
                            }`}
                            key={item.id}
                            onClick={e => {
                                e.stopPropagation();
                                itemClicked(item);
                            }}
                        >
                            {!noIcons && icon}
                            <div
                                className="display-text-inline item-dropdown"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                {item.title}
                            </div>
                        </button>
                    </li>
                );
            });

            const defaultMenuBlock = (
                <div className="dropdown-menu">
                    <ul className="dropdown-list notification-list">{dropdownList}</ul>
                </div>
            );

            const menuBlock = menuBlockOverride || defaultMenuBlock;
            const _rootElement = rootElement || '#modal-mid-root';

            return (
                <>
                    <DropdownDots
                        dotStyle={dotStyle}
                        inverted={inverted}
                        handleButton={handleButton}
                        buttonClass={buttonClass}
                        onClick={handleToggle}
                        ariaLabel={ariaLabel}
                        ref={referenceRef}
                    >
                        {children}
                    </DropdownDots>

                    {menu &&
                        ReactDOM.createPortal(
                            <div
                                className={`popper-low ${extraMenuClasses}`}
                                ref={setPopperRef}
                                style={styles.popper}
                                {...attributes.popper} // eslint-disable-line react/jsx-props-no-spreading
                            >
                                <OutsideClickHandler onOutsideClick={handleMenuOff}>
                                    {menuBlock}
                                </OutsideClickHandler>
                            </div>,
                            document.querySelector(_rootElement) as HTMLElement
                        )}
                </>
            );
        }
    )
);

export default DropdownController;
