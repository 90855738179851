import React, { CSSProperties } from 'react';
import Remarkable from 'remarkable';

import ContentSearchCardView from '@components/search/searchcard/ContentSearchCardView';
import { displayNames } from '@components/search/constants';

import {
    constructReferenceLink,
    constructSingleCardLink,
    renderAuthorInfo,
    renderBody,
    renderSubHeaderInfo,
    renderTitle,
} from '@components/search/searchcard/components/helpers';

import { ContentCard } from '@shared/welibrary-graphql/types';
import { isKey } from '~/wl-web/utilities/helpers/types/type.helpers';

type ContentSearchCardContainerProps = {
    handleCloseSearch: () => void;
    content: ContentCard;
    md: Remarkable;
    isPromptCard: boolean;
};

const ContentSearchCardContainer: React.FC<ContentSearchCardContainerProps> = ({
    handleCloseSearch,
    content,
    md,
    isPromptCard,
}) => {
    let title = '';
    let url = '/#';
    let displayName = '';
    let cardTitle: JSX.Element | null = null;
    let cardUrl = content?.url ? constructSingleCardLink(content.url) : '/#';
    let authorInfo: JSX.Element | null = null;
    let mediaThumbUrl = null;
    let mediaThumbStyle: CSSProperties = {};
    let cardBody = null;
    let hideBody = false;

    if (isPromptCard) {
        displayName = 'Prompt';
        cardUrl = content?.reference?.href ?? '/#';
        title = content?.title ?? '';
        url = content?.reference?.href ?? '/#';
        cardTitle =
            content && content.prompt ? renderTitle(content.prompt, url, handleCloseSearch) : null;
        hideBody = true;
    }

    if (!isPromptCard) {
        authorInfo = renderAuthorInfo(content, handleCloseSearch);
        mediaThumbUrl = content?.media?.thumb;
        mediaThumbStyle = { backgroundImage: `url(${mediaThumbUrl})`, backgroundSize: 'cover' };
        cardBody = content?.body ? renderBody(content.body, md) : null;
        displayName = isKey(displayNames, content?.type) ? displayNames[content?.type] : '';
        // need to write additional logic for to check if card is chapter, so reference book
        // if book -> shelf -> library etc....
        const referenceSource = content?.reference?.chapterSource;
        const refBookSource = content?.reference?.bookSource;
        if (referenceSource) {
            url = constructReferenceLink(content.reference?.href, content.url) ?? '';
            title = refBookSource?.title ?? '';
        }
        cardTitle =
            content && content.title
                ? renderTitle(content.title, cardUrl, handleCloseSearch)
                : null;
    }

    const subHeaderInfo = renderSubHeaderInfo(displayName, title, url, handleCloseSearch);
    const hasThumb = mediaThumbUrl !== null && mediaThumbUrl !== undefined && mediaThumbUrl !== '';

    return (
        <ContentSearchCardView
            handleCloseSearch={handleCloseSearch}
            cardUrl={cardUrl}
            hideBody={hideBody}
            content={content}
            authorInfo={authorInfo}
            cardTitle={cardTitle}
            hasThumb={hasThumb}
            subHeaderInfo={subHeaderInfo}
            cardBody={cardBody}
            mediaThumbStyle={mediaThumbStyle}
        />
    );
};

export default ContentSearchCardContainer;
