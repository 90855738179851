import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { debounce, find } from 'lodash';

import { GET_RECOMMENDATIONS_FOR_USER } from '@shared/welibrary-graphql/user/queries';

import RecommendationsList from '@web/ui/components/discover/DiscoverRecommendationsList';
import ErrorBoundary from '@web/ui/components/generic/errors/ErrorBoundary.js';
import LottieLoading from '@web/ui/components/generic/loading/LottieLoading';
import LottieAnimation from '@web/ui/components/generic/loading/lotties/circle-spinner.json';

// import PreferencesIcon from '@web/ui/dsc/svgs/PreferencesIcon';
import { COMMUNITY_PRIMARY_MENU_OPTIONS } from '@core/utilities/constants/community';

import { useCurrentUser } from '@stores/User';

const localNamespace = 'imports.wlWeb.ui.components.community';

import getLogger from '@core/logger';

const logger = getLogger(module);

const DiscoverRow: React.FC<{
    discoverType: string;
    setShowLottie: Function;
    showOptimize: Function;
}> = ({ discoverType, setShowLottie, showOptimize }) => {
    const { t } = useTranslation();
      const { currentUser } = useCurrentUser();

    const PRIMARY_MENU_OPTIONS = COMMUNITY_PRIMARY_MENU_OPTIONS(t);

    const { variables, stickyLabel } =
        find(PRIMARY_MENU_OPTIONS, { url: discoverType }).recommendations || {};

    const {
        data: recsData,
        loading: recsLoading,
        fetchMore,
        networkStatus,
    } = useQuery(GET_RECOMMENDATIONS_FOR_USER, {
        variables: {
            input: {
                ...variables?.input,
                userId: currentUser?._id,
            },
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !currentUser?._id,
    });

    const recommendations =
        recsData?.recommendationsForUser?.recommendations?.recommendations || [];

    // use useState / useEffect to prevent double refetching.
    const [isFetchingMore, setIsFetchingMore] = useState(false);

    useEffect(() => {
        if (isFetchingMore) {
            // ! ----------------------------------------
            // currently when the fetchMore is triggered it only
            // returns one additional item for me
            // ie: limit = 10 & triggering fetchMore returns 10(previousResults) + 1(newItems)
            // VVVV
            // ! ----------------------------------------
            fetchMore({
                variables: {
                    input: {
                        ...variables?.input,
                        cursor: recsData?.recommendationsForUser?.recommendations?.cursor,
                        userId: currentUser?._id,
                    },
                },
            })
                .then(data => {
                    logger.debug('Fetched ✅', data);
                    setIsFetchingMore(false);
                })
                .catch(error => {
                    logger.error(error);
                    setIsFetchingMore(false);
                });
        }
    }, [isFetchingMore]);

    // Debounce onScroll to improve performance
    const onScrollDebounced = debounce(e => {
        const { scrollLeft, offsetWidth, scrollWidth } = e.target as HTMLUListElement;
        const end = scrollLeft + offsetWidth === scrollWidth;
        logger.debug(`${end === true ? 'You have reached the end ✅' : 'Keep scrolling 🚨'}`);
        if (end && recsData?.recommendationsForUser?.recommendations?.cursor && !isFetchingMore) {
            setIsFetchingMore(true);
        }
    }, 50);

    if (recommendations.length > 0) {
        setShowLottie(false);
    }

    if (recommendations.length === 0) {
        return <></>;
    }

    return (
        <ErrorBoundary>
            <section className="discover-list-header">
                <span>{stickyLabel || t(`common:${localNamespace}.matches_for_you`)}</span>
                {/* hide filter btn for now */}
                {/* <button type="button">
                    <PreferencesIcon className="" />
                </button> */}
            </section>
            <ul onScroll={onScrollDebounced}>
                {recsLoading && !recsData ? (
                    <div className="discover-modal-list-item-placeholder">
                        <LottieLoading height={120} width={120} lottieData={LottieAnimation} />
                    </div>
                ) : (
                    <RecommendationsList
                        recommendations={recommendations}
                        showOptimize={showOptimize}
                    />
                )}

                {/* This is commented out until the recommendation resolver pagination issue is fixed
                
                    {recsData?.recommendationsForUser?.recommendations?.hasMore &&
                    !recsLoading &&
                    networkStatus !== 3 && (
                        <div className="discover-modal-list-item-placeholder">
                            <LottieLoading height={120} width={120} lottieData={LottieAnimation} />
                        </div>
                    )} */}
            </ul>
        </ErrorBoundary>
    );
};

export default DiscoverRow;
