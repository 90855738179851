import React from 'react';

const WoodButton: React.FC<{
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    style?: React.CSSProperties;
}> = ({ className, onClick, style, children }) => {
    return (
        <button style={style} type="button" className={className} onClick={onClick}>
            {children}
        </button>
    );
};

export default WoodButton;
