import React from 'react';

import Slider from '@dsc/forms/customInputs/Slider';

import { Maybify } from '@core/types/Utilities';
import { SurveyQuestion } from '@shared/welibrary-graphql/types';

type SurveyNumericSliderProps = {
    state: number;
    setState: (value: number) => void;
    question: Maybify<SurveyQuestion>;
    className?: string;
    disabled?: boolean;
    viewOnly?: boolean;
};

const SurveyNumericSlider: React.FC<SurveyNumericSliderProps> = ({
    state,
    setState,
    question,
    className = '',
    disabled = false,
    viewOnly = false,
}) => {
    return (
        <section className={`survey-question-numeric-slider ${className}`}>
            {!viewOnly && <p>{question.prompt}</p>}

            <Slider
                value={state}
                onChange={setState}
                min={question.range?.[0] ?? 1}
                max={question.range?.[1] ?? 5}
                displayValue={state.toString()}
                hideTooltip
                className="slider"
                quantize
                disabled={disabled && !viewOnly}
            />
        </section>
    );
};

export default SurveyNumericSlider;
