import React from 'react';

import { ModalTypes } from '@core/types/Modals';
import { titleCase } from '@helpers/string.helpers';
import useModal from '@web/ui/components/modals/hooks/useModal';
import EditIcon from '@web/ui/dsc/svgs/EditIcon';
import { useTranslation } from 'react-i18next';

import SignatureModal from './SignatureModal';

interface SignatureProps {
    name: string;
    field: {
        prompt: string;
        required: boolean;
    };
    value?: string;
    handleCustomChange: (name: string, value: string) => void;
}

const Signature: React.FC<SignatureProps> = ({
    name,
    field: { prompt, required },
    value = '',
    handleCustomChange,
}) => {
    const { newModal, closeModal } = useModal({ mobile: ModalTypes.Center });
    const { t } = useTranslation();
    const openModal = () => {
        newModal(
            <SignatureModal
                closeModal={closeModal}
                handleCustomChange={handleCustomChange}
                name={name}
            />
        );
    };

    return (
        <div className={`custom-signup-item ${required ? 'required' : ''}`}>
            <div className="custom-signup-label">
                <span>{prompt}</span>
                {required && <span className="required"> *</span>}
            </div>
            {value ? (
                <img
                    src={value}
                    alt="signature"
                    className="block w-full cursor-pointer"
                    onClick={openModal}
                />
            ) : (
                <div
                    className="flex cursor-pointer gap-4 border border-gray-400 p-2"
                    onClick={openModal}
                >
                    <EditIcon />
                    <div>{titleCase(t('common:sign_here', 'sign here'))}</div>
                </div>
            )}
        </div>
    );
};

export default Signature;
