import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_PAGINATED_NEWSFEED } from '@shared/welibrary-graphql/content_card/queries';

import GroupNewsfeedSection from '@web/ui/components/group/groupdashboard/GroupNewsfeedSection';
import LoadingLine from '@web/ui/components/generic/loading/LoadingLine';
import useInfiniteScroll from '@web/ui/components/generic/hooks/useInfiniteScroll';
import useCardReceipts from '@web/ui/components/card/hooks/useCardReceipts';

const GroupDashboardNewsfeed = ({ groupId, whatsNewRef, onLoad, newsfeedHeaderRef }) => {
    const { t } = useTranslation();

    const {
        setRef,
        queryResult: { data, loading, error },
    } = useInfiniteScroll({
        query: {
            query: GET_PAGINATED_NEWSFEED,
            options: {
                variables: { groupId, limit: 25 },
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
            },
        },
        visibleOffset: 100,
    });

    const groupNewsfeed = data?.paginatedNewsFeed?.results;
    const whatsNewNewsfeed = groupNewsfeed?.filter(card => !card.currentUserHasRead);
    const normalNewsfeed = groupNewsfeed?.filter(card => card.currentUserHasRead);

    const groupNewsfeedHasMoreResults = data?.paginatedNewsFeed?.hasMore;

    useCardReceipts({ onScreenRef: whatsNewRef, cardIds: whatsNewNewsfeed?.map(card => card._id) });

    useEffect(() => {
        if (!loading && !error && groupNewsfeed) {
            onLoad?.(groupNewsfeed);
        }
    }, [loading, groupNewsfeed]);

    return (
        <>
            <LoadingLine isLoading={loading} error={error} />
            {whatsNewNewsfeed?.length > 0 && (
                <GroupNewsfeedSection
                    sectionTitle={t('common:global.whats_new')}
                    cards={whatsNewNewsfeed}
                    ref={whatsNewRef}
                />
            )}
            {normalNewsfeed?.length > 0 && (
                <GroupNewsfeedSection
                    sectionTitle={t('common:imports.wlWeb.ui.pages.homePage.newsfeed')}
                    cards={normalNewsfeed}
                    setRef={setRef}
                    hasMoreResults={groupNewsfeedHasMoreResults}
                    ref={newsfeedHeaderRef}
                />
            )}
        </>
    );
};

export default GroupDashboardNewsfeed;
