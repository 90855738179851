import { Product } from '@shared/welibrary-graphql/types';

export const USD = 'USD';

export const getCurrencyDisplayString = (
    amount: number,
    currency: string,
    locale?: string,
    displayRawPrices: boolean = false,
    maxFractionDigits: number = 0
) => {
    if (displayRawPrices) {
        // if locale is undefined, the Internationalization will fall back to the runtime's default locale
        return Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
            maximumFractionDigits: maxFractionDigits,
            // We store USD amounts in pennies, so we have to convert it to dollars here
        }).format(amount);
    }

    // if locale is undefined, the Internationalization will fall back to the runtime's default locale
    return Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        maximumFractionDigits: maxFractionDigits,
        // We store USD amounts in pennies, so we have to convert it to dollars here
    }).format(!!currency ? amount / 100 : amount);
};

export const getLowestTicketPrice = (
    tickets: Product[],
    displayRawPrices: boolean = false,
    currency?: string
) => {
    const lowestPricedTicket = tickets.reduce((lowestTicket, currentTicket) => {
        return currentTicket.price < lowestTicket.price ? currentTicket : lowestTicket;
    }, tickets[0]);

    let _currency;

    if (currency) _currency = currency;
    else _currency = lowestPricedTicket.currency;

    return getCurrencyDisplayString(
        lowestPricedTicket.price,
        _currency,
        undefined,
        displayRawPrices
    );
};

export const getHighestTicketPrice = (
    tickets: Product[],
    displayRawPrices: boolean = false,
    currency?: string
) => {
    const highestPricedTicket = tickets.reduce((highestTicket, currentTicket) => {
        return currentTicket.price > highestTicket.price ? currentTicket : highestTicket;
    }, tickets[0]);

    let _currency;
    
    if (currency) _currency = currency;
    else _currency = highestPricedTicket.currency;

    return getCurrencyDisplayString(
        highestPricedTicket.price,
        _currency,
        undefined,
        displayRawPrices
    );
};
