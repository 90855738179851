import {
    ALIAS_EXTERNAL_LINK,
    ALIAS_GENERATOR,
    ALIAS_GROUP,
    CARD_BOOK,
    CARD_CHAPTER,
    CARD_SHELF,
} from '@core/utilities/constants/card_types.js';

// * These helpers control defaulting images during creation / updating tile links

// * UpdateCard (inside creation & edit window)
export const generateDefaultImageOnTileCreation = item => {
    let aliasType = item?.type;

    if (item?.__typename === 'Group') {
        aliasType = ALIAS_GROUP;
    }

    switch (aliasType) {
        case ALIAS_EXTERNAL_LINK:
            return null;
        case ALIAS_GROUP:
            return item?.profile?.coverPhoto || null;
        case CARD_SHELF:
            return null;
        case CARD_BOOK:
            return (
                item?.media?.thumb ||
                item?.reference?.groupSource?.profile?.coverPhoto ||
                item?.reference?.coverImage ||
                null
            );
        case CARD_CHAPTER:
            return (
                item?.media?.thumb ||
                item?.reference?.groupSource?.profile?.coverPhoto ||
                item?.reference?.coverImage ||
                null
            );

        default:
            return null;
    }
};

// * LinkUpdateForm (Link Tile Updating - reverting back to a default image)
export const revertToDefaultImageInEditMode = (alias, aliasItem, item) => {
    const aliasType = alias?.type;

    switch (aliasType) {
        case ALIAS_GROUP:
            return aliasItem?.group?.profile?.coverPhoto || null;
        case ALIAS_EXTERNAL_LINK:
            return null;
        case CARD_BOOK:
            return (
                item?.media?.thumb ||
                item?.reference?.groupSource?.profile?.coverPhoto ||
                item?.reference?.coverImage ||
                null
            );
        case CARD_CHAPTER:
            return item?.media?.thumb || item?.reference?.coverImage || null;
        default:
            return null;
    }
};

// ------------------------------------------------------------

// disables/hides the "use default image" button (On Link Tile Creation)
export const disableDefaultingImageOnCreation = (alias, chapter, group) => {
    if (alias.type === ALIAS_GROUP && alias?.thumb === alias?.item?.profile?.coverPhoto) {
        return true;
    }

    if (
        alias?.type === CARD_BOOK &&
        (alias?.thumb === alias?.item?.media?.thumb ||
            alias?.thumb === alias?.item?.reference?.groupSource?.profile?.coverPhoto)
    ) {
        return true;
    }

    if (
        alias?.type === CARD_CHAPTER &&
        (alias?.thumb === alias?.item?.media?.thumb ||
            alias?.thumb === alias?.item?.reference?.groupSource?.profile?.coverPhoto ||
            alias?.thumb === alias?.item?.reference?.coverImage)
    ) {
        return true;
    }

    if (
        alias?.type === ALIAS_EXTERNAL_LINK &&
        (alias?.thumb === chapter?.media?.thumb ||
            alias?.thumb === group?.profile?.coverPhoto ||
            alias?.thumb === null)
    ) {
        return true;
    }

    if ((alias.type === CARD_SHELF || alias.type === ALIAS_GENERATOR) && alias.thumb === null) {
        // references for CARD_SHELF & ALIAS_GENERATOR are none existent
        // so the generate-default btn is initially hidden
        // if an image is uploaded the generate-default btn is then shown to return to the randomized image
        return true;
    }

    return false;
};

// disables/hides the "use default image" button (On Link Tile Update)
export const disableDefaultingImageInEditMode = (item, aliasItem, card, mediaThumb) => {
    if (
        aliasItem.type === ALIAS_GROUP &&
        // handles disabling default-btn on creating alias
        (mediaThumb === aliasItem?.item?.profile?.coverPhoto ||
            mediaThumb === item?.group?.profile?.coverPhoto ||
            // handles disabling default-btn on updating existing alias
            mediaThumb === item?.profile?.coverPhoto ||
            mediaThumb === aliasItem?.group?.profile?.coverPhoto)
    ) {
        return true;
    }

    if (
        aliasItem?.type === CARD_BOOK &&
        // handles disabling default-btn on updating existing alias
        (aliasItem?.thumb === aliasItem?.item?.reference?.coverImage ||
            mediaThumb === item?.item?.media?.thumb ||
            // handles disabling default-btn on creating alias
            aliasItem.thumb === item?.reference?.groupSource?.profile?.coverPhoto)
    ) {
        return true;
    }

    if (
        aliasItem?.type === CARD_CHAPTER &&
        (mediaThumb === item?.reference?.coverImage ||
            mediaThumb === item?.item?.media?.thumb ||
            mediaThumb === aliasItem?.item?.reference?.coverImage)
    ) {
        return true;
    }

    if (
        aliasItem?.type === ALIAS_EXTERNAL_LINK &&
        // uses the current card -> chapter || source images as defaults for custom links
        (aliasItem?.thumb === card?.reference?.chapterSource?.media?.thumb ||
            aliasItem?.thumb === card?.reference?.groupSource?.profile?.coverPhoto ||
            aliasItem?.thumb === null)
    ) {
        return true;
    }

    if (
        (aliasItem.type === CARD_SHELF || aliasItem.type === ALIAS_GENERATOR) &&
        aliasItem.thumb === null
    ) {
        // references for CARD_SHELF & ALIAS_GENERATOR are none existent
        // so the generate-default btn is initially hidden
        // if an image is uploaded the generate-default btn is then shown to return to the randomized image
        return true;
    }

    return false;
};
