import { TFunction } from 'i18next';

import {
    getRandomDefaultCoverImage,
    getRandomDefaultThumbImage,
    getRandomDefaultUserImage,
} from '@core/utilities/constants/defaults';
import { getAliasInputFromAlias, getInfoFromAlias } from '@helpers/aliases/alias.helpers';

import {
    CARD_CREATION,
    CARD_RAINBOW_ROAD,
    CARD_SURVEY,
} from '@core/utilities/constants/card_types';

import { AliasItemInput, ContentCard, RainbowRoadNode, RainbowRoadNodeInput } from '@shared/welibrary-graphql/types';
import { Entity } from '@core/types/Search';
import { CurrentUser } from '@core/types/User';
import { PerformantMaybify } from '@core/types/Utilities';
import { NodeInfo, RRCSSProperties } from '@core/types/RainbowRoad';

export const getImageFromNode = (node: RainbowRoadNode): string => {
    if (node.icon) return node.icon;

    const { alias } = node;

    if (alias.__typename === 'UserAliasHandle') {
        return (
            alias.user?.profile?.picture ||
            (alias.type === 'group'
                ? getRandomDefaultCoverImage(alias.user?.profile?.full_name ?? '')
                : getRandomDefaultUserImage())
        );
    }

    if (alias.__typename === 'GroupAliasHandle') {
        return (
            alias.group?.profile?.picture ||
            getRandomDefaultCoverImage(alias.group?.profile?.full_name ?? '')
        );
    }

    if (alias.__typename === 'AliasItem') {
        if (alias.type === 'chapter') {
            return (
                alias.item?.media?.icon ||
                alias.item?.reference?.bookSource?.media?.thumb ||
                getRandomDefaultCoverImage(
                    (alias?.item?.reference?.bookSource?.title || alias.item?.title) ?? ''
                )
            );
        }

        if (alias.type === CARD_RAINBOW_ROAD) {
            return alias.item?.media?.icon || getRandomDefaultThumbImage(alias.item?.title || '');
        }

        return (
            alias.item?.media?.icon ||
            alias.item?.media?.thumb ||
            getRandomDefaultThumbImage(alias.item?.title || '')
        );
    }

    return '';
};

export const getAliasInputFromEntity = (entity: Entity): AliasItemInput | false => {
    if (!entity) return false;

    if (entity.__typename === 'User') {
        return { title: entity.profile?.full_name, url: entity._id, type: 'alias_user' };
    }

    if (entity.__typename === 'ContentCard') {
        if (!entity.type || !entity.url) return false;

        return { title: entity.title, url: entity.url, type: entity.type };
    }

    if (entity.__typename === 'Group') {
        return { title: entity.profile?.full_name, url: entity._id, type: 'alias_group' };
    }

    return false;
};

export const getInfoFromNode = (
    node: PerformantMaybify<RainbowRoadNode>,
    t: TFunction,
    currentUser?: CurrentUser | null
): NodeInfo | false => {
    const info = getInfoFromAlias(node?.alias ?? {}, t, currentUser);

    if (!info) return false;

    return {
        ...info,
        customPicture: node?.icon || info.picture,
        customTitle: node?.title || info.title,
        spinnerImage: node?.spinnerImage ?? undefined,
    };
};

export const getNodeInputs = (
    nodes?: Array<PerformantMaybify<RainbowRoadNode> | null> | null
): RainbowRoadNodeInput[] => {
    return (
        nodes?.reduce<RainbowRoadNodeInput[]>((acc, node) => {
            if (node?.alias?.url && node.alias.type) {
                acc.push({
                    alias: getAliasInputFromAlias(node.alias),
                    title: node.title,
                    icon: node.icon,
                    backgroundImage: node.backgroundImage,
                    spinnerImage: node.spinnerImage,
                    showMetaInformation: node.showMetaInformation,
                    preOrPost: node.preOrPost,
                });
            }

            return acc;
        }, []) ?? []
    );
};

export const nodeIsLink = (node: PerformantMaybify<RainbowRoadNode>): boolean => {
    if (node.alias?.__typename === 'AliasItem') {
        return [CARD_SURVEY, CARD_CREATION, CARD_RAINBOW_ROAD].includes(
            node.alias.item?.type ?? ''
        );
    }

    return false;
};

// TODO: This is not exhaustive, in the future, this should be expanded to get the url from any
// valid node, not just some cards
export const getNodeUrl = (
    node: PerformantMaybify<RainbowRoadNode>,
    currentUrl: string,
    questId?: string,
    questMapId?: string
): string => {
    if (node.alias?.__typename === 'AliasItem') {
        if (node.alias.item?.type === CARD_SURVEY && node.alias.item.url) {
            return `/survey/${node.alias.item.url}?returnTo=${currentUrl}${
                questId ? `&quest=${questId}` : ''
            }${questMapId ? `&questMap=${questMapId}` : ''}`;
        }

        if (node.alias.item?.type === CARD_CREATION && node.alias.item.url) {
            return `/creation/${node.alias.item.url}?returnTo=${currentUrl}${
                questId ? `&quest=${questId}` : ''
            }${questMapId ? `&questMap=${questMapId}` : ''}`;
        }

        if (node.alias.item?.type === CARD_RAINBOW_ROAD && node.alias.item.url) {
            return `/quest/${questId ? `${questId}/` : ''}${
                node.alias.item.url
            }?returnTo=${currentUrl}`;
        }
    }

    return '/home';
};

export const getCssVars = (
    rainbowRoad?: PerformantMaybify<ContentCard> | null
): RRCSSProperties => {
    const { title, rainbowRoadSettings } = rainbowRoad ?? {};

    const cssVars: RRCSSProperties = {
        '--rr-header-color':
            (rainbowRoadSettings?.header?.darkMode && rainbowRoadSettings.header.color) ||
            'var(--color-grayscale-background)',

        '--rr-header-text-color': rainbowRoadSettings?.header?.darkMode
            ? 'var(--color-grayscale-background)'
            : rainbowRoadSettings?.header?.color ?? 'var(--color-grayscale-body)',

        '--rr-body-color':
            (rainbowRoadSettings?.body?.darkMode && rainbowRoadSettings.body.color) ||
            'var(--color-grayscale-background)',

        '--rr-body-text-color': rainbowRoadSettings?.body?.darkMode
            ? 'var(--color-grayscale-background)'
            : rainbowRoadSettings?.body?.color ?? 'var(--color-grayscale-body)',
    };

    if (typeof rainbowRoadSettings?.header?.image === 'string') {
        cssVars['--rr-header-image'] = `url(${
            rainbowRoadSettings.header.image || getRandomDefaultCoverImage(title ?? '')
        })`;
    }

    if (typeof rainbowRoadSettings?.body?.image === 'string') {
        cssVars['--rr-body-image'] = `url(${
            rainbowRoadSettings.body.image || getRandomDefaultCoverImage(title ?? '')
        })`;
    }

    return cssVars;
};
