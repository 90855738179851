import React from 'react';

const SimpleCurrency: React.FC<{ className?: string; strokeWidth?: string; strokeColor?: string }> =
    ({ className, strokeWidth = '3', strokeColor }) => {
        return (
            <svg
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                strokeWidth={strokeWidth}
                stroke="currentColor"
            >
                <path
                    d="M8 1.5V3"
                    stroke={strokeColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 13V14.5"
                    stroke={strokeColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.5 5.5C11.5 5.1717 11.4353 4.84661 11.3097 4.54329C11.1841 4.23998 10.9999 3.96438 10.7678 3.73223C10.5356 3.50009 10.26 3.31594 9.95671 3.1903C9.65339 3.06466 9.3283 3 9 3H6.75C6.08696 3 5.45107 3.26339 4.98223 3.73223C4.51339 4.20107 4.25 4.83696 4.25 5.5C4.25 6.16304 4.51339 6.79893 4.98223 7.26777C5.45107 7.73661 6.08696 8 6.75 8H9.5C10.163 8 10.7989 8.26339 11.2678 8.73223C11.7366 9.20107 12 9.83696 12 10.5C12 11.163 11.7366 11.7989 11.2678 12.2678C10.7989 12.7366 10.163 13 9.5 13H6.5C5.83696 13 5.20107 12.7366 4.73223 12.2678C4.26339 11.7989 4 11.163 4 10.5"
                    stroke={strokeColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    };

export default SimpleCurrency;
