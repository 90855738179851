import {SET_VOTE_DISPLAY_STATE_ON_CARD} from '@core/client/types.js';

export default function voting(state = {}, action = {}) {
    switch (action.type) {
        case SET_VOTE_DISPLAY_STATE_ON_CARD:
            //Default state is not open.
            let isOpen = false;

            // Toggle the open state at the id
            let votingUIItem = {};
            votingUIItem[action.id] = { voteData: action.voteData };

            // Return a new object for the createCard state, merging in the new property.
            return Object.assign({}, state, votingUIItem);
        default:
            return state;
    }
}
