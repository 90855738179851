import React from 'react';

type StylizedCheckNoBorderProps = {
    className?: string;
};

const StylizedCheckNoBorder: React.FC<StylizedCheckNoBorderProps> = ({ className = '' }) => {
    return (
        <svg viewBox="0 0 22 17" fill="none" className={className}>
            <path
                d="M0 6.40661L8.11775 17L22 0H15.0652L8.11775 9.89485L5.93611 6.06351L0 6.40661Z"
                fill="currentcolor"
            />
        </svg>
    );
};

export default StylizedCheckNoBorder;
