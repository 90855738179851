import React from 'react';
import { useTranslation } from 'react-i18next';

import FileUploadHelper from '@web/ui/components/content/FileUploadHelper';
import useFilestackPicker from '@web/ui/components/generic/hooks/useFilestackPicker';

import LinkItem from '@web/ui/components/card/mediacard/bodyitems/LinkItem.js';
import VideoItem from '@web/ui/components/card/mediacard/bodyitems/VideoItem';
import DocumentItem from '@web/ui/components/card/mediacard/bodyitems/DocumentItem';

import { getRandomDefaultCoverImage, transformExif } from '@core/utilities/constants/defaults';

import {
    CARD_BLOG,
    CARD_DOCUMENT,
    CARD_EVENT,
    CARD_IMAGE,
    CARD_IMAGE_SQUARE,
    CARD_LINK,
    CARD_TEXT,
    CARD_VIDEO,
    CARD_VOTE_GALLERY,
    CARD_STORY,
} from '@core/utilities/constants/card_types.js';
import { VIEWER_MIME_TYPES } from '@core/utilities/constants/filestack.js';
import EditSquareImageCard from '../../card/squareImagecard/EditSquareImageCard';
import EditImageCard from '../../card/imagecard/EditImageCard';

const localNamespace = 'imports.wlWeb.ui.components.generic.editScreen.editCardMediaItem';

const EditCardMediaItem = ({
    card,
    state,
    handleChange,
    handleUpdateState,
    handleImgToggle,
    cardConstraints,
}) => {
    const { t, i18n } = useTranslation();

    const openPicker = useFilestackPicker();

    const { linkConstraint, mediaThumbConstraint } = cardConstraints || {};

    let backgroundImage;
    let mediaPlayer;
    let mediaContent;
    let uploader;
    let secondaryUploader;

    const { mediaThumb, mediaIcon } = state;
    const mediaObject = {
        thumb: mediaThumb,
        icon: mediaIcon,
    };

    const clearAttachment = () => handleUpdateState({ type: CARD_TEXT });

    const chapter = card?.aliases && card.aliases[0];
    const cardMedia = card?.media;
    const cardThumb = cardMedia?.thumb;

    const titleToGetDefaultImage = chapter?.url || chapter?.prompt || chapter?.title || '';
    const chapterBackgroundImage = chapter?.item?.media?.thumb;

    switch (card.type) {
        case CARD_VIDEO:
            const mediaClass = 'video-media';
            mediaPlayer = <VideoItem showVideo={false} media={mediaObject} links={card.links} />;
            mediaContent = linkConstraint ? (
                <div className="mc-add-link-container w-form">
                    <input
                        type="text"
                        value={state.link}
                        onChange={handleChange}
                        className="text-field link-field w-input"
                        maxLength="256"
                        name="link"
                        placeholder={t(`common:${localNamespace}.media_content.add_link`)}
                        id="link"
                    />
                </div>
            ) : null;
            uploader = mediaThumbConstraint ? (
                <FileUploadHelper
                    target="mediaThumb"
                    handleUpdateState={handleUpdateState}
                    render={({ handleFileSelect }) => (
                        <div
                            onClick={handleFileSelect}
                            className="mc-media-button image-button w-inline-block"
                        >
                            <div className="display-inline-block">
                                {t(`common:${localNamespace}.edit_thumbnail`)}
                                <br />
                            </div>
                        </div>
                    )}
                />
            ) : null;
            break;
        case CARD_STORY:
        case CARD_IMAGE:
            return (
                <EditImageCard
                    aliases={state.aliases}
                    state={state}
                    handleImgToggle={handleImgToggle}
                />
            );
        case CARD_EVENT:
        case CARD_BLOG:
        case CARD_VOTE_GALLERY:
            // if user has uploaded background image, use that instead of chapter thumb
            backgroundImage =
                cardThumb ||
                chapterBackgroundImage ||
                getRandomDefaultCoverImage(titleToGetDefaultImage);

            mediaPlayer = (
                <div className="mc-media-item photoitem">
                    <img src={mediaThumb || backgroundImage} alt="" className="mc-photo" />
                </div>
            );

            uploader = mediaThumbConstraint ? (
                <FileUploadHelper
                    target="mediaThumb"
                    handleUpdateState={handleUpdateState}
                    render={({ handleFileSelect }) => (
                        <button
                            type="button"
                            onClick={handleFileSelect}
                            className="mc-media-button image-button w-inline-block"
                        >
                            <div className="display-inline-block">
                                {t(`common:${localNamespace}.edit_thumbnail`)}
                                <br />
                            </div>
                        </button>
                    )}
                />
            ) : null;
            break;
        case CARD_IMAGE_SQUARE:
            return (
                <EditSquareImageCard
                    aliases={state.aliases}
                    state={state}
                    handleImgToggle={handleImgToggle}
                />
            );
        case CARD_DOCUMENT:
            mediaPlayer = <DocumentItem media={mediaObject} title="" links={card.links} />;

            secondaryUploader = linkConstraint ? (
                <FileUploadHelper
                    target="link"
                    mimeType={VIEWER_MIME_TYPES}
                    handleUpdateState={handleUpdateState}
                    render={({ handleFileSelect }) => (
                        <div
                            onClick={handleFileSelect}
                            className="edit-document-container w-inline-block"
                        >
                            <div className="document-icon" />
                            <div className="edit-document-info">
                                <div>[{state.link}]</div>
                                {/* <div className='gray-text'>[400kb]</div> */}
                            </div>
                            <div className="edit-button" />
                        </div>
                    )}
                />
            ) : null;

            uploader = mediaThumbConstraint ? (
                <FileUploadHelper
                    target="mediaThumb"
                    handleUpdateState={handleUpdateState}
                    render={({ handleFileSelect }) => (
                        <div
                            onClick={handleFileSelect}
                            className="mc-media-button image-button w-inline-block"
                        >
                            <div className="display-inline-block">
                                {t(`common:${localNamespace}.edit_thumbnail`)}
                                <br />
                            </div>
                        </div>
                    )}
                />
            ) : null;

            // secondaryUploader = linkConstraint ? (
            //   <FileUploadHelper
            //     target={'link'}
            //     mimeType={'application/pdf'}
            //     handleUpdateState={handleUpdateState}
            //     render={({ handleFileSelect }) => (
            //       <div
            //         onClick={handleFileSelect}
            //         className='mc-media-button image-button w-inline-block'
            //       >
            //         <div className='display-inline-block'>
            //           {t(`common:${localNamespace}.media_content.add_doc`)}
            //           <br />
            //         </div>
            //       </div>
            //     )}
            //   />
            // ) : null
            break;
        case CARD_LINK:
            mediaPlayer = <LinkItem media={mediaObject} links={card.links} />;
            mediaContent = linkConstraint ? (
                <div className="mc-add-link-container w-form">
                    <input
                        type="text"
                        value={state.link}
                        onChange={handleChange}
                        className="text-field link-field w-input"
                        maxLength="256"
                        name="link"
                        placeholder={t(`common:${localNamespace}.media_content.web_link`)}
                        id="link"
                    />
                </div>
            ) : null;

            uploader = mediaThumbConstraint ? (
                <FileUploadHelper
                    target="mediaThumb"
                    handleUpdateState={handleUpdateState}
                    render={({ handleFileSelect }) => (
                        <div
                            onClick={handleFileSelect}
                            className="mc-media-button image-button w-inline-block"
                        >
                            <div className="display-inline-block">
                                {t(`common:${localNamespace}.edit_thumbnail`)}
                                <br />
                            </div>
                        </div>
                    )}
                />
            ) : null;
            break;
        default:
            return null;
    }
    let backgroundStyle;
    if (card && card.type === CARD_VOTE_GALLERY) {
        backgroundStyle = {
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
                backgroundImage
                    ? transformExif(backgroundImage)
                    : getRandomDefaultCoverImage(titleToGetDefaultImage)
            })`,
        };
    }

    backgroundStyle = {
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
            mediaThumb ? transformExif(mediaThumb) : getRandomDefaultCoverImage(card.title)
        })`,
    };

    return (
        <div className="mc-media-container">
            <div className="mc-media-item">
                <div className="mc-edit-media-container" style={backgroundStyle}>
                    <div className="edit-media-content-container">
                        <div className="edit-media-content-fields">
                            {mediaContent}
                            {uploader}
                            {secondaryUploader}
                        </div>
                        {mediaPlayer}
                    </div>
                </div>
            </div>
            <button
                type="button"
                className="media-link-preview-clear-button"
                onClick={clearAttachment}
            >
                <img src="/images/icon.black.x.svg" alt="Clear link Preview" />
            </button>
        </div>
    );
};

export default EditCardMediaItem;
