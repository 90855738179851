import React from 'react';
import produce, { Draft } from 'immer';

import useModal from '@components/modals/hooks/useModal';

import Trash from '@dsc/svgs/Trash';
import Gear from '@dsc/svgs/Gear';
import Plus from '@dsc/svgs/FullSizePlus';

import { ModalOptions } from '@core/types/Modals';

type MultiCustomInputProps<DataShape extends {}, ComponentPropsType extends {}> = {
    state: DataShape[];
    onChange: (value: DataShape[]) => void;

    /**
     * The labels displayed for each state value
     */
    labels: React.ReactNode[];

    /**
     * The default state object created when hitting the plus button
     */
    defaultValue?: DataShape;

    /**
     * The Custom Form component to be rendered in a modal. This must contain at least state and
     * onChange as props, and must be modal-friendly (i.e. state will only set the inital value,
     * after that, this component must manage its own state and call onChange with its own state)
     */
    Component: React.FC<
        ComponentPropsType & {
            state: DataShape;
            onChange: (value: DataShape) => void;
        }
    >;

    /**
     * Props to be passed along to Component (you don't need to include state and onChange here)
     */
    componentProps?: ComponentPropsType;

    /**
     * Options passed to `newModal` when opening the modal
     */
    modalOptions?: ModalOptions;

    label?: string;
    buttonText?: string;
    className?: string;
    hideAddDeleteButtons?: boolean;
};

/**
 * This is a ✨ _magical_ ✨ component that allows you to take any form component that updates an
 * object and can redner in a modal, and make it work with arrays!
 *
 * The TS compiler is not _particularly_ good at specifying components with specific props as a
 * render prop, so you might find that errors get missed, but the basic usage is to pass a component
 * constructor that has at least the props state and onChange as the Component prop, and pass in any
 * extra props to that component through the componentProps prop.
 *
 * Here's an example:
 *
 * const TestForm: React.FC<{
 *     state: { value: string };
 *     onChange: (value: { value: string }) => void;
 *     disabled: boolean;
 * }> = ({ state, onChange, disabled }) => {
 *     const [text, setText] = useState(state.value);
 *
 *     const { closeModal } = useModal();
 *
 *     return (
 *         <form
 *             onSubmit={e => {
 *                 e.preventDefault();
 *                 onChange({ value: text });
 *                 closeModal();
 *             }}
 *         >
 *             <input disabled={disabled} value={text} onChange={e => setText(e.target.value)} />
 *             <button type="submit">Submit</button>
 *         </form>
 *     );
 * };
 *
 * const TestArrayForm = () => {
 *     const [state, setState] = useState([{ value: 'cool!' }]);
 *
 *     return (
 *         <MultiCustomInput
 *             labels={state.map(({ value }) => value)}
 *             state={state}
 *             onChange={values => setState(values)}
 *             defaultValue={{ value: 'default' }}
 *             Component={TestForm}
 *             componentProps={{ disabled: false }}
 *             label="Tests"
 *             buttonText="Add Test"
 *         />
 *     );
 * };
 */
const MultiCustomInput = <DataShape extends {}, ComponentPropsType extends {}>({
    labels,
    state,
    defaultValue = {},
    onChange,
    Component,
    componentProps,
    modalOptions,
    label = '',
    buttonText = '',
    className = '',
    hideAddDeleteButtons = false,
}: MultiCustomInputProps<DataShape, ComponentPropsType>) => {
    const { newModal } = useModal();

    const inputs = labels.map((inputLabel, index) => (
        <li key={index}>
            <p>{inputLabel}</p>

            <button
                type="button"
                onClick={() =>
                    newModal(
                        <Component
                            state={state[index]}
                            onChange={value => {
                                onChange(
                                    produce(state, draft => {
                                        draft[index] = value as Draft<DataShape>;
                                    })
                                );
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...(componentProps ?? ({} as ComponentPropsType))}
                        />,
                        modalOptions
                    )
                }
            >
                <Gear />
            </button>

            {!hideAddDeleteButtons && (
                <button
                    type="button"
                    onClick={() =>
                        onChange(
                            produce(state, draft => {
                                draft.splice(index, 1);
                                if (draft.length === 0)
                                    draft.push(defaultValue as Draft<DataShape>);
                            })
                        )
                    }
                >
                    <Trash />
                </button>
            )}
        </li>
    ));

    return (
        <section className={`dsc-forms-customInputs-multiCustomInput ${className}`}>
            <p>{label}</p>
            <ul>{inputs}</ul>

            {!hideAddDeleteButtons && (
                <button
                    type="button"
                    onClick={() => {
                        newModal(
                            <Component
                                state={defaultValue}
                                onChange={value => {
                                    onChange(
                                        produce(state, draft => {
                                            draft[state.length] = value as Draft<DataShape>;
                                        })
                                    );
                                }}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...(componentProps ?? ({} as ComponentPropsType))}
                            />,
                            modalOptions
                        );
                    }}
                >
                    <Plus />
                    {buttonText}
                </button>
            )}
        </section>
    );
};

export default MultiCustomInput;
