import React from 'react';

import { ModalContainer } from '@core/types/Modals';

const FreeformModal: ModalContainer = ({ component, options, open }) => {
    return (
        <aside className={`freeform-modal ${options?.className ?? ''} ${open ? 'open' : 'closed'}`}>
            {component}
        </aside>
    );
};

export default FreeformModal;
