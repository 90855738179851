import React from 'react';

import IntroContent from '@web/ui/components/generic/IntroContent';
import StripeCustomerOrderHistoryItem from '@components/profile/stripe/StripeCustomerOrderHistoryItem';

import { CustomerOrder, Group } from '@shared/welibrary-graphql/types';

const ManageOrdersList: React.FC<{ group: Group; orders: [CustomerOrder]; activeTab: string }> = ({
    orders,
    activeTab,
}) => {
    return (
        <ul>
            {(orders?.length ?? 0) > 0 ? (
                orders?.map((order, currentIndex) => {
                    return (
                        <StripeCustomerOrderHistoryItem
                            key={currentIndex}
                            order={order}
                            activeTab={activeTab}
                            showListView
                        />
                    );
                })
            ) : (
                <IntroContent declaration="No Orders Found" type="nocontent" />
            )}
        </ul>
    );
};

export default ManageOrdersList;
