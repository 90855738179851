import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useCurrentUser } from '@stores/User';

import LoadingLine from '@components/generic/loading/LoadingLine';

import { ctaURItoData } from '@core/utilities/constants/notifications';
import { SOCIAL_ACTIONS, USER_REQUEST_CONNECTION } from '@core/utilities/constants/user';

import {
    ADD_USER_TO_GROUP,
    SWITCH_ACTIVE_GROUP,
    UPDATE_USER_CONNECTION,
    UPDATE_USER_ROLE_FOR_URL,
} from '@shared/welibrary-graphql/user/mutations';
import {
    GET_GROUP_DASHBOARD,
    GET_GROUP_MEMBERSHIP,
    GET_CURRENT_USER_SIMPLE,
} from '@shared/welibrary-graphql/user/queries';
import { Notification } from '@shared/welibrary-graphql/types';
import { CARD_EVENT } from '~/wl-core/utilities/constants/card_types';

const localNamespace = 'imports.wlWeb.ui.components.generic.notifications.notificationAction';

const switchUserToGroup = ({ currentUser, switchActiveGroup, groupId, history }) => {
    if (currentUser) {
        switchActiveGroup({
            variables: {
                groupId,
            },
            refetchQueries: [GET_GROUP_DASHBOARD, GET_GROUP_MEMBERSHIP, GET_CURRENT_USER_SIMPLE],
            update: () => {
                history.push(`/g/${groupId}`);
            },
        });
    } else {
        history.push(`/g/${groupId}`);
    }
};

type NotificationActionProps = {
    notification: Notification;
    markNotificationsRead: (notificationIds: string[]) => void;
};

const NotificationAction: React.FC<NotificationActionProps> = ({
    notification,
    markNotificationsRead,
}) => {
    const { t } = useTranslation();

    const { currentUser } = useCurrentUser();

    const history = useHistory();

    const { eventDetails, messageDetails } = notification;
    const { actor } = eventDetails;
    const { socialActionsAllowed } = actor ?? {};

    let _metadata = null;

    // check if the metadata field exists && if it's a string for backwards compatibility
    // otherwise the app will crash .. since previous notifications will not contain this field
    if (eventDetails?.metadata && typeof eventDetails?.metadata === 'string') {
        _metadata = JSON.parse(eventDetails?.metadata);
    }

    const [overrideMessage, setOverrideMessage] = useState('');

    const [updateUserRoleForUrl, { loading: updateUserRoleForUrlLoading }] =
        useMutation(UPDATE_USER_ROLE_FOR_URL);
    const [updateConnection, { loading: userConnectionLoading }] =
        useMutation(UPDATE_USER_CONNECTION);
    const [addUserToGroup] = useMutation(ADD_USER_TO_GROUP);
    // const [requestAccessToCard, { data: requestAccessToCardData }] = useMutation(REQUEST_ACCESS_TO_CARD);
    const [switchActiveGroup] = useMutation(SWITCH_ACTIVE_GROUP);

    const loading = updateUserRoleForUrlLoading || userConnectionLoading;

    let finished = false;
    let showActionButton = false;
    let actionMessage = '';
    // let action: typeof(SOCIAL_ACTIONS) | undefined;
    let action: typeof SOCIAL_ACTIONS[keyof typeof SOCIAL_ACTIONS] | undefined;
    let actionClick;
    let cancelActionClick;

    let noActionMessage = '';
    switch (eventDetails.action) {
        case USER_REQUEST_CONNECTION:
            if (!actor) break;

            if (socialActionsAllowed) {
                showActionButton = true;

                if (socialActionsAllowed.includes(SOCIAL_ACTIONS.DISCONNECT)) {
                    showActionButton = false;
                    noActionMessage = t(`common:${localNamespace}.connected`);
                } else if (socialActionsAllowed.includes(SOCIAL_ACTIONS.REQUEST_CONNECTION)) {
                    actionMessage = t(`common:${localNamespace}.request_connection`);
                    action = SOCIAL_ACTIONS.REQUEST_CONNECTION;
                } else if (
                    socialActionsAllowed.includes(SOCIAL_ACTIONS.CANCEL_REQUEST_CONNECTION)
                ) {
                    actionMessage = t(`common:${localNamespace}.cancel_request`);
                    action = SOCIAL_ACTIONS.CANCEL_REQUEST_CONNECTION;
                } else if (socialActionsAllowed.includes(SOCIAL_ACTIONS.ACCEPT_CONNECTION)) {
                    actionMessage = t('common:global.verbs.accept');
                    action = SOCIAL_ACTIONS.ACCEPT_CONNECTION;

                    if (socialActionsAllowed.includes(SOCIAL_ACTIONS.REJECT_CONNECTION)) {
                        cancelActionClick = e => {
                            e.preventDefault();
                            e.stopPropagation();
                            updateConnection({
                                variables: {
                                    userId: actor._id,
                                    action: SOCIAL_ACTIONS.REJECT_CONNECTION,
                                },
                            });
                            if (markNotificationsRead) markNotificationsRead([notification._id]);
                        };
                    }
                }

                if (action) {
                    actionClick = e => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateConnection({ variables: { userId: actor._id, action } });
                        if (markNotificationsRead) markNotificationsRead([notification._id]);
                    };
                }
            }
            break;
        case 'cardAccessRequest': {
            const callToAction = ctaURItoData(messageDetails.cta.link);

            if (!callToAction) break;

            const { keyword, data } = callToAction;
            if (keyword === 'cardAccessRequest' && data) {
                const { _id, cardUrl, role } = data;

                if (_id && cardUrl && role) {
                    showActionButton = true;
                    actionClick = e => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateUserRoleForUrl({
                            variables: { url: cardUrl, userId: _id, role },
                        });
                        setOverrideMessage(t(`common:${localNamespace}.approved`));
                        finished = true;
                        if (markNotificationsRead) markNotificationsRead([notification._id]);
                    };
                    actionMessage = t('common:global.verbs.approve');
                }
            }

            break;
        }

        case 'groupAccessRequest':
        case 'groupInviteRequest': {
            const groupCallToAction = ctaURItoData(messageDetails.cta.link);

            if (!groupCallToAction) break;

            const { keyword: groupKeyword, data: groupData } = groupCallToAction;
            if (groupKeyword === 'groupRequestAccessToPurchase') {
                showActionButton = true;
                actionMessage = t(`common:${localNamespace}.subscribe_action`, 'Subscribe');
                actionClick = () => {
                    if (_metadata?.subgroupId) {
                        history.push(`/g/${_metadata?.subgroupId}`);
                    } else {
                        history.push(`/g/${eventDetails.target.id}`);
                    }
                    if (markNotificationsRead) markNotificationsRead([notification._id]);
                    finished = true;
                };
            } else if (
                groupKeyword === 'groupAccessRequest' ||
                (groupKeyword === 'groupInviteRequest' && groupData)
            ) {
                const { _id, groupId, role, approved } = groupData;

                if (_id && groupId) {
                    const requestAdmin = role === 'admin';

                    showActionButton = true;
                    actionClick = e => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (overrideMessage) {
                            switchUserToGroup({
                                currentUser,
                                switchActiveGroup,
                                groupId,
                                history,
                            });
                        } else if (!_metadata?.subgroupId && _metadata?.subtype === CARD_EVENT) {
                            history.push(`/g/${eventDetails.target.id}?showEventRegistration=true`);
                            finished = true;
                        } else if (_metadata?.cardId) {
                            addUserToGroup({
                                variables: {
                                    groupId,
                                    subgroupId: _metadata?.subgroupId ?? null,
                                    userId: _id,
                                    cardId: _metadata?.cardId ?? null,
                                },
                            }).then(() => {
                                history.push(`/c/${_metadata?.cardId}`);
                            });
                            setOverrideMessage(t(`common:${localNamespace}.accepted`, 'Accepted'));
                            finished = true;
                        } else {
                            addUserToGroup({
                                variables: {
                                    groupId,
                                    subgroupId: _metadata?.subgroupId ?? null,
                                    userId: _id,
                                    admin: requestAdmin,
                                },
                            }).then(() => {
                                switchUserToGroup({
                                    currentUser,
                                    switchActiveGroup,
                                    groupId,
                                    history,
                                });
                            });
                            setOverrideMessage(t(`common:${localNamespace}.accepted`, 'Accepted'));
                            finished = true;
                        }
                        if (markNotificationsRead) markNotificationsRead([notification._id]);
                    };
                    actionMessage = t('common:global.verbs.accept');
                    if (!_metadata?.subgroupId && _metadata?.subtype === CARD_EVENT) {
                        actionMessage = 'Register';
                    }
                } else if (approved) {
                    showActionButton = true;
                    actionMessage = t(`common:${localNamespace}.accepted`, 'Accepted');
                    actionClick = () => {
                        history.push(`/g/${eventDetails.target.id}`);
                        if (markNotificationsRead) markNotificationsRead([notification._id]);
                    };
                    finished = true;
                }
            }

            break;
        }

        case 'groupPreAuthPaymentRequest': {
            showActionButton = true;
            actionMessage = 'View';
            actionClick = () => {
                history.push(`/g/${eventDetails.target.id}/members`);

                if (markNotificationsRead) markNotificationsRead([notification._id]);
                finished = true;
            };

            break;
        }

        case 'groupPreAuthPaymentRequestApproved': {
            showActionButton = true;
            actionMessage = 'View';
            actionClick = () => {
                history.push(`/g/${eventDetails.target.id}`);

                if (markNotificationsRead) markNotificationsRead([notification._id]);
                finished = true;
            };

            break;
        }

        case 'groupSpeakerInvite': {
            showActionButton = true;
            actionMessage = 'View';
            actionClick = () => {
                history.push(`/g/${eventDetails.target.id}`);

                if (markNotificationsRead) markNotificationsRead([notification._id]);
                finished = true;
            };

            break;
        }

        case 'subscriptionPriceChange': {
            showActionButton = true;
            actionMessage = t(`common:${localNamespace}.view`, 'View');
            actionClick = () => {
                if (_metadata?.orderId) {
                    history.push(
                        `/g/${eventDetails.target.id}?${_metadata?.subgroupId}?showSubscription=true&orderId=${_metadata?.orderId}`
                    );
                } else {
                    history.push(
                        `/g/${eventDetails.target.id}?${_metadata?.subgroupId}?showSubscription=true`
                    );
                }

                if (markNotificationsRead) markNotificationsRead([notification._id]);
                finished = true;
            };

            break;
        }
        case 'subscriptionDeleted': {
            showActionButton = true;
            actionMessage = t(`common:${localNamespace}.view`, 'View');
            actionClick = () => {
                if (_metadata?.orderId) {
                    history.push(
                        `/g/${eventDetails.target.id}?${_metadata?.subgroupId}?showOrder=true&orderId=${_metadata?.orderId}`
                    );
                } else {
                    history.push(`/g/${eventDetails.target.id}?${_metadata?.subgroupId}`);
                }

                if (markNotificationsRead) markNotificationsRead([notification._id]);
                finished = true;
            };

            break;
        }

        case 'groupConnectedAccountReady': {
            showActionButton = true;
            actionMessage = t(`common:${localNamespace}.view`, 'View');
            actionClick = () => {
                history.push(`/g/${eventDetails.target.id}`);

                if (markNotificationsRead) markNotificationsRead([notification._id]);
                finished = true;
            };

            break;
        }

        case 'groupContentAutoModerated':
        case 'groupContentModeratedAccepted':
        case 'groupContentModeratedRejected': {
            showActionButton = true;
            actionMessage = t(`common:${localNamespace}.view`, 'View');
            if (_metadata?.cardId) {
                actionClick = () => {
                    history.push(`/u?moderation=true&cardId=${_metadata?.cardId}`);
                    if (markNotificationsRead) markNotificationsRead([notification._id]);
                    finished = true;
                };
            }
            break;
        }

        case 'cardContentAutoModerated':
        case 'cardContentModeratedAccepted':
        case 'cardContentModeratedRejected': {
            showActionButton = true;
            actionMessage = t(`common:${localNamespace}.view`, 'View');
            if (_metadata?.cardId) {
                actionClick = () => {
                    history.push(`/u?moderation=true&cardId=${_metadata?.cardId}`);
                    if (markNotificationsRead) markNotificationsRead([notification._id]);
                    finished = true;
                };
            }
            break;
        }

        default:
            return <></>;
    }

    if (!showActionButton) {
        return (
            <div className="notification-actions">
                {noActionMessage ? (
                    <button
                        type="button"
                        className="notification-text-button finished w-inline-block"
                    >
                        <div>{noActionMessage}</div>
                    </button>
                ) : null}
            </div>
        );
    }

    return (
        <>
            <LoadingLine isLoading={loading} />
            {!loading && (
                <div className="notification-actions">
                    {overrideMessage ? (
                        <button
                            type="button"
                            className="notification-text-button finished w-inline-block"
                        >
                            <div>{overrideMessage}</div>
                        </button>
                    ) : (
                        <>
                            {actionClick && (
                                <button
                                    type="button"
                                    onClick={e => actionClick(e)}
                                    className={`notification-text-button w-inline-block ${
                                        finished ? 'finished' : ''
                                    }`}
                                >
                                    <div>{actionMessage}</div>
                                </button>
                            )}
                            {cancelActionClick && (
                                <button
                                    type="button"
                                    onClick={e => cancelActionClick(e)}
                                    className="notification-action-button deny w-inline-block"
                                >
                                    <div />
                                </button>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

// {eventDetails.action === USER_REQUEST_CONNECTION && this.showConnectionRequest(eventDetails) &&
//     <Mutation mutation={UPDATE_USER_CONNECTION}>
//     {(updateConnection, {data, loading, error}) => (
//             <div>
//                 {loading ?
//                     <LoadingIndicator type='orbit' /> :
//                     <div className="notification-actions">
//                         {this.state.confirm === null &&
//                             <span onClick={(e) => this.handleConnectClick(updateConnection, eventDetails.actor, eventDetails.action, true, e)}  className="notification-text-button w-inline-block">
//                                 <div>Confirm</div>
//                             </span>
//                         }
//                         {this.state.confirm === null &&
//                             <span onClick={(e) => this.handleConnectClick(updateConnection, eventDetails.actor, eventDetails.action, false, e)}  className="notification-action-button deny w-inline-block"></span>
//                         }
//                         {this.state.message && <div className="notification-text-button w-inline-block"><div>{this.state.message}</div></div>}
//                     </div>
//                 }
//             </div>
//     )}</Mutation>
//  }
//  {eventDetails.action === USER_REQUEST_CONNECTION && !this.showConnectionRequest(eventDetails) &&
//     <div className="notification-actions">
//         {this.getConnectionRequestMessage(eventDetails) && <div className="notification-text-button w-inline-block"><div>{this.getConnectionRequestMessage(eventDetails) }</div></div>}
//     </div>
//  }

export default NotificationAction;
