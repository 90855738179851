import React from 'react';
import { useTranslation } from 'react-i18next';

import { useToastActionsContext } from '@components/toast/NewToastContext';

import AlertToast from '@components/toast/AlertToast';

import { defaultAlertToastOptions } from '@core/types/Toast';

const localNamespace = 'imports.wlWeb.ui.components.toast';

import getLogger from '@core/logger';

const logger = getLogger(module);

/**
 * copyTextToClipboard hook
 */
export const useCopyTextToClipboard = () => {
    const { t } = useTranslation();
    const { newToast } = useToastActionsContext();

    const copyTextToClipboard = (link: string, successMessage?: string) => {
        try {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(link).then(() => {
                    newToast(
                        <AlertToast
                            boldText={
                                successMessage ??
                                t(
                                    `common:${localNamespace}.link_copied`,
                                    'Link copied to clipboard!'
                                )
                            }
                            showSuccessIcon
                        />,
                        {
                            ...defaultAlertToastOptions,
                        }
                    );
                });
            }
        } catch (error) {
            logger.error('Async: Could not copy text: ', error);
            newToast(
                <AlertToast
                    boldText={t(
                        `common:${localNamespace}.link_not_copied`,
                        'Could not copy link to clipboard'
                    )}
                    showWarningIcon
                />,
                {
                    ...defaultAlertToastOptions,
                }
            );
        }
    };

    return {
        copyTextToClipboard,
    };
};

export default useCopyTextToClipboard;
