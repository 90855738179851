import React from 'react';
import {useTranslation} from 'react-i18next';

const emptyImageStyle = { maxWidth: '230px' };

// This is displayed when there are no recommendation items to show
const EmptyRecommendationItem = ({ showOptimize }) => {
    const { t } = useTranslation();

    const handleOptimize = () => {
        if (showOptimize) showOptimize();
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li className="discover-modal-list-item" style={{ maxHeight: '200px' }}>
            <div className="empty-state-image1" style={emptyImageStyle} />
            <p style={{ fontWeight: '600', padding: '0px 15px' }}>No Suggestions Available Yet</p>
            <button className="optimize-btn" type="button" onClick={handleOptimize}>
                {t('common:imports.wlWeb.ui.components.community.optimize')}
            </button>
        </li>
    );
};

export default EmptyRecommendationItem;
