import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';

import Picture from '@components/generic/Picture';
import RainbowWithImage from '@components/rainbowroad/RainbowWithImage';
import RRHeaderBottomMask from '@components/rainbowroad/RRHeaderBottomMask';

import Plus from '@dsc/svgs/FullSizePlus';

import {
    getRandomDefaultThumbImage,
    getRandomDefaultCoverImage,
} from '@core/utilities/constants/defaults';

import { RainbowRoadBuilderState } from '@core/types/RainbowRoad';

const localNamespace = 'imports.wlWeb.ui.pages.rainbowRoad';

type RRBuilderPreviewProps = {
    title?: string;
    state: RainbowRoadBuilderState;
};

const RRBuilderPreview = React.forwardRef<HTMLElement, RRBuilderPreviewProps>(
    function RRBuilderPreview({ title = '', state }, ref) {
        const { t } = useTranslation();

          const { currentUser } = useCurrentUser();

        const picture = state?.main?.icon?.image || getRandomDefaultThumbImage();

        const cssVars = {
            '--rr-header-color': state.header.darkMode
                ? state.header.color
                : 'var(--color-grayscale-background)',

            '--rr-header-text-color': state.header.darkMode
                ? 'var(--color-grayscale-background)'
                : state.header.color,

            '--rr-body-color': state.body.darkMode
                ? state.body.color
                : 'var(--color-grayscale-background)',

            '--rr-body-text-color': state.body.darkMode
                ? 'var(--color-grayscale-background)'
                : state.body.color,
        } as CSSProperties;

        if (state.header.useBackgroundImage) {
            cssVars['--rr-header-image'] = `url(${
                state.header.backgroundImage.image || getRandomDefaultCoverImage(state.main.title)
            })`;
        }

        if (state.body.useBackgroundImage) {
            cssVars['--rr-body-image'] = `url(${
                state.body.backgroundImage.image || getRandomDefaultCoverImage(state.main.title)
            })`;
        }

        return (
            <section className="rr-builder-preview" style={cssVars}>
                <h1>{title}</h1>

                <output
                    className={`rainbow-road preview ${state.main.font}-font ${
                        state.header.useBackgroundImage ? 'header-img-set' : ''
                    }`}
                    ref={ref}
                >
                    <header>
                        {state.header.showTopInfo && (
                            <>
                                <RainbowWithImage url={picture} />
                                <h3>{t(`common:${localNamespace}.rainbow_road`)}</h3>
                                {currentUser?.profile?.full_name && (
                                    <h4>{`by ${currentUser.profile.full_name}`}</h4>
                                )}
                            </>
                        )}

                        <h1>{state.main.title}</h1>
                        <p>{state.main.description}</p>

                        {/* {state.main.engagementTypes.length > 0 && (
                            <section>{engagementButtons}</section>
                        )} */}
                    </header>

                    <section>
                        {state.header.useBackgroundImage ? (
                            <Picture
                                url={
                                    state.header.backgroundImage.image ||
                                    getRandomDefaultCoverImage(title ?? '')
                                }
                                className="header-bg"
                                disableLink
                            />
                        ) : (
                            <div role="presentation" className="mask">
                                <RRHeaderBottomMask className="rr-header-bottom-mask" />
                            </div>
                        )}

                        <div
                            role="presentation"
                            className={`rainbow-road-node no-outline ${state.nodes.circleSize}-circle`}
                        >
                            <button type="button" disabled className="new-node">
                                {state.nodes.useIcon ? (
                                    <Picture
                                        url={
                                            state.nodes.icon.image ||
                                            getRandomDefaultCoverImage(state.main.title ?? '')
                                        }
                                        disableLink
                                        resolutions={[110, 220, 330]}
                                    />
                                ) : (
                                    <Plus strokeWidth="2" />
                                )}
                            </button>
                        </div>

                        <div
                            role="presentation"
                            className={`rainbow-road-node ${state.nodes.circleSize}-circle ${
                                state.nodes.lineStyle
                            }-line ${state.nodes.outline ? '' : 'no-'}outline ${
                                state.nodes.winding ? 'winding' : ''
                            }`}
                            style={
                                { '--rr-node-line-color': state.nodes.lineColor } as CSSProperties
                            }
                        >
                            <button type="button" disabled>
                                <Picture
                                    url={getRandomDefaultCoverImage(state.main.title)}
                                    disableLink
                                    resolutions={[110, 220, 330]}
                                />
                            </button>
                        </div>
                    </section>
                </output>
            </section>
        );
    }
);

export default RRBuilderPreview;
