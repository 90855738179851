import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@stores/User';

import EditScreenItemContainer from '@web/ui/components/generic/editscreen/EditScreenItemContainer';
import EditScreenTagSelect from '@web/ui/components/generic/editscreen/EditScreenTagSelect';
import EditScreenTagRadio from '@web/ui/components/generic/editscreen/EditScreenTagRadio';
import ToggleButtonEditScreen from '@web/ui/components/generic/buttons/ToggleButtonEditScreen';
import { capitalize } from '@helpers/string.helpers';

import {
    ROLE_ADMIN,
    ROLE_VIEW,
    VIEW_PERMISSION_PRIVATE,
    VIEW_PERMISSION_PUBLIC,
    VIEW_PERMISSION_SECRET,
    VIEW_PERMISSION_UNSET,
} from '@core/utilities/constants/roles';

const localNamespace = 'imports.wlWeb.ui.components.card.darkForms';

const BOOK_SETTINGS = 'book_settings';
const SHELF_SETTINGS = 'shelf_settings';

const DarkFormSettings = ({
    state,
    handleSubmit,
    handleRadio,
    handleToggle,
    handleAddToList,
    handleRemoveFromList,
    handleSetList,
    settingsConstraints,
    settingsType,
}) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();

    const {
        accessRoleWhitelistConstraints,
        protectionLevelConstraint,
        displayInNewsfeedConstraint,
    } = settingsConstraints;

    const defaultProtectionLevelOptions = [
        {
            text: t('common:global.adjectives.public'),
            value: VIEW_PERMISSION_PUBLIC,
            iconClass: 'public-option',
            selectedClass: 'public-selected',
            highlightedClass: 'public-highlighted',
        },
        {
            text: t('common:global.adjectives.private'),
            value: VIEW_PERMISSION_PRIVATE,
            iconClass: 'private-option',
            selectedClass: 'private-selected',
            highlightedClass: 'private-highlighted',
        },
        {
            text: t('common:global.adjectives.secret'),
            value: VIEW_PERMISSION_SECRET,
            iconClass: 'secret-option',
            selectedClass: 'secret-selected',
            highlightedClass: 'secret-highlighted',
        },
    ];

    const isProtectionLevelSelected = state.protectionLevel !== VIEW_PERMISSION_UNSET;
    if (isProtectionLevelSelected) {
        defaultProtectionLevelOptions.push({
            text: `${capitalize(t('common:reset', 'reset'))} ${t('common:to', 'to')} ${capitalize(
                t('common:default', 'default')
            )}`,
            value: VIEW_PERMISSION_UNSET,
            iconClass: 'reset-option',
        });
    }

    const protectionLevelOptions =
        protectionLevelConstraint && protectionLevelConstraint.options
            ? _.filter(defaultProtectionLevelOptions, option =>
                protectionLevelConstraint.options.includes(option.value)
            )
            : defaultProtectionLevelOptions;

    // let customRoleTypes = _.map(state._customRoles, (role) => ({text: role.name, value: role.url, color: role.color }));
    const roleTypes = [
        { text: 'Admin', value: ROLE_ADMIN },
        { text: 'Member', value: ROLE_VIEW },
    ];

    let subtitle;

    const { activeGroup } = currentUser;
    const activeGroupProtectionLevel = activeGroup?.settings?.protectionLevel;
    const activeGroupName = activeGroup?.profile.full_name;

    if (settingsType === BOOK_SETTINGS) {
        subtitle =
            'Select who can see this Collection.\n__Public__: Anyone with access can see.\n__Private__: Anyone with the whitelisted Group role can see.\n__Secret__: Anyone with the whitelisted Collection role can see.';
    } else if (settingsType === SHELF_SETTINGS) {
        subtitle = `Select who can see this Resource Library.\n__Public__: Anyone with access can see.\n__Private__: Anyone with the whitelisted Group role can see.\n__Secret__: Anyone with the whitelisted Resource Library role can see.${!isProtectionLevelSelected
                ? `\n\nThis Resource Library is ${activeGroupProtectionLevel} because it is inheriting its privacy setting from Group ${activeGroupName}`
                : ''
            }`;
    }

    return (
        <div
            className="edit-screen-tab-container w-tab-pane w--tab-active"
            style={{
                opacity: 1,
                transition: 'opacity 300ms ease 0s',
                paddingBottom: '25px',
            }}
        >
            {(accessRoleWhitelistConstraints || protectionLevelConstraint) && (
                <EditScreenItemContainer
                    title={t(`common:${localNamespace}.visibility`)}
                    subtitle={subtitle}
                    clearfix
                    showToggleContainer={
                        (state.protectionLevel === VIEW_PERMISSION_PRIVATE ||
                            state.protectionLevel === VIEW_PERMISSION_SECRET) &&
                        accessRoleWhitelistConstraints
                    }
                    renderToggleContainer={
                        <EditScreenTagSelect
                            target="accessRoleWhitelist"
                            options={roleTypes}
                            selected={state.accessRoleWhitelist}
                            allowMultiple={false}
                            handleRemoveFromList={handleRemoveFromList}
                            handleSetList={handleSetList}
                        />
                    }
                >
                    {protectionLevelConstraint && (
                        <EditScreenTagRadio
                            target="protectionLevel"
                            options={protectionLevelOptions}
                            selected={state.protectionLevel}
                            allowMultiple={false}
                            handleRadio={handleRadio}
                            optionNameToHighlight={
                                !isProtectionLevelSelected ? activeGroupProtectionLevel : null
                            }
                        />
                    )}
                </EditScreenItemContainer>
            )}
            {/* Display In Newsfeed Toggle */}
            {displayInNewsfeedConstraint && (
                <EditScreenItemContainer
                    title={t(
                        `common:imports.wlWeb.ui.components.card.updateCard.mediaCardUpdateForm.display_in_newsfeed`
                    )}
                    columnItem
                    columnsBlock
                >
                    <ToggleButtonEditScreen
                        id="displayInNewsfeed"
                        name="displayInNewsfeed"
                        handleToggle={handleToggle}
                        onOption={t('common:global.yes_caps')}
                        offOption={t('common:global.no_caps')}
                        toggleValue={
                            state.displayInNewsfeed == null ? true : state.displayInNewsfeed
                        }
                    />
                </EditScreenItemContainer>
            )}
            <EditScreenItemContainer title="Vertical Scrolling" columnItem columnsBlock>
                <ToggleButtonEditScreen
                    id="verticalScrolling"
                    name="verticalScrolling"
                    handleToggle={handleToggle}
                    onOption={t('common:global.yes_caps')}
                    offOption={t('common:global.no_caps')}
                    toggleValue={state.verticalScrolling}
                />
            </EditScreenItemContainer>
            <div className="edit-footer">
                <div
                    onClick={handleSubmit}
                    className="button callout-button w-button edit-screen-content"
                >
                    {t('common:global.verbs.save')}
                </div>
            </div>
        </div>
    );
};

export default DarkFormSettings;
