import React from 'react';

const EngagementButton = React.forwardRef(function EngagementButton(
    {
        iconClass,
        iconText,
        leftText,
        addClasses,
        activeDrawer,
        isActive,
        handleToggleDrawer,
        isCallout,
        type,
        onClick,
    },
    ref
) {
    // Names OF ICONS CURRENTLY in use
    // like
    // share
    // whitestar
    // person
    // target
    // blank defaults to a comment icon

    // this component should also take a function which will do something
    // e.g. comments icon will trigger displaying the comments
    // e.g. bookmarking will bookmark add that to the users bookmarks
    const onClickHandler = () => {
        if (onClick) onClick();
        else if (handleToggleDrawer) handleToggleDrawer(type || iconClass);
    };

    const buttonClass = `engagement-button shrink w-inline-block ${addClasses || ''} ${
        activeDrawer === iconClass || isActive ? `${iconClass} active-button-engage` : ''
    }`;

    return (
        <button
            type="button"
            ref={ref}
            onClick={onClickHandler}
            className={`${buttonClass} ${isCallout ? 'callout-button' : ''}`}
        >
            {leftText && (
                <div className="mc-button-text" style={{ marginRight: '5px' }}>
                    {leftText}
                </div>
            )}
            <div className={`content-button-icon ${iconClass}`} />
            <div className="mc-button-text">{iconText}</div>
        </button>
    );
});

export default EngagementButton;
