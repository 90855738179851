import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Remarkable } from 'remarkable';
import hljs from 'highlight.js/lib/core';
import HTML from 'highlight.js/lib/languages/xml';
import CSS from 'highlight.js/lib/languages/css';
import javascript from 'highlight.js/lib/languages/javascript';
import JSON from 'highlight.js/lib/languages/json';

import { link_open, linkify } from '@core/utilities/customLinkifyPlugin';
import { wlParser } from '@core/utilities/customMarkdownPlugins';
import { twitterParser } from '@core/utilities/twitterMarkdownPlugin';
import { translate_plugin } from '@core/utilities/markdown/translate_plugin';

import getLogger from '@core/logger';

const logger = getLogger(module);

hljs.registerLanguage('html', HTML);
hljs.registerLanguage('css', CSS);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('json', JSON);

const initMd = t => {
    const md = new Remarkable({
        t,
        html: true,
        breaks: false,
        highlight: (str, lang) => {
            if (lang && hljs.getLanguage(lang)) {
                try {
                    return hljs.highlight(lang, str).value;
                } catch (err) {
                    logger.error('Error highlighting markdown', err);
                }
            }

            try {
                return hljs.highlightAuto(str).value;
            } catch (err) {
                logger.error('Error highlighting markdown', err);
            }

            return '';
        },
    })
        .use(twitterParser)
        .use(linkify)
        .use(wlParser)
        .use(translate_plugin);
    md.renderer.rules.link_open = link_open;
    return md;
};

export const RemarkableContext = React.createContext();

export const RemarkableProvider = ({ children }) => {
    const { t } = useTranslation();
    const [md] = useState(initMd(t));

    return <RemarkableContext.Provider value={md}>{children}</RemarkableContext.Provider>;
};
