import { LatitudeLongitude, LocationCore, LocationGeoJson, LocationInput } from '@shared/welibrary-graphql/types';
import { getHaversineDistance } from '../math.helpers';

/** Converts a raw Google Geocoder to result to match our location data shape */
export const formatLocationObject = (result: google.maps.GeocoderResult): LocationInput => {
    const cityComponent = result.address_components.find(component =>
        component.types.includes('locality')
    );
    const stateComponent = result.address_components.find(component =>
        component.types.includes('administrative_area_level_1')
    );
    const countryComponent = result.address_components.find(component =>
        component.types.includes('country')
    );
    const postalComponent = result.address_components.find(component =>
        component.types.includes('postal_code')
    );
    const location = {
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng(),
    };

    return {
        formatted_address: result.formatted_address,
        location,
        city: cityComponent?.short_name,
        state: stateComponent?.short_name,
        country: countryComponent?.short_name,
        postal: postalComponent?.short_name,
    };
};

export const geoJSONPointToLatLngObject = (
    point?: LocationGeoJson | null
): LatitudeLongitude | null => {
    if (!point) return null;

    // protect against getting passed in an existing LatLng object
    if ('latitude' in point && 'longitude' in point) return point as any;

    return {
        latitude: point.coordinates[1] ?? 0,
        longitude: point.coordinates[0] ?? 0,
    };
};

export const locationCoreToLocationInput = (
    location?: LocationCore | null
): LocationInput | null => {
    if (!location) return null;

    return {
        formatted_address: location.formatted_address,
        location: geoJSONPointToLatLngObject(location.location) ?? { latitude: 0, longitude: 0 },
        city: location.city ?? undefined,
        state: location.state ?? undefined,
        country: location.country ?? undefined,
        postal: location.postal ?? undefined,
    };
};

export const getRadiusForLocation = (location: google.maps.GeocoderResult): number => {
    const { viewport } = location.geometry;

    const NE = viewport.getNorthEast().toJSON();
    const SW = viewport.getSouthWest().toJSON();
    const center = viewport.getCenter().toJSON();

    return Math.max(getHaversineDistance(NE, center), getHaversineDistance(SW, center));
};
