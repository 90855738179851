import React from 'react';

type VerificationStatusIconProps = {
    status: 'Success' | 'Error' | 'Failed';
    className?: string;
};

const VerificationStatusIcon: React.FC<VerificationStatusIconProps> = ({
    status,
    className = '',
}) => {
    switch (status) {
        case 'Success':
            return (
                <svg
                    className={className}
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="40" height="40" rx="20" fill="#39B54A" />
                    <path
                        d="M8.90039 18.1529L17.0919 27.5L31.1004 12.5H24.1026L17.0919 21.2308L14.8905 17.8502L8.90039 18.1529Z"
                        fill="white"
                    />
                </svg>
            );
        case 'Error':
            return (
                <svg
                    className={className}
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="40" height="40" rx="20" fill="#FFCF00" />
                    <path
                        d="M17.4649 22.169L16.1973 10H23.8029L22.5353 22.169H17.4649ZM20.0001 30C18.9109 30 18.0189 29.6714 17.324 29.0141C16.6292 28.338 16.2818 27.5399 16.2818 26.6197C16.2818 25.6807 16.6292 24.892 17.324 24.2535C18.0189 23.615 18.9109 23.2958 20.0001 23.2958C21.1081 23.2958 22.0001 23.615 22.6761 24.2535C23.371 24.892 23.7184 25.6807 23.7184 26.6197C23.7184 27.5399 23.371 28.338 22.6761 29.0141C22.0001 29.6714 21.1081 30 20.0001 30Z"
                        fill="white"
                    />
                </svg>
            );
        case 'Failed':
            return (
                <svg
                    className={className}
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="40" height="40" rx="20" fill="#D01012" />
                    <path
                        d="M22.9518 28C22.4942 27.2497 22.0274 26.5196 21.5515 25.8099C21.0939 25.09 20.6088 24.365 20.0963 23.635C19.6204 24.3447 19.1536 25.0596 18.696 25.7795C18.2384 26.4892 17.7991 27.2091 17.378 27.9392L11.2002 27.7567C12.2985 26.4588 13.3602 25.1762 14.3852 23.9087C15.4286 22.6413 16.4262 21.3485 17.378 20.0304C16.3896 18.7326 15.3828 17.455 14.3578 16.1977C13.351 14.9303 12.3351 13.6527 11.31 12.365L17.735 12C18.394 13.3891 19.1811 14.7478 20.0963 16.076C20.4807 15.417 20.8742 14.7529 21.2769 14.0837C21.698 13.4144 22.0915 12.7199 22.4576 12L28.8002 12.2738C27.7019 13.5615 26.6585 14.834 25.6701 16.0913C24.6816 17.3485 23.7023 18.621 22.7322 19.9087C23.6657 21.1965 24.6084 22.4537 25.5603 23.6806C26.5304 24.9075 27.5006 26.1496 28.4707 27.4068L22.9518 28Z"
                        fill="white"
                    />
                </svg>
            );
        default:
            return <></>;
    }
};

export default VerificationStatusIcon;
