import React from 'react';

import Picture from '@web/ui/components/generic/Picture';

import {
    getBackgroundImageSetForUrl,
    getRandomDefaultThumbImage,
    getRandomDefaultCoverImage,
    StyledBackgroundImage,
} from '@core/utilities/constants/defaults';

const CreationThumbDisplay: React.FC<{
    foreground: string;
    background: string;
    title: string;
    hideTitle?: boolean;
}> = ({ foreground, background, title, hideTitle = false }) => {
    const [src, srcSet] = getBackgroundImageSetForUrl(
        background || getRandomDefaultCoverImage(title)
    );

    return (
        <div className="creation-thumb">
            <StyledBackgroundImage
                className="creation-bg-thumb"
                src={src as string}
                srcSet={srcSet as string}
            />

            <Picture
                className="creation-small-thumb"
                disableLink
                url={foreground || getRandomDefaultThumbImage(title)}
            />
            {!hideTitle && (
                <div className="creation-title">
                    <h3>{title}</h3>
                </div>
            )}
        </div>
    );
};

export default CreationThumbDisplay;
