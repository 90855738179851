/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable keyword-spacing */

import React, {useState} from 'react';

const VISIBILITY_TO_CLASS = {
    public: 'public-visibility',
    private: 'private-visibility',
    secret: 'secret-visibility',
};

const VISIBILITY_TO_NAME = {
    public: 'Public',
    private: 'Friends',
    //  secret: 'Secret'
};

const visibilityOptions = ['public', 'private'];

const ToggleVisibility = ({ protectionLevel, handleToggle }) => {
    const [visibility, setVisibility] = useState(protectionLevel);

    const baseClass = 'visibility-button';
    const visibilityClass =
        visibility === null ? VISIBILITY_TO_CLASS.public : VISIBILITY_TO_CLASS[visibility];
    const buttonClass = `${baseClass} ${visibilityClass}`;
    const buttonText =
        visibility === null ? VISIBILITY_TO_NAME.public : `${VISIBILITY_TO_NAME[visibility]}`;
    const currentIndex = visibility == null ? 0 : visibilityOptions.indexOf(visibility);
    const optionName = visibilityOptions[currentIndex];

    const handleNextOption = e => {
        const nextIndex = currentIndex < visibilityOptions.length - 1 ? currentIndex + 1 : 0;
        handleToggle(e, visibilityOptions[nextIndex]);
        setVisibility(visibilityOptions[nextIndex]);
    };

    return (
        <button
            name="protectionLevel"
            onClick={handleNextOption}
            type="button"
            className={buttonClass}
        >
            {buttonText}
        </button>
    );
};

export default ToggleVisibility;
