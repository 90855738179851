import React from 'react';

type ArrowsLeftRightProps = {
    className?: string;
    size?: string;
    color?: string;
};

const ArrowsLeftRight: React.FC<ArrowsLeftRightProps> = ({
    className = '',
    size = '18',
    color = '#4E4B66',
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M13.5 10.625L15.75 12.875L13.5 15.125"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.25 12.875H15.75"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.5 8.375L2.25 6.125L4.5 3.875"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.75 6.125H2.25"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowsLeftRight;
