import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import {
    useMessagesActionsContext,
    useMessagesContext,
} from '@components/messaging/MessagesContext';

import { SetState } from '@core/types/Utilities';
import { SEARCH_NETWORK, SEARCH_NETWORK_GROUP } from '@shared/welibrary-graphql/user/queries';
import { User, UserParticipant, UserProfile, Maybe } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';
import { config } from '@core/config/getConfig';

const logger = getLogger(module);

const useEditThreadParticipants = (
    currentParticipants: Maybe<UserParticipant>[],
    setCurrentParticipants: (newParticipants: Maybe<UserParticipant>[]) => void
): {
    searchQuery: string;
    setSearchQuery: SetState<string>;
    filteredUsers: User[];
    addUserToThread: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void; // assumes that e.currentTarget.id is the id of the user to add
    removeUserFromThread: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void; // assumes that e.currentTarget.id is the id of the user to remove
} => {
    const [searchQuery, setSearchQuery] = useState('');
    const { groupName } = useMessagesContext();
    const { setGroupName } = useMessagesActionsContext();
    const rootGroupId = config?.public?.rootGroupId;

    const groupId = searchQuery?.trim?.() === '' ? rootGroupId : undefined;

    // Only limit to rootGroup search when no input
    const { data: searchNetworkData } = useQuery(SEARCH_NETWORK_GROUP, {
        variables: {
            searchQuery,
            groupId,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const reformatContact = ({
        _id,
        profile,
    }: {
        __typename: string;
        _id: string;
        profile: UserProfile;
    }): UserParticipant => ({
        __typename: 'UserParticipant',
        user: { __typename: 'User', _id, profile },
    });

    const userSearchResults = searchNetworkData?.listUsersGroup ?? [];

    const currentParticipantIds: string[] = currentParticipants?.map(
        contact => contact?.user._id ?? ''
    );

    // filter out users who are already in the thread
    const filteredUsers =
        userSearchResults?.filter(
            (contact: User) => !currentParticipantIds.includes(contact._id)
        ) ?? [];

    const addUserToThread = (e: React.MouseEvent<HTMLElement>) => {
        if (
            !currentParticipants?.find(participant => participant?.user._id === e.currentTarget.id)
        ) {
            const contact = userSearchResults.find((user: User) => user._id === e.currentTarget.id);

            if (!contact) {
                logger.error('The contact you have clicked is not in your contacts list!');
                return;
            }
            setCurrentParticipants([...currentParticipants, reformatContact(contact)]);
        }

        setSearchQuery('');
    };

    const removeUserFromThread = (e: React.MouseEvent<HTMLElement>) => {
        const newContacts = currentParticipants?.filter(
            participant => participant?.user._id !== e.currentTarget.id
        );
        setCurrentParticipants(newContacts);

        if (groupName && newContacts.length < 2) setGroupName('');
    };

    return {
        searchQuery,
        setSearchQuery,
        filteredUsers,
        addUserToThread,
        removeUserFromThread,
    };
};

export default useEditThreadParticipants;
