import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import useModal from '@web/ui/components/modals/hooks/useModal';

import useConfirmation from '@components/generic/hooks/useConfirmation';

const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
const SET_COMPONENT = 'SET_COMPONENT';
const CLEAR_SIDEBAR = 'CLEAR_SIDEBAR';
const SET_WIDTH = 'SET_WIDTH';
const SET_UNSAVED_CHANGES = 'SET_UNSAVED_CHANGES';
const SET_HEADER_TITLE = 'SET_HEADER_TITLE';

export const SidebarContext = React.createContext();
export const SidebarActionsContext = React.createContext();

const SidebarReducer = (state, action) => {
    switch (action.type) {
        case SHOW_SIDEBAR:
            return {
                ...state,
                isVisible: true,
                options: action.options,
                componentType: action.componentType,
                passThruFunc: action.passThruFunc,
                directComponent: action.directComponent,
                width: action.width,
                id: action.id,
            };
        case HIDE_SIDEBAR:
            return {
                ...state,
                isVisible: false,
            };
        case SET_HEADER_TITLE:
            return {
                ...state,
                headerTitle: action.headerTitle,
            };
        case SET_COMPONENT:
            return {
                ...state,
                directComponent: action.component,
            };
        case CLEAR_SIDEBAR:
            return {
                componentType: null,
                isVisible: false,
                passThruFunc: null,
                directComponent: null,
                width: 'shrink',
                id: null,
                options: null,
            };
        case SET_WIDTH:
            return {
                ...state,
                width: action.width,
            };
        case SET_UNSAVED_CHANGES:
            return {
                ...state,
                unsavedChanges: action.value,
            };
        default:
    }
};

// componentType - type of component, or use to indicate you are passing a component directly
// isVisible - show Sidebar or not
// passThruFunc - optional function to pass to component if not setting component directly
// directComponent - the component if passing directly
export const SidebarProvider = ({ children }) => {
    const { t } = useTranslation();
    const { closeAllModals } = useModal();
    const confirm = useConfirmation();

    const [sidebarState, dispatch] = useReducer(SidebarReducer, {
        componentType: null,
        isVisible: false,
        passThruFunc: null,
        directComponent: null,
        options: null,
        width: 'shrink',
        unsavedChanges: false,
        headerTitle: null,
        id: null,
    });

    // width options - shrink or full

    const showSidebar = (
        componentType,
        passThruFunc = null,
        directComponent = null,
        width = 'shrink',
        options = null,
        id = null
    ) => {
        closeAllModals();
        dispatch({
            type: SHOW_SIDEBAR,
            componentType,
            options,
            passThruFunc,
            width,
            directComponent,
            id,
        });
    };

    const toggleSidebar = (
        componentType,
        passThruFunc = null,
        directComponent = null,
        width = 'shrink',
        options = null,
        id = null
    ) => {
        if (!sidebarState?.isVisible || sidebarState.id !== id) {
            closeAllModals();
            dispatch({
                type: SHOW_SIDEBAR,
                componentType,
                options,
                passThruFunc,
                width,
                directComponent,
                id,
            });
        }

        if (sidebarState?.isVisible && sidebarState.id === id) {
            dispatch({ type: HIDE_SIDEBAR });
        }
    };

    const hideSidebar = async () => {
        if (
            !sidebarState.unsavedChanges ||
            (await confirm({
                text: t(
                    `common:imports.wlWeb.ui.components.prompts.sidebar.unsaved_changes`,
                    'You have unsaved changes! Are you sure you would like to quit without saving?'
                ),
            }))
        ) {
            dispatch({
                type: HIDE_SIDEBAR,
            });
        }
    };

    const setHeaderTitle = headerTitle => {
        dispatch({
            type: SET_HEADER_TITLE,
            headerTitle,
        });
    };

    const setWidth = width => {
        dispatch({
            type: SET_WIDTH,
            width,
        });
    };

    const clearSidebar = () => {
        dispatch({
            type: CLEAR_SIDEBAR,
        });
    };

    const setComponent = component => {
        dispatch({
            type: SET_COMPONENT,
            component,
        });
    };

    const setUnsavedChanges = value => {
        dispatch({ type: SET_UNSAVED_CHANGES, value });
    };

    return (
        <SidebarContext.Provider value={{ sidebarState }}>
            <SidebarActionsContext.Provider
                value={{
                    setHeaderTitle,
                    setWidth,
                    showSidebar,
                    hideSidebar,
                    toggleSidebar,
                    setComponent,
                    clearSidebar,
                    setUnsavedChanges,
                }}
            >
                {children}
            </SidebarActionsContext.Provider>
        </SidebarContext.Provider>
    );
};
