import React from 'react';

const SelectableListItem = ({ selected = false, onClick, children }) => {
    return (
        <li className={`selectable-list-item ${selected ? 'selected' : ''}`}>
            <button type="button" onClick={onClick}>
                {children}
            </button>
        </li>
    );
};

export default SelectableListItem;
