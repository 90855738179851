import React from 'react';
import {withTranslation} from 'react-i18next';

import {getRandomDefaultThumbImage} from '@core/utilities/constants/defaults';

const localNamespace = 'imports.wlWeb.ui.components.card.scholarCard.scholarCardBody';

class ScholarCardBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDrawer: false,
        };

        const { t } = this.props;

        if (this.props.source.authors) {
            this.authorList = this.props.source.authors.map(author => {
                return <span>{author.name}</span>;
            });
        }
    }

    handleDrawer = () => {
        this.setState({
            openDrawer: !this.state.openDrawer,
        });
    };

    render() {
        // debugger
        const { source } = this.props;
        let drawerStyle = 'drawer-info-container accordion-content';
        if (this.state.openDrawer) {
            drawerStyle = 'drawer-info-container accordion-content accordion-open';
        }

        let thumbBackgroundStyle = {
            background: `url(${
                source.media.icon ? source.media.icon : getRandomDefaultThumbImage(source.title)
            })`,
            backgroundPosition: '50% 50%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };

        let meta = source.meta.research || {};

        const { t } = this.props;

        return (
            <div className="scholar-card-container">
                <div className="scholar-right">
                    <a className="scholar-thumb w-inline-block" style={thumbBackgroundStyle}>
                        <div className="book-spacer" />
                    </a>
                </div>
                <div className="scholar-card-left top">
                    <div className="type-header">
                        <div className="display-inline">
                            <span>
                                <strong className="capitalize">
                                    {t(`common:${localNamespace}.meta.publication_type`, {
                                        pubType: meta.publicationType,
                                    })}
                                </strong>
                            </span>
                        </div>
                        <div className="inline-text-divider mobile-hard-return">|</div>
                        <div className="display-inline">
                            {t(`common:${localNamespace}.meta.category`, {
                                category: meta.category,
                            })}
                        </div>
                    </div>
                    <a href="#" className="card-title scholar-title">
                        {t(`common:${localNamespace}.source.title`, {
                            title: source.title,
                        })}
                    </a>
                </div>
                <div className="scholar-card-left bottom">
                    <div className="scholar-card-info">
                        <div className="display-inline">
                            <em>
                                {t(`common:${localNamespace}.meta.journal_name`, {
                                    journalName: meta.journalName,
                                })}
                            </em>
                        </div>
                        <div className="inline-text-divider mobile-hard-return">|</div>
                        <div className="display-inline">
                            {t(`common:${localNamespace}.publication_date`, {
                                publicationDate: source.date ? source.date.publish : null,
                            })}
                        </div>
                        <div className="inline-text-divider">|</div>
                        <div className="display-inline">
                            {t(`common:${localNamespace}.meta.issue`, { issue: meta.issue })}
                        </div>
                        <div className="content-button-icon small-gray lock-icon" />
                    </div>
                    <div className="scholar-card-info">
                        {/*// TODO - come back to this and check git history*/}
                        {t(`common:${localNamespace}.byAuthor`)}
                        <div>
                            By
                            {this.authorList}
                            <br />
                        </div>
                    </div>
                    <div className="scholar-card-info">
                        <div>
                            <a target="_blank" href={meta.organization}>
                                {t(`common:${localNamespace}.meta.organization`)}
                            </a>
                            <br />
                        </div>
                    </div>
                    <div className="info_drawer accordion">
                        <div className="drawer-buttons-container" style={{ marginBottom: '18px' }}>
                            <a
                                onClick={this.handleDrawer}
                                className="engagement-button drawer-button w-inline-block"
                            >
                                <div className="content-button-icon white-info" />
                                <div className="mc-button-text show-mobile accordion-toggle">
                                    {t(`common:${localNamespace}.info`)}
                                </div>
                            </a>

                            <a
                                href={meta.fileURL}
                                target="_blank"
                                className="engagement-button drawer-button w-inline-block"
                            >
                                <div className="content-button-icon white-doc" />
                                <div className="mc-button-text show-mobile">
                                    {t(`common:${localNamespace}.download`)}
                                </div>
                            </a>
                        </div>

                        <div className={drawerStyle}>
                            <p>
                                {t(`common:${localNamespace}.meta.abstract`, {
                                    abstract: meta.abstract,
                                })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ScholarCardBody);
