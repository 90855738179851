/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import loadable from '@loadable/component';

import {
    ALL_TYPES,
    CARD_BLOG,
    CARD_COURSE,
    CARD_DYNAMIC,
    CARD_EVENT,
    CARD_GROUP,
    CARD_HEADER,
    CARD_IMAGE_SQUARE,
    CARD_LINK_TILE,
    CARD_MAP,
    CARD_META_CHANNEL,
    CARD_MOBILIZATION,
    CARD_PLEDGE,
    CARD_POSTBOX,
    CARD_RESEARCH,
    CARD_VOLUNTEER_MOBILIZATION,
    CARD_VOTE_GALLERY,
    CARD_CREATION,
    CARD_SURVEY,
    CARD_RAINBOW_ROAD,
    CARD_DIALOG,
} from '@core/utilities/constants/card_types';
import { mediaRegex } from '~/wl-core/utilities/constants/regexes';

const LoadableCreateCreationForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/Creation/CreateCreationForm'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateDynamicCardForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/CreateDynamicCardForm'),
    { resolveComponent: imported => imported.default }
);
const LoadableCreateMapForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/CreateMapForm.js'),
    { resolveComponent: imported => imported.default }
);
const LoadableCreateResearchCardForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/CreateResearchCardForm.js'),
    { resolveComponent: imported => imported.default }
);
const LoadableCreateHeaderForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/CreateHeaderForm.js'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateCourseForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/Course/CreateCourseForm'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateSurveyForm = loadable(
    () => import('@components/content/newpost/forms/Survey/CreateSurveyForm'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreatePostboxForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/Postbox/CreatePostboxForm.js'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreatePledgeForm = loadable(
    () => import('@web/ui/components/content/pledge/CreatePledgeForm.js'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateEventListingForm = loadable(
    () => import('@web/ui/components/content/newpost/event/CreateEventListingForm'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateVoteGalleryForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/CreateVoteGalleryForm.js'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateJobForm = loadable(
    () => import('@web/ui/components/content/posting/CreatePostingForm.js'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateVolunteerForm = loadable(
    () => import('@web/ui/components/content/posting/CreateVolunteerForm'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateLinkTileForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/CreateLinkTileForm.js'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateMetaChannelCard = loadable(
    () => import('@web/ui/components/content/newpost/forms/CreateMetaChannelCard'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateBlogForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/CreateBlogForm.js'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateSquareImageForm = loadable(
    () => import('../../card/squareImagecard/CreateSquareImageCard'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateGroupForm = loadable(
    () => import('@web/ui/components/content/newpost/forms/CreateGroupForm.js'),
    { resolveComponent: imported => imported.default }
);

const LoadableCreateRainbowRoadForm = loadable(
    () => import('@components/content/newpost/forms/RainbowRoad/CreateRainbowRoadForm'),
    {
        resolveComponent: imported => imported.default,
    }
);

const LoadableCreateDialogForm = loadable(
    () => import('@components/content/newpost/forms/Dialog/CreateDialogForm'),
    { resolveComponent: imported => imported.default }
);

const getThirdGroup = (regexp, str) => {
    const array = [...str.matchAll(regexp)];
    return array.map(m => m[3]);
};

const parseURL = (url, regex, matchCallback = null) => {
    const match = url.match(regex);
    if (!matchCallback) return match && match.length;
    return matchCallback(match);
};

// returns video id given a string that matches the regex
const parseYoutubeURL = match => {
    const url = match?.[0];
    if (url) {
        // 'third group' here will correspond to the video id if it is a youtube short or video that matches the regex
        const thirdGroup = getThirdGroup(mediaRegex.youtube, url);
        return thirdGroup?.[0];
    }

    return false;
};

const getForm = (formName, formAttributes, currentUser, group) => {
    switch (formName) {
        case CARD_DYNAMIC:
            return <LoadableCreateDynamicCardForm {...formAttributes} />;
        case CARD_META_CHANNEL:
            return <LoadableCreateMetaChannelCard {...formAttributes} />;
        case CARD_VOLUNTEER_MOBILIZATION:
            return <LoadableCreateVolunteerForm {...formAttributes} />;
        case CARD_MOBILIZATION:
            return <LoadableCreateJobForm {...formAttributes} />;
        case CARD_COURSE:
            return <LoadableCreateCourseForm {...formAttributes} />;
        case CARD_PLEDGE:
            return <LoadableCreatePledgeForm {...formAttributes} />;
        case CARD_RESEARCH:
            return <LoadableCreateResearchCardForm {...formAttributes} />;
        case CARD_POSTBOX:
            return <LoadableCreatePostboxForm {...formAttributes} />;
        case CARD_VOTE_GALLERY:
            return <LoadableCreateVoteGalleryForm {...formAttributes} />;
        case CARD_LINK_TILE:
            return <LoadableCreateLinkTileForm {...formAttributes} currentUser={currentUser} />;
        case CARD_HEADER:
            return <LoadableCreateHeaderForm {...formAttributes} />;
        case CARD_MAP:
            return <LoadableCreateMapForm {...formAttributes} />;
        case CARD_EVENT:
            return <LoadableCreateEventListingForm {...formAttributes} />;
        case CARD_BLOG:
            return <LoadableCreateBlogForm {...formAttributes} group={group} />;
        case CARD_IMAGE_SQUARE:
            return <LoadableCreateSquareImageForm {...formAttributes} />;
        case CARD_GROUP:
            return <LoadableCreateGroupForm {...formAttributes} />;
        case CARD_RAINBOW_ROAD:
            return <LoadableCreateRainbowRoadForm {...formAttributes} />;
        case CARD_CREATION:
            return <LoadableCreateCreationForm {...formAttributes} />;
        case CARD_SURVEY:
            return <LoadableCreateSurveyForm {...formAttributes} />;
        case CARD_DIALOG:
            return <LoadableCreateDialogForm {...formAttributes} />;
        default:
            break;
    }
};

const typeAllowed = (type, user, allowedTypes) => {
    const areTypesRestricted = allowedTypes !== null;
    const canCreateAllTypes = user?.canCreate ? user.canCreate.includes(ALL_TYPES) : false;
    if (!areTypesRestricted) return true;
    const canCreateType =
        (user?.canCreate ? user.canCreate.includes(type) : false) || canCreateAllTypes;
    return canCreateType && _.includes(allowedTypes, type);
};

// iOS Safari doesn't allow programatically autofocusing on an input
// see https://stackoverflow.com/questions/54424729/ios-show-keyboard-on-input-focus
// Apple require user interaction in order to activate the keyboard
const focusAndOpenKeyboard = (el, timeout) => {
    if (!timeout) {
        timeout = 800;
    }
    if (el) {
        // Align temp input element approximately where the input element is
        // so the cursor doesn't jump around
        const __tempEl__ = document.createElement('input');
        __tempEl__.style.position = 'fixed';
        __tempEl__.style.top = `${el.offsetTop + 25}px`;
        __tempEl__.style.left = `${el.offsetLeft}px`;
        __tempEl__.style.height = 20;
        __tempEl__.style.opacity = 0;
        // Put temp element as a child of the page <body> and focus on it
        document.body.appendChild(__tempEl__);
        __tempEl__.focus();

        // The keyboard is open. Now do a delayed focus on the target element
        setTimeout(() => {
            el.focus();
            el.click();
            // Remove the temp element
            document.body.removeChild(__tempEl__);
        }, timeout);
    }
};

export { getForm, focusAndOpenKeyboard, parseURL, parseYoutubeURL, typeAllowed };
