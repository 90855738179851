import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import X from '@web/ui/dsc/svgs/X';

const localNamespace = 'imports.wlWeb.ui.components.search.SearchOverlay';

type SearchInputProps = {
    isSearchOverlayOpen?: boolean;
    onChange: (value: string) => void;
    value: string;
    placeholder?: string;
    className?: string;
    autoFocusInput?: boolean;
};
const SearchInput = memo<SearchInputProps>(function SearchInput({
    isSearchOverlayOpen,
    onChange,
    value,
    className,
    placeholder,
    autoFocusInput = true,
}) {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    // iOS Safari doesn't allow programatically autofocusing on an input
    // see https://stackoverflow.com/questions/54424729/ios-show-keyboard-on-input-focus
    // Apple require user interaction in order to activate the keyboard
    const focusAndOpenKeyboard = (el: React.RefObject<HTMLInputElement>, timeout: number = 800) => {
        if (el) {
            // Align temp input element approximately where the input element is
            // so the cursor doesn't jump around
            const __tempEl__ = document.createElement('input');
            __tempEl__.style.position = 'fixed';
            __tempEl__.style.top = `${(el.current?.offsetTop ?? 0) + 25}px`;
            __tempEl__.style.left = `${el.current?.offsetLeft ?? 0}px`;
            __tempEl__.style.height = '20';
            __tempEl__.style.opacity = '0';
            // Put this temp element as a child of the page <body> and focus on it
            document.body.appendChild(__tempEl__);
            __tempEl__.focus();

            // The keyboard is open. Now do a delayed focus on the target element
            setTimeout(() => {
                el?.current?.focus();
                el?.current?.click();
                // Remove the temp element
                document.body.removeChild(__tempEl__);
            }, timeout);
        }
    };

    useEffect(() => {
        // Don't focus input on native app
        if (isSearchOverlayOpen && autoFocusInput) {
            focusAndOpenKeyboard(inputRef, 900);
        }
    }, [isSearchOverlayOpen]);

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault();
        inputRef.current?.blur();
    };

    const reset = () => {
        onChange('');
        inputRef.current?.blur();
    };

    return (
        <form onSubmit={handleSubmit} className={`search-field-input ${className ?? ''}`}>
            <input
                tabIndex={-1}
                ref={inputRef}
                autoComplete="off"
                onChange={e => onChange(e.currentTarget.value)}
                value={value}
                type="text"
                maxLength={256}
                name="Search"
                data-name="Search"
                placeholder={placeholder || t(`common:${localNamespace}.input_placeholder`)}
                id="Search"
            />

            <button type="button" onClick={reset} className={value ? '' : 'hidden'}>
                <X />
            </button>
        </form>
    );
});

export default SearchInput;
