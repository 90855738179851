import React from 'react';
import TreasureCreations from '@components/lego/creation/TreasureCreations';

import { CreationState } from '@shared/welibrary-graphql/types';

const TreasureCreationsTab = React.forwardRef<
    HTMLElement,
    { creations: Array<CreationState | null> }
>(function TreasureCreationsTab({ creations }, ref) {
    return (
        <section className="creations-tab" ref={ref}>
            <div className="treasure-resources-body">
                <TreasureCreations creations={creations} />
            </div>
        </section>
    );
});

export default TreasureCreationsTab;
