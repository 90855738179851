import React from 'react';

const LeftCaretIcon: React.FC<{
    className?: string;
    width?: string;
    height?: string;
    strokeWidth?: string;
    color?: string;
}> = ({ strokeWidth = '2', className, width = '24', height = '24', color = '#14142B' }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.96338 1L1.00021 7.96317L7.96338 14.9263"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default LeftCaretIcon;
