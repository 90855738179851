import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
    ADD_USER_TO_GROUP,
    EXPRESS_INTEREST_IN_GROUP,
    RESPOND_NOT_GOING_TO_GROUP_EVENT,
} from '@shared/welibrary-graphql/user/mutations';
import { UPDATE_GET_GROUP_MEMBERSHIP } from '@core/utilities/constants/update_cache';
import useModal from '@components/modals/hooks/useModal';
import signUpStore from '@stores/SignUp';

import EditTickets from '@components/content/newpost/forms/ShoppingCartProducts/EditTickets';
import ShoppingCartModal from '@components/card/Shopping-Cart/ShoppingCartModal';
import TicketItem from '@components/card/Shopping-Cart/TicketItem';
import Checkout from '@components/card/Shopping-Cart/Checkout';
import ManageUserGroupSubscription from '@components/group/groupdashboard/groupSubscriptionPaywall/ManageUserGroupSubscription';

import { getWhitelabelPublicSettings, getRootUrl } from '@core/utilities/whitelabel_helpers';

import { CARD_EVENT } from '@core/utilities/constants/card_types';
import { GROUP_ACTIONS } from '@core/utilities/constants/group';
import StripePreAuthPaymentOrderDetails from '../../profile/stripe/StripePreAuthPaymentOrderDetails';
import EventActionModal from '../groupdashboard/EventActionModal';

/**
 * React Hook for getting a specific group's actions
 * This will give you all the data/logic you need to display and take actions
 * related to a group for a given user
 *
 * @param {{
 *   group: GroupObject
 *   refetchQueries?: array
 * }}
 */
const useGroupActions = ({ group, refetchQueries = [] }) => {
    const { t } = useTranslation();
    const { newModal, closeModal } = useModal();

    const localNamespace =
        group?.subtype === 'event'
            ? 'imports.wlWeb.ui.components.group.eventDashboard'
            : 'imports.wlWeb.ui.components.group.groupDashboard';

    const { homeButton } = getWhitelabelPublicSettings();
    const homeGroups = getWhitelabelPublicSettings()?.homeGroups?.groupIds;

    // "Home groups" are special groups that may have some special considerations, eg user cannot leave them
    const isHomeGroup = homeButton?.groupId === group?._id || homeGroups?.includes(group?._id);
    const isEvent = group?.subtype === CARD_EVENT;
    const isSubscriptionGroup = group?.doesSellMembership && !isEvent;

    const [addUserToGroup, { loading, error }] = useMutation(ADD_USER_TO_GROUP);
    const [expressInterestInGroup] = useMutation(EXPRESS_INTEREST_IN_GROUP);
    const [respondNotGoingToGroupEvent] = useMutation(RESPOND_NOT_GOING_TO_GROUP_EVENT);

    const getCurrentPrimaryActionAvailable = groupActionsAllowed => {
        if (!groupActionsAllowed) return { label: null, action: null };

        if (groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_EDIT_PRODUCTS)) {
            return {
                label: isEvent
                    ? t(`common:${localNamespace}.edit_tickets`, 'Edit Tickets')
                    : 'Edit Subscriptions',
                action: GROUP_ACTIONS.GROUP_EDIT_PRODUCTS,
            };
        }
        if (groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS)) {
            return {
                label: isEvent
                    ? t(`common:${localNamespace}.manage_ticket`, 'Manage Ticket')
                    : t(`common:${localNamespace}.manage_subscription`, 'Manage Subscription'),
                action: GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS,
            };
        }

        const usedShortCode = signUpStore.get.usedGroupCode();
        const signupCode = signUpStore.get.groupCode();
        const bypassApprovalRequired =
            usedShortCode &&
            signupCode.toUpperCase() === group?.settings?.shortCode &&
            group?.settings?.shortCodeApprovalRequired !== true &&
            isSubscriptionGroup;

        if (
            groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_REQUEST_TO_PURCHASE) &&
            !bypassApprovalRequired
        ) {
            return {
                label: isEvent
                    ? t(`common:${localNamespace}.request_to_attend`, 'Request to Attend')
                    : t(`common:${localNamespace}.request_invite`, 'Request Invite'),
                action: GROUP_ACTIONS.GROUP_REQUEST_TO_PURCHASE,
            };
        }
        if (groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_REACTIVATE_SUBSCRIPTION)) {
            return {
                label: 'Member',
                action: GROUP_ACTIONS.GROUP_REACTIVATE_SUBSCRIPTION,
            };
        }
        if (
            groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_PURCHASE_ACCESS) ||
            (bypassApprovalRequired && isSubscriptionGroup)
        ) {
            return {
                label: isEvent ? t(`common:${localNamespace}.register`, 'Register') : 'Subscribe',
                action: GROUP_ACTIONS.GROUP_PURCHASE_ACCESS,
            };
        }
        if (groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_LOGGEDOUT_PURCHASE_ACCESS)) {
            return {
                label: t(`common:${localNamespace}.register`, 'Register'),
                action: GROUP_ACTIONS.GROUP_LOGGEDOUT_PURCHASE_ACCESS,
            };
        }
        if (groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_ACCEPT_INVITE)) {
            return {
                label: t(`common:${localNamespace}.accept_invite`),
                action: GROUP_ACTIONS.GROUP_ACCEPT_INVITE,
            };
        }
        if (groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_CANCEL_PRE_AUTH_PURCHASE)) {
            return {
                label: t(
                    `common:${localNamespace}.cancel_pre_auth_purchase_request`,
                    'Cancel Request'
                ),
                action: GROUP_ACTIONS.GROUP_CANCEL_PRE_AUTH_PURCHASE,
            };
        }
        if (
            groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_JOIN) ||
            (bypassApprovalRequired &&
                groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_REQUEST_JOIN))
        ) {
            if (isEvent) {
                if (!group?.currentUserIsMember && group?.currentUserOwnsMembership) {
                    return {
                        label: t(`common:${localNamespace}.join`, 'Join'),
                        action: GROUP_ACTIONS.GROUP_JOIN,
                        useRegisterButton: false,
                    };
                }

                return {
                    label: t(`common:${localNamespace}.register_to_event`, 'Register'),
                    action: GROUP_ACTIONS.GROUP_JOIN,
                    useRegisterButton: true,
                };
            }

            return {
                label: t(`common:${localNamespace}.join`, 'Join'),
                action: GROUP_ACTIONS.GROUP_JOIN,
            };
        }
        if (groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_LOGGEDOUT_RSVP)) {
            if (group?.subtype === 'event') {
                return {
                    label: 'Register',
                    action: GROUP_ACTIONS.GROUP_LOGGEDOUT_RSVP,
                    useRegisterButton: true,
                };
            }
        }
        if (groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_REQUEST_JOIN)) {
            return {
                label: t(`common:${localNamespace}.request_invite`, 'Request Invite'),
                action: GROUP_ACTIONS.GROUP_REQUEST_JOIN,
            };
        }
        if (groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN)) {
            return {
                label: t(`common:${localNamespace}.invite_pending`, 'Invite Pending'),
                action: GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN,
            };
        }
        if (groupActionsAllowed.includes(GROUP_ACTIONS.GROUP_LEAVE) && !isHomeGroup) {
            if (group?.subtype === 'event') {
                return {
                    label: t(`common:${localNamespace}.leave_group`),
                    action: GROUP_ACTIONS.GROUP_LEAVE,
                    useRsvpButton: true,
                };
            }

            return {
                label: t(`common:${localNamespace}.leave_group`),
                action: GROUP_ACTIONS.GROUP_LEAVE,
            };
        }
        return {
            label: null,
            action: null,
        };
    };

    const [primaryActionAvailable, setPrimaryActionAvailable] = useState(
        getCurrentPrimaryActionAvailable(group?.groupActionsAllowed)
    );

    useEffect(() => {
        setPrimaryActionAvailable(getCurrentPrimaryActionAvailable(group?.groupActionsAllowed));
    }, [group]);

    if (!group) {
        return {};
    }

    const addUserAsMember = async userId => {
        // if a user owns a ticket but was kicked out of the event || left the event
        // they should be able to rejoin
        if (isEvent && !group?.currentUserIsMember && group?.currentUserOwnsMembership) {
            return addUserToGroup({
                variables: {
                    groupId: group?._id,
                    userId,
                    host: getRootUrl(),
                },
                update: (cache, data) =>
                    UPDATE_GET_GROUP_MEMBERSHIP(cache, data, { groupId: group?._id, type: 'user' }),
                refetchQueries,
            });
        } else if (
            isEvent &&
            group?.settings?.approvalRequired === false &&
            !group?.currentUserIsAdmin
        ) {
            const defaultFreeTicket = group?.products?.[0];
            // by default only a single free ticket should exist within an event group
            // in addition only free tickets should be queried when the following
            // group actions exist GROUP_ACTIONS.GROUP_JOIN, GROUP_ACTIONS.GROUP_LOGGEDOUT_RSVP
            newModal(<Checkout group={group} product={defaultFreeTicket} quantity={1} />, {
                className: 'no-top-padding no-bottom-padding',
                hideButton: true,
            });
        } else {
            return addUserToGroup({
                variables: {
                    groupId: group?._id,
                    userId,
                    host: getRootUrl(),
                },
                update: (cache, data) =>
                    UPDATE_GET_GROUP_MEMBERSHIP(cache, data, { groupId: group?._id, type: 'user' }),
                refetchQueries,
            });
        }
    };

    const addUserAsAdmin = async userId => {
        return addUserToGroup({
            variables: {
                groupId: group?._id,
                userId,
                admin: true,
            },
            refetchQueries,
        });
    };

    const demoteUserFromAdmin = async userId => {
        return addUserToGroup({
            variables: {
                groupId: group?._id,
                userId,
                admin: false,
            },
            refetchQueries,
        });
    };

    const removeUser = async userId => {
        return addUserToGroup({
            variables: {
                groupId: group?._id,
                userId,
                remove: true,
            },
            refetchQueries,
        });
    };

    const handleEditProducts = () => {
        newModal(
            <div style={{ padding: '12px 16px' }}>
                <EditTickets group={group} />
            </div>
        );
    };

    const handlePurchaseAccess = () => {
        if (isEvent) {
            const firstTicket = group?.products?.[0];
            const isFreeEvent = group?.products?.length === 1 && firstTicket.price === 0;
            if (isFreeEvent) {
                // by default only a single free ticket should exist within a free event group
                newModal(<Checkout group={group} product={firstTicket} quantity={1} />, {
                    className: 'no-top-padding no-bottom-padding',
                    hideButton: true,
                });
            } else {
                // newModal(<ShoppingCartModal group={group} isInline={false} />, {
                newModal(<Checkout group={group} product={firstTicket} quantity={1} />, {
                    className: 'no-top-padding no-bottom-padding',
                    hideButton: true,
                    showCloseButtonOnMobile: true,
                });
            }
        } else {
            const defaultSubscription = group?.products?.[0];
            // shortcut into checkout, with first product in group.products
            // currently only supporitng a single subscription type for groups
            newModal(<Checkout group={group} product={defaultSubscription} quantity={1} />, {
                className: 'no-top-padding no-bottom-padding',
                hideButton: true,
            });
        }
    };

    const handleViewTicket = () => {
        if (isEvent) {
            newModal(
                <TicketItem groupId={group?._id} handleShoppingCart={handlePurchaseAccess} />,
                {
                    className: 'no-top-padding no-bottom-padding no-notch ticket-modal',
                    hideButton: true,
                }
            );
        } else {
            newModal(
                <ManageUserGroupSubscription group={group} />,
                {
                    className: 'no-top-padding no-bottom-padding',
                    hideButton: true,
                },
                { mobile: 'full-screen', desktop: 'center' }
            );
        }
    };

    const handleLoggedOutPurchaseAccess = () => {
        // newModal(<ShoppingCartModal group={group} isInline={false} />, {
        newModal(<EventActionModal group={group} product={{}} />, {
            className: 'no-top-padding no-bottom-padding',
            hideButton: true,
            showCloseButtonOnMobile: true,
        });
    };

    const expressInterest = userId => {
        return expressInterestInGroup({
            variables: {
                groupId: group?._id,
                userId,
            },
            refetchQueries,
        });
    };

    const respondNotGoing = userId => {
        return respondNotGoingToGroupEvent({
            variables: {
                groupId: group?._id,
                userId,
            },
            refetchQueries,
        });
    };

    const handleCancelPreAuthPurchase = userId => {
        newModal(
            <StripePreAuthPaymentOrderDetails
                userId={userId}
                groupId={group?._id}
                mode="deny"
                closeModal={closeModal}
            />
        );
    };

    const takeGroupActionForUser = (userId, action) => {
        if (
            [
                GROUP_ACTIONS.GROUP_ACCEPT_INVITE,
                GROUP_ACTIONS.GROUP_JOIN,
                GROUP_ACTIONS.GROUP_REQUEST_JOIN,
                GROUP_ACTIONS.GROUP_REQUEST_TO_PURCHASE,
            ].includes(action)
        ) {
            if (action === GROUP_ACTIONS.GROUP_JOIN && group.currentUserIsMember) {
                return;
            }
            addUserAsMember(userId);
        } else if (
            [GROUP_ACTIONS.GROUP_CANCEL_REQUEST_JOIN, GROUP_ACTIONS.GROUP_LEAVE].includes(action)
        ) {
            removeUser(userId);
        } else if (action === GROUP_ACTIONS.GROUP_PURCHASE_ACCESS) {
            handlePurchaseAccess();
        } else if (action === GROUP_ACTIONS.GROUP_REACTIVATE_SUBSCRIPTION) {
            handleViewTicket();
        } else if (action === GROUP_ACTIONS.GROUP_LOGGEDOUT_PURCHASE_ACCESS) {
            handleLoggedOutPurchaseAccess();
        } else if (action === GROUP_ACTIONS.GROUP_LOGGEDOUT_RSVP) {
            addUserAsMember(userId);
        } else if (action === GROUP_ACTIONS.GROUP_EDIT_PRODUCTS) {
            handleEditProducts();
        } else if (action === GROUP_ACTIONS.GROUP_VIEW_PURCHASED_PRODUCTS) {
            handleViewTicket();
        } else if (action === GROUP_ACTIONS.GROUP_EXPRESS_INTEREST) {
            expressInterest(userId);
        } else if (action === GROUP_ACTIONS.GROUP_RESPOND_NOT_GOING) {
            respondNotGoing(userId);
        } else if (action === GROUP_ACTIONS.GROUP_CANCEL_PRE_AUTH_PURCHASE) {
            handleCancelPreAuthPurchase(userId);
        }
    };

    return {
        addUserAsMember,
        addUserAsAdmin,
        removeUser,
        demoteUserFromAdmin,
        primaryActionAvailable,
        takeGroupActionForUser,
        isHomeGroup,
        loading,
        error,
        handlePurchaseAccess,
    };
};

export default useGroupActions;
