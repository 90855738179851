// src/pushnotifications/mutations.ts
import { gql } from "@apollo/client";
var SYNC_PUSH_TOKEN = gql`
    mutation SyncPushToken($token: String!, $host: String) {
        syncPushToken(token: $token, host: $host) {
            __typename
            _id
            appName
            userId
            enabled
            createdAt
            updatedAt
        }
    }
`;
var REVOKE_PUSH_TOKEN = gql`
    mutation revokePushToken($token: String!) {
        revokePushToken(token: $token)
    }
`;
export {
  REVOKE_PUSH_TOKEN,
  SYNC_PUSH_TOKEN
};
