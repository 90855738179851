import React, { MouseEventHandler } from 'react';

import Hamburger from '@web/ui/dsc/svgs/Hamburger';

import useNavHighlighting from '@web/ui/components/navigation/hooks/useNavHighlighting';

type SlideMenuNavButtonProps = {
    onClick: MouseEventHandler<HTMLButtonElement>;
    onContextMenu: MouseEventHandler<HTMLButtonElement>;
    notifications: any;
};

const SlideMenuNavButton: React.FC<SlideMenuNavButtonProps> = ({
    onClick,
    onContextMenu,
    notifications,
}) => {
    const className = useNavHighlighting({ sideMenu: true });

    const unreadNotificationsLength = notifications?.unreadNotifications?.length;

    return (
        <button
            type="button"
            className={`mn-button desktop-hide w-inline-block menu ${className}`}
            id="mobile-nav-hamburger-button"
            onClick={onClick}
            onContextMenu={onContextMenu}
        >
            <div className="mn-icon">
                <Hamburger className="content-button-icon menu" />
                <div className="icon-alert-indicator">
                    {unreadNotificationsLength > 0 && (
                        <div className="alert-indicator-dot">{unreadNotificationsLength}</div>
                    )}
                </div>
            </div>
        </button>
    );
};

export default SlideMenuNavButton;
