import React from 'react';
import {useTranslation} from 'react-i18next';

const ClearAllButton = ({ markNotificationsRead }) => {
    const { t } = useTranslation();

    return (
        <button type="button" className="clear-button" onClick={() => markNotificationsRead()}>
            <div />
            {t('common:global.verbs.clear_all')}
        </button>
    );
};

export default ClearAllButton;
