import React from 'react';

import ChunkyArrowRight from '@dsc/svgs/lego/ChunkyArrowRight';
import ChunkyX from '@dsc/svgs/lego/ChunkyX';
import Button from '@dsc/forms/buttons/Button';

import { CreationPageNavTabType } from '@components/lego/creation/creationpage/types';
import { ContentCard } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

type ChooseCreationNavProps = {
    card?: Maybify<ContentCard> | null;
    activeScreen: CreationPageNavTabType;
    selectedIndex?: number;
    handleGoBack: () => void;
    handleMainClick: () => void;
    handleCancel: () => void;
};

const ChooseCreationNav: React.FC<ChooseCreationNavProps> = ({
    card,
    activeScreen,
    selectedIndex = 0,
    handleGoBack,
    handleMainClick,
    handleCancel,
}) => {
    const creationItems = card?.creationItems;
    const courseItems = creationItems?.course?.courseItems;
    const selectedCourseLevel = courseItems?.levels?.[selectedIndex];

    let mainBtnText;

    if (activeScreen === 'creationSlide') {
        mainBtnText = creationItems?.levels?.[selectedIndex]?.title || selectedCourseLevel?.title;
    }

    if (activeScreen === 'courseSlide') {
        mainBtnText = 'Start';
    }

    if (!activeScreen) return <></>;

    return (
        <div className="footer">
            <nav className="level-select">
                {activeScreen === 'courseSlide' && (
                    <Button type="icon" className="go-back-btn" onClick={handleGoBack}>
                        <ChunkyArrowRight color="#FFFFFF" className="chunky-left-btn flip-180" />
                    </Button>
                )}

                <Button className="go-button" type="medium" onClick={handleMainClick}>
                    {mainBtnText}
                    {activeScreen === 'creationSlide' && (
                        <ChunkyArrowRight className="chunky-right-btn" />
                    )}
                </Button>

                <Button type="icon" className="cancel-btn" onClick={handleCancel} disabled={false}>
                    <ChunkyX />
                </Button>
            </nav>
        </div>
    );
};

export default ChooseCreationNav;
