import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';

import useNotifications from '@web/ui/components/generic/notifications/hooks/useNotifications';

import { GET_NOTIFICATIONS_FOR_LOGGEDIN_USER } from '@shared/welibrary-graphql/notifications/queries';

/**
 * React hook for displaying notifications in the SecondaryNavigation bar
 */
const useNavNotifications = () => {
    const [toggleNotifications, setToggleNotifications] = useState(false);

    const queryResult = useQuery(GET_NOTIFICATIONS_FOR_LOGGEDIN_USER, {
        limit: 15,
        offset: 0,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const { snoozed, loading, error, notifications, unreadNotifications, markNotificationsRead } =
        useNotifications(queryResult);

    const notificationRef = useRef();

    const handleClickOutside = e => {
        const isDropdownMenu = e.target?.classList.contains('item-dropdown');
        if (notificationRef.current) {
            if (!notificationRef.current.contains(e.target) && !isDropdownMenu) {
                setToggleNotifications(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleToggle = (newToggleNotifications?: boolean) => {
        setToggleNotifications(
            newToggleNotifications ??
                (oldToggleNotifications => {
                    // Removing logic for now - each notification clears when you click it
                    // if (oldToggleNotifications) markNotificationsRead();
                    return !oldToggleNotifications;
                })
        );
    };

    return {
        toggleNotifications,
        notificationRef,
        snoozed,
        handleToggle,
        loading,
        error,
        notifications,
        unreadNotifications,
        markNotificationsRead,
    };
};

export default useNavNotifications;
