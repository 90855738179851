import React from 'react';

import useOrganizationPicker from '@web/ui/components/organization/hooks/useOrganizationPicker';

import SearchLoader from '@web/ui/components/search/SearchLoader';

import { Organization } from '@shared/welibrary-graphql/types';

type OrganizationPickerProps = {
    onOrganizationSelect: (organization: Organization) => void;
};

const OrganizationPicker: React.FC<OrganizationPickerProps> = ({ onOrganizationSelect }) => {
    const {
        searchInput,
        actualSearchInput,
        setSearchInput,
        loading,
        organizations,
        selectOrganization,
        addOrganization,
    } = useOrganizationPicker(onOrganizationSelect);

    const orgs =
        organizations?.map(organization => {
            if (organization)
                return (
                    <li key={organization?._id}>
                        <button type="button" onClick={() => selectOrganization(organization)}>
                            {organization?.name}
                        </button>
                    </li>
                );

            return <></>;
        }) ?? [];

    return (
        <section className="organization-picker">
            <header>
                <h1>Organizations</h1>
                <p>
                    Search for an organization and select from the list below or enter the name of a
                    new organization and click the plus button to create it.
                </p>
            </header>

            <form
                onSubmit={e => {
                    e.preventDefault();
                    addOrganization();
                }}
            >
                <input
                    autoComplete="off"
                    type="text"
                    maxLength={256}
                    placeholder="Search for an Organization"
                    onChange={e => setSearchInput(e.currentTarget.value)}
                    value={searchInput}
                    onClick={e => e.stopPropagation()}
                />

                <button type="submit" disabled={searchInput.length === 0}>
                    <div className="content-button-icon plus" />
                </button>
            </form>

            <ul>
                {orgs}
                <SearchLoader
                    emptyResults={
                        !loading && organizations?.length === 0 && actualSearchInput !== ''
                    }
                    emptyState={searchInput === '' && actualSearchInput === ''}
                    areResults={(organizations?.length ?? 0) > 0}
                    loading={loading}
                    prompt="Search for an Organization"
                />
            </ul>
        </section>
    );
};

export default OrganizationPicker;
