import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useModal from '@web/ui/components/modals/hooks/useModal';
import { useCurrentUser } from '@stores/User';

import { InPersonDisplay, VirtualDisplay } from '@components/card/dynamiccard/LocationDisplay';
import EventPrivacyBubble from '@components/events/EventPrivacyBubble';
import EventTimeContext from '@components/events/EventTimeContext';
import TicketPrices from '@components/events/TicketPrices';
import GroupMembers from '@components/group/groupdashboard/GroupMembers/GroupMembers';
import ActivateMerchantAccountWarning from '@components/group/ActivateMerchantAccountWarning';

import { getFormattedDateDisplayFromTimes } from '@core/utilities/constants/events';
import {
    getFreeProducts,
    getPaidProducts,
} from '@components/content/newpost/forms/ShoppingCartProducts/ticketHelpers';
import { userCanViewGroup } from '@helpers/events/event.helpers';

import { GROUP_ACTIONS } from '@core/utilities/constants/group';

import { LocationType, Group } from '@shared/welibrary-graphql/types';
import { Maybify } from '@core/types/Utilities';

const localNamespace = 'imports.wlWeb.ui.components.events.eventGroupCardInfo';

type EventGroupCardInfoProps = {
    group?: Maybify<Group> | null;
    className?: string;
};

const EventGroupCardInfo: React.FC<EventGroupCardInfoProps> = ({ group, className = '' }) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();

    const eventOverviewDetails = group?.eventOverviewDetails;
    const eventLocationSettings = eventOverviewDetails?.eventLocationSettings;

    const datesDisplay = getFormattedDateDisplayFromTimes(eventOverviewDetails);

    const virtualLink = eventOverviewDetails?.eventLocationSettings?.link;

    const locationType = eventOverviewDetails?.eventLocationSettings?.type;
    const formatted_address = eventLocationSettings?.venue?.location?.formatted_address || '';

    const showAttendees =
        !group?.eventOverviewDetails?.hideAttendeeCount && !!Number.isInteger(group?.totalMembers);

    const attendeesText =
        group?.totalMembers === 1
            ? `${group?.totalMembers} ${t(`common:${localNamespace}.attendee`, 'Attendee')}`
            : `${group?.totalMembers} ${t(`common:${localNamespace}.attendees`, 'Attendees')}`;

    const { newModal } = useModal();

    const showAttendeesModal = (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        newModal(<GroupMembers group={group} />);
    };

    const merchantAccountFullyActivated =
        group?.paymentSettings?.stripeAccount?.charges_enabled &&
        group?.paymentSettings?.stripeAccount?.details_submitted &&
        group?.paymentSettings?.stripeAccount?.payouts_enabled;

    let showTickets: boolean = group?.currentUserIsAdmin
        ? merchantAccountFullyActivated
        : group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_PURCHASE_ACCESS) ||
        group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_LOGGEDOUT_PURCHASE_ACCESS) ||
        group?.groupActionsAllowed?.includes(GROUP_ACTIONS.GROUP_REQUEST_TO_PURCHASE);
    showTickets = showTickets && group?.products !== null && (group?.products?.length ?? 0) > 0;

    let products = [];

    if (group?.currentUserIsAdmin) {
        products = group?.doesSellMembership
            ? getPaidProducts(group?.products)
            : getFreeProducts(group?.products);
    } else {
        products = group?.products;
    }

    const canUserViewMembers = userCanViewGroup(group) && currentUser;

    return (
        <section className={`event-group-card-info ${className}`}>
            <span className="event-group-info-bubble desktop">
                <EventPrivacyBubble group={group} />
                <EventTimeContext group={group} />
            </span>
            <ActivateMerchantAccountWarning group={group} />
            <span className="date">{datesDisplay}</span>
            <Link to={`/g/${group?._id}`}>
                <h3 className="title">{group?.profile?.full_name}</h3>
            </Link>

            {(locationType === LocationType.InPerson ||
                locationType === LocationType.VirtualAndInPerson) &&
                formatted_address && (
                    <span className="venue">
                        <InPersonDisplay source={eventLocationSettings} />
                    </span>
                )}
            {(locationType === LocationType.Virtual ||
                locationType === LocationType.VirtualAndInPerson) &&
                virtualLink && (
                    <span className="venue">
                        <VirtualDisplay source={eventLocationSettings} />
                    </span>
                )}

            <div className="tickets-and-attendees">
                {showTickets && <TicketPrices products={products} />}
                {showTickets && showAttendees && <span className="dot-separator">•</span>}
                {showAttendees && (
                    <span
                        className={`attendee-count ${canUserViewMembers ? '' : 'disable-pointer'}`}
                        onClick={canUserViewMembers ? showAttendeesModal : undefined}
                    >
                        {attendeesText}
                    </span>
                )}
            </div>
        </section>
    );
};

export default EventGroupCardInfo;
