import React from 'react';

import QRCodeScannerStore from '@stores/QRCodeScannerStore';

export const QRCodeScannerFooter: React.FC = () => {
    return (
        <footer className="flex items-center justify-center absolute bottom-[5%] left-0 w-full z-50">
            <button
                className="flex items-center justify-center text-center rounded-full bg-primary-default w-[90%] text-white px-[16px] py-[12px] font-mouse text-4xl uppercase"
                onClick={() => QRCodeScannerStore.set.showScanner(false)}
            >
                Cancel
            </button>
        </footer>
    );
};

export default QRCodeScannerFooter;
