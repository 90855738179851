import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useApolloClient, useQuery } from '@apollo/client';

import { GET_USER_MESSAGE_THREADS } from '@shared/welibrary-graphql/messaging/queries';
import { NEW_MESSAGE_ON_THREAD_SUBSCRIPTION } from '@shared/welibrary-graphql/messaging/subscriptions';
import pushUtilities from '@core/utilities/pushUtilities';

import useNavigation from '@web/ui/components/navigation/useNavigation';
import useNavHighlighting from '@web/ui/components/navigation/hooks/useNavHighlighting';
import useRefetchOnResume from '@web/ui/components/generic/hooks/useRefetchOnResume';

import _ from 'lodash';

const MessagingNavigationIcon = ({ currentUser, showMobile, showDesktop, onClick }) => {
    const currentUserId = currentUser?._id;

    const className = useNavHighlighting({ url: '/messages' });
    const { closeSlideMenu } = useNavigation();
    const apolloClient = useApolloClient();

    const activeQuery = {
        query: GET_USER_MESSAGE_THREADS,
        variables: {
            userId: currentUserId,
            //threadLimit: Int=10,
            messageLimit: 0,
            hasUnreadMessages: true,
        },
    };

    const {
        subscribeToMore,
        refetch,
        data: userMessageThreadsData,
        loading: userMessageThreadsLoading,
        error: userMessageThreadsError,
    } = useQuery(activeQuery.query, {
        variables: activeQuery.variables,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    useRefetchOnResume(refetch);

    useEffect(() => {
        if (currentUserId) {
            subscribeToMore({
                document: NEW_MESSAGE_ON_THREAD_SUBSCRIPTION,
                variables: activeQuery.variables,
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;
                    let newMessageOnThread = subscriptionData.data.newMessageOnThread;
                    let newUserMessageThreads = { ...prev.getUserMessageThreads };
                    newUserMessageThreads.threads = _.uniqBy(
                        [...newUserMessageThreads.threads, newMessageOnThread],
                        item => item._id
                    );
                    return {
                        getUserMessageThreads: newUserMessageThreads,
                    };
                },
            });
        }

        return () => {};
    }, [currentUserId]);

    useEffect(() => {
        if (currentUserId && userMessageThreadsData?.getUserMessageThreads?.threads) {
            pushUtilities.refreshBadge(apolloClient, currentUserId);
        }
    }, [currentUserId, userMessageThreadsData]);

    let unreadCount = 0;
    if (userMessageThreadsData) {
        _.each(userMessageThreadsData.getUserMessageThreads.threads, thread => {
            let currentUserParticipantList = thread.participants.filter(
                participant => participant.user._id === currentUserId
            );
            if (currentUserParticipantList && currentUserParticipantList.length > 0) {
                unreadCount += currentUserParticipantList[0].hasUnreadMessages ? 1 : 0;
            }
        });
    }

    const handleOnClick = () => {
        onClick?.();
        closeSlideMenu();
    };

    return (
        <>
            <Link
                to="/messages"
                className={`secondary-nav-dropdown-toggle w-dropdown-toggle w--open ${className}`}
                onClick={handleOnClick}
            >
                {showMobile && (
                    <div className="mn-icon desktop-hide">
                        <div className="content-button-icon message" />
                        <div className="icon-alert-indicator">
                            {unreadCount > 0 && (
                                <div className="alert-indicator-dot">{unreadCount}</div>
                            )}
                        </div>
                    </div>
                )}
            </Link>
            <div
                data-delay="0"
                className="sn-dropdown notification w-dropdown"
                style={{ zIndex: '901' }}
            >
                <Link
                    to="/messages"
                    className="secondary-nav-dropdown-toggle w-dropdown-toggle w--open "
                    onClick={handleOnClick}
                >
                    {showDesktop && (
                        <>
                            <div className="mn-icon mobile-hide">
                                <div className="content-button-icon message" />
                            </div>
                            <div className="icon-alert-indicator mobile-hide">
                                {unreadCount > 0 && (
                                    <div className="alert-indicator-dot">{unreadCount}</div>
                                )}
                            </div>
                        </>
                    )}
                </Link>
            </div>
        </>
    );
};

export default MessagingNavigationIcon;
