import React from 'react';
import { useQuestForLoggedInUserByRainbowRoadUrlQuery } from '@shared/welibrary-graphql/quest/queries.hook';
import { config } from '@config/getConfig';
import { RESOURCES, CREATIONS, SEASONS, BADGES } from './constants';
import { TreasureNavTabType } from './types';

type TreasureNavButtonProps = {
    label: string;
    active: TreasureNavTabType;
    type: TreasureNavTabType;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const TreasureNavButton: React.FC<TreasureNavButtonProps> = ({ label, active, type, onClick }) => {
    const baseClassname = 'treasure-nav-button';

    const buttonClass = type === active ? `${baseClassname} active` : baseClassname;

    return (
        <button type="button" onClick={onClick} className={buttonClass}>
            {label}
        </button>
    );
};

const TreasureNav: React.FC<{
    active: TreasureNavTabType;
    scrollTo: any;
    legoSeasonVCs: any;
    showBadges: boolean;
}> = ({ active, scrollTo, legoSeasonVCs, showBadges }) => {
    const { data } = useQuestForLoggedInUserByRainbowRoadUrlQuery({
        variables: { url: (config.public.main_quest_map || config.public.main_quest_url) ?? '' },
        skip: !config.public.main_quest_map && !config.public.main_quest_url,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const creations = data?.getQuestForLoggedInUserByRainbowRoadUrl?.creationsState ?? [];

    const handleClick = (activeScreen: TreasureNavTabType) => {
        scrollTo?.[activeScreen]();
    };

    const showCreationButton = creations?.length > 0;
    const showSeasonsButton = legoSeasonVCs?.length > 0;

    return (
        <nav className="treasure-nav">
            <ul className="treasure-nav-list">
                <li>
                    <TreasureNavButton
                        label="Skills"
                        active={active}
                        type={RESOURCES}
                        onClick={() => handleClick(RESOURCES)}
                    />
                </li>
                {showCreationButton && (
                    <li>
                        <TreasureNavButton
                            label="Creations"
                            active={active}
                            type={CREATIONS}
                            onClick={() => handleClick(CREATIONS)}
                        />
                    </li>
                )}
                {showSeasonsButton && (
                    <li className="fadein-delay">
                        <TreasureNavButton
                            label="Seasons"
                            active={active}
                            type={SEASONS}
                            onClick={() => handleClick(SEASONS)}
                        />
                    </li>
                )}

                {showBadges && (
                    <li>
                        <TreasureNavButton
                            label="Badges"
                            active={active}
                            type={BADGES}
                            onClick={() => handleClick(BADGES)}
                        />
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default TreasureNav;
