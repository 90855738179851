/* eslint-disable react/jsx-indent */
import React from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

import { getWhitelabelPublicSettings } from '@core/utilities/whitelabel_helpers';

import DndProvider from '@components/generic/context/DndProvider';
import { NavigationProvider } from '@components/navigation/NavigationContext';
import { SidebarProvider } from '@components/generic/context/SidebarContext';
import ModalsProvider from '@components/modals/ModalsContext';
import { RemarkableProvider } from '@components/generic/context/RemarkableContext';
import { ToastProvider } from '@components/generic/context/ToastContext';
import { GroupProvider } from '@components/group/context/GroupContext';
import { NewsfeedProvider } from '@components/newsfeed/context/NewsfeedContext';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { UniversalWalletProvider } from '@core/client/contexts/UniversalWalletContext';
import MessagesProvider from '@components/messaging/MessagesContext';
import { MapsProvider } from '@components/generic/context/MapsContext';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import NewToastProvider from '@components/toast/NewToastContext';
import { HelmetProvider } from 'react-helmet-async';

// stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const { stripe } = getWhitelabelPublicSettings();

const stripePromise = stripe?.client_api_key ? loadStripe(stripe.client_api_key) : null;

const theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'sans-serif',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                backgroundColor: 'black',
            },
        },

        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                color: 'grey',
                '&$focused': {
                    // increase the specificity for the pseudo class
                    color: 'grey',
                },
            },
        },
    },
});

/* The reason for these two different providers is that the NewsfeedProvider will result in an error
    if the apollo client is not loaded or available which is possible initially */

export const AllProviders = ({ children }) => (
    <DndProvider>
        <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18next}>
                <UniversalWalletProvider>
                    <Elements stripe={stripePromise}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <NewsfeedProvider>
                                <MessagesProvider>
                                    <ModalsProvider>
                                        <NavigationProvider>
                                            <ToastProvider>
                                                <NewToastProvider>
                                                    <SidebarProvider>
                                                        <GroupProvider>
                                                            <MapsProvider>
                                                                <RemarkableProvider>
                                                                    <HelmetProvider>
                                                                        {children}
                                                                    </HelmetProvider>
                                                                </RemarkableProvider>
                                                            </MapsProvider>
                                                        </GroupProvider>
                                                    </SidebarProvider>
                                                </NewToastProvider>
                                            </ToastProvider>
                                        </NavigationProvider>
                                    </ModalsProvider>
                                </MessagesProvider>
                            </NewsfeedProvider>
                        </MuiPickersUtilsProvider>
                    </Elements>
                </UniversalWalletProvider>
            </I18nextProvider>
        </ThemeProvider>
    </DndProvider>
);

const Providers = ({ children }) => (
    <DndProvider>
        <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18next}>
                <Elements stripe={stripePromise}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MessagesProvider>
                            <ModalsProvider>
                                <NavigationProvider>
                                    <ToastProvider>
                                        <NewToastProvider>
                                            <SidebarProvider>
                                                <GroupProvider>
                                                    <MapsProvider>
                                                        <RemarkableProvider>
                                                            <HelmetProvider>
                                                                {children}
                                                            </HelmetProvider>
                                                        </RemarkableProvider>
                                                    </MapsProvider>
                                                </GroupProvider>
                                            </SidebarProvider>
                                        </NewToastProvider>
                                    </ToastProvider>
                                </NavigationProvider>
                            </ModalsProvider>
                        </MessagesProvider>
                    </MuiPickersUtilsProvider>
                </Elements>
            </I18nextProvider>
        </ThemeProvider>
    </DndProvider>
);

export default Providers;
