import React from 'react';
import { useImmer } from 'use-immer';
import { createContext } from '@core/utilities/helpers/context.helpers';

import { Toast, ToastComponent, ToastOptions } from '@core/types/Toast';

export type ToastContextValues = {
    toast: Toast;
};

export type ToastActionsContextValues = {
    /** Opens a new Toast */
    newToast: (component: ToastComponent, options?: ToastOptions) => void;

    /** Closes the Toast */
    closeToast: () => void;
};

export const [useToastContext, ToastContextProvider] = createContext<ToastContextValues>();
export const [useToastActionsContext, ToastActionsContextProvider] =
    createContext<ToastActionsContextValues>();

const NewToastProvider: React.FC = ({ children }) => {
    const [toast, setToast] = useImmer<Toast>({ component: '', open: false });

    const hideToast = () => {
        setToast(oldToast => {
            oldToast.open = false;
        });
    };

    const closeToast = () => {
        hideToast();
        setTimeout(() => setToast({ component: '', open: false }), 300);
    };

    const newToast = (component: ToastComponent, options?: ToastOptions) => {
        setToast(() => {
            setTimeout(() => {
                setToast({ component, options, open: true });

                if (options?.duration) setTimeout(closeToast, options.duration);
            }, 10);

            return { component, options, open: false };
        });
    };

    return (
        <ToastContextProvider value={{ toast }}>
            <ToastActionsContextProvider value={{ newToast, closeToast }}>
                {children}
            </ToastActionsContextProvider>
        </ToastContextProvider>
    );
};

export default NewToastProvider;
