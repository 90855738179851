import { z } from 'zod';

export const GATracking = z.object({ GA_ID: z.string() });
export type GATrackingType = z.infer<typeof GATracking>;

export const MetaInfoConfig = z.object({
    title: z.string().optional(),
    description: z.string().optional(),
    image: z.string().optional(),
    url: z.string().optional(),
});

export const Captcha = z.object({
    key: z.string(),
});

export const FilestackConfig = z.object({
    api_key: z.string(),
    filePreview: z.boolean().default(false),
});
export type FilestackConfigType = z.infer<typeof FilestackConfig>;

export const UnsplashConfig = z.object({
    access_key: z.string(),
});
export type UnsplashConfigType = z.infer<typeof UnsplashConfig>;

export const RawFirebaseConfig = z.object({
    apiKey: z.string(),
    authDomain: z.string(),
    databaseURL: z.string(),
    projectId: z.string(),
    storageBucket: z.string(),
    messagingSenderId: z.string(),
    appId: z.string(),
    measurementId: z.string(),
});
export type RawFirebaseConfigType = z.infer<typeof RawFirebaseConfig>;

export const FirebaseConfig = z.any();
export type FirebaseConfigType = z.infer<typeof FirebaseConfig>;

export const TorusConfig = z.object({
    verifier: z.string(),
    proxyContactAddress: z.string().optional(),
    network: z.enum(['testnet', 'mainnet']),
});
export type TorusConfigType = z.infer<typeof TorusConfig>;

export const StripeConfig = z.object({
    client_api_key: z.string(),
    secret_webhook_key: z.string().optional(),
});
export type StripeConfigType = z.infer<typeof StripeConfig>;

export const HomeButtonConfig = z.object({
    picture: z.string(),
    title: z.string(),
    groupId: z.string(),
});

export const HomeGroupsConfig = z.object({
    groupIds: z.array(z.string()),
});

export type HomeButtonConfigType = z.infer<typeof HomeButtonConfig>;

export const ThemeConfig = z.object({
    primaryColor: z.string().optional(),
    primaryAccent: z.string().optional(),
    primaryShade: z.string().optional(),
    primaryHighlight: z.string().optional(),
    secondaryColor: z.string().optional(),
    hoverColor: z.string().optional(),
    slideBackground: z.string().optional(),
    thumbHighlight: z.string().optional(),
    emptyBackground: z.string().optional(),
    coffeeBackground: z.string().optional(),
    docBackground: z.string().optional(),
});
export type ThemeConfigType = z.infer<typeof ThemeConfig>;

export const SlideMenuConfig = z.object({
    search: z.boolean().optional(),
    actionItems: z.boolean().optional(),
    mobilization: z.boolean().optional(),
    filterNewsfeed: z.boolean().optional(),
    discover: z.boolean().optional(),
    rainbowRoad: z.boolean().optional(),
    footerText: z.string().optional(), // this is a translation key
    showConnectionsIcon: z.boolean().optional(),
    showBookmarkIcon: z.boolean().optional(),
});
export type SlideMenuConfigType = z.infer<typeof SlideMenuConfig>;

export const SecondaryNavigationConfig = z.object({
    items: z.object({
        mobile: z.array(
            z.enum([
                'navigator',
                'groups',
                'home',
                'messages',
                'discover',
                'search',
                'notifications',
                'profile',
                'treasures',
                'myQuests',
                'simpleProfile',
            ])
        ),
        desktop: z.array(
            z.enum([
                'groups',
                'home',
                'messages',
                'discover',
                'search',
                'notifications',
                'profile',
                'treasures',
                'simpleProfile',
            ])
        ),
    }),
});
export type SecondaryNavigationConfigType = z.infer<typeof SecondaryNavigationConfig>;

export const CustomErrorMessages = z.object({
    signupEmailAlreadyExists: z.string().optional(),
    signupPhoneAlreadyExists: z.string().optional(),
});
export type CustomErrorMessagesType = z.infer<typeof CustomErrorMessages>;

export const DomainBranding = z.object({
    root_url: z.string(),
    siteName: z.string().optional(),
    emailFrom: z.string().optional(),

    GATracking: GATracking.optional(),

    metaInfo: MetaInfoConfig.optional(),

    defaultLogoBackup: z.string().optional(),
    defaultLogoBackupWhite: z.string().optional(),
    defaultLogoBackupWhiteAlt: z.string().optional(),
    defaultLogoBlack: z.string().optional(),

    privacyPolicy: z.string().optional(),
    termsOfService: z.string().optional(),

    signupToggles: z
        .object({ hidePrivacyStep: z.boolean().optional(), hideAgeConsent: z.boolean().optional() })
        .optional(),

    brandColor1: z.string().optional(),
    brandColor2: z.string().optional(),

    copyright: z.string().optional(),
    companyAddress: z.string().optional(),

    defaultGroupCode: z.string().optional(),
    defaultCoverPhoto: z.string().optional(),

    disableServiceWorker: z.boolean().optional(),

    optionalPostTypes: z.array(z.string()).optional(),
    eventTypes: z.array(z.string()).optional(),
    htmlHeader: z.array(z.string()).optional(),

    favicon: z.string().optional(),

    root_redirect: z.string().optional(),
    root_redirect_native: z.string().optional(),
    root_redirect_loggedin: z.string().optional(),

    slideMenu: SlideMenuConfig.optional(),

    secondaryNavigation: SecondaryNavigationConfig.optional(),

    customErrorMessages: CustomErrorMessages.optional(),

    disablePersistedCache: z.boolean().optional(),
});
export type DomainBrandingType = z.infer<typeof DomainBranding>;

export const PledgeConfig = z.object({
    new_recruit_template: z.string().optional(),
    new_recruit_sms_override: z.string().optional(),
    failed_recruit_template: z.string().optional(),
    failed_recruit_sms_override: z.string().optional(),
    already_recruit_template: z.string().optional(),
    already_recruit_sms_override: z.string().optional(),
    new_recruit_claim_reminder_template: z.string().optional(),
    new_recruit_claim_reminder_two_template: z.string().optional(),
});
export type PledgeConfigType = z.infer<typeof PledgeConfig>;

export const LocizeConfig = z.object({
    projectId: z.string(),
    apiKey: z.string(),
    referenceLng: z.string(),
});
export type LocizeConfigType = z.infer<typeof LocizeConfig>;

export const CeramicConfig = z.object({
    nodeEndpointUrl: z.string().optional(),
    contentFamily: z.string().optional(),
    definitions: z.object({ MyVerifiableCredentials: z.string().optional() }).optional(),
    schemas: z
        .object({
            AchievementVerifiableCredential: z.string().optional(),
            VerifiableCredentialsList: z.string().optional(),
        })
        .optional(),
});
export type CeramicConfigType = z.infer<typeof CeramicConfig>;
export const liveUpdateUpdateMethod = z.enum(['none', 'auto', 'background']);

export const LiveUpdateSettingsConfig = z.object({
    appId: z.string().optional(),
    channel: z.string().optional(),
    debug: z.boolean().optional(),
    updateMethod: liveUpdateUpdateMethod.optional(),
});
export type LiveUpdateSettingsType = z.infer<typeof LiveUpdateSettingsConfig>;

export const AppFlowSettingsConfig = z.object({
    liveUpdate: LiveUpdateSettingsConfig.optional(),
});
export type AppFlowSettingsType = z.infer<typeof AppFlowSettingsConfig>;

export const AwsMetricSettingsConfig = z.object({
    namespace: z.string(),
});
export type AwsMetricSettingsType = z.infer<typeof AwsMetricSettingsConfig>;
