import { initLearnCard } from '@learncard/init';
import didkit from '@learncard/didkit-plugin/dist/didkit/didkit_wasm_bg.wasm';

import useTorus from '@web/utilities/hooks/torus/useTorus';
import { useUniversalWalletContext } from '@core/client/contexts/UniversalWalletContext';

import { useIssueSuperSkillsCredentialMutation } from '@shared/welibrary-graphql/quest/mutations.hook';

import { config } from '@core/config/getConfig';

import { StorageType } from '@shared/welibrary-graphql/types';

import getLogger from '@core/logger';

const logger = getLogger(module);

const useUniversalWallet = () => {
    const {
        subjectDid: subjectDidFromWallet,
        setSubjectDid,
        setTorusUser,
        setWalletInstance,
        walletInstance,
    } = useUniversalWalletContext();
    const { getTorusUserInfo } = useTorus();

    const [issueSuperSkillsCredential] = useIssueSuperSkillsCredentialMutation();

    const defaultStorageType = StorageType.Ceramic; // default to this storage type for now until ceramic is up

    const initWallet = async () => {
        const userInfo = await getTorusUserInfo();
        if (userInfo && !walletInstance) {
            const ceramicIDXconfig = {
                modelData: {
                    definitions: config?.public.ceramic.definitions,
                    schemas: config?.public.ceramic.schemas,
                    tiles: {},
                },
                credentialAlias: 'MyVerifiableCredentials',
                ceramicEndpoint: config?.public?.ceramic?.nodeEndpointUrl,
                defaultContentFamily: config?.public.ceramic?.contentFamily,
            };

            const wallet = await initLearnCard({
                seed: userInfo?.privateKey,
                ceramicIdx: ceramicIDXconfig,
                didkit,
            });

            const _subjectDid = wallet.id.did();

            setSubjectDid(_subjectDid || null);
            setTorusUser(userInfo);
            setWalletInstance(wallet);

            return wallet;
        }

        return walletInstance;
    };

    const checkWalletForSSVC = async (title: string) => {
        const records = walletInstance.index.all.get();
        return records.find(record => record.id === title);
    };

    const issueSuperSkillsVc = async ({
        title = 'SSL Season 1 VC',
        name = '',
        description = '',
        image = '',
    } = {}) => {
        if (subjectDidFromWallet) {
            const VCExists = await checkWalletForSSVC(title);
            try {
                if (!VCExists) {
                    issueSuperSkillsCredential({
                        variables: {
                            subjectDid: subjectDidFromWallet,
                            storageType: defaultStorageType,
                            name,
                            description,
                            image,
                        },
                    }).then(res => {
                        const id = res?.data?.issueSuperSkillsCredential?.storageId;

                        if (id) walletInstance.index.IDX.add({ id: title, uri: id });
                    });
                }
            } catch (e) {
                logger?.error('Error issuing SS Credential', e);
            }
        }
    };

    return {
        initWallet,
        issueSuperSkillsVc,
        walletInstance,
        subjectDidFromWallet,
    };
};

export default useUniversalWallet;
